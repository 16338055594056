import i18next from 'i18next';
import React from 'react';

import EmptyState from '@commons/EmptyState';

const NoDateSelectedEmptyState = () => (
  <EmptyState
    label={i18next.t('STOCKS.STOCKS.FORM_HINT', {
      stockType: i18next.t('STOCKS.STOCKS.FORM_TYPE_STOCK').toLowerCase(),
    })}
  />
);

const NoSPAssociatedToILT = () => (
  <EmptyState label={i18next.t('STOCKS.STOCKS.NO_SUPPLIER_PRODUCT_ASSOCIATED_TO_ILT_AND_STORE')} />
);

const NoSPAssociatedToStorageArea = (
  <EmptyState label={i18next.t('STOCKS.STOCKS.NO_SUPPLIER_PRODUCT_ASSOCIATED_TO_STORAGE_AREA')} />
);

const NoRecipeAssociatedToStorageArea = (
  <EmptyState label={i18next.t('STOCKS.STOCKS.NO_RECIPE_ASSOCIATED_TO_STORAGE_AREA')} />
);

// Only used when inventory is in read only
const NoSPInInventory = (
  <EmptyState label={i18next.t('STOCKS.STOCKS.NO_SUPPLIER_PRODUCT_ASSOCIATED_TO_INVENTORY')} />
);

const NoRecipeInInventory = (
  <EmptyState label={i18next.t('STOCKS.STOCKS.NO_RECIPE_ASSOCIATED_TO_INVENTORY')} />
);

export const STOCKFORM_EMPTY_STATES = {
  NO_DATE: NoDateSelectedEmptyState,
  NO_SP_ASSOCIATED_TO_ILT: NoSPAssociatedToILT,
  NO_SP_IN_INVENTORY: NoSPInInventory,
  NO_RECIPE_IN_INVENTORY: NoRecipeInInventory,
  NO_SP_ASSOCIATED_TO_STORAGE_AREA: NoSPAssociatedToStorageArea,
  NO_RECIPE_ASSOCIATED_TO_STORAGE_AREA: NoRecipeAssociatedToStorageArea,
};
