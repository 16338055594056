import { get, isEmpty } from 'lodash';
import i18next from 'i18next';

import {
  GENERIC_MODAL_BACK_BUTTON,
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_NEXT_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getClientStoreNameTranslation } from '@commons/utils/translations';

import { SUPPLIER_PROFILE_SETTING_ACTIONS } from './constants';
import AskSupplierProductsMappingModal from './stepsModals/AskSupplierProductsMappingModal';
import AssociateSupplierProductsModal from './stepsModals/AssociateSupplierProductsModal';
import AssociateSupplierProfileStoresModal from './stepsModals/AssociateSupplierProfileStoresModal';
import SelectStoreToCloneModal from './stepsModals/SelectStoreToCloneModal';
import SetSupplierProductsPropertiesModal from './stepsModals/SetSupplierProductsPropertiesModal';
import SetSupplierProfileInformationsModal from './stepsModals/SetSupplierProfileInformationsModal';

export const SET_SUPPLIER_PROFILE_MODAL_STEPS = {
  SET_SUPPLIER_PROFILE_INFORMATIONS: 'setSupplierProfileInformations',
  ASSOCIATE_SUPPLIER_PROFILE_STORES: 'associateSupplierProfileStores',
  ASK_SUPPLIER_PRODUCTS_MAPPING: 'askSupplierProductsMapping',
  SELECT_STORE_TO_CLONE: 'selectStoreToClone',
  ASSOCIATE_SUPPLIER_PRODUCTS: 'associateSupplierProducts',
  SET_SUPPLIER_PRODUCTS_PROPERTIES: 'setSupplierProductsProperties',
};

const createOrDuplicateModalStepNumber = {
  [SET_SUPPLIER_PROFILE_MODAL_STEPS.SET_SUPPLIER_PROFILE_INFORMATIONS]: 1,
  [SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PROFILE_STORES]: 2,
  [SET_SUPPLIER_PROFILE_MODAL_STEPS.ASK_SUPPLIER_PRODUCTS_MAPPING]: 3,
  [SET_SUPPLIER_PROFILE_MODAL_STEPS.SELECT_STORE_TO_CLONE]: 3,
  [SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PRODUCTS]: 3,
  [SET_SUPPLIER_PROFILE_MODAL_STEPS.SET_SUPPLIER_PRODUCTS_PROPERTIES]: 3,
};

const associateStoresModalStepNumber = {
  [SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PROFILE_STORES]: 1,
  [SET_SUPPLIER_PROFILE_MODAL_STEPS.ASK_SUPPLIER_PRODUCTS_MAPPING]: 2,
  [SET_SUPPLIER_PROFILE_MODAL_STEPS.SELECT_STORE_TO_CLONE]: 2,
  [SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PRODUCTS]: 2,
  [SET_SUPPLIER_PROFILE_MODAL_STEPS.SET_SUPPLIER_PRODUCTS_PROPERTIES]: 2,
};

const setSupplierProfileModalComponents = {
  [SET_SUPPLIER_PROFILE_MODAL_STEPS.SET_SUPPLIER_PROFILE_INFORMATIONS]:
    SetSupplierProfileInformationsModal,
  [SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PROFILE_STORES]:
    AssociateSupplierProfileStoresModal,
  [SET_SUPPLIER_PROFILE_MODAL_STEPS.ASK_SUPPLIER_PRODUCTS_MAPPING]: AskSupplierProductsMappingModal,
  [SET_SUPPLIER_PROFILE_MODAL_STEPS.SELECT_STORE_TO_CLONE]: SelectStoreToCloneModal,
  [SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PRODUCTS]: AssociateSupplierProductsModal,
  [SET_SUPPLIER_PROFILE_MODAL_STEPS.SET_SUPPLIER_PRODUCTS_PROPERTIES]:
    SetSupplierProductsPropertiesModal,
};

const stepsWithListView = [
  SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PROFILE_STORES,
  SET_SUPPLIER_PROFILE_MODAL_STEPS.SELECT_STORE_TO_CLONE,
  SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PRODUCTS,
];

const getSupplierProfileModalStepTitle = (props) => {
  const {
    clientStoreName,
    clientStoreNamePlural,
    supplierProfile,
    actionType,
    currentStep,
    supplierProfileInformationsData,
    supplier,
  } = props;

  const supplierProfileInCreation = get(supplierProfileInformationsData, 'supplierProfile', {});
  const supplierProfileInCreationName = get(supplierProfileInCreation, 'name', '');

  const initialSupplierProfileName = get(supplierProfile, 'name', ''); // add store and clone case

  if (currentStep === SET_SUPPLIER_PROFILE_MODAL_STEPS.SET_SUPPLIER_PROFILE_INFORMATIONS) {
    return actionType === SUPPLIER_PROFILE_SETTING_ACTIONS.CREATE_SUPPLIER_PROFILE
      ? i18next.t(
          supplier.isKitchen
            ? 'ADMIN.SUPPLIERS.CREATION_MODAL_TITLE_KITCHEN_SUPPLIER'
            : 'ADMIN.SUPPLIERS.CREATION_MODAL_TITLE',
        )
      : initialSupplierProfileName;
  }

  if (currentStep === SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PROFILE_STORES) {
    return i18next.t('ADMIN.SUPPLIERS.STORES_ASSOCIATION_MODAL_TITLE', {
      supplierProfileName:
        actionType !== SUPPLIER_PROFILE_SETTING_ACTIONS.CREATE_SUPPLIER_PROFILE &&
        actionType !== SUPPLIER_PROFILE_SETTING_ACTIONS.DUPLICATE_SUPPLIER_PROFILE
          ? initialSupplierProfileName
          : supplierProfileInCreationName,
      storeName: clientStoreNamePlural,
    });
  }

  if (currentStep === SET_SUPPLIER_PROFILE_MODAL_STEPS.ASK_SUPPLIER_PRODUCTS_MAPPING) {
    return i18next.t('ADMIN.SUPPLIERS.ASK_SUPPLIER_PRODUCTS_MAPPING_MODAL_TITLE');
  }

  if (currentStep === SET_SUPPLIER_PROFILE_MODAL_STEPS.SELECT_STORE_TO_CLONE) {
    return i18next.t('ADMIN.SUPPLIERS.SELECT_STORE_CLONE_MODAL_TITLE', {
      storeName: getClientStoreNameTranslation(clientStoreName).toLowerCase(),
    });
  }

  if (currentStep === SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PRODUCTS) {
    return i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PRODUCTS_ASSOCIATION_MODAL_TITLE', {
      storeName: clientStoreNamePlural,
      count: 2,
    });
  }

  if (currentStep === SET_SUPPLIER_PROFILE_MODAL_STEPS.SET_SUPPLIER_PRODUCTS_PROPERTIES) {
    return i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PRODUCTS_PROPERTIES_MODAL_TITLE');
  }
};

export const handleDeliveryOptionsValidation = (deliveryOptions) => {
  let isValid = true;
  let hasDouble = false;
  let deliveryDays = [];

  deliveryOptions.forEach((delivery) => {
    const deliveryDay = get(delivery, 'deliveryDay', '');
    if (deliveryDay === '') {
      isValid = false;
    } else {
      if (deliveryDays.includes(deliveryDay)) {
        isValid = false;
        hasDouble = true;
      }
      deliveryDays.push(deliveryDay);
    }
  });
  return {
    isValid,
    message: hasDouble ? i18next.t('ADMIN.SUPPLIERS.UNIQUE_DELIVERY_DAYS') : '',
  };
};

const handleSupplierProfileInformationsValidation = (
  supplierProfileInformationsData,
  clientHasMultipleTimezones,
) => {
  const supplierProfile = get(supplierProfileInformationsData, 'supplierProfile', {});

  const deliveryOptions = get(supplierProfileInformationsData, 'deliveryOptions', []);
  const checkDeliveryOptions = handleDeliveryOptionsValidation(deliveryOptions);
  const deliveryOptionsAreValid = get(checkDeliveryOptions, 'isValid', false);

  const supplierProfileName = get(supplierProfile, 'name', '');
  const supplierProfileCountry = get(supplierProfile, 'country', '');
  const supplierProfileTimezone = get(supplierProfile, 'timezone', '');

  if (clientHasMultipleTimezones) {
    return (
      !isEmpty(supplierProfileName) &&
      deliveryOptionsAreValid &&
      !isEmpty(supplierProfileCountry) &&
      !isEmpty(supplierProfileTimezone)
    );
  }

  return !isEmpty(supplierProfileName) && deliveryOptionsAreValid;
};

const getSupplierProfileModalStepActions = (props) => {
  const {
    supplierProfileInformationsData,
    currentStep,
    setCurrentStep,
    resetModal,
    storeToCloneData,
    actionType,
    selectedStoreAssociations,
    selectedSupplierProductAssociations,
    includeSelectStoreToCloneStep,
    handleSupplierProfileSaving,
    availableStoresToClone,
    clientHasMultipleTimezones,
  } = props;

  if (currentStep === SET_SUPPLIER_PROFILE_MODAL_STEPS.SET_SUPPLIER_PROFILE_INFORMATIONS) {
    return [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_NEXT_BUTTON(),
        preventClosing: true,
        isDisabled: !handleSupplierProfileInformationsValidation(
          supplierProfileInformationsData,
          clientHasMultipleTimezones,
        ),
        handleClick: async () => {
          setCurrentStep(SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PROFILE_STORES);
        },
      },
    ];
  }

  if (currentStep === SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PROFILE_STORES) {
    const nextStep = !isEmpty(availableStoresToClone)
      ? SET_SUPPLIER_PROFILE_MODAL_STEPS.ASK_SUPPLIER_PRODUCTS_MAPPING
      : SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PRODUCTS;

    if (actionType === SUPPLIER_PROFILE_SETTING_ACTIONS.ASSOCIATE_SUPPLIER_PROFILE_STORES) {
      return [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_NEXT_BUTTON(),
          preventClosing: true,
          isDisabled: isEmpty(selectedStoreAssociations),
          handleClick: async () => setCurrentStep(nextStep),
        },
      ];
    }

    if (isEmpty(selectedStoreAssociations)) {
      return [
        {
          ...GENERIC_MODAL_BACK_BUTTON(),
          preventClosing: true,
          handleClick: () =>
            setCurrentStep(SET_SUPPLIER_PROFILE_MODAL_STEPS.SET_SUPPLIER_PROFILE_INFORMATIONS),
        },
        {
          ...GENERIC_MODAL_SAVE_BUTTON(),
          handleClick: async () => {
            handleSupplierProfileSaving();
            resetModal();
          },
        },
      ];
    }

    return [
      {
        ...GENERIC_MODAL_BACK_BUTTON(),
        preventClosing: true,
        handleClick: () =>
          setCurrentStep(SET_SUPPLIER_PROFILE_MODAL_STEPS.SET_SUPPLIER_PROFILE_INFORMATIONS),
      },
      {
        ...GENERIC_MODAL_NEXT_BUTTON(),
        preventClosing: true,
        isDisabled: isEmpty(selectedStoreAssociations),
        handleClick: async () => {
          setCurrentStep(nextStep);
        },
      },
    ];
  }

  if (currentStep === SET_SUPPLIER_PROFILE_MODAL_STEPS.ASK_SUPPLIER_PRODUCTS_MAPPING) {
    return [
      {
        ...GENERIC_MODAL_BACK_BUTTON(),
        preventClosing: true,
        handleClick: () =>
          setCurrentStep(SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PROFILE_STORES),
      },
      {
        ...GENERIC_MODAL_NEXT_BUTTON(),
        preventClosing: true,
        isDisabled: false,
        handleClick: async () => {
          const nextStep = includeSelectStoreToCloneStep
            ? SET_SUPPLIER_PROFILE_MODAL_STEPS.SELECT_STORE_TO_CLONE
            : SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PRODUCTS;

          setCurrentStep(nextStep);
        },
      },
    ];
  }

  if (currentStep === SET_SUPPLIER_PROFILE_MODAL_STEPS.SELECT_STORE_TO_CLONE) {
    return [
      {
        ...GENERIC_MODAL_BACK_BUTTON(),
        preventClosing: true,
        handleClick: () =>
          setCurrentStep(SET_SUPPLIER_PROFILE_MODAL_STEPS.ASK_SUPPLIER_PRODUCTS_MAPPING),
      },
      {
        ...GENERIC_MODAL_SAVE_BUTTON(),
        preventClosing: true,
        isDisabled: isEmpty(storeToCloneData),
        handleClick: async () => {
          handleSupplierProfileSaving();
          resetModal();
        },
      },
    ];
  }

  if (currentStep === SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PRODUCTS) {
    const prevStep = !isEmpty(availableStoresToClone)
      ? SET_SUPPLIER_PROFILE_MODAL_STEPS.ASK_SUPPLIER_PRODUCTS_MAPPING
      : SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PROFILE_STORES;

    if (isEmpty(selectedSupplierProductAssociations)) {
      return [
        {
          ...GENERIC_MODAL_BACK_BUTTON(),
          preventClosing: true,
          handleClick: () => {
            setCurrentStep(prevStep);
          },
        },
        {
          ...GENERIC_MODAL_SAVE_BUTTON(),
          preventClosing: true,
          handleClick: async () => {
            handleSupplierProfileSaving();
            resetModal();
          },
        },
      ];
    }

    return [
      {
        ...GENERIC_MODAL_BACK_BUTTON(),
        preventClosing: true,
        handleClick: () => {
          setCurrentStep(prevStep);
        },
      },
      {
        ...GENERIC_MODAL_NEXT_BUTTON(),
        preventClosing: true,
        handleClick: async () => {
          setCurrentStep(SET_SUPPLIER_PROFILE_MODAL_STEPS.SET_SUPPLIER_PRODUCTS_PROPERTIES);
        },
      },
    ];
  }

  if (currentStep === SET_SUPPLIER_PROFILE_MODAL_STEPS.SET_SUPPLIER_PRODUCTS_PROPERTIES) {
    return [
      {
        ...GENERIC_MODAL_BACK_BUTTON(),
        preventClosing: true,
        handleClick: () => {
          const prevStep =
            includeSelectStoreToCloneStep && !isEmpty(availableStoresToClone)
              ? SET_SUPPLIER_PROFILE_MODAL_STEPS.SELECT_STORE_TO_CLONE
              : SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PRODUCTS;

          setCurrentStep(prevStep);
        },
      },
      {
        ...GENERIC_MODAL_SAVE_BUTTON(),
        preventClosing: true,
        isDisabled: false,
        handleClick: async () => {
          handleSupplierProfileSaving();
          resetModal();
        },
      },
    ];
  }
};

const getModalCustomPadding = (currentStep) => {
  if (currentStep === SET_SUPPLIER_PROFILE_MODAL_STEPS.SET_SUPPLIER_PROFILE_INFORMATIONS) {
    return '0px';
  }

  if (stepsWithListView.includes(currentStep)) {
    return '24px 24px 0px 24px';
  }

  return null;
};

export const getSetSupplierProfileModalConfig = (props) => {
  const { actionType, currentStep, resetModal } = props;

  const params = {
    type: 'actionWhite',
    isFullscreen: true,
    title: getSupplierProfileModalStepTitle(props),
    handleCloseCleanUp: resetModal,
    actions: getSupplierProfileModalStepActions(props),
    component: setSupplierProfileModalComponents[currentStep],
    data: props,
    componentOverflow: stepsWithListView.includes(currentStep) ? 'hidden' : 'auto',
    step: {
      total:
        actionType === SUPPLIER_PROFILE_SETTING_ACTIONS.ASSOCIATE_SUPPLIER_PROFILE_STORES ? 2 : 3,
      current:
        actionType === SUPPLIER_PROFILE_SETTING_ACTIONS.ASSOCIATE_SUPPLIER_PROFILE_STORES
          ? associateStoresModalStepNumber[currentStep]
          : createOrDuplicateModalStepNumber[currentStep],
    },
    customPadding: getModalCustomPadding(currentStep),
  };

  return params;
};
