import i18next from 'i18next';

const rootPath = '/admin/inventories/inventory-list-templates';

export const DETAILS = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: () => i18next.t('ADMIN.SUB_FEATURES.INFORMATIONS'),
  path: `${rootPath}/:id/details`,
  getLink: ({ inventoryListTemplate: { id } }) => `${rootPath}/${id}/details`,
};
export const SUPPLIER_PRODUCTS = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: () => i18next.t('GENERAL.SUPPLIER_PRODUCT_PLURAL'),
  path: `${rootPath}/:id/supplier-products`,
  getLink: ({ inventoryListTemplate: { id } }) => `${rootPath}/${id}/supplier-products`,
};

export const RECIPES = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: () => i18next.t('GENERAL.RECIPE_PLURAL'),
  path: `${rootPath}/:id/recipes`,
  getLink: ({ inventoryListTemplate: { id } }) => `${rootPath}/${id}/recipes`,
};

export const INVENTORY_LIST_TEMPLATE_CREATION = {
  isFullScreen: true,
  path: `${rootPath}/create`,
  isVisible: () => false,
};
