import i18next from 'i18next';

import { getClientStoreNameTranslation } from '../../../utils/translations';

export const DETAILS = {
  shouldReplacePath: true,
  getText: () => i18next.t('ADMIN.SUB_FEATURES.INFORMATIONS'),
  path: '/users/:id/details',
  getLink: ({ profileUser: { id } }) => `/users/${id}/details`,
};

export const STORES = {
  shouldReplacePath: true,
  getText: ({ storeName }) =>
    i18next.t('ADMIN.SUB_FEATURES.MAPPED_STORES', {
      storeNamePlural: getClientStoreNameTranslation(storeName, true),
    }),
  path: '/users/:id/details/stores',
  getLink: ({ profileUser: { id } }) => `/users/${id}/details/stores`,
};
