import { get } from 'lodash';
import i18next from 'i18next';
import React from 'react';

// Design
import {
  BeginningThread,
  ChangelogContainer,
  Container,
  EndingThread,
  Thread,
} from './styledComponents';

// Common
import { AUDIT_EVENTS_CONFIG } from '@commons/HistoryDrawer/components/History/utils/auditEvents';
import Changelog from '@commons/HistoryDrawer/components/Changelog/Changelog';

/**
 * @typedef {Object} AccountAuditLogsRel
 * @property {string} id - Unique identifier of the Account
 * @property {string|undefined} firstName - First name (if provided)
 * @property {string|undefined} lastName - Last name (if provided)
 * @property {string} email - Email
 */

/**
 * @typedef {Object} AuditLog
 * @property {string} id - Unique identifier of the audit log
 * @property {string} schemaName - Schema name
 * @property {string} tableName - Table name
 * @property {AccountAuditLogsRel|undefined} lnkAccountAuditLogsrel - Account related to the audit log
 * @property {string} targetId - Unique Identifier of the target
 * @property {string} userId - User ID
 * @property {string} event - Predefined static event
 * @property {string} actionTimestamp - When the action was performed
 * @property {string} action - Type of action
 */

/**
 *
 * @param {{auditLogs: AuditLog[]}} props
 *
 * @return {Element}
 */
const History = (props) => {
  const { auditLogs } = props;

  const formattedAuditLogs = auditLogs
    .map(({ id, lnkAccountAuditLogsrel: account, actionTimestamp, event }) => {
      const [nameFromEmail] = get(account, 'email', '').split('@');
      const firstName = get(account, 'firstName', '');
      const lastName = get(account, 'lastName', '');
      const author = firstName || lastName ? `${firstName} ${lastName}` : nameFromEmail;

      const eventConfig = AUDIT_EVENTS_CONFIG[event];
      const eventLabel = eventConfig ? i18next.t(eventConfig.label) : '';
      const eventIcon = eventConfig ? eventConfig.icon : '';

      return {
        id: id,
        author,
        datetime: actionTimestamp,
        event: eventLabel,
        icon: eventIcon,
      };
    })
    .sort(({ datetime: datetimeA }, { datetime: datetimeB }) => (datetimeA > datetimeB ? -1 : 1));

  return (
    <Container>
      {formattedAuditLogs.map(({ id, author, datetime, event, icon }, index) => (
        <ChangelogContainer key={id}>
          {index === 0 && <BeginningThread />}

          <Changelog author={author} datetime={datetime} event={event} icon={icon} />
          {index < formattedAuditLogs.length - 1 && <Thread />}

          {index === formattedAuditLogs.length - 1 && <EndingThread />}
        </ChangelogContainer>
      ))}
    </Container>
  );
};

export default History;
