export const SYNC_STATE = {
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  STARTED: 'STARTED',
  RETRY: 'RETRY',
  FAILURE: 'FAILURE',
  REVOKED: 'REVOKED',
};

export default {
  SYNC_STATE,
};
