import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { Component } from 'react';

import {
  getSupplierProfilesOfStore,
  receiveSupplierProfilesOfStore,
  requestSupplierProfilesOfStoreError,
} from '@actions/supplierProfile';
import { openMediumModal } from '@actions/modal';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';
import utilsXLS from '@commons/utils/makeXLS';

import { ENUM_QUERY_PARAMS, withListViewQueryParamHookHOC } from '@hooks/useListViewQueryParams';

import DeepsightFiltersButton from '@admin/components/FilterButton';
import EmptyState from '@admin/suppliers/suppliers/components/EmptyState';

import { Text } from './styledComponents';
import StoreSupplierProfileDetails from './StoreSupplierProfileDetails';

export class StoreSupplierProfileMappings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplierProfiles: [],
      selectedSuppliers: [],
      filteredSupplierProfilesList: [],
      isLoading: true,
      vueTitle: i18next.t('ADMIN.STORES.LIST_SHEET_TITLE_SUPPLIERS'),
      vueExportName: i18next.t('ADMIN.STORES.LIST_SHEET_TITLE_SUPPLIERS'),
      storeName: '',
      dataTitles: [
        {
          id: 'name',
          baseName: 'name',
          propertyKey: 'name',
          name: i18next.t('ADMIN.STORES.LIST_COLUMN_NAME'),
          displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_NAME'),
          render: (it) => <Text highlighted>{it}</Text>,
        },
        {
          id: 'supplierName',
          baseName: 'supplierName',
          propertyKey: 'supplierName',
          name: i18next.t('ADMIN.STORES.LIST_COLUMN_SUPPLIER'),
          displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_SUPPLIER'),
          filterType: 'string',
          excludeFromSearch: true,
          render: (it) => this.renderText(it),
        },
        {
          id: 'city',
          baseName: 'city',
          propertyKey: 'city',
          name: i18next.t('ADMIN.STORES.LIST_COLUMN_CITY'),
          displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_CITY'),
          filterType: 'string',
          excludeFromSearch: true,
          filterStringAllowNone: true,
          render: (it) => this.renderText(it),
        },
        {
          id: 'country',
          baseName: 'country',
          propertyKey: 'country',
          name: i18next.t('ADMIN.STORES.LIST_COLUMN_COUNTRY'),
          displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_COUNTRY'),
          filterType: 'string',
          excludeFromSearch: true,
          filterStringAllowNone: true,
          render: (it) => this.renderText(it),
        },
      ],
      actions: [],
      filters: null,
      applyFilters: true,
      advancedFilters: null,
      columnsFilterList: [],
    };
  }

  renderText = (item) => <Text>{item}</Text>;

  handleStoreSupplierProfileDetails = (supplierProfile) => {
    const params = {
      component: StoreSupplierProfileDetails,
      history: this.props.history,
      supplierProfile,
    };
    this.props.openModal(params);
  };

  preprocessSupplierProfiles(supplierProfiles) {
    const formattedSupplierProfiles = supplierProfiles.map((supplierProfile) => {
      supplierProfile.name = (supplierProfile.name || '').trim();
      supplierProfile.supplierName = supplierProfile.lnkSupplierSupplierprofilerel.name;
      supplierProfile.city = supplierProfile.lnkSupplierSupplierprofilerel.city || '';
      supplierProfile.country = supplierProfile.lnkSupplierSupplierprofilerel.country || '';

      return supplierProfile;
    });
    this.setState({ supplierProfiles: formattedSupplierProfiles });
    this.processAdvancedFilter(formattedSupplierProfiles);
    this.updateColumnsFilterList(formattedSupplierProfiles);
    setTimeout(() => this.setState({ isLoading: false }), 1);
  }

  componentDidMount() {
    this.setState({ storeId: this.props.storeParams.id });
    this.updateListActions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.storeParams !== this.props.storeParams) {
      this.setState({ storeId: this.props.storeParams.id });
    }

    if (prevState.storeId !== this.state.storeId) {
      this.props.getSupplierProfilesOfStore([this.state.storeId]);
    }

    if (prevProps.supplierProfiles !== this.props.supplierProfiles) {
      this.preprocessSupplierProfiles(this.props.supplierProfiles);
    }

    if (
      prevState.advancedFilters !== this.state.advancedFilters ||
      prevState.applyFilters !== this.state.applyFilters
    ) {
      this.processAdvancedFilter(this.props.supplierProfiles);
    }
  }

  processAdvancedFilter = (supplierProfiles) => {
    if (!this.state.applyFilters) {
      return;
    }

    const filteredSupplierProfilesList = supplierProfiles;

    if (
      (!this.state.advancedFilters || !this.state.advancedFilters.length) &&
      this.state.applyFilters
    ) {
      this.setState({ filteredSupplierProfilesList });
      return;
    }

    const filteredSupplierProfilesListWithAdvancedFilters = this.state.advancedFilters.reduce(
      (result, { doFilter, list, filterType, propertyKey, value }) => {
        if (filterType === 'string' && value && value.length === 1 && value[0].id === 'none') {
          return doFilter(result, propertyKey, list, true);
        }

        return doFilter(result, propertyKey, value);
      },
      filteredSupplierProfilesList,
    );

    this.setState({
      filteredSupplierProfilesList: filteredSupplierProfilesListWithAdvancedFilters,
    });
  };

  updateColumnsFilterList = (suppliers) => {
    const columnsList = this.state.dataTitles.filter((column) =>
      ['string'].includes(column.filterType),
    );

    const updatedColumnsList = columnsList.reduce((result, column) => {
      const updatedColumn = { ...column };

      if (['supplierName', 'city', 'country'].includes(column.propertyKey)) {
        const list = Object.values(
          suppliers.reduce((result, supplier, key) => {
            if (supplier[column.propertyKey]) {
              result[supplier[column.propertyKey]] = {
                id: key,
                name: supplier[column.propertyKey],
                status: supplier[column.propertyKey],
                value: supplier[column.propertyKey],
                [column.propertyKey]: supplier[column.propertyKey],
              };
            }

            return result;
          }, {}),
        );

        updatedColumn.list = sortArrayOfObjectsAlphabetically(list, 'name');
      }

      result.push(updatedColumn);

      return result;
    }, []);

    return this.setState({ columnsFilterList: updatedColumnsList });
  };

  handleExport = (data) => {
    const { vueTitle, vueExportName, dataTitles } = this.state;

    const { storeParams } = this.props;

    const sheetName = `${vueExportName}_${storeParams.name}`;

    const sheet = utilsXLS.generateDefaultSheet(sheetName, dataTitles, data);

    utilsXLS.makeXLS(vueTitle, [sheet]);
  };

  updateListActions = () => {
    const actions = [
      {
        id: 'export',
        displayActionInLine: false,
        actionLabel: i18next.t('GENERAL.EXPORT'),
        handleAction: () => this.handleExport(this.state.filteredSupplierProfilesList),
        render: this.renderExport,
      },
    ];

    this.setState({ actions });
  };

  renderExport = () => {
    const { selectedSuppliers, filteredSupplierProfilesList } = this.state;

    const data = selectedSuppliers.length ? selectedSuppliers : filteredSupplierProfilesList;

    return (
      <Button
        buttonCustomStyle={{ width: 'fit-content', minWidth: 110, fontWeight: 600 }}
        color={'blue-outline-no-shadow'}
        fontSize={14}
        formatText={false}
        handleClick={() => this.handleExport(data)}
        icon={'/images/inpulse/file-download-black-small.svg'}
        iconCustomStyle={{ width: '18px', height: '18px' }}
        label={i18next.t('GENERAL.EXPORT')}
      />
    );
  };

  render() {
    const {
      filters,
      actions,
      isLoading,
      dataTitles,
      applyFilters,
      advancedFilters,
      columnsFilterList,
      filteredSupplierProfilesList,
    } = this.state;

    const { listViewQueryParams, setListViewQueryParams } = this.props;

    const userLanguageCode = _.get(this.props.user, 'lnkLanguageAccountrel.code', 'fr');

    return (
      <ListView
        actionOnClick={(supplier) => this.handleStoreSupplierProfileDetails(supplier)}
        actions={actions}
        columns={dataTitles}
        data={filteredSupplierProfilesList}
        defaultCurrentPage={listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE]}
        defaultMaxPerPage={listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]}
        defaultOrderBy={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]}
        defaultOrderType={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]}
        defaultSearchInput={listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH]}
        handleCurrentPageChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](input)
        }
        handleMaxPerPageChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE](input)
        }
        handleOrderByChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY](input)}
        handleOrderTypeChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE](input)
        }
        handleSearchInputChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.SEARCH](input)}
        isLoading={isLoading}
        languageCode={userLanguageCode}
        padding={'108px 24px 0px 24px'}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => {
          if (!columnsFilterList || !columnsFilterList.length) {
            return null;
          }

          return (
            <DeepsightFiltersButton
              advancedFilters={advancedFilters}
              applyFilters={applyFilters}
              columnsFilterList={columnsFilterList}
              filters={filters}
              isLoading={isLoading}
              minWidth={120}
              readOnly={isLoading}
              setAdvancedFilters={(it) => {
                this.setState({ advancedFilters: it });
              }}
              setApplyFilters={(it) => this.setState({ applyFilters: it })}
              setFilters={(it) => this.setState({ filters: it })}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
            />
          );
        }}
        setSelectedItems={(items) =>
          this.setState({
            selectedSuppliers: items,
          })
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  supplierProfiles: state.baseReducer.supplierProfiles,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
  getSupplierProfilesOfStore: (storeId) =>
    dispatch(getSupplierProfilesOfStore(storeId)).then(
      (result) => dispatch(receiveSupplierProfilesOfStore(result)),
      (error) => dispatch(requestSupplierProfilesOfStoreError(error)),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withListViewQueryParamHookHOC(StoreSupplierProfileMappings));
