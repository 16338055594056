import * as React from 'react';
import i18next from 'i18next';

import { Divider, ListItemText, MenuItem, Stack } from '@mui/material';
import { GridColumnMenuFilterItem, GridColumnMenuPinningItem } from '@mui/x-data-grid-pro';

import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';

import { MenuIcon } from '../styledComponents';

const CONSUMPTION_ICON_BLACK = '/images/inpulse/bolt-black-small.svg';
const ERASE_ICON_BLACK = '/images/inpulse/trash-black.svg';

const PINNED_FIELDS = ['name', 'category', 'yesterdayStock', 'consumption', 'total'];

const ColumnMenu = (props) => {
  const { colDef, hideMenu, handleUpdateColumn } = props;

  const pinnedItemProps = {
    colDef,
    onClick: hideMenu,
  };

  return (
    <>
      {PINNED_FIELDS.includes(colDef.field) && (
        <Stack>
          <GridColumnMenuPinningItem {...pinnedItemProps} />
          <Divider />
          <GridColumnMenuFilterItem {...pinnedItemProps} />
        </Stack>
      )}
      {/** Replace MUI Datagrid menu items by custom menu items for production date columns */}
      {!PINNED_FIELDS.includes(colDef.field) ? (
        <Stack width={INPUT_WIDTH.LARGE}>
          <MenuItem
            color="primary"
            onClick={() => {
              handleUpdateColumn(colDef.field, false);
              hideMenu();
            }}
          >
            <MenuIcon src={CONSUMPTION_ICON_BLACK} />
            <ListItemText>
              {i18next.t(
                'PRODUCTION.CENTRAL_KITCHEN.PLANNING_PRODUCTION_MENU_ITEM_COMPLETE_WITH_CONSUMPTION',
              )}
            </ListItemText>
          </MenuItem>
          <MenuItem
            color="primary"
            onClick={() => {
              handleUpdateColumn(colDef.field, true);
              hideMenu();
            }}
          >
            <MenuIcon src={ERASE_ICON_BLACK} />
            <ListItemText>
              {i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_PRODUCTION_MENU_ITEM_DELETE_DATA')}
            </ListItemText>
          </MenuItem>
        </Stack>
      ) : null}
    </>
  );
};

export default ColumnMenu;
