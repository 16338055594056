import { ChartDataset, ChartOptions } from 'chart.js';

import theme, { getTheme } from '../../utils/theme';
import { externalTooltipHandler } from './tooltipHandler';

const updatedTheme = getTheme(theme);

export const DEFAULT_LINE_CHART_DATASET: ChartDataset<'line'> = {
  borderColor: updatedTheme.colors.brand.primary,
  spanGaps: true,
  fill: true,
  data: [],
  cubicInterpolationMode: 'monotone',
  tension: 0,
  // pointRadius to 0 to not display dot on chart because we can't install v4+ of chartJs
  pointRadius: 0,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: updatedTheme.colors.greys.darkest,
  gradient: {
    // Do the same thing with borderColor property
    // to have gradient on chart line and only on background
    backgroundColor: {
      axis: 'y',
      colors: {
        // First implementation of gradient for HV
        // rgba calculated from theme.colors.brand.primary
        0: 'rgba(38, 232, 136, 0)',
        100: 'rgba(38, 232, 136, 0.24)',
      },
    },
  },
};

export const getStandardLineChartOptions = (
  startDate: string,
  endDate: string,
  timezone: string,
  tooltipTitlePropertyKey?: string,
  renderTooltipHeader?: Function,
  renderTooltipBody?: Function,
  xAxisPropertyKey?: string,
  tooltipMinWidth?: string
): ChartOptions<'line'> => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxis: {
        type: 'linear',
        grace: '20%',
        min: 0,
        grid: {
          drawBorder: false,
          color: updatedTheme.colors.greys.light,
          tickLength: 0,
        },
        ticks: {
          font: {
            size: 10,
            family: 'inter',
          },
          color: updatedTheme.colors.greys.dark,
        },
      },
      xAxis: {
        grid: {
          display: false,
        },
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'DD/MM',
          },
        },
        ticks: {
          source: 'labels',
          font: {
            size: 10,
            family: 'inter',
          },
          color: updatedTheme.colors.greys.dark,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        mode: 'index',
        position: 'nearest',
        intersect: false,
        axis: 'xy',
        external: (context) =>
          externalTooltipHandler(
            context,
            startDate,
            endDate,
            timezone,
            renderTooltipHeader,
            renderTooltipBody,
            tooltipTitlePropertyKey,
            xAxisPropertyKey,
            tooltipMinWidth
          ),
      },
    },
    interaction: {
      intersect: false,
      mode: 'nearest',
      axis: 'xy',
    },
    hover: {
      mode: 'nearest',
      intersect: false,
      axis: 'xy',
    },
    // TODO: remove when status check is removed
    // TODO: (currently every check causes a re-render, which is made clear because of the animation)
    animation: false,
  };
};
export const DEFAULT_LINE_CHART_PLUGINS = [];
export default {
  DEFAULT_LINE_CHART_DATASET,
  DEFAULT_LINE_CHART_PLUGINS,
  getStandardLineChartOptions,
};
