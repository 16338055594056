import axios from '../core/http';

import { config } from '../config';

const baseApiUrl = config.baseApiUrl;

function getforecastSupplierProductsOfStore(storeId, supplierId, startDate, endDate) {
  return axios
    .get(`${baseApiUrl}/admin/stores/${storeId}/suppliers/${supplierId}/forecastSupplierProducts`, {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const forecastSupplierProducts = {
  getforecastSupplierProductsOfStore,
};
