import { ENUM_MODULE_NAME, getFeaturesByPath } from '../../../commons/utils/features';
import React, { useEffect } from 'react';

const MODULE_FEATURES_PATH_ORDERED = [
  '/backoffice/onboarding/products',
  '/backoffice/onboarding/stores',
  '/backoffice/onboarding/data-model',
  '/backoffice/onboarding/accounts',
];

const BackOfficeOnboardingContainer = (props) => {
  const { userRights, history } = props;

  useEffect(() => {
    const features = getFeaturesByPath(userRights, ENUM_MODULE_NAME.BACKOFFICE);
    if (features.length) {
      const redirectionPath = MODULE_FEATURES_PATH_ORDERED.find((featurePath) =>
        features.some((feature) => feature.path === featurePath),
      );
      history.push(redirectionPath);
    }
  }, []);

  return <div></div>;
};

// ===== Module features for easy import in ClientContainer =====

import BackOfficeAccountCreation from './BackOfficeAccountCreation';
import BackOfficeDataModel from './DataModel';
import BackOfficeProductsAssociation from './BackOfficeProductsAssociation';
import BackOfficeStoresCreation from './BackOfficeStoresCreation';

const BackOfficeOnboardingModules = {
  BackOfficeDataModel,
  BackOfficeStoresCreation,
  BackOfficeAccountCreation,
  BackOfficeProductsAssociation,
  BackOfficeOnboardingContainer,
};

export default BackOfficeOnboardingModules;
