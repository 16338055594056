import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

import theme from '@theme';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { Label } from '@commons/utils/styledLibraryComponents';
import DisplayNumber from '@commons/DisplayNumber';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { LabelContainer } from '../styledComponents';

export const getColumns = (hasMultipleServices) => {
  const columns = [
    {
      id: 'name',
      propertyKey: 'name',
      name: i18next.t('GENERAL.PRODUCT'),
      render: (item) => (
        <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_MIDDLE_BOLD}>
          {item}
        </Text>
      ),
    },
    {
      id: 'category',
      propertyKey: 'category',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_CATEGORY'),
      filterType: 'string',
      filterStringAllowNone: true,
      render: (item) => (
        <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG}>
          {item || '-'}
        </Text>
      ),
    },
    {
      id: 'subCategory',
      propertyKey: 'subCategory',
      name: i18next.t('GENERAL.SUB_CATEGORY'),
      filterType: 'string',
      filterStringAllowNone: true,
      render: (item) => (
        <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG}>
          {item || '-'}
        </Text>
      ),
    },
    {
      id: 'price',
      type: 'currency',
      propertyKey: 'price',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_PRICE_TAX_INCLUDED'),
      filterType: 'numeric',
      excludeFromSearch: true,
      render: (item) => <DisplayNumber number={item} displayCurrencyCode />,
    },
    {
      id: 'launchDate',
      propertyKey: 'launchDate',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_LAUNCH_DATE'),
      filterType: 'date',
      excludeFromSearch: true,
      render: (item) => (
        <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG}>
          {item
            ? moment(item).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)
            : i18next.t('GENERAL.SELECT_NONE_FEMININE')}
        </Text>
      ),
    },
    {
      id: 'endDate',
      propertyKey: 'endDate',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_END_DATE'),
      filterType: 'date',
      excludeFromSearch: true,
      render: (item) => (
        <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG}>
          {item
            ? moment(item).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)
            : i18next.t('GENERAL.SELECT_NONE_FEMININE')}
        </Text>
      ),
    },
    {
      id: 'hasStock',
      propertyKey: 'hasStock',
      name: i18next.t('ADMIN.STORES.STOCKS_COLUMN'),
      filterType: 'boolean',
      excludeFromSearch: true,
      list: [
        {
          id: 0,
          itemValue: true,
          name: i18next.t('GENERAL.YES'),
          value: i18next.t('GENERAL.YES'),
        },
        {
          id: 1,
          itemValue: false,
          name: i18next.t('GENERAL.NO'),
          value: i18next.t('GENERAL.NO'),
        },
      ],
      render: (item) => (
        <LabelContainer>
          <Label
            background={item ? theme.colors.infoGreen : theme.colors.infoRed}
            color={item ? theme.colors.infoGreen : theme.colors.infoRed}
            type={null}
            width={'40px'}
          >
            {i18next.t(item ? 'GENERAL.YES' : 'GENERAL.NO')}
          </Label>
        </LabelContainer>
      ),
    },
  ];

  if (!hasMultipleServices) {
    columns.push({
      id: 'min',
      propertyKey: 'min',
      filterType: 'numeric',
      excludeFromSearch: true,
      name: i18next.t('ADMIN.STORES.MINIMUM_COLUMN'),
      render: (item) => <DisplayNumber number={item} withoutDecimals />,
    });

    return columns;
  }

  columns.push(
    {
      id: 'minNoon',
      propertyKey: 'minNoon',
      filterType: 'numeric',
      excludeFromSearch: true,
      name: i18next.t('ADMIN.STORES.MINIMUM_COLUMN_NOON'),
      render: (item) => <DisplayNumber number={item} withoutDecimals />,
    },
    {
      id: 'minEvening',
      propertyKey: 'minEvening',
      filterType: 'numeric',
      excludeFromSearch: true,
      name: i18next.t('ADMIN.STORES.MINIMUM_COLUMN_EVENING'),
      render: (item) => <DisplayNumber number={item} withoutDecimals />,
    },
  );

  return columns;
};
