import { ENUM_QUERY_PARAMS, DEFAULT_QUERY_PARAMS } from '@hooks/useListViewQueryParams';

const ORDER_TYPE = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

const getPropsListView = ({
  count,
  listViewRef,
  onQueryParamsChange,
  listViewQueryParams,
  setListViewQueryParams,
}) => ({
  ref: listViewRef,
  onQueryParamsChange,
  countElements: count,
  hideAllPerPageOption: true,
  defaultOrderBy: listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY],
  defaultSearchInput: listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH],
  defaultOrderType: listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE],
  defaultMaxPerPage: listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE],
  defaultCurrentPage: listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE],
  handleOrderByChange: setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY],
  handleSearchInputChange: setListViewQueryParams[ENUM_QUERY_PARAMS.SEARCH],
  handleOrderTypeChange: setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE],
  handleMaxPerPageChange: setListViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE],
  handleCurrentPageChange: setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE],
});

const getPayloadPagination = ({ columnPropertyKeys, listViewQueryParams }) => {
  const formattedMaxPerPage =
    Number.isInteger(listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]) &&
    listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE] > 0
      ? listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]
      : DEFAULT_QUERY_PARAMS.MAX_PER_PAGE;

  const formattedCurrentPage =
    listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE] > 0
      ? (listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE] - 1) *
        (listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE] || DEFAULT_QUERY_PARAMS.MAX_PER_PAGE)
      : (DEFAULT_QUERY_PARAMS.CURRENT_PAGE - 1) * DEFAULT_QUERY_PARAMS.MAX_PER_PAGE;

  const formattedOrderBy = columnPropertyKeys.includes(
    listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY],
  )
    ? listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]
    : columnPropertyKeys[0];

  const formattedOrderType = [ORDER_TYPE.ASCENDING, ORDER_TYPE.DESCENDING].includes(
    listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE],
  )
    ? listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]
    : DEFAULT_QUERY_PARAMS.ORDER_TYPE;

  const formattedSearch =
    listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH] || DEFAULT_QUERY_PARAMS.SEARCH;

  return {
    search: formattedSearch,
    skip: formattedCurrentPage,
    limit: formattedMaxPerPage,
    orderBy: formattedOrderBy,
    orderType: formattedOrderType,
  };
};

export default {
  getPropsListView,
  getPayloadPagination,
};
