import { isEmpty } from 'lodash';
import i18next from 'i18next';
import Proptypes from 'prop-types';
import React from 'react';

import { Checkbox } from '../utils/styledLibraryComponents';

import Text, { ENUM_COLORS, ENUM_FONTS } from '../Text';

import { ItemContainer, ListContainer } from './styledComponents';

/**
 * Utility function for CheckboxList, used to properly format the props for the component.
 *
 * @param {[]} items An array of object, each containing at least an id and a name
 * @param {[]} linkedData An array, preferably of ids, meant to be associated to the selected items.
 * @returns an object containing properly formatted props, ready to be used by the CheckboxList.
 */
export const formatItemsForCheckboxList = (items, linkedData = []) => {
  if (isEmpty(items)) {
    return {
      items: {
        [i18next.t('GENERAL.NONE_VALUE')]: { id: -1, checked: false },
      },
      names: [i18next.t('GENERAL.NONE_VALUE')],
      linkedData,
    };
  }

  const formattedItems = items.reduce((result, item) => {
    result[item.name] = { id: item.id, checked: !!item.checked };

    return result;
  }, {});

  return {
    items: formattedItems,
    names: Object.keys(formattedItems).sort(),
    linkedData,
  };
};

/**
 * This component renders a list of checkboxes, meant to be used as a multi-selection list,
 * allowing to associate a set of items to another.
 *
 * @param {{items: {}, names: [], linkedData: [], onChange: function}} props
 *  - items: an object, each key being the name of the item, its id, and whether it is checked or not
 *  - names: the names of each item, allowing to easily loop through them for rendering the list
 *  - linkedData: an array, preferably of ids, meant to be associated to the selected items
 *  - onChange: a function, called when an item is checked or unchecked, passing the updated items
 */
const CheckboxList = (props) => {
  const { items, names, linkedData, onChange } = props;

  const triggerOnChange = (name) => {
    items[name].checked = !items[name].checked;

    items.linkedData = linkedData;

    // The business logic is handled by the parent component.
    onChange(items);
  };

  if (names.includes(i18next.t('GENERAL.NONE_VALUE'))) {
    return (
      <ListContainer>
        <ItemContainer>
          <Checkbox isChecked={false} isDisabledLight={true} shape={'square'} noSize />
          <Text color={ENUM_COLORS.LIGHT} font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}>
            {i18next.t('GENERAL.NONE_VALUE')}
          </Text>
        </ItemContainer>
      </ListContainer>
    );
  }

  return (
    <ListContainer>
      {names.map((name) => (
        <ItemContainer key={items[name].id}>
          <Checkbox
            handleClick={() => triggerOnChange(name)}
            isChecked={items[name].checked}
            shape={'square'}
            noSize
          />
          <Text>{name}</Text>
        </ItemContainer>
      ))}
    </ListContainer>
  );
};

CheckboxList.propTypes = {
  items: Proptypes.shape({
    name: Proptypes.objectOf({
      id: Proptypes.string,
      checked: Proptypes.bool,
    }),
  }),
  names: Proptypes.arrayOf(Proptypes.string),
  linkedData: Proptypes.arrayOf(Proptypes.object),
  onChange: Proptypes.func,
};

CheckboxList.defaultProps = {
  items: {},
  names: [],
  linkedData: [],
};

export default CheckboxList;
