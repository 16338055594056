import { connect } from 'react-redux';
import { get, isEmpty, isEqual, omit } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import {
  openSlidingModal,
  openSmallModal,
  openGenericModal,
  refreshGenericModal,
  closeGenericModal,
} from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { formatNumber } from '@commons/DisplayNumber';
import { getTheme } from '@commons/utils/theme';
import { handleOpenedImportFileModal } from '@commons/Modals/ImportModal/utils/config';
import { IMPORT_STATE, IMPORT_TYPE, SWITCH_STEP } from '@commons/Modals/ImportModal/utils/constant';
import { isUserManager, isUserDeepsight } from '@commons/utils/roles';
import { ListView, Button } from '@commons/utils/styledLibraryComponents';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import EmptyState from '@commons/EmptyState';
import GeneralEmptyStateListView from '@commons/GeneralEmptyStateListView';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { IngredientContext } from '@context/IngredientContext';

import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { ingredient as ingredientService } from '@services/ingredient';
import clientService from '@services/client';

import { downloadFile, formatXlsFileToJson } from '@backoffice/utils';

import { CHOICES_DROPDOWN_ACTIVE } from '@admin/utils/DropdownItems';
import { STRATEGIC_INGREDIENT_DROPDOWN_ITEMS } from '@admin/utils/DropdownItems';
import DeepsightFiltersButton from '@admin/components/FilterButton';

import { formatEntityBatchCreationFile } from './utils/formatEntityBatchCreationFile';
import { getActions, getRowActions } from './utils/getActions';
import {
  getDeactivationWarningModal,
  getActivationWarningModal,
} from './utils/modalConfigurations';
import { ListViewContainer } from './styledComponents';
import getColumns from './utils/getColumns';
import IngredientExportModal from './components/IngredientExportModal';

const ENUM_CUSTOM_FILTERS = {
  IS_STRATEGIC: 'is-strategic',
  ACTIVE_INACTIVE_SUPPLIER_PRODUCTS: 'active-inactive-supplier-products',
};

moment.locale('fr');

export const Ingredients = (props) => {
  const {
    client: { clientId },
    user,
    match: { path },
    history,
    currency,
    openModalExportInfo,
    modal,
    openGenericModal,
    refreshGenericModal,
    closeGenericModal,
    pageLoaded,
    pageLoading,
    showErrorMessage,
    showSuccessMessage,
    authorizedActions,
  } = props;

  const {
    filters,
    setFilters,
    advancedFilters,
    setAdvancedFilters,
    search,
    setSearch,
    orderBy,
    setOrderBy,
    orderType,
    setOrderType,
    maxPerPage,
    setMaxPerPage,
    currentPage,
    setCurrentPage,
  } = useContext(IngredientContext);

  const [columns] = useState(getColumns());
  const [ingredients, setIngredients] = useState([]);

  const [actions, setActions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredIngredients, setFilteredIngredients] = useState([]);
  const [userLanguageCode] = useState(get(user, 'lnkLanguageAccountrel.code', 'fr'));
  const [applyFilters, setApplyFilters] = useState(true);
  const [columnsFilterList, setColumnsFilterList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [emptyState, setEmptyState] = useState(false);
  const [rowActions, setRowActions] = useState([]);
  const [filteringOnActive, setFilteringOnActive] = useState(true);
  const [activeKeyForActiveSingleDropdown, setActiveKeyForActiveSingleDropdown] = useState(
    CHOICES_DROPDOWN_ACTIVE[0],
  );
  const [currentStep, setCurrentStep] = useState(IMPORT_STATE.SELECT_FILE);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorFile, setErrorFile] = useState(null);
  const [batchEntitiesToCreate, setBatchEntitiesToCreate] = useState(null);

  useEffect(() => {
    getIngredients();
    initializeFiltersFromContext();
  }, []);

  useEffect(() => {
    updateListActions();
    updateRowActionsList();
  }, [selectedItems, filteredIngredients, ingredients]);

  useEffect(() => {
    processAdvancedFilters(ingredients);
  }, [applyFilters, filters, advancedFilters]);

  useEffect(() => {
    if (!selectedFile) {
      return;
    }

    handleOpenedImportFileModal(
      {
        currentStep: currentStep,
        importType: IMPORT_TYPE.ENTITIES,
        handleSwitchStep: handleSwitchStep,
        handleFileChange: handleFileChange,
        selectedFile: selectedFile,
        exportErrorData: errorFile,
        cleanImportModal: cleanUpModalImport,
      },
      openGenericModal,
      refreshGenericModal,
      get(modal, 'GenericModalBool', false),
    );
  }, [selectedFile, currentStep]);

  const goToIngredientDetailsPage = (id) => {
    history.push(`/admin/products/ingredients/${id}/details`);
  };

  const preprocessIngredients = (ingredients) => {
    const formattedIngredients = ingredients.map((ingredient) => {
      if (ingredient.unit === 'unit') ingredient.unit = 'unité';
      let costUnit = '';
      if (ingredient.unit === 'unité') {
        costUnit = 'unité';
      } else if (ingredient.unit === 'g') {
        costUnit = 'kg';
      } else if (ingredient.unit === 'mL') {
        costUnit = 'L';
      }
      ingredient.costStr = ingredient.cost
        ? `${formatNumber(ingredient.cost, currency.numberDecimals)} ${
            currency.alphabeticCode
          }/ ${costUnit}`
        : '-';
      return ingredient;
    });
    processAdvancedFilters(formattedIngredients);
    updateColumnsFilterList(formattedIngredients);

    return formattedIngredients;
  };

  const getIngredients = async () => {
    setIsLoading(true);

    try {
      const ingredients = await ingredientService.getIngredients(clientId);

      if (!ingredients || !ingredients.length) {
        setIngredients([]);
        setEmptyState(true);

        return;
      }

      const formattedIngredients = preprocessIngredients(ingredients);

      setIngredients(formattedIngredients);
    } catch {
      showErrorMessage(i18next.t('ADMIN.INGREDIENTS.LOAD_ERROR'));

      setIngredients([]);
    } finally {
      setIsLoading(false);
    }
  };

  const initializeFiltersFromContext = () => {
    try {
      const areFiltersValid = filters[ENUM_CUSTOM_FILTERS.IS_STRATEGIC].every((filter) =>
        STRATEGIC_INGREDIENT_DROPDOWN_ITEMS.some((item) => isEqual(item, filter)),
      );

      if (!areFiltersValid) {
        throw new Error('Filters not valid');
      }
    } catch {
      setFilters(null);
    }
  };

  const getIsStrategicFiltersFromContext = () => {
    if (!filters) {
      return STRATEGIC_INGREDIENT_DROPDOWN_ITEMS;
    }

    return filters[ENUM_CUSTOM_FILTERS.IS_STRATEGIC].selectedItems;
  };

  const handleForActiveDropdown = (items) => {
    if (isEmpty(filters)) {
      return;
    }

    setFilters({
      ...filters,
      [ENUM_CUSTOM_FILTERS.IS_STRATEGIC]: {
        ...filters[ENUM_CUSTOM_FILTERS.IS_STRATEGIC],
        selectedItems: items,
      },
    });
  };

  const processAdvancedFilters = (ingredients) => {
    if (!applyFilters) {
      return;
    }

    let activeFilter;
    const selectedIsStrategicFilters = getIsStrategicFiltersFromContext();

    if (!isEmpty(filters)) {
      const {
        [ENUM_CUSTOM_FILTERS.ACTIVE_INACTIVE_SUPPLIER_PRODUCTS]: { selectedItem: isActiveFilter },
      } = filters;

      activeFilter = isActiveFilter;
    } else {
      activeFilter = activeKeyForActiveSingleDropdown;
    }

    const activeIngredients = ingredients.filter(
      ({ active }) => active === activeFilter.filterValue,
    );

    const filteredSelectedIngredients = activeIngredients.filter((ingredient) =>
      selectedIsStrategicFilters.some((key) => {
        if (key.itemValue === false) {
          return !ingredient.isStrategic;
        }
        return key.itemValue === ingredient.isStrategic;
      }),
    );

    if (isEmpty(advancedFilters) && applyFilters) {
      setFilteredIngredients(filteredSelectedIngredients);
      return;
    }

    const filteredIngredientsListWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, propertyKey, value, filterType, list }) => {
        if (filterType === 'string' && value && value.length === 1 && value[0].id === 'none') {
          return doFilter(result, propertyKey, list, true);
        }

        return doFilter(result, propertyKey, value);
      },
      filteredSelectedIngredients,
    );

    setFilteredIngredients(filteredIngredientsListWithAdvancedFilters);
  };

  const updateColumnsFilterList = (ingredients) => {
    const updatedColumnsList = columns.reduce((result, column) => {
      if (!column.filterType) {
        return result;
      }

      const item = { ...column };

      if (column.filterType === 'string') {
        const choices = ingredients.reduce((list, ingredient, key) => {
          if (ingredient[column.propertyKey]) {
            if (typeof ingredient[column.propertyKey] === 'object') {
              ingredient[column.propertyKey].forEach((elem, index) => {
                if (!list[elem]) {
                  list[elem] = {
                    id: index,
                    name: elem,
                    [column.propertyKey]: elem,
                  };
                }
              });
            } else {
              if (!list[ingredient[column.propertyKey]]) {
                list[ingredient[column.propertyKey]] = {
                  id: key,
                  name: ingredient[column.propertyKey],
                  [column.propertyKey]: ingredient[column.propertyKey],
                };
              }
            }
          }
          return list;
        }, {});

        if (!Object.keys(choices).length) {
          return result;
        }

        item.list = sortArrayOfObjectsAlphabetically(Object.values(choices), 'name');
      }

      result.push(item);

      return result;
    }, []);

    setColumnsFilterList(updatedColumnsList);

    return;
  };

  const deleteIngredientByIdsWithLinkedData = async (ingredients) => {
    if (!ingredients.length) {
      return;
    }

    const ingredientIds = ingredients.map(({ id }) => id);
    const isDeletingMultipleIngredients = ingredientIds.length > 1;

    pageLoading();

    try {
      await ingredientService.deleteIngredientByIdsWithLinkedData(ingredientIds, clientId);

      await getIngredients();

      showSuccessMessage(
        i18next.t(
          isDeletingMultipleIngredients
            ? 'ADMIN.INGREDIENTS.DELETE_MULTIPLE_SUCCESS'
            : 'ADMIN.INGREDIENTS.DELETE_SINGLE_SUCCESS',
        ),
      );

      setSelectedItems([]);
    } catch {
      showErrorMessage(
        i18next.t(
          isDeletingMultipleIngredients
            ? 'ADMIN.INGREDIENTS.DELETE_MULTIPLE_ERROR'
            : 'ADMIN.INGREDIENTS.DELETE_SINGLE_ERROR',
        ),
      );
    } finally {
      pageLoaded();
    }
  };

  const handleExport = (ingredients) => {
    const contentToExport = ingredients.length >= 1 ? ingredients : filteredIngredients;

    triggerExport(contentToExport);
  };

  const handleIngredientChangeModalBatch = (ingredients, updateProperties = true) => {
    const updatedDataModal = ingredients.reduce((result, current) => {
      if (!result[current.value]) {
        result[current.value] = {
          ...omit(current, 'ids'),
          associatedItems: current.ids.map((id) => ({
            id,
          })),
        };
      }
      return result;
    }, {});

    const updatedData = { data: updatedDataModal };

    if (updateProperties) {
      updatedData.properties = Object.keys(updatedDataModal).sort();
    }

    refreshGenericModal(
      {
        data: updatedData,
        actions: [
          {
            key: 0,
            color: 'inpulse-outline',
            label: i18next.t('GENERAL.CANCEL'),
            icon: '/images/inpulse/close-black-small.svg',
          },
          {
            key: 1,
            color: 'inpulse-default',
            label: i18next.t('GENERAL.SAVE'),
            icon: '/images/inpulse/save-white-small.svg',
            handleClick: () => updateCategoriesOfIngredients(ingredients),
            isDisabled: false,
          },
        ],
      },
      ['actions', 'data.data', 'data.properties'],
    );
  };

  const updateCategoriesOfIngredients = async (updatedIngredients) => {
    pageLoading();

    try {
      await clientService.updateIngredientPropertyByBatch(clientId, updatedIngredients);
      await getIngredients();
      showSuccessMessage(i18next.t('GENERAL.MODAL_CATEGORIES_UPDATE_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('GENERAL.MODAL_CATEGORIES_UPDATE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const updateIsStrategicOfIngredientsByBatch = async (
    ingredients = [],
    updatedIsStrategicValue,
  ) => {
    pageLoading();

    const isUniqueSelection = ingredients.length === 1;

    const payload = [
      {
        field: 'isStrategic',
        value: updatedIsStrategicValue,
        ids: ingredients.map(({ id }) => id),
      },
    ];

    try {
      await clientService.updateIngredientPropertyByBatch(clientId, payload);

      await getIngredients();

      showSuccessMessage(
        !!updatedIsStrategicValue
          ? i18next.t(
              isUniqueSelection
                ? 'GENERAL.LIST_ACTION_DEFINE_AS_STRATEGIC_SINGLE_SUCCESS'
                : 'GENERAL.LIST_ACTION_DEFINE_AS_STRATEGIC_MULTIPLE_SUCCESS',
            )
          : i18next.t(
              isUniqueSelection
                ? 'GENERAL.LIST_ACTION_DEFINE_AS_NOT_STRATEGIC_SINGLE_SUCCESS'
                : 'GENERAL.LIST_ACTION_DEFINE_AS_NOT_STRATEGIC_MULTIPLE_SUCCESS',
            ),
      );
    } catch {
      showErrorMessage(
        !!updatedIsStrategicValue
          ? i18next.t(
              isUniqueSelection
                ? 'GENERAL.LIST_ACTION_DEFINE_AS_STRATEGIC_SINGLE_FAILURE'
                : 'GENERAL.LIST_ACTION_DEFINE_AS_STRATEGIC_MULTIPLE_FAILURE',
            )
          : i18next.t(
              isUniqueSelection
                ? 'GENERAL.LIST_ACTION_DEFINE_AS_NOT_STRATEGIC_SINGLE_FAILURE'
                : 'GENERAL.LIST_ACTION_DEFINE_AS_NOT_STRATEGIC_MULTIPLE_FAILURE',
            ),
      );
    } finally {
      pageLoaded();
    }
  };

  const handleIngredientsDeactivation = (ingredientsToDeactivate) => {
    const params = getDeactivationWarningModal(ingredientsToDeactivate, disableIngredients);

    openGenericModal(params);
  };

  const disableIngredients = async (ingredients) => {
    const ingredientIds = ingredients.map(({ id }) => id);

    pageLoading();

    try {
      await ingredientService.disableIngredients(ingredientIds);

      await getIngredients();

      showSuccessMessage(
        ingredientIds.length === 1
          ? i18next.t('ADMIN.INGREDIENTS.SINGLE_DISABLE_SUCCESS')
          : i18next.t('ADMIN.INGREDIENTS.MULTIPLE_DISABLE_SUCCESS'),
      );
    } catch {
      showErrorMessage(
        ingredientIds.length === 1
          ? i18next.t('ADMIN.INGREDIENTS.SINGLE_DISABLE_ERROR')
          : i18next.t('ADMIN.INGREDIENTS.MULTIPLE_DISABLE_ERROR'),
      );
    } finally {
      pageLoaded();
    }
  };

  const handleIngredientActivation = (ingredientToActivate) => {
    const params = getActivationWarningModal({
      ingredientToActivate,
      activateIngredients,
    });

    openGenericModal(params);
  };

  const activateIngredients = async (ingredient) => {
    pageLoading();

    try {
      await ingredientService.enableIngredientsByIds([ingredient.id]);

      await getIngredients();

      showSuccessMessage(i18next.t('ADMIN.INGREDIENTS.SINGLE_ACTIVATION_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('ADMIN.INGREDIENTS.SINGLE_ACTIVATION_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const getEntityBatchCreationTemplate = async () => {
    pageLoading();

    try {
      const template = await clientService.getEntityBatchCreationTemplate(clientId);

      downloadFile(template, i18next.t('ADMIN.INGREDIENTS.DOWNLOAD_TEMPLATE_FOR_IMPORT_FILENAME'));
    } catch {
      showErrorMessage(i18next.t('ADMIN.INGREDIENTS.TOASTER_IMPORT_BATCH_FAILED'));
    } finally {
      pageLoaded();
    }
  };

  const cleanUpModalImport = () => {
    setSelectedFile(null);
    setErrorFile(null);
    setCurrentStep(IMPORT_STATE.SELECT_FILE);
    setBatchEntitiesToCreate(null);
    closeGenericModal();
  };

  const getEntityBatchFileValidation = async () => {
    pageLoading();

    try {
      const entities = await formatXlsFileToJson(selectedFile);

      const formattedEntities = formatEntityBatchCreationFile(entities);

      setBatchEntitiesToCreate(formattedEntities);

      await clientService.postEntityBatchCreateValidation(clientId, formattedEntities);

      setCurrentStep(IMPORT_STATE.VALIDATED_FILE);
    } catch (error) {
      if (error.statusCode === 422) {
        setErrorFile(error.data);
        setCurrentStep(IMPORT_STATE.ERROR_FILE);
      }
    } finally {
      pageLoaded();
    }
  };

  const postBatchEntitiesToCreate = async () => {
    pageLoading();

    try {
      await clientService.postEntityBatchCreation(clientId, batchEntitiesToCreate);

      showSuccessMessage(i18next.t('GENERAL.IMPORT_FILE_BATCH_CREATION_TOASTER_SUCCESS'));
      cleanUpModalImport();

      await getIngredients();
    } catch {
      showErrorMessage(i18next.t('GENERAL.IMPORT_FILE_BATCH_CREATION_TOASTER_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleFileValidation = async () => {
    await getEntityBatchFileValidation();
  };

  const handleSwitchStep = async (changeStep) => {
    if (changeStep === SWITCH_STEP.PREVIOUS) {
      cleanUpModalImport();
      return;
    }

    if (currentStep === IMPORT_STATE.SELECT_FILE) {
      await handleFileValidation();
    }

    if (currentStep === IMPORT_STATE.ERROR_FILE) {
      cleanUpModalImport();
    }

    if (currentStep === IMPORT_STATE.VALIDATED_FILE) {
      await postBatchEntitiesToCreate();
    }
  };

  const handleFileChange = (file) => {
    setSelectedFile(file);

    openImportEntitiesModal(file);
  };

  const openImportEntitiesModal = (file) => {
    const ingredientCreationFile =
      (!!file || file == null) && currentStep === IMPORT_STATE.SELECT_FILE ? file : selectedFile;

    handleOpenedImportFileModal(
      {
        currentStep: currentStep,
        importType: IMPORT_TYPE.ENTITIES,
        handleSwitchStep: handleSwitchStep,
        handleFileChange: handleFileChange,
        selectedFile: ingredientCreationFile,
        exportErrorData: errorFile,
        cleanImportModal: cleanUpModalImport,
      },
      openGenericModal,
      refreshGenericModal,
      get(props, 'modal.GenericModalBool', false),
    );
  };

  const updateListActions = () => {
    const updatedActions = getActions({
      ingredients: ingredients,
      handleExport: handleExport,
      selectedItems: selectedItems,
      goToIngredientDetailsPage: goToIngredientDetailsPage,
      isUserDeepsight: isUserDeepsight(user),
      renderCreateButton: renderCreateButton,
      history: history,
      openGenericModal: openGenericModal,
      handleIngredientChangeModalBatch: handleIngredientChangeModalBatch,
      deleteIngredientByIdsWithLinkedData: deleteIngredientByIdsWithLinkedData,
      updateIsStrategicOfIngredientsByBatch: updateIsStrategicOfIngredientsByBatch,
      handleIngredientsDeactivation: handleIngredientsDeactivation,
      filteringOnActive: filteringOnActive,
      user: user,
      handleIngredientActivation: handleIngredientActivation,
      getEntityBatchCreationTemplate: getEntityBatchCreationTemplate,
      openImportEntitiesModal: openImportEntitiesModal,
      authorizedActions: authorizedActions,
    });

    setActions(updatedActions);
  };

  const updateRowActionsList = () => {
    const updatedRowActions = getRowActions({
      ingredients: ingredients,
      goToIngredientDetailsPage: goToIngredientDetailsPage,
      isUserDeepsight: isUserDeepsight(user),
      openGenericModal: openGenericModal,
      handleIngredientChangeModalBatch: handleIngredientChangeModalBatch,
      deleteIngredientByIdsWithLinkedData: deleteIngredientByIdsWithLinkedData,
      updateIsStrategicOfIngredientsByBatch: updateIsStrategicOfIngredientsByBatch,
      handleIngredientsDeactivation: handleIngredientsDeactivation,
      filteringOnActive: filteringOnActive,
      user: user,
      handleIngredientActivation: handleIngredientActivation,
    });

    setRowActions(updatedRowActions);
  };

  const renderCreateButton = () => (
    <Button
      buttonCustomStyle={{ fontWeight: 400 }}
      color={'blue'}
      fontSize={14}
      formatText={false}
      handleClick={() => history.push('/admin/products/ingredients/create')}
      icon={'/images/inpulse/add-white-small.svg'}
      iconCustomStyle={{ width: '14px', height: '14px' }}
      label={i18next.t('GENERAL.CREATE')}
    />
  );

  const triggerExport = async (ingredients) => {
    openModalExportInfo({
      component: IngredientExportModal,
      title: i18next.t('ADMIN.INGREDIENTS.EXPORT'),
      ingredients,
    });
  };

  const handleActiveSingleDropdown = (key) => {
    setActiveKeyForActiveSingleDropdown(key);
    setFilteringOnActive(key.filterValue);

    if (!isEmpty(filters)) {
      setFilters({
        ...filters,
        [ENUM_CUSTOM_FILTERS.ACTIVE_INACTIVE_SUPPLIER_PRODUCTS]: {
          ...filters[ENUM_CUSTOM_FILTERS.ACTIVE_INACTIVE_SUPPLIER_PRODUCTS],
          selectedItem: key,
        },
      });
    }
  };

  const renderEmptyState = () => (
    <GeneralEmptyStateListView
      icon={'/images/inpulse/ingredients.svg'}
      renderAction={() => (
        <Button
          color="inpulse-default"
          handleClick={() => history.push('/admin/products/ingredients/create')}
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('ADMIN.INGREDIENTS.CREATE')}
        />
      )}
      subtitle={i18next.t('ADMIN.INGREDIENTS.EMPTY_STATE_SUBTITLE')}
      title={i18next.t('ADMIN.INGREDIENTS.EMPTY_STATE_TITLE')}
    />
  );

  return (
    <>
      <NavigationBreadCrumb featurePath={path} />
      {emptyState ? (
        renderEmptyState()
      ) : (
        <ListViewContainer>
          <ListView
            actionOnClick={(ingredient) => goToIngredientDetailsPage(ingredient.id)}
            actions={actions}
            columns={columns}
            data={filteredIngredients}
            defaultCurrentPage={currentPage}
            defaultMaxPerPage={maxPerPage}
            defaultOrderBy={orderBy}
            defaultOrderType={orderType}
            defaultSearchInput={search}
            handleCurrentPageChange={setCurrentPage}
            handleMaxPerPageChange={setMaxPerPage}
            handleOrderByChange={setOrderBy}
            handleOrderTypeChange={setOrderType}
            handleSearchInputChange={setSearch}
            isLoading={isLoading}
            languageCode={userLanguageCode}
            markerConfiguration={{
              isHidden: ({ isStrategic }) => !isStrategic,
              backgroundColor: getTheme().colors.greys.darkest,
              icon: {
                src: '/images/inpulse/icon-label-important-white.svg',
              },
            }}
            minActionsInActionsDropdown={1}
            padding={STANDARD_LISTVIEW_PADDING}
            placeholderShape={i18next.t('GENERAL.SEARCH')}
            renderEmptyState={() => <EmptyState />}
            renderFilterButton={() => (
              <DeepsightFiltersButton
                advancedFilters={advancedFilters}
                applyFilters={applyFilters}
                columnsFilterList={columnsFilterList}
                customMultipleDropDowns={[
                  {
                    id: ENUM_CUSTOM_FILTERS.IS_STRATEGIC,
                    icon: '/images/icon-dropdown-grey.svg',
                    list: STRATEGIC_INGREDIENT_DROPDOWN_ITEMS,
                    defaultSelectedItems: getIsStrategicFiltersFromContext(),
                    selectedItems: getIsStrategicFiltersFromContext(),
                    setSelectedItems: handleForActiveDropdown,
                  },
                ]}
                customSingleDropDowns={[
                  {
                    id: 'active-inactive-supplier-products',
                    isDisabled: isUserManager(user) && !isUserDeepsight(user),
                    disabledIcon: '/images/inpulse/power-ip-black.svg',
                    itemSelectedIcon: '/images/inpulse/power-ip-black.svg',
                    list: CHOICES_DROPDOWN_ACTIVE,
                    defaultSelectedItem: CHOICES_DROPDOWN_ACTIVE[0],
                    selectedItem: activeKeyForActiveSingleDropdown,
                    setSelectedItem: handleActiveSingleDropdown,
                  },
                ]}
                filters={filters}
                isLoading={isLoading}
                readOnly={isLoading}
                setAdvancedFilters={setAdvancedFilters}
                setApplyFilters={setApplyFilters}
                setFilters={setFilters}
                textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
              />
            )}
            rowActions={rowActions}
            setSelectedItems={setSelectedItems}
          />
        </ListViewContainer>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  modal: state.modalReducer,
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/admin/products/ingredients',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openSlidingModal: (params) => {
    dispatch(openSlidingModal(params));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params, customPropertyPathsToUpdate) => {
    dispatch(refreshGenericModal(params, customPropertyPathsToUpdate));
  },
  closeGenericModal: () => {
    dispatch(closeGenericModal());
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Ingredients);
