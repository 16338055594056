import i18next from 'i18next';
import React from 'react';

import { Tooltip } from '@commons/utils/styledLibraryComponents';

import { STOCK_FORM_TYPES } from '../../constants/types';

import { Text, Title, Line, Container } from './styledComponents';

const STOCK_CONVENTIONS = {
  START: 'start',
  END: 'end',
};

export const StoreConvention = (props) => {
  const { stockType, stockConvention } = props;

  const getTooltipText = () => {
    if (stockConvention) {
      if (
        stockType.toLowerCase() === STOCK_FORM_TYPES.STOCK &&
        stockConvention === STOCK_CONVENTIONS.START
      ) {
        return i18next.t('STOCKS.STOCKS.FORM_HEADER_CONVENTION_START_STOCK_TOOLTIP');
      }
      if (
        stockType.toLowerCase() === STOCK_FORM_TYPES.STOCK &&
        stockConvention === STOCK_CONVENTIONS.END
      ) {
        return i18next.t('STOCKS.STOCKS.FORM_HEADER_CONVENTION_END_STOCK_TOOLTIP');
      }
      if (
        stockType.toLowerCase() === STOCK_FORM_TYPES.LOSS &&
        stockConvention === STOCK_CONVENTIONS.START
      ) {
        return i18next.t('STOCKS.STOCKS.FORM_HEADER_CONVENTION_START_LOSS_TOOLTIP');
      }
      if (
        stockType.toLowerCase() === STOCK_FORM_TYPES.LOSS &&
        stockConvention === STOCK_CONVENTIONS.END
      ) {
        return i18next.t('STOCKS.STOCKS.FORM_HEADER_CONVENTION_END_LOSS_TOOLTIP');
      }
    }

    return '';
  };

  return (
    <Container>
      <Line>
        <Title>{i18next.t('STOCKS.STOCKS.FORM_HEADER_CONVENTION_TITLE_DISPLAY')}</Title>
        <Tooltip text={getTooltipText()} />
      </Line>
      <Line>
        <Text>
          {stockConvention === STOCK_CONVENTIONS.START
            ? i18next.t('STOCKS.STOCKS.FORM_HEADER_CONVENTION_START_DISPLAY')
            : i18next.t('STOCKS.STOCKS.FORM_HEADER_CONVENTION_END_DISPLAY')}
        </Text>
      </Line>
    </Container>
  );
};

export default StoreConvention;
