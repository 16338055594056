import _ from 'lodash';
import React, { useState, useEffect } from 'react';

import {
  ContainerListComposition,
  ListHeader,
  ListHeaderSection,
  ListRow,
  ListRowSection,
} from '@admin/products/products/detail/components/ProductRecipeAssociation/RecipeComposition/components/CompositionList/styledComponents';

import Toggle from '../Toggle';

import { ContainerContent } from './styledComponents';

const RecipeComposition = ({ ingredients }) => (
  <ContainerListComposition>
    <ListHeader>
      <ListHeaderSection className={'name'}>Ingredient</ListHeaderSection>
      <ListHeaderSection>Mesure</ListHeaderSection>
    </ListHeader>
    {ingredients.map(({ ingredientId, ingredientName, ingredientUnit, recipeQuantity }) => (
      <ListRow key={ingredientId}>
        <ListRowSection className={'name'}>{ingredientName}</ListRowSection>
        <ListRowSection style={{ justifyContent: 'flex-start' }}>
          {_.round(recipeQuantity, 2)} {ingredientUnit}
        </ListRowSection>
      </ListRow>
    ))}
  </ContainerListComposition>
);

export default ({ productId, recipeByProductId }) => {
  const [composition, setComposition] = useState([]);

  const [availableChannels, setAvailableChannels] = useState([]);
  const [selectedChannelName, setSelectedChannelName] = useState(null);

  useEffect(() => {
    const recipesChannels = recipeByProductId[productId].reduce((acc, { channelName }) => {
      if (!acc.includes(channelName)) {
        acc.push(channelName);
      }

      return acc;
    }, []);

    setAvailableChannels(recipesChannels);
  }, [productId, recipeByProductId]);

  useEffect(() => {
    if (!productId || !recipeByProductId || !recipeByProductId[productId] || !selectedChannelName) {
      setComposition([]);

      return;
    }

    const filteredRecipes = recipeByProductId[productId].filter(
      ({ channelName }) => channelName === selectedChannelName,
    );

    setComposition(filteredRecipes);
  }, [productId, recipeByProductId, selectedChannelName]);

  if (!productId || !recipeByProductId || !recipeByProductId[productId]) {
    return;
  }

  return (
    <ContainerContent>
      <Toggle
        channels={availableChannels}
        selectedChannelName={selectedChannelName}
        setSelectedChannelName={setSelectedChannelName}
      />

      <RecipeComposition ingredients={composition} />
    </ContainerContent>
  );
};
