import i18next from 'i18next';
import React from 'react';

import OfflineLogo from '@assets/logo/offline.svg';

import { Content, Title, SubText } from '@commons/OfflineDetector/styledComponents';

export const OfflineProductionPage = (props) => {
  const { isAnalytics } = props;
  return (
    <Content>
      <img src={OfflineLogo} />
      <Title>{i18next.t('GENERAL.OFFLINE_TITLE')}</Title>
      <SubText>
        {isAnalytics
          ? i18next.t('GENERAL.OFFLINE_SUBTITLE')
          : i18next.t('GENERAL.OFFLINE_PROD_SUBTITLE')}
      </SubText>
    </Content>
  );
};
