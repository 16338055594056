import { get, groupBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getNumberFormattedWithDecimals } from '@commons/DisplayNumber';
import utilsXLS from '@commons/utils/makeXLS';

const MAX_SHEET_ELEMENT_SIZE = 50000;

const formatDataForExport = (transactions) => {
  const formattedData = transactions.reduce(
    (
      acc,
      {
        date,
        number,
        turnover,
        turnoverTaxExcluded,
        cashier,
        channel,
        cashierStore,
        items,
        storeName,
      },
    ) => {
      items.forEach((item) => {
        acc.push({
          ...item,
          date,
          number,
          turnover,
          turnoverTaxExcluded,
          cashier,
          channel,
          cashierStore,
          storeName,
        });
      });
      return acc;
    },
    [],
  );
  return formattedData;
};

const getTransactionsColumnsSettings = (clientStoreName) => [
  {
    name: clientStoreName,
    propertyKey: 'storeName',
  },
  {
    name: i18next.t('GENERAL.CASHIER_CONNEXION'),
    propertyKey: 'cashier.name',
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_CASHIER_STORE'),
    propertyKey: 'cashierStore.name',
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_PARTNER_ID'),
    propertyKey: 'cashierStore.partnerId',
  },
  {
    name: i18next.t('GENERAL.CREATION_DATE'),
    propertyKey: 'timestamp',
    transform: (value) => moment(value).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_CASHIER_PRODUCT_SKU'),
    propertyKey: 'sku',
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_CASHIER_PRODUCT_NAME'),
    propertyKey: 'name',
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_PRICE_EXC_TAXES'),
    propertyKey: 'price',
    type: 'currency',
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_NORMALIZED_PRICE_INC_TAXES'),
    propertyKey: 'includingTaxesNormalizedPrice',
    type: 'currency',
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_NORMALIZED_PRICE_EXC_TAXES'),
    propertyKey: 'excludingTaxesNormalizedPrice',
    type: 'currency',
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_CASHIER_PRODUCT_TAXES'),
    propertyKey: 'vatRate',
    transform: (value) => (value ? `${getNumberFormattedWithDecimals(value, 1)}%` : ''),
  },
  { name: i18next.t('GENERAL.QUANTITY'), propertyKey: 'quantity' },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_CASHIER_PRODUCT_TAGS'),
    propertyKey: 'tags',
    transform: (value) => (value || []).join(','),
  },
  { name: i18next.t('GENERAL.CHANNEL'), propertyKey: 'channel' },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_TRANSACTION_ID'),
    propertyKey: 'number',
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_TRANSACTION_PRICE_WITH_TAXES'),
    propertyKey: 'turnover',
    type: 'currency',
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_TRANSACTION_PRICE_EXC_TAXES'),
    propertyKey: 'turnoverTaxExcluded',
    type: 'currency',
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_TRANSACTION_MENU_ID'),
    propertyKey: 'menuId',
  },
  {
    name: i18next.t(
      'BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_TRANSACTION_MENU_PRICE_WITH_TAXES',
    ),
    propertyKey: 'menuPrice',
    type: 'currency',
  },
];

const getInformationsColumnsSettings = (clientStoreName) => [
  {
    name: clientStoreName,
    propertyKey: 'storeName',
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_CASHIER_STORE'),
    propertyKey: 'cashierStorePartnerId',
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_INFO_HEADER_START_DATE'),
    propertyKey: 'startDate',
    transform: (value) => moment(value).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_INFO_HEADER_END_DATE'),
    propertyKey: 'endDate',
    transform: (value) => moment(value).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  },
];

const createTransactionsExportSheet = (transactions, clientStoreName, currency) => {
  const salesSheetTitle = i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_MAIN_SHEET_NAME');
  const columnSettings = getTransactionsColumnsSettings(clientStoreName);
  const data = formatDataForExport(transactions);

  return utilsXLS.generateDefaultSheet(salesSheetTitle, columnSettings, data, currency);
};

const createInformationsExportSheet = (
  transactions,
  selectedStores,
  startDate,
  endDate,
  clientStoreName,
) => {
  const salesSheetTitle = i18next.t('GENERAL.INFORMATIONS');
  const columnSettings = getInformationsColumnsSettings(clientStoreName);

  const transactionsGroupedByStores = groupBy(transactions, 'storeName');

  const data = selectedStores.map(({ name }) => {
    const cashierStorePartnerId = get(
      transactionsGroupedByStores[name],
      '[0].cashierStore.partnerId',
      '',
    );

    return {
      storeName: name,
      cashierStorePartnerId,
      startDate,
      endDate,
    };
  });

  return utilsXLS.generateDefaultSheet(salesSheetTitle, columnSettings, data);
};

const exportTransactions = (
  transactions,
  selectedStores,
  startDate,
  endDate,
  clientStoreName,
  currency,
) => {
  const transactionsSheet = createTransactionsExportSheet(transactions, clientStoreName, currency);

  const informationsSheet = createInformationsExportSheet(
    transactions,
    selectedStores,
    startDate,
    endDate,
    clientStoreName,
  );

  const transactionsSheetData = transactionsSheet.data;

  const hasManyFiles = transactionsSheetData.length > MAX_SHEET_ELEMENT_SIZE;

  let generatedFileCount = 1;

  // We must do a do while because we need to create at least one file (0 transaction data case)
  do {
    const transactionBatchData = transactionsSheetData.splice(0, MAX_SHEET_ELEMENT_SIZE);

    utilsXLS.makeXLS(
      hasManyFiles
        ? `${i18next.t('ADMIN.TRANSACTIONS.EXPORT_FILENAME')}-${generatedFileCount}`
        : i18next.t('ADMIN.TRANSACTIONS.EXPORT_FILENAME'),
      [{ ...transactionsSheet, data: transactionBatchData }, informationsSheet],
    );

    generatedFileCount++;
  } while (transactionsSheetData.length > 0);
};

export default { exportTransactions };
