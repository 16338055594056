import { orderBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import ORDER_STATUS from '../constants/status';

const getPreparationOrderColumns = () => [
  {
    id: 1,
    propertyKey: 'name',
    label: i18next.t('GENERAL.NAME'),
    weightedCoefficientWidthColumn: 2,
  },
  {
    id: 2,
    propertyKey: 'orderedQuantity',
    label: i18next.t('ORDERS.ORDERS.LIST_LABEL_QUANTITY_BDC'),
  },
  {
    id: 3,
    propertyKey: 'BDLQuantity',
    label: i18next.t('ORDERS.ORDERS.LIST_LABEL_QUANTITY_BDL'),
  },
  {
    id: 4,
    propertyKey: 'priceExcTaxes',
    label: i18next.t('ORDERS.ORDERS.LIST_LABEL_PRICE_UNIT_EX_TAX_BDL'),
  },
  {
    id: 5,
    propertyKey: 'orderedTotal',
    label: i18next.t('ORDERS.ORDERS.LIST_LABEL_TOTAL_BDC'),
  },
  {
    id: 6,
    propertyKey: 'BDLTotal',
    label: i18next.t('ORDERS.ORDERS.LIST_LABEL_TOTAL_BDL'),
  },
  {
    id: 7,
    propertyKey: 'balance',
    label: i18next.t('ORDERS.ORDERS.LIST_LABEL_BDL_BDC_DIFFERENTIAL'),
  },
  {
    id: 8,
    propertyKey: 'chart',
    label: '',
  },
];

const getOrderReceptionColumns = () => [
  {
    id: 1,
    propertyKey: 'name',
    label: i18next.t('GENERAL.NAME'),
    weightedCoefficientWidthColumn: 2,
  },
  {
    id: 2,
    propertyKey: 'BDLQuantity',
    label: i18next.t('ORDERS.ORDERS.LIST_LABEL_QUANTITY_BDL'),
  },
  {
    id: 3,
    propertyKey: 'receivedQuantity',
    label: i18next.t('ORDERS.ORDERS.LIST_LABEL_QUANTITY_RECEIVED'),
  },
  {
    id: 4,
    propertyKey: 'priceExcTaxes',
    label: i18next.t('ORDERS.ORDERS.LIST_LABEL_PRICE_UNIT_EX_TAX_BDL'),
  },
  {
    id: 5,
    propertyKey: 'BDLTotal',
    label: i18next.t('ORDERS.ORDERS.LIST_LABEL_TOTAL_BDL'),
  },
  {
    id: 6,
    propertyKey: 'receivedTotal',
    label: i18next.t('ORDERS.ORDERS.LIST_LABEL_TOTAL_RECEIVED'),
  },
  {
    id: 7,
    propertyKey: 'balance',
    label: i18next.t('ORDERS.ORDERS.LIST_LABEL_DIFFERENTIAL'),
  },
  {
    id: 8,
    propertyKey: 'chart',
    label: '',
  },
];

export const getColumnsConfig = (settings) => {
  const {
    orderStatus,
    hasForecasts,
    currency,
    isCentralMode,
    isEditingPreparationOrder,
    storeTimezone,
    shouldUseConsumption,
  } = settings;

  const yesterdayDate = moment.tz(storeTimezone).subtract(1, 'days').format('L');

  if (orderStatus >= ORDER_STATUS.SENT) {
    return isEditingPreparationOrder ? getPreparationOrderColumns() : getOrderReceptionColumns();
  }

  const columns = [
    {
      id: 1,
      propertyKey: 'name',
      label: i18next.t('GENERAL.NAME'),
      weightedCoefficientWidthColumn: 2,
    },
    {
      id: 2,
      propertyKey: 'conditioning',
      label: i18next.t('ORDERS.ORDERS.FORM_COLUMN_LABEL_FORMAT'),
    },
    {
      id: 3,
      propertyKey: 'price',
      label: i18next.t('ORDERS.ORDERS.FORM_COLUMN_LABEL_PRICE_BDC'),
    },
    {
      id: 4,
      propertyKey: 'yesterdayStock',
      label: i18next.t('ORDERS.ORDERS.FORM_YESTERDAY_STOCK_COLUMN_TITLE'),
      tooltip: i18next.t('ORDERS.ORDERS.FORM_YESTERDAY_STOCK_COLUMN_TOOLTIP', { yesterdayDate }),
      weightedCoefficientWidthColumn: 0.8,
    },
    {
      id: 5,
      propertyKey: 'recommandation',
      label: i18next.t(
        shouldUseConsumption
          ? 'ORDERS.ORDERS.FORM_COLUMN_LABEL_CONSO'
          : 'ORDERS.ORDERS.FORM_COLUMN_LABEL_RECO',
      ),
      tooltip: i18next.t(
        shouldUseConsumption
          ? 'ORDERS.ORDERS.FORM_CONSUMPTION_COLUMN_TOOLTIP_LABEL'
          : 'ORDERS.ORDERS.FORM_RECOMMANDATION_COLUMN_TOOLTIP_LABEL',
      ),
      weightedCoefficientWidthColumn: 0.8,
    },
    {
      id: 6,
      propertyKey: 'order',
      label: i18next.t('ORDERS.ORDERS.FORM_COLUMN_LABEL_ORDER'),
      weightedCoefficientWidthColumn: 1.4,
    },
    {
      id: 7,
      propertyKey: 'total',
      label: i18next.t('ORDERS.ORDERS.FORM_COLUMN_LABEL_TOTAL_WITH_UNIT', {
        currency: currency.alphabeticCode,
      }),
    },
    {
      id: 8,
      propertyKey: 'chart',
      label: '',
      isDisabled: () => !hasForecasts,
    },
  ];

  if (!hasForecasts || isCentralMode) {
    const orderColumns = columns.reduce((acc, column) => {
      if (column.propertyKey === 'recommandation') {
        return acc;
      }
      acc.push(column);
      return acc;
    }, []);
    return orderBy(orderColumns, 'id');
  }

  return orderBy(columns, 'id');
};

export default getColumnsConfig;
