import { getActionAuthorization } from '../commons/utils';

const SUPPLIER_PROFILE_ACTIONS_RESOURCE = {
  DOWNLOAD_TEMPLATE: 'supplierProfiles:batch:template',
  IMPORT: 'supplierProfiles:batch:import',
};

export const canDownloadSupplierProfileTemplate = (actions) =>
  getActionAuthorization(actions, SUPPLIER_PROFILE_ACTIONS_RESOURCE.DOWNLOAD_TEMPLATE);

export const canImportSupplierProfile = (actions) =>
  getActionAuthorization(actions, SUPPLIER_PROFILE_ACTIONS_RESOURCE.IMPORT);
