import { orderBy } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { DATE_PICKER_DOT_COLOR } from '../../../commons/DatePickers/constants';
import { formatNumber } from '../../../commons/DisplayNumber';
import { Label } from '../../../commons/utils/styledLibraryComponents';

import theme from '../../../theme';

export function getGraphConfiguration(entity, selectedMetric) {
  return {
    title: i18next.t('STOCKS.PAST_STOCKS.TITLE', {
      name: entity.name,
      status: entity.active ? i18next.t('GENERAL.ACTIVE') : i18next.t('GENERAL.INACTIVE'),
    }),
    tooltiptext: i18next.t('STOCKS.PAST_STOCKS.TOOLTIP'),
    metric: selectedMetric,
    legends: [
      {
        color: theme.colors.brand.secondary,
        name: i18next.t('STOCKS.STOCKS.THEORETICAL_STOCK'),
        propertyKey:
          selectedMetric === 'turnover' ? 'theoricalStockTurnover' : 'theoricalStockUnit',
      },
      {
        color: theme.colors.greys.light,
        name: i18next.t('STOCKS.STOCKS.REAL_STOCK'),
        propertyKey: selectedMetric === 'turnover' ? 'realStockTurnover' : 'realStockUnit',
      },
    ],
  };
}

export const getEntityUnit = (unit, condensed = true, count = 1) => {
  if (unit === 'g' || unit === 'kg' || unit === 'Kg') {
    return 'kg';
  } else if (unit === 'mL' || unit === 'L') {
    return 'L';
  } else {
    return condensed ? 'u.' : i18next.t('GENERAL.UNIT', { count });
  }
};

export const convertEntityQuantityToInpulseUnit = (value, unit) => {
  switch (unit) {
    case 'unit':
      return value;
    case 'g':
    case 'mL':
      return value / 1000;
    default:
      return value;
  }
};

export const getCalendarInfo = (storeName) => ({
  dotsInformations: [
    {
      dotColor: DATE_PICKER_DOT_COLOR.GREEN,
      dotInfo: i18next.t('STOCKS.PAST_STOCKS.DATEPICKER_TOOLTIP', {
        storeName: storeName.toLowerCase(),
      }),
    },
  ],
});

export const sortByGapPercentage = (data, orderType, customSortProperty) => {
  const notNullValues = data.filter((stock) => stock[customSortProperty] != null);
  const nullValues = data.filter((stock) => stock[customSortProperty] == null);
  const absoluteSortedNotNullValues = orderBy(
    notNullValues,
    [(item) => Math.abs(item[customSortProperty])],
    [orderType],
  );
  const alphaSortNullValues = orderBy(nullValues, (item) => item.name.toLowerCase(), ['asc']);

  return absoluteSortedNotNullValues.concat(alphaSortNullValues);
};

export const renderGapPercentage = (value, gapValueLimit) => {
  if (value == null) {
    return '--';
  }

  const formattedValue = value.toFixed(2);

  let gapValue = `${formattedValue} %`;

  if (Math.abs(value) >= gapValueLimit) {
    return (
      <Label
        background={theme.colors.infoLightOrange}
        children={gapValue}
        color={theme.colors.black}
        type={'plain'}
        useNestedListRowFont
      />
    );
  }

  return gapValue;
};

const renderStockGap = (value, item, metricKey, currency, gapValueLimit) => {
  if (value == null) {
    return '--';
  }

  const formattedValue = value.toFixed(2);

  const stockGapValue =
    metricKey === 'unit'
      ? `${formattedValue} ${getEntityUnit(item.unit)}`
      : `${formattedValue} ${currency.alphabeticCode}`;

  if (Math.abs(item.stockGapPercentage) >= gapValueLimit) {
    return (
      <Label
        background={theme.colors.infoLightOrange}
        children={stockGapValue}
        color={theme.colors.black}
        type={'plain'}
        useNestedListRowFont
      />
    );
  }

  return stockGapValue;
};

const getStockValueByMetric = (value, item, metricKey, currency) => {
  if (metricKey === 'unit') {
    return `${value < 0 ? 0 : value.toFixed(2)} ${getEntityUnit(item.unit)}`;
  }

  return `${value < 0 ? 0 : formatNumber(value, currency.numberDecimals)} ${
    currency.alphabeticCode
  }`;
};

export default {
  getGraphConfiguration,
  getEntityUnit,
  convertEntityQuantityToInpulseUnit,
  sortByGapPercentage,
  renderGapPercentage,
  renderStockGap,
  getStockValueByMetric,
};
