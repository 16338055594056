import { withRouter } from 'react-router-dom';
import i18next from 'i18next';
import React, { Component } from 'react';

import { Container, Logo, Text } from './styledComponents';

import { Button } from '../utils/styledLibraryComponents';

class InpulseErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: '' };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Logo alt="logo" src="/images/logo-inpulse.svg" />
          <Text>Oups, une erreur s’est produite !</Text>
          <Text subtitle>Si l’erreur persiste, veuillez contacter le support.</Text>
          <Button
            buttonCustomStyle={{ marginTop: 32 }}
            color={'inpulse-grey'}
            handleClick={() => {
              this.props.history.push('/home');
              this.setState({ hasError: false });
            }}
            icon={'/images/inpulse/home-white-small.svg'}
            label={i18next.t('FEATURE.HOME.HOME')}
          />
        </Container>
      );
    }
    return this.props.children;
  }
}

export const StyledErrorBoundary = withRouter(InpulseErrorBoundary);

export default StyledErrorBoundary;
