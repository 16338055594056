import React from 'react';

import IconArrow from '../images/icon-arrow-white.svg';

import { Icon, ContainerIcon } from './styledComponents';

const ArrowIcon = (): JSX.Element => {
  return (
    <ContainerIcon>
      <Icon src={IconArrow} />
    </ContainerIcon>
  );
};

ArrowIcon.propTypes = {};

export default ArrowIcon;
