import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  width: 560px;
  height: auto;

  background: ${(props) => props.theme.colors.greys.lightest};

  border-radius: ${(props) => props.theme.borders.radius.weak};
`;

export const Header = styled.div`
  width: 100%;
  padding: 24px;

  display: flex;
  align-items: center;
  gap: 8px;

  background: ${(props) => props.theme.colors.brand.primaryLight};

  border-top-left-radius: ${(props) => props.theme.borders.radius.weak};
  border-top-right-radius: ${(props) => props.theme.borders.radius.weak};

  > img:last-child {
    flex: 1;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  height: auto;

  border-bottom: 1px solid ${(props) => props.theme.colors.greys.light};
`;

export const DropdownContainer = styled.div`
  margin: auto;
  padding: 24px;

  width: 100%;
  height: 115px;

  display: flex;
  justify-content: center;
`;

export const PeriodDatePickerContainer = styled.div`
  width: 100%;
  height: 100px;

  display: flex;
  flex-direction: column;

  gap: 8px;

  align-items: center;
`;

export const LabelContainer = styled.div`
  width: 240px;
  display: flex;
  flex-direction: row;
`;

export const LabelRequiredCharacter = styled.div`
  margin-right: 2px;

  color: ${(props) => props.theme.dropdown.labelRequiredCharacter.default.color};
`;

export const Footer = styled.div`
  padding: 24px;

  gap: 24px;
  display: flex;
  justify-content: flex-end;

  border-bottom-left-radius: ${(props) => props.theme.borders.radius.weak};
  border-bottom-right-radius: ${(props) => props.theme.borders.radius.weak};
`;
