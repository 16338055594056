import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { Container, ContainerIcon, IconCheckedBox } from './styledComponents';

import { Props } from './interfaces';

import { getTheme } from '../utils/theme';

import iconCheck from '../images/checkbox/check.svg';
import iconCheckBlack from '../images/checkbox/check-ip-black.svg';
import iconUnCheck from '../images/checkbox/uncheck.svg';
import iconSmallCheck from '../images/checkbox/check-white-small.svg';

const Checkbox = (props: Props): JSX.Element => {
  const {
    theme,
    isChecked,
    handleClick,
    shape,
    uncheckOnClick,
    noSize,
    margin,
    isDisableBorder,
    isDisabled,
    isSmallCheck,
    isValidation,
    isDark,
    isDisabledLight,
  } = props;

  const updatedTheme = getTheme(theme, 'checkbox');

  const pickCheckIcon = isSmallCheck ? iconSmallCheck : iconCheck;

  const selectedCheckIcon = isDark ? iconCheckBlack : pickCheckIcon;

  return (
    <ThemeProvider theme={updatedTheme}>
      <Container
        onClick={!isDisabled ? handleClick : null}
        isDisabled={isDisabled}
        margin={margin}
        noSize={noSize}
        isDisabledLight={isDisabledLight}
      >
        <ContainerIcon
          shape={shape}
          isChecked={isChecked || uncheckOnClick}
          isDisabled={isDisabled}
          isValidation={isValidation}
          isDisableBorder={isDisableBorder}
          isDisabledLight={isDisabledLight}
        >
          {(shape === 'square' || shape === 'roundsquare') &&
            (isChecked || uncheckOnClick) && (
              <IconCheckedBox
                alt="checkbox"
                src={isChecked ? selectedCheckIcon : iconUnCheck}
              />
            )}
        </ContainerIcon>
      </Container>
    </ThemeProvider>
  );
};

Checkbox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.objectOf(PropTypes.any),
  isChecked: PropTypes.bool.isRequired,
  handleClick: PropTypes.func,
  shape: PropTypes.string,
  uncheckOnClick: PropTypes.bool,
  isDisabled: PropTypes.bool,
  noSize: PropTypes.bool,
  margin: PropTypes.string,
  isDisableBorder: PropTypes.bool,
  isDisabledLight: PropTypes.bool,
  isSmallCheck: PropTypes.bool,
  isValidation: PropTypes.bool,
  isDark: PropTypes.bool,
};

Checkbox.defaultProps = {
  handleClick: () => true,
  shape: 'round',
  theme: null,
  uncheckOnClick: false,
  isDisabled: false,
  noSize: false,
  margin: null,
  isDisableBorder: false,
  isDisabledLight: false,
  isSmallCheck: false,
  isValidation: false,
  isDark: false,
};

export default Checkbox;
