import i18next from 'i18next';

const getStoreDuplicationSettingsCardSettings = (storeDuplicationSettings) => {
  const inputs = [
    {
      checked: false,
      disabled: true,
      isCheckbox: true,
      disabledLight: true,
      label: i18next.t('BACKOFFICE.STORES.DUPLICATION_USERS'),
      propertyKey: 'duplicateUsers',
    },
    {
      checked: false,
      disabled: true,
      isCheckbox: true,
      disabledLight: true,
      label: i18next.t('BACKOFFICE.STORES.DUPLICATION_PRODUCTION_PLANNING'),
      propertyKey: 'duplicateProductionPlanning',
    },
    {
      checked: false,
      disabled: true,
      isCheckbox: true,
      disabledLight: true,
      label: i18next.t('BACKOFFICE.STORES.DUPLICATION_PRODUCT_MIX'),
      propertyKey: 'duplicateProductMix',
    },
    {
      checked: true,
      disabled: true,
      isCheckbox: true,
      label: i18next.t('BACKOFFICE.STORES.DUPLICATION_EVENTS'),
      propertyKey: 'duplicateEvents',
    },
  ];

  return {
    title: i18next.t('BACKOFFICE.STORES.DUPLICATION_TITLE'),
    inputs: inputs.reduce((acc, input) => {
      acc.push({ ...input, value: true });
      return acc;
    }, []),
  };
};

export default getStoreDuplicationSettingsCardSettings;
