import React from 'react';

import { Button, SearchBar } from '@commons/utils/styledLibraryComponents';

import OrderRestrictionTooltip from '../OrderRestrictionTooltip';

import {
  Container,
  FrancoContainer,
  FrancoSection,
  InputSection,
  LeftPartHeader,
  RightPartHeader,
  SearchContainer,
} from './styledComponents';

const LoadingState = () => (
  <>
    <Container>
      <LeftPartHeader>
        <InputSection>
          <SearchContainer>
            <SearchBar maxWidth={160} minWidth={160} setValue={() => null} disabled />
          </SearchContainer>

          <OrderRestrictionTooltip isDisabled />
        </InputSection>

        <FrancoSection>
          <FrancoContainer></FrancoContainer>
          <FrancoContainer></FrancoContainer>
          <FrancoContainer minus></FrancoContainer>
        </FrancoSection>
      </LeftPartHeader>

      <RightPartHeader>
        <Button
          color={'inpulse-outline'}
          handleClick={() => null}
          icon={'/images/inpulse/file-download-black-small.svg'}
          isDisabled
        />
      </RightPartHeader>
    </Container>
  </>
);

export default LoadingState;
