import React from 'react';
import styled from 'styled-components';

const ConfirmationModalContainer = ({
  component: Component,
  deactivateBackgroundClick = false,
  closeConfirmationModal,
  ...rest
}) => (
  <div className={rest.className}>
    <div
      className="conf-modal-background"
      onClick={deactivateBackgroundClick ? () => false : closeConfirmationModal}
    ></div>
    <div
      className="conf-modal-container"
      onClick={deactivateBackgroundClick ? () => false : closeConfirmationModal}
    >
      <Component closeConfirmationModal={closeConfirmationModal} {...rest} />
    </div>
  </div>
);

const StyledConfirmationModalContainer = styled(ConfirmationModalContainer)`
  & .conf-modal-background {
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0px;
    z-index: 15;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${(props) => props.theme.colors.greys.darkest};
    opacity: 0.64;

    overflow: hidden;
  }

  & .conf-modal-container {
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0px;
    z-index: 15;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
  }
`;

export default StyledConfirmationModalContainer;
