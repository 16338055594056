import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

export const getActions = ({
  selectedSupplierProfiles,
  handleSupplierProfileCreation,
  handleExceptionalDeliveryDaysCreation,
  handleExceptionalDeliveryDaysDeletion,
}) => [
  {
    id: 'create',
    fixed: true,
    actionLabel: i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CREATE_SUPPLIER_PROFILE_ACTION'),
    render: () => (
      <Button
        color={'inpulse-default'}
        formatText={false}
        handleClick={handleSupplierProfileCreation}
        icon={'/images/icon-plus-white.svg'}
        label={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CREATE_SUPPLIER_PROFILE_ACTION')}
      />
    ),
  },
  {
    id: 'create-batch',
    actionLabel: i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_ADD_EXCEPTIONAL_DELIVERY_DAYS_ACTION'),
    actionIcon: () => '/images/inpulse/edit-pen-black-small.svg',
    handleAction: handleExceptionalDeliveryDaysCreation,
    isHidden: () => selectedSupplierProfiles.length < 2,
  },
  {
    id: 'delete',
    actionLabel: i18next.t(
      'ADMIN.SUPPLIERS.SUPPLIER_PROFILE_DELETE_EXCEPTIONAL_DELIVERY_DAYS_ACTION',
    ),
    actionIcon: () => '/images/inpulse/delete-black-small.svg',
    handleAction: handleExceptionalDeliveryDaysDeletion,
    isHidden: () => selectedSupplierProfiles.length < 2,
  },
];

export const getRowActions = ({
  clientStoreName,
  handleEditSupplierProfile,
  handleSupplierProfileAddStores,
  handleSupplierProfileDuplication,
  handleDeleteSupplierProfileConfirmationModale,
  areUpdateSupplierActionsHidden,
}) => [
  {
    actionLabel: () =>
      i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_ADD_STORE_SUPPLIER_PROFILE_ACTION', {
        storeName: clientStoreName,
      }),
    handleAction: (item) => handleSupplierProfileAddStores(item),
    actionIcon: () => '/images/inpulse/add-black-small.svg',
    isHidden: () => areUpdateSupplierActionsHidden,
  },
  {
    actionLabel: () =>
      i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_REMOVE_STORE_SUPPLIER_PROFILE_ACTION', {
        storeName: clientStoreName,
      }),
    handleAction: (item) => handleEditSupplierProfile(item, 'delete_stores'),
    actionIcon: () => '/images/inpulse/icon-minus-ipblack.svg',
    isHidden: () => areUpdateSupplierActionsHidden,
  },
  {
    actionLabel: () =>
      i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_SEE_STORE_SUPPLIER_PROFILE_ACTION', {
        storeName: clientStoreName,
      }),
    handleAction: (item) => handleEditSupplierProfile(item, 'see_stores'),
    actionIcon: () => '/images/inpulse/eye-black-small.svg',
  },
  {
    actionLabel: () =>
      i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_DUPLICATE_SUPPLIER_PROFILE_ACTION'),
    handleAction: (item) => handleSupplierProfileDuplication(item),
    actionIcon: () => '/images/inpulse/duplicate-black-small.svg',
    isHidden: () => areUpdateSupplierActionsHidden,
  },
  {
    actionLabel: () => i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_DELETE_SUPPLIER_PROFILE_ACTION'),
    handleAction: (item) => handleDeleteSupplierProfileConfirmationModale(item),
    actionIcon: () => '/images/inpulse/trash-black-small.svg',
    isHidden: () => areUpdateSupplierActionsHidden,
  },
];
