import { groupBy, get } from 'lodash';
import { View, Text, Svg, Rect } from '@react-pdf/renderer';
import i18next from 'i18next';
import React from 'react';

import { formatNumberWithCurrency } from '@commons/DisplayNumber';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';

import { getEntityUnit } from '@stocks/utils/index';

import { sharedContentStyle, recipeContentStyle, totalStyle } from './styleSheets';

const RecipesPDFList = (props) => {
  const { recipes, currency } = props;

  const formattedRecipes = recipes.map((recipe) => {
    const brandNames = recipe.brands.map(({ name }) => name);

    const formattedBrandNames = brandNames.join(', ');
    return { ...recipe, brandNames: formattedBrandNames };
  });

  const groupedRecipesByKey = groupBy(formattedRecipes, (recipe) => get(recipe, 'category', ''));

  const sortedRecipes = Object.keys(groupedRecipesByKey).sort();

  const sortedGroupedRecipes = sortedRecipes.map((key) =>
    sortArrayOfObjectsAlphabetically(groupedRecipesByKey[key], 'name'),
  );

  const sortedGroupedRecipesWithCategory = sortedGroupedRecipes.reduce((acc, item, index) => {
    const category = sortedRecipes[index];

    acc[category] = sortedGroupedRecipes[index];

    return acc;
  }, {});

  const recipesList = sortedGroupedRecipesWithCategory;
  const categories = Object.keys(recipesList);

  let nbLinesTotal = 0;

  const SubHeaderCategory = () => (
    <View style={sharedContentStyle.listColumns}>
      <Text style={recipeContentStyle.colunmTextName}>{i18next.t('GENERAL.NAME')}</Text>
      <View style={sharedContentStyle.borderSide}></View>
      <Text style={recipeContentStyle.colunmTextBrand}>{i18next.t('GENERAL.BRAND_PLURAL')}</Text>
      <View style={sharedContentStyle.borderSide}></View>
      <Text style={recipeContentStyle.colunmTextCost}>
        {i18next.t('GENERAL.COST', {
          currencyCode: currency.alphabeticCode,
        })}
      </Text>
      <View style={sharedContentStyle.borderSide}></View>
      <Text style={recipeContentStyle.colunmTextUnit}>{i18next.t('GENERAL.UNIT')}</Text>
      <View style={sharedContentStyle.borderSide}></View>
      <Text style={sharedContentStyle.colunmTextStock}>
        {i18next.t('STOCKS.STOCKS.INVENTORY_SHEET_STOCK')}
      </Text>
    </View>
  );

  return (
    <View style={sharedContentStyle.contentContainer}>
      <View>
        {categories.map((category, index) => {
          const associatedCategories = sortedGroupedRecipesWithCategory[category];
          const associatedCategoriesLength = associatedCategories.length;

          return (
            <View key={index}>
              <View style={sharedContentStyle.list}>
                <View wrap={false}>
                  <Text style={sharedContentStyle.listTitle}>{category}</Text>
                  <SubHeaderCategory />
                </View>

                {associatedCategories.map((recipe, subIndex) => {
                  const lineStyle =
                    subIndex === 0
                      ? sharedContentStyle.lineContainerEvenWithTopBorder
                      : sharedContentStyle.lineContainerOddWithTopBorder;

                  nbLinesTotal++;

                  return (
                    <View key={subIndex}>
                      <View style={lineStyle} wrap={false}>
                        <View style={recipeContentStyle.lineTextNameContainer}>
                          <Text style={sharedContentStyle.lineText}>{recipe.name}</Text>
                        </View>
                        <View style={recipeContentStyle.lineTextBrandContainer}>
                          <Text style={sharedContentStyle.lineText}>{recipe.brandNames}</Text>
                        </View>
                        <View style={recipeContentStyle.lineTextCostContainer}>
                          <Text style={sharedContentStyle.lineText}>
                            {formatNumberWithCurrency({
                              number: recipe.cost,
                              currency,
                              displayCurrencyCode: true,
                            })}
                          </Text>
                        </View>
                        <View style={recipeContentStyle.lineTextUnitContainer}>
                          <Text style={sharedContentStyle.lineText}>
                            {getEntityUnit(recipe.unit, false)}
                          </Text>
                        </View>
                        <View style={recipeContentStyle.lineTextContainerStock}>
                          <Svg viewBox="0 0 60 15">
                            <Rect fill="#E2E2E2" height="15" width="60" x="0" />
                          </Svg>
                        </View>
                      </View>

                      {subIndex === associatedCategoriesLength - 1 && (
                        <View
                          key={`${subIndex}-${category}`}
                          style={sharedContentStyle.borderEndOfCategory}
                        ></View>
                      )}
                    </View>
                  );
                })}
                <Text style={sharedContentStyle.numberLineText}>{`${i18next.t(
                  'STOCKS.STOCKS.INVENTORY_SHEET_NUMBER_LINES',
                )} : ${associatedCategoriesLength}`}</Text>
              </View>
            </View>
          );
        })}
      </View>
      <View style={totalStyle.globalContainer} wrap={false}>
        <View style={totalStyle.totalTitleContainer}>
          <Text style={sharedContentStyle.listTitle}>Total</Text>
        </View>
        <View style={totalStyle.totalContainer}>
          <Text style={sharedContentStyle.numberLineText}>{`${i18next.t(
            'STOCKS.STOCKS.INVENTORY_SHEET_NUMBER_LINES',
          )} : ${nbLinesTotal}`}</Text>
        </View>
      </View>
    </View>
  );
};

export default RecipesPDFList;
