import _ from 'lodash';
import DataFrame from 'dataframe-js';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { Checkbox } from '@commons/utils/styledLibraryComponents';

import {
  ContainerListComposition,
  ListHeader,
  ListHeaderSection,
  ListRow,
  ListRowSection,
} from '@admin/products/products/detail/components/ProductRecipeAssociation/RecipeComposition/components/CompositionList/styledComponents';

import { ContainerCheckBox } from './styledComponents';

const __groups__ = require('dataframe-js/lib/symbol').__groups__;

const COLUMNS = (props) => [
  { name: 'Date', propertyKey: 'date' },
  { name: 'Produit', propertyKey: 'productName' },
  { name: 'Channel', propertyKey: 'channelName' },
  { name: 'Qté vendue', propertyKey: 'quantitySold' },
  {
    name: `Qté  consommée`,
    propertyKey: 'quantityConsumed',
    customRender: (value) => `${_.round(value, 2)}} ${props.unit}`,
  },
];

const Listing = ({ columns, analytics }) => (
  <ContainerListComposition>
    <ListHeader>
      {columns.map((column, index) => (
        <ListHeaderSection key={index}>{column.name}</ListHeaderSection>
      ))}
    </ListHeader>
    {analytics.map((item, indexItem) => (
      <ListRow key={indexItem}>
        {columns.map((column, indexColumn) => (
          <ListRowSection
            key={indexColumn}
            style={{
              justifyContent: indexColumn + 1 === columns.length ? 'flex-start' : '',
            }}
          >
            {item.customRender
              ? item.customRender(item[column.propertyKey])
              : item[column.propertyKey]}
          </ListRowSection>
        ))}
      </ListRow>
    ))}
  </ContainerListComposition>
);

const ColumnsConfiguration = ({
  dateChecked,
  productChecked,
  channelChecked,
  handleDateCheck,
  handleProductCheck,
  handleChannelCheck,
}) => (
  <ContainerCheckBox>
    <Checkbox handleClick={handleDateCheck} isChecked={dateChecked} shape="square" />
    {i18next.t('BACKOFFICE.GENERAL.BY_DATE')}
    <Checkbox handleClick={handleProductCheck} isChecked={productChecked} shape="square" />
    {i18next.t('BACKOFFICE.GENERAL.BY_PRODUCT')}
    <Checkbox handleClick={handleChannelCheck} isChecked={channelChecked} shape="square" />
    {i18next.t('BACKOFFICE.GENERAL.BY_CHANNEL')}
  </ContainerCheckBox>
);

export default (props) => {
  const [dateChecked, setDateChecked] = useState(true);
  const [productChecked, setProductChecked] = useState(true);
  const [channelChecked, setChannelChecked] = useState(true);

  const [columns, setColumns] = useState(COLUMNS(props));

  useEffect(() => {
    const builtColumns = COLUMNS(props).filter((column) => {
      if (column.propertyKey === 'date') {
        return dateChecked;
      }

      if (column.propertyKey === 'productName') {
        return productChecked;
      }

      if (column.propertyKey === 'channelName') {
        return channelChecked;
      }

      return true;
    });

    setColumns(builtColumns);
  }, [dateChecked, productChecked, channelChecked]);

  const handleDateCheck = () => {
    setDateChecked(!dateChecked);
  };

  const handleProductCheck = () => {
    setProductChecked(!productChecked);
  };

  const handleChannelCheck = () => {
    setChannelChecked(!channelChecked);
  };

  const aggregateAnalytics = () => {
    const analysisDf = new DataFrame(props.analysis)
      .map((row) =>
        row.set('quantityConsumed', row.get('quantitySold') * row.get('recipeQuantity')),
      )
      .restructure(['date', 'productName', 'channelName', 'quantitySold', 'quantityConsumed']);

    const groupedDf = analysisDf.groupBy(...orderFields());

    return new DataFrame(
      Object.values(groupedDf[__groups__]).map(
        ({ groupKey, group }) => ({
          ...groupKey,
          quantitySold: group.stat.sum('quantitySold'),
          quantityConsumed: group.stat.sum('quantityConsumed'),
        }),
        [...groupedDf.on, 'quantitySold', 'quantityConsumed'],
      ),
    ).toCollection();
  };

  const orderFields = () => {
    const fields = [];

    if (dateChecked) {
      fields.push('date');
    }

    if (productChecked) {
      fields.push('productName');
    }

    if (channelChecked) {
      fields.push('channelName');
    }

    return fields;
  };

  if (!props.analysis) {
    return <div></div>;
  }

  return (
    <div>
      <ColumnsConfiguration
        channelChecked={channelChecked}
        dateChecked={dateChecked}
        handleChannelCheck={handleChannelCheck}
        handleDateCheck={handleDateCheck}
        handleProductCheck={handleProductCheck}
        productChecked={productChecked}
      />

      <Listing analytics={_.orderBy(aggregateAnalytics(), orderFields())} columns={columns} />
    </div>
  );
};
