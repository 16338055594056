import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding: 30px;
  background: ${(props) => props.theme.colors.greys.light};
  box-shadow: ${(props) =>
    props.theme.borders ? undefined : '0px 2px 4px rgba(122, 122, 122, 0.1)'};
`;

export const EmptyStateContainer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const MainText = styled.div`
  margin: 20px 0 10px 0;

  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.h2};
`;

export const SubText = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors.greys.darker};
  font: ${(props) => props.theme.fonts.textSmallHeight16};
`;

export const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 15px;
`;

export const EmptyStateIcon = styled.img`
  width: 120px;
  margin-left: 25px;
  margin-top: 40px;
  position: absolute;

  &.center {
    margin: auto;
    display: flex;
    position: relative;
  }

  &.image-loader {
    width: 3em;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;
