import styled from 'styled-components';

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  justify-content: space-between;
`;

export const FooterLeftContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  height: 40px;
`;

export const FooterRightContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: ${(props) => props.width || 'auto'};
  height: 40px;

  > div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LinkImage = styled.img`
  &:hover {
    cursor: pointer;
  }
`;
