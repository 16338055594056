import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;

  padding: 24px;
`;

export const SpacedDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
