import i18next from 'i18next';
import React from 'react';

import { Container, EmptyIconContainer, EmptyIcon, EmptyTextContainer } from './styledComponents';

const INPULSE_TOO_FAR = '/images/inpulse/empty-state-no-result-alt.svg';
const INPULSE_NO_DATA = '/images/inpulse/empty-state-no-result.svg';

const EmptyGraphStock = (props) => {
  const { tooFar } = props;
  return (
    <Container>
      <EmptyIconContainer>
        {tooFar ? (
          <EmptyIcon height={96} src={INPULSE_TOO_FAR} width={96} />
        ) : (
          <EmptyIcon height={96} src={INPULSE_NO_DATA} width={96} />
        )}
      </EmptyIconContainer>
      <EmptyTextContainer>
        {!tooFar
          ? i18next.t('ORDERS.ORDERS.FORM_STOCK_CHART_EMPTY_STATE')
          : i18next.t('ORDERS.ORDERS.FORM_STOCK_CHART_LIVERY_TOO_FAR')}
      </EmptyTextContainer>
    </Container>
  );
};

export default EmptyGraphStock;
