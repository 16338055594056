import { get } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { ENTITY_UNITS } from '@commons/constants/units';
import { numberToFixed } from '@commons/utils/format';
import DisplayNumber from '@commons/DisplayNumber';

export const METRIC_KEYS = {
  CURRENCY: 'inCurrency',
  UNIT: 'inUnit',
};

export const METRICS = [
  { name: 'GENERAL.METRIC_TURNOVER_CURRENCY', key: METRIC_KEYS.CURRENCY },
  { name: 'GENERAL.UNIT', key: METRIC_KEYS.UNIT },
];

const renderAnalyticValueByMetric = (value, item, metricKey) => {
  if (metricKey === METRIC_KEYS.CURRENCY) {
    return <DisplayNumber displayCurrencyCode={true} number={value} />;
  }

  const supplierProductPackagingUnit = get(item, 'masterUnit', null);
  const formattedPackagingUnit =
    supplierProductPackagingUnit === ENTITY_UNITS.UNIT ? 'u.' : supplierProductPackagingUnit;

  return numberToFixed(value, 2, '-', ` ${formattedPackagingUnit}`);
};

export const getListHeaders = (selectedMetric) => {
  const metricKey = get(selectedMetric, 'key', null);

  return [
    {
      id: 0,
      name: i18next.t('GENERAL.NAME'),
      propertyKey: 'name',
      large: true,
      isSortable: true,
    },
    {
      id: 1,
      name: i18next.t('GENERAL.SUPPLIER'),
      propertyKey: `supplier.name`,
      large: true,
      isSortable: true,
    },
    {
      id: 2,
      name: i18next.t('ANALYSIS.PURCHASES.COLUMN_NAME_TOT_PO'),
      propertyKey: `orderedVolume.${metricKey}`,
      tooltipDisplay: i18next.t('ANALYSIS.PURCHASES.COLUMN_TOOLTIP_TOT_PO'),
      isNumber: true,
      isSortable: true,
      filterType: 'numeric',
      render: (value, item) => renderAnalyticValueByMetric(value, item, metricKey),
    },
    {
      id: 3,
      name: i18next.t('ANALYSIS.PURCHASES.COLUMN_NAME_TOT_DN'),
      propertyKey: `invoicedVolume.${metricKey}`,
      tooltipDisplay: i18next.t('ANALYSIS.PURCHASES.COLUMN_TOOLTIP_TOT_DN'),
      isNumber: true,
      isSortable: true,
      filterType: 'numeric',
      render: (value, item) => renderAnalyticValueByMetric(value, item, metricKey),
    },
    {
      id: 4,
      name: i18next.t('ANALYSIS.PURCHASES.COLUMN_NAME_TOT_RECEIVED'),
      propertyKey: `receivedVolume.${metricKey}`,
      tooltipDisplay: i18next.t('ANALYSIS.PURCHASES.COLUMN_TOOLTIP_TOT_RECEIVED'),
      isNumber: true,
      isSortable: true,
      filterType: 'numeric',
      render: (value, item) => renderAnalyticValueByMetric(value, item, metricKey),
    },
    {
      id: 5,
      name: i18next.t('ANALYSIS.PURCHASES.COLUMN_NAME_RECEPTION_DEVIATION'),
      tooltipDisplay: i18next.t('ANALYSIS.PURCHASES.COLUMN_TOOLTIP_RECEPTION_DEVIATION'),
      propertyKey: `receivedDiff.${metricKey}`,
      isNumber: true,
      isSortable: true,
      filterType: 'numeric',
      render: (value, item) => renderAnalyticValueByMetric(value, item, metricKey),
    },
    {
      id: 6,
      name: i18next.t('ANALYSIS.PURCHASES.COLUMN_NAME_SERVICE_RATE'),
      propertyKey: 'serviceRate',
      tooltipDisplay: i18next.t('ANALYSIS.PURCHASES.COLUMN_TOOLTIP_SERVICE_RATE'),
      isNumber: true,
      isSortable: true,
      filterType: 'numeric',
      render: (value) => numberToFixed(value, 2, '-', '%'),
    },
  ];
};
