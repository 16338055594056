import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import { Button, Tooltip } from '@commons/utils/styledLibraryComponents';

import theme from '@theme';

import { Box, BoxInput, Container, Content, Section, Text } from './styledComponents';

export function incrementValue(value, setValue) {
  setValue(parseInt(value || 0) + 1);
}

export function decrementValue(value, setValue, minValue) {
  const updatedValue = parseInt(value || 0) - 1;

  if (updatedValue < minValue) {
    setValue(minValue);

    return;
  }

  setValue(updatedValue);
}

export function isValueValid(value) {
  if (value === '' || isNaN(value) || isNaN(parseInt(value)) || value < 0) {
    return false;
  }

  return true;
}

export function onEnterPress(e, value, closeModal, handleSave) {
  const isFormValid = isValueValid(value);

  if (e.key === 'Enter' && isFormValid) {
    handleSave(value);
    closeModal();
  }
}

export function handleValueChange(value, setValue) {
  if (!value) {
    setValue('');
  }

  if (isNaN(value) || isNaN(parseInt(value)) || value < 0) {
    return;
  }

  const formattedValue = parseInt(value.substring(0, 3));

  setValue(formattedValue);
}

export function renderHeader(productName, productCode, recommendation, t) {
  return (
    <Content className={'header'}>
      <Section>
        <Text>{productName}</Text>
        <Text className={'medium grey'}>{productCode}</Text>
      </Section>
      <Section>
        <div className={'reco'}>
          <Text className={'medium'}>{recommendation}</Text>
          <Text className={'medium'}>
            {` ${t('PRODUCTION.PRODUCTION.MODAL_RECOMMENDATION')}`}
            <Tooltip text={t('PRODUCTION.PRODUCTION.MODAL_TOOLTIP')} />
          </Text>
        </div>
      </Section>
    </Content>
  );
}

export function renderContent(value, setValue, closeModal, handleSave) {
  return (
    <Content className={'content'}>
      <Box className={'clickable'} onClick={() => decrementValue(value, setValue, 0)}>
        <svg
          fill="none"
          height="2"
          viewBox="0 0 15 2"
          width="15"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect fill={theme.colors.greys.darkest} height="2" width="15" />
        </svg>
      </Box>
      <BoxInput
        autoFocus={true}
        max="999"
        min="0"
        step="1"
        type="number"
        value={value}
        onChange={(e) => handleValueChange(e.target.value, setValue)}
        onKeyDown={(e) => onEnterPress(e, value, closeModal, handleSave)}
      />
      <Box className={'clickable'} onClick={() => incrementValue(value, setValue)}>
        <svg
          fill="none"
          height="15"
          viewBox="0 0 13 13"
          width="15"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.58333 0H5.41667V5.41667H0V7.58333H5.41667V13H7.58333V7.58333H13V5.41667H7.58333V0Z"
            fill={theme.colors.greys.darkest}
          />
        </svg>
      </Box>
    </Content>
  );
}

export function renderFooter(closeModal, handleSave, value, t, unsetActiveProduct) {
  const isFormValid = isValueValid(value);

  return (
    <Content className={'footer'}>
      <Section>
        <Button
          color={'blue-outline'}
          handleClick={() => {
            closeModal();
            unsetActiveProduct();
          }}
          icon={'/images/inpulse/close-black-small.svg'}
          label={t('GENERAL.CANCEL')}
        />
      </Section>

      <Section>
        <Button
          color={isFormValid ? 'blue' : 'grey'}
          handleClick={() => {
            if (isFormValid) {
              handleSave(value);
              closeModal();
            }
          }}
          icon={'/images/inpulse/check-white-small.svg'}
          label={t('GENERAL.VALIDATE')}
        />
      </Section>
    </Content>
  );
}

const EditToProduceModal = (props) => {
  const {
    closeModal,
    params: { product, toProduce, toProduceRecoDeepsight, handleSave, unsetActiveProduct },
  } = props;
  const { t } = useTranslation();

  const [value, setValue] = useState('');

  useEffect(() => {
    if (toProduce > 0) {
      setValue(toProduce);
    }
  }, [toProduce]);

  return (
    <Container>
      {renderHeader(product.name, product.code, toProduceRecoDeepsight, t)}
      {renderContent(value, setValue, closeModal, handleSave)}
      {renderFooter(closeModal, handleSave, value, t, unsetActiveProduct)}
    </Container>
  );
};

export default EditToProduceModal;
