import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  padding: 28px 40px 38px 40px;
`;

export const Header = styled.div`
  width: 100%;

  padding-bottom: 28px;

  font: ${(props) => props.theme.fonts?.h2};
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;
