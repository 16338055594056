import { connect } from 'react-redux';
import { get, keyBy } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import { TableForm } from '@commons/TableForm';

import { getClientInfo } from '@selectors/client';

import CreateIngredientModal from '@admin/components/CreateIngredientModal';

import { Container } from './styledComponents';
import { formatEntity } from './utils/format';
import { RECIPES_COMPONENTS_FIELD_ARRAY_NAME } from './utils/formInputsConfiguration';
import RecipeComponentsFormHeader from './components/RecipeComponentFormHeader';

const RecipeComposition = ({
  handleCallToCreateIngredient,
  allergens,
  isReadOnly,
  recipe,
  recipesAvailable,
  setWarningModalParams,
  supplierProductsAvailable,
  onSupplierProductsAvailableChange,
  compositions,
  channels,
  associatedProduct,
  isDuplicatingRecipe,
  isKitchen,
  tableColumns,
  recipeComponentsFormInputs,
  recipeComponentsForm,
  formFields,
  addEntity,
  deleteEntity,
  fields,
  replace,
}) => {
  const [openCreateIngredientModal, setOpenCreateIngredientModal] = useState(false);

  // ======================
  // ===== UseEffects =====
  // ======================

  useEffect(() => {
    const formFieldsByIds = keyBy(formFields, 'id');

    const formattedEntities = compositions.map((composition) => {
      if (get(formFieldsByIds, `[${composition.entity.id}]`, false)) {
        return formFieldsByIds[composition.entity.id];
      }

      return formatEntity(composition);
    });

    replace(formattedEntities);
  }, []);

  return (
    <Container>
      <TableForm
        columns={tableColumns}
        deleteFunction={deleteEntity}
        displayStatusIcon={false}
        emptyState={{
          text: i18next.t('ADMIN.RECIPES.COMPONENT_EMPTY'),
          width: INPUT_WIDTH.LARGE,
        }}
        fieldArrayName={RECIPES_COMPONENTS_FIELD_ARRAY_NAME}
        fields={fields}
        form={recipeComponentsForm}
        inputs={recipeComponentsFormInputs}
        isEditionAllowed={!isReadOnly}
        renderActions={() => (
          <RecipeComponentsFormHeader
            addEntity={addEntity}
            allergens={allergens}
            associatedProduct={associatedProduct}
            channels={channels}
            compositions={compositions}
            formFields={formFields}
            isDuplicatingRecipe={isDuplicatingRecipe}
            isKitchen={isKitchen}
            isReadOnly={isReadOnly}
            recipe={recipe}
            recipesAvailable={recipesAvailable}
            setOpenCreateIngredientModal={setOpenCreateIngredientModal}
            setWarningModalParams={setWarningModalParams}
            supplierProductsAvailable={supplierProductsAvailable}
            onSupplierProductsAvailableChange={onSupplierProductsAvailableChange}
          />
        )}
      />
      {!!openCreateIngredientModal && (
        <CreateIngredientModal
          handleCallToCreateIngredient={handleCallToCreateIngredient}
          resetIngredientCreation={() => {
            setOpenCreateIngredientModal(false);
          }}
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps)(RecipeComposition);
