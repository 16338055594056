import { isEmpty } from 'lodash';
import i18next from 'i18next';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CREATE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

import CreateTheoreticalInventorySelectors from './selectors';

const getCreateTheoreticalInventoryModalActions = (props) => {
  const {
    selectedStores,
    selectedDate,
    selectedILT,
    handleCreateTheoreticalInventory,
    resetCreateTheoreticalInventoryModal,
  } = props;

  const disabledAction = isEmpty(selectedStores) || !selectedDate || isEmpty(selectedILT);

  const selectedStoresIds = selectedStores.map((store) => store.id);

  return [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_CREATE_BUTTON(),
      preventClosing: true,
      handleClick: () => {
        handleCreateTheoreticalInventory(selectedStoresIds, selectedDate, selectedILT);
        resetCreateTheoreticalInventoryModal();
      },
      isDisabled: disabledAction,
    },
  ];
};

export const getCreateTheoreticalInventoryModalConfig = (props) => {
  const { resetCreateTheoreticalInventoryModal } = props;

  const params = {
    type: 'action',
    width: '542px',
    height: 'auto',
    icon: '/images/inpulse/add-black-small.svg',
    title: i18next.t('STOCKS.STOCKS.LIST_CREATE_THEORETICAL_INVENTORY'),
    handleCloseCleanUp: resetCreateTheoreticalInventoryModal,
    actions: getCreateTheoreticalInventoryModalActions(props),
    component: CreateTheoreticalInventorySelectors,
    data: props,
  };

  return params;
};
