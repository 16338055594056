import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 40px);

  overflow: auto;

  padding: 24px;
  padding-bottom: 0px;

  gap: 24px;
`;

export const HeaderContentContainer = styled.div`
  display: flex;

  gap: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

export const HeaderProductionTitle = styled.div`
  font: normal 700 16px/16px inter;
  color: ${(props) => props.theme.colors.greys.darkest};
`;

export const LabelContainer = styled.div`
  font: ${(props) => props.theme.fonts.textMiddleNormal};
  display: flex;
  flex-direction: row;
`;

export const LabelText = styled.div`
  margin-bottom: 8px;
`;

export const HeaderIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export const RoundPicture = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const NameCellContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  gap: 8px;
`;

export const Name = styled.div`
  font: ${({ theme }) => theme.fonts.textMiddleNormal};
  color: ${({ theme }) => theme.colors.greys.darkest};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NameHover = styled.div`
  background: ${({ theme }) => theme.colors.white};
  font: ${({ theme }) => theme.fonts.textMiddleNormal};
  position: absolute;
  padding: 18px 8px; // matches the datagrid line height
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

  ${(props) => {
    let offset = 50; // Detail panel toggle column

    if (props.addSelectionOffset) {
      offset += 50; // selection column (only for recipes)
    }

    if (props.offsetName) {
      offset += 50; // name column includes pictures on the left of the name
    }

    if (props.offsetCategory) {
      offset += 240; // entire name column width
    }

    return css`
      left: ${offset}px;
    `;
  }}
`;

export const InnerLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px;
  gap: 16px;
  position: sticky;
  left: 0;
  width: ${(props) => `${props.width}px`};
`;

export const MenuIcon = styled.img`
  padding-right: 16px;
`;
