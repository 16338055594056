import styled from 'styled-components';

export const Container = styled.div`
  width: calc(100% - 64px);
  height: 64px;

  padding: 0px 24px;

  display: flex;
  justify-content: start;
  align-items: center;

  position: fixed;
  bottom: 0;
  z-index: 9;

  border-radius: 0px 0px 16px 16px;
  background: ${(props) => props.theme.colors.greys.lightest};
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.1);
`;
