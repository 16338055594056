import i18next from 'i18next';
import moment from 'moment';
import React from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { InpulseLabel } from '@commons/DeepsightComponents';
import DisplayNumber from '@commons/DisplayNumber';
import Text, { ENUM_COLORS } from '@commons/Text';

import { statusLegend, statusColorCodes } from '@orders/OrderList/common/constants';

export const get = () => [
  {
    name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDER_COLUMN_STATUS'),
    render: ({ status }) => {
      const label = Object.keys(statusLegend).includes(status)
        ? i18next.t(statusLegend[status].name)
        : i18next.t('ORDERS.ORDERS.LIST_LABEL_STATUS_NOT_DEFINED');

      const statusColor = statusColorCodes.find(({ id }) => id == status);

      // div tag is used to not apply flex width on InpulseLabel
      return (
        <div>
          <InpulseLabel color={statusColor.inpulseColor} text={label} type={'tertiary'} />
        </div>
      );
    },
  },
  {
    name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDER_COLUMN_DELIVERY_DATE'),
    render: ({ startOrderDate }) => (
      <Text color={ENUM_COLORS.DARKEST}>
        {moment(startOrderDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)}
      </Text>
    ),
  },
  {
    name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDER_COLUMN_TOTAL'),
    render: ({ totalPrice }) => <DisplayNumber displayCurrencyCode={true} number={totalPrice} />,
  },
  {
    name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDER_COLUMN_REFERENCE'),
    render: ({ reference }) => <Text color={ENUM_COLORS.DARKEST}>{reference}</Text>,
  },
];

export default { get };
