import { connect } from 'react-redux';
import { first } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import { showConfirmationMessage } from '@actions/messageconfirmation';

import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { getAnalysisByIngredient } from '../../services';

import { Container } from './styledComponents';
import AnalysisByIngredientDetail from './AnalysisByIngredientDetail';
import Header from './Header';

export const IngredientAnalytics = ({
  stores,
  clientId,
  showMessage,
  ingredientId,
  recipeByIngredientId,
}) => {
  const [analysis, setAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  useEffect(() => {
    if (!stores || !stores.length) {
      setSelectedStore(null);

      return;
    }

    setSelectedStore(first(stores));
  }, [stores]);

  useEffect(() => {
    if (!selectedStore || !selectedDateRange) {
      setAnalysis(null);

      return;
    }
  }, [selectedStore, selectedDateRange]);

  const handleSubmit = async () => {
    setAnalysis(null);
    setIsLoading(true);

    try {
      const result = await getAnalysisByIngredient(
        clientId,
        recipeByIngredientId[ingredientId],
        [selectedStore.id],
        moment(selectedDateRange.startDate).format('YYYY-MM-DD'),
        moment(selectedDateRange.endDate).format('YYYY-MM-DD'),
        ingredientId,
      );

      setAnalysis(result);
    } catch (err) {
      showMessage('Impossible de récupérer les analyses', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  if (!ingredientId) {
    return <div></div>;
  }

  return (
    <Container>
      <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.H2_INTER}>
        Analyse de la consommation de l'ingrédient
      </Text>

      <Header
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        selectedStore={selectedStore}
        setSelectedDateRange={setSelectedDateRange}
        setSelectedStore={setSelectedStore}
        stores={stores}
      />

      <AnalysisByIngredientDetail
        analysis={analysis}
        unit={recipeByIngredientId[ingredientId][0].entityUnit}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IngredientAnalytics);
