/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

export const useScrollListener = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  element: any,
  handleScroll: Function
): void => {
  React.useEffect(() => {
    const currentElement = element.current;

    if (currentElement) {
      currentElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      currentElement?.removeEventListener('scroll', handleScroll);
    };
  }, [element, handleScroll]);
};

export default useScrollListener;
