import { APIcaller } from '../APIcaller';

const getAuthorizedActionsByAccountRoleId = async (accountRoleId) =>
  APIcaller.apiGet(`/backoffice/roles/${accountRoleId}/actions`);

const assignFeatureActionsByAccountRoleId = async (accountRoleId, featureActionsIds) =>
  APIcaller.apiPost(`/backoffice/roles/${accountRoleId}/actions`, {
    accountRoleId,
    featureActionsIds,
  });

export const authorizedActions = {
  getAuthorizedActionsByAccountRoleId,
  assignFeatureActionsByAccountRoleId,
};
