import styled from 'styled-components';

export const FormattedInfoDisplay = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  flex-basis: ${(props) => `calc(${100 / props.nbColumns}%)`};

  font: ${(props) =>
    !props.isBold ? props.theme.fonts.textMiddleNormal : props.theme.fonts.textMiddleInterBold};
`;

export const LabelContainer = styled.div`
  width: fit-content;
`;

export const ConditioningColumnBigger = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  background: white;
  white-space: nowrap;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
  font: ${(props) => props.theme.fonts?.textBig || ''};
  padding: 0 16px;
`;

export default {
  FormattedInfoDisplay,
  LabelContainer,
  ConditioningColumnBigger,
};
