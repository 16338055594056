import styled from 'styled-components';

import { BREAKPOINT } from '@commons/utils/responsive';

export const CanvasChart = styled.canvas`
  height: 100%;
`;

export const ChartContainer = styled.div`
  height: 100%;
  min-height: 384px;

  @media (min-width: ${BREAKPOINT.MEDIUM}) {
    min-height: 344px;
  }
  ${(props) => props.customGraphStyle}
`;

export default {
  CanvasChart,
  ChartContainer,
};
