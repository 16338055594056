import _ from 'lodash';
import i18next from 'i18next';

import utilsXLS from '@commons/utils/makeXLS';

const ERROR_MESSAGES_BY_CODE = {
  404: 'NOT_EXIST_ERROR',
  422: 'NOT_BELONG_TO_CLIENT_ERROR',
  '200-price': 'PRICE_ERROR',
  '200-tva': 'VAT_RATE_ERROR',
  '200-price-tva': 'PRICE_AND_VAT_RATE_ERROR',
  '200-scheduled-price': 'SCHEDULED_PRICE_ERROR',
  '200-scheduled-start-date': 'SCHEDULED_START_DATE_ERROR',
  '200-scheduled-price-and-start-date': 'SCHEDULED_PRICE_AND_START_DATE_ERROR',
  '200-price-editable-at-reception': 'PRICE_EDITABLE_AT_RECEPTION_ERROR',
  '200-ean-13': 'EAN_13_ERROR',
};

const formatUnValidatedSupplierProductsForExport = (supplierProducts) =>
  supplierProducts.map((supplierProduct) => ({
    ...supplierProduct,
    error: i18next.t(
      `ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_PRICE_EXPORT_${
        ERROR_MESSAGES_BY_CODE[supplierProduct.error] || 'UNKNOWN_ERROR'
      }`,
    ),
  }));

export const exportSupplierProductErrors = (data) => {
  const columns = [
    {
      propertyKey: 'supplierName',
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_SUPPLIER'),
    },
    { propertyKey: 'name', name: i18next.t('GENERAL.NAME') },
    {
      propertyKey: 'category',
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_CATEGORY'),
    },
    {
      propertyKey: 'subCategory',
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_SUB_CATEGORY'),
    },
    { propertyKey: 'error', name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_ERROR') },
  ];

  const sheet = utilsXLS.generateDefaultSheet(
    i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_PRICE_EXPORT_SHEETNAME'),
    columns,
    formatUnValidatedSupplierProductsForExport(_.get(data, 'unValidated')),
  );

  utilsXLS.makeXLS(
    i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_EXPORT_TITLE'),
    [sheet],
  );
};
