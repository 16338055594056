import PropTypes from 'prop-types';
import React, { memo } from 'react';

import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { Item, FlexDiv } from '../styledComponents';

const memoizeCondition = (prevProps, props) => prevProps.supplierName === props.supplierName;

const Supplier = ({ supplierName, nbColumns }) => (
  <Item nbColumns={nbColumns}>
    <FlexDiv>
      <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}>
        {supplierName}
      </Text>
    </FlexDiv>
  </Item>
);

Supplier.propTypes = {
  supplierName: PropTypes.string.isRequired,
  nbColumns: PropTypes.number,
};

Supplier.defaultProps = {
  nbColumns: 1,
};

export default memo(Supplier, memoizeCondition);
