enum COLOR {
  BLUE = 'blue',
  BLUE_OUTLINE = 'blue-outline',
  BLUE_OUTLINE_NO_SHADOW = 'blue-outline-no-shadow',
  DARK_BLUE = 'dark-blue',
  STRONG_DARK_BLUE = 'strong-dark-blue',
  LIGHT_BLUE = 'light-blue',
  TRANSPARENT_HOVER_LIGHT_BLUE = 'transparent-hover-light-blue',
  TRANSPARENT_NO_HOVER = 'transparent-no-hover',
  GREEN = 'green',
  DARK_GREEN = 'dark-green',
  GREY = 'grey',
  ORANGE = 'orange',
  RED = 'red',
  WHITE = 'white',
  LOCKED = 'locked',
  DEFAULT = 'default',
  INPULSE_DEFAULT = 'inpulse-default',
  INPULSE_OUTLINE = 'inpulse-outline',
  INPULSE_GREY = 'inpulse-grey',
  INPULSE_FILTER = 'inpulse-filter',
  INPULSE_LINK = 'inpulse-link',
  INPULSE_DELETE = 'inpulse-delete',
}

export { COLOR as default };
