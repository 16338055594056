import i18next from 'i18next';

import { formatNumber } from '@commons/DisplayNumber';
import { translatedUnitCondensed } from '@commons/utils/translateUnit';

const renderWithUnit = (value, row) => {
  if (!value) {
    return 0;
  }

  return `${formatNumber(value, 0, true)} ${translatedUnitCondensed(row.ingredientUnit)}`;
};

export const getListHeaders = (dateChecked, productChecked, channelChecked) => {
  const headers = [];

  if (dateChecked) {
    headers.push({
      name: i18next.t('GENERAL.DATE'),
      propertyKey: 'date',
      large: true,
      isSortable: true,
    });
  }

  if (productChecked) {
    headers.push({
      name: i18next.t('GENERAL.PRODUCT'),
      propertyKey: 'productName',
      large: true,
      isSortable: true,
    });
  }

  if (channelChecked) {
    headers.push({
      name: i18next.t('GENERAL.CHANNEL'),
      propertyKey: 'channelName',
      isSortable: true,
    });
  }

  if (productChecked) {
    // Recipe quantity should only be present if product is selected, but always after channel
    headers.push({
      name: i18next.t('ANALYSIS.SALES.INGREDIENT_CONSUMPTION_COLUMN_UNITARY_CONSUMPTION'),
      propertyKey: 'recipeQuantity',
      tooltipDisplay: i18next.t(
        'ANALYSIS.SALES.INGREDIENT_CONSUMPTION_COLUMN_TOOLTIP_UNITARY_CONSUMPTION',
      ),
      isNumber: true,
      isSortable: true,
      render: (value, row) => renderWithUnit(value, row),
    });
  }

  headers.push(
    {
      name: i18next.t('ANALYSIS.SALES.INGREDIENT_CONSUMPTION_COLUMN_SOLD_PRODUCTS'),
      propertyKey: 'quantitySold',
      tooltipDisplay: i18next.t(
        'ANALYSIS.SALES.INGREDIENT_CONSUMPTION_COLUMN_TOOLTIP_SOLD_PRODUCTS',
      ),
      isNumber: true,
      isSortable: true,
    },
    {
      name: i18next.t('ANALYSIS.SALES.INGREDIENT_CONSUMPTION_COLUMN_TOTAL'),
      propertyKey: 'totalConsumption',
      isNumber: true,
      isSortable: true,
      render: (value, row) => renderWithUnit(value, row),
    },
  );

  return headers;
};
