import styled, { css } from 'styled-components';

export const LoadingContainer = styled.div`
  margin: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background-color: #ffffff;
  border: 1px solid transparent;
  opacity: ${(props) => (props.opacity ? props.opacity : 1)};
`;

export const LoadingRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 54px;
  padding: 0 25px 0 0;
`;

export const LoadingItemContainer = styled.div`
  padding: 0 0 0 15px;
  width: 100%;
  flex: ${(props) => (props.large ? 2 : 1)};

  ${(props) =>
    props.large &&
    !props.narrow &&
    css`
      flex-basis: calc(${(100 / props.nbColumn) * 2}%);
    `}

  ${(props) =>
    props.narrow &&
    css`
      flex-basis: 0;
    `}

  ${(props) =>
    !props.large &&
    !props.narrow &&
    css`
      flex-basis: calc(${100 / props.nbColumn}%);
    `}

  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth}px;
    `}
`;

export default {
  LoadingContainer,
  LoadingRow,
  LoadingItemContainer,
};
