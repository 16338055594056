import { connect } from 'react-redux';
import { get } from 'lodash';
import React from 'react';

import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { getCentralKitchenStores } from '@selectors/stores';

import { INVENTORY_TYPE } from '@stocks/StocksInventories/common/constants';
import InventoryListView from '@stocks/StocksInventories/components/InventoryListView';

const StocksCentralKitchenInventoriesSupplierProducts = (props) => {
  const path = get(props.match, 'path');

  return (
    <>
      <NavigationBreadCrumb featurePath={path} />
      <InventoryListView inventoryType={INVENTORY_TYPE.STOCK} {...props} isCentralKitchenView />
    </>
  );
};

const mapStateToProps = (state) => ({
  stores: getCentralKitchenStores(state.baseReducer.activeStores),
});

export default connect(mapStateToProps)(StocksCentralKitchenInventoriesSupplierProducts);
