import React, { Fragment } from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';

import { PropsChildItemRow } from '../../interface';
import {
  ExpandedItemRow,
  ItemChildDisplayWithGraph,
  ItemChildDisplayWithModal,
  ItemChildContainer,
  CustomModalContainer,
  ItemChildGraphContainer,
  Separator,
  ExpandedRowContainer,
} from './styledComponents';
import { numberToFixed } from '../../../utils/format';
import ItemDisplayChild from '../ItemDisplayChild/ItemDisplayChild';
import ActionButton from '../ActionButton/ActionButton';
import GraphButton from '../GraphButton/GraphButton';
import CustomModalButtonDisplay from '../CustomModalButton/CustomModalButtonDisplay';

const ChildItemRowDisplay = (props: PropsChildItemRow): JSX.Element => {
  const {
    theme,
    isLast,
    isFirst,
    headers,
    childItems,
    childChart,
    openChildGraph,
    customModalIcon,
    openChildModal,
  } = props;

  return (
    <ExpandedRowContainer isFirst={isFirst} isLast={isLast}>
      <ExpandedItemRow onClick={(e) => e.stopPropagation()}>
        {headers.map((header, index) => {
          const uniqId = `child-row-${index}`;
          let childValue: string | number = '-';
          if (childItems[header.propertyKey]) {
            childValue = header.isNumber
              ? numberToFixed(
                  childItems[header.propertyKey],
                  2,
                  '-',
                  header.unit
                )
              : childItems[header.propertyKey];
          }

          const isAction = header.propertyKey === 'action';
          return (
            <Fragment key={uniqId}>
              {!isAction &&
                !header.graphConfiguration &&
                !header.customModalRender && (
                  <ItemDisplayChild
                    id={childItems.id}
                    value={
                      header.render
                        ? header.render(
                            _.get(childItems, `${header.propertyKey}`),
                            childItems
                          )
                        : childValue
                    }
                    isFirst={index === 0}
                    large={header.large}
                    narrow={header.narrow}
                    minWidth={header.minWidth}
                    textDecorationChild={header.textDecorationChild}
                    nbColumn={headers.length}
                    hrefPrefix={header.hrefPrefix}
                    hrefSuffix={header.hrefSuffix}
                    withRender={!!header.graphConfiguration}
                  />
                )}

              {!isAction &&
                !header.graphConfiguration &&
                header.customModalRender && (
                  <ItemChildDisplayWithModal
                    nbColumn={headers.length}
                    minWidth={header.minWidth}
                  >
                    <ItemChildContainer>
                      <ItemDisplayChild
                        id={childItems.id}
                        value={
                          header.render
                            ? header.render(
                                _.get(childItems, `${header.propertyKey}`),
                                childItems
                              )
                            : childValue
                        }
                        isFirst={index === 0}
                        large={header.large}
                        textDecorationChild={header.textDecorationChild}
                        nbColumn={headers.length}
                        hrefPrefix={header.hrefPrefix}
                        hrefSuffix={header.hrefSuffix}
                        withRender={header.customModalRender}
                      />
                    </ItemChildContainer>

                    <CustomModalContainer>
                      <CustomModalButtonDisplay
                        theme={theme}
                        icon={customModalIcon}
                        modalContent={childItems.modalContent}
                        openModal={openChildModal}
                        openedModal={childItems.modalOpened}
                        customModalTooltip={header.customModalTooltip}
                        displayTooltip={false}
                      />
                    </CustomModalContainer>
                  </ItemChildDisplayWithModal>
                )}

              {!isAction && header.graphConfiguration && (
                <ItemChildDisplayWithGraph
                  nbColumn={headers.length}
                  minWidth={header.minWidth}
                >
                  <ItemChildGraphContainer>
                    <ItemDisplayChild
                      id={childItems.id}
                      value={
                        header.render
                          ? header.render(
                              _.get(childItems, `${header.propertyKey}`),
                              childItems
                            )
                          : childValue
                      }
                      isFirst={index === 0}
                      large={header.large}
                      textDecorationChild={header.textDecorationChild}
                      nbColumn={headers.length}
                      hrefPrefix={header.hrefPrefix}
                      hrefSuffix={header.hrefSuffix}
                      withRender={!!header.graphConfiguration}
                    />
                  </ItemChildGraphContainer>

                  <CustomModalContainer>
                    <GraphButton
                      theme={theme}
                      open={childItems.graphOpened}
                      openGraph={openChildGraph}
                      graphConfiguration={header.graphConfiguration}
                      graphs={childChart}
                    />
                  </CustomModalContainer>
                </ItemChildDisplayWithGraph>
              )}

              {isAction && <ActionButton />}
            </Fragment>
          );
        })}
      </ExpandedItemRow>
      {!isLast && <Separator />}
    </ExpandedRowContainer>
  );
};

ChildItemRowDisplay.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      propertyKey: PropTypes.string.isRequired,
      isNumber: PropTypes.bool,
      unit: PropTypes.string,
      tooltipDisplay: PropTypes.string,
      large: PropTypes.bool,
      bold: PropTypes.bool,
      textTransform: PropTypes.string,
      textDecorationChild: PropTypes.string,
      withRender: PropTypes.bool,
      hrefPrefix: PropTypes.string,
      hrefSuffix: PropTypes.string,
      graphConfiguration: PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
      }),
    })
  ).isRequired,
  childItems: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.func,
    ])
  ).isRequired,
  childChart: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string.isRequired,
      y: PropTypes.number.isRequired,
    })
  ),
  openChildGraph: PropTypes.func.isRequired,
  openChildModal: PropTypes.func,
};

ChildItemRowDisplay.defaultProps = {
  childChart: null,
  openChildModal: null,
};

export default ChildItemRowDisplay;
