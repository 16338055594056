import { cloneDeep, get, groupBy, isEmpty, keyBy } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { computeRecipeProductionTotal } from './utils/compute';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { Dropdown, INPUT_WIDTH, SearchBar, Button } from '@commons/utils/styledLibraryComponents';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import { PastDayDatePicker } from '@commons/DatePickers/PastDayDatePicker';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import normalizeStringValue from '@commons/utils/normalizeStringValue';

import { loading, loadingSuccess } from '@actions/loading';
import { openSmallModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { getCentralKitchenStores } from '@selectors/stores';
import { getClientInfo } from '@selectors/client';

import centralService from '@services/central';

import LoadingState from '../../orders/OrderList/components/OrderForm/components/LoadingState/LoadingState';

import { Filter, PageContentContainer, ProductionFormContainer } from './styledComponents';

import ProductionCategoryContent from './components/ProductionCategoryContent';
import ProductionCategorySumup from './components/ProductionCategorySumup';

import ProductionOperationExportModal from './components/ProductionOperationExportModal';

const FILTER_KEY = 'category';

const CentralKitchenProductionOperations = (props) => {
  const {
    match: { path },
    stores,
    showErrorMessage,
    showSuccessMessage,
    pageLoading,
    pageLoaded,
    featuresKeyByPath,
    openModalExportInfo,
    client: { storeName },
  } = props;

  // Pair of category - true/false boolean to say if a category need to be unfolded
  const [categoriesToUnfold, setCategoriesToUnfold] = useState({});
  const [categoriesToUnfoldDuringSearch, setCategoriesToUnfoldDuringSearch] = useState({});
  const [searchInput, setSearchInput] = useState('');
  const [filteredRecipesToProduce, setFilteredRecipesToProduce] = useState([]);

  const [isLoadingRecipesAndDate, setIsLoadingRecipesAndDate] = useState(true);
  const [isLoadingQuantities, setIsLoadingQuantities] = useState(false);

  // Contains recipes of selected store who can be produced
  const [recipesToProduce, setRecipesToProduce] = useState([]);
  const [recipesGroupedByKey, setRecipesGroupedByKey] = useState({});
  const [displayEmptyState, setDisplayEmptyState] = useState(false);
  const [displaySearchEmptyState, setDisplaySearchEmptyState] = useState(false);

  const [productionQuantities, setProductionQuantities] = useState({});
  const [plannedProductions, setPlannedProductions] = useState({});

  const [selectedStore, setSelectedStore] = useState(stores[0]);
  const [selectedDate, setSelectedDate] = useState(moment.tz(selectedStore.timezone));
  const [currentDateWithTz, setCurrentDateWithTz] = useState();
  const [focusedMonth, setFocusedMonth] = useState(moment());
  const [highlightedDates, setHighlightedDates] = useState([]);

  /** USE EFFECTS */

  useEffect(() => {
    setIsLoadingRecipesAndDate(true);

    setRecipesToProduce([]);
    setDisplayEmptyState(false);

    (async function loadData() {
      try {
        const fetchedRecipesToProduce = await centralService.getMappedRecipesListByStoreId(
          selectedStore.id,
        );

        setRecipesToProduce(fetchedRecipesToProduce);

        setRecipesGroupedByKey(
          groupBy(fetchedRecipesToProduce, (recipe) => get(recipe, FILTER_KEY, '')),
        );

        setCurrentDateWithTz(moment().tz(selectedStore.timezone));

        if (isEmpty(fetchedRecipesToProduce)) {
          return;
        }

        await getProductionQuantities();

        await getPlannedProductions();
      } catch {
        showErrorMessage(i18next.t('PRODUCTION.CENTRAL_KITCHEN.RECIPE_LOADING_FAILURE'));

        setRecipesToProduce([]);
        setRecipesGroupedByKey({});
      } finally {
        setIsLoadingRecipesAndDate(false);
      }
    })();
  }, [selectedStore]);

  useEffect(() => {
    setDisplaySearchEmptyState(!isEmpty(searchInput) && filteredRecipesToProduce.length === 0);
  }, [searchInput, filteredRecipesToProduce]);

  useEffect(() => {
    setDisplayEmptyState(recipesToProduce.length === 0 || displaySearchEmptyState);
  }, [recipesToProduce, displaySearchEmptyState]);

  useEffect(() => {
    if (isLoadingRecipesAndDate || isEmpty(recipesToProduce)) {
      return;
    }

    setIsLoadingQuantities(true);

    (async function loadData() {
      try {
        await getProductionQuantities();

        await getPlannedProductions();
      } catch {
        showErrorMessage(i18next.t('PRODUCTION.CENTRAL_KITCHEN.RECIPE_LOADING_FAILURE'));

        setProductionQuantities({});
        setPlannedProductions({});
      } finally {
        setIsLoadingQuantities(false);
      }
    })();
  }, [selectedDate, isLoadingRecipesAndDate]);

  useEffect(() => {
    if (!!selectedStore) {
      (async function fetchDates() {
        const dates = await getDatesWithProductionForMonth(focusedMonth, selectedStore);

        setHighlightedDates(dates);
      })();
    }
  }, [focusedMonth, selectedStore]);

  useEffect(() => {
    if (isEmpty(searchInput)) {
      setFilteredRecipesToProduce(recipesToProduce);
      setCategoriesToUnfoldDuringSearch({});
      return;
    }

    // Search is active
    const filteredRecipes = recipesToProduce.filter(({ name }) =>
      normalizeStringValue(name).includes(normalizeStringValue(searchInput)),
    );
    setFilteredRecipesToProduce(filteredRecipes);

    // Unfold all concerned categories
    const categories = Object.keys(recipesGroupedByKey);

    const searchCategoriesToUnfold = categories.reduce((acc, category) => {
      acc[category] = true;

      return acc;
    }, {});

    setCategoriesToUnfoldDuringSearch(searchCategoriesToUnfold);
  }, [searchInput, recipesToProduce]);

  useEffect(() => {
    setRecipesGroupedByKey(groupBy(filteredRecipesToProduce, (recipe) => recipe[FILTER_KEY]));
  }, [filteredRecipesToProduce]);

  /** FUNCTIONS */

  const handleCategoryUnfolding = (selectedCategory) => {
    const isSearchActive = !isEmpty(searchInput);

    const selectedCategories = isSearchActive
      ? cloneDeep(categoriesToUnfoldDuringSearch)
      : cloneDeep(categoriesToUnfold);

    const unfoldedCategories = isSearchActive ? categoriesToUnfoldDuringSearch : categoriesToUnfold;
    const isAlreadyUnfolded = unfoldedCategories[selectedCategory];

    const updatedCategoriesToUnfold = {
      ...selectedCategories,
      [selectedCategory]: !isAlreadyUnfolded,
    };

    if (isSearchActive) {
      setCategoriesToUnfoldDuringSearch(updatedCategoriesToUnfold);
      return;
    }

    setCategoriesToUnfold(updatedCategoriesToUnfold);
  };

  const saveRecipeProduction = async (recipeId, quantity) => {
    pageLoading();

    try {
      await centralService.createRecipeProduction(
        selectedStore.id,
        recipeId,
        selectedDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        quantity,
      );

      showSuccessMessage(i18next.t('PRODUCTION.CENTRAL_KITCHEN.RECIPE_PRODUCTION_SAVE_SUCCESS'));

      const producedQuantity = productionQuantities[recipeId] || 0;
      const updatedProductionQuantity = computeRecipeProductionTotal(producedQuantity, quantity);

      setProductionQuantities({ ...productionQuantities, [recipeId]: updatedProductionQuantity });
    } catch {
      showErrorMessage(i18next.t('PRODUCTION.CENTRAL_KITCHEN.RECIPE_PRODUCTION_SAVE_FAILURE'));
    } finally {
      pageLoaded();
    }
  };

  const getDatesWithProductionForMonth = async (currentFocusedMonth, currentSelectedStore) => {
    setIsLoadingRecipesAndDate(true);

    const startDate = moment(currentFocusedMonth)
      .startOf('month')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
    const endDate = moment(currentFocusedMonth)
      .endOf('month')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    try {
      const dates = await centralService.getRecipesProductionDates(
        currentSelectedStore.id,
        startDate,
        endDate,
      );

      return dates.map((date) => moment(date));
    } catch {
      showErrorMessage(
        i18next.t('PRODUCTION.CENTRAL_KITCHEN.RECIPE_PRODUCTION_DATES_FETCH_FAILURE'),
      );

      return [];
    } finally {
      setIsLoadingRecipesAndDate(false);
    }
  };

  const getProductionQuantities = async () => {
    const fetchedRecipesByDate = await centralService.getRecipeProductionByStoreIdAndDate(
      selectedStore.id,
      selectedDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
    );

    const formattedProductionQuantities = fetchedRecipesByDate.reduce(
      (acc, { entityId, quantity }) => {
        acc[entityId] = quantity;
        return acc;
      },
      {},
    );

    setProductionQuantities(formattedProductionQuantities);
  };

  const getPlannedProductions = async () => {
    const formattedDate = selectedDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    const plannedProductions = await centralService.getProductionPlanning(
      selectedStore.id,
      formattedDate,
      formattedDate,
    );

    const formattedPlannedProductions = plannedProductions.reduce((acc, { entityId, quantity }) => {
      acc[entityId] = quantity;
      return acc;
    }, {});

    setPlannedProductions(formattedPlannedProductions);
  };

  const handleExport = () => {
    const recipesKeyById = keyBy(recipesToProduce, 'id');

    openModalExportInfo({
      component: ProductionOperationExportModal,
      customStyle: {
        overflow: 'initial',
      },
      selectedStore,
      storeName,
      searchInput,
      date: selectedDate,
      recipesKeyById,
    });
  };

  return (
    <>
      <NavigationBreadCrumb featurePath={path} />
      <PageContentContainer>
        <LeftRightSplitter
          left={
            <Filter>
              <Dropdown
                iconSrc={'/images/inpulse/central-black-small.svg'}
                isDisabled={isLoadingRecipesAndDate || stores.length === 1}
                items={stores}
                searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                selectedItem={selectedStore}
                width={INPUT_WIDTH.LARGE}
                isRequired
                onSelectionChange={setSelectedStore}
              />

              <PastDayDatePicker
                date={selectedDate}
                disabled={isLoadingRecipesAndDate}
                highlightedDates={highlightedDates}
                maxFutureDate={currentDateWithTz}
                timezone={selectedStore.timezone}
                onDateChange={(value) => {
                  setSelectedDate(value);
                }}
                onNextMonthClick={(nextMonth) => setFocusedMonth(nextMonth)}
                onPrevMonthClick={(prevMonth) => setFocusedMonth(prevMonth)}
              />

              <SearchBar
                disabled={isLoadingRecipesAndDate}
                placeholder={i18next.t('GENERAL.SEARCH')}
                setValue={setSearchInput}
                value={searchInput}
              />
            </Filter>
          }
          right={
            <Filter>
              <Button
                color={'inpulse-default'}
                handleClick={handleExport}
                icon={'/images/inpulse/file-download-white-small.svg'}
                label={i18next.t('GENERAL.EXPORT')}
              />
            </Filter>
          }
        />
        {isLoadingRecipesAndDate || isLoadingQuantities ? (
          <LoadingState noMargin />
        ) : (
          <>
            {displayEmptyState ? (
              <EmptyState
                icon={
                  displaySearchEmptyState
                    ? '/images/inpulse/no-results-search.svg'
                    : '/images/inpulse/empty-state-recipe-production.svg'
                }
                label={i18next.t(
                  displaySearchEmptyState
                    ? 'STOCKS.RECIPES.RECIPE_STOCK_FORM_SEARCH_EMPTY_STATE_LABEL'
                    : 'PRODUCTION.CENTRAL_KITCHEN.RECIPE_EMPTY_STATE_LABEL',
                )}
              />
            ) : (
              <>
                {Object.keys(recipesGroupedByKey).map((key, index) => {
                  const isCategoryOpen = !isEmpty(searchInput)
                    ? categoriesToUnfoldDuringSearch[key]
                    : categoriesToUnfold[key];

                  const productionQuantitiesCount = recipesGroupedByKey[key].reduce(
                    (acc, { id }) => {
                      if (Object.keys(productionQuantities).includes(id)) {
                        acc += 1;
                      }
                      return acc;
                    },
                    0,
                  );

                  const productionRatio = `${productionQuantitiesCount}/${recipesGroupedByKey[key].length}`;

                  return (
                    <ProductionFormContainer displayBottomBorder={isCategoryOpen} key={index}>
                      <ProductionCategorySumup
                        handleCategoryUnfolding={handleCategoryUnfolding}
                        isCategoryOpen={isCategoryOpen}
                        name={key}
                        total={productionRatio}
                      />
                      {isCategoryOpen && (
                        <ProductionCategoryContent
                          handleQuantityUpdate={saveRecipeProduction}
                          hasProductionPlanning={
                            !!featuresKeyByPath['/central-kitchen-production/planning'] // if this happens again for another feature, refacto and maybe use context
                          }
                          plannedProductions={plannedProductions}
                          productionQuantities={productionQuantities}
                          readOnly={false}
                          recipes={recipesGroupedByKey[key]}
                          selectedDate={selectedDate}
                        />
                      )}
                    </ProductionFormContainer>
                  );
                })}
              </>
            )}
          </>
        )}
      </PageContentContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  stores: getCentralKitchenStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
  featuresKeyByPath: state.baseReducer.featuresKeyByPath,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CentralKitchenProductionOperations);
