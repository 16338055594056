import { cloneDeep, get, intersectionBy, isEmpty, isEqual, omit, sortBy } from 'lodash';
import { connect } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

// ACTIONS
import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// COMMONS
import { buildSchema } from '@commons/GenericForm';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { doesIngredientsHaveApplicableUnits, getIngredientUnitName } from '@commons/utils/units';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { translatedUnitCondensed } from '@commons/utils/translateUnit';
import InputContentModal from '@commons/Modals/InputContentModal';
import normalizeStringValue from '@commons/utils/normalizeStringValue';
import WhiteCardForm from '@commons/WhiteCardForm';

// SELECTOR
import { canEditSupplierProductFromDetailView } from '@selectors/actions/supplierProductActions';
import { getAuthorizedActions, getDisplayManufacturerName } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

// SERVICES
import { ingredient as ingredientService } from '@services/ingredient';
import { storeSupplierProductMapping } from '@services/storeSupplierProductMapping';
import { supplierProduct as supplierProductService } from '@services/supplierProduct';
import { supplier as supplierService } from '@services/supplier';
import centralService from '@services/central';
import clientService from '@services/client';
import storageAreaService from '@services/storageArea';
import storeService from '@services/store';

import {
  CAT_AND_SUB_CAT_TYPES,
  INVENTORY_LIST_TEMPLATE_TYPE,
  STORAGE_AREA_TYPE,
} from '@admin/suppliers/supplierProducts/utils/constants';
import {
  DYNAMIC_MODAL_ALREADY_USED,
  DYNAMIC_MODAL_CLOSE_CONFIRMATION,
  DYNAMIC_MODAL_INPUT_LABEL,
  DYNAMIC_MODAL_TITLE,
  DYNAMIC_PROPERTIES,
} from '@admin/commons/dynamicModalProperties';
import { UNITS_DROPDOWN_ITEMS } from '@admin/utils/DropdownItems';
import HeaderPicture from '@admin/components/HeaderPicture';
import PackagingSection from '@admin/suppliers/supplierProducts/components/PackagingSection';

import { convertSPPQuantityInMasterUnit } from '@orders/utils/computePackagingToSupplierProduct';

import { getConversionModalParams } from '../InputConversionContentModal/utils/modalConfigurations';

import { canEditAdminReservedFieldsIfCentralKitchenSP } from './utils/canEditAdminReservedFieldsIfCentralKitchenSP';
import {
  Container,
  DropdownIcon,
  DropdownItem,
  DropdownItemMainValue,
  DropdownItemSecondaryValue,
  DropdownTitle,
  FormContainer,
} from './styledComponents';
import { hasUserCatalogReadOnlyRights } from './utils/hasUserCatalogReadOnlyRights';
import {
  SUPPLIER_PRODUCT_ASSOCIATED_INGREDIENT_INPUTS,
  SUPPLIER_PRODUCT_CUSTOM_PRICE_INPUTS,
  SUPPLIER_PRODUCT_FORM_INPUTS,
  SUPPLIER_PRODUCT_GENERAL_INFOS_INPUTS,
  SUPPLIER_PRODUCT_PRICE_INPUTS,
  SUPPLIER_PRODUCT_SCHEDULE_PRICE_INPUTS,
  SUPPLIER_PRODUCT_WEIGHT_INPUTS,
} from './utils/formInputsConfigurations';

const DEFAULT_SP_PICTURE = '/images/inpulse/supplier-product-photo-placeholder.svg';
const DEFAULT_INGREDIENT_PICTURE = '/images/inpulse/ingredient-photo-placeholder.svg';

const SupplierProductDetails = ({
  supplierProduct,
  setIsFooterDisplay,
  setUpdatedSupplierProduct,
  setMappingsToCreate,
  setForm,
  isSaveAlreadyTriggered,
  isCreation = false,
  isDuplication = false,
  user,
  //redux props
  client: { clientId, storeName, hasLocalCatalogs },
  displayManufacturerName,
  pageLoading,
  pageLoaded,
  showErrorMessage,
  openGenericModal,
  refreshGenericModal,
  handleNewDataCreation,
  createdCategories,
  createdSubCategories,
  createdStorageAreas,
  createdInventoryListTemplates,
  uploadAttachment,
  downloadAttachment,
  deleteAttachment,
  authorizedActions,
}) => {
  const [picture, setPicture] = useState(null);
  const [selectedPictureFile, setSelectedPictureFile] = useState(null);

  const [ingredients, setIngredients] = useState([]);
  const [scheduledPriceDate, setScheduledPriceDate] = useState(null);
  const [scheduledPriceValue, setScheduledPriceValue] = useState(null);
  const [inputPrices, setInputPrices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [packagings, setPackagings] = useState([]);
  const [selectedInvoicePackaging, setSelectedInvoicePackaging] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [sspmsCount, setSspmsCount] = useState(0);
  const [conversions, setConversions] = useState([]);
  const [clientILTs, setClientILTs] = useState([]);
  const [storageAreas, setStorageAreas] = useState([]);

  const [isAllDataFetched, setIsAllDataFetched] = useState(false);

  const [formattedSupplierProduct, setFormattedSupplierProduct] = useState({});

  // only for Supplier Products produced by a central kitchen
  const [centralKitchenSuppliersSSPMsOfUser, setCentralKitchenSuppliersSSPMsOfUser] = useState([]);
  const [isCentralKitchen, setIsCentralKitchen] = useState(false);
  const [canEditFieldsIfCentralKitchenSP, setCanEditFieldsIfCentralKitchenSP] = useState(false);

  // Form Inputs states
  const [informationsInputs, setInformationsInputs] = useState(
    SUPPLIER_PRODUCT_GENERAL_INFOS_INPUTS({
      isCreation,
      supplierProduct,
      isCentralKitchen,
      categories,
      subCategories,
      suppliers,
      displayManufacturerName,
      canEditFieldsIfCentralKitchenSP,
      uploadAttachment,
      downloadAttachment,
      deleteAttachment,
      canEditSupplierProductFromDetailView: canEditSupplierProductFromDetailView(authorizedActions),
      user,
    }),
  );

  const [associatedIngredient, setAssociatedIngredient] = useState(
    SUPPLIER_PRODUCT_ASSOCIATED_INGREDIENT_INPUTS({
      isCreation,
      conversions,
      packagingUnit: get(packagings, '[0].unit', null),
      ingredients,
      isCentralKitchen,
      active: supplierProduct.active,
      canEditSupplierProductFromDetailView: canEditSupplierProductFromDetailView(authorizedActions),
      supplierProduct,
      user,
    }),
  );
  const [priceInputs, setPriceInputs] = useState(
    SUPPLIER_PRODUCT_PRICE_INPUTS({
      isCreation,
      storeName,
      supplierProduct,
      canEditSupplierProductFromDetailView: canEditSupplierProductFromDetailView(authorizedActions),
      user,
    }),
  );
  const [schedulePriceInputs, setSchedulePriceInputs] = useState(
    SUPPLIER_PRODUCT_SCHEDULE_PRICE_INPUTS({
      isCreation,
      scheduledPriceDate,
      scheduledPriceValue,
      canEditFieldsIfCentralKitchenSP: false,
      canEditSupplierProductFromDetailView: canEditSupplierProductFromDetailView(authorizedActions),
      supplierProduct,
      user,
    }),
  );
  const [customPriceInputs, setCustomPriceInputs] = useState(
    SUPPLIER_PRODUCT_CUSTOM_PRICE_INPUTS({
      isCreation,
      supplierProduct,
      inputPrices,
      canEditSupplierProductFromDetailView: canEditSupplierProductFromDetailView(authorizedActions),
      user,
    }),
  );
  const [weightInputs, setWeightInputs] = useState(
    SUPPLIER_PRODUCT_WEIGHT_INPUTS({
      isCreation,
      canEditSupplierProductFromDetailView: canEditSupplierProductFromDetailView(authorizedActions),
      supplierProduct,
      user,
    }),
  );

  // Categories modal states
  const [inputValue, setInputValue] = useState('');
  const [propertyName, setPropertyName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const allInputs = SUPPLIER_PRODUCT_FORM_INPUTS({
    isCreation,
    supplierProduct,
    inputPrices,
    packagingUnit: get(packagings, '[0].unit', null),
    scheduledPriceDate,
    scheduledPriceValue,
    conversions,
    uploadAttachment,
    downloadAttachment,
    deleteAttachment,
    user,
  });

  const supplierProductForm = useForm({
    defaultValues: {},
    resolver: yupResolver(buildSchema(allInputs)),
  });

  const formFields = useWatch({
    control: supplierProductForm.control,
  });

  // TODO : This API call should be moved to the fetchAllData function.
  // We haven't done this yet because we had a problem with setters.
  // Later, if we have performance issues, move these lines.

  useEffect(() => {
    (async function loadData() {
      const supplierProfilesLinkedToUser = await centralService.getCentralKitchenSSPFMsOfUser();
      const suppliersMappedToUser = supplierProfilesLinkedToUser.map(
        ({ supplierProfile }) => supplierProfile.supplierId,
      );
      setCentralKitchenSuppliersSSPMsOfUser(suppliersMappedToUser);
    })();
  }, []);

  useEffect(() => {
    if (isEmpty(supplierProduct)) {
      return;
    }
    setForm(supplierProductForm);

    (async () => {
      await fetchAllData();

      try {
        if (!isCreation) {
          const associatedSSPMs =
            await storeSupplierProductMapping.getStoreSupplierProductMappingsOfAccountStores(
              user.id,
              supplierProduct.id,
            );

          setSspmsCount(associatedSSPMs.length);
        }
      } catch {
        showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.CHECK_DATA_ERROR'));
      }
    })();

    if (!isEmpty(supplierProduct.packagings)) {
      setPackagings(supplierProduct.packagings);
    }

    // Retrieve SP img a initialisation to distinguish later if save action implies upload IMG
    if (!supplierProduct.img) {
      return;
    }

    setPicture(supplierProduct.img);
  }, [supplierProduct]);

  useEffect(() => {
    if (isEmpty(formattedSupplierProduct)) {
      return;
    }

    const formattedFormFields = {
      ...cloneDeep(formFields),
      packagings,
    };

    inputPrices.forEach(({ id }) => {
      formattedFormFields[id] = formFields[id];
    });

    if (!!selectedPictureFile) {
      formattedFormFields.selectedPictureFile = selectedPictureFile;
    }

    formattedFormFields.img = picture;

    setUpdatedSupplierProduct(formattedFormFields);

    // Early return for creation, the footer is always displayed
    if (isCreation) {
      return;
    }

    if (
      !isEqual(
        omit(formattedFormFields, ['entity', 'scheduledPriceDate']),
        omit(formattedSupplierProduct, ['entity', 'scheduledPriceDate']),
      ) ||
      // Avoid footer display if change entity and change again to initial value
      (!!formattedFormFields.entity &&
        formattedFormFields.entity.id !== formattedSupplierProduct.entity.id) ||
      (!formattedFormFields.entity && !isEmpty(formattedSupplierProduct.entity)) ||
      !_isSameSchedulePriceDate(formattedFormFields)
    ) {
      setIsFooterDisplay(true);
      return;
    }

    setIsFooterDisplay(false);
  }, [formFields, packagings, selectedPictureFile, picture]);

  // Dynamically fetch input prices for the chosen supplier
  useEffect(() => {
    try {
      (async () => {
        if (isEmpty(supplierProductForm.watch('supplier')) || !isCreation) {
          return;
        }

        const { inputs: fetchInputPrices } = supplierProductForm.watch('supplier').id
          ? await supplierService.getSupplierPriceInputs(
              clientId,
              supplierProductForm.watch('supplier').id,
            )
          : { inputs: [] };

        setInputPrices(fetchInputPrices);

        if (
          supplierProductForm.watch('supplier').id &&
          supplierProduct.supplierId !== supplierProductForm.watch('supplier').id
        ) {
          const stores = await storeService.getStoresByAccountIdAndSupplierId(
            user.id,
            supplierProductForm.watch('supplier').id,
          );

          const activeStoresWithoutMappings = stores.filter(
            ({ active, supplierProfile }) => active && !isEmpty(supplierProfile),
          );

          const createdSSPMs = activeStoresWithoutMappings.reduce((acc, { id }) => {
            acc.push({
              id,
              storeId: id,
              hasStock: true,
              available: true,
              hasDlc: true,
            });

            return acc;
          }, []);

          setMappingsToCreate(createdSSPMs);
        }
      })();
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.CHECK_DATA_ERROR'));
    }
  }, [supplierProductForm.watch('supplier')]);

  useEffect(() => {
    const isCentralKitchen = formattedSupplierProduct.isKitchen;

    setIsCentralKitchen(isCentralKitchen);

    const selectedSupplier = supplierProductForm.watch('supplier');

    const canEditFieldsIfCentralKitchenSP = canEditAdminReservedFieldsIfCentralKitchenSP(
      isCentralKitchen,
      centralKitchenSuppliersSSPMsOfUser,
      selectedSupplier,
    );

    setCanEditFieldsIfCentralKitchenSP(canEditFieldsIfCentralKitchenSP);

    setWeightInputs(
      SUPPLIER_PRODUCT_WEIGHT_INPUTS({
        isCreation,
        canEditFieldsIfCentralKitchenSP,
        canEditSupplierProductFromDetailView:
          canEditSupplierProductFromDetailView(authorizedActions),
        supplierProduct,
        user,
      }),
    );
  }, [formattedSupplierProduct.isKitchen]);

  useEffect(() => {
    const { isKitchen, associatedProduct } = formattedSupplierProduct;
    setInformationsInputs(
      SUPPLIER_PRODUCT_GENERAL_INFOS_INPUTS({
        isCreation,
        supplierProduct,
        isCentralKitchen: isKitchen,
        categories,
        subCategories,
        suppliers,
        productId: get(associatedProduct, 'id', null),
        displayManufacturerName,
        handleCategorySubcategoryCreation,
        canEditFieldsIfCentralKitchenSP,
        clientILTs,
        storageAreas,
        uploadAttachment,
        downloadAttachment,
        deleteAttachment,
        canEditSupplierProductFromDetailView:
          canEditSupplierProductFromDetailView(authorizedActions),
        user,
      }),
    );
  }, [
    categories,
    subCategories,
    suppliers,
    formattedSupplierProduct.isKitchen,
    canEditFieldsIfCentralKitchenSP,
    clientILTs,
    storageAreas,
    supplierProduct,
  ]);

  useEffect(() => {
    const { packagingUnit, entity } = supplierProductForm.getValues();

    setAssociatedIngredient(
      SUPPLIER_PRODUCT_ASSOCIATED_INGREDIENT_INPUTS({
        isCreation,
        conversions,
        packagingUnit,
        ingredients,
        entityId: !!entity ? entity.id : null,
        isCentralKitchen,
        canEditFieldsIfCentralKitchenSP,
        handleConversionCreation,
        active: supplierProduct.active,
        canEditSupplierProductFromDetailView:
          canEditSupplierProductFromDetailView(authorizedActions),
        supplierProduct,
        user,
      }),
    );
  }, [
    ingredients,
    conversions,
    supplierProductForm.watch('entity'),
    supplierProductForm.watch('packagingUnit'),
  ]);

  useEffect(() => {
    setPriceInputs(
      SUPPLIER_PRODUCT_PRICE_INPUTS({
        isCreation,
        storeName,
        packagings,
        supplierProduct,
        canEditFieldsIfCentralKitchenSP,
        canEditSupplierProductFromDetailView:
          canEditSupplierProductFromDetailView(authorizedActions),
        user,
      }),
    );
  }, [packagings]);

  useEffect(() => {
    const {
      scheduledPriceDate: updatedScheduledPriceDate,
      scheduledPriceValue: updatedScheduledPriceValue,
    } = supplierProductForm.getValues();

    setScheduledPriceDate(updatedScheduledPriceDate);
    setScheduledPriceValue(updatedScheduledPriceValue);

    setSchedulePriceInputs(
      SUPPLIER_PRODUCT_SCHEDULE_PRICE_INPUTS({
        isCreation,
        scheduledPriceValue: updatedScheduledPriceValue,
        scheduledPriceDate: updatedScheduledPriceDate,
        canEditFieldsIfCentralKitchenSP,
        canEditSupplierProductFromDetailView:
          canEditSupplierProductFromDetailView(authorizedActions),
        supplierProduct,
        user,
      }),
    );
  }, [
    supplierProductForm.watch('scheduledPriceValue'),
    supplierProductForm.watch('scheduledPriceDate'),
  ]);

  useEffect(() => {
    setCustomPriceInputs(
      SUPPLIER_PRODUCT_CUSTOM_PRICE_INPUTS({
        isCreation,
        supplierProduct,
        inputPrices,
        canEditSupplierProductFromDetailView:
          canEditSupplierProductFromDetailView(authorizedActions),
        user,
      }),
    );
  }, [inputPrices]);

  // Handle category and sub category creation modal refresh
  useEffect(() => {
    refreshGenericModal(getParamsModal(propertyName));
  }, [inputValue]);

  /**
   * Side effect to recompute the SupplierProduct's price when the invoice packaging changes
   */
  useEffect(() => {
    if (isEmpty(packagings) || isEmpty(supplierProductForm.getValues('invoicePackaging'))) {
      return;
    }

    const packagingAlreadyUsedInInvoice = packagings.find(({ isUsedInInvoice }) => isUsedInInvoice);
    const newInvoicePackaging = supplierProductForm.getValues('invoicePackaging');

    if (
      !!packagingAlreadyUsedInInvoice &&
      packagingAlreadyUsedInInvoice.id !== newInvoicePackaging.id
    ) {
      const unitPrice =
        formFields.price /
        convertSPPQuantityInMasterUnit(
          !!selectedInvoicePackaging
            ? selectedInvoicePackaging.id
            : packagingAlreadyUsedInInvoice.id,
          packagings,
        );

      const convertedQuantity = convertSPPQuantityInMasterUnit(newInvoicePackaging.id, packagings);

      if (!Number.isNaN(unitPrice) && !Number.isNaN(convertedQuantity)) {
        supplierProductForm.setValue('price', unitPrice * convertedQuantity);
      }

      setSelectedInvoicePackaging(newInvoicePackaging);
    }

    const updatedPackagings = packagings.map((packaging) => {
      if (packaging.id === newInvoicePackaging.id) {
        return { ...packaging, isUsedInInvoice: true };
      }
      return { ...packaging, isUsedInInvoice: false };
    });

    setPackagings(updatedPackagings);
  }, [supplierProductForm.watch('invoicePackaging')]);

  // Handle conversions modal refresh
  const handleConversionsUpdate = (newConversions) => {
    const matchingUnit = UNITS_DROPDOWN_ITEMS.find(
      ({ id }) => id === supplierProductForm.getValues('packagingUnit'),
    );

    if (matchingUnit) {
      const conversionModalParams = getConversionModalParams({
        packagingUnit: matchingUnit,
        existingConversions: newConversions,
        areConversionsValid,
        handleConversionsUpdate,
        handleConversionSaving,
      });

      refreshGenericModal(conversionModalParams);
    }
  };

  const _isSameSchedulePriceDate = (formattedFormFields) => {
    if (!!formattedFormFields.scheduledPriceDate && !!formattedSupplierProduct.scheduledPriceDate) {
      return (
        moment(formattedFormFields.scheduledPriceDate).format(
          DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
        ) ===
        moment(formattedSupplierProduct.scheduledPriceDate).format(
          DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
        )
      );
    }
    return formattedFormFields.scheduledPriceDate === formattedSupplierProduct.scheduledPriceDate;
  };

  const handleSaveNewItemDropdown = () => {
    switch (propertyName) {
      case DYNAMIC_PROPERTIES.CATEGORY:
        const newCategory = { id: categories.length, value: inputValue.trim() };

        const updatedCategories = sortBy([...categories, newCategory], ['value']);

        setCategories(updatedCategories);

        supplierProductForm.setValue(DYNAMIC_PROPERTIES.CATEGORY, newCategory);

        handleNewDataCreation(newCategory, CAT_AND_SUB_CAT_TYPES.CATEGORY);

        break;
      case DYNAMIC_PROPERTIES.SUB_CATEGORY:
        const newSubCategory = { id: subCategories.length, value: inputValue.trim() };

        const updatedSubCategories = sortBy([...subCategories, newSubCategory], ['value']);

        setSubCategories(updatedSubCategories);

        supplierProductForm.setValue(DYNAMIC_PROPERTIES.SUB_CATEGORY, newSubCategory);

        handleNewDataCreation(newSubCategory, CAT_AND_SUB_CAT_TYPES.SUB_CATEGORY);

        break;
      case DYNAMIC_PROPERTIES.STORAGE_AREAS:
        const newStorageArea = { id: storageAreas.length, name: inputValue.trim() };

        const updatedStorageAreas = sortBy([...storageAreas, newStorageArea], ['name']);

        setStorageAreas(updatedStorageAreas);

        const alreadySelectedStorageAreas = supplierProductForm.getValues(
          DYNAMIC_PROPERTIES.STORAGE_AREAS,
        );
        supplierProductForm.setValue(DYNAMIC_PROPERTIES.STORAGE_AREAS, [
          ...alreadySelectedStorageAreas,
          newStorageArea,
        ]);

        handleNewDataCreation(newStorageArea, STORAGE_AREA_TYPE.STORAGE_AREA);

        break;
      case DYNAMIC_PROPERTIES.INVENTORY_LIST_TEMPLATES:
        const newILT = { id: clientILTs.length, name: inputValue.trim() };

        const updatedInventoryListTemplates = sortBy([...clientILTs, newILT], ['name']);

        setClientILTs(updatedInventoryListTemplates);

        const alreadySelectedILTs = supplierProductForm.getValues(
          DYNAMIC_PROPERTIES.INVENTORY_LIST_TEMPLATES,
        );

        supplierProductForm.setValue(DYNAMIC_PROPERTIES.INVENTORY_LIST_TEMPLATES, [
          ...alreadySelectedILTs,
          newILT,
        ]);

        handleNewDataCreation(newILT, INVENTORY_LIST_TEMPLATE_TYPE.INVENTORY_LIST_TEMPLATE);

        break;
      default:
        return;
    }

    setInputValue('');
    setErrorMessage('');
  };

  const handleInputChange = (propertyName, newValue) => {
    setInputValue(newValue);

    if (!newValue) {
      setErrorMessage(i18next.t('GENERAL.REQUIRED_FILED_ERROR_MESSAGE'));

      return;
    }

    let selectedDropdownItems, checkAlreadyExists;

    const checkValueInArray = (newValue, array) =>
      array.some(({ value }) => normalizeStringValue(value) === normalizeStringValue(newValue));

    const checkNameInArray = (newValue, array) =>
      array.some(({ name }) => normalizeStringValue(name) === normalizeStringValue(newValue));

    switch (propertyName) {
      case DYNAMIC_PROPERTIES.CATEGORY:
        selectedDropdownItems = categories;
        checkAlreadyExists = checkValueInArray;
        break;
      case DYNAMIC_PROPERTIES.SUB_CATEGORY:
        selectedDropdownItems = subCategories;
        checkAlreadyExists = checkValueInArray;
        break;
      case DYNAMIC_PROPERTIES.STORAGE_AREAS:
        selectedDropdownItems = storageAreas;
        checkAlreadyExists = checkNameInArray;
        break;
      case DYNAMIC_PROPERTIES.INVENTORY_LIST_TEMPLATES:
        selectedDropdownItems = clientILTs;
        checkAlreadyExists = checkNameInArray;
        break;
      default:
    }

    const alreadyExists = checkAlreadyExists(newValue, selectedDropdownItems);

    if (alreadyExists) {
      setErrorMessage(i18next.t(DYNAMIC_MODAL_ALREADY_USED[propertyName]));

      return;
    }

    setErrorMessage('');
  };

  const closeCleanUp = () => {
    setErrorMessage('');
    setInputValue('');
  };

  const getParamsModal = (propertyName) => ({
    type: 'action',
    width: '560px',
    height: 'auto',
    icon: '/images/inpulse/add-black-small.svg',
    title: i18next.t(DYNAMIC_MODAL_TITLE[propertyName]),
    isLoading: false,
    handleCloseCleanUp: closeCleanUp,
    component: () => (
      <InputContentModal
        errorMessage={errorMessage}
        label={i18next.t(DYNAMIC_MODAL_INPUT_LABEL[propertyName])}
        value={inputValue}
        autoFocus
        onChange={(value) => handleInputChange(propertyName, value)}
      />
    ),
    closeConfig: {
      title: i18next.t('GENERAL.LEAVE'),
      content: <div>{i18next.t(DYNAMIC_MODAL_CLOSE_CONFIRMATION[propertyName])}</div>,
    },
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_SAVE_BUTTON(),
        isDisabled: !inputValue || !!errorMessage,
        handleClick: () => handleSaveNewItemDropdown(),
      },
    ],
  });

  const fetchAllData = async () => {
    if (isDuplication && !supplierProduct.id) {
      return;
    }

    if (isAllDataFetched) {
      return;
    }

    pageLoading();
    try {
      let fetchedSuppliers = [];

      if (isCreation) {
        fetchedSuppliers = await supplierService.getSuppliersOfClient(clientId, false, true);
        const activeSuppliers = fetchedSuppliers.filter(({ active, isKitchen, catalogId }) =>
          hasLocalCatalogs
            ? active && !isKitchen && catalogId === user.catalogId
            : active && !isKitchen,
        );

        setSuppliers(activeSuppliers);
      }

      // Required to fetch linked data with ingredients in order to get the SP Mapping Count
      const fetchIngredients = await ingredientService.getIngredients(clientId, true);
      const activeIngredients = fetchIngredients.filter(({ active }) => active);

      const fetchCategories = await supplierProductService.getSupplierProductsCategories(clientId);

      const { inputs: fetchInputPrices } =
        supplierProduct.supplierId || !isCreation
          ? await supplierService.getSupplierPriceInputs(clientId, supplierProduct.supplierId)
          : { inputs: [] };

      setInputPrices(fetchInputPrices);

      // Update conversions
      const formattedConversions = supplierProduct.conversions.map((conversion) => ({
        ...conversion,
        isBaseUnit: supplierProduct.packagingUnit === conversion.convertedUnit,
      }));

      setConversions(formattedConversions);

      const ingredientsDropdownItems = getIngredientsDropdownItems(
        supplierProduct.packagingUnit,
        activeIngredients,
        formattedConversions,
      );
      setIngredients(ingredientsDropdownItems);

      const categoryList = fetchCategories.categories.map((value, idx) => ({
        id: idx,
        value,
      }));

      const allCategories = createdCategories.reduce((acc, { value }) => {
        acc.push({ id: acc.length, value });

        return acc;
      }, categoryList);

      const supplierProductPackagings = get(supplierProduct, 'packagings', []);

      const subCategoryList = fetchCategories.subCategories.map((value, idx) => ({
        id: idx,
        value,
      }));

      const allSubCategories = createdSubCategories.reduce((acc, { value }) => {
        acc.push({ id: acc.length, value });

        return acc;
      }, subCategoryList);

      setCategories(allCategories);
      setSubCategories(allSubCategories);

      const categoryName =
        typeof supplierProduct.category === 'object' && !!supplierProduct.category
          ? supplierProduct.category.value
          : supplierProduct.category;

      const subCategoryName =
        typeof supplierProduct.subCategory === 'object' && !!supplierProduct.subCategory
          ? supplierProduct.subCategory.value
          : supplierProduct.subCategory;

      const matchingCategory = categoryList.find(({ value }) => value === categoryName) || null;
      const matchingSubCategory =
        subCategoryList.find(({ value }) => value === subCategoryName) || null;

      const supplierProductScheduledPrice = supplierProduct.scheduledPrice || {};

      /** InventoryListTemplates **/
      const clientInventoryListTemplates = await clientService.getInventoryListTemplates(clientId, {
        withMappings: false,
        filterByUserCatalog: true,
      });

      const mappedILTs = intersectionBy(
        clientInventoryListTemplates,
        supplierProduct.inventoryListTemplates,
        'id',
      );

      const allILTs = createdInventoryListTemplates.reduce((acc, { value }) => {
        acc.push({ id: acc.length, name: value });

        return acc;
      }, clientInventoryListTemplates);

      setClientILTs(allILTs);

      /** STORAGE AREAS */
      const storageAreas = await storageAreaService.getStorageAreasByClientId(clientId);

      const mappedStorageAreas = intersectionBy(storageAreas, supplierProduct.storageAreas, 'id');

      const allStorageAreas = createdStorageAreas.reduce((acc, { value }) => {
        acc.push({ id: acc.length, name: value });

        return acc;
      }, storageAreas);

      setStorageAreas(allStorageAreas);

      const formSupplierProduct = {
        ...cloneDeep(supplierProduct),
        conversionsDisplayed: _getConversionsDisplay(supplierProduct.conversions),
        category: matchingCategory,
        subCategory: matchingSubCategory,
        scheduledPriceValue: supplierProductScheduledPrice.price,
        invoicePackaging: supplierProductPackagings.find(({ isUsedInInvoice }) => isUsedInInvoice),
        scheduledPriceDate: !!supplierProductScheduledPrice.startDate
          ? moment(supplierProductScheduledPrice.startDate)
          : null,
        inventoryListTemplates: mappedILTs,
        storageAreas: mappedStorageAreas,
      };

      fetchInputPrices.forEach(({ id }) => {
        const matchingCustomPrice =
          supplierProduct.customPrices.find(({ inputId }) => inputId === id) || {};

        formSupplierProduct[id] =
          matchingCustomPrice.price == null ? null : matchingCustomPrice.price;
      });

      setFormattedSupplierProduct(formSupplierProduct);
      supplierProductForm.reset(formSupplierProduct);

      setIsAllDataFetched(true);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.CHECK_DATA_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const getIngredientsDropdownItems = (packagingUnit, fetchedIngredients, conversions) =>
    fetchedIngredients.map((ingredient) => {
      const sameUnit = doesIngredientsHaveApplicableUnits(
        packagingUnit,
        ingredient.unit,
        conversions,
      );

      return {
        ...ingredient,
        id: ingredient.id,
        tags: [getIngredientUnitName(ingredient.unit)],
        value: ingredient.name,
        isDisabled: !sameUnit,
        renderValue: () => (
          <DropdownItem>
            <DropdownIcon src={ingredient.img || DEFAULT_INGREDIENT_PICTURE} />
            <DropdownTitle>
              <DropdownItemMainValue>{ingredient.name}</DropdownItemMainValue>
              <DropdownItemSecondaryValue isDisabled={!sameUnit}>
                {`${ingredient.supplierProductMappingCount} ${i18next.t(
                  'ADMIN.SUPPLIER_PRODUCTS.SUPPLIER_PRODUCT_ABBREVIATION',
                )}`}
              </DropdownItemSecondaryValue>
            </DropdownTitle>
          </DropdownItem>
        ),
      };
    });

  const handleCategorySubcategoryCreation = (propertyName) => {
    setPropertyName(propertyName);

    openGenericModal(getParamsModal(propertyName));
  };

  const handleConversionCreation = (packagingUnit) => {
    const matchingUnit = UNITS_DROPDOWN_ITEMS.find(({ id }) => id === packagingUnit);

    if (matchingUnit) {
      const conversionModalParams = getConversionModalParams({
        packagingUnit: matchingUnit,
        existingConversions: conversions,
        areConversionsValid,
        handleConversionsUpdate,
        handleConversionSaving,
      });

      openGenericModal(conversionModalParams);
    }
  };

  const areConversionsValid = (newConversions, packagingUnit) => {
    const packagingConversion = newConversions.find(
      ({ convertedUnit }) => convertedUnit === packagingUnit.id,
    );

    if (!packagingConversion) {
      return false;
    }

    const allConversionsAreNull = newConversions.every(
      ({ convertedQuantity }) => convertedQuantity === '',
    );

    return allConversionsAreNull || packagingConversion.convertedQuantity !== '';
  };

  const handleConversionSaving = (newConversions) => {
    const hasNoQuantities = (conversions) =>
      conversions.every(({ convertedQuantity }) => convertedQuantity === '');

    const allConversionsHaveNoQuantities = hasNoQuantities(newConversions);

    const conversionOtherThanBaseUnit = newConversions.filter(({ isBaseUnit }) => !isBaseUnit);
    const onlyPackagingHaveQuantity =
      conversionOtherThanBaseUnit.length >= 1 && hasNoQuantities(conversionOtherThanBaseUnit);

    if (allConversionsHaveNoQuantities || onlyPackagingHaveQuantity) {
      _resetConversions();

      const ingredientsDropdownItems = getIngredientsDropdownItems(
        get(packagings, '[0].unit', null),
        ingredients,
        [],
      );

      setIngredients(ingredientsDropdownItems);
      return;
    }

    const conversionWithValues = newConversions.filter(
      ({ convertedUnit, convertedQuantity }) => convertedUnit && convertedQuantity,
    );

    const conversionsDisplayed = _getConversionsDisplay(conversionWithValues);

    supplierProductForm.setValue('conversionsDisplayed', conversionsDisplayed);
    supplierProductForm.setValue('conversions', conversionWithValues);
    setConversions(conversionWithValues);

    const ingredientsDropdownItems = getIngredientsDropdownItems(
      get(packagings, '[0].unit', null),
      ingredients,
      newConversions,
    );

    setIngredients(ingredientsDropdownItems);
  };

  const handlePackagingsChange = (newPackagings) => {
    const initialUsedInOrder = find(packagings, ['isUsedInOrder', true]);
    const newUsedInOrder = find(newPackagings, ['isUsedInOrder', true]);

    if (isEmpty(newPackagings)) {
      setIngredients(getIngredientsDropdownItems(null, ingredients, conversions));
      supplierProductForm.setValue('packagingUnit', null);
      _resetConversions();
    }

    if (!isEmpty(newPackagings)) {
      const newUnit = get(newPackagings, '[0].unit');
      const oldUnit = get(packagings, '[0].unit');

      if (newUnit !== oldUnit) {
        _resetConversions();
      }
      const matchingUnit = UNITS_DROPDOWN_ITEMS.find(({ id }) => id === newUnit);
      setIngredients(getIngredientsDropdownItems(matchingUnit.id, ingredients, conversions));
      supplierProductForm.setValue('packagingUnit', newUnit);

      const invoicePackaging = supplierProductForm.getValues('invoicePackaging');
      const packagingUsedInOrder = newPackagings.find(({ isUsedInOrder }) => isUsedInOrder);

      if (!isEmpty(invoicePackaging)) {
        const matchInvoicePackaging = newPackagings.find(({ id }) => id === invoicePackaging.id);

        // Check if invoicePackaging is still on the newPackaging set
        if (!matchInvoicePackaging) {
          supplierProductForm.setValue('invoicePackaging', packagingUsedInOrder);
        } else {
          // If invoice packaging is found, update with the new packaging to have update on name for example
          if (!isEqual(matchInvoicePackaging, invoicePackaging)) {
            supplierProductForm.setValue('invoicePackaging', matchInvoicePackaging);
          }
        }
      }

      // Else if invoicePackaging is empty we set invoicePackaging with the packaging used in order
      if (isEmpty(invoicePackaging)) {
        supplierProductForm.setValue('invoicePackaging', packagingUsedInOrder);
      }
    }

    if (
      formFields.price &&
      initialUsedInOrder &&
      newUsedInOrder &&
      !isEqual(initialUsedInOrder, newUsedInOrder)
    ) {
      const unitPrice =
        formFields.price / convertSPPQuantityInMasterUnit(initialUsedInOrder.id, packagings);
      const convertedQuantity = convertSPPQuantityInMasterUnit(newUsedInOrder.id, packagings);

      supplierProductForm.setValue('price', unitPrice * convertedQuantity);
    }

    setPackagings(newPackagings);
  };

  const _getConversionsDisplay = (conversions) =>
    conversions
      .map(
        ({ convertedQuantity, convertedUnit }) =>
          `${convertedQuantity} ${translatedUnitCondensed(convertedUnit)}`,
      )
      .join(' = ');

  const _resetConversions = () => {
    supplierProductForm.setValue('conversionsDisplayed', '');
    supplierProductForm.setValue('conversions', []);
    setConversions([]);
  };

  return (
    <Container>
      <FormContainer>
        <HeaderPicture
          isDefaultThumbnail={!picture}
          picture={picture || DEFAULT_SP_PICTURE}
          readOnly={
            !canEditSupplierProductFromDetailView(authorizedActions) ||
            hasUserCatalogReadOnlyRights(user, {
              isCreation,
              supplierId: supplierProduct.supplierId,
            })
          }
          setSelectedFile={setSelectedPictureFile}
          subTitle={
            formattedSupplierProduct.isKitchen &&
            i18next.t('ADMIN.SUPPLIER_PRODUCTS.KITCHEN_SUPPLIER_PRODUCT_DETAIL_SUBTITLE')
          }
          subTitleIcon={
            formattedSupplierProduct.isKitchen ? '/images/inpulse/central-black-small.svg' : null
          }
          title={formFields.name}
          onPictureChange={setPicture}
        />
        <WhiteCardForm
          form={supplierProductForm}
          inputs={informationsInputs}
          shouldDisplayError={isSaveAlreadyTriggered}
          title={i18next.t('ADMIN.SUPPLIER_PRODUCTS.FORM_GENERAL_INFOS')}
          tooltipText={i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_INFORMATIONS_TOOLTIP')}
        />
        <PackagingSection
          handleSupplierProductChange={(value) => handlePackagingsChange(value)}
          hasRightToEditPackagings={
            canEditFieldsIfCentralKitchenSP &&
            (isCreation || canEditSupplierProductFromDetailView(authorizedActions)) &&
            !hasUserCatalogReadOnlyRights(user, {
              isCreation,
              supplierId: supplierProduct.supplierId,
            })
          }
          isDuplicated={isDuplication}
          isErrorState={isEmpty(packagings) && isSaveAlreadyTriggered}
          supplierProduct={{ ...formFields, packagings }}
        />
        <WhiteCardForm
          form={supplierProductForm}
          inputs={associatedIngredient}
          shouldDisplayError={isSaveAlreadyTriggered}
          title={i18next.t('GENERAL.ASSOCIATED_INGREDIENT')}
        />
        <WhiteCardForm
          form={supplierProductForm}
          inputs={priceInputs}
          shouldDisplayError={isSaveAlreadyTriggered}
          title={i18next.t('GENERAL.PRICE')}
        />
        {!isCreation && sspmsCount > 0 && (
          <WhiteCardForm
            form={supplierProductForm}
            inputs={schedulePriceInputs}
            shouldDisplayError={isSaveAlreadyTriggered}
            title={i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_SCHEDULE_PRICE_CHANGE')}
          />
        )}
        {!isEmpty(inputPrices) && (
          <WhiteCardForm
            form={supplierProductForm}
            inputs={customPriceInputs}
            shouldDisplayError={isSaveAlreadyTriggered}
            title={i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_SECONDARY_PRICE_TITLE')}
          />
        )}
        <WhiteCardForm
          form={supplierProductForm}
          inputs={weightInputs}
          shouldDisplayError={isSaveAlreadyTriggered}
          title={i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_WEIGHT_TITLE')}
        />
      </FormContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  user: state.baseReducer.user,
  displayManufacturerName: getDisplayManufacturerName(state.baseReducer.userRights),
  activeStores: state.baseReducer.activeStores,
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/admin/suppliers/supplier-products/:id/details',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierProductDetails);
