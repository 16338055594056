import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 176px);

  background-color: ${(props) => props.theme.colors.greys.lighter};

  overflow: hidden;
`;

export const ContentList = styled.div`
  width: 100%;
  height: calc(100% - 112px);

  padding: 0px 24px;

  overflow: scroll;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
  & > :last-child {
    margin-bottom: 24px;
  }
`;

export const ContentHeader = styled.div`
  height: 64px;

  padding: 0px 24px;
`;

export const LineHeader = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
`;

export const Line = styled.div`
  width: 100%;
  height: 64px;

  padding: 24px 16px 24px 3px;

  display: flex;
  align-items: center;

  background-color: ${(props) => props.theme.colors.greys.lightest};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
`;

export const RowHeader = styled.div`
  height: 100%;

  flex: 1;
  display: flex;
  align-items: center;

  color: ${(props) => props.theme.colors.greys.darker};
  font: ${(props) => props.theme.fonts.textBigHeight16};
`;

export const Row = styled.div`
  height: 100%;

  flex: 1;
  display: flex;
  align-items: center;

  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.textBigHeight16};
`;

export const TopContainer = styled.div`
  width: 100%;
  height: 88px;

  padding: 24px;

  display: flex;
  align-items: center;

  color: ${(props) => props.theme.colors.greys.darkest};
  background-color: ${(props) => props.theme.colors.greys.lightest};
  font: ${(props) => props.theme.fonts.kpiSmall};
`;

export const BottomContainer = styled.div`
  width: 100%;
  height: 88px;

  padding: 24px;

  display: flex;
  justify-content: end;
  align-items: center;

  background-color: ${(props) => props.theme.colors.greys.lightest};

  & > :first-child {
    margin-right: 24px;
  }
`;

export const SearchContainer = styled.div`
  max-width: 465px;

  margin: 24px 0px 0px 24px;
`;
