import i18next from 'i18next';
import React from 'react';

import { getTheme } from '@commons/utils/theme';
import { Label } from '@commons/utils/styledLibraryComponents';
import { Tags } from '@commons/Tags';

import { SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING } from '../../common/constants';

const theme = getTheme();

export const getColumns = () => [
  {
    id: '0',
    name: i18next.t('GENERAL.CASHIER_PRODUCT'),
    type: 'string',
    propertyKey: 'name',
    large: true,
  },
  {
    id: '1',
    name: i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_LIST_COLUMN_RELEVANCE'),
    tooltipText: i18next.t(
      'ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_LIST_COLUMN_RELEVANCE_TOOLTIP',
      { significanceThreshold: SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING },
    ),
    type: 'numeric',
    propertyKey: 'similarityScore',
    render: (score) => {
      // We use floor and not round because we don't want a score of 100 if it's not totally equal
      const formattedScore = Math.floor(score * 100);

      const isRelevant = formattedScore >= SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING;

      return (
        <Label
          background={isRelevant ? theme.colors.brand.secondary : theme.colors.greys.lighty}
          color={isRelevant ? theme.colors.greys.darkest : theme.colors.greys.lightest}
          type={'plain'}
          width={'fit-content'}
        >
          {formattedScore}
        </Label>
      );
    },
  },
  {
    id: '2',
    propertyKey: 'tags',
    filterType: 'string',
    name: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_TAGS'),
    render: (items) => <Tags items={items} />,
  },
  {
    id: '3',
    name: i18next.t('ADMIN.CASHIER_PRODUCTS.AUTO_MAPPING_MODAL_COLUMN_ASSOCIATED_PRODUCT'),
    type: 'string',
    propertyKey: 'suggestedProduct.name',
    filterType: 'string',
    large: true,
  },
];
