import styled from 'styled-components';

export const StockFormFiltersContainer = styled.div`
  padding: 24px 24px 0 24px;

  display: flex;
  flex-wrap: wrap;
`;

export const FilterContainer = styled.div`
  position: relative;

  margin: 0 24px 24px 0;

  .location-filter-container {
    margin-right: 0px !important;
  }

  .location-filter-input-text {
    margin-left: 16px !important;
  }

  > div > div > span {
    text-align: center;
  }
`;

export const FilterContainerActions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  position: relative;

  margin: 0 0 24px 24px;

  > div {
    padding: 0;
  }
`;
