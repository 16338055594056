import { connect } from 'react-redux';
import { decodeToken } from 'react-jwt';
import { replace, get } from 'lodash';
import { Toaster } from 'deepsight-react-components';
import i18next from 'i18next';
import React, { useState, useLayoutEffect, useEffect } from 'react';

import clientService from '../../services/client';
import inviteService from '../../services/invite';

import backgroundPicture from '../../assets/photos/login.png';
import inpulseFullLogo from '../../assets/logo/logotype.svg';

import { STEP_REGISTRATION } from './utils/constants';
import utilsRegistration from './utils';

import RegistrationSuccess from './components/RegistrationSuccess';
import TokenExpiredContent from './components/tokenExpired/';
import UserInformations from './components/UserInformations';
import UserPassword from './components/UserPassword';

import UserRegistration from '../../commons/DeepsightComponents/UserRegistration';

import { showConfirmationMessage, hideMessage } from '../../actions/messageconfirmation';

import {
  Container,
  GreyContainer,
  InpulseLogo,
  PictureContainer,
  UserRegistrationContainer,
} from './styledComponent';

const DEFAULT_LANGUAGE_CODE = 'fr';

export const Invite = (props) => {
  const { location, history, showMessage } = props;

  const [shouldRender, setShouldRender] = useState(false);

  const [userInformations, setUserInformations] = useState({
    firstName: '',
    lastName: '',
    password: '',
  });
  const [userToken, setUserToken] = useState(null);
  const [currentStep, setCurrentStep] = useState(STEP_REGISTRATION.USER_INFORMATION);
  const [componentSettings, setComponentSettings] = useState(null);
  const [decodedToken, setDecodedToken] = useState();
  const [languages, setLanguages] = useState([]);

  const verifyTokenValidity = async (formattedToken) => {
    try {
      const userBasicInformations = await inviteService.checkLinkValidity(formattedToken);

      const languages = await clientService.getAllLanguages();
      setLanguages(languages);
      const defaultLanguage = languages.find(({ id }) => id === userBasicInformations.languageId);

      if (!!defaultLanguage) {
        i18next.changeLanguage(defaultLanguage.code);
      }

      setUserInformations({ ...userInformations, ...userBasicInformations });
    } catch (err) {
      const errorStatus = get(err, 'response.status');
      if (!errorStatus || errorStatus === 403 || errorStatus === 409) {
        history.push('/login');
      }
      if (errorStatus === 401) {
        setDecodedToken(decodeToken(formattedToken));
        setCurrentStep(STEP_REGISTRATION.TOKEN_EXPIRED_STEP_1);
      }
    }
  };

  const getComponentSettings = (currentStep) => {
    if (
      [STEP_REGISTRATION.TOKEN_EXPIRED_STEP_1, STEP_REGISTRATION.TOKEN_EXPIRED_STEP_2].includes(
        currentStep,
      )
    ) {
      return {
        component: TokenExpiredContent,
        settings: utilsRegistration.getExpiredTokenSettings({
          handleResendMail,
          currentStep,
        }),
      };
    }
    if (currentStep === STEP_REGISTRATION.USER_INFORMATION) {
      return {
        component: UserInformations,
        settings: utilsRegistration.getUserInformationsSettings({
          firstName: userInformations.firstName,
          lastName: userInformations.lastName,
          languageId: userInformations.languageId,
          handleSwitchStep: setCurrentStep,
          handleSetUserInformations,
        }),
      };
    }
    if (currentStep === STEP_REGISTRATION.USER_PASSWORD) {
      return {
        component: UserPassword,
        settings: utilsRegistration.getUserPassword({
          isValidPassword: !!userInformations.password.length,
          handleSwitchStep: setCurrentStep,
          handleSetUserInformations,
          handleUserRegistration,
        }),
      };
    }
    if (currentStep === STEP_REGISTRATION.REGISTRATION_SUCCESS) {
      return {
        component: RegistrationSuccess,
        settings: utilsRegistration.getRegistrationSuccessSettings({ history }),
      };
    }
  };

  useLayoutEffect(() => {
    if (!location.search || !location.search.includes('?token=')) {
      history.push('/login');
    }

    const formattedToken = replace(location.search, '?token=', '');

    (async function CheckToken() {
      await verifyTokenValidity(formattedToken);
      setShouldRender(true);
    })();

    setUserToken(formattedToken);
  }, []);

  useEffect(() => {
    if (!currentStep || !userInformations) {
      return;
    }

    setComponentSettings(getComponentSettings(currentStep));
  }, [currentStep, userInformations]);

  const handleSetUserInformations = (property, value) => {
    if (!property) {
      return;
    }

    setUserInformations({ ...userInformations, [property]: value });
  };

  const handleResendMail = async () => {
    const { email, id, clientId } = decodedToken.user;

    try {
      await inviteService.resendEmail(email, id, clientId);

      setCurrentStep(STEP_REGISTRATION.TOKEN_EXPIRED_STEP_2);
    } catch (err) {
      showMessage(i18next.t('AUTHENTICATION.REGISTRATION.LANDING_FAIL_TO_SEND_LINK'), 'error');
    }
  };

  const handleUserRegistration = async () => {
    const payload = {
      firstName: get(userInformations, 'firstName', null),
      lastName: get(userInformations, 'lastName', null),
      password: get(userInformations, 'password', null),
      clientId: get(userInformations, 'clientId', null),
      userId: get(userInformations, 'accountId', null),
      languageId: get(userInformations, 'languageId', null),
      token: userToken,
    };

    try {
      const defaultLanguage = languages.find((language) => language.code === DEFAULT_LANGUAGE_CODE);
      if (!payload.languageId) {
        payload.languageId = defaultLanguage.id;
      }

      await clientService.userRegistration(payload);

      props.showMessage(
        i18next.t('AUTHENTICATION.REGISTRATION.LANDING_STEP_TWO_REGISTRATION_SUCCESS'),
        'success',
      );
      setCurrentStep(STEP_REGISTRATION.REGISTRATION_SUCCESS);
    } catch (error) {
      props.showMessage(
        i18next.t('AUTHENTICATION.REGISTRATION.LANDING_STEP_TWO_REGISTRATION_ERROR'),
        'error',
      );
    }
  };

  const inpulseRedirection = () => {
    if (currentStep !== STEP_REGISTRATION.REGISTRATION_SUCCESS) {
      return;
    }

    history.replace('login');
  };

  return shouldRender ? (
    <Container>
      <GreyContainer>
        <InpulseLogo
          alt="Inpulse-logo"
          interactive={currentStep === STEP_REGISTRATION.REGISTRATION_SUCCESS}
          src={inpulseFullLogo}
          onClick={inpulseRedirection}
        />
        <PictureContainer src={backgroundPicture} />
        {!!componentSettings && (
          <UserRegistrationContainer>
            <UserRegistration
              actions={componentSettings.settings.actions}
              Component={componentSettings.component}
              params={componentSettings.settings.params}
              title={componentSettings.settings.title}
            />
          </UserRegistrationContainer>
        )}
      </GreyContainer>
      <Toaster
        autoDelete={true}
        handleClose={props.hideMessage}
        hidden={!props.confirmationMessageReducer.messageBool}
        icon={props.confirmationMessageReducer.messageCustomIcon}
        status={props.confirmationMessageReducer.messageType}
        text={props.confirmationMessageReducer.message}
      />
    </Container>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({
  confirmationMessageReducer: state.confirmationMessageReducer,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  hideMessage: () => {
    dispatch(hideMessage());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
