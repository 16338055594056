import i18next from 'i18next';
import React, { useState } from 'react';

import Plus from '@assets/icons/black-plus.svg';

import theme from '@theme';

import { Box, BoxInput, Container, BoxInfo } from './styledComponents';

/*********************/
/** Handler Methods **/
/*********************/

export function incrementValue(value, setValue) {
  setValue(parseInt(value || 0) + 1);
}

export function decrementValue(value, setValue, minValue) {
  const updatedValue = parseInt(value || 0) - 1;

  if (updatedValue < minValue) {
    setValue(minValue);

    return;
  }

  setValue(updatedValue);
}

export function onEnterPress(e, value, handleSave) {
  if (e.key === 'Enter' && !isNaN(value)) {
    handleSave(value);
  }

  if (e.key === 'e') {
    return e.preventDefault();
  }
}

export function handleValueChange(value, setValue, minValue) {
  if (!value) {
    setValue('');
  }

  if (isNaN(value) || isNaN(parseInt(value))) {
    return;
  }

  if (value < minValue) {
    setValue(minValue);

    return;
  }

  const formattedValue = parseInt(value.substring(0, value.includes('-') ? 4 : 3));

  setValue(formattedValue);
}

/********************/
/** Render Methods **/
/********************/

export function renderNonEditableInput(production) {
  return (
    <Container>
      <Box className={'empty'}></Box>
      <Box className={'empty'}></Box>
      <Box>{production}</Box>
      <Box className={'empty'}></Box>
      <Box className={'empty'}></Box>
    </Container>
  );
}

export function renderEditingInput(
  value,
  minValue,
  handleSave,
  setValue,
  unsetActiveProduct,
  production,
) {
  return (
    <Container editProduction>
      <Box className={'clickable'} onClick={() => unsetActiveProduct()}>
        <svg
          fill="none"
          height="15"
          viewBox="0 0 29 29"
          width="15"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28.3908 0.564549C27.6372 -0.188182 26.4153 -0.188184 25.6616 0.564547L14.4623 11.7504L3.34118 0.642658C2.58656 -0.111055 1.36307 -0.111054 0.608451 0.642658C-0.146171 1.39637 -0.146173 2.61838 0.608449 3.37209L11.7296 14.4798L0.565229 25.6307C-0.18841 26.3835 -0.188409 27.6039 0.565229 28.3566C1.31887 29.1093 2.54076 29.1093 3.2944 28.3566L14.4588 17.2057L25.7013 28.4347C26.4559 29.1884 27.6794 29.1884 28.434 28.4347C29.1887 27.681 29.1887 26.459 28.434 25.7053L17.1915 14.4763L28.3908 3.29043C29.1445 2.5377 29.1444 1.31728 28.3908 0.564549Z"
            fill={theme.colors.greys.dark}
          />
        </svg>
      </Box>
      <Box className={'clickable'} onClick={() => decrementValue(value, setValue, minValue)}>
        <svg
          fill="none"
          height="2"
          viewBox="0 0 15 2"
          width="15"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect fill={theme.colors.greys.darkest} height="2" width="15" />
        </svg>
      </Box>
      <BoxInput
        autoFocus={true}
        max="999"
        min={minValue || 0}
        step="1"
        type="number"
        value={value}
        onChange={(e) => handleValueChange(e.target.value, setValue, minValue)}
        onKeyDown={(e) => onEnterPress(e, value, handleSave)}
      />
      <Box className={'clickable'} onClick={() => incrementValue(value, setValue)}>
        <svg
          fill="none"
          height="15"
          viewBox="0 0 13 13"
          width="15"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.58333 0H5.41667V5.41667H0V7.58333H5.41667V13H7.58333V7.58333H13V5.41667H7.58333V0Z"
            fill={theme.colors.greys.darkest}
          />
        </svg>
      </Box>
      <Box className={'clickable'} onClick={() => handleSave(value)}>
        <svg
          fill="none"
          height="12"
          viewBox="0 0 28 23"
          width="15"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.6638 0.0108381C25.1793 0.0607122 24.7058 0.28371 24.3369 0.678039L8.68923 17.575L3.66237 12.1442C3.24393 11.6964 2.69334 11.4697 2.13725 11.4666C1.60318 11.4638 1.06911 11.6706 0.650668 12.0895C-0.197235 12.941 -0.219258 14.3459 0.601115 15.2275L7.16961 22.3223C7.76975 22.9744 8.6672 23.1576 9.43802 22.8645C9.70781 22.7613 9.96108 22.599 10.1813 22.3772C10.2584 22.2967 10.33 22.2111 10.396 22.1214L27.4037 3.76342C28.2185 2.88111 28.1965 1.47513 27.3486 0.623141C26.8806 0.152028 26.2639 -0.051056 25.6638 0.0108381Z"
            fill={theme.colors.greys.darkest}
          />
        </svg>
      </Box>
      <Box>
        <BoxInfo>
          <div>{production + value}</div>
          <div className="text">{i18next.t('PRODUCTION.LOSSES.HEADER_TOTAL')}</div>
        </BoxInfo>
      </Box>
    </Container>
  );
}

export function renderEditableInput(production, setValue, setActiveProduct, classNameAddButton) {
  return (
    <Container>
      <Box className={'empty'}></Box>
      <Box className={'empty'}></Box>
      <Box>{production}</Box>
      <Box
        className={`${classNameAddButton} clickable`}
        onClick={() => {
          setValue('');
          setActiveProduct();
        }}
      >
        <img alt="cancel" src={Plus} />
      </Box>
      <Box className={'empty'}></Box>
      <Box className={'empty'}></Box>
    </Container>
  );
}

export const InputIncrementSection = (props) => {
  const {
    handleSave,
    isEditable,
    isProductActive,
    production,
    setActiveProduct,
    unsetActiveProduct,
    classNameAddButton,
    minValue,
  } = props;

  const [value, setValue] = useState('');

  if (!isEditable) {
    return renderNonEditableInput(production);
  }

  if (isProductActive) {
    return renderEditingInput(
      value,
      minValue,
      handleSave,
      setValue,
      unsetActiveProduct,
      production,
    );
  }

  return renderEditableInput(production, setValue, setActiveProduct, classNameAddButton);
};

export default InputIncrementSection;
