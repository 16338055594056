import i18next from 'i18next';

import { CHANNELS, CHANNEL_ID_KEY_BY_NAME } from '@commons/constants/channel';
import { ENTITY_UNITS } from '@commons/constants/units';

export const formatEntities = (compositions) =>
  compositions.map(({ entity, quantity, channels, allergens }) => ({
    ...entity,
    __entityId: entity.__entityId || entity.id,
    quantity,
    cost: entity.isIngredient ? entity.cost : null,
    unit:
      entity.unit === ENTITY_UNITS.UNIT
        ? i18next.t('ORDERS.ORDERS.EXPORT_LABEL_UNIT')
        : entity.unit,
    category: entity.isIngredient ? i18next.t('GENERAL.INGREDIENT') : entity.category,
    onSite: channels.some(({ name }) => name === CHANNELS.ON_SITE),
    delivery: channels.some(({ name }) => name === CHANNELS.DELIVERY),
    allergens: allergens || [],
  }));

export const formatEntity = (composition) => {
  const { entity, quantity, channels } = composition;

  return {
    ...entity,
    __entityId: entity.__entityId || entity.id,
    quantity,
    cost: entity.isIngredient ? entity.cost : null,
    unit:
      entity.unit === ENTITY_UNITS.UNIT
        ? i18next.t('ORDERS.ORDERS.EXPORT_LABEL_UNIT')
        : entity.unit,
    category: entity.isIngredient ? i18next.t('GENERAL.INGREDIENT') : entity.category,
    onSite: channels.some(({ name }) => name === CHANNELS.ON_SITE),
    delivery: channels.some(({ name }) => name === CHANNELS.DELIVERY),
  };
};

export const formatCompositions = (entities) =>
  entities.map((entity) => {
    const channelIds = [];

    if (entity.onSite) {
      channelIds.push(CHANNEL_ID_KEY_BY_NAME[CHANNELS.ON_SITE]);
    }

    if (entity.delivery) {
      channelIds.push(CHANNEL_ID_KEY_BY_NAME[CHANNELS.DELIVERY]);
    }

    return {
      entity,
      channelIds,
      quantity: entity.quantity,
      cost: entity.isIngredient ? entity.cost : null,
      allergens: entity.allergens || [],
    };
  });
