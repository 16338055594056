import { isEmpty } from 'lodash';
import i18next from 'i18next';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import {
  Button as LibraryButton,
  Checkbox as LibraryCheckbox,
  Dropdown as LibraryDropdown,
  ExpandableEmbeddedList as LibraryExpandableEmbeddedList,
  Franco as LibraryFranco,
  InputIncrement as LibraryInputIncrement,
  InputNumber as LibraryInputNumber,
  Label as LibraryLabel,
  ListView as LibraryListView,
  NestedList as LibraryNestedList,
  ProgressBar as LibraryProgressBar,
  RadioButton as LibraryRadioButton,
  SearchBar as LibrarySearchBar,
  SimpleTableView as LibrarySimpleTableView,
  TableView as LibraryTableView,
  TimeRangedLineGraph as LibraryTimeRangedLineGraph,
  ToggleButton as LibraryToggleButton,
  ToggleSwitch as LibraryToggleSwitch,
  Tooltip as LibraryTooltip,
  HoverTooltip as LibraryHoverTooltip,
} from 'deepsight-react-components';

import { getTheme } from './theme';

const buttonColors = {
  blue: 'inpulse-default',
  'blue-outline': 'inpulse-outline',
  'blue-outline-no-shadow': 'inpulse-outline',
  'dark-blue': 'inpulse-default',
  'strong-dark-blue': 'inpulse-default',
  'light-blue': 'inpulse-default',
  'transparent-hover-light-blue': 'inpulse-default',
  'transparent-no-hover': 'inpulse-default',
  green: 'inpulse-default',
  'dark-green': 'inpulse-default',
  grey: 'inpulse-default',
  orange: 'inpulse-default',
  red: 'inpulse-default',
  white: 'inpulse-default',
  locked: 'inpulse-default',
  default: 'inpulse-default',
};

export const INPUT_WIDTH = {
  SMALL: '96px',
  MEDIUM: '160px',
  LARGE: '240px',
  EXTRA_LARGE: '352px',
  FULL: '100%',
};

export const Tooltip = (props) => <LibraryTooltip {...props} theme={getTheme()} />;

export const Button = (props) => (
  <LibraryButton
    {...props}
    color={buttonColors[props.color] || props.color}
    formatText={props.hasOwnProperty('formatText') ? props.formatText : false}
    theme={getTheme()}
  />
);

export const Checkbox = (props) => <LibraryCheckbox {...props} theme={getTheme()} />;

export const RadioButton = (props) => <LibraryRadioButton {...props} theme={getTheme()} />;

export const ExpandableEmbeddedList = (props) => (
  <LibraryExpandableEmbeddedList {...props} theme={getTheme()} />
);

export const ListView = forwardRef((props, ref) => (
  <LibraryListView {...props} ref={ref} theme={getTheme()} />
));

export const TableView = (props) => <LibraryTableView {...props} theme={getTheme()} />;

export const SearchBar = (props) => <LibrarySearchBar {...props} theme={getTheme()} />;

export const InputNumber = (props) => <LibraryInputNumber {...props} theme={getTheme()} />;

export const Label = (props) => <LibraryLabel {...props} theme={getTheme()} />;

export const Franco = (props) => <LibraryFranco {...props} theme={getTheme()} />;

export const InputIncrement = (props) => <LibraryInputIncrement {...props} theme={getTheme()} />;

export const ToggleSwitch = (props) => <LibraryToggleSwitch {...props} theme={getTheme()} />;

export const Dropdown = (props) => {
  const convertToPropsFormat = (array) => {
    if (!array) {
      return [];
    }

    const arrayWithOnlyValidData = array.filter((item) => !!item);

    return arrayWithOnlyValidData.map((item, index) => ({
      ...item,
      id: item.id === undefined ? index : item.id,
      value: item.value === undefined ? item.name : item.value,
    }));
  };

  const formattedItems = convertToPropsFormat(props.items);
  const formattedSelectedItems = convertToPropsFormat(
    !isEmpty(props.selectedItem) ? [props.selectedItem] : props.selectedItems,
  );

  const buildLabelStyleProps = () => {
    if (!props.isLabelTextSmall) {
      return props.labelStyle;
    }

    return { ...props.labelStyle, font: getTheme().fonts.textSmall };
  };

  // Explicitly declare placeholders to avoid being overwritten by undefined values being sent by the caller
  const placeholder = props.placeholder
    ? props.placeholder
    : i18next.t('GENERAL.DROPDOWN_PLACEHOLDER');

  const searchPlaceholder = props.searchPlaceholder
    ? props.searchPlaceholder
    : i18next.t('GENERAL.SEARCH') + '...';

  return (
    <LibraryDropdown
      {...props}
      items={formattedItems}
      labelStyle={buildLabelStyleProps()}
      placeholder={placeholder}
      searchPlaceholder={searchPlaceholder}
      selectedItems={formattedSelectedItems}
      theme={getTheme()}
    />
  );
};

export const DropdownRowMultipleLines = styled.div`
  row-gap: 4px;
  display: flex;
  margin: 14px 0px;
  flex-direction: column;
  width: 224px;
  justify-content: center;
`;

export const NestedList = (props) => <LibraryNestedList {...props} theme={getTheme()} />;

export const TimeRangedLineGraph = (props) => (
  <LibraryTimeRangedLineGraph {...props} theme={getTheme()} />
);

export const ToggleButton = (props) => <LibraryToggleButton {...props} theme={getTheme()} />;

export const ProgressBar = (props) => <LibraryProgressBar {...props} theme={getTheme()} />;

export const SimpleTableView = (props) => <LibrarySimpleTableView {...props} theme={getTheme()} />;

export const HoverTooltip = (props) => <LibraryHoverTooltip {...props} theme={getTheme()} />;

export default {
  Button,
  Tooltip,
  Checkbox,
  ExpandableEmbeddedList,
  SearchBar,
  InputIncrement,
  ToggleSwitch,
  Dropdown,
  NestedList,
  DropdownRowMultipleLines,
  SimpleTableView,
  TimeRangedLineGraph,
  ToggleButton,
  ProgressBar,
  HoverTooltip,
};
