import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import accountService from '../../services/account';

import { NOTIFICATION_TYPES } from '../constants/notificationTypes';
import Text from '../Text';

import { getTheme } from '../utils/theme';

import { Container, BannerContainer } from './styledComponents';

const INTERVAL_CHECK_USER_NOTIFICATIONS = 2 * 60 * 1000; // 2 minutes

const Banner = ({ backgroundColor, textColor, text }) => (
  <Container>
    <BannerContainer backgroundColor={backgroundColor}>
      <Text color={textColor} style={{ textAlign: 'center' }}>
        {text}
      </Text>
    </BannerContainer>
  </Container>
);

const Notifications = (props) => {
  const theme = getTheme();

  const [shouldShowPaymentBanner, setShouldShowPaymentBanner] = useState(false);
  const [getShowBannerInterval, setGetShowBannerInterval] = useState(0);

  const bannerSetStates = {
    [NOTIFICATION_TYPES.PAYMENT]: setShouldShowPaymentBanner,
  };

  useEffect(() => {
    // Can't use redux value because it's not been set yet
    const userId = localStorage.getItem('userId');

    getShowBanner(userId);

    const getShowBannerJob = setInterval(getShowBanner, INTERVAL_CHECK_USER_NOTIFICATIONS, userId);
    setGetShowBannerInterval(getShowBannerJob);

    /*
      Acts as a componentWillUnmount so it clears the interval when the component stops being rendered
    */
    return () => {
      clearInterval(getShowBannerInterval);
    };
  }, []);

  const getShowBanner = async (userId, type = NOTIFICATION_TYPES.PAYMENT) => {
    try {
      const notifications = await accountService.getAccountNotifications(userId);

      const shouldUpdateBannerStatus = !!notifications.find(
        (notification) => notification.type === type,
      );

      bannerSetStates[type](shouldUpdateBannerStatus);
    } catch {
      console.info('[#getShowBanner] - Silently ignore this Error');
    }
  };

  return (
    <>
      {shouldShowPaymentBanner && (
        <Banner
          backgroundColor={theme.colors.infoRed}
          text={i18next.t('GENERAL.PAYMENT_BANNER_CONTENT')}
          textColor={theme.colors.greys.lightest}
        />
      )}
    </>
  );
};

export default Notifications;
