import i18next from 'i18next';

import { canDeleteRushOrder } from '@selectors/actions/rushOrderActions';

export const getRowActions = (props) => {
  const {
    handleDeletionConfirmationModal,
    handleExportList,
    handleExportContent,
    authorizedActions,
  } = props;

  return [
    {
      id: 'delete',
      actionLabel: () => i18next.t('ORDERS.ORDERS.LIST_DELETE_ORDER'),
      isHidden: () => !canDeleteRushOrder(authorizedActions),
      handleAction: (selectedRushOrder) => handleDeletionConfirmationModal(selectedRushOrder),
      actionIcon: () => '/images/inpulse/delete-black-small.svg',
    },
    {
      id: 'export',
      actionLabel: () => i18next.t('ORDERS.ORDERS.EXPORT_ORDER'),
      actionIcon: () => '/images/inpulse/file-download-black-thick.svg',
      handleAction: (rowRushOrder) => handleExportList([rowRushOrder]),
    },
    {
      id: 'export-content',
      actionLabel: () => i18next.t('GENERAL.EXPORT_CONTENT_SINGULAR'),
      actionIcon: () => '/images/inpulse/file-download-black-thick.svg',
      handleAction: (rowRushOrder) => handleExportContent([rowRushOrder]),
    },
  ];
};

export default getRowActions;
