import i18next from 'i18next';
import React from 'react';

import ProductionPlanningLogo from '@assets/logo/production-planning.svg';

import IncludeNextStepModal from '../../IncludeNextStepModal';

export const AskProductionPlanningModal = (props) => {
  const { includeProductionPlanningStep, setIncludeProductionPlanningStep } = props;

  return (
    <IncludeNextStepModal
      iconSrc={ProductionPlanningLogo}
      includeNextStep={includeProductionPlanningStep}
      setIncludeNextStep={setIncludeProductionPlanningStep}
      text={i18next.t('ADMIN.PRODUCTS.MODAL_ASK_PRODUCTION_PLANNING_CREATION_INFO')}
      title={i18next.t('ADMIN.PRODUCTS.MODAL_ASK_PRODUCTION_PLANNING_CREATION')}
    />
  );
};
