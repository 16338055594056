import styled from 'styled-components';

export const DashboardMainContainer = styled.div`
  width: calc(100% - 64px);
  min-width: 1230px;
  height: calc(100% - 48px);
  overflow: auto;

  background-color: ${(props) => props.theme.colors.greys.lighter};

  position: absolute;

  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;

  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
`;

export const InpulseForecastPrecisionChartContainer = styled.div`
  .forecast-precision-container {
    width: 100%;
    height: 400px;
    background-color: ${(props) => props.theme.colors.greys.lightest};
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;
    padding-top: 40px;
    margin-top: 20px;
  }

  .forecast-precision-head-encloser {
    display: flex;
    justify-content: space-between;
  }

  .forecast-precision-container-title > h2 {
    margin: 0;
    padding: 0;
    font-size: 18px;
  }

  .forecast-precision-filter-bar {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .forecast-precision-buttons-encloser {
    display: flex;
  }

  .forecast-precision-buttons-encloser > div {
    margin-right: 10px;
  }
`;
