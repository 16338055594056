import { JsonEditor as Editor } from 'jsoneditor-react';
import Ajv from 'ajv';
import React from 'react';

import 'jsoneditor-react/es/editor.min.css';

import { ModalContent, Text } from './styledComponents';

const ajv = new Ajv({ allErrors: true, verbose: true });

function PropsEditorModal(props) {
  const { json, schema, onChange, description } = props;

  return (
    <ModalContent>
      {description && <Text>{description}</Text>}
      <Editor
        ajv={ajv}
        history={true}
        navigationBar={false}
        schema={schema}
        search={false}
        value={json}
        onChange={onChange}
      />
    </ModalContent>
  );
}

export default PropsEditorModal;
