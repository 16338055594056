import i18next from 'i18next';
import React from 'react';

import { Checkbox } from '@commons/utils/styledLibraryComponents';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import Text from '@commons/Text';

import { SSPM_MODAL_STEPS } from '../sspmModalsSteps';

import { Container, CheckBoxContainer, ContentContainer } from './styledComponents';

export const SelectAvailabilitiesStepContent = ({
  selectedAvailabilities,
  setSelectedAvailabilities,
}) => (
  <Container>
    <ContentContainer>
      <CheckBoxContainer>
        <Checkbox
          handleClick={() =>
            setSelectedAvailabilities({
              ...selectedAvailabilities,
              available: !selectedAvailabilities.available,
            })
          }
          isChecked={selectedAvailabilities.available}
          shape="square"
        />
        <Text>{i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_PROPERTIES_AVAILABLE_ORDER')}</Text>
      </CheckBoxContainer>
      <CheckBoxContainer>
        <Checkbox
          handleClick={() =>
            setSelectedAvailabilities({
              ...selectedAvailabilities,
              hasStock: !selectedAvailabilities.hasStock,
            })
          }
          isChecked={selectedAvailabilities.hasStock}
          shape="square"
        />
        <Text>{i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_PROPERTIES_AVAILABLE_FOR_STOCKS')}</Text>
      </CheckBoxContainer>
      <CheckBoxContainer>
        <Checkbox
          handleClick={() =>
            setSelectedAvailabilities({
              ...selectedAvailabilities,
              hasDlc: !selectedAvailabilities.hasDlc,
            })
          }
          isChecked={selectedAvailabilities.hasDlc}
          shape="square"
        />
        <Text>{i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_PROPERTIES_AVAILABLE_FOR_LOSSES')}</Text>
      </CheckBoxContainer>
    </ContentContainer>
  </Container>
);

export const getSspmStepModalConfig = ({
  currentStep,
  setCurrentStep,
  selectedAvailabilities,
  setSelectedAvailabilities,
  storeName,
  handleSspmUpdate,
  handleSspmUpdateModalCloseCleanUp,
}) => {
  if (currentStep === SSPM_MODAL_STEPS.SELECT_AVAILABILITIES) {
    return {
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_SAVE_BUTTON(),
          handleClick: () => setCurrentStep(SSPM_MODAL_STEPS.CONFIRM_CHANGES),
          preventClosing: true,
        },
      ],
      title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_TITLE_UPDATE_AVAILABILITIES'),
      icon: '/images/inpulse/pen-black-small.svg',
      type: 'action',
      handleCloseCleanUp: handleSspmUpdateModalCloseCleanUp,
      component: SelectAvailabilitiesStepContent,
      data: {
        selectedAvailabilities,
        setSelectedAvailabilities,
      },
      height: 'auto',
    };
  }

  return getConfirmationModal({
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_CONFIRM_BUTTON(),
        handleClick: () => handleSspmUpdate(),
        preventClosing: true,
      },
    ],
    handleCloseCleanUp: handleSspmUpdateModalCloseCleanUp,
    title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_WARNING_ALL_STORES_WILL_BE_IMPACTED', {
      storeName,
    }),
    content: i18next.t(
      'ADMIN.SUPPLIER_PRODUCTS.MODAL_WARNING_ALL_STORES_WILL_BE_IMPACTED_CONTENT',
      { storeName },
    ),
    icon: '/images/inpulse/warning-white-small.svg',
  });
};
