import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import sortActionsList from '@commons/utils/sortActionsList';

export const getRowActionsSettings = (settings) => {
  const {
    translatedStoreName,
    displayModalEditGroup,
    displayModalDeleteGroup,
    openStoresAssociationModal,
  } = settings;

  const rowActions = [
    {
      id: 'action-store-association',
      actionLabel: () =>
        i18next.t('BACKOFFICE.GROUPS.STORE_ASSOCIATION_ACTION_LABEL', {
          storeName: translatedStoreName,
        }),
      handleAction: (group) => openStoresAssociationModal(group),
      actionIcon: () => '/images/inpulse/link-black-small.svg',
    },
    {
      id: 'edit',
      actionLabel: () => i18next.t('BACKOFFICE.GROUPS.EDIT_ACTION_LABEL'),
      handleAction: (group) => displayModalEditGroup(group),
      actionIcon: () => '/images/inpulse/pen-black-small.svg',
    },
    {
      id: 'delete',
      actionLabel: () => i18next.t('BACKOFFICE.GROUPS.DELETE_SINGLE_GROUP_ACTION_LABEL'),
      handleAction: (group) => displayModalDeleteGroup([group]),
      actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
    },
  ];

  return sortActionsList(rowActions);
};

export const getActionsSettings = (settings) => {
  const {
    selectedItems,
    isLoading,
    translatedStoreName,
    displayModalCreateGroup,
    displayModalEditGroup,
    displayModalDeleteGroup,
    openStoresAssociationModal,
  } = settings;

  const actions = [
    {
      id: 'add',
      fixed: true,
      handleAction: () => displayModalCreateGroup(),
      actionLabel: i18next.t('GENERAL.CREATE'),
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={displayModalCreateGroup}
          icon={'/images/inpulse/add-white-small.svg'}
          isDisabled={isLoading}
          label={i18next.t('GENERAL.CREATE')}
        />
      ),
    },
    {
      id: 'action-store-association',
      actionLabel: i18next.t('BACKOFFICE.GROUPS.STORE_ASSOCIATION_ACTION_LABEL', {
        storeName: translatedStoreName,
      }),
      handleAction: () => openStoresAssociationModal(selectedItems[0]),
      actionIcon: () => '/images/inpulse/link-black-small.svg',
      isHidden: () => selectedItems.length !== 1,
    },
    {
      id: 'edit',
      actionLabel: i18next.t('BACKOFFICE.GROUPS.EDIT_ACTION_LABEL'),
      handleAction: () => displayModalEditGroup(selectedItems[0]),
      actionIcon: () => '/images/inpulse/pen-black-small.svg',
      isHidden: () => selectedItems.length !== 1,
    },
    {
      id: 'delete',
      actionLabel:
        selectedItems.length === 1
          ? i18next.t('BACKOFFICE.GROUPS.DELETE_SINGLE_GROUP_ACTION_LABEL')
          : i18next.t('BACKOFFICE.GROUPS.DELETE_MULTIPLE_GROUPS_ACTION_LABEL'),
      handleAction: () => displayModalDeleteGroup(selectedItems),
      actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
      isHidden: () => !selectedItems.length,
    },
  ];

  return sortActionsList(actions);
};

export default {
  getActionsSettings,
  getRowActionsSettings,
};
