import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: calc(100% - 40px);

  padding: 24px;

  gap: 24px;
`;

export const FilterContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  column-gap: 24px;
`;

export const GraphContainer = styled.div`
  width: 100%;

  padding: 16px;

  border: solid 1px ${(props) => props.theme.colors.greys.lighty};
  background-color: ${(props) => props.theme.colors.greys.lightest};

  border-radius: 8px;

  height: 100%;
  max-height: 452px;
`;
