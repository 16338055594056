import _ from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import sortActionsList from '@commons/utils/sortActionsList';

import {
  canCorrectProductLoss,
  canCorrectCentralKitchenLoss,
  canCorrectSupplierProductLoss,
  canCreateProductLoss,
  canCreateCentralKitchenLoss,
  canCreateSupplierProductLoss,
  canDeleteProductLoss,
  canDeleteCentralKitchenLoss,
  canDeleteSupplierProductLoss,
} from '@selectors/actions/lossActions';

import { LOSS_TYPE } from './constants';

const isDeleteDisabled = (
  disableActions,
  lossType,
  authorizedActions,
  hasCashierLoss,
  isCentralKitchen,
) => {
  let canDeleteLoss, canCorrectLoss;
  switch (lossType) {
    case LOSS_TYPE.PRODUCT:
      canDeleteLoss = canDeleteProductLoss(authorizedActions);
      canCorrectLoss = canCorrectProductLoss(authorizedActions);
      break;
    case LOSS_TYPE.RECIPE:
      canDeleteLoss = canDeleteCentralKitchenLoss(authorizedActions);
      canCorrectLoss = canCorrectCentralKitchenLoss(authorizedActions);
      break;
    case LOSS_TYPE.SUPPLIER_PRODUCT:
      canDeleteLoss = isCentralKitchen
        ? canDeleteCentralKitchenLoss(authorizedActions)
        : canDeleteSupplierProductLoss(authorizedActions);
      canCorrectLoss = isCentralKitchen
        ? canCorrectCentralKitchenLoss(authorizedActions)
        : canCorrectSupplierProductLoss(authorizedActions);
      break;
    default:
      canDeleteLoss = false;
      canCorrectLoss = false;
  }

  const isEditing = !disableActions && !hasCashierLoss && canDeleteLoss;

  const isCorrecting = disableActions && !hasCashierLoss && canCorrectLoss;

  return !(isEditing || isCorrecting);
};

const isAddDisplayed = (disableActions, lossType, authorizedActions, isCentralKitchen) => {
  let canCreateLoss, canCorrectLoss;
  switch (lossType) {
    case LOSS_TYPE.PRODUCT:
      canCreateLoss = canCreateProductLoss(authorizedActions);
      canCorrectLoss = canCorrectProductLoss(authorizedActions);
      break;
    case LOSS_TYPE.RECIPE:
      canCreateLoss = canCreateCentralKitchenLoss(authorizedActions);
      canCorrectLoss = canCorrectCentralKitchenLoss(authorizedActions);
      break;
    case LOSS_TYPE.SUPPLIER_PRODUCT:
      canCreateLoss = isCentralKitchen
        ? canCreateCentralKitchenLoss(authorizedActions)
        : canCreateSupplierProductLoss(authorizedActions);
      canCorrectLoss = isCentralKitchen
        ? canCorrectCentralKitchenLoss(authorizedActions)
        : canCorrectSupplierProductLoss(authorizedActions);
      break;
    default:
      canCreateLoss = false;
      canCorrectLoss = false;
  }

  const isEditing = !disableActions && canCreateLoss;

  const isCorrecting = disableActions && canCorrectLoss;

  return isEditing || isCorrecting;
};

export const getActionsByType = ({
  lossType,
  allItems,
  selectedItems,
  createLoss,
  exportLosses,
  deleteLosses,
  disableActions,
  authorizedActions,
  isCentralKitchen = false,
}) => {
  const noData = _.isEmpty(allItems);

  const hasCashierLoss = selectedItems.some(({ isCashierLoss }) => isCashierLoss);

  const actions = [
    {
      id: 'export',
      actionLabel: i18next.t('LOSSES.PRODUCTS.ACTION_EXPORT'),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: () => exportLosses(),
      isHidden: () => !_.isEmpty(selectedItems),
    },
    {
      id: 'delete',
      actionLabel: i18next.t('LOSSES.PRODUCTS.ACTION_DELETE', {
        count: selectedItems.length,
      }),
      actionIcon: () =>
        isDeleteDisabled(
          disableActions,
          lossType,
          authorizedActions,
          hasCashierLoss,
          isCentralKitchen,
        )
          ? '/images/inpulse/delete-ip-lmgrey-small.svg'
          : '/images/inpulse/delete-ip-black-small.svg',
      handleAction: () => deleteLosses(selectedItems),
      isDisabled: () =>
        isDeleteDisabled(
          disableActions,
          lossType,
          authorizedActions,
          hasCashierLoss,
          isCentralKitchen,
        ),
      isHidden: () => _.isEmpty(selectedItems) || noData,
      disabledTooltipText: () =>
        disableActions || hasCashierLoss
          ? i18next.t(
              hasCashierLoss
                ? 'LOSSES.PRODUCTS.DISABLED_ACTION_CASHIER_LOSS_TOOLTIP'
                : 'LOSSES.PRODUCTS.DISABLED_ACTION_TOOLTIP',
            )
          : null,
    },
  ];

  if (!noData) {
    actions.unshift({
      id: 'add',
      fixed: isAddDisplayed(disableActions, lossType, authorizedActions, isCentralKitchen),
      actionLabel: i18next.t('GENERAL.ADD'),
      handleAction: () => createLoss(),
      isHidden: () =>
        !isAddDisplayed(disableActions, lossType, authorizedActions, isCentralKitchen),
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={() => createLoss()}
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('GENERAL.ADD')}
        />
      ),
      disabledTooltipText: () =>
        disableActions ? i18next.t('LOSSES.PRODUCTS.DISABLED_ACTION_TOOLTIP') : null,
    });
  }

  return sortActionsList(actions);
};

export const getRowActions = ({
  deleteLosses,
  disableActions,
  lossType,
  authorizedActions,
  isCentralKitchen = false,
}) => [
  {
    id: 'delete',
    actionLabel: () => i18next.t('LOSSES.PRODUCTS.ACTION_DELETE'),
    handleAction: (item) => deleteLosses([item]),
    actionIcon: (value) =>
      isDeleteDisabled(
        disableActions,
        lossType,
        authorizedActions,
        value.isCashierLoss,
        isCentralKitchen,
      )
        ? '/images/inpulse/delete-ip-lmgrey-small.svg'
        : '/images/inpulse/delete-ip-black-small.svg',
    isDisabled: (value) =>
      isDeleteDisabled(
        disableActions,
        lossType,
        authorizedActions,
        value.isCashierLoss,
        isCentralKitchen,
      ),
    disabledTooltipText: (value) =>
      disableActions || value.isCashierLoss
        ? i18next.t(
            value.isCashierLoss
              ? 'LOSSES.PRODUCTS.DISABLED_ACTION_CASHIER_LOSS_TOOLTIP'
              : 'LOSSES.PRODUCTS.DISABLED_ACTION_TOOLTIP',
          )
        : null,
  },
];
