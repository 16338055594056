import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

import { canCreateTransfer, canDeleteTransfer } from '@selectors/actions/transferActions';

import TransferForm from '../components/TransferForm';

import ExportListModal from './exports/exportList';

const exportList = (transfers, openModalExportInfo, clientStoreNameTranslation) => {
  const mainSheetData = transfers.map((transfer) => ({
    sender: transfer.senderStore.name,
    recipient: transfer.recipientStore.name,
    sentAt: moment.tz(transfer.sentAt, transfer.senderStore.timezone),
    status: transfer.hasBeenReceived
      ? i18next.t('STOCKS.TRANSFERS.SENDING_STATUS_VALIDATED')
      : i18next.t('STOCKS.TRANSFERS.SENDING_STATUS_SENT'),
    totalPrice: transfer.totalPrice,
  }));

  openModalExportInfo({
    component: ExportListModal,
    title: i18next.t('STOCKS.TRANSFERS.EXPORT_MODAL_TITLE'),
    mainSheetData,
    clientStoreNameTranslation,
    sheetName: i18next.t('FEATURE.OPERATIONS.TRANSFER'),
  });
};

export const getActions = (
  selectedItems,
  filteredTransfers,
  openModalExportInfo,
  clientStoreNameTranslation,
  openModal,
  openDeleteConfirmationModal,
  fetchTransfers,
  handleTransferExportContent,
  authorizedActions,
) => {
  const actions = [
    {
      id: 'action-create',
      fixed: canCreateTransfer(authorizedActions),
      actionLabel: i18next.t('GENERAL.CREATE'),
      isHidden: () => !canCreateTransfer(authorizedActions),
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={() => {
            const params = {
              component: TransferForm,
              fetchTransfers,
            };

            openModal(params, true);
          }}
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('GENERAL.CREATE')}
        />
      ),
    },
  ];

  const areItemsSelected = !_.isEmpty(selectedItems);

  if (selectedItems.length === 1) {
    actions.push(
      {
        id: 'delete_transfer',
        actionLabel: i18next.t('STOCKS.TRANSFERS.DELETE_TRANSFER'),
        actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
        handleAction: () => openDeleteConfirmationModal(selectedItems.map((item) => item.id)),
        isHidden: () => !canDeleteTransfer(authorizedActions),
      },
      {
        id: 'export_single_transfer',
        actionLabel: i18next.t('STOCKS.TRANSFERS.EXPORT_TRANSFER'),
        actionIcon: () => '/images/inpulse/file-download-black-small.svg',
        handleAction: () =>
          exportList(selectedItems, openModalExportInfo, clientStoreNameTranslation),
      },
      {
        id: 'export_content',
        actionLabel: i18next.t('GENERAL.EXPORT_CONTENT_SINGULAR'),
        actionIcon: () => '/images/inpulse/file-download-black-small.svg',
        handleAction: () => handleTransferExportContent(selectedItems),
      },
    );

    return actions;
  }

  actions.push(
    {
      id: 'export_list',
      actionLabel: areItemsSelected
        ? i18next.t('STOCKS.STOCKS.FORM_EXPORT_SELECTION_BUTTON')
        : i18next.t('GENERAL.EXPORT_LIST'),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: () =>
        exportList(
          areItemsSelected ? selectedItems : filteredTransfers,
          openModalExportInfo,
          clientStoreNameTranslation,
        ),
    },
    {
      id: 'export_content',
      actionLabel: i18next.t('GENERAL.EXPORT_CONTENT_PLURAL'),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: () =>
        handleTransferExportContent(areItemsSelected ? selectedItems : filteredTransfers),
    },
  );

  return actions;
};

export const getRowActions = (
  openModalExportInfo,
  clientStoreNameTranslation,
  openDeleteConfirmationModal,
  handleTransferExportContent,
  authorizedActions,
) => {
  const actions = [
    {
      id: 'delete_transfer',
      actionLabel: () => i18next.t('STOCKS.TRANSFERS.DELETE_TRANSFER'),
      actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
      handleAction: (item) => openDeleteConfirmationModal([item.id]),
      isHidden: () => !canDeleteTransfer(authorizedActions),
    },
    {
      id: 'export_single_transfer',
      actionLabel: () => i18next.t('STOCKS.TRANSFERS.EXPORT_TRANSFER'),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: (it) => exportList([it], openModalExportInfo, clientStoreNameTranslation),
    },
    {
      id: 'export_content',
      actionLabel: () => i18next.t('GENERAL.EXPORT_CONTENT_SINGULAR'),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: (item) => handleTransferExportContent([item]),
    },
  ];

  return actions;
};
