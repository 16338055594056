import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.greys.lighter};
  border-radius: 16px;
  position: relative;
  overflow-x: hidden;
`;

export const Progress = styled.div`
  position: absolute;
  height: inherit;
  width: calc(${({ percentage }) => percentage}% + 24px);
  border-radius: 16px;
  left: -24px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  background: ${({ theme, variation, withGradient }) =>
    withGradient
      ? `linear-gradient(to right, ${theme.progressBar.color.start[variation]}, ${theme.progressBar.color.end[variation]})`
      : theme.progressBar.color.end[variation]};

  &:hover {
    background: ${({ theme, variation, withGradient }) =>
      withGradient
        ? `linear-gradient(to right, ${theme.progressBar.hover.start[variation]}, ${theme.progressBar.hover.end[variation]})`
        : theme.progressBar.hover.end[variation]};
  }
`;

export const Dot = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${({ theme, variation }) =>
    theme.progressBar.dot.color[variation]};
  border-radius: 50%;
  position: absolute;
  top: 12px;
  transform: translate(0, -50%);
  left: calc(${({ percentage }) => percentage}% - 20px);
  pointer-events: none;
`;

export const Text = styled.span`
  font: ${({ theme }) => theme.fonts.textMicroHeight16Weight700Bold};
  color: ${({ theme }) => theme.colors.black};
  position: absolute;
  top: 4px;
  // Beyond 91% the text is too close to the right edge of the bar
  left: ${({ displayedPercentage, percentage }) => {
    const percentageLength = String(displayedPercentage).length;

    // (28 = dot(16px) + ProgressBar after dot (4px) + Text gap (8px)), we also use percentageLength + 1 because of % character, 5.5 is an approximation of character width
    const valueOver91Percent = 5.5 * (percentageLength + 1) + 28;

    return `calc(${percentage}% ${
      percentage > 91 ? `- ${valueOver91Percent}px` : '+ 8px'
    })`;
  }};
`;
