import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 40px);
`;

export const HeaderContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 24px;
  padding: 24px 0px;
`;
