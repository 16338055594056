import React from 'react';

import Text, { ENUM_FONTS, ENUM_COLORS } from '../Text';

import {
  Container,
  TitleContainer,
  CategoryTitleContainer,
  RowItem,
  RowContainer,
} from './styledComponents';

const AnalyticsList = ({ title, data }) => (
  <Container>
    <TitleContainer>
      <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG_BOLD_HEIGHT_14}>
        {title}
      </Text>
    </TitleContainer>
    {data.map(({ categoryName, categoryValue, children }) => (
      <div key={categoryName}>
        <CategoryTitleContainer>
          <RowItem width={'120px'}>
            <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG_MICRO_BOLD}>
              {categoryName}
            </Text>
          </RowItem>
          <RowItem width={'96px'}>
            <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_BIG_MICRO_BOLD}>
              {categoryValue}
            </Text>
          </RowItem>
        </CategoryTitleContainer>
        {children &&
          children.length &&
          children.map(({ name, value }) => (
            <RowContainer key={name}>
              <RowItem width={'104px'}>
                <Text color={ENUM_COLORS.DARK} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_12}>
                  {name}
                </Text>
              </RowItem>
              <RowItem width={'96px'}>
                <Text color={ENUM_COLORS.DARK} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_12}>
                  {value}
                </Text>
              </RowItem>
            </RowContainer>
          ))}
      </div>
    ))}
  </Container>
);

export default AnalyticsList;
