import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { DATE_PICKER_DOT_COLOR } from '../constants';
import DatePickerDay from '../DatePickerDay';

import { DotInformationContainer, ModalContainer, DayContainer, Text } from './styledComponents';

/**
 * A component used by our DatePickers when an information modal is needed.
 * Should not be directly used. (see renderDatePickerModalInfo)
 */
const DatePickerModalInformation = (props) => {
  const { dotsInformations, globalInfo, timezone } = props;

  const day = !!timezone ? moment.tz(timezone) : moment();

  // TO BE IMPLEMENTED : add tooltip if renderTooltip is defined

  return (
    <ModalContainer>
      {!isEmpty(dotsInformations) &&
        dotsInformations.map((dotValue, index) => (
          <DotInformationContainer key={`dot-${index}`}>
            <DayContainer color={dotValue.dotColor}>
              <DatePickerDay color={dotValue.dotColor} day={day} hasDot={true} />
            </DayContainer>
            <Text>{dotValue.dotInfo}</Text>
          </DotInformationContainer>
        ))}
      {globalInfo && <Text>{globalInfo}</Text>}
    </ModalContainer>
  );
};

DatePickerModalInformation.propTypes = {
  dotsInformations: PropTypes.arrayOf(
    PropTypes.shape({
      dotColor: PropTypes.oneOf(Object.values(DATE_PICKER_DOT_COLOR)),
      dotInfo: PropTypes.string,
    }),
  ),
  globalInfo: PropTypes.string, // A string containing a global information on datePicker
  timezone: PropTypes.string,
  //renderTooltip: PropTypes.func, // TO BE IMPLEMENTED
};

/**
 * The method used if a DatePicker needs to have an information modal.
 * The modal will be displayed above the picker.
 *
 * Usage example in: PastDayDatePicker (renderCalendarInfo prop).
 *
 * @param {object} calendarInfo - The information text to show in the modal
 * @returns A DatePickerModalInformation if calendarInfo is defined, or null.
 */
export const renderDatePickerModalInfo = (calendarInfo, timezone) =>
  calendarInfo ? (
    <DatePickerModalInformation
      dotsInformations={calendarInfo.dotsInformations}
      globalInfo={calendarInfo.globalInfo}
      timezone={!!timezone ? timezone : ''}
    />
  ) : null;
