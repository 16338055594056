import React, { createContext, useEffect, useState } from 'react';

import { getListViewState } from './utils';

import { CHOICES_DROPDOWN_ACTIVE } from '../routes/admin/utils/DropdownItems';

export const CentralKitchenRecipeContext = createContext({});

export const CentralKitchenRecipeProvider = ({ children }) => {
  const listViewStates = getListViewState();

  // Applied filters
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(CHOICES_DROPDOWN_ACTIVE[0]);

  // Advanced filters
  const [advancedFilters, setAdvancedFilters] = useState([]);

  useEffect(() => {
    listViewStates.setCurrentPage(1);
  }, [selectedStatus, advancedFilters]);

  return (
    <CentralKitchenRecipeContext.Provider
      value={{
        ...listViewStates,
        selectedStatus,
        setSelectedStatus,
        selectedBrands,
        setSelectedBrands,
        advancedFilters,
        setAdvancedFilters,
      }}
    >
      {children}
    </CentralKitchenRecipeContext.Provider>
  );
};

export default CentralKitchenRecipeContext;
