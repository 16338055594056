import i18next from 'i18next';
import React from 'react';

import WhiteCardForm from '@commons/WhiteCardForm';

import { CATALOG_INFOS_INPUTS } from '../../../utils/formInputsConfiguration';

import { Container, FormContainer } from './styledComponents';

const CatalogInformations = (props) => {
  const { catalogForm, isSaveAlreadyTriggered, inputs = CATALOG_INFOS_INPUTS({}) } = props;

  return (
    <Container>
      <FormContainer>
        <WhiteCardForm
          form={catalogForm}
          inputs={inputs}
          shouldDisplayError={isSaveAlreadyTriggered}
          title={i18next.t('ADMIN.SUPPLIER_PRODUCTS.FORM_GENERAL_INFOS')}
        />
      </FormContainer>
    </Container>
  );
};

export default CatalogInformations;
