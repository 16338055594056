import i18next from 'i18next';
import moment from 'moment-timezone';

import { getUserTimezone } from '../utils/date';

export const getFormattedLastUpdatedText = (updatedAt) => {
  if (!updatedAt) {
    return i18next.t('STOCKS.STOCKS.FORM_LAST_CHANGE_WITHOUT_DAY');
  }

  const userTimezone = getUserTimezone();

  const inventoryUpdateDate = moment.tz(updatedAt, userTimezone);

  if (moment.tz(userTimezone).isSame(updatedAt, 'day')) {
    return i18next.t('STOCKS.STOCKS.FORM_LAST_CHANGE_SAME_DAY', {
      hours: inventoryUpdateDate.format('HH'),
      separator: i18next.t('GENERAL.DATE_TIME_FORMAT_HOUR_SEPARATOR'),
      minutes: inventoryUpdateDate.format('mm'),
    });
  }

  return i18next.t('STOCKS.STOCKS.FORM_LAST_CHANGE_OTHER_DAY', {
    date: inventoryUpdateDate.format('DD/MM/YYYY'),
    hours: inventoryUpdateDate.format('HH'),
    separator: i18next.t('GENERAL.DATE_TIME_FORMAT_HOUR_SEPARATOR'),
    minutes: inventoryUpdateDate.format('mm'),
  });
};
