export const openModal = (params, fullscreen) => ({
  type: 'MODAL_OPEN',
  deactivateBackgroundClick: params.hasOwnProperty('deactivateBackgroundClick')
    ? params.deactivateBackgroundClick
    : true,
  params,
  fullscreen,
});

export const closeModal = () => ({
  type: 'MODAL_CLOSE',
});

export const openMediumModal = (params) => ({
  type: 'MEDIUM_MODAL_OPEN',
  deactivateBackgroundClick: params.hasOwnProperty('deactivateBackgroundClick')
    ? params.deactivateBackgroundClick
    : true,
  params,
});

export const openSmallModal = (params) => ({
  type: 'MEDIUM_MODAL_OPEN',
  sizeModal: 'small',
  deactivateBackgroundClick: params.hasOwnProperty('deactivateBackgroundClick')
    ? params.deactivateBackgroundClick
    : true,
  params,
});

export const openSupplierIngredientAlreadyAssociatedWarningModal = (params) => ({
  type: 'SUPPLIER_INGREDIENT_ALREADY_ASSOCIATED_WARNING_MODAL_OPEN',
  deactivateBackgroundClick: params.hasOwnProperty('deactivateBackgroundClick')
    ? params.deactivateBackgroundClick
    : true,
  params,
});

export const openGenericModal = (params) => ({
  type: 'GENERIC_MODAL_OPEN',
  deactivateBackgroundClick: params.hasOwnProperty('deactivateBackgroundClick')
    ? params.deactivateBackgroundClick
    : true,
  params,
});

export const refreshGenericModal = (params, customPropertyPathsToUpdate = []) => ({
  type: 'GENERIC_MODAL_REFRESH',
  params,
  customPropertyPathsToUpdate,
});

export const openFitContentModal = (params) => ({
  type: 'MEDIUM_MODAL_OPEN',
  sizeModal: 'fit-content',
  deactivateBackgroundClick: params.hasOwnProperty('deactivateBackgroundClick')
    ? params.deactivateBackgroundClick
    : true,
  params,
});

export const closeMediumModal = () => ({
  type: 'MEDIUM_MODAL_CLOSE',
});

export const closeSupplierIngredientAlreadyAssociatedWarningModal = () => ({
  type: 'SUPPLIER_INGREDIENT_ALREADY_ASSOCIATED_WARNING_MODAL_CLOSE',
});

export const closeGenericModal = () => ({
  type: 'GENERIC_MODAL_CLOSE',
});

export const openSlidingModal = (params) => ({
  type: 'SLIDE_MODAL_OPEN',
  params,
});

export const closeSlidingModal = () => ({
  type: 'SLIDE_MODAL_CLOSE',
});
