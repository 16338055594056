import { get } from 'lodash';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import {
  GENERIC_MODAL_BACK_BUTTON,
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_NEXT_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

import { STOCK_FORM_TYPES } from '@stocks/StocksInventories/components/StockForm/constants/types';
import ModalStock from '@stocks/StocksInventories/components/StockForm/components/Content/components/StockFormRow/components/modalStock';

import { AddSupplierProductModal, RemoveSupplierProduct } from '../AddSupplierProductModal';

export const SUPPLIER_PRODUCT_STEPS = {
  SUPPLIER_PRODUCT_SELECTION: 'supplierProductSelection',
  STOCK_SELECTION: 'stockSelection',
  SUPPLIER_PRODUCT_DELETION: 'supplierProductDeletion',
};

const CLOSE_MODAL_CONFIG = {
  title: i18next.t('GENERAL.CANCEL'),
  content: i18next.t('STOCKS.TRANSFERS.CANCEL_ADD_SUPPLIER_PRODUCT'),
};

const standardConfig = {
  type: 'action',
  width: '542px',
  height: 'auto',
  icon: '/images/inpulse/add-black-small.svg',
};

const deletionConfig = {
  type: 'warning',
  width: '542px',
  height: 'auto',
  icon: '/images/inpulse/trash-white-small.svg',
};

export function getModalConfig(props) {
  const {
    resetModalData,
    currentStep,
    setCurrentStep,
    supplierProductToAdd,
    setSupplierProductToAdd,
    stocksOfSupplierProductToAdd,
    setStocksOfSupplierProductToAdd,
    selectedSupplierProducts,
    setSelectedSupplierProducts,
    handleSupplierProductDeletion,
    supplierProductToDelete,
    handleStocksOfSupplierProductToAdd,
    disableNextStep,
    setDisableNextStep,
    suppliers,
    sentAt,
  } = props;

  if (currentStep === SUPPLIER_PRODUCT_STEPS.SUPPLIER_PRODUCT_DELETION) {
    return {
      ...deletionConfig,
      title: i18next.t('STOCKS.TRANSFERS.REMOVE_SUPPLIER_PRODUCT'),
      component: RemoveSupplierProduct,
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          key: 1,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.REMOVE'),
          icon: '/images/inpulse/trash-white-small.svg',
          handleClick: () => {
            handleSupplierProductDeletion(supplierProductToDelete);
          },
        },
      ],
    };
  }

  if (currentStep === SUPPLIER_PRODUCT_STEPS.SUPPLIER_PRODUCT_SELECTION) {
    return {
      ...standardConfig,
      title: i18next.t('GENERAL.ACTION_ADD_ITEM', {
        item: i18next.t('GENERAL.SUPPLIER_PRODUCT_LOWERCASE'),
      }),
      component: AddSupplierProductModal,
      data: {
        supplierProductToAdd,
        setSupplierProductToAdd,
        setStocksOfSupplierProductToAdd,
        suppliers,
        selectedSupplierProducts,
        sentAt,
      },
      handleCloseCleanUp: resetModalData,
      closeConfig: CLOSE_MODAL_CONFIG,
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_NEXT_BUTTON(),
          handleClick: () => {
            setCurrentStep(SUPPLIER_PRODUCT_STEPS.STOCK_SELECTION);
            handleStocksOfSupplierProductToAdd(get(supplierProductToAdd, 'totByPackagingId', []));
          },
          preventClosing: true,
          isDisabled: !supplierProductToAdd,
        },
      ],
    };
  }

  const { price } = supplierProductToAdd;

  const packagingsUsedInStocks = get(supplierProductToAdd, 'packagings', []);

  if (currentStep === SUPPLIER_PRODUCT_STEPS.STOCK_SELECTION) {
    return {
      ...standardConfig,
      title: i18next.t('STOCKS.TRANSFERS.ADD_QUANTITIES_TO_TRANSFER'),
      component: ModalStock,
      data: {
        price,
        onChange: handleStocksOfSupplierProductToAdd,
        packagings: packagingsUsedInStocks,
        totByPackagingId: stocksOfSupplierProductToAdd,
        stockType: STOCK_FORM_TYPES.TRANSFER,
      },
      handleCloseCleanUp: resetModalData,
      closeConfig: CLOSE_MODAL_CONFIG,
      actions: [
        {
          ...GENERIC_MODAL_BACK_BUTTON(),
          handleClick: () => {
            setCurrentStep(SUPPLIER_PRODUCT_STEPS.SUPPLIER_PRODUCT_SELECTION);
            setDisableNextStep(true);
          },
        },
        {
          handleClick: () => {
            setSelectedSupplierProducts([
              ...selectedSupplierProducts,
              {
                ...supplierProductToAdd,
                totByPackagingId: stocksOfSupplierProductToAdd,
              },
            ]);
            resetModalData();
          },
          label: i18next.t('GENERAL.ADD'),
          icon: '/images/inpulse/save-white-small.svg',
          isDisabled: disableNextStep,
        },
      ],
    };
  }
}

getModalConfig.propTypes = {
  resetModalData: PropTypes.func,
  currentStep: PropTypes.oneOf(Object.values(SUPPLIER_PRODUCT_STEPS)),
  setCurrentStep: PropTypes.func,
  supplierProductToAdd: PropTypes.arrayOf(PropTypes.object),
  setSupplierProductToAdd: PropTypes.func,
  stocksOfSupplierProductToAdd: PropTypes.arrayOf(PropTypes.object),
  setStocksOfSupplierProductToAdd: PropTypes.func,
  handleSupplierProductDeletion: PropTypes.func,
  supplierProductToDelete: PropTypes.object,
};
