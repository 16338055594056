import { connect } from 'react-redux';
import { useCryptr } from '@cryptr/cryptr-react';
import i18next from 'i18next';
import React from 'react';

import { Container, Icon } from './styledComponents';

import Text, { ENUM_COLORS, ENUM_FONTS } from '../Text';

import { Button } from '../utils/styledLibraryComponents';
import { getClientInfo } from '../../selectors/client';
import { getClientStoreNameTranslation } from '../utils/translations';
import { removeLocalStorageItem } from '../utils/localStorage';

export const NoStoreDetector = (props) => {
  const {
    user,
    associatedStores,
    associatedActiveStores,
    client: { storeName },
    children,
  } = props;

  // Retrieve info connected user with cryptr SDK
  const cryptrHook = useCryptr();

  if (user.isDeepsight || associatedActiveStores.length) {
    return <>{children}</>;
  }

  const logoutUser = async () => {
    const isAuthenticated = cryptrHook.isAuthenticated();

    if (!isAuthenticated) {
      return props.handleLogout();
    }

    // Make sure user is logged out that when sso logout flow redirects to app
    removeLocalStorageItem('loopbackAccessToken');
    removeLocalStorageItem('userId');
    removeLocalStorageItem('isCentralMode');

    // Redirect to SSO logout flow
    cryptrHook.logOut(() => {
      props.handleLogout();
    });
  };

  const storeNameToLowerCase = getClientStoreNameTranslation(storeName).toLowerCase();

  const contentText =
    associatedStores.length > 0 && associatedActiveStores.length !== associatedStores.length
      ? i18next.t('GENERAL.NO_ASSOCIATED_ACTIVE_STORE_DETECTED_TITLE', {
          storeName: storeNameToLowerCase,
        })
      : i18next.t('GENERAL.NO_ASSOCIATED_STORE_DETECTED_TITLE', {
          storeName: storeNameToLowerCase,
        });

  return (
    <Container>
      <Icon alt="closed-store-icon" src={'/images/inpulse/closed-store.svg'} />
      <Text font={ENUM_FONTS.H2} style={{ marginBottom: '8px' }}>
        {contentText}
      </Text>
      <Text
        color={ENUM_COLORS.DARKER}
        font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}
        style={{ marginBottom: '16px', textAlign: 'center' }}
      >
        {i18next.t('GENERAL.NO_ASSOCIATED_STORE_DETECTED_CONTENT', {
          storeName: getClientStoreNameTranslation(storeName, true).toLowerCase(),
        })}
      </Text>
      <Button
        color={'inpulse-outline'}
        handleClick={logoutUser}
        icon={'/images/inpulse/logout-black-small.svg'}
        label={i18next.t('GENERAL.NO_ASSOCIATED_STORE_DETECTED_ACTION')}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
});

export default connect(mapStateToProps, null)(NoStoreDetector);
