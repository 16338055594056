import { connect } from 'react-redux';
import { get } from 'lodash';
import React from 'react';

import { getSalesPointStores } from '../../../selectors/stores';

import NavigationBreadCrumb from '../../../commons/Breadcrumb/NavigationBreadCrumb';

import Orders from '../OrderList';

const CentralKitchenOrderPreparation = (props) => {
  const path = get(props.match, 'path');

  return (
    <>
      <NavigationBreadCrumb featurePath={path} />

      <Orders
        canOrderWithMin={props.canOrderWithMin}
        categoriesOrder={props.categoriesOrder}
        constraints={props.constraints}
        displayStocks={props.displayStocks}
        groupedOrder={props.groupedOrder}
        send={props.send}
        sendOrder={props.sendOrder}
        stores={props.stores}
        isForCentralKitchenPreparation
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  stores: getSalesPointStores(state.baseReducer.activeStores),
});

export default connect(mapStateToProps)(CentralKitchenOrderPreparation);
