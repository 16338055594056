import { get } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { ListView } from '@commons/utils/styledLibraryComponents';

import { MapStoresModalContainer } from './styledComponents';

const getColumns = () => [
  {
    id: 'name',
    name: i18next.t('GENERAL.NAME'),
    type: 'string',
    propertyKey: 'name',
    highlighted: true,
  },
  {
    id: 'lnkBrandStorerel',
    name: i18next.t('GENERAL.BRAND'),
    type: 'string',
    propertyKey: 'lnkBrandStorerel',
    render: (brand) => <div>{get(brand, 'name', '-')}</div>,
  },
  {
    id: 'supplierProfileName',
    name: 'Profil Fournisseur',
    type: 'string',
    propertyKey: 'supplierProfileName',
    displayBigger: true,
  },
  {
    id: 'price',
    name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_PRICE'),
    type: 'currency',
    propertyKey: 'price',
    render: () => <div>-</div>,
  },
  {
    id: 'country',
    name: i18next.t('GENERAL.COUNTRY'),
    type: 'string',
    propertyKey: 'country',
  },
  {
    id: 'city',
    name: i18next.t('GENERAL.CITY'),
    type: 'string',
    propertyKey: 'city',
  },
];

const MapStoreModal = (props) => {
  const { activeUserStores, userLanguageCode, handleStoresSelection, hasMultipleBrands } = props;

  const columns = getColumns();

  return (
    <MapStoresModalContainer>
      <ListView
        columns={
          hasMultipleBrands
            ? columns
            : columns.filter((column) => column.propertyKey !== 'lnkBrandStorerel')
        }
        data={activeUserStores}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        disableFooter={true}
        forceEnableSelection={true}
        isLoading={false}
        languageCode={userLanguageCode}
        setSelectedItems={(items) => handleStoresSelection(items)}
      />
    </MapStoresModalContainer>
  );
};

export default MapStoreModal;
