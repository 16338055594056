import i18next from 'i18next';
import moment from 'moment';

import { getTheme } from '@commons/utils/theme';

const theme = getTheme();

export const GRAPH_COLORS = {
  POSITIVE: theme.colors.infoGreen,
  NEGATIVE: theme.colors.infoRed,
  REAL: theme.colors.greys.light,
  REAL_RECIPE: theme.colors.greys.dark,
  THEORICAL: theme.colors.brand.secondary,
};

export const GRAPH_TYPE = {
  CURRENT: 'CURRENT',
  PAST_CC: 'PAST_CC',
  PAST: 'PAST',
};

const GRAPH_LEGENDS = {
  CURRENT: [
    {
      color: GRAPH_COLORS.POSITIVE,
      translationKey: 'STOCKS.CURRENT_STOCKS.FUTURE_STOCKS_GRAPH_POSITIVE_STOCKS_WITH_UNIT',
      propertyKey: 'theoricalStockTurnover',
    },
    {
      color: GRAPH_COLORS.NEGATIVE,
      translationKey: 'STOCKS.CURRENT_STOCKS.FUTURE_STOCKS_GRAPH_STOCK_SHORTAGE',
      propertyKey: 'realStockTurnover',
    },
  ],
  PAST: [
    {
      color: GRAPH_COLORS.THEORICAL,
      translationKey: 'STOCKS.STOCKS.THEORETICAL_STOCK_WITH_UNIT',
      propertyKey: 'realStockTurnover',
    },
    {
      color: GRAPH_COLORS.REAL_RECIPE,
      translationKey: 'STOCKS.STOCKS.REAL_STOCK_VIA_RECIPES',
      propertyKey: 'realStockRecipeTurnover',
    },
    {
      color: GRAPH_COLORS.REAL,
      translationKey: 'STOCKS.STOCKS.REAL_STOCK_VIA_INGREDIENTS',
      propertyKey: 'realStockIngredientTurnover',
    },
  ],
  PAST_CC: [
    {
      color: GRAPH_COLORS.THEORICAL,
      translationKey: 'STOCKS.STOCKS.THEORETICAL_STOCK_WITH_UNIT',
      propertyKey: 'realStockTurnover',
    },
    {
      color: GRAPH_COLORS.REAL_RECIPE,
      translationKey: 'STOCKS.STOCKS.REAL_STOCK_VIA_RECIPES',
      propertyKey: 'realStockRecipeTurnover',
    },
    {
      color: GRAPH_COLORS.REAL,
      translationKey: 'STOCKS.STOCKS.REAL_STOCK_WITH_UNIT',
      propertyKey: 'theoricalStockTurnover',
    },
  ],
};

const getGraphLegends = (type, unit, { shouldDisplayRecipeLegend }) => {
  const legends = GRAPH_LEGENDS[type].map((legend) => ({
    ...legend,
    name: i18next.t(legend.translationKey, {
      unit,
    }),
  }));

  if (shouldDisplayRecipeLegend) {
    return legends;
  }

  return legends.filter(({ propertyKey }) => propertyKey !== 'realStockRecipeTurnover');
};

export const getGraphBasicConfiguration = (unit, type, title, { shouldDisplayRecipeLegend }) => ({
  data: [],
  configuration: {
    title,
    metric: 'unit',
    legends: getGraphLegends(type, unit, { shouldDisplayRecipeLegend }),
    tooltiptext: [GRAPH_TYPE.PAST, GRAPH_TYPE.PAST_CC].includes(type)
      ? i18next.t('STOCKS.PAST_STOCKS.TOOLTIP')
      : i18next.t('STOCKS.CURRENT_STOCKS.GRAPH_TOOLTIP'),
  },
  unit,
  isLoading: false,
  currentDate: moment(),
  hasNoInventoryStockOfEntity: false,
  singleBarGraph: type === GRAPH_TYPE.CURRENT ? true : false,
  hideUnit: false,
});
