import { BarChart, Bar, ReferenceLine, XAxis, YAxis, Tooltip } from 'recharts';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import styled from 'styled-components';

import { formatNumber } from '@commons/DisplayNumber';

import { StyledTooltip } from '@lib/inpulse/BarChartTooltip';

import theme from '@theme';

const DeepsightToolTip = ({
  payload,
  label,
  eventsObject,
  weatherObject,
  labelDisplayName,
  dataKeys,
  hasUserAccessToProductionFeature,
  wasteMetricPropertyKey,
  productionMetricsByDates,
  currency,
  storeTimezone,
  ...props
}) => {
  const date = moment.tz(label, storeTimezone).format('YYYY-MM-DD');
  const productionMetrics = productionMetricsByDates[date];
  if (!payload) {
    return '';
  }

  return (
    <div className="barchart-tooltip-container">
      <div className="barchart-tooltip-header">
        {moment.tz(label, storeTimezone).format('dddd DD MMMM YYYY')}
      </div>
      <div className="barchart-tooltip-body">
        <div className="barchart-tooltip-left">
          {payload.length &&
            payload.map((pl) => {
              let displayName;
              let res =
                dataKeys && dataKeys.length ? dataKeys.filter((dk) => dk.name === pl.dataKey) : [];
              if (res.length) {
                displayName = res[0].displayName;
              }
              return (
                <div className="tooltip-past-error-item" key={pl.dataKey}>
                  <h3 style={{ color: pl.color }}>{formatNumber(Math.round(pl.value), 0, true)}</h3>
                  <h4 style={{ color: pl.color }}>{displayName || labelDisplayName}</h4>
                </div>
              );
            })}
          {
            payload.length && payload.length > 1 && payload[0].payload.tot && (
              <div className="tooltip-past-error-item">
                <h3 style={{ color: '#BDC1CC' }}>{Math.round(payload[0].payload.tot)}</h3>
                <h4 style={{ color: '#BDC1CC' }}>{labelDisplayName}</h4>
              </div>
            ) /*Has Breakdown*/
          }
          {
            payload.length && payload.length > 1 && payload[0].payload.fcTot && (
              <div className="tooltip-past-error-item">
                <h3 style={{ color: '#0A3EFF' }}>{Math.round(payload[0].payload.fcTot)}</h3>
                <h4 style={{ color: '#0A3EFF' }}>
                  {i18next.t('FORECAST.TURNOVER.CHART_LABEL_TOTAL')}
                </h4>
              </div>
            ) /*Has Breakdown*/
          }

          {!!hasUserAccessToProductionFeature && (
            <div>
              <div className="tooltip-past-error-item">
                <h3 style={{ color: '#EF252A' }}>
                  {productionMetrics && productionMetrics.hasOwnProperty(wasteMetricPropertyKey)
                    ? `${productionMetrics[wasteMetricPropertyKey]}%`
                    : '-'}
                </h3>
                <h4 style={{ color: '#EF252A' }}>
                  {i18next.t('FORECAST.TURNOVER.CHART_LABEL_LOSS')}
                </h4>
              </div>
              <div className="tooltip-past-error-item">
                <h3>
                  {(productionMetrics && formatNumber(productionMetrics['producedTurnover'])) ||
                    '-'}
                </h3>
                <h4>
                  {i18next.t('FORECAST.TURNOVER.CHART_LABEL_PRODUCED', {
                    currencyCode: currency.alphabeticCode,
                  })}
                </h4>
              </div>
              <div className="tooltip-past-error-item">
                <h3>
                  {(productionMetrics && formatNumber(productionMetrics['toProduceTurnover'])) ||
                    '-'}
                </h3>
                <h4>
                  {i18next.t('FORECAST.TURNOVER.CHART_LABEL_TO_PRODUCE', {
                    currencyCode: currency.alphabeticCode,
                  })}
                </h4>
              </div>
            </div>
          )}
        </div>
        <div className="barchart-tooltip-right">
          <div className="barchart-tooltip-weather-container">
            <div className="barchart-tooltip-weather-temperature">
              {i18next.t('FORECAST.TURNOVER.WEATHER_TOOLTIP_TEMPERATURE_DEGREE', {
                temperature: weatherObject[label] && weatherObject[label].temperature,
              })}
            </div>
            <img
              className="barchart-tooltip-weather-icon"
              src={
                weatherObject[label] && weatherObject[label].img
                  ? weatherObject[label].img
                  : '/images/icon-empty-weather.png'
              }
            />
          </div>
          <div className="barchart-tooltip-events-container">
            <h2>{i18next.t('FORECAST.SALES.CHART_TITLE_EVENTS').toUpperCase()}</h2>
            {eventsObject[label] && eventsObject[label].length === 0 ? (
              <div className="barchart-tooltip-event-empty-state">
                {i18next.t('FORECAST.TURNOVER.CHART_NO_EVENT')}
              </div>
            ) : (
              <div />
            )}
            {eventsObject[label] &&
              eventsObject[label].map((event) => (
                <div className="barchart-tooltip-event-item" key={event.id}>
                  <img className="barchart-tooltip-event-icon" src={event.eventSubCategory.img} />
                  <div className="barchart-tooltip-event-name">{event.input1}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export class DeepsightFiveSameDaysChart extends Component {
  XFormatter = (n) => {
    if (this.props.isPast) {
      return moment.tz(n, this.props.storeTimezone).format('DD/MM/YY');
    }
    return moment.tz(n, this.props.storeTimezone).format('DD/MM');
  };

  render() {
    return (
      <StyledTooltip>
        <BarChart
          className={this.props.className}
          data={this.props.data}
          height={this.props.height}
          margin={{ top: 15, right: 5, bottom: 5, left: -20 }}
          width={360}
        >
          <defs>
            <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
              <stop offset="0%" stopColor={theme.colors.brand.secondary} />
              <stop offset="100%" stopColor={theme.colors.brand.secondary} />
            </linearGradient>
          </defs>
          {this.props.dataKeys.map((item, index) => (
            <Bar
              barSize={10}
              dataKey={item.name}
              fill={item.color}
              key={item.id}
              radius={this.props.roundedBarsIds.includes(index) ? [5, 5, 0, 0] : [0, 0, 0, 0]}
              stackId={this.props.stackId}
            />
          ))}
          <ReferenceLine
            label={{
              value: this.props.t('FORECAST.TURNOVER.CHART_LABEL_AVERAGE') + ' ' + this.props.mean,
              fill: theme.colors.greys.darkest,
              position: 'top',
            }}
            stroke={theme.colors.greys.light}
            y={this.props.mean}
          />
          <XAxis
            dataKey="timestamp"
            dy={5}
            tick={{
              fill: theme.colors.greys.dark,
              fontSize: 12,
            }}
            tickFormatter={this.XFormatter}
            tickLine={false}
          />
          <YAxis
            axisLine={false}
            tick={{
              fill: theme.colors.greys.dark,
              fontSize: 12,
            }}
            tickFormatter={(value) => formatNumber(value, 0, true)}
            tickLine={false}
          />
          <Tooltip
            content={DeepsightToolTip}
            currency={this.props.currency}
            cursor={false}
            dataKeys={this.props.dataKeys}
            eventsObject={this.props.eventsObject}
            hasUserAccessToProductionFeature={this.props.hasUserAccessToProductionFeature}
            labelDisplayName={this.props.displayName}
            position={{ y: -25 }}
            productionMetricsByDates={this.props.productionMetricsByDates}
            storeTimezone={this.props.storeTimezone}
            wasteMetricPropertyKey={this.props.wasteMetricPropertyKey}
            weatherObject={this.props.weatherObject}
          />
        </BarChart>
      </StyledTooltip>
    );
  }
}
const Styled = styled(DeepsightFiveSameDaysChart)`
  ${(props) =>
    props.theme.fonts ? `font-family: ${props.theme.fonts?.families.secondary};` : undefined}
`;

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

export default withTranslation()(connect(mapStateToProps)(Styled));
