import styled, { css } from 'styled-components';

export const Text = styled.div`
  font: ${(props) => props.theme.fonts?.textBig};
  color: ${(props) => props.theme.colors?.greys?.darkest};

  ${(props) => {
    if (props.highlighted) {
      return css`
        font: ${(props) => props.theme.fonts?.textMiddleBold};
        color: ${(props) => props.theme.colors?.greys?.darkest};
      `;
    }
  }}
`;

export const LabelContainer = styled.div`
  min-width: '115px';
  margin-left: '2px';
  padding: '5px 0px';
`;
