import i18next from 'i18next';

import InputContentModal from '@commons/Modals/InputContentModal';

import ConfirmationModal from '@backoffice/components/ConfirmationModal';

export const getCreateRoleModalConfig = ({
  name,
  onInputChange,
  forbiddenNames,
  handleCreateRole,
}) => {
  const errorMessage = forbiddenNames.includes(name.toLowerCase())
    ? i18next.t('BACKOFFICE.ROLES.CREATE_MODAL_DUPLICATE_NAME')
    : '';

  return {
    type: 'action',
    width: '542px',
    height: 'auto',
    icon: '/images/inpulse/add-black-small.svg',
    title: i18next.t('BACKOFFICE.ROLES.CREATE_MODAL_TITLE'),
    component: InputContentModal,
    data: {
      value: name,
      errorMessage,
      label: i18next.t('GENERAL.NAME'),
      placeholder: i18next.t('GENERAL.NAME'),
      onChange: (value) => onInputChange(value),
    },
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.SAVE'),
        icon: '/images/inpulse/save-white-small.svg',
        handleClick: () => handleCreateRole(name),
        isDisabled: !name || name === '' || !!errorMessage,
      },
    ],
  };
};

export const getDeleteRoleModalConfig = ({ role, handleDeleteRoleById }) => ({
  type: 'warning',
  width: '542px',
  height: 'auto',
  icon: '/images/inpulse/trash-white-small.svg',
  title: i18next.t('BACKOFFICE.ROLES.DELETE_MODAL_TITLE'),
  component: ConfirmationModal,
  data: {
    content: i18next.t('BACKOFFICE.ROLES.DELETE_MODAL_CONTENT'),
  },
  actions: [
    {
      key: 0,
      color: 'inpulse-outline',
      label: i18next.t('GENERAL.CANCEL'),
      icon: '/images/inpulse/close-black-small.svg',
    },
    {
      key: 1,
      color: 'inpulse-default',
      label: i18next.t('GENERAL.DELETE'),
      icon: '/images/inpulse/trash-white-small.svg',
      handleClick: () => handleDeleteRoleById(role.id),
    },
  ],
});

export const getRoleEditModalParams = ({
  role,
  roleName,
  errorMessage,
  handleEditeRole,
  onRoleNameEditionChange,
}) => {
  const isInputEqualToCurrentRoleName = roleName.toLowerCase() === role.name.toLowerCase();

  return {
    type: 'action',
    width: '560px',
    height: 'auto',
    icon: '/images/inpulse/edit-pen-black-small.svg',
    title: i18next.t('BACKOFFICE.ROLES.EDIT'),
    component: InputContentModal,
    data: {
      value: roleName,
      onChange: (value) => onRoleNameEditionChange(value, role),
      errorMessage: !isInputEqualToCurrentRoleName ? errorMessage : '',
      label: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_NAME'),
      placeholder: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_NAME'),
    },
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.SAVE'),
        icon: '/images/inpulse/save-white-small.svg',
        isDisabled: !!errorMessage || isInputEqualToCurrentRoleName,
        handleClick: () => handleEditeRole(roleName, role),
      },
    ],
  };
};

export default {
  getRoleEditModalParams,
  getCreateRoleModalConfig,
  getDeleteRoleModalConfig,
};
