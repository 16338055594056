import { capitalize } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useCryptr } from '@cryptr/cryptr-react';
import React from 'react';

import { Container, Icon, NameContainer, UserContainer } from './styledComponents';

import { logout } from '../../../actions/logout';

import Text, { ENUM_COLORS, ENUM_FONTS } from '../../Text';

import { removeLocalStorageItem } from '../../utils/localStorage';

const UserSection = ({ user, triggerDrawer, handleLogout }) => {
  // Retrieve info connected user with cryptr SDK
  const cryptrHook = useCryptr();

  const userDetailPath = `/users/${user.id}/details`;

  const logoutUser = async () => {
    const isAuthenticated = cryptrHook.isAuthenticated();

    if (!isAuthenticated) {
      return handleLogout();
    }

    // Make sure user is logged out that when sso logout flow redirects to app
    removeLocalStorageItem('loopbackAccessToken');
    removeLocalStorageItem('userId');
    removeLocalStorageItem('isCentralMode');

    // Redirect to SSO logout flow
    cryptrHook.logOut(() => {
      handleLogout();
    });
  };

  return (
    <Container>
      <UserContainer>
        <NameContainer>
          <Link to={userDetailPath} onClick={() => triggerDrawer({ closeDrawer: true })}>
            <Text color={ENUM_COLORS.LIGHTEST}>
              {capitalize(user.firstName)} {capitalize(user.lastName)}
            </Text>
          </Link>
          <Link to={userDetailPath} onClick={() => triggerDrawer({ closeDrawer: true })}>
            <Text color={ENUM_COLORS.DGREY} font={ENUM_FONTS.TEXT_COMPONENT_LABEL}>
              {user.lnkClientAccountrel.name}
            </Text>
          </Link>
        </NameContainer>
      </UserContainer>

      <Icon src={'/images/inpulse/logout.svg'} onClick={logoutUser} />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  handleLogout: () => {
    dispatch(logout());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSection);
