import i18next from 'i18next';
import React from 'react';

import { InpulseLabel } from '@commons/DeepsightComponents';

import { Text } from '../components/styledComponents';

const renderLabel = (item) => (
  <div
    className="supplier-label-render"
    style={{ minWidth: '115px', marginLeft: '2px', padding: '5px 0px' }}
  >
    <InpulseLabel color={item > 0 ? 'green' : 'red'} text={String(item)} />
  </div>
);

export const getColumns = () => [
  {
    id: 'ingredientName',
    name: i18next.t('GENERAL.NAME'),
    displayName: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
    baseName: 'name',
    large: true,
    render: (item) => <Text highlighted>{item}</Text>,
  },
  {
    id: 'created_at',
    baseName: 'category',
    propertyKey: 'category',
    displayName: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CATEGORY'),
    name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CATEGORY'),
    filterType: 'string',
    filterStringAllowNone: true,
    render: (item) => (item ? <Text>{item}</Text> : <Text>-</Text>),
  },
  {
    id: 'unit',
    baseName: 'unit',
    propertyKey: 'unit',
    displayName: i18next.t('GENERAL.TOGGLE_BUTTON_UNIT', { count: 1 }),
    name: i18next.t('GENERAL.TOGGLE_BUTTON_UNIT', { count: 1 }),
    excludeFromSearch: true,
    filterType: 'string',
    render: (item) => <Text>{item}</Text>,
  },
  {
    id: 'cost',
    baseName: 'cost',
    propertyKey: 'cost',
    filterType: 'numeric',
    displayName: i18next.t('ADMIN.INGREDIENTS.LABEL_COST'),
    name: i18next.t('ADMIN.INGREDIENTS.LABEL_COST'),
    excludeFromSearch: true,
    render: (_, ingredient) => <Text>{ingredient.costStr}</Text>,
  },
  {
    id: 'supplierProductMappingCount',
    baseName: 'supplierProductMappingCount',
    propertyKey: 'supplierProductMappingCount',
    displayName: i18next.t('FEATURE.ADMIN.SUPPLIERS_PRODUCTS'),
    name: i18next.t('FEATURE.ADMIN.SUPPLIERS_PRODUCTS'),
    excludeFromSearch: true,
    filterType: 'numeric',
    render: (item) => renderLabel(item),
  },
  {
    id: 'parentCounts',
    baseName: 'parentCounts',
    propertyKey: 'parentCounts',
    displayName: i18next.t('ADMIN.INGREDIENTS.USING'),
    name: i18next.t('ADMIN.INGREDIENTS.USING'),
    excludeFromSearch: true,
    filterType: 'numeric',
    render: (item) => renderLabel(item),
  },
];

export default getColumns;
