import { Route, Redirect } from 'react-router-dom';
import React from 'react';

const Secure = ({ component: Component, isAuthenticated, handleAuthenticatedChange, ...rest }) => {
  if (!isAuthenticated) {
    return <Redirect from="/" to="/login" />;
  }
  return (
    <Route {...rest}>
      <Component {...rest} handleAuthenticatedChange={handleAuthenticatedChange} />
    </Route>
  );
};

export default Secure;
