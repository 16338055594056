import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

import { EMPTY_STATE_TYPES } from '../../utils/constants';

import {
  MainText,
  SubText,
  TextContainer,
  EmptyStateIcon,
  EmptyStateContainer,
  ButtonContainer,
} from './styledComponents';

export function renderEmptyState(emptyStateType, handleAddCentralKitchenInProductionPlanning) {
  if (emptyStateType === '') {
    return;
  }

  const recipeNotSold = emptyStateType === EMPTY_STATE_TYPES.RECIPE_NOT_SOLD;

  return (
    <EmptyStateContainer>
      <div>
        <EmptyStateIcon
          alt="production-planning"
          className="center"
          src="/images/inpulse/production-planning.svg"
        />
        <TextContainer>
          <MainText>
            {i18next.t(
              recipeNotSold
                ? 'ADMIN.RECIPES.PRODUCTION_EMPTY_STATE_RECIPE_NOT_SOLD_TITLE'
                : 'ADMIN.RECIPES.PRODUCTION_EMPTY_STATE_NO_CENTRAL_KITCHEN_TITLE',
            )}
          </MainText>
          <SubText>
            {i18next.t(
              recipeNotSold
                ? 'ADMIN.RECIPES.PRODUCTION_EMPTY_STATE_RECIPE_NOT_SOLD_SUBTITLE'
                : 'ADMIN.RECIPES.PRODUCTION_EMPTY_STATE_NO_CENTRAL_KITCHEN_SUBTITLE',
            )}
          </SubText>
          {emptyStateType === EMPTY_STATE_TYPES.NO_CENTRAL && (
            <ButtonContainer>
              <Button
                color={'inpulse-default'}
                handleClick={handleAddCentralKitchenInProductionPlanning}
                icon={'/images/inpulse/add-white-small.svg'}
                label={i18next.t('GENERAL.ADD')}
              />
            </ButtonContainer>
          )}
        </TextContainer>
      </div>
    </EmptyStateContainer>
  );
}
