import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React, { useRef, useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, openMediumModal } from '@actions/modal';
import { showConfirmationMessage } from '@actions/messageconfirmation';
import { updateIsSynchronizingCashierStoresStatus } from '@actions/store';

import { exportCashierStores } from './common/exportCashierStores';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { ListView } from '@commons/utils/styledLibraryComponents';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { ENUM_QUERY_PARAMS, useListViewQueryParams } from '@hooks/useListViewQueryParams';

import { getClientInfo } from '@selectors/client';

import { cashierStoreService } from '@services/cashierStore';
import clientService from '@services/client';

import theme from '@theme';

import { CHOICES_DROPDOWN_ACTIVE } from '@admin/utils/DropdownItems';

import DeepsightFiltersButton from '@orders/components/FilterButton';

import { Container, ListViewContainer } from './styledComponents';
import StoreAssociationModal from './components/StoreAssociationModal';
import utilsActions from './utils/actions';
import utilsColumns from './utils/columns';
import utilsModal from './utils/modals';

const TIMEOUT_GET_SYNC_STATUS = 5000; // in ms

const ENUM_SYNCHRO_CASHIER_PRODUCT = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  REVOKED: 'REVOKED',
};

const CHOICES_DROPDOWN_ASSOCIATED = [
  { id: 3, name: i18next.t('GENERAL.LINKED_PLURAL'), propertyKey: 'isAssociated', itemValue: true },
  { id: 4, name: i18next.t('GENERAL.UNLINKED'), propertyKey: 'isAssociated', itemValue: false },
];

export const BackOfficeCashierStoresManagement = (props) => {
  const {
    user,
    client: { clientId, storeName },
    match,
    isSynchronizingCashierStores,
    updateIsSynchronizingCashierStoresStatus,
  } = props;
  const path = get(match, 'path');

  const translatedStoresName = getClientStoreNameTranslation(storeName, true).toLowerCase();

  const translatedStoreName = getClientStoreNameTranslation(storeName, false).toLowerCase();

  const userLanguageCode = get(props.user, 'lnkLanguageAccountrel.code', 'fr');

  const listViewRef = useRef();

  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const columns = utilsColumns.getColumnsSettings(translatedStoresName);
  const [cashierStores, setCashierStores] = useState([]);
  const [displayedCashierStores, setDisplayedCashierStores] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);

  const [listViewQueryParams, setListViewQueryParams] = useListViewQueryParams();

  const [activeKeysForActiveDropdown, setActiveKeysForActiveDropdown] = useState([
    CHOICES_DROPDOWN_ACTIVE[0],
  ]);

  const [activeKeysForAssociatedDropdown, setActiveKeysForAssociatedDropdown] = useState(
    CHOICES_DROPDOWN_ASSOCIATED,
  );

  const [markerConfiguration] = useState({
    isHidden: (cashierStore) => !cashierStore.isSynchronized,
    backgroundColor: theme.colors.black,
    icon: {
      src: '/images/inpulse/sync-white-small.svg',
    },
  });

  const reloadCashierStores = async () => {
    setIsLoading(true);

    await handleGetCashierStores();

    setIsLoading(false);
  };
  /**********************************/
  /** Fetch list of cashier stores **/
  /**********************************/

  const handleGetCashierStores = async () => {
    try {
      const result = await cashierStoreService.getCashierStoreInformations(clientId);
      const formattedCashierStores = result.map((cashierStore) => ({
        ...cashierStore,
        isAssociated: !!cashierStore.associatedStores.length,
      }));

      setCashierStores(formattedCashierStores);
      setDisplayedCashierStores(processFilters(formattedCashierStores));
    } catch (error) {
      props.showMessage(
        i18next.t('BACKOFFICE.STORES.FETCHING_ERROR', {
          storeName: translatedStoresName.toLowerCase(),
        }),
        'error',
      );

      setCashierStores([]);
      setDisplayedCashierStores([]);
    }
  };

  /****************************/
  /** Delete a cashier store **/
  /****************************/

  const handleDeleteCashierStoresById = async (cashierStoreIds) => {
    props.pageLoading();

    const hasMultipleSelection = cashierStoreIds.length > 1;

    try {
      const promises = cashierStoreIds.map((id) => clientService.deleteCashierStoreById(id));

      await Promise.all(promises);

      props.showMessage(
        i18next.t(
          hasMultipleSelection
            ? 'BACKOFFICE.CASHIER_STORES.MULTIPLE_DELETE_SUCCESS'
            : 'BACKOFFICE.CASHIER_STORES.SINGLE_DELETE_SUCCESS',
          hasMultipleSelection
            ? { storeName: translatedStoresName }
            : { storeName: translatedStoreName },
        ),
      );

      await handleGetCashierStores();
    } catch (err) {
      props.showMessage(
        i18next.t(
          hasMultipleSelection
            ? 'BACKOFFICE.CASHIER_STORES.MULTIPLE_DELETE_FAILURE'
            : 'BACKOFFICE.CASHIER_STORES.SINGLE_DELETE_FAILURE',
          { storeName: translatedStoreName },
        ),
      );
    } finally {
      props.pageLoaded();
    }
  };

  /**********************************************/
  /** Go to the detail page of a cashier store **/
  /**********************************************/

  const goCashierStoreDetailsPage = (cashierStoreId) => {
    props.history.push({
      pathname: '/backoffice/stores/cashier-stores/' + cashierStoreId + '/details',
    });
  };

  /***************************/
  /** Export cashier stores **/
  /***************************/

  const handleExport = async (selectedCashierStores = null) => {
    if (!selectedCashierStores || !selectedCashierStores.length) {
      return exportCashierStores(displayedCashierStores, storeName);
    }

    exportCashierStores(selectedCashierStores, storeName);
  };

  /**************************************/
  /** Apply filters on list to display **/
  /**************************************/

  const processFilters = (items) => {
    const filteredCashierStores = items.reduce((result, cashierStore) => {
      const callback = ({ propertyKey, itemValue }) => cashierStore[propertyKey] === itemValue;

      if (
        activeKeysForActiveDropdown.some(
          ({ propertyKey, filterValue }) => cashierStore[propertyKey] === filterValue,
        ) &&
        activeKeysForAssociatedDropdown.some(callback)
      ) {
        result.push(cashierStore);
      }

      return result;
    }, []);

    return filteredCashierStores;
  };

  /************************************/
  /** Handle display of delete modal **/
  /************************************/

  const displayModalDeleteCashierStores = (cashierStores) => {
    const params = utilsModal.getDeleteCashierStoresModalConfig({
      cashierStores,
      handleDeleteCashierStoresById,
      translatedStoreName,
      translatedStoresName,
    });

    props.openGenericModal(params);
  };

  /******************************/
  /** Associate a cashier store **/
  /******************************/

  const openStoresAssociationModal = (item) => {
    const params = {
      handleGetCashierStores,
      cashierStores: item,
      deactivateBackgroundClick: true,
      component: StoreAssociationModal,
    };

    props.openModal(params);
  };

  /*************************/
  /** Sync cashier stores **/
  /*************************/

  const syncCashierStore = async () => {
    try {
      await cashierStoreService.syncCashierStore(clientId);

      updateIsSynchronizingCashierStoresStatus(true);

      props.showMessage(
        i18next.t('BACKOFFICE.CASHIER_STORES.RESTART_SYNC_SUCCESS', {
          storeName: translatedStoresName,
        }),
        'success',
      );
    } catch (error) {
      props.showMessage(
        i18next.t('BACKOFFICE.CASHIER_STORES.RESTART_SYNC_ERROR', {
          storeName: translatedStoresName,
        }),
        'error',
      );
    }
  };

  //Handle Sync
  useEffect(() => {
    if (!isSynchronizingCashierStores) {
      return;
    }

    const getClientInterval = setInterval(() => {
      (async () => {
        try {
          const syncStatus = await cashierStoreService.getCashierStoreSyncStatus(clientId);

          if (syncStatus.state === ENUM_SYNCHRO_CASHIER_PRODUCT.SUCCESS) {
            props.showMessage(
              i18next.t('BACKOFFICE.CASHIER_STORES.SYNC_STATUS_SUCCESS', {
                storeName: translatedStoresName,
              }),
              'success',
            );
            updateIsSynchronizingCashierStoresStatus(false);
            await reloadCashierStores();
          }
          if (
            syncStatus.state === ENUM_SYNCHRO_CASHIER_PRODUCT.FAILURE ||
            syncStatus.state === ENUM_SYNCHRO_CASHIER_PRODUCT.REVOKED
          ) {
            props.showMessage(
              i18next.t('BACKOFFICE.CASHIER_STORES.SYNC_STATUS_ERROR', {
                storeName: translatedStoresName,
              }),
              'error',
            );
            updateIsSynchronizingCashierStoresStatus(false);
          }
        } catch (error) {
          props.showMessage(
            i18next.t('BACKOFFICE.CASHIER_STORES.SYNC_STATUS_ERROR', {
              storeName: translatedStoresName,
            }),
            'error',
          );
          updateIsSynchronizingCashierStoresStatus(false);
        }
      })();
    }, TIMEOUT_GET_SYNC_STATUS);

    return () => clearInterval(getClientInterval);
  }, [isSynchronizingCashierStores]);

  // Initialisation
  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      await handleGetCashierStores();

      setActions(
        utilsActions.getActionsSettings({
          selectedItems,
          handleExport,
          openStoresAssociationModal,
          goCashierStoreDetailsPage,
          syncCashierStore,
          isSynchronizingCashierStores,
          translatedStoreName,
          translatedStoresName,
        }),
      );

      setRowActions(
        utilsActions.getRowActionsSettings({
          handleExport,
          displayModalDeleteCashierStores,
          openStoresAssociationModal,
          goCashierStoreDetailsPage,
          translatedStoreName,
        }),
      );

      setIsLoading(false);
    })();
  }, [user, isSynchronizingCashierStores]);

  // When user changes selection
  useEffect(() => {
    setActions(
      utilsActions.getActionsSettings({
        selectedItems,
        handleExport,
        displayModalDeleteCashierStores,
        openStoresAssociationModal,
        goCashierStoreDetailsPage,
        syncCashierStore,
        isSynchronizingCashierStores,
        translatedStoreName,
        translatedStoresName,
      }),
    );

    setRowActions(
      utilsActions.getRowActionsSettings({
        handleExport,
        displayModalDeleteCashierStores,
        openStoresAssociationModal,
        goCashierStoreDetailsPage,
        translatedStoreName,
      }),
    );
  }, [selectedItems, cashierStores, displayedCashierStores]);

  // Handle list filters
  useEffect(() => {
    if (!applyFilters || !listViewRef || !listViewRef.current) {
      return;
    }

    setDisplayedCashierStores(processFilters(cashierStores));

    listViewRef.current.resetPagination();
  }, [applyFilters]);

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ListViewContainer>
        <ListView
          actions={actions}
          columns={columns}
          data={displayedCashierStores}
          defaultCurrentPage={listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE]}
          defaultMaxPerPage={listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]}
          defaultOrderBy={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]}
          defaultOrderType={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]}
          defaultSearchInput={listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH]}
          handleCurrentPageChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](input)
          }
          handleMaxPerPageChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE](input)
          }
          handleOrderByChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY](input)}
          handleOrderTypeChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE](input)
          }
          handleSearchInputChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.SEARCH](input)
          }
          isLoading={isLoading}
          languageCode={userLanguageCode}
          markerConfiguration={markerConfiguration}
          minActionsInActionsDropdown={1}
          padding={'24px 24px 0px 24px'}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          ref={listViewRef}
          renderEmptyState={() => <EmptyState />}
          renderFilterButton={() => (
            <DeepsightFiltersButton
              applyFilters={applyFilters}
              customMultipleDropDowns={[
                {
                  id: 'status',
                  icon: '/images/icon-dropdown-grey.svg',
                  list: CHOICES_DROPDOWN_ACTIVE,
                  defaultSelectedItems: [CHOICES_DROPDOWN_ACTIVE[0]],
                  selectedItems: activeKeysForActiveDropdown,
                  setSelectedItems: (selectedItems) =>
                    setActiveKeysForActiveDropdown(selectedItems),
                  allMessage: i18next.t('GENERAL.ACTIVES_INACTIVES'),
                },
                {
                  id: 'associated',
                  icon: '/images/inpulse/link-black-small.svg',
                  list: CHOICES_DROPDOWN_ASSOCIATED,
                  defaultSelectedItems: CHOICES_DROPDOWN_ASSOCIATED,
                  selectedItems: activeKeysForAssociatedDropdown,
                  setSelectedItems: (selectedItems) =>
                    setActiveKeysForAssociatedDropdown(selectedItems),
                  allMessage: i18next.t('GENERAL.LINKED_OR_NOT'),
                },
              ]}
              filters={filters}
              isLoading={isLoading}
              readOnly={isLoading}
              setAdvancedFilters={() => false}
              setApplyFilters={setApplyFilters}
              setFilters={setFilters}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
            />
          )}
          rowActions={rowActions}
          setSelectedItems={setSelectedItems}
        />
      </ListViewContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isSynchronizingCashierStores: state.baseReducer.isSynchronizingCashierStores,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  updateIsSynchronizingCashierStoresStatus: (isSynchronizingCashierStores) => {
    dispatch(updateIsSynchronizingCashierStoresStatus(isSynchronizingCashierStores));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeCashierStoresManagement);
