import React from 'react';

import { Button, Dropdown } from '@commons/utils/styledLibraryComponents';

import DateRangeSelectionModal from '@backoffice/components/DateRangeSelectionModal';

import { ContainerHeader } from './styledComponents';

export default ({
  stores,
  isLoading,
  handleSubmit,
  selectedStore,
  setSelectedStore,
  setSelectedDateRange,
}) => (
  <ContainerHeader>
    <Dropdown
      customStyle={{ marginBottom: '8px' }}
      height={'64px'}
      isDisabled={isLoading}
      isRequired={true}
      items={stores}
      label={'Choisissez votre store :'}
      selectedItem={selectedStore}
      onSelectionChange={setSelectedStore}
    />

    <DateRangeSelectionModal
      labelText={'Choisissez une période'}
      setDateRange={setSelectedDateRange}
      timezone={'Europe/Paris'} // TODO - [TIMEZONES]
    />

    <Button
      buttonCustomStyle={{ marginTop: '24px' }}
      color={'inpulse-outline'}
      handleClick={handleSubmit}
      icon="/images/inpulse/save-black-small.svg"
      isDisabled={isLoading}
      label={'Valider'}
    />
  </ContainerHeader>
);
