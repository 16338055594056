import styled, { css } from 'styled-components';

export const ContainerListComposition = styled.div`
  margin: 15px 0px 0px 0px;
`;

export const ListHeader = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`;

export const ListHeaderSection = styled.div`
  flex: 2;
  color: ${(props) => props.theme.colors?.greys.dark};
  font-size: 13px;
  line-height: 12px;
  font-style: normal;
  font: ${(props) => props.theme.fonts.textSmall};

  &.name,
  &.category {
    flex: 3;
  }

  &:first-child {
    padding-left: 25px;
  }

  &:last-child {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    display: flex;
    margin-right: 25px;
    align-items: center;
    justify-content: center;
  }
`;

export const ListRow = styled.div`
  height: 50px;
  display: flex;
  align-items: center;

  border: ${(props) => `1px solid ${props.theme.colors.greys.light}`};
  border-radius: ${(props) => props.theme.borders?.radius.weak};
  margin-bottom: 8px;
  &:before {
    top: 0;
    width: 1px;
    left: 0px;
    content: ' ';
    height: 100%;
  }

  &:after {
    top: 0;
    width: 1px;
    right: 0px;
    content: ' ';
    height: 100%;
  }

  &.error {
    border-bottom: solid red;
    border-width: thin;
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${props.theme.colors.greys.light};
    `}
`;

export const ListRowText = styled.div`
  color: ${(props) => props.theme.colors?.greys.darkest};

  font: ${(props) => props.theme.fonts.textBig};
  &.link {
    color: ${(props) => props.theme.colors?.greys.darkest};
  }

  ${(props) =>
    props.flex &&
    css`
      display: flex;
    `}

  ${(props) =>
    props.emptyText &&
    css`
      margin-left: 25px;
    `}
`;

export const LinkText = styled.a`
  cursor: pointer;
  color: ${(props) => props.theme.colors?.greys.darkest};

  &:hover {
    color: ${(props) => props.theme.colors?.greys.darkest};

    ${(props) =>
      props.disabled &&
      css`
        cursor: unset;
        text-decoration: none;
      `}
  }
`;

export const ListRowSection = styled.div`
  flex: 2;

  &:first-child {
    padding-left: 25px;
  }

  &:last-child {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    display: flex;
    margin-right: 25px;
    align-items: center;
    justify-content: center;
  }

  &.name,
  &.category {
    flex: 3;
  }

  p {
    margin: 0;
  }

  .actions {
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: ${(props) => props.theme.borders?.radius.weak};
    margin-right: 25px;
    background: ${(props) => props.theme.colors?.greys.light};
    align-items: center;
    justify-content: center;

    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }

    img {
      width: 15px;
      height: 15px;
    }
  }
`;
