import { connect } from 'react-redux';
import { get, cloneDeep, sortBy, find, isEqual, uniqBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect, useLayoutEffect } from 'react';

import { closeGenericModal, openGenericModal, refreshGenericModal } from '@actions/modal';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import {
  getCustomFilterMultipleAvailableOrder,
  getCustomFilterMultipleAvailableStock,
  getCustomFilterMultipleAvailableLoss,
} from '@commons/utils/filtersFetches';
import { LISTVIEW_NO_TOP_PADDING } from '@commons/constants/listViewProps';
import GeneralEmptyStateListView from '@commons/GeneralEmptyStateListView';
import utilsXLS from '@commons/utils/makeXLS';

import {
  CHOICES_ORDER_AVAILABILITY,
  CHOICES_STOCK_AVAILABILITY,
  CHOICES_LOSS_AVAILABILITY,
} from '@admin/utils/DropdownItems';
import DeepsightFiltersButton from '@admin/components/FilterButton';
import EmptyState from '@admin/suppliers/supplierProducts/components/EmptyState';
import formatUtils from '@admin/suppliers/supplierProducts/details/supplierProductStores/components/SupplierProductMappings/utils/format.js';

import { Container } from './styledComponents';
import { getColumns } from './utils/columnsUtils';
import { getStoreMappingModalConfig } from './utils/getStoreMappingModalConfig';
import actionUtils from './utils/actionUtils';

const KitchenProductSSPMSection = (props) => {
  const {
    user,
    availableStores,
    storeName,
    formFields,
    openGenericModal,
    refreshGenericModal,
    productForm,
  } = props;

  // For stores in the StoreMappingModal
  const [availableStoresToSelect, setAvailableStoresToSelect] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  // For stores in the ListView of the page
  const [mappings, setMappings] = useState([]);
  const [selectedMappings, setSelectedMappings] = useState([]);
  const [mappingsToDisplay, setMappingsToDisplay] = useState([]);

  // States filters
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [stores, setStores] = useState([]);
  const [selectedAvailableStores, setSelectedAvailableStores] = useState([]);
  const [supplierProfiles, setSupplierProfiles] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedSupplierProfiles, setSelectedSupplierProfiles] = useState([]);
  const [selectedOrderAvailability, setSelectedOrderAvailability] = useState(
    CHOICES_ORDER_AVAILABILITY,
  );
  const [selectedStockAvailability, setSelectedStockAvailability] = useState(
    CHOICES_STOCK_AVAILABILITY,
  );
  const [selectedLossAvailability, setSelectedLossAvailability] =
    useState(CHOICES_LOSS_AVAILABILITY);

  const [columns, setColumns] = useState([]);
  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  useEffect(() => {
    const formattedMappings = availableStores.map((store) => ({
      ...store,
      isRowSelected: false,
      hasDlc: true,
      available: true,
      hasStock: true,
    }));

    const sortedMappings = sortBy(formattedMappings, 'name');

    setMappings(sortedMappings);
    setMappingsToDisplay(sortedMappings);
    setSelectedStores([]);

    productForm.setValue('mappings', sortedMappings);

    handleUpdateFilters(sortedMappings);
  }, []);

  useEffect(() => {
    handleAvailableStoresForMappingUpdate(availableStores);
  }, [availableStores, mappings]);

  useEffect(() => {
    const updatedColumns = getColumns({
      onChange: onSSPMChange,
    });

    const updatedActions = actionUtils.getGlobalActions({
      selectedItems: selectedMappings,
      availableStores: availableStoresToSelect,
      handleExport,
      handleRemoveAssociation,
      handleCreateNewAssociation,
    });

    setColumns(updatedColumns);
    setActions(updatedActions);

    const updatedRowActions = actionUtils.getRowActions({
      handleRemoveAssociation,
    });

    setRowActions(updatedRowActions);
  }, [
    availableStoresToSelect,
    mappings,
    selectedMappings,
    selectedAvailableStores,
    selectedSuppliers,
    selectedSupplierProfiles,
    selectedLossAvailability,
    selectedAvailableStores,
    selectedStockAvailability,
  ]);

  useEffect(() => {
    const params = getStoreMappingModalConfig({
      availableStores: availableStoresToSelect,
      setSelectedStores,
      selectedStores,
      price: formFields.price,
      handleAddNewMappings,
      isCentralKitchenView: true,
    });

    refreshGenericModal(params);
  }, [availableStoresToSelect, selectedStores]);

  // useLayoutEffect instead of useEffect because we want to wait for the first setMappings() to finish before triggering it at the first render
  useLayoutEffect(() => {
    handleFilterMappingsToDisplay(mappings);
  }, [applyFilters]);

  const handleFilterMappingsToDisplay = (mappings) => {
    const filteredData = mappings.filter(
      ({ storeId, supplierProfileId, available, hasStock, hasDlc, supplierProfile }) =>
        selectedAvailableStores.some(({ id }) => id === storeId) && // store selector
        selectedSuppliers.some(({ id }) => supplierProfile.supplierId === id) && // supplier selector
        selectedSupplierProfiles.some(({ id }) => id === supplierProfileId) && // supplier profile selector
        selectedOrderAvailability.some(({ filterValue }) => available === filterValue) && // order availability selector
        selectedStockAvailability.some(({ filterValue }) => hasStock === filterValue) && // stock availability selector
        selectedLossAvailability.some(({ filterValue }) => hasDlc === filterValue), // loss availability selector
    );

    setMappingsToDisplay(filteredData);
  };

  const handleUpdateFilters = (mappingsForUpdate) => {
    const uniqMappings = uniqBy(mappingsForUpdate, 'storeId');

    const formattedStores = uniqMappings.map(({ storeId, name }) => ({
      id: storeId,
      name,
    }));

    setStores(formattedStores);
    setSelectedAvailableStores(formattedStores);

    // supplierProfiles
    const mappingsSupplierProfiles = mappingsForUpdate.map(
      ({ supplierProfileId, supplierProfileName, supplierProfile }) => ({
        id: supplierProfileId,
        name: supplierProfileName,
        supplierId: supplierProfile.supplierId,
      }),
    );

    const uniqSupplierProfiles = uniqBy(mappingsSupplierProfiles, 'id');

    setSupplierProfiles(uniqSupplierProfiles);
    setSelectedSupplierProfiles(uniqSupplierProfiles);

    // Suppliers
    const mappingSuppliers = mappingsForUpdate.map(({ supplierName, supplierProfile }) => ({
      id: supplierProfile.supplierId,
      name: supplierName,
    }));

    const uniqSuppliers = uniqBy(mappingSuppliers, 'id');

    setSuppliers(uniqSuppliers);
    setSelectedSuppliers(uniqSuppliers);

    setFilters({
      ...filters,
      multipleSelectLossAvailability: getCustomFilterMultipleAvailableLoss(
        selectedLossAvailability,
        setSelectedLossAvailability,
      ),
      multipleSelectOrderAvailability: getCustomFilterMultipleAvailableOrder(
        selectedOrderAvailability,
        setSelectedOrderAvailability,
      ),
      multipleSelectStockAvailability: getCustomFilterMultipleAvailableStock(
        selectedStockAvailability,
        setSelectedStockAvailability,
      ),
      stores: {
        list: formattedStores,
        activeList: formattedStores,
        selectedStores: formattedStores,
      },
      suppliers: {
        list: uniqSuppliers,
        activeList: uniqSuppliers,
        selectedSuppliers: uniqSuppliers,
      },
      'supplier-profiles': {
        id: 'supplier-profiles',
        icon: '/images/inpulse/supplier-black-small.svg',
        list: uniqSupplierProfiles,
        defaultSelectedItems: uniqSupplierProfiles,
        selectedItems: uniqSupplierProfiles,
        setSelectedItems: setSelectedSupplierProfiles,
      },
    });
  };

  const handleAvailableStoresForMappingUpdate = (stores) => {
    const formattedStores = stores.filter(
      ({ id, supplierProfileId }) =>
        !mappings.find(
          (mapping) => mapping.id === id && mapping.supplierProfileId === supplierProfileId,
        ),
    );

    setAvailableStoresToSelect(formattedStores);
  };

  const onSSPMChange = (selectedItem, changeType) => {
    const mappingsWithSelectedFields = mappings.map((mapping) => {
      if (
        mapping.id !== selectedItem.id ||
        mapping.supplierProfileId !== selectedItem.supplierProfileId
      ) {
        return mapping;
      }

      const changeTypeKeys = Object.keys(changeType);
      const changeTypeValues = Object.values(changeType);

      return {
        ...mapping,
        [changeTypeKeys[0]]: changeTypeValues[0],
      };
    });

    setMappings(mappingsWithSelectedFields);

    handleFilterMappingsToDisplay(mappingsWithSelectedFields);

    productForm.setValue('mappings', mappingsWithSelectedFields);
  };

  const handleExport = (isSelection) => {
    const titleSheetStores = getClientStoreNameTranslation(storeName, true);

    const mappingsToFormat = isSelection ? selectedMappings : mappings;

    const formattedMappings = mappingsToFormat.map((mapping) => ({
      ...mapping,
      hasDlc: !!mapping.hasDlc,
      available: !!mapping.available,
      hasStock: !!mapping.hasStock,
      isKitchen: mapping.isKitchen ? i18next.t('GENERAL.CENTRAL') : i18next.t('GENERAL.SALE_POINT'),
    }));

    const mappingsToExport = sortBy(formattedMappings, ({ name }) => name.toLowerCase());

    const sheetStores = utilsXLS.generateDefaultSheet(titleSheetStores, columns, mappingsToExport);

    const fileName = i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_EXPORT_FILENAME', {
      storeName: titleSheetStores,
      date: moment().format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
    });

    utilsXLS.makeXLS(fileName, [sheetStores]);
  };

  const handleRemoveAssociation = (selectedItems) => {
    const formattedMappings = mappings.filter(
      ({ id, supplierProfileId }) =>
        !selectedItems.find(
          (selectedItem) =>
            selectedItem.id === id && selectedItem.supplierProfileId === supplierProfileId,
        ),
    );

    setMappings(formattedMappings);
    setSelectedMappings([]);

    productForm.setValue('mappings', formattedMappings);

    handleUpdateFilters(formattedMappings);
    handleFilterMappingsToDisplay(formattedMappings);
  };

  const handleAddNewMappings = () => {
    const updatedMappings = cloneDeep(mappings);
    const formattedSelectedStores = selectedStores.map((store) => ({
      ...store,
      isRowSelected: false,
      hasDlc: true,
      available: true,
      hasStock: true,
    }));

    updatedMappings.push(...formattedSelectedStores);

    const sortedMappings = sortBy(updatedMappings, 'name');

    setMappings(sortedMappings);
    setMappingsToDisplay(sortedMappings);
    setSelectedStores([]);

    productForm.setValue('mappings', sortedMappings);

    handleUpdateFilters(sortedMappings);
  };

  const handleSelectMappings = (selectedItems) => {
    const mappingsWithRowSelected = mappings.map((mapping) => ({
      ...mapping,
      isRowSelected: !!find(
        selectedItems,
        ({ id, supplierProfileId }) =>
          id === mapping.id && supplierProfileId === mapping.supplierProfile.id,
      ),
    }));

    if (isEqual(mappings, mappingsWithRowSelected)) {
      return;
    }

    setSelectedMappings(selectedItems);
    setMappings(mappingsWithRowSelected);
    handleFilterMappingsToDisplay(mappingsWithRowSelected);

    productForm.setValue('mappings', mappingsWithRowSelected);
  };

  const handleCreateNewAssociation = () => {
    const params = getStoreMappingModalConfig({
      availableStores: availableStoresToSelect,
      setSelectedStores,
      selectedStores,
      price: formFields.price,
      handleAddNewMappings,
      isCentralKitchenView: true,
    });

    openGenericModal(params);
  };

  if (!mappings.length) {
    const storeNameToLowerCase = getClientStoreNameTranslation(storeName).toLowerCase();

    return (
      <GeneralEmptyStateListView
        icon={'/images/inpulse-icon-no-data.svg'}
        renderAction={() => (
          <Button
            color="inpulse-default"
            handleClick={() => handleCreateNewAssociation(availableStoresToSelect)}
            icon={'/images/inpulse/add-white-small.svg'}
            label={i18next.t('GENERAL.ADD')}
          />
        )}
        subtitle={i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_EMPTY_STATE_SUBTITLE', {
          storeName: storeNameToLowerCase,
        })}
        title={i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_EMPTY_STATE_TITLE', {
          storeName: storeNameToLowerCase,
        })}
      />
    );
  }

  return (
    <Container>
      <ListView
        actions={actions}
        columns={columns}
        data={mappingsToDisplay}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        disableFooter={true}
        isLoading={false}
        languageCode={userLanguageCode}
        padding={LISTVIEW_NO_TOP_PADDING}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => (
          <DeepsightFiltersButton
            applyFilters={applyFilters}
            customMultipleDropDowns={formatUtils.computeMultipleDropdownsFilter({
              supplierProfiles,
              selectedSupplierProfiles,
              setSelectedSupplierProfiles,
              selectedOrderAvailability,
              setSelectedOrderAvailability,
              selectedStockAvailability,
              setSelectedStockAvailability,
              selectedLossAvailability,
              setSelectedLossAvailability,
            })}
            filters={filters}
            readOnly={false}
            selectedStores={selectedAvailableStores}
            selectedSuppliers={selectedSuppliers}
            setApplyFilters={setApplyFilters}
            setFilters={setFilters}
            setSelectedStores={setSelectedAvailableStores}
            setSelectedSuppliers={setSelectedSuppliers}
            setSuppliers={setSuppliers}
            stores={stores}
            suppliers={suppliers}
            textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
          />
        )}
        rowActions={rowActions}
        setSelectedItems={handleSelectMappings}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  closeGenericModal: () => {
    dispatch(closeGenericModal());
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(KitchenProductSSPMSection);
