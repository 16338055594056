import moment from 'moment-timezone';

import { getUserTimezone } from '@commons/utils/date';

export const isEditionAllowed = (date) => {
  const userTimezone = getUserTimezone();

  return (
    moment.tz(userTimezone).isSame(date, 'day') ||
    moment.tz(userTimezone).isSame(moment(date).add(1, 'day'), 'day')
  );
};

export default {
  isEditionAllowed,
};
