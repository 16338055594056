import i18next from 'i18next';

import { getClientStoreNameTranslation } from '@commons/utils/translations';

export default ({ isCentral, storeName, displayCustomerCodeColumn, displayPackagingWeight }) => {
  const columns = [
    {
      propertyKey: 'order.reference',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_REFERENCE'),
    },
    {
      propertyKey: 'store.name',
      name: getClientStoreNameTranslation(storeName),
    },
    {
      propertyKey: 'store.partnerId',
      name: i18next.t('BACKOFFICE.CASHIER_STORES.PARTNER_ID'),
    },
    {
      propertyKey: 'supplier.name',
      name: i18next.t(isCentral ? 'GENERAL.CENTRAL' : 'ORDERS.ORDERS.LIST_LABEL_SUPPLIER'),
    },
    {
      propertyKey: 'order.orderDate', // Creation
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_CREATION_DATE'),
    },
    {
      propertyKey: 'order.startOrderDate', // Delivery
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_DELIVERY_DATE'),
    },
    {
      propertyKey: 'order.receptionDate', // Receipt
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEIPT_DATE'),
    },
    {
      propertyKey: 'supplierProduct.name',
      name: i18next.t('GENERAL.SUPPLIER_PRODUCT'),
    },
    {
      propertyKey: 'supplierProduct.sku',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_SKU'),
    },
    {
      propertyKey: 'supplierProduct.subCategory',
      name: i18next.t('GENERAL.CATEGORY'),
    },
    {
      propertyKey: 'ordered.packagingName',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_ORDER_PACKAGING'),
    },
    {
      propertyKey: 'ordered.quantity',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_ORDERED_QUANTITY'),
    },
    {
      propertyKey: 'ordered.price',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_PRICE_EX_TAX_BY_ORDER_PACKAGING'),
      type: 'currency',
    },
    {
      propertyKey: 'received.packagingName',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_PACKAGING'),
    },
    {
      propertyKey: 'received.quantity',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEIVED'),
    },
    {
      propertyKey: 'received.price',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_THEORETICAL_PRICE_BY_RECEPTION_PRICE'),
    },
    {
      propertyKey: 'invoiced.packagingName',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_INVOICE_PACKAGING'),
    },
    {
      propertyKey: 'invoiced.quantity',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_QUANTITY_BDL'),
    },
    {
      propertyKey: 'invoiced.price',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_PRICE_EX_TAX_BDL_BY_INVOICE_PACKAGING'),
      type: 'currency',
    },
    {
      propertyKey: 'supplierProduct.vatRate',
      name: i18next.t('ORDERS.ORDERS.EXPORT_VAT_RATE'),
    },
    {
      propertyKey: 'unreceivedTot',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_NOT_RECEIVED'),
    },
    {
      propertyKey: 'anomaly',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_ANOMALY'),
    },
    {
      propertyKey: 'credit',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_CREDIT'),
      type: 'currency',
    },
    {
      propertyKey: 'order.receptionComment',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_COMMENT'),
    },
  ];

  if (displayPackagingWeight) {
    columns.splice(16, 0, {
      propertyKey: 'totPackagingWeight',
      name: i18next.t('ORDERS.ORDERS.EXPORT_TOTAL_WEIGHT'),
    });
  }

  if (displayCustomerCodeColumn) {
    columns.splice(4, 0, {
      propertyKey: 'customerCode',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_CUSTOMER_CODE'),
    });
  }

  return columns;
};
