import i18next from 'i18next';
import React from 'react';

import InputContentModal from '@commons/Modals/InputContentModal';

export const getNewCategorySubcategoryModalParams = (
  inputValue,
  errorMessage,
  closeCleanUp,
  handleInputChange,
  itemDropdownPropertyName,
  handleSaveNewItemDropdown,
) => ({
  type: 'action',
  width: '560px',
  height: 'auto',
  icon: '/images/inpulse/add-black-small.svg',
  title:
    itemDropdownPropertyName === 'category'
      ? i18next.t('GENERAL.LIST_CREATE_CATEGORY')
      : i18next.t('GENERAL.LIST_CREATE_SUBCATEGORY'),
  isLoading: false,
  handleCloseCleanUp: closeCleanUp,
  component: () => (
    <InputContentModal
      errorMessage={errorMessage}
      label={
        itemDropdownPropertyName === 'category'
          ? i18next.t('GENERAL.MODAL_CATEGORY_NAME')
          : i18next.t('GENERAL.MODAL_SUBCATEGORY_NAME')
      }
      value={inputValue}
      autoFocus
      onChange={(value) => handleInputChange(value)}
    />
  ),
  closeConfig: {
    title: i18next.t('GENERAL.LEAVE'),
    content: <div>{i18next.t('GENERAL.MODAL_CATEGORIES_CLOSE_CONFIRMATION_CONTENT')}</div>,
  },
  actions: [
    {
      key: 0,
      color: 'inpulse-outline',
      label: i18next.t('GENERAL.CANCEL'),
      icon: '/images/inpulse/close-black-small.svg',
    },
    {
      key: 1,
      color: 'inpulse-default',
      label: i18next.t('GENERAL.SAVE'),
      isDisabled: !inputValue || !!errorMessage,
      icon: '/images/inpulse/save-white-small.svg',
      handleClick: () => handleSaveNewItemDropdown(),
    },
  ],
});
