import React from 'react';
import PropTypes from 'prop-types';

import { Props } from './interfaces';

import { Container, MainText, Icon } from './styledComponents';

import iconEmptyState from '../../../images/icon-empty-state-no-data.svg';

const EmptyState = (props: Props): JSX.Element => {
  const { label } = props;
  return (
    <Container>
      <Icon src={iconEmptyState} alt="icon-no-data" />
      <MainText>{label}</MainText>
    </Container>
  );
};

EmptyState.propTypes = {
  label: PropTypes.string.isRequired,
};

export default EmptyState;
