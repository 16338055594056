import i18next from 'i18next';

const getCashierStoreLocationCardSettings = (
  cashierStoreLocation,
  hasLatitudeError,
  hasLongitudeError,
) => {
  const inputs = [
    {
      dropdownValue: null,
      hasTooltip: false,
      icon: null,
      isDropdown: false,
      isRequired: false,
      isSwitch: false,
      label: i18next.t('BACKOFFICE.STORES.ADDRESS'),
      placeholder: i18next.t('BACKOFFICE.STORES.ADDRESS'),
      propertyKey: 'address',
      type: 'text',
    },
    {
      dropdownValue: null,
      hasTooltip: false,
      icon: null,
      isDropdown: false,
      isRequired: false,
      isSwitch: false,
      label: i18next.t('BACKOFFICE.STORES.POSTAL_CODE'),
      placeholder: i18next.t('BACKOFFICE.STORES.POSTAL_CODE'),
      propertyKey: 'postCode',
      type: 'text',
    },
    {
      dropdownValue: null,
      hasTooltip: false,
      icon: null,
      isDropdown: false,
      isRequired: false,
      isSwitch: false,
      label: i18next.t('GENERAL.CITY'),
      placeholder: i18next.t('GENERAL.CITY'),
      propertyKey: 'city',
      type: 'text',
    },
    {
      icon: null,
      isCountryDropdown: true,
      isDropdown: false,
      isRequired: false,
      isSwitch: false,
      label: i18next.t('GENERAL.COUNTRY'),
      placeholder: i18next.t('GENERAL.COUNTRY'),
      propertyKey: 'country',
      type: 'dropdown',
    },
    {
      dropdownValue: null,
      hasTooltip: true,
      icon: null,
      isDropdown: false,
      isRequired: false,
      isSwitch: false,
      label: i18next.t('BACKOFFICE.STORES.LATITUDE'),
      placeholder: i18next.t('BACKOFFICE.STORES.LATITUDE'),
      propertyKey: 'latitude',
      tooltipText: i18next.t('BACKOFFICE.STORES.LATITUDE_TOOLTIP'),
      type: 'number',
      hasError: !!hasLatitudeError,
      errorMessage: i18next.t('BACKOFFICE.STORES.LATITUDE_ERROR'),
    },
    {
      dropdownValue: null,
      hasTooltip: true,
      icon: null,
      isDropdown: false,
      isRequired: false,
      isSwitch: false,
      label: i18next.t('BACKOFFICE.STORES.LONGITUDE'),
      placeholder: i18next.t('BACKOFFICE.STORES.LONGITUDE'),
      propertyKey: 'longitude',
      tooltipText: i18next.t('BACKOFFICE.STORES.LONGITUDE_TOOLTIP'),
      type: 'number',
      hasError: !!hasLongitudeError,
      errorMessage: i18next.t('BACKOFFICE.STORES.LONGITUDE_ERROR'),
    },
  ];

  return {
    title: i18next.t('BACKOFFICE.STORES.LOCATION'),
    inputs: inputs.reduce((acc, input) => {
      acc.push({ ...input, value: cashierStoreLocation[input.propertyKey] });
      return acc;
    }, []),
  };
};

export default getCashierStoreLocationCardSettings;
