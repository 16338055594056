import { get } from 'lodash';
import i18next from 'i18next';

import clientService from '@services/client';

export const postBatchProductsToCreate = async (
  props,
  batchProductsToCreate,
  functionToCall = null,
) => {
  const {
    pageLoading,
    pageLoaded,
    showSuccessMessage,
    showErrorMessage,
    user,
    cleanUpModalImport,
  } = props;

  pageLoading();

  try {
    const clientId = get(user, 'clientId', null);

    await clientService.postProductsBatchCreation(clientId, batchProductsToCreate);

    showSuccessMessage(i18next.t('GENERAL.IMPORT_FILE_BATCH_CREATION_TOASTER_SUCCESS'));

    if (functionToCall) {
      await functionToCall();
    }
  } catch (error) {
    showErrorMessage(i18next.t('GENERAL.IMPORT_FILE_BATCH_CREATION_TOASTER_ERROR'));
  } finally {
    cleanUpModalImport();
    pageLoaded();
  }
};

export default {
  postBatchProductsToCreate,
};
