import { STEP_REGISTRATION } from './constants';
import i18next from 'i18next';

const getUserInformationsSettings = (settings) => {
  const { firstName, lastName, languageId, handleSwitchStep, handleSetUserInformations } = settings;

  const title = i18next.t('AUTHENTICATION.REGISTRATION.LANDING_STEP_ONE_TITLE');

  const isDisabled = !firstName.length || !lastName.length;

  const nextButtonParams = {
    color: 'inpulse-default',
    isDisabled,
    label: i18next.t('GENERAL.NEXT'),
    icon: '/images/inpulse/arrow-right-white.svg',
    handleClick: () => handleSwitchStep(STEP_REGISTRATION.USER_PASSWORD),
  };

  const actions = [nextButtonParams];

  const params = {
    firstName,
    lastName,
    languageId,
    handleSetUserInformations,
  };

  return {
    title,
    actions,
    params,
  };
};

export default getUserInformationsSettings;
