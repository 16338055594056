import { orderBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS, TIME_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { isSameDay } from '@commons/utils/date';

export const getAuditButtonLabel = (auditLogs) => {
  if (!auditLogs.length) {
    return '';
  }

  const sortedAuditLogs = orderBy(auditLogs, 'actionTimestamp', 'desc');

  const latestActionTimestamp = sortedAuditLogs[0].actionTimestamp;

  const today = new Date();

  const lastModifiedToday = isSameDay(latestActionTimestamp, today);

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const lastModifiedYesterday = isSameDay(latestActionTimestamp, yesterday);

  const latestAuditLogTime = moment(latestActionTimestamp).format(
    TIME_DISPLAY_FORMATS.SLASHED_HOUR_MINUTE,
  );
  const latestAuditLogDate = moment(latestActionTimestamp).format(
    DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR,
  );

  if (lastModifiedToday) {
    return i18next.t('ORDERS.ORDERS.FORM_LAST_UPDATED_TODAY', {
      time: latestAuditLogTime,
    });
  }

  if (lastModifiedYesterday) {
    return i18next.t('ORDERS.ORDERS.FORM_LAST_UPDATED_YESTERDAY', {
      time: latestAuditLogTime,
    });
  }

  return i18next.t('ORDERS.ORDERS.FORM_LAST_UPDATED_AT', {
    date: latestAuditLogDate,
    time: latestAuditLogTime,
  });
};
