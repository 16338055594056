import React from 'react';

import { Line } from 'react-chartjs-2';
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale,
} from 'chart.js';
import { ChartLineGraphProps } from './interfaces';

Chart.register(
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale
);

const ChartLineGraph: React.FC<ChartLineGraphProps> = (
  props: ChartLineGraphProps
) => {
  const { data, options } = props;

  return <Line data={data} options={options} />;
};

ChartLineGraph.propTypes = {};

ChartLineGraph.displayName = 'ChartLineGraph';

export default ChartLineGraph;
