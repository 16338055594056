import { connect } from 'react-redux';
import { get } from 'lodash';
import React from 'react';

import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { getSalesPointStores } from '@selectors/stores';

import { INVENTORY_TYPE } from '../common/constants';
import InventoryListView from '../components/InventoryListView';

export const StocksInventoriesStocks = (props) => {
  const path = get(props.match, 'path');

  return (
    <div>
      <NavigationBreadCrumb featurePath={path} />
      <InventoryListView inventoryType={INVENTORY_TYPE.STOCK} {...props} />
    </div>
  );
};

StocksInventoriesStocks.propTypes = {};

const mapStateToProps = (state) => ({
  stores: getSalesPointStores(state.baseReducer.activeStores),
});

export default connect(mapStateToProps)(StocksInventoriesStocks);
