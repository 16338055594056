import { get, groupBy, sortBy, uniq } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import { getUserTimezone } from '@commons/utils/date';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import { translateUnit } from '@commons/utils/translateUnit';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import utilsXLS from '@commons/utils/makeXLS';

import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import ExportModalContent from '@lib/inpulse/ExportModal';

import centralService from '@services/central';

import { Container, Header, Content, Footer, DatePickerContainer } from './styledComponents';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

import {
  RECIPES_PRODUCTION_SHEET,
  AGGREGATED_RECIPES_PRODUCTION_SHEET,
  INFORMATIONS_SHEET,
} from './excelColumns';

export const ProductionOperationExportModal = (props) => {
  const {
    closeModal,
    params: { date, selectedStore, storeName, recipesKeyById },
  } = props;

  const userTimezone = getUserTimezone();

  const today = moment();

  const periodPickerState = usePeriodDatePickerState(date, date);

  const [nbDaysLeftToFetch, setNbDaysLeftToFetch] = useState(0);

  const [isLoading, setLoading] = useState(true);
  const [hasStartedExport, setHasStartedExport] = useState(false);
  const [recipesProduction, setRecipesProduction] = useState([]);

  const [plannedProductions, setPlannedProductions] = useState([]);

  // Progress modal
  const [progress, setProgress] = useState(0);
  const [title, setTitle] = useState(i18next.t('PRODUCTION.PRODUCTION.EXPORT_IN_PROGRESS'));

  const exitModal = () => {
    setLoading(false);

    closeModal();
  };

  const exportReady = () => {
    setTitle(i18next.t('PRODUCTION.PRODUCTION.EXPORT_FINISHED'));
    setLoading(false);
  };

  const exportFailure = () => {
    setTitle(i18next.t('GENERAL.EXPORT_FAILURE'));
    setLoading(false);
  };

  const tiggerExport = async () => {
    if (!periodPickerState.startDate || !periodPickerState.endDate) {
      return;
    }

    await getPlannedProductions();

    setNbDaysLeftToFetch(periodPickerState.endDate.diff(periodPickerState.startDate, 'd'));

    setHasStartedExport(true);
  };

  const loadProducedQuantities = async (date) => {
    try {
      const fetchedRecipesProduction = await centralService.getRecipeProductionByStoreIdAndDate(
        selectedStore.id,
        date,
      );

      const plannedProductionsForSameDate = get(plannedProductions, `[${date}]`, []);

      if (!plannedProductionsForSameDate.length && !fetchedRecipesProduction.length) {
        setNbDaysLeftToFetch(nbDaysLeftToFetch - 1);
        return;
      }

      /*
        We get entityIds from both planned and produced because you can have something to produce and nothing produced on the
        same date for the same entity ( and vice versa ) but if you have nothing produced the entity will not appear in the fetchedRecipesProduction
        object, the same goes for plannedProductionsForSameDate so to have all the data we need to get the entityIds from both objet, merge them
        and iterate over them
      */
      const plannedProdEntityIds = plannedProductionsForSameDate.map(({ entityId }) => entityId);
      const prodEntityIds = fetchedRecipesProduction.map(({ entityId }) => entityId);

      const allEntityIds = plannedProdEntityIds.concat(prodEntityIds);
      const uniqEntityIds = uniq(allEntityIds);

      const formattedEvents = uniqEntityIds.map((entityId) => {
        const associatedEntity = get(recipesKeyById, `[${entityId}]`, {});

        const plannedForEntity = plannedProductionsForSameDate.find(
          ({ entityId }) => entityId === associatedEntity.id,
        );

        const producedForEntity = fetchedRecipesProduction.find(
          ({ entityId }) => entityId === associatedEntity.id,
        );

        return {
          entityId,
          storeName: selectedStore.name,
          category: associatedEntity.category || '',
          name: associatedEntity.name || '',
          produced: !!producedForEntity ? producedForEntity.quantity : 0,
          toProduce: !!plannedForEntity ? plannedForEntity.quantity : 0,
          unit: translateUnit(associatedEntity.unit),
          date,
        };
      });

      if (formattedEvents.length) {
        setRecipesProduction([...recipesProduction, ...formattedEvents]);
      }

      setNbDaysLeftToFetch(nbDaysLeftToFetch - 1);
    } catch {
      exportFailure();
    }
  };

  const getPlannedProductions = async () => {
    const formattedStartDate = periodPickerState.startDate.format(
      DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
    );
    const formattedEndDate = periodPickerState.endDate.format(
      DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
    );

    const fetchedPlannedProductions = await centralService.getProductionPlanning(
      selectedStore.id,
      formattedStartDate,
      formattedEndDate,
    );

    const formattedPlannedProductions = fetchedPlannedProductions.map((production) => ({
      ...production,
      productionDate: moment(production.productionDate)
        .tz(userTimezone)
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
    }));

    setPlannedProductions(groupBy(formattedPlannedProductions, 'productionDate'));
  };

  const exportData = (recipesProduction, aggregatedRecipesProduction) => {
    const recipeProductionSheet = {
      data: recipesProduction,
      title: i18next.t('PRODUCTION.PRODUCTION.EXPORT_RECIPES_PRODUCTION_SHEET_NAME'),
      headers: RECIPES_PRODUCTION_SHEET(storeName),
    };

    const aggregatedRecipesProductionSheet = {
      data: aggregatedRecipesProduction,
      title: i18next.t('PRODUCTION.PRODUCTION.EXPORT_AGGREGATED_RECIPES_PRODUCTION_SHEET_NAME'),
      headers: AGGREGATED_RECIPES_PRODUCTION_SHEET(storeName),
    };

    const informationsSheet = {
      data: [
        {
          storeName: selectedStore.name,
          startDate: periodPickerState.startDate,
          endDate: periodPickerState.endDate,
        },
      ],
      title: i18next.t('GENERAL.INFORMATIONS'),
      headers: INFORMATIONS_SHEET(storeName),
    };

    utilsXLS.makeXLS(i18next.t('PRODUCTION.PRODUCTION.EXPORT_FILE_NAME'), [
      utilsXLS.generateDefaultSheet(
        recipeProductionSheet.title,
        recipeProductionSheet.headers,
        recipeProductionSheet.data,
      ),
      utilsXLS.generateDefaultSheet(
        aggregatedRecipesProductionSheet.title,
        aggregatedRecipesProductionSheet.headers,
        aggregatedRecipesProductionSheet.data,
      ),
      utilsXLS.generateDefaultSheet(
        informationsSheet.title,
        informationsSheet.headers,
        informationsSheet.data,
      ),
    ]);

    return exportReady();
  };

  const formatDataForExport = () => {
    const sortedRecipesProduction = sortBy(recipesProduction, [
      'date',
      ({ name }) => name.toLowerCase(),
    ]);

    const aggregatedData = recipesProduction.reduce((acc, recipeProduction) => {
      if (!acc[recipeProduction.entityId]) {
        acc[recipeProduction.entityId] = {
          // We spread the object here to create a copy and not update the original objet
          ...recipeProduction,
          produced: recipeProduction.produced,
          toProduce: recipeProduction.toProduce,
        };

        return acc;
      }

      acc[recipeProduction.entityId].produced += recipeProduction.produced;
      acc[recipeProduction.entityId].toProduce += recipeProduction.toProduce;

      return acc;
    }, {});

    const sortedAggregatedData = sortBy(Object.values(aggregatedData), ({ name }) =>
      name.toLowerCase(),
    );

    exportData(sortedRecipesProduction, sortedAggregatedData);
  };

  useEffect(() => {
    if (!hasStartedExport) {
      return;
    }

    if (nbDaysLeftToFetch < 0) {
      formatDataForExport();
      return;
    }

    const datesDiff = periodPickerState.endDate.diff(periodPickerState.startDate, 'd');

    const nbDaysFetched = datesDiff - nbDaysLeftToFetch;

    const updatedProgress = (nbDaysFetched / datesDiff) * 100;

    setProgress(updatedProgress);

    const fetchDate = moment(periodPickerState.startDate)
      .add(nbDaysFetched, 'days')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    loadProducedQuantities(fetchDate);
  }, [hasStartedExport, nbDaysLeftToFetch]);

  if (hasStartedExport) {
    return (
      <ExportModalContent
        {...props}
        closeModal={closeModal}
        exitModal={exitModal}
        isLoading={isLoading}
        progress={progress}
        setLoading={setLoading}
        titleModal={title}
      />
    );
  }

  return (
    <div className={props.className}>
      <Container>
        <Header>
          <img height={16} src={'/images/inpulse/file-download-black-small.svg'} width={16} />
          <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.H1}>
            {i18next.t('PRODUCTION.PRODUCTION.EXPORT_MODAL_TITLE')}
          </Text>
          <img
            height={16}
            src={'/images/inpulse/close-black-small.svg'}
            width={16}
            onClick={closeModal}
          />
        </Header>
        <Content>
          <DatePickerContainer>
            <PeriodDatePicker
              endDate={periodPickerState.endDate}
              focusedDateInput={periodPickerState.focusedDateInput}
              maxFutureDate={today}
              setFocusedDateInput={periodPickerState.setFocusedDateInput}
              startDate={periodPickerState.startDate}
              timezone={userTimezone}
              onDatesChange={(selectedDates) => {
                periodPickerState.handleSelectedDates(selectedDates);
              }}
            />
          </DatePickerContainer>
        </Content>
        <Footer>
          <Button
            color={'inpulse-outline'}
            handleClick={exitModal}
            icon={'/images/inpulse/close-black-small.svg'}
            label={i18next.t('GENERAL.CANCEL')}
          />
          <Button
            color={'inpulse-default'}
            handleClick={tiggerExport}
            icon={'/images/inpulse/file-download-white-small.svg'}
            isDisabled={!periodPickerState.startDate || !periodPickerState.endDate}
            label={i18next.t('GENERAL.EXPORT')}
          />
        </Footer>
      </Container>
    </div>
  );
};

export default ProductionOperationExportModal;
