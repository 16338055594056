export const CHANNELS = {
  ON_SITE: 'Sur Place',
  DELIVERY: 'Livraison',
};

// TODO : create dedicated route to avoid storing ids in app
export const CHANNEL_ID_KEY_BY_NAME = {
  [CHANNELS.ON_SITE]: '9b6b0576-354c-11eb-b685-0a5bf521835e',
  [CHANNELS.DELIVERY]: '9b7b99b8-354c-11eb-b686-0a5bf521835e',
};
