import i18next from 'i18next';
import React from 'react';

import { getTheme } from '@commons/utils/theme';
import { Label } from '@commons/utils/styledLibraryComponents';
import { translateUnit } from '@commons/utils/translateUnit';
import Text, { ENUM_FONTS, ENUM_COLORS } from '@commons/Text';

import { SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING } from '@admin/cashier/cashierProducts/common/constants';

import { ContainerEllipsis, ListViewCellContent } from '../styledComponents';

const theme = getTheme();

export const get = () => [
  {
    id: '0',
    name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MAPPING_AUTO_MODAL_LIST_VIEW_COLUMN_SUPPLIER_PRODUCT'),
    type: 'string',
    propertyKey: 'name',
    render: (_, { name, packagingNames, isUnitCompatible }) => (
      <ListViewCellContent>
        <ContainerEllipsis>
          <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_SMALL_WEIGHT_700_HEIGHT_16_BOLD}>
            {name}
          </Text>
        </ContainerEllipsis>
        <Text color={!isUnitCompatible ? ENUM_COLORS.INFO_RED : ENUM_COLORS.DARKER} fontSize={10}>
          {packagingNames}
        </Text>
      </ListViewCellContent>
    ),
  },
  {
    id: '1',
    name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MAPPING_AUTO_MODAL_LIST_VIEW_COLUMN_SUPPLIER'),
    type: 'string',
    propertyKey: 'supplierName',
  },
  {
    id: '2',
    name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MAPPING_AUTO_MODAL_LIST_VIEW_COLUMN_RELEVANCY'),
    type: 'numeric',
    propertyKey: 'relevance',
    render: (score) => {
      // We use floor and not round because we don't want a score of 100 if it's not totally equal
      const formattedScore = score ? Math.floor(score) : 0;

      const isRelevant = formattedScore >= SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING;

      return (
        <Label
          background={isRelevant ? theme.colors.brand.secondary : theme.colors.greys.lighty}
          color={isRelevant ? theme.colors.greys.darkest : theme.colors.greys.lightest}
          type={'plain'}
          width={'fit-content'}
        >
          {formattedScore}
        </Label>
      );
    },
  },
  {
    id: '3',
    name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MAPPING_AUTO_MODAL_LIST_VIEW_COLUMN_CATEGORY'),
    type: 'string',
    propertyKey: 'category',
  },
  {
    id: '4',
    name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MAPPING_AUTO_MODAL_LIST_VIEW_COLUMN_SUB_CATEGORY'),
    type: 'string',
    propertyKey: 'subCategory',
  },
  {
    id: '5',
    name: i18next.t(
      'ADMIN.SUPPLIER_PRODUCTS.MAPPING_AUTO_MODAL_LIST_VIEW_COLUMN_ASSOCIATED_INGREDIENT',
    ),
    type: 'string',
    propertyKey: 'entityName',
    render: (_, { isUnitCompatible, entity: { name, unit } }) => (
      <ListViewCellContent>
        <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_SMALL_WEIGHT_700_HEIGHT_16_BOLD}>
          {name}
        </Text>
        <Text color={!isUnitCompatible ? ENUM_COLORS.INFO_RED : ENUM_COLORS.DARKER} fontSize={10}>
          {translateUnit(unit)}
        </Text>
      </ListViewCellContent>
    ),
  },
];

export default { get };
