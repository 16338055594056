import i18next from 'i18next';

export const STORE_DUPLICATION_TYPE = {
  EVENTS: 'EVENTS',
  PRODUCTION: 'PRODUCTION',
  PRODUCT_MIX: 'PRODUCT_MIX',
};

export const CLONE_STORE_STEP = {
  STORE_SELECTION: 'STORE_SELECTION',
  VALIDATION: 'VALIDATION',
};

const getStoreSettingsCardSettings = (storeSettings) => {
  const inputs = [
    {
      dropdownValue: null,
      hasTooltip: false,
      isDropdown: false,
      isRequired: false,
      isSwitch: false,
      isTextContent: true,
      textContent: i18next.t('BACKOFFICE.STORES.DUPLICATION_DESCRIPTION'),
      propertyKey: 'lastMenuDate',
    },
    {
      icon: '/images/inpulse/clone-black.svg',
      isCloneButton: true,
      label: i18next.t('BACKOFFICE.STORES.LIST_ACTION_CLONE_PRODUCTION'),
      link: '',
      propertyKey: STORE_DUPLICATION_TYPE.PRODUCTION,
    },
    {
      icon: '/images/inpulse/clone-black.svg',
      isCloneButton: true,
      label: i18next.t('BACKOFFICE.STORES.LIST_ACTION_CLONE_PRODUCT_MIX'),
      link: '',
      propertyKey: STORE_DUPLICATION_TYPE.PRODUCT_MIX,
    },
    {
      icon: '/images/inpulse/clone-black.svg',
      isCloneButton: true,
      label: i18next.t('BACKOFFICE.STORES.LIST_ACTION_CLONE_EVENTS'),
      link: '',
      propertyKey: STORE_DUPLICATION_TYPE.EVENTS,
    },
  ];
  return {
    title: i18next.t('BACKOFFICE.STORES.SETTINGS'),
    inputs: inputs.reduce((acc, input) => {
      acc.push({ ...input, value: storeSettings[input.propertyKey] });
      return acc;
    }, []),
  };
};

export default getStoreSettingsCardSettings;
