import { get, isEmpty } from 'lodash';
import i18next from 'i18next';

import { computeTypesOfPackaging, convertPriceToPackagingById } from '@commons/utils/packagings';
import { flattenArray } from '@commons/utils/format';
import { getUserTimezone } from '@commons/utils/date';
import mixpanelUtils, { ENUM_EVENTS } from '@commons/utils/mixpanel';

import { PRODUCT_ORDER_TYPE } from '@orders/OrderList/constants';

import ORDER_STATUS from '../constants/status';
import services from '../services';

import getEmailListFromString from './getEmailListFromString';
import getOrderEmailBody from './getOrderEmailBody';
import getOrderInfos from './getOrderInfos';

// THiS SHOULD BE UPDATED SOME DAY
const getStatusOrderFromProductOrders = (productOrders) => {
  const hasAnomaly = productOrders.some((product) => {
    const { packagingUsedInOrder, packagingUsedInReception, packagingUsedInInvoice } =
      computeTypesOfPackaging(product.receptionPackagingId, product.packagings);

    const invoicedPrice = convertPriceToPackagingById(
      product.price,
      packagingUsedInOrder.id,
      packagingUsedInInvoice.id,
      product.packagings,
    );

    const convertedPriceBDL = convertPriceToPackagingById(
      product.priceBDL,
      packagingUsedInReception.id,
      packagingUsedInInvoice.id,
      product.packagings,
    );

    const receivedDifferentFromInvoiced = product.received != product.invoiced;

    const totalInvoiceDifferentFromTotalReceived =
      Math.round(invoicedPrice * 100) / 100 !== Math.round(convertedPriceBDL * 100) / 100;

    return receivedDifferentFromInvoiced || totalInvoiceDifferentFromTotalReceived;
  });

  if (hasAnomaly) {
    return ORDER_STATUS.NON_COMPLIANT;
  }

  const isIncomplete = productOrders.some((product) => {
    // Added reference with no anomaly triggers INCOMPLETE status
    if (product.addedAfterOrdered) {
      return true;
    }

    // Reference removed from BDC by user after sending it to supplier triggers INCOMPLETE status
    return product.invoiced === 0 && product.received === 0;
  });

  if (isIncomplete) {
    return ORDER_STATUS.INCOMPLETE;
  }

  return ORDER_STATUS.COMPLIANT;
};

export const getFormattedProductOrders = (productOrders, orderId) =>
  productOrders.reduce((result, product) => {
    const { packagingUsedInReception } = computeTypesOfPackaging(
      product.receptionPackagingId,
      product.packagings,
    );

    const receptionProductPrice = product.priceBDL != null ? product.priceBDL : product.price;

    const payload = {
      orderId,
      checked: product.checked,
      originalPrice: product.price,
      price: receptionProductPrice,
      supplierProductId: product.id,
      receptionAnomaly: product.receptionAnomaly,
      supplierProductPackagingId: packagingUsedInReception.id,
    };

    // Received product order
    result.push({
      ...payload,
      quantity: product.received,
      type: PRODUCT_ORDER_TYPE.RECEIVED,
    });

    // Invoiced product order
    result.push({
      ...payload,
      quantity: product.invoiced,
      type: PRODUCT_ORDER_TYPE.INVOICED,
    });

    return result;
  }, []);

const _handleMixPanelEvent = (clientId, clientName) => {
  const mixPanelProperties = {
    clientId,
    clientName,
  };

  mixpanelUtils.sendMetric(
    ENUM_EVENTS.UPDATE_SUPPLIER_PRODUCT_PRICE_FROM_ORDER_RECEIPT,
    mixPanelProperties,
  );
};

const sendValidatedDelivery = async (
  {
    cc,
    user,
    params,
    orderId,
    storeId,
    sending,
    endDate,
    picturesUris,
    startDate,
    partnerId,
    storeInfos,
    supplierId,
    showMessage,
    sendingParams,
    supplierInfos,
    receptionComment,
    orderOpeningTime,
    productsInventory,
    receptionReference,
    isDeliveryDateUpdated,
    clientName,
    creditRequestData,
    creditPictureUri,
  },
  isDraftReception,
  withMail = true,
) => {
  if (!productsInventory.length) {
    return;
  }

  const flattenProductOrders = flattenArray(productsInventory.map(({ products }) => products));

  const orderedProductOrdersSPToBeUpdated = flattenProductOrders.filter(
    ({ hasOverwrittenOrderedProductPrice }) => hasOverwrittenOrderedProductPrice,
  );

  const productOrders = getFormattedProductOrders(flattenProductOrders, orderId);

  const productOrdersSPToBeUpdated = orderedProductOrdersSPToBeUpdated.map(({ id, price }) => ({
    supplierProductId: id,
    price,
  }));

  const status = isDraftReception
    ? ORDER_STATUS.RECEPTION
    : getStatusOrderFromProductOrders(flattenProductOrders);

  const initialOrderStatus = get(params, 'order.status');
  const hasOrderStatusChanged = status !== +initialOrderStatus;

  const purchaseOrderExistingData = {
    purchaseOrderRecipients: get(params, 'order.purchaseOrderRecipients', ''),
    purchaseOrderCopy: get(params, 'order.purchaseOrderCopy', ''),
    purchaseOrderComment: get(params, 'order.purchaseOrderComment', ''),
  };

  const order = {
    ...getOrderInfos({
      status,
      storeId,
      params,
      orderId,
      endDate,
      startDate,
      partnerId,
      supplierId,
      savingType: 'afterDelivery',
      isEditable: false,
      receptionComment,
      orderOpeningTime,
      productsInventory,
      receptionReference,
      isDeliveryDateUpdated,
      storeTimezone: storeInfos.timezone,
      purchaseOrderComment: purchaseOrderExistingData.purchaseOrderComment,
      emailRecipients: purchaseOrderExistingData.purchaseOrderRecipients,
      emailRecipientsCopy: purchaseOrderExistingData.purchaseOrderCopy,
      creditRequestData,
      creditPictureUri,
    }),
    pictures: picturesUris,
  };

  await services.patchOrder(
    order,
    productOrders,
    isDeliveryDateUpdated,
    productOrdersSPToBeUpdated,
  );

  if (productOrdersSPToBeUpdated.length) {
    _handleMixPanelEvent(get(user, 'clientId', null), clientName);
  }

  if (
    (isDeliveryDateUpdated && !hasOrderStatusChanged) || // When changing dates on an order with status Non Compliant
    (isDeliveryDateUpdated && initialOrderStatus === String(ORDER_STATUS.CREDIT_REQUEST_PROCESSED)) // When changing dates on an order with status CRP
  ) {
    return i18next.t('ORDERS.ORDERS.CHANGE_DELIVERY_DATE_SUCCESS_MESSAGE');
  }

  const credit = get(sendingParams, 'email.credit');

  if (status !== ORDER_STATUS.NON_COMPLIANT || !(sending === 'email' && !isEmpty(credit))) {
    return i18next.t('ORDERS.ORDERS.FORM_SAVED_SUCCESSFULLY');
  }

  const userTimezone = getUserTimezone();

  const emailSuccessfullySent = await services.sendOrderByEmail(
    get(user, 'id'),
    storeId,
    supplierId,
    order.id,
    creditRequestData.recipientEmails,
    getEmailListFromString(creditRequestData.copyEmails),
    credit.default_bcc,
    getOrderEmailBody(credit.default_body, user, storeInfos, clientName),
    creditPictureUri,
    userTimezone,
    withMail,
  );

  if (!withMail) {
    return i18next.t('ORDERS.ORDERS.FORM_CREDIT_REQUEST_SAVE_SUCCESSFULLY');
  }

  if (!emailSuccessfullySent) {
    await services.cancelOrderReception(order.id, showMessage);

    throw new Error('Email could not be sent');
  }

  return i18next.t('ORDERS.ORDERS.FORM_CREDIT_REQUEST_SENT_SUCCESSFULLY');
};

export default sendValidatedDelivery;
