import _ from 'lodash';
import React from 'react';

import SupplierProfileModalProperties from '../../../SupplierProfileModal/SupplierProfileModalProperties';

const SetSupplierProductsPropertiesModal = (props) => {
  const { supplierProductsPropertiesData, setSupplierProductsPropertiesData } = props;

  const handleSwitchChange = (name) => {
    const concernedProperty = _.get(supplierProductsPropertiesData, name, true);

    setSupplierProductsPropertiesData({
      ...supplierProductsPropertiesData,
      [name]: !concernedProperty,
    });
  };

  return (
    <SupplierProfileModalProperties
      available={_.get(supplierProductsPropertiesData, 'available', true)}
      handleSwitchChange={(name) => handleSwitchChange(name)}
      hasDlc={_.get(supplierProductsPropertiesData, 'hasDlc', true)}
      hasStock={_.get(supplierProductsPropertiesData, 'hasStock', true)}
    />
  );
};

export default SetSupplierProductsPropertiesModal;
