import { isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getTheme } from '@commons/utils/theme';
import { Tooltip } from '@commons/utils/styledLibraryComponents';
import { TooltipIcon } from '@commons/Metrics/styledComponents';
import EmptyState from '@commons/EmptyState';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import TimePropertiesLineGraph from '@commons/TimePropertiesLineGraph';

import { Container, TitleContainer } from './styledComponents';
import { tooltipHandler } from './components/TooltipHandler';
import config from './utils/config';

const TurnoverRealConsumptionGraph = (props) => {
  const { data, currency, screenWidth } = props;

  const theme = getTheme();

  const graphData = data.reduce(
    (result, { date, turnoverWithoutTaxes, realConsumptionPercentage }) => {
      result.x.push(date);

      result.y.push(turnoverWithoutTaxes);

      result.percentage.push(realConsumptionPercentage);

      return result;
    },
    {
      x: [],
      y: [],
      percentage: [],
    },
  );

  const getNextWeekDate = (titleKey, data) => {
    const matchingDataIndex = data.findIndex(({ date }) => date === titleKey);
    if (matchingDataIndex < 0) {
      return null;
    }

    // If displaying tooltip for last available date, the next week is not available in the datasets,
    // so compute what date it will be by adding 1 week
    if (matchingDataIndex === data.length - 1) {
      return moment(titleKey).add(1, 'week');
    }

    const nextDate = data[matchingDataIndex + 1].date;
    return moment(nextDate);
  };

  const isGraphEmpty = isEmpty(graphData.x) || isEmpty(graphData.y);

  return (
    <Container>
      <TitleContainer>
        <Tooltip
          displayBigger={true}
          renderTooltipTrigger={() => <TooltipIcon src={'/images/inpulse/info-dmgrey-small.svg'} />}
          text={i18next.t('DASHBOARD.FOODCOST.GRAPH_EVOLUTION_TOOLTIP')}
        />
        <Text
          className={'title-text'}
          color={ENUM_COLORS.DARKER}
          font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {i18next.t('DASHBOARD.FOODCOST.GRAPH_EVOLUTION_TITLE')}
        </Text>
      </TitleContainer>

      {!isGraphEmpty ? (
        <TimePropertiesLineGraph
          config={config}
          granularity={'week'}
          legendConfig={{
            display: true,
            position: 'bottom',
            labels: [
              {
                color: theme.colors.blue,
                text: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_REVENUE'),
              },
              {
                color: theme.colors.green.lighter,
                text: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_PERCENT_ACTUAL_FC'),
              },
            ],
          }}
          screenWidth={screenWidth}
          tooltipHandler={(context) =>
            tooltipHandler(
              {
                ...context,
                getTooltipTitleKey: (data) =>
                  moment(data).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
                getTooltipTitleValue: (titleKey, data) => {
                  const nextWeekDate = getNextWeekDate(titleKey, data);

                  const title = !nextWeekDate
                    ? titleKey
                    : `${moment(titleKey).format(
                        DATE_DISPLAY_FORMATS.DATE_MONTH_YEAR,
                      )} - ${nextWeekDate.format(DATE_DISPLAY_FORMATS.DATE_MONTH_YEAR)}`;

                  return title;
                },
              },
              data,
              currency,
            )
          }
          x={{ data: graphData.x }}
          y={{
            y: {
              label: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_REVENUE'),
              borderColor: theme.colors.blue,
              backgroundColor: '3, 155, 229',
              data: graphData.y,
            },
            percentage: {
              label: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_PERCENT_ACTUAL_FC'),
              borderColor: theme.colors.green.lighter,
              backgroundColor: '0, 234, 132',
              data: graphData.percentage,
            },
          }}
        />
      ) : (
        <EmptyState
          backgroundColor={theme.colors.white}
          label={i18next.t('DASHBOARD.FOODCOST.EMPTY_STATE_LABEL')}
        />
      )}
    </Container>
  );
};

TurnoverRealConsumptionGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      turnoverWithoutTaxes: PropTypes.number,
      realConsumption: PropTypes.number,
      realConsumptionPercentage: PropTypes.number,
      marginWithoutTaxes: PropTypes.number,
      marginWithoutTaxesPercentage: PropTypes.number,
    }).isRequired,
  ),
};

export default TurnoverRealConsumptionGraph;
