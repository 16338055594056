import styled from 'styled-components';

/**
 * Might be implemented as a common styled component
 * Maybe add a prop to adapt the Top padding depending on the presence of
 * sub-pages in the NavigationBar.
 */
export const PageContentContainer = styled.div`
  width: 100%;
  height: 100vh;
`;
