// ===== Module features for easy import in ClientContainer =====

import InventoryContainer from './components/InventoryContainer';
import ProductionAnalyticsModule from './components/AnalyticsContainer';
import ProductionContainer from './components/ProductionContainer';

const ProductionModule = {
  ProductionContainer,
  InventoryContainer,
  ...ProductionAnalyticsModule,
};

export default ProductionModule;
