import { head, isEmpty } from 'lodash';
import moment from 'moment-timezone';

export const getYesterdayStock = (entityStock, storeTimezone) =>
  !isEmpty(entityStock)
    ? head(
        entityStock.filter(
          ({ date }) =>
            moment.tz(date, storeTimezone).format('L') ===
            moment.tz(storeTimezone).subtract(1, 'days').format('L'),
        ),
      )
    : {};

export const getNoInventoryMoreThanThirtyDays = (entityStock) =>
  !!entityStock
    ? !entityStock.some(
        ({ realStockUnit, inventoryListId }) => realStockUnit != null || !!inventoryListId,
      )
    : false;
