import { getFeaturesByPath, ENUM_MODULE_NAME } from '../../../commons/utils/features';
import React, { useEffect } from 'react';

const MODULE_FEATURES_PATH_ORDERED = [
  '/backoffice/stores/stores',
  '/backoffice/stores/cashier-stores',
  '/backoffice/stores/locations',
  '/backoffice/stores/groups',
];

export const BackOfficeStoresContainer = (props) => {
  const { userRights, history } = props;

  useEffect(() => {
    const features = getFeaturesByPath(userRights, ENUM_MODULE_NAME.BACKOFFICE);
    if (features.length) {
      const redirectionPath = MODULE_FEATURES_PATH_ORDERED.find((featurePath) =>
        features.some((feature) => feature.path === featurePath),
      );
      history.push(redirectionPath);
    }
  }, []);

  return <div></div>;
};

// ===== Module features for easy import in ClientContainer =====

import BackOfficeCashierStoresDetail from './BackOfficeCashierStoresManagement/details';
import BackOfficeCashierStoresManagement from './BackOfficeCashierStoresManagement';
import BackOfficeGroups from './BackOfficeGroupsManagement';
import BackOfficeStores from './BackOfficeStoresManagement';
import BackOfficeStoresDetails from './BackOfficeStoresManagement/details';
import BackOfficeStoresLocations from './BackOfficeStoresLocations';

const BackOfficeStoresModules = {
  BackOfficeGroups,
  BackOfficeStores,
  BackOfficeStoresDetails,
  BackOfficeStoresLocations,
  BackOfficeStoresContainer,
  BackOfficeCashierStoresDetail,
  BackOfficeCashierStoresManagement,
};

export default BackOfficeStoresModules;
