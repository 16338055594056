import { get } from 'lodash';

import centralKitchenProductsXlsConstants from '@commons/Excels/constants/centralKitchenProducts.xlsx.constant';
import importTypeXlsConstants from '@commons/Excels/constants/importType.xslx.constant';
import productsXlsConstants from '@commons/Excels/constants/products.xlsx.constant';

import { mapColumnIdsAndNormalizedColumnIds, normalizeJsonObject } from '@backoffice/utils';

export const formatCentralKitchenProductBatchCreationFile = (centralKitchenProductsWorkbook) => {
  const normalizedCentralKitchenProductsWorkbook = normalizeJsonObject(
    centralKitchenProductsWorkbook,
  );

  return Object.keys(normalizedCentralKitchenProductsWorkbook).reduce((acc, sheetName) => {
    if (!acc[importTypeXlsConstants.KEY]) {
      acc[importTypeXlsConstants.KEY] = get(
        normalizedCentralKitchenProductsWorkbook,
        'ImportType[0].importtype',
        null,
      );
    }

    if (sheetName === centralKitchenProductsXlsConstants.SHEET.NAME) {
      acc[centralKitchenProductsXlsConstants.SHEET.ID] = normalizedCentralKitchenProductsWorkbook[
        sheetName
      ].map((row) => ({
        ...mapColumnIdsAndNormalizedColumnIds(row, centralKitchenProductsXlsConstants.COLUMN_IDS),
        [centralKitchenProductsXlsConstants.COLUMN_IDS.PRICE.key]: get(
          row,
          `${centralKitchenProductsXlsConstants.COLUMN_IDS.PRICE.normalizedKey}`,
          0,
        ),
        [centralKitchenProductsXlsConstants.COLUMN_IDS.VAT_RATE.key]: get(
          row,
          `${centralKitchenProductsXlsConstants.COLUMN_IDS.VAT_RATE.normalizedKey}`,
          0,
        ),
      }));
    }

    // Category
    if (sheetName === productsXlsConstants.HIDDEN_SHEET_CATEGORY.NAME) {
      acc[productsXlsConstants.HIDDEN_SHEET_CATEGORY.ID] = normalizedCentralKitchenProductsWorkbook[
        sheetName
      ].map((row) => ({
        [productsXlsConstants.HIDDEN_CATEGORY_COLUMN_ID.NAME]:
          row[productsXlsConstants.HIDDEN_CATEGORY_NORMALIZE_COLUMN_ID.NAME],
      }));
    }

    // Subcategory
    if (sheetName === productsXlsConstants.HIDDEN_SHEET_SUB_CATEGORY.NAME) {
      acc[productsXlsConstants.HIDDEN_SHEET_SUB_CATEGORY.ID] =
        normalizedCentralKitchenProductsWorkbook[sheetName].map((row) => ({
          [productsXlsConstants.HIDDEN_SUB_CATEGORY_COLUMN_ID.NAME]:
            row[productsXlsConstants.HIDDEN_SUB_CATEGORY_NORMALIZE_COLUMN_ID.NAME],
        }));
    }

    // Associated ingredient
    if (sheetName === centralKitchenProductsXlsConstants.HIDDEN_SHEET_ASSOCIATED_INGREDIENT.NAME) {
      acc[centralKitchenProductsXlsConstants.HIDDEN_SHEET_ASSOCIATED_INGREDIENT.ID] =
        normalizedCentralKitchenProductsWorkbook[sheetName].map((row) => ({
          [centralKitchenProductsXlsConstants.HIDDEN_ASSOCIATED_INGREDIENT_COLUMN_IDS.ID]:
            row[
              centralKitchenProductsXlsConstants.HIDDEN_ASSOCIATED_INGREDIENT_NORMALIZE_COLUMN_IDS
                .ID
            ],
          [centralKitchenProductsXlsConstants.HIDDEN_ASSOCIATED_INGREDIENT_COLUMN_IDS.NAME]: row[
            centralKitchenProductsXlsConstants.HIDDEN_ASSOCIATED_INGREDIENT_NORMALIZE_COLUMN_IDS
              .NAME
          ].replace(/\s/g, ' '),
          [centralKitchenProductsXlsConstants.HIDDEN_ASSOCIATED_INGREDIENT_COLUMN_IDS.UNIT]:
            row[
              centralKitchenProductsXlsConstants.HIDDEN_ASSOCIATED_INGREDIENT_NORMALIZE_COLUMN_IDS
                .UNIT
            ],
        }));
    }

    // Associated recipe
    if (sheetName === centralKitchenProductsXlsConstants.HIDDEN_SHEET_ASSOCIATED_RECIPE.NAME) {
      acc[centralKitchenProductsXlsConstants.HIDDEN_SHEET_ASSOCIATED_RECIPE.ID] =
        normalizedCentralKitchenProductsWorkbook[sheetName].map((row) => ({
          [centralKitchenProductsXlsConstants.HIDDEN_ASSOCIATED_RECIPE_COLUMN_IDS.ID]:
            row[
              centralKitchenProductsXlsConstants.HIDDEN_ASSOCIATED_RECIPE_NORMALIZE_COLUMN_IDS.ID
            ],
          [centralKitchenProductsXlsConstants.HIDDEN_ASSOCIATED_RECIPE_COLUMN_IDS.NAME]: row[
            centralKitchenProductsXlsConstants.HIDDEN_ASSOCIATED_RECIPE_NORMALIZE_COLUMN_IDS.NAME
          ].replace(/\s/g, ' '),
          [centralKitchenProductsXlsConstants.HIDDEN_ASSOCIATED_RECIPE_COLUMN_IDS.UNIT]:
            row[
              centralKitchenProductsXlsConstants.HIDDEN_ASSOCIATED_RECIPE_NORMALIZE_COLUMN_IDS.UNIT
            ],
        }));
    }

    return acc;
  }, {});
};
