/**
 * Parse a string value into a float and return NULL if this value could not be parsed
 *
 * @param {string} value  - The value to parse
 *
 * @return {number|null} The parsed value into a number, NULL if parsed failed
 */
export function parseStringToFloat(value) {
  if (!value) {
    return null;
  }

  const parsedValue = parseFloat(value);

  if (Number.isNaN(parsedValue)) {
    return null;
  }

  return parsedValue;
}

export default {
  parseStringToFloat,
};
