import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const IconActive = styled.img`
  width: 12px;
  height: 12px;

  position: absolute;
  top: -5px;
  right: -5px;
`;
