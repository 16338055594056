import styled, { css, keyframes } from 'styled-components';

const gradient = keyframes`
  0% { opacity: 0.6; } 
  50% { opacity: 1; }
  100% { opacity: 0.6; } 
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  overflow: hidden;
`;

export const ContainerList = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;

  min-width: ${(props) => `${props.minWidth}px`};

  background-color: ${(props) => props.theme.content.backgroundColor};
  border-radius: 4px;
`;

export const ContainerView = styled.div`
  padding: ${(props) => props.padding};

  height: ${(props) =>
    props.fullHeight ? '100%' : 'calc(100% - 16px)'}; // bottom bar pagination
  overflow-x: scroll;
`;

export const ContentContainer = styled.div`
  position: relative;
  height: ${({ theme }) => theme.content.height};

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `};
`;

export const CheckBoxContainer = styled.div`
  height: 100%;
  padding: 10px 0px 10px 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  box-sizing: border-box;
`;

export const IconButton = styled.button`
  border-radius: 4px;
  background: white;
  cursor: pointer;
  border: none;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: -14px;

  height: 40px;
  width: 40px;
  border-radius: 20px;

  &:hover {
    background: ${(props) =>
      props.theme.item?.inLineAction?.hover?.backgroundColor};
  }

  background: ${(props) =>
    props.selected
      ? props.theme.item?.inLineAction?.selected?.backgroundColor
      : props.theme.item?.inLineAction?.backgroundColor};
`;

export const Icon = styled.img`
  ${(props) =>
    props.rotated &&
    css`
      transform: rotate(180deg);
    `};
`;

export const LoadingRowItem = styled.div`
  width: 100%;
  height: 16px;

  border: ${(props) => props.theme.item?.loading?.border};
  border-radius: ${(props) => props.theme.item?.loading?.borderRadius};
  background: ${(props) => props.theme.item?.loading?.backgroundColor};

  ${(props) =>
    props.fadeInOut &&
    css`
      animation: ${gradient} 1.5s linear infinite;
    `};
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  min-height: 60px;
  margin: 8px 0px;
  &:first-child {
    margin-top: 0px;
  }

  height: 50px;

  background: ${(props) => props.theme.item?.default?.backgroundColor};
  border: ${(props) =>
    props.hovered ? props.theme.item?.selected?.border : '1px solid #e2e2e2'};

  box-shadow: ${(props) => props.theme.item?.boxShadow};
  border-radius: ${(props) => props.theme.item?.borderRadius};

  box-sizing: border-box;

  ${(props) =>
    props.highlighted &&
    css`
      background: ${props.theme.item?.selected?.backgroundColor};
      border: ${props.theme.item?.selected?.border};
      padding: props.hasCheckbox ? '9px 9px' : '14px 19px';
    `};

  ${(props) =>
    props.hoverable &&
    css`
      &:hover {
        background: ${props.theme.item?.hover?.backgroundColor};
        border: ${props.highlighted
          ? props.theme.item?.selected?.border
          : props.theme.item?.hover?.border};
      }
    `};

  ${(props) =>
    props.clickable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `};
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  flex: ${(props) => (props.large ? 2 : 1)};

  min-height: 60px;
  height: 100%;
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : '0px')};

  ${(props) =>
    props.narrow &&
    css`
      flex: 0;
      flex-basis: props.minWidth ? ${props.minWidth}px : auto;
      justify-content: flex-end;
    `};

  padding: ${(props) => (props.narrowSidePadding ? '0px 5px' : '0px 15px')};

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  &:hover {
    cursor: ${(props) => props.enableCursorPointer && 'pointer'};
    fill: #0a3eff;
    ${(props) =>
      props.allowHoverColorChange &&
      props.theme &&
      css`
        > div > div > div > div > div {
          background: ${props.theme.primary.pressed};
        }
      `}
  }
`;

export const HeaderItem = styled.div`
  min-width: 0; // to allow text-overflow: ellipsis in child
  display: flex;
  align-items: center;

  &:hover {
    cursor: ${(props) => props.enableCursorPointer && 'pointer'};
    fill: #0a3eff;
  }
`;

export const Item = styled.div`
  padding: 0px;
  width: 100%;
  min-width: fit-content;

  text-overflow: ellipsis;
  white-space: pre-wrap;
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  font: ${(props) => props.theme.item?.font};
`;

export const ActionItem = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight}px;
    `};
  ${(props) =>
    props.marginLeft &&
    css`
      margin-left: ${props.marginLeft}px;
    `};
`;

export const HeaderText = styled.div`
  margin-right: 6px;

  font: ${(props) =>
    props.blue
      ? props.theme.header?.selected?.font
      : props.theme.header?.default?.font};

  color: ${(props) =>
    props.blue
      ? props.theme.header?.selected?.color
      : props.theme.header?.default?.color};
`;

export const EmptyStateContainer = styled.div`
  height: 100%;
  margin: auto;
  position: relative;
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ActionsContainer = styled.div`
  display: flex;

  > div:not(:last-child) {
    margin-right: 25px;
  }
`;

export const ActionsInLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > div:not(:last-child) {
    margin-right: 25px;
  }
`;

export const OtherActionsContainer = styled.div``;

export const DisplayNameContainer = styled.div`
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const WhiteSpaceRadioButton = styled.div`
  width: 40px;
`;

export const WhiteSpaceCheckbox = styled.div`
  width: 42px;
`;

export default {
  Container,
  ContainerList,
  ContainerView,
  Header,
  IconButton,
  Icon,
  LoadingRowItem,
  Box,
  ItemContainer,
  HeaderItem,
  Item,
  ActionItem,
  HeaderText,
  EmptyStateContainer,
  OptionsContainer,
  ActionsContainer,
  ActionsInLineContainer,
  OtherActionsContainer,
  DisplayNameContainer,
  WhiteSpaceCheckbox,
  WhiteSpaceRadioButton,
  CheckBoxContainer,
};
