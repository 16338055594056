import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { Props } from './interfaces';
import { getTheme } from '../utils/theme';
import { Dot, Progress, ProgressBarContainer, Text } from './styledComponents';
import DataTooltip from '../DataTooltip';

const ProgressBar = ({
  className,
  customStyle,
  theme,
  variation,
  percentage,
  maxPercentage,
  renderTooltipContent,
  withPercentage,
  withGradient,
  withDot,
}: Props): JSX.Element => {
  const updatedTheme = getTheme(theme);

  const refinePercentage = (value: number): number => {
    if (value > 100) {
      return 100;
    }

    if (value < 0) {
      return 0;
    }

    return value;
  };
  const formatPercentage = (value: number): number => {
    return parseFloat(value.toFixed(2));
  };

  const percentageUsedForWidth = refinePercentage(
    (percentage * 100) / maxPercentage
  );

  const displayedPercentage = formatPercentage(percentage);

  return (
    <ThemeProvider theme={updatedTheme}>
      <ProgressBarContainer className={className} style={customStyle}>
        <DataTooltip renderContent={renderTooltipContent}>
          <Progress
            percentage={percentageUsedForWidth}
            variation={variation}
            withGradient={withGradient}
          />
          {withDot && (
            <Dot percentage={percentageUsedForWidth} variation={variation} />
          )}
          {withPercentage && (
            <Text
              percentage={percentageUsedForWidth}
              displayedPercentage={displayedPercentage}
            >
              {displayedPercentage}%
            </Text>
          )}
        </DataTooltip>
      </ProgressBarContainer>
    </ThemeProvider>
  );
};

ProgressBar.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  customStyle: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.objectOf(PropTypes.any),
  variation: PropTypes.oneOf(['positive', 'negative']),
  percentage: PropTypes.number,
  maxPercentage: PropTypes.number,
  renderTooltipContent: PropTypes.func,
  withPercentage: PropTypes.bool,
  withGradient: PropTypes.bool,
  withDot: PropTypes.bool,
};

ProgressBar.defaultProps = {
  className: '',
  customStyle: {},
  theme: null,
  variation: 'positive',
  percentage: 0,
  maxPercentage: 100,
  renderTooltipContent: null,
  withPercentage: false,
  withGradient: false,
  withDot: false,
};

export default ProgressBar;
