import i18next from 'i18next';

const PRODUCTION_STOCK_LOSS_CONVENTION = [
  { id: 'before_opening', name: 'Matin avant ouverture' },
  { id: 'after_closing', name: 'Soir après fermeture' },
];

const getStoreProductionCardSettings = (storeProduction, isKitchenStoreForm) => {
  const inputs = [
    {
      hasTooltip: true,
      emptyIcon: 'none',
      isDropdown: false,
      isRequired: false,
      label: i18next.t('BACKOFFICE.STORES.CREATION_MODAL_CARD_PRODUCTION_PROD_WASTE_RATE'),
      placeholder: i18next.t('BACKOFFICE.STORES.CREATION_MODAL_CARD_PRODUCTION_PROD_WASTE_RATE'),
      propertyKey: 'targetProductionWasteRate',
      type: 'number',
      tooltipText: i18next.t(
        'BACKOFFICE.STORES.CREATION_MODAL_CARD_PRODUCTION_PROD_WASTE_RATE_TOOLTIP',
      ),
    },
    {
      dropdownValue: PRODUCTION_STOCK_LOSS_CONVENTION,
      hasTooltip: true,
      isDropdown: true,
      dropdownMatchingProperty: 'id',
      isRequired: !isKitchenStoreForm, // Required if creating "point of sale" type store
      isSwitch: false,
      isMultipleChoice: false,
      icon: 'none',
      emptyIcon: 'none',
      iconWidth: 0,
      label: i18next.t('GENERAL.STOCK_CONVENTION'),
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      propertyKey: 'productionStockConvention',
      type: 'dropdown',
      customZIndex: '4',
      tooltipText: i18next.t('GENERAL.STOCK_CONVENTION_PRODUCTION'),
    },
    {
      dropdownValue: PRODUCTION_STOCK_LOSS_CONVENTION,
      hasTooltip: true,
      isDropdown: true,
      dropdownMatchingProperty: 'id',
      isRequired: !isKitchenStoreForm, // Required if creating "point of sale" type store
      isSwitch: false,
      isMultipleChoice: false,
      icon: 'none',
      emptyIcon: 'none',
      iconWidth: 0,
      label: i18next.t('GENERAL.LOSS_CONVENTION'),
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      propertyKey: 'productionLossConvention',
      type: 'dropdown',
      customZIndex: '4',
      tooltipText: i18next.t('GENERAL.LOSS_CONVENTION_PRODUCTION'),
    },
  ];

  return {
    title: i18next.t('BACKOFFICE.STORES.CREATION_MODAL_CARD_PRODUCTION_TITLE'),
    inputs: inputs.reduce((acc, input) => {
      acc.push({ ...input, value: storeProduction[input.propertyKey] });
      return acc;
    }, []),
  };
};

export default getStoreProductionCardSettings;
