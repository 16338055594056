import i18next from 'i18next';

import { getClientStoreNameTranslation } from '@commons/utils/translations';

export default ({ isCentral, storeName, displayPackagingWeight, displayInvoiceColumns }) => {
  const columns = [
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_REFERENCE'),
      propertyKey: 'reference',
    },
    {
      name: getClientStoreNameTranslation(storeName),
      propertyKey: 'store.name',
    },
    {
      name: i18next.t(isCentral ? 'GENERAL.CENTRAL' : 'ORDERS.ORDERS.LIST_LABEL_SUPPLIER'),
      propertyKey: 'supplier.name',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_DELIVERY_DATE'),
      propertyKey: 'startOrderDate',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_CREATION_DATE'),
      propertyKey: 'orderDate',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_STATUS'),
      propertyKey: isCentral ? 'preparationStatus' : 'status',
    },
    {
      name: i18next.t('ORDERS.ORDERS.EXPORT_TOTAL_HT_BDC'),
      propertyKey: 'totalPriceBDC',
      type: 'currency',
    },
    {
      name: i18next.t('ORDERS.ORDERS.EXPORT_TOTAL_HT_BDL'),
      propertyKey: 'totalPriceBDL',
      type: 'currency',
    },
  ];

  if (!isCentral) {
    const statusColumnIndex = columns.findIndex(({ propertyKey }) => propertyKey === 'status');

    columns.splice(statusColumnIndex + 1, 0, {
      name: i18next.t('ORDERS.ORDERS.EXPORT_BDL_NUMBER'),
      propertyKey: 'receptionReference',
    });
    columns.push({
      name: i18next.t('ORDERS.ORDERS.EXPORT_TOTAL_HT_RECEIVED'),
      propertyKey: 'totalPriceReceived',
      type: 'currency',
    });
  }

  if (displayPackagingWeight) {
    columns.push({
      name: i18next.t('ORDERS.ORDERS.EXPORT_TOTAL_WEIGHT'),
      propertyKey: 'totPackagingWeight',
    });
  }

  if (displayInvoiceColumns) {
    columns.push({
      name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_NUMBER_FIELD'),
      propertyKey: 'invoiceNumber',
    });

    columns.push({
      name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_DATE_FIELD'),
      propertyKey: 'invoiceDate',
    });

    columns.push({
      name: i18next.t(
        'INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_TOTAL_EXPORT_FIELD',
      ),
      propertyKey: 'invoiceTotal',
    });
  }

  return columns;
};
