import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);

  > div {
    z-index: ${(props) => (props.theme.colors ? undefined : 15)};
    width: ${(props) => (props.theme.colors ? '50%' : 'calc(100% - 64px)')};
    box-shadow: none;
  }
`;

export const ContainerContent = styled.div`
  width: 100%;
  height: calc(100% - 60px);

  display: flex;
  flex-direction: column;

  gap: 24px;

  position: relative;
  overflow-y: scroll;

  padding: 24px 24px;

  background-color: ${(props) => props.theme.colors.greys.lighter};
`;

export const Section = styled.div`
  display: flex;
  min-width: 700px;

  justify-content: space-between;
`;

export const NavigationBar = styled.div`
  width: 100%;
  height: 52px;

  display: flex;
  padding: 0px 24px;
  background: white;
  border-width: thin;
  align-items: center;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  border-bottom: ${(props) => (props.theme.colors ? undefined : '1px solid #e8e8e8')};
  border-radius: ${(props) => props.theme.borders?.radius.strong || '4px 4px 0px 0px'};

  color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};

  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts.textBig};`
      : `font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: normal;`}
`;

export const NavigationPage = styled.div`
  height: 100%;
  color: ${(props) => props.theme.colors?.greys.darker || '#6c778d'};

  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts.textBig};`
      : `font-size: 14px;
  line-height: 16px;
  text-align: center;
  font-weight: normal;`}
  display: flex;
  margin-right: 40px;
  align-items: center;

  ${(props) =>
    props.selected &&
    css`
      color: ${(props) => props.theme.colors?.greys.darkest};
      padding-top: 3px;
      border-bottom: ${(props) => ` solid ${props.theme.colors?.brand.primary}`};
    `};

  ${(props) =>
    props.isDisabled
      ? css`
          color: ${props.theme.colors.greys.light};
          &:hover {
            cursor: not-allowed;
          }
        `
      : css`
          &:hover {
            color: ${(props) => props.theme.colors?.greys.darkest};
            cursor: pointer;
          }
        `}
`;

export const GenericModalContainer = styled.div`
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0px;

  z-index: 20;
`;
