import axios from '../../core/http';

import { config } from '../../config';

const baseApiUrl = config.baseApiUrl;

export const triggerRelaunchAnalytics = async ({
  type,
  endDate,
  clientId,
  storeIds,
  startDate,
}) => {
  const { data } = await axios.post(
    `${baseApiUrl}/backoffice/general/analytics/relaunch`,
    {
      type,
      clientId,
      endDate,
      storeIds,
      startDate,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    },
  );

  return data;
};

export const getRelaunchAnalyticsStatus = async (clientId, type) => {
  const { data } = await axios.get(
    `${baseApiUrl}/backoffice/general/analytics/relaunch/${clientId}/status`,
    {
      params: { type },
      headers: {
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    },
  );

  return data;
};

export default {
  triggerRelaunchAnalytics,
  getRelaunchAnalyticsStatus,
};
