import i18next from 'i18next';

export const getColumns = () => [
  {
    id: 'name',
    propertyKey: 'name',
    name: i18next.t('GENERAL.NAME'),
  },
  {
    id: 'country',
    propertyKey: 'country',
    name: i18next.t('GENERAL.COUNTRY'),
  },
  {
    id: 'city',
    propertyKey: 'city',
    name: i18next.t('GENERAL.CITY'),
  },
  {
    id: 'supplierProfileName',
    propertyKey: 'supplierProfileName',
    name: i18next.t('GENERAL.SUPPLIER_PROFILE'),
  },
];
