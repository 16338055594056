import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { Container, Title } from './styledComponents';

import {
  checkCashierProduct,
  checkProduct,
  checkProductPriceAtNullOrZero,
  checkProductVatRateInferiorAt1,
  checkWeatherStation,
  checkSupplierProductUnmapped,
  checkSupplierProductUnit,
  checkSupplierProductPriceAtNullOrZero,
  checkIngredientUnmapped,
  checkIngredientPriceAtNullOrZero,
} from './services';

import NavigationBreadCrumb from '../../../commons/Breadcrumb/NavigationBreadCrumb';

import { GeneralExportModal } from '../components/GeneralExportModal';
import { ORDER_TYPE } from '../components/BackOfficeItemList/constants';
import { Rectangle, Text } from '../components/BackOfficeItemList/styledComponents';
import { WeatherStationMaxDistanceModal } from '../components/WeatherStationMaxDistanceModal';
import BackOfficeItemList from '../components/BackOfficeItemList';

import { openSmallModal, openFitContentModal } from '../../../actions/modal';
import { showConfirmationMessage } from '../../../actions/messageconfirmation';

import { getClientInfo } from '../../../selectors/client';

export const BackOfficeChecks = (props) => {
  const {
    showMessage,
    client: { hasMultipleBrands, clientName, clientId },
    match,
  } = props;

  const path = _.get(match, 'path');

  const [products, setProducts] = useState([]);
  const [cashierProducts, setCashierProducts] = useState([]);
  const [productsPriceAtNullOrZero, setProductsPriceAtNullOrZero] = useState([]);
  const [productsVatRateInferiorAt1, setProductsVatRateInferiorAt1] = useState([]);
  const [weatherStation, setWeatherStations] = useState([]);
  const [ingredientUnmapped, setIngredientUnmapped] = useState([]);
  const [ingredientPriceAtNullOrZero, setIngredientPriceAtNullOrZero] = useState([]);
  const [supplierProductUnmapped, setSupplierProductUnmapped] = useState([]);
  const [supplierProductUnit, setSupplierProductUnit] = useState([]);
  const [supplierProductPriceAtNullOrZero, setSupplierProductPriceAtNullOrZero] = useState([]);

  const [loadingChecks, setLoadingChecks] = useState({
    isCashierProductLoading: false,
    isProductLoading: false,
    isProductPriceAtNullOrZeroLoading: false,
    isProductVatRateInferiorAt1Loading: false,
    isWeatherStationLoading: false,
    isIngredientUnmappedLoading: false,
    isIngredientPriceAtNullOrZeroLoading: false,
    isSupplierProductUnmappedLoading: false,
    isSupplierProductUnitLoading: false,
    isSupplierProductPriceAtNullOrZeroLoading: false,
    isRecipeLoading: false,
  });

  const [alreadyRunChecks, setAlreadyRunChecks] = useState({
    checkCashierProductAsked: false,
    checkProductAsked: false,
    checkProductPriceAtNullOrZeroAsked: false,
    checkProductVatRateInferiorAt1Asked: false,
    checkWeatherStationAsked: false,
    checkIngredientUnmappedAsked: false,
    checkIngredientPriceAtNullOrZeroAsked: false,
    checkSupplierProductUnmappedAsked: false,
    checkSupplierProductUnitAsked: false,
    checkSupplierProductPriceAtNullOrZeroAsked: false,
    checkRecipeAsked: false,
  });

  const [healthCheckGroups, setHealthCheckGroups] = useState([]);

  const [maxDistanceWeatherStation, setMaxDistanceWeatherStation] = useState(30);

  const setMaxDistanceWeatherStationModal = async () => {
    props.openFitContentModal({
      component: WeatherStationMaxDistanceModal,
      deactivateBackgroundClick: false,
      customStyle: {
        marginLeft: 'calc(50% - 180px)',
        marginTop: '12%',
      },
      title: i18next.t('BACKOFFICE.CHECKS.MAX_DISTANCE_MODAL_TITLE'),
      callback: (distance) => {
        setMaxDistanceWeatherStation(distance);
        checkWeatherStation(
          loadingChecks,
          setLoadingChecks,
          alreadyRunChecks,
          setAlreadyRunChecks,
          setWeatherStations,
          clientId,
          showMessage,
          distance,
        );
      },
    });
  };

  const generalExport = async (exportKey, data) => {
    props.openModal({
      component: GeneralExportModal,
      exportKey: exportKey,
      data: data,
      hasMultipleBrands: hasMultipleBrands,
      maxDistanceWeatherStation: maxDistanceWeatherStation,
      clientName,
      showMessage: showMessage,
    });
  };

  const renderLabel = (color, nbElements, text) => (
    <Rectangle color={color} needAlign={nbElements == 0 && true}>
      <Text color={color}>{nbElements + ' ' + i18next.t(text)}</Text>
    </Rectangle>
  );

  const sortHealthChecks = (category, orderBy, orderType) =>
    healthCheckGroups.map((healthCheckGroup) => {
      if (healthCheckGroup.category == category) {
        healthCheckGroup.headers.map((header) => {
          if (header.propertyKey == 'name' && header.propertyKey == orderBy) {
            healthCheckGroup.checks.sort((a, b) => {
              if (i18next.t(a.name).toUpperCase() < i18next.t(b.name).toUpperCase()) {
                if (orderType == 'asc') {
                  return 1;
                } else {
                  return -1;
                }
              }
              if (i18next.t(a.name).toUpperCase() > i18next.t(b.name).toUpperCase()) {
                if (orderType == 'asc') {
                  return -1;
                } else {
                  return 1;
                }
              }
              return 0;
            });
          } else if (header.propertyKey == 'nbElements' && header.propertyKey == orderBy) {
            healthCheckGroup.checks.sort(function (a, b) {
              if (orderType == 'asc') {
                return a.result.length - b.result.length;
              } else {
                return -(a.result.length - b.result.length);
              }
            });
          }
        });
      }
    });

  const setOrder = (
    orderBy,
    orderType,
    category,
    columnId,
    setCategory,
    setOrderBy,
    setOrderType,
  ) => {
    setCategory(category);
    if (orderBy === columnId) {
      setOrderType(
        orderType === ORDER_TYPE.ASCENDING ? ORDER_TYPE.DESCENDING : ORDER_TYPE.ASCENDING,
      );
    } else {
      setOrderBy(columnId);
      setOrderType('asc');
    }
  };

  useEffect(() => {
    setHealthCheckGroups([
      {
        category: 'BACKOFFICE.CHECKS.SUBTITLE_WEATHER_STATION',
        headers: [
          {
            name: 'Test',
            propertyKey: 'name',
          },
          {
            name: "Nombre d'éléments",
            propertyKey: 'nbElements',
            customRender: true,
          },
        ],
        checks: [
          {
            name: 'BACKOFFICE.CHECKS.FIRST_COLUMN_WEATHER_STATION_TEXT',
            launched: alreadyRunChecks.checkWeatherStationAsked,
            result: weatherStation,
            isLoading: loadingChecks.isWeatherStationLoading,
            nbElements: () => {
              if (!alreadyRunChecks.checkWeatherStationAsked) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN');
              }

              if (loadingChecks.isWeatherStationLoading) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN_IN_PROGRESS');
              }

              return renderLabel(
                !weatherStation.length ? 'green' : 'orange',
                weatherStation.length,
                weatherStation.length > 1
                  ? 'BACKOFFICE.CHECKS.SECOND_COLUMN_WEATHER_STATION_FINISHED_PLURAL'
                  : 'BACKOFFICE.CHECKS.SECOND_COLUMN_WEATHER_STATION_FINISHED_SINGULAR',
              );
            },
            actions: [
              {
                name: 'BACKOFFICE.CHECKS.EXPORT_RESULTS',
                type: 'exporter',
                callback: () => {
                  generalExport('weatherStation', weatherStation);
                },
              },
              {
                name: 'BACKOFFICE.ANALYTICS.TITLE',
                type: 'analyser',
                callback: () => {
                  setMaxDistanceWeatherStationModal();
                },
              },
            ],
          },
        ],
      },
      {
        category: 'BACKOFFICE.CHECKS.SUBTITLE_INGREDIENTS',
        headers: [
          {
            name: 'Test',
            propertyKey: 'name',
          },
          {
            name: "Nombre d'éléments",
            propertyKey: 'nbElements',
            customRender: true,
          },
        ],
        checks: [
          {
            name: 'BACKOFFICE.CHECKS.FIRST_COLUMN_INGREDIENTS_LINK',
            launched: alreadyRunChecks.checkIngredientUnmappedAsked,
            result: ingredientUnmapped,
            isLoading: loadingChecks.isIngredientUnmappedLoading,
            nbElements: () => {
              if (!alreadyRunChecks.checkIngredientUnmappedAsked) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN');
              }

              if (loadingChecks.isIngredientUnmappedLoading) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN_IN_PROGRESS');
              }

              return renderLabel(
                !ingredientUnmapped.length ? 'green' : 'orange',
                ingredientUnmapped.length,
                ingredientUnmapped.length > 1
                  ? 'BACKOFFICE.CHECKS.SECOND_COLUMN_INGREDIENT_FINISHED_PLURAL'
                  : 'BACKOFFICE.CHECKS.SECOND_COLUMN_INGREDIENT_FINISHED_SINGULAR',
              );
            },
            actions: [
              {
                name: 'BACKOFFICE.CHECKS.EXPORT_RESULTS',
                type: 'exporter',
                callback: () => {
                  generalExport('ingredient', ingredientUnmapped);
                },
              },
              {
                name: 'BACKOFFICE.ANALYTICS.TITLE',
                type: 'analyser',
                callback: () => {
                  checkIngredientUnmapped(
                    loadingChecks,
                    setLoadingChecks,
                    alreadyRunChecks,
                    setAlreadyRunChecks,
                    setIngredientUnmapped,
                    clientId,
                    showMessage,
                  );
                },
              },
            ],
          },
          {
            name: 'BACKOFFICE.CHECKS.FIRST_COLUMN_INGREDIENTS_PRICE',
            launched: alreadyRunChecks.checkIngredientPriceAtNullOrZeroAsked,
            result: ingredientPriceAtNullOrZero,
            isLoading: loadingChecks.isIngredientPriceAtNullOrZeroLoading,
            nbElements: () => {
              if (!alreadyRunChecks.checkIngredientPriceAtNullOrZeroAsked) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN');
              }

              if (loadingChecks.isIngredientPriceAtNullOrZeroLoading) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN_IN_PROGRESS');
              }

              return renderLabel(
                !ingredientPriceAtNullOrZero.length ? 'green' : 'orange',
                ingredientPriceAtNullOrZero.length,
                ingredientPriceAtNullOrZero.length > 1
                  ? 'BACKOFFICE.CHECKS.SECOND_COLUMN_INGREDIENT_FINISHED_PLURAL'
                  : 'BACKOFFICE.CHECKS.SECOND_COLUMN_INGREDIENT_FINISHED_SINGULAR',
              );
            },
            actions: [
              {
                name: 'BACKOFFICE.CHECKS.EXPORT_RESULTS',
                type: 'exporter',
                callback: () => {
                  generalExport('ingredientPrice', ingredientPriceAtNullOrZero);
                },
              },
              {
                name: 'BACKOFFICE.ANALYTICS.TITLE',
                type: 'analyser',
                callback: () => {
                  checkIngredientPriceAtNullOrZero(
                    loadingChecks,
                    setLoadingChecks,
                    alreadyRunChecks,
                    setAlreadyRunChecks,
                    setIngredientPriceAtNullOrZero,
                    clientId,
                    showMessage,
                  );
                },
              },
            ],
          },
        ],
      },
      {
        category: 'BACKOFFICE.CHECKS.SUBTITLE_SUPPLIER_PRODUCTS',
        headers: [
          {
            name: 'Test',
            propertyKey: 'name',
          },
          {
            name: "Nombre d'éléments",
            propertyKey: 'nbElements',
            customRender: true,
          },
        ],
        checks: [
          {
            name: 'BACKOFFICE.CHECKS.FIRST_COLUMN_SUPPLIER_PRODUCTS_LINK',
            launched: alreadyRunChecks.checkSupplierProductUnmappedAsked,
            result: supplierProductUnmapped,
            isLoading: loadingChecks.isSupplierProductUnmappedLoading,
            nbElements: () => {
              if (!alreadyRunChecks.checkSupplierProductUnmappedAsked) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN');
              }

              if (loadingChecks.isSupplierProductUnmappedLoading) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN_IN_PROGRESS');
              }

              return renderLabel(
                !supplierProductUnmapped.length ? 'green' : 'orange',
                supplierProductUnmapped.length,
                supplierProductUnmapped.length > 1
                  ? 'BACKOFFICE.CHECKS.SECOND_COLUMN_SUPPLIER_PRODUCT_FINISHED_PLURAL'
                  : 'BACKOFFICE.CHECKS.SECOND_COLUMN_SUPPLIER_PRODUCT_FINISHED_SINGULAR',
              );
            },
            actions: [
              {
                name: 'BACKOFFICE.CHECKS.EXPORT_RESULTS',
                type: 'exporter',
                callback: () => {
                  generalExport('supplierProductsNotMapped', supplierProductUnmapped);
                },
              },
              {
                name: 'BACKOFFICE.ANALYTICS.TITLE',
                type: 'analyser',
                callback: () => {
                  checkSupplierProductUnmapped(
                    loadingChecks,
                    setLoadingChecks,
                    alreadyRunChecks,
                    setAlreadyRunChecks,
                    setSupplierProductUnmapped,
                    clientId,
                    showMessage,
                  );
                },
              },
            ],
          },
          {
            name: 'BACKOFFICE.CHECKS.FIRST_COLUMN_SUPPLIER_PRODUCTS_UNIT',
            launched: alreadyRunChecks.checkSupplierProductUnitAsked,
            result: supplierProductUnit,
            isLoading: loadingChecks.isSupplierProductUnitLoading,
            nbElements: () => {
              if (!alreadyRunChecks.checkSupplierProductUnitAsked) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN');
              }

              if (loadingChecks.isSupplierProductUnitLoading) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN_IN_PROGRESS');
              }

              return renderLabel(
                !supplierProductUnit.length ? 'green' : 'orange',
                supplierProductUnit.length,
                supplierProductUnit.length > 1
                  ? 'BACKOFFICE.CHECKS.SECOND_COLUMN_SUPPLIER_PRODUCT_FINISHED_PLURAL'
                  : 'BACKOFFICE.CHECKS.SECOND_COLUMN_SUPPLIER_PRODUCT_FINISHED_SINGULAR',
              );
            },
            actions: [
              {
                name: 'BACKOFFICE.CHECKS.EXPORT_RESULTS',
                type: 'exporter',
                callback: () => {
                  generalExport('supplierProductsWrongUnit', supplierProductUnit);
                },
              },
              {
                name: 'BACKOFFICE.ANALYTICS.TITLE',
                type: 'analyser',
                callback: () => {
                  checkSupplierProductUnit(
                    loadingChecks,
                    setLoadingChecks,
                    alreadyRunChecks,
                    setAlreadyRunChecks,
                    setSupplierProductUnit,
                    clientId,
                    showMessage,
                  );
                },
              },
            ],
          },
          {
            name: 'BACKOFFICE.CHECKS.FIRST_COLUMN_SUPPLIER_PRODUCTS_PRICE',
            launched: alreadyRunChecks.checkSupplierProductPriceAtNullOrZeroAsked,
            result: supplierProductPriceAtNullOrZero,
            isLoading: loadingChecks.isSupplierProductPriceAtNullOrZeroLoading,
            nbElements: () => {
              if (!alreadyRunChecks.checkSupplierProductPriceAtNullOrZeroAsked) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN');
              }

              if (loadingChecks.isSupplierProductPriceAtNullOrZeroLoading) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN_IN_PROGRESS');
              }

              return renderLabel(
                !supplierProductPriceAtNullOrZero.length ? 'green' : 'orange',
                supplierProductPriceAtNullOrZero.length,
                supplierProductPriceAtNullOrZero.length > 1
                  ? 'BACKOFFICE.CHECKS.SECOND_COLUMN_SUPPLIER_PRODUCT_FINISHED_PLURAL'
                  : 'BACKOFFICE.CHECKS.SECOND_COLUMN_SUPPLIER_PRODUCT_FINISHED_SINGULAR',
              );
            },
            actions: [
              {
                name: 'BACKOFFICE.CHECKS.EXPORT_RESULTS',
                type: 'exporter',
                callback: () => {
                  generalExport(
                    'supplierProductsPriceAtNullOrZero',
                    supplierProductPriceAtNullOrZero,
                  );
                },
              },
              {
                name: 'BACKOFFICE.ANALYTICS.TITLE',
                type: 'analyser',
                callback: () => {
                  checkSupplierProductPriceAtNullOrZero(
                    loadingChecks,
                    setLoadingChecks,
                    alreadyRunChecks,
                    setAlreadyRunChecks,
                    setSupplierProductPriceAtNullOrZero,
                    clientId,
                    showMessage,
                  );
                },
              },
            ],
          },
        ],
      },
      {
        category: 'BACKOFFICE.CHECKS.SUBTITLE_CASHIER_PRODUCT',
        headers: [
          {
            name: 'Test',
            propertyKey: 'name',
          },
          {
            name: "Nombre d'éléments",
            propertyKey: 'nbElements',
            customRender: true,
          },
        ],
        checks: [
          {
            name: 'BACKOFFICE.CHECKS.FIRST_COLUMN_CASHIER_PRODUCT_TEXT',
            launched: alreadyRunChecks.checkCashierProductAsked,
            result: cashierProducts,
            isLoading: loadingChecks.isCashierProductLoading,
            nbElements: () => {
              if (!alreadyRunChecks.checkCashierProductAsked) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN');
              }

              if (loadingChecks.isCashierProductLoading) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN_IN_PROGRESS');
              }

              return renderLabel(
                !cashierProducts.length ? 'green' : 'orange',
                cashierProducts.length,
                cashierProducts.length > 1
                  ? 'BACKOFFICE.CHECKS.SECOND_COLUMN_CASHIER_PRODUCT_FINISHED_PLURAL'
                  : 'BACKOFFICE.CHECKS.SECOND_COLUMN_CASHIER_PRODUCT_FINISHED_SINGULAR',
              );
            },
            actions: [
              {
                name: 'BACKOFFICE.CHECKS.EXPORT_RESULTS',
                type: 'exporter',
                callback: () => {
                  generalExport('cashierProducts', cashierProducts);
                },
              },
              {
                name: 'BACKOFFICE.ANALYTICS.TITLE',
                type: 'analyser',
                callback: () => {
                  checkCashierProduct(
                    loadingChecks,
                    setLoadingChecks,
                    alreadyRunChecks,
                    setAlreadyRunChecks,
                    setCashierProducts,
                    clientId,
                    showMessage,
                  );
                },
              },
            ],
          },
        ],
      },
      {
        category: 'BACKOFFICE.CHECKS.SUBTITLE_PRODUCT',
        headers: [
          {
            name: 'Test',
            propertyKey: 'name',
          },
          {
            name: "Nombre d'éléments",
            propertyKey: 'nbElements',
            customRender: true,
          },
        ],
        checks: [
          {
            name: 'BACKOFFICE.CHECKS.FIRST_COLUMN_PRODUCT_TEXT',
            launched: alreadyRunChecks.checkProductAsked,
            result: products,
            isLoading: loadingChecks.isProductLoading,
            nbElements: () => {
              if (!alreadyRunChecks.checkProductAsked) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN');
              }

              if (loadingChecks.isProductLoading) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN_IN_PROGRESS');
              }

              return renderLabel(
                !products.length ? 'green' : 'orange',
                products.length,
                products.length > 1
                  ? 'BACKOFFICE.CHECKS.SECOND_COLUMN_PRODUCT_FINISHED_PLURAL'
                  : 'BACKOFFICE.CHECKS.SECOND_COLUMN_PRODUCT_FINISHED_SINGULAR',
              );
            },
            actions: [
              {
                name: 'BACKOFFICE.CHECKS.EXPORT_RESULTS',
                type: 'exporter',
                callback: () => {
                  generalExport('products', products);
                },
              },
              {
                name: 'BACKOFFICE.ANALYTICS.TITLE',
                type: 'analyser',
                callback: () => {
                  checkProduct(
                    loadingChecks,
                    setLoadingChecks,
                    alreadyRunChecks,
                    setAlreadyRunChecks,
                    setProducts,
                    clientId,
                    showMessage,
                  );
                },
              },
            ],
          },
          {
            name: 'BACKOFFICE.CHECKS.FIRST_COLUMN_PRODUCT_PRICE',
            launched: alreadyRunChecks.checkProductPriceAtNullOrZeroAsked,
            result: productsPriceAtNullOrZero,
            isLoading: loadingChecks.isProductPriceAtNullOrZeroLoading,
            nbElements: () => {
              if (!alreadyRunChecks.checkProductPriceAtNullOrZeroAsked) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN');
              }

              if (loadingChecks.isProductPriceAtNullOrZeroLoading) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN_IN_PROGRESS');
              }

              return renderLabel(
                !productsPriceAtNullOrZero.length ? 'green' : 'orange',
                productsPriceAtNullOrZero.length,
                productsPriceAtNullOrZero.length > 1
                  ? 'BACKOFFICE.CHECKS.SECOND_COLUMN_PRODUCT_FINISHED_PLURAL'
                  : 'BACKOFFICE.CHECKS.SECOND_COLUMN_PRODUCT_FINISHED_SINGULAR',
              );
            },
            actions: [
              {
                name: 'BACKOFFICE.CHECKS.EXPORT_RESULTS',
                type: 'exporter',
                callback: () => {
                  generalExport('productsPriceAtNullOrZero', productsPriceAtNullOrZero);
                },
              },
              {
                name: 'BACKOFFICE.ANALYTICS.TITLE',
                type: 'analyser',
                callback: () => {
                  checkProductPriceAtNullOrZero(
                    loadingChecks,
                    setLoadingChecks,
                    alreadyRunChecks,
                    setAlreadyRunChecks,
                    setProductsPriceAtNullOrZero,
                    clientId,
                    showMessage,
                  );
                },
              },
            ],
          },
          {
            name: 'BACKOFFICE.CHECKS.FIRST_COLUMN_PRODUCT_VAT_RATE',
            launched: alreadyRunChecks.checkProductVatRateInferiorAt1Asked,
            result: productsVatRateInferiorAt1,
            isLoading: loadingChecks.isProductVatRateInferiorAt1Loading,
            nbElements: () => {
              if (!alreadyRunChecks.checkProductVatRateInferiorAt1Asked) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN');
              }

              if (loadingChecks.isProductVatRateInferiorAt1Loading) {
                return i18next.t('BACKOFFICE.CHECKS.SECOND_COLUMN_IN_PROGRESS');
              }

              return renderLabel(
                !productsVatRateInferiorAt1.length ? 'green' : 'orange',
                productsVatRateInferiorAt1.length,
                productsVatRateInferiorAt1.length > 1
                  ? 'BACKOFFICE.CHECKS.SECOND_COLUMN_PRODUCT_FINISHED_PLURAL'
                  : 'BACKOFFICE.CHECKS.SECOND_COLUMN_PRODUCT_FINISHED_SINGULAR',
              );
            },
            actions: [
              {
                name: 'BACKOFFICE.CHECKS.EXPORT_RESULTS',
                type: 'exporter',
                callback: () => {
                  generalExport('productsVatRateInferiorAt1', productsVatRateInferiorAt1);
                },
              },
              {
                name: 'BACKOFFICE.ANALYTICS.TITLE',
                type: 'analyser',
                callback: () => {
                  checkProductVatRateInferiorAt1(
                    loadingChecks,
                    setLoadingChecks,
                    alreadyRunChecks,
                    setAlreadyRunChecks,
                    setProductsVatRateInferiorAt1,
                    clientId,
                    showMessage,
                  );
                },
              },
            ],
          },
        ],
      },
    ]);
  }, [loadingChecks]);

  return (
    <>
      <NavigationBreadCrumb featurePath={path} />
      <Container>
        <Title>{i18next.t('BACKOFFICE.CHECKS.TITLE')}</Title>
        {healthCheckGroups.map((healthCheckGroup, index) => (
          <div key={index}>
            <BackOfficeItemList
              category={healthCheckGroup.category}
              headers={healthCheckGroup.headers}
              list={healthCheckGroup.checks}
              name={healthCheckGroup.category}
              setOrder={setOrder}
              sortHealthChecks={sortHealthChecks}
            />
          </div>
        ))}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  openModal: (params) => {
    dispatch(openSmallModal(params));
  },
  openFitContentModal: (params) => {
    dispatch(openFitContentModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeChecks);
