import { createGlobalStyle } from 'styled-components';
import americantypewriterregular from './fonts/American-Typewriter-Regular.ttf';
import maisonneuedemi from './fonts/MaisonNeue-Demi.ttf';
import proximanovabold from './fonts/ProximaNova-Bold.otf';
import proximanovaextrabold from './fonts/ProximaNova-Extrabold.otf';
import proximanovalight from './fonts/ProximaNova-Light.otf';
import proximanovalightitalic from './fonts/ProximaNova-RegularItalic.otf';
import proximanovaregular from './fonts/ProximaNova-Regular.otf';
import proximanovaregularitalic from './fonts/ProximaNova-LightItalic.otf';
import proximanovasemibold from './fonts/ProximaNova-Semibold.otf';

export const GlobalStyle = createGlobalStyle`
    body {
    overflow-y: scroll;
    margin: 0px;
    font-family: 'proximanovaregular';
  }

  * {
    touch-action: manipulation;
  }

  body > svg {
    height: 0;
    padding: 0;
    margin: 0;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  label {
    color: #23231c;
    font-family: 'proximanovalight';
  }

  input:focus {
    outline: none;
  }

  button {
    border: none;
  }

  @font-face {
    font-family: 'proximanovalight';
    src: local('proximanovalight'), url(${proximanovalight});
  }

  @font-face {
    font-family: 'proximanovaregular';
    src: local('proximanovasemibold'), url(${proximanovaregular});
  }

  @font-face {
    font-family: 'proximanovasemibold';
    src: local('proximanovasemibold'), url(${proximanovasemibold});
  }

  @font-face {
    font-family: 'proximanovaextrabold';
    src: local('proximanovaextrabold'), url(${proximanovaextrabold});
  }

  @font-face {
    font-family: 'proximanovabold';
    src: local('proximanovabold'), url(${proximanovabold});
  }

  @font-face {
    font-family: 'proximanovalightitalic';
    src: local('proximanovalightitalic'), url(${proximanovalightitalic});
  }

  @font-face {
    font-family: 'proximanovaregularitalic';
    src: local('proximanovaregularitalic'), url(${proximanovaregularitalic});
  }

  @font-face {
    font-family: 'americantypewriterregular';
    src: local('americantypewriterregular'), url(${americantypewriterregular});
  }

  @font-face {
    font-family: 'maisonneuedemi';
    src: local('maisonneuedemi'), url(${maisonneuedemi});
  }
`;

export const InpulseGlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  body {
    margin: 0px;
    font-family: ${(props) => props.theme.fonts?.families.primary};
    height:100%;
  }

  * {
    touch-action: manipulation;
  }

  body > svg {
    height: 0;
    padding: 0;
    margin: 0;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  label {
    color: ${(props) => props.theme.colors.greys.darkest};
    font-family: ${(props) => props.theme.fonts?.families.secondary};
  }

  input:focus {
    outline: none;
  }

  .underline-and-bold {
    text-decoration: underline;
    font-family : 'inter-bold';
  }

.green-title {
  color: ${(props) => props.theme.colors.brand.primary};
}
`;
