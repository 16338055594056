import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

const getGlobalActions = (
  handleCreation,
  selectedStorageAreas,
  storageAreas,
  handleDeletion,
  handleExport,
) => {
  const selectedItemsCount = selectedStorageAreas.length;

  return [
    {
      id: 'create',
      actionLabel: i18next.t('GENERAL.CREATE'),
      fixed: true,
      handleAction: handleCreation,
      render: () => (
        <Button
          handleClick={handleCreation}
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('GENERAL.CREATE')}
        />
      ),
    },
    {
      id: 'action-delete',
      actionLabel: i18next.t('ADMIN.STORAGE_AREAS.LABEL_DELETE_ACTION', {
        count: selectedItemsCount,
      }),
      actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
      handleAction: async (items) => handleDeletion(items),
      isHidden: () => !selectedItemsCount,
    },
    {
      id: 'export-list',
      actionLabel: i18next.t(
        selectedItemsCount ? 'GENERAL.EXPORT_SELECTION' : 'GENERAL.EXPORT_LIST',
      ),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: () => handleExport(selectedItemsCount ? selectedStorageAreas : storageAreas),
    },
  ];
};

const getRowActions = (handleDeletion) => [
  {
    id: 'action-delete',
    actionLabel: () =>
      i18next.t('ADMIN.STORAGE_AREAS.LABEL_DELETE_ACTION', {
        count: 1,
      }),
    actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
    handleAction: (item) => handleDeletion([item]),
  },
];

const getStorageAreaMappingsGlobalActions = (
  isLoading,
  selectedItems,
  handleAddAssociation,
  handleRemoveAssociation,
  isRecipeAssociation = false,
) => {
  const hasSelectedItems = !!selectedItems.length;

  const actionsList = [
    {
      fixed: true,
      id: 'action-add',
      actionLabel: i18next.t('GENERAL.ADD'),
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={handleAddAssociation}
          icon="/images/inpulse/add-white-small.svg"
          isDisabled={isLoading}
          label={i18next.t('GENERAL.ADD')}
        />
      ),
      handleAction: () => {},
    },
  ];

  if (hasSelectedItems) {
    actionsList.push({
      id: 'action-delete',
      actionLabel: i18next.t(
        isRecipeAssociation
          ? 'ADMIN.STORAGE_AREAS.LABEL_REMOVE_RECIPE_ASSOCIATION'
          : 'ADMIN.STORAGE_AREAS.LABEL_REMOVE_ASSOCIATION',
        {
          count: selectedItems.length,
        },
      ),
      actionIcon: () => '/images/inpulse/remove-black-small.svg',
      handleAction: (items) => handleRemoveAssociation(items),
    });
  }

  return actionsList;
};

const getStorageAreaMappingsRowActions = (handleRemoveAssociation, isRecipeAssociation = false) => [
  {
    id: 'delete',
    actionLabel: () =>
      i18next.t(
        isRecipeAssociation
          ? 'ADMIN.STORAGE_AREAS.LABEL_REMOVE_RECIPE_ASSOCIATION'
          : 'ADMIN.STORAGE_AREAS.LABEL_REMOVE_ASSOCIATION',
        {
          count: 1,
        },
      ),
    actionIcon: () => '/images/inpulse/remove-black-small.svg',
    handleAction: (item) => handleRemoveAssociation([item]),
  },
];

export default {
  getGlobalActions,
  getRowActions,
  getStorageAreaMappingsGlobalActions,
  getStorageAreaMappingsRowActions,
};
