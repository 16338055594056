import React from 'react';

export const customLegend = (payload) => (
  <div className="deepsight-chart-legend-2-elements">
    {payload.payload.map((item, index) => {
      if (!item.displayName) return '';
      let background = { backgroundColor: item.color };

      if (item.type === 'none') {
        background = {
          background:
            'repeating-linear-gradient(' +
            'to right,' +
            item.color +
            ',' +
            item.color +
            ' 2px,' +
            '#fff 2px,' +
            '#fff 5px)',
        };
      }
      if (item.color2) {
        background = {
          backgroundImage: 'linear-gradient(to bottom, ' + item.color + ', ' + item.color2 + ')',
        };
      }
      return (
        <div className="error-forecast-chart-legend-item-container" key={index}>
          <div className={'error-forecast-chart-legend'} style={background} />
          <div className="error-forecast-chart-legend-value">{item.displayName}</div>
        </div>
      );
    })}
  </div>
);
