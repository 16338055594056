import { connect } from 'react-redux';
import { get } from 'lodash';
import React from 'react';

import { Button, Checkbox } from '@commons/utils/styledLibraryComponents';
import { Dropdown, ToggleSwitch } from '@commons/utils/styledLibraryComponents';
import { SingleDatePicker } from '@commons/DatePickers/SingleDatePicker';
import { Tooltip } from '@commons/utils/styledLibraryComponents';
import DropdownCountry from '@commons/dropdownCountry';
import TimeZonesDropdown from '@commons/TimezonesDropdown';

import { Input } from '@lib/inpulse/Input';

import theme from '@theme';

import { CLONE_STORE_STEP } from '../../details/utils/index';

import {
  Container,
  ContentContainer,
  ContentContainerColumn,
  InputContainer,
  TitleContainer,
  LabelContainer,
  WildCard,
  SwitchText,
  SwitchContainer,
  ErrorMessage,
  TextContent,
  TextContainer,
  ButtonContainer,
  CheckboxContainer,
} from './styledComponents';

const renderLabelContainer = (input) => {
  const { isRequired, label, hasTooltip, tooltipText, readOnly } = input;
  return (
    <LabelContainer disabled={readOnly}>
      {isRequired ? (
        <span>
          <WildCard disabled={readOnly}>*</WildCard> {label} :
        </span>
      ) : (
        `${label} :`
      )}
      {hasTooltip && tooltipText && (
        <div style={{ marginTop: '-1px' }}>
          <Tooltip style={{ margin: '0px' }} text={tooltipText} />
        </div>
      )}
    </LabelContainer>
  );
};

const renderCheckboxContainer = (input, index) => {
  const { label, disabled, disabledLight, checked } = input;
  return (
    <CheckboxContainer key={`Checkbox-${index}`}>
      <Checkbox
        isChecked={checked}
        isDisabled={disabled}
        isDisabledLight={disabledLight}
        shape="square"
      />
      {label}
    </CheckboxContainer>
  );
};

const handleDropdownChanges = (keys, input, cardInformations, setter, handleCardInformations) => {
  if (!input.isMultipleChoice) {
    const value = get(keys, `[0].${input.dropdownMatchingProperty || 'value'}`, null);

    if (cardInformations[input.propertyKey] === value) {
      return;
    }

    handleCardInformations(input.propertyKey, value, cardInformations, setter);

    return;
  }

  handleCardInformations(input.propertyKey, keys, cardInformations, setter);
};

const getFormattedCardInformations = (input, currentValue) => {
  if (!currentValue) {
    return [];
  }
  if (!input.isMultipleChoice) {
    return input.dropdownValue.filter(
      (item) => item[input.dropdownMatchingProperty || 'value'] == currentValue,
    );
  }
  return currentValue;
};

const getDropdownIcon = (input, cardInformations) => {
  const propertyDropdownValues = cardInformations[input.propertyKey];

  if (!propertyDropdownValues || !propertyDropdownValues.length) {
    if (input.readOnly) {
      return input.disabledIcon;
    }
    if (!input.emptyIcon || input.emptyIcon === 'none') {
      return null;
    }
    return input.emptyIcon;
  }

  if (!input.icon || input.icon === 'none') {
    return null;
  }
  return input.icon;
};

const InputCardSection = (props) => {
  const {
    title,
    inputs,
    handleCardInformations,
    cardInformations,
    setter,
    isDisabled = false,
    contentsPadding,
  } = props;

  if (!isDisabled) {
    return (
      <Container>
        <TitleContainer>{title}</TitleContainer>
        <ContentContainer>
          {!!inputs.length &&
            inputs.map((input, index) => {
              if (input.isDropdown) {
                return (
                  <InputContainer key={`Dropdown-${index}`}>
                    <Dropdown
                      button={input.button}
                      customStyle={{ height: '64px' }}
                      iconSrc={getDropdownIcon(input, cardInformations)}
                      isDisabled={input.readOnly}
                      isRequired={input.isRequired}
                      isUniqueSelection={!input.isMultipleChoice}
                      items={input.dropdownValue}
                      key={index}
                      label={`${input.label}  :`}
                      labelStyle={{ font: theme.fonts.textSmall }}
                      placeholder={input.placeholder}
                      selectedItems={getFormattedCardInformations(
                        input,
                        cardInformations[input.propertyKey],
                      )}
                      sortBy={input.sortBy}
                      tooltipText={input.tooltipText}
                      onSelectionChange={(selection) => {
                        handleDropdownChanges(
                          !input.isMultipleChoice ? [selection] : selection,
                          input,
                          cardInformations,
                          setter,
                          handleCardInformations,
                        );
                      }}
                    />
                  </InputContainer>
                );
              }

              if (input.isSwitch) {
                return (
                  <SwitchContainer key={`ToggleSwitch-${index}`}>
                    <ToggleSwitch
                      checked={cardInformations[input.propertyKey]}
                      handleClick={() =>
                        handleCardInformations(
                          input.propertyKey,
                          !cardInformations[input.propertyKey],
                          cardInformations,
                          setter,
                        )
                      }
                      id={input.propertyKey}
                    />
                    <SwitchText>{input.label}</SwitchText>
                    {input.tooltipText && (
                      <div style={{ marginTop: '-1px' }}>
                        <Tooltip text={input.tooltipText} />
                      </div>
                    )}
                  </SwitchContainer>
                );
              }

              if (input.isCountryDropdown) {
                return (
                  <InputContainer key={`DropdownCountry-${index}`}>
                    {renderLabelContainer(input)}
                    <DropdownCountry
                      handleSelect={(country) =>
                        handleCardInformations(input.propertyKey, country, cardInformations, setter)
                      }
                      isDisabled={input.readOnly}
                      selected={cardInformations[input.propertyKey]}
                    />
                  </InputContainer>
                );
              }

              if (input.isTimezoneDropdown) {
                return (
                  <InputContainer key={`DropdownTimezone-${index}`}>
                    <TimeZonesDropdown
                      countryCode={!input.readOnly ? cardInformations['country'] : null}
                      defaultTimezone={cardInformations[input.propertyKey]}
                      handleChange={(timezoneName) =>
                        handleCardInformations(
                          input.propertyKey,
                          timezoneName,
                          cardInformations,
                          setter,
                        )
                      }
                      isDisabled={input.readOnly}
                      isRequired={input.isRequired}
                      label={input.label}
                      labelStyle={{ font: theme.fonts.textSmall }}
                    />
                  </InputContainer>
                );
              }

              if (input.isSingleDatePicker) {
                return (
                  <InputContainer key={`SingleDatePicker-${index}`}>
                    {renderLabelContainer(input)}
                    <SingleDatePicker
                      date={input.value}
                      timezone="Europe/Paris" // TODO - [TIMEZONES] To be replaced by IPP-5229 Admin Module
                      onDateChange={(date) =>
                        handleCardInformations(input.propertyKey, date, cardInformations, setter)
                      }
                    />
                  </InputContainer>
                );
              }

              if (input.isTextContent || input.isCloneButton || input.isCheckbox) {
                return;
              }

              return (
                <InputContainer key={`${input.propertyKey}-${index}`}>
                  <Input
                    error={!!input.hasError}
                    icon={input.icon}
                    label={input.label}
                    maxLength={input.propertyKey === 'additionnalAddress' ? 1024 : ''}
                    placeholder={input.placeholder}
                    readOnly={input.readOnly}
                    tooltipText={input.hasTooltip ? input.tooltipText : null}
                    type={input.type}
                    value={input.value || ''}
                    onChange={(event) =>
                      handleCardInformations(
                        input.propertyKey,
                        event.target.value,
                        cardInformations,
                        setter,
                      )
                    }
                  />
                  {!!input.hasError && <ErrorMessage>{input.errorMessage}</ErrorMessage>}
                </InputContainer>
              );
            })}
        </ContentContainer>
        <ContentContainerColumn contentsPadding={contentsPadding}>
          {/* A ContentContainerColumn is used here in order to change the CSS property 'flex-direction: row' into 'flex-direction:column  */}
          {!!inputs.length &&
            inputs.map((input, index) => {
              if (input.isTextContent) {
                return (
                  <TextContainer key={`TextContent-${index}`}>
                    <TextContent>{input.textContent}</TextContent>
                  </TextContainer>
                );
              }

              if (input.isCloneButton) {
                return (
                  <ButtonContainer key={`Button-${index}`}>
                    <Button
                      buttonCustomStyle={{ paddingLeft: '0px' }}
                      color={'inpulse-outline'}
                      handleClick={() => {
                        handleCardInformations(input.propertyKey, CLONE_STORE_STEP.STORE_SELECTION);
                      }}
                      icon={input.icon}
                      label={input.label}
                      noBorder={true}
                    />
                  </ButtonContainer>
                );
              }

              if (input.isCheckbox) {
                return renderCheckboxContainer(input, index);
              }
              return;
            })}
        </ContentContainerColumn>
      </Container>
    );
  }
  return null;
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

export default connect(mapStateToProps, null)(InputCardSection);
