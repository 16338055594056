import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Icon,
  ItemContainer,
  HeaderItem,
  HeaderText,
  WhiteSpaceCheckbox,
  WhiteSpaceRadioButton,
  CheckBoxContainer,
} from '../../styledComponents';

import { Props } from './interfaces';

import { ORDER_TYPE } from '../../constants';

import { getTheme } from '../../../utils/theme';

import Tooltip from '../../../Tooltip';
import Checkbox from '../../../Checkbox/index';

import iconSortedListDescDark from '../../../images/icon-sorted-desc-dark.svg';
import iconSortedListAscDark from '../../../images/icon-sorted-asc-dark.svg';
import iconSortDark from '../../../images/icon-sort-dark.svg';

import iconSortedListDesc from '../../../images/icon-sorted-desc.svg';
import iconSortedListAsc from '../../../images/icon-sorted-asc.svg';
import iconSort from '../../../images/icon-sort.svg';

const icons = {
  dark: {
    default: iconSortDark,
    asc: iconSortedListAscDark,
    desc: iconSortedListDescDark,
  },
  blue: {
    default: iconSort,
    asc: iconSortedListAsc,
    desc: iconSortedListDesc,
  },
};

const ListViewHeader = (props: Props): JSX.Element => {
  const {
    theme,
    data,
    columns,
    orderBy,
    orderType,
    setOrder,
    selectedCount,
    selectAllRows,
    isSelectable,
    disableFullSelection,
    notSelectableItemsCount,
    disableMultipleSelection,
    shouldDisplayActionColumn,
  } = props;

  const selectSortIcon = (id) => {
    const updatedTheme = getTheme(theme, 'listView');

    if (id === orderBy) {
      return orderType === ORDER_TYPE.DESCENDING
        ? icons[updatedTheme.header?.icon].desc
        : icons[updatedTheme.header?.icon].asc;
    }

    return icons[updatedTheme.header?.icon].default;
  };

  return (
    <>
      {isSelectable && !disableMultipleSelection && !disableFullSelection && (
        <CheckBoxContainer
          onClick={() => selectAllRows()}
          theme={getTheme(theme, 'checkbox')}
        >
          <Checkbox
            theme={theme}
            isChecked={false}
            shape="square"
            uncheckOnClick={selectedCount > 0}
            isDisabled={data && data.length === notSelectableItemsCount}
          />
        </CheckBoxContainer>
      )}
      {disableMultipleSelection && <WhiteSpaceRadioButton />}
      {isSelectable && !disableMultipleSelection && disableFullSelection && (
        <WhiteSpaceCheckbox />
      )}
      {columns &&
        columns.map((column) => {
          if (column.hidden) {
            return (
              <Fragment key={`headers-column-${column.id || column.name}`} />
            );
          }

          return (
            <ItemContainer
              key={`headers-column-${column.id || column.name}`}
              large={column.large}
              minWidth={column.minWidth}
              narrow={column.narrow}
              narrowSidePadding={disableMultipleSelection}
              onClick={() =>
                !column.disableSort && setOrder(column.propertyKey)
              }
              enableCursorPointer={!column.disableSort}
            >
              <HeaderItem>
                {!column.hideHeader && (
                  <>
                    <HeaderText blue={column.propertyKey === orderBy}>
                      {column.name}
                    </HeaderText>
                    {!column.disableSort && (
                      <Icon
                        width={16}
                        height={16}
                        src={selectSortIcon(column.propertyKey)}
                        alt="icon-order"
                      />
                    )}
                    {column.tooltipText && (
                      <Tooltip text={column.tooltipText} />
                    )}
                  </>
                )}
              </HeaderItem>
            </ItemContainer>
          );
        })}
      {shouldDisplayActionColumn && (
        <ItemContainer key="header-column-action" narrow minWidth="58">
          <HeaderItem />
        </ItemContainer>
      )}
    </>
  );
};

ListViewHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      render: PropTypes.func,
      name: PropTypes.string.isRequired,
      propertyKey: PropTypes.string.isRequired,
      large: PropTypes.bool,
      narrow: PropTypes.bool,
      hidden: PropTypes.bool,
      minWidth: PropTypes.number,
      disableSort: PropTypes.bool,
    })
  ).isRequired,
  orderBy: PropTypes.string,
  setOrder: PropTypes.func.isRequired,
  selectAllRows: PropTypes.func.isRequired,
  isSelectable: PropTypes.bool.isRequired,
  shouldDisplayActionColumn: PropTypes.bool.isRequired,
};

ListViewHeader.defaultProps = {
  orderBy: null,
};

export default ListViewHeader;
