import i18next from 'i18next';
import React from 'react';
import styled, { css } from 'styled-components';

import { InpulseLabel } from '@commons/DeepsightComponents';
import { numberToFixed } from '@commons/utils/format';
import DisplayNumber from '@commons/DisplayNumber';

import {
  tooltipRealTimeAnalyticsUnit,
  tooltipRealTimeAnalyticsTurnover,
} from '../../constants/tooltipText';

const LabelContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Label = styled.div`
  width: fit-content;
  height: 20px;

  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  color: #000000;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${(props) =>
    props.red &&
    css`
      color: #ef252a;
      font-weight: 600;
    `}

  ${(props) =>
    props.orange &&
    css`
      color: #ff9c40;
      font-weight: 600;
    `}
  ${(props) =>
    props.gradientGreen &&
    css`
      color: #2cd696;
      font-weight: 600;
    `}
`;

const getColumnsTable = (metric, clientStoreName) => {
  const isMetricTurnover = metric === 'turnover';

  return [
    {
      id: 1,
      propertyKey: 'storeName',
      type: 'string',
      name: clientStoreName,
    },
    {
      id: 3,
      decimals: 0,
      propertyKey: isMetricTurnover ? 'forecastedTurnover' : 'forecastedQuantity',
      type: isMetricTurnover ? 'currency' : 'numeric',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_FORECAST'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        isMetricTurnover
          ? tooltipRealTimeAnalyticsTurnover.predicted
          : tooltipRealTimeAnalyticsUnit.predicted,
      ),
      render: (item) => (
        <DisplayNumber
          displayCurrencyCode={isMetricTurnover}
          number={item}
          withoutDecimals={true}
        />
      ),
    },
    {
      id: 4,
      decimals: 0,
      propertyKey: isMetricTurnover ? 'stockTurnover' : 'stockQuantity',
      type: isMetricTurnover ? 'currency' : 'numeric',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_STOCKS'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        isMetricTurnover
          ? tooltipRealTimeAnalyticsTurnover.stocks
          : tooltipRealTimeAnalyticsUnit.stocks,
      ),
      render: (item) => (
        <DisplayNumber
          displayCurrencyCode={isMetricTurnover}
          number={item}
          withoutDecimals={true}
        />
      ),
    },
    {
      id: 5,
      decimals: 0,
      propertyKey: isMetricTurnover ? 'toProduceTurnover' : 'toProduceQuantity',
      type: isMetricTurnover ? 'currency' : 'numeric',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_TO_PRODUCE'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        isMetricTurnover
          ? tooltipRealTimeAnalyticsTurnover.toProduceTurnover
          : tooltipRealTimeAnalyticsUnit.toProduceQuantity,
      ),
      render: (item) => (
        <DisplayNumber
          displayCurrencyCode={isMetricTurnover}
          number={item}
          withoutDecimals={true}
        />
      ),
    },
    {
      id: 6,
      decimals: 0,
      propertyKey: isMetricTurnover ? 'producedTurnover' : 'producedQuantity',
      type: isMetricTurnover ? 'currency' : 'numeric',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCED'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        isMetricTurnover
          ? tooltipRealTimeAnalyticsTurnover.producedTurnover
          : tooltipRealTimeAnalyticsUnit.producedQuantity,
      ),
      render: (item) => (
        <DisplayNumber
          displayCurrencyCode={isMetricTurnover}
          number={item}
          withoutDecimals={true}
        />
      ),
    },
    {
      id: 7,
      decimals: 0,
      propertyKey: isMetricTurnover
        ? 'percentageProductionTurnover'
        : 'percentageProductionQuantity',
      type: 'percentage',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCTION_RATIO'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        isMetricTurnover
          ? tooltipRealTimeAnalyticsTurnover.producedPercent
          : tooltipRealTimeAnalyticsUnit.producedPercent,
      ),
      render: (item) => {
        const valueToDisplay = numberToFixed(item, 0, '-', '%');

        if (item == null) {
          return (
            <LabelContainer>
              <Label>-</Label>
            </LabelContainer>
          );
        }

        if (item <= 75 || item >= 125) {
          return (
            <LabelContainer>
              <InpulseLabel color={'red'} text={valueToDisplay} type={'secondary'} />
            </LabelContainer>
          );
        }

        if ((item >= 75 && item < 90) || (item > 110 && item > 125)) {
          return (
            <LabelContainer>
              <InpulseLabel color={'orange'} text={valueToDisplay} type={'secondary'} />
            </LabelContainer>
          );
        }

        return (
          <LabelContainer>
            <Label>{valueToDisplay}</Label>
          </LabelContainer>
        );
      },
    },
    {
      id: 8,
      decimals: 0,
      type: isMetricTurnover ? 'currency' : 'numeric',
      filterType: 'numeric',
      propertyKey: isMetricTurnover ? 'lossTurnover' : 'lossQuantity',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_DECLARED_LOSSES'),
      tooltipText: i18next.t(
        isMetricTurnover
          ? tooltipRealTimeAnalyticsTurnover.loss
          : tooltipRealTimeAnalyticsUnit.loss,
      ),
      render: (item) => (
        <DisplayNumber
          displayCurrencyCode={isMetricTurnover}
          number={item}
          withoutDecimals={true}
        />
      ),
    },
    {
      id: 9,
      decimals: 0,
      type: 'numeric',
      filterType: 'numeric',
      propertyKey: 'nbProducedReferences',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_NB_REFERENCE_PRODUCED'),
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_NUMBER_PRODUCED_REFERENCE'),
    },
  ];
};

export default getColumnsTable;
