import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../utils/styledLibraryComponents';

const BUTTON_STATES = {
  STANDARD: {
    icon: '/images/inpulse/file-download-black-small.svg',
    color: 'inpulse-outline',
  },
  INPULSE: {
    icon: '/images/inpulse/file-download-white-small.svg',
    color: 'inpulse-default',
  },
};

/**
 * Use to easily determine what colors should be used for a button and its icon
 * depending on whether it's disabled or an Inpulse action
 *
 * @param {boolean} isDisabled
 * @param {boolean} isInpulseOnly
 * @returns An object in the shape { icon: string, color: string }
 */
const resolveButtonIconAndColor = (isInpulseOnly) => {
  if (isInpulseOnly) {
    return BUTTON_STATES.INPULSE;
  }
  return BUTTON_STATES.STANDARD;
};

/**
 * Generic 'Export' button
 *
 * Example usage in: StocksAnalyticsCurrent
 */
export const ExportButton = (props) => {
  const { customStyle, handleClick, isDisabled, isInpulseOnly, hasLabel } = props;

  const { icon, color } = resolveButtonIconAndColor(isInpulseOnly);

  return (
    <Button
      buttonCustomStyle={{ ...customStyle }}
      color={color}
      fontSize={14}
      formatText={false}
      handleClick={handleClick}
      icon={icon}
      iconCustomStyle={{ width: '18px', height: '18px' }}
      isDisabled={isDisabled}
      label={hasLabel && i18next.t('GENERAL.EXPORT')}
    />
  );
};

ExportButton.propTypes = {
  customStyle: PropTypes.object,
  handleClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isInpulseOnly: PropTypes.bool,
  hasLabel: PropTypes.bool,
};

ExportButton.defaultProps = {
  customStyle: {},
  isDisabled: false,
  isInpulseOnly: false,
  hasLabel: true,
};
