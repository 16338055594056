import i18next from 'i18next';
import React from 'react';

import theme from '@theme';

import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { Label } from '@commons/utils/styledLibraryComponents';

import { LoaderContainer, LabelContainer } from '../styledComponents';

export const getColumns = (isLoadingStatus) => [
  {
    id: 'name',
    name: i18next.t('GENERAL.NAME'),
    displayName: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
  },
  {
    id: 'status',
    name: i18next.t('GENERAL.STATUS'),
    displayName: i18next.t('GENERAL.STATUS'),
    propertyKey: 'status',
    render: (item, row) => {
      // If call for connection status isn't finished item (status) is always null so we deplay the loader
      if (isLoadingStatus && item === null && !!row.isActive) {
        return (
          <LoaderContainer>
            <DeepsightComponentLoader color={'white'} height={16} width={16} />
          </LoaderContainer>
        );
      }

      if (!row.isActive) {
        return (
          <LabelContainer>
            <Label background={theme.colors.infoOrange} color={theme.colors.infoOrange} type={null}>
              {i18next.t('GENERAL.DEACTIVATED')}
            </Label>
          </LabelContainer>
        );
      }

      // If item is true then we choose green color if not we choose red
      const labelColor = item ? theme.colors.infoGreen : theme.colors.infoRed;

      return (
        <LabelContainer>
          <Label
            background={labelColor}
            color={labelColor}
            labelTooltip={row.errorMessage}
            type={null}
          >
            {i18next.t(
              item ? 'BACKOFFICE.CASHIERS.CONNECTED' : 'BACKOFFICE.CASHIERS.CONNECTION_ERROR',
            )}
          </Label>
        </LabelContainer>
      );
    },
  },
];
