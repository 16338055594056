import { head, isEmpty } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

import { canCreateRushOrder, canDeleteRushOrder } from '@selectors/actions/rushOrderActions';

export const getActions = (props) => {
  const {
    handleExportList,
    filteredRushOrders,
    createRushOrder,
    selectedRushOrders,
    handleDeletionConfirmationModal,
    handleExportContent,
    authorizedActions,
  } = props;

  const hasSelectedNoneOrMultipleRushOrders = selectedRushOrders.length !== 1;

  return [
    {
      id: 'create',
      actionLabel: i18next.t('ADMIN.PRODUCTS.EMPTY_STATE_CREATE'),
      fixed: canCreateRushOrder(authorizedActions),
      handleAction: () => false,
      isHidden: () => !canCreateRushOrder(authorizedActions),
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={() => createRushOrder()}
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('GENERAL.CREATE')}
        />
      ),
    },
    {
      id: 'delete',
      actionLabel: i18next.t('ORDERS.ORDERS.LIST_DELETE_ORDER'),
      isHidden: () => hasSelectedNoneOrMultipleRushOrders || !canDeleteRushOrder(authorizedActions),
      handleAction: () => handleDeletionConfirmationModal(head(selectedRushOrders)),
      actionIcon: () => '/images/inpulse/delete-black-small.svg',
    },
    {
      id: 'export',
      actionLabel: !hasSelectedNoneOrMultipleRushOrders
        ? i18next.t('ORDERS.ORDERS.EXPORT_ORDER')
        : !isEmpty(selectedRushOrders)
        ? i18next.t('ORDERS.ORDERS.EXPORT_SELECTION')
        : i18next.t('GENERAL.EXPORT_LIST'),

      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: () =>
        handleExportList(isEmpty(selectedRushOrders) ? filteredRushOrders : selectedRushOrders),
    },
    {
      id: 'export-content',
      actionLabel: !hasSelectedNoneOrMultipleRushOrders
        ? i18next.t('GENERAL.EXPORT_CONTENT_SINGULAR')
        : i18next.t('GENERAL.EXPORT_CONTENT_PLURAL'),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: () =>
        handleExportContent(isEmpty(selectedRushOrders) ? filteredRushOrders : selectedRushOrders),
    },
  ];
};

export default getActions;
