import { useTranslation } from 'react-i18next';
import React from 'react';

import { planningType as planningTypeEnum } from '@productions/constants/planning';

import { Container, MainText, SubText, Icon } from './styledComponents';

export const EmptyState = (props) => {
  const { planningType } = props;
  const { t } = useTranslation();

  return (
    <Container>
      <MainText>
        {planningType === planningTypeEnum.PRODUCTION &&
          t('PRODUCTION.PRODUCTION.BODY_EMPTY_STATE_TITLE')}
        {planningType === planningTypeEnum.LOSSES && t('PRODUCTION.LOSSES.BODY_EMPTY_STATE_TITLE')}
        {planningType === planningTypeEnum.STOCKS && t('PRODUCTION.STOCKS.BODY_EMPTY_STATE_TITLE')}
      </MainText>
      <SubText>{t('PRODUCTION.PRODUCTION.BODY_EMPTY_STATE_SUBTITLE')}</SubText>
      <Icon alt="closed-store-icon" src={'/images/inpulse/closed-store.svg'} />
    </Container>
  );
};

export default EmptyState;
