import styled from 'styled-components';

export const Container = styled.div`
  width: 16px;
  height: 16px;

  left: 25px;
  margin-top: ${(props) => (props.extraMarginTop ? '30px' : '22px')};
  position: absolute;

  border-radius: 50%;

  background-color: ${(props) => props.color};

  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.icon});
`;

export default {
  Container,
};
