import styled from 'styled-components';

export const LoadingStateContainer = styled.div`
  height: 100%;
  width: 496px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid #e5e5e5;
`;

export const LoadingRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  padding: 0 25px 0 0;
`;

export const LoadingItemContainer = styled.div`
  padding: 0 0 0 15px;
  width: 100%;
  flex: 1;
`;

export const BorderTop = styled.div`
  border-top: 1px solid #e5e5e5;
`;
