import React from 'react';

import { getTheme } from '@commons/utils/theme';

import { EventContainer, EventLabel, EventText, EventIcon } from './styledComponents';

const EventsLabel = (props) => {
  const { events } = props;

  const theme = getTheme();

  if (!events.length) {
    return;
  }

  return (
    <EventContainer>
      {events.map((event, index) => (
        <EventLabel key={index} theme={theme} userEvent={!!event.clientId}>
          <EventIcon alt={event.input1} src={event.eventSubCategory.img} />
          <EventText theme={theme}>{event.input1}</EventText>
        </EventLabel>
      ))}
    </EventContainer>
  );
};

export default EventsLabel;
