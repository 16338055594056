import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

import DisplayNumber from '@commons/DisplayNumber';

const getListColumns = () => [
  {
    id: 'date',
    name: i18next.t('ADMIN.TRANSACTIONS.COLUMN_NAME_HOUR'),
    propertyKey: 'date',
    isSortable: true,
    render: (item) => moment(item).format('HH:mm'),
  },
  {
    id: 'number',
    name: i18next.t('ADMIN.TRANSACTIONS.COLUMN_NAME_NUMBER'),
    propertyKey: 'number',
    isSortable: true,
    displayBigger: true,
  },
  {
    id: 'childCount',
    name: i18next.t('ADMIN.TRANSACTIONS.COLUMN_NAME_ROWS_COUNT'),
    propertyKey: 'childCount',
    isSortable: true,
  },
  {
    id: 'turnover',
    name: i18next.t('GENERAL.TURNOVER_TAX_INCLUDED'),
    propertyKey: 'turnover',
    isSortable: true,
    render: (item) => <DisplayNumber number={item} displayCurrencyCode />,
  },
  {
    id: 'turnoverTaxExcluded',
    name: i18next.t('GENERAL.TURNOVER_EX_TAX'),
    propertyKey: 'turnoverTaxExcluded',
    isSortable: true,
    render: (item) => <DisplayNumber number={item} displayCurrencyCode />,
  },
  {
    id: 'channel',
    name: i18next.t('GENERAL.CHANNEL'),
    propertyKey: 'channel',
    isSortable: true,
    displayBigger: true,
  },
  {
    id: 7,
    name: '',
    propertyKey: 'action',
    isClickable: true,
    isSortable: false,
  },
];

export default { getListColumns };
