import styled from 'styled-components';

export const Container = styled.div`
  width: 411px;
  height: auto;

  padding: 15px;
  margin: -15px 15px 15px 15px;

  background: ${(props) => props.theme.colors.greys.lightest};
  border-radius: ${(props) => props.theme.borders.radius.weak};

  display: flex;
`;

export const DateText = styled.div`
  font: ${(props) => props.theme.fonts.textSmall};
  color: ${(props) => props.theme.colors.greys.darkest};
`;

export const Text = styled.div`
  font: ${(props) => props.theme.fonts.textSmall};

  color: ${(props) => props.theme.colors.greys.dark};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Icon = styled.div`
  display: flex;
  align-items: center;

  margin-right: 15px;

  > img {
    width: 20px;
    height: 23px;
  }
`;
