export const ACCOUNT_STATUS = {
  INVITED: 'BACKOFFICE.TEAM.ONBOARDING_STATUS_INVITED',
  REGISTERED: 'BACKOFFICE.TEAM.ONBOARDING_STATUS_REGISTERED',
  NOT_INVITED: 'BACKOFFICE.TEAM.ONBOARDING_STATUS_NOT_INVITED',
  INVITATION_EXPIRED: 'BACKOFFICE.TEAM.ONBOARDING_STATUS_INVITATION_EXPIRED',
};

export const isUserNotInvited = (account) => {
  if (!account) {
    return false;
  }
  return account.status === ACCOUNT_STATUS.NOT_INVITED;
};

export const hasInvitationLinkExpired = (account) => {
  if (!account) {
    return false;
  }
  return account.status === ACCOUNT_STATUS.INVITATION_EXPIRED;
};

export const isUserRegistered = (account) => {
  if (!account) {
    return false;
  }
  return account.status === ACCOUNT_STATUS.REGISTERED;
};

export const isUserInvited = (account) => {
  if (!account) {
    return false;
  }
  return account.status === ACCOUNT_STATUS.INVITED;
};

export default {
  isUserInvited,
  isUserRegistered,
  isUserNotInvited,
  hasInvitationLinkExpired,
  ACCOUNT_STATUS,
};
