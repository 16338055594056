import { cloneDeep } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import {
  makeXLS,
  generateDefaultSheet,
  generateDatesSheetFromStartEndDate,
  generateStoresSheet,
} from '@commons/utils/makeXLS';

import getColumnsTable from './columns';

const formatAnalyticsForExportXLS = (analytics) => {
  const ratioColumns = ['conformity', 'cadence'];

  const strColumns = ['productName', 'productId'];

  const analyticsClone = cloneDeep(analytics);

  return analyticsClone.map((analyticsLine) => {
    Object.keys(analyticsLine).forEach((analyticsField) => {
      if (ratioColumns.includes(analyticsField)) {
        analyticsLine[analyticsField] = Math.round(analyticsLine[analyticsField] * 10000) / 100;
      } else if (!strColumns.includes(analyticsField)) {
        analyticsLine[analyticsField] = Math.round(analyticsLine[analyticsField]);
      }
    });

    return analyticsLine;
  });
};

const getExportFile = ({
  data,
  endDate,
  currency,
  startDate,
  storeName,
  selectedMetric,
  selectedStores,
  hasMultipleBrands,
  analyticsColumns,
}) => {
  const clientStoreName = getClientStoreNameTranslation(storeName, true);

  const formattedAnalytics = formatAnalyticsForExportXLS(data);

  const sheets = [
    generateDefaultSheet(
      `${i18next.t('FEATURE.PRODUCTION.ANALYTICS_REFERENCES')} en ${
        selectedMetric.key === 'turnover'
          ? i18next.t('PRODUCTION.REAL_TIME.METRIC_TURNOVER')
          : i18next.t('PRODUCTION.REAL_TIME.METRIC_UNIT')
      }`,
      getColumnsTable(currency, selectedMetric.key, hasMultipleBrands, analyticsColumns),
      formattedAnalytics,
      currency,
    ),
    generateStoresSheet(clientStoreName, selectedStores),
    generateDatesSheetFromStartEndDate(
      moment(startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      moment(endDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
    ),
  ];

  makeXLS(i18next.t('PRODUCTION.BY_REFERENCE.EXPORT_FILENAME'), sheets);
};

export default getExportFile;
