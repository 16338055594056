import _ from 'lodash';
import i18next from 'i18next';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Resizer from 'react-image-file-resizer';

import {
  Action,
  ActionsContainer,
  Box,
  Container,
  Icon,
  IconContainer,
  OpaqueBackgroundHover,
  Text,
} from './styledComponents';

const COMPRESSED_FILE_QUALITY = 70;
const MAX_MB_FILES_UPLOAD = 10;
const MB_VALUE_IN_KB = 1048576;

export const MultiplePictureUpload = forwardRef((props, ref) => {
  const {
    initialPictures,
    multipleFiles,
    readOnly,
    showMessage,
    displayDropdownActions,
    setDisplayDropdownActions,
    isSizeTooHeavy,
    setIsSizeTooHeavy,
    onSelectionChange,
  } = props;

  const [selectedPictures, setSelectedPictures] = useState([]);

  const [dropDownTopPosition, setDropDownTopPosition] = useState(0);

  const isTablet = window.innerWidth <= 1280;

  useEffect(() => {
    if (!initialPictures) {
      return;
    }

    const updatedSelection = initialPictures.reduce((result, url) => {
      if (url) {
        result.push({ data: url, file: null });
      }

      return result;
    }, []);

    setSelectedPictures(updatedSelection);
  }, [initialPictures]);

  useImperativeHandle(
    ref,
    () => ({
      getSelectedPictures() {
        return selectedPictures;
      },
    }),
    [selectedPictures],
  );

  const loadPictures = (files) =>
    new Promise((resolve, reject) => {
      const selectedPicturesDuringUpload = [...selectedPictures];

      files.forEach((file, index) => {
        let fileCopy = file;

        fileCopy.path = file.name;

        const reader = new FileReader();

        reader.onerror = reject;
        reader.onload = () => {
          if (!multipleFiles) {
            resolve([{ data: reader.result, file }]);
          }
          selectedPicturesDuringUpload.unshift({ data: reader.result, file });
          if (index === files.length - 1) {
            resolve(selectedPicturesDuringUpload);
          }
        };

        reader.readAsDataURL(fileCopy);
      });
    });

  const imageCompressor = async (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1000,
        1000,
        'JPEG',
        COMPRESSED_FILE_QUALITY,
        0,
        (uri) => {
          resolve(uri);
        },
        'file',
      );
    });

  const handlePictureUpload = async (files) => {
    if (!files) {
      return;
    }

    const filesArray = _.values(files);
    const compressedPictures = [];

    try {
      for (let index = 0; index < files.length; index++) {
        compressedPictures.push(await imageCompressor(filesArray[index]));
      }
    } catch (error) {
      showMessage(i18next.t('ORDERS.ORDERS.FORM_COMPRESSION_ERROR'), 'error');
    }

    try {
      const loadedPictures = await loadPictures(compressedPictures);
      setSelectedPictures(loadedPictures);

      // To not trigger callback whereas state selectedPictures has not been updated yet
      if (onSelectionChange) {
        setTimeout(() => {
          onSelectionChange(loadedPictures);
        }, 250);
      }
    } catch (error) {
      showMessage(i18next.t('ORDERS.ORDERS.FORM_LOAD_PICTURES_ERROR'), 'error');
    }
  };

  const openPicture = (picture) => {
    setDisplayDropdownActions(-1);
    const w = window.open('about:blank');

    setTimeout(() => {
      w.document.body.appendChild(w.document.createElement('iframe')).src = picture;

      w.document.getElementsByTagName('iframe')[0].style.width = 'calc(100% - 8px)';
      w.document.getElementsByTagName('iframe')[0].style.height = 'calc(100% - 8px)';
    }, 0);
  };

  const deletePicture = (indexPictureToDelete) => {
    const updatedPictureList = selectedPictures.filter(
      (_, index) => indexPictureToDelete !== index,
    );

    setDisplayDropdownActions(-1);
    setSelectedPictures(updatedPictureList);

    // To not trigger callback whereas state selectedPictures has not been updated yet
    if (onSelectionChange) {
      setTimeout(() => {
        onSelectionChange();
      }, 250);
    }

    if (!multipleFiles && isSizeTooHeavy) {
      setIsSizeTooHeavy(false);
    }
  };

  const getDropDownTopPosition = (index) => {
    const topPosition = document.getElementById(index).getBoundingClientRect();
    setDropDownTopPosition(topPosition.top);
  };

  const checkPictureSize = () => {
    if (!setIsSizeTooHeavy) {
      return;
    }

    const size = selectedPictures[0].file.size;
    //Converting the size to MB
    if (size / MB_VALUE_IN_KB > MAX_MB_FILES_UPLOAD) {
      return setIsSizeTooHeavy(true);
    }
    setIsSizeTooHeavy(false);
  };

  useEffect(() => {
    if (!!multipleFiles || !selectedPictures || selectedPictures.length === 0) {
      return;
    }

    checkPictureSize();
  }, [selectedPictures]);

  return (
    <>
      <Container error={isSizeTooHeavy} narrow={!multipleFiles} readOnly={readOnly}>
        <IconContainer>
          <Box disabled={readOnly} url={'/images/inpulse/addpicture-white-small.svg'} fileInput>
            {!readOnly && (
              <input
                accept=".jpg,.jpeg,.png"
                id="files"
                multiple={multipleFiles}
                type="file"
                onChange={(event) => handlePictureUpload(event.target.files)}
                onClick={(event) => (event.target.value = null)}
              />
            )}
          </Box>
        </IconContainer>
        {selectedPictures.map((picture, index) => (
          <Box
            highlighted={displayDropdownActions === index}
            id={index}
            key={index}
            url={picture.data}
            full
            onClick={(event) => {
              event.stopPropagation();

              if (index === displayDropdownActions) {
                setDisplayDropdownActions(-1);
              } else {
                setDisplayDropdownActions(index);
              }
              getDropDownTopPosition(index);
            }}
          >
            <OpaqueBackgroundHover isOpaque={index === displayDropdownActions} />
            {displayDropdownActions === index && (
              <ActionsContainer dropDownTopPosition={dropDownTopPosition} isTablet={isTablet}>
                <Action
                  onClick={(event) => {
                    event.stopPropagation();
                    openPicture(picture.data);
                  }}
                >
                  <Icon alt="open-picture" src="/images/inpulse/open-a-new-black-small.svg" />
                  <Text>{i18next.t('GENERAL.SEE_PICTURE')}</Text>
                </Action>
                <Action
                  readOnly={readOnly}
                  onClick={(event) => {
                    event.stopPropagation();
                    !readOnly && deletePicture(index);
                  }}
                >
                  <Icon
                    alt="delete-picture"
                    src={`/images/inpulse/delete-${readOnly ? 'lmgrey' : 'black'}-small.svg`}
                  />
                  <Text grey={readOnly}>{i18next.t('GENERAL.DELETE_PICTURE')}</Text>
                </Action>
              </ActionsContainer>
            )}
          </Box>
        ))}
        {multipleFiles &&
          selectedPictures.length < 5 &&
          _.times(5 - selectedPictures.length, (index) => (
            <Box disabled={true} key={`disabledBox-${index}`} />
          ))}
        {!multipleFiles && selectedPictures.length < 1 && <Box disabled={true} />}
      </Container>
      {isSizeTooHeavy && (
        <Text style={{ marginTop: '4px' }} red textSmall>
          {i18next.t('GENERAL.FILE_TOO_HEAVY', { maxSize: MAX_MB_FILES_UPLOAD })}
        </Text>
      )}
    </>
  );
});

MultiplePictureUpload.defaultProps = {
  readOnly: false,
  multipleFiles: true,
  onSelectionChange: null,
};

export default MultiplePictureUpload;
