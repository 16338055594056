import i18next from 'i18next';

export const getCompositionLabel = (entity) => {
  if (entity) {
    return entity.isIngredient
      ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_INGREDIENT')
      : i18next.t('GENERAL.RECIPE');
  }

  return i18next.t('GENERAL.NONE_VALUE');
};

export const getChannelLabel = (entity) => {
  if (entity.isIngredient || !entity.hasMultipleChannels) {
    return i18next.t('ADMIN.RECIPES.EXPORT_VALUE_SINGLE_CHANNEL');
  }

  return entity.channel;
};
