import { APIcaller } from '../APIcaller';

const getAllCurrencies = async () => APIcaller.apiGet(`/backoffice/currency`);

const getClientCurrency = async (clientCurrencyId) => {
  const allCurrencies = await currencyService.getAllCurrencies();

  const currentClientCurrency = allCurrencies.find((currency) => currency.id === clientCurrencyId);

  if (!currentClientCurrency) {
    return null;
  }

  return {
    ...currentClientCurrency,
    name: `${currentClientCurrency.name} (${currentClientCurrency.alphabeticCode})`,
    shortenedName: currentClientCurrency.name,
  };
};

export const currencyService = {
  getAllCurrencies,
  getClientCurrency,
};
