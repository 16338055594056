import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${(props) => props.theme.colors.greys.lighter};
`;

export const Header = styled.div`
  width: 100%;
  height: 88px;

  background-color: ${(props) => props.theme.colors.white};
`;

export const Form = styled.div`
  width: 100%;
`;
