import { user } from '../services/user';

export const logoutSuccess = () => ({
  type: 'LOGOUT_SUCCESS',
});

export const logoutFailure = (error) => ({
  type: 'LOGOUT_FAILURE',
  error,
});

export const logout = () =>
  function (dispatch) {
    dispatch({
      type: 'LOGOUT_REQUEST',
    });
    user.logout().then(
      () => {
        // Useful to remove session information related to Cryptr login
        sessionStorage.clear();

        dispatch(logoutSuccess());
      },
      (error) => {
        dispatch(logoutFailure(error));
      },
    );
  };
