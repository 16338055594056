import { cloneDeep, first, flatten, get, isEmpty, isEqual, keys, orderBy } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openSmallModal } from '@actions/modal';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { FiltersContext } from '@context/FiltersContext';

import useLocalStorage from '@hooks/useLocalStorage';
import useReloadPageWarning from '@hooks/useReloadPageWarning';

import { canIncrementProductionOperationsProducedOnEANScan } from '@selectors/actions/productionActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { planningOperation as planningOperationService } from '@services/planningOperation';
import { planningRecommendation as planningRecommandationService } from '@services/planningRecommendation';
import { planning as planningService } from '@services/planning';

import { buildDateWithTime, fetchRanges } from '@productions/services/ranges';
import { canPageBeRenderedInOffline } from '@productions/utils/isRenderedOffline';
import { fetchStoresUser } from '@productions/services/stores';
import { period } from '@productions/constants/period';
import { planningType } from '@productions/constants/planning';

import { OfflineProductionPage } from '../OfflinePage/OfflineProductionPage';
import { OfflineSynchronisationModal } from '../OfflineSynchronisationModal';
import PlanningHeader from '../PlanningHeader';
import PlanningListing from '../PlanningListing';
import WarningModal from '../WarningModal';

import { Container, ContainerContent, FullHeightContainer, ShadowedBox } from './styledComponents';
import { getPercentage } from './utils/format';
import { sortProductsByCategoriesByProperty, sortProductsByProperty } from './utils/sort';

const TIME_FORMAT = 'HH:mm:ss';
const INPUT_NUMBER_MAX_VALUE = 30;

const MAXIMUM_REQUEST_IN_PENDING_TO_PLAY_IN_BACKGROUND = 20;

const OTHER_CATEGORY = 'autres';

const DEFAULT_TURNOVER_SUMMARY = {
  totalByBrands: null,
  producedTurnover: null,
  toProduceTurnover: null,
  producedTurnoverPercentage: 0,
};

const DEFAULT_WARNING_MODAL_SETUP = {
  active: false,
  product: null,
  tot: null,
};

const EMPTY_ACTIVE_PRODUCT = {
  id: '',
  case: '',
  value: '',
};

const SORT_PROPERTY_NAME_MAPPING = {
  produced: 'production',
  'to-produce': 'recommendation',
  left: 'leftToProduce',
};

/*********************/
/** Handler Methods **/
/*********************/

export const handleSelectedBarcode = async (
  useCase,
  research,
  products,
  productsByCategory,
  displayProductsByCategory,
  setActiveProduct,
  setFilteredProducts,
  setFilteredProductsByCategory,
  activeProduct,
  productIdSuccess,
  updatedProps,
  authorizedActions,
) => {
  if (research) {
    const filteredProducts = products.filter((product) => {
      if (product.name.toLowerCase().includes(research.toLowerCase())) {
        return true;
      }

      return product.sku === research;
    });

    setFilteredProducts(filteredProducts);

    if (displayProductsByCategory) {
      const filteredProductsByCategory = {};

      Object.keys(productsByCategory).forEach((category) => {
        const products = productsByCategory[category].filter((product) => {
          if (product.name.toLowerCase().includes(research.toLowerCase())) {
            return true;
          }

          return product.sku === research;
        });

        if (products.length > 0) {
          filteredProductsByCategory[category] = products;
        }
      });
      setFilteredProductsByCategory(filteredProductsByCategory);
    }

    if (filteredProducts.length === 1 && useCase === period.CURRENT) {
      setActiveProduct({
        id: filteredProducts[0].productId,
        case: 'produced',
        value: '',
        shouldResetSearchInput: true,
      });

      if (
        filteredProducts[0].name.toLowerCase() !== research.toLowerCase() &&
        filteredProducts[0].sku !== research
      ) {
        return;
      }

      if (
        canIncrementProductionOperationsProducedOnEANScan(authorizedActions) &&
        !activeProduct.id
      ) {
        await handleProducedSave(updatedProps, filteredProducts[0], 1);
        setActiveProduct({
          ...EMPTY_ACTIVE_PRODUCT,
          isScanAdd: true,
          shouldResetSearchInput: true,
        });
        setFilteredProducts(products);
        setFilteredProductsByCategory(productsByCategory);
      }
    }
  } else {
    setFilteredProducts(products);
    setFilteredProductsByCategory(productsByCategory);

    if (activeProduct.id === productIdSuccess) {
      setActiveProduct(EMPTY_ACTIVE_PRODUCT);
    }
  }
};

/**
 * Handle the printing of the planning from the selection chosen by the user (date, store, timeslot)
 *
 * @param {Store} selectedStore         - The selected store
 * @param {Date} planningDate           - The selected date in the datepicker
 * @param {TimeSlot} selectedTimeSlot   - The information of the selected timeslot
 * @param {Function} pageLoading        - The method to display the loader and set the page as loading
 * @param {Function} pageLoading        - The method to not display the loader and set the page as loaded
 * @param {Function} showMessage        - The method to display message relating to the request status
 *
 * @returns {Promise<void>}
 */
export async function handlePrintPlanning(
  selectedStore,
  planningDate,
  selectedTimeSlot,
  pageLoading,
  pageLoaded,
  showMessage,
) {
  if (!selectedTimeSlot || !selectedTimeSlot.startHour || !selectedTimeSlot.endHour) {
    showMessage(i18next.t('PRODUCTION.PRODUCTION.PRINT_ERROR_MESSAGE'), 'error');
    return;
  }

  await postProductionPlanning(
    selectedStore.storeId,
    planningDate,
    selectedTimeSlot.startHour,
    selectedTimeSlot.endHour,
    pageLoading,
    pageLoaded,
    showMessage,
  );
}

/**
 * Save of the new quantity to produce for the selected timeslot to apply on the given product id
 *
 * @param {Object} props       - The props linked to the component
 * @param {Number} value      - The value representing the quantity to produce for the given product id
 * @param {String} productId  - The product id on which apply the quantity to produce
 *
 * @returns {Promise<void>}
 */
export async function handleToProduceSave(
  props,
  value,
  productId,
  enableProductMixModification = false,
) {
  const { planningDate, selectedStore, selectedTimeSlot, showMessage } = props;

  try {
    await planningRecommandationService.postPlanningRecommendation(
      selectedStore.storeId,
      productId,
      planningDate.format(),
      selectedTimeSlot.startTime.format(),
      selectedTimeSlot.endTime.format(),
      value,
      enableProductMixModification,
    );

    await refreshPlanningData(props);

    highlightProductFromSuccessfulUpdate(productId, props);
  } catch (err) {
    showMessage(i18next.t('PRODUCTION.PRODUCTION.TOP_ERROR_MESSAGE_PRODUCED'), 'error');

    highlightProductFromFailedUpdate(productId, props);
  }
}

/**
 * Save of the new quantity produce inside context
 *
 * @param {Object} props       - The props linked to the component
 * @param {Object} product      - The product to save update
 * @param {Number} prodInputValue  - The quantity produced
 * @param {Moment} currentDate - The moment value of the current day for the selected store
 *
 * @returns {Promise<void>}
 */
export async function handleProducedSaveInContext(props, product, prodInputValue, currentDate) {
  const {
    products,
    selectedStore,
    productionPlanningRawData,
    setProductionPlanningRawData,
    productionRequestsToPlay,
    setProductionRequestsToPlay,
  } = props;

  const formattedDate = moment(currentDate).tz(selectedStore.timezone).format('YYYY-MM-DD');

  const updatedProductionPlanning = cloneDeep(productionPlanningRawData[formattedDate]);

  if (!updatedProductionPlanning || !updatedProductionPlanning.dataByRange[product.productId]) {
    highlightProductFromFailedUpdate(product.productId, props);

    return;
  }

  updatedProductionPlanning.dataByRange[product.productId].production += prodInputValue;

  const updatedTotalTurnoverProduced = keys(updatedProductionPlanning.dataByRange).reduce(
    (total, productId) => {
      const matchingProduct = products.find((item) => productId === item.productId);

      if (!matchingProduct) {
        return total;
      }

      const priceProduct = get(matchingProduct, 'price', 0);

      const production = updatedProductionPlanning.dataByRange[productId].production;

      total += production * priceProduct;

      return total;
    },
    0,
  );

  updatedProductionPlanning.totalTurnoverProduced =
    Math.ceil(updatedTotalTurnoverProduced * 100) / 100;

  const payloadRequest = {
    storeId: selectedStore.storeId,
    productId: product.productId,
    timestamp: currentDate.format(),
    tot: prodInputValue,
    type: 'production',
    price: product.price,
  };

  const updatedProductPlanningRequestsToplay = Array.isArray(productionRequestsToPlay)
    ? productionRequestsToPlay.concat(payloadRequest)
    : [payloadRequest];

  setProductionRequestsToPlay(updatedProductPlanningRequestsToplay);

  const updatedProductionPlanningRawData = {
    ...productionPlanningRawData,
    [formattedDate]: updatedProductionPlanning,
  };

  setProductionPlanningRawData(updatedProductionPlanningRawData);

  highlightProductFromSuccessfulUpdate(product.productId, props);

  return refreshPlanningData({
    ...props,
    productionPlanningRawData: updatedProductionPlanningRawData,
  });
}

/**
 * Save of the new produced quantity for the selected timeslot to apply on the given product
 *
 * @param {Props} props               - The props linked to the component
 * @param {Product} product           - The product on which apply the produced quantity of the selected timeslot
 * @param {Number} prodInputValue     - The value representing the produced quantity
 * @param {Boolean} confirmedByModal  - (optional) Whether the user validate through the confimation modal
 *
 * @returns {Promise<void>}
 */
export async function handleProducedSave(props, product, prodInputValue, confirmedByModal = false) {
  const { selectedStore, warningModal, setWarningModal, showMessage, shouldUseContext } = props;

  const currentDate = moment().tz(selectedStore.timezone);

  if (!prodInputValue || isNaN(prodInputValue)) {
    return;
  }

  if (prodInputValue > INPUT_NUMBER_MAX_VALUE && (!confirmedByModal || !warningModal.active)) {
    setWarningModal({ active: true, product, tot: prodInputValue });

    return;
  }

  // Handle request to keep in local storage to be played later when context is being used
  if (shouldUseContext) {
    return handleProducedSaveInContext(props, product, prodInputValue, currentDate);
  }

  try {
    await planningOperationService.postOperation(
      selectedStore.storeId,
      product.productId,
      currentDate.format(),
      prodInputValue,
      'production',
      product.price,
    );

    await refreshPlanningData(props);

    highlightProductFromSuccessfulUpdate(product.productId, props);
  } catch (err) {
    showMessage(i18next.t('PRODUCTION.PRODUCTION.TOP_ERROR_MESSAGE_PRODUCED'), 'error');

    highlightProductFromFailedUpdate(product.productId, props);
  }
}

/**
 * Update the selected timeslot from user's selection
 *
 * @param {String} hourName               - The name of the selected timeslot from the related component to match and apply on the state
 * @param {Timeslot[]} product            - The list of timeslots available for the selected store
 * @param {Function} setSelectedTimeSlot  - The method to updated the selected timeslot by the user
 *
 * @returns {Promise<void>}
 */
export function handleSelectedTimeSlot(
  hourName,
  timeSlots,
  setSelectedTimeSlot,
  productionPlanningRawData,
  setProductionPlanningRawData,
) {
  const matchingTimeSlot = timeSlots.find((slot) => slot.name === hourName);

  if (matchingTimeSlot) {
    setSelectedTimeSlot(matchingTimeSlot);

    setProductionPlanningRawData({
      ...productionPlanningRawData,
      selectedTimeSlot: matchingTimeSlot,
    });
  }
}

/**
 * Temporarily set the product id on which the call was performed to the productIdSuccess state
 * to notify user on the outcome of the API call previously made
 *
 * @param {Product} productId - The product id on which the call succeeded
 * @param {Object} props       - The props linked to the component
 *
 * @returns {void}
 */
export function highlightProductFromSuccessfulUpdate(productId, props) {
  const { setProductIdSuccess } = props;

  setProductIdSuccess(productId);

  setTimeout(() => {
    setProductIdSuccess(null);
  }, 2000);
}

/**
 * Temporarily set the product id on which the call was performed to the productIdError state
 * to notify user on the outcome of the API call previously made
 *
 * @param {Product} productId - The product id on which the call failed
 * @param {Object} props       - The props linked to the component
 *
 * @returns {Promise<void>}
 */
export function highlightProductFromFailedUpdate(productId, props) {
  const { setProductIdError } = props;

  setProductIdError(productId);

  setTimeout(() => {
    setProductIdError(null);
  }, 2000);
}

export function handleSort(
  name,
  category,
  sortProperties,
  setSortProperties,
  productsByCategory,
  setProductsByCategory,
  setProducts,
  dataByRange,
  displayProductsByCategory,
) {
  let sortedProducts;
  const updatedOrder =
    sortProperties.name !== name ? 'asc' : sortProperties.order === 'desc' ? 'asc' : 'desc';

  if (displayProductsByCategory) {
    sortedProducts = sortProductsByProperty(
      productsByCategory[category],
      dataByRange,
      SORT_PROPERTY_NAME_MAPPING[name],
      updatedOrder,
    );

    productsByCategory[category] = sortedProducts;
    setProductsByCategory(productsByCategory);
  } else {
    setProducts(
      sortProductsByProperty(
        flatten(Object.values(productsByCategory)),
        dataByRange,
        SORT_PROPERTY_NAME_MAPPING[name],
        updatedOrder,
      ),
    );
  }

  setSortProperties({ name: name, category: category, order: updatedOrder });
}

/*****************/
/** API Methods **/
/*****************/

/**
 * Fetch the planning recommendations for the store, date and timeslot selected by the user
 *
 * @param {String} storeId        - The selected store
 * @param {Date} date             - The selected date in the datepicker
 * @param {Date} startHour        - The starting hour matching the selected timeslot
 * @param {Date} endHour          - The ending hour matching the selected timeslot
 * @param {Function} showMessage  - The method to display message relating to the request status
 *
 * @returns {Promise<PlanningRecommendations>} The planning recommendations associated to the user's selection
 */
export async function getPlanningData(
  storeId,
  date,
  startHour,
  endHour,
  showMessage,
  shouldUseContext,
  productionPlanningRawData,
  setProductionPlanningRawData,
  timezone,
) {
  const formattedDate = moment(date).tz(timezone).format('YYYY-MM-DD');

  if (shouldUseContext) {
    const productionPlanningOfTheDay = get(productionPlanningRawData, formattedDate);

    if (productionPlanningOfTheDay) {
      return productionPlanningOfTheDay;
    }

    const originalProductionPlanningRawData = get(productionPlanningRawData, 'data', {});

    const resetOriginalProductionPlanning = cloneDeep(originalProductionPlanningRawData);

    if (!isEmpty(resetOriginalProductionPlanning.dataByRange)) {
      Object.keys(resetOriginalProductionPlanning.dataByRange).forEach((productId) => {
        resetOriginalProductionPlanning.dataByRange[productId].production = 0;
      });
    } else {
      resetOriginalProductionPlanning.dataByRange = {};
      resetOriginalProductionPlanning.productsByCategory = {};
    }

    resetOriginalProductionPlanning.totalTurnoverProduced = 0;

    setProductionPlanningRawData({
      ...productionPlanningRawData,
      [formattedDate]: resetOriginalProductionPlanning,
    });

    return resetOriginalProductionPlanning;
  }

  try {
    const planningRecommendations = await planningRecommandationService.getProductionData(
      storeId,
      date,
      startHour,
      endHour,
    );

    setProductionPlanningRawData({
      data: cloneDeep(planningRecommendations),
      [formattedDate]: cloneDeep(planningRecommendations),
      selectedStore: productionPlanningRawData.selectedStore,
      selectedTimeSlot: productionPlanningRawData.selectedTimeSlot,
    });

    return planningRecommendations;
  } catch (err) {
    showMessage(i18next.t('PRODUCTION.PRODUCTION.TOP_ERROR_MESSAGE_PRODUCTION'), 'error');

    return null;
  }
}

/**
 * Send the production planning
 *
 * @param {String} storeId        - The selected store id
 * @param {Date} planningDate     - The selected date in the datepicker
 * @param {Date} startTime        - The starting time matching the selected timeslot
 * @param {Date} endTime          - The ending time matching the selected timeslot
 * @param {Function} pageLoading  - The method to display the loader and set the page as loading
 * @param {Function} pageLoading  - The method to not display the loader and set the page as loaded
 * @param {Function} showMessage  - The method to display message relating to the request status
 *
 * @returns {Promise<void>}
 */
export async function postProductionPlanning(
  storeId,
  planningDate,
  startHour,
  endHour,
  pageLoading,
  pageLoaded,
  showMessage,
) {
  pageLoading();

  try {
    await planningService.sendProductionPlanning(storeId, planningDate, startHour, endHour);
  } catch (err) {
    showMessage(i18next.t('PRODUCTION.PRODUCTION.PRINT_ERROR_MESSAGE'), 'error');
  } finally {
    pageLoaded();
  }
}

/*******************/
/** Utils Methods **/
/*******************/

/**
 * Get use case to apply on planning listing from the selected date in the date picker
 * and also the selected timeslot
 *
 * @param {Date} planningDate         - The selected date in the datepicker
 * @param {Array<Object>} selectedTimeSlot - The selected timeslot
 * @param {timezone} timezone - The timezone of the selected store
 *
 * @returns {String} The use case to apply from user's selection
 */
export function getUseCaseFromSelectedTimeSlot(planningDate, selectedTimeSlot, timezone) {
  const currentTimestamp = moment().tz(timezone);

  if (planningDate.isAfter(currentTimestamp, 'days')) {
    return period.AFTER;
  }

  if (selectedTimeSlot.startTime.isAfter(currentTimestamp)) {
    return period.AFTER;
  }

  if (selectedTimeSlot.endTime.isBefore(currentTimestamp)) {
    return period.BEFORE;
  }

  return period.CURRENT;
}

/**
 * Get the timeslot to automatically select on the first render from the list of timeslots
 * available for a store and the planning date selected by the user
 *
 * @param {Array<Object>} timeSlots - The list of timeslots
 * @param {Date} planningDate    - The selected date in the datepicker
 * @param {String} timezone    - The timezone of the selected store
 *
 * @returns {Object} The timeslot to set on first render
 */
export function getCurrentTimeslot(timeSlots, planningDate, timezone) {
  const isClosed = !timeSlots.length;

  if (isClosed) {
    return {};
  }

  const isInFuture = planningDate.isAfter(moment().tz(timezone), 'days');

  // Set first timeslot by default when selected date in is the future
  if (isInFuture) {
    return timeSlots[0];
  }

  const currentTime = moment().tz(timezone).format(TIME_FORMAT);

  const currentTimeslot = timeSlots.find(
    (range) =>
      moment(range.startHour, TIME_FORMAT)
        .tz(timezone)
        .isBefore(moment(currentTime, TIME_FORMAT).tz(timezone)) &&
      moment(range.endHour, TIME_FORMAT)
        .tz(timezone)
        .isAfter(moment(currentTime, TIME_FORMAT).tz(timezone)),
  );

  // When current time matches an actual timeslot, return it
  if (currentTimeslot) {
    return currentTimeslot;
  }

  const closestRangeIndex = timeSlots.findIndex(
    (range) => moment(range.startHour).tz(timezone) > currentTime,
  );

  // When current time is between two timeslots, return the next timeslot available
  if (closestRangeIndex >= 0) {
    return timeSlots[closestRangeIndex];
  }

  // When current time is after any timeslot, return latest one as end of the day
  return timeSlots[timeSlots.length - 1];
}

/**
 * Check whether the store is closed on the selected date
 *
 * @param {Store} selectedStore - The store selected by the user
 * @param {Date} planningDate   - The selected date in the datepicker
 *
 * @returns {Boolean} Whether the store is closed on the selected planning date
 */
export function isStoreClosedOnDate(selectedStore, planningDate) {
  const isoWeekDaysStoreClosed = selectedStore.closingDays
    ? selectedStore.closingDays.split(',').map((day) => parseInt(day, 10))
    : [];

  return isoWeekDaysStoreClosed.includes(planningDate.isoWeekday() - 1);
}

/**
 * Set the different states to display properly the empty state related to a store closed
 *
 * @param {Function} setTimeSlots         - The method to set timeslots and avoid dropdown listing whereas store is closed
 * @param {Function} setUseCase           - The method to set the appropriate use case and tso display empty state in child component
 * @param {Function} setSelectedTimeSlot  - The method to set the selected timeslot to display default value when store is closed
 * @param {Function} setTurnoverSummary   - The method to set the turnover summary to the empty state as no recommendation will be fetched
 * @param {Function} setIsLoading         - The method to set the isLoading state to false and make sure to render page
 *
 * @returns {void}
 */
export function setEmptyStateStoreClosed(
  setTimeSlots,
  setUseCase,
  setSelectedTimeSlot,
  setTurnoverSummary,
  setIsLoading,
) {
  setTimeSlots([]);
  setUseCase(period.CLOSED);
  setSelectedTimeSlot({ name: i18next.t('GENERAL.CLOSED') });
  setTurnoverSummary(DEFAULT_TURNOVER_SUMMARY);

  setIsLoading(false);
}

/**
 * Fetch the planning recommendations from the selected store, date and timeslot
 *
 * @param {Props} props - The props linked to the component
 *
 * @returns {Promise<void>}
 */
export async function refreshPlanningData(props) {
  const {
    useCase,
    selectedStore,
    planningDate,
    selectedTimeSlot,
    showMessage,
    setDataByRange,
    setDisplayProductsByCategory,
    setProductsByCategory,
    setProducts,
    setTurnoverSummary,
    sortProperties,
    reportToProduceFromPreviousRanges,
    productionPlanningRawData,
    setProductionPlanningRawData,
    shouldUseContext,
    client: { hasMultipleBrands },
  } = props;

  const result = await getPlanningData(
    selectedStore.storeId,
    planningDate.format(),
    selectedTimeSlot.startHour,
    selectedTimeSlot.endHour,
    showMessage,
    shouldUseContext,
    productionPlanningRawData,
    setProductionPlanningRawData,
    selectedStore.timezone,
  );

  if (!result) {
    return;
  }

  const dataByRange = result.dataByRange;

  Object.keys(dataByRange).forEach((productId) => {
    const recommendation = dataByRange[productId]['recommendation'];
    const production = dataByRange[productId]['production'];
    const remainingFromPreviousRanges = dataByRange[productId].hasOwnProperty(
      'remainingFromPreviousRanges',
    )
      ? dataByRange[productId]['remainingFromPreviousRanges']
      : null;

    const shouldReportFromPreviousRanges =
      reportToProduceFromPreviousRanges &&
      useCase === period.CURRENT &&
      remainingFromPreviousRanges;

    dataByRange[productId]['leftToProduce'] = shouldReportFromPreviousRanges
      ? recommendation - production + remainingFromPreviousRanges
      : recommendation - production;
  });

  setDataByRange(dataByRange);

  const hasMultipleProductCategories =
    Object.keys(result.productsByCategory).length > 1 || !result.productsByCategory[OTHER_CATEGORY];

  // Force category other to be the last displayed
  if (!!result.productsByCategory[OTHER_CATEGORY]) {
    const copyProductsOtherCategory = result.productsByCategory[OTHER_CATEGORY];

    delete result.productsByCategory[OTHER_CATEGORY];

    result.productsByCategory[OTHER_CATEGORY] = copyProductsOtherCategory;
  }

  // Whether we display the list of products by category
  setDisplayProductsByCategory(hasMultipleProductCategories);

  // Set list of products grouped by categories
  setProductsByCategory(
    sortProductsByCategoriesByProperty(
      result.productsByCategory,
      result.dataByRange,
      SORT_PROPERTY_NAME_MAPPING[sortProperties.name] || 'recommendation',
      sortProperties.order,
    ),
  );

  // Set list of products
  setProducts(
    sortProductsByProperty(
      flatten(Object.values(result.productsByCategory)),
      result.dataByRange,
      SORT_PROPERTY_NAME_MAPPING[sortProperties.name] || 'recommendation',
      sortProperties.order,
    ),
  );

  // Set turnover sumup values
  setTurnoverSummary({
    totalByBrands: hasMultipleBrands ? orderBy(result.totalByBrands, 'brand.name') : null,
    toProduceTurnover: result.totalTurnoverToProduce,
    producedTurnover: result.totalTurnoverProduced,
    producedTurnoverPercentage: getPercentage(
      result.totalTurnoverProduced,
      result.totalTurnoverToProduce,
    ),
  });
}

export async function handleProductionPendingRequest(payload) {
  const { storeId, productId, timestamp, tot, type, price } = payload;

  if (!storeId || !productId || !timestamp || !tot || !type || !price) {
    return false;
  }

  try {
    await planningOperationService.postOperation(
      storeId,
      productId,
      timestamp,
      tot,
      type,
      price,
      true,
    );

    return true;
  } catch (err) {
    return false;
  }
}

export async function processPendingRequestPlanningOperation(props) {
  const {
    pageLoaded,
    pageLoading,
    showMessage,
    confirmationMessageReducer,
    productionRequestsToPlay,
    setProductionRequestsToPlay,
  } = props;

  pageLoading();

  const payload = first(productionRequestsToPlay);

  const successful = await handleProductionPendingRequest(payload);

  if (successful) {
    setProductionRequestsToPlay(productionRequestsToPlay.filter((item) => !isEqual(item, payload)));
  } else {
    pageLoaded();
  }

  if (productionRequestsToPlay.length === 1 && successful) {
    showMessage(
      i18next.t('PRODUCTION.PRODUCTION.OFFLINE_REQUEST_SYNCHRONIZED'),
      'success',
      confirmationMessageReducer.messageBool,
    );

    await refreshPlanningData(props);

    pageLoaded();
  }

  return successful;
}

/***************************/
/** Component Declaration **/
/***************************/

export const ProductionContainer = (props) => {
  const {
    printOption,
    enableProductMixModification,
    pageLoading,
    pageLoaded,
    showMessage,
    user,
    client: { hasMultipleServices, hasOffline },
    isUserOffline,
    isConnectionSlow,
    confirmationMessageReducer,
    match,
    authorizedActions,
  } = props;

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  const reportToProduceFromPreviousRanges = !hasMultipleServices;

  const [productionRequestsToPlay, setProductionRequestsToPlay] = useLocalStorage(
    'productionRequestsToPlay',
    [],
  );

  const [barcodeScannerElement] = useState(React.createRef());
  const [isLoading, setIsLoading] = useState(true);

  const [stores, setStores] = useState([{}]);
  const [selectedStore, setSelectedStore] = useState({});

  const [turnoverSummary, setTurnoverSummary] = useState(DEFAULT_TURNOVER_SUMMARY);

  const [useCase, setUseCase] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({});

  const [planningDate, setPlanningDate] = useState(null);

  const [products, setProducts] = useState([]);
  const [dataByRange, setDataByRange] = useState({});
  const [productsByCategory, setProductsByCategory] = useState({});
  const [displayProductsByCategory, setDisplayProductsByCategory] = useState(true);
  const [activeProduct, setActiveProduct] = useState(EMPTY_ACTIVE_PRODUCT);

  const [productIdError, setProductIdError] = useState(null);
  const [productIdSuccess, setProductIdSuccess] = useState(null);

  const [warningModal, setWarningModal] = useState(DEFAULT_WARNING_MODAL_SETUP);

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredProductsByCategory, setFilteredProductsByCategory] = useState({});

  const [isSynchronisationInProgress, setIsSynchronisationInProgress] = useState(false);

  const [sortProperties, setSortProperties] = useState({
    category: '',
    name: '',
    order: 'desc',
  });

  const [shouldUseContext, setShouldUseContext] = useState(isUserOffline || isConnectionSlow);

  const { productionPlanningRawData, setProductionPlanningRawData } = useContext(FiltersContext);

  const [, setShowReloadPageWarning] = useReloadPageWarning(true);

  useEffect(() => {
    setShowReloadPageWarning(shouldUseContext);
  }, [shouldUseContext]);

  useEffect(() => {
    const updatedShouldUseContext = hasOffline && (isUserOffline || isConnectionSlow);

    if (shouldUseContext !== updatedShouldUseContext) {
      setShouldUseContext(updatedShouldUseContext);

      if (!updatedShouldUseContext && !productionRequestsToPlay.length) {
        (async function loadData() {
          // Retrieve and set all stores in state
          const storesUser = await fetchStoresUser(showMessage, true);

          setStores(storesUser.filter(({ isKitchen }) => !isKitchen));

          if (!selectedStore.storeId && storesUser.length) {
            const store = {
              storeId: storesUser[0].id,
              storeName: storesUser[0].name,
              closingDays: storesUser[0].closingDays,
              timezone: storesUser[0].timezone,
            };

            setSelectedStore(store);

            setProductionPlanningRawData({
              ...productionPlanningRawData,
              selectedStore: store,
            });
          } else {
            let productionRanges = [];

            // Retrieve and set all timeslots in state
            if (updatedShouldUseContext) {
              const selectedTimeSlotInContext = get(
                productionPlanningRawData,
                'selectedTimeSlot',
                {},
              );

              // To take into consideration the potential new planning date that can change through offline use
              const formattedSelectedTimeSlot = {
                ...selectedTimeSlotInContext,
                startTime: buildDateWithTime(
                  moment(planningDate),
                  selectedTimeSlotInContext.startHour,
                  selectedStore.timezone,
                ),
                endTime: buildDateWithTime(
                  moment(planningDate),
                  selectedTimeSlotInContext.endHour,
                  selectedStore.timezone,
                ),
              };

              productionRanges = [formattedSelectedTimeSlot];
            } else {
              productionRanges = await fetchRanges(
                selectedStore,
                planningDate,
                userLanguageCode,
                showMessage,
              );
            }

            if (!productionRanges.length) {
              setEmptyStateStoreClosed(
                setTimeSlots,
                setUseCase,
                setSelectedTimeSlot,
                setTurnoverSummary,
                setIsLoading,
              );
            }

            setTimeSlots(productionRanges);
          }
        })();
      }
    }
  }, [hasOffline, isUserOffline, isConnectionSlow]);

  useEffect(() => {
    if (shouldUseContext) {
      return;
    }

    if (!productionRequestsToPlay.length) {
      return;
    }

    if (isSynchronisationInProgress || !selectedStore.storeId || !selectedTimeSlot.startHour) {
      return;
    }

    setIsSynchronisationInProgress(true);

    if (productionRequestsToPlay.length >= MAXIMUM_REQUEST_IN_PENDING_TO_PLAY_IN_BACKGROUND) {
      props.openModal({
        component: OfflineSynchronisationModal,
        showMessage,
        confirmationMessageReducer,
        setIsSynchronisationInProgress,
        refreshPage: () =>
          refreshPlanningData({
            ...props,
            useCase,
            selectedStore,
            planningDate,
            selectedTimeSlot,
            setDataByRange,
            setDisplayProductsByCategory,
            setProductsByCategory,
            setProducts,
            setTurnoverSummary,
            sortProperties,
            reportToProduceFromPreviousRanges,
            productionPlanningRawData,
            setProductionPlanningRawData,
            shouldUseContext,
            productionRequestsToPlay,
            setProductionRequestsToPlay,
          }),
      });

      return;
    }

    (async function playPendingRequests() {
      const successful = await processPendingRequestPlanningOperation({
        ...props,
        useCase,
        selectedStore,
        planningDate,
        selectedTimeSlot,
        setDataByRange,
        setDisplayProductsByCategory,
        setProductsByCategory,
        setProducts,
        setTurnoverSummary,
        sortProperties,
        reportToProduceFromPreviousRanges,
        productionPlanningRawData,
        setProductionPlanningRawData,
        shouldUseContext,
        productionRequestsToPlay,
        setProductionRequestsToPlay,
      });

      if (successful) {
        setIsSynchronisationInProgress(false);
      }
    })();
  }, [productionRequestsToPlay, shouldUseContext, selectedStore, selectedTimeSlot]);

  useEffect(() => {
    if (isSynchronisationInProgress) {
      return;
    }

    try {
      const item = window.localStorage.getItem('productionRequestsToPlay');

      if (item) {
        const parseLocalStorageValue = JSON.parse(item);

        setProductionRequestsToPlay(parseLocalStorageValue);
      }
    } catch (error) {
      console.log(error);
    }
  }, [isSynchronisationInProgress]);

  // Initial loading with user's stores fetching
  useEffect(() => {
    pageLoading();

    (async function loadData() {
      let storesUser = [];

      if (shouldUseContext) {
        const selectedStoreInContext = get(productionPlanningRawData, 'selectedStore');

        storesUser = [
          {
            id: selectedStoreInContext.storeId,
            name: selectedStoreInContext.storeName,
            closingDays: selectedStoreInContext.closingDays,
            timezone: selectedStoreInContext.timezone,
          },
        ];
      } else {
        storesUser = await fetchStoresUser(showMessage, true);
      }

      setStores(storesUser.filter(({ isKitchen }) => !isKitchen));

      if (storesUser.length) {
        const store = {
          storeId: storesUser[0].id,
          storeName: storesUser[0].name,
          closingDays: storesUser[0].closingDays,
          timezone: storesUser[0].timezone,
        };

        setSelectedStore(store);
        setPlanningDate(moment().tz(store.timezone));

        setProductionPlanningRawData({
          ...productionPlanningRawData,
          selectedStore: store,
        });
      }

      pageLoaded();
    })();

    return () => {
      setShowReloadPageWarning(false);
    };
  }, []);

  // Trigger ranges fetch when store or planning date is changed
  useEffect(() => {
    if (selectedStore.storeId && planningDate) {
      if (isStoreClosedOnDate(selectedStore, planningDate)) {
        return setEmptyStateStoreClosed(
          setTimeSlots,
          setUseCase,
          setSelectedTimeSlot,
          setTurnoverSummary,
          setIsLoading,
        );
      }
      pageLoading();

      (async function loadData() {
        let productionRanges = [];

        if (shouldUseContext) {
          const selectedTimeSlotInContext = get(productionPlanningRawData, 'selectedTimeSlot', {});

          // To take into consideration the potential new planning date that can change through offline use
          const formattedSelectedTimeSlot = {
            ...selectedTimeSlotInContext,
            startTime: buildDateWithTime(
              moment(planningDate),
              selectedTimeSlotInContext.startHour,
              selectedStore.timezone,
            ),
            endTime: buildDateWithTime(
              moment(planningDate),
              selectedTimeSlotInContext.endHour,
              selectedStore.timezone,
            ),
          };

          productionRanges = [formattedSelectedTimeSlot];
        } else {
          productionRanges = await fetchRanges(
            selectedStore,
            planningDate,
            userLanguageCode,
            showMessage,
          );
        }

        if (!productionRanges.length) {
          setEmptyStateStoreClosed(
            setTimeSlots,
            setUseCase,
            setSelectedTimeSlot,
            setTurnoverSummary,
            setIsLoading,
          );
        }

        setTimeSlots(productionRanges);

        pageLoaded();
      })();
    }
  }, [selectedStore, planningDate]);

  // Handle selected hour whenever ranges are being changed
  useEffect(() => {
    if ((!timeSlots.length && shouldUseContext) || isEmpty(selectedStore)) {
      return;
    }

    const currentTimeslot = getCurrentTimeslot(timeSlots, planningDate, selectedStore.timezone);

    setSelectedTimeSlot(currentTimeslot);

    setProductionPlanningRawData({
      ...productionPlanningRawData,
      selectedTimeSlot: currentTimeslot,
    });
  }, [timeSlots]);

  // Handle the fetch of planning data
  useEffect(() => {
    // Do not fetch any data if no store or no time slot is selected
    // or product id success is set (leave visual on which product was successfully updated)
    if (!selectedStore.storeId || !selectedTimeSlot.startHour || !!productIdSuccess) {
      pageLoaded();

      return;
    }

    const useCase = getUseCaseFromSelectedTimeSlot(
      planningDate,
      selectedTimeSlot,
      selectedStore.timezone,
    );

    setUseCase(useCase);

    pageLoading();

    setIsLoading(true);

    (async function loadData() {
      await refreshPlanningData({
        ...props,
        useCase,
        selectedStore,
        planningDate,
        selectedTimeSlot,
        setDataByRange,
        setDisplayProductsByCategory,
        setProductsByCategory,
        setProducts,
        setTurnoverSummary,
        sortProperties,
        reportToProduceFromPreviousRanges,
        productionPlanningRawData,
        setProductionPlanningRawData,
        shouldUseContext,
      });

      pageLoaded();

      setIsLoading(false);
    })();
  }, [selectedTimeSlot]);

  // Clear active inputs whenever an update of the quantities on a product was successful
  useEffect(() => {
    if (activeProduct.id == '' && activeProduct.isScanAdd) {
      if (activeProduct.shouldResetSearchInput && barcodeScannerElement.current) {
        barcodeScannerElement.current.resetInputs();
      }
    }

    if (activeProduct.id === productIdSuccess) {
      setActiveProduct(EMPTY_ACTIVE_PRODUCT);

      if (activeProduct.shouldResetSearchInput && barcodeScannerElement.current) {
        barcodeScannerElement.current.resetInputs();
      }
    }
  }, [productIdSuccess, activeProduct]);

  useEffect(() => {
    const searchInputValue = get(barcodeScannerElement.current, 'state.searchValue');

    if (!searchInputValue) {
      setFilteredProducts(products);
      setFilteredProductsByCategory(productsByCategory);

      return;
    }

    handleSelectedBarcode(
      useCase,
      searchInputValue,
      products,
      productsByCategory,
      displayProductsByCategory,
      setActiveProduct,
      setFilteredProducts,
      setFilteredProductsByCategory,
      activeProduct,
      productIdSuccess,
      updatedProps,
      authorizedActions,
    );
  }, [products, productsByCategory]);

  const updatedProps = {
    ...props,
    products,
    warningModal,
    planningDate,
    selectedStore,
    selectedTimeSlot,
    setProducts,
    setDataByRange,
    setWarningModal,
    setProductIdError,
    setTurnoverSummary,
    setProductIdSuccess,
    setProductsByCategory,
    setDisplayProductsByCategory,
    useCase,
    sortProperties,
    shouldUseContext,
    reportToProduceFromPreviousRanges,
    productionPlanningRawData,
    setProductionPlanningRawData,
    productionRequestsToPlay,
    setProductionRequestsToPlay,
  };
  const isPageRenderableInOffline = canPageBeRenderedInOffline(
    match,
    isUserOffline,
    productionPlanningRawData.data,
  );

  if (hasOffline && !isPageRenderableInOffline) {
    return (
      <Container>
        <NavigationBreadCrumb featurePath={updatedProps.match.path} />
        <OfflineProductionPage />
      </Container>
    );
  }
  return (
    <Container>
      <NavigationBreadCrumb featurePath={updatedProps.match.path} />
      <ContainerContent>
        {warningModal.active && (
          <WarningModal
            cancelConfirmation={() => setWarningModal(DEFAULT_WARNING_MODAL_SETUP)}
            handleConfirmation={() =>
              handleProducedSave(updatedProps, warningModal.product, warningModal.tot, true) &&
              setWarningModal(DEFAULT_WARNING_MODAL_SETUP)
            }
            operationType={'production'}
            product={warningModal.product}
            tot={warningModal.tot}
          />
        )}

        <PlanningHeader
          barcodeScannerElement={barcodeScannerElement}
          handlePrintPlanning={() =>
            handlePrintPlanning(
              selectedStore,
              planningDate,
              selectedTimeSlot,
              pageLoading,
              pageLoaded,
              showMessage,
            )
          }
          handleSelectedBarcode={(search) =>
            handleSelectedBarcode(
              useCase,
              search,
              products,
              productsByCategory,
              displayProductsByCategory,
              setActiveProduct,
              setFilteredProducts,
              setFilteredProductsByCategory,
              activeProduct,
              productIdSuccess,
              updatedProps,
              authorizedActions,
            )
          }
          handleSelectedDate={(date) => setPlanningDate(moment.tz(date, selectedStore.timezone))}
          handleSelectedStore={(storeName, storeId, closingDays, timezone) => {
            const store = { storeName, storeId, closingDays, timezone };

            setSelectedStore(store);
            setPlanningDate(moment.tz(store.timezone));

            setProductionPlanningRawData({
              ...productionPlanningRawData,
              selectedStore: store,
            });
          }}
          handleSelectedTimeslot={(hourName) =>
            handleSelectedTimeSlot(
              hourName,
              timeSlots,
              setSelectedTimeSlot,
              productionPlanningRawData,
              setProductionPlanningRawData,
            )
          }
          hidePrintOption={useCase === period.CLOSED}
          hideSearchBar={!products.length}
          hideStats={!products.length}
          hideTimeSlotDropDown={!products.length}
          planningDate={planningDate}
          planningType={planningType.PRODUCTION}
          printOption={printOption}
          producedTurnover={turnoverSummary.producedTurnover}
          producedTurnoverPercentage={turnoverSummary.producedTurnoverPercentage}
          readOnly={shouldUseContext}
          selectedStore={selectedStore}
          selectedTimeSlot={selectedTimeSlot}
          stores={stores}
          timeSlots={timeSlots}
          toProduceTurnover={turnoverSummary.toProduceTurnover}
          totalByBrands={turnoverSummary.totalByBrands}
          useCase={useCase}
        />

        {!isLoading && !filteredProducts.length && useCase !== period.CLOSED && (
          <FullHeightContainer>
            <ShadowedBox>{i18next.t('PRODUCTION.PRODUCTION.NO_PRODUCT')}</ShadowedBox>
          </FullHeightContainer>
        )}

        {!isLoading && (filteredProducts.length || useCase === period.CLOSED) && (
          <PlanningListing
            activeProduct={activeProduct}
            dataByRange={dataByRange}
            displayProductsByCategory={displayProductsByCategory}
            handleProducedSave={(product, prodInputValue) =>
              handleProducedSave(updatedProps, product, prodInputValue)
            }
            handleSort={(name, category) =>
              handleSort(
                name,
                category,
                sortProperties,
                setSortProperties,
                productsByCategory,
                setProductsByCategory,
                setProducts,
                dataByRange,
                displayProductsByCategory,
              )
            }
            handleToProduceSave={(value, productId) =>
              handleToProduceSave(updatedProps, value, productId, enableProductMixModification)
            }
            isUserOffline={shouldUseContext}
            planningDate={planningDate}
            planningType={planningType.PRODUCTION}
            productError={productIdError}
            products={filteredProducts}
            productsByCategory={filteredProductsByCategory}
            productSuccess={productIdSuccess}
            reportToProduceFromPreviousRanges={reportToProduceFromPreviousRanges}
            selectedTimeSlot={selectedTimeSlot}
            setActiveProduct={setActiveProduct}
            sortProperties={sortProperties}
            timeSlots={timeSlots}
            useCase={useCase}
            user={user}
          />
        )}
      </ContainerContent>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  isUserOffline: state.baseReducer.isUserOffline,
  isConnectionSlow: state.baseReducer.isConnectionSlow,
  confirmationMessageReducer: state.confirmationMessageReducer,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/production/operations'),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type, isToasterDisplayed) => {
    setTimeout(
      () => dispatch(showConfirmationMessage(message, type)),
      isToasterDisplayed ? 3000 : 0,
    );
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openModal: (params) => {
    dispatch(openSmallModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductionContainer);
