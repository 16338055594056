import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  margin: 24px;

  background-color: ${(props) => props.theme.colors.greys.lightest};
  border-radius: 16px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
`;

export const TitleContainer = styled.div`
  font: ${(props) => props.theme.fonts.h2};
  margin-bottom: 24px;
  padding: 24px 24px 0 24px;
`;
export const DetailsContainer = styled.div`
  display: flex;
  width: 100%;

  padding: 0 24px;

  align-items: center;

  margin-bottom: 24px;
`;

export const InputContainer = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin-right: 24px;
  }
`;

export const EmailContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: flex-start;

  width: 100%;

  margin-bottom: 24px;
  padding-left: 24px;
`;

export default {
  Container,
  TitleContainer,
  DetailsContainer,
  EmailContainer,
  InputContainer,
};
