import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

const CountryComponent = (props) => {
  const { t } = useTranslation();
  return (
    <div className={props.className}>
      <div className="activity-summary-country-header">{`${t('HOME.COUNTRY_LABEL')} :`}</div>
      <div className="activity-summary-country-name">
        <img alt="" className="flag" src={props.countryFlag} style={{}} />
        <div className="activity-summary-country-name-text">{t(props.countryLabel)}</div>
      </div>
    </div>
  );
};

export default styled(CountryComponent)`
  background: ${(props) => props.theme.colors.greys.lightest};
  border-radius: ${(props) => props.theme.borders?.radius?.weak};
  width: 12%;
  height: 100%;
  min-width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-left: 2em;

  .activity-summary-country-header {
    font: ${(props) => props.theme.fonts?.h4};
  }

  .activity-summary-country-name {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .flag {
      width: 35px;
      height: 35px;
    }
    .activity-summary-country-name-text {
      margin-left: 1em;
      font: ${(props) => props.theme.fonts?.kpiBig};
    }
  }
`;
