import i18next from 'i18next';

import DataFeedLogo from '@assets/logo/data-feed.svg';
import ProductionPlanningLogo from '@assets/logo/production-planning.svg';

export const getEnumChoicesModal = () => ({
  CASHIER_PRODUCT_ASSOCIATION_TYPE: 'cashierProductAssociation',
  CASHIER_PRODUCT_ASSOCIATION: i18next.t('ADMIN.PRODUCTS.MODAL_ASK_CASHIER_PRODUCT_ASSOCIATION'),
  CASHIER_PRODUCT_ASSOCIATION_INFO: i18next.t(
    'ADMIN.PRODUCTS.MODAL_ASK_CASHIER_PRODUCT_ASSOCIATION_INFO',
  ),
  CASHIER_PRODUCT_ASSOCIATION_ICON: DataFeedLogo,
  PRODUCTION_PLANNING_CREATION_TYPE: 'productionPlanning',
  PRODUCTION_PLANNING_CREATION: i18next.t('ADMIN.PRODUCTS.MODAL_ASK_PRODUCTION_PLANNING_CREATION'),
  PRODUCTION_PLANNING_CREATION_INFO: i18next.t(
    'ADMIN.PRODUCTS.MODAL_ASK_PRODUCTION_PLANNING_CREATION_INFO',
  ),
  PRODUCTION_PLANNING_CREATION_ICON: ProductionPlanningLogo,
});

export default getEnumChoicesModal;
