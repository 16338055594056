import styled from 'styled-components';
import ColorPalette from '../utils/colorPalette';

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  border-radius: 50%;

  background: ${ColorPalette.IP_Black};
`;

export const Icon = styled.img``;

export default {
  ContainerIcon,
  Icon,
};
