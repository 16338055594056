import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { ListView, Button, Label } from '@commons/utils/styledLibraryComponents';
import { numberToFixed } from '@commons/utils/format';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import DisplayNumber from '@commons/DisplayNumber';

import { getClientInfo } from '@selectors/client';

import { product as productService } from '@services/product';

import theme from '@theme';

import { SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING } from '../../common/constants';

import { Text, Content, Container, TopContainer, BottomContainer } from './styledComponents';

const renderLabel = (item) => (
  <Label
    background={theme.colors.greys.darkest}
    color={theme.colors.greys.darkest}
    type="tertiary"
    width={'24px'}
  >
    {item}
  </Label>
);

const HEADER_BRAND = {
  id: '-1',
  name: 'Marque',
  type: 'string',
  propertyKey: 'brand',
  highlighted: true,
};

const getDefaultHeaders = () => [
  {
    id: '0',
    name: i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_LIST_COLUMN_NAME'),
    type: 'string',
    propertyKey: 'name',
    highlighted: true,
    large: true,
  },
  {
    id: '1',
    name: i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_LIST_COLUMN_CATEGORY'),
    type: 'string',
    propertyKey: 'category',
  },
  {
    id: '2',
    name: i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_LIST_COLUMN_RELEVANCE'),
    tooltipText: i18next.t(
      'ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_LIST_COLUMN_RELEVANCE_TOOLTIP',
      { significanceThreshold: SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING },
    ),
    type: 'numeric',
    propertyKey: 'relevance',
    render: (score) => {
      // We use floor and not round because we don't want a score of 100 if it's not totally equal
      const formattedScore = score ? Math.floor(score) : 0;

      const isRelevant = formattedScore >= SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING;

      return (
        <Label
          background={isRelevant ? theme.colors.brand.secondary : theme.colors.greys.lighty}
          color={isRelevant ? theme.colors.greys.darkest : theme.colors.greys.lightest}
          type={'plain'}
          width={'fit-content'}
        >
          {formattedScore}
        </Label>
      );
    },
  },
  {
    id: '3',
    name: i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_LIST_COLUMN_PRICE'),
    type: 'numeric',
    propertyKey: 'priceWithTaxes',
    render: (item) => <DisplayNumber displayCurrencyCode={true} number={item} />,
  },
  {
    id: '4',
    name: i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_LIST_COLUMN_VAT_RATE'),
    type: 'numeric',
    propertyKey: 'vatRate',
    render: (item) => <Text>{numberToFixed(item, 0, '-', '%')}</Text>,
  },
  {
    id: '5',
    name: i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_LIST_COLUMN_PRODUCTS_SYNC'),
    type: 'numeric',
    propertyKey: 'nbCashierProductMappingCount',
    render: (item) => renderLabel(item),
  },
];

const CashierProductAssociationModal = (props) => {
  const {
    user,
    pageLoaded,
    closeModal,
    showErrorMessage,
    showSuccessMessage,
    pageLoading,
    client: { hasMultipleBrands },
    params: { cashierProducts, handleShouldRetrieveData, setSelectedItems },
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  const [headers, setHeaders] = useState(getDefaultHeaders());
  const [inpulseProducts, setInpulseProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);

  const [isFormValid, setIsFormValid] = useState(false);

  const userLanguageCode = _.get(user, 'lnkLanguageAccountrel.code', 'fr');

  useEffect(() => {
    pageLoading();
    setIsLoading(true);

    (async function loadData() {
      const cashierProductIds = cashierProducts.map(({ id }) => id);
      try {
        const result = await productService.getProductsOfClientWithCashierProductsMappingRelevance(
          _.get(user, 'clientId'),
          cashierProductIds,
        );

        setInpulseProducts(result);
      } catch {
        showErrorMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_LOADING_FAILURE'));
      } finally {
        pageLoaded();
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedProduct && selectedProduct.length > 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (hasMultipleBrands) {
      const updatedHeaders = [...headers];

      updatedHeaders.splice(1, 0, HEADER_BRAND);

      setHeaders(updatedHeaders);
    }
  }, [hasMultipleBrands]);

  const handleSave = async () => {
    try {
      pageLoading();

      const cashierProductIds = cashierProducts.map((cashierProduct) => cashierProduct.id);

      await productService.associateCashierProductsToProduct(
        selectedProduct[0].id,
        cashierProductIds,
      );

      showSuccessMessage(
        i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_SUCCESS', {
          count: cashierProductIds.length,
        }),
      );

      handleShouldRetrieveData();
      setSelectedItems([]);
      closeModal();
    } catch {
      showErrorMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_FAILURE'));
    } finally {
      pageLoaded();
    }
  };

  return (
    <Container>
      <TopContainer>{i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_TITLE')}</TopContainer>
      <Content>
        <ListView
          columns={headers}
          data={inpulseProducts}
          defaultOrderBy={'relevance'}
          defaultOrderType={'desc'}
          disableMultipleSelection={true}
          isLoading={isLoading}
          languageCode={userLanguageCode}
          padding={STANDARD_LISTVIEW_PADDING}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          setSelectedItems={(items) => setSelectedProduct(items)}
        />
      </Content>
      <BottomContainer>
        <Button
          buttonCustomStyle={{ marginRight: 18 }}
          color={'blue-outline-no-shadow'}
          handleClick={() => closeModal()}
          icon={'/images/inpulse/close-black-small.svg'}
          label={i18next.t('GENERAL.CANCEL')}
        />
        <Button
          buttonCustomStyle={{ marginRight: 18 }}
          handleClick={() => (isFormValid ? handleSave() : {})}
          icon={'/images/inpulse/save-white-small.svg'}
          isDisabled={!isFormValid}
          label={i18next.t('GENERAL.SAVE')}
        />
      </BottomContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CashierProductAssociationModal);
