import i18next from 'i18next';
import React, { useEffect, useRef } from 'react';

import { RadioButton } from '@commons/utils/styledLibraryComponents';

import {
  ButtonContainer,
  Container,
  Content,
  EmptyStateIcon,
  MainText,
  RadioSelectButton,
  SubText,
  TextContainer,
} from './styledComponents';

export function renderContent(
  id,
  title,
  text,
  iconSrc,
  selectedChoice,
  setSelectedChoice,
  yesChoiceRef,
  noChoiceRef,
) {
  return (
    <Content>
      <TextContainer>
        <MainText>{title}</MainText>
        <SubText>{text}</SubText>
      </TextContainer>

      <EmptyStateIcon alt="icon-association" src={iconSrc} />

      <ButtonContainer>
        <RadioSelectButton
          ref={yesChoiceRef}
          tabIndex="0"
          onClick={() => setSelectedChoice({ ...selectedChoice, [id]: 'yes' })}
        >
          <RadioButton selected={selectedChoice[id]} size="small" value={'yes'} />
          <div>
            {i18next.t('ADMIN.PRODUCTS.CREATE_PRODUCT_MODAL_ASSOCIATE_CASHIER_PRODUCT_NOW')}
          </div>
        </RadioSelectButton>
        <RadioSelectButton
          ref={noChoiceRef}
          tabIndex="0"
          onClick={() => setSelectedChoice({ ...selectedChoice, [id]: 'no' })}
        >
          <RadioButton selected={selectedChoice[id]} size="small" value={'no'} />
          <div>
            {i18next.t('ADMIN.PRODUCTS.CREATE_PRODUCT_MODAL_ASSOCIATE_CASHIER_PRODUCT_LATER')}
          </div>
        </RadioSelectButton>
      </ButtonContainer>
    </Content>
  );
}

const PreStepChoiceToProceed = (props) => {
  const {
    currentStep: { id, title, text, iconSrc },
    selectedChoice,
    setSelectedChoice,
  } = props;

  const yesChoiceRef = useRef(null);
  const noChoiceRef = useRef(null);

  useEffect(() => {
    selectedChoice[id] && selectedChoice[id] === 'yes'
      ? yesChoiceRef.current.focus()
      : noChoiceRef.current.focus();
  }, [id]);

  return (
    <Container>
      {renderContent(
        id,
        title,
        text,
        iconSrc,
        selectedChoice,
        setSelectedChoice,
        yesChoiceRef,
        noChoiceRef,
      )}
    </Container>
  );
};

export default PreStepChoiceToProceed;
