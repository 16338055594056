import { connect } from 'react-redux';
import { keyBy, flatten, sortBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getUserTimezone } from '@commons/utils/date';
import utilsXLS from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import * as forecastService from '@services/forecast';
import { getClientInfo } from '@selectors/client';
import { group as groupService } from '@services/group';

const MAX_SHEET_ELEMENT_SIZE = 50000;

const getColumns = (forecastProperty) => [
  {
    propertyKey: 'name',
    name: i18next.t('GENERAL.STORE'),
  },
  {
    propertyKey: 'partnerId',
    name: i18next.t('GENERAL.ID'),
  },
  {
    propertyKey: 'date',
    name: i18next.t('GENERAL.DATE'),
  },
  {
    propertyKey: `dynamic.${forecastProperty}`,
    name: i18next.t('GENERAL.INPULSE_FORECAST'),
  },
  {
    propertyKey: `saved.${forecastProperty}`,
    name: i18next.t('FORECAST.TURNOVER.USER_FORECASTS'),
  },
  {
    propertyKey: 'turnoverIncludingTaxes',
    name: i18next.t('FORECAST.TURNOVER.ACTUAL_SALES'),
  },
];

const ForecastsProgressExportModal = (props) => {
  const {
    params: { title, stores, startDate, endDate },
    client: { storeName, turnoverName, forecastProperty },
    closeModal,
    showErrorMessage,
  } = props;

  const fileName = i18next.t('FORECAST.TURNOVER.EXPORT_FILE_NAME');
  const sheetName = i18next.t('FEATURE.FORECASTS.FORECASTS');

  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [forecastsByStoreIds, setForecastsByStoreIds] = useState(null);
  const [groupsByStoreIds, setGroupsByStoreIds] = useState(null);
  const [titleModal, setTitleModal] = useState(title);

  const timezone = getUserTimezone();

  useEffect(() => {
    (async () => {
      try {
        const storeIds = stores.map(({ id }) => id);

        const groupMappings = await groupService.getGroupsOfStores(storeIds);

        const formattedGroups = groupMappings.reduce((acc, groupMapping) => {
          if (acc[groupMapping.storeId]) {
            acc[groupMapping.storeId].push(groupMapping.lnkGroupStoregroupmappingrel.name);

            return acc;
          }

          acc[groupMapping.storeId] = [groupMapping.lnkGroupStoregroupmappingrel.name];

          return acc;
        }, {});

        if (!!groupMappings.length) {
          setGroupsByStoreIds(formattedGroups);
        }

        const result = await forecastService.getForecastsByStoreIdAndDates(
          storeIds,
          moment(startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
          moment(endDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        );

        setForecastsByStoreIds(result);
      } catch (error) {
        setTitleModal(i18next.t('GENERAL.EXPORT_FAILURE'));
        showErrorMessage(error.message);
      }
    })();
  }, []);

  useEffect(() => {
    if (!forecastsByStoreIds) {
      return;
    }

    const flattenedForecasts = flatten(Object.values(forecastsByStoreIds));

    const columns = getColumns(forecastProperty);

    if (groupsByStoreIds) {
      columns.splice(0, 0, {
        propertyKey: 'groups',
        name: i18next.t('GENERAL.GROUPS'),
      });
    }

    const storesKeyById = keyBy(stores, 'id');

    let formattedForecasts = flattenedForecasts.map((forecast) => {
      const groups =
        groupsByStoreIds && groupsByStoreIds[forecast.storeId]
          ? groupsByStoreIds[forecast.storeId].toString()
          : [];

      return {
        ...forecast,
        name: storesKeyById[forecast.storeId].name,
        partnerId: storesKeyById[forecast.storeId].partnerId,
        groups,
      };
    });

    let sheetIndex = 1;
    const hasManyFiles = formattedForecasts.length > MAX_SHEET_ELEMENT_SIZE;

    while (formattedForecasts.length > 0) {
      const forecastForSheet = formattedForecasts.splice(0, MAX_SHEET_ELEMENT_SIZE);

      const sortedForecast = sortBy(forecastForSheet, ['name', 'date']);

      const sheet = utilsXLS.generateDefaultSheet(sheetName, columns, sortedForecast);
      const infoSheet = makeInfoSheet();

      utilsXLS.makeXLS(hasManyFiles ? `${fileName}_${sheetIndex}` : fileName, [sheet, infoSheet]);

      ++sheetIndex;
    }

    setProgress(100);
    setTitleModal(i18next.t('GENERAL.EXPORT_SUCCESS'));
  }, [forecastsByStoreIds]);

  const makeInfoSheet = () => {
    const storeTranslations = getClientStoreNameTranslation(storeName, true);

    const headers = [
      i18next.t('FEATURE.BACKOFFICE.DATA'),
      storeTranslations,
      i18next.t('LOSSES.PRODUCTS.EXPORT_COLUMN_PERIOD_START'),
      i18next.t('LOSSES.PRODUCTS.EXPORT_COLUMN_PERIOD_END'),
      i18next.t('LOSSES.PRODUCTS.EXPORT_COLUMN_DATE'),
    ];

    const storeNames = stores
      .map((store) => store.name)
      .sort()
      .toString();
    const formattedStartDate = startDate.format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);
    const formattedEndDate = endDate.format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);
    const exportDate = moment.tz(timezone).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);

    return {
      title: i18next.t('GENERAL.INFORMATIONS'),
      headers,
      data: [[turnoverName, storeNames, formattedStartDate, formattedEndDate, exportDate]],
    };
  };

  const exitModal = () => {
    setIsLoading(false);
    closeModal();
  };

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setIsLoading}
      titleModal={titleModal}
    />
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForecastsProgressExportModal);
