const productionPlanningReducer = (state = {}, action) => {
  switch (action.type) {
    case 'PRODUCTION_PLANNING_UPDATE_QUANTITY':
      return Object.assign({}, state, {
        ...state,
        productionQtyByEntityIdAndDate: {
          ...state.productionQtyByEntityIdAndDate,
          [action.entityId]: {
            ...state.productionQtyByEntityIdAndDate[action.entityId],
            [action.date]: action.quantity,
          },
        },
      });
    case 'PRODUCTION_PLANNING_UPDATE_QUANTITY_BY_BATCH':
      return Object.assign({}, state, {
        ...state,
        productionQtyByEntityIdAndDate: {
          ...state.productionQtyByEntityIdAndDate,
          ...action.updatedProductionQuantitiesByEntityIdAndDate,
        },
      });
    case 'PRODUCTION_PLANNING_RESET_VALUES':
      return Object.assign({}, state, {
        productionQtyByEntityIdAndDate: {},
      });

    case 'PRODUCTION_PLANNING_INIT_VALUES':
      return Object.assign({}, state, {
        productionQtyByEntityIdAndDate: action.productionQtyByEntityIdAndDate,
      });

    default:
      return state;
  }
};

export default productionPlanningReducer;
