import { connect } from 'react-redux';
import { get, uniqBy } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { ListView, Button } from '@commons/utils/styledLibraryComponents';
import { LISTVIEW_NO_TOP_PADDING } from '@commons/constants/listViewProps';
import { NONE_DROPDOWN_VALUES } from '@commons/constants/dropdown';
import EmptyState from '@commons/EmptyState';

import useLocalStorage from '@hooks/useLocalStorage';

import { getClientInfo } from '@selectors/client';

import clientService from '@services/client';

import DeepsightFiltersButton from '@admin/components/FilterButton';

import formatUtils from '@admin/inventories/utils/formatUtils';
import RecipesMappingsModal from '@admin/inventories/utils/recipeMappings.utils';

import actionsUtils from '../utils/actionsUtils';
import columnsUtils from '../utils/columnsUtils';

import { Container } from './styledComponents';

const InventoryListTemplateRecipeMappings = (props) => {
  const {
    isLoading,
    isCreation,
    existingMappings = [],
    openGenericModal,
    client: { clientId, hasMultipleBrands },
    showSuccessMessage,
    showErrorMessage,
    inventoryListTemplateId,
    pageLoading,
    pageLoaded,
    inventoryListTemplateForm,
    refreshGenericModal,
    formFields,
  } = props;

  const [isInCentralMode] = useLocalStorage('isCentralMode', false);

  // States ListView
  const [columns] = useState(columnsUtils.getILTRecipeMappingsColumns(hasMultipleBrands));
  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [displayEmptyState, setDisplayEmptyState] = useState(false);

  // Filters states
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [categoriesToChoose, setCategoriesToChoose] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [brandsToChoose, setBrandsToChoose] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const [allRecipeMappings, setAllRecipeMappings] = useState([]);
  const [recipeMappings, setRecipeMappings] = useState(existingMappings);
  const [filteredRecipeMappings, setFilteredRecipeMappings] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [recipesToBeAdded, setRecipesToBeAdded] = useState([]);

  // Set recipe mappings list for display
  useEffect(() => {
    if (isCreation || !existingMappings.length) {
      setDisplayEmptyState(true);
      return;
    }

    const formattedRecipeMappings = existingMappings.map((recipe) => {
      const brands = recipe.brandEntityMappings.map(({ brandId, brandName }) => ({
        id: brandId,
        name: brandName,
      }));

      return {
        ...recipe,
        brands,
      };
    });

    setAllRecipeMappings(formattedRecipeMappings);

    const concernedMappings = formattedRecipeMappings.filter(
      ({ isKitchen }) => isInCentralMode === isKitchen,
    );
    setRecipeMappings(concernedMappings);
    setFilteredRecipeMappings(concernedMappings);
  }, [existingMappings]);

  // Update differents filters on each recipeMappings update (filters depend on what is displayed in the recipe mappings list)
  useEffect(() => {
    if (!isLoading && !recipeMappings.length) {
      setDisplayEmptyState(true);
      return;
    }

    setDisplayEmptyState(false);

    const filtersData = recipeMappings.reduce(
      (acc, { category, brands }) => {
        if (!acc.filterCategories.some(({ name }) => name === category)) {
          acc.filterCategories.push({ id: category, name: category });
        }

        acc.filterBrands = acc.filterBrands.concat(brands);

        return acc;
      },
      { filterCategories: [], filterBrands: [NONE_DROPDOWN_VALUES.FEMININE] },
    );

    const uniqCategories = filtersData.filterCategories;

    setCategoriesToChoose(uniqCategories);
    setSelectedCategories(uniqCategories);

    const uniqBrands = uniqBy(filtersData.filterBrands, 'id');

    setBrandsToChoose(uniqBrands);
    setSelectedBrands(uniqBrands);

    if (get(filters, 'categories', null) || get(filters, 'brands', null)) {
      let filtersToBeSet = {
        ...filters,
        categories: {
          id: 'categories',
          icon: '/images/inpulse/category-ipblack-small.svg',
          list: uniqCategories,
          defaultSelectedItems: uniqCategories,
          selectedItems: uniqCategories,
          setSelectedItems: setSelectedCategories,
        },
      };

      if (hasMultipleBrands) {
        filtersToBeSet = {
          ...filtersToBeSet,
          brands: {
            activeList: uniqBrands,
            list: uniqBrands,
            selectedBrands: uniqBrands,
          },
        };
      }

      setFilters(filtersToBeSet);
    }
  }, [recipeMappings]);

  // Update
  useEffect(() => {
    if (formFields && formFields.recipeMappings) {
      setRecipeMappings(formFields.recipeMappings);
      setFilteredRecipeMappings(formFields.recipeMappings);
    }
  }, [formFields]);

  // Handle filters change
  useEffect(() => {
    // User has not applied the filters
    if (!applyFilters) {
      return;
    }

    const filteredData = recipeMappings.filter(({ category, brands }) => {
      const categoriesFilterCondition = selectedCategories.some(({ name }) => category === name);

      // Apply brand filter only if client has multiple brands
      if (hasMultipleBrands && selectedBrands.length) {
        const brandNames = brands.map(({ name }) => name);

        // need to filter by brands only if at least one brand is selected (or None is selected)
        const includeNone = selectedBrands.find(({ id }) => id === -1);

        const brandsFilterCondition = selectedBrands.some(
          (selectedBrand) =>
            brandNames.includes(selectedBrand.name) || (includeNone && !brands.length), // keep recipes without brands if none is selected
        );

        return categoriesFilterCondition && brandsFilterCondition;
      }

      return categoriesFilterCondition;
    });

    setFilteredRecipeMappings(filteredData);
  }, [applyFilters, selectedCategories]);

  // Handle list actions
  useEffect(() => {
    setActions(
      actionsUtils.getInventoryListTemplateGlobalActions(
        isLoading,
        selectedItems,
        handleAddAssociation,
        handleRemoveAssociation,
        true,
      ),
    );

    setRowActions(actionsUtils.getInventoryListTemplateRowActions(handleRemoveAssociation, true));
  }, [filteredRecipeMappings, selectedItems, isLoading, recipeMappings]);

  useEffect(() => {
    const params = getRecipeMappingsModalConfig();

    refreshGenericModal(params);
  }, [recipesToBeAdded]);

  /** FUNCTIONS */
  const renderNoRecipeMappings = () => (
    <EmptyState
      label={i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.EMPTY_STATE_RECIPE_MAPPINGS_LABEL')}
      labelColor={ENUM_COLORS.IP_BLACK_1}
      labelFont={ENUM_FONTS.H2_INTER}
      renderActionButton={() => (
        <Button
          color={'inpulse-default'}
          handleClick={handleAddAssociation}
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('GENERAL.ADD')}
        />
      )}
      subtitle={i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.EMPTY_STATE_RECIPE_MAPPINGS_SUBTITLE')}
      subtitleMargin={'8px'}
    />
  );

  const handleAddAssociation = () => {
    const params = getRecipeMappingsModalConfig();

    openGenericModal(params);
  };

  const handleRemoveAssociation = (selectedItems = []) => {
    if (!selectedItems.length) {
      return;
    }

    const firstRecipe = selectedItems[0];

    const warningModalConfig = getConfirmationModal({
      title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_ACTION_REMOVE_SELECTION'),
      content: i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.REMOVE_RECIPE_MAPPINGS_WARNING', {
        count: selectedItems.length,
        name: firstRecipe.name,
      }),
      icon: '/images/inpulse/warning-white-small.svg',
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: () => deleteILTMappings(selectedItems),
        },
      ],
    });

    openGenericModal(warningModalConfig);
  };

  const getRecipeMappingsModalConfig = () => ({
    type: 'actionWhite',
    isFullscreen: true,
    title: i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.RECIPE_MAPPINGS_ASSOCIATION_MODAL_TITLE'),
    icon: '/images/inpulse/add-black-small.svg',
    customPadding: '24px 24px 0px 24px',
    component: RecipesMappingsModal,
    data: {
      clientId,
      hasMultipleBrands,
      setRecipesToBeAdded,
      alreadyMappedRecipes: recipeMappings,
    },
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_SAVE_BUTTON(),
        handleClick: addILTMappings,
      },
    ],
  });

  const addILTMappings = () => {
    const formattedRecipesToBeAdded = recipesToBeAdded.map((item) => ({
      ...item,
      id: item.entityId,
    }));

    if (isCreation) {
      const updatedMappingsList = [...recipeMappings, ...formattedRecipesToBeAdded];

      inventoryListTemplateForm.setValue('recipeMappings', updatedMappingsList);

      setRecipeMappings(updatedMappingsList);
      setFilteredRecipeMappings(updatedMappingsList);

      return;
    }

    const mappingsToKeep = recipeMappings.concat(formattedRecipesToBeAdded);
    handleILTRecipeMappingsUpdate(mappingsToKeep);
  };

  const deleteILTMappings = (selectedItems) => {
    const idsToRemove = selectedItems.map(({ id }) => id);
    const mappingsToKeep = recipeMappings.filter(({ id }) => !idsToRemove.includes(id));

    if (isCreation) {
      inventoryListTemplateForm.setValue('recipeMappings', mappingsToKeep);
      setRecipeMappings(mappingsToKeep);
      setFilteredRecipeMappings(mappingsToKeep);

      return;
    }

    handleILTRecipeMappingsUpdate(mappingsToKeep);
  };

  const handleILTRecipeMappingsUpdate = async (mappingsToKeep) => {
    const recipeIdsToAssociate = mappingsToKeep.map(({ id }) => id);

    // Keep non visible mappings according to user mode (sales point or central kitchen mode)
    const recipeIdsToKeep = allRecipeMappings.reduce((acc, { id, isKitchen }) => {
      if (isInCentralMode && !isKitchen) {
        acc.push(id);
      }
      return acc;
    }, []);

    const entityIds = [...recipeIdsToKeep, ...recipeIdsToAssociate];

    pageLoading();

    try {
      await clientService.updateInventoryListTemplate(
        clientId,
        inventoryListTemplateId,
        null,
        null, // No changes for existing SP mappings here
        entityIds,
      );

      setRecipeMappings(mappingsToKeep);
      setFilteredRecipeMappings(mappingsToKeep);

      showSuccessMessage(i18next.t('GENERAL.CHANGES_SUCCESSFULLY_SAVED'));
    } catch {
      showErrorMessage(i18next.t('GENERAL.SAVING_CHANGES_FAILED'));
    } finally {
      pageLoaded();
    }
  };

  const getConditionnalFiltersProperties = () =>
    hasMultipleBrands
      ? {
          brands: brandsToChoose,
          selectedBrands: selectedBrands,
          setSelectedBrands: setSelectedBrands,
        }
      : {};

  if (displayEmptyState) {
    return <Container>{renderNoRecipeMappings()}</Container>;
  }

  return (
    <Container>
      <ListView
        actions={actions}
        columns={columns}
        data={filteredRecipeMappings}
        defaultMaxPerPage={500}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        isLoading={isLoading}
        maxPerPageOptions={[10, 20, 50, 100, 500, 1000]}
        minActionsInActionsDropdown={1}
        padding={LISTVIEW_NO_TOP_PADDING}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => (
          <DeepsightFiltersButton
            applyFilters={applyFilters}
            customMultipleDropDowns={formatUtils.computeMultipleDropdownsFilter({
              categoriesToChoose,
              selectedCategories,
              setSelectedCategories,
            })}
            filters={filters}
            readOnly={isLoading}
            setApplyFilters={setApplyFilters}
            setFilters={setFilters}
            textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
            {...getConditionnalFiltersProperties()}
          />
        )}
        rowActions={rowActions}
        setSelectedItems={setSelectedItems}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  activeStores: state.baseReducer.activeStores,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryListTemplateRecipeMappings);
