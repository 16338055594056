import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  width: 240px;

  display: flex;
  justify-content: start;
  flex-direction: column;
  row-gap: 24px;
`;

export const RadioButtonContainer = styled.div`
  width: auto;

  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
`;

export const DatePickerContainer = styled.div`
  width: auto;

  display: flex;
  justify-content: start;
  flex-direction: column;
  row-gap: 8px;
`;

export const WildCard = styled.span`
  color: ${(props) => props.theme.colors.infoRed};
`;

export const TooltipContainer = styled.div`
  margin-top: -1px;
`;

export const LabelContainer = styled.div`
  font: ${(props) => props.theme.fonts.textSmall};

  display: flex;
  flex-direction: row;
  align-items: center;
`;
