import i18next from 'i18next';

import { getClientStoreNameTranslation } from '../../utils/translations';

const STORES_FOOD_COST = {
  getText: ({ storeName }) =>
    i18next.t('FEATURE.ANALYTICS.STORES', {
      storeName: getClientStoreNameTranslation(storeName).toLowerCase(),
    }),
  path: '/order/analytics/stores',
  getBreadCrumbText: ({ storeName }) =>
    i18next.t('FEATURE.ANALYTICS.COST_STORES_BREADCRUMB', {
      storeName: getClientStoreNameTranslation(storeName).toLowerCase(),
    }),
};

const CATEGORY_FOOD_COST = {
  getText: () => i18next.t('FEATURE.ANALYTICS.CATEGORY'),
  path: '/order/analytics/categories',
  getBreadCrumbText: () => i18next.t('FEATURE.ANALYTICS.CATEGORY_BREADCRUMB'),
};

const LOSSES_BY_INGREDIENT = {
  getText: () => i18next.t('FEATURE.ANALYTICS.WASTES_BY_INGREDIENT'),
  path: '/losses/analytics/ingredient',
  getBreadCrumbText: () => i18next.t('FEATURE.ANALYTICS.WASTES_BY_INGREDIENT'),
};

const FOOD_COST_SUBMODULE = {
  getText: () => i18next.t('FEATURE.ANALYTICS.FOOD_COST'),
  icon: '/images/savings.svg',
  children: [STORES_FOOD_COST, CATEGORY_FOOD_COST, LOSSES_BY_INGREDIENT],
};

const SUPPLIER_PRODUCT_PURCHASES = {
  getText: () => i18next.t('FEATURE.ANALYTICS.SUPPLIER_PRODUCTS_PURCHASES'),
  path: '/order/analytics/supplier-products',
  getBreadCrumbText: () => i18next.t('FEATURE.ANALYTICS.SUPPLIER_PRODUCTS_PURCHASES_BREADCRUMB'),
};

const PRICES_EVOLUTION_PURCHASES = {
  getText: () => i18next.t('FEATURE.ANALYTICS.PRICES_EVOLUTION'),
  path: '/order/analytics/prices',
  getBreadCrumbText: () => i18next.t('FEATURE.ANALYTICS.PRICES_EVOLUTION_BREADCRUMB'),
};

const PURCHASES_SUBMODULE = {
  getText: () => i18next.t('FEATURE.ANALYTICS.PURCHASES'),
  icon: '/images/orders.svg',
  children: [SUPPLIER_PRODUCT_PURCHASES, PRICES_EVOLUTION_PURCHASES],
};

const CURRENT_STOCKS = {
  getText: () => i18next.t('FEATURE.ANALYTICS.CURRENT_STOCKS'),
  path: '/stocks/analytics/current',
  getBreadCrumbText: () => i18next.t('FEATURE.ANALYTICS.CURRENT_STOCKS_BREADCRUMB'),
};

const PAST_STOCKS = {
  getText: () => i18next.t('FEATURE.ANALYTICS.PAST_STOCKS'),
  path: '/stocks/analytics/past',
  getBreadCrumbText: () => i18next.t('FEATURE.ANALYTICS.PAST_STOCKS_BREADCRUMB'),
};

const STOCKS_SUBMODULE = {
  getText: () => i18next.t('FEATURE.ANALYTICS.STOCKS'),
  icon: '/images/icon-stock.svg',
  children: [CURRENT_STOCKS, PAST_STOCKS],
};

const ANALYTICS_SALES = {
  getText: () => i18next.t('FEATURE.ANALYTICS.PAST_SALES'),
  path: '/forecast/analytics/sales',
  getBreadCrumbText: () => i18next.t('FEATURE.ANALYTICS.PAST_SALES_BREADCRUMB'),
};

const ANALYTICS_PAST_MIX = {
  getText: () => i18next.t('FEATURE.ANALYTICS.PAST_MIX'),
  path: '/forecast/analytics/past-mix',
  getBreadCrumbText: () => i18next.t('FEATURE.ANALYTICS.PAST_MIX_BREADCRUMB'),
};

const ANALYTICS_INGREDIENTS_CONSUMPTION = {
  getText: () => i18next.t('FEATURE.ANALYTICS.INGREDIENTS_CONSUMPTION'),
  path: '/forecast/analytics/ingredients-consumption',
  getBreadCrumbText: () => i18next.t('FEATURE.ANALYTICS.INGREDIENTS_CONSUMPTION_BREADCRUMB'),
};

const SALES_SUBMODULE = {
  getText: () => i18next.t('FEATURE.ANALYTICS.SALES'),
  icon: '/images/show-chart.svg',
  children: [ANALYTICS_SALES, ANALYTICS_PAST_MIX, ANALYTICS_INGREDIENTS_CONSUMPTION],
};

const REAL_TIME = {
  getText: () => i18next.t('FEATURE.ANALYTICS.REAL_TIME'),
  path: '/production/analytics/real-time',
  getBreadCrumbText: () => i18next.t('FEATURE.ANALYTICS.REAL_TIME_BREADCRUMB'),
};

const STORES_PRODUCTION = {
  getText: ({ storeName }) =>
    i18next.t('FEATURE.ANALYTICS.STORES', {
      storeName: getClientStoreNameTranslation(storeName).toLowerCase(),
    }),
  path: '/production/analytics/by-store',
  getBreadCrumbText: ({ storeName }) =>
    i18next.t('FEATURE.ANALYTICS.PRODUCTION_STORES_BREADCRUMB', {
      storeName: getClientStoreNameTranslation(storeName).toLowerCase(),
    }),
};

const BRAND_PRODUCTION = {
  getText: () => i18next.t('FEATURE.ANALYTICS.BRAND'),
  path: '/production/analytics/by-brand',
  getBreadCrumbText: () => i18next.t('FEATURE.ANALYTICS.BRAND_BREADCRUMB'),
};

const DAILY_PRODUCTION = {
  getText: () => i18next.t('FEATURE.ANALYTICS.BY_DAY'),
  path: '/production/analytics/stores',
  getBreadCrumbText: () => i18next.t('FEATURE.ANALYTICS.BY_DAY_BREADCRUMB'),
};

const PRODUCT_PRODUCTION = {
  getText: () => i18next.t('FEATURE.ANALYTICS.PRODUCT'),
  path: '/production/analytics/references',
  getBreadCrumbText: () => i18next.t('FEATURE.ANALYTICS.PRODUCT_BREADCRUMB'),
};

const PRODUCTION_SUBMODULE = {
  getText: () => i18next.t('FEATURE.ANALYTICS.PRODUCTION'),
  icon: '/images/production.svg',
  children: [REAL_TIME, STORES_PRODUCTION, BRAND_PRODUCTION, DAILY_PRODUCTION, PRODUCT_PRODUCTION],
};

const MARGIN_BY_STORE = {
  getText: ({ storeName }) =>
    i18next.t('FEATURE.ANALYTICS.STORES', {
      storeName: getClientStoreNameTranslation(storeName).toLowerCase(),
    }),
  path: '/margin/analytics/store',
  getBreadCrumbText: ({ storeName }) =>
    i18next.t('FEATURE.ANALYTICS.MARGIN_BY_STORE', {
      storeName: getClientStoreNameTranslation(storeName).toLowerCase(),
    }),
};

const MARGIN_EVOLUTION = {
  getText: () => i18next.t('FEATURE.ANALYTICS.MARGIN_EVOLUTION'),
  path: '/margin/analytics/evolution',
  getBreadCrumbText: () => i18next.t('FEATURE.ANALYTICS.MARGIN_EVOLUTION_BREADCRUMB'),
};

const MARGIN_SUBMODULE = {
  getText: () => i18next.t('FEATURE.ANALYTICS.MARGIN'),
  icon: '/images/inpulse/margin.svg',
  children: [MARGIN_BY_STORE, MARGIN_EVOLUTION],
};

export const ANALYTICS = {
  getText: () => i18next.t('FEATURE.ANALYTICS.ANALYTICS'),
  path: '/analytics',
  icon: '/images/bar-chart.svg',
  children: [
    FOOD_COST_SUBMODULE,
    PURCHASES_SUBMODULE,
    STOCKS_SUBMODULE,
    SALES_SUBMODULE,
    PRODUCTION_SUBMODULE,
    MARGIN_SUBMODULE,
  ],
};
