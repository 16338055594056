import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import qs from 'query-string';
import React, { useState, useEffect } from 'react';

import { password_change } from '@actions/password';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import UserRegistration from '@commons/DeepsightComponents/UserRegistration';

import UserPassword from '@src/routes/invite/components/UserPassword/index';

import { PasswordResetContentContainer, Text } from './styledComponents';

const PasswordResetSuccessfull = () => (
  <PasswordResetContentContainer>
    <img src="/images/inpulse/setup-complete.svg" />
    <Text>{i18next.t('AUTHENTICATION.LOGIN.PASSWORD_RESET_CONTENT')}</Text>
  </PasswordResetContentContainer>
);

const ResetPassword = (props) => {
  const { showMessage, passwordChange } = props;

  const accessToken = qs.parse(props.location.search).token;

  const [actions, setActions] = useState([]);

  const [password, setPassword] = useState('');

  const [hasResetPassword, setHasResetPassword] = useState(false);

  const history = useHistory();

  const getActions = () => {
    if (!!hasResetPassword) {
      setActions([
        {
          color: 'inpulse-default',
          isDisabled: false,
          label: i18next.t('AUTHENTICATION.LOGIN.CONNECT'),
          icon: '/images/inpulse/user-white-small.svg',
          handleClick: () => history.push('/login'),
        },
      ]);
      return;
    }
    setActions([
      {
        color: 'inpulse-outline',
        isDisabled: false,
        label: i18next.t('GENERAL.BACK'),
        icon: '/images/inpulse/arrow-left-ip-black.svg',
        handleClick: () => history.push('/login'),
      },
      {
        color: 'inpulse-default',
        isDisabled: !password.length,
        label: i18next.t('GENERAL.SAVE'),
        icon: '/images/inpulse/save-white-small.svg',
        handleClick: handleClick,
      },
    ]);
  };

  const handleSetPassword = (type, password) => {
    setPassword(password);
  };

  useEffect(() => {
    getActions();
  }, [password, hasResetPassword]);

  const handleClick = async () => {
    try {
      await passwordChange(accessToken, password);
      setHasResetPassword(true);
    } catch (err) {
      showMessage(i18next.t('AUTHENTICATION.LOGIN.RESET_PASSWORD_ERROR'), 'error');
    }
  };

  return (
    <>
      <UserRegistration
        actions={actions}
        Component={hasResetPassword ? PasswordResetSuccessfull : UserPassword}
        params={{
          handleSetUserInformations: handleSetPassword,
        }}
        title={
          hasResetPassword
            ? i18next.t('AUTHENTICATION.LOGIN.PASSWORD_RESET_TITLE')
            : i18next.t('AUTHENTICATION.LOGIN.RESET_PASSWORD_TITLE')
        }
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  passwordChange: (accessToken, password) => dispatch(password_change(accessToken, password)),
});

export default connect(null, mapDispatchToProps)(ResetPassword);
