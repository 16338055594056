import i18next from 'i18next';

export default ({ isCentral }) => [
  {
    name: i18next.t(isCentral ? 'GENERAL.CENTRAL' : 'ORDERS.ORDERS.LIST_LABEL_SUPPLIER'),
    propertyKey: 'supplier',
  },
  {
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_DELIVERY_DATE'),
    propertyKey: 'deliveryDate',
  },
  {
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_CATEGORY'),
    propertyKey: 'subCategory',
  },
  { name: i18next.t('ORDERS.ORDERS.LIST_LABEL_SKU'), propertyKey: 'sku' },
  {
    name: i18next.t('GENERAL.SUPPLIER_PRODUCT'),
    propertyKey: 'name',
  },
  {
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_ORDER_PACKAGING'),
    propertyKey: 'packagingName',
  },
  {
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_ORDERED_QUANTITY'),
    propertyKey: 'ordered',
  },
  {
    name: i18next.t('GENERAL.TOTAL_EX_TAX'),
    propertyKey: 'totalExclTaxes',
    type: 'currency',
  },
  {
    name: i18next.t('ORDERS.ORDERS.EXPORT_VAT_RATE'),
    propertyKey: 'vatRate',
  },
];
