import styled from 'styled-components';

export const StyledPrecisionTooltip = styled.div`
  .chart-precision-tooltip-container {
    width: ${(props) => (props.theme.fonts ? '230px' : '170px')};
    height: ${(props) => (props.theme.fonts ? '150px' : '137px')};
    background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
    box-shadow: ${(props) => (props.theme.colors ? undefined : '0 2px 6px 0 rgba(0, 2, 6, 0.1)')};
    border-radius: ${(props) => props.theme.borders?.radius.weak || '4px'};
  }

  & .chart-precision-tooltip-header {
    height: 26px;
    width: 100%;
    background-color: ${(props) => props.theme.colors?.greys.light || '#6b768c'};
    color: ${(props) => props.theme.colors?.greys.darkest || '#ffffff'};
    text-align: center;

    border-top-left-radius: ${(props) => props.theme.borders?.radius.weak || '4px'};
    border-top-right-radius: ${(props) => props.theme.borders?.radius.weak || '4px'};
    text-transform: capitalize;
    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.h4};`
        : `font-family: proximanovaregular;
    font-size: 12px;
    line-height: 26px;`}
  }

  & .chart-precision-tooltip-content {
    width: 100%;
    height: calc(100% - 26px);
    display: flex;
  }

  & .chart-precision-tooltip-column {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .chart-precision-tooltip-column > h3 {
    padding: 0;
    margin: 0;
    //margin-top: 10px;
    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.textSmall};`
        : `font-size: 14px;
    line-height: 14px;
    font-family: proximanovasemibold;`}
  }

  & .chart-precision-tooltip-column > h4 {
    padding: 0;
    margin: 0;

    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.textMicro};`
        : `font-size: 8px;
    line-height: 8px;
    font-family: proximanovasemibold;`}
  }

  & .chart-precision-tooltip-column-encloser {
    width: 100%;
    display: flex;
  }

  & .chart-precision-tooltip-column-child {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .chart-precision-tooltip-column-child > h3 {
    padding: 0;
    margin: 0;
    //margin-top: 10px;

    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.textSmall};`
        : `font-size: 14px;
    line-height: 14px;
    font-family: proximanovasemibold;`}
  }

  & .chart-precision-tooltip-column-child > h4 {
    padding: 0;
    margin: 0;
    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.textMicro};`
        : `font-size: 8px;
    line-height: 8px;
    font-family: proximanovasemibold;`}
  }
`;
