import i18next from 'i18next';

export const fileStatusCodes = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const fileStatus = {
  [fileStatusCodes.PENDING]: {
    name: 'INVOICE.INVOICE_CONTROL_DETAILS.FILE_STATUS_PENDING',
    color: 'orange',
    inpulseColor: 'orange',
  },
  [fileStatusCodes.IN_PROGRESS]: {
    name: 'INVOICE.INVOICE_CONTROL_DETAILS.FILE_STATUS_IN_PROGRESS',
    color: 'black',
    inpulseColor: 'black',
  },
  [fileStatusCodes.SUCCESS]: {
    name: 'INVOICE.INVOICE_CONTROL_DETAILS.FILE_STATUS_SUCCESS',
    color: 'green',
    inpulseColor: 'green',
  },
  [fileStatusCodes.ERROR]: {
    name: 'INVOICE.INVOICE_CONTROL_DETAILS.FILE_STATUS_ERROR',
    color: 'red',
    inpulseColor: 'red',
  },
};

/**
 * Converts bytes to kilobytes or megabytes accordingly.
 * @param {number} bytes - The number of bytes to convert.
 * @returns {string} - The formatted string in kilobytes (Ko) or megabytes (Mo).
 */
export const formatBytes = (bytes) => {
  if (bytes >= 1000 && bytes <= 999999) {
    const kilobytes = bytes / 1024;
    return `${kilobytes.toFixed(2)} ${i18next.t('GENERAL.KILOBYTES')}`;
  } else if (bytes > 999999) {
    const megabytes = bytes / (1024 * 1024);
    return `${megabytes.toFixed(2)} ${i18next.t('GENERAL.MEGABYTES')}`;
  } else {
    return `${bytes} ${i18next.t('GENERAL.BYTES')}`;
  }
};
