import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { appendColumnToLabel } from '@commons/utils/translations';
import { Container, InputContainer } from '@commons/Modals/InputContentModal/styledComponents';
import { Dropdown } from '@commons/utils/styledLibraryComponents';
import { getUserTimezone } from '@commons/utils/date';

import DateRangeSelectionModal from '@backoffice/components/DateRangeSelectionModal';

// Based on src/routes/backoffice/BackOfficeGeneralContainer/BackOfficeAnalytics/utils/modals.js
// Will be re-factored with https://deepsightprod.atlassian.net/browse/IPP-4592

const ExportLossesSelectors = (props) => {
  const {
    user,
    setSelectedDateRange,
    stores,
    selectedStores,
    setSelectedStores,
    translatedStoreName,
  } = props;

  const userTimezone = getUserTimezone();

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  const storeLabel = appendColumnToLabel(userLanguageCode, translatedStoreName);

  const periodLabel = appendColumnToLabel(userLanguageCode, i18next.t('GENERAL.EXPORT_PERIOD'));

  return (
    <>
      <Container>
        <InputContainer>
          <Dropdown
            customListStyle={{
              position: 'fixed',
              top: 'inherit',
              left: 'inherit',
            }}
            iconSrc={'/images/inpulse/store-black-small.svg'}
            isRequired={true}
            isUniqueSelection={false}
            items={stores}
            label={storeLabel}
            selectedItems={selectedStores}
            onSelectionChange={setSelectedStores}
          />
        </InputContainer>
      </Container>
      <Container style={{ marginTop: '56px' }}>
        <InputContainer>
          <DateRangeSelectionModal
            labelText={periodLabel}
            setDateRange={setSelectedDateRange}
            timezone={userTimezone}
          />
        </InputContainer>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

export default connect(mapStateToProps)(ExportLossesSelectors);
