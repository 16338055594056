import './UploadPhotoNew.css';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import React, { Component } from 'react';

import { showConfirmationMessage } from '../../../actions/messageconfirmation';

import { Button } from '../../utils/styledLibraryComponents';
import { ImageContainer, PlaceholderContainer } from './styledComponents';
import { uploadFile, uploadFileSuccess, uploadFileError } from '../../../actions/upload';
import styled from 'styled-components';

export class UploadPhotoNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readOnly: false,
      pictureUrl: null,
      acceptedFileTypes: ['image/png', 'image/jpeg', 'image/jpg'],
      maxBytes: 5000000,
      isPhotoPlaceholderHover: false,
    };

    if (props.hasOwnProperty('pictureUrl')) {
      this.state.pictureUrl = props.pictureUrl;
    }

    if (props.hasOwnProperty('readOnly')) {
      this.state.readOnly = props.readOnly;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pictureUrl !== this.props.pictureUrl) {
      this.setState({ pictureUrl: this.props.pictureUrl });
    }
  }

  uploadPhoto = (file) => {
    const params = {
      clientId: get(this.props.user, 'clientId'),
      type: 'recipes',
    };

    this.props
      .uploadFile(file, params)
      .then((res) => {
        if (res.file && res.file.data.fileUri) {
          let { fileUri } = res.file.data;
          this.props.handleSelectedPhoto(fileUri);
        }
      })
      .catch((err) => {
        if (err && err.data) {
          if (err.data.message === 'Bad format') {
            this.props.showMessage(this.props.i18n.t('GENERAL.NEW_WRONG_FILE_FORMAT'), 'error');
            return;
          }

          if (err.data.message === 'Bad size') {
            this.props.showMessage(this.props.i18n.t('GENERAL.NEW_FILE_TOO_BIG'), 'error');
            return;
          }
        } else {
          this.props.showMessage(this.props.i18n.t('GENERAL.NEW_UPLOAD_FAILURE'), 'error');
        }
      });
  };

  handlePhoto = (acceptedFiles) => {
    const { acceptedFileTypes, maxBytes } = this.state;

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (file.size > maxBytes) {
          this.props.showMessage(this.props.i18n.t('GENERAL.NEW_FILE_TOO_BIG'), 'error');
          return;
        }

        if (acceptedFileTypes.includes(file.type)) {
          this.uploadPhoto(file);
        } else {
          this.props.showMessage(this.props.i18n.t('GENERAL.NEW_WRONG_FILE_FORMAT'), 'error');
          return;
        }
      };

      reader.readAsDataURL(file);
    });
  };

  openImage = () => {
    if (this.state.pictureUrl.startsWith('data')) {
      //Local file
      let image = new Image();
      image.src = this.state.pictureUrl;
      let w = window.open('');
      w.document.write(image.outerHTML);
    } else {
      //Remote file
      window.open(this.state.pictureUrl, '_blank');
    }
  };

  render() {
    const { readOnly, pictureUrl, isPhotoPlaceholderHover } = this.state;

    return (
      <div
        className={this.props.className}
        onMouseEnter={() => this.setState({ isPhotoPlaceholderHover: true })}
        onMouseLeave={() => this.setState({ isPhotoPlaceholderHover: false })}
      >
        {!pictureUrl ? (
          <Dropzone disabled={readOnly} multiple={false} onDrop={this.handlePhoto}>
            {({ getRootProps, getInputProps }) =>
              !this.props.button ? (
                <PlaceholderContainer
                  {...getRootProps()}
                  data-testid="component-upload-photo-container-img"
                  readOnly={readOnly}
                >
                  <input data-testid="component-upload-photo-input-file" {...getInputProps()} />
                  <ImageContainer display="true" readOnly={readOnly}>
                    {readOnly ? (
                      <img src={'/images/inpulse/addpicture-dmgrey.svg'} />
                    ) : (
                      <img src={'/images/inpulse/addpicture-white.svg'} />
                    )}
                  </ImageContainer>
                </PlaceholderContainer>
              ) : (
                <PlaceholderContainer
                  {...getRootProps()}
                  button={this.props.button}
                  data-testid="component-upload-photo-container-img"
                  readOnly={readOnly}
                >
                  <input data-testid="component-upload-photo-input-file" {...getInputProps()} />
                  <Button
                    color="blue"
                    handleClick={() => {}}
                    icon={'/images/inpulse/addpicture-white.svg'}
                    label={this.props.i18n.t('GENERAL.NEW_ADD_PICTURE')}
                  />
                </PlaceholderContainer>
              )
            }
          </Dropzone>
        ) : (
          <Dropzone disabled={readOnly} multiple={false} onDrop={this.handlePhoto}>
            {({ getRootProps, getInputProps }) => (
              <PlaceholderContainer
                {...getRootProps()}
                button={this.props.button}
                data-testid="component-upload-photo-container-img"
                isHover={isPhotoPlaceholderHover}
                readOnly={readOnly}
                src={pictureUrl}
              >
                <input data-testid="component-upload-photo-input-file" {...getInputProps()} />
                {!(pictureUrl && readOnly) && (
                  <ImageContainer
                    background={pictureUrl}
                    display={isPhotoPlaceholderHover ? 'true' : undefined}
                  >
                    <img src={'/images/inpulse/addpicture-black.svg'} />
                  </ImageContainer>
                )}
              </PlaceholderContainer>
            )}
          </Dropzone>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  uploadFile: (file, params) =>
    dispatch(uploadFile(file, params)).then(
      (result) => dispatch(uploadFileSuccess(result)),
      (error) => {
        dispatch(uploadFileError(error));
        throw error;
      },
    ),
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(UploadPhotoNew);

export default UploadPhotoNew = withTranslation()(styled(Connected)`
  display: flex;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
  align-items: center;
  height: 100%;
  ${(props) => (props.button ? 'width: 250px;' : undefined)}
  button {
    margin-top: 24px;
  }
`);
