import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

export const getRowActions = ({ recipe, getWarningModalParams, setWarningModalParams }) => [
  {
    id: 'assign-category',
    actionLabel: () => i18next.t('ADMIN.RECIPES.PRODUCTION_DELETE_ASSOCIATION'),
    handleAction: (storeEntityMapping) => {
      setWarningModalParams(getWarningModalParams(recipe.name, storeEntityMapping));
    },
    actionIcon: () => '/images/inpulse/link-black-small.svg',
  },
];

export const getListViewActions = ({ handleAddStoreEntityMappingInProductionPlanning }) => [
  {
    id: 'add',
    actionLabel: i18next.t('GENERAL.ADD'),
    handleAction: handleAddStoreEntityMappingInProductionPlanning,
    displayActionInLine: true,
    render: () => (
      <Button
        color={'inpulse-default'}
        handleClick={handleAddStoreEntityMappingInProductionPlanning}
        icon={'/images/inpulse/add-white-small.svg'}
        label={i18next.t('GENERAL.ADD')}
      />
    ),
  },
];
