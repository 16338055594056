import 'react-dates/lib/css/_datepicker.css';
import { connect } from 'react-redux';
import { DateRangePicker } from 'react-dates';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';

import {
  requestRangeForecastsError,
  receiveRangeForecasts,
  fetchRangeForecastsOfStore,
} from '@actions/forecast';
import { requestRangeSalesError, receiveRangeSales, fetchRangeSalesOfStore } from '@actions/sale';

import { Dropdown } from '@commons/utils/styledLibraryComponents';
import {
  renderCustomArrowIcon,
  renderCustomNavIconLeft,
  renderCustomNavIconRight,
  renderTodayDateCircle,
} from '@commons/DeepsightAnalyticsHeaders/utils';

import { getClientInfo } from '@selectors/client';

import theme from '@theme';

import './ForecastPrecisionChart.css';
import DeepsightForecastPrecisionChart from './DeepsightForecastPrecisionChart';

export class ForecastPrecisionChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null,
      algoRMSE: '-',
      dynRMSE: '-',
      savedRMSE: '-',
      dataKeys: [
        {
          id: 0,
          dataKey: 'Algo',
          color: '#0A3EFF',
        },
        {
          id: 1,
          dataKey: 'Dynamic',
          color: '#00D5FF',
        },
        {
          id: 2,
          dataKey: 'Sales',
          color: '#A8B1C4',
        },
      ],
      selectedGranulValue: [
        { id: 0, value: this.props.t('FORECAST.TURNOVER.CHART_GRANULARITY_DAY') },
      ],
      selectedDayValue: [
        { id: 0, value: this.props.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_ALL') },
      ],
      selectedMenu: this.props.t('FORECAST.TURNOVER.ITEM_LIST_ALL'),
      dataType: 0,
    };
  }

  getErrors() {
    let data = this.state.chartData;
    if (data.length === 0) {
      this.setState({ algoRMSE: '-', dynRMSE: '-', savedRMSE: '-' });
    }
    let count = 0,
      sum = 0,
      sales = 0;
    data.forEach((elem, index) => {
      if (elem.sales && elem.forecastsDyn) {
        sum += Math.pow(elem.forecastsDyn - elem.sales, 2);
        count++;
        sales += elem.sales;
      }
    });
    let dynRMSE = parseInt((Math.sqrt(sum / count) / (sales / count)) * 100, 10);
    count = 0;
    sum = 0;
    sales = 0;
    data.forEach((elem, index) => {
      if (elem.sales && elem.forecastsAlgo) {
        sum += Math.pow(elem.forecastsAlgo - elem.sales, 2);
        count++;
        sales += elem.sales;
      }
    });
    let algoRMSE = parseInt((Math.sqrt(sum / count) / (sales / count)) * 100, 10);
    count = 0;
    sum = 0;
    sales = 0;
    data.forEach((elem, index) => {
      if (elem.sales && elem.forecastsSaved) {
        sum += Math.pow(elem.forecastsSaved - elem.sales, 2);
        count++;
        sales += elem.sales;
      }
    });
    let savedRMSE = parseInt((Math.sqrt(sum / count) / (sales / count)) * 100, 10);
    this.setState({
      algoRMSE: isNaN(algoRMSE) ? '-' : 100 - algoRMSE > 0 ? 100 - algoRMSE : 0,
      dynRMSE: isNaN(dynRMSE) ? '-' : 100 - dynRMSE > 0 ? 100 - dynRMSE : 0,
      savedRMSE: isNaN(savedRMSE) ? '-' : 100 - savedRMSE > 0 ? 100 - savedRMSE : 0,
    });
  }

  getDates(startDate, stopDate, granularity) {
    let dateArray = [];
    let currentDate = moment(JSON.parse(JSON.stringify(startDate)));
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = currentDate.add(1, granularity !== 'all' ? 'week' : 'days');
    }
    return dateArray;
  }

  getSameDay(array, refDay) {
    let ret = -1;
    array.map((day, index) => {
      if (moment(day.timestamp).isSame(moment(refDay), 'day')) {
        ret = index;
      }
    });
    return ret;
  }

  handleSelectGranulChange(granularity) {
    this.setState({ selectedGranulValue: granularity });
  }

  handleSelectDayChange(day) {
    this.setState({ selectedDayValue: day });
  }

  handleSelectMenuChange(menu) {
    this.setState({ selectedMenu: menu });
  }

  getDataType(forecasts) {
    let service = false;
    forecasts.forEach((forecast) => {
      if (forecast.service !== null) {
        service = true;
      }
    });
    return service;
  }

  prepareWeekData() {
    if (this.props.rangeSales.length > 0 || this.props.rangeForecasts.length > 0) {
      let dataType = this.getDataType(this.props.rangeForecasts);
      let daysArray = this.getDates(this.state.startDate, this.state.endDate, 'all');
      let data = [],
        dynamicForecasts = [],
        algoForecasts = [],
        savedForecasts = [],
        sales = this.props.rangeSales,
        forecasts = this.props.rangeForecasts;
      if (forecasts && forecasts.length > 0) {
        dynamicForecasts = forecasts.filter((forecast) => {
          if (forecast.type === 'dynamic') {
            return true;
          }
          return false;
        });
        savedForecasts = forecasts.filter((forecast) => {
          if (forecast.type === 'saved') {
            return true;
          }
          return false;
        });
        algoForecasts = forecasts.filter((forecast) => {
          if (forecast.type === 'algo') {
            return true;
          }
          return false;
        });
      }
      if (
        this.state.selectedDayValue.value !==
        this.props.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_ALL')
      ) {
        let referenceWeekDayNumber = moment(this.state.selectedDayValue.value, 'dddd').isoWeekday();
        daysArray = this.getDates(
          moment(this.state.startDate)
            .startOf('week')
            .add(referenceWeekDayNumber - 1, 'days')
            .format(),
          this.state.endDate,
          '',
        );
        dynamicForecasts = dynamicForecasts.filter((forecast) => {
          if (moment(forecast.timestamp).isoWeekday() === referenceWeekDayNumber) {
            return true;
          }
          return false;
        });
        algoForecasts = algoForecasts.filter((forecast) => {
          if (moment(forecast.timestamp).isoWeekday() === referenceWeekDayNumber) {
            return true;
          }
          return false;
        });
        savedForecasts = savedForecasts.filter((forecast) => {
          if (moment(forecast.timestamp).isoWeekday() === referenceWeekDayNumber) {
            return true;
          }
          return false;
        });
        sales = sales.filter((sale) => {
          if (moment(sale.timestamp).isoWeekday() === referenceWeekDayNumber) {
            return true;
          }
          return false;
        });
      }
      let gran = 'tot';
      if (
        this.state.selectedGranulValue.value !==
        this.props.t('FORECAST.TURNOVER.CHART_GRANULARITY_DAY')
      ) {
        gran =
          this.state.selectedGranulValue.value ===
          this.props.t('FORECAST.TURNOVER.CHART_GRANULARITY_NOON')
            ? this.props.t('FORECAST.TURNOVER.CHART_GRANULARITY_NOON')
            : this.props.t('FORECAST.TURNOVER.CHART_GRANULARITY_EVENING');
      }
      if (this.state.selectedMenu !== this.props.t('FORECAST.TURNOVER.ITEM_LIST_ALL')) {
        gran = this.state.selectedMenu;
      }
      daysArray.map((day, index) => {
        let resObj = {
          day: moment(day).format('YYYY/MM/DD'),
          sales: null,
          forecastsAlgo: null,
          errorAlgo: null,
          forecastsDyn: null,
          forecastsSaved: null,
          errorDyn: null,
          errorSaved: null,
        };
        if (sales && sales.length > 0) {
          let ret = this.getSameDay(sales, day);
          if (ret !== -1) {
            resObj.sales = sales[ret][gran] ? sales[ret][gran] : null;
          }
        }
        if (algoForecasts && algoForecasts.length > 0) {
          let ret = this.getSameDay(algoForecasts, day);
          if (ret !== -1) {
            resObj.forecastsAlgo = algoForecasts[ret][gran] ? algoForecasts[ret][gran] : null;
          }
        }
        if (dynamicForecasts && dynamicForecasts.length > 0) {
          let ret = this.getSameDay(dynamicForecasts, day);
          if (ret !== -1) {
            resObj.forecastsDyn = dynamicForecasts[ret][gran] ? dynamicForecasts[ret][gran] : null;
          }
        }
        if (savedForecasts && savedForecasts.length > 0) {
          let ret = this.getSameDay(savedForecasts, day);
          if (ret !== -1) {
            resObj.forecastsSaved = savedForecasts[ret][gran] ? savedForecasts[ret][gran] : null;
          }
        }
        if (resObj.sales !== null && resObj.forecastsAlgo != null) {
          resObj.errorAlgo = resObj.sales - resObj.forecastsAlgo;
        }
        if (resObj.sales !== null && resObj.forecastsDyn != null) {
          resObj.errorDyn = resObj.sales - resObj.forecastsDyn;
        }
        if (resObj.sales !== null && resObj.forecastsSaved != null) {
          resObj.errorSaved = resObj.sales - resObj.forecastsSaved;
        }
        data.push(resObj);
      });
      this.setState({ chartData: data, dataType: dataType });
    } else {
      this.setState({ chartDate: [] });
    }
  }

  fetchSalesData() {
    const {
      client: { isForecastTurnover },
    } = this.props;

    if (this.props.storeId) {
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      this.props.getSales(
        this.props.storeId,
        startDate,
        moment(endDate).add(1, 'day'),
        isForecastTurnover,
      );
    }
  }

  fetchForecastsData() {
    if (this.props.storeId) {
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;
      this.props.getForecasts(this.props.storeId, startDate, moment(endDate).add(1, 'day'));
    }
  }

  componentDidMount() {
    this.setState({
      startDate: moment().subtract(6, 'days'),
      endDate: moment(),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.storeId !== this.props.storeId ||
        prevState.startDate !== this.state.startDate ||
        prevState.endDate !== this.state.endDate ||
        prevState.selectedGranulValue !== this.state.selectedGranulValue ||
        prevState.selectedDayValue !== this.state.selectedDayValue ||
        prevState.selectedMenu !== this.state.selectedMenu) &&
      this.props.storeId &&
      this.state.endDate &&
      this.state.startDate
    ) {
      this.fetchSalesData();
      this.fetchForecastsData();
    }
    if (
      prevProps.rangeSales !== this.props.rangeSales ||
      prevProps.rangeForecasts !== this.props.rangeForecasts
    ) {
      this.prepareWeekData();
    }
    if (prevState.chartData !== this.state.chartData) {
      this.getErrors();
    }
  }

  render() {
    const daySelector = [
      { id: 0, value: this.props.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_ALL') },
      { id: 1, value: this.props.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_MONDAY') },
      { id: 2, value: this.props.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_TUESDAY') },
      { id: 3, value: this.props.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_WEDNESDAY') },
      { id: 4, value: this.props.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_THURSDAY') },
      { id: 5, value: this.props.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_FRIDAY') },
      { id: 6, value: this.props.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_SATURDAY') },
      { id: 7, value: this.props.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_SUNDAY') },
    ];

    const granularity = [
      { id: 0, value: this.props.t('FORECAST.TURNOVER.CHART_GRANULARITY_DAY') },
      { id: 1, value: this.props.t('FORECAST.TURNOVER.CHART_GRANULARITY_NOON') },
      { id: 2, value: this.props.t('FORECAST.TURNOVER.CHART_GRANULARITY_EVENING') },
    ];

    return (
      <div className={this.props.className}>
        <div className="forecast-precision-head-encloser">
          <div className="forecast-precision-left-child">
            <div className="forecast-precision-container-title">
              <h2>{this.props.t('FORECAST.SALES.PRECISON_CHART_TITLE')}</h2>
            </div>
            <div className="forecast-precision-filter-bar">
              <div className="forecast-precision-buttons-encloser">
                <div
                  className="forecast-precision-date-selector"
                  style={
                    this.state.focusedInput
                      ? {
                          border: `${theme.inputs.primary.focus.border}`,
                        }
                      : { border: `${theme.inputs.primary.default.border}` }
                  }
                >
                  <div className="forecast-precision-date-selector-image-encloser">
                    <img
                      alt="Selectionnez le temps"
                      src={'/images/icon-calendar-black-inpulse.svg'}
                      style={{ width: '14px' }}
                    />
                  </div>
                  <DateRangePicker
                    customArrowIcon={renderCustomArrowIcon()} // momentPropTypes.momentObj or null,
                    displayFormat={'ddd Do MMM'} // PropTypes.string.isRequired,
                    endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                    endDatePlaceholderText={this.props.t(
                      'GENERAL.ANALYTICS_HEADER_DATE_PICKER_END_PLACEHOLDER',
                    )} // PropTypes.func.isRequired,
                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    hideKeyboardShortcutsPanel={true} // PropTypes.func.isRequired,
                    isOutsideRange={() => false}
                    minimumNights={0}
                    navNext={renderCustomNavIconRight()}
                    navPrev={renderCustomNavIconLeft()}
                    noBorder={true}
                    numberOfMonths={1}
                    renderDayContents={(item) =>
                      renderTodayDateCircle(item, [this.state.startDate, this.state.endDate])
                    }
                    startDate={this.state.startDate}
                    startDateId="your_unique_start_date_id"
                    startDatePlaceholderText={this.props.t(
                      'GENERAL.ANALYTICS_HEADER_DATE_PICKER_START_PLACEHOLDER',
                    )}
                    onDatesChange={({ startDate, endDate }) =>
                      this.setState({
                        startDate,
                        endDate,
                      })
                    }
                    onFocusChange={(focusedInput) => this.setState({ focusedInput })}
                  />
                </div>
                <Dropdown
                  isRequired={true}
                  items={daySelector}
                  selectedItems={this.state.selectedDayValue}
                  sortBy={(itemsList) => _.sortBy(itemsList, 'id')}
                  onSelectionChange={(days) => this.handleSelectDayChange(days)}
                />
                {this.props.twoServices ? (
                  <Dropdown
                    isRequired={true}
                    items={granularity}
                    selectedItems={this.state.selectedGranulValue}
                    sortBy={(itemsList) => _.sortBy(itemsList, 'id')}
                    onSelectionChange={(granularities) =>
                      this.handleSelectGranulChange(granularities)
                    }
                  />
                ) : undefined}
              </div>
            </div>
          </div>
          <div className="forecast-precision-right-child">
            <div className="forecast-precision-info-bubble black">
              <h2>
                {this.state.savedRMSE === '-'
                  ? this.state.savedRMSE + ' -'
                  : this.state.savedRMSE + '%'}
              </h2>
              <p>{this.props.t('FORECAST.SALES.PRECISON_CHART_LABEL_FA_SAVED')}</p>
            </div>
            <div className="forecast-precision-info-bubble blue">
              <h2>
                {this.state.algoRMSE === '-'
                  ? this.state.algoRMSE + ' -'
                  : this.state.algoRMSE + '%'}
              </h2>
              <p>{this.props.t('FORECAST.SALES.PRECISON_CHART_LABEL_FA_ALGO')}</p>
            </div>
            <div className="forecast-precision-info-bubble light-blue">
              <h2>
                {this.state.dynRMSE === '-' ? this.state.dynRMSE + ' -' : this.state.dynRMSE + '%'}
              </h2>
              <p>{this.props.t('FORECAST.SALES.PRECISON_CHART_LABEL_FA_DYNAMIC')}</p>
            </div>
          </div>
        </div>
        <div className="forecast-precision-chart-encloser">
          <DeepsightForecastPrecisionChart
            data={this.state.chartData}
            metricName={this.props.metricName}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rangeForecasts: state.baseReducer.rangeForecasts,
  rangeSales: state.baseReducer.rangeSales,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  getForecasts: (store_id, start_date, end_date) =>
    dispatch(fetchRangeForecastsOfStore(store_id, start_date, end_date)).then(
      (result) => {
        dispatch(receiveRangeForecasts(result));
      },
      (error) => {
        dispatch(requestRangeForecastsError(error));
      },
    ),
  getSales: (store_id, start_date, end_date, isForecastTurnover) =>
    dispatch(fetchRangeSalesOfStore(store_id, start_date, end_date, isForecastTurnover)).then(
      (result) => {
        dispatch(receiveRangeSales(result));
      },
      (error) => {
        dispatch(requestRangeSalesError(error));
      },
    ),
});

ForecastPrecisionChart = connect(mapStateToProps, mapDispatchToProps)(ForecastPrecisionChart);

const Styled = styled(ForecastPrecisionChart)`
  width: 100%;
  height: 400px;
  background-color: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
  border-radius: ${(props) => props.theme.borders?.radius.weak || '6px'};
  box-shadow: ${(props) => (props.theme.colors ? undefined : '0 2px 6px 0 rgba(0, 0, 0, 0.1)')};
  padding: 30px;
  padding-top: 40px;
  margin-top: 20px;

  .forecast-precision-head-encloser {
    display: flex;
    justify-content: space-between;
  }

  .forecast-precision-container-title > h2 {
    margin: 0;
    padding: 0;
    ${(props) => (props.theme.fonts ? `font: ${props.theme.fonts?.h1};` : `font-size: 18px;`)}
  }

  .forecast-precision-filter-bar {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .forecast-precision-buttons-encloser {
    display: flex;
  }

  .forecast-precision-buttons-encloser > div {
    margin-right: 10px;
  }

  .forecast-precision-date-selector {
    min-width: 220px;
    max-width: 250px;
    height: 40px;
    background-color: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
    border-radius: ${(props) => props.theme.borders?.radius.weak || '4px'};
    box-shadow: none;
    color: ${(props) => props.theme.colors?.greys.light || '#6b768c'};
    display: flex;
    justify-content: flex-end;

    > div {
      display: flex;
      align-items: center;

      height: 40px;
      width: calc(100% - 40px);

      > div {
        width: 100%;

        > div {
          width: 100%;
          display: flex;

          .DateInput_1 {
            flex: 1;
          }
        }
      }
    }
  }

  .forecast-precision-date-selector-image-encloser {
    height: 90%;
    display: flex;
    padding-left: 7px;
    align-items: center;
    justify-content: center;
  }

  .forecast-precision-date-selector-image-encloser > img {
    width: 15px;
  }
  /* 
  .DateInput {
    background: none !important;
  }

  .DateInput_input {
    background: none !important;
    padding: 2px 8px 0px !important;
    color: ${(props) => props.theme.colors?.greys.dark || '#6b768c'} !important;

    ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts?.textBig} !important;`
      : `font-family: proximanovaregular !important;
    font-size: 14px !important;`}
  }

  .DateInput_input:hover {
    color: ${(props) => props.theme.colors?.brand.secondaryDark || '#0a3eff'} !important;
  }

  .DateRangePickerInput_arrow_svg {
    fill: ${(props) => props.theme.colors?.greys.dark || '#6b768c'} !important;
  } */

  .forecast-precision-filters-encloser {
    display: flex;
  }

  .forecast-precision-filter {
    height: 28px;
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  .forecast-precision-filter > p {
    margin-top: 10px;
    margin-right: 10px;
    color: ${(props) => props.theme.colors?.greys.dark || '#6b768c'};
    font-size: 14px;
    ${(props) => (props.theme.fonts ? `font: ${props.theme.fonts?.textBig};` : `font-size: 14px;`)}
  }

  .forecast-precision-week-input {
    width: 160px;
    height: 28px;
    background-color: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
    border: 0;
    border-radius: ${(props) => props.theme.borders?.radius.weak || '4px'};
    box-shadow: ${(props) => (props.theme.colors ? undefined : '0 2px 6px 0 rgba(0, 0, 0, 0.1)')};
    color: ${(props) => props.theme.colors?.greys.dark || '#6b768c'};
    padding-left: 5px;
  }

  .forecast-precision-right-child {
    display: flex;
  }

  .forecast-precision-info-bubble {
    width: 100px;
    height: 50px;
    border-radius: 4px;
    margin-left: 20px;
    box-shadow: ${(props) => (props.theme.colors ? undefined : '0 2px 6px 0 rgba(0, 0, 0, 0.1)')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .forecast-precision-info-bubble.blue {
    color: ${(props) => props.theme.colors?.brand.secondaryDark || '#0a3eff'};
  }

  .forecast-precision-info-bubble.light-blue {
    color: ${(props) => props.theme.colors?.brand.secondary || '#2dc6fe'};
  }

  .forecast-precision-info-bubble.light-black {
    color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};
  }

  .forecast-precision-info-bubble > h2 {
    margin: 0;
    padding: 0;

    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.textBig};`
        : `font-size: 20px;
    line-height: 20px;
    font-family: proximanovasemibold;`}
  }

  .forecast-precision-info-bubble > p {
    margin: 0;
    padding: 0;

    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.textBig};`
        : `font-size: 10px;
    font-family: proximanovaregular;`}
  }

  .forecast-precision-chart-encloser {
    width: calc(100% + 30px);
    margin-left: -30px;
  }

  & .DateRangePicker_picker {
    top: 50px !important;
    left: -40px !important;
  }

  & .DateInput_fang {
    display: none !important;
  }

  & .DateRangePickerInput__disabled {
    background-color: lightgrey !important;
  }

  & .CalendarMonth_caption {
    color: ${(props) => props.theme.colors?.greys?.darkest} !important;
    font: ${(props) => props.theme.fonts?.h3} !important;
  }

  & .DateInput_input {
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    text-align: center !important;
    cursor: pointer;
  }

  & .DateInput_input__focused {
    border-bottom: none !important;
  }

  & .DayPicker_weekHeader_li {
    font: ${(props) => props.theme.fonts?.textMicro} !important;
  }

  & .CalendarDay {
    background: ${(props) => props.theme.colors?.greys.lightest} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__default:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__highlighted_calendar {
    background: #fff !important;
    border: 1px double #0a3eff !important;
  }

  & .CalendarDay__highlighted_calendar:hover {
    background: #e4e7e7 !important;
  }

  & .CalendarDay__selected_span {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    border: 1px double #e2e2e2 !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected,
  .CalendarDay__selected:active .CalendarDay__selected:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_start,
  .CalendarDay__selected_start:hover,
  .CalendarDay__selected_end,
  .CalendarDay__selected_end:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover {
    color: ${(props) => props.theme.colors?.greys.dark} !important;
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
  }
`;

export default withTranslation()(Styled);
