import { currencyService } from '../services/backoffice/currency';

export const requestCurrency = (currency) => ({
  type: 'CURRENCY_SUCCESS',
  currency,
});

export const requestCurrencyError = (error) => ({
  type: 'CURRENCY_FAILURE',
  error,
});

export const getCurrencyById = (currencyId) =>
  function (dispatch) {
    dispatch({
      type: 'CURRENCY_REQUEST',
    });
    return currencyService.getClientCurrency(currencyId);
  };
