import styled from 'styled-components';

export const Container = styled.div`
  height: inherit;
  width: inherit;
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 72px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: ${(props) => props.theme.colors.infoOrange || '#FF9432'};
  border-radius: 8px 8px 0px 0px;
`;

export const Title = styled.div`
  font: ${(props) => props.theme.fonts.h1InterNonBold || 'normal 700 20px/24px inter'};
  color: ${(props) => props.theme.colors.greys.lightest || '#FFFFFF'};
`;

export const CloseContainer = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const CloseIcon = styled.div`
  width: 16px;
  height: 16px;
`;

export const ContentBody = styled.div``;

export const Icon = styled.img``;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: ${(props) => props.theme.colors.greys.lightest || '#FFFFFF'};
  border-radius: 0px 0px 8px 8px;
  font: ${(props) => props.theme.fonts.textBigHeight24 || 'normal 500 14px/24px inter'};
  color: ${(props) => props.theme.fonts.darker || '#606060'};
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export default {
  Container,
  TitleContainer,
  Title,
  CloseContainer,
  Icon,
  CloseIcon,
  ContentContainer,
  ButtonsContainer,
  ContentBody,
};
