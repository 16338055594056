import { connect } from 'react-redux';
import { get, isEmpty, keyBy } from 'lodash';
import i18next from 'i18next';
import React, { useState } from 'react';

import { closeGenericModal, openGenericModal, refreshGenericModal } from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { Button } from '@commons/utils/styledLibraryComponents';
import { clientStatusCheck } from '@commons/utils/clientStatusCheck';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import WhiteCard from '@commons/WhiteCard';

import { getClientInfo } from '@selectors/client';

import onboardingService from '@services/backoffice/onBoarding';

import theme from '@theme';

import { downloadFile, formatXlsFileToJson } from '@backoffice/utils';
import ImportExcelFileModal from '@backoffice/components/ImportExcelFileModal';

import { Container, WhiteCardsContainer } from './styledComponents';

export const BackOfficeAccountCreation = (props) => {
  const {
    match,
    client: { clientName, clientId, clientStatus },
    showMessage,
    openGenericModal,
    refreshGenericModal,
    pageLoading,
    pageLoaded,
  } = props;

  const path = get(match, 'path');

  const [isDownloadingExcel, setIsDownloadingExcel] = useState(false);

  const getExcel = async () => {
    setIsDownloadingExcel(true);

    try {
      const response = await onboardingService.getUsersCreationExcelTemplate(clientId);

      downloadFile(response, clientName);
    } catch (error) {
      showMessage(i18next.t('BACKOFFICE.STORES.EXCEL_RETRIEVING_ERROR'), 'error');
    } finally {
      setIsDownloadingExcel(false);
    }
  };

  const formatJson = (jsonFile) => {
    const excelContent = { ...jsonFile };

    const rolesByNames = keyBy(excelContent.roles, 'nom');

    const catalogsByNames = keyBy(excelContent.catalogs, 'nom');

    return Object.keys(excelContent).reduce((result, sheetName) => {
      if (sheetName === 'roles' || sheetName === 'catalogs') {
        result[sheetName] = excelContent[sheetName]
          .filter((current) => !isEmpty(current['id']))
          .map((current) => ({
            id: current['id'],
            name: current['nom'],
          }));
      }

      if (sheetName === 'languages') {
        result[sheetName] = excelContent[sheetName].map((current) => ({
          id: current['id'],
          name: current['nom'],
          code: current['code'],
        }));
      }

      if (sheetName === 'accounts') {
        result[sheetName] = excelContent[sheetName].map((current) => ({
          id: current.id || null,
          password: current['mot de passe'] ? current['mot de passe'].toString() : null,
          firstName: current['prénom'],
          lastName: current['nom de famille'],
          isDeepsight: current['is inpulse'],
          email: current.email.toLowerCase(),
          accountRoleId: !isEmpty(rolesByNames[current['nom du role']])
            ? rolesByNames[current['nom du role']].id
            : '',
          catalogId:
            // Also check if catalog id is not null (referred as 'Aucun')
            !isEmpty(catalogsByNames[current['référentiel']]) &&
            !!catalogsByNames[current['référentiel']].id
              ? catalogsByNames[current['référentiel']].id
              : null,
          code: current['langue par défaut'],
        }));
      }
      return result;
    }, {});
  };

  const onFileChange = (file) => {
    refreshGenericModal(getModalConfig('select-file', file));
  };

  const DEFAULT_MODAL_CONFIG = {
    width: '542px',
    height: 'auto',
    component: ImportExcelFileModal,
  };

  const handleFileValidation = async (file) => {
    try {
      pageLoading();
      const jsonFile = await formatXlsFileToJson(file);
      const formattedJsonFile = formatJson(jsonFile);

      const { status, data } = await onboardingService.validateAccountsExcel(
        clientId,
        formattedJsonFile.roles,
        formattedJsonFile.languages,
        formattedJsonFile.accounts,
        formattedJsonFile.catalogs,
      );

      if (status === 'success') {
        refreshGenericModal(getModalConfig('validate-file', file, null, formattedJsonFile));
        pageLoaded();

        return;
      }

      refreshGenericModal(getModalConfig('error-file', file, data));
    } catch (error) {
      props.showMessage(
        i18next.t('BACKOFFICE.USERS.ACCOUNT_CREATION_EXCEL_IMPORT_VALIDATION_ERROR', 'error'),
      );
    } finally {
      pageLoaded();
    }
  };

  const createUserByBatch = async (data) => {
    if (isEmpty(data)) {
      props.showMessage(
        i18next.t('BACKOFFICE.USERS.ACCOUNT_CREATION_UPLOAD_EXCEL_IMPORT_MISSING_FILE'),
        'error',
      );
      return;
    }

    try {
      pageLoading();

      await onboardingService.createUserByBatchRequest(
        clientId,
        data.roles,
        data.languages,
        data.accounts,
        data.catalogs,
      );

      props.showMessage(
        i18next.t('BACKOFFICE.USERS.ACCOUNT_CREATION_UPLOAD_EXCEL_CREATION_SUCCESS'),
        'success',
      );
    } catch (error) {
      if (get(error.response, 'status') === 409) {
        props.showMessage(
          get(
            error.response,
            'data.message',
            i18next.t('BACKOFFICE.USERS.ACCOUNT_CREATION_UPLOAD_EXCEL_CREATION_ERROR'),
          ),
          'error',
        );
      } else {
        props.showMessage(
          i18next.t('BACKOFFICE.USERS.ACCOUNT_CREATION_UPLOAD_EXCEL_CREATION_ERROR'),
          'error',
        );
      }
    } finally {
      pageLoaded();
    }
  };

  const getModalConfig = (type, updatedFile = null, errorFile = null, data = null) => {
    if (type === 'select-file') {
      return {
        ...DEFAULT_MODAL_CONFIG,
        type: 'action',
        title: i18next.t('BACKOFFICE.USERS.ACCOUNT_CREATION_UPLOAD_EXCEL_MODAL_TITLE'),
        icon: '/images/inpulse/file-upload-black-small.svg',
        data: {
          subTitle: i18next.t('BACKOFFICE.USERS.ACCOUNT_CREATION_UPLOAD_EXCEL_MODAL_CONTENT'),
          selectedFile: updatedFile,
          setSelectedFile: onFileChange,
        },
        actions: [
          {
            key: 0,
            color: 'inpulse-outline',
            label: i18next.t('GENERAL.CANCEL'),
            icon: '/images/inpulse/close-black-small.svg',
          },
          {
            key: 1,
            isDisabled: !updatedFile,
            preventClosing: true,
            color: 'inpulse-default',
            label: i18next.t('GENERAL.IMPORT'),
            icon: '/images/inpulse/file-upload-white-small.svg',
            handleClick: () => handleFileValidation(updatedFile),
          },
        ],
      };
    }

    if (type === 'validate-file') {
      return {
        ...DEFAULT_MODAL_CONFIG,
        type: 'success',
        title: i18next.t('BACKOFFICE.USERS.ACCOUNT_CREATION_UPLOAD_EXCEL_TITLE'),
        icon: '/images/inpulse/check-white-small.svg',
        data: {
          subTitle: i18next.t('BACKOFFICE.USERS.MODAL_IMPORT_SELECT_FILE_CONTENT_VALIDATED'),
          selectedFile: updatedFile,
          validatedFile: true,
        },
        actions: [
          {
            key: 0,
            color: 'inpulse-default',
            label: i18next.t('GENERAL.IMPORT'),
            icon: '/images/inpulse/check-white-small.svg',
            handleClick: () => createUserByBatch(data),
          },
        ],
      };
    }

    if (type === 'error-file') {
      return {
        ...DEFAULT_MODAL_CONFIG,
        type: 'error',
        title: i18next.t('BACKOFFICE.USERS.ACCOUNT_CREATION_UPLOAD_EXCEL_TITLE'),
        icon: '/images/inpulse/info-white-small.svg',
        data: {
          subTitle: i18next.t('BACKOFFICE.CLIENT.MODAL_IMPORT_SELECT_FILE_CONTENT_ERROR'),
          validatedFile: false,
          selectedFile: updatedFile,
        },
        actions: [
          {
            key: 0,
            label: 'Fichier erreur',
            color: 'inpulse-default',
            icon: '/images/inpulse/file-download-white-small.svg',
            handleClick: () => downloadFile(errorFile, clientName, true),
          },
        ],
      };
    }
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <WhiteCardsContainer>
        <WhiteCard
          content={i18next.t('BACKOFFICE.USERS.ACCOUNT_CREATION_IMPORT_EXCEL_TEMPLATE_CONTENT')}
          contentColor={theme.colors?.greys.darker}
          renderContent={
            <Button
              animation={{ rotate: isDownloadingExcel }}
              buttonCustomStyle={{ width: 'fit-content' }}
              color={'inpulse-default'}
              handleClick={() => {
                if (isDownloadingExcel) {
                  return;
                }
                getExcel();
              }}
              icon={
                isDownloadingExcel
                  ? '/images/inpulse/loader-white-small.svg'
                  : '/images/inpulse/file-download-white-small.svg'
              }
              isDisabled={isDownloadingExcel}
              label={i18next.t('GENERAL.DOWNLOAD')}
            />
          }
          title={i18next.t('BACKOFFICE.USERS.ACCOUNT_CREATION_IMPORT_EXCEL_TEMPLATE_TITLE')}
        />
        <WhiteCard
          content={i18next.t('BACKOFFICE.USERS.ACCOUNT_CREATION_UPLOAD_EXCEL_TEST_CONTENT')}
          contentColor={theme.colors?.greys.darker}
          renderContent={
            <Button
              buttonCustomStyle={{ width: 'fit-content' }}
              color={'inpulse-default'}
              handleClick={() => {
                openGenericModal(getModalConfig('select-file', null));
              }}
              icon={'/images/inpulse/file-upload-white-small.svg'}
              isDisabled={!clientStatusCheck(clientStatus, 'onboarding')}
              label={i18next.t('GENERAL.IMPORT')}
            />
          }
          title={i18next.t('BACKOFFICE.USERS.ACCOUNT_CREATION_UPLOAD_EXCEL_TEST_TITLE')}
        />
      </WhiteCardsContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeAccountCreation);
