import { connect } from 'react-redux';
import React from 'react';

import { openSmallModal } from '@actions/modal';

import PenIcon from '@assets/icons/black-pen.svg';

import { Box, Container } from './styledComponents';
import EditToProduceModal from './components/EditToProduceModal';

export function openModalEdition(
  openModal,
  product,
  toProduce,
  toProduceRecoDeepsight,
  handleSave,
  unsetActiveProduct,
) {
  const params = {
    component: EditToProduceModal,
    product,
    toProduce,
    toProduceRecoDeepsight,
    handleSave,
    unsetActiveProduct,
  };

  return openModal(params);
}

export const ToProduceSection = (props) => {
  const {
    product,
    handleSave,
    isEditable,
    isProductActive,
    openModal,
    toProduce,
    toProduceRecoDeepsight,
    unsetActiveProduct,
    setActiveProduct,
  } = props;

  return (
    <Container isFixed={isProductActive}>
      <Box hidden></Box>
      <Box>{toProduce}</Box>
      {isEditable && !isProductActive && (
        <Box
          className={'planning-product-row-prod-edit clickable'}
          hidden={isEditable && isProductActive}
          onClick={() => {
            openModalEdition(
              openModal,
              product,
              toProduce,
              toProduceRecoDeepsight,
              handleSave,
              unsetActiveProduct,
            );
            setActiveProduct();
          }}
        >
          <img alt="cancel" src={PenIcon} />
        </Box>
      )}
    </Container>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  openModal: (params) => {
    dispatch(openSmallModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ToProduceSection);
