import React from 'react';

import { ModalContent } from './styledComponents';

function ConfirmationModal(props) {
  const { content } = props;
  return <ModalContent>{content}</ModalContent>;
}

export default ConfirmationModal;
