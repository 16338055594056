import styled, { css } from 'styled-components';

import IconArrowDownDark from '../../../images/icon-arrow-down-dark.svg';
import IconArrowDownGreen from '../../../images/icon-arrow-down-green.svg';
import IconArrowDownGrey from '../../../images/icon-arrow-down-grey.svg';
import IconArrowDownWhite from '../../../images/icon-arrow-down-white.svg';

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;

  width: ${(props) => props.theme.toggle?.size?.width};
  height: ${(props) => props.theme.toggle?.size?.height};

  margin: 0 0 0 15px;

  ${(props) => {
    if (!props.visibility) {
      return css`
        background-color: 'transparent';
      `;
    }

    if (props.isLoading || props.isDisabled) {
      return css`
        border: ${props.theme.toggle?.loading?.border};
      `;
    }

    if (props.open) {
      return css`
        background-color: ${props.theme.toggle?.selected?.background};
      `;
    }

    return css`
      border: ${props.theme.toggle?.default?.border};
    `;
  }};

  &:hover {
    ${(props) =>
      !props.isLoading &&
      !props.isDisabled &&
      css`
        border: ${props.theme.toggle?.hover?.border};
      `}
  }

  &:hover:not(${(props) => props.open}) {
    background: none;
  }
`;

export const DropdownPicture = styled.img.attrs((props) => ({
  src:
    // eslint-disable-next-line no-nested-ternary
    props.isLoading || props.isDisabled
      ? IconArrowDownGrey
      : // eslint-disable-next-line no-nested-ternary
      props.isButtonHovered
      ? IconArrowDownGreen
      : props.open
      ? IconArrowDownWhite
      : IconArrowDownDark,
}))`
  ${(props) =>
    props.open &&
    css`
      transform: rotate(180deg);
    `};

  visibility: ${(props) => (!props.visibility ? 'hidden' : 'visible')};
`;

export default {
  Button,
  DropdownPicture,
};
