import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { Button } from '../../utils/styledLibraryComponents';

import {
  Container,
  ContentContainer,
  Divider,
  FooterContainer,
  Title,
  TitleContainer,
} from './styledComponents';

const UserRegistration = (props) => {
  const {
    title,
    Component,
    actions,
    params,
    height = '430px',
    headerPadding = '32px 48px',
  } = props;

  return (
    <>
      <Container height={height}>
        <TitleContainer headerPadding={headerPadding}>
          <Title>{parse(title)}</Title>
        </TitleContainer>
        <Divider />

        <ContentContainer>{!!Component && <Component params={params} />}</ContentContainer>

        <Divider />
        <FooterContainer hasSpaceBetween={actions.length > 1}>
          {actions.map((action, index) => {
            if (!!action.render) {
              actions.render();
              return;
            }

            return (
              <Fragment key={`${action.label}-${index}`}>
                <Button
                  color={action.color}
                  handleClick={action.handleClick}
                  icon={action.icon}
                  isDisabled={action.isDisabled}
                  label={action.label}
                  type={action.type}
                />
              </Fragment>
            );
          })}
        </FooterContainer>
      </Container>
    </>
  );
};

UserRegistration.propTypes = {
  title: PropTypes.string.isRequired,
  Component: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      handleClick: PropTypes.func,
      label: PropTypes.string.isRequired,
      isDisabled: PropTypes.bool,
      icon: PropTypes.string,
      render: PropTypes.func,
    }),
  ),
};

UserRegistration.defaultProps = {
  actions: [],
};

export default UserRegistration;
