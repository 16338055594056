import i18next from 'i18next';

const CENTRAL_KITCHEN_PRODUCTION_PLANNING = {
  getText: () => i18next.t('FEATURE.OPERATIONS.PRODUCTION_PLANNING'),
  path: '/central-kitchen-production/planning',
};

const CENTRAL_KITCHEN_FORECAST = {
  getText: () => i18next.t('FEATURE.OPERATIONS.FORECAST'),
  path: '/central-kitchen-production/forecast',
};

const CENTRAL_KITCHEN_PRODUCTION = {
  getText: () => i18next.t('FEATURE.OPERATIONS.PRODUCTION'),
  path: '/central-kitchen-production/operations',
  getBreadCrumbText: () => i18next.t('FEATURE.OPERATIONS.PRODUCTION_BREADCRUMB'),
};

const CENTRAL_KITCHEN_PRODUCTION_SUBMODULE = {
  getText: () => i18next.t('FEATURE.OPERATIONS.PRODUCTION'),
  icon: '/images/production.svg',
  children: [
    CENTRAL_KITCHEN_PRODUCTION_PLANNING,
    CENTRAL_KITCHEN_FORECAST,
    CENTRAL_KITCHEN_PRODUCTION,
  ],
};

const PREPARATION = {
  getText: () => i18next.t('FEATURE.OPERATIONS.PREPARATION'),
  path: '/central-kitchen-order/preparation',
  getBreadCrumbText: () =>
    i18next.t('FEATURE.OPERATIONS.CENTRAL_KITCHEN_ORDERS_PREPARATION_BREADCRUMB'),
};

const ORDER = {
  getText: () => i18next.t('FEATURE.OPERATIONS.ORDERS'),
  path: '/central-kitchen-order/orders',
};

const CENTRAL_KITCHEN_ORDER_SUBMODULE = {
  getText: () => i18next.t('FEATURE.OPERATIONS.ORDERS'),
  icon: '/images/orders.svg',
  children: [PREPARATION, ORDER],
};

const STOCKS = {
  getText: () => i18next.t('FEATURE.OPERATIONS.INVENTORIES'),
  path: '/central-kitchen-stocks/inventories/supplier-products',
  getBreadCrumbText: () =>
    i18next.t('FEATURE.OPERATIONS.CENTRAL_KITCHEN_SUPPLIER_PRODUCTS_STOCKS_BREADCRUMB'),
};

const CENTRAL_KITCHEN_LOSSES = {
  getText: () => i18next.t('FEATURE.OPERATIONS.LOSSES'),
  path: '/central-kitchen-stocks/losses',
};

const CENTRAL_KITCHEN_STOCK_SUBMODULE = {
  getText: () => i18next.t('FEATURE.OPERATIONS.STOCK'),
  icon: '/images/icon-stock.svg',
  children: [STOCKS, CENTRAL_KITCHEN_LOSSES],
};

export const CENTRAL_KITCHEN_OPERATIONS = {
  getText: () => i18next.t('FEATURE.OPERATIONS.OPERATIONS'),
  path: '/operations',
  icon: '/images/operations.svg',
  children: [
    CENTRAL_KITCHEN_PRODUCTION_SUBMODULE,
    CENTRAL_KITCHEN_ORDER_SUBMODULE,
    CENTRAL_KITCHEN_STOCK_SUBMODULE,
  ],
};
