import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { ThemeProvider } from 'styled-components';

import { getTheme } from '../utils/theme';

import { SearchBarContainer, Icon, SearchBarInput } from './styledComponents';

import { Props } from './interfaces';

import SearchBarIcon from '../images/icon-searchbar.svg';
import SearchBarIconWhite from '../images/icon-searchbar-white.svg';
import CloseIcon from '../images/icon-close-cross.svg';

const SearchBar = (props: Props): JSX.Element => {
  const {
    theme,
    placeholder,
    value,
    setValue,
    minWidth,
    maxWidth,
    disabled,
    height,
  } = props;

  const [labelPlaceholder, setLabelPlaceholder] = useState(placeholder);

  const [searchValue, setSearchValue] = useState(value);

  const changeHandler = (event) => {
    if (event.key === 'Enter') {
      setValue(event.target.value);
      setSearchValue(event.target.value);
    }
  };

  useEffect(() => {
    setLabelPlaceholder(placeholder);
  }, [placeholder]);

  const resetSearchInput = () => {
    setSearchValue('');
    setValue('');
  };

  useEffect(() => {
    if (!searchValue && value) {
      resetSearchInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (value === null) {
      resetSearchInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <ThemeProvider theme={getTheme(theme, 'searchbar')}>
      <SearchBarContainer
        disabled={disabled}
        minWidth={minWidth}
        maxWidth={maxWidth}
        height={height}
      >
        <Icon
          clickable
          onClick={() => setValue(searchValue)}
          src={disabled ? SearchBarIconWhite : SearchBarIcon}
        />
        <SearchBarInput
          placeholder={labelPlaceholder}
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          disabled={disabled}
          onKeyDown={changeHandler}
        />
        {searchValue && !!searchValue.length && (
          <Icon src={CloseIcon} clickable onClick={resetSearchInput} />
        )}
      </SearchBarContainer>
    </ThemeProvider>
  );
};

SearchBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.objectOf(PropTypes.any),
  placeholder: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  disabled: PropTypes.bool,
  height: PropTypes.number,
};

SearchBar.defaultProps = {
  placeholder: i18next.t('COMPONENT_SEARCHBAR_SEARCH'),
  value: '',
  minWidth: 240,
  maxWidth: 240,
  disabled: false,
  theme: null,
  height: 40,
};

export default SearchBar;
