import React, { useEffect, useState } from 'react';

import { DeepsightUploadPicture } from '@commons/DeepsightComponents';

import { Container } from './styledComponents';

export function RecipePictureSection(props) {
  const { recipe, onRecipeChange, isReadOnly } = props;

  const [pictureUrl, setPictureUrl] = useState(recipe.img || '');

  useEffect(() => {
    setPictureUrl(recipe.img || '');
  }, [recipe.id]);

  return (
    <Container>
      <DeepsightUploadPicture
        handleSelectedPhoto={(fileUri) => {
          onRecipeChange({ ...recipe, img: fileUri });
          setPictureUrl(fileUri);
        }}
        pictureUrl={pictureUrl}
        readOnly={isReadOnly}
      />
    </Container>
  );
}

export default RecipePictureSection;
