import i18next from 'i18next';

const getCashierStoreInformationCardSettings = ({ cashierConfigs, cashierStoreInformations }) => {
  const inputs = [
    {
      default: null,
      hasTooltip: false,
      isDropdown: false,
      isRequired: true,
      label: `* ${i18next.t('GENERAL.NAME')}`,
      placeholder: i18next.t('GENERAL.NAME'),
      propertyKey: 'name',
      type: 'text',
    },
    {
      hasTooltip: true,
      isDropdown: false,
      isRequired: true,
      tooltipText: i18next.t('BACKOFFICE.CASHIER_STORES.PARTNER_ID_TOOLTIP'),
      label: `* ${i18next.t('BACKOFFICE.CASHIER_STORES.PARTNER_ID')}`,
      placeholder: i18next.t('BACKOFFICE.CASHIER_STORES.PARTNER_ID'),
      propertyKey: 'partnerId',
      type: 'text',
      readOnly: cashierStoreInformations.isSynchronized,
    },
    {
      dropdownValue: cashierConfigs,
      dropdownMatchingProperty: 'id',
      hasTooltip: true,
      isDropdown: true,
      isRequired: true,
      label: `${i18next.t('GENERAL.CASHIER_CONNEXION')}`,
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      tooltipText: i18next.t('BACKOFFICE.CASHIER_STORES.CASHIER_CONNEXION_TOOLTIP'),
      propertyKey: 'cashierConfigId',
      type: 'text',
      readOnly: cashierStoreInformations.isSynchronized,
    },
    {
      hasTooltip: false,
      isDropdown: false,
      isRequired: false,
      label: i18next.t('GENERAL.PHONE'),
      placeholder: i18next.t('GENERAL.PHONE'),
      propertyKey: 'telephone',
      type: 'text',
    },
    {
      hasTooltip: false,
      isDropdown: false,
      isRequired: false,
      isSwitch: true,
      label: i18next.t('GENERAL.STORES_ACTIVE'),
      propertyKey: 'active',
    },
  ];

  return {
    title: i18next.t('BACKOFFICE.STORES.CREATION_MODAL_CARD_INFORMATION_TITLE'),
    inputs: inputs.reduce((acc, input) => {
      acc.push({ ...input, value: cashierStoreInformations[input.propertyKey] });
      return acc;
    }, []),
  };
};

export default getCashierStoreInformationCardSettings;
