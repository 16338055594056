import { get, isEmpty } from 'lodash';

import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { Fragment, useState, useEffect } from 'react';

import { loading, loadingSuccess } from '../../../actions/loading';
import { openConfirmationModal } from '../../../actions/confirmationmodal';
import { showErrorMessage, showSuccessMessage } from '../../../actions/messageconfirmation';

import { event as eventService } from '../../../services/event';

import { Button, Dropdown, INPUT_WIDTH } from '../../../commons/utils/styledLibraryComponents';
import { SingleDatePicker } from '../../../commons/DatePickers/SingleDatePicker';
import ConfirmationModal from '../../../commons/DeepsightComponents/ConfirmationModal/ConfirmationModal';
import Footer from '../../../commons/Footer/Footer';
import Text, { ENUM_COLORS, ENUM_FONTS } from '../../../commons/Text';

import { Input } from '../../../lib/inpulse/Input';

import {
  EVENT_IMPACT_DROPDOWN_ITEMS,
  QUARTER_HOURS_DROPDOWN_ITEMS,
} from '../../admin/utils/DropdownItems';

import {
  List,
  Divider,
  Container,
  TitleContainer,
  DetailContainer,
  ButtonsContainer,
  ContentContainer,
} from './styledComponents';

// 068f5a3a-4375-11e9-8442-0af6991d48ca is LocalEvent, please do not judge me if you see my name on this 👀
const LOCAL_EVENT_ID = '068f5a3a-4375-11e9-8442-0af6991d48ca';

// fb9febda-4374-11e9-8441-0af6991d48ca is LocalEvent, please do not judge me if you see my name on this 👀
const NATIONAL_EVENTS_CATEGORY_ID = 'fb9febda-4374-11e9-8441-0af6991d48ca';

const DEFAULT_EMPTY_EVENT = {
  impact: null,
  endDate: null,
  startDate: null,
  input1Value: null,
  input2Value: null,
  input3Value: null,
};

const getHourItems = (minTime) => {
  const items = [...QUARTER_HOURS_DROPDOWN_ITEMS, { id: '23:59', value: '23:59' }];

  if (!minTime) {
    return items;
  }

  const indexMinTime = items.findIndex((item) => item.value === minTime);

  if (indexMinTime == null) {
    return items;
  }

  items.splice(0, indexMinTime);

  return items;
};

const getTimeFromDateMoment = (date, timezone) => {
  if (!date) {
    return null;
  }

  const timezonedDate = moment.tz(date, timezone);

  const formattedHourMinute = timezonedDate.format('HH:mm', { trim: false });

  return { id: formattedHourMinute, value: formattedHourMinute };
};

const EventForm = (props) => {
  const {
    user,
    stores,
    storeId,
    closeForm,
    selectedEvent,
    loadStoreEvents,
    eventSubCategories,
    // Methods
    pageLoaded,
    pageLoading,
    showErrorMessage,
    showSuccessMessage,
    openConfirmationModal,
  } = props;

  const [categories, setCategories] = useState([]);
  const [subCategoriesByCategoryId, setSubCategoriesByCategory] = useState([]);

  const [selectedStore, setSelectedStore] = useState(null);
  const [event, setEvent] = useState({ ...DEFAULT_EMPTY_EVENT });

  const [selectedImpact, setSelectedImpact] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedEventSubCategory, setSelectedEventSubCategory] = useState(null);

  const isEdition = !!selectedEvent;

  const close = () => {
    setSelectedCategory(null);

    closeForm();
  };

  const isFormValid = () => {
    if (
      isEmpty(selectedCategory) ||
      isEmpty(selectedSubCategory) ||
      isEmpty(selectedEventSubCategory)
    ) {
      return false;
    }

    if (!event.startDate || !event.endDate) {
      return false;
    }

    if (selectedEventSubCategory.input1Name && !event.input1Value) {
      return false;
    }

    if (selectedEventSubCategory.input2Name && !event.input2Value) {
      return false;
    }

    if (selectedEventSubCategory.input3Name && !event.input3Value) {
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    const payload = {
      endDate: moment(event.endDate).format(),
      startDate: moment(event.startDate).format(),
      eventCategoryId: selectedCategory.id,
      eventSubCategoryId: selectedSubCategory.id,
    };

    if (selectedEventSubCategory.input1Name) {
      payload.input1 = event.input1Value;
    }

    if (selectedEventSubCategory.input2Name) {
      payload.input2 = event.input2Value;
    }

    if (selectedEventSubCategory.input3Name) {
      payload.input3 = event.input3Value;
    }

    pageLoading();

    try {
      await eventService.patchEvent(
        isEdition ? selectedEvent.id : null,
        user.clientId,
        isEdition
          ? selectedEvent.storeId // keep store id when editing event
          : selectedCategory.id === NATIONAL_EVENTS_CATEGORY_ID // national event does not required store id
          ? null
          : storeId,
        selectedCategory.id,
        selectedSubCategory.id,
        payload.startDate,
        payload.endDate,
        payload.input1,
        payload.input2,
        payload.input3,
        selectedImpact ? selectedImpact.id : 'neutral',
      );

      loadStoreEvents();

      showSuccessMessage(
        i18next.t(
          isEdition
            ? 'FORECAST.TURNOVER.EVENTS_EDITION_SUCCESS'
            : 'FORECAST.TURNOVER.EVENTS_ADDED_SUCCESSFULLY',
        ),
      );

      close();
    } catch (err) {
      showErrorMessage(
        i18next.t(
          isEdition
            ? 'FORECAST.TURNOVER.EVENTS_EDITION_ERROR'
            : 'FORECAST.TURNOVER.EVENTS_CREATION_ERROR',
        ),
      );
    } finally {
      pageLoaded();
    }
  };

  const handleDeleteEventConfirmation = () => {
    const params = {
      colorsheme: 'red',
      component: ConfirmationModal,
      title: i18next.t('FORECAST.TURNOVER.EVENT_DELETE_MODAL_TITLE'),
      text: i18next.t('FORECAST.TURNOVER.EVENT_DELETE_MODAL_CONTENT'),
      handleEventDeleteConfirmation: () => deleteEvent(selectedEvent.id),
    };

    openConfirmationModal(params);
  };

  const deleteEvent = async (eventId) => {
    pageLoading();

    try {
      await eventService.deleteEventById(eventId);

      loadStoreEvents();

      showSuccessMessage(i18next.t('FORECAST.TURNOVER.EVENTS_DELETION_SUCCESS'));

      close();
    } catch (err) {
      showErrorMessage(i18next.t('FORECAST.TURNOVER.EVENTS_DELETION_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const setSelectedTime = (propertyKey, value) => {
    const clonedDate = event[propertyKey].clone();

    const [selectedHour, selectedMinute] = value.split(':');

    clonedDate.hour(selectedHour);
    clonedDate.minute(selectedMinute);

    updateEventForm(propertyKey, clonedDate);
  };

  const updateEventForm = (propertyKey, value) => {
    setEvent({ ...event, [propertyKey]: value });
  };

  const formatEventSubCategories = (eventSubCategories) =>
    eventSubCategories.reduce(
      (result, subCategory) => {
        const {
          id,
          roles,
          nameTranslationKey,
          lnkEventcategoryEventsubcategoryrel: {
            id: categoryId,
            translationKey: categoryTranslationKey,
          },
        } = subCategory;

        if (isEmpty(roles)) {
          return result;
        }

        const formattedRoles = JSON.parse(JSON.stringify(roles)) || '';

        if (!formattedRoles.includes(get(user, 'lnkAccountRoleAccountrel.name'))) {
          return result;
        }

        if (!result.subCategoriesByCategoryId[categoryId]) {
          result.subCategoriesByCategoryId[categoryId] = [];

          result.categories.push({
            value: i18next.t(categoryTranslationKey),
            id: categoryId,
          });
        }

        result.subCategoriesByCategoryId[categoryId].push({
          id: id,
          value: i18next.t(nameTranslationKey),
        });

        return result;
      },
      {
        categories: [],
        subCategoriesByCategoryId: {},
      },
    );

  const renderCustomFields = () => {
    if (!selectedEventSubCategory) {
      return <Fragment />;
    }

    const { id, input1Name, input1Type, input2Name, input2Type, input3Name, input3Type } =
      selectedEventSubCategory;

    return (
      <Fragment>
        {input1Name && (
          <DetailContainer>
            <Input
              label={`* ${i18next.t(input1Name)}`}
              name="input1Value"
              type={input1Type === 'string' ? 'text' : 'number'}
              value={event.input1Value}
              width={INPUT_WIDTH.EXTRA_LARGE}
              textBig
              useParentHeight
              onChange={(event) => updateEventForm('input1Value', event.target.value)}
            />
          </DetailContainer>
        )}
        {input2Name && (
          <DetailContainer>
            <Input
              label={`* ${i18next.t(input2Name)}`}
              name="input2Value"
              type={input2Type === 'string' ? 'text' : 'number'}
              value={event.input2Value}
              width={INPUT_WIDTH.EXTRA_LARGE}
              textBig
              useParentHeight
              onChange={(event) => updateEventForm('input2Value', event.target.value)}
            />
          </DetailContainer>
        )}
        {input3Name && (
          <DetailContainer>
            <Input
              label={`* ${i18next.t(input3Name)}`}
              name="input3Value"
              type={input3Type === 'string' ? 'text' : 'number'}
              value={event.input3Value}
              width={INPUT_WIDTH.EXTRA_LARGE}
              textBig
              useParentHeight
              onChange={(event) => updateEventForm('input3Value', event.target.value)}
            />
          </DetailContainer>
        )}
        {id === LOCAL_EVENT_ID && (
          <DetailContainer>
            <Dropdown
              height={'fit-content'}
              isRequired={true}
              items={EVENT_IMPACT_DROPDOWN_ITEMS}
              label={i18next.t('FORECAST.TURNOVER.EVENTS_MODAL_FIELD_IMPACT')}
              selectedItem={selectedImpact}
              width={INPUT_WIDTH.EXTRA_LARGE}
              onSelectionChange={setSelectedImpact}
            />
          </DetailContainer>
        )}
        <DetailContainer>
          <Text style={{ marginBottom: '8px' }}>
            <span style={{ color: '#FA393E', marginRight: '2px' }}>*</span>
            {i18next.t('FORECAST.TURNOVER.EVENTS_MODAL_FIELD_START_DATE')}
          </Text>
          <div style={{ display: 'flex', width: '100%', gap: '8px' }}>
            <SingleDatePicker
              customStyle={{ width: '220px' }}
              date={event.startDate}
              timezone={selectedStore ? selectedStore.timezone : 'Europe/Paris'}
              onDateChange={(value) =>
                updateEventForm('startDate', value.clone().hours(0).minutes(0))
              }
            />
            <Dropdown
              isDisabled={!event.startDate}
              isLabelTextSmall={true}
              isRequired={true}
              items={getHourItems()}
              placeholder={i18next.t('ADMIN.TRANSACTIONS.COLUMN_NAME_HOUR')}
              selectedItem={getTimeFromDateMoment(
                event.startDate,
                selectedStore ? selectedStore.timezone : 'Europe/Paris',
              )}
              width={'124px'}
              onSelectionChange={(selectedItem) => setSelectedTime('startDate', selectedItem.value)}
            />
          </div>
        </DetailContainer>
        <DetailContainer>
          <Text style={{ marginBottom: '8px' }}>
            <span style={{ color: '#FA393E', marginRight: '2px' }}>*</span>
            {i18next.t('FORECAST.TURNOVER.EVENTS_MODAL_FIELD_END_DATE')}
          </Text>
          <div style={{ display: 'flex', width: '100%', gap: '8px' }}>
            <SingleDatePicker
              customStyle={{ width: '220px' }}
              date={event.endDate}
              disabled={!event.startDate}
              maxPastDate={event.startDate}
              timezone={selectedStore ? selectedStore.timezone : 'Europe/Paris'}
              onDateChange={(value) =>
                updateEventForm('endDate', value.clone().hours(23).minutes(59))
              }
            />
            <Dropdown
              isDisabled={!event.endDate}
              isLabelTextSmall={true}
              isRequired={true}
              items={
                // Param necessary to handle unselectable time based on selected start date
                getHourItems(
                  event.startDate &&
                    event.startDate.isSame(event.endDate, 'day') &&
                    event.startDate.format('HH:mm', { trim: false }),
                )
              }
              placeholder={i18next.t('ADMIN.TRANSACTIONS.COLUMN_NAME_HOUR')}
              selectedItem={getTimeFromDateMoment(
                event.endDate,
                selectedStore ? selectedStore.timezone : 'Europe/Paris',
              )}
              width={'124px'}
              onSelectionChange={(selectedItem) => setSelectedTime('endDate', selectedItem.value)}
            />
          </div>
        </DetailContainer>
        {isEdition && (
          <DetailContainer>
            <div
              style={{ display: 'flex', gap: '8px', cursor: 'pointer' }}
              onClick={handleDeleteEventConfirmation}
            >
              <img alt="cross-remove-reference" src="/images/inpulse/close-black-small.svg" />
              <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG_BOLD}>
                {i18next.t('FORECAST.TURNOVER.EVENTS_DELETE_ACTION')}
              </Text>
            </div>
          </DetailContainer>
        )}
      </Fragment>
    );
  };

  useEffect(() => {
    if (!selectedEvent) {
      return;
    }

    setEvent({
      impact: selectedEvent.impact,
      endDate: moment(selectedEvent.endDate),
      startDate: moment(selectedEvent.startDate),
      input1Value: selectedEvent.input1,
      input2Value: selectedEvent.input2,
      input3Value: selectedEvent.input3,
    });

    setSelectedCategory(selectedEvent.eventCategory);
    setSelectedSubCategory(selectedEvent.eventSubCategory);
  }, [selectedEvent]);

  // Get current store
  useEffect(() => {
    if (!storeId) {
      return;
    }

    const matchingStore = stores.find(({ id }) => storeId === id);

    if (!matchingStore) {
      return;
    }

    setSelectedStore(matchingStore);
  }, [storeId]);

  // Reset end date when start date has changed
  useEffect(() => {
    if (!event.startDate) {
      return;
    }

    updateEventForm('endDate', event.startDate.clone().hours(23).minutes(59));
  }, [event.startDate]);

  // Format & compute initial data
  useEffect(() => {
    const result = formatEventSubCategories(eventSubCategories);

    setCategories(result.categories);
    setSubCategoriesByCategory(result.subCategoriesByCategoryId);
  }, [eventSubCategories]);

  // Get EventSubCategory from selected sub category
  useEffect(() => {
    if (!selectedSubCategory) {
      setSelectedEventSubCategory(null);

      return;
    }

    const currentSubCategory = eventSubCategories.find(({ id }) => id === selectedSubCategory.id);

    if (!currentSubCategory) {
      return;
    }

    setSelectedEventSubCategory(currentSubCategory);
  }, [selectedSubCategory]);

  return (
    <Container>
      <ContentContainer>
        <TitleContainer>
          {i18next.t(
            isEdition
              ? 'FORECAST.TURNOVER.EVENTS_MODAL_EDIT_THE_EVENT'
              : 'FORECAST.TURNOVER.EVENTS_MODAL_ADD_AN_EVENT',
          )}
        </TitleContainer>

        <Divider />

        <List>
          <DetailContainer>
            <Dropdown
              height={'fit-content'}
              isRequired={true}
              items={categories}
              label={i18next.t('FORECAST.TURNOVER.EVENTS_MODAL_FIELD_CATEGORY')}
              selectedItem={selectedCategory}
              width={INPUT_WIDTH.EXTRA_LARGE}
              onSelectionChange={(selectedItem) => {
                setSelectedCategory(selectedItem);
                setEvent({ ...DEFAULT_EMPTY_EVENT });
                setSelectedSubCategory(null);
              }}
            />
          </DetailContainer>

          <DetailContainer>
            <Dropdown
              height={'fit-content'}
              isDisabled={!selectedCategory}
              isRequired={true}
              items={
                selectedCategory && subCategoriesByCategoryId[selectedCategory.id]
                  ? subCategoriesByCategoryId[selectedCategory.id]
                  : []
              }
              label={i18next.t('FORECAST.TURNOVER.EVENTS_MODAL_FIELD_SUB_CATEGORY')}
              selectedItem={selectedSubCategory}
              width={INPUT_WIDTH.EXTRA_LARGE}
              onSelectionChange={setSelectedSubCategory}
            />
          </DetailContainer>
          {renderCustomFields()}
        </List>
      </ContentContainer>

      <Divider />

      <Footer
        customStyle={{
          'box-shadow': 'none',
          'background-color': 'transparent',
          height: 'calc(100% - calc(100% - (64px + 24px)))',
          padding: '24px 0px',
        }}
      >
        <ButtonsContainer>
          <Button
            buttonCustomStyle={{ justifyContent: 'center', padding: '0px 32px' }}
            color={'inpulse-outline'}
            handleClick={close}
            icon={'/images/inpulse/close-black-small.svg'}
            label={i18next.t('GENERAL.LEAVE')}
            minWidth={168}
          />
          <Button
            buttonCustomStyle={{ justifyContent: 'center', padding: '0px 32px' }}
            color={'inpulse-default'}
            handleClick={handleSave}
            icon={'/images/inpulse/save-white-small.svg'}
            isDisabled={!isFormValid()}
            label={i18next.t('GENERAL.SAVE')}
            minWidth={168}
          />
        </ButtonsContainer>
      </Footer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  stores: state.baseReducer.activeStores,
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  openConfirmationModal: (params) => {
    dispatch(openConfirmationModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EventForm);
