import storeService from '../services/store';

export const recieveEventSubCategories = (eventSubCategories) => ({
  type: 'EVENT_SUB_CATEGORY_SUCCESS',
  eventSubCategories,
});

export const requestEventSubCategoriesError = (error) => ({
  type: 'EVENT_SUB_CATEGORY_FAILURE',
  error,
});

export const getEventSubCategories = (storeId) =>
  function (dispatch) {
    dispatch({
      type: 'EVENT_SUB_CATEGORY_REQUEST',
    });
    return storeService.getEventSubCategories(storeId);
  };
