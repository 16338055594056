import styled from 'styled-components';

export const RowContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 0 16px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  background-color: ${(props) => props.theme.colors.greys.lightest};
`;

export const Item = styled.div`
  flex-direction: column;
  flex-basis: ${(props) =>
    props.large ? `calc(${(100 / props.nbColumns) * 2}%)` : `calc(${100 / props.nbColumns}%)`};
  margin-right: 16px;
  font: ${(props) => props.theme.fonts?.textBigHeight16};
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const BoxImg = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: url(${(props) => props.img}) no-repeat center;
  background-size: contain;
  cursor: ${(props) => (props.pointerCursor ? 'pointer' : 'default')};
`;
export const NameContent = styled.div`
  margin-left: 8px;
`;

export const RowTotalContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
