import { get, orderBy, sortBy } from 'lodash';

/**
 * Sort an array of objects in alphabetical order on a given property.
 *
 * @param {array} 	array    	Array of objects to sort.
 * @param {string} 	property  	Property to sort on.
 *
 * @return {Array} Array of objects.
 */

export const sortArrayOfObjectsAlphabetically = (array, ...properties) =>
  sortBy(array, ...properties);

/**
 * Order an array of object with multiple iteratees to sort by and multiple sort orders
 *
 * @param {array} data                    - An array of object
 * @param {string[]} orderProperties      - An array of iteratees to sort by
 * @param {string[]} orderType            - An array of sort orders of iteratees ('asc' | 'desc')
 */
export const multipleOrderByWithNullsLast = (data, orderProperties, orderTypes) => {
  if (orderProperties.length !== orderTypes.length) {
    return data;
  }

  const { nonNullValues, nullValues } = data.reduce(
    (acc, item) => {
      if (orderProperties.some((property) => get(item, property, null) === null)) {
        acc.nullValues.push(item);
        return acc;
      }

      acc.nonNullValues.push(item);
      return acc;
    },
    { nullValues: [], nonNullValues: [] },
  );

  const sortedNonNullValues = orderBy(nonNullValues, orderProperties, orderTypes);

  const sortedNullValues = orderBy(nullValues, orderProperties, orderTypes);

  return [...sortedNonNullValues, ...sortedNullValues];
};
