import i18next from 'i18next';

import { brand as brandService } from '@services/brand';
import rushOrderService from '@services/rushOrders';

const getRushOrders = async (props) => {
  const { storeIds, rushSuppliers = null, showErrorMessage } = props;
  try {
    const rushOrders = await rushOrderService.getRushOrdersByStoreIds(storeIds, rushSuppliers);

    return rushOrders;
  } catch (error) {
    showErrorMessage(i18next.t('ORDERS.RUSH_ORDERS.FETCH_LIST_ERROR'));

    return [];
  }
};

const getRushSuppliers = async (props) => {
  const { clientId, showErrorMessage } = props;

  try {
    const fetchedSuppliers = await rushOrderService.getRushSuppliers(clientId);

    return fetchedSuppliers.map((rushSupplier) => ({
      id: rushSupplier,
      name: rushSupplier,
    }));
  } catch (error) {
    showErrorMessage(i18next.t('ORDERS.RUSH_ORDERS.FETCH_SUPPLIER_ERROR'));

    return [];
  }
};

const getBrandsOfClient = async (props) => {
  const { clientId, showErrorMessage } = props;

  try {
    return await brandService.getBrandsOfClient(clientId);
  } catch (error) {
    showErrorMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_BRANDS_FAILURE'));

    return [];
  }
};

export default { getRushOrders, getRushSuppliers, getBrandsOfClient };
