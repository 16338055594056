import React from 'react';

import { getTheme } from '@commons/utils/theme';
import { numberToFixed } from '@commons/utils/format';

import {
  Container,
  WeatherContainer,
  WeatherIcon,
  WeatherSeparator,
  WeatherTempMax,
  WeatherTempMin,
} from './styledComponents';

const WeatherLabel = (props) => {
  const { weather } = props;
  if (!weather) {
    return;
  }

  const theme = getTheme();

  return (
    <Container>
      <WeatherContainer>
        <WeatherIcon alt="weatherIcon" src={weather.img} />

        <WeatherTempMax theme={theme}>{numberToFixed(weather.tMax, 0, '-')}</WeatherTempMax>
        <WeatherSeparator theme={theme} />
        <WeatherTempMin theme={theme}>{numberToFixed(weather.tMin, 0, '-')}</WeatherTempMin>
      </WeatherContainer>
    </Container>
  );
};

export default WeatherLabel;
