import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import React from 'react';

import utilsMethods from '@admin/products/recipes/detail/utils';

import {
  ContainerListComposition,
  ListHeader,
  ListHeaderSection,
  ListRow,
  ListRowSection,
  ListRowText,
} from './styledComponents';

const getHeaderColumns = () => [
  {
    name: i18next.t('GENERAL.NAME'),
    className: 'name',
    renderValue: ({ item }, { history }) => {
      let url = '';

      if (item.isKitchen) {
        url = item.isProduct
          ? `/admin/kitchen-products/products/${item.id}/details`
          : `/admin/kitchen-products/recipes/${item.id}/details`;
      } else {
        url = item.isProduct
          ? `/admin/products/${item.id}/details`
          : `/admin/products/recipes/${item.id}/details`;
      }

      return (
        <ListRowText
          className="link"
          flex
          onClick={() => {
            window.open(url, '_blank');
          }}
        >
          <a style={{ cursor: 'pointer', color: 'inherit' }}>{item.name}</a>
        </ListRowText>
      );
    },
  },
  {
    name: i18next.t('ADMIN.INGREDIENTS.PARENT_ASSOCIATION_COLUMN_ELEMENT_TYPE'),
    renderValue: ({ item }) => (
      <ListRowText>
        {item.isProduct
          ? i18next.t(
              item.isKitchen ? 'ADMIN.INGREDIENTS.CENTRAL_PRODUCT_LABEL' : 'GENERAL.PRODUCT',
            )
          : i18next.t(item.isKitchen ? 'ADMIN.INGREDIENTS.CENTRAL_RECIPE_LABEL' : 'GENERAL.RECIPE')}
      </ListRowText>
    ),
  },
  {
    name: i18next.t('GENERAL.CATEGORY'),
    renderValue: ({ item }) => <ListRowText>{item.category || '-'}</ListRowText>,
  },
  {
    name: i18next.t('ADMIN.INGREDIENTS.PARENT_ASSOCIATION_COLUMN_QUANTITY_USED'),
    renderValue: ({ item }, props) => (
      <ListRowText>{`${item.quantity} ${utilsMethods.getUnit(
        props.recipe.unit,
        item.quantity,
      )}`}</ListRowText>
    ),
  },
  {
    name: i18next.t('GENERAL.STATUS'),
    renderValue: ({ item }) => (
      <ListRowText>
        {item.active ? i18next.t('GENERAL.ACTIVE') : i18next.t('GENERAL.INACTIVE')}
      </ListRowText>
    ),
  },
];

/*************************/
/* Input Change Handlers */
/*************************/

export function ParentsCompositionList(props) {
  const { entitiesMapped } = props;

  const headers = getHeaderColumns();

  const history = useHistory();

  return (
    <ContainerListComposition>
      <ListHeader>
        {headers.map(({ className, name }, indexHeader) => (
          <ListHeaderSection className={className} key={indexHeader}>
            {name}
          </ListHeaderSection>
        ))}
      </ListHeader>
      {entitiesMapped.map((item, indexComposition) => (
        <ListRow active={item.active} key={indexComposition}>
          {headers.map(({ className, renderValue }, indexItem) => (
            <ListRowSection className={className} key={indexItem}>
              {renderValue({ item, index: indexComposition }, { ...props, history })}
            </ListRowSection>
          ))}
        </ListRow>
      ))}
    </ContainerListComposition>
  );
}

export default ParentsCompositionList;
