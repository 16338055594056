import moment from 'moment';
import React from 'react';

import CircleTodayDay from './components/CircleTodayDay';

export const renderCustomArrowIcon = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <img alt="arrow" src={'/images/icon-arrow-date-picker-inpulse.svg'} />
  </div>
);

export const renderCustomNavIconLeft = () => (
  <div
    className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5"
    role="button"
    tabIndex="0"
  >
    <img
      src="/images/inpulse/icon-nav-arrow.svg"
      onMouseOut={(e) => (e.currentTarget.src = '/images/inpulse/icon-nav-arrow.svg')}
      onMouseOver={(e) => (e.currentTarget.src = '/images/inpulse/icon-nav-arrow-hover.svg')}
    />
  </div>
);

export const renderCustomNavIconRight = () => (
  <div
    className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5"
    role="button"
    style={{ transform: 'rotate(180deg)' }}
    tabIndex="0"
  >
    <img
      src="/images/inpulse/icon-nav-arrow.svg"
      onMouseOut={(e) => (e.currentTarget.src = '/images/inpulse/icon-nav-arrow.svg')}
      onMouseOver={(e) => (e.currentTarget.src = '/images/inpulse/icon-nav-arrow-hover.svg')}
    />
  </div>
);

export const renderTodayDateCircle = (item, selectedDates) => {
  const isToday = moment().format('YYYY-MM-DD') === moment(item).format('YYYY-MM-DD');
  let isTodaySelected = false;
  if (selectedDates) {
    isTodaySelected = selectedDates
      .map((date) => moment().format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))
      .some((v) => v);
  }

  return <CircleTodayDay isToday={isToday && !isTodaySelected} item={item} />;
};
