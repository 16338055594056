/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${(props) => (!props.noSize ? props.theme.size : '')};
  height: ${(props) => (!props.noSize ? props.theme.size : '')};

  border-radius: 50%;

  margin: ${(props) => props.margin || ''};

  ${(props) => {
    if (props.isDisabled || props.isDisabledLight) {
      return css`
        &:hover {
          cursor: not-allowed;
        }
      `;
    }

    return css`
      &:hover {
        cursor: pointer;
        background-color: ${props.theme?.hover.backgroundColor};
      }
    `;
  }};
`;

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) =>
    props.isValidation
      ? props.theme.checkedValidation.backgroundColor
      : props.isChecked && props.isDisabled
      ? props.theme?.checkDisabled.backgroundColor
      : props.isChecked
      ? props.theme?.checked.backgroundColor
      : props.isDisabled
      ? props.theme?.disabled.backgroundColor
      : props.theme?.default.backgroundColor};

  ${(props) =>
    props.shape === 'round' &&
    css`
      width: 12px;
      height: 12px;
      border: 1px solid #b6bbc6;
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.3);
      border-radius: 15px;
    `}

  ${(props) =>
    props.shape === 'square' &&
    css`
      width: 16px;
      height: 16px;
      box-sizing: border-box;
      border-radius: ${props.theme?.borderRadius};

      background: ${props.isValidation
        ? props.theme.checkedValidation.backgroundColor
        : props.isChecked && props.isDisabled
        ? props.theme?.checkDisabled.backgroundColor
        : props.isChecked
        ? props.theme?.checked.backgroundColor
        : props.isDisabledLight
        ? props.theme?.default.backgroundColor
        : props.isDisabled
        ? props.theme?.disabled.backgroundColor
        : props.theme?.default.backgroundColor};

      border: ${!props.isDisableBorder ? '1px solid' : ''};
      border-color: ${props.isChecked && props.isDisabled
        ? props.theme?.checkDisabled.borderColor
        : props.isChecked && !props.isDisableBorder
        ? props.theme?.checked.borderColor
        : props.isDisabledLight
        ? props.theme.disabled.backgroundColor
        : props.isDisabled
        ? props.theme?.disabled.borderColor
        : props.theme?.default.borderColor};
    `}
`;

export const IconCheckedBox = styled.img`
  width: ${(props) => props.theme?.icon.witdh};
  font-weight: 400;
`;

export default {
  IconCheckedBox,
  Container,
  ContainerIcon,
};
