import './Checkbox.css';
import React from 'react';

class Checkbox extends React.PureComponent {
  render() {
    let { isChecked, handleClick, style, type } = this.props;

    if (type === 'inpulse') {
      return (
        <div
          className={isChecked ? 'inpulse-checkbox checked' : 'inpulse-checkbox'}
          onClick={handleClick}
        >
          {(style === 'square' || style === 'roundsquare') && isChecked && (
            <img alt="selecionné" src="/images/check-white.svg" />
          )}
        </div>
      );
    }

    return (
      <div
        className={
          isChecked ? 'deepsight-checkbox-' + style + ' checked' : 'deepsight-checkbox-' + style
        }
        onClick={handleClick}
      >
        {(style === 'square' || style === 'roundsquare') && isChecked && (
          <img alt="selecionné" src="/images/checkbox-checked-image.png" />
        )}
      </div>
    );
  }
}

export default Checkbox;
