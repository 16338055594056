import { first } from 'lodash';
import i18next from 'i18next';
import React, { useRef } from 'react';

import { Text, TextContainer, Container, InputFile, FileSelectorWapper } from './styledComponents';

const ImportDataModelModal = (props) => {
  const {
    subTitle,
    validatedFile,
    unValidatedLines = [],
    selectedFile,
    setSelectedFile,
    customContent,
    additionalText,
    exportXLS,
  } = props;

  const inputFileRef = useRef(null);

  const onFileChange = (e) => {
    const file = first(e.target.files);

    if (!file) {
      return;
    }

    setSelectedFile(file);
  };

  const onFileDelete = () => {
    setSelectedFile(null);
  };

  const triggerInputClick = () => {
    inputFileRef.current.click();
  };

  const renderFileName = (noMargin = false) => (
    <FileSelectorWapper noMargin={noMargin}>
      <Text grey underline>
        {selectedFile.name} ({Math.round(selectedFile.size / 1000)} Ko)
      </Text>
      {!!setSelectedFile && (
        <img
          alt="close-icon"
          src={'/images/inpulse/close-dgrey-small.svg'}
          style={{ cursor: 'pointer', width: '9px', height: '9px', marginLeft: '12px' }}
          onClick={onFileDelete}
        />
      )}
    </FileSelectorWapper>
  );

  if (customContent) {
    return customContent;
  }

  if (!setSelectedFile && !validatedFile) {
    // Error case
    return (
      <Container>
        {renderFileName(true)}
        <Text style={{ marginTop: '24px' }} bold red>
          {subTitle}
        </Text>
        {additionalText && (
          <Text style={{ marginTop: '24px' }} grey height24>
            {additionalText}
          </Text>
        )}
        {exportXLS && (
          <TextContainer style={{ marginTop: '24px' }} onClick={() => exportXLS()}>
            <img alt="download-icon" src="/images/inpulse/file-download-black-small.svg" />
            <Text style={{ marginLeft: '8px' }} bold>
              {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_UNVALIDATED_LINES_ACTION')}
            </Text>
          </TextContainer>
        )}
      </Container>
    );
  }

  if (!setSelectedFile && validatedFile && unValidatedLines.length === 0) {
    // Success case
    return (
      <Container>
        {renderFileName(true)}
        <Text style={{ marginTop: '24px' }} bold green>
          {i18next.t('BACKOFFICE.USERS.MODAL_IMPORT_SELECT_FILE_CONTENT_VALIDATED')}
        </Text>
      </Container>
    );
  }

  if (!(unValidatedLines.length === 0) && validatedFile) {
    return (
      <Container>
        {renderFileName(true)}
        <Text style={{ marginTop: '24px' }} bold orange>
          {i18next.t(
            'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_PRICE_UNVALIDATED_LINES_TITLE',
            {
              nbLines: unValidatedLines.length,
            },
          )}
        </Text>
        <Text style={{ marginTop: '24px' }} grey height24>
          {subTitle}
        </Text>
        <Text style={{ marginTop: '24px' }}>
          {i18next.t(
            'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_PRICE_UNVALIDATED_LINES_SUBCONTENT',
          )}
        </Text>
        <TextContainer style={{ marginTop: '24px' }} onClick={() => exportXLS()}>
          <img alt="download-icon" src="/images/inpulse/file-download-black-small.svg" />
          <Text style={{ marginLeft: '8px' }} bold>
            {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_UNVALIDATED_LINES_ACTION')}
          </Text>
        </TextContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Text>{subTitle}</Text>
      {!selectedFile && (
        <>
          <InputFile
            accept=".xlsx"
            id="file-data-model"
            ref={inputFileRef}
            type="file"
            onChange={onFileChange}
          />
          <FileSelectorWapper interactive onClick={triggerInputClick}>
            <img alt="add-icon" src={'/images/inpulse/add-black-small.svg'} />
            {i18next.t('GENERAL.SELECT_FILE')}
          </FileSelectorWapper>
        </>
      )}
      {selectedFile && renderFileName()}
    </Container>
  );
};

export default ImportDataModelModal;
