import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { useState, useEffect, Fragment } from 'react';

import SortArrowActive from '@assets/icons/icon-sort-active.svg';
import SortArrowInactive from '@assets/icons/icon-sort.svg';

import { Tooltip } from '@commons/utils/styledLibraryComponents';

import useLocalStorage from '@hooks/useLocalStorage';

import { getAuthorizedActions } from '@selectors/featureProps';

import { period } from '@productions/constants/period';
import { planningType as PlanningTypeEnum } from '@productions/constants/planning';

import {
  Container,
  ContainerInfoBoxes,
  ContainerPlanning,
  PlanningHeader,
  Row,
  Section,
  HeaderTitle,
  IconSort,
} from './styledComponents';
import { ENUM_ACTIVE_PRODUCT_CASE } from './constants/enums';
import {
  INPUT_REFERENCE,
  INPUT_TO_PRODUCE,
  INPUT_PRODUCED,
  INPUT_LEFT,
  INPUT_PRICE_TTC,
  INPUT_LOSS,
  INPUT_STOCK,
  INPUT_TOTAL,
} from './constants/inputs';
import EmptyState from './components/EmptyState';
import InfoBox from './components/InfoBox';
import OfflineInfoBox from './components/OfflineInfoBox';

const tooltipTexts = {
  left: 'PRODUCTION.PRODUCTION.TOOLTIP_LEFT_TO_PRODUCE',
};

const MAXIMUM_DAYS_OFFLINE_ALLOWED = 5;

/*********************/
/** Utils Methods **/
/*********************/

function isOfflineLimitReached(isUserOffline, userOfflineSince) {
  const currentDate = moment();

  return (
    isUserOffline &&
    moment(userOfflineSince)
      .add(MAXIMUM_DAYS_OFFLINE_ALLOWED, 'day')
      .isSameOrBefore(currentDate, 'day')
  );
}

/*********************/
/** Handler Methods **/
/*********************/

export function handleChangedValue(value, activeProduct, setActiveProduct) {
  const updatedValue = value ? parseInt(value.substring(0, 3)) : '';

  setActiveProduct({
    ...activeProduct,
    value: updatedValue,
  });
}

/********************/
/** Render Methods **/
/********************/

export function renderInventoryProducts(props) {
  const {
    headers,
    planningType,
    activeProduct,
    setActiveProduct,
    lossByProduct,
    stockByProduct,
    products,
    productError,
    productSuccess,
  } = props;

  return (
    <>
      {products &&
        products.map((product, index) => {
          const rowClassName =
            productError === product.productId
              ? 'error'
              : productSuccess === product.productId
              ? 'success'
              : '';

          const cost =
            Math.round(
              product.price *
                (planningType === ENUM_ACTIVE_PRODUCT_CASE.LOSS ? lossByProduct : stockByProduct)[
                  product.productId
                ][planningType] *
                100,
            ) / 100;

          const isProductActive = activeProduct.id === product.productId;

          return (
            <Row className={rowClassName} key={product.name + index}>
              {headers.map(({ render }, indexHeader) => (
                <Section key={indexHeader}>
                  {render({
                    ...props,
                    cost,
                    index,
                    product,
                    isProductActive,
                    setActiveProduct,
                  })}
                </Section>
              ))}
            </Row>
          );
        })}
    </>
  );
}

/**
 * Render the list of products associated to a store according to the use case on which the planning is being displayed
 *
 * @param {Props} props - The props linked to the component
 */
export function renderProductionProducts(props) {
  const {
    headers,
    useCase,
    products,
    dataByRange,
    productError,
    productSuccess,
    activeProduct,
    setActiveProduct,
    handleProducedSave,
    handleToProduceSave,
    reportToProduceFromPreviousRanges,
  } = props;

  return (
    <>
      {products &&
        products.map((product, index) => {
          const rowClassName =
            productError === product.productId
              ? 'error'
              : productSuccess === product.productId
              ? 'success'
              : '';

          const productDataByRange = dataByRange[product.productId];

          const toProduce = _.get(productDataByRange, 'recommendation', 0);

          const toProduceRecoDeepsight = _.get(productDataByRange, 'recommendation-deepsight', 0);

          const production = _.get(productDataByRange, 'production', 0);
          const leftToProduce = _.get(productDataByRange, 'leftToProduce', 0);

          const remainingFromPreviousRanges = _.get(
            productDataByRange,
            'remainingFromPreviousRanges',
            0,
          );

          const isProductActive = activeProduct.id === product.productId;

          return (
            <Row className={rowClassName} key={product.name + index}>
              {headers.map(({ render }, indexHeader) => (
                <Section key={indexHeader}>
                  {render({
                    ...props,
                    index,
                    product,
                    useCase,
                    production,
                    leftToProduce,
                    toProduce,
                    isProductActive,
                    setActiveProduct,
                    handleProducedSave,
                    handleToProduceSave,
                    toProduceRecoDeepsight,
                    remainingFromPreviousRanges,
                    reportToProduceFromPreviousRanges,
                  })}
                </Section>
              ))}
            </Row>
          );
        })}
    </>
  );
}

/**
 * Render the list of products by categories associated to a store
 * according to the use case on which the planning is being displayed
 *
 * @param {Props} props - The props linked to the component
 */
export function renderProductByCategory(props) {
  const {
    headers,
    productsByCategory,
    planningType,
    sortProperties,
    handleSort,
    reportToProduceFromPreviousRanges,
  } = props;

  const productCategories = Object.keys(productsByCategory);

  return (
    <>
      {productCategories.map((category, indexCategory) => {
        const customHeaders = [{ class: 'category', name: category }].concat(headers.slice(1));
        return (
          <div key={indexCategory}>
            <PlanningHeader className={'header-category'}>
              {customHeaders.map((header, indexHeader) => {
                const isColumnSortActive =
                  sortProperties.category === category && sortProperties.name === header.class;

                return (
                  <Fragment key={indexHeader}>
                    <HeaderTitle className={header.class}>
                      <div
                        className={`${header.class} ${isColumnSortActive ? 'active' : ''}`}
                        onClick={() => {
                          handleSort && handleSort(header.class, category);
                        }}
                      >
                        {header.class === 'category'
                          ? header.name.toUpperCase()
                          : i18next.t(header.name)}
                      </div>

                      {sortProperties && (
                        <IconSort
                          alt="sort"
                          rotated={isColumnSortActive && sortProperties.order === 'desc'}
                          src={isColumnSortActive ? SortArrowActive : SortArrowInactive}
                        />
                      )}
                      {reportToProduceFromPreviousRanges && header.class === 'left' && (
                        <Tooltip text={i18next.t(tooltipTexts['left'])} />
                      )}
                    </HeaderTitle>
                  </Fragment>
                );
              })}
            </PlanningHeader>
            <ContainerPlanning className={'listing-category'}>
              {planningType === PlanningTypeEnum.PRODUCTION &&
                renderProductionProducts({
                  ...props,
                  products: productsByCategory[category],
                })}
              {(planningType === PlanningTypeEnum.LOSSES ||
                planningType === PlanningTypeEnum.STOCKS) &&
                renderInventoryProducts({
                  ...props,
                  products: productsByCategory[category],
                })}
            </ContainerPlanning>
          </div>
        );
      })}
    </>
  );
}

/**
 * Render the list of products by reference associated to a store
 * according to the use case on which the planning is being displayed
 *
 * @param {Props} props - The props linked to the component
 */
export function renderProductByReference(props) {
  const { planningType } = props;

  return (
    <ContainerPlanning className={'listing-reference'}>
      {planningType === PlanningTypeEnum.PRODUCTION && renderProductionProducts(props)}
      {(planningType === PlanningTypeEnum.LOSSES || planningType === PlanningTypeEnum.STOCKS) &&
        renderInventoryProducts(props)}
    </ContainerPlanning>
  );
}

/**
 * Render the content of the production planning
 *
 * @param {Props} props - The props linked to the component
 */
export function renderContent(props) {
  const {
    headers,
    displayProductsByCategory,
    reportToProduceFromPreviousRanges,
    handleSort,
    sortProperties,
  } = props;

  return (
    <div>
      {!displayProductsByCategory && (
        <PlanningHeader>
          {headers.map((header, index) => {
            const isColumnSortActive = sortProperties.name === header.class;

            return (
              <Fragment key={index}>
                <HeaderTitle className={header.class} key={index}>
                  <div
                    className={`${!handleSort ? 'disabled' : ''} ${
                      isColumnSortActive ? 'active' : ''
                    }`}
                    onClick={() => {
                      if (!handleSort) {
                        return;
                      }

                      handleSort(header.class, '');
                    }}
                  >
                    {i18next.t(header.name)}
                  </div>
                  {sortProperties && (
                    <IconSort
                      alt="sort"
                      rotated={isColumnSortActive && sortProperties.order === 'desc'}
                      src={isColumnSortActive ? SortArrowActive : SortArrowInactive}
                    />
                  )}
                  {reportToProduceFromPreviousRanges && header.class === 'left' && (
                    <Tooltip text={i18next.t(tooltipTexts['left'])} />
                  )}
                </HeaderTitle>
              </Fragment>
            );
          })}
        </PlanningHeader>
      )}
      <ContainerPlanning>
        {!displayProductsByCategory && renderProductByReference(props)}
        {displayProductsByCategory && renderProductByCategory(props)}
      </ContainerPlanning>
    </div>
  );
}

export const PlanningListing = (props) => {
  const { useCase, timeSlots, planningDate, planningType, selectedTimeSlot, isUserOffline } = props;

  const [headers, setHeaders] = useState([]);

  const [userOfflineSince] = useLocalStorage('userOfflineSince', null);

  // Set custom headers from the planning type
  useEffect(() => {
    let customHeaders = [];

    if (planningType === PlanningTypeEnum.PRODUCTION) {
      customHeaders = [INPUT_REFERENCE, INPUT_TO_PRODUCE, INPUT_PRODUCED, INPUT_LEFT];
    }

    if (planningType === PlanningTypeEnum.LOSSES) {
      customHeaders = [INPUT_REFERENCE, INPUT_PRICE_TTC, INPUT_LOSS, INPUT_TOTAL];
    }

    if (planningType === PlanningTypeEnum.STOCKS) {
      customHeaders = [INPUT_REFERENCE, INPUT_PRICE_TTC, INPUT_STOCK, INPUT_TOTAL];
    }

    setHeaders(customHeaders);
  }, [planningType]);

  if (useCase === period.CLOSED) {
    return <EmptyState planningType={planningType} />;
  }

  if (!headers.length) {
    return <div></div>;
  }

  const offlineLimitReached = isOfflineLimitReached(isUserOffline, userOfflineSince);

  return (
    <Container>
      <ContainerInfoBoxes>
        {isUserOffline && (
          <OfflineInfoBox
            maximumDaysOffline={MAXIMUM_DAYS_OFFLINE_ALLOWED}
            offlineLimitReached={offlineLimitReached}
          />
        )}
        <InfoBox
          planningDate={planningDate}
          selectedTimeSlot={selectedTimeSlot}
          timeSlots={timeSlots}
          useCase={useCase}
        />
      </ContainerInfoBoxes>
      {renderContent({ ...props, headers, offlineLimitReached })}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  authorizedActions: [
    ...getAuthorizedActions(state.baseReducer.userRights, '/production/operations'),
    ...getAuthorizedActions(state.baseReducer.userRights, '/production/losses'),
    ...getAuthorizedActions(state.baseReducer.userRights, '/production/stocks'),
  ],
});

export default connect(mapStateToProps)(PlanningListing);
