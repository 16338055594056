/**
 * The administration pages for Supplier Products and Recipes include dropdowns for
 * setting a Category, Sub-Category, a list of Storage Areas or Inventory List Templates.
 * A common pattern between those is allowing to create on-the-fly new values.
 * The following constants are used to configure with a bit less hassle the modal properties:
 *  - title
 *  - input label
 *  - closing confirmation text
 *  - warning for already existing value
 *
 * See SupplierProductDetails/index.js for an example
 */

export const DYNAMIC_PROPERTIES = {
  CATEGORY: 'category',
  SUB_CATEGORY: 'subCategory',
  STORAGE_AREAS: 'storageAreas',
  INVENTORY_LIST_TEMPLATES: 'inventoryListTemplates',
};

export const DYNAMIC_MODAL_TITLE = {
  [DYNAMIC_PROPERTIES.CATEGORY]: 'GENERAL.LIST_CREATE_CATEGORY',
  [DYNAMIC_PROPERTIES.SUB_CATEGORY]: 'GENERAL.LIST_CREATE_SUBCATEGORY',
  [DYNAMIC_PROPERTIES.STORAGE_AREAS]: 'GENERAL.LIST_CREATE_STORAGE_AREA',
  [DYNAMIC_PROPERTIES.INVENTORY_LIST_TEMPLATES]: 'GENERAL.LIST_CREATE_INVENTORY_TEMPLATE',
};

export const DYNAMIC_MODAL_INPUT_LABEL = {
  [DYNAMIC_PROPERTIES.CATEGORY]: 'GENERAL.MODAL_CATEGORY_NAME',
  [DYNAMIC_PROPERTIES.SUB_CATEGORY]: 'GENERAL.MODAL_SUBCATEGORY_NAME',
  [DYNAMIC_PROPERTIES.STORAGE_AREAS]: 'GENERAL.MODAL_STORAGE_AREA_NAME',
  [DYNAMIC_PROPERTIES.INVENTORY_LIST_TEMPLATES]: 'GENERAL.MODAL_INVENTORY_TEMPLATE_NAME',
};
export const DYNAMIC_MODAL_CLOSE_CONFIRMATION = {
  [DYNAMIC_PROPERTIES.CATEGORY]: 'GENERAL.MODAL_CATEGORIES_CLOSE_CONFIRMATION_CONTENT',
  [DYNAMIC_PROPERTIES.SUB_CATEGORY]: 'GENERAL.MODAL_CATEGORIES_CLOSE_CONFIRMATION_CONTENT',
  [DYNAMIC_PROPERTIES.STORAGE_AREAS]: 'GENERAL.MODAL_STORAGE_AREA_CLOSE_CONFIRMATION',
  [DYNAMIC_PROPERTIES.INVENTORY_LIST_TEMPLATES]:
    'GENERAL.MODAL_INVENTORY_TEMPLATE_CLOSE_CONFIRMATION',
};
export const DYNAMIC_MODAL_ALREADY_USED = {
  [DYNAMIC_PROPERTIES.CATEGORY]: 'GENERAL.MODAL_CATEGORY_NAME_ALREADY_USED',
  [DYNAMIC_PROPERTIES.SUB_CATEGORY]: 'GENERAL.MODAL_SUBCATEGORY_NAME_ALREADY_USED',
  [DYNAMIC_PROPERTIES.STORAGE_AREAS]: 'GENERAL.MODAL_STORAGE_AREA_NAME_ALREADY_USED',
  [DYNAMIC_PROPERTIES.INVENTORY_LIST_TEMPLATES]: 'GENERAL.MODAL_INVENTORY_TEMPLATE_ALREADY_USED',
};
