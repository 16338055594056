import { connect } from 'react-redux';
import { isEmpty, round } from 'lodash';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  Dropdown,
  HoverTooltip,
  INPUT_WIDTH,
  InputIncrement,
} from '@commons/utils/styledLibraryComponents';
import {
  computeTypesOfPackaging,
  convertPriceToPackagingById,
  convertQuantityToPackagingById,
} from '@commons/utils/packagings';
import { DisabledTooltip } from '@commons/DisabledTooltip';
import { formatTextNumberInputValue, sortPackagings } from '@commons/utils/format';
import { getIsCentralMode } from '@commons/utils/localStorage';
import DisplayNumber from '@commons/DisplayNumber';
import Footer from '@commons/Footer/Footer';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { Input } from '@lib/inpulse/Input';

import { canEditSentOrReceivedOrder } from '@selectors/actions/orderActions';
import { getAuthorizedActions } from '@selectors/featureProps';

import { PRODUCT_ORDER_TYPE } from '@orders/OrderList/constants';

import { getTranslatedOrderAnomalies } from '@orders/OrderList/components/OrderForm/constants/anomalies';
import ORDER_STATUS, {
  ORDER_PREPARATION_STATUS,
} from '@orders/OrderList/components/OrderForm/constants/status';

import {
  ButtonsContainer,
  Container,
  ContentContainer,
  DetailContainer,
  DisplayNameHovered,
  Divider,
  ImgAddedReference,
  InlineContainer,
  InputIncrementContainer,
  InputIncrementLabel,
  List,
  Name,
  NameContainer,
  RemoveReference,
  RemoveReferenceContainer,
  Sku,
  DrawerText,
  Thumbnail,
  ThumbnailContainer,
  TitleContainer,
  DropdownContainer,
} from '../styledComponents';

const GENERIC_PRODUCT_INPULSE_ICON = '/images/icon-generic-inpulse-product.svg';
const MAXIMUM_CHARACTER_BEFORE_SHOWING_BIGGER_NAME = 40;
const NUMBER_CHECK_REGEXP = new RegExp(/^\d+($|(\.|\,)(\d+|$))$/);

const OrderReceptionDrawerContent = (props) => {
  const {
    currency,
    handleInputChange,
    handleReceptionInputChange,
    handleReceptionAnomaly,
    hasBLI,
    isEditingPreparationOrder,
    onClose,
    preparationStatus,
    priceBDL,
    product,
    readOnly,
    setPriceBDL,
    orderStatus,
    authorizedActions,
    deleteProductOrder,
    setOpenStockInfo,
  } = props;

  const orderAnomalies = getTranslatedOrderAnomalies(false);
  const isReception = [ORDER_STATUS.SENT, ORDER_STATUS.RECEPTION].includes(orderStatus);

  const isCentralMode = getIsCentralMode();

  const isEditingPreparedOrder = preparationStatus > ORDER_PREPARATION_STATUS.PREPARING;

  const isPOSEditingPreparationOrder = !isCentralMode && preparationStatus !== null;

  const isRemoveAddedReferenceDisabled =
    isEditingPreparedOrder || (preparationStatus != null && !isCentralMode);

  const { packagingUsedInOrder, packagingUsedInInvoice, packagingUsedInReception } =
    computeTypesOfPackaging(product.receptionPackagingId, product.packagings);

  const [displayBigger, setDisplayBigger] = useState(false);
  const [disableAnomalyDropdown, setDisableAnomalyDropdown] = useState(
    orderStatus >= ORDER_STATUS.COMPLIANT,
  );
  const [receptionPackagingData, setReceptionPackagingData] = useState({
    packaging: packagingUsedInReception,
    invoiced: product.invoiced,
    received: product.received,
  });

  const [invoicedPriceData, setInvoicedPriceData] = useState({
    price: convertPriceToPackagingById(
      priceBDL,
      packagingUsedInReception.id,
      packagingUsedInInvoice.id,
      product.packagings,
    ),
    overwriteSupplierProductPrice: product.hasOverwrittenOrderedProductPrice,
  });
  const [anomaly, setAnomaly] = useState({});
  const [isValidationDisabled, setIsValidationDisabled] = useState(false);
  const [errorState, setErrorState] = useState(false);

  useEffect(() => {
    if (isEmpty(anomaly) && !disableAnomalyDropdown && !!errorState) {
      setIsValidationDisabled(true);
      return;
    }

    setIsValidationDisabled(readOnly);
    setErrorState(false);
  }, [anomaly, readOnly, disableAnomalyDropdown]);

  useEffect(() => {
    setReceptionPackagingData({
      ...receptionPackagingData,
      packaging: {
        id: packagingUsedInReception.id,
        value: packagingUsedInReception.name,
      },
    });
  }, [product.receptionPackagingId]);

  useEffect(() => {
    const orderedPriceConvertedInInvoicePackaging = convertPriceToPackagingById(
      product.price,
      packagingUsedInOrder.id,
      packagingUsedInInvoice.id,
      product.packagings,
    );

    const roundedInvoicedPriceData = round(invoicedPriceData.price, 3);
    const roundedConvertedOrderedPrice = round(orderedPriceConvertedInInvoicePackaging, 3);

    if (
      receptionPackagingData.invoiced === receptionPackagingData.received &&
      (roundedInvoicedPriceData === roundedConvertedOrderedPrice ||
        invoicedPriceData.overwriteSupplierProductPrice)
    ) {
      setDisableAnomalyDropdown(true);
      hasSelectedAnomaly({});
      return;
    }

    setDisableAnomalyDropdown(false);
  }, [
    receptionPackagingData.invoiced,
    receptionPackagingData.received,
    invoicedPriceData.price,
    invoicedPriceData.overwriteSupplierProductPrice,
  ]);

  useEffect(() => {
    if (isEmpty(product) && product.receptionAnomaly) {
      return;
    }

    setAnomaly(orderAnomalies.find(({ id }) => id === product.receptionAnomaly));
  }, []);

  const buttonStyle = { padding: '0px 40px' };

  const handleReceptionPackagingChange = (item) => {
    const updatedInvoicedQty = convertQuantityToPackagingById(
      receptionPackagingData.invoiced,
      receptionPackagingData.packaging.id,
      item.id,
      product.packagings,
    );

    const updatedReceivedQty = convertQuantityToPackagingById(
      receptionPackagingData.received,
      receptionPackagingData.packaging.id,
      item.id,
      product.packagings,
    );

    setReceptionPackagingData({
      ...receptionPackagingData,
      packaging: { id: item.id, value: item.name },
      invoiced: Math.round(updatedInvoicedQty * 100) / 100,
      received: Math.round(updatedReceivedQty * 100) / 100,
    });
  };

  const handleReceptionPriceChange = (e) => {
    const value = e.target?.value;

    if (!value || !value.length) {
      setInvoicedPriceData({ ...invoicedPriceData, price: 0 });

      return;
    }

    if (!NUMBER_CHECK_REGEXP.test(value)) {
      return;
    }

    const formattedValue = formatTextNumberInputValue(value, 0);

    setInvoicedPriceData({ ...invoicedPriceData, price: formattedValue });
  };

  const handleValidation = () => {
    if (isEmpty(anomaly) && !disableAnomalyDropdown && !isEditingPreparationOrder) {
      setErrorState(true);
      setIsValidationDisabled(true);
      return;
    }

    handleInputChange(receptionPackagingData.invoiced, PRODUCT_ORDER_TYPE.INVOICED);
    handleInputChange(receptionPackagingData.received, PRODUCT_ORDER_TYPE.RECEIVED);

    handleReceptionInputChange('receptionPackagingId', receptionPackagingData.packaging.id);

    if (isReception && canEditSentOrReceivedOrder(authorizedActions)) {
      handleReceptionInputChange('checked', true);
    }

    const convertedPriceToReceptionPackaging = convertPriceToPackagingById(
      invoicedPriceData.price,
      packagingUsedInInvoice.id,
      receptionPackagingData.packaging.id,
      product.packagings,
    );

    // Convert price to packagingUsedInReception for data consistency
    setPriceBDL(convertedPriceToReceptionPackaging);

    // allows to notify whether PO.price of ordered type must be updated when calling backend
    handleReceptionInputChange(
      'hasOverwrittenOrderedProductPrice',
      invoicedPriceData.overwriteSupplierProductPrice,
    );

    const convertedPriceToOrderPackaging = convertPriceToPackagingById(
      invoicedPriceData.price,
      packagingUsedInInvoice.id,
      packagingUsedInOrder.id,
      product.packagings,
    );

    if (invoicedPriceData.overwriteSupplierProductPrice || product.addedAfterOrdered) {
      handleReceptionInputChange('price', convertedPriceToOrderPackaging);
    }

    onClose();
  };

  const hasSelectedAnomaly = (anomalySelected) => {
    setAnomaly(anomalySelected);
    handleReceptionAnomaly(anomalySelected);
  };

  // trick allowing to display priceBDL instead of priceBDC if checkbox selected
  // even if the user has not yet saved
  const formatPriceBDC = () => {
    if (!product.price) {
      return 0;
    }
    if (product.hasOverwrittenOrderedProductPrice) {
      return product.priceBDL;
    }
    return product.price;
  };

  const handleRemoveAddedReference = () => {
    if (isRemoveAddedReferenceDisabled) {
      return;
    }

    setOpenStockInfo({
      entity: null,
      category: null,
    });
    deleteProductOrder(product.id);
  };

  return (
    <Container>
      <ContentContainer>
        <TitleContainer>
          {i18next.t(
            isEditingPreparationOrder
              ? 'ORDERS.ORDERS.DRAWER_TITLE_PREPARATION'
              : 'ORDERS.ORDERS.DRAWER_TITLE_RECEPTION',
          )}
        </TitleContainer>

        <List>
          <InlineContainer>
            <ThumbnailContainer>
              {product.addedAfterOrdered && <ImgAddedReference />}
              <Thumbnail src={product.img || GENERIC_PRODUCT_INPULSE_ICON} />
            </ThumbnailContainer>
            <NameContainer>
              <Name onMouseOver={() => setDisplayBigger(true)}>{product.name}</Name>
              {product.sku && <Sku>{product.sku}</Sku>}
              {displayBigger &&
                product.name.length > MAXIMUM_CHARACTER_BEFORE_SHOWING_BIGGER_NAME && (
                  <DisplayNameHovered onMouseOut={() => setDisplayBigger(false)}>
                    {product.name}
                  </DisplayNameHovered>
                )}
            </NameContainer>
          </InlineContainer>
          <DetailContainer>
            <DrawerText isLight>
              {i18next.t('ORDERS.ORDERS.FORM_COLUMN_LABEL_ORDERED')} :
            </DrawerText>
            <DrawerText isBold>{`${product.ordered} x ${packagingUsedInOrder.name}`}</DrawerText>
          </DetailContainer>
          <DetailContainer style={{ marginTop: '16px' }}>
            <DrawerText isLight>
              {`${i18next.t('ORDERS.ORDERS.FORM_COLUMN_LABEL_PRICE')} (${currency.alphabeticCode}/${
                packagingUsedInOrder.name
              }) :`}
            </DrawerText>
            <DisplayNumber
              color={ENUM_COLORS.DARKEST}
              displayCurrencyCode={true}
              font={ENUM_FONTS.TEXT_MIDDLE_BOLD}
              number={formatPriceBDC()}
            />
          </DetailContainer>
        </List>

        <Divider />

        <List>
          <DrawerText
            isDisabled={readOnly || isEditingPreparedOrder || isPOSEditingPreparationOrder}
          >
            {i18next.t('GENERAL.PACKAGING')} :
          </DrawerText>
          <DetailContainer style={{ marginTop: '8px' }}>
            <HoverTooltip
              isTooltipDisplayed={isEditingPreparationOrder && orderStatus > ORDER_STATUS.RECEPTION}
              renderContent={() => (
                <DisabledTooltip
                  text={i18next.t('ORDERS.ORDERS.DRAWER_TOOLTIP_ALREADY_RECEIVED_PREPARATION')}
                />
              )}
            >
              <Dropdown
                isDisabled={readOnly || isEditingPreparedOrder || isPOSEditingPreparationOrder}
                items={product.packagings}
                selectedItem={receptionPackagingData.packaging}
                sortBy={sortPackagings}
                width={INPUT_WIDTH.EXTRA_LARGE}
                isRequired
                onSelectionChange={handleReceptionPackagingChange}
              />
            </HoverTooltip>
          </DetailContainer>
          <DetailContainer style={{ gap: '24px' }} inline>
            <InputIncrementContainer>
              <InputIncrementLabel
                isDisabled={
                  readOnly || isEditingPreparedOrder || isPOSEditingPreparationOrder || hasBLI
                }
              >
                {i18next.t('ORDERS.ORDERS.LIST_LABEL_QUANTITY_BDL')} :
              </InputIncrementLabel>
              <HoverTooltip
                isTooltipDisplayed={
                  isEditingPreparationOrder && orderStatus > ORDER_STATUS.RECEPTION
                }
                renderContent={() => (
                  <DisabledTooltip
                    text={i18next.t('ORDERS.ORDERS.DRAWER_TOOLTIP_ALREADY_RECEIVED_PREPARATION')}
                  />
                )}
              >
                <InputIncrement
                  isDisabled={
                    readOnly || isEditingPreparedOrder || isPOSEditingPreparationOrder || hasBLI
                  }
                  limitValues={{ min: 0 }}
                  setValue={(value) =>
                    setReceptionPackagingData({ ...receptionPackagingData, invoiced: value })
                  }
                  value={receptionPackagingData.invoiced}
                  width={'100%'}
                />
              </HoverTooltip>
            </InputIncrementContainer>
            {!isEditingPreparationOrder && (
              <InputIncrementContainer>
                <InputIncrementLabel isDisabled={readOnly}>
                  {i18next.t('ORDERS.ORDERS.LIST_LABEL_QUANTITY_RECEIVED')} :
                </InputIncrementLabel>
                <InputIncrement
                  isDisabled={readOnly}
                  limitValues={{ min: 0 }}
                  setValue={(value) =>
                    setReceptionPackagingData({ ...receptionPackagingData, received: value })
                  }
                  value={receptionPackagingData.received}
                  width={'100%'}
                />
              </InputIncrementContainer>
            )}
          </DetailContainer>
        </List>

        <Divider />

        <List>
          <DrawerText
            isDisabled={
              readOnly || isEditingPreparedOrder || isPOSEditingPreparationOrder || hasBLI
            }
          >{`${i18next.t('ORDERS.ORDERS.LIST_LABEL_PRICE_UNIT_EX_TAX_BDL')} (${
            currency.alphabeticCode
          }/${packagingUsedInInvoice.name}) :`}</DrawerText>
          <DetailContainer style={{ marginTop: '8px' }}>
            <HoverTooltip
              isTooltipDisplayed={isEditingPreparationOrder && orderStatus > ORDER_STATUS.RECEPTION}
              renderContent={() => (
                <DisabledTooltip
                  text={i18next.t('ORDERS.ORDERS.DRAWER_TOOLTIP_ALREADY_RECEIVED_PREPARATION')}
                />
              )}
            >
              <Input
                autoComplete="off"
                name="BDLPrice"
                readOnly={
                  readOnly || isEditingPreparedOrder || isPOSEditingPreparationOrder || hasBLI
                }
                type="text"
                value={invoicedPriceData.price}
                width={INPUT_WIDTH.EXTRA_LARGE}
                textBig
                useParentHeight
                onChange={handleReceptionPriceChange}
              />
            </HoverTooltip>
          </DetailContainer>
          {orderStatus <= ORDER_STATUS.RECEPTION &&
            product.priceEditableAtReception &&
            !isEditingPreparationOrder && (
              <DetailContainer inline>
                <Checkbox
                  handleClick={() =>
                    setInvoicedPriceData({
                      ...invoicedPriceData,
                      overwriteSupplierProductPrice:
                        !invoicedPriceData.overwriteSupplierProductPrice,
                    })
                  }
                  isChecked={invoicedPriceData.overwriteSupplierProductPrice}
                  isDisabled={
                    readOnly ||
                    !packagingUsedInInvoice.isActive ||
                    isEditingPreparedOrder ||
                    isPOSEditingPreparationOrder
                  }
                  shape="square"
                />
                <DrawerText
                  isDisabled={readOnly || isEditingPreparedOrder || isPOSEditingPreparationOrder}
                >
                  {i18next.t('ORDERS.ORDERS.DRAWER_RECEPTION_PRICE_BDL_CHECKBOX_LABEL')}
                </DrawerText>
              </DetailContainer>
            )}
        </List>

        {!isEditingPreparationOrder && (
          <>
            <Divider />
            <List>
              <DropdownContainer>
                <Dropdown
                  isDisabled={disableAnomalyDropdown || readOnly}
                  isErrorState={errorState}
                  items={orderAnomalies}
                  label={`${i18next.t('ORDERS.ORDERS.LIST_LABEL_ANOMALY_REASON')} :`}
                  placeholder={i18next.t('GENERAL.CHOOSE_PLACEHOLDER')}
                  selectedItem={anomaly}
                  width={INPUT_WIDTH.EXTRA_LARGE}
                  isRequired
                  onSelectionChange={(selectedItem) => hasSelectedAnomaly(selectedItem)}
                />
                {errorState && (
                  <Text
                    color={ENUM_COLORS.INFO_RED}
                    font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14_WEIGHT_600}
                  >
                    {i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
                      field: i18next.t('ORDERS.ORDERS.LIST_LABEL_ANOMALY_REASON'),
                    })}
                  </Text>
                )}
              </DropdownContainer>
            </List>
          </>
        )}

        {orderStatus <= ORDER_STATUS.RECEPTION && product.addedAfterOrdered && !hasBLI && (
          <>
            {isEditingPreparationOrder && <Divider />}
            <List>
              <RemoveReferenceContainer
                isDisabled={isRemoveAddedReferenceDisabled}
                onClick={handleRemoveAddedReference}
              >
                <img
                  alt="cross remove reference"
                  src={
                    isRemoveAddedReferenceDisabled
                      ? '/images/inpulse/close-lmgrey-small.svg'
                      : '/images/inpulse/close-black-small.svg'
                  }
                />
                <RemoveReference isDisabled={isRemoveAddedReferenceDisabled}>
                  {i18next.t('ORDERS.ORDERS.FORM_DELETE_REFERENCE_TITLE')}
                </RemoveReference>
              </RemoveReferenceContainer>
            </List>
          </>
        )}
      </ContentContainer>
      <Divider />
      <Footer
        customStyle={{
          'box-shadow': 'none',
          'background-color': 'transparent',
          width: 'auto',
          height: 'calc(100% - calc(100% - (64px + 24px)))',
          padding: '24px 0px',
        }}
      >
        <ButtonsContainer>
          <Button
            buttonCustomStyle={buttonStyle}
            color={'inpulse-outline'}
            handleClick={onClose}
            icon={'/images/inpulse/close-black-small.svg'}
            label={i18next.t(readOnly ? 'GENERAL.CLOSE' : 'GENERAL.CANCEL')}
            minWidth={168}
          />
          {orderStatus <= ORDER_STATUS.RECEPTION && (
            <Button
              buttonCustomStyle={buttonStyle}
              color={'inpulse-default'}
              handleClick={handleValidation}
              icon={'/images/inpulse/check-white-small.svg'}
              isDisabled={isValidationDisabled}
              label={i18next.t('GENERAL.VALIDATE')}
              minWidth={168}
            />
          )}
        </ButtonsContainer>
      </Footer>
    </Container>
  );
};

OrderReceptionDrawerContent.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handleReceptionInputChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  priceBDL: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  setPriceBDL: PropTypes.func.isRequired,
  handleReceptionAnomaly: PropTypes.func.isRequired,
  setOpenStockInfo: PropTypes.func.isRequired,
};

OrderReceptionDrawerContent.defaultProps = {
  readOnly: false,
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/order/orders'),
});

export default connect(mapStateToProps)(OrderReceptionDrawerContent);
