import styled, { css } from 'styled-components';

export const Text = styled.div`
  white-space: pre-wrap;
  word-break: break-word;

  font: ${(props) =>
    props.height24 ? props.theme.fonts?.textBigHeight24 : props.theme.fonts?.textBig};
  color: ${(props) =>
    props.grey ? props.theme.colors?.greys?.darker : props.theme.colors?.greys?.darkest};

  ${(props) => {
    if (props.highlighted) {
      return css`
        font: ${(props) => props.theme.fonts?.textMiddleBold};
        color: ${(props) => props.theme.colors?.greys?.darkest || '#2176FA'};
      `;
    }
  }}
`;

export const UpcomingChangeContainer = styled.div`
  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.colors.greys.darkest};
  border-radius: 10px;
`;

export const CalendarIcon = styled.img`
  width: 8px;
  height: 8px;
`;

export const LabelContainer = styled.div`
  min-width: 115px;
  margin-left: 2px;
  padding: 5px 0px;

  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 16px;

  ${(props) =>
    props.tooltipId &&
    css`
      & .${props.tooltipId} {
        opacity: 1 !important;
        max-width: 220px !important;

        ::before {
          height: 0px !important;
        }
      }
    `}
`;
