import { useState, useEffect } from 'react';

const initBeforeUnLoad = (showReloadPageWarning) => {
  window.onbeforeunload = (event) => {
    if (showReloadPageWarning) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };
};

export default function useReloadPageWarning(bool) {
  const [showReloadPageWarning, setShowReloadPageWarning] = useState(bool);

  window.onload = function () {
    initBeforeUnLoad(showReloadPageWarning);
  };

  useEffect(() => {
    initBeforeUnLoad(showReloadPageWarning);
  }, []);

  useEffect(() => {
    initBeforeUnLoad(showReloadPageWarning);
  }, [showReloadPageWarning]);

  return [showReloadPageWarning, setShowReloadPageWarning];
}
