import i18next from 'i18next';

export const CENTRAL_KITCHEN_PRODUCT_DETAILS = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: () => i18next.t('ADMIN.PRODUCTS.CREATION_INFO'),
  path: '/admin/kitchen-products/products/:id/details',
  getLink: ({ product: { id } }) => `/admin/kitchen-products/products/${id}/details`,
};

export const CENTRAL_KITCHEN_PRODUCT_CREATION = {
  isFullScreen: true,
  isVisible: () => false,
  path: '/admin/kitchen-products/products/create',
};
