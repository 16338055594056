import { get } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { ENTITY_UNITS } from '@commons/constants/units';
import { getTheme } from '@commons/utils/theme';
import { Label } from '@commons/utils/styledLibraryComponents';
import { numberToFixed } from '@commons/utils/format';
import DisplayNumber from '@commons/DisplayNumber';

const theme = getTheme();

const PRICE_EVOLUTION_LIMIT = 10;
const NEGATIVE_PRICE_EVOLUTION_LIMIT = -10;

const renderPriceEvolutionValue = (value) => {
  if (value == null) {
    return '--';
  }

  const formattedValue = value > -0.01 ? Math.abs(value) : value;

  const renderValue = numberToFixed(formattedValue, 2, '-', '%');
  const signedValue = `${value >= 0.01 ? '+' : ''}${renderValue.replace(',', '.')}`;

  if (Math.abs(value) >= PRICE_EVOLUTION_LIMIT) {
    return (
      <Label
        background={
          value <= NEGATIVE_PRICE_EVOLUTION_LIMIT
            ? theme.colors.brand.primaryLight
            : theme.colors.infoLightRed
        }
        children={signedValue}
        color={theme.colors.black}
        type={'plain'}
      />
    );
  }

  return signedValue;
};

const renderPricesColumnWithUnit = (value, item) => {
  const packagingUnit = get(item, 'unit', null);
  const formattedUnit = ENTITY_UNITS.UNIT === packagingUnit ? 'u.' : packagingUnit;

  return (
    <>
      <DisplayNumber number={value} displayCurrencyCode />/{formattedUnit}
    </>
  );
};

export const getListHeaders = () => [
  {
    id: 0,
    name: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
    large: true,
    isSortable: true,
  },
  {
    id: 1,
    name: i18next.t('GENERAL.SUPPLIER'),
    propertyKey: `supplierName`,
    isSortable: true,
  },
  {
    id: 2,
    name: i18next.t('GENERAL.INGREDIENT'),
    tooltipDisplay: i18next.t('GENERAL.INGREDIENT_TOOLTIP'),
    propertyKey: `ingredient`,
    isSortable: true,
  },
  {
    id: 3,
    name: i18next.t('ANALYSIS.PURCHASES.COLUMN_NAME_TOT_PO'),
    tooltipDisplay: i18next.t('ANALYSIS.PURCHASES.COLUMN_TOOLTIP_PRICE_EVOLUTION_TOT_PO'),
    propertyKey: `orderedInCurrencyVolume`,
    isNumber: true,
    isSortable: true,
    filterType: 'numeric',
    render: (item) => <DisplayNumber number={item} displayCurrencyCode />,
  },
  {
    id: 4,
    name: i18next.t('ORDERS.PRICE_EVOLUTION.INITIAL_PRICE_COLUMN'),
    tooltipDisplay: i18next.t('ANALYSIS.PURCHASES.COLUMN_TOOLTIP_INITIAL_PRICE'),
    propertyKey: `initialPrice`,
    isNumber: true,
    isSortable: true,
    filterType: 'numeric',
    render: (value, item) => renderPricesColumnWithUnit(value, item),
  },
  {
    id: 5,
    name: i18next.t('ORDERS.PRICE_EVOLUTION.FINAL_PRICE_COLUMN'),
    tooltipDisplay: i18next.t('ANALYSIS.PURCHASES.COLUMN_TOOLTIP_FINAL_PRICE'),
    propertyKey: `finalPrice`,
    isNumber: true,
    isSortable: true,
    filterType: 'numeric',
    render: (value, item) => renderPricesColumnWithUnit(value, item),
  },
  {
    id: 6,
    name: i18next.t('ORDERS.PRICE_EVOLUTION.PRICE_EVOLUTION_COLUMN'),
    tooltipDisplay: i18next.t('ANALYSIS.PURCHASES.COLUMN_TOOLTIP_PERCENT_PRICE_EVOLUTION'),
    propertyKey: 'priceEvolution',
    isNumber: true,
    isSortable: true,
    filterType: 'numeric',
    render: (value) => renderPriceEvolutionValue(value),
  },
  {
    id: 7,
    name: '',
    propertyKey: 'action',
    isClickable: true,
    isSortable: false,
  },
];
