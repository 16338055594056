import styled from 'styled-components';

export const HeaderSelectorsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  column-gap: 24px;
  margin-right: 24px;
`;
