import i18next from 'i18next';
import React from 'react';

import {
  Container,
  TitleHeader,
  NumberHeader,
  ContainerHeader,
  ContainerContent,
} from './styledComponents';

import EmptyState from './components/EmptyState';
import ParentAssociationsList from './components/ParentAssociationsList';

export function renderEntityAssociation(props) {
  return (
    <div>
      <ContainerHeader>
        <TitleHeader>{i18next.t('ADMIN.RECIPES.ELEMENTS_USING_THIS_RECIPE')}</TitleHeader>
        <NumberHeader>{props.entitiesMapped.length}</NumberHeader>
      </ContainerHeader>
      <ContainerContent>
        <ParentAssociationsList entitiesMapped={props.entitiesMapped} recipe={props.recipe} />
      </ContainerContent>
    </div>
  );
}

export function renderEmptyState() {
  return (
    <ContainerContent>
      <EmptyState />
    </ContainerContent>
  );
}

const RecipeParentAssociations = (props) => {
  const { entitiesMapped } = props;

  if (!entitiesMapped || entitiesMapped.length === 0) {
    return (
      <Container style={{ marginBottom: '215px', minHeight: '285px' }}>
        {renderEmptyState()}
      </Container>
    );
  }

  return (
    <Container style={{ marginBottom: '215px', minHeight: '285px' }}>
      {renderEntityAssociation({
        ...props,
        entitiesMapped,
      })}
    </Container>
  );
};

export default RecipeParentAssociations;
