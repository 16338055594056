import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import {
  deleteSupplierProfile,
  deleteSupplierProfileSuccess,
  getSupplierProfilesOfSupplier,
  receiveSupplierProfilesOfSupplier,
  requestdeleteSupplierProfileError,
  requestSupplierProfilesOfSupplierError,
} from '@actions/supplierProfile';
import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, openMediumModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { supplierProfile as supplierProfilesService } from '@services/supplierProfile';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { InpulseLabel } from '@commons/DeepsightComponents';
import { ListView } from '@commons/utils/styledLibraryComponents';

import { ENUM_QUERY_PARAMS, useListViewQueryParams } from '@hooks/useListViewQueryParams';

import { canEditSupplierFromDetailView } from '@selectors/actions/supplierActions';
import { canUpdateAssociatedSupplier } from '@selectors/user';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import SupplierProfileModal from '../../SupplierProfileModal/SupplierProfileModal';

import { SUPPLIER_PROFILE_SETTING_ACTIONS } from '../SupplierProfileSettingsModal/constants';
import SupplierProfileSettingsModal from '../SupplierProfileSettingsModal';

import { Container } from './styledComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getActions, getRowActions } from './getActions';
import AddExceptionalDeliveryDaysToSupplierProfilesModal from '../AddExceptionalDeliveryDaysToSupplierProfilesModal';
import EmptyState from './EmptyState';

const renderStoreLabel = (item) => (
  <InpulseLabel color={item === 0 ? 'red' : 'green'} text={item.toString()} />
);

const getColumns = () => [
  {
    id: 'supplier_profile_name',
    name: i18next.t('GENERAL.NAME'),
    displayName: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
    baseName: 'name',
  },
  {
    id: 'nb-mappings',
    baseName: 'nbMappings',
    propertyKey: 'nbMappings',
    displayName: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_ASSOCIATED_STORES'),
    name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_ASSOCIATED_STORES'),
    excludeFromSearch: true,
    render: renderStoreLabel,
  },
];

export function renderEmptyState(
  handleNewSupplierProfile,
  isCentralKitchen,
  areUpdateSupplierActionsHidden,
) {
  return (
    <EmptyState
      handleNewSupplierProfile={handleNewSupplierProfile}
      isCentralKitchen={isCentralKitchen}
      isCreateActionHidden={areUpdateSupplierActionsHidden}
    />
  );
}

export const SupplierProfile = (props) => {
  const {
    supplierId,
    showSuccessMessage,
    showErrorMessage,
    openGenericModal,
    pageLoading,
    pageLoaded,
    deleteSupplierProfile,
    getSupplierProfilesOfSupplier,
    history,
    stores,
    openModal,
    user,
    client: { storeName, clientId, hasLocalCatalogs },
    authorizedActions,
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  const [supplier, setSupplier] = useState({});
  const [supplierProfiles, setSupplierProfiles] = useState([]);
  const [selectedSupplierProfiles, setSelectedSupplierProfiles] = useState([]);
  const [settingSupplierProfile, setSettingSupplierProfile] = useState(null); // when set, render SupplierProfileSettingsModal component
  const [actionType, setActionType] = useState(null);

  const [addingExceptionalDeliveryDays, setAddingExceptionalDeliveryDays] = useState(false);

  const [listViewQueryParams, setListViewQueryParams] = useListViewQueryParams();

  const clientStoreName = getClientStoreNameTranslation(storeName);
  const storeNamePlural = getClientStoreNameTranslation(storeName, true);

  const userLanguageCode = _.get(user, 'lnkLanguageAccountrel.code', 'fr');

  useEffect(() => {
    setIsLoading(true);

    if (!supplierId) {
      setIsLoading(false);
      return;
    }

    (async function refreshData() {
      await reloadData();
      setIsLoading(false);
    })();
  }, []);

  const resetSettingSupplierProfile = () => {
    setSettingSupplierProfile(null);
  };

  const handleEditSupplierProfile = (supplierProfile, operationType) => {
    const params = {
      component: SupplierProfileModal,
      operationType,
      history,
      reloadData: reloadData,
      supplier: supplier,
      supplierProfile,
      stores,
    };

    openModal(params);
  };

  const handleSupplierProfileAddStores = (supplierProfile) => {
    setSettingSupplierProfile(supplierProfile);
    setActionType(SUPPLIER_PROFILE_SETTING_ACTIONS.ASSOCIATE_SUPPLIER_PROFILE_STORES);
  };

  const handleSupplierProfileCreation = () => {
    const supplierProfile = {
      name: '',
      supplierId: supplier.id,
      orderMinAmount: '',
      orderMinUnit: '',
      contactName: '',
      address: '',
      email1: '',
      email2: '',
      telephone: '',
      deliveryDays: '',
      deliveryLeadTimes: '',
      orderTimeLimit: '',
      city: '',
      country: '',
      postCode: '',
    };
    setSettingSupplierProfile(supplierProfile);
    setActionType(SUPPLIER_PROFILE_SETTING_ACTIONS.CREATE_SUPPLIER_PROFILE);
  };

  const handleSupplierProfileDuplication = (supplierProfile) => {
    const supplierProfileToBeDuplicated = {
      ...supplierProfile,
      id: null,
      name: i18next.t('GENERAL.CLONE_NAME', { name: supplierProfile.name }),
    };
    setSettingSupplierProfile(supplierProfileToBeDuplicated);
    setActionType(SUPPLIER_PROFILE_SETTING_ACTIONS.DUPLICATE_SUPPLIER_PROFILE);
  };

  const reloadData = async () => {
    setIsLoading(true);

    try {
      const result = await getSupplierProfilesOfSupplier(supplierId);

      setSupplierProfiles(result.supplierProfiles);
      setSupplier(result.supplier);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIERS.GET_SUPPLIER_PROFILE_ERROR'));
    } finally {
      setIsLoading(false);
    }
  };

  const getModalContent = (supplierProfile) => {
    if (supplierProfile.nbMappings === 0) {
      return i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_WARNING_MODAL_CONTENT_NO_MAPPING', {
        name: supplierProfile.name,
      });
    }

    if (supplierProfile.nbMappings === 1) {
      return i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_WARNING_MODAL_CONTENT_UNIQ_MAPPING', {
        storeName: clientStoreName.toLowerCase(),
        supplierName: supplier.name,
      });
    }

    return i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_WARNING_MODAL_CONTENT_MULTIPLE_MAPPINGS', {
      nbMappings: supplierProfile.nbMappings,
      storeName: storeNamePlural.toLowerCase(),
      supplierName: supplier.name,
    });
  };

  const handleDeleteSupplierProfileConfirmationModale = (supplierProfile) => {
    const actions = [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_CONFIRM_BUTTON(),
        handleClick: async () => {
          await handleDeleteSupplierProfile(supplierProfile);
        },
      },
    ];

    const params = getConfirmationModal({
      title: i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_WARNING_MODAL_TITLE'),
      icon: '/images/inpulse/warning-white-small.svg',
      actions,
      content: getModalContent(supplierProfile),
    });

    openGenericModal(params);
  };

  const handleDeleteSupplierProfile = async (supplierProfile) => {
    pageLoading();
    try {
      await deleteSupplierProfile(supplierProfile.id);
      await reloadData();

      showSuccessMessage(
        i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_DELETE_SUPPLIER_PROFILE_CONFIRMATION'),
      );
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_DELETE_SUPPLIER_PROFILE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleExceptionalDeliveryDaysCreation = () => setAddingExceptionalDeliveryDays(true);

  const addExceptionalDeliveryDaysToSupplierProfiles = async (
    supplierProfiles,
    exceptionalDeliveryDays,
  ) => {
    pageLoading();

    const supplierProfileIds = supplierProfiles.map(({ id }) => id);

    const exceptionalDeliveryDaysPayload = exceptionalDeliveryDays.map(({ id, date, leadTime }) => {
      const commonFields = {
        date: date.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        leadTime: leadTime !== null ? +leadTime : null,
      };

      if (!!id) {
        return { ...commonFields, id };
      }

      return commonFields;
    });

    try {
      await supplierProfilesService.addExceptionalDeliveryDaysToSupplierProfiles(
        clientId,
        supplierProfileIds,
        exceptionalDeliveryDaysPayload,
      );

      await reloadData();

      showSuccessMessage(
        i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_ADD_EXCEPTIONAL_DELIVERY_DAYS_SUCCESS'),
      );
    } catch {
      showErrorMessage(
        i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_ADD_EXCEPTIONAL_DELIVERY_DAYS_ERROR'),
      );
    } finally {
      setAddingExceptionalDeliveryDays(false);

      pageLoaded();
    }
  };

  const deleteExceptionalDeliveryDaysBySupplierProfileIds = async (supplierProfiles) => {
    pageLoading();

    try {
      const supplierProfileIds = supplierProfiles.map(({ id }) => id);

      await supplierProfilesService.deleteExceptionalDeliveryDaysOfSupplierProfiles(
        supplierProfileIds,
      );

      await reloadData();

      showSuccessMessage(
        i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_DELETE_EXCEPTIONAL_DELIVERY_DAYS_SUCCESS'),
      );
    } catch {
      showErrorMessage(
        i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_DELETE_EXCEPTIONAL_DELIVERY_DAYS_ERROR'),
      );
    } finally {
      pageLoaded();
    }
  };

  const handleExceptionalDeliveryDaysDeletion = (supplierProfiles) => {
    const actions = [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_CONFIRM_BUTTON(),
        handleClick: async () => {
          await deleteExceptionalDeliveryDaysBySupplierProfileIds(supplierProfiles);
        },
      },
    ];

    const params = getConfirmationModal({
      actions,
      icon: '/images/inpulse/warning-white-small.svg',
      title: i18next.t(
        'ADMIN.SUPPLIERS.SUPPLIER_PROFILE_DELETE_EXCEPTIONAL_DELIVERY_DAYS_MODAL_TITLE',
      ),
      content: i18next.t(
        'ADMIN.SUPPLIERS.SUPPLIER_PROFILE_DELETE_EXCEPTIONAL_DELIVERY_DAYS_MODAL_CONTENT',
      ),
    });

    openGenericModal(params);
  };

  const areUpdateSupplierActionsHidden =
    !canEditSupplierFromDetailView(authorizedActions) ||
    !canUpdateAssociatedSupplier(user, supplier, { hasLocalCatalogs });

  const actions = !areUpdateSupplierActionsHidden
    ? getActions({
        selectedSupplierProfiles,
        handleSupplierProfileCreation,
        handleExceptionalDeliveryDaysCreation,
        handleExceptionalDeliveryDaysDeletion,
      })
    : [];

  const columns = getColumns();

  const rowActions = getRowActions({
    clientStoreName,
    handleEditSupplierProfile,
    handleSupplierProfileAddStores,
    handleSupplierProfileDuplication,
    handleDeleteSupplierProfileConfirmationModale,
    areUpdateSupplierActionsHidden,
  });

  /**********\   RENDER   /***********/

  return (
    <>
      {!isLoading &&
        (!supplierProfiles || supplierProfiles.length === 0) &&
        renderEmptyState(
          handleSupplierProfileCreation,
          supplier.isKitchen,
          areUpdateSupplierActionsHidden,
        )}
      <Container>
        {!!supplierProfiles && supplierProfiles.length !== 0 && (
          <ListView
            actionOnClick={(supplier) => handleEditSupplierProfile(supplier, 'edit')}
            actions={actions}
            columns={columns}
            data={supplierProfiles}
            defaultCurrentPage={listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE]}
            defaultMaxPerPage={listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]}
            defaultOrderBy={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]}
            defaultOrderType={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]}
            defaultSearchInput={listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH]}
            handleCurrentPageChange={(input) =>
              setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](input)
            }
            handleMaxPerPageChange={(input) =>
              setListViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE](input)
            }
            handleOrderByChange={(input) =>
              setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY](input)
            }
            handleOrderTypeChange={(input) =>
              setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE](input)
            }
            handleSearchInputChange={(input) =>
              setListViewQueryParams[ENUM_QUERY_PARAMS.SEARCH](input)
            }
            isLoading={isLoading}
            languageCode={userLanguageCode}
            padding={'24px 24px 0px 24px'}
            placeholderShape={i18next.t('GENERAL.SEARCH')}
            rowActions={rowActions}
            setSelectedItems={setSelectedSupplierProfiles}
          />
        )}
        {!!settingSupplierProfile && (
          <SupplierProfileSettingsModal
            actionType={actionType}
            allSupplierProfiles={supplierProfiles}
            reloadData={reloadData}
            resetSettingSupplierProfile={resetSettingSupplierProfile}
            supplier={supplier}
            supplierProfile={settingSupplierProfile}
          />
        )}

        {!!addingExceptionalDeliveryDays && (
          <AddExceptionalDeliveryDaysToSupplierProfilesModal
            addExceptionalDeliveryDaysToSupplierProfiles={
              addExceptionalDeliveryDaysToSupplierProfiles
            }
            setAddingExceptionalDeliveryDays={setAddingExceptionalDeliveryDays}
            supplierProfiles={selectedSupplierProfiles}
          />
        )}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  stores: state.baseReducer.stores,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/admin/suppliers/:id/details',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  getSupplierProfilesOfSupplier: (supplierId) =>
    dispatch(getSupplierProfilesOfSupplier(supplierId))
      .then((result) => {
        dispatch(receiveSupplierProfilesOfSupplier(result));
        return result;
      })
      .catch((error) => dispatch(requestSupplierProfilesOfSupplierError(error))),
  deleteSupplierProfile: (supplier) =>
    dispatch(deleteSupplierProfile(supplier)).then(
      (result) => dispatch(deleteSupplierProfileSuccess(result)),
      (error) => {
        dispatch(requestdeleteSupplierProfileError(error));
        throw 'Error';
      },
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierProfile);
