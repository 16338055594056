import { connect } from 'react-redux';
import { get, upperFirst } from 'lodash';
import i18next from 'i18next';
import React, { useState } from 'react';

import { ListView, Button } from '@commons/utils/styledLibraryComponents';
import {
  PATHS_AVAILABLE_IN_ALL_WORKSPACES,
  HELP_CENTER_FEATURE_NAME,
  REFERRAL_FEATURE_NAME,
} from '@commons/constants/centralConstants';
import EmptyState from '@commons/EmptyState';

import { getClientInfo } from '@selectors/client';

import {
  Container,
  Header,
  HeaderTitle,
  CloseImage,
  ListViewContainer,
  Footer,
} from './styledComponents';

const getColumns = (hasCentralKitchens) => [
  {
    id: 'name',
    name: i18next.t('GENERAL.PAGE'),
    propertyKey: 'name',
  },
  {
    id: 'module',
    name: i18next.t('GENERAL.MODULE'),
    propertyKey: 'module',
  },
  {
    id: 'path',
    name: i18next.t('GENERAL.PATH'),
    propertyKey: 'path',
    displayBigger: true,
  },
  {
    id: 'isCentral',
    name: i18next.t('GENERAL.WORKSPACE'),
    propertyKey: 'isCentral',
    baseName: 'isCentral',
    hidden: !hasCentralKitchens,
    render: (isCentral, feature) => {
      if (isCentral) {
        return i18next.t('GENERAL.CENTRAL');
      }

      const featurePathMainModule = get(feature.path.split('/'), '[1]', ''); // We take the first element because [0] is equal to '/'

      if (
        PATHS_AVAILABLE_IN_ALL_WORKSPACES.includes(featurePathMainModule) ||
        feature.name === HELP_CENTER_FEATURE_NAME ||
        feature.name === REFERRAL_FEATURE_NAME
      ) {
        return i18next.t('GENERAL.COMMON');
      }

      return i18next.t('GENERAL.SALE_POINT');
    },
  },
];

const FeaturesAddOrRemoveAssociationModal = (props) => {
  const {
    params: { role, closeModal, features, handleSave, isAssociation },
    client: { hasCentralKitchens },
  } = props;

  const [selectedItems, setSelectedItems] = useState([]);

  const columns = getColumns(hasCentralKitchens);

  return (
    <Container>
      <Header>
        <HeaderTitle>
          {i18next.t(
            isAssociation
              ? 'BACKOFFICE.ROLES.PAGE_ASSOCIATION_TO_ROLE'
              : 'BACKOFFICE.ROLES.PAGE_DISSOCIATION_TO_ROLE',
            { role: upperFirst(role.name) },
          )}
        </HeaderTitle>
        <CloseImage src="/images/inpulse/close-black-small.svg" onClick={() => closeModal()} />
      </Header>
      <ListViewContainer>
        <ListView
          columns={columns}
          data={features}
          defaultOrderBy={'name'}
          defaultOrderType={'asc'}
          disableFooter={true}
          forceEnableSelection={true}
          padding={'24px 24px 0px 24px'}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => <EmptyState />}
          setSelectedItems={(items) => setSelectedItems(items)}
        />
      </ListViewContainer>
      <Footer>
        <Button
          color={'inpulse-outline'}
          handleClick={() => closeModal()}
          icon="/images/inpulse/close-black-small.svg"
          label={i18next.t('GENERAL.CANCEL')}
        />
        <Button
          buttonCustomStyle={{ marginLeft: 24 }}
          color={'inpulse-default'}
          handleClick={() => handleSave(selectedItems, role.id)}
          icon="/images/inpulse/save-white-small.svg"
          isDisabled={!selectedItems.length}
          label={i18next.t('GENERAL.SAVE')}
        />
      </Footer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

export default connect(mapStateToProps)(FeaturesAddOrRemoveAssociationModal);
