import { connect } from 'react-redux';
import { get, keyBy } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';
import { switchCentralMode } from '@actions/user';

import { Button } from '@commons/utils/styledLibraryComponents';
import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { config } from '@src/config';

import invoiceService from '@services/invoice';

import utilsColumns from './utils/columns';
import utilsModals from './utils/modals';

import {
  Row,
  Container,
  RowContainer,
  TitleContainer,
  ButtonContainer,
  ContentContainer,
  ColumnsContainer,
  ButtonsContainer,
} from './styledComponents';
import useLocalStorage from '@hooks/useLocalStorage';

export const AssociatedOrders = (props) => {
  const {
    // Props
    invoice,
    isInvoiceLocked,
    setHasAssociatedOrders,
    // Dispatch methods
    openGenericModal,
    refreshGenericModal,
    showSuccessMessage,
    showErrorMessage,
    switchCentralMode,
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  const [associatedOrders, setAssociatedOrders] = useState([]);

  const [ordersToBeAdded, setOrdersToBeAdded] = useState([]);

  const [isCentralMode, setIsCentralMode] = useLocalStorage('isCentralMode', false);

  const [columns] = useState(utilsColumns.get());

  // Retrieve invoice information
  useEffect(() => {
    if (!invoice.id) {
      return;
    }

    getAssociatedOrder(invoice.id);
  }, [invoice.id]);

  // Handle modal's refresh
  useEffect(() => {
    const params = utilsModals.getOrderMappingsModalConfig({
      invoice,
      associateOrders,
      ordersToBeAdded,
      setOrdersToBeAdded,
      alreadyMappedOrdersKeyById: keyBy(associatedOrders, 'id'),
    });

    refreshGenericModal(params);
  }, [ordersToBeAdded]);

  const getAssociatedOrder = async (invoiceId) => {
    setIsLoading(true);

    try {
      const result = await invoiceService.getOrdersByInvoiceControlListId(invoiceId);

      setAssociatedOrders(result);
      setHasAssociatedOrders(result.length);
    } catch {
      setAssociatedOrders([]);

      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FETCH_ASSOCIATED_ORDERS_ERROR'));
    } finally {
      setIsLoading(false);
    }
  };

  const openModalNewAssociation = async () => {
    const params = utilsModals.getOrderMappingsModalConfig({
      invoice,
      associateOrders,
      ordersToBeAdded,
      setOrdersToBeAdded,
      alreadyMappedOrdersKeyById: keyBy(associatedOrders, 'id'),
    });

    openGenericModal(params);
  };

  /**
   * Handle the fact switching of referentiel mode (CC or PdV) according to store.isKitchen value
   */
  const openOrderNewTab = (reference, isStoreKitchen) => {
    // Need to switch mode
    if (isCentralMode !== isStoreKitchen) {
      setIsCentralMode(isStoreKitchen);
      switchCentralMode(isStoreKitchen);
    }

    if (isStoreKitchen) {
      window.open(`${config.baseAppUrl}central-kitchen-order/orders?search=${reference}`, '_blank');

      return;
    }

    window.open(`${config.baseAppUrl}order/orders?search=${reference}`, '_blank');
  };

  const deleteOrderAssociation = async (orderId) => {
    setIsLoading(true);

    try {
      await invoiceService.deleteOrderByInvoiceControlListId(invoice.id, orderId);

      showSuccessMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.DISSOCIATE_ORDER_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.DISSOCIATE_ORDER_ERROR'));
    } finally {
      // Refresh data
      await getAssociatedOrder(invoice.id);
    }
  };

  const associateOrders = async () => {
    const orderIds = ordersToBeAdded.map(({ id }) => id);

    if (!orderIds.length) {
      return;
    }

    setIsLoading(true);

    try {
      await invoiceService.addOrdersByInvoiceControlListId(invoice.id, orderIds);

      showSuccessMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATE_ORDERS_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATE_ORDERS_ERROR'));
    } finally {
      // Refresh data
      await getAssociatedOrder(invoice.id);
    }
  };

  if (isLoading) {
    return (
      <Container>
        <TitleContainer>
          {i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDERS')}
        </TitleContainer>
        <ContentContainer>
          <DeepsightComponentLoader />
        </ContentContainer>
        <ButtonContainer></ButtonContainer>
      </Container>
    );
  }

  return (
    <Container>
      <TitleContainer>
        {i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDERS')}
      </TitleContainer>
      {associatedOrders.length > 0 && (
        <ContentContainer>
          <ColumnsContainer>
            {columns.map(({ name }, index) => (
              <Text
                color={ENUM_COLORS.DARKER}
                font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
                key={`column-header-${index}`}
              >
                {name}
              </Text>
            ))}
          </ColumnsContainer>
          {associatedOrders.map((order, id) => (
            <RowContainer key={`list-container-${id}`}>
              <Row>{columns.map(({ render }) => render(order))}</Row>
              <ButtonsContainer>
                <Button
                  color={'inpulse-outline'}
                  handleClick={() => {
                    openOrderNewTab(order.reference, get(invoice, 'store.isKitchen', false));
                  }}
                  icon={'/images/inpulse/open-a-new-black-small.svg'}
                  iconCustomStyle={{ width: '16px', height: '16px' }}
                />
                <Button
                  color={'inpulse-grey'}
                  handleClick={() => deleteOrderAssociation(order.orderId)}
                  icon={'/images/inpulse/trash-white-small.svg'}
                  iconCustomStyle={{ width: '12px', height: '12px' }}
                  isDisabled={associatedOrders.length > 1} // temporary rule that will be removed when new specs will have been built the data team
                />
              </ButtonsContainer>
            </RowContainer>
          ))}
        </ContentContainer>
      )}
      <ButtonContainer>
        <Button
          buttonCustomStyle={{ paddingLeft: '0px' }}
          color={'inpulse-outline'}
          handleClick={openModalNewAssociation}
          icon={'/images/inpulse/add-black-small.svg'}
          isDisabled={associatedOrders.length > 0 || isInvoiceLocked} // temporary rule that will be removed when new specs will have been built the data team
          label={i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATE_AN_ORDER')}
          noBorder={true}
        />
      </ButtonContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  switchCentralMode: (centralMode) => {
    dispatch(switchCentralMode(centralMode));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AssociatedOrders);
