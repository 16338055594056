import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 24px;
`;

export const CategoryHeaderContainer = styled.div`
  display: flex;
  height: 40px;

  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
`;

export const LeftPartHeader = styled.div`
  display: flex;
`;

export const RightPartHeader = styled.div`
  display: flex;
`;

export const ArrowContainer = styled.div``;

export const ArrowIcon = styled.img``;

export const CategoryAmountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  align-items: center;
  padding-bottom: 8px;
  margin: 24px 0;
  min-width: 1278px;
  width: calc(100% - 24px);
`;

export const Category = styled.div`
  color: ${(props) => props.theme.colors.greys.darkest};
  text-transform: capitalize;
  font: ${(props) => props.theme.fonts.h2Inter};
  margin-left: 16px;
`;

export const ColumnsContainer = styled.div`
  display: flex;
  padding: 10px 20px;
`;

export const Column = styled.div`
  font-family: proximanovaregular;
  color: #979797;
  font-size: 12px;
  line-height: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-basis: ${(props) =>
    props.large ? `calc(${(100 / props.nbColumns) * 2}%)` : `calc(${100 / props.nbColumns}%)`};
`;

export const BodyContainer = styled.div`
  min-width: 1302px;
`;

export const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  min-width: 1100px;
`;

export const TableHead = styled.thead``;

export const TableRow = styled.tr`
  height: 41px;
  width: 100%;
  line-height: 41px;
  border-bottom: 1px solid #e2e3e5;
`;

export const TableColumn = styled.th`
  font-family: proximanovaregular;
  color: #979797;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 15px;
`;

export const TableBody = styled.tbody``;

export const BorderBottomCategory = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.greys.light};
  width: 100%;
  margin: ${(props) => (props.isOpen ? '24px auto 24px auto' : 'auto auto 24px auto')};
`;

export default {
  Container,
  Table,
  TableHead,
  TableRow,
  TableColumn,
  TableBody,
  Category,
  CategoryAmountContainer,
  CategoryHeaderContainer,
  LeftPartHeader,
  RightPartHeader,
  ArrowContainer,
  ArrowIcon,
  BorderBottomCategory,
};
