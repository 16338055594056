/**
 * Assert than a supplier product is the same unit than the ingredient itself
 *
 *
 * @param {SupplierProduct} supplierProduct - the supplier product to check
 * @param {Ingredient} ingredientUnit       - the ingredient's unit to match
 *
 * @returns {boolean} Whether the supplier product is the same unit than the ingredient unit
 */
export function isSameUnit(supplierProduct, ingredientUnit) {
  if (!supplierProduct.packagingUnit) {
    return false;
  }

  if (supplierProduct.packagingUnit === 'kg' && ingredientUnit !== 'g') {
    return false;
  }

  if (supplierProduct.packagingUnit === 'L' && ingredientUnit !== 'mL') {
    return false;
  }

  if (supplierProduct.packagingUnit === 'unit' && ingredientUnit !== 'unit') {
    return false;
  }

  return true;
}

/**
 * Assert that the list of supplier products mapped to an ingredient
 * is the same unit that the ingredient itself
 *
 * @param {Ingredient} ingredient - the ingredient to check
 *
 * @returns {boolean} Whether all the supplier product are the same unit than the ingredient
 */
export function areUnitsSame(ingredient) {
  const { supplierProducts, unit } = ingredient;

  if (!supplierProducts) {
    return true;
  }

  return supplierProducts.every((supplierProduct) => isSameUnit(supplierProduct, unit));
}

export default {
  isSameUnit,
  areUnitsSame,
};
