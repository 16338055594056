import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${(props) => props.theme.colors?.greys?.lighter};
`;

export const WhiteCardsContainer = styled.div`
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  overflow: auto;
  padding: 24px 0;
`;

export default {
  Container,
  WhiteCardsContainer,
};
