import styled from 'styled-components';

export const Container = styled.div`
  height: inherit;
  width: inherit;

  .custom-tooltip-modal {
    border-radius: 8px;

    background-color: transparent;

    width: auto;
  }

  .custom-tooltip-modal-open {
    border-radius: 8px;

    width: auto;
  }

  .custom-tooltip {
    word-break: break-word;
    white-space: pre-wrap;
    background-color: transparent;
    color: black;
    opacity: 1 !important;

    border-radius: 8px;

    max-height: 1200px;

    text-align: center;
  }

  .custom-tooltip::before {
    border-right: none !important;
  }
`;

export default {
  Container,
};
