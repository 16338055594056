import { supplierProfile } from '../services/supplierProfile';

//  GET SUPPLIER_PROFILE OF SUPPLIER

export const receiveSupplierProfilesOfSupplier = (result) => ({
  type: 'SUPPLIER_PROFILES_OF_SUPPLIER_SUCCESS',
  result,
});

export const requestSupplierProfilesOfSupplierError = (error) => ({
  type: 'SUPPLIER_PROFILES_OF_SUPPLIER_FAILURE',
  error,
});

export const getSupplierProfilesOfSupplier = (supplierId, userTimezone = 'Europe/Paris') =>
  function (dispatch) {
    dispatch({
      type: 'SUPPLIER_PROFILES_OF_SUPPLIER_REQUEST',
    });
    return supplierProfile.getSupplierProfilesOfSupplier(supplierId, userTimezone);
  };

//  GET SUPPLIER_PROFILES OF STORE

export const receiveSupplierProfilesOfStore = (supplierProfiles) => ({
  type: 'SUPPLIER_PROFILES_OF_STORE_SUCCESS',
  supplierProfiles,
});

export const requestSupplierProfilesOfStoreError = (error) => ({
  type: 'SUPPLIER_PROFILES_OF_STORE_FAILURE',
  error,
});

export const getSupplierProfilesOfStore = (supplierId) =>
  function (dispatch) {
    dispatch({
      type: 'SUPPLIER_PROFILES_OF_STORE_REQUEST',
    });
    return supplierProfile.getSupplierProfilesOfStore(supplierId);
  };

//  POST SUPPLIER_PROFILE WITH MAPPINGS ( & DEPENDENCIES )

export const postSupplierProfileWithMappingsSuccess = (success) => ({
  type: 'POST_SUPPLIER_PROFILE_WITH_MAPPINGS_SUCCESS',
  success,
});

export const postSupplierProfileWithMappingsError = (error) => ({
  type: 'POST_SUPPLIER_PROFILE_WITH_MAPPINGS_FAILURE',
  error,
});

export const postSupplierProfileWithMappings = (
  supplierProfileToCreate,
  storeIds,
  supplierProducts,
  cloneFromStoreId,
) =>
  function (dispatch) {
    dispatch({
      type: 'POST_SUPPLIER_PROFILE_WITH_MAPPINGS_REQUEST',
    });
    return supplierProfile.postSupplierProfileWithMappings(
      supplierProfileToCreate,
      storeIds,
      supplierProducts,
      cloneFromStoreId,
    );
  };

//  PATCH SUPPLIER_PROFILE

export const patchSupplierProfileSuccess = (success) => ({
  type: 'PATCH_SUPPLIER_PROFILE_SUCCESS',
  success,
});

export const patchSupplierProfileError = (error) => ({
  type: 'PATCH_SUPPLIER_PROFILE_FAILURE',
  error,
});

export const patchSupplierProfile = (
  supplierProfileToPatch,
  exceptionalDeliveryDays = [],
  userTimezone = 'Europe/Paris',
) =>
  function (dispatch) {
    dispatch({
      type: 'PATCH_SUPPLIER_PROFILE_REQUEST',
    });
    return supplierProfile.patchSupplierProfile(
      supplierProfileToPatch,
      exceptionalDeliveryDays,
      userTimezone,
    );
  };

//  DELETE SUPPLIER_PROFILE

export const deleteSupplierProfileSuccess = (result) => ({
  type: 'DELETE_SUPPLIER_PROFILE_SUCCESS',
  result,
});

export const requestdeleteSupplierProfileError = (error) => ({
  type: 'DELETE_SUPPLIER_PROFILE_FAILURE',
  error,
});

export const deleteSupplierProfile = (supplierProfileId) =>
  function (dispatch) {
    dispatch({
      type: 'DELETE_SUPPLIER_PROFILE_REQUEST',
    });
    return supplierProfile.deleteSupplierProfile(supplierProfileId);
  };
