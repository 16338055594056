import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 56px;
`;
export const WeatherContainer = styled.div`
  display: flex;
  height: 100%;

  align-items: center;
  justify-content: center;
`;

export const WeatherIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export const WeatherTempMax = styled.div`
  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.textBigHeight16};
  margin: 0 8px 0 4px;
`;

export const WeatherSeparator = styled.div`
  width: 1px;
  height: 16px;

  background-color: ${(props) => props.theme.colors.greys.light};
`;

export const WeatherTempMin = styled.div`
  color: ${(props) => props.theme.colors.greys.darker};
  font: ${(props) => props.theme.fonts.textBigHeight16};
  margin-left: 8px;
`;

export default {
  Container,
  WeatherContainer,
  WeatherIcon,
  WeatherTempMax,
  WeatherTempMin,
  WeatherSeparator,
};
