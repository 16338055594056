import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment';

import {
  GENERIC_MODAL_BACK_BUTTON,
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_NEXT_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

import { AskProductCashierProductMappingModal } from './stepsModals/AskProductCashierProductMappingModal';
import { AskProductionPlanningModal } from './stepsModals/AskProductionPlanningModal';
import ConfigureProductionPlanningModal from './stepsModals/ConfigureProductionPlanningModal';
import SelectCashierProductsModal from './stepsModals/SelectCashierProductsModal';
import SelectStoresModal from './stepsModals/SelectStoresModal';

export const ENABLE_PRODUCT_MODAL_STEPS = {
  ASK_PRODUCT_CASHIER_PRODUCT_MAPPING: 'askProductCashierProductMapping',
  SELECT_CASHIER_PRODUCTS: 'selectCashierProducts',
  ASK_PRODUCTION_PLANNING: 'askProductionPlanning',
  SELECT_STORES: 'selectStores',
  CONFIGURE_PRODUCTION_PLANNING: 'configureProductionPlanning',
};
const stepNumber = {
  [ENABLE_PRODUCT_MODAL_STEPS.ASK_PRODUCT_CASHIER_PRODUCT_MAPPING]: 1,
  [ENABLE_PRODUCT_MODAL_STEPS.SELECT_CASHIER_PRODUCTS]: 1,
  [ENABLE_PRODUCT_MODAL_STEPS.ASK_PRODUCTION_PLANNING]: 2,
  [ENABLE_PRODUCT_MODAL_STEPS.SELECT_STORES]: 2,
  [ENABLE_PRODUCT_MODAL_STEPS.CONFIGURE_PRODUCTION_PLANNING]: 2,
};

const enableProductModalComponents = {
  [ENABLE_PRODUCT_MODAL_STEPS.ASK_PRODUCT_CASHIER_PRODUCT_MAPPING]:
    AskProductCashierProductMappingModal,
  [ENABLE_PRODUCT_MODAL_STEPS.SELECT_CASHIER_PRODUCTS]: SelectCashierProductsModal,
  [ENABLE_PRODUCT_MODAL_STEPS.ASK_PRODUCTION_PLANNING]: AskProductionPlanningModal,
  [ENABLE_PRODUCT_MODAL_STEPS.SELECT_STORES]: SelectStoresModal,
  [ENABLE_PRODUCT_MODAL_STEPS.CONFIGURE_PRODUCTION_PLANNING]: ConfigureProductionPlanningModal,
};

/**
 * Properly format the modal's state for the API call (see handleCallToEnableProduct)
 * @param {*} props
 * @returns either an object { productId } or
 * an object { product, cashierProductIds, storeIds, price, currency, hasStock, launchDate, endDate }
 */
const formatDataForApiCall = (props) => {
  const {
    product,
    includeCashierProductMappingStep,
    includeProductionPlanningStep,
    selectCashierProductsData,
    selectStoresData,
    configureProductionData,
  } = props;

  let cashierProductIds = [];
  if (includeCashierProductMappingStep) {
    cashierProductIds = selectCashierProductsData.selectedCashierProductIds;
  }

  let storeIds = [];
  if (includeProductionPlanningStep) {
    const selectedStores = selectStoresData.filter((store) => store.isRowSelected);
    storeIds = selectedStores.map((store) => store.id);
  }

  const { price, currency, hasStock } = configureProductionData;

  const launchDate = configureProductionData.launchDate
    ? moment(configureProductionData.launchDate).format('YYYY-MM-DD')
    : null;
  const endDate = configureProductionData.endDate
    ? moment(configureProductionData.endDate).format('YYYY-MM-DD')
    : null;

  return {
    product: { ...product, active: true },
    cashierProductIds,
    storeIds,
    price,
    currency,
    launchDate,
    endDate,
    hasStock,
  };
};

const getEnableProductModalActions = (props) => {
  const {
    currentStep,
    setCurrentStep,
    includeCashierProductMappingStep,
    includeProductionPlanningStep,
    selectCashierProductsData,
    selectStoresData,
    resetModal,
    handleCallToEnableProduct,
    canAccessProductionPlanning,
  } = props;

  if (currentStep === ENABLE_PRODUCT_MODAL_STEPS.ASK_PRODUCT_CASHIER_PRODUCT_MAPPING) {
    return [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_NEXT_BUTTON(),
        preventClosing: true,
        label: includeCashierProductMappingStep
          ? GENERIC_MODAL_NEXT_BUTTON().label
          : canAccessProductionPlanning
          ? GENERIC_MODAL_NEXT_BUTTON().label
          : i18next.t('GENERAL.ACTIVATE'),
        icon: includeCashierProductMappingStep
          ? GENERIC_MODAL_NEXT_BUTTON().icon
          : canAccessProductionPlanning
          ? GENERIC_MODAL_NEXT_BUTTON().icon
          : '/images/inpulse/power-white-small.svg',
        handleClick: async () => {
          const nextStep = includeCashierProductMappingStep
            ? ENABLE_PRODUCT_MODAL_STEPS.SELECT_CASHIER_PRODUCTS
            : canAccessProductionPlanning
            ? ENABLE_PRODUCT_MODAL_STEPS.ASK_PRODUCTION_PLANNING
            : null;

          if (nextStep) {
            setCurrentStep(nextStep);
            return;
          }

          const formattedData = formatDataForApiCall(props);
          await handleCallToEnableProduct(formattedData);
          resetModal();
        },
      },
    ];
  }

  if (currentStep === ENABLE_PRODUCT_MODAL_STEPS.SELECT_CASHIER_PRODUCTS) {
    return [
      {
        ...GENERIC_MODAL_BACK_BUTTON(),
        preventClosing: true,
        handleClick: () =>
          setCurrentStep(ENABLE_PRODUCT_MODAL_STEPS.ASK_PRODUCT_CASHIER_PRODUCT_MAPPING),
      },
      {
        ...GENERIC_MODAL_NEXT_BUTTON(),
        preventClosing: true,
        label: canAccessProductionPlanning
          ? GENERIC_MODAL_NEXT_BUTTON().label
          : i18next.t('GENERAL.ACTIVATE'),
        icon: canAccessProductionPlanning
          ? GENERIC_MODAL_NEXT_BUTTON().icon
          : '/images/inpulse/power-white-small.svg',

        isDisabled: _.isEmpty(selectCashierProductsData.selectedCashierProductIds),
        handleClick: async () => {
          if (canAccessProductionPlanning) {
            setCurrentStep(ENABLE_PRODUCT_MODAL_STEPS.ASK_PRODUCTION_PLANNING);
            return;
          }
          const formattedData = formatDataForApiCall(props);
          await handleCallToEnableProduct(formattedData);
          resetModal();
        },
      },
    ];
  }

  if (currentStep === ENABLE_PRODUCT_MODAL_STEPS.ASK_PRODUCTION_PLANNING) {
    return [
      {
        ...GENERIC_MODAL_BACK_BUTTON(),
        preventClosing: true,
        handleClick: () => {
          const prevStep = includeCashierProductMappingStep
            ? ENABLE_PRODUCT_MODAL_STEPS.SELECT_CASHIER_PRODUCTS
            : ENABLE_PRODUCT_MODAL_STEPS.ASK_PRODUCT_CASHIER_PRODUCT_MAPPING;
          setCurrentStep(prevStep);
        },
      },
      {
        ...GENERIC_MODAL_NEXT_BUTTON(),
        preventClosing: includeProductionPlanningStep,
        label: includeProductionPlanningStep
          ? GENERIC_MODAL_NEXT_BUTTON().label
          : i18next.t('GENERAL.ACTIVATE'),
        icon: includeProductionPlanningStep
          ? GENERIC_MODAL_NEXT_BUTTON().icon
          : '/images/inpulse/power-white-small.svg',
        handleClick: async () => {
          if (includeProductionPlanningStep) {
            setCurrentStep(ENABLE_PRODUCT_MODAL_STEPS.SELECT_STORES);
            return;
          }
          const formattedData = formatDataForApiCall(props);
          await handleCallToEnableProduct(formattedData);
          resetModal();
        },
      },
    ];
  }

  if (currentStep === ENABLE_PRODUCT_MODAL_STEPS.SELECT_STORES) {
    return [
      {
        ...GENERIC_MODAL_BACK_BUTTON(),
        preventClosing: true,
        handleClick: () => setCurrentStep(ENABLE_PRODUCT_MODAL_STEPS.ASK_PRODUCTION_PLANNING),
      },
      {
        ...GENERIC_MODAL_NEXT_BUTTON(),
        preventClosing: true,
        isDisabled: _.every(selectStoresData, (store) => !store.isRowSelected),
        handleClick: () => setCurrentStep(ENABLE_PRODUCT_MODAL_STEPS.CONFIGURE_PRODUCTION_PLANNING),
      },
    ];
  }

  if (currentStep === ENABLE_PRODUCT_MODAL_STEPS.CONFIGURE_PRODUCTION_PLANNING) {
    return [
      {
        ...GENERIC_MODAL_BACK_BUTTON(),
        preventClosing: true,
        handleClick: () => setCurrentStep(ENABLE_PRODUCT_MODAL_STEPS.SELECT_STORES),
      },
      {
        ...GENERIC_MODAL_NEXT_BUTTON(),
        label: i18next.t('GENERAL.ACTIVATE'),
        icon: '/images/inpulse/power-white-small.svg',
        handleClick: async () => {
          const formattedData = formatDataForApiCall(props);
          await handleCallToEnableProduct(formattedData);
          resetModal();
        },
      },
    ];
  }
};

export const getEnableProductModalConfig = (props) => {
  const { product, currentStep, resetModal } = props;

  const params = {
    type: 'actionWhite',
    isFullscreen: true,
    icon: '/images/inpulse/power-ip-black.svg',
    title: i18next.t('ADMIN.PRODUCTS.ENABLE_PRODUCT_MODAL_TITLE', {
      name: product.name,
    }),
    handleCloseCleanUp: resetModal,
    actions: getEnableProductModalActions(props),
    component: enableProductModalComponents[currentStep],
    data: props,
    step: { total: 2, current: stepNumber[currentStep] },
    componentOverflow: 'hidden',
  };

  return params;
};
