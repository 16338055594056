import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Header = styled.div`
  height: 72px;
  width: 100%;

  padding: 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${(props) => props.theme.colors.greys.lightest};
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;

  font: ${(props) => props.theme.fonts.h1InterNonBold};
`;

export const CloseImage = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const ListViewContainer = styled.div`
  width: 100%;
  height: calc(100% - 104px);

  position: relative;
`;

export const Footer = styled.div`
  height: 88px;
  width: 100%;

  padding: 24px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  bottom: 0;
  position: absolute;

  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 40%);
  border-radius: 0 0 8px 8px;

  background-color: ${(props) => props.theme.colors.greys.lightest};
`;

export default {
  Container,
  Header,
  HeaderTitle,
  CloseImage,
  ListViewContainer,
  Footer,
};
