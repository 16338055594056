import { sortBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import {
  makeXLS,
  generateStoresSheet,
  generateDefaultSheet,
  generateDatesSheetFromStartEndDate,
} from '@commons/utils/makeXLS';

import getColumnsTable from './columns';

const getExportFile = ({
  data,
  endDate,
  currency,
  storeName,
  startDate,
  selectedStores,
  selectedMetric,
  analyticsColumns,
}) => {
  const clientStoreName = getClientStoreNameTranslation(storeName);
  const clientStoreNamePlural = getClientStoreNameTranslation(storeName, true);

  const columnsToExport = () => {
    const columnPartnerId = {
      id: 2,
      propertyKey: 'partnerId',
      type: 'string',
      name: 'Identifiant partenaire',
    };

    const columnsData = getColumnsTable(
      currency,
      selectedMetric.key,
      analyticsColumns,
      clientStoreNamePlural,
    );

    columnsData.push(columnPartnerId);

    const columnsDataSortedById = sortBy(columnsData, 'id');

    return columnsDataSortedById;
  };

  const formattedData =
    selectedMetric.key === 'turnover'
      ? data
      : data.map((item) => ({ ...item, realTurnoverInclTax: null }));

  const sheets = [
    generateDefaultSheet(
      `${i18next.t('GENERAL.LABEL_BY')} ${clientStoreName} ${i18next
        .t('GENERAL.LABEL_IN')
        .toLowerCase()} ${
        selectedMetric.key === 'turnover'
          ? i18next.t('PRODUCTION.REAL_TIME.METRIC_TURNOVER')
          : i18next.t('PRODUCTION.REAL_TIME.METRIC_UNIT')
      }`,
      columnsToExport(),
      formattedData,
      currency,
    ),
    generateStoresSheet(clientStoreNamePlural, selectedStores),
    generateDatesSheetFromStartEndDate(
      moment(startDate).startOf('day').format('LLLL'),
      moment(endDate).format('LLLL'),
    ),
  ];

  makeXLS(
    i18next.t('PRODUCTION.BY_STORE.EXPORT_FILENAME', {
      storeName: clientStoreName.toLowerCase(),
    }),
    sheets,
  );
};

export default getExportFile;
