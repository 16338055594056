import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

import {
  EmptyStateContainer,
  EmptyStateIcon,
  MainText,
  Text,
  TextContainer,
} from './styledComponents';

const EmptyState = (props) => {
  const { handleNewSupplierProfile, isCentralKitchen, isCreateActionHidden } = props;

  const getMainText = (isCentralKitchen, isCreateActionHidden) => {
    if (isCreateActionHidden) {
      return '';
    }

    if (isCentralKitchen) {
      return i18next.t(
        'ADMIN.SUPPLIERS.EMPTY_SUPPLIER_PROFILE_CREATE_YOUR_FIRST_CENTRAL_KITCHEN_SUPPLIER_PROFILE_TITLE',
      );
    }

    return i18next.t(
      'ADMIN.SUPPLIERS.EMPTY_SUPPLIER_PROFILE_CREATE_YOUR_FIRST_SUPPLIER_PROFILE_TITLE',
    );
  };

  const getText = (isCentralKitchen, isCreateActionHidden) => {
    if (isCreateActionHidden) {
      return i18next.t('ADMIN.SUPPLIERS.EMPTY_SUPPLIER_PROFILE_TITLE');
    }

    if (isCentralKitchen) {
      return i18next.t(
        'ADMIN.SUPPLIERS.EMPTY_SUPPLIER_PROFILE_CREATE_YOUR_FIRST_CENTRAL_KITCHEN_SUPPLIER_PROFILE_CONTENT',
      );
    }

    return i18next.t(
      'ADMIN.SUPPLIERS.EMPTY_SUPPLIER_PROFILE_CREATE_YOUR_FIRST_SUPPLIER_PROFILE_CONTENT',
    );
  };

  return (
    <EmptyStateContainer>
      <div>
        <EmptyStateIcon
          alt="empty-state-no-supplier-profile-icon"
          className={'center'}
          src="/images/inpulse/empty-state-supplier-profile.svg"
        />
        <TextContainer>
          <MainText>{getMainText(isCentralKitchen, isCreateActionHidden)}</MainText>
          <Text>{getText(isCentralKitchen, isCreateActionHidden)}</Text>
          {!isCreateActionHidden && (
            <Button
              buttonCustomStyle={{ margin: 'auto' }}
              color={'inpulse-default'}
              handleClick={() => handleNewSupplierProfile()}
              icon={'/images/icon-plus-white.svg'}
              label={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CREATE_SUPPLIER_PROFILE_ACTION')}
            />
          )}
        </TextContainer>
      </div>
    </EmptyStateContainer>
  );
};

export default EmptyState;
