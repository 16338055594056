import React from 'react';

import GenericForm from '@commons/GenericForm';

import { DeleteIcon, Icon, MovableColumns } from './styledComponents';

export const TableFormLineMovablePart = (props) => {
  const { form, index, fieldArrayName, deleteFunction, inputs, field, isEditionAllowed, register } =
    props;

  return (
    <MovableColumns>
      <GenericForm
        form={form}
        inputs={inputs}
        isEditionAllowed={isEditionAllowed}
        prefixInputName={`${fieldArrayName}[${index}].`} // Used to handle array form
        register={register}
      />
      {isEditionAllowed && deleteFunction && (
        <DeleteIcon
          isLoading={field.isLoading}
          onClick={() => {
            if (fieldArrayName.isLoading) {
              return;
            }

            deleteFunction(index, field);
          }}
        >
          <Icon
            alt={'cross-icon'}
            src={
              field.isLoading
                ? '/images/inpulse/close-lmgrey-small.svg'
                : '/images/inpulse/close-black-small.svg'
            }
          />
        </DeleteIcon>
      )}
    </MovableColumns>
  );
};
