import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  display: grid;

  width: 80%;
  height: 160px;

  background-color: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  padding: 24px;
  row-gap: 24px;
`;

export const Title = styled.div`
  font: ${(props) => props.theme.fonts.h2};
`;

export const TogglesContainer = styled.div`
  display: flex;
  align-items: center;

  height: 64px;
  gap: 24px;
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  :first-child {
    margin-right: 8px;
  }
`;
