import { config } from '../config';
import axios from '../core/http';
const baseApiUrl = config.baseApiUrl;

const mapAccountsToStores = (accountIds, storeIds) =>
  axios
    .post(
      baseApiUrl + '/backoffice/stores/associate/accounts',
      {
        accountIds,
        storeIds,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export default {
  mapAccountsToStores,
};
