import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${(props) =>
    props.center &&
    css`
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    `}

  .block-order-supplier {
    margin: 0 30px;
  }

  .block-order-supplier-name {
    padding: 0px 0 10px 0;

    text-transform: capitalize;
    border-bottom: 1px solid #e6e6e6;

    color: #000000;
    font: ${(props) => props.theme.fonts?.h1Inter} !important;
  }
`;

export const FormContainer = styled.div`
  width: 100%;

  ${(props) =>
    props.displayBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.expandableEmbeddedList.childRow.separator.color};
    `}

  margin-bottom: 24px;

  cursor: pointer;
`;
