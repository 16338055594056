import i18next from 'i18next';
import React from 'react';

import { getClientStoreNameTranslation } from '@commons/utils/translations';

import NextStepChoicesModal from '@admin/components/NextStepChoicesModal';

const AskSupplierProductsMappingModal = (props) => {
  const {
    includeSelectStoreToCloneStep,
    setIncludeSelectStoreToCloneStep,
    clientStoreName,
    clientStoreNamePlural,
  } = props;

  return (
    <NextStepChoicesModal
      firstChoiceSelected={includeSelectStoreToCloneStep}
      firstChoiceText={i18next.t('ADMIN.SUPPLIERS.ASK_FIRST_CHOICE', {
        storeName: getClientStoreNameTranslation(clientStoreName).toLowerCase(),
      })}
      iconSrc={'/images/inpulse/supplier-ingredients.svg'}
      secondChoiceText={i18next.t('ADMIN.SUPPLIERS.ASK_SECOND_CHOICE')}
      setFirstChoiceSelected={(value) => setIncludeSelectStoreToCloneStep(value)}
      text={i18next.t('ADMIN.SUPPLIERS.ASK_TEXT')}
      title={i18next.t('ADMIN.SUPPLIERS.ASK_TITLE', { storeName: clientStoreNamePlural })}
    ></NextStepChoicesModal>
  );
};

export default AskSupplierProductsMappingModal;
