import i18next from 'i18next';
import React from 'react';

import { Tags } from '@commons/Tags';
import { Text } from '@admin/stores/components/styledComponents';

export const getColumns = () => [
  {
    id: 'name',
    propertyKey: 'name',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_NAME'),
    render: (it) => <Text highlighted>{it}</Text>,
  },
  {
    id: 'partnerId',
    propertyKey: 'partnerId',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_ID'),
    displayBigger: true,
  },
  {
    id: 'city',
    propertyKey: 'city',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_CITY'),
    filterType: 'string',
    render: (item) => <Text>{item}</Text>,
  },
  {
    id: 'groups',
    propertyKey: 'groups',
    name: i18next.t('GENERAL.GROUPS'),
    filterType: 'string',
    render: (groups) => <Tags items={groups.map(({ name }) => name)} />,
    transform: (groups) => groups.map(({ name }) => name).join(', '),
  },
];
