import { connect } from 'react-redux';
import { isEmpty, differenceWith, isEqual } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import { Button, Franco } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { GenericModalContainer } from '@commons/Modals/GenericModal/styledComponents';
import DisplayNumber from '@commons/DisplayNumber';
import GenericModal from '@commons/Modals/GenericModal';

import { canChangeTransferDate, canCorrectTransfer } from '@selectors/actions/transferActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { getPackagingValues } from '@stocks/StocksInventories/components/StockForm/components/Content/components/StockFormRow/components/utils';
import { getTotalPrice } from '@stocks/StocksInventories/components/StockForm/utils/getPrice';
import { isEditionAllowed } from '@stocks/StocksInventories/common/rights';
import services from '@stocks/StocksInventories/components/StockForm/services';

import { STORE_LINKAGE_TYPES } from '@stocks/StocksInventories/StocksInventoriesTransfer/utils';

import EmptyState from '../EmptyState';

import { Container, SpacedDiv } from './styledComponents';
import {
  SUPPLIER_PRODUCT_STEPS,
  getModalConfig,
} from './AddSupplierProductModal/modalConfiguration';
import TransferSupplierProductsList from './TransferSupplierProductsList';

const TransferFormContent = (props) => {
  const {
    client: { clientId },
    showErrorMessage,
    transferList: {
      senderStoreId,
      recipientStoreName,
      id: inventoryTransferListId,
      createdAt,
      hasBeenReceived,
      sentAt,
    },
    setIsSaveAvailable,
    selectedSupplierProducts,
    setSelectedSupplierProducts,
    totalPrice,
    setTotalPrice,
    isCreation,
    isEditionAvailable,
    setIsEditionAvailable,
    setIsUpdatingDate,
    isUpdatingDate,
    handleTransferExportContent,
    authorizedActions,
    userStoreLinkageType,
    senderStore,
  } = props;

  const [initialSelectedSupplierProducts] = useState(selectedSupplierProducts);

  // Allows to find the supplier of a supplierProduct and show it in the modal's dropdown list
  const [suppliers, setSuppliers] = useState([]);

  const atLeastOneSP = selectedSupplierProducts.length > 0;

  const isAfter48h = !isCreation && !isEditionAllowed(createdAt);

  /*********************************/
  /** MODAL STATE **/
  /*********************************/
  const [modalParams, setModalParams] = useState(null); // For adding Supplier Products to the transfer
  const [currentStep, setCurrentStep] = useState(null);
  const [supplierProductToAdd, setSupplierProductToAdd] = useState(null);
  const [stocksOfSupplierProductToAdd, setStocksOfSupplierProductToAdd] = useState({});
  const [disableNextStep, setDisableNextStep] = useState(true);

  const setModalFirstStep = () => {
    setCurrentStep(SUPPLIER_PRODUCT_STEPS.SUPPLIER_PRODUCT_SELECTION);
  };

  const resetModalData = () => {
    setCurrentStep(null);
    setSupplierProductToAdd(null);
    setStocksOfSupplierProductToAdd({});
    setDisableNextStep(true);
  };

  const handleStocksOfSupplierProductToAdd = (packagings) => {
    setDisableNextStep(getPackagingValues(packagings, setStocksOfSupplierProductToAdd));
  };

  // Open/refresh modal
  useEffect(() => {
    if (!currentStep) {
      return;
    }

    const params = getModalConfig({
      resetModalData,
      currentStep,
      setCurrentStep,
      supplierProductToAdd,
      setSupplierProductToAdd,
      stocksOfSupplierProductToAdd,
      setStocksOfSupplierProductToAdd,
      selectedSupplierProducts,
      setSelectedSupplierProducts,
      handleStocksOfSupplierProductToAdd,
      disableNextStep,
      setDisableNextStep,
      suppliers,
      sentAt: moment(sentAt)
        .tz(senderStore.timezone)
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
    });

    // The modal's opened/closed state relies on its parameters being set.
    setModalParams(params);
  }, [currentStep, supplierProductToAdd, stocksOfSupplierProductToAdd]);

  /*********************************/

  // Retrieve the list of suppliers
  useEffect(() => {
    if (clientId) {
      (async function loadSupplierOfClient() {
        try {
          const result = await services.getSuppliersOfClient(clientId, false, true);

          setSuppliers(result);
        } catch {
          showErrorMessage(i18next.t('ORDERS.BY_CATEGORY.FETCH_SUPPLIERS_FAILURE'), 'error');
        }
      })();
    }
  }, [clientId]);

  // Retrieve the list of store supplier product mappings
  useEffect(() => {
    if (isCreation) {
      setSelectedSupplierProducts([]);
    }

    setIsSaveAvailable(false);
  }, [senderStoreId, suppliers, sentAt]);

  // Enable/disable saving depending on the selected SPs.
  useEffect(() => {
    if (isEmpty(selectedSupplierProducts)) {
      setTotalPrice(0);
      setIsSaveAvailable(false);
      return;
    }

    const total = getTotalPrice(selectedSupplierProducts);

    setTotalPrice(total);

    let hasAnySPToZero = false;
    selectedSupplierProducts.forEach(({ totByPackagingId }) => {
      if (Object.values(totByPackagingId).every(isEqualZero)) {
        hasAnySPToZero = true;
      }
    });

    if (hasAnySPToZero) {
      setIsSaveAvailable(false);
      return;
    }

    const differenceFromInitial = differenceWith(
      selectedSupplierProducts,
      initialSelectedSupplierProducts,
      isEqual,
    );

    if (
      // If editing an existing transfer, saving is not available if no change is made (i.e: changing a transfter amount / adding or removing an SP)
      !isCreation &&
      !differenceFromInitial.length
    ) {
      setIsSaveAvailable(false);
      return;
    }

    setIsSaveAvailable(true);
  }, [selectedSupplierProducts]);

  const handleDataUpdate = (supplierProductId, updatedTotByPackagingId) => {
    const updatedList = selectedSupplierProducts.map((supplierProduct) => {
      if (supplierProduct.id === supplierProductId) {
        return {
          ...supplierProduct,
          totByPackagingId: updatedTotByPackagingId,
        };
      }

      return supplierProduct;
    });

    setSelectedSupplierProducts(updatedList);
  };

  const openSupplierProductDeletionModal = (supplierProduct) => {
    const params = getModalConfig({
      currentStep: SUPPLIER_PRODUCT_STEPS.SUPPLIER_PRODUCT_DELETION,
      handleSupplierProductDeletion,
      supplierProductToDelete: supplierProduct,
    });

    setModalParams(params);
  };

  const handleSupplierProductDeletion = (supplierProduct) => {
    const updatedSelectedSupplierProducts = selectedSupplierProducts.filter(
      ({ id }) => id !== supplierProduct.id,
    );

    setSelectedSupplierProducts(updatedSelectedSupplierProducts);
  };

  const isEqualZero = (currentValue) => currentValue === 0 || currentValue == null;

  const transferActions = [
    {
      id: 'update-transfer',
      label: i18next.t('STOCKS.TRANSFERS.FORM_UPDATE_TRANSFER_BUTTON'),
      icon: '/images/inpulse/pen-ip-black-small.svg',
      handleClick: () => {
        setIsEditionAvailable(true);
      },
      isHidden:
        isEditionAvailable ||
        (!isAfter48h && !hasBeenReceived && userStoreLinkageType === STORE_LINKAGE_TYPES.SENDER) ||
        (!isEditionAvailable && !canCorrectTransfer(authorizedActions)),
    },
    {
      id: 'update-date',
      label: i18next.t('STOCKS.TRANSFERS.FORM_UPDATE_TRANSFER_DATE_BUTTON'),
      icon: '/images/inpulse/pen-ip-black-small.svg',
      handleClick: () => setIsUpdatingDate(true),
      isHidden: isUpdatingDate || !canChangeTransferDate(authorizedActions),
    },
    {
      id: 'export-transfer',
      label: i18next.t('GENERAL.EXPORT_XLS'),
      icon: '/images/inpulse/file-download-black-thick.svg',
      handleClick: () => {
        handleTransferExportContent();
      },
    },
  ];

  return (
    <Container>
      {atLeastOneSP ? (
        <div>
          <SpacedDiv>
            <Franco
              content={<DisplayNumber displayCurrencyCode={true} number={totalPrice} />}
              title={i18next.t('STOCKS.TRANSFERS.TOTAL_EXCLUDING_TAXES_WITH_DOTS')}
            />
            <Button
              actions={inventoryTransferListId ? transferActions : null}
              color={'inpulse-outline'}
              handleClick={!inventoryTransferListId ? handleTransferExportContent : null}
              icon={!inventoryTransferListId ? '/images/inpulse/file-download-black-small.svg' : ''}
              iconDropdown={
                !inventoryTransferListId ? '' : '/images/inpulse/ellipsis-black-small.svg'
              }
              isDisabled={false}
            />
          </SpacedDiv>
          <TransferSupplierProductsList
            handleDataUpdate={handleDataUpdate}
            handleDelete={openSupplierProductDeletionModal}
            readOnly={!isEditionAvailable}
            supplierProducts={selectedSupplierProducts}
          />
          <Button
            color={'inpulse-link'}
            formatText={false}
            handleClick={setModalFirstStep}
            icon={'/images/inpulse/add-black-small.svg'}
            isDisabled={!isEditionAvailable}
            label={i18next.t('GENERAL.ACTION_ADD_ITEM', {
              item: i18next.t('GENERAL.SUPPLIER_PRODUCT_LOWERCASE'),
            })}
          />
        </div>
      ) : (
        <EmptyState
          button={
            <Button
              color={'inpulse-default'}
              handleClick={setModalFirstStep}
              icon={'/images/inpulse/add-white-small.svg'}
              isDisabled={!suppliers.length}
              label={i18next.t('GENERAL.ADD')}
            />
          }
          icon="/images/inpulse/supplier-ingredients.svg"
          subtitle={i18next.t('STOCKS.TRANSFERS.ADD_SUPPLIER_PRODUCT_SUBTITLE', {
            storeName: recipientStoreName,
          })}
          title={i18next.t('STOCKS.TRANSFERS.ADD_SUPPLIER_PRODUCT_TITLE')}
        />
      )}
      {modalParams && (
        <GenericModalContainer>
          <GenericModal
            closeGenericModal={() => setModalParams(null)}
            component={modalParams.component}
            params={modalParams}
          />
        </GenericModalContainer>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/stocks/inventories/transfer',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferFormContent);
