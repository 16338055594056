import i18next from 'i18next';
import React from 'react';

import { ToggleSwitch } from '@commons/utils/styledLibraryComponents';
import Text, { ENUM_FONTS } from '@commons/Text';
import WhiteCard from '@commons/WhiteCard';

import { Content, PropertyContainer, SwitchContainer } from './styledComponents';

const SupplierProfileModalProperties = (props) => {
  const { available, handleSwitchChange, hasStock, hasDlc } = props;
  return (
    <Content>
      <WhiteCard
        renderContent={
          <PropertyContainer>
            <SwitchContainer>
              <ToggleSwitch
                checked={available}
                handleClick={() => handleSwitchChange('available')}
                id="available"
              />
              <Text font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
                {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_PROPERTIES_AVAILABLE_ORDER')}
              </Text>
            </SwitchContainer>
            <SwitchContainer>
              <ToggleSwitch
                checked={hasStock}
                handleClick={() => handleSwitchChange('hasStock')}
                id="hasStock"
              />
              <Text font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
                {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_PROPERTIES_INVENTORY_STOCKS')}
              </Text>
            </SwitchContainer>
            <SwitchContainer>
              <ToggleSwitch
                checked={hasDlc}
                handleClick={() => handleSwitchChange('hasDlc')}
                id="hasDlc"
              />
              <Text font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
                {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_PROPERTIES_INVENTORY_LOSS')}
              </Text>
            </SwitchContainer>
          </PropertyContainer>
        }
        title={i18next.t('GENERAL.PROPERTIES')}
      />
    </Content>
  );
};

export default SupplierProfileModalProperties;
