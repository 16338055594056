import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: hidden;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 24px;

  background-color: ${(props) => props.theme.colors.greys.lighter};
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;

  align-items: center;
  column-gap: 16px;

  padding: 0 24px;
`;
export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleSwitchText = styled.div`
  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.textMiddleNormal};

  margin-left: 8px;
`;

export const ChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ChartContent = styled.div`
  margin: 0 24px 0 24px;

  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  width: 100%;
`;

export const TitleChartContainer = styled.div`
  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.h1InterNonBold};

  margin: 24px 0 24px 24px;
`;

export default {
  Container,
  TitleChartContainer,
  HeaderContainer,
  ToggleContainer,
  ToggleSwitchText,
  ChartContainer,
  ChartContent,
};
