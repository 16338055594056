import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 500px;
  > div > div > div {
    overflow-x: visible;
  }
  padding-bottom: 24px;
`;
