import { connect } from 'react-redux';
import { get, head, isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, openModal, openSmallModal } from '@actions/modal';
import {
  showConfirmationMessage,
  showErrorMessage,
  showSuccessMessage,
} from '@actions/messageconfirmation';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import { COMPARE_CLAUSE, makeQueryParam } from '@commons/utils/queryCompareClause';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { DEFAULT_FULL_INVENTORY_TEMPLATE } from '@commons/constants/dropdown';
import { getUserTimezone } from '@commons/utils/date';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import EmptyState from '@commons/EmptyState';
import GeneralEmptyStateListView from '@commons/GeneralEmptyStateListView';

import {
  DEFAULT_QUERY_PARAMS,
  ENUM_QUERY_PARAMS,
  useListViewQueryParams,
} from '@hooks/useListViewQueryParams';

import {
  canCreateInitialisationInventory,
  canCreateInventory,
} from '@selectors/actions/inventoriesStockActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { brand as brandService } from '@services/brand';
import { group as groupService } from '@services/group';
import { inventoryList } from '@services/inventoryList';
import accountService from '@services/account';
import clientService from '@services/client';

import ConfirmationModal from '@orders/components/ConfirmationModal';
import DeepsightFiltersButton from '@orders/components/FilterButton';
import utilsMethods from '@orders/utils/makeXLS';

import {
  deleteInventoryListById,
  fetchInventoriesStoresCount,
  fetchPaginatedInventories,
} from '@stocks/StocksInventories/common/services';
import { getActions, getRowActions } from '@stocks/StocksInventories/common/actions';
import { getDataColumns } from '@stocks/StocksInventories/common/columns';
import {
  INVENTORY_TYPE,
  statusLegend,
  STOCK_PROPERTIES,
} from '@stocks/StocksInventories/common/constants';

import { TheoreticalInventoryProgressBar } from '../TheoreticalInventoryModal/progressBar';
import CreateInitialInventoryModal from '../InventoryCreateInitialModal';
import CreateTheoreticalInventoryModal from '../TheoreticalInventoryModal';
import InventoryExportContentModal from '../InventoryExportContentModal';
import InventoryExportListModal from '../InventoryExportListModal';
import StockForm from '../StockForm';

import { Container, EmptyStateButtonsContainer } from './styledComponents';

const ORDER_TYPE = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

const FILTER_TYPES = ['date', 'string', 'numeric', 'boolean'];

const fetchBrandsOfClient = async (clientId, showMessage) => {
  try {
    const brands = await brandService.getBrandsOfClient(clientId);

    return brands;
  } catch (err) {
    showMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_BRANDS_FAILURE'), 'error');

    return [];
  }
};

const getLocationsOfAccount = async (accountId, showMessage) => {
  try {
    const locations = await accountService.getLocationsOfAccount(accountId);

    return locations;
  } catch (err) {
    showMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_LOCATIONS_FAILURE'), 'error');

    return [];
  }
};

const fetchRetailersOfClient = async (clientId, showMessage) => {
  try {
    const retailers = await clientService.getRetailersOfClient(clientId);

    return retailers;
  } catch (err) {
    showMessage(i18next.t('STOCKS.STOCKS.FETCH_RETAILERS_ERROR'), 'error');
    return [];
  }
};

const fetchInventoryListTemplatesOfClient = async (clientId, showMessage) => {
  try {
    const templates = await clientService.getInventoryListTemplates(clientId, {
      withMappings: false,
      filterByUserCatalog: false,
    });

    return templates;
  } catch {
    showMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FETCH_INVENTORY_LIST_TEMPLATES_ERROR'), 'error');
    return [];
  }
};

const handleFetchGroupsByStoreIds = async (storeIds, showMessage, setGroups) => {
  const formattedGroups = {};

  try {
    const groupMappings = await groupService.getGroupsOfStores(storeIds);

    groupMappings.forEach((groupMapping) => {
      const groupId = groupMapping.lnkGroupStoregroupmappingrel.id;
      const groupName = groupMapping.lnkGroupStoregroupmappingrel.name;

      if (!formattedGroups[groupId]) {
        formattedGroups[groupId] = {
          groupId,
          storeIds: [],
          name: groupName,
          id: groupMapping.id,
        };
      }

      formattedGroups[groupId].storeIds.push(groupMapping.storeId);
    });
  } catch (err) {
    showMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_GROUPS_FAILURE'), 'error');
  } finally {
    setGroups(Object.values(formattedGroups));
  }
};

const InventoryListView = (props) => {
  const {
    user,
    client: { storeName, clientId, hasMultipleBrands },
    stores,
    constraints,
    stockOnlyToday,
    openModal,
    showMessage,
    pageLoading,
    pageLoaded,
    openModalExportInfo,
    inventoryType,
    showErrorMessage,
    showSuccessMessage,
    authorizedActions,
    isCentralKitchenView = false,
    inventoryValidation = false,
    theoreticalInventory = false,
  } = props;

  const accountId = get(user, 'id');

  const dataColumns = getDataColumns(storeName, isCentralKitchenView, inventoryValidation);

  const [columnsFilterList, setColumnsFilterList] = useState(
    dataColumns.filter((column) => FILTER_TYPES.includes(column.filterType)),
  );

  const listViewRef = useRef();

  const [inventories, onInventoriesChange] = useState([]);
  const [totalInventoriesCount, setTotalInventoriesCount] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [isRetrievingData, setIsRetrievingData] = useState(false);
  const [shouldRetrieveData, setShouldRetrieveData] = useState(false);

  const [locations, setLocations] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const [brands, setBrands] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const [retailers, setRetailers] = useState([]);
  const [selectedRetailers, setSelectedRetailers] = useState([]);

  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const [selectedStores, setSelectedStores] = useState([]);
  const [displayCreateInitialInventoryModal, setDisplayCreateInitialInventoryModal] =
    useState(false);
  const [displayCreateTheoreticalInventoryModal, setDisplayCreateTheoreticalInventoryModal] =
    useState(false);

  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [filters, setFilters] = useState(null);

  const [hasPastInventories, onHasPastInventories] = useState(null);

  const [inventoriesSelected, onInventoriesSelectedChange] = useState([]);

  const [listViewQueryParams, setListViewQueryParams] = useListViewQueryParams(false);

  const [clientILTs, setClientILTs] = useState([]);

  const handleDeleteInventoryList = (inventoryList) => {
    const params = {
      type: 'warning',
      width: '542px',
      height: 'auto',
      icon: '/images/inpulse/warning-white-small.svg',
      title: i18next.t('STOCKS.STOCKS.LIST_DELETE_CONFIRMATION_MODAL_TITLE'),
      component: ConfirmationModal,
      data: {
        content: i18next.t('STOCKS.STOCKS.LIST_DELETE_CONFIRMATION_MODAL_CONTENT'),
      },
      actions: [
        {
          key: 0,
          color: 'inpulse-outline',
          label: i18next.t('GENERAL.CANCEL'),
          icon: '/images/inpulse/close-black-small.svg',
        },
        {
          key: 1,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.VALIDATE'),
          icon: '/images/inpulse/check-white-small.svg',
          handleClick: async () => {
            await handleDeleteInventoryListById(
              inventoryList.id,
              props.pageLoading,
              props.pageLoaded,
              props.showMessage,
            );

            await loadInventories();
          },
        },
      ],
    };
    props.openGenericModal(params);
  };

  /**
   * Data fetch parameters
   * Not to be confused with listViewQueryParams (which are used to changed how the ListView should be sorted)
   */
  const [queryParams, setQueryParams] = useState({
    params: '',
    selectedFilters: {},
  });

  // After first render, inventoryTypeParam is added to all setQueryParams calls
  const [inventoryTypeParam, setInventoryTypeParam] = useState('');

  const handleDeleteInventoryListById = async (
    inventoryListId,
    pageLoading,
    pageLoaded,
    showMessage,
  ) => {
    pageLoading();

    try {
      await deleteInventoryListById(inventoryListId);

      showMessage(i18next.t('STOCKS.STOCKS.DELETE_SUCCESS'));
    } catch (err) {
      showMessage(i18next.t('STOCKS.STOCKS.DELETE_FAILURE'), 'error');
    } finally {
      pageLoaded();
    }
  };

  const loadInventories = async () => {
    if (hasPastInventories === false) {
      return;
    }

    setIsRetrievingData(true);

    try {
      const storeIds = selectedStores.map((store) => store.id);

      const formattedMaxPerPage =
        Number.isInteger(listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]) &&
        listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE] > 0
          ? listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]
          : DEFAULT_QUERY_PARAMS.MAX_PER_PAGE;

      const formattedCurrentPage =
        listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE] > 0
          ? (listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE] - 1) *
            (listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE] ||
              DEFAULT_QUERY_PARAMS.MAX_PER_PAGE)
          : (DEFAULT_QUERY_PARAMS.CURRENT_PAGE - 1) * DEFAULT_QUERY_PARAMS.MAX_PER_PAGE;

      const columnPropertyKeys = dataColumns.map((column) => column.propertyKey);

      const formattedOrderBy = columnPropertyKeys.includes(
        listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY],
      )
        ? listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]
        : columnPropertyKeys[0];

      const formattedOrderType =
        listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE] === ORDER_TYPE.ASCENDING ||
        listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE] === ORDER_TYPE.DESCENDING
          ? listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]
          : DEFAULT_QUERY_PARAMS.ORDER_TYPE;

      await fetchPaginatedInventories(
        storeIds,
        onInventoriesChange,
        setTotalInventoriesCount,
        listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH] || DEFAULT_QUERY_PARAMS.SEARCH,
        formattedCurrentPage,
        formattedMaxPerPage,
        formattedOrderBy,
        formattedOrderType,
        queryParams.params,
        queryParams.inventoryListTemplateIds,
      );
    } catch (err) {
      showMessage(i18next.t('STOCKS.STOCKS.LOADING_FAILURE'), 'error');
    } finally {
      setIsRetrievingData(false);
      pageLoaded();
    }
  };

  const openNewStock = () => {
    if (!stores.length) {
      return;
    }

    const currentStore = head(stores);

    const params = {
      inventory: '',
      currentStore,
      component: StockForm,
      isCentralKitchenView,
      inventoryValidation,
      storeId: currentStore.id,
      labelTopButton: i18next.t('GENERAL.LEAVE'),
      updateHandle: async () => {
        const storeIds = selectedStores.map((store) => store.id);

        const inventoriesCount = await fetchInventoriesStoresCount(storeIds);

        // Handle cases where request could fail but should not interfere with normal behaviour
        onHasPastInventories(inventoriesCount === null || inventoriesCount > 0);

        setShouldRetrieveData(true);
      },
      title: i18next.t('STOCKS.STOCKS.LIST_EDIT_MODAL_TIME'),
      stockOnlyToday,
      inventoryList: inventories,
      constraints: constraints || [],
      inventoryType: inventoryType,
    };

    openModal(params, true);
  };

  const openCreateInitialInventoryModal = () => {
    setDisplayCreateInitialInventoryModal(true);
  };

  const handleCreateInitialInventory = async (selectedStoresIds, selectedDate) => {
    pageLoading();
    try {
      const userTimezone = getUserTimezone();

      await inventoryList.createInitialInventories(
        selectedStoresIds,
        selectedDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        userTimezone,
        isCentralKitchenView,
      );
      showSuccessMessage(i18next.t('STOCKS.STOCKS.LIST_CREATE_INITIAL_INVENTORY_SUCCESS'));
      onHasPastInventories(true);
    } catch {
      showErrorMessage(i18next.t('STOCKS.STOCKS.LIST_CREATE_INITIAL_INVENTORY_ERROR'));
    } finally {
      await loadInventories();
      pageLoaded();
    }
  };

  const openCreateTheoreticalInventoryModal = () => {
    setDisplayCreateTheoreticalInventoryModal(true);
  };

  const handleCreateTheoreticalInventory = async (selectedStoresIds, selectedDate, selectedILT) => {
    const userTimezone = getUserTimezone();

    const inventoryListTemplateId =
      selectedILT.id !== DEFAULT_FULL_INVENTORY_TEMPLATE.id ? selectedILT.id : null;

    props.openModalExportInfo({
      component: TheoreticalInventoryProgressBar,
      storeIds: selectedStoresIds,
      selectedDate: selectedDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      inventoryListTemplateId,
      userTimezone,
      reloadInventories: loadInventories,
      showSuccessMessage,
      showErrorMessage,
    });
  };

  const handleEditStock = (inventory, isDateToday) => {
    const params = {
      component: StockForm,
      isCentralKitchenView,
      inventoryValidation,
      inventory: inventory,
      labelTopButton: i18next.t('GENERAL.LEAVE'),
      updateHandle: () => setShouldRetrieveData(true),
      title: i18next.t('STOCKS.STOCKS.LIST_EDIT_MODAL_TIME'),
      currentStore: { id: inventory.storeId, name: inventory.storeName },
      stockOnlyToday,
      storeId: inventory.storeId,
      constraints: constraints || [],
      isDateToday,
      inventoryType: inventory.type,
    };

    openModal(params, true);
  };

  const exportSelectedInventories = (lines) => {
    const formattedInventories = lines.map((inventory) => {
      const inventoryFormatted = { ...inventory };

      inventoryFormatted.timestamp = moment
        .tz(inventoryFormatted.timestamp, inventoryFormatted.storeTimezone)
        .format('L');
      inventoryFormatted.type = i18next.t(statusLegend[inventoryFormatted.type].name);
      inventoryFormatted.template = get(inventory, 'template.name', '');

      if (inventoryValidation && !isCentralKitchenView) {
        inventoryFormatted.isValidated = i18next.t(
          inventory.isValidated ? 'STOCKS.STOCKS.APPROVED' : 'STOCKS.STOCKS.TO_BE_APPROVED',
        );
      }

      return inventoryFormatted;
    });

    utilsMethods.makeXLS(
      i18next.t(
        `STOCKS.STOCKS.LIST_EXPORT_${inventoryType === 'loss' ? 'LOSSES' : 'STOCKS'}_TITLE`,
      ),
      formattedInventories,
      i18next.t(
        `STOCKS.STOCKS.LIST_EXPORT_${
          inventoryType === 'loss' ? 'LOSSES' : 'STOCKS'
        }_SHEET_NAME_INVENTORY_PLURAL`,
      ),
      dataColumns.filter((column) => column.name),
    );
  };

  const exportAllInventories = () => {
    props.openModalExportInfo({
      component: InventoryExportListModal,
      title: i18next.t('STOCKS.STOCKS.LIST_EXPORT_IN_PROGRESS'),
      fileName: i18next.t(
        `STOCKS.STOCKS.LIST_EXPORT_${inventoryType === 'loss' ? 'LOSSES' : 'STOCKS'}_TITLE`,
      ),
      sheetName: i18next.t(
        `STOCKS.STOCKS.LIST_EXPORT_${
          inventoryType === 'loss' ? 'LOSSES' : 'STOCKS'
        }_SHEET_NAME_INVENTORY_PLURAL`,
      ),
      columns: dataColumns.filter((column) => column.name),
      storeIds: selectedStores.map((store) => store.id),
      searchQueryParam:
        listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH] || DEFAULT_QUERY_PARAMS.SEARCH,
      queryParams,
      inventoryType,
      inventoryValidation,
      isCentralKitchenView,
    });
  };

  const exportContent = async (items) => {
    try {
      if (!items.length) {
        const columnPropertyKeys = dataColumns.map((column) => column.propertyKey);
        const formattedOrderBy = columnPropertyKeys.includes(
          listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY],
        )
          ? listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]
          : columnPropertyKeys[0];

        const formattedOrderType =
          listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE] === ORDER_TYPE.ASCENDING ||
          listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE] === ORDER_TYPE.DESCENDING
            ? listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]
            : DEFAULT_QUERY_PARAMS.ORDER_TYPE;

        const fetchAllInventories = await inventoryList.getPaginatedInventoriesListOfStores(
          selectedStores.map((store) => store.id),
          listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH] || DEFAULT_QUERY_PARAMS.SEARCH,
          0,
          0,
          formattedOrderBy,
          formattedOrderType,
          queryParams.params,
        );

        items = fetchAllInventories.inventories;
      }

      openModalExportInfo({
        inventories: items,
        isCentralKitchenView,
        component: InventoryExportContentModal,
        title: i18next.t('STOCKS.STOCKS.LIST_EXPORT_IN_PROGRESS'),
        fileName: i18next.t(
          `STOCKS.STOCKS.LIST_EXPORT_${
            inventoryType === 'loss' ? 'LOSSES' : 'STOCKS'
          }_SHEET_NAME_INVENTORY_PLURAL`,
        ),
        sheetName: i18next.t(
          `STOCKS.STOCKS.LIST_EXPORT_${
            inventoryType === 'loss' ? 'LOSSES' : 'STOCKS'
          }_SHEET_NAME_INVENTORY_PLURAL`,
        ),
      });
    } catch {
      props.showMessage(i18next.t('STOCKS.STOCKS.LOADING_FAILURE'), 'error');
    }
  };

  const setDefaultQueryParam = () => {
    if (!listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]) {
      setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]('desc');
    }

    if (!listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]) {
      setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]('timestamp');
    }
  };

  const updateAdvancedFilters = async () => {
    let updatedAdvancedFilters = columnsFilterList;

    if (clientILTs) {
      const choices = clientILTs.map(({ id, name }, index) => ({
        id: index,
        name,
        inventoryListTemplateId: id,
        inventoryListTemplateName: name,
      }));

      choices.push({
        id: -1,
        name: i18next.t('STOCKS.STOCKS.FORM_FULL_INVENTORY_TEMPLATE'),
        inventoryListTemplateId: null,
        inventoryListTemplateName: null,
      });

      updatedAdvancedFilters.push({
        id: 'template',
        propertyKey: 'template',
        filterType: 'string',
        name: i18next.t('GENERAL.INVENTORY_TEMPLATE'),
        list: choices,
      });
    }

    setColumnsFilterList(updatedAdvancedFilters);
  };

  const handleValidationOrInvalidation = async (selectedInventoryLists, isValidated) => {
    pageLoading();

    const inventoryListIds = selectedInventoryLists.map(({ id }) => id);

    try {
      await inventoryList.updateInventoryListsValidation(inventoryListIds, isValidated);

      await loadInventories();

      showSuccessMessage(
        isValidated
          ? i18next.t('STOCKS.STOCKS.VALIDATE_SUCCESS', { count: inventoryListIds.length })
          : i18next.t('STOCKS.STOCKS.INVALIDATE_SUCCESS', { count: inventoryListIds.length }),
      );
    } catch {
      showErrorMessage(
        isValidated
          ? i18next.t('STOCKS.STOCKS.VALIDATE_ERROR', { count: inventoryListIds.length })
          : i18next.t('STOCKS.STOCKS.INVALIDATE_ERROR', { count: inventoryListIds.length }),
      );
    } finally {
      pageLoaded();
    }
  };

  useEffect(() => {
    setDefaultQueryParam();
  }, []);

  const [actions, setActions] = useState([]);

  const rowActions = getRowActions({
    exportContent,
    exportList: (listItem) => exportSelectedInventories(listItem),
    deleteInventory: handleDeleteInventoryList,
    authorizedActions,
    isCentralKitchenView,
    handleValidationOrInvalidation,
    inventoryValidation,
  });

  // At first render, set the type of inventories to retrieve every time.
  useEffect(() => {
    const typeQueryParam = makeQueryParam(
      STOCK_PROPERTIES.TYPE,
      COMPARE_CLAUSE.INCLUDING,
      inventoryType,
    );

    setQueryParams({
      ...queryParams,
      selectedFilters: {},
      params: typeQueryParam,
    });
    setInventoryTypeParam(typeQueryParam);
  }, []);

  useEffect(() => {
    if (isEmpty(stores)) {
      return;
    }

    pageLoading();

    const storeIds = stores.map((store) => store.id);

    const actions = getActions({
      openNewStock,
      exportContent,
      exportList: inventoriesSelected.length
        ? () => exportSelectedInventories(inventoriesSelected)
        : exportAllInventories,
      deleteInventory: handleDeleteInventoryList,
      selectedItems: inventoriesSelected,
      openCreateInitialInventoryModal,
      authorizedActions,
      isCentralKitchenView,
      inventoryValidation,
      handleValidationOrInvalidation,
      theoreticalInventory,
      openCreateTheoreticalInventoryModal,
    });

    setActions(actions);

    (async function load() {
      const inventoriesCount = await fetchInventoriesStoresCount(storeIds);

      // Handle cases where request could fail but should not interfere with normal behaviour
      onHasPastInventories(inventoriesCount === null || inventoriesCount > 0);

      await handleFetchGroupsByStoreIds(storeIds, showMessage, setGroups);

      if (hasMultipleBrands) {
        const result = await fetchBrandsOfClient(clientId, showMessage);

        setBrands(result);
      }

      const fetchedLocations = await getLocationsOfAccount(accountId, showMessage);
      setLocations(fetchedLocations);

      const retailersOfClient = await fetchRetailersOfClient(clientId, showMessage);

      setRetailers(retailersOfClient);

      setSelectedStores(stores);

      const inventoryTemplatesOfClients = await fetchInventoryListTemplatesOfClient(
        clientId,
        showMessage,
      );

      setClientILTs(inventoryTemplatesOfClients);

      pageLoaded();
      setIsLoading(false);
    })();
  }, [stores.length]);

  useEffect(() => {
    const actions = getActions({
      openNewStock,
      exportContent,
      exportList: inventoriesSelected.length
        ? () => exportSelectedInventories(inventoriesSelected)
        : exportAllInventories,
      deleteInventory: handleDeleteInventoryList,
      selectedItems: inventoriesSelected,
      openCreateInitialInventoryModal,
      authorizedActions,
      isCentralKitchenView,
      inventoryValidation,
      handleValidationOrInvalidation,
      theoreticalInventory,
      openCreateTheoreticalInventoryModal,
    });

    setActions(actions);
  }, [inventoriesSelected, selectedStores]);

  useEffect(() => {
    if (!shouldRetrieveData || isRetrievingData) {
      return;
    }

    (async function loadData() {
      await loadInventories();

      onInventoriesSelectedChange([]);

      setShouldRetrieveData(false);
    })();
  }, [shouldRetrieveData]);

  useEffect(() => {
    if (!shouldRetrieveData && selectedStores.length) {
      setShouldRetrieveData(true);
    }
  }, [
    listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH],
    listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY],
    listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE],
    listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE],
    queryParams,
    selectedStores,
  ]);

  useEffect(() => {
    if (!applyFilters || !listViewRef || !listViewRef.current) {
      return;
    }

    const selectedFilters = {};
    selectedFilters.groups = selectedGroups;
    selectedFilters.stores = selectedStores;

    if ((!advancedFilters || !advancedFilters.length) && applyFilters) {
      setQueryParams({
        ...queryParams,
        selectedFilters,
        params: inventoryTypeParam,
        inventoryListTemplateIds: [],
      });
      setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](1);

      listViewRef.current.resetPagination();
      return;
    }

    let inventoryListTemplateIds = [];

    const params =
      advancedFilters.reduce((result, { getQueryParam, propertyKey, value, values }) => {
        if (propertyKey === 'template') {
          inventoryListTemplateIds = values.map(
            ({ inventoryListTemplateId }) => inventoryListTemplateId,
          );

          return result;
        }

        result += getQueryParam(propertyKey, value);

        return result;
      }, '') + inventoryTypeParam;

    setQueryParams({
      ...queryParams,
      selectedFilters,
      params,
      inventoryListTemplateIds,
    });
    setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](1);

    listViewRef.current.resetPagination();
  }, [advancedFilters, applyFilters, selectedGroups, selectedStores]);

  useEffect(() => {
    if (isEmpty(clientILTs)) {
      return;
    }
    updateAdvancedFilters();
  }, [clientILTs]);

  if (isLoading) {
    return <div></div>;
  }

  if (!hasPastInventories) {
    return (
      <Container>
        <GeneralEmptyStateListView
          icon={'/images/inpulse/inventory.svg'}
          renderAction={() => (
            <EmptyStateButtonsContainer>
              {canCreateInventory(authorizedActions) && (
                <Button
                  color={'inpulse-default'}
                  handleClick={openNewStock}
                  icon={'/images/inpulse/add-white-small.svg'}
                  label={i18next.t('STOCKS.STOCKS.EMPTY_LIST_CREATE_YOUR_FIRST_INVENTORY_ACTION')}
                />
              )}
              {canCreateInitialisationInventory(authorizedActions) && (
                <Button
                  color={'inpulse-default'}
                  handleClick={() => openCreateInitialInventoryModal()}
                  icon={'/images/inpulse/add-white-small.svg'}
                  label={i18next.t('STOCKS.STOCKS.LIST_CREATE_INITIAL_INVENTORY')}
                />
              )}
            </EmptyStateButtonsContainer>
          )}
          subtitle={i18next.t('STOCKS.STOCKS.EMPTY_LIST_CREATE_YOUR_FIRST_INVENTORY_CONTENT')}
          title={i18next.t('STOCKS.STOCKS.EMPTY_LIST_CREATE_YOUR_FIRST_INVENTORY_TITLE')}
        />
        {displayCreateInitialInventoryModal && (
          <CreateInitialInventoryModal
            handleCreateInitialInventory={handleCreateInitialInventory}
            resetCreateInitialInventoryModal={() => setDisplayCreateInitialInventoryModal(false)}
          />
        )}
      </Container>
    );
  }

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  return (
    <Container>
      <ListView
        actionOnClick={(inventory) =>
          handleEditStock(inventory, moment().isSame(inventory.createdAt, 'day'))
        }
        actions={actions}
        columns={dataColumns}
        countElements={totalInventoriesCount}
        data={inventories}
        defaultCurrentPage={listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE]}
        defaultMaxPerPage={listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]}
        defaultOrderBy={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY] || 'timestamp'}
        defaultOrderType={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE] || 'desc'}
        defaultSearchInput={listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH]}
        handleCurrentPageChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](input)
        }
        handleMaxPerPageChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE](input)
        }
        handleOrderByChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY](input)}
        handleOrderTypeChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE](input)
        }
        handleSearchInputChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.SEARCH](input)}
        hideAllPerPageOption={true}
        isLoading={isRetrievingData}
        languageCode={userLanguageCode}
        padding={STANDARD_LISTVIEW_PADDING}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        queryParams={queryParams}
        ref={listViewRef}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => (
          <DeepsightFiltersButton
            advancedFilters={advancedFilters}
            applyFilters={applyFilters}
            brands={brands}
            columnsFilterList={columnsFilterList}
            filters={filters}
            groups={groups}
            isLoading={isRetrievingData}
            locations={locations}
            readOnly={isRetrievingData}
            retailers={retailers}
            selectedBrands={selectedBrands}
            selectedGroups={selectedGroups}
            selectedLocations={selectedLocations}
            selectedRetailers={selectedRetailers}
            selectedStores={selectedStores}
            setAdvancedFilters={setAdvancedFilters}
            setApplyFilters={setApplyFilters}
            setFilters={setFilters}
            setSelectedBrands={setSelectedBrands}
            setSelectedGroups={setSelectedGroups}
            setSelectedLocations={setSelectedLocations}
            setSelectedRetailers={setSelectedRetailers}
            setSelectedStores={setSelectedStores}
            stores={stores}
            textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
          />
        )}
        rowActions={rowActions}
        setSelectedItems={(items) => onInventoriesSelectedChange(items)}
        onQueryParamsChange={setQueryParams}
      />
      {displayCreateInitialInventoryModal && (
        <CreateInitialInventoryModal
          handleCreateInitialInventory={handleCreateInitialInventory}
          resetCreateInitialInventoryModal={() => setDisplayCreateInitialInventoryModal(false)}
        />
      )}
      {displayCreateTheoreticalInventoryModal && (
        <CreateTheoreticalInventoryModal
          handleCreateTheoreticalInventory={handleCreateTheoreticalInventory}
          resetCreateTheoreticalInventoryModal={() =>
            setDisplayCreateTheoreticalInventoryModal(false)
          }
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/stocks/inventories/stocks',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (params, fullscreen) => {
    dispatch(openModal(params, fullscreen));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

InventoryListView.propTypes = {
  inventoryType: PropTypes.oneOf(Object.values(INVENTORY_TYPE)),
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryListView);
