import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

import {
  Container,
  TitleContainer,
  CloseContainer,
  ContentContainer,
  Title,
  ButtonsContainer,
  CloseIcon,
  Icon,
  ContentBody,
} from './styledComponents';

const MappingsErrorModal = ({ closeModal, params }) => {
  const { title, content, buttonLabel } = params;

  return (
    <Container>
      <TitleContainer>
        <Title>{title}</Title>
        <CloseContainer>
          <CloseIcon onClick={() => closeModal()}>
            <Icon src={'/images/inpulse/close-white-small.svg'} />
          </CloseIcon>
        </CloseContainer>
      </TitleContainer>
      <ContentContainer>
        <ContentBody>{content}</ContentBody>
        <ButtonsContainer>
          <Button
            handleClick={() => closeModal()}
            icon={'/images/inpulse/check-white-small.svg'}
            label={buttonLabel}
          />
        </ButtonsContainer>
      </ContentContainer>
    </Container>
  );
};

export default MappingsErrorModal;
