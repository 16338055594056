import PropTypes from 'prop-types';
import React from 'react';

import { Container, ErrorText, InputContainer } from './styledComponents';

import { Input } from '../../../lib/inpulse/Input';

const InputContentModal = ({
  label,
  value,
  onChange,
  placeholder,
  errorMessage,
  type,
  autoFocus,
}) => (
  <Container>
    <InputContainer>
      <Input
        autoFocus={!!autoFocus}
        error={!!errorMessage}
        label={label}
        placeholder={placeholder}
        type={type}
        value={value}
        width={240}
        onChange={(e) => onChange(e.target.value)}
      />
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </InputContainer>
  </Container>
);

InputContentModal.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
};

InputContentModal.defaultProps = {
  type: 'text',
};

export default InputContentModal;
