import * as yup from 'yup';
import i18next from 'i18next';

import { INPUT_TYPES } from '@commons/constants/inputType';
import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';

const SUPPLIER_INPUTS = {
  // Informations
  NAME: 'name',
  CATEGORY: 'category',
  CODE: 'code',
  SIREN: 'siren',
  CURRENCY: 'currency',
  CATALOG: 'catalog',
  // Location
  ADDRESS: 'adress',
  POST_CODE: 'postCode',
  CITY: 'city',
  COUNTRY: 'country',
};

export const SUPPLIER_INFORMATIONS_INPUTS = (props) => {
  const {
    userHasRoleInpulse,
    categories,
    handleCategoryCreation,
    hasMultipleCurrencies,
    hasLocalCatalogs,
    catalogs,
    isKitchen,
    areInputsDisabled,
  } = props;

  const inputs = [
    {
      type: INPUT_TYPES.TEXT,
      name: SUPPLIER_INPUTS.NAME,
      label: `* ${i18next.t('GENERAL.NAME')}`,
      width: INPUT_WIDTH.LARGE,
      isRequired: true,
      isDisabled: () => isKitchen || areInputsDisabled,
      rule: yup.string().required(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
          field: i18next.t('GENERAL.NAME'),
        }),
      ),
    },
    {
      type: INPUT_TYPES.SINGLE_SELECT,
      name: SUPPLIER_INPUTS.CATEGORY,
      label: i18next.t('GENERAL.CATEGORY'),
      width: INPUT_WIDTH.LARGE,
      items: categories,
      isDisabled: () => isKitchen || areInputsDisabled,
      button: {
        id: 1,
        text: i18next.t('GENERAL.LIST_CREATE_CATEGORY'),
        handleClick: () => {
          handleCategoryCreation(true);
        },
        iconSrc: '/images/inpulse/add-black-small.svg',
      },
    },
    {
      type: INPUT_TYPES.TEXT,
      name: SUPPLIER_INPUTS.CODE,
      label: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_SUPPLIER_CODE'),
      width: INPUT_WIDTH.LARGE,
      isRequired: true,
      isDisabled: () => true,
    },
    {
      type: INPUT_TYPES.TEXT,
      name: SUPPLIER_INPUTS.SIREN,
      label: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_SIREN'),
      width: INPUT_WIDTH.LARGE,
      isRequired: true,
      isDisabled: () => isKitchen || areInputsDisabled,
      tooltipText: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_SIREN_TOOLTIP'),
    },
  ];

  if (hasLocalCatalogs) {
    inputs.push({
      type: INPUT_TYPES.SINGLE_SELECT,
      name: SUPPLIER_INPUTS.CATALOG,
      label: i18next.t('GENERAL.CATALOG'),
      width: INPUT_WIDTH.LARGE,
      items: catalogs,
      isRequired: true,
      isDisabled: () => !userHasRoleInpulse,
    });
  }

  if (hasMultipleCurrencies) {
    inputs.push({
      type: INPUT_TYPES.SINGLE_SELECT,
      name: SUPPLIER_INPUTS.CURRENCY,
      label: i18next.t('GENERAL.CURRENCY'),
      width: INPUT_WIDTH.LARGE,
      isDisabled: () => true,
    });
  }

  return inputs;
};

export const SUPPLIER_LOCATION_INPUTS = (props) => {
  const { areInputsDisabled, isKitchen } = props;

  return [
    {
      type: INPUT_TYPES.TEXT,
      name: SUPPLIER_INPUTS.ADDRESS,
      label: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_ADDRESS'),
      width: INPUT_WIDTH.LARGE,
      isRequired: true,
      isDisabled: () => isKitchen || areInputsDisabled,
    },
    {
      type: INPUT_TYPES.TEXT_NUMBER,
      name: SUPPLIER_INPUTS.POST_CODE,
      label: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_POSTAL_CODE'),
      width: INPUT_WIDTH.LARGE,
      isRequired: true,
      isDisabled: () => isKitchen || areInputsDisabled,
    },
    {
      type: INPUT_TYPES.TEXT,
      name: SUPPLIER_INPUTS.CITY,
      label: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CITY'),
      width: INPUT_WIDTH.LARGE,
      isRequired: true,
      isDisabled: () => isKitchen || areInputsDisabled,
    },
    {
      type: INPUT_TYPES.TEXT,
      name: SUPPLIER_INPUTS.COUNTRY,
      label: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_COUNTRY'),
      width: INPUT_WIDTH.LARGE,
      isRequired: true,
      isDisabled: () => isKitchen || areInputsDisabled,
    },
  ];
};

export const SUPPLIER_FORM_INPUTS = (props) => [
  ...SUPPLIER_INFORMATIONS_INPUTS(props),
  ...SUPPLIER_LOCATION_INPUTS(props),
];
