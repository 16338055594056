const theme = {
  borders: {
    radius: {
      strong: '16px',
      stronger: '32px',
      weak: '8px',
      weaker: '4px',
    },
  },
  buttons: {
    dropdown: {
      background: 'white',
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgb(0 0 0 / 16%)',
      default: {
        background: 'white',
        color: '#1d1d1b',
      },
      disabled: {
        background: 'white',
        color: '#979797',
      },
      font: 'normal 500 14px/16px inter',
      highlighted: {
        background: '#1D1D1B',
        color: '#ffffff',
      },
      hover: {
        background: '#F8F8F8',
        color: '#1d1d1b',
      },
    },
    primary: {
      default: '#1D1D1B',
      disabled: '#E2E2E2',
      height: '40px',
      hover: '#606060',
      padding: '0px 24px',
      pressed: '#26E888',
      radius: '20px',
      text: '#FFFFFF',
      black: '#000000',
    },
    quaternary: {
      default: {
        background: '#1D1D1B',
        text: '#FFFFFF',
      },
      disabled: {
        background: '#E2E2E2',
        text: '#FFFFFF',
      },
      hover: {
        background: '#26E888',
        text: '#FFFFFF',
      },
      pressed: {
        background: '#15C26D',
        text: '#FFFFFF',
      },
      radius: '20px',
    },
    secondary: {
      default: {
        background: '#FFFFFF',
        border: '2px solid #1D1D1B',
        text: '#1D1D1B',
      },
      disabled: {
        background: '#FFFFFF',
        border: '2px solid #E2E2E2',
        text: '#E2E2E2',
      },
      hover: {
        background: '#FFFFFF',
        border: '2px solid #606060',
        text: '#606060',
      },
      pressed: {
        background: '#1D1D1B',
        text: '#FFFFFF',
      },
      radius: '20px',
    },
    tertiary: {
      default: {
        background: '#E2E2E2',
        text: '#1D1D1B',
      },
      disabled: {
        background: '#E2E2E2',
        text: '#FFFFFF',
      },
      hover: {
        background: '#979797',
        text: '#1D1D1B',
      },
      pressed: {
        background: '#5C5C5C',
        text: '#FFFFFF',
      },
      radius: '22px',
    },
  },
  checkbox: {
    borderRadius: '4px',
    checked: {
      backgroundColor: '#1D1D1B',
      borderColor: '#1D1D1B',
    },
    checkedValidation: {
      backgroundColor: '#E2E2E2',
      borderColor: '#E2E2E2',
    },
    default: {
      backgroundColor: 'transparent',
      borderColor: '#606060',
    },
    checkDisabled: {
      borderColor: '#E2E2E2',
      backgroundColor: '#E2E2E2',
    },
    disabled: {
      backgroundColor: '#E2E2E2',
      borderColor: '#979797',
    },
    hover: {
      backgroundColor: '#DCFBEC',
    },
    icon: {
      width: '8px',
    },
    size: '42px !important',
  },
  colors: {
    black: '#000000',
    blacks: {
      ipBlack1: '#222321',
      ipBlack3: '#464745',
      ipBlack4: '#6D706B',
      ipBlack5: '#919390',
    },
    boxBackgrounds: {
      blue: '#BAF7F6',
      green: '#BEFFE0',
      greenLight: '#CCF2F2',
      lightGrey: '#F3F3F3',
      red: '#FFEEEE',
    },
    brand: {
      primary: '#26E888',
      primaryDark: '#15C26D',
      primaryDarker: '#0FC07E',
      primaryLight: '#D1FFE9',
      primaryLighter: '#E2F9F9',
      primaryLightOpacity: '#DCFBECB0',
      secondary: '#00E7E3',
      secondaryDark: '#17CFCA',
      secondaryLight: '#CCF2F2',
    },
    green: {
      light: '#2CD696',
      lighter: '#00EA84',
    },
    greys: {
      blue: '#6B768C',
      dark: '#979797',
      darker: '#606060',
      darkest: '#1D1D1B',
      light: '#E2E2E2',
      lighty: '#E3E3E2',
      lighter: '#F8F8F8',
      lightest: '#FFFFFF',
      lightMedium: '#E8E8E8',
      medium: '#D7D7D7',
    },
    infoBlue: '#CCF2F2',
    highlightedBlue: '#00E7E3',
    infoDarkGreen: '#15C26D',
    infoGreen: '#05E08E',
    infoLightOrange: '#FFF1D4',
    infoLightRed: '#FFE0E0',
    infoOrange: '#FF9432',
    infoRed: '#FA393E',
    lgrey: '#F8F8F8',
    dgrey: '#919390',
    modalHeaders: {
      green: {
        background: '#DCFBEC',
        text: '#1D1D1B',
      },
      orange: {
        background: '#FFF3DA',
        text: '#D07F06',
      },
      red: {
        background: '#FFF0F0',
        text: '#EF252A',
      },
    },
    orderChart: {
      green: '#21D692',
    },
    red: '#EF252A',
    white: '#FFFFFF',
    blue: '#039BE5',
  },
  expandableEmbeddedList: {
    black: '#000000',
    childRow: {
      backgroundColor: '#FAFAFA',
      border: 'none',
      borderRadius: '8px',
      color: '#606060',
      font: 'normal 500 14px/28px inter',
      link: {
        color: '#1D1D1B',
      },
      separator: {
        color: '#E6E6E6',
      },
    },
    customModal: {
      backgroundColor: '#ffffff',
      icon: {
        borderRadius: '4px',
        color: 'dark',
        default: {
          backgroundColor: 'transparent',
        },
        hover: {
          backgroundColor: '#E2E2E2',
        },
        selected: {
          backgroundColor: '#1D1D1B',
        },
      },
      tooltipSize: '16px',
    },
    graphModal: {
      backgroundColor: '#ffffff',
      bar: {
        border: '1px solid #E2E2E2',
        defaultColor: '#E2E2E2',
        highlightedColor: '#27E7E2',
      },
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      icon: {
        color: 'dark',
      },
      label: {
        color: '#979797',
        font: 'normal 400 10px/12px inter',
      },
      padding: '0px 50px',
      subtitle: {
        color: '#606060',
        font: 'normal 500 12px/16px inter',
      },
      title: {
        color: '#1D1D1B',
        font: 'normal 500 14px/16px inter',
      },
      value: {
        defaultColor: '#606060',
        font: 'normal 500 10px/12px inter',
        highlightedColor: '#1D1D1B',
      },
    },
    green: '#12d19c',
    header: {
      default: {
        color: '#606060',
        font: 'normal 500 14px/18px inter',
      },
      icon: 'dark',
      selected: {
        color: '#1D1D1B',
        font: 'normal 700 14px/18px inter',
      },
    },
    red: '#ef2b2d',
    row: {
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
      color: '#1D1D1B',
      font: 'normal 500 14px/28px inter',
      highlight: {
        color: '#1D1D1B',
        font: 'normal 700 14px/28px inter',
      },
      hover: {
        border: '1px solid transparent',
      },
    },
    toggle: {
      default: {
        backgroundColor: '#F8F8F8',
      },
      picture: {
        closed: 'dark',
        opened: 'dark',
        size: {
          height: '7px',
          width: '12px',
        },
      },
      selected: {
        backgroundColor: '#F8F8F8',
      },
      size: {
        height: '40px',
        width: '40px',
      },
    },
  },
  fonts: {
    families: {
      primary: 'inter',
      secondary: 'inter',
    },
    h1: 'normal 700 20px/28px inter-bold',
    h1InterNonBold: 'normal 700 20px/24px inter',
    h1Inter: 'normal 700 20px/24px inter-bold',
    h2: 'normal 700 16px/24px inter-bold',
    h2Inter: 'normal 700 16px/24px inter',
    h3: 'normal 600 14px/24px inter-bold',
    h3Bold700: 'normal 700 14px/24px inter-bold',
    h3Inter: 'normal 700 14px/24px inter',
    h4: 'normal 600 12px/24px inter',
    h5: 'normal 400 14px/24px inter',
    h6: 'normal 400 16px/20px inter-bold',
    kpiBig: 'normal 600 20px/24.2px inter',
    kpiSmall: 'normal 600 14px/17px inter',
    kpiSmallHeight23: 'normal 600 14px/23px inter',
    textBig: 'normal 500 14px/28px inter',
    textBigBold: 'normal bold 14px/28px inter',
    textBigBoldHeight16: 'normal bold 16px/16px inter',
    textBigBoldHeight14: 'normal 700 14px/14px inter-bold',
    textBigHeight14: 'normal 500 14px/14px inter',
    textBigHeight16: 'normal 500 14px/16px inter',
    textBigHeight16Bold: 'normal 500 14px/16px inter-bold',
    textBigHeight20: 'normal 500 14px/20px inter',
    textBigHeight24: 'normal 500 14px/24px inter',
    textBigHeight24Bold: 'normal 700 14px/20px inter-bold',
    textBigHeight26: 'normal 500 14px/26px inter',
    textMicro: 'normal 500 10px/20px inter',
    textMicroWidth8Height10: 'normal 500 8px/10px inter',
    textMicroHeight12: 'normal 500 10px/12px inter',
    textMicroHeight16: 'normal 500 10px/16px inter',
    textMicroHeight16Weight700Bold: 'normal 700 10px/16px inter-bold',
    textMicroWidth10Height16Weight700Bold: 'normal 700 10px/16px inter-bold',
    textMicroWidth8Height16Weight700Bold: 'normal 700 8px/16px inter-bold',
    textMicroWeight400: 'normal 400 10px/20px inter',
    textMicroHeight12Weight500: 'normal 500 12px/10px inter',
    textMicroHeight12Weight700Bold: 'normal 700 12px/10px inter-bold',
    textMiddle: 'normal 400 14px/16px inter',
    textMiddleBold: 'normal 700 14px/16px inter',
    textMiddleInterBold: 'normal 700 14px/16px inter-bold',
    textMiddleNormal: 'normal 500 14px/16px inter',
    textSmall: 'normal 500 12px/20px inter',
    textSmallBold: 'normal 700 12px/20px inter',
    textSmallHeight14Weight700Bold: 'normal 700 12px/14px inter-bold',
    textSmallHeight12: 'normal 500 12px/12px inter',
    textSmallHeight14: 'normal 500 12px/14px inter',
    textSmallHeight14Weight600: 'normal 600 12px/14px inter',
    textSmallHeight14Weight700: 'normal 700 12px/14px inter-bold',
    textSmallHeight16: 'normal 500 12px/16px inter',
    textSmallHeight24: 'normal 500 12px/24px inter',
    textSmallWeight600: 'normal 600 12px/20px inter',
    textSmallWeight600Height16: 'normal 600 12px/16px inter',
    textSmallWeight700Height16Bold: 'normal 700 12px/16px inter-bold',
    title: 'normal 700 32px/28px inter-bold',
    textBigMicroBold: 'normal 700 12px/12px inter-bold',
    chartLabel: 'normal 500 10px/16px inter',
    titleHeight24Bold: 'normal 700 24px/24px inter-bold',
    titleSmallHeight10: 'normal 700 10px/10px inter',
  },
  inputs: {
    primary: {
      default: {
        border: '1px solid #E2E2E2',
        borderRadius: '8px',
        height: '40px',
        padding: '0px 16px',
      },
      error: {
        border: '1px solid #ef2b2d',
        color: '#ef2b2d',
      },
      focus: {
        border: '1px solid #1D1D1B',
      },
    },
  },
  label: {
    font: 'normal 600 12px/16px inter',
    loading: {
      background: '#F8F8F8',
      color: '#979797',
    },
    padding: '4px 8px',
    text: {
      font: 'normal 600 12px/16px inter',
      colors: {
        white: '#FFFFFF',
        DMGrey: '#979797',
        LMGrey: '#E2E2E2',
      },
    },
    colors: {
      infoRed: '#FA393E',
      infoGreen: '#05E08E',
      DMGrey: '#979797',
      LMGrey: '#E2E2E2',
    },
    borders: {
      radius: '4px',
      width: '2px',
    },
  },
  listView: {
    content: {
      height: 'calc(100% - 45px)',
    },
    dropdown: {
      borderRadius: '8px',
      default: {
        backgroundColor: '#FFFFFF',
        color: '#1D1D1B',
      },
      disabled: {
        backgroundColor: '#ffffff',
        color: '#E2E2E2',
      },
      font: 'normal 500 14px/16px inter',
      highlighted: {
        backgroundColor: '#1D1D1B',
        color: '#ffffff',
      },
      hover: {
        backgroundColor: '#F8F8F8',
        color: '#1D1D1B',
      },
      tooltip: {
        color: '#FFFFFF',
        backgroundColor: '#979797',
        font: 'normal 500 12px/14px inter',
      },
    },
    header: {
      default: {
        color: '#606060',
        font: 'normal 500 14px/20px inter',
      },
      icon: 'dark',
      selected: {
        color: '#1D1D1B',
        font: 'normal 700 14px/20px inter',
      },
    },
    item: {
      borderRadius: '8px',
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15);',
      default: {
        backgroundColor: '#ffffff',
        border: '1px solid transparent',
      },
      font: 'normal 500 14px/16px inter',
      hover: {
        backgroundColor: '#ffffff',
        border: '1px solid #1D1D1B',
      },
      disabled: {
        backgroundColor: '#E2E2E2',
      },
      icon: 'dark',
      loading: {
        backgroundColor: 'rgba(168, 177, 196, 0.2)',
        border: '1px solid transparent',
        borderRadius: '3px',
      },
      selected: {
        backgroundColor: '#F8F8F8',
        border: '1px solid #1D1D1B',
      },
      inLineAction: {
        backgroundColor: '#1d1d1b',
        hover: {
          backgroundColor: '#26E888',
        },
        selected: {
          backgroundColor: '#15C26D',
        },
      },
    },
    otherAction: {
      color: 'inpulse-outline',
    },
    pagination: {
      color: '#606060',
      backgroundColor: '#ffffff',
      boxShadow: '0px -3px 5px rgba(0, 0, 0, 0.05)',
      dropdown: {
        backgroundColor: '#ffffff',
        border: '1px solid #E2E2E2',
        borderRadius: '20px',
        boxShadow: 'none',
        default: {
          color: '#606060',
          font: 'normal 500 10px/12px inter',
        },
        disabledBackgroundColor: '#F8F8F8',
        list: {
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
        },
        selected: {
          color: '#1D1D1B',
          font: 'normal 500 10px/12px inter',
        },
      },
      font: 'normal 500 10px/12px inter',
      icon: 'dark',
      switch: {
        backgroundColor: '#ffffff',
        border: '1px solid #E2E2E2',
        borderRadius: '20px',
        boxShadow: 'none',
      },
    },
  },
  radioButton: {
    borderColor: '#606060',
    borderSize: '1px',
    hover: {
      backgroundColor: '#DCFBEC',
    },
    selected: {
      borderSize: '2px',
      color: '#1D1D1B',
    },
    disabled: {
      color: '#E2E2E2',
      borderSize: '2px',
    },
    size: {
      innerCircleDefault: '16px',
      innerCircleSmall: '10px',
      outerCircleDefault: '20px',
      outerCircleSmall: '18px',
    },
  },
  searchbar: {
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    default: {
      backgroundColor: '#ffffff',
      color: '#1D1D1B',
    },
    disabled: {
      backgroundColor: '#E2E2E2',
      color: '#FFFFFF',
    },
    font: 'normal 500 12px/16px inter',
    icon: {
      size: '16px',
    },
    placeholder: {
      color: '#979797',
    },
  },
  shadows: {
    short: '0 2px 6px 0 rgba(0, 0, 0, 0.1)',
  },
  tableView: {
    borderRadius: '8px',
    colors: {
      highlighted: '#1D1D1B',
      primary: '#1D1D1B',
      secondary: '#606060',
      selected: '#1D1D1B',
    },
    fonts: {
      bold: 'normal 700 14px/18px inter',
      micro: 'normal 500 10px/12px inter',
      normal: 'normal 500 14px/18px inter',
      small: 'normal 500 12px/14px inter',
    },
    pagination: {
      color: '#606060',
      dropdown: {
        backgroundColor: '#ffffff',
        border: '1px solid #E2E2E2',
        borderRadius: '20px',
        boxShadow: 'none',
        default: {
          color: '#606060',
          font: 'normal 500 10px/12px inter',
        },
        disabledBackgroundColor: '#F8F8F8',
        list: {
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
        },
        selected: {
          color: '#1D1D1B',
          font: 'normal 500 10px/12px inter',
        },
      },
      font: 'normal 500 10px/12px inter',
      icon: 'dark',
      switch: {
        backgroundColor: '#ffffff',
        border: '1px solid #E2E2E2',
        borderRadius: '20px',
        boxShadow: 'none',
      },
    },
  },
  toggleSwitch: {
    colors: {
      disabled: '#E2E2E2',
      hover: '#26E888',
      lightest: '#FFFFFF',
      pressed: '#15C26D',
      typeOff: {
        default: '#979797',
      },
      typeOn: {
        default: '#1D1D1B',
      },
    },
  },
  tooltip: {
    arrowColor: '#1D1D1B',
    backgroundColor: '#1D1D1B',
    customIcon: {
      default: {
        backgroundColor: 'transparent',
      },
      hover: {
        backgroundColor: '#E2E2E2',
      },
      selected: {
        backgroundColor: '#1D1D1B',
      },
      size: '16px',
    },
    font: 'normal 500 14px/24px inter',
    icon: {
      color: 'dark',
      size: '12px',
    },
    textColor: '#ffffff',
  },
  franco: {
    text: {
      font: 'normal 500 12px/16px inter',
      contentFont: 'normal 700 14px/16px inter',
    },
    colors: {
      white: '#FFFFFF',
      darker: '#606060',
      darkest: '#1D1D1B',
      orange: '#FF9432',
      infoRed: '#FA393E',
      infoGreen: '#05E08E',
    },
    border: '1px solid #E2E2E2',
  },
  inputIncrement: {
    inputBackground: '#ffffff',
    borderStyleFocused: '1px solid #1d1d1b',
    borderRadius: '8px',
    font: 'normal 500 12px/16px inter',
    highlighted: {
      secondary: '#27E7E2',
      secondaryLight: '#CCF2F2',
      secondaryDark: '#17CFCA',
    },
    label: {
      fonts: {
        default: 'normal 500 14px/16px inter',
        disabled: 'normal 500 14px/16px inter',
      },
      colors: {
        required: '#fa393e',
        default: '#1D1D1B',
        disabled: '#979797',
      },
    },
    buttons: {
      default: {
        backgroundColor: '#1d1d1b',
      },
      hover: {
        backgroundColor: '#26E888',
      },
      active: {
        backgroundColor: '#15c26d',
      },
      focused: {
        backgroundColor: '#15C26D',
      },
      disabled: {
        backgroundColor: '#e2e2e2',
      },
    },
    placeholder: {
      default: '#979797',
      disabled: '#ffffff',
    },
  },
  dropdown: {
    borderRadius: '8px',
    font: 'normal 500 12px/20px inter',
    cursor: 'pointer',
    labelContainer: {
      font: 'normal 500 14px/20px inter',
      default: {
        color: '#1D1D1B',
      },
      disabled: {
        color: '#979797',
      },
    },
    labelRequiredCharacter: {
      default: {
        color: '#FA393E',
      },
      disabled: {
        color: '#979797',
      },
    },
    inputContainer: {
      height: '40px',
      default: {
        border: '1px solid #E2E2E2',
        backgroundColor: '#FFFFFF',
      },
      hover: {
        backgroundColor: '#F8F8F8',
      },
      disabled: {
        backgroundColor: '#E2E2E2',
      },
      focus: {
        border: '1px solid #1D1D1B',
      },
      error: {
        border: '1px solid #FA393E',
      },
    },
    text: {
      default: {
        color: '#1D1D1B',
      },
      disabled: {
        color: '#FFFFFF',
        value: {
          color: '#1D1D1B',
        },
      },
      placeholder: {
        color: '#979797',
      },
    },
    dropdownListContainer: {
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      maxHeight: '400px',
      width: 'inherit',
    },
    loadingDropdownListContainer: {
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
    },
    loadingDropdownItem: {
      borderRadius: '4px',
      backgroundColor: '#F8F8F8',
    },
    dropdownList: {
      default: {
        maxHeight: '360px',
      },
      searchAndMulti: {
        maxHeight: '320px',
      },
      searchAndButton: {
        maxHeight: '320px',
      },
      searchAndMultiAndButton: {
        maxHeight: '320px',
      },
    },
    dropdownItem: {
      minHeight: '40px',
      default: {
        color: '#1D1D1B',
        backgroundColor: '#FFFFFF',
      },
      hover: {
        backgroundColor: '#F8F8F8',
      },
      disabled: {
        color: '#E2E2E2',
        backgroundColor: '#FFFFFF',
      },
    },
    dropdownItemText: {
      default: {
        font: 'normal 500 12px/16px inter',
      },
      showMoreResult: {
        color: '#606060',
        loadingColor: '#E2E2E2',
      },
    },
    searchInputItem: {
      height: '40px',
      borderBottom: '1px solid #e2e2e2',
      default: {
        color: '#1D1D1B',
        backgroundColor: '#FFFFFF',
      },
      hover: {
        backgroundColor: '#F8F8F8',
      },
      loading: {
        backgroundColor: '#E5E5E5',
      },
    },
    searchInput: {
      default: {
        color: '#1D1D1B',
      },
      loading: {
        backgroundColor: '#E5E5E5',
        textColor: '#FFFFFF',
      },
    },
    multiselectValidationButton: {
      color: '#FFFFFF',
      fontWeight: '700',
      default: {
        backgroundColor: '#1D1D1B',
      },
      hover: {
        backgroundColor: '#26E888',
      },
      active: {
        backgroundColor: '#15C26D',
      },
      disabled: {
        backgroundColor: '#E2E2E2',
      },
      haveButton: {
        disabled: {
          borderTop: '1px solid #FFFFFF',
        },
      },
    },
    dropdownButton: {
      borderTop: '1px solid #e2e2e2',
      fontWeight: '700',
      default: {
        color: '#1D1D1B',
        backgroundColor: '#FFFFFF',
      },
      hover: {
        backgroundColor: '#F8F8F8',
      },
      active: {
        backgroundColor: '#F8F8F8',
      },
      disabled: {
        backgroundColor: '#E2E2E2',
        color: '#FFFFFF',
      },
    },
  },
  nestedList: {
    borderRadius: '8px',
    toggle: {
      selected: {
        background: '#222321',
      },
      loading: {
        border: '1px solid #e2e2e2',
      },
      default: {
        border: '1px solid #222321',
      },
      hover: {
        border: '1px solid #00EA84',
      },
      size: {
        width: '24px',
        height: '24px',
      },
    },
    header: {
      default: {
        color: '#606060',
        font: 'normal 500 14px/18px inter',
      },
      selected: {
        color: '#1D1D1B',
        font: 'normal 700 14px/18px inter',
      },
      icon: 'dark',
    },
    row: {
      font: 'normal 500 14px/28px inter',
      highlighted: {
        color: '#222321',
        font: 'normal 700 14px/16px inter',
      },
    },
    childRow: {
      font: 'normal 500 14px/28px inter',
      color: '#606060',
      link: {
        color: '#1D1D1B',
      },
      separator: {
        color: '#E6E6E6',
      },
      border: 'none',
      borderRadius: '8px',
      backgroundColor: '#FAFAFA',
    },
    graph: {
      background: '#F8F8F8',
    },
  },
};
export default theme;
