import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import {
  CustomModalContainer,
  CustomModalBody,
  CloseIcon,
  Title,
} from './styledComponents';

import Row from './components/Row/Row';

import { getTheme } from '../../../utils/theme';

import EmptyState from './components/EmptyState';

import IconCloseCross from '../../../images/icon-close-cross.svg';

import { ModalContentProps } from '../../interface';
import LoadingState from './components/LoadingState/LoadingStateDisplay';

const CustomModalContentDisplay = (props: ModalContentProps): JSX.Element => {
  const {
    theme,
    title,
    modalHeaders,
    modalContent,
    openModal,
    labelModalEmptyState,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    (async function loadData() {
      if (modalContent) {
        const result = await modalContent();

        setData(result);
      }
      setIsLoading(false);
    })();
  }, [modalContent]);

  if (isLoading) {
    return (
      <ThemeProvider theme={getTheme(theme)}>
        <CustomModalContainer onClick={(e) => e.stopPropagation()}>
          <Title>{title}</Title>
          <CloseIcon src={IconCloseCross} onClick={openModal} />
          <CustomModalBody>
            <LoadingState headers={modalHeaders} />
          </CustomModalBody>
        </CustomModalContainer>
      </ThemeProvider>
    );
  }

  if (!data || !data.length) {
    return (
      <ThemeProvider theme={getTheme(theme)}>
        <CustomModalContainer onClick={(e) => e.stopPropagation()}>
          <Title>{title}</Title>
          <CloseIcon src={IconCloseCross} onClick={openModal} />
          <CustomModalBody>
            <EmptyState label={labelModalEmptyState} />
          </CustomModalBody>
        </CustomModalContainer>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={getTheme(theme)}>
      <CustomModalContainer onClick={(e) => e.stopPropagation()}>
        <Title>{title}</Title>
        <CloseIcon src={IconCloseCross} onClick={openModal} />
        <CustomModalBody>
          {data.map((item, index) => (
            <Fragment key={`row-${index}`}>
              <Row theme={getTheme(theme)} headers={modalHeaders} item={item} />
            </Fragment>
          ))}
        </CustomModalBody>
      </CustomModalContainer>
    </ThemeProvider>
  );
};

CustomModalContentDisplay.propTypes = {
  modalContent: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.objectOf(PropTypes.any),
};

CustomModalContentDisplay.defaultProps = {
  theme: null,
};

export default CustomModalContentDisplay;
