import React from 'react';

import fullLogo from '../../assets/logo/full.svg';

import { Container, Header, Logo, Content, Title, SubTile, Illustration } from './styledComponents';

export const MaintenancePage = (props) => {
  const { isInMaintenance, children } = props;

  if (!isInMaintenance) {
    return children;
  }

  return (
    <Container>
      <Header>
        <Logo alt="inpulse-logo" src={fullLogo} />
      </Header>
      <Content>
        <Title>Inpulse est en maintenance</Title>
        <SubTile>
          Nous nous efforçons de minimiser les perturbations et de rétablir l'application dans les
          plus brefs délais.
          <br />
          <br />
          Merci pour confiance 😊
        </SubTile>
        <Illustration alt="maintenance" src="/images/inpulse/time.svg" />
      </Content>
    </Container>
  );
};

export default MaintenancePage;
