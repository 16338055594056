import i18next from 'i18next';

export const getFactorToConvertMetricToEntityUnit = (unit) =>
  unit === 'mL' || unit === 'g' ? 1000 : 1;

export const convertUnits = (unit, isMinified = false, count = 1) => {
  switch (unit) {
    case 'unit':
      return isMinified ? i18next.t('GENERAL.MINIFIED_UNIT') : i18next.t('GENERAL.UNIT', { count });
    case 'g':
      return isMinified ? 'kg' : 'Kg';
    case 'L':
      return isMinified ? 'ml' : 'mL';
    case 'Kg':
    case 'kg':
      return 'g';
    case 'mL':
    case 'ml':
      return 'L';
  }
};
