// db.js
import Dexie from 'dexie';

const DB_VERSION = 1;

const DB_NAME = 'Inpulse';

export const db = new Dexie(DB_NAME);

db.version(DB_VERSION).stores({
  dashboard: '++id, &[storeId+weekDate], storeId, weekDate', // Primary key and indexed props
});
