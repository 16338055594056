import styled, { css } from 'styled-components';

export const Icon = styled.img`
  width: ${(props) => props.theme.icon?.size};
`;

export const Container = styled.div`
  ${(props) =>
    !props.displayBigger &&
    css`
      margin: auto 0 auto 8px;
    `};

  .custom-tooltip-modal {
    border-radius: 3px;
    padding: 3px 8px 3px 8px;

    background-color: ${(props) =>
      props.theme.customModal?.icon?.default?.backgroundColor};

    width: auto;
  }

  .custom-tooltip-modal-open {
    background-color: ${(props) =>
      props.theme.customModal?.icon?.selected?.backgroundColor};

    border-radius: 3px;
    padding: 3px 8px 3px 8px;

    width: auto;
  }

  .custom-tooltip-modal:hover {
    background-color: ${(props) =>
      props.theme.customModal?.icon?.hover?.backgroundColor};
  }

  .custom-tooltip {
    word-break: break-word;
    white-space: pre-wrap;
    border-radius: ${(props) => `${props.borderRadius}px`};

    max-width: ${(props) => `${props.maxWidth}px`};
    max-height: 1200px;

    text-align: center;
    font: ${(props) => props.theme.font};
  }
`;

export default {
  Icon,
  Container,
};
