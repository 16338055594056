import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Proptypes from 'prop-types';
import React from 'react';

import useLocalStorage from '@hooks/useLocalStorage';

import {
  Container,
  Text,
  IconContainer,
  DateContainer,
  DayCounter,
  TextContainer,
} from './styledComponents';

const DATE_FORMAT = 'YYYY-MM-DD';

export const OfflineInfoBox = (props) => {
  const { t } = useTranslation();
  const { offlineLimitReached, maximumDaysOffline } = props;

  const [userOfflineSince] = useLocalStorage('userOfflineSince');

  if (!userOfflineSince) {
    return null;
  }

  const currentDate = moment();

  const offlineActiveUntil = moment(userOfflineSince).add(maximumDaysOffline, 'day');

  const nbDaysUserIsOffline = moment(currentDate).diff(userOfflineSince, 'day') + 1;

  return (
    <Container>
      <IconContainer>
        <svg
          fill="none"
          height="20"
          viewBox="0 0 20 20"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" fill="#EF252A" r="10" />
          <path
            d="M6.86239 7.26906C6.27335 6.68031 5.68431 6.09776 5.10147 5.51521C5.03946 5.45324 4.95266 5.37887 4.93405 5.2983C4.92165 5.21774 4.95266 5.09379 5.01466 5.03181C5.05806 4.98843 5.18827 4.99463 5.26268 5.01942C5.33708 5.04421 5.39289 5.11858 5.44869 5.18055C8.53651 8.26684 11.6243 11.3531 14.7184 14.4456C14.7494 14.4766 14.7928 14.5014 14.799 14.5324C14.8238 14.6563 14.892 14.8236 14.8424 14.9042C14.7308 15.0777 14.5757 14.9848 14.4517 14.867C14.1603 14.5757 13.8565 14.2907 13.5775 13.987C13.4225 13.8197 13.2675 13.7639 13.038 13.7701C10.9919 13.7825 8.94574 13.7825 6.8996 13.7763C5.38049 13.7701 4.2396 12.7475 4.02259 11.2354C3.84278 9.90294 4.75424 8.5829 6.12454 8.17388C6.23615 8.14289 6.34776 8.04373 6.42216 7.94457C6.57717 7.74626 6.70118 7.51696 6.86239 7.26906ZM12.7404 13.2867C10.8803 11.4275 9.02015 9.57448 7.14761 7.69668C7.0298 7.895 6.9058 8.13669 6.75078 8.3536C6.67638 8.45895 6.55237 8.57051 6.43456 8.60149C5.14487 8.91756 4.36981 9.94632 4.50622 11.1982C4.63643 12.4191 5.65951 13.2867 6.974 13.2867C8.54271 13.2867 10.1114 13.2867 11.6801 13.2867C12.046 13.2867 12.4056 13.2867 12.7404 13.2867Z"
            fill="white"
          />
          <path
            d="M9.80763 6.33335C9.3612 6.4511 8.91477 6.55646 8.47454 6.6928C8.30092 6.74238 8.13971 6.80435 8.05291 6.59984C7.9661 6.39533 8.12111 6.30856 8.28232 6.2342C10.3533 5.29839 12.8334 6.42631 13.5279 8.62638C13.5899 8.82469 13.6829 8.92385 13.8813 8.99202C15.512 9.56838 16.0577 11.6011 14.9354 12.8964C14.8548 12.9893 14.7246 13.0513 14.6068 13.0885C14.5634 13.1009 14.4332 13.0079 14.4332 12.9583C14.427 12.8592 14.4518 12.729 14.5138 12.6547C14.8362 12.289 15.0222 11.88 15.0408 11.3966C15.078 10.436 14.4766 9.61795 13.5341 9.38865C13.2861 9.32668 13.1807 9.21513 13.1187 8.97343C12.7528 7.45508 11.3949 6.39533 9.80763 6.33335Z"
            fill="white"
          />
        </svg>
      </IconContainer>
      <TextContainer>
        <Text red={offlineLimitReached}>
          {offlineLimitReached
            ? t('PRODUCTION.PRODUCTION.HEADER_TOOLTIP_OFFLINE_LIMIT_REACHED')
            : t('PRODUCTION.PRODUCTION.OFFLINE_MODE')}
        </Text>
        {offlineLimitReached ? undefined : (
          <DateContainer>
            <div>
              <DayCounter>
                {`${t('PRODUCTION.PRODUCTION.OFFLINE_MAX', {
                  maximumDaysOffline,
                  nbDaysUserIsOffline,
                })} `}
              </DayCounter>
              {t('PRODUCTION.PRODUCTION.OFFLINE_DATE', {
                offlineActiveUntil: moment(offlineActiveUntil).format(DATE_FORMAT),
              })}
            </div>
          </DateContainer>
        )}
      </TextContainer>

      {/* t(
        offlineLimitReached
          ? 'PRODUCTION.PRODUCTION.HEADER_TOOLTIP_OFFLINE_LIMIT_REACHED'
          : 'PRODUCTION.PRODUCTION.HEADER_TOOLTIP_OFFLINE_LIMIT_IN_PROGRESS',
        {
          offlineActiveUntil: moment(offlineActiveUntil).format(DATE_FORMAT),
          maximumDaysOffline,
          nbDaysUserIsOffline,
        },
      ) */}
    </Container>
  );
};

OfflineInfoBox.propTypes = {
  offlineLimitReached: Proptypes.bool.isRequired,
  maximumDaysOffline: Proptypes.number.isRequired,
};

OfflineInfoBox.defaultProps = {};

export default OfflineInfoBox;
