import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

import AddCashierConfigModal from '../components/AddCashierConfigModal';

const MODAL_COLUMNS = [
  {
    id: 'name',
    name: i18next.t('GENERAL.NAME'),
    displayName: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
    baseName: 'name',
  },
];

export const getRowActions = (
  displayModalConfigEdition,
  displayModalDeleteCashierConfig,
  updateCashierConfigIsActiveStatus,
) => [
  {
    id: 'action-update-props',
    actionLabel: () => i18next.t('BACKOFFICE.CASHIERS.HANDLE_PROPS_ACTION'),
    handleAction: (item) => displayModalConfigEdition(item),
    actionIcon: () => '/images/inpulse/gear-black-small.svg',
  },
  {
    id: 'action-update-isActive',
    actionLabel: (item) =>
      i18next.t(
        !!item.isActive
          ? 'BACKOFFICE.CASHIERS.DEACTIVATE_SALES_INGESTION'
          : 'BACKOFFICE.CASHIERS.ACTIVATE_SALES_INGESTION',
      ),
    handleAction: ({ id, isActive }) => updateCashierConfigIsActiveStatus(id, !isActive),
    actionIcon: () => '/images/inpulse/power-black-small.svg',
  },
  {
    id: 'delete',
    actionLabel: () => i18next.t('BACKOFFICE.CASHIERS.DELETE_CASHIER_CONFIG_ACTION'),
    handleAction: (item) => displayModalDeleteCashierConfig(item),
    actionIcon: () => '/images/inpulse/trash-black-small.svg',
  },
];

export const getActions = (userLanguageCode, displayModalConfigEdition, clientId, openModal) => {
  const params = {
    component: AddCashierConfigModal,
    dataColumns: MODAL_COLUMNS,
    userLanguageCode,
    goToStepUpdateCashierConfig: (cashier) =>
      displayModalConfigEdition({
        cashierId: cashier.id,
        credentials: cashier.credentialsTemplate,
        config: cashier.configTemplate,
        name: `${i18next.t('BACKOFFICE.CASHIERS.CASHIER_CONFIG_NAME')} ${cashier.name}`,
        clientId,
        lnkCashierCashierConfig: cashier,
      }),
  };

  const actions = [
    {
      id: 'add',
      fixed: true,
      actionLabel: i18next.t('GENERAL.ADD'),
      handleAction: () => openModal(params),
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={() => openModal(params)}
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('GENERAL.ADD')}
        />
      ),
    },
  ];

  return actions;
};
