import { isEmpty, keyBy, times, get, groupBy } from 'lodash';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

const DATE_FORMAT = DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY;

const pickValueToReBuild = (
  date,
  inventoryStockOfEntityKeyByDate,
  stockKeyByDate,
  stockToRebuild,
  index,
  hasPreviousStock,
  stockConvention,
  storeTimezone,
) => {
  if (index === 0) {
    return {
      realStockUnit: !hasPreviousStock ? null : stockKeyByDate[date].realStockUnit,
      realStockTurnover: !hasPreviousStock ? null : stockKeyByDate[date].realStockTurnover,
      theoricalStockUnit: !hasPreviousStock ? 0 : stockKeyByDate[date].theoricalStockUnit,
      theoricalStockTurnover: !hasPreviousStock ? 0 : stockKeyByDate[date].theoricalStockTurnover,
    };
  }

  const realStockUnit = get(inventoryStockOfEntityKeyByDate[date], 'realStockUnit', null);
  const realStockTurnover = get(inventoryStockOfEntityKeyByDate[date], 'realStockTurnover', null);

  let theoricalStockUnit = get(
    stockToRebuild[stockToRebuild.length - 1],
    'theoricalStockUnit',
    get(stockKeyByDate[date], 'theoricalStockUnit', 0),
  );

  let theoricalStockTurnover = get(
    stockToRebuild[stockToRebuild.length - 1],
    'theoricalStockTurnover',
    get(stockKeyByDate[date], 'theoricalStockTurnover', 0),
  );

  const formattedDate =
    stockConvention === 'end'
      ? date
      : moment.tz(date, storeTimezone).subtract(1, 'day').format(DATE_FORMAT);

  // Override theorical stock from matching inventory (+ store_convention) when
  if (!isEmpty(inventoryStockOfEntityKeyByDate[formattedDate])) {
    if (inventoryStockOfEntityKeyByDate[formattedDate].realStockUnit != null) {
      theoricalStockUnit = inventoryStockOfEntityKeyByDate[formattedDate].realStockUnit;
    }

    if (inventoryStockOfEntityKeyByDate[formattedDate].realStockTurnover != null) {
      theoricalStockTurnover = inventoryStockOfEntityKeyByDate[formattedDate].realStockTurnover;
    }
  }

  return { realStockUnit, realStockTurnover, theoricalStockUnit, theoricalStockTurnover };
};

const checkIfPreviousStockExist = (stockKeyByDate, formattedDate, storeTimezone) =>
  !isEmpty(
    stockKeyByDate[moment.tz(formattedDate, storeTimezone).subtract(1, 'day').format(DATE_FORMAT)],
  );

const computedForecastStock = (
  entityStockEvent,
  recoStartDate, //deliveryDate
  computedOrderedAmount, // computed ordered amount without loss
  formattedStartDate, //yesterday
  formattedEndDate, //endChartDay
  recoEndDate, // End delivery date
  moreThanFiftyDay,
  stockConvention,
  storeTimezone,
) => {
  if (entityStockEvent.stock) {
    const stockToRebuild = [];
    let nbDayToRebuild = moment
      .tz(formattedEndDate, storeTimezone)
      .diff(moment.tz(formattedStartDate, storeTimezone), 'days');

    // Calculate one more (time !) day if moreThanFiftyDay is set because with diff calculation
    // one day is missing for the chart
    if (!!moreThanFiftyDay) {
      nbDayToRebuild += 1;
    }

    // Use groupBy like on the API to prepare if multiple order are made for a specific day
    const orderEntityKeyByDate = groupBy(entityStockEvent.orderOfEntity, 'startOrderDate');

    // Use groupBy like on the API because of multiple rush orders are made for a specific day
    const rushOrderEntityGroupByDate = groupBy(entityStockEvent.rushOrderOfEntity, 'rushOrderDate');

    const inventoryLossOfEntityKeyByDate = keyBy(
      entityStockEvent.inventoryLossOfEntity.map((inventoryStock) => ({
        ...inventoryStock,
        inventoryDate:
          stockConvention === 'end'
            ? inventoryStock.inventoryDate
            : moment
                .tz(inventoryStock.inventoryDate, storeTimezone)
                .subtract(1, 'day')
                .format(DATE_FORMAT),
      })),
      'inventoryDate',
    );

    const supplierProductsLossOfEntityKeyByDate = keyBy(
      entityStockEvent.supplierProductsLossOfEntity,
      'lossDate',
    );

    const productsLossOfEntityKeyByDate = keyBy(entityStockEvent.productsLossOfEntity, 'lossDate');

    const inventoryStockOfEntityKeyByDate = keyBy(
      entityStockEvent.inventoryStockOfEntity.map((inventoryStock) => ({
        ...inventoryStock,
        inventoryDate:
          stockConvention === 'end'
            ? inventoryStock.inventoryDate
            : moment
                .tz(inventoryStock.inventoryDate, storeTimezone)
                .subtract(1, 'day')
                .format(DATE_FORMAT),
      })),
      'inventoryDate',
    );

    const inventoryTransferInOfEntityKeyByDate = keyBy(
      entityStockEvent.inventoryTransferInOfEntity.map((inventoryTransferInStock) => ({
        ...inventoryTransferInStock,
        inventoryDate: inventoryTransferInStock.inventoryDate,
      })),
      'inventoryDate',
    );

    const inventoryTransferOutOfEntityGroupedByDate = groupBy(
      entityStockEvent.inventoryTransferOutOfEntity.map((inventoryTransferOutStock) => ({
        ...inventoryTransferOutStock,
        inventoryDate: inventoryTransferOutStock.inventoryDate,
      })),
      'inventoryDate',
    );

    const stockKeyByDate = keyBy(entityStockEvent.stock, 'date');

    times(nbDayToRebuild, (index) => {
      let hasPreviousStock = true;
      const formattedDate = moment
        .tz(formattedStartDate, storeTimezone)
        .add(index, 'days')
        .format(DATE_FORMAT);

      // Pre set all value to 0 to avoid multiple ternary
      // OrderedStock is mandatory a let because we use reduce to set order if already exist.
      let orderedStock = { totUnit: 0, totTurnover: 0 };
      const forecastStock = { totUnit: 0, totTurnover: 0 };
      const productSalesStock = { totUnit: 0, totTurnover: 0 };
      const inventoryLossEntity = { totUnit: 0, totTurnover: 0 };
      let inventoryOutStock = { totUnit: 0, totTurnover: 0 };
      const inventoryInStock = { totUnit: 0, totTurnover: 0 };
      const supplierProductLoss = { totUnit: 0, totTurnover: 0 };
      const productLoss = { totUnit: 0, totTurnover: 0 };
      let rushOrderStock = { totUnit: 0, totTurnover: 0 };

      // Where index === 0 we check if previous stock exist for product with real stock
      // and not compute again for an already stock calculated by the API.
      if (index === 0) {
        hasPreviousStock = checkIfPreviousStockExist(stockKeyByDate, formattedDate, storeTimezone);
      }

      // Pick value to build stock for each valuable element of stock element.
      const stockValue = pickValueToReBuild(
        formattedDate,
        inventoryStockOfEntityKeyByDate,
        stockKeyByDate,
        stockToRebuild,
        index,
        hasPreviousStock,
        storeTimezone,
      );

      let newStock = {
        date: formattedDate,
        inventoryDate: formattedDate,
        inventoryListId: '',
        realStockTurnover: stockValue.realStockTurnover,
        realStockUnit: stockValue.realStockUnit,
        theoricalStockTurnover: stockValue.theoricalStockTurnover,
        theoricalStockUnit: stockValue.theoricalStockUnit,
      };

      // check if forecastProduct is present for the current date to decrement stock with it
      if (!isEmpty(entityStockEvent.productForecastOfEntityKeyByDate[formattedDate])) {
        forecastStock.totUnit = Math.max(
          entityStockEvent.productForecastOfEntityKeyByDate[formattedDate].totUnit,
          0,
        );
        forecastStock.totTurnover = Math.max(
          entityStockEvent.productForecastOfEntityKeyByDate[formattedDate].totTurnover,
          0,
        );
      }

      // check if forecastProduct is present for the current date to decrement stock with it
      if (!isEmpty(entityStockEvent.productSalesOfEntityKeyByDate[formattedDate])) {
        productSalesStock.totUnit = Math.max(
          entityStockEvent.productSalesOfEntityKeyByDate[formattedDate].totUnit,
          0,
        );

        productSalesStock.totTurnover = Math.max(
          entityStockEvent.productSalesOfEntityKeyByDate[formattedDate].totTurnover,
          0,
        );
      }

      // Check if order exist for the current date to add it on the stock
      if (!isEmpty(orderEntityKeyByDate[formattedDate])) {
        orderedStock = orderEntityKeyByDate[formattedDate].reduce(
          (acc, order) => {
            acc.totUnit += order.totUnit;
            acc.totTurnover += order.totTurnover;

            return acc;
          },
          { totUnit: 0, totTurnover: 0 },
        );
      }

      // Check if rush order exist for the current date to add it on the stock
      if (!isEmpty(rushOrderEntityGroupByDate[formattedDate])) {
        rushOrderStock = rushOrderEntityGroupByDate[formattedDate].reduce(
          (acc, rushOrder) => {
            acc.totUnit += rushOrder.totUnit;
            acc.totTurnover += rushOrder.totTurnover;

            return acc;
          },
          { totUnit: 0, totTurnover: 0 },
        );
      }

      // Check if inventory loss exist for the current date to add it on the stock
      if (!isEmpty(inventoryLossOfEntityKeyByDate[formattedDate])) {
        inventoryLossEntity.totUnit = Math.max(
          inventoryLossOfEntityKeyByDate[formattedDate].totUnit,
          0,
        );
        inventoryLossEntity.totTurnover = Math.max(
          inventoryLossOfEntityKeyByDate[formattedDate].totTurnover,
          0,
        );
      }

      // Check if supplier product loss exist for the current date to add it on the stock
      if (!isEmpty(supplierProductsLossOfEntityKeyByDate[formattedDate])) {
        supplierProductLoss.totUnit = Math.max(
          supplierProductsLossOfEntityKeyByDate[formattedDate].totUnit,
          0,
        );
        supplierProductLoss.totTurnover = Math.max(
          supplierProductsLossOfEntityKeyByDate[formattedDate].totTurnover,
          0,
        );
      }

      // Check if product loss exist for the current date to add it on the stock
      if (!isEmpty(productsLossOfEntityKeyByDate[formattedDate])) {
        productLoss.totUnit = Math.max(productsLossOfEntityKeyByDate[formattedDate].totUnit, 0);
        productLoss.totTurnover = Math.max(
          productsLossOfEntityKeyByDate[formattedDate].totTurnover,
          0,
        );
      }

      // Check if inventory transfer out stock exist for the current date to add it on the stock
      if (!isEmpty(inventoryTransferOutOfEntityGroupedByDate[formattedDate])) {
        inventoryOutStock = inventoryTransferOutOfEntityGroupedByDate[formattedDate].reduce(
          (acc, inventoryOut) => {
            acc.totUnit += inventoryOut.totUnit;
            acc.totTurnover += inventoryOut.totTurnover;

            return acc;
          },
          { totUnit: 0, totTurnover: 0 },
        );
      }

      // Check if inventory transfer in stock exist for the current date to add it on the stock
      if (!isEmpty(inventoryTransferInOfEntityKeyByDate[formattedDate])) {
        inventoryInStock.totUnit = Math.max(
          inventoryTransferInOfEntityKeyByDate[formattedDate].theoricalStockUnit,
          0,
        );
        inventoryInStock.totTurnover = Math.max(
          inventoryTransferInOfEntityKeyByDate[formattedDate].theoricalStockTurnover,
          0,
        );
      }

      // check if order exist for the current date and reset stock to 0 if is negative
      // to properly add order and dont add to a negative stock.
      if (!isEmpty(orderEntityKeyByDate[formattedDate])) {
        newStock.theoricalStockUnit = Math.max(newStock.theoricalStockUnit, 0);
        newStock.theoricalStockTurnover = Math.max(newStock.theoricalStockTurnover, 0);
      }

      // If formattedDate is same as recoStartDate we can add total of order in current order
      if (moment.tz(recoStartDate, storeTimezone).isSame(moment.tz(formattedDate, storeTimezone))) {
        newStock.theoricalStockUnit =
          Math.max(newStock.theoricalStockUnit, 0) + computedOrderedAmount.orderedTotUnit;
        newStock.theoricalStockTurnover =
          Math.max(newStock.theoricalStockTurnover, 0) + computedOrderedAmount.orderedTotTurnover;
      }

      // hasPreviousStock is always at true.
      // Condition used to compute first element of stock if no real stock exist and no theoretical stock can be calculate by the API.
      if (
        isEmpty(inventoryStockOfEntityKeyByDate[formattedDate]) &&
        (!hasPreviousStock || index !== 0)
      ) {
        newStock.theoricalStockUnit =
          newStock.theoricalStockUnit +
          orderedStock.totUnit +
          rushOrderStock.totUnit +
          inventoryInStock.totUnit -
          inventoryOutStock.totUnit -
          forecastStock.totUnit -
          productSalesStock.totUnit -
          inventoryLossEntity.totUnit -
          supplierProductLoss.totUnit -
          productLoss.totUnit;

        newStock.theoricalStockTurnover =
          newStock.theoricalStockTurnover +
          orderedStock.totTurnover +
          rushOrderStock.totTurnover +
          inventoryInStock.totTurnover -
          inventoryOutStock.totTurnover -
          forecastStock.totTurnover -
          productSalesStock.totTurnover -
          inventoryLossEntity.totTurnover -
          supplierProductLoss.totTurnover -
          productLoss.totTurnover;
      }

      stockToRebuild.push(newStock);
    });

    return stockToRebuild;
  }

  return [];
};

export default computedForecastStock;
