import PropTypes, { any } from 'prop-types';
import React from 'react';

import { ENUM_MODULE_NAME } from '../../../commons/utils/features';
import { LOSSES_FEATURE_PATHS } from '../../../commons/utils/featurePaths';

import { ModuleContainer } from '../../ModuleContainer';

export const LossesLossesContainer = (props) => {
  const { userRights, history } = props;

  return (
    <ModuleContainer
      history={history}
      moduleFeaturePaths={[
        LOSSES_FEATURE_PATHS.LOSSES_LOSSES_PRODUCTS,
        LOSSES_FEATURE_PATHS.LOSSES_LOSSES_SUPPLIER_PRODUCTS,
      ]}
      moduleName={ENUM_MODULE_NAME.LOSS}
      userRights={userRights}
    />
  );
};

LossesLossesContainer.propTypes = {
  userRights: PropTypes.array,
  history: any, // react-router-dom's route prop
  moduleName: PropTypes.oneOf(Object.values(ENUM_MODULE_NAME)),
  moduleFeaturePaths: PropTypes.arrayOf(PropTypes.string),
};

export default LossesLossesContainer;
