import styled, { keyframes } from 'styled-components';

const gradient = keyframes`
  0% { opacity: 0.4; }
  50% { opacity: 1; }
  100% { opacity: 0.4; }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  background-color: inherit;
  margin: ${(props) => (props.noMargin ? '0' : '0 24px')};

  overflow-y: hidden;
`;

export const LoadingRow = styled.div`
  height: 100%;
  width: 100%;

  border-radius: ${(props) => props.theme.borders.radius.weak};
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.colors.greys.lightest} 0%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: ${gradient} 1.5s linear infinite;
  margin: 24px 0 0 0;
`;

export const LoadingContent = styled.div`
  height: 40px;
`;

export default {
  Container,
  LoadingRow,
  LoadingContent,
};
