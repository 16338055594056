import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Tooltip } from '../../utils/styledLibraryComponents';

import './InputNumber.css';
import { Input } from '../../../lib/inpulse/Input';

class InputNumber extends React.PureComponent {
  render() {
    const { value, onChange, readOnly, style, tooltipText, icon } = this.props;
    return (
      <div className={this.props.className}>
        <div className="text-input-container">
          <div style={{ display: 'flex' }}>
            {this.props.label && (
              <label className="text-input-form-label">{this.props.label}</label>
            )}
            {tooltipText && tooltipText.length > 0 && <Tooltip text={tooltipText} />}
            {icon && <img src={icon} style={{ marginLeft: '10px' }} />}
          </div>
          <Input
            className={`text-input-form-input ${readOnly ? 'input-readonly' : ''}`}
            min="0"
            readOnly={readOnly}
            style={style}
            type={this.props.type}
            value={value}
            onChange={onChange}
          />
        </div>
      </div>
    );
  }
}

InputNumber.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  width: PropTypes.number,
  height: PropTypes.number,
  readOnly: PropTypes.bool,
};

InputNumber.defaultProps = {
  className: '',
  style: {},
  width: 70,
  height: 30,
  readOnly: false,
};

export default styled(InputNumber)`
  & .text-input-form-label {
    margin-bottom: 8px;

    color: ${(props) => props.theme.colors?.greys.darkest};

    font: ${(props) => props.theme.fonts?.textSmall};
  }

  & .input-readonly,
  .input-readonly:hover {
    border: none;
    cursor: not-allowed;
    border-radius: ${(props) => props.theme.inputs?.primary.default.borderRadius};
    background: ${(props) => props.theme.colors.greys.light} !important;
  }
`;
