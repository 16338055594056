import { get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';

export const DATE_FILTER_CHOICES_NAME = {
  ONE_DAY: i18next.t('GENERAL.DATE_SELECTOR_FILTER_ONE_DAY'),
  BEFORE: i18next.t('GENERAL.DATE_SELECTOR_FILTER_BEFORE'),
  AFTER: i18next.t('GENERAL.DATE_SELECTOR_FILTER_AFTER'),
  BETWEEN: i18next.t('GENERAL.DATE_SELECTOR_FILTER_BETWEEN'),
  NONE: i18next.t('GENERAL.SELECT_NONE_MASCULINE'),
};

const formatItem = (item, propertyKey) => {
  const value = get(item, propertyKey);
  if (!(value || value === 0)) {
    return;
  }

  const parsedItem = typeof value === 'string' ? Number(value) : value;

  if (Number.isNaN(parsedItem)) {
    return;
  }

  return parsedItem;
};

/**
 * Get date filter params content
 *
 * @param {Boolean} hasAllowsNullFilter Allow or not 'none' choice in date filter
 *
 * @returns Returns date filter params content
 */
const getDateFilterContent = (hasAllowsNullFilter) => {
  const dateFilterContent = [
    {
      id: 1,
      name: DATE_FILTER_CHOICES_NAME.ONE_DAY,
      type: 'single',
      doFilter: (data, propertyKey, value) =>
        data.filter((item) => moment(get(item, propertyKey, '')).isSame(value, 'day')),
      getQueryParam: (propertyKey, value) => {
        const condition = {
          between: [
            moment(value).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss'),
            moment(value).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss'),
          ],
        };

        return `&${propertyKey}=${JSON.stringify(condition)}`;
      },
    },
    {
      id: 2,
      name: DATE_FILTER_CHOICES_NAME.BEFORE,
      type: 'single',
      doFilter: (data, propertyKey, value) =>
        data.filter((item) => moment(get(item, propertyKey, '')).isBefore(value, 'day')),
      getQueryParam: (propertyKey, value) => {
        const condition = {
          lt: [moment(value).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss')],
        };

        return `&${propertyKey}=${JSON.stringify(condition)}`;
      },
    },
    {
      id: 3,
      name: DATE_FILTER_CHOICES_NAME.AFTER,
      type: 'single',
      doFilter: (data, propertyKey, value) =>
        data.filter((item) => moment(get(item, propertyKey, '')).isAfter(value, 'day')),
      getQueryParam: (propertyKey, value) => {
        const condition = {
          gt: [moment(value).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss')],
        };

        return `&${propertyKey}=${JSON.stringify(condition)}`;
      },
    },
    {
      id: 4,
      name: DATE_FILTER_CHOICES_NAME.BETWEEN,
      type: 'multiple',
      doFilter: (data, propertyKey, values) =>
        data.filter((item) =>
          moment(get(item, propertyKey, '')).isBetween(values.minValue, values.maxValue, 'day'),
        ),
      getQueryParam: (propertyKey, values) => {
        const condition = {
          between: [
            moment(values.minValue).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss'),
            moment(values.maxValue).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss'),
          ],
        };

        return `&${propertyKey}=${JSON.stringify(condition)}`;
      },
    },
  ];

  if (hasAllowsNullFilter) {
    dateFilterContent.push({
      id: 5,
      name: DATE_FILTER_CHOICES_NAME.NONE,
      type: 'single',
      doFilter: (data, propertyKey) => data.filter((item) => get(item, propertyKey, '') === null),
      getQueryParam: (propertyKey) => {
        const condition = {
          eq: 'IS NULL',
        };

        return `&${propertyKey}=${JSON.stringify(condition)}`;
      },
    });
  }

  return dateFilterContent;
};

/**
 * Return a translated version of the available selector filters
 *
 * @param {Boolean} hasAllowsNullDateFilter Allow or not 'none' choice in date filter
 *
 * @returns {SelectorFilter[]} The list of selector filter
 */
export function getFiltersParams(hasAllowsNullDateFilter) {
  return {
    numeric: {
      content: [
        {
          id: 'inferior',
          name: i18next.t('GENERAL.VALUE_SELECTOR_FILTER_LOWER_THAN'),
          type: 'single',
          getQueryParam: (propertyKey, value) => `&${propertyKey}={"lt":${value}}`,
          doFilter: (data, propertyKey, value) =>
            data.filter((item) => formatItem(item, propertyKey) < value),
        },
        {
          id: 'superior',
          name: i18next.t('GENERAL.VALUE_SELECTOR_FILTER_GREATER_THAN'),
          type: 'single',
          getQueryParam: (propertyKey, value) => `&${propertyKey}={"gt":${value}}`,
          doFilter: (data, propertyKey, value) =>
            data.filter((item) => formatItem(item, propertyKey) > value),
        },
        {
          id: 'equal',
          name: i18next.t('GENERAL.VALUE_SELECTOR_FILTER_EQUAL_TO'),
          type: 'single',
          getQueryParam: (propertyKey, value) => `&${propertyKey}={"eq":${value}}`,
          doFilter: (data, propertyKey, value) =>
            data.filter((item) => formatItem(item, propertyKey) === value),
        },
        {
          id: 'between',
          name: i18next.t('GENERAL.VALUE_SELECTOR_FILTER_BETWEEN'),
          type: 'multiple',
          getQueryParam: (propertyKey, values) =>
            `&${propertyKey}={"between":[${values.minValue}, ${values.maxValue}]}`,
          doFilter: (data, propertyKey, values) =>
            data.filter((item) => {
              const parsedItem = formatItem(item, propertyKey);

              return parsedItem > values.minValue && parsedItem < values.maxValue;
            }),
        },
      ],
    },
    date: {
      content: getDateFilterContent(hasAllowsNullDateFilter),
    },
    string: {
      content: [
        {
          id: 1,
          name: i18next.t('GENERAL.STRING_SELECTOR_FILTER_CONTAINS'),
          type: 'single',
          getQueryParam: (propertyKey, values, toExclude = false) => {
            const condition = {
              [toExclude ? 'nin' : 'inq']: (values || []).map(({ itemValue, value }) => {
                const item = itemValue != null ? itemValue : value;

                return typeof item === 'string' ? encodeURIComponent(item) : item;
              }),
            };

            return `&${propertyKey}=${JSON.stringify(condition)}`;
          },
          doFilter: (data, propertyKey, selectedItems, toExclude = false) => {
            if (!selectedItems.length) {
              return data;
            }

            return data.filter((item) => {
              if (typeof item[propertyKey] === 'object' && item[propertyKey] != null) {
                const isIncluded = selectedItems.some((selectedItem) =>
                  item[propertyKey].includes(selectedItem[propertyKey]),
                );
                return toExclude ? !isIncluded : isIncluded;
              }

              const isIncluded = selectedItems.some(
                (selectedItem) => selectedItem[propertyKey] === item[propertyKey],
              );
              return toExclude ? !isIncluded : isIncluded;
            });
          },
        },
      ],
    },
    boolean: {
      content: [
        {
          id: 1,
          name: i18next.t('GENERAL.ACTIVE'),
          type: 'single',
          doFilter: (data, propertyKey, selectedItems, toExclude = false) => {
            if (selectedItems.length == 2) {
              return data;
            }
            const boolLookedFor = get(selectedItems, '[0].name', '') === i18next.t('GENERAL.YES');
            return data.filter((item) => {
              const isIncluded = item[propertyKey] === boolLookedFor;
              return toExclude ? !isIncluded : isIncluded;
            });
          },
          getQueryParam: (propertyKey, values, toExclude = false) => {
            const condition = {
              [toExclude ? 'nin' : 'inq']: (values || []).map(({ itemValue }) => itemValue),
            };

            return `&${propertyKey}=${JSON.stringify(condition)}`;
          },
        },
      ],
    },
  };
}

export default {
  getFiltersParams,
};
