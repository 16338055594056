import styled from 'styled-components';

export const EmptyStateContainer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.colors.greys.lighter};
  padding-bottom: 80px;
`;

export const EmptyStateIcon = styled.img`
  width: 200px;
  margin-bottom: 16px;
`;

export const EmptyStateTitle = styled.div`
  text-align: center;
  margin-bottom: 16px;

  color: ${(props) => props.theme.colors.black};
  font: ${(props) => props.theme.fonts.h2};
`;

export const EmptyStateSubtitle = styled.div`
  text-align: center;
  margin-bottom: 16px;

  color: ${(props) => props.theme.colors.greys.darker};
  font: ${(props) => props.theme.fonts.textSmallHeight16};
`;
