import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  padding-top: 64px;

  display: flex;
  flex-direction: column;
`;

export const StepsContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 8px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 56px);

  padding-top: 24px;

  overflow-y: scroll;
  display: flex;
  justify-content: center;
`;

export const Step = styled.div`
  display: flex;
  gap: 8px;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;

  background-color: ${(props) =>
    props.isActive ? props.theme.colors.modalHeaders.green.background : 'transparent'};
  border-radius: 4px;
`;

export const LabelIcon = styled.div`
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  font: ${(props) => props.theme.fonts.textMicroHeight16Weight700Bold};

  border-radius: 50%;
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.greys.darkest : props.theme.colors.greys.dark};
  color: white;
`;

export const ButtonsContainer = styled.div`
  width: 50%;

  display: flex;
  justify-content: end;
  gap: 16px;
  flex: auto;
`;
