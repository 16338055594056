import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';

import { ingredient as ingredientService } from '@services/ingredient';

/**********************/
/* Fetch Data Methods */
/**********************/

/**
 * Retrieve the parents of an ingredient associated to the given ingredientId
 *
 * @param {String} ingredientId   - The ingredient id on which retrieve the data
 *
 * @returns {void}
 */
export async function fetchIngredientParents(ingredientId, onEntitiesMappedChange) {
  const result = await ingredientService.getIngredientParents(ingredientId);

  const resultSorted = sortArrayOfObjectsAlphabetically(result, 'name');
  onEntitiesMappedChange(resultSorted);
}
