import { DateRangePicker } from 'react-dates';
import i18next from 'i18next';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { isAfter } from '@commons/utils/date';
import { renderCustomArrowIcon } from '@commons/DeepsightAnalyticsHeaders/utils';
import { Tooltip } from '@commons/utils/styledLibraryComponents';
import DatePickerDay from '@commons/DatePickers/DatePickerDay';

import {
  ModalContentContainer,
  Label,
  DateRangePickerContainer,
  CustomInputIcon,
} from './styledComponents';

const renderCustomInputIcon = () => (
  <CustomInputIcon>
    <img alt="arrow" src={'/images/icon-calendar-black-inpulse.svg'} />
  </CustomInputIcon>
);

const DateRangeSelectionModal = (props) => {
  const { setDateRange, labelText, tooltipText, timezone } = props;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedDateInput, setFocusedDateInput] = useState(null);

  useEffect(() => {
    if (!!startDate && !!endDate) {
      setDateRange({ startDate: startDate, endDate: endDate });
    }
  }, [startDate, endDate]);

  const setDates = (dates) => {
    if (!moment(dates.startDate).isSame(startDate, 'day')) {
      setStartDate(dates.startDate);
    }
    if (!moment(dates.endDate).isSame(endDate, 'day')) {
      setEndDate(dates.endDate);
    }
  };

  const renderDay = (day) => {
    const currentDay = day.clone();

    const isToday = currentDay.startOf('day').isSame(moment.tz(timezone).startOf('day'));

    return <DatePickerDay day={currentDay} hasCircle={isToday} />;
  };

  return (
    <ModalContentContainer>
      <DateRangePickerContainer>
        <Label>
          <span>*</span>
          <span>{labelText || i18next.t('BACKOFFICE.DATA.DATE_RANGE')}</span>
          {tooltipText && <Tooltip text={tooltipText} />}
        </Label>
        <DateRangePicker
          calendarInfoPosition="top"
          customArrowIcon={renderCustomArrowIcon()}
          customInputIcon={renderCustomInputIcon()}
          displayFormat={'ddd Do MMM'}
          endDate={endDate}
          endDateId="salesEndDate"
          endDatePlaceholderText={i18next.t('GENERAL.END')}
          focusedInput={focusedDateInput}
          hideKeyboardShortcutsPanel={true}
          isOutsideRange={(day) => isAfter(day)}
          minimumNights={0}
          noBorder={true}
          numberOfMonths={2}
          readOnly={true}
          renderDayContents={(day) => renderDay(day)}
          startDate={startDate}
          startDateId="salesStartDate"
          startDatePlaceholderText={i18next.t('GENERAL.START')}
          onDatesChange={setDates}
          onFocusChange={setFocusedDateInput}
        />
      </DateRangePickerContainer>
    </ModalContentContainer>
  );
};

DateRangeSelectionModal.propTypes = {
  labelText: PropTypes.string,
  tooltipText: PropTypes.string,
  setDateRange: PropTypes.func.isRequired,
  timezone: PropTypes.string,
};

DateRangeSelectionModal.defaultProps = {
  timezone: 'Europe/Paris',
};

export default DateRangeSelectionModal;
