import _ from 'lodash';
import XLSX from 'xlsx';

const MAXIMUM_SHEET_NAME_LENGTH = 31;

export const makeXLS = (vueTitle, lines, vueDataName, dataTitles) => {
  const sheetName = (vueTitle || '').substring(0, MAXIMUM_SHEET_NAME_LENGTH);

  var wb = XLSX.utils.book_new();
  wb.SheetNames.push(sheetName);
  var wsData = [[]];
  var fieldsBaseName = [];
  dataTitles.forEach((field) => {
    wsData[0].push(field.name);
    fieldsBaseName.push(field.propertyKey);
  });

  lines.map((line) => {
    let fieldNames = fieldsBaseName.map((fieldName) => _.get(line, fieldName));
    wsData.push(fieldNames);
  });

  var ws = XLSX.utils.aoa_to_sheet(wsData);
  wb.Sheets[sheetName] = ws;
  const fileName = `${vueDataName}.xls`;
  XLSX.writeFile(wb, fileName);
};

export default {
  makeXLS,
};
