import styled from 'styled-components';

export const Container = styled.div`
  flex: 4;
  padding: 30px;
  background: ${(props) => props.theme.colors?.greys.lightest};
  width: calc(75% - 60px);
  box-shadow: ${(props) => props.theme.shadows?.short};
  border-radius: ${(props) => props.theme.borders?.radius.strong};
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors?.greys.darkest};
  font: ${(props) => props.theme.fonts.h2};

  text-align: left;
`;

export const FormContainer = styled.div`
  width: 100%;
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: flex-start;
  margin-top: 24px;
  &:first-child {
    margin-bottom: 8px;
    margin-top: 8px;
  }
  &:last-child {
    justify-content: flex-start;
  }
`;

export const SectionInfo = styled.div`
  max-width: 33%;
  margin: 0px 25px;
  width: calc(33% - 33px);

  &:first-child {
    margin-left: 0px;
  }
  &:last-child {
    margin-right: 0px;
  }
  .text-input-container,
  .input-dropdown-container {
    margin-top: 0px !important;
  }
`;
