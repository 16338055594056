import styled, { css } from 'styled-components';

export const SectionLabel = styled.div`
  color: ${(props) => props.theme.colors?.greys.darkest};
  font: ${(props) => props.theme.fonts?.textSmall};
  display: flex;
  margin-bottom: 8px;
`;

export const SectionInfo = styled.div`
  max-width: 33%;
  margin-right: 35px;
  width: calc(33% - 30px);
  height: 68px;

  &:last-child {
    margin-right: 0px;
  }

  .text-input-container,
  .input-dropdown-container {
    input {
      color: ${(props) => props.theme.colors?.greys.darkest};

      font: ${(props) => props.theme.fonts?.textSmall};
    }

    .text-input-form-label {
      color: ${(props) => props.theme.colors?.greys.darkest};

      font: ${(props) => props.theme.fonts?.textSmall};
    }

    .input-dropdown {
      border-radius: ${(props) => props.theme.borders?.radius.weak};
    }

    .text-input-form-input {
      border-radius: ${(props) => props.theme.borders?.radius.weak};
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px;

  background-color: ${(props) => props.theme.colors?.greys?.lighter};
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;

  ${(props) =>
    props.displayErrorMessage &&
    css`
      margin-bottom: 24px;
    `}
`;

export const ToggleContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;

  align-items: center;
`;
