import { chain, cloneDeep, get, isEqual, last, omit } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';

import { group as groupService } from '@services/group';
import clientService from '@services/client';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { ListView } from '@commons/utils/styledLibraryComponents';
import { MODAL_STEP } from './components/StoreAssortmentSetupModal';
import { Text } from './components/styledComponents';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import ProgressBarModal from './components/StoreAssortmentSetupModal/progressBarModal';
import utilsXLS from '@commons/utils/makeXLS';

import { getAssignGroupListTemplatesModalParams } from '@admin/commons/modalConfigurations';

import DeepsightFiltersButton from '@admin/components/FilterButton';
import EmptyState from '@admin/suppliers/suppliers/components/EmptyState';

import { ENUM_QUERY_PARAMS, withListViewQueryParamHookHOC } from '@hooks/useListViewQueryParams';

import { CHOICES_DROPDOWN_ACTIVE, CHOICES_STORE_TYPE } from '../utils/DropdownItems';

import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { Container } from './styledComponents';

import { getActions, getRowActions } from './utils/actionsUtils';
import { getColumns } from './utils/columnUtils';

import {
  getAssortmentConfirmationModal,
  getAssortmentSetupModal,
  getGroupsModalParams,
} from './utils/modalUtils';

import { getStoresOfUser, receiveStores, requestStoresError } from '@actions/store';
import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, openSmallModal, refreshGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

moment.locale('fr');

const ENUM_CUSTOM_FILTERS = {
  STATUS: 'active-inactive',
  TYPE: 'store-type',
};

const Stores = (props) => {
  const {
    stores,
    listViewQueryParams,
    setListViewQueryParams,
    client: { clientId, hasMultipleBrands, storeName, hasCentralKitchens },
    history,
    user,
    match: { path },
    openGenericModal,
    refreshGenericModal,
    openModalExportInfo,
    pageLoading,
    pageLoaded,
    authorizedActions,
    showSuccessMessage,
    showErrorMessage,
    getStoresOfUser,
  } = props;

  const PROPERTY_NONE = i18next.t('GENERAL.NONE_VALUE');

  const storeNameSingle = getClientStoreNameTranslation(storeName).toLowerCase();
  const storeNamePlural = getClientStoreNameTranslation(storeName, true);

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  const listViewRef = useRef(null);

  const [storesList, setStoresList] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [filteredStoresList, setFilteredStoresList] = useState([]);
  const [clientGroups, setClientGroups] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [dataTitles, setDataTitles] = useState(getColumns());

  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [columnsFilterList, setColumnsFilterList] = useState([]);
  const [isInitializingFilters, setIsInitializingFilters] = useState(true);
  const [selectedStoreTypes, setSelectedStoreTypes] = useState(CHOICES_STORE_TYPE);
  const [customMultipleDropDowns, setCustomMultipleDropDowns] = useState([]);
  const [storeIdReference, setStoreIdReference] = useState(null);
  const [storesToSetup, setStoresToSetup] = useState([]);
  const [modalStep, setModalStep] = useState(null);

  /*****************/
  /** USE EFFECTS **/
  /*****************/

  useEffect(() => {
    if (!user || !stores.length) {
      return;
    }

    const currentDataTitles = getColumns();

    const hasCentralKitchenStores = stores.some(({ isKitchen }) => isKitchen);

    if (hasCentralKitchens && hasCentralKitchenStores) {
      currentDataTitles.splice(1, 0, {
        id: 'storeType',
        baseName: 'isKitchen',
        propertyKey: 'isKitchen',
        name: i18next.t('GENERAL.STORE_TYPE'),
        displayName: i18next.t('GENERAL.STORE_TYPE'),
        render: (isKitchen) => (
          <Text>{isKitchen ? i18next.t('GENERAL.CENTRAL') : i18next.t('GENERAL.SALE_POINT')}</Text>
        ),
      });

      const storeTypeFilter = {
        id: 'type',
        icon: '/images/inpulse/pin-black-small.svg',
        list: CHOICES_STORE_TYPE,
        defaultSelectedItems: CHOICES_STORE_TYPE,
        selectedItems: selectedStoreTypes,
        setSelectedItems: handleStoreTypeFilter,
      };

      setCustomMultipleDropDowns([...customMultipleDropDowns, storeTypeFilter]);
    }

    if (hasMultipleBrands) {
      currentDataTitles.splice(1, 0, {
        id: 'brand',
        baseName: 'brand',
        propertyKey: 'brand',
        name: i18next.t('ADMIN.STORES.LIST_COLUMN_BRAND'),
        displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_BRAND'),
        filterType: 'string',
        render: (item) => <Text>{item ? item : '-'}</Text>,
      });
    }

    setDataTitles(currentDataTitles);

    initializeFiltersFromQueryParams();
  }, [stores]);

  useEffect(() => {
    processGroups();
  }, []);

  useEffect(() => {
    processStores();
  }, [stores, isInitializingFilters]);

  useEffect(() => {
    processAdvancedFilter();
  }, [storesList, advancedFilters, applyFilters]);

  useEffect(() => {
    updateColumnsFilterList(filteredStoresList);
  }, [filteredStoresList, dataTitles]);

  useEffect(() => {
    const clientHasGroups = clientGroups.length > 0;

    setActions(
      getActions(
        selectedStores,
        handleExport,
        handleAssortmentSetup,
        handleManageGroups,
        handleAssignGroups,
        handleDissociateGroups,
        clientHasGroups,
        storeNameSingle,
        authorizedActions,
      ),
    );

    setRowActions(
      getRowActions(storeNameSingle, handleExport, handleAssortmentSetup, authorizedActions),
    );
  }, [dataTitles, selectedStores, clientGroups]);

  useEffect(() => {
    if (!modalStep) {
      return;
    }

    const isModalOpened = get(props, 'modal.GenericModalBool', false);

    let assortmentSetupModalConfig;

    const storesToUse = storesToSetup.length ? storesToSetup : selectedStores;

    switch (modalStep) {
      case MODAL_STEP.STORE_SELECTION:
        assortmentSetupModalConfig = getAssortmentSetupModal(
          storesList,
          storesToUse,
          storeNameSingle,
          hasMultipleBrands,
          storeIdReference,
          setStoreIdReference,
          resetModalStates,
          setModalStep,
        );
        break;

      case MODAL_STEP.VALIDATION:
        const referenceStore = storesList.find(({ id }) => id === storeIdReference);
        const referenceStoreName = referenceStore ? referenceStore.name : '-';

        assortmentSetupModalConfig = getAssortmentConfirmationModal(
          storesToUse,
          referenceStoreName,
          storeNameSingle,
          storeNamePlural,
          resetModalStates,
          setModalStep,
        );
        break;

      case MODAL_STEP.PROGRESS_BAR:
        handleProgressBarModal(storesToUse, storeIdReference);
        return;

      default:
        break;
    }

    if (isModalOpened) {
      refreshGenericModal(assortmentSetupModalConfig);
      return;
    }

    openGenericModal(assortmentSetupModalConfig);
  }, [storesList, selectedStores, storeIdReference, modalStep]);

  /**************************/
  /** CALLBACK USE EFFECTS **/
  /**************************/

  const initializeFiltersFromQueryParams = () => {
    const filters = listViewQueryParams[ENUM_QUERY_PARAMS.FILTERS];

    const defaultFilters = {
      [ENUM_CUSTOM_FILTERS.STATUS]: CHOICES_DROPDOWN_ACTIVE[0],
    };

    try {
      const areFiltersValid = filters[ENUM_CUSTOM_FILTERS.STATUS].every((filter) =>
        CHOICES_DROPDOWN_ACTIVE.some((item) => isEqual(item, filter)),
      );

      if (!areFiltersValid) {
        throw new Error('Filters not valid');
      }
    } catch (err) {
      setListViewQueryParams[ENUM_QUERY_PARAMS.FILTERS](defaultFilters);
    } finally {
      setIsInitializingFilters(false);
    }
  };

  const processAdvancedFilter = () => {
    if (!applyFilters) {
      return;
    }

    const selectedStatusFilters = getStatusFiltersFromQueryParams();
    const filterValues = selectedStoreTypes.map(({ filterValue }) => filterValue);

    const updatedFilteredStoresList = storesList.filter(
      ({ active, isKitchen }) =>
        selectedStatusFilters.filterValue === active && filterValues.includes(isKitchen),
    );

    if ((!advancedFilters || !advancedFilters.length) && applyFilters) {
      setFilteredStoresList(updatedFilteredStoresList);
      setIsLoading(false);

      return;
    }

    const filteredStoresListWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, list, filterType, propertyKey, value }) => {
        // Tricks to allow filter on groups since groups is an array of object and groupNames an array of string
        // And advanced filter only allows filter on array of strings
        const formattedPropertyKey = propertyKey === 'groups' ? 'groupNames' : propertyKey;

        if (filterType === 'string' && value && value.length === 1 && value[0].id === 'none') {
          return doFilter(result, formattedPropertyKey, list, true);
        }

        return doFilter(result, formattedPropertyKey, value);
      },
      updatedFilteredStoresList,
    );

    setFilteredStoresList(filteredStoresListWithAdvancedFilters);
    setIsLoading(false);
  };

  const processStores = () => {
    if (!stores || !stores.length || isInitializingFilters) {
      return;
    }

    const formattedStores = stores.map((store) => {
      const formattedStore = { ...store };

      formattedStore.status = store.active
        ? i18next.t('GENERAL.ACTIVE')
        : store.active === null
        ? i18next.t('ADMIN.STORES.LIST_STATUS_NOT_DEFINED_M')
        : i18next.t('GENERAL.INACTIVE');

      formattedStore.adress = store.adress || i18next.t('ADMIN.STORES.LIST_STATUS_NOT_DEFINED_F');

      formattedStore.city = store.city || i18next.t('ADMIN.STORES.LIST_STATUS_NOT_DEFINED_F');

      formattedStore.partnerId = store.partnerId || '';

      if (store.partnerToken === null) {
        formattedStore.partnerToken = '';
      }

      formattedStore.brand = store.lnkBrandStorerel ? store.lnkBrandStorerel.name : '';

      formattedStore.groupNames = store.groups.length
        ? store.groups.map(({ name }) => name)
        : [i18next.t('GENERAL.SELECT_NONE_MASCULINE')];

      return formattedStore;
    });

    setStoresList(formattedStores);
  };

  const processGroups = async () => {
    try {
      const groups = await clientService.getGroupsOfClient(clientId);
      setClientGroups(groups);
    } catch {
      showErrorMessage(i18next.t('BACKOFFICE.GROUPS.FETCHING_ERROR'));
    }
  };

  const getFilterListForProperty = (list, property) =>
    chain(list)
      .reduce((acc, item, index) => {
        if (!!item[property]) {
          acc.push({
            id: index,
            name: item[property],
            [property]: item[property],
          });
        }

        return acc;
      }, [])
      .sortBy('name')
      .sortedUniqBy(property)
      .value();

  const updateColumnsFilterList = (stores) => {
    const cityColumn = dataTitles.find(({ propertyKey }) => propertyKey === 'city');
    cityColumn.list = getFilterListForProperty(stores, 'city');

    const groupsColumn = dataTitles.find(({ propertyKey }) => propertyKey === 'groups');
    const groupFilterList = chain(stores)
      .flatMap('groups')
      .map(({ name, id }) => ({ name, id, groupNames: name }))
      .sortBy('name')
      .sortedUniqBy('name')
      .value();

    groupFilterList.unshift({
      id: -1,
      name: i18next.t('GENERAL.SELECT_NONE_MASCULINE'),
      groupNames: i18next.t('GENERAL.SELECT_NONE_MASCULINE'),
    });

    groupsColumn.list = groupFilterList;

    const brandColumn = dataTitles.find(({ propertyKey }) => propertyKey === 'brand');

    if (!hasMultipleBrands || !brandColumn) {
      setColumnsFilterList([cityColumn, groupsColumn]);

      return;
    }

    brandColumn.list = getFilterListForProperty(stores, 'brand');

    setColumnsFilterList([brandColumn, cityColumn, groupsColumn]);
  };

  /********************/
  /** GETTERS **/
  /********************/

  const getStatusFiltersFromQueryParams = () => {
    const filters = listViewQueryParams[ENUM_QUERY_PARAMS.FILTERS];

    if (!filters) {
      return CHOICES_DROPDOWN_ACTIVE[0];
    }

    return filters[ENUM_CUSTOM_FILTERS.STATUS];
  };

  /********************/
  /** CALL TO ACTION **/
  /********************/

  const handleGoToStoreDetailPage = (store) => {
    history.push({
      pathname: '/admin/stores/' + store.id + '/details',
    });
  };

  const handleForActiveDropdown = (items) => {
    setListViewQueryParams[ENUM_QUERY_PARAMS.FILTERS]({
      ...listViewQueryParams[ENUM_QUERY_PARAMS.FILTERS],
      [ENUM_CUSTOM_FILTERS.STATUS]: items,
    });
  };

  const handleStoreTypeFilter = (storeType) => {
    setSelectedStoreTypes(storeType);
  };

  const resetModalStates = () => {
    setStoreIdReference(null);
    setModalStep(null);
  };

  const handleAssortmentSetup = (stores) => {
    setStoresToSetup(stores);

    const assortmentSetupModalConfig = getAssortmentSetupModal(
      storesList,
      stores,
      storeNameSingle,
      hasMultipleBrands,
      storeIdReference,
      setStoreIdReference,
      resetModalStates,
      setModalStep,
    );

    setModalStep(MODAL_STEP.STORE_SELECTION);

    openGenericModal(assortmentSetupModalConfig);
  };

  const handleProgressBarModal = (stores, storeIdReference) => {
    openModalExportInfo({
      component: ProgressBarModal,
      customStyle: {
        overflow: 'initial',
      },
      stores,
      storeIdReference,
      setModalStep,
    });
  };

  const handleExport = (storesToExport) => {
    const currentStoresToExport = storesToExport.length
      ? storesToExport
      : listViewRef.current.getFilteredData();

    const data = currentStoresToExport.map((item) => {
      const updatedItem = cloneDeep(item);

      return {
        ...updatedItem,
        active: updatedItem.active ? i18next.t('GENERAL.ACTIVE') : i18next.t('GENERAL.INACTIVE'),
        isKitchen: updatedItem.isKitchen // We don't need to check hasCentralKitchens because the check has already been made on the columns and the export is base on them
          ? i18next.t('GENERAL.CENTRAL')
          : i18next.t('GENERAL.SALE_POINT'),
      };
    });

    const sheetName = `${i18next.t('ADMIN.STORES.LIST_SHEET_TITLE')} ${storeNamePlural}`;

    const columns = [
      ...dataTitles,
      {
        propertyKey: 'active',
        name: i18next.t('ORDERS.ORDERS.LIST_LABEL_STATUS'),
      },
    ];

    const sheet = utilsXLS.generateDefaultSheet(sheetName, columns, data);

    utilsXLS.makeXLS(storeNamePlural, [sheet]);
  };

  const handleGroupsChange = (data) => {
    const updatedDataModal = data.reduce((result, current) => {
      if (!result[current.value]) {
        result[current.value] = {
          ...omit(current, 'ids'),
          associatedItems: current.ids.map((id) => ({
            id,
          })),
          useStoreCountLabel: true,
        };
      }
      return result;
    }, {});

    const params = getGroupsModalParams(
      updatedDataModal,
      handleGroupsChange,
      handleGroupsSave,
      storeNameSingle,
      PROPERTY_NONE,
      false,
    );

    refreshGenericModal(params);
  };

  const handleManageGroups = () => {
    const formattedGroups = { [PROPERTY_NONE]: { associatedItems: [] } };

    stores.forEach((store) => {
      if (!store.groups.length) {
        return formattedGroups[PROPERTY_NONE].associatedItems.push(store);
      }
    });

    formattedGroups[PROPERTY_NONE].useStoreCountLabel = true;

    clientGroups.forEach((group) => {
      formattedGroups[group.name] = {
        ...group,
        associatedItems: group.stores,
        useStoreCountLabel: true,
      };
    });

    const params = getGroupsModalParams(
      formattedGroups,
      handleGroupsChange,
      handleGroupsSave,
      storeNameSingle,
      PROPERTY_NONE,
    );

    openGenericModal(params);
  };

  const handleGroupsSave = async (groups) => {
    try {
      pageLoading();

      const formattedGroups = Object.entries(groups).reduce((acc, group) => {
        const { value, originalItem } = last(group);

        if (value === PROPERTY_NONE) {
          return acc;
        }

        if (!get(originalItem, 'id')) {
          acc.push({ name: value });
          return acc;
        }

        acc.push({ id: originalItem.id, name: value });

        return acc;
      }, []);

      await clientService.createOrUpdateGroupsByBatch(clientId, formattedGroups);

      // Refresh client groups
      await Promise.all([processGroups(), getStoresOfUser()]);

      showSuccessMessage(i18next.t('ADMIN.STORES.MANAGE_GROUPS_SUCCESS'));
    } catch (error) {
      showErrorMessage(i18next.t('ADMIN.STORES.MANAGE_GROUPS_FAILURE'));
    } finally {
      pageLoaded();
    }
  };

  const handleAssignGroups = () => {
    const selectedStoreIds = selectedStores.map(({ id }) => id);

    const params = getAssignGroupListTemplatesModalParams(
      i18next.t('ADMIN.STORES.MODAL_ASSIGN_GROUPS_TITLE'),
      true,
      clientGroups.sort((a, b) => a.name.localeCompare(b.name)),
      selectedStoreIds,
      handleStoreGroupMappingsChange,
      manageStoreGroupMapping,
    );

    openGenericModal(params);
  };

  const handleStoreGroupMappingsChange = (mappings, shouldAssociate) => {
    const groupsToMap = Object.values(mappings).reduce((result, item) => {
      if (item.checked) {
        result.push(item.id);
      }

      return result;
    }, []);

    refreshGenericModal(
      {
        items: mappings,
        actions: [
          GENERIC_MODAL_CANCEL_BUTTON(),
          {
            ...GENERIC_MODAL_SAVE_BUTTON(),
            handleClick: () =>
              manageStoreGroupMapping(mappings.linkedData, groupsToMap, shouldAssociate),
            isDisabled: false,
          },
        ],
      },
      ['actions', 'items'], // based on category/sub-category behaviour
    );
  };

  const manageStoreGroupMapping = (storeIds, groupIds, shouldAssociate) => {
    if (shouldAssociate) {
      associateStoreGroupMapping(storeIds, groupIds);
      return;
    }

    dissociateStoreGroupMapping(storeIds, groupIds);
  };

  const associateStoreGroupMapping = async (storeIds, groupIds) => {
    pageLoading();

    try {
      await groupService.associateStoreGroupMapping(storeIds, groupIds);

      // Fetch in redux an updated version of the stores
      await getStoresOfUser();

      showSuccessMessage(i18next.t('ADMIN.STORES.MODAL_ASSIGN_GROUP_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORES.MODAL_ASSIGN_GROUP_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const dissociateStoreGroupMapping = async (storeIds, groupIds) => {
    pageLoading();

    try {
      await groupService.dissociateStoreGroupMapping(storeIds, groupIds);

      // Fetch in redux an updated version of the stores
      await getStoresOfUser();

      showSuccessMessage(i18next.t('ADMIN.STORES.MODAL_DISSOCIATE_GROUP_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORES.MODAL_DISSOCIATE_GROUP_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleDissociateGroups = () => {
    const selectedStoreIds = selectedStores.map(({ id }) => id);
    const selectedStoreGroups = chain(selectedStores)
      .flatMap('groups')
      .sortBy('name')
      .sortedUniqBy('id') // uniqBy optimized for sorted arrays
      .value();

    const params = getAssignGroupListTemplatesModalParams(
      i18next.t('ADMIN.STORES.MODAL_DISSOCIATE_GROUPS_TITLE'),
      false,
      selectedStoreGroups,
      selectedStoreIds,
      handleStoreGroupMappingsChange,
      manageStoreGroupMapping,
    );

    openGenericModal(params);
  };

  return (
    <>
      <NavigationBreadCrumb featurePath={path} />
      <Container>
        <ListView
          actionOnClick={handleGoToStoreDetailPage}
          actions={actions}
          columns={dataTitles}
          data={filteredStoresList}
          defaultCurrentPage={listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE]}
          defaultMaxPerPage={listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]}
          defaultOrderBy={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY] || 'name'}
          defaultOrderType={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE] || 'asc'}
          defaultSearchInput={listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH]}
          handleCurrentPageChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](input)
          }
          handleMaxPerPageChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE](input)
          }
          handleOrderByChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY](input)}
          handleOrderTypeChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE](input)
          }
          handleSearchInputChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.SEARCH](input)
          }
          isLoading={isLoading}
          languageCode={userLanguageCode}
          minActionsInActionsDropdown={1}
          padding={'24px 24px 0px 24px'}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          ref={listViewRef}
          renderEmptyState={() => <EmptyState />}
          renderFilterButton={() => (
            <DeepsightFiltersButton
              advancedFilters={advancedFilters}
              applyFilters={applyFilters}
              columnsFilterList={columnsFilterList}
              customMultipleDropDowns={customMultipleDropDowns}
              customSingleDropDowns={[
                {
                  id: ENUM_CUSTOM_FILTERS.STATUS,
                  itemSelectedIcon: '/images/inpulse/power-ip-black.svg',
                  list: CHOICES_DROPDOWN_ACTIVE,
                  defaultSelectedItem: getStatusFiltersFromQueryParams(),
                  selectedItem: getStatusFiltersFromQueryParams(),
                  setSelectedItem: handleForActiveDropdown,
                },
              ]}
              filters={filters}
              isLoading={isLoading}
              minWidth={120}
              readOnly={isLoading}
              setAdvancedFilters={setAdvancedFilters}
              setApplyFilters={setApplyFilters}
              setFilters={setFilters}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
            />
          )}
          rowActions={rowActions}
          setSelectedItems={setSelectedStores}
        />
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params, customPropertyPathsToUpdate) => {
    dispatch(refreshGenericModal(params, customPropertyPathsToUpdate));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showSuccessMessage: (message, type) => {
    dispatch(showSuccessMessage(message, type));
  },
  showErrorMessage: (message, type) => {
    dispatch(showErrorMessage(message, type));
  },
  getStoresOfUser: () =>
    dispatch(getStoresOfUser()).then(
      (result) => {
        dispatch(receiveStores(result));
      },
      (error) => {
        dispatch(requestStoresError(error));
      },
    ),
});

const mapStateToProps = (state) => ({
  stores: state.baseReducer.stores,
  message: state.confirmationMessageReducer.messageBool,
  user: state.baseReducer.user,
  modal: state.modalReducer,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/admin/stores'),
});

const ConnectedStores = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withListViewQueryParamHookHOC(Stores));

// ===== Module features for easy import in ClientContainer =====
import StoreDetails from './StoreDetails';
import StoreProductMapping from './StoreDetailsModules/StoreProductMappingList';
import StoreSupplierProductMappingDetails from './StoreDetailsModules/StoreSupplierProductMappingDetails';

const AdminStoresModule = {
  Stores: ConnectedStores,
  StoreDetails,
  StoreProductMapping,
  StoreSupplierProductMappingDetails,
};

export default AdminStoresModule;
