import { cloneDeep, findIndex, get, isEmpty, orderBy } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useEffect, useRef, useState } from 'react';

import { closeGenericModal } from '@actions/modal';
import { loading, loadingFailure, loadingSuccess } from '@actions/loading';
import {
  showConfirmationMessage,
  showErrorMessage,
  showSuccessMessage,
} from '@actions/messageconfirmation';

import { CHANNELS } from '@commons/constants/channel';
import { ENUM_MODULE_NAME, isUserAllowedToAccessProduction } from '@commons/utils/features';
import { getIsCentralMode } from '@commons/utils/localStorage';
import { isUserManager } from '@commons/utils/roles';
import NavBar from '@commons/NavigationBar';

import { getClientInfo } from '@selectors/client';

import { product as productService } from '@services/product';
import channelService from '@services/channel';
import recipeService from '@services/recipe';

import EnableProductModal, {
  enableProductMapCashierProductsAndAddProductionParameters,
} from '../components/EnableProductModal';

import {
  Container,
  ContainerContent,
  NavigationBar,
  NavigationPage,
  Section,
} from './styledComponents';
import CashierProductAssociation from './components/CashierProductAssociation';
import ProductComposition, { getCompositionTypes } from './components/ProductRecipeAssociation';
import ProductDetailsBottomBar from './components/BottomBar';
import ProductInfoSection from './components/ProductInfo';
import ProductPictureSection from './components/ProductPicture';
import ProductProductionPlanningAssociation from './components/ProductProductionPlanningAssociation';
import utils from './utils';
import utilsProductInfo from './components/ProductInfo/utils';

const PRODUCT_ID_CREATE_MODE = 'create';
const EMPTY_PRODUCT = { name: '', active: true };

const getRecipeSubPage = () => ({
  name: i18next.t('ADMIN.PRODUCTS.COMPOSITION'),
  render: (props, isReadOnly) => (
    <ProductComposition
      channels={props.channels}
      clientHasMultipleChannels={props.clientHasMultipleChannels}
      composition={props.composition}
      isReadOnly={isReadOnly}
      product={props.product}
      selectedCompoType={props.selectedCompoType}
      setComposition={props.setComposition}
      setRecipeEatInside={props.setRecipeEatInside}
      setRecipeTakeAway={props.setRecipeTakeAway}
      setSelectedCompoType={props.setSelectedCompoType}
      onProductChange={props.onProductChange}
    />
  ),
});

const getCashierProductsSubPage = (cashierProducts) => ({
  name: i18next.t('ADMIN.PRODUCTS.CASHIER_PRODUCTS_SECTION_TITLE', {
    count: cashierProducts.length,
  }),
  render: (props, isReadOnly) => (
    <CashierProductAssociation
      cashierProducts={props.cashierProducts}
      history={props.history}
      isReadOnly={isReadOnly}
      product={props.product}
      selectedCashierProduct={props.selectedCashierProduct}
      setCashierProducts={props.setCashierProducts}
      setSelectedCashierProduct={props.setSelectedCashierProduct}
    />
  ),
});

const getProductionPlanningSubPage = (storesProductionPlanning) => ({
  name: i18next.t(
    !getIsCentralMode()
      ? 'ADMIN.SUB_FEATURES.PRODUCTION_ASSORTMENTS'
      : 'ADMIN.PRODUCTS.PRODUCTION_PLANNING',
    { count: storesProductionPlanning.length },
  ),
  render: (props, isReadOnly) => (
    <ProductProductionPlanningAssociation
      history={props.history}
      isReadOnly={isReadOnly}
      product={props.product}
      setStoresProductionPlanning={props.setStoresProductionPlanning}
      storesProductionPlanning={props.storesProductionPlanning}
    />
  ),
});

export function renderSectionProductInfo(props, isReadOnly) {
  return (
    <Section>
      <ProductInfoSection
        isProductSynced={props.isProductSynced}
        isReadOnly={isReadOnly}
        product={props.product}
        productId={props.productId}
        onProductChange={props.onProductChange}
      />

      <ProductPictureSection
        isReadOnly={isReadOnly}
        product={props.product}
        onProductChange={props.onProductChange}
      />
    </Section>
  );
}

export function renderSectionProductionSubPages(props, isReadOnly) {
  const { activeSubPages, product } = props;

  return (
    <>
      {activeSubPages.length > 1 && (
        <Section>
          <NavigationBar>
            {activeSubPages.map((page, indexPage) => (
              <NavigationPage
                className={props.selectedProductSubPage.name === page.name ? 'selected' : ''}
                isDisabled={!product.active}
                key={indexPage}
                onClick={() => product.active && props.onSelectedProductSubPage(page)}
              >
                {page.name}
              </NavigationPage>
            ))}
          </NavigationBar>
        </Section>
      )}

      <Section nbSubPages={props.activeSubPages.length} sectionType="subpage">
        {props.selectedProductSubPage.render(props, isReadOnly)}
      </Section>
    </>
  );
}

export function renderContent(props) {
  const path = get(props, 'match.path');

  const isManager = isUserManager(props.user);

  const isReadOnly = isManager || !get(props.product, 'active');

  return (
    <>
      <NavBar
        action={
          !isManager && (
            <ProductDetailsBottomBar
              cashierProducts={props.cashierProducts}
              channels={props.channels}
              clientHasMultipleChannels={props.clientHasMultipleChannels}
              composition={props.composition}
              history={props.history}
              pageLoaded={props.pageLoaded}
              pageLoading={props.pageLoading}
              product={props.product}
              productId={props.productId}
              selectedCompoType={props.selectedCompoType}
              setOpenEnableModal={props.setOpenEnableModal}
              showMessage={props.showMessage}
              user={props.user}
            />
          )
        }
        bigTopBar={true}
        module={ENUM_MODULE_NAME.PRODUCT_DETAIL}
        path={path}
        product={props.product}
        productId={props.productId}
      />
      <ContainerContent>
        {renderSectionProductInfo(props, isReadOnly)}
        {renderSectionProductionSubPages(props, isReadOnly)}
      </ContainerContent>
    </>
  );
}

/**
 * Retrieve the list of deepsight channels and set it to the local state
 *
 * @param {Function} setChannels - Method to set the local state channels
 *
 * @returns {Promise<void>}
 */
export async function fetchChannels(setChannels) {
  const channels = await channelService.getChannels();

  setChannels(channels);

  return channels;
}

/**
 * Retrieve the list of recipes associated to the product
 *
 * @param {String} productId                  - The product id on which retrieve the recipes
 * @param {Channel[]} channels                - The list of deepsight channels
 * @param {Boolean} clientHasMultipleChannels - Whether the client has multiple channels setting activated
 * @param {Function} setRecipeEatInside       - Method to set the local state of the recipe 'SP'
 * @param {Function} setRecipeTakeAway        - Method to set the local state of the recipes ['TG', 'LIV']
 *
 * @returns {void}
 */
export async function fetchMappedRecipes(
  productId,
  channels,
  clientHasMultipleChannels,
  setRecipeEatInside,
  setRecipeTakeAway,
) {
  const recipes = await productService.getProductMappings(productId);

  if (!clientHasMultipleChannels && recipes.length) {
    const recipe = await recipeService.getRecipe(recipes[0].entity.id);

    setRecipeEatInside(utils.getRecipeCost(recipe));

    return;
  }

  const mappedRecipeEatInside = recipes.find((item) =>
    channels.some((channel) => channel.id === item.channelId && channel.name === CHANNELS.ON_SITE),
  );

  if (mappedRecipeEatInside) {
    const recipeEatInside = await recipeService.getRecipe(mappedRecipeEatInside.entity.id);

    setRecipeEatInside(utils.getRecipeCost(recipeEatInside));
  }

  const mappedRecipeTakeAway = recipes.find((item) =>
    channels.some((channel) => channel.id === item.channelId && channel.name === CHANNELS.DELIVERY),
  );

  if (mappedRecipeTakeAway) {
    const recipeTakeAway = await recipeService.getRecipe(mappedRecipeTakeAway.entity.id);

    setRecipeTakeAway(utils.getRecipeCost(recipeTakeAway));
  }
}

/**
 * Retrieve the list of stores mapped with this product
 *
 * @param {String} productId                      - The id of the product on which retrieve the mapped stores
 * @param {Function} setStoresProductionPlanning  - Method to set the local state storesProductionPlanning
 *
 * @returns {Promise<void>}
 */
export async function fetchMappedStores(productId, setStoresProductionPlanning) {
  const storesProductionPlanning = await productService.getProductionStores(productId);

  const activeStoresProductionPlanning = storesProductionPlanning.filter(({ active }) => active);

  setStoresProductionPlanning(orderBy(activeStoresProductionPlanning, 'storeName'));

  return activeStoresProductionPlanning;
}

/**
 * Retrieve the list of cashier products mapped with this product
 *
 * @param {String} productId             - The id of the product on which retrieve the mapped cashier products
 * @param {Function} setCashierProducts  - Method to set the local state cashierProducts
 *
 * @returns {Promise<void>}
 */
export async function fetchCashierProducts(productId, setCashierProducts) {
  const cashierProducts = await productService.getCashierProducts(productId);

  setCashierProducts(orderBy(cashierProducts, 'name'));

  return cashierProducts;
}

export const ProductDetails = (props) => {
  const {
    match: { params },
    client: { hasMultipleChannels, areProductsSync },
    hasUserAccessToProductionFeature,
  } = props;

  const canAccessProductionPlanning = hasUserAccessToProductionFeature;

  const compositionTypes = getCompositionTypes();

  const [productId, setProductId] = useState(params.id);

  const [cashierProducts, setCashierProducts] = useState([]);
  const cashierProductsRef = useRef();

  const [storesProductionPlanning, setStoresProductionPlanning] = useState([]);
  const storesProductionPlanningRef = useRef();

  const [product, onProductChange] = useState(EMPTY_PRODUCT);
  const [isLoading, setLoading] = useState(true);

  const [activeSubPages, setActiveSubPages] = useState([getRecipeSubPage()]);

  const [selectedProductSubPage, onSelectedProductSubPage] = useState();

  const [selectedCashierProduct, setSelectedCashierProduct] = useState({});
  const [isProductSynced, setIsProductSynced] = useState(true);

  const [openEnableModal, setOpenEnableModal] = useState(false);

  // Product composition
  const [composition, setComposition] = useState({});
  const [selectedCompoType, setSelectedCompoType] = useState(compositionTypes[0]);

  /**
   * Takes care of the loading state and success/failure toaster for the API call
   * @param {*} formattedData Result from formatDataForApiCall in EnableProductModal/modalConfiguration.js
   */
  const handleCallToEnableProduct = async (formattedData) => {
    props.pageLoading();

    try {
      await enableProductMapCashierProductsAndAddProductionParameters(formattedData);

      setProductId(null); // force useEffect for refresh
      setProductId(productId);
      props.showSuccessMessage(i18next.t('ADMIN.PRODUCTS.ENABLE_PRODUCT_SUCCESS'));
    } catch (err) {
      props.showErrorMessage(
        i18next.t('ADMIN.PRODUCTS.ENABLE_PRODUCT_FAILURE', {
          step: err.message,
        }),
      );
    } finally {
      props.pageLoaded();
    }
  };

  const setCompositionOnFirstLoad = (fetchedProduct) => {
    if (!fetchedProduct.lnkEntityProductrel) {
      return;
    }

    setComposition(fetchedProduct.lnkEntityProductrel);
    setSelectedCompoType(
      fetchedProduct.lnkEntityProductrel.isIngredient ? compositionTypes[2] : compositionTypes[1],
    );
  };

  useEffect(() => {
    props.pageLoading();

    onSelectedProductSubPage(getRecipeSubPage());

    (async function loadData() {
      const subPagesToAdd = [];

      try {
        if (!productId || productId === PRODUCT_ID_CREATE_MODE) {
          setLoading(false);

          props.pageLoaded();

          return;
        }

        const fetchedProduct = await productService.getById(productId);

        if (!!fetchedProduct.lnkEntityProductrel) {
          const recipeCost = await recipeService.getRecipeCost(fetchedProduct.entityId);

          fetchedProduct.lnkEntityProductrel.costByChannelId = recipeCost.costByChannelId;
        }

        onProductChange({
          ...fetchedProduct,
          brand: fetchedProduct.lnkBrandProductrel ? fetchedProduct.lnkBrandProductrel.name : '',
          price: fetchedProduct.priceWithTaxes,
          deliveryPrice: fetchedProduct.deliveryPriceWithTaxes,
        });

        setCompositionOnFirstLoad(fetchedProduct);

        props.pageLoaded();

        setLoading(false);

        if (areProductsSync) {
          const fetchedCashierProducts = await fetchCashierProducts(productId, setCashierProducts);

          if (
            !activeSubPages.find(
              (page) => page.name === getCashierProductsSubPage(fetchedCashierProducts).name,
            )
          ) {
            subPagesToAdd.push(getCashierProductsSubPage(fetchedCashierProducts));
          }
        }

        if (canAccessProductionPlanning) {
          const fetchedStores = await fetchMappedStores(productId, setStoresProductionPlanning);
          if (
            !activeSubPages.find(
              (page) => page.name === getProductionPlanningSubPage(fetchedStores).name,
            )
          ) {
            subPagesToAdd.push(getProductionPlanningSubPage(fetchedStores));
          }
        }
      } catch (err) {
        props.pageDidNotLoad();
        setLoading(false);
      } finally {
        setActiveSubPages(activeSubPages.concat(subPagesToAdd));
      }
    })();
  }, [productId]);

  useEffect(() => {
    const syncedCashierProduct = cashierProducts.find((cashierProduct) => cashierProduct.priceSync);

    if (syncedCashierProduct && syncedCashierProduct.id !== selectedCashierProduct.id) {
      setIsProductSynced(true);
      setSelectedCashierProduct(syncedCashierProduct);
    }

    if (!cashierProductsRef) {
      return;
    }

    if (cashierProductsRef.current) {
      const updatedActiveSubPages = cloneDeep(activeSubPages);

      const cashierProductActiveSubPageIndex = findIndex(
        updatedActiveSubPages,
        (page) => page.name === getCashierProductsSubPage(cashierProductsRef.current).name,
      );

      if (cashierProductActiveSubPageIndex >= 0) {
        updatedActiveSubPages[cashierProductActiveSubPageIndex] =
          getCashierProductsSubPage(cashierProducts);

        setActiveSubPages(updatedActiveSubPages);
      }
    }

    cashierProductsRef.current = cashierProducts;
  }, [cashierProducts]);

  // To update the value in parenthesis in the production planning sub page title
  useEffect(() => {
    if (!storesProductionPlanningRef) {
      return;
    }

    if (storesProductionPlanningRef.current) {
      const updatedActiveSubPages = cloneDeep(activeSubPages);

      // spp stands for storesProductionPlanning
      const sppActiveSubPageIndex = findIndex(
        updatedActiveSubPages,
        (page) =>
          page.name === getProductionPlanningSubPage(storesProductionPlanningRef.current).name,
      );

      if (sppActiveSubPageIndex >= 0) {
        updatedActiveSubPages[sppActiveSubPageIndex] =
          getProductionPlanningSubPage(storesProductionPlanning);

        setActiveSubPages(updatedActiveSubPages);
      }
    }

    storesProductionPlanningRef.current = storesProductionPlanning;
  }, [storesProductionPlanning]);

  // To update the value in parenthesis in the cashier products sub page title
  useEffect(() => {
    if (isEmpty(selectedCashierProduct)) {
      setIsProductSynced(false);

      const CPWithoutPriceSync = cashierProducts.map((cashierProduct) => ({
        ...cashierProduct,
        priceSync: false,
      }));

      setCashierProducts(CPWithoutPriceSync);
      return;
    }

    setIsProductSynced(true);

    const priceHT = utilsProductInfo.getPriceExcludingVAT(selectedCashierProduct);

    onProductChange({
      ...product,
      vatRate: selectedCashierProduct.vatRate,
      price: selectedCashierProduct.priceWithTaxes,
      priceHT,
    });

    const updateCashierProducts = cashierProducts.map((cashierProduct) => ({
      ...cashierProduct,
      priceSync: cashierProduct.id === selectedCashierProduct.id,
    }));

    setCashierProducts(updateCashierProducts);
  }, [selectedCashierProduct]);

  if (isLoading) {
    return null;
  }

  return (
    <Container>
      {renderContent({
        ...props,
        product,
        productId,
        onProductChange,
        clientHasMultipleChannels: hasMultipleChannels,
        canAccessProductionPlanning,
        selectedProductSubPage,
        onSelectedProductSubPage,
        storesProductionPlanning,
        setStoresProductionPlanning,
        cashierProducts,
        setCashierProducts,
        selectedCashierProduct,
        setSelectedCashierProduct,
        activeSubPages,
        isProductSynced,
        setOpenEnableModal,
        composition,
        setComposition,
        selectedCompoType,
        setSelectedCompoType,
      })}
      {openEnableModal && (
        <EnableProductModal
          handleCallToEnableProduct={handleCallToEnableProduct}
          product={product}
          resetEnablingProduct={() => setOpenEnableModal(false)}
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  hasUserAccessToProductionFeature: isUserAllowedToAccessProduction(state.baseReducer.userRights),
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  pageDidNotLoad: () => {
    dispatch(loadingFailure());
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
