import styled from 'styled-components';

export const ContentContainer = styled.div`
  width: 100%;
  height: 254px;
  padding: 0px 48px;

  display: flex;
  flex-direction: row;
  column-gap: 24px;
  align-items: center;
`;

export const Text = styled.div`
  font: ${(props) => props.theme.fonts?.textSmallHeight24};
  color: ${(props) => props.theme.colors?.greys?.darker};
`;
