import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { formatNumber } from '@commons/DisplayNumber';

import storeService from '@services/store';

import { config } from '@src/config';

import { getEntityUnit } from '@stocks/utils';
import { PAST_STOCKS_TYPE } from '@stocks/utils/constants';

import { METRICS } from '../utils/commons';

const INVENTORY_LINK = {
  [PAST_STOCKS_TYPE.INGREDIENTS]: 'stocks/inventories/stocks',
  [PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS]:
    'central-kitchen-stocks/inventories/supplier-products',
  [PAST_STOCKS_TYPE.CENTRAL_KITCHEN_RECIPES]: 'central-kitchen-stocks/inventories/recipes',
};

export const getPastInventoryOfEntityId = async (
  entityId,
  selectedStore,
  selectedMetric,
  currency,
  stockType,
  showErrorMessage,
) => {
  try {
    const pastInventories = await storeService.getLastInventoriesByEntityId(
      selectedStore.id,
      entityId,
      moment().format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
    );

    pastInventories.sort(
      (inventoryA, inventoryB) =>
        moment(inventoryB.inventoryDate).format('YYYYMMDD') -
        moment(inventoryA.inventoryDate).format('YYYYMMDD'),
    );

    return pastInventories.map((item) => {
      const inventoryDate = moment(item.inventoryDate).format(
        DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR,
      );

      const openLink = () =>
        `${config.baseAppUrl}${INVENTORY_LINK[stockType]}?search=${item.inventoryListReference}`;

      const quantity =
        selectedMetric === METRICS[0]
          ? `${formatNumber(item.price, currency.numberDecimals)} ${currency.alphabeticCode}`
          : `${item.quantity.toFixed(2)} ${getEntityUnit(item.unit)}`;

      return {
        ...item,
        inventoryDate,
        openLink,
        quantity,
      };
    });
  } catch {
    showErrorMessage(i18next.t('STOCKS.PAST_STOCKS.FETCH_PAST_INVENTORIES_ON_INGREDIENT_FAILED'));

    return {};
  }
};
