import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const ButtonLabelContainer = styled.div`
  display: flex;
  gap: 8px;
`;
