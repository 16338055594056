import { get } from 'lodash';

export const getStatsFromData = (data) => ({
  mix: get(data, 'stats.mix', 0),
  mixExcludingTaxes: get(data, 'stats.mixExcludingTaxes', 0),
  unit: get(data, 'stats.unit', 0),
  price: get(data, 'stats.price', 0),
  priceExcludingTaxes: get(data, 'stats.priceExcludingTaxes', 0),
  ca: get(data, 'stats.ca', 0),
  turnover: get(data, 'stats.turnover', 0),
  turnoverExcludingTaxes: get(data, 'stats.turnoverExcludingTaxes', 0),
  margin: get(data, 'stats.margin', 0),
  marginRatio: get(data, 'stats.marginRatio', 0),
});

export default getStatsFromData;
