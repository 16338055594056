const getSupplierProfileEmails = (supplierProfile, type) => {
  switch (type) {
    case 'order':
      return !!supplierProfile.email1 ? supplierProfile.email1 : '';
    case 'credit':
      return !!supplierProfile.email2 ? supplierProfile.email2 : '';
    default:
      return '';
  }
};

export default getSupplierProfileEmails;
