import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  padding: 24px;
  gap: 8px;

  background: white;

  border-bottom: 1px solid ${(props) => props.theme.colors.greys.light};
`;

export const Icon = styled.img`
  width: 8px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-right: 8px;
  padding: 24px 24px 24px 0;

  position: absolute;
  bottom: 0;

  background: white;
  display: flex;
  justify-content: end;
  gap: 24px;

  border-top: 1px solid ${(props) => props.theme.colors.greys.light};
`;

export const Content = styled.div`
  width: 100%;
  padding: 0px;
  overflow: scroll;
  position: relative;
  height: calc(100% - 130px);
`;
