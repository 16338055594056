import { isEmpty, isEqual } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { Checkbox } from '@commons/utils/styledLibraryComponents';

import { Input } from '@lib/inpulse/Input';

import utilsRegistration from '../../utils';

import {
  Container,
  InputContainer,
  MatchPasswordRulesContainer,
  CheckRules,
  Rule,
  RuleMessage,
  ErrorText,
} from './styledComponents';

const UserPassword = (props) => {
  const { handleSetUserInformations } = props.params;

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [errorMatchPassword, setErrorMatchPassword] = useState(false);

  const [rules, setRules] = useState(utilsRegistration.getPasswordRegexRules());

  useEffect(() => {
    if (!password.length) {
      setRules(utilsRegistration.getPasswordRegexRules());
      handleSetUserInformations('password', '');

      return;
    }

    setRules(
      utilsRegistration.getPasswordValidation(password, utilsRegistration.getPasswordRegexRules()),
    );
  }, [password]);

  useEffect(() => {
    if (!rules.all.isValid) {
      handleSetUserInformations('password', '');
    }

    if (!isEqual(password, confirmPassword)) {
      setErrorMatchPassword(true);
      handleSetUserInformations('password', '');

      return;
    }

    if (isEmpty(rules) || !rules.all.isValid || (!password.length && !confirmPassword.length)) {
      setErrorMatchPassword(false);
      return;
    }

    setErrorMatchPassword(false);
    handleSetUserInformations('password', password);
  }, [confirmPassword, password]);

  return (
    <>
      <Container>
        <InputContainer>
          <div>
            <Input
              label={`* ${i18next.t('GENERAL.PASSWORD')}`}
              type="password"
              value={password}
              width={240}
              autoFocus
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <div>
            <Input
              error={errorMatchPassword}
              label={`* ${i18next.t('GENERAL.PASSWORD_CONFIRM')}`}
              type="password"
              value={confirmPassword}
              width={240}
              onChange={(event) => setConfirmPassword(event.target.value)}
            />
            {errorMatchPassword && (
              <ErrorText>
                {i18next.t('AUTHENTICATION.REGISTRATION.LANDING_STEP_TWO_MATCH_PASSWORD_ERROR')}
              </ErrorText>
            )}
          </div>
        </InputContainer>

        <MatchPasswordRulesContainer>
          <CheckRules>
            {!!rules &&
              Object.keys(rules).map((ruleName) => {
                if (ruleName === 'all') {
                  return;
                }

                return (
                  <Rule key={ruleName}>
                    <Checkbox
                      isChecked={rules[ruleName].isValid}
                      margin={'0 8px 0 0'}
                      shape="square"
                      isDark
                      isDisableBorder
                      isDisabled
                      isSmallCheck
                      isValidation
                      noSize
                    />
                    <RuleMessage>{rules[ruleName].message}</RuleMessage>
                  </Rule>
                );
              })}
          </CheckRules>
        </MatchPasswordRulesContainer>
      </Container>
    </>
  );
};

export default UserPassword;
