import Checkbox from './Checkbox/Checkbox';
import ComponentLoader from './ComponentLoader';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import Input from './Input/Input';
import InputNumber from './Input/InputNumber';
import Label from './Label/Label';
import LabelSpot from './LabelSpot';
import NewInpulseLabel from './InpulseLabel';
import RadioButton from './RadioButton';
import ResetPasswordInputContainer from './ResetPasswordInputContainer/ResetPasswordInputContainer';
import TabletBarcodeSearchBar from './TabletOptimized/TabletBarcodeSearchBar/TabletBarcodeSearchBar';
import TabletDeepsightButton from './TabletOptimized/TabletDeepsightButton/TabletDeepsightButton';
import UploadPhotoNew from './UploadPhotoNew/UploadPhotoNew';

export const DeepsightInputNumber = InputNumber;
export const DeepsightInput = Input;
export const DeepsightLabel = Label;
export const InpulseLabel = NewInpulseLabel;
export const DeepsightLabelSpot = LabelSpot;
export const DeepsightCheckbox = Checkbox;
export const DeepsightRadioButton = RadioButton;
export const DeepsightUploadPicture = UploadPhotoNew;
export const DeepsightComponentLoader = ComponentLoader;
export const DeepsightConfirmationModal = ConfirmationModal;
export const DeepsightTabletDeepsightButton = TabletDeepsightButton;
export const DeepsightTabletBarcodeSearchBar = TabletBarcodeSearchBar;
export const DeepsightResetPasswordInputContainer = ResetPasswordInputContainer;
