import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import { Dropdown } from '@commons/utils/styledLibraryComponents';
import { INPULSE_ROLE_NAME } from '@commons/constants/inpulseRole';
import { REGEX_MATCH_EMAIL } from '@commons/utils/regex';
import WhiteCard from '@commons/WhiteCard';

import clientService from '@services/client';

import {
  Container,
  DropdownIcon,
  DropdownItem,
  FirstLetter,
  Input,
  InputContainer,
  InvalidEmail,
  Label,
  Text,
} from './styledComponents';

const DEFAULT_LANGUAGE_CODE = 'fr';

export const UserInformationModal = (props) => {
  const { roles, updateUserInformations, userInformations, isEmailAvailable, catalogs } = props;

  const [rolesDropdownItems, setRolesDropdownItems] = useState([]);
  const [catalogDropdownItems, setCatalogDropdownItems] = useState([
    { id: -1, name: i18next.t('GENERAL.SELECT_NONE_MASCULINE') },
  ]);
  const [languagesDropdownItems, setLanguagesDropdownItems] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedCatalog, setSelectedCatalog] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  useEffect(() => {
    fetchLanguages();
  }, []);

  useEffect(() => {
    const rolesWithoutInpulse = roles.filter(({ name }) => name !== INPULSE_ROLE_NAME);

    setRolesDropdownItems(rolesWithoutInpulse);

    setCatalogDropdownItems([...catalogDropdownItems, ...catalogs]);
  }, [roles, catalogs]);

  useEffect(() => {
    if (!!userInformations.roleId) {
      setSelectedRole(rolesDropdownItems.find(({ id }) => id === userInformations.roleId));
    }
    if (!!userInformations.languageId) {
      setSelectedLanguage(
        languagesDropdownItems.find(({ id }) => id === userInformations.languageId),
      );
    }

    if (!!userInformations.languageId) {
      setSelectedLanguage(
        languagesDropdownItems.find(({ id }) => id === userInformations.languageId),
      );
    }

    setSelectedCatalog(
      catalogDropdownItems.find(({ id }) => id === userInformations.catalogId) ||
        catalogDropdownItems[0],
    );
  }, [userInformations, rolesDropdownItems, languagesDropdownItems]);

  const fetchLanguages = async () => {
    try {
      const languages = await clientService.getAllLanguages();
      const defaultLanguage = languages.find(({ code }) => code === DEFAULT_LANGUAGE_CODE);

      const renderedLanguages = renderLanguagesDropdownItems(languages);

      setLanguagesDropdownItems(renderedLanguages);

      if (!userInformations.languageId) {
        setSelectedLanguage(defaultLanguage);
        updateUserInformations('languageId', defaultLanguage.id);
      }
    } catch {
      showErrorMessage(i18next.t('USERS.DETAILS.FETCH_CLIENT_LANGUAGES_ERROR'));
    }
  };

  const renderLanguagesDropdownItems = (languages) =>
    languages.map((language) => ({
      ...language,
      renderValue: () => (
        <DropdownItem>
          <DropdownIcon src={`/images/flags/icon-flag-${language.code}.svg`} />
          <Text>{language.name}</Text>
        </DropdownItem>
      ),
    }));

  return (
    <Container>
      <WhiteCard
        content={
          <InputContainer>
            <Label>
              <FirstLetter>
                {i18next.t('BACKOFFICE.TEAM.FIRST_STEP_EMAIL_ADDRESS')[0]}
                &nbsp;
              </FirstLetter>
              <span>{`${i18next.t('BACKOFFICE.TEAM.FIRST_STEP_EMAIL_ADDRESS').substring(1)}`}</span>
            </Label>
            <Input
              isEmailAvailable={isEmailAvailable}
              pattern={REGEX_MATCH_EMAIL}
              placeholder={i18next.t('BACKOFFICE.TEAM.FIRST_STEP_EMAIL_ADDRESS').slice(1, -1)}
              type="text"
              value={userInformations.email}
              onChange={(ev) => updateUserInformations('email', ev.target.value)}
            />
            {!isEmailAvailable && (
              <InvalidEmail>
                {i18next.t('BACKOFFICE.TEAM.FIRST_STEP_EMAIL_NOT_AVAILABLE')}
              </InvalidEmail>
            )}
          </InputContainer>
        }
        title={i18next.t('USERS.DETAILS.NAV_BAR_SUB_PAGE_INFORMATION')}
      />
      <WhiteCard
        content={
          <>
            <InputContainer>
              <Dropdown
                isLabelTextSmall={true}
                isRequired={true}
                items={rolesDropdownItems}
                label={`${i18next.t('BACKOFFICE.TEAM.FIRST_STEP_ROLE')} :`}
                placeholder={i18next.t('GENERAL.DROPDOWN_PLACEHOLDER')}
                selectedItem={selectedRole}
                onSelectionChange={(selectedItem) => {
                  updateUserInformations('roleId', selectedItem.id);
                }}
              />
            </InputContainer>
            {catalogs && catalogs.length > 0 && (
              <InputContainer>
                <Dropdown
                  items={catalogDropdownItems}
                  label={`${i18next.t('BACKOFFICE.TEAM.FIRST_STEP_CATALOG')} :`}
                  placeholder={i18next.t('GENERAL.DROPDOWN_PLACEHOLDER')}
                  selectedItem={selectedCatalog}
                  tooltipText={i18next.t('BACKOFFICE.TEAM.FIRST_STEP_CATALOG_TOOLTIP')}
                  isLabelTextSmall
                  isRequired
                  onSelectionChange={({ id }) => {
                    updateUserInformations('catalogId', id);
                  }}
                />
              </InputContainer>
            )}
            <InputContainer>
              <Dropdown
                iconSrc={
                  selectedLanguage ? `/images/flags/icon-flag-${selectedLanguage.code}.svg` : null
                }
                isLabelTextSmall={true}
                isRequired={true}
                items={languagesDropdownItems}
                label={`${i18next.t('USERS.DETAILS.PROFILE_DEFAULT_LANGUAGE_INPUT_LABEL')} :`}
                selectedItem={selectedLanguage}
                onSelectionChange={(selectedItem) => {
                  updateUserInformations('languageId', selectedItem.id);
                }}
              />
            </InputContainer>
          </>
        }
        title={i18next.t('BACKOFFICE.TEAM.FIRST_STEP_PARAMETERS')}
      />
    </Container>
  );
};

export default UserInformationModal;
