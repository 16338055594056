import { isEmpty } from 'lodash';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

function getFirstPossibleDeliveryDates(deliveryDays, deliveryLeadTimes, orderTimeLimit, timezone) {
  let firstPossibleDeliveryDates = [];

  deliveryDays.forEach((deliveryDay, idx) => {
    deliveryDay += 1; // + 1 : same format as moment().day()
    const deliveryLeadTime = deliveryLeadTimes[idx];
    let deliveryLeadTimeFromToday = moment.tz(timezone).add(deliveryLeadTime, 'days').day();
    if (deliveryLeadTimeFromToday === 0) deliveryLeadTimeFromToday = 7;

    let nextDeliveryDate = null;
    const daysToAdd = deliveryDay - 1; // if day == 1 (monday), then add 0 days
    if (deliveryLeadTimeFromToday > deliveryDay) {
      nextDeliveryDate = moment
        .tz(timezone)
        .add(deliveryLeadTime, 'days')
        .add(1, 'week')
        .startOf('week')
        .add(daysToAdd, 'day')
        .startOf('day')
        .format();
    } else if (deliveryLeadTimeFromToday === deliveryDay) {
      // Trick to have correct datetime limit with timezone applied
      const orderDateTimeLimitString = `${moment
        .tz(timezone)
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)}T${orderTimeLimit}`;

      const orderTimeLimitPassed = !isEmpty(orderTimeLimit)
        ? moment.tz(orderDateTimeLimitString, timezone).isBefore(moment.tz(timezone)) // today is the limit order day
        : false; // order time limit can not be passed if we do not have order time limit

      nextDeliveryDate = orderTimeLimitPassed
        ? moment
            .tz(timezone)
            .add(deliveryLeadTime, 'days')
            .add(1, 'week')
            .startOf('week')
            .add(daysToAdd, 'day')
            .startOf('day')
            .format()
        : moment
            .tz(timezone)
            .add(deliveryLeadTime, 'days')
            .startOf('week')
            .add(daysToAdd, 'day')
            .startOf('day')
            .format();
    } else {
      nextDeliveryDate = moment
        .tz(timezone)
        .add(deliveryLeadTime, 'days')
        .startOf('week')
        .add(daysToAdd, 'day')
        .startOf('day')
        .format();
    }
    firstPossibleDeliveryDates[idx] = nextDeliveryDate;
  });

  return firstPossibleDeliveryDates;
}

function getFirstDeliveryWeekDaysOfSupplierProfile(
  deliveryDays,
  deliveryLeadTimes,
  orderTimeLimit,
  timezone,
) {
  let firstPossibleDeliveryDates = getFirstPossibleDeliveryDates(
    deliveryDays,
    deliveryLeadTimes,
    orderTimeLimit,
    timezone,
  );

  // format to weekDays
  let supplierFirstPossibleDeliveryDatesByDeliveryDay = {};

  firstPossibleDeliveryDates.forEach((deliveryDate) => {
    supplierFirstPossibleDeliveryDatesByDeliveryDay[moment.tz(deliveryDate, timezone).day()] =
      deliveryDate;
  });

  return supplierFirstPossibleDeliveryDatesByDeliveryDay;
}

export default getFirstDeliveryWeekDaysOfSupplierProfile;
