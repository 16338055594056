import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SendEmail = styled.span`
  margin-top: 24px;
  font: ${(props) => props.theme.fonts?.h2};
  color: ${(props) => props.theme.colors?.greys?.darkest};
`;

export const EmailValidationText = styled.span`
  margin-top: 8px;
  font: ${(props) => props.theme.fonts?.textBigHeight24};
  color: ${(props) => props.theme.colors?.greys?.darker};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;

  column-gap: 24px;
`;

export const RadioSelectButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding: 0px 24px 0px 14px;
  column-gap: 8px;

  border: ${(props) => `2px solid ${props.theme.colors?.greys?.darkest}`};
  box-sizing: border-box;
  border-radius: 20px;
  transition: 0.3s;

  > div > div {
    border: ${(props) => `2px solid ${props.theme.colors?.greys?.darkest} !important`};
  }

  &:hover {
    transition: 0.3s;
    cursor: pointer;
    border-color: ${(props) => props.theme.colors?.brand?.primary};
    color: ${(props) => props.theme.colors?.brand?.primary};
    > span {
      transition: 0.3s;
      color: ${(props) => props.theme.colors?.brand?.primary};
    }
    > div > div {
      transition: 0.3s;
      border-color: ${(props) => props.theme.colors?.brand?.primary} !important;
      > div {
        background-color: ${(props) => props.theme.colors?.brand?.primary};
      }
    }
  }

  &:active {
    border-color: ${(props) => props.theme.colors?.brand?.primaryDark};
    color: ${(props) => props.theme.colors?.brand?.primaryDark};
    > span {
      color: ${(props) => props.theme.colors?.brand?.primaryDark};
    }
    > div > div {
      border-color: ${(props) => props.theme.colors?.brand?.primaryDark};
      > div {
        background-color: ${(props) => props.theme.colors?.brand?.primaryDark};
      }
    }
  }
`;

export const ButtonText = styled.span`
  transition: 0.3s;

  font: ${(props) => props.theme.fonts?.textMiddleBold};
  color: ${(props) => props.theme.colors?.greys?.darkest};
`;
