import { get } from 'lodash';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getUserTimezone } from '@commons/utils/date';

import { PRODUCT_ORDER_TYPE } from '@orders/OrderList/constants';

import getOrderReference from './getOrderReference';
import getTotalAmount from './getTotalAmount';
import getTotalPrice from './getTotalPrice';

const formatEmails = (emails) => (!Array.isArray(emails) ? String(emails) : emails.join(','));

const getOrderInfos = ({
  status,
  preparationStatus,
  storeId,
  params,
  orderId,
  endDate,
  startDate,
  partnerId,
  supplierId,
  savingType,
  isEditable,
  receptionComment,
  orderOpeningTime,
  receptionReference,
  productsInventory,
  isDeliveryDateUpdated,
  purchaseOrderComment,
  emailRecipients = '',
  emailRecipientsCopy = '',
  creditRequestData = {},
  storeTimezone,
  creditPictureUri = '',
}) => {
  const userTimezone = getUserTimezone();
  const creditOrderRecipientsEmails = get(creditRequestData, 'recipientEmails', '');
  const creditOrderCopyEmails = get(creditRequestData, 'copyEmails', '');

  const orderDate =
    savingType !== 'afterDelivery'
      ? moment.tz(userTimezone).format()
      : moment.tz(params.order.orderDate, userTimezone);
  const startOrderDate =
    params.order && !isDeliveryDateUpdated
      ? moment
          .tz(params.order.startOrderDate, storeTimezone)
          .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)
      : moment(startDate).clone().startOf('day').format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
  const endOrderDate = isDeliveryDateUpdated
    ? moment(endDate).clone().startOf('day').format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)
    : params.order
    ? moment
        .tz(params.order.endOrderDate, storeTimezone)
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)
    : moment(endDate).clone().endOf('day').format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

  const purchaseOrderRecipients = formatEmails(emailRecipients);
  const purchaseOrderCopy = formatEmails(emailRecipientsCopy);
  const creditOrderRecipients = formatEmails(creditOrderRecipientsEmails);
  const creditOrderCopy = formatEmails(creditOrderCopyEmails);

  const orderInfos = {
    type: 'food',
    storeId,
    isEditable,
    supplierId,
    status,
    preparationStatus,
    id: orderId || undefined,
    reference: getOrderReference(get(params.order, 'reference'), partnerId, orderOpeningTime),
    totalPrice: getTotalPrice(productsInventory),
    totalItems: getTotalAmount(productsInventory, PRODUCT_ORDER_TYPE.ORDERED),
    orderDate,
    startOrderDate,
    endOrderDate,
    purchaseOrderRecipients,
    purchaseOrderCopy,
    purchaseOrderComment,
    creditOrderRecipients,
    creditOrderCopy,
    creditOrderComment: get(creditRequestData, 'comment', ''),
    creditOrderPicture: creditPictureUri,
  };

  if (savingType === 'afterDelivery') {
    orderInfos.receptionReference = receptionReference;
    orderInfos.receptionComment = receptionComment;
    orderInfos.receptionDate = moment.tz(userTimezone).format();
  }

  return orderInfos;
};

export default getOrderInfos;
