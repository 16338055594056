import { connect } from 'react-redux';
import React from 'react';
import styled from 'styled-components';

class SlideModalContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shouldRender: this.props.shouldRender,
      isMount: false,
      componentToDisplay: null,
      slide: 'slide-in',
      fade: 'fade-in',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shouldRender !== this.props.shouldRender) {
      if (this.props.shouldRender) {
        this.setState({
          isMount: true,
          componentToDisplay: this.props.component,
          slide: 'slide-in',
          fade: 'fade-in',
          shouldRender: true,
        });
      } else {
        if (!this.props.loading.EVENTS) {
          this.setState({ slide: 'slide-out', fade: 'fade-out' });
          setTimeout(
            () =>
              this.setState({
                isMount: false,
                componentToDisplay: null,
              }),
            300,
          );
        }
      }
    }
    if (
      prevProps.loading !== this.props.loading &&
      this.props.shouldRender !== this.state.shouldRender
    ) {
      if (!this.props.shouldRender) {
        this.setState({
          slide: 'slide-out',
          fade: 'fade-out',
          shouldRender: false,
        });
        setTimeout(
          () =>
            this.setState({
              isMount: false,
              componentToDisplay: null,
            }),
          300,
        );
      }
    }
  }

  render() {
    const { closeModal, ...rest } = this.props;
    const Component = this.state.componentToDisplay;
    return this.state.isMount ? (
      <div className={rest.className}>
        <div className="slide-modal-background" id={this.state.fade} onClick={closeModal} />
        <div className="slide-modal-container" id={this.state.slide}>
          <div className="slide-modal-main-container">
            {typeof Component !== 'undefined' && <Component closeModal={closeModal} {...rest} />}
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  loading: state.loadingReducer,
});

const connectedSlideModal = connect(mapStateToProps)(SlideModalContainer);

const StyledSlideModalContainer = styled(connectedSlideModal)`
  .slide-modal-background {
    position: fixed;
    top: 0px;
    width: calc(100%);
    height: calc(100%);
    background-color: rgba(108, 108, 108, 0.38);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    overflow: hidden;
    opacity: 0;
  }

  .slide-modal-container {
    position: absolute;
    height: calc(100% - 40px);
    margin: 20px;
    z-index: 10;
    margin-left: calc(70%);
    left: ${(props) => (props.theme.colors ? undefined : '580px')};
  }

  .slide-modal-main-container {
    float: left;
    min-width: 30%;
    height: 100vh;
    overflow-y: scroll;
    background: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
  }
`;

export default StyledSlideModalContainer;
