import i18next from 'i18next';

import { canSetupAssortment } from '@selectors/actions/storeActions';

export const ELISION_CHECK_REGEX = /^[aeiouyéèh]$/i;

export const getActions = (
  selectedStores,
  handleExport,
  handleAssortmentSetup,
  handleManageGroups,
  handleAssignGroups,
  handleDissociateGroups,
  clientHasGroups,
  storeName,
  authorizedActions,
) => {
  const hasSelectedItems = selectedStores.length > 0;

  const hasSelectedStoresGroups = selectedStores.some(({ groups }) => groups && groups.length);

  const areSelectedStoresCentral = selectedStores.some(({ isKitchen }) => isKitchen);

  const actions = [
    {
      id: 'manage-groups',
      actionLabel: i18next.t('ADMIN.STORES.MANAGE_GROUPS'),
      actionIcon: () => '/images/inpulse/gear-black-small.svg',
      handleAction: handleManageGroups,
      isHidden: () => hasSelectedItems,
    },
    {
      id: 'setup-assortment',
      actionLabel: i18next.t('ADMIN.STORES.SETUP_ASSORTMENT_FROM_ANOTHER_STORE', { storeName }),
      actionIcon: () =>
        areSelectedStoresCentral
          ? '/images/inpulse/settings-lgrey.svg'
          : '/images/inpulse/settings.svg',
      handleAction: () => handleAssortmentSetup(selectedStores),
      isDisabled: () => areSelectedStoresCentral,
      disabledTooltipText: () =>
        i18next.t('ADMIN.STORES.SETUP_ASSORTIMENT_UNAVAILABLE_FOR_CENTRAL_MESSAGE'),
      isHidden: () => !canSetupAssortment(authorizedActions) || !hasSelectedItems,
    },
    {
      id: 'assign-groups',
      actionLabel: i18next.t('ADMIN.STORES.ASSIGN_GROUPS'),
      actionIcon: () =>
        clientHasGroups ? '/images/inpulse/link-ip-black.svg' : '/images/inpulse/link-grey.svg',
      isDisabled: () => !clientHasGroups,
      disabledTooltipText: () => i18next.t('ADMIN.STORES.NO_GROUPS_TOOLTIP'),
      handleAction: handleAssignGroups,
      isHidden: () => !hasSelectedItems,
    },
    {
      id: 'dissociate-groups',
      actionLabel: i18next.t('ADMIN.STORES.DISSOCIATE_GROUPS'),
      actionIcon: () =>
        clientHasGroups ? '/images/inpulse/link-ip-black.svg' : '/images/inpulse/link-grey.svg',
      isDisabled: () => !clientHasGroups,
      disabledTooltipText: () => i18next.t('ADMIN.STORES.NO_GROUPS_TOOLTIP'),
      handleAction: handleDissociateGroups,
      isHidden: () => !hasSelectedStoresGroups,
    },
    {
      id: 'export-list',
      actionLabel: i18next.t(hasSelectedItems ? 'GENERAL.EXPORT_SELECTION' : 'GENERAL.EXPORT_LIST'),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: () => handleExport(hasSelectedItems ? selectedStores : []),
    },
  ];

  return actions;
};

export const getRowActions = (
  storeName,
  handleExport,
  handleAssortmentSetup,
  authorizedActions,
) => {
  const isStoreNameFirstLetterAnElision = ELISION_CHECK_REGEX.test(storeName[0]);

  return [
    {
      id: 'setup-assortment',
      actionLabel: () =>
        i18next.t('ADMIN.STORES.SETUP_ASSORTMENT_FROM_ANOTHER_STORE', { storeName }),
      actionIcon: ({ isKitchen }) =>
        isKitchen ? '/images/inpulse/settings-lgrey.svg' : '/images/inpulse/settings.svg',
      isHidden: () => !canSetupAssortment(authorizedActions),
      handleAction: (selectedStore) => handleAssortmentSetup([selectedStore]),
      disabledTooltipText: () =>
        i18next.t('ADMIN.STORES.SETUP_ASSORTIMENT_UNAVAILABLE_FOR_CENTRAL_MESSAGE'),
      isDisabled: ({ isKitchen }) => isKitchen,
    },
    {
      id: 'export',
      actionLabel: () =>
        i18next.t(
          isStoreNameFirstLetterAnElision
            ? 'ADMIN.STORES.EXPORT_STORE_WITH_FIRST_LETTER_VOWEL'
            : 'ADMIN.STORES.EXPORT_STORE_WITH_FIRST_LETTER_CONSONANT',
          { storeName },
        ),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: (selectedStore) => handleExport([selectedStore]),
    },
  ];
};
