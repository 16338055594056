import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import {
  Container,
  Content,
  DropdownContainer,
  DropdownItem,
  DropdownIcon,
} from './styledComponents';
import { Dropdown, Button } from '../../../commons/utils/styledLibraryComponents';
import { showErrorMessage } from '../../../actions/messageconfirmation';

import utilsXLS from '../../../commons/utils/makeXLS';

import clientService from '../../../services/client';
import NavigationBreadCrumb from '../../../commons/Breadcrumb/NavigationBreadCrumb';
import Text from '../../../commons/Text';
import WhiteCard from '../../../commons/WhiteCard';

import { DATE_DISPLAY_FORMATS } from '../../../commons/DatePickers/constants';

const DEFAULT_LANGUAGE_CODE = 'fr';

/**
 * Generate an array of 1 dimension objects from an array of n dimension objects.
 * e.g. : entryArray => [{ a: { b : { c: 'value' } } }]
 * returns => [{ a.b.c : 'value' }]
 *
 * @param {Object[]} obj - The data to flatten
 * @param {String} prefix - Initial key prefix
 *
 * @returns {Object[]}
 */
const flattenObject = (obj, prefix = '') =>
  Object.keys(obj).reduce((acc, key) => {
    const previous = prefix.length ? prefix + '.' : '';

    if (typeof obj[key] === 'object') {
      Object.assign(acc, flattenObject(obj[key], previous + key));
      return acc;
    }

    acc[previous + key] = obj[key];
    return acc;
  }, {});

export const BackOfficeTranslations = (props) => {
  const { match } = props;

  const path = _.get(match, 'path');

  const [languagesDropdownItems, setLanguagesDropdownItems] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  useEffect(() => {
    fetchLanguages();
  }, []);

  const fetchLanguages = async () => {
    try {
      const languages = await clientService.getAllLanguages();

      const formattedLanguages = languages.map((language) => ({
        ...language,
        iconSrc: `/images/flags/icon-flag-${language.code}.svg`,
      }));

      setLanguagesDropdownItems(renderLanguages(formattedLanguages));
      setSelectedLanguages(formattedLanguages);
    } catch {
      showErrorMessage(i18next.t('USERS.DETAILS.FETCH_CLIENT_LANGUAGES_ERROR'));
    }
  };

  const renderLanguages = (languages) =>
    languages.map((language) => ({
      ...language,
      isDisabled: language.code === DEFAULT_LANGUAGE_CODE,
      renderValue: () => (
        <DropdownItem>
          <DropdownIcon src={language.iconSrc} />
          <Text>
            {language.name} - {language.code.toUpperCase()}
          </Text>
        </DropdownItem>
      ),
    }));

  const handleDownload = () => {
    const currentDate = moment().format(DATE_DISPLAY_FORMATS.STICKED_YEAR_MONTH_DAY);
    const fileName = `${i18next.t('FEATURE.BACKOFFICE.TRANSLATIONS')}_${currentDate}`;

    const headers = generateTranslationHeaders(selectedLanguages);

    const translationData = generateTranslationData(selectedLanguages);

    // Flatten Data
    // inpute data format is [{ fr: { module : { page : { translationKey: 'value', ...}}}}, { en: { module : { page : { translationKey: 'value', ...}}}}, ...]
    // we try to flatten this data to match the xls files data input format : [{ module: '', page: '', translationKey: '', Français-FR: '', English-EN: ''}, ...]
    const flattenedData = [];
    translationData.map((lang) => {
      const flattenedLang = flattenObject(lang);

      // For each flattened key (format: fr.module.page.translationKey OR fr.module.translationKey OR fr.translationKey),
      // we generate a row matching xls files input format { module: '', page: '', translationKey: '', fr: '' }
      Object.keys(flattenedLang).forEach((translationKey) => {
        const formattedTranslationKey = {};

        const splitKey = translationKey.split('.');
        const splitLength = splitKey.length;
        switch (splitLength) {
          case 2: // fr.translationKey
            formattedTranslationKey.translationKey = splitKey[1];
            break;
          case 3: // fr.module.translationKey
            formattedTranslationKey.translationKey = splitKey[2];
            formattedTranslationKey.module = splitKey[1];
            break;
          case 4: // fr.module.page.translationKey
            formattedTranslationKey.translationKey = splitKey[3];
            formattedTranslationKey.page = splitKey[2];
            formattedTranslationKey.module = splitKey[1];
            break;
          default:
        }
        formattedTranslationKey[splitKey[0]] = flattenedLang[translationKey];
        flattenedData.push(formattedTranslationKey);
      });
    });

    const formattedData = groupByHeaders(flattenedData);

    const translationSheet = utilsXLS.generateDefaultSheet(
      i18next.t('FEATURE.BACKOFFICE.TRANSLATIONS'),
      headers,
      formattedData,
    );
    utilsXLS.makeXLS(fileName, [translationSheet]);
    return true;
  };

  const generateTranslationHeaders = (languages) => {
    const headers = [
      {
        propertyKey: 'translationKey',
        name: i18next.t('BACKOFFICE.TRANSLATIONS.TRANSLATION_FILES_HEADERS_TRANSLATION_KEY'),
      },
      {
        propertyKey: 'module',
        name: i18next.t('BACKOFFICE.TRANSLATIONS.TRANSLATION_FILES_HEADERS_MODULE'),
      },
      {
        propertyKey: 'page',
        name: i18next.t('BACKOFFICE.TRANSLATIONS.TRANSLATION_FILES_HEADERS_PAGE'),
      },
    ];

    languages.map((language) =>
      headers.push({
        propertyKey: language.code,
        name: `${language.name} - ${language.code.toUpperCase()}`,
      }),
    );
    return headers;
  };

  const generateTranslationData = (languages) =>
    languages.reduce((result, { code }) => {
      const dataByCode = _.clone(i18next.getDataByLanguage(code));

      if (dataByCode) {
        dataByCode[code] = dataByCode['translation'];
        delete dataByCode['translation'];
        result.push(dataByCode);
      }

      return result;
    }, []);

  const groupByHeaders = (array) => {
    const keyList = ['module', 'page', 'translationKey'];
    const helper = {};
    const formattedData = array.reduce((acc, translation) => {
      const key = translation.module + '.' + translation.page + '.' + translation.translationKey;

      if (!helper[key]) {
        helper[key] = translation;
        acc.push(helper[key]);
        return acc;
      }

      const code = Object.keys(translation).find(
        (translationKey) => !keyList.includes(translationKey),
      );
      helper[key][code] = translation[code];

      return acc;
    }, []);
    return formattedData;
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <Content>
        <WhiteCard
          renderContent={
            <>
              <Text>{i18next.t('BACKOFFICE.TRANSLATIONS.TRANSLATION_FILES_DOWNLOAD_CONTENT')}</Text>
              <DropdownContainer>
                <Dropdown
                  iconSrc={selectedLanguages.length ? selectedLanguages[0].iconSrc : null}
                  isDisabled={false}
                  isUniqueSelection={false}
                  items={languagesDropdownItems}
                  selectedItems={selectedLanguages}
                  sortBy={(itemsList) => itemsList}
                  onSelectionChange={(selectedItems) => setSelectedLanguages(selectedItems)}
                />
                <Button
                  buttonCustomStyle={{
                    width: 'fit-content',
                  }}
                  fontSize={14}
                  formatText={false}
                  handleClick={() => {
                    handleDownload();
                  }}
                  icon={'/images/inpulse/file-download-white-small.svg'}
                  isDisabled={false}
                  label={i18next.t('GENERAL.DOWNLOAD')}
                />
              </DropdownContainer>
            </>
          }
          title={i18next.t('BACKOFFICE.TRANSLATIONS.TRANSLATION_FILES_DOWNLOAD_TITLE')}
        />
      </Content>
    </Container>
  );
};

export default BackOfficeTranslations;
