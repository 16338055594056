import { weatherday } from '../services/weatherday';

export const fetchWeather = (weatherStationId, storeTimezone, startDate, endDate) =>
  function (dispatch) {
    dispatch({
      type: 'WEATHER_REQUEST',
    });
    return weatherday.getWeatherForecast(weatherStationId, storeTimezone, startDate, endDate);
  };
export const receiveWeather = (weather) => ({
  type: 'WEATHER_SUCCESS',
  weather,
});
export const fetchWeatherError = (error) => ({
  type: 'WEATHER_FAILURE',
  error,
});

export const fetchPastWeather = (weatherStationId, storeTimezone, startDate, endDate) =>
  function (dispatch) {
    dispatch({
      type: 'PAST_WEATHER_REQUEST',
    });
    return weatherday.getWeatherForecast(weatherStationId, storeTimezone, startDate, endDate);
  };
export const receivePastWeather = (pastWeather) => ({
  type: 'PAST_WEATHER_SUCCESS',
  pastWeather,
});
export const fetchPastWeatherError = (error) => ({
  type: 'PAST_WEATHER_FAILURE',
  error,
});

export const fetchLyWeather = (weatherStationId, storeTimezone, startDate, endDate) =>
  function (dispatch) {
    dispatch({
      type: 'LY_WEATHER_REQUEST',
    });
    return weatherday.getWeatherForecast(weatherStationId, storeTimezone, startDate, endDate);
  };
export const receiveLyWeather = (lyWeather) => ({
  type: 'LY_WEATHER_SUCCESS',
  lyWeather,
});
export const fetchLyWeatherError = (error) => ({
  type: 'LY_WEATHER_FAILURE',
  error,
});
