import React from 'react';

import _ from 'lodash';

import { TableFormEmptyState } from './components/EmptyState';
import { TableFormLineFixedPart } from './components/LineFixedPart';
import { TableFormLineMovablePart } from './components/LineMovablePart';

import { ENUM_STATUS_LINE } from './components/LineFixedPart/constants';
import { FixedPart, LinesContainer, MovablePart } from './styledComponents';

export const TableFormContent = (props) => {
  const {
    emptyState,
    form,
    fieldArrayName,
    fields,
    columns,
    deleteFunction,
    resolveInputs,
    isEditionAllowed,
    register,
    displayStatusIcon,
  } = props;

  const fixedColumns = columns.filter((column) => column.isFixed);
  const movableColumns = columns.filter((column) => !column.isFixed);

  const movableColumnsInputs = movableColumns.map((column) => column.input);

  if (_.isEmpty(fields)) {
    return (
      <LinesContainer isEmpty={true}>
        <TableFormEmptyState columns={fixedColumns} emptyState={emptyState} />
      </LinesContainer>
    );
  }

  const getRowStatus = (index) => {
    const { errors } = form.formState;

    const path = `${fieldArrayName}[${index}]`;

    const inputErrors = _.get(errors, path);

    const { hasErrors, isLoading } = form.getValues(path);

    if (isLoading) {
      return ENUM_STATUS_LINE.LOADING;
    }

    if (_.isEmpty(inputErrors)) {
      return ENUM_STATUS_LINE.VALID;
    }

    return hasErrors ? ENUM_STATUS_LINE.INVALID : ENUM_STATUS_LINE.IDLE;
  };

  return (
    <LinesContainer>
      <FixedPart>
        {fields.map((field, index) => (
          <TableFormLineFixedPart
            columns={fixedColumns}
            displayStatusIcon={displayStatusIcon}
            field={field}
            key={field.id}
            status={getRowStatus(index)}
          />
        ))}
      </FixedPart>
      <MovablePart>
        {fields.map((field, index) => (
          <TableFormLineMovablePart
            deleteFunction={deleteFunction}
            field={field}
            fieldArrayName={fieldArrayName}
            form={form}
            index={index}
            inputs={
              !!resolveInputs
                ? resolveInputs(form.getValues(`${fieldArrayName}[${index}]`))
                : movableColumnsInputs
            }
            isEditionAllowed={isEditionAllowed}
            key={field.id}
            register={register}
          />
        ))}
      </MovablePart>
    </LinesContainer>
  );
};
