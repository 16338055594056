import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { product as productService } from '@services/product';
import centralService from '@services/central';

const PRODUCTS_TO_DELETE_AT_ONCE = 50;

const DeleteProductsModal = (props) => {
  const {
    user,
    params: { products, title, refreshPage, isInCentralKitchenOnlyPage },
    closeModal,
    showSuccessMessage,
    showErrorMessage,
  } = props;

  const clientId = get(user, 'lnkClientAccountrel.id');

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState(title || '');
  const [totalDeletedProductsNb, setTotalDeletedProductsNb] = useState(0);

  const productsToDeleteNb = products.length;

  useEffect(() => {
    if (isLoading && totalDeletedProductsNb < productsToDeleteNb) {
      const nextBatchOfProductsToDelete = products.slice(
        totalDeletedProductsNb,
        totalDeletedProductsNb + PRODUCTS_TO_DELETE_AT_ONCE,
      );

      const productsToDeleteIds = nextBatchOfProductsToDelete.map((product) => product.id);

      (async function handleQueryForProductsDelete() {
        try {
          if (isInCentralKitchenOnlyPage) {
            await centralService.deleteCentralKitchenProductsByIds(clientId, productsToDeleteIds);
          } else {
            await productService.deleteProductByIds(clientId, productsToDeleteIds);
          }

          const updatedDeletionNb = totalDeletedProductsNb + nextBatchOfProductsToDelete.length;

          setTotalDeletedProductsNb(updatedDeletionNb);
          updateProgress(updatedDeletionNb);
        } catch (error) {
          setModalTitle(
            i18next.t('ADMIN.PRODUCTS.DELETION_FAILURE', { count: productsToDeleteNb }),
          );
          setIsLoading(false);
          showErrorMessage(
            i18next.t('ADMIN.PRODUCTS.DELETION_FAILURE', { count: productsToDeleteNb }),
          );
        }
      })();

      return;
    }

    setModalTitle(i18next.t('ADMIN.PRODUCTS.DELETION_SUCCESS', { count: productsToDeleteNb }));
    setIsLoading(false);
    showSuccessMessage(i18next.t('ADMIN.PRODUCTS.DELETION_SUCCESS', { count: productsToDeleteNb }));
  }, [totalDeletedProductsNb]);

  const exitModal = () => {
    setIsLoading(false);
    refreshPage();
    closeModal();
  };

  const updateProgress = (totalDeleted) => {
    const productsLeft = productsToDeleteNb - totalDeleted;
    const updatedProgress = 100 - (productsLeft / productsToDeleteNb) * 100;
    setProgress(updatedProgress);
  };

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal} // passed from openSmallModal
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setIsLoading={setIsLoading}
      titleModal={modalTitle}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProductsModal);
