import styled from 'styled-components';

export const ResetEmailContentContainer = styled.div`
  width: 100%;
  height: 208px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0px 96px;
  row-gap: 24px;
`;

export const Text = styled.span`
  color: ${(props) => props.theme.colors?.greys.darker};
  font: ${(props) => props.theme.fonts?.textBigHeight24};
`;

export const InputContainer = styled.div`
  width: 240px;
`;

export const EmailSentContentContainer = styled.div`
  width: 100%;
  height: 254px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 0px 48px;
  column-gap: 24px;
`;
