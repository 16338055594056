import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(122, 122, 122, 0.1);
`;

export const EmptyStateContainer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors?.greys.lighter};
`;

export const MainText = styled.div`
  color: ${(props) => props.theme.colors?.greys.darkest};
  font: ${(props) => props.theme.fonts?.h2};
  margin-top: 48px;
`;

export const Text = styled.div`
  color: ${(props) => props.theme.colors?.greys.darker};
  font: ${(props) => props.theme.fonts?.textSmallHeight16};
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const TextContainer = styled.div`
  text-align: center;
`;

export const EmptyStateIcon = styled.img`
  width: 200px;
  margin-left: 25px;
  margin-top: 40px;
  position: absolute;

  &.center {
    margin: auto;
    display: flex;
    position: relative;
  }

  &.image-loader {
    width: 3em;
  }
`;
