export const tooltipByStoreAnalyticsUnit = {
  forecastedQuantity: 'PRODUCTION.REAL_TIME.LABEL_FORECAST_TOOLTIP_UNIT',
  stockedQuantity: 'PRODUCTION.REAL_TIME.LABEL_STOCKS_TOOLTIP_UNIT',
  quantityProductionRatio: 'PRODUCTION.REAL_TIME.LABEL_PRODUCTION_RATIO_TOOLTIP_UNIT',
  toProduceQuantity: 'PRODUCTION.REAL_TIME.LABEL_TO_PRODUCE_TOOLTIP_UNIT',
  producedQuantity: 'PRODUCTION.REAL_TIME.LABEL_PRODUCED_TOOLTIP_UNIT',
  sales: 'PRODUCTION.REAL_TIME.LABEL_SALES_TOOLTIP_UNIT',
  lossQuantity: 'PRODUCTION.REAL_TIME.LABEL_LOSS_TOOLTIP_UNIT',
  quantityTurnoverWasteRate: 'PRODUCTION.REAL_TIME.REPORT_LABEL_LOSS_RATIO_TOOLTIP',
  quantityProductionWasteRate: 'PRODUCTION.REAL_TIME.LABEL_LOSS_RATIO_TOOLTIP_UNIT',
};

export const tooltipByStoreAnalyticsTurnover = {
  forecastedTurnover: 'PRODUCTION.REAL_TIME.LABEL_FORECAST_TOOLTIP_TURNOVER',
  stockedTurnover: 'PRODUCTION.REAL_TIME.LABEL_STOCKS_TOOLTIP_TURNOVER',
  turnoverProductionRatio: 'PRODUCTION.REAL_TIME.LABEL_PRODUCTION_RATIO_TOOLTIP_TURNOVER',
  toProduceTurnover: 'PRODUCTION.REAL_TIME.LABEL_TO_PRODUCE_TOOLTIP_TURNOVER',
  producedTurnover: 'PRODUCTION.REAL_TIME.LABEL_PRODUCED_TOOLTIP_TURNOVER',
  turnover: 'PRODUCTION.REAL_TIME.LABEL_SALES_TOOLTIP_TURNOVER',
  loss: 'PRODUCTION.REAL_TIME.LABEL_LOSS_TOOLTIP_TURNOVER',
  turnoverWasteRate: 'PRODUCTION.REAL_TIME.REPORT_LABEL_LOSS_TURNOVER_RATIO_TOOLTIP',
  productionWasteRate: 'PRODUCTION.REAL_TIME.LABEL_LOSS_RATIO_TOOLTIP_TURNOVER',
};

export const tooltipRealTimeAnalyticsUnit = {
  predicted: 'PRODUCTION.REAL_TIME.LABEL_FORECAST_TOOLTIP_UNIT',
  stocks: 'PRODUCTION.REAL_TIME.LABEL_STOCKS_TOOLTIP_UNIT',
  producedPercent: 'PRODUCTION.REAL_TIME.LABEL_PRODUCTION_RATIO_TOOLTIP_UNIT',
  toProduceQuantity: 'PRODUCTION.REAL_TIME.LABEL_TO_PRODUCE_TOOLTIP_UNIT',
  producedQuantity: 'PRODUCTION.REAL_TIME.LABEL_PRODUCED_TOOLTIP_UNIT',
  loss: 'PRODUCTION.REAL_TIME.LABEL_DECLARED_LOSSES_TOOLTIP_UNIT',
};

export const tooltipRealTimeAnalyticsTurnover = {
  predicted: 'PRODUCTION.REAL_TIME.LABEL_FORECAST_TOOLTIP_TURNOVER',
  stocks: 'PRODUCTION.REAL_TIME.LABEL_STOCKS_TOOLTIP_TURNOVER',
  producedPercent: 'PRODUCTION.REAL_TIME.LABEL_PRODUCTION_RATIO_TOOLTIP_TURNOVER',
  toProduceTurnover: 'PRODUCTION.REAL_TIME.LABEL_TO_PRODUCE_TOOLTIP_TURNOVER',
  producedTurnover: 'PRODUCTION.REAL_TIME.LABEL_PRODUCED_TOOLTIP_TURNOVER',
  loss: 'PRODUCTION.REAL_TIME.LABEL_DECLARED_LOSSES_TOOLTIP_TURNOVER',
};
