import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;

  width: 100%;
  height: 64px;
  padding: 0 16px;

  background-color: ${(props) =>
    props.isTransfer ? props.theme.colors.greys.lightest : 'transparent'};

  border-bottom: 1px solid ${(props) => props.theme.colors.greys.light};
`;

export const Item = styled.div`
  display: inline-block;
  overflow: hidden;
  flex-direction: column;
  flex-basis: ${(props) =>
    props.large ? `calc(${(100 / props.nbColumns) * 2}%)` : `calc(${100 / props.nbColumns}%)`};

  margin-right: 16px;

  font: ${(props) => props.theme.fonts?.textBigHeight16} !important;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  > div {
    margin: 0 0 0 8px !important;
  }

  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font: ${(props) => props.theme.fonts?.textBigHeight16} !important;
`;

export const BoxImg = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 8px;

  flex-shrink: 0;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  cursor: ${(props) => (props.pointerCursor ? 'pointer' : 'default')};
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const TextTooltipName = styled.span`
  width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;

  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

export const TooltipPriceIcon = styled.div`
  width: 24px;
  height: 24px;
  padding: 4px;
  display: flex;
  border-radius: 4px;
  position: relative;
  border: ${(props) => `1px solid ${props.theme.colors?.greys.darkest}`};
  background-color: ${(props) =>
    props.white ? props.theme.colors?.greys.lightest : props.theme.colors?.greys.darkest};

  > img {
    width: 14px;
    height: 14px;
  }
`;

export const TooltipPriceContainer = styled.div`
  z-index: 1;
  margin-top: 28px;
  position: absolute;

  cursor: default;

  width: 440px;
  height: auto;
  padding: 16px;

  border-radius: 8px;
  background-color: ${(props) => props.theme.colors?.greys.lightest};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
`;

export const TooltipPriceHeader = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    flex: 1;
  }

  > img {
    width: 10px;
    height: 10px;

    cursor: pointer;
  }
`;

export const TooltipPriceContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 24px;

  border-top: ${(props) => `1px solid ${props.theme.colors?.greys.light}`};
`;

export const TooltipPriceItem = styled.div`
  width: 100%;

  padding: 8px 0px;

  display: flex;
  align-items: flex-start;

  border-bottom: ${(props) => `1px solid ${props.theme.colors?.greys.light}`};

  > div {
    flex: 1;

    &:first-child {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      max-width: 150px;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

export const InputContainerQuantityMultiPackaging = styled.div`
  max-width: 160px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ModalStockItem = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ModalStockContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  margin-bottom: 24px;

  ${(props) =>
    props.isFooter &&
    css`
      margin-bottom: 0px;
      flex-direction: row;
    `}
`;

export const ModalStockContainer = styled.div`
  margin: auto;

  width: 240px;

  display: flex;
  align-items: center;
  flex-direction: column;
`;
