import i18next from 'i18next';

const PRODUCTION = {
  getText: () => i18next.t('FEATURE.DASHBOARD.PRODUCTION'),
  path: '/dashboard/production',
};

const FOOD_COST = {
  getText: () => i18next.t('FEATURE.DASHBOARD.FOODCOST'),
  path: '/dashboard/food-cost',
};

const DASHBOARD_SUBMODULE = {
  getText: () => i18next.t('FEATURE.DASHBOARD.DASHBOARDS'),
  icon: '/images/dashboard.svg',
  children: [FOOD_COST, PRODUCTION],
  hideSubmoduleName: true,
};

export const DASHBOARD = {
  getText: () => i18next.t('FEATURE.DASHBOARD.DASHBOARDS'),
  path: '/dashboard',
  icon: '/images/dashboard.svg',
  children: [DASHBOARD_SUBMODULE],
};
