import aggregatedConfig from './aggregated';
import anomaliesConfig from './anomalies';
import contentConfig from './content';
import listConfig from './list';

export const ExportTypes = {
  LIST: 'list',
  CONTENT: 'content',
  ANOMALIES: 'anomalies',
  AGGREGATED: 'aggregated',
};

export default {
  ExportTypes,
  [ExportTypes.LIST]: listConfig,
  [ExportTypes.CONTENT]: contentConfig,
  [ExportTypes.ANOMALIES]: anomaliesConfig,
  [ExportTypes.AGGREGATED]: aggregatedConfig,
};
