import { getPasswordRegexRules, STEP_REGISTRATION } from './constants';
import getExpiredTokenSettings from './getExpiredTokenSettings';
import getPasswordValidation from './getPasswordValidation';
import getRegistrationSuccessSettings from './getRegistrationSuccessSettings';
import getUserInformationsSettings from './getUserInformationsSettings';
import getUserPassword from './getUserPasswordSettings';

export default {
  STEP_REGISTRATION,
  getPasswordRegexRules,
  getUserInformationsSettings,
  getExpiredTokenSettings,
  getUserPassword,
  getPasswordValidation,
  getRegistrationSuccessSettings,
};
