import { isEmpty } from 'lodash';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

const getExceptionalDeliveryDates = (supplierExceptionalDeliveryDays, orderTimeLimit, timezone) => {
  const datesWithDelivery = supplierExceptionalDeliveryDays.reduce(
    (acc, { date, leadTime }) => {
      const currentDate = moment.tz(date, timezone).startOf('day');

      if (leadTime !== null) {
        const exceptionalDateIsInPast = currentDate.isBefore(moment.tz(timezone).startOf('day'));

        const orderDateDeadline = moment
          .tz(date, timezone)
          .subtract(leadTime, 'days')
          .startOf('day')
          .format();

        // Trick to have correct datetime limit with timezone applied
        const orderDateTimeLimitString = `${moment
          .tz(orderDateDeadline, timezone)
          .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)}T${orderTimeLimit}`;

        const orderTimeLimitPassed =
          !isEmpty(orderTimeLimit) && !exceptionalDateIsInPast
            ? moment.tz(orderDateTimeLimitString, timezone).isBefore(moment.tz(timezone)) // today is the limit order day
            : false; // order time limit can not be passed if supplier profile doesn't have order time limit or exceptional date is in past

        // If order time limit is passed, ignore exceptional delivery date
        if (!orderTimeLimitPassed) {
          acc.exceptionalDatesWithDelivery.push(currentDate.format());
        }
        return acc;
      }

      acc.exceptionalDatesWithoutDelivery.push(currentDate.format());
      return acc;
    },
    { exceptionalDatesWithDelivery: [], exceptionalDatesWithoutDelivery: [] },
  );

  return datesWithDelivery;
};

export default getExceptionalDeliveryDates;
