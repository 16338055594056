import _ from 'lodash';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import { downloadFile } from '@backoffice/utils';

import ImportFileModal from '../index';

import * as CONFIG from './constantModalSettings';
import { IMPORT_STATE, IMPORT_TYPE } from './constant';

const DEFAULT_MODAL_CONFIG = {
  width: '542px',
  height: 'auto',
  component: ImportFileModal,
};

const disableRightActionButton = (currentStep, selectedFile) =>
  !!(currentStep === IMPORT_STATE.SELECT_FILE && !selectedFile);

const getImportFileModalConfig = (configProps) => {
  const {
    currentStep,
    importType,
    selectedFile,
    handleFileChange,
    handleSwitchStep,
    exportErrorData,
    cleanImportModal,
  } = configProps;

  let modalSubTitle = '';

  if (
    currentStep !== IMPORT_STATE.ERROR_FILE &&
    !_.isEmpty(CONFIG.MODAL_SUBTITLE[importType][currentStep])
  ) {
    modalSubTitle = i18next.t(CONFIG.MODAL_SUBTITLE[importType][currentStep]);
  }

  const configModal = {
    ...DEFAULT_MODAL_CONFIG,
    type: CONFIG.MODAL_TYPE[currentStep],
    title: i18next.t(CONFIG.MODAL_TITLE[importType][currentStep]),
    icon: CONFIG.MODAL_ICON[importType][currentStep],
    data: {
      modalSubTitle,
      selectedFile: selectedFile,
      setSelectedFile: (file) => handleFileChange(file),
      currentStep: currentStep,
      exportErrorFile: () =>
        downloadFile(
          exportErrorData,
          i18next.t(CONFIG.MODAL_ERROR_FILE_NAME[importType]),
          true,
          false,
        ),
    },
    handleCloseCleanUp: () => cleanImportModal(),
  };

  let switchStepActionLabel = '';

  if (
    currentStep === IMPORT_STATE.SELECT_FILE &&
    !_.isEmpty(CONFIG.MODAL_CTA_LEFT_TEXT[importType][currentStep])
  ) {
    switchStepActionLabel = i18next.t(CONFIG.MODAL_CTA_LEFT_TEXT[importType][currentStep]);
  }

  let actions = [
    {
      key: 0,
      color: 'inpulse-outline',
      label: switchStepActionLabel,
      icon: CONFIG.MODAL_CTA_LEFT_ICON[importType][currentStep],
      handleClick: () => handleSwitchStep('previous'),
    },
    {
      key: 1,
      isDisabled: disableRightActionButton(currentStep, selectedFile),
      preventClosing: true,
      color: 'inpulse-default',
      label: i18next.t(CONFIG.MODAL_CTA_RIGHT_TEXT[importType][currentStep]),
      icon: CONFIG.MODAL_CTA_RIGHT_ICON[importType][currentStep],
      handleClick: () => handleSwitchStep('next'),
    },
  ];

  if (currentStep !== IMPORT_STATE.SELECT_FILE) {
    actions = actions.splice(1, 1);
  }

  return { ...configModal, actions };
};

export const handleOpenedImportFileModal = (
  configProps,
  openGenericModal,
  refreshGenericModal,
  isGenericModalOpen,
) => {
  if (isGenericModalOpen) {
    refreshGenericModal(getImportFileModalConfig(configProps));
    return;
  }

  openGenericModal(getImportFileModalConfig(configProps));
};

getImportFileModalConfig.propTypes = {
  configProps: PropTypes.shape({
    currentStep: PropTypes.string.isRequired,
    importType: PropTypes.string.isRequired,
    selectedFile: PropTypes.object.isRequired,
    handleFileChange: PropTypes.func.isRequired,
    handleNextStep: PropTypes.func.isRequired,
    exportErrorData: PropTypes.func,
    cleanImportModal: PropTypes.func.isRequired,
  }),
  openGenericModal: PropTypes.func.isRequired,
  refreshGenericModal: PropTypes.func.isRequired,
  isGenericModalOpen: PropTypes.bool.isRequired,
};

getImportFileModalConfig.defaultProps = {
  configProps: PropTypes.shape({
    currentStep: IMPORT_STATE.SELECT_FILE,
    importType: IMPORT_TYPE.ENTITIES,
    selectedFile: null,
    handleFileChange: null,
    handleNextStep: null,
    cleanImportModal: null,
    exportErrorData: null,
  }),
  openGenericModal: () => null,
  refreshGenericModal: () => null,
  isGenericModalOpen: false,
};

export default { handleOpenedImportFileModal };
