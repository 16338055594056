import * as yup from 'yup';

import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';

export const SALES_INPUTS = {
  NAME: 'name',
  REAL_TURNOVER_INCL_TAX: 'realTurnoverInclTax',
};

export const FORM_INPUTS = {
  [SALES_INPUTS.NAME]: {
    type: 'plain-text',
    name: SALES_INPUTS.NAME,
    width: INPUT_WIDTH.SMALL,
  },
  [SALES_INPUTS.VALUE]: {
    type: 'text-number',
    name: SALES_INPUTS.REAL_TURNOVER_INCL_TAX,
    width: INPUT_WIDTH.LARGE,
    rule: yup.lazy((value) => (value === '' ? yup.string() : yup.number().nullable().min(0))),
  },
};
