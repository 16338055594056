import axios from '../core/http';

import { config } from '../config';

const baseApiUrl = config.baseApiUrl;

const getInventoryOperations = (storeId, date, type) =>
  axios
    .get(`${baseApiUrl}/production/stores/${storeId}/inventory-operations`, {
      params: { date, type },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const postOperation = (storeId, productId, timestamp, tot, type, price, fromOffline = false) =>
  axios
    .post(`${baseApiUrl}/production/stores/${storeId}/operations/products/${productId}`, {
      tot,
      type,
      price,
      timestamp,
      fromOffline,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export const planningOperation = {
  postOperation,
  getInventoryOperations,
};
