import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import sortActionsList from '@commons/utils/sortActionsList';

export const getRowActionsSettings = ({ displayModalEditLocation, displayModalDeleteLocation }) => {
  const rowActions = [
    {
      id: 'edit',
      actionLabel: () => i18next.t('BACKOFFICE.LOCATIONS.SINGLE_EDIT'),
      handleAction: (item) => displayModalEditLocation(item),
      actionIcon: () => '/images/inpulse/pen-black-small.svg',
    },
    {
      id: 'delete',
      actionLabel: () => i18next.t('BACKOFFICE.LOCATIONS.DELETE_SINGLE'),
      handleAction: (item) => displayModalDeleteLocation([item]),
      actionIcon: () => '/images/inpulse/trash-black-small.svg',
    },
  ];

  return sortActionsList(rowActions);
};

export const getActionsSettings = ({
  selectedItems,
  displayModalCreateLocation,
  displayModalEditLocation,
  displayModalDeleteLocation,
}) => {
  const hasSelectedItems = selectedItems.length >= 1;
  const isUniqueSelection = selectedItems.length === 1;

  const actions = [
    {
      id: 'special-add',
      actionLabel: i18next.t('GENERAL.CREATE'),
      handleAction: () => window.alert('TO BE IMPLEMENT'),
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={displayModalCreateLocation}
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('GENERAL.CREATE')}
        />
      ),
      fixed: true,
    },
    {
      id: 'delete-many',
      actionLabel: i18next.t('BACKOFFICE.LOCATIONS.DELETE_MULTIPLE'),
      handleAction: () => displayModalDeleteLocation(selectedItems),
      actionIcon: () => '/images/inpulse/trash-black-small.svg',
      isHidden: () => !(hasSelectedItems && !isUniqueSelection),
    },
    {
      id: 'edit',
      actionLabel: i18next.t('BACKOFFICE.LOCATIONS.SINGLE_EDIT'),
      handleAction: () => displayModalEditLocation(selectedItems[0]),
      actionIcon: () => '/images/inpulse/pen-black-small.svg',
      isHidden: () => !isUniqueSelection,
    },
    {
      id: 'delete-one',
      actionLabel: i18next.t('BACKOFFICE.LOCATIONS.DELETE_SINGLE'),
      handleAction: () => displayModalDeleteLocation(selectedItems),
      actionIcon: () => '/images/inpulse/trash-black-small.svg',
      isHidden: () => !isUniqueSelection,
    },
  ];

  return actions;
};

export default {
  getActionsSettings,
  getRowActionsSettings,
};
