import { getFeaturesByPath, ENUM_MODULE_NAME } from '../../../commons/utils/features';
import React, { useEffect } from 'react';

const MODULE_FEATURES_PATH_ORDERED = [
  '/backoffice/products/cashier-products',
  '/backoffice/products/ingredients',
  '/backoffice/products/ingredient-cost',
];

export const BackOfficeProductContainer = (props) => {
  const { userRights, history } = props;

  useEffect(() => {
    const features = getFeaturesByPath(userRights, ENUM_MODULE_NAME.BACKOFFICE);
    if (features.length) {
      const redirectionPath = MODULE_FEATURES_PATH_ORDERED.find((featurePath) =>
        features.some((feature) => feature.path === featurePath),
      );
      history.push(redirectionPath);
    }
  }, []);

  return <div></div>;
};

// ===== Module features for easy import in ClientContainer =====

import BackOfficeCashierProducts from './CashierProducts';
import BackOfficeIngredientCost from './IngredientCost';
import BackOfficeIngredients from './Ingredients';

const BackOfficeProductModules = {
  BackOfficeProductContainer,
  BackOfficeCashierProducts,
  BackOfficeIngredients,
  BackOfficeIngredientCost,
};

export default BackOfficeProductModules;
