export const aggregateStatsOnTotalLevel = (totalLine, filteredData) => {
  const total = filteredData.reduce(
    (result, store) => {
      result.turnover += store.turnover;
      result.order += store.order;
      result.realConsumption += store.realConsumption;
      result.theoricalConsumption += store.theoricalConsumption;
      return result;
    },
    {
      turnover: 0,
      order: 0,
      realConsumption: 0,
      theoricalConsumption: 0,
    },
  );
  total.realFromTheorical =
    total.theoricalConsumption !== 0
      ? (total.realConsumption / total.theoricalConsumption) * 100
      : null;

  total.materialRatio =
    total.turnover !== 0 ? (total.theoricalConsumption / total.turnover) * 100 : null;

  total.orderOverTurnoverRatio = total.turnover !== 0 ? (total.order / total.turnover) * 100 : null;

  return {
    ...totalLine,
    ...total,
  };
};
