import { APIcaller } from './APIcaller';

const getIngredientEntityCostHistories = (entityId, storeIds, startDate, endDate) =>
  APIcaller.apiPost(`/hook/data/get-entity-cost-history`, {
    entityId,
    storeIds,
    startDate,
    endDate,
  });

const fixEntityCostHistoryForPeriod = (clientId, startDate, endDate, storeIds, entityIds) =>
  APIcaller.apiPost(`/hook/data/fix-entity-cost-history-for-period`, {
    clientId,
    entityIds,
    storeIds,
    startDate,
    endDate,
  });

export const echService = {
  getIngredientEntityCostHistories,
  fixEntityCostHistoryForPeriod,
};
