import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-width: 100px;
`;

export const TitleChartContainer = styled.div`
  color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};
  font: ${(props) => props.theme.fonts?.h3Inter || 'normal 600 14px/14px proximanovaregular'};
  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
`;

export const ChartContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding-top: 10px;
  justify-content: center;
`;

export default {
  Container,
  TitleChartContainer,
  ChartContainer,
};
