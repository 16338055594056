import { sortBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import { Button, Dropdown } from '@commons/utils/styledLibraryComponents';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import utilsXLS from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { stock as stockService } from '@services/stock';

import { Container, Header, Content, Footer, DropdownContainer } from './styledComponents';
import { createPastStockSheet, createInformationSheet } from './utils';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

export const ExportModal = (props) => {
  const {
    closeModal,
    params: { date, stores, categories, searchInput, isStrategic, currency, storeName },
  } = props;

  // Stores selection step
  const [selectedStores, setSelectedStores] = useState(stores);
  const [isSelectingStores, setIsSelectingStores] = useState(true);

  // Data retrieving step
  const [isLoading, setLoading] = useState(true);
  const [storesPastStock, setStoresPastStock] = useState([]);
  const [countStoresFetched, setCountStoresFetched] = useState(0);

  // Progress modal
  const [progress, setProgress] = useState(0);
  const [title, setTitle] = useState(i18next.t('STOCKS.PAST_STOCKS.EXPORT_IN_PROGRESS'));

  const updateProgress = () => {
    const updatedProgress = (countStoresFetched / selectedStores.length) * 100;

    setProgress(updatedProgress);
  };

  const exitModal = () => {
    setLoading(false);

    closeModal();
  };

  const exportReady = () => {
    setTitle(i18next.t('STOCKS.PAST_STOCKS.EXPORT_FINISHED'));
    setLoading(false);
  };

  const exportFailure = () => {
    setTitle(i18next.t('GENERAL.EXPORT_FAILURE'));
    setLoading(false);
  };

  const tiggerExport = () => {
    if (!selectedStores.length) {
      return;
    }

    setIsSelectingStores(false);
  };

  const loadStoresPastStock = async () => {
    const updatedStoresPastStock = [...storesPastStock];

    // Perform request
    try {
      const pastStocks = await stockService.getPastStocksOfIngredientsByCategories(
        selectedStores[countStoresFetched].id,
        moment(date).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        categories,
        isStrategic,
      );

      const computedPastStocks = pastStocks.reduce((result, item) => {
        if (searchInput && !item.name.toLowerCase().includes(searchInput.toLowerCase())) {
          return result;
        }

        result.push({
          ...item,
          storeId: selectedStores[countStoresFetched].id,
          storeName: selectedStores[countStoresFetched].name,
        });

        return result;
      }, []);

      const dataSortedByIngredient = sortBy(computedPastStocks, 'name');

      updatedStoresPastStock.push(...dataSortedByIngredient);

      setStoresPastStock(updatedStoresPastStock);
    } catch (err) {
      exportFailure();

      return;
    }

    setCountStoresFetched(countStoresFetched + 1);
  };

  useEffect(() => {
    if (isSelectingStores) {
      return;
    }

    if (countStoresFetched === selectedStores.length) {
      setProgress(100);

      const listingDataSheet = createPastStockSheet(storesPastStock, storeName);

      const informationDataSheet = createInformationSheet(
        storesPastStock,
        moment(date).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        storeName,
      );

      utilsXLS.makeXLS(i18next.t('STOCKS.PAST_STOCKS.EXPORT_FILENAME'), [
        utilsXLS.generateDefaultSheet(
          listingDataSheet.title,
          listingDataSheet.headers,
          listingDataSheet.data,
          currency,
        ),
        utilsXLS.generateDefaultSheet(
          informationDataSheet.title,
          informationDataSheet.headers,
          informationDataSheet.data,
          currency,
        ),
      ]);

      return exportReady();
    }

    updateProgress();

    loadStoresPastStock();
  }, [isSelectingStores, countStoresFetched]);

  return (
    <div className={props.className}>
      {isSelectingStores && (
        <Container>
          <Header>
            <img height={16} src={'/images/inpulse/file-download-black-small.svg'} width={16} />
            <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.H1}>
              {i18next.t('STOCKS.PAST_STOCKS.EXPORT_MODAL_TITLE')}
            </Text>
            <img
              height={16}
              src={'/images/inpulse/close-black-small.svg'}
              width={16}
              onClick={closeModal}
            />
          </Header>
          <Content>
            <DropdownContainer>
              <Dropdown
                customListStyle={{
                  position: 'fixed',
                  top: 'inherit',
                  left: 'inherit',
                }}
                iconSrc={
                  selectedStores.length
                    ? '/images/inpulse/pin-black-small.svg'
                    : '/images/inpulse/pin-dmgrey-small.svg'
                }
                isRequired={true}
                isUniqueSelection={false}
                items={stores}
                label={storeName}
                selectedItems={selectedStores}
                onSelectionChange={setSelectedStores}
              />
            </DropdownContainer>
          </Content>
          <Footer>
            <Button
              color={'inpulse-outline'}
              handleClick={exitModal}
              icon={'/images/inpulse/close-black-small.svg'}
              label={i18next.t('GENERAL.CANCEL')}
            />
            <Button
              color={'inpulse-default'}
              handleClick={tiggerExport}
              icon={'/images/inpulse/file-download-white-small.svg'}
              isDisabled={!selectedStores.length}
              label={i18next.t('GENERAL.EXPORT')}
            />
          </Footer>
        </Container>
      )}

      {!isSelectingStores && (
        <ExportModalContent
          {...props}
          closeModal={closeModal}
          exitModal={exitModal}
          isLoading={isLoading}
          progress={progress}
          setLoading={setLoading}
          titleModal={title}
        />
      )}
    </div>
  );
};

export default ExportModal;
