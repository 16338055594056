import styled from 'styled-components';

export const Container = styled.div`
  width: ${(props) => (props.fullWidth ? '100%' : '240px')};
  height: 40px;

  & .dropdown-country {
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.colors?.greys?.lightest};

    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors?.greys?.light};

    &:focus-within {
      border: 1px solid #1d1d1b;
    }

    &:hover {
      background: ${(props) => props.theme.colors?.greys?.lighter};
    }

    > ul {
      // To style the 'Search' box
      > div[class*='ReactFlagsSelect-module_filterBox'] {
        > input {
          border: 1px solid ${(props) => props.theme.colors.greys.light};
          border-radius: 8px;

          font: ${(props) => props.theme.fonts.textSmallHeight16};
          color: ${(props) => props.theme.colors.greys.darkest};
        }
      }

      max-height: 200px !important;

      font: ${(props) => props.theme.fonts?.textSmallHeight16};
      color: ${(props) => props.theme.colors?.greys?.darkest};

      border-radius: 8px;
      border-width: 0px;
      box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);

      padding: 0px;

      > li {
        height: 40px;
        display: flex;
        align-items: center;

        font: ${(props) => props.theme.fonts?.textSmallHeight16};
        color: ${(props) => props.theme.colors?.greys?.darkest};

        margin: 0px;

        &:hover {
          background: ${(props) => props.theme.colors?.greys?.lighter};
        }
      }
    }

    > button {
      width: ${(props) => (props.fullWidth ? '100%' : '240px')};
      height: 40px;

      box-sizing: border-box;
      border: none;

      padding: 0px 16px;

      display: flex;
      align-items: center;

      border-radius: 8px;

      &:disabled {
        background-color: ${(props) => props.theme.colors.greys.light};
        cursor: not-allowed;
      }

      ::after {
        border-top: 5px solid
          ${(props) =>
            !!props.children?.props?.selected
              ? props.theme.colors?.greys?.darkest
              : props.theme.colors?.greys?.dark};
      }

      &[aria-expanded='true']:after {
        border-top: 5px solid ${(props) => props.theme.colors?.greys?.darkest};
        border-bottom: 0;
      }

      > span {
        height: 40px;
        padding: 0px;
        font: ${(props) => props.theme.fonts?.textSmallHeight16};
        color: ${(props) => props.theme.colors?.greys?.dark};

        > span {
          height: 40px;
          display: flex;

          align-items: center;

          font: ${(props) => props.theme.fonts?.textSmallHeight16};
          color: ${(props) => props.theme.colors?.greys?.darkest};
        }
      }
    }
  }
`;
