import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding: 24px;
  background: ${(props) => props.theme.colors?.greys.lightest};
  box-shadow: ${(props) => props.theme.shadows.short};
  border-radius: ${(props) => props.theme.borders?.radius?.weak};
  border: ${(props) => props.err && `1px solid ${props.theme.colors.red}`};

  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const DropdownsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 24px;
`;

export const CompositionContainer = styled.div`
  display: flex;

  column-gap: 24px;
`;

export const ExternalRedirectionContainer = styled.div`
  height: 15px;

  display: flex;
  flex-direction: row;
  column-gap: 8px;

  align-self: self-end;
  margin-bottom: 12px;

  &:hover {
    cursor: pointer;
  }
`;

export const LinkImage = styled.img`
  width: 15px;
`;

export const InformationComponentsContainer = styled.div`
  width: 100%;
  padding-top: 12px;

  display: flex;
  flex-direction: row;
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 120px;
  height: 40px;
  > div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;
