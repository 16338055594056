import { isEmpty } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { doesIngredientsHaveApplicableUnits, getIngredientUnitName } from '@commons/utils/units';
import { Dropdown, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import { getTheme } from '@commons/utils/theme';

import {
  Container,
  DropdownIcon,
  DropdownItem,
  DropdownItemMainValue,
  DropdownItemSecondaryValue,
  DropdownTitle,
} from './styledComponents';

const DEFAULT_SP_PICTURE = '/images/inpulse/supplier-product-photo-placeholder.svg';

export const MODE_TYPES = {
  CREATE: 'create',
  EDIT: 'edit',
  DUPLICATE: 'duplicate',
};

const RecipeSection = (props) => {
  const {
    handleIngredientMappingSelection,
    ingredients,
    inRecipe,
    mode,
    supplierProduct,
    enablingSupplierProduct,
  } = props;

  const getIngredientLink = () =>
    supplierProduct.entityId && mode === 'edit'
      ? '/admin/products/ingredients/' + supplierProduct.entityId + '/details'
      : false;

  const getIngredientsDropdownItems = () => {
    const formattedConversions = supplierProduct.conversions.map((conversion) => ({
      ...conversion,
      isBaseUnit: supplierProduct.packagingUnit === conversion.convertedUnit,
    }));

    return ingredients.map((ingredient) => {
      const sameUnit = doesIngredientsHaveApplicableUnits(
        supplierProduct.packagingUnit,
        ingredient.unit,
        formattedConversions,
      );

      return {
        ...ingredient,
        id: ingredient.id,
        tags: [getIngredientUnitName(ingredient.unit)],
        value: ingredient.name,
        isDisabled: !sameUnit,
        renderValue: () => (
          <DropdownItem>
            <DropdownIcon src={ingredient.img || DEFAULT_SP_PICTURE} />
            <DropdownTitle>
              <DropdownItemMainValue>{ingredient.name}</DropdownItemMainValue>
              <DropdownItemSecondaryValue isDisabled={!sameUnit}>
                {`${ingredient.supplierProductMappingCount} ${i18next.t(
                  'ADMIN.SUPPLIER_PRODUCTS.SUPPLIER_PRODUCT_ABBREVIATION',
                )}`}
              </DropdownItemSecondaryValue>
            </DropdownTitle>
          </DropdownItem>
        ),
      };
    });
  };

  return (
    <Container customWidth={enablingSupplierProduct}>
      <div className={props.className}>
        <div className="medium-modal-form-category-block">
          <div className="medium-modal-form-category-header">
            {i18next.t('GENERAL.ASSOCIATED_INGREDIENT')}
          </div>
          <div className="medium-modal-form-category-body">
            <div className="medium-modal-form-category-body-row">
              <div>
                <Dropdown
                  height="auto"
                  isDisabled={!inRecipe}
                  items={getIngredientsDropdownItems()}
                  label={`${i18next.t('GENERAL.ASSOCIATED_INGREDIENT')} :`}
                  labelStyle={{ font: getTheme().fonts.textSmall }}
                  link={
                    getIngredientLink() && {
                      onClick: () => {
                        window.open(getIngredientLink(), '_blank');
                      },
                      customStyle: { marginLeft: '8px' },
                    }
                  }
                  placeholder={i18next.t('GENERAL.DROPDOWN_PLACEHOLDER')}
                  selectedItem={
                    // Necessary to return null if entity is empty so the placeholder is visible
                    isEmpty(supplierProduct.entity)
                      ? null
                      : {
                          id: supplierProduct.entityId,
                          value: supplierProduct.entity.name,
                        }
                  }
                  width={INPUT_WIDTH.LARGE}
                  onSelectionChange={(item) => {
                    if (!item) {
                      return handleIngredientMappingSelection(null, null);
                    }

                    handleIngredientMappingSelection(item.id, item.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default RecipeSection;
