import { connect } from 'react-redux';
import { omit, times } from 'lodash';
import { useForm, useFieldArray, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import moment from 'moment';
import React, { useState, useEffect, Fragment } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { buildArraySchema } from '@commons/GenericForm';
import { Button, Dropdown, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import {
  FullScreenModalContent,
  FullScreenModalContentSelectorsAndInfos,
  FullScreenModalContentSelectors,
} from '@commons/FullScreenModal/Content';
import { FullScreenModalHeaderButtons } from '@commons/FullScreenModal/Header';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import { TableForm } from '@commons/TableForm';
import {
  Title,
  Container,
  HeaderContainer,
} from '@commons/TableForm/TableFormInModal/styledComponents.js';

import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import { getSalesPointStores } from '@selectors/stores';

import { productionMetrics } from '@services/productionMetrics';

import { getTableFormColumnsByType } from './utils/getColumns';

const DAYS_IN_PAST = 7;
const FIELD_ARRAY_NAME = 'sales';

const SalesForm = (props) => {
  const {
    // state props
    stores,
    currency,
    // dispatch props
    params: {},
    closeModal,
    pageLoaded,
    pageLoading,
    showErrorMessage,
    showSuccessMessage,
  } = props;

  // ==================
  // ===== States =====
  // ==================
  const [columns, setColumns] = useState([]);

  const [selectedStore, setSelectedStore] = useState(null);

  const periodPickerState = usePeriodDatePickerState(null, null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaveAlreadyTriggered, setHasAlreadySave] = useState(false);

  const salesFormInputs = columns.filter(({ isFixed }) => !isFixed).map(({ input }) => input);

  const salesForm = useForm({
    defaultValues: {
      [FIELD_ARRAY_NAME]: [],
    },
    resolver: yupResolver(buildArraySchema(FIELD_ARRAY_NAME, salesFormInputs)),
  });

  const { fields, prepend, remove } = useFieldArray({
    control: salesForm.control,
    name: FIELD_ARRAY_NAME,
    defaultValues: {
      [FIELD_ARRAY_NAME]: [],
    },
  });

  const formFields = useWatch({
    mode: 'all',
    name: FIELD_ARRAY_NAME,
    control: salesForm.control,
  });

  // ======================
  // ===== UseEffects =====
  // ======================

  // Automatically select first available store
  useEffect(() => {
    if (!stores.length) {
      return;
    }

    setSelectedStore(stores[0]);
  }, [stores]);

  // Generate column using currency alphabetic code
  useEffect(() => {
    if (!currency) {
      return;
    }

    setColumns(getTableFormColumnsByType(currency));
  }, [currency]);

  // Check form validation status whenever fields are being updated
  useEffect(() => {
    (async function load() {
      const isFormValid = await salesForm.trigger();

      setIsSaveDisabled(!isFormValid && isSaveAlreadyTriggered);
    })();
  }, [formFields]);

  // Reload form when selected store is being changed
  useEffect(() => {
    if (!selectedStore || !periodPickerState.startDate || !periodPickerState.endDate) {
      return;
    }

    // loading form
    makeFormLoading();

    fetchMetrics();
  }, [selectedStore, periodPickerState.startDate, periodPickerState.endDate]);

  // ====================
  // ===== Methods ======
  // ====================

  const buildFormFromDate = (callback) => {
    deleteAll();

    const diffInDays = periodPickerState.endDate.diff(periodPickerState.startDate, 'days');

    times(diffInDays + 1, callback);
  };

  const makeFormLoading = () => {
    const formBuilderCallback = () => {
      prepend({
        isLoading: true,
      });
    };

    buildFormFromDate(formBuilderCallback);
  };

  const fetchMetrics = async () => {
    if (!selectedStore || !periodPickerState.startDate || !periodPickerState.endDate) {
      return;
    }

    setIsLoading(true);

    try {
      const metrics = await productionMetrics.getMetricsByDates(
        selectedStore.id,
        periodPickerState.startDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        periodPickerState.endDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );

      const formBuilderCallback = (index) => {
        const currentDay = moment(periodPickerState.startDate).endOf('day').add(index, 'day');

        const currentMetricOfDay = metrics[
          currentDay.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)
        ] || {
          storeId: selectedStore.id,
          date: currentDay.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        };

        prepend({
          ...currentMetricOfDay,
          formattedDate: currentDay.format('LL'),
        });
      };

      buildFormFromDate(formBuilderCallback);

      salesForm.trigger(FIELD_ARRAY_NAME); // Force validate added item to render proper icon status
    } catch (err) {
      showErrorMessage(i18next.t('ADMIN.SALES.MODAL_LOADING_PRODUCTION_METRICS_FAILED'));
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAll = () => {
    remove();
  };

  const submit = async () => {
    const values = salesForm.getValues();

    const formattedValues = values.sales.map((sale) => {
      const { realTurnoverInclTax } = sale;

      const value =
        realTurnoverInclTax || realTurnoverInclTax === 0 ? parseFloat(realTurnoverInclTax) : null;

      return { ...omit(sale, 'formattedDate'), realTurnoverInclTax: value };
    });

    pageLoading();

    try {
      await productionMetrics.createMetrics(formattedValues);

      showSuccessMessage(i18next.t('ADMIN.SALES.FORM_SAVE_SUCCESS'));

      closeModal();
    } catch (err) {
      showErrorMessage(i18next.t('ADMIN.SALES.FORM_SAVE_FAILURE'));
    } finally {
      pageLoaded();
    }
  };

  const validateForm = async () => {
    const isFormValid = await salesForm.trigger();

    setHasAlreadySave(true);

    if (!isFormValid) {
      const errors = salesForm.formState.errors;

      errors[FIELD_ARRAY_NAME].forEach((_, index) => {
        const path = `${FIELD_ARRAY_NAME}[${index}]`;

        salesForm.setValue(path, {
          ...salesForm.getValues(path),
          hasErrors: true, // Allows to keep track of fields on which validation has already been run
        });
      });

      setIsSaveDisabled(true);

      return;
    }

    salesForm.handleSubmit(submit)();
  };

  // ===================
  // ===== Render ======
  // ===================

  if (!selectedStore) {
    return <Fragment />;
  }

  return (
    <Container>
      <HeaderContainer>
        <Title>{i18next.t('ADMIN.SALES.MODAL_TITLE')}</Title>
        <FullScreenModalHeaderButtons>
          <Button
            color={'inpulse-grey'}
            handleClick={closeModal}
            icon={'/images/inpulse/close-white-small.svg'}
            isDisabled={isLoading}
          />

          <Button
            color={'inpulse-default'}
            handleClick={validateForm}
            icon={'/images/inpulse/save-white-small.svg'}
            isDisabled={isSaveDisabled || isLoading}
          />
        </FullScreenModalHeaderButtons>
      </HeaderContainer>
      <FullScreenModalContent headerHeight={'64px'}>
        <FullScreenModalContentSelectorsAndInfos>
          <FullScreenModalContentSelectors>
            <Dropdown
              iconSrc={'/images/inpulse/store-black-small.svg'}
              isDisabled={isLoading}
              isRequired={true}
              isUniqueSelection={true}
              items={stores}
              selectedItem={selectedStore}
              onSelectionChange={setSelectedStore}
            />
            <PeriodDatePicker
              disabled={isLoading}
              endDate={periodPickerState.endDate}
              focusedDateInput={periodPickerState.focusedDateInput}
              maxFutureDate={moment().endOf('day').subtract(1, 'day')}
              maxPastDate={moment().endOf('day').subtract(DAYS_IN_PAST, 'day')}
              setFocusedDateInput={periodPickerState.setFocusedDateInput}
              startDate={periodPickerState.startDate}
              timezone="Europe/Paris" // TODO - [TIMEZONES] To be replaced by IPP-5229 Admin Module
              onDatesChange={periodPickerState.handleSelectedDates}
            />
          </FullScreenModalContentSelectors>
        </FullScreenModalContentSelectorsAndInfos>
        <TableForm
          columns={columns}
          emptyState={{
            text: i18next.t('ADMIN.SALES.FORM_COLUMN_DATE_EMPTY_STATE'),
            width: INPUT_WIDTH.LARGE,
          }}
          fieldArrayName={FIELD_ARRAY_NAME}
          fields={fields}
          form={salesForm}
          register={salesForm.register}
        />
      </FullScreenModalContent>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  stores: getSalesPointStores(state.baseReducer.activeStores),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
});

SalesForm.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(SalesForm);
