import styled, { css } from 'styled-components';

export const Container = styled.div`
  z-index: 60;
  position: fixed;

  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: ${(props) => props.theme.colors?.greys?.darkest};
`;

export const Logo = styled.img`
  width: 64px;
  height: 64px;

  margin-bottom: 48px;
`;

export const Text = styled.div`
  color: ${(props) => props.theme.colors?.greys?.lightest};

  font: ${(props) => props.theme.fonts?.h1};

  margin-bottom: 16px;

  ${(props) => {
    if (props.subtitle) {
      return css`
        color: ${(props) => props.theme.colors?.greys?.medium};
        font: ${(props) => props.theme.fonts?.textSmall};
      `;
    }
  }}
`;
