import { getFeaturesByPath, ENUM_MODULE_NAME } from '../../../commons/utils/features';
import React, { useEffect } from 'react';

const MODULE_FEATURES_PATH_ORDERED = ['/backoffice/users/roles', '/backoffice/users/team'];

export const BackOfficeUsersContainer = (props) => {
  const { userRights, history } = props;

  useEffect(() => {
    const features = getFeaturesByPath(userRights, ENUM_MODULE_NAME.BACKOFFICE);

    if (features.length) {
      const redirectionPath = MODULE_FEATURES_PATH_ORDERED.find((featurePath) =>
        features.some((feature) => feature.path === featurePath),
      );
      history.push(redirectionPath);
    }
  }, []);

  return <div></div>;
};

// ===== Module features for easy import in ClientContainer =====

import BackOfficeRolesManagement from './BackOfficeRolesManagement';
import BackOfficeTeamsManagement from './BackOfficeTeamsManagement';

const BackOfficeUsersModules = {
  BackOfficeUsersContainer,
  BackOfficeRolesManagement,
  BackOfficeTeamsManagement,
};

export default BackOfficeUsersModules;
