import moment from 'moment';
import normalizeStringValue from '../../commons/utils/normalizeStringValue';
import XLSX from 'xlsx';

const FILE_EXTENSION = 'xlsx';

const getFormattedFileName = (fileName, isErrorFile) => {
  const formattedDate = moment().format('YYYY-MM-DDTHH:mm:ss');
  return `${isErrorFile ? 'erreurs-import-' : ''}${fileName}-${formattedDate}.${FILE_EXTENSION}`;
};

export const downloadFile = (response, fileName, isErrorFile = false, formatFileName = true) => {
  const url = window.URL.createObjectURL(new Blob([response]));

  const link = document.createElement('a');

  link.href = url;
  link.setAttribute(
    'download',
    formatFileName ? getFormattedFileName(fileName, isErrorFile) : `${fileName}.${FILE_EXTENSION}`,
  );

  document.body.appendChild(link);

  link.click();
};

export const formatXlsFileToJson = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = reject;

    reader.onload = (e) => {
      const data = e.target.result;

      const workbook = XLSX.read(data, { type: 'array', cellDates: true });

      const dataModel = workbook.SheetNames.reduce((result, sheetName) => {
        const worksheet = workbook.Sheets[sheetName];

        let sheet = XLSX.utils.sheet_to_json(worksheet);
        sheet.forEach((json) => {
          Object.keys(json).forEach((key) => {
            json[key.toLowerCase()] = json[key];

            delete json[key];
          });
        });

        result[sheetName] = sheet;

        return result;
      }, {});

      return resolve(dataModel);
    };

    reader.readAsArrayBuffer(file);
  });

export const normalizeJsonObject = (jsonToFormat) =>
  Object.keys(jsonToFormat).reduce((acc, key) => {
    if (key === 'DataValidationWorksheet') {
      acc[key] = jsonToFormat[key];
    }

    if (key !== 'DataValidationWorksheet') {
      acc[key] = [];
      jsonToFormat[key].forEach((element) => {
        const formattedObject = Object.keys(element).reduce((acc, jsonKey) => {
          acc[normalizeStringValue(jsonKey)] = element[jsonKey];
          return acc;
        }, {});
        acc[key].push(formattedObject);
      });
    }

    return acc;
  }, {});

/**
 * Turn rows of an Excel file (an object whose keys has been normalized) into an object that will be sent in the payload
 * to be processed.
 *
 * @param {object} row - Normalized object extracted from an Excel file.
 * @param {{key: string; normalizedKey: string; mappingFn?: Function}} columnDefinitions - Definition of the columns
 * @param defaultMappingFunction
 *
 * @returns {{[key: string]: value: string}}
 */
export const mapColumnIdsAndNormalizedColumnIds = (
  row,
  columnDefinitions,
  defaultMappingFunction = (row, key) => row[key],
) => {
  const mappedObject = {};

  for (const columnId in columnDefinitions) {
    const key = columnDefinitions[columnId].key;
    const normalizedKey = columnDefinitions[columnId].normalizedKey;
    const mappingFn = columnDefinitions[columnId].mappingFn || defaultMappingFunction;

    const value = mappingFn(row, normalizedKey);

    mappedObject[key] = typeof value === 'string' ? normalizedWhiteSpaces(value) : value;
  }

  return mappedObject;
};

const normalizedWhiteSpaces = (string) => string.replace(/\s/g, ' ');

export default {
  normalizeJsonObject,
  formatXlsFileToJson,
  downloadFile,
  mapColumnIdsAndNormalizedColumnIds,
};
