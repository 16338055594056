import React from 'react';

import IngredientInfo from '@admin/products/ingredients/detail/components/IngredientInfo';

import { IngredientInformationsModalContainer } from './styledComponents';

export const IngredientInformationsModal = (props) => {
  const { ingredientInformationsData, setIngredientInformationsData, mapSupplierProductsData } =
    props;

  return (
    <IngredientInformationsModalContainer>
      <IngredientInfo
        ingredient={ingredientInformationsData}
        ingredientId={ingredientInformationsData.id}
        isCostInputDisabled={mapSupplierProductsData.length > 0}
        isChildCreation
        onIngredientChange={setIngredientInformationsData}
      />
    </IngredientInformationsModalContainer>
  );
};
