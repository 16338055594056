import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { ENTITY_UNITS } from '@commons/constants/units';
import { InputIncrement } from '@commons/utils/styledLibraryComponents';
import { sortPackagings } from '@commons/utils/format';
import DisplayNumber from '@commons/DisplayNumber';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { convertSPPQuantityInMasterUnit } from '@orders/utils/computePackagingToSupplierProduct';
import { formatInputValue } from './utils';

import { STOCK_FORM_TYPES } from '@stocks/StocksInventories/components/StockForm/constants/types';
import { UNIT } from '@stocks/StocksInventories/components/StockForm/constants/unit';

import { ModalStockItem, ModalStockContent, ModalStockContainer } from '../styledComponents';

const ModalStock = ({ price, onChange, packagings, totByPackagingId, stockType }) => {
  const getMasterUnitInfo = () => {
    const invoicePackaging = packagings.find(({ isUsedInInvoice }) => isUsedInInvoice);
    return packagings.reduce(
      (result, packaging) => {
        const value = totByPackagingId[packaging.id];

        const convertedPrice =
          price / convertSPPQuantityInMasterUnit(invoicePackaging.id, packagings);

        if (!packaging.masterSupplierProductPackagingId) {
          result.unit =
            packaging.unit === ENTITY_UNITS.UNIT ? i18next.t('GENERAL.UNIT') : packaging.unit;
        }

        const convertedQuantity = convertSPPQuantityInMasterUnit(packaging.id, packagings);

        result.total += convertedQuantity * value;
        result.price += convertedQuantity * convertedPrice * value;

        return result;
      },
      { total: 0, price: 0 },
    );
  };

  const sumupMasterUnit = getMasterUnitInfo();

  return (
    <ModalStockContainer>
      {sortPackagings(packagings).map((packaging, index) => {
        if (!packaging.isUsedInStock) {
          return null;
        }

        const value =
          stockType === STOCK_FORM_TYPES.STOCK
            ? totByPackagingId[packaging.id]
            : totByPackagingId[packaging.id] || 0;

        return (
          <ModalStockContent key={index}>
            <Text style={{ marginBottom: '8px' }}>
              {packaging.name === 'unit' ? i18next.t('GENERAL.UNIT') : packaging.name} :
            </Text>
            <InputIncrement
              allowNull={stockType === STOCK_FORM_TYPES.STOCK}
              enableNullValue={true}
              height="40px"
              limitValues={{ min: 0 }}
              placeholder={''}
              setValue={(value) =>
                onChange({ ...totByPackagingId, [packaging.id]: formatInputValue(value) })
              }
              value={value || value === 0 ? value : ''}
              width="240px"
            />
          </ModalStockContent>
        );
      })}
      <ModalStockContent isFooter={true}>
        <ModalStockItem key={'total'}>
          <Text color={ENUM_COLORS.DARKER} fontLineHeight={20} fontSize={12}>
            {i18next.t('FORECAST.TURNOVER.CHART_LABEL_TOTAL')} :
          </Text>
          <DisplayNumber
            displayCurrencyCode={true}
            font={ENUM_FONTS.TEXT_MIDDLE_BOLD}
            number={sumupMasterUnit.price}
          />
        </ModalStockItem>
        <ModalStockItem key={'total-unit'}>
          <Text color={ENUM_COLORS.DARKER} fontLineHeight={20} fontSize={12}>
            {i18next.t('FORECAST.TURNOVER.CHART_LABEL_TOTAL')} {sumupMasterUnit.unit} :
          </Text>
          <Text fontLineHeight={20} fontWeight={700}>
            {sumupMasterUnit.total.toFixed(2)}
            {' ' + sumupMasterUnit.unit}
          </Text>
        </ModalStockItem>
      </ModalStockContent>
    </ModalStockContainer>
  );
};

ModalStock.propTypes = {
  price: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  totByPackagingId: PropTypes.objectOf(PropTypes.any), // number or null
  packagings: PropTypes.arrayOf(
    PropTypes.shape({
      unit: PropTypes.oneOf(UNIT),
      quantity: PropTypes.number.isRequired,
    }),
  ),
};

ModalStock.defaultProps = {
  price: null,
  packagings: [],
  totByPackagingId: {},
};

export default ModalStock;
