import i18next from 'i18next';
import React from 'react';

import Text from '@commons/Text';

export const getColumns = (clientStoreName, hasLocalCatalogs, renderStoreLabel) => {
  const columns = [
    {
      id: 'name',
      propertyKey: 'name',
      name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_NAME'),
      displayName: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_NAME'),
      render: (it) => <Text highlighted>{it}</Text>,
    },
    {
      id: 'category',
      propertyKey: 'category',
      name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CATEGORY'),
      displayName: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CATEGORY'),
      filterType: 'string',
      render: (item) => <Text>{item}</Text>,
    },
    {
      id: 'city',
      propertyKey: 'city',
      name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CITY'),
      displayName: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CITY'),
      render: (item) => <Text>{item}</Text>,
    },
    {
      id: 'nbSupplierProfiles',
      filterType: 'numeric',
      propertyKey: 'nbSupplierProfiles',
      excludeFromSearch: true,
      name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_PROFILES'),
      displayName: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_PROFILES'),
      render: renderStoreLabel,
    },
    {
      id: 'nbStores',
      filterType: 'numeric',
      propertyKey: 'nbStores',
      excludeFromSearch: true,
      name: clientStoreName,
      displayName: clientStoreName,
      render: renderStoreLabel,
    },
    {
      id: 'nbSupplierProducts',
      propertyKey: 'nbSupplierProducts',
      excludeFromSearch: true,
      name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_SUPPLIER_PRODUCTS'),
      displayName: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_SUPPLIER_PRODUCTS'),
      render: renderStoreLabel,
    },
  ];

  if (hasLocalCatalogs) {
    const cityColumnIndex = columns.findIndex(({ propertyKey }) => propertyKey === 'city');

    columns.splice(cityColumnIndex + 1, 0, {
      id: 'catalog',
      propertyKey: 'catalogName',
      name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CATALOG'),
      displayName: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CATALOG'),
      filterType: 'string',
      render: (item) => <Text>{item}</Text>,
    });
  }

  return columns;
};
