import { ThemeProvider } from 'styled-components';
import Proptypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import { Container } from './styledComponents';

import theme from '../../../theme';

const InpulseLabel = (props) => {
  const { text, style, isDisabled, color, type, tooltipContent = null, onClick } = props;

  if (!tooltipContent) {
    return (
      <ThemeProvider theme={theme}>
        <Container color={color} isDisabled={isDisabled} style={style} type={type}>
          {text}
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container
        color={color}
        data-for="label-tooltip"
        data-tip={tooltipContent}
        isDisabled={isDisabled}
        style={style}
        type={type}
        onClick={onClick}
      >
        {text}
        {tooltipContent && <ReactTooltip effect="solid" id="label-tooltip" type="dark" />}
      </Container>
    </ThemeProvider>
  );
};

InpulseLabel.propTypes = {
  text: Proptypes.string.isRequired,
  color: Proptypes.string,
  style: Proptypes.objectOf(Proptypes.string),
  type: Proptypes.oneOf(['primary', 'secondary', 'tertiary']),
  isDisabled: Proptypes.bool,
};

InpulseLabel.defaultProps = {
  text: '',
  style: {},
  color: 'black',
  type: 'tertiary',
  isDisabled: false,
};

export default InpulseLabel;
