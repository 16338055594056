import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { formatNumberToExcelUsage } from '@commons/DisplayNumber';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import utilsXLS from '@commons/utils/makeXLS';

import { getEntityUnit } from '@stocks/utils';

const MAIN_SHEET_NAME = i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_MAIN_SHEET_NAME');

const getMainSheetColumns = (storeName, storeTimezone, currency) => [
  {
    propertyKey: 'reference',
    name: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_REFERENCE'),
  },
  {
    propertyKey: 'storeName',
    name: getClientStoreNameTranslation(storeName, false),
  },
  {
    propertyKey: 'inventoryDate',
    name: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_DATE'),
    transform: (value) =>
      moment.tz(value, storeTimezone).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
  },
  {
    propertyKey: 'category',
    name: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_CATEGORY'),
  },
  {
    propertyKey: 'name',
    name: i18next.t('GENERAL.NAME'),
  },
  {
    propertyKey: 'quantity',
    name: i18next.t('GENERAL.QUANTITY'),
  },
  {
    propertyKey: 'cost',
    name: i18next.t('GENERAL.COST_EX_TAX'),
  },
  {
    propertyKey: 'totalUnitQuantity',
    name: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_TOTAL_IN_UNIT'),
    transform: (value) => formatNumberToExcelUsage(value, currency.numberDecimals),
  },
  {
    propertyKey: 'unit',
    name: i18next.t('GENERAL.UNIT'),
    transform: (value) => getEntityUnit(value, false),
  },
  {
    propertyKey: 'totalUnitCost',
    name: i18next.t('STOCKS.STOCKS.LIST_LABEL_TOTAL'),
    type: 'currency',
    transform: (value) => formatNumberToExcelUsage(value, currency.numberDecimals),
  },
];

export const formattedMainSheetData = (recipeInventoryData) => {
  const { selectedStore, inventoryDate, reference, recipeInventories } = recipeInventoryData;

  return recipeInventories.map(({ category, name, quantity, unit, cost }) => {
    const recipeComputedTotal = quantity * cost;

    return {
      reference,
      storeName: selectedStore.name,
      inventoryDate,
      category,
      name,
      quantity,
      cost,
      totalUnitQuantity: quantity,
      unit,
      totalUnitCost: recipeComputedTotal,
    };
  });
};

export const exportRecipeInventories = (recipeInventoryData, storeName, currency) => {
  const { selectedStore, reference } = recipeInventoryData;

  const columns = getMainSheetColumns(storeName, selectedStore.timezone, currency);

  const mainSheet = utilsXLS.generateDefaultSheet(
    MAIN_SHEET_NAME,
    columns,
    formattedMainSheetData(recipeInventoryData),
    currency,
  );

  utilsXLS.makeXLS(`${i18next.t('STOCKS.RECIPES.EXPORT_STOCK_FORM_FILENAME')}-${reference}`, [
    mainSheet,
  ]);
};
