const { APIcaller } = require('./APIcaller');

const getMarginEvolutionAnalytics = (storeIds, startDate, endDate, userTimezone) =>
  APIcaller.apiPost(`/order/analytics-by-store-per-date`, {
    storeIds,
    startDate,
    endDate,
    userTimezone,
  });

export default {
  getMarginEvolutionAnalytics,
};
