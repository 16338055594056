import i18next from 'i18next';

import { formatNumberWithCurrency } from '@commons/DisplayNumber';

const getTooltipLines = (currency) => [
  {
    name: i18next.t('ANALYSIS.MARGIN.COLUMN_NAME_REVENUE'),
    propertyKey: 'turnoverWithoutTaxes',
    render: (item) =>
      formatNumberWithCurrency({
        displayCurrencyCode: true,
        shortenDigits: false,
        withoutDecimals: true,
        number: item,
        currency,
      }),
  },
  {
    name: i18next.t('ANALYSIS.MARGIN.COLUMN_NAME_MARGIN'),
    propertyKey: 'marginWithoutTaxes',
    render: (item) =>
      formatNumberWithCurrency({
        displayCurrencyCode: true,
        shortenDigits: false,
        withoutDecimals: true,
        number: item,
        currency,
      }),
  },
  {
    name: i18next.t('ANALYSIS.MARGIN.COLUMN_NAME_MARGIN_PERCENT'),
    propertyKey: 'marginWithoutTaxesRatio',
    render: (item) => `${Number.isInteger(item) ? item : item.toFixed(2)}%`,
  },
];

export default {
  getTooltipLines,
};
