import React from 'react';

import { Container, ProductImage, ImageContainer, CrossCloseContainer } from './styledComponents';

const DisplayImgInFullScreen = (props) => {
  const { image, setDisplayProductPicture } = props;

  return (
    <>
      <Container>
        <CrossCloseContainer onClick={() => setDisplayProductPicture({ state: false, img: '' })}>
          <img src="/images/inpulse/close-black-small.svg" />
        </CrossCloseContainer>
        <ImageContainer>
          <ProductImage src={image} />
        </ImageContainer>
      </Container>
    </>
  );
};

export default DisplayImgInFullScreen;
