import styled from 'styled-components';

// All these classes is used by react-modern-drawer and classes name cannot be changed
export const Container = styled.div`
  .EZDrawer .EZDrawer__checkbox {
    display: none;
  }
  .EZDrawer .EZDrawer__checkbox:checked ~ .EZDrawer__overlay {
    display: block;
    opacity: 1;
  }
  .EZDrawer .EZDrawer__checkbox:checked ~ .EZDrawer__container {
    visibility: visible;
    transform: translate3d(0, 0, 0) !important;
  }

  .EZDrawer .EZDrawer__overlay {
    display: none;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .EZDrawer .EZDrawer__container {
    position: fixed;
    visibility: hidden;
    background: ${(props) => props.theme.colors.greys.lightest};
    transition: all;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  }

  .EZDrawer__overlay {
    backdrop-filter: blur(4px);
  }
`;
