import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;

  background-color: ${(props) => props.theme.colors?.greys?.lighter};
`;

export default {
  Container,
};
