import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import React, { Children } from 'react';

import { Toaster } from 'deepsight-react-components';

import {
  ActionContainer,
  Container,
  GreyContainer,
  InpulseLogo,
  PictureContainer,
} from './styledComponents';

import backgroundPicture from '../../../assets/photos/login.png';
import inpulseFullLogo from '../../../assets/logo/logotype.svg';

import StyledLoader from '../../../commons/DeepsightComponents/Loader/Loader';

const SignInContainer = (props) => {
  if (props.isAuthenticated) {
    return <Redirect to={'/home'} />;
  }

  return (
    <Container>
      <StyledLoader />
      <GreyContainer>
        <InpulseLogo alt="Inpulse-logo" src={inpulseFullLogo} />
        <PictureContainer src={backgroundPicture} />
        <ActionContainer>{Children.only(props.children).props.children}</ActionContainer>
      </GreyContainer>
      <Toaster
        autoDelete={true}
        handleClose={props.hideMessage}
        hidden={!props.confirmationMessageReducer.messageBool}
        icon={props.confirmationMessageReducer.messageCustomIcon}
        status={props.confirmationMessageReducer.messageType}
        text={props.confirmationMessageReducer.message}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  confirmationMessageReducer: state.confirmationMessageReducer,
});

export default connect(mapStateToProps, null)(SignInContainer);
