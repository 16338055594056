import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';

import { Column, ColumnsContainer, Container, Icon, ColumnSubContainer } from './styledComponents';

const INFO_TOOLTIP = '/images/inpulse/info-dgrey-small.svg';

const ColumnHeader = (props) => {
  const { columns, orderStatus } = props;
  return (
    <ColumnsContainer orderStatus={orderStatus}>
      <ColumnSubContainer>
        {columns.map((column) => (
          <Fragment key={column.propertyKey}>
            <Column
              nbColumns={columns.length}
              orderStatus={orderStatus}
              style={column.style}
              weightedCoefficientWidthColumn={column.weightedCoefficientWidthColumn}
            >
              <div>{column.label}</div>
              {!!column.tooltip && (
                <>
                  <Container
                    data-for={`reco-tooltip`}
                    data-html={true}
                    data-tip={
                      `<div style="padding: 16px 24px; color: #FFFFFF; outline: 0; background-color: #1D1D1B; border-radius: 4px; font: normal 500 14px/16px inter; max-width: 300px; text-align: center; display: flex">` +
                      `${column.tooltip}` +
                      `</div>`
                    }
                  >
                    <Icon src={INFO_TOOLTIP} style={{ width: '16px', height: '16px' }} />
                  </Container>
                </>
              )}
            </Column>
          </Fragment>
        ))}
        <ReactTooltip
          backgroundColor={'transparent'}
          effect={'solid'}
          id={`reco-tooltip`}
          overridePosition={({ top, left }) => ({ top: top + 2, left })}
          place={'top'}
        />
      </ColumnSubContainer>
    </ColumnsContainer>
  );
};

export default ColumnHeader;
