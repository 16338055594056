import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  margin-top: 64px;

  width: 100%;
  height: calc(100% - 64px);

  overflow: scroll;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

export const ContainerContent = styled.div`
  width: fit-content;
  margin: auto;
  padding-top: 24px;

  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const NavBarContainer = styled.div`
  > div {
    left: 0px;
    width: 100%;
  }
`;
