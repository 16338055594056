import { head } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

import { isUserDeepsight, userHasRoleInpulse } from '@commons/utils/roles';
import sortActionsList from '@commons/utils/sortActionsList';

import { canCreateSuppliers, canEditSuppliers } from '@selectors/actions/supplierActions';
import {
  canDownloadSupplierProfileTemplate,
  canImportSupplierProfile,
} from '@selectors/actions/supplierProfileActions';

import { canSeeAllCatalogs } from '@selectors/user';
import {
  getAssignCategorySubCategoryModalParams,
  getCategorySubCategoryModalParams,
} from '@admin/commons/modalConfigurations';

export const isUserMapped = (suppliers, user) => {
  const mappedAccounts = suppliers.reduce((acc, { mappedAccounts }) => {
    if (!mappedAccounts) {
      return acc;
    }

    mappedAccounts.map((mapping) => {
      if (!acc.includes(mapping)) {
        acc.push(mapping);
      }
    });
    return acc;
  }, []);

  return mappedAccounts.some(({ accountId }) => accountId === user.id);
};

export const getActions = (props) => {
  const {
    user,
    suppliers,
    renderExport,
    selectedItems,
    handleNewSupplierModal,
    handleSupplierChangeModalBatch,
    openGenericModal,
    openManageMultiPriceModal,
    downloadSupplierProfilesTemplate,
    openSupplierProfilesImportModal,
    authorizedActions,
    hasLocalCatalogs,
  } = props;

  const hasSelectedItems = !!selectedItems.length;
  const onlyOneItemSelected = hasSelectedItems && selectedItems.length === 1;
  const hasKitchenSuppliers = hasSelectedItems && selectedItems.some(({ isKitchen }) => isKitchen);

  const shouldHideAssignCategoryToSupplier = () => {
    const userCatalog = user.catalog || {};

    if (selectedItems.length < 1) {
      return true;
    }

    if (hasKitchenSuppliers) {
      return true;
    }

    if (!canEditSuppliers(authorizedActions)) {
      return true;
    }

    if (
      hasLocalCatalogs &&
      !userHasRoleInpulse(user) &&
      selectedItems.some(({ catalog }) => catalog.id !== userCatalog.id)
    ) {
      return true;
    }

    return false;
  };

  const actions = [
    {
      fixed: canCreateSuppliers(authorizedActions),
      id: 'action-create',
      actionLabel: i18next.t('GENERAL.CREATE'),
      handleAction: () => handleNewSupplierModal(),
      isHidden: () => !canCreateSuppliers(authorizedActions),
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={() => handleNewSupplierModal()}
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('GENERAL.CREATE')}
        />
      ),
    },
    {
      id: 'action-update-category',
      actionLabel: i18next.t('GENERAL.UPDATE_CATEGORIES'),
      handleAction: () => {
        const params = getCategorySubCategoryModalParams(
          suppliers,
          'category',
          i18next.t('ADMIN.SUPPLIERS.MODAL_UPDATE_CATEGORY_LIST_SUPPLIER'),
          handleSupplierChangeModalBatch,
        );

        openGenericModal(params);
      },
      actionIcon: () => '/images/inpulse/gear-ipblack-small.svg',
      isHidden: () =>
        hasSelectedItems ||
        !canEditSuppliers(authorizedActions) ||
        (hasLocalCatalogs && !canSeeAllCatalogs(user, { hasLocalCatalogs })),
    },
    {
      id: 'assign-category',
      actionLabel: i18next.t('GENERAL.LIST_ACTION_ASSIGN_CATEGORY'),
      handleAction: () => {
        const params = getAssignCategorySubCategoryModalParams(
          suppliers,
          selectedItems,
          'category',
          handleSupplierChangeModalBatch,
        );

        openGenericModal(params);
      },
      actionIcon: () => '/images/inpulse/link-black-small.svg',
      isHidden: shouldHideAssignCategoryToSupplier,
    },
    {
      id: 'export',
      displayActionInLine: false,
      actionLabel: hasSelectedItems
        ? i18next.t('GENERAL.EXPORT_SELECTION')
        : i18next.t('GENERAL.EXPORT_LIST'),
      handleAction: () => renderExport(),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
    {
      id: 'csm-template-download',
      displayActionInLine: false,
      actionLabel: i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CREATE_BATCH_DOWNLOAD_TEMPLATE'),
      handleAction: downloadSupplierProfilesTemplate,
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      isDisabled: () => !canDownloadSupplierProfileTemplate(authorizedActions),
      isHidden: () => hasSelectedItems || !canDownloadSupplierProfileTemplate(authorizedActions),
    },
    {
      id: 'csm-import-template',
      displayActionInLine: false,
      actionLabel: i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CREATE_BATCH_IMPORT_TEMPLATE'),
      handleAction: () => openSupplierProfilesImportModal(),
      actionIcon: () => '/images/inpulse/file-upload-black-small.svg',
      isDisabled: () => !canImportSupplierProfile(authorizedActions),
      isHidden: () => hasSelectedItems || !canImportSupplierProfile(authorizedActions),
    },
  ];

  if (onlyOneItemSelected) {
    actions.push({
      id: 'manage-multi-price',
      actionLabel: i18next.t('GENERAL.LIST_ACTION_MANAGE_MULTI_PRICE'),
      handleAction: async () => {
        await openManageMultiPriceModal(head(selectedItems)); // onlyOneItemSelected
      },
      actionIcon: () => '/images/inpulse/gear-ipblack-small.svg',
      isHighlighted: () => true,
      isHidden: () => !isUserDeepsight(user),
    });
  }

  return sortActionsList(actions);
};

export const getRowActions = (props) => {
  const {
    user,
    suppliers,
    renderExport,
    handleDisableSupplier,
    handleSupplierChangeModalBatch,
    openGenericModal,
    openManageMultiPriceModal,
    authorizedActions,
    hasLocalCatalogs,
  } = props;

  const shouldHideAssignCategoryToSupplier = (selectedItem) => {
    const userCatalog = user.catalog || {};

    if (!canEditSuppliers(authorizedActions)) {
      return true;
    }

    if (
      hasLocalCatalogs &&
      !userHasRoleInpulse(user) &&
      selectedItem.catalog.id !== userCatalog.id
    ) {
      return true;
    }

    return false;
  };

  const rowActions = [
    {
      id: 'assign-category',
      actionLabel: () => i18next.t('GENERAL.LIST_ACTION_ASSIGN_CATEGORY'),
      handleAction: (supplier) => {
        const params = getAssignCategorySubCategoryModalParams(
          suppliers,
          [supplier],
          'category',
          handleSupplierChangeModalBatch,
        );

        openGenericModal(params);
      },
      actionIcon: () => '/images/inpulse/link-black-small.svg',
      isHidden: (item) => item.isKitchen || shouldHideAssignCategoryToSupplier(item),
    },
    {
      id: 'export-only-one',
      actionLabel: () => i18next.t('ADMIN.SUPPLIERS.EXPORT_ONLY_ONE'),
      handleAction: (supplier) => renderExport([supplier]),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      isHidden: (item) => item.isKitchen && !isUserMapped([item], user),
    },
    {
      id: 'deactivate',
      actionLabel: (supplier) =>
        !!supplier.active
          ? i18next.t('ADMIN.SUPPLIERS.DISABLE_SUPPLIER')
          : i18next.t('ADMIN.SUPPLIERS.ENABLE_SUPPLIER'),
      handleAction: (supplier) => {
        handleDisableSupplier(supplier);
      },
      actionIcon: () => '/images/inpulse/power-black-small.svg',
      isHidden: (item) =>
        (item.isKitchen && !isUserMapped([item], user)) || shouldHideAssignCategoryToSupplier(item),
    },
    {
      id: 'manage-multi-price',
      actionLabel: () => i18next.t('GENERAL.LIST_ACTION_MANAGE_MULTI_PRICE'),
      handleAction: async (supplier) => {
        await openManageMultiPriceModal(supplier);
      },
      actionIcon: () => '/images/inpulse/gear-ipblack-small.svg',
      isHighlighted: () => true,
      isHidden: () => !isUserDeepsight(user),
    },
  ];

  return sortActionsList(rowActions);
};
