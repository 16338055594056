import iconOffline from '../images/icon-toaster-offline.svg';

export const TOASTER_STATUS = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const TOASTER_CUSTOM_ICON = {
  offline: iconOffline,
};

export const TOASTER_POSITION = {
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
};

export default {
  TOASTER_STATUS,
  TOASTER_POSITION,
  TOASTER_CUSTOM_ICON,
};
