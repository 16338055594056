import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

import theme from '@theme';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { Label } from '@commons/utils/styledLibraryComponents';
import DisplayNumber from '@commons/DisplayNumber';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { LabelContainer } from '../styledComponents';

export const formatDate = (date, userLanguageCode) =>
  moment(date).format(
    userLanguageCode === 'us'
      ? DATE_DISPLAY_FORMATS.SLASHED_MONTH_DAY_YEAR
      : DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR,
  );

export const getListColumns = (userLanguageCode, storeName) => [
  {
    id: 'supplier.name',
    propertyKey: 'supplier.name',
    name: i18next.t('GENERAL.SUPPLIER'),
  },
  {
    id: 'store.name',
    propertyKey: 'store.name',
    name: getClientStoreNameTranslation(storeName),
  },
  {
    id: 'createdAt',
    propertyKey: 'createdAt',
    filterType: 'date',
    excludeFromSearch: true,
    name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_IMPORT_DATE'),
    render: (date) => formatDate(date, userLanguageCode),
  },
  {
    id: 'date',
    propertyKey: 'date',
    filterType: 'date',
    excludeFromSearch: true,
    name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_DATE'),
    render: (date) => (!!date ? formatDate(date, userLanguageCode) : '-'),
  },
  {
    id: 'number',
    propertyKey: 'number',
    name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_NUMBER'),
    render: (number) => (!!number ? number : '-'),
  },
  {
    id: 'total',
    propertyKey: 'total',
    filterType: 'numeric',
    excludeFromSearch: true,
    name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICED_TOT_EX_TAX'),
    render: (item) => (!!item ? <DisplayNumber displayCurrencyCode={true} number={item} /> : '-'),
  },
  {
    id: 'computedOrderReferences',
    propertyKey: 'computedOrderReferences', // to make search on this field possible
    filterType: 'numeric',
    name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_ASSOCIATED_ORDERS'),
    render: (_, { orders }) => {
      const count = orders.length;

      const isWarning = count === 0;

      return (
        <LabelContainer>
          <Label
            background={isWarning ? theme.colors.infoRed : theme.colors.infoGreen}
            color={isWarning ? theme.colors.infoRed : theme.colors.infoGreen}
            type={null}
          >
            {count}
          </Label>
        </LabelContainer>
      );
    },
  },
];
