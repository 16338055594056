import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { password_reset } from '@actions/password';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { Input } from '@lib/inpulse/Input';

import { REGEX_MATCH_EMAIL_INSENSITIVE } from '@commons/utils/regex';
import UserRegistration from '@commons/DeepsightComponents/UserRegistration';

import {
  ResetEmailContentContainer,
  Text,
  InputContainer,
  EmailSentContentContainer,
} from './styledComponent';

const ResetEmail = (props) => {
  const { email, setEmail } = props.params;

  return (
    <ResetEmailContentContainer>
      <Text>{i18next.t('AUTHENTICATION.LOGIN.RESET_PASSWORD_CONTENT_TEXT')}</Text>
      <InputContainer>
        <Input
          label={`* ${i18next.t('AUTHENTICATION.LOGIN.EMAIL')}`}
          type="text"
          value={email || ''}
          onChange={(ev) => setEmail(ev.target.value)}
        />
      </InputContainer>
    </ResetEmailContentContainer>
  );
};

const EmailSent = () => (
  <EmailSentContentContainer>
    <img src="/images/inpulse/send-email.svg" />
    <Text>{i18next.t('AUTHENTICATION.LOGIN.RESET_PASSWORD_EMAIL_SENT_CONTENT_TEXT')}</Text>
  </EmailSentContentContainer>
);

export const AskResetPassword = (props) => {
  const { showMessage, passwordReset } = props;

  const [email, setEmail] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [actions, setActions] = useState([]);

  const history = useHistory();

  const getActions = () => {
    if (!!isSent) {
      setActions([]);
      return;
    }

    setActions([
      {
        color: 'inpulse-outline',
        isDisabled: false,
        label: i18next.t('GENERAL.BACK'),
        icon: '/images/inpulse/arrow-left-ip-black.svg',
        handleClick: () => history.push('/login'),
      },
      {
        color: 'inpulse-default',
        isDisabled: !email.match(REGEX_MATCH_EMAIL_INSENSITIVE),
        label: i18next.t('GENERAL.SEND'),
        icon: '/images/inpulse/send-white-small.svg',
        handleClick: handleEmailSend,
      },
    ]);
  };

  useEffect(() => {
    getActions();
  }, [email, isSent]);

  const handleEmailSend = async () => {
    try {
      await passwordReset(email.toLowerCase());
      setIsSent(true);
    } catch (err) {
      showMessage(i18next.t('AUTHENTICATION.LOGIN.RESET_PASSWORD_ERROR'), 'error');
    }
  };
  return (
    <>
      <UserRegistration
        actions={actions}
        Component={!!isSent ? EmailSent : ResetEmail}
        headerPadding={!!isSent ? '32px 0px 32px 48px' : '20px 0px 20px 48px'}
        height={!!isSent ? '430px' : '360px'}
        params={{ email, setEmail }}
        title={
          !!isSent
            ? i18next.t('AUTHENTICATION.LOGIN.RESET_PASSWORD_EMAIL_SENT_TITLE')
            : i18next.t('AUTHENTICATION.LOGIN.RESET_PASSWORD_TITLE')
        }
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  passwordReset: (email) => dispatch(password_reset(email)),
});

export default connect(null, mapDispatchToProps)(AskResetPassword);
