import i18next from 'i18next';

import InputPackagingContentModal from '../components/InputPackagingContentModal';

import { PACKAGING_MODAL_TYPE } from '../components/ListRow';

export const getPackagingModalParams = ({
  availableUnits,
  currentPackaging,
  entity,
  unitDisabled,
  handlePackagingSaving,
  onInputChange,
  modalType,
  isDuplicated,
}) => ({
  type: 'action',
  width: '560px',
  height: 'auto',
  icon:
    modalType === PACKAGING_MODAL_TYPE.CREATE
      ? '/images/inpulse/add-black-small.svg'
      : '/images/inpulse/pen-black-small.svg',
  title:
    modalType === PACKAGING_MODAL_TYPE.CREATE || !currentPackaging
      ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_CONDITIONING_ADD')
      : i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_CONDITIONING_EDIT', {
          packagingName: currentPackaging.name,
        }),
  component: InputPackagingContentModal,
  data: {
    availableUnits,
    currentPackaging,
    entity,
    unitDisabled,
    isDuplicated,
    onChange: onInputChange,
    modalType,
  },
  actions: [
    {
      key: 0,
      color: 'inpulse-outline',
      label: i18next.t('GENERAL.CANCEL'),
      icon: '/images/inpulse/close-black-small.svg',
    },
    {
      key: 1,
      color: 'inpulse-default',
      label: i18next.t('GENERAL.SAVE'),
      icon: '/images/inpulse/save-white-small.svg',
      handleClick: handlePackagingSaving,
      isDisabled: !currentPackaging?.name || !currentPackaging?.unit || !currentPackaging?.quantity,
    },
  ],
});
