import _ from 'lodash';
import i18next from 'i18next';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CREATE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

import InventoryCreateInitialSelectors from './InventoryCreateInitialSelectors';

const getCreateInitialInventoryModalActions = (props) => {
  const {
    selectedStores,
    selectedDate,
    handleCreateInitialInventory,
    resetCreateInitialInventoryModal,
  } = props;

  const disabledAction = _.isEmpty(selectedStores) || !selectedDate;

  const selectedStoresIds = selectedStores.map((store) => store.id);

  return [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_CREATE_BUTTON(),
      handleClick: () => {
        handleCreateInitialInventory(selectedStoresIds, selectedDate);
        resetCreateInitialInventoryModal();
      },
      isDisabled: disabledAction,
    },
  ];
};

export const getCreateInitialInventoryModalConfig = (props) => {
  const { resetCreateInitialInventoryModal } = props;
  const params = {
    type: 'action',
    width: '542px',
    height: 'auto',
    icon: '/images/inpulse/add-black-small.svg',
    title: i18next.t('STOCKS.STOCKS.LIST_CREATE_INITIAL_INVENTORY'),
    handleCloseCleanUp: resetCreateInitialInventoryModal,
    actions: getCreateInitialInventoryModalActions(props),
    component: InventoryCreateInitialSelectors,
    data: props,
  };

  return params;
};
