import i18next from 'i18next';
import React from 'react';

import { InpulseLabel } from '@commons/DeepsightComponents';

export const getColumnsSettings = ({ stores, clientStoreNameTranslation }) => [
  {
    id: 'location-name',
    name: i18next.t('GENERAL.NAME'),
    displayName: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
    baseName: 'name',
    render: (it) => it,
  },
  {
    id: 'id',
    name: clientStoreNameTranslation,
    displayName: clientStoreNameTranslation,
    propertyKey: 'id',
    baseName: 'id',
    name: '',
    render: (item) => {
      const matchingLocations = (stores || []).filter((store) => store.locationId === item);

      return <InpulseLabel text={matchingLocations.length.toString()} />;
    },
  },
];

export default {
  getColumnsSettings,
};
