import i18next from 'i18next';

import { numberToFixed } from '../utils/format';

import { PageLimitOption } from './interfaces';

export function getPageLimitOptions(nbElements: number): PageLimitOption[] {
  return [
    {
      id: 1,
      value: 20,
      text: `20 ${i18next.t('COMPONENT_LIST_PAGINATION_PER_PAGE')}`,
      callback: (setPageLimit: (n: number) => void): void => setPageLimit(20),
    },
    {
      id: 2,
      value: 100,
      text: `100 ${i18next.t('COMPONENT_LIST_PAGINATION_PER_PAGE')}`,
      callback: (setPageLimit: (n: number) => void): void => setPageLimit(100),
    },
    {
      id: 3,
      value: nbElements,
      text: i18next.t('GENERAL_ALL'),
      callback: (
        setPageLimit: (n: number) => void,
        nbTotalElements?: number
      ): void => {
        if (nbTotalElements) {
          setPageLimit(nbTotalElements);
        }
      },
    },
  ];
}

export const TYPE_FIELD = {
  currency: {
    name: 'currency',
    format: (
      row: unknown,
      value: number,
      decimals: number,
      render?: (field: unknown, row: unknown) => unknown
    ): string | number =>
      render
        ? (render(value, row) as string)
        : numberToFixed(value, decimals, '-', ' €'),
  },
  percentage: {
    name: 'percentage',
    format: (
      row: unknown,
      value: number,
      decimals: number,
      render?: (field: unknown, row: unknown) => unknown
    ): string | number =>
      render
        ? (render(value, row) as string)
        : numberToFixed(value, decimals, '-', ' %'),
  },
  numeric: {
    name: 'numeric',
    format: (
      row: unknown,
      value: number,
      decimals: number,
      render?: (field: unknown, row: unknown) => unknown
    ): string | number =>
      render
        ? (render(value, row) as string)
        : numberToFixed(value, decimals, '-'),
  },
  date: {
    name: 'date',
    format: (
      row: unknown,
      value: unknown,
      decimals: number,
      render?: (field: unknown, row: unknown) => unknown
    ): unknown => (render ? render(value, row) : value),
  },
  string: {
    name: 'string',
    format: (
      row: unknown,
      value: number,
      decimals: number,
      render?: (field: unknown, row: unknown) => unknown
    ): string | number => (render ? (render(value, row) as string) : value),
  },
};

export const ORDER_TYPE: { ASCENDING: 'asc'; DESCENDING: 'desc' } = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};
