export const downloadFileFromBase64 = (href, fileName, fileExtension) => {
  const link = document.createElement('a');

  link.href = href;
  link.setAttribute('download', !!fileExtension ? `${fileName}.${fileExtension}` : `${fileName}`);

  document.body.appendChild(link);

  link.click();
};

export default {
  downloadFileFromBase64,
};
