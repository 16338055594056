import i18next from 'i18next';
import React from 'react';

import {
  Container,
  ErrorText,
  InputContainer,
} from '@commons/Modals/InputContentModal/styledComponents';

import { Input } from '@lib/inpulse/Input';

import ConfirmationModal from '@backoffice/components/ConfirmationModal';

const LocationModal = (props) => {
  const { name, partnerId, onChange, errorMessageName, errorMessagePartnerId } = props;

  return (
    <>
      <Container>
        <InputContainer>
          <Input
            error={!!errorMessageName}
            label={`* ${i18next.t('GENERAL.NAME')}`}
            placeholder={''}
            type="text"
            value={name}
            width={240}
            autoFocus
            onChange={(e) => onChange(e.target.value, partnerId)}
          />
          {errorMessageName && <ErrorText>{errorMessageName}</ErrorText>}
        </InputContainer>
      </Container>
      <Container style={{ marginTop: '24px' }}>
        <InputContainer>
          <Input
            error={!!errorMessagePartnerId}
            label={`* ${i18next.t('BACKOFFICE.CASHIER_STORES.PARTNER_ID')}`}
            placeholder={''}
            type="text"
            value={partnerId}
            width={240}
            onChange={(e) => onChange(name, e.target.value)}
          />
          {errorMessagePartnerId && <ErrorText>{errorMessagePartnerId}</ErrorText>}
        </InputContainer>
      </Container>
    </>
  );
};

export const getLocationModalConfig = ({
  name,
  partnerId,
  originalLocation,
  forbiddenNames,
  forbiddenPartnerIds,
  onSave,
  onInputChange,
}) => {
  const isCreation = !originalLocation.id;

  const errorMessageName = forbiddenNames.includes(name.toLowerCase())
    ? i18next.t('BACKOFFICE.LOCATIONS.CREATE_MODAL_DUPLICATE_NAME')
    : '';

  const errorMessagePartnerId = forbiddenPartnerIds.includes(partnerId.toLowerCase())
    ? i18next.t('BACKOFFICE.LOCATIONS.CREATE_MODAL_DUPLICATE_PARTNER_ID')
    : '';

  const isFormDisabled = () => {
    const areFieldsInvalid = !name || !partnerId || !!errorMessageName || !!errorMessagePartnerId;

    if (isCreation || areFieldsInvalid) {
      return areFieldsInvalid;
    }

    return (
      originalLocation.name.toLowerCase() === name.toLowerCase() &&
      originalLocation.partnerId.toLowerCase() === partnerId.toLowerCase()
    );
  };

  return {
    type: 'action',
    width: '542px',
    height: 'auto',
    icon: isCreation
      ? '/images/inpulse/add-black-small.svg'
      : '/images/inpulse/edit-pen-black-small.svg',
    title: i18next.t(
      isCreation ? 'BACKOFFICE.LOCATIONS.CREATE_MODAL_TITLE' : 'BACKOFFICE.LOCATIONS.EDIT',
    ),
    component: LocationModal,
    data: {
      name,
      partnerId,
      errorMessageName,
      errorMessagePartnerId,
      onChange: (name, partnerId) => onInputChange(originalLocation, name, partnerId),
    },
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.SAVE'),
        icon: '/images/inpulse/save-white-small.svg',
        handleClick: () => onSave(originalLocation.id, name, partnerId),
        isDisabled: isFormDisabled(),
      },
    ],
  };
};

export const getDeleteLocationModalConfig = ({ locationIds, handleDeleteLocation }) => {
  const isMultipleDelete = locationIds.length > 1;

  return {
    type: 'warning',
    width: '542px',
    height: 'auto',
    icon: '/images/inpulse/trash-white-small.svg',
    title: isMultipleDelete
      ? i18next.t('BACKOFFICE.LOCATIONS.DELETE_MODAL_TITLE_PLURAL')
      : i18next.t('BACKOFFICE.LOCATIONS.DELETE_MODAL_TITLE_SINGULAR'),
    component: ConfirmationModal,
    data: {
      content: isMultipleDelete
        ? i18next.t('BACKOFFICE.LOCATIONS.DELETE_MODAL_CONTENT_PLURAL')
        : i18next.t('BACKOFFICE.LOCATIONS.DELETE_MODAL_CONTENT_SINGULAR'),
    },
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.DELETE'),
        icon: '/images/inpulse/trash-white-small.svg',
        handleClick: () => handleDeleteLocation(locationIds),
      },
    ],
  };
};

export default {
  getLocationModalConfig,
  getDeleteLocationModalConfig,
};
