import i18next from 'i18next';

// COMMONS
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

import { SelectAvailabilitiesStepContent } from '@admin/suppliers/supplierProducts/utils/getSspmStepModalConfig';

export const getSspmAvailabilitiesModalConfig = ({
  selectedAvailabilities,
  setSelectedAvailabilities,
  sspmIds,
  handleSspmUpdate,
  handleSspmUpdateModalCloseCleanUp,
}) => ({
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      handleClick: () => {
        handleSspmUpdate(sspmIds, selectedAvailabilities);
        handleSspmUpdateModalCloseCleanUp();
      },
      preventClosing: true,
    },
  ],
  title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_TITLE_UPDATE_AVAILABILITIES'),
  icon: '/images/inpulse/pen-black-small.svg',
  type: 'action',
  handleCloseCleanUp: handleSspmUpdateModalCloseCleanUp,
  component: SelectAvailabilitiesStepContent,
  data: {
    selectedAvailabilities,
    setSelectedAvailabilities,
  },
  height: 'auto',
});
