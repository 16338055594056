import { isEmpty } from 'lodash';

import { ENTITY_UNITS } from '../constants/units';
import { SMALL_UNITS_DROPDOWN_ITEMS } from '../../routes/admin/utils/DropdownItems';

/**
 * Assert than a supplier product is the same unit than the ingredient itself
 *
 *
 * @param {string} supplierProductUnit  - the supplier product unit to check
 * @param {string} ingredientUnit       - the ingredient's unit to match
 *
 * @returns {boolean} Whether the supplier product is the same unit than the ingredient unit
 */
export function areSPAndIngredientSameUnit(supplierProductUnit, ingredientUnit) {
  if (!supplierProductUnit) {
    return false;
  }

  if (supplierProductUnit === ENTITY_UNITS.KILOGRAM && ingredientUnit !== ENTITY_UNITS.GRAM) {
    return false;
  }

  if (supplierProductUnit === ENTITY_UNITS.LITER && ingredientUnit !== ENTITY_UNITS.MILLILITER) {
    return false;
  }

  if (supplierProductUnit === ENTITY_UNITS.UNIT && ingredientUnit !== ENTITY_UNITS.UNIT) {
    return false;
  }

  return true;
}

/**
 * Check that the package unit and ingredient are either the same, or that a conversion exist for this package unit.
 *
 * @param packageUnit
 * @param ingredientUnit
 * @param conversions
 *
 * @returns {boolean}
 */
export const doesIngredientsHaveApplicableUnits = (packageUnit, ingredientUnit, conversions) => {
  // Whether there are conversions or not, if no packaging unit are set, we consider that the unit is the same
  if (!packageUnit) {
    return true;
  }

  const areSameUnit = areSPAndIngredientSameUnit(packageUnit, ingredientUnit);
  const haveConversions = doesPackageUnitHaveConversions(packageUnit, ingredientUnit, conversions);

  return areSameUnit || haveConversions;
};

/**
 * Check that the package unit has an existing conversion to the ingredient unit
 *
 * @param packageUnit
 * @param ingredientUnit
 * @param conversions
 *
 * @returns {boolean}
 */
export const doesPackageUnitHaveConversions = (packageUnit, ingredientUnit, conversions) => {
  if (isEmpty(conversions)) {
    return false;
  }

  const packageIsBaseUnit = conversions.some(
    ({ isBaseUnit, convertedUnit }) => isBaseUnit && convertedUnit === packageUnit,
  );

  const hasAConversionForIngredient = conversions.some(({ convertedUnit }) =>
    areSPAndIngredientSameUnit(convertedUnit, ingredientUnit),
  );

  return packageIsBaseUnit && hasAConversionForIngredient;
};

export const spUnitByIngredientUnit = new Map([
  ['unit', 'unit'],
  ['g', 'kg'],
  ['mL', 'L'],
]);

export const getIngredientUnitName = (value) => {
  const smallUnitItem = SMALL_UNITS_DROPDOWN_ITEMS.find(({ id }) => id === value);
  return isEmpty(smallUnitItem) ? '' : smallUnitItem.value;
};
