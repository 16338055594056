import { get, pick, keyBy, isEmpty } from 'lodash';
import moment from 'moment';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import {
  getCustomFilterMultipleAvailableOrder,
  getCustomFilterMultipleAvailableStock,
  getCustomFilterMultipleAvailableLoss,
} from '@commons/utils/filtersFetches';

const computeSupplierProductMappings = (supplierProductMappings = [], associatedtStores = []) => {
  const storesKeyById = keyBy(associatedtStores, 'id');

  return supplierProductMappings.map((mapping) => {
    const matchingStore = storesKeyById[mapping.storeId];

    let nextAvailabilityDate = null;

    if (!isEmpty(mapping.nextAvailability)) {
      nextAvailabilityDate = moment(mapping.nextAvailability.startDate).format(
        DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR,
      );
    }

    return {
      ...pick(mapping, ['id', 'name', 'storeId', 'hasDlc', 'hasStock', 'available']),
      supplierProfileId: get(matchingStore, 'supplierProfile.id'),
      supplierProfileName: get(matchingStore, 'supplierProfile.name', '-'),
      storeName: get(mapping, 'lnkStoreStoresupplierproductmappingrel.name', '-'),
      storeActive: get(mapping, 'lnkStoreStoresupplierproductmappingrel.active', '-'),
      isCentralKitchenStore: get(
        mapping,
        'lnkStoreStoresupplierproductmappingrel.isKitchen',
        false,
      ),
      nextAvailabilityDate,
      nextAvailabilityValue: get(mapping, 'nextAvailability.available', null),
    };
  });
};

const computeMultipleDropdownsFilter = ({
  supplierProfiles,
  selectedSupplierProfiles,
  setSelectedSupplierProfiles,
  selectedOrderAvailability,
  setSelectedOrderAvailability,
  selectedStockAvailability,
  setSelectedStockAvailability,
  selectedLossAvailability,
  setSelectedLossAvailability,
}) => [
  {
    id: 'supplier-profiles',
    icon: '/images/inpulse/supplier-black-small.svg',
    list: supplierProfiles,
    defaultSelectedItems: supplierProfiles,
    selectedItems: selectedSupplierProfiles,
    setSelectedItems: setSelectedSupplierProfiles,
  },
  getCustomFilterMultipleAvailableOrder(selectedOrderAvailability, setSelectedOrderAvailability),
  getCustomFilterMultipleAvailableStock(selectedStockAvailability, setSelectedStockAvailability),
  getCustomFilterMultipleAvailableLoss(selectedLossAvailability, setSelectedLossAvailability),
];

export default {
  computeSupplierProductMappings,
  computeMultipleDropdownsFilter,
};
