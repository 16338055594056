import React from 'react';

import { CENTRAL_KITCHEN_PRODUCTION_FEATURE_PATHS } from '../../commons/utils/featurePaths';
import { ENUM_MODULE_NAME } from '../../commons/utils/features';

import { ModuleContainer } from '../ModuleContainer';
import CentralKitchenProductionOperations from './CentralKitchenProductionOperations';
import CentralKitchenProductionPlanning from './CentralKitchenProductionPlanning';
import CentralKitchenProductionRecommendations from './CentralKitchenProductionRecommendations';

export const CentralKitchenProductionModuleContainer = (props) => {
  const { userRights, history } = props;

  return (
    <ModuleContainer
      history={history}
      moduleFeaturePaths={[
        CENTRAL_KITCHEN_PRODUCTION_FEATURE_PATHS.PRODUCTION_CENTRAL_KITCHEN_OPERATIONS,
      ]}
      moduleName={ENUM_MODULE_NAME.CENTRAL_KITCHEN_PRODUCTION}
      userRights={userRights}
    />
  );
};

// ===== Module features for easy import in ClientContainer =====

const CentralKitchenProductionModule = {
  CentralKitchenProductionModuleContainer,
  CentralKitchenProductionOperations,
  CentralKitchenProductionRecommendations,
  CentralKitchenProductionPlanning,
};

export default CentralKitchenProductionModule;
