import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

import ColumnFilter from './components/ColumnFilter';

import { Container, Header, Content } from './styledComponents';

export const AdvancedFiltersBlock = (props) => {
  const {
    filters,
    setFilters,
    columnsList,
    activeColumnsList,
    filtersParams,
    addNewAdvancedFilter,
  } = props;

  const [isFilterCompleted, setIsFilterCompleted] = useState(false);
  const [lastFilterId, setLastFilterId] = useState(null);

  const isFilterAvailable = !!activeColumnsList.length;

  useEffect(() => {
    if (!filters) {
      return;
    }
    const activeFilters = filters.filter((filter) => filter.display);

    if (!activeFilters.length) {
      return;
    }
    setLastFilterId(activeFilters.pop().filterId);
  }, [filters]);

  return (
    <Container>
      <Header>{`${i18next.t('GENERAL.ANALYTICS_ADVANCED_FILTERS')} :`}</Header>
      <Content>
        {filters &&
          filters.map((filter) => (
            <ColumnFilter
              activeColumnsList={activeColumnsList}
              columnsList={columnsList}
              filter={filter}
              filtersParams={filtersParams}
              key={filter.filterId}
              lastFilterId={lastFilterId}
              setFilters={setFilters}
              setIsFilterCompleted={setIsFilterCompleted}
            />
          ))}
        <div style={{ paddingTop: '26px' }}>
          <Button
            color={'inpulse-link'}
            handleClick={() => {
              if (isFilterCompleted && isFilterAvailable) {
                return addNewAdvancedFilter();
              }

              return true;
            }}
            icon={'/images/inpulse/add-black.svg'}
            iconOnDisabled={'/images/inpulse/add-lmgrey-small.svg'}
            iconOnLeft={false}
            isDisabled={!(isFilterCompleted && isFilterAvailable)}
            label={i18next.t('GENERAL.ANALYTICS_ADD_FILTER')}
          />
        </div>
      </Content>
    </Container>
  );
};

AdvancedFiltersBlock.propTypes = {
  filters: PropTypes.array.isRequired,
  setFilters: PropTypes.func.isRequired,
};

AdvancedFiltersBlock.defaultProps = {};

export default AdvancedFiltersBlock;
