import getCentralKitchenProductionPlanningSettings from './getCentralKitchenProductionPlanningSettings';
import getStoreCompanySettings from './getStoreCompanySettings';
import getStoreDuplicationSettingsCardSettings from './getStoreDuplicationSettingsCardSettings';
import getStoreGPSCoordinatesCardSettings from './getStoreGPSCoordinatesCardSettings';
import getStoreInformationCardSettings from './getStoreInformationCardSettings';
import getStoreLocationCardSettings from './getStoreLocationCardSettings';
import getStoreProductionCardSettings from './getStoreProductionCardSettings';
import getStoreProductMixCardSettings from './getStoreProductMixCardSettings';
import getStoreSettingsCardSettings from './getStoreSettingsCardSettings';

export { STORE_DUPLICATION_TYPE, CLONE_STORE_STEP } from './getStoreSettingsCardSettings';

export default {
  getStoreInformationCardSettings,
  getStoreGPSCoordinatesCardSettings,
  getStoreLocationCardSettings,
  getStoreProductionCardSettings,
  getStoreProductMixCardSettings,
  getStoreSettingsCardSettings,
  getStoreDuplicationSettingsCardSettings,
  getCentralKitchenProductionPlanningSettings,
  getStoreCompanySettings,
};
