import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 106px);
  position: absolute;
  margin-top: 106px;
  background: ${(props) => props.theme.colors?.greys.lighter};
`;
