import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styledComponents';

import { Props } from './interfaces';

const Marker = (props: Props): JSX.Element => {
  const { color, icon, extraMarginTop } = props;

  return (
    <Container extraMarginTop={extraMarginTop} color={color} icon={icon} />
  );
};

Marker.propTypes = {
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  extraMarginTop: PropTypes.bool,
};

Marker.defaultProps = {
  extraMarginTop: false,
};

export default Marker;
