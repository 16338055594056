import styled from 'styled-components';

export const Container = styled.div`
  height: 88px;
  width: 100%;

  overflow-x: scroll;
  padding: 24px 24px 10px 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${(props) => props.theme.colors.greys.lightest};
  box-shadow: inset 0px 2px 0px ${(props) => props.theme.colors.greys.lighter};
`;

export const StockFormHeaderInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 24px;
`;
