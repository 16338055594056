import axios from '../core/http';

import { APIcaller } from './APIcaller';
import { config } from '../config';
const baseApiUrl = config.baseApiUrl;

const getForecastsOfStore = (storeId, startDate, endDate) =>
  APIcaller.apiGet(`/forecast/stores/${storeId}`, { startDate, endDate });

const updateOrCreateMultipleForecastsByDate = (params) => {
  let newForecastsArray = [];
  const promiseArray = params.map((forecast) =>
    fetch(baseApiUrl + '/Forecasts/customPatchForDates', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
      body: JSON.stringify(forecast),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((result) => {
        newForecastsArray = newForecastsArray.concat(result);
      }),
  );
  return Promise.all(promiseArray).then(() => newForecastsArray);
};

// Check if actually used, if not remove
const getForecastsOfStores = (storeIdsArray, start_date, end_date, granularity) =>
  axios
    .post(
      baseApiUrl + '/Forecasts/findAndAggregateForStores/',
      {
        arrayOfStores: storeIdsArray,
        startDate: start_date,
        endDate: end_date,
        granularity: granularity,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then((response) => (response.data.data ? response.data.data : []))
    .catch((error) => {
      throw error;
    });

const checkForecastsValid = (storeId, startDate, endDate) =>
  axios
    .get(`${baseApiUrl}/admin/stores/${storeId}/forecasts/valid`, {
      params: {
        startDate,
        endDate,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data);

export const getPastProductMix = (storeIds, startDate, endDate) =>
  APIcaller.apiPost('/forecast/product-mix/past', { storeIds, startDate, endDate });

export const getForecastsByStoreIdAndDates = (storeIds, startDate, endDate) =>
  APIcaller.apiPost('/forecast/stores', { storeIds, startDate, endDate });

export const forecast = {
  getForecastsOfStore,
  updateOrCreateMultipleForecastsByDate,
  getForecastsOfStores,
  checkForecastsValid,
  getPastProductMix,
  getForecastsByStoreIdAndDates,
};
