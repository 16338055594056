import styled from 'styled-components';

import ColorPalette from '../../../utils/colorPalette';

export const NestedDataContainer = styled.div`
  margin: 16px 0px;

  height: 100%;
`;

export const NestedDataRow = styled.div`
  background-color: ${ColorPalette.LGrey};
  background-image: linear-gradient(
    90deg,
    ${ColorPalette.WHITE} 8px,
    ${ColorPalette.LGrey} 8px,
    ${ColorPalette.LGrey} calc(100% - 8px),
    ${ColorPalette.WHITE} calc(100% - 8px)
  );

  &:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 48px;
  padding: 0 26px 0 0;
`;

export default {
  NestedDataContainer,
  NestedDataRow,
};
