import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  padding: 0 24px;

  position: relative;

  > div > div > div {
    overflow-x: visible;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 24px;
`;
