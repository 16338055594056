import styled from 'styled-components';

export const FiltersContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const IconActive = styled.img`
  width: 14px;
  height: 14px;

  position: absolute;
  top: -5px;
  left: 233px;
`;
