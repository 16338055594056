import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: fit-content;

  gap: 24px;
  display: flex;
  align-items: center;

  justify-content: end;
`;

export const Button = styled.div`
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;

  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  border: 1px solid #222321;
  position: relative;
`;

export const Icon = styled.img`
  width: 16px;
`;

export const ActionsContainer = styled.div`
  width: 240px;
  margin-top: ${(props) => (props.canIncludeExcelInOrderMail ? '160px' : '104px')};

  position: absolute;

  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  background: ${(props) => props.theme.colors?.greys?.lightest};
`;

export const Action = styled.div`
  width: 100%;

  padding: 12px 8px;

  gap: 8px;
  display: flex;
  align-items: center;

  background: ${(props) => props.theme.colors?.greys?.lightest};

  &:first-child {
    border-radius: 8px 8px 0px 0px;
  }

  &:last-child {
    border-radius: 0px 0px 8px 8px;
  }

  &:only-child {
    border-radius: 8px 8px 8px 8px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    background: ${(props) =>
      props.disabled ? props.theme.colors?.greys?.lightest : props.theme.colors?.greys?.lighter};
  }
`;
