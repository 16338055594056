import i18next from 'i18next';
import React from 'react';

import { SingleDatePicker } from '@commons/DatePickers/SingleDatePicker';
import { ToggleSwitch, Tooltip } from '@commons/utils/styledLibraryComponents';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

const {
  Container,
  LabelContainer,
  DatePickerContainer,
  ToggleContainer,
  SwithContainer,
} = require('./styledComponents');

const StoreProductMappingUpdateModal = (props) => {
  const { launchDate, setLaunchDate, endDate, setEndDate, hasStock, setHasStock } = props;

  return (
    <Container>
      <Text
        color={ENUM_COLORS.DARKEST}
        font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {i18next.t('ADMIN.STORES.UPDATE_STORE_PRODUCT_MAPPING_MODAL_CONTENT')}
      </Text>

      <DatePickerContainer>
        <LabelContainer>
          <Text
            color={ENUM_COLORS.DARKEST}
            font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {`${i18next.t('ADMIN.STORES.LIST_COLUMN_LAUNCH_DATE')} :`}
          </Text>

          <Tooltip text={i18next.t('ADMIN.STORES.LAUNCH_DATE_TOOLTIP')} displayBigger />
        </LabelContainer>
        <SingleDatePicker
          customStyle={{ position: 'inherit' }}
          date={launchDate}
          maxFutureDate={endDate}
          showClearDate
          onDateChange={setLaunchDate}
        />
      </DatePickerContainer>

      <DatePickerContainer>
        <LabelContainer>
          <Text
            color={ENUM_COLORS.DARKEST}
            font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {`${i18next.t('GENERAL.END_DATE')} :`}
          </Text>

          <Tooltip text={i18next.t('ADMIN.PRODUCTS.CONFIG_PRODUCTION_END_TOOLTIP')} displayBigger />
        </LabelContainer>
        <SingleDatePicker
          customStyle={{ position: 'inherit' }}
          date={endDate}
          maxPastDate={launchDate}
          showClearDate
          onDateChange={setEndDate}
        />
        {endDate && !launchDate && (
          <LabelContainer>
            <Text color={ENUM_COLORS.INFO_RED} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14_WEIGHT_600}>
              {i18next.t('ADMIN.STORES.UPDATE_PRODUCT_EMPTY_LAUNCH_DATE_ERROR')}
            </Text>
          </LabelContainer>
        )}
      </DatePickerContainer>

      <ToggleContainer>
        <SwithContainer>
          <ToggleSwitch
            checked={hasStock}
            handleClick={() => setHasStock(!hasStock)}
            id="hasStock"
          />
        </SwithContainer>
        <LabelContainer>
          <Text
            color={ENUM_COLORS.DARKEST}
            font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_PROPERTIES_INVENTORY_STOCKS')}
          </Text>

          <Tooltip
            text={i18next.t('ADMIN.PRODUCTS.CREATION_STOCK_INVENTORY_TOOLTIP')}
            displayBigger
          />
        </LabelContainer>
      </ToggleContainer>
    </Container>
  );
};

export default StoreProductMappingUpdateModal;
