import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  padding: 180px 0;

  background: ${(props) => props.theme.colors.greys.lighter};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MainText = styled.div`
  width: 100%;
  text-align: center;

  font: ${(props) => props.theme.fonts.textSmallHeight16};

  color: ${(props) => props.theme.colors.greys.darker};

  margin-top: 48px;
`;

export const Icon = styled.img`
  width: 105px;
  height: 95px;

  ${(props) =>
    props.customSize &&
    css`
      width: ${props.customSize};
      height: ${props.customSize};
    `};
`;
