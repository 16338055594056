import React from 'react';
import Proptypes from 'prop-types';

import { Container, Img } from './styledComponents';

import { ComponentLoaderProps } from '../../interfaces';

import SpinnerIcon from '../../../images/icon-loader.svg';

const ComponentLoader = (props: ComponentLoaderProps): JSX.Element => {
  const { width, height } = props;
  return (
    <Container>
      <Img
        alt="component-loader"
        height={`${height}px`}
        src={SpinnerIcon}
        width={`${width}px`}
      />
    </Container>
  );
};

ComponentLoader.propTypes = {
  width: Proptypes.number,
  height: Proptypes.number,
};

ComponentLoader.defaultProps = {
  width: 40,
  height: 40,
};

export default ComponentLoader;
