import i18next from 'i18next';

export const INGREDIENT_CREATION = {
  getText: () => i18next.t('ADMIN.ADMIN.INGREDIENTS_CREATION'),
  path: '/admin/products/ingredients/create',
  isVisible: () => false,
};

export const INGREDIENT_DETAILS = {
  getText: () => i18next.t('ADMIN.ADMIN.INGREDIENTS_DETAIL'),
  path: '/admin/products/ingredients/:id/details',
  isVisible: () => false,
};
