import { groupBy, sortBy, first, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';

import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { Dropdown } from '@commons/utils/styledLibraryComponents';

import EmptyState from '../EmptyState';
import IngredientAnalytics from '../IngredientAnalytics';

import { Container } from './styledComponents';
import IngredientByChannel from './IngredientByChannel';

export default ({ isLoading, clientId, stores, recipes }) => {
  const [selectedIngredient, setSelectedIngredient] = useState(null);

  const [ingredients, setIngredients] = useState([]);
  const [recipeByIngredientId, setRecipeByIngredientId] = useState({});

  useEffect(() => {
    if (!recipes.length) {
      setIngredients([]);
      setSelectedIngredient({});
      setRecipeByIngredientId({});

      return;
    }

    const recipesGroupByIngredientId = groupBy(recipes, 'ingredientId');

    const selectableListIngredients = recipes.reduce((acc, { ingredientId, ingredientName }) => {
      if (!acc[ingredientId]) {
        acc[ingredientId] = { id: ingredientId, name: ingredientName };
      }

      return acc;
    }, {});

    const sortIngredients = sortBy(selectableListIngredients, 'name');

    setIngredients(sortIngredients);
    setRecipeByIngredientId(recipesGroupByIngredientId);
    setSelectedIngredient(first(sortIngredients));
  }, [recipes]);

  if (isLoading || !selectedIngredient) {
    return (
      <div style={{ paddingBottom: '24px' }}>
        <DeepsightComponentLoader />
      </div>
    );
  }

  return (
    <Container>
      <Dropdown
        height={'64px'}
        isRequired={true}
        items={ingredients}
        label={'Choisissez votre ingredient :'}
        selectedItem={selectedIngredient}
        onSelectionChange={setSelectedIngredient}
      />
      {!recipeByIngredientId[selectedIngredient.id] && <EmptyState />}
      {recipeByIngredientId[selectedIngredient.id] && (
        <IngredientByChannel
          ingredientId={selectedIngredient.id}
          recipeByIngredientId={recipeByIngredientId}
        />
      )}
      {clientId && !isEmpty(stores) && (
        <IngredientAnalytics
          clientId={clientId}
          ingredientId={selectedIngredient.id}
          recipeByIngredientId={recipeByIngredientId}
          stores={stores}
        />
      )}
    </Container>
  );
};
