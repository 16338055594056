import { sortBy } from 'lodash';
import i18next from 'i18next';

import utilsXLS from '@commons/utils/makeXLS';

const createMainSheet = (inventoryListTemplates) => {
  const columns = [
    {
      propertyKey: 'name',
      name: i18next.t('GENERAL.NAME'),
    },
    {
      propertyKey: 'associatedSPsCount',
      name: i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.ASSOCIATED_SUPPLIER_PRODUCTS_COUNT_COLUMN'),
    },
    {
      propertyKey: 'associatedRecipesCount',
      name: i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.ASSOCIATED_ENTITIES_COUNT_COLUMN'),
    },
  ];

  const sortedILTs = sortBy(inventoryListTemplates, ({ name }) => name.toLowerCase());

  return utilsXLS.generateDefaultSheet(
    i18next.t('FEATURE.ADMIN.INVENTORY_LIST_TEMPLATES'),
    columns,
    sortedILTs,
  );
};

const exportInventoryListTemplates = async (inventoryListTemplates) => {
  const filename = i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.EXPORT_LIST_FILENAME');

  const mainSheet = createMainSheet(inventoryListTemplates);

  utilsXLS.makeXLS(filename, [mainSheet]);
};

export default { exportInventoryListTemplates };
