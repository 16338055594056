import { cloneDeep, filter, find, get, isEmpty, keyBy } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { closeGenericModal, openGenericModal, refreshGenericModal } from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage } from '@actions/messageconfirmation';

import { getClientInfo } from '@selectors/client';

import { ingredient as ingredientService } from '@services/ingredient';
import { supplier as supplierService } from '@services/supplier';

import { ENABLE_SP_MODAL_STEPS, getEnableSPmodalConfig } from './modalConfiguration';

/**
 * This component does not directly render anything. Instead it makes use of the GenericModal via
 * the redux actions, manages their respective states and takes care of the SP activation logic.
 *
 * @param {*} props
 * @returns
 */
const EnableSupplierProductModal = (props) => {
  const {
    supplierProduct,
    resetEnablingSP,
    handleCallToEnableSupplierProduct,
    openGenericModal,
    refreshGenericModal,
    user,
    client: { clientId, hasMultipleBrands },
    stores,
    pageLoading,
    pageLoaded,
    showErrorMessage,
  } = props;

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  const [currentStep, setCurrentStep] = useState(ENABLE_SP_MODAL_STEPS.MAP_INGREDIENT);
  const [userStores, setUserStores] = useState([]);

  const [mapIngredientData, setMapIngredientData] = useState({
    inRecipe: true,
    ingredientsList: [],
    updatedSupplierProduct: supplierProduct,
  });
  const [mapStoreData, setMapStoreData] = useState([]);
  const [configureStoresMappingsData, setConfigureStoresMappingsData] = useState({
    available: true,
    hasStock: true,
    hasDlc: true,
  });

  const resetModal = () => {
    resetEnablingSP();
  };

  const handleStoresSelection = (selectedStores) => {
    setMapStoreData(selectedStores);
  };

  const associateStoresToTheirSuppliers = (supplier, currentStores) => {
    const filteredStores = filter(currentStores, (store) => {
      const supplierStoresMappedByIds = keyBy(supplier.storesMapped, 'storeId');

      return !!supplierStoresMappedByIds[store.id];
    });

    const storesWithSupplierProfileName = filteredStores.map((store) => {
      const supplierMapping = supplier.storesMapped.find((mapping) => mapping.storeId === store.id);

      return { ...store, supplierProfileName: supplierMapping.supplierProfileName };
    });

    return storesWithSupplierProfileName;
  };

  const setSelectedRows = (currentStores) => {
    const mapStoreDataByIds = keyBy(mapStoreData, 'id');

    const updatedStores = currentStores.map((store) => {
      if (!isEmpty(mapStoreDataByIds) && !!mapStoreDataByIds[store.id]) {
        return { ...store, isRowSelected: true };
      }

      return { ...store, isRowSelected: false };
    });

    return updatedStores;
  };

  useEffect(() => {
    if (!currentStep) {
      return;
    }

    const isModalOpened = get(props, 'modal.GenericModalBool', false);

    (async function loadData() {
      pageLoading();

      let filteredStores = cloneDeep(userStores);
      try {
        if (!isModalOpened) {
          // Required to fetch linked data with ingredients in order to get the SP Mapping Count
          const fetchIngredients = await ingredientService.getIngredients(clientId, true);
          const activeIngredients = fetchIngredients.filter(({ active }) => active);

          setMapIngredientData({
            ...mapIngredientData,
            ingredientsList: activeIngredients.sort((a, b) => a.name.localeCompare(b.name)),
          });

          const clientSuppliers = await supplierService.getSuppliersOfClient(clientId, false, true);

          const currentSupplierProductSupplier = find(
            clientSuppliers,
            (supplier) => supplier.id === supplierProduct.supplierId,
          );

          filteredStores = associateStoresToTheirSuppliers(currentSupplierProductSupplier, stores);
        }

        const storesWithUpdatesIsRowSelected = setSelectedRows(filteredStores);

        setUserStores(filteredStores);

        const params = getEnableSPmodalConfig({
          currentStep,
          setCurrentStep,
          resetModal,
          supplierProduct,
          handleCallToEnableSupplierProduct,
          mapIngredientData,
          setMapIngredientData,
          mapStoreData,
          setMapStoreData,
          configureStoresMappingsData,
          setConfigureStoresMappingsData,
          activeUserStores: storesWithUpdatesIsRowSelected,
          userLanguageCode,
          handleStoresSelection,
          hasMultipleBrands,
        });

        if (isModalOpened) {
          refreshGenericModal(params);
          pageLoaded();

          return;
        }

        openGenericModal(params);
      } catch {
        showErrorMessage(i18next.t('ADMIN.INGREDIENTS.FETCH_ERROR'));
      } finally {
        pageLoaded();
      }
    })();
  }, [currentStep, mapIngredientData, configureStoresMappingsData]);

  return <div />;
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  stores: state.baseReducer.activeStores,
  modal: state.modalReducer,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

EnableSupplierProductModal.propTypes = {
  supplierProduct: PropTypes.object, // passed by SupplierProducts.js
  resetEnablingSP: PropTypes.func, // passed by SupplierProducts.js, to reset its state
  handleCallToEnableSupplierProduct: PropTypes.func, // passed by SupplierProducts.js, to perform the API call
  openGenericModal: PropTypes.func, // passed by redux
  closeGenericModal: PropTypes.func, // passed by redux
  refreshGenericModal: PropTypes.func, // passed by redux
};

export default connect(mapStateToProps, mapDispatchToProps)(EnableSupplierProductModal);
