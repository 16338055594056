import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftjsToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { EditorContainer } from './styledComponents';

import theme from '../../theme';

const WRAPPER_STYLE = {
  border: `1px solid ${theme.colors.greys.light}`,
  borderRadius: '8px',
  minHeight: '160px',
};

// WYSIWYG EDITOR DOCUMENTATION AND DEMO => https://jpuri.github.io/react-draft-wysiwyg/#/
// Basic implementation for now, we can customize it later with product's specific settings
export const WysiwygEditor = (props) => {
  const {
    content,
    setter,
    isDisabled,
    toolbarHidden = true,
    toolbar,
    stripPastedStyles = false,
  } = props;

  const [editorState, setEditorState] = useState({});

  useEffect(() => {
    if (!content) {
      setEditorState(EditorState.createEmpty());
      return;
    }

    const blocksFromHtml = htmlToDraft(content);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    setEditorState(EditorState.createWithContent(contentState));
  }, []);

  const onEditorChange = (editorChange) => {
    setEditorState(editorChange);

    const draftHtml = draftjsToHtml(convertToRaw(editorChange.getCurrentContent()));
    setter(draftHtml.replace(/\n/g, ''));
  };

  return (
    <EditorContainer isDisabled={isDisabled}>
      {!isEmpty(editorState) && (
        <Editor
          editorClassName="editor-class"
          editorState={editorState}
          readOnly={isDisabled}
          stripPastedStyles={stripPastedStyles}
          toolbar={toolbar}
          toolbarClassName="toolbar-class"
          toolbarHidden={toolbarHidden}
          wrapperClassName="wrapper-class"
          wrapperStyle={WRAPPER_STYLE}
          onEditorStateChange={onEditorChange}
        />
      )}
    </EditorContainer>
  );
};

export default WysiwygEditor;
