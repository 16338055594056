import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import clientService from '@services/client';

import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { getClientInfo } from '@selectors/client';

import ExportModalContent from '@lib/inpulse/ExportModal';

const ProgressBarModal = (props) => {
  const {
    client: { clientId },
    closeModal,
    showSuccessMessage,
    showErrorMessage,
    params: { stores, storeIdReference, setModalStep, closeModalCleanUp },
  } = props;

  const [isLoading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [title, setTitle] = useState(i18next.t('ADMIN.STORES.ASSORTMENT_SETUP_IN_PROGRESS'));

  useEffect(() => {
    if (!stores || !stores.length || !storeIdReference) {
      return;
    }

    updateStoreAssortment(stores, storeIdReference);
  }, [stores, storeIdReference]);

  const updateStoreAssortment = async (stores, storeIdReference) => {
    const storeIds = stores.map(({ id }) => id);

    try {
      for (const [index, storeId] of storeIds.entries()) {
        const updatedProgress = (index / stores.length) * 100;
        setProgress(updatedProgress);

        await clientService.cloneProduction(clientId, storeId, storeIdReference);
      }

      assortmentSetupSuccess();

      showSuccessMessage(i18next.t('ADMIN.STORES.SETUP_ASSORTMENT_FROM_ANOTHER_STORE_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORES.SETUP_ASSORTMENT_FROM_ANOTHER_STORE_FAILURE'));

      assortmentSetupFailure();
    } finally {
      setProgress(100);
    }
  };

  const exitModal = () => {
    setLoading(false);

    closeModal();

    if (closeModalCleanUp) {
      closeModalCleanUp();
    }

    setModalStep(null);
  };

  const assortmentSetupSuccess = () => {
    setTitle(i18next.t('ADMIN.STORES.ASSORTMENT_SETUP_SUCCESS'));
    setLoading(false);
  };

  const assortmentSetupFailure = () => {
    setTitle(i18next.t('ADMIN.STORES.ASSORTMENT_SETUP_FAILURE'));
    setLoading(false);
  };

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setLoading}
      titleModal={title}
    />
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBarModal);
