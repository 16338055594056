import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { cashierProductSalesService } from '@services/cashierProductSales';

import makeProductSalesXlsx from '../utils/makeProductSalesXlsx';

const _updateProgress = (totalToFetch, nbFetched, setProgress) => {
  const updatedProgress = (nbFetched / totalToFetch) * 100;
  setProgress(updatedProgress);
};
const _fetchFormattedCashierProductSales = async (props) => {
  const { startDate, endDate, selectedStoresIds, clientName, storeName, setProgress, currency } =
    props;

  const beginOfStartDate = moment(startDate).startOf('month');
  const endOfEndDate = moment(endDate).endOf('month');
  const nbMonthToFetch = Math.ceil(moment(endOfEndDate).diff(beginOfStartDate, 'month', true));

  const cashierStoreMappingBuffer = [];
  const cashierProductSalesBuffer = [];

  for (let index = 0; index < nbMonthToFetch; index++) {
    let currentStartDate = startDate;

    if (index !== 0) {
      currentStartDate = moment(startDate)
        .add(index, 'month')
        .startOf('month')
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
    }

    let currentEndDate = endDate;

    if (index !== nbMonthToFetch - 1) {
      currentEndDate = moment(currentStartDate)
        .endOf('month')
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
    }

    const formattedCashierProductSalesData =
      await cashierProductSalesService.exportCashierProductSales(
        currentStartDate,
        currentEndDate,
        selectedStoresIds,
      );

    cashierStoreMappingBuffer.push(
      ...formattedCashierProductSalesData.formattedStoreCashierStoreMappings,
    );
    cashierProductSalesBuffer.push(
      ...formattedCashierProductSalesData.formattedCashierProductSales,
    );
    _updateProgress(nbMonthToFetch, index + 1, setProgress);
  }

  makeProductSalesXlsx(
    {
      cashierProductSales: cashierProductSalesBuffer,
      cashierStoreMapping: cashierStoreMappingBuffer,
    },
    clientName,
    storeName,
    currency,
  );
};

export const exitModal = (setLoading, closeModal) => {
  closeModal();
};

const ExportRangeProductSales = (props) => {
  const { closeModal, params, currency } = props;
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [titleModal, setTitleModal] = useState(
    i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_PROGRESS_MODAL_TITLE'),
  );

  useEffect(() => {
    (async function loadData() {
      await _fetchFormattedCashierProductSales({ ...params, setProgress, currency });
    })();
  }, []);

  useEffect(() => {
    if (!progress || progress < 100) {
      return;
    }

    setTitleModal(i18next.t('GENERAL.EXPORT_SUCCESS'));
    setIsLoading(false);
  }, [progress]);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setIsLoading}
      titleModal={titleModal}
    />
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps)(ExportRangeProductSales);
