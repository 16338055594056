import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { ENUM_ROLES_NAME } from '@commons/utils/roles';
import {
  GENERIC_MODAL_ADD_BUTTON,
  GENERIC_MODAL_CANCEL_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { ListView } from '@commons/utils/styledLibraryComponents';
import EmptyState from '@commons/EmptyState';

import { getClientInfo } from '@selectors/client';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage } from '@actions/messageconfirmation';

import { user as userService } from '@services/user';

import { Container } from './styledComponents';

import { getUserMappingsColumns } from '../../utils/columns';

const UserMappingsModal = (props) => {
  const {
    client: { clientId, storeName },
    pageLoading,
    pageLoaded,
    alreadyMappedAccounts,
    setMappingsToBeAdded,
    user,
    showErrorMessage,
  } = props;

  const clientStoreNamePlural = getClientStoreNameTranslation(storeName, true);
  const [columns] = useState(getUserMappingsColumns(clientStoreNamePlural));

  const [accounts, setAccounts] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getClientAccounts = async () => {
    pageLoading();
    setIsLoading(true);

    try {
      const fetchedAccounts = await userService.getAccountsByClientId(clientId, true);

      const alreadyMappedAccountIds = new Set(alreadyMappedAccounts.map(({ id }) => id));

      // Filter out current user, users that has already been mapped, that already has a catalogId and that has the role of API consumer
      const filteredAccounts = fetchedAccounts.filter(
        ({ id, catalogId, lnkAccountRoleAccountrel }) =>
          !alreadyMappedAccountIds.has(id) &&
          !catalogId &&
          id !== user.id &&
          ![ENUM_ROLES_NAME.API_CONSUMER, ENUM_ROLES_NAME.PUBLIC_API_CONSUMER].includes(
            lnkAccountRoleAccountrel.name,
          ),
      );

      const formattedAccounts = filteredAccounts.map((account) => ({
        ...account,
        name: account.firstName || '---',
        role: get(account, 'lnkAccountRoleAccountrel.name', ''),
      }));

      setAccounts(formattedAccounts);
    } catch {
      showErrorMessage(i18next.t('USERS.DETAILS.PROFILE_LIST_ACCOUNTS_FETCH_FAILURE'));
    } finally {
      setIsLoading(false);
      pageLoaded();
    }
  };
  ENUM_ROLES_NAME;
  useEffect(() => {
    getClientAccounts();
  }, []);

  return (
    <Container>
      <ListView
        columns={columns}
        data={accounts}
        defaultMaxPerPage={20}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        isLoading={isLoading}
        maxPerPageOptions={[20, 50, 100]}
        minActionsInActionsDropdown={1}
        padding={'0px 24px 0px 24px'}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        renderEmptyState={() => <EmptyState />}
        setSelectedItems={setMappingsToBeAdded}
        forceEnableSelection
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

const UserMappingsModalConnected = connect(mapStateToProps, mapDispatchToProps)(UserMappingsModal);

export const getModalConfig = (
  catalogForm,
  alreadyMappedAccounts,
  mappingsToBeAdded,
  setMappingsToBeAdded,
) => ({
  type: 'actionWhite',
  isFullscreen: true,
  title: i18next.t('ADMIN.CATALOGS.ASSOCIATE_USERS_TO_CATALOG'),
  icon: '/images/inpulse/add-black-small.svg',
  customPadding: '24px 0px 0px 0px',
  component: UserMappingsModalConnected,
  data: {
    alreadyMappedAccounts,
    setMappingsToBeAdded,
  },
  actions: [
    {
      ...GENERIC_MODAL_CANCEL_BUTTON(),
      handleClick: () => {
        setMappingsToBeAdded([]);
      },
    },
    {
      ...GENERIC_MODAL_ADD_BUTTON(),
      handleClick: () => {
        const formattedToBeAdded = mappingsToBeAdded.map((mapping) => ({
          ...mapping,
          isRowSelected: false,
        }));

        catalogForm.setValue('accountMappings', [...alreadyMappedAccounts, ...formattedToBeAdded]);
        setMappingsToBeAdded([]);
      },
    },
  ],
});
