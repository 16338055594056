import styled from 'styled-components';

export const CustomModalContainer = styled.div`
  z-index: 1;
  position: absolute;

  display: flex;
  flex-direction: column;
  left: 0;
  top: 35px;

  min-height: 144px;
  min-width: 350px;
`;

// export const IconArrow = styled.img`
//   width: 30px;
//   height: 30px;
//   z-index: 1;
// `;

export const CustomModalBody = styled.div`
  z-index: 2;
  position: relative;

  padding: 20px;

  background: ${(props) => props.theme.customModal?.backgroundColor};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  cursor: auto;
  min-height: 120px;
  min-width: 250px;
`;

export const CloseIcon = styled.img`
  z-index: 3;
  position: absolute;
  right: 20px;
  cursor: pointer;
`;

export default {
  CustomModalContainer,
  // IconArrow,
  CustomModalBody,
  CloseIcon,
};
