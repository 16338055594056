import { connect } from 'react-redux';
import { isEqual, get } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import catalogsService from '@services/catalogs';

import { buildSchema } from '@commons/GenericForm';
import { Button } from '@commons/utils/styledLibraryComponents';
import Footer from '@commons/Footer/Footer';
import NavigationBar from '@commons/NavigationBar';

import { CATALOG_FORM_INPUTS, FIELDS_TO_CHECK } from '../../utils/formInputsConfiguration';
import CatalogInformations from '../Creation/Informations';

import { ButtonsContainer, ContentContainer, PageContainer } from './styledComponents';

const CatalogDetails = (props) => {
  const {
    match: { path, params },
    pageLoading,
    pageLoaded,
    showErrorMessage,
    showSuccessMessage,
    history,
  } = props;

  const catalogForm = useForm({
    defaultValues: {},
    resolver: yupResolver(buildSchema(CATALOG_FORM_INPUTS())),
  });

  const formFields = useWatch({
    control: catalogForm.control,
  });

  const [catalog, setCatalog] = useState({ name: '' });

  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isSaveAlreadyTriggered, setIsSaveAlreadyTriggered] = useState(false);
  const [isFooterDisplayed, setIsFooterDisplayed] = useState(false);

  const [inputs, setInputs] = useState(CATALOG_FORM_INPUTS());

  const getCatalog = async () => {
    pageLoading();

    try {
      const fetchedCatalog = await catalogsService.getCatalogById(params.id);

      setCatalog(fetchedCatalog);
      setInputs(CATALOG_FORM_INPUTS(fetchedCatalog));

      catalogForm.setValue('name', fetchedCatalog.name);
    } catch {
      showErrorMessage(i18next.t('ADMIN.CATALOGS.CATALOG_DETAILS_FETCH_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  useEffect(() => {
    if (!params.id) {
      return;
    }

    getCatalog();
  }, [params.id]);

  useEffect(() => {
    (async () => {
      const fieldsValidation = await catalogForm.trigger(FIELDS_TO_CHECK);
      setIsSaveDisabled(!fieldsValidation);

      const isFormDirty = get(catalogForm, 'formState.isDirty', false);

      const dirtyFields = Object.keys(get(catalogForm, 'formState.dirtyFields', {}));

      const hasAtLeastOneDifferentField = dirtyFields.some(
        (field) => !isEqual(catalog[field], formFields[field]),
      );

      if (isFormDirty && hasAtLeastOneDifferentField) {
        setIsFooterDisplayed(true);
        return;
      }

      setIsFooterDisplayed(false);
    })();
  }, [formFields]);

  const returnToCatalogsList = () => history.goBack();

  const handleUpdateCatalog = async () => {
    pageLoading();

    try {
      await catalogsService.updateCatalog(catalog.id, formFields.name);

      showSuccessMessage(i18next.t('ADMIN.CATALOGS.UPDATE_SUCCESS'));
      /*
        pageLoaded is not in a finally clause because the fnc returnToCatalogsList redirect to another page,
        so we call it here to avoid an infinite loading because it would not be reached if it was in a finally clause
      */
      pageLoaded();
      returnToCatalogsList();
    } catch {
      showErrorMessage(i18next.t('ADMIN.CATALOGS.UPDATE_ERROR'));
      pageLoaded();
    }
  };

  const validateForm = async () => {
    const fieldsValidation = await catalogForm.trigger(FIELDS_TO_CHECK);

    setIsSaveAlreadyTriggered(true);

    if (!fieldsValidation) {
      setIsSaveDisabled(true);
      return;
    }

    handleUpdateCatalog();
  };

  return (
    <>
      <NavigationBar
        bigTopBar={true}
        catalog={catalog}
        enableActionBottomOrder={true}
        path={path}
      />
      <PageContainer>
        <ContentContainer isFooterDisplayed={isFooterDisplayed}>
          <CatalogInformations
            catalogForm={catalogForm}
            inputs={inputs}
            isSaveAlreadyTriggered={isSaveAlreadyTriggered}
          />
        </ContentContainer>
        {isFooterDisplayed && (
          <Footer>
            <ButtonsContainer>
              <Button
                color={'inpulse-outline'}
                handleClick={returnToCatalogsList}
                icon={'/images/inpulse/close-black-small.svg'}
                label={i18next.t('GENERAL.CANCEL')}
              />
              <Button
                color={'inpulse-default'}
                handleClick={validateForm}
                icon={'/images/inpulse/save-white-small.svg'}
                isDisabled={isSaveDisabled}
                label={i18next.t('GENERAL.SAVE')}
              />
            </ButtonsContainer>
          </Footer>
        )}
      </PageContainer>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(null, mapDispatchToProps)(CatalogDetails);
