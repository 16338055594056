import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MainText = styled.div`
  width: 100%;
  text-align: center;

  font: normal 500 12px/16px inter;

  color: #606060;

  margin-top: 16px;
  margin-bottom: 28px;
`;

export const Icon = styled.img`
  width: 105px;
  height: 95px;
`;
