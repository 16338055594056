import _ from 'lodash';

export const buildSchema = (cashier) => ({
  schema: {
    type: 'object',
    properties: {
      name: { type: 'string', minLength: 1 },
      credentials: {
        type: 'object',
        properties: _.mapValues(cashier.credentialsTemplate, (property) => ({
          type: typeof property,
          ...(typeof property === 'string' ? { minLength: 1 } : {}),
        })),
        required: Object.keys(cashier.credentialsTemplate),
        additionalProperties: false,
      },
      config: {
        type: 'object',
        properties: _.mapValues(cashier.configTemplate, (property) => ({
          type: typeof property,
        })),
        required: Object.keys(cashier.configTemplate),
        additionalProperties: false,
      },
    },
    required: ['name', 'config', 'credentials'],
    additionalProperties: false,
  },
});

export default buildSchema;
