import { catalogSupplierProductRights } from '../../../utils/supplierProductCatalogRights';
import { userHasRoleInpulse } from '@commons/utils/roles';

/**
 * Check if user has read-only rights on supplier product
 * It is the case if the user is associated with a master catalog and is checking a supplier product whose supplier is not related to the master catalog.
 * Otherwise, if the client has not local catalogs enabled, the user has the inpulse role, or is creating a new supplier product, the user has write rights.
 *
 * @param {object} user
 * @param {object} context
 *
 * @return {boolean}
 */
export const hasUserCatalogReadOnlyRights = (user, { isCreation, supplierId }) => {
  if (userHasRoleInpulse(user) || !user.catalog) {
    return false;
  }

  const { read, write } = catalogSupplierProductRights(user, { isCreation, supplierId });

  return read && !write;
};
