import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';

import { ListView, Button } from '@commons/utils/styledLibraryComponents';
import EmptyState from '@commons/EmptyState';

import onBoardingService from '@services/backoffice/onBoarding';

import theme from '@theme';

import { getSchemaByPath } from '../../constants/validators';

import {
  Container,
  Header,
  Title,
  CloseButton,
  Icon,
  TitleContainer,
  Content,
  Footer,
} from './styledComponents';

function AddFeatureModal(props) {
  const { dataColumns, loadFeatures, userLanguageCode, clientId, mappedFeatures, showMessage } =
    props.params;

  const { userRights, pageLoading, pageLoaded } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [featuresToDisplay, setFeaturesToDisplay] = useState([]);

  const [markerConfiguration] = useState({
    isHidden: (feature) => {
      const schema = getSchemaByPath(feature.path);

      return _.isEmpty(schema);
    },
    backgroundColor: theme.colors.greys.darkest,
    icon: {
      src: '/images/inpulse/gear-white-small.svg',
    },
  });

  useEffect(() => {
    (async function loadData() {
      setIsLoading(true);
      try {
        const addableFeatures = await onBoardingService.getAllAddableFeatures();

        const mappedFeatureIds = mappedFeatures.map((feature) => feature.id);
        const featuresToDisplay = addableFeatures.filter(
          (feature) => !mappedFeatureIds.includes(feature.id),
        );

        setFeaturesToDisplay(featuresToDisplay);
      } catch (err) {
        showMessage(i18next.t('BACKOFFICE.FEATURES.FETCH_FEATURES_FAILURE'), 'error');
        setFeaturesToDisplay([]);
      }
      setIsLoading(false);
    })();
  }, [userRights]);

  const createFeatureClientMappings = async () => {
    pageLoading();

    const selectedFeatureIds = selectedFeatures.map((feature) => feature.id);
    try {
      await onBoardingService.createFeatureClientMappings(clientId, selectedFeatureIds);

      showMessage(
        selectedFeatureIds.length === 1
          ? i18next.t('BACKOFFICE.FEATURES.CREATE_FEATURE_CLIENT_MAPPING_SUCCESS_SINGULAR')
          : i18next.t('BACKOFFICE.FEATURES.CREATE_FEATURE_CLIENT_MAPPING_SUCCESS_PLURAL'),
        'success',
      );
    } catch (err) {
      showMessage(
        selectedFeatureIds.length === 1
          ? i18next.t('BACKOFFICE.FEATURES.CREATE_FEATURE_CLIENT_MAPPING_FAILURE_SINGULAR')
          : i18next.t('BACKOFFICE.FEATURES.CREATE_FEATURE_CLIENT_MAPPING_FAILURE_PLURAL'),
        'error',
      );
    } finally {
      pageLoaded();

      props.closeModal();

      await loadFeatures();
    }
  };

  return (
    <Container>
      <Header>
        <TitleContainer>
          <Icon alt="add" src="/images/inpulse/add-black-small.svg"></Icon>
          <Title>{i18next.t('BACKOFFICE.FEATURES.ADD_FEATURE_TITLE')}</Title>
        </TitleContainer>
        <CloseButton
          alt="close"
          src={'/images/inpulse/close-black-small.svg'}
          onClick={props.closeModal}
        />
      </Header>
      <Content>
        <ListView
          columns={dataColumns}
          data={featuresToDisplay}
          defaultOrderBy={'name'}
          defaultOrderType={'asc'}
          disableFooter={true}
          forceEnableSelection={true}
          isLoading={isLoading}
          languageCode={userLanguageCode}
          markerConfiguration={markerConfiguration}
          padding={'24px 24px 0px 24px'}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => <EmptyState />}
          setSelectedItems={setSelectedFeatures}
        />
      </Content>
      <Footer>
        <Button
          buttonCustomStyle={{ marginLeft: 24 }}
          color={'inpulse-outline'}
          handleClick={() => props.closeModal()}
          icon="/images/inpulse/close-black-small.svg"
          isDisabled={isLoading}
          label={i18next.t('GENERAL.CANCEL')}
        />
        <Button
          buttonCustomStyle={{ marginLeft: 24 }}
          color={'inpulse-default'}
          handleClick={() => createFeatureClientMappings()}
          icon="/images/inpulse/save-white-small.svg"
          isDisabled={isLoading || !(selectedFeatures.length > 0)}
          label={i18next.t('GENERAL.SAVE')}
        />
      </Footer>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  userRights: state.baseReducer.userRights,
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddFeatureModal);
