import { sortBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';

import {
  makeXLS,
  generateDefaultSheet,
  generateDatesSheetFromStartEndDate,
} from '@commons/utils/makeXLS';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';

import getColumnsTable from './columns';

const getExportFile = ({ data, currency, storeName, selectedMetric }) => {
  const formattedAnalytics = sortArrayOfObjectsAlphabetically(data, 'storeName');

  const columnsToExport = () => {
    const columnPartnerId = {
      id: 2,
      propertyKey: 'partnerId',
      type: 'string',
      name: 'Identifiant partenaire',
    };

    const columnsData = getColumnsTable(selectedMetric.key, storeName);

    columnsData.push(columnPartnerId);

    const columnsDataSortedById = sortBy(columnsData, 'id');

    return columnsDataSortedById;
  };

  const sheets = [
    generateDefaultSheet(
      i18next.t('FEATURE.PRODUCTION.ANALYTICS_REAL_TIME'),
      columnsToExport(),
      formattedAnalytics,
      currency,
    ),
    generateDatesSheetFromStartEndDate(
      moment().startOf('day').format('LLLL'),
      moment().format('LLLL'),
    ),
  ];

  makeXLS(i18next.t('PRODUCTION.REAL_TIME.EXPORT_FILENAME'), sheets);
};

export default getExportFile;
