import styled from 'styled-components';

export const RecoTitle = styled.div`
  color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};
  margin-bottom: ${(props) => (props.theme.fonts ? '24px' : '20px')};
  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
  font: ${(props) => props.theme.fonts?.h3Inter || 'normal 600 14px/14px proximanovaregular'};
`;

export const RecoContent = styled.div`
  display: flex;
  flex-direction: column;
  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
`;

export const RecoContentHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme?.colors?.greys?.light};
`;

export const ItemHeader = styled.div`
  display: flex;
  flex-basis: 110px;
  align-items: center;
  margin-right: 8px;
  height: 44px;
`;

export const ItemText = styled.div`
  font: ${(props) => props.theme?.fonts?.textSmallHeight16};
  color: ${(props) => props.theme?.colors?.greys?.darker};
`;

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

export const RecoContentBody = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  height: 44px;
`;

export const ContentItem = styled.div`
  display: flex;
  flex-basis: 110px;
  margin-right: 8px;
  align-items: center;
  height: 24px;
`;

export const BodyContent = styled.div`
  font: ${(props) => props.theme?.fonts?.textMiddleNormal};
  color: ${(props) => props.theme?.colors?.black};
`;

export default {
  RecoTitle,
  RecoContent,
  ItemHeader,
  ItemText,
  RecoContentHeader,
  TooltipContainer,
  RecoContentBody,
  ContentItem,
  BodyContent,
};
