// EXTERNAL LIBRAIRIES
import { InpulseChart } from 'deepsight-react-components';
import { isEqual } from 'lodash';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { CanvasChart, ChartContainer } from './styledComponents';

import customPlugins from './utils/plugins';

InpulseChart.Chart.register(
  InpulseChart.LineController,
  InpulseChart.LineElement,
  InpulseChart.PointElement,
  InpulseChart.CategoryScale,
  InpulseChart.LinearScale,
  InpulseChart.TimeScale,
  InpulseChart.Tooltip,
  InpulseChart.Filler,
);

const TimePropertiesLineGraph = (props) => {
  const {
    x,
    y,
    granularity,
    chartName,
    currency,
    tooltipHandler,
    legendConfig,
    customGraphStyle,
    config,
  } = props;

  useEffect(() => {
    if (!chartName) {
      return;
    }

    const ctx = document.getElementById(chartName).getContext('2d');

    const datasets = config.getDatasets(y);

    const customChart = new InpulseChart.Chart(ctx, {
      type: 'line',
      data: {
        datasets,
        labels: x.data,
      },
      options: {
        maintainAspectRatio: false,
        interaction: config.interactionOnHover(),
        elements: { point: config.pointStyle() },
        scales: config.scales(granularity, currency),
        plugins: {
          tooltip: config.tooltip(tooltipHandler),
          // This is used to send args to plugin,
          // cf: https://www.chartjs.org/docs/3.9.1/developers/plugins.html#plugin-defaults
          canvasBackgroundColor: { legendConfig },
        },
      },
      plugins: [customPlugins.hoverLine, customPlugins.canvasBackgroundColor],
    });

    return () => {
      customChart.destroy();
    };
  }, [chartName, y]);

  return (
    <ChartContainer customGraphStyle={customGraphStyle}>
      <CanvasChart id={chartName} />
    </ChartContainer>
  );
};

TimePropertiesLineGraph.propTypes = {
  chartName: PropTypes.string,
  x: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  y: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      borderColor: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.number).isRequired,
    }),
  ).isRequired,
  config: PropTypes.shape({
    interactionOnHover: PropTypes.func.isRequired,
    pointStyle: PropTypes.func.isRequired,
    scales: PropTypes.func.isRequired,
    tooltip: PropTypes.func.isRequired,
    getDatasets: PropTypes.func.isRequired,
  }),
  granularity: PropTypes.oneOf(['day', 'week', 'month', 'quarter', 'year']),
  tooltipHandler: PropTypes.func,
  legendConfig: PropTypes.shape({
    display: PropTypes.bool,
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
  }),
};

TimePropertiesLineGraph.defaultProps = {
  granularity: 'day',
  chartName: 'default-chart-name',
  tooltipHandler: null,
  legendConfig: null,
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

/**
 * If data are all the same, do not re-render
 * @param prevProps
 * @param nextProps
 * @returns {boolean}
 */
const arePropsEqual = (prevProps, nextProps) => {
  const previousX = prevProps.x.data;
  const previousY = prevProps.y;

  const nextX = nextProps.x.data;
  const nextY = nextProps.y;

  if (prevProps.screenWidth !== nextProps.screenWidth) {
    return false;
  }

  if (previousX.length !== nextX.length) {
    return false;
  }

  if (!isEqual(previousY, nextY)) {
    return false;
  }

  return true;
};

export default connect(mapStateToProps)(React.memo(TimePropertiesLineGraph, arePropsEqual));
