import { cloneDeep, get, keyBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getUserTimezone } from '@commons/utils/date';

import { lossService } from '@services/loss';

import { exportLossData } from '../components/LossListView/export';
import { LOSS_TYPE } from '../components/LossListView/constants';

export const getDatesWithLossesForMonth = async (
  currentFocusedMonth,
  currentSelectedStore,
  lossType,
) => {
  const startDate = moment(currentFocusedMonth)
    .startOf('month')
    .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
  const endDate = moment(currentFocusedMonth)
    .endOf('month')
    .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

  const dates = await lossService.getDatesWithLossesForStores(
    lossType,
    [currentSelectedStore.id],
    startDate,
    endDate,
  );

  const datesAsMoments = dates.map((date) => ({
    timestamp: moment.tz(date.timestamp, currentSelectedStore.timezone),
    createdAt: moment.tz(date.createdAt, getUserTimezone()),
  }));

  const oldDates = datesAsMoments.filter((date) =>
    date.createdAt.isBefore(moment().subtract(2, 'days')),
  );

  const recentDates = datesAsMoments.filter((date) =>
    date.createdAt.isAfter(moment().subtract(2, 'days')),
  );

  return {
    oldDatesWithLosses: oldDates.map((date) => date.timestamp),
    recentDatesWithLosses: recentDates.map((date) => date.timestamp),
  };
};

/* Get the oldest createdAt of the current losses because we want to base the ability to edit a loss
  on its oldest createdAt and not its most recent so the user can't edit a loss whenever he wants */
const getOldestCreatedAt = (aggregatedLoss) => {
  const oldestCreatedAt = aggregatedLoss.losses.reduce((result, loss) => {
    if (!result || moment(loss.createdAt).isBefore(result)) {
      result = moment(loss.createdAt);
    }

    return result;
  }, null);

  return oldestCreatedAt;
};

export const formatPayloadIntoLossData = (
  payload,
  lossType,
  stores,
  lossCategoriesById,
  { channelsById },
) => {
  const storesById = keyBy(stores, 'id');

  switch (lossType) {
    case LOSS_TYPE.PRODUCT: {
      const formattedData = payload.map((aggregatedLoss) => {
        const product = aggregatedLoss.product;

        return {
          ...aggregatedLoss,
          id: product.id,
          name: product.name,
          channel: get(channelsById[aggregatedLoss.channelId], 'name'),
          losses: aggregatedLoss.quantity,
          oldestCreatedAt: getOldestCreatedAt(aggregatedLoss),
          category: i18next.t(
            get(lossCategoriesById[aggregatedLoss.lossCategoryId], 'translationKey'),
          ),
          total: aggregatedLoss.quantity * aggregatedLoss.cost,
          storeName: storesById[aggregatedLoss.storeId].name, // used during export
          lossIds: aggregatedLoss.losses.map((loss) => loss.id), // used during deletion
        };
      });
      return formattedData;
    }

    case LOSS_TYPE.SUPPLIER_PRODUCT: {
      const formattedData = payload.map((aggregatedLoss) => {
        const supplierProduct = aggregatedLoss.supplierProduct;
        const packaging = supplierProduct.packagings.find(
          ({ id }) => id === aggregatedLoss.supplierProductPackagingId,
        );

        return {
          ...aggregatedLoss,
          id: supplierProduct.id,
          name: supplierProduct.name,
          packaging: packaging.name,
          losses: aggregatedLoss.quantity,
          oldestCreatedAt: getOldestCreatedAt(aggregatedLoss),
          category: i18next.t(
            get(lossCategoriesById[aggregatedLoss.lossCategoryId], 'translationKey'),
          ),
          total: aggregatedLoss.quantity * aggregatedLoss.price,
          storeName: storesById[aggregatedLoss.storeId].name, // used during export
          lossIds: aggregatedLoss.losses.map((loss) => loss.id), // used during deletion
        };
      });

      return formattedData;
    }

    case LOSS_TYPE.RECIPE: {
      const formattedData = payload.map((aggregatedLoss) => {
        const recipe = aggregatedLoss.recipe;

        return {
          ...aggregatedLoss,
          id: recipe.id,
          name: recipe.name,
          losses: aggregatedLoss.quantity,
          oldestCreatedAt: getOldestCreatedAt(aggregatedLoss),
          category: i18next.t(
            get(lossCategoriesById[aggregatedLoss.lossCategoryId], 'translationKey'),
          ),
          total: aggregatedLoss.quantity * aggregatedLoss.cost,
          storeName: storesById[aggregatedLoss.storeId].name, // used during export
          lossIds: aggregatedLoss.losses.map((loss) => loss.id), // used during deletion
        };
      });

      return formattedData;
    }

    default:
      return;
  }
};

export const handleLossesExport = async (
  stores,
  dateRange,
  lossType,
  lossCategoriesById,
  currency,
  clientStoreName,
  { channelsById, hasMultipleChannels, defaultChannelId },
) => {
  const startDate = moment(dateRange.startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
  const endDate = moment(dateRange.endDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

  const storesIds = stores.map((store) => store.id);

  const lossesPayload = await lossService.getLossesList(lossType, storesIds, startDate, endDate);

  let lossesPayloadClone = cloneDeep(lossesPayload);

  if (!hasMultipleChannels) {
    lossesPayloadClone = lossesPayload.map((loss) => ({
      ...loss,
      channelId: defaultChannelId,
    }));
  }

  const data = formatPayloadIntoLossData(lossesPayloadClone, lossType, stores, lossCategoriesById, {
    channelsById,
  });

  const sortedData = data
    .sort(
      (aggLossA, aggLossB) =>
        moment(aggLossA.timestamp).format(DATE_DISPLAY_FORMATS.PACKED_YEAR_MONTH_DAY) -
        moment(aggLossB.timestamp).format(DATE_DISPLAY_FORMATS.PACKED_YEAR_MONTH_DAY),
    )
    .sort((aggLossA, aggLossB) => aggLossA.storeName.localeCompare(aggLossB.storeName));

  const storeTranslations = {
    plural: getClientStoreNameTranslation(clientStoreName, true),
    single: getClientStoreNameTranslation(clientStoreName, false),
  };

  exportLossData(lossType, currency, sortedData, { stores, dateRange }, storeTranslations, {
    lossCategoriesById,
    channelsById,
  });
};
