import { APIcaller } from './APIcaller';

const createUsersNotificationsByBatch = async (type, userIds) =>
  APIcaller.apiPost(`/notifications/${type}/accounts`, {
    accountIds: userIds,
  });

const deleteUsersNotificationsByBatch = async (type, userIds) =>
  APIcaller.apiDelete(`/notifications/${type}/accounts`, {
    accountIds: userIds,
  });

export default { createUsersNotificationsByBatch, deleteUsersNotificationsByBatch };
