import i18next from 'i18next';
import parse from 'html-react-parser';

import { GENERIC_MODAL_CLOSE_BUTTON } from '../../../commons/Modals/GenericModal/genericModalActions';
import { getConfirmationModal } from '../../../commons/Modals/ConfirmationModal';

export const getText = ({ errorDetail, customError }, { reference, storeName, supplierName }) => {
  switch (errorDetail) {
    case 'send_edi_failed_tgt': {
      const regexMsgOrderAlreadySent =
        /(La référence commande)(.*)(déjà transmise)(.*)(pour ce client et cette date livraison)/;

      if (regexMsgOrderAlreadySent.test(customError)) {
        return i18next.t('ORDERS.ORDERS.MODAL_ERROR_EDI_ALREADY_SENT_ORDER_TRANSGOURMET_CONTENT', {
          reference,
        });
      }

      return null;
    }
    case 'send_edi_send_failed_netsuite': {
      const errorPath = customError['o:errorPath'];
      const errorCode = customError['o:errorCode'];

      // case where externalId (Store.partnerId) does not exist in netsuite system
      // this requires an action from the client towards the supplier
      if (
        errorPath === 'custbody_kim_code_site_so.externalId' &&
        errorCode === 'NONEXISTENT_EXTERNAL_ID'
      ) {
        return i18next.t('ORDERS.ORDERS.MODAL_ERROR_EDI_MISSING_EXTERNAL_ID_NETSUITE_CONTENT', {
          storeName,
        });
      }

      return null;
    }
    case 'send_edi_missing_customer_code': {
      return i18next.t('ORDERS.ORDERS.MODAL_ERROR_EDI_MISSING_CUSTOMER_CODE_CONTENT', {
        storeName,
        supplierName,
      });
    }
    case 'send_edi_missing_warehouse_code': {
      return i18next.t('ORDERS.ORDERS.MODAL_ERROR_EDI_MISSING_WAREHOUSE_CODE_CONTENT', {
        storeName,
        supplierName,
      });
    }
    case 'send_edi_supplier_product_settings_failed': {
      return i18next.t('ORDERS.ORDERS.MODAL_ERROR_EDI_SUPPLIER_PRODUCT_SETTINGS_FAILED_CONTENT');
    }
    case 'send_edi_missing_supplier_code': {
      return i18next.t('ORDERS.ORDERS.MODAL_ERROR_EDI_MISSING_SUPPLIER_CODE_CONTENT', {
        supplierName,
      });
    }
    case 'send_edi_missing_partner_id': {
      return i18next.t('ORDERS.ORDERS.MODAL_ERROR_EDI_MISSING_PARTNER_ID_CONTENT', { storeName });
    }
    default:
      return null;
  }
};

export const getEdiSupplierProductSettingsErrorModal = (errorInfos, props) => {
  const params = getConfirmationModal({
    title: i18next.t('ORDERS.ORDERS.MODAL_ERROR_EDI_SETTINGS_FAILED_TITLE'),
    icon: '/images/inpulse/error-white-small.svg',
    type: 'error',
    content: parse(getText(errorInfos, props)),
    actions: [GENERIC_MODAL_CLOSE_BUTTON()],
  });

  return { ...params, disableCloseOutside: true };
};

export default {
  getText,
  getEdiSupplierProductSettingsErrorModal,
};
