import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 160px;

  background-color: ${(props) => props.theme.colors.greys.lightest};
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 72px;

  padding: 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.h1Inter};
`;

export const CloseButton = styled.img`
  width: 14px;
  height: 14px;

  cursor: pointer;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  height: 88px;

  padding: 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: inset 0px 2px 0px ${(props) => props.theme.colors.greys.lighter};
`;

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 24px;
  }
`;
