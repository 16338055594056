import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;

  flex: 1;

  background-color: ${(props) => props.theme.colors.greys.lightest};
  border-radius: 16px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
`;

export const ContainerHeader = styled.div`
  width: 100%;
`;

export const ContainerContent = styled.div`
  input {
    max-width: 50%;
  }

  & > form > div {
    margin-bottom: 24px;
  }
`;

export const TitleHeader = styled.div`
  color: ${(props) => props.theme.colors.greys.darkest};
  font: normal 700 16px/24px inter;

  margin-bottom: 10px;
`;

export const SubtitleHeader = styled.div`
  color: ${(props) => props.theme.colors.greys.dark};
  font: ${(props) => props.theme.fonts.textBig};
  margin: 12px 0px 12px 0px;
`;
