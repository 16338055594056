import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 16px;
`;

export const PillContainer = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  height: 40px;
  border-radius: 20px;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.blacks.ipBlack1 : theme.colors.white};
  padding: 16px 12px;
  cursor: pointer;
  border: ${({ theme, isSelected }) =>
    !isSelected
      ? `1px solid  ${theme.colors.greys.lighty}`
      : `1px solid  ${theme.colors.greys.darkest}`};
`;

export const CountContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.white : theme.colors.greys.lighter};
`;

export const HighCountContainer = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
