import i18next from 'i18next';
import moment from 'moment';

import getProductionRealTime from '@services/getProductionRealTime';

const setRowUniqId = (dataStore) => {
  dataStore.map((data, index) => {
    data.id = index;

    return data;
  });

  return dataStore;
};

const getAnalyticsData = async (storeIds, productIds, showErrorMessage) => {
  const currentDate = moment();

  try {
    const { totalData, data } = await getProductionRealTime(
      storeIds,
      currentDate,
      showErrorMessage,
      productIds,
    );

    const formattedData = setRowUniqId(data);

    return [
      { ...totalData, id: 'total', storeName: i18next.t('GENERAL.ANALYTICS_LABEL_TOTAL') },
    ].concat(formattedData);
  } catch (err) {
    if (showErrorMessage) {
      showErrorMessage('Impossible de récupérer les données');
    }

    return [];
  }
};

export default getAnalyticsData;
