import i18next from 'i18next';
import React from 'react';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import Text from '@commons/Text';

import SupplierProfileModalAssociations from '@admin/suppliers/suppliers/SupplierProfileModal/SupplierProfileModalAssociations';

const ListViewColumns = [
  {
    id: 'name',
    propertyKey: 'name',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_NAME'),
    render: (it) => <Text highlighted>{it}</Text>,
  },
  {
    id: 'country',
    propertyKey: 'country',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_COUNTRY'),
    filterType: 'string',
    render: (item) => <Text>{item}</Text>,
  },
  {
    id: 'city',
    propertyKey: 'city',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_CITY'),
    filterType: 'string',
    render: (item) => <Text>{item}</Text>,
  },
];

const AssociateSupplierProfileStoresModal = (props) => {
  const { clientStoreName, availableStoreAssociations, handleSelectStoreAssociations } = props;

  return (
    <SupplierProfileModalAssociations
      columns={ListViewColumns}
      data={availableStoreAssociations}
      defaultOrderBy={'name'}
      defaultOrderType={'asc'}
      emptyStateText={i18next.t('ADMIN.SUPPLIERS.ASSOCIATION_MODAL_ALL_EMPTY_STATE', {
        objectName: getClientStoreNameTranslation(clientStoreName, true).toLowerCase(),
      })}
      forceEnableSelection={true}
      setSelectedItems={(items) => handleSelectStoreAssociations(items)}
    />
  );
};

export default AssociateSupplierProfileStoresModal;
