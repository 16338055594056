import i18next from 'i18next';
import React, { useState } from 'react';

import { Checkbox } from '@commons/utils/styledLibraryComponents';
import Text from '@commons/Text';

import { Action, ActionsContainer, Button, Container, Icon } from './styledComponents';

const OrderParams = (props) => {
  const {
    multipleOrder,
    setMultipleOrder,
    xlsWithOrder,
    setXlsWithOrder,
    canIncludeExcelInOrderMail,
  } = props;

  const [shouldDisplayActions, setShouldDisplayActions] = useState(false);

  /**
   * Handle callback to trigger the display of actions dropdown
   */
  const triggerDropdown = () => {
    setShouldDisplayActions(!shouldDisplayActions);
  };

  /**
   * Handle render of dropdown actions
   */
  const renderDropdownActions = () => {
    if (!shouldDisplayActions) {
      return <></>;
    }

    return (
      <ActionsContainer canIncludeExcelInOrderMail={canIncludeExcelInOrderMail}>
        <Action onClick={() => setMultipleOrder(!multipleOrder)}>
          <Checkbox isChecked={multipleOrder} shape={'square'} noSize />
          <Text>
            {i18next.t(
              'ADMIN.SUPPLIERS.SUPPLIER_DETAIL_ORDER_TOGGLE_MULTIPLE_ORDER_SAME_DATE_TEXT',
            )}
          </Text>
        </Action>
        {canIncludeExcelInOrderMail && (
          <Action onClick={() => setXlsWithOrder(!xlsWithOrder)}>
            <Checkbox isChecked={xlsWithOrder} shape={'square'} noSize />
            <Text>
              {i18next.t('ADMIN.SUPPLIERS.SUPPLIER_DETAIL_ORDER_TOGGLE_XLS_WITH_ORDER_MAIL')}
            </Text>
          </Action>
        )}
      </ActionsContainer>
    );
  };

  return (
    <Container>
      <Button onClick={triggerDropdown}>
        <Icon alt="order-params-button" src={'/images/inpulse/settings.svg'} />
      </Button>
      {renderDropdownActions()}
    </Container>
  );
};

export default OrderParams;
