import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { openSmallModal } from '@actions/modal';

import { Button } from '@commons/utils/styledLibraryComponents';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import EntityCostCalculModal from '@backoffice/components/EntityCostCalculModal';

import { Container, WhiteCard, Title, SubTitle, Content, Export } from './styledComponents';

export async function getIngredientCost(props) {
  const { user } = props;
  props.openModalExportInfo({
    component: EntityCostCalculModal,
    title: i18next.t('BACKOFFICE.PRODUCTS.COST_CALCUL_IN_PROGRESS'),
    clientId: _.get(user, 'clientId'),
  });
}

export const BackOfficeIngredients = (props) => {
  const { match } = props;

  const path = _.get(match, 'path');

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <Content>
        <WhiteCard>
          <Title>{i18next.t('BACKOFFICE.PRODUCTS.INGREDIENTS_TITLE_COST_INGREDIENTS')}</Title>
          <SubTitle>
            {i18next.t('BACKOFFICE.PRODUCTS.INGREDIENTS_SUBTITLE_COST_INGREDIENTS')}
          </SubTitle>
          <Export>
            <Button
              color={'blue'}
              fontSize={14}
              formatText={false}
              handleClick={() => getIngredientCost(props)}
              height={36}
              icon={'/images/inpulse/sync-white-small.svg'}
              label={i18next.t('BACKOFFICE.PRODUCTS.INGREDIENTS_BUTTON_INGREDIENTS_COST')}
            />
          </Export>
        </WhiteCard>
      </Content>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeIngredients);
