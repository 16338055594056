import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { ENUM_FONTS } from '@commons/Text';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import DisplayNumber from '@commons/DisplayNumber';

import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import { activityAnalytics as activityAnalyticsService } from '@services/activityAnalytics';

const renderPercentage = (number) => {
  if (!number) return <div className="activity-summary-ca-middle-body-right">-</div>;
  if (number >= 0) {
    return (
      <div className="activity-summary-ca-middle-body-right activity-summary-ca-percent-positive">{`+ ${number} %`}</div>
    );
  } else {
    return (
      <div className="activity-summary-ca-middle-body-right activity-summary-ca-percent-negative">{`- ${Math.abs(
        number,
      )} %`}</div>
    );
  }
};

const SalesComparisonComponent = (props) => {
  const {
    client: { storeName },
  } = props;

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [salesComparison, setSalesComparison] = useState();
  const [stores, setStores] = useState();

  const handleError = () => {
    setIsLoading(false);
    setSalesComparison();
  };

  const formatSaleComparison = (salesComparison) => {
    if (salesComparison) {
      salesComparison = {
        storeCount: salesComparison.storeCount,
        lastWeekTurnover: Math.round(salesComparison.lastWeekTurnover),
        growth: Math.round(salesComparison.growth * 10000) / 100,
        currentWeekProduction: Math.round(salesComparison.currentWeekProduction),
      };
      setIsLoading(false);
      setSalesComparison(salesComparison);
    } else {
      handleError();
    }
  };

  useEffect(() => {
    const getSalesComparison = async () => {
      try {
        const res = await activityAnalyticsService.getSalesComparison(
          props.stores.map((store) => store.id),
          moment().subtract('1', 'weeks').startOf('week').format('YYYY-MM-DD'),
          moment().subtract('1', 'weeks').endOf('week').format('YYYY-MM-DD'),
        );
        formatSaleComparison(res);
      } catch (e) {
        console.error(e);
        handleError();
      }
    };
    if (props.stores?.length > 0 && stores !== props.stores) {
      setStores(props.stores);
      setIsLoading(true);
      getSalesComparison();
    }
  }, [props.stores]);

  return (
    <div className={props.className}>
      <div className="activity-summary-ca-left">
        <div className="activity-summary-ca-left-header">{t('HOME.LABEL_CONSTANT_SCOPE')}</div>
        <div className="activity-summary-ca-left-body">
          {isLoading ? (
            <DeepsightComponentLoader />
          ) : salesComparison?.storeCount && salesComparison?.storeCount !== null ? (
            `${salesComparison.storeCount} ${getClientStoreNameTranslation(storeName, true)}`
          ) : (
            '-'
          )}
        </div>
        {salesComparison ? (
          <div className="activity-summary-ca-left-sub-body">
            {t('HOME.LABEL_ACTIVE_IN', {
              previousYear: props.currentYear - 1,
              currentYear: props.currentYear,
            })}
          </div>
        ) : undefined}
      </div>
      <div className="spacer"></div>
      <div className="activity-summary-ca-middle">
        <div className="activity-summary-ca-middle-header">{t('HOME.LABEL_PAST_WEEK')}</div>
        <div className="activity-summary-ca-middle-body-container">
          <div className="activity-summary-ca-middle-body-left">
            {isLoading ? (
              <DeepsightComponentLoader />
            ) : salesComparison?.lastWeekTurnover && salesComparison?.lastWeekTurnover !== null ? (
              <DisplayNumber
                displayCurrencyCode={true}
                font={ENUM_FONTS.KPI_BIG}
                number={salesComparison.lastWeekTurnover}
                withoutDecimals={true}
              />
            ) : (
              '-'
            )}
          </div>
          {salesComparison ? renderPercentage(salesComparison?.growth) : undefined}
        </div>
        {salesComparison ? (
          <div className="activity-summary-ca-middle-sub-body-container">
            <div className="activity-summary-ca-middle-sub-body-left">
              {t('HOME.LABEL_TURNOVER')}
            </div>
            <div className="activity-summary-ca-middle-sub-body-right">
              {t('HOME.LABEL_VS_YEAR', {
                year: props.currentYear - 1,
              })}
            </div>
          </div>
        ) : undefined}
      </div>
      <div className="spacer"></div>
      <div className="activity-summary-ca-right">
        <div className="activity-summary-ca-right-header">{t('HOME.LABEL_CURRENT_YEAR')}</div>
        <div className="activity-summary-ca-right-body">
          {isLoading ? (
            <DeepsightComponentLoader />
          ) : salesComparison?.currentWeekProduction &&
            salesComparison?.currentWeekProduction !== null ? (
            <DisplayNumber
              displayCurrencyCode={true}
              font={ENUM_FONTS.KPI_BIG}
              number={salesComparison.currentWeekProduction}
              withoutDecimals={true}
            />
          ) : (
            '-'
          )}
        </div>
        {salesComparison ? (
          <div className="activity-summary-ca-right-sub-body-left">{t('HOME.LABEL_FORECAST')}</div>
        ) : undefined}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  stores: getSalesPointStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
});

const Connected = connect(mapStateToProps, null)(SalesComparisonComponent);

export default styled(Connected)`
  background: ${(props) => props.theme.colors?.greys.lightest};
  border-radius: ${(props) => props.theme.borders?.radius?.weak};
  margin-right: 30px;
  width: 47%;
  height: 100%;
  display: flex;
  min-width: 255px;
  align-items: center;

  .spacer {
    border-right: ${(props) => `1px solid ${props.theme.colors.greys.light}`};
    border-width: thin;
    height: 70%;
  }

  .activity-summary-ca-left,
  .activity-summary-ca-middle,
  .activity-summary-ca-right {
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
  }

  .activity-summary-ca-left-header,
  .activity-summary-ca-middle-header,
  .activity-summary-ca-right-header {
    font: ${(props) => props.theme.fonts?.h4};
    text-align: center;
    width: 100%;
  }

  .activity-summary-ca-left-body {
    font: ${(props) => props.theme.fonts?.kpiBig};
    margin-top: 0.5em;
    text-align: center;
  }

  .activity-summary-ca-left-sub-body {
    color: ${(props) => props.theme.colors?.greys.dark};
    font: ${(props) => props.theme.fonts?.textSmall};
    text-align: center;
  }

  /***** MIDDLE BLOCK *****/

  .activity-summary-ca-middle-body-container {
    margin-top: 0.7em;
  }
  .activity-summary-ca-middle-body-container,
  .activity-summary-ca-middle-sub-body-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }

  .activity-summary-ca-middle-body-left {
    font: ${(props) => props.theme.fonts?.kpiBig};
  }

  .activity-summary-ca-middle-body-right {
    font: ${(props) => props.theme.fonts?.kpiBig};
  }

  .activity-summary-ca-middle-sub-body-left {
    color: ${(props) => props.theme.colors?.greys.dark};
    font: ${(props) => props.theme.fonts?.textSmall};
  }

  .activity-summary-ca-middle-sub-body-right {
    color: ${(props) => props.theme.colors?.greys.dark};
    font: ${(props) => props.theme.fonts?.textSmall};
  }

  /***** RIGHT BLOCK *****/

  .activity-summary-ca-right-body {
    text-align: center;
    font: ${(props) => props.theme.fonts?.kpiBig};
    margin-top: 0.5em;
    color: ${(props) => props.theme.colors?.greys.darkest};
    width: 100%;
  }

  .activity-summary-ca-right-sub-body-left {
    text-align: center;
    color: ${(props) => props.theme.colors?.greys.dark};
    font: ${(props) => props.theme.fonts?.textSmall};
  }

  .activity-summary-ca-right-sub-body-right {
    color: ${(props) => props.theme.colors?.greys.dark};
    font: ${(props) => props.theme.fonts?.textSmall};
  }

  .activity-summary-ca-percent-negative {
    font: ${(props) => props.theme.fonts?.textSmall};
    color: ${(props) => props.theme.colors?.red};
  }

  .activity-summary-ca-percent-positive {
    font: ${(props) => props.theme.fonts?.textSmall};
    color: ${(props) => props.theme.colors?.green};
  }
`;

export const DeepsightSalesComparison = styled(Connected)`
  background: white;
  box-shadow: 0 2px 6px 0 rgba(168, 177, 196, 0.4);
  border-radius: 10px;
  margin-left: 3%;
  width: 47%;
  height: 100px;
  display: flex;
  min-width: 255px;
  align-items: center;
  .spacer {
    border-right: solid lightgray;
    border-width: thin;
  }

  .activity-summary-ca-left,
  .activity-summary-ca-middle,
  .activity-summary-ca-right {
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
  }

  .activity-summary-ca-left-header,
  .activity-summary-ca-middle-header,
  .activity-summary-ca-right-header {
    text-align: center;
    font-size: 15px;
    width: 100%;
  }

  .activity-summary-ca-left-body {
    font-size: 20px;
    margin-top: 0.5em;
    text-align: center;
  }

  .activity-summary-ca-left-sub-body {
    color: #838d9f;
    font-size: 12px;
    text-align: center;
  }

  /***** MIDDLE BLOCK *****/

  .activity-summary-ca-middle-body-container {
    margin-top: 0.7em;
  }
  .activity-summary-ca-middle-body-container,
  .activity-summary-ca-middle-sub-body-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 0.5em;
  }

  .activity-summary-ca-middle-body-left {
    font-size: 20px;
  }

  .activity-summary-ca-middle-sub-body-left {
    font-size: 12px;
    color: #838d9f;
  }

  .activity-summary-ca-middle-sub-body-right {
    font-size: 12px;
    color: #838d9f;
  }

  /***** RIGHT BLOCK *****/

  .activity-summary-ca-right-body {
    width: 100%;
    text-align: center;
    font-size: 20px;
    margin-top: 0.5em;
    color: #2176fa;
  }

  .activity-summary-ca-right-body-right {
    width: 40%;
    margin-top: 0.5em;
  }

  .activity-summary-ca-right-sub-body-left {
    text-align: center;
    font-size: 12px;
    color: #838d9f;
  }

  .activity-summary-ca-right-sub-body-right {
    width: 40%;
    font-size: 12px;
    color: #838d9f;
  }

  .activity-summary-ca-percent-negative {
    color: #ef252a;
  }

  .activity-summary-ca-percent-positive {
    color: #44d7b6;
  }
`;
