import React, { Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  LoadingContainer,
  LoadingRow,
  LoadingItemContainer,
} from './styledComponents';
import { LoadingProps } from '../../interface';
import ItemDisplay from '../ItemDisplay/ItemDisplay';
import ActionButton from '../ActionButton/ActionButton';

const EMPTY_LOADING_ROW = 8;

const LoadingState = (props: LoadingProps): JSX.Element => {
  const { headers } = props;
  return (
    <>
      {_.times(EMPTY_LOADING_ROW, (index) => (
        <LoadingContainer key={`loading-${index}`} opacity={1 - index / 10}>
          <LoadingRow>
            {headers.map((header, rowIndex) => {
              const key = `row-${index}-${rowIndex}`;
              const isAction = header.propertyKey === 'action';

              return (
                <Fragment key={key}>
                  {!isAction && (
                    <LoadingItemContainer
                      large={header.large}
                      minWidth={header.minWidth}
                      nbColumn={headers.length}
                      narrow={header.narrow}
                    >
                      <ItemDisplay
                        value=""
                        bold={header.bold}
                        large={header.large}
                        textTransform={header.textTransform}
                        nbColumn={headers.length}
                        isLoading
                      />
                    </LoadingItemContainer>
                  )}

                  {isAction && <ActionButton />}
                </Fragment>
              );
            })}
          </LoadingRow>
        </LoadingContainer>
      ))}
    </>
  );
};

LoadingState.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      propertyKey: PropTypes.string.isRequired,
      isNumber: PropTypes.bool,
      unit: PropTypes.string,
      tooltipDisplay: PropTypes.string,
      large: PropTypes.bool,
      narrow: PropTypes.bool,
      minWidth: PropTypes.number,
      bold: PropTypes.bool,
      textTransform: PropTypes.string,
      textDecorationChild: PropTypes.string,
      hrefPrefix: PropTypes.string,
      hrefSuffix: PropTypes.string,
      render: PropTypes.func,
      graphConfiguration: PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
      }),
    })
  ).isRequired,
};

LoadingState.defaultProps = {};

export default LoadingState;
