import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { Props } from './interfaces';
import { Container } from './styledComponents';
import { getTheme } from '../utils/theme';

const HoverTooltip = ({
  children,
  renderContent,
  theme,
  place,
  isTooltipDisplayed,
}: Props): JSX.Element => {
  const random = Math.random().toString();

  const updatedTheme = getTheme(theme, 'tooltip');

  return (
    <Container>
      <div
        style={{ display: 'contents', height: 'inherit', width: 'inherit' }}
        data-for={random}
        data-tip
      >
        {children}
      </div>

      {isTooltipDisplayed && (
        <ReactTooltip
          id={random}
          className="custom-tooltip"
          textColor={updatedTheme.textColor}
          arrowColor="transparent"
          backgroundColor="transparent"
          place={place}
        >
          {renderContent()}
        </ReactTooltip>
      )}
    </Container>
  );
};

HoverTooltip.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.objectOf(PropTypes.any),
  renderContent: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  place: PropTypes.string,
  isTooltipDisplayed: PropTypes.bool,
};

HoverTooltip.defaultProps = {
  theme: null,
  place: 'bottom',
  isTooltipDisplayed: true,
};

export default HoverTooltip;
