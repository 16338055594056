import React from 'react';
import PropTypes from 'prop-types';

import { Props } from './interfaces';

import Tooltip from '../../../Tooltip';

import { Item, DisplayNameContainer } from '../../styledComponents';

const ListViewItem = (props: Props): JSX.Element => {
  const { render, item, value, displayBigger } = props;

  if (render) {
    return <Item>{render(value, item) as string}</Item>;
  }

  return displayBigger ? (
    <DisplayNameContainer>
      <Tooltip
        displayBigger
        renderTooltipTrigger={() => (
          <span
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {value}
          </span>
        )}
        text={value}
      />
    </DisplayNameContainer>
  ) : (
    <div>{value}</div>
  );
};

ListViewItem.propTypes = {
  render: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  displayBigger: PropTypes.bool,
};

ListViewItem.defaultProps = {
  render: null,
  item: null,
  value: null,
  displayBigger: false,
};

export default ListViewItem;
