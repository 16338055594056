import i18next from 'i18next';
import React from 'react';

import { InpulseDot, ModalContainer, ModalBody } from './styledComponents';

const DatePickerModalInformation = () => (
  <ModalContainer>
    <InpulseDot />
    <ModalBody>{i18next.t('ORDERS.BY_CATEGORY.CATEGORIES_DATEPICKER_INFO')}</ModalBody>
  </ModalContainer>
);

export default DatePickerModalInformation;
