import { useState } from 'react';
import moment from 'moment-timezone';
import MomentPropTypes from 'react-moment-proptypes';

/**
 * Custom hook to call whenever a PeriodDatePicker is used.
 *
 * Usage example in: OrderAnalyticsBySupplierProduct
 *
 * @param {momentObj} initialStartDate - initial start date of the period
 * @param {momentObj} initialEndDate - initial end date of the period
 * @param {String} timezone - timezone for these dates
 *
 * @returns An object containing the date & focused state/setters
 */
export const usePeriodDatePickerState = (initialStartDate, initialEndDate, timezone) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [focusedDateInput, setFocusedDateInput] = useState(null);

  const handleSelectedDates = (dates, isReset = false) => {
    if (isReset) {
      setStartDate(dates.startDate);
      setEndDate(dates.endDate);
      return;
    }

    if (!moment.tz(dates.startDate, timezone).isSame(startDate, 'day')) {
      setEndDate(null);
      setStartDate(dates.startDate);
    }

    if (!moment.tz(dates.endDate, timezone).isSame(endDate, 'day')) {
      setEndDate(dates.endDate);
    }
  };

  return {
    startDate,
    endDate,
    handleSelectedDates,
    focusedDateInput,
    setFocusedDateInput,
    setStartDate,
    setEndDate,
  };
};

usePeriodDatePickerState.propTypes = {
  initialStartDate: MomentPropTypes.momentObj.isRequired,
  initialEndDate: MomentPropTypes.momentObj.isRequired,
};
