import i18next from 'i18next';

export const getColumns = () => [
  {
    name: i18next.t('GENERAL.STORE'),
    type: 'string',
    propertyKey: 'storeName',
    filterType: 'string',
  },
  {
    name: i18next.t('GENERAL.CITY'),
    type: 'string',
    propertyKey: 'storeCity',
    filterType: 'string',
  },
];
