import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 24px;

  flex: 1;

  border-radius: 16px;
  background-color: ${(props) => props.theme.colors.greys.lightest};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
`;
