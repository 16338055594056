const computeMultipleDropdownsFilter = ({
  categoriesToChoose,
  selectedCategories,
  setSelectedCategories,
  subCategoriesToChoose = [],
  selectedSubCategories = [],
  setSelectedSubCategories = [],
}) => {
  const filters = [
    {
      id: 'categories',
      icon: '/images/inpulse/category-ipblack-small.svg',
      list: categoriesToChoose,
      defaultSelectedItems: categoriesToChoose,
      selectedItems: selectedCategories,
      setSelectedItems: setSelectedCategories,
    },
  ];

  if (subCategoriesToChoose.length) {
    filters.push({
      id: 'subCategories',
      icon: '/images/inpulse/category-ipblack-small.svg',
      list: subCategoriesToChoose,
      defaultSelectedItems: subCategoriesToChoose,
      selectedItems: selectedSubCategories,
      setSelectedItems: setSelectedSubCategories,
    });
  }

  return filters;
};

export default {
  computeMultipleDropdownsFilter,
};
