// ===== Module features for easy import in ClientContainer=====

import LossesAnalyticsModule from './LossesAnalytics';
import LossesLossesContainer from './LossesLosses';
import LossesProducts from './LossesLosses/LossesProducts';
import LossesSupplierProducts from './LossesLosses/LossesSupplierProducts';

const LossesModule = {
  ...LossesAnalyticsModule,
  LossesLossesContainer,
  LossesProducts,
  LossesSupplierProducts,
};

export default LossesModule;
