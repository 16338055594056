import styled from 'styled-components';

export const MainPageContainer = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;

  background-color: ${(props) => props.theme.colors.greys.lighter};
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 96px);

  padding-bottom: 8px;

  background: ${(props) => props.theme.colors.greys.lighter};
`;

export const ListContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const Metric = styled.div`
  max-height: 40px;

  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  margin: auto;
`;

/**
 *  ========== Page "Header" ==========
 *  Contains for example the SearchBar, the Filters on the left
 *  and the Export button or actions list on the right
 *
 *  Example usage in: StocksAnalyticsCurrent
 */
export const ConfigPanelContainer = styled.div`
  width: 100%;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
`;

export const LeftSide = styled.div`
  display: flex;
  column-gap: 24px;
`;

export const RightSide = styled.div`
  display: flex;
  column-gap: 24px;

  margin-left: 24px;
`;
// ====================================
