import styled from 'styled-components';

export const InfoPanelContainer = styled.div`
  padding: 24px 24px 0 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 1326px;
`;

export const ConfirmationMessage = styled.div`
  width: 100%;
  height: 20px;
  line-height: 20px;
  z-index: 15;
  background: #ec6161;
  text-align: center;
  font-family: proximanovasemibold;
  font-size: 13px;
  color: #ffffff;
`;

export const NoConfirmationMessage = styled.div``;

export const SearchContainer = styled.div`
  width: 160px;
  height: 40px;
`;

export const MainTitleContainer = styled.div`
  min-width: 900px;

  margin-right: 24px;

  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 24px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 40px;
`;

export const OrderDisplayDiffBalance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 14px;
  margin-right: 15px;
  height: 56px;
  border-radius: 5px;
  font-family: proximanovaregular;
  background-color: #ffffff;
  color: #000000;
  width: 130px;
  padding: 10px 14px;
`;

export const DiffAmount = styled.div`
  color: ${(props) => props.color};
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  margin-right: 5px;
  font: ${(props) => props.theme.fonts?.textBigHeight16 || ''};
`;

export const DiffAmountText = styled.div`
  font-size: 12px;
  line-height: 12px;
  position: relative;
  top: 2px;
  font: ${(props) =>
    props.theme.fonts?.textSmallHeight14 || 'normal 400 12px/12px proximanovaregular'};
  color: ${(props) => props.theme.colors?.greys.darker || ''};
  margin-bottom: 5px;
`;

export const InpulseFillRecommandation = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 56px;
`;

export const FillRecommandationText = styled.div`
  max-width: 200px;
  margin-left: 8px;
  font: ${(props) => props.theme?.fonts?.textMiddleNormal || ''};
  color: ${(props) => props.theme?.colors?.greys?.darkest || ''};
`;

export const FillRecommandationSwitch = styled.div`
  position: relative;
  top: 2px;
`;

export const ActionsContainer = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  position: relative;
`;

export const ActionDot = styled.div`
  right: ${(props) => (props.inline ? '0px' : '15px')};
  bottom: ${(props) => (props.inline ? '0px' : '15px')};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  background: #26e888;
`;

export const LabelContent = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
`;

export default {
  InfoPanelContainer,
  ConfirmationMessage,
  NoConfirmationMessage,
  SearchContainer,
  MainTitleContainer,
  OrderDisplayDiffBalance,
  DiffAmount,
  DiffAmountText,
  InpulseFillRecommandation,
  FillRecommandationText,
  FillRecommandationSwitch,
  ActionsContainer,
  LoadingContainer,
};
