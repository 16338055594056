import { isEmpty } from 'lodash';

import { allergen as allergenService } from '@services/allergen';
import { ingredient as ingredientService } from '@services/ingredient';

import sortSupplierProductProperties from '@admin/utils/sortSupplierProductProperties';

/**********************/
/* Fetch Data Methods */
/**********************/

/**
 * Handle the fetch of ingredient categories for the given clientId associated to the user
 *
 * @param {String} clientId                     - The client id on which retrieve the ingredients categories
 * @param {Function} setIngredientsCategories   - Method used to set the local state of the ingredientsCategories variable
 */
export async function fetchIngredientsCategoriesOfClient(clientId, setIngredientsCategories) {
  const result = await ingredientService.getIngredientsCategories(clientId);

  if (isEmpty(result)) {
    setIngredientsCategories([]);

    return;
  }

  const formattedCategories = result.map((category, index) => ({ id: index, name: category }));

  setIngredientsCategories(
    sortSupplierProductProperties(
      formattedCategories.map((field) => field.name),
      true,
    ),
  );
}

/**
 * Render the allergens form section with the different inputs that should be displayed
 *
 * @param {Function} setAllergens  - Method used to set the local state of the allergens variable
 */
export async function fetchAllergensList(setAllergens) {
  const result = await allergenService.getAllergens();

  setAllergens(result);
}

export default {
  fetchIngredientsCategoriesOfClient,
  fetchAllergensList,
};
