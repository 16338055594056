import { get, orderBy } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Tooltip } from '@commons/utils/styledLibraryComponents';
import DisplayNumber, { getNumberFormattedWithDecimals } from '@commons/DisplayNumber';
import Text, { ENUM_FONTS } from '@commons/Text';

import { getEntityUnit } from '@stocks/utils';

import {
  DisplayBiggerContainer,
  ItemRow,
  ItemRowContainer,
  ItemRowContent,
  TooltipContainer,
  TooltipContentContainer,
  TooltipRowContainer,
  TooltipRowItem,
} from '../styledComponents';

const aggregateStatsOnTotalLevel = (totalRow, filteredData) => {
  const filteredTotalData = filteredData.reduce(
    (acc, { lossBySP, lossByProducts, total, productsData }) => {
      if (lossBySP.currency != null) {
        acc.lossBySP += lossBySP.currency;
      }

      if (lossByProducts.currency != null) {
        acc.lossByProducts += lossByProducts.currency;
      }

      if (total.currency != null) {
        acc.total += total.currency;
      }

      productsData.forEach(({ productName, currency, unit }) => {
        if (!acc.productDataByProductName[productName]) {
          acc.productDataByProductName[productName] = {
            productName,
            currency: null,
            ratio: null,
            unit: null,
          };
        }

        if (currency != null) {
          acc.productDataByProductName[productName].currency += currency;
        }

        if (unit != null) {
          acc.productDataByProductName[productName].unit += unit;
        }
      });

      return acc;
    },
    {
      lossBySP: null,
      lossByProducts: null,
      total: null,
      productDataByProductName: {},
    },
  );

  const totalRowProductsData = Object.values(filteredTotalData.productDataByProductName).map(
    (productData) => ({
      ...productData,
      ratio: filteredTotalData.lossByProducts
        ? (productData.currency * 100) / filteredTotalData.lossByProducts
        : null,
    }),
  );

  return {
    ...totalRow,
    lossBySP: {
      currency: filteredTotalData.lossBySP,
    },
    lossByProducts: {
      currency: filteredTotalData.lossByProducts,
    },
    total: {
      currency: filteredTotalData.total,
    },
    productsData: orderBy(totalRowProductsData, 'ratio', 'desc'),
  };
};

const getFormattedCurrency = (metricKey, unit) =>
  metricKey === 'currency' ? null : { alphabeticCode: getEntityUnit(unit), numberDecimals: 2 };

export const formatAnalyticsForNestedList = ({ total, dataByIngredient }) => {
  const totalLossBySPCurrency = get(total, 'lossBySP.currency', null);
  const totalLossByProductsCurrency = get(total, 'lossByProducts.currency', null);

  const totalRow = {
    id: 'total',
    name: i18next.t('GENERAL.TOTAL').toUpperCase(),
    lossBySP: {
      ...total.lossBySP,
    },
    lossByProducts: {
      ...total.lossByProducts,
    },
    total: {
      currency:
        totalLossBySPCurrency != null || totalLossByProductsCurrency != null
          ? totalLossBySPCurrency + totalLossByProductsCurrency
          : null,
    },
    productsData: [],
  };

  const productDataByProductName = {};

  const formattedDataByIngredient = Object.values(dataByIngredient).map((analytic) => {
    const ingredientTotalLossBySPCurrency = get(analytic, 'total.lossBySP.currency', null);
    const ingredientTotalLossByProductsCurrency = get(
      analytic,
      'total.lossByProducts.currency',
      null,
    );
    const ingredientTotalLossBySPUnit = get(analytic, 'total.lossBySP.unit', null);
    const ingredientTotalLossByProductsUnit = get(analytic, 'total.lossByProducts.unit', null);

    const ingredientFormattedData = {
      name: analytic.ingredientName,
      isHighlighted: analytic.isStrategic,
      entityUnit: analytic.ingredientUnit,
      lossBySP: {
        ...analytic.total.lossBySP,
      },
      lossByProducts: {
        ...analytic.total.lossByProducts,
      },
      total: {
        currency:
          ingredientTotalLossBySPCurrency != null || ingredientTotalLossByProductsCurrency != null
            ? ingredientTotalLossBySPCurrency + ingredientTotalLossByProductsCurrency
            : null,
        unit:
          ingredientTotalLossBySPUnit != null || ingredientTotalLossByProductsUnit != null
            ? ingredientTotalLossBySPUnit + ingredientTotalLossByProductsUnit
            : null,
      },
      categoriesData: analytic.categoryDataSortedByRatio || [],
      productsData: analytic.sortedByProductRatio || [],
    };

    ingredientFormattedData.productsData.forEach(({ productName, currency, unit }) => {
      if (!productDataByProductName[productName]) {
        productDataByProductName[productName] = {
          productName,
          currency: null,
          ratio: null,
          unit: null,
        };
      }

      if (currency != null) {
        productDataByProductName[productName].currency += currency;
      }

      if (unit != null) {
        productDataByProductName[productName].unit += unit;
      }
    });

    return ingredientFormattedData;
  });

  const totalRowProductsData = Object.values(productDataByProductName).map((productData) => {
    const totalRowLossByProducts = get(totalRow, 'lossByProducts.currency', null);

    return {
      ...productData,
      ratio: totalRowLossByProducts ? (productData.currency * 100) / totalRowLossByProducts : null,
    };
  });

  totalRow.productsData = orderBy(totalRowProductsData, 'ratio', 'desc');

  return { totalRow, formattedDataByIngredient };
};

const addNestedContentToFormattedIngredientData = (
  formattedIngredients,
  metricKey,
  lossCategoriesById,
) => {
  const ingredientsWithNestedContent = [...formattedIngredients].map((ingredient) => {
    const ingredientCategoryData = ingredient.categoriesData || [];

    const formattedDataForNestedContent = ingredientCategoryData.map((categoryData) => {
      const category = get(lossCategoriesById, `${categoryData.lossCategoryId}`, null);

      const categoryName = i18next.t(category.id ? category.translationKey : 'GENERAL.NONE_VALUE');

      return {
        ...categoryData,
        categoryName,
        ingredientUnit: ingredient.entityUnit,
      };
    });

    return {
      ...ingredient,
      nestedContent: formattedDataForNestedContent.length
        ? () => generateNestedContent(formattedDataForNestedContent, metricKey)
        : null,
    };
  });

  return ingredientsWithNestedContent;
};

const generateNestedContent = (items, metricKey) => (
  <ItemRowContainer>
    <ItemRow borderBottom>
      <ItemRowContent width={'240px'}>{i18next.t('GENERAL.CATEGORY')}</ItemRowContent>
      <ItemRowContent>{i18next.t('GENERAL.TYPE')}</ItemRowContent>
      <ItemRowContent>{i18next.t('GENERAL.LOSSES')}</ItemRowContent>
      <ItemRowContent>
        {i18next.t('LOSSES.ANALYTICS.CATEGORY_PERCENTAGE_COLUMN_NAME')}
        <Tooltip text={i18next.t('LOSSES.ANALYTICS.CATEGORY_PERCENTAGE_COLUMN_TOOLTIP_TEXT')} />
      </ItemRowContent>
    </ItemRow>
    {items.map(({ categoryName, lossType, currency, unit, ratio, ingredientUnit }, index) => {
      const formattedCurrency = getFormattedCurrency(metricKey, ingredientUnit);

      return (
        <ItemRow borderTop={index !== 0} key={`nestedRow-${index}`}>
          <ItemRowContent width={'240px'}>
            <DisplayBiggerContainer>
              <Tooltip
                renderTooltipTrigger={() => (
                  <ItemRowContent displayBigger>{categoryName}</ItemRowContent>
                )}
                text={categoryName}
                displayBigger
              />
            </DisplayBiggerContainer>
          </ItemRowContent>
          <ItemRowContent>
            <DisplayBiggerContainer>
              <Tooltip
                renderTooltipTrigger={() => (
                  <ItemRowContent displayBigger>
                    {i18next.t(lossType === 'product' ? 'GENERAL.PRODUCT' : 'GENERAL.INGREDIENT')}
                  </ItemRowContent>
                )}
                text={i18next.t(lossType === 'product' ? 'GENERAL.PRODUCT' : 'GENERAL.INGREDIENT')}
                displayBigger
              />
            </DisplayBiggerContainer>
          </ItemRowContent>
          <ItemRowContent>
            <DisplayNumber
              currency={formattedCurrency}
              number={metricKey === 'currency' ? currency : unit}
              displayCurrencyCode
            />
          </ItemRowContent>
          <ItemRowContent>
            {ratio ? `${getNumberFormattedWithDecimals(ratio, 2)}%` : '-'}
          </ItemRowContent>
        </ItemRow>
      );
    })}
  </ItemRowContainer>
);

const generateTooltipContent = (row, metricKey) => {
  const formattedCurrency = getFormattedCurrency(metricKey, row.entityUnit);

  return (
    <TooltipContainer>
      <Text font={ENUM_FONTS.TEXT_MIDDLE_BOLD}>
        {i18next.t('LOSSES.ANALYTICS.ROW_TOOTLIP_TITLE')}
      </Text>
      <TooltipContentContainer>
        <TooltipRowContainer>
          <TooltipRowItem width={'140px'}>
            <Text font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14_WEIGHT_700_BOLD}>
              {i18next.t('GENERAL.PRODUCT')}
            </Text>
          </TooltipRowItem>
          <TooltipRowItem width={'96px'}>
            <DisplayNumber
              currency={formattedCurrency}
              font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14_WEIGHT_700_BOLD}
              number={
                metricKey === 'currency'
                  ? get(row, 'lossByProducts.currency', null)
                  : get(row, 'lossByProducts.unit', null)
              }
              displayCurrencyCode
            />
          </TooltipRowItem>
          <TooltipRowItem width={'96px'}>
            <Text font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14_WEIGHT_700_BOLD}>100%</Text>
          </TooltipRowItem>
        </TooltipRowContainer>
        {row.productsData.map((data, index) => (
          <TooltipRowContainer key={`nestedTooltip-row-${index}`}>
            <TooltipRowItem width={'140px'} displayPaddingLeft>
              <DisplayBiggerContainer>
                <Tooltip
                  renderTooltipTrigger={() => (
                    <Text
                      font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14}
                      style={{
                        width: '132px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {data.productName}
                    </Text>
                  )}
                  text={data.productName}
                  displayBigger
                />
              </DisplayBiggerContainer>
            </TooltipRowItem>
            <TooltipRowItem width={'96px'} displayPaddingLeft>
              <DisplayNumber
                currency={formattedCurrency}
                font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14}
                number={
                  metricKey === 'currency' ? get(data, 'currency', null) : get(data, 'unit', null)
                }
                displayCurrencyCode
              />
            </TooltipRowItem>
            <TooltipRowItem width={'96px'} displayPaddingLeft>
              <Text font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14}>
                {data.ratio ? `${getNumberFormattedWithDecimals(data.ratio, 2)}%` : '-'}
              </Text>
            </TooltipRowItem>
          </TooltipRowContainer>
        ))}
      </TooltipContentContainer>
    </TooltipContainer>
  );
};

export default {
  aggregateStatsOnTotalLevel,
  formatAnalyticsForNestedList,
  generateNestedContent,
  generateTooltipContent,
  getFormattedCurrency,
  addNestedContentToFormattedIngredientData,
};
