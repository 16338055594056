import { APIcaller } from './APIcaller';
import axios from '../core/http';

import { config } from '../config';
const baseApiUrl = config.baseApiUrl;

function getStoreSupplierProfileMappingsOfSupplier(supplierId) {
  return axios
    .get(`${baseApiUrl}/admin/suppliers/${supplierId}/storeSupplierProfileMappings`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function getSupplierProfileBySupplierIdAndStoreId(supplierId, storeId) {
  return axios
    .get(`${baseApiUrl}/admin/suppliers/${supplierId}/storeSupplierProfile/${storeId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function patchStoreSupplierProfileMappings(storeSupplierProfileMappings) {
  return axios
    .patch(
      baseApiUrl + '/admin/StoreSupplierProfileMappings/many',
      { storeSupplierProfileMappings },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

const updateStoreSupplierProfileMappings = (storeSupplierProfileMappingIds, fields, supplierId) =>
  APIcaller.apiPatch('/admin/store-supplier-profile-mappings/', {
    storeSupplierProfileMappingIds,
    fields,
    supplierId,
  });

function deleteStoreSupplierProfileMappings(storeSupplierProfileMappingIds) {
  return axios
    .post(baseApiUrl + '/admin/StoreSupplierProfileMappings/delete', {
      storeSupplierProfileMappingIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function patchStoreSupplierProfileMappingFieldById(
  storeSupplierProfileMappingId,
  storeSupplierProfileMappingFieldToPatch,
) {
  return axios
    .patch(
      `${baseApiUrl}/admin/store-supplier-profile-mapping/${storeSupplierProfileMappingId}`,
      { fields: storeSupplierProfileMappingFieldToPatch },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const storeSupplierProfileMapping = {
  getStoreSupplierProfileMappingsOfSupplier,
  patchStoreSupplierProfileMappings,
  deleteStoreSupplierProfileMappings,
  getSupplierProfileBySupplierIdAndStoreId,
  patchStoreSupplierProfileMappingFieldById,
  updateStoreSupplierProfileMappings,
};
