import i18next from 'i18next';

import ConfirmationModal from '../../../components/ConfirmationModal';

export const getDeleteCashierStoresModalConfig = ({
  cashierStores,
  handleDeleteCashierStoresById,
  translatedStoreName,
  translatedStoresName,
}) => {
  const cashierStoresIds = cashierStores.map((cashierStore) => cashierStore.id);

  const isDeletingMultipleItems = cashierStoresIds.length > 1;

  return {
    type: 'warning',
    width: '542px',
    height: 'auto',
    icon: '/images/inpulse/trash-white-small.svg',
    title: isDeletingMultipleItems
      ? i18next.t('BACKOFFICE.CASHIER_STORES.MULTIPLE_DELETE_MODAL_CONTENT_PLURAL', {
          storeName: translatedStoresName,
        })
      : i18next.t('BACKOFFICE.CASHIER_STORES.MULTIPLE_DELETE_MODAL_CONTENT_SINGULAR', {
          storeName: translatedStoreName,
        }),
    component: ConfirmationModal,
    data: {
      content: isDeletingMultipleItems
        ? i18next.t('BACKOFFICE.CASHIER_STORES.MULTIPLE_DELETE_MODAL_CONTENT_PLURAL', {
            storeName: translatedStoresName,
          })
        : i18next.t('BACKOFFICE.CASHIER_STORES.MULTIPLE_DELETE_MODAL_CONTENT_SINGULAR', {
            storeName: translatedStoreName,
          }),
    },
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.DELETE'),
        icon: '/images/inpulse/trash-white-small.svg',
        handleClick: () => handleDeleteCashierStoresById(cashierStoresIds),
      },
    ],
  };
};

export default {
  getDeleteCashierStoresModalConfig,
};
