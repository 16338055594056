import styled, { css } from 'styled-components';

export const CentralKitchenIcon = styled.div`
  width: 16px;
  height: 16px;
  background: ${(props) => props.theme.colors.highlightedBlue};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubtitleContainer = styled.div`
  height: 100%;
  width: 100;

  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
`;

export const DropdownItemContainer = styled.div`
  width: 232px;

  display: flex;
  align-items: center;

  gap: 8px;
`;

export const DropdownIcon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const ImageContainer = styled.div`
  position: relative;

  width: 40px;
  height: 40px;
`;

export const ForegroundImage = styled.img`
  width: 40px;
  height: 40px;
  padding: 12px;
  z-index: 2;
  background-color: ${(props) => props.theme.nestedList.toggle.selected.background};
  opacity: 0.64;
  border-radius: 50%;
`;

export const BackgroundImage = styled.img`
  ${(props) =>
    props.hasForegroundImage &&
    css`
      position: absolute;
      top: 0;
      left: 0;
    `}

  width: 40px;
  height: 40px;
  border-radius: 50%;
`;
