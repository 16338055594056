import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${(props) => `url('${props.background}')`};

  border: 1px solid ${(props) => props.theme.colors.greys.light};
  border-radius: 8px;

  display: flex;

  align-items: center;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 240px;

  align-items: center;
  justify-content: center;
`;

export const LockIconContainer = styled.div`
  margin-bottom: 10px;
`;

export const TitleContainer = styled.div`
  font: ${(props) => props.theme.fonts.h2Inter};
  color: ${(props) => props.theme.colors.greys.darkest};
  margin-bottom: 8px;
`;

export const SubtitleContainer = styled.div`
  font: ${(props) => props.theme.fonts.textSmallHeight16};
  color: ${(props) => props.theme.colors.greys.darker};
`;

export default {
  Container,
  LockIconContainer,
  TitleContainer,
  SubtitleContainer,
  ContentContainer,
};
