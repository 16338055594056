import i18next from 'i18next';
import React from 'react';

import Text from '@commons/Text';

const modalContent = () => (
  <Text>{i18next.t('ADMIN.STORES.MODAL_DOWNLOAD_LIBEO_SYNC_CONTENT')}</Text>
);

export const getModalParams = ({ downloadLibeoStatusReport }) => ({
  type: 'info',
  width: '542px',
  height: 'auto',
  icon: '/images/inpulse/gear-black-small.svg',
  title: i18next.t('ADMIN.STORES.MODAL_DOWNLOAD_LIBEO_SYNC_TITLE'),
  component: modalContent,
  data: {},
  actions: [
    {
      key: 0,
      color: 'inpulse-default',
      label: i18next.t('GENERAL.DOWNLOAD'),
      icon: '/images/inpulse/save-white-small.svg',
      handleClick: downloadLibeoStatusReport,
    },
  ],
});

export default {
  getModalParams,
};
