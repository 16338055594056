import { isEmpty } from 'lodash';
import i18next from 'i18next';

import {
  canChangeRushOrderDeliveryDate,
  canCorrectRushOrder,
  canEditRushOrder,
} from '@selectors/actions/rushOrderActions';

export const getFormActions = ({
  isAfter48h,
  setIsEditingRushOrder,
  setIsEditingDeliveryDate,
  setIsEditingReceipts,
  setDisplayReceiptsModal,
  handleExportContent,
  authorizedActions,
  isEditingRushOrder,
  isEditingDeliveryDate,
  displayReceiptsModal,
  fields,
  hasExistingReceipts,
}) => [
  {
    label: i18next.t('ORDERS.RUSH_ORDERS.ACTION_RECEIPTS'),
    icon: '/images/inpulse/receipt-attachment.svg',
    handleClick: () => {
      setIsEditingReceipts(true);
      setDisplayReceiptsModal(true);
    },
    isHidden:
      (!hasExistingReceipts &&
        !canEditRushOrder(authorizedActions) &&
        !canCorrectRushOrder(authorizedActions)) ||
      displayReceiptsModal,
  },
  {
    label: i18next.t('ORDERS.RUSH_ORDERS.ACTION_MODIFY_RUSH_ORDER'),
    icon: '/images/inpulse/pen-ip-black-small.svg',
    handleClick: () => setIsEditingRushOrder(true),
    isHidden: !isAfter48h || !canCorrectRushOrder(authorizedActions) || isEditingRushOrder,
  },
  {
    label: i18next.t('ORDERS.ORDERS.ACTION_EDIT_DELIVERY_DATE'),
    icon: '/images/inpulse/pen-ip-black-small.svg',
    isHidden:
      !isAfter48h || !canChangeRushOrderDeliveryDate(authorizedActions) || isEditingDeliveryDate,
    handleClick: () => {
      setIsEditingDeliveryDate(true);
    },
  },
  {
    label: i18next.t('GENERAL.EXPORT_XLS'),
    icon: '/images/inpulse/file-download-black-thick.svg',
    isHidden: isEmpty(fields),
    handleClick: handleExportContent,
  },
];
