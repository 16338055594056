import { get } from 'lodash';

import importTypeXlsConstants from '@commons/Excels/constants/importType.xslx.constant';
import productsXlsConstants from '@commons/Excels/constants/products.xlsx.constant';

import { normalizeJsonObject } from '@backoffice/utils';

export const formatProductBatchCreationFile = (products) => {
  const formattedProducts = normalizeJsonObject(products);

  return Object.keys(formattedProducts).reduce((result, sheetName) => {
    if (!result[importTypeXlsConstants.KEY]) {
      result[importTypeXlsConstants.KEY] = get(formattedProducts, 'ImportType[0].importtype', null);
    }

    if (sheetName === productsXlsConstants.SHEET.NAME) {
      result[productsXlsConstants.SHEET.ID] = formattedProducts[sheetName].map((current) => ({
        [productsXlsConstants.COLUMN_IDS.NAME]:
          current[productsXlsConstants.COLUMN_NORMALIZE_IDS.NAME],
        [productsXlsConstants.COLUMN_IDS.BRAND]:
          current[productsXlsConstants.COLUMN_NORMALIZE_IDS.BRAND],
        [productsXlsConstants.COLUMN_IDS.CATEGORY]:
          current[productsXlsConstants.COLUMN_NORMALIZE_IDS.CATEGORY],
        [productsXlsConstants.COLUMN_IDS.SUB_CATEGORY]:
          current[productsXlsConstants.COLUMN_NORMALIZE_IDS.SUB_CATEGORY],
        [productsXlsConstants.COLUMN_IDS.SKU]: get(
          current,
          `${productsXlsConstants.COLUMN_NORMALIZE_IDS.SKU}`,
          '',
        ).toString(),
        [productsXlsConstants.COLUMN_IDS.SHELF_LIFE]:
          current[productsXlsConstants.COLUMN_NORMALIZE_IDS.SHELF_LIFE],
        [productsXlsConstants.COLUMN_IDS.PRICE_WITH_TAXES]: get(
          current,
          `${productsXlsConstants.COLUMN_NORMALIZE_IDS.PRICE_WITH_TAXES}`,
          0,
        ),
        [productsXlsConstants.COLUMN_IDS.VAT_RATE]: get(
          current,
          `${productsXlsConstants.COLUMN_NORMALIZE_IDS.VAT_RATE}`,
          0,
        ),
        [productsXlsConstants.COLUMN_IDS.DELIVERY_PRICE_WITH_TAXES]: get(
          current,
          `${productsXlsConstants.COLUMN_NORMALIZE_IDS.DELIVERY_PRICE_WITH_TAXES}`,
          0,
        ),
        [productsXlsConstants.COLUMN_IDS.DELIVERY_VAT_RATE]: get(
          current,
          `${productsXlsConstants.COLUMN_NORMALIZE_IDS.DELIVERY_VAT_RATE}`,
          0,
        ),
        [productsXlsConstants.COLUMN_IDS.COMPOSITION_TYPE]:
          current[productsXlsConstants.COLUMN_NORMALIZE_IDS.COMPOSITION_TYPE],
        [productsXlsConstants.COLUMN_IDS.ASSOCIATED_RECIPE]:
          current[productsXlsConstants.COLUMN_NORMALIZE_IDS.ASSOCIATED_RECIPE],
        [productsXlsConstants.COLUMN_IDS.ASSOCIATED_INGREDIENT]:
          current[productsXlsConstants.COLUMN_NORMALIZE_IDS.ASSOCIATED_INGREDIENT],
      }));
    }

    // Category
    if (sheetName === productsXlsConstants.HIDDEN_SHEET_CATEGORY.NAME) {
      result[productsXlsConstants.HIDDEN_SHEET_CATEGORY.ID] = formattedProducts[sheetName].map(
        (current) => ({
          [productsXlsConstants.HIDDEN_CATEGORY_COLUMN_ID.NAME]:
            current[productsXlsConstants.HIDDEN_CATEGORY_NORMALIZE_COLUMN_ID.NAME],
        }),
      );
    }

    // Subcategory
    if (sheetName === productsXlsConstants.HIDDEN_SHEET_SUB_CATEGORY.NAME) {
      result[productsXlsConstants.HIDDEN_SHEET_SUB_CATEGORY.ID] = formattedProducts[sheetName].map(
        (current) => ({
          [productsXlsConstants.HIDDEN_SUB_CATEGORY_COLUMN_ID.NAME]:
            current[productsXlsConstants.HIDDEN_SUB_CATEGORY_NORMALIZE_COLUMN_ID.NAME],
        }),
      );
    }

    // Brand
    if (sheetName === productsXlsConstants.HIDDEN_SHEET_BRAND.NAME) {
      result[productsXlsConstants.HIDDEN_SHEET_BRAND.ID] = formattedProducts[sheetName].map(
        (current) => ({
          [productsXlsConstants.HIDDEN_BRAND_COLUMN_ID.ID]:
            current[productsXlsConstants.HIDDEN_BRAND_NORMALIZE_COLUMN_ID.ID],
          [productsXlsConstants.HIDDEN_BRAND_COLUMN_ID.NAME]:
            current[productsXlsConstants.HIDDEN_BRAND_NORMALIZE_COLUMN_ID.NAME],
        }),
      );
    }

    // Associated ingredient
    if (sheetName === productsXlsConstants.HIDDEN_SHEET_ASSOCIATED_INGREDIENT.NAME) {
      result[productsXlsConstants.HIDDEN_SHEET_ASSOCIATED_INGREDIENT.ID] = formattedProducts[
        sheetName
      ].map((current) => ({
        [productsXlsConstants.HIDDEN_ASSOCIATED_INGREDIENT_COLUMN_ID.ID]:
          current[productsXlsConstants.HIDDEN_ASSOCIATED_INGREDIENT_NORMALIZE_COLUMN_ID.ID],
        [productsXlsConstants.HIDDEN_ASSOCIATED_INGREDIENT_COLUMN_ID.NAME]:
          current[productsXlsConstants.HIDDEN_ASSOCIATED_INGREDIENT_NORMALIZE_COLUMN_ID.NAME],
      }));
    }

    // Associated recipe
    if (sheetName === productsXlsConstants.HIDDEN_SHEET_ASSOCIATED_RECIPE.NAME) {
      result[productsXlsConstants.HIDDEN_SHEET_ASSOCIATED_RECIPE.ID] = formattedProducts[
        sheetName
      ].map((current) => ({
        [productsXlsConstants.HIDDEN_ASSOCIATED_RECIPE_COLUMN_ID.ID]:
          current[productsXlsConstants.HIDDEN_ASSOCIATED_RECIPE_NORMALIZE_COLUMN_ID.ID],
        [productsXlsConstants.HIDDEN_ASSOCIATED_RECIPE_COLUMN_ID.NAME]:
          current[productsXlsConstants.HIDDEN_ASSOCIATED_RECIPE_NORMALIZE_COLUMN_ID.NAME],
      }));
    }

    return result;
  }, {});
};

export default { formatProductBatchCreationFile };
