import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

const getGlobalActions = ({
  selectedItems,
  availableStores,
  handleExport,
  handleRemoveAssociation,
  handleCreateNewAssociation,
}) => {
  const hasSelectedItems = !!selectedItems.length;

  return [
    {
      fixed: true,
      id: 'action-add',
      actionLabel: i18next.t('GENERAL.ADD'),
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={() => handleCreateNewAssociation(availableStores)}
          icon="/images/inpulse/add-white-small.svg"
          label={i18next.t('GENERAL.ADD')}
        />
      ),
      handleAction: () => {},
    },
    {
      id: 'export',
      actionLabel: i18next.t(
        !hasSelectedItems ? 'GENERAL.EXPORT_LIST' : 'GENERAL.EXPORT_SELECTION',
      ),
      handleAction: () => handleExport(hasSelectedItems),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
    {
      id: 'delete',
      actionLabel: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_ACTION_REMOVE_SELECTION'),
      handleAction: () => handleRemoveAssociation(selectedItems),
      actionIcon: () => '/images/inpulse/remove-black-small.svg',
      isHidden: () => !hasSelectedItems,
    },
  ];
};

const getRowActions = ({ handleRemoveAssociation }) => [
  {
    id: 'delete',
    actionLabel: () =>
      i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_ACTION_REMOVE_SELECTION'),
    handleAction: (item) => handleRemoveAssociation([item]),
    actionIcon: () => '/images/inpulse/remove-black-small.svg',
  },
];

export default {
  getRowActions,
  getGlobalActions,
};
