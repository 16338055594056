import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { DataGridPro, useGridApiContext } from '@mui/x-data-grid-pro';

import Text from '@commons/Text';

import { getProductionPlanningInnerColumns } from '../utils/columns';
import { InnerLineContainer } from '../styledComponents';

const InnerContent = (props) => {
  const { row, startDate, endDate, consumptionComputation } = props;

  // Recommanded by https://mui.com/x/react-data-grid/master-detail/#disable-detail-panel-content-scroll
  const parentRef = useGridApiContext();

  const [columns, setColumns] = useState([]);
  // Recommanded by https://mui.com/x/react-data-grid/master-detail/#disable-detail-panel-content-scroll
  const [width, setWidth] = useState(() => {
    const dimensions = parentRef.current.getRootDimensions();
    return dimensions.viewportInnerSize.width;
  });

  const handleViewportInnerSizeChange = () => {
    const dimensions = parentRef.current.getRootDimensions();
    setWidth(dimensions.viewportInnerSize.width);
  };

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }

    setColumns(getProductionPlanningInnerColumns(startDate, endDate, consumptionComputation));
  }, [startDate, endDate]);

  // Recommanded by https://mui.com/x/react-data-grid/master-detail/#disable-detail-panel-content-scroll
  useEffect(
    () =>
      parentRef.current.subscribeEvent('viewportInnerSizeChange', handleViewportInnerSizeChange),
    [parentRef],
  );

  return (
    <InnerLineContainer width={width}>
      <Text>{i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_PRODUCTION_INNER_LINES_TITLE')}</Text>
      <DataGridPro
        columns={columns}
        density="compact"
        initialState={{
          pinnedColumns: {
            left: ['name'],
          },
        }}
        rows={row.innerRows}
        disableColumnResize
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        hideFooter
        ignoreDiacritics
        showCellVerticalBorder
        showColumnVerticalBorder
      />
    </InnerLineContainer>
  );
};

export default InnerContent;
