import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  width: 100%;

  padding: 0;
  margin: 0 0 24px 0;

  .custom-tooltip {
    white-space: initial !important;
  }
`;

export const RowContainer = styled.div`
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #ffffff;

  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
`;

export const ColumnsContainer = styled.div`
  display: flex;
  padding: 0 16px;
  margin: 24px 0 16px 0;
`;

export const Column = styled.div`
  font: ${(props) => props.theme.fonts?.textBig} !important;
  color: ${(props) => props.theme.listView?.header.default.color} !important;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  flex: ${(props) => props.flex};
  flex-basis: ${(props) => `calc(${(100 / props.nbColumns) * props.flex}%)`};

  margin-right: 16px;

  display: flex;
  align-items: center;
`;
