import styled from 'styled-components';

export const TooltipText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  -webkit-box-orient: vertical;
`;

export default TooltipText;
