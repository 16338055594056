import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { PropsHeader } from '../../interface';
import {
  HeaderContainer,
  TitleColumn,
  Icon,
  TextEllispis,
  HeaderFullContainer,
} from './styledComponents';
import Tooltip from '../../../Tooltip';
import ActionButton from '../ActionButton/ActionButton';
import { getRandomInt } from '../../../utils/format';
import { ORDER_TYPE } from '../../constants';

import iconSortedListDescDark from '../../../images/icon-sorted-desc-dark.svg';
import iconSortedListAscDark from '../../../images/icon-sorted-asc-dark.svg';
import iconSortDark from '../../../images/icon-sort-dark.svg';

import iconSortedListDesc from '../../../images/icon-sorted-desc.svg';
import iconSortedListAsc from '../../../images/icon-sorted-asc.svg';
import iconSort from '../../../images/icon-sort.svg';
import { getTheme } from '../../../utils/theme';

const icons = {
  dark: {
    default: iconSortDark,
    asc: iconSortedListAscDark,
    desc: iconSortedListDescDark,
  },
  blue: {
    default: iconSort,
    asc: iconSortedListAsc,
    desc: iconSortedListDesc,
  },
};

const HeaderRow = (props: PropsHeader): JSX.Element => {
  const { theme, headers, orderBy, orderType, setOrderColumn } = props;

  const selectSortIcon = (id) => {
    const updatedTheme = getTheme(theme, 'expandableEmbeddedList');

    if (id === orderBy) {
      return orderType === ORDER_TYPE.DESCENDING
        ? icons[updatedTheme.header?.icon].desc
        : icons[updatedTheme.header?.icon].asc;
    }

    return icons[updatedTheme.header?.icon].default;
  };

  return (
    <HeaderFullContainer>
      <HeaderContainer>
        {headers.map((header, index) => {
          const random = getRandomInt();
          const isColumnSelected = header.id === orderBy;
          const uniqId = `header-${header.propertyKey}-${random}`;
          return (
            <Fragment key={uniqId}>
              {header.propertyKey !== 'action' && (
                <TitleColumn
                  isFirst={index === 0}
                  large={header.large}
                  narrow={header.narrow}
                  minWidth={header.minWidth}
                  nbColumn={headers.length}
                  selected={isColumnSelected}
                >
                  <TextEllispis
                    interactive={header.isSortable}
                    onClick={() =>
                      header.isSortable && setOrderColumn(header.id)
                    }
                  >
                    {header.name}
                  </TextEllispis>
                  {header.isSortable && (
                    <Icon
                      onClick={() => setOrderColumn(header.id)}
                      width="16"
                      height="16"
                      src={selectSortIcon(header.id)}
                      alt="icon-order"
                      marginLeft="5px"
                      interactive
                    />
                  )}
                  {header.tooltipDisplay && (
                    <Tooltip
                      theme={theme}
                      text={header.tooltipDisplay}
                      place="top"
                    />
                  )}
                </TitleColumn>
              )}

              {header.propertyKey === 'action' && (
                <ActionButton open={false} key={uniqId} />
              )}
            </Fragment>
          );
        })}
      </HeaderContainer>
    </HeaderFullContainer>
  );
};

HeaderRow.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      propertyKey: PropTypes.string.isRequired,
      isNumber: PropTypes.bool,
      unit: PropTypes.string,
      tooltipDisplay: PropTypes.string,
      large: PropTypes.bool,
      bold: PropTypes.bool,
      textTransform: PropTypes.string,
      textDecorationChild: PropTypes.string,
      withRender: PropTypes.bool,
      hrefPrefix: PropTypes.string,
      hrefSuffix: PropTypes.string,
      graphConfiguration: PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
      }),
    })
  ).isRequired,
  orderBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orderType: PropTypes.string.isRequired,
  setOrderColumn: PropTypes.func.isRequired,
};

HeaderRow.defaultProps = {
  orderBy: null,
};

export default HeaderRow;
