import { useHistory } from 'react-router-dom';
import React, { useLayoutEffect } from 'react';

import { getFeaturesByPath, ENUM_MODULE_NAME } from '../../../commons/utils/features';

const MODULE_FEATURES_PATH_ORDERED = [
  '/order/analytics/stores',
  '/order/analytics/categories',
  '/order/analytics/references',
];

export const OrderAnalyticsModulesContainer = (props) => {
  const { userRights } = props;

  const history = useHistory();

  useLayoutEffect(() => {
    const features = getFeaturesByPath(userRights, ENUM_MODULE_NAME.ORDER);

    if (features.length) {
      const redirectionPath = MODULE_FEATURES_PATH_ORDERED.find((featurePath) =>
        features.some((feature) => feature.path === featurePath),
      );

      history.push(redirectionPath);
    }
  }, []);

  return <></>;
};

// ===== Module features for easy import in ClientContainer =====

import OrderAnalyticsPriceEvolution from './OrderAnalyticsPriceEvolution';

import OrderAnalyticsSupplierProducts from './OrderAnalyticsSupplierProducts';

import OrderAnalyticsCategory from './OrderAnalyticsCategory';

import OrderAnalyticsStore from './OrderAnalyticsStore';

const OrderAnalyticsModule = {
  OrderAnalyticsModulesContainer,
  OrderAnalyticsCategory,
  OrderAnalyticsStore,
  OrderAnalyticsPriceEvolution,
  OrderAnalyticsSupplierProducts,
};

export default OrderAnalyticsModule;
