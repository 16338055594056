import i18next from 'i18next';
import React from 'react';

import Text from '@commons/Text';

import theme from '@theme';

import { Label } from '@commons/utils/styledLibraryComponents';

import { LabelContainer } from '../components/Catalogs/styledComponents';

export const getListColumns = () => [
  {
    id: 'name',
    propertyKey: 'name',
    name: i18next.t('GENERAL.NAME'),
  },
  {
    id: 'accountCount',
    propertyKey: 'accountCount',
    name: i18next.t('GENERAL.USERS'),
    render: (item) => (
      <LabelContainer>
        <Label
          background={!item ? theme.colors.infoRed : theme.colors.infoGreen}
          color={!item ? theme.colors.infoRed : theme.colors.infoGreen}
          type={null}
          width={'28px'}
        >
          {item}
        </Label>
      </LabelContainer>
    ),
  },
];

export const getUserMappingsColumns = (clientStoreNameTranslation) => [
  {
    id: 'name',
    propertyKey: 'name',
    name: i18next.t('USERS.DETAILS.PROFILE_LASTNAME_INPUT_LABEL'),
    render: (item) => <Text>{item}</Text>,
  },
  {
    id: 'email',
    propertyKey: 'email',
    name: i18next.t('USERS.DETAILS.PROFILE_EMAIL_INPUT_LABEL'),
    displayBigger: true,
    render: (item) => <Text>{item}</Text>,
  },
  {
    id: 'role',
    propertyKey: 'role',
    name: i18next.t('USERS.DETAILS.PROFILE_ROLE_INPUT_LABEL'),
    render: (item) => <Text>{item}</Text>,
  },
  {
    id: 'storesCount',
    baseName: 'storesCount',
    propertyKey: 'storesCount',
    name: clientStoreNameTranslation,
    excludeFromSearch: true,
    filterType: 'numeric',
    render: (item) => (
      <LabelContainer>
        <Label
          background={!item ? theme.colors.infoRed : theme.colors.infoGreen}
          color={!item ? theme.colors.infoRed : theme.colors.infoGreen}
          type={null}
          width={'28px'}
        >
          {item}
        </Label>
      </LabelContainer>
    ),
  },
];
