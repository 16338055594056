import { connect } from 'react-redux';
import { DateRangePicker } from 'react-dates';
import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { showConfirmationMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { Dropdown } from '@commons/utils/styledLibraryComponents';
import {
  getNbDaysBetweenDays,
  isAfter,
  isBetweenDays,
  isOutsideRange,
  isSameDay,
  isSameOrAfterDay,
  isSameDateWithDifferentTimezone,
} from '@commons/utils/date';
import { getUserTimezone } from '@commons/utils/date';
import {
  renderCustomArrowIcon,
  renderCustomNavIconLeft,
  renderCustomNavIconRight,
} from '@commons/DeepsightAnalyticsHeaders/utils';
import CircleTodayDay from '@commons/DeepsightAnalyticsHeaders/components/CircleTodayDay';

import { canEditDraftOrder } from '@selectors/actions/orderActions';
import { getAuthorizedActions } from '@selectors/featureProps';

import theme from '@theme';

import utilsLib from '../../../utils';

import ORDER_STATUS from '../../../constants/status';

import './InfoModal.css';
import InfoModal from './infoModal/index';
import InpulseDatePickerDotedDay from './DatePickerDotedDay';

const isDayBlocked = (day, startDate, focusedInput) => {
  if (startDate === null || typeof startDate === 'undefined' || focusedInput === 'startDate')
    return false;
  return startDate.clone().add(60, 'days').isBefore(day);
};

class HeaderSelectors extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      focusedInput: null,
      legendModalHeight: null,
      actionsModal: false,
      isDeliveryDateEditable: false,
      exceptionalDeliveryDates: [],
      exceptionalNoDeliveryDates: [],
    };
  }

  useForceUpdate = () => () => this.setState({ value: this.state.value + 1 });

  updateLegendModalHeight = (legendModalHeight) => this.setState({ legendModalHeight });

  getSupplierEnableMultipleOrderOnSameDate() {
    return get(this.props.supplierSelected, 'enableMultipleOrderOnSameDate');
  }

  handleReceptionReferenceInputUpdate = (event) => {
    this.props.handleReceptionReference(event.target.value);
  };

  handleReceptionCommentInputUpdate = (event) => {
    this.props.handleReceptionComment(event.target.value);
  };

  setEndOfSupplyPeriod = (startDate) => {
    const { infos } = this.props;
    const { storeInfos } = infos;
    const storeTimezone = get(storeInfos, 'timezone', '');

    let nextDeliveryDate = null;
    const startDay = startDate.day() > 0 ? startDate.day() : 7; // if === sunday -> 7

    // possible delivery dates for [x] days from today
    const firstPossibleDeliveryDatesByDeliveryDayFromToday =
      this.props.firstPossibleDeliveryDatesByDeliveryDay;

    const deliveryDays = Object.keys(firstPossibleDeliveryDatesByDeliveryDayFromToday).map(
      (deliveryDay) => parseInt(deliveryDay),
    );
    if (deliveryDays.length > 0) {
      // find next possible delivery dates for [x] days from startDate
      let firstPossibleDeliveryDatesFromStartDate = deliveryDays.map((day) => {
        const nbDaysUntillNextDeliveryDay = getNbDaysBetweenDays(startDay, day === 0 ? 7 : day);

        const nextDeliveryDate = moment
          .tz(startDate, storeTimezone)
          .add(nbDaysUntillNextDeliveryDay, 'day');

        if (
          isAfter(
            nextDeliveryDate,
            firstPossibleDeliveryDatesByDeliveryDayFromToday[day],
            storeTimezone,
          )
        ) {
          return nextDeliveryDate.format();
        } else {
          return firstPossibleDeliveryDatesByDeliveryDayFromToday[day];
        }
      });

      // sort by most recent dates
      firstPossibleDeliveryDatesFromStartDate = firstPossibleDeliveryDatesFromStartDate.sort(
        (a, b) => {
          if (moment(a).isAfter(moment(b))) return 1;
          else return -1;
        },
      );
      nextDeliveryDate = firstPossibleDeliveryDatesFromStartDate[0];
    }
    this.setState({
      endOfSupplyPeriod: moment.tz(nextDeliveryDate, storeTimezone).subtract(1, 'day'),
    });
  };

  getNextDeliveryDateWithOrderTimeLimitCheck = (orderTimeLimitPassed, concernedDate, timezone) =>
    orderTimeLimitPassed
      ? moment.tz(concernedDate, timezone).add(1, 'days').startOf('day').format()
      : moment.tz(concernedDate, timezone).format();

  getOrderTimeLimitPassed = (supplierOrderTimeLimit, storeTimezone) => {
    const splittedOrderTimeLimit = !!supplierOrderTimeLimit
      ? supplierOrderTimeLimit.split(':')
      : [];

    // If there is an order time limit, we need to get timezoned store date and set time limit
    if (splittedOrderTimeLimit.length === 2) {
      return moment
        .tz(storeTimezone)
        .set({ hour: splittedOrderTimeLimit[0], minute: splittedOrderTimeLimit[1] })
        .isBefore(moment.tz(storeTimezone));
    }

    return moment
      .tz(supplierOrderTimeLimit, 'HH:mm', storeTimezone)
      .isBefore(moment.tz(storeTimezone));
  };

  /**
   *  "exceptionalDatesWithDelivery" contains passed date and in the case of a new order, we don't want to include them
   * Include passed exceptional delivery date with delivery only if we are in editing order delivery date case
   *
   * @param {array} exceptionalDeliveryDates - Complete list of supplier exceptional delivery dates with delivery
   *   default as empty array since a Supplier is not guaranteed to be mapped when switching stores,
   *   which results in exceptionalDeliveryDates being undefined
   * @returns - A filtered list of exceptional delivery dates with delivery
   */
  getFilteredExceptionalDeliveryDates = (exceptionalDeliveryDates = []) => {
    const {
      infos: { storeInfos },
      isEditingDeliveryDate,
    } = this.props;

    const storeTimezone = get(storeInfos, 'timezone', '');

    const filteredExceptionalDeliveryDates = !isEditingDeliveryDate
      ? exceptionalDeliveryDates.filter((edd) =>
          moment.tz(edd, storeTimezone).isSameOrAfter(moment.tz(storeTimezone), 'day'),
        )
      : exceptionalDeliveryDates;

    return filteredExceptionalDeliveryDates.sort();
  };

  setnextDeliveryDate = () => {
    const { orderDatesInPast, supplierSelected, infos, firstPossibleDeliveryDatesByDeliveryDay } =
      this.props;

    const { storeInfos, supplierInfos } = infos;
    const { exceptionalDeliveryDates, exceptionalNoDeliveryDates } = supplierInfos;

    const filteredExceptionalDeliveryDates =
      this.getFilteredExceptionalDeliveryDates(exceptionalDeliveryDates);

    const storeTimezone = get(storeInfos, 'timezone', '');

    const firstDeliveryDatesAvailable = this.props.firstPossibleDeliveryDatesByDeliveryDay
      ? Object.values(this.props.firstPossibleDeliveryDatesByDeliveryDay)
      : [];

    const filteredDeliveryDatesAvailable = firstDeliveryDatesAvailable.filter(
      (currentDate) => !exceptionalNoDeliveryDates.includes(currentDate),
    );

    const uniqueDeliveryDaysAvailable = Array.from(
      new Set([...filteredDeliveryDatesAvailable, ...filteredExceptionalDeliveryDates]),
    );

    let deliveryDatesAvailable = uniqueDeliveryDaysAvailable.sort();

    const enableMultipleOrderOnSameDate = this.getSupplierEnableMultipleOrderOnSameDate();
    const supplierOrderTimeLimit = get(supplierSelected, 'orderTimeLimit', null);

    let nextDeliveryDate = moment.tz(storeTimezone).startOf('day').format();

    // Delivery condition is set up when we supplierProfile.deliveryDays && supplierProfile.deliveryLeadTimes are not empty
    const hasSetUpDeliveryConditions = !isEmpty(firstPossibleDeliveryDatesByDeliveryDay);

    const orderTimeLimitPassed = this.getOrderTimeLimitPassed(
      supplierOrderTimeLimit,
      storeTimezone,
    );

    if (deliveryDatesAvailable.length > 0 && hasSetUpDeliveryConditions) {
      if (!enableMultipleOrderOnSameDate) {
        // first available date for [x] day ==> order already exists ? => move to next week
        deliveryDatesAvailable = deliveryDatesAvailable.map((deliveryDate) => {
          while (orderDatesInPast.includes(deliveryDate)) {
            deliveryDate = moment
              .tz(deliveryDate, storeTimezone)
              .add(1, 'week')
              .startOf('day')
              .format();
          }
          return deliveryDate;
        });
      }

      // sort by most recent dates
      deliveryDatesAvailable = deliveryDatesAvailable.sort((a, b) => {
        if (moment(a).isAfter(moment(b))) return 1;
        else return -1;
      });
      nextDeliveryDate = deliveryDatesAvailable[0];

      this.setState({ nextDeliveryDate });
    } else {
      // Supplier does not have any delivery days
      if (!enableMultipleOrderOnSameDate) {
        while (orderDatesInPast.includes(nextDeliveryDate)) {
          nextDeliveryDate = moment
            .tz(nextDeliveryDate, storeTimezone)
            .add(1, 'days')
            .startOf('day')
            .format();
        }
      }

      // Here nextDeliveryDate has taken into consideration order dates from this month
      nextDeliveryDate = this.getNextDeliveryDateWithOrderTimeLimitCheck(
        orderTimeLimitPassed,
        nextDeliveryDate,
        storeTimezone,
      );

      this.setState({ nextDeliveryDate });
    }
  };

  componentDidMount() {
    const { supplierInfos } = this.props.infos;
    const { exceptionalDeliveryDates, exceptionalNoDeliveryDates } = supplierInfos;

    const filteredExceptionalDeliveryDates =
      this.getFilteredExceptionalDeliveryDates(exceptionalDeliveryDates);

    this.setnextDeliveryDate();
    this.setState({
      isDeliveryDateEditable:
        this.props.isEditingDeliveryDate ||
        get(this.props.params.order, 'status') === String(ORDER_STATUS.DRAFT),
      exceptionalDeliveryDates: filteredExceptionalDeliveryDates,
      exceptionalNoDeliveryDates,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { supplierInfos } = this.props.infos;
    const { exceptionalDeliveryDates, exceptionalNoDeliveryDates } = supplierInfos;

    const filteredExceptionalDeliveryDates =
      this.getFilteredExceptionalDeliveryDates(exceptionalDeliveryDates);

    if (
      prevProps.firstPossibleDeliveryDatesByDeliveryDay !==
        this.props.firstPossibleDeliveryDatesByDeliveryDay ||
      prevProps.supplierSelected !== this.props.supplierSelected ||
      prevProps.orderDatesInPast !== this.props.orderDatesInPast
    ) {
      this.setnextDeliveryDate();
      this.setState({
        exceptionalDeliveryDates: filteredExceptionalDeliveryDates,
        // not guaranteed to have a mapped supplier when switching stores, which can results in exceptionalNoDeliveryDates being undefined
        exceptionalNoDeliveryDates: exceptionalNoDeliveryDates || [],
      });
    }
    if (
      prevProps.startDate !== this.props.startDate &&
      this.props.startDate &&
      this.props.firstPossibleDeliveryDatesByDeliveryDay
    ) {
      this.setEndOfSupplyPeriod(this.props.startDate);
    }
    if (prevProps.supplierSelected !== this.props.supplierSelected) {
      this.setState({
        endOfSupplyPeriod: null,
      });
    }

    if (prevProps.isEditingDeliveryDate !== this.props.isEditingDeliveryDate) {
      this.setState({
        isDeliveryDateEditable: this.props.isEditingDeliveryDate,
      });
    }
    if (prevState.focusedInput !== this.state.focusedInput) {
      // due to the change of the focusedInput state outside the onFocusChange function
      const forceUpdate = this.useForceUpdate();
      forceUpdate();
    }
  }

  renderOrderDay = (day, renderIsToday, supplierSelected, timezone) => {
    const { exceptionalDeliveryDates } = this.state;

    const isToday = moment(day).format('L') === moment.tz(timezone).format('L');
    const isInFuture = moment(day).isAfter(moment.tz(timezone), 'day');

    /**
     * Used to be moment(day).isSameOrAfter(moment(), 'day');
     * Unfortunately, isSameOfAfter does not seem to work correctly when timezones are involved.
     */
    const isTodayOrInFuture = isToday || isInFuture;

    const { canStillOrder } = utilsLib.getMaxOrderDateOfSupplierFromDate(
      supplierSelected,
      moment(day),
      timezone,
      exceptionalDeliveryDates,
    );

    if (
      supplierSelected.enableMultipleOrderOnSameDate &&
      (isTodayOrInFuture || this.props.isEditingDeliveryDate) &&
      !!canStillOrder
    ) {
      return (
        <InpulseDatePickerDotedDay
          color={'orange'}
          isDot={true}
          item={day}
          renderCircle={renderIsToday}
        />
      );
    }

    return (
      <InpulseDatePickerDotedDay
        color={'red'}
        isDot={true}
        item={day}
        renderCircle={renderIsToday}
      />
    );
  };

  renderExceptionalNoDeliveryDay = (day, renderIsToday) => (
    <InpulseDatePickerDotedDay color={'red'} isDot={true} item={day} renderCircle={renderIsToday} />
  );

  renderNextDeliveryAvailable = (day, customDayCaseStyle, renderIsToday) => (
    <InpulseDatePickerDotedDay
      borderColor={'black'}
      color={'green'}
      isDot={true}
      item={day}
      renderCircle={renderIsToday}
    />
  );

  renderSupplyPeriodDay = (day, endOfSupplyPeriod, customDayCaseStyle) =>
    isSameDay(moment(day), endOfSupplyPeriod) ? (
      <div className="calendar-supply-period-last-day" style={customDayCaseStyle}>
        {day.format('D')}
      </div>
    ) : (
      <div className="calendar-supply-period" style={customDayCaseStyle}>
        {day.format('D')}
      </div>
    );

  renderDeliveryAvailableDay = (day) => (
    <InpulseDatePickerDotedDay color={'green'} isDot={true} item={day} renderCircle={false} />
  );

  onDatesChange = (startDate, endDate) => {
    const { focusedInput } = this.state;

    if (!this.props.isEditingDeliveryDate) {
      return this.props.handleSelectDates({
        startDate: !!startDate ? moment(startDate) : null,
        endDate: !!endDate ? moment(endDate) : null,
      });
    }

    const differenceInDays = moment
      .duration(this.props.endDate.diff(this.props.startDate))
      .asDays();

    if (focusedInput === 'endDate') {
      // If focusedInput is endDate, we still modify startDate (order delivery date)
      // Need to check here because with 'endDate' focus, endDate input can be null (only with endDate input before this.props.endDate)
      const updatedStartDate = !!endDate ? moment(endDate) : moment(startDate);

      const calculatedEndDate = updatedStartDate.add(Math.floor(differenceInDays), 'days');

      this.props.handleSelectDates({ startDate: updatedStartDate, endDate: calculatedEndDate });
    } else {
      const calculatedEndDate = moment(startDate)
        .add(Math.floor(differenceInDays), 'days')
        .endOf('day');

      this.props.handleSelectDates({
        startDate: moment(startDate).startOf('day'),
        endDate: calculatedEndDate,
      });
    }

    this.setState({ focusedInput: false });
  };

  isAnAvailableDay = (
    supplierSelected,
    currentDay,
    userTimezone,
    storeTimezone,
    firstPossibleDeliveryDatesByDeliveryDay,
  ) => {
    const hasSetUpDeliveryConditions = !isEmpty(firstPossibleDeliveryDatesByDeliveryDay);

    const { nextDeliveryDate, exceptionalDeliveryDates } = this.state;

    const startDateSelectedCondition =
      !this.props.startDate ||
      !isSameDateWithDifferentTimezone(
        currentDay,
        this.props.startDate,
        userTimezone,
        storeTimezone,
      );

    const endDateSelectedCondition =
      !this.props.endDate ||
      !isSameDateWithDifferentTimezone(currentDay, this.props.endDate, userTimezone, storeTimezone);

    const bothStartAndEndDateSelectedCondition =
      (!this.props.startDate && !this.props.endDate) ||
      !isBetweenDays(currentDay, this.props.startDate, this.props.endDate);

    const firstPossibleDeliveryDatesCondition =
      firstPossibleDeliveryDatesByDeliveryDay[currentDay.day()] &&
      firstPossibleDeliveryDatesByDeliveryDay[currentDay.day()].length
        ? firstPossibleDeliveryDatesByDeliveryDay[currentDay.day()]
        : false &&
          isSameOrAfterDay(currentDay, firstPossibleDeliveryDatesByDeliveryDay[currentDay.day()]);

    const exceptionalDeliveryDatesCondition =
      hasSetUpDeliveryConditions && exceptionalDeliveryDates.includes(currentDay.format());

    return (
      (supplierSelected.deliveryDays &&
        nextDeliveryDate &&
        startDateSelectedCondition && // day !== startDate (only) selected
        endDateSelectedCondition && // day !== endDate (only) selected
        bothStartAndEndDateSelectedCondition && // day not between startDate & endDate (both selected)
        isAfter(currentDay, nextDeliveryDate) &&
        firstPossibleDeliveryDatesCondition) ||
      exceptionalDeliveryDatesCondition
    );
  };

  renderDayContents = (day, selectedDates) => {
    const { nextDeliveryDate, endOfSupplyPeriod, exceptionalNoDeliveryDates } = this.state;

    // trick to remove time details who is in conflict with timezones
    const currentDay = moment(day.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)).startOf(
      'day',
    );
    const userTimezone = getUserTimezone();

    const {
      firstPossibleDeliveryDatesByDeliveryDay,
      orderDatesInPast,
      supplierSelected,
      infos,
      isEditingDeliveryDate,
    } = this.props;

    const { storeInfos } = infos;
    const storeTimezone = get(storeInfos, 'timezone', '');

    const isToday =
      moment.tz(storeTimezone).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY) ===
      moment(currentDay).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    let isTodaySelected = false;

    if (selectedDates) {
      isTodaySelected = selectedDates
        .map(
          (date) =>
            moment.tz(storeTimezone).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY) ===
            moment(date).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        )
        .some((v) => v);
    }

    const customDayCaseStyle = {
      height: '38px',
      width: '38px',
      paddingTop: '9px',
    };

    const renderIsToday = isToday && !isTodaySelected;

    // We need to format this because currentDay is in user timezone and we use these date for custom date renderering
    const orderDatesInPastWithUserTimezone = orderDatesInPast.map((date) => {
      const orderDateFormatted = moment
        .tz(date, storeTimezone)
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

      return moment.tz(orderDateFormatted, userTimezone).format();
    });

    // order date
    if (orderDatesInPastWithUserTimezone.includes(currentDay.format())) {
      return this.renderOrderDay(currentDay, renderIsToday, supplierSelected, storeTimezone);
    }
    // Render a red dot for exceptional delivery date with Type = 'No delivery'
    // "exceptionalNoDeliveryDates" contains passed date and in the case of a new order, we don't want to draw a red dot on them because passed date it's outside range
    const filteredExceptionalNoDeliveryDates = !isEditingDeliveryDate
      ? exceptionalNoDeliveryDates.filter((exceptionalNoDeliveryDate) =>
          moment
            .tz(exceptionalNoDeliveryDate, storeTimezone)
            .isSameOrAfter(moment.tz(storeTimezone)),
        )
      : exceptionalNoDeliveryDates;

    if (filteredExceptionalNoDeliveryDates.includes(currentDay.format())) {
      return this.renderExceptionalNoDeliveryDay(currentDay, renderIsToday);
    }

    if (!supplierSelected || !nextDeliveryDate) return moment(currentDay).format('D');

    // is date the closer deliveryDate
    if (
      nextDeliveryDate &&
      isSameDateWithDifferentTimezone(currentDay, nextDeliveryDate, userTimezone, storeTimezone)
    ) {
      return this.renderNextDeliveryAvailable(currentDay, customDayCaseStyle, renderIsToday);
    }
    // is date between closer delivery & 2nd closer delivery
    else if (
      !this.props.endDate &&
      endOfSupplyPeriod &&
      isBetweenDays(currentDay, moment(this.props.startDate).add(1, 'days'), endOfSupplyPeriod)
    ) {
      return this.renderSupplyPeriodDay(currentDay, endOfSupplyPeriod, customDayCaseStyle);
    }
    // is date a delivery date available, after the first delivery dates setup
    else if (
      this.isAnAvailableDay(
        supplierSelected,
        currentDay,
        userTimezone,
        storeTimezone,
        firstPossibleDeliveryDatesByDeliveryDay,
      )
    ) {
      return this.renderDeliveryAvailableDay(currentDay);
    } else if (isToday && !isTodaySelected) {
      return <CircleTodayDay isToday={isToday && !isTodaySelected} item={currentDay} />;
    }
    return currentDay.format('D');
  };

  render() {
    const {
      stores,
      storeName,
      suppliers,
      supplierName,
      handleSelectStore,
      handleSelectSupplier,
      startDate,
      endDate,
      canBeModified,
      deliveryDays,
      deliveryLeadTimes,
      orderTimeLimit,
      isEditingDeliveryDate,
      orderStatus,
      displayCart,
      infos,
    } = this.props;

    const { isDeliveryDateEditable } = this.state;
    const { storeInfos, supplierInfos } = infos;
    const { exceptionalNoDeliveryDates } = supplierInfos;

    const storeTimezone = get(storeInfos, 'timezone', '');

    const cantUserEditDraftOrder =
      orderStatus === ORDER_STATUS.DRAFT && !canEditDraftOrder(this.props.authorizedActions);

    const disableDatePicker =
      !(canBeModified || isDeliveryDateEditable) ||
      suppliers.length === 0 ||
      displayCart ||
      cantUserEditDraftOrder;

    const selectedSupplier = suppliers.find((supplier) => supplier.name === supplierName);
    const selectedStore = stores.find((store) => store.name === storeName);

    return (
      <div className={this.props.className}>
        {canBeModified ? (
          <>
            <div className="block-order-filter-location">
              <Dropdown
                customStyle={{ position: 'inherit' }}
                iconSrc={'/images/inpulse/pin-black-small.svg'}
                isDisabled={cantUserEditDraftOrder || displayCart}
                isRequired={true}
                items={stores}
                searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                selectedItem={selectedStore}
                onSelectionChange={(selectedItem) => {
                  handleSelectStore(selectedItem.name, selectedItem.id);
                }}
              />
            </div>

            <div className="block-order-filter-supplier">
              <Dropdown
                customStyle={{ position: 'inherit' }}
                iconSrc={
                  supplierName
                    ? '/images/inpulse/supplier-black-small.svg'
                    : '/images/inpulse/supplier-white-small.svg'
                }
                isDisabled={
                  !suppliers.length || !supplierName || cantUserEditDraftOrder || displayCart
                }
                isRequired={true}
                items={suppliers}
                searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                selectedItem={selectedSupplier}
                onSelectionChange={(selectedItem) => {
                  handleSelectSupplier(selectedItem.name, selectedItem.id);
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="block-order-filter-location-locked">
              <img
                className="orderform-date-input-icon"
                src="/images/inpulse/pin-black-small.svg"
              />
              <div className="block-order-filter-locked">{storeName}</div>
              <img
                alt="derouler"
                className="location-filter-input-arrow"
                src="/images/inpulse/carret-black.svg"
              />
            </div>
            <div className="block-order-filter-supplier-locked">
              <img
                className="orderform-date-input-icon"
                src="/images/inpulse/supplier-black-small.svg"
              />
              <div className="block-order-filter-locked">{supplierName}</div>
              <img
                alt="derouler"
                className="location-filter-input-arrow"
                src="/images/inpulse/carret-black.svg"
              />
            </div>
          </>
        )}
        <div className="block-order-filter-date">
          <div
            className={`block-order-filter-date-items-container${
              disableDatePicker ? ' disabled' : ''
            }`}
            style={
              this.state.focusedInput
                ? {
                    border: `${theme.inputs.primary.focus.border}`,
                  }
                : { border: `${theme.inputs.primary.default.border}` }
            }
          >
            <img
              className="orderform-date-input-icon"
              src={'/images/icon-calendar-black-inpulse.svg'}
            />
            <div className="no-dates-styling">
              <DateRangePicker
                calendarInfoPosition="top" // momentPropTypes.momentObj or null,
                customArrowIcon={renderCustomArrowIcon()} // PropTypes.string.isRequired,
                disabled={disableDatePicker} // momentPropTypes.momentObj or null,
                displayFormat={'ddd Do MMM'} // PropTypes.string.isRequired,
                endDate={endDate} // PropTypes.func.isRequired,
                endDateId="your_unique_end_date_id" // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                endDatePlaceholderText={i18next.t(
                  'GENERAL.ANALYTICS_HEADER_DATE_PICKER_END_PLACEHOLDER',
                )} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput}
                hideKeyboardShortcutsPanel={true}
                isDayBlocked={(date) => isDayBlocked(date, startDate, this.state.focusedInput)}
                isOutsideRange={(day) =>
                  this.getSupplierEnableMultipleOrderOnSameDate()
                    ? isOutsideRange(
                        day.startOf('day'),
                        this.state.nextDeliveryDate,
                        [moment(startDate).startOf('day').format()],
                        isEditingDeliveryDate,
                        storeTimezone,
                        exceptionalNoDeliveryDates,
                      )
                    : isOutsideRange(
                        day.startOf('day'),
                        this.state.nextDeliveryDate,
                        this.props.orderDatesInPast,
                        isEditingDeliveryDate,
                        storeTimezone,
                        exceptionalNoDeliveryDates,
                      )
                }
                minimumNights={0}
                navNext={renderCustomNavIconRight()}
                navPrev={renderCustomNavIconLeft()}
                noBorder={true}
                numberOfMonths={2}
                readOnly={true}
                renderCalendarInfo={() => (
                  <InfoModal
                    deliveryDays={deliveryDays}
                    deliveryLeadTimes={deliveryLeadTimes}
                    enableMultipleOrderOnSameDate={this.getSupplierEnableMultipleOrderOnSameDate()}
                    nextDeliveryDate={this.state.nextDeliveryDate}
                    orderDatesInPast={this.props.orderDatesInPast}
                    orderTimeLimit={orderTimeLimit}
                    supplierSelected={this.props.supplierSelected}
                    timezone={storeTimezone}
                    updateLegendModalHeight={this.updateLegendModalHeight}
                  />
                )}
                renderDayContents={(day) => this.renderDayContents(day, [startDate, endDate])}
                startDate={startDate}
                startDateId="your_unique_start_date_id"
                startDatePlaceholderText={i18next.t('ORDERS.ORDERS.DATE_PICKER_START_PLACEHOLDER')}
                verticalSpacing={(this.state.legendModalHeight || 0) + 5}
                onDatesChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)}
                onFocusChange={(focusedInput) => this.setState({ focusedInput })}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HeaderSelectors.propTypes = {
  stores: PropTypes.array.isRequired,
  storeName: PropTypes.string.isRequired,
  suppliers: PropTypes.array.isRequired,
  supplierName: PropTypes.string.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  handleSelectStore: PropTypes.func.isRequired,
  handleSelectSupplier: PropTypes.func.isRequired,
  handleSelectDates: PropTypes.func.isRequired,
  canBeModified: PropTypes.bool.isRequired,
  infos: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/order/orders'),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
});

HeaderSelectors = connect(mapStateToProps, mapDispatchToProps)(HeaderSelectors);

const Styled = styled(HeaderSelectors)`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 24px;
  }

  .block-order-filter-location,
  .block-order-filter-supplier {
    width: 240px;
    height: 100%;
  }

  & .block-order-filter-location-locked,
  .block-order-filter-supplier-locked {
    width: 240px;
    height: 40px;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.colors?.greys.light || '#1d1d1b'};
    cursor: not-allowed;
    border-radius: 8px;
    font: ${(props) => props.theme.fonts?.textSmall || 'normal 20px proximanovasemibold'};
  }

  & .location-filter-input-arrow {
    float: right;
    margin-right: 16px;
    height: 14px;
    width: 14px;
  }

  & .orderform-date-input-icon,
  .multiple-dropdown-icon {
    margin-left: 15px;
  }

  & .block-order-filter-locked {
    width: calc(100% - 55px);

    margin-left: 15px;

    color: ${(props) => props.theme.colors.greys.darkest};
    font: normal 500 12px/16px inter;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .block-order-filter-date-locked {
    width: 155px;
    height: 36px;
    display: flex;
    align-items: center;
    background-color: rgba(168, 177, 196, 0.6);
    cursor: pointer;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    color: #6b768c;
  }

  & .no-dates-styling {
    width: inherit;
  }

  & .no-dates-styling .CalendarDay__today {
    box-shadow: inset 0 0 0px 0px rgb(255, 255, 255);
    font-weight: bold;
  }

  & .block-order-input-reference,
  .block-order-input-comment {
    border-radius: 8px;
    outline: none;
    font: ${(props) => props.theme.fonts?.textSmall || 'normal 14px proximanovasemibold'};
    line-height: 40px;
    height: 40px;
  }

  & .block-order-input-reference {
    width: 165px;
    margin-right: 20px;
  }

  & .block-order-input-comment {
    width: 165px;
  }

  & .block-order-input-reference::placeholder,
  .block-order-input-comment::placeholder {
    color: ${(props) => props.theme.colors?.greys.dark};
  }

  & .block-order-input-reference-locked,
  .block-order-input-comment-locked {
    overflow-x: auto;
    background-color: ${(props) => props.theme.colors?.greys.light || '#1d1d1b'};
    cursor: pointer;
    border-radius: 8px;
    font: ${(props) => props.theme.fonts?.textSmall || 'normal 14px proximanovasemibold'};
    outline: none;
    line-height: 40px;
    padding-left: 10px;
    height: 40px;
    width: 165px;
    margin-left: 20px;
  }

  & .block-order-input-reference-locked::-webkit-scrollbar,
  .block-order-input-comment-locked::-webkit-scrollbar {
    display: none;
  }

  & .item-list-actions-button {
    position: relative;
    height: 36px;
    line-height: 36px;
    margin-left: 10px;
  }

  & .items-list-actions-list {
    position: absolute;
    background: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    width: 200px;
    border-radius: 2px;
    padding: 5px 0;
    right: 20px;
    top: 37px;
    z-index: 2;
  }

  & .items-list-actions-list-action {
    cursor: pointer;
    height: 30px;
    width: 100%;
    font-size: 12px;
    color: #6b768c;
    line-height: 30px;
    padding-left: 15px;
  }

  & .calendar-delivery-available {
    color: ${(props) => props.theme.colors?.greys?.darkest};
  }

  & .calendar-next-delivery {
    color: ${(props) => props.theme.colors?.brand.primary};
    border: none;
  }

  & .calendar-order-day {
    color: ${(props) => props.theme.colors?.red};
  }

  & .calendar-supply-period {
    color: ${(props) => props.theme.colors?.brand.primary};
    border-top: ${(props) => `1px solid ${props.theme.colors.brand.primary}`};
    border-bottom: ${(props) => `1px solid ${props.theme.colors.brand.primary}`};
  }

  & .calendar-supply-period-last-day {
    color: ${(props) => props.theme.colors?.brand.primary};
    border-top: ${(props) => `1px solid ${props.theme.colors.brand.primary}`};
    border-bottom: ${(props) => `1px solid ${props.theme.colors.brand.primary}`};
    border-right: ${(props) => `1px solid ${props.theme.colors.brand.primary}`};
  }

  & .block-order-filter-date {
    width: 240px;
    height: 40px;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
    cursor: pointer;
    border-radius: ${(props) => props.theme.borders?.radius.weak || '4px'};
    -webkit-box-shadow: none;
    box-shadow: none;
    color: ${(props) => props.theme.colors?.greys.light || '#6b768c'};
  }

  & .block-order-filter-date-items-container.disabled {
    background-color: ${(props) => props.theme.colors.greys.light};
    cursor: not-allowed;
  }

  & .DateRangePicker_picker {
    top: inherit !important;
    left: inherit !important;
    position: fixed;
    margin-top: 135px !important;
    @media (min-width: 1251px) {
      margin-left: -30px !important;
    }
    @media (max-width: 1250px) {
      margin-left: -408px !important;
    }
  }

  & .block-order-filter-date-items-container.disabled {
    background-color: ${(props) => props.theme.colors.greys.light};
    cursor: not-allowed;
  }

  & .block-order-filter-date-items-container {
    white-space: nowrap;
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    cursor: pointer;
    border-radius: ${(props) => props.theme.inputs?.primary.default.borderRadius || '4px'};
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div > div {
      display: flex;
      align-items: center;

      height: 40px;
      width: calc(100% - 40px);

      > div {
        width: 100%;

        > div {
          width: 100%;
          display: flex;

          .DateInput_1 {
            flex: 1;
          }
        }
      }
    }
  }

  & .DateInput_fang {
    display: none !important;
  }

  & .DateRangePickerInput__disabled {
    background-color: ${(props) => props.theme.colors.greys.light};
  }

  & .CalendarMonth_caption {
    color: ${(props) => props.theme.colors?.greys?.darkest} !important;
    font: ${(props) => props.theme.fonts?.h3} !important;
  }

  & .DateInput_input {
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    text-align: center !important;
    cursor: pointer;
  }

  & .DateInput_input__disabled {
    cursor: not-allowed;
  }

  & .DateInput_input__focused {
    border-bottom: none !important;
  }

  & .DateInput_input__disabled {
    cursor: not-allowed;
  }

  & .DayPicker_weekHeader_li {
    font: ${(props) => props.theme.fonts?.textMicro} !important;
  }

  & .CalendarDay {
    background: ${(props) => props.theme.colors?.greys.lightest} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__default:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__highlighted_calendar {
    background: #fff !important;
    border: 1px double #0a3eff !important;
  }

  & .CalendarDay__highlighted_calendar:hover {
    background: #e4e7e7 !important;
  }

  & .CalendarDay__selected_span {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    border: 1px double #e2e2e2 !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected,
  .CalendarDay__selected:active .CalendarDay__selected:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_start,
  .CalendarDay__selected_start:hover,
  .CalendarDay__selected_end,
  .CalendarDay__selected_end:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover {
    color: ${(props) => props.theme.colors?.greys.dark} !important;
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
  }

  & .block-order-filters-left-wrapper {
    display: flex;
    height: 36px;
    margin-left: 10px;
    margin-bottom: 20px;
  }

  & .block-order-filter-location,
  .block-order-filter-supplier {
    width: 240px;
    height: 100%;
  }

  & .orderform-date-input-icon {
    margin-left: 15px;
  }
`;

export default Styled;
