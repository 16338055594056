import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';

import { Button, Label } from '@commons/utils/styledLibraryComponents';
import { getTheme } from '@commons/utils/theme';
import DisplayNumber, { formatNumber } from '@commons/DisplayNumber';
import Drawer from '@commons/Drawer';
import Text, { ENUM_FONTS } from '@commons/Text';

import { getEntityUnit } from '@stocks/utils';

import {
  RowContentContainer,
  Item,
  FlexDiv,
  BoxImg,
  NameContent,
  ProductionInteractionContainer,
} from '../styledComponents';

import ProductionDrawerContent from './ProductionDrawerContent';

const ProductionRow = memo((props) => {
  const {
    nbColumns,
    recipe,
    handleQuantityUpdate,
    setDisplayProductPicture,
    productionQuantity,
    plannedProduction,
    selectedDate,
    readOnly,
    isOpened,
    setOpenedRecipeId,
    hasProductionPlanning,
  } = props;

  const [quantity, setQuantity] = useState(0);

  const reset = () => {
    setOpenedRecipeId(null);
    setQuantity(0);
  };

  const theme = getTheme();

  return (
    <>
      <RowContentContainer>
        <Item flex={2} nbColumns={nbColumns}>
          <FlexDiv>
            <BoxImg
              img={recipe.img || '/images/inpulse/recipe-photo-placeholder.svg'}
              pointerCursor={!!recipe.img}
              onClick={(event) => {
                event.stopPropagation();

                if (!!recipe.img && setDisplayProductPicture) {
                  setDisplayProductPicture({ state: true, displayImage: recipe.img });
                }
              }}
            />
            <NameContent>
              <Text>{recipe.name}</Text>
            </NameContent>
          </FlexDiv>
        </Item>

        {hasProductionPlanning && (
          <Item flex={2} nbColumns={nbColumns}>
            <FlexDiv>
              <Label
                background={theme.colors.greys.lighty}
                children={`${formatNumber(plannedProduction, 2)} ${getEntityUnit(recipe.unit)}`}
                color={theme.colors.blacks.ipBlack1}
              />
            </FlexDiv>
          </Item>
        )}

        <Item flex={3} nbColumns={nbColumns}>
          <ProductionInteractionContainer>
            <Button
              buttonCustomStyle={{
                width: '40px',
                height: '40px',
                borderRadius: '8px',
              }}
              color={'inpulse-default'}
              handleClick={() => setOpenedRecipeId(recipe.id)}
              icon={'/images/icon-plus-white.svg'}
              iconCustomStyle={{ width: '16px', height: '16px' }}
              isDisabled={readOnly}
            />
            <DisplayNumber font={ENUM_FONTS.TEXT_BIG_HEIGHT_16} number={productionQuantity || 0} />
            <Text>{getEntityUnit(recipe.unit, false)}</Text>
          </ProductionInteractionContainer>
        </Item>
      </RowContentContainer>

      <Drawer isOpened={isOpened} onClick={reset}>
        <ProductionDrawerContent
          handleQuantityUpdate={handleQuantityUpdate}
          hasProductionPlanning={hasProductionPlanning}
          plannedProduction={plannedProduction}
          productionQuantity={productionQuantity}
          quantity={quantity}
          readOnly={readOnly}
          recipe={recipe}
          selectedDate={selectedDate}
          setQuantity={setQuantity}
          onClose={reset}
        />
      </Drawer>
    </>
  );
});

ProductionRow.PropTypes = {
  nbColumns: PropTypes.number.isRequired,
  recipe: PropTypes.object.isRequired,
  handleQuantityUpdate: PropTypes.func.isRequired,
  setDisplayProductPicture: PropTypes.func.isRequired,
  productionQuantity: PropTypes.object.isRequired,
  plannedProduction: PropTypes.object.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  readOnly: PropTypes.bool.isRequired,
};

export default ProductionRow;
