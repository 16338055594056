import React from 'react';

import { get } from 'lodash';

import {
  Column,
  FixedColumns,
  FixedPart,
  StatusIcon,
  Text,
  LoadingState,
} from './styledComponents';

import { ENUM_STATUS_LINE } from './constants';

export const TableFormLineFixedPart = (props) => {
  const { columns, field, status, displayStatusIcon } = props;

  return (
    <FixedPart>
      {!!displayStatusIcon && (
        <StatusIcon
          src={
            status === ENUM_STATUS_LINE.VALID
              ? '/images/inpulse/done-white-small.svg'
              : '/images/inpulse/close-white-small.svg'
          }
          status={status}
        />
      )}
      <FixedColumns>
        {columns.map((column) => {
          if (field.isLoading) {
            return <LoadingState key={field.id} width={column.width} />;
          }
          return (
            <Column key={`${field.id}-${column.name}`} width={column.width}>
              {!!column.customRender && column.customRender(field)}
              {!column.customRender && <Text>{get(field, column.input.name)}</Text>}
            </Column>
          );
        })}
      </FixedColumns>
    </FixedPart>
  );
};
