import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import DisplayNumber from '@commons/DisplayNumber';

import { Text } from './styledComponents';
import RenderInventoryValidationLabel from './renderInventoryValidationLabel';

const renderDate = (item) => <Text bold>{moment(item).format('L')}</Text>;

const renderInventoryTemplate = ({ name }) => <Text highlighted>{name}</Text>;

export function getDataColumns(storeName, isCentralKitchenView, inventoryValidation) {
  const columns = [
    {
      id: 'storeName',
      propertyKey: 'storeName',
      name: isCentralKitchenView
        ? i18next.t('GENERAL.KITCHEN')
        : getClientStoreNameTranslation(storeName),
      render: (it) => <Text highlighted>{it}</Text>,
    },
    {
      id: 'timestamp',
      filterType: 'date',
      propertyKey: 'timestamp',
      name: i18next.t('STOCKS.STOCKS.LIST_LABEL_DATE'),
      render: renderDate,
    },
    {
      id: 'template',
      propertyKey: 'template',
      name: i18next.t('GENERAL.INVENTORY_TEMPLATE'),
      render: renderInventoryTemplate,
      transform: ({ name }) => name,
    },
    {
      id: 'total',
      type: 'currency',
      filterType: 'numeric',
      propertyKey: 'total',
      name: i18next.t('STOCKS.STOCKS.LIST_LABEL_TOTAL'),
      render: (item) => <DisplayNumber displayCurrencyCode={true} number={item} />,
    },
  ];

  if (inventoryValidation && !isCentralKitchenView) {
    columns.splice(3, 0, {
      id: 'isValidated',
      propertyKey: 'isValidated',
      filterType: 'string',
      name: i18next.t('GENERAL.APPROVAL'),
      excludeFromSearch: true,
      render: (isValidated) => <RenderInventoryValidationLabel isValidated={isValidated} />,
      list: [
        {
          id: 0,
          itemValue: true,
          name: i18next.t('STOCKS.STOCKS.APPROVED'),
          value: i18next.t('STOCKS.STOCKS.APPROVED'),
        },
        {
          id: 1,
          itemValue: false,
          name: i18next.t('STOCKS.STOCKS.TO_BE_APPROVED'),
          value: i18next.t('STOCKS.STOCKS.TO_BE_APPROVED'),
        },
      ],
    });
  }

  return columns;
}

export default {
  getDataColumns,
};
