import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { formatNumber } from '@commons/DisplayNumber';
import { getTheme } from '@commons/utils/theme';
import { Label, Tooltip } from '@commons/utils/styledLibraryComponents';

import { getEntityUnit } from '@stocks/utils/';

const ICON_UNLINK_GREY = '/images/inpulse/unlink-dmgrey-small.svg';
const ICON_NO_RECENT_INVENTORY = '/images/inpulse/stock-dmgrey-small.svg';

const theme = getTheme();

const YesterdayStockLabel = (props) => {
  const { yesterdayStock, moreThanThirtyDay, theoricalStock, isAssociated, unit } = props;

  const [labelText, setLabelText] = useState('');
  const [backgroundLabel, setBackgroundLabel] = useState(theme.colors.lgrey);
  const [colorLabel, setColorLabel] = useState(theme.colors.greys.darkest);
  const [iconLabel, setIconLabel] = useState(null);
  const [iconOnly, setIconOnly] = useState(false);
  const [tooltipLabel, setTooltipLabel] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const productUnit = getEntityUnit(unit);

    if (!isAssociated) {
      setLabelText('');
      setTooltipLabel(i18next.t('ORDERS.ORDERS.FORM_RECOMMANDATION_NOT_ASSOCIATED_TOOLTIP'));
      setBackgroundLabel(theme.colors.greys.dark);
      setIconLabel(ICON_UNLINK_GREY);
      setIconOnly(true);
      setIsLoading(false);
      return;
    }

    if (moreThanThirtyDay) {
      setLabelText('');
      setTooltipLabel(i18next.t('ORDERS.ORDERS.FORM_YESTERDAY_STOCK_NO_RECENT_INVENTORY_TOOLTIP'));
      setBackgroundLabel(theme.colors.greys.dark);
      setIconLabel(ICON_NO_RECENT_INVENTORY);
      setIconOnly(true);
      setIsLoading(false);
      return;
    }

    if (theoricalStock) {
      setColorLabel(theme.colors.highlightedBlue);
      setBackgroundLabel(theme.colors.highlightedBlue);
    } else {
      setColorLabel(theme.colors.greys.dark);
      setBackgroundLabel(theme.colors.greys.dark);
    }

    setLabelText(
      `${
        !!yesterdayStock && yesterdayStock > 0
          ? formatNumber(yesterdayStock, unit === 'u.' ? 0 : 2)
          : '0'
      } ${productUnit}`,
    );
    setIsLoading(false);
  }, [yesterdayStock, isAssociated, moreThanThirtyDay, unit]);

  return (moreThanThirtyDay || !isAssociated) && !isLoading ? (
    <Tooltip
      borderRadius={8}
      cursor={false}
      dataHtml={true}
      displayBigger={true}
      maxWidth={300}
      renderTooltipTrigger={() => (
        <Label
          background={backgroundLabel}
          children={labelText}
          color={colorLabel}
          icon={iconLabel}
          iconOnly={iconOnly}
          isLoading={isLoading}
          position="left"
          type={''}
          width={iconOnly ? 'auto' : null}
        />
      )}
      text={
        `<div style="padding: 8px 8px 0 8px; margin-bottom:-16px; color: #FFFFFF; background-color: #1D1D1B; border-radius: 4px; font: normal 500 14px/16px inter; max-width: 300px; text-align: center; display: flex">` +
        `${tooltipLabel}` +
        `</div>`
      }
    />
  ) : (
    <Label
      background={backgroundLabel}
      children={labelText}
      color={colorLabel}
      icon={iconLabel}
      iconOnly={iconOnly}
      isLoading={isLoading}
      position="left"
      type={''}
      width={iconOnly ? 'auto' : null}
    />
  );
};

YesterdayStockLabel.propTypes = {
  yesterdayStock: PropTypes.number.isRequired,
  moreThanThirtyDay: PropTypes.bool.isRequired,
  isAssociated: PropTypes.bool.isRequired,
  theoricalStock: PropTypes.bool.isRequired,
  unit: PropTypes.oneOf(['u.', 'kg', 'L', '', 'unit']),
};

YesterdayStockLabel.defaultProps = {
  yesterdayStock: 0,
  moreThanThirtyDay: false,
  isAssociated: true,
  theoricalStock: true,
  unit: 'unit',
};

export default YesterdayStockLabel;
