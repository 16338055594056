import DashboardProductionModule from './production';

import FoodCostDashboard from './foodCost';

const DashboardModule = {
  ...DashboardProductionModule,
  FoodCostDashboard,
};

export default DashboardModule;
