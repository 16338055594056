export const showMessage = (message, type, customIcon, dismissTime, manualDismiss) => ({
  type: 'MESSAGE_SHOW',
  message,
  messageType: type,
  messageCustomIcon: customIcon,
  messageDismissTime: dismissTime,
  messageToDismissManually: manualDismiss,
});

export const hideMessage = () => ({
  type: 'MESSAGE_HIDE',
});

export const showConfirmationMessage = (
  message,
  type,
  customIcon,
  dismissTime,
  manualDismiss = false,
) =>
  function (dispatch) {
    dispatch(showMessage(message, type, customIcon, dismissTime, manualDismiss));

    if (!manualDismiss) {
      setTimeout(function () {
        dispatch({
          type: 'MESSAGE_HIDE',
        });
      }, dismissTime || 3000);
    }
  };

export const showErrorMessage = (message, customIcon, dismissTime) =>
  showConfirmationMessage(message, 'error', customIcon, dismissTime);

export const showSuccessMessage = (message, customIcon, dismissTime) =>
  showConfirmationMessage(message, 'success', customIcon, dismissTime);
