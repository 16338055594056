import styled, { css } from 'styled-components';

export const InputContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  display: flex;
  align-items: center;

  position: relative;

  border: none;
  background-color: transparent;

  transition: all 0.2s ease-in-out;

  input::placeholder {
    color: ${(props) =>
      props.isDisabled
        ? props.theme.placeholder.disabled
        : props.theme.placeholder.default};
  }

  input:disabled {
    cursor: not-allowed;
  }
`;

export const ArrowsContainer = styled.div`
  width: 82px;
  height: 100%;

  display: flex;

  position: absolute;
  right: 0px;

  & ::selection {
    color: transparent;
    background: none;
    text-shadow: none;
  }

  & ::-moz-selection {
    color: transparent;
    background: none;
    text-shadow: none;
  }
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};

  background-color: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.isDisabled
      ? props.theme.buttons.disabled.backgroundColor
      : props.theme.buttons.default.backgroundColor};

  ${(props) =>
    props.right
      ? css`
          border-left: 1px solid #ffffff;
          border-radius: 0 calc(${props.theme?.borderRadius} - 1px)
            calc(${props.theme?.borderRadius} - 1px) 0;
        `
      : css`
          border-right: 1px solid #ffffff;
          border-radius: ${props.theme?.borderRadius} 0 0
            ${props.theme?.borderRadius};
        `}

  ${(props) =>
    !props.isDisabled &&
    css`
      &:hover {
        background-color: ${props.theme.buttons.hover.backgroundColor};
      }

      &:active {
        background-color: ${props.theme.buttons.active.backgroundColor};
      }
    `}

  transition: all 0.2s ease-in-out;
`;

export const Input = styled.input`
  height: 100%;
  width: calc(100% - 70px);

  font: ${(props) => props.theme?.font};
  color: #1d1d1b;

  border-radius: ${(props) => (!props.isButtonHidden ? '8px 0 0 8px' : '8px')};
  border: 1px solid ${(props) => props.borderColor.default};
  background-color: ${(props) => props.backgroundColor.default};

  &:hover {
    background-color: ${(props) => props.backgroundColor.hovered};
    border: 1px solid ${(props) => props.borderColor.hovered};
  }

  ${(props) =>
    props.hasErrors &&
    css`
      color: ${props.borderColor.error};
      border: 1px solid ${props.borderColor.error};

      &:hover {
        border: 1px solid ${props.borderColor.error};
      }
    `}

  outline: none;
  text-align: left;
  padding: 0 16px;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  -moz-appearance: textfield;
`;

export const Label = styled.div`
  margin-bottom: 8px;

  font: ${(props) =>
    props.isDisabled
      ? props.theme?.label?.fonts?.disabled
      : props.theme?.label?.fonts?.default};

  color: ${(props) =>
    props.isDisabled
      ? props.theme?.label?.colors?.disabled
      : props.theme?.label?.colors?.default};

  ${(props) =>
    props.required &&
    css`
      &:before {
        content: '* ';
        color: ${props.isDisabled
          ? props.theme?.label?.colors?.disabled
          : props.theme?.label?.colors?.required};
      }
    `}
`;

export const Icon = styled.img`
  width: 10px;
  height: 10px;
`;
