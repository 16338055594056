import _ from 'lodash';
import i18next from 'i18next';

import { ConfirmationModal } from '@commons/Modals/ConfirmationModal';

export const getDeleteIngredientsModalParams = (
  ingredients,
  deleteIngredientByIdsWithLinkedData,
) => {
  const isUniqueSelection = ingredients.length === 1;

  const ingredientToDelete = _.head(ingredients);

  return {
    type: 'warning',
    width: '560px',
    maxHeight: '280px',
    height: 'auto',

    component: ConfirmationModal,
    data: {
      shouldInterpretHtml: true,
      content: isUniqueSelection
        ? i18next.t('ADMIN.INGREDIENTS.SINGLE_DELETE_MODAL_CONTENT', {
            ingredientName: _.get(ingredientToDelete, 'name', ''),
          })
        : i18next.t('ADMIN.INGREDIENTS.MULTIPLE_DELETE_MODAL_CONTENT'),
    },
    title: isUniqueSelection
      ? i18next.t('ADMIN.INGREDIENTS.DELETE_SINGLE_INGREDIENT_ACTION')
      : i18next.t('ADMIN.INGREDIENTS.DELETE_MULTIPLE_INGREDIENTS_ACTION'),
    icon: '/images/inpulse/delete-ip-white-small.svg',
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.DELETE'),
        icon: '/images/inpulse/delete-ip-white-small.svg',
        handleClick: () => deleteIngredientByIdsWithLinkedData(ingredients),
      },
    ],
  };
};

export const getDeactivationWarningModal = (ingredientsToDeactivate, disableIngredients) => {
  const firstIngredient = _.head(ingredientsToDeactivate);

  const isUniqueSelection = ingredientsToDeactivate.length === 1;

  return {
    type: 'warning',
    width: '560px',
    maxHeight: '280px',
    height: 'auto',

    component: ConfirmationModal,
    data: {
      content: isUniqueSelection
        ? i18next.t('ADMIN.INGREDIENTS.SINGLE_DEACTIVATION_WARNING_MODAL_CONTENT', {
            ingredientName: _.get(firstIngredient, 'name'),
          })
        : i18next.t('ADMIN.INGREDIENTS.MULTIPLE_DEACTIVATION_WARNING_MODAL_CONTENT'),
    },
    title: isUniqueSelection
      ? i18next.t('ADMIN.INGREDIENTS.SINGLE_DEACTIVATION')
      : i18next.t('ADMIN.INGREDIENTS.MULTIPLE_DEACTIVATION'),
    icon: '/images/inpulse/power-white-small.svg',
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.DEACTIVATE'),
        icon: '/images/inpulse/power-white-small.svg',
        handleClick: () => disableIngredients(ingredientsToDeactivate),
      },
    ],
  };
};

export const getActivationWarningModal = ({ ingredientToActivate, activateIngredients }) => ({
  type: 'warning',
  width: '560px',
  maxHeight: '280px',
  height: 'auto',

  component: ConfirmationModal,
  data: {
    content: i18next.t('ADMIN.INGREDIENTS.SINGLE_ACTIVATION_WARNING_MODAL_CONTENT', {
      ingredientName: ingredientToActivate.name,
    }),
  },
  title: i18next.t('ADMIN.INGREDIENTS.SINGLE_ACTIVATION'),
  icon: '/images/inpulse/power-white-small.svg',
  actions: [
    {
      key: 0,
      color: 'inpulse-outline',
      label: i18next.t('GENERAL.CANCEL'),
      icon: '/images/inpulse/close-black-small.svg',
    },
    {
      key: 1,
      color: 'inpulse-default',
      label: i18next.t('GENERAL.ACTIVATE'),
      icon: '/images/inpulse/power-white-small.svg',
      handleClick: () => activateIngredients(ingredientToActivate),
    },
  ],
});
