import { APIcaller } from './APIcaller';
import axios from '../core/http';

import { config } from '../config';

const baseApiUrl = config.baseApiUrl;

const getLocationsOfAccount = (accountId) =>
  axios
    .get(baseApiUrl + `/admin/accounts/${accountId}/locations`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const getAccountNotifications = (accountId) =>
  APIcaller.apiGet(`/admin/accounts/${accountId}/notifications`);

export default { getLocationsOfAccount, getAccountNotifications };
