import _ from 'lodash';
import styled, { keyframes } from 'styled-components';

import { ENUM_STATUS_LINE } from './constants';

const gradient = keyframes`
  0% { opacity: 0.4; }
  50% { opacity: 1; }
  100% { opacity: 0.4; }
`;

const statusBackgroundColor = {
  [ENUM_STATUS_LINE.LOADING]: 'theme.colors.greys.light',
  [ENUM_STATUS_LINE.VALID]: 'theme.colors.infoGreen',
  [ENUM_STATUS_LINE.IDLE]: 'theme.colors.greys.dark',
  [ENUM_STATUS_LINE.INVALID]: 'theme.colors.infoRed',
};

export const FixedPart = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  min-height: 64px;

  border-right: ${(props) => `1px solid ${props.theme.colors.greys.light}`};
  gap: 8px;

  border-bottom: ${(props) => `1px solid ${props.theme.colors.greys.light}`};

  &:last-child {
    border-bottom: none;
  }
`;

export const FixedColumns = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 16px;

  gap: 16px;
`;

export const Column = styled.div`
  display: flex;
  width: ${(props) => props.width};

  overflow: hidden;
`;

export const StatusIcon = styled.img`
  padding: 4px;
  margin-left: -8px;
  position: absolute;

  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  background-color: ${(props) => _.get(props, statusBackgroundColor[props.status])};

  > img {
    width: 6px;
  }
`;

export const Text = styled.div`
  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.textMiddleNormal};
`;

export const LoadingState = styled.div`
  width: ${(props) => props.width};
  height: 24px;

  border-radius: ${(props) => props.theme.borders.radius.weak};

  background: linear-gradient(
    90deg,
    ${(props) => props.theme.colors.greys.light} 0%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: ${gradient} 1.5s linear infinite;
`;
