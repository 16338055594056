/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import _ from 'lodash';

const defaultTheme = {
  colors: {
    greys: {
      darkest: '#1D1D1B',
      darker: '#606060',
      dark: '#979797',
      medium: '#D7D7D7',
      blue: '#6B768C',
      lightMedium: '#E8E8E8',
      light: '#E2E2E2',
      lighter: '#F8F8F8',
      lightest: '#FFFFFF',
    },
    brand: {
      primary: '#26E888',
      secondary: '#00E7E3',
      secondaryLight: '#CCF2F2',
      primaryLight: '#D1FFE9',
      primaryLightOpacity: '#DCFBECB0',
      primaryLighter: '#E2F9F9',
      primaryDark: '#15C26D',
      secondaryDark: '#17CFCA',
    },
    black: '#000000',
    red: '#EF252A',
    infoRed: '#FA393E',
    infoLightRed: '#FFE0E0',
    green: '#2CD696',
    infoGreen: '#05E08E',
    infoDarkGreen: '#15C26D',
    infoBlue: '#CCF2F2',
    infoOrange: '#FF9432',
    infoLightOrange: '#FFF1D4',
    lgrey: '#F8F8F8',
    boxBackgrounds: {
      red: '#FFEEEE',
      green: '#BEFFE0',
      greenLight: '#CCF2F2',
      blue: '#BAF7F6',
      lightGrey: '#F3F3F3',
    },
    modalHeaders: {
      orange: {
        background: '#FFF3DA',
        text: '#D07F06',
      },
      red: {
        background: '#FFF0F0',
        text: '#EF252A',
      },
      green: {
        background: '#DCFBEC',
        text: '#1D1D1B',
      },
    },
    orderChart: {
      green: '#21D692',
    },
    highlightedBlue: '#00E7E3',
    white: '#FFFFFF',
  },
  fonts: {
    families: {
      primary: 'inter',
      secondary: 'inter',
    },
    /* style | weight | size/line-height | family */
    title: 'normal 700 32px/28px inter-bold',
    h1: 'normal 700 20px/28px inter-bold',
    h1Inter: 'normal 700 20px/24px inter-bold',
    h2: 'normal 700 16px/24px inter-bold',
    h3: 'normal 600 14px/24px inter-bold',
    h4: 'normal 600 12px/24px inter',
    h5: 'normal 400 14px/24px inter',
    h6: 'normal 400 16px/20px inter-bold',
    kpiBig: 'normal 600 20px/24.2px inter',
    kpiSmall: 'normal 600 14px/17px inter',
    kpiSmallHeight23: 'normal 600 14px/23px inter',
    textBig: 'normal 500 14px/28px inter',
    textBigHeight16: 'normal 500 14px/16px inter',
    textBigHeight20: 'normal 500 14px/20px inter',
    textBigHeight24: 'normal 500 14px/24px inter',
    textBigHeight26: 'normal 500 14px/26px inter',
    textBigHeight24Bold: 'normal 700 14px/20px inter-bold',
    textBigBold: 'normal bold 14px/28px inter',
    textBigBoldHeight16: 'normal bold 16px/19px inter',
    textMiddle: 'normal 400 14px/16px inter',
    textMiddleNormal: 'normal 500 14px/16px inter',
    textMiddleBold: 'normal 700 14px/16px inter',
    textSmall: 'normal 500 12px/20px inter',
    textSmallHeight14: 'normal 500 12px/14px inter',
    textSmallHeight16: 'normal 500 12px/16px inter',
    textSmallHeight24: 'normal 500 12px/24px inter',
    textSmallHeight14Weight600: 'normal 600 12px/14px inter',
    textSmallHeight14Weight700: 'normal 700 12px/14px inter-bold',
    textSmallWeight600: 'normal 600 12px/20px inter',
    textMicro: 'normal 500 10px/20px inter',
    textMicroWeight400: 'normal 400 10px/20px inter',
    textMicroHeight12: 'normal 500 10px/12px inter',
    textBigMicro: 'normal 700 12px/12px inter',
    h2Inter: 'normal 700 16px/24px inter',
    h3Inter: 'normal 700 14px/24px inter',
    h1IinterNonBold: 'normal 700 20px/24px inter',
    chartLabel: 'normal 500 10px/16px inter',
    h3Bold700: 'normal 700 14px/24px inter-bold',
    textMicroWidth8Height10: 'normal 500 8px/10px inter',
    textMicroHeight16: 'normal 500 10px/16px inter',
    textMicroHeight16Weight700Bold: 'normal 700 8px/16px inter-bold',
    textMicroHeight12Weight500: 'normal 500 12px/10px inter',
    textMicroHeight12Weight700Bold: 'normal 700 12px/10px inter-bold',
    textSmallBold: 'normal 700 12px/20px inter',
    textSmallHeight14Weight700Bold: 'normal 700 12px/14px inter-bold',
  },
  tooltip: {
    textColor: '#ffffff',
    arrowColor: '#1D1D1B',
    backgroundColor: '#1D1D1B',
    font: 'normal 500 14px/24px inter',
    icon: {
      color: 'dark',
      size: '12px',
    },
    customIcon: {
      size: '16px',
      default: {
        backgroundColor: 'transparent',
      },
      hover: {
        backgroundColor: '#E2E2E2',
      },
      selected: {
        backgroundColor: '#1D1D1B',
      },
    },
  },
  checkbox: {
    hover: {
      backgroundColor: '#DCFBEC',
    },
    checked: {
      borderColor: '#1D1D1B',
      backgroundColor: '#1D1D1B',
    },
    checkedValidation: {
      borderColor: '#E2E2E2',
      backgroundColor: '#E2E2E2',
    },
    default: {
      borderColor: '#606060',
      backgroundColor: 'transparent',
    },
    disabled: {
      borderColor: '#979797',
      backgroundColor: '#E2E2E2',
    },
    checkDisabled: {
      borderColor: '#E2E2E2',
      backgroundColor: '#E2E2E2',
    },
    icon: {
      width: '8px',
    },
    borderRadius: '4px',
    size: '42px !important',
  },
  progressBar: {
    color: {
      start: {
        positive: '#F8F8F8',
        negative: '#F8F8F8',
      },
      end: {
        positive: '#43ff643d',
        negative: '#e849333d',
      },
    },
    hover: {
      start: {
        positive: '#00ea8429',
        negative: '#e8493329',
      },
      end: {
        positive: '#00ea847a',
        negative: '#e849337a',
      },
    },
    dot: {
      color: {
        positive: '#00EA84',
        negative: '#E84933',
        neutral: '#F8F8F8',
      },
    },
  },
  radioButton: {
    hover: {
      backgroundColor: '#DCFBEC',
    },
    size: {
      outerCircleDefault: '20px',
      innerCircleDefault: '16px',
      outerCircleSmall: '18px',
      innerCircleSmall: '10px',
    },
    selected: {
      color: '#1D1D1B',
      borderSize: '2px',
    },
    disabled: {
      color: '#E2E2E2',
      borderSize: '2px',
    },
    borderSize: '1px',
    borderColor: '#606060',
  },
  expandableEmbeddedList: {
    toggle: {
      selected: {
        backgroundColor: '#F8F8F8',
      },
      default: {
        backgroundColor: '#F8F8F8',
      },
      size: {
        width: '40px',
        height: '40px',
      },
      picture: {
        size: {
          width: '12px',
          height: '7px',
        },
        opened: 'dark',
        closed: 'dark',
      },
    },
    header: {
      default: {
        color: '#606060',
        font: 'normal 500 14px/18px inter',
      },
      selected: {
        color: '#1D1D1B',
        font: 'normal 700 14px/18px inter',
      },
      icon: 'dark',
    },
    row: {
      hover: {
        border: '1px solid transparent',
      },
      font: 'normal 500 14px/28px inter',
      color: '#1D1D1B',
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
      highlight: {
        font: 'normal 700 14px/28px inter',
        color: '#1D1D1B',
      },
    },
    childRow: {
      font: 'normal 500 14px/28px inter',
      color: '#606060',
      link: {
        color: '#1D1D1B',
      },
      separator: {
        color: '#E6E6E6',
      },
      border: 'none',
      borderRadius: '8px',
      backgroundColor: '#FAFAFA',
    },
    customModal: {
      icon: {
        color: 'dark',
        borderRadius: '4px',
        default: {
          backgroundColor: 'transparent',
        },
        hover: {
          backgroundColor: '#E2E2E2',
        },
        selected: {
          backgroundColor: '#1D1D1B',
        },
      },
      tooltipSize: '16px',
      backgroundColor: '#ffffff',
    },
    graphModal: {
      icon: {
        color: 'dark',
      },
      title: {
        color: '#1D1D1B',
        font: 'normal 500 14px/16px inter',
      },
      subtitle: {
        color: '#606060',
        font: 'normal 500 12px/16px inter',
      },
      value: {
        font: 'normal 500 10px/12px inter',
        highlightedColor: '#1D1D1B',
        defaultColor: '#606060',
      },
      bar: {
        highlightedColor: '#27E7E2',
        defaultColor: '#E2E2E2',
        border: '1px solid #E2E2E2',
      },
      label: {
        font: 'normal 400 10px/12px inter',
        color: '#979797',
      },
      padding: '0px 50px',
      backgroundColor: '#ffffff',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    },
    red: '#ef2b2d',
    green: '#12d19c',
    black: '#000000',
  },
  listView: {
    content: {
      height: 'calc(100% - 45px)',
    },
    header: {
      default: {
        color: '#606060',
        font: 'normal 500 14px/20px inter',
      },
      selected: {
        color: '#1D1D1B',
        font: 'normal 700 14px/20px inter',
      },
      icon: 'dark',
    },
    item: {
      icon: 'dark',
      borderRadius: '8px',
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15);',
      hover: {
        border: '1px solid #1D1D1B',
        backgroundColor: '#ffffff',
      },
      selected: {
        border: '1px solid #1D1D1B',
        backgroundColor: '#F8F8F8',
      },
      disabled: {
        backgroundColor: '#E2E2E2',
      },
      loading: {
        borderRadius: '3px',
        border: '1px solid transparent',
        backgroundColor: 'rgba(168, 177, 196, 0.2)',
      },
      default: {
        border: '1px solid transparent',
        backgroundColor: '#ffffff',
      },
      font: 'normal 500 14px/16px inter',
      inLineAction: {
        backgroundColor: '#1d1d1b',
        hover: {
          backgroundColor: '#26E888',
        },
        selected: {
          backgroundColor: '#15C26D',
        },
      },
    },
    otherAction: {
      color: 'inpulse-outline',
    },
    dropdown: {
      borderRadius: '8px',
      default: {
        color: '#1D1D1B',
        backgroundColor: '#FFFFFF',
      },
      disabled: {
        color: '#E2E2E2',
        backgroundColor: '#ffffff',
      },
      highlighted: {
        color: '#ffffff',
        backgroundColor: '#1D1D1B',
      },
      hover: {
        color: '#1D1D1B',
        backgroundColor: '#F8F8F8',
      },
      tooltip: {
        color: '#FFFFFF',
        backgroundColor: '#979797',
        font: 'normal 500 12px/14px inter',
      },
      font: 'normal 500 14px/16px inter',
    },
    pagination: {
      icon: 'dark',
      color: '#606060',
      font: 'normal 500 10px/12px inter',
      backgroundColor: '#ffffff',
      switch: {
        boxShadow: 'none',
        borderRadius: '20px',
        backgroundColor: '#ffffff',
        border: '1px solid #E2E2E2',
      },
      dropdown: {
        boxShadow: 'none',
        borderRadius: '20px',
        backgroundColor: '#ffffff',
        border: '1px solid #E2E2E2',
        disabledBackgroundColor: '#F8F8F8',
        list: {
          borderRadius: '8px',
          backgroundColor: '#ffffff',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
        },
        default: {
          font: 'normal 500 10px/12px inter',
          color: '#606060',
        },
        selected: {
          font: 'normal 500 10px/12px inter',
          color: '#1D1D1B',
        },
      },
      boxShadow: '0px -3px 5px rgba(0, 0, 0, 0.05)',
    },
  },
  searchbar: {
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    font: 'normal 500 12px/16px inter',
    icon: {
      size: '16px',
    },
    default: {
      color: '#1D1D1B',
      backgroundColor: '#ffffff',
    },
    disabled: {
      color: '#FFFFFF',
      backgroundColor: '#E2E2E2',
    },
    placeholder: {
      color: '#979797',
    },
  },
  tableView: {
    fonts: {
      small: 'normal 500 12px/14px inter',
      micro: 'normal 500 10px/12px inter',
      normal: 'normal 500 14px/18px inter',
      bold: 'normal 700 14px/18px inter',
    },
    colors: {
      secondary: '#606060',
      primary: '#1D1D1B',
      selected: '#1D1D1B',
      highlighted: '#1D1D1B',
    },
    borderRadius: '8px',
    pagination: {
      icon: 'dark',
      color: '#606060',
      font: 'normal 500 10px/12px inter',
      switch: {
        boxShadow: 'none',
        borderRadius: '20px',
        backgroundColor: '#ffffff',
        border: '1px solid #E2E2E2',
      },
      dropdown: {
        boxShadow: 'none',
        borderRadius: '20px',
        backgroundColor: '#ffffff',
        border: '1px solid #E2E2E2',
        disabledBackgroundColor: '#F8F8F8',
        list: {
          borderRadius: '8px',
          backgroundColor: '#ffffff',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
        },
        default: {
          font: 'normal 500 10px/12px inter',
          color: '#606060',
        },
        selected: {
          font: 'normal 500 10px/12px inter',
          color: '#1D1D1B',
        },
      },
    },
  },
  label: {
    loading: {
      background: '#F8F8F8',
      color: '#979797',
    },
    padding: '4px 8px',
    text: {
      font: 'normal 600 12px/16px inter',
      colors: {
        white: '#FFFFFF',
        DMGrey: '#979797',
        LMGrey: '#E2E2E2',
      },
    },
    colors: {
      infoRed: '#FA393E',
      infoGreen: '#05E08E',
      DMGrey: '#979797',
      LMGrey: '#E2E2E2',
    },
    borders: {
      radius: '4px',
      width: '2px',
    },
    font: 'normal 600 12px/16px inter',
  },
  franco: {
    text: {
      font: 'normal 500 12px/16px inter',
      contentFont: 'normal 700 14px/16px inter',
    },
    colors: {
      white: '#FFFFFF',
      darker: '#606060',
      darkest: '#1D1D1B',
      orange: '#FF9432',
      infoRed: '#FA393E',
      infoGreen: '#05E08E',
    },
    border: '1px solid #E2E2E2',
  },
  buttons: {
    primary: {
      padding: '0px 24px',
      height: '40px',
      radius: '20px',
      text: '#FFFFFF',
      default: '#1D1D1B',
      hover: '#606060',
      pressed: '#26E888',
      disabled: '#E2E2E2',
      black: '#000000',
    },
    secondary: {
      radius: '20px',
      default: {
        border: '2px solid #1D1D1B',
        text: '#1D1D1B',
        background: '#FFFFFF',
      },
      hover: {
        border: '2px solid #606060',
        text: '#606060',
        background: '#FFFFFF',
      },
      pressed: {
        text: '#FFFFFF',
        background: '#1D1D1B',
      },
      disabled: {
        border: '2px solid #E2E2E2',
        text: '#E2E2E2',
        background: '#FFFFFF',
      },
    },
    tertiary: {
      radius: '22px',
      default: {
        text: '#1D1D1B',
        background: '#E2E2E2',
      },
      hover: {
        text: '#1D1D1B',
        background: '#979797',
      },
      pressed: {
        text: '#FFFFFF',
        background: '#5C5C5C',
      },
      disabled: {
        text: '#FFFFFF',
        background: '#E2E2E2',
      },
    },
    quaternary: {
      radius: '20px',
      default: {
        text: '#FFFFFF',
        background: '#1D1D1B',
      },
      hover: {
        text: '#FFFFFF',
        background: '#26E888',
      },
      pressed: {
        text: '#FFFFFF',
        background: '#15C26D',
      },
      disabled: {
        text: '#FFFFFF',
        background: '#E2E2E2',
      },
    },
    dropdown: {
      background: 'white',
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgb(0 0 0 / 16%)',
      font: 'normal 500 14px/16px inter',
      hover: {
        color: '#1d1d1b',
        background: '#F8F8F8',
      },
      default: {
        color: '#1d1d1b',
        background: 'white',
      },
      disabled: {
        color: '#979797',
        background: 'white',
      },
      highlighted: {
        color: '#ffffff',
        background: '#1D1D1B',
      },
    },
  },
  inputIncrement: {
    inputBackground: '#ffffff',
    borderStyleFocused: '1px solid #1d1d1b',
    borderRadius: '8px',
    font: 'normal 500 12px/16px inter',
    highlighted: {
      secondary: '#27E7E2',
      secondaryLight: '#CCF2F2',
      secondaryDark: '#17CFCA',
    },
    label: {
      fonts: {
        default: 'normal 500 14px/16px inter',
        disabled: 'normal 500 14px/16px inter',
      },
      colors: {
        required: '#fa393e',
        default: '#1D1D1B',
        disabled: '#979797',
      },
    },
    buttons: {
      default: {
        backgroundColor: '#1d1d1b',
      },
      hover: {
        backgroundColor: '#26E888',
      },
      active: {
        backgroundColor: '#15c26d',
      },
      focused: {
        backgroundColor: '#15C26D',
      },
      disabled: {
        backgroundColor: '#e2e2e2',
      },
    },
    placeholder: {
      default: '#979797',
      disabled: '#ffffff',
    },
  },
  toggleSwitch: {
    colors: {
      lightest: '#FFFFFF',
      hover: '#26E888',
      pressed: '#15C26D',
      disabled: '#E2E2E2',
      typeOff: {
        default: '#979797',
      },
      typeOn: {
        default: '#1D1D1B',
      },
    },
  },
  toggleButton: {
    border: '1px solid #ececec',
    backgroundColor: '#ececec',
  },
  dropdown: {
    borderRadius: '8px',
    font: 'normal 500 12px/20px inter',
    cursor: 'pointer',
    labelContainer: {
      font: 'normal 500 14px/20px inter',
      default: {
        color: '#1D1D1B',
      },
      disabled: {
        color: '#979797',
      },
    },
    labelRequiredCharacter: {
      default: {
        color: '#FA393E',
      },
      disabled: {
        color: '#979797',
      },
    },
    inputContainer: {
      height: '40px',
      default: {
        border: '1px solid #E2E2E2',
        backgroundColor: '#FFFFFF',
      },
      hover: {
        backgroundColor: '#F8F8F8',
      },
      disabled: {
        backgroundColor: '#E2E2E2',
      },
      focus: {
        border: '1px solid #1D1D1B',
      },
      error: {
        border: '1px solid #FA393E',
      },
    },
    text: {
      default: {
        color: '#1D1D1B',
      },
      disabled: {
        color: '#FFFFFF',
        value: {
          color: '#1D1D1B',
        },
      },
      placeholder: {
        color: '#979797',
      },
    },
    dropdownListContainer: {
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      maxHeight: '400px',
      width: '320px',
    },
    loadingDropdownListContainer: {
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
    },
    loadingDropdownItem: {
      borderRadius: '4px',
      backgroundColor: '#F8F8F8',
    },
    dropdownList: {
      default: {
        maxHeight: '360px',
      },
      searchAndMulti: {
        maxHeight: '320px',
      },
      searchAndButton: {
        maxHeight: '320px',
      },
      searchAndMultiAndButton: {
        maxHeight: '320px',
      },
    },
    dropdownItem: {
      minHeight: '40px',
      default: {
        color: '#1D1D1B',
        backgroundColor: '#FFFFFF',
      },
      hover: {
        backgroundColor: '#F8F8F8',
      },
      disabled: {
        color: '#E2E2E2',
        backgroundColor: '#FFFFFF',
      },
    },
    dropdownItemText: {
      default: {
        font: 'normal 500 12px/16px inter',
      },
      showMoreResult: {
        color: '#606060',
        loadingColor: '#E2E2E2',
      },
    },
    searchInputItem: {
      height: '40px',
      borderBottom: '1px solid #e2e2e2',
      default: {
        color: '#1D1D1B',
        backgroundColor: '#FFFFFF',
      },
      hover: {
        backgroundColor: '#F8F8F8',
      },
      loading: {
        backgroundColor: '#E5E5E5',
      },
    },
    searchInput: {
      default: {
        color: '#1D1D1B',
      },
      loading: {
        backgroundColor: '#E5E5E5',
        textColor: '#FFFFFF',
      },
    },
    multiselectValidationButton: {
      color: '#FFFFFF',
      fontWeight: '700',
      default: {
        backgroundColor: '#1D1D1B',
      },
      hover: {
        backgroundColor: '#26E888',
      },
      active: {
        backgroundColor: '#15C26D',
      },
      disabled: {
        backgroundColor: '#E2E2E2',
      },
      haveButton: {
        disabled: {
          borderTop: '1px solid #FFFFFF',
        },
      },
    },
    dropdownButton: {
      borderTop: '1px solid #e2e2e2',
      fontWeight: '700',
      default: {
        color: '#1D1D1B',
        backgroundColor: '#FFFFFF',
      },
      hover: {
        backgroundColor: '#F8F8F8',
      },
      active: {
        backgroundColor: '#F8F8F8',
      },
      disabled: {
        backgroundColor: '#E2E2E2',
        color: '#FFFFFF',
      },
    },
  },
  nestedList: {
    borderRadius: '8px',
    toggle: {
      selected: {
        background: '#222321',
      },
      loading: {
        border: '1px solid #e2e2e2',
      },
      default: {
        border: '1px solid #222321',
      },
      hover: {
        border: '1px solid #00EA84',
      },
      size: {
        width: '24px',
        height: '24px',
      },
    },
    header: {
      default: {
        color: '#606060',
        font: 'normal 500 14px/18px inter',
      },
      selected: {
        color: '#1D1D1B',
        font: 'normal 700 14px/18px inter',
      },
      icon: 'dark',
    },
    row: {
      font: 'normal 500 14px/16px inter',
      bold: {
        font: 'normal 700 14px/16px inter',
      },
      highlighted: {
        color: '#222321',
        font: 'normal 700 14px/16px inter',
      },
    },
    childRow: {
      font: 'normal 500 14px/28px inter',
      color: '#606060',
      link: {
        color: '#1D1D1B',
      },
      separator: {
        color: '#E6E6E6',
      },
      border: 'none',
      borderRadius: '8px',
      backgroundColor: '#FAFAFA',
    },
    graph: {
      background: '#F8F8F8',
    },
  },
  borders: {
    radius: {
      strong: '16px',
      stronger: '32px',
      weak: '8px',
      weaker: '4px',
    },
  },
  inputs: {
    primary: {
      default: {
        border: '1px solid #E2E2E2',
        borderRadius: '8px',
        height: '40px',
        padding: '0px 16px',
      },
      error: {
        border: '1px solid #ef2b2d',
        color: '#ef2b2d',
      },
      focus: {
        border: '1px solid #1D1D1B',
      },
    },
  },
  shadows: {
    short: '0 2px 6px 0 rgba(0, 0, 0, 0.1)',
  },
};
export const getTheme = (theme: any, key?: string): any => {
  const updatedTheme = _.merge({ ...defaultTheme }, theme);

  if (!key) {
    return updatedTheme;
  }

  return _.get(updatedTheme, key, {});
};

export default {
  getTheme,
};
