import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import React, { Component } from 'react';

import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';

import { getBreakagePropertyName } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import { activityAnalytics as activityAnalyticsService } from '@services/activityAnalytics';

import styled from 'styled-components';

export class StoresWithTurnoverRatio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      storesWithTurnover: [],
    };
  }

  getStoreIds = () => this.props.stores.map((store) => store.id);

  formatStoresWithTurnover = (storesWithTurnover) => {
    if (storesWithTurnover) {
      storesWithTurnover = sortArrayOfObjectsAlphabetically(storesWithTurnover, 'storeName');
      this.setState({ isLoading: false, storesWithTurnover });
    } else {
      this.setState({ isLoading: false, storesWithTurnover: [] });
    }
  };

  componentDidMount() {
    if (this.props.stores.length > 0) {
      this.setState({ isLoading: true });

      activityAnalyticsService
        .getStoresWithTurnoverRatio(
          this.getStoreIds(),
          moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'),
          moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD'),
        )
        .then((res) => this.formatStoresWithTurnover(res))
        .catch(() => {
          this.setState({ isLoading: false, storesWithTurnover: [] });
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.stores !== this.props.stores && this.props.stores.length > 0) {
      this.setState({ isLoading: true });

      activityAnalyticsService
        .getStoresWithTurnoverRatio(
          this.getStoreIds(),
          moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'),
          moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD'),
        )
        .then((res) => this.formatStoresWithTurnover(res))
        .catch(() => {
          this.setState({ isLoading: false, storesWithTurnover: [] });
        });
    }
  }

  renderStoreShare = (share) => {
    if (!share || share === null) {
      return <div className="activity-summary-stores-tab-growth-positive">-</div>;
    }
    share = this.roundPercentage(share);
    if (share >= 0) {
      return <div className="activity-summary-stores-tab-growth-positive">+{share} %</div>;
    } else {
      return <div className="activity-summary-stores-tab-growth-negative">{share} %</div>;
    }
  };

  roundPercentage = (number) => Math.round(number * 10000) / 100;

  render() {
    const {
      breakagePropertyName,
      client: { storeName },
    } = this.props;

    const { isLoading, storesWithTurnover } = this.state;

    return (
      <div className={this.props.className}>
        <div className="activity-summary-stores-header">
          {getClientStoreNameTranslation(storeName, true)}{' '}
          {storesWithTurnover?.length > 0 ? `(${storesWithTurnover.length})` : ''}
        </div>
        {isLoading ? (
          <div className="activity-summary-stores-empty-container">
            <div style={{ paddingTop: '100px' }}>
              <DeepsightComponentLoader />
            </div>
          </div>
        ) : (
          storesWithTurnover.length > 0 && (
            <>
              <div className="activity-summary-stores-tab-headers">
                <div className="activity-summary-stores-tab-header-left">
                  {getClientStoreNameTranslation(storeName)}
                </div>
                <div className="activity-summary-stores-tab-growth">
                  {this.props.t('HOME.LABEL_EVOLUTION')}
                </div>
                <div className="activity-summary-stores-tab-breakageRate">
                  {this.props.t('HOME.LABEL_LOSS_PERCENTAGE')}
                </div>
                <div className="activity-summary-stores-tab-production">
                  {this.props.t('HOME.LABEL_PRODUCTION_PERCENTAGE')}
                </div>
              </div>
              <div className="activity-summary-stores-tab">
                {storesWithTurnover.map((store, index) => (
                  <div className="activity-summary-stores-tab-row" key={index}>
                    <div className="activity-summary-stores-tab-row-left">{store.storeName}</div>
                    {this.renderStoreShare(store.growth || 0)}
                    <div className="activity-summary-stores-tab-breakageRate">
                      {(store[breakagePropertyName] || store[breakagePropertyName] === 0) &&
                      store[breakagePropertyName] !== null
                        ? `${this.roundPercentage(store[breakagePropertyName])}%`
                        : '-'}
                    </div>
                    <div className="activity-summary-stores-tab-production">
                      {(store.productionRate || store.productionRate === 0) &&
                      store.productionRate !== null
                        ? `${this.roundPercentage(store.productionRate)}%`
                        : '-'}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )
        )}
        {storesWithTurnover.length === 0 && (
          <div className="activity-summary-stores-empty-container">
            <div style={{ paddingTop: '100px' }}>{this.props.t('HOME.NO_DATA_TO_DISPLAY')}</div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stores: getSalesPointStores(state.baseReducer.activeStores),
  breakagePropertyName: getBreakagePropertyName(state.baseReducer.userRights),
  client: getClientInfo(state.baseReducer.user),
});

const Connected = connect(mapStateToProps, null)(withTranslation()(StoresWithTurnoverRatio));

const InpulseStoreComparison = styled(Connected)`
  background: ${(props) => props.theme.colors.greys.lightest};
  border-radius: ${(props) => props.theme.borders?.radius?.weak};
  margin-left: 30px;
  width: 33%;
  height: 100%;
  min-width: 325px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .activity-summary-stores-empty-container {
    ${(props) => (props.theme.fonts ? `font: ${props.theme.fonts?.textBig};` : undefined)}
    height: calc(100% - 40px);
    text-align: center;
  }

  .activity-summary-stores-header {
    font: ${(props) => props.theme.fonts?.h3};
    color: ${(props) => props.theme.colors.greys.darkest};
    width: 85%;
    padding-top: 20px;
  }

  .activity-summary-stores-tab-headers {
    display: flex;
    width: 85%;
    margin-top: 20px;
    font: ${(props) => props.theme.fonts?.textSmall};
    color: ${(props) => props.theme.colors.greys.dark};
    border-bottom: ${(props) => `1px solid ${props.theme.colors.greys.light}`};
  }

  .activity-summary-stores-tab-header-left {
    width: 49%;
  }

  .activity-summary-stores-tab-growth {
    width: 23%;
  }

  .activity-summary-stores-tab-growth-positive {
    width: 23%;
    color: ${(props) => props.theme.colors.brand.primaryDark};
  }

  .activity-summary-stores-tab-growth-negative {
    width: 23%;
    color: ${(props) => props.theme.colors.red};
  }

  .activity-summary-stores-tab-breakageRate {
    width: 17%;
  }

  .activity-summary-stores-tab {
    width: 85%;
    height: 100%;
    margin-bottom: 20px;
    overflow-y: scroll;
    font: ${(props) => props.theme.fonts?.textSmall};
  }

  .activity-summary-stores-tab::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  .activity-summary-stores-tab::-webkit-scrollbar-track-piece {
    background: transparent;
    width: 2px;
  }

  .activity-summary-stores-tab::-webkit-scrollbar-thumb {
    border-radius: ${(props) => props.theme.borders?.radius?.weak};
    background-color: rgba(0, 0, 0, 0.5);
  }

  .activity-summary-stores-tab-row {
    display: flex;
    margin-top: 10px;
  }

  .activity-summary-stores-tab-row-left {
    width: 50%;
  }
`;

const DeepsightStoreComparison = styled(Connected)`
  background: white;
  box-shadow: 0 2px 6px 0 rgba(168, 177, 196, 0.4);
  border-radius: 10px;
  margin-left: 30px;
  width: 30%;
  height: 300px;
  min-width: 325px;

  .activity-summary-stores-empty-container {
    height: calc(100% - 40px);
    text-align: center;
  }

  .activity-summary-stores-header {
    margin-left: 20px;
    padding-top: 20px;
  }

  .activity-summary-stores-tab-headers {
    display: flex;
    width: 85%;
    margin: auto;
    margin-top: 20px;
    font-size: 12px;
    color: #838d9f;
    font-size: 12px;
  }

  .activity-summary-stores-tab-header-left {
    width: 49%;
  }

  .activity-summary-stores-tab-growth {
    width: 23%;
  }

  .activity-summary-stores-tab-growth-positive {
    width: 23%;
    color: #44d7b6;
  }

  .activity-summary-stores-tab-growth-negative {
    width: 23%;
    color: #ef252a;
  }

  .activity-summary-stores-tab-breakageRate {
    width: 17%;
  }

  .activity-summary-stores-tab {
    width: 85%;
    height: 200px;
    margin: auto;
    overflow-y: scroll;
    font-size: 12px;
  }

  .activity-summary-stores-tab::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  .activity-summary-stores-tab::-webkit-scrollbar-track-piece {
    background: transparent;
    width: 2px;
  }

  .activity-summary-stores-tab::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .activity-summary-stores-tab-row {
    display: flex;
    margin-top: 10px;
  }

  .activity-summary-stores-tab-row-left {
    width: 50%;
  }
`;

export { InpulseStoreComparison, DeepsightStoreComparison };
