import _ from 'lodash';
import { useEffect, useRef, MouseEvent } from 'react';

import { Column } from './interfaces';
import { TYPE_FIELD } from './constants';

export function getPaginatedData(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[],
  currentPage: number,
  pageLimit: number,
  highligthedRowsId: string[]
): unknown[] {
  if (!data || !data.length) {
    return [];
  }

  const end = currentPage * pageLimit;
  const start = (currentPage - 1) * pageLimit;

  return data
    .filter((item) => !highligthedRowsId.includes(item.id))
    .slice(start, end);
}

export function sortListBy(
  data: unknown[],
  columns: Column[],
  orderBy: string,
  orderType: 'asc' | 'desc',
  highligthedRowsId: string[]
): unknown[] {
  const selectedColumn = columns.find((column) => column.id === orderBy);

  if (!selectedColumn) {
    return data;
  }

  if (
    selectedColumn.type === TYPE_FIELD.numeric.name ||
    selectedColumn.type === TYPE_FIELD.currency.name ||
    selectedColumn.type === TYPE_FIELD.percentage.name
  ) {
    return _.orderBy(
      data,
      [(item) => parseFloat(_.get(item, selectedColumn.propertyKey, '-1'))],
      [orderType]
    );
  }

  return _.orderBy(
    data,
    [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (item: any) =>
        highligthedRowsId.includes(item.id) ||
        _.get(item, selectedColumn.propertyKey, '').toString().toLowerCase(),
    ],
    [orderType]
  );
}

export const useOnClickOutside = (
  handler: (event: MouseEvent) => void,
  initialValue = null
): object => {
  const ref = useRef<HTMLDivElement>(initialValue);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current?.contains(event.target)) {
        handler(event);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handler]);

  return ref;
};

export default {
  sortListBy,
  getPaginatedData,
  useOnClickOutside,
};
