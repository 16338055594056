import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { closeGenericModal, openGenericModal, refreshGenericModal } from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { supplierProduct as supplierProductService } from '@services/supplierProduct';

import { convertSPPQuantityInMasterUnit } from '@orders/utils/computePackagingToSupplierProduct';

import { getIngredientUnit } from '@admin/products/ingredients/detail/components/IngredientInfo/common/inputs';

import {
  CREATE_INGREDIENT_MODAL_STEPS,
  getCreateIngredientModalConfig,
} from './modalConfiguration';

/**
 * This component does not directly render anything. Instead it makes use of the GenericModal via
 * the redux actions, manages their respective states and takes care of the ingredient creation logic.
 *
 * @param {*} props
 * @returns
 */
const CreateIngredientModal = (props) => {
  const {
    resetIngredientCreation,
    handleCallToCreateIngredient,
    openGenericModal,
    refreshGenericModal,
    user,
    showErrorMessage,
  } = props;

  const userLanguageCode = _.get(user, 'lnkLanguageAccountrel.code', 'fr');
  const clientId = _.get(user, 'clientId');

  const [currentStep, setCurrentStep] = useState(
    CREATE_INGREDIENT_MODAL_STEPS.INGREDIENT_INFORMATIONS,
  );

  const [ingredientInformationsData, setIngredientInformationsData] = useState({
    id: null,
    name: '',
    category: '',
    unit: '',
    supplierProducts: [],
    allergens: [],
    cost: null,
  });

  const [mapSupplierProductsData, setMapSupplierProductsData] = useState([]);

  const [supplierProductsToDisplay, setSupplierProductsToDisplay] = useState([]);

  const [hasStepChanged, setHasStepChanged] = useState(false);

  const [supplierProductsOfClient, setSupplierProductsOfClient] = useState(null); // null as first value to not make difference with first fetch

  const handleSupplierProductsToDisplay = (supplierProducts) => {
    const updatedSupplierProductsToDisplay = refreshSupplierProductsSelection(supplierProducts);
    setSupplierProductsToDisplay(updatedSupplierProductsToDisplay);
  };

  useEffect(() => {
    if (!currentStep) {
      return;
    }

    if (hasStepChanged) {
      handleSupplierProductsToDisplay(supplierProductsToDisplay);
    }

    setHasStepChanged(false);

    const isModalOpened = _.get(props, 'modal.GenericModalBool', false);

    const params = getCreateIngredientModalConfig({
      currentStep,
      setCurrentStep,
      resetModal: resetIngredientCreation,
      handleCallToCreateIngredient,
      ingredientInformationsData,
      setIngredientInformationsData,
      mapSupplierProductsData,
      userLanguageCode,
      handleSupplierProductsSelection,
      supplierProductsToDisplay,
    });

    if (isModalOpened) {
      refreshGenericModal(params);
      return;
    }

    openGenericModal(params);
  }, [currentStep, ingredientInformationsData, supplierProductsToDisplay]);

  useEffect(() => {
    setHasStepChanged(true);
  }, [currentStep]);

  // Load supplier products as first render to not have to reload it on each ingredient change
  // Use pageLoading / pageLoaded to not allow user to fill in form whereas data are not ready
  useEffect(() => {
    fetchSupplierProducts();
  }, []);

  // Update isNotSelectable property on SP list when ingredient unit changes
  useEffect(() => {
    // Has not been loaded yet, then early return
    if (!supplierProductsOfClient) {
      return;
    }

    updateSelectableList();
  }, [supplierProductsOfClient, ingredientInformationsData.unit]);

  const handleSupplierProductsSelection = (selectedSupplierProducts) => {
    setMapSupplierProductsData(selectedSupplierProducts);

    if (selectedSupplierProducts.length === 0) {
      setIngredientInformationsData({ ...ingredientInformationsData, cost: 0 });
    }
  };

  const refreshSupplierProductsSelection = (supplierProducts) => {
    const mapSupplierProductsDataByIds = _.keyBy(mapSupplierProductsData, 'id');

    const updatedSupplierProducts = supplierProducts.map((supplierProduct) => {
      if (
        !_.isEmpty(mapSupplierProductsDataByIds) &&
        !!mapSupplierProductsDataByIds[supplierProduct.id] &&
        !supplierProduct.isNotSelectable
      ) {
        return { ...supplierProduct, isRowSelected: true };
      }

      return { ...supplierProduct, isRowSelected: false };
    });

    return updatedSupplierProducts;
  };

  useEffect(() => {
    handleSupplierProductsToDisplay(supplierProductsToDisplay);
  }, [mapSupplierProductsData.length]);

  const updateSelectableList = () => {
    const list = supplierProductsOfClient.map((supplierProduct) => {
      const masterSPP = _.head(supplierProduct.packagings);

      const isNotSelectable =
        ingredientInformationsData.unit === 'unit'
          ? masterSPP.unit !== ingredientInformationsData.unit
          : masterSPP.unit !== getIngredientUnit(ingredientInformationsData.unit);

      return {
        ...supplierProduct,
        isNotSelectable,
      };
    });

    setSupplierProductsToDisplay(refreshSupplierProductsSelection(list));
  };

  const fetchSupplierProducts = async () => {
    props.pageLoading();

    try {
      const groupedSupplierProducts =
        await supplierProductService.getSupplierProductsGroupedByProperty(clientId, null, true);

      const supplierProducts = _.get(groupedSupplierProducts, 'supplierProducts');

      const activeSupplierProducts = supplierProducts.filter(
        (supplierProduct) => !!supplierProduct.active,
      );

      const formattedActiveSP = activeSupplierProducts.map((supplierProduct) => {
        const masterSPP = _.head(supplierProduct.packagings);

        const invoicePackaging = supplierProduct.packagings.find(
          ({ isUsedInInvoice }) => isUsedInInvoice,
        );
        const convertedPrice =
          supplierProduct.price /
          convertSPPQuantityInMasterUnit(invoicePackaging.id, supplierProduct.packagings);

        return {
          ...supplierProduct,
          packagingUnit: masterSPP.unit,
          supplierName: _.get(supplierProduct, 'supplier.name', ''),
          convertedPrice,
        };
      });

      setSupplierProductsOfClient(formattedActiveSP);

      setMapSupplierProductsData([]);
      setIngredientInformationsData({ ...ingredientInformationsData, cost: null });
    } catch (err) {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FETCH_ERROR'));
    } finally {
      props.pageLoaded();
    }
  };

  return <div />;
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  modal: state.modalReducer,
});

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

CreateIngredientModal.propTypes = {
  resetIngredientCreation: PropTypes.func,
  openGenericModal: PropTypes.func, // passed by redux
  closeGenericModal: PropTypes.func, // passed by redux
  refreshGenericModal: PropTypes.func, // passed by redux
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateIngredientModal);
