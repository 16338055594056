import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { ListView, Label } from '@commons/utils/styledLibraryComponents';

import { getClientInfo } from '@selectors/client';

import theme from '@theme';

import { Container } from './styledComponents';

const getColumns = (storeNameTranslation, hasMultipleBrands) => [
  {
    id: 'storeName',
    name: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
    render: (it) => <div>{it}</div>,
  },
  ...(hasMultipleBrands
    ? [
        {
          id: 'brandName',
          name: i18next.t('GENERAL.BRAND'),
          propertyKey: 'brandName',
          render: (it) => <div>{_.isEmpty(it) ? '---' : it}</div>,
        },
      ]
    : []),
  {
    id: 'mappedCashierStores',
    name: i18next.t('BACKOFFICE.DATA.ASSOCIATED_CASHIERS', {
      storeName: storeNameTranslation,
    }),
    propertyKey: 'mappedCashierStores',
    excludeFromSearch: true,
    disableSort: true,
    render: (it) => (
      <Label
        background={theme.colors.greys.darkest}
        color={theme.colors.greys.darkest}
        type="tertiary"
        width={'fit-content'}
      >
        {it && it.length}
      </Label>
    ),
  },
];

const StoreSelectionModal = (props) => {
  const {
    client: { hasMultipleBrands, storeName },
    stores,
    handleStoresSelection,
    isLoading,
  } = props;

  const [storesToDisplay, setStoresToDisplay] = useState(stores);
  const storeNameTranslation = getClientStoreNameTranslation(storeName, false);

  const columnsConfig = getColumns(storeNameTranslation, hasMultipleBrands);

  useEffect(() => {
    setStoresToDisplay(stores);
  }, [stores]);

  return (
    <Container>
      <ListView
        columns={columnsConfig}
        data={storesToDisplay}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        disableFooter={true}
        forceEnableSelection={true}
        isLoading={isLoading}
        languageCode="fr"
        padding={'0px'}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        setSelectedItems={handleStoresSelection}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

StoreSelectionModal.propTypes = {
  handleStoresSelection: PropTypes.func.isRequired,
  stores: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

StoreSelectionModal.defaultProps = {
  hasMultipleBrands: false,
  stores: [],
  isLoading: false,
};

export default connect(mapStateToProps)(StoreSelectionModal);
