import i18next from 'i18next';
import moment from 'moment';
import React from 'react';

import {
  EdiStateContainer,
  IconContainer,
  SubtitleContainer,
  TitleContainer,
} from './styledComponents';

const EDI_SEND_ICON = '/images/inpulse/send-edi-state.svg';

// CODE TO REMOVE ON 2024-04-07 - https://deepsightprod.atlassian.net/browse/IPP-6855
const TGT_MAINTENANCE_WARNING = (props) => {
  const { ediPartner } = props;

  const displayWarningTgtMaintenance =
    ediPartner === 'TGT' && !moment().isAfter(moment('2024/04/07').endOf('day'));

  if (!displayWarningTgtMaintenance) {
    return <></>;
  }

  return (
    <>
      <SubtitleContainer warning>
        {
          'Passage de commande impossible auprès du fournisseur Transgourmet le dimanche 07 avril de 8h00 à 11h00 pour cause de maintenance de leur système.'
        }
      </SubtitleContainer>
    </>
  );
};

export const EdiSummaryPanel = (props) => (
  <EdiStateContainer>
    <IconContainer>
      <img alt="edi-icon" src={EDI_SEND_ICON} />
    </IconContainer>
    <TitleContainer>{i18next.t('ORDERS.ORDERS.SUMMARY_SEND_EDI_STATE_TITLE')}</TitleContainer>
    <SubtitleContainer>
      {i18next.t('ORDERS.ORDERS.SUMMARY_SEND_EDI_STATE_SUBTITLE')}
    </SubtitleContainer>
    <TGT_MAINTENANCE_WARNING {...props} />
  </EdiStateContainer>
);
export default EdiSummaryPanel;
