const SEPARATOR = ',';

const getEmailListFromString = (string) => {
  if (!string || !string.length) {
    return [];
  }

  if (Array.isArray(string)) {
    return string.map((item) => item.trim());
  }

  const array = string.split(SEPARATOR);

  return array.map((item) => item.trim());
};

export default getEmailListFromString;
