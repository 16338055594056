import { get } from 'lodash';

import { fetchUserRightsError, receiveUserRights, requestUserRights } from '../../actions/user';
import { user } from '../../services/user';

const REGEX_PATH_HOME = /^\/home/g;
const REGEX_PATH_USER = /^\/users/g;
const REGEX_PATH_ADMIN = /^\/admin/g;
const REGEX_PATH_STOCK = /^\/stocks/g;
const REGEX_PATH_CENTRAL_KITCHEN_STOCKS = /^\/central-kitchen-stocks/g;
const REGEX_PATH_LOSS = /^\/losses/g;
const REGEX_PATH_ORDER = /\/order\/\w+/g;
const REGEX_PATH_CENTRAL_KITCHEN_ORDER = /^\/central-kitchen-order/g;
const REGEX_PATH_CENTRAL_KITCHEN_PRODUCTION = /^\/central-kitchen-production/g;
const REGEX_PATH_PRODUCTION = /\/production\/\w+/g;
const REGEX_PATH_STORE_DETAIL = /\/admin\/stores\//g;
const REGEX_PATH_FORECAST = /\/forecast(\/\w+){0,}/g;
const REGEX_PATH_RECIPE_DETAIL = /\/admin\/recipes\//g;
const REGEX_PATH_KITCHEN_RECIPE_DETAIL = /\/admin\/kitchen-products\/recipes\//g;
const REGEX_PATH_BACKOFFICE = /\/backoffice(\/\w+){0,}/g;
const REGEX_PATH_PRODUCT_DETAIL = /\/admin\/products\//g;
const REGEX_PATH_SUPPLIER_DETAIL = /\/admin\/suppliers\//g;
const REGEX_PATH_SUPPLIER_PRODUCT_DETAIL = /\/admin\/suppliers\/supplier-products\//g;
const REGEX_PATH_INGREDIENT_DETAIL = /\/admin\/ingredients\//g;
const REGEX_PATH_CASHIER_STORE_DETAIL = /\/backoffice\/stores\/cashier-stores\//g;
const REGEX_PATH_BACKOFFICE_STORE_DETAIL = /\/backoffice\/stores\/stores\//g;
const REGEX_PATH_KITCHEN_PRODUCT_DETAIL = /\/admin\/kitchen-products\//g;
const REGEX_PATH_CENTRAL_KITCHEN_STOCKS_RECIPE_DETAILS =
  /^\/central-kitchen-stocks\/inventories\/recipes\//g;
const REGEX_PATH_MARGIN_ANALYTICS = /\/margin(\/\w+){0,}/g;

export const ENUM_REGEX_PATHS_FEATURE = {
  USER: REGEX_PATH_USER,
  HOME: REGEX_PATH_HOME,
  STOCK: REGEX_PATH_STOCK,
  LOSS: REGEX_PATH_LOSS,
  ADMIN: REGEX_PATH_ADMIN,
  ORDER: REGEX_PATH_ORDER,
  CENTRAL_KITCHEN_ORDER: REGEX_PATH_CENTRAL_KITCHEN_ORDER,
  CENTRAL_KITCHEN_PRODUCTION: REGEX_PATH_CENTRAL_KITCHEN_PRODUCTION,
  FORECAST: REGEX_PATH_FORECAST,
  PRODUCTION: REGEX_PATH_PRODUCTION,
  BACKOFFICE: REGEX_PATH_BACKOFFICE,
  STORE_DETAIL: REGEX_PATH_STORE_DETAIL,
  RECIPE_DETAIL: REGEX_PATH_RECIPE_DETAIL,
  KITCHEN_RECIPE_DETAIL: REGEX_PATH_KITCHEN_RECIPE_DETAIL,
  PRODUCT_DETAIL: REGEX_PATH_PRODUCT_DETAIL,
  SUPPLIER_DETAIL: REGEX_PATH_SUPPLIER_DETAIL,
  SUPPLIER_PRODUCT_DETAIL: REGEX_PATH_SUPPLIER_PRODUCT_DETAIL,
  INGREDIENT_DETAIL: REGEX_PATH_INGREDIENT_DETAIL,
  CASHIER_STORE_DETAIL: REGEX_PATH_CASHIER_STORE_DETAIL,
  BACKOFFICE_STORE_DETAIL: REGEX_PATH_BACKOFFICE_STORE_DETAIL,
  KITCHEN_PRODUCT_DETAILS: REGEX_PATH_KITCHEN_PRODUCT_DETAIL,
  CENTRAL_KITCHEN_STOCKS: REGEX_PATH_CENTRAL_KITCHEN_STOCKS,
  CENTRAL_KITCHEN_STOCKS_RECIPE_DETAILS: REGEX_PATH_CENTRAL_KITCHEN_STOCKS_RECIPE_DETAILS,
  MARGIN_ANALYTICS: REGEX_PATH_MARGIN_ANALYTICS,
};

export const ENUM_MODULE_NAME = {
  USER: 'USER',
  HOME: 'HOME',
  STOCK: 'STOCK',
  LOSS: 'LOSS',
  ADMIN: 'ADMIN',
  ORDER: 'ORDER',
  CENTRAL_KITCHEN_ORDER: 'CENTRAL_KITCHEN_ORDER',
  CENTRAL_KITCHEN_PRODUCTION: 'CENTRAL_KITCHEN_PRODUCTION',
  PRODUCTION: 'PRODUCTION',
  BACKOFFICE: 'BACKOFFICE',
  STORE_DETAIL: 'STORE_DETAIL',
  RECIPE_DETAIL: 'RECIPE_DETAIL',
  KITCHEN_RECIPE_DETAIL: 'KITCHEN_RECIPE_DETAIL',
  PRODUCT_DETAIL: 'PRODUCT_DETAIL',
  SUPPLIER_DETAIL: 'SUPPLIER_DETAIL',
  SUPPLIER_PRODUCT_DETAIL: 'SUPPLIER_PRODUCT_DETAIL',
  INGREDIENT_DETAIL: 'INGREDIENT_DETAIL',
  CASHIER_STORE_DETAIL: 'CASHIER_STORE_DETAIL',
  BACKOFFICE_STORE_DETAIL: 'BACKOFFICE_STORE_DETAIL',
  CENTRAL_KITCHEN_STOCKS: 'CENTRAL_KITCHEN_STOCKS',
  KITCHEN_PRODUCT_DETAILS: 'KITCHEN_PRODUCT_DETAILS',
  CENTRAL_KITCHEN_STOCKS_RECIPE_DETAILS: 'CENTRAL_KITCHEN_STOCKS_RECIPE_DETAILS',
  MARGIN_ANALYTICS: 'MARGIN_ANALYTICS',
};

export const FEATURES_URL_BY_MODULE_NAME = {
  [ENUM_MODULE_NAME.ORDER]: [{ path: '/order/orders' }],
};

export const getFeatureNameFromPath = (path) => {
  if (!path) {
    return null;
  }

  return Object.keys(ENUM_REGEX_PATHS_FEATURE).find((feature) =>
    path.match(ENUM_REGEX_PATHS_FEATURE[feature]),
  );
};

export const isUserAllowedToAccessProduction = (userRights) =>
  userRights.some((right) => right.path.includes('/production'));

export function getFeaturesByPath(features, featureName) {
  if (!features || !ENUM_REGEX_PATHS_FEATURE[featureName]) {
    return [];
  }
  return features.filter((feature) => feature.path.match(ENUM_REGEX_PATHS_FEATURE[featureName]));
}

export function getFeaturePropsByFeaturePath(userRights, featurePath) {
  if (!userRights) {
    return {};
  }

  const matchingFeature = userRights.find((feature) => feature.path === featurePath);

  return get(matchingFeature, 'props', {});
}

export const fetchUserRights = (currentUserId, hasForecasts) => async (dispatch) => {
  dispatch(requestUserRights());
  try {
    const userRights = await user.getUserRights(currentUserId);

    dispatch(receiveUserRights(userRights));
  } catch (error) {
    dispatch(fetchUserRightsError(error));
  }
};

export default {
  ENUM_MODULE_NAME,
  ENUM_REGEX_PATHS_FEATURE,
  isUserAllowedToAccessProduction,
  getFeaturePropsByFeaturePath,
  getFeaturesByPath,
};
