import styled from 'styled-components';

export const ActionFrame = styled.div`
  min-height: 50px;
  margin-top: 5px;
  width: fit-content;
  padding: 15px 35px 15px 15px;

  border-radius: 2px;
  background: ${(props) => props.theme.colors.greys.lightest};
  box-sizing: border-box;

  color: ${(props) => props.theme.colors.greys.darkest};

  &.search-bar {
    margin-top: 0px;
    padding: 4px 0px;
  }

  &.no-top-border {
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  &:hover {
    cursor: pointer;
  }

  > .input-dropdown-container {
    margin-top: 0px !important;

    .input-dropdown {
      margin-bottom: 0px;
      border: none;
    }

    .dropdown-result-result-subtitle {
      margin-top: 5px;
    }

    .input-search-dropdown-result {
      margin-top: 45px;
      margin-left: -1px;
      width: calc(100% + 2px);
    }

    .search-dropdown-input {
      color: #000000;
      font-size: 13px;

      &::placeholder {
        color: #6b768c;
        font-size: 13px;
        line-height: 13px;
      }
    }
  }

  > img {
    width: 10px;
    margin-right: 10px;
  }
`;
