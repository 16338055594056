import React from 'react';

import { HeaderContainer } from './styledComponents';

export const FullScreenModalHeader = (props) => <HeaderContainer>{props.children}</HeaderContainer>;

// For easy import
import FullScreenModalHeaderButtons from './HeaderButtons';
import FullScreenModalHeaderInfos from './HeaderInfo';
import FullScreenModalHeaderSelectors from './HeaderSelectors';

export { FullScreenModalHeaderButtons, FullScreenModalHeaderInfos, FullScreenModalHeaderSelectors };
