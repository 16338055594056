import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${(props) => props.theme.colors?.greys?.lighter};
`;

export const ListViewContainer = styled.div`
  height: calc(100% - 40px);
`;

export default {
  Container,
};
