import { head } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { InpulseLabel } from '@commons/DeepsightComponents';
import { Tags } from '@commons/Tags';
import DisplayNumber, { formatNumber } from '@commons/DisplayNumber';

import {
  LabelContainer,
  Text,
  UpcomingChangeContainer,
  CalendarIcon,
} from '../components/styledComponents';

const renderLabel = (item) => (
  <LabelContainer>
    <Text>{item}</Text>
  </LabelContainer>
);

const renderSchedulePriceTooltip = (tooltipId, scheduledPrice, currency) => {
  const date = moment(scheduledPrice.startDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);
  const formattedScheduledPrice = formatNumber(scheduledPrice.price, 2);
  const price = `${formattedScheduledPrice} ${currency.alphabeticCode}`;
  const tooltipText = i18next.t('ADMIN.SUPPLIER_PRODUCTS.NEXT_SSPM_SCHEDULED_PRICE', {
    date,
    price,
  });

  return (
    <>
      <UpcomingChangeContainer data-for={tooltipId} data-tip={tooltipText}>
        <CalendarIcon src={'/images/inpulse/calendar-white-small.svg'} />
      </UpcomingChangeContainer>
      <ReactTooltip
        backgroundColor={'black'}
        className={tooltipId}
        effect={'solid'}
        html={true}
        id={tooltipId}
      />
    </>
  );
};

const renderScheduledPriceColumnValue = (item, row, currency) => {
  const hasScheduledPrice = !!row.scheduledPrice;
  const tooltipId = `info-tooltip-${row.scheduledPrice?.id}`;

  const supplierCurrency = row.currency || currency;

  return (
    <LabelContainer tooltipId={tooltipId}>
      <DisplayNumber currency={supplierCurrency} displayCurrencyCode={true} number={item} />
      {hasScheduledPrice &&
        renderSchedulePriceTooltip(tooltipId, row.scheduledPrice, supplierCurrency)}
    </LabelContainer>
  );
};

const renderAvailabilityLabel = (item, row, storeNames) => {
  let color = item === row.SSPMCount ? 'green' : 'orange';

  if (item === 0 && row.SSPMCount > 0) {
    color = 'red';
  }

  const nextAvailabilities = row.nextAvailabilities || [];
  const nextAvailability = head(nextAvailabilities);

  const areAllRowsEqual = nextAvailabilities.every(
    (sspm) =>
      sspm.available === nextAvailability.available &&
      moment(sspm.startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY) ===
        moment(nextAvailability.startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  );

  const nextAvailabilitiesDate = !!nextAvailability
    ? moment(nextAvailability.startDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)
    : null;

  const nextAvailabilitiesAvailableValue = !!nextAvailability
    ? nextAvailability.available.toString()
    : '';

  const tooltipText =
    areAllRowsEqual && !!nextAvailability && row.SSPMCount === nextAvailabilities.length
      ? i18next.t(
          `ADMIN.SUPPLIER_PRODUCTS.NEXT_SSPM_AVAILABILITY_${nextAvailabilitiesAvailableValue.toUpperCase()}_FOR_SAME_DATE`,
          { date: nextAvailabilitiesDate, storeNames },
        )
      : i18next.t('ADMIN.SUPPLIER_PRODUCTS.NEXT_SSPM_AVAILABILITY_FOR_DIFFERENT_DATE', {
          storeNames,
        });

  const tooltipId = `info-tooltip-${row.id}`;

  return (
    <LabelContainer tooltipId={tooltipId}>
      <InpulseLabel color={color} text={`${item}/${row.SSPMCount}`} />
      {!!nextAvailability && (
        <>
          <UpcomingChangeContainer data-for={tooltipId} data-tip={tooltipText}>
            <CalendarIcon src={'/images/inpulse/calendar-white-small.svg'} />
          </UpcomingChangeContainer>
          <ReactTooltip
            backgroundColor={'black'}
            className={tooltipId}
            effect={'solid'}
            id={tooltipId}
          />
        </>
      )}
    </LabelContainer>
  );
};

const get = (hasMultipleBrands, formattedPluralStoreName, currency) => {
  const columns = [
    {
      id: 'name',
      propertyKey: 'name',
      name: i18next.t('GENERAL.NAME'),
      render: (it) => <Text highlighted>{it}</Text>,
    },
    {
      id: 'sku',
      hidden: true,
      propertyKey: 'sku',
      name: 'SKU',
      render: renderLabel,
    },
    {
      id: 'supplierName',
      propertyKey: 'supplierName',
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_SUPPLIER'),
      render: (it) => <Text>{it}</Text>,
    },
  ];

  if (hasMultipleBrands) {
    columns.push({
      id: 'brands',
      disableSort: true,
      propertyKey: 'brands',
      name: i18next.t('GENERAL.BRAND_PLURAL'),
      tooltipText: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_BRANDS_TOOLTIP'),
      render: (items) => {
        const names = items.map(({ name }) => name);

        return <Tags items={names} />;
      },
    });
  }

  columns.push(
    {
      id: 'entityName',
      propertyKey: 'entityName',
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_INGREDIENT'),
      filterType: 'string',
      render: (it) => <Text>{it}</Text>,
    },
    {
      id: 'category',
      propertyKey: 'category',
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_CATEGORY'),
      filterType: 'string',
      render: (it) => <Text>{it}</Text>,
    },
    {
      id: 'subCategory',
      propertyKey: 'subCategory',
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_SUB_CATEGORY'),
      filterType: 'string',
      render: (it) => <Text>{it}</Text>,
    },
    {
      id: 'price',
      disableSort: true,
      propertyKey: 'price',
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_PRICE'),
      filterType: 'numeric',
      render: (item, row) => renderScheduledPriceColumnValue(item, row, currency),
    },
    {
      id: 'SSPMOrderCount',
      disableSort: true,
      propertyKey: 'SSPMOrderCount',
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.AVAILABILITY_COLUMN_NAME'),
      tooltipText: i18next.t('ADMIN.SUPPLIER_PRODUCTS.AVAILABILITY_COLUMN_TOOLTIP'),
      filterType: 'numeric',
      render: (item, row) => renderAvailabilityLabel(item, row, formattedPluralStoreName),
    },
    {
      id: 'priceEditableAtReception',
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.PRICE_EDITABLE_COLUMN_NAME'),
      propertyKey: 'priceEditableAtReception',
      filterType: 'boolean',
      hidden: true,
    },
    {
      id: 'inventoryListTemplate',
      propertyKey: 'inventoryListTemplate',
      name: i18next.t('GENERAL.INVENTORY_TEMPLATES'),
      filterType: 'string',
      hidden: true,
    },
    {
      id: 'storageArea',
      propertyKey: 'storageArea',
      name: i18next.t('FEATURE.ADMIN.STORAGE_AREAS'),
      filterType: 'string',
      hidden: true,
    },
  );

  return columns;
};

export default { get };
