import { COUNTRY_FRANCE_TYPO, ISO_FRANCE } from '../constants/country';

import { getCountry } from 'countries-and-timezones';

const convertCountry = (country) => {
  if (COUNTRY_FRANCE_TYPO.includes(country)) {
    return ISO_FRANCE;
  }

  return country;
};

export const getIsoCountryCode = (country) => {
  if (country.length === 2) {
    return country;
  }

  const countryData = getCountry(convertCountry(country));

  return countryData.id;
};

export default {
  getIsoCountryCode,
};
