import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  width: 100%;
  padding: 30px 25px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(82, 134, 245, 0.1);
  -webkit-box-shadow: 0 2px 6px 0 rgba(82, 134, 245, 0.1);
  background: linear-gradient(270deg, #1ab3ec 0%, #0a35d6 98.83%);

  ${(props) =>
    props.white &&
    css`
      background: #ffffff;
    `}

  ${(props) =>
    props.noShadow &&
    css`
      box-shadow: none;
    `}
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 400;
  color: white;
  font-style: normal;

  ${(props) =>
    props.black &&
    css`
      color: #000000;
    `}

  ${(props) =>
    props.medium &&
    css`
      font-size: 16px;
      line-height: 20px;
    `}
`;

/* export const ContentProgressBar = styled.div`
  padding: 40px 40px 20px 40px;

  .progress {
    background: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }

  .progress-bar {
    background: linear-gradient(269.8deg, #1ab3ec -13.83%, #0a3eff 100.6%);
  }
`; */

export const ContentDateSelection = styled.div`
  padding: 40px;

  text-align: center;

  color: #000000;
  font-size: 14px;
  line-height: 24px;
`;

export const Footer = styled.div`
  display: flex;
  padding-bottom: 20px;
  justify-content: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  ${(props) =>
    props.shadow &&
    css`
      box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.05);
    `}

  ${(props) =>
    props.alignLeft &&
    css`
      justify-content: left;
    `}
`;

export const DatePicker = styled.div`
  flex: 1;

  height: ${(props) => (props.theme.inputs ? '40px' : '36px')};
  min-width: 200px;
  max-width: 250px;

  margin: 20px auto 0px auto;

  color: #6b768c;

  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

  .DateRangePicker_picker {
    top: 40px !important;
    left: -190px !important;
  }

  > div {
    display: flex;
    align-items: center;

    height: 36px;
    width: calc(100% - 40px);

    > div {
      width: 100%;

      > div {
        width: 100%;
        display: flex;

        .DateInput_1 {
          flex: 1;
        }
      }
    }
  }

  border: ${(props) =>
    props.focusedDateInput
      ? props.theme.inputs?.primary.focus.border
      : props.theme.inputs?.primary.default.border};
`;
