import i18next from 'i18next';

import utilsXLS from '@commons/utils/makeXLS';

const FILENAME = 'Produits caisse';
const SHEET_NAME = 'Produits caisse';

const COLUMNS_SETTINGS = [
  {
    propertyKey: 'name',
    name: i18next.t('GENERAL.NAME'),
  },
  {
    propertyKey: 'cashierName',
    name: i18next.t('ADMIN.CASHIER_PRODUCTS.CASHIER'),
  },
  {
    propertyKey: 'created_at',
    name: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_DATE'),
  },
  {
    propertyKey: 'tags',
    name: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_TAGS'),
  },
  {
    propertyKey: 'updated_at',
    name: i18next.t('ADMIN.CASHIER_PRODUCTS.LAST_MODIFICATION'),
  },
  {
    propertyKey: 'sku',
    name: i18next.t('GENERAL.SKU'),
  },
  {
    type: 'currency',
    propertyKey: 'price_with_taxes',
    name: i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_LIST_COLUMN_PRICE'),
  },
  {
    propertyKey: 'vat_rate',
    name: i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_LIST_COLUMN_VAT_RATE'),
  },
  {
    propertyKey: 'mapped',
    name: i18next.t('GENERAL.LINKED'),
  },
  {
    propertyKey: 'associated_sku',
    name: i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATED_SKU'),
  },
  {
    propertyKey: 'active',
    name: i18next.t('GENERAL.ACTIVE'),
  },
  {
    propertyKey: 'hidden',
    name: i18next.t('ADMIN.CASHIER_PRODUCTS.EXCLUDED'),
  },
  {
    propertyKey: 'cashier_connection',
    name: i18next.t('GENERAL.CASHIER_CONNEXION'),
  },
  {
    propertyKey: 'sold_at',
    name: i18next.t('ADMIN.CASHIER_PRODUCTS.LAST_SALE'),
  },
];

export const exportCashierProducts = (props) => {
  const { selectedCashierProducts, currency } = props;

  const formattedCashierProducts = selectedCashierProducts.map((cashierProduct) => ({
    ...cashierProduct,
    mapped: !!cashierProduct.product_cashier_product_mapping_id ? 'Oui' : 'Non',
    active: !!cashierProduct.active ? 'Oui' : 'Non',
    hidden: !!cashierProduct.hidden ? 'Oui' : 'Non',
  }));

  const sheet = utilsXLS.generateDefaultSheet(
    SHEET_NAME,
    COLUMNS_SETTINGS,
    formattedCashierProducts,
    currency,
  );

  utilsXLS.makeXLS(FILENAME, [sheet]);
};
