import i18next from 'i18next';
import React from 'react';

import { showMessage } from '@actions/messageconfirmation';

import InputContentModal from '@commons/Modals/InputContentModal';

const getParamsForKey = (key) => {
  switch (key) {
    case 'customerCode': {
      return {
        title: i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_MANAGE_CLIENT_CODE'),
        label: i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_CUSTOMER_CODE_LABEL_INPUT'),
        closeContent: i18next.t(
          'ADMIN.SUPPLIERS.ASSOCIATED_STORES_CUSTOMER_CODE_CLOSE_CONFIRMATION_CONTENT',
        ),
      };
    }
    case 'warehouseCode': {
      return {
        title: i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_MANAGE_WAREHOUSE_CODE'),
        label: i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_WAREHOUSE_CODE_LABEL_INPUT'),
        closeContent: i18next.t(
          'ADMIN.SUPPLIERS.ASSOCIATED_STORES_WAREHOUSE_CODE_CLOSE_CONFIRMATION_CONTENT',
        ),
      };
    }
    default:
      return;
  }
};

export const getAssociatedStoreCodeParams = (
  associatedStore,
  handleUpdateAssociateStoreCode,
  handleUpdateAssociatedStore,
  codeValue,
  key,
) => {
  if (!key) {
    showMessage(i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_MISSING_KEY_CODE'), 'error');
    return;
  }
  const paramsForKey = getParamsForKey(key);

  return {
    type: 'action',
    width: '542px',
    maxHeight: '560px',
    height: 'auto',
    icon: '/images/inpulse/gear-black-small.svg',
    title: paramsForKey.title,
    component: InputContentModal,
    data: {
      value: codeValue || '',
      label: paramsForKey.label,
      onChange: (value) =>
        handleUpdateAssociateStoreCode(value, associatedStore.storeSupplierProfileMappingId, key),
    },
    closeConfig: {
      title: i18next.t('GENERAL.LEAVE'),
      content: <div>{paramsForKey.closeContent}</div>,
    },
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.SAVE'),
        icon: '/images/inpulse/save-white-small.svg',
        isDisabled: associatedStore[key] === codeValue || (!associatedStore[key] && !codeValue),
        handleClick: () =>
          handleUpdateAssociatedStore(
            codeValue,
            associatedStore.storeSupplierProfileMappingId,
            key,
          ),
      },
    ],
  };
};

export default {
  getAssociatedStoreCodeParams,
};
