import { connect } from 'react-redux';
import { get } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

// ACTIONS
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// COMMONS
import { buildSchema } from '@commons/GenericForm';
import { getTheme } from '@commons/utils/theme';
import Footer from '@commons/Footer/Footer';
import NavigationBar from '@commons/NavigationBar';
import Text, { ENUM_FONTS } from '@commons/Text';

// SELECTORS
import { canSeeAllCatalogs } from '@selectors/user';
import { getClientInfo } from '@selectors/client';

// SERVICES
import catalogService from '@services/catalogs';
import clientService from '@services/client';

import navigationUtils from '../../utils/navigation.utils';

import {
  INVENTORY_LIST_TEMPLATE_FORM_INPUTS,
  FIELDS_TO_CHECK,
} from '../utils/formInputsConfigurations';
import InventoryListTemplateInformations from '../inventoryListTemplateInformations';
import InventoryListTemplateMappings from '../inventoryListTemplateMappings';
import InventoryListTemplateRecipeMappings from '../inventoryListTemplateMappings/recipeMappings';

import {
  ButtonsContainer,
  ContentContainer,
  LabelContainer,
  LabelIcon,
  PageContainer,
  Step,
  StepsContainer,
} from './styledComponents';

const STEP_1_DETAILS = 'details';
const STEP_2_SUPPLIER_PRODUCT_MAPPING = 'supplierProductMapping';
const STEP_3_RECIPE_MAPPING = 'recipeMapping';

const InventoryListTemplateCreation = (props) => {
  const {
    match: { path },
    client: { clientId, hasLocalCatalogs },
    history,
    showSuccessMessage,
    showErrorMessage,
    pageLoading,
    pageLoaded,
    user,
  } = props;

  const [catalogs, setCatalogs] = useState([]);

  const theme = getTheme();

  const STEPS_INVENTORY_LIST_TEMPLATE_CREATION = [
    { key: STEP_1_DETAILS, label: i18next.t('GENERAL.INFORMATIONS') },
    {
      key: STEP_2_SUPPLIER_PRODUCT_MAPPING,
      label: i18next.t('GENERAL.SUPPLIER_PRODUCT_PLURAL'),
    },
    {
      key: STEP_3_RECIPE_MAPPING,
      label: i18next.t('GENERAL.RECIPE_PLURAL'),
    },
  ];
  const STEPS_MAX_INDEX = STEPS_INVENTORY_LIST_TEMPLATE_CREATION.length - 1;

  const inventoryListTemplateForm = useForm({
    defaultValues: {},
    resolver: yupResolver(buildSchema(INVENTORY_LIST_TEMPLATE_FORM_INPUTS())),
  });

  const formFields = useWatch({
    control: inventoryListTemplateForm.control,
  });

  const [isSaveAlreadyTriggered, setIsSaveAlreadyTriggered] = useState(false);
  const [currentStep, setCurrentStep] = useState(STEPS_INVENTORY_LIST_TEMPLATE_CREATION[0].key);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const getClientCatalogs = async () => {
    pageLoading();

    if (!hasLocalCatalogs) {
      pageLoaded();
      return;
    }

    try {
      const clientCatalogs = await catalogService.getCatalogsByClientId(clientId);

      const filteredCatalogs = clientCatalogs.filter(({ active }) => active);

      if (!canSeeAllCatalogs(user, { hasLocalCatalogs })) {
        setCatalogs([user.catalog]);

        pageLoaded();
        return;
      }

      setCatalogs(filteredCatalogs);
    } catch {
      showErrorMessage(i18next.t('ADMIN.CATALOGS.FETCH_ERROR'));
      setCatalogs([]);
    } finally {
      pageLoaded();
    }
  };

  useEffect(() => {
    getClientCatalogs();
  }, []);

  /** USE EFFECTS */
  useEffect(() => {
    (async () => {
      switch (currentStep) {
        case STEPS_INVENTORY_LIST_TEMPLATE_CREATION[0].key:
          const fieldsValidation = await inventoryListTemplateForm.trigger(FIELDS_TO_CHECK);
          setIsSaveDisabled(!fieldsValidation && isSaveAlreadyTriggered);
          break;
        case STEPS_INVENTORY_LIST_TEMPLATE_CREATION[1].key:
        case STEPS_INVENTORY_LIST_TEMPLATE_CREATION[2].key:
          setIsSaveDisabled(false);
          break;
        default:
          return;
      }
    })();
  }, [formFields]);

  /** FUNCTIONS */

  const returnToInventoryListTemplatesList = () => history.goBack();

  const createInventoryListTemplate = async () => {
    pageLoading();

    const inventoryListTemplateName = get(formFields, 'name', '');

    const supplierProducts = get(formFields, 'supplierProductMappings', []);
    const supplierProductIds = supplierProducts.map(({ supplierProductId }) => supplierProductId);

    const recipes = get(formFields, 'recipeMappings', []);
    const entityIds = recipes.map(({ entityId }) => entityId);

    try {
      await clientService.createInventoryListTemplate(
        clientId,
        inventoryListTemplateName,
        supplierProductIds,
        entityIds,
      );
      showSuccessMessage(i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.CREATION_SUCCESS'));

      /*
        Can't call it in a 'finally' because function returnToInventoryListTemplatesList redirect to another page and it might create an infinite loading
      */

      pageLoaded();

      returnToInventoryListTemplatesList();
    } catch {
      showErrorMessage(i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.CREATION_FAILURE'));

      pageLoaded();
    }
  };

  const nextStepHandler = async () => {
    const fieldsValidation = await inventoryListTemplateForm.trigger(FIELDS_TO_CHECK);

    switch (currentStep) {
      case STEPS_INVENTORY_LIST_TEMPLATE_CREATION[0].key:
        setIsSaveAlreadyTriggered(true);

        if (!fieldsValidation) {
          setIsSaveDisabled(true);

          return;
        }

        break;
      case STEPS_INVENTORY_LIST_TEMPLATE_CREATION[1].key:
      case STEPS_INVENTORY_LIST_TEMPLATE_CREATION[2].key:
        setIsSaveDisabled(false);

        break;
      default:
    }

    const currentStepIndex = STEPS_INVENTORY_LIST_TEMPLATE_CREATION.findIndex(
      ({ key }) => key === currentStep,
    );

    if (currentStepIndex < STEPS_MAX_INDEX) {
      setCurrentStep(STEPS_INVENTORY_LIST_TEMPLATE_CREATION[currentStepIndex + 1].key);
      setIsSaveAlreadyTriggered(false);
    }

    if (currentStepIndex === STEPS_MAX_INDEX) {
      await createInventoryListTemplate();
    }
  };

  const previousStepHandler = () => {
    setIsSaveDisabled(false);

    const currentStepIndex = STEPS_INVENTORY_LIST_TEMPLATE_CREATION.findIndex(
      ({ key }) => key === currentStep,
    );

    if (currentStepIndex > 0) {
      setCurrentStep(STEPS_INVENTORY_LIST_TEMPLATE_CREATION[currentStepIndex - 1].key);
    }

    if (currentStepIndex === 0) {
      returnToInventoryListTemplatesList();
    }
  };

  return (
    <>
      <NavigationBar
        displaySubFeatures={false}
        inventoryListTemplate={{ name: i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.CREATE_TITLE') }}
        path={path}
        bigTopBar
        enableActionBottomOrder
      />
      <PageContainer>
        <ContentContainer>
          {currentStep === STEPS_INVENTORY_LIST_TEMPLATE_CREATION[0].key && (
            <InventoryListTemplateInformations
              inventoryListTemplateForm={inventoryListTemplateForm}
              isSaveAlreadyTriggered={isSaveAlreadyTriggered}
            />
          )}
          {currentStep === STEPS_INVENTORY_LIST_TEMPLATE_CREATION[1].key && (
            <InventoryListTemplateMappings
              catalogs={catalogs}
              existingMappings={formFields.supplierProductMappings || []}
              formFields={formFields}
              inventoryListTemplateForm={inventoryListTemplateForm}
              isLoading={false}
              isCreation
            />
          )}
          {currentStep === STEPS_INVENTORY_LIST_TEMPLATE_CREATION[2].key && (
            <InventoryListTemplateRecipeMappings
              existingMappings={formFields.recipeMappings || []}
              formFields={formFields}
              inventoryListTemplateForm={inventoryListTemplateForm}
              isLoading={false}
              isCreation
            />
          )}
        </ContentContainer>
        <Footer>
          <StepsContainer>
            {STEPS_INVENTORY_LIST_TEMPLATE_CREATION.map(({ key, label }, index) => (
              <Step key={`step-${index}`}>
                <LabelContainer isActive={currentStep === key}>
                  <LabelIcon isActive={currentStep === key}>{index + 1}</LabelIcon>
                  <Text
                    children={label}
                    color={
                      currentStep === key
                        ? theme.colors.modalHeaders.green.text
                        : theme.colors.greys.dark
                    }
                    font={ENUM_FONTS.TEXT_MIDDLE_BOLD}
                  />
                </LabelContainer>
                {index !== STEPS_INVENTORY_LIST_TEMPLATE_CREATION.length - 1 && (
                  <img src={'/images/inpulse/chevron-right-dmgrey-small.svg'} />
                )}
              </Step>
            ))}
            <ButtonsContainer>
              {navigationUtils.renderPreviousButton(
                currentStep === STEP_1_DETAILS,
                previousStepHandler,
              )}
              {navigationUtils.renderNextButton(
                currentStep === STEP_3_RECIPE_MAPPING,
                isSaveDisabled,
                nextStepHandler,
              )}
            </ButtonsContainer>
          </StepsContainer>
        </Footer>
      </PageContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryListTemplateCreation);
