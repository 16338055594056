export const DATE_DISPLAY_FORMATS = {
  // lun. 7 nov.
  CONDENSED_DAY_DATE_MONTH: 'ddd Do MMM',
  // lun. 7 nov. 2022
  CONDENSED_DAY_DATE_MONTH_YEAR: 'ddd Do MMM YYYY',
  // 7 nov.
  CONDENSED_DATE_MONTH: 'Do MMM',
  // lundi 7 nov.
  DAY_DATE_MONTH: 'dddd Do MMM',
  // lundi 7 novembre
  FULL_DAY_DATE_MONTH: 'dddd Do MMMM',
  // 7 nov. 2022
  DATE_MONTH_YEAR: 'Do MMM YYYY',
  // 7 novembre
  DATE_MONTH: 'D MMMM',
  // 2022-10-07
  DASHED_YEAR_MONTH_DAY: 'YYYY-MM-DD',
  // 07-10-2022
  DASHED_DAY_MONTH_YEAR: 'DD-MM-YYYY',
  // 07/10/2022
  SLASHED_DAY_MONTH_YEAR: 'DD/MM/YYYY',
  // 07/10
  SLASHED_DAY_MONTH: 'DD/MM',
  // 07/10/2022 23:10
  SLASHED_DAY_MONTH_YEAR_HOUR_MINUTES: 'DD/MM/YYYY HH:mm',
  // 10/07/2022
  SLASHED_MONTH_DAY_YEAR: 'MM/DD/YYYY',
  // 07102022
  STICKED_YEAR_MONTH_DAY: 'DDMMYYYY',
  // 07/12/2022T21:23:10
  HYPHEN_YEAR_MONTH_DAY_HOUR_MINUTE_SECOND: 'YYYY-MM-DDTHH:mm:ss',
  // 2022_12_07_21_23_10
  UNDERSCORED_MONTH_DAY_HOUR_MINUTE_SECOND: 'YYYY-MM-DD_HH_mm_ss',
  // 20221007
  PACKED_YEAR_MONTH_DAY: 'YYYYMMDDD',
  // 071022
  SHORT_STICKED_DAY_MONTH_YEAR: 'DDMMYY',
  // 07 nov. 2022 - S23
  DATE_MONTH_YEAR_WEEK_NUMBER: 'Do MMM YYYY - [S]WW',
  // nov. 7, 23
  SHORTEN_MONTH_AND_YEAR_DAY: 'MMM D, YY',
};

export const TIME_DISPLAY_FORMATS = {
  SLASHED_HOUR_MINUTE_SECOND: 'HH:mm:ss',
  SLASHED_HOUR_MINUTE: 'HH:mm',
};

export const DATE_PICKER_DOT_COLOR = {
  RED: 'red',
  GREEN: 'green',
  ORANGE: 'orange',
};
