import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { PropsActionButton } from '../../interface';

import { Button, DropdownPicture } from './styledComponents';

const ActionButton = (props: PropsActionButton): JSX.Element => {
  const { open, visibility, isLoading, theme, isDisabled } = props;

  const [isButtonHovered, setIsButtonHovered] = useState(false);

  return (
    <div style={{ marginLeft: 'calc(100% - 32px)' }}>
      <Button
        open={open}
        visibility={visibility ? 'visible' : ''}
        isLoading={isLoading}
        isDisabled={isDisabled}
        onMouseEnter={() => setIsButtonHovered(true)}
        onMouseLeave={() => setIsButtonHovered(false)}
        theme={theme}
      >
        <DropdownPicture
          id="actionButton"
          visibility={visibility ? 'visible' : ''}
          open={open}
          isLoading={isLoading}
          isDisabled={isDisabled}
          isButtonHovered={isButtonHovered}
        />
      </Button>
    </div>
  );
};

ActionButton.propTypes = {
  open: PropTypes.bool,
  visibility: PropTypes.bool,
  isLoading: PropTypes.bool,
};

ActionButton.defaultProps = {
  open: false,
  visibility: false,
  isLoading: false,
};

export default ActionButton;
