import i18next from 'i18next';

import { convertUnits } from '@commons/utils/conversion';
import { getClientStoreNameTranslation } from '@commons/utils/translations';

import { transformNumber } from '../../utils/exports';

export const PROP_KEYS = {
  CATEGORY_RATIO: 'categoryRatio',
  CURRENCY: 'currency',
  END_DATE: 'endDate',
  INGREDIENT_CATEGORIES: 'ingredientCategories',
  INGREDIENT_NAME: 'ingredientName',
  LOSS_CATEGORIES: 'lossCategories',
  LOSS_CATEGORY: 'lossCategory',
  LOSS_RATIO: 'lossRatio',
  LOSS_TYPE: 'lossType',
  PRODUCT_LOSS_CURRENCY: 'productLossCurrency',
  PRODUCT_LOSS_UNIT: 'productLossUnit',
  START_DATE: 'startDate',
  STORES: 'stores',
  SUPPLIER_PRODUCT_LOSS_CURRENCY: 'supplierProductLossCurrency',
  SUPPLIER_PRODUCT_LOSS_UNIT: 'supplierProductLossUnit',
  TOTAL_LOSS_CURRENCY: 'totalLossCurrency',
  TOTAL_LOSS_UNIT: 'totalLossUnit',
  TURNOVER: 'turnover',
  UNIT: 'unit',
};

export const getByIngredientSheetColumns = () => {
  const columns = [
    {
      name: i18next.t('GENERAL.INGREDIENT'),
      propertyKey: PROP_KEYS.INGREDIENT_NAME,
    },
    {
      name: i18next.t('LOSSES.ANALYTICS.LOSSES_BY_INGREDIENT_COLUMN_NAME'),
      propertyKey: PROP_KEYS.SUPPLIER_PRODUCT_LOSS_CURRENCY,
      type: 'currency',
    },
    {
      name: i18next.t('LOSSES.ANALYTICS.LOSSES_BY_PRODUCT_COLUMN_NAME'),
      propertyKey: PROP_KEYS.PRODUCT_LOSS_CURRENCY,
      type: 'currency',
    },
    {
      name: i18next.t('GENERAL.TOTAL'),
      propertyKey: PROP_KEYS.TOTAL_LOSS_CURRENCY,
      type: 'currency',
    },
    {
      name: i18next.t('GENERAL.CURRENCY'),
      propertyKey: PROP_KEYS.CURRENCY,
    },
    {
      name: i18next.t('LOSSES.ANALYTICS.LOSSES_BY_INGREDIENT_QUANTITY_COLUMN_NAME'),
      propertyKey: PROP_KEYS.SUPPLIER_PRODUCT_LOSS_UNIT,
      transform: transformNumber,
    },
    {
      name: i18next.t('LOSSES.ANALYTICS.LOSSES_BY_PRODUCT_QUANTITY_COLUMN_NAME'),
      propertyKey: PROP_KEYS.PRODUCT_LOSS_UNIT,
      transform: transformNumber,
    },
    {
      name: i18next.t('LOSSES.ANALYTICS.TOTAL_QUANTITY_COLUMN_NAME'),
      propertyKey: PROP_KEYS.TOTAL_LOSS_UNIT,
      transform: transformNumber,
    },
    {
      name: i18next.t('GENERAL.UNIT'),
      propertyKey: PROP_KEYS.UNIT,
      transform: convertUnits,
    },
  ];

  return columns;
};

export const getByCategorySheetColumns = () => {
  const columns = [
    {
      name: i18next.t('GENERAL.INGREDIENT'),
      propertyKey: PROP_KEYS.INGREDIENT_NAME,
    },
    {
      name: i18next.t('GENERAL.CATEGORY'),
      propertyKey: PROP_KEYS.LOSS_CATEGORY,
    },
    {
      name: i18next.t('GENERAL.TYPE'),
      propertyKey: PROP_KEYS.LOSS_TYPE,
    },
    {
      name: i18next.t('GENERAL.LOSSES'),
      propertyKey: PROP_KEYS.TOTAL_LOSS_CURRENCY,
      type: 'currency',
    },
    {
      name: i18next.t('GENERAL.CURRENCY'),
      propertyKey: PROP_KEYS.CURRENCY,
    },
    {
      name: i18next.t('LOSSES.ANALYTICS.LOSSES_QUANTITY_COLUMN_NAME'),
      propertyKey: PROP_KEYS.TOTAL_LOSS_UNIT,
      transform: transformNumber,
    },
    {
      name: i18next.t('LOSSES.ANALYTICS.CATEGORY_PERCENTAGE_COLUMN_NAME'),
      propertyKey: PROP_KEYS.CATEGORY_RATIO,
      type: 'percentage',
    },
    {
      name: i18next.t('GENERAL.UNIT'),
      propertyKey: PROP_KEYS.UNIT,
      transform: convertUnits,
    },
  ];

  return columns;
};

export const getContextSheetColumns = (storeName) => {
  const translatedStoreName = getClientStoreNameTranslation(storeName, true);

  const columns = [
    {
      name: translatedStoreName,
      propertyKey: PROP_KEYS.STORES,
    },
    {
      name: i18next.t('GENERAL.START_DATE'),
      propertyKey: PROP_KEYS.START_DATE,
    },
    {
      name: i18next.t('GENERAL.END_DATE'),
      propertyKey: PROP_KEYS.END_DATE,
    },
    {
      name: i18next.t('LOSSES.ANALYTICS.INGREDIENT_CATEGORIES_COLUMN_NAME'),
      propertyKey: PROP_KEYS.INGREDIENT_CATEGORIES,
    },
    {
      name: i18next.t('LOSSES.ANALYTICS.LOSS_CATEGORIES_COLUMN_NAME'),
      propertyKey: PROP_KEYS.LOSS_CATEGORIES,
    },
    {
      name: i18next.t('GENERAL.TURNOVER_EX_TAX'),
      propertyKey: PROP_KEYS.TURNOVER,
      type: 'currency',
    },
    {
      name: i18next.t('ANALYSIS.FOOD_COST.LOSS_RATE'),
      propertyKey: PROP_KEYS.LOSS_RATIO,
      type: 'percentage',
    },
    {
      name: i18next.t('GENERAL.CURRENCY'),
      propertyKey: PROP_KEYS.CURRENCY,
    },
  ];

  return columns;
};
