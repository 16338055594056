export const statusLegend = {
  stock: {
    name: 'STOCKS.STOCKS.LIST_STATUS_STOCK',
    color: 'blue',
    value: 'stock',
  },
  loss: {
    name: 'STOCKS.STOCKS.LIST_STATUS_LOSS',
    color: 'red',
    value: 'loss',
  },
};

export const statusLegendArray = [
  { id: 'stock', name: 'STOCKS.STOCKS.LIST_STATUS_STOCK', color: 'blue', inpulseColor: 'green' },
  { id: 'loss', name: 'STOCKS.STOCKS.LIST_STATUS_LOSS', color: 'red', inpulseColor: 'red' },
];

export const INVENTORY_TYPE = {
  LOSS: 'loss',
  STOCK: 'stock',
};

/**
 * Lists the different properties that make up the columns used in the ListView, as well as
 * the data on which advanced filters can be applied.
 *
 */
export const STOCK_PROPERTIES = {
  TIMESTAMP: 'timestamp',
  TYPE: 'type',
  TOTAL: 'total',
};
