import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  background-color: ${(props) => props.theme.colors.greys.lightest};

  border-radius: 8px;
  border: solid 1px ${(props) => props.theme.colors.greys.lighty};

  background-color: ${(props) => props.theme.colors.greys.lightest};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;

  gap: 8px;
`;

export const FileSizeContainer = styled.div`
  text-wrap: nowrap;
`;

export const StatusContainer = styled.div`
  height: 28px;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
`;
