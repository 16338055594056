import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { multipleOrderByWithNullsLast } from '@commons/utils/sorting';
import utilsXLS, { generateFiles } from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { getClientInfo } from '@selectors/client';

import { lossService } from '@services/loss';

import { formatAnalyticsForNestedList } from '../../utils/format';
import {
  getByIngredientSheetColumns,
  getByCategorySheetColumns,
  getContextSheetColumns,
  PROP_KEYS,
} from './sheets';
import formatUtils from './format';

export const ExportLossAnalyticsByStoreModal = (props) => {
  const {
    params: { currency, lossCategoryById, context },
    client: { storeName },
    closeModal,
    showErrorMessage,
  } = props;

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const exitModal = () => {
    setIsLoading(false);

    closeModal();
  };

  useEffect(() => {
    setProgress(0);

    (async () => {
      try {
        const byIngredientSheetData = [];
        const byCategorySheetData = [];

        const formattedStartDate = moment(context.startDate).format(
          DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
        );
        const formattedEndDate = moment(context.endDate).format(
          DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
        );
        const ingredientCategories = context.ingredientCategories.map(({ value }) => value);
        const lossCategoriesIds = context.lossCategories.map(({ id }) => id);

        for (const [index, store] of context.stores.entries()) {
          const fetchedAnalytics = await lossService.getLossesAnalytics(
            [store.id],
            formattedStartDate,
            formattedEndDate,
            ingredientCategories,
            lossCategoriesIds,
          );

          const { formattedDataByIngredient } = formatAnalyticsForNestedList(fetchedAnalytics);

          formattedDataByIngredient.forEach((ingredientData) => {
            const ingredientRowData = formatUtils.extractIngredientData(
              currency,
              store.name,
              ingredientData,
            );

            byIngredientSheetData.push(ingredientRowData);

            const lossByCategoryOfIngredient = ingredientData.categoriesData.map((categoryData) =>
              formatUtils.extractCategoryData(
                lossCategoryById,
                store.name,
                ingredientRowData,
                categoryData,
              ),
            );

            byCategorySheetData.push(...lossByCategoryOfIngredient);
          });

          setProgress(((index + 1) / context.stores.length) * 100);
        }

        const sortedIngredients = multipleOrderByWithNullsLast(
          byIngredientSheetData,
          [PROP_KEYS.STORE_NAME, PROP_KEYS.TOTAL_LOSS_CURRENCY, PROP_KEYS.INGREDIENT_NAME],
          ['asc', 'desc', 'asc'],
        );
        const byIngredientSheet = utilsXLS.generateDefaultSheet(
          i18next.t('LOSSES.ANALYTICS.EXPORT_BY_INGREDIENT'),
          getByIngredientSheetColumns(storeName),
          sortedIngredients,
        );

        const byCategorySheet = utilsXLS.generateDefaultSheet(
          i18next.t('LOSSES.ANALYTICS.EXPORT_BY_CATEGORY'),
          getByCategorySheetColumns(storeName),
          sortBy(byCategorySheetData, [PROP_KEYS.STORE_NAME, PROP_KEYS.INGREDIENT_NAME]),
        );

        const contextSheet = utilsXLS.generateDefaultSheet(
          i18next.t('GENERAL.INFORMATIONS'),
          getContextSheetColumns(storeName),
          [formatUtils.extractContextData(context, currency)],
          currency,
        );

        generateFiles(
          i18next.t('LOSSES.ANALYTICS.EXPORT_BY_STORE_FILENAME'),
          [byIngredientSheet, byCategorySheet],
          contextSheet,
        );

        setIsLoading(false);
      } catch {
        showErrorMessage(i18next.t('GENERAL.EXPORT_FAILURE'));
        exitModal();
      }
    })();
  }, []);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setIsLoading}
      titleModal={i18next.t('LOSSES.ANALYTICS.EXPORT_BY_STORE_MODAL_TITLE')}
    />
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportLossAnalyticsByStoreModal);
