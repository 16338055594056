import React, { useEffect, useRef } from 'react';

import { RadioButton } from '@commons/utils/styledLibraryComponents';

import {
  Container,
  Content,
  SubText,
  MainText,
  TextContainer,
  EmptyStateIcon,
  ButtonContainer,
  RadioSelectButton,
} from './styledComponents';

const NextStepChoicesModal = (props) => {
  const {
    title,
    text,
    iconSrc,
    firstChoiceText,
    secondChoiceText,
    firstChoiceSelected,
    setFirstChoiceSelected,
  } = props;

  const firstChoiceRef = useRef(null);
  const secondChoiceRef = useRef(null);

  useEffect(() => {
    firstChoiceSelected ? firstChoiceRef.current.focus() : secondChoiceRef.current.focus();
  }, [firstChoiceSelected]);

  return (
    <Container>
      <Content>
        <EmptyStateIcon alt="icon-association" src={iconSrc} />
        <TextContainer>
          <MainText>{title}</MainText>
          <SubText>{text}</SubText>
        </TextContainer>
        <ButtonContainer>
          <RadioSelectButton ref={firstChoiceRef} onClick={() => setFirstChoiceSelected(true)}>
            <RadioButton
              borderSize={!firstChoiceSelected && '2px'}
              selected={firstChoiceSelected}
              size="small"
              value={true}
            />
            <div>{firstChoiceText}</div>
          </RadioSelectButton>
          <RadioSelectButton ref={secondChoiceRef} onClick={() => setFirstChoiceSelected(false)}>
            <RadioButton
              borderSize={firstChoiceSelected && '2px'}
              selected={firstChoiceSelected}
              size="small"
              value={false}
            />
            <div>{secondChoiceText}</div>
          </RadioSelectButton>
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default NextStepChoicesModal;
