import styled, { css } from 'styled-components';

export const TagLabel = styled.div`
  background: ${(props) => props.theme.colors.greys.lighter};

  min-width: 20px;
  max-width: 120px;

  width: fit-content;
  height: auto;
  padding: 0 6px;

  font: ${(props) => props.theme.fonts.textBig};
  font-size: 14px;
  border-radius: ${(props) => props.theme.borders.radius.weak};

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  display: flex;
  justify-content: start;

  &.box {
    max-width: none;
    min-width: fit-content;
    margin: auto 10px;
  }

  ${(props) =>
    props.isNestedContent &&
    css`
      height: auto;
      background: ${(props) => props.theme.colors.greys.lighty};
    `}
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  position: relative;

  height: 100%;
  width: fit-content;

  cursor: ${(props) => (props.pointer ? 'pointer' : 'inherit')};

  &:hover {
    margin-top: 46px;
    > div {
      display: flex;
      align-items: center;
      > TagInfo {
        margin-top: -2px;
      }
    }
  }
`;

export const TagLabelAndInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TagInfo = styled.div`
  min-width: 20px;
  margin-left: 5px;
`;

export const TagHoverBox = styled.div`
  width: auto;
  height: 35px;

  display: none;

  position: inherit;
  z-index: 1;

  margin: 8px 0 2px 0px;

  background: ${(props) => props.theme.colors.greys.lightest};
  border-radius: ${(props) => props.theme.borders.radius.weak};

  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
`;

export const PointerDiv = styled.div`
  width: 8px;
  height: 8px;

  background: ${(props) => props.theme.colors.greys.lightest};

  position: absolute;
  z-index: 1;
  top: -4px;
  left: 20px;
  transform: translateX(-50%) rotate(-135deg);
`;
