import { getActionAuthorization } from '../commons/utils';

const RUSH_ORDER_ACTIONS_RESOURCE = {
  CREATE: 'rushorder:create',
  EDIT: 'rushorder:edit',
  DELETE: 'rushorder:delete',
  CHANGE_DELIVERY_DATE: 'rushorder:edit:delivery-date',
  CORRECT: 'rushorder:correct',
};

export const canCreateRushOrder = (actions) =>
  getActionAuthorization(actions, RUSH_ORDER_ACTIONS_RESOURCE.CREATE);

export const canEditRushOrder = (actions) =>
  getActionAuthorization(actions, RUSH_ORDER_ACTIONS_RESOURCE.EDIT);

export const canDeleteRushOrder = (actions) =>
  getActionAuthorization(actions, RUSH_ORDER_ACTIONS_RESOURCE.DELETE);

export const canChangeRushOrderDeliveryDate = (actions) =>
  getActionAuthorization(actions, RUSH_ORDER_ACTIONS_RESOURCE.CHANGE_DELIVERY_DATE);

export const canCorrectRushOrder = (actions) =>
  getActionAuthorization(actions, RUSH_ORDER_ACTIONS_RESOURCE.CORRECT);
