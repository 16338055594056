import { convertToSpreadsheetCurrencyFormat } from '../../../commons/utils/format';
import i18next from 'i18next';
import moment from 'moment';

export const convertToBetterDateFormat = (date) => {
  if (!date) {
    return '';
  }
  return moment(date).format('DD-MM-YYYY');
};

export const configuration = {
  ingredient: () => {
    const config = {
      modalTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_IN_PROGRESS'),
      fileTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_INGREDIENT_TITLE'),
      sheetTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_INGREDIENT_WITHOUT_MATCH_SHEET_TITLE'),
      columns: [
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_NAME'),
          keyName: 'name',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_CATEGORY'),
          keyName: 'category',
          transform: (value) => value,
        },
      ],
    };
    return config;
  },
  ingredientPrice: () => {
    const config = {
      modalTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_IN_PROGRESS'),
      fileTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_INGREDIENT_TITLE'),
      sheetTitle: i18next.t(
        'BACKOFFICE.CHECKS.EXPORT_MODAL_INGREDIENT_PRICE_AT_NULL_OR_ZERO_SHEET_TITLE',
      ),
      columns: [
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_NAME'),
          keyName: 'name',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_CATEGORY'),
          keyName: 'category',
          transform: (value) => value,
        },
      ],
    };
    return config;
  },
  supplierProductsNotMapped: () => {
    const config = {
      modalTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_IN_PROGRESS'),
      fileTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_SUPPLIER_PRODUCT_TITLE'),
      sheetTitle: i18next.t(
        'BACKOFFICE.CHECKS.EXPORT_MODAL_SUPPLIER_PRODUCT_WITHOUT_MATCH_SHEET_TITLE',
      ),
      columns: [
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_NAME'),
          keyName: 'name',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_CATEGORY'),
          keyName: 'category',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_PACKAGING'),
          keyName: 'packagingName',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_SKU'),
          keyName: 'sku',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_PRICE_EX_TAX'),
          keyName: 'price',
          transform: (value) => convertToSpreadsheetCurrencyFormat(value),
        },
      ],
    };
    return config;
  },
  supplierProductsWrongUnit: () => {
    const config = {
      modalTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_IN_PROGRESS'),
      fileTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_SUPPLIER_PRODUCT_TITLE'),
      sheetTitle: i18next.t(
        'BACKOFFICE.CHECKS.EXPORT_MODAL_SUPPLIER_PRODUCT_WRONG_UNIT_SHEET_TITLE',
      ),
      columns: [
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_NAME'),
          keyName: 'supplierProduct.name',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_SKU'),
          keyName: 'supplierProduct.sku',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_UNIT'),
          keyName: 'supplierProduct.packagingUnit',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_ENTITY'),
          keyName: 'entity.name',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_ENTITY_UNIT'),
          keyName: 'entity.unit',
          transform: (value) => value,
        },
      ],
    };
    return config;
  },
  supplierProductsPriceAtNullOrZero: () => {
    const config = {
      modalTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_IN_PROGRESS'),
      fileTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_SUPPLIER_PRODUCT_TITLE'),
      sheetTitle: i18next.t(
        'BACKOFFICE.CHECKS.EXPORT_MODAL_SUPPLIER_PRODUCT_PRICE_AT_NULL_OR_ZERO_SHEET_TITLE',
      ),
      columns: [
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_NAME'),
          keyName: 'name',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_PACKAGING'),
          keyName: 'packagingName',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_SKU'),
          keyName: 'sku',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_PRICE_EX_TAX'),
          keyName: 'price',
          transform: (value) => convertToSpreadsheetCurrencyFormat(value),
        },
      ],
    };
    return config;
  },
  recipe: () => {
    const config = {
      modalTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_IN_PROGRESS'),
      fileTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_RECIPE_TITLE'),
      sheetTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_RECIPE_SHEET_TITLE'),
      columns: [
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_ID'),
          keyName: 'id',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_NAME'),
          keyName: 'name',
          transform: (value) => value,
        },
      ],
    };
    return config;
  },
  weatherStation: () => {
    const config = {
      modalTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_IN_PROGRESS'),
      fileTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_WEATHER_STATION_TITLE'),
      sheetTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_WEATHER_STATION_SHEET_TITLE'),
      columns: [
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_ID'),
          keyName: 'id',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_NAME'),
          keyName: 'name',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_CITY'),
          keyName: 'city',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_COUNTRY'),
          keyName: 'country',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_DISTANCE'),
          keyName: 'distanceWeatherStation',
          transform: (value) => value,
        },
      ],
    };
    return config;
  },
  cashierProducts: () => {
    const config = {
      modalTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_IN_PROGRESS'),
      fileTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_CASHIER_PRODUCT_TITLE'),
      sheetTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_CASHIER_PRODUCT_SHEET_TITLE'),
      columns: [
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_NAME'),
          keyName: 'name',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_DATE'),
          keyName: 'createdAt',
          transform: (value) => convertToBetterDateFormat(value),
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_TAGS'),
          keyName: 'tags',
          transform: (value) => value.toString(),
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_SKU'),
          keyName: 'sku',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_PRICE_WITH_TAXES'),
          keyName: 'priceWithTaxes',
          transform: (value) => convertToSpreadsheetCurrencyFormat(value),
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_VAT_RATE'),
          keyName: 'vatRate',
          transform: (value) => value,
        },
      ],
    };
    return config;
  },
  products: (hasMultipleBrands) => {
    const config = {
      modalTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_IN_PROGRESS'),
      fileTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_PRODUCT_TITLE'),
      sheetTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_PRODUCT_SHEET_TITLE'),
      columns: [
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_NAME'),
          keyName: 'name',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_CATEGORY'),
          keyName: 'category',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_SKU'),
          keyName: 'sku',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_PRICE_WITH_TAXES'),
          keyName: 'priceWithTaxes',
          transform: (value) => convertToSpreadsheetCurrencyFormat(value),
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_VAT_RATE'),
          keyName: 'vatRate',
          transform: (value) => value,
        },
      ],
    };
    if (hasMultipleBrands) {
      config.columns[1].splice(2, 0, {
        displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_BRAND'),
        keyName: 'lnkBrandProductrel.name',
        transform: (value) => value,
      });
    }
    return config;
  },
  productsPriceAtNullOrZero: (hasMultipleBrands) => {
    const config = {
      modalTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_IN_PROGRESS'),
      fileTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_PRODUCT_TITLE'),
      sheetTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_PRICE_AT_NULL_OR_ZERO_SHEET_TITLE'),
      columns: [
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_NAME'),
          keyName: 'name',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_CATEGORY'),
          keyName: 'category',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_SKU'),
          keyName: 'sku',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_PRICE_WITH_TAXES'),
          keyName: 'priceWithTaxes',
          transform: (value) => convertToSpreadsheetCurrencyFormat(value),
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_VAT_RATE'),
          keyName: 'vatRate',
          transform: (value) => value,
        },
      ],
    };
    if (hasMultipleBrands) {
      config.columns[1].splice(2, 0, {
        displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_BRAND'),
        keyName: 'lnkBrandProductrel.name',
        transform: (value) => value,
      });
    }
    return config;
  },
  productsVatRateInferiorAt1: (hasMultipleBrands) => {
    const config = {
      modalTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_IN_PROGRESS'),
      fileTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_PRODUCT_TITLE'),
      sheetTitle: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_VAT_RATE_INFERIOR_AT_ONE_SHEET_TITLE'),
      columns: [
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_NAME'),
          keyName: 'name',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_CATEGORY'),
          keyName: 'category',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_SKU'),
          keyName: 'sku',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_PRICE_WITH_TAXES'),
          keyName: 'priceWithTaxes',
          transform: (value) => convertToSpreadsheetCurrencyFormat(value),
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_VAT_RATE'),
          keyName: 'vatRate',
          transform: (value) => value,
        },
      ],
    };
    if (hasMultipleBrands) {
      config.columns.splice(2, 0, {
        displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_BRAND'),
        keyName: 'lnkBrandProductrel.name',
        transform: (value) => value,
      });
    }
    return config;
  },
};

export default {
  configuration,
};
