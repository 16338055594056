import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Button } from '../../../commons/utils/styledLibraryComponents';
import { getClientStoreNameTranslation } from '../../../commons/utils/translations';
import WhiteCard from '../../../commons/WhiteCard';

import { openModal } from '../../../actions/modal';
import SalesForm from './SalesForm';

import { getClientInfo } from '../../../selectors/client';

import { Container } from './styledComponents';
import NavigationBreadCrumb from '../../../commons/Breadcrumb/NavigationBreadCrumb';

export const AdminSales = ({ client, match, openFullscreenModal }) => {
  const path = get(match, 'path');

  const callback = () => {
    openFullscreenModal({
      component: SalesForm,
    });
  };

  const storeName = getClientStoreNameTranslation(client.storeName).toLowerCase();

  return (
    <>
      <NavigationBreadCrumb featurePath={path} />
      <Container>
        <WhiteCard
          content={i18next.t('ADMIN.SALES.WHITE_CARD_CONTENT', { storeName })}
          renderContent={
            <Button
              buttonCustomStyle={{ width: 'fit-content' }}
              color={'inpulse-default'}
              handleClick={callback}
              icon={'/images/inpulse/pen-white-small.svg'}
              label={i18next.t('ADMIN.SALES.WHITE_CARD_BUTTON')}
            />
          }
          title={i18next.t('ADMIN.SALES.WHITE_CARD_TITLE', { storeName })}
        />
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  openFullscreenModal: (params) => {
    dispatch(openModal(params, true));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSales);
