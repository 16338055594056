import aggregateStatsOnTotalLevel from './aggregateStats';
import getAnalyticsData from './fetch';
import getColumnsTable from './columns';
import getExportFile from './exports';

export default {
  getExportFile,
  getColumnsTable,
  getAnalyticsData,
  aggregateStatsOnTotalLevel,
};
