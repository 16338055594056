import styled from 'styled-components';

export const SelectorsAndInfosContainer = styled.div`
  height: 40px;
  width: 100%;

  gap: 24px;

  display: flex;
  align-items: center;
`;
