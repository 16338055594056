import styled, { css } from 'styled-components';

export const ExpandedRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;

  ${(props) => {
    if (props.isFirst && props.isLast) {
      return css`
        border-radius: ${props.theme.childRow?.borderRadius};
      `;
    }

    if (props.isFirst) {
      return css`
        border-top-left-radius: ${props.theme.childRow?.borderRadius};
        border-top-right-radius: ${props.theme.childRow?.borderRadius};
      `;
    }

    if (props.isLast) {
      return css`
        border-bottom-left-radius: ${props.theme.childRow?.borderRadius};
        border-bottom-right-radius: ${props.theme.childRow?.borderRadius};
      `;
    }

    return css``;
  }}

  border-top: ${(props) => props.theme.childRow?.border};
  border-left: ${(props) => props.theme.childRow?.border};
  border-right: ${(props) => props.theme.childRow?.border};

  &:last-child {
    border-bottom: ${(props) => props.theme.childRow?.border};
  }

  background-color: ${(props) => props.theme.childRow?.backgroundColor};
`;

export const ExpandedItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 54px;
  border-bottom: 0;
  cursor: auto;
`;

export const Separator = styled.div`
  height: 1px;
  width: calc(100% - 30px);

  margin: auto;
  padding: 0 15px;

  background: ${(props) => props.theme.childRow?.separator?.color};
`;

export const ItemChildDisplayWithGraph = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex: 1;

  flex-basis: ${(props) => `calc(${100 / props.nbColumn}%)`};
  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth}px;
    `}
`;

export const ItemChildContainer = styled.div`
  flex: 1;
`;

export const ItemChildGraphContainer = styled.div`
  flex-basis: 50%;
`;

export const CustomModalContainer = styled.div``;

export const ItemChildDisplayWithModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex: 1;

  flex-basis: ${(props) => `calc(${100 / props.nbColumn}%)`};
  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth}px;
    `}
`;

export default {
  ExpandedRowContainer,
  ExpandedItemRow,
  ItemChildDisplayWithGraph,
  ItemChildDisplayWithModal,
  ItemChildContainer,
  CustomModalContainer,
  ItemChildGraphContainer,
};
