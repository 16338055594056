import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openConfirmationModal } from '@actions/confirmationmodal';
import { openMediumModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { DeepsightConfirmationModal } from '@commons/DeepsightComponents';
import { ListView } from '@commons/utils/styledLibraryComponents';

import { getClientInfo } from '@selectors/client';

import { product as productService } from '@services/product';

import CreateProductModal from '@admin/products/products/components/CreateProductModal';
import ENUM_MODES_MODAL from '@admin/products/products/components/CreateProductModal/constants/modesModal';

import { Container } from './styledComponents';
import { getActions, getRowActions } from './getActions';
import getColumns from './columns';
import ProductionPlanningAssociationEmptyState from './components/EmptyState';

export async function handleDeletion(storeId, productId, props) {
  const {
    pageLoading,
    pageLoaded,
    showSuccessMessage,
    showErrorMessage,
    storesProductionPlanning,
    setStoresProductionPlanning,
  } = props;

  pageLoading();

  try {
    await productService.deleteStoreProductMapping(storeId, productId);

    const filteredStoresProductionPlanning = storesProductionPlanning.filter(
      (storeProductionPlanning) => storeProductionPlanning.storeId !== storeId,
    );

    setStoresProductionPlanning(filteredStoresProductionPlanning);

    showSuccessMessage(i18next.t('ADMIN.PRODUCTS.SUCCESSFULLY_REMOVED_FROM_PROCUTION_PLANNING'));
  } catch (err) {
    showErrorMessage(i18next.t('ADMIN.PRODUCTS.ERROR_WHEN_REMOVING_FROM_PRODUCTION_PLANNING'));
  } finally {
    pageLoaded();
  }
}

/**
 * Display popup modal to let the user confirm the decision to delete the production planning
 * of the associated store with the product
 *
 * @param {String} selectedStore    - The selected store
 * @param {Props} props             - The props linked to the component
 *
 * @returns {void}
 */
export function confirmDeletion(selectedStore, props) {
  const { product, openConfirmationModal } = props;

  const params = {
    component: DeepsightConfirmationModal,
    props,
    colorsheme: 'orange',
    eventId: selectedStore,
    handleEventDeleteConfirmation: () =>
      handleDeletion(selectedStore.storeId, selectedStore.productId, props),
    title: i18next.t('ADMIN.PRODUCTS.REMOVE_PRODUCTION_PLANNING_ASSOCIATION_MODAL_TITLE'),
    styleTitle: { fontFamily: 'proximanovaregular' },
    text: i18next.t('ADMIN.PRODUCTS.REMOVE_PRODUCTION_PLANNING_ASSOCIATION_MODAL_CONTENT', {
      productName: product.name,
      storeName: selectedStore.storeName,
    }),
  };

  openConfirmationModal(params);
}

export function addNewStoresProductionPlanning(props) {
  const params = {
    component: CreateProductModal,
    storesProductionPlanning: props.storesProductionPlanning,
    setStoresProductionPlanning: props.setStoresProductionPlanning,
    productId: props.product.id,
    history: props.history,
    mode: ENUM_MODES_MODAL.EDIT_STORES,
  };

  return props.openModal(params);
}

export function handleEdition(props, storeProductMapping) {
  if (props.isReadOnly) {
    return;
  }

  const params = {
    component: CreateProductModal,
    productId: props.product.id,
    mode: ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING,
    selectedStoreProductionPlanning: storeProductMapping,
    storesProductionPlanning: props.storesProductionPlanning,
    setStoresProductionPlanning: props.setStoresProductionPlanning,
  };

  return props.openModal(params);
}

const ProductProductionPlanningAssociation = (props) => {
  const { isReadOnly, storesProductionPlanning } = props;

  const [triggeredPPAssociation, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [formattedSPPlannings, setFormattedSPPlannings] = useState(storesProductionPlanning);

  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);

  const columns = getColumns();

  useEffect(() => {
    setActions(getActions(addNewStoresProductionPlanning, isReadOnly, props));

    if (!isReadOnly) {
      setRowActions(getRowActions(confirmDeletion, props));
      return;
    }

    setRowActions([]);
  }, [isReadOnly, storesProductionPlanning]);

  useEffect(() => {
    if (storesProductionPlanning.length) {
      setTrigger(true);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    // We need to format this data because the listView needs to have a column 'name' to display data
    setFormattedSPPlannings(
      storesProductionPlanning.map((planning) => ({ ...planning, name: planning.storeName })),
    );
  }, [storesProductionPlanning]);

  if (isLoading) {
    return <div></div>;
  }

  if (!triggeredPPAssociation || !formattedSPPlannings.length) {
    return (
      <ProductionPlanningAssociationEmptyState
        isReadOnly={isReadOnly}
        setTrigger={() => {
          setTrigger(true);
          addNewStoresProductionPlanning(props);
        }}
      />
    );
  }

  return (
    <Container>
      <ListView
        actionOnClick={(selectedStore) => !isReadOnly && handleEdition(props, selectedStore)}
        actions={actions}
        columns={columns}
        data={formattedSPPlannings}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        rowActions={rowActions}
        disableFooter
        disableFullSelection
        disableSelection
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openConfirmationModal: (params) => {
    dispatch(openConfirmationModal(params));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductProductionPlanningAssociation);
