import styled, { css, keyframes } from 'styled-components';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width && props.width};
  min-width: ${(props) => !props.width && '42px'};

  padding: ${(props) => props.theme.padding || '4px 8px'};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${(props) => props.theme.borders?.radius || '4px'};
  padding: ${(props) => props.theme.padding || '4px 8px'};
  font: ${(props) =>
    props.useNestedListRowFont
      ? 'normal 500 14px/28px inter'
      : props.theme.text?.font || 'normal 600 12px/16px inter'};
  color: ${(props) => props.theme.text?.colors?.white || '#FFFFFF'};
  border: 2px solid transparent;

  ${(props) => {
    if (props.type === 'plain') {
      return css`
        background-color: ${props.background || props.theme.loading.background};
        color: ${props.color || props.theme.loading.color};
      `;
    }

    return css`
      background-color: transparent;
      border: 2px solid ${props.background || props.theme?.loading?.background};
      color: ${props.color || props.theme?.loading?.color};
    `;
  }};

  .custom-tooltip-modal {
    border-radius: 3px;
    padding: 3px 8px 3px 8px;

    background-color: ${(props) =>
      props.theme.customModal?.icon?.default?.backgroundColor};

    width: auto;
  }

  .custom-tooltip-modal-open {
    background-color: ${(props) =>
      props.theme.customModal?.icon?.selected?.backgroundColor};

    border-radius: 3px;
    padding: 3px 8px 3px 8px;

    width: auto;
  }

  .custom-tooltip-modal:hover {
    background-color: ${(props) =>
      props.theme.customModal?.icon?.hover?.backgroundColor};
  }

  .custom-tooltip {
    word-break: break-word;
    white-space: pre-wrap;
    border-radius: ${(props) => `${props.borderRadius}px`};

    max-width: ${(props) => `${props.maxWidth}px`};
    max-height: 1200px;

    text-align: center;
    font: ${(props) => props.theme.font};
    width: ${(props) => props.tooltipWidth};
  }
`;

export const LabelText = styled.div`
  color: ${(props) => props.color};
`;

export const LabelIcon = styled.div`
  margin: ${(props) => (props.left ? '0px 4px 0px 0px' : '0px 0px 0px 4px')};
`;

export const LabelIconOnly = styled.div`
  margin: 0px;
`;

export const Icon = styled.img``;

export const IconLoadingContainer = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
`;

export const IconLoading = styled.img`
  animation: ${rotation} 2s linear infinite;
`;

export default {
  Container,
  LabelText,
  LabelIcon,
  LabelIconOnly,
  Icon,
  IconLoadingContainer,
  IconLoading,
};
