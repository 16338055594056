import styled from 'styled-components';

import theme from '../../theme';

export const Container = styled.div`
  background-color: ${theme.colors.greys.lightest};
  width: 100%;
  height: 100%;
  border-radius: 8px;
  min-width: 256px;
  padding: 8px;
`;

export const Header = styled.div`
  display: flex;
  font: ${theme.fonts.textMiddleInterBold};
  color: ${theme.colors.greys.darkest};
  text-align: flex-start;
  margin-bottom: 16px;
`;

export const Row = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  // first-child doesn't seem to work for some reason
  border-width: ${({ index }) => (index === 0 ? '1px 0 1px 0' : '0 0 1px 0')};
  border-style: solid;
  border-color: ${theme.colors.greys.light};
`;

export const RowLeftSplit = styled.div`
  display: flex;
  text-align: flex-start;

  color: ${theme.colors.blacks.ipBlack1};
  font: ${theme.fonts.textMicroHeight12Weight700Bold};
  width: 104px;
`;
export const RowRightSplit = styled.div`
  display: flex;
  text-align: flex-start;

  color: ${theme.colors.greys.darker};
  font: ${theme.fonts.textMicroHeight12Weight700Bold};
  width: 96px;
`;
