import React, { useEffect, useState } from 'react';

import { DeepsightUploadPicture } from '@commons/DeepsightComponents';

import { Container } from './styledComponents';

export function ProfilePictureSection(props) {
  const { profileUser, setProfileUser, isDisabled } = props;

  const [pictureUrl, setPictureUrl] = useState('');

  useEffect(() => {
    setPictureUrl(profileUser.img);
  }, [profileUser]);

  return (
    <Container>
      <DeepsightUploadPicture
        handleSelectedPhoto={(fileUri) => {
          setProfileUser({ ...profileUser, img: fileUri });
          setPictureUrl(fileUri);
        }}
        pictureUrl={pictureUrl}
        readOnly={isDisabled}
      />
    </Container>
  );
}

export default ProfilePictureSection;
