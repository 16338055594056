import HomeComponent from './Home';
import styled from 'styled-components';

const StyledHome = styled(HomeComponent)`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  & .modal-error-header-orange {
    height: 72px;

    padding: 24px;

    color: ${(props) => props.theme.colors.greys.lightest};
    font: ${(props) => props.theme.fonts.h1Inter};

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: ${(props) => props.theme.colors.infoOrange};
  }

  // --------- --------- confirmationsModals

  & .conf-modal-encloser {
    height: 100%;
    width: 100%;

    position: fixed;
    top: 0;
    z-index: 13;

    display: flex;
    align-items: center;
    justify-items: center;

    background-color: rgba(29, 29, 27, 0.64);
  }

  & .conf-modal-content-encloser {
    margin: auto;
    width: 600px;
  }

  // --------- modals upper

  & .conf-modal-upper-blue {
    width: 100%;
    height: 72px;

    padding: 24px;

    display: flex;
    flex-direction: column;

    background-color: ${(props) => props.theme.colors.infoGreen};
    border-radius: 8px 8px 0 0;
  }

  & .conf-modal-upper-info-green-light {
    width: 100%;
    height: 72px;

    padding: 24px;

    display: flex;
    flex-direction: column;

    background-color: ${(props) => props.theme.colors?.brand?.primaryLight};
    border-radius: 8px 8px 0 0;
  }

  & .conf-modal-upper-orange {
    width: 100%;
    height: 72px;

    padding: 24px;

    display: flex;
    flex-direction: column;

    background-color: ${(props) => props.theme.colors.infoOrange};
    border-radius: 8px 8px 0 0;
  }

  & .conf-modal-upper-grey {
    width: 100%;
    height: 72px;

    padding: 24px;

    display: flex;
    flex-direction: column;

    background-color: ${(props) => props.theme.colors.greys.lighter};
    border-radius: 8px 8px 0 0;
  }

  & .conf-modal-upper-red {
    width: 100%;
    height: 72px;

    padding: 24px;

    display: flex;
    flex-direction: column;

    background-color: ${(props) => props.theme.colors.infoRed};
    border-radius: 8px 8px 0 0;
  }

  // --------- end modals upper

  // --------- modals title

  & .conf-modal-upper-p-blue {
    color: ${(props) => props.theme.colors.greys.lightest};
    font: ${(props) => props.theme.fonts.h1Inter};
  }

  & .conf-modal-upper-p-info-green-light {
    color: ${(props) => props.theme.colors.greys.darkest};
    font: ${(props) => props.theme.fonts.h1Inter};
  }

  & .conf-modal-upper-p-orange {
    color: ${(props) => props.theme.colors.greys.lightest};
    font: ${(props) => props.theme.fonts.h1Inter};
  }

  & .conf-modal-upper-p-grey {
    color: ${(props) => props.theme.colors.greys.darkest};
    font: ${(props) => props.theme.fonts.h1Inter};
  }

  & .conf-modal-upper-p-red {
    color: ${(props) => props.theme.colors.greys.lightest};
    font: ${(props) => props.theme.fonts.h1Inter};
  }

  // --------- end modals title

  & .conf-modal-down {
    width: 100%;

    background-color: ${(props) => props.theme.colors.greys.lightest};
    border-radius: 0 0 8px 8px;
  }

  & .conf-modal-text {
    width: 100%;

    padding: 24px;

    font: ${(props) => props.theme.fonts.textBig};
    overflow-wrap: break-word;
  }

  & .conf-down-buttons {
    width: 100%;

    padding: 0px 24px 24px 24px;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    background-color: white;

    border-radius: 0 0 8px 8px;

    & > :not(:last-child) {
      margin-right: 24px;
    }
  }

  & .conf-down-buttons.separator {
    padding: 24px;

    border: 1px solid ${(props) => props.theme.colors.greys.lighter};
  }

  // --------- --------- end confirmationsModals

  & .modal-confirm-order-content-input-container {
    padding: 30px;
    row-gap: 300px;
  }

  & .custom-tooltip-event {
    opacity: 1 !important;
    padding: 0px !important;
    border-radius: 5px !important;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1) !important;
  }
  & .app-container {
    position: absolute;
    width: 100%;
    display: block;
  }
  & .item-list-header {
    height: 80px;
    padding: 0 20px;
    background: #f8f8f8;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .item-list-title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 20px;
  }
  & .item-list-title {
    font-family: proximanovaregular;
    font-size: 20px;
    color: #000000;
    letter-spacing: 0.42px;
    margin-left: 20px;
    margin-right: 40px;
  }
  & .item-list-actions-button {
    position: relative;
    height: 36px;
    line-height: 36px;
  }
  & .items-list-actions-link:hover {
    background: #0a3eff;
    color: #ffffff;
    text-decoration: none;
  }
  & .item-list-encloser {
    min-width: 1000px;
  }
  & .item-list-content {
    width: 100%;
    min-width: 1000px;
    padding: 0 15px;
    height: calc(100vh - 112px);
    overflow: hidden;
  }
  & .item-list-undo-filters {
    position: absolute;
    align-items: right;
    right: 0;
    margin-right: 1%;
  }
  & .item-list-disable-filters {
    float: right;
    color: red;
    cursor: pointer;
    text-decoration: underline;
  }
  & .item-list-number-filter {
    padding-left: 10px;
  }
  & .item-list-content-lines-encloser {
    height: 100%;
    overflow: scroll;
    padding-bottom: 50px;
  }
  & .deepsight-item-list-bottom-navigation-bar {
    height: 32px;
    width: calc(100% - 64px);
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.05);
    position: absolute;
    bottom: 0;
    right: 0;
    margin-left: 60px;
    z-index: 11;
  }
  & .item-list-bottom-navigation-bar {
    background: #ffffff;
    height: 32px;
    width: calc(100% - 64px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.05);
    position: absolute;
    bottom: 0;
    right: 0;
  }
  & .item-list-navbar-navigator {
    width: 124px;
    height: 23px;
    border-radius: 1px;
    background: #ffffff;
    border: 1px solid rgba(107, 118, 140, 0.3);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    margin-right: calc((100% - 124px) / 2);
  }
  & .item-list-navbar-arrow > img {
    height: 10px;
  }
  & .item-list-navbar-arrow > img {
    height: 10px;
  }
  & .item-list-navbar-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 100%;
    cursor: pointer;
  }
  & .item-list-navbar-arrow.right {
    border-left: 1px solid rgba(196, 197, 199, 0.3);
  }
  & .item-list-navbar-arrow.right > img {
    transform: rotate(180deg);
  }
  & .item-list-navbar-arrow.left {
    border-right: 1px solid rgba(196, 197, 199, 0.3);
  }
  & .item-list-navbar-arrow-locked {
    opacity: 0.3;
  }
  & .item-list-navbar-arrow.locked:hover {
    background: none;
  }
  & .item-list-navbar-arrow:hover {
    background: #0a3eff;
  }
  & .item-list-navbar-arrow:hover > img {
    filter: brightness(0) invert(1);
  }
  & .item-list-navbar-page-indicator {
    font-size: 16px;
    color: #6b768c;
    text-align: center;
  }
  & .items-list-editable-tags-list-encloser {
    position: absolute;
    right: 0;
    display: flex;
    margin-top: 10px;
  }
  & .items-list-editable-tag-encloser,
  & .items-list-action-tag-encloser {
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  & .items-list-editable-tag,
  & .items-list-editable-tag-duplicate {
    width: 30px;
    height: 30px;
    border-radius: 2px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e9e9e9;
  }
  & .items-list-action-tag {
    width: 30px;
    height: 30px;
    border-radius: 2px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  & .items-list-editable-tag:hover,
  & .items-list-editable-tag-duplicate:hover {
    width: 30px;
    height: 30px;
    border-radius: 2px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: lightgray;
  }
  & .items-list-editable-tag > img {
    margin-left: 2px;
    margin-bottom: 2px;
    width: 18px;
  }
  & .items-list-editable-tag-duplicate > img {
    width: 18px;
  }
  & .items-list-editable-text-tag-encloser {
    font-size: 12px;
    color: #838d9f;
    height: 100%;
    display: flex;
    align-items: center;
  }
  & .items-list-editable-text-tag {
    padding: 8px;
    height: 30px;
    border-radius: 2px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
  }
  & .items-list-editable-text-tag:hover {
    padding: 8px;
    height: 30px;
    border-radius: 2px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6b768c;
    color: white;
  }
  & .item-list-navbar-vue-settings {
    display: flex;
  }
  & .item-list-navbar-selector {
    width: fit-content;
    min-width: 110px;
    height: 21px;
    background: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    margin-left: 15px;
    cursor: pointer;
  }
  & .item-list-navbar-selector-childrens {
    position: absolute;
    width: 150px;
    background: #ffffff;
    border-radius: 2px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    padding: 5px 0;
    bottom: 25px;
    z-index: 2;
  }
  & .item-list-navbar-selector-children {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
    font-size: 12px;
  }
  & .item-list-navbar-selector-children.selected {
    color: #0a3eff;
  }
  & .item-list-navbar-selector-children:hover {
    width: 100%;
    height: 25px;
    background: #0a3eff;
    color: #ffffff;
  }
  & .item-list-navbar-selector-selected {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & .item-list-navbar-selector-selected > p {
    margin-left: 10px;
    font-size: 12px;
    color: #6b768c;
    line-height: 21px;
    margin-top: 10px;
  }
  & .item-list-navbar-selector-selected:hover > p {
    color: #0a3eff;
  }
  & .item-list-navbar-selector-selected:hover > img {
    -webkit-filter: url(#colorize);
    filter: url(#colorize);
  }
  & .item-list-navbar-selector-icon {
    margin-right: 10px;
    width: 7px;
  }
  & .item-list-navbar-selector-icon.opened {
    transform: rotate(180deg);
  }
  & .item-list-navbar-howmany-selected {
    padding-top: 4px;
    font-size: 12px;
    color: grey;
    margin-left: 10px;
  }
  /* Items List in Details Page */
  & .details-page-item-list-title-encloser {
    display: flex;
    justify-content: flex-start;
    height: 40px;
    align-items: center;
  }

  & .main-container {
    // overflow-y: scroll;
    // float: left;
    /* width: calc(100% - 64px); */
    /* height: calc(100%-60px); */
    background-color: #f8f8f8;
  }

  & .modal-upload-suppliers-content {
    font-family: ${(props) => props.theme.fonts?.families.secondary};
  }
`;
export default StyledHome;
