import styled from 'styled-components';

export const ModalContent = styled.div`
  color: ${(props) => props.theme.colors?.greys.darker};
  font: ${(props) => props.theme.fonts?.textBigHeight24};

  .jsoneditor-popover {
    cursor: auto;
    position: absolute;

    text-align: center;
    word-break: break-word;
    white-space: pre-wrap;

    width: 300px;

    padding: 3px 8px 3px 8px;

    font: ${(props) => props.theme.fonts?.textBigHeight24};
    color: ${(props) => props.theme.colors?.greys.lightest};
    background-color: ${(props) => props.theme.colors?.greys.darkest};

    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  }

  .jsoneditor {
    border-radius: 8px;
    border: thin solid ${(props) => props.theme.colors?.greys.darkest};
  }

  .jsoneditor-menu {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    background-color: ${(props) => props.theme.colors?.greys.darkest};
    border-bottom: 1px solid ${(props) => props.theme.colors?.greys.darkest};
  }

  .jsoneditor-tree {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .jsoneditor-outer.has-main-menu-bar {
    margin-top: 0px;
    padding-top: 8px;
  }

  .jsoneditor-outer {
    max-height: 400px;

    padding: 8px 0px;

    overflow: auto;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .jsoneditor-above {
    left: -140px;
    bottom: 24px;
  }

  .jsoneditor-above:before {
    border-top: none;
  }

  .jsoneditor-left {
    top: 24px;
    left: -140px;
  }

  .jsoneditor-left:before {
    border-left: none;
  }
`;

export const Text = styled.div`
  text-align: left;
  white-space: pre-line;
  margin-bottom: 20px;
  overflow-y: scroll;
  max-height: 350px;
`;
