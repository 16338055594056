import { isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
const { getTheme } = require('@commons/utils/theme');
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { Tooltip } from '@commons/utils/styledLibraryComponents';
import { tooltipHandler } from '@commons/TimePropertiesLineGraph/utils/tooltip';
import { TooltipIcon } from '@commons/Metrics/styledComponents';
import ComponentLoader from '@commons/DeepsightComponents/ComponentLoader';
import EmptyState from '@commons/EmptyState';
import TimePropertiesLineGraph from '@commons/TimePropertiesLineGraph';

import { Container, LoadingContainer, TitleContainer } from './styledComponents';
import config from './utils/config';

const MarginEvolutionGraph = (props) => {
  const { data, currency, screenWidth, isLoading } = props;

  const theme = getTheme();

  const graphData = data.reduce(
    (result, { date, turnoverWithoutTaxes, marginWithoutTaxes }) => {
      result.x.push(date);

      result.turnover.push(turnoverWithoutTaxes);

      result.margin.push(marginWithoutTaxes);

      return result;
    },
    {
      x: [],
      turnover: [],
      margin: [],
    },
  );

  const isGraphEmpty =
    isEmpty(graphData.x) || (isEmpty(graphData.turnover) && isEmpty(graphData.margin));

  if (isLoading) {
    return (
      <LoadingContainer>
        <ComponentLoader height={24} width={24} />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <TitleContainer>
        <Tooltip
          renderTooltipTrigger={() => <TooltipIcon src={'/images/inpulse/info-dmgrey-small.svg'} />}
          text={i18next.t('ANALYSIS.MARGIN.GRAPH_EVOLUTION_TOOLTIP')}
          displayBigger
        />
        <Text
          className={'title-text'}
          color={ENUM_COLORS.DARKER}
          font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {i18next.t('ANALYSIS.MARGIN.GRAPH_EVOLUTION_TITLE')}
        </Text>
      </TitleContainer>

      {!isGraphEmpty ? (
        <TimePropertiesLineGraph
          config={config}
          customGraphStyle={{ maxHeight: '452px' }}
          granularity={'day'}
          legendConfig={{
            font: theme.fonts.textMicroWidth10Height16Weight700Bold,
            display: true,
            position: 'bottom',
            labels: [
              {
                color: theme.colors.blue,
                text: i18next.t('ANALYSIS.MARGIN.COLUMN_NAME_REVENUE'),
              },
              {
                color: theme.colors.green.lighter,
                text: i18next.t('ANALYSIS.MARGIN.COLUMN_NAME_MARGIN'),
              },
            ],
          }}
          screenWidth={screenWidth}
          tooltipHandler={(context) =>
            tooltipHandler(
              {
                ...context,
                getTooltipTitleKey: (data) =>
                  moment(data).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
                getTooltipTitleValue: (data) =>
                  `${moment(data).format(DATE_DISPLAY_FORMATS.DATE_MONTH_YEAR)}`,
              },
              data,
              currency,
            )
          }
          x={{ data: graphData.x }}
          y={{
            turnover: {
              label: i18next.t('ANALYSIS.MARGIN.COLUMN_NAME_REVENUE'),
              borderColor: theme.colors.blue,
              backgroundColor: '3, 155, 229',
              data: graphData.turnover,
            },
            margin: {
              label: i18next.t('ANALYSIS.MARGIN.COLUMN_NAME_MARGIN'),
              borderColor: theme.colors.green.lighter,
              backgroundColor: '0, 234, 132',
              data: graphData.margin,
            },
          }}
        />
      ) : (
        <EmptyState
          backgroundColor={theme.colors.white}
          label={i18next.t('DASHBOARD.FOODCOST.EMPTY_STATE_LABEL')}
        />
      )}
    </Container>
  );
};

MarginEvolutionGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      turnoverWithoutTaxes: PropTypes.number,
      realConsumption: PropTypes.number,
      realConsumptionPercentage: PropTypes.number,
      marginWithoutTaxes: PropTypes.number,
      marginWithoutTaxesPercentage: PropTypes.number,
    }).isRequired,
  ),
};

export default MarginEvolutionGraph;
