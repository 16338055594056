import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-dirextion: row;
  flex-shrink: 2;

  column-gap: 16px;

  padding: 0 0 16px 0;

  padding: ${(props) => (props.isCategoryOpen ? '0px' : '0 0 16px 0')};

  border-bottom: ${(props) => (props.isCategoryOpen ? '0px' : '1px solid #e6e6e6')};

  cursor: pointer;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 3;
`;

export const Text = styled.div`
  color: #000000;
  text-transform: capitalize;
  font: ${(props) => props.theme.fonts.h2Inter} !important;
`;
