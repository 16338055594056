import i18next from 'i18next';

export const STEP_REGISTRATION = {
  USER_INFORMATION: 'userInformation',
  USER_PASSWORD: 'userPassword',
  TOKEN_EXPIRED_STEP_1: 'tokenExpiredStep1',
  TOKEN_EXPIRED_STEP_2: 'tokenExpiredStep2',
  REGISTRATION_SUCCESS: 'registrationSuccess',
};

const UPPER_REGEX = /[A-Z]/;
const NUMBER_REGEX = /[0-9]/;
const SPECIAL_CHAR_REGEX = /[!@#$%^&*)(+=._-]/;
const LENGTH_REGEX = /.{8,}/;

export const getPasswordRegexRules = () => ({
  length: {
    regex: LENGTH_REGEX,
    message: i18next.t('AUTHENTICATION.REGISTRATION.LANDING_STEP_TWO_LENGTH_REGEX'),
    isValid: false,
  },
  specialChar: {
    regex: SPECIAL_CHAR_REGEX,
    message: i18next.t('AUTHENTICATION.REGISTRATION.LANDING_STEP_TWO_SPECIAL_CHAR_REGEX'),
    isValid: false,
  },
  upper: {
    regex: UPPER_REGEX,
    message: i18next.t('AUTHENTICATION.REGISTRATION.LANDING_STEP_TWO_UPPER_REGEX'),
    isValid: false,
  },
  number: {
    regex: NUMBER_REGEX,
    message: i18next.t('AUTHENTICATION.REGISTRATION.LANDING_STEP_TWO_NUMBER_REGEX'),
    isValid: false,
  },
  all: {
    isValid: true,
  },
});

export default {
  STEP_REGISTRATION,
  getPasswordRegexRules,
};
