import styled from 'styled-components';

export const StatContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;

  gap: 4px;
`;

export const VariationContainer = styled.div`
  display: flex;
  gap: 4px;
`;

export const LoadingIcon = styled.img`
  animation: rotation 1s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const VariationIcon = styled.img``;
