import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const EmptyIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyIcon = styled.img``;

export const EmptyTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  color: ${(props) => props.theme.colors?.greys.darker || '#6b768c'};
  text-align: center;
  font: ${(props) => props.theme.fonts?.textSmallHeight14 || ''};
`;

export default {
  Container,
  EmptyIconContainer,
  EmptyIcon,
  EmptyTextContainer,
};
