import i18next from 'i18next';
import React from 'react';

import { InpulseLabel } from '@commons/DeepsightComponents';
import { Tags } from '@commons/Tags';
import DisplayNumber from '@commons/DisplayNumber';
import Text from '@commons/Text';

const getColumns = () => [
  {
    id: 'name',
    name: i18next.t('ADMIN.PRODUCTS.CASHIER_PRODUCT_SYNC_NAME'),
    displayName: i18next.t('ADMIN.PRODUCTS.CASHIER_PRODUCT_SYNC_NAME'),
    propertyKey: 'name',
    tooltipText: i18next.t('ADMIN.PRODUCTS.SYNC_PRICE_TOOLTIP'),
    displayBigger: true,
  },
  {
    id: 'active',
    name: i18next.t('ADMIN.PRODUCTS.CASHIER_PRODUCTS_ASSOCIATION_STATUS'),
    displayName: i18next.t('ADMIN.PRODUCTS.CASHIER_PRODUCTS_ASSOCIATION_STATUS'),
    propertyKey: 'active',
    render: (active) => (
      <InpulseLabel
        color={active ? 'green' : 'red'}
        text={active ? i18next.t('GENERAL.ACTIVE') : i18next.t('GENERAL.INACTIVE')}
      />
    ),
  },
  {
    id: 'sku',
    name: i18next.t('GENERAL.SKU'),
    displayName: i18next.t('GENERAL.SKU'),
    propertyKey: 'sku',
    displayBigger: true,
  },
  {
    id: 'cashierConfigName',
    displayBigger: true,
    name: i18next.t('GENERAL.CASHIER_CONNEXION'),
    displayName: i18next.t('GENERAL.CASHIER_CONNEXION'),
    propertyKey: 'lnkCashierconfigCashierproductrel.name',
  },
  {
    id: 'tags',
    name: i18next.t('ADMIN.PRODUCTS.CREATE_PRODUCT_MODAL_CASHIER_PRODUCT_ASSOCIATION_TAGS'),
    displayName: i18next.t('ADMIN.PRODUCTS.CREATE_PRODUCT_MODAL_CASHIER_PRODUCT_ASSOCIATION_TAGS'),
    propertyKey: 'tags',
    displayBigger: true,
    render: (items) => <Tags items={items} />,
  },
  {
    id: 'priceWithTaxes',
    displayName: i18next.t('ADMIN.PRODUCTS.CASHIER_PRODUCTS_ASSOCIATION_PRICE'),
    name: i18next.t('ADMIN.PRODUCTS.CASHIER_PRODUCTS_ASSOCIATION_PRICE'),
    propertyKey: 'priceWithTaxes',
    filterType: 'numeric',
    tooltipText: i18next.t(
      'ADMIN.PRODUCTS.CREATE_PRODUCT_MODAL_CASHIER_PRODUCT_ASSOCIATION_ON_SITE_PRICE',
    ),
    render: (priceWithTaxes) => (
      <DisplayNumber displayCurrencyCode={true} number={priceWithTaxes} />
    ),
  },
  {
    id: 'vatRate',
    displayName: i18next.t('ADMIN.PRODUCTS.CASHIER_PRODUCTS_ASSOCIATION_VAT'),
    name: i18next.t('ADMIN.PRODUCTS.CASHIER_PRODUCTS_ASSOCIATION_VAT'),
    propertyKey: 'vatRate',
    filterType: 'numeric',
    render: (vatRate) => <Text>{vatRate}%</Text>,
  },
];

export default getColumns;
