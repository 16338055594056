import { DATE_PICKER_DOT_COLOR } from '../constants';
import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  padding: 16px 22px;
  border-radius: 8px;
  gap: 16px;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  background-color: ${(props) => props.theme.colors.greys.lightest};
`;

export const DotInformationContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const DayContainer = styled.div`
  z-index: 15;
  width: 40px;
  height: 40px;
  border: 1px solid ${(props) => props.theme.colors.greys.light};
  background: ${(props) => props.theme.colors.greys.lighter};
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.color === DATE_PICKER_DOT_COLOR.RED
      ? props.theme.colors.greys.dark
      : props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.textSmall} !important;
`;

export const Text = styled.div`
  font: ${(props) => props.theme.fonts.textSmall};
`;
