import { isEmpty } from 'lodash';
import { Page, Document, Image, View, Text, Font } from '@react-pdf/renderer';
import i18next from 'i18next';
import moment from 'moment';
import React from 'react';

import { getUserTimezone } from '@commons/utils/date';

import FontInterBold from '@fonts/inpulse/Inter-Bold.ttf';
import FontInterMedium from '@fonts/inpulse/Inter-Medium.ttf';

import { STOCK_DATA_TYPES } from '../../../constants/types';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { headerStyle, footerStyle, pageStyle } from './styleSheets';
import { LogoInpulseFull, LogoInpulse } from './logoInpulse';
import RecipesPDFList from './recipesPDFList';
import SupplierProductsPDFList from './supplierProductsPDFList';

const PDFInventoryRender = (props) => {
  const {
    clientPicture,
    currentStoreName,
    stockConvention,
    stockDate,
    supplierProducts,
    currency,
    filterBy,
    selectedStorageArea = {},
    selectedInventoryListTemplate,
    selectedMetric,
    recipes,
  } = props;

  const MAX_STRING_CHARACTERS = 17;

  const REGEX_IS_PNG_FILE = /(.png)$/;

  const userTimezonedNow = moment
    .tz(getUserTimezone())
    .format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR_HOUR_MINUTES);

  Font.register({
    family: 'Inter',
    format: 'truetype',
    fonts: [
      {
        src: FontInterMedium,
      },
      {
        src: FontInterBold,
      },
    ],
  });

  // the twenty or so lines that follow avoid an overlap between the SKU and the name if the SKU is too long

  const parseWords = (str, size) => {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, j = 0; i < numChunks; ++i, j += size) {
      chunks[i] = str.substr(j, size);
    }
    return chunks;
  };

  Font.registerHyphenationCallback((word) => {
    if (!word) {
      return;
    }
    if (word.length > MAX_STRING_CHARACTERS) {
      return parseWords(word, MAX_STRING_CHARACTERS);
    }
    return [word];
  });

  const useInpulseLogo =
    !clientPicture || (clientPicture && clientPicture.match(REGEX_IS_PNG_FILE)) ? true : false;

  const Header = () => (
    <View>
      <View style={headerStyle.header}>
        <View style={headerStyle.titleContainer}>
          <View style={headerStyle.imageBorderContainer}>
            <View style={headerStyle.imageContainer}>
              {!useInpulseLogo ? (
                <Image src={clientPicture} style={headerStyle.clientPicture} />
              ) : (
                <LogoInpulse style={headerStyle.clientPicture} />
              )}
            </View>
          </View>
          <View>
            <Text style={headerStyle.title}>
              {i18next.t(
                selectedMetric.key === STOCK_DATA_TYPES.SUPPLIER_PRODUCT
                  ? 'STOCKS.STOCKS.INGREDIENTS_INVENTORY_SHEET'
                  : 'STOCKS.STOCKS.RECIPES_INVENTORY_SHEET',
              )}
            </Text>
          </View>
        </View>
      </View>
      <View style={headerStyle.subHeader}>
        <Text style={headerStyle.subTitle}>{`${i18next.t(
          'GENERAL.STORE',
        )} : ${currentStoreName}`}</Text>
        <View style={headerStyle.gap8px}>
          <Text style={headerStyle.conventionAndDate}>{`${i18next.t(
            'GENERAL.INVENTORY_TEMPLATE',
          )} : ${selectedInventoryListTemplate.name}`}</Text>
          <Text style={headerStyle.conventionAndDate}>{`Convention : ${stockConvention} `}</Text>
          <Text style={headerStyle.conventionAndDate}>{`Date : ${moment(stockDate).format(
            'LL',
          )}`}</Text>
          <Text style={headerStyle.conventionAndDate}>{`Type : ${
            selectedMetric.key === STOCK_DATA_TYPES.SUPPLIER_PRODUCT
              ? i18next.t('GENERAL.INGREDIENT_PLURAL')
              : i18next.t('GENERAL.RECIPE_PLURAL')
          } `}</Text>
          {!isEmpty(selectedStorageArea) && (
            <Text style={headerStyle.conventionAndDate}>{`${i18next.t(
              'GENERAL.STORAGE_AREA_SINGLE',
            )} : ${selectedStorageArea.name}`}</Text>
          )}
        </View>
      </View>
    </View>
  );

  const InventoryPDF = () => {
    const shouldDisplayPDFList =
      (selectedMetric.key === STOCK_DATA_TYPES.SUPPLIER_PRODUCT && supplierProducts.length) ||
      (selectedMetric.key === STOCK_DATA_TYPES.RECIPE && recipes.length);

    return (
      <Document>
        {shouldDisplayPDFList ? (
          <Page size="A4" style={pageStyle.page} wrap>
            <Header />
            {selectedMetric.key === STOCK_DATA_TYPES.SUPPLIER_PRODUCT ? (
              <SupplierProductsPDFList
                currency={currency}
                filterBy={filterBy}
                supplierProducts={supplierProducts}
              />
            ) : (
              <RecipesPDFList currency={currency} recipes={recipes} />
            )}
            <Text
              render={({ pageNumber, totalPages }) =>
                `${i18next.t('STOCKS.STOCKS.INVENTORY_SHEET_PAGE')} ${pageNumber}/${totalPages}`
              }
              style={footerStyle.pageNumber}
              fixed
            />

            <Text render={() => `${userTimezonedNow}`} style={footerStyle.timestamp} fixed />

            <View style={footerStyle.imageContainer} fixed>
              <LogoInpulseFull style={footerStyle.clientPicture} />
            </View>
          </Page>
        ) : (
          <Page size="A4" style={pageStyle.page}>
            <Header />
          </Page>
        )}
      </Document>
    );
  };

  return <InventoryPDF />;
};

export default PDFInventoryRender;
