import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { getClientInfo } from '@selectors/client';

import clientService from '@services/client';

const SUPPLIER_PRODUCTS_TO_UPDATE_AT_ONCE = 50;

export const ImportSpPricesModal = (props) => {
  const {
    client: { clientId },
    params: { supplierProducts, title, refreshPage },
    closeModal,
    showSuccessMessage,
    showErrorMessage,
  } = props;

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState(title || '');
  const [updatedSpNb, setUpdatedSpNb] = useState(0);

  useEffect(() => {
    if (isLoading && updatedSpNb < supplierProducts.length) {
      const nextBatchOfSpToUpdate = supplierProducts.slice(
        updatedSpNb,
        updatedSpNb + SUPPLIER_PRODUCTS_TO_UPDATE_AT_ONCE,
      );

      (async () => {
        try {
          await clientService.importSupplierProductsUpdatedPrices(clientId, nextBatchOfSpToUpdate);

          const updatedSupplierProductsNb = updatedSpNb + nextBatchOfSpToUpdate.length;

          setUpdatedSpNb(updatedSupplierProductsNb);
          updateProgress(updatedSupplierProductsNb);
        } catch (error) {
          setModalTitle(
            i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_ERROR'),
          );
          setIsLoading(false);
          showErrorMessage(
            i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_ERROR'),
          );
        }
      })();

      return;
    }

    setModalTitle(
      i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_SUCCESS'),
    );
    setIsLoading(false);
    showSuccessMessage(
      i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_SUCCESS'),
    );
  }, [updatedSpNb]);

  const exitModal = () => {
    setIsLoading(false);

    refreshPage();
    closeModal();
  };

  const updateProgress = (totalUpdated) => {
    const totalSPs = supplierProducts.length;
    const supplierProductsLeft = totalSPs - totalUpdated;
    const updatedProgress = 100 - (supplierProductsLeft / totalSPs) * 100;

    setProgress(updatedProgress);
  };

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal} // passed from openSmallModal
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setIsLoading={setIsLoading}
      titleModal={modalTitle}
    />
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportSpPricesModal);
