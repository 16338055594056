import {
  Line,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ComposedChart,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

import { formatNumber } from '@commons/DisplayNumber';

import { StyledCustomLegend } from '@lib/inpulse/ChartLegend';
import { StyledTooltip } from '@lib/inpulse/BarChartTooltip';

import theme from '@theme';

const customLegend = (props) => {
  const { payload } = props;
  return (
    <div className="error-forecast-chart-legend-container">
      {payload.map((item, index) => {
        if (Object.keys(item).length !== 0) {
          let background = { backgroundColor: item.color };

          if (item.type === 'none') {
            background = {
              background:
                'repeating-linear-gradient(' +
                'to right,' +
                item.color +
                ',' +
                item.color +
                ' 2px,' +
                '#fff 2px,' +
                '#fff 5px)',
            };
          }
          if (item.color2) {
            background = {
              backgroundImage:
                'linear-gradient(to bottom, ' + item.color + ', ' + item.color2 + ')',
            };
          }
          return (
            <div className="error-forecast-chart-legend-item-container" key={index}>
              <div className={'error-forecast-chart-legend'} style={background} />
              <div className="error-forecast-chart-legend-value">{item.value}</div>
            </div>
          );
        } else {
          return '';
        }
      })}
    </div>
  );
};

const customTooltip = ({
  payload,
  eventsObject,
  weatherObject,
  managerPrevDisplay,
  metricName,
  storeTimezone,
}) => {
  if (!payload) {
    return '';
  } else {
  }
  let timestamp, error;
  if (payload.length > 0) {
    timestamp = payload[0].payload.timestamp;
    error = payload[0].payload.error;
  }
  return (
    <div className="barchart-tooltip-container" style={{ height: 200 }}>
      <div className="barchart-tooltip-header">
        {moment.tz(timestamp, storeTimezone).format('dddd DD MMMM YYYY')}
      </div>
      <div className="barchart-tooltip-body">
        <div className="barchart-tooltip-left">
          {payload.map((item, index) => {
            if (item.name === 'dynamic') {
              return (
                <div className="tooltip-past-error-item" key={item.name + item.timestamp}>
                  <h3 style={{ color: item.color }}>{formatNumber(item.value, 0, true)}</h3>
                  <h4 style={{ color: item.color }}>Inpulse</h4>
                </div>
              );
            }
            if (item.name === 'saved') {
              return (
                <div className="tooltip-past-error-item" key={item.name + item.timestamp}>
                  <h3 style={{ color: item.color }}>{formatNumber(item.value, 0, true)}</h3>
                  <h4 style={{ color: item.color }}>
                    {i18next.t('FORECAST.SALES.PRECISON_CHART_LABEL_MANAGER')}
                  </h4>
                </div>
              );
            }
            if (item.name === 'sales') {
              return (
                <div className="tooltip-past-error-item" key={item.name + item.timestamp}>
                  <h3 style={{ color: item.color }}>
                    {isNaN(item.value) ? 0 : formatNumber(item.value, 0, true)}
                  </h3>
                  <h4 style={{ color: item.color }}>{metricName}</h4>
                </div>
              );
            }
            return (
              <div className="tooltip-past-error-item" key={item.name + item.timestamp}>
                <h3 style={{ color: item.color }}>{formatNumber(item.value, 0, true)}</h3>
                <h4 style={{ color: item.color }}>{item.name}</h4>
              </div>
            );
          })}
          {error !== null && (
            <div className="tooltip-past-error-item" key={'error' + timestamp}>
              <h3
                style={{
                  color: error >= 0 ? '#20DAAA' : '#FF0000',
                }}
              >
                {formatNumber(error, 0, true)}
              </h3>
              <h4
                style={{
                  color: error >= 0 ? '#20DAAA' : '#FF0000',
                }}
              >
                {managerPrevDisplay
                  ? i18next.t('FORECAST.SALES.CHART_PAST_ERROR_LABEL_GAP_MANAGER')
                  : i18next.t('FORECAST.SALES.CHART_PAST_ERROR_LABEL_GAP')}
              </h4>
            </div>
          )}
        </div>
        <div className="barchart-tooltip-right">
          <div className="barchart-tooltip-weather-container">
            <div className="barchart-tooltip-weather-temperature">
              {weatherObject[timestamp] && weatherObject[timestamp].temperature} °C
            </div>
            <img
              alt=""
              className="barchart-tooltip-weather-icon"
              src={weatherObject[timestamp] && weatherObject[timestamp].img}
            />
          </div>
          <div className="barchart-tooltip-events-container">
            <h2>{i18next.t('FORECAST.SALES.CHART_TITLE_EVENTS')}</h2>
            {eventsObject[timestamp] && eventsObject[timestamp].length === 0 ? (
              <div className="barchart-tooltip-event-empty-state">
                {i18next.t('FORECAST.TURNOVER.CHART_NO_EVENT')}
              </div>
            ) : (
              <div />
            )}
            {eventsObject[timestamp] &&
              eventsObject[timestamp].map((event) => (
                <div className="barchart-tooltip-event-item" key={event.id}>
                  <img
                    alt=""
                    className="barchart-tooltip-event-icon"
                    src={event.eventSubCategory.img}
                  />
                  <div className="barchart-tooltip-event-name">{event.input1}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const hiddenTooltip = (props) => null;

const DeepsightPastErrorForecastChart = (props) => {
  const {
    data,
    width,
    height,
    legend,
    bar_color,
    daysToDisplay,
    eventsObject,
    weatherObject,
    managerPrevDisplay,
    metricName,
    storeTimezone,
  } = props;

  const getBarSize = () => {
    if (daysToDisplay <= 7) return 10;
    else if (daysToDisplay <= 14) return 8;
    else if (daysToDisplay <= 30) return 6;
    return 4;
  };

  const { minValue, maxValue } = data.reduce((result, item) => {
    ['sales', 'saved', 'dynamic'].forEach((propertyKey) => {
      if (item[propertyKey] == null) {
        return result;
      }

      const value = +item[propertyKey];

      if (!result) {
        result = { minValue: value, maxValue: value };

        return result;
      }

      if (value > result.maxValue) {
        result.maxValue = value;
      }

      if (value < result.minValue) {
        result.minValue = value;
      }
    });

    return result;
  }, null) || { minValue: 0, maxValue: 0 };

  return (
    <StyledTooltip>
      <StyledCustomLegend>
        <div style={{ width: '100%', height: '500px' }}>
          {data.length === 0 ? (
            <div className="bar-chart-empty-state" style={{ height: height * 0.55 }}>
              {i18next.t('HOME.NO_DATA_TO_DISPLAY')}
            </div>
          ) : (
            <div>
              <ResponsiveContainer height={height * 0.55} minWidth={400} width="100%">
                <ComposedChart
                  data={data}
                  height={height}
                  margin={{ top: 20, right: 30 }}
                  width={width}
                >
                  <CartesianGrid stroke="#EDF0F6" strokeWidth={1} vertical={false} />
                  <Line
                    dataKey={'sales'}
                    dot={false}
                    stroke={legend[2].color}
                    strokeWidth={2}
                    type="monotone"
                  />
                  <Line
                    dataKey={'dynamic'}
                    dot={false}
                    stroke={legend[0].color}
                    strokeWidth={2}
                    type="monotone"
                  />
                  <Line
                    dataKey={'saved'}
                    dot={false}
                    stroke={legend[1].color}
                    strokeWidth={2}
                    type="monotone"
                  />
                  <XAxis dataKey="x" tick={false} />
                  <YAxis
                    allowDataOverflow={true}
                    axisLine={false}
                    domain={[minValue * 0.9, maxValue * 1.1]}
                    tick={{
                      fill: '#6B768C',
                      fontSize: 12,
                      fontFamily: theme.fonts.families.secondary,
                    }}
                    tickFormatter={(value) => formatNumber(value, 0, true)}
                    type="number"
                  />
                  <Tooltip
                    content={customTooltip}
                    eventsObject={eventsObject}
                    legend={legend}
                    managerPrevDisplay={managerPrevDisplay}
                    metricName={metricName}
                    storeTimezone={storeTimezone}
                    weatherObject={weatherObject}
                  />
                </ComposedChart>
              </ResponsiveContainer>
              <ResponsiveContainer height={height * 0.4} minWidth={400} width="100%">
                <ComposedChart
                  data={data}
                  height={height}
                  margin={{ top: 20, right: 30 }}
                  width={width}
                >
                  <defs>
                    <linearGradient id="gradient_grey" x1="0" x2="0" y1="0" y2="1">
                      <stop offset="0%" stopColor="#697BBE" />
                      <stop offset="100%" stopColor="#A5D0C6" />
                    </linearGradient>
                  </defs>
                  <defs>
                    <linearGradient id="gradient_green" x1="0" x2="0" y1="0" y2="1">
                      <stop offset="0%" stopColor="#DCFA5E" />
                      <stop offset="100%" stopColor="#6BE282" />
                    </linearGradient>
                  </defs>
                  <defs>
                    <linearGradient id="gradient_red" x1="0" x2="0" y1="0" y2="1">
                      <stop offset="0%" stopColor="#E70000" />
                      <stop offset="100%" stopColor="#FFA600" />
                    </linearGradient>
                  </defs>
                  <CartesianGrid stroke="#EDF0F6" strokeWidth={1} vertical={false} />
                  <Bar
                    barSize={getBarSize()}
                    dataKey={'error'}
                    radius={[getBarSize() / 2, getBarSize() / 2, 0, 0]}
                  >
                    {data.map((entry, index) => {
                      let color = '';
                      if (bar_color === 'green/red') {
                        color = entry.error > 0 ? '#20DAAA' : '#FF0000';
                      } else {
                        color = 'grey';
                      }
                      return <Cell fill={color} key={index} />;
                    })}
                  </Bar>
                  <Legend content={customLegend} payload={legend} />
                  <XAxis
                    axisLine={false}
                    dataKey="timestamp"
                    dy={5}
                    height={55}
                    tick={{
                      fill: theme.colors.greys.darker,
                      fontSize: 12,
                      fontFamily: theme.fonts.families.secondary,
                    }}
                    tickFormatter={(n) => moment.tz(n, storeTimezone).format('DD/MM')}
                    tickLine={false}
                  />
                  <YAxis
                    axisLine={false}
                    domain={['auto', 'auto']}
                    tick={{
                      fill: theme.colors.greys.darker,
                      fontSize: 12,
                      fontFamily: theme.fonts.families.secondary,
                    }}
                    tickFormatter={(value) => formatNumber(value, 0, true)}
                    tickLine={false}
                    type="number"
                  />
                  <ReferenceLine label={{}} stroke="#6B768C" y={0} />
                  <Tooltip content={hiddenTooltip} />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
      </StyledCustomLegend>
    </StyledTooltip>
  );
};

export default DeepsightPastErrorForecastChart;
