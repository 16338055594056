import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';

import {
  closeGenericModal,
  openGenericModal,
  openSmallModal,
  refreshGenericModal,
} from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showSuccessMessage, showErrorMessage } from '@actions/messageconfirmation';

import { GENERIC_MODAL_CANCEL_BUTTON } from '@commons/Modals/GenericModal/genericModalActions';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { isUserManager, isUserDeepsight } from '@commons/utils/roles';
import { ListView, Button } from '@commons/utils/styledLibraryComponents';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import { translateUnit } from '@commons/utils/translateUnit';
import EmptyState from '@commons/EmptyState';
import GeneralEmptyStateListView from '@commons/GeneralEmptyStateListView';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { CentralKitchenRecipeContext } from '@context/CentralKitchenRecipeContext';
import { RecipeContext } from '@context/RecipeContext';

import useLocalStorage from '@hooks/useLocalStorage';

import { getClientInfo } from '@selectors/client';

import { brand as brandService } from '@services/brand';
import centralService from '@services/central';
import recipeService from '@services/recipe';

import { CHOICES_DROPDOWN_ACTIVE } from '@admin/utils/DropdownItems';
import { getIsInCentralKitchenOnlyPage } from '@admin/utils/centralKitchen';
import DeepsightFiltersButton from '@admin/components/FilterButton';

import { getActions, getRowActions } from './utils/actions';
import { getAvailabilitiesModalConfig } from './utils/availabitiliesModalContent';
import { getColumns } from './utils/columns';
import { getDeleteRecipesModalParams } from './utils/modalConfigurations';
import { ListViewContainer } from './styledComponents';
import ExportRecipeModal from './components/ExportRecipeModal';

moment.locale('fr');

export const Recipes = (props) => {
  const {
    user,
    pageLoading,
    pageLoaded,
    showSuccessMessage,
    showErrorMessage,
    openGenericModal,
    openModalExportInfo,
    history,
    client: { clientId, hasMultipleChannels, hasMultipleBrands },
    match: { path },
    refreshGenericModal,
    closeGenericModal,
  } = props;

  // Global states - const
  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');
  const isInCentralKitchenOnlyPage = getIsInCentralKitchenOnlyPage();

  const contextHandler =
    useContext(isInCentralKitchenOnlyPage ? CentralKitchenRecipeContext : RecipeContext) || {};

  const {
    // Applied filters
    selectedStatus,
    setSelectedStatus,
    selectedBrands,
    setSelectedBrands,
    // Advanced filters
    advancedFilters,
    setAdvancedFilters,
    // ListView params
    currentPage,
    setCurrentPage,
    maxPerPage,
    setMaxPerPage,
    orderBy,
    setOrderBy,
    orderType,
    setOrderType,
    search,
    setSearch,
  } = contextHandler;

  const [isInCentralMode] = useLocalStorage('isCentralMode', false);
  const [emptyState, setEmptyState] = useState(false);

  // ListView states - const

  const [columns, setColumns] = useState([]);

  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Filter states
  const [brands, setBrands] = useState([]);
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [columnsFilterList, setColumnsFilterList] = useState([]);

  // Data states
  const [recipes, setRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [selectedRecipes, setSelectedRecipes] = useState([]);
  const [selectedAvailabilities, setSelectedAvailabilities] = useState({
    hasStock: true,
    hasLoss: true,
  });

  useEffect(() => {
    getRecipes();
  }, []);

  // First render (not exactly), to fetch brands if multiple brands
  useEffect(() => {
    if (!clientId) {
      return;
    }

    (async () => {
      if (hasMultipleBrands) {
        await getBrandsOfClient();
      }

      setColumns(getColumns(hasMultipleBrands));
    })();
  }, [clientId, hasMultipleBrands]);

  useEffect(() => {
    updateColumnsFilterList(recipes);
  }, [columns]);

  useEffect(() => {
    if (!selectedAvailabilities) {
      return;
    }

    const params = getAvailabilitiesModalConfig({
      handleAvailabilitiesUpdate,
      selectedItems: selectedRecipes,
      selectedAvailabilities,
      setSelectedAvailabilities,
      handleAvailabilitiesModalCloseCleanUp,
    });

    refreshGenericModal(params);
  }, [selectedAvailabilities]);

  useEffect(() => {
    const filteringOnActive = get(selectedStatus, 'filterValue', true);

    setActions(
      getActions({
        selectedItems: selectedRecipes,
        createRecipe,
        duplicateRecipe,
        handleDeletionWarningGenericModal,
        exportRecipes: triggerExport,
        filteringOnActive,
        openGenericModal,
        enableRecipe,
        user,
        disableRecipes,
        activateMultipleChannels,
        clientHasMultipleChannels: hasMultipleChannels,
        openAvailabilitiesUpdateModal,
      }),
    );

    setRowActions(
      getRowActions({
        duplicateRecipe,
        handleDeletionWarningGenericModal,
        filteringOnActive,
        openGenericModal,
        enableRecipe,
        user,
        disableRecipes,
        openAvailabilitiesUpdateModal,
      }),
    );
  }, [recipes, selectedRecipes, filteredRecipes, selectedStatus, selectedAvailabilities]);

  useEffect(() => {
    if (isEmpty(contextHandler)) {
      return;
    }

    processAdvancedFilters(recipes);
  }, [selectedStatus, advancedFilters, applyFilters, selectedBrands]);

  const goToRecipeDetailsPage = (recipe) => {
    const path = getIsInCentralKitchenOnlyPage()
      ? `/admin/kitchen-products/recipes/${recipe.id}/details`
      : `/admin/products/recipes/${recipe.id}/details`;

    history.push(path);
  };

  const preprocessRecipes = (recipes) => {
    const formattedRecipes = recipes.map((recipe) => {
      recipe.unit = translateUnit(recipe.unit) || '';
      recipe.id = recipe.id || '';
      recipe.name = recipe.name || '';
      recipe.quantity = recipe.quantity || '';
      recipe.mappingCount = recipe.mappingCount || null;
      recipe.mappings = recipe.mappings || [];
      recipe.code = recipe.code || null;
      recipe.category = recipe.category || '';

      return recipe;
    });

    processAdvancedFilters(formattedRecipes);

    return formattedRecipes;
  };

  const formatRecipesUnits = (recipes) => {
    const formattedRecipes = recipes.map((recipe) => ({
      ...recipe,
      unit: translateUnit(recipe.unit),
    }));

    return formattedRecipes;
  };

  const createRecipe = () => {
    const path = getIsInCentralKitchenOnlyPage()
      ? '/admin/kitchen-products/recipes/create'
      : '/admin/products/recipes/create';

    history.push(path);
  };

  const duplicateRecipe = (recipes) => {
    const path = getIsInCentralKitchenOnlyPage()
      ? '/admin/kitchen-products/recipes/create'
      : '/admin/products/recipes/create';

    history.push({
      pathname: path,
      recipe: recipes[0],
      type: 'duplicate',
    });
  };

  const activateMultipleChannels = (selectedRecipes) => {
    const handleAction = async () => {
      pageLoading();

      try {
        const recipeIds = selectedRecipes.map(({ id }) => id);

        await recipeService.activateMulitpleChannelsByIds(recipeIds);

        showSuccessMessage(i18next.t('ADMIN.RECIPES.ACTIVATE_MULTIPLE_CHANNELS_ACTION_SUCCESS'));

        await getRecipes();
      } catch {
        showErrorMessage(i18next.t('ADMIN.RECIPES.ACTIVATE_MULTIPLE_CHANNELS_ACTION_FAILURE'));
      }

      pageLoaded();
    };

    const actions = [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        key: 0,
        color: 'inpulse-default',
        handleClick: handleAction,
        label: i18next.t('GENERAL.PRE_FILL'),
        icon: '/images/inpulse/bolt-white-small.svg',
      },
    ];

    const params = getConfirmationModal({
      title: i18next.t('ADMIN.RECIPES.ACTIVATE_MULTIPLE_CHANNELS_MODAL_TITLE'),
      icon: '/images/inpulse/bolt-white-small.svg',
      actions,
      content: i18next.t('ADMIN.RECIPES.ACTIVATE_MULTIPLE_CHANNELS_MODAL_CONTENT'),
    });

    openGenericModal(params);
  };

  const getBrandsOfClient = async () => {
    try {
      const result = await brandService.getBrandsOfClient(clientId);

      const noneDropdownBrandValue = {
        id: -1,
        clientId,
        name: i18next.t('GENERAL.SELECT_NONE_FEMININE'),
      };

      if (result.length) {
        result.unshift(noneDropdownBrandValue);
      }

      setBrands(result);

      if (!!contextHandler && !isEmpty(selectedBrands)) {
        return;
      }

      if (setSelectedBrands) {
        setSelectedBrands(result);
      }
    } catch (err) {
      setBrands([]);

      if (setSelectedBrands) {
        setSelectedBrands([]);
      }

      showErrorMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_BRANDS_FAILURE'));
    }
  };

  const getRecipes = async () => {
    try {
      const recipes = getIsInCentralKitchenOnlyPage()
        ? await centralService.getKitchenRecipesOfClient(clientId)
        : await recipeService.getRecipesOfClient(clientId);

      if (!recipes || !recipes.length) {
        setRecipes([]);
        setEmptyState(true);

        return;
      }

      const formattedRecipes = formatRecipesUnits(
        preprocessRecipes(sortArrayOfObjectsAlphabetically(recipes, 'name')),
      );

      setRecipes(formattedRecipes);
    } catch (err) {
      showErrorMessage(i18next.t('ADMIN.RECIPES.LOAD_ERROR'));

      setRecipes([]);
    } finally {
      setIsLoading(false);
    }
  };

  const processAdvancedFilters = (recipes) => {
    if (!applyFilters) {
      return;
    }

    let filteredSelectedRecipes = recipes.filter(
      (recipe) => recipe.active === get(selectedStatus, 'filterValue'),
    );

    const selectedBrandIds = selectedBrands.map(({ id }) => id);

    if (!isEmpty(selectedBrandIds)) {
      // need to filter by brands only if at least one brand is selected (or None/-1 is selected)
      const includeNone = selectedBrandIds.includes(-1);

      filteredSelectedRecipes = filteredSelectedRecipes.filter((recipe) => {
        const recipeBrandIds = recipe.brands.map(({ id }) => id);

        return (
          (includeNone && !recipeBrandIds.length) || // keep recipes without brands if none is selected
          recipeBrandIds.some((id) => selectedBrandIds.includes(id)) // keep recipes with selected brands
        );
      });
    }

    if ((!advancedFilters || !advancedFilters.length) && applyFilters) {
      setFilteredRecipes(filteredSelectedRecipes);
      return;
    }

    const filteredRecipesListWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, propertyKey, value, filterType, list }) => {
        if (filterType === 'string' && value && value.length === 1 && value[0].id === 'none') {
          return doFilter(result, propertyKey, list, true);
        }

        return doFilter(result, propertyKey, value);
      },
      filteredSelectedRecipes,
    );

    setFilteredRecipes(filteredRecipesListWithAdvancedFilters);
  };

  const updateColumnsFilterList = (recipes) => {
    const updatedColumnsList = columns.reduce((result, column) => {
      if (!column.filterType) {
        return result;
      }

      const item = { ...column };

      if (column.filterType === 'string') {
        const choices = recipes.reduce((list, recipe, key) => {
          if (recipe[column.propertyKey]) {
            if (typeof recipe[column.propertyKey] === 'object') {
              recipe[column.propertyKey].forEach((elem, index) => {
                if (!list[elem]) {
                  list[elem] = {
                    id: index,
                    name: elem,
                    [column.propertyKey]: elem,
                  };
                }
              });
            } else {
              if (!list[recipe[column.propertyKey]]) {
                list[recipe[column.propertyKey]] = {
                  id: key,
                  name: recipe[column.propertyKey],
                  [column.propertyKey]: recipe[column.propertyKey],
                };
              }
            }
          }
          return list;
        }, {});

        if (!Object.keys(choices).length) {
          return result;
        }

        item.list = sortArrayOfObjectsAlphabetically(Object.values(choices), 'name');
      }

      result.push(item);

      return result;
    }, []);

    setColumnsFilterList(updatedColumnsList);
  };

  const disableRecipes = async (recipes) => {
    const recipeIds = recipes.map((recipe) => recipe.id);

    pageLoading();

    try {
      await recipeService.disableRecipes(recipeIds);

      await getRecipes();

      showSuccessMessage(
        recipeIds.length === 1
          ? i18next.t('ADMIN.RECIPES.SINGLE_DISABLE_SUCCESS')
          : i18next.t('ADMIN.RECIPES.MULTIPLE_DISABLE_SUCCESS'),
      );
    } catch (error) {
      showErrorMessage(
        recipeIds.length === 1
          ? i18next.t('ADMIN.RECIPES.SINGLE_DISABLE_ERROR')
          : i18next.t('ADMIN.RECIPES.MULTIPLE_DISABLE_ERROR'),
      );
    } finally {
      pageLoaded();
    }
  };

  const enableRecipe = async (recipeToEnable) => {
    const recipeToEnableId = recipeToEnable.id;

    pageLoading();

    try {
      await recipeService.enableRecipe(recipeToEnableId);

      await getRecipes();

      showSuccessMessage(i18next.t('ADMIN.RECIPES.ENABLE_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('ADMIN.RECIPES.ENABLE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleDeletionWarningGenericModal = (recipesToDelete) => {
    const modalConfig = getDeleteRecipesModalParams(recipesToDelete, deleteDisabledRecipes);

    openGenericModal(modalConfig);
  };

  const deleteDisabledRecipes = async (recipes) => {
    const recipeIds = recipes.map((recipe) => recipe.id);

    pageLoading();
    try {
      await recipeService.deleteDisabledRecipes(recipeIds);

      const message =
        recipeIds.length === 1
          ? i18next.t('ADMIN.RECIPES.DELETE')
          : i18next.t('ADMIN.RECIPES.DELETE_PLURAL');

      await getRecipes();
      showSuccessMessage(message);
    } catch {
      const message =
        recipeIds.length === 1
          ? i18next.t('ADMIN.RECIPES.SINGLE_DELETE_ERROR')
          : i18next.t('ADMIN.RECIPES.MULTIPLE_DELETE_ERROR');

      showErrorMessage(message);
    } finally {
      pageLoaded();
    }
  };

  const triggerExport = async () => {
    const recipesIds = selectedRecipes.length
      ? selectedRecipes.map(({ id }) => id)
      : filteredRecipes.map(({ id }) => id);

    openModalExportInfo({
      component: ExportRecipeModal,
      title: i18next.t('ADMIN.RECIPES.EXPORT'),
      recipesIds,
      isInCentralMode,
    });
  };

  const handleAvailabilitiesUpdate = async (recipes, availabilities) => {
    pageLoading();

    try {
      const recipeIds = recipes.map(({ id }) => id);

      await recipeService.updateRecipesAvailabilities(
        recipeIds,
        availabilities.hasStock,
        availabilities.hasLoss,
      );

      await getRecipes();

      showSuccessMessage(i18next.t('ADMIN.RECIPES.AVAILABILITIES_SUCCESSFULLY_UPDATED'));
      handleAvailabilitiesModalCloseCleanUp();
    } catch {
      showErrorMessage(i18next.t('ADMIN.RECIPES.AVAILABILITIES_UPDATE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleAvailabilitiesModalCloseCleanUp = () => {
    setSelectedAvailabilities({
      hasStock: true,
      hasLoss: true,
    });

    closeGenericModal();
  };

  const openAvailabilitiesUpdateModal = async (selectedItems) => {
    setSelectedRecipes(selectedItems);

    const params = getAvailabilitiesModalConfig({
      handleAvailabilitiesUpdate,
      selectedItems,
      selectedAvailabilities,
      setSelectedAvailabilities,
      handleAvailabilitiesModalCloseCleanUp,
    });

    openGenericModal(params);
  };

  const renderEmptyState = () => (
    <GeneralEmptyStateListView
      icon={'/images/inpulse/recipe.svg'}
      renderAction={() => (
        <Button
          color="inpulse-default"
          handleClick={createRecipe}
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('ADMIN.RECIPES.CREATE')}
        />
      )}
      subtitle={i18next.t('ADMIN.RECIPES.SUBTITLE')}
      title={i18next.t('ADMIN.RECIPES.TITLE')}
    />
  );

  const getCustomMultipleDropdowns = () => {
    if (!hasMultipleBrands || !brands) {
      return [];
    }

    return [
      {
        id: 'brands-list',
        list: brands,
        selectedItems: selectedBrands,
        setSelectedItems: setSelectedBrands,
        defaultSelectedItems: selectedBrands,
        icon: '/images/inpulse/brand-black-small.svg',
      },
    ];
  };

  return (
    <>
      <NavigationBreadCrumb featurePath={path} />
      {emptyState ? (
        renderEmptyState()
      ) : (
        <ListViewContainer>
          <ListView
            actionOnClick={goToRecipeDetailsPage}
            actions={actions}
            columns={columns}
            data={filteredRecipes}
            defaultCurrentPage={currentPage}
            defaultMaxPerPage={maxPerPage}
            defaultOrderBy={orderBy}
            defaultOrderType={orderType}
            defaultSearchInput={search}
            handleCurrentPageChange={setCurrentPage}
            handleMaxPerPageChange={setMaxPerPage}
            handleOrderByChange={setOrderBy}
            handleOrderTypeChange={setOrderType}
            handleSearchInputChange={setSearch}
            isLoading={isLoading}
            languageCode={userLanguageCode}
            minActionsInActionsDropdown={1}
            padding={STANDARD_LISTVIEW_PADDING}
            placeholderShape={i18next.t('GENERAL.SEARCH')}
            renderEmptyState={() => <EmptyState />}
            renderFilterButton={() => (
              <DeepsightFiltersButton
                advancedFilters={advancedFilters}
                applyFilters={applyFilters}
                columnsFilterList={columnsFilterList}
                customMultipleDropDowns={getCustomMultipleDropdowns()}
                customSingleDropDowns={[
                  {
                    id: 'recipe-state',
                    isDisabled: isUserManager(user) && !isUserDeepsight(user),
                    disabledIcon: '/images/inpulse/power-ip-black.svg',
                    itemSelectedIcon: '/images/inpulse/power-ip-black.svg',
                    list: CHOICES_DROPDOWN_ACTIVE,
                    defaultSelectedItem: CHOICES_DROPDOWN_ACTIVE[0],
                    selectedItem: selectedStatus,
                    setSelectedItem: setSelectedStatus,
                  },
                ]}
                filters={filters}
                isLoading={isLoading}
                readOnly={isLoading}
                setAdvancedFilters={setAdvancedFilters}
                setApplyFilters={setApplyFilters}
                setFilters={setFilters}
                textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
              />
            )}
            rowActions={rowActions}
            setSelectedItems={setSelectedRecipes}
          />
        </ListViewContainer>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  recipes: state.baseReducer.recipes,
  client: getClientInfo(state.baseReducer.user),
  isCentralView: state.baseReducer.isCentralView,
});

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  closeGenericModal: () => {
    dispatch(closeGenericModal());
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Recipes);
