import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 88px;

  .deepsight-item-list-bottom-navigation-bar {
    width: 100%;
    margin-left: 0px;
  }

  .items-list-editable-tag {
    background-color: white;

    &:hover {
      cursor: pointer;
    }

    > img {
      width: 15px;
      height: 15px;
      margin-left: 0px;
      margin-bottom: 0px;
    }
  }
`;

export const LabelContainer = styled.div`
  min-width: 40px;
  display: flex;
`;
