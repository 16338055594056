import i18next from 'i18next';

const getStoreProductMixCardSettings = (storeProductMix) => {
  const inputs = [
    {
      dropdownValue: null,
      hasTooltip: false,
      isDropdown: false,
      isRequired: false,
      isSwitch: false,
      isSingleDatePicker: true,
      label: i18next.t('GENERAL.LAST_MENU'),
      propertyKey: 'lastMenuDate',
    },
  ];

  return {
    title: i18next.t('FEATURE.HOME.PRODUCT_MIX'),
    inputs: inputs.reduce((acc, input) => {
      acc.push({ ...input, value: storeProductMix[input.propertyKey] });
      return acc;
    }, []),
  };
};

export default getStoreProductMixCardSettings;
