import generateDataContainer from './generateDataContainer';
import getEmailListFromString from './getEmailListFromString';
import getEntitiesRecommandations from './getEntitiesRecommandations';
import getExceptionalDeliveryDates from './exceptionalDeliveryDays';
import getFirstDeliveryWeekDaysOfSupplierProfile from './getFirstDeliveryWeekDaysOfSupplierProfile';
import getMaxOrderDateOfSupplierFromDate from './getMaxOrderDateOfSupplierFromDate';
import getOrderEmailBody from './getOrderEmailBody';
import getOrderInfos from './getOrderInfos';
import getOrderReference from './getOrderReference';
import getSupplierProfile from './getSupplierProfile';
import getSupplierProfileBySupplierIdAndStoreId from './getSupplierProfileBySupplierIdAndStoreId';
import getSupplierProfileEmails from './getSupplierProfileEmails';
import getTotalAmount from './getTotalAmount';
import getTotalPrice from './getTotalPrice';
import sendOrder from './sendOrder';
import sendValidatedDelivery from './sendValidatedDelivery';

export default {
  sendOrder,
  getOrderInfos,
  getTotalPrice,
  getTotalAmount,
  getSupplierProfileEmails,
  getOrderEmailBody,
  getOrderReference,
  generateDataContainer,
  sendValidatedDelivery,
  getEmailListFromString,
  getMaxOrderDateOfSupplierFromDate,
  getFirstDeliveryWeekDaysOfSupplierProfile,
  getSupplierProfile,
  getSupplierProfileBySupplierIdAndStoreId,
  getEntitiesRecommandations,
  getExceptionalDeliveryDates,
};
