import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { SwitchWrapper, SwitchLabel, Switch } from './styledComponents';

import { Props } from './interfaces';

import { getTheme } from '../utils/theme';

const ToggleSwitch = (props: Props): JSX.Element => {
  const { theme, disabled, handleClick, checked, id } = props;

  return (
    <ThemeProvider theme={getTheme(theme, 'toggleSwitch')}>
      <SwitchWrapper>
        <Switch
          id={id}
          type="checkbox"
          onClick={() => {
            handleClick();
          }}
          onChange={() => true}
          disabled={disabled}
          checked={checked}
        />
        <SwitchLabel htmlFor={id} disabled={disabled} />
      </SwitchWrapper>
    </ThemeProvider>
  );
};

ToggleSwitch.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

ToggleSwitch.defaultProps = {
  theme: null,
  disabled: false,
  checked: false,
};

export default ToggleSwitch;
