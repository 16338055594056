import React from 'react';

import PropTypes from 'prop-types';

import { HeaderInfosContainer, Title, Subtitle } from './styledComponents';

export const FullScreenModalHeaderInfos = (props) => (
  <HeaderInfosContainer>
    <Title>{props.title}</Title>
    <Subtitle>{props.subtitle}</Subtitle>
  </HeaderInfosContainer>
);

FullScreenModalHeaderInfos.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default FullScreenModalHeaderInfos;
