import styled, { css } from 'styled-components';

const Container = styled.div`
  height: 100%;
  width: 64px;
  min-width: 64px;

  background-color: ${(props) => props.theme.colors.greys.darkest};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;

  // Handle borders
  ::after,
  ::before {
    content: '';
    position: absolute;
    left: 8px;
    right: 8px;
    width: 48px;
    border-bottom: solid 1px ${(props) => props.theme.colors.greys.darker};
  }

  ::after {
    top: 64px;
  }

  ::before {
    bottom: 64px;
  }
`;

const Row = styled.div`
  width: 100%;
`;

const Head = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 16px;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: flex-start;
  align-items: center;
`;

const Image = styled.img`
  height: ${(props) => (props.position === 'top' ? '32px' : props.position === 'bottom' && '36px')};
  width: ${(props) => (props.position === 'top' ? '32px' : props.position === 'bottom' && '36px')};

  ${(props) =>
    props.position === 'top'
      ? css`
          margin-top: 16px;
        `
      : props.position === 'bottom' &&
        css`
          margin-bottom: 16px;
          border-radius: 4px;
        `}
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  row-gap: 16px;
  padding-top: 16px;
`;

const ItemImage = styled.img`
  height: 16px;
  width: 16px;

  transition: filter 0.3s cubic-bezier(0, 0.64, 0.25, 0.99);

  ${(props) =>
    !props.isSelected &&
    css`
      filter: brightness(0) invert(1);
    `}
`;

const ItemImageContainer = styled.div`
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s cubic-bezier(0, 0.64, 0.25, 0.99),
    border 0.3s cubic-bezier(0, 0.64, 0.25, 0.99);

  cursor: pointer;

  ${(props) =>
    props.isSelected
      ? css`
          background-color: ${props.focusColor || props.theme.colors.brand.primary};
          border-radius: 4px;
        `
      : css`
          &:hover > ${ItemImage} {
            ${props.focusColor
              ? css`
                  -webkit-filter: invert(71%) sepia(94%) saturate(382%) hue-rotate(120deg)
                    brightness(94%) contrast(94%);
                  filter: invert(71%) sepia(94%) saturate(382%) hue-rotate(120deg) brightness(94%)
                    contrast(94%);
                `
              : css`
                  -webkit-filter: url(#colorize);
                  filter: url(#colorize);
                `}
          }
        `}

  ${(props) => props.customStyle}
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

export { Container, Row, Head, Bottom, Image, ItemImage, ItemImageContainer, Item, ItemsContainer };
