import { InpulseChart } from 'deepsight-react-components';
import { max, min } from 'lodash';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { formatShortenCurrencyNumber } from '@commons/utils/format';

import theme from '@theme';

const GRACE_RATIO = 0.5;

const xAxis = (granularity) => ({
  // use native time formatter
  type: 'time',
  time: {
    unit: granularity,
    tooltipFormat: DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
    displayFormats: {
      // format of the date provided in the x data
      week: DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
    },
  },
  ticks: {
    source: 'labels', // display x label for each data in x array
    callback: (value) => moment(value).format(DATE_DISPLAY_FORMATS.SHORTEN_MONTH_AND_YEAR_DAY),
  },
  // Do not display grid on this axis, nor border
  grid: {
    display: false,
    drawBorder: false,
  },
});

const percentageAxis = () => ({
  position: 'right',
  // Force percentage scale
  suggestedMin: 0,
  suggestedMax: 100,
  grid: {
    drawOnChartArea: false, // hide grid in graph
    drawBorder: false, // hide border
    tickColor: null, // hide tick
    tickLength: 4, // add padding between axis and graph
  },
  // Add '%' character in axis labels
  ticks: {
    callback: (value) => `${value}%`,
  },
});

const yAxis = (currency) => ({
  beginAtZero: true,
  grace: `${GRACE_RATIO * 100}%`, // add extra padding in graph to not start on min value and end in max value
  // Format axis labels to match the way numbers are displayed
  ticks: {
    callback: (value) =>
      !value ? null : formatShortenCurrencyNumber(value, currency.numberDecimals, true),
  },
  grid: {
    tickLength: 4, // add padding between axis and graph
    tickColor: null, // hide tick
    drawBorder: false, // hide border
    // Remove bottom and top line of graph
    color: (context) => {
      if (context.tick.value <= 0) {
        return theme.colors.greys.lightest;
      }

      return theme.colors.greys.lighty;
    },
  },
});

const getDatasets = ({ y, percentage }) => [
  {
    ...dataset(y.data, y.label, y.borderColor, y.backgroundColor),
    fill: 1,
    yAxisID: 'y',
    data: y.data,
  },
  {
    ...dataset(
      [0, ...percentage.data],
      percentage.label,
      percentage.borderColor,
      percentage.backgroundColor,
    ),
    fill: 'origin',
    yAxisID: 'percentage',
    data: percentage.data,
  },
];

export const scales = (granularity, currency) => ({
  x: xAxis(granularity),
  y: yAxis(currency),
  percentage: percentageAxis(),
});

export const dataset = (data, label, borderColor, backgroundColor) => {
  const minValue = min(data);
  const maxValue = max(data);

  // take into consideration the GRACE RATIO
  const minValueGradient = minValue - (maxValue - minValue * GRACE_RATIO);

  return {
    label,
    borderColor,
    tension: 0.2,
    borderWidth: 2,
    gradient: {
      backgroundColor: {
        axis: 'y',
        colors: {
          [minValueGradient]: `rgba(${backgroundColor}, 0)`,
          [maxValue]: `rgba(${backgroundColor}, 0.24)`,
        },
      },
    },
  };
};

export const pointStyle = () => ({
  radius: 0,
  hoverRadius: 2,
  pointStyle: 'circle',
  hoverBorderColor: theme.colors.blacks.ipBlack1,
  hoverBackgroundColor: theme.colors.blacks.ipBlack1,
});

export const interactionOnHover = () => ({
  mode: 'index', // make smoother the hovering on values, no need to be on exact x axis position
  intersect: false, // gather together point of the two lines in graph
});

/**
 * Implements average positioning used in ChartJS but add the shift when mouse goes over half of the canvas

 * @param items
 * @param eventPosition

 * @returns {{x: *, y: number}|boolean}
 */
InpulseChart.Tooltip.positioners.averageWithShift = function (items, eventPosition) {
  // Reference the tooltip model
  const tooltip = this;
  const canvasWidth = tooltip.chart.width;
  const tooltipWidth = tooltip.width;
  const tooltipHeight = tooltip.height;
  const { x: mouseX } = eventPosition;

  // Add offset to position tooltip correctly
  const offsetX = tooltipWidth;
  const offsetY = tooltipHeight;

  const shouldShift = mouseX > canvasWidth / 2;

  // Average positioning implementation by ChartJS
  // cf: https://github.com/chartjs/Chart.js/blob/767d64e7a90dbfe94f9c5d159a890054818c1680/src/plugins/plugin.tooltip.js#L21
  if (!items.length) {
    return false;
  }

  let i, len;
  let xSet = new Set();
  let y = 0;
  let count = 0;

  for (i = 0, len = items.length; i < len; ++i) {
    const el = items[i].element;
    if (el && el.hasValue()) {
      const pos = el.tooltipPosition();
      xSet.add(pos.x);
      y += pos.y;
      ++count;
    }
  }

  const xAverage = [...xSet].reduce((a, b) => a + b) / xSet.size;

  return {
    x: xAverage + (shouldShift ? -(tooltipWidth + offsetX) : 0),
    y: y / count - offsetY,
  };
};

export const tooltip = (tooltipHandler) =>
  tooltipHandler
    ? {
        enabled: false,
        external: tooltipHandler,
        position: 'averageWithShift',
      }
    : {
        backgroundColor: theme.colors.white,
        titleColor: theme.colors.black,
        titleMarginBottom: 10,
        bodySpacing: 6,
        bodyColor: theme.colors.black,
        caretPadding: 24, // Distance from mouse
        displayColors: false,
        padding: 16,
        caretSize: 0, // 0 for no arrow
        cornerRadius: 16, // Border radius of the tooltip
      };

export default {
  scales,
  dataset,
  pointStyle,
  interactionOnHover,
  tooltip,
  getDatasets,
};
