import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { ListView } from '@commons/utils/styledLibraryComponents';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';

import { ENUM_QUERY_PARAMS, useListViewQueryParams } from '@hooks/useListViewQueryParams';

import storeService from '@services/store';

import DeepsightFiltersButton from '@admin/components/FilterButton';
import EmptyState from '@admin/suppliers/suppliers/components/EmptyState';

import { Container, Text } from './styledComponents';

/**
 * Retrieve the list of accounts associated to the given storeId
 *
 * @param {String} storeId      - The store id on which retrieve the associated account
 * @param {Method} showMessage  - The method to display message on page
 *
 * @returns {Object[]} The list of accounts associated to the storeId
 */
export async function fetchAccountsOfStore(storeId, showMessage) {
  try {
    const accounts = await storeService.getAccountsOfStore(storeId);

    const formattedAccounts = accounts.map((account) => ({
      id: account.id,
      name: `${account.firstName} ${account.lastName}`,
      email: account.email,
      role: _.get(account, 'lnkAccountRoleAccountrel.name', ''),
    }));

    return formattedAccounts;
  } catch (error) {
    showMessage(i18next.t('USERS.DETAILS.PROFILE_LIST_ACCOUNTS_FETCH_FAILURE'), 'error');

    return [];
  }
}

const renderText = (item) => <Text>{item}</Text>;

const DISPLAYED_ACCOUNTS_INFORMATION = [
  {
    id: 'name',
    baseName: 'name',
    propertyKey: 'name',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_NAME'),
    displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_NAME'),
    render: (item) => <Text highlighted>{item}</Text>,
  },
  {
    id: 'email',
    baseName: 'email',
    propertyKey: 'email',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_EMAIL'),
    displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_EMAIL'),
    render: (item) => renderText(item),
  },
  {
    id: 'role',
    baseName: 'role',
    propertyKey: 'role',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_ROLE'),
    displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_ROLE'),
    filterType: 'string',
    excludeFromSearch: true,
    render: (item) => renderText(item),
  },
];

export const StoreAssociatedUsers = (props) => {
  const { storeParams, pageLoading, pageLoaded, showMessage } = props;
  const storeId = storeParams.id;

  const [accountList, setAccountList] = useState([]);
  const [filteredAccountList, setFilteredAccountList] = useState([]);

  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [columnsFilterList, setColumnsFilterList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [listViewQueryParams, setListViewQueryParams] = useListViewQueryParams();

  const updateColumnsFilterList = (accounts) => {
    const roleColumn = DISPLAYED_ACCOUNTS_INFORMATION.find(
      (column) => column.propertyKey === 'role',
    );

    if (!roleColumn) {
      return setColumnsFilterList(null);
    }

    const roles = Object.values(
      accounts.reduce((result, account, key) => {
        if (account.role) {
          result[account.role] = {
            id: key,
            name: account.role,
            status: account.role,
            value: account.role,
            role: account.role,
          };
        }

        return result;
      }, {}),
    );

    roleColumn.list = sortArrayOfObjectsAlphabetically(roles, 'name');

    return setColumnsFilterList([roleColumn]);
  };

  const processAdvancedFilter = (accounts) => {
    if (!applyFilters) {
      return;
    }

    const filteredAccountList = accounts;

    if ((!advancedFilters || !advancedFilters.length) && applyFilters) {
      setFilteredAccountList(filteredAccountList);
      return;
    }

    const filteredAccountListWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, propertyKey, value }) => doFilter(result, propertyKey, value),
      filteredAccountList,
    );

    return setFilteredAccountList(filteredAccountListWithAdvancedFilters);
  };

  useEffect(() => {
    pageLoading();
    setIsLoading(true);

    (async function loadData() {
      const accounts = await fetchAccountsOfStore(storeId, showMessage);

      setAccountList(accounts);
      setFilteredAccountList(accounts);
      updateColumnsFilterList(accounts);

      setIsLoading(false);
      pageLoaded();
    })();
  }, []);

  useEffect(() => {
    processAdvancedFilter(accountList);
  }, [applyFilters, advancedFilters]);

  const userLanguageCode = _.get(props.user, 'lnkLanguageAccountrel.code', 'fr');

  return (
    <Container>
      <ListView
        columns={DISPLAYED_ACCOUNTS_INFORMATION}
        data={filteredAccountList}
        defaultCurrentPage={listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE]}
        defaultMaxPerPage={listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]}
        defaultOrderBy={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]}
        defaultOrderType={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]}
        defaultSearchInput={listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH]}
        handleCurrentPageChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](input)
        }
        handleMaxPerPageChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE](input)
        }
        handleOrderByChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY](input)}
        handleOrderTypeChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE](input)
        }
        handleSearchInputChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.SEARCH](input)}
        isLoading={isLoading}
        languageCode={userLanguageCode}
        padding={STANDARD_LISTVIEW_PADDING}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => {
          if (!columnsFilterList || !columnsFilterList.length) {
            return null;
          }

          return (
            <DeepsightFiltersButton
              advancedFilters={advancedFilters}
              applyFilters={applyFilters}
              columnsFilterList={columnsFilterList}
              filters={filters}
              isLoading={isLoading}
              minWidth={120}
              readOnly={isLoading}
              setAdvancedFilters={(it) => setAdvancedFilters(it)}
              setApplyFilters={(it) => setApplyFilters(it)}
              setFilters={(it) => setFilters(it)}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
            />
          );
        }}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreAssociatedUsers);
