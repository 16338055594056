import { orderBy } from 'lodash';
import i18next from 'i18next';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

import { brand as brandService } from '@services/brand';
import { group as groupService } from '@services/group';
import { supplier as supplierService } from '@services/supplier';
import accountService from '@services/account';
import clientService from '@services/client';
import orderService from '@services/order';

const fetchSuppliersOfClient = async (clientId, pageLoading, pageLoaded, showMessage) => {
  pageLoading();

  try {
    const suppliers = await supplierService.getSuppliersOfClient(clientId);

    return suppliers;
  } catch (err) {
    showMessage(i18next.t('ORDERS.BY_CATEGORY.FETCH_SUPPLIERS_FAILURE'), 'error');

    return [];
  } finally {
    pageLoaded();
  }
};

const fetchBrandsOfClient = async (clientId, showMessage) => {
  try {
    const brands = await brandService.getBrandsOfClient(clientId);

    return brands;
  } catch (err) {
    showMessage('Impossible de récupérer les marques', 'error');

    return [];
  }
};

const fetchLocationsOfAccount = async (accountId, showMessage) => {
  try {
    const locations = await accountService.getLocationsOfAccount(accountId);

    return locations;
  } catch (err) {
    showMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_LOCATIONS_FAILURE'), 'error');

    return [];
  }
};

const fetchRetailersOfClient = async (clientId, showMessage) => {
  try {
    const retailers = await clientService.getRetailersOfClient(clientId);

    return retailers;
  } catch (err) {
    showMessage(i18next.t('STOCKS.CURRENT_STOCKS.GET_RETAILERS_FAILURE'), 'error');

    return [];
  }
};

const fetchGroupsByStoreIds = async (storeIds, showMessage) => {
  const formattedGroups = {};

  try {
    const groupMappings = await groupService.getGroupsOfStores(storeIds);

    groupMappings.forEach((groupMapping) => {
      const groupId = groupMapping.lnkGroupStoregroupmappingrel.id;
      const groupName = groupMapping.lnkGroupStoregroupmappingrel.name;

      if (!formattedGroups[groupId]) {
        formattedGroups[groupId] = {
          groupId,
          storeIds: [],
          name: groupName,
          id: groupMapping.id,
        };
      }

      formattedGroups[groupId].storeIds.push(groupMapping.storeId);
    });
  } catch (err) {
    showMessage(i18next.t('GENERAL.GROUPS_CLIENT_FETCH_FAILURE'), 'error');

    return {};
  }

  return formattedGroups;
};

const fetchAnalyticsData = async (storeIds, startDate, endDate, showMessage) => {
  try {
    const analytics = await orderService.getOrdersAnalyticsByStore(
      storeIds,
      startDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      endDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
    );

    const formattedData = orderBy(
      analytics.data.map((item, index) => ({
        ...item,
        id: index,
        orderOverTurnoverRatio:
          item.orderOverTurnoverRatio == null ? null : item.orderOverTurnoverRatio * 100,
        materialRatio: item.materialRatio == null ? null : item.materialRatio * 100,
      })),
      'storeName',
    );

    return [
      {
        ...analytics.totalData,
        id: 'total',
        storeName: i18next.t('ORDERS.BY_CATEGORY.LABEL_TOTAL'),
        orderOverTurnoverRatio:
          analytics.totalData.orderOverTurnoverRatio == null
            ? null
            : analytics.totalData.orderOverTurnoverRatio * 100,
        materialRatio:
          analytics.totalData.materialRatio == null
            ? null
            : analytics.totalData.materialRatio * 100,
      },
    ].concat(formattedData);
  } catch (err) {
    showMessage(i18next.t('ORDERS.BY_CATEGORY.FETCH_DATA_FAILURE'), 'error');

    return [];
  }
};

export default {
  fetchAnalyticsData,
  fetchBrandsOfClient,
  fetchGroupsByStoreIds,
  fetchRetailersOfClient,
  fetchSuppliersOfClient,
  fetchLocationsOfAccount,
};
