import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
`;

export const ContentContainer = styled.div`
  height: calc(100% - 40px);

  background: ${(props) => props.theme.colors.greys.lighter};

  > div {
    min-width: 100%;
    width: fit-content;
  }

  @media (max-width: 1200px) {
    > div:not(:first-child) > div > div {
      padding-right: 20px;
    }
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 90px;

  padding: 20px 20px;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  .location-filter-input-text {
    max-width: none !important;
  }
`;

export const Filter = styled.div`
  width: 240px;

  display: flex;
  align-items: center;
`;

export const FilterContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  column-gap: 24px;
`;

export const TextWithTooltipButtonContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const NestedListContainer = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
`;
