const getTotalAmount = (productsInventory = []) =>
  productsInventory.reduce((result, category) => {
    if (!category.products) {
      return result;
    }

    category.products.forEach((product) => {
      if (product.ordered) {
        result += +product.ordered;
      }
    });

    return result;
  }, 0);

export default getTotalAmount;
