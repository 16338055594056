import i18next from 'i18next';
import React from 'react';

import { Dropdown, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';

import { Input } from '@lib/inpulse/Input';

import theme from '@theme';

import utils from '../utils';

/*************************************/
/* Inputs Product Form Configuration */
/*************************************/

export const ProductNameInput = () => ({
  name: `* ${i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_LIST_COLUMN_NAME')}`,
  keyProperty: 'name',
  component: Input,
  props: ({ product, onProductChange, isReadOnly }) => ({
    type: 'text',
    readOnly: isReadOnly,
    onChange: (event) => handleOnChangeProduct(product, onProductChange, event, 'name'),
  }),
});

export const ProductCategoryInput = () => ({
  name: i18next.t('ADMIN.PRODUCTS.INFO_CATEGORY'),
  keyProperty: 'category',
  component: Dropdown,
  props: ({ product, onProductChange, productsCategories, isReadOnly, ...props }) => {
    const productCategory = !!product.category ? product.category.trim().toLowerCase() : null;

    const selectedCategory = productsCategories.find(
      (category) => (category.name || '').toLowerCase() === productCategory,
    );

    return {
      width: INPUT_WIDTH.FULL,
      isDisabled: isReadOnly,
      items: productsCategories,
      selectedItem: selectedCategory,
      labelStyle: { font: theme.fonts.textSmall },
      onSelectionChange: (selectedItem, _, isUnselected) => {
        handleOnChangeProduct(
          product,
          onProductChange,
          { target: { value: isUnselected ? null : selectedItem.name } },
          'category',
        );
      },
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      button: {
        id: 1,
        text: i18next.t('GENERAL.LIST_CREATE_CATEGORY'),
        handleClick: () => {
          handleCategorySubcategoryCreation('category', props);
        },
        iconSrc: '/images/inpulse/add-black-small.svg',
      },
    };
  },
});

export const ProductSubCategoryInput = () => ({
  name: `${i18next.t('GENERAL.SUB_CATEGORY')} :`,
  keyProperty: 'subCategory',
  component: Dropdown,
  props: ({ product, onProductChange, productsSubCategories, isReadOnly, ...props }) => {
    const productSubCategory = !!product.subCategory
      ? product.subCategory.trim().toLowerCase()
      : null;

    const selectedSubCategory = productsSubCategories.find(
      (subCategory) => (subCategory.name || '').toLowerCase() === productSubCategory,
    );

    return {
      width: INPUT_WIDTH.FULL,
      isDisabled: isReadOnly,
      selectedItem: selectedSubCategory,
      items: productsSubCategories,
      labelStyle: { font: theme.fonts.textSmall },
      onSelectionChange: (selectedItem, _, isUnselected) => {
        handleOnChangeProduct(
          product,
          onProductChange,
          { target: { value: isUnselected ? null : selectedItem.name } },
          'subCategory',
        );
      },
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      button: {
        id: 1,
        text: i18next.t('GENERAL.LIST_CREATE_SUBCATEGORY'),
        handleClick: () => {
          handleCategorySubcategoryCreation('subCategory', props);
        },
        iconSrc: '/images/inpulse/add-black-small.svg',
      },
    };
  },
});

export const EmptyInput = {
  name: 'empty',
  keyProperty: '',
  component: () => <div></div>,
  props: () => ({}),
};

export const ProductDlcInput = () => ({
  name: i18next.t('ADMIN.PRODUCTS.PRODUCT_SHELF_LIFE'),
  keyProperty: 'shelfLife',
  component: Input,
  props: ({ product, onProductChange, isReadOnly }) => ({
    type: 'number',
    readOnly: isReadOnly,
    onChange: (event) => handleOnChangeProduct(product, onProductChange, event, 'shelfLife'),
  }),
});

export const ProductPriceInput = (currency) => ({
  name: `* ${i18next.t('GENERAL.PRICE_TTC_WITH_CURRENCY', {
    currency: currency.alphabeticCode,
  })}`,
  keyProperty: 'price',
  component: Input,
  props: ({ product, onProductChange, isProductSynced, isReadOnly }) => ({
    type: 'number',
    readOnly: isProductSynced || isReadOnly,
    disabled: isProductSynced || isReadOnly,
    tooltipText: i18next.t(
      'ADMIN.PRODUCTS.CREATE_PRODUCT_MODAL_CASHIER_PRODUCT_ASSOCIATION_ON_SITE_PRICE',
    ),
    onChange: (event) => handleOnChangeProduct(product, onProductChange, event, 'price'),
  }),
});

export const ProductDeliveryPriceInput = (currency) => ({
  name: `${i18next.t('GENERAL.DELIVERY_PRICE_TTC_WITH_CURRENCY', {
    currency: currency.alphabeticCode,
  })}`,
  keyProperty: 'deliveryPrice',
  component: Input,
  props: ({ product, onProductChange, isReadOnly }) => ({
    type: 'number',
    readOnly: isReadOnly,
    disabled: isReadOnly,
    tooltipText: i18next.t('ADMIN.PRODUCTS.DELIVERY_PRICE_WITH_TAXES_TOOLTIP'),
    onChange: (event) => handleOnChangeProduct(product, onProductChange, event, 'deliveryPrice'),
  }),
});

export const CurrencySelector = (props) => {
  const { product, onProductChange, isDisabled, currency } = props;

  return (
    <Dropdown
      customStyle={{ marginBottom: '28px' }} // not fitting the 8px rule to match legacy page style
      isDisabled={isDisabled}
      isLabelTextSmall={true}
      isRequired={true}
      label={`${i18next.t('GENERAL.CURRENCY')} :`}
      selectedItem={currency}
      width={INPUT_WIDTH.FULL}
      onSelectionChange={(selectedItem) => {
        handleOnChangeProduct(
          product,
          onProductChange,
          {
            target: { value: selectedItem.id },
          },
          'currency',
        );
      }}
    />
  );
};

export const ProductCurrencyInput = () => ({
  keyProperty: 'currency',
  component: CurrencySelector,
  props: ({ product, onProductChange, currency }) => ({
    product,
    onProductChange,
    isDisabled: true,
    currency,
  }),
});

export const ProductVatInput = () => ({
  name: `* ${i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_LIST_COLUMN_VAT_RATE')} (%)`,
  keyProperty: 'vatRate',
  component: Input,
  props: ({ product, onProductChange, isProductSynced, isReadOnly }) => ({
    type: 'number',
    tooltipText: i18next.t('ADMIN.PRODUCTS.DELIVERY_VAT_RATE_TOOLTIP'),
    readOnly: isProductSynced || isReadOnly,
    disabled: isProductSynced || isReadOnly,
    onChange: (event) => handleOnChangeProduct(product, onProductChange, event, 'vatRate'),
  }),
});

export const ProductDeliveryVatInput = () => ({
  name: `${i18next.t('ADMIN.PRODUCTS.DELIVERY_VAT_RATE')} (%)`,
  keyProperty: 'deliveryVatRate',
  component: Input,
  props: ({ product, onProductChange, isReadOnly }) => ({
    type: 'number',
    tooltipText: i18next.t('ADMIN.PRODUCTS.DELIVERY_VAT_RATE_TOOLTIP'),
    readOnly: isReadOnly,
    disabled: isReadOnly,
    onChange: (event) => handleOnChangeProduct(product, onProductChange, event, 'deliveryVatRate'),
  }),
});

export const ProductSkuInput = () => ({
  name: i18next.t('GENERAL.SKU'),
  keyProperty: 'sku',
  component: Input,
  props: ({ product, onProductChange, isReadOnly }) => ({
    type: 'text',
    readOnly: isReadOnly,
    onChange: (event) => handleOnChangeProduct(product, onProductChange, event, 'sku'),
  }),
});

export const ProductBrandsInput = () => ({
  name: `${i18next.t('ADMIN.STORES.LIST_COLUMN_BRAND')} :`,
  keyProperty: 'brand',
  component: Dropdown,
  props: ({ product, onProductChange, brands, isReadOnly }) => {
    const selectedBrand = brands.find((brand) => brand.name === product.brand);

    return {
      width: INPUT_WIDTH.FULL,
      labelStyle: { font: theme.fonts.textSmall },
      items: brands,
      selectedItem: selectedBrand,
      isDisabled: isReadOnly,
      onSelectionChange: (selectedItem, _, isUnselected) => {
        onProductChange({
          ...product,
          brandId: isUnselected ? null : selectedItem.id,
          brand: isUnselected ? '' : selectedItem.name,
        });
      },
    };
  },
});

/*************************/
/* Input Change Handlers */
/*************************/

export function handleBrandInput(product, onProductChange, clientBrands, selectedBrand) {
  const updatedProduct = { ...product, brandId: null, brand: null };

  const matchingBrand = clientBrands.find(
    (clientBrand) => clientBrand.name.toLowerCase() === selectedBrand.toLowerCase(),
  );

  if (matchingBrand) {
    updatedProduct.brandId = matchingBrand.id;
    updatedProduct.brand = matchingBrand.name;
  }

  onProductChange({ ...updatedProduct });
}

/**
 * Generic method that is in charge of updating the product from the inputs that the user changes
 *
 * @param {Product} product           - The original product when the user performs the change in an input
 * @param {Function} onProductChange  - Method used to set the local state of the product variable
 * @param {Event} event               - The event emitted by the input OnChange method
 * @param {String} fieldName          - The field name on which apply the change
 */
export function handleOnChangeProduct(product, onProductChange, event, fieldName) {
  let updatedFieldValue = event.target.value;

  // Format price and VAT fields to have a proper number format with decimal
  if (
    updatedFieldValue &&
    (fieldName === 'vatRate' ||
      fieldName === 'price' ||
      fieldName === 'deliveryVatRate' ||
      fieldName === 'deliveryPrice')
  ) {
    updatedFieldValue = updatedFieldValue.replace(',', '.');
  }

  const updatedProduct = { ...product, [fieldName]: updatedFieldValue };

  onProductChange({ ...updatedProduct });

  // update price accordingly on vatRate or priceHT inputs change
  if (fieldName === 'vatRate' || fieldName === 'price') {
    // Make sure to display the correct price excluding VAT
    const priceHT = utils.getPriceExcludingVAT(updatedProduct);

    onProductChange({ ...updatedProduct, priceHT });
  }

  // update delivery price accordingly on deliveryVatRate or deliveryPriceHT inputs change
  if (fieldName === 'deliveryVatRate' || fieldName === 'deliveryPrice') {
    // Make sure to display the correct price excluding VAT
    const deliveryPriceHT = utils.getPriceExcludingVAT(updatedProduct, true);

    onProductChange({ ...updatedProduct, deliveryPriceHT });
  }
}

export const handleCategorySubcategoryCreation = (propertyName, props) => {
  const { setItemDropdownPropertyName, setOpenModalAddNewItemDropdown } = props;
  setItemDropdownPropertyName(propertyName);

  setOpenModalAddNewItemDropdown(true);
};

export default {
  // Inputs
  ProductNameInput,
  ProductCategoryInput,
  ProductSubCategoryInput,
  ProductDlcInput,
  ProductPriceInput,
  ProductCurrencyInput,
  ProductVatInput,
  ProductSkuInput,
  ProductBrandsInput,
  // Handlers methods
  handleBrandInput,
  handleOnChangeProduct,
};
