import i18next from 'i18next';
import React from 'react';

import { MainText, TextContainer, EmptyStateIcon, EmptyStateContainer } from './styledComponents';

const EmptyState = () => (
  <EmptyStateContainer>
    <EmptyStateIcon alt="no result found" src={'/images/inpulse/empty-state.svg'} />
    <TextContainer>
      <MainText>{i18next.t('GENERAL.NO_RESULT')}</MainText>
    </TextContainer>
  </EmptyStateContainer>
);

export default EmptyState;
