import { Container, Picture, PictureContainer, TextContainer, Text } from './styledComponents';
import React from 'react';

const RegistrationSuccess = (props) => {
  const { registrationText } = props.params;

  return (
    <Container>
      <PictureContainer>
        <Picture
          alt="Registration-success-picture"
          src={'/images/inpulse/user-registration-complete.svg'}
        />
      </PictureContainer>

      <TextContainer>
        <Text>{registrationText}</Text>
      </TextContainer>
    </Container>
  );
};

export default RegistrationSuccess;
