import React from 'react';

import { Container } from './styledComponents';

export const DatePickerInputIcon = (date, isDisabled = false) => (
  <Container>
    <img
      alt="arrow"
      src={
        isDisabled
          ? '/images/inpulse/calendar-white-small.svg'
          : !!date
          ? '/images/inpulse/calendar-black-small.svg'
          : '/images/inpulse/calendar-dmgrey-small.svg'
      }
    />
  </Container>
);
