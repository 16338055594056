import { SingleDatePicker } from 'react-dates';
import moment from 'moment-timezone';
import onClickOutside from 'react-onclickoutside';
import React from 'react';

import {
  renderCustomNavIconLeft,
  renderCustomNavIconRight,
  renderTodayDateCircle,
} from '@commons/DeepsightAnalyticsHeaders/utils';

import { planningType as planningTypeEnum } from '@productions/constants/planning';

import { Filter, CalendarIcon } from './styledComponents';

function RenderDatePicker(props) {
  const {
    planningType,
    planningDate,
    handleSelectedDate,
    datePickerFocused,
    setDatePickerFocused,
    readOnly,
    timezone,
  } = props;

  const isDisabled = readOnly || planningType !== planningTypeEnum.PRODUCTION;

  RenderDatePicker.handleClickOutside = () => setDatePickerFocused(false);

  return (
    <Filter
      className={`date-picker ${isDisabled ? 'disabled' : ''}`}
      focusedDateInput={datePickerFocused}
      date
      onClick={() => {
        if (!isDisabled) {
          setDatePickerFocused(!datePickerFocused);
        }
      }}
    >
      <CalendarIcon
        alt=""
        className="orderform-date-input-icon"
        src={'/images/icon-calendar-black-inpulse.svg'}
      />
      <SingleDatePicker
        date={planningDate}
        disabled={isDisabled}
        displayFormat={'Do MMM'}
        focused={datePickerFocused}
        hideKeyboardShortcutsPanel={true}
        id="planning_date_selector"
        isOutsideRange={(day) => moment.tz(timezone).startOf('day').isAfter(day)}
        navNext={renderCustomNavIconRight()}
        navPrev={renderCustomNavIconLeft()}
        noBorder={true}
        numberOfMonths={1}
        placeholder={'Date'}
        readOnly={true}
        renderDayContents={(item) => renderTodayDateCircle(item, [planningDate])}
        timezone="Europe/Paris" // TODO - [TIMEZONES]
        onDateChange={(e) => {
          setDatePickerFocused(false);
          handleSelectedDate(e);
        }}
        onFocusChange={() => null}
      />
      <img alt="arrow" src={'/images/icon-arrow-bottom-black.svg'} style={{ marginLeft: '10px' }} />
    </Filter>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => RenderDatePicker.handleClickOutside,
};

export default onClickOutside(RenderDatePicker, clickOutsideConfig);
