import styled, { css } from 'styled-components';

export const CashierProductsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmptyCashierOutlineIcon = styled.img`
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
`;
export const CashierProductsNotFoundIcon = styled.img`
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
`;

export const TextTitle = styled.p`
  ${(props) =>
    props.theme.fonts
      ? css`
          font: ${props.theme.fonts.h1Inter};
        `
      : css`
          font-weight: 700;
          font-size: 20px;
        `}
`;

export const TextSubtitle = styled.p`
  ${(props) =>
    props.theme.fonts
      ? css`
          font: ${props.theme.fonts.textBigHeight16};
        `
      : css`
          font-weight: 500;
          font-size: 14px;
        `}
`;
