import { useEffect } from 'react';

import { ENUM_MODULE_NAME, getFeaturesByPath } from '@commons/utils/features';

const PRODUCTION_SUB_PAGES = [
  '/production/analytics/real-time',
  '/production/analytics/by-store',
  '/production/analytics/stores',
  '/production/analytics/references',
];

export const AnalyticsContainer = (props) => {
  const { history, userRights } = props;

  useEffect(() => {
    const features = getFeaturesByPath(userRights, ENUM_MODULE_NAME.PRODUCTION);

    const subFeatures = features.filter((feature) => PRODUCTION_SUB_PAGES.includes(feature.path));

    const sortedSubFeatures = subFeatures.sort(
      (feature_A, feature_B) =>
        PRODUCTION_SUB_PAGES.indexOf(feature_A.path) - PRODUCTION_SUB_PAGES.indexOf(feature_B.path),
    );

    history.push(sortedSubFeatures[0].path);
  }, []);

  return null;
};

// ===== Module features for easy import in ClientContainer =====

import ProductionByBrandAnalytics from './byBrand';
import ProductionByDayAnalytics from './byDay';
import ProductionByReferenceAnalytics from './byReference';
import ProductionByStoreAnalytics from './byStore';
import ProductionRealTimeAnalytics from './realTime';

const ProductionAnalyticsModule = {
  AnalyticsContainer,
  ProductionByStoreAnalytics,
  ProductionByBrandAnalytics,
  ProductionRealTimeAnalytics,
  ProductionStoresAnalytics: ProductionByDayAnalytics,
  ProductionReferencesAnalytics: ProductionByReferenceAnalytics,
};

export default ProductionAnalyticsModule;
