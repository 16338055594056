import i18next from 'i18next';
import React from 'react';

import Text from '@commons/Text';

export const getColumns = () => [
  {
    id: 'name',
    propertyKey: 'name',
    name: i18next.t('GENERAL.NAME'),
    render: (it) => <Text highlighted>{it}</Text>,
  },
  {
    id: 'sku',
    propertyKey: 'sku',
    name: i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_REFERENCE_COLUMN_TITLE'),
    filterType: 'string',
    render: (item) => <Text>{item}</Text>,
  },
  {
    id: 'packagingFormattedName',
    propertyKey: 'packagingFormattedName',
    name: i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_PACKAGING_COLUMN_TITLE'),
    filterType: 'string',
    render: (item) => <Text>{item}</Text>,
  },
];
