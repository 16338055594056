import { connect } from 'react-redux';
import React from 'react';

import { getSalesPointStores } from '@selectors/stores';

import RenderInventoryValidationLabel from '@stocks/StocksInventories/common/renderInventoryValidationLabel';

import { Container, StockFormHeaderInfoContainer } from './styledComponents';
import StockFormHeaderButtons from './components/StockFormHeaderButtons';
import StockFormHeaderInfo from './components/StockFormHeaderInfo';
import StockFormHeaderSelectors from './components/StockFormHeaderSelectors';

function StockFormHeader(props) {
  const {
    readOnly,
    stockType,
    stockDate,
    handleSave,
    setStockDate,
    selectedStore,
    stockOnlyToday,
    setSelectedStore,
    displayWarning,
    isEditingDate,
    inventory,
    reference,
    closeModal,
    inventoryCreationDate,
    isCreation,
    isCorrectingInventory,
    isCentralKitchenView,
    inventoryListTemplates,
    selectedInventoryListTemplate,
    setSelectedInventoryListTemplate,
    inventoryValidation,
  } = props;

  return (
    <Container>
      <StockFormHeaderSelectors
        displayWarning={displayWarning}
        inventoryListTemplates={inventoryListTemplates}
        isCentralKitchenView={isCentralKitchenView}
        isEditingDate={isEditingDate}
        readOnly={readOnly}
        selectedInventoryListTemplate={selectedInventoryListTemplate}
        selectedStore={selectedStore}
        setSelectedInventoryListTemplate={setSelectedInventoryListTemplate}
        setSelectedStore={setSelectedStore}
        setStockDate={setStockDate}
        stockDate={stockDate}
        stockOnlyToday={stockOnlyToday}
        stockType={stockType}
      />
      <StockFormHeaderInfoContainer>
        <StockFormHeaderInfo inventory={inventory} reference={reference} />
        {inventoryValidation && !isCreation && (
          <RenderInventoryValidationLabel isValidated={inventory.isValidated} />
        )}
      </StockFormHeaderInfoContainer>
      <StockFormHeaderButtons
        closeModal={closeModal}
        handleSave={handleSave}
        inventoryCreationDate={inventoryCreationDate}
        isCorrectingInventory={isCorrectingInventory}
        isCreation={isCreation}
        isEditingDate={isEditingDate}
        isValidated={inventory.isValidated}
      />
    </Container>
  );
}

const mapStateToProps = (state) => ({
  stores: getSalesPointStores(state.baseReducer.activeStores),
});

export default connect(mapStateToProps)(StockFormHeader);
