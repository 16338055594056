import i18next from 'i18next';
import React from 'react';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { ToggleSwitch } from '@commons/utils/styledLibraryComponents';
import Text from '@commons/Text';

import { Container, SwitchContainer } from './styledComponents';

const priceEditableAtReceptionModalContent = ({
  priceEditableAtReception,
  setPriceEditableAtReception,
}) => (
  <Container>
    <SwitchContainer>
      <ToggleSwitch
        checked={priceEditableAtReception}
        handleClick={() => setPriceEditableAtReception(!priceEditableAtReception)}
        id="hasStock"
      />
      <Text>{i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTIVATE_PRICE_EDITABLE_AT_RECEPTION')}</Text>
    </SwitchContainer>
  </Container>
);

export const priceEditableAtReceptionModalConfig = ({
  handlePriceEditableAtReceptionUpdate,
  selectedItems,
  priceEditableAtReception,
  setPriceEditableAtReception,
  handlePriceEditableAtReceptionModalCloseCleanUp,
}) => ({
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      handleClick: () =>
        handlePriceEditableAtReceptionUpdate(selectedItems, priceEditableAtReception),
      preventClosing: true,
    },
  ],
  title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MANAGE_PRICE_EDITABLE_AT_RECEPTION_MODAL_TITLE'),
  icon: '/images/inpulse/pen-black-small.svg',
  type: 'action',
  handleCloseCleanUp: handlePriceEditableAtReceptionModalCloseCleanUp,
  component: priceEditableAtReceptionModalContent,
  data: {
    priceEditableAtReception,
    setPriceEditableAtReception,
  },
  height: 'auto',
});
