import { connect } from 'react-redux';
import i18next from 'i18next';
import parse from 'html-react-parser';
import React, { useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';

import { Button, Checkbox, Tooltip } from '@commons/utils/styledLibraryComponents';
import { getTheme } from '@commons/utils/theme';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import WhiteCard from '@commons/WhiteCard';
import WysiwygEditor from '@commons/WysiwygEditor';

import orderService from '@services/order';

import {
  ButtonContainer,
  CheckboxContainer,
  ContainerTextArea,
  InvoiceContainer,
  OrderCartContainer,
  SubTitleWhiteCard,
  TitleWhiteCard,
  WhiteCardContainer,
} from '../../styledComponents';

const DeliveryNotePreview = (props) => {
  const {
    htmlPreview,
    receptionComment,
    setReceptionComment,
    hasGenerateInvoiceProps,
    handlePreparationValidation,
    storeId,
    supplierId,
    pageLoading,
    pageLoaded,
  } = props;

  const [shouldGenerateInvoice, setShouldGenerateInvoice] = useState(false);
  const [hasStoreInformationError, setHasStoreInformationError] = useState(false);

  const theme = getTheme();

  const customWhiteCardStyle = { border: `1px solid ${theme.colors.greys.light}` };

  const handleValidation = async () => {
    if (!shouldGenerateInvoice) {
      handlePreparationValidation(false);
      return;
    }

    pageLoading();

    try {
      const areStoreInformationComplete = await orderService.checkCompaniesInformation(
        storeId,
        supplierId,
      );

      setHasStoreInformationError(!areStoreInformationComplete);

      if (areStoreInformationComplete) {
        handlePreparationValidation(true);
        return;
      }

      // we make pageLoaded after return because if there is no error handlePreparationValidation function will do it
      pageLoaded();
    } catch {
      setHasStoreInformationError(true);
      // we make pageLoaded on catch because if there is no error handlePreparationValidation function will do it
      pageLoaded();
    }
  };

  return (
    <OrderCartContainer>
      <WhiteCardContainer width={'65%'}>
        <WhiteCard
          customStyle={customWhiteCardStyle}
          maxWidth={'inherit'}
          renderContent={
            <>
              <TitleWhiteCard>{i18next.t('ORDERS.PREPARATIONS.PREVIEW_TITLE')}</TitleWhiteCard>
              {!!htmlPreview && <div>{parse(htmlPreview)}</div>}
            </>
          }
          width={'100%'}
        ></WhiteCard>
      </WhiteCardContainer>
      <WhiteCardContainer width={'35%'}>
        <WhiteCard
          customStyle={customWhiteCardStyle}
          renderContent={
            <>
              <TitleWhiteCard>
                {i18next.t('ORDERS.PREPARATIONS.VALIDATE_DELIVERY_NOTE')}
              </TitleWhiteCard>

              <ContainerTextArea>
                <SubTitleWhiteCard>
                  {i18next.t('ORDERS.PREPARATIONS.WYSIWYG_TITLE')}
                </SubTitleWhiteCard>
                <WysiwygEditor content={receptionComment} setter={setReceptionComment} />
              </ContainerTextArea>

              {hasGenerateInvoiceProps && (
                <InvoiceContainer>
                  <CheckboxContainer>
                    <Checkbox
                      handleClick={() => setShouldGenerateInvoice(!shouldGenerateInvoice)}
                      isChecked={shouldGenerateInvoice}
                      shape={'square'}
                      noSize
                    />
                    <Text>{i18next.t('ORDERS.PREPARATIONS.GENERATE_INVOICE')}</Text>
                    <Tooltip text={i18next.t('ORDERS.PREPARATIONS.GENERATE_INVOICE_WARNING')} />
                  </CheckboxContainer>
                  {hasStoreInformationError && (
                    <Text
                      color={ENUM_COLORS.INFO_RED}
                      font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14_WEIGHT_600}
                    >
                      {i18next.t('ORDERS.PREPARATIONS.GENERATE_INVOICE_ERROR')}
                    </Text>
                  )}
                </InvoiceContainer>
              )}

              <ButtonContainer>
                <Button
                  color={'inpulse-default'}
                  handleClick={handleValidation}
                  icon={'/images/inpulse/check-white-small.svg'}
                  label={i18next.t('GENERAL.VALIDATE')}
                />
              </ButtonContainer>
            </>
          }
          width={'100%'}
        ></WhiteCard>
      </WhiteCardContainer>
    </OrderCartContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(null, mapDispatchToProps)(DeliveryNotePreview);
