import { connect } from 'react-redux';
import { DateRangePicker } from 'react-dates';
import i18next from 'i18next';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { showConfirmationMessage } from '@actions/messageconfirmation';

import { Button } from '@commons/utils/styledLibraryComponents';
import {
  renderCustomArrowIcon,
  renderCustomNavIconLeft,
  renderCustomNavIconRight,
  renderTodayDateCircle,
} from '@commons/DeepsightAnalyticsHeaders/utils';
import utilsXLS from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { cashierProductService } from '@services/cashierProduct';

import { createListingSheet } from './utils';
import {
  Title,
  Header,
  Footer,
  Container,
  DatePicker,
  ContentDateSelection,
} from './styledComponents';

const DEFAULT_TITLE_EXPORT_FAILURE = 'GENERAL.EXPORT_FAILURE';
const DEFAULT_TITLE_SELECT_DATE_RANGE =
  'BACKOFFICE.CASHIER_PRODUCTS.EXPORT_DATE_RANGE_SELECTION_TEXT';
const DEFAULT_TITLE_EXPORT_IN_PROGRESS = 'BACKOFFICE.CHECKS.EXPORT_IN_PROGRESS';
const DEFAULT_TITLE_EXPORT_READY = 'BACKOFFICE.CHECKS.EXPORT_FINISHED';

const FILENAME =
  'BACKOFFICE.PRODUCTS.CASHIER_PRODUCTS_GENERATE_MOST_CONSUMED_CASHIER_PRODUCTS_EXPORT_FILENAME';

const TIMEOUT_GET_MOST_CONSUMED_CASHIER_PRODUCTS_STATUS = 5000; // in ms

/************************/
/** Components methods **/
/************************/

export function updateProgress(nbDaysLeftToFetch, nbDaysFetched, setProgress) {
  const totalDaysToFetch = nbDaysLeftToFetch + nbDaysFetched;

  const updatedProgress = 100 - (nbDaysLeftToFetch / totalDaysToFetch) * 100;

  setProgress(updatedProgress);
}

export function exitModal(setLoading, closeModal) {
  setLoading(false);

  closeModal();
}

export function exportReady(setLoading, setTitle) {
  setTitle(i18next.t(DEFAULT_TITLE_EXPORT_READY));
  setLoading(false);
}

export function exportFailure(setLoading, setTitle) {
  setTitle(i18next.t(DEFAULT_TITLE_EXPORT_FAILURE));
  setLoading(false);
}

/*******************/
/** Fetch methods **/
/*******************/

export async function loadMostConsumedCashierProducts(
  clientId,
  startDate,
  endDate,
  setLoading,
  setTitle,
  setIsGettingMostConsumedCashierProducts,
) {
  // Perform request
  try {
    await cashierProductService.getMostConsumedCashierProductsByClientId(
      clientId,
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD'),
    );

    setIsGettingMostConsumedCashierProducts(true);
  } catch (err) {
    exportFailure(setLoading, setTitle);
  }
}

/********************/
/** Render methods **/
/********************/

export function renderDateSelection(props) {
  const {
    endDate,
    startDate,
    closeModal,
    setLoading,
    focusedDateInput,
    setFocusedDateInput,
    handleSelectedDates,
    setIsSettingDateRange,
  } = props;

  return (
    <div>
      <Header style={{ padding: '25px' }} noShadow white>
        <Title black medium>
          {i18next.t(DEFAULT_TITLE_SELECT_DATE_RANGE)}
        </Title>
      </Header>

      <ContentDateSelection>
        <div>{i18next.t('BACKOFFICE.CASHIER_PRODUCTS.EXPORT_DATE_RANGE_SELECTION_TEXT')}</div>
        <DatePicker focusedDateInput={focusedDateInput}>
          <img
            alt=""
            className="orderform-date-input-icon"
            src={'/images/icon-calendar-black-inpulse.svg'}
            style={{ width: '16px', height: '16px' }}
          />
          <DateRangePicker
            calendarInfoPosition={'top'}
            customArrowIcon={renderCustomArrowIcon()}
            daySize={35}
            displayFormat={'ddd Do MMM'}
            endDate={endDate}
            endDateId="your_unique_end_date_id"
            endDatePlaceholderText={i18next.t(
              'GENERAL.ANALYTICS_HEADER_DATE_PICKER_END_PLACEHOLDER',
            )}
            focusedInput={focusedDateInput}
            hideKeyboardShortcutsPanel={true}
            isOutsideRange={(day) => day.isAfter(moment().subtract(1, 'days').endOf('day'))}
            minimumNights={0}
            navNext={renderCustomNavIconRight()}
            navPrev={renderCustomNavIconLeft()}
            noBorder={true}
            numberOfMonths={2}
            renderDayContents={(item) => renderTodayDateCircle(item, [startDate, endDate])}
            startDate={startDate}
            startDateId="your_unique_start_date_id"
            startDatePlaceholderText={i18next.t(
              'GENERAL.ANALYTICS_HEADER_DATE_PICKER_START_PLACEHOLDER',
            )}
            onDatesChange={(dates) => handleSelectedDates(dates, focusedDateInput)}
            onFocusChange={(updatedFocusedDateInput) =>
              setFocusedDateInput(updatedFocusedDateInput)
            }
          />
        </DatePicker>
      </ContentDateSelection>

      <Footer style={{ padding: '17px 20px' }} alignLeft shadow>
        <Button
          buttonCustomStyle={{ marginRight: 18 }}
          color={'blue-outline-no-shadow'}
          handleClick={() => exitModal(setLoading, closeModal)}
          icon={'/images/inpulse/close-black-small.svg'}
          label={i18next.t('GENERAL.CANCEL')}
          minWidth={90}
        />
        <Button
          buttonCustomStyle={{ marginRight: 18 }}
          color={startDate && endDate ? 'blue' : 'grey'}
          handleClick={() => {
            if (startDate && endDate) {
              setIsSettingDateRange(false);
            }
          }}
          icon={'/images/inpulse/check-white-small.svg'}
          label={i18next.t('GENERAL.VALIDATE')}
          minWidth={90}
        />
      </Footer>
    </div>
  );
}

/* export function renderExportProgress(props) {
  const { closeModal, title, progress, isLoading, setLoading } = props;

  return (
    <div>
      <Header>
        <Title>{title}</Title>
      </Header>

      <ContentProgressBar>
        <ProgressBar now={progress} />
      </ContentProgressBar>

      <Footer>
        <Button
          color={isLoading ? 'white' : 'blue'}
          handleClick={() => exitModal(setLoading, closeModal)}
          marginRight={18}
          label={isLoading ? i18next.t('GENERAL.CANCEL') : i18next.t('GENERAL.CLOSE')}
          minWidth={150}
        />
      </Footer>
    </div>
  );
} */

export const CashierProductExportModal = (props) => {
  const {
    closeModal,
    params: { clientId },
  } = props;

  const [progress, setProgress] = useState(0);
  const [isGettingMostConsumedCashierProducts, setIsGettingMostConsumedCashierProducts] =
    useState(false);

  const [title, setTitle] = useState(i18next.t(DEFAULT_TITLE_EXPORT_IN_PROGRESS));

  const [startDate, setStartDate] = useState(moment().subtract(1, 'day'));
  const [endDate, setEndDate] = useState(moment().subtract(1, 'day'));

  const [focusedDateInput, setFocusedDateInput] = useState();

  const [isLoading, setLoading] = useState(true);
  const [isSettingDateRange, setIsSettingDateRange] = useState(true);

  const handleSelectedDates = (dates) => {
    if (!moment(dates.startDate).isSame(startDate, 'day')) {
      setEndDate(null);
      setStartDate(dates.startDate);
    }

    if (!moment(dates.endDate).isSame(endDate, 'day')) {
      setEndDate(dates.endDate);
    }
  };

  useEffect(() => {
    if (!isGettingMostConsumedCashierProducts) {
      return;
    }

    let isFetchingData = false;

    const getClientInterval = setInterval(() => {
      (async function loadData() {
        if (isFetchingData) {
          return;
        }

        isFetchingData = true;

        try {
          setProgress((prev) => {
            if (prev < 90) {
              return prev + 10;
            }
          });

          const getMostConsumedCashierProductsInProgress =
            await cashierProductService.getMostConsumedCashierProductsByClientId(
              clientId,
              startDate.format('YYYY-MM-DD'),
              endDate.format('YYYY-MM-DD'),
              '',
            );

          if (getMostConsumedCashierProductsInProgress.length) {
            setProgress(100);

            const listingDataSheet = createListingSheet(getMostConsumedCashierProductsInProgress);

            utilsXLS.makeXLS(i18next.t(FILENAME), [listingDataSheet]);

            return exportReady(setLoading, setTitle);
          }

          if (
            getMostConsumedCashierProductsInProgress.state === 'FAILURE' ||
            getMostConsumedCashierProductsInProgress.state === 'REVOKED'
          ) {
            closeModal();
            props.showMessage(
              i18next.t(
                'BACKOFFICE.PRODUCTS.CASHIER_PRODUCTS_GENERATE_MOST_CONSUMED_CASHIER_PRODUCTS_ERROR',
              ),
              'error',
            );
          }

          isFetchingData = false;
        } catch (error) {
          closeModal();
          props.showMessage(
            i18next.t(
              'BACKOFFICE.PRODUCTS.CASHIER_PRODUCTS_GENERATE_MOST_CONSUMED_CASHIER_PRODUCTS_ERROR',
            ),
            'error',
          );
        }
      })();
    }, TIMEOUT_GET_MOST_CONSUMED_CASHIER_PRODUCTS_STATUS);

    return () => clearInterval(getClientInterval);
  }, [isGettingMostConsumedCashierProducts]);

  useEffect(() => {
    if (!isSettingDateRange) {
      loadMostConsumedCashierProducts(
        clientId,
        startDate,
        endDate,
        setLoading,
        setTitle,
        setIsGettingMostConsumedCashierProducts,
      );
    }
  }, [startDate, endDate, isSettingDateRange]);

  return (
    <div className={props.className}>
      {isSettingDateRange && (
        <Container>
          {renderDateSelection({
            endDate,
            startDate,
            closeModal,
            setLoading,
            focusedDateInput,
            setFocusedDateInput,
            handleSelectedDates,
            setIsSettingDateRange,
          })}
        </Container>
      )}

      {!isSettingDateRange && (
        <ExportModalContent
          {...props}
          closeModal={closeModal}
          exitModal={exitModal}
          isLoading={isLoading}
          progress={progress}
          setLoading={setLoading}
          titleModal={title}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(styled(CashierProductExportModal)`
  & .orderform-date-input-icon,
  .multiple-dropdown-icon {
    margin-left: 15px;
  }

  & .DateInput_fang {
    display: none !important;
  }

  & .DateRangePickerInput__disabled {
    background-color: lightgrey !important;
  }

  & .CalendarMonth_caption {
    color: ${(props) => props.theme.colors?.greys?.darkest} !important;
    font: ${(props) => props.theme.fonts?.h3} !important;
  }

  & .DateInput_input {
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    text-align: center !important;
    cursor: pointer;
  }

  & .DateInput_input__focused {
    border-bottom: none !important;
  }

  & .DayPicker_weekHeader_li {
    font: ${(props) => props.theme.fonts?.textMicro} !important;
  }

  & .CalendarDay {
    background: ${(props) => props.theme.colors?.greys.lightest} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__default:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__highlighted_calendar {
    background: #fff !important;
    border: 1px double #0a3eff !important;
  }

  & .CalendarDay__highlighted_calendar:hover {
    background: #e4e7e7 !important;
  }

  & .CalendarDay__selected_span {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    border: 1px double #e2e2e2 !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected,
  .CalendarDay__selected:active .CalendarDay__selected:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_start,
  .CalendarDay__selected_start:hover,
  .CalendarDay__selected_end,
  .CalendarDay__selected_end:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover {
    color: ${(props) => props.theme.colors?.greys.dark} !important;
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
  }
`);
