import { chain, get } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { ConfirmationModal } from '@commons/Modals/ConfirmationModal';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { NONE_DROPDOWN_VALUES } from '@commons/constants/dropdown';
import InputContentModal from '@commons/Modals/InputContentModal';
import ItemListSorter from '@commons/ItemListSorter';

import { ELISION_CHECK_REGEX } from '@admin/stores/utils/actionsUtils';
import StoreAssortmentSetupModal, {
  MODAL_STEP,
} from '@admin/stores/components/StoreAssortmentSetupModal';

export const getGroupsModalParams = (
  groups,
  handleGroupsChange,
  handleGroupsSave,
  storeName,
  propertyNoneLabel,
  isDisabled = true,
) => ({
  type: 'action',
  width: '542px',
  height: 'auto',
  maxHeight: '560px',
  icon: '/images/inpulse/gear-ipblack-small.svg',
  title: i18next.t('ADMIN.STORES.MANAGE_GROUPS'),
  component: ItemListSorter,
  data: {
    data: groups,
    properties: Object.keys(groups).sort(),
    entityName: storeName,
    disableCreateItem: false,
    createItemLabel: i18next.t('ADMIN.STORES.MANAGE_GROUPS_CREATE_LABEL'),
    disableMergeItems: true,
    onChange: handleGroupsChange,
    propertyNoneLabel,
  },
  closeConfig: {
    title: i18next.t('GENERAL.LEAVE'),
    content: i18next.t('ADMIN.STORES.MANAGE_GROUPS_CLOSE_CONFIRMATION_CONTENT'),
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      key: 1,
      handleClick: () => handleGroupsSave(groups),
      isDisabled,
    },
  ],
});

export const getAssortmentSetupModal = (
  storesList,
  selectedStores,
  storeName,
  hasMultipleBrands,
  storeIdReference,
  setStoreIdReference,
  resetModalStates,
  setModalStep,
) => {
  const groupItems = chain(storesList)
    .map(({ groups }) => groups.map(({ id, name }) => ({ id, value: name })))
    .flatten()
    .sortBy('value')
    .sortedUniqBy('id') // uniqBy optimized for sorted arrays
    .value();

  groupItems.unshift(NONE_DROPDOWN_VALUES.MASCULINE);

  return {
    type: 'actionWhite',
    isFullscreen: true,
    title: i18next.t('ADMIN.STORES.SETUP_ASSORTMENT_FROM_ANOTHER_STORE_MODAL_TITLE', {
      storeName,
    }),
    icon: '/images/inpulse/link-ip-black.svg',
    customPadding: '24px 0px 0px 0px',
    component: StoreAssortmentSetupModal,
    data: { storesList, selectedStores, setStoreIdReference, hasMultipleBrands, groupItems },
    actions: [
      {
        ...GENERIC_MODAL_CANCEL_BUTTON(),
        handleClick: resetModalStates,
      },
      {
        ...GENERIC_MODAL_SAVE_BUTTON(),
        isDisabled: !storeIdReference,
        handleClick: () => setModalStep(MODAL_STEP.VALIDATION),
      },
    ],
  };
};

export const getAssortmentConfirmationModal = (
  stores,
  referenceStoreName,
  storeNameSingle,
  storeNamePlural,
  resetModalStates,
  setModalStep,
) => {
  const isStoreNameFirstLetterAnElision = ELISION_CHECK_REGEX.test(storeNameSingle[0]);

  return {
    type: 'warning',
    width: '560px',
    height: 'auto',
    isFullscreen: false,
    icon: '/images/inpulse/gear-white-small.svg',
    component: ConfirmationModal,
    data: {
      content: i18next.t(
        isStoreNameFirstLetterAnElision
          ? 'ADMIN.STORES.SETUP_ASSORTMENT_FROM_ANOTHER_STORE_CONFIRMATION_MODAL_CONTENT_CIENT_STORENAME_VOWEL'
          : 'ADMIN.STORES.SETUP_ASSORTMENT_FROM_ANOTHER_STORE_CONFIRMATION_MODAL_CONTENT_CIENT_STORENAME_CONSONANT',
        {
          count: stores.length,
          storeName: get(stores, '[0].name', '-'),
          referenceStoreName,
          clientStoreName: storeNameSingle.toLowerCase(),
          clientStoreNamePlural: storeNamePlural.toLowerCase(),
        },
      ),
    },
    title: i18next.t('ADMIN.STORES.SETUP_ASSORTMENT_FROM_ANOTHER_STORE_CONFIRMATION_MODAL_TITLE'),
    actions: [
      {
        ...GENERIC_MODAL_CANCEL_BUTTON(),
        handleClick: resetModalStates,
      },
      {
        ...GENERIC_MODAL_SAVE_BUTTON(),
        label: i18next.t('GENERAL.CONFIGURE'),
        icon: '/images/inpulse/gear-white-small.svg',
        handleClick: () => setModalStep(MODAL_STEP.PROGRESS_BAR),
      },
    ],
  };
};

// Create group "on the fly"
export const getGroupCreationParamsModal = (
  inputValue,
  handleInputChange,
  handleSaveNewGroupDropdown,
  errorMessage,
  closeCleanUp,
) => ({
  type: 'action',
  width: '560px',
  height: 'auto',
  icon: '/images/inpulse/add-black-small.svg',
  title: i18next.t('ADMIN.STORES.MANAGE_GROUPS_CREATE_LABEL'),
  isLoading: false,
  handleCloseCleanUp: closeCleanUp,
  component: () => (
    <InputContentModal
      errorMessage={errorMessage}
      label={`* ${i18next.t('GENERAL.MODAL_GROUP_NAME')}`}
      value={inputValue}
      autoFocus
      onChange={(value) => handleInputChange(value)}
    />
  ),
  closeConfig: {
    title: i18next.t('GENERAL.LEAVE'),
    content: i18next.t('ADMIN.STORES.MANAGE_GROUPS_CLOSE_CONFIRMATION_CONTENT'),
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      isDisabled: !inputValue || !!errorMessage,
      handleClick: handleSaveNewGroupDropdown,
    },
  ],
});
