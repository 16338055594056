import i18next from 'i18next';

import { ConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { GENERIC_MODAL_OKAY_BUTTON } from '@commons/Modals/GenericModal/genericModalActions';

export const getModalConfig = ({ countProducts, countCashierProducts }, goBackAction) => ({
  type: 'success',
  title: i18next.t('ADMIN.CASHIER_PRODUCTS.AUTO_MAPPING_MODAL_SUCCESS_TITLE'),
  icon: '/images/inpulse/bolt-white-small.svg',
  width: '542px',
  height: 'auto',
  component: ConfirmationModal,
  data: {
    content: i18next.t(
      countCashierProducts > 1
        ? 'ADMIN.CASHIER_PRODUCTS.AUTO_MAPPING_MODAL_SUCCESS_CONTENT_MULTIPLE'
        : 'ADMIN.CASHIER_PRODUCTS.AUTO_MAPPING_MODAL_SUCCESS_CONTENT_SINGLE',
      {
        countProducts,
        countCashierProducts,
      },
    ),
  },
  actions: [{ ...GENERIC_MODAL_OKAY_BUTTON(), handleClick: goBackAction }],
});
