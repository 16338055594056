import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, openMediumModal, refreshGenericModal } from '@actions/modal';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import {
  patchStoreSupplierProfileMappingByField,
  patchStoreSupplierProfileMappingByFieldError,
  patchStoreSupplierProfileMappingByFieldSuccess,
} from '@actions/storeSupplierProfileMapping';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getStoresByAccountIdAndSupplierId } from '@services/supplier';
import { ListView } from '@commons/utils/styledLibraryComponents';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';
import EmptyState from '@commons/EmptyState';

import { ENUM_QUERY_PARAMS, useListViewQueryParams } from '@hooks/useListViewQueryParams';

import { canEditSupplierFromDetailView } from '@selectors/actions/supplierActions';
import { canUpdateAssociatedSupplier } from '@selectors/user';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { storeSupplierProfileMapping } from '@services/storeSupplierProfileMapping';

import DeepsightFiltersButton from '@orders/components/FilterButton';

import { getAssociatedStoreCodeParams } from './utils/getAssociatedStoreCodeParamsModal';

import { exportAssociatedStores } from './utils/exportAssociatedStores';

import { Container } from './styledComponents';
import utilsActions from './utils/actions';
import utilsColumns from './utils/columns';

export function renderEmptyState() {
  return <EmptyState label={i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_EMPTY_STATE')} />;
}

export const SupplierProfile = (props) => {
  const {
    user,
    client: { storeName, hasLocalCatalogs },
    supplierId,
    openGenericModal,
    supplier,
    authorizedActions,
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [columnsFilterList, setColumnsFilterList] = useState([]);

  const [associatedStores, setAssociatedStores] = useState([]);
  const [filteredAssociatedStores, setFilteredAssociatedStores] = useState([]);

  const [listViewQueryParams, setListViewQueryParams] = useListViewQueryParams();

  const userLanguageCode = _.get(user, 'lnkLanguageAccountrel.code', 'fr');
  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [columns, setColumns] = useState(
    utilsColumns.getColumnsSettings({ shouldDisplayEdi: false }),
  );
  const [shouldDisplayCdn, setShouldDisplayCdn] = useState(false);

  const translatedStoreName = getClientStoreNameTranslation(storeName, true);

  const isAllowedToEdit =
    canUpdateAssociatedSupplier(user, supplier, { hasLocalCatalogs }) &&
    canEditSupplierFromDetailView(authorizedActions);

  const shouldDisplayEdi = !!supplier.ediPartner && supplier.ediPartner != '';

  const reloadAll = async () => {
    setIsLoading(true);
    await getStoreAssociatedStores(
      _.get(user, 'id'),
      supplierId,
      setAssociatedStores,
      updateColumnsFilterList,
      props.showMessage,
    );
    setIsLoading(false);
  };

  useEffect(() => {
    if (!user || !supplierId) {
      return;
    }

    (async function loadData() {
      reloadAll();
    })();
  }, [user, supplierId]);

  useEffect(() => {
    setColumns(
      utilsColumns.getColumnsSettings({
        shouldDisplayEdi,
        isKitchen: supplier.isKitchen,
        shouldDisplayCdn,
      }),
    );
  }, [supplier, shouldDisplayCdn]);

  useEffect(() => {
    processAdvancedFilter(associatedStores);
    updateColumnsFilterList(associatedStores, columns);
  }, [applyFilters, advancedFilters, associatedStores, columns]);

  useEffect(() => {
    setActions(
      utilsActions.getActionsSettings({
        selectedItems,
        handleUpdateAssociateStoreCode,
        handleUpdateAssociatedStore,
        openGenericModal,
        shouldDisplayEdi,
        getAssociatedStoreCodeParams,
        handleExport,
        supplier,
        associatedStores,
        changeEdiStatus,
        isAllowedToEdit,
      }),
    );
    setRowActions(
      utilsActions.getRowActionsSettings({
        handleUpdateAssociateStoreCode,
        handleUpdateAssociatedStore,
        openGenericModal,
        shouldDisplayEdi,
        getAssociatedStoreCodeParams,
        handleExport,
        supplier,
        changeEdiStatus,
        isAllowedToEdit,
      }),
    );
  }, [selectedItems, supplier, associatedStores]);

  const handleExport = (associatedStores, supplier) =>
    exportAssociatedStores({
      associatedStores,
      translatedStoreName,
      supplierName: supplier.name,
      shouldDisplayEdi,
    });

  const changeEdiStatus = async (stores, activate) => {
    setIsLoading(true);
    try {
      const storeSupplierProfileMappingsIdsOfSelectedItems = stores.map(
        (store) => store.storeSupplierProfileMappingId,
      );

      await storeSupplierProfileMapping.updateStoreSupplierProfileMappings(
        storeSupplierProfileMappingsIdsOfSelectedItems,
        { isEdi: activate },
        supplierId,
      );

      await reloadAll();
      activate
        ? props.showMessage(i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_ACTIVATE_EDI_SUCCESS'))
        : props.showMessage(i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_DESACTIVATE_EDI_SUCCESS'));
    } catch (err) {
      activate
        ? props.showMessage(
            i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_ACTIVATE_EDI_FAILURE'),
            'error',
          )
        : props.showMessage(
            i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_DESACTIVATE_EDI_FAILURE'),
            'error',
          );
    }
  };

  const getStoreAssociatedStores = async (
    userId,
    supplierId,
    setAssociatedStores,
    updateColumnsFilterList,
    showMessage,
  ) => {
    try {
      props.pageLoading();
      const associatedStores = await getStoresByAccountIdAndSupplierId(userId, supplierId);

      // At least one associated stores has bli set up
      const shouldDisplayCdn = associatedStores.some(({ hasBli }) => hasBli);

      const formattedStores = associatedStores.map((store) => ({
        ...store,
        supplierProfileName: _.get(store, 'supplierProfile.name'),
      }));

      setAssociatedStores(formattedStores);
      updateColumnsFilterList(formattedStores, columns);
      setShouldDisplayCdn(shouldDisplayCdn);
    } catch (error) {
      showMessage(i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_FETCH_ERROR'), 'error');
      return [];
    } finally {
      props.pageLoaded();
    }
  };

  const processAdvancedFilter = (associatedStores) => {
    if (!applyFilters) {
      return;
    }

    const filteredAssociatedStores = associatedStores;

    if ((!advancedFilters || !advancedFilters.length) && applyFilters) {
      setFilteredAssociatedStores(filteredAssociatedStores);
      return;
    }
    const filteredAssociatedStoresWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, list, filterType, propertyKey, value }) => {
        if (filterType === 'string' && value && value.length === 1 && value[0].id === 'none') {
          return doFilter(result, propertyKey, list, true);
        }

        return doFilter(result, propertyKey, value);
      },
      filteredAssociatedStores,
    );

    return setFilteredAssociatedStores(filteredAssociatedStoresWithAdvancedFilters);
  };

  const handleUpdateAssociateStoreCode = (value, storeSupplierProfileMappingId, key) => {
    const params = getAssociatedStoreCodeParams(
      associatedStores.find(
        (store) => store.storeSupplierProfileMappingId === storeSupplierProfileMappingId,
      ),
      handleUpdateAssociateStoreCode,
      handleUpdateAssociatedStore,
      value,
      key,
    );

    props.refreshGenericModal({ data: params.data, actions: params.actions });
  };

  const handleUpdateAssociatedStore = async (codeValue, storeSupplierProfileMappingId, key) => {
    const fieldToUpdate = { [key]: codeValue };

    try {
      await storeSupplierProfileMapping.patchStoreSupplierProfileMappingFieldById(
        storeSupplierProfileMappingId,
        fieldToUpdate,
      );

      await getStoreAssociatedStores(
        _.get(user, 'id'),
        supplierId,
        setAssociatedStores,
        updateColumnsFilterList,
        props.showMessage,
      );

      props.showMessage(
        i18next.t(
          key === 'customerCode'
            ? 'ADMIN.SUPPLIERS.ASSOCIATED_STORES_CUSTOMER_CODE_LABEL_UPDATE_SUCCESS'
            : 'ADMIN.SUPPLIERS.ASSOCIATED_STORES_WAREHOUSE_CODE_LABEL_UPDATE_SUCCESS',
        ),
        'success',
      );
    } catch (error) {
      props.showMessage(
        i18next.t(
          key === 'customerCode'
            ? 'ADMIN.SUPPLIERS.ASSOCIATED_STORES_CUSTOMER_CODE_LABEL_UPDATE_ERROR'
            : 'ADMIN.SUPPLIERS.ASSOCIATED_STORES_WAREHOUSE_CODE_LABEL_UPDATE_ERROR',
        ),
        'error',
      );
    }
  };

  const updateColumnsFilterList = (associatedStores, columns) => {
    const columnsList = columns.filter((column) =>
      ['date', 'string', 'numeric', 'boolean'].includes(column.filterType),
    );
    const updateColumnsList = columnsList.reduce((result, column) => {
      const updatedColumn = { ...column };
      if (['supplierProfileName', 'city', 'country'].includes(column.propertyKey)) {
        const list = Object.values(
          associatedStores.reduce((result, store, key) => {
            if (store[column.propertyKey]) {
              result[store[column.propertyKey]] = {
                id: key,
                name: store[column.propertyKey],
                status: store[column.propertyKey],
                value: store[column.propertyKey],
                [column.propertyKey]: store[column.propertyKey],
              };
            }
            return result;
          }, {}),
        );

        updatedColumn.list = sortArrayOfObjectsAlphabetically(list, 'name');
      }
      if (['isEdi'].includes(column.propertyKey)) {
        const list = Object.values(
          associatedStores.reduce((result, store, key) => {
            if (store[column.propertyKey] === true) {
              result[i18next.t('GENERAL.YES')] = {
                id: key,
                name: i18next.t('GENERAL.YES'),
                status: i18next.t('GENERAL.YES'),
                value: i18next.t('GENERAL.YES'),
                [column.propertyKey]: i18next.t('GENERAL.YES'),
              };
            }
            if (store[column.propertyKey] === false) {
              result[i18next.t('GENERAL.NO')] = {
                id: key,
                name: i18next.t('GENERAL.NO'),
                status: i18next.t('GENERAL.NO'),
                value: i18next.t('GENERAL.NO'),
                [column.propertyKey]: i18next.t('GENERAL.NO'),
              };
            }
            return result;
          }, {}),
        );

        updatedColumn.list = sortArrayOfObjectsAlphabetically(list, 'name').reverse();
      }
      result.push(updatedColumn);

      return result;
    }, []);

    setColumnsFilterList(updateColumnsList.length ? updateColumnsList : null);
  };
  /**********\   RENDER   /***********/

  return (
    <>
      {!isLoading && (!associatedStores || associatedStores.length === 0) && renderEmptyState()}
      <Container>
        {associatedStores && associatedStores.length !== 0 && (
          <ListView
            actions={actions}
            columns={columns}
            data={filteredAssociatedStores}
            defaultCurrentPage={listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE]}
            defaultMaxPerPage={listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]}
            defaultOrderBy={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]}
            defaultOrderType={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]}
            defaultSearchInput={listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH]}
            handleCurrentPageChange={(input) =>
              setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](input)
            }
            handleMaxPerPageChange={(input) =>
              setListViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE](input)
            }
            handleOrderByChange={(input) =>
              setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY](input)
            }
            handleOrderTypeChange={(input) =>
              setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE](input)
            }
            handleSearchInputChange={(input) =>
              setListViewQueryParams[ENUM_QUERY_PARAMS.SEARCH](input)
            }
            isLoading={isLoading}
            languageCode={userLanguageCode}
            padding={'24px 24px 0px 24px'}
            placeholderShape={i18next.t('GENERAL.SEARCH')}
            renderEmptyState={() => <EmptyState />}
            renderFilterButton={() => (
              <DeepsightFiltersButton
                advancedFilters={advancedFilters}
                applyFilters={applyFilters}
                columnsFilterList={columnsFilterList}
                filters={filters}
                isLoading={isLoading}
                readOnly={isLoading}
                setAdvancedFilters={(it) => setAdvancedFilters(it)}
                setApplyFilters={(it) => setApplyFilters(it)}
                setFilters={(it) => setFilters(it)}
                textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
              />
            )}
            rowActions={rowActions}
            setSelectedItems={(items) => setSelectedItems(items)}
          />
        )}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/admin/suppliers/:id/details',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  patchStoreSupplierProductMappingField: (storeSupplierProfileMappingId, fieldToUpdate) => {
    dispatch(
      patchStoreSupplierProfileMappingByField(storeSupplierProfileMappingId, fieldToUpdate),
    ).then(
      (result) => dispatch(patchStoreSupplierProfileMappingByFieldSuccess(result)),
      (error) => {
        dispatch(patchStoreSupplierProfileMappingByFieldError(error));
      },
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierProfile);
