import _ from 'lodash';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import { getTheme } from '@commons/utils/theme';
import { sortPackagings } from '@commons/utils/format';
import GenericModal from '@commons/Modals/GenericModal';
import WhiteCard from '@commons/WhiteCard';

import {
  Container,
  ContentContainer,
  EmptyStateIcon,
  ModalContainer,
  Text,
} from './styledComponents';
import {
  getCurrentPackagingUnits,
  handleModalInputChange,
  handlePackagingSaving,
} from './components/InputPackagingContentModal';
import { getPackagingModalParams } from './utils/modalConfigurations';
import { PACKAGING_MODAL_TYPE } from './components/ListRow';
import ConditioningList from './components/ConditioningList';
import DeletionModalContent, { handleDelectionModalClose } from './components/DeletionModal';

const PackagingSection = ({
  isDuplicated,
  isErrorState,
  supplierProduct,
  handleSupplierProductChange,
  hasRightToEditPackagings,
}) => {
  const theme = getTheme();

  const [genericModalParams, setGenericModalParams] = useState(null);
  const [packagingModalType, setPackagingModalType] = useState(null);
  const [currentPackaging, setCurrentPackaging] = useState(null);
  const [unitDisabled, setUnitDisabled] = useState(false);

  const createPackagingModalParams = getPackagingModalParams({
    availableUnits: getCurrentPackagingUnits({
      supplierProductPackagings: supplierProduct.packagings,
      packagingModalType,
      currentPackaging,
    }),
    currentPackaging,
    entity: supplierProduct.entity,
    unitDisabled,
    isDuplicated,
    onInputChange: (fieldName, value, packagingId) =>
      handleModalInputChange({
        fieldName,
        value,
        packagingId,
        currentPackaging,
        supplierProduct,
        setCurrentPackaging,
      }),
    handlePackagingSaving: () =>
      handlePackagingSaving({
        supplierProduct,
        handleSupplierProductChange,
        setUnitDisabled,
        currentPackaging,
        packagingModalType,
      }),
    modalType: packagingModalType,
  });

  useEffect(() => {
    if (packagingModalType === PACKAGING_MODAL_TYPE.CREATE) {
      const sortedPackagings = supplierProduct.packagings
        ? sortPackagings([...supplierProduct.packagings])
        : [];

      const isPackagingEmpty = _.isEmpty(sortedPackagings);

      const updatedCurrentPackaging = {
        id: Date.now(), // random id
        name: null,
        quantity: null,
        isActive: true,
        isUsedInOrder: isPackagingEmpty,
        isUsedInStock: isPackagingEmpty,
        isUsedInInvoice: isPackagingEmpty,
        supplierProductId: supplierProduct.id,
        unit: isPackagingEmpty ? supplierProduct.packagingUnit : null,
        masterSupplierProductPackagingId: isPackagingEmpty ? null : _.first(sortedPackagings).id,
        parentSupplierProductPackagingId: isPackagingEmpty ? null : _.last(sortedPackagings).id,
      };

      if (isPackagingEmpty) {
        setUnitDisabled(supplierProduct.packagingUnit);
      }

      setCurrentPackaging(updatedCurrentPackaging);
    }
  }, [packagingModalType]);

  return (
    <Container>
      {genericModalParams && (
        <ModalContainer>
          <GenericModal
            closeGenericModal={() => handleDelectionModalClose(setGenericModalParams)}
            component={DeletionModalContent}
            params={genericModalParams}
          />
        </ModalContainer>
      )}
      {!_.isEmpty(packagingModalType) && (
        <ModalContainer>
          <GenericModal
            closeGenericModal={() => setPackagingModalType(null)}
            component={createPackagingModalParams.component}
            params={createPackagingModalParams}
          />
        </ModalContainer>
      )}
      <WhiteCard
        customStyle={isErrorState ? { border: `1px solid ${theme.colors.red}` } : {}}
        maxWidth="100%"
        renderContent={
          _.isEmpty(supplierProduct.packagings) ? (
            <ContentContainer>
              <EmptyStateIcon src={'/images/inpulse/supplier.svg'} />
              <Text customStyle={{ marginTop: '24px' }} isTitle>
                {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_EMPTY_STATE_TITLE')}
              </Text>
              <Text>
                {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_EMPTY_STATE_SUBTITLE')}
              </Text>
              <Button
                color={'inpulse-default'}
                handleClick={() => setPackagingModalType(PACKAGING_MODAL_TYPE.CREATE)}
                icon={'/images/inpulse/add-white-small.svg'}
                label={i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_EMPTY_STATE_BUTTON')}
              />
            </ContentContainer>
          ) : (
            <ConditioningList
              handleSupplierProductChange={handleSupplierProductChange}
              isDisabled={!hasRightToEditPackagings}
              setCurrentPackaging={setCurrentPackaging}
              setGenericModalParams={setGenericModalParams}
              setPackagingModalType={setPackagingModalType}
              sortedPackagings={sortPackagings(supplierProduct.packagings)}
            />
          )
        }
        title={i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS')}
        titleFont={`${theme.fonts.h2} !important`}
        tooltipText={i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_TOOLTIP')}
        width="100%"
      />
    </Container>
  );
};

PackagingSection.propTypes = {
  isDuplicated: PropTypes.bool,
  supplierProduct: PropTypes.object.isRequired,
};

PackagingSection.defaultProps = {
  isDuplicated: false,
  supplierProduct: {},
};

export default PackagingSection;
