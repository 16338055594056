import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  align-items: flex-start;
  justify-content: center;

  margin: 24px 24px 8px 24px;
  gap: 24px;

  height: calc(100% - 32px);
`;

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-height: calc(100% - 32px);

  background-color: ${(props) => props.theme.colors.greys.lightest};

  border: 1px solid ${(props) => props.theme.colors.greys.light};
  border-radius: 8px;

  margin: 0px;
  padding: 24px;

  overflow-y: scroll;
`;

export const Title = styled.div`
  font: ${(props) => props.theme.fonts.h1InterNonBold};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 24px;
`;
