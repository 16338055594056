import _, { pick } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { translateUnit } from '@commons/utils/translateUnit';
import utilsXLS from '@commons/utils/makeXLS';

const MAX_SHEET_ELEMENT_SIZE = 50000;

const getConsumptionsColumnsSettings = (clientStoreName) => [
  {
    name: i18next.t('GENERAL.INGREDIENT'),
    propertyKey: 'ingredientName',
  },
  {
    name: clientStoreName,
    propertyKey: 'storeName',
  },
  {
    name: i18next.t('GENERAL.DATE'),
    propertyKey: 'date',
    transform: (value) => moment(value).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
  },
  {
    name: i18next.t('GENERAL.PRODUCT'),
    propertyKey: 'productName',
  },
  {
    name: i18next.t('GENERAL.CHANNEL'),
    propertyKey: 'channelName',
  },
  {
    name: i18next.t('ANALYSIS.SALES.INGREDIENT_CONSUMPTION_COLUMN_UNITARY_CONSUMPTION'),
    propertyKey: 'recipeQuantity',
  },
  {
    name: i18next.t('ANALYSIS.SALES.INGREDIENT_CONSUMPTION_COLUMN_SOLD_PRODUCTS'),
    propertyKey: 'quantitySold',
  },
  {
    name: i18next.t('ANALYSIS.SALES.INGREDIENT_CONSUMPTION_COLUMN_TOTAL'),
    propertyKey: 'totalConsumption',
  },
  {
    name: i18next.t('GENERAL.UNIT'),
    propertyKey: 'ingredientUnit',
    transform: (value) => translateUnit(value),
  },
];

const getInformationsColumnsSettings = (clientStoreName) => [
  {
    name: clientStoreName,
    propertyKey: 'storeName',
  },
  {
    name: i18next.t('GENERAL.INGREDIENT'),
    propertyKey: 'ingredientName',
  },
  {
    name: i18next.t('GENERAL.START_DATE'),
    propertyKey: 'startDate',
    transform: (value) => moment(value).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  },
  {
    name: i18next.t('GENERAL.END_DATE'),
    propertyKey: 'endDate',
    transform: (value) => moment(value).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  },
];

const createConsumptionExportSheet = (consumptions, clientStoreName) => {
  const salesSheetTitle = i18next.t('ANALYSIS.SALES.INGREDIENT_CONSUMPTION_EXPORT_MAIN_SHEET_NAME');
  const columnSettings = getConsumptionsColumnsSettings(clientStoreName);

  return utilsXLS.generateDefaultSheet(salesSheetTitle, columnSettings, consumptions);
};

// TODO OPE-145
const createInformationsExportSheet = (consumptions, startDate, endDate, clientStoreName) => {
  const salesSheetTitle = i18next.t('GENERAL.INFORMATIONS');
  const columnSettings = getInformationsColumnsSettings(clientStoreName);

  const data = _(consumptions)
    .groupBy((analysisRow) => JSON.stringify(pick(analysisRow, ['ingredientName', 'storeName'])))
    .map((analysisGroup) => {
      const { ingredientName, storeName } = analysisGroup[0];

      return {
        storeName,
        ingredientName,
        startDate,
        endDate,
      };
    })
    .value();

  return utilsXLS.generateDefaultSheet(salesSheetTitle, columnSettings, data);
};

export const exportConsumptions = (consumptions, startDate, endDate, clientStoreName) => {
  const consumptionSheet = createConsumptionExportSheet(consumptions, clientStoreName);

  const informationsSheet = createInformationsExportSheet(
    consumptions,
    startDate,
    endDate,
    clientStoreName,
  );

  const consumptionsSheetData = consumptionSheet.data;

  const hasManyFiles = consumptionsSheetData.length > MAX_SHEET_ELEMENT_SIZE;

  let generatedFileCount = 1;

  // We must do a do while because we need to create at least one file (0 consumption data case)
  do {
    const consumptionBatchData = consumptionsSheetData.splice(0, MAX_SHEET_ELEMENT_SIZE);

    utilsXLS.makeXLS(
      hasManyFiles
        ? `${i18next.t(
            'ANALYSIS.SALES.INGREDIENT_CONSUMPTION_EXPORT_FILENAME',
          )}-${generatedFileCount}`
        : i18next.t('ANALYSIS.SALES.INGREDIENT_CONSUMPTION_EXPORT_FILENAME'),
      [{ ...consumptionSheet, data: consumptionBatchData }, informationsSheet],
    );

    generatedFileCount++;
  } while (consumptionsSheetData.length > 0);
};
