import i18next from 'i18next';
import utilsXLS from '@commons/utils/makeXLS';

import { formatDateWithLanguageCode } from '@commons/utils/date';

const createMainSheet = (
  productsToBeExported,
  { hasMultipleBrands, hasMultipleServices, userLanguageCode, currency },
) => {
  const columns = [
    {
      propertyKey: 'name',
      name: i18next.t('GENERAL.PRODUCT'),
    },
    {
      propertyKey: 'category',
      name: i18next.t('GENERAL.CATEGORY'),
    },
    {
      propertyKey: 'price',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_PRICE_TAX_INCLUDED'),
      type: 'currency',
    },
    {
      propertyKey: 'launchDate',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_LAUNCH_DATE'),
      transform: (date) => date && formatDateWithLanguageCode(date, userLanguageCode),
    },
    {
      propertyKey: 'endDate',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_END_DATE'),
      transform: (date) => date && formatDateWithLanguageCode(date, userLanguageCode),
    },
    {
      propertyKey: 'hasStock',
      name: i18next.t('ADMIN.STORES.STOCKS_COLUMN'),
      transform: (hasStock) => i18next.t(hasStock ? 'GENERAL.YES' : 'GENERAL.NO'),
    },
  ];

  if (hasMultipleBrands) {
    columns.splice(1, 0, {
      propertyKey: 'brand',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_BRAND'),
    });
  }

  if (!hasMultipleServices) {
    columns.push({
      propertyKey: 'min',
      name: i18next.t('ADMIN.STORES.MINIMUM_COLUMN'),
    });
  } else {
    columns.push(
      {
        propertyKey: 'minNoon',
        name: i18next.t('ADMIN.STORES.MINIMUM_COLUMN_NOON'),
      },
      {
        propertyKey: 'minEvening',
        name: i18next.t('ADMIN.STORES.MINIMUM_COLUMN_EVENING'),
      },
    );
  }

  return utilsXLS.generateDefaultSheet(
    i18next.t('GENERAL.PRODUCT_PLURAL'),
    columns,
    productsToBeExported,
    currency,
  );
};

export const exportProductionAssortment = (
  productsToBeExported,
  { hasMultipleBrands, hasMultipleServices, userLanguageCode, currency },
) => {
  const filename = i18next.t('ADMIN.STORES.PRODUCT_MAPPING_EXPORT_FILENAME');

  const mainSheet = createMainSheet(productsToBeExported, {
    hasMultipleBrands,
    hasMultipleServices,
    userLanguageCode,
    currency,
  });

  utilsXLS.makeXLS(filename, [mainSheet]);
};
