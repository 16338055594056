import { user } from '../services/user';

//Synchronous action creators
export function requestUser() {
  return {
    type: 'USER_REQUEST',
  };
}

export function receiveUser(user) {
  return {
    type: 'USER_SUCCESS',
    user,
  };
}

export function fetchUserError(error) {
  return {
    type: 'USER_FAILURE',
    error,
  };
}

export function requestUserRights() {
  return {
    type: 'USER_RIGHTS_REQUEST',
  };
}

export function receiveUserRights(userRights) {
  return {
    type: 'USER_RIGHTS_SUCCESS',
    userRights,
  };
}

export function fetchUserRightsError(error) {
  return {
    type: 'USER_RIGHTS_FAILURE',
    error,
  };
}

export function requestChangePassword() {
  return {
    type: 'CHANGE_PASSWORD_REQUEST',
  };
}

export function changePasswordSuccess() {
  return {
    type: 'CHANGE_PASSWORD_SUCCESS',
  };
}

export function changePasswordError() {
  return {
    type: 'CHANGE_PASSWORD_FAILURE',
  };
}

export function changePassword(old_password, new_password) {
  return function (dispatch) {
    dispatch(requestChangePassword());
    return user.changePassword(old_password, new_password);
  };
}

export function updateUserPassword(accountId, newPassword) {
  return function (dispatch) {
    dispatch(requestChangePassword());
    return user.updateUserPassword(accountId, newPassword);
  };
}

export function switchCentralMode(centralMode) {
  return {
    type: 'UPDATE_CENTRAL_MODE',
    centralMode,
  };
}

export function fetchUser(id) {
  return function (dispatch) {
    dispatch(requestUser());
    user.getAccountById(id).then(
      (result) => {
        dispatch(receiveUser(result));
      },
      (error) => {
        dispatch(fetchUserError(error));
      },
    );
  };
}

export function isAuthenticated() {
  return function (dispatch) {
    dispatch({ type: 'USER_VERIFICATION_REQUEST' });
    user
      .isAuthenticated()
      .then(() => {
        dispatch({ type: 'USER_VERIFICATION_SUCCESS' });
      })
      .catch(() => {
        dispatch({ type: 'USER_VERIFICATION_FAILURE' });
      });
  };
}
