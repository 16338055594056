import axios from '../core/http';

import { APIcaller } from './APIcaller';
import { config } from '../config';
import i18next from 'i18next';
import moment from 'moment';

const baseApiUrl = config.baseApiUrl;

export const getInventoriesListOfStoresByDate = async (storeIds, date, showMessage, type) => {
  const where = { storeId: { inq: storeIds } };

  if (type === 'between') {
    const start = moment(date).startOf('day');
    const end = moment(date).endOf('day');
    Object.assign(where, {
      timestamp: { between: [start, end] },
    });
  } else {
    Object.assign(where, {
      timestamp: { lt: moment(date).startOf('day') },
    });
  }

  const filter = {
    where,
    order: 'timestamp DESC',
    limit: 1,
    include: {
      relation: 'lnkStoreInventorylistrel',
      scope: {
        order: 'name ASC',
      },
    },
  };
  return axios
    .post(
      baseApiUrl + '/InventoryLists/getByFilter',
      {
        filter,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then((response) => response.data)
    .catch(() => {
      showMessage(i18next.t('STOCKS.STOCKS.LIST_GET_LIST_OF_STORE_ERROR'), 'error');
      return [];
    });
};

const checkStocksValid = (storeId, date) =>
  axios
    .get(baseApiUrl + '/admin/stores/' + storeId + '/stocks/valid', {
      params: {
        date,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const getInventoriesExportDataByIds = (inventoryListIds) =>
  axios
    .post(`${baseApiUrl}/stocks/inventories/export`, {
      inventoryListIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const getPaginatedInventoriesListOfStores = (
  storeIds,
  search,
  skip,
  limit,
  orderBy,
  orderType,
  params,
  inventoryListTemplateIds,
) => {
  let requestUri = `${baseApiUrl}/stocks/inventories?`;

  if (skip != null) {
    requestUri = requestUri.concat('skip=', `${skip}&`);
  }

  if (limit != null) {
    requestUri = requestUri.concat('limit=', `${limit}&`);
  }

  if (orderBy != null) {
    requestUri = requestUri.concat('orderBy=', `${orderBy}&`);
  }

  if (orderType != null) {
    requestUri = requestUri.concat('orderType=', `${orderType}&`);
  }

  if (search) {
    requestUri = requestUri.concat('search=', `${search}&`);
  }

  requestUri = requestUri.replace(/(&)$/, '');

  if (params) {
    requestUri = requestUri.concat(params);
  }

  let requestBody = { storeIds, inventoryListTemplateIds };

  return axios
    .post(requestUri, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

const fetchInventoriesStoresCount = (storeIds) =>
  axios
    .post(
      `${baseApiUrl}/stocks/inventories/count`,
      { storeIds },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const deleteInventoryListById = (id) =>
  axios
    .delete(`${baseApiUrl}/stocks/inventories/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const createInitialInventories = (
  storeIds,
  inventoryDate,
  userTimezone,
  isCentralKitchenView = false,
) =>
  APIcaller.apiPost('/stocks/init/create', {
    storeIds,
    inventoryDate,
    userTimezone,
    isKitchen: isCentralKitchenView,
  });

const updateInventoryListsValidation = (inventoryListIds, isValidated) =>
  APIcaller.apiPatch('/stocks/inventories/validation', { inventoryListIds, isValidated });

const createTheoreticalInventories = (
  storeIds,
  inventoryDate,
  inventoryListTemplateId,
  userTimezone,
) =>
  APIcaller.apiPost('/stocks/theoretical/create', {
    storeIds,
    inventoryDate,
    inventoryListTemplateId,
    userTimezone,
  });

export const inventoryList = {
  fetchInventoriesStoresCount,
  checkStocksValid,
  getInventoriesExportDataByIds,
  getPaginatedInventoriesListOfStores,
  getInventoriesListOfStoresByDate,
  deleteInventoryListById,
  createInitialInventories,
  updateInventoryListsValidation,
  createTheoreticalInventories,
};
