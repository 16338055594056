import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 90px;

  padding: 20px;

  display: flex;
  align-items: center;
`;

export const FilterContainer = styled.div`
  flex: 1;

  display: flex;
  align-items: center;

  ${(props) =>
    props.minWidth &&
    css`
      flex: none;

      > div {
        flex: none;
      }
    `}

  .deepsight-button {
    padding: 0px 15px;

    .icon {
      margin-right: 10px;
    }
  }
`;

export const Filter = styled.div`
  flex: 1;
  max-width: 280px;

  margin-right: ${(props) => (props.ranges ? '10px' : '24px')};
  min-width: ${(props) => props.ranges && '200px'};

  display: flex;
  align-items: center;

  &:last-child {
    margin-right: 0px;
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: lightgrey !important;

      .DateInput_input__disabled {
        color: #6b768c;
        font-style: normal;

        &:hover {
          color: #6b768c !important;
        }
      }
    `}

  ${(props) =>
    props.date &&
    css`
      min-width: 200px;
      max-width: 250px;
      height: 36px;

      &:hover {
        cursor: pointer;
      }

      border-radius: 4px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

      background-color: #fff;

      > div {
        display: flex;
        align-items: center;

        width: calc(100% - 40px);

        > div {
          width: 100%;

          > div {
            width: 100%;
            display: flex;

            .DateInput_1 {
              flex: 1;
            }
          }
        }
      }

      .DateRangePicker_picker {
        top: ${props.withExtraMarginTop ? '66px !important' : '45px !important'};
        z-index: 6;
      }

      color: #6b768c;
    `}

  ${(props) => {
    if (props.date) {
      return css`
        border: ${(props) =>
          props.focusedDateInput
            ? props.theme.inputs?.primary.focus.border
            : props.theme.inputs?.primary.default.border};
        border-radius: ${(props) => props.theme.inputs?.primary.default.borderRadius};
        box-shadow: none;
        /* color: unset !important; */

        .DateRangePicker_picker {
          left: ${'-30px !important'};
        }
      `;
    }
  }}

${(props) =>
    props.filterButton &&
    css`
      flex: none;
    `}

  .deepsight-button.locked {
    background-color: lightgray;
  }
`;

export const DisplayCurrentDay = styled.div`
  font-family: ${(props) => props.theme?.fonts?.families.secondary || 'inter'};
  font-size: 14px;
  font-weight: 600;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
