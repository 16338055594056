import InvoiceControlBatchImport from './invoiceControlBatchImport';
import InvoiceControlDetails from './invoiceControlDetails';
import InvoiceControls from './invoiceControls';

const InvoiceModule = {
  InvoiceControls,
  InvoiceControlDetails,
  InvoiceControlBatchImport,
};

export default InvoiceModule;
