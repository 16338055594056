import styled from 'styled-components';

export const Container = styled.div`
  width: 560px;
  height: auto;

  border-radius: 16px;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.08));

  background: ${(props) => props.theme.colors.greys.lightest};

  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  padding: 24px 48px;
`;

export const Title = styled.span`
  font: ${(props) => props.theme.fonts.h1InterNonBold};
  color: ${(props) => props.theme.colors.greys.darkest};

  &:after {
    content: attr(data-text);
    color: ${(props) => props.theme.colors.brand.primary};
    transform: translateX(-100%);
    position: absolute;
  }
`;

export const Subtitle = styled.div`
  font: ${(props) => props.theme.fonts.textSmallHeight24};
  color: ${(props) => props.theme.colors.greys.darker};
`;

export const Divider = styled.hr`
  margin: 0 48px;
  height: 1px;
  width: 464px;
  border-bottom: 1px solid ${(props) => props.theme.colors.greys.lighter};
  background-color: unset;
`;

export const FooterContainer = styled.div`
  height: 88px !important;
  padding: 24px 48px;
  display: flex;
  justify-content: center;
`;

export default {
  Container,
  TitleContainer,
  Title,
  Divider,
  FooterContainer,
};
