import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import i18next from 'i18next';
import React, { useEffect } from 'react';

import { ListView } from '@commons/utils/styledLibraryComponents';

import { Text } from '@admin/stores/components/styledComponents';

import { Container } from '../styledComponents';

const ListViewColumns = [
  {
    id: 'name',
    baseName: 'name',
    propertyKey: 'name',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_NAME'),
    displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_NAME'),
    render: (it) => <Text highlighted>{it}</Text>,
  },
  {
    id: 'partnerId',
    baseName: 'partnerId',
    propertyKey: 'partnerId',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_ID'),
    displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_ID'),
    displayBigger: true,
  },
  {
    id: 'city',
    baseName: 'city',
    propertyKey: 'city',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_CITY'),
    displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_CITY'),
    filterType: 'string',
    render: (item) => <Text>{item}</Text>,
  },
  {
    id: 'country',
    baseName: 'country',
    propertyKey: 'country',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_COUNTRY'),
    displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_COUNTRY'),
    filterType: 'string',
    render: (item) => <Text>{item}</Text>,
  },
];

const SelectStoresModal = (props) => {
  const { stores, selectStoresData, setSelectStoresData, handleSelectStores } = props;

  useEffect(() => {
    if (!isEmpty(selectStoresData)) {
      return;
    }

    setSelectStoresData(stores);
  }, []);

  return (
    <Container>
      <ListView
        columns={ListViewColumns}
        data={selectStoresData}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        disableFooter={true}
        forceEnableSelection={true}
        setSelectedItems={(items) => {
          handleSelectStores(items);
        }}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  stores: state.baseReducer.activeStores,
});

export default connect(mapStateToProps)(SelectStoresModal);
