export const BREAKPOINT = {
  EXTRA_LARGE: '1440px',
  LARGE: '1200px',
  MEDIUM: '1024px',
  SMALL: '768px',
};

export const SIDEBAR_WIDTH = '64px';

export const BREADCRUMB_HEIGHT = '40px';
