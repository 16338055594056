import './StoreDetails.css';
import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { Component } from 'react';

import { showErrorMessage } from '../../../actions/messageconfirmation';

import centralService from '../../../services/central';

import StoreAssociatedUsers from './StoreDetailsModules/StoreAssociatedUsers';
import StoreProductMappings from './StoreDetailsModules/StoreProductMappingList';
import StoreSupplierProductMapping from './StoreDetailsModules/StoreSupplierProductMappingList';
import StoreSupplierProfileMappings from './StoreDetailsModules/StoreSupplierProfileMappingList';

import {
  getSingleStore,
  receiveSingleStore,
  requestSingleStoreError,
} from '../../../actions/store';
import NavigationBar from '../../../commons/NavigationBar';

import { DAYS } from '../utils/DropdownItems';

import { ContentContainer } from './styledComponents';
import { ENUM_MODULE_NAME } from '../../../commons/utils/features';
import StoreInformations from './components/StoreInformations';

const PATHS_AND_PAGES = {
  '/admin/stores/:id/details': 'details',
  '/admin/stores/:id/details-users': 'details-users',
  '/admin/stores/:id/products': 'products',
  '/admin/stores/:id/supplierProfiles': 'supplierProfiles',
  '/admin/stores/:id/supplier-products': 'supplier-products',
};

export class StoreDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      store: {
        name: '',
        partnerId: '',
        adress: '',
        postCode: '',
        city: '',
        country: '',
        telephone: '',
        active: false,
      },
      closingDays: [],
      storeFeatures: [],
    };
  }

  getSelectedPageFromPath(path) {
    if (!path || typeof path !== 'string') {
      return null;
    }

    const storeDetailPaths = Object.keys(PATHS_AND_PAGES);

    const matchingPath = storeDetailPaths.find((item) => path === item);

    if (!matchingPath) {
      return null;
    }

    return PATHS_AND_PAGES[matchingPath];
  }

  async preProcessStore(store) {
    store.name = store.name || '';
    store.partnerId = store.partnerId || '';
    store.adress = store.adress || '';
    store.postCode = store.postCode || '';
    store.city = store.city || '';
    store.country = store.country || '';
    store.telephone = store.telephone || '';
    store.active = store.active || false;
    store.isKitchen = store.isKitchen || false;
    store.supplier = [];

    if (store.isKitchen) {
      try {
        store.supplier = await centralService.getkitchenSupplierByStoreIds([store.id]);
        store.consumptionComputation = store.consumptionComputation;
        store.showYesterdayStock = store.showYesterdayStock;
      } catch {
        this.props.showErrorMessage(i18next.t('ADMIN.STORES.FETCH_KITCHEN_SUPPLIER_ERROR'));
      }
    }

    //Handle closing days
    let closingDays = [];
    if (store.closingDays) {
      let storeClosingDays = store.closingDays.split(',');
      closingDays = DAYS.filter((day) => storeClosingDays.includes(day.itemValue.toString()));
    }
    this.setState({ store: store, closingDays: closingDays });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.closingDays !== this.state.closingDays) {
      let store = this.state.store;
      store.closingDays = this.state.closingDays;
      this.setState({ store: store });
    }

    if (prevProps.match !== this.props.match) {
      const path = _.get(this.props, 'match.path');

      this.setState({
        selectedPage: this.getSelectedPageFromPath(path),
      });
    }
  }

  componentDidMount() {
    const storeId = this.props.match.params.id ? this.props.match.params.id : '';

    this.props.getSingleStore(storeId).then((store) => {
      this.preProcessStore(store);
    });

    const path = _.get(this.props, 'match.path');

    this.setState({ selectedPage: this.getSelectedPageFromPath(path) });
  }

  render() {
    let { selectedPage, store, isFooterDisplay } = this.state;

    const path = _.get(this.props, 'match.path');

    return (
      <>
        <NavigationBar
          module={ENUM_MODULE_NAME.STORE_DETAIL}
          path={path}
          storeId={store.id}
          storeName={
            store.isKitchen
              ? i18next.t('ADMIN.STORES.NAVIGATION_BAR_TITLE_KITCHEN', { storeName: store.name })
              : i18next.t('ADMIN.STORES.NAVIGATION_BAR_TITLE_SALE_POINT', { storeName: store.name })
          }
        />
        {selectedPage === 'details' && store.id && (
          <ContentContainer isFooterDisplay={isFooterDisplay}>
            <StoreInformations storeParams={store} />
          </ContentContainer>
        )}
        {selectedPage === 'details-users' && store.id && (
          <StoreAssociatedUsers storeParams={store} />
        )}
        {selectedPage === 'products' && store.id && (
          <StoreProductMappings
            history={this.props.history}
            match={this.props.match}
            storeParams={store}
          />
        )}
        {selectedPage === 'supplierProfiles' && store.id && (
          <StoreSupplierProfileMappings history={this.props.history} storeParams={store} />
        )}
        {selectedPage === 'supplier-products' && store.id && (
          <StoreSupplierProductMapping history={this.props.history} storeParams={store} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  getSingleStore: (storeId) =>
    dispatch(getSingleStore(storeId))
      .then((result) => {
        dispatch(receiveSingleStore(result));
        return result;
      })
      .catch((error) => {
        dispatch(requestSingleStoreError(error));
      }),
  showErrorMessage: (message, type) => {
    dispatch(showErrorMessage(message, type));
  },
});

export default StoreDetails = connect(mapStateToProps, mapDispatchToProps)(StoreDetails);
