import i18next from 'i18next';
import React from 'react';

import { InpulseLabel } from '@commons/DeepsightComponents';

import TooltipAssociationList from '../components/TooltipAssociationList';

const renderTooltipOrLabelAssociation = (associated, item) => (
  <div
    className="supplier-label-render"
    style={{ minWidth: '115px', marginLeft: '2px', padding: '5px 0px' }}
  >
    {!!associated.length && <TooltipAssociationList cashierStore={item} />}

    {!associated.length && <InpulseLabel color="red" text={i18next.t('GENERAL.NO')} />}
  </div>
);

export const getColumnsSettings = () => [
  {
    id: 'group_name',
    name: i18next.t('GENERAL.NAME'),
    displayName: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
    baseName: 'name',
    large: true,
  },
  {
    id: 'cashier_connexion',
    name: i18next.t('GENERAL.CASHIER_CONNEXION'),
    displayName: i18next.t('GENERAL.CASHIER_CONNEXION'),
    propertyKey: 'cashierConfigName',
    baseName: 'cashierConfigName',
  },
  {
    id: 'address',
    name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_ADDRESS'),
    displayName: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_ADDRESS'),
    propertyKey: 'address',
    baseName: 'address',
    displayBigger: true,
  },
  {
    id: 'postal_code',
    name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_POSTAL_CODE'),
    displayName: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_POSTAL_CODE'),
    propertyKey: 'postCode',
    baseName: 'postalCode',
  },
  {
    id: 'city',
    name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CITY'),
    displayName: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CITY'),
    propertyKey: 'city',
    baseName: 'city',
    displayBigger: true,
  },
  {
    id: 'country',
    name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_COUNTRY'),
    displayName: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_COUNTRY'),
    propertyKey: 'country',
    baseName: 'country',
  },
  {
    id: 'associated',
    baseName: 'associatedStores',
    propertyKey: 'associatedStores',
    displayName: i18next.t('GENERAL.LINKED'),
    name: i18next.t('GENERAL.LINKED'),
    excludeFromSearch: true,
    render: (_, item) => renderTooltipOrLabelAssociation(_, item),
  },
];

export default {
  getColumnsSettings,
};
