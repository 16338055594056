import styled from 'styled-components';

export const Box = styled.div`
  width: 60px;
  height: 60px;

  color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};
  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts?.kpiBig};`
      : `font-size: 18px;
  font-weight: 600;
  line-height: 18px;`}

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  background: inherit;
  border-radius: 3px;

  &.border-red {
    border: 1px solid #ff5454;
  }

  &.border-green {
    border: 1px solid greenyellow;
  }

  &.background-red {
    background: ${(props) => props.theme.colors?.boxBackgrounds.red || '#ff5454'};
    color: ${(props) => props.theme.colors?.greys.darkest || '##ffffff'};
  }

  &.background-green {
    background: ${(props) => props.theme.colors?.boxBackgrounds.green || 'greenyellow5'};
    color: ${(props) => props.theme.colors?.greys.darkest || '##ffffff'};
  }

  &.background-orange {
    background: ${(props) => props.theme.colors?.infoLightOrange || 'greenyellow5'};
    color: ${(props) => props.theme.colors?.greys.darkest || '##ffffff'};
  }

  &.disabled {
    border: 1px solid #a8b1c4;
    color: #a8b1c4;
  }
`;
