import { Paper, TableContainer } from '@mui/material';
import i18next from 'i18next';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// Commons
import { Button } from '@commons/utils/styledLibraryComponents';
import { getTheme } from '@commons/utils/theme';
import EmptyState from '@commons/EmptyState';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

// Design
import { Container, FileSizeContainer, StatusContainer, TextContainer } from './styledComponents';
import { InpulseLabel } from '@commons/DeepsightComponents';

import { fileStatus, fileStatusCodes } from '../../utils/fileStatus';

export const InvoiceControlFilesList = (props) => {
  const { selectedFiles, removeFile, isImporting } = props;

  const theme = getTheme();

  return (
    <Container>
      {!selectedFiles.length ? (
        <EmptyState
          backgroundColor={theme.colors.white}
          customStyle={{ borderRadius: 'inherit' }}
          label={i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.INVOICE_CONTROL_LIST_EMPTY_STATE')}
        />
      ) : (
        <TableContainer component={Paper} style={{ maxHeight: '100%', borderRadius: 'inherit' }}>
          <Table aria-label="sticky table" stickyHeader>
            <TableHead sx={{ height: 40 }}>
              <TableRow>
                <TableCell
                  sx={{
                    borderRight: `1px solid ${theme.colors.greys.lighty}`,
                    padding: '0 16px 0 16px',
                  }}
                >
                  <Text color={ENUM_COLORS.DGREY} font={ENUM_FONTS.TEXT_SMALL}>
                    {i18next.t('FEATURE.INVOICE.INVOICES')} ({selectedFiles.length})
                  </Text>
                </TableCell>
                <TableCell sx={{ padding: '0 16px 0 16px' }}>
                  <Text color={ENUM_COLORS.DGREY} font={ENUM_FONTS.TEXT_SMALL}>
                    {i18next.t('GENERAL.STATUS')}
                  </Text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedFiles.map((file) => {
                const currentFileStatus = fileStatus[file.status];

                const canDelete =
                  !isImporting &&
                  [fileStatusCodes.PENDING, fileStatusCodes.ERROR].includes(file.status);

                return (
                  <TableRow key={file.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ borderRight: `1px solid ${theme.colors.greys.lighty}` }}
                    >
                      <TextContainer>
                        <Text font={ENUM_FONTS.TEXT_SMALL}>{file.fileName}</Text>
                        <FileSizeContainer>
                          <Text color={ENUM_COLORS.DGREY} font={ENUM_FONTS.TEXT_SMALL}>
                            ({file.fileSize})
                          </Text>
                        </FileSizeContainer>
                      </TextContainer>
                    </TableCell>
                    <TableCell sx={{ width: 320 }}>
                      <StatusContainer>
                        <InpulseLabel
                          color={currentFileStatus.color}
                          text={i18next.t(currentFileStatus.name)}
                        />
                        {canDelete && (
                          <Button
                            color={'inpulse-outline'}
                            customStyle={{ height: '24px' }}
                            handleClick={() => removeFile(file.id)}
                            icon={'/images/inpulse/close-black-small.svg'}
                            noBorder
                          />
                        )}
                      </StatusContainer>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};
