import { connect } from 'react-redux';
import i18next from 'i18next';
import React from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openSmallModal } from '@actions/modal';
import { showErrorMessage } from '@actions/messageconfirmation';

import { Button } from '@commons/utils/styledLibraryComponents';
import { translateUnit } from '@commons/utils/translateUnit';

import { getClientInfo } from '@selectors/client';

import recipeService from '@services/recipe';

import { downloadFileFromBase64 } from '@admin/products/recipes/utils/downloadRecipe';

export const generatePDFBlob = async (props) => {
  const {
    recipe: { id, name, img, recipePreparationSteps, category, value, unit },
    client: { clientId },
    compositionSettings,
    showErrorMessage,
    pageLoading,
    pageLoaded,
  } = props;

  pageLoading();

  try {
    const formattedCompositions = compositionSettings.map((composition) => ({
      ...composition,
      name: composition.name || composition.entity.name,
      quantity: composition.quantity || composition.entity.quantity,
      category: composition.category || composition.entity.category,
      unit: composition.unit || composition.entity.unit,
    }));

    const { fileName, rawBase64File } = await recipeService.downloadRecipeDetails(
      clientId,
      id,
      name,
      img,
      recipePreparationSteps,
      category,
      value,
      translateUnit(unit),
      formattedCompositions,
    );

    downloadFileFromBase64(rawBase64File, fileName);
  } catch (error) {
    showErrorMessage(i18next.t('ADMIN.RECIPES.PDF_GENERATION_ERROR'), 'error');
  } finally {
    pageLoaded();
  }
};

function ExportRecipeWithCompositionAndPreparation(props) {
  const { compositionSettings, recipe } = props;

  const isDisabled =
    !compositionSettings.length ||
    !recipe.name ||
    !recipe.value ||
    !recipe.category ||
    !recipe.unit;

  const actions = [
    {
      isDisabled,
      label: i18next.t('ADMIN.RECIPES.DOWNLOAD'),
      icon: isDisabled
        ? `/images/inpulse/file-download-lmgrey-small.svg`
        : `/images/inpulse/file-download-black-small.svg`,
      handleClick: () => {
        generatePDFBlob(props);
      },
    },
  ];

  return (
    <Button
      actions={actions}
      color={'inpulse-outline'}
      iconDropdown={'/images/inpulse/carret-black.svg'}
      iconOnLeft={false}
      label={i18next.t('ADMIN.RECIPES.ITEM_LIST_BUTTONS_ACTIONS')}
    />
  );
}

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (params) => {
    dispatch(openSmallModal(params));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExportRecipeWithCompositionAndPreparation);
