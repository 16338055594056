import styled, { css } from 'styled-components';

export const IngredientAllergenDropdown = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  > label {
    display: flex;
    color: ${(props) => props.theme.colors?.greys.darkest};
    font: ${(props) => props.theme.fonts?.textSmall};
    margin-bottom: 8px !important;
  }
  .location-filter-list-result {
    width: auto;

    margin-top: 5px;
  }

  .location-filter-input-no-data {
    height: 38px;
  }

  .location-filter-input-text {
    max-width: fit-content !important;
  }

  .location-filter-input {
    height: 38px;
  }

  .location-filter-input > div {
    width: calc(100% - 30px);
  }
`;

export const Text = styled.div`
  font: ${(props) => props.theme.fonts.textSmall};
  color: ${(props) => props.theme.colors.greys.darkest};

  ${(props) => {
    if (props.capitalize) {
      return css`
        text-transform: capitalize;
      `;
    }

    return css``;
  }}
`;

export const InlineContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  margin-top: 24px;

  > ${Text} {
    margin-left: 8px;
  }
`;
