import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import Text, { ENUM_COLORS, ENUM_FONTS } from '../Text';

import {
  TextContainer,
  EmptyStateIcon,
  EmptyStateContainer,
  ActionContainer,
} from './styledComponents';

const EmptyState = (props) => {
  const {
    label,
    icon,
    iconWidth = '200px',
    iconHeight = 'auto',
    subtitle,
    labelFont,
    labelColor,
    subtitleMargin,
    renderActionButton,
    backgroundColor,
    customStyle,
  } = props;

  return (
    <EmptyStateContainer backgroundColor={backgroundColor} style={customStyle}>
      <div>
        <EmptyStateIcon
          alt="empty-state-ingredient-entity-association-icon"
          className={'center'}
          height={iconHeight}
          src={icon || '/images/inpulse-icon-no-data.svg'}
          width={iconWidth}
        />
        <TextContainer>
          <Text color={labelColor} font={labelFont} style={{ 'margin-top': '20px' }}>
            {label}
          </Text>
          <Text
            color={ENUM_COLORS.DARKER}
            font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}
            style={{ margin: subtitleMargin ? subtitleMargin : '0px' }}
          >
            {subtitle}
          </Text>
        </TextContainer>
        {renderActionButton && <ActionContainer>{renderActionButton()}</ActionContainer>}
      </div>
    </EmptyStateContainer>
  );
};

EmptyState.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  subtitle: PropTypes.string,
  labelFont: PropTypes.string,
  labelColor: PropTypes.string,
  subtitleMargin: PropTypes.string,
  renderActionButton: PropTypes.bool,
  backgroundColor: PropTypes.string,
  customStyle: PropTypes.objectOf(PropTypes.any),
};

EmptyState.defaultProps = {
  label: i18next.t('GENERAL.NO_RESULT'),
  icon: '/images/inpulse-icon-no-data.svg',
  subtitle: null,
  labelFont: ENUM_FONTS.TEXT_SMALL_HEIGHT_16,
  labelColor: ENUM_COLORS.DARKER,
  subtitleMargin: null,
  renderActionButton: null,
  backgroundColor: null,
  customStyle: {},
};

export default EmptyState;
