import _ from 'lodash';
import React, { useState, useEffect } from 'react';

import {
  ContainerListComposition,
  ListHeader,
  ListHeaderSection,
  ListRow,
  ListRowSection,
} from '@admin/products/products/detail/components/ProductRecipeAssociation/RecipeComposition/components/CompositionList/styledComponents';

import Toggle from '../Toggle';

import { ContainerContent } from './styledComponents';

const UsedByListing = ({ products }) => (
  <ContainerListComposition>
    <ListHeader>
      <ListHeaderSection className={'name'}>Produit</ListHeaderSection>
      <ListHeaderSection>Mesure</ListHeaderSection>
    </ListHeader>
    {products.map(({ productId, productName, recipeQuantity, ingredientUnit }) => (
      <ListRow key={productId}>
        <ListRowSection className={'name'}>{productName}</ListRowSection>
        <ListRowSection style={{ justifyContent: 'flex-start' }}>
          {_.round(recipeQuantity, 2)} {ingredientUnit}
        </ListRowSection>
      </ListRow>
    ))}
  </ContainerListComposition>
);

export default ({ ingredientId, recipeByIngredientId }) => {
  const [selectedChannelName, setSelectedChannelName] = useState(null);
  const [availableChannels, setAvailableChannels] = useState([]);

  const [composition, setComposition] = useState([]);

  useEffect(() => {
    const recipesChannels = recipeByIngredientId[ingredientId].reduce((acc, { channelName }) => {
      if (!acc.includes(channelName)) {
        acc.push(channelName);
      }

      return acc;
    }, []);

    setAvailableChannels(recipesChannels);
  }, [ingredientId, recipeByIngredientId]);

  useEffect(() => {
    if (
      !ingredientId ||
      !recipeByIngredientId ||
      !recipeByIngredientId[ingredientId] ||
      !selectedChannelName
    ) {
      setComposition([]);

      return;
    }

    const filteredRecipes = recipeByIngredientId[ingredientId].filter(
      ({ channelName }) => channelName === selectedChannelName,
    );

    setComposition(filteredRecipes);
  }, [ingredientId, recipeByIngredientId, selectedChannelName]);

  if (!ingredientId || !recipeByIngredientId || !recipeByIngredientId[ingredientId]) {
    return;
  }

  return (
    <ContainerContent>
      <Toggle
        channels={availableChannels}
        selectedChannelName={selectedChannelName}
        setSelectedChannelName={setSelectedChannelName}
      />
      <UsedByListing products={composition} />
    </ContainerContent>
  );
};
