import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 816px;
  padding-top: 24px; // Because of the NavigationBar + wished top padding

  margin: auto;
`;
