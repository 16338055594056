export const NB_EMPTY_ROWS_LOADING_STATE = 9;

export const ORDER_TYPE: { ASCENDING: 'asc'; DESCENDING: 'desc' } = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

export const DEFAULT_PROPS = {
  maxPerPage: 20,
  currentPage: 1,
};
