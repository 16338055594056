import { connect } from 'react-redux';
import { flatten, get, keyBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, Dropdown, ListView, ToggleButton } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS, DATE_PICKER_DOT_COLOR } from '@commons/DatePickers/constants';
import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { formatNumber } from '@commons/DisplayNumber';
import { LISTVIEW_NO_TOP_PADDING } from '@commons/constants/listViewProps';
import { PastDayDatePicker } from '@commons/DatePickers/PastDayDatePicker';
import DisplayImgInFullScreen from '@commons/DisplayImgInFullScreen';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { getAuthorizedActions } from '@selectors/featureProps';
import { getCentralKitchenStores } from '@selectors/stores';
import { getClientInfo } from '@selectors/client';

import { lossService } from '@services/loss';

import {
  DELETE_LOSSES_FAILURE,
  DELETE_LOSSES_SUCCESS,
  EMPTY_STATE_ICON,
  EXPORT_FAILURE,
  RETRIEVE_DATES_FAILURE,
  RETRIEVE_LOSSES_FAILURE,
} from '@losses/LossesLosses/components/LossListView/constants';
import {
  formatPayloadIntoLossData,
  getDatesWithLossesForMonth,
  handleLossesExport,
} from '@losses/LossesLosses/utils/commons';
import {
  getActionsByType,
  getRowActions,
} from '@losses/LossesLosses/components/LossListView/getActions';
import { getColumnsByType } from '@losses/LossesLosses/components/LossListView/getColumns';
import {
  TotalLossContainer,
  TotalLossValue,
} from '@losses/LossesLosses/components/LossListView/styledComponents';
import ExportLossesModal from '@losses/LossesLosses/components/LossListView/ExportLossesModal';
import LossForm from '@losses/LossesLosses/components/LossForm';

import { ENTITY_TYPES_CHOICES } from '@stocks/utils/constants';
import EmptyState from '@stocks/StocksInventories/StocksInventoriesTransfer/components/TransferForm/components/EmptyState';

import { Container, HeaderContainer } from './styledComponents';

const CALENDAR_INFO = {
  dotsInformations: [
    {
      dotColor: DATE_PICKER_DOT_COLOR.RED,
      dotInfo: i18next.t('LOSSES.PRODUCTS.NO_EDITABLE'),
    },
    {
      dotColor: DATE_PICKER_DOT_COLOR.GREEN,
      dotInfo: i18next.t('LOSSES.PRODUCTS.EDITABLE'),
    },
  ],
};

const CentralKitchenLosses = (props) => {
  const {
    match: { path },
    client: { clientId, storeName },
    centralKitchens,
    currency,
    openFullscreenModal,
    pageLoaded,
    pageLoading,
    authorizedActions,
    showErrorMessage,
    showSuccessMessage,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [losses, setLosses] = useState([]);
  const [lossCategoriesById, setLossCategoriesById] = useState({});
  const [selectedLosses, setSelectedLosses] = useState([]);
  const [totalLoss, setTotalLoss] = useState(0);

  const [selectedMetric, setSelectedMetric] = useState(ENTITY_TYPES_CHOICES[0]);

  const [selectedCentralKitchenStore, setSelectedCentralKitchenStore] = useState(
    centralKitchens[0],
  );

  const [selectedDate, setSelectedDate] = useState(moment());
  const [currentDateWithTz, setCurrentDateWithTz] = useState(moment());
  const [focusedMonth, setFocusedMonth] = useState(moment());

  const [displayExportModal, setDisplayExportModal] = useState(false);

  // The list of dates on which losses have been reported in the last 48h
  const [recentDatesWithLosses, setRecentDatesWithLosses] = useState([]);

  // The list of dates on which losses have been reported more than 48h ago
  const [oldDatesWithLosses, setOldDatesWithLosses] = useState([]);

  const [disableActions, setDisableActions] = useState(false);

  const [isFormClosing, setIsFormClosing] = useState(false);

  const [displayPicture, setDisplayRecipeOrSPPicture] = useState({ state: false, img: '' });
  const [columns, setColumns] = useState(
    getColumnsByType(selectedMetric.key, setDisplayRecipeOrSPPicture),
  );

  const getPastDatesWithLossesForMonth = async (
    currentFocusedMonth,
    currentSelectedStore,
    showLoadingState = true,
  ) => {
    if (showLoadingState) {
      pageLoading();
    }

    setIsLoading(true);
    try {
      const datesWithLosses = await getDatesWithLossesForMonth(
        currentFocusedMonth,
        currentSelectedStore,
        selectedMetric.key,
      );

      return datesWithLosses;
    } catch {
      showErrorMessage(i18next.t(RETRIEVE_DATES_FAILURE[selectedMetric.key]));
    } finally {
      setIsLoading(false);

      if (showLoadingState) {
        pageLoaded();
      }
    }
  };

  const fetchData = async () => {
    const startDate = moment(selectedDate)
      .startOf('day')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
    const endDate = moment(selectedDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    const allLossCategories = await lossService.getLossCategoriesByClientIdAndLossType(
      clientId,
      selectedMetric.key,
    );

    const allLossCategoriesById = keyBy(allLossCategories, 'id');

    setLossCategoriesById(allLossCategoriesById);

    const lossesPayload = await lossService.getLossesList(
      selectedMetric.key,
      [selectedCentralKitchenStore.id],
      startDate,
      endDate,
    );

    const formattedLosses = formatPayloadIntoLossData(
      lossesPayload,
      selectedMetric.key,
      centralKitchens,
      allLossCategoriesById,
      {},
    );

    setLosses(formattedLosses);

    const lossesOldestCreatedAt = formattedLosses.map(({ oldestCreatedAt }) => oldestCreatedAt);

    const disabledDate = lossesOldestCreatedAt.some((date) =>
      moment(date).add(1, 'days').isBefore(moment(), 'day'),
    );

    setDisableActions(disabledDate);

    const computedTotalLoss = formattedLosses.reduce(
      (acc, { total }) => acc + parseFloat(formatNumber(total, currency.numberDecimals)),
      0,
    );

    setTotalLoss(computedTotalLoss);

    setSelectedLosses([]);
  };

  const createLoss = () => {
    const params = {
      component: LossForm,
      initialStore: selectedCentralKitchenStore,
      initialDate: selectedDate,
      currentDateWithTz,
      lossType: selectedMetric.key,
      initialOldDatesWithLosses: oldDatesWithLosses,
      initialRecentDatesWithLosses: recentDatesWithLosses,
      getDatesWithLossesForMonth: getPastDatesWithLossesForMonth,
      handleCloseCleanUp: async (formSelectedStore, formSelectedDate) => {
        setIsFormClosing(true);
        setSelectedDate(moment(formSelectedDate));
        setFocusedMonth(moment(formSelectedDate));
        setSelectedCentralKitchenStore({ ...formSelectedStore });
      },
    };

    openFullscreenModal(params);
  };

  const handleExport = async (stores, dateRange) => {
    pageLoading();

    try {
      handleLossesExport(
        stores,
        dateRange,
        selectedMetric.key,
        lossCategoriesById,
        currency,
        storeName,
        {},
      );
    } catch {
      showErrorMessage(i18next.t(EXPORT_FAILURE[selectedMetric.key]));
    } finally {
      pageLoaded();
    }
  };

  const exportLosses = () => {
    setDisplayExportModal(true);
  };

  const deleteLosses = async (items) => {
    pageLoading();
    try {
      const aggregatedLossIds = items.map((item) => item.lossIds);
      const lossIds = flatten(aggregatedLossIds);

      await lossService.deleteLosses(lossIds);

      fetchData();
      showSuccessMessage(i18next.t(DELETE_LOSSES_SUCCESS[selectedMetric.key]));
    } catch (err) {
      showErrorMessage(i18next.t(DELETE_LOSSES_FAILURE[selectedMetric.key]));
    } finally {
      pageLoaded();
    }
  };

  const actions = getActionsByType({
    lossType: selectedMetric.key,
    allItems: losses,
    selectedItems: selectedLosses,
    createLoss,
    exportLosses,
    deleteLosses,
    disableActions,
    authorizedActions,
    isCentralKitchen: true,
  });

  const rowActions = getRowActions({
    lossType: selectedMetric.key,
    deleteLosses,
    disableActions,
    authorizedActions,
    isCentralKitchen: true,
  });

  useEffect(() => {
    if (isFormClosing) {
      setIsFormClosing(false);
      return;
    }

    setSelectedDate(moment.tz(selectedDate, selectedCentralKitchenStore.timezone));
    setCurrentDateWithTz(moment.tz(selectedCentralKitchenStore.timezone));
  }, [selectedCentralKitchenStore]);

  useEffect(() => {
    setColumns(getColumnsByType(selectedMetric.key, setDisplayRecipeOrSPPicture));
  }, [selectedMetric]);

  useEffect(() => {
    setIsLoading(true);
    setLosses([]);

    (async () => {
      try {
        await fetchData();
      } catch (err) {
        showErrorMessage(
          i18next.t(RETRIEVE_LOSSES_FAILURE[selectedMetric.key], {
            storeName: selectedCentralKitchenStore.name,
            date: selectedDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
          }),
        );
      } finally {
        setIsLoading(false);
      }
    })();
  }, [selectedMetric, selectedDate, selectedCentralKitchenStore]);

  // Fetch dates with losses whenever changing month in the DatePicker
  useEffect(() => {
    if (!!selectedCentralKitchenStore) {
      (async () => {
        const dates = await getPastDatesWithLossesForMonth(
          focusedMonth,
          selectedCentralKitchenStore,
        );

        setOldDatesWithLosses(get(dates, 'oldDatesWithLosses', []));
        setRecentDatesWithLosses(get(dates, 'recentDatesWithLosses', []));
      })();
    }
  }, [focusedMonth, selectedCentralKitchenStore, selectedDate, selectedMetric]);

  return (
    <>
      <NavigationBreadCrumb featurePath={path} />

      {!!displayPicture.state && (
        <DisplayImgInFullScreen
          image={displayPicture.img}
          setDisplayProductPicture={setDisplayRecipeOrSPPicture}
        />
      )}
      <Container>
        <ListView
          actions={!isLoading && actions}
          columns={columns}
          data={losses}
          isLoading={isLoading}
          padding={LISTVIEW_NO_TOP_PADDING}
          renderEmptyState={() => (
            <EmptyState
              button={
                <Button
                  color={'inpulse-default'}
                  handleClick={createLoss}
                  icon={'/images/inpulse/add-white-small.svg'}
                  label={i18next.t('GENERAL.ADD')}
                />
              }
              icon={EMPTY_STATE_ICON[selectedMetric.key]}
              subtitle={i18next.t('LOSSES.PRODUCTS.NO_LOSSES_SUBTITLE', {
                date: selectedDate.format(DATE_DISPLAY_FORMATS.DATE_MONTH),
                storeName: !!selectedCentralKitchenStore && selectedCentralKitchenStore.name,
              })}
              title={i18next.t('LOSSES.PRODUCTS.NO_LOSSES_TITLE')}
            />
          )}
          renderFilterButton={() => (
            <HeaderContainer>
              <ToggleButton
                choices={ENTITY_TYPES_CHOICES}
                isDisabled={isLoading}
                selectedChoice={selectedMetric}
                setSelectedChoice={setSelectedMetric}
              />
              <Dropdown
                iconSrc={'/images/inpulse/store-black-small.svg'}
                isDisabled={isLoading}
                items={centralKitchens}
                selectedItem={selectedCentralKitchenStore}
                isRequired
                isUniqueSelection
                onSelectionChange={setSelectedCentralKitchenStore}
              />
              <PastDayDatePicker
                authorizedDates={recentDatesWithLosses}
                calendarInfo={CALENDAR_INFO}
                date={selectedDate}
                disabled={isLoading}
                forbiddenDates={oldDatesWithLosses}
                maxFutureDate={currentDateWithTz}
                timezone={
                  !!selectedCentralKitchenStore
                    ? selectedCentralKitchenStore.timezone
                    : 'Europe/Paris'
                }
                clickableForbiddenDates
                specialLoss
                onDateChange={setSelectedDate}
                onNextMonthClick={(nextMonth) => setFocusedMonth(nextMonth)}
                onPrevMonthClick={(prevMonth) => setFocusedMonth(prevMonth)}
              />

              <TotalLossContainer>
                {i18next.t('GENERAL.TOTAL_EXCL_TAXES_WITH_CURRENCY', {
                  currencyCode: currency.alphabeticCode,
                })}
                <TotalLossValue>
                  {isLoading ? (
                    <DeepsightComponentLoader height={16} width={16} />
                  ) : (
                    formatNumber(totalLoss, currency.numberDecimals)
                  )}
                </TotalLossValue>
              </TotalLossContainer>
            </HeaderContainer>
          )}
          rowActions={rowActions}
          setSelectedItems={setSelectedLosses}
          hideSearchbar
        />
        {displayExportModal && (
          <ExportLossesModal
            handleExport={handleExport}
            lossType={selectedMetric.key}
            resetExportingLosses={() => setDisplayExportModal(false)}
          />
        )}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  centralKitchens: getCentralKitchenStores(state.baseReducer.activeStores),
  currency: state.baseReducer.currency,
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/central-kitchen-stocks/losses',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  openFullscreenModal: (params) => {
    dispatch(openModal(params, true));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CentralKitchenLosses);
