import styled from 'styled-components';

export const Container = styled.div`
  // This css property is for reset inherit CSS for component
  // and trying to have same style behavior in App and storyBook
  // See more here => https://developer.mozilla.org/fr/docs/Web/CSS/all
  all: initial;

  display: flex;

  flex-direction: column;

  max-height: 416px;

  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.greys.light};
  background-color: ${(props) => props.theme.colors.greys.lightest};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 16px 16px 0 16px;

  margin-bottom: 24px;
`;

export const Title = styled.div`
  font: ${(props) => props.theme.fonts.h3Inter};
  color: ${(props) => props.theme.colors.greys.darkest};
`;

export const ChartContainer = styled.div`
  padding: 0 16px 16px 16px;

  height: 264px;
`;

export const LegendContainer = styled.div`
  display: flex;
  width: 100%;
  height: 16px;

  align-items: center;
  justify-content: flex-start;

  padding: 0 16px 24px 52px;
`;

export const RenderLegend = styled.div`
  display: flex;

  align-items: center;
  justify-content: flex-start;
`;

export const LegendLine = styled.div`
  width: 16px;
  height: 2px;

  background-color: ${(props) =>
    props.lineColor || props.theme.colors.brand.primary};
`;

export const CanvasChart = styled.canvas`
  width: 100%;
`;

export const LegendLabel = styled.div`
  margin-left: 8px;

  font: ${(props) => props.theme.fonts.textMiddleNormal};
  color: ${(props) => props.theme.colors.greys.dark};
`;
export default {
  Container,
  HeaderContainer,
  ChartContainer,
  LegendContainer,
  RenderLegend,
  LegendLine,
  LegendLabel,
  CanvasChart,
};
