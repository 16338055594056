import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import theme from '@theme';

import { Container, Icon, Tooltip } from './styledComponents';

const INFO_ICON = {
  dmGrey: '/images/inpulse/info-dmgrey-small.svg',
  ipBlack: '/images/inpulse/info-black-small.svg',
  white: '/images/inpulse/info-white-small.svg',
};

function OrderRestrictionTooltip(props) {
  const { tooltipContent, isDisabled } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(INFO_ICON.white);
  const [selectedBorder, setSelectedBorder] = useState(theme.colors.greys.light);

  useEffect(() => {
    if (isDisabled && selectedIcon === INFO_ICON.white) {
      return;
    }

    if (isDisabled) {
      setSelectedIcon(INFO_ICON.white);
      setSelectedBorder(theme.colors.greys.light);
      return;
    }

    if (isOpen) {
      setSelectedIcon(INFO_ICON.ipBlack);
      setSelectedBorder(theme.colors.greys.darkest);
      return;
    }

    setSelectedIcon(INFO_ICON.dmGrey);
    setSelectedBorder(theme.colors.greys.light);
  }, [isOpen, isDisabled]);

  const handleClick = () => {
    if (isDisabled) {
      return;
    }

    setIsOpen(!isOpen);
  };

  OrderRestrictionTooltip.handleClickOutside = () => {
    setIsOpen(false);
  };

  return (
    <Container
      border={selectedBorder}
      isClicked={isOpen}
      isDisabled={isDisabled}
      onClick={handleClick}
    >
      <Icon src={selectedIcon} />
      {isOpen && <Tooltip>{tooltipContent}</Tooltip>}
    </Container>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => OrderRestrictionTooltip.handleClickOutside,
};

OrderRestrictionTooltip.propTypes = {
  isDisabled: PropTypes.bool,
  tooltipContent: PropTypes.string,
};

OrderRestrictionTooltip.defaultProps = {
  isDisabled: false,
  tooltipContent: '',
};

export default onClickOutside(OrderRestrictionTooltip, clickOutsideConfig);
