import { connect } from 'react-redux';
import { get } from 'lodash';

import NavigationBreadCrumb from '../../../commons/Breadcrumb/NavigationBreadCrumb';

import { getCentralKitchenStores } from '../../../selectors/stores';

import Orders from '../OrderList';
import React from 'react';

const CentralKitchenOrderList = (props) => {
  const path = get(props.match, 'path');

  return (
    <>
      <NavigationBreadCrumb featurePath={path} />

      <Orders
        canOrderWithMin={props.canOrderWithMin}
        categoriesOrder={props.categoriesOrder}
        constraints={props.constraints}
        displayStocks={props.displayStocks}
        groupedOrder={props.groupedOrder}
        send={props.send}
        sendOrder={props.sendOrder}
        stores={props.stores}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  stores: getCentralKitchenStores(state.baseReducer.activeStores),
});

export default connect(mapStateToProps)(CentralKitchenOrderList);
