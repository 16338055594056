import _ from 'lodash';
import i18next from 'i18next';

import {
  GENERIC_MODAL_BACK_BUTTON,
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_NEXT_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

import { ConfigureStoresMappingsModal } from './stepsModals/ConfigureStoresMappingModal';
import { MapIngredientModal } from './stepsModals/MapIngredientModal';
import MapStoreModal from './stepsModals/MapStoreModal';

export const ENABLE_SP_MODAL_STEPS = {
  MAP_INGREDIENT: 'mapIngredient',
  MAP_STORES: 'mapStores',
  CONFIGURE_STORES_MAPPINGS: 'configureStoresMappings',
};

const MODAL_STEPS_ORDER = [
  ENABLE_SP_MODAL_STEPS.MAP_INGREDIENT,
  ENABLE_SP_MODAL_STEPS.MAP_STORES,
  ENABLE_SP_MODAL_STEPS.CONFIGURE_STORES_MAPPINGS,
];

const _getStepNumber = (currentStep) => ({
  total: MODAL_STEPS_ORDER.length,
  current: MODAL_STEPS_ORDER.findIndex((step) => step === currentStep) + 1,
});

const enableSPmodalComponents = {
  [ENABLE_SP_MODAL_STEPS.MAP_INGREDIENT]: MapIngredientModal,
  [ENABLE_SP_MODAL_STEPS.MAP_STORES]: MapStoreModal,
  [ENABLE_SP_MODAL_STEPS.CONFIGURE_STORES_MAPPINGS]: ConfigureStoresMappingsModal,
};

/**
 * @returns
 * {
 *   supplierProductId: <uuid>,
 *   entityId: <uuid>,
 *   storeSupplierProductMappings: Object[],
 * }
 */
const formatDataForApiCall = (props) => {
  const { supplierProduct, mapIngredientData, mapStoreData, configureStoresMappingsData } = props;

  const SSPMs = mapStoreData.map((store) => ({
    storeId: store.id,
    supplierProductId: supplierProduct.id,
    price: supplierProduct.price,
    ...configureStoresMappingsData,
  }));

  const formattedData = {
    supplierProduct: supplierProduct,
    entityId: mapIngredientData.updatedSupplierProduct.entityId,
    storeSupplierProductMappings: SSPMs,
  };

  return formattedData;
};

const getEnableSPmodalActions = (props) => {
  const {
    currentStep,
    setCurrentStep,
    resetModal,
    handleCallToEnableSupplierProduct,
    activeUserStores,
  } = props;

  if (currentStep === ENABLE_SP_MODAL_STEPS.MAP_INGREDIENT) {
    if (_.isEmpty(activeUserStores)) {
      // if there are no stores to be mapped, only put the Activate action as the
      return [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_NEXT_BUTTON(),
          label: i18next.t('GENERAL.ACTIVATE'),
          icon: '/images/inpulse/power-white-small.svg',
          handleClick: () => {
            const formattedData = formatDataForApiCall(props);
            handleCallToEnableSupplierProduct(formattedData);
            resetModal();
          },
        },
      ];
    }
    return [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_NEXT_BUTTON(),
        preventClosing: true,
        handleClick: () => {
          setCurrentStep(ENABLE_SP_MODAL_STEPS.MAP_STORES);
        },
      },
    ];
  }

  if (currentStep === ENABLE_SP_MODAL_STEPS.MAP_STORES) {
    return [
      {
        ...GENERIC_MODAL_BACK_BUTTON(),
        preventClosing: true,
        handleClick: () => {
          setCurrentStep(ENABLE_SP_MODAL_STEPS.MAP_INGREDIENT);
        },
      },
      {
        ...GENERIC_MODAL_NEXT_BUTTON(),
        preventClosing: true,
        handleClick: () => {
          setCurrentStep(ENABLE_SP_MODAL_STEPS.CONFIGURE_STORES_MAPPINGS);
        },
      },
    ];
  }

  if (currentStep === ENABLE_SP_MODAL_STEPS.CONFIGURE_STORES_MAPPINGS) {
    return [
      {
        ...GENERIC_MODAL_BACK_BUTTON(),
        preventClosing: true,
        handleClick: () => {
          setCurrentStep(ENABLE_SP_MODAL_STEPS.MAP_STORES);
        },
      },
      {
        ...GENERIC_MODAL_NEXT_BUTTON(),
        label: i18next.t('GENERAL.ACTIVATE'),
        icon: '/images/inpulse/power-white-small.svg',
        handleClick: () => {
          const formattedData = formatDataForApiCall(props);
          handleCallToEnableSupplierProduct(formattedData);
          resetModal();
        },
      },
    ];
  }
};

export const getEnableSPmodalConfig = (props) => {
  const { supplierProduct, currentStep, resetModal, activeUserStores } = props;

  const params = {
    type: 'actionWhite',
    isFullscreen: true,
    icon: '/images/inpulse/power-ip-black.svg',
    title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTIVATE_SUPPLIER_PRODUCT_MODAL_TITLE', {
      name: supplierProduct.name,
    }),
    handleCloseCleanUp: resetModal,
    actions: getEnableSPmodalActions(props),
    component: enableSPmodalComponents[currentStep],
    data: props,
  };

  if (!_.isEmpty(activeUserStores)) {
    // if there are stores to be mapped, indicate the step number in the modal
    params.step = _getStepNumber(currentStep);
  }

  return params;
};
