import React from 'react';

import {
  DropdownItem,
  DropdownItemImage,
  DropdownItemMainValue,
  DropdownItemSecondaryValue,
  DropdownItemValuesContainer,
} from './styledComponents';

const ItemsWithPicture = ({ imgSrc, title, subtitle }) => (
  <DropdownItem>
    <DropdownItemImage src={imgSrc}></DropdownItemImage>
    <DropdownItemValuesContainer>
      <DropdownItemMainValue>{title}</DropdownItemMainValue>
      <DropdownItemSecondaryValue>{subtitle}</DropdownItemSecondaryValue>
    </DropdownItemValuesContainer>
  </DropdownItem>
);

export default ItemsWithPicture;
