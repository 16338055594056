import styled from 'styled-components';

export const Text = styled.div`
  font: ${(props) => props.theme.fonts.textBig};
  color: ${(props) => props.theme.colors.greys.darkest};
`;

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 40px);
  position: relative;

  > div {
    overflow: auto;
  }
`;

export default {
  Container,
  Text,
};
