import { flattenDeep, isEmpty, keyBy } from 'lodash';

import { ADMIN } from './salesPointNavigation/admin';
import { ANALYTICS } from './salesPointNavigation/analytics';
import { BACKOFFICE } from './salesPointNavigation/backoffice';
import { CENTRAL_KITCHEN_ADMIN } from './centralKitchenNavigation/admin';
import { CENTRAL_KITCHEN_ANALYTICS } from './centralKitchenNavigation/analytics';
import { CENTRAL_KITCHEN_OPERATIONS } from './centralKitchenNavigation/operations';
import { DASHBOARD } from './salesPointNavigation/dashboard';
import { FORECAST } from './salesPointNavigation/forecast';
import { HELP_CENTER } from './salesPointNavigation/help-center';
import { INVOICE } from './salesPointNavigation/invoice';
import { OPERATIONS } from './salesPointNavigation/operations';
import { REFERRAL } from './salesPointNavigation/referral';

import { REGEX_UUID } from '../utils/regex';

export const ENUM_MODULES = {
  FORECAST,
  OPERATIONS,
  ANALYTICS,
  INVOICE,
  ADMIN,
  HELP_CENTER,
  REFERRAL,
  BACKOFFICE,
  DASHBOARD,
};

export const ORDERED_NAV_TREE = [
  ENUM_MODULES.DASHBOARD,
  ENUM_MODULES.FORECAST,
  ENUM_MODULES.OPERATIONS,
  ENUM_MODULES.ANALYTICS,
  ENUM_MODULES.INVOICE,
  ENUM_MODULES.ADMIN,
  ENUM_MODULES.HELP_CENTER,
  ENUM_MODULES.REFERRAL,
  ENUM_MODULES.BACKOFFICE,
];

export const ENUM_CENTRAL_KITCHEN_MODULES = {
  CENTRAL_KITCHEN_OPERATIONS,
  CENTRAL_KITCHEN_ANALYTICS,
  CENTRAL_KITCHEN_ADMIN,
  INVOICE,
  HELP_CENTER,
  REFERRAL,
  BACKOFFICE,
};

export const ORDERED_CENTRAL_KITCHEN_NAV_TREE = [
  ENUM_CENTRAL_KITCHEN_MODULES.CENTRAL_KITCHEN_OPERATIONS,
  ENUM_CENTRAL_KITCHEN_MODULES.CENTRAL_KITCHEN_ANALYTICS,
  ENUM_CENTRAL_KITCHEN_MODULES.CENTRAL_KITCHEN_ADMIN,
  ENUM_CENTRAL_KITCHEN_MODULES.INVOICE,
  ENUM_CENTRAL_KITCHEN_MODULES.HELP_CENTER,
  ENUM_CENTRAL_KITCHEN_MODULES.REFERRAL,
  ENUM_CENTRAL_KITCHEN_MODULES.BACKOFFICE,
];

/**
 * Recursive function to browse the navigation tree and get all features and subFeatures while gathering
 * moduleInfo and subModuleInfo while browsing
 *
 * @param {object} feature
 * @param {string} storeName
 * @param {object} moduleInfo
 * @param {object} subModuleInfo
 * @param {String | null} parentSubFeaturesPath
 *
 * @returns {[]} The list of all features and subModules with moduleInfo and subModuleInfo
 */
const _getNavTree = (
  feature,
  storeName,
  moduleInfo = {},
  subModuleInfo = {},
  parentSubFeaturesPath = null,
) => {
  if (!feature.children) {
    // Some features have subFeatures, only displayed in the NavBar
    if (!feature.subFeatures) {
      return {
        ...feature,
        moduleInfo,
        subModuleInfo: {
          ...subModuleInfo,
          isVisible: !!feature.isVisible ? (props) => feature.isVisible(props) : () => true,
        },
        parentSubFeaturesPath,
      };
    }

    return [
      {
        ...feature,
        moduleInfo,
        subModuleInfo: {
          ...subModuleInfo,
          isVisible: !!feature.isVisible ? (props) => feature.isVisible(props) : () => true,
        },
      },
      feature.subFeatures.map((subFeature) =>
        _getNavTree(subFeature, storeName, moduleInfo, subModuleInfo, feature.path),
      ),
    ];
  }

  // If moduleInfo is empty, the current feature is a module, so we generate moduleInfo
  if (isEmpty(moduleInfo)) {
    return feature.children.map((child) =>
      _getNavTree(
        child,
        storeName,
        {
          name: feature.getText({ storeName }),
          breadCrumbName: !!feature.getBreadCrumbText
            ? feature.getBreadCrumbText({ storeName })
            : feature.getText({ storeName }),
        },
        subModuleInfo,
      ),
    );
  }

  // If subModuleInfo is empty, the current feature is a subModule, so we generate subModuleInfo
  if (isEmpty(subModuleInfo)) {
    return feature.children.map((child) =>
      _getNavTree(child, storeName, moduleInfo, {
        name: feature.getText({ storeName }),
        breadCrumbName: !!feature.getBreadCrumbText
          ? feature.getBreadCrumbText({ storeName })
          : feature.getText({ storeName }),
        // If the subModule feature does not have isVisible prop, it is visible by default
        isVisible: !!feature.isVisible ? (props) => feature.isVisible(props) : () => true,
        hideSubmoduleName: feature.hideSubmoduleName,
      }),
    );
  }

  return feature.children.map((child) => _getNavTree(child, storeName, moduleInfo, subModuleInfo));
};

export const getNavTreeKeyByPath = ({ navTree, storeName }) => {
  const flattenedNavTree = flattenDeep(navTree.map((module) => _getNavTree(module, storeName)));

  const keyByPathNavTree = keyBy(flattenedNavTree, 'path');

  return keyByPathNavTree;
};

export const getFirstFeature = (features, navTree, storeName) => {
  const flattenedNavTree = flattenDeep(navTree.map((module) => _getNavTree(module, storeName)));

  const featuresByPath = keyBy(features, 'path');

  const firstFeature = flattenedNavTree.find(({ path }) => !!featuresByPath[path]);

  return firstFeature || flattenedNavTree[0];
};

/**
 * Get a feature that has subFeatures given a path
 *
 * @param {object} keyByPathNavTree - Navigation tree keyed by path
 * @param {string} path - Current user location
 * @returns {object | null}
 */
export const getSubFeaturesForPath = (keyByPathNavTree, path) => {
  const feature = keyByPathNavTree[path];

  if (!feature || !keyByPathNavTree[feature.parentSubFeaturesPath]) {
    return null;
  }

  return keyByPathNavTree[feature.parentSubFeaturesPath];
};

export const getEntityLabelNavbar = (entity, defaultLabel) => {
  // NavBar title when editing an entity
  if (entity && entity.id) {
    return `/ ${entity.name}`;
  }

  // NavBar title when creating an entity
  if (entity && entity.name) {
    return entity.name;
  }

  return defaultLabel;
};

const _isValidUUID = (segment) => REGEX_UUID.test(segment);

export const getCurrentFeature = (featuresKeyByPath, location) => {
  const pathSegments = location.pathname.split('/');

  // Filter out the UUID segment
  const filteredSegments = pathSegments.reduce((acc, segment) => {
    if (!_isValidUUID(segment)) {
      acc.push(segment);
      return acc;
    }

    acc.push(':id');
    return acc;
  }, []);

  // Join the filtered segments back into a path
  const pathWithoutUUID = `${filteredSegments.join('/')}`;

  return featuresKeyByPath[pathWithoutUUID];
};
