import PropTypes from 'prop-types';
import React from 'react';

import { ADMIN_FEATURE_PATHS } from '../../../commons/utils/featurePaths';
import { ENUM_MODULE_NAME } from '../../../commons/utils/features';

import { ModuleContainer } from '../../ModuleContainer';

export const AdminSuppliersContainer = (props) => {
  const { userRights, history } = props;
  return (
    <ModuleContainer
      history={history}
      moduleFeaturePaths={[
        ADMIN_FEATURE_PATHS.ADMIN_SUPPLIERS_SUPPLIERS,
        ADMIN_FEATURE_PATHS.ADMIN_SUPPLIERS_SUPPLIER_PRODUCTS,
      ]}
      moduleName={ENUM_MODULE_NAME.ADMIN}
      userRights={userRights}
    />
  );
};

AdminSuppliersContainer.propTypes = {
  userRights: PropTypes.array,
  history: PropTypes.any, // react-router-dom's route prop
  moduleName: PropTypes.oneOf(Object.values(ENUM_MODULE_NAME)),
  moduleFeaturePaths: PropTypes.arrayOf(PropTypes.string),
};

// ===== Module features for easy import in ClientContainer =====

import SupplierProductCreation from './supplierProducts/creation';
import SupplierProductDetails from './supplierProducts/details/supplierProductInformations/components/SupplierProductDetails';
import SupplierProductInformations from './supplierProducts/details/supplierProductInformations';
import SupplierProductMappings from './supplierProducts/details/supplierProductStores/components/SupplierProductMappings';
import SupplierProducts from './supplierProducts';
import SupplierProductStores from './supplierProducts/details/supplierProductStores';

import SupplierDetails from './suppliers/SupplierDetails';
import Suppliers from './suppliers';

const AdminSuppliersModule = {
  AdminSuppliersContainer,
  SupplierProducts,
  Suppliers,
  SupplierDetails,
  SupplierProductDetails,
  SupplierProductInformations,
  SupplierProductMappings,
  SupplierProductStores,
  SupplierProductCreation,
};

export default AdminSuppliersModule;
