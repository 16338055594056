import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  width: ${(props) => props.width};
  height: 40px;
  padding: 0 16px !important;
  border-radius: 8px;

  background: ${(props) => props.theme.franco?.colors.white || '#FFFFFF'};
  border: ${(props) => props.hasBorder && props.theme.border};

  justify-content: flex-start;

  ${(props) =>
    props.labelContent !== '' &&
    props.content !== '' &&
    props.title !== '' &&
    css`
      justify-content: space-between;
    `}
`;

export const FullSizeContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;

  font: ${(props) =>
    props.theme.franco?.text.font || 'normal 500 12px/16px inter'};
  color: ${(props) => props.theme.franco?.colors.darker || '#606060'};
`;

export const LeftPartContainer = styled.div`
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

export const Title = styled.div`
  font: ${(props) =>
    props.theme.franco?.text.font || 'normal 500 12px/16px inter'};
  color: ${(props) => props.theme.franco?.colors.darker || '#606060'};
  display: flex;
`;

export const TooltipContainer = styled.div`
  position: relative;
  bottom: 2px;
  right: 2px;
`;

export const Content = styled.div`
  font: ${(props) =>
    props.theme.franco?.text.contentFont || 'normal 700 14px/16px inter'};
  color: ${(props) =>
    props.color ? props.color : props.franco?.colors?.darker};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  column-gap: 16px;
`;

export default {
  Content,
  Title,
  LeftPartContainer,
  FullSizeContentContainer,
  Container,
  TooltipContainer,
};
