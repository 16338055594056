import PropTypes from 'prop-types';
import React from 'react';

import { Container, Label, Chevron, ContainerAction, LabelContainer } from './styledComponents';

const Breadcrumb = (props) => {
  const { paths, action } = props;

  return (
    <Container>
      {paths.map((path, index) => {
        const isLast = index === paths.length - 1;

        return (
          <LabelContainer key={index}>
            <Label isLast={isLast}>{path}</Label>
            {!isLast && <Chevron src={'/images/inpulse/chevron-right-dmgrey-small.svg'} />}
          </LabelContainer>
        );
      })}
      {action && <ContainerAction>{action}</ContainerAction>}
    </Container>
  );
};

Breadcrumb.propTypes = {
  paths: PropTypes.array.isRequired,
};

export default Breadcrumb;
