const KEY = 'importType';

const VALUES = {
  ENTITIES: 'entities',
  SUPPLIER_PRODUCTS: 'supplierProducts',
  PRODUCTS: 'products',
  CENTRAL_KITCHEN_PRODUCTS: 'centralKitchenProducts',
};

export default {
  KEY,
  VALUES,
};
