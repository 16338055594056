import axios from 'axios';

const appVersionHeader = 'x-app-version';

export const instance = axios.create({
  headers: {
    'content-type': 'application/json',
    [appVersionHeader]: process.env.REACT_APP_GIT_SHA,
  },
});

export default instance;
