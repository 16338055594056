import { connect } from 'react-redux';
import { get, head, intersection, isEmpty, keyBy, omit, sortBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  closeGenericModal,
  openGenericModal,
  openMediumModal,
  openSmallModal,
  refreshGenericModal,
} from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { getTheme } from '@commons/utils/theme';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import { SupplierProductsContext } from '@context/SupplierProductsContext';
import GeneralEmptyStateListView from '@commons/GeneralEmptyStateListView';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { getListViewPersistentFilters, PersistentFilterTypes } from '@context/utils';

import { ENUM_QUERY_PARAMS, useListViewQueryParams } from '@hooks/useListViewQueryParams';

import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { brand as brandService } from '@services/brand';
import { supplierProduct as supplierProductService } from '@services/supplierProduct';
import { supplier as supplierService } from '@services/supplier';
import catalogService from '@services/catalogs';
import centralService from '@services/central';
import clientService from '@services/client';
import storageAreaService from '@services/storageArea';

import { downloadFile, formatXlsFileToJson } from '@backoffice/utils';

import { CHOICES_DROPDOWN_ACTIVE } from '@admin/utils/DropdownItems';
import {
  getAssignCategorySubCategoryModalParams,
  getCategorySubCategoryModalParams,
  getSupplierProductsDeletionModalParams,
} from '@admin/commons/modalConfigurations';
import DeepsightFiltersButton from '@admin/components/FilterButton';

import { canCreateSupplierProducts } from '@selectors/actions/supplierProductActions';
import { computeAdvancedFiltersList, computeSupplierProduct } from './utils/format';
import { Container } from './styledComponents';
import { exportSupplierProductErrors } from './utils/export';
import { exportSupplierProducts } from './utils/exportSupplierProducts';
import { formatJson, getSupplierProductModalConfig, refreshImportModal } from './utils/import';
import { getNextAvailabilitiesModal } from './utils/getNextAvailabilitiesModalConfig/index';
import { getPersistentFilters } from './utils/persistentFilters';
import { getSspmStepModalConfig } from './utils/getSspmStepModalConfig/index';
import { IMPORT_MODAL_STEPS, IMPORT_TYPES } from './utils/constants';
import { mappingAutoModalConfig } from './utils/mappingAutoModalConfig';
import { priceEditableAtReceptionModalConfig } from './utils/priceEditableAtReceptionModal';
import { SSPM_MODAL_STEPS } from './utils/sspmModalsSteps';
import actionsUtils from './utils/actions';
import columnsUtils from './utils/columns';
import EmptyState from './components/EmptyState';
import EnableSupplierProductModal from './components/EnableSupplierProductModal';
import ImportSpPricesModal from './components/ImportSpPricesModal';
import paginationUtils from './utils/pagination';

export const SupplierProducts = (props) => {
  const {
    user,
    client: { clientId, hasMultipleBrands, storeName, hasLocalCatalogs },
    currency,
    pageLoaded,
    pageLoading,
    showErrorMessage,
    openGenericModal,
    openSmallModal,
    closeGenericModal,
    showSuccessMessage,
    refreshGenericModal,
    history,
    match: { path },
    authorizedActions,
  } = props;

  const contextHandler = useContext(SupplierProductsContext);

  const {
    // Applied filters
    selectedSuppliers,
    setSelectedSuppliers,
    selectedBrands,
    setSelectedBrands,
    selectedStatus,
    setSelectedStatus,
    selectedCatalogs,
    setSelectedCatalogs,
    // Advanced filters
    advancedFilters,
    setAdvancedFilters,
    queryParams,
    setQueryParams,
  } = contextHandler;

  const persistentFilters = [
    ...getListViewPersistentFilters(contextHandler),
    ...getPersistentFilters(contextHandler),
  ];

  const theme = getTheme();

  // Global states
  const [userLanguageCode, setUserLanguageCode] = useState('fr');
  const [shouldRenderEmptyCallToAction, setShouldRenderEmptyCallToAction] = useState(null); // null to distinguish when being first render

  // ListView states - ref
  const listViewRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [isFirstLoadingFinished, setIsFirstLoadingFinished] = useState(false);

  const [columns, setColumns] = useState([]);
  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);

  const [supplierProducts, setSupplierProducts] = useState([]);
  const [supplierProductsCount, setSupplierProductsCount] = useState(0);
  const [selectedSupplierProducts, setSelectedSupplierProducts] = useState([]);

  // Filter states
  const [suppliers, setSuppliers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [columnsFilterList, setColumnsFilterList] = useState([]);
  const [catalogs, setCatalogs] = useState([]);

  // Pagination states
  const [paginationProps, setPaginationProps] = useState(null);
  const [listViewQueryParams, setListViewQueryParams] = useListViewQueryParams(false);

  // Modal SSPM states
  const [sspmModalCurrentStep, setSspmModalCurrentStep] = useState(
    SSPM_MODAL_STEPS.SELECT_AVAILABILITIES,
  );
  const [selectedAvailabilities, setSelectedAvailabilities] = useState({
    available: true,
    hasStock: true,
    hasDlc: true,
  });
  const [sspmNextAvailabilitiesModalSteps, setSspmNextAvailabilitiesModalSteps] = useState(
    SSPM_MODAL_STEPS.CONFIGURE_NEXT_AVAILABILITIES,
  );
  const [nextAvailabilitiesData, setNextAvailabilitiesData] = useState({
    available: true,
    startDate: '',
  });

  // SupplierProduct modal state
  const [enablingSP, setEnablingSP] = useState({}); // when set, render EnableSupplierProductModal,
  const [priceEditableAtReception, setPriceEditableAtReception] = useState(true);

  // Auto mapping SP / Entity modal state
  const [selectedSpEntityMappings, setSelectedSpEntityMappings] = useState([]);

  // Import state
  const [selectedFile, setSelectedFile] = useState(null);

  // Allows to handle rights on actions for central kitchen supplier products
  const [centralKitchenSuppliersSSPMsOfUser, setCentralKitchenSuppliersSSPMsOfUser] = useState([]);

  const formattedSingularStoreName = getClientStoreNameTranslation(storeName, false).toLowerCase();
  const formattedPluralStoreName = getClientStoreNameTranslation(storeName, true).toLowerCase();

  useEffect(() => {
    if (!isFirstLoadingFinished) {
      return;
    }

    (async () => {
      const stores = await centralService.getCentralKitchenSSPFMsOfUser();
      const suppliersMappedToUser = stores.map(({ supplierProfile }) => supplierProfile.supplierId);
      setCentralKitchenSuppliersSSPMsOfUser(suppliersMappedToUser);
    })();

    checkIfClientHasSupplierProduct();
  }, [isFirstLoadingFinished]);

  // First render (not exactly), to fetch brands and suppliers
  useEffect(() => {
    if (!clientId) {
      return;
    }

    setUserLanguageCode(get(user, 'lnkLanguageAccountrel.code'));

    setColumns(columnsUtils.get(hasMultipleBrands, formattedPluralStoreName, currency));

    if (hasMultipleBrands) {
      getBrandsOfClient();
    }

    getSuppliersOfClient();
  }, [clientId, hasMultipleBrands]);

  // When columns are ready, compute advanced filters
  useEffect(() => {
    if (!columns.length) {
      setColumnsFilterList([]);

      return;
    }

    updateAdvancedFilters();
  }, [columns]);

  // Update data to be equal to the context
  useEffect(() => {
    if (!contextHandler) {
      return;
    }

    const listViewQueryParamsToUpdate = persistentFilters.filter(
      ({ filterType }) => filterType === PersistentFilterTypes.LIST_VIEW,
    );

    listViewQueryParamsToUpdate.forEach(({ contextParam, queryParam }) => {
      if (contextParam && contextParam.length && listViewQueryParams[queryParam] !== contextParam) {
        setListViewQueryParams[queryParam](contextParam);
      }
    });
  }, [
    /*
      We watch every variable instead of only contextHandler because it is refreshed at every change and we don't
      want to enter in this useEffect on every change, only when a state is changed
    */
    !!contextHandler &&
      (contextHandler.search ||
        contextHandler.orderBy ||
        contextHandler.orderType ||
        contextHandler.maxPerPage ||
        contextHandler.currentPage),
  ]);

  // Update context to keep data between pages
  useEffect(() => {
    if (!contextHandler) {
      return;
    }

    const contextFiltersToUpdate = persistentFilters.filter(
      ({ filterType }) => filterType === PersistentFilterTypes.LIST_VIEW,
    );
    contextFiltersToUpdate.forEach(({ contextParam, updateFunction, queryParam }) => {
      if (
        (!!listViewQueryParams[queryParam] || listViewQueryParams[queryParam] === '') &&
        listViewQueryParams[queryParam] !== contextParam
      ) {
        updateFunction(listViewQueryParams[queryParam]);
      }
    });

    // Trigger reload SP when pagination or applied filters changed
    if (
      (hasMultipleBrands && brands.length && !selectedBrands.length) ||
      !listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE] ||
      !listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY] ||
      !listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE] ||
      !listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]
    ) {
      /**
       * On listViewQueryParams:
       * Weirdly, when loading the listview after a 'goBack' from a SupplierProduct details page,
       * sometimes the listViewQueryParams is not appropriately set, not even with default values, but undefined instead.
       * This in itself is not the issue, but it leaves a paginated fetch from reloadSupplierProducts which sometimes sets the state after
       * another call with correct listViewQueryParams.
       */

      return;
    }

    reloadSupplierProducts();
  }, [
    listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH],
    listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY],
    listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE],
    listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE],
    listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE],
    selectedBrands,
    selectedStatus,
    selectedSuppliers,
    queryParams, // Change whenever advanced filters changes
  ]);

  //  Trigger reload SP when advanced filters changed
  useEffect(() => {
    if (
      !selectedSuppliers.length ||
      (hasMultipleBrands && brands.length && !selectedBrands.length) ||
      (!applyFilters && advancedFilters !== null) // advancedFilters could be null and applyFilters false when hitting reset filters
    ) {
      return;
    }

    if (!listViewRef || !listViewRef.current) {
      return;
    }

    const { entityIds, params, inventoryListTemplateIds, storageAreaIds } = (
      advancedFilters || []
    ).reduce(
      (result, { getQueryParam, propertyKey, value, values }) => {
        switch (propertyKey) {
          case 'entityName':
            result.entityIds = values.map(({ entityId }) => entityId);
            break;
          case 'inventoryListTemplate':
            result.inventoryListTemplateIds = values.map(
              ({ inventoryListTemplateId }) => inventoryListTemplateId,
            );
            break;
          case 'storageArea':
            result.storageAreaIds = values.map(({ storageAreaId }) => storageAreaId);
            break;
          default:
            result.params += getQueryParam(propertyKey, value);
        }

        return result;
      },
      { entityIds: [], params: '', inventoryListTemplateIds: [], storageAreaIds: [] },
    );

    setQueryParams({ params, entityIds, inventoryListTemplateIds, storageAreaIds });

    setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](1);

    listViewRef.current.resetPagination();
  }, [advancedFilters]);

  // Update actions when receiving new list of SP
  useEffect(() => {
    const filteringOnActive = selectedStatus.filterValue;

    const suppliersKeyById = keyBy(suppliers, 'id');

    setRowActions(
      actionsUtils.getRowActions({
        user,
        hasLocalCatalogs,
        suppliersKeyById,
        centralKitchenSuppliersSSPMsOfUser,
        filteringOnActive,
        openSspmManagementModal,
        handleDuplicateSupplierProduct,
        handleEnableSupplierProduct,
        handleDisableSupplierProducts,
        openSupplierProductsDeletionModal,
        openModalAssignCategorySubCategory,
        openNextAvailabilitiesSchedulingModal,
        handleDeleteScheduledAvailabilitiesModal,
        handleDeleteScheduledPriceModal,
        openPriceEditableAtReceptionModal,
        authorizedActions,
      }),
    );
    setActions(
      actionsUtils.getGlobalActions({
        user,
        hasLocalCatalogs,
        suppliersKeyById,
        centralKitchenSuppliersSSPMsOfUser,
        clientId,
        filteringOnActive,
        selectedSupplierProducts,
        handleExportCategorySubCategory,
        openSspmManagementModal,
        handleNewSupplierProductCreation,
        handleDuplicateSupplierProduct,
        handleEnableSupplierProduct,
        handleDisableSupplierProducts,
        openSupplierProductsDeletionModal,
        openModalAssignCategorySubCategory,
        openModalHandleCategorySubCategory,
        openGenericModalForFileImport,
        getExcelSupplierProductEditPriceVatRate,
        getSupplierProductsBatchCreationTemplate,
        supplierProducts,
        openNextAvailabilitiesSchedulingModal,
        handleDeleteScheduledAvailabilitiesModal,
        handleDeleteScheduledPriceModal,
        authorizedActions,
        openPriceEditableAtReceptionModal,
        openMappingAutoModal,
      }),
    );
  }, [suppliers, supplierProducts, selectedSupplierProducts, centralKitchenSuppliersSSPMsOfUser]);

  // Side effect when SP count is updated (new list received)
  useEffect(() => {
    setPaginationProps(
      paginationUtils.getPropsListView({
        listViewRef,
        listViewQueryParams,
        setListViewQueryParams,
        count: supplierProductsCount,
        onQueryParamsChange: () => true, // not used but necessary to adapt select MaxPage ListView
      }),
    );
  }, [supplierProductsCount]);

  // Handle SSPM modal flow
  useEffect(() => {
    if (!sspmModalCurrentStep) {
      return;
    }

    const params = getSspmStepModalConfig({
      currentStep: sspmModalCurrentStep,
      setCurrentStep: setSspmModalCurrentStep,
      selectedAvailabilities,
      setSelectedAvailabilities,
      storeName: formattedPluralStoreName,
      handleSspmUpdate,
      handleSspmUpdateModalCloseCleanUp,
    });

    refreshGenericModal(params);
  }, [sspmModalCurrentStep, selectedAvailabilities]);

  // Handle SSPM modal flow
  useEffect(() => {
    if (!sspmNextAvailabilitiesModalSteps) {
      return;
    }

    const params = getNextAvailabilitiesModal({
      currentStep: sspmNextAvailabilitiesModalSteps,
      setCurrentStep: setSspmNextAvailabilitiesModalSteps,
      nextAvailabilitiesData,
      setNextAvailabilitiesData,
      storeName: formattedPluralStoreName,
      handleNextAvailabilitiesScheduling,
      handleCloseCleanUp: handleNextAvailabilitiesSchedulingModalCloseCleanUp,
    });

    refreshGenericModal(params);
  }, [sspmNextAvailabilitiesModalSteps, nextAvailabilitiesData]);

  useEffect(() => {
    const params = priceEditableAtReceptionModalConfig({
      handlePriceEditableAtReceptionUpdate,
      selectedItems: selectedSupplierProducts,
      priceEditableAtReception,
      setPriceEditableAtReception,
      handleAvailabilitiesModalCloseCleanUp,
    });

    refreshGenericModal(params);
  }, [priceEditableAtReception]);

  // Handle refresh of auto mapping SP / Entity modal
  useEffect(() => {
    if (!selectedSpEntityMappings) {
      return;
    }

    const supplierIds = suppliers.map(({ id }) => id);

    const params = mappingAutoModalConfig({
      supplierIds,
      selectedSpEntityMappings,
      setSelectedSpEntityMappings,
      handleAutoMappingCreationBetweenSpAndEntities,
      shouldByPassLoading: true, // useful to make sure not to re-render all ListView in modal
    });

    refreshGenericModal(params);
  }, [selectedSpEntityMappings]);

  const updateAdvancedFilters = async () => {
    const advancedFiltersList = await computeAdvancedFiltersList({
      clientId,
      columns,
    });

    setColumnsFilterList(advancedFiltersList);
  };

  const getSuppliersOfClient = async () => {
    try {
      const suppliers = await supplierService.getSuppliersOfClient(clientId, false, true);
      const activeSuppliers = suppliers.filter(({ active }) => active);

      setSuppliers(activeSuppliers);

      if (!!contextHandler && !isEmpty(selectedSuppliers)) {
        // If catalogs have been previously selected, it automatically has set suppliers
        if (!isEmpty(selectedCatalogs)) {
          setSelectedCatalogs(selectedCatalogs);

          // Still getting all catalogs of client to be able to filter by them
          getClientCatalogs(selectedSuppliers);
        }

        setSelectedSuppliers(selectedSuppliers);
        return;
      }

      if (!hasLocalCatalogs) {
        setSelectedSuppliers(activeSuppliers);
      }

      if (suppliers.length) {
        getClientCatalogs(suppliers);
      }
    } catch {
      setSuppliers([]);
      setSelectedSuppliers([]);

      showErrorMessage(i18next.t('ADMIN.SUPPLIERS.FETCH_FAILURE'));
    }
  };

  const getBrandsOfClient = async () => {
    try {
      const result = await brandService.getBrandsOfClient(clientId);

      const noneDropdownBrandValue = {
        id: -1,
        clientId,
        name: i18next.t('GENERAL.SELECT_NONE_FEMININE'),
      };

      if (result.length) {
        result.unshift(noneDropdownBrandValue);
      }

      setBrands(result);

      if (!!contextHandler && !isEmpty(selectedBrands)) {
        setSelectedBrands(selectedBrands);
        return;
      }

      setSelectedBrands(result);
    } catch {
      setBrands([]);
      setSelectedBrands([]);

      showErrorMessage(i18next.t('ADMIN.SUPPLIERS.FETCH_FAILURE'));
    }
  };

  const getClientCatalogs = async (fetchedSuppliers) => {
    if (!hasLocalCatalogs) {
      return;
    }

    // Using fetched supplier since those from the state might not have been updated at this point
    const filteredSuppliers = fetchedSuppliers.filter(
      ({ catalogId }) => user?.catalog?.id === catalogId,
    );

    // If user has only local catalog and is not inpulse
    if (user.catalog && !user.catalog.isMasterCatalog) {
      setCatalogs([user.catalog]);

      // If selected catalogs have been used before to filter, no need to set default values
      if (!selectedCatalogs.length) {
        setSelectedCatalogs([user.catalog]);
        setSelectedSuppliers(filteredSuppliers);
      }

      return;
    }

    try {
      const clientCatalogs = await catalogService.getCatalogsByClientId(clientId);

      const filteredCatalogs = clientCatalogs.filter(({ active }) => active);

      setCatalogs(filteredCatalogs);
      if (!selectedCatalogs.length) {
        setSelectedCatalogs([user.catalog]);
        setSelectedSuppliers(filteredSuppliers);
      }
    } catch {
      showErrorMessage(i18next.t('ADMIN.CATALOGS.FETCH_ERROR'));
    }
  };

  const checkIfClientHasSupplierProduct = async () => {
    try {
      const supplierIds = suppliers.map(({ id }) => id);

      const { totalCount: totalCountActive } = await supplierProductService.getWithPagination({
        clientId,
        supplierIds,
        search: '',
        skip: 0,
        limit: 1,
        orderBy: 'name',
        orderType: 'asc',
        queryParams: '&active=true',
      });

      const { totalCount: totalCountInactive } = await supplierProductService.getWithPagination({
        clientId,
        supplierIds,
        search: '',
        skip: 0,
        limit: 1,
        orderBy: 'name',
        orderType: 'asc',
        queryParams: '&active=false',
      });

      const totalCount = totalCountInactive + totalCountActive;

      // Handle render of specific empty state when no SP on first render
      setShouldRenderEmptyCallToAction(!totalCount && shouldRenderEmptyCallToAction === null);
    } catch {
      setShouldRenderEmptyCallToAction(false);
    }
  };

  const reloadSupplierProducts = async () => {
    setIsLoading(true);

    if (!selectedSuppliers.length) {
      setSupplierProducts([]);
      setSupplierProductsCount(0);

      setIsLoading(false);
      return;
    }

    const columnPropertyKeys = columns.map((column) => column.propertyKey);

    try {
      const brandIds = selectedBrands.map(({ id }) => (id === -1 ? null : id));
      const supplierIds = selectedSuppliers.map(({ id }) => id);

      const { search, skip, limit, orderBy, orderType } = paginationUtils.getPayloadPagination({
        columnPropertyKeys,
        listViewQueryParams,
      });

      const queryParamsWithActiveFilter = `&active=${selectedStatus.filterValue}${queryParams.params}`;

      const { supplierProducts, totalCount } = await supplierProductService.getWithPagination({
        clientId,
        brandIds,
        supplierIds,
        entityIds: queryParams.entityIds,
        inventoryListTemplateIds: queryParams.inventoryListTemplateIds,
        storageAreaIds: queryParams.storageAreaIds,
        search,
        skip,
        limit,
        orderBy: orderBy || 'name',
        orderType: orderType || 'asc',
        queryParams: queryParamsWithActiveFilter,
      });

      const formattedSupplierProducts = supplierProducts.map(computeSupplierProduct);

      setSupplierProducts(formattedSupplierProducts);

      setSupplierProductsCount(totalCount);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FETCH_ERROR'));

      setSupplierProducts([]);
    } finally {
      setIsLoading(false);

      if (!isFirstLoadingFinished) {
        setIsFirstLoadingFinished(true);
      }
    }
  };

  const handleExportCategorySubCategory = async (fullListBool = true) => {
    const supplierProductToExport = await fetchSupplierProductsForExport();

    let filteredSupplierProducts = supplierProductToExport;
    if (!fullListBool) {
      const selectedSupplierProductIds = selectedSupplierProducts.map(({ id }) => id);

      filteredSupplierProducts = supplierProductToExport.filter(({ id }) =>
        selectedSupplierProductIds.includes(id),
      );
    }

    const formattedSupplierProducts = formatSupplierProductsForExport(filteredSupplierProducts);

    exportSupplierProducts(formattedSupplierProducts, currency);
  };

  const _formatSupplierProductsForGlobalExport = (
    supplierProductsForExport,
    spIdsToFilter = [],
  ) => {
    const selectedSupplierIds = selectedSuppliers.map(({ id }) => id);

    const filteredSpWithAppliedFilters = supplierProductsForExport.filter(
      ({ active, supplier }) =>
        active === selectedStatus.filterValue && selectedSupplierIds.includes(supplier.id),
    );

    if (!advancedFilters) {
      return filteredSpWithAppliedFilters;
    }

    /*
      We remove the SSPMOrderCount from filters because if we wanted it to filter the
      export we would need to make pretty big changes in the Back and it's not worth it right now
    */
    const advancedFiltersWithoutSSPMCount = advancedFilters.filter(
      ({ propertyKey }) => propertyKey !== 'SSPMOrderCount',
    );

    if (!advancedFiltersWithoutSSPMCount.length) {
      return filteredSpWithAppliedFilters;
    }

    return advancedFiltersWithoutSSPMCount.reduce((result, { doFilter, propertyKey, value }) => {
      if (
        ['inventoryListTemplate', 'storageArea'].includes(propertyKey) &&
        !isEmpty(spIdsToFilter)
      ) {
        return result.filter(({ id }) => spIdsToFilter.includes(id));
      }

      return doFilter(result, propertyKey, value);
    }, filteredSpWithAppliedFilters);
  };

  /**
   * Unfortunately, the current implementation for the general export relies on fetching all SPs, and then applying advanced filters.
   * In the case of InventoryListTemplates and StorageAreas, which depend on mapping tables, it is necessary to separately
   * fetch the lists of SP IDs matching the chosen advanced filters, and pass those to _formatSupplierProductsForGlobalExport
   * It might be interesting in the future to change the implementation on the API side to facilitate exports.
   */
  const _getSpIdsFromAdvancedFilters = async () => {
    const needsTemplatesFilter = !isEmpty(queryParams.inventoryListTemplateIds);
    const needsStorageAreasFilter = !isEmpty(queryParams.storageAreaIds);
    const needsBothFilters = needsTemplatesFilter && needsStorageAreasFilter;

    const spIdsMappedToTemplates = needsTemplatesFilter
      ? await clientService.getSPIdsMappedToTemplates(
          clientId,
          queryParams.inventoryListTemplateIds,
        )
      : [];

    const spIdsMappedToStorageAreas = needsStorageAreasFilter
      ? await storageAreaService.getSPIdsMappedToStorageAreas(clientId, queryParams.storageAreaIds)
      : [];

    if (needsTemplatesFilter && !needsBothFilters) {
      return spIdsMappedToTemplates;
    }

    if (needsStorageAreasFilter && !needsBothFilters) {
      return spIdsMappedToStorageAreas;
    }

    return intersection(spIdsMappedToTemplates, spIdsMappedToStorageAreas);
  };

  const fetchSupplierProductsForExport = async () => {
    pageLoading();

    try {
      const { supplierProducts } =
        await supplierProductService.getSupplierProductsGroupedByProperty(clientId, null, true);

      const spIdsToFilter = await _getSpIdsFromAdvancedFilters();

      const filteredSupplierProducts = _formatSupplierProductsForGlobalExport(
        supplierProducts,
        spIdsToFilter,
      );

      return filteredSupplierProducts;
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FETCH_ERROR'));
      return [];
    } finally {
      pageLoaded();
    }
  };

  const formatSupplierProductsForExport = (supplierProducts) => {
    supplierProducts.forEach((supplierProduct) => {
      if (supplierProduct.supplierPriceInputs && supplierProduct.supplierProductPrices) {
        const currentSupplierProductPricesByInputIds = keyBy(
          supplierProduct.supplierProductPrices,
          'inputId',
        );
        supplierProduct.supplierPriceInputs = sortBy(supplierProduct.supplierPriceInputs, 'name');

        supplierProduct.supplierPriceInputs.forEach((priceInput, index) => {
          supplierProduct[`inputPriceName${index + 2}`] = priceInput.name;
          supplierProduct[`price${index + 2}`] = get(
            currentSupplierProductPricesByInputIds[priceInput.id],
            'price',
            null,
          );
        });
      }
    });

    return supplierProducts;
  };

  const openGenericModalForFileImport = (importType) => {
    const params = getSupplierProductModalConfig(
      {
        type: IMPORT_MODAL_STEPS.SELECT_FILE,
        updatedFile: null,
        checkedSupplierProducts: null,
        selectedFile: null,
        handleFileChange: onFileChange,
      },
      importType,
      props,
    );

    openGenericModal(params);
  };

  const onFileChange = (file, importType) => {
    setSelectedFile(file);

    refreshImportModal(
      {
        type: IMPORT_MODAL_STEPS.VALIDATE_FILE,
        updatedFile: file,
        checkedSupplierProducts: null,
        selectedFile,
        handleFileChange: onFileChange,
        handleFileValidation:
          importType === IMPORT_TYPES.PRICE_IMPORT
            ? handlePriceFileValidation
            : handleSupplierProductBatchCreationFileValidation,
      },
      importType,
      props,
    );
  };

  const handlePriceFileValidation = async (file) => {
    pageLoading();

    try {
      const jsonSupplierProductsFile = await formatXlsFileToJson(file);
      const formattedJsonSupplierProductsFile = formatSPpriceEditJson(jsonSupplierProductsFile);

      const { status, data } = await clientService.checkedSupplierProducts(
        clientId,
        formattedJsonSupplierProductsFile,
      );

      if (status === 'success') {
        const modalConfigProps = {
          type: IMPORT_MODAL_STEPS.VALIDATED_FILE,
          updatedFile: file,
          checkedSupplierProducts: data,
          selectedFile,
          handleFileChange: onFileChange,
          handleFileValidation: handlePriceFileValidation,
          exportSupplierProductErrors,
          handleFileImport: handleSupplierProductPriceEditImport,
        };

        refreshImportModal(modalConfigProps, IMPORT_TYPES.PRICE_IMPORT, props);
        return;
      }

      const modalConfigProps = {
        type: IMPORT_MODAL_STEPS.ERROR_FILE,
        updatedFile: file,
        checkedSupplierProducts: null,
        selectedFile: null,
        handleFileChange: onFileChange,
        handleFileValidation: handlePriceFileValidation,
        exportSupplierProductErrors,
        handleFileImport: handleSupplierProductPriceEditImport,
      };

      refreshImportModal(modalConfigProps, IMPORT_TYPES.PRICE_IMPORT, props);
    } catch {
      closeGenericModal();

      showErrorMessage(
        i18next.t(
          'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_DOWNLOAD_TEMPLATE_ERROR',
        ),
      );
    } finally {
      pageLoaded();
    }
  };

  const handleSupplierProductPriceEditImport = (supplierProducts) => {
    closeGenericModal();

    openSmallModal({
      component: ImportSpPricesModal,
      title: i18next.t(
        'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_TITLE_BIS',
      ),
      supplierProducts,
      refreshPage: () => reloadSupplierProducts(),
    });

    return;
  };

  const formatSPpriceEditJson = (jsonSupplierProductsFile) =>
    Object.keys(jsonSupplierProductsFile).reduce((result, sheetName) => {
      if (sheetName === i18next.t('ADMIN.SUPPLIER_PRODUCTS.IMPORT_FILE_NAME')) {
        result = jsonSupplierProductsFile[sheetName].map((current) => {
          const scheduledStartDate = current['date de changement de prix'] || null;

          return {
            id: current['id'],
            name: current['nom'],
            supplierName: current['fournisseur'],
            price: current['prix ht'],
            vatRate: current['tva'],
            scheduledPrice: current['prix ht à programmer'] || null,
            priceEditableAtReception: current['prix modifiable à réception'],
            sku: current['sku'],
            category: current['catégorie'],
            subCategory: current['sous-catégorie'],
            ean: current['code ean 13'] || null,

            /*
              Adding one hour because xlsx parsing remove approximately 30 minutes from a date and there's no real fix for it
              except this quickfix, see issue: https://github.com/SheetJS/sheetjs/issues/1565
              We do not take timezone into account so that the timezone stays at DD-MM-YYYY-00-00-00
            */
            scheduledStartDate:
              !!scheduledStartDate &&
              moment(scheduledStartDate, ['DD/MM/YYYY', 'DD-MM-YYYY'], true).isValid()
                ? moment(scheduledStartDate, ['DD/MM/YYYY', 'DD-MM-YYYY'])
                    .utc(true)
                    .add(1, 'h')
                    .startOf('day')
                    .format()
                : null,
          };
        });
      }

      return result;
    }, {});

  const handleSupplierProductBatchCreationFileValidation = async (file) => {
    pageLoading();

    try {
      const jsonSupplierProductsCreationFile = await formatXlsFileToJson(file);
      // formattedPayload contains an object of sheets: supplierProducts, suppliers, entities, categories, subCategories
      const formattedPayload = formatJson(jsonSupplierProductsCreationFile);

      const { status, data, statusCode } =
        await supplierProductService.validateSupplierProductsBatchCreationTemplate(
          clientId,
          formattedPayload,
        );

      if (status === 'success') {
        const modalConfigProps = {
          type: IMPORT_MODAL_STEPS.VALIDATED_FILE,
          batchSPdata: formattedPayload,
          updatedFile: file,
          selectedFile,
          handleFileChange: onFileChange,
          exportSupplierProductErrors,
          handleFileImport: handleBatchSupplierProductsCreation,
        };

        refreshImportModal(modalConfigProps, IMPORT_TYPES.SUPPLIER_PRODUCTS_IMPORT, props);
        return;
      }

      const modalConfigProps = {
        type: IMPORT_MODAL_STEPS.ERROR_FILE,
        updatedFile: file,
        checkedSupplierProducts: data,
        statusCode,
        handleFileChange: onFileChange,
        handleFileValidation: handleSupplierProductBatchCreationFileValidation,
        exportSupplierProductErrors: () =>
          downloadFile(
            data,
            i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_BATCH_ERROR_FILENAME'),
            true,
            false,
          ),
      };

      refreshImportModal(modalConfigProps, IMPORT_TYPES.SUPPLIER_PRODUCTS_IMPORT, props);
    } catch (error) {
      closeGenericModal();

      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.TOASTER_IMPORT_BATCH_FAILED'));
    } finally {
      pageLoaded();
    }
    return;
  };

  const handleBatchSupplierProductsCreation = async (supplierProductsPayload) => {
    pageLoading();

    try {
      const { supplierProducts, suppliers, entities, categories, subCategories, importType } =
        supplierProductsPayload;
      await clientService.createSPbyBatch(
        clientId,
        supplierProducts,
        suppliers,
        entities,
        categories,
        subCategories,
        importType,
      );
      showSuccessMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.BATCH_IMPORT_SUCCESS'));

      await reloadSupplierProducts();
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.BATCH_IMPORT_FAILURE'));
    } finally {
      pageLoaded();
      closeGenericModal();
    }
  };

  const getExcelSupplierProductEditPriceVatRate = async () => {
    const columnPropertyKeys = columns.map((column) => column.propertyKey);

    pageLoading();

    try {
      const brandIds = selectedBrands.map(({ id }) => (id === -1 ? null : id));
      const supplierIds = selectedSuppliers.map(({ id }) => id);

      const { search, orderBy, orderType } = paginationUtils.getPayloadPagination({
        columnPropertyKeys,
        listViewQueryParams,
      });

      const queryParamsWithActiveFilter = `&active=${selectedStatus.filterValue}${queryParams.params}`;
      const result = await supplierProductService.getExcelSupplierProductEditPriceVatRate({
        clientId,
        brandIds,
        supplierIds,
        entityIds: queryParams.entityIds,
        inventoryListTemplateIds: queryParams.inventoryListTemplateIds,
        storageAreaIds: queryParams.storageAreaIds,
        search,
        orderBy: orderBy || 'name',
        orderType: orderType || 'asc',
        queryParams: queryParamsWithActiveFilter,
      });

      downloadFile(result, i18next.t('GENERAL.LIST_ACTION_EDIT_PRICE_XLS_FILENAME'), false, false);
    } catch (error) {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.DOWNLOAD_EDIT_PRICE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const getSupplierProductsBatchCreationTemplate = async () => {
    pageLoading();
    try {
      const result = await supplierProductService.getSupplierProductsBatchCreationTemplate(
        clientId,
      );

      downloadFile(
        result,
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.DOWNLOAD_TEMPLATE_FOR_IMPORT_FILENAME'),
      );
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.DOWNLOAD_TEMPLATE_FOR_IMPORT_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleCallToDisableSupplierProducts = async (supplierProductIds) => {
    pageLoading();

    const singleDeactivate = supplierProductIds.length === 1;

    try {
      await supplierProductService.disableSupplierProducts(supplierProductIds);

      showSuccessMessage(
        singleDeactivate
          ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.DEACTIVATE_ONE_SUCCESS')
          : i18next.t('ADMIN.SUPPLIER_PRODUCTS.DEACTIVATE_MULTIPLE_SUCCESS'),
      );

      await reloadSupplierProducts();
    } catch (e) {
      showErrorMessage(
        singleDeactivate
          ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.DEACTIVATE_ONE_FAILURE')
          : i18next.t('ADMIN.SUPPLIER_PRODUCTS.DEACTIVATE_MULTIPLE_FAILURE'),
      );
    } finally {
      pageLoaded();
    }
  };

  const handleDisableSupplierProducts = (supplierProducts) => {
    const singleDeactivate = supplierProducts.length === 1;

    const supplierProductIds = supplierProducts.map(({ id }) => id);

    const warningModalConfig = getConfirmationModal({
      title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DEACTIVATE_SUPPLIER_PRODUCT', {
        count: supplierProducts.length,
      }),
      content: singleDeactivate
        ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.DEACTIVATE_ONE_WARNING', {
            name: head(supplierProducts).name,
          })
        : i18next.t('ADMIN.SUPPLIER_PRODUCTS.DEACTIVATE_MULTIPLE_WARNING'),
      icon: '/images/inpulse/power-white-small.svg',
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          key: 1,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.DEACTIVATE'),
          icon: '/images/inpulse/power-white-small.svg',
          handleClick: () => {
            handleCallToDisableSupplierProducts(supplierProductIds);
          },
        },
      ],
    });

    openGenericModal(warningModalConfig);
  };

  // Will be passed to the EnableSupplierProductModal component to stop rendering it
  const resetEnablingSP = () => {
    setEnablingSP({});
  };

  /**
   * @param {*} formattedData result of formatDataForApiCall from EnableSupplierProductModal/modalConfiguration.js
   * {
   *   supplierProductId: <uuid>,
   *   entityId: <uuid>,
   *   storeSupplierProductMappings: Object[],
   * }
   */
  const handleCallToEnableSupplierProduct = async (formattedData) => {
    pageLoading();

    const { supplierProduct, entityId, storeSupplierProductMappings } = formattedData;

    try {
      await supplierProductService.enableSupplierProduct(
        supplierProduct,
        entityId,
        storeSupplierProductMappings,
      );

      showSuccessMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTIVATE_ONE_SUCCESS'));

      await reloadSupplierProducts();
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTIVATE_ONE_FAILURE'));
    } finally {
      pageLoaded();
    }
  };

  const handleEnableSupplierProduct = async (supplierProduct) => {
    // Setting this state will render the EnableSupplierProductModal component
    const formattedSupplierProduct = await supplierProductService.getSingleSupplierProduct(
      supplierProduct.id,
    );
    setEnablingSP(formattedSupplierProduct);
  };

  const openSupplierProductsDeletionModal = async (supplierProducts) => {
    const params = getSupplierProductsDeletionModalParams(
      handleSupplierProductsDeletion,
      supplierProducts,
    );

    openGenericModal(params);
  };

  const openModalHandleCategorySubCategory = async (type) => {
    pageLoading();

    try {
      const { supplierProducts: allSP } =
        await supplierProductService.getSupplierProductsGroupedByProperty(clientId, null);

      const params = getCategorySubCategoryModalParams(
        allSP,
        type,
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.SUPPLIER_PRODUCT_ABBREVIATION'),
        handleSupplierProductChangeModalBatch,
      );

      openGenericModal(params);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FETCH_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleSupplierProductsDeletion = async (supplierProducts, isSingleDelete) => {
    pageLoading();

    try {
      const supplierProductIds = supplierProducts.map((supplierProduct) => supplierProduct.id);

      await supplierProductService.deleteByIds(supplierProductIds);

      showSuccessMessage(
        i18next.t(
          isSingleDelete
            ? 'ADMIN.SUPPLIER_PRODUCTS.SINGLE_DELETION_SUCCESS'
            : 'ADMIN.SUPPLIER_PRODUCTS.MULTIPLE_DELETION_SUCCESS',
        ),
      );
    } catch {
      showErrorMessage(
        i18next.t(
          isSingleDelete
            ? 'ADMIN.SUPPLIER_PRODUCTS.SINGLE_DELETION_ERROR'
            : 'ADMIN.SUPPLIER_PRODUCTS.MULTIPLE_DELETION_ERROR',
        ),
      );
    }

    await reloadSupplierProducts();
    pageLoaded();
  };

  const _mapCategorySubCategory = (type, values) =>
    values.map((value, index) => ({
      id: index,
      [type]: value,
    }));

  const openModalAssignCategorySubCategory = async (items, type) => {
    pageLoading();

    try {
      const { categories, subCategories } =
        await supplierProductService.getSupplierProductsCategories(clientId);

      const values = _mapCategorySubCategory(
        type,
        type === 'category' ? categories : subCategories,
      );

      const params = getAssignCategorySubCategoryModalParams(
        values,
        items,
        type,
        handleSupplierProductChangeModalBatch,
      );

      openGenericModal(params);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FETCH_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const getCustomMultipleDropdowns = () => {
    const customMultipleDropdowns = [];

    if (hasMultipleBrands && brands.length) {
      customMultipleDropdowns.push({
        id: 'brands-list',
        list: brands,
        selectedItems: selectedBrands,
        setSelectedItems: setSelectedBrands,
        defaultSelectedItems: selectedBrands,
        icon: '/images/inpulse/brand-black-small.svg',
      });
    }

    return customMultipleDropdowns;
  };

  const openSspmManagementModal = (selectedSupplierProducts) => {
    setSelectedSupplierProducts(selectedSupplierProducts);

    setSspmModalCurrentStep(SSPM_MODAL_STEPS.SELECT_AVAILABILITIES);

    const params = getSspmStepModalConfig({
      currentStep: SSPM_MODAL_STEPS.SELECT_AVAILABILITIES,
      setCurrentStep: setSspmModalCurrentStep,
      selectedAvailabilities,
      setSelectedAvailabilities,
      storeName: formattedSingularStoreName,
      handleSspmUpdate,
      handleSspmUpdateModalCloseCleanUp,
    });

    openGenericModal(params);
  };

  const handleSspmUpdateModalCloseCleanUp = () => {
    setSelectedAvailabilities({
      available: true,
      hasStock: true,
      hasDlc: true,
    });

    setSspmModalCurrentStep(SSPM_MODAL_STEPS.SELECT_AVAILABILITIES);
  };

  const handleSspmUpdate = async () => {
    pageLoading();

    try {
      const selectedSupplierProductIds = selectedSupplierProducts.map(({ id }) => id);

      await supplierProductService.updateBySupplierProductIds(
        selectedSupplierProductIds,
        selectedAvailabilities.available,
        selectedAvailabilities.hasStock,
        selectedAvailabilities.hasDlc,
      );

      handleSspmUpdateModalCloseCleanUp();
      closeGenericModal();

      showSuccessMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.AVAILABILITIES_SUCCESSFULLY_UPDATED'));

      reloadSupplierProducts();
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_UPDATE_SSPM_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleNewSupplierProductCreation = () => {
    history.push('/admin/suppliers/supplier-products/create');
  };

  const handleDuplicateSupplierProduct = async (supplierProduct) => {
    history.push({
      pathname: '/admin/suppliers/supplier-products/create',
      supplierProductId: supplierProduct.id,
      type: 'duplicate',
    });
  };

  const goToSupplierProductDetails = (id) => {
    history.push(`/admin/suppliers/supplier-products/${id}/details`);
  };

  const handleSupplierProductChangeModalBatch = (
    updatedSupplierProduct,
    updateProperties = true,
    field,
  ) => {
    const updatedDataModal = updatedSupplierProduct.reduce((result, current) => {
      if (!result[current.value]) {
        result[current.value] = {
          ...omit(current, 'ids'),
          associatedItems: current.ids.map((id) => ({
            id,
          })),
        };
      }
      return result;
    }, {});

    const updatedData = { data: updatedDataModal };

    if (updateProperties) {
      updatedData.properties = Object.keys(updatedDataModal).sort();
    }

    refreshGenericModal(
      {
        data: updatedData,
        actions: [
          GENERIC_MODAL_CANCEL_BUTTON(),
          {
            ...GENERIC_MODAL_SAVE_BUTTON(),
            handleClick: async () =>
              await updateSupplierProduct(updatedSupplierProduct, {
                successMessage: i18next.t(
                  field === 'category'
                    ? 'GENERAL.MODAL_CATEGORIES_UPDATE_SUCCESS'
                    : 'GENERAL.MODAL_SUBCATEGORIES_UPDATE_SUCCESS',
                ),
                errorMessage: i18next.t(
                  field === 'category'
                    ? 'GENERAL.MODAL_CATEGORIES_UPDATE_ERROR'
                    : 'GENERAL.MODAL_SUBCATEGORIES_UPDATE_ERROR',
                ),
              }),
            isDisabled: false,
          },
        ],
      },
      ['actions', 'data.data', 'data.properties'],
    );
  };

  const updateSupplierProduct = async (
    updatedSupplierProduct,
    { successMessage, errorMessage },
  ) => {
    if (isEmpty(updatedSupplierProduct)) {
      return;
    }

    pageLoading();

    try {
      await supplierProductService.updateSupplierProductColumnByBatch(updatedSupplierProduct);
      showSuccessMessage(successMessage);

      await reloadSupplierProducts();

      // Make sure that list of categories and sub-categories are up to date
      await updateAdvancedFilters();
    } catch {
      showErrorMessage(errorMessage);
    } finally {
      pageLoaded();
    }
  };

  const openNextAvailabilitiesSchedulingModal = (selectedSupplierProducts) => {
    setSelectedSupplierProducts(selectedSupplierProducts);

    setSspmNextAvailabilitiesModalSteps(SSPM_MODAL_STEPS.CONFIGURE_NEXT_AVAILABILITIES);

    const params = getNextAvailabilitiesModal({
      currentStep: SSPM_MODAL_STEPS.CONFIGURE_NEXT_AVAILABILITIES,
      setCurrentStep: setSspmNextAvailabilitiesModalSteps,
      nextAvailabilitiesData,
      setNextAvailabilitiesData,
      storeName: formattedPluralStoreName,
      handleNextAvailabilitiesScheduling,
      handleCloseCleanUp: handleNextAvailabilitiesSchedulingModalCloseCleanUp,
    });

    openGenericModal(params);
  };

  const handleNextAvailabilitiesSchedulingModalCloseCleanUp = () => {
    setNextAvailabilitiesData({
      available: true,
      startDate: '',
    });

    setSspmNextAvailabilitiesModalSteps(SSPM_MODAL_STEPS.CONFIGURE_NEXT_AVAILABILITIES);
  };

  const handleNextAvailabilitiesScheduling = async () => {
    if (!selectedSupplierProducts.length) {
      return;
    }

    pageLoading();
    try {
      const selectedSupplierProductIds = selectedSupplierProducts.map(({ id }) => id);

      await supplierProductService.createAvailabilitiesByBatch(
        selectedSupplierProductIds,
        nextAvailabilitiesData.startDate,
        nextAvailabilitiesData.available,
      );

      handleNextAvailabilitiesSchedulingModalCloseCleanUp();
      closeGenericModal();

      showSuccessMessage(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.NEXT_AVAILABILITIES_BATCH_CREATION_SUCCESS'),
      );

      reloadSupplierProducts();
    } catch {
      showErrorMessage(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.NEXT_AVAILABILITIES_BATCH_CREATION_ERROR'),
      );
    } finally {
      pageLoaded();
    }
  };

  const handleDeleteScheduledAvailabilitiesModal = (supplierProducts) => {
    const supplierProductIds = supplierProducts.map(({ id }) => id);

    const isSingleSelection = supplierProductIds.length === 1;

    const warningModalConfig = getConfirmationModal({
      title: isSingleSelection
        ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_AVAILABILITY_MODAL_TITLE_SINGLE')
        : i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_AVAILABILITY_MODAL_TITLE_MULTIPLE'),
      content: isSingleSelection
        ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_AVAILABILITY_MODAL_CONTENT_SINGLE', {
            storeName: formattedPluralStoreName,
          })
        : i18next.t(
            'ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_AVAILABILITY_MODAL_CONTENT_MULTIPLE',
            {
              storeName: formattedPluralStoreName,
            },
          ),
      icon: '/images/inpulse/warning-white-small.svg',
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: () => {
            handleCallToDeleteScheduledAvailabilities(supplierProductIds);
          },
        },
      ],
    });

    openGenericModal(warningModalConfig);
  };

  const handleDeleteScheduledPriceModal = (supplierProducts) => {
    const supplierProductIds = supplierProducts.map(({ id }) => id);

    const isSingleSelection = supplierProductIds.length === 1;

    const warningModalConfig = getConfirmationModal({
      title: isSingleSelection
        ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_AVAILABILITY_MODAL_TITLE_SINGLE')
        : i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_AVAILABILITY_MODAL_TITLE_MULTIPLE'),
      content: isSingleSelection
        ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_PRICE_MODAL_CONTENT_SINGLE', {
            storeName: formattedPluralStoreName,
          })
        : i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_PRICE_MODAL_CONTENT_MULTIPLE', {
            storeName: formattedPluralStoreName,
          }),
      icon: '/images/inpulse/warning-white-small.svg',
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: () => {
            handleCallToDeleteScheduledPrices(supplierProductIds);
          },
        },
      ],
    });

    openGenericModal(warningModalConfig);
  };

  const handleCallToDeleteScheduledPrices = async (supplierProductIds) => {
    pageLoading();

    const isSingleSelection = supplierProductIds.length === 1;

    try {
      await supplierProductService.deleteScheduledPricesBySPIds(supplierProductIds);

      showSuccessMessage(
        isSingleSelection
          ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_PRICE_SUCCESS_SINGLE')
          : i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_PRICE_SUCCESS_MULTIPLE'),
      );

      reloadSupplierProducts();
    } catch {
      showErrorMessage(
        isSingleSelection
          ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_PRICE_ERROR_SINGLE')
          : i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_PRICE_ERROR_MULTIPLE'),
      );
    } finally {
      pageLoaded();
    }
  };

  const handleCallToDeleteScheduledAvailabilities = async (supplierProductIds) => {
    pageLoading();

    const isSingleSelection = supplierProductIds.length === 1;

    try {
      await supplierProductService.deleteScheduledAvailabilitiesBySPIds(supplierProductIds);

      showSuccessMessage(
        isSingleSelection
          ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_NEXT_AVAILABILTIES_SUCCESS_SINGLE')
          : i18next.t(
              'ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_NEXT_AVAILABILTIES_SUCCESS_MULTIPLE',
            ),
      );

      reloadSupplierProducts();
    } catch (error) {
      showErrorMessage(
        isSingleSelection
          ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_NEXT_AVAILABILTIES_ERROR_SINGLE')
          : i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SCHEDULED_NEXT_AVAILABILTIES_ERROR_MULTIPLE'),
      );
    } finally {
      pageLoaded();
    }
  };

  const handlePriceEditableAtReceptionUpdate = async (
    selectedSupplierProducts,
    priceEditableAtReception,
  ) => {
    pageLoading();

    const supplierProductIds = selectedSupplierProducts.map(({ id }) => id);

    try {
      await supplierProductService.updatePriceEditableAtReceptionByIds(
        supplierProductIds,
        priceEditableAtReception,
      );

      handleAvailabilitiesModalCloseCleanUp();

      showSuccessMessage(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.PRICE_EDITABLE_AT_RECEPTION_UPDATE_ACTIVATION_SUCCESS'),
      );

      reloadSupplierProducts();
    } catch {
      showErrorMessage(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.PRICE_EDITABLE_AT_RECEPTION_UPDATE_ACTIVATION_ERROR'),
      );
    } finally {
      pageLoaded();
    }
  };

  const handleAvailabilitiesModalCloseCleanUp = () => {
    setPriceEditableAtReception(true);
    setSelectedSupplierProducts([]);
    closeGenericModal();
  };

  const openPriceEditableAtReceptionModal = async (selectedItems) => {
    setSelectedSupplierProducts(selectedItems);

    const params = priceEditableAtReceptionModalConfig({
      handlePriceEditableAtReceptionUpdate,
      selectedItems,
      priceEditableAtReception,
      setPriceEditableAtReception,
      handleAvailabilitiesModalCloseCleanUp,
    });

    openGenericModal(params);
  };

  const openMappingAutoModal = () => {
    const supplierIds = suppliers.map(({ id }) => id);

    const params = mappingAutoModalConfig({
      supplierIds,
      selectedSpEntityMappings,
      setSelectedSpEntityMappings,
      handleAutoMappingCreationBetweenSpAndEntities,
    });

    openGenericModal(params);
  };

  const handleAutoMappingCreationBetweenSpAndEntities = async () => {
    pageLoading();

    const { mappings, targettedEntityId, targettedSupplierProductIds } =
      selectedSpEntityMappings.reduce(
        (result, { supplierProductId, entity: { id: entityId } }) => {
          result.mappings.push({ entityId, supplierProductId });

          result.targettedEntityId[entityId] = true;
          result.targettedSupplierProductIds[supplierProductId] = true;

          return result;
        },
        {
          mappings: [],
          targettedEntityId: {},
          targettedSupplierProductIds: {},
        },
      );

    try {
      await supplierProductService.assignEntityInBatch(mappings);

      showSuccessMessage(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.MAPPING_AUTO_MODAL_DATA_SAVE_SUCCESS', {
          entityCount: Object.keys(targettedEntityId).length,
          spCount: Object.keys(targettedSupplierProductIds).length,
          count: mappings.length,
        }),
      );

      await reloadSupplierProducts();
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.MAPPING_AUTO_MODAL_DATA_SAVE_ERROR'));
    } finally {
      pageLoaded();
      closeGenericModal();
    }
  };

  if (hasLocalCatalogs && isEmpty(user.catalog)) {
    return (
      <>
        <NavigationBreadCrumb featurePath={path} />
        <Container>
          <GeneralEmptyStateListView
            icon={'/images/inpulse/production-planning.svg'}
            subtitle={i18next.t('ADMIN.SUPPLIER_PRODUCTS.NO_CATALOGS_EMPTY_STATE_SUBTITLE')}
            title={i18next.t('ADMIN.SUPPLIER_PRODUCTS.NO_CATALOGS_EMPTY_STATE_TITLE')}
          />
        </Container>
      </>
    );
  }

  if (shouldRenderEmptyCallToAction) {
    return (
      <>
        <NavigationBreadCrumb featurePath={path} />
        <Container>
          <GeneralEmptyStateListView
            icon={'/images/inpulse/supplier-ingredients.svg'}
            renderAction={() =>
              canCreateSupplierProducts(authorizedActions) ? (
                <Button
                  color="inpulse-default"
                  handleClick={handleNewSupplierProductCreation}
                  icon={'/images/inpulse/add-white-small.svg'}
                  label={i18next.t('ADMIN.SUPPLIER_PRODUCTS.EMPTY_STATE_ACTION')}
                />
              ) : null
            }
            subtitle={i18next.t('ADMIN.SUPPLIER_PRODUCTS.EMPTY_STATE_SUBTITLE')}
            title={i18next.t('ADMIN.SUPPLIER_PRODUCTS.EMPTY_STATE_TITLE')}
          />
        </Container>
      </>
    );
  }

  // Goal is to avoid render ListView with unbuilt pagination props
  if (!paginationProps) {
    return <></>;
  }

  return (
    <>
      <NavigationBreadCrumb featurePath={path} />
      <Container>
        <ListView
          {...paginationProps}
          actionOnClick={({ id }) => goToSupplierProductDetails(id)}
          actions={actions}
          columns={columns}
          data={supplierProducts}
          defaultSearchInput={contextHandler.search}
          isLoading={isLoading}
          languageCode={userLanguageCode}
          markerConfiguration={{
            isHidden: ({ isKitchen }) => !isKitchen,
            backgroundColor: theme.colors.brand.secondary,
            icon: { src: '/images/inpulse/central-black-small.svg' },
          }}
          padding={STANDARD_LISTVIEW_PADDING}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => <EmptyState />}
          renderFilterButton={() => (
            <DeepsightFiltersButton
              advancedFilters={advancedFilters}
              applyFilters={applyFilters}
              catalogs={catalogs}
              columnsFilterList={columnsFilterList}
              customMultipleDropDowns={getCustomMultipleDropdowns()}
              customSingleDropDowns={[
                {
                  id: 'active-inactive',
                  selectedItem: selectedStatus,
                  list: CHOICES_DROPDOWN_ACTIVE,
                  setSelectedItem: setSelectedStatus,
                  defaultSelectedItem: CHOICES_DROPDOWN_ACTIVE[0],
                  itemSelectedIcon: '/images/inpulse/power-ip-black.svg',
                },
              ]}
              filters={filters}
              isLoading={isLoading}
              minWidth={120}
              readOnly={isLoading}
              selectedCatalogs={selectedCatalogs}
              selectedSuppliers={selectedSuppliers}
              setAdvancedFilters={setAdvancedFilters}
              setApplyFilters={setApplyFilters}
              setFilters={setFilters}
              setSelectedCatalogs={setSelectedCatalogs}
              setSelectedSuppliers={setSelectedSuppliers}
              suppliers={suppliers}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
            />
          )}
          rowActions={rowActions}
          setSelectedItems={setSelectedSupplierProducts}
        />
        {!isEmpty(enablingSP) && (
          <EnableSupplierProductModal
            handleCallToEnableSupplierProduct={handleCallToEnableSupplierProduct}
            resetEnablingSP={resetEnablingSP}
            supplierProduct={enablingSP}
          />
        )}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/admin/suppliers/supplier-products',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params, customPropertyPathsToUpdate) => {
    dispatch(refreshGenericModal(params, customPropertyPathsToUpdate));
  },
  closeGenericModal: () => {
    dispatch(closeGenericModal());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openSmallModal: (params) => {
    dispatch(openSmallModal(params));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierProducts);
