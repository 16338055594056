import { get, sortBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { formatNumberToExcelUsage } from '@commons/DisplayNumber';

import { PAST_STOCKS_TYPE } from '@stocks/utils/constants';

import StocksAnalyticsPastExportModal from '../StocksAnalyticsPast/commons/StocksAnalyticsPastExportModal';

const FILE_TITLE = {
  [PAST_STOCKS_TYPE.INGREDIENTS]: i18next.t('STOCKS.PAST_STOCKS.PAST_STOCKS_INGREDIENTS'),
  [PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS]: i18next.t(
    'STOCKS.PAST_STOCKS_INGREDIENTS.PAST_STOCKS_CENTRAL_KITCHEN_INGREDIENTS',
  ),
  [PAST_STOCKS_TYPE.CENTRAL_KITCHEN_RECIPES]: i18next.t(
    'STOCKS.PAST_STOCKS_RECIPES.PAST_STOCKS_CENTRAL_KITCHEN_RECIPES',
  ),
};

export const exportStocksAnalyticsPast = (
  displayedPastStocks,
  openModalExportInfo,
  pastStockType,
) => {
  const formattedDisplayedPastStocks = displayedPastStocks.map((stock) => {
    const theoricalStockUnit = get(stock, 'theoricalStock.unit', null);
    const theoricalStockTurnover = get(stock, 'theoricalStock.turnover', null);
    const realStockUnit = get(
      stock,
      pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_RECIPES
        ? 'realStock.unit'
        : 'realStockWithRecipes.unit',
      null,
    );
    const realStockTurnover = get(
      stock,
      pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_RECIPES
        ? 'realStock.turnover'
        : 'realStockWithRecipes.turnover',
      null,
    );
    const stockGapUnit = get(stock, 'stockGapValue.unit', null);
    const stockGapTurnover = get(stock, 'stockGapValue.turnover', null);

    return {
      ...stock,
      theoricalStockUnit: !!theoricalStockUnit ? Math.max(theoricalStockUnit.toFixed(2), 0) : '',
      theoricalStockTurnover: !!theoricalStockTurnover
        ? Math.max(theoricalStockTurnover.toFixed(2), 0)
        : '',
      realStockUnit:
        realStockUnit != null ? formatNumberToExcelUsage(realStockUnit.toFixed(2), 2) : '',
      realStockTurnover:
        realStockTurnover != null ? formatNumberToExcelUsage(realStockTurnover.toFixed(2), 2) : '',
      stockGapUnit: !!stockGapUnit ? formatNumberToExcelUsage(stockGapUnit.toFixed(2), 2) : '',
      stockGapTurnover: !!stockGapTurnover
        ? formatNumberToExcelUsage(stockGapTurnover.toFixed(2), 2)
        : '',
    };
  });

  const sortedDisplayedPastStocks = sortBy(formattedDisplayedPastStocks, 'name');

  openModalExportInfo({
    component: StocksAnalyticsPastExportModal,
    title: `${FILE_TITLE[pastStockType]} ${moment().format(
      DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
    )}`,
    mainSheetData: sortedDisplayedPastStocks,
    pastStockType,
  });
};
