import { connect } from 'react-redux';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage } from '@actions/messageconfirmation';

import { ANALYTICS_TYPE } from '@commons/constants/analyticsType';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { TableView } from '@commons/utils/styledLibraryComponents';
import DeepsightAnalyticsHeaders from '@commons/DeepsightAnalyticsHeaders';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { FiltersContext } from '@context/FiltersContext';

import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import { OfflineProductionPage } from '../../OfflinePage/OfflineProductionPage';

import { useProductsFilter } from '../hooks/useProductsFilter';
import { useStoresFilter } from '../hooks/useStoresFilter';
import METRICS from '../constants/metrics';

import {
  Container,
  ContainerContent,
  ContainerHeader,
  ContainerTableView,
} from './styledComponents';
import utils from './utils';

export const ProductionRealTimeAnalytics = (props) => {
  const {
    // redux props
    stores,
    currency,
    user,
    client: { clientId, hasMultipleBrands, hasOffline, storeName },
    // redux methods
    pageLoading,
    pageLoaded,
    showErrorMessage,
    // offline
    isUserOffline,
    isConnectionSlow,
    // Feature client mapping props
    defaultAnalyticsMetric,
    applyBrandsFilterOnProducts,
  } = props;

  const { filtersAnalyticsProduction, setFiltersAnalyticsProduction } = useContext(FiltersContext);

  // Loading states
  const [isLoading, setIsLoading] = useState(true);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [renderEmptyState, setRenderEmptyState] = useState(false);
  const [isRetrievingAnalytics, setIsRetrievingAnalytics] = useState(true);
  const [shouldRetrieveAnalytics, setShouldRetrieveAnalytics] = useState(false);

  const productsFilters = useProductsFilter({
    clientId,
    showErrorMessage,
    useBrandsFilter: hasMultipleBrands && applyBrandsFilterOnProducts,
  });

  const storesFilters = useStoresFilter({
    stores,
    clientId,
    showErrorMessage,
    useBrandsFilter: hasMultipleBrands && !applyBrandsFilterOnProducts,
  });

  const [selectedMetric, setSelectedMetric] = useState(METRICS[0]);

  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);

  // TableView commponent states
  const [columns, setColumns] = useState([]);
  const [analytics, setAnalytics] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  // Check if user loses internet to display dedicated view if client.hasOffline
  useEffect(() => {
    const updatedShouldFetchData = !hasOffline || (!isUserOffline && !isConnectionSlow);

    if (shouldFetchData !== updatedShouldFetchData) {
      setShouldFetchData(updatedShouldFetchData);
      setIsLoading(updatedShouldFetchData);
    }
  }, [hasOffline, isUserOffline, isConnectionSlow]);

  // Set default analytics metrics for client
  useEffect(() => {
    const matchingMetric = METRICS.find((metric) => metric.key === defaultAnalyticsMetric);

    if (matchingMetric) {
      setSelectedMetric(matchingMetric);
    }
  }, [defaultAnalyticsMetric]);

  // Handle columns configuration
  useEffect(() => {
    const clientStoreName = getClientStoreNameTranslation(storeName);

    setColumns(utils.getColumnsTable(selectedMetric.key, clientStoreName));
  }, [storeName, selectedMetric]);

  // Wait until stores filters are loaded to set isLoading to false
  useEffect(() => {
    if (!shouldFetchData) {
      return;
    }

    if (storesFilters.isLoading || productsFilters.isLoading) {
      pageLoading();

      return;
    }

    setIsLoading(false);

    pageLoaded();
  }, [shouldFetchData, storesFilters.isLoading, productsFilters.isLoading]);

  // Listen to state updates that could trigger fetch of analytics and wait until all states are updated
  useEffect(() => {
    if (isLoading) {
      return;
    }

    // To indicate and simulate that analytics are already being loaded
    setIsRetrievingAnalytics(true);

    setTimeout(() => {
      setShouldRetrieveAnalytics(true);
    }, 250); // make sure all states are properly updated from filters component
  }, [isLoading, storesFilters.selectedStores, productsFilters.selectedProducts]);

  // Handle the fetch of analytics data when triggered
  useEffect(() => {
    if (!shouldRetrieveAnalytics) {
      return;
    }

    const storeIds = storesFilters.selectedStores.map((store) => store.id);
    const productIds = productsFilters.selectedProducts.map((item) => item.id);

    if (!storeIds.length) {
      return;
    }

    setAnalytics(null);
    setRenderEmptyState(false);

    (async function loadData() {
      const result = await utils.getAnalyticsData(storeIds, productIds, showErrorMessage);

      setAnalytics(result);

      setIsRetrievingAnalytics(false);
      setShouldRetrieveAnalytics(false);
    })();
  }, [shouldRetrieveAnalytics]);

  // Apply advanced filters on received analytics
  useEffect(() => {
    if (!analytics || isRetrievingAnalytics) {
      return;
    }

    if ((!advancedFilters || !advancedFilters.length) && applyFilters) {
      setFilteredData(analytics);
      setRenderEmptyState(!analytics || !analytics.length);

      return;
    }

    if (!applyFilters) {
      return;
    }

    let filteredData = analytics.filter((data) => data.id !== 'total');

    advancedFilters.forEach(({ propertyKey, doFilter, value }) => {
      filteredData = doFilter(filteredData, propertyKey, value);
    });

    let totalLine = analytics.find((data) => data.id === 'total');
    if (totalLine && filteredData.length) {
      const updatedTotalLine = utils.aggregateStatsOnTotalLevel(
        _.cloneDeep(totalLine),
        filteredData,
      );
      filteredData.unshift(updatedTotalLine);
    }

    setFilteredData(filteredData);
    setRenderEmptyState(!filteredData || !filteredData.length);
  }, [analytics, advancedFilters, applyFilters, isRetrievingAnalytics]);

  const handleExport = () => {
    if (isRetrievingAnalytics) {
      return;
    }

    utils.getExportFile({
      currency,
      storeName,
      selectedMetric,
      data: filteredData,
    });
  };

  if (isLoading) {
    return null;
  }

  if (hasOffline && (isUserOffline || isConnectionSlow)) {
    return (
      <Container>
        <NavigationBreadCrumb featurePath={props.match.path} />
        <OfflineProductionPage isAnalytics />
      </Container>
    );
  }
  return (
    <Container>
      <NavigationBreadCrumb featurePath={props.match.path} />
      <ContainerContent>
        <ContainerHeader>
          <DeepsightAnalyticsHeaders
            advancedFilters={advancedFilters}
            analyticsType={ANALYTICS_TYPE.PRODUCTION}
            columnsFilterList={columns.filter((column) => column.filterType === 'numeric')}
            customMultipleDropDowns={[
              ...Object.values(storesFilters.filtersKeyById),
              ...Object.values(productsFilters.filtersKeyById),
            ]}
            disableExportButton={!filteredData || !filteredData.length}
            displayCurrentDay={true}
            filters={filtersAnalyticsProduction}
            handleExport={handleExport}
            hideDatePicker={true}
            metrics={METRICS}
            readOnly={isRetrievingAnalytics}
            selectedMetric={selectedMetric}
            setAdvancedFilters={setAdvancedFilters}
            setApplyFilters={setApplyFilters}
            setFilters={setFiltersAnalyticsProduction}
            setSelectedMetric={(value) => {
              setAdvancedFilters(null);
              setSelectedMetric(value);
            }}
          />
        </ContainerHeader>
        <ContainerTableView>
          {renderEmptyState && (
            <div style={{ verticalAlign: 'middle', height: 'calc(100vh - 225px)' }}>
              <EmptyState />
            </div>
          )}
          {!renderEmptyState && (
            <TableView
              columns={columns}
              data={filteredData}
              defaultOrderBy={1}
              defaultOrderType={'asc'}
              displayColumnsFilters={false}
              highligthedRowsId={['total']}
              isLoading={isRetrievingAnalytics}
              languageCode={_.get(user, 'lnkLanguageAccountrel.code', 'fr')}
              maxPerPage={100}
              minWidth={1000}
            />
          )}
        </ContainerTableView>
      </ContainerContent>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  isUserOffline: state.baseReducer.isUserOffline,
  isConnectionSlow: state.baseReducer.isConnectionSlow,
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message, type) => {
    dispatch(showErrorMessage(message, type));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductionRealTimeAnalytics);
