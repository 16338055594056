import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import SpinnerIcon from '@assets/icons/new-loader.svg';

import { formatShortenCurrencyNumber } from '@commons/utils/format';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { LoadingIcon, StatContainer, VariationContainer, VariationIcon } from './styledComponents';

export const STATS_DATA_TYPE = {
  CURRENCY: 'currency',
  UNIT: 'unit',
  PERCENT: 'percent',
};

export const POSITIVE_VARIATION = {
  INCREASE: 'increase',
  DECREASE: 'decrease',
};

const Stats = (props) => {
  const {
    data,
    isLoading,
    currency: { numberDecimals },
  } = props;

  const buildDisplayedValue = (value, type, unit) => {
    if (type === STATS_DATA_TYPE.UNIT) {
      return `${Number.isInteger(value) ? value : value.toFixed(2)} ${unit}`;
    }

    if (type === STATS_DATA_TYPE.PERCENT) {
      return `${Number.isInteger(value) ? value : value.toFixed(2)}%`;
    }

    return formatShortenCurrencyNumber(value, numberDecimals);
  };

  const getVariation = () => {
    if (!data.oldValue) {
      return null;
    }

    if (data.variationType === STATS_DATA_TYPE.PERCENT && data.type !== STATS_DATA_TYPE.PERCENT) {
      return ((data.value - data.oldValue) / data.oldValue) * 100;
    }

    return data.value - data.oldValue;
  };

  const variation = getVariation();

  const getVariationIcon = () => {
    if (!variation) {
      return data.positiveVariation === POSITIVE_VARIATION.INCREASE
        ? '/images/inpulse/carret-top-dmgrey-small.svg'
        : '/images/inpulse/carret-bottom-dmgrey-small.svg';
    }

    if (variation > 0) {
      return data.positiveVariation === POSITIVE_VARIATION.INCREASE
        ? '/images/inpulse/carret-top-green-small.svg'
        : '/images/inpulse/carret-top-red-small.svg';
    }

    return data.positiveVariation === POSITIVE_VARIATION.INCREASE
      ? '/images/inpulse/carret-bottom-red-small.svg'
      : '/images/inpulse/carret-bottom-green-small.svg';
  };

  const getVariationTextColor = () => {
    if (!variation) {
      return ENUM_COLORS.DARKER;
    }

    if (variation > 0) {
      return data.positiveVariation === POSITIVE_VARIATION.INCREASE
        ? ENUM_COLORS.INFO_GREEN
        : ENUM_COLORS.INFO_RED;
    }

    return data.positiveVariation === POSITIVE_VARIATION.INCREASE
      ? ENUM_COLORS.INFO_RED
      : ENUM_COLORS.INFO_GREEN;
  };

  return (
    <StatContainer>
      <Text
        font={ENUM_FONTS.TITLE_HEIGHT_24_BOLD}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {buildDisplayedValue(data.value, data.type, data.unit)}
      </Text>
      <VariationContainer>
        {isLoading && (
          <LoadingIcon alt={'component-loader'} height="16px" src={SpinnerIcon} width="16px" />
        )}
        {!isLoading && (
          <>
            <VariationIcon src={getVariationIcon()} />
            <Text
              color={getVariationTextColor()}
              font={ENUM_FONTS.TEXT_BIG_MICRO_BOLD}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {!!variation ? buildDisplayedValue(variation, data.variationType, data.unit) : '-'}
            </Text>
          </>
        )}
      </VariationContainer>
    </StatContainer>
  );
};

Stats.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      type: PropTypes.oneOf(Object.values(STATS_DATA_TYPE)),
      positiveVariation: PropTypes.oneOf(Object.values(POSITIVE_VARIATION)),
      oldValue: PropTypes.number,
      variationType: PropTypes.oneOf(Object.values(STATS_DATA_TYPE)),
    }),
  ),
  isLoading: PropTypes.bool,
};

Stats.defaultProps = {
  data: {},
  isLoading: false,
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps)(Stats);
