import styled from 'styled-components';

export const ExpandableListContainer = styled.div`
  font-family: proximanovaregular;
  width: 100%;

  min-width: ${(props) => props.minWidth}px;
`;

export const RowContainer = styled.div`
  overflow: auto;

  height: ${(props) => `${props.height}` || '100%'};
`;

export default { ExpandableListContainer, RowContainer };
