import { get } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { InpulseLabel } from '@commons/DeepsightComponents';

import { LabelContainer } from '../styledComponents';

export const getColumnsSettings = ({ clientStoreNameTranslation }) => [
  {
    id: 'group_name',
    name: i18next.t('GENERAL.NAME'),
    displayName: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
    baseName: 'name',
  },
  {
    id: 'storesCount',
    baseName: 'stores',
    propertyKey: 'stores',
    name: clientStoreNameTranslation,
    displayName: clientStoreNameTranslation,
    excludeFromSearch: true,
    render: (item) => (
      <LabelContainer>
        <InpulseLabel text={String(get(item, 'length', 0))} />
      </LabelContainer>
    ),
  },
];

export default {
  getColumnsSettings,
};
