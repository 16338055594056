import React, { useEffect, useState } from 'react';

import { ListView } from '@commons/utils/styledLibraryComponents';
import EmptyState from '@commons/EmptyState';

import { StoreListViewContainer } from './styledComponents';

const SupplierProfileModalAssociations = (props) => {
  const {
    columns,
    data,
    defaultOrderBy,
    defaultOrderType,
    disableSelection,
    forceEnableSelection,
    emptyStateText,
    setSelectedItems,
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!data || !data.length) {
      return;
    }
    setIsLoading(false);
  }, [data]);

  return (
    <StoreListViewContainer>
      <div>
        <ListView
          columns={columns}
          data={data}
          defaultOrderBy={defaultOrderBy}
          defaultOrderType={defaultOrderType}
          disableFooter={true}
          disableSelection={disableSelection}
          forceEnableSelection={forceEnableSelection}
          isLoading={isLoading}
          padding={'0px'}
          renderEmptyState={() => <EmptyState label={emptyStateText} />}
          setSelectedItems={setSelectedItems}
        />
      </div>
    </StoreListViewContainer>
  );
};

export default SupplierProfileModalAssociations;
