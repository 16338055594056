import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
`;

export const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  position: absolute;
  top: 0px;

  background-color: ${(props) => props.theme.colors.greys.darkest || '#1D1D1B'};
  opacity: 64%;
  overflow: hidden;
  z-index: 10;
`;

export const ModalContainer = styled.div`
  width: 560px;
  display: flex;

  background: ${(props) => props.theme.colors.greys.lightest || '#FFFFFF'};
  border-radius: 8px;
  padding: 0px, 0px, 24px, 0px;

  z-index: 12;
  position: absolute;
`;
