import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px 12px;
  margin-bottom: 24px;
  width: 100%;
  max-width: 816px;

  display: flex;
  flex-direction: column;

  background-color: ${(props) => props.theme.colors.greys.lightest};
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: ${(props) => props.theme.borders.radius.weak};
`;

export const TitleContainer = styled.div`
  font: ${(props) => props.theme.fonts.h2Inter};
  padding-left: 12px;
`;

export const ContentContainer = styled.div`
  width: fit-content;
  height: 100%;

  display: flex;
  flex-wrap: wrap;
`;

export const ContentContainerColumn = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  padding: ${(props) => props.contentsPadding};
`;

export const DatePickerContainer = styled.div`
  white-space: nowrap;

  width: 100%;
  height: 40px;

  cursor: pointer;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;

  background-color: #ffffff;
  border: ${(props) =>
    props.focusedDateInput
      ? props.theme.inputs.primary.focus.border
      : props.theme.inputs.primary.default.border};
  border-radius: ${(props) => props.theme.inputs.primary.default.borderRadius};
  box-shadow: none;
`;

export const InputContainer = styled.div`
  width: 240px;

  margin: 12px;
`;

export const CheckDropDownContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 240px;

  margin: 12px;
`;

export const WildCard = styled.span`
  color: ${(props) =>
    props.disabled ? props.theme.colors.greys.dark : props.theme.colors.infoRed};
`;

export const LabelContainer = styled.div`
  font: ${(props) => props.theme.fonts.textSmall};

  margin-bottom: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${(props) => (props.disabled ? props.theme.colors.greys.dark : '')};
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;

  width: 240px;

  margin: 40px 12px 12px 12px;
`;

export const SwitchText = styled.div`
  font: ${(props) => props.theme.fonts.textSmall};
  margin-left: 8px;
`;

export const ErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.infoRed};
  font: ${(props) => props.theme.fonts.textSmallHeight16};
  margin-top: 8px;
`;

export const TextContent = styled.div`
  color: ${(props) => props.theme.colors.greys.darker};
  font: normal 500 14px/16px inter;
`;

export const TextContainer = styled.div`
  margin: 12px;
  margin-top: 24px;
`;

export const ButtonContainer = styled.div`
  margin-left: 12px;
`;

export const CheckboxContainer = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font: ${(props) => props.theme.fonts.textMiddleNormal};
`;

export default {
  Container,
  TitleContainer,
  ContentContainer,
  ContentContainerColumn,
  CheckDropDownContainer,
  LabelContainer,
  WildCard,
  SwitchContainer,
  SwitchText,
  ErrorMessage,
  TextContent,
  TextContainer,
  ButtonContainer,
  CheckboxContainer,
};
