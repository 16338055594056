import { APIcaller } from '@services/APIcaller';

const getGroupsOfStores = (storeIds) =>
  APIcaller.apiPost('/admin/stores/groups', {
    storeIds: JSON.stringify(storeIds),
  });

const associateStoreGroupMapping = (storeIds, groupIds) =>
  APIcaller.apiPost('/admin/stores/store-group-mappings', {
    storeIds,
    groupIds,
  });

const dissociateStoreGroupMapping = (storeIds, groupIds) =>
  APIcaller.apiDelete('/admin/stores/store-group-mappings', {
    storeIds,
    groupIds,
  });

export const group = {
  getGroupsOfStores,
  associateStoreGroupMapping,
  dissociateStoreGroupMapping,
};
