import { connect } from 'react-redux';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { Button, ToggleButton, Tooltip } from '@commons/utils/styledLibraryComponents';
import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { getFormattedCurrencyName } from '@commons/utils/translations';
import DisplayNumber from '@commons/DisplayNumber';
import FiltersModal from '@commons/DeepsightAnalyticsHeaders/components/DeepsightFiltersButton/components/FiltersModal';

import RangeDatePicker from '../RangeDatePicker';

import {
  FiltersContainer,
  IconActive,
  LittleBoxContainer,
  InfoBoxLabel,
  ButtonContainer,
} from './styledComponents';

function DeepsightFiltersButton(props) {
  const {
    currency,
    readOnly,
    disableExport,
    textFilterButton,
    advancedFilters,
    turnOver,
    lossRate,
    isLoading,
    handleExport,
    handleExportByStore,
    selectedStores,
    handleSelectedDates,
    startDate,
    endDate,
    setStartMonth,
    setEndMonth,
    formattedInventoryStockDay,
    setFocusedDateInput,
    focusedDateInput,
    metrics,
    selectedMetric,
    setSelectedMetric,
  } = props;

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);

  const actions = [
    {
      isDisabled: readOnly || isLoading || disableExport,
      label: i18next.t('ORDERS.BY_CATEGORY.EXPORT_ACTION'),
      icon: `/images/inpulse/file-download-black-small.svg`,
      handleClick: handleExport,
    },
    {
      isDisabled: readOnly || isLoading || disableExport,
      label: i18next.t('ORDERS.BY_CATEGORY.EXPORT_BY_STORE_ACTION'),
      icon: `/images/inpulse/file-download-black-small.svg`,
      handleClick: handleExportByStore,
    },
  ];

  useEffect(() => {
    if (
      !advancedFilters ||
      (advancedFilters && !advancedFilters.length) ||
      !advancedFilters.some((filter) => filter.display)
    ) {
      setIsFilterActive(false);
    }
  }, [advancedFilters]);

  return (
    <FiltersContainer>
      <Button
        color="inpulse-filter"
        fontSize={14}
        formatText={false}
        handleClick={() => {
          if (readOnly) {
            return;
          }

          setFiltersModalOpen(!filtersModalOpen);
        }}
        height={40}
        icon={'/images/inpulse/filter-black-small.svg'}
        isActive={filtersModalOpen}
        isDisabled={readOnly}
        label={textFilterButton}
        maxWidth={240}
        minWidth={240}
      />
      {isFilterActive && (
        <IconActive
          alt="icon-filter-active"
          readOnly={readOnly}
          src={
            readOnly
              ? '/images/icon-dot-active-grey-inpulse.svg'
              : '/images/icon-dot-active-green-inpulse.svg'
          }
        />
      )}
      {filtersModalOpen && (
        <FiltersModal
          params={props}
          setFiltersModalOpen={setFiltersModalOpen}
          setIsFilterActive={setIsFilterActive}
        />
      )}

      <RangeDatePicker
        endDate={endDate}
        focusedDateInput={focusedDateInput}
        formattedInventoryStockDay={formattedInventoryStockDay}
        handleSelectedDates={handleSelectedDates}
        readOnly={readOnly}
        setEndMonth={setEndMonth}
        setFocusedDateInput={setFocusedDateInput}
        setStartMonth={setStartMonth}
        singleStore={selectedStores.length === 1}
        startDate={startDate}
      />

      <ToggleButton
        choices={metrics}
        isDisabled={false}
        selectedChoice={selectedMetric}
        setSelectedChoice={setSelectedMetric}
      />

      <LittleBoxContainer>
        <InfoBoxLabel>
          {i18next.t('GENERAL.TURNOVER_EX_TAX')}
          <Tooltip
            text={i18next.t('GENERAL.METRIC_TURNOVER_EX_TAX_TOOLTIP', {
              currencyName: getFormattedCurrencyName(currency.shortenedName, true),
            })}
          />
        </InfoBoxLabel>

        <div className="kpi">
          {isLoading ? (
            <DeepsightComponentLoader height={16} width={16} />
          ) : (
            <DisplayNumber
              currency={currency}
              number={turnOver}
              displayCurrencyCode
              withoutDecimals
            />
          )}
        </div>
      </LittleBoxContainer>

      <LittleBoxContainer>
        <InfoBoxLabel>{i18next.t('ANALYSIS.FOOD_COST.LOSS_RATE')}</InfoBoxLabel>

        <div className="kpi">
          {isLoading ? <DeepsightComponentLoader height={16} width={16} /> : `${lossRate}%`}
        </div>
      </LittleBoxContainer>

      <ButtonContainer style={{ display: 'flex', flex: 1, justifyContent: 'end' }}>
        <Button
          actions={actions}
          color={'inpulse-outline'}
          iconDropdown={'/images/inpulse/carret-black.svg'}
          iconOnLeft={false}
          label={i18next.t('GENERAL.ACTIONS')}
        />
      </ButtonContainer>
    </FiltersContainer>
  );
}

DeepsightFiltersButton.propTypes = {
  readOnly: PropTypes.bool,
  disableExport: PropTypes.bool,
  textFilterButton: PropTypes.string.isRequired,
};

DeepsightFiltersButton.defaultProps = {
  readOnly: false,
  disableExport: false,
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps)(DeepsightFiltersButton);
