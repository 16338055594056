import i18next from 'i18next';
import React from 'react';

import { getTheme } from '@commons/utils/theme';
import { InpulseLabel } from '@commons/DeepsightComponents';
import { Label } from '@commons/utils/styledLibraryComponents';

import { LabelContainer, SupplierLabelRender, Text } from './styledComponents';

export const getStoreColumnConfig = ({ hasMultipleBrands }) => {
  const theme = getTheme();

  return [
    {
      id: 'store_name',
      name: i18next.t('GENERAL.NAME'),
      displayName: i18next.t('GENERAL.NAME'),
      propertyKey: 'name',
      baseName: 'name',
      render: (it) => <Text>{it}</Text>,
    },
    ...(hasMultipleBrands
      ? [
          {
            id: 'brand_name',
            name: i18next.t('GENERAL.BRAND'),
            displayName: i18next.t('GENERAL.BRAND'),
            propertyKey: 'brandName',
            baseName: 'brandName',
            render: (it) => <Text>{it}</Text>,
          },
        ]
      : []),
    {
      id: 'storeType',
      baseName: 'isKitchen',
      propertyKey: 'isKitchen',
      name: i18next.t('GENERAL.STORE_TYPE'),
      displayName: i18next.t('GENERAL.STORE_TYPE'),
      render: (isKitchen) => (
        <Text>{isKitchen ? i18next.t('GENERAL.CENTRAL') : i18next.t('GENERAL.SALE_POINT')}</Text>
      ),
    },
    {
      id: 'identifier',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_IDENTIFIER'),
      displayName: i18next.t('ORDERS.ORDERS.LIST_LABEL_IDENTIFIER'),
      propertyKey: 'partnerId',
      baseName: 'partnerId',
    },
    {
      id: 'status',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_STATUS'),
      displayName: i18next.t('ORDERS.ORDERS.LIST_LABEL_STATUS'),
      propertyKey: 'active',
      baseName: 'active',
      excludeFromSearch: true,
      list: [
        {
          id: 'active',
          value: true,
          active: i18next.t('GENERAL.ACTIVE'),
          name: i18next.t('GENERAL.ACTIVE'),
          render: () => (
            <SupplierLabelRender>
              <InpulseLabel color="green" text={i18next.t('GENERAL.ACTIVE')} />
            </SupplierLabelRender>
          ),
        },
        {
          id: 'inactive',
          value: false,
          active: i18next.t('GENERAL.INACTIVE'),
          name: i18next.t('GENERAL.INACTIVE'),
          excludeFromSearch: true,
          render: () => (
            <SupplierLabelRender>
              <InpulseLabel color="red" text={i18next.t('GENERAL.INACTIVE')} />
            </SupplierLabelRender>
          ),
        },
      ],
      render: (item) => (
        <LabelContainer>
          <Label
            background={item ? theme.colors.brand.primary : theme.colors.infoRed}
            children={item ? i18next.t('GENERAL.ACTIVE') : i18next.t('GENERAL.INACTIVE')}
            color={item ? theme.colors.brand.primary : theme.colors.infoRed}
            type={'secondary'}
          />
        </LabelContainer>
      ),
    },
  ];
};

export default {
  getStoreColumnConfig,
};
