import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  border-radius: ${(props) => props.theme.borders?.radius.weak || '4px'};
  background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
  box-sizing: border-box;
`;

export const ToggleSection = styled.div`
  width: 90px;
  padding: 10px;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.greys.light};

  > span {
    width: 100%;
    text-align: center;
    color: ${(props) => props.theme.colors?.greys.darker || '#6c778d'};

    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.textSmall};`
        : `font-size: 14px;
    line-height: 16px;
    font-weight: normal;`}
  }

  &.active {
    background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
    border: ${(props) => `1px solid ${props.theme.colors?.greys.light || '#0a61fe'}`};

    > span {
      color: ${(props) => props.theme.colors?.greys.darkest || '#0a3eff'};
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:first-child {
    border-top-left-radius: ${(props) => props.theme.borders?.radius.weak || '4px'};
    border-bottom-left-radius: ${(props) => props.theme.borders?.radius.weak || '4px'};
  }

  &:last-child {
    border-top-right-radius: ${(props) => props.theme.borders?.radius.weak || '4px'};
    border-bottom-right-radius: ${(props) => props.theme.borders?.radius.weak || '4px'};
  }
`;
