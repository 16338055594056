import moment from 'moment';
import React from 'react';

import { Circle, Date, DateContainer, CircleContainer } from './styledComponents';

const CircleTodayDay = (props) => {
  const { item, isToday } = props;

  return (
    <>
      <DateContainer>
        <CircleContainer isToday={isToday}>
          <Circle isToday={isToday}>
            <Date isToday={isToday}>{moment(item).format('D')}</Date>
          </Circle>
        </CircleContainer>
        <Date isToday={!isToday}>{moment(item).format('D')}</Date>
      </DateContainer>
    </>
  );
};

export default CircleTodayDay;
