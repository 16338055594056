import * as yup from 'yup';
import i18next from 'i18next';
import React from 'react';

import { CHANNELS, CHANNEL_ID_KEY_BY_NAME } from '@commons/constants/channel';
import { INPUT_TYPES } from '@commons/constants/inputType';
import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import DisplayNumber, { formatNumber } from '@commons/DisplayNumber';

export const RECIPES_COMPONENTS_FIELD_ARRAY_NAME = 'recipeComponents';

export const RECIPE_COMPONENTS_INPUTS = {
  NAME: 'name',
  CATEGORY: 'category',
  QUANTITY: 'quantity',
  UNIT: 'unit',
  COST: 'cost',
  ONSITE: 'onSite',
  DELIVERY: 'delivery',
};

export const RECIPE_COMPONENTS_FORM_INPUTS = (
  input,
  clientDefaultChannelId,
  currency,
  hasMultipleChannels,
) => {
  switch (input) {
    case RECIPE_COMPONENTS_INPUTS.NAME:
      return {
        type: INPUT_TYPES.PLAIN_TEXT,
        name: RECIPE_COMPONENTS_INPUTS.NAME,
        width: INPUT_WIDTH.LARGE,
      };
    case RECIPE_COMPONENTS_INPUTS.CATEGORY:
      return {
        type: INPUT_TYPES.PLAIN_TEXT,
        name: RECIPE_COMPONENTS_INPUTS.CATEGORY,
        width: INPUT_WIDTH.MEDIUM,
      };
    case RECIPE_COMPONENTS_INPUTS.QUANTITY:
      return {
        type: INPUT_TYPES.NUMBER,
        name: RECIPE_COMPONENTS_INPUTS.QUANTITY,
        width: INPUT_WIDTH.MEDIUM,
        limitValues: { min: -Number.MAX_VALUE },
        defaultValue: 0,
        rule: yup
          .number()
          .test(
            'notEqualToZero',
            i18next.t('ADMIN.RECIPES.FORM_FIELD_NOT_EQUAL_TO_ERROR', {
              field: i18next.t('GENERAL.QUANTITY'),
              value: 0,
            }),
            (value) => value !== 0,
          )
          .required(
            i18next.t('ADMIN.RECIPES.FORM_FIELD_REQUIRED_ERROR', {
              field: i18next.t('GENERAL.QUANTITY'),
            }),
          ),
      };
    case RECIPE_COMPONENTS_INPUTS.UNIT:
      return {
        type: INPUT_TYPES.PLAIN_TEXT,
        name: RECIPE_COMPONENTS_INPUTS.UNIT,
        width: INPUT_WIDTH.SMALL,
      };
    case RECIPE_COMPONENTS_INPUTS.COST:
      return {
        type: INPUT_TYPES.CURRENCY,
        name: RECIPE_COMPONENTS_INPUTS.COST,
        defaultValue: '-',
        width: INPUT_WIDTH.MEDIUM,
        computeValue: (currentRow) => {
          if (currentRow.isIngredient) {
            const totalCost = currentRow.cost * currentRow.quantity;
            return <DisplayNumber number={totalCost || null} />;
          }

          if (!hasMultipleChannels || !currentRow.hasMultipleChannels) {
            const totalCost =
              currentRow.costByChannelId[clientDefaultChannelId] * currentRow.quantity;
            return <DisplayNumber number={totalCost || null} />;
          }

          const totalCost = `${formatNumber(
            currentRow.costByChannelId[CHANNEL_ID_KEY_BY_NAME[CHANNELS.ON_SITE]] *
              currentRow.quantity,
            currency.numberDecimals,
          )} | ${formatNumber(
            currentRow.costByChannelId[CHANNEL_ID_KEY_BY_NAME[CHANNELS.DELIVERY]] *
              currentRow.quantity,
            currency.numberDecimals,
          )}`;
          return <>{totalCost}</>;
        },
      };
    case RECIPE_COMPONENTS_INPUTS.ONSITE:
      return {
        type: INPUT_TYPES.CHECKBOX,
        name: RECIPE_COMPONENTS_INPUTS.ONSITE,
        width: INPUT_WIDTH.SMALL,
        isDisabled: ({ delivery }) => !delivery,
      };
    case RECIPE_COMPONENTS_INPUTS.DELIVERY:
      return {
        type: INPUT_TYPES.CHECKBOX,
        name: RECIPE_COMPONENTS_INPUTS.DELIVERY,
        width: INPUT_WIDTH.SMALL,
        isDisabled: ({ onSite }) => !onSite,
      };
    default:
      return {};
  }
};
