import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { inventoryList } from '@services/inventoryList';

export const TheoreticalInventoryProgressBar = (props) => {
  const { closeModal, params } = props;

  const [isLoading, setLoading] = useState(true);
  const [filters, setFilters] = useState(null);
  const [createdInventoryListCount, setCreatedInventoryListCount] = useState(null);
  const [totalInventoryListToBeCreated] = useState(params?.storeIds.length);

  // Progress modal
  const [progress, setProgress] = useState(0);

  const exitModal = () => {
    setLoading(false);
    closeModal();
  };

  const updateProgress = (totalCreated) => {
    const inventoryListLeft = totalInventoryListToBeCreated - totalCreated;
    const updatedProgress = 100 - (inventoryListLeft / totalInventoryListToBeCreated) * 100;

    setProgress(updatedProgress);
  };

  const createTheoreticalInventory = async (storeId) => {
    const { selectedDate, inventoryListTemplateId, userTimezone, showErrorMessage } = filters;

    try {
      await inventoryList.createTheoreticalInventories(
        [storeId],
        selectedDate,
        inventoryListTemplateId,
        userTimezone,
      );

      const createdCount = createdInventoryListCount + 1;

      setCreatedInventoryListCount(createdCount);

      updateProgress(createdCount);
    } catch {
      exitModal();
      showErrorMessage(i18next.t('STOCKS.STOCKS.THEORETICAL_INVENTORY_CREATION_ERROR'));
    }
  };

  useEffect(() => {
    if (!params) {
      return;
    }

    setCreatedInventoryListCount(0);

    setFilters(params);
  }, [params]);

  useEffect(() => {
    if (!filters) {
      return;
    }

    const { storeIds, reloadInventories, showSuccessMessage } = filters;

    if (isLoading && createdInventoryListCount < totalInventoryListToBeCreated) {
      const nextStoreId = storeIds[createdInventoryListCount];
      createTheoreticalInventory(nextStoreId);
      return;
    }

    exitModal();
    reloadInventories();
    showSuccessMessage(i18next.t('STOCKS.STOCKS.THEORETICAL_INVENTORY_CREATION_SUCCESS'));
  }, [createdInventoryListCount]);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setLoading}
      titleModal={i18next.t('STOCKS.STOCKS.THEORETICAL_INVENTORY_PROGRESS_BAR_TITLE')}
    />
  );
};

export default TheoreticalInventoryProgressBar;
