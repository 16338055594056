import i18next from 'i18next';
import React from 'react';

import AnalyticsList from '@commons/AnalyticsList';
import DisplayNumber, { formatNumber } from '@commons/DisplayNumber';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import TooltipButton from '@commons/TooltipButton';

import { tooltipTextsAnalyticsStore } from '@orders/OrderAnalyticsModulesContainer/constants/tooltipText';

import { TextWithTooltipButtonContainer } from '../styledComponents';

import { formatDataForTooltip } from '@orders/OrderAnalyticsModulesContainer/OrderAnalyticsStore/utils/columns';

export const getColumns = (currency = {}, startDate, endDate) => [
  {
    id: 1,
    propertyKey: 'storeName',
    type: 'string',
    // 16px of padding + 160px for the width
    minWidth: '176',
    name: i18next.t('GENERAL.NAME'),
    isSortable: true,
  },
  {
    id: 2,
    decimals: 0,
    propertyKey: 'turnover',
    type: 'currency',
    filterType: 'numeric',
    // 16px of padding + 160px for the width
    minWidth: '176',
    name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_REVENUE'),
    tooltipDisplay: i18next.t(tooltipTextsAnalyticsStore.turnover),
    isSortable: true,
    render: (item) => (
      <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
    ),
  },
  {
    id: 3,
    decimals: 0,
    propertyKey: 'purchase',
    type: 'currency',
    filterType: 'numeric',
    // 16px of padding + 160px for the width
    minWidth: '176',
    name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_ACTUAL_FC'),
    tooltipDisplay: i18next.t(tooltipTextsAnalyticsStore.order),
    isSortable: true,
    render: (item, row) => {
      const dataForTooltip = formatDataForTooltip(row, currency, startDate, endDate);

      // no need to check on orderTotal, transferTotal or purchase because they're all the sum of the following variables
      const isButtonDisabled =
        !row.order && !row.rushOrder && !row.transferIn && !row.transferOut && !row.stockVariation;

      return (
        <TextWithTooltipButtonContainer>
          <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
          <TooltipButton
            isDisabled={isButtonDisabled}
            tooltipContent={<AnalyticsList data={dataForTooltip} title={row.storeName} />}
          />
        </TextWithTooltipButtonContainer>
      );
    },
  },
  {
    id: 4,
    decimals: 0,
    propertyKey: 'orderOverTurnoverRatio',
    type: 'percentage',
    // 16px of padding + 160px for the width
    minWidth: '176',
    name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_PERCENT_ACTUAL_FC'),
    filterType: 'numeric',
    tooltipDisplay: i18next.t(tooltipTextsAnalyticsStore.orderOverTurnoverRatio),
    isSortable: true,
    render: (item) => {
      const valueToDisplay = item == null ? '-' : `${formatNumber(item, 2)}%`;

      return (
        <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}>
          {valueToDisplay}
        </Text>
      );
    },
  },
  {
    id: 5,
    decimals: 0,
    propertyKey: 'grossSalary',
    type: 'currency',
    // 16px of padding + 160px for the width
    minWidth: '176',
    name: i18next.t('ANALYSIS.MARGIN.GROSS_SALARY_COLUMN_NAME'),
    filterType: 'numeric',
    tooltipDisplay: i18next.t(tooltipTextsAnalyticsStore.grossSalary),
    isSortable: true,
    render: (item) => (
      <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
    ),
  },
  {
    id: 6,
    decimals: 0,
    propertyKey: 'grossSalaryPercentage',
    type: 'percentage',
    // 16px of padding + 160px for the width
    minWidth: '176',
    name: i18next.t('ANALYSIS.MARGIN.GROSS_SALARY_OVER_TURNOVER_PERCENTAGE_COLUMN_NAME'),
    filterType: 'numeric',
    tooltipDisplay: i18next.t(tooltipTextsAnalyticsStore.grossSalaryPercentage),
    isSortable: true,
    render: (item) => {
      const valueToDisplay = item == null ? '-' : `${formatNumber(item, 2)}%`;

      return (
        <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}>
          {valueToDisplay}
        </Text>
      );
    },
  },
  {
    id: 7,
    decimals: 0,
    propertyKey: 'grossMargin',
    type: 'currency',
    // 16px of padding + 160px for the width
    minWidth: '176',
    name: i18next.t('ANALYSIS.MARGIN.GROSS_MARGIN_COLUMN_NAME'),
    filterType: 'numeric',
    tooltipDisplay: i18next.t(tooltipTextsAnalyticsStore.grossMargin),
    isSortable: true,
    render: (item) => (
      <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
    ),
  },
  {
    id: 8,
    decimals: 0,
    propertyKey: 'grossMarginOverTurnoverPercentage',
    type: 'percentage',
    // 16px of padding + 160px for the width
    minWidth: '176',
    name: i18next.t('ANALYSIS.MARGIN.GROSS_MARGIN_OVER_TURNOVER_PERCENTAGE_COLUMN_NAME'),
    filterType: 'numeric',
    tooltipDisplay: i18next.t(tooltipTextsAnalyticsStore.grossMarginOverTurnoverPercentage),
    isSortable: true,
    render: (item) => {
      const valueToDisplay = item == null ? '-' : `${formatNumber(item, 2)}%`;

      return (
        <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}>
          {valueToDisplay}
        </Text>
      );
    },
  },
];
