import i18next from 'i18next';

export const getListColumns = ({ storeName }) => [
  {
    propertyKey: 'name',
    label: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_DESIGNATION'),
    displayImage: true,
    flex: 1,
  },
  {
    propertyKey: 'quantity',
    label: i18next.t('PRODUCTION.PRODUCTION.RECOMMENDATION'),
    flex: 1,
    tooltip: i18next.t('PRODUCTION.PRODUCTION.RECOMMENDATION_TOOLTIP', { storeName }),
  },
  {
    propertyKey: 'unit',
    label: i18next.t('GENERAL.UNIT'),
    flex: 1,
  },
];
