import styled from 'styled-components';

export const Container = styled.div`
  // 64px represents the width of the sidebar
  width: calc(100% - 64px);
  height: 40px;

  // Modals z-index is 5 and we want it to appear behind them
  z-index: 4;
  position: absolute;

  bottom: 56px;
  right: 0;

  display: flex;
  justify-content: center;
`;

export const BannerContainer = styled.div`
  width: 1280px;
  height: 100%;

  border-radius 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${(props) => props.backgroundColor};
`;
