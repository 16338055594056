/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import moment from 'moment';

export const getValueGraphPosition = (
  index: number,
  arrayY: Array<{ value: number }>
): string => {
  let position = '0';

  try {
    if (index === 0) {
      if (arrayY.length > 1 && arrayY[1].value !== null) {
        position = '-2px';
      }
    }
    if (index === 1) {
      if ((_.head(arrayY) || {}).value !== null) {
        position = '2px';
      }
    }
  } catch (error) {
    console.error(`getValueGraphPosition: ${error}`);
  }

  return position;
};

export const graphsTransfrom = (
  datas: any,
  tooltipData: any,
  configurationGraph: any
): Array<any> => {
  const result: any[] = [];

  try {
    // Using forEach instead of for loop to avoid error in the console for date not found as she already set.
    datas.forEach((data) => {
      const date = moment(data.date);
      const formattedDate = moment(data.date).format('YYYY-MM-DD');
      const lossFound = _.find(tooltipData.loss, {
        inventoryDate: formattedDate,
      });

      const orderFound = _.reduce(
        tooltipData.order,
        (res: null | any, order) => {
          if (order.startOrderDate === formattedDate) {
            if (!res) {
              return order;
            }

            return {
              ...order,
              totUnit: res.totUnit + order.totUnit,
              totTurnover: res.totTurnover + order.totTurnover,
            };
          }

          return res;
        },
        null
      );

      const legends = _.get(configurationGraph, 'legends');

      const y = _.map(legends, (legend) => {
        const { propertyKey, color } = legend;

        const value =
          _.get(data, propertyKey) === null
            ? null
            : Number(_.get(data, propertyKey));

        return { value, color };
      });

      const consumption = tooltipData.consumption
        ? tooltipData.consumption[formattedDate] || {
            totUnit: null,
            totTurnover: null,
          }
        : {
            totUnit: null,
            totTurnover: null,
          };

      const loss = lossFound || {
        inventoryDate: formattedDate,
        totUnit: null,
        totTurnover: null,
      };

      const order = orderFound || {};

      result.push({ x: date, y: { stock: y, loss, consumption, order } });
    });
  } catch (error) {
    console.error(`graphsTransfrom: ${error}`);
  }

  return result;
};

export const getMaxValue = (data: any): number => {
  const getAllMaxValue = data.map((item) => {
    const max: any =
      _.maxBy(item.y.stock, (currentStock) => {
        if (
          !!currentStock.overlappedStocks &&
          currentStock.overlappedStocks.length
        ) {
          return Math.max(
            ...currentStock.overlappedStocks.map(({ value }) => value)
          );
        }

        return currentStock.value;
      }) || {};

    const formattedMax = !max.overlappedStocks
      ? max
      : {
          value: Math.max(...max.overlappedStocks.map(({ value }) => value)),
        };

    return !!formattedMax && formattedMax.value > 0
      ? Math.round(formattedMax.value)
      : 0;
  });

  return Math.max(...getAllMaxValue);
};

export default {
  getValueGraphPosition,
  graphsTransfrom,
  getMaxValue,
};
