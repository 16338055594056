// Based on PreStepChoiceToProceed, should be improved later

import i18next from 'i18next';
import React, { useEffect, useRef } from 'react';

import { RadioButton } from '@commons/utils/styledLibraryComponents';

import {
  Container,
  Content,
  SubText,
  MainText,
  TextContainer,
  EmptyStateIcon,
  ButtonContainer,
  RadioSelectButton,
} from './styledComponents';

const IncludeNextStepModal = (props) => {
  const { title, text, iconSrc, includeNextStep, setIncludeNextStep } = props;

  const yesChoiceRef = useRef(null);
  const noChoiceRef = useRef(null);

  useEffect(() => {
    includeNextStep ? yesChoiceRef.current.focus() : noChoiceRef.current.focus();
  }, [includeNextStep]);

  return (
    <Container>
      <Content>
        <TextContainer>
          <MainText>{title}</MainText>
          <SubText>{text}</SubText>
        </TextContainer>

        <EmptyStateIcon alt="icon-association" src={iconSrc} />

        <ButtonContainer>
          <RadioSelectButton ref={yesChoiceRef} onClick={() => setIncludeNextStep(true)}>
            <RadioButton selected={includeNextStep} size="small" value={true} />
            <div>{i18next.t('GENERAL.ASK_YES_NOW')}</div>
          </RadioSelectButton>
          <RadioSelectButton ref={noChoiceRef} onClick={() => setIncludeNextStep(false)}>
            <RadioButton selected={includeNextStep} size="small" value={false} />
            <div>{i18next.t('GENERAL.ASK_NO_LATER')}</div>
          </RadioSelectButton>
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default IncludeNextStepModal;
