import { connect } from 'react-redux';
import { first } from 'lodash';
import i18next from 'i18next';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import DisplayImgInFullScreen from '@commons/DisplayImgInFullScreen';
import Text, { ENUM_FONTS, ENUM_COLORS } from '@commons/Text';

import theme from '@theme';

import utils from './utils/files';

import {
  Icon,
  Action,
  Container,
  IconAction,
  ContainerText,
  ContainerPicture,
  ActionsContainer,
  IconOpacityEffect,
  SubTitleIcon,
  SubTitleContainer,
} from './styledComponents';

function HeaderPicture({
  title,
  subTitle,
  subTitleIcon,
  picture,
  onPictureChange,
  isDefaultThumbnail,
  setSelectedFile,
  readOnly,
  // Dispatched props
  showErrorMessage,
}) {
  // To allow trigger click on input without displaying it
  const selectFileInputRef = useRef(null);

  const [shouldDisplayActions, setShouldDisplayActions] = useState(false);

  const [shouldPictureFullScreen, setShouldPictureFullScreen] = useState({ state: false });

  // Configuration to close actions dropdown on component click-outside event
  HeaderPicture.handleClickOutside = () => setShouldDisplayActions(false);

  /**
   * Handle callback to trigger the display of actions dropdown
   */
  const triggerDropdown = () => {
    setShouldDisplayActions(!shouldDisplayActions);
  };

  /**
   * Handle callback to display picture as fullscreen
   */
  const displayPictureFullScreen = () => {
    if (isDefaultThumbnail) {
      return;
    }

    setShouldPictureFullScreen({ state: true, img: picture });
  };

  /**
   * Handle callback to delete picture
   */
  const deletePicture = () => {
    if (isDefaultThumbnail) {
      return;
    }

    setSelectedFile(null);

    onPictureChange(null);
  };

  /**
   * Handle callback to update picture
   */
  const changePicture = () => {
    if (!selectFileInputRef || !selectFileInputRef.current) {
      return;
    }

    selectFileInputRef.current.click();
  };

  /**
   * Handle callback when file has been selected
   *
   * @param {Event} event - The event associated to the file input
   */
  const handleSelectedPicture = async (event) => {
    const file = first(event.target.files);

    if (!file) {
      return;
    }

    try {
      // TODO : Optimisation CDN
      // const compressedFile = await utils.compress(file);

      const loadedPicture = await utils.load(file);

      onPictureChange(loadedPicture.data);
      setSelectedFile(loadedPicture.file);
    } catch (error) {
      showErrorMessage(i18next.t('ORDERS.ORDERS.FORM_COMPRESSION_ERROR'), 'error');
    }
  };

  /**
   * Handle render of dropdown actions
   */
  const renderDropdownActions = () => {
    if (!shouldDisplayActions) {
      return <></>;
    }

    return (
      <ActionsContainer>
        <Action disabled={isDefaultThumbnail || readOnly} onClick={displayPictureFullScreen}>
          <IconAction
            alt="open-picture"
            src={`/images/inpulse/open-in-fullscreen-${
              isDefaultThumbnail || readOnly ? 'lmgrey' : 'black'
            }.svg`}
          />
          <Text color={isDefaultThumbnail || readOnly ? ENUM_COLORS.LIGHT : ENUM_COLORS.DARKEST}>
            {i18next.t('GENERAL.SEE_PICTURE')}
          </Text>
        </Action>
        <Action disabled={readOnly} onClick={changePicture}>
          <IconAction
            alt="delete-picture"
            src={`/images/inpulse/add-a-photo-${readOnly ? 'lmgrey' : 'black'}.svg`}
          />
          <Text color={readOnly ? ENUM_COLORS.LIGHT : ENUM_COLORS.DARKEST}>
            {i18next.t('GENERAL.REPLACE_PICTURE')}
          </Text>
        </Action>
        <Action disabled={isDefaultThumbnail || readOnly} onClick={deletePicture}>
          <IconAction
            alt="delete-picture"
            src={`/images/inpulse/delete-${
              isDefaultThumbnail || readOnly ? 'lmgrey' : 'black'
            }-small.svg`}
          />
          <Text color={isDefaultThumbnail || readOnly ? ENUM_COLORS.LIGHT : ENUM_COLORS.DARKEST}>
            {i18next.t('GENERAL.DELETE_PICTURE')}
          </Text>
        </Action>
      </ActionsContainer>
    );
  };

  /**
   * Handle render picture as fullscreen
   */
  const renderPictureFullscreen = () => {
    if (!shouldPictureFullScreen.state) {
      return <></>;
    }

    return (
      <DisplayImgInFullScreen
        image={shouldPictureFullScreen.img}
        setDisplayProductPicture={setShouldPictureFullScreen}
      />
    );
  };

  return (
    <Container>
      {/* Input file has to be display here so onChange callback works properly */}
      <input
        accept="image/*"
        id="select-file-input"
        ref={selectFileInputRef}
        type="file"
        onClick={(event) => (event.target.value = null)}
        onInput={handleSelectedPicture}
      />
      <ContainerPicture onClick={triggerDropdown}>
        <Icon picture={picture}>
          <IconOpacityEffect />
          {renderDropdownActions()}
        </Icon>
      </ContainerPicture>
      <ContainerText>
        <Text font={ENUM_FONTS.H1_INTER}>{title}</Text>
        {!!subTitle && !!subTitleIcon && (
          <SubTitleContainer>
            <SubTitleIcon color={theme.colors.highlightedBlue} icon={subTitleIcon} />
            <Text font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}>{subTitle}</Text>
          </SubTitleContainer>
        )}
      </ContainerText>
      {renderPictureFullscreen()}
    </Container>
  );
}

HeaderPicture.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleIcon: PropTypes.string,
  isDefaultThumbnail: PropTypes.bool,
  onPictureChange: PropTypes.func.isRequired,
};

HeaderPicture.defaultProps = {
  img: '',
  title: '',
  subTitle: '',
  subTitleIcon: '',
  isDefaultThumbnail: false,
};

const clickOutsideConfig = {
  handleClickOutside: () => HeaderPicture.handleClickOutside,
};

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(null, mapDispatchToProps)(onClickOutside(HeaderPicture, clickOutsideConfig));
