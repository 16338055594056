import i18next from 'i18next';

/**
 * Return a translated vers of the availble selector filters
 *
 * @returns {SelectorFilter[]} The list of selector filter
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getSelectorFilters(): { [key: string]: any } {
  return {
    numeric: {
      content: [
        {
          id: 'inferior',
          name: i18next.t('COMPONENT_VALUE_SELECTOR_FILTER_LOWER_THAN'),
          type: 'single',
          doFilter: (
            data: unknown[],
            propertyKey: string,
            value: number | string
          ): unknown[] =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            data.filter((item: any) => {
              if (!Object.prototype.hasOwnProperty.call(item, propertyKey)) {
                return 0;
              }

              const parsedItem = parseInt(item[propertyKey], 10);

              if (Number.isNaN(parsedItem)) {
                return 0;
              }

              return parsedItem < value;
            }),
        },
        {
          id: 'superior',
          name: i18next.t('COMPONENT_VALUE_SELECTOR_FILTER_GREATER_THAN'),
          type: 'single',
          doFilter: (
            data: unknown[],
            propertyKey: string,
            value: number | string
          ): unknown[] =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            data.filter((item: any) => {
              if (!Object.prototype.hasOwnProperty.call(item, propertyKey)) {
                return 0;
              }

              const parsedItem = parseInt(item[propertyKey], 10);

              if (Number.isNaN(parsedItem)) {
                return 0;
              }

              return parsedItem > value;
            }),
        },
        {
          id: 'equal',
          name: i18next.t('COMPONENT_VALUE_SELECTOR_FILTER_EQUAL_TO'),
          type: 'single',
          doFilter: (
            data: unknown[],
            propertyKey: string,
            value: number | string
          ): unknown[] =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            data.filter((item: any) => {
              if (!Object.prototype.hasOwnProperty.call(item, propertyKey)) {
                return 0;
              }

              const parsedItem = parseInt(item[propertyKey], 10);

              if (Number.isNaN(parsedItem)) {
                return 0;
              }

              return parsedItem === value;
            }),
        },
        {
          id: 'between',
          name: i18next.t('COMPONENT_VALUE_SELECTOR_FILTER_BETWEEN'),
          type: 'multiple',
          doFilter: (
            data: unknown[],
            propertyKey: string,
            values: { minValue: number; maxValue: number }
          ): unknown[] =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            data.filter((item: any) => {
              if (!Object.prototype.hasOwnProperty.call(item, propertyKey)) {
                return 0;
              }

              const parsedItem = parseInt(item[propertyKey], 10);

              if (Number.isNaN(parsedItem)) {
                return 0;
              }

              return (
                parsedItem > values.minValue && parsedItem < values.maxValue
              );
            }),
        },
      ],
    },
    date: {
      content: [
        {
          id: 1,
          name: i18next.t('COMPONENT_DATE_SELECTOR_FILTER_ONE_DAY'),
          type: 'single',
        },
        {
          id: 2,
          name: i18next.t('COMPONENT_DATE_SELECTOR_FILTER_BEFORE'),
          type: 'single',
        },
        {
          id: 3,
          name: i18next.t('COMPONENT_DATE_SELECTOR_FILTER_AFTER'),
          type: 'single',
        },
        {
          id: 4,
          name: i18next.t('COMPONENT_DATE_SELECTOR_FILTER_BETWEEN'),
          type: 'multiple',
        },
      ],
    },
    string: {
      content: [
        {
          id: 1,
          doFilter: (
            data: unknown[],
            propertyKey: string,
            selectedItems: unknown[]
          ) => {
            if (!selectedItems.length) {
              return data;
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return data.filter((item: any) =>
              selectedItems.some(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (selectedItem: any) =>
                  selectedItem[propertyKey] === item[propertyKey]
              )
            );
          },
        },
      ],
    },
  };
}

export default {
  getSelectorFilters,
};
