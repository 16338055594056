import i18next from 'i18next';

export const CATALOG_CREATION = {
  isFullScreen: true,
  path: '/admin/catalogs/create',
};

export const DETAILS = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: () => i18next.t('ADMIN.SUB_FEATURES.INFORMATIONS'),
  path: '/admin/catalogs/:id/details',
  getLink: ({ catalog: { id } }) => `/admin/catalogs/${id}/details`,
};

export const USERS = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: () => i18next.t('GENERAL.USERS'),
  path: '/admin/catalogs/:id/users',
  getLink: ({ catalog: { id } }) => `/admin/catalogs/${id}/users`,
};
