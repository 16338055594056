import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  overflow: hidden;
  text-overflow: ellipsis;

  margin-left: 8px;
  padding-left: 8px;

  ${(props) =>
    !props.large &&
    css`
      padding-right: 18px;
    `}

  flex: ${(props) => (props.large ? 2 : 1)};

  flex-basis: ${(props) =>
    props.large
      ? `calc(${(100 / props.nbColumn) * 2}% + ${
          props.isFirst ? '8px' : '0px'
        })`
      : `calc(${100 / props.nbColumn}% + ${props.isFirst ? '8px' : '0px'})`};

  color: ${(props) => props.theme.childRow.color};

  font: ${(props) => props.theme.childRow.font};
`;

export const Link = styled.a`
  color: ${(props) => props.theme.childRow?.link?.color};
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.childRow?.link?.color};
    text-decoration: underline;
  }
`;

export default {
  Container,
  Link,
};
