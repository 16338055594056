import _ from 'lodash';
import i18next from 'i18next';
import React, { useState } from 'react';

import { ListView, Button } from '@commons/utils/styledLibraryComponents';
import EmptyState from '@commons/EmptyState';

import {
  Container,
  Header,
  HeaderTitle,
  CloseImage,
  ListViewContainer,
  Footer,
  HeaderLeftPart,
} from './styledComponents';

const COLUMNS = [
  {
    id: 'name',
    name: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
  },
];

export const EventSubCategoriesAssociationModal = (props) => {
  const { role, closeModal, eventSubCategories, handleSave } = props.params;
  const [selectedItems, setSelectedItems] = useState([]);

  return (
    <Container>
      <Header>
        <HeaderLeftPart>
          <img src="/images/inpulse/inpulse-link-black-small.svg" />
          <HeaderTitle>
            {`${i18next.t('BACKOFFICE.ROLES.ASSOCIATE_EVENTS_MODAL_TITLE')} ”${_.upperFirst(
              role.name,
            )}”`}
          </HeaderTitle>
        </HeaderLeftPart>
        <CloseImage src="/images/inpulse/close-black-small.svg" onClick={() => closeModal()} />
      </Header>
      <ListViewContainer>
        <ListView
          columns={COLUMNS}
          data={eventSubCategories}
          defaultOrderBy={'name'}
          defaultOrderType={'asc'}
          disableFooter={true}
          forceEnableSelection={true}
          padding={'24px 24px 0px 24px'}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => <EmptyState />}
          setSelectedItems={(items) => setSelectedItems(items)}
        />
      </ListViewContainer>
      <Footer>
        <Button
          color={'inpulse-outline'}
          handleClick={() => closeModal()}
          icon="/images/inpulse/close-black-small.svg"
          label={i18next.t('GENERAL.CANCEL')}
        />
        <Button
          buttonCustomStyle={{ marginLeft: 24 }}
          color={'inpulse-default'}
          handleClick={() => handleSave(selectedItems, role.id)}
          icon="/images/inpulse/save-white-small.svg"
          isDisabled={!selectedItems.length}
          label={i18next.t('GENERAL.SAVE')}
        />
      </Footer>
    </Container>
  );
};

export default EventSubCategoriesAssociationModal;
