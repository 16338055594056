import { get } from 'lodash';
import i18next from 'i18next';

import { numberToFixed } from '@commons/utils/format';

import { productionAnalytics as productionAnalyticsService } from '@services/productionAnalytics';

const getAnalyticsData = async (
  storeIds,
  startDate,
  endDate,
  showErrorMessage,
  productIds,
  setTargetProductionWasteRate,
) => {
  try {
    const analytics = await productionAnalyticsService.getStoresAnalytics(
      storeIds,
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD'),
      productIds,
    );

    const formattedData = analytics.data.map((item, index) => ({
      ...item,
      id: index,
      cadence: item.cadence == null ? null : item.cadence * 100,
      conformity: item.conformity == null ? null : item.conformity * 100,
      quantityProductionRatio:
        item.quantityProductionRatio == null ? null : item.quantityProductionRatio * 100,
      quantityTurnoverWasteRate:
        item.quantityTurnoverWasteRate == null ? null : item.quantityTurnoverWasteRate * 100,
      quantityProductionWasteRate:
        item.quantityProductionWasteRate == null ? null : item.quantityProductionWasteRate * 100,
      turnoverProductionRatio:
        item.turnoverProductionRatio == null ? null : item.turnoverProductionRatio * 100,
      turnoverWasteRate: item.turnoverWasteRate == null ? null : item.turnoverWasteRate * 100,
      productionWasteRate: item.productionWasteRate == null ? null : item.productionWasteRate * 100,
    }));

    if (!formattedData || !formattedData.length) {
      return [];
    }

    setTargetProductionWasteRate(
      numberToFixed(get(analytics, 'avgTargetProductionWasteRate') * 100, 0, '-', '%'),
    );

    return [
      {
        ...analytics.totalData,
        id: 'total',
        date: i18next.t('GENERAL.ANALYTICS_LABEL_TOTAL'),
        cadence: analytics.totalData.cadence == null ? null : analytics.totalData.cadence * 100,
        conformity:
          analytics.totalData.conformity == null ? null : analytics.totalData.conformity * 100,
        quantityProductionRatio:
          analytics.totalData.quantityProductionRatio == null
            ? null
            : analytics.totalData.quantityProductionRatio * 100,
        quantityTurnoverWasteRate:
          analytics.totalData.quantityTurnoverWasteRate == null
            ? null
            : analytics.totalData.quantityTurnoverWasteRate * 100,
        quantityProductionWasteRate:
          analytics.totalData.quantityProductionWasteRate == null
            ? null
            : analytics.totalData.quantityProductionWasteRate * 100,
        quantityLossRatio:
          analytics.totalData.quantityLossRatio == null
            ? null
            : analytics.totalData.quantityLossRatio * 100,
        turnoverProductionRatio:
          analytics.totalData.turnoverProductionRatio == null
            ? null
            : analytics.totalData.turnoverProductionRatio * 100,
        turnoverWasteRate:
          analytics.totalData.turnoverWasteRate == null
            ? null
            : analytics.totalData.turnoverWasteRate * 100,
        productionWasteRate:
          analytics.totalData.productionWasteRate == null
            ? null
            : analytics.totalData.productionWasteRate * 100,
      },
    ].concat(formattedData);
  } catch (err) {
    if (showErrorMessage) {
      showErrorMessage('Impossible de récupérer les données');
    }

    return [];
  }
};

export default getAnalyticsData;
