import { connect } from 'react-redux';
import { uniq } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal } from '@actions/modal';
import { showErrorMessage } from '@actions/messageconfirmation';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import EmptyState from '@commons/EmptyState';
import Text, { ENUM_FONTS } from '@commons/Text';

import { getClientInfo } from '@selectors/client';

import { cashierProductService } from '@services/cashierProduct';
import { product as productService } from '@services/product';

import { SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING } from '../common/constants';

import { ButtonContainer, Container, Content, Header, Icon } from './styledComponents';
import { getColumns } from './utils/getColumns';
import { getModalConfig } from './utils/getConfirmationModalParams';

const MappingCashierProductsProducts = (props) => {
  const {
    client: { clientId },
    history,
    pageLoading,
    pageLoaded,
    openGenericModal,
    showErrorMessage,
  } = props;

  const columns = getColumns();

  const [isLoading, setIsLoading] = useState(false);

  const [displayEmptyStateRelaunchSync, setDisplayEmptyStateRelaunchSync] = useState(false);

  const [cashierProductsProductsMappings, setCashierProductsProductsMappings] = useState([]);
  const [selectedCashierProductsProductsMappings, setSelectedCashierProductsProductsMappings] =
    useState([]);

  const loadCashierProductsWithSuggestedMappings = async () => {
    setIsLoading(true);

    try {
      const suggestedMappingsWithProduct =
        await cashierProductService.getSimilarityScoresForMappingCashierProductProduct(clientId);

      if (!suggestedMappingsWithProduct) {
        setDisplayEmptyStateRelaunchSync(true);

        return;
      }

      const selectedMappings = [];

      const formattedSuggestedMappingsWithProduct = suggestedMappingsWithProduct.map(
        (suggestedMapping) => {
          const isMappingRelevant =
            Math.floor(suggestedMapping.similarityScore * 100) >=
            SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING;

          if (isMappingRelevant) {
            selectedMappings.push(suggestedMapping);
          }

          return {
            ...suggestedMapping,
            isRowSelected: isMappingRelevant,
          };
        },
      );

      setSelectedCashierProductsProductsMappings(selectedMappings);
      setCashierProductsProductsMappings(formattedSuggestedMappingsWithProduct);
    } catch {
      showErrorMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.LOAD_ERROR'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async function loadData() {
      await loadCashierProductsWithSuggestedMappings();
    })();
  }, []);

  const handleNewMappings = async () => {
    pageLoading();

    try {
      for (const cashierProduct of selectedCashierProductsProductsMappings) {
        const {
          id: cashierProductId,
          suggestedProduct: { id: productId },
        } = cashierProduct;

        await productService.associateCashierProductToProduct(cashierProductId, productId);
      }

      const { cashierProductIds, productIds } = selectedCashierProductsProductsMappings.reduce(
        (result, { id: cashierProductId, suggestedProduct: { id: productId } }) => {
          result.cashierProductIds.push(cashierProductId);
          result.productIds.push(productId);

          return result;
        },
        { cashierProductIds: [], productIds: [] },
      );

      const params = getModalConfig(
        {
          countProducts: uniq(productIds).length,
          countCashierProducts: uniq(cashierProductIds).length,
        },
        history.goBack,
      );

      openGenericModal(params);
    } catch {
      showErrorMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_FAILURE'));
    } finally {
      pageLoaded();
    }
  };

  return (
    <Container style={{ marginBottom: '90px' }}>
      <Header>
        <Icon src={'/images/inpulse/bolt-black-small.svg'}></Icon>
        <Text font={ENUM_FONTS.H1_INTER}>
          {i18next.t('ADMIN.CASHIER_PRODUCTS.AUTO_MAPPING_MODAL_TITLE')}
        </Text>
      </Header>
      <Content>
        <ListView
          columns={columns}
          data={cashierProductsProductsMappings}
          defaultOrderBy={'similarityScore'}
          defaultOrderType={'desc'}
          isLoading={isLoading}
          padding={STANDARD_LISTVIEW_PADDING}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => (
            <EmptyState
              icon={'/images/inpulse/empty-state.svg'}
              label={i18next.t(
                displayEmptyStateRelaunchSync
                  ? 'ADMIN.CASHIER_PRODUCTS.ACTION_MAPPING_AUTO_TO_PRODUCT_IMPOSSIBLE'
                  : 'ADMIN.CASHIER_PRODUCTS.ACTION_MAPPING_AUTO_TO_PRODUCT_NOT_ELIGIBLE',
              )}
              subtitle={
                displayEmptyStateRelaunchSync
                  ? i18next.t('ADMIN.CASHIER_PRODUCTS.ACTION_MAPPING_AUTO_TO_PRODUCT_RELAUNCH_SYNC')
                  : null
              }
            />
          )}
          setSelectedItems={setSelectedCashierProductsProductsMappings}
          disableFooter
          forceEnableSelection
        />
      </Content>
      <ButtonContainer>
        <Button
          color={'inpulse-outline'}
          handleClick={history.goBack}
          icon={'/images/inpulse/close-black-small.svg'}
          label={i18next.t('GENERAL.CANCEL')}
        />
        <Button
          color={'inpulse-default'}
          handleClick={handleNewMappings}
          icon={'/images/inpulse/bolt-white-small.svg'}
          isDisabled={!selectedCashierProductsProductsMappings.length}
          label={i18next.t('GENERAL.ASSOCIATE')}
        />
      </ButtonContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MappingCashierProductsProducts);
