/**
 * Compute recipes total cost
 *
 * @param {Array} recipes - List of recipes
 * @param {Object} recipeQuantities - Object of { recipeId: inventoryQuantity }
 *
 * @returns Total value of recipes cost
 */
export const computeRecipeTotalCost = (recipes = [], recipeQuantities = {}) =>
  recipes.reduce((total, recipe) => {
    const recipeInventoryQuantity = recipeQuantities[recipe.id] || 0;
    const recipeInventotyTotal = recipeInventoryQuantity * recipe.cost;

    return total + recipeInventotyTotal;
  }, 0);
