import PropTypes from 'prop-types';
import React from 'react';

import { ENUM_MODULE_NAME } from '../../../commons/utils/features';

import { ADMIN_FEATURE_PATHS } from '../../../commons/utils/featurePaths';

import { ModuleContainer } from '../../ModuleContainer';

export const KitchenProductsContainer = (props) => {
  const { userRights, history } = props;

  return (
    <ModuleContainer
      history={history}
      moduleFeaturePaths={[
        ADMIN_FEATURE_PATHS.ADMIN_KITCHEN_PRODUCTS_PRODUCTS,
        ADMIN_FEATURE_PATHS.ADMIN_KITCHEN_PRODUCTS_RECIPES,
        ADMIN_FEATURE_PATHS.ADMIN_KITCHEN_PRODUCTS_DETAILS,
      ]}
      moduleName={ENUM_MODULE_NAME.ADMIN}
      style={{ overflowY: 'scroll' }}
      userRights={userRights}
    />
  );
};

KitchenProductsContainer.propTypes = {
  userRights: PropTypes.array,
  history: PropTypes.any, // react-router-dom's route prop
  moduleName: PropTypes.oneOf(Object.values(ENUM_MODULE_NAME)),
  moduleFeaturePaths: PropTypes.arrayOf(PropTypes.string),
};

import CreateKitchenProduct from './KitchenProductsProducts/components/creation';
import KitchenProductDetails from './KitchenProductsProducts/components/details';
import KitchenProductsProducts from '../products/products';
// Use admin recipes component to optimise code base
import KitchenProductsRecipes from '../products/recipes';

const KitchenProductsModule = {
  KitchenProductsContainer,
  KitchenProductsProducts,
  KitchenProductsRecipes,
  KitchenProductDetails,
  CreateKitchenProduct,
};

export default KitchenProductsModule;
