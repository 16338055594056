import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #ececec;
  margin-right: ${(props) => (props.noRightMargin ? '0px' : '24px')};
`;

export const ToggleSection = styled.div`
  width: 93px;
  height: 38px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  background: #ececec;

  > span {
    color: ${(props) => props.theme.colors.greys.dark};

    font-family: ${(props) => props.theme.fonts?.families.secondary || 'inter'};
    font-size: 12px;
    line-height: 14.5px;
    font-weight: normal;
  }

  &.active {
    background: ${(props) => props.theme.colors.greys.lightest};
    border: 1px solid ${(props) => props.theme.colors.black};

    > span {
      color: ${(props) => props.theme.colors.greys.darkest};
    }
  }

  &:hover {
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  }

  &:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

export const ToggleSectionDeepsightTheme = styled.div`
  width: 80px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(188, 196, 213, 0.58);
  > span {
    color: #6c778d;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
  }
  &.active {
    background: #ffffff;
    border: 1px solid #0a61fe;
    > span {
      color: #0a3eff;
    }
  }
  &:hover {
    cursor: pointer;
  }
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
