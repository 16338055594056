import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';

import { DeepsightComponentLoader } from '@commons/DeepsightComponents';

import { getBreakagePropertyName } from '@selectors/featureProps';
import { getSalesPointStores } from '@selectors/stores';

import { activityAnalytics as activityAnalyticsService } from '@services/activityAnalytics';

import TurnoverEvolutionGraph from './TurnoverEvolutionGraph/TurnoverEvolutionGraph';

export class TurnoverEvolutionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formattedEvolution: [],
      highestValue: 0,
    };
  }

  getStoreIds = () => this.props.stores.map((store) => store.id);

  formatEvolutionData = (turnoverEvolution) => {
    const { breakagePropertyName } = this.props;

    if (turnoverEvolution) {
      let highestValue = 0;
      turnoverEvolution.forEach((turnoverData) => {
        if (turnoverData.amountIncludingTaxes > highestValue) {
          highestValue = Math.round(turnoverData.amountIncludingTaxes);
        }
      });
      turnoverEvolution = turnoverEvolution.map((turnoverData) => {
        turnoverData.week =
          this.props.t('GENERAL.WEEK_ABBREVIATION') + moment(turnoverData.week, 'YYYY-WW').week();
        turnoverData[breakagePropertyName] = Math.round(
          turnoverData.amountIncludingTaxes * turnoverData.turnoverWasteRate,
        );
        turnoverData.turnover = Math.round(turnoverData.amountIncludingTaxes);
        return turnoverData;
      });
      this.setState({ isLoading: false, formattedEvolution: turnoverEvolution, highestValue });
    } else {
      this.setState({ isLoading: false, formattedEvolution: [] });
    }
  };

  componentDidMount() {
    if (this.props.stores.length > 0) {
      this.setState({ isLoading: true });

      activityAnalyticsService
        .getTurnoverEvolution(
          this.getStoreIds(),
          moment().startOf('week').subtract(5, 'week').add(3, 'days').format('YYYY-MM-DD'),
          moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD'),
        )
        .then((res) => this.formatEvolutionData(res))
        .catch(() => {
          this.setState({ isLoading: false, formattedEvolution: [] });
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.stores !== this.props.stores && this.props.stores.length > 0) {
      this.setState({ isLoading: true });

      activityAnalyticsService
        .getTurnoverEvolution(
          this.getStoreIds(),
          moment().startOf('week').subtract(5, 'week').add(3, 'days').format('YYYY-MM-DD'),
          moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD'),
        )
        .then((res) => this.formatEvolutionData(res))
        .catch(() => {
          this.setState({ isLoading: false, formattedEvolution: [] });
        });
    }
  }

  render() {
    const { breakagePropertyName, currency } = this.props;
    const { formattedEvolution, highestValue, isLoading } = this.state;

    const DATAKEYS = [
      {
        id: 1,
        name: 'turnover',
        displayName: `${this.props.t('HOME.LABEL_TURNOVER')} (${currency.alphabeticCode})`,
        color: '#0FBCF9',
      },
      {
        id: 2,
        name: breakagePropertyName,
        displayName: this.props.t('HOME.LABEL_LOSS_PERCENTAGE'),
        color: '#FFA500',
      },
    ];

    return (
      <div className={this.props.className}>
        <div className="activity-summary-ca-evolution-header">
          {this.props.t('HOME.TURNOVER_EVOLUTION_CHART_HEADER', {
            currencyCode: currency.alphabeticCode,
          })}
        </div>
        {isLoading ? (
          <div style={{ paddingTop: '100px' }}>
            <DeepsightComponentLoader />
          </div>
        ) : (
          formattedEvolution.length > 0 && (
            <div className="activity-summary-ca-evolution-graph-container">
              <TurnoverEvolutionGraph
                data={formattedEvolution}
                dataKeys={DATAKEYS}
                height={280}
                highestValue={highestValue}
                roundedBarsIds={[]}
                width={700}
              />
            </div>
          )
        )}
        {isLoading
          ? undefined
          : formattedEvolution.length === 0 && (
              <div
                className="activity-summary-ca-evolution-graph-container"
                style={{ textAlign: 'center' }}
              >
                <div style={{ paddingTop: '100px' }}>{this.props.t('HOME.NO_DATA_TO_DISPLAY')}</div>
              </div>
            )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  breakagePropertyName: getBreakagePropertyName(state.baseReducer.userRights),
});

const Connected = connect(mapStateToProps, null)(withTranslation()(TurnoverEvolutionContainer));

const InpulseTurnoverEvolutionContainer = styled(Connected)`
  background: ${(props) => props.theme.colors.greys.lightest};
  border-radius: ${(props) => props.theme.borders?.radius?.weak};
  margin-left: 3%;
  margin-right: 30px;
  min-width: 66%;
  height: 100%;

  .activity-summary-ca-evolution-header {
    font: ${(props) => props.theme.fonts?.h3};
    color: ${(props) => props.theme.colors.greys.darkest};
    margin-left: 20px;
    margin-top: 20px;
  }

  .activity-summary-ca-evolution-graph-container {
    font: ${(props) => props.theme.fonts?.textMicro};
    width: 90%;
    height: 80%;
    margin: 10px auto;
  }
`;

const DeepsightTurnoverEvolutionContainer = styled(Connected)`
  background: white;
  box-shadow: 0 2px 6px 0 rgba(168, 177, 196, 0.4);
  border-radius: 10px;
  margin-left: 3%;
  min-width: 62%;
  height: 300px;

  .activity-summary-ca-evolution-header {
    margin-left: 20px;
    margin-top: 20px;
  }

  .activity-summary-ca-evolution-graph-container {
    width: 90%;
    height: 80%;
    margin: 10px auto;
  }
`;

export { InpulseTurnoverEvolutionContainer, DeepsightTurnoverEvolutionContainer };
