import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 40px);

  padding: 24px;

  display: flex;
  flex-direction: column;

  row-gap: 24px;
  overflow-y: auto;
`;

export const SettingContainer = styled.div`
  display: flex;

  align-items: center;
  column-gap: 24px;
`;

export const RecommendationFormContainer = styled.div`
  width: 100%;

  ${(props) =>
    props.displayBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.expandableEmbeddedList.childRow.separator.color};
    `}

  cursor: pointer;
`;

export const RecommendationsContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  row-gap: 24px;
`;
