const REGEX_MATCH_DECIMAL_0 = /([.,]([0]{0,}))$/;

export function numberToFixed(
  value: number | string,
  nbDecimals = 2,
  defaultValue = '0',
  unit?: string
): string {
  if (value == null) {
    return defaultValue;
  }

  const valueToProcess = typeof value === 'string' ? Number(value) : value;

  const formattedValue = valueToProcess
    .toFixed(nbDecimals)
    .replace(REGEX_MATCH_DECIMAL_0, '');

  if (!unit) {
    return parseFloat(formattedValue).toLocaleString('fr');
  }

  return `${parseFloat(formattedValue).toLocaleString('fr')}${unit}`;
}

export const getRandomInt = (min = 1, max = 1000): number => {
  const minCeil = Math.ceil(min);
  const maxFloor = Math.floor(max);

  return Math.floor(Math.random() * (maxFloor - minCeil)) + minCeil;
};

/**
 * Normalize a string by removing accents, lowercasing it and trimming it.
 *
 * @param {string} search - The string to normalize. Since this function can be used when looping
 * through an object properties for comparison (e.g. in ListView), it is called sometimes with
 * boolean or numbers as the parameter. Despite the expected string datatype, we need to make sure
 * to normalize the value as a string, hence the String() call.
 *
 * @returns {string} - Normalized string
 */
export const normalizeString = (search: string): string => {
  return String(search)
    .trim()
    .normalize('NFD') // Decompose combined characters into the sequence of their parts. e.g.:"è" becomes "e`"
    .replace(/\p{Diacritic}/gu, '') // Remove all accents
    .toLowerCase();
};
export default {
  numberToFixed,
  getRandomInt,
  normalizeString,
};
