/**
 * Whenever a generic modal is configured, chances are there are buttons to either cancel, go back,
 * go next, or 'finish' the modal execution.
 * These shorten the number of lines required to defined a given button.
 *
 * See TransferFormContent/AddSupplierProductModal/modalConfiguration.js for examples.
 */

import i18next from 'i18next';

export const GENERIC_MODAL_CANCEL_BUTTON = () => ({
  key: 0,
  color: 'inpulse-outline',
  label: i18next.t('GENERAL.CANCEL'),
  icon: '/images/inpulse/close-black-small.svg',
});

export const GENERIC_MODAL_CLOSE_BUTTON = () => ({
  key: 0,
  color: 'inpulse-outline',
  label: i18next.t('GENERAL.CLOSE'),
  icon: '/images/inpulse/close-black-small.svg',
});

export const GENERIC_MODAL_BACK_BUTTON = () => ({
  key: 0,
  color: 'inpulse-outline',
  label: i18next.t('GENERAL.BACK'),
  icon: '/images/inpulse/arrow-left-ip-black.svg',
});

export const GENERIC_MODAL_NEXT_BUTTON = () => ({
  key: 1,
  color: 'inpulse-default',
  label: i18next.t('GENERAL.NEXT'),
  icon: '/images/inpulse/arrow-right-white.svg',
});

export const GENERIC_MODAL_SAVE_BUTTON = () => ({
  key: 1,
  color: 'inpulse-default',
  label: i18next.t('GENERAL.SAVE'),
  icon: '/images/inpulse/save-white-small.svg',
});

export const GENERIC_MODAL_CONFIRM_BUTTON = () => ({
  key: 1,
  color: 'inpulse-default',
  label: i18next.t('GENERAL.CONFIRM'),
  icon: '/images/inpulse/done-white-small.svg',
});

export const GENERIC_MODAL_CREATE_BUTTON = () => ({
  key: 1,
  color: 'inpulse-default',
  label: i18next.t('GENERAL.CREATE'),
  icon: '/images/inpulse/add-white-small.svg',
});

export const GENERIC_MODAL_DELETE_BUTTON = () => ({
  key: 1,
  color: 'inpulse-default',
  label: i18next.t('GENERAL.DELETE'),
  icon: '/images/inpulse/trash-white.svg',
});

export const GENERIC_MODAL_UNDERSTOOD_BUTTON = () => ({
  key: 1,
  color: 'inpulse-default',
  label: i18next.t('GENERAL.UNDERSTOOD'),
  icon: '/images/inpulse/done-white-small.svg',
});

export const GENERIC_MODAL_IMPORT_BUTTON = () => ({
  key: 1,
  preventClosing: true,
  color: 'inpulse-default',
  label: i18next.t('GENERAL.IMPORT'),
  icon: '/images/inpulse/file-upload-white-small.svg',
});

export const GENERIC_MODAL_DOWNLOAD_BUTTON = () => ({
  key: 1,
  color: 'inpulse-default',
  label: i18next.t('GENERAL.DOWNLOAD'),
  icon: '/images/inpulse/file-download-white-small.svg',
});

export const GENERIC_MODAL_OKAY_BUTTON = () => ({
  key: 1,
  color: 'inpulse-default',
  label: i18next.t('GENERAL.OKAY'),
  icon: '/images/inpulse/done-white-small.svg',
});

export const GENERIC_MODAL_SHOW_BUTTON = () => ({
  key: 1,
  color: 'inpulse-default',
  label: i18next.t('GENERAL.SHOW'),
  icon: '/images/inpulse/visibility-show-white-small.svg',
});

export const GENERIC_MODAL_HIDE_BUTTON = () => ({
  key: 1,
  color: 'inpulse-default',
  label: i18next.t('GENERAL.HIDE'),
  icon: '/images/inpulse/visibility-hide-white-small.svg',
});

export const GENERIC_MODAL_EXPORT_BUTTON = () => ({
  key: 1,
  preventClosing: true,
  color: 'inpulse-default',
  label: i18next.t('GENERAL.EXPORT'),
  icon: '/images/inpulse/file-download-white-small.svg',
});

export const GENERIC_MODAL_ASSOCIATE_BUTTON = () => ({
  key: 1,
  preventClosing: true,
  color: 'inpulse-default',
  label: i18next.t('GENERAL.ASSOCIATE'),
  icon: '/images/inpulse/bolt-white-small.svg',
});

export const GENERIC_MODAL_ADD_BUTTON = () => ({
  key: 1,
  color: 'inpulse-default',
  label: i18next.t('GENERAL.ADD'),
  icon: '/images/inpulse/add-white-small.svg',
});
