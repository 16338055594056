import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { Container, Header, Row, RowLeftSplit, RowRightSplit } from './styledComponents';
import { formatNumberWithCurrency } from '../DisplayNumber';

export const DataType = {
  CURRENCY: 'currency',
  PERCENTAGE: 'percentage',
};

const DataListView = (props) => {
  const { title, headers, data, currency } = props;

  const format = (data, type) => {
    switch (type) {
      case DataType.CURRENCY:
        return formatNumberWithCurrency({
          displayCurrencyCode: true,
          shortenDigits: false,
          withoutDecimals: true,
          number: data,
          currency,
        });
      case DataType.PERCENTAGE:
        return `${Number.isInteger(data) ? data : data.toFixed(2)} %`;
      default:
        return data;
    }
  };

  return (
    <Container>
      <Header>{title}</Header>
      {headers.map(({ name, propertyKey, type }, index) => (
        <Row index={index} key={propertyKey}>
          <RowLeftSplit>{name}</RowLeftSplit>
          <RowRightSplit>{format(data[propertyKey], type)}</RowRightSplit>
        </Row>
      ))}
    </Container>
  );
};

DataListView.propTypes = {
  title: PropTypes.string,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(Object.values(DataType)),
      propertyKey: PropTypes.string.isRequired,
    }),
  ),
  data: PropTypes.array,
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps)(DataListView);
