import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 0 24px;
  height: 100%;
`;

export const ContentContainer = styled.div`
  overflow-y: scroll;
  // 64px size of the sidePanel footer + 2px for Divider  // 24px margin size design wanted between divider and footer
  height: calc(100% - (64px + 2px + 24px));
  padding: 24px 0px;
`;

export const TitleContainer = styled.div`
  font: ${(props) => props.theme.fonts.h1InterNonBold};
  color: ${(props) => props.theme.colors.greys.darkest};
`;

export const List = styled.div`
  height: ${({ height }) => height};
  padding: 24px 0px;
`;

export const RemoveReferenceContainer = styled.div`
  display: flex;

  justify-content: flex-start;

  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;

export const CrossContainer = styled.img``;

export const RemoveReference = styled.div`
  font: ${(props) => props.theme.fonts.textMiddleInterBold};
  margin-left: 8px;

  color: ${(props) =>
    props.isDisabled ? props.theme.colors.greys.lighty : props.theme.colors.greys.darkest};
`;

export const Divider = styled.div`
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.greys.light};
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 16px;
`;

export const DetailContainer = styled.div`
  display: grid;

  margin-top: 24px;
  gap: 8px;

  ${({ inline }) =>
    inline &&
    css`
      display: flex;
      flex-direction: row;
    `};
`;

export const RequiredTextContainer = styled.div`
  display: flex;

  margin-top: 8px;
  gap: 4px;
`;

export const EmptyStateContainer = styled.div`
  display: grid;

  margin-top: 24px;
  gap: 8px;

  justify-items: center;

  ${({ inline }) =>
    inline &&
    css`
      display: flex;
      flex-direction: row;
    `};
`;

/** PRODUCT INFORMATIONS SECTION */
export const ThumbnailContainer = styled.div`
  display: flex;
  margin-right: 8px;
`;

export const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Thumbnail = styled.img`
  width: 40px;
  height: 40px;
`;

export const NameContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Name = styled.div`
  font: ${({ theme }) => theme.fonts.textMiddleNormal};
  color: ${({ theme }) => theme.colors.greys.darkest};
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Sku = styled.div`
  font: ${({ theme }) => theme.fonts.textMicroHeight12};
  color: ${({ theme }) => theme.colors.greys.darker};
`;

export const DisplayNameHovered = styled.div`
  position: absolute;
  top: 72px;
  left: 64px;
  right: 64px;
  height: 40px;
  background: ${({ theme }) => theme.colors.greys.lighter};
  padding: 0 16px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font: ${({ theme }) => theme.fonts.textMiddleNorma};
`;

export const DrawerText = styled.div`
  display: flex;
  align-items: center;

  font: ${({ theme, isBold }) =>
    isBold ? theme.fonts.textMiddleBold : theme.fonts.textMiddleNormal};
  color: ${({ theme, isLight }) =>
    isLight ? theme.colors.greys.darker : theme.colors.greys.darkest};

  ${({ theme, isDisabled }) =>
    isDisabled &&
    css`
      color: ${theme.colors.greys.dark};
    `};
`;

export const ImgAddedReference = styled.div`
  align-self: center;

  width: 16px;
  height: 16px;
  margin-right: 8px;

  cursor: default;

  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.infoOrange};

  background-size: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('/images/inpulse/add-white-small.svg');
`;
/** END OF PRODUCT INFORMATIONS SECTION */

/** PRODUCT PACKAGING RECEPTION SECTION */
export const InputIncrementContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  justify-content: start;
`;

export const InputIncrementLabel = styled.div`
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.greys.dark : theme.colors.greys.darkest};
  font: ${({ theme }) => theme.fonts.textBigHeight16};
`;
/** END OF PRODUCT PACKAGING RECEPTION SECTION */

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;
