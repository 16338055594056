import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: fit-content;
`;

export const Content = styled.div`
  width: 100%;
  height: fit-content;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: ${(props) => (props.bold ? '600' : '400')};
  font-size: ${(props) => (props.big ? '18px' : '14px')};
  line-height: ${(props) => (props.big ? '18px' : '24px')};

  color: ${(props) =>
    props.grey
      ? '#6B768C'
      : props.black
      ? props.theme.colors?.greys.darkest
      : props.color === 'orange'
      ? '#ff9c40'
      : props.color === 'green'
      ? '#2cd696'
      : '#000000'};
`;

export const HeaderText = styled.div`
  font-style: normal;
  font-weight: ${(props) => (props.bold ? '600' : '400')};
  font-size: ${(props) => (props.big ? '18px' : '14px')};
  line-height: ${(props) => (props.big ? '18px' : '24px')};

  font: ${(props) => props.bold && props.theme.fonts?.h3Inter};

  color: ${(props) =>
    props.black ? props.theme.colors?.greys.darkest : props.theme.colors?.greys.darker};
  &:hover {
    cursor: pointer;
  }
`;

export const Headers = styled.div`
  display: flex;

  padding: 15px 10px 15px 25px;

  width: calc(100% - 42px);

  > div {
    flex: 1;

    display: flex;

    > ${HeaderText} {
      margin-right: 10px;
    }
  }
`;

export const Item = styled.div`
  flex: 1;

  ${(props) =>
    props.button &&
    css`
      flex: none;
      width: 32px;
      height: 32px;
      margin-left: 10px;

      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export const Icon = styled.img`
  min-width: 12px;
  ${(props) =>
    props.rotated &&
    css`
      transform: rotate(180deg);
    `};
  &:hover {
    cursor: pointer;
  }
`;

export const HeaderItem = styled.div``;

export const Row = styled.div`
  width: 100%;

  margin-bottom: 10px;

  display: flex;
  align-items: center;

  padding: 9px 10px 9px 25px;

  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
`;

export const Rectangle = styled.div`
  width: fit-content;
  margin-left: ${(props) => (props.needAlign == true ? '0px' : '20px')};
  padding: 0 10px;
  height: 100%;
  text-align: center;

  background: ${(props) => (props.color == 'orange' ? '#fff0cb' : '#d6ffee')};
  border-radius: 4px;
`;

export const TooltipBackOfficeRectangle = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors?.greys.darkest};
  box-shadow: 0px 2px 6px rgba(10, 62, 255, 0.15);
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-image: ${(props) =>
    !props.isLoading ? `url('/images/magnifying-glass.svg');` : `none;`};
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    background: ${(props) => props.theme.colors?.brand?.primary};
    background-image: ${(props) =>
      !props.isLoading ? `url('/images/magnifying-glass.svg');` : `none;`};
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
`;

export const TooltipBackOfficeExport = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors?.greys?.darkest};
  border: 1px solid ${(props) => props.theme.colors?.greys?.darkest};
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background-image: url('/images/icon-download-white.svg');
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    border: 1px solid ${(props) => props.theme.colors?.brand?.primary};
    background: ${(props) => props.theme.colors?.brand?.primary};
    background-image: url('/images/icon-download-white.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
`;
