import styled from 'styled-components';

export const Text = styled.span`
  color: ${(props) => props.theme.colors?.greys.darker};
  font: ${(props) => props.theme.fonts?.textBigHeight24};
`;

export const PasswordResetContentContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 0px 48px;
  column-gap: 24px;
`;
