/**
 * Deduce the percentage of a value from the given reference number
 *
 * @param {Number} value      - The value on which determine the associated percentage
 * @param {Number} reference  - The reference value used to calculate percentage
 *
 * @returns {Number} The deduced percentage
 */
export function getPercentage(value, reference) {
  if (reference > 0) {
    return Math.round((value / reference) * 100);
  }
  return 0;
}

export default {
  getPercentage,
};
