import i18next from 'i18next';
import React from 'react';

import { MainText, TextContainer, EmptyStateIcon, EmptyStateContainer } from './styledComponents';

const EmptyState = (props) => (
  <EmptyStateContainer>
    <div>
      <EmptyStateIcon
        alt="empty-state-ingredient-entity-association-icon"
        className={'center'}
        src="/images/inpulse-icon-no-data.svg"
      />
      <TextContainer>
        <MainText>{i18next.t('GENERAL.NO_RESULT')}</MainText>
      </TextContainer>
    </div>
  </EmptyStateContainer>
);

export default EmptyState;
