import { STORE_TYPES_DROPDOWN_ITEMS } from '../../utils/DropdownItems';
import _ from 'lodash';
import i18next from 'i18next';

const STORE_TYPE_VALUES = [
  { id: 'succursale', name: 'Succursale', value: 'Succursale' },
  { id: 'franchise', name: 'Franchisé', value: 'Franchisé' },
];

const CLOSING_DAYS = [
  { id: 0, name: 'Lundi' },
  { id: 1, name: 'Mardi' },
  { id: 2, name: 'Mercredi' },
  { id: 3, name: 'Jeudi' },
  { id: 4, name: 'Vendredi' },
  { id: 5, name: 'Samedi' },
  { id: 6, name: 'Dimanche' },
];
const STOCK_CONVENTION_VALUES = [
  { id: 'start', name: 'Matin avant ouverture', value: 'Matin avant ouverture' },
  { id: 'end', name: 'Soir après fermeture', value: 'Soir après fermeture' },
];

const getStoreInformationCardSettings = (
  {
    storeInformation,
    brands,
    retailers,
    clientGroups,
    handleGroupsCreation,
    hasMultipleBrands,
    errorsByPropertyKey = {},
  },
  isCreation,
  isKitchenStoreForm,
) => {
  const getInputs = () => [
    {
      dropdownValue: STORE_TYPES_DROPDOWN_ITEMS,
      dropdownMatchingProperty: 'id',
      hasTooltip: false,
      icon: 'none',
      emptyIcon: 'none',
      iconWidth: 0,
      isDropdown: true,
      isRequired: true,
      isSwitch: false,
      readOnly: true,
      isMultipleChoice: false,
      label: i18next.t('GENERAL.STORE_TYPE'),
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      propertyKey: 'storeType',
      type: 'dropdown',
    },
    {
      hasTooltip: false,
      isDropdown: false,
      isRequired: true,
      label: `* ${i18next.t('GENERAL.NAME')}`,
      placeholder: i18next.t('GENERAL.NAME'),
      propertyKey: 'name',
      type: 'text',
    },
    {
      hasTooltip: false,
      isDropdown: false,
      isRequired: !isCreation,
      label: `${!isCreation ? '*' : ''} ${i18next.t('BACKOFFICE.CASHIER_STORES.PARTNER_ID')}`,
      placeholder: i18next.t('BACKOFFICE.CASHIER_STORES.PARTNER_ID'),
      propertyKey: 'partnerId',
      type: 'text',
      hasError:
        !!errorsByPropertyKey['partnerId'] &&
        storeInformation['partnerId'] === errorsByPropertyKey['partnerId'].value,
      errorMessage: !!errorsByPropertyKey['partnerId'] && errorsByPropertyKey['partnerId'].error,
    },
    {
      dropdownValue: STORE_TYPE_VALUES,
      dropdownMatchingProperty: 'id',
      hasTooltip: false,
      icon: 'none',
      emptyIcon: 'none',
      iconWidth: 0,
      isDropdown: true,
      isRequired: true,
      isSwitch: false,
      label: i18next.t('BACKOFFICE.STORES.POINT_OF_SALE_TYPE'),
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      propertyKey: 'type',
      type: 'dropdown',
    },
    {
      dropdownValue: brands,
      emptyIcon: '/images/inpulse/bookmark-lmgrey.svg',
      hasTooltip: false,
      icon: '/images/inpulse/bookmark-black.svg',
      iconWidth: 12,
      isDropdown: true,
      dropdownMatchingProperty: 'id',
      isRequired: false,
      isSwitch: false,
      label: i18next.t('GENERAL.BRAND'),
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      propertyKey: 'brandId',
      type: 'dropdown',
      customZIndex: '4',
    },
    {
      dropdownValue: clientGroups,
      emptyIcon: '/images/inpulse/group-lmgrey-small.svg',
      icon: '/images/inpulse/group-black-small.svg',
      disabledIcon: '/images/inpulse/group-white-small.svg',
      iconWidth: 12,
      isDropdown: true,
      dropdownMatchingProperty: 'id',
      isMultipleChoice: true,
      isRequired: false,
      label: i18next.t('GENERAL.GROUPS'),
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      propertyKey: 'groups',
      type: 'dropdown',
      button: {
        id: 1,
        text: i18next.t('ADMIN.STORES.MANAGE_GROUPS_CREATE_LABEL'),
        handleClick: handleGroupsCreation,
        iconSrc: '/images/inpulse/add-black-small.svg',
      },
    },
    {
      dropdownValue: retailers,
      emptyIcon: '/images/inpulse/store-lmgrey.svg',
      hasTooltip: false,
      icon: '/images/inpulse/store-black.svg',
      disabledIcon: '/images/inpulse/store-white.svg',
      iconWidth: 12,
      isDropdown: true,
      dropdownMatchingProperty: 'id',
      isMultipleChoice: false,
      isRequired: false,
      isSwitch: false,
      readOnly: !retailers.length,
      label: i18next.t('GENERAL.RETAILER'),
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      propertyKey: 'retailerId',
      type: 'dropdown',
      customZIndex: '4',
    },
    {
      dropdownValue: CLOSING_DAYS,
      emptyIcon: '/images/inpulse/closingdays-lm-grey.svg',
      hasTooltip: true,
      icon: '/images/inpulse/closingdays-black-small.svg',
      iconWidth: 12,
      isDropdown: true,
      isMultipleChoice: true,
      isRequired: false,
      isSwitch: false,
      sortBy: (itemsList) => _.sortBy(itemsList, 'id'),
      label: i18next.t('GENERAL.CLOSING_DAYS'),
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      propertyKey: 'closingDays',
      tooltipText: i18next.t(
        'BACKOFFICE.STORES.CREATION_MODAL_CARD_INFORMATION_CLOSING_DAYS_TOOLTIP',
      ),
      type: 'dropdown',
    },
    {
      dropdownValue: STOCK_CONVENTION_VALUES,
      dropdownMatchingProperty: 'id',
      hasTooltip: true,
      icon: 'none',
      emptyIcon: 'none',
      iconWidth: 0,
      isDropdown: true,
      isRequired: true,
      isMultipleChoice: false,
      isSwitch: false,
      label: i18next.t('GENERAL.STOCK_CONVENTION'),
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      propertyKey: 'stockConvention',
      tooltipText: i18next.t(
        'BACKOFFICE.STORES.CREATION_MODAL_CARD_INFORMATION_STOCK_CONVENTION_TOOLTIP',
      ),
      type: 'dropdown',
    },
    {
      hasTooltip: false,
      isDropdown: false,
      isRequired: false,
      label: i18next.t('GENERAL.PHONE'),
      placeholder: i18next.t('GENERAL.PHONE'),
      propertyKey: 'telephone',
      type: 'text',
    },
    {
      dropdownValue: null,
      hasTooltip: false,
      iconWidth: 12,
      isDropdown: false,
      isRequired: true,
      isSwitch: false,
      isSingleDatePicker: true,
      label: i18next.t('BACKOFFICE.STORES.BILLING_DATE'),
      placeholder: i18next.t('BACKOFFICE.STORES.BILLING_DATE'),
      propertyKey: 'launchDate',
      type: 'date',
    },
    {
      dropdownValue: null,
      hasTooltip: false,
      icon: 'none',
      emptyIcon: 'none',
      isDropdown: false,
      isRequired: false,
      isSwitch: true,
      label: i18next.t('GENERAL.STORES_ACTIVE'),
      propertyKey: 'active',
      default: true,
    },
  ];

  const skippedInputForKitchenStoreForm = ['type'];

  return {
    title: i18next.t('BACKOFFICE.STORES.CREATION_MODAL_CARD_INFORMATION_TITLE'),
    inputs: getInputs().reduce((acc, input) => {
      if (input.propertyKey === 'brandId' && !hasMultipleBrands) {
        return acc;
      }

      if (isKitchenStoreForm && skippedInputForKitchenStoreForm.includes(input.propertyKey)) {
        return acc;
      }

      acc.push({ ...input, value: storeInformation[input.propertyKey] });
      return acc;
    }, []),
  };
};

export default getStoreInformationCardSettings;
