import { useState } from 'react';
import MomentPropTypes from 'react-moment-proptypes';

/**
 * Custom hook to call whenever a DatePicker is used.
 *
 * Usage example in: StocksAnalyticsCurrent
 *
 * @param {momentObj} initialDate
 * @returns An object containing the date & focused state/setters
 */
export const useDatePickerState = (initialDate) => {
  const [date, setDate] = useState(initialDate);
  const [datePickerIsFocused, setDatePickerIsFocused] = useState(false);

  return { date, setDate, datePickerIsFocused, setDatePickerIsFocused };
};

useDatePickerState.propTypes = {
  initialDate: MomentPropTypes.momentObj.isRequired,
};
