import { config } from '@src/config';
import axios from '@src/core/http';

const baseApiUrl = config.baseApiUrl;

export const getClients = async () => {
  const { data } = await axios.get(`${baseApiUrl}/backoffice/clients`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('loopbackAccessToken'),
    },
  });

  return data;
};

export const getRecipes = async (clientId) => {
  const { data } = await axios.get(
    `${baseApiUrl}/backoffice/clients/${clientId}/debugger/recipes`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    },
  );

  return data;
};

export const getStores = async (clientId) => {
  const { data } = await axios.get(`${baseApiUrl}/admin/clients/${clientId}/stores`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('loopbackAccessToken'),
    },
  });

  return data;
};

export const getAnalysisByIngredient = async (
  clientId,
  recipes,
  storeIds,
  startDate,
  endDate,
  entityId,
) => {
  const { data } = await axios.post(
    `${baseApiUrl}/backoffice/clients/${clientId}/debugger/recipes/analysis-by-ingredient`,
    {
      recipes,
      startDate,
      endDate,
      storeIds,
      entityId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    },
  );

  return data;
};
