import { get } from 'lodash';

export const ENUM_ROLES_NAME = {
  ADMIN: 'admin',
  MANAGER: 'manager',
  API_CONSUMER: 'api_consumer',
  PUBLIC_API_CONSUMER: 'public-api-consumer',
  INPULSE: 'inpulse',
};

export const isUserManager = (user) => {
  const role = get(user, 'lnkAccountRoleAccountrel.name', '');

  // Disable/hide certain actions or pages whatever the kind of manager the role is.
  return role.includes(ENUM_ROLES_NAME.MANAGER);
};

export const isUserDeepsight = (user) => !!user.isDeepsight;

export const isUserAdmin = (user) => {
  const role = get(user, 'lnkAccountRoleAccountrel.name', '');
  return role.includes(ENUM_ROLES_NAME.ADMIN);
};

export const userHasRoleInpulse = (user) => {
  const role = get(user, 'lnkAccountRoleAccountrel.name', '');
  return role === ENUM_ROLES_NAME.INPULSE;
};
