import i18next from 'i18next';

import { LOSS_TYPE } from '../../losses/LossesLosses/components/LossListView/constants';

/**
 * Used by the StocksAnalyticsPastNestedList and StockAnalyticsPast to determine what to setup in terms of:
 * - empty states
 * - data fetches
 */
export const PAST_STOCKS_TYPE = {
  INGREDIENTS: 'ingredients',
  CENTRAL_KITCHEN_INGREDIENTS: 'central_kitchen_ingredients',
  CENTRAL_KITCHEN_RECIPES: 'central_kitchen_recipes',
};

export const ENTITY_TYPES_CHOICES = [
  { key: LOSS_TYPE.SUPPLIER_PRODUCT, name: i18next.t('FEATURE.ADMIN.SUPPLIERS_PRODUCTS') },
  { key: LOSS_TYPE.RECIPE, name: i18next.t('FEATURE.ADMIN.RECIPES') },
];
