export const convertQuantityToSupplierProductUnit = (unit, quantity) => {
  if (unit === 'unit') {
    return quantity;
  }

  return quantity / 1000;
};

export default {
  convertQuantityToSupplierProductUnit,
};
