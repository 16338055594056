import i18next from 'i18next';
import React from 'react';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { ToggleSwitch } from '@commons/utils/styledLibraryComponents';
import Text from '@commons/Text';

import { Container, SwitchContainer } from './styledComponents';

const AvailabilitiesModalContent = ({
  selectedAvailabilities: { hasStock, hasLoss },
  setSelectedAvailabilities,
}) => (
  <Container>
    <SwitchContainer>
      <ToggleSwitch
        checked={hasStock}
        handleClick={() => setSelectedAvailabilities({ hasStock: !hasStock, hasLoss })}
        id="hasStock"
      />
      <Text>{i18next.t('ADMIN.RECIPES.HAS_STOCK_LABEL')}</Text>
    </SwitchContainer>
    {/* [IPP-6529 - Recipe Admin - Uncomment this when recipe loss feature is available]
    <SwitchContainer>
      <ToggleSwitch
        checked={hasLoss}
        handleClick={() => setSelectedAvailabilities({ hasStock, hasLoss: !hasLoss })}
        id="hasLoss"
      />
      <Text>{i18next.t('ADMIN.RECIPES.HAS_LOSS_LABEL')}</Text>
    </SwitchContainer> */}
  </Container>
);

export const getAvailabilitiesModalConfig = ({
  handleAvailabilitiesUpdate,
  selectedItems,
  selectedAvailabilities,
  setSelectedAvailabilities,
  handleAvailabilitiesModalCloseCleanUp,
}) => ({
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      handleClick: () => handleAvailabilitiesUpdate(selectedItems, selectedAvailabilities),
      preventClosing: true,
    },
  ],
  title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_TITLE_UPDATE_AVAILABILITIES'),
  icon: '/images/inpulse/pen-black-small.svg',
  type: 'action',
  handleCloseCleanUp: handleAvailabilitiesModalCloseCleanUp,
  component: AvailabilitiesModalContent,
  data: {
    selectedAvailabilities,
    setSelectedAvailabilities,
  },
  height: 'auto',
});
