import i18next from 'i18next';

import { handleExport } from './handleExport';

export const getButtonActions = (
  columns,
  entityCostHistories,
  currency,
  startDate,
  endDate,
  selectedStores,
  selectedIngredient,
  pluralTranslatedStoreName,
  openRestartCalculationModal,
) => [
  {
    label: i18next.t('BACKOFFICE.PRODUCTS.RESTART_HISTORICIZED_CALCULATIONS'),
    icon: `/images/inpulse/sync-black-small.svg`,
    handleClick: openRestartCalculationModal,
  },
  {
    label: i18next.t('BACKOFFICE.PRODUCTS.EXPORT_COSTS'),
    icon: `/images/inpulse/file-download-black-small.svg`,
    handleClick: () =>
      handleExport(
        columns,
        entityCostHistories,
        currency,
        startDate,
        endDate,
        selectedStores,
        selectedIngredient,
        pluralTranslatedStoreName,
      ),
  },
];
