import styled from 'styled-components';

export const ProductGroupContainer = styled.div`
  background-color: #ffffff;
  box-shadow: ${(props) => props.theme.orderForm?.boxShadow || '0 1px 4px rgba(0, 0, 0, 0.12)'};
  border-radius: ${(props) => props.theme.borders?.radius.weak};
  border: 1px solid
    ${(props) =>
      props.isOpen && props.addBorder
        ? props.theme?.colors?.greys?.darkest
        : props.theme?.colors?.greys?.light};
  margin-bottom: 8px;
  min-height: 64px;
  display: flex;
  flex-direction: column;
`;

export default {
  ProductGroupContainer,
};
