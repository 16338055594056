import styled from 'styled-components';

export const EmptyStateContainer = styled.div`
  flex: 1;
  padding: 24px;

  display: flex;
  align-items: center;

  background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
`;

export const TextContainer = styled.div`
  height: 100%;

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > :not(:first-child) {
    margin-top: 24px;
  }
`;

export const MainText = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};
  font: ${(props) => props.theme.fonts?.h2};
`;

export const SubText = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};
  font: ${(props) => props.theme.fonts?.textBig};
`;

export const EmptyStateIcon = styled.img`
  width: 160px;
  height: 160px;
`;
