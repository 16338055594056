import styled, { css } from 'styled-components';

export const PaginatorContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 32px;

  padding: 0 15px;

  box-shadow: ${(props) => props.theme.pagination?.boxShadow};
  background: ${(props) => props.theme.pagination?.backgroundColor};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PaginatorInfo = styled.div`
  display: flex;
  align-items: center;
  width: 400px;

  font: ${(props) => props.theme.pagination?.font};
  color: ${(props) => props.theme.pagination?.color};

  ${(props) =>
    props.hidden &&
    css`
      visibility: hidden;
    `};
`;

export const MaxPerPageDropdown = styled.div`
  position: relative;
`;

export const DropdownButton = styled.div`
  width: fit-content;
  min-width: 90px;
  height: 20px;

  color: ${(props) => props.theme.pagination?.dropdown?.color};
  border: ${(props) => props.theme.pagination?.dropdown?.border};
  box-shadow: ${(props) => props.theme.pagination?.dropdown?.boxShadow};
  background: ${(props) => props.theme.pagination?.dropdown?.backgroundColor};
  border-radius: ${(props) => props.theme.pagination?.dropdown?.borderRadius};

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;

  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      background: ${props.theme.pagination?.dropdown?.disabledBackgroundColor};
    `};
`;

export const DropdownList = styled.div`
  position: absolute;
  left: 0;
  right: auto;
  bottom: 100%;
  z-index: 10;
  max-height: 500px;
  padding: 5px 0;

  min-width: 110px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 4px;

  box-shadow: ${(props) => props.theme.pagination?.dropdown?.list?.boxShadow};
  border-radius: ${(props) =>
    props.theme.pagination?.dropdown?.list?.borderRadius};
  background: ${(props) =>
    props.theme.pagination?.dropdown?.list?.backgroundColor};
`;

export const DropdownItem = styled.div`
  width: 100%;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const DropdownText = styled.span`
  font: ${(props) => props.theme.pagination?.dropdown?.default?.font};
  color: ${(props) => props.theme.pagination?.dropdown?.default?.color};

  ${(props) =>
    props.selected &&
    css`
      font: ${props.theme.pagination?.dropdown?.selected?.font};
      color: ${props.theme.pagination?.dropdown?.selected?.color};
    `};
`;

export const ElementsInfo = styled.div`
  margin: 0 10px 0 15px;
  display: flex;
  justify-content: center;
  width: fit-content;

  font: ${(props) => props.theme.pagination?.font};
  color: ${(props) => props.theme.pagination?.color};
`;

export const SelectedItemsInfo = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const SelectedItemsCount = styled.span`
  margin-left: 10px;
`;

export const PagesHandler = styled.div`
  height: 24px;
  background: ${(props) => props.theme.pagination?.switch?.backgroundColor};
  border: ${(props) => props.theme.pagination?.switch?.border};
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.pagination?.switch?.boxShadow};
  border-radius: ${(props) => props.theme.pagination?.switch?.borderRadius};
  display: flex;
  justify-content: space-between;
`;

export const PageSwitcher = styled.div`
  display: flex;
  padding: 0 10px;
  display: flex;
  align-items: center;

  ${(props) => {
    if (props.border === 'left') {
      return css`
        border-left: 1px solid rgba(196, 197, 199, 0.239893);
      `;
    }

    if (props.border === 'right') {
      return css`
        border-right: 1px solid rgba(196, 197, 199, 0.239893);
      `;
    }

    return '';
  }}

  &:hover:not(${(props) => !props.disabled}) {
    cursor: pointer;
  }

  ${(props) =>
    props.disabled &&
    css`
      > img {
        opacity: 0.3;
      }
    `};
`;

export const PagesDisplay = styled.div`
  width: max-content;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font: ${(props) => props.theme.pagination?.font};
  color: ${(props) => props.theme.pagination?.color};
`;

export default {
  PaginatorContainer,
  MaxPerPageDropdown,
  DropdownButton,
  DropdownList,
  DropdownItem,
  DropdownText,
  PagesHandler,
  PageSwitcher,
  PagesDisplay,
  PaginatorInfo,
  ElementsInfo,
  SelectedItemsInfo,
  SelectedItemsCount,
};
