import styled from 'styled-components';

export const Container = styled.div`
  position: ${(props) => (props.theme.fonts ? undefined : 'fixed')};
  bottom: ${(props) => (props.theme.fonts ? undefined : 0)};
  display: flex;
  width: ${(props) => (props.theme.colors ? '50%' : '100%')};
  height: 60px;
  justify-content: ${(props) => (props.theme.colors ? 'flex-end' : 'space-between')};
  flex: ${(props) => (props.theme.colors ? 1 : undefined)};
  padding: 0 30px;
  background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
  box-shadow: ${(props) => (props.theme.borders ? undefined : '0 -2px 6px 0 rgba(0, 0, 0, 0.1)')};
  z-index: ${(props) => (props.theme.fonts ? undefined : 9)};
`;

export const WarningModalContainer = styled.div`
  text-align: start;
`;

export const ModalBodySubtitle = styled.p``;

export const BodyListContainer = styled.ul`
  padding: 0 0 0 16px;
`;

export const BodyListItem = styled.li`
  padding: 0 0 8px 0;
`;

export const BodyTextItem = styled.span``;

export default {
  Container,
  WarningModalContainer,
  ModalBodySubtitle,
  BodyListContainer,
  BodyListItem,
  BodyTextItem,
};
