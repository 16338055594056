import { connect } from 'react-redux';
import { Form, reduxForm } from 'redux-form';
import { useCryptr } from '@cryptr/cryptr-react';
import CryptrSpa from '@cryptr/cryptr-spa-js';
import i18next from 'i18next';
import qs from 'query-string';
import React, { useEffect, useState, useLayoutEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';

import { Button } from '@commons/utils/styledLibraryComponents';
import { getTheme } from '@commons/utils/theme';
import { REGEX_MATCH_EMAIL_INSENSITIVE } from '@commons/utils/regex';

import { Input } from '@lib/inpulse/Input';

import { user as userService } from '@services/user';
import ssoService from '@services/sso';

import { config as cryptrConfig } from '@src/core/cryptr';

import {
  Container,
  Divider,
  FooterContainer,
  Subtitle,
  Title,
  TitleContainer,
} from '../sharedStyledComponents';

import {
  ContentContainer,
  ConnectionContainer,
  EmailContainer,
  ErrorContainer,
  ForgetPasswordContainer,
  ForgetPasswordLink,
  PasswordContainer,
} from './styledComponents';

const ENABLE_SSO_LOGIN_FLOW = true;

const validateEmail = (email) => email && email.match(REGEX_MATCH_EMAIL_INSENSITIVE);

// Not added password validation from userRegistration component
// to avoid oldPassword who not match validation
const validatePassword = (password) => password && password.length >= 6;

const LoginComponent = (props) => {
  const { location } = props;

  const theme = getTheme();

  // Retrieve info connected user with cryptr SDK
  const cryptrHook = useCryptr();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isDisableButton, setIsDisableButton] = useState(true);

  const [isSSOLogin, setIsSSOLogin] = useState(false);

  // Used to avoid loop of re-render and loop of API calls
  const [isGeneratingTokenFromSSOAccessToken, setIsGeneratingTokenFromSSOAccessToken] =
    useState(false);

  useLayoutEffect(() => {
    if (!location || !location.search) {
      return;
    }

    const queryParams = qs.parse(location.search);

    if (queryParams && !!queryParams.email) {
      setEmail(queryParams.email);
    }

    if (queryParams && queryParams.sso === 'true') {
      setIsSSOLogin(true);
    }
  }, []);

  useEffect(() => {
    const isAuthenticated = cryptrHook.isAuthenticated();

    if (!isAuthenticated) {
      return;
    }

    const accessToken = cryptrHook.getCurrentIdToken();

    (async function load() {
      await generateInpulseTokenFromCryptrAcessToken(accessToken);
    })();
  }, [cryptrHook]);

  useEffect(() => {
    setError(null);

    if (isSSOLogin) {
      setIsDisableButton(!validateEmail(email));
      return;
    }

    setIsDisableButton(!validateEmail(email) || !validatePassword(password));
  }, [email, password, isSSOLogin]);

  const generateInpulseTokenFromCryptrAcessToken = async (accessToken) => {
    if (isGeneratingTokenFromSSOAccessToken) {
      return;
    }

    // Never set is to false as the component and so the state will be re-initialized if it fails
    setIsGeneratingTokenFromSSOAccessToken(true);

    props.pageLoading();

    try {
      await ssoService.login(accessToken);

      props.history.go('/home');
    } catch (e) {
      setError(i18next.t('AUTHENTICATION.LOGIN.ERROR'));
    } finally {
      props.pageLoaded();
    }
  };

  const signInByMail = async () => {
    props.pageLoading();

    try {
      await userService.login(email, password);

      props.history.go('/home');
    } catch (e) {
      setError(i18next.t('AUTHENTICATION.LOGIN.ERROR'));
    } finally {
      props.pageLoaded();
    }
  };

  const signInBySSO = async () => {
    try {
      const { cryptrDomain } = await ssoService.verifyEligibility(email);

      setError(null);
      setIsDisableButton(true);

      const cryptrClient = await CryptrSpa.createClient(cryptrConfig);

      await cryptrClient.signInWithDomain(cryptrDomain);
    } catch (error) {
      setError(i18next.t('GENERAL.CONNECTION_MICROSOFT_FAILURE'));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isSSOLogin) {
      await signInBySSO();

      return;
    }

    await signInByMail();
  };

  return (
    <Container>
      <TitleContainer>
        <Title data-text="Inpulse">
          {i18next.t(!isSSOLogin ? 'AUTHENTICATION.LOGIN.TITLE' : 'AUTHENTICATION.LOGIN.TITLE_SSO')}
          Inpulse
        </Title>
        <Subtitle>{i18next.t('AUTHENTICATION.LOGIN.SUBTITLE')}</Subtitle>
      </TitleContainer>
      <Divider />

      <Form onSubmit={handleSubmit}>
        <ContentContainer isSSO={isSSOLogin}>
          {!!error && <ErrorContainer>{error}</ErrorContainer>}
          <EmailContainer>
            <Input
              label={`* ${i18next.t('AUTHENTICATION.LOGIN.EMAIL')}`}
              type="text"
              value={email}
              width={240}
              autoFocus
              onChange={(event) => setEmail(event.target.value)}
            />
          </EmailContainer>
          {!isSSOLogin && (
            <>
              <PasswordContainer>
                <Input
                  label={`* ${i18next.t('AUTHENTICATION.LOGIN.PASSWORD')}`}
                  type="password"
                  value={password}
                  width={240}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </PasswordContainer>
              <ForgetPasswordContainer>
                <ForgetPasswordLink href="/ask-password-reset" target="_self">
                  {i18next.t('AUTHENTICATION.LOGIN.FORGOT_PASSWORD')}
                </ForgetPasswordLink>
              </ForgetPasswordContainer>
            </>
          )}
          <ConnectionContainer>
            <Button
              buttonCustomStyle={{ width: '240px', justifyContent: 'center' }}
              color="inpulse-default"
              handleClick={handleSubmit}
              icon={'images/inpulse/user-white.svg'}
              isDisabled={isDisableButton}
              label={i18next.t('GENERAL.CONNECTION')}
              type="submit"
            />
          </ConnectionContainer>
        </ContentContainer>
      </Form>
      <Divider />
      <FooterContainer>
        {ENABLE_SSO_LOGIN_FLOW && (
          <Button
            buttonCustomStyle={{
              width: '240px',
              justifyContent: 'center',
              font: theme.fonts.textBigHeight16,
            }}
            color="inpulse-outline"
            handleClick={() => setIsSSOLogin(!isSSOLogin)}
            icon={!isSSOLogin ? 'images/logo-microsoft.svg' : 'images/inpulse/mail-ip-black.svg'}
            label={i18next.t(
              !isSSOLogin ? 'GENERAL.CONNECTION_MICROSOFT' : 'GENERAL.CONNECTION_EMAIL',
            )}
          />
        )}
      </FooterContainer>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default reduxForm({
  form: 'simple',
})(connect(null, mapDispatchToProps)(LoginComponent));
