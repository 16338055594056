import { keyBy } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import Text, {
  ENUM_FONTS,
  ENUM_COLORS,
  CUSTOM_STYLE_FOR_DROPDOWN_TEXT,
} from '../../../commons/Text';

import { DropdownRowMultipleLines } from '../../../commons/utils/styledLibraryComponents';
import ItemsWithPicture from '../../../commons/DropdownItems/ItemsWithPicture';

/**
 * @param {Object} currentEntity { id: <uuid>, name: <string>, category: <string>, unit <string>, supplierProducts <object[]> } The current Entity (recipe or product)
 * @param {Object[]} compositions { allergens: <object[]>, entity <object> } The already existing composition of the current recipe or product
 * @param {Object[]} categories The different categories of the entities
 * @param {Object[]} entitiesToFormat The entities that we need to format and filter before displaying them in a dropdown
 * @returns
 */
export const getSearchableCompositions = ({
  currentEntity,
  compositions,
  entitiesToFormat,
  shouldDisplayItemsWithPicture,
}) => {
  const compositionKeyByEntityId = keyBy(compositions, 'entity.id');

  const filteredEntities = entitiesToFormat.filter(
    (entity) =>
      !compositionKeyByEntityId[entity.id] && entity.id !== currentEntity.id && entity.active,
  );

  const formattedEntities = filteredEntities.map((entity) => ({
    ...entity,
    value: entity.name,
    renderValue: shouldDisplayItemsWithPicture
      ? () =>
          ItemsWithPicture({
            title: entity.name,
            subtitle: entity.isIngredient ? i18next.t('GENERAL.INGREDIENT') : entity.category,
            imgSrc:
              entity.img ||
              (entity.isIngredient
                ? '/images/inpulse/ingredient-photo-placeholder.svg'
                : '/images/inpulse/recipe-photo-placeholder.svg'),
          })
      : () => (
          <DropdownRowMultipleLines>
            <Text font={ENUM_FONTS.TEXT_BIG_HEIGHT_16} style={CUSTOM_STYLE_FOR_DROPDOWN_TEXT}>
              {entity.name}
            </Text>
            <Text
              color={ENUM_COLORS.DARK}
              font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}
              style={CUSTOM_STYLE_FOR_DROPDOWN_TEXT}
            >
              {entity.isIngredient ? i18next.t('GENERAL.INGREDIENT') : entity.category}
            </Text>
          </DropdownRowMultipleLines>
        ),
  }));

  return formattedEntities;
};
