import PropTypes from 'prop-types';
import React from 'react';

import Text, { ENUM_COLORS, ENUM_FONTS } from '../Text';

import { PillContainer, CountContainer, HighCountContainer } from './styledComponents';

const TABLET_COMPATIBLE_STYLE = { lineHeight: '24px', width: '24px', textAlign: 'center' };

const Pill = (props) => {
  const { name, count, isSelected, setSelectedItem } = props;

  return (
    <PillContainer isSelected={isSelected} onClick={setSelectedItem}>
      <Text
        color={isSelected ? ENUM_COLORS.LIGHTEST : ENUM_COLORS.DARKEST}
        style={{
          'margin-right': '8px',
        }}
      >
        {name}
      </Text>
      <CountContainer isSelected={isSelected}>
        {count <= 99 ? (
          <Text
            font={ENUM_FONTS.TEXT_SMALL_WEIGHT_700_HEIGHT_16_BOLD}
            style={TABLET_COMPATIBLE_STYLE}
          >
            {count}
          </Text>
        ) : (
          <HighCountContainer>
            <Text
              font={ENUM_FONTS.TEXT_MICRO_WIDTH_8_HEIGHT_16_WEIGHT_700_BOLD}
              style={TABLET_COMPATIBLE_STYLE}
            >
              +
            </Text>
            <Text
              font={ENUM_FONTS.TEXT_SMALL_WEIGHT_700_HEIGHT_16_BOLD}
              style={TABLET_COMPATIBLE_STYLE}
            >
              99
            </Text>
          </HighCountContainer>
        )}
      </CountContainer>
    </PillContainer>
  );
};

Pill.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
};

export default Pill;
