import { capitalize, get, isEmpty, maxBy, reverse, sortBy } from 'lodash';
import i18next from 'i18next';
import React, { useState } from 'react';

import { getTheme } from '@commons/utils/theme';
import { ProgressBar, ToggleButton, Tooltip } from '@commons/utils/styledLibraryComponents';
import { TooltipIcon } from '@commons/Metrics/styledComponents';
import DataListView, { DataType } from '@commons/DataListView';
import EmptyState from '@commons/EmptyState';
import ScrollableWhiteCard from '@commons/WhiteCard/ScrollableWhiteCard';
import TextWithTooltip from '@commons/TextWithTooltip';

import {
  HeaderContainer,
  StoreLabel,
  StoreRow,
  Title,
  TitleContainer,
} from '../../styledComponents';

const STORE_RANKING_CHOICES = [
  {
    key: 'realConsumptionRatio',
    name: i18next.t('DASHBOARD.FOODCOST.FOODCOST_RATIO'),
    getLabel: () => i18next.t('DASHBOARD.FOODCOST.FOODCOST_LABEL'),
    variation: 'positive',
  },
  {
    key: 'lossRatio',
    name: i18next.t('DASHBOARD.FOODCOST.LOSS_RATIO'),
    getLabel: () => i18next.t('DASHBOARD.FOODCOST.LOSS_LABEL'),
    variation: 'negative',
  },
];

export const RealConsumptionLossProgressBar = (props) => {
  const { isLoading, storeRanking, storesKeyById } = props;

  const theme = getTheme();

  /* STATE */

  const [selectedStoreRankingMetric, setSelectedStoreRankingMetric] = useState(
    STORE_RANKING_CHOICES[0],
  );

  /* FUNCTIONS */

  const renderTooltipContent = (
    {
      turnoverExcludingTaxes,
      realConsumption,
      realConsumptionRatio,
      marginExcludingTaxes,
      marginExcludingTaxesRatio,
      lossExcludingTaxes,
      lossRatio,
    },
    storeName,
  ) => (
    <DataListView
      data={{
        turnoverExcludingTaxes,
        realConsumption,
        realConsumptionPercentage: realConsumptionRatio,
        marginExcludingTaxes,
        marginExcludingTaxesPercentage: marginExcludingTaxesRatio,
        lossExcludingTaxes,
        lossExcludingTaxesPercentage: lossRatio,
      }}
      headers={[
        {
          name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_REVENUE'),
          type: DataType.CURRENCY,
          propertyKey: 'turnoverExcludingTaxes',
        },
        {
          name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_ACTUAL_FC'),
          type: DataType.CURRENCY,
          propertyKey: 'realConsumption',
        },
        {
          name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_PERCENT_ACTUAL_FC'),
          type: DataType.PERCENTAGE,
          propertyKey: 'realConsumptionPercentage',
        },
        {
          name: i18next.t('FORECAST.PRODUCT_MIX.ACTIVITY_BOX_FORECAST_MARGIN'),
          type: DataType.CURRENCY,
          propertyKey: 'marginExcludingTaxes',
        },
        {
          name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_PERCENT_MARGIN_WITHOUT_TAXES'),
          type: DataType.PERCENTAGE,
          propertyKey: 'marginExcludingTaxesPercentage',
        },
        {
          name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_WASTES'),
          type: DataType.CURRENCY,
          propertyKey: 'lossExcludingTaxes',
        },
        {
          name: i18next.t('DASHBOARD.FOODCOST.LOSS_RATE'),
          type: DataType.PERCENTAGE,
          propertyKey: 'lossExcludingTaxesPercentage',
        },
      ]}
      title={storeName}
    />
  );

  const offsetByMetric = selectedStoreRankingMetric.key === 'realConsumptionRatio' ? 10 : 5;
  const maxBySelectedMetric = maxBy(storeRanking, selectedStoreRankingMetric.key);
  const percentage = maxBySelectedMetric
    ? maxBySelectedMetric[selectedStoreRankingMetric.key] + offsetByMetric
    : 100;
  const maxPercentage = percentage <= 100 ? percentage : 100;

  return (
    <ScrollableWhiteCard
      className="store-ranking"
      isLoading={isLoading}
      renderContent={
        !isEmpty(storeRanking) ? (
          <>
            {reverse(sortBy(storeRanking, `${selectedStoreRankingMetric.key}`)).map(
              (storeData, index) => {
                const store = storesKeyById[storeData.storeId];
                if (!store) {
                  return null;
                }
                const storeName = store.name;

                return (
                  <StoreRow key={index}>
                    <TextWithTooltip
                      content={<StoreLabel>{storeName}</StoreLabel>}
                      text={storeName}
                    />
                    <ProgressBar
                      maxPercentage={maxPercentage}
                      percentage={get(storeData, `${selectedStoreRankingMetric.key}`)}
                      renderTooltipContent={() => renderTooltipContent(storeData, storeName)}
                      variation={selectedStoreRankingMetric.variation}
                      withDot
                      withGradient
                      withPercentage
                    />
                  </StoreRow>
                );
              },
            )}
          </>
        ) : (
          <EmptyState
            backgroundColor={theme.colors.white}
            label={i18next.t('DASHBOARD.FOODCOST.EMPTY_STATE_LABEL')}
          />
        )
      }
      renderHeader={
        <HeaderContainer>
          <TitleContainer>
            <Tooltip
              renderTooltipTrigger={() => (
                <TooltipIcon src={'/images/inpulse/info-dmgrey-small.svg'} />
              )}
              text={i18next.t('DASHBOARD.FOODCOST.STORE_RANKING_TITLE_TOOLTIP', {
                data: selectedStoreRankingMetric.getLabel(),
              })}
              displayBigger
            />
            <Title>
              {i18next.t('DASHBOARD.FOODCOST.STORE_RANKING_TITLE', {
                data: capitalize(selectedStoreRankingMetric.getLabel()),
              })}
            </Title>
          </TitleContainer>
          <ToggleButton
            choices={STORE_RANKING_CHOICES}
            customStyle={{
              height: '24px',
              width: '96px',
              font: theme.fonts.textSmallWeight700Height16Bold,
            }}
            isDisabled={false}
            selectedChoice={selectedStoreRankingMetric}
            setSelectedChoice={setSelectedStoreRankingMetric}
          />
        </HeaderContainer>
      }
    ></ScrollableWhiteCard>
  );
};

export default RealConsumptionLossProgressBar;
