import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { openMediumModal } from '@actions/modal';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { getIsCentralMode } from '@commons/utils/localStorage';
import { LAST_CHARACTER_IS_NUMBER } from '@commons/utils/regexps';

import { canEditDraftOrder, canEditSentOrReceivedOrder } from '@selectors/actions/orderActions';
import {
  getAuthorizedActions,
  getResetStockEveryDayForCentralKitchen,
} from '@selectors/featureProps';

import { PRODUCT_ORDER_TYPE } from '@orders/OrderList/constants';
import ORDER_STATUS from '@orders/OrderList/components/OrderForm/constants/status';

import DraftProductOrder from './components/DraftProductOrder';
import SentProductOrder from './components/SentProductOrder';

const INPUT_REGEX_COMPARATOR = /^[\d+]+(\.0+)[0]*$/;

const ProductOrder = (props) => {
  const {
    preparationStatus,
    allProductsUnavailable,
    authorizedActions,
    category,
    columns,
    computedOrderedAmount,
    currency,
    deleteProductOrder,
    endDate,
    entityStockEvent,
    handleInvoicedDiff,
    handleReceivedDiff,
    hasBLI,
    indexCategory,
    indexEntity,
    invoicedDiffOfProductOrder,
    isEditable,
    isEditingPreparationOrder,
    isFirstElement,
    isFirstElementAvailable,
    isLastElement,
    isOpen,
    multiple,
    openStockInfo,
    orderStatus,
    prefillInpulse,
    product,
    receivedDiffOfProductOrder,
    resetStockEveryDayForCentralKitchen,
    selectedStore,
    setDisplayProductPicture,
    setIsOpen,
    setOpenStockInfo,
    setUpdateAmount,
    startDate,
    storeInfos,
    sumUpRecommendations,
    supplierInfos,
    updateProduct,
    user,
  } = props;

  const nbColumns = columns.length;

  const [displayBigger, setDisplayBigger] = useState({ format: false, reference: false });

  const isTablet = window.innerWidth <= 1280;

  const unavailable = !product.available && isEditable && orderStatus < ORDER_STATUS.SENT;

  const isCreation = [ORDER_STATUS.CREATION, ORDER_STATUS.DRAFT].includes(orderStatus);

  const hasForecasts = user.hasForecasts;

  const isCentralMode = getIsCentralMode() || selectedStore.isKitchen;

  // For some customers (Krispy Kreme), when ordering from a Central, they don't want to use the order recos but the theoretical consumption
  // (as the DLC is 1 day, they never have any stock).
  const shouldUseConsumption = resetStockEveryDayForCentralKitchen && supplierInfos.isKitchen;

  useEffect(() => {
    if (openStockInfo.category === indexCategory) {
      if (openStockInfo.entity === indexEntity) {
        return setIsOpen(true);
      }
    }

    setIsOpen(false);
  }, [openStockInfo]);

  const handleInputChange = (e, type) => {
    let isFloat = false;

    if (
      typeof e === 'string' &&
      ((e.slice(-1) === '.' && !isNaN(product.ordered)) ||
        e.match(INPUT_REGEX_COMPARATOR) ||
        LAST_CHARACTER_IS_NUMBER.test(e))
    ) {
      isFloat = true;
    }

    const value = parseFloat(e, 10);
    const isNumber = Number.isFinite(value);

    if (type === PRODUCT_ORDER_TYPE.ORDERED && isNumber && value < 0) {
      updateProduct(product.id, type, 0, category);
    } else {
      updateProduct(product.id, type, isFloat ? e : isNumber ? value : 0, category);
    }
  };

  const selectDisplayBigger = (elt) => {
    setDisplayBigger({ ...displayBigger, [elt]: !displayBigger[elt] });
  };

  /*
    We use !(...) here because we want to know what the user can not do then we just take the opposite,
    it's way easier than checking what he can do
  */
  const userHasRightToEditOrder = !(
    (orderStatus === ORDER_STATUS.DRAFT && !canEditDraftOrder(authorizedActions)) ||
    ([ORDER_STATUS.SENT, ORDER_STATUS.RECEPTION].includes(orderStatus) &&
      !canEditSentOrReceivedOrder(authorizedActions))
  );

  const allowRowOpening =
    (!isCreation || (product.entityId && isEditable)) && userHasRightToEditOrder;

  const displayTotalPrice = () => {
    if (!!product.available) {
      return true;
    }
    if (!isEditable) {
      return true;
    }
    if (orderStatus >= ORDER_STATUS.SENT) {
      return true;
    }
    return false;
  };

  return (
    <>
      {orderStatus < ORDER_STATUS.SENT && (
        <DraftProductOrder
          allowRowOpening={allowRowOpening && !isCentralMode && !shouldUseConsumption}
          allProductsUnavailable={allProductsUnavailable}
          category={category}
          computedOrderedAmount={computedOrderedAmount}
          currency={currency}
          displayBigger={displayBigger}
          displayTotalPrice={displayTotalPrice()}
          endDate={endDate}
          entityStockEvent={entityStockEvent}
          handleInputChange={handleInputChange}
          hasForecasts={hasForecasts}
          indexCategory={indexCategory}
          indexEntity={indexEntity}
          isEditable={isEditable}
          isFirstElement={isFirstElement}
          isFirstElementAvailable={isFirstElementAvailable}
          isLastElement={isLastElement}
          isOpen={isOpen}
          isTablet={isTablet}
          multiple={multiple}
          nbColumns={nbColumns}
          orderStatus={orderStatus}
          prefillInpulse={prefillInpulse}
          product={product}
          selectDisplayBigger={selectDisplayBigger}
          selectedStore={selectedStore}
          setDisplayProductPicture={setDisplayProductPicture}
          setOpenStockInfo={setOpenStockInfo}
          setUpdateAmount={setUpdateAmount}
          startDate={startDate}
          storeInfos={storeInfos}
          sumUpRecommendations={sumUpRecommendations}
          supplierInfos={supplierInfos}
          unavailable={unavailable}
          updateProduct={updateProduct}
          userHasRightToEditOrder={userHasRightToEditOrder}
        />
      )}
      {orderStatus >= ORDER_STATUS.SENT && (
        <SentProductOrder
          allowRowOpening={allowRowOpening}
          category={category}
          currency={currency}
          deleteProductOrder={deleteProductOrder}
          displayBigger={displayBigger}
          handleInputChange={handleInputChange}
          handleInvoicedDiff={handleInvoicedDiff}
          handleReceivedDiff={handleReceivedDiff}
          hasBLI={hasBLI}
          indexCategory={indexCategory}
          indexEntity={indexEntity}
          invoicedDiffOfProductOrder={invoicedDiffOfProductOrder}
          isEditingPreparationOrder={isEditingPreparationOrder}
          isFirstElement={isFirstElement}
          isLastElement={isLastElement}
          isOpen={isOpen}
          isTablet={isTablet}
          multiple={multiple}
          nbColumns={nbColumns}
          orderStatus={orderStatus}
          preparationStatus={preparationStatus}
          product={product}
          receivedDiffOfProductOrder={receivedDiffOfProductOrder}
          selectDisplayBigger={selectDisplayBigger}
          setDisplayProductPicture={setDisplayProductPicture}
          setOpenStockInfo={setOpenStockInfo}
          unavailable={unavailable}
          updateProduct={updateProduct}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/order/orders'),
  resetStockEveryDayForCentralKitchen: getResetStockEveryDayForCentralKitchen(
    state.baseReducer.userRights,
  ),
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
  showMessage: (message, type, icon) => {
    dispatch(showConfirmationMessage(message, type, icon));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductOrder);
