import _ from 'lodash';
import i18next from 'i18next';

import { numberToFixed } from '@commons/utils/format';

const SHEET_NAME_TOPLINE = 'FORECAST.PRODUCT_MIX.EXPORT_TOPLINE_DATA_SHEET_NAME';
const SHEET_NAME_LISTING = 'FORECAST.PRODUCT_MIX.EXPORT_LISTING_DATA_SHEET_NAME';
const SHEET_NAME_AGGREGATED_DATA = 'FORECAST.PRODUCT_MIX.EXPORT_AGGREGATED_DATA_SHEET_NAME';
const PERCENTAGE = '%';

const getToplineSheetColumnsSettings = (forecastProperty, forecastName, currency) => [
  {
    propertyKey: 'date',
    name: i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_MIX_DATE_LABEL'),
    transform: (value) => value.format('L'),
  },
  {
    propertyKey: forecastProperty,
    name: forecastName,
  },
];

const AGGREGATED_SHEET_COLUMNS_SETTINGS = (useTtc) => [
  {
    propertyKey: 'category',
    name: i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_MIX_CATEGORY_LABEL'),
    transform: (value) => value.toUpperCase(),
  },
  {
    propertyKey: 'product.name',
    name: i18next.t('GENERAL.NAME'),
    transform: (value) => value,
  },
  {
    propertyKey: 'product.stats.unit',
    name: i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_UNIT_LABEL'),
    transform: (value) => numberToFixed(value, 2, '-'),
  },
  {
    type: 'currency',
    propertyKey: useTtc ? 'product.stats.ca' : 'product.stats.turnoverExcludingTaxes',
    name: i18next.t(useTtc ? 'GENERAL.TURNOVER_TAX_INCLUDED' : 'GENERAL.TURNOVER_EX_TAX'),
  },
];

const LISTING_SHEET_COLUMNS_SETTINGS = (useTtc) => [
  {
    propertyKey: 'date',
    name: i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_MIX_DATE_LABEL'),
    transform: (value) => value.format('L'),
  },
  {
    propertyKey: 'category',
    name: i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_MIX_CATEGORY_LABEL'),
    transform: (value) => value.toUpperCase(),
  },
  {
    propertyKey: 'product.name',
    name: i18next.t('GENERAL.NAME'),
  },
  {
    type: 'percentage',
    propertyKey: useTtc ? 'statsCategory.mix' : 'statsCategory.mixExcludingTaxes',
    name: `${i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_MIX_MIX_CATEGORY_LABEL')} (${PERCENTAGE})`,
  },
  {
    type: 'percentage',
    propertyKey: useTtc ? 'product.stats.mix' : 'product.stats.mixExcludingTaxes',
    name: `${i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_MIX_PRODUCT_LABEL')} (${PERCENTAGE})`,
  },
  {
    propertyKey: 'product.stats.unit',
    name: i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_UNIT_LABEL'),
    transform: (value) => numberToFixed(value, 2, '-'),
  },
  {
    type: 'currency',
    propertyKey: useTtc ? 'product.stats.price' : 'product.stats.priceExcludingTaxes',
    name: i18next.t(useTtc ? 'GENERAL.PRICE_TTC' : 'GENERAL.PRICE_EX_TAX'),
  },
  {
    type: 'currency',
    propertyKey: useTtc ? 'product.stats.ca' : 'product.stats.turnoverExcludingTaxes',
    name: i18next.t(useTtc ? 'GENERAL.TURNOVER_TAX_INCLUDED' : 'GENERAL.TURNOVER_EX_TAX'),
  },
  {
    type: 'percentage',
    propertyKey: 'product.stats.margin',
    name: `${i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_MARGIN_LABEL')} (${PERCENTAGE})`,
  },
];

export const createToplineSheet = (
  forecastProperty,
  toplineName,
  productMixListByDate,
  currency,
) => ({
  data: productMixListByDate,
  title: i18next.t(SHEET_NAME_TOPLINE),
  headers: getToplineSheetColumnsSettings(forecastProperty, toplineName, currency),
});

export const createListingSheet = (useTtc, productMixListByDate) => {
  const mappedData = [];

  for (const productMixDate of productMixListByDate) {
    const { date, productsByCategory } = productMixDate;

    _.keys(productsByCategory).forEach((category) => {
      const products = productsByCategory[category].products;
      const statsCategory = productsByCategory[category].stats;

      for (const product of products) {
        const context = {
          date,
          category,
          statsCategory,
          product,
        };

        mappedData.push(context);
      }
    });
  }

  return {
    data: mappedData,
    title: i18next.t(SHEET_NAME_LISTING),
    headers: LISTING_SHEET_COLUMNS_SETTINGS(useTtc),
  };
};

export const createAggregatedSheet = (useTtc, productMixListByDate) => {
  const aggregatedProductMix = productMixListByDate.reduce((result, productMixDate) => {
    const { productsByCategory } = productMixDate;

    _.keys(productsByCategory).forEach((category) => {
      if (!result[category]) {
        result[category] = {
          products: {},
          stats: [],
        };
      }

      const products = productsByCategory[category].products;

      for (const product of products) {
        if (!result[category].products[product.id]) {
          result[category].products[product.id] = {
            ...product,
            stats: [],
          };
        }

        result[category].products[product.id].stats.push(product.stats);
      }

      result[category].stats.push(productsByCategory[category].stats);
    });

    return result;
  }, {});

  const mappedData = [];

  _.keys(aggregatedProductMix).forEach((category) => {
    const totalMixCategory = aggregatedProductMix[category].stats.reduce(
      (result, { mix }) => (result += mix),
      0,
    );
    const totalMixExcludingCategory = aggregatedProductMix[category].stats.reduce(
      (result, { mixExcludingTaxes }) => (result += mixExcludingTaxes),
      0,
    );

    const formattedStatsCategory = {
      mix: totalMixCategory / aggregatedProductMix[category].stats.length,
      mixExcludingTaxes: totalMixExcludingCategory / aggregatedProductMix[category].stats.length,
    };

    const products = _.values(aggregatedProductMix[category].products);

    for (const product of products) {
      const aggregatedStatsProduct = product.stats.reduce(
        (result, stats) => {
          result.ca += stats.ca;
          result.turnoverExcludingTaxes += stats.turnoverExcludingTaxes;
          result.mix += stats.mix;
          result.mixExcludingTaxes += stats.mixExcludingTaxes;
          result.unit += stats.unit;
          result.price += stats.price;
          result.priceExcludingTaxes += stats.priceExcludingTaxes;
          result.margin += stats.margin;

          return result;
        },
        {
          ca: 0,
          mix: 0,
          unit: 0,
          price: 0,
          margin: 0,
          mixExcludingTaxes: 0,
          priceExcludingTaxes: 0,
          turnoverExcludingTaxes: 0,
        },
      );

      const formattedStatsProduct = {
        ca: aggregatedStatsProduct.ca,
        turnoverExcludingTaxes: aggregatedStatsProduct.turnoverExcludingTaxes,
        unit: aggregatedStatsProduct.unit,
        mix: aggregatedStatsProduct.mix / product.stats.length,
        mixExcludingTaxes: aggregatedStatsProduct.mixExcludingTaxes / product.stats.length,
        price: aggregatedStatsProduct.price / product.stats.length,
        priceExcludingTaxes: aggregatedStatsProduct.priceExcludingTaxes / product.stats.length,
        margin: aggregatedStatsProduct.margin / product.stats.length,
      };

      const context = {
        category,
        statsCategory: formattedStatsCategory,
        product: {
          ...product,
          stats: formattedStatsProduct,
        },
      };

      mappedData.push(context);
    }
  });

  return {
    data: mappedData,
    headers: AGGREGATED_SHEET_COLUMNS_SETTINGS(useTtc),
    title: i18next.t(SHEET_NAME_AGGREGATED_DATA),
  };
};
