import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: fit-content;

  gap: 24px;
  display: flex;
  align-items: center;

  #select-file-input {
    display: none;
  }
`;

export const ContainerPicture = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 50%;
  position: relative;
`;

export const Icon = styled.div`
  position: relative;
  z-index: 1;

  width: 64px;
  height: 64px;
  border-radius: 50%;

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.picture});

  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.08));

  &:hover {
    cursor: pointer;
  }
`;

export const IconOpacityEffect = styled.div`
  opacity: 0;

  width: inherit;
  height: inherit;
  border-radius: inherit;

  background-color: #22232140;

  &:hover {
    opacity: 1;

    cursor: pointer;
  }
`;

export const ContainerText = styled.div`
  flex: 1;
`;

export const ActionsContainer = styled.div`
  width: 240px;
  margin-top: 8px;

  position: absolute;

  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  background: ${(props) => props.theme.colors?.greys?.lightest};
`;

export const Action = styled.div`
  width: 100%;

  padding: 12px 8px;

  gap: 8px;
  display: flex;
  align-items: center;

  background: ${(props) => props.theme.colors?.greys?.lightest};

  &:first-child {
    border-radius: 8px 8px 0px 0px;
  }

  &:last-child {
    border-radius: 0px 0px 8px 8px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    background: ${(props) =>
      props.disabled ? props.theme.colors?.greys?.lightest : props.theme.colors?.greys?.lighter};
  }
`;

export const IconAction = styled.img`
  width: 16px;
`;

export const SubTitleIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;

  background-color: ${(props) => props.color};

  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.icon});
`;

export const SubTitleContainer = styled.div`
  display: flex;
  gap: 8px;
`;
