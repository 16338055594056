import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { PropGraphContent } from '../../interface';

import IconCloseCross from '../../../images/icon-close-cross.svg';
import IconNoData from '../../../images/icon-inpulse-no-data.svg';

import {
  GraphContainer,
  TitleCloseRow,
  TitleContainer,
  Title,
  Subtitle,
  CloseIcon,
  GraphContent,
  GraphDisplayContent,
  GraphBars,
  GraphLabels,
  GraphLabelDay,
  GraphValue,
  GraphBar,
  EmptyGraph,
  DisplayIconNoData,
  NoDataInfo,
} from './styledComponents';

import { getTheme } from '../../../utils/theme';
import { getRandomInt } from '../../../utils/format';

const GraphContentDisplay = (props: PropGraphContent): JSX.Element => {
  const { theme, graphConfiguration, graphs, openGraph } = props;
  const graphData = graphs?.length;
  let average = 0;
  let maxHeight = 0;

  if (graphs) {
    const salesList = graphs.map((graph) => graph.y).filter((sales) => sales);

    if (salesList && salesList.length) {
      average = Math.round(
        salesList.reduce((acc, salesOfDay) => acc + salesOfDay, 0) /
          salesList.length
      );
      maxHeight = average * 2;
    }
  }

  return (
    <ThemeProvider theme={getTheme(theme, 'expandableEmbeddedList')}>
      <GraphContainer onClick={(e) => e.stopPropagation()}>
        <TitleCloseRow>
          <TitleContainer>
            <Title>{graphConfiguration.title}</Title>
            <CloseIcon
              onClick={openGraph}
              src={IconCloseCross}
              alt="icon-close-cross"
            />
          </TitleContainer>
          {!!graphData && (
            <Subtitle>
              {graphConfiguration.subTitle} {average}
            </Subtitle>
          )}
        </TitleCloseRow>

        <GraphContent>
          {!!graphData && graphs && (
            <>
              {graphs.map((graph, index) => {
                const random = getRandomInt();
                const uniqId = `date-${graph.x[index]}-${random}`;
                const abscissaSplit = graph.x.split(' ');
                const height = (graph.y / maxHeight) * 100;

                return (
                  <GraphDisplayContent key={uniqId}>
                    <GraphBars nbColumn={graphs.length}>
                      <GraphValue id={index} nbColumn={graphs.length}>
                        {graph.y || 0}
                      </GraphValue>
                      <GraphBar
                        height={height}
                        id={index}
                        nbColumn={graphs.length}
                      />
                    </GraphBars>

                    <GraphLabels nbColumn={graphs.length}>
                      {abscissaSplit.map((abscissa) => {
                        const randomLabel = getRandomInt();
                        const uniqLabelId = `label-${abscissa}-${randomLabel}`;
                        return (
                          <GraphLabelDay key={uniqLabelId}>
                            {abscissa}
                          </GraphLabelDay>
                        );
                      })}
                    </GraphLabels>
                  </GraphDisplayContent>
                );
              })}
            </>
          )}

          {!graphData && (
            <EmptyGraph>
              <DisplayIconNoData src={IconNoData} alt="icon-no-data" />
              <NoDataInfo>{i18next.t('CHART_NO_DATA')}</NoDataInfo>
            </EmptyGraph>
          )}
        </GraphContent>
      </GraphContainer>
    </ThemeProvider>
  );
};

GraphContentDisplay.propTypes = {
  graphConfiguration: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }).isRequired,
  graphs: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string,
      y: PropTypes.number,
    })
  ),
  openGraph: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.objectOf(PropTypes.any),
};

GraphContentDisplay.defaultProps = {
  graphs: null,
  theme: null,
};

export default GraphContentDisplay;
