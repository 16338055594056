import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';

import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { ENUM_FONTS } from '@commons/Text';
import { numberToFixed } from '@commons/utils/format';
import DisplayNumber from '@commons/DisplayNumber';

import { getBreakagePropertyName } from '@selectors/featureProps';

import styled from 'styled-components';

export class FlopSales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      turnoverRatio: null,
      flopSales: [],
      productNameHover: null,
    };
  }

  formatName(name) {
    if (name.length >= 27) {
      return name.substring(0, 25) + '...';
    } else return name;
  }

  formatFlopSalesData = (flopSalesData) => {
    const { breakagePropertyName } = this.props;

    if (flopSalesData && flopSalesData.length > 0) {
      let turnoverRatio = 0;
      let flopSales = flopSalesData.map((sale) => {
        turnoverRatio += sale.share;
        sale[breakagePropertyName] = Math.round(sale[breakagePropertyName] * 100);
        sale.share = Math.round(sale.share * 10000) / 100;
        sale.currentWeekForecast = Math.round(sale.currentWeekForecast);
        return sale;
      });
      flopSales = flopSales.sort((saleA, saleB) => {
        if (saleA[breakagePropertyName] < saleB[breakagePropertyName]) return 1;
        else if (saleA[breakagePropertyName] > saleB[breakagePropertyName]) return -1;
        else return 0;
      });
      this.setState({ turnoverRatio: Math.round(turnoverRatio * 10000) / 100, flopSales });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.flopSalesData !== this.props.flopSalesData) {
      this.formatFlopSalesData(this.props.flopSalesData);
    }
  }

  componentDidMount() {}

  render() {
    const { isLoading, breakagePropertyName } = this.props;

    const { flopSales } = this.state;

    return (
      <div className={this.props.className}>
        <div className="activity-summary-sales-header">
          <div className="activity-summary-sales-header-left">
            <img
              alt=""
              src="/images/icon-thumb-down.png"
              style={{ width: '24px', height: '24px' }}
            />
            <div className="activity-summary-sales-header-left-text">
              {this.props.t('HOME.FLOP_SALES_HEADER')}
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="activity-summary-sales-empty-container">
            <DeepsightComponentLoader />
          </div>
        ) : (
          flopSales.length > 0 && (
            <>
              <div className="activity-summary-sales-tab-headers">
                <div className="activity-summary-sales-tab-headers-sales">
                  {this.props.t('HOME.LABEL_TURNOVER')}
                </div>
                <div className="activity-summary-sales-tab-headers-loss-ratio">
                  {this.props.t('HOME.LABEL_LOSS_PERCENTAGE')}
                </div>
                <div className="activity-summary-sales-tab-headers-ca-forecast">
                  {this.props.t('HOME.LABEL_FORECAST_CURRENT_WEEK')}
                </div>
              </div>
              <div className="activity-summary-sales-tab">
                {flopSales.map((sale, index) => (
                  <div
                    className={`activity-summary-sales-tab-row ${
                      index + 1 < flopSales.length ? 'border' : ''
                    }`}
                    key={index}
                  >
                    <div className="activity-summary-sales-tab-row-icon">
                      {index < 3 && (
                        <img
                          alt=""
                          src="/images/icon-thumb-down.png"
                          style={{ width: '15px', height: '15px' }}
                        />
                      )}
                      {index >= 3 && (
                        <img
                          alt=""
                          src="/images/icon-omg.png"
                          style={{ width: '15px', height: '15px' }}
                        />
                      )}
                    </div>
                    <div className="activity-summary-sales-tab-row-index">{index + 1}</div>
                    <div
                      className="activity-summary-sales-tab-row-name"
                      style={{ position: 'relative' }}
                      onMouseEnter={() => this.setState({ productNameHover: sale.productName })}
                      onMouseLeave={() => this.setState({ productNameHover: null })}
                    >
                      {this.formatName(sale.productName)}
                      {this.state.productNameHover === sale.productName &&
                        sale.productName.length >= 27 && (
                          <div className="sale-product-name-hover">{sale.productName}</div>
                        )}
                    </div>
                    <div className="activity-summary-sales-tab-row-sales">
                      <DisplayNumber
                        displayCurrencyCode={true}
                        font={ENUM_FONTS.TEXT_SMALL}
                        number={sale.turnover}
                        withoutDecimals={true}
                      />
                    </div>
                    <div className="activity-summary-sales-tab-row-loss-ratio">
                      {numberToFixed(sale[breakagePropertyName], 0, '-', '%')}
                    </div>
                    <div className="activity-summary-sales-tab-row-ca-forecast">
                      <DisplayNumber
                        displayCurrencyCode={true}
                        font={ENUM_FONTS.TEXT_SMALL}
                        number={sale.currentWeekForecast}
                        withoutDecimals={true}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )
        )}
        {isLoading
          ? undefined
          : flopSales.length === 0 && (
              <div className="activity-summary-sales-empty-container">
                <div style={{ paddingTop: '60px' }}>{this.props.t('HOME.NO_DATA_TO_DISPLAY')}</div>
              </div>
            )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  breakagePropertyName: getBreakagePropertyName(state.baseReducer.userRights),
});
const Connected = connect(mapStateToProps, null)(withTranslation()(FlopSales));

const InpulseFlopSales = styled(Connected)`
  background: ${(props) => props.theme.colors.greys.lightest};
  border-radius: ${(props) => props.theme.borders?.radius?.weak};
  margin-left: 30px;
  margin-right: 30px;
  width: 48%;
  min-width: 325px;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .activity-summary-sales-empty-container {
    height: 150px;
    text-align: center;
  }
  .activity-summary-sales-header {
    width: 100%;
    margin-top: 20px;
    display: flex;
  }

  .activity-summary-sales-header-left {
    font: ${(props) => props.theme.fonts?.h3};
    color: ${(props) => props.theme.colors.greys.darkest};
    display: flex;
    margin-left: 20px;
    width: 70%;
  }

  .activity-summary-sales-header-left-text {
    margin-left: 10px;
    margin-top: 5px;
  }

  .activity-summary-sales-ca-ratio {
    margin-left: 80%;
    font-size: 25px;
  }

  .activity-summary-sales-tab-headers {
    display: flex;
    width: 90%;
    margin-top: 20px;
    padding-bottom: 10px;
    border-bottom: ${(props) => `solid ${props.theme.colors.greys.light}`};
    border-width: thin;
    font: ${(props) => props.theme.fonts?.textSmall};
    color: ${(props) => props.theme.colors.greys.dark};
  }

  .activity-summary-sales-tab-headers-sales {
    margin-left: 55%;
    width: 10%;
  }

  .activity-summary-sales-tab-headers-sales,
  .activity-summary-sales-tab-headers-loss-ratio,
  .activity-summary-sales-tab-headers-ca-forecast {
    width: 15%;
  }

  .activity-summary-sales-tab {
    width: 90%;
    overflow-y: scroll;
    font: ${(props) => props.theme.fonts?.textSmall};
    color: ${(props) => props.theme.colors.greys.darkest};
    margin-bottom: 20px;
  }

  /********** SALES ROW **********/

  .activity-summary-sales-tab-row {
    display: flex;
    width: 100%;
    margin: 10px auto;
    padding-bottom: 10px;

    &.border {
      border-bottom: ${(props) => `solid ${props.theme.colors.greys.light}`};
      border-width: thin;
    }
  }

  .activity-summary-sales-tab-row-icon,
  .activity-summary-sales-tab-row-index {
    width: 5%;
  }

  .activity-summary-sales-tab-row-name {
    width: 45%;
  }

  .activity-summary-sales-tab-row-sales,
  .activity-summary-sales-tab-row-loss-ratio {
    width: 15%;
  }
`;

const DeepsightFlopSales = styled(Connected)`
  background: white;
  box-shadow: 0 2px 6px 0 rgba(168, 177, 196, 0.4);
  border-radius: 10px;
  margin-left: 30px;
  width: 46%;
  min-width: 325px;

  .activity-summary-sales-empty-container {
    height: 150px;
    text-align: center;
  }
  .activity-summary-sales-header {
    margin-top: 20px;
    display: flex;
  }

  .activity-summary-sales-header-left {
    display: flex;
    margin-left: 20px;
    width: 70%;
  }

  .activity-summary-sales-header-left-text {
    margin-left: 10px;
    margin-top: 5px;
  }

  .activity-summary-sales-header-right {
    margin-top: 5px;
    color: #838d9f;
  }

  .activity-summary-sales-ca-ratio {
    margin-left: 80%;
    font-size: 25px;
  }

  .activity-summary-sales-tab-headers {
    display: flex;
    width: 90%;
    margin: auto;
    margin-top: 40px;
    padding-bottom: 10px;
    border-bottom: solid #cfcfcf;
    border-width: thin;
    font-size: 12px;
  }

  .activity-summary-sales-tab-headers-sales {
    margin-left: 55%;
    width: 10%;
  }

  .activity-summary-sales-tab-headers-sales,
  .activity-summary-sales-tab-headers-loss-ratio,
  .activity-summary-sales-tab-headers-ca-forecast {
    width: 15%;
  }

  /********** SALES ROW **********/

  .activity-summary-sales-tab-row {
    display: flex;
    width: 90%;
    margin: 10px auto;
    padding-bottom: 10px;

    font-size: 12px;

    &.border {
      border-bottom: solid #cfcfcf;
      border-width: thin;
    }
  }

  .activity-summary-sales-tab-row-icon,
  .activity-summary-sales-tab-row-index {
    width: 5%;
  }

  .activity-summary-sales-tab-row-name {
    width: 45%;
  }

  .activity-summary-sales-tab-row-sales,
  .activity-summary-sales-tab-row-loss-ratio {
    width: 15%;
  }

  .activity-summary-sales-tab-row-ca-forecast {
    color: #2176fa;
  }
`;
export { InpulseFlopSales, DeepsightFlopSales };
