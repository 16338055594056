import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: fit-content;
  min-height: 24px;

  padding: 4px 8px;

  display: flex;
  align-items: center;

  border-radius: 4px;
  font: ${(props) => props.theme.label.font};

  ${({ type, color, isDisabled, theme }) => {
    if (type === 'tertiary') {
      if (color === 'ipGreen1') {
        return css`
          background-color: ${isDisabled
            ? theme.colors.greys.lightMedium
            : theme.colors.greys.lightest};
          border: 2px solid ${theme.colors.green.lighter};
          color: ${theme.colors.green.lighter};
        `;
      }

      if (color === 'green') {
        return css`
          background-color: ${isDisabled
            ? theme.colors.greys.lightMedium
            : theme.colors.greys.lightest};
          border: 2px solid ${theme.colors?.infoGreen};
          color: ${theme.colors?.infoGreen};
        `;
      }

      if (color === 'red') {
        return css`
          background-color: ${isDisabled
            ? theme.colors.greys.lightMedium
            : theme.colors.greys.lightest};
          border: 2px solid ${theme.colors?.infoRed};
          color: ${theme.colors?.infoRed};
        `;
      }

      if (color === 'orange') {
        return css`
          background-color: ${isDisabled
            ? theme.colors.greys.lightMedium
            : theme.colors.greys.lightest};
          border: 2px solid ${theme.colors?.infoOrange};
          color: ${theme.colors?.infoOrange};
        `;
      }

      if (color === 'black') {
        return css`
          background-color: ${isDisabled
            ? theme.colors.greys.lightMedium
            : theme.colors.greys.lightest};
          border: 2px solid ${theme.colors?.greys?.darkest};
          color: ${theme.colors?.greys?.darkest};
        `;
      }

      if (color === 'grey') {
        return css`
          background-color: ${isDisabled
            ? theme.colors.greys.lightMedium
            : theme.colors.greys.lightest};
          border: 2px solid ${theme.colors?.greys?.dark};
          color: ${theme.colors?.greys?.dark};
        `;
      }
    }

    if (type === 'secondary') {
      if (color === 'ipGreen1') {
        return css`
          background: ${isDisabled ? theme.colors.greys.lightMedium : theme.colors.green.lighter};
          color: ${theme.colors.blacks.ipBlack3};
        `;
      }

      if (color === 'green') {
        return css`
          background: ${isDisabled ? theme.colors.greys.lightMedium : theme.colors?.infoDarkGreen};
          color: ${theme.colors?.greys?.lightest};
        `;
      }

      if (color === 'orange') {
        return css`
          background: ${isDisabled ? theme.colors.greys.lightMedium : theme.colors?.infoOrange};
          color: ${theme.colors?.greys?.lightest};
        `;
      }

      if (color === 'red') {
        return css`
          background: ${isDisabled ? theme.colors.greys.lightMedium : theme.colors?.infoRed};
          color: ${theme.colors?.greys?.lightest};
        `;
      }
    }
  }}
`;
