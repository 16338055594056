import styled, { keyframes } from 'styled-components';

const gradient = keyframes`
  0% { opacity: 0.4; }
  50% { opacity: 1; }
  100% { opacity: 0.4; }
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-end;
`;

export const ChartContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-height: 65px;
  margin: 0 0 15px 15px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 10%;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

export const ItemColorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  border-radius: ${(props) =>
    props.isFirst || props.isLast ? (props.isFirst ? '3px 0 0 0' : '0 3px 0 0') : '0'};
  background-color: ${(props) =>
    props.isInPeriod ? props.theme.colors.greys.lighter : 'transparent'};
  animation: ${gradient} 1.5s linear infinite;
  padding-top: 5px;
`;

export const ChartBar = styled.div`
  height: ${(props) => `${props.height}px`};
  background-color: #bcc4d570;
  width: 10px;
  border-radius: 25px 25px 0 0;
  animation: ${gradient} 1.5s linear infinite;
`;

export default {
  Container,
  ChartContainer,
  ItemContainer,
  ItemColorContainer,
  ChartBar,
};
