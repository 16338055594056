import { METRIC_KEY } from './constants';

export const aggregateStatsOnCategoryLevel = (
  categoryData,
  turnoverByStoreId,
  startDate,
  endDate,
) => {
  const stats = categoryData.childItems.reduce(
    (result, entity) => {
      if (entity) {
        if (entity.loss != null) {
          result.loss += entity.loss;
        }

        if (entity.order != null) {
          result.order += entity.order;
        }

        if (entity.realConsumption != null) {
          result.realConsumption += entity.realConsumption;
        }

        if (entity.theoreticalConsumption != null) {
          result.theoreticalConsumption += entity.theoreticalConsumption;
        }

        if (entity.finalStock != null && entity.initialStock != null) {
          result.finalStock += entity.finalStock;
          result.initialStock += entity.initialStock;
        }
      }

      return result;
    },
    {
      loss: 0,
      order: 0,
      finalStock: null,
      initialStock: null,
      realConsumption: 0,
      theoreticalConsumption: 0,
    },
  );

  const realFromTheorical = stats.realConsumption - stats.theoreticalConsumption;

  const relativeRealFromTheorical = stats.theoreticalConsumption
    ? (realFromTheorical * 10000) / stats.theoreticalConsumption / 100
    : null;

  const realOverTurnover = turnoverByStoreId.total
    ? (10000 * stats.realConsumption) / turnoverByStoreId.total / 100
    : null;

  const inventoryShrinkage = realFromTheorical ? realFromTheorical - stats.loss : null;

  return {
    ...categoryData,
    ...stats,
    // variationStock,
    realOverTurnover,
    realFromTheorical,
    relativeRealFromTheorical,
    inventoryShrinkage,
  };
};

export const aggregateStatsOnTotalLevel = (
  totalLine,
  filteredData,
  turnoverByStoreId,
  startDate,
  endDate,
) =>
  Object.values(METRIC_KEY).reduce((acc, key) => {
    const total = filteredData[key].reduce(
      (result, entity) => {
        if (entity) {
          result.loss += entity.loss;
          result.order += entity.order;
          result.realConsumption += entity.realConsumption;
          result.theoreticalConsumption += entity.theoreticalConsumption;

          if (entity.finalStock != null && entity.initialStock != null) {
            result.finalStock += entity.finalStock;
            result.initialStock += entity.initialStock;
          }
        }

        return result;
      },
      {
        loss: 0,
        order: 0,
        finalStock: null,
        initialStock: null,
        realConsumption: 0,
        theoreticalConsumption: 0,
        relativeRealConsumptionOverview: 100,
        inventoryShrinkage: null,
      },
    );

    total.realFromTheorical = total.realConsumption - total.theoreticalConsumption;

    total.relativeRealFromTheorical = total.theoreticalConsumption
      ? (total.realFromTheorical * 10000) / total.theoreticalConsumption / 100
      : null;

    total.realOverTurnover = turnoverByStoreId.total
      ? (10000 * total.realConsumption) / turnoverByStoreId.total / 100
      : null;

    total.variationStock =
      total.finalStock != null && total.initialStock != null
        ? total.finalStock - total.initialStock
        : null;

    total.inventoryShrinkage = total.realFromTheorical
      ? total.realFromTheorical - total.loss
      : null;

    acc[key] = {
      ...totalLine,
      ...total,
    };
    return acc;
  }, {});

export default { aggregateStatsOnCategoryLevel, aggregateStatsOnTotalLevel };
