import i18next from 'i18next';
import React from 'react';

import DataFeedLogo from '@assets/logo/data-feed.svg';

import IncludeNextStepModal from '../../IncludeNextStepModal';

export const AskProductCashierProductMappingModal = (props) => {
  const { includeCashierProductMappingStep, setIncludeCashierProductMappingStep } = props;

  return (
    <IncludeNextStepModal
      iconSrc={DataFeedLogo}
      includeNextStep={includeCashierProductMappingStep}
      setIncludeNextStep={setIncludeCashierProductMappingStep}
      text={i18next.t('ADMIN.PRODUCTS.MODAL_ASK_CASHIER_PRODUCT_ASSOCIATION_INFO')}
      title={i18next.t('ADMIN.PRODUCTS.MODAL_ASK_CASHIER_PRODUCT_ASSOCIATION')}
    />
  );
};
