import _ from 'lodash';
import i18next from 'i18next';

import { numberToFixed } from '@commons/utils/format';

const SHEET_NAME_LISTING =
  'BACKOFFICE.PRODUCTS.CASHIER_PRODUCTS_GENERATE_MOST_CONSUMED_CASHIER_PRODUCTS_SHEETNAME';

function getListingSheetColumnsSettings() {
  return [
    {
      baseName: 'cashierProductId',
      displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_ID'),
      transform: (value) => value,
    },
    {
      baseName: 'name',
      displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_NAME'),
      transform: (value) => value,
    },
    {
      baseName: 'quantity',
      displayName: i18next.t('BACKOFFICE.CASHIER_PRODUCTS.EXPORT_MODAL_LABEL_QUANTITY'),
      transform: (value) => numberToFixed(value, 2, '-'),
    },
    {
      baseName: 'turnover',
      displayName: i18next.t('BACKOFFICE.PRODUCTS.EXPORT_MODAL_LABEL_TURNOVER_WITH_TAXES'),
      transform: (value) => numberToFixed(value, 2, '-'),
    },
    {
      baseName: 'turnoverPercentage',
      displayName: i18next.t(
        'BACKOFFICE.CASHIER_PRODUCTS.EXPORT_MODAL_LABEL_TURNOVER_PERCENTAGE_WITH_TAXES',
      ),
      transform: (value) => numberToFixed(value, 3, '-'),
    },
  ];
}

export function createListingSheet(cashierProducts) {
  const headersSettings = getListingSheetColumnsSettings();

  const headers = headersSettings.map(({ displayName }) => displayName);

  const mappedData = [];

  for (const cashierProduct of cashierProducts) {
    const { cashierProductId, name, quantity, turnover, turnoverPercentage } = cashierProduct;

    const context = {
      cashierProductId,
      name,
      quantity,
      turnover,
      turnoverPercentage,
    };

    mappedData.push(
      headersSettings.map(({ baseName, transform }) => transform(_.get(context, baseName))),
    );
  }

  return {
    title: i18next.t(SHEET_NAME_LISTING),
    headers,
    data: mappedData,
  };
}
