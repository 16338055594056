import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { showConfirmationMessage } from '@actions/messageconfirmation';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { backoffice as ingredientsService } from '@services/backoffice/ingredients';

import { SubContainer, SubContentLeft, SubContentRight, Text } from './styledComponents';

/**
 * Close the modal
 *
 * @returns {void}
 */
export function exitModal(setIsLoading, closeModal) {
  setIsLoading(false);

  closeModal();
}

export const EntityCostCalculModal = (props) => {
  const {
    params: { title, clientId },
    closeModal,
  } = props;

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [titleModal, setTitleModal] = useState(title || '');
  const [nbEntities, setNbEntities] = useState('');
  const [nbEntitiesUpdated, setNbEntitiesUpdated] = useState('');
  const [nbEntitiesCostAt0, setNbEntitiesCostAt0] = useState('');
  const [nbEntitiesCostAtNull, setNbEntitiesCostAtNull] = useState('');

  useEffect(() => {
    (async function launchCalcul() {
      try {
        const {
          nbEntities: nbEntities,
          nbEntitiesUpdated: nbEntitiesUpdated,
          nbEntitiesCostAt0: nbEntitiesCostAt0,
          nbEntitiesCostAtNull: nbEntitiesCostAtNull,
        } = await ingredientsService.updateIngredientsCostOfClient(clientId);
        setNbEntities(nbEntities);
        setNbEntitiesUpdated(nbEntitiesUpdated);
        setNbEntitiesCostAt0(nbEntitiesCostAt0);
        setNbEntitiesCostAtNull(nbEntitiesCostAtNull);
        setTitleModal(i18next.t('BACKOFFICE.PRODUCTS.COST_CALCUL_FINISHED'));
        setIsLoading(false);
      } catch (error) {
        props.showMessage('Echec du calcul', 'error');
      }
    })();
  }, []);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setProgress(100);
      }, 1000);
    }
  }, [isLoading]);

  /* return (
    <Container>
      {renderContent({
        ...props,
        closeModal,
        progress,
        titleModal,
        isLoading,
        setIsLoading,
        nbEntities,
        nbEntitiesUpdated,
        nbEntitiesCostAt0,
        nbEntitiesCostAtNull,
      })}
    </Container>
  ); */
  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setIsLoading}
      titleModal={titleModal}
    >
      <SubContainer>
        <SubContentLeft>
          <Text>
            <b>{i18next.t('BACKOFFICE.PRODUCTS.COST_CALCULATED')}:</b> {nbEntities}
          </Text>
          <Text>
            <b>{i18next.t('BACKOFFICE.PRODUCTS.COST_UPDATED')}:</b> {nbEntitiesUpdated}
          </Text>
        </SubContentLeft>
        <SubContentRight>
          <Text>
            <b>{i18next.t('BACKOFFICE.PRODUCTS.COST_AT_0')}:</b> {nbEntitiesCostAt0}
          </Text>
          <Text>
            <b>{i18next.t('BACKOFFICE.PRODUCTS.COST_AT_NULL')}:</b> {nbEntitiesCostAtNull}
          </Text>
        </SubContentRight>
      </SubContainer>
    </ExportModalContent>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
});

export default connect(null, mapDispatchToProps)(EntityCostCalculModal);
