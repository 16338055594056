import moment from 'moment-timezone';

import { getUserTimezone } from '@commons/utils/date';

const DATE_FORMAT = 'DDMMYY';
const PREFIX_ORDER_REFERENCE_NAME = 'CMD-';

const getOrderReference = (reference, partnerId, orderOpeningTime) => {
  if (reference) {
    return reference;
  }

  if (partnerId) {
    return `${PREFIX_ORDER_REFERENCE_NAME}${partnerId}-${moment
      .tz(getUserTimezone())
      .format(DATE_FORMAT)}-${orderOpeningTime}`;
  } else {
    return PREFIX_ORDER_REFERENCE_NAME;
  }
};

export default getOrderReference;
