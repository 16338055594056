import ClientContainer from './ClientContainer';
import styled from 'styled-components';

const StyledClientContainer = styled(ClientContainer)`
  font-family: ${(props) => props.theme.fonts?.families.primary};
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.greys.lighter};
  display: flex;
  position: fixed;
  & .topbar-page-selection {
    display: flex;
    height: 60px;
    background: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 3px;
  }
  .main-container {
    float: left;
    width: 100%;
    background-color: ${(props) => props.theme.colors.greys.lighter};
  }
  & .sidebar {
    height: 100%;
    width: 60px;
    background: ${(props) => props.theme.colors.greys.darkest};
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  & .sidebar-items-container {
    width: 100%;
    height: calc(100% - 146px);
  }
  & .sidebar-top {
    width: 100%;
  }

  & .sidebar-deepsight-logo {
    bottom: 0;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  & .sidebar-deepsight-logo > img {
    width: 36px;
    height: 36px;
    margin-bottom: 5px;
  }

  & .sidebar-deepsight-logo > p {
    font-family: ${(props) => props.theme.fonts?.families.primary};
    color: #ffffff;
    font-size: 9px;
  }

  & .sidebar-head {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .sidebar-head img {
    height: 36px;
    width: 36px;
    -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
    border-radius: 100%;
  }

  & .sidebar-title {
    font-family: ${(props) => props.theme.fonts?.families.primary};
    font-size: 16px;
    margin-top: 14px;
    color: #ffffff;
    text-align: center;
  }
`;

export default StyledClientContainer;
