import styled, { css } from 'styled-components';

export const ItemRowContainer = styled.div`
  margin: 0 0 0 0;
  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  border-bottom: 1px solid #e5e5e5;
  border-radius: inherit;

  &:hover #actionButton {
    filter: grayscale(0);
  }
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: flex-start;

  min-height: 48px;
  padding: 0 26px 0 0px;
  cursor: ${(props) => props.isExpandable && 'pointer'};
`;

export const ItemDisplayWithCustomModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex: 1;

  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth}px;
    `}
`;

export const ModalContainer = styled.div``;

export const ArrowIconContainer = styled.div`
  position: absolute;
  margin-top: 16px;
  left: 19px;
`;

export const NestedContentContainer = styled.div`
  margin: 16px 8px;
  padding: ${(props) =>
    props.customNestedContentPadding
      ? props.customNestedContentPadding
      : '16px 24px'};
  background: ${(props) => props.theme.graph?.background};
`;

// This is for the StockGraph component. The fixed height seems to be necessary for a correct display
export const NestedStockGraphContainer = styled.div`
  height: 466px;
  margin: 16px 8px;

  padding: 16px 24px;
  background: ${(props) => props.theme.graph?.background};
`;

export default {
  ItemRowContainer,
  ItemRow,
  ItemDisplayWithCustomModal,
  ModalContainer,
  ArrowIconContainer,
};
