import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openSmallModal, openModal, openGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Filters } from '@commons/Filters';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { isUserDeepsight } from '@commons/utils/roles';
import { ListView, Button } from '@commons/utils/styledLibraryComponents';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { useFiltersButtonState } from '@hooks/useFiltersButtonState';

import { canCreateTransfer } from '@selectors/actions/transferActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { brand as brandService } from '@services/brand';
import {
  getInventoryTransferListByStoreIds,
  deleteInventoryTransferListByIds,
  getInventoryTransferListWithInventoriesById,
} from '@services/inventoryTransferList';
import clientService from '@services/client';
import storeService from '@services/store';

import ConfirmationModal from '@orders/components/ConfirmationModal';

import {
  ComponentContainer,
  ListViewContainer,
  EmptyStateContainer,
  GlobalEmptyStateImage,
  GlobalEmptyStateTitle,
  GlobalEmptyStateSubTitle,
  FiltersContainer,
} from './styledComponents';
import { getActions, getRowActions } from './utils/getActions';
import { getColumns } from './utils/getColumns';
import TransferExportContentModal from './utils/exports/exportContent';
import TransferForm from './components/TransferForm';

const FILTER_TYPES = ['date', 'string', 'numeric'];

export const StocksInventoriesTransfer = (props) => {
  const {
    user,
    client: { clientId, storeName },
    userActiveStores,
    match,
    showSuccessMessage,
    showErrorMessage,
    pageLoading,
    pageLoaded,
    openModalExportInfo,
    openModal,
    authorizedActions,
  } = props;

  const path = _.get(match, 'path');

  const clientStoreNameTranslation = getClientStoreNameTranslation(storeName);

  const userLanguageCode = _.get(user, 'lnkLanguageAccountrel.code', 'fr');

  const [columnsConfig] = useState(getColumns(clientStoreNameTranslation));

  const [transfersToDisplay, setTransfersToDisplay] = useState([]);
  const [filteredTransfers, setFilteredTransfers] = useState([]);
  const [isLoadingListView, setIsLoadingListView] = useState(false);
  const [isLoadingGlobal, setIsLoadingGlobal] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  /* The isFistFetch exists because we want to display the global empty state only if the user has no transfers but not when we apply the filters. 
  Both use the same function to fetch the transfers so we check if the user has associated transfers on the first fetch, if he doesn't we display the 
  global empty state, if he does we don't display the global empty state and if after that he applies filters that fetch no data we will only display 
  the list view empty state and not the global empty state */
  const [isFirstFetch, setIsFirstFetch] = useState(true);

  // Filters states
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const filtersButtonState = useFiltersButtonState(advancedFilters);
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [retailers, setRetailers] = useState([]);
  const [selectedRetailers, setSelectedRetailers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);

  const columnsFilterList = columnsConfig.filter((column) =>
    FILTER_TYPES.includes(column.filterType),
  );

  const [filtersModalParams, setFilterModalParams] = useState({
    applyFilters,
    setApplyFilters,
    filters,
    setFilters,
    advancedFilters,
    setAdvancedFilters,
    selectedBrands,
    setSelectedBrands,
    selectedRetailers,
    setSelectedRetailers,
    selectedLocations,
    setSelectedLocations,
    selectedStores,
    setSelectedStores,
    columnsFilterList,
  });

  const formatTransfersToDisplay = (fetchedTransfers) => {
    const transfers = fetchedTransfers.reduce((result, transfer) => {
      if (!!transfer.senderStore.active && !!transfer.recipientStore.active) {
        result.push({
          ...transfer,
          senderStoreName: _.get(transfer, 'senderStore.name', '---'),
          recipientStoreName: _.get(transfer, 'recipientStore.name', '---'),
        });
      }
      return result;
    }, []);

    return transfers;
  };

  const fetchTransfers = async () => {
    if (isFirstFetch) {
      setIsLoadingGlobal(true);
      pageLoading();
    }
    setIsLoadingListView(true);

    const storesIds = selectedStores.map((store) => store.id);

    try {
      const transfers = await getInventoryTransferListByStoreIds(storesIds);
      const formattedTransfers = formatTransfersToDisplay(transfers);
      setTransfersToDisplay(formattedTransfers);
      setFilteredTransfers(formattedTransfers);

      if (isFirstFetch && !_.isEmpty(formattedTransfers)) {
        setIsFirstFetch(false);
      }
    } catch (err) {
      showErrorMessage(i18next.t('STOCKS.TRANSFERS.FETCH_ERROR'));
      setTransfersToDisplay([]);
      setFilteredTransfers([]);
    }

    setIsLoadingListView(false);
    setIsLoadingGlobal(false);
    pageLoaded();
  };

  const openNewTransfer = () => {
    const params = {
      component: TransferForm,
      fetchTransfers,
    };

    openModal(params, true);
  };

  const openExistingTransfer = async (transferId) => {
    try {
      const { data } = await getInventoryTransferListWithInventoriesById(transferId);

      const clientStores = await storeService.getStoresOfClient(clientId);

      const recipientStore = clientStores.find(({ id }) => id === data.recipientStoreId);

      const params = {
        component: TransferForm,
        inventoryTransferList: data,
        recipientStoreName: recipientStore.name,
        fetchTransfers,
      };

      openModal(params, true);
    } catch {
      showErrorMessage(i18next.t('STOCKS.TRANSFERS.SINGLE_TRANSFER_FETCH_ERROR'));
    }
  };

  // Filters data
  const fetchBrands = async () => {
    try {
      const clientBrands = await brandService.getBrandsOfClient(clientId);

      setBrands(clientBrands);

      return clientBrands;
    } catch (error) {
      showErrorMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_BRANDS_FAILURE'));
      return [];
    }
  };

  const fetchRetailers = async () => {
    try {
      const clientRetailers = await clientService.getRetailersOfClient(clientId);

      setRetailers(clientRetailers);

      return clientRetailers;
    } catch (error) {
      showErrorMessage(i18next.t('PRODUCTION.PRODUCTION.TOP_ERROR_MESSAGE_RETAILERS'));
      return [];
    }
  };

  const fetchLocations = async () => {
    if (!isUserDeepsight(user)) {
      setLocations([]);
      return [];
    }

    try {
      const clientLocations = await clientService.getLocationsByClientId(clientId);

      setLocations(clientLocations);

      return clientLocations;
    } catch (err) {
      showErrorMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_LOCATIONS_FAILURE'));
      return [];
    }
  };

  const handleTransferExportContent = (transfers) => {
    openModalExportInfo({
      component: TransferExportContentModal,
      title: i18next.t('STOCKS.TRANSFERS.EXPORT_MODAL_TITLE'),
      transfers,
    });
  };

  const handleDeleteInventoryTransferListByIds = async (inventoryTransferListIds) => {
    pageLoading();

    try {
      await deleteInventoryTransferListByIds(inventoryTransferListIds);
      showSuccessMessage(i18next.t('STOCKS.TRANSFERS.DELETE_SUCCESS'));
    } catch (err) {
      showErrorMessage(i18next.t('STOCKS.TRANSFERS.DELETE_FAILURE'));
    } finally {
      pageLoaded();
    }
  };

  const openDeleteConfirmationModal = (inventoryTransferListIds) => {
    const params = {
      type: 'warning',
      width: '542px',
      height: 'auto',
      icon: '/images/inpulse/warning-white-small.svg',
      title: i18next.t('STOCKS.TRANSFERS.DELETE_CONFIRMATION_MODAL_TITLE'),
      component: ConfirmationModal,
      data: {
        content: i18next.t('STOCKS.TRANSFERS.DELETE_CONFIRMATION_MODAL_CONTENT'),
      },
      actions: [
        {
          key: 0,
          color: 'inpulse-outline',
          label: i18next.t('GENERAL.CANCEL'),
          icon: '/images/inpulse/close-black-small.svg',
        },
        {
          key: 1,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.VALIDATE'),
          icon: '/images/inpulse/check-white-small.svg',
          handleClick: async () => {
            await handleDeleteInventoryTransferListByIds(inventoryTransferListIds);

            await fetchTransfers();
          },
        },
      ],
    };
    props.openGenericModal(params);
  };

  useEffect(() => {
    if (_.isEmpty(userActiveStores)) {
      return;
    }

    (async function loadData() {
      setIsLoadingListView(true);
      const clientBrands = await fetchBrands();
      const clientRetailers = await fetchRetailers();
      const clientLocations = await fetchLocations();

      setSelectedStores(userActiveStores);

      const updatedFilters = {
        brands: !_.isEmpty(clientBrands)
          ? {
              list: [...clientBrands],
              selectedBrands,
            }
          : null,
        retailers: !_.isEmpty(clientRetailers)
          ? {
              list: [...clientRetailers],
              selectedRetailers,
            }
          : null,
        locations: !_.isEmpty(clientLocations)
          ? {
              list: [...clientLocations],
              selectedLocations,
            }
          : null,
        stores: {
          activeList: [...userActiveStores],
          list: [...userActiveStores],
          selectedStores: [...userActiveStores],
        },
      };
      setFilters(updatedFilters);
      setApplyFilters(true);
    })();
  }, [userActiveStores.length]);

  useEffect(() => {
    const actions = getActions(
      selectedItems,
      filteredTransfers,
      openModalExportInfo,
      clientStoreNameTranslation,
      openModal,
      openDeleteConfirmationModal,
      fetchTransfers,
      handleTransferExportContent,
      authorizedActions,
    );

    setActions(actions);
  }, [filteredTransfers, selectedItems, userActiveStores, transfersToDisplay]);

  useEffect(() => {
    const rowActions = getRowActions(
      openModalExportInfo,
      clientStoreNameTranslation,
      openDeleteConfirmationModal,
      handleTransferExportContent,
      authorizedActions,
    );

    setRowActions(rowActions);
  }, [userActiveStores, transfersToDisplay]);

  useEffect(() => {
    if (!!applyFilters) {
      fetchTransfers();
    }
  }, [applyFilters]);

  useEffect(() => {
    setFilterModalParams({
      ...filtersModalParams,
      brands,
      retailers,
      locations,
      stores: userActiveStores,
      filters,
    });
  }, [brands, retailers, locations, userActiveStores, filters]);

  useEffect(() => {
    setFilterModalParams({
      ...filtersModalParams,
      selectedBrands,
      selectedRetailers,
      selectedLocations,
      selectedStores,
      filters,
    });
  }, [selectedBrands, selectedRetailers, selectedLocations, selectedStores, filters]);

  // HANDLE ADVANCED FILTERS
  useEffect(() => {
    setFilterModalParams({
      ...filtersModalParams,
      advancedFilters,
    });
  }, [advancedFilters]);

  useEffect(() => {
    if (!applyFilters) {
      return;
    }
    const filteredTransfers = transfersToDisplay;

    if (!advancedFilters || !advancedFilters.length) {
      setFilteredTransfers(filteredTransfers);
      return;
    }

    if (!isLoadingListView) {
      const filteredTransferListWithAdvancedFilters = advancedFilters.reduce(
        (result, { doFilter, propertyKey, value }) => doFilter(result, propertyKey, value),
        filteredTransfers,
      );

      setFilteredTransfers(filteredTransferListWithAdvancedFilters);
    }
  }, [advancedFilters, applyFilters, isLoadingListView]);

  return (
    <ComponentContainer>
      <NavigationBreadCrumb featurePath={path} />
      {!isLoadingGlobal && (
        <ListViewContainer>
          {isFirstFetch && _.isEmpty(transfersToDisplay) ? (
            <EmptyStateContainer>
              <GlobalEmptyStateImage src="/images/inpulse/supplier-ingredients.svg" />
              <GlobalEmptyStateTitle>
                {i18next.t('STOCKS.TRANSFERS.GLOBAL_EMPTY_STATE_TITLE')}
              </GlobalEmptyStateTitle>
              <GlobalEmptyStateSubTitle>
                {i18next.t('STOCKS.TRANSFERS.GLOBAL_EMPTY_STATE_SUBTITLE')}
              </GlobalEmptyStateSubTitle>
              {canCreateTransfer(authorizedActions) && (
                <Button
                  color={'inpulse-default'}
                  handleClick={openNewTransfer}
                  icon={'/images/inpulse/add-white-small.svg'}
                  label={i18next.t('GENERAL.CREATE')}
                />
              )}
            </EmptyStateContainer>
          ) : (
            <ListView
              actionOnClick={(transfer) => openExistingTransfer(transfer.id)}
              actions={actions}
              columns={columnsConfig}
              data={filteredTransfers}
              defaultOrderBy="sentAt"
              defaultOrderType={'desc'}
              forceEnableSelection={true}
              isLoading={isLoadingListView}
              languageCode={userLanguageCode}
              padding={'20px 24px 0px 24px'}
              placeholderShape={i18next.t('GENERAL.SEARCH')}
              renderEmptyState={() => (
                <EmptyStateContainer>
                  <EmptyState />
                </EmptyStateContainer>
              )}
              renderFilterButton={() => (
                <FiltersContainer>
                  <Filters
                    filtersButtonState={filtersButtonState}
                    filtersModalParams={filtersModalParams}
                    filterText={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
                  />
                </FiltersContainer>
              )}
              rowActions={rowActions}
              setSelectedItems={setSelectedItems}
            />
          )}
        </ListViewContainer>
      )}
    </ComponentContainer>
  );
};

StocksInventoriesTransfer.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  userActiveStores: state.baseReducer.activeStores,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/stocks/inventories/transfer',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (params, fullscreen) => {
    dispatch(openModal(params, fullscreen));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StocksInventoriesTransfer);
