import { connect } from 'react-redux';
import { keyBy, groupBy, orderBy, isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';

import { DeepsightCheckbox, InpulseLabel } from '@commons/DeepsightComponents';
import {
  Dropdown,
  INPUT_WIDTH,
  Label,
  SearchBar,
  Tooltip,
} from '@commons/utils/styledLibraryComponents';
import { getTheme } from '@commons/utils/theme';
import DisplayNumber from '@commons/DisplayNumber';

import { getClientInfo } from '@selectors/client';

import { CHOICES_DROPDOWN_ACTIVE, CHOICES_DROPDOWN_MAPPED } from '@admin/utils/DropdownItems';

import {
  ContainerLastSync,
  Content,
  ContentHeader,
  ContentList,
  DateContainer,
  FilterContainer,
  Line,
  ListRowText,
  PointerDiv,
  Row,
  TagContainer,
  TagHoverBox,
  TagInfo,
  TagLabel,
  Text,
} from './styledComponents';

import { SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING } from '@admin/cashier/cashierProducts/common/constants';

import { cashierProductService } from '@services/cashierProduct';
import { showErrorMessage } from '@actions/messageconfirmation';

const theme = getTheme();

const HEADER_NAME = () => ({
  baseName: 'name',
  displayName: i18next.t('GENERAL.NAME'),
  renderItem: (item) => <ListRowText className="link">{item}</ListRowText>,
});

const HEADER_SIMILARITY_SCORE = () => ({
  baseName: 'similarityScore',
  displayName: i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATION_MODAL_LIST_COLUMN_RELEVANCE'),
  renderItem: (score) => {
    // We use floor and not round because we don't want a score of 100 if it's not totally equal
    const formattedScore = score ? Math.floor(score) : 0;

    const isRelevant = formattedScore >= SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING;

    return (
      <Label
        background={isRelevant ? theme.colors.brand.secondary : theme.colors.greys.lighty}
        color={isRelevant ? theme.colors.greys.darkest : theme.colors.greys.lightest}
        type={'plain'}
        width={'fit-content'}
      >
        {formattedScore}
      </Label>
    );
  },
});

const HEADER_SKU = () => ({
  baseName: 'sku',
  displayName: i18next.t('GENERAL.SKU'),
  renderItem: (item) => (
    <ListRowText className="sku-label">{`#${item}` || 'Non défini'}</ListRowText>
  ),
});

const HEADER_TAG = () => ({
  baseName: 'tags',
  displayName: i18next.t('ADMIN.PRODUCTS.CREATE_PRODUCT_MODAL_CASHIER_PRODUCT_ASSOCIATION_TAGS'),
  renderItem: (item) => renderTags(item),
});

const HEADER_CREATED = () => ({
  baseName: 'createdAt',
  displayName: i18next.t(
    'ADMIN.PRODUCTS.CREATE_PRODUCT_MODAL_CASHIER_PRODUCT_ASSOCIATION_ADD_DATE',
  ),
  renderItem: (item) =>
    item ? <DateContainer bold>{moment(item).format('DD MMMM YYYY')}</DateContainer> : '',
});

const HEADER_PRICE = () => ({
  baseName: 'priceWithTaxes',
  displayName: (
    <Fragment>
      <span>{i18next.t('GENERAL.PRICE_TTC')}</span>
      <Tooltip
        text={i18next.t(
          'ADMIN.PRODUCTS.CREATE_PRODUCT_MODAL_CASHIER_PRODUCT_ASSOCIATION_ON_SITE_PRICE',
        )}
      />
    </Fragment>
  ),
  renderItem: (item) => <DisplayNumber displayCurrencyCode={true} number={item} />,
});

const HEADER_VAT_RATE = () => ({
  baseName: 'vatRate',
  displayName: i18next.t('GENERAL.VAT_RATE'),
  renderItem: (item) => <ListRowText>{item !== null ? `${item}%` : '-'}</ListRowText>,
});

const HEADER_SYNC = () => ({
  baseName: 'isAssociated',
  displayName: i18next.t('GENERAL.LINKED'),
  renderItem: (item) => renderLabelAssociation(item),
});

export const renderTags = (itemList) => {
  if (!itemList || !itemList.length) {
    return null;
  }
  if (itemList.length === 1) {
    return (
      <TagContainer>
        <TagLabel>{itemList[0]}</TagLabel>
      </TagContainer>
    );
  }
  return (
    <TagContainer>
      <TagLabel>{itemList[0]}</TagLabel>
      <TagInfo>{`+ ${itemList.length - 1}`}</TagInfo>
      <TagHoverBox>
        <PointerDiv />
        {itemList.map((item, index) => (
          <TagLabel className="box" key={index}>
            {item}
          </TagLabel>
        ))}
      </TagHoverBox>
    </TagContainer>
  );
};

export const renderLabelAssociation = (item) => (
  <div className="supplier-label-render">
    <InpulseLabel
      color={item ? 'green' : 'red'}
      text={i18next.t(item ? 'GENERAL.YES' : 'GENERAL.NO')}
    />
  </div>
);
/*********************/
/** Handler Methods **/
/*********************/

export function handleListSelection(
  itemsToDisplay,
  selectionCashierProducts,
  setSelectionCashierProducts,
  selectedId,
) {
  const selectionCashierProductsKeyById = keyBy(selectionCashierProducts, 'id');

  const itemsToDisplayGroupByDisplay = groupBy(itemsToDisplay, 'display');

  if (!selectedId) {
    const items = !!itemsToDisplayGroupByDisplay[true]
      ? Object.values(itemsToDisplayGroupByDisplay[true])
      : [];

    const areAllSelected = items.every((item) => item.selected);

    for (const item of items) {
      if (selectionCashierProductsKeyById[item.id]) {
        selectionCashierProductsKeyById[item.id].selected = areAllSelected ? false : true;
      }
    }

    setSelectionCashierProducts(Object.values(selectionCashierProductsKeyById));

    return;
  }

  if (selectionCashierProductsKeyById[selectedId]) {
    selectionCashierProductsKeyById[selectedId].selected =
      !selectionCashierProductsKeyById[selectedId].selected;
  }

  setSelectionCashierProducts(Object.values(selectionCashierProductsKeyById));
}

export function handleSearchAndFilterLogic(
  headers,
  searchInput,
  selectionCashierProducts,
  setItemstoDisplay,
) {
  const updatedSelectionCashierProducts = selectionCashierProducts.map((item) => {
    item.display = headers.some((header) => {
      if (!item[header.baseName] || typeof item[header.baseName] !== 'string') {
        return false;
      }

      return item[header.baseName].toLowerCase().includes(searchInput.toLowerCase());
    });

    return item;
  });

  setItemstoDisplay(updatedSelectionCashierProducts);
}

export function keyPress(
  e,
  headers,
  searchInput,
  selectionCashierProducts,
  setSelectionCashierProducts,
  setItemstoDisplay,
) {
  if (e.keyCode === 13) {
    handleSearchAndFilterLogic(
      headers,
      searchInput,
      selectionCashierProducts,
      setSelectionCashierProducts,
      setItemstoDisplay,
    );
  }
}

export function isFormValid(selectionCashierProducts) {
  const selectedItems = selectionCashierProducts.filter(
    (selectionCashierProduct) => selectionCashierProduct.selected,
  );

  return !!selectedItems.length;
}

/********************/
/** Render Methods **/
/********************/

export function renderLastSync(lastSync) {
  if (!lastSync) {
    return null;
  }

  const now = moment();
  const formattedDate = moment(lastSync);

  const isToday = formattedDate.isSame(now, 'day');
  const isYesterday = formattedDate.isSame(now.subtract(1, 'day'), 'day');

  const date = formattedDate.format('DD/MM');
  const time = formattedDate.format('HH:mm');

  return (
    <Text grey>
      {isToday
        ? i18next.t('ADMIN.CASHIER_PRODUCTS.LAST_SYNC_TODAY', { time })
        : isYesterday
        ? i18next.t('ADMIN.CASHIER_PRODUCTS.LAST_SYNC_YESTERDAY', { time })
        : i18next.t('ADMIN.CASHIER_PRODUCTS.LAST_SYNC_DATE_PARAM', { date, time })}
    </Text>
  );
}

export function RenderContent(props) {
  const {
    headers,
    selectionCashierProducts,
    setSelectionCashierProducts,
    activeKeysDropdowns,
    handleActiveKeysDropdownSelection,
    lastSync,
    similarityScoresKeyByName,
  } = props;

  const [itemsToDisplay, setItemstoDisplay] = useState();

  useEffect(() => {
    if (!isEmpty(similarityScoresKeyByName)) {
      const cashierProductsWithScores = selectionCashierProducts.map((cashierProduct) => ({
        ...cashierProduct,
        similarityScore: similarityScoresKeyByName[cashierProduct.name],
      }));

      const sortedCashierProductsWithScores = orderBy(
        cashierProductsWithScores,
        'similarityScore',
        'desc',
      );

      setItemstoDisplay(sortedCashierProductsWithScores.filter(({ hidden }) => !hidden));
      return;
    }
    setItemstoDisplay([]);
  }, [selectionCashierProducts, similarityScoresKeyByName]);

  if (!itemsToDisplay) return <></>;

  const itemsToDisplayGroupByDisplay = groupBy(itemsToDisplay, 'display');

  const selectedActiveKeysDropdowns = activeKeysDropdowns?.filter(
    (activeKey) => activeKey.propertyKey === 'active',
  );

  const selectedMappedKeysDropdowns = activeKeysDropdowns?.filter(
    (activeKey) => activeKey.propertyKey === 'isAssociated',
  );

  return (
    <Content>
      <FilterContainer>
        <SearchBar
          placeholder={`${itemsToDisplay?.length} produit${
            itemsToDisplay?.length > 1 ? 's' : ''
          } caisse`}
          setValue={(input) =>
            handleSearchAndFilterLogic(
              headers,
              input,
              selectionCashierProducts,
              setSelectionCashierProducts,
              setItemstoDisplay,
            )
          }
        />
        <Dropdown
          customStyle={{ margin: '0px 8px' }}
          iconSrc={
            !selectedActiveKeysDropdowns
              ? '/images/inpulse/location-dmgrey-small.svg'
              : '/images/inpulse/location-black-small.svg'
          }
          isUniqueSelection={false}
          items={CHOICES_DROPDOWN_ACTIVE}
          selectedItems={selectedActiveKeysDropdowns}
          width={INPUT_WIDTH.MEDIUM}
          onSelectionChange={handleActiveKeysDropdownSelection}
        />
        <Dropdown
          customStyle={{ marginRight: '8px' }}
          iconSrc={
            !selectedMappedKeysDropdowns
              ? '/images/inpulse/location-dmgrey-small.svg'
              : '/images/inpulse/location-black-small.svg'
          }
          isUniqueSelection={false}
          items={CHOICES_DROPDOWN_MAPPED}
          selectedItems={selectedMappedKeysDropdowns}
          width={INPUT_WIDTH.MEDIUM}
          onSelectionChange={handleActiveKeysDropdownSelection}
        />
        <ContainerLastSync>{renderLastSync(lastSync)}</ContainerLastSync>
      </FilterContainer>
      <ContentHeader>
        <Line className="header">
          <DeepsightCheckbox
            handleClick={() => {
              handleListSelection(
                itemsToDisplay,
                selectionCashierProducts,
                setSelectionCashierProducts,
              );
            }}
            isChecked={
              !!itemsToDisplay?.length &&
              itemsToDisplayGroupByDisplay[true] &&
              Object.values(itemsToDisplayGroupByDisplay[true]).every((item) => item.selected)
            }
            style="square"
            type={'inpulse'}
          />
          {headers?.map((header, indexHeader) => (
            <Row
              isEllipsis={header.baseName === 'name'}
              key={`${indexHeader}`}
              width={
                header.baseName === 'vatRate' || header.baseName === 'isAssociated'
                  ? 'small'
                  : 'normal'
              }
            >
              {header.displayName}
            </Row>
          ))}
        </Line>
      </ContentHeader>
      <ContentList>
        {itemsToDisplay?.map(
          (item, indexList) =>
            !!item.display && (
              <Line key={`${indexList}`}>
                <DeepsightCheckbox
                  handleClick={() => {
                    handleListSelection(
                      itemsToDisplay,
                      selectionCashierProducts,
                      setSelectionCashierProducts,
                      item.id,
                    );
                  }}
                  isChecked={item.selected}
                  style="square"
                  type={'inpulse'}
                />
                {headers?.map((header, indexHeader) => (
                  <Row
                    isEllipsis={header.baseName === 'name'}
                    key={`${indexList}-${indexHeader}`}
                    width={
                      header.baseName === 'vatRate' || header.baseName === 'isAssociated'
                        ? 'small'
                        : 'normal'
                    }
                  >
                    {header.renderItem(item[header.baseName])}
                  </Row>
                ))}
              </Line>
            ),
        )}
      </ContentList>
    </Content>
  );
}

const StepCashierProductAssociation = (props) => {
  const {
    onCashierProductsChange,
    cashierProductsClient,
    client: { lastSync, clientId },
    product,
    pageLoading,
    pageLoaded,
    showErrorMessage,
  } = props;

  const [activeFilters, setActiveFilters] = useState([
    CHOICES_DROPDOWN_ACTIVE[0],
    CHOICES_DROPDOWN_MAPPED[1],
  ]);
  const [activeKeysDropdowns, setActiveKeysDropdowns] = useState([
    CHOICES_DROPDOWN_ACTIVE[0],
    CHOICES_DROPDOWN_MAPPED[1],
  ]);

  const [selectionCashierProducts, setSelectionCashierProducts] = useState([]);
  const [cashierProductsWithScores, setCashierProductsWithScores] = useState([]);
  const [similarityScoresKeyByName, setSimilarityScoresKeyByName] = useState({});

  const searchInput = useRef('');

  const getHeaders = () => [
    HEADER_NAME(),
    HEADER_SIMILARITY_SCORE(),
    HEADER_CREATED(),
    HEADER_TAG(),
    HEADER_SKU(),
    HEADER_PRICE(),
    HEADER_VAT_RATE(),
    HEADER_SYNC(),
  ];

  const computeMappingScoresAndSetCashierProductsWithScores = async () => {
    const scores =
      await cashierProductService.getCashierProductsWithProductMappingScoresByProductName(
        product.name,
        cashierProductsClient.map(({ name }) => name),
        clientId,
      );

    setSimilarityScoresKeyByName(scores);

    const formattedCashierProducts = cashierProductsClient.map((cashierProduct) => ({
      ...cashierProduct,
      similarityScore: scores[cashierProduct.name],
    }));

    const sortedCashierProducts = orderBy(formattedCashierProducts, 'similarityScore', 'desc');

    setCashierProductsWithScores(sortedCashierProducts);
  };

  useEffect(() => {
    if (!cashierProductsClient.length) {
      return;
    }

    (async () => {
      try {
        pageLoading();
        await computeMappingScoresAndSetCashierProductsWithScores();
      } catch {
        showErrorMessage(i18next.t('BACKOFFICE.PRODUCTS.CASHIER_PRODUCTS_RETRIEVING_ERROR'));
      } finally {
        pageLoaded();
      }
    })();
  }, [cashierProductsClient]);

  useEffect(() => {
    if (selectionCashierProducts) {
      const selectedCashierProducts = selectionCashierProducts.filter(
        (selectionCashierProduct) => selectionCashierProduct.selected,
      );

      onCashierProductsChange(selectedCashierProducts);
    }
  }, [selectionCashierProducts]);

  useEffect(() => {
    if (!activeFilters.length) {
      setSelectionCashierProducts(cashierProductsWithScores);

      return;
    }

    let updatedFilteredItems = [...cashierProductsClient];

    activeFilters.forEach((activeKey) => {
      updatedFilteredItems = updatedFilteredItems.filter((item) => {
        if (item[activeKey.propertyKey] === activeKey.filterValue) {
          item.display = true;
          return item;
        }
      });
    });

    setSelectionCashierProducts(updatedFilteredItems);
  }, [activeFilters, cashierProductsWithScores]);

  /************************/
  /** DROPDOWNS HANDLERS **/
  /************************/

  const handleActiveKeysDropdownSelection = (keys) => {
    if (keys.length === 0) {
      return;
    }

    // update dropdowns selection
    const updatedKeysDropdowns = activeKeysDropdowns.filter(
      (activeKey) => activeKey.propertyKey !== keys[0].propertyKey,
    );

    setActiveKeysDropdowns([...updatedKeysDropdowns, ...keys]);

    if (keys.length === 2) {
      // if both choices are selected, remove dropdown from filters
      const updatedFilters = activeFilters.filter(
        (activeKey) => activeKey.propertyKey !== keys[0].propertyKey,
      );

      setActiveFilters(updatedFilters);

      return;
    }

    // if one choice is selected, replace old choice by new one
    const updatedFilters = activeFilters.filter(
      (activeKey) => activeKey.propertyKey !== keys[0].propertyKey,
    );

    setActiveFilters([...updatedFilters, ...keys]);
  };

  if (!selectionCashierProducts && !activeKeysDropdowns) return <></>;

  return (
    <RenderContent
      activeKeysDropdowns={activeKeysDropdowns}
      handleActiveKeysDropdownSelection={handleActiveKeysDropdownSelection}
      headers={getHeaders()}
      lastSync={lastSync}
      searchInput={searchInput}
      selectionCashierProducts={selectionCashierProducts}
      setSelectionCashierProducts={setSelectionCashierProducts}
      similarityScoresKeyByName={similarityScoresKeyByName}
    />
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StepCashierProductAssociation);
