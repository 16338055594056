import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { get, set } from 'lodash';

import moment from 'moment';

import centralKitchenProductionPlanningReducer from './centralKitchenProductionPlanningReducer';
import stockFormReducer from './stockFormReducer';

function findForecast(toAmend, forecasts) {
  let res = -1;
  forecasts.forEach((elem, index) => {
    if (
      moment(elem.timestamp).isSame(moment(toAmend.timestamp).endOf('day'), 'day') &&
      elem.type === toAmend.type
    ) {
      res = index;
    }
  });
  return res;
}

function baseReducer(state = {}, action) {
  switch (action.type) {
    case 'NEW_APP_VERSION_RECEIVED': {
      if (state.shouldReloadApp !== action.shouldReloadApp) {
        return Object.assign({}, state, { shouldReloadApp: action.shouldReloadApp });
      }

      return state;
    }
    case 'EVENTS_SUCCESS':
      return Object.assign({}, state, {
        events: action.events,
      });
    case 'IS_SYNCHRONIZING_CASHIER_PRODUCTS_UPDATE':
      return Object.assign({}, state, {
        isSynchronizingCashierProducts: action.isSynchronizingCashierProducts,
      });
    case 'IS_SYNCHRONIZING_CASHIER_STORES_UPDATE':
      return Object.assign({}, state, {
        isSynchronizingCashierStores: action.isSynchronizingCashierStores,
      });
    case 'IS_SYNCHRONIZING_CASHIER_PRODUCTS_SALES_UPDATE':
      return Object.assign({}, state, {
        isSynchronizingCashierProductsSales: action.isSynchronizingCashierProductsSales,
      });
    case 'IS_CASHIER_PRODUCT_SALES_INGESTION_PIPELINE_RUNNING_UPDATE':
      return Object.assign({}, state, {
        isCashierProductSalesIngestionPipelineRunning:
          action.isCashierProductSalesIngestionPipelineRunning,
      });
    case 'PAST_EVENTS_SUCCESS':
      return Object.assign({}, state, {
        pastEvents: action.pastEvents,
      });
    case 'WEATHER_SUCCESS':
      return Object.assign({}, state, {
        weather: action.weather,
      });
    case 'PAST_WEATHER_SUCCESS':
      return Object.assign({}, state, {
        pastWeather: action.pastWeather,
      });
    case 'LOGIN_SUCCESS':
      return Object.assign({}, state, {
        user: action.user,
      });
    case 'LOGOUT_SUCCESS':
      return Object.assign({}, state, {
        user: {},
        stores: [],
        userRights: [],
        activeStores: [],
      });
    case 'OFFLINE_STATUS_UPDATE': {
      return Object.assign({}, state, {
        isUserOffline: action.isUserOffline,
      });
    }
    case 'SLOW_CONNECTION_UPDATE': {
      return Object.assign({}, state, {
        isConnectionSlow: action.isConnectionSlow,
      });
    }
    case 'USER_SUCCESS':
      return Object.assign({}, state, {
        user: action.user,
      });
    case 'USER_FAILURE':
      return Object.assign({}, state, {
        user: {},
      });
    case 'CURRENCY_SUCCESS':
      return Object.assign({}, state, {
        currency: action.currency,
      });
    case 'CURRENCY_FAILURE':
      return Object.assign({}, state, {
        currency: {},
      });
    case 'USER_RIGHTS_SUCCESS':
      return Object.assign({}, state, {
        userRights: action.userRights,
      });
    case 'USER_RIGHTS_FAILURE':
      return Object.assign({}, state, {
        userRights: [],
      });
    case 'STORES_SUCCESS':
      return Object.assign({}, state, {
        stores: action.stores,
        activeStores: action.stores.filter((store) => store.active),
      });
    case 'SALES_SUCCESS':
      return Object.assign({}, state, {
        sales: action.sales,
      });
    case 'PAST_SALES_SUCCESS':
      return Object.assign({}, state, {
        pastSales: action.pastSales,
      });
    case 'FORECASTS_SUCCESS':
      return Object.assign({}, state, {
        forecasts: action.forecasts,
      });
    case 'PAST_FORECASTS_SUCCESS':
      return Object.assign({}, state, {
        pastForecasts: action.pastForecasts,
      });
    case 'PRODUCTS_SUCCESS':
      return Object.assign({}, state, {
        products: action.products,
      });
    case 'SUPPLIERS_SUCCESS':
      return Object.assign({}, state, {
        suppliers: action.suppliers,
      });
    case 'SUPPLIER_PRODUCTS_SUCCESS':
      return Object.assign({}, state, {
        supplierProducts: action.supplierProducts,
      });
    case 'WEATHER_FORECAST_STATION_SUCCESS':
      return Object.assign({}, state, {
        weatherStation: action.weatherStation,
      });
    case 'WEEKLY_SALES_SUCCESS':
      return Object.assign({}, state, {
        weeklySales: action.weeklySales,
      });
    case 'WEEKLY_FORECASTS_SUCCESS':
      return Object.assign({}, state, {
        weeklyForecasts: action.weeklyForecasts,
      });
    case 'RANGE_SALES_SUCCESS':
      return Object.assign({}, state, {
        rangeSales: action.rangeSales,
      });
    case 'RANGE_FORECASTS_SUCCESS':
      return Object.assign({}, state, {
        rangeForecasts: action.rangeForecasts,
      });
    case 'FORECASTS_PATCH_BY_DATE_SUCCESS': {
      let newForecastsArray = action.forecastToAmend,
        aggregatedForecast = {};
      const forecasts = state.forecasts;
      aggregatedForecast = JSON.parse(JSON.stringify(newForecastsArray))[0];
      if (newForecastsArray && newForecastsArray.length > 1) {
        if (newForecastsArray[0].service && newForecastsArray[0].service !== null) {
          let tot = 0;
          newForecastsArray.forEach((newForecast) => {
            aggregatedForecast[newForecast.service] = newForecast.tot;
            tot += newForecast.tot;
          });
          aggregatedForecast.tot = tot;
        }
      }
      let existingLocation = findForecast(aggregatedForecast, forecasts);
      if (existingLocation > -1) {
        const fc = forecasts
          .slice(0, existingLocation)
          .concat(aggregatedForecast)
          .concat(forecasts.slice(existingLocation + 1));
        return Object.assign({}, state, {
          forecasts: fc,
        });
      } else {
        const fc = forecasts.concat(aggregatedForecast);
        return Object.assign({}, state, {
          forecasts: fc,
        });
      }
    }
    case 'EVENT_SUB_CATEGORY_SUCCESS':
      return Object.assign({}, state, {
        eventSubCategories: action.eventSubCategories,
      });
    case 'ORDER_LOGIC_DIFF_UPDATE':
      return Object.assign({}, state, {
        diffChange: action.diffChange,
      });
    case 'LAST_YEAR_SALES_SUCCESS':
      return Object.assign({}, state, {
        lastYearSales: action.lastYearSales,
      });
    case 'STORE_PRODUCT_MAPPING_SUCCESS':
      return Object.assign({}, state, {
        storeProductMappings: action.storeProductMappings,
      });
    case 'SINGLE_STORE_SUPPLIER_PRODUCT_MAPPING_SUCCESS':
      return Object.assign({}, state, {
        storeSupplierProductMapping: action.storeSupplierProductMapping,
      });
    case 'STORE_SUPPLIER_PRODUCT_MAPPING_SUCCESS':
      return Object.assign({}, state, {
        storeSupplierProductMappings: action.storeSupplierProductMappings,
      });
    case 'STORES_SUPPLIER_PRODUCT_MAPPING_OF_ACCOUNT_STORES_SUCCESS':
      return Object.assign({}, state, {
        storeSupplierProductMappings: action.storeSupplierProductMappings,
      });
    case 'CLEAR_FORECASTS':
      return Object.assign({}, state, {
        forecasts: [],
        sales: [],
        lastYearSales: [],
        weather: [],
        events: [],
      });
    case 'LY_EVENTS_SUCCESS':
      return Object.assign({}, state, {
        lyEvents: action.lyEvents,
      });
    case 'LY_WEATHER_SUCCESS':
      return Object.assign({}, state, {
        lyWeather: action.lyWeather,
      });
    case 'SINGLE_STORE_SUCCESS':
      return Object.assign({}, state, {
        store: action.store,
      });
    case 'INGREDIENTS_OF_CLIENT_SUCCESS':
      return Object.assign({}, state, {
        ingredients: action.ingredients,
      });
    case 'RECIPES_SUCCESS':
      return Object.assign({}, state, {
        recipes: action.recipes,
      });
    case 'RECIPES_BY_CATEGORIES_SUCCESS':
      return Object.assign({}, state, {
        recipesByCategories: action.recipesByCategories,
      });
    case 'RECIPE_SUCCESS':
      return Object.assign({}, state, {
        recipe: action.recipe,
      });
    case 'SUPPLIERS_CATEGORIES_SUCCESS':
      return Object.assign({}, state, {
        suppliersCategories: action.suppliersCategories,
      });
    case 'CLIENT_BRANDS_SUCCESS':
      return Object.assign({}, state, {
        brands: action.brands,
      });
    case 'SUPPLIER_PROFILES_OF_STORE_SUCCESS':
      return Object.assign({}, state, {
        supplierProfiles: action.supplierProfiles,
      });
    case 'PRODUCTION_METRICS_BY_DATES_SUCCESS':
      return Object.assign({}, state, {
        productionMetricsByDates: action.productionMetricsByDates,
      });
    case 'UPDATE_CENTRAL_MODE':
      return Object.assign({}, state, {
        isCentralView: action.centralMode,
      });
    case 'FEATURES_SUCCESS':
      return Object.assign({}, state, {
        featuresKeyByPath: action.features,
      });
    default:
      return state;
  }
}

const loadingReducer = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName]: requestState === 'REQUEST',
  };
};

const modalReducer = (state = {}, action) => {
  switch (action.type) {
    case 'MODAL_OPEN':
      return Object.assign({}, state, {
        modalBool: true,
        params: action.params,
        deactivateBackgroundClick: action.deactivateBackgroundClick,
        fullscreen: action.fullscreen,
      });
    case 'MODAL_CLOSE':
      return Object.assign({}, state, {
        modalBool: false,
        params: {},
      });
    case 'CONFIRMATION_MODAL_OPEN':
      return Object.assign({}, state, {
        confModalBool: true,
        params: action.params,
      });
    case 'CONFIRMATION_MODAL_CLOSE':
      return Object.assign({}, state, {
        confModalBool: false,
        params: {},
      });
    case 'MEDIUM_MODAL_OPEN':
      return Object.assign({}, state, {
        mediumModalBool: true,
        sizeModal: action.sizeModal,
        customStyle: action.customStyle,
        deactivateBackgroundClick: action.deactivateBackgroundClick,
        params: action.params,
      });
    case 'MEDIUM_MODAL_CLOSE':
      return Object.assign({}, state, {
        mediumModalBool: false,
        params: {},
      });
    case 'SUPPLIER_INGREDIENT_ALREADY_ASSOCIATED_WARNING_MODAL_OPEN':
      return Object.assign({}, state, {
        SupplierIngredientAlreadyAssociatedWarningModalBool: true,
        params: action.params,
      });
    case 'SUPPLIER_INGREDIENT_ALREADY_ASSOCIATED_WARNING_MODAL_CLOSE':
      return Object.assign({}, state, {
        SupplierIngredientAlreadyAssociatedWarningModalBool: false,
        params: {},
      });
    case 'GENERIC_MODAL_OPEN':
      return Object.assign({}, state, {
        GenericModalBool: true,
        params: action.params,
      });
    case 'GENERIC_MODAL_REFRESH': {
      if (!action.customPropertyPathsToUpdate.length) {
        return Object.assign({}, state, {
          params: { ...state.params, ...action.params },
        });
      }

      const updatedParams = { ...state.params };

      for (const propertyPath of action.customPropertyPathsToUpdate) {
        const data = get(action, `params.${propertyPath}`);

        if (data) {
          set(updatedParams, propertyPath, data);
        }
      }

      return Object.assign({}, state, {
        params: updatedParams,
      });
    }
    case 'GENERIC_MODAL_CLOSE':
      return Object.assign({}, state, {
        GenericModalBool: false,
        params: {},
      });
    case 'SLIDE_MODAL_OPEN':
      return Object.assign({}, state, {
        slideModalBool: true,
        params: action.params,
      });
    case 'SLIDE_MODAL_CLOSE':
      return Object.assign({}, state, {
        slideModalBool: false,
        params: {},
      });
    default:
      return state;
  }
};

const switchButtonReducer = (state = {}, action) => {
  switch (action.type) {
    case 'OPEN_GRAPH_FOR_SUPPLIER_PRODUCT':
      return Object.assign({}, state, {
        supplierProductId: action.supplierProductId,
      });
    default:
      return state;
  }
};

const confirmationMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case 'MESSAGE_SHOW':
      return Object.assign({}, state, {
        messageBool: true,
        message: action.message,
        messageType: action.messageType,
        messageCustomIcon: action.messageCustomIcon,
        messageDismissTime: action.messageDismissTime,
        messageToDismissManually: action.messageToDismissManually,
      });
    case 'MESSAGE_HIDE':
      return Object.assign({}, state, {
        messageBool: false,
      });
    default:
      return state;
  }
};

const orderConfirmationMessageReducer = (state = {}, action) => state;

const rootReducer = combineReducers({
  baseReducer,
  loadingReducer,
  modalReducer,
  stockFormReducer,
  switchButtonReducer,
  confirmationMessageReducer,
  orderConfirmationMessageReducer,
  centralKitchenProductionPlanningReducer,
  form: formReducer,
});

export default rootReducer;
