import i18next from 'i18next';

import { getClientStoreNameTranslation } from '@commons/utils/translations';

export default ({ isCentral, storeName, displayCustomerCode, displayPackagingWeight }) => {
  const columns = [
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_REFERENCE'),
      propertyKey: 'order.reference',
    },
    {
      name: getClientStoreNameTranslation(storeName),
      propertyKey: 'store.name',
    },
    {
      name: i18next.t('BACKOFFICE.CASHIER_STORES.PARTNER_ID'),
      propertyKey: 'store.partnerId',
    },
    {
      name: i18next.t(isCentral ? 'GENERAL.CENTRAL' : 'ORDERS.ORDERS.LIST_LABEL_SUPPLIER'),
      propertyKey: 'supplier.name',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_DELIVERY_DATE'),
      propertyKey: 'order.deliveryDate',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_CREATION_DATE'),
      propertyKey: 'order.orderDate',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_CATEGORY'),
      propertyKey: 'supplierProduct.subCategory',
    },
    { name: i18next.t('ORDERS.ORDERS.LIST_LABEL_SKU'), propertyKey: 'supplierProduct.sku' },
    {
      name: i18next.t('GENERAL.SUPPLIER_PRODUCT'),
      propertyKey: 'supplierProduct.name',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_ORDER_PACKAGING'),
      propertyKey: 'ordered.packagingName',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_ORDERED_QUANTITY'),
      propertyKey: 'ordered.quantity',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_PRICE_EX_TAX_BY_ORDER_PACKAGING'),
      propertyKey: 'ordered.price',
      type: 'currency',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_INVOICE_PACKAGING'),
      propertyKey: 'invoiced.packagingName',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_QUANTITY_BDL'),
      propertyKey: 'invoiced.quantity',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_PRICE_EX_TAX_BDL_BY_INVOICE_PACKAGING'),
      propertyKey: 'invoiced.price',
      type: 'currency',
    },
    {
      name: i18next.t('ORDERS.ORDERS.EXPORT_VAT_RATE'),
      propertyKey: 'supplierProduct.vatRate',
    },
  ];

  if (!isCentral) {
    const orderedPriceColumnIndex = columns.findIndex(
      ({ propertyKey }) => propertyKey === 'ordered.price',
    );

    columns.splice(
      orderedPriceColumnIndex + 1,
      0,
      {
        name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_PACKAGING'),
        propertyKey: 'received.packagingName',
      },
      {
        name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEIVED'),
        propertyKey: 'received.quantity',
      },
      {
        name: i18next.t('ORDERS.ORDERS.LIST_LABEL_THEORETICAL_PRICE_BY_RECEPTION_PRICE'),
        propertyKey: 'received.price',
        type: 'currency',
      },
    );
    columns.push({
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_ANOMALY'),
      propertyKey: 'anomaly',
    });
  }

  if (displayCustomerCode) {
    columns.splice(4, 0, {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_CUSTOMER_CODE'),
      propertyKey: 'customerCode',
    });
  }

  if (displayPackagingWeight) {
    columns.push({
      name: i18next.t('ORDERS.ORDERS.EXPORT_TOTAL_WEIGHT'),
      propertyKey: 'totPackagingWeight',
    });
  }

  return columns;
};
