import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const ColumnsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border: 1px solid transparent;

  min-width: 1302px;
`;

export const Column = styled.div`
  color: ${(props) => props.theme.colors?.greys.darker || '#979797'};
  flex-basis: ${(props) =>
    !!props.weightedCoefficientWidthColumn
      ? `calc(${(100 / props.nbColumns) * props.weightedCoefficientWidthColumn}%)`
      : `calc(${100 / props.nbColumns}%)`};
  font: ${(props) => props.theme.fonts?.textBigHeight16 || ''};
  display: flex;
  &:nth-child(1) {
    padding: 0 0 0 16px;
  }
`;

export const Icon = styled.img`
  margin-left: 8px;
`;

export const ColumnSubContainer = styled.div`
  display: flex;
  width: 100%;
`;

export default {
  Container,
  ColumnsContainer,
  Column,
  Icon,
  ColumnSubContainer,
};
