import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ComposedChart,
  ResponsiveContainer,
} from 'recharts';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';
import React, { Component } from 'react';

import { StyledCustomLegend } from '@lib/inpulse/ChartLegend';
import { StyledPrecisionTooltip } from '@lib/inpulse/PrecisionChartTooltip';

import theme from '@theme';

const XFormatter = (n) => moment(n, 'YYYY-MM-DD').format('DD/MM');

const customMargin = (numberOfElement, width) => {
  const margin = 'calc((100% - ' + numberOfElement * width + 'px)/' + numberOfElement * 2 + ')';
  return {
    width: width + 'px',
    marginLeft: margin,
    marginRight: margin,
  };
};

const customLegend = (props) => {
  const { payload } = props;
  const containerStyle = customMargin(payload.length, 125);
  return (
    <div className="bar-chart-legend-container">
      {payload.map((item, index) => (
        <div className="bar-chart-legend-item-container" key={index} style={containerStyle}>
          <div className={'error-forecast-chart-legend'} style={{ backgroundColor: item.color }} />
          <div className="error-forecast-chart-legend-value">{item.value}</div>
        </div>
      ))}
    </div>
  );
};

const renderTooltip = ({ payload, label, legend, metricName }) => {
  if (!payload) {
    return '';
  }
  let globalPayload = payload[0] !== undefined ? payload[0].payload : null;
  if (!globalPayload || payload.length === 0) {
    return '';
  }
  return (
    <div className="chart-precision-tooltip-container">
      <div className="chart-precision-tooltip-header">
        {moment(label, 'YYYY-MM-DD').format('dddd Do MMMM YYYY')}
      </div>
      <div className="chart-precision-tooltip-content">
        <div className="chart-precision-tooltip-column">
          <h3 style={{ color: legend[2].color }}>
            {globalPayload.sales !== null ? globalPayload.sales : '-'}
          </h3>
          <h4 style={{ color: legend[2].color }}>{metricName}</h4>
          <div className="chart-precision-tooltip-column-encloser">
            <div className="chart-precision-tooltip-column-child">
              <h3 style={{ color: legend[0].color }}>
                {globalPayload.forecastsAlgo !== null ? globalPayload.forecastsAlgo : '-'}
              </h3>
              <h4 style={{ color: legend[0].color }}>
                {i18next.t('FORECAST.SALES.PRECISON_CHART_TOOLTIP_ALGO')}
              </h4>
              <h3 style={{ color: legend[0].color }}>
                {globalPayload.errorAlgo !== null ? globalPayload.errorAlgo : '-'}
              </h3>
              <h4 style={{ color: legend[0].color }}>
                {i18next.t('FORECAST.SALES.PRECISON_CHART_TOOLTIP_ALGO_DELTA')}
              </h4>
            </div>
            <div className="chart-precision-tooltip-column-child">
              <h3 style={{ color: legend[1].color }}>
                {globalPayload.forecastsDyn !== null ? globalPayload.forecastsDyn : '-'}
              </h3>
              <h4 style={{ color: legend[1].color }}>
                {i18next.t('FORECAST.SALES.PRECISON_CHART_TOOLTIP_DYNAMIC')}
              </h4>
              <h3 style={{ color: legend[1].color }}>
                {globalPayload.errorDyn !== null ? globalPayload.errorDyn : '-'}
              </h3>
              <h4 style={{ color: legend[1].color }}>
                {i18next.t('FORECAST.SALES.PRECISON_CHART_TOOLTIP_DYNAMIC_DELTA')}
              </h4>
            </div>
            <div className="chart-precision-tooltip-column-child">
              <h3 style={{ color: legend[3].color }}>
                {globalPayload.forecastsDyn !== null ? globalPayload.forecastsSaved : '-'}
              </h3>
              <h4 style={{ color: legend[3].color }}>
                {i18next.t('FORECAST.SALES.PRECISON_CHART_TOOLTIP_SAVED')}
              </h4>
              <h3 style={{ color: legend[3].color }}>
                {globalPayload.errorDyn !== null ? globalPayload.errorSaved : '-'}
              </h3>
              <h4 style={{ color: legend[3].color }}>
                {i18next.t('FORECAST.SALES.PRECISON_CHART_TOOLTIP_SAVED_DELTA')}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export class DeepsightForecastPrecisionChart extends Component {
  render() {
    const legend = [
      {
        value: this.props.t('FORECAST.SALES.PRECISON_CHART_LABEL_ALGO'),
        color: theme.colors.brand.secondaryDark,
      },
      {
        value: this.props.t('FORECAST.SALES.PRECISON_CHART_LABEL_DYNAMIC'),
        color: theme.colors.brand.secondary,
      },

      {
        value: this.props.metricName,
        color: theme.colors.greys.dark,
      },

      {
        value: this.props.t('FORECAST.SALES.PRECISON_CHART_LABEL_MANAGER'),
        color: theme.colors.greys.darkest,
      },
    ];
    return (
      <StyledPrecisionTooltip>
        <StyledCustomLegend>
          <ResponsiveContainer height={250} minWidth={400} width="100%">
            <ComposedChart data={this.props.data} height={100} margin={{ top: 20 }}>
              <CartesianGrid stroke={theme.colors.greys.light} strokeWidth={1} vertical={false} />
              <Line
                dataKey="forecastsAlgo"
                dot={false}
                stroke={legend[0].color}
                strokeWidth={2}
                type="monotone"
              />
              <Line
                dataKey="forecastsDyn"
                dot={false}
                stroke={legend[1].color}
                strokeDasharray="10 10"
                strokeWidth={2}
                type="monotone"
              />
              <Line
                dataKey="forecastsSaved"
                dot={false}
                stroke={legend[3].color}
                strokeWidth={2}
                type="monotone"
              />
              <Line
                dataKey="sales"
                dot={false}
                stroke={legend[2].color}
                strokeWidth={2}
                type="monotone"
              />
              <XAxis
                dataKey="day"
                dy={5}
                tick={{
                  fill: theme.colors.greys.dark,
                  fontSize: 12,
                  fontFamily: theme.fonts.families.secondary,
                }}
                tickFormatter={XFormatter}
              />
              <YAxis
                axisLine={false}
                tick={{
                  fill: theme.colors.greys.dark,
                  fontSize: 12,
                  fontFamily: theme.fonts.families.secondary,
                }}
                tickLine={false}
                type="number"
              />
              <Legend content={customLegend} payload={legend} />
              <Tooltip content={renderTooltip} legend={legend} metricName={this.props.metricName} />
            </ComposedChart>
          </ResponsiveContainer>
        </StyledCustomLegend>
      </StyledPrecisionTooltip>
    );
  }
}

export default withTranslation()(DeepsightForecastPrecisionChart);
