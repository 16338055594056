import React from 'react';

import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { Icon, Container } from './styledComponents';

export default () => (
  <Container>
    <Icon src={'/images/inpulse/product.svg'} />
    <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG_HEIGHT_24_BOLD}>
      {'Aucune recette associée à ce produit'}
    </Text>
  </Container>
);
