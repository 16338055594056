import { head } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import sortActionsList from '@commons/utils/sortActionsList';

import { getAssignPropertyModalParams } from './modals';

export const getRowActionsSettings = ({
  brands,
  history,
  retailers,
  clientLocations,
  openGenericModal,
  hasMultipleBrands,
  filteredStoresList,
  handleAssignPropertiesChange,
  openAccountsAssociationModal,
  openEnableStoresModal,
  translatedClientStoreName,
  handleGoToStoreDetailPage,
}) => {
  const activeStore = filteredStoresList.length && head(filteredStoresList).active;

  const rowActions = [
    {
      id: 'action-brand',
      actionLabel: () => i18next.t('BACKOFFICE.STORES.LIST_ACTION_ASSOCIATE_USER'),
      handleAction: (store) => openAccountsAssociationModal([store]),
      actionIcon: () => '/images/inpulse/link-black-small.svg',
    },
    {
      id: 'assign-brand',
      actionLabel: () => i18next.t('GENERAL.LIST_ACTION_ASSIGN_BRAND'),
      handleAction: (store) => {
        const params = getAssignPropertyModalParams(
          brands,
          [store],
          'brandName',
          handleAssignPropertiesChange,
        );

        openGenericModal(params);
      },
      actionIcon: () => '/images/inpulse/link-black-small.svg',
      isHidden: () => !hasMultipleBrands,
    },
    {
      id: 'assign-retailer',
      actionLabel: () => i18next.t('GENERAL.LIST_ACTION_ASSIGN_RETAILER'),
      handleAction: (store) => {
        const params = getAssignPropertyModalParams(
          retailers,
          [store],
          'retailerName',
          handleAssignPropertiesChange,
        );

        openGenericModal(params);
      },
      actionIcon: () => '/images/inpulse/link-black-small.svg',
    },
    {
      id: 'assign-locations',
      actionLabel: () => i18next.t('GENERAL.LIST_ACTION_ASSIGN_LOCATION'),
      handleAction: (store) => {
        const params = getAssignPropertyModalParams(
          clientLocations,
          [store],
          'locationName',
          handleAssignPropertiesChange,
        );

        openGenericModal(params);
      },
      actionIcon: () => '/images/inpulse/link-black-small.svg',
    },
    {
      id: 'duplicate-store',
      actionLabel: () =>
        i18next.t('BACKOFFICE.STORES.LIST_ACTION_DUPLICATE_STORE', {
          storeName: translatedClientStoreName,
        }),
      handleAction: (store) => {
        if (!!store) {
          handleGoToStoreDetailPage({ id: 'create' }, { cloneStoreId: store.id });
        }
      },
      actionIcon: () => '/images/inpulse/duplicate-ip-black.svg',
    },
    {
      id: 'enable-stores',
      actionLabel: () =>
        i18next.t(
          activeStore
            ? 'BACKOFFICE.STORES.LIST_ACTION_DISABLE_STORES'
            : 'BACKOFFICE.STORES.LIST_ACTION_ENABLE_STORES',
          { count: 1 },
        ),
      handleAction: (store) => {
        openEnableStoresModal([store]);
      },
      actionIcon: () => '/images/inpulse/power-ip-black.svg',
    },
  ];

  return sortActionsList(rowActions);
};

export const getActionsSettings = ({
  hasMultipleBrands,
  hasActiveStores,
  handleExport,
  selectedItems,
  openAccountsAssociationModal,
  isLoading,
  openManageBrandsModal,
  openManageRetailersModal,
  handleCreateStoreAction,
  brands,
  retailers,
  clientLocations,
  openGenericModal,
  handleAssignPropertiesChange,
  openEnableStoresModal,
  history,
  translatedClientStoreName,
  handleGoToStoreDetailPage,
}) => {
  const hasSelectedItems = !!selectedItems.length;

  const actions = [
    {
      id: 'action-link',
      actionLabel: i18next.t('BACKOFFICE.STORES.LIST_ACTION_ASSOCIATE_USER'),
      handleAction: () => openAccountsAssociationModal(),
      actionIcon: () => '/images/inpulse/link-black-small.svg',
      isHidden: () => !hasSelectedItems,
    },
    {
      id: 'assign-brand',
      actionLabel: i18next.t('GENERAL.LIST_ACTION_ASSIGN_BRAND'),
      handleAction: () => {
        const params = getAssignPropertyModalParams(
          brands,
          selectedItems,
          'brandName',
          handleAssignPropertiesChange,
        );

        openGenericModal(params);
      },
      actionIcon: () => '/images/inpulse/link-black-small.svg',
      isHidden: () => !hasSelectedItems,
    },
    {
      id: 'assign-retailer',
      actionLabel: i18next.t('GENERAL.LIST_ACTION_ASSIGN_RETAILER'),
      handleAction: () => {
        const params = getAssignPropertyModalParams(
          retailers,
          selectedItems,
          'retailerName',
          handleAssignPropertiesChange,
        );

        openGenericModal(params);
      },
      actionIcon: () => '/images/inpulse/link-black-small.svg',
      isHidden: () => !hasSelectedItems,
    },
    {
      id: 'assign-locations',
      actionLabel: i18next.t('GENERAL.LIST_ACTION_ASSIGN_LOCATION'),
      handleAction: () => {
        const params = getAssignPropertyModalParams(
          clientLocations,
          selectedItems,
          'locationName',
          handleAssignPropertiesChange,
        );

        openGenericModal(params);
      },
      actionIcon: () => '/images/inpulse/link-black-small.svg',
      isHidden: () => !hasSelectedItems,
    },
    {
      id: 'duplicate-store',
      actionLabel: i18next.t('BACKOFFICE.STORES.LIST_ACTION_DUPLICATE_STORE', {
        storeName: translatedClientStoreName,
      }),
      handleAction: (stores) => {
        const store = head(stores);
        if (!!store) {
          handleGoToStoreDetailPage({ id: 'create' }, { cloneStoreId: store.id });
        }
      },
      actionIcon: () => '/images/inpulse/duplicate-ip-black.svg',
      isHidden: () => selectedItems.length !== 1,
    },
    {
      id: 'enable-stores',
      actionLabel: i18next.t(
        hasActiveStores
          ? 'BACKOFFICE.STORES.LIST_ACTION_DISABLE_STORES'
          : 'BACKOFFICE.STORES.LIST_ACTION_ENABLE_STORES',
        {
          count: selectedItems.length,
        },
      ),
      handleAction: () => {
        openEnableStoresModal(selectedItems);
      },
      actionIcon: () => '/images/inpulse/power-ip-black.svg',
      isHidden: () => !hasSelectedItems,
    },
    {
      id: 'action-brands',
      actionLabel: i18next.t('BACKOFFICE.STORES.MANAGE_BRANDS'),
      handleAction: () => {
        openManageBrandsModal();
      },
      actionIcon: () => '/images/inpulse/gear-ipblack-small.svg',
      isHidden: () => hasSelectedItems || !hasActiveStores,
    },
    {
      id: 'action-retailers',
      actionLabel: i18next.t('BACKOFFICE.STORES.MANAGE_RETAILERS'),
      handleAction: () => {
        openManageRetailersModal();
      },
      actionIcon: () => '/images/inpulse/gear-ipblack-small.svg',
      isHidden: () => hasSelectedItems || !hasActiveStores,
    },
    {
      id: 'export',
      actionLabel: i18next.t(hasSelectedItems ? 'GENERAL.EXPORT_SELECTION' : 'GENERAL.EXPORT_LIST'),
      handleAction: () => handleExport(),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
    {
      id: 'action-add',
      fixed: true,
      handleAction: () => handleCreateStoreAction(),
      actionLabel: i18next.t('GENERAL.CREATE'),
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={() => handleCreateStoreAction()}
          icon={'/images/inpulse/add-white-small.svg'}
          isDisabled={isLoading}
          label={i18next.t('GENERAL.CREATE')}
        />
      ),
    },
  ];

  return sortActionsList(
    actions.filter(({ id }) => {
      if (['action-brands', 'assign-brand'].includes(id)) {
        return hasMultipleBrands;
      }

      return true;
    }),
  );
};

export default {
  getActionsSettings,
  getRowActionsSettings,
};
