import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './translations/translation_en.json';
import translationFR from './translations/translation_fr.json';
import translationTH from './translations/translation_th.json';
import translationVI from './translations/translation_vi.json';

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  th: {
    translation: translationTH,
  },
  vi: {
    translation: translationVI,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
