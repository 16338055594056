import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  position: relative;
  overflow: auto;
  background-color: ${(props) => props.theme.colors?.greys.lighter};

  display: flex;
  flex-direction: column;

  padding: 24px;

  gap: 24px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  display: flex;
  align-items: center;

  color: #000000;
  padding-bottom: 8px;

  border-bottom: 1px solid ${(props) => props.theme.colors.greys.lighty};
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;

  color: #000000;
`;
