import { connect } from 'react-redux';
import { get, keyBy } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

// ACTIONS
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// COMMONS
import { ENUM_MODULE_NAME } from '@commons/utils/features';
import NavigationBar from '@commons/NavigationBar';

// SELECTORS
import { canSeeAllCatalogs } from '@selectors/user';
import { getClientInfo } from '@selectors/client';

// SERVICES
import catalogService from '@services/catalogs';
import clientService from '@services/client';

import { ContentContainer, PageContainer } from '../styledComponents';
import InventoryListTemplateMappings from '../inventoryListTemplateMappings';

const InventoryListTemplateSupplierProducts = (props) => {
  const {
    match: { path, params },
    client: { clientId, hasLocalCatalogs },
    pageLoaded,
    pageLoading,
    showErrorMessage,
    user,
  } = props;

  const inventoryListTemplateId = params.id;

  const [isLoading, setIsLoading] = useState(true);
  const [inventoryListTemplate, setInventoryListTemplate] = useState({});
  const [catalogs, setCatalogs] = useState([]);

  const getClientCatalogs = async () => {
    if (!hasLocalCatalogs) {
      return [];
    }

    try {
      const clientCatalogs = await catalogService.getCatalogsByClientId(clientId);

      const filteredCatalogs = clientCatalogs.filter(({ active }) => active);

      if (!canSeeAllCatalogs(user, { hasLocalCatalogs })) {
        setCatalogs([user.catalog]);

        return filteredCatalogs;
      }

      setCatalogs(filteredCatalogs);

      return filteredCatalogs;
    } catch {
      showErrorMessage(i18next.t('ADMIN.CATALOGS.FETCH_ERROR'));
      setCatalogs([]);

      return [];
    }
  };

  const getInventoryListTemplate = async (filteredCatalogs) => {
    try {
      const fetchedInventoryListTemplate = await clientService.getInventoryListTemplateById(
        clientId,
        params.id,
      );

      const catalogsKeyById = keyBy(filteredCatalogs, 'id');

      const mappings = fetchedInventoryListTemplate.supplierProductMappings || [];

      const formattedSPMappings = mappings.map((mapping) => ({
        ...mapping,
        catalogName: mapping.catalogId
          ? get(catalogsKeyById, `${[mapping.catalogId]}.name`, '')
          : '',
      }));

      setInventoryListTemplate({
        ...fetchedInventoryListTemplate,
        supplierProductMappings: formattedSPMappings,
      });
    } catch {
      showErrorMessage(i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.FETCH_ERROR'));
    }
  };

  useEffect(() => {
    if (!params.id) {
      return;
    }

    pageLoading();

    (async () => {
      const filteredCatalogs = await getClientCatalogs();

      await getInventoryListTemplate(filteredCatalogs);

      setIsLoading(false);
      pageLoaded();
    })();
  }, []);

  return (
    <>
      <NavigationBar
        inventoryListTemplate={{
          id: inventoryListTemplateId,
          name: inventoryListTemplate.name,
        }}
        module={ENUM_MODULE_NAME.SUPPLIER_PRODUCT_DETAIL}
        path={path}
        bigTopBar
        enableActionBottomOrder
      />
      <PageContainer>
        <ContentContainer>
          <InventoryListTemplateMappings
            catalogs={catalogs}
            existingMappings={inventoryListTemplate.supplierProductMappings}
            inventoryListTemplateId={inventoryListTemplateId}
            isLoading={isLoading}
          />
        </ContentContainer>
      </PageContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryListTemplateSupplierProducts);
