import axios from '../core/http';

import { config } from '../config';

import { APIcaller } from './APIcaller';

const baseApiUrl = config.baseApiUrl;

function getProductsOfClient(clientId) {
  return APIcaller.apiGet('/admin/products', {
    clientId,
  });
}

const getProductsCategories = (clientId) =>
  APIcaller.apiGet('/admin/products/categories', { clientId });

function getById(id) {
  return axios
    .get(`${baseApiUrl}/admin/products/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function patchMultipleProducts(products) {
  return axios
    .post(`${baseApiUrl}/admin/products/batch`, {
      products,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      let resp = response.data;
      if (response.data.data) resp = response.data.data;
      return resp;
    })
    .catch(function (error) {
      throw error;
    });
}

function getProductMappings(productId) {
  return axios
    .get(baseApiUrl + '/admin/products/' + productId + '/recipes', {
      params: {},
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function updateProduct(product, productCashierProductMappings) {
  return axios
    .patch(
      `${baseApiUrl}/admin/products/${product.id}`,
      {
        product,
        productCashierProductMappings,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function getProductsExportDataByIds(productIds) {
  const productIdsAsString = productIds.join(',');

  return axios
    .get(`${baseApiUrl}/admin/products/${productIdsAsString}/export`, {
      params: {},
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function saveProductWithProductionParameters(
  product,
  storeIds,
  price,
  hasStock,
  launchDate,
  endDate,
  noonMinimum,
  dailyMinimum,
  eveningMinimum,
) {
  const payload = {
    product: {
      ...product,
      priceWithTaxes: product.price,
      deliveryPriceWithTaxes: product.deliveryPrice,
    },
    storeIds,
    price,
    hasStock,
    launchDate,
    endDate,
  };

  if (dailyMinimum !== '') {
    payload.dailyMinimum = dailyMinimum;
  }

  if (noonMinimum !== '') {
    payload.noonMinimum = noonMinimum;
  }

  if (eveningMinimum !== '') {
    payload.eveningMinimum = eveningMinimum;
  }

  return axios
    .post(baseApiUrl + '/admin/products/production', {
      ...payload,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function updateStoreProductionParameters(
  productId,
  storeId,
  price,
  hasStock,
  launchDate,
  endDate,
  noonMinimum,
  dailyMinimum,
  eveningMinimum,
) {
  const payload = {
    price,
    hasStock,
    launchDate,
    endDate,
  };

  if (dailyMinimum !== '') {
    payload.dailyMinimum = dailyMinimum;
  }

  if (noonMinimum !== '') {
    payload.noonMinimum = noonMinimum;
  }

  if (eveningMinimum !== '') {
    payload.eveningMinimum = eveningMinimum;
  }

  return axios
    .patch(`${baseApiUrl}/admin/products/${productId}/stores/${storeId}/production`, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function getProductionStores(productId) {
  return axios
    .get(baseApiUrl + '/admin/products/' + productId + '/stores', {
      params: {},
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function getCashierProducts(productId) {
  return axios
    .get(baseApiUrl + '/admin/products/' + productId + '/cashier-products', {
      params: {},
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function deleteStoreProductMapping(storeId, productId) {
  return axios
    .delete(`${baseApiUrl}/admin/products/${productId}/stores/${storeId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function deleteCashierProductAssociation(productId, cashierProductId) {
  return axios
    .delete(`${baseApiUrl}/admin/products/${productId}/cashier-products/${cashierProductId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function associateCashierProductsToProduct(productId, cashierProductIds) {
  return axios
    .post(`${baseApiUrl}/admin/products/${productId}/cashier-products`, {
      cashierProductIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

const associateCashierProductToProduct = (cashierProductId, productId) =>
  APIcaller.apiPost(`/admin/products/${productId}/cashier-products/${cashierProductId}`);

const getProductsOfClientWithCashierProductsMappingRelevance = (clientId, cashierProductIds) =>
  APIcaller.apiPost(`/admin/products/with-cashier-product-mapping-relevance`, {
    clientId,
    cashierProductIds,
  });

function deleteProductByIds(clientId, productIds) {
  return axios
    .delete(`${baseApiUrl}/admin/products`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
      data: { clientId, productIds },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function disableProductByIds(productIds) {
  return axios
    .patch(
      `${baseApiUrl}/admin/products/disable`,
      { productIds },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function enableProduct(productId) {
  return axios
    .patch(
      `${baseApiUrl}/admin/products/${productId}/enable`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const product = {
  getProductsOfClient,
  getProductsCategories,
  getById,
  patchMultipleProducts,
  getProductMappings,
  updateProduct,
  getProductsExportDataByIds,
  saveProductWithProductionParameters,
  updateStoreProductionParameters,
  getProductionStores,
  deleteStoreProductMapping,
  deleteCashierProductAssociation,
  getCashierProducts,
  associateCashierProductToProduct,
  associateCashierProductsToProduct,
  deleteProductByIds,
  disableProductByIds,
  enableProduct,
  getProductsOfClientWithCashierProductsMappingRelevance,
};
