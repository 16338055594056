import moment from 'moment';
import React from 'react';

import { InpulseDatePickerDotedDay } from '../DatePickerDotedDay';

export const selectIfRenderDotAndTodayDateCircle = (
  formattedInventoryStockDay,
  singleStore,
  item,
  selectedDates,
) => {
  const isToday = moment().format('YYYY-MM-DD') === moment(item).format('YYYY-MM-DD');
  let isTodaySelected = false;
  if (selectedDates) {
    isTodaySelected = selectedDates
      .map((date) => moment().format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))
      .some((v) => v);
  }

  if (!formattedInventoryStockDay) {
    return <InpulseDatePickerDotedDay item={item} renderCircle={isToday && !isTodaySelected} />;
  }
  const isDot = formattedInventoryStockDay.indexOf(moment(item).format('YYYY-MM-DD')) >= 0;

  return (
    <InpulseDatePickerDotedDay
      isDot={isDot && singleStore}
      item={item}
      renderCircle={isToday && !isTodaySelected}
    />
  );
};
