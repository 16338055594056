import i18next from 'i18next';

import { STORE_INPUTS } from '@admin/stores/components/StoreInformations/utils/formInputsConfiguration';

import { INPUT_TYPES } from '@commons/constants/inputType';
import { PAYMENT_TERM_IN_DAYS_DROPDOWN_VALUES } from '@commons/constants/dropdown';

const getStoreCompanyCardSettings = (storeCompany) => {
  const inputs = [
    {
      dropdownValue: null,
      hasTooltip: false,
      icon: null,
      isDropdown: false,
      isRequired: false,
      isSwitch: false,
      label: i18next.t('ADMIN.STORES.COMPANY_NAME'),
      placeholder: i18next.t('ADMIN.STORES.COMPANY_NAME'),
      propertyKey: STORE_INPUTS.COMPANY_NAME,
      type: INPUT_TYPES.TEXT,
    },
    {
      dropdownValue: null,
      hasTooltip: false,
      icon: null,
      isDropdown: false,
      isRequired: false,
      isSwitch: false,
      label: i18next.t('ADMIN.STORES.SIRET'),
      placeholder: i18next.t('ADMIN.STORES.SIRET'),
      propertyKey: STORE_INPUTS.SIRET,
      type: INPUT_TYPES.TEXT,
    },
    {
      dropdownValue: null,
      hasTooltip: false,
      icon: null,
      isDropdown: false,
      isRequired: false,
      isSwitch: false,
      label: i18next.t('ADMIN.STORES.VAT_NUMBER'),
      placeholder: i18next.t('ADMIN.STORES.VAT_NUMBER'),
      propertyKey: STORE_INPUTS.VAT_NUMBER,
      type: INPUT_TYPES.TEXT,
    },
    {
      dropdownValue: null,
      hasTooltip: false,
      icon: null,
      isDropdown: false,
      isRequired: false,
      isSwitch: false,
      label: i18next.t('ADMIN.STORES.COMPANY_TYPE'),
      placeholder: i18next.t('ADMIN.STORES.COMPANY_TYPE'),
      propertyKey: STORE_INPUTS.COMPANY_TYPE,
      type: INPUT_TYPES.TEXT,
    },
    {
      dropdownValue: null,
      hasTooltip: false,
      icon: null,
      isDropdown: false,
      isRequired: false,
      isSwitch: false,
      label: i18next.t('ADMIN.STORES.CAPITAL'),
      placeholder: i18next.t('ADMIN.STORES.CAPITAL'),
      propertyKey: STORE_INPUTS.CAPITAL,
      type: 'number',
    },
    {
      dropdownValue: null,
      hasTooltip: false,
      icon: null,
      isDropdown: false,
      isRequired: false,
      isSwitch: false,
      label: i18next.t('ADMIN.STORES.COMPANY_REGISTER'),
      placeholder: i18next.t('ADMIN.STORES.COMPANY_REGISTER'),
      propertyKey: STORE_INPUTS.COMPANY_REGISTER,
      type: INPUT_TYPES.TEXT,
    },
    {
      dropdownValue: Object.values(PAYMENT_TERM_IN_DAYS_DROPDOWN_VALUES),
      hasTooltip: true,
      icon: 'none',
      emptyIcon: 'none',
      isDropdown: true,
      dropdownMatchingProperty: 'value',
      isRequired: false,
      placeholder: i18next.t('ADMIN.STORES.PAYMENT_TERM_IN_DAYS'),
      label: i18next.t('ADMIN.STORES.PAYMENT_TERM_IN_DAYS'),
      propertyKey: STORE_INPUTS.PAYMENT_TERM_IN_DAYS,
      type: 'dropdown',
    },
  ];

  return {
    title: i18next.t('ADMIN.STORES.COMPANY'),
    inputs: inputs.reduce((acc, input) => {
      acc.push({ ...input, value: storeCompany[input.propertyKey] });
      return acc;
    }, []),
  };
};

export default getStoreCompanyCardSettings;
