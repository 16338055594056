import { useTranslation } from 'react-i18next';
import React from 'react';

import IngredientLogo from '@assets/logo/ingredients.svg';

import { DeepsightComponentLoader } from '@commons/DeepsightComponents';

import { MainText, TextContainer, EmptyStateIcon, EmptyStateContainer } from './styledComponents';

export function EmptyList() {
  const { t } = useTranslation();
  return (
    <EmptyStateContainer>
      <div>
        <EmptyStateIcon
          alt="empty-state-ingredient-entity-association-icon"
          className={'center'}
          src={IngredientLogo}
        />
        <TextContainer>
          <MainText>{t('ADMIN.INGREDIENTS.SUB_PAGES_EMPTY_STATE')}</MainText>
        </TextContainer>
      </div>
    </EmptyStateContainer>
  );
}

const EmptyState = (props) => {
  if (props.isLoading === true) {
    return <DeepsightComponentLoader />;
  } else {
    return <EmptyList />;
  }
};

export default EmptyState;
