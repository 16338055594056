import {
  ResponsiveContainer,
  CartesianGrid,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
} from 'recharts';
import React from 'react';

import { StyledCustomLegend } from '@lib/inpulse/ChartLegend';
import { StyledTooltip } from '@lib/inpulse/BarChartTooltip';

import { customLegend } from './Legend';
import { customTooltip } from './Tooltip';

const TurnoverEvolutionGraph = (props) => {
  const { data, dataKeys, width, height, highestValue } = props;
  return (
    <StyledTooltip>
      <StyledCustomLegend>
        <ResponsiveContainer height={height} minWidth={400} width="100%">
          <ComposedChart
            data={data}
            height={height}
            margin={{ top: 15, right: 5, bottom: 5, left: -30 }}
            width={width}
          >
            <CartesianGrid stroke="#EDF0F6" strokeWidth={1} vertical={false} />
            <Bar
              barSize={25}
              dataKey={dataKeys[1].name}
              fill={dataKeys[1].color}
              key={dataKeys[1].id}
              label={{ position: 'top' }}
              radius={[2, 2, 0, 0]}
              stackId={'b'}
            />
            <Bar
              barSize={25}
              dataKey={dataKeys[0].name}
              fill={dataKeys[0].color}
              key={dataKeys[0].id}
              label={{ position: 'top' }}
              radius={[2, 2, 0, 0]}
              stackId={'c'}
            />
            <Legend content={customLegend} payload={dataKeys} />
            <XAxis dataKey="week" tick={{ fill: '#6B768C', fontSize: 12 }} tickLine={false} />
            <YAxis
              axisLine={true}
              tick={{ fill: '#6B768C', fontSize: 12 }}
              tickFormatter={(n) => {
                if (n < 1000) return n;
                return `${Math.round(n / 1000)} K`;
              }}
              tickLine={true}
              width={75} // Default 60 but is insufficient to display some data
            />
            <Tooltip content={customTooltip} cursor={false} highestValue={highestValue} />
          </ComposedChart>
        </ResponsiveContainer>
      </StyledCustomLegend>
    </StyledTooltip>
  );
};

export default TurnoverEvolutionGraph;
