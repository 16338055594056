import _ from 'lodash';
import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { PropsItemRow } from '../../interface';
import { numberToFixed } from '../../../utils/format';
import {
  ItemRowContainer,
  ItemRow,
  ExpandedContainer,
  ItemDisplayWithGraph,
  ItemDisplayWithCustomModal,
  ItemContainer,
  ItemGraphContainer,
  ModalContainer,
} from './styledComponents';
import ActionButton from '../ActionButton/ActionButton';
import ItemDisplay from '../ItemDisplay/ItemDisplay';
import GraphButton from '../GraphButton/GraphButton';
import CustomModalButtonDisplay from '../CustomModalButton/CustomModalButtonDisplay';
import ChildItemRowDisplay from '../ChildItemRowDisplay/ChildItemRowDisplay';

const ItemRowDisplay = (props: PropsItemRow): JSX.Element => {
  const {
    theme,
    headers,
    items,
    parentIndex,
    openRow,
    openGraph,
    openChildGraph,
    customModalIcon,
    openParentModal,
    openChildModal,
  } = props;

  const isExpandable = _.get(items, `parentItem.items.isExpandable`);

  return (
    <>
      <ItemRowContainer isExpandable={isExpandable}>
        <ItemRow
          isExpandable={isExpandable}
          onClick={() => (isExpandable ? openRow() : null)}
        >
          {headers.map((header, index) => {
            let value: string | number = '-';

            const isAction = header.propertyKey === 'action';

            if (items.parentItem.items[header.propertyKey]) {
              value = header.isNumber
                ? numberToFixed(
                    items.parentItem.items[header.propertyKey]
                      ? items.parentItem.items[header.propertyKey]
                      : '-',
                    2,
                    '-',
                    header.unit
                  )
                : items.parentItem.items[header.propertyKey];
            }

            return (
              <Fragment key={`parent-row-${index}`}>
                {!isAction &&
                  !header.graphConfiguration &&
                  !header.customModalRender && (
                    <ItemDisplay
                      value={
                        header.render
                          ? header.render(
                              _.get(
                                items,
                                `parentItem.items[${header.propertyKey}]`
                              ),
                              items.parentItem.items
                            )
                          : value
                      }
                      bold={header.bold || items.isHighlighted}
                      blue={items.isHighlighted}
                      large={header.large}
                      narrow={header.narrow}
                      minWidth={header.minWidth}
                      textTransform={header.textTransform}
                      nbColumn={headers.length}
                    />
                  )}

                {!isAction &&
                  !header.graphConfiguration &&
                  header.customModalRender && (
                    <ItemDisplayWithCustomModal
                      nbColumn={headers.length}
                      minWidth={header.minWidth}
                    >
                      <ItemContainer>
                        <ItemDisplay
                          value={
                            header.render
                              ? header.render(
                                  _.get(
                                    items,
                                    `parentItem.items[${header.propertyKey}]`
                                  ),
                                  items.parentItem.items
                                )
                              : value
                          }
                          bold={header.bold || items.isHighlighted}
                          blue={items.isHighlighted}
                          large={header.large}
                          textTransform={header.textTransform}
                          nbColumn={headers.length}
                          withRender={header.customModalRender}
                        />
                      </ItemContainer>
                      <ModalContainer>
                        <CustomModalButtonDisplay
                          theme={theme}
                          icon={customModalIcon}
                          openModal={openParentModal}
                          openedModal={_.get(
                            items,
                            `parentItem.items.modalOpened`
                          )}
                          modalContent={_.get(
                            items,
                            `parentItem.items.modalContent`
                          )}
                          customModalTooltip={header.customModalTooltip}
                        />
                      </ModalContainer>
                    </ItemDisplayWithCustomModal>
                  )}

                {!isAction &&
                  header.graphConfiguration &&
                  !header.customModalRender && (
                    <ItemDisplayWithGraph
                      nbColumn={headers.length}
                      minWidth={header.minWidth}
                    >
                      <ItemGraphContainer>
                        <ItemDisplay
                          value={value}
                          bold={header.bold || items.isHighlighted}
                          blue={items.isHighlighted}
                          large={header.large}
                          textTransform={header.textTransform}
                          withRender={!!header.graphConfiguration}
                          nbColumn={headers.length}
                        />
                      </ItemGraphContainer>

                      <ModalContainer>
                        <GraphButton
                          theme={theme}
                          openGraph={openGraph}
                          open={_.get(items, `parentItem.items.graphOpened`)}
                          graphConfiguration={header.graphConfiguration}
                          graphs={items.parentItem.chart}
                        />
                      </ModalContainer>
                    </ItemDisplayWithGraph>
                  )}

                {isAction && (
                  <ActionButton
                    open={items.parentItem.items.opened}
                    visibility={isExpandable}
                  />
                )}
              </Fragment>
            );
          })}
        </ItemRow>

        <ExpandedContainer
          onClick={() => openRow()}
          open={items.parentItem.items.opened}
        >
          {items.parentItem.items.opened &&
            items.childItems.map((childItem, index) => (
              <Fragment key={`expanded-row-${index}`}>
                <ChildItemRowDisplay
                  theme={theme}
                  headers={headers}
                  isFirst={index === 0}
                  isLast={index + 1 === items.childItems.length}
                  childItems={childItem.items}
                  childChart={childItem.chart}
                  openChildGraph={() =>
                    openChildGraph(parentIndex, index, 'graphOpened')
                  }
                  customModalIcon={customModalIcon}
                  openChildModal={() =>
                    openChildModal(parentIndex, index, 'modalOpened')
                  }
                />
              </Fragment>
            ))}
        </ExpandedContainer>
      </ItemRowContainer>
    </>
  );
};

ItemRowDisplay.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      propertyKey: PropTypes.string.isRequired,
      isNumber: PropTypes.bool,
      unit: PropTypes.string,
      tooltipDisplay: PropTypes.string,
      large: PropTypes.bool,
      bold: PropTypes.bool,
      textTransform: PropTypes.string,
      textDecorationChild: PropTypes.string,
      hrefPrefix: PropTypes.string,
      hrefSuffix: PropTypes.string,
      render: PropTypes.func,
      graphConfiguration: PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
      }),
    })
  ).isRequired,
  items: PropTypes.shape({
    parentItem: PropTypes.shape({
      items: PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.bool,
          PropTypes.func,
        ])
      ),
      chart: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.string,
          y: PropTypes.number,
        })
      ),
    }),
    childItems: PropTypes.arrayOf(
      PropTypes.shape({
        items: PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.bool,
            PropTypes.func,
          ])
        ),
        chart: PropTypes.arrayOf(
          PropTypes.shape({
            x: PropTypes.string.isRequired,
            y: PropTypes.number.isRequired,
          })
        ),
      })
    ),
  }).isRequired,
  parentIndex: PropTypes.number.isRequired,
  openRow: PropTypes.func.isRequired,
  openGraph: PropTypes.func.isRequired,
  openChildGraph: PropTypes.func.isRequired,
};

ItemRowDisplay.defaultProps = {};

export default ItemRowDisplay;
