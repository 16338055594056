import React from 'react';

import { DeleteText } from './styledComponents';

const SupplierProfileModalDeletionConfirm = (props) => {
  const { storeName, stores, supplier } = props;
  const storesUnMapped = stores.filter((store) => store.selected);
  return (
    <div style={{ width: '80%', marginLeft: '5%', marginTop: '50px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          alt="delete-store-supplier-profile-ammpings"
          src={'/images/inpulse/delete-black.svg'}
          style={{ width: '40px', height: '40px' }}
        />
        <DeleteText>
          {storeName && (
            <div>{`Suppression du profil fournisseur pour ${
              storesUnMapped.length
            } ${storeName.toLowerCase()}${storesUnMapped.length > 1 ? 's' : ''}.`}</div>
          )}
          {storesUnMapped.length > 1 && (
            <div>{`Les ingrédients fournisseurs de ${
              supplier.name
            } ne seront plus disponibles sur la commande et l’inventaire pour ces ${storeName.toLowerCase()}s.`}</div>
          )}
          {storesUnMapped.length <= 1 && (
            <div>{`Les ingrédients fournisseurs de ${
              supplier.name
            } ne seront plus disponibles sur la commande et l’inventaire pour ce ${storeName.toLowerCase()}.`}</div>
          )}
        </DeleteText>
      </div>
    </div>
  );
};

export default SupplierProfileModalDeletionConfirm;
