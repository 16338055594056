import { clone, get } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { GENERIC_MODAL_SAVE_BUTTON } from '@commons/Modals/GenericModal/genericModalActions';
import { openGenericModal, refreshGenericModal } from '@actions/modal';

import { EXCEPTIONAL_DELIVERY_TYPES } from '../../utils/constants';
import ExceptionalDeliveryDays from '../../SupplierProfileModal/ExceptionalDeliveryDays';

const AddExceptionalDeliveryDaysToSupplierProfilesModal = (props) => {
  const {
    modal,
    openGenericModal,
    refreshGenericModal,

    addExceptionalDeliveryDaysToSupplierProfiles,
    setAddingExceptionalDeliveryDays,
    supplierProfiles,
  } = props;

  const [exceptionalDeliveryDays, setExceptionalDeliveryDays] = useState([]);

  useEffect(() => {
    const isModalOpened = get(modal, 'GenericModalBool', false);

    const params = getConfig();

    if (isModalOpened) {
      refreshGenericModal(params);
      return;
    }

    openGenericModal(params);
  }, [exceptionalDeliveryDays]);

  const getConfig = () => {
    const actions = [
      {
        ...GENERIC_MODAL_SAVE_BUTTON(),
        handleClick: async () => {
          await addExceptionalDeliveryDaysToSupplierProfiles(
            supplierProfiles,
            exceptionalDeliveryDays,
          );
        },
        isDisabled: !areExceptionalDeliveryDaysValid(),
      },
    ];

    const params = {
      type: 'actionWhite',
      isFullscreen: true,
      title: i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_ADD_EXCEPTIONAL_DELIVERY_DAYS_ACTION'),
      handleCloseCleanUp: resetModal,
      icon: '/images/inpulse/pen-black-small.svg',
      actions,
      component: ExceptionalDeliveryDays,
      data: {
        exceptionalDeliveryDays,
        handleExceptionalDeliveryDays,
        addExceptionalDeliveryDay,
        deleteExceptionalDeliveryDay,
        areExceptionalDeliveryDaysValid,
      },
    };

    return params;
  };

  const handleExceptionalDeliveryDays = (selectedItemId, index, field) => {
    let updatedEDD = clone(exceptionalDeliveryDays);

    updatedEDD[index][field] = selectedItemId;

    if (field === 'type' && selectedItemId === EXCEPTIONAL_DELIVERY_TYPES.EXCEPTIONAL_DELIVERY) {
      updatedEDD[index]['leadTime'] = '1';
    }

    setExceptionalDeliveryDays([...updatedEDD]);
  };

  const addExceptionalDeliveryDay = () => {
    setExceptionalDeliveryDays([
      ...exceptionalDeliveryDays,
      { date: null, type: null, leadTime: null },
    ]);
  };

  const deleteExceptionalDeliveryDay = (index) => {
    let updatedEDD = clone(exceptionalDeliveryDays);

    updatedEDD.splice(index, 1);

    setExceptionalDeliveryDays(updatedEDD);
  };

  const areExceptionalDeliveryDaysValid = () => {
    if (!exceptionalDeliveryDays.length) {
      return false;
    }

    return exceptionalDeliveryDays.every((edd) => edd.date && edd.type);
  };

  const resetModal = () => {
    setAddingExceptionalDeliveryDays(false);
  };

  return <div />; // the component does not directly render anything but manages the modal
};

const mapStateToProps = (state) => ({
  modal: state.modalReducer,
});

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddExceptionalDeliveryDaysToSupplierProfilesModal);
