import { connect } from 'react-redux';
import i18next from 'i18next';
import React from 'react';

import { Day, Dot, ModalContainer, Text, ModalBody } from './styledComponents';

const StockModalInformation = (props) => {
  const { stockType } = props;
  const formattedStockType =
    stockType.toLowerCase() === 'stock'
      ? i18next.t('STOCKS.STOCKS.FORM_TYPE_STOCK').toLowerCase()
      : i18next.t('STOCKS.STOCKS.FORM_TYPE_LOSS').toLowerCase();
  return (
    <ModalContainer>
      <Day>
        <Dot />2
      </Day>
      <ModalBody>
        <Text>
          {i18next.t('ORDERS.ORDERS.STOCK_FORM_DATEPICKER_TOOLTIP', {
            stockType: formattedStockType,
          })}
        </Text>
      </ModalBody>
    </ModalContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

export default connect(mapStateToProps)(StockModalInformation);
