import { get } from 'lodash';

import clientService from '@services/client';

import { formatProductBatchCreationFile } from '@admin/products/products/utils/formatProductsBatchCreationFile';

import { formatXlsFileToJson } from '@backoffice/utils';

import { IMPORT_STATE } from './constant';

// Don't do it as React customHook because it could be use with class components
export const getBatchProductsValidation = async (props, setters, selectedFile) => {
  const { setBatchProductsToCreate, setErrorFile, setCurrentStep } = setters;
  const { pageLoading, pageLoaded, user } = props;

  pageLoading();

  try {
    const products = await formatXlsFileToJson(selectedFile);

    const formattedProducts = formatProductBatchCreationFile(products);

    setBatchProductsToCreate(formattedProducts);

    const clientId = get(user, 'clientId', null);

    await clientService.postProductsBatchCreationValidate(clientId, formattedProducts);
    setCurrentStep(IMPORT_STATE.VALIDATED_FILE);
  } catch (error) {
    if (error.response.status === 422) {
      setErrorFile(error.response.data);
      setCurrentStep(IMPORT_STATE.ERROR_FILE);
    }
  } finally {
    pageLoaded();
  }
};

export default {
  getBatchProductsValidation,
};
