import React, { useState } from 'react';

import { DeepsightUploadPicture } from '@commons/DeepsightComponents';

import { Container } from './styledComponents';

export function ProductPictureSection(props) {
  const { product, onProductChange, isReadOnly } = props;

  const [pictureUrl, setPictureUrl] = useState(product.img || '');

  return (
    <Container>
      <DeepsightUploadPicture
        handleSelectedPhoto={(fileUri) => {
          onProductChange({ ...product, img: fileUri });
          setPictureUrl(fileUri);
        }}
        pictureUrl={pictureUrl}
        readOnly={isReadOnly}
        center
      />
    </Container>
  );
}

export default ProductPictureSection;
