import i18next from 'i18next';

import utilsXLS from '@commons/utils/makeXLS';

export const exportAssociatedStores = ({
  associatedStores,
  translatedStoreName,
  supplierName,
  shouldDisplayEdi,
}) => {
  const FILENAME = `${supplierName}_${translatedStoreName}`;

  const SHEET_NAME = `${translatedStoreName}`;

  const COLUMNS_SETTINGS = [
    {
      propertyKey: 'name',
      name: i18next.t('GENERAL.NAME'),
    },
    {
      propertyKey: 'supplierProfileName',
      name: i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_COLUMN_SUPPLIER_PROFILE'),
    },
    {
      propertyKey: 'city',
      name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CITY'),
    },
    {
      propertyKey: 'country',
      name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_COUNTRY'),
    },
    {
      propertyKey: 'customerCode',
      name: i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_CUSTOMER_CODE_LABEL_INPUT'),
    },
    {
      propertyKey: 'warehouseCode',
      name: i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_WAREHOUSE_CODE_LABEL_INPUT'),
    },
    ...(shouldDisplayEdi
      ? [
          {
            propertyKey: 'isEdi',
            name: i18next.t('GENERAL.EDI'),
          },
        ]
      : []),
  ];

  const formattedAssociatedStores = associatedStores.map((store) => ({
    ...store,
    isEdi: store.isEdi ? i18next.t('GENERAL.YES') : i18next.t('GENERAL.NO'),
  }));

  const sheet = utilsXLS.generateDefaultSheet(
    SHEET_NAME,
    COLUMNS_SETTINGS,
    formattedAssociatedStores,
  );

  utilsXLS.makeXLS(FILENAME, [sheet]);
};
