import React, { Fragment } from 'react';
import _ from 'lodash';

import {
  BorderTop,
  LoadingContainer,
  LoadingRow,
  LoadingItemContainer,
  LoadingStateContainer,
} from './styledComponents';

import { LoadingModalProps } from '../../../../interface';

import { Item, Icon } from '../Row/styledComponents';

import ItemDisplay from '../../../ItemDisplay/ItemDisplay';

import IconLinkGrey from '../../../../../images/icon-open-in-new-grey.svg';

const EMPTY_LOADING_ROW = 5;

const LoadingState = (props: LoadingModalProps): JSX.Element => {
  const { headers } = props;

  return (
    <LoadingStateContainer>
      <BorderTop />
      {_.times(EMPTY_LOADING_ROW, (index) => (
        <LoadingContainer key={`loading-${index}`}>
          <LoadingRow>
            {headers.map((header, rowIndex) => {
              const key = `row-${index}-${rowIndex}`;
              const isAction = header.propertyKey === 'action';

              return (
                <Fragment key={key}>
                  {!isAction && (
                    <LoadingItemContainer nbColumn={headers.length}>
                      <ItemDisplay
                        value=""
                        nbColumn={headers.length}
                        isLoading
                        height={16}
                      />
                    </LoadingItemContainer>
                  )}

                  {isAction && (
                    <Item isLoading narrow>
                      <Icon isLoading icon={IconLinkGrey} />
                    </Item>
                  )}
                </Fragment>
              );
            })}
          </LoadingRow>
        </LoadingContainer>
      ))}
    </LoadingStateContainer>
  );
};

export default LoadingState;
