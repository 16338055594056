import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import _ from 'lodash';

import { NestedDataProps } from '../../interface';

import { NestedDataContainer, NestedDataRow } from './styledComponents';
import NestedDataItem from './NestedDataItem/NestedDataItem';

const NestedData = (props: NestedDataProps): JSX.Element => {
  const { theme, data, headers } = props;

  return (
    <ThemeProvider theme={theme}>
      <NestedDataContainer>
        {data.map((item, dataIndex) => (
          <NestedDataRow>
            {headers.map((header, headerIndex) => {
              const value: string | number = _.get(item, header.propertyKey);

              const link: string | null = _.get(item, 'link', null);

              const isAction = header.propertyKey === 'action';

              const isFirst = headerIndex === 0;

              return (
                <Fragment key={`nested-${headerIndex}-${dataIndex}`}>
                  {!isAction && (
                    <NestedDataItem
                      value={
                        header.render
                          ? header.render(_.get(item, header.propertyKey), item)
                          : value
                      }
                      large={header.large}
                      narrow={header.narrow}
                      nbColumn={headers.length}
                      isFirst={isFirst}
                      link={isFirst ? link : null}
                    />
                  )}
                </Fragment>
              );
            })}
            <div style={{ width: '8px' }} />
          </NestedDataRow>
        ))}
      </NestedDataContainer>
    </ThemeProvider>
  );
};

NestedData.propTypes = {};

export default NestedData;
