import styled, { css } from 'styled-components';

export const InputContainer = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};

  display: flex;
  justify-content: space-between;

  border-radius: 8px;
  border: 1px solid #e2e2e2;
  background: #ffffff;
`;

export const ArrowsContainer = styled.div`
  width: 25px;

  display: flex;
  flex-direction: column;

  border-left: 1px solid #e2e2e2;
  border-radius: 0 8px 8px 0;

  cursor: pointer;
`;

export const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  &:hover {
    background-color: #e2e2e2;
  }

  ${(props) =>
    props.top
      ? css`
          border-top-right-radius: 6px;
          border-bottom: 1px solid #e2e2e2;
        `
      : css`
          border-bottom-right-radius: 6px;
        `}
`;

export const Input = styled.input`
  height: 100%;
  width: 100%;

  font: normal 500 12px/16px inter;
  color: #1d1d1b;

  outline: none;
  border: none;
  text-align: left;
  padding-left: 7px;
  margin-top: -1px;
  background-color: transparent;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  -moz-appearance: textfield;
`;

export const Text = styled.div`
  font-family: inter;
  padding: 0 10px;
`;

export const Icon = styled.img`
  width: 5px;
  height: 5px;
`;

export const MultipleInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 185px;
  width: 185px;
`;
