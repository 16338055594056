import i18next from 'i18next';
import React from 'react';

import { ContentContainer, Text } from './styledComponents';

import { STEP_REGISTRATION } from '../../utils/constants';

export const TokenExpiredContent = (props) => {
  const { currentStep } = props.params;

  return (
    <ContentContainer>
      <img
        src={
          currentStep === STEP_REGISTRATION.TOKEN_EXPIRED_STEP_1
            ? 'images/inpulse/link-expired.svg'
            : 'images/inpulse/email-sent.svg'
        }
      />
      <Text>
        {currentStep === STEP_REGISTRATION.TOKEN_EXPIRED_STEP_1
          ? i18next.t('AUTHENTICATION.REGISTRATION.LANDING_TOKEN_EXPIRED_CONTENT')
          : i18next.t('AUTHENTICATION.REGISTRATION.LANDING_TOKEN_RESENT_CONTENT')}
      </Text>
    </ContentContainer>
  );
};

export default TokenExpiredContent;
