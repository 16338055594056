import styled, { css } from 'styled-components';

export const HeaderDisplay = styled.div``;

export const TextEllispis = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;

  max-width: 84px;

  &:hover {
    ${(props) =>
      props.interactive &&
      css`
        cursor: pointer;
      `}
  }
`;

export const HeaderFullContainer = styled.div`
  margin: 0;
  ${(props) =>
    props.hasNestedData &&
    css`
      margin: 0px 24px;
    `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 72px;
  padding: 16px 24px;
  padding-top: 24px;
  ${(props) =>
    props.hasNestedData &&
    css`
      padding: 16px 26px 16px 0px;
    `}
`;

export const TitleColumn = styled.div`
  display: flex;
  font-size: 14px;

  overflow: hidden;

  padding-left: 16px;

  width: ${(props) =>
    !props.hasNestedData && (props.large ? '240px' : '160px')};

  ${(props) =>
    props.hasNestedData &&
    props.large &&
    css`
      flex: 2;
      padding-right: 0px;
      flex-basis: calc(${(100 / props.nbColumn) * 2}%);
    `}

  ${(props) =>
    props.hasNestedData &&
    !props.large &&
    css`
      padding-right: 20px;
      flex-basis: calc(${100 / props.nbColumn}%);
    `}


  ${(props) =>
    props.narrow &&
    css`
      width: fit-content;
      flex: none;
    `}

  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth}px;
    `}

  color: ${(props) => {
    if (props.selected) {
      return props.theme.header?.selected?.color;
    }

    return props.theme.header?.default?.color;
  }};

  font: ${(props) => {
    if (props.selected) {
      return props.theme.header?.selected?.font;
    }

    return props.theme.header?.default?.font;
  }};
`;

export const Icon = styled.img`
  margin: ${(props) =>
    props.marginLeft ? `auto 0 auto ${props.marginLeft}` : `auto 0 auto 0`};

  &:hover {
    ${(props) =>
      props.interactive &&
      css`
        cursor: pointer;
      `}
  }
`;

export default {
  HeaderFullContainer,
  HeaderDisplay,
  HeaderContainer,
  TitleColumn,
  Icon,
  TextEllispis,
};
