import _ from 'lodash';

const aggregateStatsOnTotalLevel = (totalLine, filteredData) => {
  const total = filteredData.reduce(
    (result, store) => {
      result.lossTurnover += _.get(store, 'lossTurnover', 0);
      result.lossQuantity += _.get(store, 'lossQuantity', 0);
      result.stockTurnover += _.get(store, 'stockTurnover', 0);
      result.stockQuantity += _.get(store, 'stockQuantity', 0);
      result.producedTurnover += _.get(store, 'producedTurnover', 0);
      result.producedQuantity += _.get(store, 'producedQuantity', 0);
      result.toProduceTurnover += _.get(store, 'toProduceTurnover', 0);
      result.toProduceQuantity += _.get(store, 'toProduceQuantity', 0);
      result.forecastedTurnover += _.get(store, 'forecastedTurnover', 0);
      result.forecastedQuantity += _.get(store, 'forecastedQuantity', 0);

      return result;
    },
    {
      lossTurnover: 0,
      lossQuantity: 0,
      stockTurnover: 0,
      stockQuantity: 0,
      producedTurnover: 0,
      producedQuantity: 0,
      toProduceTurnover: 0,
      toProduceQuantity: 0,
      forecastedTurnover: 0,
      forecastedQuantity: 0,
      nbProducedReferences: 0,
      percentageProductionTurnover: 0,
      percentageProductionQuantity: 0,
    },
  );

  total.percentageProductionTurnover = total.toProduceTurnover
    ? Math.round((total.producedTurnover / total.toProduceTurnover) * 100)
    : 0;

  total.percentageProductionQuantity = total.toProduceQuantity
    ? Math.round((total.producedQuantity / total.toProduceQuantity) * 100)
    : 0;

  let nbProducedReferences = 0;
  filteredData.forEach((store) => {
    const productIds = Object.keys(store.productionByProductId);
    productIds.forEach((id) => {
      if (store.productionByProductId[id]) {
        nbProducedReferences += 1;
      }
    });
  });

  total.nbProducedReferences = filteredData.length ? nbProducedReferences / filteredData.length : 0;

  return {
    ...totalLine,
    ...total,
  };
};

export default aggregateStatsOnTotalLevel;
