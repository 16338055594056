import i18next from 'i18next';
import React from 'react';

import { ChartContainer, Container, TitleChartContainer } from './styledComponents';
import ChartLoadingState from '../LoadingState';
import EmptyGraphStock from '../EmptyState';
import ForecastChart from './component/ForecastChart';
import StarterPlanForecast from './component/StarterPlanForecast';

const OrderStockChart = (props) => {
  const {
    unit,
    forecastStock,
    yesterday,
    periodEndDate,
    periodStartDate,
    isLoading,
    hasForecasts,
    storeTimezone,
  } = props;

  return (
    <>
      <Container>
        <TitleChartContainer isHidden={isLoading}>
          {i18next.t('ORDERS.ORDERS.FORM_STOCK_CHART_TITLE', { unit })}
        </TitleChartContainer>
        <ChartContainer>
          {!hasForecasts && <StarterPlanForecast />}
          {hasForecasts && isLoading && <ChartLoadingState />}
          {hasForecasts && !isLoading && !forecastStock && <EmptyGraphStock />}
          {hasForecasts && !isLoading && forecastStock && (
            <ForecastChart
              forecastStock={forecastStock}
              periodEndDate={periodEndDate}
              periodStartDate={periodStartDate}
              storeTimezone={storeTimezone}
              yesterday={yesterday}
            />
          )}
        </ChartContainer>
      </Container>
    </>
  );
};

export default OrderStockChart;
