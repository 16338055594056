import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  overflow: auto;
  position: relative;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  ${(props) =>
    props.withSubtitles
      ? css`
          color: ${(props) => props.theme.colors.greys.darkest};
          font: ${(props) => props.theme.fonts.h3Bold700};
        `
      : css`
          color: ${(props) => props.theme.colors.greys.darkest};
          font: ${(props) => props.theme.fonts.h1};
        `};
`;

export const SubTitle = styled.div`
  color: ${(props) => props.theme.colors.greys.darker};
  font: ${(props) => props.theme.fonts.textSmallHeight16};
`;

export const HeaderContainer = styled.div`
  height: 64px;
  width: 100%;

  padding: 12px 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${(props) => props.theme.colors.greys.lightest};
  box-shadow: inset 0px 2px 0px ${(props) => props.theme.colors.greys.lighter};
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  justify-content: space-between;
`;

export const FooterRightContainer = styled.div`
  color: ${(props) => props.theme.colors.greys.dark};
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 40px;

  ${(props) =>
    props.isDisabled &&
    css`
      cursor: not-allowed;

      color: ${props.theme.colors.greys.light};
    `};

  &:hover {
    -webkit-filter: url(#colorize);
    filter: url(#colorize);
  }
`;

export const DropdownItem = styled.div`
  width: 100%;
  padding: 12px 0px;
  display: flex;
  flex-direction: row;
`;

export const DropdownItemImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const DropdownItemValuesContainer = styled.div`
  overflow: hidden;
  padding-left: 8px;
  max-width: 264px;
`;

export const DropdownItemMainValue = styled.div`
  font: ${(props) => props.theme.fonts.textMiddleNormal};
  margin-bottom: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DropdownItemSecondaryValue = styled.div`
  font: ${(props) => props.theme.fonts.textSmallHeight16};
  color: ${(props) => props.theme.colors.greys.dark};
  margin-top: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${(props) =>
    props.isDisabled &&
    css`
      color: ${props.theme.colors.greys.light};
    `};
`;

export const ItemNameContainer = styled.div`
  display: flex;
  height: 64px;
  align-items: center;
  gap: 8px;

  ${(props) =>
    props.isLink &&
    css`
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    `};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AllergensTooltipContainer = styled.div`
  width: 15px;
  height: 15px;
  > div {
    margin: 0px;
  }
`;

export const Image = styled.img`
  width: 40px;
  height: 40px;
`;

export const Name = styled.div`
  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.textMiddleNormal};
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    text-decoration: inherit;
    cursor: inherit;
  }
`;
