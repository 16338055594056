import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;

  background-color: white;
`;

export const ContentContainer = styled.div`
  width: calc(100% - 64px);
  height: calc(100% - 40px);

  background: ${(props) => props.theme.colors.greys.lighter};

  > div {
    min-width: 100%;
    width: fit-content;
  }

  @media (max-width: 1200px) {
    > div:not(:first-child) > div > div {
      padding-right: 20px;
    }
  }
`;

export const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Label = styled.div`
  width: fit-content;
  height: 20px;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${(props) =>
    props.red &&
    css`
      color: #ef252a;
      font-weight: 700;
    `}

  ${(props) =>
    props.orange &&
    css`
      color: orange;
      font-weight: 700;
    `}
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 90px;

  padding: 20px 20px;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  .location-filter-input-text {
    max-width: none !important;
  }
`;

export const Filter = styled.div`
  width: 240px;

  display: flex;
  align-items: center;
`;

export const FilterContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  column-gap: 24px;
`;

export const NestedListContainer = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
`;

export const TextWithTooltipButtonContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
