import styled, { css } from 'styled-components';

export const ImageContainer = styled.div`
  ${(props) =>
    props.display
      ? css`
          width: 40px;
          height: 40px;

          display: flex;
          align-items: center;
          justify-content: center;

          border-radius: 4px;
          background-color: ${(props) =>
            props.readOnly
              ? props.theme.colors.greys.lightest
              : props.background
              ? props.theme.colors.greys.lighter
              : props.theme.colors.greys.darkest};
        `
      : css`
          display: none;
        `}
`;

export const PlaceholderContainer = styled.div`
  ${(props) =>
    props.button && props.src
      ? `width: 250px;
      height: 250px`
      : `width: 100%;
      height: 100%;`};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 16px;

  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${(props) => (props.button ? 'contain' : 'cover')};

  ${(props) =>
    props.readOnly
      ? css`
          cursor: default;
        `
      : css`
          cursor: pointer;

          ${props.isHover &&
          css`
            box-shadow: inset 0 0 0 1000px rgb(29 29 27 / 64%);
          `}
        `}
`;
