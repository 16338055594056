import moment from 'moment-timezone';
import React from 'react';
import styled from 'styled-components';

import { InpulseEventsOfDay } from './CalendarEvent';
import DataTile from '../DataTile';

const CalendarDayContent = ({
  date,
  border,
  weather,
  forecasts,
  events,
  toggleTooltip,
  toggleSprite,
  handleEventEdit,
  sales,
  managerPrevDisplay,
  displayName,
  changeActiveDate,
  data,
  index,
  maxDisplayedEventsInColumn,
  storeTimezone,
  ...props
}) => {
  if (data && data.length) {
    data = data.filter((day) =>
      moment.tz(day.date, storeTimezone).isSame(moment.tz(date, storeTimezone), 'day'),
    );
  }
  const isPast = date.isBefore(moment.tz(storeTimezone).startOf('day'));

  return (
    <div
      className={`${props.className} ${isPast ? 'past' : 'future'}`}
      style={{ borderRightWidth: border === true ? '1px' : '0px' }}
    >
      <div className="forecast-calendar-tiles-encloser">
        {data &&
          data.length > 0 &&
          data[0].breakdownDatas.map((data) => (
            <div key={date.format() + data.baseName} style={{ marginTop: '5px', width: '100%' }}>
              <DataTile
                background={
                  date.isBefore(moment.tz(storeTimezone).startOf('day')) ? '#F1F1F1' : null
                }
                data={data}
                date={date}
                displayName={displayName}
                handleDayOpen={() => changeActiveDate(date, data.baseName)}
                id={date.format() + data.baseName}
                index={index}
                isCalendar={true}
                isPast={isPast}
                key={data.name}
                mouseEnter={() => toggleSprite(date, data.baseName)}
                mouseLeave={() => toggleSprite(null, null)}
              />
            </div>
          ))}
      </div>
      <div
        style={{
          borderRight: '1px solid #E2E2E2',
          minHeight: '262px',
          height: maxDisplayedEventsInColumn * 48 - 18 + 'px',
        }}
      >
        <InpulseEventsOfDay
          date={date}
          events={events}
          handleEventEdit={handleEventEdit}
          storeTimezone={storeTimezone}
          toggleTooltip={toggleTooltip}
        />
      </div>
    </div>
  );
};

const InpulseCalendarDayContent = styled(CalendarDayContent)`
  width: calc((100% - 100px) / 7);
  height: 100%;
  display: inline-block;
  vertical-align: top;

  &.past {
    background: ${(props) => `repeating-linear-gradient(
        135deg,
        ${props.theme.colors.greys.lightest},
        ${props.theme.colors.greys.lightest} 2px,
        ${props.theme.colors.greys.lighter} 2px,
        ${props.theme.colors.greys.lighter} 4px
      )`};
  }

  &.future {
    background: ${(props) => props.theme.colors.greys.lightest};
  }

  .forecast-calendar-tiles-encloser {
    height: 227px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 17px 5%;
    border-right: ${(props) => `1px solid ${props.theme.colors.greys.light}`};
  }
`;

export { InpulseCalendarDayContent };
