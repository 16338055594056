import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import sortActionsList from '@commons/utils/sortActionsList';

import { ENUM_WARNING_MODAL_TYPE } from './modalConfigurations';
import { isUserNotInvited, isUserRegistered } from './status';

export const getRowActionsSettings = ({
  openWarningModal,
  userId,
  isUserPath,
  handleOpenUserDetail,
  isInpulseUser,
  handleExport,
}) => {
  const rowActions = [
    {
      id: 'export-users',
      actionLabel: () => i18next.t('BACKOFFICE.TEAM.EXPORT_USER'),
      handleAction: (item) => handleExport([item]),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
    {
      id: 'action-send-resend-invitation',
      actionLabel: (selectedUser) =>
        isUserNotInvited(selectedUser)
          ? i18next.t('BACKOFFICE.TEAM.SEND_INVITATION')
          : i18next.t('BACKOFFICE.TEAM.RESEND_INVITATION'),
      handleAction: (selectedUser) => {
        openWarningModal(ENUM_WARNING_MODAL_TYPE.SEND_EMAIL, [selectedUser]);
      },
      isDisabled: (selectedUser) => isUserRegistered(selectedUser),
      actionIcon: (selectedUser) =>
        isUserRegistered(selectedUser)
          ? '/images/inpulse/send-lgrey-small.svg'
          : '/images/inpulse/send-black-small.svg',
    },
    {
      id: 'delete-user',
      actionLabel: () => i18next.t('BACKOFFICE.TEAM.USER_DELETION_MODAL_TITLE'),
      handleAction: (selectedUser) =>
        openWarningModal(ENUM_WARNING_MODAL_TYPE.DELETE_USERS, [selectedUser]),
      isDisabled: (selectedUser) => selectedUser.id === userId,
      actionIcon: (selectedUser) =>
        selectedUser.id === userId
          ? '/images/inpulse/delete-ip-lmgrey-small.svg'
          : '/images/inpulse/delete-ip-black-small.svg',
    },
    {
      id: 'csm-activate-forecast',
      actionLabel: () => i18next.t('BACKOFFICE.DATA.ACTIVATE_FORECAST_WHITECARD_TITLE'),
      handleAction: (item) =>
        openWarningModal(ENUM_WARNING_MODAL_TYPE.EDIT_FORECASTS, [item], true),
      actionIcon: (item) =>
        item.hasForecasts
          ? '/images/inpulse/power-lmgrey-small.svg'
          : '/images/inpulse/power-black-small.svg',
      isDisabled: (item) => item.hasForecasts,
      isHidden: () => !isInpulseUser,
      isHighlighted: () => true,
    },
    {
      id: 'csm-deactivate-forecast',
      actionLabel: () => i18next.t('BACKOFFICE.TEAM.DISABLE_FORECAST'),
      handleAction: (item) =>
        openWarningModal(ENUM_WARNING_MODAL_TYPE.EDIT_FORECASTS, [item], false),
      actionIcon: (item) =>
        !item.hasForecasts
          ? '/images/inpulse/power-lmgrey-small.svg'
          : '/images/inpulse/power-black-small.svg',
      isDisabled: (item) => !item.hasForecasts,
      isHidden: () => !isInpulseUser,
      isHighlighted: () => true,
    },
    {
      id: 'csm-show-payment-banner',
      actionLabel: () => i18next.t('BACKOFFICE.TEAM.SHOW_PAYMENT_BANNER'),
      handleAction: (selectedUser) =>
        openWarningModal(ENUM_WARNING_MODAL_TYPE.SHOW_PAYMENT_BANNER, [selectedUser], false),
      actionIcon: () => '/images/inpulse/visibility-show-white-small.svg',
      isHidden: (selectedUser) => !isInpulseUser || isUserPath || selectedUser.hasPaymentBanner,
      isHighlighted: () => true,
    },
    {
      id: 'csm-hide-payment-banner',
      actionLabel: () => i18next.t('BACKOFFICE.TEAM.HIDE_PAYMENT_BANNER'),
      handleAction: (selectedUser) =>
        openWarningModal(ENUM_WARNING_MODAL_TYPE.HIDE_PAYMENT_BANNER, [selectedUser], false),
      actionIcon: () => '/images/inpulse/visibility-hide-white-small.svg',
      isHidden: (selectedUser) => !isInpulseUser || isUserPath || !selectedUser.hasPaymentBanner,
      isHighlighted: () => true,
    },
  ];

  if (isUserPath) {
    const updateUser = {
      id: 'action-update-user',
      actionLabel: () => i18next.t('BACKOFFICE.TEAM.USER_UPDATE_USER_ACTION_LABEL'),
      isDisabled: () => false,
      handleAction: (user) => handleOpenUserDetail(user),
      actionIcon: () => '/images/inpulse/user-black-small.svg',
    };

    rowActions.unshift(updateUser);
  }

  return sortActionsList(rowActions);
};

export const getActionsSettings = ({
  isLoading,
  disableCreation,
  openUserCreationModal,
  selectedItems,
  userId,
  openWarningModal,
  isInpulseUser,
  accounts,
  handleExport,
  isUserPath,
}) => {
  const hasSelectedItems = !!selectedItems.length;
  const isUniqueSelection = selectedItems.length === 1;
  const actions = [
    {
      id: 'add',
      fixed: true,
      actionLabel: i18next.t('GENERAL.CREATE'),
      handleAction: () => openUserCreationModal(),
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={openUserCreationModal}
          icon={'/images/inpulse/add-white-small.svg'}
          isDisabled={isLoading}
          label={i18next.t('GENERAL.CREATE')}
        />
      ),
    },
    {
      id: 'action-send-resend-invitation',
      actionLabel: isUserNotInvited(selectedItems[0])
        ? i18next.t('BACKOFFICE.TEAM.SEND_INVITATION')
        : i18next.t('BACKOFFICE.TEAM.RESEND_INVITATION'),
      handleAction: () => {
        openWarningModal(ENUM_WARNING_MODAL_TYPE.SEND_EMAIL, selectedItems);
      },
      isDisabled: () => isUserRegistered(selectedItems[0]),
      actionIcon: () =>
        isUserRegistered(selectedItems[0])
          ? '/images/inpulse/send-lgrey-small.svg'
          : '/images/inpulse/send-black-small.svg',
      isHidden: () => !isUniqueSelection,
    },
    {
      id: 'export-users',
      actionLabel: hasSelectedItems
        ? isUniqueSelection
          ? i18next.t('BACKOFFICE.TEAM.EXPORT_USER')
          : i18next.t('ORDERS.ORDERS.EXPORT_SELECTION')
        : i18next.t('GENERAL.EXPORT_LIST'),
      handleAction: (items) => (hasSelectedItems ? handleExport(items) : handleExport(accounts)),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
    {
      id: 'delete-users',
      actionLabel: isUniqueSelection
        ? i18next.t('BACKOFFICE.TEAM.USER_DELETION_MODAL_TITLE')
        : i18next.t('GENERAL.DELETE_USERS'),
      handleAction: () => openWarningModal(ENUM_WARNING_MODAL_TYPE.DELETE_USERS, selectedItems),
      actionIcon: () =>
        selectedItems.find((user) => user.id === userId)
          ? '/images/inpulse/delete-ip-lmgrey-small.svg'
          : '/images/inpulse/delete-ip-black-small.svg',
      isHidden: () => !hasSelectedItems,
    },
    {
      id: 'csm-activate-forecast',
      actionLabel: i18next.t('BACKOFFICE.DATA.ACTIVATE_FORECAST_WHITECARD_TITLE'),
      handleAction: () =>
        openWarningModal(ENUM_WARNING_MODAL_TYPE.EDIT_FORECASTS, selectedItems, true),
      actionIcon: () =>
        isUniqueSelection && selectedItems[0].hasForecasts === true
          ? '/images/inpulse/power-lmgrey-small.svg'
          : '/images/inpulse/power-black-small.svg',
      isDisabled: () => isUniqueSelection && selectedItems[0].hasForecasts === true,
      isHidden: () => !isInpulseUser || !hasSelectedItems,
      isHighlighted: () => true,
    },
    {
      id: 'csm-deactivate-forecast',
      actionLabel: i18next.t('BACKOFFICE.TEAM.DISABLE_FORECAST'),
      handleAction: () =>
        openWarningModal(ENUM_WARNING_MODAL_TYPE.EDIT_FORECASTS, selectedItems, false),
      actionIcon: () =>
        isUniqueSelection && selectedItems[0].hasForecasts === false
          ? '/images/inpulse/power-lmgrey-small.svg'
          : '/images/inpulse/power-black-small.svg',
      isDisabled: () => isUniqueSelection && selectedItems[0].hasForecasts === false,
      isHidden: () => !isInpulseUser || !hasSelectedItems,
      isHighlighted: () => true,
    },
    {
      id: 'csm-show-payment-banner',
      actionLabel: i18next.t('BACKOFFICE.TEAM.SHOW_PAYMENT_BANNER'),
      handleAction: () =>
        openWarningModal(ENUM_WARNING_MODAL_TYPE.SHOW_PAYMENT_BANNER, selectedItems, false),
      actionIcon: () => '/images/inpulse/visibility-show-white-small.svg',
      isHidden: () => !isInpulseUser || isUserPath || !hasSelectedItems,
      isHighlighted: () => true,
    },
    {
      id: 'csm-hide-payment-banner',
      actionLabel: i18next.t('BACKOFFICE.TEAM.HIDE_PAYMENT_BANNER'),
      handleAction: () =>
        openWarningModal(ENUM_WARNING_MODAL_TYPE.HIDE_PAYMENT_BANNER, selectedItems, false),
      actionIcon: () => '/images/inpulse/visibility-hide-white-small.svg',
      isHidden: () => !isInpulseUser || isUserPath || !hasSelectedItems,
      isHighlighted: () => true,
    },
  ];

  if (!disableCreation) {
    return sortActionsList(actions);
  }

  return sortActionsList(actions.filter(({ id }) => id !== 'add'));
};

export default {
  getActionsSettings,
  getRowActionsSettings,
};
