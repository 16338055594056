import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  padding: 48px;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 24px;
`;

export const PictureContainer = styled.div``;

export const Picture = styled.img``;

export const TextContainer = styled.div``;

export const Text = styled.div`
  font: ${(props) => props.theme.fonts.textSmallHeight24};
  color: ${(props) => props.theme.colors.greys.darker};
`;

export default {
  Container,
  PictureContainer,
  TextContainer,
};
