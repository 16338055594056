import styled, { css } from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  position: relative;
  width: ${(props) => `${props.width}px`};
  height: 40px;
  background-color: transparent;

  .SingleDatePicker {
    position: inherit;
    display: flex;
  }

  .SingleDatePicker_picker {
    position: inherit;
    display: flex;
    background-color: transparent;
    box-shadow: none !important;
  }

  .SingleDatePickerInput {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: absolute;
    padding: 0 16px;
    gap: 8px;

    background-color: ${(props) => props.theme.colors.greys.lightest};
    border: 1px solid ${(props) => props.theme.colors.greys.light};
    border-radius: 8px;
    cursor: pointer;
    width: ${(props) => `${props.width}px`};
    height: 40px;

    &:hover {
      background-color: ${(props) => props.theme.colors.greys.lighter};
    }

    ${(props) =>
      props.focused &&
      css`
        border: 1px solid ${(props) => props.theme.colors.greys.darkest};
      `}

    ${(props) =>
      props.disabled &&
      css`
        background-color: ${(props) => props.theme.colors.greys.light};
        cursor: not-allowed;

        &:hover {
          background-color: ${(props) => props.theme.colors.greys.light};
        }
      `}

    ${(props) =>
      props.isErrorState &&
      css`
        border: 1px solid ${(props) => props.theme.colors.red};
      `}
  }

  .SingleDatePickerInput_calendarIcon {
    padding: 0;
    margin: 0;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .DayPicker {
    border-radius: 8px;
  }

  .DayPicker > div:first-child {
    display: flex;
    flex-direction: column-reverse;
    gap: ${(props) => (props.calendarInfo ? '16px' : 'none')};
  }

  .DayPicker__withBorder {
    background: transparent;
    box-shadow: none;
  }

  .DayPicker_transitionContainer {
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  }

  .DateInput {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    ${(props) =>
      props.disabled &&
      css`
        cursor: not-allowed;
      `}
  }

  .DateInput_input {
    font: ${(props) => props.theme.fonts.textSmallHeight16} !important;
    color: ${(props) =>
      props.disabled ? props.theme.colors.white : props.theme.colors.greys.darkest} !important;
    cursor: pointer;
    border: 0;
    width: 100%;
    height: 100%;
    padding-top: 0px !important;

    ${(props) =>
      props.disabled &&
      css`
        cursor: not-allowed;

        &::placeholder {
          color: ${(props) => props.theme.colors.greys.lightest} !important;
        }
      `}
  }

  .DateInput_fang {
    display: none !important;
  }

  .SingleDatePicker_picker {
    background: transparent;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 16%);
    position: absolute;
    z-index: 10;

    border-radius: 8px;
    top: 48px !important;
  }

  .CalendarMonth_caption {
    font: ${(props) => props.theme.fonts.h3} !important;
    color: ${(props) => props.theme.colors.greys.darkest} !important;
  }

  .DayPicker_weekHeader_li {
    font: ${(props) => props.theme.fonts.textMicro} !important;
  }

  .CalendarDay {
    background: ${(props) => props.theme.colors.greys.lightest} !important;
    color: ${(props) => props.theme.colors.greys.darkest} !important;
    font: ${(props) => props.theme.fonts.textSmall} !important;
  }

  .CalendarDay__default:hover {
    background: ${(props) => props.theme.colors.greys.lighter} !important;
    color: ${(props) => props.theme.colors.greys.darkest} !important;
    font: ${(props) => props.theme.fonts.textSmall} !important;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active .CalendarDay__selected:hover {
    background: ${(props) => props.theme.colors.greys.darkest} !important;
    color: ${(props) => props.theme.colors.greys.lightest} !important;
    font: ${(props) => props.theme.fonts.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover {
    color: ${(props) => props.theme.colors.greys.dark} !important;
    background: ${(props) => props.theme.colors.greys.lighter} !important;
    cursor: not-allowed;
  }

  .SingleDatePickerInput_clearDate__default:focus,
  .SingleDatePickerInput_clearDate__default:hover {
    background: transparent;
    border-radius: 50%;
  }
`;
