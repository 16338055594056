import i18next from 'i18next';
import moment from 'moment';
import React from 'react';

import { Container, Title, Subtitle } from './styledComponents';

const getFormattedLastUpdatedText = (inventory) => {
  if (!inventory || !inventory.updatedAt) {
    return i18next.t('STOCKS.STOCKS.FORM_LAST_CHANGE_WITHOUT_DAY');
  }

  const inventoryUpdateDate = moment(inventory.updatedAt);

  if (moment().isSame(inventory.updatedAt, 'day')) {
    return i18next.t('STOCKS.STOCKS.FORM_LAST_CHANGE_SAME_DAY', {
      hours: inventoryUpdateDate.format('HH'),
      separator: i18next.t('GENERAL.DATE_TIME_FORMAT_HOUR_SEPARATOR'),
      minutes: inventoryUpdateDate.format('mm'),
    });
  }

  return i18next.t('STOCKS.STOCKS.FORM_LAST_CHANGE_OTHER_DAY', {
    date: inventoryUpdateDate.format('DD/MM/YYYY'),
    hours: inventoryUpdateDate.format('HH'),
    separator: i18next.t('GENERAL.DATE_TIME_FORMAT_HOUR_SEPARATOR'),
    minutes: inventoryUpdateDate.format('mm'),
  });
};

function StockFormHeaderInfo(props) {
  const { inventory, reference } = props;

  return (
    <Container>
      <Title>
        {i18next.t('STOCKS.STOCKS.FORM_TYPE_LABEL')} {reference}
      </Title>
      <Subtitle>{getFormattedLastUpdatedText(inventory)}</Subtitle>
    </Container>
  );
}

export default StockFormHeaderInfo;
