export const EDI_PARTNER = [
  'AMB',
  'AXIANE',
  'BDF',
  'DEMO',
  'DMG',
  'KHADISPAL',
  'MMT',
  'NETSUITE',
  'PMN',
  'PMNAS2',
  'PROGIAL',
  'PTD',
  'RDT',
  'SELLSY',
  'STEF',
  'STEF_KKDF',
  'STEF_FEUILLETTE',
  'TGT',
  'SELLSY',
  'SOUFFLET',
  'VIF_SFTP',
  'VIF_EMAIL',
];
