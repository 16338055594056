import { differenceBy, first } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { Button, Dropdown } from '@commons/utils/styledLibraryComponents';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { Input } from '@lib/inpulse/Input';

import { UNITS_DROPDOWN_ITEMS } from '@admin/utils/DropdownItems';

import {
  ModalConversionContainer,
  ModalConversionInputs,
  ModalConversionRow,
} from './styledComponents';

const InputConversionContentModal = (props) => {
  const { packagingUnit: baseUnit, existingConversions, handleConversionsUpdate } = props;

  const units = UNITS_DROPDOWN_ITEMS.filter(({ id }) => id !== baseUnit.id);
  const [summary, setSummary] = useState('');
  const [availableConversions, setAvailableConversions] = useState(units);
  const [conversions, setConversions] = useState([]);
  const [conversionWithValues, setConversionWithValues] = useState([]);

  // Set default values for existing conversions
  useEffect(() => {
    if (existingConversions.length > 0) {
      const formattedExistingConversions = existingConversions.map(
        ({ convertedUnit, convertedQuantity }) => ({
          convertedUnit,
          convertedQuantity,
          displayedUnit: UNITS_DROPDOWN_ITEMS.find(({ id }) => id === convertedUnit).value,
          isBaseUnit: convertedUnit === baseUnit.id,
        }),
      );

      setConversions(formattedExistingConversions);
      return;
    }

    const firstUnit = UNITS_DROPDOWN_ITEMS.find(({ id }) => id !== baseUnit.id);
    // Base state for conversions
    setConversions([
      {
        displayedUnit: baseUnit.value,
        convertedUnit: baseUnit.id,
        convertedQuantity: '',
        isBaseUnit: true,
      },
      {
        displayedUnit: firstUnit.value,
        convertedUnit: firstUnit.id,
        convertedQuantity: '',
        isBaseUnit: false,
      },
    ]);
  }, []);

  useEffect(() => {
    // All units are available except those that have already a conversion
    // It is required to map object since the object that will be stored in the DB has the convertedUnit property
    // But the UNITS_DROPDOWN_ITEMS enum has the id property
    setAvailableConversions(
      differenceBy(
        UNITS_DROPDOWN_ITEMS,
        conversions.map(({ convertedUnit }) => ({ id: convertedUnit })),
        'id',
      ),
    );

    handleConversionsUpdate(conversions);

    const conversionWithValues = conversions.filter(
      ({ convertedUnit, convertedQuantity }) => convertedUnit && convertedQuantity,
    );

    setConversionWithValues(conversionWithValues);
  }, [conversions]);

  useEffect(() => {
    // Setting summary text in modal
    const updatedSummaryText = conversionWithValues
      .map(({ displayedUnit, convertedQuantity }) => `${convertedQuantity} ${displayedUnit}`)
      .join(' = ');

    setSummary(updatedSummaryText);
  }, [conversionWithValues]);

  const handleUnitChange = ({ id, value }, index) => {
    const updatedConversions = [...conversions];
    updatedConversions[index].convertedUnit = id;
    updatedConversions[index].displayedUnit = value;

    setConversions(updatedConversions);
  };

  const handleQuantityChange = (newQuantity, index) => {
    const updatedConversions = [...conversions];
    updatedConversions[index].convertedQuantity = newQuantity;

    setConversions(updatedConversions);
  };

  const handleAddRow = () => {
    const updatedConversions = [...conversions];
    const availableConversion = first(availableConversions);

    if (availableConversion) {
      updatedConversions.push({
        convertedUnit: availableConversion.id,
        displayedUnit: availableConversion.value,
        isBaseUnit: false,
        convertedQuantity: null,
      });

      setConversions(updatedConversions);
    }
  };

  const handleDeleteRow = () => {
    const updatedConversions = [...conversions];
    updatedConversions.pop();

    setConversions(updatedConversions);
  };

  return (
    <ModalConversionContainer>
      {conversions.map(({ displayedUnit, convertedUnit, convertedQuantity, isBaseUnit }, index) => (
        <ModalConversionRow key={convertedUnit}>
          <Text
            color={isBaseUnit ? ENUM_COLORS.DARKEST : ENUM_COLORS.DARK}
            font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}
          >
            {isBaseUnit
              ? i18next.t('GENERAL.PACKAGING')
              : i18next.t('ADMIN.SUPPLIER_PRODUCTS.CONVERSION')}{' '}
            :
          </Text>
          <ModalConversionInputs>
            <Dropdown
              customStyle={{ position: 'inherit' }}
              isDisabled={isBaseUnit}
              items={availableConversions}
              placeholder={i18next.t('GENERAL.CHOOSE_PLACEHOLDER')}
              selectedItem={isBaseUnit ? baseUnit : { id: convertedUnit, value: displayedUnit }}
              onSelectionChange={(unit) => handleUnitChange(unit, index)}
            />
            <Input
              min={0}
              type="number"
              value={convertedQuantity}
              onChange={(e) => handleQuantityChange(e.target.value, index)}
            />
            {index >= 2 && (
              <Button
                buttonCustomStyle={{
                  border: '1px solid #e2e2e2',
                  borderRadius: '8px',
                }}
                color={'inpulse-outline'}
                handleClick={handleDeleteRow}
                icon={'/images/inpulse/close-black-small.svg'}
                iconCustomStyle={{ width: '40px' }}
              />
            )}
          </ModalConversionInputs>
        </ModalConversionRow>
      ))}
      {conversions.length < UNITS_DROPDOWN_ITEMS.length && (
        <ModalConversionRow>
          <Button
            buttonCustomStyle={{ padding: '0', marginBottom: '16px' }}
            color={'inpulse-outline'}
            handleClick={handleAddRow}
            icon={'/images/inpulse/add-black-small.svg'}
            isDisabled={conversionWithValues.length !== conversions.length}
            label={i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONVERSIONS_ADD')}
            noBorder={true}
          />
        </ModalConversionRow>
      )}
      {conversionWithValues.length > 1 && (
        <ModalConversionRow>
          <Text
            color={ENUM_COLORS.DARKER}
            font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}
            fontLineHeight={20}
            fontSize={12}
          >
            {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONVERSIONS_EQUIVALENT')} :
          </Text>
          <Text fontLineHeight={20} fontWeight={700}>
            {summary}
          </Text>
        </ModalConversionRow>
      )}
    </ModalConversionContainer>
  );
};

export default InputConversionContentModal;
