import i18next from 'i18next';
import moment from 'moment';

import { formatNumberToExcelUsage } from '@commons/DisplayNumber';
import { getClientStoreNameTranslation } from '@commons/utils/translations';

import { METRIC_KEY } from './constants';

const convertToBetterDateFormat = (date) => moment(date).format('DD-MM-YYYY');

export const getMainSheetColumnsSettings = (metric, storeName, groupByStore = false) => {
  const isMetricUnit = metric === METRIC_KEY.UNIT;
  const type = isMetricUnit ? '' : 'currency';
  const transform = (value) => (value == null ? null : formatNumberToExcelUsage(value, 2));

  const columns = [
    {
      name: i18next.t('ORDERS.BY_CATEGORY.LABEL_CATEGORY'),
      propertyKey: 'category',
    },
    {
      name: i18next.t('ORDERS.BY_CATEGORY.LABEL_INGREDIENT'),
      propertyKey: 'name',
    },
    {
      name: i18next.t('ORDERS.BY_CATEGORY.LABEL_SUPPLIER_ORDERS'),
      propertyKey: 'orderPurchase',
      type,
      transform,
    },
    {
      name: i18next.t('ORDERS.BY_CATEGORY.LABEL_RUSH_ORDERS'),
      propertyKey: 'rushOrder',
      type,
      transform,
    },
    {
      name: i18next.t('ORDERS.BY_CATEGORY.LABEL_TRANSFER_IN'),
      propertyKey: 'transferIn',
      type,
      transform,
    },
    {
      name: i18next.t('ORDERS.BY_CATEGORY.LABEL_TRANSFER_OUT'),
      propertyKey: 'transferOut',
      type,
      transform,
    },
    {
      name: i18next.t('ORDERS.BY_CATEGORY.LABEL_INITIAL_STOCK'),
      propertyKey: 'initialStock',
      type,
      transform,
    },
    {
      name: i18next.t('ORDERS.BY_CATEGORY.LABEL_FINAL_STOCK'),
      propertyKey: 'finalStock',
      type,
      transform,
    },
    {
      name: i18next.t('ORDERS.BY_CATEGORY.LABEL_VARIATION_STOCK'),
      propertyKey: 'variationStock',
      type,
      transform,
    },
    {
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_ACTUAL_FC'),
      propertyKey: 'realConsumption',
      type,
      transform,
    },
    {
      name: i18next.t('ORDERS.BY_CATEGORY.LABEL_ACTUAL_FOODCOST_RATIO'),
      propertyKey: 'realOverTurnover',
      type: 'percentage',
    },
    {
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_THEORETICAL_FC'),
      propertyKey: 'theoreticalConsumption',
      type,
      transform,
    },
    {
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_FC_DEVIATION'),
      propertyKey: 'realFromTheorical',
      type,
      transform,
    },
    {
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_PERCENT_FC_DEVIATION'),
      propertyKey: 'relativeRealFromTheorical',
      type: 'percentage',
    },
    {
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_WASTES'),
      propertyKey: 'loss',
      type,
      transform,
    },
    {
      name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_INVENTORY_SHRINKAGE'),
      propertyKey: 'inventoryShrinkage',
      type,
      transform,
    },
  ];

  if (groupByStore) {
    columns.splice(0, 0, {
      name: getClientStoreNameTranslation(storeName, false),
      propertyKey: 'storeName',
    });
  }

  if (isMetricUnit) {
    columns.push({
      name: i18next.t('GENERAL.UNIT'),
      propertyKey: 'unit',
    });
  }

  return columns;
};

export const getContextSheetColumnsSettings = () => [
  {
    name: i18next.t('GENERAL.STORE'),
    propertyKey: 'storeName',
  },
  {
    name: i18next.t('ORDERS.BY_CATEGORY.LABEL_START_DATE'),
    propertyKey: 'startDate',
    transform: (value) => convertToBetterDateFormat(value),
  },
  {
    name: i18next.t('ORDERS.BY_CATEGORY.LABEL_END_DATE'),
    propertyKey: 'endDate',
    transform: (value) => convertToBetterDateFormat(value),
  },
  {
    name: i18next.t('ORDERS.BY_CATEGORY.LABEL_TURNOVER_EX_TAX'),
    propertyKey: 'turnover',
    type: 'currency',
  },
  {
    name: i18next.t('ANALYSIS.FOOD_COST.LOSS_RATE'),
    propertyKey: 'lossRate',
  },
];
