import React from 'react';

import { Tooltip } from '../utils/styledLibraryComponents';
import TooltipText from './styledComponents';

const TextWithTooltip = ({ text, content }) => (
  <Tooltip
    renderTooltipTrigger={() => <TooltipText>{content}</TooltipText>}
    text={text}
    displayBigger
  />
);

export default TextWithTooltip;
