import styled, { css } from 'styled-components';

export const GraphContainer = styled.div`
  z-index: 1;
  position: absolute;

  display: flex;
  flex-direction: column;
  left: 40px;
  top: 25px;

  height: 210px;
  width: 450px;

  padding: 10px 20px 10px 20px;

  background: ${(props) => props.theme.graphModal?.backgroundColor};
  box-shadow: ${(props) => props.theme.graphModal?.boxShadow};
  border-radius: 5px;
  cursor: auto;
`;

export const TitleCloseRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30%;
  justify-content: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled.div`
  margin-bottom: 5px;

  color: ${(props) => props.theme.graphModal?.title?.color};
  font: ${(props) => props.theme.graphModal?.title?.font};
`;

export const Subtitle = styled.div`
  color: ${(props) => props.theme.graphModal?.subtitle?.color};
  font: ${(props) => props.theme.graphModal?.subtitle?.font};
`;

export const CloseIcon = styled.img`
  cursor: pointer;
`;

export const GraphContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;

  padding: ${(props) => props.theme.graphModal?.padding};
`;

export const GraphDisplayContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const GraphBars = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  border-bottom: ${(props) => props.theme.graphModal?.bar?.border};

  ${(props) =>
    props.nbColumn &&
    css`
      flex-basis: calc(100 / ${props.nbColumn}) %;
    `}
`;

export const GraphLabels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0 10px 0;

  font: ${(props) => props.theme.graphModal?.label?.font};
  color: ${(props) => props.theme.graphModal?.label?.color};

  ${(props) =>
    props.nbColumn &&
    css`
      flex-basis: calc(100 / ${props.nbColumn}) %;
    `}
`;

export const GraphValue = styled.div`
  color: ${(props) =>
    props.id + 1 === props.nbColumn
      ? props.theme.graphModal?.value?.highlightedColor
      : props.theme.graphModal?.value?.defaultColor};
  font: ${(props) => props.theme.graphModal?.value?.font};
  padding-bottom: 5px;
`;

export const GraphBar = styled.div`
  width: 15px;
  border-radius: 25px 25px 0 0;

  height: ${(props) => `${props.height}%` || 0};
  background: ${(props) =>
    props.id + 1 === props.nbColumn
      ? props.theme.graphModal?.bar?.highlightedColor
      : props.theme.graphModal?.bar?.defaultColor};
`;

export const GraphLabelDay = styled.div``;

export const EmptyGraph = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const DisplayIconNoData = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
`;

export const NoDataInfo = styled.div`
  font-family: inter;
`;

export default {
  GraphContainer,
  TitleCloseRow,
  TitleContainer,
  Title,
  Subtitle,
  CloseIcon,
  GraphContent,
  GraphDisplayContent,
  GraphLabels,
  GraphLabelDay,
  GraphBars,
  GraphValue,
  GraphBar,
  EmptyGraph,
  DisplayIconNoData,
  NoDataInfo,
};
