import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  overflow: auto;
`;

export const FormContainer = styled.div`
  width: 864px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin: auto;
  padding: 24px;
`;

// For custom Dropdown rendering
export const DropdownItem = styled.div`
  width: 232px;
  height: 64px;

  display: flex;
  align-items: center;

  gap: 8px;
`;

export const DropdownTitle = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  overflow: auto;
`;

export const DropdownItemMainValue = styled.div`
  font: ${(props) => props.theme.fonts.textMiddleNormal};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DropdownItemSecondaryValue = styled.div`
  font: ${(props) => props.theme.fonts.textSmallHeight16};
  color: ${(props) => props.theme.colors.greys.dark};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${(props) =>
    props.isDisabled &&
    css`
      color: ${props.theme.colors.greys.light};
    `};
`;

export const DropdownIcon = styled.img`
  width: 40px;
  height: 40px;
`;
