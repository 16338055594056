import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

import HiEmoji from '@assets/icons/emoji-hi.png';

const HelloComponent = (props) => {
  const { t } = useTranslation();
  return (
    <div className={props.className}>
      <div className="activity-summary-hello-name">
        <img alt="" src={HiEmoji} style={{}} />
        <div className="activity-summary-hello-name-text">
          {t('HOME.HELLO', { name: get(props.user, 'firstName') })}
        </div>
      </div>
      <div className="activity-summary-hello-message">{t('HOME.LOOK_ACTIVITY_KEY_METRICS_UP')}</div>
    </div>
  );
};

export default styled(HelloComponent)`
  background: ${(props) => props.theme.colors.greys.lightest};
  border-radius: ${(props) => props.theme.borders?.radius?.weak};
  margin-left: 30px;
  width: 30%;
  height: 120px;
  min-width: 426px;

  .activity-summary-hello-name {
    margin-top: 25px;
    margin-left: 20px;
    display: flex;

    > img {
      width: 30px;
      height: 30px;
    }

    .activity-summary-hello-name-text {
      color: ${(props) => props.theme.colors.greys.darkest};
      font: ${(props) => props.theme.fonts?.title};
      margin-left: 10px;
    }
  }

  .activity-summary-hello-message {
    margin-top: 8px;
    margin-left: 23px;
    color: ${(props) => props.theme.colors.greys.darkest};
    font: ${(props) => props.theme.fonts?.textBig};
  }
`;
