import i18next from 'i18next';
import React from 'react';

import { numberToFixed, stringValueReplacer } from '@commons/utils/format';
import DisplayNumber from '@commons/DisplayNumber';

export const getListHeaders = () => [
  {
    name: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
    large: true,
    isSortable: true,
    render: (value) => value.toUpperCase(),
  },
  {
    name: i18next.t('GENERAL.SOLD_QUANTITY'),
    propertyKey: `unit`,
    isNumber: true,
    isSortable: true,
    filterType: 'numeric',
    render: (item) => {
      const fixedQuantity = numberToFixed(item, 2, '-');
      return stringValueReplacer(fixedQuantity, ',', '.');
    },
  },
  {
    name: i18next.t('GENERAL.AVERAGE_PRICE_EX_TAX'),
    propertyKey: 'priceExcludingTaxes',
    tooltipDisplay: i18next.t('ANALYSIS.SALES.COLUMN_TOOLTIP_PAST_PRODUCT_MIX_PRICE_WITHOUT_TAXES'),
    isNumber: true,
    isSortable: true,
    filterType: 'numeric',
    render: (item) => <DisplayNumber number={item} displayCurrencyCode />,
  },
  {
    name: i18next.t('GENERAL.TURNOVER_EX_TAX'),
    propertyKey: 'turnoverExcludingTaxes',
    tooltipDisplay: i18next.t('ANALYSIS.SALES.COLUMN_TOOLTIP_REVENUE_WITHOUT_TAXES'),
    isNumber: true,
    isSortable: true,
    filterType: 'numeric',
    render: (item) => <DisplayNumber number={item} displayCurrencyCode />,
  },
  {
    name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_PERCENT_PRODUCT_MIX'),
    propertyKey: 'mixExcludingTaxes',
    tooltipDisplay: i18next.t('ANALYSIS.SALES.COLUMN_TOOLTIP_PERCENT_PRODUCT_MIX_WITHOUT_TAXES'),
    isNumber: true,
    isSortable: true,
    filterType: 'numeric',
    render: (item) => {
      const fixedMix = numberToFixed(item, 2, '-', '%');
      return stringValueReplacer(fixedMix, ',', '.');
    },
  },
  {
    name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_PERCENT_MARGIN'),
    propertyKey: 'marginRatio',
    tooltipDisplay: i18next.t('ANALYSIS.SALES.COLUMN_TOOLTIP_PERCENT_MARGIN_WITHOUT_TAXES'),
    isNumber: true,
    isSortable: true,
    filterType: 'numeric',
    render: (item) => numberToFixed(item, 2, '-', '%'),
  },
  {
    name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_MARGIN_WITHOUT_TAXES'),
    propertyKey: 'margin',
    tooltipDisplay: i18next.t('ANALYSIS.SALES.COLUMN_TOOLTIP_MARGIN_WITHOUT_TAXES'),
    isNumber: true,
    isSortable: true,
    filterType: 'numeric',
    render: (item) => <DisplayNumber number={item} displayCurrencyCode />,
  },
  {
    name: '',
    propertyKey: 'action',
    isClickable: true,
    isSortable: false,
  },
];
