import { ProgressBar } from 'react-bootstrap';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import {
  Container,
  Background,
  ModalContainer,
  ModalHeader,
  ModalContent,
  CloseModalImg,
  ProgressBarContainer,
} from './styledComponents';

const ProgressBarModal = (props) => {
  const { setOpenProgressBarModal, progressBarStatus } = props;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progressBarStatus.totalPicturesCount === 0) {
      setProgress(0);
      return;
    }
    setProgress((progressBarStatus.index / progressBarStatus.totalPicturesCount) * 100);
  }, [progressBarStatus]);

  return (
    <Container>
      <Background />
      <ModalContainer>
        <ModalHeader>
          <span>{i18next.t('ORDERS.ORDERS.FORM_PICTURE_UPLOAD_PROCESSING')}</span>
          <CloseModalImg
            src="/images/inpulse/close-black-small.svg"
            onClick={() => {
              setOpenProgressBarModal(false);
            }}
          />
        </ModalHeader>
        <ModalContent>
          <ProgressBarContainer>
            <ProgressBar now={progress} />
          </ProgressBarContainer>
        </ModalContent>
      </ModalContainer>
    </Container>
  );
};

export default ProgressBarModal;
