import _ from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { ContentContainer } from './styledComponents';

const DeletionModalContent = (data) => (
  <ContentContainer>
    {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_DELETE_MODAL_CONTENT', {
      packagingName: data.packagingName,
    })}
  </ContentContainer>
);

export const handleDelectionModalClose = (setGenericModalParams) => {
  setGenericModalParams(null);
};

const handleSave = (deletedPackaging, updatedPackagings, handleSupplierProductChange) => {
  const { formattedPackagings } = _.reduce(
    updatedPackagings,
    (result, packaging) => {
      if (packaging.id === deletedPackaging.id) {
        return result;
      }

      const updatedPackaging = { ...packaging };

      if (packaging.id === deletedPackaging.parentSupplierProductPackagingId) {
        const { isUsedInOrder, isUsedInStock } = deletedPackaging;

        updatedPackaging.isUsedInOrder = updatedPackaging.isUsedInOrder || isUsedInOrder;

        updatedPackaging.isUsedInStock = updatedPackaging.isUsedInStock || isUsedInStock;
      }

      result.formattedPackagings.push(updatedPackaging);

      return result;
    },
    { deletedPackagingIndex: null, formattedPackagings: [] },
  );

  handleSupplierProductChange(formattedPackagings, 'packagings');
};

export const handleDeletionModal = ({
  updatedPackagings,
  packaging,
  setGenericModalParams,
  handleSupplierProductChange,
}) => {
  const params = {
    type: 'warning',
    width: '542px',
    height: 'auto',
    title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_DELETE_MODAL_TITLE', {
      packagingName: packaging.name,
    }),
    icon: '/images/inpulse/trash-white-small.svg',
    data: {
      packagingName: packaging.name,
    },
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
        handleClick: () => handleDelectionModalClose(setGenericModalParams),
      },
      {
        key: 1,
        handleClick: () => handleSave(packaging, updatedPackagings, handleSupplierProductChange),
        color: 'inpulse-default',
        label: i18next.t('GENERAL.DELETE'),
        icon: '/images/inpulse/trash-white-small.svg',
      },
    ],
  };

  setGenericModalParams(params);
};

export default DeletionModalContent;
