export function getPartnerId(stores, storeId) {
  const matchingStore = stores.find(({ id }) => storeId === id);

  if (!matchingStore) {
    return null;
  }

  return matchingStore.partnerId;
}

export default {
  getPartnerId,
};
