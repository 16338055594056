import 'moment/locale/fr';
import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment';
import React, { Component } from 'react';

import {
  getSuppliersCategories,
  patchSupplier,
  patchSupplierError,
  patchSupplierSuccess,
  receiveSuppliersCategories,
  requestSuppliersCategoriesError,
} from '@actions/supplier';

import { showConfirmationMessage, showErrorMessage } from '@actions/messageconfirmation';

import { Button } from '@commons/utils/styledLibraryComponents';
import { userHasRoleInpulse } from '@commons/utils/roles';

import { getClientInfo } from '@selectors/client';

import { currencyService } from '@services/backoffice/currency';
import catalogsService from '@services/catalogs';

import { FooterButtonContainer, SupplierModalContainer } from './styledComponents';
import SupplierModalInformation from './SupplierModalInformation';

moment.locale('fr');

export class SupplierModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryOptions: [],
      catalogs: [],
      selectedCatalog: null,
      currencies: [props.currency],
      selectedCurrency: props.currency,
      supplier: {
        name: '',
        category: '',
        code: '',
        adress: '',
        city: '',
        postCode: '',
        country: '',
        active: true,
      },
      suppliersCategories: [],
      formCategories: {
        general: true,
        contact: true,
        localisation: true,
      },
      countryHasUniqueTimezone: true,
    };
  }

  exitModal = (message, supplierId) => {
    this.props.showMessage(message);

    if (this.props.params.reloadData) {
      this.props.params.reloadData();
    }

    this.props.closeModal();

    if (supplierId) {
      this.props.params.history.push(`/admin/suppliers/${supplierId}/details`);
    }
  };

  /**********\   DATA PROCESSING   /***********/

  componentDidMount() {
    this.props.getSuppliersCategories(this.props.client.clientId);

    if (this.props.client.hasMultipleCurrencies) {
      this.getCurrencies();
    }

    if (this.props.client.hasLocalCatalogs) {
      this.getCatalogs();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.suppliersCategories !== this.props.suppliersCategories) {
      const suppliersCategories = this.props.suppliersCategories.map((category, index) => ({
        id: index,
        name: category,
      }));
      this.setState({ suppliersCategories });
    }
  }

  getCurrencies = async () => {
    try {
      const allCurrencies = await currencyService.getAllCurrencies();

      const formattedAllCurrencies = allCurrencies.map((currency) => ({
        ...currency,
        name: `${currency.name} (${currency.alphabeticCode})`,
      }));

      this.setState({ currencies: formattedAllCurrencies });
    } catch (error) {
      this.setState({ currencies: [this.props.currency] });
    }
  };

  getCatalogs = async () => {
    try {
      const hasRoleInpulse = userHasRoleInpulse(this.props.params.user);
      const userCatalog = this.props.params.user.catalog;

      if (hasRoleInpulse) {
        const allCatalogs = await catalogsService.getCatalogsByClientId(this.props.client.clientId);

        this.setState({ catalogs: allCatalogs, selectedCatalog: userCatalog });
        return;
      }

      this.setState({ catalogs: [userCatalog], selectedCatalog: userCatalog });
    } catch {
      this.setState({ catalogs: [], selectedCatalog: null });
    }
  };

  /**********\   FORM HANDLERS   /***********/

  handleSupplierChange = (value, fieldName) => {
    this.setState({ supplier: { ...this.state.supplier, [fieldName]: value } });
  };

  handleCurrencyChange = (value) => {
    this.setState({ selectedCurrency: value });
  };

  handleCatalogChange = (value) => {
    this.setState({ selectedCatalog: value });
  };

  handleSuppliersCategoriesChange = (value) => {
    const formattedCategories = value.map(({ name, id }) => ({
      name: name.trim(),
      id,
    }));

    this.setState({ suppliersCategories: formattedCategories });
  };

  /**********\   SAVING   /***********/

  handleSave = async () => {
    let supplier = Object.assign({}, this.state.supplier);

    supplier.clientId = this.props.client.clientId;
    supplier.currencyId = this.state.selectedCurrency.id;
    supplier.catalogId = this.state.selectedCatalog?.id;

    try {
      const resultSupplier = await this.props.patchSupplier(supplier);

      this.exitModal(i18next.t('ADMIN.SUPPLIERS.CREATION_SUCCESS'), resultSupplier.id);
    } catch {
      this.props.showErrorMessage(i18next.t('ADMIN.SUPPLIERS.SUPPLIER_GLOBAL_SAVING_ERROR'));
    }
  };

  /**********\   RENDER   /***********/

  render() {
    const {
      supplier,
      suppliersCategories,
      currencies,
      selectedCurrency,
      catalogs,
      selectedCatalog,
    } = this.state;

    return (
      <SupplierModalContainer>
        <div style={{ width: '100%', height: '100%' }}>
          <div className="medium-modal-form-header">
            <div className="medium-modal-form-header-title">
              <span style={{ marginLeft: '10px' }}>
                {i18next.t('ADMIN.SUPPLIERS.EMPTY_STATE_ACTION')}
              </span>
            </div>
          </div>
          <SupplierModalInformation
            catalogs={catalogs}
            currencies={currencies}
            handleCatalogChange={this.handleCatalogChange}
            handleCurrencyChange={this.handleCurrencyChange}
            handleSupplierChange={this.handleSupplierChange}
            handleSuppliersCategoriesChange={this.handleSuppliersCategoriesChange}
            selectedCatalog={selectedCatalog}
            selectedCurrency={selectedCurrency}
            supplier={supplier}
            suppliersCategories={suppliersCategories}
            userHasRoleInpulse={userHasRoleInpulse(this.props.params.user)}
          />
          <div className="medium-modal-form-footer">
            <FooterButtonContainer>
              <Button
                color={'blue-outline-no-shadow'}
                handleClick={() => this.props.closeModal()}
                icon={'/images/inpulse/close-black-small.svg'}
                label={i18next.t('GENERAL.CANCEL')}
              />
              <Button
                color={'blue'}
                handleClick={() => this.handleSave()}
                icon={'/images/inpulse/save-white-small.svg'}
                label={i18next.t('GENERAL.SAVE')}
              />
            </FooterButtonContainer>
          </div>
        </div>
      </SupplierModalContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
  suppliersCategories: state.baseReducer.suppliersCategories,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  getSuppliersCategories: (clientId) =>
    dispatch(getSuppliersCategories(clientId)).then(
      (result) => dispatch(receiveSuppliersCategories(result)),
      (error) => dispatch(requestSuppliersCategoriesError(error)),
    ),
  patchSupplier: (supplier) =>
    dispatch(patchSupplier(supplier)).then(
      (result) => {
        dispatch(patchSupplierSuccess(result));
        return result;
      },
      (error) => {
        dispatch(patchSupplierError(error));
        throw 'Error';
      },
    ),
});

export default SupplierModal = connect(mapStateToProps, mapDispatchToProps)(SupplierModal);
