import { convertSPPQuantityInMasterUnit } from '../../routes/orders/utils/computePackagingToSupplierProduct';

/**
 * Handle convertion of a price from on a given packaging to another packaging
 *
 * @param {number} price              - The price to convert
 * @param {uuid} originalPackagingId  - The packaging id on which price is represented
 * @param {uuid} targettedPackagingId - The packaging id on which price will be converted
 * @param {SPP[]} packagings          - The list of packaging on which
 *
 * @return {number} - the converted price
 */
export const convertPriceToPackagingById = (
  price,
  originalPackagingId,
  targettedPackagingId,
  packagings,
) => {
  if (!packagings || !packagings.length || price === null) {
    return null;
  }

  if (originalPackagingId === targettedPackagingId) {
    return price;
  }

  const originalQuantityMasterUnit = convertSPPQuantityInMasterUnit(
    originalPackagingId,
    packagings,
  );

  const targettedQuantityMasterUnit = convertSPPQuantityInMasterUnit(
    targettedPackagingId,
    packagings,
  );

  return (price / originalQuantityMasterUnit) * targettedQuantityMasterUnit;
};

/**
 * Handle convertion of a quantity from on a given packaging to another packaging
 *
 * @param {number} quantity           - The quantity to convert
 * @param {uuid} originalPackagingId  - The packaging id on which quantity is represented
 * @param {uuid} targettedPackagingId - The packaging id on which quantity will be converted
 * @param {SPP[]} packagings          - The list of packaging on which
 *
 * @return {number} - the converted quantity
 */
export const convertQuantityToPackagingById = (
  quantity,
  originalPackagingId,
  targettedPackagingId,
  packagings,
) => {
  if (!packagings || !packagings.length || quantity === null) {
    return null;
  }

  if (originalPackagingId === targettedPackagingId) {
    return quantity;
  }

  const originalQuantityMasterUnit = convertSPPQuantityInMasterUnit(
    originalPackagingId,
    packagings,
  );

  const targettedQuantityMasterUnit = convertSPPQuantityInMasterUnit(
    targettedPackagingId,
    packagings,
  );

  return (quantity * originalQuantityMasterUnit) / targettedQuantityMasterUnit;
};

/**
 * Dispatch packagings to determine which packaging is used in reception, order, and invoice.
 * In case the user explicitly overwrites the reception packaging, we set the packaging used in reception based on the receptionPackagingId.
 * Otherwise, the packaging used in reception should be the one used in order. Hence the guard (hasOverwrittenReceptionPackaging).
 *
 * @param {string | null} receptionPackagingId - The ID of the reception packaging.
 * @param {Array} packagings - The list of packagings.
 *
 * @returns {Object} - An object containing the packaging used in reception, order, and invoice.
 */
export const computeTypesOfPackaging = (receptionPackagingId, packagings) =>
  packagings.reduce(
    (acc, packaging) => {
      if (receptionPackagingId === packaging.id) {
        acc.packagingUsedInReception = packaging;
        acc.hasOverwrittenReceptionPackaging = true;
      }

      if (!acc.hasOverwrittenReceptionPackaging && packaging.isUsedInOrder) {
        acc.packagingUsedInReception = packaging;
      }

      if (packaging.isUsedInOrder) {
        acc.packagingUsedInOrder = packaging;
      }

      if (packaging.isUsedInInvoice) {
        acc.packagingUsedInInvoice = packaging;
      }

      return acc;
    },
    { hasOverwrittenReceptionPackaging: false },
  );

export default {
  computeTypesOfPackaging,
  convertPriceToPackagingById,
  convertQuantityToPackagingById,
};
