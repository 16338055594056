import styled from 'styled-components';

export const ModalContainer = styled.div`
  margin-top: 45px;
  position: relative;
`;

export const InpulseDot = styled.div`
  left: 10px;
  z-index: 15;
  bottom: 60px;
  position: absolute;

  width: 7px;
  height: 7px;

  border-radius: 50px;
  background: ${(props) => props.theme.colors.brand.primary};
`;

export const ModalBody = styled.div`
  z-index: 10;
  bottom: 10px;
  position: absolute;

  width: 350px;
  height: 65px;

  margin-top: 0px;
  padding: 15px 10px 15px 25px;

  display: inline-block;

  border-radius: 3px;
  background: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);

  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;

  font: ${(props) => props.theme.fonts.textSmallHeight16};
  color: ${(props) => props.theme.colors.greys.darker};
`;

export default {
  ModalContainer,
  InpulseDot,
  ModalBody,
};
