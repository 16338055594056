import axios from '../core/http';

import { config } from '../config';

const baseApiUrl = config.baseApiUrl;

export const getHealthStatus = async () =>
  axios
    .get(`${baseApiUrl}/health/status`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => ({
      status: response.data,
      duration: response.duration,
    }))
    .catch(() => ({})); // Return empty object to bypass further checks
