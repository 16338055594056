import { parseStringToFloat } from './parseStringToFloat';

/**
 * Get price product from its price TTC and the VAT applied on it
 *
 * @param {Product} product - Product that need to be updated
 *
 * @returns {Number} The price HT
 */
export function getPriceExcludingVAT(product, delivery = false) {
  if (!product) {
    return null;
  }

  const vatRate = parseStringToFloat(delivery ? product.deliveryVatRate : product.vatRate);

  const deliveryPrice =
    parseStringToFloat(product.deliveryPrice) || parseStringToFloat(product.deliveryPriceWithTaxes);

  const onSitePrice =
    parseStringToFloat(product.price) || parseStringToFloat(product.priceWithTaxes);

  const price = delivery ? deliveryPrice : onSitePrice;

  if (!price) {
    return null;
  }

  if (!vatRate) {
    return price;
  }

  return Math.round((price / (1 + vatRate / 100)) * 100) / 100;
}

export default {
  getPriceExcludingVAT,
};
