/**
 * Dispatch action to update productionQtyByEntityIdAndDate redux value for a given entity id
 *
 * @param {Uuid} entityId - Entity to be produced id
 * @param {String} date - Production date
 * @param {Number} quantity - Quantity to produce
 *
 * @returns {void}
 */
export const productionPlanningUpdateQuantity = (entityId, date, quantity) => (dispatch) =>
  dispatch({
    type: 'PRODUCTION_PLANNING_UPDATE_QUANTITY',
    entityId,
    date,
    quantity,
  });

/**
 * Dispatch action to update productionQtyByEntityIdAndDate redux value for given entity ids
 *
 * @param {Object} updatedProductionQuantitiesByEntityIdAndDate - production quantities by entity id and date
 *
 * @returns {void}
 */
export const productionPlanningUpdateQuantityByBatch =
  (updatedProductionQuantitiesByEntityIdAndDate) => (dispatch) =>
    dispatch({
      type: 'PRODUCTION_PLANNING_UPDATE_QUANTITY_BY_BATCH',
      updatedProductionQuantitiesByEntityIdAndDate,
    });

/**
 * Dispatch action to reset productionQtyByEntityIdAndDate redux values
 *
 * @returns {void}
 */
export const resetProductionPlanningValues = () => (dispatch) =>
  dispatch({
    type: 'PRODUCTION_PLANNING_RESET_VALUES',
  });

/**
 * Dispatch action to initialize productionQtyByEntityIdAndDate redux value with given values
 *
 * @param {Object} productionQtyByEntityIdAndDate
 * @example productionQtyByEntityIdAndDate
 * {
 *  some-entity-id: {
 *      '2024-03-01': 42,
 *      '2024-03-02': 50,
 *  },
 *  some-entity-id2: {
 *      '2024-03-01': 30,
 *      '2024-03-02': 47,
 *  }
 * }
 *
 * @returns {void}
 */
export const initializeProductionPlanningValues = (productionQtyByEntityIdAndDate) => (dispatch) =>
  dispatch({
    type: 'PRODUCTION_PLANNING_INIT_VALUES',
    productionQtyByEntityIdAndDate,
  });
