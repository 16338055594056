import i18next from 'i18next';
import React from 'react';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import InputContentModal from '@commons/Modals/InputContentModal';

const CategorySubCategoryModal = (props) => {
  const { errorMessage, propertyName, inputValue, handleInputChange } = props;

  return (
    <InputContentModal
      errorMessage={errorMessage}
      label={i18next.t(
        propertyName === 'category'
          ? 'GENERAL.MODAL_CATEGORY_NAME'
          : 'GENERAL.MODAL_SUBCATEGORY_NAME',
      )}
      value={inputValue}
      autoFocus
      onChange={(value) => handleInputChange(value)}
    />
  );
};

export const getModalParams = ({
  propertyName,
  closeCleanUp,
  errorMessage,
  handleInputChange,
  inputValue,
  handleSaveNewItemDropdown,
}) => ({
  type: 'action',
  width: '560px',
  height: 'auto',
  icon: '/images/inpulse/add-black-small.svg',
  title: i18next.t(
    propertyName === 'category'
      ? 'GENERAL.LIST_CREATE_CATEGORY'
      : 'GENERAL.LIST_CREATE_SUBCATEGORY',
  ),
  isLoading: false,
  handleCloseCleanUp: closeCleanUp,
  component: () => (
    <CategorySubCategoryModal
      errorMessage={errorMessage}
      handleInputChange={handleInputChange}
      inputValue={inputValue}
      propertyName={propertyName}
    />
  ),
  closeConfig: {
    title: i18next.t('GENERAL.LEAVE'),
    content: <>{i18next.t('GENERAL.MODAL_CATEGORIES_CLOSE_CONFIRMATION_CONTENT')}</>,
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      isDisabled: !inputValue || !!errorMessage,
      handleClick: handleSaveNewItemDropdown,
    },
  ],
});

export default {
  getModalParams,
};
