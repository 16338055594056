import styled from 'styled-components';

export const YAxisBigContainer = styled.div`
  display: flex;
  height: calc(100% - 40px);

  margin-bottom: 35px;
  margin-right: -5px;
`;

export const YAxisContainer = styled.div`
  height: 100%;
  width: 1px;

  background: ${(props) => props.theme.colors?.greys.darker};
`;

export const YAxisContainerSmallLine = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const YAxisSmallLine = styled.div`
  flex: 1;
  height: 1px;
  margin-left: -2px;
  width: 4px;
  background: ${(props) => props.theme.colors?.greys.darker};
  margin-top: ${(props) => props.marginTop && props.marginTop};
`;

export const YAxisContainerValue = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: -12px;
  row-gap: 74px;
`;

export const YAxisValue = styled.div`
  flex: 1;
  text-align: end;

  margin-top: -9px;
  margin-right: 6px;

  font: ${(props) => props.theme.fonts?.textMicro};
`;
