export function getRecipeCost(recipe) {
  if (!recipe.compositionSettings.length) {
    recipe.renderedCost = 0;

    return recipe;
  }

  // Set rendered cost for each composition item
  recipe.compositionSettings.forEach((item) => {
    const cost = item.entity.cost;
    const packagingValue = item.entity.packagingValue;

    item.entity.renderedCost = cost * (packagingValue || 0);

    return item;
  });

  // Set cost for recipe itself
  recipe.recipeCost = recipe.compositionSettings
    .map((compositionSetting) => compositionSetting.entity.renderedCost)
    .reduce((a, b) => a + b, 0);

  return recipe;
}

export default {
  getRecipeCost,
};
