import React, { useEffect, useRef } from 'react';

// Hook version
export const useOutsideAlerter = (ref, handler) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        handler(true);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

// Wrapper version
const OutsideAlerter = ({ handler, children }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, handler);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;
