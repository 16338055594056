import i18next from 'i18next';
import moment from 'moment';
import React from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { InpulseLabel } from '@commons/DeepsightComponents';
import DisplayNumber from '@commons/DisplayNumber';
import Text, { ENUM_COLORS } from '@commons/Text';

import { statusLegend, statusColorCodes } from '@orders/OrderList/common/constants';

export const get = (store, supplier, storeName) => [
  {
    id: 'supplier.name',
    name: i18next.t('GENERAL.SUPPLIER'),
    propertyKey: 'supplierName',
    render: () => <Text color={ENUM_COLORS.DARKEST}>{supplier.name}</Text>,
  },
  {
    id: 'store.name',
    name: getClientStoreNameTranslation(storeName),
    propertyKey: 'storeName',
    render: () => <Text color={ENUM_COLORS.DARKEST}>{store.name}</Text>,
  },
  {
    id: 'startOrderDate',
    filterType: 'date',
    name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDER_COLUMN_DELIVERY_DATE'),
    propertyKey: 'startOrderDate',
    render: (date) => (
      <Text color={ENUM_COLORS.DARKEST}>
        {moment(date).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)}
      </Text>
    ),
  },
  {
    id: 'status',
    propertyKey: 'status',
    filterType: 'string',
    name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDER_COLUMN_STATUS'),
    // Used to render advanced status filter list
    list: Object.keys(statusLegend).reduce((result, key) => {
      result.push({
        id: key,
        status: key,
        itemValue: key,
        name: i18next.t(statusLegend[key].name),
        renderValue: () => {
          const statusColor = statusColorCodes.find(({ id }) => id == key);

          return (
            <div className="supplier-label-render">
              <InpulseLabel
                color={statusColor.inpulseColor}
                style={{
                  padding: '0',
                  border: 'none',
                  backgroundColor: 'transparent',
                }}
                text={i18next.t(statusLegend[key].name)}
                type={'tertiary'}
              />
            </div>
          );
        },
      });

      return result;
    }, []),
    render: (status) => {
      const label = Object.keys(statusLegend).includes(status)
        ? i18next.t(statusLegend[status].name)
        : i18next.t('ORDERS.ORDERS.LIST_LABEL_STATUS_NOT_DEFINED');

      const statusColor = statusColorCodes.find(({ id }) => id == status);

      return <InpulseLabel color={statusColor.inpulseColor} text={label} type={'tertiary'} />;
    },
  },
  {
    id: 'totalPrice',
    name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDER_COLUMN_TOTAL'),
    propertyKey: 'totalPrice',
    filterType: 'numeric',
    render: (totalPrice) => <DisplayNumber displayCurrencyCode={true} number={totalPrice} />,
  },
  {
    id: 'reference',
    large: true,
    propertyKey: 'reference',
    name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDER_COLUMN_REFERENCE'),
    render: (reference) => <Text color={ENUM_COLORS.DARKEST}>{reference}</Text>,
  },
];

export default { get };
