import styled from 'styled-components';

export const CustomInputIcon = styled.div`
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  height: '100%';
`;

export const ModalContentContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Label = styled.div`
  display: flex;

  font: ${(props) => props.theme.fonts.textBigHeight16};
  width: 100%;

  > span:first-child {
    margin-right: 2px;
    color: ${(props) => props.theme.colors.infoRed};
  }
`;

export const DateRangePickerContainer = styled.div`
  width: 240px;

  display: flex;
  flex-direction: column;
  row-gap: 8px;

  align-items: left;
  justify-content: center;

  cursor: default;

  box-shadow: none !important;
  -webkit-box-shadow: none !important;

  .DateRangePicker {
    width: 100%;
  }

  .DateRangePickerInput_calendarIcon {
    padding: 0;
    margin: 0;
  }
  .DateRangePickerInput {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    padding: 0 16px 0 16px;

    border: 1px solid ${(props) => props.theme.colors.greys.light};
    border-radius: ${(props) => props.theme.inputs.primary.default.borderRadius};
  }

  .DateRangePicker_picker {
    position: fixed !important;
    top: inherit !important;
    left: inherit !important;
    margin-top: 400px !important;
    margin-left: -16px;

    background: transparent;
  }

  .DateInput_input {
    color: ${(props) => props.theme.colors.greys.darkest} !important;
    font: ${(props) => props.theme.fonts.textSmall} !important;
    text-align: center;
    cursor: pointer;
    border-bottom: none !important;
  }

  .CalendarMonth_caption {
    font: ${(props) => props.theme.fonts.h3} !important;
  }

  & .CalendarDay {
    background: ${(props) => props.theme.colors?.greys.lightest} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__default:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__highlighted_calendar {
    background: #fff !important;
    border: 1px double #0a3eff !important;
  }

  & .CalendarDay__highlighted_calendar:hover {
    background: #e4e7e7 !important;
  }

  & .CalendarDay__selected_span {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    border: 1px double #e2e2e2 !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected,
  .CalendarDay__selected:active .CalendarDay__selected:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_start,
  .CalendarDay__selected_start:hover,
  .CalendarDay__selected_end,
  .CalendarDay__selected_end:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover {
    color: ${(props) => props.theme.colors?.greys.dark} !important;
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
  }
`;
