import i18next from 'i18next';
import React from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import AnalyticsList from '@commons/AnalyticsList';
import DisplayNumber, { formatNumberWithCurrency } from '@commons/DisplayNumber';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import TooltipButton from '@commons/TooltipButton';

import { tooltipTextsAnalyticsStore } from '@orders/OrderAnalyticsModulesContainer/constants/tooltipText';

import { Label, TextWithTooltipButtonContainer } from '../styledComponents';

const DEFAULT_DATA_FOR_FORMAT_NUMBER = {
  displayCurrencyCode: true,
  withoutDecimals: true,
  shortenDigits: true,
};

export const formatDataForTooltip = (data, currency, startDate, endDate) => [
  {
    categoryName: i18next.t('ORDERS.BY_CATEGORY.LABEL_ORDERED'),
    categoryValue: formatNumberWithCurrency({
      ...DEFAULT_DATA_FOR_FORMAT_NUMBER,
      number: data.orderTotal,
      currency,
    }),
    children: [
      {
        name: i18next.t('GENERAL.SUPPLIER_PLURAL'),
        value:
          data.order == null
            ? '--'
            : formatNumberWithCurrency({
                ...DEFAULT_DATA_FOR_FORMAT_NUMBER,
                number: data.order,
                currency,
              }),
      },
      {
        name: i18next.t('ORDERS.RUSH_ORDERS.RUSH_ORDER'),
        value:
          data.rushOrder == null
            ? '--'
            : formatNumberWithCurrency({
                ...DEFAULT_DATA_FOR_FORMAT_NUMBER,
                number: data.rushOrder,
                currency,
              }),
      },
    ],
  },
  {
    categoryName: i18next.t('STOCKS.STOCKS.TRANSFER_GRAPH_TOOLTIP'),
    categoryValue:
      data.transferTotal == null
        ? '--'
        : formatNumberWithCurrency({
            ...DEFAULT_DATA_FOR_FORMAT_NUMBER,
            number: data.transferTotal,
            currency,
          }),
    children: [
      {
        name: i18next.t('STOCKS.CURRENT_STOCKS.TOOLTIP_TRANSFER_IN'),
        value:
          data.transferIn == null
            ? '--'
            : formatNumberWithCurrency({
                ...DEFAULT_DATA_FOR_FORMAT_NUMBER,
                number: data.transferIn,
                currency,
              }),
      },
      {
        name: i18next.t('STOCKS.CURRENT_STOCKS.TOOLTIP_TRANSFER_OUT'),
        value:
          data.transferOut == null
            ? '--'
            : formatNumberWithCurrency({
                ...DEFAULT_DATA_FOR_FORMAT_NUMBER,
                number: data.transferOut,
                currency,
              }),
      },
    ],
  },
  {
    categoryName: i18next.t('ORDERS.BY_CATEGORY.LABEL_VARIATION_STOCK'),
    categoryValue:
      data.stockVariation == null
        ? '--'
        : formatNumberWithCurrency({
            ...DEFAULT_DATA_FOR_FORMAT_NUMBER,
            number: data.stockVariation,
            currency,
          }),
    children: [
      {
        name: startDate?.format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
        value:
          data.initialStock == null
            ? '--'
            : formatNumberWithCurrency({
                ...DEFAULT_DATA_FOR_FORMAT_NUMBER,
                number: data.initialStock,
                currency,
              }),
      },
      {
        name: endDate?.format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
        value:
          data.finalStock == null
            ? '--'
            : formatNumberWithCurrency({
                ...DEFAULT_DATA_FOR_FORMAT_NUMBER,
                number: data.finalStock,
                currency,
              }),
      },
    ],
  },
  {
    categoryName: i18next.t('GENERAL.TOTAL'),
    categoryValue:
      data.purchase == null
        ? '--'
        : formatNumberWithCurrency({
            ...DEFAULT_DATA_FOR_FORMAT_NUMBER,
            number: data.purchase,
            currency,
          }),
  },
];

export const getColumnsTable = (currency = {}, startDate, endDate) => [
  {
    id: 1,
    propertyKey: 'storeName',
    type: 'string',
    // 16px of padding + 160px for the width
    minWidth: '160',
    name: (
      <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}>
        {i18next.t('GENERAL.NAME')}
      </Text>
    ),
    isSortable: true,
  },
  {
    id: 2,
    decimals: 0,
    propertyKey: 'turnover',
    type: 'currency',
    filterType: 'numeric',
    tooltipText: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_REVENUE'),
    name: (
      <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}>
        {i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_REVENUE')}
      </Text>
    ),
    tooltipDisplay: i18next.t(tooltipTextsAnalyticsStore.turnover),
    isSortable: true,
    render: (item) => (
      <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
    ),
  },
  {
    id: 3,
    decimals: 0,
    propertyKey: 'orderOverTurnoverRatio',
    type: 'percentage',
    tooltipText: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_PERCENT_ACTUAL_FC'),
    name: (
      <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}>
        {i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_PERCENT_ACTUAL_FC')}
      </Text>
    ),
    filterType: 'numeric',
    tooltipDisplay: i18next.t(tooltipTextsAnalyticsStore.orderOverTurnoverRatio),
    isSortable: true,
    render: (item) => {
      if (item == null) {
        return <Label>-</Label>;
      }

      return (
        <Label>
          <DisplayNumber number={item} /> %
        </Label>
      );
    },
  },
  {
    id: 4,
    decimals: 0,
    propertyKey: 'purchase',
    type: 'currency',
    filterType: 'numeric',
    tooltipText: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_BY_STORE_ACTUAL_FC'),
    name: (
      <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}>
        {i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_ACTUAL_FC')}
      </Text>
    ),
    tooltipDisplay: i18next.t(tooltipTextsAnalyticsStore.order),
    isSortable: true,
    render: (item, row) => {
      const dataForTooltip = formatDataForTooltip(row, currency, startDate, endDate);

      // no need to check on orderTotal, transferTotal or purchase because they're all the sum of the following variables
      const isButtonDisabled =
        !row.order && !row.rushOrder && !row.transferIn && !row.transferOut && !row.stockVariation;

      return (
        <TextWithTooltipButtonContainer>
          <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
          <TooltipButton
            isDisabled={isButtonDisabled}
            tooltipContent={<AnalyticsList data={dataForTooltip} title={row.storeName} />}
          />
        </TextWithTooltipButtonContainer>
      );
    },
  },
  {
    id: 5,
    decimals: 0,
    propertyKey: 'materialRatio',
    type: 'percentage',

    tooltipText: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_PERCENT_THEORETICAL_FC'),
    name: (
      <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}>
        {i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_PERCENT_THEORETICAL_FC')}
      </Text>
    ),
    filterType: 'numeric',
    tooltipDisplay: i18next.t(tooltipTextsAnalyticsStore.materialRatio),
    isSortable: true,
    render: (item) => {
      if (item == null) {
        return <Label>-</Label>;
      }

      return (
        <Label>
          <DisplayNumber number={item} /> %
        </Label>
      );
    },
  },
  {
    id: 6,
    decimals: 0,
    propertyKey: 'theoricalConsumption',
    type: 'currency',
    tooltipText: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_THEORETICAL_FC'),
    name: (
      <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}>
        {i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_THEORETICAL_FC')}
      </Text>
    ),
    filterType: 'numeric',
    tooltipDisplay: i18next.t(tooltipTextsAnalyticsStore.theoricalConsumption, {
      currencyCode: currency.alphabeticCode,
    }),
    isSortable: true,
    render: (item) => (
      <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
    ),
  },
  {
    id: 7,
    decimals: 0,
    propertyKey: 'realFromTheorical',
    type: 'currency',
    tooltipText: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_FC_DEVIATION'),
    name: (
      <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}>
        {i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_FC_DEVIATION')}
      </Text>
    ),
    filterType: 'numeric',
    tooltipDisplay: i18next.t(tooltipTextsAnalyticsStore.realFromTheorical, {
      currencyCode: currency.alphabeticCode,
    }),
    isSortable: true,
    render: (item) => (
      <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
    ),
  },
  {
    id: 10,
    decimals: 0,
    propertyKey: 'inventoryShrinkage',
    type: 'currency',
    tooltipText: i18next.t('ANALYSIS.FOOD_COST.COLUMN_TOOLTIP_INVENTORY_SHRINKAGE'),
    name: (
      <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}>
        {i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_INVENTORY_SHRINKAGE')}
      </Text>
    ),
    filterType: 'numeric',
    tooltipDisplay: i18next.t(tooltipTextsAnalyticsStore.inventoryShrinkage),
    isSortable: true,
    render: (item) => (
      <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
    ),
  },
  {
    name: '',
    propertyKey: 'action',
    isClickable: false,
    isSortable: false,
    isHidden: true,
  },
];

export default getColumnsTable;
