import React from 'react';

import { TextContainer, EmptyContainer, FixedPart } from './styledComponents';

export const TableFormEmptyState = (props) => {
  const { emptyState } = props;

  return (
    <EmptyContainer>
      <FixedPart>
        <TextContainer width={emptyState.width}>{emptyState.text}</TextContainer>
      </FixedPart>
    </EmptyContainer>
  );
};
