import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

import { CHOICES_DROPDOWN_ACTIVE } from '@admin/utils/DropdownItems';

const isDisablingCatalogDisabled = (catalogs) =>
  catalogs.some(({ isMasterCatalog }) => isMasterCatalog);

const isRemovingUserDisabled = (selectedUsers, user) =>
  selectedUsers.some(({ id }) => user.id === id);

// Catalogs ListViewActions
export const getListActions = (
  selectedItems,
  catalogStatus,
  handleExport,
  handleCreation,
  handleStatus,
) => {
  const masterCatalogName = isDisablingCatalogDisabled(selectedItems)
    ? selectedItems.find(({ isMasterCatalog }) => isMasterCatalog).name
    : null;

  return [
    {
      id: 'create',
      actionLabel: i18next.t('GENERAL.CREATE'),
      fixed: true,
      handleAction: () => {},
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={handleCreation}
          icon="/images/inpulse/add-white-small.svg"
          label={i18next.t('GENERAL.CREATE')}
        />
      ),
    },
    {
      id: 'disable-selection',
      actionLabel: i18next.t('GENERAL.DISABLE_SELECTION'),
      isDisabled: () => isDisablingCatalogDisabled(selectedItems),
      disabledTooltipText: () =>
        i18next.t('ADMIN.CATALOGS.DISABLE_ACTION_TOOLTIP', {
          catalog: masterCatalogName,
        }),
      handleAction: () => handleStatus(selectedItems),
      actionIcon: () =>
        isDisablingCatalogDisabled(selectedItems)
          ? '/images/inpulse/power-lmgrey-small.svg'
          : '/images/inpulse/power-black-small.svg',
      isHidden: () =>
        !selectedItems.length || catalogStatus.name !== CHOICES_DROPDOWN_ACTIVE[0].name,
    },
    {
      id: 'enable-selection',
      actionLabel: () => i18next.t('GENERAL.ENABLE_SELECTION'),
      handleAction: () => handleStatus(selectedItems),
      actionIcon: () => '/images/inpulse/power-black-small.svg',
      isHidden: () =>
        !selectedItems.length || catalogStatus.name === CHOICES_DROPDOWN_ACTIVE[0].name,
    },
    {
      id: 'export-selection',
      actionLabel: i18next.t(
        !selectedItems.length ? 'GENERAL.EXPORT_LIST' : 'GENERAL.EXPORT_SELECTION',
      ),
      handleAction: () => handleExport(selectedItems),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
  ];
};

export const getListRowActions = (catalogStatus, handleStatus) => [
  {
    id: 'disable-selection',
    actionLabel: () => i18next.t('GENERAL.DISABLE_SELECTION'),
    isDisabled: (selectedItem) => isDisablingCatalogDisabled([selectedItem]),
    disabledTooltipText: (selectedItem) =>
      i18next.t('ADMIN.CATALOGS.DISABLE_ACTION_TOOLTIP', { catalog: selectedItem.name }),
    handleAction: (selectedItem) => handleStatus([selectedItem]),
    actionIcon: (selectedItem) =>
      isDisablingCatalogDisabled([selectedItem])
        ? '/images/inpulse/power-lmgrey-small.svg'
        : '/images/inpulse/power-black-small.svg',
    isHidden: () => catalogStatus.name !== CHOICES_DROPDOWN_ACTIVE[0].name,
  },
  {
    id: 'enable-selection',
    actionLabel: () => i18next.t('GENERAL.ENABLE_SELECTION'),
    handleAction: (selectedItem) => handleStatus([selectedItem]),
    actionIcon: () => '/images/inpulse/power-black-small.svg',
    isHidden: () => catalogStatus.name === CHOICES_DROPDOWN_ACTIVE[0].name,
  },
];

// User mappings actions
export const getUserMappingsActions = (
  selectedItems,
  handleAddAssociation,
  handleRemoveAssociation,
  user,
) => [
  {
    id: 'add',
    actionLabel: i18next.t('GENERAL.ADD'),
    fixed: true,
    handleAction: () => {},
    render: () => (
      <Button
        color={'inpulse-default'}
        handleClick={handleAddAssociation}
        icon="/images/inpulse/add-white-small.svg"
        label={i18next.t('GENERAL.ADD')}
      />
    ),
  },
  {
    id: 'delete-selection',
    actionLabel: i18next.t('ADMIN.CATALOGS.REMOVE_USERS_ACTIONS', {
      count: selectedItems.length,
    }),
    handleAction: () => handleRemoveAssociation(selectedItems),
    actionIcon: () =>
      isRemovingUserDisabled(selectedItems, user)
        ? '/images/inpulse/trash-lmgrey-small.svg'
        : '/images/inpulse/trash-black-small.svg',
    isHidden: () => !selectedItems.length,
    isDisabled: () => isRemovingUserDisabled(selectedItems, user),
    disabledTooltipText: () => i18next.t('ADMIN.CATALOGS.CANNOT_REMOVE_OWN_CATALOG'),
  },
];

export const getUserMappingsRowActions = (handleRemoveAssociation, user) => [
  {
    id: 'delete-selection',
    actionLabel: () => i18next.t('ADMIN.CATALOGS.REMOVE_USERS_ACTIONS', { count: 1 }),
    handleAction: (selectedItem) => handleRemoveAssociation([selectedItem]),
    actionIcon: (selectedItem) =>
      isRemovingUserDisabled([selectedItem], user)
        ? '/images/inpulse/trash-lmgrey-small.svg'
        : '/images/inpulse/trash-black-small.svg',
    isDisabled: (selectedItem) => isRemovingUserDisabled([selectedItem], user),
    disabledTooltipText: () => i18next.t('ADMIN.CATALOGS.CANNOT_REMOVE_OWN_CATALOG'),
  },
];
