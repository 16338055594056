import _ from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import useLocalStorage from '@hooks/useLocalStorage';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { planningOperation as planningOperationService } from '@services/planningOperation';

const TITLE_MODAL_SYNCHRONISATION =
  'PRODUCTION.PRODUCTION.OFFLINE_REQUEST_SYNCHRONISATION_IN_PROGRESS';

export const OfflineSynchronisationModal = (props) => {
  const {
    params: {
      refreshPage,
      showMessage,
      confirmationMessageReducer,
      setIsSynchronisationInProgress,
    },
    closeModal,
  } = props;

  const [progress, setProgress] = useState(0);
  const [requestsToMake, setRequestsToMake] = useState(0);
  const [countRequestMade, setCountRequestMade] = useState(0);

  const [productionRequestsToPlay, setProductionRequestsToPlay] = useLocalStorage(
    'productionRequestsToPlay',
    [],
  );

  const postProductionOperation = async (payload) => {
    const { storeId, productId, timestamp, tot, type, price } = payload;

    if (!storeId || !productId || !timestamp || !tot || !type || !price) {
      return false;
    }

    try {
      await planningOperationService.postOperation(
        storeId,
        productId,
        timestamp,
        tot,
        type,
        price,
        true,
      );

      return true;
    } catch (err) {
      return false;
    }
  };

  const handleRequestInPending = async (payload) => {
    const successful = await postProductionOperation(payload);

    if (successful) {
      setProductionRequestsToPlay(
        productionRequestsToPlay.filter((item) => !_.isEqual(item, payload)),
      );
    } else {
      return closeModal();
    }

    if (productionRequestsToPlay.length === 1 && successful) {
      showMessage(
        i18next.t('PRODUCTION.PRODUCTION.OFFLINE_REQUEST_SYNCHRONIZED'),
        'success',
        confirmationMessageReducer.messageBool,
      );

      await refreshPage();
    }

    return successful;
  };

  useEffect(() => {
    setRequestsToMake(productionRequestsToPlay.length);
  }, []);

  useEffect(() => {
    if (countRequestMade % 5 !== 0) {
      return;
    }

    const updatedProgress = Math.round((countRequestMade * 100) / requestsToMake);

    setProgress(updatedProgress);
  }, [countRequestMade]);

  useEffect(() => {
    if (!productionRequestsToPlay.length) {
      setIsSynchronisationInProgress(false);

      setProgress(100);

      setTimeout(() => {
        closeModal();
      }, 1000);

      return;
    }

    (async function handleRequest() {
      const payload = _.first(productionRequestsToPlay);

      await handleRequestInPending(payload);

      setCountRequestMade(countRequestMade + 2);
    })();
  }, [productionRequestsToPlay]);

  return (
    <ExportModalContent
      {...props}
      progress={progress}
      titleModal={i18next.t(TITLE_MODAL_SYNCHRONISATION)}
      noFooter
    />
  );
};

export default OfflineSynchronisationModal;
