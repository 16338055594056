/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

export const ItemRow = styled.div`
  display: flex;
  border-bottom: 1px solid #e2e2e2;

  height: 32px;

  align-items: center;

  color: ${(props) => props.theme.colors?.greys.darker};
  font: ${(props) => props.theme.fonts?.textSmallHeight14};
`;

export const Item = styled.div`
  flex: ${(props) => (props.isLoading ? 0 : props.narrow ? 1 : 3)};
`;

export const Icon = styled.div`
  width: 16px;
  height: 16px;

  background: url(${(props) => props.icon}) no-repeat;

  &:hover {
    background: url(${(props) => props.iconHover}) no-repeat;
    cursor: pointer;
  }
`;

export const Text = styled.div`
  max-width: 96px;
  height: 32px;

  padding-top: 8px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${(props) => {
    if (props.bold) {
      return css`
        font: ${props.theme.fonts?.textSmallHeight14Weight700Bold};
      `;
    }

    return css``;
  }};

  ${(props) => {
    if (props.black) {
      return css`
        color: ${props.theme.colors?.greys.darkest};
      `;
    }

    if (props.red) {
      return css`
        color: ${props.theme.colors?.infoRed};
      `;
    }

    if (props.green) {
      return css`
        color: ${props.theme.colors?.infoGreen};
      `;
    }

    return css``;
  }};
`;
