import { get, isEmpty } from 'lodash';
import i18next from 'i18next';

const _getTurnoverName = (useTtc, isForecastTurnover) => {
  if (!isForecastTurnover) {
    return i18next.t('FORECAST.TURNOVER.METRIC_NAME_HAMBURGERS');
  }

  if (useTtc) {
    return i18next.t('GENERAL.TURNOVER_TAX_INCLUDED');
  }

  return i18next.t('GENERAL.TURNOVER_EX_TAX');
};

const _getForecastProperty = (useTtc, isForecastTurnover) => {
  if (!isForecastTurnover) {
    return 'tot';
  }

  if (useTtc) {
    return 'ca';
  }

  return 'turnoverExcludingTaxes';
};

export const getClientInfo = (user = {}) => {
  const { lnkClientAccountrel: client } = user;

  if (isEmpty(client)) {
    return {};
  }

  // not pretty but it's temporary OK
  const isForecastTurnover = client.id !== 'd5d09aa6-a62d-11e8-ba89-0af6991d48ca';

  return {
    clientId: client.id,
    storeName: client.storeName,
    cryptrDomain: client.cryptrDomain,
    hasOffline: get(client, 'hasOffline', false),
    clientStatus: get(client, 'status', ''),
    clientName: get(client, 'name', 'unknown'),
    clientPicture: get(client, 'img', '/images/logo-inpulse.svg'),
    hasMultipleBrands: get(client, 'hasMultipleBrands', false),
    lastSync: client.lastSync,
    hasMultipleChannels: get(client, 'hasMultipleChannels', false),
    areProductsSync: get(client, 'areProductsSync', false),
    hasMultipleServices: get(client, 'hasMultipleServices', false),
    defaultChannelId: client.defaultChannelId,
    currencyId: client.currencyId,
    hasMultipleTimezones: get(client, 'hasMultipleTimezones', false),
    hasMultipleCurrencies: get(client, 'hasMultipleCurrencies', false),
    hasCentralKitchens: get(client, 'hasCentralKitchens', false),
    hasLocalCatalogs: get(client, 'hasLocalCatalogs', false),
    defaultTimezone: get(client, 'defaultTimezone', null),
    creationDate: get(client, 'creationDate', null),
    // Forecast properties
    isForecastTurnover,
    useTtc: client.useTtc,
    forecastType: isForecastTurnover ? 'ca' : 'tot',
    turnoverName: _getTurnoverName(client.useTtc, isForecastTurnover),
    forecastProperty: _getForecastProperty(client.useTtc, isForecastTurnover),
  };
};
