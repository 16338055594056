import { connect } from 'react-redux';
import { isEmpty, sortBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import { getClientInfo } from '@selectors/client';

import { Button, Dropdown } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getPreviousDayOfStore } from '@commons/utils/date';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import ExportModalContent from '@lib/inpulse/ExportModal';

import storeService from '@services/store';

import {
  Container,
  Header,
  Content,
  Footer,
  DropdownContainer,
  LabelRequiredCharacter,
  PeriodDatePickerContainer,
  LabelContainer,
} from './styledComponents';
import exportUtils from './exportUtils';

export const ExportModal = (props) => {
  const {
    client: { defaultTimezone },
    closeModal,
    params: { stores, currency, storeName },
  } = props;

  // Stores selection step
  const [selectedStores, setSelectedStores] = useState(stores);
  const [isSelectingStores, setIsSelectingStores] = useState(true);

  // Data retrieving step
  const [isLoading, setLoading] = useState(true);
  const [storesTransactions, setStoresTransactions] = useState([]);
  const [countStoresFetched, setCountStoresFetched] = useState(0);

  // Dates states
  const [maxFutureDate, setMaxFutureDate] = useState(null);
  const periodPickerState = usePeriodDatePickerState(null, null, defaultTimezone);
  const [isSelectingDates, setIsSelectingDates] = useState(true);

  // Progress modal
  const [progress, setProgress] = useState(0);
  const [title, setTitle] = useState(i18next.t('ADMIN.TRANSACTIONS.EXPORT_IN_PROGRESS'));

  const updateProgress = () => {
    const updatedProgress = (countStoresFetched / selectedStores.length) * 100;

    setProgress(updatedProgress);
  };

  const exitModal = () => {
    setLoading(false);

    closeModal();
  };

  const exportReady = () => {
    setTitle(i18next.t('ADMIN.TRANSACTIONS.EXPORT_FINISHED'));
    setLoading(false);
  };

  const exportFailure = () => {
    setTitle(i18next.t('GENERAL.EXPORT_FAILURE'));
    setLoading(false);
  };

  const tiggerExport = () => {
    if (!selectedStores.length || !periodPickerState.startDate || !periodPickerState.endDate) {
      return;
    }

    setIsSelectingStores(false);
    setIsSelectingDates(false);
  };

  const loadStoresTransactions = async () => {
    const updatedStoresTransactions = [...storesTransactions];

    // Perform request
    try {
      const transactions = await storeService.getStoreTransactions(
        selectedStores[countStoresFetched].id,
        moment(periodPickerState.startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        moment(periodPickerState.endDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );

      const formattedTransactions = transactions.map((transaction) => ({
        ...transaction,
        storeName: selectedStores[countStoresFetched].name,
      }));

      updatedStoresTransactions.push(...sortBy(formattedTransactions, 'date'));

      setStoresTransactions(updatedStoresTransactions);

      setCountStoresFetched(countStoresFetched + 1);
    } catch {
      setProgress(100);

      exportFailure();
    }
  };

  // Update datepicker restriction when store changes
  useEffect(() => {
    if (isEmpty(selectedStores)) {
      return;
    }

    const previousDayOfStore = getPreviousDayOfStore(selectedStores[0]);

    setMaxFutureDate(previousDayOfStore);

    periodPickerState.setFocusedDateInput(previousDayOfStore);
  }, [selectedStores]);

  useEffect(() => {
    if (isSelectingStores || isSelectingDates) {
      return;
    }

    if (countStoresFetched === selectedStores.length) {
      setProgress(100);

      exportUtils.exportTransactions(
        storesTransactions,
        selectedStores,
        periodPickerState.startDate,
        periodPickerState.endDate,
        storeName,
        currency,
      );

      return exportReady();
    }

    updateProgress();

    loadStoresTransactions();
  }, [isSelectingStores, isSelectingDates, countStoresFetched]);

  return (
    <div className={props.className}>
      {isSelectingStores && isSelectingDates && (
        <Container>
          <Header>
            <img height={16} src={'/images/inpulse/file-download-black-small.svg'} width={16} />
            <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.H1}>
              {i18next.t('ADMIN.TRANSACTIONS.EXPORT_MODAL_TITLE')}
            </Text>
            <img
              height={16}
              src={'/images/inpulse/close-black-small.svg'}
              width={16}
              onClick={closeModal}
            />
          </Header>
          <Content>
            <DropdownContainer>
              <Dropdown
                customListStyle={{
                  position: 'fixed',
                  top: 'inherit',
                  left: 'inherit',
                }}
                iconSrc={
                  selectedStores.length
                    ? '/images/inpulse/pin-black-small.svg'
                    : '/images/inpulse/pin-dmgrey-small.svg'
                }
                isUniqueSelection={false}
                items={stores}
                label={`${getClientStoreNameTranslation(storeName, true)} :`}
                selectedItems={selectedStores}
                isRequired
                onSelectionChange={setSelectedStores}
              />
            </DropdownContainer>
            <PeriodDatePickerContainer>
              <LabelContainer>
                <LabelRequiredCharacter>*</LabelRequiredCharacter>
                <Text
                  color={ENUM_COLORS.DARKEST}
                  font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {`${i18next.t('GENERAL.EXPORT_PERIOD')} :`}
                </Text>
              </LabelContainer>
              <PeriodDatePicker
                customStyle={{ position: 'absolute', marginTop: '28px' }} // for the list to appear over the modal (instead of inside)
                disabled={false}
                endDate={periodPickerState.endDate}
                focusedDateInput={periodPickerState.focusedDateInput}
                maxFutureDate={maxFutureDate}
                setFocusedDateInput={periodPickerState.setFocusedDateInput}
                startDate={periodPickerState.startDate}
                timezone={defaultTimezone}
                onDatesChange={periodPickerState.handleSelectedDates}
              />
            </PeriodDatePickerContainer>
          </Content>
          <Footer>
            <Button
              color={'inpulse-outline'}
              handleClick={exitModal}
              icon={'/images/inpulse/close-black-small.svg'}
              label={i18next.t('GENERAL.CANCEL')}
            />
            <Button
              color={'inpulse-default'}
              handleClick={tiggerExport}
              icon={'/images/inpulse/file-download-white-small.svg'}
              isDisabled={!selectedStores.length}
              label={i18next.t('GENERAL.EXPORT')}
            />
          </Footer>
        </Container>
      )}

      {!isSelectingStores && !isSelectingDates && (
        <ExportModalContent
          {...props}
          closeModal={closeModal}
          exitModal={exitModal}
          isLoading={isLoading}
          progress={progress}
          setLoading={setLoading}
          titleModal={title}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

export default connect(mapStateToProps)(ExportModal);
