import i18next from 'i18next';
import React, { Component } from 'react';
import styled from 'styled-components';

import SearchIcon from '@assets/icons/loupe.svg';

export default class BarcodeSearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
      hiddenSearchValue: '',
      lastPressKeyTime: Date.now(),
    };
  }

  componentDidMount = () => {
    document.body.addEventListener('keypress', this.handleKeyPress);
  };

  componentWillUnmount = () => {
    document.body.removeEventListener('keypress', this.handleKeyPress);
  };

  onBlur = () => {
    this.setState({ focus: false });
  };

  onFocus = () => {
    this.setState({ focus: true });
  };

  handleKeyDown = (e) => {
    if (e.key !== 'Enter') return;

    if (this.state.focus) {
      this.props.hasScanned(this.state.searchValue);
    } else if (this.isCharacterComingFromScanner()) {
      this.setState({ searchValue: this.state.hiddenSearchValue });
      this.props.hasScanned(this.state.hiddenSearchValue);
    }
  };

  handleKeyPress = (event) => {
    if (this.state.focus) return;

    const character = String.fromCharCode(event.which);

    let lastCharacters = this.state.hiddenSearchValue;
    if (!this.isCharacterComingFromScanner()) {
      lastCharacters = '';
    }

    if (event.key === 'Enter') {
      return this.handleKeyDown(event);
    }

    this.setState({
      hiddenSearchValue: (lastCharacters += character),
      lastPressKeyTime: Date.now(),
    });
  };

  resetInputs = () => {
    this.props.hasScanned('');
    this.setState({ searchValue: '', hiddenSearchValue: '' });
  };

  isCharacterComingFromScanner() {
    return Date.now() - this.state.lastPressKeyTime < 200;
  }

  render = () => (
    <div className={this.props.className}>
      <img src={SearchIcon} width={10}></img>
      <input
        className="tablet-barcode-search-bar-input"
        placeholder={i18next.t('PRODUCTION.PRODUCTION.SEARCHBAR_EMPTY_STATE')}
        type="text"
        value={this.state.searchValue}
        onBlur={this.onBlur}
        onChange={(e) => this.setState({ searchValue: e.target.value })}
        onFocus={this.onFocus}
        onKeyDown={(e) => this.handleKeyDown(e)}
      />
      {this.state.searchValue && (
        <img
          className="input-delete-icon"
          src="/images/icon-close-sprite.png"
          width={10}
          onClick={this.resetInputs}
        />
      )}
    </div>
  );
}

const InpulseSearchBar = styled(BarcodeSearchBar)`
  position: relative;
  margin: 0;
  height: 40px;
  width: 240px;
  font: ${(props) => props.theme.fonts?.textSmall};
  color: ${(props) => props.theme.colors.greys.darkest};
  background: ${(props) => props.theme.colors.greys.lightest};
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.borders?.radius?.weak};
  border: ${(props) => `1px solid ${props.theme.colors.greys.lightMedium}`};
  padding-left: 24px;

  .planning-edit-header-barcode-search-bar {
    height: 100%;
  }

  /* .tablet-barcode-search-bar .text-input-form-input {
    padding-left: 24px;
    padding-right: 24px;
  } */

  .tablet-barcode-search-bar .input-icon {
    position: absolute;
    bottom: 12px;
    left: 16px;
    width: 16px;
  }

  .tablet-barcode-search-bar .input-delete-icon {
    position: absolute;
    bottom: 12px;
    right: 16px;
    width: 16px;
    cursor: pointer;
  }

  .tablet-barcode-search-bar-input {
    padding-left: 8px;
    border: none;
    height: 100%;
    width: calc(100% - 40px);
  }
`;

const DeepsightSearchBar = styled(BarcodeSearchBar)`
  position: relative;
  margin: 0;
  height: 40px;
  width: 240px;
  border: double 1px transparent;
  border-radius: 4px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, rgb(10, 62, 255), rgb(10, 242, 250));
  background-origin: border-box;
  background-clip: content-box, border-box;

  .planning-edit-header-barcode-search-bar {
    padding-left: 2em;
    height: 100%;
  }

  .text-input-form-input {
    padding-left: 2em;
    height: 100%;
    padding-left: 35px;
    padding-right: 30px;
    border: 1px solid #f6f6f6;
  }

  .input-icon {
    position: absolute;
    bottom: 17px;
    left: 13px;
    width: 14px;
  }

  .input-delete-icon {
    position: absolute;
    bottom: 17px;
    right: 13px;
    width: 14px;
    cursor: pointer;
  }

  .tablet-barcode-search-bar-input {
    padding-left: 2em;
    height: 100%;
    width: 100%;
  }
`;

export { InpulseSearchBar, DeepsightSearchBar };
