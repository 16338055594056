import styled from 'styled-components';

export const EmptyStateIcon = styled.img`
  width: 160px;
  height: 160px;
`;

export const Text = styled.div`
  font: ${(props) => (props.isTitle ? props.theme.fonts.h2 : props.theme.fonts.textSmallHeight16)};
  color: ${(props) =>
    props.isTitle ? props.theme.colors.greys.darkest : props.theme.colors.greys.darker};
  margin: 8px 0px 16px 0px;

  ${(props) => ({ ...props.customStyle })}
`;

export const Container = styled.div`
  width: 100%;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0px;

  z-index: 1;
`;
