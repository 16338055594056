export const loadingSuccess = () => ({
  type: 'LOADING_SUCCESS',
});

export const loadingFailure = () => ({
  type: 'LOADING_FAILURE',
});

export const loading = () => (dispatch) =>
  dispatch({
    type: 'LOADING_REQUEST',
  });
