import React from 'react';

import { getTheme } from '@commons/utils/theme';
import { Label } from '@commons/utils/styledLibraryComponents';

import { DisplayNameHovered } from '../../../../styledComponents';

import { FormattedInfoDisplay, LabelContainer } from '../../styledComponents';

const ProductOrderPriceQuantityColumns = (props) => {
  const { nbColumns, displayBigger, selectDisplayBigger, maxCharBeforeShowingBiggerName, product } =
    props;

  const theme = getTheme();

  return (
    <>
      {/* Invoiced quantity */}
      <FormattedInfoDisplay
        isBold={product.displayRules.boldInvoicedQuantity}
        nbColumns={nbColumns}
        onClick={() => selectDisplayBigger('formattedInvoicedQuantity')}
        onMouseEnter={() => selectDisplayBigger('formattedInvoicedQuantity')}
        onMouseLeave={() => selectDisplayBigger('formattedInvoicedQuantity')}
      >
        {product.formattedInvoicedQuantity.length > maxCharBeforeShowingBiggerName
          ? product.formattedInvoicedQuantity.substring(0, maxCharBeforeShowingBiggerName - 2) +
            ' ...'
          : product.formattedInvoicedQuantity}
        {displayBigger.formattedInvoicedQuantity === true &&
          product.formattedInvoicedQuantity.length > maxCharBeforeShowingBiggerName && (
            <DisplayNameHovered
              isBold={product.displayRules.boldInvoicedQuantity}
              nbColumns={nbColumns}
              onClick={(e) => e.stopPropagation()}
            >
              {product.formattedInvoicedQuantity}
            </DisplayNameHovered>
          )}
      </FormattedInfoDisplay>

      {/* Received quantity */}
      <FormattedInfoDisplay
        isBold={product.displayRules.boldReceivedQuantity}
        nbColumns={nbColumns}
        onClick={() => selectDisplayBigger('formattedReceivedQuantity')}
        onMouseEnter={() => selectDisplayBigger('formattedReceivedQuantity')}
        onMouseLeave={() => selectDisplayBigger('formattedReceivedQuantity')}
      >
        {product.formattedReceivedQuantity.length > maxCharBeforeShowingBiggerName
          ? product.formattedReceivedQuantity.substring(0, maxCharBeforeShowingBiggerName - 2) +
            ' ...'
          : product.formattedReceivedQuantity}
        {displayBigger.formattedReceivedQuantity === true &&
          product.formattedReceivedQuantity.length > maxCharBeforeShowingBiggerName && (
            <DisplayNameHovered
              isBold={product.displayRules.boldReceivedQuantity}
              nbColumns={nbColumns}
              onClick={(e) => e.stopPropagation()}
            >
              {product.formattedReceivedQuantity}
            </DisplayNameHovered>
          )}
      </FormattedInfoDisplay>

      {/* Invoiced price */}
      <FormattedInfoDisplay
        isBold={product.displayRules.boldInvoicedUnitPrice}
        nbColumns={nbColumns}
        onClick={() => selectDisplayBigger('formattedInvoicedUnit')}
        onMouseEnter={() => selectDisplayBigger('formattedInvoicedUnit')}
        onMouseLeave={() => selectDisplayBigger('formattedInvoicedUnit')}
      >
        <div>
          {product.formattedInvoicedUnit.length > maxCharBeforeShowingBiggerName
            ? product.formattedInvoicedUnit.substring(0, maxCharBeforeShowingBiggerName - 2) +
              ' ...'
            : product.formattedInvoicedUnit}
        </div>
        {displayBigger.formattedInvoicedUnit === true &&
          product.formattedInvoicedUnit.length > maxCharBeforeShowingBiggerName && (
            <DisplayNameHovered
              isBold={product.displayRules.boldInvoicedUnitPrice}
              nbColumns={nbColumns}
              onClick={(e) => e.stopPropagation()}
            >
              {product.formattedInvoicedUnit}
            </DisplayNameHovered>
          )}
      </FormattedInfoDisplay>

      {/* Invoiced total price */}
      <FormattedInfoDisplay
        isBold={product.displayRules.boldInvoicedTotalPrice}
        nbColumns={nbColumns}
      >
        {product.formattedInvoicedTotal}
      </FormattedInfoDisplay>

      {/* Received total price */}
      <FormattedInfoDisplay
        isBold={product.displayRules.boldReceivedTotalPrice}
        nbColumns={nbColumns}
      >
        {product.formattedReceivedTotal}
      </FormattedInfoDisplay>

      {/* Balance Invoiced / Received */}
      <FormattedInfoDisplay nbColumns={nbColumns}>
        <LabelContainer>
          <Label
            background={
              product.productOrderDiff === 0 ? theme.colors.infoGreen : theme.colors.infoRed
            }
            color={theme.colors.greys.lightest}
          >
            {product.formattedDiff}
          </Label>
        </LabelContainer>
      </FormattedInfoDisplay>
    </>
  );
};

export default ProductOrderPriceQuantityColumns;
