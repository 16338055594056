import i18next from 'i18next';
import React from 'react';

// Design
import {
  Container,
  ContentContainer,
  FooterContainer,
  HeaderContainer,
  Subtitle,
  Title,
} from './styledComponents';

// Common
import { Button } from '@commons/utils/styledLibraryComponents';
import History from '@commons/HistoryDrawer/components/History/History';

/**
 * @param {{ subtitle: string; onClose: () => void; changelogs: ChangelogProp[] }} props
 *
 * @return {JSX.Element}
 */
const HistoryDrawerContent = (props) => {
  const { subtitle, auditLogs, onClose } = props;

  return (
    <Container>
      <HeaderContainer>
        <Title>{i18next.t('GENERAL.CHANGE_HISTORY')}</Title>

        <Subtitle>{subtitle}</Subtitle>
      </HeaderContainer>

      <ContentContainer>
        <History auditLogs={auditLogs} />
      </ContentContainer>

      <FooterContainer>
        <Button
          buttonCustomStyle={{ justifyContent: 'center' }}
          color={'inpulse-outline'}
          handleClick={onClose}
          icon={'/images/inpulse/close-black-small.svg'}
          label={i18next.t('GENERAL.CLOSE')}
          minWidth={168}
        />
      </FooterContainer>
    </Container>
  );
};

export default HistoryDrawerContent;
