import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import utilsXLS from '@commons/utils/makeXLS';

import { getEntityUnit } from '@src/routes/stocks/utils';

const getProductionPlanningCommonsData = (
  productionStartDate,
  productionEndDate,
  consumptionComputation,
  userLanguageCode,
) => {
  const columns = [
    {
      name: i18next.t('GENERAL.NAME'),
      propertyKey: 'name',
    },
    {
      name: i18next.t('GENERAL.CATEGORY'),
      propertyKey: 'category',
    },
  ];

  if (!!consumptionComputation) {
    columns.push({
      name: i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_CONSUMPTION_CONSUMPTIONS'),
      propertyKey: 'totalConsumption',
      type: 'currency',
    });
  }

  const dateDisplayFormat =
    userLanguageCode === 'en'
      ? DATE_DISPLAY_FORMATS.SLASHED_MONTH_DAY_YEAR
      : DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR;

  for (
    let currentDate = moment(productionStartDate).clone();
    currentDate.isSameOrBefore(moment(productionEndDate));
    currentDate.add(1, 'day')
  ) {
    columns.push({
      name: currentDate.format(dateDisplayFormat),
      propertyKey: currentDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      type: 'currency',
    });
  }

  columns.push(
    {
      name: i18next.t('GENERAL.TOTAL'),
      propertyKey: 'totalProduction',
      type: 'currency',
    },
    {
      name: i18next.t('GENERAL.UNIT'),
      propertyKey: 'unit',
      transform: (entityUnit) => getEntityUnit(entityUnit, false),
    },
  );

  return columns;
};

const getRecipeSheetColums = (
  productionStartDate,
  productionEndDate,
  showYesterdayStock,
  consumptionComputation,
  userLanguageCode,
) => {
  const columns = getProductionPlanningCommonsData(
    productionStartDate,
    productionEndDate,
    consumptionComputation,
    userLanguageCode,
  );

  if (showYesterdayStock) {
    columns.splice(2, 0, {
      name: i18next.t('ORDERS.ORDERS.FORM_YESTERDAY_STOCK_COLUMN_TITLE'),
      propertyKey: 'yesterdayStock',
      type: 'currency',
    });
  }

  return columns;
};

const getInformationsSheetColumns = () => [
  {
    name: i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_PRODUCTION_HUB'),
    propertyKey: 'selectedCentralKitchen.name',
  },
  {
    name: i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_PRODUCTION_EXPORT_PRODUCTION_START_DATE'),
    propertyKey: 'productionStartDate',
  },
  {
    name: i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_PRODUCTION_EXPORT_PRODUCTION_END_DATE'),
    propertyKey: 'productionEndDate',
  },
  {
    name: i18next.t('GENERAL.STORE_NAME_LABEL_ESTABLISHMENT_PLURAL'),
    propertyKey: 'selectedStoresName',
  },
  {
    name: i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_PRODUCTION_EXPORT_CONSUMPTION_START_DATE'),
    propertyKey: 'consumptionStartDate',
  },
  {
    name: i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_PRODUCTION_EXPORT_CONSUMPTION_END_DATE'),
    propertyKey: 'consumptionEndDate',
  },
];

export const exportProductionPlanning = (
  recipes,
  preparations,
  ingredients,
  metaData,
  currency,
) => {
  if (!recipes) {
    return;
  }

  const { selectedCentralKitchen, productionStartDate, productionEndDate, userLanguageCode } =
    metaData;

  const consumptionComputation = selectedCentralKitchen.consumptionComputation;

  const recipeSheet = utilsXLS.generateDefaultSheet(
    i18next.t('GENERAL.RECIPE_PLURAL'),
    getRecipeSheetColums(
      productionStartDate,
      productionEndDate,
      selectedCentralKitchen.showYesterdayStock,
      consumptionComputation,
      userLanguageCode,
    ),
    recipes,
    currency,
  );

  const preparationSheet = utilsXLS.generateDefaultSheet(
    i18next.t('PRODUCTION.CENTRAL_KITCHEN.PREPARATIONS'),
    getProductionPlanningCommonsData(
      productionStartDate,
      productionEndDate,
      consumptionComputation,
      userLanguageCode,
    ),
    preparations,
    currency,
  );

  const ingredientSheet = utilsXLS.generateDefaultSheet(
    i18next.t('GENERAL.INGREDIENT_PLURAL'),
    getProductionPlanningCommonsData(
      productionStartDate,
      productionEndDate,
      consumptionComputation,
      userLanguageCode,
    ),
    ingredients,
    currency,
  );

  const informationSheet = utilsXLS.generateDefaultSheet(
    i18next.t('GENERAL.INFORMATIONS'),
    getInformationsSheetColumns(),
    [metaData],
    currency,
  );

  utilsXLS.makeXLS(i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_PRODUCTION_EXPORT_FILENAME'), [
    recipeSheet,
    preparationSheet,
    ingredientSheet,
    informationSheet,
  ]);
};
