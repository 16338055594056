import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 280px;
  display: flex;
  align-items: center;
  &:last-child {
    margin-right: 0px;
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: lightgrey !important;
      .DateInput_input__disabled {
        color: #6b768c;
        font-style: normal;
        &:hover {
          color: #6b768c !important;
        }
      }
    `}
  ${(props) =>
    props.ranges &&
    css`
      margin-right: 10px;
      min-width: 200px;
    `}
  ${(props) =>
    props.date &&
    css`
      min-width: 200px;
      max-width: 250px;
      height: 36px;
      &:hover {
        cursor: pointer;
      }
      border-radius: 4px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      > div {
        display: flex;
        align-items: center;
        width: calc(100% - 40px);
        > div {
          width: 100%;
          > div {
            width: 100%;
            display: flex;
            .DateInput_1 {
              flex: 1;
            }
          }
        }
      }
      color: #6b768c;
    `}
  .deepsight-button.locked {
    background-color: lightgray;
  }
  .DateRangePicker_picker__directionLeft {
    background: transparent;
    top: ${(props) => (props.modal ? '70px !important' : '45px !important')};
    left: ${(props) => (props.modal ? '0px !important' : '-30px !important')};
  }
`;

export const Date = styled.div``;

export default {
  Container,
  Date,
};
