import { get } from 'lodash';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

import { PROP_KEYS } from './sheets';

const extractByStoreData = (store, groupsByStoreId, { productsByCategory }) => {
  const storeGroups = groupsByStoreId[store.id] || [];

  const formattedStoreGroups = storeGroups.join(', ');

  const storeData = [];

  Object.entries(productsByCategory).forEach(([category, { nestedData, stats }]) => {
    nestedData.forEach((productData) => {
      storeData.push({
        [PROP_KEYS.STORE_NAME]: store.name,
        [PROP_KEYS.GROUPS]: formattedStoreGroups,
        [PROP_KEYS.CATEGORY]: category,
        [PROP_KEYS.PRODUCT_NAME]: productData.name,
        [PROP_KEYS.SOLD_QUANTITY]: get(productData, 'stats.unit', null),
        [PROP_KEYS.AVERAGE_PRICE]: get(productData, 'stats.priceExcludingTaxes', null),
        [PROP_KEYS.TURNOVER]: get(productData, 'stats.turnoverExcludingTaxes', null),
        [PROP_KEYS.CATEGORY_RATIO]: stats.mixExcludingTaxes || null,
        [PROP_KEYS.MIX_RATIO]: get(productData, 'stats.mixExcludingTaxes', null),
        [PROP_KEYS.MARGIN_RATIO]: get(productData, 'stats.marginRatio', null),
        [PROP_KEYS.MARGIN]: get(productData, 'stats.margin', null),
      });
    });
  });

  return storeData;
};

const extractContextData = (store, context, { turnoverExcludingTaxes, margin }) => {
  const startDate = moment(context.startDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);
  const endDate = moment(context.endDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);

  return {
    [PROP_KEYS.STORE_NAME]: store.name,
    [PROP_KEYS.START_DATE]: startDate,
    [PROP_KEYS.END_DATE]: endDate,
    [PROP_KEYS.TURNOVER]: turnoverExcludingTaxes,
    [PROP_KEYS.MARGIN]: margin.total,
    [PROP_KEYS.MARGIN_RATIO]: margin.ratioToTurnover,
  };
};

export default {
  extractByStoreData,
  extractContextData,
};
