import styled from 'styled-components';

export const ModalContainer = styled.div`
  margin-top: 45px;
  bottom: 512px;
`;

export const Dot = styled.div`
  z-index: 16;
  width: 7px;
  height: 7px;
  border-radius: 50px;
  background: ${(props) => props.theme.colors?.infoRed};
  position: absolute;
  left: 27px;
  top: 4px;
`;

export const Day = styled.div`
  margin: 16px;
  top: -82px;
  z-index: 15;
  width: 40px;
  height: 40px;
  left: 6px;
  border: 1px solid #e2e2e2;
  background: ${(props) => props.theme.colors?.greys.lighter};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors?.greys.dark};
  font: ${(props) => props.theme.fonts?.textSmall} !important;
`;

export const Text = styled.div`
  margin-top: 6px;
  margin-left: 38px;
`;

export const ModalBody = styled.div`
  width: 320px;
  top: -82px;
  position: absolute;
  display: inline-block;
  margin-top: 0px;
  padding: ${(props) => (props.theme ? '10px 30px 10px 40px' : '17px 30px 17px 40px')};
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  font-family: proximanovaregular;
  font: ${(props) => props.theme.fonts?.textSmall} !important;
  color: ${(props) => props.theme.colors?.greys.darkest};
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  z-index: 10;
`;
