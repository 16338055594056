import { first } from 'lodash';
import i18next from 'i18next';
import Proptypes from 'prop-types';
import React, { useRef } from 'react';

import {
  Container,
  DownloadErrorFile,
  DownloadFileIcon,
  FileName,
  FileSelectionIcon,
  FileSelectorWrapper,
  InputFile,
  InvalidLineRender,
  InvalidLineRenderHelp,
  RemoveFileSelected,
  SubtitleText,
} from './styledComponents';

import { IMPORT_STATE } from './utils/constant';

const ImportFileModal = (props) => {
  const { modalSubTitle, selectedFile, setSelectedFile, currentStep, exportErrorFile } = props;

  const inputFileRef = useRef(null);

  const onFileChange = (e) => {
    const file = first(e.target.files);

    if (!file) {
      return;
    }

    setSelectedFile(file);
  };

  const onFileDelete = () => {
    setSelectedFile(null);
  };

  const triggerInputClick = () => {
    inputFileRef.current.click();
  };

  const RenderFileSelected = (props) => {
    const { hasNoMargin } = props;

    return (
      <FileSelectorWrapper noMargin={hasNoMargin}>
        <FileName>
          {selectedFile.name} ({Math.round(selectedFile.size / 1000)} Ko)
        </FileName>
        {currentStep === 'select-file' && (
          <RemoveFileSelected
            alt="delete-file-icon"
            src={'/images/inpulse/close-dgrey-small.svg'}
            onClick={onFileDelete}
          />
        )}
      </FileSelectorWrapper>
    );
  };

  const RenderFileSelection = () => (
    <>
      <InputFile
        accept=".xlsx"
        id="file-data-model"
        ref={inputFileRef}
        type="file"
        onChange={onFileChange}
      />
      <FileSelectorWrapper interactive onClick={triggerInputClick}>
        <FileSelectionIcon alt="add file" src={'/images/inpulse/add-black-small.svg'} />
        {i18next.t('GENERAL.SELECT_FILE')}
      </FileSelectorWrapper>
    </>
  );

  const RenderFileValidationError = () => (
    <>
      <RenderFileSelected hasNoMargin={true} />

      <InvalidLineRender>{modalSubTitle}</InvalidLineRender>

      <InvalidLineRenderHelp>
        {i18next.t('GENERAL.IMPORT_FILE_ERROR_SUBTITLE_MODAL')}
      </InvalidLineRenderHelp>

      <DownloadErrorFile onClick={() => exportErrorFile()}>
        <DownloadFileIcon alt="download-icon" src="/images/inpulse/file-download-black-small.svg" />
        {i18next.t('GENERAL.IMPORT_FILE_DOWNLOAD_ERROR')}
      </DownloadErrorFile>
    </>
  );

  const RenderFileValidated = () => (
    <FileSelectorWrapper noMargin>
      <FileName>
        {selectedFile.name} ({Math.round(selectedFile.size / 1000)} Ko)
      </FileName>
    </FileSelectorWrapper>
  );

  return (
    <Container>
      {currentStep === IMPORT_STATE.SELECT_FILE && (
        <>
          {!!modalSubTitle && <SubtitleText>{modalSubTitle}</SubtitleText>}
          {!selectedFile && <RenderFileSelection />}
          {!!selectedFile && <RenderFileSelected />}
        </>
      )}
      {currentStep === IMPORT_STATE.ERROR_FILE && <RenderFileValidationError />}
      {currentStep === IMPORT_STATE.VALIDATED_FILE && <RenderFileValidated />}
    </Container>
  );
};

ImportFileModal.propTypes = {
  subTitle: Proptypes.string,
  selectedFile: Proptypes.func,
  setSelectedFile: Proptypes.func,
  currentStep: Proptypes.string.isRequired,
  exportErrorFile: Proptypes.func,
};

ImportFileModal.defaultProps = {
  currentStep: IMPORT_STATE.SELECT_FILE,
};

export default ImportFileModal;
