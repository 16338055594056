import i18next from 'i18next';

const getEntityUnit = (entity) => {
  switch (entity.unit) {
    case 'mL':
      return 'L';
    case 'g':
      return 'Kg';
    case 'unit':
      return i18next.t('STOCKS.STOCKS.FORM_PACKAGING_UNIT_LABEL');
    default:
      return entity.unit;
  }
};

export default getEntityUnit;
