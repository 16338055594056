import { get } from 'lodash';
import i18next from 'i18next';

import { ingredient as ingredientService } from '@services/ingredient';
import { supplierProduct as supplierProductService } from '@services/supplierProduct';
import clientService from '@services/client';
import storageAreaService from '@services/storageArea';

export const computeSupplierProduct = (supplierProduct) => {
  const formattedName = supplierProduct.name.trim();

  const formattedSKU = supplierProduct.sku ? supplierProduct.sku.trim() : '-';

  const status = i18next.t(supplierProduct.active ? 'GENERAL.ACTIVE' : 'GENERAL.INACTIVE');

  const formattedSupplierName = get(supplierProduct, 'supplier.name', '-').trim();

  const formattedEntityName = get(supplierProduct, 'entity.name', '-').trim();

  const formattedCategoryName = supplierProduct.category
    ? supplierProduct.category.trim()
    : i18next.t('GENERAL.SELECT_NONE_FEMININE');

  const formattedSubCategoryName = supplierProduct.subCategory
    ? supplierProduct.subCategory.trim()
    : i18next.t('GENERAL.SELECT_NONE_FEMININE');

  return {
    ...supplierProduct,
    status,
    sku: formattedSKU,
    name: formattedName,
    entityName: formattedEntityName,
    category: formattedCategoryName,
    supplierName: formattedSupplierName,
    subCategory: formattedSubCategoryName,
  };
};

export const computeAdvancedFiltersList = async ({ clientId, columns }) => {
  const { categories, subCategories } = await supplierProductService.getSupplierProductsCategories(
    clientId,
  );

  let ingredientOfClients, inventoryListTemplatesOfClient, storageAreasOfClient;

  try {
    ingredientOfClients = await ingredientService.getIngredients(clientId, false);

    inventoryListTemplatesOfClient = await clientService.getInventoryListTemplates(clientId, {
      withMappings: false,
      filterByUserCatalog: true,
    });

    storageAreasOfClient = await storageAreaService.getStorageAreasByClientId(clientId);
  } catch {
    // silent catch, no need to warn user
  }

  const result = columns.reduce((result, column) => {
    const { propertyKey, filterType } = column;

    if (filterType === 'numeric') {
      result.push(column);
    }

    if (propertyKey === 'category' && categories) {
      const choices = categories.map((name, index) => ({
        name,
        id: index,
        category: name,
      }));

      result.push({
        ...column,
        list: choices,
      });
    }

    if (propertyKey === 'subCategory' && subCategories) {
      const choices = subCategories.map((name, index) => ({
        name,
        id: index,
        subCategory: name,
      }));

      result.push({
        ...column,
        list: choices,
      });
    }

    if (propertyKey === 'entityName' && ingredientOfClients) {
      const choices = ingredientOfClients.map(({ id, name }, index) => ({
        name,
        entityName: name,
        id: index,
        entityId: id,
      }));

      choices.push({
        id: -1,
        entityId: null,
        entityName: null,
        name: i18next.t('GENERAL.SELECT_NONE_MASCULINE'),
      });

      result.push({
        ...column,
        list: choices,
      });
    }

    if (propertyKey === 'priceEditableAtReception') {
      result.push({
        ...column,
        list: [
          {
            id: 0,
            itemValue: true,
            name: i18next.t('GENERAL.YES'),
            value: i18next.t('GENERAL.YES'),
            ['priceEditableAtReception']: i18next.t('GENERAL.YES'),
          },
          {
            id: 1,
            itemValue: false,
            name: i18next.t('GENERAL.NO'),
            value: i18next.t('GENERAL.NO'),
            ['priceEditableAtReception']: i18next.t('GENERAL.NO'),
          },
        ],
      });
    }

    if (propertyKey === 'inventoryListTemplate' && inventoryListTemplatesOfClient) {
      const choices = inventoryListTemplatesOfClient.map(({ id, name }, index) => ({
        id: index,
        name,
        inventoryListTemplateId: id,
        inventoryListTemplateName: name,
      }));

      choices.push({
        id: -1,
        name: i18next.t('GENERAL.SELECT_NONE_MASCULINE'),
        inventoryListTemplateId: null,
        inventoryListTemplateName: null,
      });

      result.push({
        ...column,
        list: choices,
      });
    }

    if (propertyKey === 'storageArea' && storageAreasOfClient) {
      const choices = storageAreasOfClient.map(({ id, name }, index) => ({
        id: index,
        name,
        storageAreaId: id,
        storageAreaName: name,
      }));

      choices.push({
        id: -1,
        name: i18next.t('GENERAL.SELECT_NONE_MASCULINE'),
        storageAreaId: null,
        storageAreaName: null,
      });

      result.push({
        ...column,
        list: choices,
      });
    }

    return result;
  }, []);

  return result;
};
