import theme from '@theme';

CanvasRenderingContext2D.prototype.roundRect = function (top, left, width, height, radius) {
  if (width < 2 * radius) {
    radius = width / 2;
  }

  if (height < 2 * radius) {
    radius = height / 2;
  }

  this.beginPath();

  this.moveTo(top + radius, left);

  this.arcTo(top + width, left, top + width, left + height, radius);
  this.arcTo(top + width, left + height, top, left + height, radius);
  this.arcTo(top, left + height, top, left, radius);
  this.arcTo(top, left, top + width, left, radius);

  this.closePath();

  return this;
};

const canvasBackgroundColor = {
  id: 'canvasBackgroundColor',
  beforeDraw(chart, args, { legendConfig }) {
    const {
      ctx,
      chartArea: { top, left, width, height },
    } = chart;

    ctx.fillStyle = theme.colors.greys.lighter;
    ctx.roundRect(left, top, width, height, 8).fill();

    if (legendConfig.display) {
      let startX = left + 8;
      const startY = top + 16;
      ctx.font = legendConfig.font || theme.fonts.textMicroWidth8Height16Weight700Bold;

      for (const { color, text } of legendConfig.labels) {
        ctx.fillStyle = color;
        ctx.fillText(`• ${text}`, startX, startY);

        // Shift the X position of the next text using the current text width
        // And add 12px for the space in between
        startX += ctx.measureText(text).width + 12;
      }
    }
  },
};

const hoverLine = {
  id: 'hoverLine',
  afterDatasetsDraw(chart) {
    const {
      ctx,
      tooltip,
      chartArea: { top, bottom },
      scales: { x },
    } = chart;

    if (!tooltip || !tooltip._active.length) {
      return;
    }

    const xCoor = x.getPixelForValue(tooltip.dataPoints[0].parsed.x);

    ctx.save();

    ctx.beginPath();

    ctx.lineWidth = 1;
    ctx.strokeStyle = theme.colors.blacks.ipBlack1;

    ctx.moveTo(xCoor, top);
    ctx.lineTo(xCoor, bottom);

    ctx.stroke();

    ctx.closePath();
  },
};

export default {
  hoverLine,
  canvasBackgroundColor,
};
