import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  position: relative;

  > div > div > div {
    overflow-x: visible;
  }
`;

export const LabelContainer = styled.div`
  height: 100%;
  min-width: 115px;

  display: flex;
  align-items: center;

  padding: 5px 0px;
  margin-left: 2px;
`;

export const Text = styled.div`
  font: ${(props) => props.theme.fonts?.textBig};
  color: ${(props) => props.theme.colors?.greys?.darkest};

  ${(props) => {
    if (props.bold) {
      return css`
        font: ${(props) => props.theme.fonts?.textMiddleBold};
      `;
    }
  }}
`;

export const SupplierLabelRender = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export default {
  SupplierLabelRender,
  Text,
  LabelContainer,
  Container,
};
