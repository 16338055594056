const { get } = require('lodash');

const SHEET = {
  ID: 'centralKitchenProducts', // Must match the sheet ID declared in the constant on the server
  NAME: 'Produits_Centrale',
};

// The "key" property will be the keys used for payload, must be similar to COLUMN_CONFIG keys
// definition on the server.
// "normalized key" are the headers as seen in the Excel file without diacritics and lower cased.
const COLUMN_IDS = {
  NAME: {
    key: 'name',
    normalizedKey: 'nom',
  },
  CATEGORY: {
    key: 'category',
    normalizedKey: 'categorie',
  },
  SUB_CATEGORY: {
    key: 'subCategory',
    normalizedKey: 'sous-categorie',
  },
  SKU: {
    key: 'sku',
    normalizedKey: 'sku',
    mappingFn: (row, normalizedKey) => get(row, normalizedKey, '').toString(),
  },
  SHELF_LIFE: {
    key: 'shelfLife',
    normalizedKey: 'dlc (j)',
  },
  PRICE: {
    key: 'price',
    normalizedKey: 'prix ht',
  },
  VAT_RATE: {
    key: 'vatRate',
    normalizedKey: 'tva (%)',
  },
  COMPOSITION_TYPE: {
    key: 'compositionTypes',
    normalizedKey: 'type de composition',
  },
  PRODUCT_ASSOCIATED_RECIPE: {
    key: 'associatedRecipe',
    normalizedKey: 'recette associee (produit)',
  },
  PRODUCT_ASSOCIATED_INGREDIENT: {
    key: 'associatedIngredient',
    normalizedKey: 'ingredient associe (produit)',
  },
  SUPPLIER_PRODUCT_ENTITY: {
    key: 'supplierProductEntity',
    normalizedKey: 'ingredient associe (if)',
  },
  LOSS: {
    key: 'loss',
    normalizedKey: 'pertes (%)',
  },
  PACKAGE_WEIGHT: {
    key: 'packageWeight',
    normalizedKey: 'poids du colis (kg)',
  },
  PACKAGING_NAME_1: {
    key: 'packagingName1',
    normalizedKey: 'conditionnement 1',
  },
  PACKAGING_NAME_2: {
    key: 'packagingName2',
    normalizedKey: 'conditionnement 2',
  },
  PACKAGING_NAME_3: {
    key: 'packagingName3',
    normalizedKey: 'conditionnement 3',
  },
  PACKAGING_UNIT_1: {
    key: 'packagingUnit1',
    normalizedKey: 'unite du conditionnement 1',
  },
  PACKAGING_UNIT_2: {
    key: 'packagingUnit2',
    normalizedKey: 'unite du conditionnement 2',
  },
  PACKAGING_UNIT_3: {
    key: 'packagingUnit3',
    normalizedKey: 'unite du conditionnement 3',
  },
  PACKAGING_QUANTITY_1: {
    key: 'packagingQuantity1',
    normalizedKey: 'valeur du conditionnement 1',
  },
  PACKAGING_QUANTITY_2: {
    key: 'packagingQuantity2',
    normalizedKey: 'valeur du conditionnement 2',
  },
  PACKAGING_QUANTITY_3: {
    key: 'packagingQuantity3',
    normalizedKey: 'valeur du conditionnement 3',
  },
};

// Central Kitchen Product associated ingredient sheet definition
const HIDDEN_SHEET_ASSOCIATED_INGREDIENT = {
  ID: 'associatedIngredient',
  NAME: 'ingrédientAssocié',
};

const HIDDEN_ASSOCIATED_INGREDIENT_COLUMN_IDS = {
  ID: 'id',
  NAME: 'name',
  UNIT: 'unit',
};

const HIDDEN_ASSOCIATED_INGREDIENT_NORMALIZE_COLUMN_IDS = {
  ID: 'ingredientid',
  NAME: 'nom',
  UNIT: 'unite',
};

// Central Kitchen Product associated ingredient sheet definition
const HIDDEN_SHEET_ASSOCIATED_RECIPE = {
  ID: 'associatedRecipe',
  NAME: 'recetteAssociée',
};

const HIDDEN_ASSOCIATED_RECIPE_COLUMN_IDS = {
  ID: 'id',
  NAME: 'name',
  UNIT: 'unit',
};

const HIDDEN_ASSOCIATED_RECIPE_NORMALIZE_COLUMN_IDS = {
  ID: 'recipeid',
  NAME: 'nom',
  UNIT: 'unite',
};

module.exports = {
  SHEET,
  COLUMN_IDS,
  // Central Kitchen Product associated recipe
  HIDDEN_SHEET_ASSOCIATED_RECIPE,
  HIDDEN_ASSOCIATED_RECIPE_COLUMN_IDS,
  HIDDEN_ASSOCIATED_RECIPE_NORMALIZE_COLUMN_IDS,
  // Central Kitchen Product associated ingredient
  HIDDEN_SHEET_ASSOCIATED_INGREDIENT,
  HIDDEN_ASSOCIATED_INGREDIENT_COLUMN_IDS,
  HIDDEN_ASSOCIATED_INGREDIENT_NORMALIZE_COLUMN_IDS,
};
