import styled, { css } from 'styled-components';

export const SimpleTableViewContainer = styled.div`
  display: flex;
  flex-direction: column;

  flex-grow: 1;

  /* for Firefox */
  min-height: 0;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  width: 100%;

  z-index: 5;
`;

export const Content = styled.div`
  width: 100%;

  /* for Firefox */
  min-height: 0;
`;

export const Row = styled.div`
  height: ${({ header }) => (header ? '24px' : '40px')};
  width: 100%;
  position: relative;
  display: flex;
  background: ${({ theme, header }) =>
    header ? theme.colors.white : theme.colors.lgrey};
  border-radius: ${({ header }) => (header ? '0px' : '8px')};
`;

export const RowSeparator = styled.div`
  height: 8px;
  width: 100%;

  background: white;
`;

export const Item = styled.div`
  min-width: 0; // to allow text-overflow: ellipsis in child

  flex: 2;

  &:first-child {
    flex: 3;
  }

  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  overflow: hidden;
  white-space: pre-line;
  text-overflow: ellipsis;

  padding: 8px 16px;

  ${(props) =>
    props.header &&
    css`
      font: ${props.theme.fonts.titleSmallHeight10};
      color: ${props.theme.colors.dgrey};
    `}
`;

export const DisplayNameContainer = styled.div`
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TooltipText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  -webkit-box-orient: vertical;
`;

export default {
  SimpleTableViewContainer,
  Header,
  Content,
  Row,
  RowSeparator,
  Item,
  Text,
  DisplayNameContainer,
  TooltipText,
};
