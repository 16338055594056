import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 48px);

  margin-top: 48px;
  position: relative;

  > div {
    overflow: auto;
  }
`;

export const Text = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  font-family: proximanovaregular;

  font: ${(props) => props.theme.fonts?.textBig};
  color: ${(props) => props.theme.colors?.greys?.darkest};

  ${(props) => {
    if (props.bold) {
      return css`
        font: ${(props) => props.theme.fonts?.textMiddleBold};
      `;
    }
  }}

  ${(props) => {
    if (props.highlighted) {
      return css`
        font: ${(props) => props.theme.fonts?.textMiddleBold};
        color: ${(props) => props.theme.colors?.greys?.darkest};
      `;
    }
  }}
`;

export const LabelContainer = styled.div`
  display: flex;
`;
