import moment from 'moment-timezone';

import { getUserTimezone } from '@commons/utils/date';

const getOrderEmailBody = (emailBody, userInfos, storeInfos, clientName) => {
  let formattedBody = emailBody;

  const replaceFields = {
    '[storeName]': `${storeInfos.brand || clientName} ${storeInfos.name}`,
    '[orderDate]': moment.tz(getUserTimezone()).format('L'),
    '[firstName]': userInfos.firstName || 'Inpulse',
    '[lastName]': userInfos.lastName || '',
  };

  Object.keys(replaceFields).forEach((fieldName) => {
    formattedBody = formattedBody.replace(fieldName, replaceFields[fieldName]);
  });

  return formattedBody.replace(/\n/g, '<br/>');
};

export default getOrderEmailBody;
