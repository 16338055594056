import styled, { css } from 'styled-components';

export const StyledCustomLegend = styled.div`
  .error-forecast-chart-legend-container {
    display: flex;
    height: 65px;
    margin-left: 50px;
    margin-right: 20px;
    width: calc(100% - 70px);
  }
  .error-forecast-chart-legend-item-container {
    display: inline-flex;
    align-items: center;
    height: 12px;
    width: 125px;
    margin-bottom: 10px;
    margin-right: 24px;
  }
  .error-forecast-chart-legend {
    float: left;
    height: 16px;
    width: 16px;
    border-radius: 50%;
  }
  .error-forecast-chart-legend-value {
    float: left;
    margin-left: 10px;
    ${(props) =>
      props.theme.fonts
        ? css`
            font: ${props.theme.fonts?.textBig};
          `
        : css`
            line-height: 15px;
            font-family: proximanovaregular;
            font-size: 13px;
          `}
    color: ${(props) => props.theme.colors?.greys.darkest || '#6b768c'};
  }

  .bar-chart-legend-container {
    display: inline-block;
    height: 50px;
    width: calc(80%);
    margin-left: 10%;
    padding: 20px 10px 0 10px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .bar-chart-legend-item-container {
    display: inline-block;
    height: 12px;
    margin-bottom: 10px;
  }
`;
