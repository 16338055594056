import styled, { css } from 'styled-components';

export const LoadingStateContainer = styled.div`
  overflow: auto;
  height: 100%;

  border-radius: 8px;
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;

  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);

  background-color: #ffffff;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid #e5e5e5;
`;

export const LoadingRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  padding: 0 25px 0 0;
`;

export const LoadingItemContainer = styled.div`
  padding: 0 0 0 15px;
  width: 100%;
  flex: ${(props) => (props.large ? 2 : 1)};

  ${(props) =>
    props.narrow &&
    css`
      flex-basis: 0;
    `}

  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth}px;
    `}
`;

export default {
  LoadingContainer,
  LoadingRow,
  LoadingItemContainer,
};
