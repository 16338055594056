import styled, { css, keyframes } from 'styled-components';

const gradient = keyframes`
  0% { opacity: 0.4; }
  50% { opacity: 1; }
  100% { opacity: 0.4; }
`;

export const Container = styled.div`
  display: flex;
  height: 244px;
  align-items: center;
  padding: 0 16px;
  font-size: 14px;
  line-height: 14px;
`;

export const RecommendationContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  height: 212px;
  background-color: #ffffff;
  border-radius: 8px;
  border: ${(props) => `1px solid ${props.theme.colors.greys.light}`};
  margin-right: 16px;
  padding: 16px;
  ${(props) =>
    props.isLoading &&
    css`
      animation: ${gradient} 1.5s linear infinite;
    `}
`;

export const StockContainer = styled.div`
  display: flex;
  flex-basis: 50%;
  height: 212px;
  background-color: #ffffff;
  border-radius: 8px;
  border: ${(props) => `1px solid ${props.theme.colors.greys.light}`};
  width: 100%;
  padding: 16px;
`;

export default {
  Container,
  RecommendationContainer,
  StockContainer,
};
