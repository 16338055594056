import _ from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import utilsXLS from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { configuration } from '@backoffice/BackOfficeChecks/constant';
/**
 * Close the modal
 *
 * @returns {void}
 */
export const exitModal = (setIsLoading, closeModal) => {
  setIsLoading(false);

  closeModal();
};

export const createMainSheet = async (
  exportKey,
  sheetTitle,
  data,
  hasMultipleBrands,
  maxDistanceWeatherStation,
) => {
  const headersSettings = configuration[exportKey](hasMultipleBrands).columns;

  const headers = headersSettings.map(({ displayName }) => displayName);

  const mappedData = data.map((item) =>
    headersSettings.map(({ keyName, transform }) => {
      if (_.get(item, keyName) === null) {
        return '';
      } else if (keyName === 'distanceWeatherStation') {
        return maxDistanceWeatherStation;
      } else {
        return transform(_.get(item, keyName));
      }
    }),
  );

  return {
    title: sheetTitle,
    headers,
    data: mappedData,
  };
};

/* export const renderContent = (props) => {
  const { closeModal, progress, titleModal, isLoading, setIsLoading } = props;

  return (
    <div>
      <Header>
        <Title>{titleModal}</Title>
      </Header>

      <Content>
        <ProgressBar now={progress} />
      </Content>

      <Footer>
        <Button
          color={isLoading ? 'white' : 'blue'}
          handleClick={() => exitModal(setIsLoading, closeModal)}
          label={isLoading ? i18next.t('GENERAL.CANCEL') : i18next.t('GENERAL.CLOSE')}
          minWidth={150}
        />
      </Footer>
    </div>
  );
}; */

export const GeneralExportModal = (props) => {
  const {
    params: {
      exportKey,
      data,
      clientName,
      showMessage,
      hasMultipleBrands,
      maxDistanceWeatherStation,
    },
    closeModal,
  } = props;
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [titleModal, setTitleModal] = useState(configuration[exportKey]().modalTitle || '');

  useEffect(() => {
    (async function launchExport() {
      try {
        const fileTitle = configuration[exportKey]().fileTitle + ' - ' + clientName;
        const mainSheet = await createMainSheet(
          exportKey,
          configuration[exportKey]().sheetTitle,
          data,
          hasMultipleBrands,
          maxDistanceWeatherStation,
        );
        utilsXLS.makeXLS(fileTitle, [mainSheet]);
        setTitleModal(i18next.t('GENERAL.EXPORT_SUCCESS'));
        setIsLoading(false);
      } catch (error) {
        showMessage(i18next.t('GENERAL.EXPORT_FAILURE'), 'error');
        exitModal(setIsLoading, closeModal);
      }
    })();
  }, []);

  useEffect(() => {
    if (isLoading && progress < 100) {
      setProgress(100);
    }
  }, [progress]);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setIsLoading}
      titleModal={titleModal}
    />
  );
};

export default GeneralExportModal;
