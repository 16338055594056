import i18next from 'i18next';
import React from 'react';

import { Button, Tooltip } from '@commons/utils/styledLibraryComponents';
import sortActionsList from '@commons/utils/sortActionsList';

import { canEditSupplierProductFromDetailView } from '@selectors/actions/supplierProductActions';

const getGlobalActions = ({
  selectedItems,
  availableStores,
  isLoading,
  shouldDisableNewAssociation,
  handleExport,
  handleRemoveAssociation,
  handleEditionAssociation,
  handleCreateNewAssociation,
  openNextAvailabilitiesSchedulingModal,
  handleDeleteScheduledAvailabilitiesModal,
  isCreation,
  authorizedActions,
  hasUserCatalogReadOnlyRights,
}) => {
  const hasSelectedItems = !!selectedItems.length;

  const isSingleSelection = selectedItems.length === 1;

  const hasSelectedInactiveStores = selectedItems.some((store) => !store.storeActive);

  const doesOneHasScheduledAvailabilities = selectedItems.some(
    ({ nextAvailabilityDate }) => !!nextAvailabilityDate,
  );

  return sortActionsList([
    {
      fixed:
        (isCreation || canEditSupplierProductFromDetailView(authorizedActions)) &&
        !hasUserCatalogReadOnlyRights,
      id: 'action-add',
      actionLabel: i18next.t('GENERAL.ADD'),
      isHidden: () =>
        (!isCreation && !canEditSupplierProductFromDetailView(authorizedActions)) ||
        hasUserCatalogReadOnlyRights,
      render: () => (
        <>
          {!shouldDisableNewAssociation && (
            <Button
              color={'inpulse-default'}
              handleClick={() => handleCreateNewAssociation(availableStores)}
              icon="/images/inpulse/add-white-small.svg"
              isDisabled={isLoading}
              label={i18next.t('GENERAL.ADD')}
            />
          )}
          {shouldDisableNewAssociation && (
            <Tooltip
              displayBigger={true}
              renderTooltipTrigger={() => (
                <Button
                  color={'inpulse-default'}
                  handleClick={() => {}}
                  icon="/images/inpulse/add-white-small.svg"
                  isDisabled={true}
                  label={i18next.t('GENERAL.ADD')}
                />
              )}
              text={i18next.t(
                'ADMIN.SUPPLIER_PRODUCTS.MAPPINGS_DISABLED_ASSOCIATION_BUTTON_TOOLTIP',
              )}
            />
          )}
        </>
      ),
      handleAction: () => {},
    },
    {
      id: 'export',
      actionLabel: i18next.t(
        !hasSelectedItems ? 'GENERAL.EXPORT_LIST' : 'GENERAL.EXPORT_SELECTION',
      ),
      handleAction: () => handleExport(hasSelectedItems),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
    {
      id: 'delete',
      actionLabel: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_ACTION_REMOVE_SELECTION'),
      handleAction: () => handleRemoveAssociation(selectedItems),
      actionIcon: () => '/images/inpulse/remove-black-small.svg',
      isHidden: () =>
        !hasSelectedItems ||
        hasUserCatalogReadOnlyRights ||
        (!isCreation && !canEditSupplierProductFromDetailView(authorizedActions)),
    },
    {
      id: 'delete-scheduled-next-availabilities',
      actionLabel: isSingleSelection
        ? i18next.t(
            'ADMIN.SUPPLIER_PRODUCTS.ACTION_DELETE_SUPPLIER_PRODUCT_SCHEDULED_AVAILABILITY_SINGLE',
          )
        : i18next.t(
            'ADMIN.SUPPLIER_PRODUCTS.ACTION_DELETE_SUPPLIER_PRODUCT_SCHEDULED_AVAILABILITY_MULTIPLE',
          ),
      handleAction: () => handleDeleteScheduledAvailabilitiesModal(selectedItems),
      actionIcon: () => '/images/inpulse/trash-black.svg',
      isHidden: () =>
        !hasSelectedItems ||
        !doesOneHasScheduledAvailabilities ||
        isCreation ||
        hasUserCatalogReadOnlyRights ||
        !canEditSupplierProductFromDetailView(authorizedActions),
    },
    {
      id: 'edit',
      actionLabel: i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTION_MANAGE_AVAILABILITY'),
      handleAction: () => handleEditionAssociation(),
      actionIcon: () => '/images/inpulse/pen-black-small.svg',
      isHidden: () =>
        !hasSelectedItems ||
        isSingleSelection ||
        hasUserCatalogReadOnlyRights ||
        (!isCreation && !canEditSupplierProductFromDetailView(authorizedActions)),
    },
    {
      id: 'define-next-availabilities-action',
      actionLabel: () => i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTION_SCHEDULE_NEXT_AVAILABILITIES'),
      handleAction: () => openNextAvailabilitiesSchedulingModal(selectedItems),
      actionIcon: () => '/images/inpulse/calendar-black-small.svg',
      isHidden: () =>
        hasSelectedInactiveStores ||
        !hasSelectedItems ||
        isCreation ||
        hasUserCatalogReadOnlyRights ||
        !canEditSupplierProductFromDetailView(authorizedActions),
    },
  ]);
};

const getRowActions = ({
  handleRemoveAssociation,
  openNextAvailabilitiesSchedulingModal,
  handleDeleteScheduledAvailabilitiesModal,
  isCreation,
  authorizedActions,
  hasUserCatalogReadOnlyRights,
}) => [
  {
    id: 'define-next-availabilities-action',
    actionLabel: () => i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTION_SCHEDULE_NEXT_AVAILABILITIES'),
    handleAction: (mapping) => openNextAvailabilitiesSchedulingModal([mapping]),
    actionIcon: () => '/images/inpulse/calendar-black-small.svg',
    isHidden: ({ storeActive }) =>
      !storeActive ||
      isCreation ||
      !canEditSupplierProductFromDetailView(authorizedActions) ||
      hasUserCatalogReadOnlyRights,
  },
  {
    id: 'delete',
    actionLabel: () =>
      i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_ACTION_REMOVE_SELECTION'),
    handleAction: (item) => handleRemoveAssociation([item]),
    actionIcon: () => '/images/inpulse/remove-black-small.svg',
    isHidden: () =>
      (!isCreation && !canEditSupplierProductFromDetailView(authorizedActions)) ||
      hasUserCatalogReadOnlyRights,
  },
  {
    id: 'delete-scheduled-next-availabilities',
    actionLabel: () =>
      i18next.t(
        'ADMIN.SUPPLIER_PRODUCTS.ACTION_DELETE_SUPPLIER_PRODUCT_SCHEDULED_AVAILABILITY_SINGLE',
      ),
    handleAction: (selectedSSPM) => handleDeleteScheduledAvailabilitiesModal([selectedSSPM]),
    actionIcon: () => '/images/inpulse/trash-black.svg',
    isHidden: (selectedSSPM) =>
      !selectedSSPM.nextAvailabilityDate ||
      isCreation ||
      !canEditSupplierProductFromDetailView(authorizedActions) ||
      hasUserCatalogReadOnlyRights,
  },
];

export default {
  getRowActions,
  getGlobalActions,
};
