import { connect } from 'react-redux';
import { get, isEqual } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

// ACTIONS
import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// COMMONS
import { buildSchema } from '@commons/GenericForm';
import { Button } from '@commons/utils/styledLibraryComponents';
import { ConfirmationModal } from '@commons/Modals/ConfirmationModal';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import Footer from '@commons/Footer/Footer';
import NavigationBar from '@commons/NavigationBar';

// SELECTORS
import { getClientInfo } from '@selectors/client';

// SERVICES
import clientService from '@services/client';

import { ButtonsContainer, ContentContainer, PageContainer } from '../styledComponents';
import {
  INVENTORY_LIST_TEMPLATE_FORM_INPUTS,
  FIELDS_TO_CHECK,
} from '../utils/formInputsConfigurations';
import InventoryListTemplateInformations from '../inventoryListTemplateInformations';

const InventoryListTemplateDetails = (props) => {
  const {
    match: { path, params },
    client: { clientId },
    pageLoaded,
    pageLoading,
    history,
    showSuccessMessage,
    showErrorMessage,
    openGenericModal,
  } = props;

  const inventoryListTemplateId = params.id;

  const [inventoryListTemplate, setInventoryListTemplate] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isSaveAlreadyTriggered, setIsSaveAlreadyTriggered] = useState(false);
  const [isFooterDisplayed, setIsFooterDisplayed] = useState(false);

  const inventoryListTemplateForm = useForm({
    defaultValues: {},
    resolver: yupResolver(buildSchema(INVENTORY_LIST_TEMPLATE_FORM_INPUTS())),
  });

  const formFields = useWatch({
    control: inventoryListTemplateForm.control,
  });

  useEffect(() => {
    if (!params.id) {
      return;
    }

    pageLoading();

    (async () => {
      try {
        const fetchedInventoryListTemplate = await clientService.getInventoryListTemplateById(
          clientId,
          params.id,
        );

        setInventoryListTemplate(fetchedInventoryListTemplate);
        inventoryListTemplateForm.setValue('name', fetchedInventoryListTemplate.name);
      } catch {
        showErrorMessage(i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.FETCH_ERROR'));
      } finally {
        pageLoaded();
      }
    })();
  }, []);

  /** USE EFFECTS */

  useEffect(() => {
    (async () => {
      const fieldsValidation = await inventoryListTemplateForm.trigger(FIELDS_TO_CHECK);
      setIsSaveDisabled(!fieldsValidation);

      const isFormDirty = get(inventoryListTemplateForm, 'formState.isDirty', false);

      const dirtyFields = Object.keys(get(inventoryListTemplateForm, 'formState.dirtyFields', {}));

      const hasAtLeastOneDifferentField = dirtyFields.some(
        (field) => !isEqual(inventoryListTemplate[field], formFields[field]),
      );

      if (isFormDirty && hasAtLeastOneDifferentField) {
        setIsFooterDisplayed(true);
        return;
      }

      setIsFooterDisplayed(false);
    })();
  }, [formFields]);

  /** FUNCTIONS */

  const returnToInventoryListTemplatesList = () => history.goBack();

  const validateForm = async () => {
    const fieldsValidation = await inventoryListTemplateForm.trigger(FIELDS_TO_CHECK);

    setIsSaveAlreadyTriggered(true);

    if (!fieldsValidation) {
      setIsSaveDisabled(true);
      return;
    }

    handleUpdateInventoryListTemplate();
  };

  const handleUpdateInventoryListTemplate = () => {
    const params = {
      type: 'warning',
      width: '542px',
      height: 'auto',
      icon: '/images/inpulse/warning-white-small.svg',
      title: i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.UPDATE_NAME_CONFIRMATION_MESSAGE'),
      component: ConfirmationModal,
      data: {
        content: i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.UPDATE_NAME_CONFIRMATION_TITLE'),
      },
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: () => updateInventoryListTemplate(),
        },
      ],
    };
    openGenericModal(params);
  };

  const updateInventoryListTemplate = async () => {
    const updatedName = get(formFields, 'name', '');

    pageLoading();

    try {
      await clientService.updateInventoryListTemplate(
        clientId,
        inventoryListTemplateId,
        updatedName,
      );

      showSuccessMessage(i18next.t('GENERAL.CHANGES_SUCCESSFULLY_SAVED'));

      /*
        We call pageLoaded here and not in a finally because the function returnToInventoryListTemplatesList 
        redirects to another page and we don't want to risk an infinite loading
      */
      pageLoaded();

      returnToInventoryListTemplatesList();
    } catch (error) {
      pageLoaded();

      showErrorMessage(i18next.t('GENERAL.SAVING_CHANGES_FAILED'));
    }
  };

  return (
    <>
      <NavigationBar
        inventoryListTemplate={{
          id: inventoryListTemplateId,
          name: inventoryListTemplate.name || '',
        }}
        path={path}
        bigTopBar
        enableActionBottomOrder
      />
      <PageContainer>
        <ContentContainer isFooterDisplayed>
          <InventoryListTemplateInformations
            inventoryListTemplateForm={inventoryListTemplateForm}
            isSaveAlreadyTriggered={isSaveAlreadyTriggered}
          />
        </ContentContainer>
        {isFooterDisplayed && (
          <Footer>
            <ButtonsContainer>
              <Button
                color={'inpulse-outline'}
                handleClick={returnToInventoryListTemplatesList}
                icon={'/images/inpulse/close-black-small.svg'}
                label={i18next.t('GENERAL.CANCEL')}
              />
              <Button
                color={'inpulse-default'}
                handleClick={validateForm}
                icon={'/images/inpulse/save-white-small.svg'}
                isDisabled={isSaveDisabled}
                label={i18next.t('GENERAL.SAVE')}
              />
            </ButtonsContainer>
          </Footer>
        )}
      </PageContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryListTemplateDetails);
