import styled, { css } from 'styled-components';

export const Container = styled.div`
  max-width: ${(props) => props.maxWidth || '800px'};
  width: ${(props) => props.width || 'calc(100% - 48px)'};

  padding: 24px;

  display: flex;
  flex-direction: column;
  row-gap: 24px;

  background-color: ${(props) => props.theme.colors?.greys?.lightest};

  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08) !important;
  border-radius: 8px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;

  font: ${(props) => props.titleFont || props.theme.fonts.textMiddleNormal};
  color: ${(props) =>
    props.contentColor ? props.contentColor : props.theme.colors?.greys?.darkest};

  ${(props) =>
    props.hasTooltip &&
    css`
      > div {
        margin: 0px !important;
        display: flex;
        align-items: center;
      }
    `}

  ${(props) =>
    props.bold &&
    css`
      font: ${(props) => props.theme.fonts.h2Inter};
    `}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  font: ${(props) => props.theme.fonts.textMiddleNormal};
  color: ${(props) =>
    props.contentColor ? props.contentColor : props.theme.colors?.greys?.darkest};

  ${(props) =>
    props.bold &&
    css`
      font: ${(props) => props.theme.fonts.h2Inter};
    `}
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  row-gap: 24px;
  column-gap: 24px;
`;
