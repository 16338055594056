import i18next from 'i18next';
import React from 'react';

import { ENUM_FONTS } from '@commons/Text';
import DisplayNumber from '@commons/DisplayNumber';

const getBreakagePercentage = (part, total) => {
  if (total === 0) return 0;
  else return Number.parseInt((part / total) * 100);
};

export const customTooltip = ({ payload, highestValue }) => {
  if (!payload) return;

  const week = payload[0] ? payload[0].payload.week : '';
  const breakageRate = payload[0];
  const turnover = payload[1];

  return (
    <div
      className="manager-barchart-tooltip-container"
      style={{ width: '150px', backgroundColor: 'white' }}
    >
      <div className="barchart-tooltip-header">{week}</div>
      <div className="manager-barchart-tooltip-body">
        {payload.map((item, index) => {
          if (item.name === 'turnover' && turnover) {
            return (
              <div className="tooltip-past-error-item" key={'turnover' + index}>
                <h3 style={{ color: item.color, width: '100%' }}>
                  <DisplayNumber
                    color={item.color}
                    displayCurrencyCode={true}
                    font={ENUM_FONTS.TEXT_SMALL}
                    number={turnover.value}
                    withoutDecimals={true}
                  />
                </h3>
                <h4 style={{ color: item.color }}>{i18next.t('HOME.LABEL_TURNOVER')}</h4>
              </div>
            );
          }
          if (
            (item.name === 'productionWasteRate' || item.name === 'turnoverWasteRate') &&
            breakageRate
          ) {
            return (
              <div className="tooltip-past-error-item" key={'breakageRate' + index}>
                <h3 style={{ color: item.color }}>
                  {getBreakagePercentage(breakageRate.value, highestValue).toFixed(0)}%
                </h3>
                <h4 style={{ color: item.color }}>{i18next.t('HOME.LABEL_LOSS_PERCENTAGE')}</h4>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
