import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import moment from 'moment-timezone';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import { TURNOVER_FORECAST } from '@commons/features/salesPointNavigation/forecast';

import { RenderValidateModal } from '../../OrderFormModals';
import ORDER_MODAL_TYPE from '../../constants/modalType';

const RenderCloseOrderModal = (props) => {
  const { closeCloseOrderModal, verifiedClose } = props;
  return (
    <div className="conf-modal-encloser">
      <div className="conf-modal-content-encloser">
        <div className="conf-modal-upper-red">
          <p className="conf-modal-upper-p-red">{i18next.t('GENERAL.LEAVE')}</p>
        </div>
        <div className="conf-modal-down">
          <div className="conf-modal-text">
            {i18next.t('ORDERS.ORDERS.MODAL_CLOSE_TEXT_1')}
            <br />
            {i18next.t('ORDERS.ORDERS.MODAL_CLOSE_TEXT_2')}
          </div>
          <div className="conf-down-buttons">
            <Button
              buttonCustomStyle={{ marginLeft: 30 }}
              color={'inpulse-outline'}
              formatText={false}
              handleClick={closeCloseOrderModal}
              label={i18next.t('GENERAL.CANCEL')}
            />
            <Button
              buttonCustomStyle={{ marginLeft: 30 }}
              color={'red'}
              formatText={false}
              handleClick={verifiedClose}
              label={i18next.t('GENERAL.LEAVE')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderDateErrorModal = (props) => {
  const { closeOrderDateModal, dateErrorType, maxOrderDate, orderTimeLimit, startDate, timezone } =
    props;

  let reason = '';
  let header = i18next.t('ORDERS.ORDERS.MODAL_ERROR_DATE_DEFAULT_TITLE');

  if (dateErrorType === ORDER_MODAL_TYPE.DATE_ORDER_LIMIT) {
    header = i18next.t('ORDERS.ORDERS.MODAL_ERROR_DATE_ORDER_DATE_DEADLINE_EXCEEDED_TITLE');
    reason = orderTimeLimit
      ? i18next.t(
          'ORDERS.ORDERS.MODAL_ERROR_DATE_ORDER_DATE_DEADLINE_EXCEEDED_CONTENT_WITH_ORDER_LIMIT',
          {
            maxOrderDate: moment.tz(maxOrderDate, timezone).format('dddd D MMMM'),
            orderTimeLimit,
            startDate: moment.tz(startDate, timezone).format('dddd D MMMM'),
          },
        )
      : i18next.t(
          'ORDERS.ORDERS.MODAL_ERROR_DATE_ORDER_DATE_DEADLINE_EXCEEDED_CONTENT_WITHOUT_ORDER_LIMIT',
          {
            maxOrderDate: moment.tz(maxOrderDate, timezone).format('dddd D MMMM'),
            startDate: moment.tz(startDate, timezone).format('dddd D MMMM'),
          },
        );
  } else if (dateErrorType === ORDER_MODAL_TYPE.DATE_ORDER_LIMIT_AFTER_EDIT) {
    header = i18next.t('ORDERS.ORDERS.MODAL_ERROR_DATE_ORDER_TIME_DEADLINE_EXCEEDED_TITLE');
    reason = orderTimeLimit
      ? i18next.t(
          'ORDERS.ORDERS.MODAL_ERROR_DATE_ORDER_TIME_DEADLINE_EXCEEDED_CONTENT_WITH_ORDER_LIMIT',
          {
            maxOrderDate: moment.tz(maxOrderDate, timezone).format('dddd D MMMM'),
            orderTimeLimit,
          },
        )
      : i18next.t(
          'ORDERS.ORDERS.MODAL_ERROR_DATE_ORDER_TIME_DEADLINE_EXCEEDED_CONTENT_WITHOUT_ORDER_LIMIT',
          {
            maxOrderDate: moment.tz(maxOrderDate, timezone).format('dddd D MMMM'),
          },
        );
  } else if (dateErrorType === ORDER_MODAL_TYPE.DATE_ORDER_EXISTS) {
    header = i18next.t('ORDERS.ORDERS.MODAL_ERROR_DATE_ANOTHER_ORDER_ALREADY_EXISTS_TITLE');
    reason = i18next.t('ORDERS.ORDERS.MODAL_ERROR_DATE_ANOTHER_ORDER_ALREADY_EXISTS_CONTENT');
  } else if (dateErrorType === 'supplierProfile') {
    header = i18next.t(
      'ORDERS.ORDERS.MODAL_ERROR_DATE_SUPPLIER_DOES_NOT_DELIVER_ON_THIS_DATE_TITLE',
    );
    reason = i18next.t(
      'ORDERS.ORDERS.MODAL_ERROR_DATE_SUPPLIER_DOES_NOT_DELIVER_ON_THIS_DATE_CONTENT',
    );
  }
  return (
    <div className="conf-modal-encloser">
      <div className="conf-modal-content-encloser">
        <div className="conf-modal-upper-blue">
          <p className="conf-modal-upper-p-blue">{header}</p>
        </div>
        <div className="conf-modal-down">
          <div className="conf-modal-text">
            <p>{parse(reason)}</p>
          </div>
          <div className="conf-down-buttons">
            <Button
              buttonCustomStyle={{ marginLeft: 30 }}
              color={'blue'}
              formatText={false}
              handleClick={() => closeOrderDateModal()}
              icon={'/images/inpulse/check-white-small.svg'}
              label={i18next.t('ORDERS.ORDERS.MODAL_ERROR_BUTTON_UNDERSTOOD')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderStockReassemblyNotDoneErrorModal = (props) => {
  const { closeStockReassemblyNotDoneModal, goInventoriesPage } = props;

  return (
    <div
      className="conf-modal-encloser"
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          closeStockReassemblyNotDoneModal();
        }
      }}
    >
      <div className="conf-modal-content-encloser">
        <div className="conf-modal-upper-orange">
          <p className="conf-modal-upper-p-orange">
            {i18next.t('ORDERS.ORDERS.MODAL_ERROR_STOCK_REASSEMBLY_TITLE')}
          </p>
        </div>
        <div className="conf-modal-down">
          <div className="conf-modal-text">
            <p>{parse(i18next.t('ORDERS.ORDERS.MODAL_ERROR_STOCK_REASSEMBLY_CONTENT'))}</p>
          </div>
          <div className="conf-down-buttons">
            <Button
              buttonCustomStyle={{ marginLeft: 30 }}
              color={'inpulse-outline'}
              formatText={false}
              handleClick={() => closeStockReassemblyNotDoneModal()}
              icon={'/images/inpulse/close-black-small.svg'}
              label={i18next.t('GENERAL.CANCEL')}
            />
            <Button
              buttonCustomStyle={{ marginLeft: 30 }}
              color={'orange'}
              formatText={false}
              handleClick={goInventoriesPage}
              icon={'/images/inpulse/stock-white-small.svg'}
              label={i18next.t('ORDERS.ORDERS.MODAL_ERROR_BUTTON_STOCK_REASSEMBLY')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderForecastNotConfirmedErrorModal = (props) => {
  const { closeForecastNotFoundModal, endDate, goForecastPage, startDate } = props;

  const forecaseNotConfirmedErrorText = startDate.isSame(endDate, 'day')
    ? i18next.t('ORDERS.ORDERS.MODAL_ERROR_FORECASTS_NOT_CONFIRM_CONTENT_SAME_DATE_START_END', {
        date: startDate.format('DD/MM'),
      })
    : i18next.t('ORDERS.ORDERS.MODAL_ERROR_FORECASTS_NOT_CONFIRM_CONTENT_RANGE_DATE_START_END', {
        startDate: startDate.startOf('day').format('DD/MM/YYYY'),
        endDate: endDate.endOf('day').format('DD/MM/YYYY'),
      });

  return (
    <div
      className="conf-modal-encloser"
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          closeForecastNotFoundModal();
        }
      }}
    >
      <div className="conf-modal-content-encloser">
        <div className="conf-modal-upper-orange">
          <p className="conf-modal-upper-p-orange">
            {i18next.t('ORDERS.ORDERS.MODAL_ERROR_FORECASTS_NOT_CONFIRM_TITLE')}
          </p>
        </div>
        <div className="conf-modal-down">
          <div className="conf-modal-text">
            <p>{parse(forecaseNotConfirmedErrorText)}</p>
          </div>
          <div className="conf-down-buttons">
            <Button
              buttonCustomStyle={{ marginLeft: 30 }}
              color={'inpulse-outline'}
              formatText={false}
              handleClick={() => closeForecastNotFoundModal()}
              icon={'/images/inpulse/close-black-small.svg'}
              label={i18next.t('GENERAL.CANCEL')}
            />
            <Button
              buttonCustomStyle={{ marginLeft: 30 }}
              color={'orange'}
              formatText={false}
              handleClick={goForecastPage}
              label={i18next.t('ORDERS.ORDERS.MODAL_ERROR_BUTTON_FORECASTS')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function ModalManager(props) {
  const {
    endDate,
    modalType,
    startDate,
    setModalType,
    verifiedClose,
    maxOrderDate,
    orderTimeLimit,
    closeErrorModal,
    timezone,
  } = props;

  const history = useHistory();

  if (modalType === ORDER_MODAL_TYPE.CLOSE) {
    return (
      <RenderValidateModal
        closeModal={() => setModalType(null)}
        handleConfirm={verifiedClose}
        text={`${i18next.t('ORDERS.ORDERS.MODAL_CLOSE_TEXT_1')}\n ${i18next.t(
          'ORDERS.ORDERS.MODAL_CLOSE_TEXT_2',
        )}`}
        title={i18next.t('GENERAL.LEAVE')}
      />
    );
  }

  if (modalType === ORDER_MODAL_TYPE.DATE) {
    return (
      <RenderCloseOrderModal
        closeCloseOrderModal={() => setModalType(null)}
        verifiedClose={verifiedClose}
      />
    );
  }

  if (
    [
      ORDER_MODAL_TYPE.DATE_ORDER_LIMIT,
      ORDER_MODAL_TYPE.DATE_ORDER_EXISTS,
      ORDER_MODAL_TYPE.DATE_SUPPLIER_PROFILE,
      ORDER_MODAL_TYPE.DATE_ORDER_LIMIT_AFTER_EDIT,
    ].includes(modalType)
  ) {
    return (
      <RenderDateErrorModal
        closeOrderDateModal={() =>
          modalType !== ORDER_MODAL_TYPE.DATE_ORDER_LIMIT_AFTER_EDIT
            ? closeErrorModal()
            : setModalType(null)
        }
        dateErrorType={modalType}
        maxOrderDate={maxOrderDate}
        orderTimeLimit={orderTimeLimit}
        startDate={startDate}
        timezone={timezone}
      />
    );
  }

  if (modalType === ORDER_MODAL_TYPE.STOCK_REASSEMBLY_NOT_DONE) {
    return (
      <RenderStockReassemblyNotDoneErrorModal
        closeStockReassemblyNotDoneModal={closeErrorModal}
        goInventoriesPage={() => {
          history.push('/stocks/inventories');

          setModalType(null);

          verifiedClose();
        }}
      />
    );
  }

  if (modalType === ORDER_MODAL_TYPE.FORECAST_NOT_CONFIRMED) {
    return (
      <RenderForecastNotConfirmedErrorModal
        closeForecastNotFoundModal={closeErrorModal}
        endDate={endDate}
        goForecastPage={() => {
          history.push(TURNOVER_FORECAST.path);

          setModalType(null);

          verifiedClose();
        }}
        startDate={startDate}
      />
    );
  }

  return null;
}

ModalManager.propTypes = {
  modalType: PropTypes.oneOf(Object.values(ORDER_MODAL_TYPE)).isRequired,
  setModalType: PropTypes.func.isRequired,
  verifiedClose: PropTypes.func.isRequired,
};

ModalManager.defaultProps = {};

export default ModalManager;
