import { parseStringToFloat } from './parseStringToFloat';

/**
 * Get price product from its price HT and the VAT applied on it
 *
 * @param {Product} product - Product that to process
 *
 * @returns {Number} The price TTC
 */
export function getPriceIncludingVAT(product) {
  if (!product) {
    return null;
  }

  const vatRate = parseStringToFloat(product.vatRate);
  const priceHT = parseStringToFloat(product.priceHT);

  return priceHT * (1 + vatRate / 100);
}

export default {
  getPriceIncludingVAT,
};
