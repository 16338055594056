import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

import { getTheme } from '@commons/utils/theme';
import { Label } from '@commons/utils/styledLibraryComponents';
import DisplayNumber from '@commons/DisplayNumber';

import { SentAtContainer, LabelContainer } from '../styledComponents';

const getLabelProps = (hasBeenReceived, theme) => {
  if (!!hasBeenReceived) {
    return {
      type: 'secondary',
      background: theme.colors.infoGreen,
      color: theme.colors.infoGreen,
    };
  }

  return {
    type: 'secondary',
    background: theme.colors.greys.darkest,
    color: theme.colors.greys.darkest,
  };
};

export const getColumns = (clientStoreNameTranslation) => {
  const theme = getTheme();
  return [
    {
      id: 'senderStoreName',
      name: i18next.t('STOCKS.TRANSFERS.SENDER_STORE', {
        storeName: clientStoreNameTranslation,
      }),
      propertyKey: 'senderStoreName',
      render: (it) => <div>{it}</div>,
    },
    {
      id: 'recipientStoreName',
      name: i18next.t('STOCKS.TRANSFERS.RECIPIENT_STORE', {
        storeName: clientStoreNameTranslation,
      }),
      propertyKey: 'recipientStoreName',
      render: (it) => <div>{it}</div>,
    },
    {
      id: 'sentAt',
      name: i18next.t('STOCKS.TRANSFERS.SENDING_DATE'),
      propertyKey: 'sentAt',
      render: (it, element) => (
        <SentAtContainer>
          {moment.tz(it, element.senderStore.timezone).format('DD/MM/YYYY')}
        </SentAtContainer>
      ),
      excludeFromSearch: true,
      filterType: 'date',
    },
    {
      id: 'status',
      name: i18next.t('STOCKS.TRANSFERS.SENDING_STATUS'),
      propertyKey: 'hasBeenReceived',
      filterType: 'string',
      list: [
        {
          id: 'status-validated',
          name: i18next.t('STOCKS.TRANSFERS.SENDING_STATUS_VALIDATED'),
          hasBeenReceived: true,
        },
        {
          id: 'status-sent',
          name: i18next.t('STOCKS.TRANSFERS.SENDING_STATUS_SENT'),
          hasBeenReceived: false,
        },
      ],
      render: (it) => {
        const labelProps = getLabelProps(it, theme);

        return (
          <LabelContainer>
            <Label {...labelProps}>
              {!!it
                ? i18next.t('STOCKS.TRANSFERS.SENDING_STATUS_VALIDATED')
                : i18next.t('STOCKS.TRANSFERS.SENDING_STATUS_SENT')}
            </Label>
          </LabelContainer>
        );
      },
      excludeFromSearch: true,
    },
    {
      id: 'totalPrice',
      name: i18next.t('STOCKS.TRANSFERS.TOTAL_EXCLUDING_TAXES'),
      propertyKey: 'totalPrice',
      render: (item) => <DisplayNumber displayCurrencyCode={true} number={item} />,
      excludeFromSearch: true,
      filterType: 'numeric',
    },
    {
      id: 'reference',
      name: 'reference',
      propertyKey: 'reference',
      render: () => {},
      hidden: true,
    },
  ];
};
