import { APIcaller } from './APIcaller';

const ALL_ENTITY_AND_SP_IDS = 'allEntityAndSpIds';

const getStorageAreasByClientId = async (
  clientId,
  {
    withAllEntityAndSpIds = false,
    withoutMappings = false,
    getAllStorageAreas = false,
    filterByUserCatalog = false,
  } = {},
) => {
  const storageAreas = await APIcaller.apiGet(`/admin/storage-area/client/${clientId}`, {
    withoutMappings,
    getAllStorageAreas,
    filterByUserCatalog,
  });

  return withAllEntityAndSpIds
    ? storageAreas
    : storageAreas.filter(({ id }) => id !== ALL_ENTITY_AND_SP_IDS);
};

const disableStorageAreas = async (clientId, storageAreaIds) =>
  APIcaller.apiDelete(`/admin/storage-area/client/${clientId}`, { storageAreaIds });

const assignSupplierProductsToStorageAreas = async (clientId, supplierProductIds, storageAreaIds) =>
  APIcaller.apiPost(`/admin/storage-area/client/${clientId}/mappings`, {
    supplierProductIds,
    storageAreaIds,
  });

const getSPIdsMappedToStorageAreas = async (clientId, storageAreaIds) =>
  APIcaller.apiPost(`/admin/storage-area/client/${clientId}/mapped-supplier-products`, {
    storageAreaIds,
  });

const getStorageAreaById = async (clientId, storageAreaId) =>
  APIcaller.apiGet(`/admin/storage-area/client/${clientId}/storage-area/${storageAreaId}`);

const createStorageArea = async (clientId, name, supplierProductIds = [], entityIds = []) =>
  APIcaller.apiPost(`/admin/storage-area/client/${clientId}`, {
    name,
    supplierProductIds,
    entityIds,
  });

const updateStorageArea = async (clientId, storageAreaId, name, supplierProductIds, entityIds) =>
  APIcaller.apiPatch(`/admin/storage-area/client/${clientId}/update/${storageAreaId}`, {
    name,
    supplierProductIds,
    entityIds,
  });

const getStorageAreasBySupplierProductAndEntityIds = async (
  clientId,
  supplierProductIds = [],
  entityIds = [],
) =>
  APIcaller.apiPost(`/admin/storage-area/client/${clientId}/storage-areas`, {
    supplierProductIds,
    entityIds,
  });

export default {
  getStorageAreasByClientId,
  disableStorageAreas,
  assignSupplierProductsToStorageAreas,
  getSPIdsMappedToStorageAreas,
  getStorageAreaById,
  createStorageArea,
  updateStorageArea,
  getStorageAreasBySupplierProductAndEntityIds,
};
