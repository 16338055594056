import { isEmpty } from 'lodash';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

import { upload as uploadService } from '@services/upload';

export const uploadPicture = async (clientId, name, selectedPictureFile) => {
  const params = {
    clientId,
    /* 
        Add timestamp to avoid delay between two changes because if the pictures are different and are uploaded in a short time span
        the CDN might not upload the second one as they have the same name
    */
    productId: `${name.replaceAll(' ', '_')}_${moment().format(
      DATE_DISPLAY_FORMATS.UNDERSCORED_MONTH_DAY_HOUR_MINUTE_SECOND,
    )}`,
  };

  const uploadedPicture = await uploadService.uploadFile(selectedPictureFile, params);

  return !isEmpty(uploadedPicture) ? uploadedPicture.data.fileUri : null;
};
