import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${(props) =>
    css`
      padding: 24px;

      flex: 3;

      background-color: ${props.theme.colors.greys.lightest};
      border-radius: 16px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    `}
`;

export const Title = styled.div`
  ${(props) =>
    css`
      color: ${props.theme.colors.greys.darkest};
      font: normal 700 16px/24px inter;
      text-transform: capitalize;
    `}
`;

export const FormContainer = styled.div`
  width: 100%;
`;

export const Section = styled.div`
  width: 100%;
  display: flex;

  &:first-child {
    margin-top: 25px;
  }
`;

export const SectionInfo = styled.div`
  max-width: 30%;
  margin-right: 25px;
  margin-bottom: 25px;

  flex: 1;

  &:last-child {
    margin-right: 0px;
  }
`;

export const DropdownItem = styled.div`
  width: 100%;
  padding: 12px 8px;
  display: flex;
`;

export const DropdownIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  margin-top: 6px;
`;

export const Text = styled.div`
  font: ${(props) => props.theme.fonts?.textBig};
  color: ${(props) => props.theme.colors?.greys?.darkest};
  ${(props) => {
    if (props.bold) {
      return css`
        font: ${(props) => props.theme.fonts?.textMiddleBold};
      `;
    }
  }}
`;
