import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const SubContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  background: #ffffff;
`;

export const Title = styled.div`
  padding: 30px 25px;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;

  color: #000000;
`;

export const Content = styled.div`
  padding: 80px 50px 95px 50px;
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 14px;

  color: #000000;
`;

export const Hbar = styled.div`
  position: realtive;
  width: 100%;
  border: 0.8px solid #e1e1e1;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  padding: 15px 15px;
  justify-content: end;
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
`;
