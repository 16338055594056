import { keyBy } from 'lodash';
import { useState, useEffect, useContext } from 'react';

import { getLocalStorageItem } from '@commons/utils/localStorage';

import { FiltersContext } from '@context/FiltersContext';

import { ITEM_DROPDOWN_NONE_VALUE } from '@admin/utils/DropdownItems';

import {
  getBrandsByClientId,
  getLocationsByAccountId,
  getRetailersByClientId,
  getGroupsByStoreIds,
} from '../services';

export const ENUM_STORES_FILTERS_NAME = {
  BRANDS: 'custom-brands',
  STORES: 'custom-stores',
  GROUPS: 'custom-groups',
  RETAILERS: 'custom-retailers',
  LOCATIONS: 'custom-locations',
};

const filterStoresWithSelectedItems = (
  stores,
  selectedBrands = [],
  selectedRetailers = [],
  selectedLocations = [],
  selectedGroups = [],
) => {
  const selectedBrandsKeyById = keyBy(selectedBrands, 'id');
  const selectedRetailersKeyById = keyBy(selectedRetailers, 'id');
  const selectedLocationsKeyById = keyBy(selectedLocations, 'id');

  return stores.filter(({ id, brandId, retailerId, locationId }) => {
    let brandIdMatches = true; // default true as selected brands could be empty
    let retailerIdMatches = true; // default true as selected retailers could be empty
    let locationIdMatches = true; // default true as selected locations could be empty
    let groupIdMatches = true; // default true as selected groups could be empty

    if (selectedBrands.length) {
      const value = brandId === null ? -1 : brandId;

      brandIdMatches = !!selectedBrandsKeyById[value];
    }

    if (selectedRetailers.length) {
      retailerIdMatches = !!selectedRetailersKeyById[retailerId];
    }

    if (selectedLocations.length) {
      locationIdMatches = !!selectedLocationsKeyById[locationId];
    }

    if (selectedGroups.length) {
      const groupStoreIds = selectedGroups.reduce(
        (storeIds, group) => storeIds.concat(group.storeIds),
        [],
      );

      groupIdMatches = groupStoreIds.includes(id);
    }

    return groupIdMatches && brandIdMatches && retailerIdMatches && locationIdMatches;
  });
};

const _buildDropdownFilters = ({
  // stores
  stores = [],
  filteredStores = [],
  selectedStores = [],
  // brands
  brands = [],
  selectedBrands = [],
  originalSelectedBrands,
  // retailers
  retailers = [],
  selectedRetailers = [],
  originalSelectedRetailers,
  // locations
  locations = [],
  selectedLocations = [],
  originalSelectedLocations,
  // groups
  groups = [],
  selectedGroups = [],
  originalSelectedGroups,
  // config
  useBrandsFilter = false,
  onChangeSelection,
}) => {
  const filters = {};

  if (useBrandsFilter && brands.length) {
    filters[ENUM_STORES_FILTERS_NAME.BRANDS] = {
      id: ENUM_STORES_FILTERS_NAME.BRANDS,
      icon: !selectedBrands.length
        ? '/images/inpulse/brand-dmgrey-small.svg'
        : '/images/inpulse/brand-black-small.svg',
      list: brands,
      defaultSelectedItems: brands,
      originalSelectedItems: originalSelectedBrands || selectedBrands,
      selectedItems: selectedBrands,
      setSelectedItems: (...parameters) =>
        onChangeSelection(ENUM_STORES_FILTERS_NAME.BRANDS, ...parameters),
      forceStateUpdateTrigger: true, // force selected items call to be able to filters Stores list content accordingly
    };
  }

  if (locations.length) {
    filters[ENUM_STORES_FILTERS_NAME.LOCATIONS] = {
      id: ENUM_STORES_FILTERS_NAME.LOCATIONS,
      icon: !selectedLocations.length
        ? '/images/inpulse/location-dmgrey-small.svg'
        : '/images/inpulse/location-black-small.svg',
      list: locations,
      defaultSelectedItems: locations,
      originalSelectedItems: originalSelectedLocations || selectedLocations,
      selectedItems: selectedLocations,
      setSelectedItems: (...parameters) =>
        onChangeSelection(ENUM_STORES_FILTERS_NAME.LOCATIONS, ...parameters),
      forceStateUpdateTrigger: true, // force selected items call to be able to filters Stores list content accordingly
    };
  }

  if (retailers.length) {
    filters[ENUM_STORES_FILTERS_NAME.RETAILERS] = {
      id: ENUM_STORES_FILTERS_NAME.RETAILERS,
      icon: !selectedRetailers.length
        ? '/images/inpulse/retailer-dmgrey-small.svg'
        : '/images/inpulse/retailer-black-small.svg',
      list: retailers,
      defaultSelectedItems: retailers,
      originalSelectedItems: originalSelectedRetailers || selectedRetailers,
      selectedItems: selectedRetailers,
      setSelectedItems: (...parameters) =>
        onChangeSelection(ENUM_STORES_FILTERS_NAME.RETAILERS, ...parameters),
      forceStateUpdateTrigger: true, // force selected items call to be able to filters Stores list content accordingly
    };
  }

  if (groups.length) {
    filters[ENUM_STORES_FILTERS_NAME.GROUPS] = {
      id: ENUM_STORES_FILTERS_NAME.GROUPS,
      icon: !selectedGroups.length
        ? '/images/inpulse/group-dmgrey-small.svg'
        : '/images/inpulse/group-black-small.svg',
      list: groups,
      defaultSelectedItems: groups,
      originalSelectedItems: originalSelectedGroups || selectedGroups,
      selectedItems: selectedGroups,
      setSelectedItems: (...parameters) =>
        onChangeSelection(ENUM_STORES_FILTERS_NAME.GROUPS, ...parameters),
      forceStateUpdateTrigger: true, // force selected items call to be able to filters Stores list content accordingly
    };
  }

  filters[ENUM_STORES_FILTERS_NAME.STORES] = {
    id: ENUM_STORES_FILTERS_NAME.STORES,
    icon: !selectedStores.length
      ? '/images/inpulse/pin-dmgrey-small.svg'
      : '/images/inpulse/pin-black-small.svg',
    list: filteredStores,
    defaultSelectedItems: stores,
    originalSelectedItems: selectedStores,
    selectedItems: selectedStores,
    setSelectedItems: (...parameters) =>
      onChangeSelection(ENUM_STORES_FILTERS_NAME.STORES, ...parameters),
  };

  return filters;
};

export const useStoresFilter = (props) => {
  const { clientId, useBrandsFilter, showErrorMessage } = props;

  const { filtersAnalyticsProduction, setFiltersAnalyticsProduction } = useContext(FiltersContext);

  const [isLoading, setIsLoading] = useState(true); // general state to know whether brands / groups / locations / retailers have been loaded

  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [originalSelectedBrands, setOriginalSelectedBrands] = useState(null);

  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [originalSelectedGroups, setOriginalSelectedGroups] = useState(null);

  const [retailers, setRetailers] = useState([]);
  const [selectedRetailers, setSelectedRetailers] = useState([]);
  const [originalSelectedRetailers, setOriginalSelectedRetailers] = useState(null);

  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [originalSelectedLocations, setOriginalSelectedLocations] = useState(null);

  const [stores, setStores] = useState([]); // backup of all stores to iterate on when filtering on matching selected brands / groups / locations / retailers
  const [filteredStores, setFilteredStores] = useState([]); // used for dropdown choices after filtering on matching selected brands / groups / locations / retailers
  const [selectedStores, setSelectedStores] = useState([]); // selected list of stores

  // Handle logic when dropdown selection has changed
  const onChangeSelection = (filterType, items) => {
    if (filterType === ENUM_STORES_FILTERS_NAME.BRANDS) {
      setOriginalSelectedBrands(selectedBrands);
      setSelectedBrands(items);
    }

    if (filterType === ENUM_STORES_FILTERS_NAME.RETAILERS) {
      setOriginalSelectedRetailers(selectedRetailers);
      setSelectedRetailers(items);
    }

    if (filterType === ENUM_STORES_FILTERS_NAME.LOCATIONS) {
      setOriginalSelectedLocations(selectedLocations);
      setSelectedLocations(items);
    }

    if (filterType === ENUM_STORES_FILTERS_NAME.GROUPS) {
      setOriginalSelectedGroups(selectedGroups);
      setSelectedGroups(items);
    }

    if (filterType === ENUM_STORES_FILTERS_NAME.STORES) {
      setSelectedStores(items);
    }
  };

  const setItemsFromFilterContext = () => {
    if (!filtersAnalyticsProduction) {
      return;
    }

    const groupFilter = filtersAnalyticsProduction[ENUM_STORES_FILTERS_NAME.GROUPS] || {};

    setGroups(groupFilter.list || []);
    setSelectedGroups(groupFilter.selectedItems || []);

    const retailerFilter = filtersAnalyticsProduction[ENUM_STORES_FILTERS_NAME.RETAILERS] || {};

    setRetailers(retailerFilter.list || []);
    setSelectedRetailers(retailerFilter.selectedItems || []);

    const locationFilter = filtersAnalyticsProduction[ENUM_STORES_FILTERS_NAME.LOCATIONS] || {};

    setLocations(locationFilter.list || []);
    setSelectedLocations(locationFilter.selectedItems || []);

    const brandFilter = filtersAnalyticsProduction[ENUM_STORES_FILTERS_NAME.BRANDS] || {};

    setBrands(brandFilter.list || []);
    setSelectedBrands(brandFilter.selectedItems || []);

    const storeFilter = filtersAnalyticsProduction[ENUM_STORES_FILTERS_NAME.STORES] || {};

    setStores(props.stores);
    setSelectedStores(storeFilter.selectedItems || []);
  };

  const propsFiltersBuilder = {
    stores,
    filteredStores,
    selectedStores,
    brands,
    selectedBrands,
    originalSelectedBrands,
    groups,
    selectedGroups,
    originalSelectedGroups,
    retailers,
    selectedRetailers,
    originalSelectedRetailers,
    locations,
    selectedLocations,
    originalSelectedLocations,
    onChangeSelection,
    useBrandsFilter,
  };

  const filters = _buildDropdownFilters(propsFiltersBuilder);

  // Retrieve data brands / groups / locations / retailers
  useEffect(() => {
    if (!isLoading || !clientId) {
      return;
    }

    if (filtersAnalyticsProduction) {
      setItemsFromFilterContext();

      setIsLoading(false);

      return;
    }

    (async function loadData() {
      // Handle groups fetch
      const storeIds = props.stores.map(({ id }) => id);
      const fetchedGroups = await getGroupsByStoreIds(storeIds, showErrorMessage);
      setGroups(fetchedGroups);
      setSelectedGroups(fetchedGroups);

      // Handle retailers fetch
      const fetchedRetailers = await getRetailersByClientId(clientId, showErrorMessage);
      setRetailers(fetchedRetailers);
      setSelectedRetailers(fetchedRetailers);

      // Handle locations fetch
      const userId = await getLocalStorageItem('userId');
      const fetchedLocations = await getLocationsByAccountId(userId, showErrorMessage);
      setLocations(fetchedLocations);
      setSelectedLocations(fetchedLocations);

      // Handle brands fetch
      if (useBrandsFilter) {
        const fetchedBrands = await getBrandsByClientId(clientId, showErrorMessage);

        const brandsWithNoneOption = [ITEM_DROPDOWN_NONE_VALUE, ...fetchedBrands];

        setBrands(brandsWithNoneOption);
        setSelectedBrands(brandsWithNoneOption);
      }

      setStores(props.stores);
      setSelectedStores(props.stores);

      setIsLoading(false);
    })();
  }, [clientId]);

  // When selection changes on brands / groups / locations / retailers, make sure to apply changes on stores states
  useEffect(() => {
    if (isLoading) {
      return;
    }

    const matchingStores = filterStoresWithSelectedItems(
      stores,
      selectedBrands,
      selectedRetailers,
      selectedLocations,
      selectedGroups,
    );

    setFilteredStores(matchingStores);
  }, [isLoading, stores, selectedBrands, selectedRetailers, selectedLocations, selectedGroups]);

  // Make sure to update production filters when products dropdown content comes to change
  // NOTE: necessary as filter component does not call setFilters method when customMultipleDropdowns content changes
  useEffect(() => {
    if (isLoading || !filtersAnalyticsProduction) {
      return;
    }

    const updatedFilters = {
      ...filtersAnalyticsProduction,
      ..._buildDropdownFilters({
        ...propsFiltersBuilder,
        selectedStores: filteredStores, // We want new list of stores to be all selected
      }),
    };

    setFiltersAnalyticsProduction(updatedFilters);
  }, [filteredStores]);

  return {
    // state to notify consumer component
    isLoading,
    // Stores selection related states
    selectedStores,
    // Filters customMultipleDropdown purpose
    filtersKeyById: filters,
  };
};
