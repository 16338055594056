import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  padding: 88px 264px 0px 264px;

  display: flex;
  flex-direction: column;

  align-items: center;

  overflow: auto;
`;
