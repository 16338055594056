import { get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React from 'react';

import { ENUM_ROLES_NAME } from '@commons/utils/roles';
import { InpulseLabel } from '@commons/DeepsightComponents';

import { Icon, LabelContainer, Text } from '../styledComponents';

import { ACCOUNT_STATUS } from './status';

export const getColumnsSettings = ({
  roles,
  isUserPath,
  clientStoreNameTranslation,
  isInpulseUser,
  hasCentralKitchens = false,
  hasLocalCatalogs = false,
}) => [
  {
    id: 'name',
    propertyKey: 'name',
    name: i18next.t('USERS.DETAILS.PROFILE_LASTNAME_INPUT_LABEL'),
    render: (item) => <Text highlighted>{item}</Text>,
  },
  {
    id: 'email',
    propertyKey: 'email',
    name: i18next.t('USERS.DETAILS.PROFILE_EMAIL_INPUT_LABEL'),
    displayBigger: true,
  },
  {
    id: 'role',
    propertyKey: 'role',
    excludeFromSearch: true,
    name: i18next.t('USERS.DETAILS.PROFILE_ROLE_INPUT_LABEL'),
    filterType: 'string',
    list: Object.values(
      roles.reduce((result, role, key) => {
        if (role.name === 'inpulse') {
          return result;
        }

        if (
          role.name &&
          !result[role.name] &&
          (!isUserPath || role.name !== ENUM_ROLES_NAME.API_CONSUMER)
        ) {
          result[role.name] = {
            id: key,
            name: role.name,
            status: role.name,
            value: role.name,
            role: role.name,
          };
        }

        return result;
      }, {}),
    ),
    render: (item) => <Text>{item}</Text>,
  },
  ...(hasLocalCatalogs
    ? [
        {
          id: 'catalog',
          propertyKey: 'catalog',
          name: i18next.t('USERS.DETAILS.PROFILE_CATALOG_INPUT_LABEL'),
          render: (item) => <Text>{item}</Text>,
        },
      ]
    : []),
  {
    id: 'storesCount',
    propertyKey: 'storesCount',
    name: clientStoreNameTranslation,
    excludeFromSearch: true,
    render: (item) => (
      <LabelContainer>
        <InpulseLabel text={String(item)} />
      </LabelContainer>
    ),
  },
  ...(hasCentralKitchens
    ? [
        {
          id: 'kitchensCount',
          propertyKey: 'kitchensCount',
          name: i18next.t('GENERAL.CENTRALS'),
          excludeFromSearch: true,
          render: (item) => (
            <LabelContainer>
              <InpulseLabel text={String(item)} />
            </LabelContainer>
          ),
        },
      ]
    : []),
  {
    id: 'status',
    propertyKey: 'status',
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_STATUS'),
    excludeFromSearch: true,
    tooltipText: i18next.t('BACKOFFICE.TEAM.STATUS_COLUMN_TOOLTIP'),
    filterType: 'string',
    list: Object.values(ACCOUNT_STATUS).reduce((result, status, key) => {
      result.push({
        id: key,
        name: i18next.t(status),
        status: status,
        itemValue: status,
      });

      return result;
    }, []),
    render: (status, row) => {
      let color = 'black';
      let tooltipContent = '';

      switch (status) {
        case 'BACKOFFICE.TEAM.ONBOARDING_STATUS_INVITED':
          color = 'orange';
          tooltipContent = i18next.t('BACKOFFICE.TEAM.ONBOARDING_INVITED_AT', {
            date: moment(row.invitationLinkSentDate).format('DD/MM/YYYY'),
          });
          break;
        case 'BACKOFFICE.TEAM.ONBOARDING_STATUS_INVITATION_EXPIRED':
          color = 'orange';
          tooltipContent = i18next.t('BACKOFFICE.TEAM.ONBOARDING_EXPIRED_AT', {
            date: moment(row.invitationLinkExpiredDate).format('DD/MM/YYYY'),
          });
          break;
        case 'BACKOFFICE.TEAM.ONBOARDING_STATUS_REGISTERED':
          color = 'green';
          tooltipContent = i18next.t('BACKOFFICE.TEAM.ONBOARDING_REGISTERED_AT', {
            date: moment(row.registrationDate).format('DD/MM/YYYY'),
          });
          break;
        default:
          color = 'black';
          tooltipContent = i18next.t('BACKOFFICE.TEAM.ONBOARDING_CREATED_AT', {
            date: moment(row.creationDate).format('DD/MM/YYYY'),
          });
      }

      return (
        <LabelContainer>
          <InpulseLabel
            color={color}
            text={!!status && String(i18next.t(status))}
            tooltipContent={tooltipContent}
          />
        </LabelContainer>
      );
    },
  },
  {
    id: 'hasForecasts',
    propertyKey: 'hasForecasts',
    name: i18next.t('ORDERS.ORDERS.MODAL_ERROR_BUTTON_FORECASTS'),
    excludeFromSearch: true,
    tooltipText: i18next.t('BACKOFFICE.TEAM.FORECASTS_COLUMN_TOOLTIP'),
    hidden: !isInpulseUser,
    render: (item) => (
      <LabelContainer>
        <InpulseLabel
          color={item ? 'green' : 'red'}
          text={item ? i18next.t('GENERAL.YES') : i18next.t('GENERAL.NO')}
        />
      </LabelContainer>
    ),
  },
  {
    id: 'hasPaymentBanner',
    propertyKey: 'hasPaymentBanner',
    name: i18next.t('BACKOFFICE.TEAM.PAYMENT_BANNER_LABEL'),
    hidden: !isInpulseUser || isUserPath,
    render: (item) => (
      <LabelContainer>
        <InpulseLabel
          color={item ? 'green' : 'red'}
          text={item ? i18next.t('GENERAL.YES') : i18next.t('GENERAL.NO')}
        />
      </LabelContainer>
    ),
  },
  {
    id: 'language',
    propertyKey: 'languageName',
    name: i18next.t('USERS.DETAILS.PROFILE_LANGUAGE_INPUT_LABEL'),
    render: (item, row) => {
      const languageCode = get(row, 'language.code', 'fr');

      return (
        <LabelContainer>
          <Icon src={`/images/flags/icon-flag-${languageCode}.svg`} />
          <Text>{item}</Text>
        </LabelContainer>
      );
    },
  },
];

export default {
  getColumnsSettings,
};
