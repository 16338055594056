import { supplierProduct as supplierProductService } from '../services/supplierProduct';

export const receiveSupplierProducts = (supplierProducts) => ({
  type: 'SUPPLIER_PRODUCTS_SUCCESS',
  supplierProducts,
});

export const requestSupplierProductsError = (error) => ({
  type: 'SUPPLIER_PRODUCTS_INVENTORIES_FAILURE',
  error,
});

export const getSupplierProductsOfSuppliers = (suppliers, skip, limit, where) =>
  function (dispatch) {
    dispatch({
      type: 'SUPPLIER_PRODUCTS_REQUEST',
    });
    return supplierProductService.getSupplierProductsOfSuppliers(suppliers, skip, limit, where);
  };

// Single supplierProduct
export const receiveSingleSupplierProduct = (success) => ({
  type: 'SINGLE_SUPPLIER_PRODUCT_SUCCESS',
  success,
});

export const requestSingleSupplierProductError = (error) => ({
  type: 'SINGLE_SUPPLIER_PRODUCT_FAILURE',
  error,
});

export const getSingleSupplierProduct = (supplierProductId) =>
  function (dispatch) {
    dispatch({
      type: 'SINGLE_SUPPLIER_PRODUCT_REQUEST',
    });
    return supplierProductService.getSingleSupplierProduct(supplierProductId);
  };

// CHANGE SUPPLIER STATUS TO ACTIVE
export const receiveSupplierProductsActive = (success) => ({
  type: 'PATCH_SUPPLIER_PRODUCTS_ACTIVE_SUCCESS',
  success,
});

export const requestSupplierProductsActiveError = (error) => ({
  type: 'PATCH_SUPPLIER_PRODUCTS_ACTIVE_FAILURE',
  error,
});

export const setSupplierProductsToActive = (supplierProductIds, storeSupplierProductMappings) =>
  function (dispatch) {
    dispatch({
      type: 'PATCH_SUPPLIER_PRODUCTS_ACTIVE_REQUEST',
    });
    return supplierProductService.setSupplierProductsToActive(
      supplierProductIds,
      storeSupplierProductMappings,
    );
  };

// CHANGE SUPPLIER STATUS TO INACTIVE
export const receiveSupplierProductsInactive = (success) => ({
  type: 'PATCH_SUPPLIER_PRODUCTS_INACTIVE_SUCCESS',
  success,
});

export const requestSupplierProductsInactiveError = (error) => ({
  type: 'PATCH_SUPPLIER_PRODUCTS_INACTIVE_FAILURE',
  error,
});

export const setSupplierProductsToInactive = (supplierProductIds) =>
  function (dispatch) {
    dispatch({
      type: 'PATCH_SUPPLIER_PRODUCTS_INACTIVE_REQUEST',
    });
    return supplierProductService.setSupplierProductsToInactive(supplierProductIds);
  };

// create supplierProduct
export const createSupplierProductSuccess = (success) => ({
  type: 'CREATE_SUPPLIER_PRODUCT_SUCCESS',
  success,
});

export const createSupplierProductError = (error) => ({
  type: 'CREATE_SUPPLIER_PRODUCT_FAILURE',
  error,
});

export const createSupplierProduct = (supplierProduct, mappings, customPrices) =>
  function (dispatch) {
    dispatch({
      type: 'CREATE_SUPPLIER_PRODUCT_REQUEST',
    });
    return supplierProductService.createSupplierProduct(supplierProduct, mappings, customPrices);
  };

// Patch supplierProduct
export const patchSupplierProductByIdSuccess = (success) => ({
  type: 'PATCH_SUPPLIER_PRODUCT_SUCCESS',
  success,
});

export const patchSupplierProductByIdError = (error) => ({
  type: 'PATCH_SUPPLIER_PRODUCT_FAILURE',
  error,
});

export const patchSupplierProductById = (id, updatedSupplierProduct, mappings, customPrices) =>
  function (dispatch) {
    dispatch({
      type: 'PATCH_SUPPLIER_PRODUCT_REQUEST',
    });
    return supplierProductService.patchSupplierProductById(
      id,
      updatedSupplierProduct,
      mappings,
      customPrices,
    );
  };

export const receivePatchMultipleSupplierProducts = (supplierProducts) => ({
  type: 'PATCH_MANY_SUPPLIER_PRODUCTS_SUCCESS',
  supplierProducts,
});

export const requestPatchMultipleSupplierProductsError = (error) => ({
  type: 'PATCH_MANY_SUPPLIER_PRODUCTS_FAILURE',
  error,
});

// Check if supplierProduct have data associated
export const checkSupplierProductWithData = (id) =>
  function (dispatch) {
    dispatch({
      type: 'CHECK_SUPPLIER_PRODUCT_DATA_REQUEST',
    });
    return supplierProductService.checkSupplierProductData(id);
  };

export const checkSupplierProductWithDataSuccess = (success) => ({
  type: 'CHECK_SUPPLIER_PRODUCT_DATA_SUCCESS',
  data: success,
});

export const checkSupplierProductWithDataFailure = (error) => ({
  type: 'CHECK_SUPPLIER_PRODUCT_DATA_FAILURE',
  error,
});
