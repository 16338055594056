import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`;

export const FiltersContainer = styled.div`
  width: 100%;
  padding: 0 24px;
`;

export const FlexedGapContainer = styled.div`
  display: flex;
  column-gap: 24px;
  width: 100%;
`;

export const TurnoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 120px;
`;

export const TurnoverLabel = styled.div`
  display: flex;
  flex-direction: row;
  color: ${(props) => props.theme.colors.blacks.ipBlack4};
  font: ${(props) => props.theme.fonts.textMiddleNormal};
`;

export const Kpi = styled.div`
  color: ${(props) => props.theme.colors.blacks.ipBlack1};
  font: ${(props) => props.theme.fonts.textMiddleBold};
`;

/** NestedList Rows */
export const ItemRowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 48px;
  align-items: center;
  column-gap: 16px;

  ${(props) =>
    props.borderTop &&
    css`
      border-top: 1px solid ${(props) => props.theme.colors.greys.lighty};
    `}

  ${(props) =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.greys.lighty};
    `}
`;

export const ItemRowContent = styled.div`
  display: flex;

  width: ${(props) => props.width || '160px'};

  ${(props) =>
    props.displayBigger &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    `}
`;

export const DisplayBiggerContainer = styled.div`
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
