import styled, { css } from 'styled-components';

export const ContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 80px);

  margin-top: 80px;
  padding-top: 24px;

  overflow-y: scroll;
  display: flex;
  justify-content: center;

  ${(props) =>
    props.isFooterDisplayed &&
    css`
      height: calc(100% - 128px);
    `}
`;

export const ButtonsContainer = styled.div`
  width: calc(100% - 64px);

  display: flex;
  justify-content: end;
  gap: 16px;
`;
