import { getTimezone } from 'countries-and-timezones';

export const formatToInpulseTimezone = (timezone) =>
  `${timezone.name} (UTC ${timezone.utcOffsetStr}; ${timezone.dstOffsetStr})`;

export const formatToInpulseFromString = (value) => {
  if (!value) {
    return null;
  }
  const timezoneData = getTimezone(value);

  return formatToInpulseTimezone(timezoneData);
};

export default { formatToInpulseTimezone, formatToInpulseFromString };
