import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { openSmallModal } from '@actions/modal';

import { Button } from '@commons/utils/styledLibraryComponents';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import WhiteCard from '@commons/WhiteCard';

import theme from '@theme';

import { Container, Content } from './styledComponents';
import CashierProductsExportModal from './CashierProductExportModal';

export const BackOfficeCashierProducts = (props) => {
  const { user, match, openModal } = props;

  const path = _.get(match, 'path');

  const openExportMostConsumedCashierProductsModal = () => {
    openModal({
      component: CashierProductsExportModal,
      customStyle: {
        marginTop: '10%',
        overflow: 'initial',
        borderRadius: '5px',
      },
      clientId: _.get(user, 'clientId'),
    });
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <Content>
        <WhiteCard
          content={i18next.t(
            'BACKOFFICE.PRODUCTS.CASHIER_PRODUCTS_GENERATE_MOST_CONSUMED_CASHIER_PRODUCTS_FIRST_SUBTITLE',
          )}
          contentColor={theme.colors?.greys.darker}
          renderContent={
            <Button
              buttonCustomStyle={{ width: 'fit-content' }}
              color={'blue'}
              fontSize={14}
              formatText={false}
              handleClick={openExportMostConsumedCashierProductsModal}
              icon={'/images/inpulse/file-download-white-small.svg'}
              label={i18next.t('BACKOFFICE.PRODUCTS.CASHIER_PRODUCTS_BUTTON_EXPORT')}
            />
          }
          title={i18next.t(
            'BACKOFFICE.PRODUCTS.CASHIER_PRODUCTS_GENERATE_MOST_CONSUMED_CASHIER_PRODUCTS_TITLE',
          )}
        />
      </Content>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  isSynchronizingCashierProducts: state.baseReducer.isSynchronizingCashierProducts,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (params) => {
    dispatch(openSmallModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeCashierProducts);
