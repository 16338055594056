import theme from '@theme';

const getStyleAndColor = (
  isInPeriod,
  isYesterday,
  date,
  periodStartDate,
  periodEndDate,
  isValidForecast,
  isYesterdayReal,
  value,
) => {
  if (isYesterday) {
    return {
      color: isYesterdayReal
        ? theme?.colors?.greys?.light
        : value > 0
        ? theme?.colors?.infoGreen
        : theme?.colors?.infoRed,
      weight: 400,
    };
  }
  if (isYesterday) {
    return {
      color: isYesterdayReal
        ? theme?.colors.greys.light
        : value > 0
        ? theme?.colors?.infoGreen
        : theme?.colors?.infoRed,
      weight: 400,
    };
  }
  if (isInPeriod && (date === periodStartDate || date === periodEndDate)) {
    return {
      color: isValidForecast ? theme?.colors?.infoGreen : theme?.colors?.infoRed,
      weight: 700,
    };
  }
  if (isInPeriod) {
    return {
      color: isValidForecast ? theme?.colors?.infoGreen : theme?.colors?.infoRed,
      weight: 400,
    };
  }

  return { color: value > 0 ? theme?.colors?.infoGreen : theme?.colors?.infoRed, weight: 400 };
};

export default getStyleAndColor;
