import React from 'react';

import PillsSet from '@commons/PillsSet';

import { Container } from './styledComponents';

const StorageAreaListing = (props) => {
  const { storageAreas, selectedStorageArea, setSelectedStorageArea } = props;

  return (
    <Container>
      <PillsSet
        items={storageAreas}
        selectedItem={selectedStorageArea || storageAreas[0]}
        setSelectedItem={setSelectedStorageArea}
      />
    </Container>
  );
};

export default StorageAreaListing;
