import Resizer from 'react-image-file-resizer';

const COMPRESSED_FILE_QUALITY = 70;
const COMPRESSED_FORMAT = 'JPEG';

const MAX_WIDTH = 1000;
const MAX_HEIGHT = 1000;

const ROTATION = 0;

const OUTPUT_TYPE = 'file';

const compress = async (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      MAX_WIDTH,
      MAX_HEIGHT,
      COMPRESSED_FORMAT,
      COMPRESSED_FILE_QUALITY,
      ROTATION,
      resolve,
      OUTPUT_TYPE,
    );
  });

const load = async (file) =>
  new Promise((resolve, reject) => {
    let fileCopy = file;

    fileCopy.path = file.name;

    const reader = new FileReader();

    reader.onerror = reject;

    reader.onload = () => {
      resolve({ data: reader.result, file });
    };

    reader.readAsDataURL(fileCopy);
  });

export default {
  load,
  compress,
};
