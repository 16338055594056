import PropTypes from 'prop-types';
import React from 'react';

import { ADMIN_FEATURE_PATHS } from '../../../commons/utils/featurePaths';
import { ENUM_MODULE_NAME } from '../../../commons/utils/features';

import { ModuleContainer } from '../../ModuleContainer';

export const AdminProductsContainer = (props) => {
  const { userRights, history } = props;
  return (
    <ModuleContainer
      history={history}
      moduleFeaturePaths={[
        ADMIN_FEATURE_PATHS.ADMIN_PRODUCTS_PRODUCTS,
        ADMIN_FEATURE_PATHS.ADMIN_PRODUCTS_RECIPES,
        ADMIN_FEATURE_PATHS.ADMIN_PRODUCTS_INGREDIENTS,
      ]}
      moduleName={ENUM_MODULE_NAME.ADMIN}
      userRights={userRights}
    />
  );
};

AdminProductsContainer.propTypes = {
  userRights: PropTypes.array,
  history: PropTypes.any, // react-router-dom's route prop
  moduleName: PropTypes.oneOf(Object.values(ENUM_MODULE_NAME)),
  moduleFeaturePaths: PropTypes.arrayOf(PropTypes.string),
};

// ===== Module features for easy import in ClientContainer =====

import ProductDetails from './products/detail';
import Products from './products';

import IngredientDetails from './ingredients/detail';
import Ingredients from './ingredients';

import RecipeDetails from './recipes/detail';
import Recipes from './recipes';

const AdminProductsModule = {
  AdminProductsContainer,
  Products,
  ProductDetails,
  Recipes,
  RecipeDetails,
  Ingredients,
  IngredientDetails,
  KitchenRecipeDetails: RecipeDetails,
};

export default AdminProductsModule;
