// ===== Module features for easy import in ClientContainer=====

import StocksCentralKitchenAnalyticsPastContainer from './StocksCentralKitchenAnalyticsPast';
import StocksCentralKitchenInventoriesContainer from './StocksCentralKitchenInventories';

const CentralKitchenStocksModule = {
  ...StocksCentralKitchenInventoriesContainer,
  ...StocksCentralKitchenAnalyticsPastContainer,
};

export default CentralKitchenStocksModule;
