import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  overflow: auto;

  position: relative;
`;

export const Content = styled.div`
  height: ${(props) => (props.extraPaddingTop ? 'calc(100% - 176px)' : 'calc(100% - 88px)')};

  padding: 0px 24px;
`;

export const FilterAndExportContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
