import styled from 'styled-components';

export const InputContainer = styled.div`
  height: 68px;

  display: flex;

  justify-content: flex-start;
  align-items: flex-end !important;
  padding-bottom: 12px;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  &:hover {
    cursor: pointer;
  }
`;

export const LinkImage = styled.img`
  width: 16px;
  height: 16px;
`;

export const TextContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 8px;

  justify-content: center;
  align-items: flex-start;
`;

export const CountsTextContainer = styled.div`
  height: 100%;
  width: 257px;

  display: flex;
  flex-direction: row;
  column-gap: 42px;

  justify-content: center;
  align-items: flex-start;
`;

export const LibeoInputContainer = styled.div`
  height: 100%;

  display: block;
`;
