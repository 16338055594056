import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

import {
  canReceiveOrderAndSendCreditRequest,
  canValidateCreditRequestWithoutMail,
} from '@selectors/actions/orderActions';
import { showErrorMessage } from '@actions/messageconfirmation';

import { Button, Tooltip } from '@commons/utils/styledLibraryComponents';
import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { getTheme } from '@commons/utils/theme';
import InputEmailChip from '@lib/inpulse/InputEmailChip';
import WhiteCard from '@commons/WhiteCard';
import WysiwygEditor from '@commons/WysiwygEditor';

import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import {
  ContainerTextArea,
  EmailContainer,
  OrderCartContainer,
  SubTitleWhiteCard,
  WhiteCardContainer,
} from '../../styledComponents';
import MultiplePictureUpload from '../OrderFormInfoPanel/components/MultiplePictureUpload';

import { ButtonsContainer, Title } from './styledComponents';

/**
 * When doing the reception of an order, if any anomalies are entered, the form shall first display
 * a summary of the credit request, along with emails/comment/picture inputs to set for the credit request
 * @param {*} props
 */
const OrderCreditForm = (props) => {
  const {
    htmlPreview,
    creditRequestData,
    setCreditRequestData,
    handleSaveDelivery,
    // from redux
    authorizedActions,
    // from dispatch
    showErrorMessage,
  } = props;

  const theme = getTheme();

  const { recipientEmails, copyEmails, comment } = creditRequestData;

  const pictureSelectedRef = useRef();
  const [displayPictureDropdown, setDisplayPictureDropdown] = useState(false);
  const [initialPictures, setInitialPictures] = useState([]);

  const readyToSend = !isEmpty(recipientEmails);

  useEffect(() => {
    if (creditRequestData.creditPicture.url) {
      setInitialPictures([creditRequestData.creditPicture.url]);
    }
  }, [creditRequestData.creditPicture.url]);

  const onDataChange = (key, value) => {
    setCreditRequestData({ ...creditRequestData, [key]: value });
  };

  const onSelectedPictureChange = async (loadedPicture) => {
    const creditPicture = get(loadedPicture, '[0]', null);
    if (isEmpty(creditPicture)) {
      onDataChange('creditPicture', '');
      return;
    }

    onDataChange('creditPicture', creditPicture);
  };

  const handleSendCreditRequest = (withMail = true) => {
    handleSaveDelivery(false, withMail);
  };

  const customWithCardStyle = { border: `1px solid ${theme.colors.greys.light}` };

  return (
    <OrderCartContainer>
      <WhiteCardContainer width={'65%'}>
        <WhiteCard
          customStyle={customWithCardStyle}
          maxWidth={'inherit'}
          renderContent={
            <div>
              <Title>{i18next.t('ORDERS.ORDERS.CREDIT_TITLE')}</Title>
              {!!htmlPreview ? <div>{parse(htmlPreview)}</div> : <DeepsightComponentLoader />}
            </div>
          }
          width={'100%'}
        ></WhiteCard>
      </WhiteCardContainer>

      <WhiteCardContainer width={'35%'}>
        <WhiteCard
          customStyle={customWithCardStyle}
          renderContent={
            <>
              <Title>{i18next.t('ORDERS.ORDERS.CREDIT_EMAIL_TITLE')}</Title>
              <EmailContainer>
                <InputEmailChip
                  emails={recipientEmails}
                  isDisabled={!canReceiveOrderAndSendCreditRequest(authorizedActions)}
                  placeholder={i18next.t('ORDERS.ORDERS.CART_RECIPIENTS')}
                  required={true}
                  setEmails={(emails) => onDataChange('recipientEmails', emails)}
                  title={`${i18next.t('ORDERS.ORDERS.CART_RECIPIENTS')} :`}
                  width={'100%'}
                />
              </EmailContainer>
              <EmailContainer>
                <InputEmailChip
                  emails={copyEmails}
                  isDisabled={!canReceiveOrderAndSendCreditRequest(authorizedActions)}
                  placeholder={i18next.t('ORDERS.ORDERS.CART_RECIPIENTS_COPIES')}
                  setEmails={(emails) => onDataChange('copyEmails', emails)}
                  title={`${i18next.t('ORDERS.ORDERS.CART_RECIPIENTS_COPIES')} :`}
                  width={'100%'}
                />
              </EmailContainer>
              <ContainerTextArea>
                <SubTitleWhiteCard>{i18next.t('ORDERS.ORDERS.CART_COMMENT')}</SubTitleWhiteCard>
                <div style={{ height: '160px' }}>
                  <WysiwygEditor
                    content={comment}
                    isDisabled={!canReceiveOrderAndSendCreditRequest(authorizedActions)}
                    setter={(value) => onDataChange('comment', value)}
                  />
                </div>
              </ContainerTextArea>
              <div>
                <SubTitleWhiteCard>
                  {i18next.t('GENERAL.PHOTO')} :
                  <Tooltip
                    dataHtml={true}
                    text={i18next.t('ORDERS.ORDERS.FORM_SEND_CREDIT_REQUEST_ATTACHMENT_TOOLTIP')}
                  />
                </SubTitleWhiteCard>
                <MultiplePictureUpload
                  displayDropdownActions={displayPictureDropdown}
                  initialPictures={initialPictures}
                  multipleFiles={false}
                  ref={pictureSelectedRef}
                  setDisplayDropdownActions={setDisplayPictureDropdown}
                  showMessage={showErrorMessage}
                  onSelectionChange={onSelectedPictureChange}
                />
              </div>
              <ButtonsContainer>
                <Button
                  color={'inpulse-default'}
                  handleClick={() => handleSendCreditRequest(true)}
                  icon={'/images/inpulse/send-white-small.svg'}
                  isDisabled={
                    !readyToSend || !canReceiveOrderAndSendCreditRequest(authorizedActions)
                  }
                  label={i18next.t('GENERAL.SEND')}
                />
                <Button
                  color={'inpulse-outline'}
                  handleClick={() => handleSendCreditRequest(false)}
                  icon={'/images/inpulse/check-black-small.svg'}
                  isDisabled={!canValidateCreditRequestWithoutMail(authorizedActions)}
                  label={i18next.t('GENERAL.VALIDATE_WITHOUT_SENDING')}
                />
              </ButtonsContainer>
            </>
          }
          width={'100%'}
        />
      </WhiteCardContainer>
    </OrderCartContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/order/orders'),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderCreditForm);
