const getXlsHeaderSettings = (clientStoreName) => [
  {
    displayName: clientStoreName,
    keyName: 'storeName',
    transform: (value) => value,
  },
  {
    displayName: 'Fournisseur Inpulse',
    keyName: 'name',
    transform: (value) => value,
  },
  {
    displayName: 'SIREN Inpulse',
    keyName: 'siren',
    transform: (value) => value,
  },
  {
    displayName: 'Fournisseur Libeo',
    keyName: 'nameLibeo',
    transform: (value) => value,
  },
  {
    displayName: 'SIREN Libeo',
    keyName: 'sirenLibeo',
    transform: (value) => value,
  },
  {
    displayName: 'Intégration',
    keyName: 'status',
    transform: (value) => (value ? value : 'OK'),
  },
];

export default getXlsHeaderSettings;
