import i18next from 'i18next';
import React from 'react';

import { canSetupAssortment } from '@selectors/actions/storeActions';

import { Button } from '@commons/utils/styledLibraryComponents';

export const getActions = (
  selectedStoreProductMappings,
  data,
  handleExport,
  handleStoreProductMappingsUpdate,
  handleStoreProductMappingsDeletion,
  storeName,
  openAssociateProductsModal,
  authorizedActions,
  store,
  handleAssortmentSetup,
) => {
  const hasSelectedItems = selectedStoreProductMappings.length > 0;

  const actions = [
    {
      id: 'action-add',
      fixed: true,
      actionLabel: i18next.t('GENERAL.ADD'),
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={openAssociateProductsModal}
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('GENERAL.ADD')}
        />
      ),
    },
    {
      id: 'export',
      actionLabel: i18next.t(hasSelectedItems ? 'GENERAL.EXPORT_SELECTION' : 'GENERAL.EXPORT_LIST'),
      handleAction: () => handleExport(data),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
  ];

  if (!hasSelectedItems) {
    actions.splice(1, 0, {
      id: 'setup-assortment',
      actionLabel: i18next.t('ADMIN.STORES.SETUP_ASSORTMENT_FROM_ANOTHER_STORE', { storeName }),
      actionIcon: () => '/images/inpulse/settings.svg',
      handleAction: () => handleAssortmentSetup([store]),
      isHidden: () => !canSetupAssortment(authorizedActions),
    });

    return actions;
  }

  actions.splice(
    1,
    0,
    {
      id: 'update-selection',
      actionLabel: i18next.t('GENERAL.UPDATE_SELECTION'),
      actionIcon: () => '/images/inpulse/pen-black-small.svg',
      handleAction: handleStoreProductMappingsUpdate,
    },
    {
      id: 'remove-selection',
      actionLabel: i18next.t('GENERAL.REMOVE_SELECTION'),
      actionIcon: () => '/images/inpulse/remove-black-small.svg',
      handleAction: handleStoreProductMappingsDeletion,
    },
  );

  return actions;
};

export const getRowActions = (handleStoreProductMappingsDeletion) => [
  {
    id: 'remove-selection',
    actionLabel: () => i18next.t('ADMIN.STORES.REMOVE_PRODUCT_FROM_ASSORTMENT'),
    handleAction: (item) => handleStoreProductMappingsDeletion([item]),
    actionIcon: () => '/images/inpulse/remove-black-small.svg',
  },
];
