enum ColorPalette {
  Black = '#000000',
  DGrey = '#606060',
  DMGrey = '#979797',
  LMGrey = '#E2E2E2',
  LGrey = '#F8F8F8',
  WHITE = '#FFFFFF',

  IP_LGreen = '#D1FFE9',
  IP_Green = '#26E888',
  IP_DGreen = '#15C26D',

  IP_LBlue = '#CCF2F2',
  IP_Blue = '#27E7E2',
  IP_DBlue = '#17CFCA',

  Info_LOrange = '#FFF1D4',
  Info_Orange = '#FF9432',

  Info_LRed = '#FFE0E0',
  Info_Red = '#FA393E',

  Info_LGreen = '#BEFFE0',
  Info_Green = '#05E08E',

  IP_Black = '#1D1D1B',
}

export { ColorPalette as default };
