import ORDER_STATUS from '../../constants/status';
import React from 'react';

import HeaderButtons from './components/HeaderButtons';
import HeaderSelectors from './components/HeaderSelectors';

import { HeaderContainer } from './styledComponents';

import OrderInfo from '../OrderInfo';

const Header = (props) => {
  const headerSelectorsProps = {
    canBeModified: props.orderStatus === ORDER_STATUS.CREATION,
    deliveryDays: props.deliveryDays,
    deliveryLeadTimes: props.deliveryLeadTimes,
    displayCart: props.displayCart,
    endDate: props.endDate,
    firstPossibleDeliveryDatesByDeliveryDay: props.firstPossibleDeliveryDatesByDeliveryDay,
    handleReceptionComment: props.handleReceptionComment,
    handleReceptionReference: props.handleReceptionReference,
    handleSelectDates: props.handleSelectDates,
    handleSelectStore: props.handleSelectStore,
    handleSelectSupplier: props.handleSelectSupplier,
    infos: props.infos,
    isEditingDeliveryDate: props.isEditingDeliveryDate,
    orderDatesInPast: props.orderDatesInPast,
    orderStatus: props.orderStatus,
    orderTimeLimit: props.orderTimeLimit,
    params: props.params,
    receptionComment: props.receptionComment,
    receptionReference: props.receptionReference,
    startDate: props.startDate,
    storeName: props.storeName,
    stores: props.stores,
    supplierName: props.supplierName,
    suppliers: props.suppliers,
    supplierSelected: props.supplierSelected,
    timezone: props.timezone,
  };

  const orderInfoProps = {
    labelTopButton: props.labelTopButton,
    order: props.order,
    orderOpeningTime: props.orderOpeningTime,
    orderStatus: props.orderStatus,
    partnerId: props.partnerId,
    receptionDate: props.receptionDate,
  };

  const headerButtonsProps = {
    creditRequestData: props.creditRequestData,
    disableSendButton: props.disableSendButton,
    displayCart: props.displayCart,
    displayCreditForm: props.displayCreditForm,
    handleClickClose: props.handleClickClose,
    handleSave: props.handleSave,
    handleSaveDelivery: props.handleSaveDelivery,
    handleSaveDeliveryConfirmationModal: props.handleSaveDeliveryConfirmationModal,
    hasRoleAdminOrInpulse: props.hasRoleAdminOrInpulse,
    isConfirmable: props.isConfirmable,
    isGeneratingData: props.isGeneratingData,
    limitOrderTime: props.limitOrderTime,
    nbAnomalies: props.nbAnomalies,
    nbReferencesOrdered: props.nbReferencesOrdered,
    orderedUnit: props.orderedUnit,
    orderStatus: props.orderStatus,
    preparationStatus: props.preparationStatus,
    pictureConstraint: props.pictureConstraint,
    sending: props.sending,
    sendingParams: props.sendingParams,
    setDisplayCart: props.setDisplayCart,
    setDisplayCreditForm: props.setDisplayCreditForm,
    setDisplayValidateReception: props.setDisplayValidateReception,
    isEditingPreparationOrder: props.isEditingPreparationOrder,
    handlePreparationSave: props.handlePreparationSave,
    setDisplayDeliveryNotePreview: props.setDisplayDeliveryNotePreview,
    displayDeliveryNotePreview: props.displayDeliveryNotePreview,
  };

  return (
    <HeaderContainer>
      <HeaderSelectors {...headerSelectorsProps} />
      <OrderInfo {...orderInfoProps} />
      <HeaderButtons {...headerButtonsProps} />
    </HeaderContainer>
  );
};

export default Header;
