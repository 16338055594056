import React from 'react';

import { getTheme } from '@commons/utils/theme';
import { Label } from '@commons/utils/styledLibraryComponents';

import { DisplayNameHovered } from '../../../../styledComponents';

import { FormattedInfoDisplay, LabelContainer } from '../../styledComponents';

const PreparationOrderPriceQuantityColumns = (props) => {
  const { nbColumns, displayBigger, selectDisplayBigger, maxCharBeforeShowingBiggerName, product } =
    props;

  const theme = getTheme();

  return (
    <>
      {/* Ordered quantity */}
      <FormattedInfoDisplay
        nbColumns={nbColumns}
        onClick={() => selectDisplayBigger('formattedOrderedQuantity')}
        onMouseEnter={() => selectDisplayBigger('formattedOrderedQuantity')}
        onMouseLeave={() => selectDisplayBigger('formattedOrderedQuantity')}
      >
        {!product.formattedOrderedQuantity ? (
          '-'
        ) : (
          <>
            {product.formattedOrderedQuantity.length > maxCharBeforeShowingBiggerName
              ? product.formattedOrderedQuantity.substring(0, maxCharBeforeShowingBiggerName - 2) +
                ' ...'
              : product.formattedOrderedQuantity}
            {displayBigger.formattedOrderedQuantity === true &&
              product.formattedOrderedQuantity.length > maxCharBeforeShowingBiggerName && (
                <DisplayNameHovered
                  isBold={product.displayRules.boldReceivedQuantity}
                  nbColumns={nbColumns}
                  onClick={(e) => e.stopPropagation()}
                >
                  {product.formattedOrderedQuantity}
                </DisplayNameHovered>
              )}
          </>
        )}
      </FormattedInfoDisplay>

      {/* Invoiced quantity */}
      <FormattedInfoDisplay
        isBold={product.displayRules.boldInvoicedQuantity}
        nbColumns={nbColumns}
        onClick={() => selectDisplayBigger('formattedInvoicedQuantity')}
        onMouseEnter={() => selectDisplayBigger('formattedInvoicedQuantity')}
        onMouseLeave={() => selectDisplayBigger('formattedInvoicedQuantity')}
      >
        {product.formattedInvoicedQuantity.length > maxCharBeforeShowingBiggerName
          ? product.formattedInvoicedQuantity.substring(0, maxCharBeforeShowingBiggerName - 2) +
            ' ...'
          : product.formattedInvoicedQuantity}
        {displayBigger.formattedInvoicedQuantity === true &&
          product.formattedInvoicedQuantity.length > maxCharBeforeShowingBiggerName && (
            <DisplayNameHovered
              isBold={product.displayRules.boldInvoicedQuantity}
              nbColumns={nbColumns}
              onClick={(e) => e.stopPropagation()}
            >
              {product.formattedInvoicedQuantity}
            </DisplayNameHovered>
          )}
      </FormattedInfoDisplay>

      {/* Invoiced price */}
      <FormattedInfoDisplay
        isBold={product.displayRules.boldInvoicedUnitPrice}
        nbColumns={nbColumns}
        onClick={() => selectDisplayBigger('formattedInvoicedUnit')}
        onMouseEnter={() => selectDisplayBigger('formattedInvoicedUnit')}
        onMouseLeave={() => selectDisplayBigger('formattedInvoicedUnit')}
      >
        <div>
          {product.formattedInvoicedUnit.length > maxCharBeforeShowingBiggerName
            ? product.formattedInvoicedUnit.substring(0, maxCharBeforeShowingBiggerName - 2) +
              ' ...'
            : product.formattedInvoicedUnit}
        </div>
        {displayBigger.formattedInvoicedUnit === true &&
          product.formattedInvoicedUnit.length > maxCharBeforeShowingBiggerName && (
            <DisplayNameHovered
              isBold={product.displayRules.boldInvoicedUnitPrice}
              nbColumns={nbColumns}
              onClick={(e) => e.stopPropagation()}
            >
              {product.formattedInvoicedUnit}
            </DisplayNameHovered>
          )}
      </FormattedInfoDisplay>

      {/* Ordered total price */}
      <FormattedInfoDisplay nbColumns={nbColumns}>
        {product.formattedOrderedTotal || '-'}
      </FormattedInfoDisplay>

      {/* Invoiced total price */}
      <FormattedInfoDisplay
        isBold={product.displayRules.boldInvoicedTotalPrice}
        nbColumns={nbColumns}
      >
        {product.formattedInvoicedTotal}
      </FormattedInfoDisplay>

      {/* Balance Ordered / Invoiced */}
      <FormattedInfoDisplay nbColumns={nbColumns}>
        <LabelContainer>
          <Label
            background={
              product.productInvoicedDiff === 0 ? theme.colors.infoGreen : theme.colors.infoOrange
            }
            color={theme.colors.greys.lightest}
          >
            {product.formattedInvoicedDiff}
          </Label>
        </LabelContainer>
      </FormattedInfoDisplay>
    </>
  );
};

export default PreparationOrderPriceQuantityColumns;
