import { APIcaller } from './APIcaller';

const getSupplierProfilesOfSupplier = (supplierId, userTimezone = 'Europe/Paris') =>
  APIcaller.apiGet(`/admin/suppliers/${supplierId}/supplierProfiles`, { userTimezone });

const getSupplierProfilesOfStore = (storeId) =>
  APIcaller.apiGet(`/admin/stores/${storeId}/supplierProfiles`);

const postSupplierProfileWithMappings = (
  supplierProfile,
  storeIds,
  supplierProducts,
  cloneFromStoreId = false,
) => {
  const customBody = cloneFromStoreId
    ? {
        cloneFromStoreId,
      }
    : {
        supplierProducts,
      };

  return APIcaller.apiPost(`/admin/supplierProfile/mappings`, {
    supplierProfile,
    storeIds,
    ...customBody,
  });
};

const patchSupplierProfile = (
  supplierProfile,
  exceptionalDeliveryDays = [],
  userTimezone = 'Europe/Paris',
) =>
  APIcaller.apiPatch(`/admin/supplierProfiles`, {
    supplierProfile,
    exceptionalDeliveryDays,
    userTimezone,
  });

const deleteSupplierProfile = (supplierProfileId) =>
  APIcaller.apiDelete(`/admin/supplierProfiles/${supplierProfileId}`);

/**
 * Handle the download of the supplier profiles template
 * @param {string} clientId - the client id of the current client
 * @return {Promise<any>} - Xlsx file for supplier profiles template
 */
const getBatchCreationTemplate = async (clientId) =>
  APIcaller.apiGet(`/admin/${clientId}/supplier-profiles/batch-creation/template`, {}, 'blob');

const postBatchSupplierProfilesToValidate = async (clientId, payload) =>
  APIcaller.apiPost(
    `/admin/${clientId}/supplier-profiles/batch-creation/validate`,
    payload,
    'blob',
  );

const postBatchSupplierProfileImport = async (clientId, payload) =>
  APIcaller.apiPost(`/admin/${clientId}/supplier-profiles/batch-creation/import`, payload);

const deleteExceptionalDeliveryDaysOfSupplierProfiles = async (supplierProfileIds) =>
  APIcaller.apiDelete(`/admin/supplier-profiles/exceptional-delivery-days`, { supplierProfileIds });

const addExceptionalDeliveryDaysToSupplierProfiles = async (
  clientId,
  supplierProfileIds,
  exceptionalDeliveryDays,
) =>
  APIcaller.apiPost(`/admin/${clientId}/supplier-profiles/exceptional-delivery-days`, {
    supplierProfileIds,
    exceptionalDeliveryDays,
  });

export const supplierProfile = {
  getSupplierProfilesOfSupplier,
  getSupplierProfilesOfStore,
  postSupplierProfileWithMappings,
  patchSupplierProfile,
  deleteSupplierProfile,
  getBatchCreationTemplate,
  postBatchSupplierProfilesToValidate,
  postBatchSupplierProfileImport,
  deleteExceptionalDeliveryDaysOfSupplierProfiles,
  addExceptionalDeliveryDaysToSupplierProfiles,
};
