import { head, isEqualWith } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { sortPackagings } from '@commons/utils/format';
import Text, { ENUM_COLORS } from '@commons/Text';

import { UNIT } from '@stocks/StocksInventories/components/StockForm/constants/unit';

import { Item } from '../styledComponents';

const memoizeCondition = (prevProps, props) =>
  isEqualWith(prevProps.packagings, props.packagings) && prevProps.isDirty === props.isDirty;

const Unit = (props) => {
  const { isDirty, nbColumns, packagings, allPackagings } = props;

  if (!packagings.length) {
    <Item nbColumns={nbColumns} large>
      <Text color={!isDirty ? ENUM_COLORS.DARKER : ENUM_COLORS.DARKEST}>-</Text>
    </Item>;
  }

  if (packagings.length === 1) {
    const { name } = head(packagings);

    return (
      <Item nbColumns={nbColumns} large>
        <Text color={!isDirty ? ENUM_COLORS.DARKER : ENUM_COLORS.DARKEST}>{name}</Text>
      </Item>
    );
  }

  const packagingNames = sortPackagings(allPackagings).reduce((result, packaging) => {
    if (!packaging.isUsedInStock) {
      return result;
    }

    result.push(packaging.name);

    return result;
  }, []);

  return (
    <Item nbColumns={nbColumns} large>
      <Text color={!isDirty ? ENUM_COLORS.DARKER : ENUM_COLORS.DARKEST}>
        {packagingNames.join(' | ')}
      </Text>
    </Item>
  );
};

Unit.propTypes = {
  isDirty: PropTypes.bool,
  nbColumns: PropTypes.number,
  packagings: PropTypes.arrayOf(
    PropTypes.shape({
      unit: PropTypes.oneOf(UNIT),
      quantity: PropTypes.number.isRequired,
    }),
  ),
};

Unit.defaultProps = {
  isDirty: true,
  nbColumns: 1,
  packagings: [],
};

export default memo(Unit, memoizeCondition);
