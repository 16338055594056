import moment from 'moment';
import React from 'react';

import {
  InpulseDateContainer,
  InpulseDate,
  DateContainer,
  DotContainer,
  CircleContainer,
  Dot,
  Circle,
  Date,
} from './styledComponents';

export const DatePickerDotedDay = (props) => {
  const { item, isDot } = props;

  return (
    <>
      <DateContainer>
        <DotContainer>
          <Dot isDot={isDot} />
        </DotContainer>
        <Date>{moment(item).format('D')}</Date>
      </DateContainer>
    </>
  );
};

export const InpulseDatePickerDotedDay = (props) => {
  const { item, isDot, renderCircle } = props;

  return (
    <>
      <InpulseDateContainer>
        <CircleContainer renderCircle={renderCircle}>
          <Circle renderCircle={renderCircle}>
            <InpulseDate renderCircle={renderCircle}>{moment(item).format('D')}</InpulseDate>
          </Circle>
        </CircleContainer>
        <DotContainer renderCircle={renderCircle}>
          <Dot isDot={isDot} />
        </DotContainer>
        <InpulseDate renderCircle={!renderCircle}>{moment(item).format('D')}</InpulseDate>
      </InpulseDateContainer>
    </>
  );
};
