import * as yup from 'yup';
import PropTypes from 'prop-types';
import React from 'react';

import { GenericFormContainer } from './styledComponents';
import FormInput from './components/FormInput';

/**
 * Build a yup schema for object to use with useForm in order to define fields behaviour
 *
 * @param {Object[]} inputs - List of inputs containing a specific (and optional) yup rule to be validated by form
 *
 * @returns {yup.AnyObjectSchema} The built yup schema
 */
export const buildSchema = (inputs) => {
  const builtSchema = inputs.reduce((result, input) => {
    if (input.rule) {
      result[input.name] = input.rule;
    }

    return result;
  }, {});

  return yup.object(builtSchema).required();
};

/**
 * Build a yup schema for array to use with useForm in order to define fields behaviour
 *
 * @param {Object[]} inputs - List of inputs containing a specific (and optional) yup rule to be validated by form
 *
 * @returns {yup.AnyObjectSchema} The built yup schema
 */
export const buildArraySchema = (key, inputs) => {
  const builtSchema = inputs.reduce((result, input) => {
    if (input.rule) {
      result[input.name] = input.rule;
    }

    return result;
  }, {});

  return yup
    .object()
    .shape({
      [key]: yup.array().of(yup.object(builtSchema)),
    })
    .required();
};

/**
 * Simple container of forms that could be customise to Inpulse style later
 * Handle Single & Mulitple forms inputs
 */
export const GenericForm = (props) => {
  const {
    form,
    inputs,
    prefixInputName,
    customStyle,
    register,
    shouldDisplayError,
    shouldDisplayErrorMessage,
    isEditionAllowed,
  } = props;

  return (
    <GenericFormContainer style={customStyle}>
      {inputs.map((input, index) => (
        <FormInput
          register={register}
          {...form}
          input={input}
          isEditionAllowed={isEditionAllowed}
          key={index}
          prefixInputName={prefixInputName}
          shouldDisplayError={shouldDisplayError}
          shouldDisplayErrorMessage={shouldDisplayErrorMessage}
        />
      ))}
    </GenericFormContainer>
  );
};

GenericForm.propTypes = {
  form: PropTypes.any.isRequired,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      defaultValue: PropTypes.any,
      rule: PropTypes.any,
    }),
  ),
  prefixInputName: PropTypes.string,
  customStyle: PropTypes.objectOf(PropTypes.any),
  isEditionAllowed: PropTypes.bool,
  shouldDisplayError: PropTypes.bool,
  shouldDisplayErrorMessage: PropTypes.bool,
};

GenericForm.defaultProps = {
  prefixInputName: '',
  inputs: [],
  isEditionAllowed: true,
  shouldDisplayErrorMessage: true,
};

export default GenericForm;
