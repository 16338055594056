import i18next from 'i18next';

export const EXCEPTIONAL_DELIVERY_TYPES = {
  NO_DELIVERY: 'no-delivery',
  EXCEPTIONAL_DELIVERY: 'exceptional-delivery',
};

export const EXCEPTIONAL_DELIVERY_TYPES_CHOICES = [
  {
    id: EXCEPTIONAL_DELIVERY_TYPES.NO_DELIVERY,
    value: i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_NO_DELIVERY'),
  },
  {
    id: EXCEPTIONAL_DELIVERY_TYPES.EXCEPTIONAL_DELIVERY,
    value: i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_EXCEPTIONAL_DELIVERY_DAY'),
  },
];
