import i18next from 'i18next';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';

export const CHANGE_TYPES = {
  DATE: 'date',
  STORE: 'store',
};

export const getUpdateStoreWarningModal = (
  translatedStoreName,
  selectedData,
  handleChange,
  deleteAllLosses,
  changeType,
) => {
  const params = getConfirmationModal({
    title:
      changeType === CHANGE_TYPES.STORE
        ? i18next.t('LOSSES.PRODUCTS.CHANGE_STORE_WARNING_MODAL_TITLE', {
            storeName: translatedStoreName,
          })
        : i18next.t('LOSSES.PRODUCTS.CHANGE_DATE_WARNING_MODAL_TITLE'),
    icon: '/images/inpulse/warning-white-small.svg',
    content: i18next.t('LOSSES.PRODUCTS.CHANGE_DATA_WARNING_MODAL_TEXT'),
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_CONFIRM_BUTTON(),
        handleClick: () => {
          deleteAllLosses();
          handleChange(selectedData);
        },
      },
    ],
  });

  return params;
};
