import { connect } from 'react-redux';
import React, { useState, createContext } from 'react';

import { getClientInfo } from '../selectors/client';

export const FiltersContext = createContext();

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const FiltersProvider = ({ client: { hasOffline }, children }) => {
  const [filtersAnalyticsProduction, setFiltersAnalyticsProduction] = useState(null);

  const [productionPlanningRawData, setProductionPlanningRawData] = useState({
    data: [],
    selectedStore: {},
    selectedTimeSlot: {},
  });

  const [productionInventoryRawData, setProductionInventoryRawData] = useState({
    stock: {
      data: [],
      selectedStore: {},
    },
    loss: {
      data: [],
      selectedStore: {},
    },
  });

  // Method to populate context of the product operations page when offline feature is activated
  const updateProductionPlanningRawData = (data) => {
    if (!hasOffline) {
      return;
    }

    setProductionPlanningRawData(data);
  };

  // Method to populate context of the production inventory page when offline feature is activated
  const updateProductionInventoryRawData = (data) => {
    if (!hasOffline) {
      return;
    }

    setProductionInventoryRawData(data);
  };

  return (
    <FiltersContext.Provider
      value={{
        filtersAnalyticsProduction,
        setFiltersAnalyticsProduction,
        productionPlanningRawData,
        setProductionPlanningRawData: updateProductionPlanningRawData,
        productionInventoryRawData,
        setProductionInventoryRawData: updateProductionInventoryRawData,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export default connect(mapStateToProps, null)(FiltersProvider);
