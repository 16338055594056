import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 72px;
  padding: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;

  border: 1px solid ${(props) => props.theme.colors.greys.light};
  border-radius: 8px;
`;

export const Column = styled.div`
  width: 160px;
  height: 16px;

  font: ${(props) => props.theme.fonts.textBigHeight16};
  color: ${(props) =>
    props.isColumnTitle ? props.theme.colors.greys.darker : props.theme.colors.greys.darkest};

  ${(props) =>
    props.isButton
      ? css`
          display: flex;
          align-items: center;

          > div {
            min-height: 0px;
            min-width: 0px;

            width: 18px !important;
            height: 18px !important;
          }
        `
      : css`
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
`;
