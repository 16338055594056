import _ from 'lodash';
import i18next from 'i18next';

import { ConfirmationModal } from '@commons/Modals/ConfirmationModal';

export const getDisableRecipesModalParams = (recipes, disableRecipes) => {
  const recipeToDisable = _.head(recipes);

  return {
    type: 'warning',
    width: '542px',
    height: 'auto',
    icon: '/images/inpulse/power-white-small.svg',
    title:
      recipes.length === 1
        ? i18next.t('ADMIN.RECIPES.SINGLE_DISABLE')
        : i18next.t('ADMIN.RECIPES.MULTIPLE_DISABLE'),
    component: ConfirmationModal,
    data: {
      content:
        recipes.length === 1
          ? i18next.t('ADMIN.RECIPES.SINGLE_DISABLE_MODAL_CONTENT', {
              name: _.get(recipeToDisable, 'name', ''),
            })
          : i18next.t('ADMIN.RECIPES.MULTIPLE_DISABLE_MODAL_CONTENT'),
    },
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.DISABLE'),
        icon: '/images/inpulse/power-white-small.svg',
        handleClick: async () => {
          await disableRecipes(recipes);
        },
      },
    ],
  };
};

export const getDeleteRecipesModalParams = (recipes, deleteRecipes) => {
  const recipeToDelete = _.head(recipes);
  const isSingleDelete = recipes.length === 1;

  return {
    type: 'warning',
    width: '542px',
    height: 'auto',
    icon: '/images/inpulse/trash-white.svg',
    title: isSingleDelete
      ? i18next.t('ADMIN.RECIPES.SINGLE_DELETE_MODAL_TITLE')
      : i18next.t('ADMIN.RECIPES.MULTIPLE_DELETE_MODAL_TITLE'),
    component: ConfirmationModal,
    data: {
      shouldInterpretHtml: true,
      content: isSingleDelete
        ? i18next.t('ADMIN.RECIPES.SINGLE_DELETE_MODAL_CONTENT', {
            recipeName: recipeToDelete.name,
          })
        : i18next.t('ADMIN.RECIPES.MULTIPLE_DELETE_MODAL_CONTENT'),
    },
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.DELETE'),
        icon: '/images/inpulse/trash-white.svg',
        handleClick: () => deleteRecipes(recipes),
      },
    ],
  };
};

export const getEnableRecipeModalParams = (recipe, enableRecipe) => ({
  type: 'warning',
  width: '542px',
  height: 'auto',
  icon: '/images/inpulse/power-white-small.svg',
  title: i18next.t('ADMIN.RECIPES.ENABLE_MODAL_TITLE'),
  component: ConfirmationModal,
  data: {
    content: i18next.t('ADMIN.RECIPES.ENABLE_MODAL_CONTENT', {
      name: recipe.name,
    }),
  },
  actions: [
    {
      key: 0,
      color: 'inpulse-outline',
      label: i18next.t('GENERAL.CANCEL'),
      icon: '/images/inpulse/close-black-small.svg',
    },
    {
      key: 1,
      color: 'inpulse-default',
      label: i18next.t('GENERAL.ACTIVATE'),
      icon: '/images/inpulse/power-white-small.svg',
      handleClick: () => enableRecipe(recipe),
    },
  ],
});
