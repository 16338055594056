import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.greys.light}`};
  color: ${(props) => props.theme.colors.greys.darker};
  font: ${(props) => props.theme.fonts.textBigHeight16};
  overflow: hidden;
`;

export const FixedColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  border-right: ${(props) => `1px solid ${props.theme.colors.greys.light}`};
  gap: 16px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  gap: 16px;
`;

export const TextContainer = styled.div`
  width: ${(props) => props.width};
  display: flex;
  flex-direction: row;
`;

export const TextRequiredCharacter = styled.div`
  margin-right: 2px;
  color: ${(props) => props.theme.colors.infoRed};
`;
