import { APIcaller } from './APIcaller';

const updateCredentialsByStoreId = (storeId, apiKey) =>
  APIcaller.apiPost(`/partnership/libeo/stores/${storeId}/credentials`, { apiKey });

const getCredentialsByStoreId = (storeId) =>
  APIcaller.apiGet(`/partnership/libeo/stores/${storeId}/credentials`);

export default {
  getCredentialsByStoreId,
  updateCredentialsByStoreId,
};
