import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Tooltip } from '@commons/utils/styledLibraryComponents';
import DisplayImgInFullScreen from '@commons/DisplayImgInFullScreen';

import {
  CategoryContentContainer,
  RowContainer,
  ColumnsContainer,
  Column,
} from '@productions/CentralKitchenProductionOperations/styledComponents';

import { getListColumns } from '../utils/columns';

import RecommendationRow from './RecommendationRow';

const RecommendationCategoryContent = (props) => {
  const { entities, storeName, selectedChoiceKey } = props;

  const columns = getListColumns({ storeName });

  const orderedEntities = sortBy(entities, 'name');

  const [displayProductPicture, setDisplayProductPicture] = useState({
    state: false,
    displayImage: '',
  });

  return (
    <CategoryContentContainer>
      <ColumnsContainer>
        {columns.map((column, indexColumn) => (
          <Column
            flex={column.flex}
            key={`${column.propertyKey}-${indexColumn}`}
            nbColumns={columns.length}
          >
            {column.displayImage && displayProductPicture.state ? (
              <>
                <DisplayImgInFullScreen
                  image={displayProductPicture.displayImage}
                  setDisplayProductPicture={setDisplayProductPicture}
                />
                {column.label}
              </>
            ) : (
              <>{column.label}</>
            )}
            {!!column.tooltip && (
              <Tooltip key={`tooltip-${column.propertyKey}-${indexColumn}`} text={column.tooltip} />
            )}
          </Column>
        ))}
      </ColumnsContainer>
      {orderedEntities.map((entity, index) => (
        <RowContainer key={`${entity.name}-${index}`}>
          <RecommendationRow
            entity={entity}
            nbColumns={columns.length}
            recommendationQuantity={entity.quantity || 0}
            selectedChoiceKey={selectedChoiceKey}
            setDisplayProductPicture={setDisplayProductPicture}
          />
        </RowContainer>
      ))}
    </CategoryContentContainer>
  );
};

RecommendationCategoryContent.propTypes = {
  entities: PropTypes.array.isRequired,
  recommendationQuantities: PropTypes.object.isRequired,
  selectedChoiceKey: PropTypes.string.isRequired,
};

export default RecommendationCategoryContent;
