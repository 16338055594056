import i18next from 'i18next';
import parse from 'html-react-parser';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';

export const getDeleteWarningModalParams = ({ invoiceControlListId, deleteById }) =>
  getConfirmationModal({
    type: 'warning',
    title: i18next.t('INVOICE.INVOICE_CONTROLS.DELETE_WARNING_MODAL_TITLE'),
    icon: '/images/inpulse/warning-white-small.svg',
    content: parse(i18next.t('INVOICE.INVOICE_CONTROLS.DELETE_WARNING_MODAL_CONTENT')),
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_CONFIRM_BUTTON(),
        handleClick: () => deleteById(invoiceControlListId),
      },
    ],
  });
