import { head } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import { getIsCentralMode } from '@commons/utils/localStorage';
import { isUserAdmin, isUserDeepsight, isUserManager } from '@commons/utils/roles';
import sortActionsList from '@commons/utils/sortActionsList';

import { getDisableRecipesModalParams, getEnableRecipeModalParams } from './modalConfigurations';

/**
 * This function returns a boolean that indicates if the deactivation action of the recipes is disabled. (disabled if used by active elements)
 *
 * @param {[object]} recipes selected recipes
 * @returns boolean
 */
const isDisableActionDisabled = (recipes) =>
  recipes.some(({ parentMappings, product }) => {
    const isAnyParentMappingsActive = parentMappings.some(
      ({ associatedEntity }) => !!associatedEntity && associatedEntity.active,
    );

    const isProductLinkedActive = !!product ? product.active : false;

    return isAnyParentMappingsActive || isProductLinkedActive;
  });

/**
 * This function returns a boolean that indicates if the activation action of the recipes is disabled. (disabled if use only inactive elements)
 *
 * @param {[object]} recipes selected recipes
 * @returns boolean
 */
const isEnableActionDisabled = (recipes) =>
  recipes.some(({ mappings }) => {
    const isAnyChildMappingsInactive = mappings.some(
      ({ lnkEntityEntitymappingrel }) =>
        !!lnkEntityEntitymappingrel && !lnkEntityEntitymappingrel.active,
    );
    return !!mappings.length && isAnyChildMappingsInactive;
  });

export const getActions = (props) => {
  const {
    selectedItems,
    createRecipe,
    duplicateRecipe,
    handleDeletionWarningGenericModal,
    exportRecipes,
    filteringOnActive,
    openGenericModal,
    enableRecipe,
    user,
    disableRecipes,
    activateMultipleChannels,
    clientHasMultipleChannels,
    openAvailabilitiesUpdateModal,
  } = props;

  const isSingleSelection = selectedItems.length === 1;

  const actions = [
    {
      fixed: true,
      id: 'action-create',
      handleAction: () => createRecipe(),
      actionLabel: i18next.t('GENERAL.CREATE'),
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={() => createRecipe()}
          icon="/images/inpulse/add-inpulse-white-small.svg"
          label={i18next.t('GENERAL.CREATE')}
        />
      ),
      isHidden: () => isUserManager(user) || selectedItems.length > 0,
    },
  ];

  if (selectedItems.length === 0) {
    actions.push({
      id: 'export',
      actionLabel: i18next.t('GENERAL.EXPORT_LIST'),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: () => {
        exportRecipes();
      },
    });

    return sortActionsList(actions);
  }

  const isDisableRecipeActionDisabled = isDisableActionDisabled(selectedItems);

  actions.push(
    {
      id: 'export',
      actionLabel: i18next.t('ADMIN.PRODUCTS.EXPORT_SELECTION'),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: () => exportRecipes(selectedItems),
    },
    {
      id: 'duplicate',
      actionLabel: i18next.t('ADMIN.RECIPES.DUPLICATE'),
      actionIcon: () => '/images/inpulse/duplicate-ip-black.svg',
      handleAction: () => duplicateRecipe(selectedItems),
      isHidden: () => isUserManager(user) || !isSingleSelection || !filteringOnActive,
    },
    {
      id: 'action-manage-availabilities',
      actionLabel: i18next.t('ADMIN.RECIPES.ACTION_MANAGE_AVAILABILITY'),
      handleAction: () => openAvailabilitiesUpdateModal(selectedItems),
      actionIcon: () => '/images/inpulse/pen-black-small.svg',
      isHidden: () => !filteringOnActive,
    },
    {
      id: 'disable-recipes',
      actionLabel: isSingleSelection
        ? i18next.t('ADMIN.RECIPES.SINGLE_DISABLE')
        : i18next.t('ADMIN.RECIPES.MULTIPLE_DISABLE'),
      handleAction: (recipes) => {
        const params = getDisableRecipesModalParams(recipes, disableRecipes);
        openGenericModal(params);
      },
      isDisabled: () => isDisableRecipeActionDisabled,
      actionIcon: () =>
        isDisableRecipeActionDisabled
          ? '/images/inpulse/power-lmgrey-small.svg'
          : '/images/inpulse/power-black-small.svg',
      disabledTooltipText: () =>
        i18next.t('ADMIN.RECIPES.DISABLED_DISABLE_TOOLTIP', { count: selectedItems.length }),
      isHidden: () => !filteringOnActive || (!isUserAdmin(user) && !isUserDeepsight(user)),
    },
    {
      id: 'enable-recipes',
      actionLabel: i18next.t('ADMIN.RECIPES.ENABLE'),
      handleAction: () => {
        const params = getEnableRecipeModalParams(head(selectedItems), enableRecipe);
        openGenericModal(params);
      },
      isDisabled: () => isEnableActionDisabled(selectedItems),
      disabledTooltipText: () => i18next.t('ADMIN.RECIPES.ENABLED_DISABLE_TOOLTIP'),
      actionIcon: () =>
        isEnableActionDisabled(selectedItems)
          ? '/images/inpulse/power-lmgrey-small.svg'
          : '/images/inpulse/power-black-small.svg',
      isHidden: () =>
        !isSingleSelection || filteringOnActive || (!isUserAdmin(user) && !isUserDeepsight(user)),
    },
    {
      id: 'csm-delete',
      actionLabel: isSingleSelection
        ? i18next.t('ADMIN.RECIPES.SINGLE_DELETE_MODAL_TITLE')
        : i18next.t('ADMIN.RECIPES.MULTIPLE_DELETE_MODAL_TITLE'),
      actionIcon: () => '/images/inpulse/trash-black.svg',
      handleAction: () => handleDeletionWarningGenericModal(selectedItems),
      isHidden: () => filteringOnActive || !isUserDeepsight(user),
      isHighlighted: () => true,
    },
    {
      id: 'csm-duplicate-recipe-channel',
      actionLabel: i18next.t('ADMIN.RECIPES.ACTIVATE_MULTIPLE_CHANNELS_ACTION'),
      handleAction: () => activateMultipleChannels(selectedItems),
      actionIcon: () => '/images/inpulse/bolt-white-small.svg',
      isHighlighted: () => true,
      isHidden: () =>
        isSingleSelection ||
        !filteringOnActive ||
        !isUserDeepsight(user) ||
        !clientHasMultipleChannels ||
        getIsCentralMode(),
    },
  );

  return sortActionsList(actions);
};

export const getRowActions = (props) => {
  const {
    duplicateRecipe,
    handleDeletionWarningGenericModal,
    openGenericModal,
    filteringOnActive,
    disableRecipes,
    enableRecipe,
    user,
    openAvailabilitiesUpdateModal,
  } = props;

  // Conditioning the return to avoid having the ellipse button appear
  if (!isUserManager(user)) {
    return [
      {
        id: 'action-manage-availabilities',
        actionLabel: () => i18next.t('ADMIN.RECIPES.ACTION_MANAGE_AVAILABILITY'),
        handleAction: (selectedItem) => openAvailabilitiesUpdateModal([selectedItem]),
        actionIcon: () => '/images/inpulse/pen-black-small.svg',
        isHidden: () => !filteringOnActive,
      },
      {
        id: 'duplicate',
        actionLabel: () => i18next.t('ADMIN.RECIPES.DUPLICATE'),
        actionIcon: () => '/images/inpulse/duplicate-ip-black.svg',
        handleAction: (item) => duplicateRecipe([item]),
        isHidden: () => isUserManager(user) || !filteringOnActive,
      },
      {
        id: 'disable-recipes',
        actionLabel: () => i18next.t('ADMIN.RECIPES.SINGLE_DISABLE'),
        isDisabled: (recipe) => isDisableActionDisabled([recipe]),
        actionIcon: (recipe) =>
          isDisableActionDisabled([recipe])
            ? '/images/inpulse/power-lmgrey-small.svg'
            : '/images/inpulse/power-black-small.svg',
        disabledTooltipText: () =>
          i18next.t('ADMIN.RECIPES.DISABLED_DISABLE_TOOLTIP', { count: 1 }),
        handleAction: (recipes) => {
          const params = getDisableRecipesModalParams([recipes], disableRecipes);
          openGenericModal(params);
        },
        isHidden: () => !filteringOnActive || (!isUserAdmin(user) && !isUserDeepsight(user)),
      },
      {
        id: 'enable-recipes',
        actionLabel: () => i18next.t('ADMIN.RECIPES.ENABLE'),
        handleAction: (recipe) => {
          const params = getEnableRecipeModalParams(recipe, enableRecipe);
          openGenericModal(params);
        },
        isDisabled: (recipe) => isEnableActionDisabled([recipe]),
        disabledTooltipText: () => i18next.t('ADMIN.RECIPES.ENABLED_DISABLE_TOOLTIP'),
        actionIcon: (recipe) =>
          isEnableActionDisabled([recipe])
            ? '/images/inpulse/power-lmgrey-small.svg'
            : '/images/inpulse/power-black-small.svg',
        isHidden: () => filteringOnActive || (!isUserAdmin(user) && !isUserDeepsight(user)),
      },
      {
        id: 'csm-delete',
        actionLabel: () => i18next.t('ADMIN.RECIPES.SINGLE_DELETE_MODAL_TITLE'),
        actionIcon: () => '/images/inpulse/trash-black.svg',
        handleAction: (item) => handleDeletionWarningGenericModal([item]),
        isHidden: () => filteringOnActive || !isUserDeepsight(user),
        isHighlighted: () => true,
      },
    ];
  }

  return [];
};
