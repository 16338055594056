import { InputNumber } from 'deepsight-react-components';
import { SingleDatePicker } from 'react-dates';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { DATE_FILTER_CHOICES_NAME } from '@commons/DeepsightAnalyticsHeaders/constants';
import { DeepsightLabel } from '@commons/DeepsightComponents';
import { Dropdown, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import {
  renderCustomNavIconLeft,
  renderCustomNavIconRight,
  renderTodayDateCircle,
} from '@commons/DeepsightAnalyticsHeaders/utils';

import {
  Container,
  FilterContainer,
  CloseIcon,
  LabelContainer,
  FilterDate,
  FilterBool,
  CloseIconContainer,
} from './styledComponents';

export const ColumnFilter = (props) => {
  const {
    filter,
    setFilters,
    columnsList,
    lastFilterId,
    filtersParams,
    activeColumnsList,
    setIsFilterCompleted,
  } = props;

  const [datePickerFocused, setDatePickerFocused] = useState(false);
  const [datePickerMinFocused, setDatePickerMinFocused] = useState(false);
  const [datePickerMaxFocused, setDatePickerMaxFocused] = useState(false);

  const concernedFilterParamsContent = _.get(filtersParams[filter.filterType], 'content', []);
  const concernedFilterParam = concernedFilterParamsContent.find(
    (param) => param.name === filter.paramName,
  );

  const inputType = _.get(concernedFilterParam, 'type', '');
  const inputName = _.get(concernedFilterParam, 'name', '');

  useEffect(() => {
    if (filter && filter.display && !filter.value && filter.value !== 0) {
      setIsFilterCompleted(false);
      return;
    }

    setIsFilterCompleted(true);
  }, [filter]);

  useEffect(() => {
    if (filter && inputName === DATE_FILTER_CHOICES_NAME.NONE) {
      setFilters(filter.filterId, 'value', 'value', 'none'); // Is needed in order to enable apply filters button
    }
  }, [inputName, filter.columnName]);

  const handleInputChange = (e, id) => {
    const inputValue = parseInt(e.target.value, 10);
    const formattedValue = !Number.isNaN(inputValue) ? inputValue : '';

    if (id === 'min') {
      setFilters(filter.filterId, 'value', 'limitValues', {
        minValue: formattedValue,
        maxValue: filter.limitValues.maxValue,
      });
    } else if (id === 'max') {
      setFilters(filter.filterId, 'value', 'limitValues', {
        minValue: filter.limitValues.minValue,
        maxValue: formattedValue,
      });
    } else {
      setFilters(filter.filterId, 'value', 'value', formattedValue);
    }
  };

  if (!filter || !filter.display) {
    return null;
  }

  const isLastFilter = filter.filterId === lastFilterId;

  const selectedColumn = _.find(columnsList, ['name', filter.columnName]);
  const selectableColumns = [selectedColumn].concat(activeColumnsList);

  return (
    <Container className={props.className} isLast={isLastFilter}>
      <FilterContainer>
        {filter && (
          <Dropdown
            customStyle={{ marginRight: '10px' }}
            isDisabled={selectableColumns.length <= 1}
            isRequired={true}
            items={selectableColumns}
            selectedItem={selectedColumn}
            width={INPUT_WIDTH.MEDIUM}
            onSelectionChange={(selectedItem) => {
              if (filter.columnName === selectedItem.name) {
                return;
              }

              setFilters(
                filter.filterId,
                'columnName',
                'propertyKey',
                _.find(columnsList, (column) => column.name === selectedItem.name),
              );
            }}
          />
        )}
        {filter.columnName && (
          <Dropdown
            customStyle={{ marginRight: '10px' }}
            isDisabled={filtersParams[filter.filterType].content.length <= 1}
            isRequired={true}
            items={filtersParams[filter.filterType].content}
            selectedItem={_.find(filtersParams[filter.filterType].content, [
              'name',
              filter.paramName,
            ])}
            sortBy={(itemsList) =>
              _.sortBy(itemsList, filter.filterType === 'date' ? 'id' : 'value')
            }
            width={INPUT_WIDTH.MEDIUM}
            onSelectionChange={(selectedItem) => {
              if (filter.paramName === selectedItem.name) {
                return;
              }

              setFilters(
                filter.filterId,
                'paramName',
                'doFilter',
                _.find(
                  filtersParams[filter.filterType].content,
                  (param) => param.name === selectedItem.name,
                ),
              );
            }}
          />
        )}
        {filter.paramName && filter.filterType === 'numeric' ? (
          <InputNumber
            borderRadius={undefined}
            borderStyle={undefined}
            display={inputType}
            height={'38px'}
            limitValues={filter.limitValues}
            min={0}
            value={filter.value}
            width={80}
            onChange={handleInputChange}
          />
        ) : undefined}
        {filter.paramName && filter.filterType === 'string' && (
          <Dropdown
            isUniqueSelection={false}
            items={filter.list}
            selectedItems={filter.values}
            width={INPUT_WIDTH.MEDIUM}
            onSelectionChange={(selectedItems) =>
              setFilters(filter.filterId, 'value', 'values', selectedItems)
            }
          />
        )}
        {filter.paramName && filter.filterType === 'boolean' && (
          <FilterBool>
            <Dropdown
              isUniqueSelection={false}
              items={filter.list}
              selectedItems={filter.values}
              width={INPUT_WIDTH.MEDIUM}
              onSelectionChange={(selectedItems) =>
                setFilters(filter.filterId, 'value', 'values', selectedItems)
              }
            />
          </FilterBool>
        )}
        {filter.paramName &&
          filter.filterType === 'date' &&
          inputType === 'single' &&
          inputName !== DATE_FILTER_CHOICES_NAME.NONE && (
            <FilterDate focusedDateInput={datePickerFocused}>
              <img
                alt=""
                className="filter-date-input-icon"
                src={'/images/icon-calendar-black-inpulse.svg'}
                style={{ width: '16px', height: '16px' }}
              />

              <SingleDatePicker
                date={filter.value || null}
                displayFormat={'Do MMM'}
                focused={datePickerFocused}
                hideKeyboardShortcutsPanel={true}
                id="planning_date_selector"
                isOutsideRange={(day) => false}
                navNext={renderCustomNavIconRight()}
                navPrev={renderCustomNavIconLeft()}
                noBorder={true}
                numberOfMonths={1}
                placeholder={i18next.t('STOCKS.STOCKS.LIST_LABEL_DATE')}
                readOnly={true}
                renderDayContents={(item) => renderTodayDateCircle(item, [filter.value || null])}
                timezone="Europe/Paris" // TODO - [TIMEZONES]
                onDateChange={(value) => setFilters(filter.filterId, 'value', 'value', value)}
                onFocusChange={({ focused }) => setDatePickerFocused(focused)}
              />
            </FilterDate>
          )}
        {filter.paramName && filter.filterType === 'date' && inputType === 'multiple' && (
          <div style={{ display: 'flex' }}>
            <FilterDate focusedDateInput={datePickerMinFocused}>
              <img
                alt=""
                className="filter-date-input-icon"
                src={'/images/icon-calendar-black-inpulse.svg'}
                style={{ width: '16px', height: '16px' }}
              />

              <SingleDatePicker
                date={filter.limitValues.minValue || null}
                displayFormat={'Do MMM'}
                focused={datePickerMinFocused}
                hideKeyboardShortcutsPanel={true}
                id="planning_date_selector"
                isOutsideRange={(day) =>
                  filter.limitValues.maxValue
                    ? moment(filter.limitValues.maxValue).endOf('day').isBefore(day)
                    : false
                }
                navNext={renderCustomNavIconRight()}
                navPrev={renderCustomNavIconLeft()}
                noBorder={true}
                numberOfMonths={1}
                openDirection={'up'}
                placeholder={i18next.t('GENERAL.ANALYTICS_HEADER_DATE_PICKER_START_PLACEHOLDER')}
                readOnly={true}
                renderDayContents={(item) => renderTodayDateCircle(item, [filter.value || null])}
                timezone="Europe/Paris" // TODO - [TIMEZONES]
                onDateChange={(value) =>
                  setFilters(filter.filterId, 'value', 'limitValues', {
                    minValue: value,
                    maxValue: filter.limitValues.maxValue,
                  })
                }
                onFocusChange={({ focused }) => setDatePickerMinFocused(focused)}
              />
            </FilterDate>
            <FilterDate
              disabled={!filter.limitValues.minValue}
              focusedDateInput={datePickerMaxFocused}
            >
              <img
                alt=""
                className="filter-date-input-icon"
                src={'/images/icon-calendar-black-inpulse.svg'}
                style={{ width: '16px', height: '16px' }}
              />

              <SingleDatePicker
                date={filter.limitValues.maxValue || null}
                disabled={!filter.limitValues.minValue}
                displayFormat={'Do MMM'}
                focused={datePickerMaxFocused}
                hideKeyboardShortcutsPanel={true}
                id="planning_date_selector"
                isOutsideRange={(day) =>
                  moment(filter.limitValues.minValue).startOf('day').isAfter(day)
                }
                navNext={renderCustomNavIconRight()}
                navPrev={renderCustomNavIconLeft()}
                noBorder={true}
                numberOfMonths={1}
                openDirection={'up'}
                placeholder={i18next.t('GENERAL.ANALYTICS_HEADER_DATE_PICKER_END_PLACEHOLDER')}
                readOnly={true}
                renderDayContents={(item) => renderTodayDateCircle(item, [filter.value || null])}
                timezone="Europe/Paris" // TODO - [TIMEZONES]
                onDateChange={(value) =>
                  setFilters(filter.filterId, 'value', 'limitValues', {
                    minValue: filter.limitValues.minValue,
                    maxValue: value,
                  })
                }
                onFocusChange={({ focused }) => setDatePickerMaxFocused(focused)}
              />
            </FilterDate>
          </div>
        )}
      </FilterContainer>
      {filter && (
        <CloseIconContainer>
          <CloseIcon
            alt="icon-close"
            src="/images/icon-cross-close-black.svg"
            onClick={() => setFilters(filter.filterId, 'remove')}
          />
        </CloseIconContainer>
      )}
      {!isLastFilter && (
        <LabelContainer>
          <DeepsightLabel
            labelStyle={'inpulse'}
            style={{ fontSize: '12px', paddingTop: '3px' }}
            value="et"
            width={25}
          />
        </LabelContainer>
      )}
    </Container>
  );
};

const StyledColumnFilter = styled(ColumnFilter)`
  & .DateInput_fang {
    display: none !important;
  }

  & .DateRangePickerInput__disabled {
    background-color: lightgrey !important;
  }

  & .CalendarMonth_caption {
    color: ${(props) => props.theme.colors?.greys?.darkest} !important;
    font: ${(props) => props.theme.fonts?.h3} !important;
  }

  & .DateInput_input {
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    text-align: center !important;
    cursor: pointer;
  }

  & .DateInput_input__focused {
    border-bottom: none !important;
  }

  & .DayPicker_weekHeader_li {
    font: ${(props) => props.theme.fonts?.textMicro} !important;
  }

  & .CalendarDay {
    background: ${(props) => props.theme.colors?.greys.lightest} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__default:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__highlighted_calendar {
    background: #fff !important;
    border: 1px double #0a3eff !important;
  }

  & .CalendarDay__highlighted_calendar:hover {
    background: #e4e7e7 !important;
  }

  & .CalendarDay__selected_span {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    border: 1px double #e2e2e2 !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected,
  .CalendarDay__selected:active .CalendarDay__selected:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_start,
  .CalendarDay__selected_start:hover,
  .CalendarDay__selected_end,
  .CalendarDay__selected_end:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover {
    color: ${(props) => props.theme.colors?.greys.dark} !important;
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
  }
`;

export default StyledColumnFilter;
