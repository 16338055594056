import React from 'react';

import { ModalBackground, ModalContainer, Container } from './styledComponents';

const SupplierIngredientAlreadyAssociatedWarningModal = ({
  component: Component,
  closeSupplierIngredientAlreadyAssociatedWarningModal,
  ...rest
}) => (
  <>
    <Container>
      <ModalBackground
        onClick={() => closeSupplierIngredientAlreadyAssociatedWarningModal()}
      ></ModalBackground>

      <ModalContainer>
        <Component closeModal={closeSupplierIngredientAlreadyAssociatedWarningModal} {...rest} />
      </ModalContainer>
    </Container>
  </>
);

export default SupplierIngredientAlreadyAssociatedWarningModal;
