import styled, { css, keyframes } from 'styled-components';
import COLOR from './constants';
import ColorPalette from '../utils/colorPalette';

const rotation = keyframes`
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
`;

export const Icon = styled.img`
  margin-right: ${(props) => props.alt && (props.iconOnLeft ? '8px' : 0)};
  margin-left: ${(props) => props.alt && (props.iconOnLeft ? 0 : '8px')};

  ${(props) =>
    props.rotation &&
    css`
      animation: ${rotation} 2s linear infinite;
    `}
`;

const gradient = keyframes`
  0% { opacity: 0.6; }
	50% { opacity: 1; }
  100% { opacity: 0.6; }
`;

export const StyledButton = styled.button`
  border: none;
  font-size: ${(props) => `${props.fontSize}px`};
  font-family: inter-bold;
  border-radius: 24px;
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: all 0.2s ease-in;
  position: relative;

  padding: 12px 24px;

  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${`${props.minWidth}px`};
    `}
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${`${props.maxWidth}px`};
    `}
  height: ${(props) => `${props.height}px`};

  > ${Icon} {
    width: 16px;
    height: 16px;
    transition: all 0.2s ease-in;
  }

  ${(props) =>
    props.formatText &&
    css`
      text-transform: uppercase;
    `};

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  ${(props) =>
    (props.isDropdownOpened || props.active) &&
    props.color === COLOR.INPULSE_OUTLINE &&
    css`
      color: ${ColorPalette.IP_DGreen} !important;
      background: transparent !important;
      border-color: ${ColorPalette.IP_DGreen} !important;
      transition: all 0.2s ease-in;

      & > ${Icon} {
        filter: invert(62%) sepia(33%) saturate(3767%) hue-rotate(107deg)
          brightness(96%) contrast(84%);
        transition: all 0.2s ease-in;
      }
    `}

  ${(props) =>
    props.color === COLOR.BLUE &&
    css`
      color: ${props.fontColor || '#ffffff'};
      background: #0a3eff;
      box-shadow: 0 7px 12px 0 rgba(10, 62, 255, 0.3);

      &:hover {
        background: #082283;
      }
    `};

  ${(props) =>
    props.color === COLOR.BLUE_OUTLINE &&
    css`
      color: ${props.fontColor || '#0a3eff'};
      background: #ffffff;
      border: ${props.noBorder ? 'none' : '1px solid #0a3eff'};
      box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);

      &:hover {
        background: #0a3eff;
        color: #ffffff;
      }
      &:hover > ${Icon} {
        filter: brightness(0) invert(1);
        transition: filter 0.2s linear;
      }
    `};

  ${(props) =>
    props.color === COLOR.BLUE_OUTLINE_NO_SHADOW &&
    css`
      color: ${props.fontColor || '#0a3eff'};
      background: #ffffff;
      border: ${props.noBorder ? 'none' : '1px solid #0a3eff'};

      &:hover {
        background: #0a3eff;
        color: #ffffff;
      }
      &:hover > ${Icon} {
        filter: brightness(0) invert(1);
        transition: filter 0.2s linear;
      }
    `};

  ${(props) =>
    props.color === COLOR.DARK_BLUE &&
    css`
      color: ${props.fontColor || '#ffffff'};
      background: #697bbe;
      box-shadow: 0 7px 12px 0 rgba(105, 123, 190, 0.3);

      &:hover {
        background: rgb(61, 73, 111);
      }
    `};

  ${(props) =>
    props.color === COLOR.STRONG_DARK_BLUE &&
    css`
      color: ${props.fontColor || '#ffffff'};
      background: #1239ca;
      box-shadow: 0 7px 12px 0 rgba(105, 123, 190, 0.3);

      &:hover {
        background: rgb(61, 73, 111);
      }
    `};

  ${(props) =>
    props.color === COLOR.GREEN &&
    css`
      color: ${props.fontColor || '#ffffff'};
      background: #36eda3;
      box-shadow: 0 7px 12px 0 rgba(87, 238, 177, 0.3);

      &:hover {
        background: #2bb87f;
      }
    `};

  ${(props) =>
    props.color === COLOR.DARK_GREEN &&
    css`
      color: ${props.fontColor || '#ffffff'};
      background: #217647;
      box-shadow: 0 7px 12px 0 rgba(33, 119, 71, 0.3);

      &:hover {
        background: #114629;
      }
    `};

  ${(props) =>
    props.color === COLOR.ORANGE &&
    css`
      color: ${props.fontColor || '#ffffff'};
      background: #ff7b00;
      box-shadow: 0 7px 12px 0 rgba(255, 126, 0, 0.3);

      &:hover {
        background: #9e4e04;
      }
    `};

  ${(props) =>
    props.color === COLOR.RED &&
    css`
      color: ${props.fontColor || '#ffffff'};
      background: #fd0000;
      box-shadow: 0 7px 12px 0 rgba(255, 126, 0, 0.3);

      &:hover {
        background: #b20f0f;
      }
    `};

  ${(props) =>
    props.color === COLOR.GREY &&
    css`
      color: ${props.fontColor || '#ffffff'};
      background: #a8b1c4;
      box-shadow: 0 7px 12px 0 rgba(168, 177, 196, 0.3);

      &:hover {
        background: #626b7e;
      }
    `};

  ${(props) =>
    props.color === COLOR.LIGHT_BLUE &&
    css`
      color: ${props.fontColor || '#ffffff'};
      background: #0acdff;
      box-shadow: 0 7px 12px 0 rgba(10, 205, 255, 0.3);

      &:hover {
        background: #089dc3;
      }
    `};

  ${(props) =>
    props.color === COLOR.TRANSPARENT_HOVER_LIGHT_BLUE &&
    css`
      color: ${props.fontColor || '#0a3eff'};
      background: transparent;

      &:hover {
        background: #e6eeff;
      }
    `};

  ${(props) =>
    props.color === COLOR.TRANSPARENT_NO_HOVER &&
    css`
      color: ${props.fontColor || '#0a3eff'};
      background: transparent;

      &:hover {
        cursor: default;
      }
    `};

  ${(props) =>
    props.color === COLOR.WHITE &&
    css`
      color: ${props.fontColor || '#6b768c'};
      background: #ffffff;
      box-shadow: 0 1px 4px 0 rgba(107, 118, 140, 0.2);
      border: ${props.noBorder ? 'none' : '1px solid #979797'};

      &:hover {
        background: #626b7e;
        color: #ffffff;
      }

      &:hover > ${Icon} {
        filter: brightness(3);
      }
    `};

  ${(props) =>
    props.color === COLOR.LOCKED &&
    css`
      color: ${props.fontColor || '#6b768c'};
      background: lightgray;
      box-shadow: 0 7px 12px 0 rgba(107, 118, 140, 0.3);

      &:hover {
        cursor: default;
      }
    `};

  ${(props) =>
    props.color === COLOR.DEFAULT &&
    css`
      color: ${props.fontColor || '#6b768c'};
      background: #ffffff;
      border: ${props.noBorder ? 'none' : '1px solid #6b768c'};

      &:hover {
        color: #ffffff;
        background: #6b768c;
      }
    `};

  ${(props) =>
    props.color === COLOR.INPULSE_DEFAULT &&
    css`
      height: 40px;
      color: ${ColorPalette.WHITE};
      background-color: ${props.active
        ? ColorPalette.IP_DGreen
        : ColorPalette.IP_Black};
      border-radius: 24px;
      padding: 0px 24px;
      font: normal 700 14px/16px inter-bold;

      &:hover {
        background: ${ColorPalette.IP_Green};
      }

      &:active {
        background: ${ColorPalette.IP_DGreen};
      }

      &:disabled {
        cursor: not-allowed;
        background: ${ColorPalette.LMGrey};
      }
      &:disabled > ${Icon} {
        filter: brightness(0) invert(1);
      }
    `};

  ${(props) =>
    props.color === COLOR.INPULSE_OUTLINE &&
    css`
      height: 40px;
      color: ${ColorPalette.IP_Black};
      background-color: transparent;
      border: ${!props.noBorder && `2px solid ${ColorPalette.IP_Black}`};
      border-radius: 24px;
      padding: 0px 24px;
      font: normal 700 14px/16px inter-bold;

      &:hover {
        color: ${ColorPalette.IP_Green};
        border-color: ${ColorPalette.IP_Green};
      }
      ${!props.isDropdownOpened &&
      !props.active &&
      css`
        &:hover > ${Icon} {
          filter: invert(86%) sepia(100%) saturate(2426%) hue-rotate(70deg)
            brightness(99%) contrast(83%);
          transition: all 0.2s ease-in;
        }
      `}

      &:active {
        color: ${ColorPalette.IP_DGreen};
        border-color: ${ColorPalette.IP_DGreen};
      }
      &:active > ${Icon} {
        filter: invert(62%) sepia(33%) saturate(3767%) hue-rotate(107deg)
          brightness(96%) contrast(84%);
        transition: all 0.2s ease-in;
      }

      &:disabled {
        cursor: not-allowed;
        color: ${ColorPalette.LMGrey};
        border-color: ${ColorPalette.LMGrey};
      }
      &:disabled > ${Icon} {
        filter: invert(100%) sepia(3%) saturate(88%) hue-rotate(82deg)
          brightness(112%) contrast(77%);
        transition: all 0.2s ease-in;
      }

      ${props.active &&
      css`
        color: ${ColorPalette.IP_DGreen};
        border-color: ${ColorPalette.IP_DGreen};
        > ${Icon} {
          filter: invert(62%) sepia(33%) saturate(3767%) hue-rotate(107deg)
            brightness(96%) contrast(84%);
          transition: all 0.2s ease-in;
        }
      `}
    `};

  ${(props) =>
    props.color === COLOR.INPULSE_GREY &&
    css`
      color: ${ColorPalette.WHITE};
      background-color: ${ColorPalette.DMGrey};
      border-radius: 24px;
      padding: 0px 24px;
      font: normal 700 14px/16px inter-bold;

      &:hover {
        background: ${ColorPalette.DGrey};
      }

      &:active {
        background: ${ColorPalette.IP_Black};
      }

      &:disabled {
        cursor: not-allowed;
        background: ${ColorPalette.LMGrey};
      }
    `};

  ${(props) =>
    props.color === COLOR.INPULSE_FILTER &&
    css`
      height: 40px;
      color: ${props.active ? ColorPalette.IP_BLACK : '#979797'};
      background: ${ColorPalette.WHITE};
      border: 1px solid ${props.active ? ColorPalette.IP_BLACK : '#E2E2E2'};
      border-radius: 8px;
      padding: 0px 16px;
      font: normal 500 12px/16px inter;
      justify-content: flex-start;

      &:hover {
        background: ${ColorPalette.LGrey};
      }

      &:active {
        background: ${ColorPalette.WHITE};
        border-color: ${ColorPalette.IP_Black};
      }

      &:disabled {
        cursor: not-allowed;
        color: ${ColorPalette.WHITE};
        background: ${ColorPalette.LMGrey};
        border-color: ${ColorPalette.LMGrey};
      }
      &:disabled > ${Icon} {
        filter: brightness(0) invert(1);
        transition: all 0.2s ease-in;
      }
    `};

  ${(props) =>
    props.color === COLOR.INPULSE_LINK &&
    css`
      height: 40px;
      color: ${ColorPalette.IP_Black};
      background-color: transparent;
      border: none;
      font: normal 700 14px/16px inter-bold;
      padding: 0px 24px;

      & > ${Icon} {
        height: 16px;
        width: 16px;

        ${props.iconOnLeft &&
        css`
          margin-right: 8px;
        `};
      }

      &:hover {
        color: ${ColorPalette.IP_Green};
        transition: all 0.2s ease-in;
      }
      &:hover > ${Icon} {
        filter: invert(86%) sepia(100%) saturate(2426%) hue-rotate(70deg)
          brightness(99%) contrast(83%);
      }

      &:active {
        color: ${ColorPalette.IP_DGreen};
        transition: all 0.2s ease-in;
      }
      &:active > ${Icon} {
        filter: invert(62%) sepia(33%) saturate(3767%) hue-rotate(107deg)
          brightness(96%) contrast(84%);
      }

      &:disabled {
        cursor: default;
        color: ${ColorPalette.LMGrey};
        transition: all 0.2s ease-in;

        &:hover {
          background-color: transparent;
        }
      }
      &:disabled > ${Icon} {
        ${props.iconOnDisabled
          ? css`
              content: url(${props.iconOnDisabled});
              filter: none;
            `
          : css`
              filter: invert(96%) sepia(0%) saturate(4%) hue-rotate(255deg)
                brightness(93%) contrast(100%);
            `}
      }
    `};

  ${(props) =>
    props.color === COLOR.INPULSE_DELETE &&
    css`
      height: 40px;
      color: ${ColorPalette.WHITE};
      background-color: ${ColorPalette.Info_Red};
      border-radius: 20px;
      padding: ${props.label === '' ? '0px 20px' : '0px 24px'};
      font: normal 700 14px/14px inter;

      &:hover {
        background-color: #d22126;
      }

      &:active {
        background-color: #af1014;
      }

      &:disabled {
        cursor: not-allowed;
        background-color: ${ColorPalette.LMGrey};
      }
      &:disabled > ${Icon} {
        filter: brightness(0) invert(1);
      }
    `};

  ${(props) =>
    props.fadeInOut &&
    css`
      animation: ${gradient} 2s linear infinite;
    `};

  ${(props) =>
    !props.label &&
    (props.icon || props.iconDropdown) &&
    css`
      width: 40px;
      height: 40px;
      padding: 0px;
    `};

  ${(props) =>
    props.customBackground &&
    css`
      background: ${props.customBackground};
    `};
`;

export const DropdownList = styled.div`
  right: 0;
  top: 48px;
  z-index: 10;
  position: absolute;

  min-width: 240px;
  max-height: 500px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background: ${(props) => props.theme.dropdown.background};
  border-radius: ${(props) => props.theme.dropdown.borderRadius};
  box-shadow: ${(props) => props.theme.dropdown.boxShadow};
`;

export const CountDotContainer = styled.div`
  top: -4px;
  right: -4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  background: ${(props) => props.theme.primary.pressed};
  color: ${(props) => props.theme.primary.black};
`;

export const Count = styled.div`
  font-size: 8px;
  display: flex;
  justify-content: center;
`;

export const CountPlusIcon = styled.div`
  font-size: 6px;
  font-weight: 700;
`;

export const DropdownItem = styled.div`
  color: ${(props) =>
    props.theme.dropdown[props.isDisabled ? 'disabled' : 'default'].color};
  background: ${(props) =>
    props.theme.dropdown[props.isDisabled ? 'disabled' : 'default'].background};

  width: 100%;
  height: 34px;
  padding: 12px;

  display: flex;
  align-items: center;

  font: ${(props) => props.theme.dropdown.font};

  &:hover {
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
    color: ${(props) =>
      props.theme.dropdown[props.isDisabled ? 'disabled' : 'hover'].color};
    background: ${(props) =>
      props.theme.dropdown[props.isDisabled ? 'disabled' : 'hover'].background};
  }

  &:first-child {
    border-top-left-radius: ${(props) => props.theme.dropdown.borderRadius};
    border-top-right-radius: ${(props) => props.theme.dropdown.borderRadius};
  }

  &:last-child {
    border-bottom-left-radius: ${(props) => props.theme.dropdown.borderRadius};
    border-bottom-right-radius: ${(props) => props.theme.dropdown.borderRadius};
  }

  ${(props) => {
    if (props.isHighlighted && !props.isDisabled) {
      return css`
        color: ${props.theme.dropdown?.highlighted?.color};
        background: ${props.theme.dropdown?.highlighted?.background};

        &:hover {
          color: ${props.theme.dropdown?.highlighted?.color};
          background: ${props.theme.dropdown?.highlighted?.background};
        }
      `;
    }
    if (props.isHighlighted && props.isDisabled) {
      return css`
        color: ${props.theme.dropdown?.highlighted?.disabled?.color};
        background: ${props.theme.dropdown?.highlighted?.disabled?.background};

        &:hover {
          color: ${props.theme.dropdown?.highlighted?.disabled?.color};
          background: ${props.theme.dropdown?.highlighted?.disabled
            ?.background};
        }
      `;
    }
    return css``;
  }}
`;

export const DropdownItemText = styled.span`
  padding: 0;
  white-space: nowrap;

  width: 100%;
  text-align: left;
`;

export const CustomButton = styled.div`
  cursor: pointer;
`;

export const DropdownListIcon = styled.div`
  width: 16px;
  height: 16px;

  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.icon});
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 12px;

  &:hover ~ div {
    color: #ffffff;
  }
`;

export default {
  StyledButton,
  Icon,
  DropdownList,
  CountDotContainer,
  Count,
  CountPlusIcon,
  DropdownItem,
  IconContainer,
  DropdownItemText,
  DropdownListIcon,
};
