import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  cursor: pointer;
  user-select: none;

  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  &.disabled {
    cursor: default;
  }

  &:hover:not(.disabled) {
    background: rgba(33, 118, 250, 0.1);
  }
`;

export const OuterCircle = styled.div`
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;

  border-radius: 50%;
  border: 2px solid #0a61fe;

  transition: all 0.1s linear;

  &.unselected {
    border: 2px solid #6b768c;
  }
`;

export const InnerCircle = styled.div`
  width: 12px;
  height: 12px;

  margin: 2.2px;

  border-radius: 50%;
  background-color: #0a61fe;
  transition: all 0.1s linear;

  &.unselected-circle {
    width: 0;
    height: 0;
  }
`;

export const Text = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  display: flex;
  align-items: center;

  color: #000000;
`;
