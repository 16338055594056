import PropTypes, { any } from 'prop-types';
import React from 'react';

import { ENUM_MODULE_NAME } from '../../../commons/utils/features';
import { FORECAST_FEATURE_PATHS } from '../../../commons/utils/featurePaths';

import { ModuleContainer } from '../../ModuleContainer';

import ForecastsProvider from '../../../context/ForecastsContext';

export const ForecastForecastContainer = (props) => {
  const { userRights, history } = props;

  return (
    <ForecastsProvider>
      <ModuleContainer
        history={history}
        moduleFeaturePaths={[
          FORECAST_FEATURE_PATHS.FORECAST_FORECAST_TURNOVER,
          FORECAST_FEATURE_PATHS.FORECAST_FORECAST_PRODUCT_MIX,
        ]}
        moduleName={ENUM_MODULE_NAME.FORECAST}
        userRights={userRights}
      />
    </ForecastsProvider>
  );
};

ForecastForecastContainer.propTypes = {
  userRights: PropTypes.array,
  history: any, // react-router-dom's route prop
  moduleName: PropTypes.oneOf(Object.values(ENUM_MODULE_NAME)),
  moduleFeaturePaths: PropTypes.arrayOf(PropTypes.string),
};

export default ForecastForecastContainer;
