import { backoffice as healthCheckService } from '../../../services/backoffice/healthCheck';
import i18next from 'i18next';

/* Health Check Cashier Products */

export const checkCashierProduct = async (
  loadingChecks,
  setLoadingChecks,
  alreadyRunChecks,
  setAlreadyRunChecks,
  setCashierProducts,
  clientId,
  showMessage,
) => {
  try {
    setAlreadyRunChecks({ ...alreadyRunChecks, checkCashierProductAsked: true });
    setLoadingChecks({ ...loadingChecks, isCashierProductLoading: true });
    const result = await healthCheckService.healthCheckCashierProduct(clientId);

    setCashierProducts(result);
  } catch (error) {
    showMessage(i18next.t('BACKOFFICE.CHECKS.CASHIER_PRODUCT_EXPORT_FAILURE'), 'error');
  } finally {
    setLoadingChecks({ ...loadingChecks, isCashierProductLoading: false });
  }
};

/* Health Check Product */

export const checkProduct = async (
  loadingChecks,
  setLoadingChecks,
  alreadyRunChecks,
  setAlreadyRunChecks,
  setProducts,
  clientId,
  showMessage,
) => {
  try {
    setAlreadyRunChecks({ ...alreadyRunChecks, checkProductAsked: true });
    setLoadingChecks({ ...loadingChecks, isProductLoading: true });
    const result = await healthCheckService.healthCheckProduct(clientId);

    setProducts(result);
  } catch (error) {
    showMessage(i18next.t('BACKOFFICE.CHECKS.PRODUCT_EXPORT_FAILURE'), 'error');
  } finally {
    setLoadingChecks({ ...loadingChecks, isProductLoading: false });
  }
};

export const checkProductPriceAtNullOrZero = async (
  loadingChecks,
  setLoadingChecks,
  alreadyRunChecks,
  setAlreadyRunChecks,
  setProductsPriceAtNullOrZero,
  clientId,
  showMessage,
) => {
  try {
    setAlreadyRunChecks({ ...alreadyRunChecks, checkProductPriceAtNullOrZeroAsked: true });
    setLoadingChecks({ ...loadingChecks, isProductPriceAtNullOrZeroLoading: true });
    const result = await healthCheckService.healthCheckProductPrice(clientId);

    setProductsPriceAtNullOrZero(result);
  } catch (error) {
    showMessage(i18next.t('BACKOFFICE.CHECKS.PRODUCT_EXPORT_FAILURE'), 'error');
  } finally {
    setLoadingChecks({ ...loadingChecks, isProductPriceAtNullOrZeroLoading: false });
  }
};

export const checkProductVatRateInferiorAt1 = async (
  loadingChecks,
  setLoadingChecks,
  alreadyRunChecks,
  setAlreadyRunChecks,
  setProductsVatRateInferiorAt1,
  clientId,
  showMessage,
) => {
  try {
    setAlreadyRunChecks({ ...alreadyRunChecks, checkProductVatRateInferiorAt1Asked: true });
    setLoadingChecks({ ...loadingChecks, isProductVatRateInferiorAt1Loading: true });
    const result = await healthCheckService.healthCheckProductVatRate(clientId);

    setProductsVatRateInferiorAt1(result);
  } catch (error) {
    showMessage(i18next.t('BACKOFFICE.CHECKS.PRODUCT_EXPORT_FAILURE'), 'error');
  } finally {
    setLoadingChecks({ ...loadingChecks, isProductVatRateInferiorAt1Loading: false });
  }
};

/* Health Check Weather Station */

export const checkWeatherStation = async (
  loadingChecks,
  setLoadingChecks,
  alreadyRunChecks,
  setAlreadyRunChecks,
  setWeatherStations,
  clientId,
  showMessage,
  distance,
) => {
  try {
    setAlreadyRunChecks({ ...alreadyRunChecks, checkWeatherStationAsked: true });
    setLoadingChecks({ ...loadingChecks, isWeatherStationLoading: true });
    const result = await healthCheckService.healthCheckWeatherStation(clientId, distance);

    setWeatherStations(result);
  } catch (error) {
    showMessage(i18next.t('BACKOFFICE.CHECKS.WEATHER_STATION_EXPORT_FAILURE'), 'error');
  } finally {
    setLoadingChecks({ ...loadingChecks, isWeatherStationLoading: false });
  }
};

/* Health Check Supplier Product */

export const checkSupplierProductUnmapped = async (
  loadingChecks,
  setLoadingChecks,
  alreadyRunChecks,
  setAlreadyRunChecks,
  setSupplierProductUnmapped,
  clientId,
  showMessage,
) => {
  try {
    setAlreadyRunChecks({ ...alreadyRunChecks, checkSupplierProductUnmappedAsked: true });
    setLoadingChecks({ ...loadingChecks, isSupplierProductUnmappedLoading: true });
    const result = await healthCheckService.healthCheckSupplierProduct(clientId);

    setSupplierProductUnmapped(result);
  } catch (error) {
    showMessage(i18next.t('BACKOFFICE.CHECKS.SUPPLIER_PRODUCT_EXPORT_FAILURE'), 'error');
  } finally {
    setLoadingChecks({ ...loadingChecks, isSupplierProductUnmappedLoading: false });
  }
};

export const checkSupplierProductUnit = async (
  loadingChecks,
  setLoadingChecks,
  alreadyRunChecks,
  setAlreadyRunChecks,
  setSupplierProductUnit,
  clientId,
  showMessage,
) => {
  try {
    setAlreadyRunChecks({ ...alreadyRunChecks, checkSupplierProductUnitAsked: true });
    setLoadingChecks({ ...loadingChecks, isSupplierProductUnitLoading: true });
    const result = await healthCheckService.healthCheckSupplierProductUnit(clientId);

    setSupplierProductUnit(result);
  } catch (error) {
    showMessage(i18next.t('BACKOFFICE.CHECKS.SUPPLIER_PRODUCT_EXPORT_FAILURE'), 'error');
  } finally {
    setLoadingChecks({ ...loadingChecks, isSupplierProductUnitLoading: false });
  }
};

export const checkSupplierProductPriceAtNullOrZero = async (
  loadingChecks,
  setLoadingChecks,
  alreadyRunChecks,
  setAlreadyRunChecks,
  setSupplierProductPriceAtNullOrZero,
  clientId,
  showMessage,
) => {
  try {
    setAlreadyRunChecks({ ...alreadyRunChecks, checkSupplierProductPriceAtNullOrZeroAsked: true });
    setLoadingChecks({ ...loadingChecks, isSupplierProductPriceAtNullOrZeroLoading: true });
    const result = await healthCheckService.healthCheckSupplierProductPrice(clientId);

    setSupplierProductPriceAtNullOrZero(result);
  } catch (error) {
    showMessage(i18next.t('BACKOFFICE.CHECKS.SUPPLIER_PRODUCT_EXPORT_FAILURE'), 'error');
  } finally {
    setLoadingChecks({ ...loadingChecks, isSupplierProductPriceAtNullOrZeroLoading: false });
  }
};

/* Health Check Ingredient */

export const checkIngredientPriceAtNullOrZero = async (
  loadingChecks,
  setLoadingChecks,
  alreadyRunChecks,
  setAlreadyRunChecks,
  setIngredientPriceAtNullOrZero,
  clientId,
  showMessage,
) => {
  try {
    setAlreadyRunChecks({ ...alreadyRunChecks, checkIngredientPriceAtNullOrZeroAsked: true });
    setLoadingChecks({ ...loadingChecks, isIngredientPriceAtNullOrZeroLoading: true });
    const result = await healthCheckService.healthCheckIngredientPrice(clientId);

    setIngredientPriceAtNullOrZero(result);
  } catch (error) {
    showMessage(i18next.t('BACKOFFICE.CHECKS.INGREDIENT_EXPORT_FAILURE'), 'error');
  } finally {
    setLoadingChecks({ ...loadingChecks, isIngredientPriceAtNullOrZeroLoading: false });
  }
};

export const checkIngredientUnmapped = async (
  loadingChecks,
  setLoadingChecks,
  alreadyRunChecks,
  setAlreadyRunChecks,
  setIngredientUnmapped,
  clientId,
  showMessage,
) => {
  try {
    setAlreadyRunChecks({ ...alreadyRunChecks, checkIngredientUnmappedAsked: true });
    setLoadingChecks({ ...loadingChecks, isIngredientUnmappedLoading: true });
    const result = await healthCheckService.healthCheckIngredient(clientId);

    setIngredientUnmapped(result);
  } catch (error) {
    showMessage(i18next.t('BACKOFFICE.CHECKS.INGREDIENT_EXPORT_FAILURE'), 'error');
  } finally {
    setLoadingChecks({ ...loadingChecks, isIngredientUnmappedLoading: false });
  }
};

export default {
  checkCashierProduct,
  checkProduct,
  checkProductPriceAtNullOrZero,
  checkProductVatRateInferiorAt1,
  checkWeatherStation,
  checkSupplierProductUnmapped,
  checkSupplierProductUnit,
  checkSupplierProductPriceAtNullOrZero,
  checkIngredientUnmapped,
  checkIngredientPriceAtNullOrZero,
};
