const stockFormReducer = (state = {}, action) => {
  switch (action.type) {
    case 'STOCK_FORM_UPDATE_VALUE':
      return Object.assign({}, state, {
        ...state,
        totByStorageAreaIdSPIdAndPackagingId: {
          ...state.totByStorageAreaIdSPIdAndPackagingId,
          [action.storageAreaId]: {
            ...state.totByStorageAreaIdSPIdAndPackagingId[action.storageAreaId],
            [action.supplierProductId]: action.updatedTotByPackagingId,
          },
        },
      });

    case 'STOCK_FORM_UPDATE_RECIPE_VALUE':
      return Object.assign({}, state, {
        ...state,
        totByStorageAreaIdAndRecipeId: {
          ...state.totByStorageAreaIdAndRecipeId,
          [action.storageAreaId]: {
            ...state.totByStorageAreaIdAndRecipeId[action.storageAreaId],
            [action.recipeId]: action.updatedTotal,
          },
        },
      });

    case 'STOCK_FORM_RESET_VALUES':
      return Object.assign({}, state, {
        totByStorageAreaIdSPIdAndPackagingId: {},
        totByStorageAreaIdAndRecipeId: {},
      });

    case 'STOCK_FORM_INIT_VALUES':
      return Object.assign({}, state, {
        totByStorageAreaIdSPIdAndPackagingId: action.totByStorageAreaIdSPIdAndPackagingId,
        totByStorageAreaIdAndRecipeId: action.totByStorageAreaIdAndRecipeId,
      });

    default:
      return state;
  }
};

export default stockFormReducer;
