import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;

  background-color: ${(props) => props.theme.colors?.greys?.lighter};
`;

export const Text = styled.div`
  font: ${(props) => props.theme.fonts?.textBigHeight16};
  color: ${(props) => props.theme.colors?.greys?.darkest};

  ${(props) => {
    if (props.capitalize) {
      return css`
        text-transform: capitalize;
      `;
    }

    return css``;
  }}
`;

export const ActivationsContainer = styled.div`
  display: flex;
`;

export const InlineContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  > ${Text} {
    margin-left: 8px;
  }
`;
