import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
`;

export const LegendContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const LegendFirstRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LegendRow = styled.div`
  width: 100%;
  height: 26px;

  display: flex;
  flex-direction: row;
  column-gap: 16px;

  font: ${(props) => props.theme.fonts?.textSmallHeight16};
  color: ${(props) => props.theme.colors?.greys?.darker};

  align-items: center;
`;

export const ColoredBall = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 100%;

  background-color: ${(props) => props.backgroundColor || props.theme.colors?.infoGreen};
`;
