import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 22px;
`;

export const DropdownContainer = styled.div`
  display: flex;

  margin-bottom: 24px;
`;

export const DropdownItem = styled.div`
  width: 100%;
  padding: 12px 8px;
  display: flex;
`;

export const DropdownIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  margin-top: 6px;
`;

export const Text = styled.div`
  font: ${(props) => props.theme.fonts.textBig};
  color: ${(props) => props.theme.colors.greys.darkest};
  ${(props) => {
    if (props.bold) {
      return css`
        font: ${(props) => props.theme.fonts.textMiddleBold};
      `;
    }
  }}
`;

export default {
  Container,
};
