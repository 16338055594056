import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import DisplayNumber from '@commons/DisplayNumber';
import Text from '@commons/Text';

export const getColumns = () => [
  {
    id: 'storeName',
    propertyKey: 'storeName',
    name: i18next.t('GENERAL.KITCHEN'),
  },
  {
    id: 'inventoryDate',
    propertyKey: 'inventoryDate',
    name: i18next.t('GENERAL.DATE'),
    filterType: 'date',
    render: (item) => (
      <Text>{moment(item).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)}</Text>
    ),
  },
  {
    id: 'total',
    propertyKey: 'total',
    name: i18next.t('GENERAL.TOTAL'),
    filterType: 'numeric',
    render: (item) => <DisplayNumber displayCurrencyCode={true} number={item || 0} />,
  },
  {
    id: 'reference',
    propertyKey: 'reference',
    name: 'reference', // no need to be translated
    hidden: true,
  },
];

export const getRecipeInventoryFormColumns = ({ hasMultipleBrands }) => {
  const columns = [
    {
      propertyKey: 'name',
      label: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_DESIGNATION'),
      displayImage: true,
    },
    {
      propertyKey: 'cost',
      label: i18next.t('GENERAL.COST_EX_TAX'),
    },
    {
      propertyKey: 'unit',
      label: i18next.t('STOCKS.CURRENT_STOCKS.COLUMN_NAME_STOCK'),
    },
    {
      propertyKey: 'tot',
      label: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_TOTAL_COST_EX_TAX'),
    },
  ];

  if (hasMultipleBrands) {
    columns.splice(1, 0, {
      propertyKey: 'brand',
      label: i18next.t('GENERAL.BRAND'),
    });
  }

  return columns;
};
