import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { openGenericModal, refreshGenericModal } from '@actions/modal';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { ListView } from '@commons/utils/styledLibraryComponents';

import { Container } from './styledComponents';
import { EMPTY_STATE_TYPES } from './utils/constants';
import { getColumns } from './utils/getColumns';
import { getRowActions, getListViewActions } from './utils/getActions';
import { renderEmptyState } from './components/EmptyState';
import getStoreAdditionModalConfig from './utils/getModalParams';

const RecipeProduction = ({
  recipe,
  associatedProduct,
  mappedStoreEntityMappings,
  setMappedStoreEntityMappings,
  notMappedStoreEntityMappings,
  setNotMappedStoreEntityMappings,
  selectedStoreEntityMappings,
  setSelectedStoreEntityMappings,
  setWarningModalParams,
  // Redux
  openGenericModal,
  refreshGenericModal,
}) => {
  const [emptyStateType, setEmptyStateType] = useState('');

  useEffect(() => {
    if (isEmpty(associatedProduct)) {
      setEmptyStateType(EMPTY_STATE_TYPES.RECIPE_NOT_SOLD);
      return;
    }

    if (!mappedStoreEntityMappings.length) {
      setEmptyStateType(EMPTY_STATE_TYPES.NO_CENTRAL);
      return;
    }
  }, [associatedProduct, mappedStoreEntityMappings]);

  useEffect(() => {
    const params = getStoreAdditionModalConfig({
      setSelectedStoreEntityMappings,
      selectedStoreEntityMappings,
      notMappedStoreEntityMappings,
      handleAddingStores,
    });

    refreshGenericModal(params);
  }, [selectedStoreEntityMappings]);

  const handleAddingStores = () => {
    const formattedSelectedMappings = selectedStoreEntityMappings.map(
      ({ storeId, storeName, storeCity, storeCountry, entityId }) => ({
        storeId,
        storeName,
        storeCity,
        storeCountry,
        entityId,
      }),
    );

    // Update available store mappings
    const updatedNotMappedStoreEntityMappings = notMappedStoreEntityMappings.filter(
      ({ storeId }) => !formattedSelectedMappings.some((item) => item.storeId === storeId),
    );
    setNotMappedStoreEntityMappings(updatedNotMappedStoreEntityMappings);

    // Update mapped stores list
    const updatedMappedStoreEntityMappings =
      mappedStoreEntityMappings.concat(formattedSelectedMappings);
    setMappedStoreEntityMappings(updatedMappedStoreEntityMappings);

    setSelectedStoreEntityMappings([]);
  };

  const handleAddStoreEntityMappingInProductionPlanning = () => {
    const params = getStoreAdditionModalConfig({
      setSelectedStoreEntityMappings,
      notMappedStoreEntityMappings,
      handleAddingStores,
    });

    openGenericModal(params);
  };

  const getWarningModalParams = (recipeName, storeEntityMapping) =>
    getConfirmationModal({
      title: i18next.t('ADMIN.RECIPES.PRODUCTION_DELETE_ASSOCIATION_MODAL_TITLE'),
      icon: '/images/inpulse/warning-white-small.svg',
      content: i18next.t('ADMIN.RECIPES.PRODUCTION_DELETE_ASSOCIATION_MODAL_CONTENT', {
        recipeName,
        storeName: storeEntityMapping.storeName,
      }),
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: () => {
            const notMappedStoreEntityMappingsAfterUpdate = notMappedStoreEntityMappings.concat([
              storeEntityMapping,
            ]);

            setNotMappedStoreEntityMappings(notMappedStoreEntityMappingsAfterUpdate);

            const mappedStoreEntityMappingsAfterUpdate = mappedStoreEntityMappings.filter(
              ({ storeId }) => storeId !== storeEntityMapping.storeId,
            );
            setMappedStoreEntityMappings(mappedStoreEntityMappingsAfterUpdate);
          },
        },
      ],
    });

  return (
    <Container>
      {!mappedStoreEntityMappings.length ? (
        renderEmptyState(emptyStateType, handleAddStoreEntityMappingInProductionPlanning)
      ) : (
        <ListView
          actions={getListViewActions({ handleAddStoreEntityMappingInProductionPlanning })}
          columns={getColumns()}
          data={mappedStoreEntityMappings}
          defaultOrderBy={'storeName'}
          defaultOrderType={'asc'}
          disableFooter={true}
          disableSelection={true}
          minActionsInActionsDropdown={1}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          rowActions={getRowActions({ recipe, getWarningModalParams, setWarningModalParams })}
        />
      )}
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
});

export default connect(null, mapDispatchToProps)(RecipeProduction);
