export function areUnitsSame(supplierProductUnit, ingredientUnit) {
  if (supplierProductUnit === 'kg' && ingredientUnit !== 'g') return false;
  else if (supplierProductUnit === 'L' && ingredientUnit !== 'mL') return false;
  else if (supplierProductUnit === 'unit' && ingredientUnit !== 'unit') return false;
  return true;
}

export default {
  areUnitsSame,
};
