import { connect } from 'react-redux';
import { head } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Button, InputIncrement } from '@commons/utils/styledLibraryComponents';
import { GenericModalContainer } from '@commons/Modals/GenericModal/styledComponents';
import { sortPackagings } from '@commons/utils/format';
import GenericModal from '@commons/Modals/GenericModal';
import Text, { ENUM_COLORS } from '@commons/Text';

import { STOCK_FORM_TYPES } from '@stocks/StocksInventories/components/StockForm/constants/types';
import { UNIT } from '@stocks/StocksInventories/components/StockForm/constants/unit';

import { Item, InputContainerQuantityMultiPackaging } from '../styledComponents';

import { formatInputValue, getStockModalParams } from './utils';

const DEFAULT_VALUE_NO_STOCK = '--';

const QuantityByPackaging = ({
  name,
  price,
  onChange,
  readOnly,
  nbColumns,
  stockType,
  packagings,
  allPackagings,
  totByPackagingId,
}) => {
  const [modalParams, setModalParams] = useState(null);
  const [totByPackagingIdEdition, setTotByPackagingIdEdition] = useState({});

  let isDirty = false;

  const packagingNames = sortPackagings(allPackagings).reduce((result, packaging) => {
    if (!packaging.isUsedInStock) {
      return result;
    }

    const value = totByPackagingId[packaging.id];

    if (value == null) {
      result.push(DEFAULT_VALUE_NO_STOCK);

      return result;
    }

    if (!isDirty) {
      isDirty = true;
    }

    result.push(value);

    return result;
  }, []);

  const openStockModal = () => {
    const params = getStockModalParams({
      name,
      price,
      isDirty,
      onChange,
      allPackagings,
      totByPackagingIdEdition,
      setTotByPackagingIdEdition,
      isStock:
        stockType.toLowerCase() === STOCK_FORM_TYPES.STOCK ||
        stockType.toLowerCase() === STOCK_FORM_TYPES.TRANSFER,
      stockType,
    });

    setModalParams(params);
  };

  // Trigger refresh modal
  useEffect(() => {
    if (!modalParams) {
      return;
    }

    openStockModal();
  }, [totByPackagingIdEdition]);

  // Keep updated temporary tot by packaging id
  useEffect(() => {
    setTotByPackagingIdEdition(totByPackagingId);
  }, [totByPackagingId]);

  return (
    <Item nbColumns={nbColumns}>
      <InputContainerQuantityMultiPackaging>
        <Text color={!isDirty ? ENUM_COLORS.DARKER : ENUM_COLORS.DARKEST}>
          {packagingNames.join(' | ')}
        </Text>
        <Button
          buttonCustomStyle={{ borderRadius: '8px' }}
          handleClick={openStockModal}
          icon={
            !isDirty
              ? '/images/inpulse/add-inpulse-white-small.svg'
              : '/images/inpulse/edit-pen-white-small.svg'
          }
          iconCustomStyle={{
            width: !isDirty ? '10px' : '14px',
            height: !isDirty ? '10px' : '14px',
          }}
          isDisabled={readOnly}
        />
      </InputContainerQuantityMultiPackaging>
      {modalParams && (
        <GenericModalContainer>
          <GenericModal
            closeGenericModal={() => {
              setModalParams(null);
              setTotByPackagingIdEdition(totByPackagingId);
            }}
            component={modalParams.component}
            params={modalParams}
          />
        </GenericModalContainer>
      )}
    </Item>
  );
};

const Quantity = (props) => {
  const { packagings, totByPackagingId, handleTotByPackagingId, nbColumns, readOnly, currency } =
    props;

  if (!packagings.length) {
    return <Item nbColumns={nbColumns}>- {currency.alphabeticCode}</Item>;
  }

  if (packagings.length > 1) {
    return <QuantityByPackaging {...props} onChange={handleTotByPackagingId} />;
  }

  const packaging = !packagings.length ? {} : head(packagings);

  const value = totByPackagingId[packaging.id];

  const onChange = (value) => {
    handleTotByPackagingId({ ...totByPackagingId, [packaging.id]: formatInputValue(value) });
  };

  return (
    <Item nbColumns={nbColumns}>
      <InputIncrement
        allowNull={true}
        enableNullValue={true}
        height="40px"
        isDisabled={readOnly}
        limitValues={{ min: 0 }}
        placeholder={''}
        setValue={onChange}
        value={value || value === 0 ? value : ''}
        width="160px"
      />
    </Item>
  );
};

Quantity.propTypes = {
  name: PropTypes.string,
  supplierProductId: PropTypes.string,
  price: PropTypes.number,
  readOnly: PropTypes.bool,
  currency: PropTypes.object, //send by base reducer
  nbColumns: PropTypes.number,
  handleTotByPackagingId: PropTypes.func.isRequired,
  totByPackagingId: PropTypes.objectOf(PropTypes.any), // number or null
  packagings: PropTypes.arrayOf(
    PropTypes.shape({
      unit: PropTypes.oneOf(UNIT),
      quantity: PropTypes.number.isRequired,
    }),
  ),
  allPackagings: PropTypes.arrayOf(
    PropTypes.shape({
      unit: PropTypes.oneOf(UNIT),
      quantity: PropTypes.number.isRequired,
    }),
  ),
  stockType: PropTypes.string.isRequired,
};

Quantity.defaultProps = {
  name: '',
  price: null,
  nbColumns: 1,
  readOnly: false,
  packagings: [],
  allPackagings: [],
  totByPackagingId: {},
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps)(Quantity);
