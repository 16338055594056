import styled from 'styled-components';

export const Container = styled.div`
  height: ${(props) => props.height || '100%'};
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
`;

export const DropdownContainer = styled.div`
  min-height: 68px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 8px;
`;

export const LabelContainer = styled.div`
  width: 240px;
  display: flex;
  flex-direction: row;
`;

export const LabelRequiredCharacter = styled.div`
  margin-right: 2px;

  color: ${(props) => props.theme.dropdown.labelRequiredCharacter.default.color};
`;
