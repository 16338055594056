import { get } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { getTheme } from '@commons/utils/theme';
import { Label } from '@commons/utils/styledLibraryComponents';

import { PAST_STOCKS_TYPE } from '@stocks/utils/constants';
import stockUtils from '@stocks/utils';

const GAP_VALUE_LIMIT = 20;

const theme = getTheme();

const getListConfig = (pastStockType, selectedMetric, currency) => {
  const metricKey = get(selectedMetric, 'key', null);

  return [
    {
      id: 0,
      name: i18next.t('GENERAL.NAME'),
      propertyKey: 'name',
      large: true,
      isSortable: true,
    },
    {
      id: 1,
      name: i18next.t('GENERAL.TYPE'),
      propertyKey: 'type',
      isSortable: true,
    },
    {
      id: 2,
      name: i18next.t('STOCKS.STOCKS.THEORETICAL_STOCK_SINGULAR_SHORT'),
      propertyKey: `theoricalStock.${metricKey}`,
      tooltipDisplay: i18next.t(
        pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS
          ? 'STOCKS.PAST_STOCKS.INGREDIENT_COLUMN_THEORETICAL_STOCK_TOOLTIP'
          : 'STOCKS.PAST_STOCKS.RECIPE_COLUMN_THEORETICAL_STOCK_TOOLTIP',
      ),
      isNumber: true,
      render: (value, item) => {
        if (value == null) {
          return (
            <Label
              background={theme.colors.highlightedBlue}
              children={'--'}
              color={theme.colors.highlightedBlue}
              labelTooltip={
                // === false and not !item.data because it can be null and !null = true
                item.isIngredient === false && item.isProduced === false
                  ? i18next.t('STOCKS.PAST_STOCKS_RECIPES.PAST_STOCKS_NOT_PRODUCED_LABEL_TOOLTIP')
                  : null
              }
              tooltipWidth={'248px'}
              type={''}
            />
          );
        }

        return (
          <Label
            background={theme.colors.highlightedBlue}
            children={stockUtils.getStockValueByMetric(value, item, metricKey, currency)}
            color={theme.colors.highlightedBlue}
            type={''}
          />
        );
      },
      isSortable: true,
    },
    {
      id: 3,
      name: i18next.t('STOCKS.STOCKS.REAL_STOCK_SINGULAR'),
      propertyKey: `${
        pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS
          ? 'realStockWithRecipes'
          : 'realStock'
      }.${metricKey}`,
      tooltipDisplay: i18next.t(
        pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS
          ? 'STOCKS.PAST_STOCKS.INGREDIENT_COLUMN_REAL_STOCK_TOOLTIP'
          : 'STOCKS.PAST_STOCKS.RECIPE_COLUMN_REAL_STOCK_TOOLTIP',
      ),
      isNumber: true,
      render: (value, item) => {
        if (value == null) {
          return (
            <Label
              background={theme.colors.greys.dark}
              children={'--'}
              color={theme.colors.greys.dark}
              type={''}
            />
          );
        }

        return (
          <Label
            background={theme.colors.greys.dark}
            children={stockUtils.getStockValueByMetric(value, item, metricKey, currency)}
            color={theme.colors.greys.dark}
            type={''}
          />
        );
      },
      isSortable: true,
    },
    {
      id: 4,
      name: i18next.t('STOCKS.PAST_STOCKS.GAP'),
      propertyKey: `stockGapValue.${metricKey}`,
      tooltipDisplay: i18next.t(
        pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS
          ? 'STOCKS.PAST_STOCKS.INGREDIENT_COLUMN_GAP_TOOLTIP'
          : 'STOCKS.PAST_STOCKS.RECIPE_COLUMN_GAP_TOOLTIP',
      ),
      isNumber: true,
      render: (value, item) =>
        stockUtils.renderStockGap(value, item, metricKey, currency, GAP_VALUE_LIMIT),
      isSortable: true,
    },
    {
      id: 5,
      name: i18next.t('STOCKS.PAST_STOCKS.GAP_PERCENT'),
      propertyKey: 'stockGapPercentage',
      tooltipDisplay: i18next.t(
        pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS
          ? 'STOCKS.PAST_STOCKS.INGREDIENT_COLUMN_GAP_PERCENT_TOOLTIP'
          : 'STOCKS.PAST_STOCKS.RECIPE_COLUMN_GAP_PERCENT_TOOLTIP',
      ),
      isNumber: true,
      render: (value) => stockUtils.renderGapPercentage(value, GAP_VALUE_LIMIT),
      isSortable: true,
    },
    {
      id: 6,
      name: i18next.t('STOCKS.PAST_STOCKS.LAST_INVENTORIES'),
      tooltipDisplay: i18next.t('STOCKS.PAST_STOCKS.COLUMN_LAST_INVENTORIES_TOOLTIP'),
      propertyKey: 'lastInventories',
      customModalRender: true,
      isSortable: false,
    },
    {
      id: 7,
      name: '',
      propertyKey: 'action',
      isClickable: true,
      isSortable: false,
    },
  ];
};

const getModalConfig = () => [
  { propertyKey: 'url' },
  {
    propertyKey: 'inventoryListReference',
    bold: true,
  },
  {
    propertyKey: 'quantity',
    bold: true,
  },
  { propertyKey: 'inventoryDate' },
];

export default {
  getListConfig,
  getModalConfig,
};
