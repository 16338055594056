import * as yup from 'yup';
import i18next from 'i18next';

// COMMONS
import { INPUT_TYPES } from '@commons/constants/inputType';
import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';

const CATALOG_INPUTS = {
  NAME: 'name',
};

export const FIELDS_TO_CHECK = ['name'];

export const CATALOG_INFOS_INPUTS = (catalog) => [
  {
    type: INPUT_TYPES.TEXT,
    name: CATALOG_INPUTS.NAME,
    width: INPUT_WIDTH.LARGE,
    isDisabled: () => !!catalog && catalog.isMasterCatalog,
    isRequired: true,
    label: `* ${i18next.t('GENERAL.NAME')}`,
    rule: yup.string().required(
      i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
        field: i18next.t('GENERAL.NAME'),
      }),
    ),
  },
];

export const CATALOG_FORM_INPUTS = (catalog) => [...CATALOG_INFOS_INPUTS(catalog)];
