import i18next from 'i18next';
import React from 'react';

import { Checkbox } from '@commons/utils/styledLibraryComponents';
import Text from '@commons/Text';

const RenderCheckBox = ({ item, propertyKey, onChange }) => {
  if (!item) {
    return '';
  }

  const value = item[propertyKey];

  return (
    <Checkbox
      handleClick={() =>
        onChange(
          { id: item.id, supplierProfileId: item.supplierProfileId },
          {
            [propertyKey]: !value,
          },
        )
      }
      isChecked={value}
      shape="square"
    />
  );
};

const getColumns = ({ onChange }) => [
  {
    id: 'store-name',
    propertyKey: 'name',
    name: i18next.t('GENERAL.NAME'),
    render: (item) => <Text>{item}</Text>,
  },
  {
    id: 'is-kitchen',
    propertyKey: 'isKitchen',
    name: i18next.t('GENERAL.STORE_TYPE'),
    render: (item) => (
      <Text>{item ? i18next.t('GENERAL.CENTRAL') : i18next.t('GENERAL.SALE_POINT')}</Text>
    ),
  },
  {
    id: 'supplier-name',
    propertyKey: 'supplierName',
    name: i18next.t('GENERAL.SUPPLIER'),
    render: (item) => <Text>{item}</Text>,
  },
  {
    id: 'supplier-profile-name',
    propertyKey: 'supplierProfileName',
    name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_COLUMN_SUPPLIER_PROFILE'),
    render: (item) => <Text>{item}</Text>,
  },
  {
    id: 'available',
    excludeFromSearch: true,
    propertyKey: 'available',
    name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_COLUMN_AVAILABLE'),
    render: (_, item) => (
      <RenderCheckBox item={item} propertyKey={'available'} onChange={onChange} />
    ),
  },
  {
    id: 'has-stock',
    excludeFromSearch: true,
    propertyKey: 'hasStock',
    name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_COLUMN_HAS_STOCK'),
    render: (_, item) => (
      <RenderCheckBox item={item} propertyKey={'hasStock'} onChange={onChange} />
    ),
  },
  {
    id: 'has-dlc',
    excludeFromSearch: true,
    propertyKey: 'hasDlc',
    name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_COLUMN_HAS_DLC'),
    render: (_, item) => <RenderCheckBox item={item} propertyKey={'hasDlc'} onChange={onChange} />,
  },
];

export { getColumns };
