import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import { Franco, Label } from '@commons/utils/styledLibraryComponents';
import { getUserTimezone } from '@commons/utils/date';
import { ListView } from '@commons/utils/styledLibraryComponents';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import DisplayNumber from '@commons/DisplayNumber';
import EmptyState from '@commons/EmptyState';

import theme from '@theme';

import ORDER_STATUS from '../../../OrderForm/constants/status';
import utilsLib from '../../../OrderForm/utils';

import { Container, FrancoContainer, ItemListContainer } from './styledComponents';

const HEADERS = [
  {
    id: 0,
    name: i18next.t('GENERAL.STORE_NAME_LABEL_ESTABLISHMENT'),
    type: 'string',
    propertyKey: 'storeName',
    highlighted: true,
    filterType: 'string',
  },
  {
    id: 1,
    name: i18next.t('GENERAL.SUPPLIER'),
    type: 'string',
    propertyKey: 'supplierName',
    filterType: 'string',
  },
  {
    id: 2,
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_DELIVERY_DATE'),
    type: 'string',
    propertyKey: 'startOrderDate',
    filterType: 'date',
  },
  {
    id: 3,
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_CREATION_DATE'),
    type: 'string',
    propertyKey: 'orderDate',
    filterType: 'date',
  },
  {
    id: 4,
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_STATUS'),
    type: 'string',
    propertyKey: 'status',
    filterType: 'string',
    render: () => (
      <Label
        background={theme?.colors?.greys?.dark}
        color={theme?.colors?.greys?.dark}
        type="secondary"
        width={'fit-content'}
      >
        Brouillon
      </Label>
    ),
  },
  {
    id: 5,
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_TOTAL_EX_TAX'),
    type: 'currency',
    propertyKey: 'totalPrice',
    filterType: 'numeric',
    render: (item) => <DisplayNumber displayCurrencyCode={true} number={item} />,
  },
];

const GroupOrderBody = (props) => {
  const {
    user,
    orders,
    currency,
    selectedOrders,
    setSelectedOrders,
    selectedSupplier,
    isEmpty,
    supplierProfile,
    setSupplierProfile,
    orderedUnit,
    setOrderedUnit,
    selectedOrdersTotalPrice,
    setSelectedOrdersTotalPrice,
    orderTimeLimit,
    setOrderTimeLimit,
    storeTimezone,
  } = props;

  const [filteredData, setFilteredData] = useState([]);
  const [forceRender, setForceRender] = useState(false);
  const [orderMinAmount, setOrderMinAmount] = useState(null);
  const [orderMinUnit, setOrderMinUnit] = useState(null);

  const userLanguageCode = _.get(user, 'lnkLanguageAccountrel.code', 'fr');

  useEffect(() => {
    let tempOrderList = [];
    let tmpOrders = _.cloneDeep(orders.orders);
    if (!tmpOrders) {
      return;
    }

    (async function loadData() {
      for (const order of tmpOrders) {
        let formattedOrder = order;

        if (
          formattedOrder.status &&
          formattedOrder.status === String(ORDER_STATUS.DRAFT) &&
          moment.tz(formattedOrder.startOrderDate, storeTimezone).isAfter(moment.tz(storeTimezone))
        ) {
          const supplierProfile = await utilsLib.getSupplierProfileBySupplierIdAndStoreId(
            selectedSupplier.id,
            formattedOrder.storeId,
          );
          formattedOrder.orderDate = moment
            .tz(formattedOrder.orderDate, getUserTimezone())
            .format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);
          formattedOrder.startOrderDate = moment
            .tz(formattedOrder.startOrderDate, storeTimezone)
            .format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);
          if (formattedOrder.lnkStoreOrderrel) {
            formattedOrder.storeName = formattedOrder.lnkStoreOrderrel.name;
          }
          if (formattedOrder.lnkSupplierOrderrel) {
            formattedOrder.supplierName = formattedOrder.lnkSupplierOrderrel.name;
          }
          formattedOrder.supplierProfile = supplierProfile;
          tempOrderList.push(formattedOrder);
        }
      }
      setFilteredData(tempOrderList);
    })();
  }, [orders]);

  //Actions on orders selection
  useEffect(() => {
    let tmpOrders = [...filteredData];
    let tmpTotalPrice = 0;
    let totalItems = 0;

    if (tmpOrders.length === 0) return;

    if (selectedOrders.length === 0) {
      tmpOrders.forEach((item) => {
        item.isRowSelected = false;
        item.isNotSelectable = false;
      });
      setFilteredData(tmpOrders);
      setForceRender(true);
      setSelectedOrdersTotalPrice(null);
      setOrderedUnit(null);
      setOrderTimeLimit(null);
      setOrderMinUnit(null);
      setOrderMinAmount(null);
      return;
    }

    const selectedOrdersKeyBy = _.keyBy(selectedOrders, 'id');

    tmpOrders.forEach((item) => {
      if (selectedOrdersKeyBy[item.id]) {
        item.isRowSelected = true;
      } else {
        item.isRowSelected = false;
      }
      if (
        item.startOrderDate !== selectedOrders[0].startOrderDate ||
        item.supplierProfile.id !== selectedOrders[0].supplierProfile.id
      ) {
        item.isNotSelectable = true;
        return;
      }
      item.isNotSelectable = false;
    });

    setFilteredData(tmpOrders);
    setForceRender(true);

    selectedOrders.forEach((order) => {
      tmpTotalPrice += order.totalPrice;
      totalItems += order.totalItems;
    });

    setSelectedOrdersTotalPrice(Math.round(tmpTotalPrice * 100) / 100);
    setOrderedUnit(totalItems);

    const defaultSelectedOrder = selectedOrders[0];
    if (defaultSelectedOrder.startOrderDate) {
      const selectedOrderValid = orders.orders.find((item) => item.id === selectedOrders[0].id);

      if (defaultSelectedOrder.supplierProfile) {
        let limitOrderDay = utilsLib.getMaxOrderDateOfSupplierFromDate(
          defaultSelectedOrder.supplierProfile,
          moment.tz(selectedOrderValid.startOrderDate, storeTimezone),
          storeTimezone,
        );

        if (!limitOrderDay.maxOrderDate) {
          const selectSupplierProfile = supplierProfile || defaultSelectedOrder.supplierProfile;

          limitOrderDay.maxOrderDate = moment
            .tz(
              moment.tz(storeTimezone).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY) +
                (selectSupplierProfile.orderTimeLimit
                  ? 'T' + selectSupplierProfile.orderTimeLimit
                  : ''),
              storeTimezone,
            )
            .format();
        }
        setOrderTimeLimit(limitOrderDay.maxOrderDate);
        setOrderMinAmount(defaultSelectedOrder.supplierProfile.orderMinAmount);
        setOrderMinUnit(defaultSelectedOrder.supplierProfile.orderMinUnit);
        setSupplierProfile(defaultSelectedOrder.supplierProfile);
      }
    }
  }, [selectedOrders]);

  //Force render
  useEffect(() => {
    if (!forceRender) {
      return;
    }

    setForceRender(false);
  }, [forceRender]);

  const handleSetSelectedOrders = (items) => {
    if (
      items.length !== selectedOrders.length ||
      !items.every((item) => selectedOrders.some((order) => order.id === item.id))
    ) {
      setSelectedOrders(items);
    }
  };

  return (
    <Container>
      {!selectedSupplier ? (
        <EmptyState label={i18next.t('ORDERS.ORDERS.GROUP_EMPTY_STATE')} />
      ) : isEmpty || !filteredData.length ? (
        <EmptyState
          icon={'/images/grouped-order-empty-state.svg'}
          label={i18next.t('ORDERS.ORDERS.GROUP_EMPTY_STATE_DROPDOWN')}
        />
      ) : (
        <>
          <FrancoContainer>
            <Franco
              background={
                selectedOrdersTotalPrice < orderMinAmount
                  ? theme?.colors?.infoRed
                  : theme?.colors?.infoGreen
              }
              color="#FFFFFF"
              content={
                <DisplayNumber number={!!selectedOrdersTotalPrice ? selectedOrdersTotalPrice : 0} />
              }
              labelContent={
                !!orderMinAmount ? (
                  <DisplayNumber
                    color={ENUM_COLORS.LIGHTEST}
                    font={ENUM_FONTS.TEXT_COMPONENT_LABEL}
                    number={orderMinAmount}
                  />
                ) : (
                  ''
                )
              }
              title={`${i18next.t('ORDERS.ORDERS.LIST_LABEL_TOTAL_EX_TAX')} (${
                currency.alphabeticCode
              }) :`}
              type="plain"
            />
            <Franco
              background={
                orderedUnit < orderMinUnit ? theme?.colors?.infoRed : theme?.colors?.infoGreen
              }
              color="#FFFFFF"
              content={`${Math.round(orderedUnit * 100) / 100}`}
              labelContent={
                !!orderMinUnit
                  ? `${i18next.t('ORDERS.ORDERS.FORM_BOX_MIN_AMOUNT')} ${orderMinUnit}`
                  : ''
              }
              title={`${i18next.t('ORDERS.ORDERS.FORM_BOX_MIN_ORDER_PACKAGE_UNIT')} :`}
              type="plain"
            />
            {orderTimeLimit && orderTimeLimit !== '' && (
              <Franco
                content={i18next.t('ORDERS.ORDERS.GROUP_ERROR_DATE', {
                  maxOrderDate: moment.tz(orderTimeLimit, storeTimezone).format('DD/MM'),
                  orderTimeLimit: moment.tz(orderTimeLimit, storeTimezone).format('HH:mm'),
                })}
              />
            )}
          </FrancoContainer>

          <ItemListContainer>
            <ListView
              columns={HEADERS}
              data={filteredData}
              defaultOrderBy={'orderDate'}
              defaultOrderType={'desc'}
              disableFooter={true}
              disableFullSelection={true}
              disableListOptions={true}
              forceEnableSelection={true}
              languageCode={userLanguageCode}
              padding={STANDARD_LISTVIEW_PADDING}
              setSelectedItems={(items) => handleSetSelectedOrders(items)}
            />
          </ItemListContainer>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps, null)(GroupOrderBody);
