import { head } from 'lodash';
import i18next from 'i18next';

// Used for ALPHABET_DROPDOWN_ITEMS
const generateLettersArrayForDropdown = (startChar, endChar) => {
  let idx = 0,
    lettersArray = [],
    startCharCode = startChar.charCodeAt(0),
    charBCode = endChar.charCodeAt(0);
  while (startCharCode <= charBCode) {
    lettersArray.push({ id: idx.toString(), value: String.fromCharCode(startCharCode) });
    startCharCode++;
    idx++;
  }
  return lettersArray;
};

// Used for QUARTER_HOURS_DROPDOWN_ITEMS
const generateQuarterHourTimeChoices = () => {
  const quarterHours = ['00', '15', '30', '45'];
  let quarterHourTimeChoices = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 4; j++) {
      let time = i + ':' + quarterHours[j];
      if (i < 10) {
        time = '0' + time;
      }
      quarterHourTimeChoices.push({ id: time, value: time });
    }
  }
  return quarterHourTimeChoices;
};

//-------------------------------------------------------------------------------//

export const EVENT_IMPACT_DROPDOWN_ITEMS = [
  { id: 'positive', value: i18next.t('FORECAST.TURNOVER.EVENTS_MODAL_FIELD_IMPACT_POSITIVE') },
  { id: 'negative', value: i18next.t('FORECAST.TURNOVER.EVENTS_MODAL_FIELD_IMPACT_NEGATIVE') },
  { id: 'neutral', value: i18next.t('FORECAST.TURNOVER.EVENTS_MODAL_FIELD_IMPACT_NEUTRAL') },
];

export const INVENTORY_UNIT_DROPDOWN_ITEMS = [
  { id: 'unit', value: '' }, // the value will be inferred at render (see getPackagingUnitOptions)
  {
    id: 'packaging',
    value: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_STOCK_IN_PACKAGING'),
  },
];

export const SMALL_UNITS_DROPDOWN_ITEMS = [
  { id: 'g', value: 'g' },
  { id: 'mL', value: 'mL' },
  { id: 'unit', value: i18next.t('GENERAL.UNIT') },
];

export const STOCK_INVENTORY_DROPDOWN_ITEMS = [
  { id: 'true', value: i18next.t('GENERAL.ACTIVATED') },
  { id: 'false', value: i18next.t('GENERAL.DEACTIVATED') },
];

export const STOCK_LOSS_CONVENTION_DROPDOWN_ITEMS = [
  { id: 'before_opening', value: i18next.t('GENERAL.STOCK_LOSS_CONVENTION_BEFORE_OPENING') },
  { id: 'after_closing', value: i18next.t('GENERAL.STOCK_LOSS_CONVENTION_AFTER_CLOSING') },
];

export const UNITS_DROPDOWN_ITEMS = [
  { id: 'kg', value: 'Kg' },
  { id: 'L', value: 'L' },
  { id: 'unit', value: i18next.t('GENERAL.UNIT') },
];

export const WEEKDAY_DROPDOWN_ITEMS = [
  { id: '0', value: i18next.t('GENERAL.WEEKDAY_1_MONDAY') },
  { id: '1', value: i18next.t('GENERAL.WEEKDAY_2_TUESDAY') },
  { id: '2', value: i18next.t('GENERAL.WEEKDAY_3_WEDNESDAY') },
  { id: '3', value: i18next.t('GENERAL.WEEKDAY_4_THURSDAY') },
  { id: '4', value: i18next.t('GENERAL.WEEKDAY_5_FRIDAY') },
  { id: '5', value: i18next.t('GENERAL.WEEKDAY_6_SATURDAY') },
  { id: '6', value: i18next.t('GENERAL.WEEKDAY_7_SUNDAY') },
];

export const CHOICES_DROPDOWN_ACTIVE = [
  { id: 1, name: i18next.t('GENERAL.ACTIVES'), propertyKey: 'active', filterValue: true },
  { id: 2, name: i18next.t('GENERAL.INACTIVES'), propertyKey: 'active', filterValue: false },
];

export const CHOICES_STORE_TYPE = [
  { id: 1, name: i18next.t('GENERAL.CENTRAL'), propertyKey: 'isKitchen', filterValue: true },
  {
    id: 2,
    name: i18next.t('GENERAL.SALE_POINT'),
    propertyKey: 'isKitchen',
    filterValue: false,
  },
];

export const CHOICES_ORDER_AVAILABILITY = [
  {
    id: 1,
    name: i18next.t('GENERAL.AVAILABLE_ORDER'),
    propertyKey: 'available',
    filterValue: true,
  },
  {
    id: 2,
    name: i18next.t('GENERAL.UNAVAILABLE_ORDER'),
    propertyKey: 'available',
    filterValue: false,
  },
];

export const CHOICES_STOCK_AVAILABILITY = [
  { id: 1, name: i18next.t('GENERAL.AVAILABLE_STOCK'), propertyKey: 'hasStock', filterValue: true },
  {
    id: 2,
    name: i18next.t('GENERAL.UNAVAILABLE_STOCK'),
    propertyKey: 'hasStock',
    filterValue: false,
  },
];

export const CHOICES_LOSS_AVAILABILITY = [
  { id: 1, name: i18next.t('GENERAL.AVAILABLE_LOSS'), propertyKey: 'hasDlc', filterValue: true },
  { id: 2, name: i18next.t('GENERAL.UNAVAILABLE_LOSS'), propertyKey: 'hasDlc', filterValue: false },
];

export const CHOICES_DROPDOWN_MAPPED = [
  { id: 3, name: 'Associés', propertyKey: 'isAssociated', filterValue: true },
  { id: 4, name: 'Non Associés', propertyKey: 'isAssociated', filterValue: false },
];

export const DAYS = [
  { id: 0, name: 'Lundi', itemValue: 0 },
  { id: 1, name: 'Mardi', itemValue: 1 },
  { id: 2, name: 'Mercredi', itemValue: 2 },
  { id: 3, name: 'Jeudi', itemValue: 3 },
  { id: 4, name: 'Vendredi', itemValue: 4 },
  { id: 5, name: 'Samedi', itemValue: 5 },
  { id: 6, name: 'Dimanche', itemValue: 6 },
];

export const ITEM_DROPDOWN_NONE_VALUE = {
  id: -1,
  value: i18next.t('GENERAL.NONE_VALUE'),
  name: i18next.t('GENERAL.NONE_VALUE'),
};

/**
 *  26-items array, with { id: <alphabet_position>, value: <letter> }
 *  i.e: [
 *    { id: '0', value: 'A' }, { id: '1', value: 'B' },
 *    ...,
 *    { id: '25', value: 'Z' }
 *  ]
 *  Useful whenever working with delivery days in dropdowns
 *  */
export const ALPHABET_DROPDOWN_ITEMS = generateLettersArrayForDropdown('A', 'Z');

/**
 *  96-items array, with { id: <time>, value: <time> }
 *  i.e: [
 *    { id: '00:00', value: '00:00' }, { id: '00:15', value: '00:15' },
 *    ...,
 *    { id: '23:30', value: '23:30' }, { id: '23:45', value: '23:45' }
 *  ]
 *  Useful whenever working with order time limit
 *  */
export const QUARTER_HOURS_DROPDOWN_ITEMS = generateQuarterHourTimeChoices();

//-------------------------------------------------------------------------------//

/**
 *  When selecting an inventory unit, it can either be:
 * - in packaging
 * - in unit
 * In the latter case, the "unit" can either be Kg, L, or unit.
 *
 * @param {*} packagingUnit
 * @returns INVENTORY_UNIT_DROPDOWN_ITEMS, with the first item's value correctly computed and translated.
 */
export const getInventoryUnitOptions = (packagingUnit) => {
  const updatedDropdownItems = INVENTORY_UNIT_DROPDOWN_ITEMS;

  let inferredPackagingUnit = '';
  if (!packagingUnit) {
    // Should only happen when creating an ingredient without a unit selected
    inferredPackagingUnit = `${i18next.t('STOCKS.STOCKS.FORM_PACKAGING_UNIT_LABEL')},kg,L`;
  } else {
    inferredPackagingUnit =
      packagingUnit === 'unit'
        ? (inferredPackagingUnit = i18next.t('STOCKS.STOCKS.FORM_PACKAGING_UNIT_LABEL')) // unit, so we need the translated word.
        : packagingUnit; // either kg or L
  }

  head(updatedDropdownItems).value = i18next.t(
    'ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_STOCK_IN_PACKAGING_STANDARD_UNIT',
    { packagingUnit: inferredPackagingUnit },
  );

  return updatedDropdownItems;
};

//-------------------------------------------------------------------------------//

export const getUnitValue = (value) => {
  const unitItem = UNITS_DROPDOWN_ITEMS.find((unit) => unit.id === value);

  return unitItem ? unitItem.value : value;
};

export const STRATEGIC_INGREDIENT_DROPDOWN_ITEMS = [
  { id: 1, name: i18next.t('ADMIN.INGREDIENTS.STRATEGIC'), itemValue: true },
  { id: 2, name: i18next.t('ADMIN.INGREDIENTS.NOT_STRATEGIC'), itemValue: false },
];
