import i18next from 'i18next';

export const RECIPE_CREATION = {
  getText: () => i18next.t('FEATURE.ADMIN.NEW_RECIPE'),
  path: '/admin/products/recipes/create',
  isVisible: () => false,
};

export const RECIPE_DETAILS = {
  getText: () => i18next.t('FEATURE.ADMIN.RECIPE'),
  path: '/admin/products/recipes/:id/details',
  getLink: ({ recipeId }) => `/admin/products/recipes/${recipeId}/details`,
  isVisible: () => false,
};
