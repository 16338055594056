import { find, sortBy } from 'lodash';
import i18next from 'i18next';
import React, { useMemo } from 'react';

import { Dropdown } from '../utils/styledLibraryComponents';
import { formatToInpulseFromString } from '../Timezones/format';
import timezones from '../Timezones';

const TimeZonesDropdown = (props) => {
  const { defaultTimezone, handleChange, isDisabled, isRequired, countryCode, label, labelStyle } =
    props;

  const availableTimezones = useMemo(
    () => (countryCode ? timezones.getCountryTimezones(countryCode) : timezones.getAll(timezones)),
    [countryCode],
  );

  return (
    <Dropdown
      customStyle={{ marginRight: '10px', width: '100%' }}
      isDisabled={isDisabled}
      isRequired={isRequired}
      items={availableTimezones}
      label={`${label || i18next.t('BACKOFFICE.GENERAL.UPDATE_DEFAULT_TIMEZONE')} :`}
      labelStyle={labelStyle}
      selectedItem={find(
        availableTimezones,
        ({ value }) => value === formatToInpulseFromString(defaultTimezone),
      )}
      sortBy={(itemsList) => sortBy(itemsList, ({ name }) => name)}
      onSelectionChange={({ name }) => handleChange(name)}
    />
  );
};

export default TimeZonesDropdown;
