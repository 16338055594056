/**
 * Dispatch action to update totByStorageAreaIdSPIdAndPackagingId redux value for a given supplier product id
 *
 * @param {*} supplierProductId
 * @param {Object} updatedTotByPackagingId
 * @example updatedTotByPackagingId
 * {
 *   sppId: number,
 *   sppId2: number,
 * }
 *
 * @returns {void}
 */
export const stockFormUpdateValue =
  (supplierProductId, updatedTotByPackagingId, storageAreaId) => (dispatch) =>
    dispatch({
      type: 'STOCK_FORM_UPDATE_VALUE',
      supplierProductId,
      updatedTotByPackagingId,
      storageAreaId,
    });

export const stockFormUpdateRecipeValue = (recipeId, updatedTotal, storageAreaId) => (dispatch) =>
  dispatch({
    type: 'STOCK_FORM_UPDATE_RECIPE_VALUE',
    recipeId,
    updatedTotal,
    storageAreaId,
  });

/**
 * Dispatch action to reset totByStorageAreaIdSPIdAndPackagingId redux value and empty potential existing values
 *
 * @returns {void}
 */
export const resetStockFormValues = () => (dispatch) =>
  dispatch({
    type: 'STOCK_FORM_RESET_VALUES',
  });

/**
 * Dispatch action init to update totByStorageAreaIdSPIdAndPackagingId redux value with defaut values
 *
 * @param {Object} totByStorageAreaIdSPIdAndPackagingId
 * @example totByStorageAreaIdSPIdAndPackagingId
 * {
 *   "spId": {
 *     sppId: number,
 *     sppId2: number,
 *   },
 *   "spId2": {
 *     spp.id: number,
 *   },
 * }
 *
 * @returns {void}
 */
export const initStockFormValues =
  (totByStorageAreaIdSPIdAndPackagingId, totByStorageAreaIdAndRecipeId) => (dispatch) =>
    dispatch({
      type: 'STOCK_FORM_INIT_VALUES',
      totByStorageAreaIdSPIdAndPackagingId,
      totByStorageAreaIdAndRecipeId,
    });
