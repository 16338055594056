import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openConfirmationModal } from '@actions/confirmationmodal';
import { openMediumModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { DeepsightConfirmationModal } from '@commons/DeepsightComponents';
import { ListView } from '@commons/utils/styledLibraryComponents';

import { product as productService } from '@services/product';

import { Container } from './styledComponents';
import { getActions, getRowActions } from './getActions';
import AssociationListingSelection from './components/AssociationListingSelection';
import CashierProductAssociationEmptyState from './components/EmptyState';
import getColumns from './columns';

export async function handleDeletion(cashierProductId, productId, props) {
  const {
    pageLoading,
    pageLoaded,
    showSuccessMessage,
    showErrorMessage,
    cashierProducts,
    setCashierProducts,
    selectedCashierProduct,
    setSelectedCashierProduct,
  } = props;

  pageLoading();

  try {
    await productService.deleteCashierProductAssociation(productId, cashierProductId);

    const filteredCashierProducts = cashierProducts.filter(
      (cashierProduct) => cashierProduct.id !== cashierProductId,
    );

    setCashierProducts(filteredCashierProducts);

    if (!isEmpty(selectedCashierProduct) && selectedCashierProduct.id === cashierProductId) {
      setSelectedCashierProduct({});
    }

    showSuccessMessage(i18next.t('ADMIN.PRODUCTS.ASSOCIATION_SUCCESSFULLY_DELETED'));
  } catch (err) {
    showErrorMessage(i18next.t('ADMIN.PRODUCTS.ASSOCIATION_DELETION_ERROR'));
  } finally {
    pageLoaded();
  }
}

/**
 * Display popup modal to let the user confirm the decision to delete the cashier product association
 *
 * @param {String} selectedCashierProduct - The selected store
 * @param {Props} props                   - The props linked to the component
 *
 * @returns {void}
 */
export function confirmDeletion(selectedCashierProduct, props) {
  const { product, openConfirmationModal } = props;

  const textModal = `Les ventes de ${selectedCashierProduct.name} ne seront plus remontées. Cela impactera les recommandations d’approvisionnement et de production. Souhaitez-vous confirmer ?`;
  const titleModal = 'Confirmer la suppression';

  const params = {
    component: DeepsightConfirmationModal,
    props,
    colorsheme: 'orange',
    size: 'medium',
    eventId: selectedCashierProduct,
    handleEventDeleteConfirmation: () =>
      handleDeletion(selectedCashierProduct.id, product.id, props),
    title: titleModal,
    styleTitle: { fontFamily: 'proximanovaregular' },
    text: textModal,
    deactivateBackgroundClick: true,
  };

  openConfirmationModal(params);
}

export function addNewCashierProducts(props) {
  const params = {
    component: AssociationListingSelection,
    productId: props.product.id,
    cashierProducts: props.cashierProducts,
    setCashierProducts: props.setCashierProducts,
    history: props.history,
  };

  return props.openModal(params);
}

const CashierProductAssociation = (props) => {
  const { isReadOnly, cashierProducts, selectedCashierProduct, setSelectedCashierProduct } = props;

  const [triggeredCPAssociation, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formattedCashierProducts, setFormattedCashierProducts] = useState(cashierProducts);

  const actions = isReadOnly ? [] : getActions(props, addNewCashierProducts);
  const rowActions = isReadOnly ? [] : getRowActions(confirmDeletion, props);

  const columns = getColumns();

  useEffect(() => {
    if (!cashierProducts.length) {
      setFormattedCashierProducts([]);
      return;
    }

    const CPWithRowSelected = cashierProducts.map((cashierProduct) => ({
      ...cashierProduct,
      isRowSelected: cashierProduct.priceSync,
    }));

    setFormattedCashierProducts(CPWithRowSelected);
  }, [cashierProducts]);

  const handleCashierProductSelection = (selectedItems) => {
    if (!selectedItems.length && !isEmpty(selectedCashierProduct)) {
      setSelectedCashierProduct({});
      return;
    }

    if (isEmpty(selectedCashierProduct) || selectedCashierProduct.id !== selectedItems[0].id) {
      setSelectedCashierProduct(selectedItems[0]);
    }
  };

  useEffect(() => {
    if (cashierProducts.length) {
      setTrigger(true);
    }

    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  if (!triggeredCPAssociation || !formattedCashierProducts.length) {
    return (
      <CashierProductAssociationEmptyState
        isReadOnly={isReadOnly}
        setTrigger={() => {
          setTrigger(true);
          addNewCashierProducts(props);
        }}
      />
    );
  }

  return (
    <Container>
      <ListView
        actions={actions}
        columns={columns}
        data={formattedCashierProducts}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        disableFooter={true}
        disableMultipleSelection={isReadOnly ? false : true}
        disableSelection={isReadOnly}
        forceEnableSelection={isReadOnly ? false : true}
        rowActions={rowActions}
        setSelectedItems={handleCashierProductSelection}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openConfirmationModal: (params) => {
    dispatch(openConfirmationModal(params));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CashierProductAssociation);
