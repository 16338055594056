import PropTypes from 'prop-types';
import React, { useState } from 'react';

import IconGraphBlue from '../../../images/expandableEmbeddedList/icon-graph-blue.svg';
import IconGraphSelectedBlue from '../../../images/expandableEmbeddedList/icon-graph-selected-blue.svg';

import IconGraphDark from '../../../images/expandableEmbeddedList/icon-graph-dark.svg';
import IconGraphSelectedDark from '../../../images/expandableEmbeddedList/icon-graph-selected-dark.svg';

import { PropsGraphButton } from '../../interface';
import { ButtonContainer, Icon } from './styledComponents';

import GraphContentDisplay from '../GraphContentDisplay/GraphContentDisplay';

const icons = {
  blue: {
    default: IconGraphBlue,
    selected: IconGraphSelectedBlue,
  },
  dark: {
    default: IconGraphDark,
    selected: IconGraphSelectedDark,
  },
};

const GraphButton = (props: PropsGraphButton): JSX.Element => {
  const { theme, openGraph, open, graphConfiguration, graphs } = props;
  const [activeChart, setActiveChart] = useState(false);

  return (
    <>
      {!!openGraph && (
        <ButtonContainer>
          <Icon
            src={
              icons[theme?.expandableEmbeddedList?.graphModal?.icon?.color][
                activeChart || open ? 'selected' : 'default'
              ]
            }
            alt="icon-graph-previous-sales"
            onMouseEnter={() => setActiveChart(true)}
            onMouseLeave={() => setActiveChart(false)}
            onClick={(e) => {
              e.stopPropagation();
              openGraph();
            }}
          />
          {open && (
            <GraphContentDisplay
              theme={theme}
              graphConfiguration={graphConfiguration}
              graphs={graphs}
              openGraph={openGraph}
            />
          )}
        </ButtonContainer>
      )}
    </>
  );
};

GraphButton.propTypes = {
  openGraph: PropTypes.func.isRequired,
  open: PropTypes.bool,
  graphConfiguration: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }).isRequired,
  graphs: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string.isRequired,
      y: PropTypes.number.isRequired,
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.objectOf(PropTypes.any),
};

GraphButton.defaultProps = {
  graphs: undefined,
  open: false,
  theme: null,
};

export default GraphButton;
