import { sortBy, first, groupBy } from 'lodash';
import React, { useState, useEffect } from 'react';

import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { Dropdown } from '@commons/utils/styledLibraryComponents';

import EmptyState from '../EmptyState';

import { Container } from './styledComponents';
import RecipeByChannel from './RecipeByChannel';

export default ({ isLoading, recipes }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [products, setProducts] = useState([]);
  const [recipeByProductId, setRecipeByProductId] = useState({});

  useEffect(() => {
    if (!recipes.length) {
      setProducts([]);
      setSelectedProduct({});
      setRecipeByProductId({});

      return;
    }

    const recipesGroupByProductId = groupBy(recipes, 'productId');

    const selectableListProducts = recipes.reduce((acc, { productId, productName }) => {
      if (!acc[productId]) {
        acc[productId] = { id: productId, name: productName };
      }

      return acc;
    }, {});

    const sortProducts = sortBy(selectableListProducts, 'name');

    setProducts(sortProducts);
    setRecipeByProductId(recipesGroupByProductId);
    setSelectedProduct(first(sortProducts));
  }, [recipes]);

  if (isLoading || !selectedProduct) {
    return (
      <div style={{ paddingBottom: '24px' }}>
        <DeepsightComponentLoader />
      </div>
    );
  }

  return (
    <Container>
      <Dropdown
        height={'64px'}
        isRequired={true}
        items={products}
        label={'Choisissez votre produit :'}
        selectedItem={selectedProduct}
        onSelectionChange={setSelectedProduct}
      />
      {!recipeByProductId[selectedProduct.id] && <EmptyState />}
      {recipeByProductId[selectedProduct.id] && (
        <RecipeByChannel productId={selectedProduct.id} recipeByProductId={recipeByProductId} />
      )}
    </Container>
  );
};
