import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Text, { ENUM_COLORS, ENUM_FONTS } from '../Text';

const DIGIT_DISPLAYED = 5; // must be > 3

const MATH_POW_ABBREVIATION = {
  [0]: '', // 0,00 to 99 999,99
  [1]: 'K', // 100K to 99 999,49K
  [2]: 'M', // 100M to 99 999,49M
  [3]: 'B', // up to 100B
};
const REGEX_GROUP_OF_THREE = /\B(?=(\d{3})+(?!\d))/g;

export const formatNumberToExcelUsage = (number, numberDecimals) =>
  +formatNumber(number, numberDecimals).replace(/ /g, '');

const _reduceBigNumber = (number, iterationNumber = 0) => {
  if (Math.abs(Math.round(number)) < Math.pow(10, DIGIT_DISPLAYED) || iterationNumber > 3) {
    return { number: `${Math.round(number)}`, iterationNumber };
  }

  iterationNumber += 1;

  return _reduceBigNumber(number / 1000, iterationNumber);
};

/**
 * Allows to have a real rounding in the mathematical sense (3.345=>3.35)
 *
 * @param {number} number - Number to be formatted
 * @param {number} numberDecimals - Number of decimals
 * @returns - A formatted number
 */
export const getNumberFormattedWithDecimals = (number, numberDecimals) =>
  (Math.round(number * Math.pow(10, numberDecimals)) / Math.pow(10, numberDecimals)).toFixed(
    numberDecimals,
  );

export const formatNumber = (number, numberDecimals = 0, shortenDigits = false) => {
  if (number == null) {
    return '-';
  }

  const numberFormattedWithDecimals = getNumberFormattedWithDecimals(number, numberDecimals);

  const numberPart = numberFormattedWithDecimals.toString().split('.');

  const integerPart = numberPart[0];
  const decimalPart = numberPart[1];

  let reducedIntegerPart = { number: integerPart, iterationNumber: 0 };

  if (shortenDigits) {
    reducedIntegerPart = _reduceBigNumber(+reducedIntegerPart.number);
  }

  // numberPart[0] represents the integer part and numberPart[1] represents the decimal part
  const integerPartFormatted = reducedIntegerPart.number.replace(REGEX_GROUP_OF_THREE, ' ');

  const finalIntegerPart =
    reducedIntegerPart.iterationNumber > 0
      ? `${integerPartFormatted}${MATH_POW_ABBREVIATION[reducedIntegerPart.iterationNumber]}`
      : integerPartFormatted;

  const finalDecimalPart =
    numberDecimals === 0 || reducedIntegerPart.iterationNumber > 0 ? '' : `.${decimalPart}`;

  return `${finalIntegerPart}${finalDecimalPart}`;
};

export const formatNumberWithCurrency = ({
  number,
  currency,
  defaultCurrency,
  displayCurrencyCode,
  withoutDecimals,
  shortenDigits,
}) => {
  const alphabeticCode = _.get(currency || defaultCurrency, 'alphabeticCode');

  const numberFormatted = formatNumber(
    number,
    withoutDecimals ? 0 : _.get(currency || defaultCurrency, 'numberDecimals'),
    shortenDigits,
  );

  return displayCurrencyCode && numberFormatted != '-'
    ? `${numberFormatted} ${alphabeticCode}`
    : numberFormatted;
};

const DisplayNumber = (props) => {
  const { number, currency, defaultCurrency, displayCurrencyCode, withoutDecimals, shortenDigits } =
    props;

  const numberToDisplay = formatNumberWithCurrency({
    number,
    currency,
    defaultCurrency,
    displayCurrencyCode,
    withoutDecimals,
    shortenDigits,
  });

  return <Text {...props}>{numberToDisplay}</Text>;
};

DisplayNumber.propTypes = {
  number: PropTypes.number,
  currency: PropTypes.object, //send by base reducer
  displayCurrencyCode: PropTypes.bool,
  font: PropTypes.oneOf(Object.values(ENUM_FONTS)),
  color: PropTypes.oneOfType([PropTypes.oneOf(Object.values(ENUM_COLORS)), PropTypes.string]),
  withoutDecimals: PropTypes.bool,
  shortenDigits: PropTypes.bool,
};

DisplayNumber.defaultProps = {
  displayCurrencyCode: false,
  font: ENUM_FONTS.TEXT_BIG_HEIGHT_16,
  withoutDecimals: false,
  color: ENUM_COLORS.DARKEST,
  shortenDigits: false,
};

const mapStateToProps = (state) => ({
  defaultCurrency: state.baseReducer.currency,
});

export default connect(mapStateToProps)(DisplayNumber);
