import _ from 'lodash';

const INVENTORIES_STOCKS_ACTIONS_RESOURCE = {
  VIEW: 'inventory:view',
  CREATE: 'inventory:create',
  CREATE_INIT: 'inventory:create:init',
  EDIT_INVENTORY_DATE: 'inventory:edit:inventory-date',
  DELETE: 'inventory:delete',
  EDIT: 'inventory:edit',
  CORRECT: 'inventory:correct',
  DOWNLOAD_SHEET: 'inventory:download',
  VALIDATE_INVENTORY: 'inventory:validate',
  INVALIDATE_INVENTORY: 'inventory:invalidate',
};

const _getActionAuthorization = (actions, resource) => {
  const actionsByResource = _.keyBy(actions, 'resource');

  return !!actionsByResource[resource];
};

export const canViewInventory = (actions) =>
  _getActionAuthorization(actions, INVENTORIES_STOCKS_ACTIONS_RESOURCE.VIEW);

export const canCreateInventory = (actions) =>
  _getActionAuthorization(actions, INVENTORIES_STOCKS_ACTIONS_RESOURCE.CREATE);

export const canCreateInitialisationInventory = (actions) =>
  _getActionAuthorization(actions, INVENTORIES_STOCKS_ACTIONS_RESOURCE.CREATE_INIT);

export const canEditInventoryDate = (actions) =>
  _getActionAuthorization(actions, INVENTORIES_STOCKS_ACTIONS_RESOURCE.EDIT_INVENTORY_DATE);

export const canDeleteInventory = (actions) =>
  _getActionAuthorization(actions, INVENTORIES_STOCKS_ACTIONS_RESOURCE.DELETE);

export const canEditInventory = (actions) =>
  _getActionAuthorization(actions, INVENTORIES_STOCKS_ACTIONS_RESOURCE.EDIT);

export const canCorrectInventory = (actions) =>
  _getActionAuthorization(actions, INVENTORIES_STOCKS_ACTIONS_RESOURCE.CORRECT);

export const canDownloadInventorySheet = (actions) =>
  _getActionAuthorization(actions, INVENTORIES_STOCKS_ACTIONS_RESOURCE.DOWNLOAD_SHEET);

export const canValidateInventory = (actions) =>
  _getActionAuthorization(actions, INVENTORIES_STOCKS_ACTIONS_RESOURCE.VALIDATE_INVENTORY);

export const canInvalidateInventory = (actions) =>
  _getActionAuthorization(actions, INVENTORIES_STOCKS_ACTIONS_RESOURCE.INVALIDATE_INVENTORY);
