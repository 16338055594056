import React from 'react';
import styled from 'styled-components';

const ResetPasswordInputContainer = (props) => (
  <div className={props.className}>
    <label className="reset-password-form-label">{props.label}</label>
    <input
      {...props.input}
      className={
        'reset-password-form-input ' +
        (props.meta.invalid && props.meta.touched && !props.meta.active
          ? 'reset-password-form-input-error'
          : '')
      }
      type={props.type}
    />
    {props.meta.invalid && props.meta.touched && !props.meta.active ? (
      <div className="reset-password-error-message">{props.meta.error}</div>
    ) : (
      <div></div>
    )}
    {props.meta.submitFailed && props.asyncValidation ? (
      <div className="reset-password-error-message">Mot de passe erroné</div>
    ) : (
      <div></div>
    )}
  </div>
);

const StyledResetPasswordInputContainer = styled(ResetPasswordInputContainer)`
  width: 100%;
  margin-top: 20px;
  & .reset-password-form-label {
    font: ${(props) => props.theme.fonts?.h4};
    margin-bottom: 5px;
    color: ${(props) => props.theme.colors?.greys.darkest};
    text-transform: uppercase;
  }
  & .reset-password-form-input {
    position: relative;
    font: ${(props) => props.theme.fonts?.h2};
    border: 1px solid rgba(107, 118, 140, 0.64);
    border-radius: 2px;
    outline: none;
    color: ${(props) => props.theme.colors?.greys.darkest};
    display: block;
    width: 100%;
    height: 40px;
    /*margin: 0 auto;*/
    padding-left: 10px;
    box-sizing: border-box;
    box-shadow: 0 2px 6px 0 rgba(168, 177, 196, 0.4);
  }

  & .reset-password-form-input-error {
    border: solid 1px #ef2b2d !important;
  }

  & .reset-password-error-message {
    font: ${(props) => props.theme.fonts?.h4};
    color: ${(props) => props.theme.colors?.red};
  }
`;

export default StyledResetPasswordInputContainer;
