const getTotalPrice = (productsInventory = []) =>
  productsInventory.reduce((result, category) => {
    if (!category.products) {
      return result;
    }

    category.products.forEach((product) => {
      if (product.ordered) {
        result += product.ordered * product.price;
      }
    });

    return result;
  }, 0);

export default getTotalPrice;
