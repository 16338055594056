import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { multipleOrderByWithNullsLast } from '@commons/utils/sorting';
import utilsXLS from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { getClientInfo } from '@selectors/client';

import { LOSS_TYPE_TRANSLATIONS } from '@losses/LossesLosses/components/LossListView/constants';

import {
  getByIngredientSheetColumns,
  getByCategorySheetColumns,
  getContextSheetColumns,
  PROP_KEYS,
} from './sheets';

export const ExportLossAnalyticsModal = (props) => {
  const {
    params: { title, data, currency, lossCategoryById, context },
    client: { storeName },
    closeModal,
    showErrorMessage,
  } = props;

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [titleModal, setTitleModal] = useState(title || '');

  const exitModal = () => {
    setIsLoading(false);

    closeModal();
  };

  // For one ingredient's analytics, return an object matching the sheet columns keys
  const extractIngredientData = ({ name, entityUnit, lossBySP, lossByProducts, total }) => ({
    [PROP_KEYS.INGREDIENT_NAME]: name,
    [PROP_KEYS.SUPPLIER_PRODUCT_LOSS_CURRENCY]: lossBySP.currency,
    [PROP_KEYS.PRODUCT_LOSS_CURRENCY]: lossByProducts.currency,
    [PROP_KEYS.TOTAL_LOSS_CURRENCY]: total.currency,
    [PROP_KEYS.CURRENCY]: currency.alphabeticCode,
    [PROP_KEYS.SUPPLIER_PRODUCT_LOSS_UNIT]: lossBySP.unit,
    [PROP_KEYS.PRODUCT_LOSS_UNIT]: lossByProducts.unit,
    [PROP_KEYS.TOTAL_LOSS_UNIT]: total.unit,
    [PROP_KEYS.UNIT]: entityUnit,
  });

  const extractCategoryData = (
    ingredientData,
    { lossCategoryId, lossType, currency, ratio, unit },
  ) => {
    const category = get(lossCategoryById, `${lossCategoryId}`, null);

    const categoryName = i18next.t(category.id ? category.translationKey : 'GENERAL.NONE_VALUE');

    const translatedLossType = LOSS_TYPE_TRANSLATIONS[lossType];

    return {
      [PROP_KEYS.INGREDIENT_NAME]: ingredientData[PROP_KEYS.INGREDIENT_NAME],
      [PROP_KEYS.LOSS_CATEGORY]: categoryName,
      [PROP_KEYS.LOSS_TYPE]: translatedLossType,
      [PROP_KEYS.TOTAL_LOSS_CURRENCY]: currency,
      [PROP_KEYS.CATEGORY_RATIO]: ratio,
      [PROP_KEYS.CURRENCY]: ingredientData[PROP_KEYS.CURRENCY],
      [PROP_KEYS.TOTAL_LOSS_UNIT]: unit,
      [PROP_KEYS.UNIT]: ingredientData[PROP_KEYS.UNIT],
    };
  };

  const extractContextData = (context) => {
    const storesNames = context.stores.map(({ name }) => name).join(', ');
    const startDate = moment(context.startDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);
    const endDate = moment(context.endDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);
    const ingredientCategories = context.ingredientCategories.map(({ value }) => value).join(', ');
    const lossCategories = context.lossCategories.map(({ value }) => value).join(', ');

    return {
      [PROP_KEYS.STORES]: storesNames,
      [PROP_KEYS.START_DATE]: startDate,
      [PROP_KEYS.END_DATE]: endDate,
      [PROP_KEYS.INGREDIENT_CATEGORIES]: ingredientCategories,
      [PROP_KEYS.LOSS_CATEGORIES]: lossCategories,
      [PROP_KEYS.TURNOVER]: context.turnover,
      [PROP_KEYS.LOSS_RATIO]: context.lossRate,
    };
  };

  useEffect(() => {
    try {
      const { dataSortByIngredient, dataSortByCategory } = data.reduce(
        (ingredientAcc, ingredientData) => {
          const ingredientRowData = extractIngredientData(ingredientData);

          ingredientAcc.dataSortByIngredient.push(ingredientRowData);

          const lossByCategoryOfIngredient = ingredientData.categoriesData.map((categoryData) =>
            extractCategoryData(ingredientRowData, categoryData),
          );

          ingredientAcc.dataSortByCategory.push(...lossByCategoryOfIngredient);

          return ingredientAcc;
        },
        { dataSortByIngredient: [], dataSortByCategory: [] },
      );

      const contextData = [
        {
          ...extractContextData(context),
          [PROP_KEYS.CURRENCY]: currency.alphabeticCode,
        },
      ];

      const sortedIngredients = multipleOrderByWithNullsLast(
        dataSortByIngredient,
        [PROP_KEYS.TOTAL_LOSS_CURRENCY, PROP_KEYS.INGREDIENT_NAME],
        ['desc', 'asc'],
      );

      const byIngredientSheet = utilsXLS.generateDefaultSheet(
        i18next.t('LOSSES.ANALYTICS.EXPORT_BY_INGREDIENT'),
        getByIngredientSheetColumns(),
        sortedIngredients,
      );

      const byCategorySheet = utilsXLS.generateDefaultSheet(
        i18next.t('LOSSES.ANALYTICS.EXPORT_BY_CATEGORY'),
        getByCategorySheetColumns(),
        dataSortByCategory,
      );

      const contextSheet = utilsXLS.generateDefaultSheet(
        i18next.t('GENERAL.INFORMATIONS'),
        getContextSheetColumns(storeName),
        contextData,
        currency,
      );

      utilsXLS.makeXLS(i18next.t('LOSSES.ANALYTICS.EXPORT_FILENAME'), [
        byIngredientSheet,
        byCategorySheet,
        contextSheet,
      ]);

      setTitleModal(i18next.t('GENERAL.EXPORT_SUCCESS'));
      setIsLoading(false);
    } catch {
      showErrorMessage(i18next.t('GENERAL.EXPORT_FAILURE'));
      exitModal();
    }
  }, []);

  useEffect(() => {
    if (isLoading && progress < 100) {
      setProgress(100);
    }
  }, [progress]);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setIsLoading}
      titleModal={titleModal}
    />
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportLossAnalyticsModal);
