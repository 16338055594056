import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  background-color: ${(props) => props.theme.colors?.greys?.lighter};
`;

export const ListViewContainer = styled.div`
  height: calc(100% - 40px);
`;

export const LabelContainer = styled.div`
  height: 100%;
  min-width: 115px;

  display: flex;
  align-items: center;

  padding: 5px 0px;
  margin-left: 2px;
`;

export const Text = styled.div`
  font: ${(props) => props.theme.fonts?.textBig};
  color: ${(props) => props.theme.colors?.greys?.darkest};

  ${(props) => {
    if (props.bold) {
      return css`
        font: ${(props) => props.theme.fonts?.textMiddleBold};
      `;
    }
  }}
`;

export const WarningModalContentContainer = styled.span`
  font: ${(props) => props.theme.fonts?.textBigHeight24};
  color: ${(props) => props.theme.colors?.greys?.darker};
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;

  margin-right: 12px;
`;
