import { get } from 'lodash';
import i18next from 'i18next';
import parse from 'html-react-parser';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import { getTheme } from '@commons/utils/theme';
import WhiteCard from '@commons/WhiteCard';
import WysiwygEditor from '@commons/WysiwygEditor';

import InputEmailChip from '@lib/inpulse/InputEmailChip';

import { canSendOrder } from '@selectors/actions/orderActions';

import EdiSummaryPanel from '../../components/EDISummaryPanel';

import {
  ButtonContainer,
  ContainerTextArea,
  EmailContainer,
  OrderCartContainer,
  SubTitleWhiteCard,
  TitleWhiteCard,
  WhiteCardContainer,
} from '../../styledComponents';

const OrderCart = (props) => {
  const {
    htmlPreview,
    supplierHasEDI,
    emailRecipients,
    userHasRightToUpdate,
    handleEmails,
    setEmailRecipients,
    emailRecipientsCopy,
    setEmailRecipientsCopy,
    purchaseOrderComment,
    setPurchaseOrderComment,
    matchingSupplier,
    handleSendConfirmationModal,
    authorizedActions,
  } = props;

  const theme = getTheme();

  const customWhiteCardStyle = { border: `1px solid ${theme.colors.greys.light}` };

  return (
    <OrderCartContainer>
      <WhiteCardContainer width={'65%'}>
        <WhiteCard
          customStyle={customWhiteCardStyle}
          maxWidth={'inherit'}
          renderContent={
            <>
              <TitleWhiteCard>{i18next.t('ORDERS.ORDERS.CART')}</TitleWhiteCard>
              {!!htmlPreview && <>{parse(htmlPreview)}</>}
            </>
          }
          width={'100%'}
        ></WhiteCard>
      </WhiteCardContainer>
      <WhiteCardContainer width={'35%'}>
        <WhiteCard
          customStyle={customWhiteCardStyle}
          renderContent={
            <>
              <TitleWhiteCard>
                {i18next.t(
                  !!supplierHasEDI ? 'ORDERS.ORDERS.SEND_BY_EDI' : 'ORDERS.ORDERS.SEND_BY_EMAIL',
                )}
              </TitleWhiteCard>
              {!supplierHasEDI && (
                <>
                  <EmailContainer>
                    <InputEmailChip
                      emails={emailRecipients}
                      isDisabled={userHasRightToUpdate()}
                      placeholder={i18next.t('ORDERS.ORDERS.CART_RECIPIENTS')}
                      setEmails={(emails) => handleEmails(emails, setEmailRecipients)}
                      title={i18next.t('ORDERS.ORDERS.CART_RECIPIENTS')}
                      width={'100%'}
                      required
                    />
                  </EmailContainer>
                  <EmailContainer>
                    <InputEmailChip
                      emails={emailRecipientsCopy}
                      isDisabled={userHasRightToUpdate()}
                      placeholder={i18next.t('ORDERS.ORDERS.CART_RECIPIENTS_COPIES')}
                      setEmails={(emails) => handleEmails(emails, setEmailRecipientsCopy)}
                      title={i18next.t('ORDERS.ORDERS.CART_RECIPIENTS_COPIES')}
                      width={'100%'}
                    />
                  </EmailContainer>
                  <ContainerTextArea>
                    <SubTitleWhiteCard>
                      {i18next.t('ORDERS.ORDERS.CART_COMMENT_PN')}
                    </SubTitleWhiteCard>
                    <>
                      <WysiwygEditor
                        content={purchaseOrderComment}
                        isDisabled={userHasRightToUpdate()}
                        setter={setPurchaseOrderComment}
                      />
                    </>
                  </ContainerTextArea>
                </>
              )}
              {supplierHasEDI && (
                <EdiSummaryPanel ediPartner={get(matchingSupplier, 'ediPartner')} />
              )}
              <ButtonContainer centerButton={supplierHasEDI}>
                <Button
                  color={'inpulse-default'}
                  handleClick={handleSendConfirmationModal}
                  icon={'/images/inpulse/send-white-small.svg'}
                  isDisabled={
                    (!supplierHasEDI && !emailRecipients) ||
                    (!supplierHasEDI && !emailRecipients.length) ||
                    !canSendOrder(authorizedActions)
                  }
                  label={i18next.t('GENERAL.SEND')}
                />
              </ButtonContainer>
            </>
          }
          width={'100%'}
        ></WhiteCard>
      </WhiteCardContainer>
    </OrderCartContainer>
  );
};

export default OrderCart;
