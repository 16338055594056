import { connect } from 'react-redux';
import { head } from 'lodash';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useState, memo } from 'react';

import { sortPackagings } from '@commons/utils/format';
import DisplayNumber from '@commons/DisplayNumber';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { convertSPPQuantityInMasterUnit } from '@orders/utils/computePackagingToSupplierProduct';

import { UNIT } from '@stocks/StocksInventories/components/StockForm/constants/unit';

import {
  Item,
  TooltipPriceItem,
  TooltipPriceIcon,
  TooltipPriceHeader,
  TooltipPriceContent,
  TooltipPriceContainer,
} from '../styledComponents';

const memoizeCondition = (prevProps, props) => prevProps.price === props.price;

const RenderTooltipPrice = (props) => {
  const { nbColumns, price, invoicePackaging, packagings } = props;

  const [isOpened, setIsOpened] = useState(false);

  const triggerTooltip = () => {
    setIsOpened(!isOpened);
  };

  return (
    <Item nbColumns={nbColumns}>
      <TooltipPriceIcon white={!isOpened} onClick={triggerTooltip}>
        <img src={`/images/inpulse/info-${!isOpened ? 'black' : 'white'}-small.svg`} />
      </TooltipPriceIcon>
      {isOpened && (
        <TooltipPriceContainer>
          <TooltipPriceHeader>
            <Text font={ENUM_FONTS.TEXT_BIG_HEIGHT_24_BOLD}>
              {i18next.t('STOCKS.STOCKS.LIST_LABEL_PRICE')}
            </Text>
            <img src={`/images/inpulse/close-black-small.svg`} onClick={triggerTooltip} />
          </TooltipPriceHeader>
          <TooltipPriceContent>
            {sortPackagings(packagings).map((packaging, index) => {
              if (!packaging.isUsedInStock) {
                return null;
              }

              const convertedPrice =
                price / convertSPPQuantityInMasterUnit(invoicePackaging.id, packagings);

              const convertedQuantity = convertSPPQuantityInMasterUnit(packaging.id, packagings);

              return (
                <TooltipPriceItem key={index}>
                  <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14}>
                    {packaging.name}
                  </Text>
                  <DisplayNumber
                    color={ENUM_COLORS.DARKER}
                    displayCurrencyCode={true}
                    font={ENUM_FONTS.TEXT_SMALL_BOLD}
                    number={convertedPrice * convertedQuantity}
                  />
                </TooltipPriceItem>
              );
            })}
          </TooltipPriceContent>
        </TooltipPriceContainer>
      )}
    </Item>
  );
};

const Price = (props) => {
  const { price, currency, nbColumns, packagings } = props;

  const invoicePackaging = packagings.find(({ isUsedInInvoice }) => isUsedInInvoice);

  if (!packagings.length || !invoicePackaging) {
    return <Item nbColumns={nbColumns}>- {currency.alphabeticCode}</Item>;
  }

  const availablePackagings = packagings.filter((packaging) => packaging.isUsedInStock);

  if (availablePackagings.length > 1) {
    return (
      <RenderTooltipPrice
        invoicePackaging={invoicePackaging}
        nbColumns={nbColumns}
        packagings={packagings}
        price={price}
      />
    );
  }

  const { id } = !availablePackagings.length ? {} : head(availablePackagings);

  if (price == null) {
    return <Item nbColumns={nbColumns}>- {currency.alphabeticCode}</Item>;
  }

  const convertedPrice = price / convertSPPQuantityInMasterUnit(invoicePackaging.id, packagings);

  const convertedQuantity = convertSPPQuantityInMasterUnit(id, packagings);

  return (
    <Item nbColumns={nbColumns}>
      <DisplayNumber
        displayCurrencyCode={true}
        font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
        number={convertedPrice * convertedQuantity}
      />
    </Item>
  );
};

Price.propTypes = {
  price: PropTypes.number,
  currency: PropTypes.object, // send by base reducer
  nbColumns: PropTypes.number,
  packagings: PropTypes.arrayOf(
    PropTypes.shape({
      unit: PropTypes.oneOf(UNIT),
      quantity: PropTypes.number.isRequired,
    }),
  ),
};

Price.defaultProps = {
  price: null,
  nbColumns: 1,
  packaging: [],
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps)(memo(Price, memoizeCondition));
