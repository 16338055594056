import React from 'react';

import { Text } from './styledComponents';

const MAX_LENGTH_DISPLAYED_STRING = 35;

export const ProductInfoSection = (props) => {
  const { name, code } = props;

  return (
    <>
      <Text className="ref-name">
        {name.length > MAX_LENGTH_DISPLAYED_STRING
          ? name.substring(0, MAX_LENGTH_DISPLAYED_STRING - 2) + ' ...'
          : name}
      </Text>
      {code && <Text className="ref-code">{code}</Text>}
    </>
  );
};

export default ProductInfoSection;
