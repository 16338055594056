import { get } from 'lodash';

import { getTheme } from '@commons/utils/theme';

import columnUtils from '../../../routes/margin/Evolution/components/MarginEvolutionGraph/utils/columns';

const TOOLTIP_DIV_ID = 'graph-tooltip';

const getOrCreateTooltip = (theme) => {
  let tooltipDiv = document.getElementById(TOOLTIP_DIV_ID);

  if (!tooltipDiv) {
    tooltipDiv = document.createElement('div');
    tooltipDiv.id = TOOLTIP_DIV_ID;

    // Display
    tooltipDiv.style.width = '256px';
    tooltipDiv.style.backgroundColor = theme.colors.greys.lightest;
    tooltipDiv.style.transition = 'all .1s ease';

    // Border
    tooltipDiv.style.border = `1px solid ${theme.colors.greys.light}`;
    tooltipDiv.style.borderRadius = '8px';
    tooltipDiv.style.boxShadow = '0px 4px 8px 0px rgba(0, 0, 0, 0.16)';
    tooltipDiv.style.padding = '16px ';
    tooltipDiv.style.pointerEvents = 'none';

    // Append element
    const tableRoot = document.createElement('table');
    tableRoot.style.width = '100%';

    tooltipDiv.appendChild(tableRoot);
    document.body.appendChild(tooltipDiv);
  }

  return tooltipDiv;
};

const getTooltipTitle = (title, theme) => {
  const span = document.createElement('span');
  span.style.colors = theme.colors.greys.darkest;
  span.style.font = theme.fonts.textBigBoldHeight14;
  span.innerText = title;

  const th = document.createElement('th');
  th.appendChild(span);
  th.style.paddingBottom = '16px';

  const tr = document.createElement('tr');
  tr.appendChild(th);

  const tableHead = document.createElement('thead');
  tableHead.appendChild(tr);

  return tableHead;
};

const getTooltipBody = (headers, data, theme) => {
  const tableBody = document.createElement('tbody');

  headers.forEach(({ name, propertyKey, render }, index) => {
    const borderWidthStyle = index === 0 ? '1px 0 1px 0' : '0 0 1px 0';

    const spanName = document.createElement('span');
    spanName.style.color = theme.colors.blacks.ipBlack1;
    spanName.style.font = theme.fonts.textSmallWeight700Height16Bold;
    spanName.innerText = name;

    const spanValue = document.createElement('span');
    spanValue.style.color = theme.colors.greys.darker;
    spanValue.style.font = theme.fonts.textSmallWeight700Height16Bold;
    spanValue.innerText = render(data[propertyKey]);

    const td = document.createElement('td');
    td.style.padding = '4px 0';
    td.style.borderWidth = borderWidthStyle;
    td.style.borderStyle = 'solid';
    td.style.borderColor = theme.colors.greys.light;
    td.style.display = 'flex';
    td.style.justifyContent = 'space-between';
    td.appendChild(spanName);
    td.appendChild(spanValue);

    const tr = document.createElement('tr');
    tr.appendChild(td);

    tableBody.appendChild(tr);
  });

  return tableBody;
};

export const tooltipHandler = (
  { tooltip, chart, getTooltipTitleKey, getTooltipTitleValue },
  data,
  currency,
) => {
  const theme = getTheme();

  // Tooltip Element
  const tooltipDiv = getOrCreateTooltip(theme);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipDiv.style.opacity = '0';
    return;
  }

  // Set table header
  const tooltipTitle = get(tooltip, 'title[0]', null);
  // This must be the same as the property xAxis.time.tooltipFormat specified in  the config
  const titleKey = !!getTooltipTitleKey ? getTooltipTitleKey(tooltipTitle) : tooltipTitle;

  const title = !!getTooltipTitleValue ? getTooltipTitleValue(titleKey, data) : titleKey;

  const tableHead = getTooltipTitle(title, theme);

  // Set table body
  const headers = columnUtils.getTooltipLines(currency);
  const matchingData = data.find(({ date }) => date === titleKey);

  const tableBody = matchingData
    ? getTooltipBody(headers, matchingData, theme)
    : document.createElement('tbody');

  // Append table
  const tableRoot = tooltipDiv.querySelector('table');

  // Remove old children
  while (tableRoot.firstChild) {
    tableRoot.firstChild.remove();
  }

  tableRoot.appendChild(tableHead);
  tableRoot.appendChild(tableBody);

  // Actually display tooltip
  const position = chart.canvas.getBoundingClientRect();
  tooltipDiv.style.opacity = '1';
  const offset = 24; // Distance from mouse
  tooltipDiv.style.position = 'absolute';
  tooltipDiv.style.left = offset + position.left + window.pageXOffset + tooltip.caretX + 'px';
  tooltipDiv.style.top = position.top + window.pageYOffset + tooltip.caretY + 'px';
};
