import i18next from 'i18next';
import moment from 'moment';
import React from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { InpulseLabel } from '@commons/DeepsightComponents';
import { Tags } from '@commons/Tags';

import { Text } from '../styledComponents';

const renderLabel = (item) => (
  <div
    className="supplier-label-render"
    style={{ minWidth: '115px', marginLeft: '2px', padding: '5px 0px' }}
  >
    <InpulseLabel
      color={item === 'Actif' ? 'green' : item === 'Inactif' ? 'red' : 'black'}
      text={String(item)}
    />
  </div>
);

const renderGroups = (itemList) => {
  const groupNames = itemList.map((group) => group.name);

  return <Tags items={groupNames} />;
};

export const getColumnsSettings = ({ hasMultipleBrands, hasGroups, shouldDisplayLocations }) => [
  {
    id: 'store_name',
    name: i18next.t('GENERAL.NAME'),
    displayName: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
    baseName: 'name',
    render: (it) => <Text highlighted>{it}</Text>,
  },
  {
    id: 'store_type',
    name: i18next.t('GENERAL.STORE_TYPE'),
    displayName: i18next.t('GENERAL.STORE_TYPE'),
    propertyKey: 'isKitchen',
    baseName: 'isKitchen',
    render: (isKitchen) => (
      <Text>
        {isKitchen
          ? i18next.t('BACKOFFICE.GENERAL.CENTRAL', { count: 1 })
          : i18next.t('BACKOFFICE.STORES.POINT_OF_SALE')}
      </Text>
    ),
  },
  ...(hasMultipleBrands
    ? [
        {
          id: 'brand_name',
          name: i18next.t('GENERAL.BRAND'),
          displayName: i18next.t('GENERAL.BRAND'),
          propertyKey: 'brandName',
          baseName: 'brandName',
          render: (item) => (item ? <Text>{item}</Text> : <Text>-</Text>),
        },
      ]
    : []),
  ...(shouldDisplayLocations
    ? [
        {
          id: 'store-location',
          name: i18next.t('BACKOFFICE.STORES.LOCATION'),
          displayName: i18next.t('BACKOFFICE.STORES.LOCATION'),
          propertyKey: 'location',
          baseName: 'location',
          render: (it) => <Text>{it && it.name}</Text>,
        },
      ]
    : []),
  {
    id: 'mapped_groups',
    name: i18next.t('GENERAL.GROUPS'),
    displayName: i18next.t('GENERAL.GROUPS'),
    propertyKey: 'mappedGroups',
    baseName: 'mappedGroups',
    excludeFromSearch: true,
    render: (it) => renderGroups(it),
  },
  {
    id: 'created_at',
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_CREATION_DATE'),
    displayName: i18next.t('ORDERS.ORDERS.LIST_LABEL_CREATION_DATE'),
    propertyKey: 'createdAt',
    baseName: 'createdAt',
    filterType: 'date',
    excludeFromSearch: true,
    render: (it) => <div>{moment(it).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)}</div>,
  },
  {
    id: 'started_at',
    name: i18next.t('BACKOFFICE.STORES.BILLING_DATE'),
    displayName: i18next.t('BACKOFFICE.STORES.BILLING_DATE'),
    propertyKey: 'launchDate',
    baseName: 'launchDate',
    excludeFromSearch: true,
    filterType: 'date',
    render: (it) => (
      <div>{it ? moment(it).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR) : '-'}</div>
    ),
  },
  {
    id: 'mapped_accounts',
    name: i18next.t('GENERAL.USERS'),
    displayName: i18next.t('GENERAL.USERS'),
    propertyKey: 'mappedAccounts',
    baseName: 'mappedAccounts',
    filterType: 'numeric',
    excludeFromSearch: true,
    render: (it) => renderLabel(it, { fontSize: '12px', height: '18px' }),
  },
];

export default {
  getColumnsSettings,
};
