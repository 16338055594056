import { DateRangePicker } from 'react-dates';
import i18next from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, Tooltip } from '../utils/styledLibraryComponents';

import { DeepsightComponentLoader } from '../DeepsightComponents';
import { LittleBoxContainer } from '../../routes/orders/OrderAnalyticsModulesContainer/OrderAnalyticsCategory/components/Headers/styledComponents';

import { getClientStoreNameTranslation } from '../../commons/utils/translations';

import {
  renderCustomArrowIcon,
  renderCustomNavIconLeft,
  renderCustomNavIconRight,
  renderTodayDateCircle,
} from './utils';

import {
  Container,
  Filter,
  FilterContainer,
  DisplayCurrentDay,
  TitleContainer,
} from './styledComponents';

import DeepsightFiltersButton from './components/DeepsightFiltersButton';
import ProductionAnalyticsToggle from '../../routes/prod/components/AnalyticsContainer/components/ProductionAnalyticsToggle';

const DEFAULT_NUMBER_OF_MONTH_DATE_PICKER = 1;

export const DeepsightAnalyticsHeaders = (props) => {
  const {
    startDate,
    endDate,
    minimumNightsDatePicker,
    numberOfMonthsDatePicker,
    tooltipDatePicker,
    handleSelectedDates,
    highlightDayDatePicker,
    renderCalendarInfoDatePicker,
    metrics,
    selectedMetric,
    setSelectedMetric,
    handleExport,
    readOnly,
    disableExportButton,
    hideDatePicker,
    hideFilterButton,
    displayCurrentDay,
    isLoading,
    storeName,
    targetProductionWasteRate,
    displayTargetProductionWasteRate,
  } = props;

  const [focusedDateInput, setFocusedDateInput] = useState();
  const currentDate = moment();
  return (
    <Container className={props.className}>
      <FilterContainer minWidth>
        <Filter>
          {metrics && (
            <div className={'sub-filter'}>
              <ProductionAnalyticsToggle
                choices={metrics}
                selectedChoice={selectedMetric}
                setSelectedChoice={setSelectedMetric}
              />
            </div>
          )}
        </Filter>
      </FilterContainer>
      <FilterContainer>
        {!hideDatePicker && (
          <Filter
            disabled={readOnly}
            focusedDateInput={focusedDateInput}
            withExtraMarginTop={!!renderCalendarInfoDatePicker}
            date
          >
            <img
              alt=""
              className="orderform-date-input-icon"
              readOnly={readOnly}
              src={'/images/icon-calendar-black-inpulse.svg'}
              style={{ width: '16px', height: '16px' }}
            />
            <DateRangePicker
              calendarInfoPosition={'top'}
              customArrowIcon={renderCustomArrowIcon()}
              disabled={readOnly}
              displayFormat={'ddd Do MMM'}
              endDate={endDate}
              endDateId="your_unique_end_date_id"
              endDatePlaceholderText={i18next.t(
                'GENERAL.ANALYTICS_HEADER_DATE_PICKER_END_PLACEHOLDER',
              )}
              focusedInput={focusedDateInput}
              hideKeyboardShortcutsPanel={true}
              isDayHighlighted={highlightDayDatePicker}
              isOutsideRange={(day) => day.isAfter(moment().subtract(1, 'day').endOf('day'))}
              minimumNights={minimumNightsDatePicker || 0}
              navNext={renderCustomNavIconRight()}
              navPrev={renderCustomNavIconLeft()}
              noBorder={true}
              numberOfMonths={numberOfMonthsDatePicker}
              readOnly={true}
              renderCalendarInfo={renderCalendarInfoDatePicker}
              renderDayContents={(item) => renderTodayDateCircle(item, [startDate, endDate])}
              startDate={startDate}
              startDateId="your_unique_start_date_id"
              startDatePlaceholderText={i18next.t(
                'GENERAL.ANALYTICS_HEADER_DATE_PICKER_START_PLACEHOLDER',
              )}
              onDatesChange={(dates) => handleSelectedDates(dates, focusedDateInput)}
              onFocusChange={(focusedDateInput) => setFocusedDateInput(focusedDateInput)}
            />
          </Filter>
        )}
        {!hideFilterButton && (
          <Filter filterButton>
            <DeepsightFiltersButton
              {...props}
              textFilterButton={i18next.t('GENERAL.ANALYTICS_BUTTON')}
            />
          </Filter>
        )}
        {displayCurrentDay && (
          <DisplayCurrentDay>
            <div style={{ color: '#606060', fontFamily: 'inter', weight: '400', fontSize: '10px' }}>
              Date :<br />
            </div>
            {currentDate.format('L')}
          </DisplayCurrentDay>
        )}
        {tooltipDatePicker && (
          <div style={{ marginLeft: '-25px', marginRight: '15px' }}>
            <Tooltip text={tooltipDatePicker} />
          </div>
        )}
        {displayTargetProductionWasteRate && (
          <LittleBoxContainer style={{ marginLeft: 0, marginRight: '24px' }}>
            <TitleContainer>
              {i18next.t('PRODUCTION.REAL_TIME.LABEL_TARGET_LOSS_RATIO')}
              <Tooltip
                text={i18next.t('PRODUCTION.REAL_TIME.METRIC_TARGET_LOSS_RATIO_TOOLTIP', {
                  storeName: getClientStoreNameTranslation(storeName, true).toLowerCase(),
                })}
              />
            </TitleContainer>
            <div className="kpi">
              {isLoading ? (
                <DeepsightComponentLoader height={16} width={16} />
              ) : (
                targetProductionWasteRate
              )}
            </div>
          </LittleBoxContainer>
        )}
      </FilterContainer>
      {handleExport && (
        <FilterContainer minWidth>
          <Filter>
            <Button
              buttonCustomStyle={{ width: 'fit-content', minWidth: 100, fontWeight: 600 }}
              color={readOnly || disableExportButton ? 'locked' : 'blue'}
              fontSize={14}
              formatText={false}
              handleClick={() => {
                if (readOnly || disableExportButton) {
                  return;
                }

                handleExport();
              }}
              icon={'/images/inpulse/file-download-white-small.svg'}
              isDisabled={readOnly || disableExportButton}
              label={i18next.t('GENERAL.EXPORT')}
            />
          </Filter>
        </FilterContainer>
      )}
    </Container>
  );
};

const StyledAnalyticsHeaders = styled(DeepsightAnalyticsHeaders)`
  & .orderform-date-input-icon,
  .multiple-dropdown-icon {
    margin-left: 15px;
  }

  & .DateInput_fang {
    display: none !important;
  }

  & .DateRangePickerInput__disabled {
    background-color: lightgrey !important;
  }

  & .CalendarMonth_caption {
    color: ${(props) => props.theme.colors?.greys?.darkest} !important;
    font: ${(props) => props.theme.fonts?.h3} !important;
  }

  & .DateInput_input {
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    text-align: center !important;
    cursor: pointer;
  }

  & .DateInput_input__focused {
    border-bottom: none !important;
  }

  & .DayPicker_weekHeader_li {
    font: ${(props) => props.theme.fonts?.textMicro} !important;
  }

  & .CalendarDay {
    background: ${(props) => props.theme.colors?.greys.lightest} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__default:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__highlighted_calendar {
    background: #fff !important;
    border: 1px double #0a3eff !important;
  }

  & .CalendarDay__highlighted_calendar:hover {
    background: #e4e7e7 !important;
  }

  & .CalendarDay__selected_span {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    border: 1px double #e2e2e2 !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected,
  .CalendarDay__selected:active .CalendarDay__selected:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_start,
  .CalendarDay__selected_start:hover,
  .CalendarDay__selected_end,
  .CalendarDay__selected_end:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover {
    color: ${(props) => props.theme.colors?.greys.dark} !important;
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
  }
`;

DeepsightAnalyticsHeaders.propTypes = {
  // countries filter
  selectedCountry: PropTypes.object,
  setSelectedCountry: PropTypes.func,
  countries: PropTypes.arrayOf(PropTypes.object),
  // brands filter
  selectedBrands: PropTypes.arrayOf(PropTypes.object),
  setSelectedBrands: PropTypes.func,
  brands: PropTypes.arrayOf(PropTypes.object),
  // retailers filter
  selectedRetailers: PropTypes.arrayOf(PropTypes.object),
  setSelectedRetailers: PropTypes.func,
  retailers: PropTypes.arrayOf(PropTypes.object),
  // groups filter
  selectedGroups: PropTypes.arrayOf(PropTypes.object),
  setSelectedGroups: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.object),
  // stores filter
  selectedStores: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
  setSelectedStores: PropTypes.func,
  stores: PropTypes.arrayOf(PropTypes.object),
  // production ranges filter
  selectedProductionRanges: PropTypes.arrayOf(PropTypes.object),
  setSelectedProductionRanges: PropTypes.func,
  productionRanges: PropTypes.arrayOf(PropTypes.object),
  // suppliers filter
  selectedSuppliers: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
  setSelectedSuppliers: PropTypes.func,
  supplier: PropTypes.arrayOf(PropTypes.object),
  // metrics filter
  selectedMetric: PropTypes.object,
  setSelectedMetric: PropTypes.func,
  metrics: PropTypes.arrayOf(PropTypes.object),
  // date picker filter
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  minimumNightsDatePicker: PropTypes.number,
  tooltipDatePicker: PropTypes.string,
  handleSelectedDates: PropTypes.func,
  highlightDayDatePicker: PropTypes.func,
  renderCalendarInfoDatePicker: PropTypes.func,
  // export
  handleExport: PropTypes.func,
  exportButtonPositionLeft: PropTypes.bool,
  // extra props
  readOnly: PropTypes.bool,
};

DeepsightAnalyticsHeaders.defaultProps = {
  readOnly: false,
  exportButtonPositionLeft: false,
  numberOfMonthsDatePicker: DEFAULT_NUMBER_OF_MONTH_DATE_PICKER,
};

export default StyledAnalyticsHeaders;
