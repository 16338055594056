import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import {
  openGenericModal,
  refreshGenericModal,
  closeGenericModal,
  openSmallModal,
} from '@actions/modal';

import { Dropdown, Franco, Button } from '@commons/utils/styledLibraryComponents';
import { formatNumber } from '@commons/DisplayNumber';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_EXPORT_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getTheme } from '@commons/utils/theme';

import { ForecastsContext } from '@context/ForecastsContext';

import { getClientInfo } from '@selectors/client';

import { Container, InfosContainer } from './styledComponents';
import ForecastsExportModal from './components/forecastsExportModal';
import ForecastsProgressExportModal from './components/forecastsProgressExportModal';

export const ForecastsPerformancesContainer = (props) => {
  const {
    currency,
    forecasts,
    lastYearSales,
    sales,
    stores,
    storeTimezone,
    openGenericModal,
    refreshGenericModal,
    closeGenericModal,
    openModalExportInfo,
    modal,
    client: { forecastProperty, isForecastTurnover, turnoverName },
  } = props;

  const theme = getTheme();

  const [performance, setPerformance] = useState('-');
  const [lastWeekSales, setLastWeekSales] = useState('-');
  const [lastWeekPastYearSales, setLastWeekPastYearSales] = useState('-');
  const [thisWeekPastYearSales, setThisWeekPastYearSales] = useState('-');
  const [thisWeekTotal, setThisWeekTotal] = useState('-');
  const [lastWeekError, setLastWeekError] = useState('');
  const [thisWeekError, setThisWeekError] = useState('');

  // Export modal states
  const [exportStartDate, setExportStartDate] = useState(null);
  const [exportEndDate, setExportEndDate] = useState(null);
  const [selectedStoresForExport, setSelectedStoresForExport] = useState([]);
  const [isExportModalButtonDisabled, setIsExportModalButtonDisabled] = useState(false);

  const contextHandler = useContext(ForecastsContext);

  const timezonedNow = moment.tz(storeTimezone);

  useEffect(() => {
    generateData();
  }, [sales, forecasts, lastYearSales]);

  useEffect(() => {
    const isModalOpened = get(modal, 'GenericModalBool', false);

    if (!isModalOpened) {
      return;
    }

    const params = {
      type: 'action',
      width: '512px',
      height: 'auto',
      icon: '/images/inpulse/file-download-black-small.svg',
      title: i18next.t('FORECAST.TURNOVER.EXPORT_FORECASTS'),
      component: ForecastsExportModal,
      data: {
        stores,
        handleForecastsExport,
        exportStartDate,
        setExportStartDate,
        exportEndDate,
        setExportEndDate,
        selectedStoresForExport,
        setSelectedStoresForExport,
        setIsExportModalButtonDisabled,
      },
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_EXPORT_BUTTON(),
          handleClick: handleForecastsExport,
          isDisabled: isExportModalButtonDisabled,
        },
      ],
    };

    refreshGenericModal(params);
  }, [isExportModalButtonDisabled]);

  const generateData = () => {
    const currentForecasts = forecasts.filter(
      (forecast) =>
        moment
          .tz(forecast.timestamp, storeTimezone)
          .isBefore(timezonedNow.clone().endOf('isoWeek')) &&
        moment
          .tz(forecast.timestamp, storeTimezone)
          .isAfter(timezonedNow.clone().startOf('isoWeek').subtract(1, 'week')),
    );

    const currentSales = sales.filter((sale) =>
      moment
        .tz(sale.timestamp, storeTimezone)
        .isAfter(timezonedNow.clone().startOf('isoWeek').subtract(1, 'week')),
    );

    const pastWeekDays = getDates(
      timezonedNow.clone().startOf('isoWeek').subtract(1, 'week'),
      timezonedNow.clone().endOf('isoWeek').subtract(1, 'week'),
      'day',
    );

    let res, filteredSales, filteredForecasts;

    const pastSalesAndForecasts = pastWeekDays.map((day) => {
      res = {
        timestamp: day,
        sales: null,
        forecasts: null,
      };

      const timezonedDay = moment.tz(day, storeTimezone);

      filteredSales = currentSales.filter((sale) =>
        moment.tz(sale.timestamp, storeTimezone).isSame(timezonedDay, 'day'),
      );

      if (filteredSales.length > 0) {
        res.sales = filteredSales[0][forecastProperty];
      }

      filteredForecasts = currentForecasts.filter(
        (forecast) =>
          moment.tz(forecast.timestamp, storeTimezone).isSame(timezonedDay, 'day') &&
          forecast.type === 'saved',
      );

      if (filteredForecasts.length > 0) {
        res.forecasts = filteredForecasts[0][forecastProperty];
      }

      return res;
    });

    let count = 0,
      sum = 0,
      saleTot = 0;

    pastSalesAndForecasts.forEach((elem, index) => {
      if (elem.sales && elem.forecasts) {
        sum += Math.pow(elem.forecasts - elem.sales, 2);
        count++;
        saleTot += elem.sales;
      } else if (elem.sales) {
        saleTot += elem.sales;
      }
    });

    let lastWeekSales = saleTot;

    let performance = Math.round((Math.sqrt(sum / count) / (saleTot / count)) * 100, 10);

    performance = isNaN(performance) ? '-' : 100 - performance > 0 ? 100 - performance : 0;

    let lastWeekPastYearSales = 0;
    let thisWeekPastYearSales = 0;

    lastYearSales.forEach((sale) => {
      const timezonedSaleTimestamp = moment.tz(sale.timestamp, storeTimezone);

      if (
        timezonedSaleTimestamp
          .clone()
          .isAfter(timezonedNow.clone().subtract(1, 'year').startOf('isoWeek')) &&
        timezonedSaleTimestamp
          .clone()
          .isBefore(timezonedNow.clone().subtract(1, 'year').endOf('isoWeek'))
      ) {
        thisWeekPastYearSales += sale[forecastProperty];
      }

      if (
        timezonedSaleTimestamp
          .clone()
          .isAfter(
            timezonedNow.clone().subtract(1, 'year').subtract(1, 'week').startOf('isoWeek'),
          ) &&
        timezonedSaleTimestamp
          .clone()
          .isBefore(timezonedNow.clone().subtract(1, 'year').startOf('isoWeek'))
      ) {
        lastWeekPastYearSales += sale[forecastProperty];
      }
    });

    const thisWeekDays = getDates(
      timezonedNow.clone().startOf('isoWeek'),
      timezonedNow.clone().endOf('isoWeek'),
      'day',
    );

    let thisWeekTotal = 0;
    let value;

    thisWeekDays.map((day) => {
      const timezonedDay = moment.tz(day, storeTimezone);
      value = -1;

      currentSales.forEach((sale) => {
        if (moment.tz(sale.timestamp, storeTimezone).isSame(timezonedDay, 'day')) {
          value = sale[forecastProperty];
        }
      });

      if (value === -1 && currentForecasts.length > 0) {
        currentForecasts.forEach((forecast) => {
          if (
            moment.tz(forecast.timestamp, storeTimezone).isSame(timezonedDay, 'day') &&
            forecast.type === 'saved'
          ) {
            value = forecast[forecastProperty];
          }
        });

        if (value === -1) {
          currentForecasts.forEach((forecast) => {
            if (
              moment.tz(forecast.timestamp, storeTimezone).isSame(timezonedDay, 'day') &&
              forecast.type === 'dynamic'
            ) {
              value = forecast[forecastProperty];
            }
          });
        }
      }

      thisWeekTotal += value !== -1 ? value : 0;
    });

    let lastWeekError =
      lastWeekPastYearSales !== 0
        ? ((lastWeekSales - lastWeekPastYearSales) / lastWeekPastYearSales) * 100
        : 0;

    let thisWeekError =
      thisWeekPastYearSales !== 0
        ? ((thisWeekTotal - thisWeekPastYearSales) / thisWeekPastYearSales) * 100
        : 0;

    lastWeekSales =
      lastWeekSales === 0
        ? '-'
        : `${formatNumber(lastWeekSales, 0, true)} ${
            isForecastTurnover ? currency.alphabeticCode : ''
          }`;

    lastWeekPastYearSales =
      lastWeekPastYearSales === 0
        ? '-'
        : `${formatNumber(lastWeekPastYearSales, 0, true)} ${
            isForecastTurnover ? currency.alphabeticCode : ''
          }`;

    thisWeekPastYearSales =
      thisWeekPastYearSales === 0
        ? '-'
        : `${formatNumber(thisWeekPastYearSales, 0, true)} ${
            isForecastTurnover ? currency.alphabeticCode : ''
          }`;

    thisWeekTotal =
      thisWeekTotal === 0
        ? '-'
        : `${formatNumber(thisWeekTotal, 0, true)} ${
            isForecastTurnover ? currency.alphabeticCode : ''
          }`;

    lastWeekError =
      parseInt(lastWeekError) >= 0
        ? `+${parseInt(lastWeekError).toLocaleString()}`
        : parseInt(lastWeekError).toLocaleString();

    thisWeekError =
      parseInt(thisWeekError) >= 0
        ? `+${parseInt(thisWeekError).toLocaleString()}`
        : parseInt(thisWeekError).toLocaleString();

    setPerformance(performance);
    setLastWeekSales(lastWeekSales);
    setLastWeekPastYearSales(lastWeekPastYearSales);
    setThisWeekPastYearSales(thisWeekPastYearSales);
    setThisWeekTotal(thisWeekTotal);
    setLastWeekError(lastWeekError);
    setThisWeekError(thisWeekError);
  };

  const getDates = (startDate, stopDate, granularity) => {
    let dateArray = [];
    let currentDate = startDate.clone();

    while (currentDate <= stopDate) {
      dateArray.push(currentDate.format());
      currentDate = currentDate.add(1, granularity);
    }

    return dateArray;
  };

  const handleForecastsExport = () => {
    closeGenericModal();

    openModalExportInfo({
      component: ForecastsProgressExportModal,
      title: i18next.t('FORECAST.TURNOVER.EXPORT_IN_PROGRESS'),
      stores: selectedStoresForExport,
      startDate: exportStartDate,
      endDate: exportEndDate,
    });

    setSelectedStoresForExport([]);
    setExportStartDate(null);
    setExportEndDate(null);
  };

  const openForecastsExportModal = () => {
    const params = {
      type: 'action',
      width: '512px',
      height: 'auto',
      icon: '/images/inpulse/file-download-black-small.svg',
      title: i18next.t('FORECAST.TURNOVER.EXPORT_FORECASTS'),
      component: ForecastsExportModal,
      data: {
        stores,
        handleForecastsExport,
        exportStartDate,
        setExportStartDate,
        exportEndDate,
        setExportEndDate,
        selectedStoresForExport,
        setSelectedStoresForExport,
        setIsExportModalButtonDisabled,
      },
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_EXPORT_BUTTON(),
          handleClick: handleForecastsExport,
          isDisabled: true,
        },
      ],
    };

    openGenericModal(params);
  };

  return (
    <Container>
      <InfosContainer>
        <Dropdown
          iconSrc={'/images/inpulse/pin-black-small.svg'}
          items={stores.filter(({ active }) => active)}
          searchPlaceholder={i18next.t('GENERAL.SEARCH')}
          selectedItem={{
            id: get(contextHandler, 'selectedStore.id', ''),
            name: get(contextHandler, 'selectedStore.name', ''),
          }}
          isRequired
          onSelectionChange={(selectedItem) => {
            if (!!contextHandler.handleStoreSelection) {
              contextHandler.handleStoreSelection(selectedItem);
            }
          }}
        />
        <Franco
          background={theme.colors.infoGreen}
          color={theme.colors.greys.lightest}
          content={`${performance}%`}
          title={i18next.t('FORECAST.TURNOVER.PERFORMANCE_CONTAINER_S_1_PRECISION')}
          type="plain"
          width={'284px'}
        />
        <Franco
          background={lastWeekError >= 0 ? theme.colors.infoGreen : theme.colors.infoRed}
          color={theme.colors.greys.lightest}
          content={lastWeekSales.toLocaleString()}
          labelContent={`${lastWeekError}%`}
          labelTooltip={i18next.t('FORECAST.TURNOVER.PERFORMANCE_CONTAINER_S_1_TURNOVER_TOOLTIP', {
            turnoverName,
            week: timezonedNow.clone().subtract(1, 'week').isoWeek(),
            year: timezonedNow.clone().subtract(1, 'year').year(),
            quantity: lastWeekPastYearSales,
          })}
          minWidth={'240px'}
          title={i18next.t('FORECAST.TURNOVER.PERFORMANCE_CONTAINER_S_1_TURNOVER', {
            turnoverName,
          })}
          type="plain"
          width={'284px'}
        />
        <Franco
          background={thisWeekError >= 0 ? theme.colors.infoGreen : theme.colors.infoRed}
          color={theme.colors.greys.lightest}
          content={thisWeekTotal}
          labelContent={`${thisWeekError}%`}
          labelTooltip={i18next.t(
            'FORECAST.TURNOVER.PERFORMANCE_CONTAINER_NEXT_WEEK_TURNOVER_TOOLTIP',
            {
              turnoverName,
              week: timezonedNow.clone().isoWeek(),
              year: timezonedNow.clone().subtract(1, 'year').year(),
              quantity: thisWeekPastYearSales,
            },
          )}
          minWidth={'240px'}
          title={i18next.t('FORECAST.TURNOVER.PERFORMANCE_CONTAINER_NEXT_WEEK_TURNOVER', {
            turnoverName,
          })}
          type="plain"
          width={'284px'}
        />
      </InfosContainer>
      <Button
        color={'inpulse-default'}
        handleClick={openForecastsExportModal}
        icon={'/images/inpulse/file-download-white-small.svg'}
        label={i18next.t('GENERAL.EXPORT')}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
  modal: state.modalReducer,
});

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
});

const StyledForecastsPerformancesContainer = styled(ForecastsPerformancesContainer)`
  height: 64px;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;

  .forecasts-performances-container-leftchild {
    width: calc(50% - 10px);
    margin-right: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    .greeting {
      font: ${(props) => props.theme.fonts.textBig || 'normal 12px/6px proximanovaregular'};
    }
  }

  .forecasts-performances-container-rightchild {
    width: calc(60% - 10px);
    background: ${(props) => props.theme.colors?.greys.lightest || '#fffff'};
    box-shadow: ${(props) => props.theme.shadows?.short || '0 2px 6px 0 rgba(0, 0, 0, 0.1)'};
    margin-left: 10px;
    border-radius: ${(props) => props.theme.borders?.radius.weak || '5px'};
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .forecast-performances-spacer {
    width: 1px;
    height: 60%;
    background: ${(props) => props.theme.colors?.greys.light || '#d8d8d8'};
  }

  .forecast-performances-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding-left: 2%;
    width: calc(100% / 3);
    &.sm {
      ${(props) => (props.theme.colors ? 'width: calc(100% / 4);' : undefined)}
    }
    .perf-container {
      justify-content: start;
    }
  }

  .forecast-performances-item > h1 {
    margin: 0;
    padding: 0;
    font: ${(props) => props.theme.fonts?.textMicro || 'normal 12px/12px proximanovaregular'};
    margin-bottom: 5px;
    color: ${(props) => props.theme.colors?.greys.darker || 'black'};
  }

  .forecast-performances-item-value-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    width: 100%;
  }

  .forecast-performances-item-value-container > p {
    margin: 0;
    padding: 0;
    &.performance {
      font: ${(props) => props.theme.fonts?.kpiBig || 'normal 24px/24px proximanovaregular'};
      color: ${(props) => props.theme.colors?.greys.darker || 'black'};
    }
    &.weekly {
      font: ${(props) => props.theme.fonts?.kpiBig || 'normal 24px/24px proximanovaregular'};
      color: ${(props) => props.theme.colors?.greys.darker || 'black'};
    }

    &.last-year {
      font: ${(props) => props.theme.fonts?.textSmall || 'normal 14px/24px proximanovaregular'};
      color: ${(props) => props.theme.colors?.greys.dark || '#97A5C4'};
    }
  }

  .forecast-performances-trend-encloser {
    font: ${(props) => props.theme.fonts?.textSmall || 'normal 12px/6px proximanovaregular'};
    display: flex;
    align-items: center;
    height: 18px;
  }

  .forecast-preformances-trend-arrow {
    width: 12px;
    margin-left: 5px;
  }

  .forecast-preformances-trend-text {
    margin: 0 0 0 3px;
    padding: 0;
  }

  .deepsight-assistant-encloser {
    margin-left: 20px;
    box-shadow: ${(props) => props.theme.colors || '0 2px 5px 0 rgba(0, 0, 0, 0.2)'};
    width: 45px;
    height: 45px;
    border-radius: ${(props) => props.theme.borders?.radius.weak || '50px'};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    font: ${(props) => props.theme.fonts?.h1 || 'normal 12px/6px proximanovaregular'};
    background: ${(props) => props.theme.colors?.brand.secondary || '#fffff'};
    color: ${(props) => props.theme.colors?.greys.lightest || '#fffff'};
  }
  .deepsight-assistant-encloser > img {
    width: 36px;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(StyledForecastsPerformancesContainer);
