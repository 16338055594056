import styled from 'styled-components';

export const Container = styled.div`
  margin: auto;

  width: 240px;

  display: flex;
  flex-direction: column;
`;

export const SupplierProductDetailsContainer = styled.div`
  width: 100%;

  font: ${(props) => props.theme.fonts.textSmall};
  color: ${(props) => props.theme.colors.greys.darker};
`;

export const SupplierProductDeletionContentContainer = styled.div`
  width: 100%;

  font: ${(props) => props.theme.fonts.textBigHeight24};
  color: ${(props) => props.theme.colors.greys.darker};
`;

export const SupplierProductPriceContainer = styled.div`
  display: flex;
  gap: 4px;
`;

export const DropdownContainer = styled.div`
  width: 100%;
  height: 80px;
`;
