import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import { Dropdown, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';

import { Input } from '@lib/inpulse/Input';

import theme from '@theme';

import {
  Container,
  DropdownIcon,
  DropdownItem,
  FormContainer,
  Section,
  SectionInfo,
  Text,
  Title,
} from './styledComponents';

const ProfileFirstNameInput = {
  name: 'USERS.DETAILS.PROFILE_FIRSTNAME_INPUT_LABEL',
  keyProperty: 'firstName',
  component: Input,
  props: ({ isDisabled, profileUser, setProfileUser }) => ({
    type: 'text',
    readOnly: isDisabled,
    onChange: (event) => handleOnChangeProfileUser(profileUser, setProfileUser, event, 'firstName'),
  }),
};

const ProfileLastNameInput = {
  name: 'USERS.DETAILS.PROFILE_LASTNAME_INPUT_LABEL',
  keyProperty: 'lastName',
  component: Input,
  props: ({ isDisabled, profileUser, setProfileUser }) => ({
    type: 'text',
    readOnly: isDisabled,
    onChange: (event) => handleOnChangeProfileUser(profileUser, setProfileUser, event, 'lastName'),
  }),
};

const ProfileEmailInput = {
  name: 'USERS.DETAILS.PROFILE_EMAIL_INPUT_LABEL',
  keyProperty: 'email',
  component: Input,
  props: () => ({
    type: 'text',
    readOnly: true,
  }),
};

const ProfileRoleInput = {
  name: 'USERS.DETAILS.PROFILE_ROLE_INPUT_LABEL',
  keyProperty: 'roleName',
  component: Dropdown,
  props: ({
    isDeepsightUser,
    isCurrentUserProfile,
    profileUser,
    setProfileUser,
    clientRoles = [],
  }) => {
    const selectedRole = clientRoles.find((role) => role.name === profileUser.roleName);

    return {
      isDisabled: isCurrentUserProfile && !isDeepsightUser,
      items: clientRoles,
      label: i18next.t('GENERAL.RETAILER'),
      labelStyle: { font: theme.fonts.textSmall },
      selectedItem: selectedRole,
      isRequired: true,
      width: INPUT_WIDTH.FULL,
      onSelectionChange: (selectedItem) => {
        handleRoleInput(profileUser, setProfileUser, clientRoles, selectedItem.name);
      },
    };
  },
};

const ProfileCatalogInput = {
  name: 'USERS.DETAILS.PROFILE_CATALOG_INPUT_LABEL',
  keyProperty: 'catalogId',
  component: Dropdown,
  props: ({ catalogs, profileUser, setProfileUser, isDeepsightUser, isCurrentUserProfile }) => {
    const catalogItems = [
      { id: -1, name: i18next.t('GENERAL.SELECT_NONE_MASCULINE') },
      ...catalogs,
    ];

    const selectedCatalog =
      catalogs.find(({ id }) => id === profileUser.catalogId) || catalogItems[0];

    return {
      isDisabled: isCurrentUserProfile && !isDeepsightUser,
      items: catalogItems,
      onSelectionChange: ({ id }) => {
        const updatedProfileUser = { ...profileUser, catalogId: id };
        setProfileUser({ ...updatedProfileUser });
      },
      isRequired: true,
      selectedItem: selectedCatalog,
      width: INPUT_WIDTH.FULL,
      label: i18next.t('USERS.DETAILS.PROFILE_CATALOG_INPUT_LABEL'),
      labelStyle: { font: theme.fonts.textSmall },
      tooltipText: i18next.t('BACKOFFICE.TEAM.FIRST_STEP_CATALOG_TOOLTIP'),
    };
  },
};

const ProfileLanguageInput = {
  name: 'USERS.DETAILS.PROFILE_LANGUAGE_INPUT_LABEL',
  keyProperty: 'languageName',
  component: Dropdown,
  props: ({ languages, profileUser, setProfileUser }) => {
    const [languagesDropdownItems, setLanguagesDropdownItems] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const getLanguagesDropdownItems = () =>
      languagesDropdownItems.map((language) => ({
        ...language,
        renderValue: () => (
          <DropdownItem>
            <DropdownIcon src={language.iconSrc} />
            <Text>{language.name}</Text>
          </DropdownItem>
        ),
      }));

    const fetchLanguages = () => {
      try {
        const formattedLanguages = languages.map((language) => ({
          ...language,
          iconSrc: `/images/flags/icon-flag-${language.code}.svg`,
        }));

        setLanguagesDropdownItems(formattedLanguages);

        const language = formattedLanguages.find(({ id }) => id === profileUser.languageId);
        setSelectedLanguage(language);
      } catch {
        showErrorMessage(i18next.t('USERS.DETAILS.FETCH_CLIENT_LANGUAGES_ERROR'));
      }
    };

    useEffect(() => {
      fetchLanguages();
    }, []);

    return {
      items: getLanguagesDropdownItems(languages),
      onSelectionChange: (selectedItem) => {
        setSelectedLanguage(languagesDropdownItems.find(({ id }) => id === selectedItem.id));
        handleLanguagesInput(profileUser, setProfileUser, languages, selectedItem.name);
      },
      isRequired: true,
      selectedItem: selectedLanguage,
      iconSrc: `/images/flags/icon-flag-${profileUser.languageCode}.svg`,
      width: INPUT_WIDTH.FULL,
      label: i18next.t('USERS.DETAILS.PROFILE_LANGUAGE_INPUT_LABEL'),
      labelStyle: { font: theme.fonts.textSmall },
    };
  },
};

/**
 * Method that is in charge of updating the language setting of a user profile
 *
 * @param {User} profileUser        - The original user when the user performs the change in an input
 * @param {Function} setProfileUser - Method used to set the local state of the user variable
 * @param {Language[]} languages    - The list of languages available for the client
 * @param {String} selectedLanguage - The selected language name from the user
 */
export function handleLanguagesInput(profileUser, setProfileUser, languages, selectedLanguage) {
  const updatedProfileUser = {
    ...profileUser,
    languageId: null,
    languageName: null,
  };

  const matchingLanguage = languages.find(
    (languagesClient) => languagesClient.name.toLowerCase() === selectedLanguage.toLowerCase(),
  );

  if (matchingLanguage) {
    updatedProfileUser.languageId = matchingLanguage.id;
    updatedProfileUser.languageName = matchingLanguage.name;
    updatedProfileUser.languageCode = matchingLanguage.code;
  }

  setProfileUser({ ...updatedProfileUser });
}

/**
 * Method that is in charge of updating the role setting of a user profile
 *
 * @param {User} profileUser        - The original user when the user performs the change in an input
 * @param {Function} setProfileUser - Method used to set the local state of the user variable
 * @param {Role[]} clientRoles      - The list of roles available for the client
 * @param {String} selectedRole     - The selected role name from the user
 */
export function handleRoleInput(profileUser, setProfileUser, clientRoles, selectedRole) {
  const updatedProfileUser = { ...profileUser, roleId: null, roleName: null };

  const matchingRole = clientRoles.find(
    (role) => role.name.toLowerCase() === selectedRole.toLowerCase(),
  );

  if (matchingRole) {
    updatedProfileUser.roleId = matchingRole.id;
    updatedProfileUser.roleName = matchingRole.name;
  }

  setProfileUser({ ...updatedProfileUser });
}

/**
 * Generic method that is in charge of updating the profile user from the inputs that the user changes
 *
 * @param {User} profileUser        - The original user when the user performs the change in an input
 * @param {Function} setProfileUser - Method used to set the local state of the user variable
 * @param {Event} event             - The event emitted by the input OnChange method
 * @param {String} fieldName        - The field name on which apply the change
 */
export function handleOnChangeProfileUser(profileUser, setProfileUser, event, fieldName) {
  let updatedFieldValue = event.target.value;

  const updatedProfileUser = { ...profileUser, [fieldName]: updatedFieldValue };

  setProfileUser({ ...updatedProfileUser });
}

/**
 * Get the list of inputs to allow in the form according
 *
 * @returns {props} Props of the components displaying the inputs
 */
export function getListInputs({ catalogs }) {
  const firstRowInputs = [ProfileFirstNameInput, ProfileLastNameInput, ProfileEmailInput];
  const secondRowInputs = [ProfileRoleInput, ProfileLanguageInput];

  if (catalogs && catalogs.length) {
    secondRowInputs.splice(1, 0, ProfileCatalogInput);
  }

  return [firstRowInputs, secondRowInputs];
}

/**
 * Render the user profile form section with the different inputs that should be displayed
 *
 * @param {Props} props                   - The props sent to method
 * @param {Product} props.profileUser     - The user information to display
 * @param {Function} props.setProfileUser - Method used to set the local state of the user variable
 */
export function renderForm(props) {
  const { profileUser } = props;

  const listInputsBySection = getListInputs(props);

  return (
    <>
      {listInputsBySection.map((section, indexSection) => (
        <Section data-testid={'section-form'} key={indexSection}>
          {section.map((item, indexItem) => {
            const ComponentName = item.component;

            return (
              <SectionInfo key={indexItem}>
                <ComponentName
                  {...item.props(props)}
                  data-testid={item.name}
                  label={i18next.t(item.name)}
                  placeholder={i18next.t(item.name)}
                  value={profileUser[item.keyProperty] || ''}
                />
              </SectionInfo>
            );
          })}
        </Section>
      ))}
    </>
  );
}

export function ProfileInfoSection(props) {
  return (
    <Container>
      <Title>{i18next.t('USERS.DETAILS.PROFILE_INFORMATION_SECTION_TITLE').toLowerCase()}</Title>
      <FormContainer>{renderForm(props)}</FormContainer>
    </Container>
  );
}

export default ProfileInfoSection;
