import AdminCashierModule from './cashier';
import AdminCatalogsContainer from './catalogs';
import AdminInventoriesModule from './inventories';
import AdminProductsModule from './products';
import AdminSales from './sales';
import AdminStoresModule from './stores';
import AdminSuppliersModule from './suppliers';

export const AdminModule = {
  ...AdminProductsModule,
  ...AdminSuppliersModule,
  ...AdminStoresModule,
  ...AdminCashierModule,
  ...AdminInventoriesModule,
  AdminSales,
  ...AdminCatalogsContainer,
};

import KitchenProductsModule from './KitchenProducts';
import RecipeDetails from './products/recipes/detail';

export const CentralKitchenAdminModule = {
  ...KitchenProductsModule,
  KitchenRecipeDetails: RecipeDetails,
};

export default { CentralKitchenAdminModule, AdminModule };
