import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import utilsXLS from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import invoiceService from '@services/invoice';

import { formatICLOcerizationDataForExport } from '../../utils/exportUtils';
import { getMainSheetOcerizationColumnsSettings } from './columns';

export const InvoiceControlListsOcerizationExportModal = (props) => {
  const {
    closeModal,
    params: { invoiceControlListIds, currency, storeName },
  } = props;

  const [isLoading, setLoading] = useState(true);
  const [iclsOcerizationDataToExport, setIclsOcerizationDataToExport] = useState([]);
  const [ICLTotalFetchedCount, setICLTotalFetchedCount] = useState(0);

  const ICLTotalToFetchNb = invoiceControlListIds.length;

  // Progress modal
  const [progress, setProgress] = useState(0);
  const [title, setTitle] = useState(
    i18next.t('INVOICE.INVOICE_CONTROLS.CONTENT_EXPORT_MODAL_TITLE'),
  );

  const exitModal = () => {
    setLoading(false);
    closeModal();
  };

  const exportReady = () => {
    setTitle(i18next.t('GENERAL.EXPORT_SUCCESS'));
    setLoading(false);
  };

  const exportFailure = () => {
    setTitle(i18next.t('GENERAL.EXPORT_FAILURE'));
    setLoading(false);
  };

  const updateProgress = (totalFetched) => {
    const invoiceControlsLeft = ICLTotalToFetchNb - totalFetched;
    const updatedProgress = 100 - (invoiceControlsLeft / ICLTotalToFetchNb) * 100;

    setProgress(updatedProgress);
  };

  const getInvoiceControlListsOcerizationData = async (invoiceControlListId) => {
    try {
      const invoice = await invoiceService.getById(invoiceControlListId);

      const fetchedICLOcerizationData = await invoiceService.getOcerizationById(
        invoiceControlListId,
      );

      const formattedIclsOcerizationData = formatICLOcerizationDataForExport(
        invoice,
        fetchedICLOcerizationData,
      );

      const updatedFetchedNb = ICLTotalFetchedCount + 1;

      setIclsOcerizationDataToExport([
        ...iclsOcerizationDataToExport,
        ...formattedIclsOcerizationData,
      ]);

      setICLTotalFetchedCount(updatedFetchedNb);
      updateProgress(updatedFetchedNb);
    } catch {
      exportFailure();
    }
  };

  const exportData = () => {
    const columns = getMainSheetOcerizationColumnsSettings(storeName);

    const fileName = i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_EXPORT_OCERIZATION_FILENAME');

    const mainSheet = {
      data: iclsOcerizationDataToExport,
      title: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_MAIN_SHEET_NAME'),
      headers: columns,
    };

    utilsXLS.makeXLS(fileName, [
      utilsXLS.generateDefaultSheet(mainSheet.title, mainSheet.headers, mainSheet.data, currency),
    ]);

    return exportReady();
  };

  useEffect(() => {
    if (isLoading && ICLTotalFetchedCount < ICLTotalToFetchNb) {
      // Get invoice control list ocerization data one by one
      const nextInvoiceControlListId = invoiceControlListIds[ICLTotalFetchedCount];

      (async () => {
        await getInvoiceControlListsOcerizationData(nextInvoiceControlListId);
      })();

      return;
    }

    exportData();
  }, [ICLTotalFetchedCount]);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setLoading}
      titleModal={title}
    />
  );
};

export default InvoiceControlListsOcerizationExportModal;
