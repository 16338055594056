import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Dropdown } from '@commons/utils/styledLibraryComponents';

import { getCentralKitchenStores, getSalesPointStores } from '@selectors/stores';

import StockFormDatePicker from './StockFormDatePicker';

import { HeaderSelectorsContainer } from './styledComponents';

function StockFormHeaderSelectors(props) {
  const {
    displayWarning,
    isEditingDate,
    readOnly,
    selectedStore,
    setSelectedStore,
    setStockDate,
    stockDate,
    stockOnlyToday,
    stockType,
    salesPointStores,
    centralKitchenStores,
    isCentralKitchenView,
    inventoryListTemplates,
    selectedInventoryListTemplate,
    setSelectedInventoryListTemplate,
  } = props;

  // Display either central kitchen stores or not central kitchen stores
  const filteredStores = isCentralKitchenView ? centralKitchenStores : salesPointStores;

  const hasInventoryListTemplates = !isEmpty(inventoryListTemplates);

  return (
    <HeaderSelectorsContainer>
      <Dropdown
        customStyle={{ position: 'inherit' }}
        iconSrc={
          !selectedStore
            ? '/images/inpulse/pin-dmgrey-small.svg'
            : '/images/inpulse/pin-black-small.svg'
        }
        isDisabled={readOnly}
        isRequired={true}
        items={filteredStores}
        searchPlaceholder={i18next.t('GENERAL.SEARCH')}
        selectedItem={selectedStore}
        onSelectionChange={(selectedItem) => setSelectedStore(selectedItem.id)}
      />
      <StockFormDatePicker
        displayWarning={displayWarning}
        handleSelectDate={setStockDate}
        isEditingDate={isEditingDate}
        readOnly={readOnly}
        stockDate={stockDate}
        stockOnlyToday={stockOnlyToday}
        stockType={stockType}
        storeId={selectedStore.id}
        storeTimezone={selectedStore.timezone}
      />
      <Dropdown
        customStyle={{ position: 'inherit' }}
        isDisabled={readOnly || !hasInventoryListTemplates}
        isRequired={hasInventoryListTemplates}
        items={inventoryListTemplates}
        placeholder={
          hasInventoryListTemplates
            ? i18next.t('STOCKS.STOCKS.FORM_CHOOSE_TEMPLATE')
            : i18next.t('STOCKS.STOCKS.FORM_NO_TEMPLATE')
        }
        selectedItem={selectedInventoryListTemplate}
        onSelectionChange={(selectedItem) => setSelectedInventoryListTemplate(selectedItem.id)}
      />
    </HeaderSelectorsContainer>
  );
}

const mapStateToProps = (state) => ({
  salesPointStores: getSalesPointStores(state.baseReducer.activeStores),
  centralKitchenStores: getCentralKitchenStores(state.baseReducer.activeStores),
});

export default connect(mapStateToProps)(StockFormHeaderSelectors);
