import React from 'react';

import { Container, WrapperLabel, FirstLetter } from './styledComponents';

import { Tooltip } from '../../../commons/utils/styledLibraryComponents';

export const Textarea = (props) => {
  const required = props.label && props.label[0] === '*';

  const textareaProps = {};

  Object.keys(props).forEach((key) => {
    if (key !== 'tooltipText') textareaProps[key] = props[key];
  });

  return (
    <Container disabled={props.readOnly}>
      {(props.label || props.icon || props.tooltipText) && (
        <WrapperLabel>
          {' '}
          {props.label && (
            <label htmlFor={props.name}>
              <FirstLetter required={required}>{`${props.label[0]}`}</FirstLetter>
              {`${props.label.substring(1)} :`}
            </label>
          )}
          {props.tooltipText && props.tooltipText.length > 0 && (
            <Tooltip text={props.tooltipText} />
          )}
        </WrapperLabel>
      )}
      <textarea {...textareaProps} disabled={props.readOnly} />
    </Container>
  );
};
