import { get } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { Text } from '@admin/stores/components/styledComponents';

import { Dropdown, ListView } from '@commons/utils/styledLibraryComponents';
import { NONE_DROPDOWN_VALUES } from '@commons/constants/dropdown';
import { Tags } from '@commons/Tags';
import EmptyState from '@commons/EmptyState';

const { Container, HeaderContainer } = require('./styledComponents');

export const MODAL_STEP = {
  STORE_SELECTION: 'STORE_SELECTION',
  VALIDATION: 'VALIDATION',
  PROGRESS_BAR: 'PROGRESS_BAR',
};

const getStoreAssortmentSetupColumns = (hasMultipleBrands) => [
  {
    id: 'storeName',
    propertyKey: 'name',
    name: i18next.t('GENERAL.NAME'),
    render: (it) => <Text highlighted>{it}</Text>,
  },
  ...(hasMultipleBrands
    ? [
        {
          id: 'brand',
          propertyKey: 'lnkBrandStorerel.name',
          name: i18next.t('ADMIN.STORES.LIST_COLUMN_BRAND'),
          filterType: 'string',
          render: (item) => <Text>{item ? item : '-'}</Text>,
        },
      ]
    : []),
  {
    id: 'partnerId',
    propertyKey: 'partnerId',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_ID'),
    displayBigger: true,
  },
  {
    id: 'city',
    propertyKey: 'city',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_CITY'),
    render: (item) => <Text>{item}</Text>,
  },
  {
    id: 'groups',
    propertyKey: 'groups',
    name: i18next.t('GENERAL.GROUPS'),
    filterType: 'string',
    render: (groups) => <Tags items={groups.map(({ name }) => name)} />,
  },
];

const StoreAssortmentSetupModal = (props) => {
  const { storesList, selectedStores, setStoreIdReference, hasMultipleBrands, groupItems } = props;

  const [dataColumns] = useState(getStoreAssortmentSetupColumns(hasMultipleBrands));
  const [filteredStoresList, setFilteredStoresList] = useState(storesList);
  const [selectedGroups, setSelectedGroups] = useState(groupItems);

  useEffect(() => {
    const selectedStoresIds = selectedStores.map(({ id }) => id);

    const selectedGroupIds = new Set(selectedGroups.map(({ id }) => id));

    const filteredSelectedStores = storesList.filter(({ id, active, isKitchen, groups }) => {
      const storeHasNoGroupsAndNoneIsSelected =
        !groups.length && selectedGroupIds.has(NONE_DROPDOWN_VALUES.MASCULINE.id);

      const storeHasAGroupSelected = groups.some(({ id }) => selectedGroupIds.has(id));

      return (
        active &&
        !isKitchen &&
        (storeHasNoGroupsAndNoneIsSelected || storeHasAGroupSelected) &&
        !selectedStoresIds.includes(id)
      );
    });

    setFilteredStoresList(filteredSelectedStores);
  }, [selectedGroups]);

  const handleSelection = (selectedItems) => {
    const storeId = get(selectedItems, '[0].id', null);

    setStoreIdReference(storeId);
  };

  return (
    <Container>
      <ListView
        columns={dataColumns}
        data={filteredStoresList}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        padding={'6px 0px 0px 0px'}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => (
          <HeaderContainer>
            <Dropdown
              iconSrc={
                selectedGroups.length
                  ? '/images/inpulse/category-ipblack-small.svg'
                  : '/images/inpulse/category-dmgrey-small.svg'
              }
              isUniqueSelection={false}
              items={groupItems}
              placeholder={i18next.t('GENERAL.GROUPS')}
              selectedItems={selectedGroups}
              isRequired
              onSelectionChange={setSelectedGroups}
            />
          </HeaderContainer>
        )}
        setSelectedItems={handleSelection}
        disableFooter
        disableMultipleSelection
      />
    </Container>
  );
};

export default StoreAssortmentSetupModal;
