export const ORDER_STATUS = {
  CREATION: 0,
  DRAFT: 1,
  SENT_EDITABLE: 2,
  SENT: 3,
  RECEPTION: 4,
  COMPLIANT: 5,
  INCOMPLETE: 6,
  NON_COMPLIANT: 7,
  CREDIT_REQUEST_PROCESSED: 8,
  BILLED: 10,
  PAID: 20,
};

export const ORDER_PREPARATION_STATUS = {
  TO_BE_PREPARED: 0,
  PREPARING: 1,
  COMPLIANT_PREPARED: 2,
  NON_COMPLIANT_PREPARED: 3,
  COMPLIANT_INVOICED: 4,
  NON_COMPLIANT_INVOICED: 5,
};

export default ORDER_STATUS;
