import { orderBy, get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getUserTimezone } from '@commons/utils/date';
import { numberToFixed } from '@commons/utils/format';
import utilsXLS from '@commons/utils/makeXLS';

const createAggregatedSheet = (data, currency) => {
  const title = i18next.t('FEATURE.FORECASTS.ANALYTICS_PAST_MIX');

  const columns = [
    {
      propertyKey: 'categoryName',
      name: i18next.t('GENERAL.CATEGORY'),
    },
    {
      propertyKey: 'name',
      name: i18next.t('GENERAL.NAME'),
    },
    {
      propertyKey: 'unit',
      name: i18next.t('GENERAL.SOLD_QUANTITY'),
    },
    {
      type: 'currency',
      propertyKey: 'priceExcludingTaxes',
      name: i18next.t('GENERAL.AVERAGE_PRICE_EX_TAX'),
    },
    {
      type: 'currency',
      propertyKey: 'turnoverExcludingTaxes',
      name: i18next.t('GENERAL.TURNOVER_EX_TAX'),
    },
    {
      propertyKey: 'categoryMix',
      name: `%${i18next.t('GENERAL.CATEGORY')}`,
      transform: (item) => numberToFixed(item, 2, ''),
    },
    {
      propertyKey: 'mixExcludingTaxes',
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_PERCENT_PRODUCT_MIX'),
      transform: (item) => numberToFixed(item, 2, ''),
    },
    {
      propertyKey: 'marginRatio',
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_PERCENT_MARGIN'),
      transform: (item) => numberToFixed(item, 2, ''),
    },
    {
      propertyKey: 'margin',
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_MARGIN_WITHOUT_TAXES'),
      transform: (item) => numberToFixed(item, 2, ''),
    },
  ];

  const formattedData = data.reduce((result, item) => {
    item.nestedData.forEach((product) => {
      result.push({
        ...product,
        categoryName: item.name,
        categoryMix: item.mixExcludingTaxes,
      });
    });
    return result;
  }, []);

  return utilsXLS.generateDefaultSheet(title, columns, formattedData, currency);
};

const createInfoSheet = (startDate, endDate, stores, clientStoreName) => {
  const title = i18next.t('GENERAL.INFORMATIONS');

  const userTimezone = getUserTimezone();
  const columns = [
    {
      propertyKey: 'startDate',
      name: i18next.t('FORECAST.PAST_MIX.PERIOD_START_COLUMN'),
      transform: (item) =>
        moment.tz(item, userTimezone).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
    },
    {
      propertyKey: 'endDate',
      name: i18next.t('FORECAST.PAST_MIX.PERIOD_END_COLUMN'),
      transform: (item) =>
        moment.tz(item, userTimezone).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
    },
    {
      propertyKey: 'stores',
      name: getClientStoreNameTranslation(clientStoreName, true),
      transform: (stores) => stores.map(({ name }) => name).join(','),
    },
  ];

  const data = [{ startDate, endDate, stores }];

  return utilsXLS.generateDefaultSheet(title, columns, data);
};

export const exportPastMix = ({
  data,
  useTtc,
  currency,
  clientStoreName,
  startDate,
  endDate,
  stores,
}) => {
  const withSortedNestedData = data.map((row) => {
    const sortedNestedData = orderBy(get(row, 'nestedData'), 'mix', 'desc');
    return { ...row, nestedData: sortedNestedData };
  });
  const sortedData = orderBy(withSortedNestedData, 'mix', 'desc');

  const aggregatedSheet = createAggregatedSheet(sortedData, currency);
  const infoSheet = createInfoSheet(startDate, endDate, stores, clientStoreName);

  const pastMixFilename = i18next.t('FORECAST.PAST_MIX.FILENAME', {
    date: moment.tz(getUserTimezone()).format(DATE_DISPLAY_FORMATS.STICKED_YEAR_MONTH_DAY),
  });

  utilsXLS.makeXLS(pastMixFilename, [aggregatedSheet, infoSheet]);
};
