import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.greys.lightest};

  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 8px;

  margin-bottom: 16px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
`;
