import { weatherStation } from '../services/weatherStation';

export const recieveWeatherForecastStation = (weatherStation) => ({
  type: 'WEATHER_FORECAST_STATION_SUCCESS',
  weatherStation,
});

export const requestWeatherForecastStationError = (error) => ({
  type: 'WEATHER_FORECAST_STATION_FAILURE',
  error,
});

export const getWeatherForecastStation = (storeLocation) =>
  function (dispatch) {
    dispatch({
      type: 'WEATHER_FORECAST_STATION_REQUEST',
    });
    return weatherStation.getWeatherForecastStation(storeLocation);
  };
