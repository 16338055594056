import { connect } from 'react-redux';
import { orderBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import utilsXLS, { generateFiles } from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { getClientInfo } from '@selectors/client';

import { getPastProductMix } from '@services/forecast';
import { group as groupService } from '@services/group';

import { getByStoreSheetColumns, getContextSheetColumns, PROP_KEYS } from './sheets';
import formatUtils from './format';

export const ExportPastMixByStoreModal = (props) => {
  const {
    params: { currency, context },
    client: { storeName },
    closeModal,
    showErrorMessage,
  } = props;

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const exitModal = () => {
    setIsLoading(false);

    closeModal();
  };

  useEffect(() => {
    setProgress(0);

    (async () => {
      try {
        const byStoreSheetData = [];
        const contextSheetData = [];

        const formattedStartDate = moment(context.startDate).format(
          DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
        );
        const formattedEndDate = moment(context.endDate).format(
          DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
        );

        const storeIds = context.stores.map(({ id }) => id);

        const groupMappings = await groupService.getGroupsOfStores(storeIds);

        const groupsByStoreId = groupMappings.reduce((acc, groupMapping) => {
          if (!acc[groupMapping.storeId]) {
            acc[groupMapping.storeId] = [];
          }

          acc[groupMapping.storeId].push(groupMapping.lnkGroupStoregroupmappingrel.name);

          return acc;
        }, {});

        for (const [index, store] of context.stores.entries()) {
          const fetchedAnalytics = await getPastProductMix(
            [store.id],
            formattedStartDate,
            formattedEndDate,
          );

          if (!!fetchedAnalytics) {
            const storeData = formatUtils.extractByStoreData(
              store,
              groupsByStoreId,
              fetchedAnalytics,
            );

            byStoreSheetData.push(...storeData);

            const contextData = formatUtils.extractContextData(store, context, fetchedAnalytics);

            contextSheetData.push(contextData);
          }

          setProgress(((index + 1) / context.stores.length) * 100);
        }

        const sortedByStoreData = orderBy(
          byStoreSheetData,
          [PROP_KEYS.STORE_NAME, PROP_KEYS.CATEGORY, PROP_KEYS.PRODUCT_NAME],
          ['asc', 'asc', 'asc'],
        );
        const byStoreSheet = utilsXLS.generateDefaultSheet(
          i18next.t('FORECAST.PAST_MIX.EXPORT_BY_STORE_SHEET_NAME'),
          getByStoreSheetColumns(storeName, groupMappings),
          sortedByStoreData,
          currency,
        );

        const contextSheet = utilsXLS.generateDefaultSheet(
          i18next.t('GENERAL.INFORMATIONS'),
          getContextSheetColumns(storeName),
          contextSheetData,
          currency,
        );

        generateFiles(
          i18next.t('FORECAST.PAST_MIX.EXPORT_BY_STORE_FILE_NAME', {
            storeName,
            date: moment().format(DATE_DISPLAY_FORMATS.STICKED_YEAR_MONTH_DAY),
          }),
          [byStoreSheet],
          contextSheet,
        );

        setIsLoading(false);
      } catch {
        showErrorMessage(i18next.t('GENERAL.EXPORT_FAILURE'));
        exitModal();
      }
    })();
  }, []);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setIsLoading}
      titleModal={i18next.t('FORECAST.PAST_MIX.EXPORT_BY_STORE_MODAL_TITLE')}
    />
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportPastMixByStoreModal);
