import React from 'react';

import PropTypes from 'prop-types';
import { PropsItemDisplay } from '../../interface';
import { Item } from './styledComponents';

const ItemDisplay = (props: PropsItemDisplay): JSX.Element => {
  const {
    value,
    bold,
    large,
    narrow,
    minWidth,
    textTransform,
    withRender,
    nbColumn,
    isLoading,
    blue,
  } = props;

  return (
    <>
      <Item
        nbColumn={nbColumn}
        large={large}
        bold={bold}
        blue={blue}
        narrow={narrow}
        minWidth={minWidth}
        textTransform={textTransform}
        withRender={withRender}
        isLoading={isLoading}
      >
        {value}
      </Item>
    </>
  );
};

ItemDisplay.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]).isRequired,
  bold: PropTypes.bool,
  large: PropTypes.bool,
  textTransform: PropTypes.string,
  withRender: PropTypes.bool,
  nbColumn: PropTypes.number.isRequired,
};

ItemDisplay.defaultProps = {
  bold: false,
  large: false,
  textTransform: '',
  withRender: false,
};

export default ItemDisplay;
