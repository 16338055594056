import { APIcaller } from '@services/APIcaller';

const getStocksOfIngredientsByCategories = async (storeId, date, categories, isStrategic) =>
  APIcaller.apiGet('/stocks/analytics/current', {
    date,
    storeId,
    categories,
    isStrategic,
  });

const getNextOrderOfEntityId = async (storeId, entityId, date) =>
  APIcaller.apiGet(`/admin/stores/${storeId}/ingredients/${entityId}/orders`, {
    date,
    storeId,
    entityId,
  });

export default {
  getNextOrderOfEntityId,
  getStocksOfIngredientsByCategories,
};
