import styled, { css } from 'styled-components';

export const SearchEmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 104px);
`;

export const FormContainer = styled.div`
  width: 100%;

  ${(props) =>
    props.displayBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.expandableEmbeddedList.childRow.separator.color};
    `}

  margin-bottom: 24px;

  cursor: pointer;
`;

// CATEGORY SUMUP
export const CategorySumupContainer = styled.div`
  display: flex;
  column-gap: 16px;
  padding: ${(props) => (props.isCategoryOpen ? '0px' : '0 0 16px 0')};
  border-bottom: ${(props) => (props.isCategoryOpen ? '0px' : '1px solid #e6e6e6')};
  cursor: pointer;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 3;
`;

export const Text = styled.div`
  text-transform: capitalize;
  font: ${(props) => props.theme.fonts.h2Inter} !important;
`;

// CATEGORY CONTENT
export const CategoryContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
  margin-bottom: 24px;
`;

export const ColumnsContainer = styled.div`
  display: flex;
  padding: 0 16px;
  margin: 24px 0 16px 0;
`;

export const Column = styled.div`
  font: ${(props) => props.theme.fonts?.textBig} !important;
  color: ${(props) => props.theme.listView?.header.default.color} !important;
  flex: 1;
  flex-basis: ${(props) => `calc(${(100 / props.nbColumns) * props.flex}%)`};
  margin-right: 16px;
`;

export const RowContainer = styled.div`
  border-radius: 8px;
  margin-bottom: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
`;

// ROW
export const RowContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 0 16px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  background-color: ${(props) => props.theme.colors.greys.lightest};
`;

export const Item = styled.div`
  flex-direction: column;
  flex-basis: ${(props) =>
    props.large ? `calc(${(100 / props.nbColumns) * 2}%)` : `calc(${100 / props.nbColumns}%)`};
  margin-right: 16px;
  font: ${(props) => props.theme.fonts?.textBigHeight16};
`;

export const NameContent = styled.div`
  margin-left: 8px;
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const BoxImg = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: url(${(props) => props.img}) no-repeat center;
  background-size: contain;
  cursor: ${(props) => (props.pointerCursor ? 'pointer' : 'default')};
`;

export const RowTotalContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
