import _ from 'lodash';

export const canPageBeRenderedInOffline = (match, isUserOffline, data) => {
  if (!isUserOffline) {
    return true;
  }

  if (match.path === '/production/operations' && !_.isEmpty(data)) {
    return true;
  }

  if (match.path === '/production/stocks' && !_.isEmpty(data)) {
    return true;
  }

  if (match.path === '/production/losses' && !_.isEmpty(data)) {
    return true;
  }

  return false;
};
