import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 160px;
  & .medium-modal-form-category-block {
    background: ${(props) => props.theme.colors.greys.lightest};
    padding: 24px;
    width: ${(props) => (props.customWidth ? '816px' : '100%')};
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
  }

  & .medium-modal-form-category-header {
    display: flex;

    color: ${(props) => props.theme.colors.greys.darkest};
    font: ${(props) => props.theme?.fonts?.h2};
  }

  & .medium-modal-form-category-body-row {
    width: 100%;
    display: flex;
    margin-top: 24px;
    column-gap: 24px;
  }
`;

// For custom Dropdown rendering
export const DropdownItem = styled.div`
  width: 232px;
  height: 64px;

  display: flex;
  align-items: center;

  gap: 8px;
`;

export const DropdownItemMainValue = styled.div`
  font: ${(props) => props.theme.fonts.textMiddleNormal};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DropdownItemSecondaryValue = styled.div`
  font: ${(props) => props.theme.fonts.textSmallHeight16};
  color: ${(props) => props.theme.colors.greys.dark};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${(props) =>
    props.isDisabled &&
    css`
      color: ${props.theme.colors.greys.light};
    `};
`;

export const DropdownTitle = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  overflow: auto;
`;

export const DropdownIcon = styled.img`
  width: 40px;
  height: 40px;
`;
