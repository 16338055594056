import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  overflow: auto;
  position: relative;
`;

export const ContainerContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 64px;
`;

export const Section = styled.div`
  display: flex;
  min-width: 700px;
  margin: 24px 32px 0px 32px;
  justify-content: space-between;
  border-radius: ${(props) => props.theme.borders?.radius.weak};

  ${(props) =>
    props.sectionType === 'subpage' &&
    css`
      margin: ${props.nbSubPages > 1 ? '0px' : '24px'} 32px 0px 32px;
    `};
`;

export const NavigationBar = styled.div`
  width: 100%;
  height: 52px;

  display: flex;
  padding: 0px 30px;
  background: ${(props) => props.theme.colors?.greys.lightest};
  border-width: thin;
  align-items: center;
  box-shadow: ${(props) => props.theme.shadows?.short};
  border-bottom: 1px solid #e8e8e8;
  border-radius: ${(props) => props.theme.borders?.radius.strong};

  font: ${(props) => props.theme.fonts?.textBig};

  margin-bottom: 24px;
`;

export const NavigationPage = styled.div`
  height: 100%;
  color: ${(props) => props.theme.colors?.greys.dark};

  font: ${(props) => props.theme.fonts?.textBig};

  display: flex;
  margin-right: 40px;
  align-items: center;

  &.selected {
    color: ${(props) => props.theme.colors?.greys.darkest};
    padding-top: 3px;
    border-bottom: ${(props) => `solid ${props.theme.colors?.brand.primary}`};
  }

  ${(props) =>
    props.isDisabled
      ? css`
          color: ${props.theme.colors.greys.light};
          &:hover {
            cursor: not-allowed;
          }
        `
      : css`
          &:hover {
            color: ${(props) => props.theme.colors?.greys.darkest};
            cursor: pointer;
          }
        `}
`;
