import { isEmpty, sortBy } from 'lodash';
import moment from 'moment-timezone';

import { convertQuantityToPackagingById } from '@commons/utils/packagings';
import { getUserTimezone } from '@commons/utils/date';

import { getTranslatedOrderAnomalies } from '@orders/OrderList/components/OrderForm/constants/anomalies';

export default (productOrders) => {
  const userTimezone = getUserTimezone();

  const orderAnomalies = getTranslatedOrderAnomalies();

  const formattedProductOrders = productOrders
    // Order with anomalies have necessarily received product orders
    // But for some very old orders, that can be not the case
    .filter(({ received }) => !isEmpty(received))
    .map((productOrder) => {
      const order = productOrder.order;

      const formattedTotPackagingWeight =
        productOrder.supplierProduct.packagingWeight * productOrder.ordered.quantity || '';

      order.orderDate = moment.tz(productOrder.order.orderDate, userTimezone).format('L');
      order.deliveryDate = moment
        .tz(productOrder.order.startOrderDate, productOrder.store.timezone)
        .format('L');

      const formattedReceived = isEmpty(productOrder.received)
        ? productOrder.ordered
        : productOrder.received;

      const formattedInvoiced = isEmpty(productOrder.invoiced)
        ? productOrder.ordered
        : productOrder.invoiced;

      const isAddedReference = isEmpty(productOrder.ordered);

      // Since productOrder.invoiced.packagingId is always the packaging used in the PO for reception,
      // We need to get the packaging used in invoice from the supplier product packagings.
      const packagingUsedInInvoice = productOrder.supplierProduct.packagings.find(
        ({ isUsedInInvoice }) => isUsedInInvoice,
      );

      const receivedInInvoicedPackaging = convertQuantityToPackagingById(
        formattedReceived.quantity,
        productOrder.received.packagingId,
        packagingUsedInInvoice.id,
        productOrder.supplierProduct.packagings,
      );

      const receivedInOrderPackaging = convertQuantityToPackagingById(
        formattedReceived.quantity,
        productOrder.received.packagingId,
        isAddedReference ? productOrder.received.packagingId : productOrder.ordered.packagingId,
        productOrder.supplierProduct.packagings,
      );

      const unreceivedTot = productOrder.invoiced.quantity - receivedInInvoicedPackaging;

      const credit = isAddedReference
        ? null
        : receivedInOrderPackaging * productOrder.ordered.price -
          productOrder.invoiced.quantity * productOrder.invoiced.price;

      return {
        ...productOrder,
        order,
        received: formattedReceived,
        invoiced: formattedInvoiced,
        anomaly: orderAnomalies[productOrder.received.anomaly],
        unreceivedTot,
        credit,
        totPackagingWeight: formattedTotPackagingWeight,
      };
    });

  return sortBy(formattedProductOrders, ['orderDate']).reverse();
};
