import { get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getUserTimezone } from '@commons/utils/date';
import { InpulseLabel } from '@commons/DeepsightComponents';
import DisplayNumber from '@commons/DisplayNumber';

import { Text } from '../styledComponents';
import ORDER_STATUS from '../components/OrderForm/constants/status';

import {
  preparationStatusColorCodes,
  preparationStatusLegend,
  statusColorCodes,
  statusLegend,
} from './constants';

export const getValue = (id, array, field) => {
  let res = array.find((elem) => elem.id === id);

  if (res) {
    return res[field];
  }
};

export const renderLabel = (label, key, colorCodes) => (
  <div
    className="supplier-label-render"
    style={{ minWidth: '115px', marginLeft: '2px', padding: '5px 0px' }}
  >
    <InpulseLabel
      color={getValue(key, colorCodes, 'inpulseColor')}
      text={label}
      type={'tertiary'}
    />
  </div>
);

const _getStatusColumn = ({
  propertyKey,
  filterType,
  legends,
  colorCodes,
  filterStatusesToSkip = [],
}) => ({
  id: 'status',
  name: i18next.t('ORDERS.ORDERS.LIST_LABEL_STATUS'),
  propertyKey,
  filterType,
  // Used to render advanced status filter list
  list: Object.keys(legends).reduce((result, key) => {
    if (filterStatusesToSkip.includes(key)) {
      return result;
    }

    result.push({
      id: key,
      status: key,
      itemValue: key,
      name: i18next.t(legends[key].name),
      renderValue: () => (
        <div className="supplier-label-render">
          <InpulseLabel
            color={getValue(key, colorCodes, 'inpulseColor')}
            style={{
              padding: '0',
              border: 'none',
              backgroundColor: 'transparent',
            }}
            text={i18next.t(legends[key].name)}
            type={'tertiary'}
          />
        </div>
      ),
    });

    return result;
  }, []),
  // Used to render status column et in list view
  render: (status, item) =>
    renderLabel(
      Object.keys(legends).includes(status)
        ? i18next.t(legends[status].name)
        : i18next.t('ORDERS.ORDERS.LIST_LABEL_STATUS_NOT_DEFINED'),
      item[propertyKey],
      colorCodes,
    ),
});

export const getOrderColumns = (storeName) => [
  {
    id: 'storeName',
    name: getClientStoreNameTranslation(storeName),
    propertyKey: 'storeName',
    render: (it) => <Text highlighted>{it}</Text>,
  },
  {
    id: 'supplier',
    name: i18next.t('GENERAL.SUPPLIER'),
    propertyKey: 'supplierName',
    render: (it) => <Text>{it}</Text>,
  },
  {
    id: 'startOrderDate',
    filterType: 'date',
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_DELIVERY_DATE'),
    propertyKey: 'startOrderDate',
    render: (it, item) => <Text bold>{moment.tz(it, item.storeTimezone).format('L')}</Text>,
  },
  {
    id: 'orderDate',
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_CREATION_DATE'),
    propertyKey: 'orderDate',
    filterType: 'date',
    render: (it) => <Text>{moment.tz(it, getUserTimezone()).format('L')}</Text>,
  },
  _getStatusColumn({
    propertyKey: 'status',
    filterType: 'string',
    legends: statusLegend,
    colorCodes: statusColorCodes,
    filterStatusesToSkip: [ORDER_STATUS.SENT_EDITABLE, ORDER_STATUS.BILLED, ORDER_STATUS.PAID].map(
      String,
    ),
  }),
  {
    id: 'totalPrice',
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_TOTAL_EX_TAX'),
    propertyKey: 'totalPrice',
    filterType: 'numeric',
    render: (it, row) => (
      <DisplayNumber
        currency={get(row, 'lnkSupplierOrderrel.lnkCurrencySupplierrel')}
        number={it}
        displayCurrencyCode
      />
    ),
  },
];

export const getCentralKitchenPreparationColumns = (storeName) => [
  {
    id: 'supplier',
    name: i18next.t('GENERAL.CENTRAL'),
    propertyKey: 'supplierName',
    render: (it) => <Text>{it}</Text>,
  },
  {
    id: 'storeName',
    name: getClientStoreNameTranslation(storeName),
    propertyKey: 'storeName',
    render: (it) => <Text highlighted>{it}</Text>,
  },
  {
    id: 'startOrderDate',
    filterType: 'date',
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_DELIVERY_DATE'),
    propertyKey: 'startOrderDate',
    render: (it, item) => <Text bold>{moment.tz(it, item.storeTimezone).format('L')}</Text>,
  },
  _getStatusColumn({
    propertyKey: 'preparationStatus',
    filterType: 'string',
    legends: preparationStatusLegend,
    colorCodes: preparationStatusColorCodes,
  }),
  {
    id: 'totalPrice',
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_TOTAL_EX_TAX'),
    propertyKey: 'totalPrice',
    filterType: 'numeric',
    render: (it, row) => (
      <DisplayNumber
        currency={get(row, 'lnkSupplierOrderrel.lnkCurrencySupplierrel')}
        number={it}
        displayCurrencyCode
      />
    ),
  },
];
