import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;

  font-family: proximanovaregular;

  top: 0;
  left: 0;
  bottom: 0;

  position: absolute;

  display: flex;
  flex-direction: column;
`;

export const ContainerTable = styled.div`
  flex-grow: 1;

  /* for Firefox */
  min-height: 0;

  display: flex;
  flex-direction: column;

  margin: 0px 20px 32px 20px;

  min-width: ${(props) => `${props.minWidth}px` || '800px'};

  border-radius: ${(props) => props.theme.borderRadius};
`;

export const DropdownContainer = styled.div`
  left: 0px;
  bottom: 25px;
  position: absolute;

  min-width: 110px;

  border-radius: ${(props) =>
    props.theme.pagination?.dropdown?.list?.borderRadius};
  box-shadow: ${(props) => props.theme.pagination?.dropdown?.list?.boxShadow};
  background: ${(props) =>
    props.theme.pagination?.dropdown?.list?.backgroundColor};

  > div {
    height: 25px;
    width: 100%;

    padding: 5px 10px;

    cursor: pointer;

    font: ${(props) => props.theme.pagination?.dropdown?.default?.font};
    color: ${(props) => props.theme.pagination?.dropdown?.default?.color};

    display: flex;
    align-items: center;

    &:first-child {
      border-top-left-radius: ${(props) =>
        props.theme.pagination?.dropdown?.list?.borderRadius};
      border-top-right-radius: ${(props) =>
        props.theme.pagination?.dropdown?.list?.borderRadius};
    }

    &:last-child {
      border-bottom-left-radius: ${(props) =>
        props.theme.pagination?.dropdown?.list?.borderRadius};
      border-bottom-right-radius: ${(props) =>
        props.theme.pagination?.dropdown?.list?.borderRadius};
    }

    &:hover {
      font: ${(props) => props.theme.pagination?.dropdown?.selected?.font};
      color: ${(props) => props.theme.pagination?.dropdown?.selected?.color};
    }
  }
`;

export const PaginationBar = styled.div`
  bottom: 0px;
  position: fixed;
  z-index: 10;

  height: 32px;
  width: calc(100% - 64px);

  padding: 0px 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font: ${(props) => props.theme.pagination?.font};
  color: ${(props) => props.theme.pagination?.color};
  box-shadow: ${(props) => props.theme.pagination?.boxShadow};
  background-color: ${(props) => props.theme.pagination?.backgroundColor};
`;

export const PaginationInfo = styled.div`
  width: fit-content;

  display: flex;
  align-items: center;

  box-sizing: border-box;

  height: 24px;

  border: ${(props) => props.theme.pagination?.switch?.border};
  box-shadow: ${(props) => props.theme.pagination?.switch?.boxShadow};
  border-radius: ${(props) => props.theme.pagination?.switch?.borderRadius};
  background-color: ${(props) =>
    props.theme.pagination?.switch?.backgroundColor};

  ${(props) =>
    props.hidden &&
    css`
      flex: 1;
      visibility: hidden;
    `};
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3px 10px;

  font: ${(props) => props.theme.pagination?.font};
  color: ${(props) => props.theme.pagination?.color};

  &:first-child {
    border-right: 1px solid rgba(196, 197, 199, 0.239893);
  }

  &:last-child {
    border-left: 1px solid rgba(196, 197, 199, 0.239893);
  }

  ${(props) =>
    props.interactive &&
    css`
      &:hover:not(.disabled) {
        cursor: pointer;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      background: ${props.theme.pagination?.dropdown
        ?.disabledBackgroundColor} !important;
    `}

  > img {
    width: 12px;
    height: 8px;

    &.rotated {
      transform: rotate(180deg);
    }
  }

  &.disabled {
    > img {
      opacity: 0.3;
    }
  }
`;

export const RowSeparator = styled.div`
  height: 1px;
  margin: 0 15px;
  position: absolute;
  width: calc(100% - 30px);
  background-color: #e6e6e6;
`;

export const Row = styled.div`
  width: 100%;

  background-color: white;

  position: relative;

  display: flex;
  align-items: flex-start;

  &:not(:first-child) {
    border-top: ${(props) =>
      props.theme.pagination ? 'none' : '1px solid rgba(232, 232, 232, 0.6)'};
  }

  ${(props) =>
    props.header &&
    css`
      background: transparent;
      border-top-left-radius: ${props.theme.borderRadius};
      border-top-right-radius: ${props.theme.borderRadius};
    `}
`;

export const Action = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
`;

export const Item = styled.div`
  min-width: 0; // to allow text-overflow: ellipsis in child

  flex: 1;

  &:first-child {
    flex: 2;
  }

  display: flex;
  align-items: center;

  min-height: 55px;

  padding: 10px 15px;

  &:not(:last-child) {
    border-right: ${(props) =>
      props.theme.pagination ? 'none' : '1px solid rgba(232, 232, 232, 0.6)'};
  }
`;

export const LoadingRowItem = styled.div`
  width: 100%;
  height: 20px;

  border-radius: 3px;
  background: rgba(168, 177, 196, 0.2);

  opacity: ${(props) => (props.opacity ? props.opacity : 1)};
`;

export const Text = styled.div`
  overflow: hidden;
  white-space: pre-line;
  text-overflow: ellipsis;

  ${(props) => {
    if (props.small) {
      return css`
        font: ${props.theme.fonts?.small};
        font-weight: ${props.bold ? '600' : '500'};
      `;
    }

    if (props.micro) {
      return css`
        font: ${props.theme.fonts?.micro};
        font-weight: ${props.bold ? '600' : '500'};
      `;
    }

    return css`
      font: ${props.theme.fonts?.normal};
      font-weight: ${props.bold ? '600' : '500'};
    `;
  }}

  color: ${(props) => {
    if (props.darkBlue) {
      return props.theme.colors?.selected;
    }

    if (props.blue) {
      return props.theme.colors?.highlighted;
    }

    if (props.grey) {
      return props.theme.colors?.secondary;
    }

    return props.theme.colors?.primary;
  }};

  ${(props) =>
    props.blue &&
    css`
      > div > div {
        color: ${props.theme.colors?.highlighted} !important;
        font: ${props.theme.fonts?.bold};
      }
    `}

  &:hover {
    ${(props) =>
      props.interactive &&
      css`
        color: ${props.theme.colors?.selected};
        cursor: pointer;
      `}
  }
`;

export const Icon = styled.img`
  margin-left: ${(props) => props.marginLeft || 0};

  &:hover {
    ${(props) =>
      props.interactive &&
      css`
        cursor: pointer;
      `}
  }

  ${(props) =>
    props.rotated &&
    css`
      transform: rotate(180deg);
    `};
`;

export const CloseFilter = styled.div`
  width: fit-content;

  font-size: 14px;

  color: red;
  text-decoration: underline;

  margin: 10px 20px;

  cursor: pointer;
`;

export const Headers = styled.div`
  width: 100%;

  z-index: 5;
`;

export const ContainerHeaders = styled.div`
  border-top-left-radius: ${(props) => props.theme.borderRadius};
  border-top-right-radius: ${(props) => props.theme.borderRadius};

  background-color: white;

  box-shadow: 0px -1px 3px rgb(0 0 0 / 8%);

  border-left: 1px solid rgba(232, 232, 232, 0.6);
  border-right: 1px solid rgba(232, 232, 232, 0.6);

  ${(props) =>
    props.$scrolling &&
    css`
      border-bottom: none;
      box-shadow: 0px -1px 3px rgb(0 0 0 / 8%), 0 6px 3px -3px rgb(0 0 0 / 10%);

      transition: box-shadow 0.3s ease-in-out;
    `}

  > div:first-child {
    border-top-left-radius: ${(props) => props.theme.borderRadius};
    border-top-right-radius: ${(props) => props.theme.borderRadius};
  }
`;

export const Content = styled.div`
  width: 100%;

  overflow: auto;

  /* for Firefox */
  min-height: 0;

  border-bottom-left-radius: ${(props) => props.theme.borderRadius};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius};

  border-left: 1px solid rgba(232, 232, 232, 0.6);
  border-right: 1px solid rgba(232, 232, 232, 0.6);
  border-bottom: 1px solid rgba(232, 232, 232, 0.6);
  box-shadow: 0px 2px 3px rgb(0 0 0 / 8%);
`;

export default {
  Box,
  Row,
  Item,
  Text,
  Icon,
  Action,
  Container,
  PaginationBar,
  ContainerTable,
  ContainerHeaders,
  PaginationInfo,
  DropdownContainer,
  LoadingRowItem,
  RowSeparator,
  CloseFilter,
};
