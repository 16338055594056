import i18next from 'i18next';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_DOWNLOAD_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';

export const getDownloadInventorySheetWarningModal = (props) => {
  const {
    generatePDFBlob,
    setInfoModalDownloadInventorySheet,
    infoModalDownloadInventorySheet,
    _handleMixPanelEvent,
  } = props;
  const params = getConfirmationModal({
    title: i18next.t('STOCKS.STOCKS.FORM_DOWNLOAD_INVENTORY_PDF'),
    icon: '/images/inpulse/file-download-white-small.svg',
    content: i18next.t('STOCKS.STOCKS.INVENTORY_SHEET_CONTENT_MODAL'),
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_DOWNLOAD_BUTTON(),
        label: i18next.t('STOCKS.STOCKS.INVENTORY_SHEET_BUTTON_MODAL'),
        handleClick: () => {
          generatePDFBlob(infoModalDownloadInventorySheet);
          _handleMixPanelEvent();
          setInfoModalDownloadInventorySheet(null);
        },
      },
    ],
  });

  return params;
};
