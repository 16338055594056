export const CLIENT_STATUS = {
  TEST: 'Test',
  RUN: 'Run',
  ONBOARDING: 'Onboarding',
  STOPPED: 'Stopped',
  SUSPENDED: 'Suspended',
};

export const CLIENT_STATUS_DROPDOWN = [
  { id: 0, name: CLIENT_STATUS.TEST.toLocaleLowerCase(), value: CLIENT_STATUS.TEST },
  { id: 1, name: CLIENT_STATUS.RUN.toLocaleLowerCase(), value: CLIENT_STATUS.RUN },
  { id: 2, name: CLIENT_STATUS.ONBOARDING.toLocaleLowerCase(), value: CLIENT_STATUS.ONBOARDING },
  { id: 3, name: CLIENT_STATUS.STOPPED.toLocaleLowerCase(), value: CLIENT_STATUS.STOPPED },
  { id: 4, name: CLIENT_STATUS.SUSPENDED.toLocaleLowerCase(), value: CLIENT_STATUS.SUSPENDED },
];

export const CLIENT_STORE_NAMES = {
  BOUTIQUE: 'Boutique',
  BOULANGERIE: 'Boulangerie',
  ETABLISSEMENT: 'Etablissement',
  LABORATOIRE: 'Laboratoire',
  SANDWICH_NERA: 'Sandwich Nera',
  RESTAURANT: 'Restaurant',
  MAGASIN: 'Magasin',
  KITCHEN: 'Kitchen',
  CORNER: 'Corner',
};

export const CLIENT_STORE_NAME_DROPDOWN = [
  { id: 0, value: CLIENT_STORE_NAMES.BOUTIQUE },
  { id: 1, value: CLIENT_STORE_NAMES.BOULANGERIE },
  { id: 2, value: CLIENT_STORE_NAMES.ETABLISSEMENT },
  { id: 3, value: CLIENT_STORE_NAMES.LABORATOIRE },
  { id: 4, value: CLIENT_STORE_NAMES.SANDWICH_NERA },
  { id: 5, value: CLIENT_STORE_NAMES.RESTAURANT },
  { id: 6, value: CLIENT_STORE_NAMES.MAGASIN },
  { id: 7, value: CLIENT_STORE_NAMES.KITCHEN },
  { id: 8, value: CLIENT_STORE_NAMES.CORNER },
];
