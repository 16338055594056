import i18next from 'i18next';
import React from 'react';
import ReactFlagsSelect from '@deepsight/react-flags-select';

import { Container } from './styledComponent';

const DropdownCountry = (props) => {
  const { selected, handleSelect, countriesToShow, fullWidth } = props;

  const onSelectionChange = (countryCode) => {
    handleSelect(countryCode);
  };

  return (
    <Container fullWidth={fullWidth}>
      <ReactFlagsSelect
        className="dropdown-country"
        countries={countriesToShow}
        disabled={props.isDisabled}
        placeholder={i18next.t('GENERAL.CHOOSE_PLACEHOLDER')}
        searchPlaceholder={i18next.t('GENERAL.SEARCH')}
        selected={selected}
        searchable
        onSelect={(countryCode) => onSelectionChange(countryCode)}
      />
    </Container>
  );
};

export default DropdownCountry;
