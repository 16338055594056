import i18next from 'i18next';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import DisplayNumber from '@commons/DisplayNumber';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { planningType as planningTypeEnum } from '@productions/constants/planning';

import {
  StatsInfoBox,
  StatsInfoIcon,
  StatsInfoBoxContainer,
  StatsInfoBoxTitle,
  StatsInfoBoxContent,
  StatsInfoBoxRow,
} from './styledComponents';

function RenderInfoStats(props) {
  const { planningType, totalByBrands } = props;

  const [isOpened, setIsOpened] = useState(false);

  const triggerTooltip = () => {
    setIsOpened(!isOpened);
  };

  const byPassClickOutside = (event) => {
    event.stopPropagation();
  };

  RenderInfoStats.handleClickOutside = () => {
    setIsOpened(false);
  };

  return (
    <StatsInfoBox onClick={triggerTooltip}>
      <StatsInfoIcon src={`/images/inpulse/info-black-small.svg`} white={!isOpened} />
      {isOpened && (
        <StatsInfoBoxContainer onClick={byPassClickOutside}>
          <StatsInfoBoxTitle>
            <Text font={ENUM_FONTS.TEXT_BIG_HEIGHT_24_BOLD}>
              {i18next.t('PRODUCTION.PRODUCTION.STATS_INFO_TITLE')}
            </Text>
            <img src={'/images/inpulse/close-black.svg'} onClick={triggerTooltip} />
          </StatsInfoBoxTitle>
          <StatsInfoBoxContent>
            <StatsInfoBoxRow>
              <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14}>
                {i18next.t('GENERAL.BRAND')}
              </Text>
              {planningType === planningTypeEnum.PRODUCTION && (
                <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14}>
                  {i18next.t('PRODUCTION.PRODUCTION.HEADER_TO_PRODUCE')}
                </Text>
              )}
              {planningType === planningTypeEnum.PRODUCTION && (
                <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14}>
                  {i18next.t('PRODUCTION.PRODUCTION.HEADER_PRODUCED')}
                </Text>
              )}
              {planningType === planningTypeEnum.PRODUCTION && (
                <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14}>
                  {i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCTION_RATIO')}
                </Text>
              )}
              {[planningTypeEnum.LOSSES, planningTypeEnum.STOCKS].includes(planningType) && (
                <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14}>
                  {i18next.t('PRODUCTION.LOSSES.HEADER_TOTAL')}
                </Text>
              )}
            </StatsInfoBoxRow>
            {totalByBrands.map(
              ({ brand, total, totalTurnoverProduced, totalTurnoverToProduce }, index) => (
                <StatsInfoBoxRow key={index}>
                  <Text font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14}>
                    {brand ? brand.name : i18next.t('GENERAL.NONE_VALUE')}
                  </Text>
                  {planningType === planningTypeEnum.PRODUCTION && (
                    <DisplayNumber
                      displayCurrencyCode={true}
                      font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14_WEIGHT_700_BOLD}
                      number={totalTurnoverToProduce}
                    />
                  )}
                  {planningType === planningTypeEnum.PRODUCTION && (
                    <DisplayNumber
                      displayCurrencyCode={true}
                      font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14_WEIGHT_700_BOLD}
                      number={totalTurnoverProduced}
                    />
                  )}
                  {planningType === planningTypeEnum.PRODUCTION && (
                    <Text
                      color={ENUM_COLORS.DARKEST}
                      font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14_WEIGHT_700_BOLD}
                    >
                      {totalTurnoverProduced && totalTurnoverToProduce
                        ? Math.round((totalTurnoverProduced / totalTurnoverToProduce) * 100)
                        : 0}
                      %
                    </Text>
                  )}
                  {[planningTypeEnum.LOSSES, planningTypeEnum.STOCKS].includes(planningType) && (
                    <DisplayNumber
                      displayCurrencyCode={true}
                      font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14_WEIGHT_700_BOLD}
                      number={total}
                    />
                  )}
                </StatsInfoBoxRow>
              ),
            )}
          </StatsInfoBoxContent>
        </StatsInfoBoxContainer>
      )}
    </StatsInfoBox>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => RenderInfoStats.handleClickOutside,
};

RenderInfoStats.propTypes = {
  planningType: PropTypes.string.isRequired,
  totalByBrands: PropTypes.arrayOf(PropTypes.object),
};

RenderInfoStats.defaultProps = { totalByBrands: [] };

export default onClickOutside(RenderInfoStats, clickOutsideConfig);
