import { APIcaller } from './APIcaller';

const getProductionPlanning = (storeId, startDate, endDate) =>
  APIcaller.apiPost('/production/central-kitchen/production-planning', {
    storeId,
    startDate,
    endDate,
  });

const getCentralKitchenRecipesOfStore = (storeId, startDate, endDate) =>
  APIcaller.apiPost(`/production/central-kitchen/recipes`, {
    storeId,
    startDate,
    endDate,
  });

const getCentralKitchenRecipesConsumptions = (
  productionStoreId,
  consumptionStoreIds,
  startDate,
  endDate,
  entityIds,
) =>
  APIcaller.apiPost(`/production/central-kitchen/consumption-forecast`, {
    productionStoreId,
    consumptionStoreIds,
    startDate,
    endDate,
    entityIds,
  });

const upsertCentralKitchenProductionPlanning = (
  storeId,
  entityId,
  productionDate,
  quantity,
  userId,
) =>
  APIcaller.apiPost(`/production/central-kitchen/production-planning/${storeId}`, {
    entityId,
    productionDate,
    quantity,
    userId,
  });

const upsertCentralKitchenProductionPlanningByBatch = (productionPlannings) =>
  APIcaller.apiPost(`/production/central-kitchen/batch/production-planning`, {
    productionPlannings,
  });

export default {
  getProductionPlanning,
  getCentralKitchenRecipesOfStore,
  getCentralKitchenRecipesConsumptions,
  upsertCentralKitchenProductionPlanning,
  upsertCentralKitchenProductionPlanningByBatch,
};
