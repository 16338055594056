import { brand as brandService } from '@services/brand';
import { product as productService } from '@services/product';
import centralService from '@services/central';

import sortSupplierProductProperties from '@admin/utils/sortSupplierProductProperties';

const DEFAULT_NONE = 'Aucune';

const _manageCategoryAucune = (categories) => {
  const updatedCategories = [...categories];
  const checkCategoryAucune = updatedCategories.some(
    (category) => category.name.toLowerCase() === DEFAULT_NONE,
  );
  if (!checkCategoryAucune) {
    updatedCategories.push({ id: -1, name: DEFAULT_NONE });
  }

  return updatedCategories;
};

/**********************/
/* Fetch Data Methods */
/**********************/

/**
 * Handle the fetch of product categories for the given clientId associated to the user
 *
 * @param {String} clientId                 - The client id on which retrieve the products categories
 * @param {Function} setProductsCategories  - Method used to set the local state of the productsCategories variable
 *
 * @returns {Object} - Object of two arrays corresponding to categories and subCategories of this client
 */
export const fetchProductsCategoriesOfClient = async (
  clientId,
  setProductsCategories,
  setProductsSubCategories,
  isKitchen = false,
) =>
  (isKitchen
    ? centralService.getCentralKitchenProductCategoriesByClientId(clientId)
    : productService.getProductsCategories(clientId)
  ).then((result) => {
    const formattedCategories = result.categories.map((category, index) => ({
      id: index,
      name: category,
    }));

    const formattedSubCategories = result.subCategories.map((subCategory, index) => ({
      id: index,
      name: subCategory,
    }));

    const productsCategories = sortSupplierProductProperties(
      _manageCategoryAucune(formattedCategories).map(({ name }) => name),
      true,
    );

    setProductsCategories(productsCategories);

    const productsSubCategories = sortSupplierProductProperties(
      _manageCategoryAucune(formattedSubCategories).map(({ name }) => name),
      true,
    );

    setProductsSubCategories(productsSubCategories);

    return { categories: productsCategories, subCategories: productsSubCategories };
  });

/**
 * Render the product form section with the different inputs that should be displayed
 *
 * @param {String} clientId     - The client id on which retrieve the brands
 * @param {Function} setBrands  - Method used to set the local state of the brands variable
 */
export function fetchBrandsOfClient(clientId, setBrands) {
  return brandService.getBrandsOfClient(clientId).then((result) => setBrands(result));
}

export default {
  fetchProductsCategoriesOfClient,
  fetchBrandsOfClient,
};
