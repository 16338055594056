import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { openGenericModal } from '@actions/modal';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import normalizeStringValue from '@commons/utils/normalizeStringValue';

import { getClientInfo } from '@selectors/client';

import { getNewCategorySubcategoryModalParams } from '@admin/products/products/components/CreateProductModal/utils/modalConfigurations';
import sortSupplierProductProperties from '@admin/utils/sortSupplierProductProperties';

import { Container, FormContainer, Section, SectionInfo, Title } from './styledComponents';
import { fetchIngredientsCategoriesOfClient, fetchAllergensList } from './common/services';
import {
  IngredientNameInput,
  IngredientCategoryInput,
  IngredientUnityInput,
  IngredientCostInput,
  IngredientAllergensInput,
  IngredientIsStrategicInput,
  handleOnChangeIngredient,
} from './common/inputs';
/**
 * Get the custom list of inputs to allow in the form according the client settings
 *
 * @returns {Inputs[]} The list of custom inputs to display
 */
export function getCustomListInputs() {
  return [
    [IngredientNameInput, IngredientCategoryInput, IngredientAllergensInput],
    [IngredientUnityInput, IngredientCostInput, IngredientIsStrategicInput],
  ];
}

/**
 * Render the ingredient form section with the different inputs that should be displayed
 *
 * @param {Props} props                         - The props sent to method
 * @param {Ingredient} props.Ingredient         - The ingredient's data that should be displayed
 * @param {Function} props.onIngredientChange   - Method used to set the local state of the ingredient variable
 */
export function renderForm(props) {
  const { ingredient } = props;

  const ingredientFieldsFormBySection = getCustomListInputs();

  return (
    <div>
      {ingredientFieldsFormBySection.map((section, indexSection) => (
        <Section key={indexSection}>
          {section.map((item, indexItem) => {
            const ComponentName = item.component;
            const inputProps = item.props(props);

            return (
              <SectionInfo key={indexItem}>
                <ComponentName
                  {...inputProps}
                  value={ingredient[item.keyProperty] != null ? ingredient[item.keyProperty] : ''}
                />
              </SectionInfo>
            );
          })}
        </Section>
      ))}
    </div>
  );
}

/*************************/
/* Main Component Method */
/*************************/

export function IngredientInfoSection(props) {
  const {
    ingredient,
    onIngredientChange,
    client: { clientId },
    isReadOnly,
    isChildCreation,
    isCostInputDisabled,
    currency,
  } = props;

  const [ingredientsCategories, setIngredientsCategories] = useState([]);
  const [availableAllergens, setAvailableAllergens] = useState([]);

  const [openModalAddNewItemDropdown, setOpenModalAddNewItemDropdown] = useState(false);
  const [itemDropdownPropertyName, setItemDropdownPropertyName] = useState('');

  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const { t } = useTranslation();
  useEffect(() => {
    (async function loadData() {
      try {
        await fetchIngredientsCategoriesOfClient(clientId, setIngredientsCategories);

        await fetchAllergensList(setAvailableAllergens);
      } catch (err) {
        props.showMessage("Impossible de récupérer les informations liées à l'ingrédient", 'error');
      }
    })();
  }, []);

  useEffect(() => {
    if (openModalAddNewItemDropdown) {
      const modalCreateNewCategoryParams = getNewCategorySubcategoryModalParams(
        inputValue,
        errorMessage,
        closeCleanUp,
        handleInputChange,
        itemDropdownPropertyName,
        handleSaveNewItemDropdown,
      );

      props.openGenericModal(modalCreateNewCategoryParams);
    }
  }, [inputValue, openModalAddNewItemDropdown]);

  const handleInputChange = (value) => {
    setInputValue(value);

    if (!value) {
      setErrorMessage(i18next.t('GENERAL.REQUIRED_FILED_ERROR_MESSAGE'));

      return;
    }

    const alreadyExists = ingredientsCategories.some(
      ({ name }) => normalizeStringValue(name) === normalizeStringValue(value),
    );

    if (alreadyExists) {
      setErrorMessage(i18next.t('GENERAL.MODAL_CATEGORY_NAME_ALREADY_USED'));

      return;
    }

    setErrorMessage(null);
  };

  const closeCleanUp = () => {
    setErrorMessage(null);
    setInputValue('');

    setOpenModalAddNewItemDropdown(false);
  };

  const handleSaveNewItemDropdown = () => {
    const updatedField = cloneDeep(ingredientsCategories);

    updatedField.push({
      id: Math.random(),
      name: inputValue.trim(),
    });

    const sortedSupplierProductProperties = sortSupplierProductProperties(
      updatedField.map((field) => field.name),
      true,
    );

    sortedSupplierProductProperties.forEach((properties) => (properties.value = properties.name));

    setIngredientsCategories(sortedSupplierProductProperties);

    handleOnChangeIngredient(
      ingredient,
      onIngredientChange,
      { target: { value: inputValue.trim() } },
      itemDropdownPropertyName,
    );

    setOpenModalAddNewItemDropdown(false);

    setInputValue('');
    setErrorMessage(null);
  };

  return (
    <Container>
      <Title>{t('ADMIN.INGREDIENTS.INFO_TITLE')}</Title>
      <FormContainer>
        {renderForm({
          ingredient,
          onIngredientChange,
          ingredientsCategories,
          availableAllergens,
          isChildCreation,
          setOpenModalAddNewItemDropdown,
          setItemDropdownPropertyName,
          isReadOnly,
          isCostInputDisabled,
          currency,
        })}
      </FormContainer>
    </Container>
  );
}

IngredientInfoSection.propTypes = {
  ingredient: PropTypes.object,
  onIngredientChange: PropTypes.func,
  isChildCreation: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IngredientInfoSection);
