import styled, { css } from 'styled-components';

export const ContainerListComposition = styled.div`
  margin: 15px 0px 0px 0px;
`;

export const ListHeader = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: ${(props) => (props.theme.borders ? undefined : '1px solid #a8b1c4')};
`;

export const ListHeaderSection = styled.div`
  flex: 2;
  color: ${(props) => props.theme.colors?.greys.dark || '#6b768c'};
  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts.textSmall};`
      : `font-size: 13px;
  line-height: 12px;
  font-style: normal;`}

  &.name,
  &.category {
    flex: 3;
  }

  &:first-child {
    padding-left: 25px;
  }

  &:last-child {
    flex: 1;
  }
`;

export const ListRow = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: ${(props) => (props.theme.borders ? undefined : '1px solid #a8b1c4')};
  border: ${(props) =>
    props.theme.borders ? `1px solid ${props.theme.colors.greys.light}` : undefined};

  border-radius: ${(props) => props.theme.borders?.radius.weak || undefined};
  margin-bottom: ${(props) => props.theme.borders?.radius.weak || undefined};
  &:before {
    top: 0;
    width: 1px;
    left: 0px;
    content: ' ';
    height: 100%;
    box-shadow: ${(props) => (props.theme.shadows ? undefined : '-15px 0 3px -17px inset')};
  }

  &:after {
    top: 0;
    width: 1px;
    right: 0px;
    content: ' ';
    height: 100%;
    box-shadow: ${(props) => (props.theme.shadows ? undefined : '-15px 0 3px -17px inset')};
  }

  &.error {
    border-bottom: solid red;
    border-width: thin;
  }
`;

export const CentralKitchenIcon = styled.div`
  width: 16px;
  height: 16px;
  background: ${(props) => props.theme.colors.highlightedBlue};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  left: -9px;
`;

export const ListRowText = styled.div`
  color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};

  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts.textSmall};`
      : `font-size: 14px;
  line-height: 16px;
  font-weight: normal;`}

  &.link {
    color: ${(props) => props.theme.colors?.greys.darkest || '#2176fa'};
    cursor: pointer;
  }

  ${(props) =>
    props.flex &&
    css`
      display: flex;
    `}
`;

export const ListRowSection = styled.div`
  flex: 2;

  &:first-child {
    padding-left: 25px;
    min-width: 0;
  }

  &:last-child {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  &.name,
  &.category {
    flex: 3;
  }

  p {
    margin: 0;
  }

  .actions {
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 4px;
    margin-right: 25px;
    background: ${(props) => props.theme.colors?.greys.light || '#ffffff'};
    align-items: center;
    justify-content: center;
    box-shadow: ${(props) => (props.theme.shadows ? undefined : '0px 2px 6px rgba(0, 0, 0, 0.1)')};

    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }

    img {
      width: 15px;
      height: 15px;
    }
  }
`;

export const ItemLinkText = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 95%;

  color: inherit;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
