import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Tooltip } from '../utils/styledLibraryComponents';
import ComponentLoader from '../DeepsightComponents/ComponentLoader';
import Text, { ENUM_COLORS, ENUM_FONTS } from '../Text';

import {
  Container,
  ContentContainer,
  LoadingContainer,
  StatsContainer,
  TitleContainer,
  TooltipIcon,
} from './styledComponents';
import Stats from './components/Stats';

const Metrics = (props) => {
  const { isLoading, isLoadingPreviousData, className, customStyle, title, tooltipText, data } =
    props;

  const renderTooltipIcon = () => <TooltipIcon src={'/images/inpulse/info-dmgrey-small.svg'} />;

  return (
    <Container className={className} style={customStyle}>
      {isLoading && (
        <LoadingContainer>
          <ComponentLoader height={16} width={16} />
        </LoadingContainer>
      )}
      {!isLoading && (
        <ContentContainer>
          <TitleContainer>
            {!isEmpty(tooltipText) && (
              <Tooltip
                displayBigger={true}
                renderTooltipTrigger={renderTooltipIcon}
                text={tooltipText}
              />
            )}
            <Text
              className={'title-text'}
              color={ENUM_COLORS.DARKER}
              font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {title}
            </Text>
          </TitleContainer>
          <StatsContainer>
            {isEmpty(data) && (
              <Text
                font={ENUM_FONTS.TITLE_HEIGHT_24_BOLD}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {'-'}
              </Text>
            )}
            {!isEmpty(data) &&
              data.map((statsData, index) => (
                <Stats data={statsData} isLoading={isLoadingPreviousData} key={`stats-${index}`} />
              ))}
          </StatsContainer>
        </ContentContainer>
      )}
    </Container>
  );
};

Metrics.propTypes = {
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  customStyle: PropTypes.object,
  title: PropTypes.string,
  tooltipText: PropTypes.string,
};

Metrics.defaultProps = {
  isLoading: false,
  className: '',
  customStyle: {},
  title: '',
  tooltipText: '',
};

export default Metrics;
