import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { makeXLS } from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { getClientInfo } from '@selectors/client';
import { getDisplaySupplierProductPackagingWeight } from '@selectors/featureProps';

const BATCH_ORDERS_TO_FETCH_AT_ONCE = 50;

export const ContentsBatchExportModal = (props) => {
  const {
    currency,
    closeModal,
    displaySupplierProductPackagingWeight,
    client: { storeName },
    params: { request, config },
  } = props;

  const [progress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(true);

  const [titleModal, setTitleModal] = useState(config.titleModal || '');

  const [totalCount, setTotalCount] = useState(request.totalCount);
  const [fetchCount, setFetchCount] = useState(0);
  const [fetchedData, setFetchedData] = useState([]);

  const isReady = () => {
    setTitleModal(i18next.t('GENERAL.EXPORT_SUCCESS'));

    setLoading(false);
  };

  const hasError = () => {
    setTitleModal(i18next.t('GENERAL.EXPORT_FAILURE'));

    setLoading(false);
  };

  const close = () => {
    setLoading(false);

    closeModal();
  };

  const updateProgress = () => {
    const value = (fetchCount / totalCount) * 100;

    setProgress(value);
  };

  const loadBySelectedIds = async () => {
    try {
      const data = await request.getByIds();

      setFetchedData(fetchedData.concat(data));

      setFetchCount(totalCount);
    } catch {
      hasError();
    }
  };

  const loadByBatch = async () => {
    try {
      const { data, count } = await request.getPaginated(fetchCount, BATCH_ORDERS_TO_FETCH_AT_ONCE);

      if (!totalCount) {
        setTotalCount(count);
      }

      setFetchedData(fetchedData.concat(data));

      setFetchCount(fetchCount + BATCH_ORDERS_TO_FETCH_AT_ONCE);
    } catch {
      hasError();
    }
  };

  useEffect(() => {
    // Fetch finished, generate excel File
    if (progress >= 100) {
      const files = config.excel(fetchedData, {
        storeName,
        isCentralExport: config.isCentralExport,
        displaySupplierProductPackagingWeight,
        currency,
      });

      for (const { filename, sheets } of files) {
        makeXLS(filename, sheets);
      }

      isReady();

      return;
    }

    // Fetch selection
    if (request.getByIds) {
      loadBySelectedIds();

      return;
    }

    // Fetch all with pagination
    loadByBatch();
  }, [progress]);

  // Update progress bar
  useEffect(() => {
    updateProgress();
  }, [fetchCount]);

  return (
    <ExportModalContent
      exitModal={close}
      isLoading={isLoading}
      progress={progress}
      titleModal={titleModal}
    />
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
  displaySupplierProductPackagingWeight: getDisplaySupplierProductPackagingWeight(
    state.baseReducer.userRights,
  ),
});

export default connect(mapStateToProps)(ContentsBatchExportModal);
