import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { Component } from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { isUserAllowedToAccessProduction } from '@commons/utils/features';

import { getClientInfo } from '@selectors/client.js';

import theme from '@theme';

import Utils from '../../utils/UtilsFunctions.js';

import DeepsightBarChart from './DeepsightFiveSameDaysChart';
import styled from 'styled-components';

export class FiveSameDaysChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataKeys: [],
      roundedBarsIds: [],
      salesPastSameWeekDayMean: null,
      eventsObject: {},
      weatherObject: {},
      focusedTypeButon: 'trend',
    };
  }

  getDynamicForecastOfDate(date) {
    const { storeTimezone } = this.props;
    var forecasts = JSON.parse(JSON.stringify(this.props.forecasts));

    var filteredArray = forecasts.filter(function (forecast) {
      return (
        moment
          .tz(date, storeTimezone)
          .isSame(moment.tz(forecast.timestamp, storeTimezone), 'day') &&
        forecast.type === 'dynamic'
      );
    });

    return filteredArray;
  }

  getEvents() {
    const { storeTimezone } = this.props;
    var eventsObject = {};
    var events = this.props.events;

    this.state.data.map((day) => {
      var filteredEvents = events.filter(function (event) {
        var currentDate = moment
          .tz(day.timestamp, storeTimezone)
          .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
        return (
          currentDate >=
            moment
              .tz(event.startDate, storeTimezone)
              .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY) &&
          currentDate <=
            moment
              .tz(event.endDate, storeTimezone)
              .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)
        );
      });

      let filteredFilteredEvents = Utils.sortEvents(filteredEvents);
      eventsObject[day.timestamp] = filteredFilteredEvents.slice(
        Math.max(filteredFilteredEvents.length - 3, 0),
      ); //Max 3 events
    });

    this.setState({
      eventsObject,
    });
  }

  getMeanGran = () => {
    const {
      client: { forecastProperty },
    } = this.props;

    if (this.props.activeService !== 'tot') {
      return this.props.activeService;
    }

    return forecastProperty;
  };

  getWeather() {
    const { storeTimezone } = this.props;

    var weatherObject = {};
    var weather = this.props.weather;

    this.state.data.map(function (day) {
      var filteredweather = weather.filter(function (weatherDay) {
        return (
          moment
            .tz(day.timestamp, storeTimezone)
            .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY) ===
          moment
            .tz(weatherDay.timestamp, storeTimezone)
            .startOf('day')
            .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)
        );
      });

      if (filteredweather[0]) {
        var temperature = parseInt(filteredweather[0].tMax, 10);
        var img = filteredweather[0].lnkWeathericonWeatherdayrel
          ? filteredweather[0].lnkWeathericonWeatherdayrel.img
          : '/images/icon-weather-cloud.png';
        weatherObject[day.timestamp] = {
          temperature: temperature,
          img: img,
        };
      } else {
        weatherObject[day.timestamp] = {};
      }
    });

    this.setState({
      weatherObject: weatherObject,
    });
  }

  updateSales(data) {
    const { storeTimezone } = this.props;

    let sales = this.props.data.sales;
    let meanArr = [];

    let newdata = data.map((day) => {
      if (
        moment
          .tz(day.timestamp, storeTimezone)
          .isBefore(moment.tz(this.props.activeDate, storeTimezone).startOf('day'))
      ) {
        day.tot = null;
        if (this.props.services && this.props.services.length) {
          day.midi = null;
          day.soir = null;
        }
      }
      return day;
    });

    const meanGran = this.getMeanGran();
    sales.forEach((sale) => {
      newdata.forEach((day, index) => {
        if (
          moment
            .tz(sale.timestamp, storeTimezone)
            .isSame(moment.tz(day.timestamp, storeTimezone), 'day')
        ) {
          meanArr.push(sale[meanGran] ? sale[meanGran] : 0);
          newdata[index].tot = sale[meanGran];
          if (this.props.services) {
            this.props.services.map((service) => {
              newdata[index][service.serviceName] = sale[service.serviceName] || 0;
            });
          }
        }
      });
    });

    let mean = 0;
    meanArr.forEach((day) => {
      mean += day;
    });
    mean = mean / meanArr.length || 0;
    mean = parseInt(mean, 10);

    this.setState({ data: newdata, mean });
  }

  updateForecast(data) {
    const {
      client: { forecastType },
      storeTimezone,
    } = this.props;

    let forecasts = this.props.data.forecasts;

    let currentDayForecast = forecasts.filter(
      (fc) =>
        moment
          .tz(fc.timestamp, storeTimezone)
          .isSame(moment.tz(this.props.activeDate, storeTimezone), 'day') && fc.type === 'dynamic',
    );

    if (
      moment
        .tz(this.props.activeDate, storeTimezone)
        .isSameOrAfter(moment.tz(storeTimezone).startOf('day'))
    ) {
      let newdata = data.map((day) => {
        if (
          moment
            .tz(day.timestamp, storeTimezone)
            .isSame(moment.tz(this.props.activeDate, storeTimezone), 'day')
        ) {
          day.fcTot = currentDayForecast.length > 0 ? currentDayForecast[0][forecastType] : null;
          if (this.props.services && this.props.services.length) {
            this.props.services.map((service) => {
              day['fc' + service.serviceName] =
                currentDayForecast.length > 0 ? currentDayForecast[0][service.serviceName] : null;
            });
          }
        }
        return day;
      });
      this.setState({ data: newdata });
    }
  }

  updatePastSales(data) {
    const { storeTimezone } = this.props;
    let sales = this.props.data.lastYearSales;
    let meanArr = [];

    let newdata = data.map((day) => {
      day.tot = null;
      if (this.props.services && this.props.services.length) {
        day.midi = null;
        day.soir = null;
      }
      return day;
    });

    const meanGran = this.getMeanGran();
    sales.forEach((sale) => {
      newdata.forEach((day, index) => {
        if (
          moment
            .tz(sale.timestamp, storeTimezone)
            .isSame(moment.tz(day.timestamp, storeTimezone), 'day')
        ) {
          meanArr.push(sale[meanGran]);
          newdata[index].tot = sale[meanGran];
          if (this.props.services) {
            this.props.services.map((service) => {
              newdata[index][service.serviceName] = sale[service.serviceName];
            });
          }
        }
      });
    });

    let mean = 0;
    meanArr.forEach((day) => {
      mean += day;
    });
    mean = mean / meanArr.length || 0;
    mean = parseInt(mean, 10);

    this.setState({ data: newdata, mean });
  }

  setDaysArray(type, date) {
    const { storeTimezone } = this.props;
    let data = [];

    const timezonedDate = moment.tz(date, storeTimezone);
    const timezonedNow = moment.tz(storeTimezone);

    if (type === 'past') {
      date = timezonedDate
        .clone()
        .subtract(1, 'year')
        .isoWeek(timezonedDate.clone().isoWeek())
        .isoWeekday(timezonedDate.clone().isoWeekday());
      let startDate = date.clone().subtract(2, 'week');
      let endDate = startDate.clone().add(5, 'week');

      while (startDate.isBefore(endDate)) {
        data.push({ timestamp: startDate.format() });
        startDate = startDate.add(1, 'week');
      }
    } else {
      let selectedDate = timezonedDate.clone();

      if (selectedDate.isAfter(timezonedNow.startOf('day'))) {
        while (selectedDate.isAfter(timezonedNow.startOf('day'))) {
          selectedDate = selectedDate.subtract(1, 'week');
        }
      }
      let startDate = moment.tz(selectedDate, storeTimezone).subtract(4, 'week');

      while (startDate.isSameOrBefore(selectedDate)) {
        data.push({ timestamp: startDate.format() });
        startDate = startDate.add(1, 'week');
      }

      if (timezonedDate.clone().isSameOrAfter(timezonedNow.startOf('day'))) {
        data.push({ timestamp: timezonedDate.clone().format() });
      }
    }

    this.setState({ data });
    return data;
  }

  generateDataKeys() {
    const gran = this.props.activeService;

    let dataKeys = [];
    let roundedBarsIds = [];

    if (this.props.services && this.props.services.length > 0) {
      let services = [];

      if (gran === 'tot') {
        services = JSON.parse(JSON.stringify(this.props.services));
      } else {
        services = this.props.services.filter((service) => service.serviceName === gran);
      }

      services.forEach((service, index) => {
        dataKeys.push({
          id: dataKeys.length + 1,
          name: service.serviceName,
          displayName: service.displayName,
          color: service.saleColor,
        });

        dataKeys.push({
          id: dataKeys.length + 1,
          name: 'fc' + service.serviceName,
          displayName: 'Prév. ' + service.displayName,
          color: service.forecastColor,
        });

        if (index + 1 === services.length) {
          roundedBarsIds.push(dataKeys.length - 1, dataKeys.length - 2);
        }
      });
    } else {
      dataKeys.push({
        id: dataKeys.length + 1,
        name: 'tot',
        color: theme.colors.greys.dark,
      });

      dataKeys.push({
        id: dataKeys.length + 1,
        name: 'fcTot',
        color: theme.colors.greys.darkest,
      });

      roundedBarsIds.push(dataKeys.length - 1, dataKeys.length - 2);
    }

    this.setState({ dataKeys, roundedBarsIds });
  }

  formatEllipsis(string) {
    if (string.length > 12) {
      string = string.substring(0, 11) + '..';
    }
    return string;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.data.sales !== this.props.data.sales ||
        prevState.focusedTypeButon !== this.state.focusedTypeButon) &&
      this.state.focusedTypeButon !== 'past'
    ) {
      this.updateSales(this.state.data);
    }
    if (
      (prevProps.data.forecasts !== this.props.data.forecasts ||
        prevState.focusedTypeButon !== this.state.focusedTypeButon) &&
      this.state.focusedTypeButon !== 'past'
    ) {
      this.updateForecast(this.state.data);
    }
    if (
      (prevProps.data.lastYearSales !== this.props.data.lastYearSales ||
        prevState.focusedTypeButon !== this.state.focusedTypeButon) &&
      this.state.focusedTypeButon === 'past'
    ) {
      this.updatePastSales(this.state.data);
    }
    if (
      (prevState.data !== this.state.data && this.state.data.length > 0) ||
      prevProps.events !== this.props.events
    ) {
      if (this.props.events.length > 0) {
        this.getEvents();
      }
    }
    if (
      (prevState.data !== this.state.data && this.state.data.length > 0) ||
      prevProps.weather !== this.props.weather
    ) {
      if (this.props.weather.length > 0) {
        this.getWeather();
      }
    }
    if (prevProps.activeDate !== this.props.activeDate) {
      this.setState({ focusedTypeButon: 'trend' });
    }
    if (
      (prevProps.activeDate !== this.props.activeDate ||
        prevProps.activeService !== this.props.activeService ||
        prevState.focusedTypeButon !== this.state.focusedTypeButon) &&
      this.props.activeDate
    ) {
      const data = this.setDaysArray(this.state.focusedTypeButon, this.props.activeDate.format());
      this.generateDataKeys();
      if (this.state.focusedTypeButon === 'past') {
        this.updatePastSales(data);
      } else {
        this.updateSales(data);
        this.updateForecast(data);
      }
    }
  }

  componentDidMount() {
    if (this.props.activeDate) {
      this.setDaysArray(this.state.focusedTypeButon, this.props.activeDate);
    }
  }

  render() {
    const GRANULARITIES = [
      { name: i18next.t('FORECAST.TURNOVER.CHART_GRANULARITY_NOON'), id: 'midi' },
      { name: i18next.t('FORECAST.TURNOVER.CHART_GRANULARITY_EVENING'), id: 'soir' },
      { name: i18next.t('FORECAST.TURNOVER.CHART_GRANULARITY_DAY'), id: 'tot' },
    ];

    const {
      client: { turnoverName },
      storeTimezone,
    } = this.props;

    return (
      <div className={this.props.className}>
        <div className="buttons-encloser">
          <div className="buttons-group left">
            <div
              className={
                this.state.focusedTypeButon === 'trend'
                  ? 'similar-days-chart-button focused'
                  : 'similar-days-chart-button'
              }
              style={{ width: 82 }}
              onClick={() => this.setState({ focusedTypeButon: 'trend' })}
            >
              {i18next.t('FORECAST.TURNOVER.CHART_LABEL_TREND')}
            </div>
            <div
              className={
                this.state.focusedTypeButon === 'past'
                  ? 'similar-days-chart-button focused'
                  : 'similar-days-chart-button'
              }
              style={{ width: 82 }}
              onClick={() => this.setState({ focusedTypeButon: 'past' })}
            >
              {i18next.t('FORECAST.TURNOVER.CHART_LABEL_PAST_YEAR')}
            </div>
          </div>
          {this.props.services && (
            <div className="buttons-group right">
              {GRANULARITIES.map((granularity) => (
                <div
                  className={
                    this.props.activeService === granularity.id
                      ? 'similar-days-chart-button focused'
                      : 'similar-days-chart-button'
                  }
                  key={granularity.id}
                  style={{ width: 38 }}
                  onClick={() => this.props.updateActiveService(granularity.id)}
                >
                  {granularity.name}
                </div>
              ))}
            </div>
          )}
        </div>
        <DeepsightBarChart
          data={this.state.data}
          dataKeys={this.state.dataKeys}
          displayName={this.formatEllipsis(turnoverName)}
          eventsObject={this.state.eventsObject}
          hasUserAccessToProductionFeature={this.props.hasUserAccessToProductionFeature}
          height={130}
          isPast={this.state.focusedTypeButon === 'past'}
          mean={this.state.mean}
          productionMetricsByDates={this.props.productionMetricsByDates}
          roundedBarsIds={this.state.roundedBarsIds}
          stackId="a"
          storeTimezone={storeTimezone}
          wasteMetricPropertyKey={this.props.wasteMetricPropertyKey}
          weatherObject={this.state.weatherObject}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  hasUserAccessToProductionFeature: isUserAllowedToAccessProduction(state.baseReducer.userRights),
});

const Styled = styled(FiveSameDaysChart)`
  margin-bottom: 15px;
  .buttons-encloser {
    width: 100%;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    height: 20px;
    margin-bottom: 10px;
  }

  .buttons-encloser > .buttons-group {
    display: flex;
    width: 50%;
  }

  .buttons-encloser > .buttons-group.left {
    justify-content: flex-start;
  }

  .buttons-group.left > .similar-days-chart-button {
    margin-right: 5px;
  }

  .buttons-encloser > .buttons-group.right {
    justify-content: flex-end;
  }

  .buttons-group.right > .similar-days-chart-button {
    margin-left: 5px;
  }

  .similar-days-chart-button {
    border-radius: 10px;
    background: ${(props) => props.theme.colors?.greys.lightest || '#f1f1f1'};
    box-shadow: ${(props) => (props.theme.colors ? undefined : '0 2px 4px 0 #a8b1c4')};
    line-height: 20px;
    text-align: center;
    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.textMicro};`
        : `font-family: 'proximanovasemibold';
    font-size: 10px;`}
    color: ${(props) => props.theme.colors?.greys.darkest || '#6b768c'};
    cursor: pointer;
  }

  .similar-days-chart-button:hover {
    color: ${(props) => props.theme.colors?.greys.darker || '#0a3eff'};
    cursor: pointer;
    background: ${(props) => props.theme.colors?.greys.light};
  }

  .similar-days-chart-button.focused {
    background: ${(props) => props.theme.colors?.greys.light || '#0a3eff'};
    color: ${(props) => props.theme.colors?.greys.darkest || 'white'};
    ${(props) =>
      props.theme.colors ? undefined : `box-shadow: 0 2px 4px 0 rgba(10, 62, 255, 0.4);`}
  }
`;
export default connect(mapStateToProps, null)(Styled);
