import { getActionAuthorization } from '../commons/utils';

const PRODUCTION_OPERATIONS_ACTIONS = {
  TO_PRODUCE_EDIT: 'production:operations:to-produce:edit',
  PRODUCED_EDIT: 'production:operations:produced:edit',
  EAN_INCREMENT: 'production:operation:ean-increment',
};
const PRODUCTION_LOSSES_ACTIONS = {
  EDIT: 'production:losses:edit',
  EAN_INCREMENT: 'production:losses:ean-increment',
};
const PRODUCTION_STOCKS_ACTIONS = {
  EDIT: 'production:stocks:edit',
  EAN_INCREMENT: 'production:stocks:ean-increment',
};

const PRODUCTION_PLANNING_ACTIONS = {
  VIEW: 'production:planning:view',
  EDIT: 'production:planning:edit',
};

// Operations
export const canEditProductionOperationsToProduce = (actions) =>
  getActionAuthorization(actions, PRODUCTION_OPERATIONS_ACTIONS.TO_PRODUCE_EDIT);

export const canEditProductionOperationsProduced = (actions) =>
  getActionAuthorization(actions, PRODUCTION_OPERATIONS_ACTIONS.PRODUCED_EDIT);

export const canIncrementProductionOperationsProducedOnEANScan = (actions) =>
  getActionAuthorization(actions, PRODUCTION_OPERATIONS_ACTIONS.EAN_INCREMENT);

// Losses
export const canEditProductionLosses = (actions) =>
  getActionAuthorization(actions, PRODUCTION_LOSSES_ACTIONS.EDIT);

export const canIncrementProductionLossesOnEANScan = (actions) =>
  getActionAuthorization(actions, PRODUCTION_LOSSES_ACTIONS.EAN_INCREMENT);

// Stocks
export const canEditProductionStocks = (actions) =>
  getActionAuthorization(actions, PRODUCTION_STOCKS_ACTIONS.EDIT);

export const canIncrementProductionStocksOnEANScan = (actions) =>
  getActionAuthorization(actions, PRODUCTION_STOCKS_ACTIONS.EAN_INCREMENT);

// Production planning
export const canViewProductionPlanning = (actions) =>
  getActionAuthorization(actions, PRODUCTION_PLANNING_ACTIONS.VIEW);

export const canEditProductionPlanning = (actions) =>
  getActionAuthorization(actions, PRODUCTION_PLANNING_ACTIONS.EDIT);
