import { connect } from 'react-redux';
import { first, last, get, omit, isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import { getStoresOfUser, receiveStores, requestStoresError } from '@actions/store';
import { loading, loadingSuccess } from '@actions/loading';
import {
  openMediumModal,
  openGenericModal,
  refreshGenericModal,
  closeGenericModal,
} from '@actions/modal';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { ListView } from '@commons/utils/styledLibraryComponents';
import { PROPERTY_NONE } from '@commons/ItemListSorter';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { getClientInfo } from '@selectors/client';

import { brand as brandService } from '@services/brand';
import clientService from '@services/client';
import storeService from '@services/store';

import { CHOICES_DROPDOWN_ACTIVE, ITEM_DROPDOWN_NONE_VALUE } from '@admin/utils/DropdownItems';

import DeepsightFiltersButton from '@orders/components/FilterButton';

import { Container, ListViewContainer } from './styledComponents';
import { exportStores } from './utils/exportStore';
import { POINT_OF_SALE_STORE_TYPE_ID, STORE_TYPES_DROPDOWN_ITEMS } from './utils/DropdownItems';
import AccountAssociationModal from './components/AccountAssociationModal';
import EmptyState from './components/EmptyState';
import utilsActions from './utils/actions';
import utilsColumns from './utils/columns';
import utilsModals from './utils/modals';

export const BackOfficeStores = (props) => {
  const {
    user,
    client: { clientId, hasCentralKitchens, hasMultipleBrands, storeName, clientName },
    match,
    openModal,
    pageLoaded,
    pageLoading,
    showMessage,
    openGenericModal,
    refreshGenericModal,
  } = props;

  const path = get(match, 'path');

  const userLanguageCode = get(props.user, 'lnkLanguageAccountrel.code', 'fr');

  const translatedClientStoreName = getClientStoreNameTranslation(storeName).toLowerCase();

  const translatedStoreName = getClientStoreNameTranslation(storeName, true);

  // States for list view setup
  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [dataColumns, setDataColumns] = useState(utilsColumns.getColumnsSettings({}));
  const [selectedItems, onStoresSelectedChange] = useState([]);
  const [columnsFilterList, setColumnsFilterList] = useState([]);
  const [filteredStoresList, setFilteredStoresList] = useState([]);

  // States for global data
  const [brands, setClientBrands] = useState([]);
  const [stores, setClientStores] = useState([]);
  const [retailers, setClientRetailers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientLocations, setClientLocations] = useState([]);

  // States for filters management
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [selectedBrands, setSelectedBrands] = useState([ITEM_DROPDOWN_NONE_VALUE]);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [activeKeyForActiveDropdown, setActiveKeyForActiveDropdown] = useState(
    CHOICES_DROPDOWN_ACTIVE[0],
  );

  const [updatedStores, setUpdatedStores] = useState([]);
  const [propertyToUpdate, setPropertyToUpdate] = useState('');

  const history = useHistory();

  const handleGoToStoreDetailPage = (store, state = {}) => {
    history.push({
      pathname: '/backoffice/stores/stores/' + store.id + '/details',
      state,
    });
  };

  /*******************/
  /** Fetch Methods **/
  /*******************/

  const fetchData = async () => {
    try {
      if (hasMultipleBrands) {
        const clientBrands = await getBrandsOfClient(clientId, setClientBrands, props.showMessage);

        setClientBrands(clientBrands);
        setSelectedBrands([ITEM_DROPDOWN_NONE_VALUE, ...clientBrands]);
      }

      const stores = await getStoresOfClient();
      const hasGroups = stores.some((store) => get(store, 'mappedGroups').length > 0);
      setClientStores(stores);

      setClientRetailers(await getRetailersOfClient(clientId));

      const locations = await getLocationsOfClient(clientId);

      setClientLocations(locations);

      const shouldDisplayLocations = locations.length >= 1;

      const columns = utilsColumns.getColumnsSettings({
        hasMultipleBrands,
        hasGroups,
        shouldDisplayLocations,
      });

      setDataColumns(columns);
      setColumnsFilterList(
        columns.filter((column) => ['date', 'string', 'numeric'].includes(column.filterType)),
      );
    } catch (error) {
      props.showMessage(
        i18next.t('BACKOFFICE.STORES.FETCHING_ERROR', {
          storeName: translatedStoreName,
        }),
        'error',
      );
    }
  };

  /***************/
  /** API CALLS **/
  /***************/

  const handleLaunchDateSave = async (selectedStore, selectedDate) => {
    const launchDate = selectedDate ? selectedDate.format('MM-DD-YYYY HH:mm:ss') : null;

    pageLoading();

    try {
      await storeService.updateStoresInBatch([selectedStore.id], { launchDate });

      const clientStores = await getStoresOfClient();
      setClientStores(clientStores);

      props.showMessage(i18next.t('BACKOFFICE.STORES.LIST_LAUNCH_DATE_UPDATE_SUCCESS'), 'success');
    } catch (error) {
      props.showMessage(i18next.t('BACKOFFICE.STORES.LIST_LAUNCH_DATE_UPDATE_ERROR'), 'error');
    } finally {
      pageLoaded();
    }
  };

  const handleBrandsSave = async (brands) => {
    try {
      props.closeGenericModal();
      props.pageLoading();

      const formattedBrands = Object.entries(brands).map((brand) => {
        const brandParams = last(brand);
        if (brandParams.value === PROPERTY_NONE) {
          return;
        }

        if (!get(brandParams, 'originalItem.id')) {
          return { name: brandParams.value };
        }

        return { id: brandParams.originalItem.id, name: brandParams.value };
      });

      await clientService.createOrUpdateBrandByBatch(
        clientId,
        formattedBrands.filter((item) => item !== undefined),
      );

      showMessage(i18next.t('BACKOFFICE.STORES.UPDATE_BRAND_SUCCESS'), 'success');

      if (hasMultipleBrands) {
        const clientBrands = await getBrandsOfClient(clientId, setClientBrands, showMessage);

        setClientBrands(clientBrands);
        setSelectedBrands([ITEM_DROPDOWN_NONE_VALUE, ...clientBrands]);
      }

      setClientStores(await getStoresOfClient());
    } catch (error) {
      showMessage(i18next.t('BACKOFFICE.STORES.UPDATE_BRAND_ERROR'), 'error');
    } finally {
      props.pageLoaded();
    }
  };

  const handleRetailersSave = async (retailers) => {
    try {
      props.closeGenericModal();
      props.pageLoading();

      const formattedRetailers = Object.entries(retailers).map((retailer) => {
        const retailerParams = last(retailer);
        if (retailerParams.value === PROPERTY_NONE) {
          return;
        }

        if (!get(retailerParams, 'originalItem.id')) {
          return { name: retailerParams.value };
        }

        return { id: retailerParams.originalItem.id, name: retailerParams.value };
      });

      await clientService.createOrUpdateRetailerByBatch(
        clientId,
        formattedRetailers.filter((item) => item !== undefined),
      );

      showMessage(i18next.t('BACKOFFICE.STORES.UPDATE_RETAILERS_SUCCESS'), 'success');

      setClientRetailers(await getRetailersOfClient(clientId));
      setClientStores(await getStoresOfClient());
    } catch (error) {
      showMessage(i18next.t('BACKOFFICE.STORES.UPDATE_RETAILERS_ERROR'), 'error');
    } finally {
      props.pageLoaded();
    }
  };

  const handleBatchStoresSave = async (propertyName) => {
    props.closeGenericModal();
    props.pageLoading();

    if (isEmpty(updatedStores)) {
      return;
    }
    const propertyId = get(first(updatedStores), 'originalProperty.id');
    const storeIds = updatedStores.reduce((acc, current) => {
      for (const storeId of get(current, 'ids')) {
        acc.push(storeId);
      }
      return acc;
    }, []);

    try {
      await storeService.updateStoresInBatch(storeIds, { [propertyName]: propertyId || null });
      showMessage(
        i18next.t(
          propertyName === 'locationId'
            ? 'GENERAL.MODAL_LOCATIONS_UPDATE_SUCCESS'
            : propertyName === 'retailerId'
            ? 'GENERAL.MODAL_RETAILERS_UPDATE_SUCCESS'
            : 'GENERAL.MODAL_BRANDS_UPDATE_SUCCESS',
        ),
        'success',
      );

      setClientStores(await getStoresOfClient());

      props.getStoresOfUser();
    } catch (error) {
      showMessage(
        i18next.t(
          propertyName === 'locationId'
            ? 'GENERAL.MODAL_LOCATIONS_UPDATE_ERROR'
            : propertyName === 'retailerId'
            ? 'GENERAL.MODAL_RETAILERS_UPDATE_ERROR'
            : 'GENERAL.MODAL_BRANDS_UPDATE_ERROR',
        ),
        'error',
      );
    } finally {
      props.pageLoaded();
    }
  };

  const getStoresOfClient = async () => {
    try {
      const stores = await storeService.getStoresOfClient(clientId);

      return stores.map((store) => ({
        ...store,
        ...(hasMultipleBrands ? { brandName: get(store, 'lnkBrandStorerel.name') || '' } : {}),
        retailerName: get(store, 'lnkRetailerStorerel.name') || '',
        createdAt: moment(store.createdAt),
        mappedAccounts: store.mappedAccounts.length,
        launchDate: store.launchDate ? moment(store.launchDate) : null,
      }));
    } catch (err) {
      showMessage(
        i18next.t('GENERAL.STORES_CLIENT_FETCH_FAILURE', {
          storeName: translatedStoreName,
        }),
        'error',
      );

      return [];
    }
  };

  const getBrandsOfClient = async () => {
    try {
      return brandService.getBrandsOfClient(clientId);
    } catch (err) {
      showMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_BRANDS_FAILURE'), 'error');

      return [];
    }
  };

  async function getRetailersOfClient(clientId) {
    try {
      return clientService.getRetailersOfClient(clientId);
    } catch (err) {
      showMessage(i18next.t('PRODUCTION.BY_REFERENCE.FETCH_RETAILERS_ERROR'), 'error');

      return [];
    }
  }

  const getLocationsOfClient = async (clientId) => {
    try {
      const result = await clientService.getLocationsByClientId(clientId);

      return result;
    } catch (err) {
      showMessage(i18next.t('BACKOFFICE.STORES.LOCATION_FETCH_ERROR'), 'error');
      return [];
    }
  };

  /**********************/
  /** Callback actions **/
  /**********************/

  const openManageBrandsModal = () => {
    const handleBrandsChange = (data) => {
      const updatedDataModal = data.reduce((result, current) => {
        if (!result[current.value]) {
          result[current.value] = {
            ...omit(current, 'ids'),
            associatedItems: current.ids.map((id) => ({
              id,
            })),
          };
        }
        return result;
      }, {});

      const params = utilsModals.getBrandsModalParams(
        updatedDataModal,
        handleBrandsChange,
        handleBrandsSave,
        translatedStoreName,
        false,
      );

      refreshGenericModal(params);
    };

    const formattedBrands = { [PROPERTY_NONE]: { associatedItems: [] } };
    stores.forEach((store) => {
      if (!store.brandId) {
        return formattedBrands[PROPERTY_NONE].associatedItems.push(store);
      }
    });

    brands.forEach((brand) => {
      formattedBrands[brand.name] = {
        ...brand,
        associatedItems: [],
      };

      stores.forEach((store) => {
        if (store.brandId === brand.id) {
          formattedBrands[brand.name].associatedItems.push(store);
        }
      });
    });

    const params = utilsModals.getBrandsModalParams(
      formattedBrands,
      handleBrandsChange,
      handleBrandsSave,
      translatedStoreName,
    );

    openGenericModal(params);
  };

  const openManageRetailersModal = () => {
    const handleRetailersChange = (data) => {
      const updatedDataModal = data.reduce((result, current) => {
        if (!result[current.value]) {
          result[current.value] = {
            ...omit(current, 'ids'),
            associatedItems: current.ids.map((id) => ({
              id,
            })),
          };
        }
        return result;
      }, {});

      const params = utilsModals.getRetailersModalParams(
        updatedDataModal,
        handleRetailersChange,
        handleRetailersSave,
        translatedStoreName,
        false,
      );

      refreshGenericModal(params);
    };

    const formattedRetailers = { [PROPERTY_NONE]: { associatedItems: [] } };
    stores.forEach((store) => {
      if (!store.retailerId) {
        return formattedRetailers[PROPERTY_NONE].associatedItems.push(store);
      }
    });

    retailers.forEach((retailer) => {
      formattedRetailers[retailer.name] = {
        ...retailer,
        associatedItems: [],
      };

      stores.forEach((store) => {
        if (store.retailerId === retailer.id) {
          formattedRetailers[retailer.name].associatedItems.push(store);
        }
      });
    });

    const params = utilsModals.getRetailersModalParams(
      formattedRetailers,
      handleRetailersChange,
      handleRetailersSave,
      translatedStoreName,
    );

    openGenericModal(params);
  };

  const openLaunchDateModal = (selectedStore) => {
    const handleDateChange = (selectedStore, selectedDate) => {
      const params = utilsModals.getDateModalParams({
        selectedDate,
        selectedStore,
        handleDateChange,
        handleLaunchDateSave,
      });

      refreshGenericModal(params);
    };

    const params = utilsModals.getDateModalParams({
      selectedStore,
      handleDateChange,
      handleLaunchDateSave,
      selectedDate: selectedStore.launchDate,
    });

    openGenericModal(params);
  };

  const openAccountsAssociationModal = (item) => {
    const params = {
      reload,
      isLoading,
      stores: item || selectedItems,
      deactivateBackgroundClick: true,
      component: AccountAssociationModal,
    };

    openModal(params);
  };

  const openEnableStoresModal = (stores) => {
    const storeIds = stores.map(({ id }) => id);
    const firstStoreName = first(stores).name;
    const activeStore = first(stores).active;

    const params = utilsModals.getEnableStoresModalConfig({
      storeIds,
      activeStore,
      handleEnableStores,
      firstStoreName,
    });

    openGenericModal(params);
  };

  const handleEnableStores = async (storeIds) => {
    pageLoading();

    const firstStore = await storeService.getById(storeIds[0]);

    try {
      await storeService.updateStoresInBatch(storeIds, {
        active: !firstStore.active,
      });

      const formattedStores = stores.map((store) => {
        if (storeIds.includes(store.id)) {
          store.active = !firstStore.active;
        }

        return store;
      });

      setClientStores(formattedStores);

      props.getStoresOfUser();

      props.showMessage(
        i18next.t(
          firstStore.active
            ? 'BACKOFFICE.STORES.LIST_ACTION_DISABLE_SUCCESS'
            : 'BACKOFFICE.STORES.LIST_ACTION_ENABLE_SUCCESS',
          { count: storeIds.length },
        ),
        'success',
      );
    } catch (error) {
      props.showMessage(
        i18next.t(
          firstStore.active
            ? 'BACKOFFICE.STORES.LIST_ACTION_DISABLE_ERROR'
            : 'BACKOFFICE.STORES.LIST_ACTION_ENABLE_ERROR',
          { count: storeIds.length },
        ),
        'error',
      );
    } finally {
      pageLoaded();
    }
  };

  const handleCreateStoreAction = () => {
    // Client without central kitchens can only create POS stores
    if (!hasCentralKitchens) {
      history.push({
        pathname: '/backoffice/stores/stores/create/details',
        state: {
          selectedStoreType: POINT_OF_SALE_STORE_TYPE_ID,
        },
      });

      return;
    }
    const handleStoreTypeSelection = (selectedStoreType) => {
      const params = utilsModals.getCreateStoreModalConfig({
        handleStoreTypeSelection,
        handleCreateStore,
        availableStoreTypes: STORE_TYPES_DROPDOWN_ITEMS,
        selectedStoreType,
      });

      refreshGenericModal(params);
    };

    const params = utilsModals.getCreateStoreModalConfig({
      handleStoreTypeSelection,
      handleCreateStore,
      availableStoreTypes: STORE_TYPES_DROPDOWN_ITEMS,
      selectedStoreType: first(STORE_TYPES_DROPDOWN_ITEMS),
    });

    openGenericModal(params);
  };

  const handleCreateStore = async (selectedStoreType) => {
    history.push({
      pathname: '/backoffice/stores/stores/create/details',
      state: {
        selectedStoreType,
      },
    });
  };

  const handleExport = (stores) => {
    const formattedStores = stores.map((store) => ({
      ...store,
      createdAt: moment(store.createdAt).format('DD/MM/YYYY'),
      launchDate: store.launchDate ? moment(store.launchDate).format('DD/MM/YYYY') : '-',
    }));

    exportStores(formattedStores, translatedStoreName, clientName);
  };

  const reload = async () => {
    setIsLoading(true);

    const result = await getStoresOfClient();

    setClientStores(result);

    props.getStoresOfUser();

    onStoresSelectedChange([]);

    setIsLoading(false);
  };

  /******************************/
  /** Handle Store Association **/
  /******************************/

  // Reload user stores after store creation and go back on this page
  useEffect(() => {
    props.getStoresOfUser();
  }, []);

  // Initial setup of the component
  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      await fetchData();

      setRowActions(
        utilsActions.getRowActionsSettings({
          brands,
          history,
          retailers,
          clientLocations,
          openGenericModal,
          hasMultipleBrands,
          filteredStoresList,
          openLaunchDateModal,
          handleAssignPropertiesChange,
          openAccountsAssociationModal,
          openEnableStoresModal,
          translatedClientStoreName,
        }),
      );

      setIsLoading(false);
    })();
  }, [user]);

  // Update list of actions when selection of items has changed
  useEffect(() => {
    setActions(
      utilsActions.getActionsSettings({
        hasMultipleBrands,
        hasActiveStores: filteredStoresList.length ? first(filteredStoresList).active : true,
        selectedItems,
        openAccountsAssociationModal,
        handleExport: () =>
          handleExport(selectedItems.length >= 1 ? selectedItems : filteredStoresList),
        isLoading,
        openManageBrandsModal,
        openManageRetailersModal,
        brands,
        retailers,
        clientLocations,
        openGenericModal,
        handleAssignPropertiesChange,
        openEnableStoresModal,
        history,
        translatedClientStoreName,
        handleGoToStoreDetailPage,
        handleCreateStoreAction,
      }),
    );
    setRowActions(
      utilsActions.getRowActionsSettings({
        brands,
        history,
        retailers,
        clientLocations,
        openGenericModal,
        hasMultipleBrands,
        filteredStoresList,
        handleAssignPropertiesChange,
        openAccountsAssociationModal,
        openEnableStoresModal,
        translatedClientStoreName,
        handleGoToStoreDetailPage,
      }),
    );
  }, [brands, stores, filteredStoresList, selectedItems, isLoading]);

  // Apply default filters on stores list when being set
  useEffect(() => {
    const { selectedBrandIds, noneBrandSelected } = selectedBrands.reduce(
      (acc, { id }) => {
        if (id === -1) {
          acc.noneBrandSelected = true;
        } else {
          acc.selectedBrandIds.push(id);
        }

        return acc;
      },
      { selectedBrandIds: [], noneBrandSelected: false },
    );

    const filteredSelectedStores = stores.filter(({ active, brandId }) => {
      const activeFilterMatched = activeKeyForActiveDropdown.filterValue === active;

      if (!hasMultipleBrands || !activeFilterMatched) {
        return activeFilterMatched;
      }

      if (!brandId) {
        return noneBrandSelected;
      }

      return selectedBrandIds.includes(brandId);
    });

    if (!advancedFilters || !advancedFilters.length) {
      setFilteredStoresList(filteredSelectedStores);
      return;
    }

    const filteredStoresListWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, propertyKey, value }) => doFilter(result, propertyKey, value),
      filteredSelectedStores,
    );

    setFilteredStoresList(filteredStoresListWithAdvancedFilters);
  }, [stores]);

  // Apply selected filters on stores list
  useEffect(() => {
    if (!applyFilters) {
      return;
    }

    const selectedBrandIds = selectedBrands.map(({ id }) => (id === -1 ? null : id));

    // This condition is due to the fact of adding a 'brand' with id = -1
    // in order to add "None" in dropdown
    const isFilterOnBrand = !(
      selectedBrands.length === 0 ||
      (selectedBrands.length === 1 && get(selectedBrands, '[0].id', false) === null)
    );

    const filteredSelectedStores = stores.filter(({ active, brandId }) =>
      isFilterOnBrand
        ? activeKeyForActiveDropdown.filterValue === active && selectedBrandIds.includes(brandId)
        : activeKeyForActiveDropdown.filterValue === active,
    );

    if ((!advancedFilters || !advancedFilters.length) && applyFilters) {
      setFilteredStoresList(filteredSelectedStores);
      return;
    }

    const filteredStoresListWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, propertyKey, value }) => doFilter(result, propertyKey, value),
      filteredSelectedStores,
    );

    setFilteredStoresList(filteredStoresListWithAdvancedFilters);
  }, [advancedFilters, applyFilters]);

  useEffect(() => {
    const isModalOpened = get(props, 'modal.GenericModalBool', false);

    if (!isModalOpened) {
      return;
    }

    const updatedDataModal = updatedStores.reduce((result, current) => {
      if (!result[current.value]) {
        result[current.value] = {
          ...omit(current, 'ids'),
          associatedItems: current.ids.map((id) => ({
            id,
          })),
        };
      }
      return result;
    }, {});

    refreshGenericModal({
      data: {
        ...props.modal.params.data,
        data: updatedDataModal,
        properties: props.modal.params.data.properties,
      },
      actions: [
        {
          key: 0,
          color: 'inpulse-outline',
          label: i18next.t('GENERAL.CANCEL'),
          icon: '/images/inpulse/close-black-small.svg',
        },
        {
          key: 1,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.SAVE'),
          icon: '/images/inpulse/save-white-small.svg',
          handleClick: () =>
            handleBatchStoresSave(
              propertyToUpdate === 'brandName'
                ? 'brandId'
                : propertyToUpdate === 'locationName'
                ? 'locationId'
                : 'retailerId',
            ),
          isDisabled: false,
        },
      ],
    });
  }, [updatedStores]);

  const handleAssignPropertiesChange = (stores, property) => {
    setPropertyToUpdate(property);
    setUpdatedStores(stores);
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ListViewContainer>
        <ListView
          actionOnClick={(store) => handleGoToStoreDetailPage(store)}
          actions={actions}
          columns={dataColumns}
          data={filteredStoresList}
          defaultOrderBy={'name'}
          defaultOrderType={'asc'}
          isLoading={isLoading}
          languageCode={userLanguageCode}
          minActionsInActionsDropdown={1}
          padding={'24px 24px 0px 24px'}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => <EmptyState />}
          renderFilterButton={() => (
            <DeepsightFiltersButton
              advancedFilters={advancedFilters}
              applyFilters={applyFilters}
              columnsFilterList={columnsFilterList}
              customMultipleDropDowns={[
                {
                  id: 'brands-list',
                  list: [ITEM_DROPDOWN_NONE_VALUE, ...brands],
                  selectedItems: selectedBrands,
                  setSelectedItems: setSelectedBrands,
                  icon: '/images/inpulse/app-black-small.svg',
                },
              ]}
              customSingleDropDowns={[
                {
                  id: 'status',
                  itemSelectedIcon: '/images/inpulse/power-ip-black.svg',
                  list: CHOICES_DROPDOWN_ACTIVE,
                  defaultSelectedItem: CHOICES_DROPDOWN_ACTIVE[0],
                  selectedItem: activeKeyForActiveDropdown,
                  setSelectedItem: (selectedItem) => {
                    setActiveKeyForActiveDropdown(selectedItem);
                    onStoresSelectedChange([]);
                  },
                },
              ]}
              filters={filters}
              isLoading={isLoading}
              readOnly={isLoading}
              setAdvancedFilters={setAdvancedFilters}
              setApplyFilters={setApplyFilters}
              setFilters={setFilters}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
            />
          )}
          rowActions={rowActions}
          setSelectedItems={(items) => onStoresSelectedChange(items)}
        />
      </ListViewContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  modal: state.modalReducer,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  getStoresOfUser: () =>
    dispatch(getStoresOfUser()).then(
      (result) => {
        dispatch(receiveStores(result));
      },
      (error) => {
        dispatch(requestStoresError(error));
      },
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeStores);
