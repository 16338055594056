import * as yup from 'yup';

import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';

export const RUSH_ORDER_INPUTS = {
  NAME: 'name',
  UNIT: 'unit',
  QUANTITY: 'quantity',
  TOTAL: 'totalPrice',
};

export const FORM_INPUTS = {
  [RUSH_ORDER_INPUTS.NAME]: {
    type: 'plain-text',
    name: RUSH_ORDER_INPUTS.NAME,
    width: INPUT_WIDTH.SMALL,
  },
  [RUSH_ORDER_INPUTS.UNIT]: {
    type: 'single-select',
    name: RUSH_ORDER_INPUTS.UNIT,
    placeholder: null,
    defaultValue: '',
    width: INPUT_WIDTH.MEDIUM,
    rule: yup
      .object({
        name: yup.string(),
        unit: yup.array().of(yup.string()),
      })
      .strict()
      .required('Please select a unit'),
    isRequired: true,
  },
  [RUSH_ORDER_INPUTS.QUANTITY]: {
    type: 'number',
    name: RUSH_ORDER_INPUTS.QUANTITY,
    width: INPUT_WIDTH.MEDIUM,
    defaultValue: 0,
    rule: yup
      .number()
      .positive('Please enter a correct quantity value')
      .required('Please enter your quantity'),
    isRequired: true,
  },
  [RUSH_ORDER_INPUTS.TOTAL]: {
    type: 'number',
    isButtonHidden: true,
    name: RUSH_ORDER_INPUTS.TOTAL,
    defaultValue: '0',
    width: INPUT_WIDTH.MEDIUM,
    rule: yup.number().min(0),
  },
};

//  * @param {*} currentRow Should be the result of form.getValues(`${fieldArrayName}[${index}]`) - The current row's form state
//  * @param {Input[]} formInputs The form's input definition, see FORM_INPUTS
//  * @returns An array of correctly defined inputs, with the values of Dropdown inputs appropriately disabled.
export const resolveRushOrderRowInputs = (currentRow, formInputs) => {
  const getSelectInputItems = {
    [RUSH_ORDER_INPUTS.UNIT]: (formRow) =>
      formRow.selectableUnits.map((u) => ({
        ...u,
      })),
  };

  return formInputs.map((formInput) => {
    if (formInput.type === 'single-select') {
      const resolvedItems = getSelectInputItems[formInput.name](currentRow);

      return {
        ...formInput,
        items: resolvedItems,
      };
    }

    return { ...formInput };
  });
};
