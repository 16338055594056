import i18next from 'i18next';

export const PAGINATED_DROPDOWN_TYPES_OF_DATA_TO_FETCH = {
  RESET_FETCH: 'RESET_FETCH',
  LOAD_MORE_DATA: 'LOAD_MORE_DATA',
};

export const NONE_DROPDOWN_VALUES = {
  MASCULINE: {
    id: -1,
    name: i18next.t('GENERAL.SELECT_NONE_MASCULINE'),
  },
  FEMININE: {
    id: -1,
    name: i18next.t('GENERAL.SELECT_NONE_FEMININE'),
  },
};

export const DEFAULT_FULL_INVENTORY_TEMPLATE = {
  id: -1,
  name: i18next.t('STOCKS.STOCKS.FORM_FULL_INVENTORY_TEMPLATE'),
};

export const PAYMENT_TERM_IN_DAYS_DROPDOWN_VALUES = {
  15: {
    id: 1,
    value: 15,
  },
  20: {
    id: 2,
    value: 20,
  },
  30: {
    id: 3,
    value: 30,
  },
  45: {
    id: 4,
    value: 45,
  },
  60: {
    id: 5,
    value: 60,
  },
};
