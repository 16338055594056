export const formatCompositionsEntityCost = (compositions) =>
  compositions.map((composition) => ({
    ...composition,
    entity: {
      ...composition.entity,
      renderCost: composition.entity.cost * (composition.quantity || 0),
    },
  }));

export default {
  formatCompositionsEntityCost,
};
