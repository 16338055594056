import Drawer from 'react-modern-drawer';
import PropTypes from 'prop-types';
import React from 'react';

import { Container } from './styledComponents';

const SideBarDrawer = (props) => {
  const { direction, isOpened, children, customWidth, onClick } = props;

  return (
    <Container>
      <Drawer
        direction={direction}
        duration={100}
        open={isOpened}
        overlayColor={'rgba(0, 0, 0, 0)'} // Use rgba to have blur and opacity
        overlayOpacity={0}
        size={customWidth}
        style={{ height: window.innerHeight ? `${window.innerHeight}px` : '100vh' }} // use window.innerHeight because of old tablet
        zIndex={10}
        onClose={onClick}
      >
        {children}
      </Drawer>
    </Container>
  );
};

SideBarDrawer.propTypes = {
  direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  isOpened: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  customWidth: PropTypes.string,
};

SideBarDrawer.defaultProps = {
  direction: 'left',
  customWidth: '200',
};

export default SideBarDrawer;
