import i18next from 'i18next';

const ORDER_ANOMALIES = {
  INVALID_QUANTITY: 'invalid_quantity',
  INVALID_PRICE: 'invalid_price',
  INVALID_WEIGHT: 'invalid_weight',
  BAD_REFERENCE: 'bad_reference',
  TAINTED: 'tainted',
  DEFECTIVE: 'defective',
  LATE: 'late',
  SOLD_OUT: 'sold_out',
  SHORT_DLC: 'short_dlc',
  BROKEN_PRODUCT: 'broken_product',
  NOT_ORDERED: 'not_ordered',
  WITHDRAWAL: 'withdrawal',
  OTHER: 'other',
};

export const getTranslatedOrderAnomalies = (transformToObject = true) => {
  const anomalies = [
    {
      id: ORDER_ANOMALIES.OTHER,
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_OTHER'),
      value: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_OTHER'),
    },
    {
      id: ORDER_ANOMALIES.INVALID_QUANTITY,
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_WRONG_QUANTITY'),
      value: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_WRONG_QUANTITY'),
    },
    {
      id: ORDER_ANOMALIES.INVALID_PRICE,
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_WRONG_PRICE'),
      value: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_WRONG_PRICE'),
    },
    {
      id: ORDER_ANOMALIES.INVALID_WEIGHT,
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_WRONG_WEIGHT'),
      value: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_WRONG_WEIGHT'),
    },
    {
      id: ORDER_ANOMALIES.BAD_REFERENCE,
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_WRONG_REFERENCE'),
      value: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_WRONG_REFERENCE'),
    },
    {
      id: ORDER_ANOMALIES.TAINTED,
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_ROTTEN'),
      value: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_ROTTEN'),
    },
    {
      id: ORDER_ANOMALIES.DEFECTIVE,
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_FLAWED'),
      value: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_FLAWED'),
    },
    {
      id: ORDER_ANOMALIES.LATE,
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_SUPPLIER_DELAY'),
      value: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_SUPPLIER_DELAY'),
    },
    {
      id: ORDER_ANOMALIES.SOLD_OUT,
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_SOLD_OUT'),
      value: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_SOLD_OUT'),
    },
    {
      id: ORDER_ANOMALIES.SHORT_DLC,
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_SHORT_DLC'),
      value: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_SHORT_DLC'),
    },
    {
      id: ORDER_ANOMALIES.BROKEN_PRODUCT,
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_BROKEN_PRODUCT'),
      value: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_BROKEN_PRODUCT'),
    },
    {
      id: ORDER_ANOMALIES.NOT_ORDERED,
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_NOT_ORDERED'),
      value: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_NOT_ORDERED'),
    },
    {
      id: ORDER_ANOMALIES.WITHDRAWAL,
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_WITHDRAWAL'),
      value: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_ANOMALY_WITHDRAWAL'),
    },
  ];

  if (!transformToObject) {
    return anomalies;
  }

  return anomalies.reduce((result, anomaly) => {
    result[anomaly.id] = anomaly.value;

    return result;
  }, {});
};

export default {
  ORDER_ANOMALIES,
  getTranslatedOrderAnomalies,
};
