import { connect } from 'react-redux';
import { get, head } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useLayoutEffect, useState } from 'react';

import { closeModal } from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { getUserTimezone } from '@commons/utils/date';

import OrderService from '@services/order';

import { RenderConfirmationModal } from '../OrderForm/OrderFormModals';
import utilsLib from '../OrderForm/utils';

import { Container } from './styledComponents';
import GroupOrderBody from './components/GroupOrderBody';
import GroupOrderHeader from './components/GroupOrderHeader';

const GroupOrderModal = (props) => {
  const { user, closeModal, showMessage, params } = props;

  const [selectedStores, setSelectedStores] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [isEmpty, setIsEmpty] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [userEmail, setUserEmail] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [sending, setSending] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [sendingParams, setSendingParams] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(null);
  const [supplierInfos, setSupplierInfos] = useState(null);
  const [cc, setCc] = useState(params.send.email.order.default_cc);
  const [supplierProfile, setSupplierProfile] = useState(null);
  const [orderedUnit, setOrderedUnit] = useState(null);
  const [selectedOrdersTotalPrice, setSelectedOrdersTotalPrice] = useState(null);
  const [orderTimeLimit, setOrderTimeLimit] = useState('');

  // TODO - [TIMEZONES] Adapt when we have spec for multi-stores selection with timezones
  const firstStore = head(selectedStores);
  const storeTimezone = get(firstStore, 'timezone', '');

  const initializeSendindParamsConfiguration = () => {
    if (!params.send) {
      return;
    }

    const email = get(user, 'email', '');

    let updatedSending = '';
    let updatedSendingParams = Object.assign({}, params.send);

    if (updatedSendingParams.hasOwnProperty('email')) {
      updatedSending = 'email';

      if (!updatedSendingParams.email.order.default_cc.includes(email)) {
        updatedSendingParams.email.order.default_cc.push(email);
      }

      if (!updatedSendingParams.email.credit.default_cc.includes(email)) {
        updatedSendingParams.email.credit.default_cc.push(email);
      }
    }

    if (updatedSendingParams.hasOwnProperty('edi')) {
      updatedSending = 'edi';
    }

    setUserEmail(email);
    setSending(updatedSending);
    setSendingParams(updatedSendingParams);
  };

  useEffect(() => {
    if (!supplierProfile) {
      return;
    }

    setSupplierInfos({
      email: utilsLib.getSupplierProfileEmails(supplierProfile, 'order'),
    });
  }, [supplierProfile]);

  useLayoutEffect(() => {
    initializeSendindParamsConfiguration();
  }, []);

  const formatCc = (cc) => {
    if (!cc) {
      return [];
    }

    if (typeof cc === 'string') {
      return cc.split(',');
    }

    return cc;
  };

  const sendGroupOrders = async () => {
    const orderIds = selectedOrders.map((order) => order.id);
    const sendParams = {
      to: supplierInfos.email,
      cc: formatCc(cc),
      bcc: params.send.email.order.default_bcc,
      body: params.send.email.order.default_body,
      clientId: get(user, 'clientId'),
      userId: get(user, 'id'),
      userTimezone: getUserTimezone(),
    };

    try {
      props.pageLoading();
      props.params.setIsRetrievingData(true);
      await OrderService.sendGroupedOrder(orderIds, sendParams);

      props.showMessage(i18next.t('ORDERS.ORDERS.GROUP_SEND_SUCCESS'), 'success');
    } catch (e) {
      props.showMessage(i18next.t('ORDERS.ORDERS.GROUP_SEND_ERROR'), 'error');
    } finally {
      setDisplayConfirmationModal(false);
      props.closeModal();
      props.pageLoaded();
      params.reloadOrders();
    }
  };

  return (
    <Container>
      {displayConfirmationModal && (
        <RenderConfirmationModal
          cc={cc}
          confirmationModalTitle={i18next.t('ORDERS.ORDERS.FORM_SENT_BY_MAIL')}
          handleCancel={() => setDisplayConfirmationModal(false)}
          handleCC={(ev) => setCc(ev.target.value)}
          handleConfirm={() => {
            if (!supplierInfos.email) {
              return;
            }

            sendGroupOrders();
          }}
          handleSupplierInfoEmail={(ev) =>
            setSupplierInfos({
              ...supplierInfos,
              email: ev.target.value,
            })
          }
          supplierEmail={supplierInfos.email}
        />
      )}

      <GroupOrderHeader
        closeModal={closeModal}
        orderedUnit={orderedUnit}
        orderTimeLimit={orderTimeLimit}
        selectedOrders={selectedOrders}
        selectedOrdersTotalPrice={selectedOrdersTotalPrice}
        selectedStores={selectedStores}
        selectedSupplier={selectedSupplier}
        setDisplayConfirmationModal={setDisplayConfirmationModal}
        setIsEmpty={setIsEmpty}
        setIsLoading={setIsLoading}
        setOrders={setOrders}
        setSelectedOrders={setSelectedOrders}
        setSelectedStores={setSelectedStores}
        setSelectedSupplier={setSelectedSupplier}
        showMessage={showMessage}
        storeTimezone={storeTimezone}
        supplierProfile={supplierProfile}
        user={user}
      />
      <GroupOrderBody
        isEmpty={isEmpty}
        isLoadind={isLoading}
        orderedUnit={orderedUnit}
        orders={orders}
        orderTimeLimit={orderTimeLimit}
        selectedOrders={selectedOrders}
        selectedOrdersTotalPrice={selectedOrdersTotalPrice}
        selectedSupplier={selectedSupplier}
        setIsLoading={setIsLoading}
        setOrderedUnit={setOrderedUnit}
        setOrderTimeLimit={setOrderTimeLimit}
        setSelectedOrders={setSelectedOrders}
        setSelectedOrdersTotalPrice={setSelectedOrdersTotalPrice}
        setSupplierProfile={setSupplierProfile}
        storeTimezone={storeTimezone}
        supplierProfile={supplierProfile}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  closeModal: () => {
    dispatch(closeModal());
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupOrderModal);
