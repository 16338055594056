import { groupBy, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Tooltip } from '@commons/utils/styledLibraryComponents';
import DisplayImgInFullScreen from '@commons/DisplayImgInFullScreen';

import getColumnsConfig from '@stocks/StocksInventories/components/StockForm/utils/getColumnsConfig';

import Row from '../StockFormRow';

import { Container, RowContainer, ColumnsContainer, Column } from './styledComponents';

const StockFormCategory = (props) => {
  const {
    handleDataUpdate,
    supplierProducts,
    readOnly,
    stockType,
    totByStorageAreaIdSPIdAndPackagingId,
    selectedStorageAreaId,
  } = props;

  const columnsConfig = getColumnsConfig({ stockType });

  const [displayProductPicture, setDisplayProductPicture] = useState({ state: false, img: '' });

  const categorizedSpByAssociation = supplierProducts.reduce(
    (acc, supplierProduct) => {
      if (!!supplierProduct.entityId) {
        acc.associated.push(supplierProduct);
        return acc;
      }

      acc.notAssociated.push([supplierProduct]);
      return acc;
    },
    { associated: [], notAssociated: [] },
  );

  const spsGroupedByEntities = Object.values(
    groupBy(categorizedSpByAssociation.associated, 'entityId'),
  );

  const formattedSPsByAssociation = spsGroupedByEntities.concat(
    categorizedSpByAssociation.notAssociated,
  );

  const sortedSPsByAssociation = sortBy(formattedSPsByAssociation, (sps) => sps[0].name);

  return (
    <Container>
      <ColumnsContainer>
        {columnsConfig.map((column, indexColumn) => (
          <Column
            flex={column.flex}
            key={`${column.propertyKey}-${indexColumn}`}
            nbColumns={columnsConfig.length}
          >
            {column.img && !!displayProductPicture.state ? (
              <>
                <DisplayImgInFullScreen
                  image={displayProductPicture.img}
                  setDisplayProductPicture={setDisplayProductPicture}
                />
                {column.label}
              </>
            ) : (
              <div style={column.style}>{column.label}</div>
            )}
            {!!column.tooltip && (
              <Tooltip key={`tooltip-${column.propertyKey}-${indexColumn}`} text={column.tooltip} />
            )}
          </Column>
        ))}
      </ColumnsContainer>
      {sortedSPsByAssociation.map((formattedSPs, index) => (
        <RowContainer key={index}>
          {formattedSPs.map((supplierProduct) => (
            <Row
              columns={columnsConfig}
              handleDataUpdate={handleDataUpdate}
              key={`${supplierProduct.name}-${index}`}
              readOnly={readOnly}
              selectedStorageAreaId={selectedStorageAreaId}
              setDisplayProductPicture={setDisplayProductPicture}
              stockType={stockType}
              supplierProduct={supplierProduct}
              totByPackagingId={totByStorageAreaIdSPIdAndPackagingId[supplierProduct.id]}
            />
          ))}
        </RowContainer>
      ))}
    </Container>
  );
};

StockFormCategory.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  categoryName: PropTypes.string.isRequired,
  handleDataUpdate: PropTypes.func.isRequired,
  supplierProducts: PropTypes.array.isRequired,
};

export default StockFormCategory;
