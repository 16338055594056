import { connect } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import styled from 'styled-components';

const _EventDisplay = ({ event, toggleTooltip, handleEventEdit, ...props }) => {
  let className = 'forecast-calendar-event ';
  if (event.clientId === null && event.storeId === null) {
    className += 'blue';
  }
  let widthAdditioner = '';
  if (event.isFirst) {
    className += ' first';
  } else {
    className += ' not-first';
    widthAdditioner += ' + 50px ';
  }
  if (event.isLast) {
    className += ' last';
  } else {
    className += ' not-last';
    widthAdditioner += ' + 50px ';
  }
  const width =
    'calc(100% * ' + event.count + ' + ' + (event.count - 1) + 'px' + widthAdditioner + ')';
  const openEdit = (event) => {
    if (
      event.eventSubCategory.roles &&
      JSON.parse(event.eventSubCategory.roles).includes(
        get(props.user, 'lnkAccountRoleAccountrel.name'),
      )
    ) {
      handleEventEdit(event);
    }
  };

  const mouseEnter = (event) => {
    toggleTooltip(event);
  };

  const mouseLeave = () => {
    toggleTooltip(null);
  };

  return (
    <div
      className={`${props.className} ${className}`}
      id={event.id}
      style={{ width }}
      onClick={() => openEdit(event)}
      onMouseEnter={() => mouseEnter(event)}
      onMouseLeave={mouseLeave}
    >
      <img className="forecast-calendar-event-icon" src={event.eventSubCategory.img} />
      {event.input1}
    </div>
  );
};

const EventDisplay = connect((state) => ({
  user: state.baseReducer.user,
}))(_EventDisplay);

const InpulseEventDisplay = styled(EventDisplay)`
  height: 28px;
  margin-bottom: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font: ${(props) => props.theme.fonts?.textSmall};
  color: ${(props) => props.theme.colors.greys.darkest};
  padding-right: 10px;
  padding-left: 3px;
  z-index: 0;
  position: relative;
  background-color: ${(props) => props.theme.colors.greys.lighter};
  border: 1px solid ${(props) => props.theme.colors.greys.light};
  display: flex;
  align-items: center;

  &.blue {
    border: ${(props) => `1px solid ${props.theme.colors.greys.darkest}`};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  }

  &.last {
    border-bottom-right-radius: 14px;
    border-top-right-radius: 14px;
  }

  &.not-last {
    padding-right: 35px;
  }

  &.first {
    border-bottom-left-radius: 14px;
    border-top-left-radius: 14px;
  }

  &.not-first {
    padding-left: 28px;
    left: -50px;
  }

  &:hover {
    cursor: pointer;
  }

  .forecast-calendar-event-icon {
    height: 20px;
    margin-right: 10px;
    margin-left: 1px;
  }
`;

const EventsOfDay = ({ date, events, toggleTooltip, handleEventEdit, storeTimezone, ...props }) => {
  let eventFiltered = events
    .filter((event) => event.date.isSame(date, 'day'))
    .map((event) => {
      event.howManyDaysLeft = moment
        .tz(event.endDate, storeTimezone)
        .diff(moment.tz(storeTimezone), 'days');
      return event;
    });
  eventFiltered = eventFiltered.sort((a, b) => (a.howManyDaysLeft < b.howManyDaysLeft ? 1 : -1));
  const previousEvents = events.filter(
    (event) =>
      event.date.isBefore(date, 'day') && moment.tz(event.endDate, storeTimezone).isAfter(date),
  );
  return (
    <div className={props.className}>
      {previousEvents.map((event) => (
        <div className="forecast-calendar-event-empty" key={date.format() + '-' + event.id} />
      ))}
      {eventFiltered.map((event) => (
        <InpulseEventDisplay
          event={event}
          handleEventEdit={handleEventEdit}
          key={event.id}
          toggleTooltip={toggleTooltip}
        />
      ))}
    </div>
  );
};

const InpulseEventsOfDay = styled(EventsOfDay)`
  .forecast-calendar-event-empty {
    height: 28px;
    margin-bottom: 20px;
    line-height: 28px;
    z-index: -1;
    position: relative;
  }
`;

export { InpulseEventsOfDay };
