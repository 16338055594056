import i18next from 'i18next';

import theme from '@theme';

export const DAYS_TO_FETCH_PAST = 40;

export const SELECTABLE_DAYS = [
  {
    id: 0,
    value: i18next.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_ALL'),
  },
  {
    id: 1,
    value: i18next.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_MONDAY'),
  },
  {
    id: 2,
    value: i18next.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_TUESDAY'),
  },
  {
    id: 3,
    value: i18next.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_WEDNESDAY'),
  },
  {
    id: 4,
    value: i18next.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_THURSDAY'),
  },
  {
    id: 5,
    value: i18next.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_FRIDAY'),
  },
  {
    id: 6,
    value: i18next.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_SATURDAY'),
  },
  {
    id: 7,
    value: i18next.t('FORECAST.SALES.PRECISION_CHART_DAY_SELECTED_SUNDAY'),
  },
];

export const CHART_LEGEND = [
  {
    id: 0,
    value: 'Inpulse',
    type: 'square',
    color: theme.colors.brand.primary,
  },
  {
    id: 1,
    value: i18next.t('FORECAST.SALES.CHART_PAST_ERROR_LEGEND_FORECAST'),
    type: 'square',
    color: '#000000',
  },
  {
    id: 2,
    value: i18next.t('FORECAST.SALES.CHART_PAST_ERROR_LEGEND_SALES'),
    type: 'square',
    color: '#BDC1CC',
  },
  {
    id: 3,
    value: i18next.t('FORECAST.SALES.CHART_PAST_ERROR_LEGEND_POSITIVE_GAP'),
    type: 'square',
    color: theme.colors.brand.primaryDark,
  },
  {
    id: 4,
    value: i18next.t('FORECAST.SALES.CHART_PAST_ERROR_LEGEND_NEGATIVE_GAP'),
    type: 'square',
    color: '#FF0000',
  },
];

export default {
  CHART_LEGEND,
  DAYS_TO_FETCH_PAST,
  SELECTABLE_DAYS,
};
