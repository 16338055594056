import { head } from 'lodash';
import i18next from 'i18next';

import { isUserAdmin, isUserDeepsight } from '@commons/utils/roles';
import sortActionsList from '@commons/utils/sortActionsList';

import {
  canDownloadIngredientTemplate,
  canImportIngredient,
} from '@selectors/actions/ingredientsActions';

import {
  getAssignCategorySubCategoryModalParams,
  getCategorySubCategoryModalParams,
} from '@admin/commons/modalConfigurations';

import { getDeleteIngredientsModalParams } from './modalConfigurations';

const isDisableActionDisabled = (ingredients) =>
  ingredients.some(({ parentMappings, products }) => {
    const areAnyParentMappingsActive = parentMappings.some(
      ({ associatedEntity }) => !!associatedEntity && associatedEntity.active,
    );

    const areAnyLinkedProductsActive = products.some(({ active }) => active);

    return areAnyParentMappingsActive || areAnyLinkedProductsActive;
  });

export const getActions = (props) => {
  const {
    ingredients,
    handleExport,
    selectedItems,
    renderCreateButton,
    history,
    openGenericModal,
    handleIngredientChangeModalBatch,
    deleteIngredientByIdsWithLinkedData,
    updateIsStrategicOfIngredientsByBatch,
    handleIngredientsDeactivation,
    filteringOnActive,
    user,
    handleIngredientActivation,
    getEntityBatchCreationTemplate,
    openImportEntitiesModal,
    authorizedActions,
  } = props;

  const isDisableIngredientActionDisabled = isDisableActionDisabled(selectedItems);

  const actions = [
    {
      id: 'action-create',
      fixed: true,
      actionLabel: i18next.t('GENERAL.CREATE'),
      handleAction: () => history.push('/admin/products/ingredients/create'),
      render: renderCreateButton,
    },
  ];

  if (!selectedItems.length) {
    actions.push(
      {
        id: 'action-update-category',
        actionLabel: i18next.t('GENERAL.UPDATE_CATEGORIES'),
        handleAction: () => {
          const params = getCategorySubCategoryModalParams(
            ingredients,
            'category',
            i18next.t('ADMIN.INGREDIENTS.MODAL_UPDATE_CATEGORY_LIST_INGREDIENT'),
            handleIngredientChangeModalBatch,
          );

          openGenericModal(params);
        },
        actionIcon: () => '/images/inpulse/gear-ipblack-small.svg',
        isHidden: () => !filteringOnActive,
      },
      {
        id: 'export',
        actionLabel: i18next.t('ADMIN.INGREDIENTS.EXPORT_LIST'),
        actionIcon: () => '/images/inpulse/file-download-black-small.svg',
        handleAction: () => handleExport(selectedItems),
      },
      {
        id: 'csm-download-template',
        actionLabel: i18next.t('ADMIN.INGREDIENTS.DOWNLOAD_TEMPLATE_BATCH_CREATE_ACTION'),
        handleAction: () => getEntityBatchCreationTemplate(),
        actionIcon: () =>
          !canDownloadIngredientTemplate(authorizedActions)
            ? '/images/inpulse/file-download-lmgrey-small.svg'
            : '/images/inpulse/file-download-black-small.svg',
        isHidden: () => !filteringOnActive || !canDownloadIngredientTemplate(authorizedActions),
      },
      {
        id: 'csm-import-entities',
        actionLabel: i18next.t('ADMIN.INGREDIENTS.UPLOAD_TEMPLATE_BATCH_CREATE_ACTION'),
        handleAction: () => openImportEntitiesModal(),
        actionIcon: () =>
          !canImportIngredient(authorizedActions)
            ? '/images/inpulse/file-upload-lmgrey-small.svg'
            : '/images/inpulse/file-upload-black-small.svg',
        isHidden: () => !filteringOnActive || !canImportIngredient(authorizedActions),
      },
    );
  } else if (selectedItems.length) {
    const isUniqueSelection = selectedItems.length === 1;
    const isSelectionStrategicOnly = selectedItems.every(({ isStrategic }) => isStrategic);

    actions.push({
      id: 'activate',
      actionLabel: i18next.t('ADMIN.INGREDIENTS.SINGLE_ACTIVATION'),
      handleAction: () => handleIngredientActivation(head(selectedItems)),
      actionIcon: () => '/images/inpulse/power-black-small.svg',
      isHidden: () =>
        !isUniqueSelection || filteringOnActive || (!isUserAdmin(user) && !isUserDeepsight(user)),
    });

    actions.push(
      {
        id: 'assign-category',
        actionLabel: i18next.t('GENERAL.LIST_ACTION_ASSIGN_CATEGORY'),
        handleAction: () => {
          const params = getAssignCategorySubCategoryModalParams(
            ingredients,
            selectedItems,
            'category',
            handleIngredientChangeModalBatch,
          );

          openGenericModal(params);
        },
        actionIcon: () => '/images/inpulse/link-black-small.svg',
        isHidden: () => !filteringOnActive,
      },
      {
        id: 'define-strategic',
        actionLabel: isUniqueSelection
          ? i18next.t(
              isSelectionStrategicOnly
                ? 'GENERAL.LIST_ACTION_DEFINE_AS_NOT_STRATEGIC_SINGLE'
                : 'GENERAL.LIST_ACTION_DEFINE_AS_STRATEGIC_SINGLE',
            )
          : i18next.t(
              isSelectionStrategicOnly
                ? 'GENERAL.LIST_ACTION_DEFINE_AS_NOT_STRATEGIC_MULTIPLE'
                : 'GENERAL.LIST_ACTION_DEFINE_AS_STRATEGIC_MULTIPLE',
            ),
        handleAction: () =>
          updateIsStrategicOfIngredientsByBatch(selectedItems, !isSelectionStrategicOnly),
        actionIcon: () => '/images/inpulse/icon-label-important-black.svg',
        isHidden: () => !filteringOnActive,
      },
      {
        id: 'deactivate',
        actionLabel: isUniqueSelection
          ? i18next.t('ADMIN.INGREDIENTS.SINGLE_DEACTIVATION')
          : i18next.t('ADMIN.INGREDIENTS.MULTIPLE_DEACTIVATION'),
        handleAction: () => handleIngredientsDeactivation(selectedItems),
        actionIcon: () =>
          isDisableIngredientActionDisabled
            ? '/images/inpulse/power-lmgrey-small.svg'
            : '/images/inpulse/power-black-small.svg',
        isDisabled: () => isDisableIngredientActionDisabled,
        disabledTooltipText: () =>
          i18next.t('ADMIN.INGREDIENTS.DEACTIVATION_DISABLED_TOOLTIP_TEXT', {
            count: selectedItems.length,
          }),
        isHidden: () => !filteringOnActive || (!isUserAdmin(user) && !isUserDeepsight(user)),
      },
      {
        id: 'export',
        actionLabel: isUniqueSelection
          ? i18next.t('ADMIN.INGREDIENTS.EXPORT_INGREDIENT')
          : i18next.t('ADMIN.INGREDIENTS.EXPORT_SELECTION'),
        actionIcon: () => '/images/inpulse/file-download-black-small.svg',
        handleAction: () => handleExport(selectedItems),
      },
      {
        id: 'csm-delete',
        actionLabel: isUniqueSelection
          ? i18next.t('ADMIN.INGREDIENTS.DELETE_SINGLE_INGREDIENT_ACTION')
          : i18next.t('ADMIN.INGREDIENTS.DELETE_MULTIPLE_INGREDIENTS_ACTION'),
        isHighlighted: () => true,
        actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
        isHidden: () => filteringOnActive || !isUserDeepsight(user),
        handleAction: () => {
          const params = getDeleteIngredientsModalParams(
            selectedItems,
            deleteIngredientByIdsWithLinkedData,
          );

          openGenericModal(params);
        },
      },
    );
  }

  return sortActionsList(actions);
};

export const getRowActions = (props) => {
  const {
    ingredients,
    openGenericModal,
    handleIngredientChangeModalBatch,
    deleteIngredientByIdsWithLinkedData,
    updateIsStrategicOfIngredientsByBatch,
    handleIngredientsDeactivation,
    filteringOnActive,
    user,
    handleIngredientActivation,
  } = props;

  const rowActions = [
    {
      id: 'assign-category',
      actionLabel: () => i18next.t('GENERAL.LIST_ACTION_ASSIGN_CATEGORY'),
      handleAction: (ingredient) => {
        const params = getAssignCategorySubCategoryModalParams(
          ingredients,
          [ingredient],
          'category',
          handleIngredientChangeModalBatch,
        );

        openGenericModal(params);
      },
      actionIcon: () => '/images/inpulse/link-black-small.svg',
      isHidden: () => !filteringOnActive,
    },
    {
      id: 'define-strategic',
      actionLabel: (ingredient) =>
        i18next.t(
          ingredient.isStrategic
            ? 'GENERAL.LIST_ACTION_DEFINE_AS_NOT_STRATEGIC_SINGLE'
            : 'GENERAL.LIST_ACTION_DEFINE_AS_STRATEGIC_SINGLE',
        ),
      handleAction: (ingredient) =>
        updateIsStrategicOfIngredientsByBatch([ingredient], !ingredient.isStrategic),
      actionIcon: () => '/images/inpulse/icon-label-important-black.svg',
      isHidden: () => !filteringOnActive,
    },
    {
      id: 'activate',
      actionLabel: () => i18next.t('ADMIN.INGREDIENTS.SINGLE_ACTIVATION'),
      handleAction: (ingredient) => handleIngredientActivation(ingredient),
      actionIcon: () => '/images/inpulse/power-black-small.svg',
      isHidden: () => filteringOnActive || (!isUserAdmin(user) && !isUserDeepsight(user)),
    },
    {
      id: 'deactivate',
      actionLabel: () => i18next.t('ADMIN.INGREDIENTS.SINGLE_DEACTIVATION'),
      handleAction: (ingredient) => handleIngredientsDeactivation([ingredient]),
      actionIcon: (ingredient) =>
        isDisableActionDisabled([ingredient])
          ? '/images/inpulse/power-lmgrey-small.svg'
          : '/images/inpulse/power-black-small.svg',
      isDisabled: (ingredient) => isDisableActionDisabled([ingredient]),
      disabledTooltipText: () =>
        i18next.t('ADMIN.INGREDIENTS.DEACTIVATION_DISABLED_TOOLTIP_TEXT', { count: 1 }),
      isHidden: () => !filteringOnActive || (!isUserAdmin(user) && !isUserDeepsight(user)),
    },
    {
      id: 'csm-delete',
      actionLabel: () => i18next.t('ADMIN.INGREDIENTS.DELETE_SINGLE_INGREDIENT_ACTION'),
      handleAction: (ingredient) => {
        const params = getDeleteIngredientsModalParams(
          [ingredient],
          deleteIngredientByIdsWithLinkedData,
        );

        openGenericModal(params);
      },
      actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
      isHighlighted: () => true,
      isHidden: () => filteringOnActive || !isUserDeepsight(user),
    },
  ];

  return sortActionsList(rowActions);
};
