import i18next from 'i18next';

import { getClientStoreNameTranslation } from '@commons/utils/translations';

export const getMainSheetOcerizationColumnsSettings = (storeName) => {
  const columns = [
    {
      name: i18next.t('GENERAL.SUPPLIER'),
      propertyKey: 'supplierName',
    },
    {
      name: getClientStoreNameTranslation(storeName),
      propertyKey: 'storeName',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_IMPORT_DATE'),
      propertyKey: 'importedAt',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_NUMBER_EXPORT'),
      propertyKey: 'invoiceNumber',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_DATE'),
      propertyKey: 'invoiceDate',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.ARTICLE_CODE'),
      propertyKey: 'sku',
    },
    {
      name: i18next.t(
        'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_COLUMN_NAME_ARTICLE',
      ),
      propertyKey: 'invoiceName',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_COLUMN_PACKAGING'),
      propertyKey: 'invoicePackaging',
    },
    {
      name: i18next.t(
        'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_COLUMN_PRICE_EXCLUDING_TAXES',
      ),
      propertyKey: 'invoicePrice',
      type: 'currency',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_COLUMN_QUANTITY'),
      propertyKey: 'invoiceQuantity',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_COLUMN_TOTAL'),
      propertyKey: 'computedTotal',
      type: 'currency',
    },
  ];

  return columns;
};
