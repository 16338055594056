import PropTypes from 'prop-types';
import React from 'react';

import { ENUM_FONTS } from '@commons/Text';
import DisplayNumber from '@commons/DisplayNumber';

import { CategorySumupContainer, TextContainer, Text } from './styledComponents';

const RecipeInventoryFormCategorySumup = (props) => {
  const { name, total, handleCategoryUnfolding, isCategoryOpen } = props;

  return (
    <CategorySumupContainer
      isCategoryOpen={isCategoryOpen}
      onClick={() => handleCategoryUnfolding(name)}
    >
      <img
        src={
          isCategoryOpen
            ? '/images/inpulse/carret-bottom-black.svg'
            : '/images/inpulse/carret-right-black.svg'
        }
      />
      <TextContainer>
        <Text>{name}</Text>
        <DisplayNumber font={ENUM_FONTS.H2_INTER} number={total} displayCurrencyCode />
      </TextContainer>
    </CategorySumupContainer>
  );
};

RecipeInventoryFormCategorySumup.propTypes = {
  name: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  handleCategoryUnfolding: PropTypes.func.isRequired,
  isCategoryOpen: PropTypes.bool.isRequired,
};

export default RecipeInventoryFormCategorySumup;
