import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ThemeProvider } from 'styled-components';

import { Props } from './interfaces';

import { FrancoButton } from './components/FrancoButton/FrancoButton';

import {
  Container,
  FullSizeContentContainer,
  Title,
  Content,
  LeftPartContainer,
  ButtonsContainer,
  TooltipContainer,
} from './styledComponents';

import { getTheme } from '../utils/theme';

import Label from '../Label';
import Tooltip from '../Tooltip';

const Franco = (props: Props): JSX.Element => {
  const {
    theme,
    color,
    type,
    title,
    content,
    labelContent,
    contentColor,
    background,
    width,
    labelTooltip,
    buttons,
    infoTooltip,
    hasBorder,
  } = props;

  return (
    <ThemeProvider theme={getTheme(theme, 'franco')}>
      <Container width={width} hasBorder={hasBorder}>
        {content !== '' && title === '' && labelContent === '' && (
          <FullSizeContentContainer>{content}</FullSizeContentContainer>
        )}
        {content !== '' && title !== '' && (
          <>
            <LeftPartContainer>
              <Title>
                {title}
                {!!infoTooltip && (
                  <TooltipContainer>
                    <Tooltip text={infoTooltip} />
                  </TooltipContainer>
                )}
              </Title>
              <Content color={contentColor}>{content}</Content>
            </LeftPartContainer>
            {labelContent !== '' && (
              <Label
                labelTooltip={labelTooltip}
                background={background}
                color={color}
                type={type}
              >
                {labelContent}
              </Label>
            )}
            {!_.isEmpty(buttons) && (
              <ButtonsContainer>
                {buttons.map((button, index) => (
                  <FrancoButton
                    key={`button-${index}`}
                    icon={button.icon}
                    title={button.title}
                    render={button.render}
                  />
                ))}
              </ButtonsContainer>
            )}
          </>
        )}
      </Container>
    </ThemeProvider>
  );
};

Franco.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.objectOf(PropTypes.any),
  color: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  contentColor: PropTypes.string,
  background: PropTypes.string,
  width: PropTypes.string,
  labelTooltip: PropTypes.string,
  infoTooltip: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      render: PropTypes.func.isRequired,
    })
  ),
  hasBorder: PropTypes.bool,
};

Franco.defaultProps = {
  theme: null,
  color: null,
  background: null,
  type: 'plain',
  title: '',
  content: '',
  labelContent: '',
  contentColor: null,
  width: '240px',
  labelTooltip: '',
  infoTooltip: '',
  buttons: [],
  hasBorder: false,
};

export default Franco;
