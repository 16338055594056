import i18next from 'i18next';
import React, { useState } from 'react';

import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_ADD_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { ListView } from '@commons/utils/styledLibraryComponents';
import DisplayNumber from '@commons/DisplayNumber';
import EmptyState from '@commons/EmptyState';

import { ListViewContainer } from './styledComponents';

const getColumns = (hasMultipleBrands) => {
  const columns = [
    {
      id: 'name',
      name: i18next.t('GENERAL.NAME'),
      propertyKey: 'name',
      // The render method is necessary otherwise the text will overflow if the name is too long
      render: (item) => item,
    },
    {
      id: 'category',
      name: i18next.t('GENERAL.CATEGORY'),
      propertyKey: 'category',
      render: (item) => item || '-',
    },
    {
      id: 'subCategory',
      name: i18next.t('GENERAL.SUB_CATEGORY'),
      propertyKey: 'subCategory',
      render: (item) => item || '-',
    },
    {
      id: 'shelfLife',
      name: i18next.t('ADMIN.PRODUCTS.PRODUCT_SHELF_LIFE'),
      propertyKey: 'shelfLife',
      render: (item) => item || '-',
    },
    {
      id: 'priceWithTaxes',
      name: i18next.t('GENERAL.PRICE_TTC'),
      propertyKey: 'priceWithTaxes',
      render: (item) => <DisplayNumber number={item} displayCurrencyCode />,
    },
  ];

  if (hasMultipleBrands) {
    columns.splice(1, 0, {
      id: 'brandName',
      name: i18next.t('GENERAL.BRAND'),
      propertyKey: 'brandName',
      render: (item) => item || '-',
    });
  }

  return columns;
};

const AssociateProductsModal = (props) => {
  const { products, setSelectedProducts, hasMultipleBrands } = props;

  const [columns] = useState(getColumns(hasMultipleBrands));

  if (!products.length) {
    return (
      <EmptyState
        label={i18next.t('ADMIN.STORES.PRODUCTS_ASSOCIATION_MODAL_EMPTY_STATE')}
        labelColor={ENUM_COLORS.DARKEST}
        labelFont={ENUM_FONTS.H2}
      />
    );
  }

  return (
    <ListViewContainer>
      <ListView
        columns={columns}
        data={products}
        defaultMaxPerPage={100}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        forceEnableSelection={true}
        padding={'0px 24px 0px 24px'}
        setSelectedItems={setSelectedProducts}
      />
    </ListViewContainer>
  );
};

export const getAssociateProductsModal = ({
  products,
  selectedProducts,
  setSelectedProducts,
  handleProductsAssociation,
  hasMultipleBrands,
}) => {
  const actions = [GENERIC_MODAL_CANCEL_BUTTON()];

  if (products.length) {
    actions.push({
      ...GENERIC_MODAL_ADD_BUTTON(),
      isDisabled: !selectedProducts.length,
      handleClick: handleProductsAssociation,
    });
  }

  return {
    type: 'actionWhite',
    icon: '/images/inpulse/add-black-small.svg',
    isFullscreen: true,
    title: i18next.t('ADMIN.STORES.ASSOCIATE_PRODUCTS_MODAL_TITLE'),
    customPadding: '24px 0px 0px 0px',
    handleCloseCleanUp: () => {
      setSelectedProducts([]);
    },
    component: AssociateProductsModal,
    data: { products, selectedProducts, setSelectedProducts, hasMultipleBrands },
    actions,
  };
};
