import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  padding: 180px 0;

  background: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MainText = styled.div`
  width: 100%;
  text-align: center;

  color: ${(props) => props.theme.colors.primary};
  font: ${(props) => props.theme.fonts.normal};

  margin-top: 45px;
`;

export const Icon = styled.img`
  width: 200px;
`;
