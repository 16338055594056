import i18next from 'i18next';
import React from 'react';
import styled, { css } from 'styled-components';

import { InpulseLabel } from '@commons/DeepsightComponents';
import { numberToFixed } from '@commons/utils/format';
import DisplayNumber from '@commons/DisplayNumber';

import {
  tooltipByStoreAnalyticsTurnover,
  tooltipByStoreAnalyticsUnit,
} from '../../constants/tooltipText';

const LabelContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Label = styled.div`
  width: fit-content;
  height: 20px;

  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  color: #000000;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${(props) =>
    props.red &&
    css`
      color: #ef252a;
      font-weight: 700;
    `}

  ${(props) =>
    props.orange &&
    css`
      color: #ff9c40;
      font-weight: 700;
    `}
`;

const getColumnsTable = (
  currency,
  metric,
  allowedColumnsByKey,
  clientStoreName,
  isAllProductsSelected,
) => {
  const columns = [
    {
      id: 1,
      propertyKey: 'storeName',
      type: 'string',
      name: clientStoreName || '',
    },
    {
      id: 3,
      decimals: 0,
      propertyKey: metric === 'turnover' ? 'forecastedTurnover' : 'forecastedQuantity',
      type: metric === 'turnover' ? 'currency' : 'numeric',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_FORECAST'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        metric === 'turnover'
          ? tooltipByStoreAnalyticsTurnover.forecastedTurnover
          : tooltipByStoreAnalyticsUnit.forecastedQuantity,
      ),
      render: (item) => (
        <LabelContainer>
          <DisplayNumber
            displayCurrencyCode={metric === 'turnover'}
            number={item}
            withoutDecimals={true}
          />
        </LabelContainer>
      ),
    },
    {
      id: 4,
      decimals: 0,
      propertyKey: metric === 'turnover' ? 'stockedTurnover' : 'stockedQuantity',
      type: metric === 'turnover' ? 'currency' : 'numeric',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_STOCKS'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        metric === 'turnover'
          ? tooltipByStoreAnalyticsTurnover.stockedTurnover
          : tooltipByStoreAnalyticsUnit.stockedQuantity,
      ),
      render: (item) => (
        <LabelContainer>
          <DisplayNumber
            displayCurrencyCode={metric === 'turnover'}
            number={item}
            withoutDecimals={true}
          />
        </LabelContainer>
      ),
    },
    {
      id: 5,
      decimals: 0,
      propertyKey: metric === 'turnover' ? 'toProduceTurnover' : 'toProduceQuantity',
      type: metric === 'turnover' ? 'currency' : 'numeric',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_TO_PRODUCE'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        metric === 'turnover'
          ? tooltipByStoreAnalyticsTurnover.toProduceTurnover
          : tooltipByStoreAnalyticsUnit.toProduceQuantity,
      ),
      render: (item) => (
        <LabelContainer>
          <DisplayNumber
            displayCurrencyCode={metric === 'turnover'}
            number={item}
            withoutDecimals={true}
          />
        </LabelContainer>
      ),
    },
    {
      id: 6,
      decimals: 0,
      propertyKey: metric === 'turnover' ? 'producedTurnover' : 'producedQuantity',
      type: metric === 'turnover' ? 'currency' : 'numeric',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCED'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        metric === 'turnover'
          ? tooltipByStoreAnalyticsTurnover.producedTurnover
          : tooltipByStoreAnalyticsUnit.producedQuantity,
      ),
      render: (item) => (
        <LabelContainer>
          <DisplayNumber
            displayCurrencyCode={metric === 'turnover'}
            number={item}
            withoutDecimals={true}
          />
        </LabelContainer>
      ),
    },
    {
      id: 7,
      decimals: 0,
      propertyKey: metric === 'turnover' ? 'turnoverProductionRatio' : 'quantityProductionRatio',
      type: 'percentage',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCTION_RATIO'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        metric === 'turnover'
          ? tooltipByStoreAnalyticsTurnover.turnoverProductionRatio
          : tooltipByStoreAnalyticsUnit.quantityProductionRatio,
      ),
      render: (item) => {
        const valueToDisplay = numberToFixed(item, 0, '-', '%');

        if (item == null) {
          return (
            <LabelContainer>
              <Label>-</Label>
            </LabelContainer>
          );
        }

        if (item < 75 || item >= 125) {
          return (
            <LabelContainer>
              <InpulseLabel color={'red'} text={valueToDisplay} type={'secondary'} />
            </LabelContainer>
          );
        }

        if ((item >= 75 && item < 90) || (item > 110 && item < 125)) {
          return (
            <LabelContainer>
              <InpulseLabel color={'orange'} text={valueToDisplay} type={'secondary'} />
            </LabelContainer>
          );
        }

        return (
          <LabelContainer>
            <Label>{valueToDisplay}</Label>
          </LabelContainer>
        );
      },
    },
    {
      id: 8,
      decimals: 0,
      propertyKey: metric === 'turnover' ? 'loss' : 'lossQuantity',
      type: metric === 'turnover' ? 'currency' : 'numeric',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        metric === 'turnover'
          ? tooltipByStoreAnalyticsTurnover.loss
          : tooltipByStoreAnalyticsUnit.lossQuantity,
      ),
      render: (item) => (
        <LabelContainer>
          <DisplayNumber
            displayCurrencyCode={metric === 'turnover'}
            number={item}
            withoutDecimals={true}
          />
        </LabelContainer>
      ),
    },
    {
      id: 9,
      decimals: 0,
      propertyKey: metric === 'turnover' ? 'turnoverWasteRate' : 'quantityTurnoverWasteRate',
      type: 'percentage',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS_RATIO'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        metric === 'turnover'
          ? tooltipByStoreAnalyticsTurnover.turnoverWasteRate
          : tooltipByStoreAnalyticsUnit.quantityTurnoverWasteRate,
      ),
      render: (item, row) => {
        const valueToDisplay = numberToFixed(item, 0, '-', '%');
        const isTargetProductionWasteRateEnabled = allowedColumnsByKey['targetProductionWasteRate'];

        if (isTargetProductionWasteRateEnabled && metric === 'turnover') {
          if (item === null) {
            return (
              <LabelContainer>
                <Label>{valueToDisplay}</Label>
              </LabelContainer>
            );
          }
          if (parseInt(numberToFixed(item, 0)) === parseInt(row.targetProductionWasteRate)) {
            return (
              <LabelContainer>
                <InpulseLabel color={'green'} text={valueToDisplay} type={'secondary'} />
              </LabelContainer>
            );
          }
          if (
            parseInt(item) <= parseInt(row.targetProductionWasteRate) + 5 &&
            parseInt(item) >= parseInt(row.targetProductionWasteRate) - 5
          ) {
            return (
              <LabelContainer>
                <InpulseLabel color={'orange'} text={valueToDisplay} type={'secondary'} />
              </LabelContainer>
            );
          }
          return (
            <LabelContainer>
              <InpulseLabel color={'red'} text={valueToDisplay} type={'secondary'} />
            </LabelContainer>
          );
        }

        return (
          <LabelContainer>
            <Label>{valueToDisplay}</Label>
          </LabelContainer>
        );
      },
    },
    {
      id: 10,
      decimals: 0,
      propertyKey: metric === 'turnover' ? 'productionWasteRate' : 'quantityProductionWasteRate',
      type: 'percentage',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS_RATIO'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        metric === 'turnover'
          ? tooltipByStoreAnalyticsTurnover.productionWasteRate
          : tooltipByStoreAnalyticsUnit.quantityProductionWasteRate,
      ),
      render: (item, row) => {
        const valueToDisplay = numberToFixed(item, 0, '-', '%');
        const isTargetProductionWasteRateEnabled = allowedColumnsByKey['targetProductionWasteRate'];

        if (isTargetProductionWasteRateEnabled && metric === 'turnover') {
          if (item === null) {
            return (
              <LabelContainer>
                <Label>{valueToDisplay}</Label>
              </LabelContainer>
            );
          }
          if (parseInt(numberToFixed(item, 0)) === parseInt(row.targetProductionWasteRate)) {
            return (
              <LabelContainer>
                <InpulseLabel color={'green'} text={valueToDisplay} type={'secondary'} />
              </LabelContainer>
            );
          }
          if (
            parseInt(item) <= parseInt(row.targetProductionWasteRate) + 5 &&
            parseInt(item) >= parseInt(row.targetProductionWasteRate) - 5
          ) {
            return (
              <LabelContainer>
                <InpulseLabel color={'orange'} text={valueToDisplay} type={'secondary'} />
              </LabelContainer>
            );
          }
          return (
            <LabelContainer>
              <InpulseLabel color={'red'} text={valueToDisplay} type={'secondary'} />
            </LabelContainer>
          );
        }

        return (
          <LabelContainer>
            <Label>{valueToDisplay}</Label>
          </LabelContainer>
        );
      },
    },
    {
      id: 11,
      decimals: 0,
      propertyKey: metric === 'turnover' ? 'realTurnover' : 'realSales',
      type: metric === 'turnover' ? 'currency' : 'numeric',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_SALES'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        metric === 'turnover'
          ? tooltipByStoreAnalyticsTurnover.turnover
          : tooltipByStoreAnalyticsUnit.sales,
        { currency: currency.alphabeticCode },
      ),
      render: (item) => (
        <LabelContainer>
          <DisplayNumber
            displayCurrencyCode={metric === 'turnover'}
            number={item}
            withoutDecimals={true}
          />
        </LabelContainer>
      ),
    },
    {
      id: 10,
      decimals: 0,
      name: i18next.t('PRODUCTION.BY_DATE.COLUMN_REAL_SALES'),
      tooltipText: i18next.t('PRODUCTION.BY_DATE.COLUMN_REAL_SALES_TOOLTIP'),
      type: 'currency',
      filterType: 'numeric',
      propertyKey: 'realTurnoverInclTax',
      render: (item) =>
        metric === 'turnover' ? (
          <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
        ) : (
          <LabelContainer>-</LabelContainer>
        ),
    },
    {
      id: 12,
      decimals: 0,
      propertyKey: 'assortmentRate',
      type: 'percentage',
      name: i18next.t('HOME.ACTIVITY_REPORT_LABEL_ASSORTMENT_RATE'),
      filterType: 'numeric',
      tooltipText: i18next.t('PRODUCTION.BY_STORE.ASSORTMENT_RATE_TOOLTIP'),
      render: (item) => (
        <LabelContainer>
          <Label>{numberToFixed(item, 0, '-', '%')}</Label>
        </LabelContainer>
      ),
    },
    {
      id: 13,
      decimals: 0,
      propertyKey: 'initialAssortmentRate',
      type: 'percentage',
      name: i18next.t('PRODUCTION.REAL_TIME.INITIAL_ASSORTMENT_RATE'),
      filterType: 'numeric',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.INITIAL_ASSORTMENT_RATE_TOOLTIP'),
      render: (item) => (
        <LabelContainer>
          <Label>{numberToFixed(item, 0, '-', '%')}</Label>
        </LabelContainer>
      ),
    },
    {
      id: 14,
      decimals: 0,
      propertyKey: 'assortmentGapRate',
      type: 'percentage',
      name: i18next.t('PRODUCTION.REAL_TIME.INITIAL_ASSORTMENT_GAP'),
      filterType: 'numeric',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.INITIAL_ASSORTMENT_GAP_TOOLTIP'),
      render: (item) => (
        <LabelContainer>
          <Label>{numberToFixed(item, 0, '-', '%')}</Label>
        </LabelContainer>
      ),
    },
  ];

  if (metric === 'turnover') {
    columns.splice(9, 0, {
      id: 9,
      decimals: 0,
      propertyKey: 'targetProductionWasteRate',
      type: 'percentage',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_TARGET_LOSS_RATIO'),
      filterType: 'numeric',
      render: (item) => (
        <LabelContainer>
          <Label>{numberToFixed(item, 0, '-', '%')}</Label>
        </LabelContainer>
      ),
    });
  }

  if (isAllProductsSelected) {
    columns.splice(13, 0, {
      id: 15,
      decimals: 0,
      type: 'numeric',
      filterType: 'numeric',
      propertyKey: 'nbProducedReferences',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_NB_REFERENCE_PRODUCED'),
      tooltipText: i18next.t('PRODUCTION.BY_STORE.LABEL_NUMBER_PRODUCED_REFERENCE'),
    });
  }

  return columns.filter((column) => allowedColumnsByKey[column.propertyKey]);
};

export default getColumnsTable;
