import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, openMediumModal } from '@actions/modal';
import { showConfirmationMessage, showErrorMessage } from '@actions/messageconfirmation';
import { updateIsSynchronizingCashierProductsStatus } from '@actions/product';

import { ADMIN_FEATURE_PATHS } from '@commons/utils/featurePaths';
import { ConfirmationModal } from '@commons/Modals/ConfirmationModal';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_NEXT_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { ListView } from '@commons/utils/styledLibraryComponents';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';
import CashierProductAssociationModal from './components/CashierProductAssociationModal';
import EmptyCashierProducts from './components/EmptyCashierProducts';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import {
  ENUM_QUERY_PARAMS,
  DEFAULT_QUERY_PARAMS,
  useListViewQueryParams,
} from '@hooks/useListViewQueryParams';

import { cashierProductService } from '@services/cashierProduct';
import { getCashierConfigByClientId } from '@services/cashierConfig';
import { getClientInfo } from '@selectors/client';

import DeepsightFiltersButton from '@orders/components/FilterButton';

import { CHOICES_DROPDOWN_ACTIVE } from '@admin/utils/DropdownItems';

import {
  Container,
  FilterButtonContainer,
  LastSyncContainer,
  LastSyncTitle,
  LastSyncDate,
} from './styledComponents';
import { exportCashierProducts } from './common/exportCashierProducts';
import { getActions, getRowActions } from './common/getActions';
import { getColumns } from './common/column';
import { SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING } from './common/constants';
import MODES from './components/EmptyCashierProducts/constants';

const TIMEOUT_GET_SYNC_STATUS = 5000; // in ms

const DEFAULT_FILTERS_ID = [1, 4, 6];

const ORDER_TYPE = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

export const fetchPaginatedCashierProductsByClientId = async (
  clientId,
  applyFilter,
  queryParams,
  getAll = false,
) =>
  cashierProductService.getPaginatedCashierProductsByClientId(
    clientId,
    applyFilter,
    queryParams,
    getAll,
  );

const formatCashierProducts = async (cashierProducts, lastSync) => {
  const formattedCashierProducts = cashierProducts.map((cashierProduct) => {
    const isCashierProductMapped = !!cashierProduct.product_cashier_product_mapping_id;

    const wasRecentlySync = moment(cashierProduct.created_at).diff(moment(lastSync), 'day') === 0;
    const hasNeverBeenUpdated = moment(cashierProduct.created_at).isSame(cashierProduct.updated_at);

    const isCashierProductNew = hasNeverBeenUpdated && wasRecentlySync && !isCashierProductMapped;

    const tags = [];
    if (cashierProduct.tags) {
      cashierProduct.tags.forEach((tag) => {
        tags.push(tag.replace('[', '').replace(']', ''));
      });
    }

    return {
      ...cashierProduct,
      mapped: isCashierProductMapped,
      new: isCashierProductNew,
      ...(cashierProduct.tags ? { tags } : {}),
    };
  });

  return formattedCashierProducts;
};

const renderContent = (props) => {
  const {
    dataColumns,
    filteredCashierProducts,
    countAllFilteredCashierProducts,
    CustomEmptyState,
    userLanguageCode,
    isLoading,
    actions,
    rowActions,
    setSelectedItems,
    applyFilters,
    setApplyFilters,
    advancedFilters,
    setAdvancedFilters,
    filters,
    setFilters,
    columnsFilterList,
    activeKeysForActiveDropdown,
    setActiveKeysForActiveDropdown,
    mappedKeysForMappedDropdown,
    setMappedKeysForMappedDropdown,
    hiddenKeysForHiddenDropdown,
    setHiddenKeysForHiddenDropdown,
    DEFAULT_KEYS_DROPDOWN_ACTIVE,
    CHOICES_DROPDOWN_MAPPED,
    DEFAULT_KEYS_DROPDOWN_MAPPED,
    CHOICES_DROPDOWN_HIDDEN,
    DEFAULT_KEYS_DROPDOWN_HIDDEN,
    lastSyncDate,
    listViewRef,
    queryParams,
    setQueryParams,
    listViewQueryParams,
    setListViewQueryParams,
  } = props;

  return (
    <ListView
      actions={actions}
      columns={dataColumns}
      countElements={countAllFilteredCashierProducts}
      data={filteredCashierProducts}
      defaultCurrentPage={listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE]}
      defaultMaxPerPage={listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]}
      defaultOrderBy={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]}
      defaultOrderType={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]}
      defaultSearchInput={listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH]}
      handleCurrentPageChange={(input) =>
        setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](input)
      }
      handleMaxPerPageChange={(input) =>
        setListViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE](input)
      }
      handleOrderByChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY](input)}
      handleOrderTypeChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE](input)}
      handleSearchInputChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.SEARCH](input)}
      hideAllPerPageOption={true}
      isLoading={isLoading}
      languageCode={userLanguageCode}
      minActionsInActionsDropdown={1}
      padding={'24px 24px 0px 24px'}
      placeholderShape={i18next.t('GENERAL.SEARCH')}
      queryParams={queryParams}
      ref={listViewRef}
      renderEmptyState={() => <CustomEmptyState />}
      renderFilterButton={() => (
        <FilterButtonContainer>
          <DeepsightFiltersButton
            advancedFilters={advancedFilters}
            applyFilters={applyFilters}
            columnsFilterList={columnsFilterList}
            customMultipleDropDowns={[
              {
                id: 'status',
                icon: '/images/icon-dropdown-grey.svg',
                list: CHOICES_DROPDOWN_ACTIVE,
                defaultSelectedItems: DEFAULT_KEYS_DROPDOWN_ACTIVE,
                selectedItems: activeKeysForActiveDropdown,
                setSelectedItems: (selectedItems) => setActiveKeysForActiveDropdown(selectedItems),
                allMessage: i18next.t('GENERAL.ACTIVES_INACTIVES'),
              },
              {
                id: 'associate',
                icon: '/images/inpulse/link-black-small.svg',
                list: CHOICES_DROPDOWN_MAPPED,
                defaultSelectedItems: DEFAULT_KEYS_DROPDOWN_MAPPED,
                selectedItems: mappedKeysForMappedDropdown,
                setSelectedItems: (selectedItems) => setMappedKeysForMappedDropdown(selectedItems),
                allMessage: i18next.t('GENERAL.LINKED_OR_NOT'),
              },
              {
                id: 'include',
                icon: '/images/inpulse/eye-black-small.svg',
                list: CHOICES_DROPDOWN_HIDDEN,
                defaultSelectedItems: DEFAULT_KEYS_DROPDOWN_HIDDEN,
                selectedItems: hiddenKeysForHiddenDropdown,
                setSelectedItems: (selectedItems) => setHiddenKeysForHiddenDropdown(selectedItems),
                allMessage: i18next.t('GENERAL.EXCLUDED_OR_NOT'),
              },
            ]}
            filters={filters}
            isLoading={isLoading}
            readOnly={isLoading}
            setAdvancedFilters={setAdvancedFilters}
            setApplyFilters={setApplyFilters}
            setFilters={setFilters}
            textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
          />
          {!_.isEmpty(lastSyncDate) && (
            <LastSyncContainer>
              <LastSyncTitle>{i18next.t('GENERAL.LAST_SYNC')}</LastSyncTitle>
              <LastSyncDate>
                {i18next.t('ADMIN.CASHIER_PRODUCTS.LAST_SYNC_DATE', {
                  date: lastSyncDate.date,
                  hour: lastSyncDate.hour,
                })}
              </LastSyncDate>
            </LastSyncContainer>
          )}
        </FilterButtonContainer>
      )}
      rowActions={rowActions}
      setSelectedItems={(items) => setSelectedItems(items)}
      onQueryParamsChange={setQueryParams}
    />
  );
};

const CashierProducts = (props) => {
  const {
    user,
    isSynchronizingCashierProducts,
    updateIsSynchronizingCashierProductsStatus,
    pageLoading,
    pageLoaded,
    showMessage,
    client: { clientId, lastSync },
    currency,
    match: { path },
    history,
    openGenericModal,
  } = props;

  const DEFAULT_KEYS_DROPDOWN_ACTIVE = [CHOICES_DROPDOWN_ACTIVE[0]];

  const CHOICES_DROPDOWN_MAPPED = [
    { id: 3, name: i18next.t('GENERAL.LINKED_PLURAL'), propertyKey: 'mapped', itemValue: true },
    { id: 4, name: i18next.t('GENERAL.UNLINKED'), propertyKey: 'mapped', itemValue: false },
  ];

  const DEFAULT_KEYS_DROPDOWN_MAPPED = [
    { id: 4, name: i18next.t('GENERAL.UNLINKED'), propertyKey: 'mapped', itemValue: false },
  ];

  const CHOICES_DROPDOWN_HIDDEN = [
    { id: 5, name: i18next.t('GENERAL.EXCLUDED'), propertyKey: 'hidden', itemValue: true },
    { id: 6, name: i18next.t('GENERAL.UNEXCLUDED'), propertyKey: 'hidden', itemValue: false },
  ];

  const DEFAULT_KEYS_DROPDOWN_HIDDEN = [
    { id: 6, name: i18next.t('GENERAL.UNEXCLUDED'), propertyKey: 'hidden', itemValue: false },
  ];

  const userLanguageCode = _.get(user, 'lnkLanguageAccountrel.code', 'fr');

  const listViewRef = useRef();

  const [tags, setTags] = useState([]);
  const [filteredCashierProducts, setFilteredCashierProducts] = useState([]);
  const [countAllFilteredCashierProducts, setCountAllFilteredCashierProducts] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [actions, setActions] = useState([]);
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [columnsFilterList, setColumnsFilterList] = useState([]);
  const [lastSyncDate, setLastSyncDate] = useState({});
  const [shouldRetrieveData, setShouldRetrieveData] = useState(true);
  const [hasRetrieveData, setHasRetrieveData] = useState(false);
  const [cashierConnexions, setCashierConnexions] = useState([]);

  const [hasActiveNotHiddenCashierProducts, setHasActiveNotHiddenCashierProducts] = useState(true);

  const [queryParams, setQueryParams] = useState({
    skip: 0,
    limit: 20,
    params: '',
    search: '',
    orderType: 'asc',
    orderBy: 'name',
    selectedFilters: {
      findActive: 'true',
      findHidden: 'false',
      findMapped: 'false',
    },
  });

  const [activeKeysForActiveDropdown, setActiveKeysForActiveDropdown] = useState(
    DEFAULT_KEYS_DROPDOWN_ACTIVE,
  );
  const [mappedKeysForMappedDropdown, setMappedKeysForMappedDropdown] = useState(
    DEFAULT_KEYS_DROPDOWN_MAPPED,
  );
  const [hiddenKeysForHiddenDropdown, setHiddenKeysForHiddenDropdown] = useState(
    DEFAULT_KEYS_DROPDOWN_HIDDEN,
  );

  const activeFilters = [
    ...activeKeysForActiveDropdown,
    ...mappedKeysForMappedDropdown,
    ...hiddenKeysForHiddenDropdown,
  ];

  const isDefaultFilter =
    DEFAULT_FILTERS_ID.every((filterId) =>
      activeFilters.some((activeFilter) => activeFilter.id === filterId),
    ) &&
    (!advancedFilters || !advancedFilters.length);

  const [isLoading, setIsloading] = useState(true);

  const [clientLastSync, setClientLastSync] = useState(lastSync);

  const [listViewQueryParams, setListViewQueryParams] = useListViewQueryParams();

  useEffect(() => {
    if (!isSynchronizingCashierProducts) {
      return;
    }

    const getClientInterval = setInterval(() => {
      (async () => {
        try {
          const syncStatus = await cashierProductService.getCashierProductSyncStatus(clientId);

          if (syncStatus.state === 'SUCCESS') {
            setShouldRetrieveData(true);
            setClientLastSync(lastSync);
            updateIsSynchronizingCashierProductsStatus(false);
            props.showMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.SYNC_STATUS_SUCCESS'), 'success');
          }

          if (syncStatus.state === 'FAILURE' || syncStatus.state === 'REVOKED') {
            updateIsSynchronizingCashierProductsStatus(false);
            props.showMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.SYNC_STATUS_ERROR'), 'error');
          }
        } catch (error) {
          updateIsSynchronizingCashierProductsStatus(false);
          props.showMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.SYNC_STATUS_ERROR'), 'error');
        }
      })();
    }, TIMEOUT_GET_SYNC_STATUS);

    return () => clearInterval(getClientInterval);
  }, [isSynchronizingCashierProducts]);

  const dataColumns = getColumns();

  const updateColumnsFilterList = () =>
    dataColumns.reduce((result, column) => {
      if (!column.filterType) {
        return result;
      }

      const item = { ...column };

      if (column.filterType === 'string') {
        const choices =
          column.propertyKey === 'tags'
            ? tags
            : column.propertyKey === 'cashier_connection'
            ? cashierConnexions
            : filteredCashierProducts.reduce((list, cashierProduct, key) => {
                if (cashierProduct[column.propertyKey]) {
                  cashierProduct[column.propertyKey].forEach((elem, index) => {
                    if (!list[elem]) {
                      list[elem] = {
                        id: index + key,
                        name: elem,
                        itemValue: elem,
                        [column.propertyKey]: elem,
                      };
                    }
                  });
                }
                return list;
              }, {});

        if (!Object.keys(choices).length) {
          return result;
        }

        item.list = sortArrayOfObjectsAlphabetically(Object.values(choices), 'name');
      }

      result.push(item);

      return result;
    }, []);

  useEffect(() => {
    setColumnsFilterList(updateColumnsFilterList());
  }, [tags, filteredCashierProducts, cashierConnexions]);

  const getQueryParamsFetchRequest = () => {
    const formattedMaxPerPage =
      Number.isInteger(listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]) &&
      listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE] > 0
        ? listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]
        : DEFAULT_QUERY_PARAMS.MAX_PER_PAGE;

    const formattedCurrentPage =
      listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE] > 0
        ? (listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE] - 1) *
          (listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE] || DEFAULT_QUERY_PARAMS.MAX_PER_PAGE)
        : (DEFAULT_QUERY_PARAMS.CURRENT_PAGE - 1) * DEFAULT_QUERY_PARAMS.MAX_PER_PAGE;

    const columnPropertyKeys = dataColumns.map((column) => column.propertyKey);

    const formattedOrderBy = columnPropertyKeys.includes(
      listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY],
    )
      ? listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]
      : columnPropertyKeys[0];

    const formattedOrderType =
      listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE] === ORDER_TYPE.ASCENDING ||
      listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE] === ORDER_TYPE.DESCENDING
        ? listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]
        : DEFAULT_QUERY_PARAMS.ORDER_TYPE;

    return {
      search: listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH] || DEFAULT_QUERY_PARAMS.SEARCH,
      skip: formattedCurrentPage,
      limit: formattedMaxPerPage,
      orderBy: formattedOrderBy,
      orderType: formattedOrderType,
      params: queryParams.params,
    };
  };

  const loadPaginatedCashierProducts = async () => {
    setIsloading(true);

    try {
      const cashierProducts = await fetchPaginatedCashierProductsByClientId(
        clientId,
        queryParams.selectedFilters,
        getQueryParamsFetchRequest(),
      );

      const formattedCashierProducts = await formatCashierProducts(
        cashierProducts.cashierProducts,
        clientLastSync,
      );

      setFilteredCashierProducts(formattedCashierProducts);
      setCountAllFilteredCashierProducts(cashierProducts.count);
    } catch {
      showErrorMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.LOAD_ERROR'));
    } finally {
      setIsloading(false);
      setHasRetrieveData(true);
    }
  };

  const checkCountActiveNotHiddenCashierProducts = async () => {
    const { count } = await fetchPaginatedCashierProductsByClientId(
      clientId,
      queryParams.selectedFilters,
      {
        search: '',
        skip: 0,
        limit: 1,
        orderBy: 'name',
        orderType: DEFAULT_QUERY_PARAMS.ORDER_TYPE,
      },
    );

    setHasActiveNotHiddenCashierProducts(count >= 1);
  };

  useEffect(() => {
    if (!shouldRetrieveData && hasRetrieveData) {
      return;
    }

    (async function loadData() {
      await loadPaginatedCashierProducts();

      await checkCountActiveNotHiddenCashierProducts();

      setShouldRetrieveData(false);
    })();
  }, [shouldRetrieveData]);

  useEffect(() => {
    (async function loadTags() {
      const retrievedTags = await cashierProductService.getCashierProductTagsByClientId(clientId);

      setTags(retrievedTags.map((item, index) => ({ id: index, name: item })));
    })();

    (async function loadCashierConnexions() {
      try {
        const fetchedCashierConnexions = await getCashierConfigByClientId(clientId);

        const formattedCashierConnexions = fetchedCashierConnexions.map((item, index) => ({
          id: index,
          name: item.name,
        }));

        setCashierConnexions(formattedCashierConnexions);
      } catch (error) {
        showMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.CASHIER_CONFIG_FETCH_ERROR'), 'error');
      }
    })();
  }, []);

  useEffect(() => {
    const formattedDate = moment(clientLastSync);

    setLastSyncDate({ date: formattedDate.format('DD/MM'), hour: formattedDate.format('HH:mm') });
  }, [clientLastSync]);

  const handleShouldRetrieveData = () => {
    setShouldRetrieveData(true);
  };

  const handleCashierProductModal = (selectedItems, setShouldRetrieveData, openModal) => {
    const params = {
      component: CashierProductAssociationModal,
      cashierProducts: selectedItems,
      handleShouldRetrieveData,
      setSelectedItems,
      customStyle: {
        minWidth: '850px',
      },
    };
    openModal(params);
  };

  const handleCashierProductVisibility = async (selectedCashierProduct) => {
    const hidden = selectedCashierProduct.hidden ? false : true;

    pageLoading();

    try {
      await cashierProductService.updateCashierProduct({ id: selectedCashierProduct.id, hidden });

      setShouldRetrieveData(true);

      showMessage(
        i18next.t(
          hidden
            ? 'ADMIN.CASHIER_PRODUCTS.EXCLUDED_SUCCESS'
            : 'ADMIN.CASHIER_PRODUCTS.INCLUDED_SUCCESS',
        ),
      );
    } catch (error) {
      showMessage(
        i18next.t('ADMIN.CASHIER_PRODUCTS.UPDATE_PRODUCT_ERROR', {
          cashierProduct: selectedCashierProduct.name,
        }),
        'error',
      );
    } finally {
      pageLoaded();
    }
  };

  const handleCashierProductsVisibility = async (selectedCashierProducts, hidden) => {
    pageLoading();

    try {
      const formattedCashierProductsToUpdate = selectedCashierProducts.map(({ id }) => ({
        id,
        hidden,
      }));

      await cashierProductService.updateCashierProductByBatch(formattedCashierProductsToUpdate);

      setShouldRetrieveData(true);

      let message = '';

      if (hidden) {
        message =
          selectedCashierProducts.length > 1
            ? i18next.t('ADMIN.CASHIER_PRODUCTS.EXCLUDED_SUCCESS_PLURAL')
            : i18next.t('ADMIN.CASHIER_PRODUCTS.EXCLUDED_SUCCESS');
      } else {
        message =
          selectedCashierProducts.length > 1
            ? i18next.t('ADMIN.CASHIER_PRODUCTS.INCLUDED_SUCCESS_PLURAL')
            : i18next.t('ADMIN.CASHIER_PRODUCTS.INCLUDED_SUCCESS');
      }

      showMessage(message);

      setSelectedItems([]);
    } catch (error) {
      showMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.UPDATE_ERROR'), 'error');
    } finally {
      pageLoaded();
    }
  };

  const handleExport = async (selectedCashierProducts) => {
    if (!!selectedCashierProducts) {
      return exportCashierProducts({ selectedCashierProducts, currency });
    }

    const cashierProductsToExport = await fetchPaginatedCashierProductsByClientId(
      clientId,
      queryParams.selectedFilters,
      getQueryParamsFetchRequest(),
      true,
    );

    const formattedCashierProducts = await formatCashierProducts(
      cashierProductsToExport.cashierProducts,
      clientLastSync,
    );

    return exportCashierProducts({ selectedCashierProducts: formattedCashierProducts, currency });
  };

  const getModalConfig = () => ({
    type: 'warning',
    title: i18next.t('ADMIN.CASHIER_PRODUCTS.AUTO_MAPPING_MODAL_TITLE'),
    icon: '/images/inpulse/bolt-white-small.svg',
    width: '542px',
    height: 'auto',
    component: ConfirmationModal,
    data: {
      content: i18next.t('ADMIN.CASHIER_PRODUCTS.AUTO_MAPPING_MODAL_CONTENT', {
        significanceThreshold: SIGNIFICANCE_THRESHOLD_SIMILARITY_SCORE_MAPPING,
      }),
    },
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_NEXT_BUTTON(),
        handleClick: () => {
          history.push(ADMIN_FEATURE_PATHS.ADMIN_CASHIER_CASHIER_PRODUCTS_MAPPING_WITH_PRODUCTS);
        },
      },
    ],
  });

  const handleAutomaticCashierProductMapping = () => {
    const params = getModalConfig();

    openGenericModal(params);
  };

  const updateListActions = () => {
    setActions(
      getActions({
        clientId,
        syncCashierProduct,
        isSynchronizingCashierProducts,
        updateIsSynchronizingCashierProductsStatus,
        showMessage,
        selectedItems,
        handleCashierProductModal,
        setShouldRetrieveData,
        openModal: props.openModal,
        handleCashierProductsVisibility,
        handleCashierProductVisibility,
        handleExport,
        filteredCashierProducts,
        user,
        handleAutomaticCashierProductMapping,
        hasActiveNotHiddenCashierProducts,
      }),
    );
  };

  const rowActions = getRowActions({
    handleCashierProductModal,
    setShouldRetrieveData,
    openModal: props.openModal,
    handleCashierProductVisibility,
    handleExport,
  });

  useEffect(() => {
    updateListActions();
  }, [
    countAllFilteredCashierProducts,
    hasActiveNotHiddenCashierProducts,
    selectedItems,
    isSynchronizingCashierProducts,
  ]);

  const syncCashierProduct = async (clientId) => {
    try {
      await cashierProductService.syncCashierProduct(clientId);

      updateIsSynchronizingCashierProductsStatus(true);

      props.showMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.RESTART_SYNC_SUCCESS'), 'success');
    } catch (error) {
      props.showMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.RESTART_SYNC_ERROR'), 'error');
    }
  };

  const CustomEmptyState = () => {
    if (isLoading) {
      return null;
    }

    const isEmptySearchInput = _.isEmpty(_.get(listViewQueryParams, 'search', null));
    const emptyFilteredCashierProducts = !filteredCashierProducts.length;

    if (isDefaultFilter && emptyFilteredCashierProducts && isEmptySearchInput) {
      return <EmptyCashierProducts mode={MODES.EMPTY} />;
    } else if (emptyFilteredCashierProducts && isEmptySearchInput) {
      return <EmptyCashierProducts mode={MODES.NOT_FOUND} />;
    }

    return <EmptyState />;
  };

  const applyFilterSelected = () => {
    const findActive =
      activeKeysForActiveDropdown.length > 1
        ? 'both'
        : _.head(activeKeysForActiveDropdown).filterValue.toString();

    const findMapped =
      mappedKeysForMappedDropdown.length > 1
        ? 'both'
        : _.head(mappedKeysForMappedDropdown).itemValue.toString();

    const findHidden =
      hiddenKeysForHiddenDropdown.length > 1
        ? 'both'
        : _.head(hiddenKeysForHiddenDropdown).itemValue.toString();

    return { findActive, findMapped, findHidden };
  };

  useEffect(() => {
    if (!applyFilters || !listViewRef || !listViewRef.current) {
      return;
    }

    if ((!advancedFilters || !advancedFilters.length) && applyFilters) {
      setQueryParams({
        ...queryParams,
        selectedFilters: applyFilterSelected(),
        params: '',
        skip: 0,
      });

      setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](1);

      listViewRef.current.resetPagination();
      return;
    }

    const params = advancedFilters.reduce((result, { getQueryParam, propertyKey, value }) => {
      result += getQueryParam(propertyKey, value);

      return result;
    }, '');

    setQueryParams({
      ...queryParams,
      selectedFilters: applyFilterSelected(),
      params,
      skip: 0,
    });

    setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](1);

    listViewRef.current.resetPagination();
  }, [advancedFilters, applyFilters]);

  useEffect(() => {
    if (!shouldRetrieveData) {
      setShouldRetrieveData(true);
    }
  }, [queryParams]);

  return (
    <>
      <NavigationBreadCrumb featurePath={path} />
      <Container>
        {renderContent({
          ...props,
          dataColumns,
          filteredCashierProducts,
          countAllFilteredCashierProducts,
          CustomEmptyState,
          userLanguageCode,
          isLoading,
          actions,
          rowActions,
          setSelectedItems,
          applyFilters,
          setApplyFilters,
          advancedFilters,
          setAdvancedFilters,
          filters,
          setFilters,
          columnsFilterList,
          activeKeysForActiveDropdown,
          setActiveKeysForActiveDropdown,
          mappedKeysForMappedDropdown,
          setMappedKeysForMappedDropdown,
          hiddenKeysForHiddenDropdown,
          setHiddenKeysForHiddenDropdown,
          DEFAULT_KEYS_DROPDOWN_ACTIVE,
          CHOICES_DROPDOWN_MAPPED,
          DEFAULT_KEYS_DROPDOWN_MAPPED,
          CHOICES_DROPDOWN_HIDDEN,
          DEFAULT_KEYS_DROPDOWN_HIDDEN,
          lastSyncDate,
          listViewRef,
          queryParams,
          setQueryParams,
          listViewQueryParams,
          setListViewQueryParams,
        })}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  isSynchronizingCashierProducts: state.baseReducer.isSynchronizingCashierProducts,
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  updateIsSynchronizingCashierProductsStatus: (isSynchronizingCashierProducts) => {
    dispatch(updateIsSynchronizingCashierProductsStatus(isSynchronizingCashierProducts));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CashierProducts);
