import i18next from 'i18next';
import React, { useState } from 'react';

import { ListView } from '@commons/utils/styledLibraryComponents';

import { Container } from './styledComponents';

const StoreAdditionModal = (props) => {
  const { notMappedStoreEntityMappings, setSelectedStoreEntityMappings } = props;

  const [listViewColumns] = useState([
    {
      id: 'storeName',
      propertyKey: 'storeName',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_NAME'),
      filterType: 'string',
    },
    {
      id: 'storeCity',
      propertyKey: 'storeCity',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_CITY'),
      filterType: 'string',
    },
    {
      id: 'storeCountry',
      propertyKey: 'storeCountry',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_COUNTRY'),
      filterType: 'string',
    },
  ]);

  return (
    <Container>
      <ListView
        columns={listViewColumns}
        data={notMappedStoreEntityMappings}
        defaultOrderBy={'storeName'}
        defaultOrderType={'asc'}
        setSelectedItems={setSelectedStoreEntityMappings}
        disableFooter
        forceEnableSelection
      />
    </Container>
  );
};

export default StoreAdditionModal;
