import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { ListView } from '@commons/utils/styledLibraryComponents';

import { StoreListViewContainer } from '@admin/suppliers/suppliers/SupplierProfileModal/styledComponents';

import { getColumns } from './getColumns';

const SelectStoreToCloneModal = (props) => {
  const { storeToCloneData, setStoreToCloneData, availableStoresToClone } = props;

  const [storesToDisplay, setStoresToDisplay] = useState([]);

  const columns = getColumns();

  const handleSelectedStore = (item, currentStores) => {
    if (!item) {
      return;
    }

    const storesWithIsRowSelected = currentStores.map((store) => {
      if (store.id === item.id) {
        return { ...store, isRowSelected: true };
      }

      return { ...store, isRowSelected: false };
    });

    setStoresToDisplay(storesWithIsRowSelected);
  };

  const getStoresToDisplay = async () => {
    handleSelectedStore(storeToCloneData, availableStoresToClone);
  };

  useEffect(() => {
    getStoresToDisplay();
  }, []);

  return (
    <StoreListViewContainer>
      <ListView
        columns={columns}
        data={storesToDisplay}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        disableFooter={true}
        disableMultipleSelection={true}
        forceEnableSelection={true}
        setSelectedItems={(items) => {
          const selectedItem = items[0];

          if (!_.isEqual(selectedItem, storeToCloneData)) {
            setStoreToCloneData(selectedItem);
            handleSelectedStore(selectedItem, storesToDisplay);
          }
        }}
      />
    </StoreListViewContainer>
  );
};

export default SelectStoreToCloneModal;
