import _ from 'lodash';
import i18next from 'i18next';

import { convertSPPQuantityInMasterUnit } from '@orders/utils/computePackagingToSupplierProduct';

export const buildPackagingFormattedName = (supplierProduct) => {
  const supplierProductPackagingUsedInOrder = _.find(
    supplierProduct.packagings,
    (packaging) => packaging.isUsedInOrder,
  );

  const masterSPP = _.find(
    supplierProduct.packagings,
    (packaging) => packaging.parentSupplierProductPackagingId === null,
  );

  if (!masterSPP || !_.get(masterSPP, 'unit', null)) {
    return '';
  }

  const packagingUnit =
    masterSPP.unit === 'unit' ? i18next.t('GENERAL.UNIT').toLowerCase() : masterSPP.unit;

  const quantityInMasterUnit = convertSPPQuantityInMasterUnit(
    supplierProductPackagingUsedInOrder.id,
    supplierProduct.packagings,
  );

  const packagingFormattedName = `${supplierProductPackagingUsedInOrder.name} (${quantityInMasterUnit} ${packagingUnit})`;

  return packagingFormattedName;
};
