import PropTypes from 'prop-types';
import React from 'react';

import { Button, RadioButton, Tooltip } from '../utils/styledLibraryComponents';

import { LastAction } from '../LastAction';

import { Container, TitleContainer, ContentContainer, ButtonContainer } from './styledComponents';

const WhiteCard = (props) => {
  const {
    title,
    content,
    renderContent,
    contentColor,
    renderLastAction,
    buttonFlexDirection,
    maxWidth,
    width,
    titleFont,
    tooltipText,
    customStyle,
  } = props;

  return (
    <Container maxWidth={maxWidth} style={customStyle} width={width}>
      {(!!title || !!tooltipText) && (
        <TitleContainer hasTooltip={!!tooltipText} titleFont={titleFont} bold>
          {title}
          {!!tooltipText && <Tooltip text={tooltipText} />}
        </TitleContainer>
      )}
      {content && <ContentContainer contentColor={contentColor}>{content}</ContentContainer>}
      <ButtonContainer flexDirection={buttonFlexDirection}>
        {renderContent}
        {!!renderLastAction && renderLastAction}
      </ButtonContainer>
    </Container>
  );
};

WhiteCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  renderContent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(Button),
    PropTypes.instanceOf(RadioButton),
  ]),
  contentColor: PropTypes.string,
  renderLastAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
    PropTypes.instanceOf(LastAction),
  ]),
  buttonFlexDirection: PropTypes.oneOf(['column', 'row']),
  maxWidth: PropTypes.string,
  width: PropTypes.string,
  titleFont: PropTypes.string,
  tooltipText: PropTypes.string,
  customStyle: PropTypes.object,
};

WhiteCard.defaultProps = {
  renderLastAction: null,
  buttonFlexDirection: 'column',
};

export default WhiteCard;
