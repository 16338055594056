import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 72px);

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const MainText = styled.div`
  width: 100%;
  text-align: center;

  font-size: 22px;
  font-weight: 600;
  line-height: 22px;

  color: #000000;

  margin-bottom: 25px;
`;

export const SubText = styled.div`
  width: 100%;
  text-align: center;

  font-size: 18px;
  line-height: 20px;

  color: #000000;
`;

export const Icon = styled.img`
  width: 400px;
`;
