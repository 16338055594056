import { isEmpty } from 'lodash';

import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';

import centralService from '@services/central';
import recipeService from '@services/recipe';

import utilsMethods from '../utils';

/**********************/
/* Fetch Data Methods */
/**********************/

/**
 * Retrieve the information related to recipe associated to the given recipeId
 *
 * @param {String} recipeId                         - The recipe id on which retrieve the data
 * @param {Function} onRecipeChange                 - Method to set the local state of the recipe
 * @param {Function} onCompositionSettingsChange    - Method to set the local state of the compositionSettings
 * @param {Function} onAllergensChange              - Method to set the local state of the allergens
 *
 * @returns {void}
 */
export async function fetchRecipe(
  recipeId,
  onRecipeChange,
  onCompositionSettingsChange,
  onAllergensChange,
) {
  const result = await recipeService.getRecipe(recipeId);

  onRecipeChange(result.recipe);
  onCompositionSettingsChange(utilsMethods.sortCompositionSettings(result.compositionSettings));
  onAllergensChange(result.allergens);
}

/**
 * Retrieve the information related to the recipes by categories associated to the given clientId
 *
 * @param {String} clientId                     - The client id on which retrieve the data
 * @param {Function} onRecipesAvailableChange   - Method to set the local state of the recipesAvailable
 * @param {Function} onRecipeCategoriesChange   - Method to set the local state of the recipeCategories
 *
 * @returns {void}
 */
export async function fetchRecipesByCategories(
  clientId,
  onRecipesAvailableChange,
  onRecipeCategoriesChange,
  withoutIngredients = false,
  isKitchen = false,
) {
  const result = isKitchen
    ? await centralService.getKitchenRecipesByCategoriesForRecipeComposition(
        clientId,
        withoutIngredients,
      )
    : await recipeService.getRecipesByCategoriesForRecipeComposition(clientId, withoutIngredients);

  if (isEmpty(result)) {
    onRecipesAvailableChange([]);
    onRecipeCategoriesChange([]);

    return;
  }

  let allRecipes = Object.keys(result.recipes).reduce(
    (allRecipes, recipesOfCategories) => allRecipes.concat(result.recipes[recipesOfCategories]),
    [],
  );

  onRecipesAvailableChange(allRecipes);
  onRecipeCategoriesChange(result.categories);
}

/**
 * Retrieve the parents of a recipe associated to the given recipeId
 *
 * @param {String} recipeId   - The recipe id on which retrieve the data
 *
 * @returns {void}
 */
export async function fetchRecipeParents(recipeId, onEntitiesMappedChange) {
  const result = await recipeService.getRecipeParents(recipeId);

  const resultSorted = sortArrayOfObjectsAlphabetically(result, 'name');

  onEntitiesMappedChange(resultSorted);
}
