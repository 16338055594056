import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Tooltip } from '@commons/utils/styledLibraryComponents';
import DisplayImgInFullScreen from '@commons/DisplayImgInFullScreen';

import {
  CategoryContentContainer,
  RowContainer,
  ColumnsContainer,
  Column,
} from '../styledComponents';
import { getListColumns } from '../utils/columns';

import ProductionRow from './ProductionRow';

const ProductionCategoryContent = (props) => {
  const {
    recipes,
    handleQuantityUpdate,
    productionQuantities,
    plannedProductions,
    selectedDate,
    readOnly,
    hasProductionPlanning,
  } = props;

  const [columns] = useState(getListColumns(hasProductionPlanning));

  const [displayProductPicture, setDisplayProductPicture] = useState({
    state: false,
    displayImage: '',
  });
  const [openedRecipeId, setOpenedRecipeId] = useState(null);

  return (
    <CategoryContentContainer>
      <ColumnsContainer>
        {columns.map((column, indexColumn) => (
          <Column
            flex={column.flex}
            key={`${column.propertyKey}-${indexColumn}`}
            nbColumns={columns.length}
          >
            {column.displayImage && displayProductPicture.state ? (
              <>
                <DisplayImgInFullScreen
                  image={displayProductPicture.displayImage}
                  setDisplayProductPicture={setDisplayProductPicture}
                />
                {column.label}
              </>
            ) : (
              <>{column.label}</>
            )}
            {!!column.tooltip && (
              <Tooltip key={`tooltip-${column.propertyKey}-${indexColumn}`} text={column.tooltip} />
            )}
          </Column>
        ))}
      </ColumnsContainer>
      {sortBy(recipes, 'name').map((recipe, index) => (
        <RowContainer key={`${recipe.name}-${index}`}>
          <ProductionRow
            handleQuantityUpdate={handleQuantityUpdate}
            hasProductionPlanning={hasProductionPlanning}
            isOpened={openedRecipeId === recipe.id}
            nbColumns={columns.length}
            plannedProduction={plannedProductions[recipe.id] || 0}
            productionQuantity={productionQuantities[recipe.id] || 0}
            readOnly={readOnly}
            recipe={recipe}
            selectedDate={selectedDate}
            setDisplayProductPicture={setDisplayProductPicture}
            setOpenedRecipeId={setOpenedRecipeId}
          />
        </RowContainer>
      ))}
    </CategoryContentContainer>
  );
};

ProductionCategoryContent.propTypes = {
  recipes: PropTypes.array.isRequired,
  handleQuantityUpdate: PropTypes.func.isRequired,
  productionQuantities: PropTypes.object.isRequired,
  plannedProductions: PropTypes.object.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  readOnly: PropTypes.bool.isRequired,
};

export default ProductionCategoryContent;
