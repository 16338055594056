import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { ExpandableEmbeddedList } from '@commons/utils/styledLibraryComponents';

import { getClientInfo } from '@selectors/client';

import { Container } from './styledComponents';
import { getColumnSettings } from './utils';

export const Mix = (props) => {
  const {
    mixData,
    isLoading,
    client: { useTtc },
    currency,
  } = props;
  const currentDate = moment(props.currentDate).format('YYYY-MM-DD');
  const currentDay = moment(props.currentDate).format('dddd');
  const [embedListData, setEmbedListData] = useState({
    headers: getColumnSettings(useTtc, currency),
    data: [],
  });

  useEffect(() => {
    formatProductMix(mixData);
    settingsDay();
  }, [mixData]);

  /*                                                         */
  /*  Little function to update current day for graph title  */
  /*                                                         */
  const settingsDay = () => {
    const titleGraph = `${i18next.t('FORECAST.PRODUCT_MIX.CHART_TITLE', { day: currentDay })}`;
    embedListData.headers.forEach((head) => {
      if (head.propertyKey === 'unit') {
        head.graphConfiguration.title = titleGraph;
      }
    });
  };

  const orderAndFormatSales = (unit, chartData) => {
    let graphFormat = chartData;
    graphFormat.push({
      y: unit ? Math.round(unit) : unit,
      x: currentDate,
    });
    graphFormat = _.sortBy(chartData, ['x']);
    graphFormat = graphFormat.map((graphValue) => ({
      y: graphValue.y ? Math.round(graphValue.y * 100) / 100 : graphValue.y,
      x: moment(graphValue.x).format('dddd Do/MM'),
    }));

    return graphFormat;
  };

  const formatGenericChart = (chartData) => {
    const chart = [];

    chartData.forEach((chartItem) => {
      chart.push({ x: chartItem.date, y: chartItem.sales || 0 });
    });

    return chart;
  };

  const formatProductMix = (mixData) => {
    const dataTmp = _.sortBy(mixData, ['mix']).reverse();

    const formattedData = dataTmp.reduce((formattedData, mix) => {
      let item = { parentItem: { items: {}, chart: [] }, childItems: [] };
      item.parentItem.items = _.omit(mix, ['previousSales', 'products']);
      item.parentItem.items.opened = false;
      item.parentItem.items.graphOpened = false;
      item.parentItem.items.isExpandable = true;

      item.parentItem.chart = orderAndFormatSales(mix.unit, formatGenericChart(mix.previousSales));

      mix.products.forEach((product) => {
        let items = _.omit(product, ['previousSales', 'products']);
        items.graphOpened = false;
        item.childItems.push({
          items,
          chart: orderAndFormatSales(product.unit, formatGenericChart(product.previousSales)),
        });
      });

      formattedData.push(item);
      return formattedData;
    }, []);

    setEmbedListData({ ...embedListData, data: formattedData });
  };

  return (
    <Container>
      <ExpandableEmbeddedList
        data={embedListData.data}
        defaultOrderBy={'category'}
        defaultOrderType={'asc'}
        headers={embedListData.headers}
        height={'calc(100vh - 230px)'}
        isLoading={isLoading}
        minWidth={1200}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
});

export default connect(mapStateToProps)(Mix);
