import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 88px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: ${(props) =>
    props.isLast ? `1px solid ${props.theme.colors.greys.light}` : 'none'};
  border-top: 1px solid ${(props) => props.theme.colors.greys.light};
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;

  .location-filter-input-icon {
    width: 100%;
  }

  > div:first-child {
    .location-filter-list-result {
      width: 170px;
    }
  }

  > div:not(:first-child) {
    .location-filter-list-result {
      width: 180px;
    }
  }

  .location-filter-result-text {
    width: calc(100% - 20px);
  }
`;

export const CloseIconContainer = styled.div`
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseIcon = styled.img`
  width: 14px;
  height: 14px;

  cursor: pointer;
`;

export const LabelContainer = styled.div`
  position: absolute;
  bottom: -13px;
  left: 5px;
  z-index: 1;
`;

export const FilterString = styled.div`
  width: 200px;
  height: 38px;

  display: flex;
  align-items: center;

  background: #ffffff;
  border-radius: 2px;

  > div {
    width: 100%;
  }

  .location-filter-input {
    box-shadow: none;
  }

  .location-filter-result-text:hover {
    .deepsight-label {
      color: inherit;
    }
  }
`;

export const FilterBool = styled.div`
  width: 200px;
  height: 38px;

  display: flex;
  align-items: center;

  background: #ffffff;
  border-radius: 2px;

  > div {
    width: 100%;
  }

  .location-filter-input {
    box-shadow: none;
  }

  .location-filter-result-text:hover {
    .deepsight-label {
      color: inherit;
    }
  }
`;

export const FilterDate = styled.div`
  width: 115px;
  height: 38px;
  padding: 0px 10px;
  margin-right: 10px;

  display: flex;
  align-items: center;

  background: #ffffff;
  border-radius: 2px;

  ${(props) => {
    if (props.disabled) {
      return css`
        background-color: #f2f2f2;
      `;
    }
  }}

  .SingleDatePicker {
    width: 100%;

    margin-right: 0px;

    .SingleDatePickerInput {
      width: 100%;

      .DateInput {
        width: 100% !important;
      }

      .SingleDatePicker_picker {
        left: 4px !important;
        top: 40px !important;
      }
    }
  }

  border: ${(props) =>
    props.focusedDateInput
      ? props.theme.inputs?.primary.focus.border
      : props.theme.inputs?.primary.default.border};
  border-radius: ${(props) => props.theme.inputs?.primary.default.borderRadius};
  box-shadow: none;
`;
