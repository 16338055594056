import { STEP_REGISTRATION } from './constants';
import i18next from 'i18next';

const getUserPassword = (settings) => {
  const { isValidPassword, handleSwitchStep, handleSetUserInformations, handleUserRegistration } =
    settings;

  const title = i18next.t('AUTHENTICATION.REGISTRATION.LANDING_STEP_ONE_TITLE');

  const previousButton = {
    color: 'inpulse-outline',
    isDisabled: false,
    label: i18next.t('GENERAL.BACK'),
    icon: '/images/inpulse/arrow-left-ip-black.svg',
    handleClick: () => handleSwitchStep(STEP_REGISTRATION.USER_INFORMATION),
  };

  const nextButtonParams = {
    color: 'inpulse-default',
    isDisabled: !isValidPassword,
    label: i18next.t('AUTHENTICATION.REGISTRATION.LANDING_STEP_TWO_ACTION_REGISTER'),
    icon: '/images/inpulse/check-white.svg',
    handleClick: () => handleUserRegistration(),
  };

  const actions = [previousButton, nextButtonParams];

  const params = {
    handleSetUserInformations,
  };

  return {
    title,
    actions,
    params,
  };
};

export default getUserPassword;
