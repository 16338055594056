import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1140px;
  height: calc(100% - 75px);

  margin-top: 5px;
  position: relative;
  padding: 25px 15px 0px 15px;

  background-color: ${(props) => props.theme.colors?.greys.lighter};
`;

export const ContainerInfoBoxes = styled.div`
  display: flex;
`;

export const IconSort = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 10px;

  transform: ${(props) => (props.rotated ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const PlanningHeader = styled.div`
  width: 100%;
  padding: 0 35px;
  margin-bottom: 15px;
  font: ${(props) => props.theme.fonts?.textBig};

  display: flex;

  &.header-category {
    padding: 0 15px;
    width: calc(100% - 30px);
  }

  > div {
    flex: 1;
    text-align: center;

    &:first-child {
      text-align: left;
    }

    color: ${(props) => props.theme.colors?.greys.dark};

    font: ${(props) => props.theme.fonts?.textBig};

    &.reference {
      flex: 1;

      min-width: 300px;
    }

    &.category {
      flex: 1;

      display: flex;
      align-items: center;

      min-width: 315px;

      color: ${(props) => props.theme.colors?.greys.dark};

      font: ${(props) => props.theme.fonts?.textBig};
      padding-left: 50px;
      justify-content: flex-start;
    }

    &.produced,
    &.loss,
    &.stock {
      min-width: 390px;
    }
  }
`;

export const ContainerPlanning = styled.div`
  width: 100%;
  height: 100%;

  overflow: scroll;

  &.listing-category {
    overflow: hidden;

    width: calc(100% - 30px);
    margin: 0px 15px 35px 15px;

    border-radius: ${(props) => props.theme.borders?.radius.weak};
    box-shadow: ${(props) => props.theme.shadows.short};

    > div:first-child {
      border-top: none;

      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    > div:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;

export const Row = styled.div`
  width: 100%;
  padding: 15px 35px;
  border-top: 1px solid rgba(216, 216, 216, 0.6);

  display: flex;

  &:nth-child(odd) {
    background: #ffffff;
  }

  &:nth-child(even) {
    background: ${(props) => props.theme.colors?.greys.lighter};
  }

  &.success {
    border: 2px solid #44d7b6 !important;
  }

  &.error {
    border: 2px solid #ef252a !important;
  }
`;

export const Section = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  &.product-name {
    flex: 1;
    flex-direction: column;
    font: ${(props) => props.theme.fonts?.textBig};
    color: ${(props) => props.theme.colors?.greys.darkest};
    min-width: 300px;
  }

  .ref-price,
  .ref-total {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }
`;

export const Box = styled.div`
  width: 60px;
  height: 60px;

  color: ${(props) => props.theme.colors?.greys.darkest};

  font: ${(props) => props.theme.fonts?.kpiBig};

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

  &.left-to-produce {
    box-shadow: none;
    background: inherit;
  }

  &.border-red {
    border: 1px solid #ef252a;
  }

  &.border-green {
    border: 1px solid greenyellow;
  }
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-child {
    align-items: start;
    justify-content: start;
  }
  > div {
    color: ${(props) => props.theme.colors?.greys.dark};
    &.active {
      color: ${(props) => props.theme.colors?.greys.darkest};
    }
    &:not(.disabled) {
      &:hover {
        color: ${(props) => props.theme.colors?.greys.darker};
      }
      cursor: pointer;
    }
  }
`;
