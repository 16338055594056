import { get, head, pick, isEmpty } from 'lodash';
import i18next from 'i18next';

import { capitalizeFirstLetter } from '@commons/utils/format';
import { convertPriceToPackagingById } from '@commons/utils/packagings';

import { STORAGE_AREA_NONE_ID } from '../constants/storageAreaIdNone';

/**
 * Return a formatted packagings list representing all the packagings that can be used within the inventory form
 *
 * @param {SPP[]} packagings                    - The list of packagings (SPP[]) on whioch iterate
 * @param {uuid[]} supplierProductPackagingIds  - Optional, the list of supplierProductPackaging.id to override SPP.isUsedInStock check
 *
 * @returns {SPP[]} The packaging that can be used within the inventory form
 */
export const getAvailablePackagings = (packagings, supplierProductPackagingIds = []) =>
  packagings.map((packaging) => ({
    ...packaging,
    isUsedInStock: packaging.isUsedInStock || supplierProductPackagingIds.includes(packaging.id),
  }));

/**
 * Return filtered inventories without duplicates. Inventories can sometimes have the same supplier product id multiple
 * times because of the packagings for the same storage area. This functions aims to keep one sp per storage area to
 * not have any duplicates
 *
 * @param {Object[]} inventories - The list of inventories ( formatted supplier products ) to filter
 *
 * @returns {Object[]} The filtered inventories
 */
const _removeDuplicateFromInventories = (inventories) =>
  inventories.reduce(
    (acc, inventory) => {
      const currentStorageAreaIdByInventoryId = acc.storageAreaIdByInventoryId[inventory.id];

      if (
        currentStorageAreaIdByInventoryId &&
        currentStorageAreaIdByInventoryId.has(inventory.storageAreaId)
      ) {
        return acc;
      }

      if (!currentStorageAreaIdByInventoryId) {
        acc.storageAreaIdByInventoryId[inventory.id] = new Set();
      }

      acc.storageAreaIdByInventoryId[inventory.id].add(inventory.storageAreaId);

      acc.filtered.push(inventory);
      return acc;
    },
    { filtered: [], storageAreaIdByInventoryId: {} },
  );

export const formatSupplierProducts = ({
  readOnly,
  suppliers,
  inventories,
  inventoryListId,
  storeSupplierProductMappings,
}) => {
  // Existing inventory
  if (readOnly && inventoryListId) {
    const inventoriesWithoutDuplicate = _removeDuplicateFromInventories(inventories);

    return dispatchIntoCategories({
      inventories,
      inventoryListId,
      storeSupplierProductMappings,
      supplierProducts: inventoriesWithoutDuplicate.filtered,
    });
  }

  const storeSupplierProducts = (storeSupplierProductMappings || []).map((supplierProduct) => {
    const mapping = supplierProduct.lnkSupplierproductStoresupplierproductmappingrel;

    const matchingSupplier = suppliers.find(({ id }) => id === mapping.supplierId);

    return {
      ...mapping,
      totByPackagingId: {},
      ...pick(supplierProduct, ['hasStock', 'hasDlc']),
      lnkSupplierSupplierproductrel: matchingSupplier,
    };
  });

  let filteredStoreSupplierProducts = storeSupplierProducts;

  const inventoriesWithoutDuplicate = _removeDuplicateFromInventories(inventories || []);

  const filteredInventories = inventoriesWithoutDuplicate.filtered;

  if (inventoryListId && inventories) {
    const inventoriesWithoutStorageArea = filteredInventories.filter(
      ({ storageAreaId }) => !storageAreaId,
    );
    const inventoryIdsWithoutStorageArea = new Set(
      inventoriesWithoutStorageArea.map(({ id }) => id),
    );

    filteredStoreSupplierProducts = storeSupplierProducts.filter(
      ({ id }) => !inventoryIdsWithoutStorageArea.has(id),
    );
  }

  return dispatchIntoCategories({
    inventories,
    inventoryListId,
    storeSupplierProductMappings,
    supplierProducts: filteredStoreSupplierProducts.concat(filteredInventories),
  });
};

export const buildSupplierProductFromExistingInventory = (supplierProduct, inventories) => {
  const { packagings } = supplierProduct;

  // Property id here is referencing a SupplierProduct.id
  const matchingInventories = inventories.filter(
    ({ id, storageAreaId }) =>
      id === supplierProduct.id &&
      // Keep no strict check on storageAreaId to avoid breaking change on Transfer detail view (IPP-6949)
      storageAreaId == supplierProduct.storageAreaId,
  );

  const supplierProductPackagingIds = matchingInventories.map(
    (inventory) => inventory.supplierProductPackagingId,
  );

  const currentPackagings = matchingInventories.length
    ? head(matchingInventories).packagings
    : packagings;

  const availablePackagings = getAvailablePackagings(
    currentPackagings,
    supplierProductPackagingIds,
  );

  const invoicePackaging = availablePackagings.find(({ isUsedInInvoice }) => isUsedInInvoice);

  let supplierProductPrice = supplierProduct.price;

  const formattedSupplierProduct = availablePackagings.reduce((result, packaging) => {
    const matchingInventory = matchingInventories.find(
      (inventory) => inventory.supplierProductPackagingId === packaging.id,
    );

    const total = get(matchingInventory, 'tot', null);

    if (matchingInventory) {
      const convertedPrice = convertPriceToPackagingById(
        matchingInventory.price,
        matchingInventory.supplierProductPackagingId,
        invoicePackaging.id,
        currentPackagings,
      );

      supplierProductPrice = convertedPrice;
    }

    if (!result) {
      result = {
        ...supplierProduct,
        packagings: [],
        totByPackagingId: {},
        allPackagings: currentPackagings,
        price: supplierProductPrice,
        storageAreaId: matchingInventory ? matchingInventory.storageAreaId : null,
      };
    }

    result.packagings.push(packaging);

    result.totByPackagingId[packaging.id] = total;

    return result;
  }, null);

  return { ...formattedSupplierProduct, price: supplierProductPrice };
};

const buildSupplierProductFromMappingsConfiguration = (supplierProduct, mappings) => {
  const mapping = mappings.find((item) => item.supplierProductId === supplierProduct.id);

  const { packagings, price } = mapping.lnkSupplierproductStoresupplierproductmappingrel;

  const availablePackagings = getAvailablePackagings(packagings);

  return availablePackagings.reduce((result, packaging) => {
    if (!result) {
      result = {
        ...supplierProduct,
        packagings: [],
        totByPackagingId: {},
        allPackagings: packagings,
        price: price || null,
      };
    }

    result.packagings.push(packaging);

    result.totByPackagingId[packaging.id] = null;

    return result;
  }, null);
};

const buildInventoryEntity = (
  inventoryListId,
  supplierProduct,
  inventories,
  storeSupplierProductMappings,
) => {
  if (inventoryListId) {
    return buildSupplierProductFromExistingInventory(supplierProduct, inventories);
  }

  return buildSupplierProductFromMappingsConfiguration(
    supplierProduct,
    storeSupplierProductMappings,
  );
};

const dispatchIntoCategories = ({
  inventories,
  inventoryListId,
  supplierProducts,
  storeSupplierProductMappings,
}) =>
  supplierProducts.reduce((result, supplierProduct) => {
    const inventory = buildInventoryEntity(
      inventoryListId,
      supplierProduct,
      inventories,
      storeSupplierProductMappings,
    );

    if (!inventory.lnkSupplierSupplierproductrel) {
      return result;
    }

    const formattedRelation = {
      ...inventory.lnkSupplierSupplierproductrel,
      name: capitalizeFirstLetter(
        (
          inventory.lnkSupplierSupplierproductrel.name ||
          i18next.t('PRODUCTION.PRODUCTION.OTHER_CATEGORY')
        ).toLowerCase(),
      ),
    };

    result.push({
      ...inventory,
      storageAreaId: supplierProduct.storageAreaId || null,
      lnkSupplierSupplierproductrel: formattedRelation,
      category: capitalizeFirstLetter(
        (inventory.category || i18next.t('PRODUCTION.PRODUCTION.OTHER_CATEGORY')).toLowerCase(),
      ),
      subCategory: capitalizeFirstLetter(
        (inventory.subCategory || i18next.t('PRODUCTION.PRODUCTION.OTHER_CATEGORY')).toLowerCase(),
      ),
    });

    return result;
  }, []);

export const getInventoryPayload = (
  supplierProducts,
  {
    totByStorageAreaIdSPIdAndPackagingId = {},
    listId = null,
    isTransfer = false,
    storageAreaId = null,
  },
) =>
  supplierProducts.reduce((result, { id, price, packagings, totByPackagingId }) => {
    const currentTotByPackagingId =
      totByStorageAreaIdSPIdAndPackagingId[id] || totByPackagingId || {};

    if (isEmpty(currentTotByPackagingId)) {
      return result;
    }

    const invoicePackaging = packagings.find(({ isUsedInInvoice }) => isUsedInInvoice);

    for (const supplierProductPackagingId of Object.keys(currentTotByPackagingId)) {
      const value = currentTotByPackagingId[supplierProductPackagingId];

      if (!value && value !== 0) {
        continue;
      }

      const matchingPackaging =
        packagings.find((packaging) => packaging.id === supplierProductPackagingId) || {};

      const isMasterPackaging = !matchingPackaging.masterSupplierProductPackagingId;

      const payload = {
        price: convertPriceToPackagingById(
          price,
          invoicePackaging.id,
          supplierProductPackagingId,
          packagings,
        ),
        inventoryListId: !!isTransfer ? null : listId,
        inventoryTransferListId: !!isTransfer ? listId : null,
        supplierProductId: id,
        supplierProductPackagingId,
        unit: isMasterPackaging ? 'unit' : 'packaging',
        tot: parseFloat(value),
      };

      if (!isTransfer) {
        payload.storageAreaId = storageAreaId === STORAGE_AREA_NONE_ID ? null : storageAreaId;
      }

      result.push(payload);
    }

    return result;
  }, []);

export const getRecipeInventoryPayload = (
  recipes,
  { totByRecipeId = {}, inventoryListId = null, storageAreaId = null },
) =>
  recipes.reduce((acc, recipe) => {
    const recipeTotal = totByRecipeId[recipe.id];

    if (!recipeTotal && recipeTotal !== 0) {
      return acc;
    }

    const payload = {
      inventoryListId,
      inventoryTransferListId: null,
      entityId: recipe.id,
      cost: recipe.cost,
      quantity: recipeTotal,
      storageAreaId: storageAreaId === STORAGE_AREA_NONE_ID ? null : storageAreaId,
    };

    acc.push(payload);

    return acc;
  }, []);

export const formatRecipes = ({ readOnly, recipes, inventoryListId, recipeInventories }) => {
  const inventoriesWithoutDuplicate = _removeDuplicateFromInventories(recipeInventories || []);

  if (readOnly && inventoryListId) {
    return inventoriesWithoutDuplicate.filtered;
  }

  const filteredInventories = inventoriesWithoutDuplicate.filtered;

  let filteredRecipes = recipes;

  if (inventoryListId && recipeInventories) {
    const inventoriesWithoutStorageArea = filteredInventories.filter(
      ({ storageAreaId }) => !storageAreaId,
    );
    const inventoryIdsWithoutStorageArea = new Set(
      inventoriesWithoutStorageArea.map(({ id }) => id),
    );

    filteredRecipes = recipes.filter(({ id }) => !inventoryIdsWithoutStorageArea.has(id));
  }

  return filteredRecipes.concat(filteredInventories);
};

export default {
  getInventoryPayload,
  formatSupplierProducts,
  formatRecipes,
  getAvailablePackagings,
  getRecipeInventoryPayload,
};
