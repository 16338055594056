import { head } from 'lodash';
import i18next from 'i18next';

const getStoreGPSCoordinatesCardSettings = (
  storeGPSCoordinates,
  hasLatitudeError,
  hasLongitudeError,
) => {
  const inputs = [
    {
      dropdownValue: null,
      hasTooltip: true,
      icon: null,
      isDropdown: false,
      isRequired: true,
      isSwitch: false,
      label: `* ${i18next.t('BACKOFFICE.STORES.LATITUDE')}`,
      placeholder: i18next.t('BACKOFFICE.STORES.LATITUDE'),
      propertyKey: 'latitude',
      tooltipText: i18next.t('BACKOFFICE.STORES.LATITUDE_TOOLTIP'),
      type: 'number',
      hasError: !!hasLatitudeError,
      errorMessage: i18next.t('BACKOFFICE.STORES.LATITUDE_ERROR'),
    },
    {
      dropdownValue: null,
      hasTooltip: true,
      icon: null,
      isDropdown: false,
      isRequired: true,
      isSwitch: false,
      label: `* ${i18next.t('BACKOFFICE.STORES.LONGITUDE')}`,
      placeholder: i18next.t('BACKOFFICE.STORES.LONGITUDE'),
      propertyKey: 'longitude',
      tooltipText: i18next.t('BACKOFFICE.STORES.LONGITUDE_TOOLTIP'),
      type: 'number',
      hasError: !!hasLongitudeError,
      errorMessage: i18next.t('BACKOFFICE.STORES.LONGITUDE_ERROR'),
    },
  ];

  return {
    title: i18next.t('BACKOFFICE.STORES.GPS_COORDINATES'),
    inputs: inputs.reduce((acc, input) => {
      if (input.propertyKey === 'latitude') {
        const locationValue = storeGPSCoordinates.locationValue;
        const coordinates = !!locationValue ? locationValue.split(',') : '';
        acc.push({ ...input, value: head(coordinates) });
        return acc;
      }

      if (input.propertyKey === 'longitude') {
        const locationValue = storeGPSCoordinates.locationValue;
        const coordinates = !!locationValue ? locationValue.split(',') : '';
        acc.push({ ...input, value: coordinates[1] });
        return acc;
      }
    }, []),
  };
};

export default getStoreGPSCoordinatesCardSettings;
