import i18next from 'i18next';

export const formatHourRangeName = (startHour, endHour, userLanguageCode) => {
  let start = startHour;
  let end = endHour;

  if (start.minutes() !== 0 || userLanguageCode === 'en') {
    start = start.format(`H[${i18next.t('GENERAL.DATE_TIME_FORMAT_HOUR_SEPARATOR')}]mm`);
  } else {
    start = start.format(`H[${i18next.t('GENERAL.DATE_TIME_FORMAT_HOUR_SEPARATOR')}]`);
  }

  if (end.minutes() !== 0 || userLanguageCode === 'en') {
    end = end.format(`H[${i18next.t('GENERAL.DATE_TIME_FORMAT_HOUR_SEPARATOR')}]mm`);
  } else {
    end = end.format(`H[${i18next.t('GENERAL.DATE_TIME_FORMAT_HOUR_SEPARATOR')}]`);
  }

  return `${start} - ${end}`;
};

export default {
  formatHourRangeName,
};
