import { useState, useEffect } from 'react';

/**
 * Custom hook to call whenever a FiltersButton is used.
 *
 * Props 'advancedFilters' must be defined before the call of this hook
 * Usage example in: StockInventoriesTransfer
 *
 * @returns An object containing the button's state/setters
 */
export const useFiltersButtonState = (advancedFilters) => {
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [advancedFiltersApplied, setAdvancedFiltersApplied] = useState(false);

  useEffect(() => {
    if (
      !advancedFilters ||
      (advancedFilters && !advancedFilters.length) ||
      !advancedFilters.some((filter) => filter.display)
    ) {
      setAdvancedFiltersApplied(false);
    }
  }, [advancedFilters]);

  return {
    filtersModalOpen,
    setFiltersModalOpen,
    advancedFiltersApplied,
    setAdvancedFiltersApplied,
  };
};
