import i18next from 'i18next';
import React, { useState, useRef, useEffect } from 'react';

import { RadioButton } from '@commons/utils/styledLibraryComponents';

import {
  Container,
  SendEmail,
  EmailValidationText,
  ButtonsContainer,
  RadioSelectButton,
  ButtonText,
} from './styledComponents';

export const ENUM_WHEN_IS_SENDING_MAIL = {
  NOW: 'now',
  LATER: 'later',
};

export const SendInvitationModal = (props) => {
  const { userInformations, handleEmailChoiceChange } = props;

  const [selectedChoice, setSelectedChoice] = useState(
    userInformations.isSendingEmailNow || ENUM_WHEN_IS_SENDING_MAIL.NOW,
  );

  const yesChoiceRef = useRef(null);
  const noChoiceRef = useRef(null);

  useEffect(() => {
    handleEmailChoiceChange(userInformations, selectedChoice);

    selectedChoice && selectedChoice === ENUM_WHEN_IS_SENDING_MAIL.LATER
      ? yesChoiceRef.current.focus()
      : noChoiceRef.current.focus();
  }, [selectedChoice]);

  useEffect(() => {
    handleEmailChoiceChange(userInformations, selectedChoice);
  }, []);

  return (
    <Container>
      <img src="/images/inpulse/send-email.svg"></img>
      <SendEmail>{i18next.t('BACKOFFICE.TEAM.SEND_EMAIL_TO_USER')}</SendEmail>
      <EmailValidationText>
        {i18next.t('BACKOFFICE.TEAM.SEND_EMAIL_TO_USER_VALIDATION_TEXT', {
          email: userInformations.email,
        })}
      </EmailValidationText>
      <ButtonsContainer>
        <RadioSelectButton
          ref={yesChoiceRef}
          onClick={() => setSelectedChoice(ENUM_WHEN_IS_SENDING_MAIL.LATER)}
        >
          <RadioButton
            selected={selectedChoice}
            size="small"
            value={ENUM_WHEN_IS_SENDING_MAIL.LATER}
          />
          <ButtonText>{i18next.t('BACKOFFICE.TEAM.SEND_EMAIL_LATER')}</ButtonText>
        </RadioSelectButton>
        <RadioSelectButton
          ref={noChoiceRef}
          onClick={() => setSelectedChoice(ENUM_WHEN_IS_SENDING_MAIL.NOW)}
        >
          <RadioButton
            selected={selectedChoice}
            size="small"
            value={ENUM_WHEN_IS_SENDING_MAIL.NOW}
          />
          <ButtonText>{i18next.t('BACKOFFICE.TEAM.SEND_EMAIL_NOW')}</ButtonText>
        </RadioSelectButton>
      </ButtonsContainer>
    </Container>
  );
};

export default SendInvitationModal;
