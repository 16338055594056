import React, { useEffect, useRef, useState } from 'react';

import {
  Button,
  IconContainer,
  ModalContainer,
  TitleContainer,
  ContentContainer,
} from './styledComponents';

export interface FrancoButtonProps {
  icon: string;
  title: string;
  render: () => React.ReactElement;
}

// Used for reference : https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
const useOutsideAlerter = (
  displayedElementRef,
  displayerRef,
  setIsModalOpen
) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        displayedElementRef.current &&
        !displayedElementRef.current.contains(event.target) &&
        !displayerRef.current.contains(event.target)
      ) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

export const FrancoButton = (props: FrancoButtonProps): React.ReactElement => {
  const { icon, title, render } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const buttonRef = useRef<HTMLInputElement>(null);
  const modalRef = useRef<HTMLInputElement>(null);
  useOutsideAlerter(modalRef, buttonRef, setIsModalOpen);

  return (
    <Button
      onClick={() => {
        setIsModalOpen(!isModalOpen);
      }}
      isModalOpen={isModalOpen}
    >
      <IconContainer src={icon} ref={buttonRef} />
      {isModalOpen && (
        <ModalContainer ref={modalRef}>
          <TitleContainer>{title}</TitleContainer>
          <ContentContainer>{render()}</ContentContainer>
        </ModalContainer>
      )}
    </Button>
  );
};

export default FrancoButton;
