import PropTypes from 'prop-types';
import React from 'react';

import { Tooltip } from '@commons/utils/styledLibraryComponents';

import { STOCK_FORM_TYPES } from '@stocks/StocksInventories/components/StockForm/constants/types';
import getColumnsConfig from '@stocks/StocksInventories/components/StockForm/utils/getColumnsConfig';
import StockFormRow from '@stocks/StocksInventories/components/StockForm/components/Content/components/StockFormRow';

import { Container, ColumnsContainer, Column, RowContainer } from './styledComponents';

const TransferSupplierProductsList = (props) => {
  const { supplierProducts, handleDataUpdate, handleDelete, readOnly } = props;

  const columns = getColumnsConfig({ stockType: 'transfer' });

  return (
    <Container>
      <ColumnsContainer>
        {columns.map((column, indexColumn) => (
          <Column
            flex={column.flex}
            key={`${column.propertyKey}-${indexColumn}`}
            width={column.width}
          >
            <div style={column.style}>{column.label}</div>
            {!!column.tooltip && (
              <Tooltip key={`tooltip-${column.propertyKey}-${indexColumn}`} text={column.tooltip} />
            )}
          </Column>
        ))}
      </ColumnsContainer>
      {supplierProducts.map((supplierProduct, index) => (
        <RowContainer key={`${supplierProduct.name}-${index}`}>
          <StockFormRow
            columns={columns}
            handleDataUpdate={handleDataUpdate}
            handleDelete={handleDelete}
            key={supplierProduct.id}
            readOnly={readOnly}
            stockType={STOCK_FORM_TYPES.TRANSFER}
            supplierProduct={supplierProduct}
          />
        </RowContainer>
      ))}
    </Container>
  );
};

TransferSupplierProductsList.propTypes = {
  supplierProducts: PropTypes.arrayOf(PropTypes.object),
};

export default TransferSupplierProductsList;
