import PropTypes, { any } from 'prop-types';
import React, { useEffect } from 'react';

import { ENUM_MODULE_NAME, getFeaturesByPath } from '../commons/utils/features';

/**
 * Generic component to manage a module's feature paths.
 * See StocksModuleContainer and StocksInventoriesContainer for examples.
 *
 * @param {*} props
 * @returns an empty div
 */
export const ModuleContainer = (props) => {
  const { userRights, history, moduleName, moduleFeaturePaths } = props;

  useEffect(() => {
    const features = getFeaturesByPath(userRights, moduleName);

    if (features.length) {
      const redirectionPath = moduleFeaturePaths.find((featurePath) =>
        features.some((feature) => feature.path === featurePath),
      );
      history.replace(redirectionPath);
    }
  }, []);

  return <div />;
};

ModuleContainer.propTypes = {
  userRights: PropTypes.array,
  history: any, // react-router-dom's route prop
  moduleName: PropTypes.oneOf(Object.values(ENUM_MODULE_NAME)),
  moduleFeaturePaths: PropTypes.arrayOf(PropTypes.string),
};
