import { isEqual, get } from 'lodash';
import React, { memo } from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

import { STOCK_FORM_TYPES } from '@stocks/StocksInventories/components/StockForm/constants/types';

import { Container } from './styledComponents';
import { Name, Price, Unit, Total, Quantity, Supplier } from './components';

const memoizeCondition = (prevProps, props) =>
  props.stockType === STOCK_FORM_TYPES.TRANSFER
    ? false
    : isEqual(prevProps.totByPackagingId, props.totByPackagingId);

const StockFormRow = memo((props) => {
  const {
    stockType,
    columns,
    readOnly,
    supplierProduct,
    handleDataUpdate,
    setDisplayProductPicture,
    selectedStorageAreaId,
    handleDelete,
    totByPackagingId,
  } = props;

  const nbColumns = columns.length;

  const availablePackagings = supplierProduct.packagings.filter(
    (packaging) => packaging.isUsedInStock,
  );

  const currentTotByPackagingId = totByPackagingId || supplierProduct.totByPackagingId;

  const hasValues = Object.values(currentTotByPackagingId).some((tot) => tot != null);

  const handleTotByPackagingId = (updatedTotByPackagingId) => {
    handleDataUpdate(supplierProduct.id, updatedTotByPackagingId, selectedStorageAreaId);
  };

  const supplierName = get(supplierProduct, 'lnkSupplierSupplierproductrel.name');

  return (
    <Container>
      <Name
        img={supplierProduct.img}
        name={supplierProduct.name}
        nbColumns={nbColumns}
        setDisplayProductPicture={setDisplayProductPicture}
        sku={supplierProduct.sku}
      />
      {stockType === STOCK_FORM_TYPES.STOCK && (
        <Supplier nbColumns={nbColumns} supplierName={supplierName} />
      )}
      <Price
        nbColumns={nbColumns}
        packagings={supplierProduct.packagings}
        price={supplierProduct.price}
      />
      <Quantity
        allPackagings={supplierProduct.packagings}
        handleTotByPackagingId={handleTotByPackagingId}
        name={supplierProduct.name}
        nbColumns={nbColumns}
        packagings={availablePackagings}
        price={supplierProduct.price}
        readOnly={readOnly}
        stockType={stockType}
        totByPackagingId={currentTotByPackagingId}
      />
      <Unit
        allPackagings={supplierProduct.packagings}
        isDirty={hasValues}
        nbColumns={nbColumns}
        packagings={availablePackagings}
      />
      <Total
        nbColumns={nbColumns}
        packagings={supplierProduct.packagings}
        price={supplierProduct.price}
        totByPackagingId={currentTotByPackagingId}
      />
      {stockType === STOCK_FORM_TYPES.TRANSFER && !readOnly && (
        <Button
          color={'inpulse-grey'}
          handleClick={() => handleDelete(supplierProduct)}
          icon={'/images/inpulse/trash-white-small.svg'}
          isDisabled={false}
          minWidth={40}
        />
      )}
    </Container>
  );
});

export default memo(StockFormRow, memoizeCondition);
