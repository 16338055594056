import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import themeProvider from '@commons/utils/theme';

import {
  Container,
  Content,
  Text,
  HeaderText,
  HeaderItem,
  Headers,
  Row,
  Item,
  Icon,
  TooltipBackOfficeExport,
  TooltipBackOfficeRectangle,
} from './styledComponents';
import { ORDER_TYPE } from './constants';

export const renderActions = (list, headers) => {
  const theme = themeProvider.getTheme();

  return (
    !!list &&
    list.length > 0 &&
    list.map((item, indexList) => (
      <Row key={indexList}>
        {headers.map((header, indexHeader) => {
          if (header.customRender) {
            return <Item key={indexHeader}>{item[header.propertyKey]()}</Item>;
          }

          return (
            <Item key={indexHeader}>
              <Text black>{i18next.t(item[header.propertyKey])}</Text>
            </Item>
          );
        })}
        {!!item.actions &&
          item.actions.length > 0 &&
          item.actions.map((action, indexAction) => {
            const random = Math.random().toString();
            if (action.type === 'exporter') {
              if (item.launched && item.result.length !== 0) {
                return (
                  <Item
                    key={indexAction}
                    button
                    onClick={() => {
                      action.callback();
                    }}
                  >
                    <TooltipBackOfficeExport
                      data-for={random}
                      data-tip={i18next.t('GENERAL.EXPORT')}
                    ></TooltipBackOfficeExport>
                    <ReactTooltip
                      arrowColor={`${theme.colors?.greys?.darkest}`}
                      backgroundColor={`${theme.colors?.greys?.darkest} !important`}
                      effect="solid"
                      id={random}
                      textColor="white !important"
                    />
                  </Item>
                );
              }
            } else {
              return (
                <Item
                  key={indexAction}
                  button
                  onClick={() => {
                    action.callback();
                  }}
                >
                  <TooltipBackOfficeRectangle isLoading={item.isLoading}>
                    {item.isLoading && (
                      <DeepsightComponentLoader color={'white'} height={16} width={16} />
                    )}
                  </TooltipBackOfficeRectangle>
                </Item>
              );
            }
          })}
      </Row>
    ))
  );
};

export const BackOfficeItemList = (props) => {
  const { category, name, headers, list, setOrder, sortHealthChecks } = props;

  const [orderCategory, setOrderCategory] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [orderType, setOrderType] = useState(null);

  useEffect(() => {
    sortHealthChecks(category, orderBy, orderType);
  }, [category, orderBy, orderType]);

  return (
    <Container>
      <Text big bold main>
        {i18next.t(name)}
      </Text>
      <Headers>
        {!!headers &&
          headers.length > 0 &&
          headers.map((header, indexHeader) => {
            const isColumnSelected = header.propertyKey === orderBy && category === orderCategory;
            return (
              <HeaderItem
                key={indexHeader}
                onClick={() =>
                  setOrder(
                    orderBy,
                    orderType,
                    category,
                    header.propertyKey,
                    setOrderCategory,
                    setOrderBy,
                    setOrderType,
                  )
                }
              >
                <HeaderText black={isColumnSelected} bold={isColumnSelected}>
                  {header.name}
                </HeaderText>
                <Icon
                  alt="icon-order"
                  height={16}
                  rotated={isColumnSelected && orderType === ORDER_TYPE.DESCENDING}
                  src={
                    isColumnSelected ? '/images/icon-sort-dark-asc.svg' : '/images/icon-sort.svg'
                  }
                  width={16}
                ></Icon>
              </HeaderItem>
            );
          })}
      </Headers>
      <Content>{renderActions(list, headers)}</Content>
    </Container>
  );
};

export default BackOfficeItemList;
