import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
`;

export const InputContainer = styled.div`
  width: 240px;

  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-right: 16px;
`;

export const FirstLetter = styled.span`
  color: ${(props) => props.theme.colors?.infoRed};
  font: ${(props) => props.theme.fonts?.textSmallHeight16};
`;

export const Label = styled.div`
  display: flex;
  flex-direction: row;
  font: ${(props) => props.theme.fonts?.textSmallHeight16};
`;

export const Input = styled.input`
  width: 240px;
  height: 40px;

  padding: 12px 16px;

  border-radius: 8px;
  border: ${(props) => `1px solid ${props.theme.colors?.greys?.light}`};

  color: ${(props) => props.theme?.colors?.greys?.darkest};

  font: ${(props) =>
    props.textBig ? props.theme.fonts?.textBigHeight16 : props.theme.fonts?.textSmall};

  &:hover {
    background-color: ${(props) => props.theme.colors.greys.lighter};
  }

  &:invalid {
    color: ${(props) => props.theme.colors.infoRed};
    border-color: ${(props) => props.theme.colors.infoRed};
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.greys.dark};
    font: ${(props) => props.theme.fonts.textSmall};
  }

  ${(props) =>
    !props.isEmailAvailable &&
    css`
      color: ${(props) => props.theme.colors.infoRed};
      border-color: ${(props) => props.theme.colors.infoRed};
    `}
`;

export const InvalidEmail = styled.span`
  color: ${(props) => props.theme.colors?.infoRed};
  font: ${(props) => props.theme.fonts?.textSmallHeight16};
`;

export const DropdownItem = styled.div`
  width: 100%;
  max-height: 40px;
  display: flex;
`;

export const DropdownIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  margin-top: 6px;
`;

export const Text = styled.div`
  font: ${(props) => props.theme.fonts?.textBig};
  color: ${(props) => props.theme.colors?.greys?.darkest};

  ${(props) => {
    if (props.bold) {
      return css`
        font: ${(props) => props.theme.fonts?.textMiddleBold};
      `;
    }
  }}
`;
