import i18next from 'i18next';
import React from 'react';

import { DeepsightComponentLoader } from '@commons/DeepsightComponents';

import { EmptyStateContainer, EmptyStateIcon, MainText, TextContainer } from './styledComponents';

export function renderEmptyList() {
  return (
    <EmptyStateContainer>
      <div>
        <EmptyStateIcon
          alt="empty-state-ingredient-entity-association-icon"
          className={'center'}
          src="/images/inpulse-icon-no-data.svg"
        />
        <TextContainer>
          <MainText>{i18next.t('ADMIN.RECIPES.UNUSED_RECIPE')}</MainText>
        </TextContainer>
      </div>
    </EmptyStateContainer>
  );
}

const EmptyState = (props) => {
  if (props.isLoading === true) {
    return <DeepsightComponentLoader />;
  } else {
    return renderEmptyList();
  }
};

export default EmptyState;
