import * as yup from 'yup';
import { isEmpty, truncate } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React from 'react';

// COMMONS
import { ADMIN_FEATURE_PATHS } from '@commons/utils/featurePaths';
import { doesIngredientsHaveApplicableUnits } from '@commons/utils/units';
import { FILE_TYPES, getFileExtension } from '@commons/utils/fileUtils';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { hasUserCatalogReadOnlyRights } from './hasUserCatalogReadOnlyRights';
import { INPUT_TYPES } from '@commons/constants/inputType';
import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import { sortPackagings } from '@commons/utils/format';

// This value is arbitrary, it looks like the best approximation to know the max length before truncating
const MAX_FILE_NAME_LENGTH_BEFORE_TRUNCATE = 21;

const SUPPLIER_PRODUCT_INPUTS = {
  // SUPPLIER_PRODUCT_GENERAL_INFOS_INPUTS
  NAME: 'name',
  SKU: 'sku',
  EAN: 'ean',
  CATEGORY: 'category',
  ASSOCIATED_PRODUCT: 'associatedProduct',
  SUB_CATEGORY: 'subCategory',
  MANUFACTURER: 'manufacturerName',
  SUPPLIER: 'supplier',
  INVENTORY_LIST_TEMPLATES: 'inventoryListTemplates',
  STORAGE_AREAS: 'storageAreas',
  ATTACHMENT_LINK: 'attachmentLink',
  // SUPPLIER_PRODUCT_RECIPES_INPUTS
  CONVERSIONS: 'conversionsDisplayed',
  ASSOCIATED_INGREDIENTS: 'entity',
  LOSSES: 'loss',
  // SUPPLIER_PRODUCT_PRICE_INPUTS
  INVOICE_PACKAGING: 'invoicePackaging',
  PRICE: 'price',
  CURRENCY: 'currency',
  VAT_RATE: 'vatRate',
  PRICE_EDITABLE_AT_RECEPTION: 'priceEditableAtReception',
  // SUPPLIER_PRODUCT_SCHEDULE_PRICE_INPUTS
  SCHEDULED_PRICE_VALUE: 'scheduledPriceValue',
  SCHEDULED_PRICE_DATE: 'scheduledPriceDate',
  // SUPPLIER_PRODUCT_WEIGHT_INPUTS
  WEIGHT: 'packagingWeight',
};

const _getAttachmentLinkDropdownActions = (
  attachmentLink,
  isReadOnly,
  uploadAttachment,
  downloadAttachment,
  deleteAttachment,
) => {
  const downloadAction = {
    label: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DOWNLOAD_FILE'),
    handleClick: downloadAttachment,
    icon: '/images/inpulse/file-download-black-small.svg',
  };

  const uploadAction = {
    canSelectFile: true,
    acceptedFileTypes: [
      FILE_TYPES.images.mime.jpeg,
      FILE_TYPES.images.mime.png,
      FILE_TYPES.documents.mime.pdf,
    ].join(','),
    label: i18next.t('ADMIN.SUPPLIER_PRODUCTS.UPLOAD_FILE'),
    handleClick: uploadAttachment,
    icon: '/images/inpulse/file-upload-black-small.svg',
  };

  const replaceAction = {
    canSelectFile: true,
    label: i18next.t('ADMIN.SUPPLIER_PRODUCTS.REPLACE_FILE'),
    handleClick: uploadAttachment,
    icon: '/images/inpulse/receipt-attachment.svg',
  };

  const deleteAction = {
    label: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_FILE'),
    handleClick: deleteAttachment,
    icon: '/images/inpulse/trash-black-small.svg',
  };

  if (isReadOnly) {
    return attachmentLink ? [downloadAction] : [];
  }

  const actions = [downloadAction, replaceAction, deleteAction];

  return attachmentLink ? actions : [uploadAction];
};

export const SUPPLIER_PRODUCT_GENERAL_INFOS_INPUTS = (props) => {
  const {
    isCreation,
    isCentralKitchen,
    categories,
    subCategories,
    suppliers,
    productId,
    displayManufacturerName,
    handleCategorySubcategoryCreation,
    canEditFieldsIfCentralKitchenSP,
    clientILTs,
    storageAreas,
    supplierProduct,
    uploadAttachment,
    downloadAttachment,
    deleteAttachment,
    canEditSupplierProductFromDetailView,
    user,
  } = props;

  const isReadOnly =
    (!isCreation && !canEditSupplierProductFromDetailView) ||
    hasUserCatalogReadOnlyRights(user, { isCreation, supplierId: supplierProduct.supplierId });

  const DISABLING_RULES = {
    [SUPPLIER_PRODUCT_INPUTS.SUPPLIER]: () => !isCreation,
    [SUPPLIER_PRODUCT_INPUTS.ASSOCIATED_PRODUCT]: () => true,
    [SUPPLIER_PRODUCT_INPUTS.NAME]: () => isCentralKitchen || isReadOnly,
    [SUPPLIER_PRODUCT_INPUTS.SKU]: () => isCentralKitchen || isReadOnly,
    [SUPPLIER_PRODUCT_INPUTS.EAN]: () => isCentralKitchen || isReadOnly,
    [SUPPLIER_PRODUCT_INPUTS.CATEGORY]: () => !canEditFieldsIfCentralKitchenSP || isReadOnly,
    [SUPPLIER_PRODUCT_INPUTS.SUB_CATEGORY]: () => !canEditFieldsIfCentralKitchenSP || isReadOnly,
    [SUPPLIER_PRODUCT_INPUTS.INVENTORY_LIST_TEMPLATES]: () => isReadOnly,
    [SUPPLIER_PRODUCT_INPUTS.STORAGE_AREAS]: () => isReadOnly,
    [SUPPLIER_PRODUCT_INPUTS.MANUFACTURER]: () => isReadOnly,
    [SUPPLIER_PRODUCT_INPUTS.ATTACHMENT_LINK]: () => !supplierProduct.attachmentLink && isReadOnly,
  };

  const inputs = [
    {
      type: INPUT_TYPES.SINGLE_SELECT,
      name: SUPPLIER_PRODUCT_INPUTS.SUPPLIER,
      label: `${i18next.t('GENERAL.SUPPLIER')} :`,
      width: INPUT_WIDTH.LARGE,
      iconSrc: '/images/inpulse/app-black-small.svg',
      isRequired: true,
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.SUPPLIER],
      items: suppliers,
      rule: yup.lazy((item) =>
        !item
          ? yup.mixed().test(
              'isEmpty',
              i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
                field: i18next.t('GENERAL.SUPPLIER'),
              }),
              (value) => !isEmpty(value),
            )
          : yup
              .object()
              .test(
                'isEmpty',
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
                  field: i18next.t('GENERAL.SUPPLIER'),
                }),
                (value) => !isEmpty(value),
              )
              .shape({
                id: yup.string().required(),
                name: yup.string().required(),
              })
              .strict(),
      ),
    },
  ];

  if (isCentralKitchen) {
    inputs.push({
      type: INPUT_TYPES.SINGLE_SELECT,
      name: SUPPLIER_PRODUCT_INPUTS.ASSOCIATED_PRODUCT,
      label: `${i18next.t('ADMIN.RECIPES.EXPORT_COLUMN_ASSOCIATED_PRODUCT')} :`,
      width: INPUT_WIDTH.LARGE,
      isRequired: true,
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.ASSOCIATED_PRODUCT],
      link: productId
        ? {
            onClick: () => {
              window.open(
                `${ADMIN_FEATURE_PATHS.ADMIN_KITCHEN_PRODUCTS_PRODUCTS}/${productId}/details`,
                '_blank',
              );
            },
            customStyle: { marginLeft: '8px' },
          }
        : null,
    });
  }

  inputs.push(
    {
      type: INPUT_TYPES.TEXT,
      name: SUPPLIER_PRODUCT_INPUTS.NAME,
      width: INPUT_WIDTH.LARGE,
      isRequired: true,
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.NAME],
      label: `* ${i18next.t('GENERAL.NAME')}`,
      rule: yup.string().required(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
          field: i18next.t('GENERAL.NAME'),
        }),
      ),
    },
    {
      type: INPUT_TYPES.TEXT,
      name: SUPPLIER_PRODUCT_INPUTS.SKU,
      width: INPUT_WIDTH.LARGE,
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.SKU],
      label: i18next.t('GENERAL.SKU'),
      rule: yup.string().nullable(),
    },
    {
      type: INPUT_TYPES.TEXT,
      name: SUPPLIER_PRODUCT_INPUTS.EAN,
      width: INPUT_WIDTH.LARGE,
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.EAN],
      // TODO Uncomment when mobile app is public
      // tooltipText: i18next.t('ADMIN.SUPPLIER_PRODUCTS.EAN_FIELD_TOOLTIP_TEXT'),
      label: i18next.t('ADMIN.SUPPLIER_PRODUCTS.EAN_CODE'),
      rule: yup
        .string()
        .nullable()
        .test(
          'ean',
          i18next.t('ADMIN.SUPPLIER_PRODUCTS.EAN_ONLY_NUMBERS_ERROR'),
          (value) => !value || /^\d+$/.test(value),
        )
        .test(
          'ean',
          i18next.t('ADMIN.SUPPLIER_PRODUCTS.EAN_TOO_SHORT_ERROR'),
          (value) => (value && value.length === 13) || !value,
        ),
    },
    {
      type: INPUT_TYPES.SINGLE_SELECT,
      name: SUPPLIER_PRODUCT_INPUTS.CATEGORY,
      label: `${i18next.t('GENERAL.CATEGORY')} :`,
      width: INPUT_WIDTH.LARGE,
      iconSrc: '/images/inpulse/search-black-small.svg',
      isRequired: true,
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.CATEGORY],
      items: categories,
      button: {
        id: 1,
        text: i18next.t('GENERAL.LIST_CREATE_CATEGORY'),
        handleClick: () => {
          handleCategorySubcategoryCreation(SUPPLIER_PRODUCT_INPUTS.CATEGORY);
        },
        isDisabled: user.catalog && !user.catalog.isMasterCatalog,
        iconSrc:
          user.catalog && !user.catalog.isMasterCatalog
            ? '/images/inpulse/add-white-small.svg'
            : '/images/inpulse/add-black-small.svg',
      },
      rule: yup.lazy((item) =>
        !item
          ? yup.mixed().test(
              'isEmpty',
              i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
                field: i18next.t('GENERAL.CATEGORY'),
              }),
              (value) => !isEmpty(value),
            )
          : yup
              .object()
              .test(
                'isEmpty',
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
                  field: i18next.t('GENERAL.CATEGORY'),
                }),
                (value) => !isEmpty(value),
              )
              .shape({
                id: yup.number().required(),
                value: yup.string().required(),
              })
              .strict(),
      ),
    },
    {
      type: INPUT_TYPES.SINGLE_SELECT,
      name: SUPPLIER_PRODUCT_INPUTS.SUB_CATEGORY,
      label: `${i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_SUB_CATEGORY')} :`,
      width: INPUT_WIDTH.LARGE,
      iconSrc: '/images/inpulse/search-black-small.svg',
      isRequired: true,
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.SUB_CATEGORY],
      items: subCategories,
      button: {
        id: 1,
        text: i18next.t('GENERAL.LIST_CREATE_SUBCATEGORY'),
        handleClick: () => {
          handleCategorySubcategoryCreation(SUPPLIER_PRODUCT_INPUTS.SUB_CATEGORY);
        },
        isDisabled: user.catalog && !user.catalog.isMasterCatalog,
        iconSrc:
          user.catalog && !user.catalog.isMasterCatalog
            ? '/images/inpulse/add-white-small.svg'
            : '/images/inpulse/add-black-small.svg',
      },
      rule: yup.lazy((item) =>
        !item
          ? yup.mixed().test(
              'isEmpty',
              i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
                field: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_SUB_CATEGORY'),
              }),
              (value) => !isEmpty(value),
            )
          : yup
              .object()
              .test(
                'isEmpty',
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
                  field: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_SUB_CATEGORY'),
                }),
                (value) => !isEmpty(value),
              )
              .shape({
                id: yup.number().required(),
                value: yup.string().required(),
              })
              .strict(),
      ),
    },
    {
      type: INPUT_TYPES.MULTI_SELECT,
      name: SUPPLIER_PRODUCT_INPUTS.INVENTORY_LIST_TEMPLATES,
      label: `${i18next.t('GENERAL.INVENTORY_TEMPLATES')} :`,
      width: INPUT_WIDTH.LARGE,
      iconSrc:
        clientILTs && clientILTs.length === 0
          ? '/images/inpulse/search-dmgrey-small.svg'
          : '/images/inpulse/search-black-small.svg',
      isRequired: false,
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.INVENTORY_LIST_TEMPLATES],
      items: clientILTs,
      placeholder: i18next.t('GENERAL.SELECT_NONE_MASCULINE'),
      button: {
        id: 1,
        text: i18next.t('GENERAL.LIST_CREATE_INVENTORY_TEMPLATE'),
        handleClick: () =>
          handleCategorySubcategoryCreation(SUPPLIER_PRODUCT_INPUTS.INVENTORY_LIST_TEMPLATES),
        isDisabled: user.catalog && !user.catalog.isMasterCatalog,
        iconSrc:
          user.catalog && !user.catalog.isMasterCatalog
            ? '/images/inpulse/add-white-small.svg'
            : '/images/inpulse/add-black-small.svg',
      },
    },
    {
      type: INPUT_TYPES.MULTI_SELECT,
      name: SUPPLIER_PRODUCT_INPUTS.STORAGE_AREAS,
      label: `${i18next.t('FEATURE.ADMIN.STORAGE_AREAS')} :`,
      width: INPUT_WIDTH.LARGE,
      iconSrc:
        storageAreas && storageAreas.length === 0
          ? '/images/inpulse/search-dmgrey-small.svg'
          : '/images/inpulse/search-black-small.svg',
      isRequired: false,
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.STORAGE_AREAS],
      items: storageAreas,
      placeholder: i18next.t('GENERAL.SELECT_NONE_FEMININE'),
      button: {
        id: 1,
        text: i18next.t('GENERAL.LIST_CREATE_STORAGE_AREA'),
        handleClick: () => handleCategorySubcategoryCreation(SUPPLIER_PRODUCT_INPUTS.STORAGE_AREAS),
        isDisabled: user.catalog && !user.catalog.isMasterCatalog,
        iconSrc:
          user.catalog && !user.catalog.isMasterCatalog
            ? '/images/inpulse/add-white-small.svg'
            : '/images/inpulse/add-black-small.svg',
      },
    },
  );

  if (displayManufacturerName) {
    inputs.push({
      type: INPUT_TYPES.TEXT,
      name: SUPPLIER_PRODUCT_INPUTS.MANUFACTURER,
      width: INPUT_WIDTH.LARGE,
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.MANUFACTURER],
      label: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_MANUFACTURER_NAME'),
      rule: yup.string().nullable(),
    });
  }

  if (!isCreation) {
    let truncatedAttachmentLinkName = supplierProduct.attachmentLinkName;

    /*
    This condition aims to truncate the file name if it's too long, we do this here instead of inside
    the component itself because it could have unwanted side effects and the input button is added over the
    input so if we wanted to use the text-overflow: ellipsis; it would not work because it would truncate the file
    name behind the button, rendering it useless
    */
    if (
      supplierProduct.attachmentLinkName &&
      supplierProduct.attachmentLinkName.length > MAX_FILE_NAME_LENGTH_BEFORE_TRUNCATE
    ) {
      const truncated = truncate(supplierProduct.attachmentLinkName, {
        length: MAX_FILE_NAME_LENGTH_BEFORE_TRUNCATE,
      });

      const fileExtension = getFileExtension(supplierProduct.attachmentLink);

      truncatedAttachmentLinkName = `${truncated} .${fileExtension}`;
    }

    const dropdownActions = _getAttachmentLinkDropdownActions(
      supplierProduct.attachmentLink,
      isReadOnly,
      uploadAttachment,
      downloadAttachment,
      deleteAttachment,
    );

    inputs.push({
      type: INPUT_TYPES.TEXT_WITH_ACTION,
      button: {
        id: 1,
        text: <img src={'/images/inpulse/gear-white-small.svg'} />,
        isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.ATTACHMENT_LINK](),
        handleClick: () => {},
      },
      name: SUPPLIER_PRODUCT_INPUTS.ATTACHMENT_LINK,
      width: INPUT_WIDTH.LARGE,
      isUnchangeable: isReadOnly || !supplierProduct.active, // Makes input properly "readonly"
      isReadOnly: isReadOnly || !supplierProduct.active, // Makes input disabled
      label: i18next.t('ADMIN.SUPPLIER_PRODUCTS.SPECIFICATION_SHEET'),
      value:
        truncatedAttachmentLinkName ||
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.NO_SPECIFICATION_SHEET_PLACEHOLDER'),
      placeholder: i18next.t('ADMIN.SUPPLIER_PRODUCTS.NO_SPECIFICATION_SHEET_PLACEHOLDER'),
      tooltipText: i18next.t('ADMIN.SUPPLIER_PRODUCTS.SPECIFICATION_SHEET_TOOLTIP'),
      dropdownActions,
    });
  }

  return inputs;
};

export const SUPPLIER_PRODUCT_ASSOCIATED_INGREDIENT_INPUTS = (props) => {
  const {
    isCreation,
    conversions,
    packagingUnit,
    ingredients,
    entityId,
    canEditFieldsIfCentralKitchenSP,
    handleConversionCreation,
    active,
    canEditSupplierProductFromDetailView,
    supplierProduct: { supplierId },
    user,
  } = props;

  const isReadOnly =
    (!isCreation && !canEditSupplierProductFromDetailView) ||
    hasUserCatalogReadOnlyRights(user, { isCreation, supplierId });

  const DISABLING_RULES = {
    [SUPPLIER_PRODUCT_INPUTS.CONVERSIONS]: () => !packagingUnit || !active || isReadOnly,
    [SUPPLIER_PRODUCT_INPUTS.ASSOCIATED_INGREDIENTS]: () =>
      !canEditFieldsIfCentralKitchenSP || !active || isReadOnly,
    [SUPPLIER_PRODUCT_INPUTS.LOSSES]: () => !canEditFieldsIfCentralKitchenSP || isReadOnly,
  };

  return [
    {
      type: INPUT_TYPES.TEXT_WITH_ACTION,
      button: {
        id: 1,
        text: <img src={'/images/inpulse/gear-white-small.svg'} />,
        isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.CONVERSIONS](),
        handleClick: () => {
          handleConversionCreation(packagingUnit);
        },
      },
      name: SUPPLIER_PRODUCT_INPUTS.CONVERSIONS,
      width: INPUT_WIDTH.LARGE,
      isUnchangeable: true, // Makes input properly "readonly"
      isReadOnly: !packagingUnit, // Makes input disabled
      label: i18next.t('ADMIN.SUPPLIER_PRODUCTS.CONVERSION_PLURAL'),
      placeholder: i18next.t('ADMIN.SUPPLIER_PRODUCTS.CONVERSION_NONE'),
      tooltipText: i18next.t('ADMIN.SUPPLIER_PRODUCTS.CONVERSION_TOOLTIP'),
    },
    {
      type: INPUT_TYPES.SINGLE_SELECT,
      name: SUPPLIER_PRODUCT_INPUTS.ASSOCIATED_INGREDIENTS,
      defaultValue: null,
      label: `${i18next.t('GENERAL.ASSOCIATED_INGREDIENT')} :`,
      width: INPUT_WIDTH.LARGE,
      iconSrc: '/images/inpulse/search-black-small.svg',
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.ASSOCIATED_INGREDIENTS],
      link: entityId
        ? {
            onClick: () => {
              if (entityId) {
                window.open(
                  `${ADMIN_FEATURE_PATHS.ADMIN_INGREDIENTS}/${entityId}/details`,
                  '_blank',
                );
              }
            },
            customStyle: { marginLeft: '8px' },
          }
        : null,
      items: ingredients,
      rule: yup
        .object()
        .nullable()
        .test(
          'areSPAndIngredientSameUnit',
          i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_INGREDIENT_AND_SP_UNIT_ERROR', {
            spUnit: packagingUnit,
          }),
          (value) =>
            !value ||
            !value.unit ||
            (value.unit &&
              doesIngredientsHaveApplicableUnits(packagingUnit, value.unit, conversions)),
        )
        .test(
          (value) =>
            !value ||
            (!value.unit
              ? yup.object().shape({})
              : yup.object().shape({
                  id: yup.string().required(),
                  name: yup.string().required(),
                }).strict),
        ),
    },
    {
      type: INPUT_TYPES.TEXT_NUMBER,
      name: SUPPLIER_PRODUCT_INPUTS.LOSSES,
      width: INPUT_WIDTH.LARGE,
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.LOSSES],
      label: `${i18next.t('GENERAL.LOSSES')} (%)`,
      tooltipText: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_RECIPE_LOSS_TOOLTIP'),
      rule: yup.lazy((value) =>
        value === ''
          ? yup.string()
          : yup
              .number()
              .nullable()
              .min(
                0,
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_GREATER_THAN_ERROR', {
                  field: i18next.t('GENERAL.LOSSES'),
                  value: 0,
                }),
              )
              .max(
                100,
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_LOWER_THAN_ERROR', {
                  field: i18next.t('GENERAL.LOSSES'),
                  value: 100,
                }),
              ),
      ),
    },
  ];
};

export const SUPPLIER_PRODUCT_PRICE_INPUTS = (props) => {
  const {
    isCreation,
    packagings,
    storeName,
    supplierProduct: { currency, supplierId },
    canEditFieldsIfCentralKitchenSP,
    canEditSupplierProductFromDetailView,
    user,
  } = props;

  const isReadOnly =
    (!isCreation && !canEditSupplierProductFromDetailView) ||
    hasUserCatalogReadOnlyRights(user, { isCreation, supplierId });

  const DISABLING_RULES = {
    [SUPPLIER_PRODUCT_INPUTS.INVOICE_PACKAGING]: () =>
      !canEditFieldsIfCentralKitchenSP || isEmpty(packagings) || isReadOnly,
    [SUPPLIER_PRODUCT_INPUTS.PRICE]: () => !canEditFieldsIfCentralKitchenSP || isReadOnly,
    [SUPPLIER_PRODUCT_INPUTS.CURRENCY]: () => true,
    [SUPPLIER_PRODUCT_INPUTS.VAT_RATE]: () => !canEditFieldsIfCentralKitchenSP || isReadOnly,
    [SUPPLIER_PRODUCT_INPUTS.PRICE_EDITABLE_AT_RECEPTION]: () =>
      !canEditFieldsIfCentralKitchenSP || isReadOnly,
  };

  const storeNameToLowerCase = getClientStoreNameTranslation(storeName, true).toLowerCase();

  return [
    {
      type: INPUT_TYPES.SINGLE_SELECT,
      sortBy: sortPackagings,
      name: SUPPLIER_PRODUCT_INPUTS.INVOICE_PACKAGING,
      label: `${i18next.t('ADMIN.SUPPLIER_PRODUCTS.INVOICE_PACKAGING')} :`,
      tooltipText: i18next.t('ADMIN.SUPPLIER_PRODUCTS.INVOICE_PACKAGING_TOOLTIP'),
      width: INPUT_WIDTH.LARGE,
      isRequired: true,
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.INVOICE_PACKAGING],
      items: packagings,
    },
    {
      type: INPUT_TYPES.TEXT_NUMBER,
      name: SUPPLIER_PRODUCT_INPUTS.PRICE,
      width: INPUT_WIDTH.LARGE,
      tooltipText: i18next.t('ADMIN.SUPPLIER_PRODUCTS.PRICE_TOOLTIP', {
        storeName: storeNameToLowerCase,
      }),
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.PRICE],
      label: `${i18next.t('GENERAL.PRICE_EX_TAX')}`,
      rule: yup.lazy((value) =>
        value === ''
          ? yup.string()
          : yup
              .number()
              .min(
                0,
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_GREATER_THAN_ERROR', {
                  field: i18next.t('GENERAL.PRICE_EX_TAX'),
                  value: 0,
                }),
              )
              .nullable()
              .typeError(i18next.t('ADMIN.CENTRAL_PRODUCTS.VALUE_MUST_BE_A_NUMBER')),
      ),
    },
    {
      type: INPUT_TYPES.SINGLE_SELECT,
      name: SUPPLIER_PRODUCT_INPUTS.CURRENCY,
      label: `${i18next.t('GENERAL.CURRENCY')} :`,
      width: INPUT_WIDTH.LARGE,
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.CURRENCY],
      isRequired: true,
      selectedItem: currency,
    },
    {
      type: INPUT_TYPES.TEXT_NUMBER,
      name: SUPPLIER_PRODUCT_INPUTS.VAT_RATE,
      width: INPUT_WIDTH.LARGE,
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.VAT_RATE],
      tooltipText: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_VAT_RATE_TOOLTIP'),
      label: `${i18next.t('GENERAL.VAT_RATE')} (%)`,
      rule: yup.lazy((value) =>
        value === ''
          ? yup.string()
          : yup
              .number()
              .min(
                0,
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_GREATER_THAN_ERROR', {
                  field: i18next.t('GENERAL.VAT_RATE'),
                  value: 0,
                }),
              )
              .max(
                100,
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_LOWER_THAN_ERROR', {
                  field: i18next.t('GENERAL.VAT_RATE'),
                  value: 100,
                }),
              )
              .nullable(),
      ),
    },
    {
      type: INPUT_TYPES.TOGGLE,
      name: SUPPLIER_PRODUCT_INPUTS.PRICE_EDITABLE_AT_RECEPTION,
      width: INPUT_WIDTH.LARGE,
      label: i18next.t('ADMIN.SUPPLIER_PRODUCTS.TOGGLE_PRICE_EDITABLE_AT_RECEPTION'),
      tooltipText: i18next.t('ADMIN.SUPPLIER_PRODUCTS.TOGGLE_PRICE_EDITABLE_AT_RECEPTION_TOOLTIP'),
      parentContainerStyle: { height: '68px' },
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.PRICE_EDITABLE_AT_RECEPTION],
    },
  ];
};

export const SUPPLIER_PRODUCT_SCHEDULE_PRICE_INPUTS = ({
  isCreation,
  scheduledPriceValue,
  scheduledPriceDate,
  canEditFieldsIfCentralKitchenSP,
  canEditSupplierProductFromDetailView,
  supplierProduct: { supplierId },
  user,
}) => {
  const isReadOnly =
    !canEditFieldsIfCentralKitchenSP ||
    (!isCreation && !canEditSupplierProductFromDetailView) ||
    hasUserCatalogReadOnlyRights(user, { isCreation, supplierId });

  const DISABLING_RULES = {
    [SUPPLIER_PRODUCT_INPUTS.SCHEDULED_PRICE_VALUE]: () => isReadOnly,
    [SUPPLIER_PRODUCT_INPUTS.SCHEDULED_PRICE_DATE]: () => isReadOnly,
  };

  const tomorrow = moment().add(1, 'days');

  return [
    {
      type: INPUT_TYPES.TEXT_NUMBER,
      name: SUPPLIER_PRODUCT_INPUTS.SCHEDULED_PRICE_VALUE,
      width: INPUT_WIDTH.LARGE,
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.SCHEDULED_PRICE_VALUE],
      label: i18next.t('ADMIN.SUPPLIER_PRODUCTS.SCHEDULE_PRICE_INPUT_LABEL'),
      rule: yup.lazy((value) =>
        value === ''
          ? yup.string().test(
              'bothFieldNotEmpty',
              i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
                field: i18next.t('ADMIN.SUPPLIER_PRODUCTS.SCHEDULE_PRICE_INPUT_LABEL'),
              }),
              () => !!scheduledPriceDate && !!value,
            )
          : yup
              .number()
              .typeError(i18next.t('ADMIN.SUPPLIER_PRODUCTS.SCHEDULED_PRICE_MUST_BE_A_NUMBER'))
              .min(
                0,
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_GREATER_THAN_ERROR', {
                  field: i18next.t('ADMIN.SUPPLIER_PRODUCTS.SCHEDULE_PRICE_INPUT_LABEL'),
                  value: 0,
                }),
              )
              .test(
                'bothFieldNotEmpty',
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
                  field: i18next.t('ADMIN.SUPPLIER_PRODUCTS.SCHEDULE_PRICE_INPUT_LABEL'),
                }),
                (value) => (!!scheduledPriceDate ? value >= 0 : true),
              ),
      ),
    },
    {
      type: INPUT_TYPES.SINGLE_DATEPICKER,
      name: SUPPLIER_PRODUCT_INPUTS.SCHEDULED_PRICE_DATE,
      width: INPUT_WIDTH.LARGE,
      isDisabled: DISABLING_RULES[SUPPLIER_PRODUCT_INPUTS.SCHEDULED_PRICE_DATE],
      label: i18next.t('ADMIN.SUPPLIER_PRODUCTS.CHANGE_AVAILABILITY_DATE_PICKER_LABEL'),
      tooltipText: i18next.t('ADMIN.SUPPLIER_PRODUCTS.CHANGE_PRICE_DATE_PICKER_TOOLTIP'),
      maxPastDate: tomorrow,
      showClearDate: true,
      rule: yup
        .date()
        .test(
          'bothFieldNotEmpty',
          i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
            field: i18next.t('ADMIN.SUPPLIER_PRODUCTS.CHANGE_DATE_PICKER_LABEL'),
          }),
          (value) => !(!!scheduledPriceValue && scheduledPriceValue !== 0 && !value),
        )
        .nullable(),
    },
  ];
};

export const SUPPLIER_PRODUCT_CUSTOM_PRICE_INPUTS = (props) => {
  const {
    isCreation,
    inputPrices,
    canEditSupplierProductFromDetailView,
    supplierProduct: { supplierId },
    user,
  } = props;

  const formattedInputPrices = inputPrices || [];

  const inputs = formattedInputPrices.reduce((result, { id, name }) => {
    result.push({
      type: INPUT_TYPES.TEXT_NUMBER,
      name: id,
      width: INPUT_WIDTH.LARGE,
      label: name,
      isDisabled: () =>
        (!isCreation && !canEditSupplierProductFromDetailView) ||
        hasUserCatalogReadOnlyRights(user, { isCreation, supplierId }),
      rule: yup.lazy((value) =>
        value === ''
          ? yup.string()
          : yup
              .number()
              .min(
                0,
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_GREATER_THAN_ERROR', {
                  field: name,
                  value: 0,
                }),
              )
              .nullable(),
      ),
    });

    return result;
  }, []);

  return inputs;
};

export const SUPPLIER_PRODUCT_WEIGHT_INPUTS = (props) => {
  const {
    isCreation,
    canEditFieldsIfCentralKitchenSP,
    canEditSupplierProductFromDetailView,
    supplierProduct: { supplierId },
    user,
  } = props;

  return [
    {
      type: INPUT_TYPES.TEXT_NUMBER,
      name: SUPPLIER_PRODUCT_INPUTS.WEIGHT,
      width: INPUT_WIDTH.LARGE,
      isDisabled: () =>
        !canEditFieldsIfCentralKitchenSP ||
        (!isCreation && !canEditSupplierProductFromDetailView) ||
        hasUserCatalogReadOnlyRights(user, { isCreation, supplierId }),
      label: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONNING_PACKAGING_WEIGHT'),
      tooltipText: i18next.t(
        'ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONNING_PACKAGING_WEIGHT_TOOLTIP',
      ),
      rule: yup.lazy((value) =>
        value === ''
          ? yup.string()
          : yup
              .number()
              .min(
                0,
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_GREATER_THAN_ERROR', {
                  field: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONNING_PACKAGING_WEIGHT'),
                  value: 0,
                }),
              )
              .nullable(),
      ),
    },
  ];
};

export const SUPPLIER_PRODUCT_FORM_INPUTS = (props) => [
  ...SUPPLIER_PRODUCT_GENERAL_INFOS_INPUTS(props),
  ...SUPPLIER_PRODUCT_ASSOCIATED_INGREDIENT_INPUTS(props),
  ...SUPPLIER_PRODUCT_PRICE_INPUTS(props),
  ...SUPPLIER_PRODUCT_SCHEDULE_PRICE_INPUTS(props),
  ...SUPPLIER_PRODUCT_CUSTOM_PRICE_INPUTS(props),
  ...SUPPLIER_PRODUCT_WEIGHT_INPUTS(props),
];

export default {
  SUPPLIER_PRODUCT_FORM_INPUTS,
  SUPPLIER_PRODUCT_GENERAL_INFOS_INPUTS,
  SUPPLIER_PRODUCT_PRICE_INPUTS,
  SUPPLIER_PRODUCT_SCHEDULE_PRICE_INPUTS,
  SUPPLIER_PRODUCT_CUSTOM_PRICE_INPUTS,
  SUPPLIER_PRODUCT_ASSOCIATED_INGREDIENT_INPUTS,
  SUPPLIER_PRODUCT_WEIGHT_INPUTS,
};
