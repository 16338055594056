import i18next from 'i18next';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

// Design
import { Container, EventContainer, Icon } from './styledComponents';

// Common
import { DATE_DISPLAY_FORMATS, TIME_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import Text, { ENUM_FONTS } from '@commons/Text';

/**
 * @typedef {Object} ChangelogProp
 * @property {string|undefined} author - Author name
 * @property {Date} datetime - Date
 * @property {string} event - Event
 */

/**
 * @param {ChangelogProp} props
 *
 * @return {React.JSX.Element}
 */
const Changelog = (props) => {
  const { icon, author, datetime, event } = props;

  const formattedDate = moment(datetime).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);
  const formattedTime = moment(datetime).format(TIME_DISPLAY_FORMATS.SLASHED_HOUR_MINUTE_SECOND);

  return (
    <Container>
      <Icon src={icon} />
      <EventContainer>
        <Text font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14_WEIGHT_700_BOLD}>{event}</Text>
        <Text font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14}>
          {author &&
            i18next.t('GENERAL.CHANGELOG_DATE_WITH_AUTHOR', {
              author,
              date: formattedDate,
              time: formattedTime,
            })}
          {!author &&
            i18next.t('GENERAL.CHANGELOG_DATE', {
              date: formattedDate,
              time: formattedTime,
            })}
        </Text>
      </EventContainer>
    </Container>
  );
};

Changelog.propTypes = {
  icon: PropTypes.string,
  author: PropTypes.string,
  datetime: PropTypes.string,
  event: PropTypes.string,
};

Changelog.defaultProps = {
  icon: '/images/inpulse/edit-pen-black-small.svg',
  author: '',
  datetime: new Date(),
  event: '',
};

export default Changelog;
