import moment from 'moment-timezone';
import React from 'react';

import {
  InpulseDateContainer,
  InpulseDate,
  DotContainer,
  CircleContainer,
  Dot,
  Circle,
} from './styledComponents';

export const InpulseDatePickerDotedDay = (props) => {
  const { item, isDot, renderCircle, borderColor, color } = props;

  return (
    <>
      <InpulseDateContainer borderColor={borderColor} renderCircle={renderCircle}>
        <CircleContainer renderCircle={renderCircle}>
          <Circle borderColor={borderColor} renderCircle={renderCircle}>
            <DotContainer isDot={isDot} renderCircle={renderCircle}>
              <Dot color={color} isDot={isDot} renderCircle={renderCircle} />
            </DotContainer>
            <InpulseDate isDot={isDot} renderCircle={renderCircle}>
              {moment(item).format('D')}
            </InpulseDate>
          </Circle>
        </CircleContainer>
        <DotContainer isDot={isDot} renderCircle={!renderCircle}>
          <Dot color={color} isDot={isDot && !renderCircle} renderCircle={renderCircle} />
        </DotContainer>
        <InpulseDate isDot={isDot} renderCircle={!renderCircle}>
          {moment(item).format('D')}
        </InpulseDate>
      </InpulseDateContainer>
    </>
  );
};

export default InpulseDatePickerDotedDay;
