import i18next from 'i18next';
import React, { useState } from 'react';

import { Tooltip, Button } from '@commons/utils/styledLibraryComponents';

import { Input } from '@lib/inpulse/Input';

import { Container, Hbar, Header, Title, Content, Center, Footer, Text } from './styledComponents';
export const exitModal = (closeModal) => {
  closeModal();
};

export const renderContent = (props) => {
  const { closeModal, titleModal, maxDistance, setMaxDistance, callback } = props;

  return (
    <div>
      <Header>
        <Title>{titleModal}</Title>
      </Header>

      <Content>
        <Center>
          <Text>{i18next.t('BACKOFFICE.CHECKS.MAX_DISTANCE_MODAL_INPUT')}</Text>
          <Tooltip text={i18next.t('BACKOFFICE.CHECKS.MAX_DISTANCE_MODAL_TOOLTIP')} />
        </Center>
        <Center>
          <Input
            height={35}
            type="number"
            value={maxDistance}
            width={150}
            onChange={(d) => {
              setMaxDistance(d.target.value ? parseInt(d.target.value) : 0);
            }}
          />
        </Center>
      </Content>
      <Hbar></Hbar>
      <Footer>
        <div style={{ marginRight: '25px' }}>
          <Button
            color={'blue-outline-no-shadow'}
            fontSize={14}
            formatText={false}
            handleClick={() => exitModal(closeModal)}
            icon={'/images/inpulse/close-black-small.svg'}
            label={i18next.t('GENERAL.CANCEL')}
            minWidth={100}
          />
        </div>
        <Button
          color={'blue'}
          fontSize={14}
          formatText={false}
          handleClick={() => (callback(maxDistance), exitModal(closeModal))}
          icon={'/images/inpulse/check-white-small.svg'}
          label={i18next.t('GENERAL.VALIDATE')}
          minWidth={100}
        />
      </Footer>
    </div>
  );
};

export const WeatherStationMaxDistanceModal = (props) => {
  const {
    params: { callback, title },
    closeModal,
  } = props;

  const [maxDistance, setMaxDistance] = useState(30);
  const [titleModal] = useState(title || '');

  return (
    <Container>
      {renderContent({
        ...props,
        closeModal,
        titleModal,
        maxDistance,
        setMaxDistance,
        callback,
      })}
    </Container>
  );
};

export default WeatherStationMaxDistanceModal;
