import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { formatNumberToExcelUsage } from '@commons/DisplayNumber';
import { getClientStoreNameTranslation } from '@commons/utils/translations';

import { getEntityUnit } from '@stocks/utils';

export const getRecipeColumnsSettings = () => [
  {
    displayName: i18next.t('GENERAL.NAME'),
    keyName: 'name',
    transform: (value) => value,
  },
  {
    displayName: i18next.t('ADMIN.INGREDIENTS.EXPORT_LABEL_CATEGORY'),
    keyName: 'category',
    transform: (value) => value,
  },
  {
    displayName: i18next.t('FEATURE.FORECASTS.FORECASTS'),
    keyName: 'quantity',
    transform: (value) => formatNumberToExcelUsage(value, 2),
  },
  {
    displayName: i18next.t('ADMIN.INGREDIENTS.EXPORT_LABEL_UNIT'),
    keyName: 'unit',
    transform: (value) =>
      getEntityUnit(value) === 'u.' ? i18next.t('GENERAL.UNIT') : getEntityUnit(value),
  },
  {
    displayName: i18next.t(
      'PRODUCTION.RECOMMENDATIONS.CENTRAL_KITCHEN_RECOMMENDATIONS_EXPORT_IN_PRODUCTION_COLUMN',
    ),
    keyName: 'isProducedByCentralKitchen',
    transform: (value) => (value ? i18next.t('GENERAL.YES') : i18next.t('GENERAL.NO')),
  },
];

export const getIngredientsColumnsSettings = () => [
  {
    displayName: i18next.t('GENERAL.NAME'),
    keyName: 'name',
    transform: (value) => value,
  },
  {
    displayName: i18next.t('ADMIN.INGREDIENTS.EXPORT_LABEL_CATEGORY'),
    keyName: 'category',
    transform: (value) => value,
  },
  {
    displayName: i18next.t('FEATURE.FORECASTS.FORECASTS'),
    keyName: 'quantity',
    transform: (value) => formatNumberToExcelUsage(value, 2),
  },
  {
    displayName: i18next.t('ADMIN.INGREDIENTS.EXPORT_LABEL_UNIT'),
    keyName: 'unit',
    transform: (value) =>
      getEntityUnit(value) === 'u.' ? i18next.t('GENERAL.UNIT') : getEntityUnit(value),
  },
];

export const getInformationsColumnsSettings = (storeName) => [
  {
    displayName: i18next.t('GENERAL.CENTRAL_KITCHEN'),
    keyName: 'centralKitchenName',
    transform: (value) => value,
  },
  {
    displayName: getClientStoreNameTranslation(storeName, false),
    keyName: 'storeName',
    transform: (value) => value,
  },
  {
    displayName: i18next.t('FORECAST.PAST_MIX.PERIOD_START_COLUMN'),
    keyName: 'startDate',
    transform: (value) => moment(value).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
  },
  {
    displayName: i18next.t('FORECAST.PAST_MIX.PERIOD_END_COLUMN'),
    keyName: 'endDate',
    transform: (value) => moment(value).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
  },
];
