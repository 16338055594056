import { connect } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { buildSchema } from '@commons/GenericForm';
import { getTheme } from '@commons/utils/theme';
import Footer from '@commons/Footer/Footer';
import NavigationBar from '@commons/NavigationBar';
import Text, { ENUM_FONTS } from '@commons/Text';

import { getClientInfo } from '@selectors/client';

import navigationUtils from '@admin/inventories/utils/navigation.utils';

import catalogsService from '@services/catalogs';

import { CATALOG_FORM_INPUTS, FIELDS_TO_CHECK } from '../../utils/formInputsConfiguration';

import CatalogInformations from './Informations';
import CatalogUserMappings from './UserMappings';

import {
  Container,
  ButtonsContainer,
  StepsContainer,
  Step,
  LabelContainer,
  LabelIcon,
  ContentContainer,
} from './styledComponents';

const STEP_1_DETAILS = 'details';
const STEP_2_USERS_MAPPING = 'usersMapping';

const CatalogCreation = (props) => {
  const {
    history,
    match: { path },
    pageLoading,
    pageLoaded,
    client: { clientId },
    showErrorMessage,
    showSuccessMessage,
  } = props;

  const STEPS_CATALOG_CREATION = [
    { key: STEP_1_DETAILS, label: i18next.t('GENERAL.INFORMATIONS') },
    {
      key: STEP_2_USERS_MAPPING,
      label: i18next.t('GENERAL.USERS'),
    },
  ];

  const STEPS_MAX_INDEX = STEPS_CATALOG_CREATION.length - 1;

  const theme = getTheme();

  const [isSaveAlreadyTriggered, setIsSaveAlreadyTriggered] = useState(false);
  const [currentStep, setCurrentStep] = useState(STEPS_CATALOG_CREATION[0].key);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const catalogForm = useForm({
    defaultValues: {},
    resolver: yupResolver(buildSchema(CATALOG_FORM_INPUTS())),
  });

  const formFields = useWatch({
    control: catalogForm.control,
  });

  const createCatalog = async () => {
    setIsSaveDisabled(true);

    pageLoading();

    try {
      const accountMappings = formFields.accountMappings || [];
      const accountIds = accountMappings.map(({ id }) => id);

      await catalogsService.createCatalog(clientId, formFields.name, accountIds);

      showSuccessMessage(i18next.t('ADMIN.CATALOGS.CREATION_SUCCESS'));
      history.push('/admin/catalogs');
    } catch {
      showErrorMessage(i18next.t('ADMIN.CATALOGS.CREATION_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const returnToCatalogsList = () => history.goBack();

  const previousStepHandler = () => {
    setIsSaveDisabled(false);

    const currentStepIndex = STEPS_CATALOG_CREATION.findIndex(({ key }) => key === currentStep);

    if (currentStepIndex > 0) {
      setCurrentStep(STEPS_CATALOG_CREATION[currentStepIndex - 1].key);
    }

    if (currentStepIndex === 0) {
      returnToCatalogsList();
    }
  };

  const nextStepHandler = async () => {
    const fieldsValidation = await catalogForm.trigger(FIELDS_TO_CHECK);

    switch (currentStep) {
      case STEPS_CATALOG_CREATION[0].key:
        setIsSaveAlreadyTriggered(true);

        if (!fieldsValidation) {
          setIsSaveDisabled(true);
          return;
        }

        break;
      case STEPS_CATALOG_CREATION[1].key:
        setIsSaveDisabled(false);

        break;
      default:
    }

    const currentStepIndex = STEPS_CATALOG_CREATION.findIndex(({ key }) => key === currentStep);

    if (currentStepIndex < STEPS_MAX_INDEX) {
      setCurrentStep(STEPS_CATALOG_CREATION[currentStepIndex + 1].key);
      setIsSaveAlreadyTriggered(false);
    }

    if (currentStepIndex === STEPS_MAX_INDEX) {
      await createCatalog();
    }
  };

  useEffect(() => {
    (async () => {
      switch (currentStep) {
        case STEPS_CATALOG_CREATION[0].key:
          const fieldsValidation = await catalogForm.trigger(FIELDS_TO_CHECK);
          setIsSaveDisabled(!fieldsValidation && isSaveAlreadyTriggered);
          break;
        case STEPS_CATALOG_CREATION[1].key:
          setIsSaveDisabled(false);
          break;
        default:
          return;
      }
    })();
  }, [formFields]);

  return (
    <>
      <NavigationBar
        catalog={{ name: i18next.t('ADMIN.CATALOGS.CREATION_TOP_BAR_TITLE') }}
        displaySubFeatures={false}
        path={path}
        bigTopBar
        enableActionBottomOrder
      />
      <Container>
        <ContentContainer>
          {currentStep === STEPS_CATALOG_CREATION[0].key && (
            <CatalogInformations
              catalogForm={catalogForm}
              isSaveAlreadyTriggered={isSaveAlreadyTriggered}
            />
          )}
          {currentStep === STEPS_CATALOG_CREATION[1].key && (
            <CatalogUserMappings catalogForm={catalogForm} formFields={formFields} />
          )}
        </ContentContainer>
        <Footer>
          <StepsContainer>
            {STEPS_CATALOG_CREATION.map(({ key, label }, index) => (
              <Step key={`step-${index}`}>
                <LabelContainer isActive={currentStep === key}>
                  <LabelIcon isActive={currentStep === key}>{index + 1}</LabelIcon>
                  <Text
                    children={label}
                    color={
                      currentStep === key
                        ? theme.colors.modalHeaders.green.text
                        : theme.colors.greys.dark
                    }
                    font={ENUM_FONTS.TEXT_MIDDLE_BOLD}
                  />
                </LabelContainer>
                {index !== STEPS_CATALOG_CREATION.length - 1 && (
                  <img src={'/images/inpulse/chevron-right-dmgrey-small.svg'} />
                )}
              </Step>
            ))}
            <ButtonsContainer>
              {navigationUtils.renderPreviousButton(
                currentStep === STEP_1_DETAILS,
                previousStepHandler,
              )}
              {navigationUtils.renderNextButton(
                currentStep === STEP_2_USERS_MAPPING,
                isSaveDisabled,
                nextStepHandler,
              )}
            </ButtonsContainer>
          </StepsContainer>
        </Footer>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CatalogCreation);
