import React from 'react';

import { MapIngredientModalContainer } from './styledComponents';

import RecipeSection, { MODE_TYPES } from '../../../RecipeSection';

export const MapIngredientModal = (props) => {
  const { mapIngredientData, setMapIngredientData, supplierProduct } = props;

  const handleIngredientMappingSelection = (entityIdInput, entityNameInput) => {
    const updatedSp = {
      ...supplierProduct,
      entityId: entityIdInput,
      entity: !!entityNameInput ? { id: entityIdInput, name: entityNameInput } : {},
    };

    setMapIngredientData({
      ...mapIngredientData,
      updatedSupplierProduct: updatedSp,
    });
  };

  return (
    <MapIngredientModalContainer>
      <RecipeSection
        closeModal={() => {}}
        enablingSupplierProduct={true}
        handleIngredientMappingSelection={handleIngredientMappingSelection}
        ingredients={mapIngredientData.ingredientsList}
        inRecipe={mapIngredientData.inRecipe}
        mode={MODE_TYPES.EDIT}
        supplierProduct={mapIngredientData.updatedSupplierProduct}
      />
    </MapIngredientModalContainer>
  );
};
