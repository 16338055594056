// ===== Module features for easy import in ClientContainer=====

import BackOfficeChecks from './BackOfficeChecks';
import BackOfficeGeneralContainer from './BackOfficeGeneralContainer';
import BackOfficeOnboardingContainer from './BackOfficeOnboardingContainer';
import BackOfficeProductModulesContainer from './BackOfficeProductModulesContainer';
import BackOfficeStoresContainer from './BackOfficeStoresContainer';
import BackOfficeTranslations from './BackOfficeTranslations';
import BackOfficeUsersContainer from './BackOfficeUsersContainer';

const BackOfficeModules = {
  BackOfficeChecks,
  BackOfficeTranslations,
  ...BackOfficeUsersContainer,
  ...BackOfficeStoresContainer,
  ...BackOfficeOnboardingContainer,
  ...BackOfficeGeneralContainer,
  ...BackOfficeProductModulesContainer,
};

export default BackOfficeModules;
