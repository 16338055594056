import _ from 'lodash';
import i18next from 'i18next';

import utilsXLS from '@commons/utils/makeXLS';

const getGroupsString = (groups) => {
  if (_.isEmpty(groups)) {
    return '-';
  }
  return groups.reduce(
    (groupNames, group, index) => groupNames.concat(index === 0 ? '' : ', ', group.name),
    '',
  );
};

const getMainSheetColumnsSettings = () => {
  const columns = [
    {
      displayName: i18next.t('GENERAL.ID'),
      keyName: 'id',
    },
    {
      displayName: i18next.t('GENERAL.NAME'),
      keyName: 'name',
    },
    {
      displayName: i18next.t('GENERAL.BRAND'),
      keyName: 'brandName',
    },
    {
      displayName: i18next.t('GENERAL.GROUPS'),
      keyName: 'mappedGroups',
      transform: (groups) => getGroupsString(groups),
    },
    {
      displayName: i18next.t('GENERAL.CREATION_DATE'),
      keyName: 'createdAt',
    },
    {
      displayName: i18next.t('BACKOFFICE.STORES.EXPORT_LAUNCH_DATE'),
      keyName: 'launchDate',
    },
    {
      displayName: i18next.t('GENERAL.USERS'),
      keyName: 'mappedAccounts',
    },
  ];

  return columns;
};

const createMainSheet = (stores, sheetName) => {
  const headersSettings = getMainSheetColumnsSettings();

  const headers = headersSettings.map(({ displayName }) => displayName);

  const mappedData = stores.map((store) =>
    headersSettings.map(({ keyName, transform }) => {
      if (!transform) {
        return store[keyName];
      }
      return transform(store[keyName]);
    }),
  );

  return {
    title: sheetName,
    headers,
    data: mappedData,
  };
};

export function exportStores(stores, storeName, clientName) {
  if (!stores) {
    return;
  }

  const fileName = `${storeName} - ${clientName}`;
  const sheetName = storeName;

  const mainSheet = createMainSheet(stores, sheetName);

  utilsXLS.makeXLS(fileName, [mainSheet]);
}
