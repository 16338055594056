const getChartBorder = (isFirst, isLast, isInPeriod) => {
  if (!!isFirst && !!isLast) {
    return { radius: '8px 8px 0 0', width: '1px 1px 0 1px' };
  }

  if (!!isFirst && !isLast) {
    return { radius: '8px 0 0 0', width: '1px 0 0 1px' };
  }

  if (!!isLast && !isFirst) {
    return { radius: '0 8px 0 0', width: '1px 1px 0 0' };
  }

  if (isInPeriod) {
    return { radius: '0', width: '1px 0 0 0' };
  }

  return { radius: '0', width: '0' };
};

export default getChartBorder;
