import './TabletDeepsightButton.css';
import Proptypes from 'prop-types';
import React from 'react';

const TabletDeepsightButton = (props) => {
  const { value, leftIcon, rightIcon, onClick, iconCenter, readOnly } = props;
  return (
    <button
      className={`deepsight-button ${readOnly ? 'locked' : 'white'}`}
      style={{ width: '80px', height: '50px' }}
      onClick={() => {
        if (readOnly) {
          return;
        }

        onClick();
      }}
    >
      {leftIcon.toString() !== '' && (
        <img alt={value} className={'icon' + (iconCenter ? '-center' : '')} src={leftIcon} />
      )}
      <div className={'title'}>{value}</div>
      {rightIcon.toString() !== '' && (
        <img alt={value} className={'icon' + (iconCenter ? '-center' : '')} src={rightIcon} />
      )}
    </button>
  );
};

TabletDeepsightButton.propTypes = {
  onClick: Proptypes.func.isRequired,
  value: Proptypes.string.isRequired,
  buttonStyle: Proptypes.string,
  className: Proptypes.string,
  style: Proptypes.objectOf(Proptypes.string),
  width: Proptypes.number,
  height: Proptypes.number,
  leftIcon: Proptypes.string,
  rightIcon: Proptypes.string,
  formatText: Proptypes.bool,
  readOnly: Proptypes.bool,
};

TabletDeepsightButton.defaultProps = {
  buttonStyle: '',
  className: '',
  style: {},
  width: 180,
  height: 36,
  leftIcon: '',
  rightIcon: '',
  formatText: true,
  readOnly: false,
};

export default TabletDeepsightButton;
