import i18next from 'i18next';

const HOLIDAY_ZONES = [{ name: 'A' }, { name: 'B' }, { name: 'C' }, { name: 'Corse' }];

const getStoreLocationCardSettings = (
  storeLocation,
  locations,
  isCreation,
  hasHolidayZone,
  hasMultipleTimezones,
  countryHasUniqueTimezone,
) => {
  const inputs = [
    {
      dropdownValue: locations,
      icon: '/images/inpulse/location-black-small.svg',
      emptyIcon: '/images/inpulse/location-dmgrey-small.svg',
      disabledIcon: '/images/inpulse/location-white-small.svg',
      hasTooltip: false,
      isDropdown: true,
      dropdownMatchingProperty: 'id',
      isRequired: false,
      isSwitch: false,
      readOnly: !locations.length,
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      label: `${i18next.t('BACKOFFICE.STORES.LOCATION')}`,
      propertyKey: 'location',
      type: 'dropdown',
    },
    {
      dropdownValue: null,
      hasTooltip: false,
      icon: null,
      isDropdown: false,
      isRequired: true,
      isSwitch: false,
      label: `* ${i18next.t('BACKOFFICE.STORES.ADDRESS')}`,
      placeholder: i18next.t('BACKOFFICE.STORES.ADDRESS'),
      propertyKey: 'adress',
      type: 'text',
    },
    {
      dropdownValue: null,
      hasTooltip: false,
      icon: null,
      isDropdown: false,
      isRequired: true,
      isSwitch: false,
      label: `* ${i18next.t('BACKOFFICE.STORES.POSTAL_CODE')}`,
      placeholder: i18next.t('BACKOFFICE.STORES.POSTAL_CODE'),
      propertyKey: 'postCode',
      type: 'text',
    },
    {
      dropdownValue: null,
      hasTooltip: false,
      icon: null,
      isDropdown: false,
      isRequired: true,
      isSwitch: false,
      label: `* ${i18next.t('GENERAL.CITY')}`,
      placeholder: i18next.t('GENERAL.CITY'),
      propertyKey: 'city',
      type: 'text',
    },
    {
      icon: null,
      isCountryDropdown: true,
      isDropdown: false,
      isRequired: true,
      isSwitch: false,
      readOnly: !isCreation,
      label: i18next.t('GENERAL.COUNTRY'),
      placeholder: i18next.t('GENERAL.COUNTRY'),
      propertyKey: 'country',
      type: 'dropdown',
    },
    {
      dropdownValue: null,
      hasTooltip: false,
      icon: null,
      isDropdown: false,
      isRequired: false,
      isSwitch: false,
      label: i18next.t('BACKOFFICE.STORES.ADDITIONNAL_ADDRESS'),
      placeholder: i18next.t('BACKOFFICE.STORES.ADDITIONNAL_ADDRESS'),
      propertyKey: 'additionnalAddress',
      type: 'text',
    },
    {
      label: i18next.t('BACKOFFICE.STORES.TIMEZONE'),
      propertyKey: 'timezone',
      isTimezoneDropdown: true,
      isRequired: true,
      readOnly:
        !hasMultipleTimezones ||
        (hasMultipleTimezones &&
          (!storeLocation.country ||
            !isCreation ||
            (!!storeLocation.country && countryHasUniqueTimezone))),
    },
    {
      dropdownValue: HOLIDAY_ZONES,
      hasTooltip: true,
      icon: 'none',
      emptyIcon: 'none',
      isDropdown: true,
      dropdownMatchingProperty: 'name',
      isRequired: true,
      isSwitch: false,
      readOnly: !hasHolidayZone,
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      label: i18next.t('BACKOFFICE.STORES.HOLIDAY_ZONE'),
      propertyKey: 'holidayZone',
      tooltipText: i18next.t('BACKOFFICE.STORES.HOLIDAY_ZONE_TOOLTIP'),
      type: 'dropdown',
    },
  ];

  return {
    title: i18next.t('BACKOFFICE.STORES.LOCATION'),
    inputs: inputs.reduce((acc, input) => {
      acc.push({ ...input, value: storeLocation[input.propertyKey] });
      return acc;
    }, []),
  };
};

export default getStoreLocationCardSettings;
