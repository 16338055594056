import styled, { css } from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  padding-top: 104px;

  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;

  justify-content: center;

  ${({ isFooterDisplayed }) =>
    isFooterDisplayed &&
    css`
      height: calc(100% - 56px);
    `};
`;

export const ButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: end;
  gap: 16px;
`;
