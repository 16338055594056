import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(122, 122, 122, 0.1);
`;

export const EmptyStateContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MainText = styled.div`
  margin-top: 20px;

  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.textBig};
`;

export const TextContainer = styled.div`
  text-align: center;
`;

export const EmptyStateIcon = styled.img``;
