import { getAllTimezones, getTimezonesForCountry } from 'countries-and-timezones';
import { keyBy } from 'lodash';

import format from './format';

const _computeTimezones = (timezones) =>
  Object.keys(timezones).reduce((acc, timezone, index) => {
    const formattedTimezone = format.formatToInpulseTimezone(timezones[timezone]);

    acc.push({
      ...timezones[timezone],
      // defaultTimezone is the default display of timezone for Inpulse
      defaultTimezone: formattedTimezone,
      id: index,
      value: formattedTimezone, // only format value, name is still the same
      renderValue: () => formattedTimezone,
    });

    return acc;
  }, []);

const getAll = () => {
  const timezones = getAllTimezones();

  return _computeTimezones(timezones);
};

const getCountryTimezones = (countryCode) => {
  if (!countryCode) {
    return null;
  }
  const countryTimezones = getTimezonesForCountry(countryCode);

  return _computeTimezones(keyBy(countryTimezones, 'name'));
};

// TODO let expand this with utils function for get and set timezone of users
export default {
  getAll,
  format,
  getCountryTimezones,
};
