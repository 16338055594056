import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${(props) => props.theme.colors?.greys?.lighter};
`;

export const Content = styled.div`
  height: calc(100% - 40px);

  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;

  .location-filter-result-text,
  .location-filter-input-text {
    text-transform: capitalize;
  }

  padding: 24px 0;
`;

export const Label = styled.div`
  margin-bottom: 8px;

  color: ${(props) => props.theme.colors?.greys?.darkest};
  font: ${(props) => props.theme.fonts?.textBigHeight16};
`;

export const InformationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const PropertyContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  height: 64px;
  margin-bottom: 24px;
`;

export const ContentContainer = styled.div``;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 240px;
  height: 64px;
  gap: 8px;
`;

export const DefaultTimezoneContainer = styled.div`
  display: flex;
  align-items: center;
  width: 240px;
  height: 64px;
  gap: 8px;
  margin-bottom: 32px;
`;

export const ModalWarningContentContainer = styled.div`
  height: 296px;
`;

export const ModalWarningTimezoneContainer = styled.div`
  margin-top: 8px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
  position: relative;

  > div {
    justify-content: center;
  }
`;

export const ErrorText = styled.div`
  color: ${(props) => props.theme.colors.infoRed};
  font: ${(props) => props.theme.fonts.textSmallHeight14Weight600};

  width: ${(props) => props.width};
`;
