import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { DATE_PICKER_DOT_COLOR } from '@commons/DatePickers/constants';
import { Dropdown, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import { SingleDatePicker } from '@commons/DatePickers/SingleDatePicker';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { Container, DateContainer, LabelContainer } from './styledComponents';

// Based on src/routes/backoffice/BackOfficeGeneralContainer/BackOfficeAnalytics/utils/modals.js
// Will be re-factored with https://deepsightprod.atlassian.net/browse/IPP-4592

const CreateInitialInventorySelectors = (props) => {
  const {
    setFocusedMonth,
    selectedDate,
    setSelectedDate,
    stores,
    selectedStores,
    setSelectedStores,
    forbiddenDates,
    translatedStoreName,
  } = props;

  const isDatePickerDisabled = _.isEmpty(selectedStores);

  const CALENDAR_INFO = {
    dotsInformations: [
      {
        dotColor: DATE_PICKER_DOT_COLOR.RED,
        dotInfo: i18next.t('ORDERS.ORDERS.STOCK_FORM_DATEPICKER_TOOLTIP', { stockType: 'stock' }),
      },
    ],
  };

  return (
    <Container>
      <Dropdown
        customStyle={{ position: 'inherit' }}
        height={'64px'}
        isRequired={true}
        isUniqueSelection={false}
        items={stores}
        label={translatedStoreName}
        selectedItems={selectedStores}
        width={INPUT_WIDTH.LARGE}
        onSelectionChange={setSelectedStores}
      />
      <DateContainer>
        <LabelContainer>
          <Text
            color={isDatePickerDisabled ? ENUM_COLORS.LIGHT : ENUM_COLORS.INFO_RED}
            font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}
          >
            *
          </Text>
          <Text
            color={isDatePickerDisabled ? ENUM_COLORS.LIGHT : ENUM_COLORS.DARKEST}
            font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}
          >
            {i18next.t('GENERAL.DATE')}
          </Text>
        </LabelContainer>
        <SingleDatePicker
          calendarInfo={CALENDAR_INFO}
          customStyle={{ position: 'inherit' }}
          date={selectedDate}
          disabled={isDatePickerDisabled}
          forbiddenDates={forbiddenDates}
          timezone="Europe/Paris" // TODO - [TIMEZONES] To be replaced by IPP-5227 Stock Module
          onDateChange={setSelectedDate}
          onNextMonthClick={(nextMonth) => setFocusedMonth(nextMonth)}
          onPrevMonthClick={(prevMonth) => setFocusedMonth(prevMonth)}
        ></SingleDatePicker>
      </DateContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

export default connect(mapStateToProps)(CreateInitialInventorySelectors);
