import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${(props) => props.theme.colors?.greys?.lightest};
`;

export const Text = styled.div`
  color: ${(props) =>
    props.grey
      ? props.theme.colors?.greys?.darker
      : props.red
      ? props.theme.colors?.infoRed
      : props.green
      ? props.theme.colors?.infoGreen
      : props.orange
      ? props.theme?.colors?.infoOrange
      : props.theme.colors?.greys?.darkest};

  font: ${(props) =>
    props.bold
      ? props.theme.fonts.textMiddleBold
      : props.height24
      ? props.theme.fonts.textBigHeight24
      : props.theme.fonts.textMiddleNormal};

  ${(props) =>
    props.underline &&
    css`
      text-decoration: underline ${props.theme.colors?.greys?.darker};
    `}
`;

export const TextContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const InputFile = styled.input`
  height: 0px;

  opacity: 0;
`;

export const FileSelectorWapper = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
    props.interactive &&
    css`
      cursor: pointer;
    `}

  margin-top: 24px;

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;
    `}

  color: ${(props) => props.theme.colors?.greys?.darkest};

  font: ${(props) => props.theme.fonts.textMiddleBold};

  > img {
    margin-right: 12px;
  }
`;
