import styled, { css } from 'styled-components';

export const Item = styled.div`
  display: flex;
  flex-basis: 30%;
  align-items: center;
  font-size: 14px;

  padding-left: 15px;

  flex: ${(props) => (props.large ? 2 : 1)};

  ${(props) =>
    !props.large &&
    props.withRender &&
    css`
      flex: 0;
      padding-right: 20px;
    `}

  ${(props) =>
    props.large &&
    !props.isLoading &&
    css`
      flex-basis: calc(${(100 / props.nbColumn) * 2}%);
    `}

  ${(props) =>
    !props.large &&
    !props.isLoading &&
    css`
      flex-basis: calc(${100 / props.nbColumn}%);
    `}

  ${(props) =>
    props.narrow &&
    css`
      width: fit-content;
      flex: none;
    `}

  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth}px;
    `}

  font-weight: ${(props) => (props.bold ? '700' : 0)};
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : ''};

  ${(props) =>
    props.isLoading &&
    css`
      border-radius: 3px;
      background-color: rgba(168, 177, 196, 0.2);
      height: 20px;
    `}

  color: ${(props) => props.theme.row?.color};

  font: ${(props) => props.theme.row?.font};

  ${(props) =>
    props.blue &&
    css`
      color: ${props.theme.row?.highlight?.color};
      font: ${props.theme.row?.highlight?.font};
    `}
`;

export default {
  Item,
};
