import i18next from 'i18next';
import InputConversionContentModal from '../index';

export const getConversionModalParams = ({
  packagingUnit,
  existingConversions,
  areConversionsValid,
  handleConversionsUpdate,
  handleConversionSaving,
}) => ({
  type: 'action',
  width: '560px',
  height: 'auto',
  icon: '/images/inpulse/cockwheel.svg',
  title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONVERSIONS_TITLE'),
  component: InputConversionContentModal,
  data: {
    packagingUnit,
    existingConversions,
    handleConversionsUpdate,
  },
  actions: [
    {
      key: 0,
      color: 'inpulse-outline',
      label: i18next.t('GENERAL.CANCEL'),
      icon: '/images/inpulse/close-black-small.svg',
    },
    {
      key: 1,
      color: 'inpulse-default',
      label: i18next.t('GENERAL.SAVE'),
      icon: '/images/inpulse/save-white-small.svg',
      isDisabled: !areConversionsValid(existingConversions, packagingUnit),
      handleClick: () => {
        handleConversionSaving(existingConversions);
      },
    },
  ],
});
