import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  position: relative;

  > img {
    width: 35px;
    height: 25px;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const Icon = styled.img``;

export default {
  ButtonContainer,
  Icon,
};
