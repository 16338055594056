import _ from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import { isUserDeepsight } from '@commons/utils/roles';
import sortActionsList from '@commons/utils/sortActionsList';

import theme from '@theme';

export const getRowActions = (props) => {
  const {
    handleCashierProductModal,
    loadCashierProducts,
    openModal,
    handleCashierProductVisibility,
    handleExport,
  } = props;
  const actions = [
    {
      id: 'action-link',
      actionLabel: () => i18next.t('ADMIN.CASHIER_PRODUCTS.LINK_TO_INPULSE_PRODUCT'),
      handleAction: (selectedItem) => {
        handleCashierProductModal([selectedItem], loadCashierProducts, openModal);
      },
      actionIcon: () => '/images/inpulse/inpulse-link-black-small.svg',
    },
    {
      id: 'action-exclude-or-include-only-one',
      actionLabel: (selectedItem) =>
        !!selectedItem.hidden
          ? i18next.t('ADMIN.CASHIER_PRODUCTS.INCLUDE_ONLY_ONE')
          : i18next.t('ADMIN.CASHIER_PRODUCTS.EXCLUDE_ONLY_ONE'),
      handleAction: (selectedItem) => handleCashierProductVisibility(selectedItem),
      actionIcon: (selectedItem) =>
        !!selectedItem.hidden
          ? '/images/inpulse/add-black-small.svg'
          : '/images/inpulse/remove-black-small.svg',
    },
    {
      id: 'export-only-one',
      actionLabel: () => i18next.t('ADMIN.CASHIER_PRODUCTS.EXPORT_ONLY_ONE'),
      handleAction: (selectedItem) => handleExport([selectedItem]),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
  ];

  return sortActionsList(actions);
};

export const getActions = (props) => {
  const {
    clientId,
    syncCashierProduct,
    isSynchronizingCashierProducts,
    updateIsSynchronizingCashierProductsStatus,
    showMessage,
    selectedItems,
    handleCashierProductModal,
    handleShouldRetrieveData,
    openModal,
    handleCashierProductsVisibility,
    handleCashierProductVisibility,
    handleExport,
    handleAutomaticCashierProductMapping,
    hasActiveNotHiddenCashierProducts,
    user,
  } = props;

  const actions = [
    {
      id: 'action-sync',
      fixed: true,
      actionLabel: i18next.t('GENERAL.SYNC'),
      handleAction: () => {
        if (isSynchronizingCashierProducts) {
          return;
        }

        syncCashierProduct(clientId, updateIsSynchronizingCashierProductsStatus, showMessage);
      },
      render: () => (
        <Button
          animation={{
            rotate: isSynchronizingCashierProducts,
          }}
          buttonCustomStyle={
            isSynchronizingCashierProducts
              ? {
                  color: theme.buttons.primary.text,
                  backgroundColor: theme.buttons.primary.disabled,
                  cursor: 'default',
                }
              : {}
          }
          color={'blue'}
          fontSize={14}
          formatText={false}
          handleClick={() => {
            if (isSynchronizingCashierProducts) {
              return;
            }

            syncCashierProduct(clientId, updateIsSynchronizingCashierProductsStatus, showMessage);
          }}
          icon={'/images/inpulse/sync-white-small.svg'}
          iconCustomStyle={{ width: '20px', height: '20px' }}
          label={i18next.t('GENERAL.SYNC')}
        />
      ),
    },
  ];

  if (!selectedItems.length) {
    actions.push({
      id: 'export-list',
      actionLabel: i18next.t('ADMIN.CASHIER_PRODUCTS.EXPORT_LIST'),
      handleAction: () => handleExport(),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    });
    actions.push({
      id: 'csm-mapping-cp-auto',
      actionLabel: i18next.t('ADMIN.CASHIER_PRODUCTS.ACTION_MAPPING_AUTO_TO_PRODUCT'),
      handleAction: () => handleAutomaticCashierProductMapping(),
      actionIcon: () =>
        !hasActiveNotHiddenCashierProducts
          ? '/images/inpulse/lightning-lmgrey-small.svg'
          : '/images/inpulse/lightning-black-small.svg',
      isDisabled: () => !hasActiveNotHiddenCashierProducts,
      disabledTooltipText: () =>
        i18next.t('ADMIN.CASHIER_PRODUCTS.ACTION_MAPPING_AUTO_TO_PRODUCT_NOT_ELIGIBLE'),
      isHighlighted: () => true,
      isHidden: () => !isUserDeepsight(user),
    });
  } else if (selectedItems.length) {
    actions.push({
      id: 'action-link',
      actionLabel: i18next.t('ADMIN.CASHIER_PRODUCTS.LINK_TO_INPULSE_PRODUCT'),
      handleAction: () => {
        handleCashierProductModal(selectedItems, handleShouldRetrieveData, openModal);
      },
      actionIcon: () => '/images/inpulse/inpulse-link-black-small.svg',
    });
    if (selectedItems.length === 1) {
      actions.push(
        {
          id: 'action-exclude-or-include-only-one',
          actionLabel: !!selectedItems.every((item) => item.hidden)
            ? i18next.t('ADMIN.CASHIER_PRODUCTS.INCLUDE_ONLY_ONE')
            : i18next.t('ADMIN.CASHIER_PRODUCTS.EXCLUDE_ONLY_ONE'),
          handleAction: () => handleCashierProductVisibility(_.first(selectedItems)),
          actionIcon: () =>
            !!selectedItems.every((item) => item.hidden)
              ? '/images/inpulse/add-black-small.svg'
              : '/images/inpulse/remove-black-small.svg',
        },
        {
          id: 'export-only-one',
          actionLabel: i18next.t('ADMIN.CASHIER_PRODUCTS.EXPORT_ONLY_ONE'),
          handleAction: () => handleExport(selectedItems),
          actionIcon: () => '/images/inpulse/file-download-black-small.svg',
        },
      );
    } else {
      actions.push(
        {
          id: 'action-exclude-or-include-multiple',
          actionLabel: !!selectedItems.every((item) => item.hidden)
            ? i18next.t('ADMIN.CASHIER_PRODUCTS.INCLUDE_MULTIPLE')
            : i18next.t('ADMIN.CASHIER_PRODUCTS.EXCLUDE_MULTIPLE'),
          handleAction: () =>
            handleCashierProductsVisibility(
              selectedItems,
              selectedItems.every((item) => item.hidden) ? false : true,
            ),
          actionIcon: () =>
            !!selectedItems.every((item) => item.hidden)
              ? '/images/inpulse/add-black-small.svg'
              : '/images/inpulse/remove-black-small.svg',
        },
        {
          id: 'export-multiple',
          actionLabel: i18next.t('ADMIN.CASHIER_PRODUCTS.EXPORT_MULTIPLE'),
          handleAction: () => handleExport(selectedItems),
          actionIcon: () => '/images/inpulse/file-download-black-small.svg',
        },
      );
    }
  }

  return sortActionsList(actions);
};
