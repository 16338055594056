import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Tooltip } from '@commons/utils/styledLibraryComponents';
import DisplayImgInFullScreen from '@commons/DisplayImgInFullScreen';

import { getRecipeInventoryFormColumns } from '../../utils/getColumns';

import RecipeInventoryFormRow from '../RecipeInventoryFormRow';

import {
  CategoryContentContainer,
  RowContainer,
  ColumnsContainer,
  Column,
} from './styledComponents';

const RecipeInventoryFormCategoryContent = (props) => {
  const {
    recipes,
    handleQuantityUpdate,
    readOnly,
    selectedStorageAreaId,
    totByRecipeId,
    hasMultipleBrands,
  } = props;

  const [displayProductPicture, setDisplayProductPicture] = useState({
    state: false,
    displayImage: '',
  });

  const columns = getRecipeInventoryFormColumns({ hasMultipleBrands });

  return (
    <CategoryContentContainer>
      <ColumnsContainer>
        {columns.map((column, indexColumn) => (
          <Column key={`${column.propertyKey}-${indexColumn}`} nbColumns={columns.length}>
            {column.displayImage && displayProductPicture.state ? (
              <>
                <DisplayImgInFullScreen
                  image={displayProductPicture.displayImage}
                  setDisplayProductPicture={setDisplayProductPicture}
                />
                {column.label}
              </>
            ) : (
              <>{column.label}</>
            )}
            {!!column.tooltip && (
              <Tooltip key={`tooltip-${column.propertyKey}-${indexColumn}`} text={column.tooltip} />
            )}
          </Column>
        ))}
      </ColumnsContainer>
      {recipes.map((recipe, index) => (
        <RowContainer key={`${recipe.name}-${selectedStorageAreaId}-${index}`}>
          <RecipeInventoryFormRow
            handleQuantityUpdate={handleQuantityUpdate}
            hasMultipleBrands={hasMultipleBrands}
            nbColumns={columns.length}
            readOnly={readOnly}
            recipe={recipe}
            selectedStorageAreaId={selectedStorageAreaId}
            setDisplayProductPicture={setDisplayProductPicture}
            totByRecipeId={totByRecipeId}
          />
        </RowContainer>
      ))}
    </CategoryContentContainer>
  );
};

RecipeInventoryFormCategoryContent.propTypes = {
  recipes: PropTypes.array.isRequired,
  handleQuantityUpdate: PropTypes.func.isRequired,
  recipeQuantities: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  selectedStorageAreaId: PropTypes.string.isRequired,
  totByRecipeId: PropTypes.object.isRequired,
  hasMultipleBrands: PropTypes.bool.isRequired,
};

export default RecipeInventoryFormCategoryContent;
