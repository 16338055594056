import i18next from 'i18next';
import React from 'react';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_UNDERSTOOD_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import InputContentModal from '@commons/Modals/InputContentModal';

import {
  DYNAMIC_MODAL_CLOSE_CONFIRMATION,
  DYNAMIC_MODAL_INPUT_LABEL,
  DYNAMIC_MODAL_TITLE,
} from '@admin/commons/dynamicModalProperties';

export const getAddNewItemModalParams = ({
  closeCleanUp,
  propertyName,
  inputValue,
  handleInputChange,
  errorMessage = null,
  handleSaveNewItemInDropdown,
}) => ({
  type: 'action',
  width: '560px',
  height: 'auto',
  icon: '/images/inpulse/add-black-small.svg',
  title: i18next.t(DYNAMIC_MODAL_TITLE[propertyName]),
  isLoading: false,
  handleCloseCleanUp: closeCleanUp,

  component: () => (
    <InputContentModal
      autoFocus={true}
      errorMessage={errorMessage}
      label={i18next.t(DYNAMIC_MODAL_INPUT_LABEL[propertyName])}
      value={inputValue}
      onChange={(value) => handleInputChange(propertyName, value)}
    />
  ),
  closeConfig: {
    title: i18next.t('GENERAL.LEAVE'),
    content: <div>{i18next.t(DYNAMIC_MODAL_CLOSE_CONFIRMATION[propertyName])}</div>,
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      isDisabled: !inputValue || !!errorMessage,
      handleClick: handleSaveNewItemInDropdown,
    },
  ],
});

export const getWarningModalParams = (recipeName) =>
  getConfirmationModal({
    title: i18next.t('GENERAL.SAVE_IMPOSSIBLE'),
    icon: '/images/inpulse/warning-white-small.svg',
    content: i18next.t('ADMIN.RECIPES.ENTITY_ASSOCIATION_WARNING_MODAL_CONTENT', { recipeName }),
    actions: [GENERIC_MODAL_UNDERSTOOD_BUTTON()],
  });
