import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { REGEX_IMAGE_FILE_EXTENSION } from '@commons/utils/regex';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { Item, FlexDiv, BoxImg, Content } from '../styledComponents';

const memoizeCondition = (prevProps, props) => prevProps.name === props.name;

const Name = ({ img, name, sku, nbColumns, setDisplayProductPicture }) => {
  const shouldAllowFullScreenImg = !!img;

  return (
    <Item nbColumns={nbColumns} large>
      <FlexDiv>
        <BoxImg
          pointerCursor={shouldAllowFullScreenImg}
          src={
            `${!!img && img.replace(REGEX_IMAGE_FILE_EXTENSION, '_compressed.$1')}` ||
            '/images/icon-generic-inpulse-product.svg'
          }
          onClick={(e) => {
            e.stopPropagation();

            if (shouldAllowFullScreenImg && setDisplayProductPicture) {
              setDisplayProductPicture({ state: true, img: img });
            }
          }}
          onError={({ currentTarget }) => {
            // if the image is not found
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = '/images/icon-generic-inpulse-product.svg';
          }}
        />
        <Content>
          <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}>
            {name}
          </Text>
          {!!sku && (
            <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_MICRO_HEIGHT_16}>
              {sku}
            </Text>
          )}
        </Content>
      </FlexDiv>
    </Item>
  );
};

Name.propTypes = {
  img: PropTypes.string,
  nbColumns: PropTypes.number,
  name: PropTypes.string.isRequired,
  sku: PropTypes.string,
  setDisplayProductPicture: PropTypes.func,
};

Name.defaultProps = {
  nbColumns: 1,
};

export default memo(Name, memoizeCondition);
