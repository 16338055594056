import { isEmpty } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import TimeZonesDropdown from '@commons/TimezonesDropdown';

import theme from '@theme';

import { ModalWarningContentContainer, ModalWarningTimezoneContainer } from '../styledComponents';

export const getDefaultTimezoneWarningModal = (
  translatedStoresName,
  newDefaultTimezone,
  setNewDefaultTimezone,
  onInformationsChange,
) => {
  const params = getConfirmationModal({
    title: i18next.t('BACKOFFICE.GENERAL.TIMEZONE_WARNING_MODAL_TITLE'),
    width: '560px',
    icon: '/images/inpulse/warning-white-small.svg',
    content: (
      <ModalWarningContentContainer>
        {i18next.t('BACKOFFICE.GENERAL.TIMEZONE_WARNING_MODAL_CONTENT', {
          storeName: translatedStoresName,
        })}
        <ModalWarningTimezoneContainer>
          <TimeZonesDropdown
            defaultTimezone={newDefaultTimezone}
            handleChange={(timezone) => setNewDefaultTimezone(timezone)}
            isRequired={true}
            labelStyle={{ color: theme.colors.greys.darker }}
          />
        </ModalWarningTimezoneContainer>
      </ModalWarningContentContainer>
    ),
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_CONFIRM_BUTTON(),
        isDisabled: isEmpty(newDefaultTimezone),
        handleClick: () => {
          onInformationsChange(newDefaultTimezone, 'defaultTimezone');
          onInformationsChange(false, 'hasMultipleTimezones');
        },
      },
    ],
  });

  return { ...params, applyOverflow: false };
};
