import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorText = styled.div`
  color: ${(props) => props.theme?.colors?.infoRed};
  font: ${(props) => props.theme.fonts?.textSmallHeight14Weight600};
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
`;
