import moment from 'moment-timezone';

/*
  Adding one hour because xlsx parsing remove approxymately 30 minutes from a date and there's no real fix for it 
  except this quickfix, see issue: https://github.com/SheetJS/sheetjs/issues/1565
*/
export const formatExcelDates = (dateToFormat, timezone) => {
  if (!dateToFormat || !timezone) {
    return null;
  }

  return moment(dateToFormat).tz(timezone).add(1, 'h').startOf('day').format();
};
