import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

export const getActions = (props, addNewCashierProducts) => [
  {
    id: 'action-add',
    fixed: true,
    actionLabel: i18next.t('GENERAL.ADD'),
    handleAction: () => addNewCashierProducts(props),
    render: () => (
      <Button
        color={'inpulse-default'}
        handleClick={() => addNewCashierProducts(props)}
        icon={'/images/inpulse/add-white-small.svg'}
        label={i18next.t('GENERAL.ADD')}
      />
    ),
  },
];

export const getRowActions = (confirmDeletion, props) => [
  {
    id: 'delete-association',
    actionLabel: () => i18next.t('ADMIN.PRODUCTS.DELETE_CASHIER_PRODUCT_ASSOCIATION'),
    handleAction: (selectedCashierProduct) => confirmDeletion(selectedCashierProduct, props),
    actionIcon: () => '/images/inpulse/trash-black-small.svg',
  },
];
