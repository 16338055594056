import { omit, isEmpty } from 'lodash';
import { SingleDatePicker } from 'react-dates';
import i18next from 'i18next';
import moment from 'moment';
import React, { useState } from 'react';

import { Container } from '@commons/Modals/InputContentModal/styledComponents';
import { Dropdown } from '@commons/utils/styledLibraryComponents';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_NEXT_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import ItemListSorter, { PROPERTY_NONE } from '@commons/ItemListSorter';

import ConfirmationModal from '@backoffice/components/ConfirmationModal';

import { CLONE_STORE_STEP, STORE_DUPLICATION_TYPE } from '../details/utils/index';
import {
  DatePickerContainer,
  LaunchDateModalContentContainer,
  ContainerInput,
  DatePickerLabel,
  DatePickerAndLabelContainer,
} from '../styledComponents';
import StoreDuplicationComponent, { WarningModalContent } from '../components/StoreDuplication';

const LaunchDateModalContent = (props) => {
  const { selectedDate, handleDateChange, selectedStore } = props;

  const [focused, setFocused] = useState(false);

  return (
    <LaunchDateModalContentContainer>
      <DatePickerAndLabelContainer>
        <DatePickerLabel>
          <span>*</span>
          <span> {i18next.t('BACKOFFICE.STORES.LIST_LAUNCH_DATE_DATE_PICKER_LABEL')}</span>
        </DatePickerLabel>
        <DatePickerContainer>
          <ContainerInput>
            <img
              alt=""
              className="launchdate-date-input-icon"
              src={
                selectedDate
                  ? '/images/inpulse/calendar-black.svg'
                  : '/images/inpulse/calendar-dmgrey.svg'
              }
              style={{ width: '16px', height: '16px' }}
            />
            <SingleDatePicker
              date={selectedDate}
              focused={focused}
              hideKeyboardShortcutsPanel={true}
              id="your_unique_id"
              isOutsideRange={() => false}
              noBorder={true}
              numberOfMonths={1}
              placeholder={i18next.t('STOCKS.STOCKS.FORM_DATE_PLACEHOLDER')}
              timezone="Europe/Paris" // TODO - [TIMEZONES]
              onDateChange={(date) => handleDateChange(selectedStore, date)}
              onFocusChange={({ focused }) => setFocused(focused)}
            />
            {selectedDate && (
              <img
                alt=""
                className="launchdate-date-input-icon"
                src={'/images/inpulse/close-black-small.svg'}
                style={{ width: '16px', height: '16px' }}
                onClick={() => handleDateChange(selectedStore, null)}
              />
            )}
          </ContainerInput>
        </DatePickerContainer>
      </DatePickerAndLabelContainer>
    </LaunchDateModalContentContainer>
  );
};

const StoreTypeSelectionModalContent = (props) => {
  const { availableStoreTypes, handleStoreTypeSelection, selectedStoreType } = props;

  return (
    <Container>
      <Dropdown
        customStyle={{ position: 'inherit' }}
        height={'64px'}
        isRequired={true}
        isSearchDisabled={true}
        items={availableStoreTypes}
        label={i18next.t('GENERAL.STORE_TYPE')}
        selectedItem={selectedStoreType}
        onSelectionChange={handleStoreTypeSelection}
      />
    </Container>
  );
};

export const getDateModalParams = ({
  selectedDate,
  selectedStore,
  handleDateChange,
  handleLaunchDateSave,
}) => ({
  type: 'action',
  width: '560px',
  maxHeight: '272px',
  height: 'auto',
  data: {
    selectedDate,
    selectedStore,
    handleDateChange,
  },
  component: LaunchDateModalContent,
  title: i18next.t('BACKOFFICE.STORES.LIST_MANAGE_LAUCH_DATE'),
  icon: '/images/inpulse/gear-black-small.svg',
  actions: [
    {
      key: 0,
      color: 'inpulse-outline',
      label: i18next.t('GENERAL.CANCEL'),
      icon: '/images/inpulse/close-black-small.svg',
    },
    {
      isDisabled:
        moment(selectedDate).isSame(selectedStore.launchDate, 'day') ||
        (!selectedDate && !selectedStore.launchDate),
      key: 1,
      color: 'inpulse-default',
      label: i18next.t('GENERAL.SAVE'),
      icon: '/images/inpulse/save-white-small.svg',
      handleClick: () => handleLaunchDateSave(selectedStore, selectedDate),
    },
  ],
});

export const getStoreDuplicationModalConfig = (params) => {
  const {
    cloningType,
    selectableStores,
    brands,
    dataColumns,
    handleStoreDuplicationSave,
    storeToCloneFrom,
    handleStoreToCloneFromChange,
    closeDuplicationModalCleanUp,
    handleCloneStoreStep,
    cloneStoreStep,
    storeToCloneInto,
  } = params;

  if (
    cloneStoreStep === CLONE_STORE_STEP.VALIDATION &&
    cloningType === STORE_DUPLICATION_TYPE.PRODUCTION
  ) {
    return {
      type: 'warning',
      width: '560px',
      height: 'auto',
      isFullscreen: false,
      component: WarningModalContent,
      data: {
        storeToCloneFrom,
        storeToCloneInto,
      },
      title: i18next.t('BACKOFFICE.STORES.DUPLICATION_WARNING_MODAL_TITLE'),
      icon: '/images/inpulse/save-white-small.svg',
      actions: [
        {
          key: 0,
          color: 'inpulse-outline',
          label: i18next.t('GENERAL.BACK'),
          icon: '/images/inpulse/arrow-left-ip-black.svg',
          preventClosing: true,
          handleClick: () => handleCloneStoreStep(CLONE_STORE_STEP.STORE_SELECTION),
        },
        {
          key: 1,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.VALIDATE'),
          icon: '/images/inpulse/save-white-small.svg',
          handleClick: handleStoreDuplicationSave,
        },
      ],
    };
  }

  return {
    type: 'actionWhite',
    isFullscreen: true,
    component: StoreDuplicationComponent,
    title: i18next.t(`BACKOFFICE.STORES.DUPLICATION_${cloningType}_TITLE`),
    icon: '/images/inpulse/add-black-small.svg',
    handleCloseCleanUp: closeDuplicationModalCleanUp,
    data: {
      storeList: selectableStores,
      brands,
      dataColumns,
      handleStoreToCloneFromChange,
      storeToCloneFrom,
    },
    customPadding: '24px 24px 0px 24px',
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        color: 'inpulse-default',
        label:
          cloningType === STORE_DUPLICATION_TYPE.PRODUCTION
            ? i18next.t('GENERAL.NEXT')
            : i18next.t('GENERAL.SAVE'),
        icon:
          cloningType === STORE_DUPLICATION_TYPE.PRODUCTION
            ? '/images/inpulse/arrow-right-white.svg'
            : '/images/inpulse/save-white-small.svg',
        preventClosing: true,
        isDisabled: isEmpty(storeToCloneFrom),
        handleClick: () =>
          cloningType === STORE_DUPLICATION_TYPE.PRODUCTION
            ? handleCloneStoreStep(CLONE_STORE_STEP.VALIDATION)
            : handleStoreDuplicationSave(),
      },
    ],
  };
};

export const getBrandsModalParams = (
  brands,
  handleBrandsChange,
  handleBrandsSave,
  storeName,
  isDisabled = true,
) => ({
  type: 'action',
  width: '542px',
  height: 'auto',
  maxHeight: '560px',
  icon: '/images/inpulse/gear-ipblack-small.svg',
  title: i18next.t('BACKOFFICE.STORES.MANAGE_BRANDS'),
  component: ItemListSorter,
  data: {
    data: brands,
    properties: Object.keys(brands).sort(),
    entityName: storeName,
    disableCreateItem: false,
    createItemLabel: i18next.t('BACKOFFICE.STORES.MODAL_CREATE_BRAND_LABEL'),
    disableMergeItems: true,
    onChange: (values) => handleBrandsChange(values),
  },
  closeConfig: {
    title: i18next.t('GENERAL.LEAVE'),
    content: <div>{i18next.t('BACKOFFICE.STORES.MODAL_BRANDS_CLOSE_CONFIRMATION_CONTENT')}</div>,
  },
  actions: [
    {
      key: 0,
      color: 'inpulse-outline',
      label: i18next.t('GENERAL.CANCEL'),
      icon: '/images/inpulse/close-black-small.svg',
    },
    {
      key: 1,
      color: 'inpulse-default',
      label: i18next.t('GENERAL.SAVE'),
      icon: '/images/inpulse/save-white-small.svg',
      handleClick: () => handleBrandsSave(brands),
      isDisabled,
    },
  ],
});

export const getAssignPropertyModalParams = (
  data,
  selectedItems,
  field,
  handleAssignPropertiesChange,
) => {
  const formattedData = data.reduce(
    (result, item) => {
      result.push({
        ...omit(item, 'name'),
        value: item.name,
      });

      return result;
    },
    [{ value: PROPERTY_NONE }],
  );

  const formattedItems = selectedItems.reduce((result, current) => {
    if (!result[current[field] || PROPERTY_NONE]) {
      result[current[field] || PROPERTY_NONE] = {
        associatedItems: [],
      };
    }
    result[current[field] || PROPERTY_NONE].associatedItems.push(current);
    return result;
  }, {});

  return {
    type: 'action',
    width: '542px',
    height: 'auto',
    maxHeight: '560px',
    icon: '/images/inpulse/link-black-small.svg',
    title:
      field === 'retailerName'
        ? i18next.t('GENERAL.LIST_ACTION_ASSIGN_RETAILER')
        : field === 'locationName'
        ? i18next.t('GENERAL.LIST_ACTION_ASSIGN_LOCATION')
        : i18next.t('GENERAL.LIST_ACTION_ASSIGN_BRAND'),
    itemsCount: selectedItems.length,
    component: ItemListSorter,
    data: {
      data: formattedItems,
      properties: formattedData,
      field,
      onChange: (values) => {
        handleAssignPropertiesChange(values, field);
      },
      groupAllAtOnce: true,
    },
    closeConfig: {
      title: i18next.t('GENERAL.LEAVE'),
      content: <div>{i18next.t('BACKOFFICE.STORES.MODAL_BRANDS_CLOSE_CONFIRMATION_CONTENT')}</div>,
    },
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.SAVE'),
        icon: '/images/inpulse/save-white-small.svg',
        isDisabled: true,
      },
    ],
  };
};

export const getRetailersModalParams = (
  retailers,
  handleRetailersChange,
  handleRetailersSave,
  storeName,
  isDisabled = true,
) => ({
  type: 'action',
  width: '542px',
  height: 'auto',
  maxHeight: '560px',
  icon: '/images/inpulse/gear-ipblack-small.svg',
  title: i18next.t('BACKOFFICE.STORES.MANAGE_RETAILERS'),
  component: ItemListSorter,
  data: {
    data: retailers,
    properties: Object.keys(retailers).sort(),
    entityName: storeName,
    disableCreateItem: false,
    createItemLabel: i18next.t('BACKOFFICE.STORES.MODAL_CREATE_RETAILER_LABEL'),
    disableMergeItems: true,
    onChange: (values) => handleRetailersChange(values),
  },
  closeConfig: {
    title: i18next.t('GENERAL.LEAVE'),
    content: <div>{i18next.t('BACKOFFICE.STORES.MODAL_RETAILERS_CLOSE_CONFIRMATION_CONTENT')}</div>,
  },
  actions: [
    {
      key: 0,
      color: 'inpulse-outline',
      label: i18next.t('GENERAL.CANCEL'),
      icon: '/images/inpulse/close-black-small.svg',
    },
    {
      key: 1,
      color: 'inpulse-default',
      label: i18next.t('GENERAL.SAVE'),
      icon: '/images/inpulse/save-white-small.svg',
      handleClick: () => handleRetailersSave(retailers),
      isDisabled,
    },
  ],
});

export const getEnableStoresModalConfig = ({
  storeIds,
  activeStore,
  handleEnableStores,
  firstStoreName,
}) => ({
  type: 'warning',
  width: '542px',
  height: 'auto',
  icon: '/images/inpulse/power-ip-white.svg',
  title: i18next.t(
    activeStore
      ? 'BACKOFFICE.STORES.LIST_ACTION_DISABLE_STORES'
      : 'BACKOFFICE.STORES.LIST_ACTION_ENABLE_STORES',
    { count: storeIds.length },
  ),
  component: ConfirmationModal,
  data: {
    content: i18next.t(
      activeStore
        ? 'BACKOFFICE.STORES.DISABLE_MODAL_CONTENT'
        : 'BACKOFFICE.STORES.ENABLE_MODAL_CONTENT',
      {
        count: storeIds.length,
        storeName: firstStoreName,
      },
    ),
  },
  actions: [
    {
      key: 0,
      color: 'inpulse-outline',
      label: i18next.t('GENERAL.CANCEL'),
      icon: '/images/inpulse/close-black-small.svg',
    },
    {
      key: 1,
      color: 'inpulse-default',
      label: i18next.t(activeStore ? 'GENERAL.DISABLE' : 'GENERAL.ACTIVATE'),
      icon: '/images/inpulse/power-ip-white.svg',
      handleClick: () => handleEnableStores(storeIds),
    },
  ],
});

export const getCreateStoreModalConfig = ({
  handleCreateStore,
  handleStoreTypeSelection,
  availableStoreTypes,
  selectedStoreType,
}) => ({
  type: 'action',
  width: '542px',
  height: 'auto',
  title: i18next.t('BACKOFFICE.GENERAL.STORE_MODAL_CREATE'),
  component: StoreTypeSelectionModalContent,
  data: {
    availableStoreTypes,
    selectedStoreType,
    handleStoreTypeSelection,
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_NEXT_BUTTON(),
      handleClick: () => handleCreateStore(selectedStoreType),
    },
  ],
});

export default {
  getDateModalParams,
  getBrandsModalParams,
  getRetailersModalParams,
  getEnableStoresModalConfig,
  getAssignPropertyModalParams,
  getStoreDuplicationModalConfig,
  getCreateStoreModalConfig,
};
