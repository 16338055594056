import { getActionAuthorization } from '../commons/utils';

const FORECAST_ACTIONS = {
  TURNOVER_CREATE: 'forecast:turnover:create',
  EVENT_SETUP: 'forecast:turnover:event:setup',
};

export const canCreateForecastTurnover = (actions) =>
  getActionAuthorization(actions, FORECAST_ACTIONS.TURNOVER_CREATE);

export const canSetupForecastEvent = (actions) =>
  getActionAuthorization(actions, FORECAST_ACTIONS.EVENT_SETUP);
