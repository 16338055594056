import i18next from 'i18next';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CenteredParagraph = styled.p`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/**
 * Workaround to not show the "No rows" overlay too quickly and avoid flickering
 * @returns
 */
export const CustomDataGridNoRowOverlay = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 300);
    return () => clearTimeout(timeout);
  }, []);

  if (!show) return null;

  return (
    <CenteredParagraph>
      {i18next.t('PRODUCTION.CENTRAL_KITCHEN.NO_RECIPE_MAPPED_FOR_PRODUCTION')}
    </CenteredParagraph>
  );
};
