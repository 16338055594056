import i18next from 'i18next';
import React from 'react';

import WhiteCardForm from '@commons/WhiteCardForm';

import { STORAGE_AREA_INFOS_INPUTS } from '../utils/formInputsConfigurations';

import { Container, FormContainer } from './styledComponents';

const StorageAreaInformations = (props) => {
  const { storageAreaForm, isSaveAlreadyTriggered } = props;

  return (
    <Container>
      <FormContainer>
        <WhiteCardForm
          form={storageAreaForm}
          inputs={STORAGE_AREA_INFOS_INPUTS()}
          shouldDisplayError={isSaveAlreadyTriggered}
          title={i18next.t('ADMIN.SUPPLIER_PRODUCTS.FORM_GENERAL_INFOS')}
        />
      </FormContainer>
    </Container>
  );
};

export default StorageAreaInformations;
