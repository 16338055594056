import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

import {
  EmptyStateContainer,
  EmptyStateIcon,
  MainText,
  SubText,
  TextContainer,
} from './styledComponents';

const ProductionPlanningAssociationEmptyState = (props) => {
  const { isReadOnly, setTrigger } = props;

  return (
    <EmptyStateContainer>
      <EmptyStateIcon alt="production-planning" src="/images/inpulse/production-planning.svg" />
      <TextContainer>
        <MainText>{i18next.t('ADMIN.PRODUCTS.ADD_TO_PRODUCTION_PLANNING')}</MainText>
        <SubText>{i18next.t('ADMIN.PRODUCTS.COLLECT_PRODUCTION_DATA')}</SubText>
        {!isReadOnly && (
          <Button
            color={'inpulse-default'}
            handleClick={() => setTrigger(true)}
            icon={'/images/inpulse/add-white-small.svg'}
            label={i18next.t('GENERAL.ADD')}
          />
        )}
      </TextContainer>
    </EmptyStateContainer>
  );
};

export default ProductionPlanningAssociationEmptyState;
