import i18next from 'i18next';

export const DETAILS = {
  isFullScreen: true,
  getText: () => i18next.t('FEATURE.INVOICE.INVOICE_CONTROL_DETAILS'),
  path: '/invoice/invoice-controls/:id/details',
  getLink: ({ invoiceControlListId }) =>
    `/invoice/invoice-controls/${invoiceControlListId}/details`,
  isVisible: () => false,
};

export const BATCH_IMPORT = {
  isFullScreen: true,
  getText: () => i18next.t('FEATURE.INVOICE.INVOICE_CONTROL_BATCH_IMPORT'),
  path: '/invoice/invoice-controls/batch-import',
  isVisible: () => false,
};
