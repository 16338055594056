import { IMPORT_STATE, IMPORT_TYPE } from './constant';

export const MODAL_TYPE = {
  [IMPORT_STATE.SELECT_FILE]: 'action',
  [IMPORT_STATE.ERROR_FILE]: 'error',
  [IMPORT_STATE.VALIDATED_FILE]: 'success',
};

export const MODAL_TITLE = {
  [IMPORT_TYPE.ENTITIES]: {
    [IMPORT_STATE.SELECT_FILE]: 'GENERAL.IMPORT_BATCH_CREATE_TITLE_MODAL',
    [IMPORT_STATE.ERROR_FILE]: 'GENERAL.IMPORT_FILE_ERROR_TITLE_MODAL',
    [IMPORT_STATE.VALIDATED_FILE]: 'GENERAL.IMPORT_FILE_NO_ERROR_TITLE_MODAL',
  },
  [IMPORT_TYPE.PRODUCTS]: {
    [IMPORT_STATE.SELECT_FILE]: 'GENERAL.IMPORT_BATCH_CREATE_TITLE_MODAL',
    [IMPORT_STATE.ERROR_FILE]: 'GENERAL.IMPORT_FILE_ERROR_TITLE_MODAL',
    [IMPORT_STATE.VALIDATED_FILE]: 'GENERAL.IMPORT_FILE_NO_ERROR_TITLE_MODAL',
  },
  [IMPORT_TYPE.CENTRAL_KITCHEN_PRODUCTS]: {
    [IMPORT_STATE.SELECT_FILE]: 'GENERAL.IMPORT_BATCH_CREATE_TITLE_MODAL',
    [IMPORT_STATE.ERROR_FILE]: 'GENERAL.IMPORT_FILE_ERROR_TITLE_MODAL',
    [IMPORT_STATE.VALIDATED_FILE]: 'GENERAL.IMPORT_FILE_NO_ERROR_TITLE_MODAL',
  },
  [IMPORT_TYPE.SUPPLIER_PROFILE]: {
    [IMPORT_STATE.SELECT_FILE]: 'GENERAL.IMPORT_BATCH_CREATE_TITLE_MODAL',
    [IMPORT_STATE.ERROR_FILE]: 'GENERAL.IMPORT_FILE_ERROR_TITLE_MODAL',
    [IMPORT_STATE.VALIDATED_FILE]: 'GENERAL.IMPORT_FILE_NO_ERROR_TITLE_MODAL',
  },
};

export const MODAL_ICON = {
  [IMPORT_TYPE.ENTITIES]: {
    [IMPORT_STATE.SELECT_FILE]: '/images/inpulse/file-upload-black-small.svg',
    [IMPORT_STATE.ERROR_FILE]: '/images/inpulse/info-white-small.svg',
    [IMPORT_STATE.VALIDATED_FILE]: '/images/inpulse/check-white-small.svg',
  },
  [IMPORT_TYPE.PRODUCTS]: {
    [IMPORT_STATE.SELECT_FILE]: '/images/inpulse/file-upload-black-small.svg',
    [IMPORT_STATE.ERROR_FILE]: '/images/inpulse/info-white-small.svg',
    [IMPORT_STATE.VALIDATED_FILE]: '/images/inpulse/check-white-small.svg',
  },
  [IMPORT_TYPE.CENTRAL_KITCHEN_PRODUCTS]: {
    [IMPORT_STATE.SELECT_FILE]: '/images/inpulse/file-upload-black-small.svg',
    [IMPORT_STATE.ERROR_FILE]: '/images/inpulse/info-white-small.svg',
    [IMPORT_STATE.VALIDATED_FILE]: '/images/inpulse/check-white-small.svg',
  },
  [IMPORT_TYPE.SUPPLIER_PROFILE]: {
    [IMPORT_STATE.SELECT_FILE]: '/images/inpulse/file-upload-black-small.svg',
    [IMPORT_STATE.ERROR_FILE]: '/images/inpulse/info-white-small.svg',
    [IMPORT_STATE.VALIDATED_FILE]: '/images/inpulse/check-white-small.svg',
  },
};

export const MODAL_SUBTITLE = {
  [IMPORT_TYPE.ENTITIES]: {
    [IMPORT_STATE.SELECT_FILE]: 'ADMIN.INGREDIENTS.SELECT_FILE_TITLE_SUBTITLE_MODAL',
    [IMPORT_STATE.ERROR_FILE]: 'GENERAL.IMPORT_FILE_ERROR_LINE_INVALID',
    [IMPORT_STATE.VALIDATED_FILE]: '',
  },
  [IMPORT_TYPE.PRODUCTS]: {
    [IMPORT_STATE.SELECT_FILE]: 'ADMIN.PRODUCTS.SELECT_FILE_TITLE_SUBTITLE_MODAL',
    [IMPORT_STATE.ERROR_FILE]: 'GENERAL.IMPORT_FILE_ERROR_LINE_INVALID',
    [IMPORT_STATE.VALIDATED_FILE]: '',
  },
  [IMPORT_TYPE.CENTRAL_KITCHEN_PRODUCTS]: {
    [IMPORT_STATE.SELECT_FILE]: 'ADMIN.PRODUCTS.SELECT_FILE_CENTRAL_KITCHEN_TITLE_SUBTITLE_MODAL',
    [IMPORT_STATE.ERROR_FILE]: 'GENERAL.IMPORT_FILE_ERROR_LINE_INVALID',
    [IMPORT_STATE.VALIDATED_FILE]: '',
  },
  [IMPORT_TYPE.SUPPLIER_PROFILE]: {
    [IMPORT_STATE.SELECT_FILE]: 'ADMIN.SUPPLIERS.SUPPLIER_PROFILE_SELECT_FILE_TITLE_SUBTITLE_MODAL',
    [IMPORT_STATE.ERROR_FILE]: 'GENERAL.IMPORT_FILE_ERROR_LINE_INVALID',
    [IMPORT_STATE.VALIDATED_FILE]: '',
  },
};

export const MODAL_CTA_RIGHT_TEXT = {
  [IMPORT_TYPE.ENTITIES]: {
    [IMPORT_STATE.SELECT_FILE]: 'GENERAL.IMPORT',
    [IMPORT_STATE.ERROR_FILE]: 'GENERAL.CLOSE',
    [IMPORT_STATE.VALIDATED_FILE]: 'GENERAL.IMPORT',
  },
  [IMPORT_TYPE.PRODUCTS]: {
    [IMPORT_STATE.SELECT_FILE]: 'GENERAL.IMPORT',
    [IMPORT_STATE.ERROR_FILE]: 'GENERAL.CLOSE',
    [IMPORT_STATE.VALIDATED_FILE]: 'GENERAL.IMPORT',
  },
  [IMPORT_TYPE.CENTRAL_KITCHEN_PRODUCTS]: {
    [IMPORT_STATE.SELECT_FILE]: 'GENERAL.IMPORT',
    [IMPORT_STATE.ERROR_FILE]: 'GENERAL.CLOSE',
    [IMPORT_STATE.VALIDATED_FILE]: 'GENERAL.IMPORT',
  },
  [IMPORT_TYPE.SUPPLIER_PROFILE]: {
    [IMPORT_STATE.SELECT_FILE]: 'GENERAL.IMPORT',
    [IMPORT_STATE.ERROR_FILE]: 'GENERAL.CLOSE',
    [IMPORT_STATE.VALIDATED_FILE]: 'GENERAL.IMPORT',
  },
};

export const MODAL_CTA_LEFT_TEXT = {
  [IMPORT_TYPE.ENTITIES]: {
    [IMPORT_STATE.SELECT_FILE]: 'GENERAL.CANCEL',
    [IMPORT_STATE.ERROR_FILE]: '',
    [IMPORT_STATE.VALIDATED_FILE]: '',
  },
  [IMPORT_TYPE.PRODUCTS]: {
    [IMPORT_STATE.SELECT_FILE]: 'GENERAL.CANCEL',
    [IMPORT_STATE.ERROR_FILE]: '',
    [IMPORT_STATE.VALIDATED_FILE]: '',
  },
  [IMPORT_TYPE.CENTRAL_KITCHEN_PRODUCTS]: {
    [IMPORT_STATE.SELECT_FILE]: 'GENERAL.CANCEL',
    [IMPORT_STATE.ERROR_FILE]: '',
    [IMPORT_STATE.VALIDATED_FILE]: '',
  },
  [IMPORT_TYPE.SUPPLIER_PROFILE]: {
    [IMPORT_STATE.SELECT_FILE]: 'GENERAL.CANCEL',
    [IMPORT_STATE.ERROR_FILE]: '',
    [IMPORT_STATE.VALIDATED_FILE]: '',
  },
};

export const MODAL_CTA_RIGHT_ICON = {
  [IMPORT_TYPE.ENTITIES]: {
    [IMPORT_STATE.SELECT_FILE]: '/images/inpulse/file-upload-white-small.svg',
    [IMPORT_STATE.ERROR_FILE]: '/images/inpulse/close-white-small.svg',
    [IMPORT_STATE.VALIDATED_FILE]: '/images/inpulse/check-white-small.svg',
  },
  [IMPORT_TYPE.PRODUCTS]: {
    [IMPORT_STATE.SELECT_FILE]: '/images/inpulse/file-upload-white-small.svg',
    [IMPORT_STATE.ERROR_FILE]: '/images/inpulse/close-white-small.svg',
    [IMPORT_STATE.VALIDATED_FILE]: '/images/inpulse/check-white-small.svg',
  },
  [IMPORT_TYPE.CENTRAL_KITCHEN_PRODUCTS]: {
    [IMPORT_STATE.SELECT_FILE]: '/images/inpulse/file-upload-white-small.svg',
    [IMPORT_STATE.ERROR_FILE]: '/images/inpulse/close-white-small.svg',
    [IMPORT_STATE.VALIDATED_FILE]: '/images/inpulse/check-white-small.svg',
  },
  [IMPORT_TYPE.SUPPLIER_PROFILE]: {
    [IMPORT_STATE.SELECT_FILE]: '/images/inpulse/file-upload-white-small.svg',
    [IMPORT_STATE.ERROR_FILE]: '/images/inpulse/close-white-small.svg',
    [IMPORT_STATE.VALIDATED_FILE]: '/images/inpulse/check-white-small.svg',
  },
};

export const MODAL_CTA_LEFT_ICON = {
  [IMPORT_TYPE.ENTITIES]: {
    [IMPORT_STATE.SELECT_FILE]: '/images/inpulse/close-black-small.svg',
    [IMPORT_STATE.ERROR_FILE]: '',
    [IMPORT_STATE.VALIDATED_FILE]: '',
  },
  [IMPORT_TYPE.PRODUCTS]: {
    [IMPORT_STATE.SELECT_FILE]: '/images/inpulse/close-black-small.svg',
    [IMPORT_STATE.ERROR_FILE]: '',
    [IMPORT_STATE.VALIDATED_FILE]: '',
  },
  [IMPORT_TYPE.CENTRAL_KITCHEN_PRODUCTS]: {
    [IMPORT_STATE.SELECT_FILE]: '/images/inpulse/close-black-small.svg',
    [IMPORT_STATE.ERROR_FILE]: '',
    [IMPORT_STATE.VALIDATED_FILE]: '',
  },
  [IMPORT_TYPE.SUPPLIER_PROFILE]: {
    [IMPORT_STATE.SELECT_FILE]: '/images/inpulse/close-black-small.svg',
    [IMPORT_STATE.ERROR_FILE]: '',
    [IMPORT_STATE.VALIDATED_FILE]: '',
  },
};

export const MODAL_ERROR_FILE_NAME = {
  [IMPORT_TYPE.ENTITIES]: 'ADMIN.INGREDIENTS.ERROR_FILE_NAME',
  [IMPORT_TYPE.PRODUCTS]: 'ADMIN.PRODUCTS.ERROR_FILE_NAME',
  [IMPORT_TYPE.CENTRAL_KITCHEN_PRODUCTS]: 'ADMIN.PRODUCTS.ERROR_CENTRAL_KITCHEN_FILE_NAME',
  [IMPORT_TYPE.SUPPLIER_PROFILE]: 'ADMIN.SUPPLIERS.SUPPLIER_PROFILE_ERROR_FILE_NAME',
};
