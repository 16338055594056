import React, { useState, createContext } from 'react';

export const ForecastsContext = createContext();

export const ForecastsProvider = ({ children }) => {
  const [selectedStore, setSelectedStore] = useState({});

  const handleStoreSelection = (store) => {
    setSelectedStore(store);
  };

  return (
    <ForecastsContext.Provider
      value={{
        selectedStore,
        handleStoreSelection,
      }}
    >
      {children}
    </ForecastsContext.Provider>
  );
};

export default ForecastsProvider;
