import axios from '../../core/http';

import { config } from '../../config';
const baseApiUrl = config.baseApiUrl;

const getProductsFromCashierProductsOfClient = (clientId) =>
  axios
    .get(
      `${baseApiUrl}/backoffice/products/export-products-cashier-products-mapping?clientId=${clientId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const getCashierProductsOfClient = (clientId) =>
  axios
    .get(`${baseApiUrl}/backoffice/products/cashier-products?clientId=${clientId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const createProductsFromCashierProducts = (clientId) =>
  axios
    .post(
      `${baseApiUrl}/backoffice/onboarding/products/create-from-cashier-products?clientId=${clientId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const downloadProductsCreationTemplate = async (clientId) => {
  const { data } = await axios.get(
    `${baseApiUrl}/admin/clients/${clientId}/products/batch-creation/template`,
    {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    },
  );

  return data;
};

function validateExcel(clientId, data) {
  return axios
    .post(
      `${baseApiUrl}/backoffice/onboarding/products/validate?clientId=${clientId}`,
      {
        ...data,
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
      { responseType: 'blob' },
    )
    .then(function (response) {
      return { status: 'success', data: response.data };
    })
    .catch(function (error) {
      if (error.response) {
        return { status: 'error', data: error.response.data };
      }

      throw error;
    });
}

export function importProductsExcelTemplate(clientId, data) {
  return axios
    .post(`${baseApiUrl}/backoffice/onboarding/products/import?clientId=${clientId}`, {
      ...data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const productsService = {
  getProductsFromCashierProductsOfClient,
  getCashierProductsOfClient,
  createProductsFromCashierProducts,
  downloadProductsCreationTemplate,
  validateExcel,
  importProductsExcelTemplate,
};
