export const ORDER_MODAL_TYPE = {
  CLOSE: 'close',
  DATE_ORDER_LIMIT: 'orderLimit',
  DATE_ORDER_EXISTS: 'orderExists',
  DATE_SUPPLIER_PROFILE: 'supplierProfile',
  FORECAST_NOT_CONFIRMED: 'forecastNotConfirmed',
  DATE_ORDER_LIMIT_AFTER_EDIT: 'orderLimitAfterEdit',
  STOCK_REASSEMBLY_NOT_DONE: 'stockReassemblyNotDone',
};

export default ORDER_MODAL_TYPE;
