import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;

  background-color: ${(props) => props.theme.colors.greys.lighter};
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  padding-bottom: 8px;
  overflow-x: scroll;
  overflow-y: hidden;

  background: ${(props) => props.theme.colors.greys.lighter};
`;

export const SidePaddedGapContainer = styled.div`
  width: 100%;
  padding: 0 24px;
`;

export const FlexedGapContainer = styled.div`
  display: flex;
  column-gap: 24px;
  width: 100%;
`;

export const TurnoverContainer = styled.div`
  width: -webkit-fill-available;
  align-self: center;

  color: ${(props) => props.theme.colors.greys.darker};
  font: ${(props) => props.theme.fonts.textSmall};

  > .kpi {
    color: ${(props) => props.theme.colors.greys.darkest};
    font: ${(props) => props.theme.fonts.kpiSmall};
  }
`;

export const InfoBoxLabel = styled.div`
  display: flex;
  flex-direction: row;
`;

export default {
  Container,
  ContentContainer,
};
