import { get } from 'lodash';
import i18next from 'i18next';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import theme from '@theme';

import { TooltipAssociate, TextContent, Text, rotate } from './styledComponents';

const TooltipAssociationList = ({ ...props }) => {
  const [click, setClick] = useState(false);
  const { cashierStore } = props;
  const random = Math.random().toString();
  const tooltipId = `tooltip-${cashierStore.associatedStores.map((store) => store.name)}-${random}`;

  const associatedStore = get(cashierStore, 'associatedStores[0]', {});

  return (
    <TooltipAssociate>
      <div
        data-for={tooltipId}
        data-html={true}
        data-tip={
          `<div style="background-color: white; width: 100%; opacity: 100%; border-radius: ${theme.borders.radius.weak}; cursor: default;">` +
          `<a target="_blank" href="/admin/stores/${associatedStore.id}/details" style="font-family: ${theme.fonts.families.secondary}; cursor: pointer; color: ${theme.colors.greys.darkest};"/>${associatedStore.name}</a>` +
          `</div>`
        }
      >
        <TextContent>
          <Text>{i18next.t('GENERAL.YES')}</Text>
          <img
            alt="arrow dropdown green"
            src="/images/arrow-dropdown-green.svg"
            style={click ? rotate : null}
          />
        </TextContent>

        <ReactTooltip
          afterHide={(e) => {
            e && e.stopPropagation();
            setClick(false);
          }}
          afterShow={(e) => {
            e.stopPropagation();
            setClick(true);
          }}
          backgroundColor={'white'}
          className="custom-tooltip-list"
          clickable={true}
          effect={'solid'}
          event={'click'}
          globalEventOff={'click'}
          id={tooltipId}
          isCapture={true}
          place={'bottom'}
        />
      </div>
    </TooltipAssociate>
  );
};

export default TooltipAssociationList;
