import styled from 'styled-components';

export const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  padding: 0 16px;
  background: ${(props) => props.theme.colors.greys.lightest};
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.1);
`;
