import axios from '../core/http';

import { APIcaller } from './APIcaller';

import { config } from '../config';
const baseApiUrl = config.baseApiUrl;

const syncCashierStore = (clientId) =>
  axios
    .post(
      `${baseApiUrl}/admin/cashier-stores/sync`,
      {
        clientId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const createCashierStore = (cashierStore) =>
  axios
    .post(`${baseApiUrl}/admin/cashier-stores`, {
      cashierStores: [cashierStore],
      cashierConfigId: cashierStore.cashierConfigId,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const patchCashierStore = (cashierStore) =>
  axios
    .patch(
      `${baseApiUrl}/admin/cashier-stores/${cashierStore.id}`,
      { cashierStore },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const getCashierStoreSyncStatus = (clientId) =>
  axios
    .get(`${baseApiUrl}/admin/cashier-stores/sync/${clientId}/status`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const getCashierStoreInformations = (clientId) =>
  axios
    .get(`${baseApiUrl}/admin/cashier-stores`, {
      params: {
        clientId,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const deleteStoreCashierStoreMappingByCashierStoreId = (cashierStoreId) =>
  APIcaller.apiDelete(`/admin/cashier-stores/store-mapping/${cashierStoreId}`, {
    cashierStoreId,
  });

export const cashierStoreService = {
  syncCashierStore,
  getCashierStoreSyncStatus,
  getCashierStoreInformations,
  createCashierStore,
  patchCashierStore,
  deleteStoreCashierStoreMappingByCashierStoreId,
};
