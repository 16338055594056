import i18next from 'i18next';
import React from 'react';

import { InpulseLabel } from '@commons/DeepsightComponents';
import { Tags } from '@commons/Tags';

import { Text, LabelContainer } from '../components/styledComponents';

const renderLabel = (item) => (
  <LabelContainer>
    <InpulseLabel
      color={item > 0 ? 'green' : 'red'}
      text={String(item).toLowerCase() === 'null' ? '0' : String(item)}
    />
  </LabelContainer>
);

export const getColumns = (hasMultipleBrands) => {
  const columns = [
    {
      id: 'name',
      name: i18next.t('GENERAL.NAME'),
      displayName: i18next.t('GENERAL.NAME'),
      propertyKey: 'name',
      baseName: 'name',
      large: true,
      render: (item) => <Text highlighted>{item}</Text>,
    },
    {
      id: 'category',
      baseName: 'category',
      propertyKey: 'category',
      displayName: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CATEGORY'),
      name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CATEGORY'),
      filterType: 'string',
      filterStringAllowNone: true,
      render: (item) => (item ? <Text>{item}</Text> : <Text>-</Text>),
    },
    {
      id: 'quantity',
      baseName: 'quantity',
      propertyKey: 'quantity',
      displayName: i18next.t('ADMIN.RECIPES.QUANTITY'),
      name: i18next.t('ADMIN.RECIPES.QUANTITY'),
      excludeFromSearch: true,
      filterType: 'numeric',
      render: (item) => (item ? <Text>{item}</Text> : <Text>-</Text>),
    },
    {
      id: 'unit',
      baseName: 'unit',
      propertyKey: 'unit',
      displayName: i18next.t('GENERAL.TOGGLE_BUTTON_UNIT', { count: 1 }),
      name: i18next.t('GENERAL.TOGGLE_BUTTON_UNIT', { count: 1 }),
      excludeFromSearch: true,
      filterType: 'string',
      render: (item) => <Text>{item}</Text>,
    },
    {
      id: 'mappingCount',
      baseName: 'mappingCount',
      propertyKey: 'mappingCount',
      displayName: i18next.t('ADMIN.RECIPES.COMPONENTS'),
      name: i18next.t('ADMIN.RECIPES.COMPONENTS'),
      filterType: 'numeric',
      excludeFromSearch: true,
      render: (item) => renderLabel(item),
    },
    {
      id: 'parentCounts',
      baseName: 'parentCounts',
      propertyKey: 'parentCounts',
      displayName: i18next.t('ADMIN.INGREDIENTS.USING'),
      name: i18next.t('ADMIN.INGREDIENTS.USING'),
      filterType: 'numeric',
      excludeFromSearch: true,
      render: (item) => renderLabel(item),
    },
  ];

  if (hasMultipleBrands) {
    columns.splice(1, 0, {
      id: 'brands',
      baseName: 'brands',
      propertyKey: 'brands',
      displayName: i18next.t('GENERAL.BRAND_PLURAL'),
      name: i18next.t('GENERAL.BRAND_PLURAL'),
      disableSort: true,
      render: (items) => {
        const names = items.map(({ name }) => name);

        return <Tags items={names} />;
      },
    });
  }

  return columns;
};
