import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';

import { Button } from '../../utils/styledLibraryComponents';

export class ConfirmationModal extends Component {
  handleConfirm() {
    let props = this.props.params;
    const { closeConfirmationModal } = this.props;

    props.handleEventDeleteConfirmation(props.eventId);
    closeConfirmationModal();
  }

  render() {
    const { closeConfirmationModal, params: props } = this.props;

    return (
      <div className={`conf-modal-encloser${` ${props.size}` || ''}`}>
        <div className="conf-modal-content-encloser">
          <div className={'conf-modal-upper-' + props.colorsheme}>
            <div className={'conf-modal-upper-p-' + props.colorsheme}>{props.title}</div>
          </div>
          <div className="conf-modal-down">
            <div className="conf-modal-text">
              <div>{props.text}</div>
            </div>
            <div className="conf-down-buttons">
              <Button
                color={'inpulse-outline'}
                handleClick={() => closeConfirmationModal()}
                icon={'/images/inpulse/close-black-small.svg'}
                label={this.props.t('GENERAL.CANCEL')}
              />
              <Button
                color={'inpulse-default'}
                handleClick={this.handleConfirm.bind(this)}
                icon={'/images/inpulse/check-white-small.svg'}
                label={this.props.t('GENERAL.CONFIRM')}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ConfirmationModal);
