import { getActionAuthorization } from '../commons/utils';

const PRODUCT_ACTIONS_RESOURCE = {
  DOWNLOAD_TEMPLATE: 'products:batch:template',
  IMPORT: 'products:batch:import',
};

export const canDownloadProductTemplate = (actions) =>
  getActionAuthorization(actions, PRODUCT_ACTIONS_RESOURCE.DOWNLOAD_TEMPLATE);

export const canImportProduct = (actions) =>
  getActionAuthorization(actions, PRODUCT_ACTIONS_RESOURCE.IMPORT);
