import styled, { css } from 'styled-components';

export const Container = styled.div`
  top: 0;
  left: 64px;
  z-index: 1;
  position: fixed;

  height: auto;
  width: calc(100% - 64px);

  background: white;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 8%);

  display: flex;

  ${(props) => {
    if (props.fullWidth) {
      return css`
        left: 0px;
        width: 100%;
      `;
    }
  }}
`;

export const TopBarContainer = styled.div`
  width: 100%;
  height: ${(props) => (props.big ? '64px' : '48px')};

  display: flex;
  flex-direction: row;

  align-items: center;

  padding-right: 8px;

  border-bottom: ${(props) =>
    props.noBorder
      ? 'none'
      : props.theme.colors?.greys.lighter
      ? `2px solid ${props.theme.colors?.greys.lighter}`
      : '2px solid #F8F8F8'};
`;

export const TopBar = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;

  padding: 0px 24px;
`;

export const SubBar = styled.div`
  width: 100%;
  height: 40px;

  display: flex;
  justify-content: flex-start;

  padding: 0px 24px;
`;

export const Section = styled.div`
  > a {
    font: ${(props) => (props.small ? props.theme.fonts?.textSmall : props.theme.fonts?.textBig)};
    color: ${(props) => props.theme.colors?.greys.dark};
    text-decoration: none;
    transition: color 0.2s cubic-bezier(0, 0.64, 0.25, 0.99);

    &:hover {
      cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};

      color: ${(props) =>
        props.isDisabled ? props.theme.colors.greys.light : props.theme.colors?.greys.darkest};
    }

    ${(props) => {
      if (props.selected) {
        return css`
          color: ${(props) => props.theme.colors?.greys.darkest};
          font: ${(props) =>
            props.small ? props.theme.fonts?.textSmallWeight600 : props.theme.fonts?.textBigBold};
        `;
      }
      if (props.isDisabled) {
        return css`
          color: ${(props) => props.theme.colors.greys.light};
        `;
      }
    }}
  }

  ${(props) => {
    if (props.selected) {
      return css`
        border-top: 4px solid rgba(0, 0, 0, 0);
        border-bottom: 4px solid #26e888;
      `;
    }
  }}

  text-align: center;

  display: flex;
  align-items: center;

  margin-right: 24px;
`;

export const ContainerAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 0px 24px;

  max-height: 64px;

  ${(props) => {
    if (props.withBottomBorder) {
      return css`
        border-bottom: 2px solid #f8f8f8;
      `;
    }
  }}
`;

export const ContainerPages = styled.div`
  flex: 1;
`;

export const CustomRenderContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 16px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;

    font: ${(props) => props.theme.fonts?.textBig};
    color: ${(props) => props.theme.colors?.greys.darkest};
  }

  img {
    width: 24px;
    margin-right: 24px;

    cursor: pointer;
  }

  a {
    cursor: pointer;
  }
`;

export const ClickableImage = styled.img`
  ${(props) => {
    if (props.disabled) {
      return css`
        pointer-events: none;
      `;
    }
  }}
`;
