import styled, { css, keyframes } from 'styled-components';

const toastInRight = keyframes`
	from {
	  transform: translateX(100%);
	}
	to {
	  transform: translateX(0);
	}
`;

const toastInRightOut = keyframes`
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0%);
	}
`;

const toastInLeft = keyframes`
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0);
	}
`;

const toastInLeftOut = keyframes`
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0%);
	}
`;

const animatedBackground = keyframes`
  from {
    background-position: right bottom;
  }
  to {
    background-position: left bottom;
  }
`;

export const Container = styled.div`
  z-index: 33;

  width: 320px;

  position: fixed;

  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);

  ${(props) => {
    if (props.position === 'top-right') {
      return css`
        top: 15px;
        right: 15px;
        transition: transform 0.6s ease-in-out;
        animation: ${toastInRight} 0.7s;
      `;
    }

    if (props.position === 'bottom-right') {
      return css`
        bottom: 15px;
        right: 15px;
        transition: transform 0.6s ease-in-out;
        animation: ${toastInRight} 0.7s;
      `;
    }

    if (props.position === 'top-left') {
      return css`
        top: 15px;
        transition: transform 0.6s ease-in;
        left: ${props.hidden ? '-370px' : '15px'};
        animation: ${props.hidden ? toastInLeftOut : toastInLeft} 0.7s;
      `;
    }

    if (props.position === 'bottom-left') {
      return css`
        left: 15px;
        bottom: 15px;
        transition: transform 0.6s ease-in;
        animation: ${toastInLeft} 0.7s;
      `;
    }

    if (props.position === 'top-left-fade-out') {
      return css`
        top: 15px;
        left: -370px;
        transition: transform 0.6s ease-in;
        animation: ${toastInLeftOut} 0.7s;
      `;
    }

    if (props.position === 'bottom-left-fade-out') {
      return css`
        bottom: 15px;
        left: -370px;
        transition: transform 0.6s ease-in;
        animation: ${toastInLeftOut} 0.7s;
      `;
    }

    if (props.position === 'top-right-fade-out') {
      return css`
        top: 15px;
        right: -370px;
        transition: transform 0.6s ease-in;
        animation: ${toastInRightOut} 0.7s;
      `;
    }

    if (props.position === 'bottom-right-fade-out') {
      return css`
        bottom: 15px;
        right: -370px;
        transition: transform 0.6s ease-in;
        animation: ${toastInRightOut} 0.7s;
      `;
    }

    return ``;
  }}
`;

export const Banner = styled.div`
  width: 100%;
  height: 4px;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  ${(props) => {
    if (!props.hidden && props.enabledAnimation) {
      return css`
        background: linear-gradient(
          to right,
          ${props.backgroundColor} 50%,
          white 50%
        );
        background-size: 200% 100%;
        animation: ${animatedBackground} ${props.transitionTime}s linear;
      `;
    }

    return css`
      display: block;
      animation: none;
      background-size: 100%;
      background-position: center;
      background: ${props.backgroundColor};
    `;
  }}
`;

export const Content = styled.div`
  padding: 24px;

  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  flex: 1;

  margin: 0px 16px;

  color: #1d1d1b;
  font: normal 500 14px/16px inter;
`;

export const Icon = styled.img`
  ${(props) =>
    props.hoverable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

export default {
  Icon,
  Container,
};
