import styled from 'styled-components';

export const StyledTooltip = styled.div`
  .barchart-tooltip-container {
    height: 220px;
    min-height: 200px;
    width: 240px;
    background: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(0, 2, 6, 0.1);
    border-radius: 4px;
    margin-top: 100px;
    position: relative;
    z-index: 2;
  }

  .barchart-tooltip-header {
    height: 26px;
    width: 100%;
    background-color: ${(props) => props.theme.colors?.greys.lighter || '#6b768c'};
    color: ${(props) => props.theme.colors?.greys.darkest || '#fff'};
    text-align: center;
    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.h4};`
        : `line-height: 26px;
    font-family: proximanovaregular;
    `}
    text-transform: capitalize;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .barchart-tooltip-left {
    float: left;
    width: 40%;
    border-right: solid 1px rgba(216, 216, 216, 0.56);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .tooltip-past-error-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .tooltip-past-error-item > h3 {
    padding: 0;
    margin: 0;
    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.textSmall};`
        : `margin-top: 8px;
        font-size: 16px;
    line-height: 16px;
    font-family: proximanovasemibold;`}
    text-align: center;
    width: 50px;
  }

  .tooltip-past-error-item > span {
    padding: 0;
    margin: 0;

    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.textSmall};`
        : `
    margin-top: 8px;
    font-size: 16px;
    line-height: 16px;
    font-family: proximanovasemibold;`}
    text-align: center;
    width: 80px;
  }

  .tooltip-past-error-item > h4 {
    padding: 0;
    margin: 0;
    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.textSmall};`
        : `font-size: 10px;
    line-height: 10px;
    font-family: proximanovasemibold;`}
  }
  .barchart-tooltip-right {
    float: left;
    width: 60%;
  }
  .barchart-tooltip-weather-container {
    float: left;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 15px;
    width: calc(100% - 50px);
    height: 22px;
    ${(props) => (props.theme.fonts ? `font: ${props.theme.fonts?.textSmall};` : undefined)}
  }

  .barchart-tooltip-weather-temperature {
    float: left;
    font-size: 18px;
    margin-left: 15px;
  }

  .barchart-tooltip-weather-icon {
    float: right;
    margin-right: 15px;
    height: 22px;
  }

  .barchart-tooltip-events-container {
    float: left;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    height: 100px;
    overflow: hidden;
    width: calc(100% - 20px);
  }
  .barchart-tooltip-events-container > h2 {
    margin: 0;
    padding: 0;
    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.textSmall};`
        : `font-size: 12px;
    font-family: proximanovasemibold;`}
    color: #6b768c;
  }

  .barchart-tooltip-event-item {
    float: left;
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  .barchart-tooltip-event-icon {
    width: 20px;
    height: 20px;
    border-radius: 30px;
    box-shadow: 0 2px 6px 0 rgba(0, 2, 6, 0.15);
    margin-right: 10px;
    margin-top: -2px;
  }

  .barchart-tooltip-event-name {
    width: calc(100% - 30px);
    max-width: 130px;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${(props) => (props.theme.fonts ? `font: ${props.theme.fonts?.textSmall};` : undefined)}
  }

  & .barchart-tooltip-event-empty-state {
    ${(props) => (props.theme.fonts ? `font: ${props.theme.fonts?.textSmall};` : undefined)}
    font-size: 12px;
  }
`;
