import styled, { css } from 'styled-components';

export const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  padding-left: 15px;

  width: ${(props) =>
    !props.hasNestedData && (props.large ? '240px' : '160px')};

  ${(props) =>
    props.hasNestedData &&
    props.large &&
    css`
      flex: 2;
      padding-right: 0px;
      flex-basis: calc(${(100 / props.nbColumn) * 2}%);
      width: '320px';
    `}

  ${(props) =>
    props.hasNestedData &&
    !props.large &&
    css`
      flex: 1;
      padding-right: ${props.isHighlighted ? '23px' : '20px'};
      flex-basis: calc(${100 / props.nbColumn}%);
    `}


  ${(props) =>
    props.narrow &&
    css`
      width: fit-content;
      flex: none;
    `}

  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth}px;
    `}

  ${(props) =>
    props.isLoading &&
    css`
      border-radius: 4px;
      background-color: #f8f8f8;
      height: ${() => `${props.height}px`};
    `}

  color: ${(props) => props.theme.row?.color};

  font: ${(props) =>
    props.isBold ? props.theme.row.bold.font : props.theme.row.font};

  ${(props) =>
    props.isHighlighted &&
    css`
      color: ${props.theme.row?.highlighted?.color};
      font: ${props.theme.row?.highlighted?.font};
    `}

  ${(props) =>
    props.displayBigger &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    `}
`;

export const DisplayNameContainer = styled.div`
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default {
  Item,
  DisplayNameContainer,
};
