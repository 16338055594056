import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const ColumnsTitleContainer = styled.div`
  width: calc(100% - 114px);
  padding: 0px 16px 16px 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
`;

export const Column = styled.div`
  flex: 1;
  height: 16px;

  font: ${(props) => props.theme.fonts.textBigHeight16};
  color: ${(props) =>
    props.isColumnTitle ? props.theme.colors.greys.darker : props.theme.colors.greys.darkest};

  ${(props) =>
    props.isColumnTitle &&
    css`
      column-gap: 8px;
      display: flex;
      align-items: center;

      > div {
        margin: 0px;
      }
    `}
`;

export const ListContainer = styled.div`
  width: 100%;
  display: flex;

  position: relative;
`;

export const RowsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  position: relative;
`;

export const ArrowIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 64px;
  padding: 68px 0px 68px 8px;
  position: absolute;
`;

export const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: ${(props) => props.theme.colors.greys.darkest};
`;

export const Icon = styled.img`
  width: 12px;
  height: 12px;
`;
