import { get, groupBy, isEmpty, cloneDeep } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';
import EmptyState from '@commons/EmptyState';

import { getTotalPrice } from '../../utils/getPrice';
import { STOCKFORM_EMPTY_STATES } from '../../utils/emptyStates';

import { Container, FormContainer } from './styledComponents';
import StockFormCategory from './components/StockFormCategory';
import StockFromCategorySumup from './components/StockFromCategorySumup';

const StockContent = (props) => {
  const {
    readOnly,
    groupByProperty,
    stockType,
    isSearchActive,
    handleDataUpdate,
    supplierProducts,
    totByStorageAreaIdSPIdAndPackagingId,
    selectedStorageAreaId,
    categoriesToUnfold,
    setCategoriesToUnfold,
  } = props;

  const [categoriesToUnfoldDuringSearch, setCategoriesToUnfoldDuringSearch] = useState([]);

  const groupedSupplierProductsByKey = groupBy(supplierProducts, (supplierProduct) =>
    get(supplierProduct, groupByProperty, ''),
  );

  useEffect(() => {
    if (!!isSearchActive) {
      const categories = Object.keys(groupedSupplierProductsByKey);
      setCategoriesToUnfoldDuringSearch(categories);
      return;
    }

    setCategoriesToUnfoldDuringSearch([]);
  }, [isSearchActive]);

  const handleCategoryUnfolding = (selectedCategory) => {
    let selectedCategories = !!isSearchActive
      ? cloneDeep(categoriesToUnfoldDuringSearch)
      : cloneDeep(categoriesToUnfold);

    if (!!isSearchActive) {
      if (categoriesToUnfoldDuringSearch.includes(selectedCategory)) {
        const categoriesToDisplay = categoriesToUnfoldDuringSearch.filter(
          (category) => category !== selectedCategory,
        );

        setCategoriesToUnfoldDuringSearch(categoriesToDisplay);
        return;
      }

      selectedCategories.push(selectedCategory);
      setCategoriesToUnfoldDuringSearch(selectedCategories);

      return;
    }

    if (!!supplierProducts && supplierProducts.length > 100) {
      selectedCategories = [];
    }

    if (categoriesToUnfold.includes(selectedCategory)) {
      const categoriesToDisplay = categoriesToUnfold.filter(
        (category) => category !== selectedCategory,
      );
      setCategoriesToUnfold(categoriesToDisplay);
      return;
    }

    selectedCategories.push(selectedCategory);
    setCategoriesToUnfold(selectedCategories);
  };

  if (isSearchActive && (!supplierProducts || !supplierProducts.length)) {
    return (
      <Container center>
        <EmptyState
          icon={'/images/inpulse/no-results-search.svg'}
          label={i18next.t('ORDERS.ORDERS.FORM_EMPTY_STATE_SUPPLIER_PRODUCT_NOT_FOUND')}
        />
      </Container>
    );
  }

  if (isEmpty(groupedSupplierProductsByKey)) {
    return readOnly
      ? STOCKFORM_EMPTY_STATES.NO_SP_IN_INVENTORY
      : STOCKFORM_EMPTY_STATES.NO_SP_ASSOCIATED_TO_STORAGE_AREA;
  }

  return (
    <Container>
      {Object.keys(groupedSupplierProductsByKey)
        .sort()
        .map((key, index) => {
          const supplierProducts = sortArrayOfObjectsAlphabetically(
            groupedSupplierProductsByKey[key],
            'name',
          );

          const isCategoryOpen = !!isSearchActive
            ? categoriesToUnfoldDuringSearch.includes(key)
            : categoriesToUnfold.includes(key);

          return (
            <FormContainer displayBottomBorder={isCategoryOpen} key={index}>
              <StockFromCategorySumup
                handleCategoryUnfolding={handleCategoryUnfolding}
                isCategoryOpen={isCategoryOpen}
                name={key}
                total={getTotalPrice(supplierProducts, totByStorageAreaIdSPIdAndPackagingId)}
              />

              {isCategoryOpen && (
                <StockFormCategory
                  categoryName={key}
                  handleDataUpdate={handleDataUpdate}
                  readOnly={readOnly}
                  selectedStorageAreaId={selectedStorageAreaId}
                  stockType={stockType}
                  supplierProducts={supplierProducts}
                  totByStorageAreaIdSPIdAndPackagingId={totByStorageAreaIdSPIdAndPackagingId}
                />
              )}
            </FormContainer>
          );
        })}
    </Container>
  );
};

export default StockContent;
