import styled from 'styled-components';

export const RowContainer = styled.div`
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: ${(props) => props.theme.colors.greys.lightest};

  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  row-gap: 8px;

  padding: 24px 0px;
`;

export const ColumnsContainer = styled.div`
  width: calc(100% - 40px);
  display: flex;
  padding: 0 16px;
  margin: 0 0 16px 0;
`;

export const Column = styled.div`
  font: ${(props) => props.theme.fonts?.textBig} !important;
  color: ${(props) => props.theme.listView?.header.default.color} !important;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  flex: ${(props) => props.flex};

  margin-right: 16px;
  display: flex;
  align-items: center;
`;
