import i18next from 'i18next';

import { getClientStoreNameTranslation } from '../../../utils/translations';

export const DETAILS = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: () => i18next.t('ADMIN.SUB_FEATURES.INFORMATIONS'),
  path: '/admin/suppliers/supplier-products/:id/details',
  getLink: ({ supplierProduct: { id } }) => `/admin/suppliers/supplier-products/${id}/details`,
};
export const STORES = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: ({ storeName }) =>
    i18next.t('ADMIN.SUB_FEATURES.MAPPED_STORES', {
      storeNamePlural: getClientStoreNameTranslation(storeName, true),
    }),
  path: '/admin/suppliers/supplier-products/:id/stores',
  getLink: ({ supplierProduct: { id } }) => `/admin/suppliers/supplier-products/${id}/stores`,
};

export const SUPPLIER_PRODUCT_CREATION = {
  isFullScreen: true,
  path: '/admin/suppliers/supplier-products/create',
  isVisible: () => false,
};
