import styled from 'styled-components';

export const IconContainer = styled.div`
  position: relative;
`;

export const Icon = styled.img`
  border-radius: ${(props) => props.theme.customModal?.icon.borderRadius};
  padding: 3px 8px 3px 8px;

  background-color: ${(props) =>
    props.selected
      ? props.theme.customModal?.icon?.selected?.backgroundColor
      : props.theme.customModal?.icon?.default?.backgroundColor};

  &:hover {
    background-color: ${(props) =>
      props.theme.customModal?.icon?.hover?.backgroundColor};
  }
`;

export default { IconContainer, Icon };
