import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useEffect, useReducer } from 'react';

import { fetchUser } from '@actions/user';
import { loading, loadingSuccess } from '@actions/loading';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { Button } from '@commons/utils/styledLibraryComponents';

import { user as userService } from '@services/user';

import { Container } from './styledComponents';

/**
 * Assert that the general form of that page can be saved
 *
 * @param {Object} profileUser  - The profile user to save
 *
 * @returns {boolean} Whether the form is valid
 */
export function isFormValid(profileUser) {
  if (!profileUser.email || !profileUser.roleId) {
    return false;
  }

  return true;
}

/**
 * Handle the save of the profile user information
 *
 * @param {Props} props                 - The props linked to the component
 * @param {Product} props.profileUser   - The user information to display
 * @param {Function} props.showMessage  - Method used to disaptch a message on the app
 *
 * @returns {void}
 */
export async function handleSave(props) {
  const {
    profileUser,
    showMessage,
    profileSaving,
    profileSaved,
    updateCurrentUser,
    isCurrentUserProfile,
  } = props;

  profileSaving();

  try {
    await userService.patchAccountById(profileUser.id, {
      email: profileUser.email,
      roleId: profileUser.roleId,
      languageId: profileUser.languageId,
      lastName: profileUser.lastName,
      firstName: profileUser.firstName,
      img: profileUser.img || undefined,
      catalogId: profileUser.catalogId === -1 ? null : profileUser.catalogId,
    });

    // Update redux user
    if (isCurrentUserProfile) {
      updateCurrentUser(profileUser.id);
      i18next.changeLanguage(profileUser.languageCode || 'fr');
    }

    showMessage(i18next.t('USERS.DETAILS.PROFILE_SAVED'));
  } catch (error) {
    showMessage(error.message, 'error');
  } finally {
    profileSaved();
  }
}

export const ProfileBottomBar = (props) => {
  const { user, profileUser } = props;

  // To force re-render when user save new language in its settings
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    forceUpdate();
  }, [user]);

  return (
    <Container>
      <Button
        handleClick={() => handleSave(props)}
        icon={'/images/inpulse/save-white-small.svg'}
        isDisabled={!isFormValid(profileUser)}
        label={i18next.t('GENERAL.SAVE')}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  profileSaving: () => {
    dispatch(loading());
  },
  profileSaved: () => {
    dispatch(loadingSuccess());
  },
  updateCurrentUser: (id) => {
    dispatch(fetchUser(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBottomBar);
