import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;

  display: flex;
  flex-direction: row;
`;

export const ButtonContainer = styled.div`
  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.greys.darkest};

  background: ${(props) =>
    props.isOpened ? props.theme.colors.greys.darkest : props.theme.colors.greys.lightest};

  &:hover {
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  }

  ${(props) =>
    props.isDisabled &&
    css`
      border: 1px solid ${props.theme.colors.greys.lighty};
    `}
`;

export const TooltipContainer = styled.div`
  width: 256px;
  height: auto;

  padding: 16px;

  z-index: 6;

  position: absolute;
  // ButtonContainer width + separation of 8px
  margin-left: 32px;

  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px ${(props) => props.theme.colors.greys.light};
  background: ${(props) => props.theme.colors.greys.lightest};

  ${(props) => props.customStyle}
`;
