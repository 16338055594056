import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

// ACTIONS
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// COMMONS
import NavigationBar from '@commons/NavigationBar';

// SELECTORS
import { getClientInfo } from '@selectors/client';

// SERVICES
import clientService from '@services/client';

import { ContentContainer, PageContainer } from '../styledComponents';
import InventoryListTemplateRecipeMappings from '../inventoryListTemplateMappings/recipeMappings';

const InventoryListTemplateRecipes = (props) => {
  const {
    match: { path, params },
    client: { clientId },
    pageLoaded,
    pageLoading,
    showErrorMessage,
  } = props;

  const inventoryListTemplateId = params.id;

  const [isLoading, setIsLoading] = useState(true);
  const [inventoryListTemplate, setInventoryListTemplate] = useState({});

  useEffect(() => {
    if (!params.id) {
      return;
    }

    pageLoading();

    (async () => {
      try {
        const fetchedInventoryListTemplate = await clientService.getInventoryListTemplateById(
          clientId,
          params.id,
        );

        setInventoryListTemplate(fetchedInventoryListTemplate);
      } catch {
        showErrorMessage(i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.FETCH_ERROR'));
      } finally {
        setIsLoading(false);
        pageLoaded();
      }
    })();
  }, []);

  return (
    <>
      <NavigationBar
        inventoryListTemplate={{
          id: inventoryListTemplateId,
          name: inventoryListTemplate.name,
        }}
        path={path}
        bigTopBar
        enableActionBottomOrder
      />
      <PageContainer>
        <ContentContainer>
          <InventoryListTemplateRecipeMappings
            existingMappings={inventoryListTemplate.entityMappings}
            inventoryListTemplateId={inventoryListTemplateId}
            isLoading={isLoading}
          />
        </ContentContainer>
      </PageContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryListTemplateRecipes);
