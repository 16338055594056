import i18next from 'i18next';
import React from 'react';

import DisplayNumber, { formatNumberWithCurrency } from '@commons/DisplayNumber';

import { Icon, LabelContainer, ProductNameContainer, ProductNameLabel } from '../styledComponents';

const DEFAULT_PRODUCT_IMG = '/images/inpulse/ingredient-photo-placeholder.svg';

const getTurnoverRealConsumptionColumns = (currency) => [
  {
    name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_REVENUE'),
    propertyKey: 'turnoverWithoutTaxes',
    render: (item) =>
      formatNumberWithCurrency({
        displayCurrencyCode: true,
        shortenDigits: false,
        withoutDecimals: true,
        number: item,
        currency,
      }),
  },
  {
    name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_ACTUAL_FC'),
    propertyKey: 'realConsumption',
    render: (item) =>
      formatNumberWithCurrency({
        displayCurrencyCode: true,
        shortenDigits: false,
        withoutDecimals: true,
        number: item,
        currency,
      }),
  },
  {
    name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_PERCENT_ACTUAL_FC'),
    propertyKey: 'realConsumptionPercentage',
    render: (item) => `${Number.isInteger(item) ? item : item.toFixed(2)}%`,
  },
  {
    name: i18next.t('FORECAST.PRODUCT_MIX.ACTIVITY_BOX_FORECAST_MARGIN'),
    propertyKey: 'marginWithoutTaxes',
    render: (item) =>
      formatNumberWithCurrency({
        displayCurrencyCode: true,
        shortenDigits: false,
        withoutDecimals: true,
        number: item,
        currency,
      }),
  },
  {
    name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_PERCENT_MARGIN_WITHOUT_TAXES'),
    propertyKey: 'marginWithoutTaxesPercentage',
    render: (item) => `${Number.isInteger(item) ? item : item.toFixed(2)}%`,
  },
];

const getTopFlopColumns = (useTtc) => [
  {
    id: 1,
    type: 'string',
    propertyKey: 'name',
    name: i18next.t('GENERAL.NAME'),
    displayBigger: true,
    render: (item, row) => (
      <ProductNameContainer>
        <Icon src={row.img || DEFAULT_PRODUCT_IMG} />
        <ProductNameLabel>{item}</ProductNameLabel>
      </ProductNameContainer>
    ),
  },
  {
    id: 2,
    type: 'numeric',
    propertyKey: 'margin',
    name: i18next.t('GENERAL.MARGIN'),
    render: (item) => (
      <LabelContainer>
        <DisplayNumber displayCurrencyCode={true} number={item} />
      </LabelContainer>
    ),
  },
  {
    id: 3,
    type: 'numeric',
    propertyKey: 'unit',
    name: i18next.t('DASHBOARD.FOODCOST.UNIT_COUNT'),
    render: (item) => (
      <LabelContainer>
        <DisplayNumber displayCurrencyCode={false} number={item} withoutDecimals />
        {i18next.t('DASHBOARD.FOODCOST.UNIT')}
      </LabelContainer>
    ),
  },
  {
    id: 4,
    type: 'numeric',
    propertyKey: useTtc ? 'ca' : 'turnoverExcludingTaxes',
    name: i18next.t(useTtc ? 'GENERAL.TURNOVER_TAX_INCLUDED' : 'GENERAL.TURNOVER_EX_TAX'),
    render: (item) => (
      <LabelContainer>
        <DisplayNumber number={item} displayCurrencyCode withoutDecimals />
      </LabelContainer>
    ),
  },
];

export default {
  getTurnoverRealConsumptionColumns,
  getTopFlopColumns,
};
