import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { Label } from '@commons/utils/styledLibraryComponents';

import theme from '@theme';

import { getEntityUnit } from '@stocks/utils/';

import { Container } from './styledComponents';

const ICON_THUNDER_BLACK = '/images/inpulse/lightning-black-small.svg';
const ICON_THUNDER_GREY = '/images/inpulse/lightning-dmgrey-small.svg';
const ICON_UNLINK_GREY = '/images/inpulse/unlink-dmgrey-small.svg';

const RecommandationLabel = (props) => {
  const { entityRecommandation, moreThanFiftyDay, isAssociated, unit, id } = props;

  const [labelText, setLabelText] = useState('');
  const [backgroundLabel, setBackgroundLabel] = useState(theme?.colors?.lgrey);
  const [colorLabel, setColorLabel] = useState(theme?.colors?.greys?.darkest);
  const [iconLabel, setIconLabel] = useState(null);
  const [tooltipLabel, setTooltipLabel] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!entityRecommandation && entityRecommandation !== 0 && !!isAssociated) {
      return;
    }
    const productUnit = getEntityUnit(unit);

    if (!isAssociated) {
      setLabelText(i18next.t('ORDERS.ORDERS.FORM_RECOMMANDATION_NOT_ASSOCIATED_TOOLTIP_LABEL'));
      setTooltipLabel(i18next.t('ORDERS.ORDERS.FORM_RECOMMANDATION_NOT_ASSOCIATED_TOOLTIP'));
      setBackgroundLabel(theme?.colors?.lgrey);
      setColorLabel(theme?.colors?.greys?.dark);
      setIconLabel(ICON_UNLINK_GREY);
      setIsLoading(false);
      return;
    }

    if (moreThanFiftyDay && isAssociated) {
      setBackgroundLabel(theme?.colors?.infoLightOrange);
      setColorLabel(theme?.colors?.greys?.darkest);
      setTooltipLabel(i18next.t('ORDERS.ORDERS.FORM_RECOMMANDATION_INCOMPLETE_TOOLTIP'));
      setIconLabel(ICON_THUNDER_BLACK);
    }

    if (entityRecommandation === 0 && !moreThanFiftyDay) {
      setBackgroundLabel(theme?.colors?.lgrey);
      setColorLabel(theme?.colors?.greys?.dark);
      setIconLabel(ICON_THUNDER_GREY);
    }

    if (entityRecommandation > 0 && !moreThanFiftyDay) {
      setBackgroundLabel(theme?.colors?.infoBlue);
      setIconLabel(ICON_THUNDER_BLACK);
      setColorLabel(theme?.colors?.greys?.darkest);
    }

    setLabelText(
      `${!!entityRecommandation ? entityRecommandation.toFixed(2) : '0.00'} ${productUnit}`,
    );
    setIsLoading(false);
  }, [entityRecommandation, isAssociated]);

  return (
    <Container>
      <div
        data-for={id}
        data-html={true}
        data-tip={
          `<div style="padding: 16px 24px; color: #FFFFFF; outline: 0; background-color: #1D1D1B; border-radius: 8px; font: normal 500 14px/16px inter; max-width: 300px; text-align: center">` +
          `${tooltipLabel}` +
          `</div>`
        }
        data-tip-disable={(!moreThanFiftyDay && isAssociated) || isLoading}
      >
        <Label
          background={backgroundLabel}
          children={labelText}
          color={colorLabel}
          icon={iconLabel}
          isLoading={isLoading}
          position="left"
          type="plain"
        />
      </div>
      <ReactTooltip
        arrowColor={theme?.colors?.greys?.darkest}
        backgroundColor="transparent"
        data-place="top"
        effect="solid"
        id={id}
      />
    </Container>
  );
};

export default RecommandationLabel;
