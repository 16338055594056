import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  overflow: hidden;
  background-color: ${(props) => props.theme.colors?.greys.lighter || '#ffffff'};
`;

export const ContainerContent = styled.div`
  width: 100%;
  position: relative;
  height: calc(100% - 48px);

  overflow: scroll;
`;

export const FullHeightContainer = styled.div`
  width: 100%;
  min-height: 100%;

  padding: 10px 30px 0 30px;
`;

export const ShadowedBox = styled.div`
  width: 25%;

  padding: 3%;
  margin: 18% auto;

  color: #6b768c;
  text-align: center;
  font-weight: bold;

  border-radius: 8px;
  background: white;
  box-shadow: 0 2px 12px 4px rgba(0, 0, 0, 0.13);
`;
