import _ from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { capitalizeFirstLetter } from '@commons/utils/format';
import utilsXLS from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { productsService } from '@services/backoffice/products';

/**
 * Close the modal
 *
 * @returns {void}
 */
export const exitModal = (setIsLoading, closeModal) => {
  setIsLoading(false);

  closeModal();
};
export const getSheetColumnsSettings = (sheetColumns) => {
  switch (sheetColumns) {
    case 'cashierProducts':
      let cashierProductsColumns = [
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_ID'),
          keyName: 'id',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_NAME'),
          keyName: 'name',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_SKU'),
          keyName: 'sku',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_PRICE_WITH_TAXES'),
          keyName: 'priceWithTaxes',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_VAT_RATE'),
          keyName: 'vatRate',
          transform: (value) => value,
        },
      ];
      return cashierProductsColumns;
    case 'products':
      let productsColumns = [
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_NAME'),
          keyName: 'name',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_CATEGORY'),
          keyName: 'category',
          transform: (value) => capitalizeFirstLetter((value || '').toLowerCase()),
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_BRAND'),
          keyName: '',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_PRICE_WITH_TAXES'),
          keyName: 'priceWithTaxes',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.PRODUCTS.EXPORT_MODAL_LABEL_CURRENCY'),
          keyName: 'currency',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_VAT_RATE'),
          keyName: 'vatRate',
          transform: (value) => value,
        },
      ];
      return productsColumns;
    case 'mappings':
      let mappingsColumns = [
        {
          displayName: i18next.t('BACKOFFICE.PRODUCTS.EXPORT_MODAL_MAPPINGS_LABEL_ID'),
          keyName: 'cashierProductId',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.PRODUCTS.EXPORT_MODAL_LABEL_TURNOVER_WITH_TAXES'),
          keyName: 'cashierProductName',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.PRODUCTS.EXPORT_MODAL_MAPPINGS_LABEL_SKU'),
          keyName: 'cashierProductSku',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.PRODUCTS.EXPORT_MODAL_LABEL_PRICE_SYNC'),
          keyName: 'cashierProductPriceSync',
          transform: (value) => value,
        },
        {
          displayName: i18next.t('BACKOFFICE.PRODUCTS.EXPORT_MODAL_MAPPINGS_PRODUCTS_LABEL_NAME'),
          keyName: 'productName',
          transform: (value) => value,
        },
      ];
      return mappingsColumns;
  }
};

export const createSheet = async (sheetTitle, columns, data) => {
  const headersSettings = getSheetColumnsSettings(columns);

  const headers = headersSettings.map(({ displayName }) => displayName);

  const mappedData = data.map((item) =>
    headersSettings.map(({ keyName, transform }) => transform(_.get(item, keyName))),
  );

  return {
    title: sheetTitle,
    headers,
    data: mappedData,
  };
};

export const ProductsFromCashierProductsExportModal = (props) => {
  const {
    params: { title, clientId, ClientName, showMessage },
    closeModal,
  } = props;
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [titleModal, setTitleModal] = useState(title || '');

  useEffect(() => {
    (async function launchExport() {
      try {
        const data = await productsService.getProductsFromCashierProductsOfClient(clientId);
        const CashierProductsSheet = await createSheet(
          i18next.t(
            'BACKOFFICE.PRODUCTS.EXPORT_MODAL_PRODUCTS_CASHIER_PRODUCTS_MAPPINGS_SHEETTITLE_CASHIER_PRODUCTS',
          ),
          'cashierProducts',
          data.cashierProducts,
        );
        const productsSheet = await createSheet(
          i18next.t(
            'BACKOFFICE.PRODUCTS.EXPORT_MODAL_PRODUCTS_CASHIER_PRODUCTS_MAPPINGS_SHEETTITLE_PRODUCTS',
          ),
          'products',
          data.products,
        );
        const mappingsSheet = await createSheet(
          i18next.t(
            'BACKOFFICE.PRODUCTS.EXPORT_MODAL_PRODUCTS_CASHIER_PRODUCTS_MAPPINGS_SHEETTITLE_MAPPINGS',
          ),
          'mappings',
          data.mappings,
        );
        utilsXLS.makeXLS(
          i18next.t('BACKOFFICE.PRODUCTS.EXPORT_MODAL_PRODUCTS_CASHIER_PRODUCTS_MAPPINGS_TITLE') +
            ' - ' +
            ClientName,
          [CashierProductsSheet, productsSheet, mappingsSheet],
        );
        setTitleModal(i18next.t('GENERAL.EXPORT_SUCCESS'));
        setIsLoading(false);
      } catch (error) {
        showMessage(i18next.t('GENERAL.EXPORT_FAILURE'), 'error');
        exitModal(setIsLoading, closeModal);
      }
    })();
  }, []);

  useEffect(() => {
    if (isLoading && progress < 100) {
      setProgress(100);
    }
  }, [progress]);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setIsLoading}
      titleModal={titleModal}
    />
  );
};

export default ProductsFromCashierProductsExportModal;
