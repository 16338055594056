import { isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import BlackTrashBin from '@assets/icons/icon-delete-trash.svg';

import { Checkbox } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { spUnitByIngredientUnit } from '@commons/utils/units';
import DisplayNumber from '@commons/DisplayNumber';

import { getUnitValue } from '@admin/utils/DropdownItems';

import { convertSPPQuantityInMasterUnit } from '@orders/utils/computePackagingToSupplierProduct';

import utilsMethods from '@admin/products/ingredients/detail/utils';

import {
  CentralKitchenIcon,
  ContainerListComposition,
  ItemLinkText,
  ListHeader,
  ListHeaderSection,
  ListRow,
  ListRowSection,
  ListRowText,
  CheckboxContainer,
} from './styledComponents';

const renderUnitsList = (props) => {
  const { conversions, packagingUnit } = props;

  const list = conversions
    .reduce(
      (acc, { convertedUnit }) => {
        if (convertedUnit && convertedUnit !== packagingUnit) {
          acc.push(getUnitValue(convertedUnit));
        }

        return acc;
      },
      [getUnitValue(packagingUnit)],
    ) // First unit displayed is packaging unit
    .join(', ');

  return list;
};

const getHeaders = (isCreation) => {
  const headers = [
    {
      name: i18next.t('GENERAL.NAME'),
      className: 'name',
      renderValue: ({ item }, { history }) => (
        <ListRowText
          className="link"
          onClick={() => {
            window.open(`/admin/suppliers/supplier-products/${item.id}/details`, '_blank');
          }}
        >
          <ItemLinkText>{item.name}</ItemLinkText>
        </ListRowText>
      ),
    },
    {
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_SUPPLIER'),
      className: 'supplierName',
      renderValue: ({ item }) => <ListRowText>{item.supplierName}</ListRowText>,
    },
    {
      name: i18next.t('ADMIN.INGREDIENTS.DETAIL_RECIPE_UNIT'),
      renderValue: ({ item }) => <ListRowText>{renderUnitsList(item)}</ListRowText>,
    },
    {
      name: i18next.t('ADMIN.INGREDIENTS.LABEL_COST'),
      renderValue: ({ item }, props) => {
        const { ingredient } = props;

        const { packagings, price, conversions, currency } = item;

        if (isEmpty(conversions)) {
          conversions.push({
            convertedUnit: spUnitByIngredientUnit.get(ingredient.unit),
            convertedQuantity: 1,
          });
        }

        const invoicePackaging = packagings.find(({ isUsedInInvoice }) => isUsedInInvoice);

        const masterSPP = packagings.find(
          ({ masterSupplierProductPackagingId }) => masterSupplierProductPackagingId === null,
        );

        const sppQuantityInMasterUnit = convertSPPQuantityInMasterUnit(
          invoicePackaging.id,
          packagings,
        );

        const spConversionUnit = conversions.find(
          ({ convertedUnit }) => convertedUnit === masterSPP.unit,
        );
        const quantityForSpConversionUnit =
          (spConversionUnit && spConversionUnit.convertedQuantity) || 1;

        const ingredientConversionUnit = conversions.find(
          ({ convertedUnit }) => convertedUnit === spUnitByIngredientUnit.get(ingredient.unit),
        );
        const quantityForIngredientConversionUnit =
          (ingredientConversionUnit && ingredientConversionUnit.convertedQuantity) || 1;

        const convertedPrice =
          (price * quantityForSpConversionUnit) /
          (sppQuantityInMasterUnit * quantityForIngredientConversionUnit);

        return (
          <DisplayNumber
            currency={currency}
            displayCurrencyCode={true}
            number={convertedPrice || 0}
          />
        );
      },
    },
    {
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_LOSS'),
      renderValue: ({ item }) => <ListRowText>{item.loss || 0} %</ListRowText>,
    },
    {
      name: i18next.t('ADMIN.INGREDIENTS.COST_CALCULATION'),
      renderValue: ({ item }, props) => (
        <CheckboxContainer>
          <Checkbox
            handleClick={() => handleIsUsedForCostChange(item.id, !item.isUsedForCost, props)}
            isChecked={item.isUsedForCost}
            shape="square"
            noSize
          />
        </CheckboxContainer>
      ),
    },
    {
      name: '',
      renderValue: ({ item }, props) => {
        if (props.isReadOnly) {
          return null;
        }

        return (
          <div
            className="actions"
            style={{
              backgroundImage: `url(${BlackTrashBin})`,
            }}
            onClick={(ev) => {
              ev.stopPropagation();
              deleteSupplierProduct(item.id, props);
            }}
            onMouseEnter={(e) =>
              (e.target.style.backgroundImage = "url('/images/icon-delete-trash-red.svg')")
            }
            onMouseLeave={(e) => (e.target.style.backgroundImage = `url(${BlackTrashBin}) `)}
          ></div>
        );
      },
    },
  ];

  if (isCreation) {
    return headers;
  }

  headers.splice(6, 0, {
    name: i18next.t('ADMIN.INGREDIENTS.SUB_PAGES_SUPPLIER_PRODUCT_COLUMN_ASSOCIATED_ON'),
    renderValue: ({ item }) => {
      if (!item.associatedAt) {
        return '-';
      }

      return (
        <ListRowText>
          {moment(item.associatedAt).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)}
        </ListRowText>
      );
    },
  });

  return headers;
};

/*************************/
/* Input Change Handlers */
/*************************/

/**
 * Handle user deleting a supplierProduct
 *
 * @param {Number} id   - The supplierProduct's id associated to the user selection
 * @param {Props} props - The props linked to the component
 *
 * @returns {void}
 */
export const deleteSupplierProduct = (id, props) => {
  const { ingredient, onIngredientChange } = props;

  const updatedsupplierProducts = ingredient.supplierProducts.filter((item) => item.id !== id);

  onIngredientChange({
    ...props.ingredient,
    supplierProducts: updatedsupplierProducts,
  });
};

export const handleIsUsedForCostChange = (spId, isUsedForCost, props) => {
  const { ingredient, onIngredientChange } = props;

  const updatedSPs = ingredient.supplierProducts.map((supplierProduct) => {
    if (spId === supplierProduct.id) {
      return { ...supplierProduct, isUsedForCost };
    }

    return supplierProduct;
  });

  onIngredientChange({
    ...props.ingredient,
    supplierProducts: updatedSPs,
  });
};

export const SupplierProductsCompositionList = (props) => {
  const { ingredient } = props;

  const isCreation = !ingredient.id;

  const headers = getHeaders(isCreation);

  return (
    <ContainerListComposition>
      {ingredient.supplierProducts.length > 0 && (
        <ListHeader>
          {headers.map(({ className, name }, indexHeader) => (
            <ListHeaderSection className={className} key={indexHeader}>
              {name}
            </ListHeaderSection>
          ))}
        </ListHeader>
      )}
      {ingredient.supplierProducts.map((item, indexComposition) => (
        <ListRow
          className={
            !utilsMethods.areUnitsSame(item.packagingUnit, ingredient.unit) &&
            !item.conversions.some(
              ({ convertedUnit }) => convertedUnit === spUnitByIngredientUnit.get(ingredient.unit),
            )
              ? 'error'
              : ''
          }
          key={indexComposition}
        >
          {item.isKitchen && (
            <CentralKitchenIcon>
              <img src="/images/inpulse/central-black-extra-small.svg" />
            </CentralKitchenIcon>
          )}
          {headers.map(({ className, renderValue }, indexItem) => (
            <ListRowSection className={className} key={indexItem}>
              {renderValue({ item, index: indexComposition }, { ...props })}
            </ListRowSection>
          ))}
        </ListRow>
      ))}
    </ContainerListComposition>
  );
};

SupplierProductsCompositionList.propTypes = {
  ingredient: PropTypes.object,
};

export default SupplierProductsCompositionList;
