import * as yup from 'yup';
import { cloneDeep, get } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { ENTITY_UNITS } from '@commons/constants/units';
import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import DisplayNumber from '@commons/DisplayNumber';

import { LOSS_TYPE } from '../../LossListView/constants';

export const LOSS_INPUTS = {
  CHANNEL: 'channel',
  COST: 'cost',
  LOSSES: 'losses',
  CATEGORY: 'category',
  NAME: 'name',
  UNIT_VALUE: 'unitValue',
  PACKAGING: 'packaging',
  PRICE: 'price',
  SUPPLIER_NAME: 'supplierName',
  TOTAL: 'total',
};

export const FORM_INPUTS = {
  [LOSS_INPUTS.NAME]: {
    type: 'plain-text',
    name: LOSS_INPUTS.NAME,
    width: INPUT_WIDTH.SMALL,
  },
  [LOSS_INPUTS.SUPPLIER_NAME]: {
    type: 'plain-text',
    name: LOSS_INPUTS.SUPPLIER_NAME,
    width: INPUT_WIDTH.MEDIUM,
  },
  [LOSS_INPUTS.CHANNEL]: {
    type: 'single-select',
    name: LOSS_INPUTS.CHANNEL,
    placeholder: i18next.t('GENERAL.SELECT_CHANNEL_PLACEHOLDER'),
    defaultValue: '',
    width: INPUT_WIDTH.MEDIUM,
    rule: yup
      .object({
        id: yup.string(),
        name: yup.string(),
        distributionChannels: yup.array().of(yup.string()),
      })
      .strict()
      .required('Please select a channel'),
    isRequired: true,
  },
  [LOSS_INPUTS.COST]: {
    type: 'currency',
    name: LOSS_INPUTS.COST,
    defaultValue: '-',
    width: INPUT_WIDTH.SMALL,
    computeValue: (currentRow) => {
      const currentChannelId = get(currentRow.channel, 'id');

      if (!!currentChannelId) {
        const currentChannelCost = get(currentRow.costByChannelId, currentChannelId);

        currentRow.cost = currentChannelCost;
      }

      return <DisplayNumber number={currentRow.cost || null} displayCurrencyCode />;
    },
  },
  [LOSS_INPUTS.CATEGORY]: {
    type: 'single-select',
    name: LOSS_INPUTS.CATEGORY,
    placeholder: i18next.t('GENERAL.SELECT_LOSS_CATEGORY_PLACEHOLDER'),
    width: INPUT_WIDTH.MEDIUM,
    rule: yup
      .object({
        id: yup.mixed(), // To handle Aucune option with id === -1
        name: yup.string(),
        value: yup.string(),
      })
      .nullable(true)
      .strict(),
    isRequired: true,
  },
  [LOSS_INPUTS.LOSSES]: {
    type: 'number',
    name: LOSS_INPUTS.LOSSES,
    width: INPUT_WIDTH.MEDIUM,
    defaultValue: 0,
    rule: yup
      .number()
      .positive('Please enter a correct loss value')
      .required('Please enter your loss'),
  },
  [LOSS_INPUTS.PACKAGING]: {
    type: 'single-select',
    name: LOSS_INPUTS.PACKAGING,
    placeholder: i18next.t('GENERAL.SELECT_PACKAGING_PLACEHOLDER'),
    width: INPUT_WIDTH.MEDIUM,
    rule: yup
      .object({
        id: yup.string(),
        name: yup.string(),
        value: yup.string(),
      })
      .strict()
      .required('Please choose a packaging'),
    isRequired: true,
  },
  [LOSS_INPUTS.PRICE]: {
    type: 'currency',
    name: LOSS_INPUTS.PRICE,
    defaultValue: '-',
    width: INPUT_WIDTH.SMALL,
    computeValue: (currentRow) => {
      const price = currentRow.priceByPackagingId[get(currentRow, 'packaging.id')];

      return <DisplayNumber number={price} displayCurrencyCode />;
    },
  },

  [LOSS_INPUTS.UNIT_VALUE]: {
    type: 'currency',
    name: LOSS_INPUTS.UNIT_VALUE,
    defaultValue: '-',
    width: INPUT_WIDTH.SMALL,
    computeValue: (currentRow) =>
      `${currentRow.quantity} ${currentRow.unit === ENTITY_UNITS.UNIT ? 'u.' : currentRow.unit}`,
  },
  [LOSS_INPUTS.TOTAL]: {
    type: 'currency',
    name: LOSS_INPUTS.TOTAL,
    defaultValue: '-',
    width: INPUT_WIDTH.SMALL,
    computeValue: (currentRow) => {
      let total;

      if (currentRow.price) {
        total = currentRow.priceByPackagingId[get(currentRow, 'packaging.id')] * currentRow.losses;
      }
      if (currentRow.cost) {
        total = currentRow.cost * currentRow.losses;
      }

      return <DisplayNumber number={total || null} displayCurrencyCode />;
    },
  },
};

/**
 * Called through the resolveInputs prop of TableForm
 * The different dropdowns' values can't be defined in advanced and must be _resolved_ at render, in order to have proper enabled/disabled values.
 * In our use case, a given loss is uniquely defined by either:
 * - a [SupplierProduct|Packaging|LossCategory] triplet
 * - a [Product|Channel|LossCategory] triplet
 *
 * I.E: if a [SP|packagingA] is entered with a loss category 'Breakage', that value has to be disabled in the dropdown values in other instances [SP|PackagingA].
 *
 * @param {*} currentRow Should be the result of form.getValues(`${fieldArrayName}[${index}]`) - The current row's form state
 * @param {Input[]} formInputs The form's input definition, see FORM_INPUTS
 * @param {LOSS_TYPE} lossType Either 'supplier_product' or 'product'
 * @param {*} formRows The form's current rows.
 * @returns An array of correctly defined inputs, with the values of Dropdown inputs appropriately disabled.
 */
export const resolveLossRowInputs = (
  currentRow,
  formInputs,
  lossType,
  formRows = [],
  clientHasMultipleChannels,
) => {
  const matchingFieldsOnCurrentEntity = formRows.filter((formRow) => {
    switch (lossType) {
      case LOSS_TYPE.PRODUCT:
        return currentRow.__Pid === formRow.__Pid;

      case LOSS_TYPE.SUPPLIER_PRODUCT:
        return currentRow.__SPid === formRow.__SPid;

      case LOSS_TYPE.RECIPE:
        return currentRow.__Rid === formRow.__Rid;

      default:
        return;
    }
  });

  const isChannelDisabled = (channel) => {
    const matchingFieldsOnCurrentChannel = matchingFieldsOnCurrentEntity.filter(
      (item) => item.channel && item.channel.id === channel.id,
    );

    if (
      lossType === LOSS_TYPE.PRODUCT &&
      currentRow.channel &&
      currentRow.costByChannelId[channel.id] == null
    ) {
      return true;
    }

    return matchingFieldsOnCurrentChannel.length >= currentRow.lossCategoryValues.length;
  };

  const isPackagingDisabled = (packaging) => {
    const matchingFieldsOnCurrentPackaging = matchingFieldsOnCurrentEntity.filter(
      (item) => item.packaging && item.packaging.id === packaging.id,
    );

    return matchingFieldsOnCurrentPackaging.length >= currentRow.lossCategoryValues.length;
  };

  const isCategoryDisabled = ({ channel, packaging, category: selectedCategory }, category) => {
    // Allow to unselect selected category for the current row
    if (selectedCategory && selectedCategory.id === category.id) {
      return false;
    }

    if (
      lossType === LOSS_TYPE.RECIPE &&
      matchingFieldsOnCurrentEntity.some((item) => get(item.category, 'id', null) === category.id)
    ) {
      return true;
    }

    const matchingFieldsOnCurrentPackagingOrChannel = matchingFieldsOnCurrentEntity.filter(
      (item) => {
        if (lossType === LOSS_TYPE.SUPPLIER_PRODUCT) {
          return item.packaging && packaging && item.packaging.id === packaging.id;
        }
        return item.channel && channel && item.channel.id === channel.id;
      },
    );

    return matchingFieldsOnCurrentPackagingOrChannel.some(
      (item) => get(item.category, 'id', null) === category.id,
    );
  };

  const getSelectInputItems = {
    [LOSS_INPUTS.CHANNEL]: (formRow) =>
      formRow.channelValues.map((channelValue) => ({
        ...channelValue,
        isDisabled: isChannelDisabled(channelValue),
      })),
    [LOSS_INPUTS.CATEGORY]: (formRow) =>
      formRow.lossCategoryValues.map((lossCategory) => ({
        ...lossCategory,
        isDisabled: isCategoryDisabled(currentRow, lossCategory),
      })),
    [LOSS_INPUTS.PACKAGING]: (formRow) =>
      formRow.packagings.map((packaging) => ({
        ...packaging,
        isDisabled: isPackagingDisabled(packaging),
      })),
  };

  return formInputs.map((formInput) => {
    let formInputClone = cloneDeep(formInput);

    if (formInputClone.type === 'single-select') {
      const resolvedItems = getSelectInputItems[formInputClone.name](currentRow);

      if (
        lossType === LOSS_TYPE.PRODUCT &&
        LOSS_INPUTS.CHANNEL === formInputClone.name &&
        !clientHasMultipleChannels
      ) {
        formInputClone = { ...formInputClone, isDisabled: () => true };
      }

      if (
        [LOSS_INPUTS.CHANNEL, LOSS_INPUTS.PACKAGING, LOSS_INPUTS.CATEGORY].includes(
          formInputClone.name,
        )
      ) {
        return {
          ...formInputClone,
          items: resolvedItems,
          defaultValue: resolvedItems.find((item) => !item.isDisabled),
        };
      }

      return {
        ...formInputClone,
        items: resolvedItems,
      };
    }

    return { ...formInputClone };
  });
};
