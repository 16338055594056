import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React, { useState } from 'react';

import { closeGenericModal, openGenericModal, refreshGenericModal } from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button } from '@commons/utils/styledLibraryComponents';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import WhiteCard from '@commons/WhiteCard';

import {
  getNewClientExcelTemplate,
  importNewClientExcelTemplate,
  validateNewClientExcelTemplate,
} from '@services/backoffice/onBoarding';

import { downloadFile, formatXlsFileToJson } from '@backoffice/utils';
import ImportExcelFileModal from '@backoffice/components/ImportExcelFileModal';

import { Container } from './styledComponents';

const defaultModalConfig = {
  width: '542px',
  height: 'auto',
  component: ImportExcelFileModal,
};

export const BackOfficeCreateClient = (props) => {
  const {
    user,
    match,
    closeGenericModal,
    refreshGenericModal,
    showSuccessMessage,
    showErrorMessage,
    pageLoaded,
    pageLoading,
    openGenericModal,
  } = props;

  const [selectedFile, setSelectedFile] = useState(null);
  const [isDownloadingExcel, setIsDownloadingExcel] = useState(false);

  const path = get(match, 'path');

  const formatJson = (dataModel) => {
    const channelSheet = dataModel['channels'];
    const currencySheet = dataModel['currencies'];

    return Object.keys(dataModel).reduce((result, sheetName) => {
      if (sheetName === 'currencies') {
        result[sheetName] = dataModel[sheetName].map((currency) => ({
          id: currency.id,
          currency: currency.devise,
        }));
      }
      if (sheetName === 'client') {
        const data = dataModel[sheetName][0];
        // data represents the data of the first line of the "client" sheet

        const matchingChannel = channelSheet.find(
          (channel) => channel['nom du channel'] === data['channel par défaut'],
        );

        const matchingCurrency = currencySheet.find(
          (currency) => currency['devise'] === data['devise'],
        );

        result[sheetName] = {
          name: data['nom'],
          useCase: data["cas d'usage"],
          defaultChannelId: matchingChannel && matchingChannel['id'],
          currencyId: matchingCurrency && matchingCurrency['id'],
          storeName: data['nom des établissements'],
          hasMultipleBrands: data['plusieurs marques'],
          hasMultipleServices: data['plusieurs services'],
          hasMultipleChannels: data['plusieurs channels'],
          areProductsSync: data['produits caisse synchronisés'],
          hasProductionFeature: data['activer le module de production'],
          hasMultipleTimezones: data['plusieurs fuseaux horaires'],
          defaultTimezone: data['fuseau horaire par défaut'],
          useTtc: data['affichage ttc'],
          hasLocalCatalogs: data['activer les référentiels locaux'],
        };
      }

      return result;
    }, {});
  };

  const onFileChange = (file) => {
    setSelectedFile(file);

    refreshGenericModal(getModalModalConfig('validate-file', file));
  };

  const retrieveBlobNewClientExcelTemplate = async () => {
    setIsDownloadingExcel(true);

    try {
      const response = await getNewClientExcelTemplate(get(user, 'clientId'));

      downloadFile(response, 'new-client');
    } catch (error) {
      showErrorMessage(i18next.t('BACKOFFICE.CLIENT.EXCEL_RETRIEVING_ERROR'));
    } finally {
      setIsDownloadingExcel(false);
    }
  };

  const handleFileValidation = async (file) => {
    pageLoading();

    try {
      const dataModel = await formatXlsFileToJson(file);

      const formattedJsonDataModel = formatJson(dataModel);

      const { status, data } = await validateNewClientExcelTemplate(formattedJsonDataModel);

      if (status === 'success') {
        refreshGenericModal(getModalModalConfig('validated-file', file));

        return;
      }

      refreshGenericModal(getModalModalConfig('error-file', file, data));
    } catch (error) {
      closeGenericModal();
      showErrorMessage(i18next.t('BACKOFFICE.CLIENT.EXCEL_VALIDATION_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleFileImport = async (file) => {
    pageLoading();

    try {
      const dataModel = await formatXlsFileToJson(file);

      const formattedJsonDataModel = formatJson(dataModel);

      await importNewClientExcelTemplate(formattedJsonDataModel);

      showSuccessMessage(i18next.t('BACKOFFICE.CLIENT.EXCEL_IMPORT_SUCCESS'));
    } catch (error) {
      if (get(error.response, 'status') === 409) {
        showErrorMessage(
          get(error.response, 'data.message', i18next.t('BACKOFFICE.CLIENT.EXCEL_IMPORT_ERROR')),
        );
      } else {
        showErrorMessage(i18next.t('BACKOFFICE.CLIENT.EXCEL_IMPORT_ERROR'));
      }
    } finally {
      closeGenericModal();
      pageLoaded();
    }
  };

  const getModalModalConfig = (
    type,
    updatedFile = null,
    errorFile = null,
    email = null,
    password = null,
  ) => {
    if (type === 'select-file') {
      return {
        ...defaultModalConfig,
        type: 'action',
        title: i18next.t('BACKOFFICE.CLIENT.MODAL_IMPORT_SELECT_FILE_TITLE'),
        icon: '/images/inpulse/file-upload-black-small.svg',
        data: {
          subTitle: i18next.t('BACKOFFICE.CLIENT.MODAL_IMPORT_SELECT_FILE_CONTENT'),
          selectedFile,
          setSelectedFile: onFileChange,
        },
        actions: [
          {
            key: 0,
            color: 'inpulse-outline',
            label: i18next.t('GENERAL.CANCEL'),
            icon: '/images/inpulse/close-black-small.svg',
            handleClick: setSelectedFile(null),
          },
          {
            key: 1,
            isDisabled: true,
            preventClosing: true,
            color: 'inpulse-default',
            label: i18next.t('GENERAL.IMPORT'),
            icon: '/images/inpulse/file-upload-white-small.svg',
            handleClick: () => false,
          },
        ],
      };
    }

    if (type === 'validate-file') {
      return {
        ...defaultModalConfig,
        type: 'action',
        title: i18next.t('BACKOFFICE.CLIENT.MODAL_IMPORT_SELECT_FILE_TITLE'),
        icon: '/images/inpulse/file-upload-black-small.svg',
        data: {
          subTitle: i18next.t('BACKOFFICE.CLIENT.MODAL_IMPORT_SELECT_FILE_CONTENT'),
          selectedFile: updatedFile,
          setSelectedFile: onFileChange,
        },
        actions: [
          {
            key: 0,
            color: 'inpulse-outline',
            label: i18next.t('GENERAL.CANCEL'),
            icon: '/images/inpulse/close-black-small.svg',
            handleClick: setSelectedFile(null),
          },
          {
            key: 1,
            preventClosing: true,
            color: 'inpulse-default',
            label: i18next.t('GENERAL.IMPORT'),
            icon: '/images/inpulse/file-upload-white-small.svg',
            handleClick: () => handleFileValidation(updatedFile),
          },
        ],
      };
    }

    if (type === 'error-file') {
      return {
        ...defaultModalConfig,
        type: 'error',
        title: i18next.t('BACKOFFICE.CLIENT.MODAL_IMPORT_SELECT_FILE_TITLE_VALIDATION'),
        icon: '/images/inpulse/info-white-small.svg',
        data: {
          subTitle: i18next.t('BACKOFFICE.CLIENT.MODAL_IMPORT_SELECT_FILE_CONTENT_ERROR'),
          validatedFile: false,
          selectedFile: updatedFile,
        },
        actions: [
          {
            key: 0,
            label: 'Fichier erreur',
            color: 'inpulse-default',
            icon: '/images/inpulse/file-download-white-small.svg',
            handleClick: () => downloadFile(errorFile, 'new-client', true),
          },
        ],
      };
    }

    if (type === 'validated-file') {
      return {
        ...defaultModalConfig,
        type: 'success',
        title: i18next.t('BACKOFFICE.CLIENT.MODAL_IMPORT_SELECT_FILE_TITLE_VALIDATION'),
        icon: '/images/inpulse/check-white-small.svg',
        data: {
          validatedFile: true,
          selectedFile: updatedFile,
        },
        actions: [
          {
            key: 0,
            preventClosing: true,
            color: 'inpulse-default',
            label: i18next.t('GENERAL.IMPORT'),
            icon: '/images/inpulse/check-white-small.svg',
            handleClick: () => handleFileImport(updatedFile),
          },
        ],
      };
    }

    return defaultModalConfig;
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <WhiteCard
        content={i18next.t('BACKOFFICE.CLIENT.GENERATE_TEMPLATE_SUBTITLE')}
        renderContent={
          <Button
            animation={{ rotate: isDownloadingExcel }}
            buttonCustomStyle={{ width: 'fit-content' }}
            color={'inpulse-default'}
            handleClick={() => {
              if (isDownloadingExcel) {
                return;
              }

              retrieveBlobNewClientExcelTemplate();
            }}
            icon={
              isDownloadingExcel
                ? '/images/inpulse/loader-white-small.svg'
                : '/images/inpulse/file-download-white-small.svg'
            }
            isDisabled={isDownloadingExcel}
            label={i18next.t('GENERAL.DOWNLOAD')}
          />
        }
        title={i18next.t('BACKOFFICE.CLIENT.GENERATE_TEMPLATE_TITLE')}
      />
      <WhiteCard
        content={i18next.t('BACKOFFICE.CLIENT.IMPORT_TEMPLATE_SUBTITLE')}
        renderContent={
          <Button
            buttonCustomStyle={{ width: 'fit-content' }}
            color={'inpulse-default'}
            handleClick={() => openGenericModal(getModalModalConfig('select-file', null))}
            icon={'/images/inpulse/file-upload-white-small.svg'}
            label={i18next.t('GENERAL.IMPORT')}
          />
        }
        title={i18next.t('BACKOFFICE.CLIENT.IMPORT_TEMPLATE_TITLE')}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeCreateClient);
