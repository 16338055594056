import { groupBy } from 'lodash';
import i18next from 'i18next';

import utilsXLS from '@commons/utils/makeXLS';

const aggregateIngredients = (ingredients, clientHasMultipleChannels) => {
  const groupedIngredientsByRecipeId = groupBy(
    ingredients,
    ({ componentId, recipeId }) => `${componentId}-${recipeId}`,
  );

  const aggregatedIngredients = Object.values(groupedIngredientsByRecipeId).map((ingredients) => {
    if (ingredients.length === 1) {
      return ingredients[0];
    }

    const {
      componentIsUsedOnSite,
      componentOnSiteQuantity,
      componentOnSiteCost,
      componentIsUsedOnDelivery,
      componentDeliveryQuantity,
      componentDeliveryCost,
    } = ingredients.reduce(
      (
        acc,
        {
          componentIsUsedOnSite,
          componentOnSiteCost,
          componentIsUsedOnDelivery,
          componentDeliveryCost,
          componentQuantity,
        },
      ) => {
        if (componentIsUsedOnSite) {
          acc.componentIsUsedOnSite = true;
          acc.componentOnSiteQuantity += componentQuantity;
          acc.componentOnSiteCost += componentOnSiteCost;
        }

        if (componentIsUsedOnDelivery) {
          acc.componentIsUsedOnDelivery = true;
          acc.componentDeliveryQuantity += componentQuantity;
          acc.componentDeliveryCost += componentDeliveryCost;
        }

        return acc;
      },
      {
        componentIsUsedOnSite: false,
        componentOnSiteQuantity: 0,
        componentOnSiteCost: 0,
        componentIsUsedOnDelivery: false,
        componentDeliveryQuantity: 0,
        componentDeliveryCost: 0,
      },
    );

    return {
      ...ingredients[0],
      componentIsUsedOnSite,
      componentOnSiteQuantity,
      componentOnSiteCost,
      componentIsUsedOnDelivery,
      componentDeliveryQuantity,
      componentDeliveryCost,
    };
  });

  const formattedIngredients = aggregatedIngredients.reduce((acc, ingredient) => {
    if (ingredient.isIngredient) {
      if (clientHasMultipleChannels) {
        ingredient.ingredientOnSiteQuantity = ingredient.componentIsUsedOnSite
          ? ingredient.componentOnSiteQuantity || ingredient.componentQuantity
          : 0;

        ingredient.componentOnSiteCost = ingredient.componentIsUsedOnSite
          ? ingredient.componentOnSiteCost
          : 0;

        ingredient.ingredientOnDeliveryQuantity = ingredient.componentIsUsedOnDelivery
          ? ingredient.componentDeliveryQuantity || ingredient.componentQuantity
          : 0;

        ingredient.componentDeliveryCost = ingredient.componentIsUsedOnDelivery
          ? ingredient.componentDeliveryCost
          : 0;
      }

      acc.push(ingredient);
    }

    return acc;
  }, []);

  return formattedIngredients;
};

const getRecipeCommonColumns = (isMainSheet) => [
  {
    name: i18next.t('ADMIN.RECIPES.EXPORT_COLUMN_NAME'),
    propertyKey: 'name',
  },
  {
    name: i18next.t('GENERAL.STATUS'),
    propertyKey: 'status',
    transform: (value) => i18next.t(`${value ? 'GENERAL.ACTIVE' : 'GENERAL.INACTIVE'}`),
  },
  {
    name: i18next.t(
      isMainSheet
        ? 'ADMIN.RECIPES.EXPORT_COLUMN_CATEGORY'
        : 'ADMIN.RECIPES.COMPOSITION_EXPORT_RECIPE_CATEGORY_EXPORT',
    ),
    propertyKey: 'category',
  },
  {
    name: i18next.t('ADMIN.RECIPES.EXPORT_COLUMN_ASSOCIATED_PRODUCT'),
    propertyKey: 'associatedProductName',
  },
  {
    name: i18next.t(
      isMainSheet
        ? 'ADMIN.RECIPES.EXPORT_COLUMN_QUANTITY'
        : 'ADMIN.RECIPES.COMPOSITION_EXPORT_RECIPE_QUANTITY_EXPORT',
    ),
    propertyKey: 'quantity',
  },
  {
    name: i18next.t(
      isMainSheet
        ? 'ADMIN.RECIPES.EXPORT_COLUMN_UNIT'
        : 'ADMIN.RECIPES.COMPOSITION_EXPORT_RECIPE_UNIT_EXPORT',
    ),
    propertyKey: 'unit',
  },
];

const getMainSheetColumns = (clientHasMultipleChannels, clientHasMultipleBrands) => {
  const columns = [
    ...getRecipeCommonColumns(true),
    {
      name: i18next.t('ADMIN.INGREDIENTS.LABEL_ALLERGENS'),
      propertyKey: 'allergensNames',
      transform: (items) => items.join(','),
    },
  ];

  if (clientHasMultipleBrands) {
    columns.splice(2, 0, {
      name: i18next.t('GENERAL.BRAND_PLURAL'),
      propertyKey: 'brands',
      transform: (values) => {
        const brandNames = values.map(({ name }) => name);

        return brandNames.join(',');
      },
    });
  }

  const startIndexOfRecipeCost = clientHasMultipleBrands ? 7 : 6;

  if (clientHasMultipleChannels) {
    columns.splice(
      startIndexOfRecipeCost,
      0,
      {
        name: i18next.t('ADMIN.RECIPES.COST_EX_TAX_ON_SITE'),
        propertyKey: 'onSiteCost',
        type: 'currency',
      },
      {
        name: i18next.t('ADMIN.RECIPES.COST_EX_TAX_DELIVERY'),
        propertyKey: 'deliveryCost',
        type: 'currency',
      },
    );

    return columns;
  }

  columns.splice(startIndexOfRecipeCost, 0, {
    name: i18next.t('GENERAL.COST_EX_TAX'),
    propertyKey: 'defaultCost',
    type: 'currency',
  });

  return columns;
};

const getComponentsSheetColumns = (clientHasMultipleChannels, clientHasMultipleBrands) => {
  const columns = [
    ...getRecipeCommonColumns(false),
    {
      name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_NAME_EXPORT'),
      propertyKey: 'componentName',
    },
    {
      name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_TYPE'),
      propertyKey: 'componentType',
    },
    {
      name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_CATEGORY_EXPORT'),
      propertyKey: 'componentCategory',
    },
    {
      name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_QUANTITY_EXPORT'),
      propertyKey: 'componentQuantity',
    },
    {
      name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_UNIT_EXPORT'),
      propertyKey: 'componentUnit',
    },
    {
      name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_ALLERGENS'),
      propertyKey: 'componentAllergensNames',
      transform: (items) => items.join(','),
    },
  ];

  if (clientHasMultipleBrands) {
    columns.splice(2, 0, {
      name: i18next.t('GENERAL.BRAND_PLURAL'),
      propertyKey: 'brands',
      transform: (values) => {
        const brandNames = values.map(({ name }) => name);

        return brandNames.join(',');
      },
    });
  }

  const startIndexOfRecipeCost = clientHasMultipleBrands ? 7 : 6;

  if (clientHasMultipleChannels) {
    columns.splice(
      startIndexOfRecipeCost,
      0,
      {
        name: i18next.t('ADMIN.RECIPES.COST_EX_TAX_ON_SITE'),
        propertyKey: 'onSiteCost',
        type: 'currency',
      },
      {
        name: i18next.t('ADMIN.RECIPES.COST_EX_TAX_DELIVERY'),
        propertyKey: 'deliveryCost',
        type: 'currency',
      },
    );

    columns.splice(
      clientHasMultipleBrands ? 14 : 13,
      0,
      {
        name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_USED_ON_SITE'),
        propertyKey: 'componentIsUsedOnSite',
        transform: (value) => i18next.t(`${value ? 'GENERAL.YES' : 'GENERAL.NO'}`).toUpperCase(),
      },
      {
        name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_USED_FOR_DELIVERY'),
        propertyKey: 'componentIsUsedOnDelivery',
        transform: (value) => i18next.t(`${value ? 'GENERAL.YES' : 'GENERAL.NO'}`).toUpperCase(),
      },
      {
        name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_COST_EX_TAX_ON_SITE'),
        propertyKey: 'componentOnSiteCost',
        type: 'currency',
      },
      {
        name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_COST_EX_TAX_DELIVERY'),
        propertyKey: 'componentDeliveryCost',
        type: 'currency',
      },
    );

    return columns;
  }

  // If client does not have multiple channels
  columns.splice(startIndexOfRecipeCost, 0, {
    name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_RECIPE_COST_EXPORT'),
    propertyKey: 'defaultCost',
    type: 'currency',
  });

  columns.splice(clientHasMultipleBrands ? 13 : 12, 0, {
    name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_COST_EXPORT'),
    propertyKey: 'componentCost',
    type: 'currency',
  });

  return columns;
};

const getIngredientsSheetColumns = (clientHasMultipleChannels, clientHasMultipleBrands) => {
  const columns = [
    ...getRecipeCommonColumns(false),
    {
      name: i18next.t('GENERAL.INGREDIENT'),
      propertyKey: 'componentName',
    },
    {
      name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_CATEGORY_EXPORT'),
      propertyKey: 'componentCategory',
    },
    {
      name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_UNIT_EXPORT'),
      propertyKey: 'componentUnit',
    },
    {
      name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_ALLERGENS'),
      propertyKey: 'componentAllergensNames',
      transform: (items) => items.join(','),
    },
  ];

  if (clientHasMultipleChannels) {
    columns.splice(
      6,
      0,
      {
        name: i18next.t('ADMIN.RECIPES.COST_EX_TAX_ON_SITE'),
        propertyKey: 'onSiteCost',
        type: 'currency',
      },
      {
        name: i18next.t('ADMIN.RECIPES.COST_EX_TAX_DELIVERY'),
        propertyKey: 'deliveryCost',
        type: 'currency',
      },
    );

    columns.splice(
      10,
      0,
      {
        name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_QUANTITY_ON_SITE_EXPORT'),
        propertyKey: 'ingredientOnSiteQuantity',
      },
      {
        name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_QUANTITY_ON_DELIVERY_EXPORT'),
        propertyKey: 'ingredientOnDeliveryQuantity',
      },
    );

    columns.splice(
      13,
      0,
      {
        name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_COST_EX_TAX_ON_SITE'),
        propertyKey: 'componentOnSiteCost',
        type: 'currency',
      },
      {
        name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_COST_EX_TAX_DELIVERY'),
        propertyKey: 'componentDeliveryCost',
        type: 'currency',
      },
    );
  }

  if (!clientHasMultipleChannels) {
    columns.splice(6, 0, {
      name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_RECIPE_COST_EXPORT'),
      propertyKey: 'defaultCost',
      type: 'currency',
    });

    columns.splice(10, 0, {
      name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_QUANTITY_EXPORT'),
      propertyKey: 'componentQuantity',
    });

    columns.splice(12, 0, {
      name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_COST_EXPORT'),
      propertyKey: 'componentCost',
      type: 'currency',
    });
  }

  if (clientHasMultipleBrands) {
    columns.splice(2, 0, {
      name: i18next.t('GENERAL.BRAND_PLURAL'),
      propertyKey: 'brands',
      transform: (values) => {
        const brandNames = values.map(({ name }) => name);

        return brandNames.join(',');
      },
    });
  }

  return columns;
};

/**
 * Handle the export of the analytics reference data into a XLS file
 *
 * @param {Array} detailedRecipesList - The list of recipes to export in the XLS file
 *
 * @returns {void}
 */
export function exportRecipes(
  detailedRecipesList,
  detailedRecipesWithComponents,
  currency,
  clientHasMultipleChannels,
  clientHasMultipleBrands,
  isInCentralMode = false,
) {
  if (!detailedRecipesList) {
    return;
  }

  const mainSheet = utilsXLS.generateDefaultSheet(
    i18next.t('GENERAL.RECIPE_PLURAL'),
    getMainSheetColumns(clientHasMultipleChannels, clientHasMultipleBrands),
    detailedRecipesList,
    currency,
  );

  const componentsSheet = utilsXLS.generateDefaultSheet(
    i18next.t('ADMIN.RECIPES.COMPONENTS'),
    getComponentsSheetColumns(clientHasMultipleChannels, clientHasMultipleBrands),
    detailedRecipesWithComponents.filter(({ isPreparationIngredient }) => !isPreparationIngredient),
    currency,
  );

  const detailedRecipesWithIngredients = aggregateIngredients(
    detailedRecipesWithComponents,
    clientHasMultipleChannels,
  );

  const ingredientsSheet = utilsXLS.generateDefaultSheet(
    i18next.t('GENERAL.INGREDIENT_PLURAL'),
    getIngredientsSheetColumns(clientHasMultipleChannels, clientHasMultipleBrands),
    detailedRecipesWithIngredients,
    currency,
  );

  utilsXLS.makeXLS(
    i18next.t(
      isInCentralMode
        ? 'ADMIN.RECIPES.EXPORT_FILENAME_CENTRAL_KITCHEN'
        : 'ADMIN.RECIPES.EXPORT_FILENAME',
    ),
    [mainSheet, componentsSheet, ingredientsSheet],
  );
}
