import styled, { keyframes } from 'styled-components';

const gradient = keyframes`
  0% { opacity: 0.4; }
  50% { opacity: 1; }
  100% { opacity: 0.4; }
`;

export const Container = styled.div`
  height: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

export const LoadingInfoPanelRow = styled.div`
  height: 40px;
  display: flex;
  padding-top: 24px;
  align-items: center;
`;

export const LoadingRow = styled.div`
  height: 100%;
  width: ${(props) => props.width || '100%'};

  border-radius: ${(props) => props.theme.borders.radius.weak};
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.colors.greys.lightest} 0%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: ${gradient} 1.5s linear infinite;
  margin: ${(props) => props.showMargin && '24px 0 0 0'};
`;

export const LoadingContent = styled.div`
  height: 40px;
`;
