import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 300px;

  justify-content: flex-start;
  align-items: center;
`;

export const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 40px;

  padding: 0 16px;
  margin: 4px 0;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme?.colors?.greys?.light};

  &:nth-child(1) {
    margin: 0 0 4px;
  }

  &:nth-last-child(1) {
    margin: 4px 0 0;
  }

  &:hover {
    border: 1px solid
      ${(props) =>
        !!props.isDisabled
          ? props.theme?.colors?.greys?.light
          : props.theme?.colors?.greys?.darkest};
  }

  ${(props) =>
    props.orange &&
    css`
      border: 1px solid ${(props) => props.theme?.colors?.infoOrange};

      &:hover {
        border: 1px solid ${(props) => props.theme?.colors?.infoOrange};
      }
    `}

  ${(props) =>
    props.red &&
    css`
      border: 1px solid ${(props) => props.theme?.colors?.infoRed};

      &:hover {
        border: 1px solid ${(props) => props.theme?.colors?.infoRed};
      }
    `}
`;

export const CreateItemContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  margin-bottom: 12px;

  &:hover {
    cursor: pointer;
  }
`;

export const Item = styled.div`
  &:first-child {
    flex: 2;
  }

  &:not(:first-child) {
    flex: 1;
  }
`;

export const ContainerRadioButton = styled.div`
  display: flex;
  align-items: center;

  > div:first-child {
    margin-left: -6px;
    margin-right: 2px;
  }
`;

export const Text = styled.div`
  text-align: left;

  max-width: 224px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${(props) =>
    !props.isDisabled ? props.theme?.colors?.greys?.darkest : props.theme?.colors?.greys?.dark};

  font: ${(props) => props.theme.fonts.textMiddleBold};

  ${(props) =>
    props.onMouseOver &&
    css`
      &:hover {
        color: ${(props) =>
          !props.isDisabled ? props.theme.colors.green.lighter : props.theme.colors.greys.dark};
      }
    `}

  ${(props) =>
    props.orange &&
    css`
      color: ${props.theme?.colors?.infoOrange};
    `}

  ${(props) =>
    props.bold &&
    css`
      font: ${(props) => props.theme?.fonts?.textMiddleBold};
    `}
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Icon = styled.img`
  ${(props) =>
    !props.keepIconsSize &&
    css`
      width: 12px;
      height: 12px;
    `}

  margin-left: 24px;

  &:hover {
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  }
`;

export const Input = styled.input`
  padding: 0;

  width: 100%;

  border: none;

  color: ${(props) => props.theme?.colors?.greys?.darkest};

  font: ${(props) => props.theme?.fonts?.textMiddleNormal};
`;
