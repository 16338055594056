import i18next from 'i18next';
import React from 'react';

import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import { Franco } from '@commons/utils/styledLibraryComponents';
import { getTheme } from '@commons/utils/theme';
import DisplayNumber from '@commons/DisplayNumber';

import { LabelContent } from './styledComponent';

const TotalBDLFranco = (props) => {
  const theme = getTheme();

  const { currency, isEditingPreparationOrder, totalBDL, diffBetweenOrderedAndInvoiced } = props;

  const labelContent = isEditingPreparationOrder ? (
    <LabelContent>
      {diffBetweenOrderedAndInvoiced > 0 ? '+ ' : ''}
      <DisplayNumber
        color={ENUM_COLORS.LIGHTEST}
        currency={currency}
        font={ENUM_FONTS.TEXT_COMPONENT_LABEL}
        number={diffBetweenOrderedAndInvoiced || 0}
        displayCurrencyCode
      />
    </LabelContent>
  ) : (
    ''
  );

  return (
    <Franco
      background={
        diffBetweenOrderedAndInvoiced === 0 ? theme.colors.infoGreen : theme.colors.infoOrange
      }
      content={
        <DisplayNumber
          color={
            diffBetweenOrderedAndInvoiced === 0 ? ENUM_COLORS.INFO_GREEN : ENUM_COLORS.INFO_ORANGE
          }
          currency={currency}
          font={ENUM_FONTS.TEXT_MIDDLE_BOLD}
          number={totalBDL || 0}
          displayCurrencyCode
        />
      }
      contentColor={theme.colors.infoOrange}
      labelContent={labelContent}
      title={`${i18next.t('ORDERS.ORDERS.LIST_LABEL_TOTAL_EX_TAX_BDL')} :`}
      width={isEditingPreparationOrder ? '240px' : '160px'}
      hasBorder
    />
  );
};

export default TotalBDLFranco;
