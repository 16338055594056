export const ENUM_ACTIVE_PRODUCT_CASE = {
  TO_PRODUCE: 'to-produce',
  PRODUCED: 'produced',
  LOSS: 'loss',
  STOCK: 'stock',
};

export default {
  ENUM_ACTIVE_PRODUCT_CASE,
};
