import i18next from 'i18next';

export const getListColumns = (hasProductionPlanning) => {
  const nameColumn = {
    propertyKey: 'name',
    label: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_DESIGNATION'),
    displayImage: true,
    flex: 2,
  };

  const toProduceColumn = {
    propertyKey: 'toProduce',
    label: i18next.t('PRODUCTION.PRODUCTION.HEADER_TO_PRODUCE'),
    flex: 2,
  };

  const quantityColumn = {
    propertyKey: 'quantity',
    label: i18next.t('GENERAL.PRODUCT'),
    flex: 3,
  };

  if (hasProductionPlanning) {
    return [nameColumn, toProduceColumn, quantityColumn];
  }
  return [nameColumn, quantityColumn];
};
