import React from 'react';

import SupplierProfileModalAssociations from '@admin/suppliers/suppliers/SupplierProfileModal/SupplierProfileModalAssociations';

import { getColumns } from './columns';

const AssociateSupplierProductsModal = (props) => {
  const { availableSupplierProductAssociations, handleSelectSupplierProductAssociations } = props;

  return (
    <SupplierProfileModalAssociations
      columns={getColumns()}
      data={availableSupplierProductAssociations}
      defaultOrderBy={'name'}
      defaultOrderType={'asc'}
      forceEnableSelection={true}
      setSelectedItems={(items) => handleSelectSupplierProductAssociations(items)}
    />
  );
};

export default AssociateSupplierProductsModal;
