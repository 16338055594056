import { connect } from 'react-redux';
import { get, upperFirst } from 'lodash';
import i18next from 'i18next';
import React, { useState } from 'react';

import { ListView, Button } from '@commons/utils/styledLibraryComponents';
import {
  PATHS_AVAILABLE_IN_ALL_WORKSPACES,
  HELP_CENTER_COMPONENT_NAME,
  REFERRAL_COMPONENT_NAME,
} from '@commons/constants/centralConstants';
import EmptyState from '@commons/EmptyState';

import { getClientInfo } from '@selectors/client';

import {
  Container,
  Header,
  HeaderTitle,
  CloseImage,
  ListViewContainer,
  Footer,
} from './styledComponents';

const getColumns = (hasCentralKitchens) => [
  {
    id: 'right',
    name: i18next.t('GENERAL.RIGHT'),
    propertyKey: 'name',
  },
  {
    id: 'module',
    name: i18next.t('GENERAL.MODULE'),
    propertyKey: 'module',
  },
  {
    id: 'page',
    name: i18next.t('GENERAL.PAGE'),
    propertyKey: 'page',
  },
  {
    id: 'path',
    name: i18next.t('GENERAL.PATH'),
    propertyKey: 'path',
    displayBigger: true,
  },
  {
    id: 'isCentral',
    name: i18next.t('GENERAL.WORKSPACE'),
    propertyKey: 'isCentral',
    baseName: 'isCentral',
    hidden: !hasCentralKitchens,
    render: (isCentral, action) => {
      const featurePathMainModule = get(action.path.split('/'), '[1]', ''); // We take the first element because [0] is equal to '/'

      if (
        PATHS_AVAILABLE_IN_ALL_WORKSPACES.includes(featurePathMainModule) ||
        action.page === HELP_CENTER_COMPONENT_NAME ||
        action.page === REFERRAL_COMPONENT_NAME
      ) {
        return i18next.t('GENERAL.COMMON');
      }

      return isCentral ? i18next.t('GENERAL.CENTRAL') : i18next.t('GENERAL.SALE_POINT');
    },
  },
];

const EditRoleRights = (props) => {
  const {
    params: { role, closeModal, actions, handleSave },
    client: { hasCentralKitchens },
  } = props;
  const [selectedItems, setSelectedItems] = useState([]);

  const [columns] = useState(getColumns(hasCentralKitchens));

  return (
    <Container>
      <Header>
        <HeaderTitle>
          {i18next.t('BACKOFFICE.ROLES.PAGE_EDIT_RIGHTS', {
            role: upperFirst(role.name),
          })}
        </HeaderTitle>
        <CloseImage src="/images/inpulse/close-black-small.svg" onClick={() => closeModal()} />
      </Header>
      <ListViewContainer>
        <ListView
          columns={columns}
          data={actions}
          defaultOrderBy={'path'}
          padding={'24px 24px 0px 24px'}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => <EmptyState />}
          setSelectedItems={(items) => setSelectedItems(items)}
          disableFooter
          disableListOptions
          forceEnableSelection
        />
      </ListViewContainer>
      <Footer>
        <Button
          color={'inpulse-outline'}
          handleClick={() => closeModal()}
          icon="/images/inpulse/close-black-small.svg"
          label={i18next.t('GENERAL.CANCEL')}
        />
        <Button
          buttonCustomStyle={{ marginLeft: 24 }}
          color={'inpulse-default'}
          handleClick={() => handleSave(selectedItems, role.id)}
          icon="/images/inpulse/save-white-small.svg"
          isDisabled={!selectedItems.length}
          label={i18next.t('GENERAL.SAVE')}
        />
      </Footer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

export default connect(mapStateToProps)(EditRoleRights);
