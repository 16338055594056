import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Box = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 15px;

  color: ${(props) => props.theme.colors?.greys.darkest};
  font: ${(props) => props.theme.fonts?.kpiBig};

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.colors?.greys.lightest};
  border: ${(props) => `1px solid ${props.theme.colors.greys.light}`};
  border-radius: ${(props) => props.theme.borders?.radius.weak};

  &:last-child {
    margin-right: 0px;
  }

  &.clickable:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.hidden &&
    css`
      background: inherit;
      box-shadow: none;
      border: none;

      img {
        width: 0px !important;
      }
    `}

  img {
    width: 20px;
  }
`;
