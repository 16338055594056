import i18next from 'i18next';

import { STEP_REGISTRATION } from './constants';

export const getExpiredTokenSettings = (settings) => {
  const { handleResendMail, currentStep } = settings;

  if (currentStep === STEP_REGISTRATION.TOKEN_EXPIRED_STEP_1) {
    const title = i18next.t('AUTHENTICATION.REGISTRATION.LANDING_TOKEN_EXPIRED_TITLE');

    const sendEmailButtonParams = {
      color: 'inpulse-default',
      label: i18next.t('AUTHENTICATION.REGISTRATION.LANDING_TOKEN_RESEND_LINK'),
      icon: '/images/inpulse/send-white-small.svg',
      handleClick: () => handleResendMail(),
    };

    const actions = [sendEmailButtonParams];

    const params = {
      currentStep,
    };

    return {
      title,
      actions,
      params,
    };
  }

  if (currentStep === STEP_REGISTRATION.TOKEN_EXPIRED_STEP_2) {
    const title = i18next.t('AUTHENTICATION.REGISTRATION.LANDING_TOKEN_RESENT_TITLE');

    const actions = [];

    const params = {
      currentStep,
    };

    return {
      title,
      actions,
      params,
    };
  }
};

export default getExpiredTokenSettings;
