import { connect } from 'react-redux';
import { first, get, set } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useRef, useState } from 'react';

import { getCurrencyById, requestCurrency, requestCurrencyError } from '@actions/currency';
import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';
import { receiveUser as updateUserInStore } from '@actions/user';

import {
  Button,
  Dropdown,
  INPUT_WIDTH,
  ToggleSwitch,
} from '@commons/utils/styledLibraryComponents';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { GenericModalContainer } from '@commons/Modals/GenericModal/styledComponents';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import GenericModal from '@commons/Modals/GenericModal';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import Text, { ENUM_FONTS } from '@commons/Text';
import TimeZonesDropdown from '@commons/TimezonesDropdown';
import WhiteCard from '@commons/WhiteCard';

import useLocalStorage from '@hooks/useLocalStorage';

import { Input } from '@lib/inpulse/Input';

import { getClientInfo } from '@selectors/client';

import inpulseAccountService from '@services/backoffice/inpulseAccounts';

import { currencyService } from '@services/backoffice/currency';

import { upload as uploadService } from '@services/upload';
import clientService from '@services/client';

import MultiplePictureUpload from '@orders/OrderList/components/OrderForm/components/OrderFormInfoPanel/components/MultiplePictureUpload';

import {
  CLIENT_STATUS,
  CLIENT_STATUS_DROPDOWN,
  CLIENT_STORE_NAME_DROPDOWN,
} from './utils/constants';
import {
  Container,
  Content,
  ContentContainer,
  DefaultTimezoneContainer,
  ErrorText,
  InformationContainer,
  InputContainer,
  PropertyContainer,
  SwitchContainer,
} from './styledComponents';
import { getDefaultTimezoneWarningModal } from './utils/modalConfiguration';

export const BackOfficeGeneral = (props) => {
  const {
    match,
    user,
    client: {
      clientId,
      clientName,
      clientPicture,
      storeName,
      clientStatus,
      hasMultipleBrands,
      hasMultipleTimezones,
      hasMultipleCurrencies,
      hasCentralKitchens,
      defaultTimezone,
      hasLocalCatalogs,
      // hasMultipleChannels, Uncomment once data team is ready to handle this switch
    },
    showErrorMessage,
    showSuccessMessage,
    openGenericModal,
    pageLoading,
    updateUserInStore,
    getCurrencyById,
    pageLoaded,
    currency,
  } = props;

  const path = get(match, 'path');
  const translatedStoreNames = getClientStoreNameTranslation(storeName, true).toLowerCase();

  const [initialPictures, setInitialPictures] = useState([]);
  const [displayDropdownActions, setDisplayDropdownActions] = useState(false);

  const selectedPictureRef = useRef();

  const [initialStatus, setInitialStatus] = useState('');
  const [initialStoreName, setInitialStoreName] = useState('');

  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');

  const [enableWarningModal, setEnableWarningModal] = useState(false);
  const [warningModalParams, setWarningModalParams] = useState(null);
  const [newDefaultTimezone, setNewDefaultTimezone] = useState(null);

  // Inpulse Accounts states
  const [isLoadingInpulseAccounts, setIsLoadingInpulseAccounts] = useState(true);
  const [inpulseAccounts, setInpulseAccounts] = useState([]);
  const [selectedInpulseAccount, setSelectedInpulseAccount] = useState(null);

  const [updatedClientName, setUpdatedClientName] = useState(clientName);
  const [clientNameErrorValue, setClientNameErrorValue] = useState(null);
  const [isErrorState, setIsErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const [storeIsCentralMode, setStoreIsCentralMode] = useLocalStorage('isCentralMode', false);

  useEffect(() => {
    if (clientPicture) {
      setInitialPictures([clientPicture]);
    }

    setInitialStoreName(storeName);

    if (clientStatus) {
      setInitialStatus(clientStatus);
    }

    getCurrencies();

    getInpulseAccounts();
  }, [user, currency]);

  // Handle selected CSM manager update
  useEffect(() => {
    if (isLoadingInpulseAccounts) {
      return;
    }

    updateCsmManager();
  }, [selectedInpulseAccount]);

  useEffect(() => {
    setWarningModalParams(
      getDefaultTimezoneWarningModal(
        translatedStoreNames,
        newDefaultTimezone,
        setNewDefaultTimezone,
        onInformationsChange,
      ),
    );
  }, [newDefaultTimezone]);

  useEffect(() => {
    setIsSaveDisabled(updatedClientName === clientName);
  }, [updatedClientName, clientName]);

  useEffect(() => {
    if (clientNameErrorValue) {
      setIsErrorState(updatedClientName === clientNameErrorValue);
      setIsSaveDisabled(updatedClientName === clientNameErrorValue);
    }
  }, [clientNameErrorValue, updatedClientName]);

  const handleUpdateClientName = async () => {
    pageLoading();
    try {
      await clientService.updateClientName(clientId, updatedClientName);

      const updatedUserInfo = set(
        { ...user },
        `lnkClientAccountrel.name`,
        updatedClientName.trim(),
      );

      updateUserInStore(updatedUserInfo);

      setIsSaveDisabled(true);

      showSuccessMessage(i18next.t('BACKOFFICE.GENERAL.UPDATE_CLIENT_NAME_SUCCESS'));
    } catch (err) {
      if (err.response.status === 409) {
        setClientNameErrorValue(updatedClientName);
        setIsErrorState(true);
        setErrorMessage(i18next.t('BACKOFFICE.GENERAL.CLIENT_NAME_ALREADY_EXISTS'));
        return;
      }

      if (err.response.status === 422) {
        setClientNameErrorValue(updatedClientName);
        setIsErrorState(true);
        setErrorMessage(i18next.t('BACKOFFICE.GENERAL.INVALID_NAME'));
        return;
      }

      showErrorMessage(i18next.t('BACKOFFICE.GENERAL.UPDATE_CLIENT_NAME_ERROR'));
    } finally {
      pageLoaded();
    }
    return;
  };

  const getCurrentInpulseAccount = async () => {
    try {
      const inpulseAccount = await inpulseAccountService.getCsmManagerByClientId(clientId);

      if (inpulseAccount) {
        const formattedInpulseAccount = {
          ...inpulseAccount,
          name: `${inpulseAccount.firstName} ${inpulseAccount.lastName}`,
        };

        setSelectedInpulseAccount(formattedInpulseAccount);

        return;
      }

      setSelectedInpulseAccount(null);
    } catch {
      showErrorMessage(i18next.t('BACKOFFICE.GENERAL.CSM_MANAGER_FETCH_ERROR'));

      setSelectedInpulseAccount(null);
    }
  };

  const updateCsmManager = async () => {
    if (!clientId) {
      return;
    }

    const inpulseAccountId = selectedInpulseAccount ? selectedInpulseAccount.id : null;

    try {
      await inpulseAccountService.upsertCsmManagerByClientId(clientId, inpulseAccountId);

      showSuccessMessage(i18next.t('BACKOFFICE.GENERAL.CSM_MANAGER_UPDATE_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('BACKOFFICE.GENERAL.CSM_MANAGER_UPDATE_ERROR'));
    }
  };

  const getInpulseAccounts = async () => {
    setIsLoadingInpulseAccounts(true);

    try {
      await getCurrentInpulseAccount();

      const result = await inpulseAccountService.getAll();

      const formattedInpulseAccounts = result.map((account) => ({
        ...account,
        name: `${account.firstName} ${account.lastName}`,
      }));

      setInpulseAccounts(formattedInpulseAccounts);
    } catch {
      showErrorMessage(i18next.t('BACKOFFICE.GENERAL.CSM_LIST_FETCH_ERROR'));

      setInpulseAccounts([]);
    } finally {
      setIsLoadingInpulseAccounts(false);
    }
  };

  const getCurrencies = async () => {
    try {
      const allCurrencies = await currencyService.getAllCurrencies();

      const formattedAllCurrencies = allCurrencies.map((currency) => ({
        ...currency,
        name: `${currency.name} (${currency.alphabeticCode})`,
      }));

      setCurrencies(formattedAllCurrencies);

      setSelectedCurrency(currency);
    } catch {
      showErrorMessage(i18next.t('BACKOFFICE.GENERAL.CURRENCIES_FETCH_ERROR'));
    }
  };

  const getUrlFromBase64 = async (base64Img) => {
    if (!base64Img) {
      return null;
    }

    try {
      const result = await uploadService.uploadFile(base64Img, {
        type: 'logo',
        clientId: clientId,
      });

      return get(result, 'data.fileUri');
    } catch {
      showErrorMessage(i18next.t('GENERAL.NEW_UPLOAD_FAILURE'));

      return null;
    }
  };

  const handleClientLogoSave = async (base64Img) => {
    let img = null;

    pageLoading();

    if (base64Img) {
      img = await getUrlFromBase64(base64Img);
    }

    await onInformationsChange(img, 'img');
  };

  const onSelectedPictureChange = async () => {
    if (selectedPictureRef && selectedPictureRef.current) {
      const selectedPictures = selectedPictureRef.current.getSelectedPictures();

      if (!selectedPictures || !selectedPictures.length) {
        await handleClientLogoSave();

        return;
      }

      const picture = first(selectedPictures);

      if (!picture) {
        showErrorMessage(i18next.t('BACKOFFICE.GENERAL.UPDATE_CLIENT_LOGO_FAILURE'));
        return;
      }

      await handleClientLogoSave(picture.file);
    }
  };

  const onInformationsChange = async (selectedValue, key) => {
    pageLoading();

    try {
      await clientService.patchClientById(clientId, { [key]: selectedValue });

      showSuccessMessage(i18next.t('BACKOFFICE.GENERAL.UPDATE_INFORMATIONS_PART_SUCCESS'));

      const updatedUserInfo = set({ ...user }, `lnkClientAccountrel.${key}`, selectedValue);

      updateUserInStore(updatedUserInfo);

      // force refresh of currency in store
      if (key === 'currencyId') {
        getCurrencyById(selectedValue);
      }

      if (key === 'hasCentralKitchens') {
        // Change "isCentralMode" local storage value only when we are in "central mode"
        if (storeIsCentralMode) {
          setStoreIsCentralMode(selectedValue);
        }
      }
    } catch {
      showErrorMessage(i18next.t('BACKOFFICE.GENERAL.UPDATE_INFORMATIONS_PART_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const selectedClientStatus = CLIENT_STATUS_DROPDOWN.find(
    (status) => status.name.toLocaleLowerCase() === initialStatus,
  );

  const selectedClientStoreName = CLIENT_STORE_NAME_DROPDOWN.find(
    (storeName) => storeName.value === initialStoreName,
  );

  const clientStatusListWithItemDisabled = CLIENT_STATUS_DROPDOWN.map((status) => {
    const isDisabled =
      status.value === CLIENT_STATUS.ONBOARDING &&
      initialStatus !== CLIENT_STATUS.ONBOARDING.toLocaleLowerCase();

    return {
      ...status,
      isDisabled,
    };
  });

  const getChangeCurrencyConfirmationModal = (selectedItem) =>
    getConfirmationModal({
      title: i18next.t('BACKOFFICE.GENERAL.MODAL_TITLE_CHANGE_CURRENCY'),
      content: i18next.t('BACKOFFICE.GENERAL.MODAL_CONTENT_CHANGE_CURRENCY', {
        newCurrency: selectedItem.name,
      }),
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: () => {
            onInformationsChange(selectedItem.id, 'currencyId');
            setSelectedCurrency(currency);
          },
        },
      ],
    });

  const getEnablingLocalCatalogsConfirmationModal = (value) =>
    getConfirmationModal({
      title: i18next.t('BACKOFFICE.GENERAL.CONFIRM_ENABLING_LOCAL_CATALOGS_TITLE'),
      content: i18next.t('BACKOFFICE.GENERAL.CONFIRM_ENABLING_LOCAL_CATALOGS_CONTENT'),
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: () => {
            onInformationsChange(value, 'hasLocalCatalogs');
          },
        },
      ],
    });

  const handleChangeCurrencyDropdown = (selectedItem) => {
    openGenericModal(getChangeCurrencyConfirmationModal(selectedItem));
  };

  const handleEnablingLocalCatalog = (value) => {
    openGenericModal(getEnablingLocalCatalogsConfirmationModal(value));
  };

  const handleSwitchMultipleTimezones = async () => {
    if (!hasMultipleTimezones) {
      onInformationsChange(!hasMultipleTimezones, 'hasMultipleTimezones');
      onInformationsChange(null, 'defaultTimezone');

      return;
    }

    if (hasMultipleTimezones) {
      setEnableWarningModal(!enableWarningModal);
      setWarningModalParams(
        getDefaultTimezoneWarningModal(
          translatedStoreNames,
          newDefaultTimezone,
          setNewDefaultTimezone,
          onInformationsChange,
        ),
      );
    }
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <Content>
        <WhiteCard
          renderContent={
            <>
              <InputContainer>
                <Input
                  error={!!errorMessage && isErrorState}
                  label={`* ${i18next.t('BACKOFFICE.GENERAL.CLIENT_NAME')}`}
                  type={'text'}
                  value={updatedClientName}
                  width={INPUT_WIDTH.LARGE}
                  onChange={(event) => setUpdatedClientName(event.target.value)}
                />
                {isErrorState && !!errorMessage && <ErrorText>{errorMessage}</ErrorText>}
              </InputContainer>
              <Button
                color={'inpulse-default'}
                handleClick={handleUpdateClientName}
                icon={'/images/inpulse/save-white-small.svg'}
                isDisabled={isSaveDisabled}
                label={i18next.t('GENERAL.SAVE')}
                maxWidth={parseInt(INPUT_WIDTH.MEDIUM)}
              />
            </>
          }
          title={i18next.t('BACKOFFICE.GENERAL.CLIENT_NAME')}
        />
        <WhiteCard
          renderContent={
            <>
              <InformationContainer>
                <Dropdown
                  height={'64px'}
                  isDisabled={!initialStatus}
                  items={clientStatusListWithItemDisabled}
                  label={`${i18next.t('BACKOFFICE.GENERAL.UPDATE_CLIENT_STATUS_TITLE')} :`}
                  selectedItem={selectedClientStatus}
                  isRequired
                  onSelectionChange={({ name }) => onInformationsChange(name, 'status')}
                />
                <Dropdown
                  height={'64px'}
                  items={CLIENT_STORE_NAME_DROPDOWN}
                  label={`${i18next.t('BACKOFFICE.GENERAL.STORE_NAME')} :`}
                  selectedItem={selectedClientStoreName}
                  isRequired
                  onSelectionChange={({ value }) => onInformationsChange(value, 'storeName')}
                />
                <Dropdown
                  height={'64px'}
                  items={currencies}
                  label={`${i18next.t('BACKOFFICE.GENERAL.CURRENCY')} :`}
                  selectedItem={selectedCurrency}
                  isRequired
                  onSelectionChange={(selectedItem) => {
                    handleChangeCurrencyDropdown(selectedItem);
                  }}
                />
              </InformationContainer>
              <InformationContainer>
                <Dropdown
                  height={'64px'}
                  isDisabled={isLoadingInpulseAccounts || !inpulseAccounts.length}
                  items={inpulseAccounts}
                  label={`${i18next.t('BACKOFFICE.GENERAL.CSM_MANAGER')} :`}
                  selectedItem={selectedInpulseAccount}
                  onSelectionChange={setSelectedInpulseAccount}
                />
                <MultiplePictureUpload
                  displayDropdownActions={displayDropdownActions}
                  initialPictures={initialPictures}
                  multipleFiles={false}
                  ref={selectedPictureRef}
                  setDisplayDropdownActions={setDisplayDropdownActions}
                  onSelectionChange={onSelectedPictureChange}
                />
              </InformationContainer>
            </>
          }
          title={i18next.t('BACKOFFICE.GENERAL.GENERAL_INFORMATION')}
        />
        <WhiteCard
          renderContent={
            <ContentContainer>
              <PropertyContainer>
                <SwitchContainer>
                  <ToggleSwitch
                    checked={hasMultipleBrands}
                    handleClick={() =>
                      onInformationsChange(!hasMultipleBrands, 'hasMultipleBrands')
                    }
                    id="hasMultipleBrands"
                  />
                  <Text font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
                    {i18next.t('BACKOFFICE.GENERAL.HAS_MULTIPLE_BRANDS')}
                  </Text>
                </SwitchContainer>
                <SwitchContainer>
                  <ToggleSwitch
                    checked={hasMultipleTimezones}
                    disabled={true} // TODO: remove when full-timezones support is implemented
                    handleClick={handleSwitchMultipleTimezones}
                    id="hasMultipleTimezones"
                  />
                  <Text font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
                    {i18next.t('BACKOFFICE.GENERAL.UPDATE_MULTIPLE_TIMEZONES')}
                  </Text>
                </SwitchContainer>
                {!hasMultipleTimezones && (
                  <DefaultTimezoneContainer>
                    <TimeZonesDropdown
                      defaultTimezone={defaultTimezone}
                      handleChange={(timezone) => onInformationsChange(timezone, 'defaultTimezone')}
                      isDisabled={hasMultipleTimezones}
                      isRequired
                    />
                  </DefaultTimezoneContainer>
                )}
              </PropertyContainer>
              <PropertyContainer>
                <SwitchContainer>
                  <ToggleSwitch
                    checked={hasMultipleCurrencies}
                    handleClick={() =>
                      onInformationsChange(!hasMultipleCurrencies, 'hasMultipleCurrencies')
                    }
                    id="hasMultipleCurrencies"
                  />
                  <Text font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
                    {i18next.t('BACKOFFICE.GENERAL.HAS_MULTIPLE_CURRENCIES')}
                  </Text>
                </SwitchContainer>
                <SwitchContainer>
                  <ToggleSwitch
                    checked={hasCentralKitchens}
                    disabled={hasCentralKitchens}
                    handleClick={() =>
                      onInformationsChange(!hasCentralKitchens, 'hasCentralKitchens')
                    }
                    id="hasCentralKitchens"
                  />
                  <Text font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
                    {i18next.t('BACKOFFICE.GENERAL.CENTRAL', { count: 2 })}
                  </Text>
                </SwitchContainer>
                <SwitchContainer>
                  <ToggleSwitch
                    checked={hasLocalCatalogs}
                    disabled={hasLocalCatalogs}
                    handleClick={() => handleEnablingLocalCatalog(!hasLocalCatalogs)}
                    id="hasLocalCatalogs"
                  />
                  <Text font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
                    {i18next.t('BACKOFFICE.GENERAL.LOCAL_CATALOGS')}
                  </Text>
                </SwitchContainer>
              </PropertyContainer>
            </ContentContainer>
          }
          title={i18next.t('GENERAL.PROPERTIES')}
        />
        {enableWarningModal && (
          <GenericModalContainer>
            <GenericModal
              closeGenericModal={() => {
                setWarningModalParams(null);
                setEnableWarningModal(false);
              }}
              component={warningModalParams.component}
              params={warningModalParams}
            />
          </GenericModalContainer>
        )}
      </Content>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
  modal: state.modalReducer,
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  updateUserInStore: (updatedUser) => {
    dispatch(updateUserInStore(updatedUser));
  },
  getCurrencyById: (currencyId) =>
    dispatch(getCurrencyById(currencyId)).then(
      (result) => dispatch(requestCurrency(result)),
      (error) => dispatch(requestCurrencyError(error)),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeGeneral);
