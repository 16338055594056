import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;

  background-color: ${(props) => props.theme.colors?.greys?.lighter};
`;

export const WhiteCard = styled.div`
  max-width: 800px;
  width: calc(100% - 48px);

  padding: 24px;

  display: flex;
  flex-direction: column;
  row-gap: 24px;

  background-color: ${(props) => props.theme.colors?.greys?.lightest};

  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;

export const WhiteCardTitle = styled.div`
  font: ${(props) => props.theme.fonts.h2Inter};
`;

export const WhiteCardText = styled.div`
  font: ${(props) => props.theme.fonts.textMiddleNormal};
`;
