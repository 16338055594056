import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;

  font-family: proximanovaregular;
`;

export const FilterContainer = styled.div`
  top: 20px;
  right: ${(props) => (props.customPosition ? props.customPosition : '-135px')};
  position: absolute;
  z-index: 1000000000;

  display: flex;
  flex-direction: column;

  height: 270px;
  width: 270px;

  ${(props) =>
    props.fitContent &&
    css`
      max-height: 270px;
      height: fit-content;
    `}

  font-size: 14px;
  color: #6b768c;

  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  border-radius: 4px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 10%;

  margin: 10px 0 10px 0;
  padding: 0;
`;

export const Title = styled.h1`
  font-family: proximanovasemibold;
  font-size: 14px;
`;

export const Content = styled.div`
  height: 55%;

  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: baseline;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding-left: 5px;
  margin-bottom: 5px;

  p {
    width: 100%;
    font-family: proximanovaregular;
    font-size: 14px;
    color: #6b768c;
    text-align: left;
    margin: 0 0 0 10px;
  }
`;

export const RowLabel = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 5px;
`;

export const ActionContainer = styled.div`
  height: 30px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0;

  box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);
  border-radius: 0 0 4px 4px;

  font-size: 14px;
  color: #0a3eff;

  cursor: pointer;

  &:hover {
    color: #fff;
    background: #0a3eff;
  }
`;

export const FilterIcon = styled.img`
  margin-left: 10px;
  margin-top: -5px;

  &:hover {
    cursor: pointer;
  }
`;

export const Icon = styled.img`
  width: 15px;
  height: 15px;

  float: right;
  margin-top: 10px;
`;

export const ItemSelectionList = styled.div`
  width: 100%;
  height: inherit;

  overflow: auto;

  background-color: #ffffff;

  border-radius: 4px;
`;

export const ItemSelectionListRow = styled.div`
  width: 100%;
  height: 34px;

  padding: 0px 20px;

  color: #6b768c;
  font-size: 14px;
  line-height: 34px;
  font-family: proximanovaregular;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    color: white;
    cursor: pointer;
    background-color: #0a3eff;
  }

  ${(props) =>
    props.selected &&
    css`
      color: #0a3eff;
    `}
`;
