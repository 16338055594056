import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font: ${(props) => props.theme.fonts.h1InterNonBold};
  color: ${(props) => props.theme.colors.greys.darkest};
`;

export const Subtitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: ${(props) => props.theme.fonts.h3Bold700};
  color: ${(props) => props.theme.colors.greys.dark};
`;

export const HeaderContainer = styled.div`
  flex: 0 0 auto;
`;

export const ContentContainer = styled.div`
  flex: 1 1 auto;
  padding: 24px 0;
  overflow: auto;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding-top: 24px;
  border-top: 2px solid ${(props) => props.theme.colors.greys.light};
`;
