import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

export const RECIPES_PRODUCTION_SHEET = (storeName) => [
  {
    name: storeName,
    propertyKey: 'storeName',
  },
  {
    name: i18next.t('GENERAL.DATE'),
    propertyKey: 'date',
    transform: (date) => moment(date).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
  },
  {
    name: i18next.t('GENERAL.CATEGORY'),
    propertyKey: 'category',
  },
  {
    name: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
  },
  {
    name: i18next.t('PRODUCTION.PRODUCTION.HEADER_TO_PRODUCE'),
    propertyKey: 'toProduce',
  },
  {
    name: i18next.t('PRODUCTION.PRODUCTION.HEADER_PRODUCED'),
    propertyKey: 'produced',
  },
  {
    name: i18next.t('GENERAL.UNIT'),
    propertyKey: 'unit',
  },
];

export const AGGREGATED_RECIPES_PRODUCTION_SHEET = (storeName) => [
  {
    name: storeName,
    propertyKey: 'storeName',
  },
  {
    name: i18next.t('GENERAL.CATEGORY'),
    propertyKey: 'category',
  },
  {
    name: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
  },
  {
    name: i18next.t('PRODUCTION.PRODUCTION.HEADER_TO_PRODUCE'),
    propertyKey: 'toProduce',
  },
  {
    name: i18next.t('PRODUCTION.PRODUCTION.HEADER_PRODUCED'),
    propertyKey: 'produced',
  },
  {
    name: i18next.t('GENERAL.UNIT'),
    propertyKey: 'unit',
  },
];

export const INFORMATIONS_SHEET = (storeName) => [
  {
    name: storeName,
    propertyKey: 'storeName',
  },
  {
    name: i18next.t('GENERAL.START_DATE'),
    propertyKey: 'startDate',
    transform: (startDate) => moment(startDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
  },
  {
    name: i18next.t('GENERAL.END_DATE'),
    propertyKey: 'endDate',
    transform: (endDate) => moment(endDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
  },
];
