import React, { memo } from 'react';

import { InputIncrement } from '@commons/utils/styledLibraryComponents';
import DisplayNumber from '@commons/DisplayNumber';
import Text, { ENUM_FONTS, ENUM_COLORS } from '@commons/Text';

import { getEntityUnit } from '@stocks/utils';

import {
  RowContentContainer,
  Item,
  FlexDiv,
  BoxImg,
  NameContent,
  RowTotalContainer,
} from './styledComponents';

const RecipeInventoryFormRow = memo((props) => {
  const {
    nbColumns,
    recipe,
    handleQuantityUpdate,
    setDisplayProductPicture,
    recipeQuantity,
    readOnly,
    selectedStorageAreaId,
    totByRecipeId,
    hasMultipleBrands,
  } = props;

  const recipeComputedTotal = !!totByRecipeId[recipe.id]
    ? totByRecipeId[recipe.id] * recipe.cost
    : 0;

  const handleTotalByRecipeId = (value) => {
    handleQuantityUpdate(recipe.id, value, selectedStorageAreaId);
  };

  return (
    <RowContentContainer>
      <Item nbColumns={nbColumns}>
        <FlexDiv>
          <BoxImg
            img={recipe.img || '/images/icon-generic-inpulse-product.svg'}
            pointerCursor={!!recipe.img}
            onClick={(event) => {
              event.stopPropagation();

              if (!!recipe.img && setDisplayProductPicture) {
                setDisplayProductPicture({ state: true, displayImage: recipe.img });
              }
            }}
          />
          <NameContent>
            <Text>{recipe.name}</Text>
          </NameContent>
        </FlexDiv>
      </Item>

      {hasMultipleBrands && (
        <Item nbColumns={nbColumns}>
          <Text>{recipe.brand || '-'}</Text>
        </Item>
      )}

      <Item nbColumns={nbColumns}>
        <DisplayNumber
          font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
          number={recipe.cost}
          displayCurrencyCode
        />
      </Item>

      <Item nbColumns={nbColumns}>
        <FlexDiv>
          <InputIncrement
            height="40px"
            isDisabled={readOnly}
            limitValues={{ min: 0 }}
            placeholder={''}
            setValue={handleTotalByRecipeId}
            value={totByRecipeId[recipe.id]}
            width="160px"
            allowNull
            enableNullValue
          />
          <Text
            color={recipeQuantity == null ? ENUM_COLORS.DARKER : ENUM_COLORS.DARKEST}
            style={{ paddingLeft: '16px', alignSelf: 'center' }}
          >
            {`${getEntityUnit(recipe.unit, false)}`}
          </Text>
        </FlexDiv>
      </Item>

      <Item nbColumns={nbColumns}>
        <DisplayNumber
          font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
          number={recipeComputedTotal}
          displayCurrencyCode
        />
        <RowTotalContainer>
          <DisplayNumber
            color={ENUM_COLORS.DARKER}
            displayCurrencyCode={false}
            font={ENUM_FONTS.TEXT_MICRO}
            number={totByRecipeId[recipe.id] || 0}
          />
          <Text
            color={ENUM_COLORS.DARKER}
            font={ENUM_FONTS.TEXT_MICRO}
            style={{ marginLeft: '2px' }}
          >
            {`${getEntityUnit(recipe.unit)}`}
          </Text>
        </RowTotalContainer>
      </Item>
    </RowContentContainer>
  );
});

export default RecipeInventoryFormRow;
