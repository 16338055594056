import { connect } from 'react-redux';
import i18next from 'i18next';
import React from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { Button } from '@commons/utils/styledLibraryComponents';
import { getClientStoreNameTranslation } from '@commons/utils/translations';

import { getClientInfo } from '@selectors/client';

import { cashierStoreService } from '@services/cashierStore';

import { Container } from './styledComponents';

export const cashierDetailSaveButton = (props) => {
  const {
    cashierStoreInformations,
    cashierStoreLocation,
    isCreation,
    showMessage,
    savingFinished,
    savingInProgress,
    history,
    cashierStoreId,
    client: { storeName },
  } = props;

  const translatedStoreName = getClientStoreNameTranslation(storeName).toLowerCase();

  const getPayloadCashierStore = () => {
    const cashierStore = {
      active: cashierStoreInformations.active,
      address: cashierStoreLocation.address,
      cashierConfigId: cashierStoreInformations.cashierConfigId,
      city: cashierStoreLocation.city,
      country: cashierStoreLocation.country,
      latitude: cashierStoreLocation.latitude,
      longitude: cashierStoreLocation.longitude,
      name: cashierStoreInformations.name,
      partnerId: cashierStoreInformations.partnerId,
      postCode: cashierStoreLocation.postCode,
      telephone: cashierStoreInformations.phone,
      id: cashierStoreId || undefined,
      timezone: 'Europe/Paris',
    };

    return cashierStore;
  };

  const isFormValid = () =>
    !(
      !cashierStoreInformations.name ||
      !cashierStoreInformations.partnerId ||
      !cashierStoreInformations.cashierConfigId
    );

  const createCashierStore = async () => {
    const cashierStore = getPayloadCashierStore();

    savingInProgress();

    try {
      await cashierStoreService.createCashierStore(cashierStore);

      showMessage(
        i18next.t('BACKOFFICE.CASHIER_STORES.SINGLE_CREATION_SUCCES', {
          storeName: translatedStoreName,
        }),
      );

      history.goBack();
    } catch (err) {
      showMessage(
        i18next.t('BACKOFFICE.CASHIER_STORES.SINGLE_CREATION_FAILURE', {
          storeName: translatedStoreName,
        }),
        'error',
      );
    } finally {
      savingFinished();
    }
  };

  const updateCashierStore = async () => {
    const cashierStore = getPayloadCashierStore();

    savingInProgress();

    try {
      await cashierStoreService.patchCashierStore(cashierStore);

      showMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_PATCH_SUPPLIER_PRODUCTS_SUCCESS'));

      history.goBack();
    } catch (err) {
      showMessage(i18next.t('BACKOFFICE.CASHIER_STORES.SINGLE_MODIFICATION_FAILURE'), 'error');
      window.alert(err);
    } finally {
      savingFinished();
    }
  };

  const handleSave = () => {
    if (isCreation) {
      createCashierStore();
    } else {
      updateCashierStore();
    }
  };
  return (
    <Container>
      <Button
        buttonCustomStyle={{ margin: '8px 16px 0 0' }}
        color={'inpulse-default'}
        handleClick={() => (isFormValid() ? handleSave() : {})}
        icon={'/images/inpulse/save-white-small.svg'}
        isDisabled={!isFormValid()}
        label={i18next.t('GENERAL.SAVE')}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  savingInProgress: () => {
    dispatch(loading());
  },
  savingFinished: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(cashierDetailSaveButton);
