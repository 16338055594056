import styled from 'styled-components';

export const Container = styled.div`
  min-width: 160px;
  height: 40px;
  background: ${(props) => props.theme.colors.greys.lightest};
  border-radius: 8px;
  padding: 4px 16px;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  > div {
    height: 16px !important;
    diplay: flex;
    justify-content: start;
    align-items: start;
    > img {
      margin-top: -7px;
    }
  }
`;

export const Title = styled.div`
  font: ${(props) => props.theme.fonts?.textSmallHeight16};
  color: ${(props) => props.theme.colors?.greys?.darker};
`;

export const Text = styled.div`
  font: ${(props) => props.theme.fonts?.textMiddleBold};
  color: ${(props) => props.theme.colors?.greys?.darkest};
`;
