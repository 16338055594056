import i18next from 'i18next';
import React from 'react';

import DisplayNumber from '@commons/DisplayNumber';
import TooltipButton from '@commons/TooltipButton';

import { TextWithTooltipButtonContainer } from '../styledComponents';
import formatUtils from './format';

const getListColumns = (metricKey) => [
  {
    id: 'ingredient',
    name: i18next.t('LOSSES.ANALYTICS.INGREDIENT_COLUMN_NAME'),
    propertyKey: 'name',
    isSortable: true,
    large: true,
  },
  {
    id: 'lossBySP',
    name: i18next.t('LOSSES.ANALYTICS.LOSSES_BY_INGREDIENT_COLUMN_NAME'),
    propertyKey: `lossBySP.${metricKey}`,
    tooltipDisplay: i18next.t('LOSSES.ANALYTICS.LOSSES_BY_INGREDIENT_TOOLTIP_TEXT'),
    isSortable: true,
    filterType: 'numeric',
    large: true,
    render: (item, row) => {
      const formattedCurrency = formatUtils.getFormattedCurrency(metricKey, row.entityUnit);

      return <DisplayNumber currency={formattedCurrency} number={item} displayCurrencyCode />;
    },
  },
  {
    id: 'lossByProducts',
    name: i18next.t('LOSSES.ANALYTICS.LOSSES_BY_PRODUCT_COLUMN_NAME'),
    propertyKey: `lossByProducts.${metricKey}`,
    tooltipDisplay: i18next.t('LOSSES.ANALYTICS.LOSSES_BY_PRODUCT_TOOLTIP_TEXT'),
    isSortable: true,
    filterType: 'numeric',
    large: true,
    render: (item, row) => {
      const formattedCurrency = formatUtils.getFormattedCurrency(metricKey, row.entityUnit);

      return (
        <TextWithTooltipButtonContainer>
          <DisplayNumber currency={formattedCurrency} number={item} displayCurrencyCode />
          <TooltipButton
            customTooltipStyle={{ width: '440px', height: '282px' }}
            tooltipContent={formatUtils.generateTooltipContent(row, metricKey)}
          />
        </TextWithTooltipButtonContainer>
      );
    },
  },
  {
    id: 'total',
    name: i18next.t('GENERAL.TOTAL'),
    propertyKey: `total.${metricKey}`,
    tooltipDisplay: i18next.t('LOSSES.ANALYTICS.TOTAL_COLUMN_TOOLTIP_TEXT'),
    filterType: 'numeric',
    isSortable: true,
    large: true,
    render: (item, row) => {
      const formattedCurrency = formatUtils.getFormattedCurrency(metricKey, row.entityUnit);

      return <DisplayNumber currency={formattedCurrency} number={item} displayCurrencyCode />;
    },
  },
  {
    id: 4,
    name: '',
    propertyKey: 'action',
    isClickable: true,
    isSortable: false,
  },
];

export default { getListColumns };
