import axios from '../core/http';

import { config } from '../config';

const baseApiUrl = config.baseApiUrl;

function getBrandsOfClient(clientId) {
  return axios
    .get(baseApiUrl + '/admin/client/brands', {
      params: {
        clientId,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const brand = {
  getBrandsOfClient,
};
