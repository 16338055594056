import _ from 'lodash';

import { APIcaller } from './APIcaller';
import { config } from '../config';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../commons/utils/localStorage';
import axios from '../core/http';

const baseApiUrl = config.baseApiUrl;

function login(email, password) {
  return axios
    .post(
      `${baseApiUrl}/signin/login`,
      {
        email: email,
        password: password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      setLocalStorageItem('loopbackAccessToken', response.data.id);
      setLocalStorageItem('userId', response.data.userId);
      return response.data.user;
    })
    .catch((error) => {
      throw error.response;
    });
}

function logout() {
  return axios
    .post(
      baseApiUrl + '/Accounts/logout',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then((response) => {
      removeLocalStorageItem('loopbackAccessToken');
      removeLocalStorageItem('userId');
      removeLocalStorageItem('isCentralMode');
      return response.data;
    })
    .catch((error) => {
      // Do not consider unauthorized error as a failure
      if (_.get(error.response, 'status') === 401) {
        return true;
      }

      throw error;
    });
}

const getById = (id) => {
  try {
    return APIcaller.apiGet(
      `/Accounts/${id}?filter[include][0]=lnkClientAccountrel&filter[include][1]=lnkAccountRoleAccountrel&filter[include][1]=lnkLanguageAccountrel`,
    );
  } catch (err) {
    throw err;
  }
};

function getUserRights(accountId) {
  return axios
    .get(`${baseApiUrl}/users/${accountId}/rights`, {
      params: {},
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function changePassword(old_password, new_password) {
  return axios
    .post(
      baseApiUrl + '/Accounts/change-password',
      {
        oldPassword: old_password,
        newPassword: new_password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then((response) => response.data)
    .then((result) => {
      if (result.error && result.error.statusCode === 400) {
        throw result.error;
      }
      return result;
    })
    .catch((error) => {
      throw error.response;
    });
}

function changePasswordFromResetToken(accessToken, new_password) {
  return axios
    .post(
      baseApiUrl + '/Accounts/reset-password',
      {
        newPassword: new_password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: accessToken,
        },
      },
    )
    .then((response) => response.data)
    .then((result) => {
      if (result.error && result.error.statusCode === 400) {
        throw result.error;
      }
      return result;
    })
    .catch((error) => {
      throw error.response;
    });
}

function resetPassword(email) {
  const redirectUrl = window.location.origin.toString();
  return axios
    .post(
      baseApiUrl + '/Accounts/reset',
      {
        email: email,
        redirectUrl: redirectUrl + '/',
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

const isAuthenticated = async () => {
  try {
    const userId = await getLocalStorageItem('userId');

    return APIcaller.apiGet(`/users/${userId}`);
  } catch (err) {
    throw err;
  }
};

export function getAccountById(accountId) {
  return axios
    .get(`${baseApiUrl}/users/${accountId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const getAccountsByClientId = (clientId, withoutInpulseRole = false) =>
  APIcaller.apiGet(`/users/list/${clientId}`, { withoutInpulseRole });

export function patchAccountById(accountId, account) {
  return axios
    .patch(`${baseApiUrl}/users/${accountId}`, account, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function updateUserPassword(accountId, newPassword) {
  return axios
    .post(`${baseApiUrl}/users/${accountId}/reset-password`, {
      newPassword,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .then((result) => {
      if (result.error && result.error.statusCode === 400) {
        throw result.error;
      }
      return result;
    })
    .catch((error) => {
      throw error.response;
    });
}

export function deleteAccountByIds(accountIds) {
  return axios
    .post(`${baseApiUrl}/users/deleteMany`, {
      accountIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function getStoresOfAccount(accountId) {
  return axios
    .get(`${baseApiUrl}/users/${accountId}/stores`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function dissociateStoresToAccount(accountId, storeIds) {
  return axios
    .post(`${baseApiUrl}/users/${accountId}/stores/deleteMany`, {
      storeIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function associateStoresToAccount(accountId, storeIds) {
  return axios
    .post(`${baseApiUrl}/users/${accountId}/stores`, {
      storeIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function updateAccountsByIds(userIds, fields) {
  const payload = {
    userIds: userIds,
    fields: fields,
  };
  return axios
    .patch(`${baseApiUrl}/backoffice/users`, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

//Une fonction isAuthenticated? Qui appelle le serveur pour check si le token est bien dans la base.

export const user = {
  login,
  logout,
  getById,
  getUserRights,
  resetPassword,
  getAccountById,
  changePassword,
  isAuthenticated,
  patchAccountById,
  getStoresOfAccount,
  deleteAccountByIds,
  updateUserPassword,
  getAccountsByClientId,
  associateStoresToAccount,
  dissociateStoresToAccount,
  changePasswordFromResetToken,
  updateAccountsByIds,
};
