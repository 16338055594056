import i18next from 'i18next';

import { productionAnalytics as productionAnalyticsService } from '@services/productionAnalytics';

const getAnalyticsData = async (storeIds, startDate, endDate, productIds, showErrorMessage) => {
  try {
    const analytics = await productionAnalyticsService.getAnalyticsByStores(
      storeIds,
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD'),
      productIds,
    );

    const formattedData = analytics.data.map((item, index) => ({
      ...item,
      id: index,
      assortmentRate: item.assortmentRate == null ? null : item.assortmentRate * 100,
      assortmentGapRate: item.assortmentGapRate == null ? null : item.assortmentGapRate * 100,
      initialAssortmentRate:
        item.initialAssortmentRate == null ? null : item.initialAssortmentRate * 100,
      quantityProductionRatio:
        item.quantityProductionRatio == null ? null : item.quantityProductionRatio * 100,
      quantityTurnoverWasteRate:
        item.quantityTurnoverWasteRate == null ? null : item.quantityTurnoverWasteRate * 100,
      quantityProductionWasteRate:
        item.quantityProductionWasteRate == null ? null : item.quantityProductionWasteRate * 100,
      turnoverProductionRatio:
        item.turnoverProductionRatio == null ? null : item.turnoverProductionRatio * 100,
      turnoverWasteRate: item.turnoverWasteRate == null ? null : item.turnoverWasteRate * 100,
      productionWasteRate: item.productionWasteRate == null ? null : item.productionWasteRate * 100,
      targetProductionWasteRate:
        item.targetProductionWasteRate == null ? 0 : item.targetProductionWasteRate * 100,
    }));

    if (!formattedData || !formattedData.length) {
      return [];
    }

    return [
      {
        ...analytics.totalData,
        id: 'total',
        date: i18next.t('GENERAL.ANALYTICS_LABEL_TOTAL'),
        assortmentRate:
          analytics.totalData.assortmentRate == null
            ? null
            : analytics.totalData.assortmentRate * 100,
        assortmentGapRate:
          analytics.totalData.assortmentGapRate == null
            ? null
            : analytics.totalData.assortmentGapRate * 100,
        initialAssortmentRate:
          analytics.totalData.initialAssortmentRate == null
            ? null
            : analytics.totalData.initialAssortmentRate * 100,
        quantityProductionRatio:
          analytics.totalData.quantityProductionRatio == null
            ? null
            : analytics.totalData.quantityProductionRatio * 100,
        quantityTurnoverWasteRate:
          analytics.totalData.quantityTurnoverWasteRate == null
            ? null
            : analytics.totalData.quantityTurnoverWasteRate * 100,
        quantityProductionWasteRate:
          analytics.totalData.quantityProductionWasteRate == null
            ? null
            : analytics.totalData.quantityProductionWasteRate * 100,
        quantityLossRatio:
          analytics.totalData.quantityLossRatio == null
            ? null
            : analytics.totalData.quantityLossRatio * 100,
        turnoverProductionRatio:
          analytics.totalData.turnoverProductionRatio == null
            ? null
            : analytics.totalData.turnoverProductionRatio * 100,
        turnoverWasteRate:
          analytics.totalData.turnoverWasteRate == null
            ? null
            : analytics.totalData.turnoverWasteRate * 100,
        productionWasteRate:
          analytics.totalData.productionWasteRate == null
            ? null
            : analytics.totalData.productionWasteRate * 100,
        targetProductionWasteRate:
          analytics.totalData.targetProductionWasteRate == null
            ? 0
            : analytics.totalData.targetProductionWasteRate * 100,
      },
    ].concat(formattedData);
  } catch (err) {
    if (showErrorMessage) {
      showErrorMessage(i18next.t('PRODUCTION.BY_REFERENCE.FETCH_DATA_ERROR'));
    }

    return [];
  }
};

export default getAnalyticsData;
