import _ from 'lodash';

/**
 * Get converted quantity of packaging based on master packaging configuration
 *
 * @param {uuid} packagingId - the id of SupplierProductPackaging to convert in master unit
 * @param {SupplierProductPackaging[]} packagings - the array of SupplierProductPackagings which contains the SupplierProductPackaging to convert with its parents/childs
 *
 * @returns {number} - return the conversion in master unit
 */
export const convertSPPQuantityInMasterUnit = (packagingId, packagings) => {
  const supplierProductPackaging = _.find(packagings, ['id', packagingId]);

  let totalQuantity = supplierProductPackaging.quantity;

  let lastSPP = supplierProductPackaging;

  const result = _.reduce(
    packagings,
    (total) => {
      const parentSPP = _.find(packagings, ['id', lastSPP.parentSupplierProductPackagingId]);

      if (!parentSPP) {
        return total;
      }

      lastSPP = parentSPP;

      return total * parentSPP.quantity;
    },
    totalQuantity,
  );

  return result;
};

export const computePackagingToSupplierProduct = (
  supplierProduct,
  supplierProductPackagingId = null,
) => {
  if (!supplierProduct || _.isEmpty(supplierProduct)) {
    return {};
  }

  const packagings = supplierProduct.packagings;

  if (_.isEmpty(packagings)) {
    return { ...supplierProduct, packagings: [] };
  }

  const packagingToUseForNameAndValue = _.find(packagings, (packaging) => {
    if (!supplierProductPackagingId) {
      return !!packaging.isUsedInOrder;
    }

    return packaging.id === supplierProductPackagingId;
  });

  const masterSPP = _.find(
    packagings,
    (packaging) => packaging.masterSupplierProductPackagingId === null,
  );

  const getPackagingId = () => _.get(packagingToUseForNameAndValue, 'id', null);

  const getPackagingName = () => _.get(packagingToUseForNameAndValue, 'name', null);

  const getPackagingUnit = () => _.get(masterSPP, 'unit', null);

  const getPackagingValue = () => {
    if (!packagingToUseForNameAndValue) {
      return 1;
    }

    const quantityInMasterUnit = convertSPPQuantityInMasterUnit(
      packagingToUseForNameAndValue.id,
      packagings,
    );

    return quantityInMasterUnit;
  };

  const updatedSupplierProduct = { ...supplierProduct };

  updatedSupplierProduct.packagingId = getPackagingId();
  updatedSupplierProduct.packagingName = getPackagingName();
  updatedSupplierProduct.packagingUnit = getPackagingUnit();
  updatedSupplierProduct.packagingValue = getPackagingValue();

  return updatedSupplierProduct;
};

export const computePackagingToSupplierProductInBatch = (supplierProducts) => {
  const formattedSupplierProducts = supplierProducts.map((supplierProduct) => {
    if (supplierProduct.supplierProductPackagingId) {
      return computePackagingToSupplierProduct(
        supplierProduct,
        supplierProduct.supplierProductPackagingId,
      );
    }

    return computePackagingToSupplierProduct(supplierProduct);
  });

  return formattedSupplierProducts.map((supplierProduct) =>
    Object.keys(supplierProduct).reduce((acc, key) => {
      if (key === 'receptionAnomaly') {
        Object.assign(acc, { anomalies: supplierProduct[key] });
        return acc;
      }

      acc[key] = supplierProduct[key];
      return acc;
    }, {}),
  );
};
