import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { formatNumberWithCurrency } from '@commons/DisplayNumber';
import { translateUnit } from '@commons/utils/translateUnit';
import utilsXLS from '@commons/utils/makeXLS';

const getInfoSheetColumns = (pluralTranslatedStoreName) => [
  {
    id: 0,
    name: i18next.t('BACKOFFICE.PRODUCTS.EXPORT_COLUMN_DATE'),
    propertyKey: 'date',
  },
  {
    id: 1,
    name: i18next.t('BACKOFFICE.PRODUCTS.EXPORT_COLUMN_PERIOD_START'),
    propertyKey: 'startDate',
  },
  {
    id: 2,
    name: i18next.t('BACKOFFICE.PRODUCTS.EXPORT_COLUMN_PERIOD_END'),
    propertyKey: 'endDate',
  },
  {
    id: 3,
    name: pluralTranslatedStoreName,
    propertyKey: 'storeNames',
  },
  {
    id: 4,
    name: i18next.t('GENERAL.INGREDIENT'),
    propertyKey: 'entityName',
  },
];

const createInfoSheet = (
  startDate,
  endDate,
  selectedStores,
  selectedIngredient,
  pluralTranslatedStoreName,
) => {
  const title = i18next.t('GENERAL.INFORMATIONS');

  const columns = getInfoSheetColumns(pluralTranslatedStoreName);

  const storeNames = selectedStores.map(({ name }) => name);

  const data = [
    {
      date: moment().format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
      startDate: moment(startDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
      endDate: moment(endDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
      storeNames: storeNames.join(','),
      entityName: selectedIngredient.name,
    },
  ];

  return utilsXLS.generateDefaultSheet(title, columns, data);
};

export const handleExport = (
  columns,
  entityCostHistories,
  currency,
  startDate,
  endDate,
  selectedStores,
  selectedIngredient,
  pluralTranslatedStoreName,
) => {
  const filename = i18next.t('BACKOFFICE.PRODUCTS.INGREDIENT_COST_EXPORT_FILE_NAME');

  const formattedECH = entityCostHistories.map((ech) => {
    const validityEnd = !!ech.validityEnd
      ? moment(ech.validityEnd).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)
      : '-';

    return {
      ...ech,
      date: moment(ech.date).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
      validityEnd,
      cost: formatNumberWithCurrency({
        displayCurrencyCode: true,
        number: ech.cost,
        currency,
      }),
      unit: translateUnit(ech.unit),
    };
  });

  const mainSheet = utilsXLS.generateDefaultSheet(
    i18next.t('FEATURE_NAME_BACKOFFICE_INGREDIENT_COST'),
    columns,
    formattedECH,
  );

  const infoSheet = createInfoSheet(
    startDate,
    endDate,
    selectedStores,
    selectedIngredient,
    pluralTranslatedStoreName,
  );

  utilsXLS.makeXLS(filename, [mainSheet, infoSheet]);
};
