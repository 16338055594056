import { connect } from 'react-redux';
import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import GeneralEmptyStateListView from '@commons/GeneralEmptyStateListView';

import { canCreateRushOrder } from '@selectors/actions/rushOrderActions';
import { getAuthorizedActions } from '@selectors/featureProps';

const RenderEmptyState = (props) => {
  const { createRushOrder, authorizedActions } = props;

  return (
    <GeneralEmptyStateListView
      icon={'/images/inpulse/first-order.svg'}
      renderAction={() =>
        canCreateRushOrder(authorizedActions) && (
          <Button
            color={'inpulse-default'}
            handleClick={createRushOrder}
            icon={'/images/inpulse/add-white-small.svg'}
            label={i18next.t('ORDERS.RUSH_ORDERS.EMPTY_LIST_CREATE_YOUR_FIRST_RUSH_ORDER_ACTION')}
          />
        )
      }
      subtitle={i18next.t('ORDERS.RUSH_ORDERS.EMPTY_LIST_CREATE_YOUR_FIRST_RUSH_ORDER_SUBTITLE')}
      title={i18next.t('ORDERS.RUSH_ORDERS.EMPTY_LIST_CREATE_YOUR_FIRST_RUSH_ORDER_TITLE')}
    />
  );
};

const mapStateToProps = (state) => ({
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/order/rush-orders'),
});

export default connect(mapStateToProps)(RenderEmptyState);
