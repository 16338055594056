export function sortProductsByCategoriesByProperty(
  productsByCategory,
  dataByRange,
  sortProperty,
  sortOrder,
) {
  let sortedProductsByCategory = {};
  Object.keys(productsByCategory).forEach((category) => {
    sortedProductsByCategory[category] = sortProductsByProperty(
      productsByCategory[category],
      dataByRange,
      sortProperty,
      sortOrder,
    );
  });

  return sortedProductsByCategory;
}

export function sortProductsByToProduceValue(products, dataByRange) {
  const compare = (product_A, product_B) => {
    if (
      dataByRange[product_A.productId].recommendation >
      dataByRange[product_B.productId].recommendation
    ) {
      return -1;
    }
    if (
      dataByRange[product_A.productId].recommendation <
      dataByRange[product_B.productId].recommendation
    ) {
      return 1;
    }
    return product_A.name.localeCompare(product_B.name);
  };

  return products.sort(compare);
}

export function sortProductsByProperty(products, dataByRange, sortProperty, sortOrder) {
  const compare = (product_A, product_B) => {
    if (
      dataByRange[product_A.productId][sortProperty] >
      dataByRange[product_B.productId][sortProperty]
    ) {
      return -1;
    }
    if (
      dataByRange[product_A.productId][sortProperty] <
      dataByRange[product_B.productId][sortProperty]
    ) {
      return 1;
    }
    return product_B.name.localeCompare(product_A.name);
  };

  if (sortOrder === 'desc') {
    return products.sort(compare);
  } else {
    return products.sort(compare).reverse();
  }
}

export default {
  sortProductsByToProduceValue,
  sortProductsByCategoriesByProperty,
  sortProductsByProperty,
};
