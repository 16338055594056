import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  position: relative;

  > div > div > div {
    overflow-x: visible;
  }
`;
