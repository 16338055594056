import MomentPropTypes from 'react-moment-proptypes';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Button, INPUT_WIDTH } from '../../utils/styledLibraryComponents';
import { getTheme } from '../../utils/theme';

import Text, { ENUM_FONTS } from '../../Text';

import { DATE_DISPLAY_FORMATS } from '../constants';
import { DeepsightComponentLoader } from '../../DeepsightComponents';
import { useOutsideAlerter } from '../../../hooks/useOutsideAlerter';

import PeriodWeekPickerModal from './component';

import { ButtonLabelContainer, Container } from './styledComponents';

export const PERIOD_WEEK_PICKER_TYPES = {
  PAST: 'past',
  FUTURE: 'future',
};

const PeriodWeekPicker = (props) => {
  const {
    startDate,
    endDate,
    minDate,
    maxDate,
    maxDeltaWeek,
    initialDeltaWeek,
    type,
    onDateChange,
  } = props;

  const periodWeekPickerButtonRef = useRef(null);

  const theme = getTheme();

  const formattedMinDate = minDate.startOf('week');
  const formattedMaxDate = maxDate.startOf('week');
  const weekDiffBetweenMinAndMax = formattedMaxDate.diff(formattedMinDate, 'weeks');

  const [newStartDate, setNewStartDate] = useState(startDate);
  const [newEndDate, setNewEndDate] = useState(endDate);

  const [isModalOpened, setIsModalOpened] = useState(false);

  const [label, setLabel] = useState('');

  const handleClickOutside = () => {
    setIsModalOpened(false);
  };

  useOutsideAlerter(periodWeekPickerButtonRef, handleClickOutside);

  useEffect(() => {
    initDates();
  }, [initialDeltaWeek]);

  useEffect(() => {
    setLabel(renderLabel());
  }, [startDate, endDate]);

  const initDates = () => {
    const defaultDeltaWeek = initialDeltaWeek || maxDeltaWeek;
    const diffBelowDelta = weekDiffBetweenMinAndMax < defaultDeltaWeek;

    if (!defaultDeltaWeek || diffBelowDelta) {
      setNewStartDate(formattedMinDate);
      setNewEndDate(formattedMaxDate);
      onDateChange(formattedMinDate, formattedMaxDate);
      return;
    }

    if (type === PERIOD_WEEK_PICKER_TYPES.PAST) {
      const computedStartDate = formattedMaxDate.clone().subtract(defaultDeltaWeek - 1, 'weeks');

      setNewStartDate(computedStartDate);
      setNewEndDate(formattedMaxDate);
      onDateChange(computedStartDate, formattedMaxDate);
      return;
    }

    const computedEndDate = formattedMinDate.clone().add(defaultDeltaWeek - 1, 'weeks');

    setNewStartDate(formattedMinDate);
    setNewEndDate(computedEndDate);
    onDateChange(formattedMinDate, computedEndDate);
  };

  const renderLabel = () => {
    if (!startDate && !endDate) {
      return (
        <ButtonLabelContainer>
          <DeepsightComponentLoader height={16} width={16} />
        </ButtonLabelContainer>
      );
    }

    return (
      <ButtonLabelContainer>
        <Text
          font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {startDate.format(DATE_DISPLAY_FORMATS.DATE_MONTH_YEAR)}
        </Text>
        <img alt="derouler" src="/images/inpulse/carret-right-black.svg" />
        <Text
          font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {endDate.format(DATE_DISPLAY_FORMATS.DATE_MONTH_YEAR)}
        </Text>
      </ButtonLabelContainer>
    );
  };

  return (
    <Container ref={periodWeekPickerButtonRef}>
      <Button
        color="inpulse-filter"
        fontSize={14}
        formatText={false}
        handleClick={() => {
          setIsModalOpened(!isModalOpened);
        }}
        height={40}
        icon={'/images/inpulse/calendar-today.svg'}
        isActive={isModalOpened}
        // Disable the button if loading
        isDisabled={false}
        label={label}
        maxWidth={parseInt(INPUT_WIDTH.LARGE)}
        minWidth={parseInt(INPUT_WIDTH.LARGE)}
      />
      {isModalOpened && (
        <PeriodWeekPickerModal
          {...props}
          endDate={newEndDate}
          setIsModalOpened={setIsModalOpened}
          setNewEndDate={setNewEndDate}
          setNewStartDate={setNewStartDate}
          startDate={newStartDate}
          theme={theme}
        />
      )}
    </Container>
  );
};

PeriodWeekPicker.propTypes = {
  startDate: MomentPropTypes.momentObj, // The startDate is the selected date selected to start the period
  endDate: MomentPropTypes.momentObj, // The endDate is the selected date selected to end the period
  minDate: MomentPropTypes.momentObj.isRequired, // The minDate week will be the first selectable week
  maxDate: MomentPropTypes.momentObj.isRequired, // The maxDate week will be the last selectable week
  maxDeltaWeek: PropTypes.number, // Maximum number of weeks selectable between minDate and maxDate weeks
  initialDeltaWeek: PropTypes.number, // Default number of weeks selected between minDate and maxDate weeks
  initialDeltaWeek: (props, propName, componentName) => {
    const { [propName]: propValue, maxDeltaWeek } = props;

    if (propValue !== undefined && maxDeltaWeek !== undefined && propValue > maxDeltaWeek) {
      return new Error(
        `Invalid prop '${propName}' supplied to '${componentName}'. '${propName}' must be less than or equal to 'maxDeltaWeek'.`,
      );
    }

    return null;
  },
  displayFormat: PropTypes.oneOf(Object.values(DATE_DISPLAY_FORMATS)), // date format in input
  onDateChange: PropTypes.func.isRequired, // function called when date changes
  timezone: PropTypes.string, // The timezone
  type: PropTypes.oneOf(Object.values(PERIOD_WEEK_PICKER_TYPES)), // The type of PeriodWeekPicker (ie 'past' || 'future'). If past, delta week will be applied on maxDate to initialize startDate and endDate, else, delta week will be applied on minDate to initialize startDate and endDate
};

PeriodWeekPicker.defaultProps = {
  maxDeltaWeek: null,
  initialDeltaWeek: null,
  displayFormat: DATE_DISPLAY_FORMATS.DATE_MONTH_YEAR,
  timezone: 'Europe/Paris',
  type: PERIOD_WEEK_PICKER_TYPES.PAST,
};

export default PeriodWeekPicker;
