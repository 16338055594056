import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 40px);
`;

export const Filter = styled.div`
  position: relative;
  top: 125px;
`;

export const FilterButtonContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 24px;

  > div {
    width: 240px;
  }
`;

export const LastSyncContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const LastSyncTitle = styled.span`
  color: ${(props) => props.theme.colors?.greys.darker};
  font: ${(props) => props.theme.fonts?.textSmall};
`;

export const LastSyncDate = styled.span`
  color: ${(props) => props.theme.colors?.greys?.darkest};
  font: ${(props) => props.theme.fonts?.textBigHeight24Bold};
`;
