import React from 'react';

import {
  HeaderContainer,
  FixedColumnContainer,
  ColumnContainer,
  TextContainer,
  TextRequiredCharacter,
} from './styledComponents';

export const TableFormHeader = (props) => {
  const { columns } = props;

  const fixedColumns = columns.filter((column) => column.isFixed);
  const movableColumns = columns.filter((column) => !column.isFixed);

  const renderColumn = (column) => (
    <TextContainer key={`header-column-${column.name}`} width={column.width}>
      {column.isRequired && <TextRequiredCharacter>*</TextRequiredCharacter>}
      {column.name}
      {!!column.render && column.render()}
    </TextContainer>
  );

  return (
    <HeaderContainer>
      <FixedColumnContainer>
        {fixedColumns.map((column) => renderColumn(column))}
      </FixedColumnContainer>
      <ColumnContainer>{movableColumns.map((column) => renderColumn(column))}</ColumnContainer>
    </HeaderContainer>
  );
};
