import { APIcaller } from '@services/APIcaller';

export const duplicateClientSetupFromAnotherOne = async (targetClientId, sourceClientId) =>
  APIcaller.apiPost('/backoffice/products/duplicate-setup', {
    targetClientId,
    sourceClientId,
  });

export default {
  duplicateClientSetupFromAnotherOne,
};
