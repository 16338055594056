import React from 'react';

import { get } from 'lodash';

import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { PAST_STOCKS_TYPE } from '@stocks/utils/constants';

import StocksAnalyticsPastNestedList from '../components/StocksAnalyticsPastNestedList';

import { MainPageContainer } from './styledComponents';

const StocksCentralKitchenAnalyticsPastIngredients = (props) => {
  const path = get(props.match, 'path');

  return (
    <MainPageContainer>
      <NavigationBreadCrumb featurePath={path} />
      <StocksAnalyticsPastNestedList
        pastStockType={PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS}
      ></StocksAnalyticsPastNestedList>
    </MainPageContainer>
  );
};

export default StocksCentralKitchenAnalyticsPastIngredients;
