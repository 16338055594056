import styled from 'styled-components';

export const ContentContainer = styled.div`
  height: calc(100% - ${(props) => props.headerHeight});
  width: 100%;

  padding: 24px;
  display: flex;
  flex-direction: column;

  gap: 24px;
`;
