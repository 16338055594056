import { keyBy, orderBy } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { ListView } from '@commons/utils/styledLibraryComponents';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';

import EmptyState from '@backoffice/BackOfficeStoresContainer/BackOfficeStoresManagement/components/EmptyState';

import DeepsightFiltersButton from '@orders/components/FilterButton';

import { CHOICES_DROPDOWN_ACTIVE } from '@admin/utils/DropdownItems';

import { ListViewContainer } from './styledComponents';

const getColumns = (hasMultipleBrands) => {
  const columns = [
    {
      id: 'name',
      propertyKey: 'name',
      name: i18next.t('GENERAL.NAME'),
      renderItem: (item) => <p>{item}</p>,
    },
    {
      id: 'city',
      propertyKey: 'city',
      name: i18next.t('GENERAL.CITY'),
      renderItem: (item) => <p>{item}</p>,
    },
    {
      id: 'country',
      propertyKey: 'country',
      name: i18next.t('GENERAL.COUNTRY'),
      renderItem: (item) => <p>{item}</p>,
    },
  ];

  if (hasMultipleBrands) {
    columns.splice(1, 0, {
      id: 'brand',
      propertyKey: 'brand',
      name: i18next.t('GENERAL.BRAND'),
      renderItem: (item) => <p>{item}</p>,
    });
  }

  return columns;
};

const StepStoreAssociation = (props) => {
  const { hasMultipleBrands, storesClient, storesProduct, onStoresProductChange } = props;

  const [selectionStores, setSelectionStores] = useState();

  const columns = getColumns(hasMultipleBrands);

  // Filters
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(CHOICES_DROPDOWN_ACTIVE[0]);

  useEffect(() => {
    const storesProductById = keyBy(storesProduct, 'id');

    const updatedStoresList = storesClient.map((store) => {
      if (storesProductById[store.id]) {
        return { ...store, isRowSelected: true };
      }

      return { ...store, isRowSelected: false };
    });

    const filteredStoresList = updatedStoresList.filter(
      ({ active }) => active === selectedStatus.filterValue,
    );

    setSelectionStores(orderBy(filteredStoresList, 'name'));
  }, [storesClient, selectedStatus]);

  const handleStoresSelection = (selectedStores) => {
    onStoresProductChange(selectedStores);
  };

  return (
    <ListViewContainer>
      <ListView
        columns={columns}
        data={selectionStores}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        disableFooter={true}
        forceEnableSelection={true}
        padding={STANDARD_LISTVIEW_PADDING}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => (
          <DeepsightFiltersButton
            applyFilters={applyFilters}
            columnsFilterList={[]}
            customSingleDropDowns={[
              {
                id: 'active-inactive',
                selectedItem: selectedStatus,
                list: CHOICES_DROPDOWN_ACTIVE,
                setSelectedItem: setSelectedStatus,
                defaultSelectedItem: CHOICES_DROPDOWN_ACTIVE[0],
                itemSelectedIcon: '/images/inpulse/power-ip-black.svg',
              },
            ]}
            disableAdvancedFilter={true}
            filters={filters}
            minWidth={120}
            setAdvancedFilters={() => false}
            setApplyFilters={setApplyFilters}
            setFilters={setFilters}
            textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
          />
        )}
        setSelectedItems={handleStoresSelection}
      />
    </ListViewContainer>
  );
};

export default StepStoreAssociation;
