import styled from 'styled-components';

export const Content = styled.div`
  min-height: 136px;
  padding: 30px;
  position: relative;
  width: calc(100% - 30px);
  margin: 15px 15px 0px 15px;

  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.greys.lightest};
  border: 1px solid ${(props) => props.theme.colors.greys.lighter};
  box-shadow: ${(props) => props.theme.shadows.short};

  border-radius: ${(props) => props.theme.borders?.radius.weak || undefined};
  &:last-child {
    min-height: 136px;
    margin-bottom: 10px;
  }

  .recipe-img-container {
    margin-top: 24px;
    margin-bottom: 30px !important;
  }

  &.container-picture {
    .block-order-filters-photo-new-wrapper {
      margin-top: 25px;

      justify-content: flex-start;

      img {
        width: 130px !important;
        height: 130px !important;
      }

      p {
        width: 130px;
      }
    }
  }
`;

export const CompositionContainer = styled.div`
  margin: 15px 15px 0px 15px;
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors?.greys.darkest};

  text-align: left;
  font: ${(props) => props.theme.fonts.h2};
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 24px;
`;

export const SectionInfo = styled.div`
  max-width: 33%;
  margin: 0px 25px;
  width: calc(33% - 30px);

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }

  .text-input-container,
  .input-dropdown-container {
    margin-top: ${(props) => (props.theme.borders ? '0px !important' : '25px !important')};
    ${(props) => (props.theme.fonts ? `font: ${props.theme.fonts?.textBig};` : undefined)}
    input {
      color: ${(props) => props.theme.colors?.greys.darker || '#6b768c'};

      ${(props) =>
        props.theme.fonts
          ? `font: ${props.theme.fonts?.textBig};`
          : ` font-size: 14px;
      line-height: 13px;`}
    }

    .text-input-form-label {
      color: ${(props) => props.theme.colors?.greys.darkest || '#6b768c'};

      ${(props) =>
        props.theme.fonts ? `font: ${props.theme.fonts?.textSmall};` : ` font-size: 13px;`}
    }

    .input-dropdown {
      border-radius: ${(props) => props.theme.borders?.radius.weak || '2px'};
    }

    .text-input-form-input {
      border-radius: ${(props) => props.theme.borders?.radius.weak || '2px'};
    }
  }
`;
