import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const SubContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
`;

export const Header = styled.div`
  width: 100%;
  padding: 30px 25px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(82, 134, 245, 0.1);
  -webkit-box-shadow: 0 2px 6px 0 rgba(82, 134, 245, 0.1);
  background: linear-gradient(270deg, #1ab3ec 0%, #0a35d6 98.83%);
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 400;
  color: white;
  font-style: normal;
`;

export const Content = styled.div`
  padding: 40px 40px 20px 40px;

  .progress {
    background: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
  .progress-bar {
    background: linear-gradient(269.8deg, #1ab3ec -13.83%, #0a3eff 100.6%);
  }
`;

export const SubContentLeft = styled.div`
  display: inline-block;
`;

export const SubContentRight = styled.div`
  display: inline-block;
  padding-left: 23px;
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 4px;

  color: #000000;
`;

export const Footer = styled.div`
  display: flex;
  padding-bottom: 20px;
  justify-content: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;
