import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContainerContent = styled.div`
  width: 100%;
  height: calc(100% - 40px);

  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
`;

export const ContainerTableView = styled.div`
  position: relative;
  height: calc(100% - 88px);
`;

export default {
  Container,
  ContainerContent,
  ContainerHeader,
  ContainerTableView,
};
