export const updateIsSynchronizingCashierProductsSales = (isSynchronizingCashierProductsSales) => ({
  type: 'IS_SYNCHRONIZING_CASHIER_PRODUCTS_SALES_UPDATE',
  isSynchronizingCashierProductsSales,
});

export const updateIsCashierProductSalesIngestionPipelineRunning = (
  isCashierProductSalesIngestionPipelineRunning,
) => ({
  type: 'IS_CASHIER_PRODUCT_SALES_INGESTION_PIPELINE_RUNNING_UPDATE',
  isCashierProductSalesIngestionPipelineRunning,
});
