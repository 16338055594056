import styled from 'styled-components';

export const Splitter = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${(props) => props.height || 'auto'};

  width: ${(props) => props.width};
`;

export const LeftContainer = styled.span`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

export const RightContainer = styled.span``;
