import React from 'react';
import PropTypes from 'prop-types';
import { PropsActionButton } from '../../interface';

import { Button, DropdownPicture } from './styledComponents';

const ActionButton = (props: PropsActionButton): JSX.Element => {
  const { open, visibility } = props;

  return (
    <>
      <Button
        open={open}
        visibility={visibility ? visibility.toString() : undefined}
      >
        <DropdownPicture
          id="actionButton"
          visibility={visibility ? visibility.toString() : undefined}
          open={open}
        />
      </Button>
    </>
  );
};

ActionButton.propTypes = {
  open: PropTypes.bool,
  visibility: PropTypes.bool,
};

ActionButton.defaultProps = {
  open: false,
  visibility: false,
};

export default ActionButton;
