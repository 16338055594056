import { get } from 'lodash';
import i18next from 'i18next';

const getSheetColumnsSettings = (hasLocalCatalogs) => [
  {
    displayName: i18next.t('GENERAL.NAME'),
    keyName: 'name',
    transform: (value) => (value === '---' ? '' : value),
  },
  {
    displayName: i18next.t('GENERAL.EMAIL'),
    keyName: 'email',
    transform: (value) => value,
  },
  {
    displayName: i18next.t('USERS.DETAILS.PROFILE_ROLE_INPUT_LABEL'),
    keyName: 'role',
    transform: (value) => value,
  },
  ...(hasLocalCatalogs
    ? [
        {
          displayName: i18next.t('USERS.DETAILS.PROFILE_CATALOG_INPUT_LABEL'),
          keyName: 'catalog',
          transform: (value) => value,
        },
      ]
    : []),
  {
    displayName: i18next.t('GENERAL.STORES'),
    keyName: 'storesCount',
    transform: (value) => value,
  },
  {
    displayName: i18next.t('ORDERS.ORDERS.LIST_LABEL_STATUS'),
    keyName: 'status',
    transform: (value) => i18next.t(value),
  },
  {
    displayName: i18next.t('USERS.DETAILS.PROFILE_LANGUAGE_INPUT_LABEL'),
    keyName: 'language.name',
    transform: (value) => value,
  },
];

export const createSheet = (accounts, clientName, hasLocalCatalogs) => {
  const headersSettings = getSheetColumnsSettings(hasLocalCatalogs);

  const headers = headersSettings.map(({ displayName }) => displayName);

  const mappedData = accounts.map((item) =>
    headersSettings.map(({ keyName, transform }) => transform(get(item, keyName))),
  );

  return {
    title: i18next.t('BACKOFFICE.TEAM.EXPORT_SHEET_TITLE', { clientName }),
    headers,
    data: mappedData,
  };
};
