import styled, { css } from 'styled-components';

const ROW_HEIGHT = 50;

export const NestedListContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: ${(props) => props.minWidth};

  display: flex;
  flex-direction: column;
`;

export const RowsContainer = styled.div`
  position: relative;

  height: ${(props) =>
    props.fullHeight
      ? `calc(100% - (${props.numberFixedItems * ROW_HEIGHT}px) - 40px)`
      : `calc(100% - ${props.numberFixedItems * ROW_HEIGHT}px)`};

  padding: 0 24px;
  margin: 0;

  overflow-y: scroll;
`;

export const Container = styled.div`
  height: ${(props) => props.customContentHeight || '100%'};
`;

export const FixedRowsContainer = styled.div`
  height: ${(props) => `${props.numberFixedItems * ROW_HEIGHT}px`};

  padding: 0 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const FixedRowContainer = styled.div`
  &:nth-child(1) {
    border-top-left-radius: ${(props) => props.theme.nestedList?.borderRadius};
    border-top-right-radius: ${(props) => props.theme.nestedList?.borderRadius};
  }

  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
`;

export const BorderedContainer = styled.div`
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);

  border-radius: ${(props) => props.theme.nestedList?.borderRadius};

  ${(props) =>
    props.noBorderTopRadius &&
    css`
      border-top: none;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    `}
`;

export const RowContainer = styled.div`
  &:nth-child(1) {
    border-top-left-radius: ${(props) =>
      props.noBorderTopRadius ? '0px' : props.theme.nestedList?.borderRadius};
    border-top-right-radius: ${(props) =>
      props.noBorderTopRadius ? '0px' : props.theme.nestedList?.borderRadius};
  }

  &:nth-last-child(1) {
    border-bottom-left-radius: ${(props) =>
      props.theme.nestedList?.borderRadius};
    border-bottom-right-radius: ${(props) =>
      props.theme.nestedList?.borderRadius};
    margin-bottom: 68px;
  }
`;

export const PaginatorContainer = styled.div`
  bottom: 0;
  position: fixed;
  width: -webkit-fill-available;
`;

export const GlobalContainer = styled.div`
  flex-grow: 1;
  position: absolute;
  width: 100%;
  height: calc(100% - ${(props) => (props.fullHeight ? '0px' : '108px')});
`;

export default {
  NestedListContainer,
  RowsContainer,
  BorderedContainer,
  RowContainer,
  PaginatorContainer,
  GlobalContainer,
};
