import getConvertedPackagingValue from './getConvertedPackagingValue';

const computeOrderedAmount = (products) => {
  const orderedAmount = products.reduce(
    (acc, product) => {
      const ratioLoss = !product.loss ? 1 : 1 - product.loss / 100;

      const convertedPackagingValue = getConvertedPackagingValue(product);
      acc.orderedTotUnit += product.ordered * convertedPackagingValue * ratioLoss;
      acc.orderedTotTurnover += product.ordered * product.price * ratioLoss;

      return acc;
    },
    { orderedTotUnit: 0, orderedTotTurnover: 0 },
  );

  return {
    ...orderedAmount,
    orderedTotUnit: Math.round(orderedAmount.orderedTotUnit * 100) / 100,
    orderedTotTurnover: Math.round(orderedAmount.orderedTotTurnover * 100) / 100,
  };
};

export default computeOrderedAmount;
