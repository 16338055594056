import styled from 'styled-components';

export const TableFormContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors?.greys?.lightest};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08) !important;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const HeaderAndContent = styled.div`
  display: flex;
  width: 100%;
`;
