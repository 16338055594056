export const PATHS_AVAILABLE_IN_ALL_WORKSPACES = [
  'admin',
  'backoffice',
  'help-center',
  'invoice',
  'referral',
  'users',
];

export const HELP_CENTER_COMPONENT_NAME = 'HelpCenter';
export const HELP_CENTER_FEATURE_NAME = 'Help Center';

export const REFERRAL_COMPONENT_NAME = 'Referral';
export const REFERRAL_FEATURE_NAME = 'Referral';

export const CONSUMPTION_COMPUTATION_TYPES = {
  ORDERS: 'orders',
  PRODUCT_MIX: 'product_mix',
};
