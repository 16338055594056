import i18next from 'i18next';
import moment from 'moment';

import { formatNumber } from '@commons/DisplayNumber';

import theme from '@theme';

const dateTitle = (isDateToday, graph) => {
  const dateTitle = isDateToday ? i18next.t('GENERAL.TODAY') : moment(graph.x).format('dddd Do MMMM')
  const dateTitleWithFirstLetterUppercase = dateTitle.charAt(0).toUpperCase() + dateTitle.slice(1)

  return (
    `<div style="font: ${theme.fonts.textSmallHeight14Weight700Bold}; text-align: flex-start; color: ${theme.colors.greys.darkest}; margin-bottom: 16px;">
      ${dateTitleWithFirstLetterUppercase}
    </div>`
  );
};

const deliveryRows = (graph, unit, metric, currency) => {
  const deliveryOrder = graph.y.delivery.order;
  const deliveryRushOrder = graph.y.delivery.rushOrder;
  const deliveryTotal = !deliveryOrder && !deliveryRushOrder ? null : deliveryOrder + deliveryRushOrder;
  const isMetricTurnover = metric === 'turnover';

  return (
    `<div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light};">
      <div style="color: ${theme.colors.greys.darkest}; font: ${theme.fonts.textMicroHeight12Weight700Bold}; width: 120px;">
        ${i18next.t('STOCKS.STOCKS.DELIVERY_GRAPH_TOOLTIP')}
      </div>
      <div style="color: ${theme.colors.greys.darkest}; font: ${theme.fonts.textMicroHeight12Weight700Bold}; width: 96px;">
        ${formatNumber(deliveryTotal, currency.numberDecimals)}${!deliveryTotal ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`}
      </div>
    </div>
    <div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light}; padding-left: 16px;">
      <div style="color: ${theme.colors.greys.dark}; font: ${theme.fonts.textMicroHeight12Weight500}; width: 104px;">
        ${i18next.t('STOCKS.STOCKS.ORDER_GRAPH_TOOLTIP')}
      </div>
      <div style="color: ${theme.colors.greys.dark}; font: ${theme.fonts.textMicroHeight12Weight500}; width: 96px;">
      ${formatNumber(deliveryOrder, currency.numberDecimals)}${!deliveryOrder ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`}
      </div>
    </div>
    <div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light}; padding-left: 16px;">
      <div style="color: ${theme.colors.greys.dark}; font: ${theme.fonts.textMicroHeight12Weight500}; width: 104px;">
      ${i18next.t('STOCKS.STOCKS.RUSH_ORDER_GRAPH_TOOLTIP')}
      </div>
      <div style="color: ${theme.colors.greys.dark}; font: ${theme.fonts.textMicroHeight12Weight500}; width: 96px;">
      ${formatNumber(deliveryRushOrder, currency.numberDecimals)}${!deliveryRushOrder ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`}
      </div>
    </div>`
  )
}

const consumptionRow = (graph, unit, metric, isTheorical, currency) => {
  const isMetricTurnover = metric === 'turnover';

  const consumptionValueProperty = isMetricTurnover ? 'totTurnover' : 'totUnit'

  return (
    `<div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light}">
      <div style="color: ${!isTheorical ? theme.colors.greys.darkest : theme.colors.brand.secondaryDark}; font: ${theme.fonts.textMicroHeight12Weight700Bold}; width: 120px;">
        ${i18next.t('STOCKS.STOCKS.CONSUMPTION_GRAPH_TOOLTIP')}
      </div>
      <div style="color: ${!isTheorical ? theme.colors.greys.darkest : theme.colors.brand.secondaryDark}; font: ${theme.fonts.textMicroHeight12Weight700Bold}; width: 96px;">
      ${`${graph.y.consumption[consumptionValueProperty] ? '-' : ''}${formatNumber(graph.y.consumption[consumptionValueProperty], isMetricTurnover ? currency.numberDecimals : 2)}${graph.y.consumption[consumptionValueProperty] == null ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`}`}
      </div>
    </div>`
  )
}

const lossRows = (graph, unit, metric, currency) => {
  const isMetricTurnover = metric === 'turnover';

  return (
    `<div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light};">
      <div style="color: ${theme.colors.infoRed}; font: ${theme.fonts.textMicroHeight12Weight700Bold}; width: 120px;">
        ${i18next.t('STOCKS.STOCKS.LOSS_GRAPH_TOOLTIP')}
      </div>
      <div style="color: ${theme.colors.infoRed}; font: ${theme.fonts.textMicroHeight12Weight700Bold}; width: 96px;">
        ${graph.y.loss.total ? '-' : ''}${formatNumber(graph.y.loss.total, currency.numberDecimals)}${!graph.y.loss.total ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`}
      </div>
    </div>
    <div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light}; padding-left: 16px;">
      <div style="color: ${theme.colors.greys.dark}; font: ${theme.fonts.textMicroHeight12Weight500}; width: 104px;">
        ${i18next.t('ADMIN.STORES.LIST_SHEET_TITLE_PRODUCTS')}
      </div>
      <div style="color: ${theme.colors.greys.dark}; font: ${theme.fonts.textMicroHeight12Weight500}; width: 96px;">
        ${graph.y.loss.product ? '-' : ''}${formatNumber(graph.y.loss.product, currency.numberDecimals)}${!graph.y.loss.product ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`}
      </div>
    </div>
    <div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light}; padding-left: 16px;">
      <div style="color: ${theme.colors.greys.dark}; font: ${theme.fonts.textMicroHeight12Weight500}; width: 104px;">
        ${i18next.t('FEATURE.ADMIN.INGREDIENTS')}
      </div>
      <div style="color: ${theme.colors.greys.dark}; font: ${theme.fonts.textMicroHeight12Weight500}; width: 96px;">
        ${graph.y.loss.supplierProduct ? '-' : ''}${formatNumber(graph.y.loss.supplierProduct, currency.numberDecimals)}${!graph.y.loss.supplierProduct ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`}
      </div>
    </div>`
  )
}

const transferRows = (graph, unit, metric, currency) => {
  const transferIn = graph.y.transfer.in
  const transferOut = graph.y.transfer.out
  const transferTotal = !transferIn && !transferOut ? null : transferIn - transferOut
  const isMetricTurnover = metric === 'turnover';

  return (
    `<div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light};">
      <div style="color: ${theme.colors.greys.darkest}; font: ${theme.fonts.textMicroHeight12Weight700Bold}; width: 120px;">
        ${i18next.t('STOCKS.STOCKS.TRANSFER_GRAPH_TOOLTIP')}
      </div>
      <div style="color: ${theme.colors.greys.darkest}; font: ${theme.fonts.textMicroHeight12Weight700Bold}; width: 96px;">
        ${formatNumber(transferTotal, currency.numberDecimals)}${!transferTotal ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`}
      </div>
    </div>
    <div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light}; padding-left: 16px;">
      <div style="color: ${theme.colors.greys.dark}; font: ${theme.fonts.textMicroHeight12Weight500}; width: 104px;">
        ${i18next.t('STOCKS.CURRENT_STOCKS.TOOLTIP_TRANSFER_IN')}
      </div>
      <div style="color: ${theme.colors.greys.dark}; font: ${theme.fonts.textMicroHeight12Weight500}; width: 96px;">
        ${formatNumber(transferIn, currency.numberDecimals)}${!transferIn ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`}
      </div>
    </div>
    <div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light}; padding-left: 16px;">
      <div style="color: ${theme.colors.greys.dark}; font: ${theme.fonts.textMicroHeight12Weight500}; width: 104px;">
      ${i18next.t('STOCKS.CURRENT_STOCKS.TOOLTIP_TRANSFER_OUT')}
      </div>
      <div style="color: ${theme.colors.greys.dark}; font: ${theme.fonts.textMicroHeight12Weight500}; width: 96px;">
        ${formatNumber(transferOut, currency.numberDecimals)}${!transferOut ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`}
      </div>
    </div>`
  )
}

const theoreticalStockRow = (theoricalStock, unit, metric, currency) => {

  const isMetricTurnover = metric === 'turnover';

  return (
    `<div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light};">
      <div style="color: ${theme.colors.brand.secondaryDark}; font: ${theme.fonts.textMicroHeight12Weight700Bold}; width: 120px;">
        ${i18next.t('STOCKS.STOCKS.THEORETICAL_STOCK')}
      </div>
      <div style="color: ${theme.colors.brand.secondaryDark}; font: ${theme.fonts.textMicroHeight12Weight700Bold}; width: 96px;">
        ${formatNumber(theoricalStock, currency.numberDecimals)}${theoricalStock == null ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`}
      </div>
    </div>`
  )
}

const realStockRow = (graph, realStock, displayRecipeStock, unit, metric, currency) => {
  const recipes = graph.y.inventories.recipes;
  const ingredients = graph.y.inventories.ingredients;

  const isMetricTurnover = metric === 'turnover';

  return (
    `<div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light};">
      <div style="color: ${theme.colors.greys.darker}; font: ${theme.fonts.textMicroHeight12Weight700Bold}; width: 120px;">
        ${i18next.t('STOCKS.STOCKS.REAL_STOCK')}
      </div>
      <div style="color: ${theme.colors.greys.dark}; font: ${theme.fonts.textMicroHeight12Weight700Bold}; width: 96px;">
        ${formatNumber(realStock, currency.numberDecimals)}${realStock == null ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`}
      </div>
    </div>
    <div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light}; padding-left: 16px;">
      <div style="color: ${theme.colors.greys.dark}; font: ${theme.fonts.textMicroHeight12Weight500}; width: 104px;">
      ${i18next.t('STOCKS.STOCKS.REAL_STOCK_INGREDIENTS')}
      </div>
      <div style="color: ${theme.colors.greys.dark}; font: ${theme.fonts.textMicroHeight12Weight500}; width: 96px;">
        ${formatNumber(ingredients, currency.numberDecimals)}${ingredients == null ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`}
      </div>
    </div>
    ${displayRecipeStock ? `<div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light}; padding-left: 16px;">
      <div style="color: ${theme.colors.greys.dark}; font: ${theme.fonts.textMicroHeight12Weight500}; width: 104px;">
      ${i18next.t('STOCKS.STOCKS.REAL_STOCK_RECIPES')}
      </div>
      <div style="color: ${theme.colors.greys.dark}; font: ${theme.fonts.textMicroHeight12Weight500}; width: 96px;">
        ${formatNumber(recipes, currency.numberDecimals)}${recipes == null ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`}
      </div>` : ''}
    </div>`
  )
}

const gapRow = (gapStock, unit, metric, currency) => {
  const isMetricTurnover = metric === 'turnover';

  return (
    `<div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light};">
      <div style="color: ${theme.colors.greys.darkest}; font: ${theme.fonts.textMicroHeight12Weight700Bold}; width: 120px;">
        ${i18next.t('STOCKS.STOCKS.REAL_FROM_THEORETICAL')}
      </div>
      <div style="color: ${theme.colors.greys.darkest}; font: ${theme.fonts.textMicroHeight12Weight700Bold}; width: 96px;">
        ${formatNumber(gapStock, currency.numberDecimals)}${gapStock == null ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`}
      </div>
    </div>`
  )
}

export const DeepsightTooltip = ({
  graph,
  unit,
  metric,
  gapStock,
  realStock,
  isPastDate,
  isDateToday,
  theoricalStock,
  displayRealStock,
  displayRecipeStock,
  displayRealStockGap,
  displayTheoricalStock,
  currency,
}) =>
  `<div style="background-color: ${theme.colors.greys.lightest}; width: 100%; height: 100%; border-radius: 5px; min-width: 256px; min-height: 156px;
  padding: 16px;">` +
    dateTitle(isDateToday, graph) +
    deliveryRows(graph, unit, metric, currency) +
    `${isPastDate ? consumptionRow(graph, unit, metric, false, currency) + lossRows(graph, unit, metric, currency) : ''}` +
    transferRows(graph, unit, metric, currency) +
    `${!isPastDate ? consumptionRow(graph, unit, metric, true, currency) : ''}` +
    `${displayTheoricalStock ? theoreticalStockRow(theoricalStock, unit, metric, currency) : ''}` +
    `${displayRealStock ? realStockRow(graph, realStock, displayRecipeStock, unit, metric, currency) : ''}` + `${displayRealStockGap ? gapRow(gapStock, unit, metric, currency) : ''}` +
  `</div>`


