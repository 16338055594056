import _ from 'lodash';
import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';

import PropTypes from 'prop-types';

import { getTheme } from '../../../../../utils/theme';
import { PropsModalRow } from '../../../../interface';
import { ItemRow, Icon, Text, Item } from './styledComponents';

import IconLinkBlack from '../../../../../images/icon-open-in-new-black.svg';
import IconLinkGreen from '../../../../../images/icon-open-in-new-green.svg';

const Row = (props: PropsModalRow): JSX.Element => {
  const { theme, headers, item } = props;

  return (
    <>
      <ThemeProvider theme={getTheme(theme)}>
        <ItemRow>
          {headers.map((header, index) => {
            const value: string | number =
              item[header.propertyKey] != null ? item[header.propertyKey] : '';

            const isAction: boolean = header.propertyKey === 'url';

            return (
              <Fragment key={`modal-row-${index}`}>
                {!isAction && (
                  <Item>
                    <Text bold={header.bold}>
                      {header.render
                        ? header.render(_.get(item, header.propertyKey), item)
                        : value}
                    </Text>
                  </Item>
                )}
                {isAction && (
                  <Item
                    onClick={() => {
                      if (!item.openLink) {
                        return;
                      }

                      window.open(item.openLink() || '#');
                    }}
                    narrow
                  >
                    <Icon icon={IconLinkBlack} iconHover={IconLinkGreen} />
                  </Item>
                )}
              </Fragment>
            );
          })}
        </ItemRow>
      </ThemeProvider>
    </>
  );
};

Row.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      propertyKey: PropTypes.string.isRequired,
    })
  ).isRequired,
  item: PropTypes.shape({
    orderId: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    deliveryDate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    supplierName: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.objectOf(PropTypes.any),
};

Row.defaultProps = {
  theme: null,
};

export default Row;
