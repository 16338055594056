import i18next from 'i18next';

export const CENTRAL_KITCHEN_RECIPE_CREATION = {
  getText: () => i18next.t('FEATURE.ADMIN.NEW_RECIPE'),
  path: '/admin/kitchen-products/recipes/create',
  isVisible: () => false,
};

export const CENTRAL_KITCHEN_RECIPE_DETAILS = {
  getText: () => i18next.t('FEATURE.ADMIN.RECIPE'),
  path: '/admin/kitchen-products/recipes/:id/details',
  getLink: ({ recipeId }) => `/admin/kitchen-products/recipes/${recipeId}/details`,
  isVisible: () => false,
};
