import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { ThemeProvider } from 'styled-components';

import { Props } from './interfaces';

import {
  Container,
  LabelIcon,
  Icon,
  LabelText,
  IconLoadingContainer,
  IconLoading,
  LabelIconOnly,
} from './styledComponents';

import { getTheme } from '../utils/theme';

import LOADING_ICON from '../images/icon-loading-state-dmgrey.svg';

const Label = (props: Props): JSX.Element => {
  const {
    theme,
    width,
    height,
    background,
    color,
    type,
    children,
    icon,
    iconOnly,
    position,
    isLoading,
    labelTooltip,
    useNestedListRowFont,
    tooltipWidth,
  } = props;

  const labelTheme = getTheme(theme, 'label');

  const tooltipId = `label-tooltip-${Math.random().toString()}`;
  const tooltipTheme = getTheme(theme, 'tooltip');

  if (isLoading) {
    return (
      <ThemeProvider theme={labelTheme}>
        <Container
          width={width}
          height={height}
          background={background}
          color={color}
          type={type}
        >
          <IconLoadingContainer>
            <IconLoading src={LOADING_ICON} />
          </IconLoadingContainer>
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={labelTheme}>
      <Container
        width={width}
        height={height}
        background={background}
        color={color}
        type={type}
        data-for={tooltipId}
        data-html
        data-tip={labelTooltip}
        useNestedListRowFont={useNestedListRowFont}
        tooltipWidth={tooltipWidth}
      >
        {!!icon && position === 'left' && !iconOnly && (
          <LabelIcon left>
            <Icon src={icon} />
          </LabelIcon>
        )}
        <LabelText>{children}</LabelText>
        {!!icon && position === 'right' && !iconOnly && (
          <LabelIcon left={false}>
            <Icon src={icon} />
          </LabelIcon>
        )}
        {iconOnly && !!icon && (
          <LabelIconOnly>
            <Icon src={icon} />
          </LabelIconOnly>
        )}
        <ReactTooltip
          id={tooltipId}
          className="custom-tooltip"
          textColor={tooltipTheme.textColor}
          arrowColor={tooltipTheme.arrowColor}
          backgroundColor={tooltipTheme.backgroundColor}
          data-place="top"
        />
      </Container>
    </ThemeProvider>
  );
};

Label.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.objectOf(PropTypes.any),
  width: PropTypes.string,
  height: PropTypes.string,
  type: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  icon: PropTypes.string,
  iconOnly: PropTypes.bool,
  position: PropTypes.string,
  isLoading: PropTypes.bool,
  labelTooltip: PropTypes.string,
  tooltipWidth: PropTypes.string,
  useNestedListRowFont: PropTypes.bool,
};

Label.defaultProps = {
  type: 'plain',
  theme: null,
  icon: null,
  iconOnly: false,
  position: '',
  isLoading: false,
  color: null,
  width: null,
  height: '24px',
  background: null,
  labelTooltip: '',
  useNestedListRowFont: false,
  tooltipWidth: 'auto',
};

export default Label;
