import React, { useEffect, useState } from 'react';

import {
  Container,
  TitleHeader,
  NumberHeader,
  ContainerHeader,
  ContainerContent,
} from './styledComponents';

import EmptyState from './components/EmptyState';
import ParentsCompositionList from './components/ParentsCompositionList';

import { fetchIngredientParents } from './common/services';
import i18next from 'i18next';

export function renderEntityAssociation(props) {
  return (
    <div>
      <ContainerHeader>
        <TitleHeader>{i18next.t('ADMIN.INGREDIENTS.SUB_PAGES_PARENT_ELEMENT')}</TitleHeader>
        <NumberHeader>{props.entitiesMapped.length}</NumberHeader>
      </ContainerHeader>
      <ContainerContent>
        <ParentsCompositionList
          entitiesMapped={props.entitiesMapped}
          history={props.history}
          ingredient={props.ingredient}
          onIngredientChange={props.onIngredientChange}
        />
      </ContainerContent>
    </div>
  );
}

export function renderEmptyState(isLoading) {
  return (
    <ContainerContent>
      <EmptyState isLoading={isLoading} />
    </ContainerContent>
  );
}

const IngredientParentAssociations = (props) => {
  const { entitiesMapped, onEntitiesMappedChange } = props;

  const [isLoading, setLoading] = useState(true);

  const ingredientId = props.ingredient.id;

  useEffect(() => {
    if (entitiesMapped) {
      return setLoading(false);
    }
    (async function loadData() {
      try {
        await fetchIngredientParents(ingredientId, onEntitiesMappedChange);
      } catch (err) {
        props.showMessage("Impossible de récupérer les parents de l'ingredient", 'error');
      } finally {
        setLoading(false);
      }
    })();
  }, [ingredientId]);

  return (
    <Container style={{ marginBottom: '215px', minHeight: '285px' }}>
      {(!entitiesMapped || entitiesMapped.length === 0) && renderEmptyState(isLoading)}
      {entitiesMapped &&
        entitiesMapped.length > 0 &&
        renderEntityAssociation({
          ...props,
          isLoading,
          entitiesMapped,
        })}
    </Container>
  );
};

export default IngredientParentAssociations;
