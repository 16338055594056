export const IMPORT_STATE = {
  SELECT_FILE: 'select-file',
  ERROR_FILE: 'error-file',
  VALIDATED_FILE: 'validated-file',
};

export const SWITCH_STEP = {
  NEXT: 'next',
  PREVIOUS: 'previous',
};

export const IMPORT_TYPE = {
  ENTITIES: 'entities',
  PRODUCTS: 'products',
  CENTRAL_KITCHEN_PRODUCTS: 'central-kitchen-products',
  SUPPLIER_PROFILE: 'supplier-profile',
};
