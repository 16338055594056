import { getActionAuthorization } from '../commons/utils';

const SUPPLIER_ACTIONS_RESOURCE = {
  VIEW: 'suppliers:view',

  CREATE: 'suppliers:create',

  EDIT: 'suppliers:edit',
  EDIT_FROM_DETAIL_VIEW: 'suppliers:detail:edit',
};

export const canViewSuppliers = (actions) =>
  getActionAuthorization(actions, SUPPLIER_ACTIONS_RESOURCE.VIEW);

export const canCreateSuppliers = (actions) =>
  getActionAuthorization(actions, SUPPLIER_ACTIONS_RESOURCE.CREATE);

export const canEditSuppliers = (actions) =>
  getActionAuthorization(actions, SUPPLIER_ACTIONS_RESOURCE.EDIT);

export const canEditSupplierFromDetailView = (actions) =>
  getActionAuthorization(actions, SUPPLIER_ACTIONS_RESOURCE.EDIT_FROM_DETAIL_VIEW);
