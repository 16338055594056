import i18next from 'i18next';
import momentPropTypes from 'react-moment-proptypes';
import PropTypes from 'prop-types';

import DateRangeSelectionModal from '@backoffice/components/DateRangeSelectionModal';

import StoreSelectionModal from '../components/StoreSelectionModal';

export const SYNC_CONFIG_MODAL_STEPS = {
  STORE_SELECTION: 'storeSelection',
  DATE_RANGE_SELECTION: 'dateRangeSelection',
};

const getActions = ({
  currentStep,
  selectedStores,
  salesDateRange,
  setCurrentStep,
  resetData,
  handleSelectedStoresChange,
  handleValidation,
}) => {
  if (currentStep === SYNC_CONFIG_MODAL_STEPS.STORE_SELECTION) {
    return [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
        handleClick: () => resetData(),
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.NEXT'),
        icon: '/images/inpulse/arrow-right-white.svg',
        preventClosing: true,
        isDisabled: !selectedStores.length,
        handleClick: () => {
          handleSelectedStoresChange(selectedStores);
          setCurrentStep(SYNC_CONFIG_MODAL_STEPS.DATE_RANGE_SELECTION);
        },
      },
    ];
  }

  if (currentStep === SYNC_CONFIG_MODAL_STEPS.DATE_RANGE_SELECTION) {
    return [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.BACK'),
        icon: '/images/inpulse/arrow-left-ip-black.svg',
        preventClosing: true,
        handleClick: () => {
          setCurrentStep(SYNC_CONFIG_MODAL_STEPS.STORE_SELECTION);
        },
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.VALIDATE'),
        icon: '/images/inpulse/check-white-small.svg',
        preventClosing: true,
        isDisabled: !salesDateRange,
        handleClick: () => {
          handleValidation();
        },
      },
    ];
  }
};

export const getModalConfig = (props) => {
  const {
    currentStep,
    user,
    stores,
    setSalesDateRange,
    handleStoresSelection,
    isLoading,
    resetData,
  } = props;

  if (currentStep === SYNC_CONFIG_MODAL_STEPS.STORE_SELECTION) {
    return {
      type: 'actionWhite',
      isFullscreen: true,
      component: StoreSelectionModal,
      data: { user, stores, handleStoresSelection, isLoading },
      title: i18next.t('BACKOFFICE.DATA.SELECT_STORES'),
      handleCloseCleanUp: resetData,
      customPadding: '24px 24px 0px 24px',
      actions: getActions(props),
    };
  }

  if (currentStep === SYNC_CONFIG_MODAL_STEPS.DATE_RANGE_SELECTION) {
    return {
      type: 'action',
      width: '560px',
      height: 'auto',
      isFullscreen: false,
      component: DateRangeSelectionModal,
      title: i18next.t('BACKOFFICE.DATA.SELECT_DATE_RANGE'),
      isConfirmationModal: true,
      data: { setDateRange: setSalesDateRange, timezone: 'Europe/Paris' }, // TODO - [TIMEZONES]
      handleCloseCleanUp: resetData,
      actions: getActions(props),
    };
  }
};

const propTypes = {
  currentStep: PropTypes.oneOf([
    SYNC_CONFIG_MODAL_STEPS.STORE_SELECTION,
    SYNC_CONFIG_MODAL_STEPS.DATE_RANGE_SELECTION,
  ]),
  setCurrentStep: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  hasMultipleBrands: PropTypes.bool.isRequired,
  stores: PropTypes.array.isRequired,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  handleStoresSelection: PropTypes.func.isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  resetData: PropTypes.func.isRequired,
};

getModalConfig.propTypes = propTypes;

export default getModalConfig;
