import { connect } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { showConfirmationMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import {
  renderCustomNavIconLeft,
  renderCustomNavIconRight,
} from '@commons/DeepsightAnalyticsHeaders/utils';

import orderService from '@services/order';

import { InpulseDatePickerDotedDay } from '@orders/OrderList/components/OrderForm/components/Header/components/DatePickerDotedDay';

import { ERROR_FORM_TYPES } from '@stocks/StocksInventories/components/StockForm/constants/errors';

import StockFormModalInformation from '../../StockFormModalInformation';

import { Container, ContainerInput } from './styledComponents';

function StockFormDatePicker(props) {
  const {
    stockOnlyToday,
    readOnly,
    stockDate,
    handleSelectDate,
    storeId,
    storeTimezone,
    stockType,
    showMessage,
    displayWarning,
    isEditingDate,
  } = props;

  const [focused, setFocused] = useState(false);
  const [pastInventoryDates, setPastInventoryDates] = useState([]);

  const [selectedStartMonth, setSelectedStartMonth] = useState(
    moment
      .tz(storeTimezone)
      .subtract(1, 'month')
      .startOf('month')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  );
  const [selectedEndMonth, setSelectedEndMonth] = useState(
    moment.tz(storeTimezone).endOf('month').format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  );

  useEffect(() => {
    if (
      !storeId ||
      !stockType ||
      !selectedStartMonth ||
      !selectedEndMonth ||
      (readOnly && !isEditingDate)
    ) {
      return;
    }

    (async function loadData() {
      const data = await orderService.getInventoryForStores(
        [storeId],
        selectedStartMonth,
        selectedEndMonth,
        showMessage,
        stockType,
      );

      if (data && data.dates) {
        const formattedDates = data.dates.map((item) =>
          moment(item).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        );

        const filteredDates = !isEditingDate
          ? formattedDates
          : formattedDates.filter((date) => !moment(date).isSame(stockDate, 'day'));

        setPastInventoryDates(filteredDates);

        const existingInventory = filteredDates.find((date) =>
          moment(date).isSame(stockDate, 'day'),
        );

        if (existingInventory) {
          handleSelectDate(null); // Reset selectedDate when store or stockType updated

          displayWarning(ERROR_FORM_TYPES.ALREADY_EXISTS);
        }
      }
    })();
  }, [isEditingDate, storeId, stockType, selectedStartMonth, selectedEndMonth]);

  const setSelectedMonth = (date, type) => {
    switch (type) {
      case 'next':
        setSelectedStartMonth(
          moment
            .tz(date, storeTimezone)
            .startOf('month')
            .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        );
        setSelectedEndMonth(
          moment
            .tz(date, storeTimezone)
            .add(1, 'month')
            .endOf('month')
            .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        );
        break;
      case 'previous':
        setSelectedStartMonth(
          moment
            .tz(date, storeTimezone)
            .startOf('month')
            .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        );
        setSelectedEndMonth(
          moment
            .tz(date, storeTimezone)
            .add(1, 'month')
            .endOf('month')
            .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        );
        break;
      default:
        setSelectedStartMonth(
          moment
            .tz(date.startDate, storeTimezone)
            .startOf('month')
            .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        );
        setSelectedEndMonth(
          moment
            .tz(date.endDate, storeTimezone)
            .endOf('month')
            .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        );
        break;
    }
  };

  const isOutsideRange = (day) => {
    if (stockOnlyToday) {
      return !day.isSame(moment.tz(storeTimezone), 'day');
    }

    const dayWithInventory =
      pastInventoryDates.indexOf(moment(day).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)) >=
      0;

    return !!dayWithInventory;
  };

  const selectIfRenderDotAndTodayDateCircle = (item, selectedDates) => {
    const isToday =
      moment.tz(storeTimezone).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY) ===
      item.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    let isTodaySelected = false;
    if (selectedDates) {
      isTodaySelected = selectedDates
        .map(
          (date) =>
            moment.tz(storeTimezone).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY) ===
            moment.tz(date, storeTimezone).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        )
        .some((v) => v);
    }

    if (!pastInventoryDates.length) {
      return (
        <InpulseDatePickerDotedDay
          color={'red'}
          item={item}
          renderCircle={isToday && !isTodaySelected}
        />
      );
    }
    const isDot =
      pastInventoryDates.indexOf(moment(item).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)) >=
      0;

    return (
      <InpulseDatePickerDotedDay
        color={'red'}
        isDot={isDot}
        item={item}
        renderCircle={isToday && !isTodaySelected}
      />
    );
  };

  if (readOnly && !isEditingDate) {
    return (
      <Container>
        <img
          className="stockform-date-input-icon"
          src={'/images/icon-calendar-black-inpulse.svg'}
          style={{ width: '16px' }}
        />
        <div className="block-stock-filter-locked">
          {stockDate && stockDate.format('ddd Do MMM')}
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <ContainerInput>
        <img
          alt=""
          className="stockform-date-input-icon"
          src={'/images/icon-calendar-black-inpulse.svg'}
          style={{ width: '16px', height: '16px' }}
        />
        <SingleDatePicker
          date={stockDate}
          displayFormat={'ddd Do MMM'}
          focused={focused}
          hideKeyboardShortcutsPanel={true}
          id="your_unique_start_date_id"
          isOutsideRange={(day) => isOutsideRange(day)}
          navNext={renderCustomNavIconRight()}
          navPrev={renderCustomNavIconLeft()}
          noBorder={true}
          numberOfMonths={1}
          placeholder={i18next.t('STOCKS.STOCKS.FORM_DATE_PLACEHOLDER')}
          readOnly={true}
          renderCalendarInfo={() => <StockFormModalInformation stockType={stockType} />}
          renderDayContents={(item) => selectIfRenderDotAndTodayDateCircle(item, [stockDate])}
          timezone="Europe/Paris" // TODO - [TIMEZONES]
          onDateChange={(props) => handleSelectDate(props)}
          onFocusChange={({ focused }) => setFocused(focused)}
          onNextMonthClick={(nextMonth) => setSelectedMonth(nextMonth, 'next')}
          onPrevMonthClick={(prevMonth) => setSelectedMonth(prevMonth, 'previous')}
        />
      </ContainerInput>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
});

export default connect(null, mapDispatchToProps)(StockFormDatePicker);
