import { get } from 'lodash';

export const sortCompositions = (compositions) =>
  compositions.sort(({ entity: optionA }, { entity: optionB }) => {
    const optionAName = get(optionA, 'name', '');
    const optionBName = get(optionB, 'name', '');
    return optionAName.localeCompare(optionBName);
  });

export default {
  sortCompositions,
};
