import { connect } from 'react-redux';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { Container, ToggleSection } from './styledComponents';

const ProductionAnalyticsToggle = (props) => {
  const { choices, noRightMargin, selectedChoice, setSelectedChoice, currency, isDisabled } = props;

  return (
    <Container noRightMargin={noRightMargin}>
      {choices.map((choice, index) => {
        const isSelected = choice.name === selectedChoice.name;

        return (
          <Fragment key={index}>
            <ToggleSection
              className={isSelected ? 'active' : ''}
              isDisabled={isDisabled}
              key={index}
              onClick={() => !isDisabled && setSelectedChoice(choice)}
            >
              <span>{i18next.t(choice.name, { currencyName: currency.shortenedName })}</span>
            </ToggleSection>
          </Fragment>
        );
      })}
    </Container>
  );
};

ProductionAnalyticsToggle.propTypes = {
  choices: PropTypes.array.isRequired,
  selectedChoice: PropTypes.object.isRequired,
  setSelectedChoice: PropTypes.func,
  currency: PropTypes.object, // send by base reducer
  isDisabled: PropTypes.bool,
  noRightMargin: PropTypes.bool,
};

ProductionAnalyticsToggle.defaultProps = {
  isDisabled: false,
  noRightMargin: false,
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps)(ProductionAnalyticsToggle);
