import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import React from 'react';
import styled from 'styled-components';

const CalendarTooltip = (props) => {
  const { event, storeTimezone } = props;
  const left = props.left + 'px';
  const top = props.top + 'px';

  return (
    <div
      className={props.className}
      id="calendar-tooptip"
      style={{
        left: left,
        top: top,
      }}
    >
      <div className="calendar-tooptip-title">{event.eventSubCategory.name}</div>
      {event.eventSubCategory.input1Name && (
        <div className="calendar-tooptip-text">
          <span>{props.t(event.eventSubCategory.input1Name)} : </span> {event.input1}
        </div>
      )}
      {event.eventSubCategory.input2Name && (
        <div className="calendar-tooptip-text">
          <span>{props.t(event.eventSubCategory.input2Name)} : </span> {event.input2}
        </div>
      )}
      {event.eventSubCategory.input3Name && (
        <div className="calendar-tooptip-text">
          <span>{props.t(event.eventSubCategory.input3Name)} : </span> {event.input3}
        </div>
      )}
      <div className="calendar-tooptip-text">
        <span>{props.t('FORECAST.TURNOVER.WEATHER_TOOLTIP_LABEL_START_DATE')}</span>{' '}
        {moment.tz(event.startDate, storeTimezone).format('LLL')}
      </div>
      <div className="calendar-tooptip-text">
        <span>{props.t('FORECAST.TURNOVER.WEATHER_TOOLTIP_LABEL_END_DATE')}</span>{' '}
        {moment.tz(event.endDate, storeTimezone).format('LLL')}
      </div>
    </div>
  );
};

const CalendarTooltipWithTranslation = withTranslation()(CalendarTooltip);

const InpulseCalendarTooltip = styled(CalendarTooltipWithTranslation)`
  height: 163px;
  width: 303px;
  border-radius: 5px;
  z-index: 20;
  position: absolute;
  background: ${(props) => props.theme.colors.greys.lightest};
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.04);

  #calendar-tooptip {
    opacity: 0;
    -webkit-animation: fade-in 0.2s forwards;
    -webkit-animation-delay: 0s;
    animation: fade-in 0.2s forwards;
    animation-delay: 0s;
  }

  .calendar-tooptip-title {
    width: 100%;
    height: 30px;
    color: ${(props) => props.theme.colors.greys.darkest};
    background: ${(props) => props.theme.colors.greys.lighter};
    text-align: center;
    border-radius: 7px 7px 0px 0px;
    padding-left: 10px;
    padding-right: 10px;
    font: ${(props) => props.theme.fonts?.h4};
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .calendar-tooptip-text {
    font: ${(props) => props.theme.fonts?.textSmallHeight14};
    color: ${(props) => props.theme.colors.greys.darkest};
    font-size: 13px;
    line-height: 13px;
    padding: 20px 20px 0px 20px;
  }
`;

export { InpulseCalendarTooltip };
