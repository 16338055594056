import axios from '@src/core/http';

import { config } from '@src/config';

import { APIcaller } from './APIcaller';

const baseApiUrl = config.baseApiUrl;

export const getCashierConfigByClientId = (clientId) =>
  APIcaller.apiGet(`/admin/clients/${clientId}/cashier-configs`);

export const createCashierConfig = (clientId, cashierConfig) =>
  APIcaller.apiPost(`/admin/clients/${clientId}/cashier-configs`, {
    cashierConfigs: [cashierConfig],
  });

export const patchCashierConfig = (clientId, cashierConfig, shouldByPassDataAPICall) =>
  APIcaller.apiPatch(`/admin/clients/${clientId}/cashier-configs/${cashierConfig.id}`, {
    cashierConfig,
    shouldByPassDataAPICall,
  });

export const deleteCashierConfig = (clientId, cashierConfigId) =>
  APIcaller.apiDelete(`/admin/clients/${clientId}/cashier-configs/${cashierConfigId}`);

export const getConnexionStatusByIds = (cashierConfigIds) =>
  axios
    .post(
      `${baseApiUrl}/admin/cashier-configs/status`,
      {
        cashierConfigIds,
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
      { timeout: 1800000 },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export default {
  getCashierConfigByClientId,
  patchCashierConfig,
  createCashierConfig,
  deleteCashierConfig,
  getConnexionStatusByIds,
};
