import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: hidden;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;

  background-color: ${(props) => props.theme.colors.greys.lighter};
`;

export const FiltersContainer = styled.div`
  width: 100%;
  padding: 0 24px;
`;

export const FlexedGapContainer = styled.div`
  display: flex;
  column-gap: 24px;
  width: 100%;
`;

export const ContainerCheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ExternalRedirectionContainer = styled.div`
  height: 16px;

  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-self: center;

  ${(props) => {
    if (props.enabled) {
      return css`
        &:hover {
          cursor: pointer;
        }
      `;
    }
  }};
`;

export const LinkImage = styled.img`
  width: 16px;
  height: 16px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 48px);

  padding-bottom: 8px;

  background: ${(props) => props.theme.colors.greys.lighter};
`;
