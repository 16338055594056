import _ from 'lodash';
import i18next from 'i18next';

import { STOCK_FORM_TYPES } from '../constants/types';

export const getColumnsConfig = (settings) => {
  const { stockType } = settings;

  const isStockTypeStock = stockType.toLowerCase() === STOCK_FORM_TYPES.STOCK;

  let stockTypeLabel = isStockTypeStock
    ? i18next.t('STOCKS.STOCKS.FORM_TYPE_STOCK')
    : i18next.t('STOCKS.STOCKS.FORM_TYPE_LOSS');

  if (stockType.toLowerCase() === 'transfer') {
    stockTypeLabel = i18next.t('STOCKS.TRANSFERS.CONTENT_COLUMN_TITLE');
  }

  const columns = [
    {
      id: 1,
      propertyKey: 'name',
      label: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_DESIGNATION'),
      flex: 2,
      img: true,
    },

    {
      id: 3,
      propertyKey: 'price',
      label: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_UNIT_COST'),
      tooltip: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_UNIT_COST_TOOLTIP'),
      flex: 1,
    },
    {
      id: 4,
      propertyKey: 'stockType',
      label: stockTypeLabel,
      tooltip: i18next.t('STOCKS.STOCKS.FORM_TYPE_TOOLTIP'),
      flex: 1,
    },
    {
      id: 5,
      propertyKey: 'unit',
      label: '',
      flex: 2,
    },
    {
      id: 6,
      propertyKey: 'tot',
      label: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_TOTAL_COST_EX_TAX'),
      flex: 1,
    },
  ];

  if (isStockTypeStock) {
    columns.splice(1, 0, {
      id: 2,
      propertyKey: 'supplierName',
      label: i18next.t('GENERAL.SUPPLIER'),
      flex: 1,
    });
  }

  return _.orderBy(columns, 'id');
};

export default getColumnsConfig;
