import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { getStoresOfUser, receiveStores, requestStoresError } from '@actions/store';
import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { ListView } from '@commons/utils/styledLibraryComponents';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { getClientInfo } from '@selectors/client';

import clientService from '@services/client';
import storeService from '@services/store';

import EmptyState from '../BackOfficeStoresManagement/components/EmptyState';

import { Container, ListViewContainer } from './styledComponents';
import utilsActions from './utils/actions';
import utilsColumns from './utils/columns';
import utilsModal from './utils/modals';

export const BackOfficeStoresLocations = (props) => {
  const {
    match,
    user,
    showMessage,
    client: { clientId, storeName },
  } = props;

  const path = get(match, 'path');
  const userLanguageCode = get(props.user, 'lnkLanguageAccountrel.code', 'fr');

  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);

  const [clientLocations, setClientLocations] = useState([]);
  const [storesOfClient, setStoresOfClient] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);

  const [columns, setColumns] = useState(utilsColumns.getColumnsSettings(storesOfClient));

  const getLocationsOfClient = async (clientId) => {
    try {
      const result = await clientService.getLocationsByClientId(clientId);

      return result;
    } catch (err) {
      showMessage(i18next.t('BACKOFFICE.STORES.LOCATION_FETCH_ERROR'), 'error');

      return [];
    }
  };

  const fetchData = async (clientId) => {
    const stores = await storeService.getStoresOfClient(clientId);

    setStoresOfClient(stores);

    const locations = await getLocationsOfClient(clientId);

    setClientLocations(locations);

    const clientStoreNameTranslation = getClientStoreNameTranslation(storeName, true);

    setColumns(utilsColumns.getColumnsSettings({ stores, clientStoreNameTranslation }));
  };

  const reloadLocations = async () => {
    setIsLoading(true);

    setClientLocations(await getLocationsOfClient(clientId));

    setIsLoading(false);
  };

  /***************************/
  /** Handle Location Modal **/
  /***************************/

  const handleDeleteLocation = async (locationIds) => {
    props.pageLoading();

    const isMultipleDeletion = locationIds.length > 1;

    try {
      const promises = [];
      locationIds.map((locationId) => {
        promises.push(clientService.deleteLocationById(clientId, locationId));
      });

      await Promise.all(promises);

      props.showMessage(
        i18next.t(
          isMultipleDeletion
            ? 'BACKOFFICE.LOCATIONS.DELETE_LOCATIONS_SUCCESS'
            : 'BACKOFFICE.LOCATIONS.DELETE_LOCATION_SUCCESS',
        ),
      );

      await reloadLocations();

      props.getStoresOfUser();
    } catch (err) {
      props.showMessage(
        i18next.t(
          isMultipleDeletion
            ? 'BACKOFFICE.LOCATIONS.DELETE_LOCATIONS_FAILURE'
            : 'BACKOFFICE.LOCATIONS.DELETE_LOCATIONS_FAILURE',
        ),
        'error',
      );
    } finally {
      props.pageLoaded();
    }
  };

  const handleSaveLocation = async (locationId, name, partnerId) => {
    props.pageLoading();

    const isCreation = !locationId;

    try {
      if (isCreation) {
        await clientService.createClientLocation(clientId, name, partnerId);
      } else {
        await clientService.updateClientLocation(clientId, locationId, {
          name,
          partnerId,
        });
      }

      props.showMessage(
        i18next.t(
          isCreation
            ? 'BACKOFFICE.LOCATIONS.CREATE_LOCATION_SUCCESS'
            : 'BACKOFFICE.LOCATIONS.NAME_SUCCESSFULLY_UPDATED',
        ),
      );

      await reloadLocations();
    } catch (error) {
      props.showMessage(
        i18next.t(
          isCreation
            ? 'BACKOFFICE.LOCATIONS.CREATE_LOCATION_FAILURE'
            : 'BACKOFFICE.LOCATIONS.NAME_UPDATE_ERROR',
        ),
        'error',
      );
    } finally {
      props.pageLoaded();
    }
  };

  const onLocationModalChange = (originalLocation, name, partnerId) => {
    const forbiddenNames = clientLocations.map(({ name }) => name.toLowerCase());
    const forbiddenPartnerIds = clientLocations.map(({ partnerId }) => partnerId.toLowerCase());

    const params = utilsModal.getLocationModalConfig({
      name,
      partnerId,
      originalLocation,
      forbiddenNames: forbiddenNames.filter((item) => item !== originalLocation.name.toLowerCase()),
      forbiddenPartnerIds: forbiddenPartnerIds.filter(
        (item) => item !== originalLocation.partnerId.toLowerCase(),
      ),
      onSave: handleSaveLocation,
      onInputChange: onLocationModalChange,
    });

    props.refreshGenericModal({ data: params.data, actions: params.actions });
  };

  const displayModalEditLocation = (location) => {
    const forbiddenNames = clientLocations.map(({ name }) => name.toLowerCase());
    const forbiddenPartnerIds = clientLocations.map(({ partnerId }) => partnerId.toLowerCase());

    const params = utilsModal.getLocationModalConfig({
      name: location.name,
      originalLocation: location,
      partnerId: location.partnerId,
      forbiddenNames: forbiddenNames.filter((item) => item !== location.name.toLowerCase()),
      forbiddenPartnerIds: forbiddenPartnerIds.filter(
        (item) => item !== location.partnerId.toLowerCase(),
      ),
      onSave: handleSaveLocation,
      onInputChange: onLocationModalChange,
    });

    props.openGenericModal(params);
  };

  const displayModalCreateLocation = () => {
    const forbiddenNames = clientLocations.map(({ name }) => name.toLowerCase());
    const forbiddenPartnerIds = clientLocations.map(({ partnerId }) => partnerId.toLowerCase());

    const params = utilsModal.getLocationModalConfig({
      name: '',
      partnerId: '',
      forbiddenNames,
      forbiddenPartnerIds,
      originalLocation: {
        name: '',
        partnerId: '',
      },
      onSave: handleSaveLocation,
      onInputChange: onLocationModalChange,
    });

    props.openGenericModal(params);
  };

  const displayModalDeleteLocation = (locations) => {
    const locationIds = locations.map((location) => location.id);

    const params = utilsModal.getDeleteLocationModalConfig({
      locationIds,
      handleDeleteLocation,
    });

    props.openGenericModal(params);
  };

  useEffect(() => {
    setActions(
      utilsActions.getActionsSettings({
        selectedItems,
        displayModalCreateLocation,
        displayModalEditLocation,
        displayModalDeleteLocation,
      }),
    );
    setRowActions(
      utilsActions.getRowActionsSettings({ displayModalEditLocation, displayModalDeleteLocation }),
    );
  }, [selectedItems, isLoading]);

  useEffect(() => {
    if (!user) {
      return;
    }
    (async () => {
      await fetchData(clientId);
      setIsLoading(false);
    })();
  }, [user]);
  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ListViewContainer>
        <ListView
          actions={actions}
          columns={columns}
          data={clientLocations}
          defaultOrderBy={'name'}
          defaultOrderType={'asc'}
          isLoading={isLoading}
          languageCode={userLanguageCode}
          minActionsInActionsDropdown={1}
          padding={'24px 24px 0px 24px'}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => <EmptyState />}
          rowActions={rowActions}
          setSelectedItems={setSelectedItems}
        />
      </ListViewContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  getStoresOfUser: () =>
    dispatch(getStoresOfUser()).then(
      (result) => {
        dispatch(receiveStores(result));
      },
      (error) => {
        dispatch(requestStoresError(error));
      },
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeStoresLocations);
