import { get } from 'lodash';

import entityXlsConstants from '@commons/Excels/constants/entity.xlsx.constant';
import importTypeXlsConstants from '@commons/Excels/constants/importType.xslx.constant';
import normalizeStringValue from '@commons/utils/normalizeStringValue';

import { normalizeJsonObject } from '@backoffice/utils';

const HIDDEN_SHEET_ALLERGENS = {
  ID: 'allergens',
  NAME: 'Allergènes',
};

const HIDDEN_ALLERGENS_COLUMN_ID = {
  NAME: 'name',
  ID: 'id',
};

export const formatEntityBatchCreationFile = (entities) => {
  const formattedEntities = normalizeJsonObject(entities);

  return Object.keys(formattedEntities).reduce((result, sheetName) => {
    if (!result[importTypeXlsConstants.KEY]) {
      result[importTypeXlsConstants.KEY] = get(formattedEntities, 'ImportType[0].importtype', null);
    }

    if (sheetName === entityXlsConstants.SHEET.name) {
      result[entityXlsConstants.SHEET.id] = formattedEntities[sheetName].map((current) => {
        const associatedAllergens = formattedEntities['Allergènes'].filter(({ allergenes }) => {
          // Replaces the accents ( céleri -> celeri )
          const normalizedAllergens = normalizeStringValue(allergenes);

          return !!current[normalizedAllergens] && current[normalizedAllergens] === 'true';
        });

        const allergensIds = associatedAllergens.map(({ id }) => id);

        return {
          [entityXlsConstants.COLUMN_IDS.NAME]: current['nom'],
          [entityXlsConstants.COLUMN_IDS.UNIT]: current['unite'],
          [entityXlsConstants.COLUMN_IDS.CATEGORY]: current['categorie'],
          [entityXlsConstants.COLUMN_IDS.COST]: current['cout ht'],
          [entityXlsConstants.COLUMN_IDS.IS_STRATEGIC]: current['ingredient strategique'],
          allergensIds,
        };
      });
    }

    if (sheetName === entityXlsConstants.HIDDEN_SHEET_CATEGORY.NAME) {
      result[entityXlsConstants.HIDDEN_SHEET_CATEGORY.ID] = formattedEntities[sheetName].map(
        (current) => ({
          [entityXlsConstants.HIDDEN_CATEGORY_COLUMN_ID.NAME]: current['categorie'],
        }),
      );
    }

    if (sheetName === HIDDEN_SHEET_ALLERGENS.NAME) {
      result[HIDDEN_SHEET_ALLERGENS.ID] = formattedEntities[sheetName].map((current) => ({
        [HIDDEN_ALLERGENS_COLUMN_ID.NAME]: current['allergenes'],
        [HIDDEN_ALLERGENS_COLUMN_ID.ID]: current['id'],
      }));
    }

    return result;
  }, {});
};

export default {
  formatEntityBatchCreationFile,
};
