import {
  CAT_AND_SUB_CAT_TYPES,
  STORAGE_AREA_TYPE,
  INVENTORY_LIST_TEMPLATE_TYPE,
} from './constants';

export const handleNewDataCreation = (
  item,
  type,
  {
    createdCategories,
    setCreatedCategories,
    createdSubCategories,
    setCreatedSubCategories,
    createdStorageAreas,
    setCreatedStorageAreas,
    createdInventoryListTemplates,
    setCreatedInventoryListTemplates,
  },
) => {
  const dataToUpdate = {
    [CAT_AND_SUB_CAT_TYPES.CATEGORY]: {
      data: createdCategories,
      setter: setCreatedCategories,
    },
    [CAT_AND_SUB_CAT_TYPES.SUB_CATEGORY]: {
      data: createdSubCategories,
      setter: setCreatedSubCategories,
    },
    [STORAGE_AREA_TYPE.STORAGE_AREA]: {
      data: createdStorageAreas,
      setter: setCreatedStorageAreas,
    },
    [INVENTORY_LIST_TEMPLATE_TYPE.INVENTORY_LIST_TEMPLATE]: {
      data: createdInventoryListTemplates,
      setter: setCreatedInventoryListTemplates,
    },
  };

  const currentDataToUpdate = dataToUpdate[type];
  currentDataToUpdate.setter([...currentDataToUpdate.data, item]);
};
