/**
 * Used in query filters
 * Based on API's loopback comparison clauses, to directly match a route's filter
 */
export const COMPARE_CLAUSE = {
  LESS_THAN: 'lt',
  GREATER_THAN: 'gt',
  EQUAL: 'equal',
  BETWEEN: 'between',
  EXCLUDING: 'nin',
  INCLUDING: 'inq',
};

export const makeQueryParam = (propertyKey, compareClause, values) => {
  const condition = {
    [compareClause]: [values],
  };
  return `&${propertyKey}=${JSON.stringify(condition)}`;
};
