import i18next from 'i18next';

import { CONSUMPTION_COMPUTATION_TYPES } from '../../../../../../commons/constants/centralConstants';

const getProductionPlanningCardSettings = (productionPlanningSettings) => {
  const { showYesterdayStock } = productionPlanningSettings;

  const inputs = [
    {
      type: 'dropdown',
      propertyKey: 'consumptionComputation',
      dropdownValue: [
        {
          id: CONSUMPTION_COMPUTATION_TYPES.ORDERS,
          name: i18next.t('FEATURE.OPERATIONS.ORDERS'),
          value: i18next.t('FEATURE.OPERATIONS.ORDERS'),
        },
        {
          id: CONSUMPTION_COMPUTATION_TYPES.PRODUCT_MIX,
          name: i18next.t('GENERAL.PRODUCT_MIX'),
          value: i18next.t('GENERAL.PRODUCT_MIX'),
        },
      ],
      dropdownMatchingProperty: 'id',
      hasTooltip: true,
      isDropdown: true,
      isRequired: true,
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      label: i18next.t('BACKOFFICE.STORES.CONSUMPTION_CALCULATION'),
      tooltipText: i18next.t('BACKOFFICE.STORES.CONSUMPTION_CALCULATION_TOOLTIP'),
    },
    {
      propertyKey: 'showYesterdayStock',
      hasTooltip: true,
      isRequired: true,
      isSwitch: true,
      label: i18next.t('BACKOFFICE.STORES.YESTERDAY_STOCK_DISPLAYED'),
      tooltipText: i18next.t('BACKOFFICE.STORES.YESTERDAY_STOCK_DISPLAYED_TOOLTIP'),
      default: showYesterdayStock,
    },
  ];

  return {
    title: i18next.t('ADMIN.PRODUCTS.PRODUCTION_PLANNING'),
    inputs: inputs.reduce((acc, input) => {
      acc.push({ ...input, value: productionPlanningSettings[input.propertyKey] });
      return acc;
    }, []),
  };
};

export default getProductionPlanningCardSettings;
