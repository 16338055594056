const SHEET = {
  ID: 'supplierProfiles',
  NAME: 'Profils fournisseurs',
};

const HIDDEN_SHEET_SUPPLIERS = {
  ID: 'suppliers',
  NAME: 'Fournisseurs',
};

const HIDDEN_SHEET_SUPPLIERS_COLUMN_IDS = {
  ID: 'id',
  NAME: 'name',
};

const HIDDEN_SHEET_SUPPLIERS_NORMALIZE_COLUMN_IDS = {
  ID: 'supplierid',
  NAME: 'nom',
};

export const COLUMN_IDS = {
  SUPPLIER: 'supplier',
  SUPPLIER_PROFILE_NAME: 'supplierProfileName',
  CONTACT_NAME: 'contactName',
  ORDER_EMAIL: 'orderEmail',
  CREDIT_EMAIL: 'creditEmail',
  PHONE: 'phone',
  ADDRESS: 'address',
  POSTAL_CODE: 'postalCode',
  CITY: 'city',
  COUNTRY: 'country',
  TIMEZONE: 'timezone',
  ORDER_MIN_AMOUNT: 'orderMinAmount',
  ORDER_MIN_UNIT: 'orderMinUnit',
  MONDAY_DELIVERY: 'mondayDelivery',
  TUESDAY_DELIVERY: 'tuesdayDelivery',
  WEDNESDAY_DELIVERY: 'wednesdayDelivery',
  THURSDAY_DELIVERY: 'thursdayDelivery',
  FRIDAY_DELIVERY: 'fridayDelivery',
  SATURDAY_DELIVERY: 'saturdayDelivery',
  SUNDAY_DELIVERY: 'sundayDelivery',
  ORDER_TIME_LIMIT: 'orderTimeLimit',
};

export const COLUMN_NORMALIZE_IDS = {
  SUPPLIER: 'fournisseur',
  SUPPLIER_PROFILE_NAME: 'nom du profil fournisseur',
  CONTACT_NAME: 'nom contact',
  ORDER_EMAIL: 'email de commande (separateur ,)',
  CREDIT_EMAIL: "email de demande d'avoir (separateur ,)",
  PHONE: 'telephone',
  ADDRESS: 'adresse',
  POSTAL_CODE: 'code postal',
  CITY: 'ville',
  COUNTRY: 'pays',
  TIMEZONE: 'fuseau horaire',
  ORDER_MIN_AMOUNT: 'minimum de commande (montant ht)',
  ORDER_MIN_UNIT: 'minimum de commande (colis)',
  MONDAY_DELIVERY: 'livraison lundi',
  TUESDAY_DELIVERY: 'livraison mardi',
  WEDNESDAY_DELIVERY: 'livraison mercredi',
  THURSDAY_DELIVERY: 'livraison jeudi',
  FRIDAY_DELIVERY: 'livraison vendredi',
  SATURDAY_DELIVERY: 'livraison samedi',
  SUNDAY_DELIVERY: 'livraison dimanche',
  ORDER_TIME_LIMIT: 'heure limite de commande',
};

export default {
  SHEET,
  HIDDEN_SHEET_SUPPLIERS,
  COLUMN_IDS,
  COLUMN_NORMALIZE_IDS,
  HIDDEN_SHEET_SUPPLIERS_COLUMN_IDS,
  HIDDEN_SHEET_SUPPLIERS_NORMALIZE_COLUMN_IDS,
};
