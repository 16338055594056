import i18next from 'i18next';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

import StoresAdditionModal from '../components/StoresAdditionModal';

export const getStoreAdditionModalConfig = ({
  setSelectedStoreEntityMappings,
  notMappedStoreEntityMappings,
  handleAddingStores,
}) => ({
  type: 'actionWhite',
  isFullscreen: true,
  title: i18next.t('ADMIN.RECIPES.PRODUCTON_ADD_STORES_MODAL_TITLE'),
  icon: '/images/inpulse/add-black-small.svg',
  customPadding: '24px 24px 0px 24px',
  component: StoresAdditionModal,
  data: {
    notMappedStoreEntityMappings,
    setSelectedStoreEntityMappings,
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      handleClick: handleAddingStores,
    },
  ],
});

export default getStoreAdditionModalConfig;
