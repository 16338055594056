export const canEditAdminReservedFieldsIfCentralKitchenSP = (
  isCentralKitchen,
  centralKitchenSuppliersSSPMsOfUser,
  selectedSupplier,
) => {
  // If the supplier product is not linked to a central kitchen product thus
  // all fields are enabled.
  if (!isCentralKitchen) {
    return true;
  }
  // Checks if the user is an admin mapped to the central kitchen linked to the supplier product
  if (!!centralKitchenSuppliersSSPMsOfUser && !!selectedSupplier) {
    return centralKitchenSuppliersSSPMsOfUser.includes(selectedSupplier.id);
  }
  return false;
};
