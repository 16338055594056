import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import {
  AllergensTooltipContainer,
  Image,
  ItemNameContainer,
  Name,
} from '@commons/TableForm/TableFormInModal/styledComponents';
import { DeepsightLabelSpot } from '@commons/DeepsightComponents';
import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';

import { RECIPE_COMPONENTS_FORM_INPUTS, RECIPE_COMPONENTS_INPUTS } from './formInputsConfiguration';

const renderNameColumn = (item, itemPlaceholder) => (
  <ItemNameContainer
    isLink={true}
    onClick={() => {
      const adminIngredientPath = '/admin/products/ingredients';
      const adminRecipePath = item.isKitchen
        ? '/admin/kitchen-products/recipes'
        : '/admin/products/recipes';

      window.open(
        `${item.isIngredient ? adminIngredientPath : adminRecipePath}/${get(
          item,
          '__entityId',
        )}/details`,
        '_blank',
      );
    }}
  >
    <Image src={item.img ? item.img : itemPlaceholder}></Image>
    <Name>{item.name}</Name>

    {!isEmpty(item.allergens) && (
      <AllergensTooltipContainer>
        <DeepsightLabelSpot
          isTooltip={true}
          number={item.allergens.length}
          tooltipText={i18next.t('ADMIN.RECIPES.ALLERGENS_COUNT', {
            count: item.allergens.length,
            allergens: item.allergens.map((allergen) => allergen.name).join(', '),
          })}
        />
      </AllergensTooltipContainer>
    )}
  </ItemNameContainer>
);

export const getTableFormColumns = (clientDefaultChannelId, currency, recipe) => {
  const columns = [
    {
      name: i18next.t('GENERAL.NAME'),
      width: INPUT_WIDTH.LARGE,
      input: RECIPE_COMPONENTS_FORM_INPUTS(RECIPE_COMPONENTS_INPUTS.NAME),
      isFixed: true,
      customRender: (item) =>
        renderNameColumn(
          item,
          item.isIngredient
            ? '/images/inpulse/ingredient-photo-placeholder.svg'
            : '/images/inpulse/recipe-photo-placeholder.svg',
        ),
    },
    {
      name: i18next.t('GENERAL.CATEGORY'),
      width: INPUT_WIDTH.MEDIUM,
      input: RECIPE_COMPONENTS_FORM_INPUTS(RECIPE_COMPONENTS_INPUTS.CATEGORY),
    },
    {
      name: i18next.t('GENERAL.QUANTITY'),
      width: INPUT_WIDTH.MEDIUM,
      input: RECIPE_COMPONENTS_FORM_INPUTS(RECIPE_COMPONENTS_INPUTS.QUANTITY),
    },
    {
      name: i18next.t('GENERAL.UNIT'),
      width: INPUT_WIDTH.SMALL,
      input: RECIPE_COMPONENTS_FORM_INPUTS(RECIPE_COMPONENTS_INPUTS.UNIT),
    },
    {
      name: i18next.t('GENERAL.COST_EX_TAX_WITH_CURRENCY', { currency: currency.alphabeticCode }),
      width: INPUT_WIDTH.MEDIUM,
      input: RECIPE_COMPONENTS_FORM_INPUTS(
        RECIPE_COMPONENTS_INPUTS.COST,
        clientDefaultChannelId,
        currency,
        recipe.hasMultipleChannels,
      ),
    },
  ];

  if (recipe.hasMultipleChannels) {
    columns.push(
      {
        name: i18next.t('ADMIN.RECIPES.ONSITE'),
        width: INPUT_WIDTH.SMALL,
        input: RECIPE_COMPONENTS_FORM_INPUTS(RECIPE_COMPONENTS_INPUTS.ONSITE),
      },
      {
        name: i18next.t('ADMIN.RECIPES.DELIVERY'),
        width: INPUT_WIDTH.SMALL,
        input: RECIPE_COMPONENTS_FORM_INPUTS(RECIPE_COMPONENTS_INPUTS.DELIVERY),
      },
    );
  }

  return columns;
};
