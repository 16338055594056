import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import DisplayNumber from '@commons/DisplayNumber';
import Text from '@commons/Text';

const getColumns = () => [
  {
    id: 'name',
    name: i18next.t('GENERAL.NAME'),
    displayName: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
    render: (name) => <Text>{name}</Text>,
  },
  {
    id: 'price',
    displayName: i18next.t('ADMIN.PRODUCTS.CASHIER_PRODUCTS_ASSOCIATION_PRICE'),
    name: i18next.t('ADMIN.PRODUCTS.CASHIER_PRODUCTS_ASSOCIATION_PRICE'),
    propertyKey: 'price',
    filterType: 'numeric',
    render: (price) => <DisplayNumber displayCurrencyCode={true} number={price} />,
  },
  {
    id: 'launchDate',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_LAUNCH_DATE'),
    displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_LAUNCH_DATE'),
    propertyKey: 'launchDate',
    render: (launchDate) => (
      <Text>
        {launchDate
          ? moment(launchDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)
          : i18next.t('GENERAL.SELECT_NONE_FEMININE')}
      </Text>
    ),
  },
  {
    id: 'endDate',
    name: i18next.t('ADMIN.STORES.LIST_COLUMN_END_DATE'),
    displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_END_DATE'),
    propertyKey: 'endDate',
    render: (endDate) => (
      <Text>
        {endDate
          ? moment(endDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)
          : i18next.t('GENERAL.SELECT_NONE_FEMININE')}
      </Text>
    ),
  },
];

export default getColumns;
