import ForecastCurrentDayTile from './ForecastCurrentDayTile';
import styled from 'styled-components';

export const StyledCurrentDayTile = styled(ForecastCurrentDayTile)`
  height: 565px;
  width: 400px;
  z-index: 2;
  position: relative;

  .current-day-tile-header {
    width: 100%;
    height: 55px;
    ${(props) =>
      props.theme.colors
        ? `background: ${props.theme.colors.greys.lightest};`
        : 'background-image: linear-gradient(45deg, #0a3eff 0%, #0af2fa 100%);'}
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 0 20px 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .current-day-tile-header > h1 {
    color: ${(props) => props.theme.colors?.greys.darkest || '#fff'};
    font: ${(props) => props.theme.fonts?.h1 || 'normal 20px proximanovasemibold'};
    text-transform: capitalize;
    padding: 0;
    margin: 0;
  }

  /* Current Day Tile Content */

  & .current-day-tile-content {
    border-radius: 4px;
    height: 500px;
    margin-top: 10px;
    background: #ffffff;
    box-shadow: ${(props) =>
      props.theme.colors
        ? '0px 2px 6px rgba(0, 0, 0, 0.15);'
        : '1px 2px 7px 0 rgba(107, 118, 140, 0.6)'};
    border-radius: 8px 0px 0px 8px;
  }

  .current-day-tile-forecast-encloser {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 0;
    background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
    ${(props) =>
      props.theme.colors ? undefined : 'box-shadow: 1px 2px 7px 0 rgba(107, 118, 140, 0.2);'}
    border-radius: 4px 4px 0 0;
  }

  /* Forecasts */

  & .forecast-data-line-container {
    ${(props) => (props.theme.colors ? '' : 'margin-top: 10px;')}
    margin-bottom: 15px;

    .forecast-data-line-label {
      background: ${(props) => props.theme.colors?.greys.light || '#00296a'};
      ${(props) => (props.theme.colors ? '' : 'box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);')}
      font: ${(props) => props.theme.fonts?.textBig || 'normal 12px/19px proximanovasemibold'};
      color: ${(props) => props.theme.colors?.greys.darker || '#ffffff'};
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: ${(props) => (props.theme.borders ? '20px' : '19px')};
      border-radius: '0 4px 4px 0';
      padding-left: 2%;
      padding-right: 2%;
      width: ${(props) => (props.theme.borders ? '40%' : '30%')};
      min-width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 0px 4px 4px 0px;
    }
  }
  hr {
    width: 75%;
    margin-top: 0;
    margin-bottom: 0;
  }

  & .forecast-data-line {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 8px;
    padding: 8px 8px 8px 24px;
    align-items: center;

    .forecast-data-line-content {
      width: 40%;
    }
  }

  & .forecast-data-line > .deepsight-input-encloser {
    border: 1px solid #a8b1c4;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    margin-top: 35px;
    margin-left: 20px;
  }

  & .forecast-data-line > .deepsight-input-encloser > input {
    font-family: 'proximanovaregular';
    font-size: 16px;
    color: #000000;
  }

  & .forecast-data-line-input-holder {
    width: calc(100% - 300px);
    height: 62px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  & .forecast-data-line-input {
    background: #00edb4;
    box-shadow: 0 1px 4px 0 rgba(0, 255, 185, 0.5);
    width: 85px;
    height: 32px;
    border-radius: 6px 0 0 6px;
    font-family: 'proximanovaregular';
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    line-height: 34px;
    cursor: pointer;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
  }

  & .forecast-data-line-input:hover {
    background: #2bb87f;
  }

  & .current-day-scroll {
    overflow: scroll;
    height: 335px;
    padding: 20px 20px 30px 20px;
  }

  & .current-day-scroll > .similar-days-chart-encloser {
    margin-bottom: 15px;
  }
`;
