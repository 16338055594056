import React from 'react';
import PropTypes from 'prop-types';

import { PropsItemDisplay } from '../../interface';
import Tooltip from '../../../Tooltip';

import { Item, DisplayNameContainer } from './styledComponents';

const ItemDisplay = (props: PropsItemDisplay): JSX.Element => {
  const {
    value,
    large,
    narrow,
    minWidth,
    nbColumn,
    height,
    isLoading,
    isHighlighted,
    hasNestedData,
    isBold,
    displayBigger,
  } = props;

  const renderItem = () => (
    <Item
      nbColumn={nbColumn}
      large={large}
      narrow={narrow}
      minWidth={minWidth}
      isLoading={isLoading}
      height={height}
      isHighlighted={isHighlighted}
      hasNestedData={hasNestedData}
      isBold={isBold}
      displayBigger={!!displayBigger}
    >
      {value}
    </Item>
  );

  return displayBigger ? (
    <DisplayNameContainer>
      <Tooltip
        displayBigger
        renderTooltipTrigger={() => renderItem()}
        text={value}
      />
    </DisplayNameContainer>
  ) : (
    renderItem()
  );
};

ItemDisplay.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]).isRequired,
  large: PropTypes.bool,
  height: PropTypes.number,
  isHighlighted: PropTypes.bool,
  nbColumn: PropTypes.number.isRequired,
  isBold: PropTypes.bool,
  displayBigger: PropTypes.bool,
};

ItemDisplay.defaultProps = {
  large: false,
  height: 24,
  isHighlighted: false,
  isBold: false,
  displayBigger: false,
};

export default ItemDisplay;
