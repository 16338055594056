import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

const getDeliveryDayOfSupplier = (supplier) => {
  if (!supplier || !supplier.deliveryDays) {
    return null;
  }

  return supplier.deliveryDays.split(';');
};

/**
 * Get the max order date for an order
 *
 * @param {Date} date - Reference date
 * @param {string} supplierOrderTimeLimit - Supplier order time limit
 * @returns - Max order date taking into account supplier order time limit
 */
const _getMaxOrderDateByOrderTimeLimit = (date, supplierOrderTimeLimit) => {
  if (!!supplierOrderTimeLimit) {
    return moment(
      date.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY) + 'T' + supplierOrderTimeLimit,
    ).format();
  }

  return moment(date.endOf('day')).format();
};

const getMaxOrderDate = (supplier, date, timezone) => {
  let maxOrderDate = null;
  let canStillOrder = true;

  const deliveryDays = getDeliveryDayOfSupplier(supplier);

  if (!deliveryDays) {
    return { maxOrderDate, canStillOrder };
  }

  const indexDeliveryDay = deliveryDays.findIndex(
    (day) => parseInt(day, 10) === date.isoWeekday() - 1,
  );

  if (indexDeliveryDay < 0) {
    return { maxOrderDate, canStillOrder };
  }

  const maxOrderDay = supplier.deliveryLeadTimes.split(';')[indexDeliveryDay];
  const clonedDate = date.clone().subtract(maxOrderDay, 'days');

  maxOrderDate = _getMaxOrderDateByOrderTimeLimit(clonedDate, supplier.orderTimeLimit);

  return {
    maxOrderDate,
    canStillOrder: moment.tz(timezone).isBefore(moment(maxOrderDate)),
  };
};

export default getMaxOrderDate;
