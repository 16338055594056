import { supplier } from '../services/supplier';

export const receiveSuppliers = (suppliers) => ({
  type: 'SUPPLIERS_SUCCESS',
  suppliers,
});

export const requestSuppliersError = (error) => ({
  type: 'SUPPLIERS_FAILURE',
  error,
});

export const getSuppliersOfClient = (client_id, withLinkedData, filterByUserCatalog) =>
  function (dispatch) {
    dispatch({
      type: 'SUPPLIERS_REQUEST',
    });
    return supplier.getSuppliersOfClient(client_id, withLinkedData, filterByUserCatalog);
  };

export const getSupplierPriceInputs = (clientId, supplierId) =>
  function (dispatch) {
    dispatch({
      type: 'SUPPLIER_PRICE_INPUTS_REQUEST',
    });
    return supplier.getSupplierPriceInputs(clientId, supplierId);
  };

export const receiveSupplierPriceInputs = (supplierPriceInputs) => ({
  type: 'SUPPLIER_PRICE_INPUTS_SUCCESS',
  supplierPriceInputs,
});

export const requestSupplierPriceInputsError = (error) => ({
  type: 'SUPPLIER_PRICE_INPUTS_ERROR',
  error,
});

export const getSuppliersOfStore = (storeId) =>
  function (dispatch) {
    dispatch({
      type: 'SUPPLIERS_REQUEST',
    });
    return supplier.getSuppliersOfStore(storeId);
  };

export const receiveSuppliersNoSaving = (result) => ({
  type: 'SUPPLIERS_SUCCESS_NO_SAVING',
  result,
});

export const getSuppliersOfStores = (storeIds) =>
  function (dispatch) {
    dispatch({
      type: 'SUPPLIERS_REQUEST',
    });
    return supplier.getSuppliersOfStores(storeIds);
  };

// Suppliers Categories
export const receiveSuppliersCategories = (suppliersCategories) => ({
  type: 'SUPPLIERS_CATEGORIES_SUCCESS',
  suppliersCategories,
});

export const requestSuppliersCategoriesError = (error) => ({
  type: 'SUPPLIERS_CATEGORIES_ERROR',
  error,
});

export const getSuppliersCategories = (clientId) =>
  function (dispatch) {
    dispatch({
      type: 'SUPPLIERS_CATEGORIES_REQUEST',
    });
    return supplier.getSuppliersCategories(clientId);
  };

// PATCH SUPPLIER
export const patchSupplierSuccess = (success) => ({
  type: 'PATCH_SUPPLIER_SUCCESS',
  success,
});

export const patchSupplierError = (error) => ({
  type: 'PATCH_SUPPLIER_FAILURE',
  error,
});

export const patchSupplier = (supplierToPatch) =>
  function (dispatch) {
    dispatch({
      type: 'PATCH_SUPPLIER_REQUEST',
    });
    return supplier.patchSupplier(supplierToPatch);
  };

// CHANGE SUPPLIER STATUS
export const receiveSuppliersStatus = (success) => ({
  type: 'PATCH_SUPPLIER_STATUS_SUCCESS',
  success,
});

export const requestSuppliersStatusError = (error) => ({
  type: 'PATCH_SUPPLIER_STATUS_FAILURE',
  error,
});

export const changeSuppliersStatus = (supplierIds, active) =>
  function (dispatch) {
    dispatch({
      type: 'PATCH_SUPPLIER_STATUS_REQUEST',
    });
    return supplier.changeSuppliersStatus(supplierIds, active);
  };

export const receivePatchMultipleSuppliers = (suppliers) => ({
  type: 'PATCH_MANY_SUPPLIERS_SUCCESS',
  suppliers,
});

export const requestPatchMultipleSuppliersError = (error) => ({
  type: 'PATCH_MANY_SUPPLIERS_FAILURE',
  error,
});

export const patchMultipleSuppliers = (suppliers) =>
  function (dispatch) {
    dispatch({
      type: 'PATCH_MANY_SUPPLIERS_REQUEST',
    });
    return supplier.patchMultipleSuppliers(suppliers);
  };
