import styled from 'styled-components';

export const Label = styled.div`
  width: 15px;
  height: 15px;

  margin-left: 5px;

  cursor: default;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background: #ffffff;
  border: 1px solid ${(props) => props.color};

  > span {
    color: ${(props) => props.color};

    font-size: 11px;
    font-weight: 600;
    line-height: 11px;
  }
`;
