import { connect } from 'react-redux';
import { groupBy, sumBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { Dropdown } from '@commons/utils/styledLibraryComponents';
import { getUserTimezone } from '@commons/utils/date';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import marginService from '@services/margin';

import { Container, ContentContainer, FilterContainer, GraphContainer } from './styledComponents';
import { getSalesPointStores } from '@selectors/stores';
import MarginEvolutionGraph from './components/MarginEvolutionGraph';

export const MarginEvolution = (props) => {
  const {
    match: { path },
    currency,
    stores,
    showErrorMessage,
  } = props;

  const userTimezone = getUserTimezone();
  const yesterday = moment.tz(userTimezone).subtract(1, 'days').endOf('day');

  const periodPickerState = usePeriodDatePickerState(
    moment.tz(userTimezone).subtract(1, 'month').startOf('day'),
    yesterday,
    userTimezone,
  );

  const [analyticsData, setAnalyticsData] = useState([]);
  const [selectedStores, setSelectedStores] = useState(stores);

  const [isRetrievingAnalytics, setIsRetrievingAnalytics] = useState(true);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Handle window resizing and message display if window width is too small
    window.addEventListener('resize', () => setScreenWidth(window.innerWidth));

    return () => {
      window.removeEventListener('resize', () => setScreenWidth(window.innerWidth));
    };
  }, []);

  useEffect(() => {
    if (!periodPickerState.startDate || !periodPickerState.endDate || !selectedStores.length) {
      return;
    }

    getAnalyticsData();
  }, [selectedStores, periodPickerState.startDate, periodPickerState.endDate]);

  const formatAnalyticsDataByDate = (analytics) => {
    const analyticsGroupedByDate = groupBy(analytics, 'date');

    const analyticsByDate = [];

    for (
      let iterationDate = moment(periodPickerState.startDate).clone();
      iterationDate.isSameOrBefore(periodPickerState.endDate);
      iterationDate.add(1, 'day')
    ) {
      const formattedDate = moment(iterationDate).format(
        DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
      );

      const analyticsForDate = analyticsGroupedByDate[formattedDate];

      if (!analyticsForDate) {
        analyticsByDate.push({
          date: formattedDate,
          grossSalary: 0,
          grossSalaryRatio: 0,
          marginWithoutTaxes: 0,
          marginWithoutTaxesRatio: 0,
          realConsumption: 0,
          realConsumptionRatio: 0,
          turnoverWithoutTaxes: 0,
        });

        continue;
      }

      const totalDateGrossSalary = sumBy(analyticsForDate, 'grossSalary') || 0;
      const totalDateRealConsumption = sumBy(analyticsForDate, 'realConsumption') || 0;
      const totalDateTurnoverWithoutTaxes = sumBy(analyticsForDate, 'turnoverWithoutTaxes') || 0;
      const totalDateMarginWithoutTaxes =
        totalDateTurnoverWithoutTaxes - totalDateRealConsumption - totalDateGrossSalary;

      analyticsByDate.push({
        date: formattedDate,
        grossSalary: totalDateGrossSalary,
        grossSalaryRatio: totalDateTurnoverWithoutTaxes
          ? (totalDateGrossSalary / totalDateTurnoverWithoutTaxes) * 100
          : 0,
        marginWithoutTaxes: totalDateMarginWithoutTaxes,
        marginWithoutTaxesRatio: totalDateTurnoverWithoutTaxes
          ? (totalDateMarginWithoutTaxes / totalDateTurnoverWithoutTaxes) * 100
          : 0,
        realConsumption: totalDateRealConsumption,
        realConsumptionRatio: totalDateTurnoverWithoutTaxes
          ? (totalDateRealConsumption / totalDateTurnoverWithoutTaxes) * 100
          : 0,
        turnoverWithoutTaxes: totalDateTurnoverWithoutTaxes,
      });
    }

    return analyticsByDate;
  };

  const getAnalyticsData = async () => {
    setIsRetrievingAnalytics(true);

    const storeIds = selectedStores.map(({ id }) => id);

    try {
      const analytics = await marginService.getMarginEvolutionAnalytics(
        storeIds,
        moment(periodPickerState.startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        moment(periodPickerState.endDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        userTimezone,
      );

      const formattedAnalyticsByDate = formatAnalyticsDataByDate(analytics);

      setAnalyticsData(formattedAnalyticsByDate);
    } catch {
      showErrorMessage(i18next.t('ANALYSIS.MARGIN.FETCH_DATA_FAILURE'));
    } finally {
      setIsRetrievingAnalytics(false);
    }
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ContentContainer>
        <FilterContainer>
          <Dropdown
            iconSrc={
              isRetrievingAnalytics
                ? '/images/inpulse/pin-white-small.svg'
                : selectedStores.length
                ? '/images/inpulse/pin-black-small.svg'
                : '/images/inpulse/pin-dmgrey-small.svg'
            }
            isDisabled={isRetrievingAnalytics}
            isUniqueSelection={false}
            items={stores}
            searchPlaceholder={i18next.t('GENERAL.SEARCH')}
            selectedItems={selectedStores}
            isRequired
            onSelectionChange={setSelectedStores}
          />
          <PeriodDatePicker
            disabled={isRetrievingAnalytics}
            endDate={periodPickerState.endDate}
            focusedDateInput={periodPickerState.focusedDateInput}
            maxFutureDate={yesterday}
            setFocusedDateInput={periodPickerState.setFocusedDateInput}
            startDate={periodPickerState.startDate}
            timezone={userTimezone} // TODO - [TIMEZONES] To be replaced by IPP-5229 Admin Module
            onDatesChange={periodPickerState.handleSelectedDates}
          />
        </FilterContainer>
        <GraphContainer>
          <MarginEvolutionGraph
            currency={currency}
            data={analyticsData}
            isLoading={isRetrievingAnalytics}
            screenWidth={screenWidth}
          ></MarginEvolutionGraph>
        </GraphContainer>
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  stores: getSalesPointStores(state.baseReducer.activeStores),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MarginEvolution);
