import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  padding-bottom: 24px;
`;

export const Header = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 24px 24px 0px 24px;
`;

export const HeaderInputs = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const TheoriticalBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 4px;
`;

export const TheoriticalWithLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;
`;

export const OpenInNew = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const NestedListContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
