import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS, TIME_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { ENTITY_UNITS } from '@commons/constants/units';
import { getUserTimezone } from '@commons/utils/date';
import utilsXLS from '@commons/utils/makeXLS';

const getColumns = (isMainSheet) => [
  {
    name: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
  },
  {
    name: i18next.t('GENERAL.SKU'),
    propertyKey: 'supplierProduct.sku',
  },
  {
    name: i18next.t('GENERAL.CATEGORY'),
    propertyKey: 'supplierProduct.category',
  },
  {
    name: i18next.t('GENERAL.SUB_CATEGORY'),
    propertyKey: 'supplierProduct.subCategory',
  },
  {
    name: i18next.t('GENERAL.ASSOCIATED_INGREDIENT'),
    propertyKey: 'entityName',
  },
  {
    name: i18next.t('ORDERS.BY_SUPPLIER_PRODUCT.EXPORT_IS_STRATEGIC'),
    propertyKey: 'isHighlighted',
    transform: (value) => (!value ? i18next.t('GENERAL.NO') : i18next.t('GENERAL.YES')),
  },
  {
    name: i18next.t('ORDERS.BY_SUPPLIER_PRODUCT.EXPORT_STATUS'),
    propertyKey: 'supplierProduct.active',
    transform: (value) => (!value ? i18next.t('GENERAL.INACTIVE') : i18next.t('GENERAL.ACTIVE')),
  },
  {
    name: i18next.t('GENERAL.SUPPLIER'),
    propertyKey: 'supplier.name',
  },
  {
    type: isMainSheet ? 'currency' : 'number',
    name: i18next.t('ANALYSIS.PURCHASES.COLUMN_NAME_TOT_PO'),
    propertyKey: `orderedVolume.${isMainSheet ? 'inCurrency' : 'inUnit'}`,
  },
  {
    type: isMainSheet ? 'currency' : 'number',
    name: i18next.t('ANALYSIS.PURCHASES.COLUMN_NAME_TOT_DN'),
    propertyKey: `invoicedVolume.${isMainSheet ? 'inCurrency' : 'inUnit'}`,
  },
  {
    type: isMainSheet ? 'currency' : 'number',
    name: i18next.t('ANALYSIS.PURCHASES.COLUMN_NAME_TOT_RECEIVED'),
    propertyKey: `receivedVolume.${isMainSheet ? 'inCurrency' : 'inUnit'}`,
  },
  {
    type: isMainSheet ? 'currency' : 'number',
    name: i18next.t('ANALYSIS.PURCHASES.COLUMN_NAME_RECEPTION_DEVIATION'),
    propertyKey: `receivedDiff.${isMainSheet ? 'inCurrency' : 'inUnit'}`,
  },
  {
    type: 'percentage',
    name: i18next.t('ANALYSIS.PURCHASES.COLUMN_NAME_SERVICE_RATE'),
    propertyKey: 'serviceRate',
  },
];

// Unit sheet
const createSecondSheet = (analyticsData, title) => {
  const columns = getColumns(false);

  const unitColumn = {
    name: i18next.t('GENERAL.UNIT'),
    propertyKey: 'masterUnit',
    transform: (value) => (value === ENTITY_UNITS.UNIT ? i18next.t('GENERAL.UNIT') : value),
  };

  columns.splice(7, 0, unitColumn);

  const headers = columns.map(({ name }) => name);

  const columnMappedData = analyticsData.map((analytic) =>
    columns.map(({ propertyKey, transform }) => {
      const analyticData = _.get(analytic, propertyKey, '');
      if (!transform) {
        return analyticData;
      }
      return transform(analyticData);
    }),
  );

  return {
    title,
    headers,
    data: columnMappedData,
  };
};

const createInfoSheet = (metaData, storeTranslations) => {
  const {
    dateRange,
    selectedStores,
    selectedSuppliers,
    isStrategic,
    selectedCategory,
    selectedSubCategory,
    statuses,
  } = metaData;

  const headers = [
    i18next.t('LOSSES.PRODUCTS.EXPORT_COLUMN_DATE'),
    i18next.t('LOSSES.PRODUCTS.EXPORT_COLUMN_TIME'),
    i18next.t('LOSSES.PRODUCTS.EXPORT_COLUMN_PERIOD_START'),
    i18next.t('LOSSES.PRODUCTS.EXPORT_COLUMN_PERIOD_END'),
    storeTranslations.plural,
    i18next.t('GENERAL.SUPPLIER_PLURAL'),
    i18next.t('STOCKS.CURRENT_STOCKS.FILTERS_STRATEGIC_ONLY'),
    i18next.t('GENERAL.CATEGORIES'),
    i18next.t('GENERAL.SUB_CATEGORIES'),
    i18next.t('GENERAL.STATUSES'),
  ];

  const selectedStoreNames = selectedStores.map(({ name }) => name).toString();
  const selectedSupplierNames = selectedSuppliers
    .map(({ name }) => name)
    .sort()
    .toString();
  const statusesNames = statuses.map(({ name }) => name).toString();

  const userTimezonedNow = moment.tz(getUserTimezone());

  return {
    title: i18next.t('GENERAL.INFORMATIONS'),
    headers,
    data: [
      [
        userTimezonedNow.format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
        userTimezonedNow.format(TIME_DISPLAY_FORMATS.SLASHED_HOUR_MINUTE_SECOND),
        dateRange.startDate,
        dateRange.endDate,
        selectedStoreNames,
        selectedSupplierNames,
        isStrategic,
        _.get(selectedCategory, 'name', ''),
        _.get(selectedSubCategory, 'name', ''),
        statusesNames,
      ],
    ],
  };
};

export const exportOrderSPAnalyticsData = (
  analyticsData,
  totalRowData,
  metaData,
  storeTranslations,
  currency,
) => {
  const sortedData = analyticsData.sort((dataA, dataB) => {
    const orderedVolumeAInCurrency = _.get(dataA, 'orderedVolume.inCurrency', null);
    const orderedVolumeBInCurrency = _.get(dataB, 'orderedVolume.inCurrency', null);

    return orderedVolumeBInCurrency - orderedVolumeAInCurrency;
  });

  const mainSheet = utilsXLS.generateDefaultSheet(
    i18next.t('GENERAL.METRIC_TURNOVER_CURRENCY', { currencyCode: currency.alphabeticCode }),
    getColumns(true),
    [totalRowData, ...sortedData],
    currency,
  );

  const secondSheet = createSecondSheet(sortedData, i18next.t('GENERAL.UNIT'));

  const infoSheet = createInfoSheet(metaData, storeTranslations);

  const analyticsFilename = i18next.t('ORDERS.BY_SUPPLIER_PRODUCT.EXPORT_FILENAME', {
    date: moment.tz(getUserTimezone()).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  });

  utilsXLS.makeXLS(analyticsFilename, [mainSheet, secondSheet, infoSheet]);
};
