import orderService from '@services/order';

const DEFAULT_ORDER_BY_REQUEST = 'orderDate';
const DEFAULT_ORDER_TYPE_REQUEST = 'DESC';

const getByIds = async (orderIds) => orderService.getOrderDetailsByOrderIds(orderIds);

const getPaginated = async (storeIds, supplierIds, search, skip, limit, params) => {
  const { orders: fetchOrders, totalCount: count } = await orderService.getOrdersOfStoresPaginate(
    storeIds,
    supplierIds,
    search,
    skip,
    limit,
    DEFAULT_ORDER_BY_REQUEST,
    DEFAULT_ORDER_TYPE_REQUEST,
    params,
  );

  const fetchOrderIds = fetchOrders.map(({ id }) => id);

  const orderDetails = await getByIds(fetchOrderIds);

  return { data: orderDetails, count };
};

export default {
  getByIds,
  getPaginated,
};
