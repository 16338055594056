const { useState, useEffect } = require('react');

function getOfflineStatus() {
  return typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? !navigator.onLine
    : false;
}

function useOfflineStatus() {
  const [offlineStatus, setOfflineStatus] = useState(getOfflineStatus());

  const goOnline = () => setOfflineStatus(false);

  const goOffline = () => setOfflineStatus(true);

  useEffect(() => {
    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);

  return offlineStatus;
}

export default useOfflineStatus;
