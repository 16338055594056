import styled from 'styled-components';

export const Container = styled.div`
  flex: 4;
  padding: 30px;
  background: ${(props) => props.theme.colors?.greys.lightest};
  width: calc(75% - 60px);
  box-shadow: ${(props) => props.theme.shadows.short};
  border-radius: ${(props) => props.theme.borders?.radius.weak};
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors?.greys.darkest};

  text-align: left;
  font: ${(props) => props.theme.fonts?.h2};
`;

export const FormContainer = styled.div`
  width: 100%;
`;

export const Section = styled.div`
  width: ${(props) => (props.small ? '66%' : '100%')};
  display: flex;

  margin-top: 25px;
`;

export const SectionInfo = styled.div`
  width: 100%;
  flex: 1;
  margin-right: 25px;
  display: flex;
  align-items: flex-start;
  &:last-child {
    margin-right: 0px;
  }

  .text-input-container,
  .input-dropdown-container {
    margin-top: 0px !important;
  }
`;
