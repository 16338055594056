import { sortBy, get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import utilsXLS from '@commons/utils/makeXLS';

import { formatDate } from './columns';
import { getMainSheetOcerizationColumnsSettings } from '../components/invoiceControlListsOcerizationExportModal/columns';

const createMainSheet = (invoiceControlLists, userLanguageCode, currency, storeName) => {
  const columns = [
    {
      propertyKey: 'supplier.name',
      name: i18next.t('GENERAL.SUPPLIER'),
    },
    {
      propertyKey: 'store.name',
      name: getClientStoreNameTranslation(storeName),
    },
    {
      propertyKey: 'createdAt',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_IMPORT_DATE'),
      transform: (date) => formatDate(date, userLanguageCode),
    },
    {
      propertyKey: 'date',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_DATE'),
      transform: (date) => (!!date ? formatDate(date, userLanguageCode) : ''),
    },
    {
      propertyKey: 'number',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_NUMBER_EXPORT'),
      transform: (number) => (!!number ? number : '-'),
    },
    {
      propertyKey: 'total',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICED_TOT_EX_TAX'),
      type: 'currency',
    },
  ];

  const sortedInvoiceControls = sortBy(invoiceControlLists, ({ supplier }) =>
    supplier.name.toLowerCase(),
  );

  return utilsXLS.generateDefaultSheet(
    i18next.t('GENERAL.EXPORT_LIST_XLS'),
    columns,
    sortedInvoiceControls,
    currency,
  );
};

export const exportInvoiceControlLists = async (
  invoiceControlLists,
  userLanguageCode,
  currency,
  storeName,
) => {
  const filename = i18next.t('INVOICE.INVOICE_CONTROLS.EXPORT_LIST_FILE_NAME', {
    date: moment().format(DATE_DISPLAY_FORMATS.DASHED_DAY_MONTH_YEAR),
  });

  const mainSheet = createMainSheet(invoiceControlLists, userLanguageCode, currency, storeName);

  utilsXLS.makeXLS(filename, [mainSheet]);
};

export const formatICLOcerizationDataForExport = (invoice, fetchedICLOcerizationData) => {
  const formattedICLsOcerizationDataToExport = fetchedICLOcerizationData.map((item) => ({
    ...item,
    storeName: get(invoice, 'store.name', ''),
    supplierName: get(invoice, 'supplier.name', ''),
    computedTotal: item.invoicePrice * item.invoiceQuantity,
    invoiceDate:
      invoice.date && moment(invoice.date).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
    importedAt:
      invoice.createdAt &&
      moment(invoice.createdAt).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
    invoiceNumber: get(invoice, 'number', ''),
  }));

  return formattedICLsOcerizationDataToExport;
};

export const exportInvoiceOcerizationData = (
  invoice,
  fetchedICLOcerizationData,
  storeName,
  currency,
) => {
  const fileName = i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_FILENAME', {
    date: moment(invoice.createdAt).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  });

  const sheetName = i18next.t(
    'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_MAIN_SHEET_NAME',
  );

  const columns = getMainSheetOcerizationColumnsSettings(storeName);

  const formattedIclOcerizationData = formatICLOcerizationDataForExport(
    invoice,
    fetchedICLOcerizationData,
  );

  const mainSheet = {
    data: formattedIclOcerizationData,
    title: sheetName,
    headers: columns,
  };

  utilsXLS.makeXLS(fileName, [
    utilsXLS.generateDefaultSheet(mainSheet.title, mainSheet.headers, mainSheet.data, currency),
  ]);
};
