import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(122, 122, 122, 0.1);
`;

export const EmptyStateContainer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  background: ${(props) => props.backgroundColor || props.theme.colors.greys.lighter};
`;

export const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 15px;
`;

export const EmptyStateIcon = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  margin-left: 25px;
  margin-top: 40px;
  position: absolute;

  &.center {
    margin: auto;
    display: flex;
    position: relative;
  }

  &.image-loader {
    width: 3em;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
