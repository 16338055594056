import React from 'react';

import PropTypes from 'prop-types';

import { ContentInfosContainer, Title, Text } from './styledComponents';

export const FullScreenModalContentInfos = (props) => (
  <ContentInfosContainer>
    <Title>{props.title}</Title>
    <Text>{props.text}</Text>
  </ContentInfosContainer>
);

FullScreenModalContentInfos.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default FullScreenModalContentInfos;
