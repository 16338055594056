import styled from 'styled-components';

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 48px);

  padding-bottom: 8px;

  background: ${(props) => props.theme.colors.greys.lighter};
`;

export const ListContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const ConfigPanelContainer = styled.div`
  width: 100%;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
`;

export const LeftSide = styled.div`
  display: flex;
  column-gap: 24px;
`;

export const RightSide = styled.div`
  display: flex;
  column-gap: 24px;

  margin-left: 24px;
`;
