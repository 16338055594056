import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import sortActionsList from '@commons/utils/sortActionsList';

import {
  canCreateInventory,
  canDeleteInventory,
  canCreateInitialisationInventory,
  canValidateInventory,
  canInvalidateInventory,
} from '@selectors/actions/inventoriesStockActions';

export function getActions(props) {
  const {
    openNewStock,
    exportContent,
    exportList,
    selectedItems,
    deleteInventory,
    openCreateInitialInventoryModal,
    authorizedActions,
    isCentralKitchenView,
    inventoryValidation,
    handleValidationOrInvalidation,
    theoreticalInventory,
    openCreateTheoreticalInventoryModal,
  } = props;

  const hasSelectedItems = !!selectedItems.length;
  const isOneSelected = selectedItems.length === 1;

  const hasOneValidItem = selectedItems.some(({ isValidated }) => !!isValidated);
  const hasOneInvalidItem = selectedItems.some(({ isValidated }) => !isValidated);

  const actions = [
    {
      id: 'action-validate',
      actionLabel: i18next.t('STOCKS.STOCKS.VALIDATE_INVENTORY', { count: selectedItems.length }),
      actionIcon: () => '/images/inpulse/edit-pen-black-small.svg',
      handleAction: async () => handleValidationOrInvalidation(selectedItems, true),
      isHidden: () =>
        isCentralKitchenView ||
        !hasSelectedItems ||
        !inventoryValidation ||
        hasOneValidItem ||
        !canValidateInventory(authorizedActions),
    },
    {
      id: 'action-invalidate',
      actionLabel: i18next.t('STOCKS.STOCKS.INVALIDATE_INVENTORY', { count: selectedItems.length }),
      actionIcon: () => '/images/inpulse/edit-pen-black-small.svg',
      handleAction: async () => handleValidationOrInvalidation(selectedItems, false),
      isHidden: () =>
        isCentralKitchenView ||
        !hasSelectedItems ||
        !inventoryValidation ||
        hasOneInvalidItem ||
        !canInvalidateInventory(authorizedActions),
    },
    {
      id: 'action-delete',
      actionLabel: i18next.t('STOCKS.STOCKS.LIST_DELETE_INVENTORY'),
      actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
      handleAction: async (items) => deleteInventory(items[0]),
      isHidden: () =>
        !isOneSelected || (!isCentralKitchenView && !canDeleteInventory(authorizedActions)),
    },
    {
      id: 'action-create-initial-inventory',
      actionLabel: i18next.t('STOCKS.STOCKS.LIST_CREATE_INITIAL_INVENTORY'),
      actionIcon: () => '/images/inpulse/add-black-small.svg',
      handleAction: () => openCreateInitialInventoryModal(),
      isHidden: () =>
        hasSelectedItems ||
        (!isCentralKitchenView && !canCreateInitialisationInventory(authorizedActions)),
    },
    {
      id: 'action-create-theoretical-inventory',
      actionLabel: i18next.t('STOCKS.STOCKS.LIST_CREATE_THEORETICAL_INVENTORY'),
      actionIcon: () => '/images/inpulse/add-black-small.svg',
      handleAction: openCreateTheoreticalInventoryModal,
      isHidden: () => !theoreticalInventory || hasSelectedItems || isCentralKitchenView,
    },
    {
      id: 'export',
      actionLabel: isOneSelected
        ? i18next.t('STOCKS.STOCKS.FORM_EXPORT_STOCK_BUTTON')
        : hasSelectedItems
        ? i18next.t('STOCKS.STOCKS.FORM_EXPORT_SELECTION_BUTTON')
        : i18next.t('GENERAL.EXPORT_LIST'),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: exportList,
    },
    {
      id: 'export-content',
      actionLabel: isOneSelected
        ? i18next.t('GENERAL.EXPORT_CONTENT_SINGULAR')
        : i18next.t('GENERAL.EXPORT_CONTENT_PLURAL'),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: (items) => exportContent(items),
    },
    {
      id: 'create',
      actionLabel: i18next.t('GENERAL.CREATE'),
      fixed: canCreateInventory(authorizedActions),
      handleAction: () => false,
      isHidden: () => !isCentralKitchenView && !canCreateInventory(authorizedActions),
      render: () => (
        <Button
          color={'blue'}
          handleClick={openNewStock}
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('GENERAL.CREATE')}
        />
      ),
    },
  ];

  return sortActionsList(actions);
}

export function getRowActions(props) {
  const {
    exportContent,
    exportList,
    deleteInventory,
    authorizedActions,
    isCentralKitchenView,
    handleValidationOrInvalidation,
    inventoryValidation,
  } = props;

  const rowActions = [
    {
      id: 'action-validate',
      actionLabel: () => i18next.t('STOCKS.STOCKS.VALIDATE_INVENTORY', { count: 1 }),
      actionIcon: () => '/images/inpulse/edit-pen-black-small.svg',
      handleAction: async (item) => handleValidationOrInvalidation([item], true),
      isHidden: (item) =>
        isCentralKitchenView ||
        !inventoryValidation ||
        !!item.isValidated ||
        !canValidateInventory(authorizedActions),
    },
    {
      id: 'action-invalidate',
      actionLabel: () => i18next.t('STOCKS.STOCKS.INVALIDATE_INVENTORY', { count: 1 }),
      actionIcon: () => '/images/inpulse/edit-pen-black-small.svg',
      handleAction: async (item) => handleValidationOrInvalidation([item], false),
      isHidden: (item) =>
        isCentralKitchenView ||
        !inventoryValidation ||
        !item.isValidated ||
        !canInvalidateInventory(authorizedActions),
    },
    {
      id: 'action-delete',
      actionLabel: () => i18next.t('STOCKS.STOCKS.LIST_DELETE_INVENTORY'),
      handleAction: async (listItem) => {
        deleteInventory(listItem);
      },
      actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
      isHidden: () => !isCentralKitchenView && !canDeleteInventory(authorizedActions),
    },
    {
      id: 'export',
      actionLabel: () => i18next.t('STOCKS.STOCKS.FORM_EXPORT_STOCK_BUTTON'),
      handleAction: (listItem) => exportList([listItem]),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
    {
      id: 'export-content',
      actionLabel: () => i18next.t('GENERAL.EXPORT_CONTENT_SINGULAR'),
      handleAction: (listItem) => {
        exportContent([listItem]);
      },
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
  ];

  return sortActionsList(rowActions);
}
