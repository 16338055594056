import 'react-tagsinput/react-tagsinput.css';
import { uniq } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import TagsInput from 'react-tagsinput';

import { Asterisk, Container, TagsInputContainer, TitleContainer } from './styledComponents';
import { REGEX_MATCH_EMAIL_INSENSITIVE } from '../../../commons/utils/regex';
import Text, { ENUM_COLORS, ENUM_FONTS } from '../../../commons/Text';

const InputEmailChip = (props) => {
  const {
    emails,
    title,
    setEmails,
    placeholder,
    width,
    required,
    isDisabled = false,
    titleProperties,
  } = props;

  const [realPlaceholder, setRealPlaceholder] = useState(placeholder);

  useEffect(() => {
    const sanitizedEmails = sanitizeEmails(emails);

    if (sanitizedEmails.length > 0) {
      setRealPlaceholder('');
      return;
    }
    setRealPlaceholder(placeholder);
  }, [emails]);

  const handleChangeEmails = (emails) => {
    if (!emails.length) {
      setEmails('');
      return;
    }

    const emailsToAdd = sanitizeEmails(emails);

    const uniqEmail = uniq(emailsToAdd);

    setEmails(uniqEmail.join(','));
  };

  const sanitizeEmails = (emails) => {
    if (!emails || !emails.length) {
      return [];
    }

    const emailsToSanitize = typeof emails === 'string' ? emails.split(',') : emails;

    return emailsToSanitize.reduce((acc, email) => {
      if (email === '') {
        return acc;
      }
      acc.push(email);
      return acc;
    }, []);
  };

  return (
    <>
      <Container>
        <TitleContainer>
          {required && <Asterisk>*</Asterisk>}
          <Text {...titleProperties}>{title}</Text>
        </TitleContainer>
        <TagsInputContainer isDisabled={isDisabled} width={width}>
          <TagsInput
            addKeys={[188, 13, 32]}
            disabled={isDisabled}
            inputProps={{
              className: 'react-tagsinput-custom',
              placeholder: realPlaceholder,
            }}
            name="contact-emails"
            pasteSplit={(emails) => sanitizeEmails(emails)}
            validationRegex={REGEX_MATCH_EMAIL_INSENSITIVE}
            value={sanitizeEmails(emails)}
            addOnPaste
            onlyUnique
            onChange={(emails) => handleChangeEmails(emails)}
          />
        </TagsInputContainer>
      </Container>
    </>
  );
};

InputEmailChip.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  titleProperties: PropTypes.object,
  setEmails: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  required: PropTypes.bool,
};

InputEmailChip.defaultProps = {
  emails: [],
  title: '',
  placeholder: '',
  required: false,
  titleProperties: {
    color: ENUM_COLORS.DARKEST,
    font: ENUM_FONTS.TEXT_BIG_HEIGHT_16,
  },
};

export default InputEmailChip;
