import { clone, isEmpty, reverse, sortBy } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getTheme } from '@commons/utils/theme';
import { SimpleTableView, ToggleButton, Tooltip } from '@commons/utils/styledLibraryComponents';
import { TooltipIcon } from '@commons/Metrics/styledComponents';
import EmptyState from '@commons/EmptyState';
import ScrollableWhiteCard from '@commons/WhiteCard/ScrollableWhiteCard';

import { forecast as forecastService } from '@services/forecast';

import { HeaderContainer, Title, TitleContainer } from '../../styledComponents';
import dashboardColumns from '../../utils/getColumns';

const PRODUCT_RANKING_CHOICES = [
  { key: 'TOP_TEN', name: i18next.t('DASHBOARD.FOODCOST.TOP_TEN') },
  { key: 'FLOP_TEN', name: i18next.t('DASHBOARD.FOODCOST.FLOP_TEN') },
];

const TopFlopProductsTable = (props) => {
  const { stores, startDate, endDate, useTtc } = props;

  const theme = getTheme();

  /* STATE */

  const [selectedMetric, setSelectedMetric] = useState(PRODUCT_RANKING_CHOICES[0]);
  const [isDashboardTopProductsLoading, setIsDashboardTopProductsLoading] = useState(true);
  const [topTenBestProducts, setTopTenBestProducts] = useState([]);
  const [topTenWorstProducts, setTopTenWorstProducts] = useState([]);
  const [topFlopColumns, setTopFlopColumns] = useState([]);

  /* USE EFFECT */

  useEffect(() => {
    setTopFlopColumns(dashboardColumns.getTopFlopColumns(useTtc));

    (async () => {
      if (isEmpty(stores)) {
        return;
      }

      setIsDashboardTopProductsLoading(true);

      try {
        const selectedStoreIds = stores.map(({ id }) => id);

        const formattedStartDate = startDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
        const formattedEndDate = endDate
          .endOf('week')
          .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

        // Product Mix Past data cannot be cached since route does not return weekly data per Store
        const pastMix = await forecastService.getPastProductMix(
          selectedStoreIds,
          formattedStartDate,
          formattedEndDate,
        );

        const mergedData = Object.values(pastMix.productsByCategory).reduce(
          (acc, { nestedData }) => {
            const formattedData = nestedData.reduce((acc, product) => {
              if (product.priceWithTaxes !== 0) {
                acc.push({ ...product, ...product.stats });
              }

              return acc;
            }, []);

            acc = acc.concat(formattedData);

            return acc;
          },
          [],
        );

        const sortedData = sortBy(mergedData, useTtc ? 'ca' : 'turnoverExcludingTaxes');

        const reversedData = reverse(clone(sortedData));

        setTopTenBestProducts(reversedData.slice(0, 10));

        setTopTenWorstProducts(sortedData.slice(0, 10));
      } catch {
        setTopTenBestProducts([]);
        setTopTenWorstProducts([]);
        showErrorMessage(i18next.t('DASHBOARD.FOODCOST.GETTING_DASHBOARD_DATA_ERROR'));
      } finally {
        setIsDashboardTopProductsLoading(false);
      }
    })();
  }, [stores, startDate, endDate]);

  return (
    <ScrollableWhiteCard
      className="top-flop-products"
      isLoading={isDashboardTopProductsLoading}
      renderContent={
        !isEmpty(
          selectedMetric === PRODUCT_RANKING_CHOICES[0] ? topTenBestProducts : topTenWorstProducts,
        ) ? (
          <SimpleTableView
            className={'top-flop-products-simple-tableview'}
            columns={topFlopColumns}
            data={
              selectedMetric === PRODUCT_RANKING_CHOICES[0]
                ? topTenBestProducts
                : topTenWorstProducts
            }
          />
        ) : (
          <EmptyState
            backgroundColor={theme.colors.white}
            label={i18next.t('DASHBOARD.FOODCOST.EMPTY_STATE_LABEL')}
          />
        )
      }
      renderHeader={
        <HeaderContainer>
          <TitleContainer>
            <Tooltip
              renderTooltipTrigger={() => (
                <TooltipIcon src={'/images/inpulse/info-dmgrey-small.svg'} />
              )}
              text={i18next.t('DASHBOARD.FOODCOST.TOP_FLOP_TITLE_TOOLTIP')}
              displayBigger
            />
            <Title>{i18next.t('DASHBOARD.FOODCOST.TOP_FLOP_TITLE')}</Title>
          </TitleContainer>
          <ToggleButton
            choices={PRODUCT_RANKING_CHOICES}
            customStyle={{
              height: '24px',
              width: '96px',
              font: theme.fonts.textSmallWeight700Height16Bold,
            }}
            isDisabled={false}
            selectedChoice={selectedMetric}
            setSelectedChoice={setSelectedMetric}
          />
        </HeaderContainer>
      }
    ></ScrollableWhiteCard>
  );
};

export default TopFlopProductsTable;
