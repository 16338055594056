import { ingredient as ingredientService } from '@services/ingredient';
import { supplierProduct as supplierProductService } from '@services/supplierProduct';
import { supplier as supplierService } from '@services/supplier';

/**********************/
/* Fetch Data Methods */
/**********************/

/**
 * Retrieve the information related to ingredient associated to the given ingredientId
 *
 * @param {String} ingredientId          - The ingredient id on which retrieve the data
 * @param {Function} onIngredientChange  - Method to set the local state of the ingredient
 *
 * @returns {void}
 */
export const fetchIngredient = async (ingredientId) =>
  ingredientService.getIngredientById(ingredientId);

/**
 * Retrieve the information related to the suppliers associated to the given clientId
 *
 * @param {String} clientId             - The clientId id on which retrieve the suppliers linked
 * @param {Function} onSuppliersChange  - Method to set the local state of the suppliers
 *
 * @returns {void}
 */
export const fetchSuppliersOfClient = async (clientId) =>
  supplierService.getSuppliersOfClient(clientId, false);

/**
 * Retrieve the information related to supplierProducts associated to the given supplierIds
 *
 * @param {String} supplierIds                          - The supplierIds linked to the client
 *
 * @returns {Promise<SupplierProduct[]>}
 */

export const fetchSuppliersProductsAvailable = async (clientId) => {
  try {
    const { supplierProducts } = await supplierProductService.getSupplierProductsGroupedByProperty(
      clientId,
      null,
      true,
    );

    return supplierProducts;
  } catch (err) {
    return [];
  }
};

/**
 * Retrieve the information related to the cost of the ingredient depending on the given supplierProductIds
 *
 * @param {String} supplierIds          - The supplierIds linked to the client
 * @param {Array} supplierProductIds    - The ids of the supplierProducts already linked to the ingredient
 *
 * @returns {number}
 */
export const fetchIngredientCostFromSupplierList = async (supplierProductIds, ingredientUnit) => {
  const { cost } = await ingredientService.getIngredientCostFromSupplierProductIds(
    ingredientUnit,
    supplierProductIds,
  );

  return cost;
};
