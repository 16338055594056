import React from 'react';

import PropTypes from 'prop-types';

import { ContentContainer } from './styledComponents';

export const FullScreenModalContent = (props) => {
  const { headerHeight } = props;
  return <ContentContainer headerHeight={headerHeight}>{props.children}</ContentContainer>;
};

FullScreenModalContent.propTypes = {
  headerHeight: PropTypes.string,
};

FullScreenModalContent.defaultProps = {
  headerHeight: '88px',
};

// For easy import
import FullScreenModalContentInfos from './ContentSelectorsAndInfos/ContentInfo';
import FullScreenModalContentSelectors from './ContentSelectorsAndInfos/ContentSelectors';
import FullScreenModalContentSelectorsAndInfos from './ContentSelectorsAndInfos';

export {
  FullScreenModalContentSelectorsAndInfos,
  FullScreenModalContentInfos,
  FullScreenModalContentSelectors,
};
