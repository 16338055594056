import axios from '../core/http';

import { config } from '../config';

const baseApiUrl = config.baseApiUrl;

export function getChannels() {
  return axios
    .get(baseApiUrl + '/admin/channels', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export default {
  getChannels,
};
