import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  row-gap: 8px;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  padding: 0px;

  position: relative;
`;

export const ListViewCellContent = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: left;
  align-items: left;

  flex-direction: column;
  row-gap: 0px;
`;

export const ContainerEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
