import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showConfirmationMessage, showErrorMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getIsCentralMode } from '@commons/utils/localStorage';

import { getCentralKitchenStores, getSalesPointStores } from '@selectors/stores';
import { getClientInfo } from '@selectors/client';

import orderService from '@services/order';

import { getCreateInitialInventoryModalConfig } from './modalConfiguration';

const CreateInitialInventoryModal = (props) => {
  const {
    handleCreateInitialInventory,
    resetCreateInitialInventoryModal,
    openGenericModal,
    refreshGenericModal,
    client: { storeName },
    stores,
    modal,
    showMessage,
    showErrorMessage,
  } = props;

  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [focusedMonth, setFocusedMonth] = useState(moment());
  const [forbiddenDates, setForbiddenDates] = useState([]);

  const [translatedStoreName, setTranslatedStoreName] = useState(null);

  const getDatesWithInventoriesForMonth = () => {
    const startDate = moment(focusedMonth)
      .startOf('month')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
    const endDate = moment(focusedMonth)
      .endOf('month')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    (async function call() {
      try {
        const selectedStoresIds = selectedStores.map((store) => store.id);

        const { dates } = await orderService.getInventoryForStores(
          selectedStoresIds,
          startDate,
          endDate,
          showMessage,
          'stock',
        );

        const storesInventoriesDates = dates.map((date) => moment(date));

        setForbiddenDates(storesInventoriesDates);
      } catch (err) {
        showErrorMessage(i18next.t('STOCKS.STOCKS.LOADING_FAILURE'));
      }
    })();
  };

  useEffect(() => {
    const storeNameTranslation = getClientStoreNameTranslation(storeName, true);
    setTranslatedStoreName(storeNameTranslation);
  }, []);

  useEffect(() => {
    setSelectedDate(null);
  }, [selectedStores]);

  useEffect(() => {
    if (!_.isEmpty(selectedStores)) {
      getDatesWithInventoriesForMonth();
    }
  }, [selectedStores, focusedMonth]);

  useEffect(() => {
    const isModalOpened = _.get(modal, 'GenericModalBool', false);

    const params = getCreateInitialInventoryModalConfig({
      handleCreateInitialInventory,
      resetCreateInitialInventoryModal,
      stores,
      focusedMonth,
      setFocusedMonth,
      selectedStores,
      setSelectedStores,
      selectedDate,
      setSelectedDate,
      forbiddenDates,
      translatedStoreName,
    });

    if (isModalOpened) {
      refreshGenericModal(params);
      return;
    }

    openGenericModal(params);
  }, [selectedStores, selectedDate, focusedMonth, forbiddenDates, translatedStoreName]);

  return <div />;
};

const mapStateToProps = (state) => ({
  stores: getIsCentralMode()
    ? getCentralKitchenStores(state.baseReducer.activeStores)
    : getSalesPointStores(state.baseReducer.activeStores),
  modal: state.modalReducer,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

CreateInitialInventoryModal.propTypes = {
  handleCreateInitialInventory: PropTypes.func, // passed by InventoryListView.js, to perform the API call
  resetCreateInitialInventoryModal: PropTypes.func, // passed by InventoryListView.js, to reset its state
  openGenericModal: PropTypes.func, // passed by redux
  refreshGenericModal: PropTypes.func, // passed by redux
  stores: PropTypes.array, // passed by redux
  modal: PropTypes.array, // passed by redux
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateInitialInventoryModal);
