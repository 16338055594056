import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  position: relative;

  background: ${(props) => props.theme.colors.greys.lighter};

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ListContainer = styled.div`
  flex: 1;
  width: 100%;

  overflow-y: hidden;
  position: relative;

  > div > div:last-child {
    height: calc(100% - 32px);
  }
`;

export const Item = styled.div`
  width: 40px;
  height: 30px;
`;

export const Label = styled.div`
  width: fit-content;
  padding: 5px 10px;
  height: 100%;
  text-align: center;
  background: ${(props) => props.orange && '#fff0cb'};
  border-radius: 4px;
`;

export const Icon = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-image: url('/images/icon-stock.svg');
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    background: #e7efff;
    background-image: url('/images/icon-stock.svg');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 3px;
    cursor: pointer;
  }
`;

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 24px 0px 24px;
`;

export const StockModal = styled.div`
  min-width: 375px;
`;

export const Stock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const Title = styled.div`
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  line-height: 16px;
  font: ${(props) => props.theme.fonts?.h2};
`;

export const StockValue = styled.div`
  display: flex;

  font-size: 24px;
  text-align: center;
  justify-content: center;
  font-weight: 600;
  line-height: 24px;
  font: ${(props) => props.theme.fonts?.h1};
`;

export const StockDate = styled.div`
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  line-height: 12px;
  margin-top: 10px;
  font: ${(props) => props.theme.fonts?.textSmall};
`;

export const StockStats = styled.div`
  padding: 0 10px;
`;

/** NestedList Rows */
export const TextWithTooltipButtonContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
`;
