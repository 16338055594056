import React from 'react';
import styled, { css } from 'styled-components';

const MediumModalContainer = ({
  component: Component,
  sizeModal = 'medium',
  customStyle = {},
  closeModal,
  deactivateBackgroundClick = false,
  ...rest
}) => (
  <div className={rest.className}>
    <div
      className="modal-background"
      onClick={deactivateBackgroundClick ? () => false : closeModal}
    ></div>
    <div className="modal-container">
      <div className={`medium-modal-main-container ${sizeModal}`} style={customStyle}>
        <Component closeModal={closeModal} {...rest} />
      </div>
    </div>
  </div>
);

const StyledMediumModalContainer = styled(MediumModalContainer)`
  .modal-background {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0px;

    background-color: ${(props) => props.theme.colors.greys.darkest};
    opacity: 64%;
    overflow: hidden;
    z-index: 5;
  }

  & .modal-container {
    height: 100%;
    width: 100%;
    min-width: 540px;

    padding: 48px;

    position: absolute;
    z-index: 10;
  }

  .medium-modal-main-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: ${(props) => props.theme.colors?.greys.lighter};
    border-radius: 8px;

    &.small {
      ${(props) =>
        props.theme.fonts
          ? css`
              width: 360px;
              height: auto;
            `
          : css`
              width: 50%;
              height: auto;
            `}
      max-width: 500px;
      margin-top: 15%;
      min-width: 450px;
      margin-left: calc(50% - 250px);
      overflow: hidden;

      @media (max-width: 1366px) {
        margin-top: 17% !important;
        margin-left: calc(50% - 200px) !important;
      }
    }

    &.fit-content {
      width: fit-content;
      height: auto;
      margin-top: 15%;
      min-width: 0;
      margin-left: calc(50% - 200px);
    }
  }
`;

export default StyledMediumModalContainer;
