import i18next from 'i18next';
import React from 'react';
import styled, { css } from 'styled-components';

import { InpulseLabel } from '@commons/DeepsightComponents';
import { numberToFixed } from '@commons/utils/format';
import DisplayNumber from '@commons/DisplayNumber';

import {
  tooltipByStoreAnalyticsTurnover,
  tooltipByStoreAnalyticsUnit,
} from '../../constants/tooltipText';

const LabelContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Label = styled.div`
  width: fit-content;
  height: 20px;

  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  color: #000000;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${(props) =>
    props.red &&
    css`
      color: #ef252a;
      font-weight: 700;
    `}

  ${(props) =>
    props.orange &&
    css`
      color: #ff9c40;
      font-weight: 700;
    `}
`;

const getColumnsTable = ({ metric, currency, clientStoreName }) => {
  const columns = [
    {
      id: 1,
      type: 'string',
      propertyKey: 'storeName',
      name: clientStoreName || '',
    },
    {
      id: 2,
      type: 'string',
      propertyKey: 'brandName',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_BRAND'),
    },
    {
      id: 3,
      decimals: 0,
      propertyKey: metric === 'turnover' ? 'forecastedTurnover' : 'forecastedQuantity',
      type: metric === 'turnover' ? 'currency' : 'numeric',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_FORECAST'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        metric === 'turnover'
          ? tooltipByStoreAnalyticsTurnover.forecastedTurnover
          : tooltipByStoreAnalyticsUnit.forecastedQuantity,
      ),
      render: (item) => (
        <LabelContainer>
          <DisplayNumber
            displayCurrencyCode={metric === 'turnover'}
            number={item}
            withoutDecimals={true}
          />
        </LabelContainer>
      ),
    },
    {
      id: 4,
      decimals: 0,
      propertyKey: metric === 'turnover' ? 'stockedTurnover' : 'stockedQuantity',
      type: metric === 'turnover' ? 'currency' : 'numeric',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_STOCKS'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        metric === 'turnover'
          ? tooltipByStoreAnalyticsTurnover.stockedTurnover
          : tooltipByStoreAnalyticsUnit.stockedQuantity,
      ),
      render: (item) => (
        <LabelContainer>
          <DisplayNumber
            displayCurrencyCode={metric === 'turnover'}
            number={item}
            withoutDecimals={true}
          />
        </LabelContainer>
      ),
    },
    {
      id: 5,
      decimals: 0,
      propertyKey: metric === 'turnover' ? 'toProduceTurnover' : 'toProduceQuantity',
      type: metric === 'turnover' ? 'currency' : 'numeric',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_TO_PRODUCE'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        metric === 'turnover'
          ? tooltipByStoreAnalyticsTurnover.toProduceTurnover
          : tooltipByStoreAnalyticsUnit.toProduceQuantity,
      ),
      render: (item) => (
        <LabelContainer>
          <DisplayNumber
            displayCurrencyCode={metric === 'turnover'}
            number={item}
            withoutDecimals={true}
          />
        </LabelContainer>
      ),
    },
    {
      id: 6,
      decimals: 0,
      propertyKey: metric === 'turnover' ? 'producedTurnover' : 'producedQuantity',
      type: metric === 'turnover' ? 'currency' : 'numeric',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCED'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        metric === 'turnover'
          ? tooltipByStoreAnalyticsTurnover.producedTurnover
          : tooltipByStoreAnalyticsUnit.producedQuantity,
      ),
      render: (item) => (
        <LabelContainer>
          <DisplayNumber
            displayCurrencyCode={metric === 'turnover'}
            number={item}
            withoutDecimals={true}
          />
        </LabelContainer>
      ),
    },
    {
      id: 7,
      decimals: 0,
      propertyKey: metric === 'turnover' ? 'turnoverProductionRatio' : 'quantityProductionRatio',
      type: 'percentage',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCTION_RATIO'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        metric === 'turnover'
          ? tooltipByStoreAnalyticsTurnover.turnoverProductionRatio
          : tooltipByStoreAnalyticsUnit.quantityProductionRatio,
      ),
      render: (item) => {
        const valueToDisplay = numberToFixed(item, 0, '-', '%');

        if (item == null) {
          return (
            <LabelContainer>
              <Label>-</Label>
            </LabelContainer>
          );
        }

        if (item < 75 || item >= 125) {
          return (
            <LabelContainer>
              <InpulseLabel color={'red'} text={valueToDisplay} type={'secondary'} />
            </LabelContainer>
          );
        }

        if ((item >= 75 && item < 90) || (item > 110 && item < 125)) {
          return (
            <LabelContainer>
              <InpulseLabel color={'orange'} text={valueToDisplay} type={'secondary'} />
            </LabelContainer>
          );
        }

        return (
          <LabelContainer>
            <Label>{valueToDisplay}</Label>
          </LabelContainer>
        );
      },
    },
    {
      id: 8,
      decimals: 0,
      propertyKey: metric === 'turnover' ? 'loss' : 'lossQuantity',
      type: metric === 'turnover' ? 'currency' : 'numeric',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        metric === 'turnover'
          ? tooltipByStoreAnalyticsTurnover.loss
          : tooltipByStoreAnalyticsUnit.lossQuantity,
      ),
      render: (item) => (
        <LabelContainer>
          <DisplayNumber
            displayCurrencyCode={metric === 'turnover'}
            number={item}
            withoutDecimals={true}
          />
        </LabelContainer>
      ),
    },
    {
      id: 9,
      decimals: 0,
      propertyKey: metric === 'turnover' ? 'productionWasteRate' : 'quantityProductionWasteRate',
      type: 'percentage',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS_RATIO'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        metric === 'turnover'
          ? tooltipByStoreAnalyticsTurnover.productionWasteRate
          : tooltipByStoreAnalyticsUnit.quantityProductionWasteRate,
      ),
      render: (item) => {
        const valueToDisplay = numberToFixed(item, 0, '-', '%');

        return (
          <LabelContainer>
            <Label>{valueToDisplay}</Label>
          </LabelContainer>
        );
      },
    },
    {
      id: 10,
      decimals: 0,
      propertyKey: metric === 'turnover' ? 'realTurnover' : 'realSales',
      type: metric === 'turnover' ? 'currency' : 'numeric',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_SALES'),
      filterType: 'numeric',
      tooltipText: i18next.t(
        metric === 'turnover'
          ? tooltipByStoreAnalyticsTurnover.turnover
          : tooltipByStoreAnalyticsUnit.sales,
        { currency: currency.alphabeticCode },
      ),
      render: (item) => (
        <LabelContainer>
          <DisplayNumber
            displayCurrencyCode={metric === 'turnover'}
            number={item}
            withoutDecimals={true}
          />
        </LabelContainer>
      ),
    },
  ];

  return columns;
};

export default getColumnsTable;
