import PropTypes from 'prop-types';
import React from 'react';

import FiltersModal from '../DeepsightAnalyticsHeaders/components/DeepsightFiltersButton/components/FiltersModal';

import { FiltersButton } from './components/FiltersButton';

import { Container } from './styledComponents';

/**
 * The component to use whenever filters have to be added to a page
 *
 * Usage example in: StocksAnalyticsCurrent
 */
export const Filters = (props) => {
  const { filtersButtonState, filtersModalParams, filterText, isDisabled, customWidth } = props;

  return (
    <Container>
      <FiltersButton
        customWidth={customWidth}
        filtersButtonState={filtersButtonState}
        filterText={filterText}
        isDisabled={isDisabled}
      />
      {filtersButtonState.filtersModalOpen && (
        <FiltersModal
          params={filtersModalParams}
          setFiltersModalOpen={filtersButtonState.setFiltersModalOpen}
          setIsFilterActive={filtersButtonState.setAdvancedFiltersApplied}
        />
      )}
    </Container>
  );
};

Filters.propTypes = {
  // filtersButtonState must be the result of a call to usefiltersButtonState
  filtersButtonState: PropTypes.shape({
    filtersModalOpen: PropTypes.bool.isRequired,
    setFiltersModalOpen: PropTypes.func.isRequired,
    advancedFiltersApplied: PropTypes.bool.isRequired,
    setAdvancedFiltersApplied: PropTypes.func.isRequired,
  }).isRequired,
  filtersModalParams: PropTypes.any,
  filterText: PropTypes.string,
};
