import { StyleSheet } from '@react-pdf/renderer';

import { getTheme } from '@commons/utils/theme';

const theme = getTheme();

export const pageStyle = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    paddingTop: 20,
    paddingBottom: 48,
  },
});

export const headerStyle = StyleSheet.create({
  header: {
    paddingHorizontal: 16,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  clientPicture: {
    display: 'block',
    width: 32,
    height: 32,
  },
  subHeader: {
    backgroundColor: '#FAFAFA',
    padding: '12',
    margin: '0 12 0 12',
  },
  imageContainer: {
    height: 32,
    width: 32,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    borderRadius: 50,
  },
  imageBorderContainer: {
    height: 34,
    width: 34,
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    borderBottomLeftRadius: 50,
    border: '1px solid #E2E2E2',
    marginRight: 16,
  },
  titleContainer: {
    height: 34,
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 8,
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontFamily: 'Inter',
  },
  subTitle: {
    fontSize: 8,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontFamily: 'Inter',
  },
  gap8px: {
    margin: '8 0 0 0',
  },
  conventionAndDate: {
    fontSize: 6,
    fontStyle: 'normal',
    fontFamily: 'Inter',
  },
});

export const sharedContentStyle = StyleSheet.create({
  contentContainer: {
    paddingTop: 8,
    marginBottom: 16,
  },
  list: {
    opacity: 1,
    paddingTop: 4,
    paddingBottom: 12,
    paddingHorizontal: 12,
  },
  listTitle: {
    fontSize: 8,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    backgroundColor: theme.colors.greys.light,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 8,
    borderBottom: '1px solid #979797',
  },
  listColumns: {
    paddingLeft: 8,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.colors.greys.light,
    opacity: 0.16,
  },
  borderSide: {
    height: 19,
    flexDirection: 'column',
    borderLeft: '1px solid #313131',
  },
  colunmTextStock: {
    paddingBottom: 5,
    paddingTop: 5,
    marginLeft: 4,
    width: 10,
    fontSize: 7,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    opacity: 1,
  },
  lineContainerEvenWithTopBorder: {
    borderTop: '1px solid #E2E2E2',
    display: 'flex',
    flexDirection: 'row',
  },
  lineContainerOddWithTopBorder: {
    borderTop: '1px solid #E2E2E2',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.colors.greys.lighter,
  },
  lineContainerEvenWithoutTopBorder: {
    display: 'flex',
    flexDirection: 'row',
  },
  lineContainerOddWithoutTopBorder: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.colors.greys.lighter,
  },
  borderEndOfCategory: {
    height: 1,
    flexDirection: 'row',
    borderBottom: '1px solid #E2E2E2',
  },
  borderEndOfCategory: {
    height: 1,
    flexDirection: 'row',
    borderBottom: '1px solid #E2E2E2',
  },
  lineText: {
    fontSize: 6,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    opacity: 1,
    paddingVertical: 3,
  },
  numberLineText: {
    fontSize: 6,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    opacity: 1,
    margin: '8 0 0 0',
    paddingLeft: 8,
  },
});

export const supplierProductContentStyle = StyleSheet.create({
  colunmTextSKU: {
    paddingBottom: 5,
    paddingTop: 5,
    width: 68,
    fontSize: 7,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    opacity: 1,
  },
  colunmTextName: {
    paddingBottom: 5,
    paddingTop: 5,
    width: 197,
    marginLeft: 4,
    fontSize: 7,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    opacity: 1,
  },
  colunmTextSupplier: {
    paddingBottom: 5,
    paddingTop: 5,
    marginLeft: 4,
    width: 74.5,
    fontSize: 7,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    opacity: 1,
  },
  colunmTextPrice: {
    paddingBottom: 5,
    paddingTop: 5,
    marginLeft: 4,
    width: 74.5,
    fontSize: 7,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    opacity: 1,
  },
  colunmTextCond: {
    paddingBottom: 5,
    paddingTop: 5,
    marginLeft: 4,
    width: 74.5,
    fontSize: 7,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    opacity: 1,
  },
  lineTextContainerSKU: {
    width: 76,
    height: 16,
    paddingLeft: 8,
    justifyContent: 'center',
    flexShrink: 1,
  },
  lineTextContainerName: {
    width: 202,
    height: 16,
    paddingLeft: 4,
    borderLeft: '1px solid #E2E2E2',
    justifyContent: 'center',
    flexShrink: 1,
  },
  lineTextContainerSupplier: {
    width: 79.5,
    height: 16,
    paddingLeft: 4,
    borderLeft: '1px solid #E2E2E2',
    justifyContent: 'center',
  },
  lineTextContainerPrice: {
    width: 79.5,
    height: 16,
    paddingLeft: 4,
    borderLeft: '1px solid #E2E2E2',
    justifyContent: 'center',
  },
  lineTextContainerCond: {
    width: 79.5,
    height: 16,
    paddingLeft: 4,
    borderLeft: '1px solid #E2E2E2',
    justifyContent: 'center',
  },
  lineTextContainerStock: {
    width: 55,
    height: 16,
    paddingLeft: 2,
    paddingRight: 10,
    borderLeft: '1px solid #E2E2E2',
    justifyContent: 'center',
  },
});

export const recipeContentStyle = StyleSheet.create({
  colunmTextName: {
    paddingBottom: 5,
    paddingTop: 5,
    width: 265,
    fontSize: 7,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    opacity: 1,
  },
  colunmTextBrand: {
    paddingBottom: 5,
    paddingTop: 5,
    marginLeft: 4,
    width: 74.5,
    fontSize: 7,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    opacity: 1,
  },
  colunmTextCost: {
    paddingBottom: 5,
    paddingTop: 5,
    marginLeft: 4,
    width: 74.5,
    fontSize: 7,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    opacity: 1,
  },
  colunmTextUnit: {
    paddingBottom: 5,
    paddingTop: 5,
    marginLeft: 4,
    width: 74.5,
    fontSize: 7,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    opacity: 1,
  },
  lineTextNameContainer: {
    width: 273,
    height: 16,
    paddingLeft: 8,
    justifyContent: 'center',
  },
  lineTextBrandContainer: {
    width: 79.5,
    height: 16,
    paddingLeft: 4,
    borderLeft: '1px solid #E2E2E2',
    justifyContent: 'center',
  },
  lineTextCostContainer: {
    width: 79.5,
    height: 16,
    paddingLeft: 4,
    borderLeft: '1px solid #E2E2E2',
    justifyContent: 'center',
  },
  lineTextUnitContainer: {
    width: 79.5,
    height: 16,
    paddingLeft: 4,
    borderLeft: '1px solid #E2E2E2',
    justifyContent: 'center',
  },
  lineTextContainerStock: {
    width: 59.5,
    height: 16,
    paddingLeft: 2,
    paddingRight: 10,
    borderLeft: '1px solid #E2E2E2',
    justifyContent: 'center',
  },
});

export const totalStyle = StyleSheet.create({
  globalContainer: {
    paddingHorizontal: 6,
  },
  totalTitleContainer: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    backgroundColor: theme.colors.greys.light,
    margin: '6 6 0 6',
    borderBottom: '1px solid #979797',
  },
  totalContainer: {
    backgroundColor: theme.colors.greys.lighter,
    margin: '0 6 13 6',
    paddingBottom: 7,
  },
});

export const footerStyle = StyleSheet.create({
  footerContainer: {
    alignSelf: 'end',
    paddingHorizontal: 16,
    position: 'fixed',
    bottom: '16',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 32,
    left: 0,
    right: 32,
    textAlign: 'right',
    color: '#000000',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  timestamp: {
    position: 'absolute',
    fontSize: 8,
    bottom: 32,
    left: 32,
    right: 32,
    textAlign: 'center',
    color: '#000000',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  clientPicture: {
    objectFit: 'contain',
    'object-position': '50% 50%',
    display: 'block',
    height: 'auto',
    position: 'absolute',
  },
  imageContainer: {
    height: 32,
    width: 48,
    bottom: 20,
    left: 32,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
});
