import styled, { css } from 'styled-components';

export const FirstLetter = styled.span`
  ${(props) =>
    props.required &&
    css`
      color: ${props.theme.colors.infoRed};
    `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font: ${(props) => props.theme.fonts?.textSmall};
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};

  label {
    margin-bottom: 8px;
    font: ${(props) => props.theme.fonts?.textSmall};
    margin-bottom: 0px !important;
    color: ${(props) =>
      props.disabled ? props.theme.colors?.greys.dark : props.theme.colors?.greys.darkest};
  }

  textarea {
    width: 100%;
    min-height: 40px;
    -moz-appearance: ${(props) => (props.appearance === 'number' ? undefined : 'textfield')};
    padding: 12px 16px;

    resize: ${(props) => props.resize || 'none'};

    color: ${(props) => props.theme.colors.greys.darkest};
    background-color: ${(props) => props.theme.colors.greys.lightest};
    border: ${(props) => ` 1px solid ${props.theme.colors.greys.light}`};
    border-radius: 8px;

    ${(props) =>
      props.width &&
      css`
        width: ${props.width};
      `}

    &:hover {
      background-color: ${(props) => props.theme.colors.greys.lighter};
    }

    &:focus,
    &:focus-visible {
      outline: none;
      color: ${(props) => props.theme.colors.greys.darkest};
      background-color: ${(props) => props.theme.colors.greys.lightest};
      border-color: ${(props) => props.theme.colors.greys.darkest};
    }

    &:invalid {
      color: ${(props) => props.theme.colors.infoRed};
      border-color: ${(props) => props.theme.colors.infoRed};
    }

    &:disabled {
      color: ${(props) => props.theme.colors.greys.darkest};
      border-color: ${(props) => props.theme.colors.greys.light};
      background-color: ${(props) => props.theme.colors.greys.light};
      cursor: not-allowed;
    }
  }

  textarea::placeholder {
    color: ${(props) => props.theme.colors.greys.dark};
    font: ${(props) => props.theme.fonts.textSmall};
  }

  textarea:disabled::placeholder {
    color: ${(props) => props.theme.colors.greys.lightest};
  }
`;

export const WrapperLabel = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 8px;
`;
