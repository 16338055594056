import i18next from 'i18next';
import React from 'react';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
  GENERIC_MODAL_DELETE_BUTTON,
  GENERIC_MODAL_HIDE_BUTTON,
  GENERIC_MODAL_SHOW_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { REGEX_MATCH_EMAIL_INSENSITIVE } from '@commons/utils/regex';

import { ENUM_WHEN_IS_SENDING_MAIL } from '../ModalComponents/SendInvitationModal/index';
import { WarningModalContentContainer } from '../styledComponents';
import SendInvitationModal from '../ModalComponents/SendInvitationModal';
import StoreAssociationModal from '../ModalComponents/StoreAssociationModal';
import UserInformationModal from '../ModalComponents/UserInformationModal';

import { isUserNotInvited } from './status';

export const ENUM_WARNING_MODAL_TYPE = {
  DELETE_USERS: 'deleteMany',
  SEND_EMAIL: 'sendEmail',
  HANDLE_HAS_FORECASTS: 'handleHasForecasts',
  SHOW_PAYMENT_BANNER: 'SHOW_PAYMENT_BANNER',
  HIDE_PAYMENT_BANNER: 'HIDE_PAYMENT_BANNER',
};

export const ENUM_MODAL_STEP_NAME = {
  USER_CREATION: 'userCreation',
  USER_STORE_ASSOCIATION: 'userStoreAssociation',
  SENDING_EMAIL: 'sendingEmail',
};

const _getStepConfig = (currentStep) => {
  const steps = Object.values(ENUM_MODAL_STEP_NAME);

  return {
    total: steps.length,
    current: steps.findIndex((step) => step === currentStep) + 1,
  };
};

export const getModalConfig = ({
  currentStep,
  roles,
  stores,
  userInformations,
  isEmailAvailable,
  handleSwitchStep,
  handleStoreSelection,
  updateUserInformations,
  verifyEmailAvailability,
  handleUserCreationModalSave,
  hasMultipleBrands,
  brands,
  setIsSwitchingStep,
  cleanUserInformation,
  handleEmailChoiceChange,
  countSelectedStores,
  catalogs,
}) => {
  const stepConfig = _getStepConfig(currentStep);

  if (currentStep === ENUM_MODAL_STEP_NAME.USER_CREATION) {
    const shouldDisableSaveButton =
      !userInformations.email ||
      !userInformations.email.match(REGEX_MATCH_EMAIL_INSENSITIVE) ||
      !userInformations.roleId;

    if (currentStep === ENUM_MODAL_STEP_NAME.USER_CREATION) {
      return {
        type: 'actionWhite',
        isFullscreen: true,
        step: stepConfig,
        component: UserInformationModal,
        title: i18next.t('BACKOFFICE.TEAM.NEW_USER_CREATION'),
        icon: '/images/inpulse/add-black-small.svg',
        itemsCount: null,
        handleCloseCleanUp: cleanUserInformation,
        data: {
          roles,
          isEmailAvailable,
          userInformations,
          updateUserInformations,
          catalogs,
        },
        actions: [
          {
            key: 0,
            color: 'inpulse-outline',
            label: i18next.t('GENERAL.CANCEL'),
            icon: '/images/inpulse/close-black-small.svg',
            handleClick: () => cleanUserInformation(),
          },
          {
            key: 1,
            color: 'inpulse-default',
            label: i18next.t('GENERAL.NEXT'),
            icon: '/images/inpulse/arrow-right-white.svg',
            preventClosing: true,
            isDisabled: shouldDisableSaveButton,
            handleClick: () => {
              verifyEmailAvailability(userInformations.email);
              setIsSwitchingStep(true);
            },
          },
        ],
      };
    }
  }

  if (currentStep === ENUM_MODAL_STEP_NAME.USER_STORE_ASSOCIATION) {
    return {
      type: 'actionWhite',
      isFullscreen: true,
      step: stepConfig,
      component: StoreAssociationModal,
      title: i18next.t('BACKOFFICE.TEAM.ASSOCIATE_USER_TO_STORES'),
      icon: '/images/inpulse/inpulse-link-black-small.svg',
      itemsCount: countSelectedStores,
      handleCloseCleanUp: cleanUserInformation,
      customPadding: '24px 24px 0px 24px',
      data: {
        stores,
        handleStoreSelection,
        hasMultipleBrands,
        brands,
        userInformations,
      },
      actions: [
        {
          key: 0,
          color: 'inpulse-outline',
          label: i18next.t('GENERAL.BACK'),
          icon: '/images/inpulse/arrow-left-ip-black.svg',
          preventClosing: true,
          handleClick: () => {
            handleSwitchStep(ENUM_MODAL_STEP_NAME.USER_CREATION);
            setIsSwitchingStep(true);
          },
        },
        {
          key: 1,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.NEXT'),
          icon: '/images/inpulse/arrow-right-white.svg',
          preventClosing: true,
          isDisabled: !userInformations.associatedStores.length,
          handleClick: () => {
            handleSwitchStep(ENUM_MODAL_STEP_NAME.SENDING_EMAIL);
            setIsSwitchingStep(true);
          },
        },
      ],
    };
  }

  if (currentStep === ENUM_MODAL_STEP_NAME.SENDING_EMAIL) {
    return {
      type: 'actionWhite',
      isFullscreen: true,
      step: stepConfig,
      component: SendInvitationModal,
      data: {
        userInformations,
        handleEmailChoiceChange,
      },
      title: i18next.t('BACKOFFICE.TEAM.SEND_EMAIL_TO_USER'),
      icon: '/images/inpulse/send-black-small.svg',
      itemsCount: null,
      handleCloseCleanUp: cleanUserInformation,
      actions: [
        {
          key: 0,
          color: 'inpulse-outline',
          label: i18next.t('GENERAL.BACK'),
          icon: '/images/inpulse/arrow-left-ip-black.svg',
          preventClosing: true,
          handleClick: () => {
            handleSwitchStep(ENUM_MODAL_STEP_NAME.USER_STORE_ASSOCIATION);
            setIsSwitchingStep(true);
          },
        },
        {
          key: 1,
          color: 'inpulse-default',
          label:
            !userInformations.isSendingEmailNow ||
            userInformations.isSendingEmailNow === ENUM_WHEN_IS_SENDING_MAIL.NOW
              ? i18next.t('GENERAL.SEND')
              : i18next.t('GENERAL.SAVE'),
          icon:
            !userInformations.isSendingEmailNow ||
            userInformations.isSendingEmailNow === ENUM_WHEN_IS_SENDING_MAIL.NOW
              ? '/images/inpulse/send-white-small.svg'
              : '/images/inpulse/save-white-small.svg',
          preventClosing: true,
          handleClick: () => {
            handleUserCreationModalSave(userInformations);
            setIsSwitchingStep(true);
          },
        },
      ],
    };
  }
};

const WarningModalContent = (props) => {
  const { content } = props;
  return <WarningModalContentContainer>{content}</WarningModalContentContainer>;
};

export const getWarningModalConfig = ({
  type,
  selectedUsers,
  inviteUser,
  deleteUsers,
  shouldActivateForecasts,
  handleHasForecasts,
  handleUserPaymentNotification,
}) => {
  const isSingleSelection = selectedUsers.length === 1;

  if (type === ENUM_WARNING_MODAL_TYPE.SEND_EMAIL) {
    return {
      type: 'warning',
      height: 'auto',

      component: WarningModalContent,
      data: {
        content: i18next.t(
          isUserNotInvited(selectedUsers[0])
            ? 'BACKOFFICE.TEAM.SEND_EMAIL_WARNING_MODAL_CONTENT'
            : 'BACKOFFICE.TEAM.RESEND_EMAIL_WARNING_MODAL_CONTENT',
          {
            email: selectedUsers[0].email,
          },
        ),
      },
      title: i18next.t(
        isUserNotInvited(selectedUsers[0])
          ? 'BACKOFFICE.TEAM.SEND_EMAIL_WARNING_MODAL_TITLE'
          : 'BACKOFFICE.TEAM.RESEND_EMAIL_WARNING_MODAL_TITLE',
      ),
      icon: '/images/inpulse/send-white-small.svg',
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          key: 1,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.SEND'),
          icon: '/images/inpulse/send-white-small.svg',
          handleClick: () => inviteUser(selectedUsers[0]),
        },
      ],
    };
  }

  if (type === ENUM_WARNING_MODAL_TYPE.EDIT_FORECASTS) {
    return {
      type: 'warning',
      height: 'auto',

      component: WarningModalContent,
      data: shouldActivateForecasts
        ? {
            content: i18next.t('BACKOFFICE.TEAM.USER_FORECASTS_ACTIVATION_MODAL_CONTENT'),
          }
        : { content: i18next.t('BACKOFFICE.TEAM.USER_FORECASTS_DEACTIVATION_MODAL_CONTENT') },

      title: shouldActivateForecasts
        ? i18next.t('BACKOFFICE.DATA.ACTIVATE_FORECAST_WHITECARD_TITLE')
        : i18next.t('BACKOFFICE.TEAM.DISABLE_FORECAST'),
      icon: '/images/inpulse/power-white-small.svg',
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: () => handleHasForecasts(selectedUsers, shouldActivateForecasts),
        },
      ],
    };
  }

  if (type === ENUM_WARNING_MODAL_TYPE.DELETE_USERS) {
    return {
      type: 'warning',
      height: 'auto',

      component: WarningModalContent,
      data: isSingleSelection
        ? {
            content: i18next.t('BACKOFFICE.TEAM.USER_DELETION_MODAL_CONTENT', {
              email: selectedUsers[0].email,
            }),
          }
        : { content: i18next.t('BACKOFFICE.TEAM.USERS_DELETION_MODAL_CONTENT') },

      title: isSingleSelection
        ? i18next.t('BACKOFFICE.TEAM.USER_DELETION_MODAL_TITLE')
        : i18next.t('GENERAL.DELETE_USERS'),
      icon: '/images/inpulse/delete-white-small.svg',
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_DELETE_BUTTON(),
          handleClick: () => deleteUsers(selectedUsers),
        },
      ],
    };
  }

  if (
    [
      ENUM_WARNING_MODAL_TYPE.SHOW_PAYMENT_BANNER,
      ENUM_WARNING_MODAL_TYPE.HIDE_PAYMENT_BANNER,
    ].includes(type)
  ) {
    return {
      type: 'warning',
      height: 'auto',

      component: WarningModalContent,
      data:
        type === ENUM_WARNING_MODAL_TYPE.SHOW_PAYMENT_BANNER
          ? {
              content: i18next.t(
                isSingleSelection
                  ? 'BACKOFFICE.TEAM.SHOW_PAYMENT_WARNING_MODAL_CONTENT_SINGLE'
                  : 'BACKOFFICE.TEAM.SHOW_PAYMENT_WARNING_MODAL_CONTENT_MULTIPLE',
              ),
            }
          : {
              content: i18next.t(
                isSingleSelection
                  ? 'BACKOFFICE.TEAM.HIDE_PAYMENT_WARNING_MODAL_CONTENT_SINGLE'
                  : 'BACKOFFICE.TEAM.HIDE_PAYMENT_WARNING_MODAL_CONTENT_MULTIPLE',
              ),
            },

      title:
        type === ENUM_WARNING_MODAL_TYPE.SHOW_PAYMENT_BANNER
          ? i18next.t('BACKOFFICE.TEAM.SHOW_PAYMENT_BANNER')
          : i18next.t('BACKOFFICE.TEAM.HIDE_PAYMENT_BANNER'),
      icon:
        type === ENUM_WARNING_MODAL_TYPE.SHOW_PAYMENT_BANNER
          ? '/images/inpulse/visibility-show-white-small.svg'
          : '/images/inpulse/visibility-hide-white-small.svg',
      actions:
        type === ENUM_WARNING_MODAL_TYPE.SHOW_PAYMENT_BANNER
          ? [
              GENERIC_MODAL_CANCEL_BUTTON(),
              {
                ...GENERIC_MODAL_SHOW_BUTTON(),
                handleClick: () => handleUserPaymentNotification(selectedUsers, type),
              },
            ]
          : [
              GENERIC_MODAL_CANCEL_BUTTON(),
              {
                ...GENERIC_MODAL_HIDE_BUTTON(),
                handleClick: () => handleUserPaymentNotification(selectedUsers, type),
              },
            ],
    };
  }
};
