import _ from 'lodash';
import i18next from 'i18next';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, Dropdown, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import { InpulseSearchBar } from '@commons/DeepsightComponents/TabletOptimized/TabletBarcodeSearchBar/TabletBarcodeSearchBar';
import DisplayNumber from '@commons/DisplayNumber';
import Text, { ENUM_FONTS } from '@commons/Text';

import { period } from '@productions/constants/period';
import { planningType as planningTypeEnum } from '@productions/constants/planning';

import { Container, Filter, StatsContainer, StatsMetric } from './styledComponents';
import RenderDatePicker from './RenderDatePicker';
import RenderInfoStats from './RenderInfoStats';

export function renderDropDownStores(stores, selectedStoreName, handleSelectedStore, readOnly) {
  const selectedStore = stores.find((store) => store.name === selectedStoreName);

  return (
    selectedStoreName && (
      <Filter className={'dropdown-stores'}>
        <Dropdown
          iconSrc={'/images/inpulse/pin-black-small.svg'}
          isDisabled={readOnly}
          isRequired={true}
          items={stores}
          searchPlaceholder={i18next.t('GENERAL.SEARCH')}
          selectedItem={selectedStore}
          width={INPUT_WIDTH.MEDIUM}
          onSelectionChange={({ name, id, closingDays, timezone }) => {
            handleSelectedStore(name, id, closingDays, timezone);
          }}
        />
      </Filter>
    )
  );
}

export function renderDropDownTimeSlots(
  timeSlots,
  selectedTimeSlot,
  handleSelectedTimeslot,
  readOnly,
) {
  return (
    <Filter className={'dropdown-timeslots'}>
      <Dropdown
        isDisabled={readOnly}
        isRequired={true}
        items={timeSlots}
        searchPlaceholder={i18next.t('GENERAL.SEARCH')}
        selectedItem={selectedTimeSlot}
        sortBy={(slotsList) => _.sortBy(slotsList, 'startHour')}
        width={INPUT_WIDTH.MEDIUM}
        onSelectionChange={(selectedItem) => handleSelectedTimeslot(selectedItem.name)}
      />
    </Filter>
  );
}

export function renderSearchBar(barcodeScannerElement, handleSelectedBarcode) {
  return (
    <Filter className={'search-bar'}>
      <InpulseSearchBar hasScanned={handleSelectedBarcode} ref={barcodeScannerElement} />
    </Filter>
  );
}

export function renderStats(
  totalByBrands,
  toProduceTurnover,
  producedTurnover,
  producedTurnoverPercentage,
  stockOrLossTurnover,
  planningType,
) {
  return (
    <StatsContainer>
      {!!totalByBrands && (
        <RenderInfoStats planningType={planningType} totalByBrands={totalByBrands} />
      )}
      {toProduceTurnover != null && (
        <StatsMetric>
          <Text font={ENUM_FONTS.TEXT_SMALL}>
            {i18next.t('PRODUCTION.PRODUCTION.HEADER_TO_PRODUCE')}
          </Text>
          <DisplayNumber
            displayCurrencyCode={true}
            font={ENUM_FONTS.TEXT_BIG_HEIGHT_24_BOLD}
            number={toProduceTurnover}
          />
        </StatsMetric>
      )}
      {producedTurnover != null && (
        <StatsMetric>
          <Text font={ENUM_FONTS.TEXT_SMALL}>
            {i18next.t('PRODUCTION.PRODUCTION.HEADER_PRODUCED')}
            {producedTurnoverPercentage > 0 ? (
              <span>({`${producedTurnoverPercentage}%`})</span>
            ) : (
              ''
            )}
          </Text>
          <DisplayNumber
            displayCurrencyCode={true}
            font={ENUM_FONTS.TEXT_BIG_HEIGHT_24_BOLD}
            number={producedTurnover}
          />
        </StatsMetric>
      )}
      {planningType !== planningTypeEnum.PRODUCTION && (
        <StatsMetric>
          <Text font={ENUM_FONTS.TEXT_SMALL}>{i18next.t('PRODUCTION.LOSSES.HEADER_TOTAL')}</Text>
          <DisplayNumber
            displayCurrencyCode={true}
            font={ENUM_FONTS.TEXT_BIG_HEIGHT_24_BOLD}
            number={stockOrLossTurnover}
          />
        </StatsMetric>
      )}
    </StatsContainer>
  );
}

export function renderPrintButton(handlePrintPlanning, readOnly) {
  return (
    <Filter className={'print'}>
      <Button
        color={'inpulse-outline'}
        handleClick={handlePrintPlanning}
        icon={'/images/inpulse/print-ip-black-small.svg'}
        isDisabled={readOnly}
        label={i18next.t('PRODUCTION.PRODUCTION.PRINT')}
      />
    </Filter>
  );
}

export const PlanningHeader = (props) => {
  const {
    totalByBrands,
    producedTurnover,
    stores,
    useCase,
    hideStats,
    timeSlots,
    stockOrLossTurnover,
    toProduceTurnover,
    printOption,
    planningDate,
    planningType,
    hideSearchBar,
    hidePrintOption,
    producedTurnoverPercentage,
    selectedTimeSlot,
    selectedStore,
    handleSelectedDate,
    handlePrintPlanning,
    handleSelectedStore,
    hideTimeSlotDropDown,
    barcodeScannerElement,
    handleSelectedBarcode,
    handleSelectedTimeslot,
    readOnly,
  } = props;

  const [datePickerFocused, setDatePickerFocused] = useState(false);

  return (
    <Container className={props.className}>
      {renderDropDownStores(stores, selectedStore.storeName, handleSelectedStore, readOnly)}

      <RenderDatePicker
        datePickerFocused={datePickerFocused}
        handleSelectedDate={handleSelectedDate}
        planningDate={planningDate}
        planningType={planningType}
        readOnly={readOnly}
        setDatePickerFocused={setDatePickerFocused}
        timezone={selectedStore.timezone}
      />

      {planningType === planningTypeEnum.PRODUCTION &&
        useCase !== period.CLOSED &&
        !hideTimeSlotDropDown &&
        renderDropDownTimeSlots(timeSlots, selectedTimeSlot, handleSelectedTimeslot, readOnly)}

      {useCase !== period.CLOSED &&
        !hideSearchBar &&
        renderSearchBar(barcodeScannerElement, handleSelectedBarcode)}

      {useCase !== period.CLOSED &&
        !hideStats &&
        renderStats(
          totalByBrands,
          toProduceTurnover,
          producedTurnover,
          producedTurnoverPercentage,
          stockOrLossTurnover,
          planningType,
        )}

      {planningType === planningTypeEnum.PRODUCTION &&
        printOption &&
        !hidePrintOption &&
        renderPrintButton(handlePrintPlanning, readOnly)}
    </Container>
  );
};

const StyledPlanningHeader = styled(PlanningHeader)`
  & .orderform-date-input-icon,
  .multiple-dropdown-icon {
    margin-left: 15px;
  }

  & .DateInput_fang {
    display: none !important;
  }

  & .DateRangePickerInput__disabled {
    background-color: lightgrey !important;
  }

  & .CalendarMonth_caption {
    color: ${(props) => props.theme.colors?.greys?.darkest} !important;
    font: ${(props) => props.theme.fonts?.h3} !important;
  }

  & .DateInput_input {
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    text-align: center !important;
    cursor: pointer;
  }

  & .DateInput_input__focused {
    border-bottom: none !important;
  }

  & .DayPicker_weekHeader_li {
    font: ${(props) => props.theme.fonts?.textMicro} !important;
  }

  & .CalendarDay {
    background: ${(props) => props.theme.colors?.greys.lightest} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__default:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__highlighted_calendar {
    background: #fff !important;
    border: 1px double #0a3eff !important;
  }

  & .CalendarDay__highlighted_calendar:hover {
    background: #e4e7e7 !important;
  }

  & .CalendarDay__selected_span {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    border: 1px double #e2e2e2 !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected,
  .CalendarDay__selected:active .CalendarDay__selected:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_start,
  .CalendarDay__selected_start:hover,
  .CalendarDay__selected_end,
  .CalendarDay__selected_end:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover {
    color: ${(props) => props.theme.colors?.greys.dark} !important;
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
  }
`;

export default StyledPlanningHeader;
