import { withTranslation } from 'react-i18next';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

const WeatherSprite = (props) => {
  const { day, weather } = props;
  let tAvg = '-',
    tMin = '-',
    tMax = '-',
    weatherIcon = '/images/icon-empty-weather.png',
    weatherType = '--',
    precip = '-',
    wind = '-',
    humidity = '-';

  let currentDayWeather = weather.filter((weatherday) =>
    moment(weatherday.timestamp).isSame(day, 'day'),
  );
  currentDayWeather = currentDayWeather.length > 0 ? currentDayWeather[0] : null;
  if (currentDayWeather) {
    tMax = Math.round(currentDayWeather.tMax, 10);
    tMin = Math.round(currentDayWeather.tMin, 10);
    tAvg = Math.round((currentDayWeather.tMax + currentDayWeather.tMin) / 2 || 0);
    if (currentDayWeather.lnkWeathericonWeatherdayrel) {
      weatherIcon = currentDayWeather.lnkWeathericonWeatherdayrel.img;
      weatherType = currentDayWeather.lnkWeathericonWeatherdayrel.description;
    }
    precip = !isNaN(parseInt(currentDayWeather.precip, 10))
      ? parseInt(currentDayWeather.precip, 10)
      : 0;
    wind = !isNaN(parseInt(currentDayWeather.windSpeed, 10))
      ? parseInt(currentDayWeather.windSpeed, 10)
      : 0;
    humidity = currentDayWeather.humidity;
  }
  const left = props.left + 'px';
  const top = props.top + 'px';

  return (
    <div
      className={props.className}
      id="weather-tooptip"
      style={{
        left: left,
        top: top,
      }}
    >
      <div className="weather-sprite-title">
        {props.t('FORECAST.TURNOVER.WEATHER_TOOLTIP_HEADER')}
      </div>
      <div className="sprite-weather-upper-part">
        <div className="sprite-weather-temperatures">
          <div className="sprite-weather-temperatures-upper">
            {props.t('FORECAST.TURNOVER.WEATHER_TOOLTIP_TEMPERATURE_DEGREE', { temperature: tAvg })}
          </div>
          <div className="sprite-weather-temperatures-lower">
            <div className="sprite-weather-temperatures-lower-child">
              <h1>
                {props.t('FORECAST.TURNOVER.WEATHER_TOOLTIP_TEMPERATURE_DEGREE', {
                  temperature: tMax,
                })}
              </h1>
              <p>{props.t('FORECAST.TURNOVER.WEATHER_TOOLTIP_TEMPERATURE_MAX_ABBREVIATION')}</p>
            </div>
            <div className="spacer"></div>
            <div className="sprite-weather-temperatures-lower-child">
              <h1>
                {props.t('FORECAST.TURNOVER.WEATHER_TOOLTIP_TEMPERATURE_DEGREE', {
                  temperature: tMin,
                })}
              </h1>
              <p>{props.t('FORECAST.TURNOVER.WEATHER_TOOLTIP_TEMPERATURE_MIN_ABBREVIATION')}</p>
            </div>
          </div>
        </div>
        <div className="sprite-weather-icon">
          <img alt={weatherType} src={weatherIcon} />
          <p>{weatherType}</p>
        </div>
      </div>
      <div className="sprite-weather-lower-part">
        <div className="sprite-weather-lower-child">
          <h1>
            {precip}
            {props.t('FORECAST.TURNOVER.WEATHER_TOOLTIP_LABEL_PRECIPITATIONS_ABBREVIATION')}
          </h1>
          <p>{props.t('FORECAST.TURNOVER.WEATHER_TOOLTIP_LABEL_PRECIPITATIONS')}</p>
        </div>
        <div className="sprite-weather-lower-spacer"></div>
        <div className="sprite-weather-lower-child">
          <h1>
            {wind}
            {props.t('FORECAST.TURNOVER.WEATHER_TOOLTIP_KILOMETER_PER_HOUR_ABBBREVIATION')}
          </h1>
          <p>{props.t('FORECAST.TURNOVER.WEATHER_TOOLTIP_LABEL_WIND')}</p>
        </div>
        <div className="sprite-weather-lower-spacer"></div>
        <div className="sprite-weather-lower-child">
          <h1>{humidity}%</h1>
          <p>{props.t('FORECAST.TURNOVER.WEATHER_TOOLTIP_LABEL_HUMIDITY')}</p>
        </div>
      </div>
    </div>
  );
};
const WeatherSpriteWithTranslation = withTranslation()(WeatherSprite);

const InpulseWeatherSprite = styled(WeatherSpriteWithTranslation)`
  height: 239px;
  width: 252px;
  border-radius: ${(props) => props.theme.borders?.radius?.weak};
  z-index: 1;
  position: absolute;
  background: ${(props) => props.theme.colors.greys.lightest};
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  .spacer {
    height: 50%;
    border: ${(props) => `1px solid ${props.theme.colors.greys.light}`};
    align-self: flex-start;
  }

  #weather-tooptip {
    opacity: 0;
    -webkit-animation: fade-in 0.2s forwards;
    -webkit-animation-delay: 0s;
    animation: fade-in 0.5s forwards;
    animation-delay: 0s;
  }

  .weather-sprite-title {
    width: 100%;
    height: 48px;
    color: ${(props) => props.theme.colors.greys.darkest};
    background: ${(props) => props.theme.colors.greys.lighter};
    font: ${(props) => props.theme.fonts?.h4};
    text-align: center;
    border-radius: 4px 4px 0 0;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sprite-weather-upper-part {
    height: 80px;
    margin-top: 1em;
    margin-bottom: 1em;
    padding-bottom: 6em;
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: ${(props) => `1px solid ${props.theme.colors.greys.light}`};
  }

  .sprite-weather-temperatures {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 99px;
    height: 70px;
  }

  .sprite-weather-temperatures-upper {
    font: ${(props) => props.theme.fonts?.kpiBig};
    color: ${(props) => props.theme.colors.greys.darkest};
    text-align: center;
  }

  .sprite-weather-temperatures-lower {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sprite-weather-temperatures-lower-child {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.greys.darker};
  }

  .sprite-weather-temperatures-lower-child > h1 {
    padding: 0;
    margin: 0;
    font: ${(props) => props.theme.fonts?.textSmall};
  }

  .sprite-weather-temperatures-lower-child > p {
    padding: 0;
    margin: 0;
    font: ${(props) => props.theme.fonts?.textMicro};
  }

  .sprite-weather-icon {
    width: 67px;
    height: 53px;
    text-align: center;
  }

  .sprite-weather-icon > p {
    font: ${(props) => props.theme.fonts?.kpiSmall};
    text-align: center;
    vertical-align: middle;
  }

  .sprite-weather-icon > img {
    height: 30px;
  }

  .sprite-weather-lower-part {
    width: 100%;
    height: 24px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sprite-weather-lower-child {
    width: 70px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .sprite-weather-lower-child > h1 {
    font: ${(props) => props.theme.fonts?.textSmall};
    margin: 0;
    padding: 0;
  }

  .sprite-weather-lower-child > p {
    color: ${(props) => props.theme.colors.greys.darker};

    font: ${(props) => props.theme.fonts?.textMicro};
    margin: 0;
    padding: 0;
  }

  .sprite-weather-lower-spacer {
    width: 1px;
    height: 20px;
    background: ${(props) => props.theme.colors.greys.ligthest};
  }
`;

export { InpulseWeatherSprite };
