import PropTypes from 'prop-types';
import React from 'react';

import { Container } from './styledComponents';

import Pill from './Pill';

const PillsSet = (props) => {
  const { items, selectedItem, setSelectedItem } = props;

  return (
    <Container>
      {items.map((item) => (
        <Pill
          count={item.count}
          isSelected={selectedItem.id === item.id}
          key={item.id}
          name={item.name}
          setSelectedItem={() => setSelectedItem(item)}
        />
      ))}
    </Container>
  );
};

PillsSet.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      count: PropTypes.number,
    }),
  ),
  selectedItem: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    count: PropTypes.number,
  }),
  setSelectedItem: PropTypes.func.isRequired,
};

export default PillsSet;
