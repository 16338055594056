import { APIcaller } from '../APIcaller';

const getAll = async () => APIcaller.apiGet(`/backoffice/inpulse-accounts`);

const getCsmManagerByClientId = async (clientId) =>
  APIcaller.apiGet(`/backoffice/clients/${clientId}/inpulse-accounts`);

const upsertCsmManagerByClientId = async (clientId, inpulseAccountId) =>
  APIcaller.apiPost(`/backoffice/clients/${clientId}/inpulse-accounts`, {
    inpulseAccountId,
  });

export default { getAll, getCsmManagerByClientId, upsertCsmManagerByClientId };
