import styled from 'styled-components';

export const CategoryContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
  margin-bottom: 24px;
`;

export const RowContainer = styled.div`
  border-radius: 8px;
  margin-bottom: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
`;

export const ColumnsContainer = styled.div`
  display: flex;
  padding: 0 16px;
  margin: 24px 0 16px 0;
`;

export const Column = styled.div`
  font: ${(props) => props.theme.fonts?.textBig} !important;
  color: ${(props) => props.theme.listView?.header.default.color} !important;
  flex: 1;
  flex-basis: ${(props) => `calc(${(100 / props.nbColumns) * props.flex}%)`};
  margin-right: 16px;
`;
