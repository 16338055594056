import i18next from 'i18next';
import moment from 'moment';

import { formatNumber } from '@commons/DisplayNumber';

import theme from '@theme';

const dateTitle = (isDateToday, graph) => {
  const dateTitle = isDateToday
    ? i18next.t('GENERAL.TODAY')
    : moment(graph.x).format('dddd Do MMMM');
  const dateTitleWithFirstLetterUppercase = dateTitle.charAt(0).toUpperCase() + dateTitle.slice(1);

  return `<div style="font: ${theme.fonts.textSmallHeight14Weight700Bold}; text-align: flex-start; color: ${theme.colors.greys.darkest}; margin-bottom: 16px;">
      ${dateTitleWithFirstLetterUppercase}
    </div>`;
};

const theoreticalStockRow = (
  theoricalStock,
  unit,
  metric,
  currency,
  shouldOnlyDisplayRealStocks = false,
) => {
  const isMetricTurnover = metric === 'turnover';
  const unitToDisplay =
    theoricalStock == null ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`;

  return `<div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light};">
      <div style="color: ${theme.colors.brand.secondaryDark}; font: ${
    theme.fonts.textMicroHeight12Weight700Bold
  }; width: 120px;">
        ${i18next.t('STOCKS.STOCKS.THEORETICAL_STOCK')}
      </div>
      <div style="color: ${theme.colors.brand.secondaryDark}; font: ${
    theme.fonts.textMicroHeight12Weight700Bold
  }; width: 96px;">
        ${
          shouldOnlyDisplayRealStocks
            ? '--'
            : `${formatNumber(theoricalStock, currency.numberDecimals)} ${unitToDisplay}`
        }
      </div>
    </div>`;
};

const realStockRowRecipe = (realStock, unit, metric, currency) => {
  const isMetricTurnover = metric === 'turnover';

  return `<div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light};">
      <div style="color: ${theme.colors.greys.dark}; font: ${
    theme.fonts.textMicroHeight12Weight700Bold
  }; width: 120px;">
        ${i18next.t('STOCKS.STOCKS.REAL_STOCK')}
      </div>
      <div style="color: ${theme.colors.greys.dark}; font: ${
    theme.fonts.textMicroHeight12Weight700Bold
  }; width: 96px;">
        ${formatNumber(realStock, currency.numberDecimals)}${
    realStock == null ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`
  }
      </div>
    </div>`;
};

const realStockRowIngredient = (graph, realStock, displayRecipeStock, unit, metric, currency) => {
  const isMetricTurnover = metric === 'turnover';

  const recipes = graph.y.inventories.recipes;
  const ingredients = graph.y.inventories.ingredients;

  return `<div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light};">
      <div style="color: ${theme.colors.greys.darker}; font: ${
    theme.fonts.textMicroHeight12Weight700Bold
  }; width: 120px;">
        ${i18next.t('STOCKS.STOCKS.REAL_STOCK')}
      </div>
      <div style="color: ${theme.colors.greys.dark}; font: ${
    theme.fonts.textMicroHeight12Weight700Bold
  }; width: 96px;">
        ${formatNumber(realStock, currency.numberDecimals)}${
    realStock == null ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`
  }
      </div>
    </div>
    <div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${
      theme.colors.greys.light
    }; padding-left: 16px;">
      <div style="color: ${theme.colors.greys.dark}; font: ${
    theme.fonts.textMicroHeight12Weight500
  }; width: 104px;">
      ${i18next.t('STOCKS.STOCKS.REAL_STOCK_INGREDIENTS')}
      </div>
      <div style="color: ${theme.colors.greys.dark}; font: ${
    theme.fonts.textMicroHeight12Weight500
  }; width: 96px;">
        ${formatNumber(ingredients, currency.numberDecimals)}${
    ingredients == null ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`
  }
      </div>
    </div>
    ${
      displayRecipeStock
        ? `<div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${
      theme.colors.greys.light
    }; padding-left: 16px;">
      <div style="color: ${theme.colors.greys.dark}; font: ${
            theme.fonts.textMicroHeight12Weight500
          }; width: 104px;">
      ${i18next.t('STOCKS.STOCKS.REAL_STOCK_RECIPES')}
      </div>
      <div style="color: ${theme.colors.greys.dark}; font: ${
            theme.fonts.textMicroHeight12Weight500
          }; width: 96px;">
        ${formatNumber(recipes, currency.numberDecimals)}${
            recipes == null ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`
          }
      </div>`
        : ''
    }
    </div>`;
};

const gapRow = (gapStock, unit, metric, currency, shouldOnlyDisplayRealStocks = false) => {
  const isMetricTurnover = metric === 'turnover';
  const unitToDisplay =
    gapStock == null ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`;

  return `<div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light};">
      <div style="color: ${theme.colors.greys.darkest}; font: ${
    theme.fonts.textMicroHeight12Weight700Bold
  }; width: 120px;">
        ${i18next.t('STOCKS.STOCKS.REAL_FROM_THEORETICAL')}
      </div>
      <div style="color: ${theme.colors.greys.darkest}; font: ${
    theme.fonts.textMicroHeight12Weight700Bold
  }; width: 96px;">
        ${
          shouldOnlyDisplayRealStocks
            ? '--'
            : `${formatNumber(gapStock, currency.numberDecimals)}${unitToDisplay}`
        }
      </div>
    </div>`;
};

const deliveryRows = (graph, unit, metric, currency) => {
  const deliveryOrder = graph.y.delivery.order;

  const isMetricTurnover = metric === 'turnover';

  return `<div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light};">
      <div style="color: ${theme.colors.greys.darkest}; font: ${
    theme.fonts.textMicroHeight12Weight700Bold
  }; width: 120px;">
        ${i18next.t('STOCKS.STOCKS.PURCHASE_GRAPH_TOOLTIP')}
      </div>
      <div style="color: ${theme.colors.greys.darkest}; font: ${
    theme.fonts.textMicroHeight12Weight700Bold
  }; width: 96px;">
        ${formatNumber(deliveryOrder, currency.numberDecimals)}${
    !deliveryOrder ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`
  }
      </div>
    </div>
  `;
};

const productionRows = (graph, unit, metric, currency, displayProductionSubLine) => {
  const recipeProduction = graph.y.production.recipe;
  const recipeConsumption = graph.y.production.consumptions;
  const recipeProductionTotal =
    // With JS, when a calculation is performed with null, null is replaced by 0.
    !recipeProduction && !recipeConsumption ? null : recipeProduction - recipeConsumption;

  const isMetricTurnover = metric === 'turnover';

  let content = `
  <div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
  border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light};">
    <div style="color: ${theme.colors.greys.darkest}; font: ${
    theme.fonts.textMicroHeight12Weight700Bold
  }; width: 120px;">
      ${i18next.t('STOCKS.STOCKS.PRODUCTION_GRAPH_TOOLTIP')}
    </div>
    <div style="color: ${theme.colors.greys.darkest}; font: ${
    theme.fonts.textMicroHeight12Weight700Bold
  }; width: 96px;">
    ${formatNumber(recipeProductionTotal, currency.numberDecimals)}${
    !recipeProductionTotal ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`
  }
    </div>
  </div>`;

  if (displayProductionSubLine) {
    content += `<div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
  border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${
    theme.colors.greys.light
  }; padding-left: 16px;">
    <div style="color: ${theme.colors.greys.dark}; font: ${
      theme.fonts.textMicroHeight12Weight500
    }; width: 104px;">
      ${i18next.t('STOCKS.STOCKS.PRODUCTION_GRAPH_TOOLTIP')}
    </div>
    <div style="color: ${theme.colors.greys.dark}; font: ${
      theme.fonts.textMicroHeight12Weight500
    }; width: 96px;">
  ${formatNumber(recipeProduction, currency.numberDecimals)}${
      !recipeProduction ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`
    }
    </div>
      </div>`;
  }

  content += `
    <div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${
      theme.colors.greys.light
    }; padding-left: 16px;">
    <div style="color: ${theme.colors.greys.dark}; font: ${
    theme.fonts.textMicroHeight12Weight500
  }; width: 104px;">
      ${i18next.t('STOCKS.STOCKS.CONSUMPTION_GRAPH_TOOLTIP')}
    </div>
    <div style="color: ${theme.colors.greys.dark}; font: ${
    theme.fonts.textMicroHeight12Weight500
  }; width: 96px;">
    ${!recipeConsumption ? '' : '-'}${formatNumber(recipeConsumption, currency.numberDecimals)}${
    !recipeConsumption ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`
  }
    </div>
  </div>`;

  return content;
};

const centralKitchenStockOutRows = (graph, unit, metric, currency) => {
  const centralKitchenStockOut = graph.y.centralKitchenStockOut;

  const isMetricTurnover = metric === 'turnover';

  const tot = isMetricTurnover
    ? centralKitchenStockOut.totTurnover
    : centralKitchenStockOut.totUnit;

  return `<div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light};">
      <div style="color: ${theme.colors.greys.darkest}; font: ${
    theme.fonts.textMicroHeight12Weight700Bold
  }; width: 120px;">
        ${i18next.t('STOCKS.STOCKS.DELIVERY_GRAPH_TOOLTIP')}
      </div>
      <div style="color: ${theme.colors.greys.darkest}; font: ${
    theme.fonts.textMicroHeight12Weight700Bold
  }; width: 96px;">
  ${!tot ? '' : '-'}${formatNumber(tot, currency.numberDecimals)}${
    !tot ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`
  }
      </div>
    </div>
  `;
};

const lossesRows = (graph, unit, metric, currency, displayIngredientLosses) => {
  const {
    total,
    supplierProduct, // supplier_product_loss
    recipeIngredient, // recipe_ingredient_loss
    recipe, // recipe_loss
  } = graph.y.loss;

  const isMetricTurnover = metric === 'turnover';
  const directLosses = displayIngredientLosses ? supplierProduct : recipe;

  // Either a loss of ingredient through a non produced recipe,
  // or a loss of recipe where a component is a non produced recipe
  const indirectLosses = recipeIngredient;

  return `
    <!-- Total Loss -->
    <div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${theme.colors.greys.light};">
      <div style="color: ${theme.colors.infoRed}; font: ${
    theme.fonts.textMicroHeight12Weight700Bold
  }; width: 120px;">
        ${i18next.t('STOCKS.STOCKS.LIST_STATUS_LOSS')}
      </div>
      <div style="color: ${theme.colors.infoRed}; font: ${
    theme.fonts.textMicroHeight12Weight700Bold
  }; width: 96px;">
        ${total ? '-' : ''}${formatNumber(total, currency.numberDecimals)}${
    !total ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`
  }
      </div>
    </div>

    <!-- Direct losses -->
    <div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${
      theme.colors.greys.light
    }; padding-left: 16px;">
      <div style="color: ${theme.colors.greys.dark}; font: ${
    theme.fonts.textMicroHeight12Weight500
  }; width: 104px;">
        ${i18next.t('STOCKS.STOCKS.REAL_STOCK_INGREDIENTS')}
      </div>
      <div style="color: ${theme.colors.greys.dark}; font: ${
    theme.fonts.textMicroHeight12Weight500
  }; width: 96px;">
        ${directLosses ? '-' : ''}${formatNumber(directLosses, currency.numberDecimals)}${
    !directLosses ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`
  }
      </div>
    </div>

    <!-- Indirect losses -->
    <div style="width: 100%; height: 20px; display: flex; flex-direction: row; align-items: center; column-gap: 8px;
    border-width: 0px 0px 1px 0px; border-style: solid; border-color: ${
      theme.colors.greys.light
    }; padding-left: 16px;">
      <div style="color: ${theme.colors.greys.dark}; font: ${
    theme.fonts.textMicroHeight12Weight500
  }; width: 104px;">
        ${i18next.t('STOCKS.STOCKS.REAL_STOCK_RECIPES')}
      </div>
      <div style="color: ${theme.colors.greys.dark}; font: ${
    theme.fonts.textMicroHeight12Weight500
  }; width: 96px;">
        ${indirectLosses ? '-' : ''}${formatNumber(indirectLosses, currency.numberDecimals)}${
    !indirectLosses ? '-' : isMetricTurnover ? ` ${currency.alphabeticCode}` : ` ${unit}`
  }
      </div>
    </div>`;
};

export const CentralKitchenGraphTooltip = ({
  graph,
  unit,
  metric,
  gapStock,
  realStock,
  isDateToday,
  theoricalStock,
  displayRecipeStock,
  isCentralKitchenIngredientPastStockType,
  displayProductionLines,
  isCentralKitchenRecipePastStockType,
  currency,
  shouldOnlyDisplayRealStocks,
}) =>
  `<div style="background-color: ${theme.colors.greys.lightest}; width: 100%; height: 100%; border-radius: 5px; min-width: 256px;
  padding: 16px;">` +
  dateTitle(isDateToday, graph) +
  `${isCentralKitchenIngredientPastStockType ? deliveryRows(graph, unit, metric, currency) : ''}` +
  `${
    displayProductionLines
      ? productionRows(graph, unit, metric, currency, isCentralKitchenRecipePastStockType)
      : ''
  }` +
  `${centralKitchenStockOutRows(graph, unit, metric, currency)}` +
  `${lossesRows(graph, unit, metric, currency, isCentralKitchenIngredientPastStockType)}` +
  `${theoreticalStockRow(theoricalStock, unit, metric, currency, shouldOnlyDisplayRealStocks)}` +
  `${
    isCentralKitchenRecipePastStockType
      ? realStockRowRecipe(realStock, unit, metric, currency)
      : realStockRowIngredient(graph, realStock, displayRecipeStock, unit, metric, currency)
  }` +
  `${gapRow(gapStock, unit, metric, currency, shouldOnlyDisplayRealStocks)}` +
  `</div>`;
