const SHEET = {
  id: 'entities',
  name: 'Ingrédients',
};

const COLUMN_IDS = {
  CATEGORY: 'category',
  ID: 'id',
  NAME: 'name',
  UNIT: 'unit',
  COST: 'cost',
  IS_STRATEGIC: 'isStrategic',
};

const HIDDEN_SHEET_CATEGORY = {
  ID: 'categories',
  NAME: 'Catégories',
};

const HIDDEN_CATEGORY_COLUMN_ID = {
  NAME: 'name',
};

module.exports = {
  SHEET,
  COLUMN_IDS,
  HIDDEN_SHEET_CATEGORY,
  HIDDEN_CATEGORY_COLUMN_ID,
};
