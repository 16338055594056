import React from 'react';

import { period } from '@productions/constants/period';

import { Box } from './styledComponents';

export const LeftToProduceSection = (props) => {
  const {
    value,
    reportToProduceFromPreviousRanges,
    remainingFromPreviousRanges,
    useCase,
    disabled,
    isDisplayed,
  } = props;

  let className;
  if (disabled) {
    className = 'disabled';
  } else if (
    useCase === period.CURRENT &&
    reportToProduceFromPreviousRanges &&
    remainingFromPreviousRanges > 0 &&
    value > 0
  ) {
    className = 'background-red';
  } else {
    className = value > 0 ? 'background-orange' : 'background-green';
  }

  return isDisplayed ? <Box className={className}>{value}</Box> : null;
};

export default LeftToProduceSection;
