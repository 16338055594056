const STOCK_FORM_TYPES = {
  STOCK: 'stock',
  LOSS: 'loss',
  TRANSFER: 'transfer',
};

const STOCK_DATA_TYPES = {
  RECIPE: 'recipe',
  SUPPLIER_PRODUCT: 'supplier_product',
};

module.exports = {
  STOCK_FORM_TYPES,
  STOCK_DATA_TYPES,
};
