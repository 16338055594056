import { get } from 'lodash';
import i18next from 'i18next';

import {
  computeTypesOfPackaging,
  convertPriceToPackagingById,
  convertQuantityToPackagingById,
} from '@commons/utils/packagings';
import { flattenArray } from '@commons/utils/format';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { makeOrderOrInventoryXLS } from '@commons/utils/makeXLS';

import ORDER_STATUS from '../../../constants/status';

import {
  getNoInventoryMoreThanThirtyDays,
  getYesterdayStock,
} from '../../../utils/formatYesterdayStock';

const _formatYesterdayStock = (product, storeTimezone) => {
  const yesterdayStock = getYesterdayStock(
    get(product, 'entityStockEvent.stock', {}),
    storeTimezone,
  );

  const noInventoryMoreThanThirtyDays = getNoInventoryMoreThanThirtyDays(
    get(product, 'entityStockEvent.stock', null),
  );

  if (noInventoryMoreThanThirtyDays || !yesterdayStock) {
    return null;
  }

  return yesterdayStock.realStockUnit != null
    ? yesterdayStock.realStockUnit
    : yesterdayStock.theoricalStockUnit;
};

const _formatUnit = (unit) => {
  if (unit === 'unit') {
    return i18next.t('ORDERS.ORDERS.EXPORT_LABEL_UNIT');
  }
  if (unit === 'kg' || unit === 'L') {
    return unit;
  }
  return null;
};

const processProductsInventory = (
  products,
  orderId,
  storeName,
  supplierName,
  customerCode,
  orderDate,
  startDate,
  receivedDiffOfProductOrder,
  invoicedDiffOfProductOrder,
  receptionAnomalies,
  storeTimezone,
  partnerId,
) =>
  Object.values(products).map((category) =>
    category.products
      .filter((product) => product.ordered > 0 || product.invoiced > 0)
      .map((product) => {
        const recommendations = get(product, 'entityStockEvent.recommandation', 0);
        const consumption = get(product, 'entityStockEvent.consumption', 0);
        const invoicedPrice = product.priceBDL != null ? product.priceBDL : product.price;
        const invoicedQuantity =
          product.invoiced || product.invoiced === 0 ? product.invoiced : product.ordered;
        const receivedQuantity =
          product.received || product.received === 0 ? product.received : product.ordered;

        const { packagingUsedInOrder, packagingUsedInInvoice, packagingUsedInReception } =
          computeTypesOfPackaging(product.receptionPackagingId || null, product.packagings);

        const convertedInvoicedPrice = convertPriceToPackagingById(
          invoicedPrice,
          packagingUsedInReception.id,
          packagingUsedInInvoice.id,
          product.packagings,
        );

        const convertedInvoicedQuantity = convertQuantityToPackagingById(
          invoicedQuantity,
          packagingUsedInReception.id,
          packagingUsedInInvoice.id,
          product.packagings,
        );

        const result = {
          ...product,
          reference: orderId,
          storeName: storeName,
          partnerId: partnerId,
          supplierName: supplierName,
          customerCode: customerCode,
          orderDate: orderDate.format('L'),
          deliveryDate: startDate.format('L'),
          price: product.price || 0,
          priceBDL: product.priceBDL != null ? product.priceBDL : product.price,
          recoValue: recommendations,
          consoValue: consumption,
          receivedDiff: receivedDiffOfProductOrder[product.id],
          invoicedDiff: invoicedDiffOfProductOrder[product.id],
          receptionAnomaly: receptionAnomalies[product.receptionAnomaly],
          invoiced: convertedInvoicedQuantity,
          received: receivedQuantity,
          subCategory: product.subCategory
            ? product.subCategory
            : i18next.t('PRODUCTION.PRODUCTION.OTHER_CATEGORY'),
          yesterdayStock: _formatYesterdayStock(product, storeTimezone),
          stockUnit: !!product.entityId ? _formatUnit(product.packagingUnit) : null,
          orderPackagingName: packagingUsedInOrder.name,
          invoicePackagingName: packagingUsedInInvoice.name,
          invoicedPrice: convertedInvoicedPrice,
        };

        if (product.receptionPackagingId) {
          const receptionPrice = convertPriceToPackagingById(
            product.price,
            packagingUsedInOrder.id,
            packagingUsedInReception.id,
            product.packagings,
          );

          return {
            ...result,
            receptionPackagingName: packagingUsedInReception.name,
            receptionPrice,
          };
        }

        return result;
      }),
  );

const makeOrderXls = ({
  products,
  user,
  client,
  orderStatus,
  orderId,
  storeName,
  supplierName,
  customerCode,
  orderDate,
  startDate,
  receivedDiffOfProductOrder,
  invoicedDiffOfProductOrder,
  receptionAnomalies,
  showMessage,
  displaySupplierProductPackagingWeight,
  currency,
  storeTimezone,
  partnerId,
  isEditingPreparationOrder,
  supplierInfos,
  resetStockEveryDayForCentralKitchen,
}) => {
  // For some customers (Krispy Kreme), when ordering from a Central, they don't want to use the order recos but the theoretical consumption
  // (as the DLC is 1 day, they never have any stock).
  const shouldUseConsumption = resetStockEveryDayForCentralKitchen && supplierInfos.isKitchen;

  let columns = [
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_REFERENCE'),
      propertyKey: 'reference',
    },
    {
      name: getClientStoreNameTranslation(client.storeName),
      propertyKey: 'storeName',
    },
    {
      name: i18next.t('BACKOFFICE.CASHIER_STORES.PARTNER_ID'),
      propertyKey: 'partnerId',
    },
    {
      name: i18next.t(
        isEditingPreparationOrder ? 'GENERAL.CENTRAL' : 'ORDERS.ORDERS.LIST_LABEL_SUPPLIER',
      ),
      propertyKey: 'supplierName',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_DELIVERY_DATE'),
      propertyKey: 'deliveryDate',
    },
    {
      name: i18next.t('GENERAL.CREATION_DATE'),
      propertyKey: 'orderDate',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_CATEGORY'),
      propertyKey: 'subCategory',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_SKU'),
      propertyKey: 'sku',
    },
    {
      name: i18next.t('GENERAL.SUPPLIER_PRODUCT'),
      propertyKey: 'name',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_ORDER_PACKAGING'),
      propertyKey: 'orderPackagingName',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_ORDERED_QUANTITY'),
      propertyKey: 'ordered',
    },
    {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_PRICE_EX_TAX_BY_ORDER_PACKAGING'),
      propertyKey: 'price',
      type: 'currency',
    },
    {
      name: i18next.t('ORDERS.ORDERS.EXPORT_VAT_RATE'),
      propertyKey: 'vatRate',
    },
  ];

  if (orderStatus < ORDER_STATUS.SENT) {
    columns.push(
      {
        name: i18next.t('ORDERS.ORDERS.LIST_LABEL_YESTERDAY_STOCK'),
        propertyKey: 'yesterdayStock',
      },
      {
        name: i18next.t('ORDERS.ORDERS.LIST_LABEL_STOCK_UNIT'),
        propertyKey: 'stockUnit',
      },
    );
  }

  if (user.hasForecasts && orderStatus < ORDER_STATUS.SENT) {
    columns.push({
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_CONSUMPTION'),
      propertyKey: 'consoValue',
    });

    if (!shouldUseConsumption) {
      columns.push({
        name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECO'),
        propertyKey: 'recoValue',
      });
    }
  }

  if (orderStatus >= ORDER_STATUS.SENT) {
    const priceColumnIndex = columns.findIndex(({ propertyKey }) => propertyKey === 'price');

    // When exporting data of a (at least) sent order, add the following columns right after the 'price' column.
    columns.splice(
      priceColumnIndex + 1,
      0,
      {
        name: i18next.t('ORDERS.ORDERS.LIST_LABEL_INVOICE_PACKAGING'),
        propertyKey: 'invoicePackagingName',
      },
      {
        name: i18next.t('ORDERS.ORDERS.LIST_LABEL_QUANTITY_BDL'),
        propertyKey: 'invoiced',
      },
      {
        name: i18next.t('ORDERS.ORDERS.LIST_LABEL_PRICE_EX_TAX_BDL_BY_INVOICE_PACKAGING'),
        propertyKey: 'invoicedPrice',
        type: 'currency',
      },
    );

    if (isEditingPreparationOrder) {
      columns.push({
        name: i18next.t('ORDERS.ORDERS.LIST_LABEL_BDL_BDC_DIFFERENTIAL'),
        propertyKey: 'invoicedDiff',
      });
    }

    if (!isEditingPreparationOrder) {
      columns.splice(
        columns.findIndex(({ propertyKey }) => propertyKey === 'price') + 1,
        0,
        {
          name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEPTION_PACKAGING'),
          propertyKey: 'receptionPackagingName',
        },
        {
          name: i18next.t('ORDERS.ORDERS.LIST_LABEL_RECEIVED'),
          propertyKey: 'received',
        },
        {
          name: i18next.t('ORDERS.ORDERS.LIST_LABEL_THEORETICAL_PRICE_BY_RECEPTION_PRICE'),
          propertyKey: 'receptionPrice',
        },
      );

      columns.push(
        {
          name: i18next.t('ORDERS.ORDERS.LIST_LABEL_DIFFERENTIAL'),
          propertyKey: 'receivedDiff',
        },
        {
          name: i18next.t('ORDERS.ORDERS.LIST_LABEL_ANOMALY'),
          propertyKey: 'receptionAnomaly',
        },
      );
    }
  }

  if (customerCode) {
    columns.splice(4, 0, {
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_CUSTOMER_CODE'),
      propertyKey: 'customerCode',
    });
  }

  if (displaySupplierProductPackagingWeight) {
    columns.splice(columns.findIndex(({ propertyKey }) => propertyKey === 'vatRate') + 1, 0, {
      name: i18next.t('ORDERS.ORDERS.EXPORT_TOTAL_WEIGHT'),
      propertyKey: 'totPackagingWeight',
    });
  }

  try {
    const formattedProductInventories = processProductsInventory(
      products,
      orderId,
      storeName,
      supplierName,
      customerCode,
      orderDate,
      startDate,
      receivedDiffOfProductOrder,
      invoicedDiffOfProductOrder,
      receptionAnomalies,
      storeTimezone,
      partnerId,
    );

    const filteredProductInventories = formattedProductInventories.filter(
      (mappedProduct) => mappedProduct.length > 0,
    );

    const flattenProductInventories = flattenArray(filteredProductInventories);

    const formattedProducts = flattenProductInventories.map((product) => ({
      ...product,
      totPackagingWeight: product.packagingWeight ? product.packagingWeight * product.ordered : '',
    }));

    makeOrderOrInventoryXLS(orderId, formattedProducts, orderId, columns, currency);
  } catch (err) {
    showMessage(i18next.t('ORDERS.BY_CATEGORY.EXPORT_LIST_FAILURE'), 'error');
  }
};

export default makeOrderXls;
