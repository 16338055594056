import { APIcaller } from './APIcaller';

const getWeatherForecast = (weatherStationId, storeTimezone, startDate, endDate) =>
  APIcaller.apiGet(`/forecast/weather-days/${weatherStationId}`, {
    storeTimezone,
    startDate,
    endDate,
  });

export const weatherday = {
  getWeatherForecast,
};
