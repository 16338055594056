import SUPPLIER_PROFILES_XLSX_CONSTANT from '@commons/Excels/constants/supplierProfile.xlsx.constant';

import { normalizeJsonObject } from '@backoffice/utils';
/**
 * This function aim to format excel of supplier profile
 * to send it to the API and validate or insert in database
 * @param {object} supplierProfiles - The supplier profile batch creation to format to validate or insert in database
 * @return {object}
 */
export const formatSupplierProfilesCreationFile = (supplierProfiles) => {
  const formattedSupplierProfiles = normalizeJsonObject(supplierProfiles);

  return Object.keys(formattedSupplierProfiles).reduce((result, sheetName) => {
    if (sheetName === SUPPLIER_PROFILES_XLSX_CONSTANT.SHEET.NAME) {
      result[SUPPLIER_PROFILES_XLSX_CONSTANT.SHEET.ID] = formattedSupplierProfiles[sheetName].map(
        (current) => ({
          // Nom du Fournisseur
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.SUPPLIER]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.SUPPLIER],
          // Nom du profil fournisseur
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.SUPPLIER_PROFILE_NAME]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.SUPPLIER_PROFILE_NAME],
          // Nom du contact
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.CONTACT_NAME]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.CONTACT_NAME],
          // Email de commande
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.ORDER_EMAIL]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.ORDER_EMAIL],
          // Email de demande d'avoir
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.CREDIT_EMAIL]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.CREDIT_EMAIL],
          // Telephone
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.PHONE]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.PHONE],
          // Adresse postal
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.ADDRESS]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.ADDRESS] || '',
          // Code postal
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.POSTAL_CODE]: !!current[
            SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.POSTAL_CODE
          ]
            ? String(current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.POSTAL_CODE])
            : '',
          // Ville
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.CITY]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.CITY],
          // Pays
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.COUNTRY]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.COUNTRY] || '',
          // Fuseau horaire
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.TIMEZONE]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.TIMEZONE] || '',
          // Minimum de commande (Prix)
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.ORDER_MIN_AMOUNT]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.ORDER_MIN_AMOUNT],
          // Minimum de commande (colis)
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.ORDER_MIN_UNIT]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.ORDER_MIN_UNIT],
          // Delais de livraison (lundi)
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.MONDAY_DELIVERY]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.MONDAY_DELIVERY],
          // Delais de livraison (mardi)
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.TUESDAY_DELIVERY]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.TUESDAY_DELIVERY],
          // Delais de livraison (mercredi)
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.WEDNESDAY_DELIVERY]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.WEDNESDAY_DELIVERY],
          // Delais de livraison (jeudi)
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.THURSDAY_DELIVERY]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.THURSDAY_DELIVERY],
          // Delais de livraison (vendredi)
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.FRIDAY_DELIVERY]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.FRIDAY_DELIVERY],
          // Delais de livraison (samedi)
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.SATURDAY_DELIVERY]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.SATURDAY_DELIVERY],
          // Delais de livraison (dimanche)
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.SUNDAY_DELIVERY]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.SUNDAY_DELIVERY],
          // Heure limite de commande
          [SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_IDS.ORDER_TIME_LIMIT]:
            current[SUPPLIER_PROFILES_XLSX_CONSTANT.COLUMN_NORMALIZE_IDS.ORDER_TIME_LIMIT],
        }),
      );
    }

    if (sheetName === SUPPLIER_PROFILES_XLSX_CONSTANT.HIDDEN_SHEET_SUPPLIERS.NAME) {
      result[SUPPLIER_PROFILES_XLSX_CONSTANT.HIDDEN_SHEET_SUPPLIERS.ID] = formattedSupplierProfiles[
        sheetName
      ].map((current) => ({
        // supplierId
        [SUPPLIER_PROFILES_XLSX_CONSTANT.HIDDEN_SHEET_SUPPLIERS_COLUMN_IDS.ID]:
          current[SUPPLIER_PROFILES_XLSX_CONSTANT.HIDDEN_SHEET_SUPPLIERS_NORMALIZE_COLUMN_IDS.ID],
        // Name
        [SUPPLIER_PROFILES_XLSX_CONSTANT.HIDDEN_SHEET_SUPPLIERS_COLUMN_IDS.NAME]:
          current[SUPPLIER_PROFILES_XLSX_CONSTANT.HIDDEN_SHEET_SUPPLIERS_NORMALIZE_COLUMN_IDS.NAME],
      }));
    }

    return result;
  }, {});
};

export default { formatSupplierProfilesCreationFile };
