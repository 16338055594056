import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React from 'react';

import { ModalContent } from './styledComponents';

export const ConfirmationModal = (props) => {
  const { content, shouldInterpretHtml } = props;

  if (shouldInterpretHtml) {
    return <ModalContent>{parse(content)}</ModalContent>;
  }

  return <ModalContent>{content}</ModalContent>;
};

export const getConfirmationModal = (props) => {
  const { title, icon, content, actions, type, shouldInterpretHtml = false } = props;

  return {
    type: type || 'warning',
    height: 'auto',
    component: ConfirmationModal,
    data: {
      content: content,
      shouldInterpretHtml: shouldInterpretHtml,
    },
    title,
    icon,
    actions,
  };
};

ConfirmationModal.propTypes = {
  content: PropTypes.string,
  shouldInterpretHtml: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  content: '',
  shouldInterpretHtml: false,
};
