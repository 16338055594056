import i18next from 'i18next';

import utilsXLS, { generateDatesSheetFromStartEndDate } from '@commons/utils/makeXLS';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

const getMainSheetColumnsSettings = (alphabeticCode = 'EUR') => [
  {
    name: i18next.t('GENERAL.NAME'),
    propertyKey: 'storeName',
  },
  {
    type: 'currency',
    name: `${i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_REVENUE')} (${alphabeticCode})`,
    propertyKey: 'turnover',
  },
  {
    type: 'currency',
    name: `${i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_ACTUAL_FC')} (${alphabeticCode})`,
    propertyKey: 'purchase',
  },
  {
    type: 'percentage',
    name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_PERCENT_ACTUAL_FC'),
    propertyKey: 'orderOverTurnoverRatio',
  },
  {
    type: 'currency',
    name: i18next.t('ANALYSIS.MARGIN.GROSS_SALARY_EXPORT_COLUMN_NAME', { alphabeticCode }),
    propertyKey: 'grossSalary',
  },
  {
    type: 'percentage',
    name: i18next.t('ANALYSIS.MARGIN.GROSS_SALARY_OVER_TURNOVER_PERCENTAGE_COLUMN_NAME'),
    propertyKey: 'grossSalaryPercentage',
  },
  {
    type: 'currency',
    name: i18next.t('ANALYSIS.MARGIN.GROSS_MARGIN_EXPORT_COLUMN_NAME', { alphabeticCode }),
    propertyKey: 'grossMargin',
  },
  {
    type: 'percentage',
    name: i18next.t('ANALYSIS.MARGIN.GROSS_MARGIN_OVER_TURNOVER_PERCENTAGE_COLUMN_NAME'),
    propertyKey: 'grossMarginOverTurnoverPercentage',
  },
];

const createMainSheet = (analytics, currency) => {
  const headersSettings = getMainSheetColumnsSettings(!!currency ? currency.alphabeticCode : null);

  const formattedDataToExport = analytics.map((item) => {
    if (item.id === 'total') {
      return {
        ...item,
        storeName: i18next.t('GENERAL.ANALYTICS_LABEL_TOTAL'),
      };
    }

    return item;
  });

  return utilsXLS.generateDefaultSheet(
    i18next.t('ANALYSIS.MARGIN.EXPORT_MAIN_SHEET_NAME'),
    headersSettings,
    formattedDataToExport,
    currency,
  );
};

export const exportAnalytics = (analytics, startDate, endDate, currency) => {
  if (!analytics) {
    return;
  }

  const mainSheet = createMainSheet(analytics, currency);

  const datesSheet = generateDatesSheetFromStartEndDate(
    startDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
    endDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  );

  utilsXLS.makeXLS(i18next.t('ANALYSIS.MARGIN.EXPORT_FILENAME'), [mainSheet, datesSheet]);
};
