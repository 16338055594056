import { connect } from 'react-redux';
import React from 'react';
import SpinnerIcon from '../../../assets/icons/new-loader.svg';
import styled, { keyframes } from 'styled-components';

export const Spinner = (props) =>
  props.customLoader ||
  Object.keys(props.loading)
    .map((key) => props.loading[key])
    .includes(true) ? (
    <div className={props.className}>
      <div className="loader">
        <img alt="loading..." className="loader-image" src={SpinnerIcon} />
      </div>
    </div>
  ) : (
    <div></div>
  );

const mapStateToProps = (state) => ({
  loading: state.loadingReducer,
  customLoader: state.baseReducer.customLoader,
});

const Loader = connect(mapStateToProps)(Spinner);

const rotate = keyframes`
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }`;

const StyledLoader = styled(Loader)`
  position: fixed;
  top: 0px;
  width: calc(100%);
  height: calc(100%);
  background-color: rgba(98, 100, 102, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  overflow: hidden;
  z-index: 100;

  .loader {
    width: 3em;
    height: 3em;

    .loader-image {
      user-select: none;
      -webkit-animation: ${rotate} 1s linear infinite;
      -moz-animation: ${rotate} 1s linear infinite;
      animation: ${rotate} 1s linear infinite;
    }
  }
`;

export default StyledLoader;
