import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px 48px;

  display: flex;

  align-items: center;
  justify-content: space-between;
`;

export const InputContainer = styled.div`
  display: flex;

  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 30px;
`;

export const ErrorText = styled.div`
  position: relative;
  top: 8px;

  color: ${(props) => props.theme?.colors?.infoRed};
  font: ${(props) => props.theme.fonts?.textSmallHeight14Weight600};
`;

export const MatchPasswordRulesContainer = styled.div`
  width: 200px;

  padding: 16px 8px 16px 24px;

  background-color: ${(props) => props.theme.colors.greys.lighter};
  border-radius: 8px;
`;

export const CheckRules = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

export const Rule = styled.div`
  display: flex;

  align-items: center;
  justify-content: flex-start;
`;

export const RuleMessage = styled.div`
  margin-top: 4px;

  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.textSmallHeight16};
`;

export default {
  Container,
  MatchPasswordRulesContainer,
  CheckRules,
  Rule,
  RuleMessage,
  ErrorText,
};
