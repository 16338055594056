import _ from 'lodash';
import { ItemGlobal, HeaderInterface } from './interface';

const compare = (item, selectedColumn, selectedPropertyKey?) => {
  if (!selectedColumn.isNumber) {
    return _.get(
      item,
      `items.${selectedPropertyKey || selectedColumn.propertyKey}`,
      ''
    )
      .toString()
      .toLowerCase();
  }

  const property = _.get(
    item,
    `items.${selectedPropertyKey || selectedColumn.propertyKey}`
  );

  return property === null
    ? -1
    : parseFloat(
        _.get(
          item,
          `items.${selectedPropertyKey || selectedColumn.propertyKey}`,
          -1
        )
      );
};

export function sortListBy(
  data: Array<ItemGlobal>,
  headers: HeaderInterface[],
  orderBy: number,
  orderType: 'asc' | 'desc'
): Array<ItemGlobal> {
  const selectedColumn = headers.find((header) => header.id === orderBy);

  if (!selectedColumn) {
    return data;
  }

  const totalRow = data.find((item) => _.get(item, 'isHighlighted'));

  const orderedCategories = _.orderBy(
    data.filter((item) => !_.get(item, 'isHighlighted')),
    [
      (item) =>
        compare(item.parentItem, selectedColumn, selectedColumn.categorySortBy),
    ],
    [orderType]
  );

  orderedCategories.forEach((category, index) => {
    const orderedCategory = _.orderBy(
      category.childItems,
      [(child) => compare(child, selectedColumn)],
      [orderType]
    );
    orderedCategories[index].childItems = orderedCategory;
  });

  if (totalRow) {
    orderedCategories.unshift(totalRow);
  }

  return orderedCategories;
}

export default sortListBy;
