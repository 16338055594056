import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { ENTITY_UNITS } from '@commons/constants/units';
import DisplayNumber from '@commons/DisplayNumber';
import Text, { ENUM_FONTS, ENUM_COLORS } from '@commons/Text';

import { UNIT } from '@stocks/StocksInventories/components/StockForm/constants/unit';

import utils from '@stocks/StocksInventories/components/StockForm/utils';

import { Item } from '../styledComponents';

const memoizeCondition = (prevProps, props) =>
  isEqual(prevProps.totByPackagingId, props.totByPackagingId);

const Total = (props) => {
  const { price, packagings, totByPackagingId, nbColumns } = props;

  const productPrice = utils.getPrice({ packagings, totByPackagingId, price });

  const getEntityUnit = (unit) => {
    if (unit === ENTITY_UNITS.UNIT) {
      return 'u.';
    }

    return unit;
  };

  const getUnit = () => {
    const masterPackaging = packagings.find((item) => !item.masterSupplierProductPackagingId);

    if (!masterPackaging) {
      return '-';
    }

    return getEntityUnit(masterPackaging.unit);
  };

  return (
    <Item nbColumns={nbColumns}>
      <DisplayNumber displayCurrencyCode={true} number={productPrice} />
      <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_MICRO}>
        {utils.getQuantityMasterUnit({ packagings, totByPackagingId }).toFixed(2)} {getUnit()}
      </Text>
    </Item>
  );
};

Total.propTypes = {
  price: PropTypes.number,
  nbColumns: PropTypes.number,
  packagings: PropTypes.arrayOf(
    PropTypes.shape({
      unit: PropTypes.oneOf(UNIT),
      quantity: PropTypes.number.isRequired,
    }),
  ),
  totByPackagingId: PropTypes.objectOf(PropTypes.any), // number or null
};

Total.defaultProps = {
  price: null,
  nbColumns: 1,
  packagings: [],
  totByPackagingId: {},
};

export default memo(Total, memoizeCondition);
