import i18next from 'i18next';
import React from 'react';

import { Tags } from '@commons/Tags';
import { Tooltip } from '@commons/utils/styledLibraryComponents';
import DisplayNumber, { getNumberFormattedWithDecimals } from '@commons/DisplayNumber';

import {
  ItemRowContainer,
  ItemRow,
  ItemRowContent,
  DisplayBiggerContainer,
} from '../styledComponents';

const formatTransactionsForNestedList = (transactions) =>
  transactions.map((transaction) => ({
    ...transaction,
    nestedContent: () => generateNestedContent(transaction.items),
  }));

const generateNestedContent = (items) => (
  <ItemRowContainer>
    <ItemRow borderBottom>
      <ItemRowContent>{i18next.t('GENERAL.SKU')}</ItemRowContent>
      <ItemRowContent>{i18next.t('GENERAL.NAME')}</ItemRowContent>
      <ItemRowContent>{i18next.t('GENERAL.TAGS')}</ItemRowContent>
      <ItemRowContent>{i18next.t('GENERAL.QUANTITY')}</ItemRowContent>
      <ItemRowContent>{i18next.t('GENERAL.UNIT_PRICE_WITH_TAXES_SHORTENED')}</ItemRowContent>
      <ItemRowContent>{i18next.t('GENERAL.NORMALIZED_PRICE_WITH_TAXES')}</ItemRowContent>
      <ItemRowContent>{i18next.t('GENERAL.VAT_RATE')}</ItemRowContent>
      <ItemRowContent>{i18next.t('GENERAL.ACTIVE')}</ItemRowContent>
      <ItemRowContent>{i18next.t('GENERAL.LINKED')}</ItemRowContent>
    </ItemRow>
    {items.map(
      (
        {
          id,
          sku,
          name,
          tags,
          quantity,
          price,
          includingTaxesNormalizedPrice,
          vatRate,
          active,
          isAssociated,
        },
        index,
      ) => (
        <ItemRow borderTop={index !== 0} key={id}>
          <DisplayBiggerContainer>
            <Tooltip
              renderTooltipTrigger={() => <ItemRowContent displayBigger>{sku}</ItemRowContent>}
              text={sku}
              displayBigger
            />
          </DisplayBiggerContainer>
          <DisplayBiggerContainer>
            <Tooltip
              renderTooltipTrigger={() => <ItemRowContent displayBigger>{name}</ItemRowContent>}
              text={name}
              displayBigger
            />
          </DisplayBiggerContainer>
          <ItemRowContent>
            <Tags items={tags} isNestedContent />
          </ItemRowContent>
          <ItemRowContent>{quantity}</ItemRowContent>
          <ItemRowContent>
            <DisplayNumber number={price} displayCurrencyCode />
          </ItemRowContent>
          <ItemRowContent>
            <DisplayNumber number={includingTaxesNormalizedPrice} displayCurrencyCode />
          </ItemRowContent>
          <ItemRowContent>
            {vatRate ? `${getNumberFormattedWithDecimals(vatRate, 1)}%` : '-'}
          </ItemRowContent>
          <ItemRowContent>{i18next.t(active ? 'GENERAL.YES' : 'GENERAL.NO')}</ItemRowContent>
          <ItemRowContent>{i18next.t(isAssociated ? 'GENERAL.YES' : 'GENERAL.NO')}</ItemRowContent>
        </ItemRow>
      ),
    )}
  </ItemRowContainer>
);

export default { formatTransactionsForNestedList, generateNestedContent };
