import PropTypes from 'prop-types';
import React from 'react';

import DisplayNumber from '@commons/DisplayNumber';

import { Container } from './styledComponents';

export const PriceSection = (props) => {
  const { value, isDisplayed } = props;

  return isDisplayed ? (
    <Container>
      <DisplayNumber displayCurrencyCode={true} number={value || 0} />
    </Container>
  ) : null;
};

PriceSection.propTypes = {
  value: PropTypes.number.isRequired,
  isDisplayed: PropTypes.bool,
};

PriceSection.defaultProps = {
  isDisplayed: true,
};

export default PriceSection;
