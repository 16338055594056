import i18next from 'i18next';
import React from 'react';

import { InpulseLabel } from '@commons/DeepsightComponents';

const renderEdi = (isEdi) => (
  <InpulseLabel
    color={isEdi === true ? 'green' : 'red'}
    text={String(isEdi ? i18next.t('GENERAL.YES') : i18next.t('GENERAL.NO'))}
    type={'tertiary'}
  />
);
export const getColumnsSettings = ({ shouldDisplayEdi, isKitchen, shouldDisplayCdn }) => [
  {
    id: 'name',
    name: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
    baseName: 'name',
  },
  {
    id: 'supplier_profile_name',
    name: i18next.t(
      isKitchen
        ? 'ADMIN.SUPPLIERS.ASSOCIATED_STORES_COLUMN_KITCHEN_SUPPLIER_PROFILE'
        : 'ADMIN.SUPPLIERS.ASSOCIATED_STORES_COLUMN_SUPPLIER_PROFILE',
    ),
    propertyKey: 'supplierProfileName',
    baseName: 'supplierProfileName',
    filterType: 'string',
    displayBigger: true,
  },
  {
    id: 'city',
    name: i18next.t('GENERAL.CITY'),
    propertyKey: 'city',
    baseName: 'city',
    filterType: 'string',
  },
  {
    id: 'country',
    name: i18next.t('GENERAL.COUNTRY'),
    propertyKey: 'country',
    baseName: 'country',
    filterType: 'string',
  },
  {
    id: 'customer_code',
    name: i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_CUSTOMER_CODE_LABEL_INPUT'),
    propertyKey: 'customerCode',
    baseName: 'customerCode',
    render: (item) => (item != null && item != '' ? item : '-'),
  },
  {
    id: 'warehouse_code',
    name: i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_WAREHOUSE_CODE_LABEL_INPUT'),
    propertyKey: 'warehouseCode',
    baseName: 'warehouseCode',
    render: (item) => (item != null && item != '' ? item : '-'),
  },

  ...(shouldDisplayEdi
    ? [
        {
          id: 'is_edi',
          name: i18next.t('GENERAL.EDI'),
          propertyKey: 'isEdi',
          baseName: 'isEdi',
          filterType: 'boolean',
          render: (isEdi) => renderEdi(isEdi),
        },
      ]
    : []),

  ...(shouldDisplayCdn
    ? [
        {
          id: 'has_bli',
          name: i18next.t('GENERAL.CDN'),
          propertyKey: 'hasBli',
          render: renderEdi,
        },
      ]
    : []),
];

export default {
  getColumnsSettings,
};
