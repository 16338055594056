import { getNavTreeKeyByPath, getSubFeaturesForPath } from '../commons/features';

export function getFeatures(features) {
  return {
    type: 'FEATURES_SUCCESS',
    features,
  };
}

export function getFeaturesKeyByPath(navTree, storeName) {
  return function (dispatch) {
    const features = getNavTreeKeyByPath({ navTree, storeName });
    dispatch(getFeatures(features));
  };
}

export const getFeatureWithSubFeaturesForPath = (keyByPathNavTree, path) =>
  function (dispatch) {
    dispatch({
      type: 'GET_SUB_FEATURES',
    });
    return getSubFeaturesForPath(keyByPathNavTree, path);
  };
