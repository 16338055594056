import i18next from 'i18next';

export const INVOICE_CONTROL_STATUS_TRANSLATED = {
  // Price and Quantity
  no_gap: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_NO_GAP'),
  price_gap: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_PRICE_GAP'),
  price_and_quantity_gap: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_PRICE_AND_QTY_GAP'),
  quantity_gap: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_QTY_GAP'),

  // Packaging
  wrong_packaging: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_WRONG_PACKAGING'),
  missing_packaging: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_MISSING_PACKAGING'),

  // SKU
  no_corresponding_order: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_NO_CORRESPONDING_ORDER'),
  no_matching_sku: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_NO_MATCHING_SKU'),
  missing_sku_from_invoice: i18next.t('INVOICE.INVOICE_CONTROLS.STATUS_MISSING_SKU_FROM_INVOICE'),
};

export const INVOICE_STATUS = {
  UNCONTROLLED: 'uncontrolled',
  SUCCESS: 'success',
  FAILURE: 'failure',
  PENDING: 'pending',
};

export const INVOICE_STATUS_LABEL_CONFIG = {
  [INVOICE_STATUS.UNCONTROLLED]: {
    color: 'grey',
    label: i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_STATUS_UNCONTROLLED'),
  },
  [INVOICE_STATUS.PENDING]: {
    color: 'orange',
    label: i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_STATUS_PENDING'),
  },
  [INVOICE_STATUS.SUCCESS]: {
    color: 'green',
    label: i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_STATUS_SUCCESS'),
  },
  [INVOICE_STATUS.FAILURE]: {
    color: 'red',
    label: i18next.t('ORDERS.ORDERS.FORM_INVOICE_MODAL_CONTROL_PART_STATUS_FAILURE'),
  },
};
