import _ from 'lodash';

const sortSupplierProductProperties = (properties, mapped = false) => {
  const formatProperties = properties.map((prop) => {
    if (typeof prop !== 'string') {
      return;
    }

    if (
      !prop ||
      prop === 'null' ||
      prop.toLowerCase() === 'aucune' ||
      prop.toLowerCase() === 'empty'
    ) {
      return 'Aucune';
    }

    return prop;
  });

  const sortProperties = _.sortBy(_.uniq(formatProperties), (prop) => prop.toLowerCase()).reduce(
    (acc, prop) => {
      if (prop.toLowerCase() === 'aucune') {
        acc.unshift('Aucune');
        return acc;
      }

      acc.push(prop);
      return acc;
    },
    [],
  );

  if (!!mapped) {
    return sortProperties.map((prop, index) => ({ name: prop, id: index }));
  }

  return sortProperties;
};

export default sortSupplierProductProperties;
