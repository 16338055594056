import styled from 'styled-components';

export const TextContainer = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.greys.dark};
  font: ${(props) => props.theme.fonts.textBigHeight16};

  width: ${(props) => props.width};
`;

export const EmptyContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const FixedPart = styled.div`
  padding: 24px 16px;
  border-right: ${(props) => `1px solid ${props.theme.colors.greys.light}`};
`;
