import React from 'react';
import PropTypes from 'prop-types';
import { PropsItemDisplay } from '../../interface';
import {
  ItemChild,
  LinkChild,
  ItemDisplayChildContainer,
} from './styledComponents';

const ItemDisplayChild = (props: PropsItemDisplay): JSX.Element => {
  const {
    value,
    large,
    narrow,
    minWidth,
    textDecorationChild,
    withRender,
    hrefPrefix,
    hrefSuffix,
    id,
    nbColumn,
    isFirst,
  } = props;

  return (
    <>
      <ItemDisplayChildContainer
        withRender={withRender}
        large={large}
        nbColumn={nbColumn}
        narrow={narrow}
        minWidth={minWidth}
        isFirst={isFirst}
      >
        {!textDecorationChild && (
          <ItemChild textTransformChild={textDecorationChild}>
            {value}
          </ItemChild>
        )}
        {!!textDecorationChild && (
          <ItemChild
            large={large}
            narrow={narrow}
            minWidth={minWidth}
            nbColumn={nbColumn}
          >
            <LinkChild
              href={`${hrefPrefix}${id}${hrefSuffix}`}
              textDecorationChild={textDecorationChild}
              target="_blank"
            >
              {value}
            </LinkChild>
          </ItemChild>
        )}
      </ItemDisplayChildContainer>
    </>
  );
};

ItemDisplayChild.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  large: PropTypes.bool,
  textDecorationChild: PropTypes.string,
  withRender: PropTypes.bool,
  hrefPrefix: PropTypes.string,
  hrefSuffix: PropTypes.string,
  id: PropTypes.string,
  nbColumn: PropTypes.number.isRequired,
};

ItemDisplayChild.defaultProps = {
  large: false,
  textDecorationChild: '',
  withRender: false,
  hrefPrefix: '',
  hrefSuffix: '',
  id: '',
};

export default ItemDisplayChild;
