import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { PropsHeader } from '../../interface';
import {
  HeaderContainer,
  TitleColumn,
  Icon,
  TextEllispis,
  HeaderFullContainer,
} from './styledComponents';
import Tooltip from '../../../Tooltip';
import ActionButton from '../ActionButton';
import { getRandomInt } from '../../../utils/format';
import { ORDER_TYPE } from '../../constants';

import iconSortedListDescDark from '../../../images/icon-sorted-desc-dark.svg';
import iconSortedListAscDark from '../../../images/icon-sorted-asc-dark.svg';
import iconSortDark from '../../../images/icon-sort-dark.svg';

import { getTheme } from '../../../utils/theme';

const icons = {
  dark: {
    default: iconSortDark,
    asc: iconSortedListAscDark,
    desc: iconSortedListDescDark,
  },
};

const HeaderRow = (props: PropsHeader): JSX.Element => {
  const { theme, headers, orderBy, orderType, setOrderColumn, hasNestedData } =
    props;

  const selectSortIcon = (propertyKey) => {
    const updatedTheme = getTheme(theme, 'expandableEmbeddedList');

    if (propertyKey === orderBy) {
      return orderType === ORDER_TYPE.DESCENDING
        ? icons[updatedTheme.header?.icon].desc
        : icons[updatedTheme.header?.icon].asc;
    }

    return icons[updatedTheme.header?.icon].default;
  };

  return (
    <HeaderFullContainer hasNestedData={hasNestedData}>
      <HeaderContainer hasNestedData={hasNestedData}>
        {headers.map((header, index) => {
          const random = getRandomInt();
          const isColumnSelected = header.propertyKey === orderBy;
          const uniqId = `header-${header.propertyKey}-${random}`;

          return (
            <Fragment key={uniqId}>
              {header.propertyKey !== 'action' && (
                <TitleColumn
                  isFirst={index === 0}
                  large={header.large}
                  narrow={header.narrow}
                  minWidth={header.minWidth}
                  nbColumn={headers.length}
                  selected={isColumnSelected}
                  hasNestedData={hasNestedData}
                >
                  <TextEllispis
                    interactive={header.isSortable}
                    onClick={() =>
                      header.isSortable && setOrderColumn(header.propertyKey)
                    }
                  >
                    {header.name}
                  </TextEllispis>
                  {header.isSortable && (
                    <Icon
                      onClick={() => setOrderColumn(header.propertyKey)}
                      width="16"
                      height="16"
                      src={selectSortIcon(header.propertyKey)}
                      alt="icon-order"
                      marginLeft="5px"
                      interactive
                    />
                  )}
                  {header.tooltipDisplay && (
                    <Tooltip
                      theme={theme}
                      text={header.tooltipDisplay}
                      place="top"
                    />
                  )}
                </TitleColumn>
              )}

              {header.propertyKey === 'action' && (
                <div style={{ flex: 4 }}>
                  <ActionButton open={false} key={uniqId} />
                </div>
              )}
            </Fragment>
          );
        })}
      </HeaderContainer>
    </HeaderFullContainer>
  );
};

HeaderRow.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      propertyKey: PropTypes.string.isRequired,
      isNumber: PropTypes.bool,
      unit: PropTypes.string,
      tooltipDisplay: PropTypes.string,
      large: PropTypes.bool,
    })
  ).isRequired,
  orderBy: PropTypes.string,
  orderType: PropTypes.string.isRequired,
  setOrderColumn: PropTypes.func.isRequired,
  hasNestedData: PropTypes.bool,
};

HeaderRow.defaultProps = {
  orderBy: null,
  hasNestedData: false,
};

export default HeaderRow;
