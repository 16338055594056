import i18next from 'i18next';
import moment from 'moment/moment';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { generateDefaultSheet, makeXLS } from '@commons/utils/makeXLS';
import { getClientStoreNameTranslation } from '@commons/utils/translations';

const getExportListColumns = (clientStoreName) => [
  {
    propertyKey: 'reference',
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_REFERENCE'),
  },
  {
    propertyKey: 'storeName',
    name: getClientStoreNameTranslation(clientStoreName),
  },
  {
    propertyKey: 'rushSupplier',
    name: i18next.t('ORDERS.RUSH_ORDERS.EXPORT_SUPPLIER'),
  },
  {
    propertyKey: 'createdAt',
    name: i18next.t('GENERAL.CREATION_DATE'),
    transform: (date) => moment(date).format('L'),
  },
  {
    propertyKey: 'receptionDate',
    name: i18next.t('GENERAL.DELIVERY_DATE'),
    transform: (date) => moment(date).format('L'),
  },
  {
    type: 'currency',
    propertyKey: 'totalPrice',
    name: i18next.t('GENERAL.TOTAL_EX_TAX'),
  },
];

export const exportList = (rushOrderData, clientStoreName, currency) => {
  const filename = i18next.t('ORDERS.RUSH_ORDERS.EXPORT_FILENAME', {
    date: moment().format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  });

  const sheets = [
    generateDefaultSheet(
      i18next.t('FEATURE.OPERATIONS.RUSH_ORDERS'),
      getExportListColumns(clientStoreName),
      rushOrderData,
      currency,
    ),
  ];

  return makeXLS(filename, sheets);
};
