import { isEmpty } from 'lodash';

/**
 * Filter a list of modules features with user rights
 *
 * @param {array} features - List of features
 * @param {array} userRights - List of user rights (contains the list of available features for the user)
 * @returns - A user rights filtered list of modules features
 */
export const filterModulesWithUserRights = (features, userRights) => {
  const filteredModulesAndContainers = features.filter(({ path }) => {
    if (!!path) {
      return userRights.some((feature) => feature.path === path);
    }
    return true;
  });
  return filteredModulesAndContainers;
};

/**
 * Filter a list of sub-modules with user rights
 *
 * @param {array} subModules - List of sub-modules
 * @param {array} userRights - List of user rights (contains the list of available features for the user)
 * @returns - A user rights filtered list of sub-modules features
 */
export const filterSubModulesModuleChildren = (subModules, userRights) => {
  const filteredSubModuleChildren = subModules.reduce((acc, subModule) => {
    const filteredChildren = filterModulesWithUserRights(subModule.children, userRights);

    if (!isEmpty(filteredChildren)) {
      acc.push({
        ...subModule,
        children: filteredChildren,
      });
    }

    return acc;
  }, []);

  return filteredSubModuleChildren;
};
