import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import utilLibs from '../../../../utils';

import {
  Container,
  ArrowContainer,
  ArrowIcon,
  ChartContainer,
  ItemContainer,
  ItemColorContainer,
  ChartBar,
  DisplayDay,
  ChartValue,
  DisplayDayContainer,
  DisplayStockIcon,
  ArrowContainerIcon,
} from './styledComponents';

const ARROW_ACTIVE = '/images/inpulse/arrow-up-ipblack-small.svg';
const ARROW_CLICK = '/images/inpulse/arrow-up-ipgreen-small.svg';
const ARROW_HOVER = '/images/inpulse/arrow-up-ipdgreen-small.svg';
const ARROW_DISABLE = '/images/inpulse/arrow-up-lmgrey-small.svg';
const DISPLAY_DAY = 10;

const ForecastChart = (props) => {
  const { forecastStock, yesterday, periodStartDate, periodEndDate, storeTimezone } = props;

  const [baseIndex, setBaseIndex] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [previousHover, setPreviousHover] = useState(false);
  const [nextHover, setNextHover] = useState(false);
  const [nextClick, setNextClick] = useState(false);
  const [previousClick, setPreviousClick] = useState(false);
  const [forecastToDisplay, setForecastToDisplay] = useState([]);
  const [isValidForecast, setIsValidForecast] = useState(false);
  const today = moment.tz(storeTimezone).format('YYYY-MM-DD');

  useEffect(() => {
    if (!forecastStock) {
      return;
    }

    getForecastPeriod();
  }, [baseIndex, forecastStock]);

  useEffect(() => {
    if (!forecastStock) {
      return;
    }

    getIfValidForecast();
  }, [forecastStock]);

  const getIfValidForecast = () => {
    const periodEndIndex = _.findIndex(
      forecastStock,
      (forecast) => forecast.date === periodEndDate,
    );

    // TODO Replace 0 with stock tampon value when it was implemented
    if (!!forecastStock[periodEndIndex] && forecastStock[periodEndIndex].theoricalStockUnit > 0) {
      setIsValidForecast(true);
    } else {
      setIsValidForecast(false);
    }
  };

  const getForecastPeriod = () => {
    const forecastPeriod = forecastStock.slice(baseIndex, baseIndex + DISPLAY_DAY);
    const forecastValue = forecastStock.map((forecast) =>
      forecast.realStockUnit == null ? forecast.theoricalStockUnit : forecast.realStockUnit,
    );
    const maxValue = (Math.max(...forecastValue) * 100) / 100;

    setForecastToDisplay(forecastPeriod);
    setMaxValue(maxValue);
  };

  const switchChart = (type) => {
    if (type === 'previous' && baseIndex > 0) {
      setBaseIndex(baseIndex - 1);
      setPreviousClick(false);
    }

    if (type === 'next' && baseIndex + DISPLAY_DAY <= forecastStock.length - 1) {
      setBaseIndex(baseIndex + 1);
      setNextClick(false);
    }
  };

  return (
    <Container>
      <ArrowContainer>
        <ArrowContainerIcon
          isDisable={baseIndex === 0}
          onClick={() => {
            if (baseIndex === 0) {
              return;
            }

            setPreviousClick(true);
            switchChart('previous');
          }}
          onMouseEnter={() => setPreviousHover(true)}
          onMouseLeave={() => setPreviousHover(false)}
        >
          <ArrowIcon
            isDisable={baseIndex === 0}
            right={false}
            src={
              baseIndex === 0
                ? ARROW_DISABLE
                : !!previousClick || !!previousHover
                ? !!previousHover
                  ? ARROW_HOVER
                  : ARROW_CLICK
                : ARROW_ACTIVE
            }
          />
        </ArrowContainerIcon>
      </ArrowContainer>
      <ChartContainer>
        {!!forecastToDisplay &&
          forecastToDisplay.map((forecast, index) => {
            const isToday = moment.tz(forecast.date, storeTimezone).isSame(today);
            const isYesterday = moment.tz(forecast.date, storeTimezone).isSame(yesterday);
            const forecastTotUnit =
              forecast.realStockUnit != null
                ? forecast.realStockUnit > 0
                  ? Math.round(forecast.realStockUnit * 100) / 100
                  : 0
                : forecast.theoricalStockUnit > 0
                ? Math.round(forecast.theoricalStockUnit * 100) / 100
                : 0;
            const isYesterdayReal = forecast.realStockUnit != null && isYesterday;
            const barHeight = !!maxValue ? (forecastTotUnit / maxValue) * 100 : 0;
            const isInPeriod = moment
              .tz(forecast.date, storeTimezone)
              .isBetween(periodStartDate, periodEndDate, 'day', '[]');
            const { color, weight } = utilLibs.getStyleAndColor(
              isInPeriod,
              isYesterday,
              forecast.date,
              periodStartDate,
              periodEndDate,
              isValidForecast,
              isYesterdayReal,
              forecastTotUnit,
            );

            return (
              <ItemContainer key={`${forecast.date}-${index}`} nbColumns={forecastToDisplay.length}>
                <ItemColorContainer
                  border={utilLibs.getChartBorder(
                    forecast.date === periodStartDate,
                    forecast.date === periodEndDate,
                    isInPeriod,
                  )}
                  isInPeriod={isInPeriod}
                  isValid={isValidForecast}
                >
                  <ChartValue
                    color={color}
                    isLast={forecast.date === periodEndDate}
                    isValid={isValidForecast}
                    weight={weight}
                  >
                    {forecastTotUnit}
                  </ChartValue>
                  <ChartBar barHeight={barHeight} color={color} />
                </ItemColorContainer>
                <DisplayDayContainer isYesterdayReal={isYesterdayReal}>
                  {isYesterdayReal && <DisplayStockIcon src={'/images/icon-tiny-grey-stock.svg'} />}
                  <DisplayDay weight={weight}>
                    {isToday || isYesterday
                      ? isToday
                        ? i18next.t('ORDERS.ORDERS.FORM_STOCK_TODAY_DISPLAY')
                        : i18next.t('ORDERS.ORDERS.FORM_STOCK_YESTERDAY_DISPLAY')
                      : moment.tz(forecast.date, storeTimezone).format('DD/MM')}
                  </DisplayDay>
                </DisplayDayContainer>
              </ItemContainer>
            );
          })}
      </ChartContainer>
      <ArrowContainer>
        <ArrowContainerIcon
          isDisable={baseIndex + DISPLAY_DAY === forecastStock.length}
          onClick={() => {
            if (baseIndex + DISPLAY_DAY === forecastStock.length) {
              return;
            }

            setNextClick(true);
            switchChart('next');
          }}
          onMouseEnter={() => setNextHover(true)}
          onMouseLeave={() => setNextHover(false)}
        >
          <ArrowIcon
            src={
              baseIndex + DISPLAY_DAY === forecastStock.length
                ? ARROW_DISABLE
                : !!nextClick || !!nextHover
                ? !!previousHover
                  ? ARROW_HOVER
                  : ARROW_CLICK
                : ARROW_ACTIVE
            }
            right
          />
        </ArrowContainerIcon>
      </ArrowContainer>
    </Container>
  );
};

export default ForecastChart;
