import { connect } from 'react-redux';
import React from 'react';

import { getClientInfo } from '@selectors/client';

import StepProductionPlanning from '../../../CreateProductModal/components/StepProductionPlanning';

const ConfigureProductionPlanningModal = (props) => {
  const {
    client: { hasMultipleServices },
    configureProductionData,
    setConfigureProductionData,
  } = props;

  const productionParameters = {
    price: configureProductionData.price,
    currency: configureProductionData.currency,
    launchDate: configureProductionData.launchDate,
    endDate: configureProductionData.endDate,
    hasStock: configureProductionData.hasStock,
  };

  return (
    <StepProductionPlanning
      hasMultipleServices={hasMultipleServices}
      productionParameters={productionParameters}
      setProductionParameters={setConfigureProductionData}
    />
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

export default connect(mapStateToProps)(ConfigureProductionPlanningModal);
