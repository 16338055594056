import { PersistentFilterTypes } from '@context/utils';

export const getPersistentFilters = (context) => [
  {
    contextParam: context.selectedSuppliers,
    updateFunction: context.setSelectedSuppliers,
    filterType: PersistentFilterTypes.APPLIED,
  },
  {
    contextParam: context.selectedBrands,
    updateFunction: context.setSelectedBrands,
    filterType: PersistentFilterTypes.APPLIED,
  },
  {
    contextParam: context.selectedStatus,
    updateFunction: context.setSelectedStatus,
    filterType: PersistentFilterTypes.APPLIED,
  },
  {
    contextParam: context.advancedFilters,
    updateFunction: context.setAdvancedFilters,
    filterType: PersistentFilterTypes.ADVANCED,
  },
  {
    contextParam: context.queryParams,
    updateFunction: context.setQueryParams,
    filterType: PersistentFilterTypes.ADVANCED,
  },
];
