import Proptypes from 'prop-types';
import React from 'react';

import { Container } from './styledComponents';

import SpinnerIcon from '../../../assets/icons/new-loader.svg';

const ComponentLoader = (props) => {
  const { width, height } = props;
  return (
    <Container>
      <img alt={'component-loader'} height={`${height}px`} src={SpinnerIcon} width={`${width}px`} />
    </Container>
  );
};

ComponentLoader.propTypes = {
  width: Proptypes.number,
  height: Proptypes.number,
  color: Proptypes.string,
};

ComponentLoader.defaultProps = {
  width: 40,
  height: 40,
};

export default ComponentLoader;
