import { connect } from 'react-redux';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import styled from 'styled-components';

import { canSetupForecastEvent } from '@selectors/actions/forecastActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { InpulseAddEvent } from './CreateNewEventButton';
import { InpulseCustomCalendar } from './CustomCalendar';
import { StyledCurrentDayTile } from './styledComponents';

export class ForecastsMainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      activeDate: null,
      activeService: 'tot',
      resetViewport: false,
      data: [],
    };
  }

  componentDidMount() {
    this.setState({
      startDate: moment.tz(this.props.storeTimezone).subtract(this.props.salesLimit, 'days'),
      endDate: moment.tz(this.props.storeTimezone).add(this.props.forecastsLimit, 'days'),
      activeDate: moment.tz(this.props.storeTimezone),
    });
    this.initialiseData();
  }

  componentWillUnmount() {
    this.props.clearForecasts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sales !== this.props.sales) {
      this.updateDataSales();
    }

    if (prevProps.forecasts !== this.props.forecasts) {
      this.updateDataForecasts();
    }

    if (prevProps.weather !== this.props.weather && this.props.weather.length) {
      this.updateDataWeather();
    }

    if (prevProps.lastYearSales !== this.props.lastYearSales) {
      this.updateDataPastSales();
    }

    if (
      (prevProps.storeId !== this.props.storeId ||
        prevProps.salesLimit !== this.props.salesLimit ||
        prevProps.forecastsLimit !== this.props.forecastsLimit) &&
      this.props.salesLimit &&
      this.props.forecastsLimit
    ) {
      this.initialiseData();
    }

    if (prevProps.storeId !== this.props.storeId) {
      this.setState({
        startDate: moment.tz(this.props.storeTimezone).subtract(this.props.salesLimit, 'days'),
        endDate: moment.tz(this.props.storeTimezone).add(this.props.forecastsLimit, 'days'),
        activeDate: moment.tz(this.props.storeTimezone),
        resetViewport: true,
      });
    }

    if (prevState.resetViewport !== this.state.resetViewport) {
      this.setState({ resetViewport: false });
    }
  }

  initialiseData() {
    const {
      salesLimit,
      forecastsLimit,
      services,
      client: { turnoverName, forecastProperty },
    } = this.props;

    const data = this.getDaysArray(
      salesLimit,
      forecastsLimit,
      services,
      forecastProperty,
      turnoverName,
    );

    this.setState({ data });
  }

  updateDataSales() {
    const { sales, storeTimezone } = this.props;

    const data = JSON.parse(JSON.stringify(this.state.data));

    const updatedData = data.map((day) => {
      sales.forEach((sale) => {
        if (
          moment.tz(sale.timestamp, storeTimezone).isSame(moment.tz(day.date, storeTimezone), 'day')
        ) {
          day.breakdownDatas.forEach((breakdown, index) => {
            day.breakdownDatas[index].sale = sale[breakdown.baseName];
          });
        }
      });
      return day;
    });

    const errorData = this.getErrors(updatedData);

    this.setState({ data: errorData });
  }

  updateDataForecasts() {
    const { forecasts, storeTimezone } = this.props;

    const data = JSON.parse(JSON.stringify(this.state.data));

    const updatedData = data.map((day) => {
      forecasts.forEach((forecast) => {
        if (
          moment
            .tz(forecast.timestamp, storeTimezone)
            .isSame(moment.tz(day.date, storeTimezone), 'day') &&
          (forecast.type === 'dynamic' || forecast.type === 'saved')
        ) {
          day.breakdownDatas.forEach((breakdown, index) => {
            day.breakdownDatas[index][forecast.type] = forecast[breakdown.baseName];
            if (
              (forecast.type === 'dynamic' && breakdown.inputValue === '') ||
              forecast.type === 'saved'
            ) {
              day.breakdownDatas[index].inputValue = forecast[breakdown.baseName];
              if (breakdown.baseName === 'ca') {
                day.breakdownDatas[index].totValue = forecast.tot || null;
              }
            }
          });
        }
      });
      return day;
    });

    const errorData = this.getErrors(updatedData);

    this.setState({ data: errorData });
  }

  getErrors(data) {
    data = JSON.parse(JSON.stringify(data));

    const errorData = data.map((day) => {
      day.breakdownDatas.forEach((breakdown, index) => {
        if (
          (breakdown.sale || breakdown.sale === 0) &&
          (breakdown.saved || breakdown.saved === 0)
        ) {
          day.breakdownDatas[index].error = breakdown.sale - breakdown.saved;
        }
      });

      return day;
    });

    return errorData;
  }

  updateDataWeather() {
    const { weather, storeTimezone } = this.props;

    const data = JSON.parse(JSON.stringify(this.state.data));

    const updatedData = data.map((day) => {
      weather.forEach((weatherDay) => {
        if (
          moment
            .tz(weatherDay.timestamp, storeTimezone)
            .isSame(moment.tz(day.date, storeTimezone), 'day')
        ) {
          day.breakdownDatas.forEach((breakdown, index) => {
            day.breakdownDatas[index].weather.tMin = Math.round(weatherDay.tMin);
            day.breakdownDatas[index].weather.tMax = Math.round(weatherDay.tMax);
            day.breakdownDatas[index].weather.img = weatherDay.lnkWeathericonWeatherdayrel.img;
            day.breakdownDatas[index].weather.desc =
              weatherDay.lnkWeathericonWeatherdayrel.description;
          });
        }
      });
      return day;
    });

    this.setState({ data: updatedData });
  }

  updateDataPastSales() {
    const { lastYearSales, storeTimezone } = this.props;

    const data = JSON.parse(JSON.stringify(this.state.data));

    const updatedData = data.map((day) => {
      lastYearSales.forEach((sale) => {
        if (
          moment
            .tz(sale.timestamp, storeTimezone)
            .isSame(
              moment
                .tz(day.date, storeTimezone)
                .subtract(1, 'year')
                .isoWeekday(moment.tz(day.date, storeTimezone).isoWeekday()),
              'day',
            )
        ) {
          day.breakdownDatas.forEach((breakdown, index) => {
            day.breakdownDatas[index].salePast = sale[breakdown.baseName];
          });
        }
      });
      return day;
    });

    this.setState({ data: updatedData });
  }

  getDaysArray(past, future, services, baseName, displayName) {
    const initBreakdown = {
      name: displayName,
      baseName: baseName,
      dynamic: null,
      saved: null,
      sale: null,
      salePast: null,
      inputValue: '',
      error: null,
      inputCheckFail: false,
      weather: {
        img: '',
        desc: '',
        tMin: null,
        tMax: null,
      },
    };

    const startDate = moment.tz(this.props.storeTimezone).startOf('day').subtract(past, 'days');
    let result = [];
    let breakdownData = [];
    if (services) {
      breakdownData = services.map((service) => {
        let breakdown = JSON.parse(JSON.stringify(initBreakdown));
        breakdown.name = service.displayName;
        breakdown.baseName = service.serviceName;
        return breakdown;
      });
    } else {
      breakdownData.push(initBreakdown);
    }

    const endDate = moment.tz(this.props.storeTimezone).startOf('day').add(future, 'days');

    while (startDate.isSameOrBefore(endDate)) {
      result.push({
        date: startDate.format(),
        events: [],
        breakdownDatas: breakdownData,
      });
      startDate.add(1, 'day');
    }
    return result;
  }

  changeActiveDate = (activeDate, breakdown) => {
    this.setState({ activeDate: activeDate, activeService: breakdown || 'tot' });
  };

  handleNextDay() {
    if (!this.state.activeDate.isSame(this.state.endDate)) {
      this.changeActiveDate(this.state.activeDate.clone().add(1, 'day'));
    } else {
      this.changeActiveDate(moment.tz(this.props.storeTimezone));
    }
  }

  render() {
    const {
      authorizedActions,
      className,
      events,
      hasOrder,
      hasPlanning,
      managerPrevDisplay,
      sales,
      forecasts,
      lastYearSales,
      productionMetricsByDates,
      services,
      storeId,
      user,
      wasteMetricPropertyKey,
      weather,
      lastYearWeather,
      eventSubCategories,
      loadStoreEvents,
      storeTimezone,
      client,
    } = this.props;

    const hasRightToSetupForecastEvent = canSetupForecastEvent(authorizedActions);

    return (
      <div className={className} id="forecasts-main-container-encloser">
        <StyledCurrentDayTile
          activeDate={this.state.activeDate}
          activeService={this.state.activeService}
          data={this.state.data.filter((data) =>
            moment
              .tz(data.date, storeTimezone)
              .isSame(moment.tz(this.state.activeDate, storeTimezone), 'day'),
          )}
          events={events}
          handleNextDay={() => this.handleNextDay()}
          hasOrder={hasOrder}
          hasPlanning={hasPlanning}
          managerPrevDisplay={managerPrevDisplay}
          pastChart={{
            sales: sales,
            forecasts: forecasts,
            lastYearSales: lastYearSales,
          }}
          productionMetricsByDates={productionMetricsByDates}
          services={services}
          storeId={storeId}
          storeTimezone={storeTimezone}
          updateActiveService={(name) => this.setState({ activeService: name })}
          user={user}
          wasteMetricPropertyKey={wasteMetricPropertyKey}
          weather={weather.concat(lastYearWeather)}
        />
        {hasRightToSetupForecastEvent && (
          <InpulseAddEvent
            eventSubCategories={eventSubCategories}
            loadStoreEvents={() => loadStoreEvents(storeId, true)}
            storeId={storeId}
          />
        )}
        <InpulseCustomCalendar
          activeDate={this.state.activeDate}
          changeActiveDate={this.changeActiveDate}
          data={this.state.data}
          displayName={client.turnoverName}
          endDate={this.state.endDate}
          events={events}
          eventSubCategories={eventSubCategories}
          loadStoreEvents={() => loadStoreEvents(storeId, true)}
          resetViewport={this.state.resetViewport}
          sales={sales}
          startDate={this.state.startDate}
          storeTimezone={storeTimezone}
          twoServices={services && services.length}
          weather={weather}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  weatherStation: state.baseReducer.weatherStation,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/forecast/forecast/turnover',
  ),
});

const mapDispatchToProps = (dispatch) => ({});

ForecastsMainContainer = connect(mapStateToProps, mapDispatchToProps)(ForecastsMainContainer);

const Styled = styled(ForecastsMainContainer)`
  width: 100%;
  height: 565px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export default Styled;
