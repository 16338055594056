import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  row-gap: 24px;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  row-gap: 8px;
`;

export const ToggleContainer = styled.div`
  width: 240px;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: row;

  column-gap: 8px;
`;

export const SwithContainer = styled.div`
  width: 32px;
`;

export const LabelContainer = styled.div`
  width: 240px;
  display: flex;
  flex-direction: row;

  column-gap: 8px;
`;
