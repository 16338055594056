import styled, { css } from 'styled-components';

export const ItemDisplayChildContainer = styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: flex-start;

  padding-left: 15px;

  overflow: hidden;
  text-overflow: ellipsis;

  flex: ${(props) => (props.large ? 2 : 1)};
  ${(props) =>
    !props.large &&
    props.withRender &&
    css`
      flex: 0;
      flex-basis: 30% !important;
    `}
  flex-basis: ${(props) =>
    props.large
      ? `calc(${(100 / props.nbColumn) * 2}% - ${
          props.isFirst ? '26px' : '0px'
        })`
      : `calc(${100 / props.nbColumn}% - ${props.isFirst ? '26px' : '0px'})`};

  ${(props) =>
    props.narrow &&
    css`
      width: fit-content;
      flex: none;
    `}

  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth - 25}px;
    `}

  ${(props) =>
    props.minWidth &&
    props.narrow &&
    css`
      min-width: ${props.minWidth}px;
    `}
`;

export const ItemChild = styled.div`
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  color: ${(props) => props.theme.childRow?.color};

  font: ${(props) => props.theme.childRow?.font};
`;

export const LinkChild = styled.a`
  color: ${(props) => props.theme.childRow?.link?.color};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: ${(props) =>
      props.textDecorationChild ? props.textDecorationChild : ''};
    color: ${(props) => props.theme.childRow?.link?.color};
  }

  &:active {
    color: ${(props) => props.theme.childRow?.link?.color};
  }
`;

export default {
  ItemChild,
  LinkChild,
  ItemDisplayChildContainer,
};
