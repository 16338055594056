import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 0px; // To make the overflow work if too long email
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const InputEmailContainer = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? `${props.height}px` : '40px')};
  max-height: 120px;

  overflow: auto;
  white-space: nowrap;

  border-radius: 8px;
  border: ${(props) => `1px solid ${props.theme.colors?.greys?.light}`};
  background: ${(props) =>
    props.isInputHovered
      ? props.theme.colors?.greys?.lighter
      : props.theme.colors?.greys?.lightest};
`;

export const FirstLetter = styled.span`
  ${(props) =>
    props.required &&
    css`
      color: ${props.disabled ? props.theme?.colors?.greys?.dark : props.theme?.colors?.infoRed};
    `}
`;

export const Label = styled.div`
  margin-bottom: 8px;
  font: ${(props) =>
    props.textBig ? props.theme.fonts?.textBigHeight16 : props.theme.fonts?.textSmall};

  color: ${(props) =>
    props.readOnly ? props.theme.colors?.greys?.dark : props.theme.colors?.greys?.darkest};
`;

export const ValidEmailContainer = styled.div`
  margin-left: 16px;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ValidEmail = styled.div`
  margin-top: 12px;
  width: fit-content;

  background: ${(props) => props.theme.colors?.greys?.lighter};
  border: ${(props) => `1px solid ${props.theme.colors?.greys?.light}`};
  border-radius: ${(props) => props.theme.borders?.radius?.weaker};

  font: ${(props) =>
    props.textBig ? props.theme.fonts?.textBigHeight16 : props.theme.fonts?.textSmall};
`;

export const TextEmail = styled.div`
  padding: 1px 8px;
`;

export const Item = styled.div`
  flex: 1;
`;

export const Input = styled.input`
  padding: 0;
  height: 38px;

  width: calc(100% - 16px);

  border: none;

  color: ${(props) => props.theme?.colors?.greys?.darkest};

  font: ${(props) =>
    props.textBig ? props.theme.fonts?.textBigHeight16 : props.theme.fonts?.textSmall};

  &:hover {
    background-color: ${(props) => props.theme.colors.greys.lighter};
  }

  &:invalid {
    color: ${(props) => props.theme.colors.infoRed};
    border-color: ${(props) => props.theme.colors.infoRed};
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.greys.dark};
    font: ${(props) => props.theme.fonts.textSmall};
  }
`;
