import { Tooltip } from '../../commons/utils/styledLibraryComponents';
import React from 'react';
import styled, { css } from 'styled-components';

const FirstLetter = styled.span`
  ${(props) =>
    props.required &&
    css`
      color: ${props.disabled ? props.theme?.colors?.greys?.dark : props.theme?.colors?.infoRed};
    `}
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;

  ${(props) =>
    props.isClickable &&
    css`
      cursor: pointer;
    `};

  ${(props) => ({ ...props.customStyle })};
`;

const InputComponent = (props) => {
  const required = props.label && props.label[0] === '*';
  const inputProps = {};

  if (props.type === 'number') {
    inputProps['step'] = 'any';
    inputProps['onKeyDown'] = (evt) =>
      (evt.keyCode === 69 || evt.keyCode === 190) && evt.preventDefault();
  }

  Object.keys(props).forEach((k) => {
    // Prevent React warning about unknown props
    if (
      ![
        'link',
        'tooltipText',
        'textBig',
        'iconSize',
        'useParentHeight',
        'error',
        'isUnchangeable',
        'isDisabled',
      ].includes(k)
    )
      inputProps[k] = props[k];
  });

  return (
    <div className={props.className}>
      {(props.label || props.icon || props.tooltipText || props.link) && (
        <div style={{ display: 'flex', marginBottom: '8px', alignItems: 'center' }}>
          {props.label && (
            <label htmlFor={props.name}>
              <FirstLetter
                disabled={props.readOnly}
                required={required}
              >{`${props.label[0]}`}</FirstLetter>
              {`${props.label.substring(1)} :`}
            </label>
          )}
          {/* {props.icon && <img src={props.icon} style={{ marginLeft: '10px' }} />} */}
          {/* Pour ajouter une icone sur l'Input, tout se passe dans le code CSS un peu plus bas dans ce fichier,
          j'ai utilisé des background-image. Sur le composant Input il faut utiliser le prop icon et mettre uniquement
          le nom de l'icon comme sur le Figma. Par exemple l'icon de la loupe sur le Figma s'appelle Search, donc on
          met icon={'search'} et on rajoute iconSize={'small'} pour utiliser la petite version de l'icon (qui est d'ailleurs
          plutôt la version standard mais il faudrait dans ce cas renommer toutes les icons)
          Toutes les icons sont dans le dossier /public/images/inpulse et ont un naming spécific qui est
          <Nom de l'icon>-<Couleur>-(<Size>?).svg */}
          {props.tooltipText && props.tooltipText.length > 0 && (
            <div style={{ marginTop: '-1px' }}>
              <Tooltip text={props.tooltipText} />
            </div>
          )}
          {props.link && (
            <div style={{ marginTop: '-1px' }}>
              <Icon
                customStyle={props.link.customStyle}
                src={'/images/inpulse/open-a-new-black-small.svg'}
                isClickable
                onClick={props.link.onClick}
              />
            </div>
          )}
        </div>
      )}
      {/* Dedicated props for readonly attribute: "unchangeable", since readonly and disabled are mixed within the use of this component */}
      {/* TODO: Distinguish readonly and disabled state for input component */}
      <input {...inputProps} disabled={props.readOnly} readOnly={props.isUnchangeable} />
    </div>
  );
};

export const Input = styled(InputComponent)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font: ${(props) => props.theme.fonts?.textSmall};
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.useParentHeight ? '100%' : '64px')};

  label {
    font: ${(props) =>
      props.textBig ? props.theme.fonts?.textBigHeight16 : props.theme.fonts?.textSmall};
    margin-bottom: 0 !important;

    color: ${(props) =>
      props.readOnly ? props.theme.colors?.greys?.dark : props.theme.colors?.greys?.darkest};
  }

  input {
    height: 40px;
    -moz-appearance: ${(props) => (props.appearance === 'number' ? undefined : 'textfield')};
    padding: 12px 16px;

    color: ${(props) => props.theme.colors.greys.darkest};
    background-color: ${(props) => props.theme.colors.greys.lightest};
    border: ${(props) => ` 1px solid ${props.theme.colors.greys.light}`};
    border-radius: 8px;

    ${(props) =>
      props.error &&
      css`
        border: ${(props) => ` 1px solid ${props.theme.colors.infoRed} !important`};
        color: ${(props) => `${props.theme?.colors?.infoRed} !important`};
      `}

    ${(props) =>
      props.icon &&
      css`
        padding-left: 40px;
        background-image: url('/images/inpulse/${props.icon}-${props.value
          ? 'black'
          : 'dmgrey'}${props.iconSize === 'small' && '-small'}.svg');
        background-repeat: no-repeat;
        background-position: 14px center;
      `}

    ${(props) =>
      props.width &&
      css`
        width: ${props.width};
      `}

    &:hover {
      background-color: ${(props) => props.theme.colors.greys.lighter};
    }

    &:focus {
      color: ${(props) => props.theme.colors.greys.darkest};
      background-color: ${(props) => props.theme.colors.greys.lightest};
      border-color: ${(props) => props.theme.colors.greys.darkest};

      ${(props) =>
        props.icon &&
        css`
          background-image: url('/images/inpulse/${props.icon}-black${props.iconSize === 'small' &&
          '-small'}.svg');
        `}
    }

    &:invalid {
      color: ${(props) => props.theme.colors.infoRed};
      border-color: ${(props) => props.theme.colors.infoRed};

      ${(props) =>
        props.icon &&
        css`
          background-image: url('/images/inpulse/${props.icon}-red${props.iconSize === 'small' &&
          '-small'}.svg');
        `}
    }

    &:disabled {
      color: ${(props) => props.theme.colors.greys.darkest};
      border-color: ${(props) => props.theme.colors.greys.light};
      background-color: ${(props) => props.theme.colors.greys.light};
      cursor: not-allowed;

      ${(props) =>
        props.icon &&
        css`
          background-image: url('/images/inpulse/${props.icon}-${props.value
            ? 'black'
            : 'white'}${props.iconSize === 'small' && '-small'}.svg');
        `}
    }
  }

  input::placeholder {
    color: ${(props) => props.theme.colors.greys.dark};
    font: ${(props) => props.theme.fonts.textSmall};
  }

  input:disabled::placeholder {
    color: ${(props) => props.theme.colors.greys.lightest};
  }
`;
