import { cloneDeep } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import {
  makeXLS,
  generateDefaultSheet,
  generateDatesSheetFromStartEndDate,
  generateStoresSheet,
} from '@commons/utils/makeXLS';

import getColumnsTable from './columns';

const formatAnalyticsForExportXLS = (analytics, selectedMetric) => {
  const ratioColumns = ['conformity', 'cadence'];

  const unformattedColumns = ['date', 'realTurnoverInclTax'];

  const analyticsClone = cloneDeep(analytics);

  return analyticsClone.map((analyticsLine) => {
    Object.keys(analyticsLine).forEach((analyticsField) => {
      if (ratioColumns.includes(analyticsField)) {
        analyticsLine[analyticsField] = Math.round(analyticsLine[analyticsField] * 10000) / 100;
      } else if (!unformattedColumns.includes(analyticsField)) {
        analyticsLine[analyticsField] = Math.round(analyticsLine[analyticsField]);
      } else if (selectedMetric.key === 'unit' && analyticsField === 'realTurnoverInclTax') {
        analyticsLine[analyticsField] = null;
      }
    });

    return analyticsLine;
  });
};

const getExportFile = ({
  data,
  endDate,
  currency,
  storeName,
  startDate,
  selectedStores,
  selectedMetric,
  analyticsColumns,
}) => {
  const clientStoreName = getClientStoreNameTranslation(storeName, true);

  const formattedAnalytics = formatAnalyticsForExportXLS(data, selectedMetric);

  const sheets = [
    generateDefaultSheet(
      `${i18next.t('GENERAL.LABEL_BY')} ${i18next
        .t('PRODUCTION.REAL_TIME.LABEL_DATE')
        .toLowerCase()} ${i18next.t('GENERAL.LABEL_IN').toLowerCase()} ${
        selectedMetric.key === 'turnover'
          ? i18next.t('PRODUCTION.REAL_TIME.METRIC_TURNOVER')
          : i18next.t('PRODUCTION.REAL_TIME.METRIC_UNIT')
      }`,
      getColumnsTable(currency, selectedMetric.key, analyticsColumns),
      formattedAnalytics,
      currency,
    ),
    generateStoresSheet(clientStoreName, selectedStores),
    generateDatesSheetFromStartEndDate(
      moment(startDate).format('YYYY-MM-DD'),
      moment(endDate).format('YYYY-MM-DD'),
    ),
  ];

  makeXLS(i18next.t('PRODUCTION.BY_DATE.EXPORT_FILENAME'), sheets);
};

export default getExportFile;
