import axios from '../core/http';

import { config } from '../config';

import 'moment/locale/fr';
import moment from 'moment';
moment.locale('fr');

const baseApiUrl = config.baseApiUrl;

function patchEvent(
  id,
  clientId,
  storeId,
  eventCategoryId,
  eventSubCategoryId,
  startDate,
  endDate,
  input1,
  input2,
  input3,
  impact,
) {
  return axios
    .patch(
      baseApiUrl + '/NewEvents',
      {
        //CHANGE HERE TO /EVENTS TO SUPPORT NEW EVENTS TABLE
        id: id || null,
        clientId: clientId || null,
        storeId: storeId || null,
        eventCategoryId: eventCategoryId,
        eventSubCategoryId: eventSubCategoryId,
        startDate: startDate,
        endDate: endDate,
        input1: input1,
        input2: input2,
        input3: input3,
        impact: impact,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function deleteEventById(id) {
  return axios
    .delete(baseApiUrl + '/NewEvents/' + id, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const event = {
  patchEvent,
  deleteEventById,
};
