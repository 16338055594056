import i18next from 'i18next';
import storeService from '../../../services/store';

/**
 * Handle the fetch of the stores associated to the current user
 *
 * @param {Function} showMessage      - The method to display message relating to the request status
 * @param {Boolean} activeStoresOnly  - (optional) Whether we only want to retrieve active store
 *
 * @returns {Promise<Store[]>} The list of stores on which the current user belongs
 */
export async function fetchStoresUser(showMessage, activeStoresOnly) {
  try {
    return storeService.getStoresOfUser(activeStoresOnly);
  } catch (err) {
    if (showMessage) {
      showMessage(i18next.t('PRODUCTION.PRODUCTION.TOP_ERROR_MESSAGE_STORES'), 'error');
    }

    return [];
  }
}

export default {
  fetchStoresUser,
};
