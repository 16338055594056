import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const InpulseLogo = styled.img`
  position: absolute;
  left: 48px;
  top: 48px;
  width: 120px;
  height: 40px;

  cursor: ${(props) => (props.interactive ? 'pointer' : 'default')};
`;

export const GreyContainer = styled.div`
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.colors.greys.lighter};
`;

export const PictureContainer = styled.img`
  height: 100vh;
  object-fit: cover;
  width: calc(100vw - 328px);
  position: absolute;
  left: 328px;
  top: 0;
`;

export const ActionContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 48px;
  transform: translateY(-50%);
  width: 560px;
  height: 430px;
`;

export default {
  Container,
  InpulseLogo,
  GreyContainer,
  PictureContainer,
  ActionContainer,
};
