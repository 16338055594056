import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 48px;
  position: relative;

  &.extra-margin {
    padding-top: 88px;
  }

  .deepsight-item-list-bottom-navigation-bar {
    width: 100%;
    margin-left: 0px;
  }

  .deepsight-label {
    text-transform: none;
  }

  .items-list-editable-tag {
    border-radius: 4px;
    background: #ffffff;
    cursor: pointer;

    > img {
      width: 15px;
      margin-left: 0px;
      margin-right: 2px;
      margin-bottom: 0px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const LabelStore = styled.div`
  height: 100%;
  display: flex;
  min-width: 38px;
  width: fit-content;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div`
  font: ${(props) => props.theme.fonts?.textBig};
  color: ${(props) => props.theme.colors?.greys?.darkest};

  ${(props) => {
    if (props.bold) {
      return css`
        font: ${(props) => props.theme.fonts?.textMiddleBold};
      `;
    }
  }}

  ${(props) => {
    if (props.capitalize) {
      return css`
        text-transform: capitalize;
      `;
    }
  }}
`;
