import i18next from 'i18next';
import moment from 'moment';
import React from 'react';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { RadioButton, Tooltip } from '@commons/utils/styledLibraryComponents';
import { SingleDatePicker } from '@commons/DatePickers/SingleDatePicker';
import Text from '@commons/Text';

import { SSPM_MODAL_STEPS } from '../sspmModalsSteps';

import {
  Container,
  ContentContainer,
  RadioButtonContainer,
  WildCard,
  LabelContainer,
  DatePickerContainer,
  TooltipContainer,
} from './styledComponents';

const ConfigureNextAvailabilitiesContent = ({
  nextAvailabilitiesData,
  setNextAvailabilitiesData,
}) => (
  <Container>
    <ContentContainer>
      <RadioButtonContainer>
        <RadioButton
          selected={true}
          size="small"
          value={nextAvailabilitiesData.available}
          onChange={() =>
            setNextAvailabilitiesData({
              ...nextAvailabilitiesData,
              available: true,
            })
          }
        />
        <Text>{i18next.t('ADMIN.SUPPLIER_PRODUCTS.SET_AVAILABLE_FOR_ORDER')}</Text>
      </RadioButtonContainer>
      <RadioButtonContainer>
        <RadioButton
          selected={false}
          size="small"
          value={nextAvailabilitiesData.available}
          onChange={() =>
            setNextAvailabilitiesData({
              ...nextAvailabilitiesData,
              available: false,
            })
          }
        />
        <Text>{i18next.t('ADMIN.SUPPLIER_PRODUCTS.SET_UNAVAILABLE_FOR_ORDER')}</Text>
      </RadioButtonContainer>
      <DatePickerContainer>
        <LabelContainer>
          <span>
            <WildCard>*</WildCard>
            {i18next.t('ADMIN.SUPPLIER_PRODUCTS.CHANGE_AVAILABILITY_DATE_PICKER_LABEL')}
          </span>
          <TooltipContainer>
            <Tooltip
              text={i18next.t('ADMIN.SUPPLIER_PRODUCTS.CHANGE_AVAILABILITY_DATE_PICKER_TOOLTIP')}
            />
          </TooltipContainer>
        </LabelContainer>
        <SingleDatePicker
          customStyle={{ position: 'inherit' }}
          date={nextAvailabilitiesData.startDate}
          maxPastDate={moment().startOf('day').add(1, 'days')}
          timezone="Europe/Paris" // TODO - [TIMEZONES] To be replaced by IPP-5229 Admin Module
          onDateChange={(selectedDate) => {
            setNextAvailabilitiesData({
              ...nextAvailabilitiesData,
              startDate: selectedDate,
            });
          }}
        />
      </DatePickerContainer>
    </ContentContainer>
  </Container>
);

export const getNextAvailabilitiesModalWithoutWarningStep = ({
  nextAvailabilitiesData,
  setNextAvailabilitiesData,
  handleCloseCleanUp,
  handleNextAvailabilitiesScheduling,
}) => ({
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      handleClick: handleNextAvailabilitiesScheduling,
      preventClosing: true,
      isDisabled: !nextAvailabilitiesData.startDate,
    },
  ],
  title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_TITLE_SCHEDULE_AVAILABILITIES'),
  icon: '/images/inpulse/pen-black-small.svg',
  type: 'action',
  handleCloseCleanUp,
  component: ConfigureNextAvailabilitiesContent,
  data: {
    nextAvailabilitiesData,
    setNextAvailabilitiesData,
  },
  height: 'auto',
});

export const getNextAvailabilitiesModal = ({
  currentStep,
  setCurrentStep,
  nextAvailabilitiesData,
  setNextAvailabilitiesData,
  handleCloseCleanUp,
  handleNextAvailabilitiesScheduling,
  storeName,
}) => {
  if (currentStep === SSPM_MODAL_STEPS.CONFIGURE_NEXT_AVAILABILITIES) {
    return {
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_SAVE_BUTTON(),
          handleClick: () => setCurrentStep(SSPM_MODAL_STEPS.CONFIRM_NEXT_AVAILABILITIES_CHANGES),
          preventClosing: true,
          isDisabled: !nextAvailabilitiesData.startDate,
        },
      ],
      title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_TITLE_SCHEDULE_AVAILABILITIES'),
      icon: '/images/inpulse/pen-black-small.svg',
      type: 'action',
      handleCloseCleanUp,
      component: ConfigureNextAvailabilitiesContent,
      data: {
        nextAvailabilitiesData,
        setNextAvailabilitiesData,
      },
      height: 'auto',
    };
  }

  return getConfirmationModal({
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_CONFIRM_BUTTON(),
        handleClick: handleNextAvailabilitiesScheduling,
        preventClosing: true,
      },
    ],
    handleCloseCleanUp,
    title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_WARNING_ALL_STORES_WILL_BE_IMPACTED', {
      storeName,
    }),
    content: i18next.t('ADMIN.SUPPLIER_PRODUCTS.NEXT_AVAILABILITIES_WARNING_MODAL_CONTENT', {
      storeName,
    }),
    icon: '/images/inpulse/warning-white-small.svg',
  });
};
