import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 280px);
  display: flex;
  position: relative;

  // to handle litte dot cropping when advanced filters are set
  > div > div > div {
    padding: 8px 24px;
  }
`;
