import styled, { css } from 'styled-components';

export const Container = styled.div`
  z-index: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  width: 100%;

  padding: 0 24px;
  background: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.greys.lighty};
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Chevron = styled.img`
  padding: 0 8px;
`;

export const Label = styled.div`
  font: ${({ theme, isLast }) =>
    isLast ? theme.fonts.textBigHeight16Bold : theme.fonts.textBigHeight16};
  color: ${({ theme, isLast }) =>
    isLast ? theme.colors.blacks.ipBlack1 : theme.colors.blacks.ipBlack5};

  ${({ isLast }) =>
    isLast &&
    css`
      width: 400px;
    `}
`;

export const ContainerAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  padding: 0px 24px;
`;
