import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  overflow: hidden;

  background-color: white;
`;

export const ContainerContent = styled.div`
  background: ${(props) => props.theme.colors.greys.lighter};

  width: 100%;
  position: relative;
  height: calc(100% - 40px);

  overflow: scroll;

  background: ${(props) => props.theme.colors.greys.lighter};

  .planning-analytics-corner-ref {
    height: 48px;

    margin-top: 3px;
    padding-top: 0px;
  }

  .planning-analytics-selectors {
    padding-top: 65px;
  }
`;

export const ContainerFlexMargin = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ContainerMargin = styled.div`
  width: 20px;
  bottom: 15px;

  position: relative;
  background-color: ${(props) => props.theme.colors.greys.lighter};
  height: calc(100vh - 225px);
`;

export const ContainerTableView = styled.div`
  overflow-x: scroll;
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 0;
  width: calc(100% - 20px);
  margin-right: 20px;

  > div > div:first-child {
    margin: 0 20px 0 20px;
  }
`;

export const ContainerEmptyState = styled.div`
  vertical-align: middle;
  height: calc(100vh - 225px);
  margin-top: 20px;
`;
