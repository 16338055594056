import React from 'react';
import Proptypes from 'prop-types';

import {
  YAxisContainer,
  YAxisSmallLine,
  YAxisBigContainer,
  YAxisValue,
  YAxisContainerValue,
  YAxisContainerSmallLine,
} from './styledComponents';

import { YAxisProps } from '../../interfaces';

const YAxis = (props: YAxisProps): JSX.Element => {
  const { height } = props;
  return (
    <>
      <YAxisBigContainer>
        <YAxisContainerValue>
          <YAxisValue>{height}</YAxisValue>
          <YAxisValue>{(height / 4) * 2}</YAxisValue>
          <YAxisValue>{height / 4}</YAxisValue>
          <YAxisValue>0</YAxisValue>
        </YAxisContainerValue>
        <YAxisContainer />
        <YAxisContainerSmallLine>
          <div style={{ flex: 1 }}>
            <YAxisSmallLine />
          </div>
          <div style={{ flex: 1 }}>
            <YAxisSmallLine />
          </div>
          <div style={{ flex: 1 }}>
            <YAxisSmallLine />
          </div>
        </YAxisContainerSmallLine>
      </YAxisBigContainer>
    </>
  );
};

YAxis.propTypes = {
  height: Proptypes.number.isRequired,
};

export default YAxis;
