import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputContainer = styled.div`
  min-height: 68px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
`;

export const ContentWhiteCard = styled.div`
  gap: 8px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export default {
  Container,
  InputContainer,
};
