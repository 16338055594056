import { useQueryParams, StringParam, NumberParam, JsonParam } from 'use-query-params';
// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';

import { Buffer } from 'buffer';

export const ENUM_QUERY_PARAMS = {
  SEARCH: 'search',
  FILTERS: 'filters',
  ORDER_BY: 'order_by',
  ORDER_TYPE: 'order_type',
  CURRENT_PAGE: 'current_page',
  MAX_PER_PAGE: 'max_per_page',
};

export const DEFAULT_QUERY_PARAMS = {
  ORDER_BY: '',
  ORDER_TYPE: '',
  CURRENT_PAGE: 1,
  MAX_PER_PAGE: 20,
  SEARCH: undefined,
  FILTERS: undefined,
};

export const ORDER_TYPES = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

export const encodeBase64 = (filters) => Buffer.from(JSON.stringify(filters)).toString('base64');

export const decodeBase64 = (encodedString) => {
  if (!encodedString) {
    return null;
  }

  const decodedString = Buffer.from(encodedString, 'base64').toString();

  try {
    const json = JSON.parse(decodedString);

    return json;
  } catch (e) {
    return null;
  }
};

export function useListViewQueryParams(useDefaultParams = true) {
  const [queryParams, setQueryParams] = useQueryParams({
    [ENUM_QUERY_PARAMS.FILTERS]: JsonParam,
    [ENUM_QUERY_PARAMS.SEARCH]: StringParam,
    [ENUM_QUERY_PARAMS.ORDER_BY]: StringParam,
    [ENUM_QUERY_PARAMS.ORDER_TYPE]: StringParam,
    [ENUM_QUERY_PARAMS.CURRENT_PAGE]: NumberParam,
    [ENUM_QUERY_PARAMS.MAX_PER_PAGE]: NumberParam,
  });

  const initializeQueryParam = () => {
    if (!queryParams[ENUM_QUERY_PARAMS.ORDER_BY]) {
      setQueryParams({ [ENUM_QUERY_PARAMS.ORDER_BY]: 'name' }, 'replaceIn');
    }
    if (!queryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]) {
      setQueryParams({ [ENUM_QUERY_PARAMS.ORDER_TYPE]: 'asc' }, 'replaceIn');
    }

    if (!queryParams[ENUM_QUERY_PARAMS.FILTERS]) {
      setQueryParams({ [ENUM_QUERY_PARAMS.FILTERS]: undefined }, 'replaceIn');
    }
  };

  useEffect(() => {
    if (!queryParams[ENUM_QUERY_PARAMS.SEARCH]) {
      setQueryParams({ [ENUM_QUERY_PARAMS.SEARCH]: DEFAULT_QUERY_PARAMS.SEARCH }, 'replaceIn');
    }
    if (!queryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]) {
      setQueryParams(
        { [ENUM_QUERY_PARAMS.MAX_PER_PAGE]: DEFAULT_QUERY_PARAMS.MAX_PER_PAGE },
        'replaceIn',
      );
    }
    if (!queryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE]) {
      setQueryParams(
        { [ENUM_QUERY_PARAMS.CURRENT_PAGE]: DEFAULT_QUERY_PARAMS.CURRENT_PAGE },
        'replaceIn',
      );
    }
    if (useDefaultParams) {
      initializeQueryParam();
    }
  }, []);

  return [
    {
      [ENUM_QUERY_PARAMS.CURRENT_PAGE]: queryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE],
      [ENUM_QUERY_PARAMS.MAX_PER_PAGE]: queryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE],
      [ENUM_QUERY_PARAMS.SEARCH]: queryParams[ENUM_QUERY_PARAMS.SEARCH],
      [ENUM_QUERY_PARAMS.ORDER_BY]: queryParams[ENUM_QUERY_PARAMS.ORDER_BY],
      [ENUM_QUERY_PARAMS.ORDER_TYPE]: queryParams[ENUM_QUERY_PARAMS.ORDER_TYPE],
      [ENUM_QUERY_PARAMS.FILTERS]: decodeBase64(queryParams[ENUM_QUERY_PARAMS.FILTERS]),
    },
    {
      [ENUM_QUERY_PARAMS.CURRENT_PAGE]: (value) =>
        setQueryParams({ [ENUM_QUERY_PARAMS.CURRENT_PAGE]: value }, 'replaceIn'),
      [ENUM_QUERY_PARAMS.MAX_PER_PAGE]: (value) =>
        setQueryParams({ [ENUM_QUERY_PARAMS.MAX_PER_PAGE]: value }, 'replaceIn'),
      [ENUM_QUERY_PARAMS.SEARCH]: (value) =>
        setQueryParams({ [ENUM_QUERY_PARAMS.SEARCH]: value }, 'replaceIn'),
      [ENUM_QUERY_PARAMS.ORDER_BY]: (value) =>
        setQueryParams({ [ENUM_QUERY_PARAMS.ORDER_BY]: value }, 'replaceIn'),
      [ENUM_QUERY_PARAMS.ORDER_TYPE]: (value) =>
        setQueryParams({ [ENUM_QUERY_PARAMS.ORDER_TYPE]: value }, 'replaceIn'),
      [ENUM_QUERY_PARAMS.FILTERS]: (value) =>
        setQueryParams({ [ENUM_QUERY_PARAMS.FILTERS]: encodeBase64(value) }, 'replaceIn'),
    },
  ];
}

export const withListViewQueryParamHookHOC = (Component) => (props) => {
  const [listViewQueryParams, setListViewQueryParams] = useListViewQueryParams();

  return (
    <Component
      listViewQueryParams={listViewQueryParams}
      setListViewQueryParams={setListViewQueryParams}
      {...props}
    />
  );
};

export default {
  withListViewQueryParamHookHOC,
  useListViewQueryParams,
  ENUM_QUERY_PARAMS,
};
