import styled, { css } from 'styled-components';

export const TooltipAssociate = styled.div`
  text-align: center;
  font-family: ${(props) => props.theme.fonts?.families.secondary || 'proximanovabold, serif'};
  border-radius: 3px;
  background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 8px 10px 10px;
  height: 18px;
  &:hover {
    cursor: pointer;
  }

  ${(props) => {
    if (props.theme.colors) {
      return css`
        width: fit-content;
        min-height: 24px;

        padding: 4px 8px;

        display: flex;
        align-items: center;

        border-radius: 4px;
        font: ${(props) => props.theme.fonts?.textSmall};

        box-shadow: none;
        border: 1px solid ${props.theme.colors?.infoGreen};
      `;
    }
  }}
`;

export const TextContent = styled.div`
  color: #2cd696;
  display: flex;

  ${(props) => {
    if (props.theme.colors) {
      return css`
        color: ${props.theme.colors?.infoGreen};
      `;
    }
  }}
`;

export const Text = styled.div`
  margin-right: 6px;
  font-size: 10px;
  text-transform: uppercase;
`;

export const rotate = {
  transform: 'rotate(180deg)',
};
