import styled from 'styled-components';

export const Text = styled.div`
  text-align: left;

  &.ref-name {
    font: ${(props) => props.theme.fonts?.textBig};
    color: ${(props) => props.theme.colors.greys.darkest};
  }

  &.ref-code {
    margin-top: 5px;

    font: ${(props) => props.theme.fonts?.textBig};

    color: ${(props) => props.theme.colors?.greys.darker};
  }
`;
