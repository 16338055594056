import { useState } from 'react';

/**
 * Custom hook to call whenever a NestedList with pagination is used.
 *
 * Usage example in: OrderAnalyticsPriceEvolution
 *
 * @returns An object containing paginator properties
 */
export const usePaginatorState = (props) => {
  const { defaultCurrentPage, defaultMaxPerPage } = props || {};

  const [currentPage, setCurrentPage] = useState(defaultCurrentPage || 1);
  const [maxPerPage, setMaxPerPage] = useState(defaultMaxPerPage || 20);

  const maxPerPageOptions = [10, 20, 50, 100];

  return { currentPage, setCurrentPage, maxPerPage, setMaxPerPage, maxPerPageOptions };
};
