import mixpanel from 'mixpanel-browser';

const MixPanelSingleton = (function () {
  const constructor = function () {
    // To know whether the mixpanel instance has been initialized
    this._loaded = false;

    /**
     * Handle the initialization of the mixpanel instance used to send metrics by linking the given project id
     *
     * @param {String} projectId - The id of the project on which send metrics
     *
     * @returns {void}
     */
    this.init = (projectId) => {
      mixpanel.init(projectId);

      this._loaded = true;
    };

    /**
     * Identity a user profile when this one just logged in within the app
     *
     * @param {Object}  -
     *
     * @returns {void}
     */
    this.identity = ({
      id,
      role,
      name,
      email,
      clientId,
      isInpulse,
      appVersion,
      clientName,
      clientStatus,
    }) => {
      if (!this._loaded) {
        return;
      }

      // Use if of the user to identity "People" on mix panel
      mixpanel.identify(id);

      // Complete "People" profile without user account details
      mixpanel.people.set({
        $id: id,
        $role: role,
        $name: name,
        $email: email,
        $clientId: clientId,
        $isInpulse: isInpulse,
        $appVersion: appVersion,
        $clientName: clientName,
        $clientStatus: clientStatus,
      });
    };

    /**
     * Handle the tracking of specific metric with mixpanel and attach custom properties when defined
     *
     * @param {Enum<EVENTS>} event  - The type of event to send
     * @param {Object} properties   - (optional) The addition properties to attach to the event
     *
     * @returns {void}
     */
    this.track = (event, properties = {}) => {
      if (!this._loaded) {
        return;
      }

      mixpanel.track(event, properties);
    };
  };

  let instance = null;

  return new (function () {
    this.getInstance = () => {
      if (instance == null) {
        instance = new constructor();

        // To make sure that constructor cannot be called again
        instance.constructor = null;
      }

      return instance;
    };
  })();
})();

export default MixPanelSingleton;
