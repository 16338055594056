import PropTypes from 'prop-types';
import React from 'react';

import { DisabledTooltipContainer } from './styledComponents';

export const DisabledTooltip = (props) => {
  const { text } = props;

  return <DisabledTooltipContainer>{text}</DisabledTooltipContainer>;
};

DisabledTooltip.propTypes = {
  text: PropTypes.string,
};

DisabledTooltip.defaultProps = {
  text: '',
};
