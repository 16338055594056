import styled, { css } from 'styled-components';

export const NameContainer = styled.div`
  width: 100%;
  padding: 0px 5px;
  height: inherit;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DateContainer = styled.div`
  font-weight: ${(props) => (props.bold ? '700' : '400')};

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const TextCustomRender = styled.div`
  color: ${(props) => props.theme.colors?.greys.darkest};

  font: ${(props) => props.theme.fonts?.textBig};

  ${(props) => {
    if (props.highlighted) {
      return css`
        font: ${(props) => props.theme.fonts?.textMiddleBold};
        color: ${(props) => props.theme.colors?.greys?.darkest || '#2176FA'};
      `;
    }
  }}
`;

export const SupplierLabelRender = styled.div`
  min-width: '115px';
  margin-left: '2px';
  padding: '5px 0px';
`;
