import i18next from 'i18next';

const PRODUCTION_PLANNING = {
  getText: () => i18next.t('FEATURE.OPERATIONS.PRODUCTION'),
  path: '/production/operations',
  getBreadCrumbText: () => i18next.t('FEATURE.OPERATIONS.PRODUCTION_BREADCRUMB'),
};

const STOCK_PRODUCTION = {
  getText: () => i18next.t('FEATURE.OPERATIONS.STOCKS'),
  path: '/production/stocks',
  getBreadCrumbText: () => i18next.t('FEATURE.OPERATIONS.STOCKS_BREADCRUMB'),
};

const LOSS_PRODUCTION = {
  getText: () => i18next.t('FEATURE.OPERATIONS.LOSSES'),
  path: '/production/losses',
  getBreadCrumbText: () => i18next.t('FEATURE.OPERATIONS.LOSSES_BREADCRUMB'),
};

const PRODUCTION_SUBMODULE = {
  getText: () => i18next.t('FEATURE.OPERATIONS.PRODUCTION'),
  icon: '/images/production.svg',
  children: [PRODUCTION_PLANNING, STOCK_PRODUCTION, LOSS_PRODUCTION],
};

const ORDER = {
  getText: () => i18next.t('FEATURE.OPERATIONS.ORDERS'),
  path: '/order/orders',
};

const RUSH_ORDER = {
  getText: () => i18next.t('FEATURE.OPERATIONS.RUSH_ORDERS'),
  path: '/order/rush-orders',
  getBreadCrumbText: () => i18next.t('FEATURE.OPERATIONS.RUSH_ORDERS_BREADCRUMB'),
};

const ORDER_SUBMODULE = {
  getText: () => i18next.t('FEATURE.OPERATIONS.ORDERS'),
  icon: '/images/orders.svg',
  children: [ORDER, RUSH_ORDER],
};

const STOCK = {
  getText: () => i18next.t('FEATURE.OPERATIONS.INVENTORIES'),
  path: '/stocks/inventories/stocks',
};

const TRANSFER = {
  getText: () => i18next.t('FEATURE.OPERATIONS.TRANSFER'),
  path: '/stocks/inventories/transfer',
  getBreadCrumbText: () => i18next.t('FEATURE.OPERATIONS.TRANSFER_BREADCRUMB'),
};

const STOCK_SUBMODULE = {
  getText: () => i18next.t('FEATURE.OPERATIONS.STOCKS'),
  icon: '/images/icon-stock.svg',
  children: [STOCK, TRANSFER],
};

const PRODUCT_LOSS = {
  getText: () => i18next.t('FEATURE.OPERATIONS.LOSSES_PRODUCTS'),
  path: '/losses/losses/products',
  getBreadCrumbText: () => i18next.t('FEATURE.OPERATIONS.LOSSES_PRODUCTS_BREADCRUMB'),
};

const SUPPLIER_PRODUCTS_LOSS = {
  getText: () => i18next.t('FEATURE.OPERATIONS.LOSSES_SUPPLIER_PRODUCTS'),
  path: '/losses/losses/supplier-products',
  getBreadCrumbText: () => i18next.t('FEATURE.OPERATIONS.LOSSES_SUPPLIER_PRODUCTS_BREADCRUMB'),
};

const LOSS_SUBMODULE = {
  getText: () => i18next.t('FEATURE.OPERATIONS.LOSSES'),
  icon: '/images/icon-loss.svg',
  children: [PRODUCT_LOSS, SUPPLIER_PRODUCTS_LOSS],
};

export const OPERATIONS = {
  getText: () => i18next.t('FEATURE.OPERATIONS.OPERATIONS'),
  path: '/operations',
  icon: '/images/operations.svg',
  children: [PRODUCTION_SUBMODULE, ORDER_SUBMODULE, STOCK_SUBMODULE, LOSS_SUBMODULE],
};
