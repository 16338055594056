import React from 'react';
import PropTypes from 'prop-types';

import { Props } from './interfaces';

import { Container, MainText, Icon } from './styledComponents';

import iconEmptyState from '../../../images/icon-empty-state-no-result.svg';

const EmptyState = (props: Props): JSX.Element => {
  const { label, customIcon, customSize } = props;
  return (
    <Container>
      <Icon
        src={customIcon || iconEmptyState}
        alt="icon-no-data"
        customSize={customSize}
      />
      <MainText>{label}</MainText>
    </Container>
  );
};

EmptyState.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  customIcon: PropTypes.any,
  label: PropTypes.string.isRequired,
  customSize: PropTypes.string,
};

EmptyState.defaultProps = {
  customIcon: null,
  customSize: null,
};

export default EmptyState;
