import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { ThemeProvider } from 'styled-components';

import { Container, Icon } from './styledComponents';

import iconTooltipBlue from '../images/icon-tooltip.svg';
import iconTooltipDark from '../images/icon-tooltip-dark.svg';
import iconTooltipPlainGrey from '../images/icon-tooltip-plain-grey.svg';
import iconTooltipPlainBlack from '../images/icon-tooltip-plain-dark.svg';
import IconStockBlue from '../images/icon-stock-blue.svg';
import IconStockDark from '../images/icon-stock-dark.svg';
import IconStockWhite from '../images/icon-stock-white.svg';

import { getTheme } from '../utils/theme';

interface Props {
  text: string | number | void;
  place: string;
  icon: string;
  dataHtml: boolean;
  customClassName: string;
  renderTooltipTrigger: Function;
  isHighlighted: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any;
  displayBigger: boolean;
  maxWidth: number;
  borderRadius: number;
}

const icons = {
  'icon-tooltip-blue': iconTooltipBlue,
  'icon-tooltip-dark': iconTooltipDark,
  'icon-tooltip-plain-dark': iconTooltipPlainGrey,
  'icon-tooltip-plain-black-dark': iconTooltipPlainBlack,
  'icon-stock-closed-blue': IconStockBlue,
  'icon-stock-closed-dark': IconStockDark,
  'icon-stock-opened-blue': IconStockWhite,
  'icon-stock-opened-dark': IconStockWhite,
};

const Tooltip = (props: Props): JSX.Element => {
  const {
    text,
    customClassName,
    theme,
    place,
    icon,
    dataHtml,
    renderTooltipTrigger,
    isHighlighted,
    displayBigger,
    maxWidth,
    borderRadius,
  } = props;

  const random = Math.random().toString();

  const updatedTheme = getTheme(theme, 'tooltip');

  return (
    <ThemeProvider theme={updatedTheme}>
      <Container
        displayBigger={displayBigger}
        maxWidth={maxWidth}
        borderRadius={borderRadius}
      >
        {renderTooltipTrigger && (
          <div data-for={random} data-tip={text} data-html={dataHtml}>
            {renderTooltipTrigger()}
          </div>
        )}
        {!renderTooltipTrigger && (
          <Icon
            className={customClassName}
            data-for={random}
            data-html={dataHtml}
            data-tip={text}
            src={
              isHighlighted
                ? icons[`${icon}-black-${updatedTheme.icon?.color}`]
                : icons[`${icon}-${updatedTheme.icon?.color}`]
            }
            alt="toolitp-icon"
          />
        )}
        <ReactTooltip
          id={random}
          className="custom-tooltip"
          textColor={updatedTheme.textColor}
          arrowColor={updatedTheme.arrowColor}
          backgroundColor={updatedTheme.backgroundColor}
          data-place={place}
        />
      </Container>
    </ThemeProvider>
  );
};

Tooltip.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.objectOf(PropTypes.any),
  text: PropTypes.string.isRequired,
  place: PropTypes.string,
  icon: PropTypes.string,
  dataHtml: PropTypes.bool,
  customClassName: PropTypes.string,
  renderTooltipTrigger: PropTypes.func,
  isHighlighted: PropTypes.bool,
  displayBigger: PropTypes.bool,
  maxWidth: PropTypes.number,
  borderRadius: PropTypes.number,
};

Tooltip.defaultProps = {
  place: '',
  theme: null,
  dataHtml: false,
  customClassName: '',
  icon: 'icon-tooltip',
  renderTooltipTrigger: null,
  isHighlighted: false,
  displayBigger: false,
  maxWidth: 400,
  borderRadius: 3,
};

export default Tooltip;
