import { DateRangePicker } from 'react-dates';
import i18next from 'i18next';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import {
  renderCustomArrowIcon,
  renderCustomNavIconLeft,
  renderCustomNavIconRight,
} from '@commons/DeepsightAnalyticsHeaders/utils';

import DatePickerModalInformation from '../DatePickerModalInformation';

import { Container } from './styledComponents';
import { selectIfRenderDotAndTodayDateCircle } from './utils';

const RangeDatePicker = (props) => {
  const {
    startDate,
    endDate,
    handleSelectedDates,
    highlightDayDatePicker,
    readOnly,
    singleStore,
    setStartMonth,
    setEndMonth,
    formattedInventoryStockDay,
    setFocusedDateInput,
    focusedDateInput,
  } = props;

  const setSelectedMonth = (date, type) => {
    switch (type) {
      case 'next':
        setStartMonth(moment(date).startOf('month').format('YYYY-MM-DD'));
        setEndMonth(moment(date).add(1, 'month').endOf('month').format('YYYY-MM-DD'));
        break;
      case 'previous':
        setStartMonth(moment(date).startOf('month').format('YYYY-MM-DD'));
        setEndMonth(moment(date).add(1, 'month').endOf('month').format('YYYY-MM-DD'));
        break;
      default:
        setStartMonth(moment(date.startDate).startOf('month').format('YYYY-MM-DD'));
        setEndMonth(moment(date.endDate).endOf('month').format('YYYY-MM-DD'));
        break;
    }
  };

  const disableSelectedDay = (day) => {
    if (focusedDateInput === 'endDate') {
      return (
        day.isAfter(moment().subtract(1, 'day').endOf('day')) ||
        day.isBefore(moment(startDate).startOf('day'))
      );
    }
    return day.isAfter(moment().subtract(1, 'day').endOf('day'));
  };

  return (
    <Container className={props.className} disabled={readOnly} modal={singleStore} date>
      <img
        alt=""
        className="orderform-date-input-icon"
        readOnly={readOnly}
        src={'/images/icon-calendar-black-inpulse.svg'}
        style={{ width: '16px', height: '16px' }}
      />
      <DateRangePicker
        calendarInfoPosition={'top'}
        customArrowIcon={renderCustomArrowIcon()}
        disabled={readOnly}
        displayFormat={'ddd Do MMM'}
        endDate={endDate}
        endDateId="your_unique_end_date_id"
        endDatePlaceholderText={i18next.t('GENERAL.ANALYTICS_HEADER_DATE_PICKER_END_PLACEHOLDER')}
        focusedInput={focusedDateInput}
        hideKeyboardShortcutsPanel={true}
        isDayHighlighted={highlightDayDatePicker}
        isOutsideRange={(day) => disableSelectedDay(day)}
        minimumNights={0}
        navNext={renderCustomNavIconRight()}
        navPrev={renderCustomNavIconLeft()}
        noBorder={true}
        numberOfMonths={2}
        readOnly={true}
        renderCalendarInfo={() => {
          if (singleStore) {
            return <DatePickerModalInformation />;
          }
        }}
        renderDayContents={(item) =>
          selectIfRenderDotAndTodayDateCircle(
            formattedInventoryStockDay[focusedDateInput],
            singleStore,
            item,
            [startDate, endDate],
          )
        }
        startDate={startDate}
        startDateId="your_unique_start_date_id"
        startDatePlaceholderText={i18next.t(
          'GENERAL.ANALYTICS_HEADER_DATE_PICKER_START_PLACEHOLDER',
        )}
        onClose={(event) => setSelectedMonth(event)}
        onDatesChange={(dates) => {
          handleSelectedDates(dates, focusedDateInput);
        }}
        onFocusChange={(focusedDateInput) => {
          if (!endDate) {
            setEndMonth(moment(startDate).add(1, 'month').endOf('month').format('YYYY-MM-DD'));
          }
          setFocusedDateInput(focusedDateInput);
        }}
        onNextMonthClick={(nextMonth) => setSelectedMonth(nextMonth, 'next')}
        onPrevMonthClick={(prevMonth) => setSelectedMonth(prevMonth, 'previous')}
      />
    </Container>
  );
};

const StyledRangeDatePicker = styled(RangeDatePicker)`
  & .orderform-date-input-icon,
  .multiple-dropdown-icon {
    margin-left: 15px;
  }

  & .DateInput_fang {
    display: none !important;
  }

  & .DateRangePickerInput__disabled {
    background-color: lightgrey !important;
  }

  & .CalendarMonth_caption {
    color: ${(props) => props.theme.colors?.greys?.darkest} !important;
    font: ${(props) => props.theme.fonts?.h3} !important;
  }

  & .DateInput_input {
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    text-align: center !important;
    cursor: pointer;
  }

  & .DateInput_input__focused {
    border-bottom: none !important;
  }

  & .DayPicker_weekHeader_li {
    font: ${(props) => props.theme.fonts?.textMicro} !important;
  }

  & .CalendarDay {
    background: ${(props) => props.theme.colors?.greys.lightest} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__default:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__highlighted_calendar {
    background: #fff !important;
    border: 1px double #0a3eff !important;
  }

  & .CalendarDay__highlighted_calendar:hover {
    background: #e4e7e7 !important;
  }

  & .CalendarDay__selected_span {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    border: 1px double #e2e2e2 !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected,
  .CalendarDay__selected:active .CalendarDay__selected:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_start,
  .CalendarDay__selected_start:hover,
  .CalendarDay__selected_end,
  .CalendarDay__selected_end:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover {
    color: ${(props) => props.theme.colors?.greys.dark} !important;
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
  }
`;

export default StyledRangeDatePicker;
