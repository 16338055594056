import styled, { css } from 'styled-components';

import ORDER_STATUS from '@orders/OrderList/components/OrderForm/constants/status';

export const ProductRow = styled.div`
  display: flex;
  height: 64px;
  position: relative;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  border: 1px solid transparent;
  cursor: ${(props) => (props.cantOpen ? 'auto' : 'pointer')};

  ${({ addBorder }) =>
    addBorder &&
    css`
      ${({ isOpen, theme }) =>
        isOpen
          ? `
              border-radius: 8px 8px 0 0;
              border-bottom: 1px solid ${theme.colors.greys.darkest};
            `
          : `
              border-radius: ${theme.borders?.radius.weak};
            `};
    `}
`;

export const DotAddedReference = styled.div`
  top: 24px;
  left: -8px;

  position: absolute;

  width: 16px;
  height: 16px;

  cursor: default;

  border-radius: 50%;
  background-color: ${(props) => props.theme.colors?.infoOrange};

  background-size: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('/images/inpulse/add-white-small.svg');

  cursor: pointer;
`;

export const DotAddedUnavailable = styled.div`
  top: 24px;
  left: -8px;

  position: absolute;

  width: 16px;
  height: 16px;

  cursor: default;

  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.infoRed};

  background-size: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('/images/inpulse/close-white-small.svg');

  cursor: pointer;
`;

export const ItemName = styled.div`
  flex-basis: ${(props) => `calc(${(100 / props.nbColumns) * 2}%)`};
  overflow: hidden;
`;

export const DisplayImgName = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0 16px;
`;

export const DisplayName = styled.div`
  max-width: ${(props) => (props.isTablet ? '130px' : '180px')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 8px;
`;

export const Name = styled.div`
  max-width: ${(props) => (props.orderStatus < ORDER_STATUS.SENT ? '200px' : '162px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: ${(props) => props.theme.fonts.textBigHeight16 || ''};
`;

export const SkuContainer = styled.div`
  max-width: ${(props) => (props.orderStatus < ORDER_STATUS.SENT ? '200px' : '162px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.colors.greys.darker};
  font: ${(props) => props.theme.fonts.textMicroHeight16};
`;

export const DisplayNameHovered = styled.div`
  position: absolute;
  top: 0;
  left: ${(props) => (props.hasPicture ? '64px' : '0')};
  height: 100%;
  background: white;
  white-space: nowrap;
  padding: 0 16px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
  font: ${(props) =>
    !props.isBold ? props.theme.fonts.textMiddleNormal : props.theme.fonts.textMiddleBold};
`;

export const OrderUnavailable = styled.div`
  position: absolute;
  bottom: 0;
  left: 60px;
  z-index: 12;
  opacity: 1;
  height: calc(100% + 8px);
`;

export const ProductImg = styled.img`
  margin: 0 15px 0 0;
  cursor: ${(props) => (props.pointerCursor ? 'pointer' : 'default')};
  border-radius: 4px;
  background-size: cover;
`;

export const ProductImagDiv = styled.img`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  cursor: ${(props) => (props.pointerCursor ? 'pointer' : 'default')};
  flex-shrink: 0;
`;

export const ItemConditioning = styled.div`
  position: relative;
  flex-basis: ${(props) => `calc(${100 / props.nbColumns}%)`};
  display: flex;
  flex-direction: column;
`;

export const ConditioningColumnFormat = styled.div`
  text-overflow: ellipsis;
  font: ${(props) => props.theme.fonts?.textBig || ''};
`;

export const ConditioningColumnPackaging = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: #6b768c;
`;

export const ConditioningColumnBigger = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  background: white;
  white-space: nowrap;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
  font: ${(props) => props.theme.fonts?.textBig || ''};
  padding: 0 16px;
`;

export const ItemPrice = styled.div`
  flex-basis: ${(props) => `calc(${100 / props.nbColumns}%)`};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: proximanovaregular;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font: ${(props) => props.theme.fonts?.textBig || ''};
`;

export const ItemOrderProduct = styled.div`
  flex-basis: ${(props) =>
    !!props.weightedCoefficientWidthColumn
      ? `calc(${(100 / props.nbColumns) * props.weightedCoefficientWidthColumn}%)`
      : `calc(${100 / props.nbColumns}%)`};
  opacity: ${(props) => (props.opaque ? 0.6 : 1)};
`;

export const LabelContainer = styled.div`
  flex-basis: ${(props) =>
    !!props.weightedCoefficientWidthColumn
      ? `calc(${(100 / props.nbColumns) * props.weightedCoefficientWidthColumn}%)`
      : `calc(${100 / props.nbColumns}%)`};

  & > div {
    width: fit-content;
  }
`;

export const ItemOrderRecommandation = styled.div`
  display: flex;
  flex-basis: ${(props) =>
    !!props.weightedCoefficientWidthColumn
      ? `calc(${(100 / props.nbColumns) * props.weightedCoefficientWidthColumn}%)`
      : `calc(${100 / props.nbColumns}%)`};
`;

export const DisplayReco = styled.div`
  background-color: #f1f6ff;
  display: flex;
  padding: 14px 10px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  min-width: 115px;
`;

export const DisplayRecoIcon = styled.img``;

export const DisplayRecoText = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  position: relative;
  top: 1px;
  margin-left: 8px;
`;

export const ItemCantStillOrder = styled.div`
  font: ${(props) => props.theme.fonts?.textBigHeight16 || ''};
`;

export const ItemProductAnomalies = styled.div`
  flex-basis: 240px;
  max-width: ${(props) => `calc(${100 / props.nbColumns - 1}%)`};
  height: 40px;
  position: relative;
  right: 15px;
`;

export const ItemProductAnomaliesDisable = styled.div`
  font: ${(props) => props.theme.fonts?.textSmall || ''};
  color: ${(props) => props.theme.colors?.greys.dark || ''};
`;

export const ItemProductAnomaliesDisableImg = styled.img`
  height: 5px;
`;

export const ItemProductDisplayAnomalies = styled.div`
  flex-basis: ${(props) => `calc(${100 / props.nbColumns}%)`};
  font: ${(props) => props.theme?.fonts.textBigHeight16 || ''};
`;

export const ItemTotal = styled.div`
  flex-basis: ${(props) => `calc(${100 / props.nbColumns}%)`};
  display: flex;
  flex-direction: column;
`;

export const TotalAmount = styled.div`
  text-align: ${(props) => (props.rightAlign ? 'end' : '')};
  font: ${(props) => props.theme.fonts?.textBigHeight16 || ''};
`;

export const TotalPackagingValue = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: #6b768c;
  font: ${(props) => props.theme.fonts?.textMicroHeight12 || ''};
`;

export const ItemMoreData = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-basis: ${(props) => `calc(${100 / props.nbColumns}%)`};
`;

export const OpenEntityRowButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: ${(props) => props.theme?.colors?.greys?.lighter};
  border-left: 1px solid
    ${(props) =>
      props.isOpen ? props.theme?.colors?.greys?.darkest : props.theme?.colors?.greys?.light};
  border-radius: ${(props) =>
    props.isOpen ? '0 8px 0 0' : props.multiple ? '0 8px 0 0' : '0 8px 8px 0'};
  border-bottom: ${(props) =>
    props.isOpen ? `1px solid ${props.theme?.colors?.greys?.darkest}` : ''};
  cursor: ${(props) => (props.isDisable ? 'not-allowed' : 'pointer')};
  position: relative;
  left: 1px;
`;

export const IconChevron = styled.img``;

export const BorderMultipleProduct = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme?.colors?.greys?.light};
  width: 100%;
  margin: auto;
`;

export const ContainerOrderAnomalies = styled.div`
  display: flex;
`;

export const ContainerDeleteIcon = styled.div`
  display: flex;
  margin-top: 24px;
  margin-right: 12px;
  align-items: center;
  justify-content: center;
`;

export const OpenSidePanelButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 2px solid ${(props) => props.theme.colors.greys.darkest};
  border-radius: 8px;
  margin-right: 16px;
`;

export const CheckContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 2px solid ${(props) => props.theme.colors.greys.darkest};
  border-radius: 8px;
  margin-right: 16px;
  transition: 0.3s;

  &:hover {
    cursor: ${(props) => (props.isDisabled ? `not-allowed` : `pointer`)};
  }

  ${(props) =>
    props.checked
      ? css`
          background: ${props.theme.colors.brand.primary};
          border: 2px solid ${(props) => props.theme.colors.brand.primary};

          &:hover {
            border: ${!props.isDisabled && `2px solid ${props.theme.colors.greys.darker}`};
            background: ${!props.isDisabled && props.theme.colors.greys.darker};
          }
        `
      : css`
          color: ${props.theme.colors.greys.darkest};
          background: ${props.theme.colors.white};

          &:hover {
            border: ${!props.isDisabled && `2px solid ${props.theme.colors.brand.primary}`};
            background: ${!props.isDisabled && props.theme.colors.brand.primary};
          }
        `}
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

export default {
  ProductRow,
  ItemName,
  ItemConditioning,
  ItemPrice,
  ConditioningColumnFormat,
  ConditioningColumnPackaging,
  ConditioningColumnBigger,
  ItemOrderProduct,
  ItemTotal,
  TotalAmount,
  TotalPackagingValue,
  ItemMoreData,
  DisplayImgName,
  DisplayName,
  Name,
  SkuContainer,
  DisplayNameHovered,
  OrderUnavailable,
  ProductImg,
  ItemCantStillOrder,
  ItemProductAnomalies,
  ItemProductAnomaliesDisable,
  ItemProductAnomaliesDisableImg,
  ItemProductDisplayAnomalies,
  ItemOrderRecommandation,
  DisplayReco,
  DisplayRecoIcon,
  DisplayRecoText,
  ProductImagDiv,
  OpenEntityRowButton,
  IconChevron,
};
