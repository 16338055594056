import i18next from 'i18next';
import React from 'react';

import { InpulseLabel } from '@commons/DeepsightComponents';

const renderLabel = (item) => (
  <div
    className="supplier-label-render"
    style={{ minWidth: '115px', marginLeft: '2px', padding: '5px 0px' }}
  >
    <InpulseLabel
      color={item === 'Actif' ? 'green' : item === 'Inactif' ? 'red' : 'black'}
      text={String(item)}
    />
  </div>
);

export const getColumnsSettings = (hasMultipleBrands) => [
  {
    id: 'store_name',
    name: i18next.t('GENERAL.NAME'),
    displayName: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
    baseName: 'name',
  },
  ...(hasMultipleBrands
    ? [
        {
          id: 'brand_name',
          name: i18next.t('GENERAL.BRAND'),
          displayName: i18next.t('GENERAL.BRAND'),
          propertyKey: 'brandName',
          baseName: 'brandName',
        },
      ]
    : []),
  {
    id: 'identifier',
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_IDENTIFIER'),
    displayName: i18next.t('ORDERS.ORDERS.LIST_LABEL_IDENTIFIER'),
    propertyKey: 'partnerId',
    baseName: 'partnerId',
  },
  {
    id: 'status',
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_STATUS'),
    displayName: i18next.t('ORDERS.ORDERS.LIST_LABEL_STATUS'),
    propertyKey: 'active',
    baseName: 'active',
    excludeFromSearch: true,
    list: [
      {
        id: 'active',
        value: 'Actif',
        active: 'Actif',
        name: 'Actif',
        render: (item) => (
          <div className="supplier-label-render">
            <InpulseLabel color="green" text={item.name} />
          </div>
        ),
      },
      {
        id: 'inactive',
        value: 'Inactif',
        active: 'Inactif',
        name: 'Inactif',
        excludeFromSearch: true,
        render: (item) => (
          <div className="supplier-label-render">
            <InpulseLabel color="red" text={item.name} />
          </div>
        ),
      },
    ],
    render: (it) => renderLabel(it),
  },
];

export default {
  getColumnsSettings,
};
