import React, { useState, useRef } from 'react';

import { useOutsideAlerter } from '../../hooks/useOutsideAlerter';

import { ButtonContainer, TooltipContainer, Container } from './styledComponents';

const TooltipButton = ({ tooltipContent, isDisabled = false, customTooltipStyle = {} }) => {
  const tooltipButtonRef = useRef(null);

  const [isOpened, setIsOpened] = useState(false);

  const handleClickOutside = () => {
    setIsOpened(false);
  };

  useOutsideAlerter(tooltipButtonRef, handleClickOutside);

  const triggerTooltip = () => {
    if (isDisabled) {
      return;
    }

    setIsOpened(!isOpened);
  };

  return (
    <Container ref={tooltipButtonRef}>
      <ButtonContainer
        isDisabled={isDisabled}
        isOpened={isOpened}
        onClick={(event) => {
          event.stopPropagation();
          triggerTooltip();
        }}
      >
        <img
          src={
            isDisabled
              ? '/images/inpulse/list-lightygrey-small.svg'
              : `/images/inpulse/list-${!isOpened ? 'black' : 'white'}-small.svg`
          }
        />
      </ButtonContainer>
      {isOpened && (
        <TooltipContainer customStyle={customTooltipStyle}>{tooltipContent}</TooltipContainer>
      )}
    </Container>
  );
};

export default TooltipButton;
