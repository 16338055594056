import { Button } from '../../commons/utils/styledLibraryComponents';
import { ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

const Header = styled.div`
  width: 100%;
  padding: 30px 25px;
  border-top-left-radius: ${(props) => props.theme.borders?.radius.weak || '5px'};
  border-top-right-radius: ${(props) => props.theme.borders?.radius.weak || '5px'};
  box-shadow: ${(props) =>
    props.theme.colors ? undefined : '0 2px 6px 0 rgba(82, 134, 245, 0.1)'};
  -webkit-box-shadow: ${(props) =>
    props.theme.colors ? undefined : '0 2px 6px 0 rgba(82, 134, 245, 0.1)'};
  background: ${(props) =>
    props.theme.colors?.greys.lightest || 'linear-gradient(270deg, #1ab3ec 0%, #0a35d6 98.83%)'};
`;

const Title = styled.div`
  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts.textBig};`
      : `font-size: 24px;
  font-weight: 400;
  font-style: normal;`}
  color: ${(props) => props.theme.colors?.greys.darkest || 'white'};
`;

const Content = styled.div`
  padding: ${(props) => (props.theme.borders ? '0px 40px 20px 40px' : '40px 40px 20px 40px')};
  background: ${(props) => props.theme.colors?.greys.lightest || undefined};
  .progress {
    background: ${(props) => props.theme.colors?.greys.lighter || 'white'};
    box-shadow: ${(props) =>
      props.theme.colors ? '0px 0px 0px rgba(0, 0, 0, 0)' : '0px 2px 4px rgba(0, 0, 0, 0.1)'};
    border-radius: ${(props) => props.theme.borders?.radius.strong || '3px'};
  }

  .progress-bar {
    box-shadow: ${(props) => (props.theme.colors ? '0px 0px 0px rgba(0, 0, 0, 0)' : undefined)};
    background: ${(props) =>
      props.theme.colors?.brand.primary ||
      'linear-gradient(269.8deg, #1ab3ec -13.83%, #0a3eff 100.6%)'};
  }
`;

const Footer = styled.div`
  background: ${(props) => props.theme.colors?.greys.lightest || undefined};
  display: flex;
  padding-bottom: 20px;
  justify-content: center;
  border-bottom-left-radius: ${(props) => props.theme.borders?.radius.weak || '5px'};
  border-bottom-right-radius: ${(props) => props.theme.borders?.radius.weak || '5px'};
`;
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ExportModalContent = (props) => {
  const { t } = useTranslation();
  const { closeModal, progress, titleModal, isLoading, setLoading, exitModal, noFooter, children } =
    props;

  return (
    <Container>
      <Header>
        <Title>{titleModal}</Title>
      </Header>

      <Content>
        {children ? (
          isLoading ? (
            <ProgressBar now={progress} />
          ) : (
            children
          )
        ) : (
          <ProgressBar now={progress} />
        )}
      </Content>

      <Footer>
        {!noFooter && (
          <Button
            buttonCustomStyle={{ marginRight: 18 }}
            color={isLoading ? 'white' : 'blue'}
            handleClick={() => exitModal(setLoading, closeModal)}
            icon={'/images/inpulse/close-white-small.svg'}
            label={isLoading ? t('GENERAL.CANCEL') : t('GENERAL.CLOSE')}
          />
        )}
      </Footer>
    </Container>
  );
};

export default ExportModalContent;
