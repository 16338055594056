import axios from '../core/http';

import { config } from '../config';
const baseApiUrl = config.baseApiUrl;

const getIngestionPipelineStatus = (clientId) =>
  axios
    .get(`${baseApiUrl}/backoffice/pipelines/ingestion/status`, {
      params: {
        clientId,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const getCashierProductsSalesSyncStatus = (clientId) =>
  axios
    .get(`${baseApiUrl}/backoffice/cashier-product-sales/sync/${clientId}/status`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const syncCashierProductSales = (storeIds, startDate, endDate) =>
  axios
    .post(`${baseApiUrl}/backoffice/cashier-product-sales/sync`, {
      storeIds,
      startDate,
      endDate,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const getForecastPipelineStatus = (clientId) =>
  axios
    .get(`${baseApiUrl}/backoffice/pipelines/forecast/status`, {
      params: {
        clientId,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const exportCashierProductSales = (startDate, endDate, storeIds) =>
  axios
    .post(
      `${baseApiUrl}/backoffice/cashier-product-sales/export`,
      { startDate, endDate, storeIds },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const activateIngestionPipeline = (clientId) =>
  axios
    .post(`${baseApiUrl}/backoffice/pipelines/ingestion/activate`, {
      clientId,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const activateIngestionPipelineStatus = (clientId) =>
  axios
    .get(`${baseApiUrl}/backoffice/pipelines/ingestion/activate/status`, {
      params: {
        clientId,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const activateForecasts = (clientId) =>
  axios
    .post(`${baseApiUrl}/backoffice/pipelines/forecast/activate`, {
      clientId,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

export const cashierProductSalesService = {
  getIngestionPipelineStatus,
  getCashierProductsSalesSyncStatus,
  syncCashierProductSales,
  exportCashierProductSales,
  activateIngestionPipeline,
  activateIngestionPipelineStatus,
  activateForecasts,
  getForecastPipelineStatus,
};
