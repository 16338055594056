import styled from 'styled-components';

import { BREADCRUMB_HEIGHT, BREAKPOINT, SIDEBAR_WIDTH } from '../../../commons/utils/responsive';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  height: calc(100% - ${BREADCRUMB_HEIGHT});
  width: 100%;
  padding: 16px;

  display: flex;
  flex-flow: column;
  gap: 16px;

  overflow: auto;
  /* max-width: calc(${BREAKPOINT.EXTRA_LARGE} - ${SIDEBAR_WIDTH}); */

  align-items: center;

  @media (min-width: ${BREAKPOINT.LARGE}) {
    gap: 24px;
    padding: 24px;
  }
`;

export const SelectorContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;

  width: 100%;
  max-width: ${BREAKPOINT.EXTRA_LARGE};

  @media (min-width: ${BREAKPOINT.LARGE}) {
    gap: 24px;
  }
`;

export const ContentContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
  max-width: ${BREAKPOINT.EXTRA_LARGE};

  @media (min-width: ${BREAKPOINT.LARGE}) {
    gap: 24px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex: 1 100%;
  flex-flow: row wrap;
  gap: 16px;

  @media (min-width: ${BREAKPOINT.MEDIUM}) {
    max-height: 452px;
  }

  @media (min-width: ${BREAKPOINT.LARGE}) {
    gap: 24px;
  }

  .top-flop-products {
    flex: 1 100%;

    min-height: 318px;
    max-height: 407px;

    @media (min-width: ${BREAKPOINT.MEDIUM}) {
      flex: 1;
    }
  }

  .store-ranking {
    flex: 1 100%;

    min-height: 318px;
    max-height: 407px;

    @media (min-width: ${BREAKPOINT.MEDIUM}) {
      flex: 1;
    }
  }
`;

export const MetricsContainer = styled.div`
  flex: 1 100%;

  display: flex;
  flex-flow: row wrap;
  gap: 16px;

  min-height: 344px;

  @media (min-width: ${BREAKPOINT.MEDIUM}) {
    flex: 1;
    max-height: 452px;
  }

  @media (min-width: ${BREAKPOINT.LARGE}) {
    gap: 24px;
  }

  .ca-ht {
    flex: 1;
    min-height: '104px';
  }

  .margin-ht {
    flex: 1;
    min-height: '104px';
  }

  .real-food-cost {
    flex: 1 100%;
    min-height: '104px';
  }

  .theorical-food-cost {
    flex: 1 100%;
    min-height: '104px';
  }
`;

export const GraphContainer = styled.div`
  border: solid 1px ${(props) => props.theme.colors.greys.lighty};
  background-color: ${(props) => props.theme.colors.greys.lightest};

  border-radius: 8px;

  flex: 1 100%;
  min-height: 452px;
  max-height: 452px;

  padding: 16px;

  @media (min-width: ${BREAKPOINT.MEDIUM}) {
    min-height: 344px;
    flex: 2;
  }
`;

export const Test = styled.div`
  display: flex;
  align-items: center;

  height: 40px;
  padding: 12px;
  background-color: #f8f8f8;
  border-radius: 8px;
`;

export const ProductNameContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 184px;

  padding-left: 16px;

  gap: 8px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;

  border-radius: 20px;
`;

export const ProductNameLabel = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.blacks.ipBlack1};
`;

export const HeaderContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  color: ${({ theme }) => theme.colors.blacks.ipBlack4};
`;

export const Title = styled.div`
  font: ${({ theme }) => theme.fonts.textSmallHeight16};
`;

export const LabelContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 4px;
`;

export const StoreRow = styled.div`
  display: flex;
  gap: 8px;
`;

export const StoreLabel = styled.div`
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
