import i18next from 'i18next';

import { formatNumber } from '@commons/DisplayNumber';

export function getColumnSettings(useTtc, currency) {
  const headers = [
    {
      name: i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_MIX_MIX_CATEGORY_LABEL'),
      propertyKey: 'name',
      large: true,
      bold: true,
      textTransform: 'uppercase',
      textDecorationChild: 'underline',
      hrefPrefix: '/admin/products/',
      hrefSuffix: '/details',
    },
    {
      name: i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_MIX_PRODUCT_LABEL'),
      propertyKey: useTtc ? `mix` : 'mixExcludingTaxes',
      tooltipDisplay: i18next.t(
        useTtc
          ? 'FORECAST.PRODUCT_MIX.LIST_HEADER_MIX_PRODUCT_LABEL_TOOLTIP'
          : 'FORECAST.PRODUCT_MIX.LIST_HEADER_MIX_EXCLUDING_TAXES_PRODUCT_LABEL_TOOLTIP',
      ),
      isNumber: true,
      unit: '%',
      bold: true,
      render: (item) => `${formatNumber(item, 2)} %`,
    },
    {
      name: i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_UNIT_LABEL'),
      propertyKey: 'unit',
      isNumber: true,
      withRender: true,
      minWidth: 60,
      graphConfiguration: {
        title: '',
        subTitle: i18next.t('FORECAST.PRODUCT_MIX.CHART_AVERAGE_LABEL'),
      },
    },
    {
      name: i18next.t(useTtc ? 'GENERAL.PRICE_TTC' : 'GENERAL.PRICE_EX_TAX'),
      propertyKey: useTtc ? 'price' : 'priceExcludingTaxes',
      tooltipDisplay: i18next.t(
        useTtc
          ? 'ANALYSIS.SALES.COLUMN_TOOLTIP_PAST_PRODUCT_MIX_PRICE_WITH_TAXES'
          : 'ANALYSIS.SALES.COLUMN_TOOLTIP_PAST_PRODUCT_MIX_PRICE_WITHOUT_TAXES',
      ),
      unit: ` ${currency.alphabeticCode}`,
      isNumber: true,
      render: (item) => `${formatNumber(item, currency.numberDecimals)} ${currency.alphabeticCode}`,
    },
    {
      name: i18next.t(useTtc ? 'GENERAL.TURNOVER_TAX_INCLUDED' : 'GENERAL.TURNOVER_EX_TAX'),
      propertyKey: useTtc ? 'ca' : 'turnoverExcludingTaxes',
      unit: ` ${currency.alphabeticCode}`,
      isNumber: true,
    },
    {
      name: i18next.t('ANALYSIS.SALES.COLUMN_NAME_PERCENT_MARGIN_WITHOUT_TAXES'),
      propertyKey: 'margin',
      tooltipDisplay: i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_MARGIN_LABEL_TOOLTIP'),
      unit: '%',
      isNumber: true,
      render: (item) => `${formatNumber(item, 2)} %`,
    },
    {
      name: '',
      propertyKey: 'action',
      isClickable: true,
    },
  ];

  return headers;
}
