import axios from '../core/http';

import { APIcaller } from './APIcaller';
import { config } from '../config';
const baseApiUrl = config.baseApiUrl;

const getIngredients = (clientId, withLinkedData = true) =>
  APIcaller.apiGet('/admin/ingredients', {
    clientId,
    withLinkedData,
  });

const getIngredientById = (id) =>
  axios
    .get(baseApiUrl + '/admin/ingredients/' + id, {
      params: {},
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const getIngredientByIds = (ingredientIds) =>
  axios
    .post(baseApiUrl + '/admin/ingredients/get-by-ids', {
      ingredientIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export const getIngredientsCategories = (clientId) =>
  APIcaller.apiGet('/admin/ingredientsCategories', {
    clientId,
  });

const getIngredientParents = (id) =>
  axios
    .get(`${baseApiUrl}/admin/ingredients/${id}/parents-mappings`, {
      params: {},
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const createIngredient = (
  clientId,
  name,
  unit,
  category,
  code,
  cost,
  supplierProducts,
  allergenIds,
  isStrategic,
) =>
  axios
    .post(baseApiUrl + '/admin/ingredients', {
      clientId,
      name,
      unit,
      category,
      code,
      cost,
      supplierProducts,
      allergenIds,
      isStrategic,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const updateIngredient = (
  clientId,
  id,
  name,
  unit,
  category,
  code,
  cost,
  supplierProducts,
  allergenIds,
  isStrategic,
) =>
  axios
    .patch(
      baseApiUrl + '/admin/ingredients',
      {
        clientId,
        id,
        name,
        unit,
        category,
        code,
        cost,
        supplierProducts,
        allergenIds,
        isStrategic,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const deleteIngredients = (ingredientIds) =>
  axios
    .post(baseApiUrl + '/admin/ingredients/delete', {
      ingredientIds: JSON.stringify(ingredientIds),
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const checkMetricsBeforeDelete = (ingredientIds) =>
  axios
    .post(baseApiUrl + '/admin/ingredients/check-existing-data-metrics', {
      ingredientIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const getIngredientCostFromSupplierProductIds = (ingredientUnit, supplierProductIds) => {
  const supplierProductIdsAsString = supplierProductIds.join(',');

  return axios
    .get(`${baseApiUrl}/admin/ingredients/cost`, {
      params: {
        unit: ingredientUnit,
        supplierProductIds: supplierProductIdsAsString,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

const deleteIngredientByIdsWithLinkedData = (ingredientIds, clientId) =>
  axios
    .delete(`${baseApiUrl}/admin/clients/${clientId}/ingredients`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
      data: {
        ingredientIds,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export const disableIngredients = (ingredientIds) =>
  axios.patch(
    baseApiUrl + '/admin/ingredients/disable',
    {
      ingredientIds,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    },
  );

export const enableIngredientsByIds = async (ingredientIds) =>
  axios.patch(
    baseApiUrl + '/admin/ingredients/enable',
    {
      ingredientIds,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    },
  );

export const ingredient = {
  getIngredients,
  getIngredientById,
  getIngredientByIds,
  getIngredientsCategories,
  getIngredientParents,
  createIngredient,
  updateIngredient,
  deleteIngredients,
  disableIngredients,
  getIngredientCostFromSupplierProductIds,
  checkMetricsBeforeDelete,
  deleteIngredientByIdsWithLinkedData,
  enableIngredientsByIds,
};
