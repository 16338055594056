import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { getTheme } from '../utils/theme';
import {
  Content,
  Header,
  Item,
  Row,
  RowSeparator,
  SimpleTableViewContainer,
  Text,
  DisplayNameContainer,
  TooltipText,
} from './styledComponents';

import Tooltip from '../Tooltip';

import { Column, Props } from './interfaces';
import { TYPE_FIELD } from '../TableView/constants';

const renderHeader = (columns: Column[]) => (
  <>
    <Row header>
      {columns.map((column) => {
        return (
          <Item key={`headers-column-${column.id}`}>
            <Text header>{column.name}</Text>
            {column.tooltipText && <Tooltip text={column.tooltipText} />}
          </Item>
        );
      })}
    </Row>
  </>
);

const renderContent = (
  columns: Column[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
) => (
  <>
    {data.length &&
      data.map((item) => (
        <>
          <Row key={`row-${item.id}`}>
            {columns.map(
              ({
                name,
                decimals,
                propertyKey,
                type,
                displayBigger,
                render,
              }) => {
                const fieldFormat = TYPE_FIELD[type] || TYPE_FIELD.string;

                return (
                  <Item key={`row-column-${name}`}>
                    {displayBigger ? (
                      <DisplayNameContainer>
                        <Tooltip
                          displayBigger
                          renderTooltipTrigger={() => (
                            <TooltipText>
                              {fieldFormat.format(
                                item,
                                item[propertyKey],
                                decimals,
                                render
                              )}
                            </TooltipText>
                          )}
                          text={item[propertyKey]}
                        />
                      </DisplayNameContainer>
                    ) : (
                      <Text>
                        {fieldFormat.format(
                          item,
                          item[propertyKey],
                          decimals,
                          render
                        )}
                      </Text>
                    )}
                  </Item>
                );
              }
            )}
          </Row>
          <RowSeparator />
        </>
      ))}
  </>
);

const SimpleTableView = ({
  data,
  columns,
  className,
  customStyle,
  theme,
}: Props): JSX.Element => {
  const updatedTheme = getTheme(theme);

  return (
    <ThemeProvider theme={updatedTheme}>
      <SimpleTableViewContainer className={className} style={customStyle}>
        <Header>{renderHeader(columns)}</Header>
        <Content>{renderContent(columns, data)}</Content>
      </SimpleTableViewContainer>
    </ThemeProvider>
  );
};

SimpleTableView.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  customStyle: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.objectOf(PropTypes.any),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      render: PropTypes.func,
      decimals: PropTypes.number,
      tooltipText: PropTypes.string,
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(Object.keys(TYPE_FIELD)),
      propertyKey: PropTypes.string.isRequired,
    })
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
};

SimpleTableView.defaultProps = {
  className: '',
  customStyle: {},
  theme: null,
};

export default SimpleTableView;
