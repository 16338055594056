export const ENUM_STATUS_LINE = {
  LOADING: 'loading',
  IDLE: 'idle',
  VALID: 'valid',
  INVALID: 'invalid',
};

export default {
  ENUM_STATUS_LINE,
};
