import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Breadcrumb from '../../Breadcrumb';

import { getClientInfo } from '../../../selectors/client';

const NavigationBreadCrumb = (props) => {
  const {
    featurePath,
    featuresKeyByPath,
    client: { storeName },
  } = props;

  const getNavigationPaths = () => {
    const navigationPaths = [];

    const feature = featuresKeyByPath[featurePath];

    if (!feature) {
      return navigationPaths;
    }

    if (!isEmpty(feature.moduleInfo)) {
      navigationPaths.push(feature.moduleInfo.breadCrumbName);
    }

    if (!isEmpty(feature.subModuleInfo) && !feature.subModuleInfo.hideSubmoduleName) {
      navigationPaths.push(feature.subModuleInfo.breadCrumbName);
    }

    navigationPaths.push(
      !!feature.getBreadCrumbText
        ? feature.getBreadCrumbText({ storeName })
        : feature.getText({ storeName }),
    );

    return navigationPaths;
  };

  return <Breadcrumb paths={getNavigationPaths()}></Breadcrumb>;
};

const mapStateToProps = (state) => ({
  featuresKeyByPath: state.baseReducer.featuresKeyByPath,
  client: getClientInfo(state.baseReducer.user),
});

NavigationBreadCrumb.propTypes = {
  featurePath: PropTypes.string,
};

export default connect(mapStateToProps)(NavigationBreadCrumb);
