import styled, { css, keyframes } from 'styled-components';

const GRADIENT = keyframes`
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
`;

export const Container = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  position: relative;
`;

export const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  background: transparent;

  font: ${(props) => props.theme.labelContainer.font};
  color: ${(props) => props.theme.labelContainer.default.color};

  ${(props) =>
    props.isDisabled &&
    css`
      color: ${props.theme.labelContainer.disabled.color};
    `}

  ${(props) => ({ ...props.labelStyle })};
`;

export const LabelRequiredCharacter = styled.div`
  margin-right: 2px;

  color: ${(props) => props.theme.labelRequiredCharacter.default.color};

  ${(props) =>
    props.isDisabled &&
    css`
      color: ${props.theme.labelRequiredCharacter.disabled.color};
    `}
`;

export const InputContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;

  background-color: ${(props) =>
    props.theme.inputContainer.default.backgroundColor};
  border: ${(props) => props.theme.inputContainer.default.border};
  border-radius: ${(props) => props.theme.borderRadius};
  cursor: ${(props) => props.theme.cursor};
  height: ${(props) => props.theme.inputContainer.height};

  &:hover {
    background-color: ${(props) =>
      props.theme.inputContainer.hover.backgroundColor};
  }

  ${(props) =>
    props.isFocus &&
    css`
      border: ${props.theme.inputContainer.focus.border};
    `}

  ${(props) =>
    props.isDisabled &&
    css`
      background-color: ${props.theme.inputContainer.disabled.backgroundColor};
      cursor: not-allowed;

      &:hover {
        background-color: ${props.theme.inputContainer.disabled
          .backgroundColor};
      }
    `}

    ${(props) =>
    props.error &&
    css`
      border: ${props.theme.inputContainer.error.border};
    `}
`;

export const DropdownList = styled.div`
  overflow-y: scroll;
  width: 100%;
  border-radius: inherit;
  max-height: ${(props) => props.theme.dropdownList.default.maxHeight};

  ${(props) =>
    props.isSearchDisplay &&
    css`
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    `}

  ${(props) =>
    props.haveButton &&
    css`
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}

  ${(props) =>
    props.isMultiSelect &&
    css`
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}

  ${(props) =>
    props.haveButton &&
    props.isSearchDisplay &&
    css`
      max-height: ${props.theme.dropdownList.searchAndButton.maxHeight};
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}

  ${(props) =>
    props.isMultiSelect &&
    props.isSearchDisplay &&
    css`
      max-height: ${props.theme.dropdownList.searchAndMulti.maxHeight};
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}

    ${(props) =>
    props.haveButton &&
    props.isMultiSelect &&
    props.isSearchDisplay &&
    css`
      max-height: ${props.theme.dropdownList.searchAndMultiAndButton.maxHeight};
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}

  ${(props) => ({ ...props.customDropdownListStyle })};
`;

export const DropdownItem = styled.div`
  align-items: center;
  display: flex;
  padding: 0px 8px;
  gap: 8px;

  cursor: ${(props) => props.theme.cursor};
  font: ${(props) => props.theme.font};
  min-height: ${(props) => props.theme.dropdownItem.minHeight};
  background-color: ${(props) =>
    props.theme.dropdownItem.default.backgroundColor};
  color: ${(props) => props.theme.dropdownItem.default.color};

  ${(props) =>
    !props.isSearchDisplay &&
    css`
      &:first-child {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
      }
    `}

  ${(props) =>
    !props.isMultiSelect &&
    css`
      &:last-child {
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
      }
    `}

  &:hover {
    background-color: ${(props) =>
      props.theme.dropdownItem.hover.backgroundColor};
  }

  ${(props) =>
    props.isDisabled &&
    css`
      cursor: not-allowed;

      background-color: ${props.theme.dropdownItem.disabled.backgroundColor};
      color: ${props.theme.dropdownItem.disabled.color};

      &:hover {
        background-color: ${props.theme.dropdownItem.disabled.backgroundColor};
      }
    `}
`;

export const DropdownItemTag = styled.div`
  margin-left: auto;
  padding: 4px 8px;
  white-space: nowrap;

  border-radius: ${(props) => props.theme.borders?.radius.weaker || '4px'};
  border: ${(props) =>
    props.theme.inputs?.primary?.default?.border || '1px solid #E2E2E2'};

  font: ${(props) => props.theme.label?.font};
  color: ${(props) => props.theme.colors?.greys?.darkest};
`;

export const DropdownItemText = styled.div`
  flex: 1;
  margin: 12px 0px;
  font: ${(props) => props.theme.dropdownItemText.default.font};
  text-overflow: ellipsis;
  white-space: pre-wrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  &.item-show-more-results-text {
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${(props) => props.theme.dropdownItemText.showMoreResult.color};
    text-decoration: underline;

    ${(props) =>
      props.isLoading &&
      css`
        animation: ${GRADIENT} 1.5s linear infinite;
        color: ${props.theme.dropdownItemText.showMoreResult.loadingColor};
      `}
  }
`;

export const SpinnerImg = styled.img`
  width: 16px;
  heigth: 16px;

  margin-right: 16px;

  animation: rotation 1s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const DropdownListContainer = styled.div`
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 16%);
  margin-top: 8px;
  position: absolute;
  width: ${(props) => props.theme.dropdownListContainer.width};
  max-height: ${(props) => props.theme.dropdownListContainer.maxHeight};
  z-index: 10;

  background-color: ${(props) =>
    props.theme.dropdownListContainer.backgroundColor};
  border-radius: ${(props) => props.theme.dropdownListContainer.borderRadius};

  ${(props) => ({ ...props.customListStyle })};
`;

export const LoadingDropdownListContainer = styled.div`
  max-height: 360px;
  padding: 12px 8px;

  background-color: ${(props) =>
    props.theme.loadingDropdownListContainer.backgroundColor};
  border-radius: ${(props) =>
    props.theme.loadingDropdownListContainer.borderRadius};

  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const LoadingDropdownItem = styled.div`
  width: 100%;
  height: 16px;

  background-color: ${(props) =>
    props.theme.loadingDropdownItem.backgroundColor};
  border-radius: ${(props) => props.theme.loadingDropdownItem.borderRadius};

  animation: ${GRADIENT} 1.5s linear infinite;
`;

export const Text = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: ${(props) => props.theme.font};
  color: ${(props) => props.theme.text.default.color};

  ${(props) =>
    props.isPlaceholder &&
    !props.isFocus &&
    css`
      color: ${props.theme.text.placeholder.color};
    `};

  ${(props) =>
    props.isDisabled &&
    css`
      color: ${props.theme.text.disabled.color};
    `};

  ${(props) =>
    props.isDisabled &&
    props.isValue &&
    css`
      color: ${props.theme.text.disabled.value.color};
    `};
`;

export const Icon = styled.img`
  ${(props) =>
    props.isClickable &&
    css`
      cursor: pointer;
    `};

  ${(props) => ({ ...props.customStyle })};
`;

export const SearchInputItem = styled.div`
  align-items: center;
  display: flex;
  padding: 0px 8px;
  gap: 8px;
  box-sizing: border-box;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  border-bottom: ${(props) => props.theme.searchInputItem.borderBottom};
  height: ${(props) => props.theme.searchInputItem.height};

  &:hover {
    background-color: ${(props) =>
      !props.isLoading && props.theme.searchInputItem.hover.backgroundColor};
  }

  ${(props) =>
    props.isLoading &&
    css`
      background: ${props.theme.searchInputItem.loading.backgroundColor};
    `};
`;

export const SearchInput = styled.input`
  width: calc(100% - 32px);
  background: transparent;
  border: none;
  padding: 0px;

  color: ${(props) => props.theme.searchInput.default.color};
  font: ${(props) => props.theme.font};

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${(props) =>
    props.isLoading &&
    css`
      background: ${props.theme.searchInput.loading.backgroundColor};
      pointer-events: none;

      ::placeholder {
        color: ${props.theme.searchInput.loading.textColor};
      }
    `};
`;

export const CheckboxContainer = styled.div``;

export const MultiselectValidationButton = styled.button`
  align-items: center;
  border: none;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  gap: 8px;
  height: 40px;
  justify-content: center;
  line-height: 16px;
  padding: 12px 16px;
  width: inherit;

  background-color: ${(props) =>
    props.theme.multiselectValidationButton.default.backgroundColor};
  color: ${(props) => props.theme.multiselectValidationButton.color};
  font-weight: ${(props) => props.theme.multiselectValidationButton.fontWeight};

  &:hover {
    background-color: ${(props) =>
      props.theme.multiselectValidationButton.hover.backgroundColor};
  }

  &:active {
    background-color: ${(props) =>
      props.theme.multiselectValidationButton.active.backgroundColor};
  }

  &:disabled {
    background-color: ${(props) =>
      props.theme.multiselectValidationButton.disabled.backgroundColor};
    cursor: not-allowed;
  }

  ${(props) =>
    props.haveButton &&
    css`
      &:disabled {
        border-top: ${props.theme.multiselectValidationButton.haveButton
          .disabled.borderTop};
      }
    `}
`;

export const DropdownButton = styled.button`
  align-items: center;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  gap: 8px;
  height: 40px;
  justify-content: center;
  line-height: 16px;
  padding: 12px 16px;
  width: inherit;

  background-color: ${(props) =>
    props.theme.dropdownButton.default.backgroundColor};
  color: ${(props) => props.theme.dropdownButton.color};
  font-weight: ${(props) => props.theme.dropdownButton.fontWeight};
  border-top: ${(props) => props.theme.dropdownButton.borderTop};
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;

  &:hover {
    background-color: ${(props) =>
      props.theme.dropdownButton.hover.backgroundColor};
  }

  &:active {
    background-color: ${(props) =>
      props.theme.dropdownButton.active.backgroundColor};
  }

  &:disabled {
    background-color: ${(props) =>
      props.theme.dropdownButton.disabled.backgroundColor};
    color: ${(props) => props.theme.dropdownButton.disabled.color};
    cursor: not-allowed;
  }
`;

export default {
  Icon,
  Container,
  LabelContainer,
  LabelRequiredCharacter,
  DropdownItem,
  DropdownItemText,
  DropdownItemTag,
  DropdownList,
  InputContainer,
  DropdownListContainer,
  Text,
  SearchInput,
  SearchInputItem,
  CheckboxContainer,
  MultiselectValidationButton,
  DropdownButton,
};
