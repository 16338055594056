import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 40px);
  position: relative;

  > div {
    overflow: auto;
  }
`;

export const Text = styled.div`
  font: ${(props) => props.theme.fonts?.textBig};
  color: ${(props) => props.theme.colors?.greys?.darkest};

  ${(props) => {
    if (props.bold) {
      return css`
        font: ${(props) => props.theme.fonts?.textMiddleBold};
      `;
    }
  }}

  ${(props) => {
    if (props.highlighted) {
      return css`
        font: ${(props) => props.theme.fonts?.textMiddleBold};
        color: ${(props) => props.theme.colors?.greys?.darkest};
      `;
    }
  }}
`;
