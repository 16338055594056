import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 72px;
  min-width: 1140px;

  display: flex;
  padding: 10px 30px;
  background-color: ${(props) => props.theme.colors?.greys.lighter || '#ffffff'};
  box-shadow: ${(props) => (props.theme.colors ? undefined : '0 3px 5px 0 rgba(0, 0, 0, 0.05)')};

  ${(props) =>
    props.theme.colors
      ? `
    justify-content: flex-start;
    align-items: center;
  `
      : undefined}
  .SingleDatePickerInput__disabled {
    background-color: ${(props) => props.theme.colors.greys.lighter};
  }
`;

export const Filter = styled.div`
  height: ${(props) => (props.theme.colors ? '40px' : '50px')} !important;

  margin-right: 10px;
  display: flex;
  align-items: center;

  &.search-bar {
    ${(props) =>
      props.theme.fonts
        ? `display: flex;
    align-items: center;
    justify-content: center;`
        : undefined}
  }

  &.date-picker {
    min-width: 120px;

    display: flex;
    align-items: center;

    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: ${(props) => (props.theme.colors ? '8px' : '4px')};

    .SingleDatePicker_picker {
      top: 44px !important;
      left: -35px !important;
    }

    .DateInput_input {
      padding: 0px !important;
    }

    .DateInput {
      width: ${(props) => (props.theme.colors ? '50px' : '0px')} !important;
    }

    .SingleDatePicker {
      margin-left: 5px;
    }

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      background-color: ${(props) => props.theme.colors.greys.lighter};
      cursor: not-allowed;
      > * {
        cursor: not-allowed !important;
        > div > div > div > input {
          cursor: not-allowed !important;
          color: ${(props) => props.theme.colors?.greys?.darkest} !important;

          &:disabled {
            &:hover {
              color: ${(props) => props.theme.colors?.greys?.darkest} !important;
            }
          }
        }
      }

      .DateInput_input__disabled {
        color: #6b768c;
        font-style: normal;

        &:hover {
          color: #6b768c !important;
        }
      }
    }
  }

  ${(props) => {
    if (props.date) {
      return css`
        width: 120px;
        border: ${(props) =>
          props.focusedDateInput
            ? props.theme.inputs?.primary.focus.border
            : props.theme.inputs?.primary.default.border};
        border-radius: ${(props) => props.theme.inputs?.primary.default.borderRadius};
        box-shadow: none !important;
      `;
    }
  }}

  &.shadowed-box {
    height: 50px;
    width: 140px;

    color: ${(props) => props.theme.colors?.greys.darker || '#6b768c'};
    text-align: center;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    background-color: ${(props) => props.theme.colors?.greys.lighter || '#ffffff'};
    border-radius: ${(props) => (props.theme.borders ? undefined : '4px')};
    box-shadow: ${(props) => (props.theme.colors ? undefined : '0px 2px 6px rgba(0, 0, 0, 0.1)')};

    .value {
      ${(props) =>
        props.theme.fonts
          ? `font: ${props.theme.fonts?.kpiBig};
          color: ${props.theme.colors.greys.darkest};`
          : `font-size: 18px;
      line-height: 20px;`}
    }

    .text {
      ${(props) =>
        props.theme.fonts
          ? `font: ${props.theme.fonts?.textMicro};`
          : `font-size: 10px;
      line-height: 11px;`}

      margin-top: 3px;
    }
  }

  &.print {
    margin: auto;
    margin-right: 0px;
  }

  .tablet-location-filter-container {
    width: 180px;
    border-radius: 4px;

    .location-filter-input-text {
      text-align: left;
      margin-right: 14px;
    }
  }

  .tablet-location-filter-list-result {
    min-width: 160px;
    max-width: 290px;
    width: fit-content;

    .tablet-location-filter-result-text {
      width: 100%;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      margin-right: 10px;
    }
  }

  &.dropdown-stores {
    .tablet-location-filter-list-result {
      min-width: 180px;
    }
  }

  .deepsight-button.locked {
    background-color: ${(props) => props.theme.colors.greys.lighter};
    border: 1px solid #bcc4d5;
    box-shadow: none;
  }
`;

export const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 16px;

  margin-left: 16px;
`;

export const StatsInfoBox = styled.div`
  width: 40px;
  height: 40px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  background: ${(props) => props.theme.colors?.greys.lightest};
  border: 1px solid ${(props) => props.theme.colors?.greys.darkest};

  &:hover {
    cursor: pointer;
  }
`;

export const StatsInfoIcon = styled.img`
  width: 12px;
  height: 12px;
`;

export const StatsMetric = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  font: ${(props) => props.theme.fonts.textSmall};
`;

export const StatsInfoBoxContainer = styled.div`
  height: auto;
  min-width: 440px;

  top: 48px;
  left: 0;
  z-index: 1;
  position: absolute;

  padding: 16px;

  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  background: ${(props) => props.theme.colors?.greys.lightest};

  &:hover {
    cursor: default;
  }
`;

export const StatsInfoBoxTitle = styled.div`
  margin-bottom: 24px;

  display: flex;
  justify-content: space-between;

  > img {
    width: 12px;
    height: 12px;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const StatsInfoBoxContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StatsInfoBoxRow = styled.div`
  gap: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  border-bottom: 1px solid #e2e2e2;

  &:not(:first-child) {
    margin-top: 8px;
  }

  > div {
    min-width: 100px;
    margin-bottom: 8px;
  }
`;

export const CalendarIcon = styled.img`
  margin-left: 15px;
  width: 16px;
  margin-top: -5px;

  &:hover {
    cursor: pointer;
  }
`;
