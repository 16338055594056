import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${(props) => props.theme.colors?.greys?.lightest};
`;

export const SubtitleText = styled.div`
  color: ${(props) => props.theme.colors.greys.darkest};

  font: ${(props) => props.theme.fonts.textMiddleNormal};
`;

export const FileName = styled.div`
  color: ${(props) => props.theme.colors.greys.darker};
  font: ${(props) => props.theme.fonts.textBigHeight24};
  text-decoration: underline;
`;

export const RemoveFileSelected = styled.img`
  cursor: 'pointer';
  width: '9px';
  height: '9px';
  marginleft: '12px';
`;

export const InputFile = styled.input`
  height: 0px;

  opacity: 0;
`;

export const FileSelectorWrapper = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
    props.interactive &&
    css`
      cursor: pointer;
    `}

  margin-top: 24px;

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;
    `}

  > img {
    margin-right: 12px;
  }
`;

export const FileSelectionIcon = styled.img``;

export const InvalidLineRender = styled.div`
  color: ${(props) => props.theme.colors.infoRed};

  font: ${(props) => props.theme.fonts.h3Bold700};
  margin-top: 24px;
`;

export const InvalidLineRenderHelp = styled.div`
  color: ${(props) => props.theme.colors.greys.darker};

  font: ${(props) => props.theme.fonts.textBigHeight24};
  margin-top: 24px;
`;

export const DownloadErrorFile = styled.div`
  color: ${(props) => props.theme.colors.greys.darkest};
  cursor: pointer;

  font: ${(props) => props.theme.fonts.textMiddleBold};
  margin-top: 24px;
`;

export const DownloadFileIcon = styled.img``;

export default {
  Container,
  SubtitleText,
  InputFile,
  FileSelectorWrapper,
  FileName,
  InvalidLineRender,
  InvalidLineRenderHelp,
  DownloadErrorFile,
  DownloadFileIcon,
  FileSelectionIcon,
  RemoveFileSelected,
};
