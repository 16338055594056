import styled, { css } from 'styled-components';

export const DropdownItem = styled.div`
  width: 100%;
  padding: 12px 8px;
  display: flex;
  flex-direction: row;
`;

export const DropdownItemImage = styled.img`
  width: 40px;
  height: 40px;
`;

export const DropdownItemValuesContainer = styled.div`
  overflow: hidden;
  padding-left: 8px;
`;

export const DropdownItemMainValue = styled.div`
  font: ${(props) => props.theme.fonts.textMiddleNormal};
  margin-bottom: 4px;
  padding-right: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DropdownItemSecondaryValue = styled.div`
  font: ${(props) => props.theme.fonts.textSmallHeight16};
  color: ${(props) => props.theme.colors.greys.dark};
  margin-top: 4px;
  padding-right: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${(props) =>
    props.isDisabled &&
    css`
      color: ${props.theme.colors.greys.light};
    `};
`;
