import { any } from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openSmallModal } from '@actions/modal';
import { showErrorMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { DisabledDatePicker } from '@commons/DatePickers/DisabledDatePicker';
import { ExportButton } from '@commons/ExportButton';
import { Filters } from '@commons/Filters';
import {
  getCustomFilterIsStrategic,
  getCustomFilterMultipleCategory,
} from '@commons/utils/filtersFetches';
import { getPreviousDayOfStore } from '@commons/utils/date';
import { NestedList, SearchBar } from '@commons/utils/styledLibraryComponents';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { useFiltersButtonState } from '@hooks/useFiltersButtonState';
import { usePaginatorState } from '@hooks/usePaginatorState';

import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import { brand as brandService } from '@services/brand';
import { ingredient as ingredientService } from '@services/ingredient';
import clientService from '@services/client';
import orderService from '@services/order';

import { config } from '@src/config';

import { DeepsightTooltip } from '@stocks/components/GraphStock/GraphTooltip';
import { getEntityUnit } from '@stocks/utils';

import {
  ConfigPanelContainer,
  ContentContainer,
  LeftSide,
  MainPageContainer,
  RightSide,
  ListContainer,
} from '../commonStyledComponents';
import { formatDataForStockGraph, METRICS } from '../utils/commons';
import { GRAPH_TYPE } from '../utils/getGraphBasicConfiguration';

import { convertQuantityToSupplierProductUnit } from './commons/format';
import StocksAnalyticsCurrentExportModal from './components/StocksAnalyticsCurrentExportModal';
import stocksService from './services/stocks';
import utilsColumns from './commons/columns';

const AMOUNT_DAY_TO_ADD_TO_END_DATE = 15;

export const StocksAnalyticsCurrent = (props) => {
  const {
    user,
    stores,
    match: { path },
    client: { clientId },
    showErrorMessage,
    openModalExportInfo,
    currency,
  } = props;

  const userLanguageCode = _.get(user, 'lnkLanguageAccountrel.code', 'fr');

  const paginatorState = usePaginatorState({ defaultMaxPerPage: 50 });

  // Loader states
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFilters, setIsLoadingFilters] = useState(true);

  // Nested List component states
  const [stocks, setStocks] = useState([]);
  const [displayedStocks, setDisplayStocks] = useState([]);
  const [columnsModal] = useState(utilsColumns.getModalConfig());

  // Interactive inputs states with data
  const [searchInput, setSearchInput] = useState('');

  // Columns
  const [columns] = useState(utilsColumns.getListConfig());

  // Brands dropdown
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  // Retailers dropdown
  const [retailers, setRetailers] = useState([]);
  const [selectedRetailers, setSelectedRetailers] = useState([]);

  // Locations dropdown
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  // Categories dropdown
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Stores dropdown
  const [selectedStore, setSelectedStore] = useState({});

  // Strategic toggle
  const [isStrategic, setIsStrategic] = useState(true);

  // Filters states
  const [filters, setFilters] = useState(null);
  const filtersButtonState = useFiltersButtonState([]);
  const [applyFilters, setApplyFilters] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState(null);

  const exportStocksAnalyticsCurrent = () => {
    const mainSheetData = displayedStocks.map((data) => ({
      name: data.name,
      isReal: data.stock.isReal,
      stockValue: Math.round(Math.max(data.stock.value, 0) * 100) / 100,
      stockUnit: getEntityUnit(data.unit, false),
      meanForecastedConsumption: data.meanForecastedConsumption,
      daysBeforeBreakage: data.daysBeforeBreakage,
    }));

    openModalExportInfo({
      component: StocksAnalyticsCurrentExportModal,
      title: i18next.t('STOCKS.CURRENT_STOCKS.EXPORT_IN_PROGRESS'),
      mainSheetData: _.sortBy(mainSheetData, 'name'),
    });
  };

  const getNextOrderOfEntityId = async (store, entityId) => {
    try {
      const nextOrders = await stocksService.getNextOrderOfEntityId(
        store.id,
        entityId,
        getPreviousDayOfStore(store, DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );
      nextOrders.sort(
        (orderA, orderB) =>
          moment
            .tz(orderA.deliveryDate, store.timezone)
            .format(DATE_DISPLAY_FORMATS.PACKED_YEAR_MONTH_DAY) -
          moment
            .tz(orderB.deliveryDate, store.timezone)
            .format(DATE_DISPLAY_FORMATS.PACKED_YEAR_MONTH_DAY),
      );

      return nextOrders.map((item) => ({
        ...item,
        deliveryDate: moment
          .tz(item.deliveryDate, store.timezone)
          .format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
        openLink: () => `${config.baseAppUrl}order/orders?search=${item.orderReference}`,
        quantity: `${convertQuantityToSupplierProductUnit(item.unit, item.quantity).toFixed(
          2,
        )} ${getEntityUnit(item.unit)}`,
      }));
    } catch (err) {
      showErrorMessage(i18next.t('STOCKS.CURRENT_STOCKS.FETCH_NEXT_ORDERS_ON_INGREDIENT_FAILED'));

      return [];
    }
  };

  const getForecastStockOfEntityId = async (store, entityId) => {
    const formattedSelectedDate = getPreviousDayOfStore(
      store,
      DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
    );
    const endDate = moment(formattedSelectedDate)
      .add(AMOUNT_DAY_TO_ADD_TO_END_DATE, 'day')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    try {
      const recommandationsArray = await orderService.getRecommandationsByBatch(
        store.id,
        [entityId],
        endDate,
        formattedSelectedDate,
        endDate,
      );

      const graph = formatDataForStockGraph(
        METRICS[1].key,
        formattedSelectedDate,
        GRAPH_TYPE.CURRENT,
        _.head(recommandationsArray),
        null,
        null,
        store.stockConvention,
        currency,
      );

      return graph;
    } catch (err) {
      showErrorMessage(i18next.t('STOCKS.CURRENT_STOCKS.GRAPH_FETCH_ERROR'));
      return {
        data: [],
        configuration: {},
      };
    }
  };

  const getForecastData = async (store, entityId) => {
    const forecastStockOfEntityId = await getForecastStockOfEntityId(store, entityId);

    const forecastData = _.get(forecastStockOfEntityId, 'data', []);

    const formattedforecastData = forecastData.map((forecastStock) => {
      const isDateToday = moment.tz(forecastStock.x, store.timezone).isSame(moment(), 'day');
      const isDateYesterday = moment
        .tz(forecastStock.x, store.timezone)
        .isSame(moment().subtract(1, 'day'), 'day');

      const shouldDisplayRealStock =
        isDateYesterday && _.get(forecastStock, 'y.stock[0].isReal', false);

      let stock =
        _.get(forecastStock, 'y.stock[0].value', null) == null // == null because we don't want to return null if the value is 0
          ? null
          : Math.round(forecastStock.y.stock[0].value * 100) / 100;

      if (stock < 0) {
        stock = 0;
      }

      const isPastDate = moment
        .tz(forecastStock.x, store.timezone)
        .isBefore(moment.tz(store.timezone), 'day');

      const tooltip = DeepsightTooltip({
        isPastDate,
        isDateToday,
        gapStock: null,
        metric: 'unit',
        graph: forecastStock,
        displayRealStockGap: false,
        unit: forecastStockOfEntityId.unit,
        displayRealStock: shouldDisplayRealStock,
        displayTheoricalStock: !shouldDisplayRealStock,
        realStock: shouldDisplayRealStock ? stock : null,
        theoricalStock: shouldDisplayRealStock ? null : stock,
        currency,
      });

      return { ...forecastStock, tooltip };
    });

    return {
      ...forecastStockOfEntityId,
      data: formattedforecastData,
      timezone: store.timezone,
    };
  };

  const refreshStockData = async (store) => {
    setIsLoading(true);

    const categories = selectedCategories.map((selectedCategory) => selectedCategory.name);

    try {
      const ingredientsStock = await stocksService.getStocksOfIngredientsByCategories(
        store.id,
        getPreviousDayOfStore(store, DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        !categories.length ? [null] : categories,
        isStrategic,
      );

      const formattedStocks = ingredientsStock.map((item) => ({
        ...item,
        modalContent: async () => await getNextOrderOfEntityId(store, item.entityId),
        graphContent: async () => await getForecastData(store, item.entityId),
      }));

      setStocks(formattedStocks);
    } catch {
      setStocks([]);
      showErrorMessage(i18next.t('STOCKS.CURRENT_STOCKS.GET_STOCK_FAILURE'));
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBrands = async () => {
    try {
      const clientBrands = await brandService.getBrandsOfClient(clientId);

      setBrands(clientBrands);
    } catch (error) {
      showErrorMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_BRANDS_FAILURE'));
    }
  };

  const fetchRetailers = async () => {
    try {
      const clientRetailers = await clientService.getRetailersOfClient(clientId);

      setRetailers(clientRetailers);
    } catch (error) {
      showErrorMessage(i18next.t('PRODUCTION.PRODUCTION.TOP_ERROR_MESSAGE_RETAILERS'));
    }
  };

  const fetchLocations = async () => {
    try {
      const clientLocations = await clientService.getLocationsByClientId(clientId);

      setLocations(clientLocations);
    } catch (err) {
      showErrorMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_LOCATIONS_FAILURE'));
    }
  };

  const fetchCategories = async () => {
    try {
      const clientcategories = await ingredientService.getIngredientsCategories(clientId);

      const formattedCategories = clientcategories.map((category) => ({
        name: category,
      }));

      setCategories(formattedCategories);

      setSelectedCategories(formattedCategories);
    } catch (err) {
      showErrorMessage(i18next.t('STOCKS.CURRENT_STOCKS.ANALYSIS_CATEGORIES_FETCH_ERROR'));
    }
  };

  // Define default selected store on loading
  useEffect(() => {
    if (_.isEmpty(stores)) {
      return;
    }

    const store = _.head(stores);

    if (selectedStore.id !== store.id) {
      setSelectedStore(store);

      refreshStockData(store);
    }
  }, []);

  // Load data to initialize dropdown filters data
  useEffect(() => {
    if (!clientId) {
      return;
    }

    (async function loadData() {
      await fetchBrands();
      await fetchRetailers();
      await fetchLocations();
      await fetchCategories();

      setIsLoadingFilters(false);
    })();
  }, [clientId]);

  // Handle search input
  useEffect(() => {
    paginatorState.setCurrentPage(1);

    if (!searchInput) {
      setDisplayStocks(stocks);

      return;
    }

    const filteredStocks = stocks.filter((data) =>
      data.name.toLowerCase().includes(searchInput.toLowerCase()),
    );

    setDisplayStocks(filteredStocks);
  }, [stocks, searchInput]);

  // Trigger refresh on filters changes
  useEffect(() => {
    if (isLoading || !applyFilters || !selectedStore.id) {
      return;
    }

    refreshStockData(selectedStore);
  }, [applyFilters]);

  return (
    <MainPageContainer>
      <NavigationBreadCrumb featurePath={path} />
      <ContentContainer>
        <ConfigPanelContainer>
          <LeftSide>
            <SearchBar
              disabled={isLoading}
              placeholder={i18next.t('GENERAL.SEARCH')}
              setValue={setSearchInput}
              value={searchInput}
            />
            <Filters
              customWidth={160}
              filtersButtonState={filtersButtonState}
              filtersModalParams={{
                // Modal Filters
                applyFilters,
                setApplyFilters,
                filters,
                setFilters,
                advancedFilters,
                setAdvancedFilters,
                // Brands
                brands,
                selectedBrands,
                setSelectedBrands,
                // Retailers
                retailers,
                selectedRetailers,
                setSelectedRetailers,
                // Locations
                locations,
                selectedLocations,
                setSelectedLocations,
                // Store
                stores,
                selectedStore,
                setSelectedStore,
                // Categories
                customMultipleDropDowns: [
                  getCustomFilterMultipleCategory(
                    categories,
                    selectedCategories,
                    setSelectedCategories,
                  ),
                ],
                // Strategic
                customToggles: [getCustomFilterIsStrategic(isStrategic, setIsStrategic)],
              }}
              filterText={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
              isDisabled={isLoadingFilters || isLoading}
            />
            <DisabledDatePicker customWidth={160} date={getPreviousDayOfStore(selectedStore)} />
          </LeftSide>
          <RightSide>
            <ExportButton
              handleClick={exportStocksAnalyticsCurrent}
              isDisabled={isLoading}
              isInpulseOnly={true}
            />
          </RightSide>
        </ConfigPanelContainer>
        <ListContainer>
          <NestedList
            currentPage={paginatorState.currentPage}
            customModalIcon={'icon-order'}
            data={displayedStocks}
            defaultOrderBy={'daysBeforeBreakage'}
            defaultOrderType={'asc'}
            graphEmptyStateLabel={i18next.t('STOCKS.CURRENT_STOCKS.FUTURE_GRAPH_EMPTY_STATE_LABEL')}
            headers={columns}
            isLoading={isLoading}
            languageCode={userLanguageCode}
            maxPerPage={paginatorState.maxPerPage}
            maxPerPageOptions={paginatorState.maxPerPageOptions}
            minWidth={'978px'}
            modalHeaders={columnsModal}
            modalTitle={i18next.t('ANALYSIS.STOCKS.COLUMN_NAME_NEXT_ORDERS')}
            setCurrentPage={paginatorState.setCurrentPage}
            setMaxPerPage={paginatorState.setMaxPerPage}
            hasPagination
          />
        </ListContainer>
      </ContentContainer>
    </MainPageContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
});

StocksAnalyticsCurrent.propTypes = {
  match: any, // react-router-dom's match route prop
};

export default connect(mapStateToProps, mapDispatchToProps)(StocksAnalyticsCurrent);
