import React from 'react';

import {
  Container,
  ChartContainer,
  ItemContainer,
  ItemColorContainer,
  ChartBar,
} from './styledComponents';

const REPEAT_CHART_BAR_COUNT = [
  { height: 45 },
  { height: 45 },
  { height: 41 },
  { height: 38 },
  { height: 36 },
  { height: 33 },
  { height: 21 },
  { height: 13 },
  { height: 6 },
  { height: 4 },
];

const ChartLoadingState = () => (
  <Container>
    <ChartContainer>
      {REPEAT_CHART_BAR_COUNT.map((repeat, index) => {
        const isInPeriod = index >= 3 && index <= 6;
        return (
          <ItemContainer key={`chart-bar-${index}`}>
            <ItemColorContainer isFirst={index === 4} isInPeriod={isInPeriod} isLast={index === 7}>
              <ChartBar height={repeat.height} />
            </ItemColorContainer>
          </ItemContainer>
        );
      })}
    </ChartContainer>
  </Container>
);

export default ChartLoadingState;
