import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 30px);
  padding: 1.5rem;
  background-color: #fff;
`;

export const Illustration = styled.img`
  width: 120px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  padding: 0px 200px;
  font: ${(props) => props.theme.fonts.kpiBig};
`;

export const SubText = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0px 200px;
  font: ${(props) => props.theme.fonts.kpiSmall};
`;

export const BottomBar = styled.div`
  width: 100%;
  height: 30px;

  bottom: 0;
  position: absolute;
  font: ${(props) => props.theme.fonts.textBigHeight16};
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.25);
  background: ${(props) => props.theme.colors.greys.dark};
`;

export const SubTitle = styled.div`
  color: #ffffff;
`;

export const IllustrationBottomBar = styled.img`
  width: 16px;
  margin-right: 5px;
`;

export const Children = styled.div`
  width: 100%;
  height: 100%;

  display: ${(props) => (props.shouldbeHidden ? 'none' : '')};
`;
