import { connect } from 'react-redux';
import { uniqBy } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { openGenericModal, refreshGenericModal } from '@actions/modal';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';
import EmptyState from '@commons/EmptyState';

import { getClientInfo } from '@selectors/client';

import DeepsightFiltersButton from '@orders/components/FilterButton';

import { Container } from './styledComponents';
import { getModalConfig } from '../../UserMappingsModal';
import { getUserMappingsActions, getUserMappingsRowActions } from '../../../utils/actions';
import { getUserMappingsColumns } from '../../../utils/columns';

const CatalogUserMappings = (props) => {
  const {
    isLoading,
    client: { storeName },
    refreshGenericModal,
    openGenericModal,
    catalogForm,
    formFields,
    user,
  } = props;

  const clientStoreNamePlural = getClientStoreNameTranslation(storeName, true);
  const [columns] = useState(getUserMappingsColumns(clientStoreNamePlural));

  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);

  const [mappings, setMappings] = useState([]);
  const [filteredMappings, setFilteredMappings] = useState([]);
  const [selectedMappings, setSelectedMappings] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  // Filters States
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);

  const [mappingsToBeAdded, setMappingsToBeAdded] = useState([]);

  // Using this name because it will later be used to update mappings in selection modal
  const updateMappings = (mappingsToKeep) => {
    setFilteredMappings(mappingsToKeep);

    catalogForm.setValue('accountMappings', mappingsToKeep);

    setSelectedMappings([]);
  };

  const handleAddAssociation = () => {
    const params = getModalConfig(catalogForm, mappings, mappingsToBeAdded, setMappingsToBeAdded);

    openGenericModal(params);
  };

  const handleRemoveAssociation = (selectedItems) => {
    const selectedItemsIds = new Set(selectedItems.map(({ id }) => id));

    const mappingsToKeep = mappings.filter(({ id }) => !selectedItemsIds.has(id));

    const firstUserName = selectedItems[0].name;

    const warningModalConfig = getConfirmationModal({
      title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_ACTION_REMOVE_SELECTION'),
      content: i18next.t('ADMIN.CATALOGS.REMOVE_USERS_WARNING', {
        count: selectedItems.length,
        name: firstUserName === '---' ? '' : firstUserName,
      }),
      icon: '/images/inpulse/warning-white-small.svg',
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: () => updateMappings(mappingsToKeep, true),
        },
      ],
    });

    openGenericModal(warningModalConfig);
  };

  useEffect(() => {
    setActions(
      getUserMappingsActions(selectedMappings, handleAddAssociation, handleRemoveAssociation, user),
    );
    setRowActions(getUserMappingsRowActions(handleRemoveAssociation, user));
  }, []);

  useEffect(() => {
    setActions(
      getUserMappingsActions(selectedMappings, handleAddAssociation, handleRemoveAssociation, user),
    );
    setRowActions(getUserMappingsRowActions(handleRemoveAssociation, user));
  }, [selectedMappings, mappingsToBeAdded, filteredMappings]);

  useEffect(() => {
    if (formFields && formFields.accountMappings) {
      setMappings(formFields.accountMappings);

      const fetchedRoles = formFields.accountMappings.map(({ role }) => ({
        name: role,
        propertyKey: 'role',
        filterValue: true,
      }));

      const uniqueRoles = uniqBy(fetchedRoles, 'name');

      setRoles(uniqueRoles);
      setSelectedRoles(uniqueRoles);
    }
  }, [formFields]);

  useEffect(() => {
    const params = getModalConfig(catalogForm, mappings, mappingsToBeAdded, setMappingsToBeAdded);

    refreshGenericModal(params);
  }, [mappingsToBeAdded, mappings]);

  /******************************/
  /** HANDLE FILTERS LIST VIEW **/
  /******************************/
  useEffect(() => {
    if (!applyFilters) {
      return;
    }

    const selectedRolesValues = selectedRoles.map(({ name }) => name);

    const filteredMappingsWithAppliedFilters = mappings.filter(({ role }) =>
      selectedRolesValues.includes(role),
    );

    if (!advancedFilters || !advancedFilters.length) {
      setFilteredMappings(filteredMappingsWithAppliedFilters);

      return;
    }

    advancedFilters[0].list = sortArrayOfObjectsAlphabetically(advancedFilters[0].list, 'name');

    const filteredMappingsWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, propertyKey, value }) => doFilter(result, propertyKey, value),
      filteredMappings,
    );

    setFilteredMappings(filteredMappingsWithAdvancedFilters);
  }, [applyFilters, advancedFilters, selectedRoles, roles]);

  if (!mappings.length) {
    return (
      <EmptyState
        label={i18next.t('ADMIN.CATALOGS.CREATION_USER_LIST_EMPTY_STATE_TITLE')}
        labelColor={ENUM_COLORS.IP_BLACK_1}
        labelFont={ENUM_FONTS.H2_INTER}
        renderActionButton={() => (
          <Button
            color={'inpulse-default'}
            handleClick={handleAddAssociation}
            icon={'/images/inpulse/add-white-small.svg'}
            label={i18next.t('GENERAL.ADD')}
          />
        )}
        subtitle={i18next.t('ADMIN.CATALOGS.CREATION_USER_LIST_EMPTY_STATE_SUBTITLE')}
        subtitleMargin={'8px'}
      />
    );
  }

  return (
    <Container>
      <ListView
        actions={actions}
        columns={columns}
        data={filteredMappings}
        defaultMaxPerPage={20}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        isLoading={isLoading}
        maxPerPageOptions={[20, 50, 100]}
        minActionsInActionsDropdown={1}
        padding={'24px 24px 0px 24px'}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => (
          <DeepsightFiltersButton
            advancedFilters={advancedFilters}
            applyFilters={applyFilters}
            columnsFilterList={columns.filter(({ filterType }) => !!filterType)}
            customMultipleDropDowns={[
              {
                id: 'roles',
                icon: '/images/icon-dropdown-grey.svg',
                list: roles,
                defaultSelectedItems: selectedRoles,
                selectedItems: selectedRoles,
                setSelectedItems: setSelectedRoles,
              },
            ]}
            filters={filters}
            isLoading={isLoading}
            readOnly={isLoading}
            setAdvancedFilters={setAdvancedFilters}
            setApplyFilters={setApplyFilters}
            setFilters={setFilters}
            textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
          />
        )}
        rowActions={rowActions}
        setSelectedItems={setSelectedMappings}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CatalogUserMappings);
