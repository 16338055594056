import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContainerHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ContainerContent = styled.div`
  width: 100%;
`;

export const TitleHeader = styled.div`
  color: ${(props) => props.theme.colors?.greys.darkest};
  font: ${(props) => props.theme.fonts.h2};
  width: fit-content;
  padding-right: 14px;
  text-align: left;
`;

export const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > div {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;

    > div {
      height: 50px;
      display: flex;
    }
  }
`;
