import { Svg, Path } from '@react-pdf/renderer';
import React from 'react';

export const LogoInpulseFull = (style) => (
  <Svg style={style} viewBox="0 0 48 16" fixed>
    <Path
      d="M13.7214 4.87024L2.93896 7.11552L3.0785 7.46479L3.08358 7.47578C3.39405 8.20762 3.85799 8.86431 4.44404 9.40147C5.03009 9.93863 5.7246 10.3437 6.48065 10.5895C7.23671 10.8352 8.03669 10.9158 8.82656 10.8258C9.61643 10.7358 10.3778 10.4773 11.0592 10.0678C11.0964 10.0458 11.1336 10.0238 11.1691 10.001C11.2605 9.94347 11.3493 9.88343 11.4364 9.82C11.4956 9.77856 11.5531 9.73544 11.6106 9.69146L11.6816 9.63311C11.7992 9.53924 11.9116 9.44114 12.0199 9.33881L12.0283 9.3312C12.6035 8.78677 13.0552 8.1252 13.3527 7.39121C13.3671 7.35738 13.3797 7.3244 13.3924 7.29057C13.4372 7.17472 13.477 7.05801 13.5142 6.93877C13.5311 6.88296 13.548 6.82714 13.5633 6.76964C13.5835 6.69522 13.6038 6.61995 13.6208 6.54384C13.6436 6.44743 13.6622 6.35018 13.68 6.25123C13.6893 6.19373 13.6994 6.13538 13.707 6.07618C13.7239 5.96032 13.7366 5.84362 13.7459 5.72607C13.7459 5.67617 13.7518 5.62712 13.7535 5.57723C13.7595 5.45714 13.7628 5.33621 13.7611 5.21443C13.7611 5.19752 13.7611 5.18145 13.7611 5.16454"
      fill="#00EA84"
    />
    <Path
      d="M12.9905 2.31458C13.1133 2.85372 13.1763 3.40478 13.1782 3.95774C13.1779 5.51159 12.6366 7.01684 11.6473 8.21508C10.658 9.41331 9.28246 10.2298 7.75678 10.5243C6.2311 10.8188 4.65048 10.573 3.28629 9.82906C1.9221 9.08513 0.859444 7.8895 0.280766 6.44742L0 5.75058L6.79927 4.39326L7.04367 5.61696L1.77001 6.66984C2.43411 7.82664 3.49994 8.69925 4.76508 9.12193C6.03021 9.54461 7.40652 9.48792 8.63261 8.96264C9.8587 8.43735 10.8492 7.48005 11.4159 6.27255C11.9826 5.06505 12.0861 3.69147 11.7067 2.41268C11.7067 2.41268 12.3097 1.80633 12.3097 1.80548L12.9905 2.31458Z"
      fill="#1D1D1B"
    />
    <Path
      d="M17.7383 5.89858V9.9976H19.0821V5.89181L18.4072 5.4436L17.7383 5.89858Z"
      fill="#1D1D1B"
    />
    <Path
      d="M18.4061 4.86434C18.8456 4.86434 19.2019 4.50805 19.2019 4.06855C19.2019 3.62905 18.8456 3.27277 18.4061 3.27277C17.9666 3.27277 17.6104 3.62905 17.6104 4.06855C17.6104 4.50805 17.9666 4.86434 18.4061 4.86434Z"
      fill="#00EA84"
    />
    <Path
      d="M20.062 5.41655H21.3305V5.91889H21.3999C21.5817 5.60683 22.0147 5.30408 22.6304 5.30408C23.6452 5.30408 24.2684 5.98823 24.2684 6.94216V9.99253H22.923V7.35824C22.923 6.88973 22.6456 6.5523 22.1855 6.5523C21.7255 6.5523 21.4092 6.94216 21.4092 7.42758V9.99253H20.062V5.41655Z"
      fill="#1D1D1B"
    />
    <Path
      d="M25.2622 5.41657H26.5891V5.92398H26.6584C26.7878 5.71594 27.1658 5.29987 27.932 5.29987C29.1802 5.29987 30.0386 6.23603 30.0386 7.67454C30.0386 9.11304 29.198 10.1084 27.9667 10.1084C27.2217 10.1084 26.8225 9.727 26.6753 9.47499H26.606V11.9892H25.2622V5.41657ZM28.6821 7.70498C28.6821 6.98531 28.2593 6.5168 27.6335 6.5168C26.9916 6.5168 26.584 7.01998 26.584 7.70498C26.584 8.43311 27.0178 8.88894 27.6335 8.88894C28.2492 8.88894 28.6821 8.4069 28.6821 7.70498Z"
      fill="#1D1D1B"
    />
    <Path
      d="M30.8965 8.46693V5.41656H32.2403V8.05171C32.2403 8.52782 32.5177 8.85764 32.9769 8.85764C33.4361 8.85764 33.7566 8.46694 33.7566 7.98236V5.41656H35.1004V9.99254H33.8259V9.49021H33.7566C33.5832 9.79381 33.1502 10.1059 32.5346 10.1059C31.5206 10.1084 30.8965 9.42086 30.8965 8.46693Z"
      fill="#1D1D1B"
    />
    <Path
      d="M36.1572 8.55401V3.49261H37.501V8.39756C37.501 8.67495 37.6397 8.81279 37.8824 8.81279H38.0465V9.99167H37.5873C36.6689 9.99252 36.1572 9.44705 36.1572 8.55401Z"
      fill="#1D1D1B"
    />
    <Path
      d="M38.6968 8.58616H39.9188C39.9535 8.90667 40.2224 9.07158 40.6638 9.07158C41.1053 9.07158 41.3666 8.90244 41.3666 8.64874C41.3666 7.82504 38.783 8.72654 38.783 6.75864C38.783 5.97892 39.4418 5.30322 40.6638 5.30322C41.7218 5.30322 42.4846 5.80556 42.5455 6.75864H41.3835C41.3404 6.48126 41.1061 6.29098 40.6638 6.29098C40.2571 6.29098 39.9966 6.45589 39.9966 6.6986C39.9966 7.44364 42.6664 6.55145 42.6664 8.57939C42.6664 9.46313 41.956 10.1016 40.6554 10.1016C39.3378 10.1084 38.723 9.5164 38.6968 8.58616Z"
      fill="#1D1D1B"
    />
    <Path
      d="M43.2988 7.73034C43.2988 6.30029 44.2519 5.30408 45.6473 5.30408C47.156 5.30408 47.9442 6.41276 47.9442 7.6872V8.07876H44.5733C44.6164 8.65974 45.0409 9.07581 45.6726 9.07581C46.2012 9.07581 46.5572 8.81534 46.6875 8.5295H47.901C47.693 9.4902 46.8642 10.1084 45.6219 10.1084C44.2959 10.1084 43.2988 9.10879 43.2988 7.73034ZM46.6951 7.18487C46.6426 6.69945 46.2443 6.3265 45.6456 6.3265C45.3968 6.31944 45.1537 6.40235 44.9611 6.56002C44.7685 6.71769 44.6392 6.93955 44.5969 7.18487H46.6951Z"
      fill="#1D1D1B"
    />
    <Path
      d="M12.7269 0.739964C12.8535 0.349404 12.6394 -0.0697744 12.2489 -0.196298C11.8583 -0.322822 11.4391 -0.108779 11.3126 0.281781C11.1861 0.672341 11.4001 1.09152 11.7907 1.21804C12.1812 1.34457 12.6004 1.13052 12.7269 0.739964Z"
      fill="#00EA84"
    />
  </Svg>
);

export const LogoInpulse = (style) => (
  <Svg style={style} viewBox="0 0 48 48">
    <Path
      d="M23.9996 47.9992C37.2542 47.9992 47.9992 37.2542 47.9992 23.9996C47.9992 10.745 37.2542 0 23.9996 0C10.745 0 0 10.745 0 23.9996C0 37.2542 10.745 47.9992 23.9996 47.9992Z"
      fill="#1D1D1B"
    />
    <Path
      d="M41.1185 22.904L14.1226 28.47L14.4697 29.3446C14.4697 29.3547 14.479 29.3632 14.4832 29.3734C15.2569 31.2066 16.415 32.8525 17.8794 34.1998C19.3438 35.5471 21.0802 36.5644 22.9715 37.1829C24.8628 37.8015 26.8648 38.0069 28.8422 37.7854C30.8197 37.5638 32.7266 36.9204 34.4341 35.8986C34.5255 35.8436 34.6178 35.7902 34.7092 35.7293C34.937 35.5853 35.1588 35.4338 35.3781 35.278C35.5254 35.173 35.6705 35.0655 35.8133 34.9554C35.8742 34.908 35.9335 34.8581 35.9936 34.8098C36.2866 34.5761 36.5688 34.3314 36.8403 34.0757L36.8606 34.0554C38.3028 32.6956 39.4368 31.0424 40.1863 29.2074C40.2202 29.1227 40.2523 29.0381 40.2854 28.9534C40.3983 28.665 40.5001 28.372 40.591 28.0746C40.6342 27.9357 40.6757 27.7952 40.7155 27.6512C40.7671 27.4641 40.8171 27.2762 40.8611 27.0865C40.917 26.8444 40.9652 26.6005 41.0084 26.355C41.0338 26.2094 41.0584 26.0629 41.0787 25.9156C41.1205 25.626 41.1532 25.3339 41.1769 25.0393C41.1862 24.9157 41.1913 24.7912 41.1981 24.6676C41.2133 24.367 41.2218 24.0639 41.2175 23.7591C41.2175 23.7168 41.2175 23.6745 41.2175 23.6347H41.2125"
      fill="#00EA84"
    />
    <Path
      d="M39.2854 16.4939C39.5928 17.8432 39.7504 19.2223 39.7553 20.6062C39.7553 29.8348 32.2454 37.3481 23.0142 37.3481C19.6691 37.3576 16.3985 36.3604 13.6277 34.4863C10.8569 32.6121 8.71409 29.9476 7.47786 26.8393L6.77344 25.0935L23.7914 21.6975L24.4019 24.7599L11.2057 27.3939C13.6247 31.6272 18.073 34.2256 23.0159 34.2256C30.5249 34.2256 36.6345 28.1161 36.6345 20.6062C36.6344 19.2984 36.4457 17.9975 36.074 16.7437C36.074 16.7437 37.5844 15.2247 37.5836 15.2197L39.2854 16.4939Z"
      fill="white"
    />
    <Path
      d="M38.6497 12.5639C38.9683 11.5868 38.4345 10.5364 37.4573 10.2177C36.4802 9.89911 35.4297 10.433 35.1111 11.4101C34.7925 12.3872 35.3263 13.4377 36.3035 13.7563C37.2806 14.0749 38.3311 13.5411 38.6497 12.5639Z"
      fill="#00EA84"
    />
  </Svg>
);
