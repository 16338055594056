import i18next from 'i18next';

import sentryService from '../../providers/sentry';

export function getClientStoreNameTranslation(clientStoreName, isPlural) {
  if (!clientStoreName) {
    return '';
  }

  if (clientStoreName.toLowerCase() === 'corner') {
    return i18next.t(
      isPlural ? 'GENERAL.STORE_NAME_LABEL_CORNER_PLURAL' : 'GENERAL.STORE_NAME_LABEL_CORNER',
    );
  }

  if (clientStoreName.toLowerCase() === 'etablissement') {
    return i18next.t(
      isPlural
        ? 'GENERAL.STORE_NAME_LABEL_ESTABLISHMENT_PLURAL'
        : 'GENERAL.STORE_NAME_LABEL_ESTABLISHMENT',
    );
  }

  if (clientStoreName.toLowerCase() === 'laboratoire') {
    return i18next.t(
      isPlural ? 'GENERAL.STORE_NAME_LABEL_LAB_PLURAL' : 'GENERAL.STORE_NAME_LABEL_LAB',
    );
  }

  if (clientStoreName.toLowerCase() === 'restaurant') {
    return i18next.t(
      isPlural
        ? 'GENERAL.STORE_NAME_LABEL_RESTAURANT_PLURAL'
        : 'GENERAL.STORE_NAME_LABEL_RESTAURANT',
    );
  }

  if (clientStoreName.toLowerCase() === 'magasin') {
    return i18next.t(
      isPlural ? 'GENERAL.STORE_NAME_LABEL_STORE_PLURAL' : 'GENERAL.STORE_NAME_LABEL_STORE',
    );
  }

  if (clientStoreName.toLowerCase() === 'boulangerie') {
    return i18next.t(
      isPlural ? 'GENERAL.STORE_NAME_LABEL_BAKERY_PLURAL' : 'GENERAL.STORE_NAME_LABEL_BAKERY',
    );
  }

  if (clientStoreName.toLowerCase() === 'kitchen') {
    return i18next.t(
      isPlural ? 'GENERAL.STORE_NAME_LABEL_KITCHEN_PLURAL' : 'GENERAL.STORE_NAME_LABEL_KITCHEN',
    );
  }

  if (clientStoreName.toLowerCase() === 'boutique') {
    return i18next.t(
      isPlural ? 'GENERAL.STORE_NAME_LABEL_BOUTIQUE_PLURAL' : 'GENERAL.STORE_NAME_LABEL_BOUTIQUE',
    );
  }

  sentryService.send(`Missing translation key for client store name ${clientStoreName}`);

  return `${clientStoreName}${isPlural ? 's' : ''}`;
}

/**
 * Takes a label text and appropriately append a column with or without a space between
 * depending on the user's language
 * @param {string} label
 * @returns string
 */
export const appendColumnToLabel = (userLanguageCode, label) => {
  if (userLanguageCode === 'en') {
    return `${label}:`;
  }
  return `${label} :`;
};

/**
 * Format a currency name to make it plural or not
 *
 * @param {string} currencyName The currency name
 * @param {boolean} isPlural Specify whether a currency name needs to be pluralized
 * @returns A formatted currency name
 */
export const getFormattedCurrencyName = (currencyName, isPlural = false) => {
  if (!currencyName) {
    return '';
  }

  return `${currencyName.toLowerCase()}${isPlural ? 's' : ''}`;
};
