import PropTypes from 'prop-types';
import React from 'react';

import { CategorySumupContainer, TextContainer, Text } from '../styledComponents';

const ProductionCategorySumup = (props) => {
  const { name, total, handleCategoryUnfolding, isCategoryOpen } = props;

  return (
    <CategorySumupContainer
      isCategoryOpen={isCategoryOpen}
      onClick={() => handleCategoryUnfolding(name)}
    >
      <img
        src={
          isCategoryOpen
            ? '/images/inpulse/carret-bottom-black.svg'
            : '/images/inpulse/carret-right-black.svg'
        }
      />
      <TextContainer>
        <Text>{name}</Text>
        <Text>{total}</Text>
      </TextContainer>
    </CategorySumupContainer>
  );
};

ProductionCategorySumup.propTypes = {
  name: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  handleCategoryUnfolding: PropTypes.func.isRequired,
  isCategoryOpen: PropTypes.bool.isRequired,
};

export default ProductionCategorySumup;
