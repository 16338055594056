import { config as appConfig } from '../config';

export const config = {
  default_locale: 'fr',
  dedicated_server: true,
  default_slo_after_revoke: false,
  audience: appConfig.cryptr.REACT_APP_CRYPTR_AUDIENCE,
  client_id: appConfig.cryptr.REACT_APP_CRYPTR_CLIENT_ID,
  cryptr_base_url: appConfig.cryptr.REACT_APP_CRYPTR_BASE_URL,
  tenant_domain: appConfig.cryptr.REACT_APP_CRYPTR_TENANT_DOMAIN,
  default_redirect_uri: appConfig.cryptr.REACT_APP_CRYPTR_DEFAULT_REDIRECT_URI,
};

export default {
  config,
};
