import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useEffect, useReducer, useState } from 'react';

import {
  changePassword,
  changePasswordSuccess,
  changePasswordError,
  updateUserPassword,
} from '@actions/user';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { Button } from '@commons/utils/styledLibraryComponents';

import { Input } from '@lib/inpulse/Input';

import {
  Container,
  TitleHeader,
  SubtitleHeader,
  ContainerHeader,
  ContainerContent,
} from './styledComponents';

export function validatePasswordMatch(password, newPassword) {
  return !!password && !!newPassword && password === newPassword;
}

const validatePassword = (value) => value && value.length >= 6;

export function renderContentOtherProfile(
  props,
  password,
  setPassword,
  matchPassword,
  setMatchPassword,
  isValidPassword,
  setIsValidPassword,
  isValidMatchPassword,
  setIsValidMatchPassword,
) {
  const { profileUser } = props;

  const title =
    i18next.t('USERS.DETAILS.PROFILE_CHANGE_PASSWORD_OTHER_USER_SECTION_TITLE')[0].toUpperCase() +
    i18next
      .t('USERS.DETAILS.PROFILE_CHANGE_PASSWORD_OTHER_USER_SECTION_TITLE')
      .substring(1)
      .toLowerCase();

  const handleChangePwd = (e) => {
    if (validatePassword(e.target.value)) {
      setIsValidPassword(true);
      setPassword(e.target.value);
      return;
    }

    setIsValidPassword(false);
    return setPassword();
  };

  const handleChangeMatchPwd = (e) => {
    if (validatePassword(e.target.value)) {
      setIsValidMatchPassword(true);
      setMatchPassword(e.target.value);
      return;
    }

    setIsValidMatchPassword(false);
    return setMatchPassword();
  };

  const submit = (event) => {
    event.preventDefault();
    const isValidMatch = validatePasswordMatch(password, matchPassword);

    if (!isValidMatch) {
      props.showMessage(i18next.t('USERS.DETAILS.PROFILE_PASSWORDS_DO_NOT_MATCH'), 'error');
      return;
    }

    props.resetPassword(profileUser.id, password);
  };

  return (
    <div>
      <ContainerHeader>
        <TitleHeader>{title}</TitleHeader>
        <SubtitleHeader>
          {i18next.t('USERS.DETAILS.PROFILE_CHANGE_PASSWORD_OTHER_USER_SECTION_SUBTITLE')}
        </SubtitleHeader>
      </ContainerHeader>
      <ContainerContent>
        <form onSubmit={submit}>
          <Input
            label={i18next.t('USERS.DETAILS.PROFILE_NEW_PASSWORD_INPUT_LABEL')}
            name="new_password"
            type="password"
            onChange={handleChangePwd}
          />
          <Input
            label={i18next.t('USERS.DETAILS.PROFILE_CONFIRM_PASSWORD_INPUT_LABEL')}
            name="match_new_password"
            type="password"
            onChange={handleChangeMatchPwd}
          />
          <Button
            buttonCustomStyle={{ marginTop: 24 }}
            color={'inpulse-default'}
            handleClick={submit}
            icon={'/images/inpulse/refresh-white-small.svg'}
            isDisabled={!isValidPassword || !isValidMatchPassword}
            label={i18next.t('GENERAL.RESET')}
            type="submit"
          />
        </form>
      </ContainerContent>
    </div>
  );
}

export function renderContentOwnProfile(
  props,
  password,
  setPassword,
  matchPassword,
  setMatchPassword,
  oldPassword,
  setOldPassword,
  isValidPassword,
  setIsValidPassword,
  isValidMatchPassword,
  setIsValidMatchPassword,
  isValidOldPassword,
  setIsValidOldPassword,
) {
  const title =
    i18next.t('USERS.DETAILS.PROFILE_CHANGE_PASSWORD_SECTION_TITLE')[0].toUpperCase() +
    i18next.t('USERS.DETAILS.PROFILE_CHANGE_PASSWORD_SECTION_TITLE').substring(1).toLowerCase();

  const handleChangeOldPwd = (e) => {
    if (validatePassword(e.target.value)) {
      setIsValidOldPassword(true);
      setOldPassword(e.target.value);

      return;
    }

    setIsValidOldPassword(false);
    setOldPassword();
  };

  const handleChangePwd = (e) => {
    if (validatePassword(e.target.value)) {
      setIsValidPassword(true);
      setPassword(e.target.value);
      return;
    }

    setIsValidPassword(false);
    setPassword();
  };

  const handleChangeMatchPwd = (e) => {
    if (validatePassword(e.target.value)) {
      setIsValidMatchPassword(true);
      setMatchPassword(e.target.value);
      return;
    }

    setIsValidMatchPassword(false);
    setMatchPassword();
  };

  const submit = (event) => {
    event.preventDefault();
    const isValidMatch = validatePasswordMatch(password, matchPassword);

    if (!isValidMatch) {
      props.showMessage(i18next.t('USERS.DETAILS.PROFILE_PASSWORDS_DO_NOT_MATCH'), 'error');
      return;
    }

    props.changePassword(oldPassword, password);
  };

  return (
    <div>
      <ContainerHeader>
        <TitleHeader>{title}</TitleHeader>
        <SubtitleHeader>
          {i18next.t('USERS.DETAILS.PROFILE_CHANGE_PASSWORD_SECTION_SUBTITLE')}
        </SubtitleHeader>
      </ContainerHeader>
      <ContainerContent>
        <form onSubmit={submit}>
          <Input
            label={i18next.t('USERS.DETAILS.PROFILE_OLD_PASSWORD_INPUT_LABEL')}
            name="old_password"
            type="password"
            onChange={handleChangeOldPwd}
          />
          <Input
            label={i18next.t('USERS.DETAILS.PROFILE_NEW_PASSWORD_INPUT_LABEL')}
            name="new_password"
            type="password"
            onChange={handleChangePwd}
          />
          <Input
            label={i18next.t('USERS.DETAILS.PROFILE_CONFIRM_PASSWORD_INPUT_LABEL')}
            name="match_new_password"
            type="password"
            onChange={handleChangeMatchPwd}
          />
          <Button
            buttonCustomStyle={{ marginTop: 20 }}
            color={'inpulse-default'}
            handleClick={submit}
            icon={'/images/inpulse/check-white-small.svg'}
            isDisabled={!isValidPassword || !isValidMatchPassword}
            label={i18next.t('GENERAL.CONFIRM')}
          />
        </form>
      </ContainerContent>
    </div>
  );
}

const ProfilePassword = (props) => {
  const { user, isCurrentUserProfile } = props;

  const [password, setPassword] = useState(null);
  const [matchPassword, setMatchPassword] = useState(null);
  const [oldPassword, setOldPassword] = useState(null);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isValidMatchPassword, setIsValidMatchPassword] = useState(false);
  const [isValidOldPassword, setIsValidOldPassword] = useState(false);

  // To force re-render when user save new language in its settings
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    forceUpdate();
  }, [user]);

  return (
    <Container>
      {!isCurrentUserProfile &&
        renderContentOtherProfile(
          props,
          password,
          setPassword,
          matchPassword,
          setMatchPassword,
          isValidPassword,
          setIsValidPassword,
          isValidMatchPassword,
          setIsValidMatchPassword,
        )}
      {isCurrentUserProfile &&
        renderContentOwnProfile(
          props,
          password,
          setPassword,
          matchPassword,
          setMatchPassword,
          oldPassword,
          setOldPassword,
          isValidPassword,
          setIsValidPassword,
          isValidMatchPassword,
          setIsValidMatchPassword,
          isValidOldPassword,
          setIsValidOldPassword,
        )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  resetPassword: (accountId, password) => {
    dispatch(updateUserPassword(accountId, password)).then(
      () => {
        dispatch(changePasswordSuccess());
        dispatch(
          showConfirmationMessage(i18next.t('USERS.DETAILS.PROFILE_PASSWORD_UPDATED_SUCCESSFULLY')),
        );
      },
      () => {
        dispatch(
          showConfirmationMessage(
            i18next.t('USERS.DETAILS.PROFILE_PASSWORD_UPDATED_FAILURE'),
            'error',
          ),
        );
        dispatch(changePasswordError());
      },
    );
  },
  changePassword: (oldPassword, newPassword) => {
    dispatch(changePassword(oldPassword, newPassword)).then(
      () => {
        dispatch(changePasswordSuccess());
        dispatch(
          showConfirmationMessage(i18next.t('USERS.DETAILS.PROFILE_PASSWORD_UPDATED_SUCCESSFULLY')),
        );
      },
      () => {
        dispatch(
          showConfirmationMessage(
            i18next.t('USERS.DETAILS.PROFILE_PASSWORD_UPDATED_FAILURE'),
            'error',
          ),
        );
        dispatch(changePasswordError());
      },
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePassword);
