import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  position: relative;
  width: 240px;
  height: 40px;
  background-color: transparent;
  z-index: 6;

  .DateInput_fang {
    display: none !important;
  }

  .DateRangePicker {
    width: 100%;
  }

  .DateRangePicker_picker {
    background-color: transparent;
    box-shadow: none !important;
    top: 48px !important;
  }

  .DateRangePickerInput__disabled {
    background-color: ${(props) => props.theme.colors.greys.light} !important;
  }

  .DateRangePickerInput_calendarIcon {
    padding: 0;
    margin: 0;
  }
  .DateRangePickerInput {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    padding: 0 16px 0 16px;

    border: 1px solid ${(props) => props.theme.colors.greys.light};
    border-radius: ${(props) => props.theme.inputs.primary.default.borderRadius};
  }

  .CalendarMonth_caption {
    color: ${(props) => props.theme.colors?.greys?.darkest} !important;
    font: ${(props) => props.theme.fonts?.h3} !important;
  }

  .DateInput_input {
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    text-align: center !important;
    cursor: pointer;
  }

  .DateInput_input__disabled {
    :hover {
      cursor: not-allowed;
    }
  }

  .DateInput_input__focused {
    border-bottom: none !important;
  }

  .DayPicker_weekHeader_li {
    font: ${(props) => props.theme.fonts?.textMicro} !important;
  }

  .DayPicker > div:first-child {
    display: flex;
    flex-direction: column;
    gap: ${(props) => (props.calendarInfo ? '16px' : 'none')};
  }
  .DayPicker__withBorder {
    background: transparent;
    box-shadow: none;
  }
  .DayPicker_transitionContainer {
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  }

  .CalendarDay {
    background: ${(props) => props.theme.colors?.greys.lightest} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  .CalendarDay__default:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  .CalendarDay__highlighted_calendar {
    background: #fff !important;
  }

  .CalendarDay__highlighted_calendar:hover {
    background: #e4e7e7 !important;
  }

  .CalendarDay__selected_span {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    border: 1px double #e2e2e2 !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active .CalendarDay__selected:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    border: 1px double #e2e2e2 !important;
  }

  .CalendarDay__selected_start,
  .CalendarDay__selected_start:hover,
  .CalendarDay__selected_end,
  .CalendarDay__selected_end:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover {
    color: ${(props) => props.theme.colors?.greys.dark} !important;
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
  }
`;
