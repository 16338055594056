import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 252px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  position: absolute;
  margin-top: 88px;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2px;

  justify-content: flex-start;
`;
