import { getActionAuthorization } from '../commons/utils';

const TRANSFER_ACTIONS_RESOURCE = {
  CREATE: 'transfer:create',
  EDIT: 'transfer:edit',
  RECEIVE: 'transfer:receive',
  DELETE: 'transfer:delete',
  CHANGE_TRANSFER_DATE: 'transfer:edit:transfer-date',
  CORRECT: 'transfer:correct',
};

export const canCreateTransfer = (actions) =>
  getActionAuthorization(actions, TRANSFER_ACTIONS_RESOURCE.CREATE);

export const canEditTransfer = (actions) =>
  getActionAuthorization(actions, TRANSFER_ACTIONS_RESOURCE.EDIT);

export const canReceiveTransfer = (actions) =>
  getActionAuthorization(actions, TRANSFER_ACTIONS_RESOURCE.RECEIVE);

export const canDeleteTransfer = (actions) =>
  getActionAuthorization(actions, TRANSFER_ACTIONS_RESOURCE.DELETE);

export const canChangeTransferDate = (actions) =>
  getActionAuthorization(actions, TRANSFER_ACTIONS_RESOURCE.CHANGE_TRANSFER_DATE);

export const canCorrectTransfer = (actions) =>
  getActionAuthorization(actions, TRANSFER_ACTIONS_RESOURCE.CORRECT);
