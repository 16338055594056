import { productionMetrics } from '../services/productionMetrics';

export const receiveMetricsByDates = (productionMetricsByDates) => ({
  type: 'PRODUCTION_METRICS_BY_DATES_SUCCESS',
  productionMetricsByDates,
});

export const requestMetricsByDatesError = (error) => ({
  type: 'PRODUCTION_METRICS_BY_DATES_FAILURE',
  error,
});

export const getMetricsByDates = (storeId, startDate, endDate) =>
  function (dispatch) {
    dispatch({
      type: 'PRODUCTION_METRICS_BY_DATES_REQUEST',
    });
    return productionMetrics.getMetricsByDates(storeId, startDate, endDate);
  };
