import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 160px);
  overflow-y: hidden;
`;

export const FrancoContainer = styled.div`
  width: 100%;

  padding: 24px;

  display: flex;
  flex-direction: row;
  column-gap: 24px;
`;

export const ItemListContainer = styled.div`
  width: 100%;
  height: calc(100% - 88px);

  & > div {
    width: 100%;
    height: 100%;
  }
`;
