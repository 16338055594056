import { connect } from 'react-redux';
import { isEmpty, get, pick, isEqual, omit, snakeCase, sortBy } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { buildSchema } from '@commons/GenericForm';
import { Button } from '@commons/utils/styledLibraryComponents';
import { CONSUMPTION_COMPUTATION_TYPES } from '@commons/constants/centralConstants';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { formatNewItemsOfDropdownSelection } from '@commons/utils/format';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getStoresOfUser, receiveStores, requestStoresError } from '@actions/store';
import { isUserAllowedToAccessProduction } from '@commons/utils/features';
import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showSuccessMessage, showErrorMessage } from '@actions/messageconfirmation';
import Footer from '@commons/Footer/Footer';
import normalizeStringValue from '@commons/utils/normalizeStringValue';
import TimezonesFormat from '@commons/Timezones/format';
import utilsXLS from '@commons/utils/makeXLS';
import WhiteCardForm from '@commons/WhiteCardForm';

import { getClientInfo } from '@selectors/client';

import { brand as brandService } from '@services/brand';
import { group as groupService } from '@services/group';
import { upload as uploadService } from '@services/upload';
import clientService from '@services/client';
import libeoService from '@services/libeo';
import storeService from '@services/store';

import { getGroupCreationParamsModal } from '@admin/stores/utils/modalUtils';
import { STOCK_LOSS_CONVENTION_DROPDOWN_ITEMS } from '@admin/utils/DropdownItems';
import HeaderPicture from '@admin/components/HeaderPicture';

import { Container, FormContainer, ButtonsContainer } from './styledComponents';
import { getModalParams } from './utils/getModalParams';
import { PAYMENT_TERM_IN_DAYS_DROPDOWN_VALUES } from '@commons/constants/dropdown';
import {
  STORE_DETAILS_FORM_INPUTS,
  STORE_INFORMATIONS_INPUTS,
  CENTRAL_KITCHEN_PRODUCTION_PLANNING_INPUTS,
  STORE_LOCATION_INPUTS,
  STORE_PRODUCTION_INPUTS,
  STORE_LIBEO_INPUTS,
  STORE_KITCHEN_DELIVERY_INPUTS,
  STORE_COMPANY_INPUTS,
} from './utils/formInputsConfiguration';
import getXlsHeaderSettings from './utils/getXlsHeaderSettings';

const FIELDS_TO_COMPARE_CENTRAL_TO_DISPLAY_SAVE = [
  'additionnalAddress',
  'telephone',
  'closingDays',
  'showYesterdayStock',
  'groups',
  'companyName',
  'vatNumber',
  'siret',
  'companyType',
  'capital',
  'companyRegister',
];
const FIELDS_TO_COMPARE_SALE_POINT_TO_DISPLAY_SAVE = [
  'additionnalAddress',
  'telephone',
  'closingDays',
  'groups',
  'companyName',
  'vatNumber',
  'siret',
  'companyType',
  'capital',
  'companyRegister',
];

const FIELDS_TO_OMIT_TO_UPDATE_STORE_FROM_ADMIN = ['partnerId'];

const CONSUMPTION_COMPUTATION_TYPES_TRANSLATION_KEY = {
  [CONSUMPTION_COMPUTATION_TYPES.ORDERS]: 'FEATURE.OPERATIONS.ORDERS',
  [CONSUMPTION_COMPUTATION_TYPES.PRODUCT_MIX]: 'GENERAL.PRODUCT_MIX',
};

const DEFAULT_STORE_PICTURE = '/images/inpulse/store-kitchen-photo-placeholder.svg';

const PARTNER_NAME_LIBEO = 'libeo';

const StoreInformations = (props) => {
  const {
    showSuccessMessage,
    showErrorMessage,
    pageLoading,
    pageLoaded,
    getStoresOfUser,
    storeParams,
    hasUserAccessToProductionFeature,
    client: { clientId, name: clientName, storeName: clientStoreName, hasMultipleBrands },
    openGenericModal,
    refreshGenericModal,
  } = props;

  const history = useHistory();

  // Global states
  const [isFooterDisplayed, setIsFooterDisplayed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [picture, setPicture] = useState(null);
  const [selectedPictureFile, setSelectedPictureFile] = useState(null);
  const [isSynchronizing, setIsSynchronizing] = useState(false);
  const [libeoSuppliersReport, setLibeoSuppliersReport] = useState([]);
  const [isLibeoPartner, setIsLibeoPartner] = useState(false);

  // Libeo
  const [libeoApiKey, setLibeoApiKey] = useState('');

  // Brands
  const [brands, setBrands] = useState([]);

  // Groups
  const [clientGroups, setClientGroups] = useState([]);
  const [groupCreationInputValue, setGroupCreationInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Retailers
  const [retailers, setRetailers] = useState([]);

  // Form
  const [, setForm] = useState(useForm());

  // Declared here instead of the end of the file because it needs to be declared before feeding it to allInputs
  const handleLibeoSync = async () => {
    setIsSynchronizing(true);

    try {
      const { suppliersStatusById } = await libeoService.updateCredentialsByStoreId(
        storeParams.id,
        libeoApiKey,
      );

      const suppliers = Object.values(suppliersStatusById);

      const formattedSuppliers = suppliers.map(
        ({ id, name, siren, libeoStatus, sirenLibeo, nameLibeo }) => ({
          storeName: storeParams.name,
          id: id,
          name: name,
          siren: siren,
          status: libeoStatus,
          sirenLibeo: sirenLibeo,
          nameLibeo: nameLibeo,
        }),
      );

      setLibeoSuppliersReport(formattedSuppliers);
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORES.LIBEO_SYNC_ERROR'));
    } finally {
      setIsSynchronizing(false);
    }
  };

  const allInputs = STORE_DETAILS_FORM_INPUTS({
    storeParams,
    handleLibeoSync,
    libeoApiKey,
    setLibeoApiKey,
  });

  const storeForm = useForm({
    defaultValues: {},
    resolver: yupResolver(buildSchema(allInputs)),
  });

  const formFields = useWatch({
    control: storeForm.control,
  });

  const fetchBrandsOfClient = async () => {
    try {
      const result = await brandService.getBrandsOfClient(clientId);

      return result;
    } catch {
      showErrorMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_BRANDS_FAILURE'));

      return [];
    }
  };

  const fetchGroupsOfClientAndStores = async (clientId, storeId) => {
    try {
      const fetchedClientGroups = await clientService.getGroupsOfClient(clientId);
      const fetchedStoreGroups = await groupService.getGroupsOfStores([storeId]);

      return { fetchedClientGroups, fetchedStoreGroups };
    } catch {
      showErrorMessage(i18next.t('BACKOFFICE.GROUPS.FETCHING_ERROR'));

      return [];
    }
  };

  const fetchRetailersOfClient = async () => {
    try {
      const retailers = await clientService.getRetailersOfClient(clientId);

      return retailers;
    } catch (err) {
      showErrorMessage(i18next.t('STOCKS.CURRENT_STOCKS.GET_RETAILERS_FAILURE'));

      return [];
    }
  };

  const isLibeoPartnerActiveOnClient = async () => {
    try {
      const partners = await clientService.getPartnershipsByClientId(clientId);

      return partners.some(({ name, activated }) => name === PARTNER_NAME_LIBEO && !!activated);
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORES.FETCH_PARTNERS_ERROR'));
      return false;
    }
  };

  const fetchLibeoApiKey = async () => {
    try {
      const { apiKey } = await libeoService.getCredentialsByStoreId(storeParams.id);

      return apiKey;
    } catch {
      return '';
      // No showErrorMessage because the route returns a 404 when store has no connection to libeo and we don't want any error message when it happens
    }
  };

  useEffect(() => {
    if (isEmpty(storeParams)) {
      return;
    }

    (async function loadData() {
      setIsLoading(true);
      pageLoading();

      setPicture(storeParams.picture);

      // Libeo
      if (!storeParams.isKitchen) {
        const hasLibeoAsPartner = await isLibeoPartnerActiveOnClient();

        setIsLibeoPartner(hasLibeoAsPartner);

        if (hasLibeoAsPartner) {
          const fetchedLibeoApiKey = await fetchLibeoApiKey();

          setLibeoApiKey(fetchedLibeoApiKey);
          storeForm.setValue('libeoApiKey', fetchedLibeoApiKey);
        }
      }

      // Retailers
      const fetchedRetailers = await fetchRetailersOfClient();
      setRetailers(fetchedRetailers);

      if (fetchedRetailers.length) {
        const matchingRetailer = fetchedRetailers.find(({ id }) => id === storeParams.retailerId);

        storeForm.setValue('retailer', matchingRetailer);
      }

      // Brands
      if (hasMultipleBrands) {
        const fetchedBrands = await fetchBrandsOfClient();

        setBrands(fetchedBrands);

        const matchingBrand = fetchedBrands.find(({ id }) => id === storeParams.brandId);

        storeForm.setValue('brand', matchingBrand);
      }

      // Groups
      const { fetchedClientGroups, fetchedStoreGroups } = await fetchGroupsOfClientAndStores(
        clientId,
        [storeParams.id],
      );

      setClientGroups(fetchedClientGroups);

      if (fetchedStoreGroups.length) {
        const matchingGroups = fetchedStoreGroups.map(
          ({ lnkGroupStoregroupmappingrel }) => lnkGroupStoregroupmappingrel,
        );

        storeForm.setValue('groups', matchingGroups);
      }

      // Informations
      storeForm.setValue('name', storeParams.name);
      storeForm.setValue('partnerId', storeParams.partnerId);
      storeForm.setValue('telephone', storeParams.telephone);
      storeForm.setValue('closingDays', storeParams.closingDays);

      // Location
      storeForm.setValue('adress', storeParams.adress);
      storeForm.setValue('postCode', storeParams.postCode);
      storeForm.setValue('city', storeParams.city);
      storeForm.setValue('country', storeParams.country);
      storeForm.setValue(
        'timezone',
        TimezonesFormat.formatToInpulseFromString(storeParams.timezone),
      );
      storeForm.setValue('additionnalAddress', storeParams.additionnalAddress);

      // Company
      storeForm.setValue('companyName', storeParams.companyName);
      storeForm.setValue('siret', storeParams.siret);
      storeForm.setValue('vatNumber', storeParams.vatNumber);
      storeForm.setValue('companyType', storeParams.companyType);
      storeForm.setValue('capital', storeParams.capital);
      storeForm.setValue('companyRegister', storeParams.companyRegister);
      storeForm.setValue(
        'paymentTermInDays',
        PAYMENT_TERM_IN_DAYS_DROPDOWN_VALUES[storeParams.paymentTermInDays],
      );

      // Production
      const selectedLossConvention = STOCK_LOSS_CONVENTION_DROPDOWN_ITEMS.find(
        ({ id }) => id === storeParams.productionLossConvention,
      );

      const selectedStockConvention = STOCK_LOSS_CONVENTION_DROPDOWN_ITEMS.find(
        ({ id }) => id === storeParams.productionStockConvention,
      );

      storeForm.setValue('productionLossConvention', selectedLossConvention);
      storeForm.setValue('productionStockConvention', selectedStockConvention);

      if (storeParams.isKitchen) {
        // Delivery
        storeForm.setValue('supplier', get(storeParams, 'supplier[0]', {}));
        storeForm.setValue('type', {
          id: 'DELIVERY_TYPE_INPUT',
          name: i18next.t('GENERAL.SUPPLIER'),
        });
        storeForm.setValue('supplierId', get(storeParams, 'supplier[0].id', ''));
        storeForm.setValue('counts', {
          associatedStoresCount: get(storeParams, 'supplier[0].associatedStoresCount', 0),
          supplierProfilesCount: get(storeParams, 'supplier[0].supplierProfilesCount', 0),
        });

        // Production planning
        storeForm.setValue('consumptionComputation', {
          id: storeParams.consumptionComputation,
          name:
            !!storeParams.consumptionComputation &&
            i18next.t(
              CONSUMPTION_COMPUTATION_TYPES_TRANSLATION_KEY[storeParams.consumptionComputation],
            ),
        });

        storeForm.setValue('showYesterdayStock', storeParams.showYesterdayStock);
      }

      setForm(storeForm);

      pageLoaded();
      setIsLoading(false);
    })();
  }, [storeParams]);

  useEffect(() => {
    if (isLoading || isEmpty(formFields)) {
      return;
    }

    const isFormDirty = get(storeForm, 'formState.isDirty', false);

    const formFieldsForComparaison = {
      ...formFields,
      closingDays: formFields.closingDays.map((closingDay) => omit(closingDay, 'value')),
    };

    formFieldsForComparaison.closingDays = formFields.closingDays.map((closingDay) =>
      omit(closingDay, 'value'),
    );

    if (hasUserAccessToProductionFeature) {
      formFieldsForComparaison.productionLossConvention = get(
        formFields,
        'productionLossConvention.id',
        '',
      );
      formFieldsForComparaison.productionStockConvention = get(
        formFields,
        'productionStockConvention.id',
        '',
      );
    }

    const dirtyFields = Object.keys(get(storeForm, 'formState.dirtyFields', {}));

    const hasAtLeastOneDifferentField = dirtyFields.some(
      (field) => !isEqual(storeParams[field], formFieldsForComparaison[field]),
    );

    if ((isFormDirty && hasAtLeastOneDifferentField) || picture !== storeParams.picture) {
      setIsFooterDisplayed(true);
      return;
    }

    setIsFooterDisplayed(false);
  }, [formFields, picture, isLoading]);

  useEffect(() => {
    if (!libeoSuppliersReport.length) {
      return;
    }

    const params = getModalParams({ downloadLibeoStatusReport });

    openGenericModal(params);
  }, [libeoSuppliersReport]);

  useEffect(() => {
    refreshGenericModal(
      getGroupCreationParamsModal(
        groupCreationInputValue,
        handleNewGroupInputChange,
        handleSaveNewGroupDropdown,
        errorMessage,
        closeCleanUp,
      ),
    );
  }, [groupCreationInputValue]);

  const handlePreviousPage = () => {
    history.goBack();
  };

  const uploadPicture = async () => {
    const params = {
      clientId,
      // Add timestamp to avoid delay between two changes
      supplierProductId: `${storeParams.id}_${moment().format(
        DATE_DISPLAY_FORMATS.UNDERSCORED_MONTH_DAY_HOUR_MINUTE_SECOND,
      )}`,
    };

    return uploadService.uploadFile(selectedPictureFile, params);
  };

  const handleSave = async () => {
    try {
      pageLoading();

      const formFieldsToPick = pick(
        formFields,
        storeParams.isKitchen
          ? FIELDS_TO_COMPARE_CENTRAL_TO_DISPLAY_SAVE
          : FIELDS_TO_COMPARE_SALE_POINT_TO_DISPLAY_SAVE,
      );

      const formattedStore = {
        ...omit(storeParams, FIELDS_TO_OMIT_TO_UPDATE_STORE_FROM_ADMIN),
        ...formFieldsToPick,
        groups: formatNewItemsOfDropdownSelection(get(formFields, 'groups', [])),
        closingDays: formFields.closingDays.map((closingDay) => closingDay.itemValue),
        paymentTermInDays: get(formFields, 'paymentTermInDays.value', null),
      };

      if (hasUserAccessToProductionFeature) {
        formattedStore.productionLossConvention = get(
          formFields,
          'productionLossConvention.id',
          '',
        );
        formattedStore.productionStockConvention = get(
          formFields,
          'productionStockConvention.id',
          '',
        );
      }

      if (hasMultipleBrands) {
        formattedStore.brandId = get(formFields, 'brand.id', null);
      }

      if (retailers.length) {
        formattedStore.retailerId = get(formFields, 'retailer.id', null);
      }

      if (picture !== storeParams.picture) {
        if (!!picture) {
          const url = await uploadPicture();

          formattedStore.picture = get(url, 'data.fileUri', storeParams.picture);
        } else {
          formattedStore.picture = null;
        }
      }

      await storeService.patchStore(storeParams.id, formattedStore);

      // Fetch in redux an updated version of the stores
      getStoresOfUser();

      showSuccessMessage(i18next.t('ADMIN.STORES.SAVING_SUCCESS'));

      pageLoaded();
      handlePreviousPage();
    } catch {
      pageLoaded();
      showErrorMessage(i18next.t('ADMIN.STORES.DETAILS_SAVE_ERROR'));
    }
  };

  const downloadLibeoStatusReport = () => {
    if (!libeoSuppliersReport.length) {
      return;
    }

    try {
      const fileName = `${snakeCase(clientName)}-${clientStoreName}-Libeo-${moment()
        .tz(storeParams.timezone)
        .format(DATE_DISPLAY_FORMATS.HYPHEN_YEAR_MONTH_DAY_HOUR_MINUTE_SECOND)}`;

      const translatedClientStoreName = getClientStoreNameTranslation(clientStoreName, false);
      const xlsHeadersSettings = getXlsHeaderSettings(translatedClientStoreName);

      const headers = xlsHeadersSettings.map(({ displayName }) => displayName);

      const mappedData = libeoSuppliersReport.map((supplierReports) =>
        xlsHeadersSettings.map(({ keyName, transform }) => {
          const supplierReportKey = get(supplierReports, keyName);
          return transform(supplierReportKey);
        }),
      );

      utilsXLS.makeXLS(fileName, [
        {
          headers,
          title: i18next.t('FEATURE.ADMIN.SUPPLIERS'),
          data: mappedData,
        },
      ]);

      showSuccessMessage(i18next.t('ADMIN.STORES.SYNCH_REPORT_DOWNLOAD_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORES.SYNCH_REPORT_DOWNLOAD_ERROR'));
    } finally {
      setLibeoSuppliersReport([]);
    }
  };

  const handleNewGroupInputChange = (newValue) => {
    setGroupCreationInputValue(newValue);

    if (!newValue) {
      setErrorMessage(i18next.t('GENERAL.REQUIRED_FILED_ERROR_MESSAGE'));

      return;
    }

    const alreadyExists = clientGroups.some(
      ({ name }) => normalizeStringValue(name) === normalizeStringValue(newValue),
    );

    if (alreadyExists) {
      setErrorMessage(i18next.t('GENERAL.MODAL_GROUP_ALREADY_USED'));

      return;
    }

    setErrorMessage('');
  };

  const handleSaveNewGroupDropdown = () => {
    const newGroup = { id: clientGroups.length, name: groupCreationInputValue.trim() };

    const updatedGroups = sortBy([...clientGroups, newGroup], 'name');

    setClientGroups(updatedGroups);

    const alreadySelectedGroups = storeForm.getValues('groups') || [];

    // Option "shouldDirty" allows us to dirty storeForm.groups field
    storeForm.setValue('groups', [...alreadySelectedGroups, newGroup], { shouldDirty: true });

    closeCleanUp();
  };

  const closeCleanUp = () => {
    setErrorMessage('');
    setGroupCreationInputValue('');
  };

  const handleGroupsCreation = () => {
    openGenericModal(
      getGroupCreationParamsModal(
        groupCreationInputValue,
        handleNewGroupInputChange,
        handleSaveNewGroupDropdown,
        errorMessage,
        closeCleanUp,
      ),
    );
  };

  return (
    <>
      <Container>
        <FormContainer isFooterDisplayed={isFooterDisplayed}>
          <HeaderPicture
            isDefaultThumbnail={!storeParams.picture}
            picture={picture || DEFAULT_STORE_PICTURE}
            setSelectedFile={setSelectedPictureFile}
            title={storeParams.name}
            onPictureChange={setPicture}
          />
          <WhiteCardForm
            form={storeForm}
            inputs={STORE_INFORMATIONS_INPUTS({
              brands,
              retailers,
              clientGroups,
              hasMultipleBrands,
              handleGroupsCreation,
            })}
            shouldDisplayError={false}
            title={i18next.t('ADMIN.STORES.DETAIL_INFORMATION_FORM_INFORMATIONS')}
          />
          {storeParams.isKitchen && (
            <WhiteCardForm
              form={storeForm}
              inputs={CENTRAL_KITCHEN_PRODUCTION_PLANNING_INPUTS()}
              shouldDisplayError={false}
              title={i18next.t('ADMIN.PRODUCTS.PRODUCTION_PLANNING')}
            />
          )}
          <WhiteCardForm
            form={storeForm}
            inputs={STORE_LOCATION_INPUTS}
            shouldDisplayError={false}
            title={i18next.t('BACKOFFICE.STORES.LOCATION')}
          />
          <WhiteCardForm
            form={storeForm}
            inputs={STORE_COMPANY_INPUTS}
            shouldDisplayError={false}
            title={i18next.t('ADMIN.STORES.COMPANY')}
          />
          {hasUserAccessToProductionFeature && !storeParams.isKitchen && (
            <WhiteCardForm
              form={storeForm}
              inputs={STORE_PRODUCTION_INPUTS}
              shouldDisplayError={false}
              title={i18next.t('BACKOFFICE.STORES.CREATION_MODAL_CARD_PRODUCTION_TITLE')}
            />
          )}
          {isLibeoPartner && !storeParams.isKitchen && (
            <WhiteCardForm
              customStyle={{ height: '224px' }}
              form={storeForm}
              inputs={STORE_LIBEO_INPUTS({
                handleLibeoSync,
                isSynchronizing,
                libeoApiKey,
                setLibeoApiKey,
              })}
              shouldDisplayError={false}
              title={i18next.t('ADMIN.STORES.PARTNER_INTEGRATION', { partnerName: 'Libeo' })}
            />
          )}
          {storeParams.isKitchen && (
            <WhiteCardForm
              form={storeForm}
              inputs={STORE_KITCHEN_DELIVERY_INPUTS}
              shouldDisplayError={false}
              title={i18next.t('ADMIN.STORES.DELIVERY_WHITE_CARD_TITLE')}
            />
          )}
        </FormContainer>
      </Container>
      {isFooterDisplayed && (
        <Footer>
          <ButtonsContainer>
            <Button
              color={'inpulse-outline'}
              handleClick={handlePreviousPage}
              icon={'/images/inpulse/close-black-small.svg'}
              label={i18next.t('GENERAL.CANCEL')}
            />
            <Button
              color={'inpulse-default'}
              handleClick={handleSave}
              icon={'/images/inpulse/save-white-small.svg'}
              isDisabled={false}
              label={i18next.t('GENERAL.SAVE')}
            />
          </ButtonsContainer>
        </Footer>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  hasUserAccessToProductionFeature: isUserAllowedToAccessProduction(state.baseReducer.userRights),
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message, type) => {
    dispatch(showSuccessMessage(message, type));
  },
  showErrorMessage: (message, type) => {
    dispatch(showErrorMessage(message, type));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  getStoresOfUser: () =>
    dispatch(getStoresOfUser()).then(
      (result) => {
        dispatch(receiveStores(result));
      },
      (error) => {
        dispatch(requestStoresError(error));
      },
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreInformations);
