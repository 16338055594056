import styled, { keyframes } from 'styled-components';

const gradient = keyframes`
  0% { opacity: 0.4; }
  50% { opacity: 1; }
  100% { opacity: 0.4; }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;

  justify-content: space-between;
`;

export const LeftPartHeader = styled.div`
  display: flex;

  gap: 24px;
`;

export const InputSection = styled.div`
  display: flex;
  gap: 12px;
`;

export const SearchContainer = styled.div`
  width: 160px;
  height: 40px;
`;

export const FrancoSection = styled.div`
  display: flex;
  gap: 24px;
`;

export const FrancoContainer = styled.div`
  width: ${(props) => (props.minus ? '160px' : '240px')};
  height: 40px;

  border-radius: ${(props) => props.theme.borders.radius.weak};

  background: linear-gradient(
    90deg,
    ${(props) => props.theme.colors.greys.lightest} 0%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: ${gradient} 1.5s linear infinite;
`;

export const RightPartHeader = styled.div`
  display: flex;
`;

export default {
  Container,
  SearchContainer,
  FrancoContainer,
  LeftPartHeader,
  InputSection,
  FrancoSection,
  RightPartHeader,
};
