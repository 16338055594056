import React from 'react';

import PropTypes from 'prop-types';

import { TableFormContainer } from './styledComponents';
import { TableFormContent } from './Content';
import { TableFormHeader } from './Header';

export const TableForm = (props) => {
  const {
    form,
    fields,
    columns,
    emptyState,
    resolveInputs,
    fieldArrayName,
    deleteFunction,
    renderActions,
    isEditionAllowed,
    register,
    displayStatusIcon,
  } = props;

  return (
    <TableFormContainer>
      {renderActions && renderActions()}
      <TableFormHeader columns={columns} />
      <TableFormContent
        columns={columns}
        deleteFunction={deleteFunction}
        displayStatusIcon={displayStatusIcon}
        emptyState={emptyState}
        fieldArrayName={fieldArrayName}
        fields={fields}
        form={form}
        isEditionAllowed={isEditionAllowed}
        register={register}
        resolveInputs={resolveInputs}
      />
      {props.children}
    </TableFormContainer>
  );
};

TableForm.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      width: PropTypes.string,
      isRequired: PropTypes.bool,
      isFixed: PropTypes.bool,
    }),
  ).isRequired,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      defaultValue: PropTypes.any,
      rule: PropTypes.any,
    }).isRequired,
  ),
  emptyState: PropTypes.object,
  fields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  form: PropTypes.shape().isRequired,
  fieldArrayName: PropTypes.string.isRequired,
  deleteFunction: PropTypes.func,
  renderActions: PropTypes.func,
  displayStatusIcon: PropTypes.bool,
  isEditionAllowed: PropTypes.bool,
  resolveInputs: PropTypes.func, // see LossForm/index.js for example
};

TableForm.defaultProps = {
  items: [],
  emptyState: null,
  displayStatusIcon: true,
  isEditionAllowed: true,
};

{
  /* resolveInputs={resolveInputs} */
}
