const updateFilteredArrays = (array, setter, categoryProducts) => {
  const products = categoryProducts;
  const res = {};
  products.forEach((product) => {
    res[product.id] = [];
  });
  if (array.length) {
    array.forEach((data) => {
      if (Object.keys(res).includes(data.supplierProductId)) {
        res[data.supplierProductId].push(data);
      }
    });
  }
  setter(res);
};

export default updateFilteredArrays;
