import styled from 'styled-components';

export const ModalConversionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 248px;
`;

export const ModalConversionInputs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  width: inherit;

  // Enforce dropdown width, so that even when a value is selected, the width remains the same
  > div > div {
    width: 124px;
  }
`;

export const ModalConversionRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: inherit;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
