import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 106px);
  position: absolute;
  margin-top: 106px;
`;

export const WarningModalButtonContainer = styled.div`
  padding: 0px 24px 24px 24px;

  display: flex;
  justify-content: end;

  & > :first-child {
    margin-right: 24px;
  }
`;

export const WarningModalBackgroundContainer = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;

  background-color: rgba(29, 29, 27, 0.72);

  z-index: 13;
`;

export const WarningModalContainer = styled.div`
  width: 600px;
  margin-right: 64

  display: flex;
  flex-direction: column;

  background: ${(props) => props.theme.colors.greys.lightest};
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
`;

export const WarningModalErrorOrangeHeader = styled.div`
  height: 72px;

  padding: 24px;

  color: ${(props) => props.theme.colors.greys.lightest};
  font: ${(props) => props.theme.fonts.h1Inter};

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${(props) => props.theme.colors.infoOrange};
`;

export const WarningModalBody = styled.div`
  padding: 24px;

  font: ${(props) => props.theme.fonts?.textBigHeight24};
`;
