import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  z-index: 10;
  top: 45px;

  width: 664px;
  height: fit-content;

  border-radius: 8px;

  ${(props) =>
    props.slide &&
    css`
      @media (min-width: 700px) and (max-width: 1150px) {
        right: -425px;
      }
    `}

  ${(props) =>
    props.positionLeft &&
    css`
      left: 0px;
    `}

  ${(props) => css`
    background-color: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  `}
`;

export const AdvancedFiltersButtonContainer = styled.div`
  padding: 30px 25px 50px 25px;
`;

export const Footer = styled.div`
  width: 100%;
  bottom: 0px;
  padding: 24px;

  border-radius: 0px 0px 8px 8px;

  background-color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;

    & > button {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  ${(props) => css`
    border-top: 1px solid ${(props) => props.theme.colors.greys.light};
  `}
`;
