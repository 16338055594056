import styled, { css } from 'styled-components';

export const MovableColumns = styled.div`
  height: 100%;
  display: flex;
  padding-left: 16px;
  min-height: 64px;

  justify-content: space-between;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.greys.light}`};

  &:last-child {
    border-bottom: none;
  }
`;

export const Icon = styled.img`
  width: 16px;
`;

export const DeleteIcon = styled.div`
  display: flex;
  width: 72px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.isLoading &&
    css`
      cursor: not-allowed;
    `}

  &:hover {
    > ${Icon} {
      -webkit-filter: url(#colorize);
      filter: url(#colorize);
    }
  }
`;
