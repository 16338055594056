import { useTranslation } from 'react-i18next';

import {
  CashierProductsContainer,
  CashierProductsNotFoundIcon,
  EmptyCashierOutlineIcon,
  IconTextContainer,
  TextSubtitle,
  TextTitle,
} from './styledComponents';
import MODES from './constants';
import React from 'react';

const EmptyCashierProducts = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <CashierProductsContainer>
      <IconTextContainer>
        {MODES.EMPTY === props.mode && (
          <>
            <EmptyCashierOutlineIcon
              alt="setup-complete"
              src="/images/inpulse/setup-complete.svg"
            />

            <TextTitle>{t('ADMIN.CASHIER_PRODUCTS.EMPTY_TITLE')}</TextTitle>

            <TextSubtitle>{t('ADMIN.CASHIER_PRODUCTS.EMPTY_SUBTITLE')}</TextSubtitle>
          </>
        )}

        {MODES.NOT_FOUND === props.mode && (
          <>
            <CashierProductsNotFoundIcon alt="sync-data" src="/images/inpulse/sync-data.svg" />

            <TextTitle>{t('ADMIN.CASHIER_PRODUCTS.CONNECT_CASHIER_TO_SYNC')}</TextTitle>
            <TextSubtitle>{t('ADMIN.CASHIER_PRODUCTS.NOT_FOUND_SUBTITLE')}</TextSubtitle>
          </>
        )}
      </IconTextContainer>
    </CashierProductsContainer>
  );
};

export default EmptyCashierProducts;
