import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 40px);
`;

export const HeaderContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 24px;
`;

export const NameWithThumbnail = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
`;

export const ThumbnailContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

export const Thumbnail = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 50%;
`;

export const NameContainer = styled.div``;

export const NameContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const TotalLossContainer = styled.div`
  color: ${(props) => props.theme.colors.greys.darker};
  font: normal 500 10px/14px inter;
`;

export const TotalLossValue = styled.div`
  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.kpiSmall};
`;

export const SkuContainer = styled.div`
  color: ${(props) => props.theme.colors.greys.darker};
  font: ${(props) => props.theme.fonts.textMicroHeight16};
`;
