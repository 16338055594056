import { getFeaturesByPath, ENUM_MODULE_NAME } from '../../../commons/utils/features';
import React, { useEffect } from 'react';

const MODULE_FEATURES_PATH_ORDERED = [
  '/backoffice/general/client',
  '/backoffice/general/features',
  '/backoffice/general/cashier-products',
  '/backoffice/general/general',
  '/backoffice/general/integrations',
  '/backoffice/general/sales',
  '/backoffice/general/analytics',
  '/backoffice/general/recipe-debugger',
];

export const BackOfficeGeneralContainer = (props) => {
  const { userRights, history } = props;

  useEffect(() => {
    const features = getFeaturesByPath(userRights, ENUM_MODULE_NAME.BACKOFFICE);
    if (features.length) {
      const redirectionPath = MODULE_FEATURES_PATH_ORDERED.find((featurePath) =>
        features.some((feature) => feature.path === featurePath),
      );
      history.push(redirectionPath);
    }
  }, []);

  return <div></div>;
};

// ===== Module features for easy import in ClientContainer =====

import BackOfficeAnalytics from './BackOfficeAnalytics';
import BackOfficeCashiers from './BackOfficeCashiers';
import BackOfficeCreateClient from './BackOfficeCreateClient';
import BackOfficeFeaturesAssociation from './BackOfficeFeaturesAssociation';
import BackOfficeGeneral from './BackOfficeGeneral';
import BackOfficeIntegrations from './BackOfficeIntegrations';
import BackOfficeRecipeDebugger from './BackOfficeRecipeDebugger';
import BackOfficeSales from './BackOfficeSales';

const BackOfficeGeneralModules = {
  BackOfficeSales,
  BackOfficeGeneral,
  BackOfficeCashiers,
  BackOfficeAnalytics,
  BackOfficeCreateClient,
  BackOfficeIntegrations,
  BackOfficeRecipeDebugger,
  BackOfficeGeneralContainer,
  BackOfficeFeaturesAssociation,
};

export default BackOfficeGeneralModules;
