import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { Dropdown } from '@commons/utils/styledLibraryComponents';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import { Container, DatePickerContainer, TextContainer } from './styledComponents';

const ModalContent = (props) => {
  const {
    stores,
    selectedModalStores,
    ingredients,
    selectedModalIngredients,
    modalPeriodPickerState,
    userTimezone,
    translatedStoreName,
    setSelectedModalStores,
    setSelectedModalIngredients,
    setIsExportModalButtonDisabled,
  } = props;

  const [selectedStores, setSelectedStores] = useState(selectedModalStores);
  const [selectedIngredients, setSelectedIngredients] = useState(selectedModalIngredients);

  const periodPickerState = usePeriodDatePickerState(
    modalPeriodPickerState.startDate,
    modalPeriodPickerState.endDate,
    userTimezone,
  );

  useEffect(() => {
    setSelectedModalStores(selectedStores);
    setSelectedModalIngredients(selectedIngredients);
    modalPeriodPickerState.handleSelectedDates({
      startDate: periodPickerState.startDate,
      endDate: periodPickerState.endDate,
    });

    if (
      !selectedStores.length ||
      !selectedIngredients.length ||
      !periodPickerState.startDate ||
      !periodPickerState.endDate
    ) {
      setIsExportModalButtonDisabled(true);
      return;
    }

    setIsExportModalButtonDisabled(false);
  }, [selectedStores, selectedIngredients, periodPickerState.startDate, periodPickerState.endDate]);

  return (
    <Container>
      <Dropdown
        customStyle={{ position: 'inherit', height: '68px' }} // for the list to appear over the modal (instead of inside)
        iconSrc={'/images/inpulse/store-black-small.svg'}
        isUniqueSelection={false}
        items={stores}
        label={`${translatedStoreName} :`}
        placeholder={translatedStoreName}
        selectedItems={selectedStores}
        isRequired
        onSelectionChange={setSelectedStores}
      />
      <DatePickerContainer>
        <TextContainer>
          <Text color={ENUM_COLORS.INFO_RED} font={ENUM_FONTS.TEXT_BIG_HEIGHT_20}>
            *
          </Text>
          <Text font={ENUM_FONTS.TEXT_BIG_HEIGHT_20}>
            {i18next.t('BACKOFFICE.PRODUCTS.RELAUNCH_MODAL_DATE_TEXT')} :
          </Text>
        </TextContainer>
        <PeriodDatePicker
          endDate={periodPickerState.endDate}
          focusedDateInput={periodPickerState.focusedDateInput}
          maxFutureDate={moment()}
          setFocusedDateInput={periodPickerState.setFocusedDateInput}
          startDate={periodPickerState.startDate}
          timezone={userTimezone}
          onDatesChange={periodPickerState.handleSelectedDates}
        />
      </DatePickerContainer>
      <Dropdown
        customDropdownListStyle={{ height: '240px' }}
        customStyle={{ position: 'inherit', height: '68px', marginTop: '108px' }} // for the list to appear over the modal (instead of inside)
        isUniqueSelection={false}
        items={ingredients}
        label={`${i18next.t('GENERAL.INGREDIENT_PLURAL')} :`}
        placeholder={i18next.t('GENERAL.INGREDIENT_PLURAL')}
        selectedItems={selectedIngredients}
        isRequired
        onSelectionChange={setSelectedIngredients}
      />
    </Container>
  );
};

export const getRestartCalculationsModal = ({
  stores,
  selectedModalStores,
  ingredients,
  selectedModalIngredients,
  modalPeriodPickerState,
  userTimezone,
  translatedStoreName,
  setSelectedModalStores,
  setSelectedModalIngredients,
  isExportModalButtonDisabled,
  setIsExportModalButtonDisabled,
  handleCloseCleanUp,
  handleCostReCalculation,
}) => ({
  type: 'action',
  width: '542px',
  height: 'auto',
  icon: '/images/inpulse/sync-black-small.svg',
  title: i18next.t('BACKOFFICE.PRODUCTS.RESTART_HISTORICIZED_CALCULATIONS'),
  component: ModalContent,
  data: {
    stores,
    selectedModalStores,
    ingredients,
    selectedModalIngredients,
    modalPeriodPickerState,
    userTimezone,
    translatedStoreName,
    setSelectedModalStores,
    setSelectedModalIngredients,
    setIsExportModalButtonDisabled,
  },
  handleCloseCleanUp,
  actions: [
    {
      key: 0,
      color: 'inpulse-outline',
      label: i18next.t('GENERAL.CANCEL'),
      icon: '/images/inpulse/close-black-small.svg',
      preventClosing: true,
    },
    {
      key: 1,
      color: 'inpulse-default',
      label: i18next.t('BACKOFFICE.ANALYTICS.RELAUNCH_MODAL_RELAUNCH_BUTTON'),
      icon: '/images/inpulse/save-white-small.svg',
      isDisabled: isExportModalButtonDisabled,
      handleClick: handleCostReCalculation,
      preventClosing: true,
    },
  ],
});
