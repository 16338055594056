import { getActionAuthorization } from '../commons/utils';

const SUPPLIER_PRODUCT_ACTIONS_RESOURCE = {
  VIEW: 'supplierProducts:view',

  CREATE: 'supplierProducts:create',

  EDIT: 'supplierProducts:edit',
  EDIT_FROM_DETAIL_VIEW: 'supplierProducts:detail:edit',
  MAPPING_AUTO: 'supplierProducts:mapping-auto',

  DOWNLOAD_TEMPLATE: 'supplierProducts:batch:template',
  IMPORT: 'supplierProducts:batch:import',
};

export const canViewSupplierProducts = (actions) =>
  getActionAuthorization(actions, SUPPLIER_PRODUCT_ACTIONS_RESOURCE.VIEW);

export const canCreateSupplierProducts = (actions) =>
  getActionAuthorization(actions, SUPPLIER_PRODUCT_ACTIONS_RESOURCE.CREATE);

export const canEditSupplierProducts = (actions) =>
  getActionAuthorization(actions, SUPPLIER_PRODUCT_ACTIONS_RESOURCE.EDIT);

export const canEditSupplierProductFromDetailView = (actions) =>
  getActionAuthorization(actions, SUPPLIER_PRODUCT_ACTIONS_RESOURCE.EDIT_FROM_DETAIL_VIEW);

export const canMapSupplierProductAutomatically = (actions) =>
  getActionAuthorization(actions, SUPPLIER_PRODUCT_ACTIONS_RESOURCE.MAPPING_AUTO);

export const canDownloadSupplierProductTemplate = (actions) =>
  getActionAuthorization(actions, SUPPLIER_PRODUCT_ACTIONS_RESOURCE.DOWNLOAD_TEMPLATE);

export const canImportSupplierProduct = (actions) =>
  getActionAuthorization(actions, SUPPLIER_PRODUCT_ACTIONS_RESOURCE.IMPORT);
