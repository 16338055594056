import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 16px;
  padding-top: 80px;
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled.div`
  display: flex;
  flex-direction: row;

  gap: 8px;
`;

export const ButtonContainer = styled.div`
  height: 66px;
  display: flex;
  align-items: center;
`;

export const TextWithToolTipContainer = styled.div`
  display: flex;
  flex-direction: row;

  gap: 8px;
`;

export const TopContainer = styled.div`
  width: 100%;
  height: 66px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  margin-top: 16px;
  gap: 16px;
`;

export const DragAndDropContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 8px;
  border: solid 1px ${(props) => props.theme.colors.greys.lighty};

  background-color: ${(props) => props.theme.colors.greys.lightest};
`;
