import _ from 'lodash';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { Button, Tooltip } from '@commons/utils/styledLibraryComponents';

import ListRow, { PACKAGING_MODAL_TYPE, UPDATE_TYPES } from '../ListRow';

import {
  Container,
  ColumnsTitleContainer,
  Column,
  ListContainer,
  RowsContainer,
  ArrowIconsContainer,
  IconContainer,
  Icon,
} from './styledComponents';

const MAX_CONDITIONINGS_TO_DISPLAY = 3;

const ConditioningList = ({
  setGenericModalParams,
  sortedPackagings,
  handleSupplierProductChange,
  setCurrentPackaging,
  setPackagingModalType,
  isDisabled,
}) => {
  const [stockDisabledPackagingIds, setStockDisabledPackagingIds] = useState([]);

  const updatedPackagingsByIds = _.keyBy(sortedPackagings, 'id');

  useEffect(() => {
    const usedInStockPackagingIds = getPackagingIdsBasedOnStock(true);
    if (usedInStockPackagingIds.length === 1) {
      setStockDisabledPackagingIds([_.first(usedInStockPackagingIds)]);

      return;
    }

    if (usedInStockPackagingIds.length >= MAX_CONDITIONINGS_TO_DISPLAY) {
      const stockDisabledPackagingIds = getPackagingIdsBasedOnStock(false);

      setStockDisabledPackagingIds(stockDisabledPackagingIds);

      return;
    }

    setStockDisabledPackagingIds([]);
  }, [sortedPackagings]);

  const getPackagingIdsBasedOnStock = (isUsedInStock) =>
    _.reduce(
      sortedPackagings,
      (result, packaging) => {
        if (packaging.isUsedInStock === isUsedInStock) {
          result.push(packaging.id);
        }
        return result;
      },
      [],
    );

  const updateUsedInOrderOrInStock = (selectedPackagingId, updateType) => {
    const packagings = sortedPackagings.map((packaging) => {
      if (packaging.id === selectedPackagingId) {
        return updateType === UPDATE_TYPES.USED_IN_ORDER
          ? { ...packaging, isUsedInOrder: true }
          : { ...packaging, isUsedInStock: !packaging.isUsedInStock };
      }
      return updateType === UPDATE_TYPES.USED_IN_ORDER
        ? { ...packaging, isUsedInOrder: false }
        : packaging;
    });

    handleSupplierProductChange(packagings, 'packagings');
  };

  return (
    <Container>
      <ColumnsTitleContainer>
        <Column isColumnTitle={true}>
          {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_CONDITIONING_NAME')}
        </Column>
        <Column isColumnTitle={true}>
          {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_CONDITIONING_QUANTITY')}
        </Column>
        <Column isColumnTitle={true}>
          {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_CONDITIONING_UNIT')}
        </Column>
        <Column isColumnTitle={true}>
          {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_CONDITIONING_ORDER')}
          <Tooltip text={i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_ORDER_TOOLTIP')} />
        </Column>
        <Column isColumnTitle={true}>
          {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_CONDITIONING_INVENTORIES')}
          <Tooltip
            text={i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_INVENTORIES_TOOLTIP', {
              packagingsCount: MAX_CONDITIONINGS_TO_DISPLAY,
            })}
          />
        </Column>
      </ColumnsTitleContainer>
      <ListContainer>
        <RowsContainer>
          {sortedPackagings.map((packaging, index) => {
            const packagingUnit =
              packaging.unit ||
              updatedPackagingsByIds[packaging.parentSupplierProductPackagingId].name;

            return (
              <ListRow
                handleSupplierProductChange={handleSupplierProductChange}
                index={index}
                isDisabled={isDisabled}
                isReadOnly={sortedPackagings.length === 1}
                isStockDisabled={stockDisabledPackagingIds.includes(packaging.id)}
                key={packaging.id}
                packaging={packaging}
                packagings={sortedPackagings}
                packagingUnit={packagingUnit}
                setCurrentPackaging={setCurrentPackaging}
                setGenericModalParams={setGenericModalParams}
                setPackagingModalType={setPackagingModalType}
                updatedPackagings={sortedPackagings}
                updatedPackagingsLength={sortedPackagings.length}
                updateUsedInOrderOrInStock={updateUsedInOrderOrInStock}
              />
            );
          })}
        </RowsContainer>
        <ArrowIconsContainer>
          {sortedPackagings.map((packaging, index) => {
            if (sortedPackagings.length === 1 || index === sortedPackagings.length - 1) {
              return;
            }
            return (
              <IconContainer key={packaging.id}>
                <Icon src="/images/inpulse/arrow-up-white-small.svg" />
              </IconContainer>
            );
          })}
        </ArrowIconsContainer>
      </ListContainer>
      <Button
        buttonCustomStyle={{ paddingLeft: '0px', marginTop: '12px' }}
        color={'inpulse-outline'}
        handleClick={() => {
          setPackagingModalType(PACKAGING_MODAL_TYPE.CREATE);
        }}
        icon={'/images/inpulse/add-black-small.svg'}
        isDisabled={isDisabled}
        label={i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_EMPTY_STATE_BUTTON')}
        noBorder={true}
      />
    </Container>
  );
};

ConditioningList.propTypes = {
  sortedPackagings: PropTypes.array.isRequired,
  setPackagingModalType: PropTypes.func.isRequired,
  handleSupplierProductChange: PropTypes.func.isRequired,
};

export default ConditioningList;
