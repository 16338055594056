import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import { INPULSE_ROLE_NAME } from '@commons/constants/inpulseRole';
import sortActionsList from '@commons/utils/sortActionsList';

export const getActions = ({ displayModalCreateRole }) => [
  {
    id: 'add',
    fixed: true,
    handleAction: () => displayModalCreateRole(),
    actionLabel: i18next.t('GENERAL.ADD'),
    render: () => (
      <Button
        color={'inpulse-default'}
        handleClick={displayModalCreateRole}
        icon={'/images/inpulse/add-white-small.svg'}
        label={i18next.t('GENERAL.ADD')}
      />
    ),
  },
];

const isInpulseRole = (role) => role.name.toLowerCase() === INPULSE_ROLE_NAME;

export const getRowActions = ({
  displayModalEditRole,
  displayModalEditRights,
  displayModalDeleteRole,
  displayModalFeaturesAssociation,
  displayModalFeaturesDissociation,
  displayModalEventSubCategoriesAssociation,
}) => {
  const rowActions = [
    {
      id: 'action-add-features',
      isDisabled: isInpulseRole,
      actionLabel: () => i18next.t('BACKOFFICE.ROLES.ADD_PAGE_ASSOCIATION'),
      handleAction: (role) => {
        displayModalFeaturesAssociation(role);
      },
      actionIcon: (role) =>
        isInpulseRole(role)
          ? '/images/inpulse/icon-plus-lmgrey.svg'
          : '/images/inpulse/icon-plus-ipblack.svg',
    },
    {
      id: 'action-remove-features',
      isDisabled: isInpulseRole,
      actionLabel: () => i18next.t('BACKOFFICE.ROLES.REMOVE_PAGE_ASSOCIATION'),
      handleAction: (role) => displayModalFeaturesDissociation(role),
      actionIcon: (role) =>
        isInpulseRole(role)
          ? '/images/inpulse/icon-minus-lmgrey.svg'
          : '/images/inpulse/icon-minus-ipblack.svg',
    },
    {
      id: 'action-edit-rights',
      isDisabled: (role) => !role.hasFeatureActionsLinked || isInpulseRole(role),
      actionLabel: () => i18next.t('BACKOFFICE.ROLES.EDIT_RIGHTS'),
      handleAction: (role) => displayModalEditRights(role),
      actionIcon: (role) =>
        !role.hasFeatureActionsLinked || isInpulseRole(role)
          ? '/images/inpulse/gear-lmgrey-small.svg'
          : '/images/inpulse/gear-ipblack-small.svg',
      disabledTooltipText: () => i18next.t('BACKOFFICE.TEAM.ROLES_HANDLE_RIGHTS_DISABLED_TOOLTIP'),
    },
    {
      id: 'assign-events',
      actionLabel: () => i18next.t('BACKOFFICE.ROLES.ASSOCIATE_EVENTS'),
      handleAction: (role) => displayModalEventSubCategoriesAssociation(role),
      actionIcon: () => '/images/inpulse/inpulse-link-black-small.svg',
    },
    {
      id: 'edit-role',
      isDisabled: (role) => role.name.toLowerCase() === 'inpulse',
      actionLabel: () => i18next.t('BACKOFFICE.ROLES.EDIT'),
      handleAction: (role) => displayModalEditRole(role),
      actionIcon: (role) =>
        role.name.toLowerCase() === 'inpulse'
          ? '/images/inpulse/edit-pen-lmgrey-small.svg'
          : '/images/inpulse/edit-pen-black-small.svg',
    },
    {
      id: 'delete-role',
      isDisabled: (item) => item.name.toLowerCase() === 'inpulse' || item.associatedAccountsCount,
      actionLabel: () => i18next.t('BACKOFFICE.ROLES.DELETE_ACTION'),
      handleAction: (item) => displayModalDeleteRole(item),
      actionIcon: (item) =>
        item.name.toLowerCase() === 'inpulse' || item.associatedAccountsCount
          ? '/images/inpulse/trash-lmgrey-small.svg'
          : '/images/inpulse/trash-black-small.svg',
    },
  ];

  return sortActionsList(rowActions);
};

export default {
  getActions,
  getRowActions,
};
