import _ from 'lodash';
import React from 'react';

import { Container, LoadingContent, LoadingRow } from './styledComponents';

const LOADING_ROW_ITEM = 20;

const LoadingState = ({ noMargin = false }) => (
  <Container noMargin={noMargin}>
    {_.times(LOADING_ROW_ITEM, (index) => (
      <LoadingRow key={`loading-item-${index}`}>
        <LoadingContent></LoadingContent>
      </LoadingRow>
    ))}
  </Container>
);

export default LoadingState;
