import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;

  flex: 1;
  padding: 16px 16px 0px 16px;

  background-color: ${(props) => props.theme.colors.greys.lightest};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.greys.light};
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
`;

export const ContentContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
`;

export const HeaderContainer = styled.div`
  display: flex;

  gap: 16px;
`;

export const BodyContainer = styled.div`
  flex: 1;
  padding-bottom: 8px;
  overflow: auto;

  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ scrollable, hasReachedTop, hasReachedBottom, theme }) => {
    if (!scrollable) {
      return;
    }

    switch (true) {
      // display both top box-shadow and bottom box-shadow
      case !hasReachedTop && !hasReachedBottom:
        return css`
          ::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 48px;
            z-index: 1;
            pointer-events: none;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
          }
          ::after {
            content: '';
            position: absolute;
            width: 100%;
            bottom: 0px;
            height: 48px;
            z-index: 1;
            pointer-events: none;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
          }
        `;
      // display top box-shadow
      case hasReachedBottom:
        return css`
          ::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 48px;
            z-index: 1;
            pointer-events: none;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
          }
        `;
      // by default, display bottom box-shadow (includes if hasReachedTop is true)
      default:
        return css`
          ::after {
            content: '';
            position: absolute;
            width: 100%;
            bottom: 0px;
            height: 48px;
            z-index: 1;
            pointer-events: none;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
          }
        `;
    }
  }}
`;
