import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useState } from 'react';

import { ListView } from '@commons/utils/styledLibraryComponents';
import DisplayNumber from '@commons/DisplayNumber';

import { Text } from '@admin/stores/components/styledComponents';

import { Container } from '../styledComponents';

const getColumns = (isCentralKitchenView) => {
  const columns = [
    {
      id: 'name',
      propertyKey: 'name',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_NAME'),
      render: (it) => <Text highlighted>{it}</Text>,
    },
    {
      id: 'supplierProfile',
      propertyKey: 'supplierProfile.name',
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_COLUMN_SUPPLIER_PROFILE'),
      displayBigger: true,
    },
    {
      id: 'price',
      propertyKey: 'price',
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_PRICE'),
      filterType: 'string',
      render: (item) => <DisplayNumber displayCurrencyCode={true} number={item} />,
    },
    {
      id: 'city',
      propertyKey: 'city',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_CITY'),
      filterType: 'string',
      render: (item) => <Text>{item}</Text>,
    },
    {
      id: 'country',
      propertyKey: 'country',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_COUNTRY'),
      filterType: 'string',
      render: (item) => <Text>{item}</Text>,
    },
  ];

  if (isCentralKitchenView) {
    const columnsToAdd = [
      {
        id: 'is-kitchen',
        propertyKey: 'isKitchen',
        name: i18next.t('GENERAL.STORE_TYPE'),
        render: (item) => (
          <Text>{item ? i18next.t('GENERAL.CENTRAL') : i18next.t('GENERAL.SALE_POINT')}</Text>
        ),
      },
      {
        id: 'supplier-name',
        propertyKey: 'supplierName',
        name: i18next.t('GENERAL.SUPPLIER'),
        render: (item) => <Text>{item}</Text>,
      },
    ];
    columns.splice(1, 0, ...columnsToAdd);
  }

  return columns;
};

const StoreMappingModal = (props) => {
  const { availableStores, setSelectedAvailableStores, price, isCentralKitchenView } = props;

  const [listViewColumns] = useState(getColumns(isCentralKitchenView));

  return (
    <Container>
      <ListView
        columns={listViewColumns}
        data={availableStores.map((sspm) => ({
          ...sspm,
          price,
        }))}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        disableFooter={true}
        forceEnableSelection={true}
        setSelectedItems={(items) => setSelectedAvailableStores(items)}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

export default connect(mapStateToProps)(StoreMappingModal);
