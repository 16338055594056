import { db } from '../db';

const findByStoreIdsStartDateEndDate = async (storeIds, startDate, endDate) =>
  db.dashboard
    .where('weekDate')
    .between(startDate, endDate, true, true)
    .and(({ storeId }) => storeIds.includes(storeId))
    .toArray();

/**
 * Create entry or update data props
 * Dexie bulkPut would not work since it conflicts with the unique compound indexes when adding items
 * Dexie bulkUpdate requires the primary key to be present in the data
 * Therefore it is required to loop through all entries and update / create them individually
 *
 * @param items
 */
const createOrUpdateDataProps = (items) =>
  db.transaction('rw', db.dashboard, () => {
    for (const { weekDate, storeId, createdAt, data } of items) {
      db.dashboard.add({ weekDate, storeId, createdAt, data }).catch(() => {
        db.dashboard.where({ weekDate, storeId }).modify((dashboardData) => {
          dashboardData.createdAt = createdAt;
          dashboardData.data = { ...dashboardData.data, ...data };
        });
      });
    }
  });

const createMany = (items) => db.dashboard.bulkAdd(items);

const deleteMany = (items) => db.dashboard.bulkDelete(items);

export default {
  findByStoreIdsStartDateEndDate,
  createOrUpdateDataProps,
  createMany,
  deleteMany,
};
