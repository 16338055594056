import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';

import { Dropdown } from '@commons/utils/styledLibraryComponents';
import { formatNumber } from '@commons/DisplayNumber';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { getClientInfo } from '@selectors/client';

import {
  Container,
  DropdownsContainer,
  LinkImage,
  CompositionContainer,
  ExternalRedirectionContainer,
  InformationContainer,
  LabelContainer,
  InformationComponentsContainer,
} from './styledComponents';

const InformationComponent = ({ label, value, link }) => (
  <InformationContainer>
    <LabelContainer>
      <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
        {label} :
      </Text>
      {!!link && (
        <LinkImage src="/images/inpulse/open-a-new-black-small.svg" onClick={link.onClick} />
      )}
    </LabelContainer>
    <Text color={ENUM_COLORS.BLACK} font={ENUM_FONTS.TEXT_MIDDLE_BOLD}>
      {value}
    </Text>
  </InformationContainer>
);

const computePriceWithTaxes = (price, vatRate) => price * (1 + vatRate / 100);

const KitchenProductDelivery = (props) => {
  const {
    currency: { numberDecimals },
    client: { storeName },
    suppliers,
    selectedSupplier,
    setSelectedSupplier,
    supplierProducts,
    supplierProduct,
    setSupplierProduct,
    product,
    setProduct,
    formError,
  } = props;

  const clientStoreNamePlural = getClientStoreNameTranslation(storeName, true);

  const [nbStoresDelivered, setNbStoresDelivered] = useState(0);
  const [deliveryPackaging, setDeliveryPackaging] = useState('');

  useEffect(() => {
    if (isEmpty(supplierProducts) || isEmpty(selectedSupplier)) {
      return;
    }

    const selectedSupplierProduct = supplierProducts.find(
      ({ supplierId }) => supplierId === selectedSupplier.id,
    );

    const selectedDeliveryPackaging = selectedSupplierProduct.packagings.find(
      ({ isUsedInOrder }) => isUsedInOrder,
    );

    setSupplierProduct(selectedSupplierProduct);
    setNbStoresDelivered(get(selectedSupplierProduct, 'storeMappings.length', 0));
    setDeliveryPackaging(selectedDeliveryPackaging.name);
    setProduct({
      ...product,
      priceHT: selectedSupplierProduct.price,
      priceWithTaxes: computePriceWithTaxes(
        selectedSupplierProduct.price,
        selectedSupplierProduct.vatRate,
      ),
      vatRate: selectedSupplierProduct.vatRate,
    });
  }, [selectedSupplier, supplierProducts]);

  const handleOpenSupplierProductDetails = () => {
    window.open(`/admin/suppliers/supplier-products/${supplierProduct.id}/details`, '_blank');
  };

  return (
    <Container err={formError}>
      <Text font={ENUM_FONTS.H2}>{i18next.t('ADMIN.PRODUCTS.DELIVERY')}</Text>
      <DropdownsContainer>
        <Dropdown
          customStyle={{ height: 'auto' }}
          isDisabled={suppliers.length <= 1}
          items={suppliers}
          label={`${i18next.t('GENERAL.SUPPLIER')} :`}
          placeholder={i18next.t('GENERAL.SEARCH')}
          selectedItem={selectedSupplier}
          tooltipText={i18next.t('ADMIN.CENTRAL_PRODUCTS.DELIVERY_SUPPLIER_TOOLTIP')}
          isRequired
          shouldInterpretTooltipHTML
          onSelectionChange={(item) => {
            setSelectedSupplier(item);
          }}
        />

        <CompositionContainer>
          <Dropdown
            customStyle={{ height: 'auto' }}
            isDisabled={true}
            isRequired={true}
            label={`${i18next.t('GENERAL.SUPPLIER_PRODUCT')} :`}
            placeholder={i18next.t('GENERAL.SEARCH')}
            selectedItem={supplierProduct}
            tooltipText={i18next.t('ADMIN.CENTRAL_PRODUCTS.DELIVERY_SUPPLIER_PRODUCT_TOOLTIP')}
          />
          {
            <ExternalRedirectionContainer onClick={handleOpenSupplierProductDetails}>
              <LinkImage src="/images/inpulse/open-a-new-black-small.svg" />
              <Text font={ENUM_FONTS.TEXT_MIDDLE_BOLD}>
                {i18next.t('ADMIN.PRODUCTS.SEE_SUPPLIER_PRODUCT')}
              </Text>
            </ExternalRedirectionContainer>
          }
        </CompositionContainer>
      </DropdownsContainer>
      <InformationComponentsContainer>
        <InformationComponent
          label={i18next.t('ADMIN.PRODUCTS.DELIVERY_PACKAGING')}
          value={deliveryPackaging}
        />
        <InformationComponent
          label={i18next.t('GENERAL.PRICE_EX_TAX')}
          value={formatNumber(supplierProduct.price || null, numberDecimals)}
        />
        <InformationComponent
          label={i18next.t('ADMIN.CENTRAL_PRODUCTS.DELIVERED_STORE', {
            storeName: clientStoreNamePlural,
          })}
          value={nbStoresDelivered}
        />
      </InformationComponentsContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(KitchenProductDelivery);
