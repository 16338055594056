import styled from 'styled-components';

export const HeaderContainer = styled.div`
  height: 88px;
  width: 100%;

  overflow-x: scroll;
  padding: 24px 24px 10px 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${(props) => props.theme.colors.greys.lightest};
  box-shadow: inset 0px 2px 0px ${(props) => props.theme.colors.greys.lighter};
`;
