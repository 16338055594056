import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  padding: 24px 24px 0px 24px;

  border-radius: 8px 8px 0px 0px;

  & .location-filter-input-text {
    max-width: 200px !important;
  }
`;

export const Header = styled.div`
  width: 100%;

  padding: 12px 0px 0px 16px;
  font: ${(props) => props.theme.fonts?.h2};
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  margin-top: 28px;
  padding: 0px 16px;

  & > div {
    flex: 1 1 240px;

    margin-bottom: 40px;

    &:nth-child(odd) {
      margin-right: 24px;
    }

    max-width: 240px;
  }

  & .location-filter-list-results-container,
  & .location-filter-list-results-container-scroll {
    max-height: 170px;
  }

  & .location-filter-list-result {
    width: 290px;
  }

  & .location-filter-result-text {
    width: calc(100% - 45px);
  }

  margin-top: 28px;
  padding: 0px 16px;

  & > div {
    margin-bottom: 20px;

    &:not(:first-child, :nth-child(2)) {
      margin-top: 20px;
    }
  }
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  :first-child {
    margin-right: 8px;
  }
`;
