import { get } from 'lodash';
import React from 'react';

import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { LOSS_TYPE } from '../components/LossListView/constants';
import { PageContentContainer } from '../styledComponents';
import LossListView from '../components/LossListView';

export const LossesSupplierProducts = (props) => {
  const path = get(props.match, 'path');

  return (
    <div>
      <NavigationBreadCrumb featurePath={path} />
      <PageContentContainer>
        <LossListView lossType={LOSS_TYPE.SUPPLIER_PRODUCT} {...props} />
      </PageContentContainer>
    </div>
  );
};

export default LossesSupplierProducts;
