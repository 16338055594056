import axios from '../../core/http';

import { config } from '../../config';
const baseApiUrl = config.baseApiUrl;

const healthCheckCashierProduct = (clientId) =>
  axios
    .get(`${baseApiUrl}/backoffice/general/health-check/cashier-product?clientId=${clientId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const healthCheckProduct = (clientId) =>
  axios
    .get(`${baseApiUrl}/backoffice/general/health-check/product?clientId=${clientId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const healthCheckProductPrice = (clientId) =>
  axios
    .get(`${baseApiUrl}/backoffice/general/health-check/product/price?clientId=${clientId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const healthCheckProductVatRate = (clientId) =>
  axios
    .get(`${baseApiUrl}/backoffice/general/health-check/product/vat-rate?clientId=${clientId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const healthCheckWeatherStation = (clientId, distance) =>
  axios
    .get(
      `${baseApiUrl}/backoffice/general/health-check/weather-station?clientId=${clientId}&distance=${distance}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const healthCheckSupplierProduct = (clientId) =>
  axios
    .get(`${baseApiUrl}/backoffice/general/health-check/supplier-product?clientId=${clientId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const healthCheckSupplierProductPrice = (clientId) =>
  axios
    .get(
      `${baseApiUrl}/backoffice/general/health-check/supplier-product/price?clientId=${clientId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const healthCheckSupplierProductUnit = (clientId) =>
  axios
    .get(
      `${baseApiUrl}/backoffice/general/health-check/supplier-product/unit?clientId=${clientId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const healthCheckIngredient = (clientId) =>
  axios
    .get(`${baseApiUrl}/backoffice/general/health-check/ingredient?clientId=${clientId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const healthCheckIngredientPrice = (clientId) =>
  axios
    .get(`${baseApiUrl}/backoffice/general/health-check/ingredient/price?clientId=${clientId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export const backoffice = {
  healthCheckProduct,
  healthCheckCashierProduct,
  healthCheckProductPrice,
  healthCheckProductVatRate,
  healthCheckWeatherStation,
  healthCheckSupplierProduct,
  healthCheckSupplierProductPrice,
  healthCheckSupplierProductUnit,
  healthCheckIngredient,
  healthCheckIngredientPrice,
};
