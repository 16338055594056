import styled, { css } from 'styled-components';

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 165px);

  overflow: hidden;
  padding: 0px 35px;
  position: relative;
`;

export const ContentList = styled.div`
  width: 100%;
  overflow: scroll;
  position: relative;
  height: calc(100% - 150px);
`;

export const ContentHeader = styled.div``;

export const Line = styled.div`
  width: 100%;
  padding: 15px 20px;
  background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
  border-bottom: ${(props) => (props.theme.borders ? undefined : '1px solid #d8d8d8')};
  ${(props) =>
    props.theme.borders
      ? `
    border: solid 1px ${props.theme.colors.greys.light};
    border-radius: ${props.theme.borders?.radius?.weak};
    margin-bottom: 8px;
    `
      : undefined}

  display: flex;
  align-items: center;

  &.header {
    background: inherit;

    ${(props) =>
      props.theme.borders
        ? `
    border: none;
    margin-bottom: 8px;
    `
        : undefined}

    > div {
      color: ${(props) => props.theme.colors?.greys.dark || '#6b768c'};
    }
  }

  .inpulse-checkbox {
    margin-right: 1%;
  }

  .deepsight-checkbox-square {
    margin-right: 30px;
  }
`;

export const Row = styled.div`
  flex: ${(props) => (props.width === 'small' ? 0.5 : 1)};

  color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};

  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts?.textBig};`
      : `font-weight: normal;
  font-size: 14px;
  line-height: 16px;`}

  ${(props) =>
    props.isEllipsis &&
    css`
      flex: 1.5;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  > p {
    margin: 0;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchBarContainer = styled.div`
  margin-top: 10px;
`;

export const CheckDropDownContainer = styled.div`
  display: flex;

  margin-left: 15px;
  margin-top: 10px;
`;

export const SearchContainer = styled.div`
  flex: 1;
  height: 36px;
  max-width: 440px;
  margin: 25px 0px 15px 0px;

  display: flex;
  flex-shrink: 1;
  align-items: center;

  border-radius: ${(props) => props.theme.borders?.radius.weak || '2px'};
  background: ${(props) => props.theme.colors?.greys.lightest || 'rgba(194, 201, 213, 0.4)'};
  > img {
    width: 18px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }

  > input {
    width: calc(100% - 38px);
    background: none;
    border: none;
    height: 100%;
    margin-top: 3px;

    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.textBig};`
        : `line-height: 36px;
    font-size: 14px;
    font-family: proximanovasemibold;`}
  }

  > input::placeholder {
    color: ${(props) => props.theme.colors?.greys.dark || '#a8b1c4'};

    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.textBig};`
        : `font-size: 16px;
    line-height: 18px;
    font-weight: normal;
    letter-spacing: 0.33px;
    font-family: proximanovaregular;`}
  }
`;

export const ListRowText = styled.div`
  color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};

  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts?.textSmall};`
      : `font-size: 14px;
  line-height: 16px;
  font-weight: normal;`}

  &.link {
    color: ${(props) => props.theme.colors?.greys.darkest || '#2176fa'};
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.sku-label {
    display: block;
    overflow: hidden;

    max-width: 80px;
    width: fit-content;

    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const TopContainer = styled.div`
  width: 100%;
  height: 90px;
  padding: 0px 35px;
  border-radius: ${(props) => props.theme.borders?.radius.weak || undefined};
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};

  color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};

  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts?.textBig};`
      : `font-size: 18px;
  line-height: 22px;
  font-weight: normal;`}
`;

export const BottomContainer = styled.div`
  width: 100%;
  height: 75px;
  padding: 0px 35px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-radius: ${(props) => props.theme.borders?.radius.weak || undefined};
  bottom: 0px;
  position: absolute;

  background: ${(props) => props.theme.colors.greys.lighter};
  border-top: 1px solid rgba(220, 219, 221, 0.367644);
`;

export const DateContainer = styled.p`
  font-weight: ${(props) => (props.bold ? '700' : '400')};

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const TagLabel = styled.div`
  background: ${(props) => props.theme.colors?.greys.lighter || '#e8e8e8'};

  min-width: 20px;

  width: fit-content;
  max-width: 80%;
  height: 20px;
  padding: 0px 6px;

  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts?.textSmall};`
      : `line-height: 20px;
  font-size: 14px;`}

  border-radius: 3px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  display: flex;
  justify-content: start;

  &.box {
    min-width: fit-content;
    margin: auto 10px;
  }

  &:hover ~ div {
    display: flex;
    align-items: center;
  }
`;

export const TagContainer = styled.div`
  position: relative;

  display: flex;
  justify-content: start;
  align-items: center;

  height: 100%;
  width: 100%;

  cursor: default;
`;
export const TagInfo = styled.div`
  min-width: 20px;
  margin-left: 5px;

  &:hover ~ div {
    display: flex;
    align-items: center;
  }
`;

export const TagHoverBox = styled.div`
  width: auto;
  height: 40px;

  display: none;

  position: absolute;
  z-index: 1;
  top: 25px;

  background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
  border-radius: ${(props) => props.theme.borders?.radius.weak || '3px'};

  box-shadow: ${(props) => (props.theme.borders ? undefined : '0px 2px 6px rgba(0, 0, 0, 0.1)')};
`;

export const PointerDiv = styled.div`
  width: 8px;
  height: 8px;

  background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};

  position: absolute;
  z-index: 1;
  top: -4px;
  /* left: 50%; */
  left: 20px;
  transform: translateX(-50%) rotate(-135deg);
`;

export const NameContainer = styled.div`
  width: 100%;
  padding: 0px 5px;
  height: inherit;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > p {
    margin: 0;

    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;

    color: ${(props) => props.theme.colors?.greys.darkest || '#2c7dfe'};
  }
`;

export const InfoDiv = styled.div`
  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts?.textSmall};`
      : `font-size: 12px;
  line-height: 13px;`}
  color: ${(props) => props.theme.colors?.greys.darker || '#6b768c'};

  min-width: 150px;
`;

export const SyncBtn = styled.div`
  background: ${(props) => props.theme.colors?.greys.darkest || '#0a3eff'};

  box-shadow: ${(props) =>
    props.theme.borders ? undefined : '0px 8px 12px rgba(10, 62, 255, 0.2)'};
  border-radius: ${(props) => props.theme.borders?.radius.weak || '3px'};

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px 0px 12px;
  color: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};

  width: 35px;
  height: 35px;
`;

export const Icon = styled.img`
  width: 15px;
  height: 20px;
`;

export const ContainerLastSync = styled.div`
  height: 36px;
  padding-top: 3px;
  margin: 25px 0px 15px 0px;

  display: flex;

  align-items: center;
`;

export const Text = styled.div`
  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts?.textSmall};`
      : `font-size: 12px;
  line-height: 13px;`}

  color: ${(props) =>
    props.theme.colors
      ? props.grey
        ? props.theme.colors.greys.darker
        : props.theme.colors.greys.darkest
      : props.grey
      ? '#6b768c'
      : '#000000'};
`;
