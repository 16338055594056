import _ from 'lodash';
import i18next from 'i18next';

import { ConfirmationModal } from '@commons/Modals/ConfirmationModal';

export const getDeleteProductsModalParams = (
  products,
  handleProductsDeletion,
  isInCentralKitchenOnlyPage,
) => ({
  type: 'warning',
  width: '542px',
  height: 'auto',
  icon: '/images/inpulse/delete-ip-white-small.svg',
  title:
    products.length === 1
      ? i18next.t('ADMIN.PRODUCTS.DELETE_PRODUCT')
      : i18next.t('ADMIN.PRODUCTS.DELETE_PRODUCTS'),
  component: ConfirmationModal,
  data: {
    shouldInterpretHtml: true,
    content: i18next.t(
      isInCentralKitchenOnlyPage
        ? 'ADMIN.CENTRAL_PRODUCTS.DELETE_PRODUCT_MODAL_CONTENT'
        : 'ADMIN.PRODUCTS.DELETE_PRODUCT_MODAL_CONTENT',
      {
        count: products.length,
        name: _.get(products[0], 'name', ''),
      },
    ),
  },
  actions: [
    {
      key: 0,
      color: 'inpulse-outline',
      label: i18next.t('GENERAL.CANCEL'),
      icon: '/images/inpulse/close-black-small.svg',
    },
    {
      key: 1,
      preventClosing: true,
      color: 'inpulse-default',
      label: i18next.t('GENERAL.DELETE'),
      icon: '/images/inpulse/delete-ip-white-small.svg',
      handleClick: () => {
        handleProductsDeletion(products);
      },
    },
  ],
});

export const getDisableProductsModalParams = (
  products,
  disableProducts,
  isInCentralKitchenOnlyPage = false,
) => ({
  type: 'warning',
  width: '542px',
  height: 'auto',
  icon: '/images/inpulse/power-white-small.svg',
  title: i18next.t(
    isInCentralKitchenOnlyPage
      ? 'ADMIN.PRODUCTS.DISABLE_KITCHEN_PRODUCTS_MODAL_TITLE'
      : 'ADMIN.PRODUCTS.DISABLE_PRODUCTS_MODAL_TITLE',
    {
      count: products.length,
    },
  ),
  component: ConfirmationModal,
  data: {
    content: i18next.t(
      isInCentralKitchenOnlyPage
        ? 'ADMIN.PRODUCTS.DISABLE_KITCHEN_PRODUCTS_MODAL_CONTENT'
        : 'ADMIN.PRODUCTS.DISABLE_PRODUCTS_MODAL_CONTENT',
      {
        count: products.length,
        name: _.get(products[0], 'name', ''),
      },
    ),
  },
  actions: [
    {
      key: 0,
      color: 'inpulse-outline',
      label: i18next.t('GENERAL.CANCEL'),
      icon: '/images/inpulse/close-black-small.svg',
    },
    {
      key: 1,
      color: 'inpulse-default',
      label: i18next.t('GENERAL.DISABLE'),
      icon: '/images/inpulse/power-white-small.svg',
      handleClick: async () => {
        await disableProducts(products);
      },
    },
  ],
});

export const getEnableProductsModalParams = (products, enableProducts) => ({
  type: 'warning',
  width: '542px',
  height: 'auto',
  icon: '/images/inpulse/power-white-small.svg',
  title: i18next.t('ADMIN.PRODUCTS.ENABLE_KITCHEN_PRODUCTS_MODAL_TITLE', {
    count: products.length,
  }),
  component: ConfirmationModal,
  data: {
    content: i18next.t('ADMIN.PRODUCTS.ENABLE_KITCHEN_PRODUCTS_MODAL_CONTENT', {
      count: products.length,
      name: _.get(products[0], 'name', ''),
    }),
  },
  actions: [
    {
      key: 0,
      color: 'inpulse-outline',
      label: i18next.t('GENERAL.CANCEL'),
      icon: '/images/inpulse/close-black-small.svg',
    },
    {
      key: 1,
      color: 'inpulse-default',
      label: i18next.t('GENERAL.ACTIVATE'),
      icon: '/images/inpulse/power-white-small.svg',
      handleClick: async () => {
        const productIds = products.map(({ id }) => id);
        await enableProducts(productIds);
      },
    },
  ],
});
