import { DATE_DISPLAY_FORMATS } from '../commons/DatePickers/constants';

import { APIcaller } from './APIcaller';

const getForecastsProductMix = (storeId, date) =>
  APIcaller.apiGet(`/admin/product-mix/${storeId}/forecasts`, {
    date: date.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  });

export const productMix = {
  getForecastsProductMix,
};
