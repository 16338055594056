import React from 'react';

import { Container, Title, Subtitle, Action, Icon } from './styledComponents';

export const GeneralEmptyStateListView = (props) => {
  const { title, subtitle, renderAction, icon, iconStyle } = props;

  return (
    <Container>
      <Icon src={icon} style={iconStyle} />
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {renderAction && <Action>{renderAction()}</Action>}
    </Container>
  );
};

export default GeneralEmptyStateListView;
