import styled from 'styled-components';

const Container = styled.div`
  padding: 0 16px;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background: ${(props) => props.theme.colors.greys.darkest};

  ::before {
    content: '';
    position: absolute;
    left: 8px;
    right: 8px;
    bottom: 64px;
    border-bottom: solid 1px ${(props) => props.theme.colors.greys.darker};
  }
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;

  transition: filter 0.3s; /* Add a smooth transition effect */

  &:hover {
    -webkit-filter: url(#colorize);
    filter: url(#colorize);
  }
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;

  > a:hover {
    text-decoration: none;
  }
`;

export { Container, UserContainer, NameContainer, Icon };
