import moment from 'moment-timezone';
import React from 'react';
import styled from 'styled-components';

import InpulseArrow from '@assets/icons/arrow_carousel.svg';

const CalendarDayDisplay = ({ date, isSelected, selectDay, storeTimezone, ...props }) => (
  <div className={props.className}>
    <div
      className={`forecast-calendar-date ${isSelected ? 'selected' : ''} ${
        date.isBefore(moment.tz(storeTimezone).startOf('day')) ? 'past' : 'future'
      }`}
      onClick={() => selectDay(date)}
    >
      <div className="forecast-calendar-date-title">{date.format('ddd')}</div>
      <div className="forecast-calendar-date-text">{date.format('D MMMM')}</div>
    </div>
  </div>
);

const CustomArrow = ({ arrowClick, direction, date, storeTimezone, ...props }) => (
  <div className={props.className} onClick={() => arrowClick(direction)}>
    <div
      className={`forecast-calendar-arrow ${
        date && date.isBefore(moment.tz(storeTimezone).startOf('day')) ? 'past' : 'future'
      }`}
    >
      {direction === 'left' ? (
        <img alt="arrow" className="forecast-calendar-header-arrow" src={InpulseArrow} />
      ) : (
        <img alt="arrow" className="forecast-calendar-header-arrow rotated" src={InpulseArrow} />
      )}
    </div>
  </div>
);

const InpulseCustomArrow = styled(CustomArrow)`
  width: 50px;
  height: 100%;
  line-height: 82px;
  text-align: center;

  .forecast-calendar-arrow {
    width: calc(30px);
    height: 55px;
    margin-left: 10px;
    text-align: center;
    border-radius: ${(props) => props.theme.borders?.radius?.weak};
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    &.past {
      background: ${(props) => `repeating-linear-gradient(
        135deg,
        ${props.theme.colors.greys.lightest},
        ${props.theme.colors.greys.lightest} 2px,
        ${props.theme.colors.greys.lighter} 2px,
        ${props.theme.colors.greys.lighter} 4px
      )`};
    }

    &.future {
      background: ${(props) => props.theme.colors.greys.lightest};
    }
  }

  .forecast-calendar-arrow-empty {
    width: 50px;
    display: inline-block;
  }

  .forecast-calendar-arrow:hover {
    cursor: pointer;
    filter: brightness(80%);
  }

  .forecast-calendar-header-arrow {
    height: 3em;
    width: 2em;
    position: relative;
  }

  .forecast-calendar-header-arrow.rotated {
    transform: rotate(180deg);
  }
`;

const DeepsightCustomArrow = styled(CustomArrow)`
  width: 50px;
  height: 100%;
  line-height: 82px;
  text-align: center;

  .forecast-calendar-arrow {
    width: calc(30px);
    height: 55px;
    line-height: 55px;
    margin-left: 10px;
    text-align: center;
    border-radius: 4px;

    &.past {
      background: #374887;
    }

    &.future {
      background: #0ab0fc;
    }
  }

  .forecast-calendar-arrow-empty {
    width: 50px;
    display: inline-block;
  }

  .forecast-calendar-arrow:hover {
    cursor: pointer;
    filter: brightness(80%);
  }

  .forecast-calendar-header-arrow {
    width: 13px;
    height: 24px;
    position: relative;
  }

  .forecast-calendar-header-arrow.rotated {
    transform: rotate(180deg);
  }
`;

const InpulseCalendarDayDisplay = styled(CalendarDayDisplay)`
  width: calc(((100% - 100px) / 7) - 0.5%);
  height: 55px;
  margin: 0 0.5%;
  position: relative;

  .forecast-calendar-date-encloser:hover > .forecast-calendar-date {
    background: ${(props) => props.theme.colors.greys.lighter};
  }

  .forecast-calendar-date {
    height: 55px;
    text-align: center;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    color: ${(props) => props.theme.colors.greys.darkest};
    border-radius: ${(props) => props.theme.borders?.radius?.weak};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    position: absolute;
    width: 100%;
    cursor: pointer;

    &.past {
      background: ${(props) => `repeating-linear-gradient(
        135deg,
        ${props.theme.colors.greys.lightest},
        ${props.theme.colors.greys.lightest} 2px,
        ${props.theme.colors.greys.lighter} 2px,
        ${props.theme.colors.greys.lighter} 4px
      )`};
    }

    &.future {
      background: ${(props) => props.theme.colors.greys.lightest};
    }

    &.selected {
      background: ${(props) => props.theme.colors.greys.darkest};
      color: ${(props) => props.theme.colors.greys.lightest};
    }

    &:hover {
      background: ${(props) => props.theme.colors.greys.lighter};
    }
  }

  .forecast-calendar-date-title {
    font: ${(props) => props.theme.fonts?.h3};
    text-transform: capitalize;
  }

  .forecast-calendar-date-text {
    font: ${(props) => props.theme.fonts?.textBig};
  }

  .forecast-calendar-date.selected {
    background-color: ${(props) => props.theme.colors.greys.darkest};
    color: ${(props) => props.theme.colors.greys.lightest};
  }
`;

const DeepsightCalendarDayDisplay = styled(CalendarDayDisplay)`
  width: calc(((100% - 100px) / 7) - 0.5%);
  height: 55px;
  margin: 0 0.5%;
  position: relative;

  .forecast-calendar-date-encloser:hover > .forecast-calendar-date {
    top: -10px;
    color: white;
  }

  .forecast-calendar-date {
    height: 55px;
    text-align: center;
    color: white;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    position: absolute;
    width: 100%;
    cursor: pointer;

    &.past {
      background: #374887;
    }

    &.future {
      background: #0ab0fc;
    }

    &.selected {
      background-color: rgb(10, 176, 252) none repeat scroll 0% 0%;
      top: -10px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
    }
  }

  .forecast-calendar-date:hover {
    top: -10px;
  }

  .forecast-calendar-date-title {
    text-transform: capitalize;
    font-family: 'proximanovabold';
    font-size: 16px;
  }

  .forecast-calendar-date-text {
    line-height: 16px;
    font-family: 'proximanovaregular';
    font-size: 12px;
  }

  .forecast-calendar-date.selected {
    background-color: rgb(10, 176, 252) none repeat scroll 0% 0%;
    color: white;
    font-family: 'proximanovasemibold';
    margin-bottom: 10px;
  }
`;

export {
  InpulseCalendarDayDisplay,
  InpulseCustomArrow,
  DeepsightCalendarDayDisplay,
  DeepsightCustomArrow,
};
