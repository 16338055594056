import styled, { css } from 'styled-components';

import IconArrowDownBlue from '../../../images/expandableEmbeddedList/icon-arrow-down-blue.svg';
import IconArrowDownDark from '../../../images/expandableEmbeddedList/icon-arrow-down-dark.svg';
import IconArrowDownWhite from '../../../images/expandableEmbeddedList/icon-arrow-down-white.svg';

const icons = {
  dark: IconArrowDownDark,
  blue: IconArrowDownBlue,
  white: IconArrowDownWhite,
};

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 3px;

  width: ${(props) => props.theme.toggle?.size?.width};
  height: ${(props) => props.theme.toggle?.size?.height};

  margin: 0 0 0 15px;

  ${(props) => {
    if (!props.visibility) {
      return css`
        background-color: 'transparent';
      `;
    }

    if (props.open) {
      return css`
        background-color: ${props.theme.toggle?.selected?.backgroundColor};
      `;
    }

    return css`
      background-color: ${props.theme.toggle?.default?.backgroundColor};
    `;
  }};
`;

export const DropdownPicture = styled.img.attrs((props) => ({
  src: props.open
    ? icons[props.theme.toggle.picture.opened]
    : icons[props.theme.toggle.picture.closed],
}))`
  width: ${(props) => props.theme.toggle?.picture?.size?.width};
  height: ${(props) => props.theme.toggle?.picture?.size?.height};

  ${(props) =>
    props.open &&
    css`
      transform: rotate(180deg);
    `};

  visibility: ${(props) => (!props.visibility ? 'hidden' : 'visible')};
`;

export default {
  Button,
  DropdownPicture,
};
