import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

const renderPreviousButton = (isFirst, previousStepHandler) => (
  <Button
    color={'inpulse-outline'}
    handleClick={previousStepHandler}
    icon={
      isFirst ? '/images/inpulse/close-black-small.svg' : '/images/inpulse/arrow-left-ip-black.svg'
    }
    label={isFirst ? i18next.t('GENERAL.CANCEL') : i18next.t('GENERAL.BACK')}
  />
);

const renderNextButton = (isLast, isSaveDisabled, nextStepHandler) => (
  <Button
    color={'inpulse-default'}
    handleClick={nextStepHandler}
    icon={isLast ? '/images/inpulse/save-white-small.svg' : '/images/inpulse/arrow-right-white.svg'}
    isDisabled={isSaveDisabled}
    label={isLast ? i18next.t('GENERAL.SAVE') : i18next.t('GENERAL.NEXT')}
  />
);

export default { renderPreviousButton, renderNextButton };
