import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Content = styled.div`
  width: 100%;
  padding: 0px;
  overflow: scroll;
  position: relative;
  height: ${(props) => (props.noHeader ? 'calc(100% - 75px)' : 'calc(100% - 135px)')};
`;

export const TopContainer = styled.div`
  width: 100%;
  height: 75px;
  padding: 0px 35px;
  border-radius: ${(props) =>
    `${props.theme.borders?.radius.strong} ${props.theme.borders?.radius.strong} 0px 0px  !important` ||
    undefined};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};

  color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};

  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts.kpiSmall};`
      : `font-size: 18px;
  line-height: 22px;
  font-weight: normal;`}
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderRightPartContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  align-items: center;

  font: ${(props) => props.theme.fonts?.h2};
`;

export const CloseModalIcon = styled.img`
  height: 16px;
  width: 16px;

  cursor: pointer;
`;

export const BottomContainer = styled.div`
  width: 100%;
  height: 75px;
  padding: 0px 35px;

  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.theme.colors ? 'flex-end' : undefined)};
  background: ${(props) => props.theme.colors?.greys.lightest || undefined};
  border-radius: ${(props) =>
    `0px 0px ${props.theme.borders?.radius.strong} ${props.theme.borders?.radius.strong} !important` ||
    undefined};
  bottom: 0px;
  position: absolute;
  border-top: ${(props) =>
    props.theme.borders ? undefined : '1px solid rgba(220, 219, 221, 0.367644)'};

  ${(props) => (props.theme.borders ? 'box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.15);' : undefined)};
`;

export const GenericModalContainer = styled.div`
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0px;

  z-index: 20;
`;
