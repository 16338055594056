import i18next from 'i18next';

import { Invoice } from '../salesPointNavigation/subNavigation';

const INVOICE_CONTROLS = {
  getText: () => i18next.t('FEATURE.INVOICE.INVOICES_CONTROLS'),
  path: '/invoice/invoice-controls',
  customRender: ({ invoice }) => ({
    linkedText: invoice ? i18next.t('FEATURE.INVOICE.INVOICE_CONTROL_DETAILS') : '',
    extraText: getExtraTextForInvoiceSubFeature(invoice),
  }),
  subFeatures: [Invoice.DETAILS, Invoice.BATCH_IMPORT],
};

const getExtraTextForInvoiceSubFeature = (invoice) => {
  if (invoice && invoice.supplier) {
    return `${invoice.supplier.name} n°${invoice.number || '-'}`;
  }

  return i18next.t('FEATURE.INVOICE.INVOICE_CONTROL_BATCH_IMPORT');
};

const INVOICE_SUBMODULE = {
  getText: () => i18next.t('FEATURE.INVOICE.INVOICES'),
  icon: '/images/invoice.svg',
  children: [INVOICE_CONTROLS],
};

export const INVOICE = {
  getText: () => i18next.t('FEATURE.INVOICE.INVOICES'),
  path: '/invoice',
  icon: '/images/invoice.svg',
  children: [INVOICE_SUBMODULE],
};
