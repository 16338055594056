import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect, useContext } from 'react';

import {
  fetchEventsByStoreIdAndDates,
  receiveEvents,
  fetchEventError,
  fetchLyEventsOfStore,
  receiveLyEvents,
  fetchLyEventError,
} from '@actions/event';
import {
  fetchForecastsOfStore,
  receiveForecasts,
  requestForecastsError,
  clearForecasts,
} from '@actions/forecast';
import {
  fetchSalesOfStore,
  receiveSales,
  receiveLastYearSales,
  requestSalesError,
} from '@actions/sale';
import {
  fetchWeather,
  receiveWeather,
  fetchWeatherError,
  fetchLyWeather,
  receiveLyWeather,
  fetchLyWeatherError,
} from '@actions/weather';
import {
  getEventSubCategories,
  recieveEventSubCategories,
  requestEventSubCategoriesError,
} from '@actions/eventSubCategory';
import {
  getMetricsByDates,
  receiveMetricsByDates,
  requestMetricsByDatesError,
} from '@actions/productionMetrics';
import {
  getWeatherForecastStation,
  recieveWeatherForecastStation,
  requestWeatherForecastStationError,
} from '@actions/weatherStation';
import { showErrorMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { isUserAllowedToAccessProduction } from '@commons/utils/features';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { ForecastsContext } from '@context/ForecastsContext';

import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import { DashboardMainContainer, InpulseForecastPrecisionChartContainer } from './styledComponents';
import ForecastPrecisionChart from './ForecastPrecisionChart/ForecastPrecisionChart';
import ForecastsMainContainer from './ForecastsMainContainer/ForecastsMainContainer';
import ForecastsPerformancesContainer from './ForecastsPerformancesContainer/ForecastsPerformancesContainer';

const DAYS_TO_FETCH_PAST = 40;

export const DashboardForecastsContainer = (props) => {
  const {
    stores,
    hasUserAccessToProductionFeature,
    getMetricsByDates,
    events,
    getEventSubCategories,
    prevHorizon,
    getPastEventsOfStore,
    user,
    getEventsOfStore,
    weatherStation,
    getPastWeatherForecast,
    getWeatherForecast,
    getWeatherForecastStation,
    getSales,
    getPastSales,
    getForecasts,
    forecasts,
    lastYearSales,
    sales,
    clearForecasts,
    lyEvents,
    eventSubCategories,
    hasOrder,
    hasPlanning,
    lyWeather,
    productionMetricsByDates,
    services,
    waste,
    weather,
    forecastPrecisionChart,
    client: { turnoverName, isForecastTurnover },
    match: { path },
  } = props;

  const [storeId, setStoreId] = useState('');
  const [storeName, setStoreName] = useState('');
  const [storeTimezone, setStoreTimezone] = useState('');
  const [todayOfStore, setTodayOfStore] = useState(null); // a moment object to be re-used via clone

  // We can't destructuring the context because the page load before the context is set in the clientContainer
  const contextHandler = useContext(ForecastsContext);

  // useEffects

  useEffect(() => {
    if (!contextHandler || !stores.length) {
      return;
    }

    const currentStoreId = _.get(contextHandler, 'selectedStore.id', stores[0].id);
    const currentStoreName = _.get(contextHandler, 'selectedStore.name', stores[0].name);
    const currentStoreTimezone = _.get(
      contextHandler,
      'selectedStore.timezone',
      stores[0].timezone,
    );

    if (!_.isEmpty(stores) && _.isEmpty(contextHandler.selectedStore)) {
      contextHandler.handleStoreSelection(stores[0]);
    }

    setStoreId(currentStoreId);
    setStoreName(currentStoreName);
    setStoreTimezone(currentStoreTimezone);
    setTodayOfStore(moment.tz(currentStoreTimezone));
  }, [stores, !!contextHandler && contextHandler.selectedStore]);

  useEffect(() => {
    if (!_.isEmpty(weatherStation)) {
      getCurrentWeatherForecast();
    }
  }, [weatherStation]);

  useEffect(() => {
    if (!storeId) {
      return;
    }

    loadStoreData(storeId);

    if (hasUserAccessToProductionFeature) {
      getMetricsByDates(
        storeId,
        todayOfStore
          .clone()
          .subtract(6, 'weeks')
          .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        todayOfStore.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );
    }
  }, [storeId]);

  // functions

  const loadStoreData = (storeId, storeTimezone) => {
    getNearestWeatherForecastStation(storeId);

    fetchSalesData(storeId); //Sales Data
    fetchForecastsData(storeId); //Forecasts Data

    getEventSubCategories(storeId);
    loadStoreEvents(storeId);
  };

  const loadStoreEvents = (storeId, refreshForecasts) => {
    if (refreshForecasts) {
      fetchForecastsData(storeId);
    }

    const currentdaysToFetchPast =
      prevHorizon.past > DAYS_TO_FETCH_PAST ? prevHorizon.past : DAYS_TO_FETCH_PAST;

    getPastEventsOfStore(
      storeId,
      todayOfStore.clone().subtract(currentdaysToFetchPast, 'days').subtract(1, 'year'),
      todayOfStore.clone().add(prevHorizon.future, 'days').subtract(1, 'year'),
    );

    getEventsOfStore(
      storeId,
      todayOfStore.clone().subtract(currentdaysToFetchPast, 'days'),
      todayOfStore.clone().add(prevHorizon.future, 'days'),
    );
  };

  const getCurrentWeatherForecast = () => {
    if (!_.isEmpty(weatherStation) && !!todayOfStore) {
      const currentdaysToFetchPast =
        prevHorizon.past > DAYS_TO_FETCH_PAST ? prevHorizon.past : DAYS_TO_FETCH_PAST;

      getPastWeatherForecast(
        weatherStation.id,
        storeTimezone,
        todayOfStore
          .clone()
          .subtract(currentdaysToFetchPast, 'days')
          .subtract(1, 'year')
          .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        todayOfStore
          .clone()
          .add(prevHorizon.future, 'days')
          .subtract(1, 'year')
          .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );
      getWeatherForecast(
        weatherStation.id,
        storeTimezone,
        todayOfStore
          .clone()
          .subtract(currentdaysToFetchPast, 'days')
          .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        todayOfStore
          .clone()
          .add(prevHorizon.future, 'days')
          .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );
    }
  };

  const getNearestWeatherForecastStation = async (storeId) => {
    try {
      const storesByIds = _.keyBy(stores, 'id');
      const storeLocation = _.get(storesByIds, `[${storeId}].locationValue`, null);

      if (storeLocation) {
        await getWeatherForecastStation(storeLocation);
        getCurrentWeatherForecast();
      }
    } catch {
      showErrorMessage(i18next.t('FORECAST.TURNOVER.PRECISION_WEATHER_STATION_FETCH_ERROR'));
    }
  };

  const fetchSalesData = (storeId) => {
    let currentdaysToFetchPast =
      prevHorizon.past > DAYS_TO_FETCH_PAST ? prevHorizon.past : DAYS_TO_FETCH_PAST;

    const start_sale = todayOfStore
      .clone()
      .subtract(currentdaysToFetchPast + 5, 'days')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    const end_sale = todayOfStore.clone().format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    getSales(storeId, start_sale, end_sale, isForecastTurnover);

    getPastSales(
      storeId,
      todayOfStore
        .clone()
        .subtract(1, 'year')
        .startOf('isoWeek')
        .subtract(prevHorizon.past * 2, 'days')
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      todayOfStore
        .clone()
        .subtract(1, 'year')
        .add(prevHorizon.future, 'days')
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      isForecastTurnover,
    );
  };

  const fetchForecastsData = (storeId) => {
    const start_forecast = todayOfStore
      .clone()
      .subtract(prevHorizon.past, 'days')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    const end_forecast = todayOfStore
      .clone()
      .add(prevHorizon.future + 1, 'days')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    getForecasts(storeId, start_forecast, end_forecast);
  };

  return (
    <div className={props.className}>
      <NavigationBreadCrumb featurePath={path} />
      <DashboardMainContainer>
        <ForecastsPerformancesContainer
          forecasts={forecasts}
          lastYearSales={lastYearSales}
          sales={sales}
          storeName={storeName}
          stores={stores}
          storeTimezone={storeTimezone}
        />
        <ForecastsMainContainer
          clearForecasts={clearForecasts}
          events={events.concat(lyEvents)}
          eventSubCategories={eventSubCategories}
          forecasts={forecasts}
          forecastsLimit={prevHorizon.future}
          hasOrder={hasOrder}
          hasPlanning={hasPlanning}
          lastYearSales={lastYearSales}
          lastYearWeather={!!weatherStation ? lyWeather : []}
          loadStoreEvents={loadStoreEvents}
          managerPrevDisplay={true}
          productionMetricsByDates={productionMetricsByDates}
          sales={sales}
          salesLimit={prevHorizon.past}
          services={services}
          storeId={storeId}
          storeTimezone={storeTimezone}
          todayOfStore={todayOfStore}
          user={user}
          wasteMetricPropertyKey={waste || 'turnoverWasteRate'}
          weather={!!weatherStation ? weather : []}
        />
        <InpulseForecastPrecisionChartContainer>
          {forecastPrecisionChart &&
            _.get(user, 'lnkAccountRoleAccountrel.name') === 'deepsight' && (
              <ForecastPrecisionChart
                metricName={turnoverName}
                storeId={storeId}
                twoServices={services && services.length}
              />
            )}
        </InpulseForecastPrecisionChartContainer>
      </DashboardMainContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  forecasts: state.baseReducer.forecasts,
  sales: state.baseReducer.sales,
  weather: state.baseReducer.weather,
  events: state.baseReducer.events,
  weatherStation: state.baseReducer.weatherStation,
  eventSubCategories: state.baseReducer.eventSubCategories,
  user: state.baseReducer.user,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  lastYearSales: state.baseReducer.lastYearSales,
  lyEvents: state.baseReducer.lyEvents,
  lyWeather: state.baseReducer.lyWeather,
  productionMetricsByDates: state.baseReducer.productionMetricsByDates,
  hasUserAccessToProductionFeature: isUserAllowedToAccessProduction(state.baseReducer.userRights),
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  getWeatherForecastStation: (storeLocation) =>
    dispatch(getWeatherForecastStation(storeLocation)).then(
      (result) => {
        dispatch(recieveWeatherForecastStation(result));
      },
      (error) => {
        dispatch(requestWeatherForecastStationError(error));
      },
    ),
  getForecasts: (store_id, start_date, end_date) =>
    dispatch(fetchForecastsOfStore(store_id, start_date, end_date)).then(
      (result) => {
        dispatch(receiveForecasts(result));
      },
      (error) => {
        dispatch(requestForecastsError(error));
      },
    ),
  getSales: (store_id, start_date, end_date, isForecastTurnover) =>
    dispatch(fetchSalesOfStore(store_id, start_date, end_date, isForecastTurnover)).then(
      (result) => {
        dispatch(receiveSales(result));
      },
      (error) => {
        dispatch(requestSalesError(error));
      },
    ),
  getPastSales: (store_id, start_date, end_date, isForecastTurnover) =>
    dispatch(fetchSalesOfStore(store_id, start_date, end_date, isForecastTurnover)).then(
      (result) => {
        dispatch(receiveLastYearSales(result));
      },
      (error) => {
        dispatch(requestSalesError(error));
      },
    ),
  getWeatherForecast: (weatherStationId, storeTimezone, startDate, endDate) =>
    dispatch(fetchWeather(weatherStationId, storeTimezone, startDate, endDate)).then(
      (result) => {
        dispatch(receiveWeather(result));
      },
      (error) => {
        dispatch(fetchWeatherError(error));
      },
    ),
  getPastWeatherForecast: (weatherStationId, storeTimezone, startDate, endDate) =>
    dispatch(fetchLyWeather(weatherStationId, storeTimezone, startDate, endDate)).then(
      (result) => {
        dispatch(receiveLyWeather(result));
      },
      (error) => {
        dispatch(fetchLyWeatherError(error));
      },
    ),
  getEventsOfStore: (storeId, start_date, end_date) =>
    dispatch(fetchEventsByStoreIdAndDates(storeId, start_date, end_date)).then(
      (result) => {
        dispatch(receiveEvents(result));
      },
      (error) => {
        dispatch(fetchEventError(error));
      },
    ),
  getPastEventsOfStore: (storeId, start_date, end_date) =>
    dispatch(fetchLyEventsOfStore(storeId, start_date, end_date)).then(
      (result) => {
        dispatch(receiveLyEvents(result));
      },
      (error) => {
        dispatch(fetchLyEventError(error));
      },
    ),
  getEventSubCategories: (storeId) =>
    dispatch(getEventSubCategories(storeId)).then(
      (result) => {
        dispatch(recieveEventSubCategories(result));
      },
      (error) => {
        dispatch(requestEventSubCategoriesError(error));
      },
    ),
  getMetricsByDates: (storeId, startDate, endDate) =>
    dispatch(getMetricsByDates(storeId, startDate, endDate)).then(
      (result) => {
        dispatch(receiveMetricsByDates(result));
      },
      (error) => {
        dispatch(requestMetricsByDatesError(error));
      },
    ),
  clearForecasts: () => dispatch(clearForecasts()),
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardForecastsContainer);
