import { get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getNumberFormattedWithDecimals } from '@commons/DisplayNumber';

import { brand as brandService } from '@services/brand';
import { group as groupService } from '@services/group';
import { supplier as supplierService } from '@services/supplier';
import accountService from '@services/account';
import clientService from '@services/client';
import orderService from '@services/order';

import { METRIC_KEY } from './constants';

export const fetchBrandsByClientId = async (clientId, showMessage) => {
  try {
    const brands = await brandService.getBrandsOfClient(clientId);

    return brands;
  } catch (err) {
    showMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_BRANDS_FAILURE'), 'error');

    return [];
  }
};

export const fetchLocationsByAccountId = async (accountId, showMessage) => {
  try {
    const locations = await accountService.getLocationsOfAccount(accountId);

    return locations;
  } catch (err) {
    showMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_LOCATIONS_FAILURE'), 'error');

    return [];
  }
};

export const fetchRetailersByClientId = async (clientId, showMessage) => {
  try {
    const retailers = await clientService.getRetailersOfClient(clientId);

    return retailers;
  } catch (err) {
    showMessage(i18next.t('STOCKS.CURRENT_STOCKS.GET_RETAILERS_FAILURE'), 'error');

    return [];
  }
};

export const fetchGroupsByStoreIds = async (storeIds, showMessage) => {
  const formattedGroups = {};

  try {
    const groupMappings = await groupService.getGroupsOfStores(storeIds);

    groupMappings.forEach((groupMapping) => {
      const groupId = groupMapping.lnkGroupStoregroupmappingrel.id;
      const groupName = groupMapping.lnkGroupStoregroupmappingrel.name;

      if (!formattedGroups[groupId]) {
        formattedGroups[groupId] = {
          groupId,
          storeIds: [],
          name: groupName,
          id: groupMapping.id,
        };
      }

      formattedGroups[groupId].storeIds.push(groupMapping.storeId);
    });

    return Object.values(formattedGroups);
  } catch (err) {
    showMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_GROUPS_FAILURE'), 'error');

    return [];
  }
};

export const fetchSuppliersByClientId = async (clientId, showMessage) => {
  try {
    const suppliers = await supplierService.getSuppliersOfClient(clientId);

    return suppliers;
  } catch (err) {
    showMessage(i18next.t('ORDERS.BY_CATEGORY.FETCH_SUPPLIERS_FAILURE'), 'error');

    return [];
  }
};

export const fetchAnalytics = async (
  storeIds,
  startDate,
  endDate,
  metrics,
  setTurnoverByStoreId,
  setTotalLossByStoreId,
  setTurnover,
  setLossRate,
  showMessage,
  currency,
  shouldSetStates = true, // For exports, we do not update turnoverByStoreId, turnover and lossRate
) => {
  const formattedData = {};
  let formattedTotalRow;

  try {
    const { total, dataByCategory, turnoverByStoreId, totalLossByStoreId } =
      await orderService.getOrdersAnalyticsByCategory(
        storeIds,
        startDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        endDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        showMessage,
      );

    if (shouldSetStates) {
      setTurnover(getNumberFormattedWithDecimals(turnoverByStoreId['total'], 0));
      setTurnoverByStoreId(turnoverByStoreId);
      setTotalLossByStoreId(totalLossByStoreId);
    }

    if (total) {
      formattedTotalRow = Object.keys(total).reduce((acc, metric) => {
        acc[metric] = {
          ...total[metric],
          name: 'TOTAL',
          isFixed: true,
        };
        return acc;
      }, {});

      const formattedLossRate = turnoverByStoreId['total']
        ? getNumberFormattedWithDecimals(
            (total[METRIC_KEY.TURNOVER].loss * 100) / turnoverByStoreId['total'],
            2,
          )
        : 0;

      if (shouldSetStates) {
        setLossRate(formattedLossRate);
      }
    }

    metrics.forEach(({ key }) => {
      formattedData[key] = [];
      Object.keys(dataByCategory).forEach((category) => {
        const categoryData = dataByCategory[category];

        const formattedEntities = categoryData.entities.map(({ id, name, unit, stats }) => ({
          ...get(stats, key, {}),
          id,
          name,
          unit,
        }));

        const row = {
          name: category,
          ...categoryData.stats[key],
          childItems: formattedEntities,
        };

        formattedData[key].push(row);
      });
    });
  } catch (err) {
    showMessage('Impossible de récupérer les données', 'error');
  } finally {
    return { formattedData, formattedTotalRow };
  }
};

export const fetchInventoryDateOfStoreByDates = async (
  storeIds,
  startMonth,
  endMonth,
  userTimezone,
  showMessage,
) => {
  const data = await orderService.getInventoryForStores(
    storeIds,
    startMonth,
    endMonth,
    showMessage,
    'stock',
  );

  const formattedDate = { startDate: [], endDate: [] };

  if (data.list.length) {
    // For more explanation see Ticket (DEEP-1167) => https://deepsightprod.atlassian.net/jira/software/projects/DEEP/boards/12?assignee=60a6181d5998a60068872119&selectedIssue=DEEP-1167
    // Cas#1 stockConvention = start & Cas #2 stockConvention = end
    // stockConvention choose to be start if stockConvention is `null`
    if (data.list[0].lnkStoreInventorylistrel.stockConvention === 'end') {
      formattedDate.startDate = data.dates.map((item) =>
        moment
          .tz(item, userTimezone)
          .add(1, 'day')
          .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );
      formattedDate.endDate = data.dates.map((item) =>
        moment.tz(item, userTimezone).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );
    } else {
      formattedDate.startDate = data.dates.map((item) =>
        moment.tz(item, userTimezone).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );
      formattedDate.endDate = data.dates.map((item) =>
        moment
          .tz(item, userTimezone)
          .subtract(1, 'day')
          .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );
    }
  }

  return formattedDate;
};

export default {
  fetchAnalytics,
  fetchGroupsByStoreIds,
  fetchBrandsByClientId,
  fetchSuppliersByClientId,
  fetchRetailersByClientId,
  fetchLocationsByAccountId,
  fetchInventoryDateOfStoreByDates,
};
