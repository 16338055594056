import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: space-between;
  padding: 0 0 0 24px;
  background: ${(props) => props.theme.colors?.greys.lightest};
  z-index: 200;
`;
