import moment from 'moment-timezone';
import React from 'react';

import { SingleDatePicker } from '../SingleDatePicker';

/**
 * A single day DatePicker limited to past days.
 *
 * Usage example in: LossListView
 */
export const PastDayDatePicker = (props) => {
  const { maxFutureDate } = props;
  return <SingleDatePicker {...props} maxFutureDate={maxFutureDate || moment()} />;
};

PastDayDatePicker.propTypes = SingleDatePicker.propTypes;

PastDayDatePicker.defaultProps = SingleDatePicker.defaultProps;
