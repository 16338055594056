export const AUDIT_EVENTS = {
  // Order Events
  ORDER_CREATED: 'ORDER_CREATED',
  ORDER_SENT: 'ORDER_SENT',
  ORDER_MANUAL_SENT: 'ORDER_MANUAL_SENT', // Set to status SENT from ListView
  ORDER_MANUAL_DRAFT: 'ORDER_MANUAL_DRAFT', // Set to status DRAFT from ListView
  ORDER_RECEIVED: 'ORDER_RECEIVED', // From SENT to RECEIVED
  ORDER_RECEPTION_VALIDATED: 'ORDER_RECEPTION_VALIDATED', // From SENT or RECEIVED to COMPLIANT or NON_COMPLIANT
  ORDER_RECEPTION_CANCELED: 'ORDER_RECEPTION_CANCELED', // From COMPLIANT or NON_COMPLIANT to SENT or RECEIVED
  ORDER_CREDIT_REQUEST_PROCESSED: 'ORDER_CREDIT_REQUEST_PROCESSED', // Set to status CREDIT_REQUEST_PROCESSED
  ORDER_EDN_RECEIVED: 'ORDER_EDN_RECEIVED',
  ORDER_EDI_ERROR: 'ORDER_EDI_ERROR',
  ORDER_UPDATED: 'ORDER_UPDATED',

  // Supplier Product Events
  SUPPLIER_PRODUCT_CREATED: 'SUPPLIER_PRODUCT_CREATED',
  SUPPLIER_PRODUCT_LINKED: 'SUPPLIER_PRODUCT_LINKED',
  SUPPLIER_PRODUCT_UNLINKED: 'SUPPLIER_PRODUCT_UNLINKED',
  INVOICE_PACKAGING_UPDATED: 'INVOICE_PACKAGING_UPDATED',
  SUPPLIER_PRODUCT_DISABLED: 'SUPPLIER_PRODUCT_DISABLED',
  SUPPLIER_PRODUCT_ENABLED: 'SUPPLIER_PRODUCT_ENABLED',
  SUPPLIER_PRODUCT_PRICE_UPDATED: 'SUPPLIER_PRODUCT_PRICE_UPDATED',
  SUPPLIER_PRODUCT_UPDATED: 'SUPPLIER_PRODUCT_UPDATED',
};

export const AUDIT_EVENTS_CONFIG = {
  [AUDIT_EVENTS.ORDER_CREATED]: {
    label: 'ORDERS.AUDIT_EVENTS.ORDER_CREATED',
    icon: '/images/inpulse/add-black.svg',
  },
  [AUDIT_EVENTS.ORDER_SENT]: {
    label: 'ORDERS.AUDIT_EVENTS.ORDER_SENT',
    icon: '/images/inpulse/send-black-small.svg',
  },
  [AUDIT_EVENTS.ORDER_MANUAL_SENT]: {
    label: 'ORDERS.AUDIT_EVENTS.ORDER_MANUAL_SENT',
    icon: '/images/inpulse/send-black-small.svg',
  },
  [AUDIT_EVENTS.ORDER_MANUAL_DRAFT]: {
    label: 'ORDERS.AUDIT_EVENTS.ORDER_MANUAL_DRAFT',
    icon: '/images/inpulse/edit-pen-black-small.svg',
  },
  [AUDIT_EVENTS.ORDER_RECEIVED]: {
    label: 'ORDERS.AUDIT_EVENTS.ORDER_RECEIVED',
    icon: '/images/inpulse/edit-pen-black-small.svg',
  },
  [AUDIT_EVENTS.ORDER_RECEPTION_VALIDATED]: {
    label: 'ORDERS.AUDIT_EVENTS.ORDER_RECEPTION_VALIDATED',
    icon: '/images/inpulse/done-black-small.svg',
  },
  [AUDIT_EVENTS.ORDER_RECEPTION_CANCELED]: {
    label: 'ORDERS.AUDIT_EVENTS.ORDER_RECEPTION_CANCELED',
    icon: '/images/inpulse/edit-pen-black-small.svg',
  },
  [AUDIT_EVENTS.ORDER_CREDIT_REQUEST_PROCESSED]: {
    label: 'ORDERS.AUDIT_EVENTS.ORDER_CREDIT_REQUEST_PROCESSED',
    icon: '/images/inpulse/done-black-small.svg',
  },
  [AUDIT_EVENTS.ORDER_EDN_RECEIVED]: {
    label: 'ORDERS.AUDIT_EVENTS.ORDER_EDN_RECEIVED',
    icon: '/images/inpulse/receipt-attachment.svg',
  },
  [AUDIT_EVENTS.ORDER_EDI_ERROR]: {
    label: 'ORDERS.AUDIT_EVENTS.ORDER_EDI_ERROR',
    icon: '/images/inpulse/warning-red-small.svg',
  },
  [AUDIT_EVENTS.ORDER_UPDATED]: {
    label: 'ORDERS.AUDIT_EVENTS.ORDER_UPDATED',
    icon: '/images/inpulse/edit-pen-black-small.svg',
  },

  [AUDIT_EVENTS.SUPPLIER_PRODUCT_CREATED]: {
    label: 'ADMIN.SUPPLIER_PRODUCTS.AUDIT_EVENTS_CREATED',
    icon: '/images/inpulse/add-black.svg',
  },
  [AUDIT_EVENTS.SUPPLIER_PRODUCT_LINKED]: {
    label: 'ADMIN.SUPPLIER_PRODUCTS.AUDIT_EVENTS_LINKED',
    icon: '/images/inpulse/inpulse-link-black-small.svg',
  },
  [AUDIT_EVENTS.SUPPLIER_PRODUCT_UNLINKED]: {
    label: 'ADMIN.SUPPLIER_PRODUCTS.AUDIT_EVENTS_UNLINKED',
    icon: '/images/inpulse/unlink-black-small.svg',
  },
  [AUDIT_EVENTS.INVOICE_PACKAGING_UPDATED]: {
    label: 'ADMIN.SUPPLIER_PRODUCTS.AUDIT_EVENTS_INVOICE_PACKAGING',
    icon: '/images/inpulse/stock-black-small.svg',
  },
  [AUDIT_EVENTS.SUPPLIER_PRODUCT_DISABLED]: {
    label: 'ADMIN.SUPPLIER_PRODUCTS.AUDIT_EVENTS_DISABLED',
    icon: '/images/inpulse/power-black-small.svg',
  },
  [AUDIT_EVENTS.SUPPLIER_PRODUCT_ENABLED]: {
    label: 'ADMIN.SUPPLIER_PRODUCTS.AUDIT_EVENTS_ENABLED',
    icon: '/images/inpulse/power-black-small.svg',
  },
  [AUDIT_EVENTS.SUPPLIER_PRODUCT_PRICE_UPDATED]: {
    label: 'ADMIN.SUPPLIER_PRODUCTS.AUDIT_EVENTS_PRICE',
    icon: '/images/inpulse/payments-black.svg',
  },
  [AUDIT_EVENTS.SUPPLIER_PRODUCT_UPDATED]: {
    label: 'ADMIN.SUPPLIER_PRODUCTS.AUDIT_EVENTS_UPDATED',
    icon: '/images/inpulse/edit-pen-black-small.svg',
  },
};
