import PropTypes from 'prop-types';
import React from 'react';

import { Container } from './styledComponents';
import Drawer from 'react-modern-drawer';

const CustomDrawer = (props) => {
  const {
    direction,
    duration,
    isOpened,
    children,
    customWidth,
    onClick,
    overlayColor,
    overlayOpacity,
  } = props;

  return (
    <Container>
      <Drawer
        direction={direction}
        duration={duration}
        open={isOpened}
        overlayColor={overlayColor}
        overlayOpacity={overlayOpacity}
        size={customWidth}
        style={{ height: window.innerHeight ? `${window.innerHeight}px` : '100vh' }} // use window.innerHeight because of old tablet
        onClose={onClick}
      >
        {children}
      </Drawer>
    </Container>
  );
};

CustomDrawer.propTypes = {
  direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  isOpened: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  customWidth: PropTypes.string,
  duration: PropTypes.number,
  overlayColor: PropTypes.string,
  overlayOpacity: PropTypes.string,
};

CustomDrawer.defaultProps = {
  direction: 'right',
  customWidth: '400px',
  duration: 100,
  overlayColor: 'rgba(34, 35, 33, 0.64)', // Use rgba to have blur and opacity
  overlayOpacity: 'none',
};

export default CustomDrawer;
