import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

import { stock as stockService } from '@services/stock';
import centralService from '@services/central';

import { PAST_STOCKS_TYPE } from '@stocks/utils/constants';

export const getPastStockDates = async (
  pastStockType,
  focusedMonth,
  selectedStoreId,
  { showErrorMessage },
) => {
  const startDate = moment(focusedMonth)
    .startOf('month')
    .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
  const endDate = moment(focusedMonth)
    .add(1, 'month')
    .endOf('month')
    .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

  try {
    const datesWithInventories =
      pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_RECIPES
        ? await centralService.getRecipeInventoryDatesByStoresIds(
            [selectedStoreId],
            startDate,
            endDate,
          )
        : await stockService.getPastStocksDates(selectedStoreId, startDate, endDate);

    return datesWithInventories.map((date) => moment(date));
  } catch {
    showErrorMessage(i18next.t('STOCKS.PAST_STOCKS.GET_PAST_STOCK_DATE'));
    return [];
  }
};
