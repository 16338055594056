import storeService from '../services/store';

export const receiveStores = (stores) => ({
  type: 'STORES_SUCCESS',
  stores,
});

export const requestStoresError = (error) => ({
  type: 'STORES_FAILURE',
  error,
});

export const getStoresOfUser = () =>
  function (dispatch) {
    dispatch({
      type: 'STORES_REQUEST',
    });
    return storeService.getStoresOfUser();
  };

export const receiveSingleStore = (store) => ({
  type: 'SINGLE_STORE_SUCCESS',
  store,
});

export const requestSingleStoreError = (error) => ({
  type: 'SINGLE_STORE_FAILURE',
  error,
});

export const getSingleStore = (storeId) =>
  function (dispatch) {
    dispatch({
      type: 'SINGLE_STORE_REQUEST',
    });
    return storeService.getById(storeId);
  };

export const updateIsSynchronizingCashierStoresStatus = (isSynchronizingCashierStores) => ({
  type: 'IS_SYNCHRONIZING_CASHIER_STORES_UPDATE',
  isSynchronizingCashierStores,
});
