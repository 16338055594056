import { chunk } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { generateDefaultSheet } from '@commons/utils/makeXLS';
import { getUserTimezone } from '@commons/utils/date';

import formatProductOrders from './format';
import getColumns from './columns';

const MAX_SHEET_ELEMENT_SIZE = 50_000;

export default (
  productOrders,
  { storeName, isCentralExport, displaySupplierProductPackagingWeight, currency },
) => {
  const formattedProductOrders = formatProductOrders(productOrders);

  const displayCustomerCode = formattedProductOrders.some(({ customerCode }) => customerCode);
  const displayPackagingWeight =
    displaySupplierProductPackagingWeight &&
    formattedProductOrders.some(({ totPackagingWeight }) => totPackagingWeight);

  const columns = getColumns({
    storeName,
    isCentral: isCentralExport,
    displayPackagingWeight,
    displayCustomerCode,
  });

  const filename = i18next.t('ORDERS.ORDERS.LIST_EXPORT_ANOMALY_TITLE');
  const sheetName = i18next.t('ORDERS.ORDERS.LIST_EXPORT_ANOMALY_TITLE');

  const date = moment.tz(getUserTimezone()).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
  const batchProductOrders = chunk(formattedProductOrders, MAX_SHEET_ELEMENT_SIZE);
  const hasManyFiles = batchProductOrders.length > 1;

  return batchProductOrders.map((chunkProductOrders, index) => {
    const appendFileCount = hasManyFiles ? `-${index + 1}` : '';
    const sheet = generateDefaultSheet(sheetName, columns, chunkProductOrders, currency);

    return {
      filename: `${filename}-${date}${appendFileCount}`,
      sheets: [sheet],
    };
  });
};
