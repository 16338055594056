import i18next from 'i18next';
import React from 'react';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { Label } from '@commons/utils/styledLibraryComponents';
import DisplayNumber from '@commons/DisplayNumber';

import theme from '@theme';

import { Text } from '../components/styledComponents';

const getCashierMappedColor = (product) => {
  if (!product) {
    return null;
  }
  if (product.nbCashierProductMappingCount > 0 && product.nbCashierProductMappingActive > 0) {
    return theme.colors.infoGreen;
  }
  if (product.nbCashierProductMappingCount > 0 && product.nbCashierProductMappingActive === 0) {
    return theme.colors.infoOrange;
  }
  if (product.nbCashierProductMappingCount === 0) {
    return theme.colors.infoRed;
  }
};

export const getColumns = (client) => {
  const columns = [
    {
      id: 'productName',
      name: i18next.t('GENERAL.NAME'),
      displayName: i18next.t('GENERAL.NAME'),
      propertyKey: 'name',
      baseName: 'name',
      large: true,
      render: (item) => <Text highlighted>{item}</Text>,
    },
    {
      id: 'sku',
      baseName: 'sku',
      propertyKey: 'sku',
      displayName: i18next.t('ORDERS.ORDERS.LIST_LABEL_SKU'),
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_SKU'),
      minWidth: 130,
      render: (item) => <Text>{item ? item : '-'}</Text>,
    },
    {
      id: 'created_at',
      baseName: 'category',
      propertyKey: 'category',
      displayName: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CATEGORY'),
      name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CATEGORY'),
      filterType: 'string',
      filterStringAllowNone: true,
      render: (item) => (item ? <Text>{item}</Text> : <Text>-</Text>),
    },
    {
      id: 'subCategory',
      baseName: 'subCategory',
      propertyKey: 'subCategory',
      displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_SUB_CATEGORY'),
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_SUB_CATEGORY'),
      filterType: 'string',
      minWidth: 130,
      render: (item) => (item ? <Text>{item}</Text> : <Text>-</Text>),
    },
    {
      id: 'priceTaxes',
      baseName: 'priceWithTaxes',
      propertyKey: 'priceWithTaxes',
      displayName: i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_PRICE_LABEL'),
      name: i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_PRICE_LABEL'),
      excludeFromSearch: true,
      filterType: 'numeric',
      tooltipText:
        client.hasMultipleChannels && i18next.t('ADMIN.PRODUCTS.PRICE_WITH_TAXES_TOOLTIP'),
      render: (item) => <DisplayNumber displayCurrencyCode={true} number={item} />,
    },
    {
      id: 'composition',
      baseName: 'composition',
      propertyKey: 'composition',
      displayName: i18next.t('ADMIN.RECIPES.EXPORT_COLUMN_PRODUCT_COMPOSITION'),
      name: i18next.t('ADMIN.RECIPES.EXPORT_COLUMN_PRODUCT_COMPOSITION'),
      filterType: 'string',
      render: (item) => <Text>{item}</Text>,
    },
  ];

  if (client.hasMultipleBrands) {
    columns.splice(2, 0, {
      id: 'brand',
      name: i18next.t('GENERAL.BRAND'),
      displayName: i18next.t('GENERAL.BRAND'),
      propertyKey: 'brand',
      baseName: 'brand',
      filterType: 'string',
      render: (item) => (item ? <div>{item}</div> : <Text>-</Text>),
    });
  }

  if (client.areProductsSync) {
    columns.push({
      id: 'cashierProducts',
      baseName: 'nbCashierProductMappingCount',
      propertyKey: 'nbCashierProductMappingCount',
      displayName: i18next.t('ADMIN.PRODUCTS.CASHIER_PRODUCT_ASSOCIATE'),
      name: i18next.t('ADMIN.PRODUCTS.CASHIER_PRODUCT_ASSOCIATE'),
      excludeFromSearch: true,
      filterType: 'numeric',
      minWidth: 180,
      render: (item, product) => {
        const color = getCashierMappedColor(product);
        return (
          <Label
            background={color}
            children={item}
            color={color}
            height={'24px'}
            type={'outline'}
            width={'24px'}
          />
        );
      },
    });
  }

  return columns;
};

export const getCentralKitchenProductsColumns = (clientStoreName) => {
  const clientStoreNamePlural = getClientStoreNameTranslation(clientStoreName, true);

  return [
    {
      id: 'productName',
      name: i18next.t('GENERAL.NAME'),
      propertyKey: 'name',
      filterType: 'string',
    },
    {
      id: 'category',
      propertyKey: 'category',
      name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CATEGORY'),
      filterType: 'string',
      filterStringAllowNone: true,
      render: (item) => (item ? <Text>{item}</Text> : <Text>-</Text>),
    },
    {
      id: 'subCategory',
      propertyKey: 'subCategory',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_SUB_CATEGORY'),
      filterType: 'string',
      minWidth: 130,
      render: (item) => (item ? <Text>{item}</Text> : <Text>-</Text>),
    },
    {
      id: 'priceWithTaxes',
      propertyKey: 'priceWithTaxes',
      name: i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_PRICE_LABEL'),
      excludeFromSearch: true,
      filterType: 'numeric',
      render: (item) => <DisplayNumber displayCurrencyCode={true} number={item} />,
    },
    {
      id: 'composition',
      propertyKey: 'composition',
      name: i18next.t('ADMIN.RECIPES.EXPORT_COLUMN_PRODUCT_COMPOSITION'),
      filterType: 'string',
      render: (item) => <Text>{item}</Text>,
    },
    {
      id: 'deliveredStoreCount',
      propertyKey: 'deliveredStoreCount',
      name: i18next.t('ADMIN.CENTRAL_PRODUCTS.DELIVERED_STORE', {
        storeName: clientStoreNamePlural,
      }),
      filterType: 'numeric',
      render: (item) => {
        const color = item === 0 ? theme.colors.red : theme.colors.green.light;

        return (
          <Label
            background={color}
            children={item}
            color={color}
            height={'24px'}
            type={'outline'}
            width={'24px'}
          />
        );
      },
    },
  ];
};

export default { getColumns, getCentralKitchenProductsColumns };
