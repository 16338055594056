import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import useLocalStorage from '../../hooks/useLocalStorage';
import useOfflineStatus from '../../hooks/useOfflineStatus';

import { showConfirmationMessage } from '../../actions/messageconfirmation';
import { updateOfflineStatus } from '../../actions/offline';
import { updateSlowConnectionStatus } from '../../actions/offline';

import { getHealthStatus } from '../../services/healthCheck';

import { config } from '../../config';

import {
  Container,
  BottomBar,
  Content,
  Title,
  SubTitle,
  Children,
  SubText,
} from './styledComponents';

import { getClientInfo } from '../../selectors/client';
import OfflineLogo from '../../assets/logo/offline.svg';

const REGEX_PRODUCTION_PATH = new RegExp(/(\/production\/|\/production$)/);
const REGEX_INVENTORIES_PATH = new RegExp(/(\/stocks$)/);

export const OfflineDetector = React.memo((props) => {
  const {
    client: { hasOffline },
    children,
    mainPath,
    isUserOffline,
    showMessage,
    isConnectionSlow,
    updateOfflineStatus,
    updateSlowConnectionStatus,
    path,
  } = props;

  const updatedUserOfflineStatus = useOfflineStatus();

  const [isProductionPage, setIsProductionPage] = useState(false);
  const [isInventoriesPage, setIsInventoriesPage] = useState(false);

  const [previousOfflineStatus, setPreviousOfflineStatus] = useState(
    isUserOffline || isConnectionSlow,
  );

  const [, setUserOfflineSince] = useLocalStorage('userOfflineSince', null);

  useEffect(() => {
    if (!mainPath) {
      return;
    }

    setIsProductionPage(REGEX_PRODUCTION_PATH.test(mainPath));
    setIsInventoriesPage(REGEX_INVENTORIES_PATH.test(mainPath));
  }, [mainPath, path]);

  useEffect(() => {
    const updatedOfflineStatus = hasOffline && (isUserOffline || isConnectionSlow);

    if (previousOfflineStatus !== updatedOfflineStatus) {
      setPreviousOfflineStatus(updatedOfflineStatus);
      setUserOfflineSince(updatedOfflineStatus ? moment() : null);

      if (!updatedOfflineStatus) {
        showMessage(i18next.t('GENERAL.OFFLINE_TOASTER_MESSAGE_FOUND_CONNECTION'));
      } else {
        showMessage(
          i18next.t(
            isInventoriesPage
              ? 'GENERAL.OFFLINE_TOASTER_MESSAGE_LOST_CONNECTION_INVENTORIES_PAGE'
              : 'GENERAL.OFFLINE_TOASTER_MESSAGE_LOST_CONNECTION',
          ),
          'info',
          'offline',
          isInventoriesPage, // force manual dismiss
        );
      }
    }
  }, [isUserOffline, isConnectionSlow]);

  useEffect(() => {
    if (!hasOffline || updatedUserOfflineStatus === isUserOffline) {
      return;
    }

    if (updatedUserOfflineStatus) {
      updateSlowConnectionStatus(false);
      updateOfflineStatus(true);

      return;
    }

    (async function checkConnection() {
      const { duration } = await getHealthStatus();

      const isConnectionUnstable = duration >= config.limitResponseTime;

      if (isConnectionUnstable !== isConnectionSlow) {
        updateSlowConnectionStatus(isConnectionUnstable);
      }

      updateOfflineStatus(false);
    })();
  }, [updatedUserOfflineStatus, isUserOffline, isConnectionSlow]);

  const isConsideredAsOffline = hasOffline && (isUserOffline || isConnectionSlow);

  return (
    <Container>
      <Children shouldbeHidden={isConsideredAsOffline && !isProductionPage}>{children}</Children>
      {isConsideredAsOffline && (
        <>
          {!isProductionPage && (
            <Content>
              <img src={OfflineLogo} />
              <Title>{i18next.t('GENERAL.OFFLINE_TITLE')}</Title>
              <SubText>{i18next.t('GENERAL.OFFLINE_SUBTITLE')}</SubText>
            </Content>
          )}
          <BottomBar>
            <div style={{ marginLeft: '-60px', display: 'flex', alignItems: 'center' }}>
              <svg height="14" viewBox="0 0 16 14" width="16" xmlns="http://www.w3.org/2000/svg">
                <g id="surface1">
                  <path
                    d="M 3.972656 3.148438 C 3.15625 2.332031 2.339844 1.523438 1.527344 0.714844 C 1.441406 0.628906 1.324219 0.527344 1.296875 0.414062 C 1.28125 0.300781 1.324219 0.128906 1.410156 0.0429688 C 1.46875 -0.015625 1.648438 -0.0078125 1.753906 0.0273438 C 1.855469 0.0625 1.933594 0.164062 2.011719 0.25 C 6.296875 4.535156 10.582031 8.820312 14.878906 13.113281 C 14.921875 13.15625 14.980469 13.1875 14.992188 13.230469 C 15.023438 13.40625 15.121094 13.636719 15.050781 13.75 C 14.894531 13.988281 14.679688 13.859375 14.507812 13.695312 C 14.105469 13.292969 13.683594 12.898438 13.296875 12.476562 C 13.078125 12.242188 12.863281 12.164062 12.546875 12.175781 C 9.707031 12.191406 6.867188 12.191406 4.023438 12.183594 C 1.917969 12.175781 0.332031 10.753906 0.03125 8.65625 C -0.21875 6.804688 1.046875 4.972656 2.949219 4.40625 C 3.105469 4.363281 3.257812 4.226562 3.363281 4.085938 C 3.578125 3.8125 3.75 3.492188 3.972656 3.148438 Z M 12.132812 11.503906 C 9.550781 8.921875 6.96875 6.351562 4.371094 3.742188 C 4.207031 4.019531 4.035156 4.355469 3.820312 4.65625 C 3.714844 4.800781 3.542969 4.957031 3.378906 5 C 1.589844 5.4375 0.511719 6.867188 0.703125 8.605469 C 0.882812 10.296875 2.304688 11.503906 4.128906 11.503906 C 6.304688 11.503906 8.484375 11.503906 10.660156 11.503906 C 11.167969 11.503906 11.667969 11.503906 12.132812 11.503906 Z M 12.132812 11.503906 "
                    fill="#FFFFFF"
                  />
                  <path
                    d="M 8.0625 1.851562 C 7.441406 2.015625 6.824219 2.160156 6.210938 2.351562 C 5.96875 2.417969 5.746094 2.503906 5.625 2.21875 C 5.503906 1.9375 5.71875 1.816406 5.945312 1.714844 C 8.820312 0.414062 12.261719 1.980469 13.226562 5.035156 C 13.3125 5.308594 13.441406 5.445312 13.714844 5.542969 C 15.980469 6.339844 16.738281 9.164062 15.179688 10.960938 C 15.066406 11.089844 14.886719 11.175781 14.722656 11.226562 C 14.664062 11.246094 14.484375 11.117188 14.484375 11.046875 C 14.472656 10.910156 14.507812 10.730469 14.59375 10.625 C 15.042969 10.117188 15.300781 9.550781 15.328125 8.878906 C 15.378906 7.546875 14.542969 6.410156 13.234375 6.09375 C 12.890625 6.007812 12.742188 5.851562 12.65625 5.515625 C 12.148438 3.40625 10.265625 1.9375 8.0625 1.851562 Z M 8.0625 1.851562 "
                    fill="#FFFFFF"
                  />
                </g>
              </svg>
            </div>
            <SubTitle style={{ marginLeft: '5px' }}>{i18next.t('GENERAL.OFFLINE')}</SubTitle>
          </BottomBar>
        </>
      )}
    </Container>
  );
});

const mapStateToProps = (state) => ({
  isUserOffline: state.baseReducer.isUserOffline,
  isConnectionSlow: state.baseReducer.isConnectionSlow,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  updateOfflineStatus: (offlineStatus) => {
    dispatch(updateOfflineStatus(offlineStatus));
  },
  showMessage: (message, type, icon, manualDismiss) => {
    dispatch(showConfirmationMessage(message, type, icon, 3000, manualDismiss));
  },
  updateSlowConnectionStatus: (isConnectionSlow) => {
    dispatch(updateSlowConnectionStatus(isConnectionSlow));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OfflineDetector);
