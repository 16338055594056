import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import utilsXLS from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import {
  getContextSheetColumnsSettings,
  getMainSheetColumnsSettings,
} from '../../utils/exportColumns';

export const OrderAnalyticsByCategoryExportModal = (props) => {
  const {
    params: {
      title,
      turnoverSheetData,
      unitSheetData,
      contextSheetData,
      showMessage,
      currency,
      storeName,
    },
    closeModal,
  } = props;

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [titleModal, setTitleModal] = useState(title || '');

  const exitModal = () => {
    setIsLoading(false);

    closeModal();
  };

  useEffect(() => {
    (async function launchExport() {
      try {
        const turnoverSheet = utilsXLS.generateDefaultSheet(
          i18next.t('ORDERS.BY_CATEGORY.EXPORT_TURNOVER_SHEET_NAME', { currency: currency.name }),
          getMainSheetColumnsSettings('turnover', storeName),
          turnoverSheetData,
          currency,
        );

        const unitSheet = utilsXLS.generateDefaultSheet(
          i18next.t('GENERAL.UNIT'),
          getMainSheetColumnsSettings('unit', storeName),
          unitSheetData,
          currency,
        );

        const contextSheet = utilsXLS.generateDefaultSheet(
          i18next.t('ORDERS.BY_CATEGORY.EXPORT_CONTEXT_SHEET_NAME'),
          getContextSheetColumnsSettings(),
          contextSheetData,
          currency,
        );

        utilsXLS.makeXLS(i18next.t('ORDERS.BY_CATEGORY.EXPORT_ANALYTICS_FILENAME'), [
          turnoverSheet,
          unitSheet,
          contextSheet,
        ]);

        setTitleModal(i18next.t('GENERAL.EXPORT_SUCCESS'));
        setIsLoading(false);
      } catch {
        showMessage(i18next.t('GENERAL.EXPORT_FAILURE'), 'error');
        exitModal();
      }
    })();
  }, []);

  useEffect(() => {
    if (isLoading && progress < 100) {
      setProgress(100);
    }
  }, [progress]);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setIsLoading}
      titleModal={titleModal}
    />
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps)(OrderAnalyticsByCategoryExportModal);
