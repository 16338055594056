import { APIcaller } from './APIcaller';

const getRushOrderById = (rushOrderId) => APIcaller.apiGet(`/rush-order/${rushOrderId}`);

const getRushOrderByIds = (rushOrderIds) =>
  APIcaller.apiPost(`/rush-order/content`, { rushOrderIds });

const getRushOrdersByStoreIds = async (storeIds, rushSuppliers = null) =>
  APIcaller.apiPost('/rush-order/list', {
    storeIds,
    rushSuppliers,
  });

const getRushSuppliers = async (clientId) => APIcaller.apiGet(`/rush-order/${clientId}/suppliers`);

const getSuppliersByStoreIds = async (storeIds) =>
  APIcaller.apiPost(`/rush-order/suppliers`, { storeIds });

const deleteById = async (rushOrderId) => APIcaller.apiDelete(`/rush-order/${rushOrderId}`);

const createRushOrder = async (rushOrder) => APIcaller.apiPost('/rush-order/create', rushOrder);

const updateRushOrder = async (rushOrder) => APIcaller.apiPatch('/rush-order/', rushOrder);

export default {
  getRushOrderById,
  getRushOrderByIds,
  getRushOrdersByStoreIds,
  getRushSuppliers,
  createRushOrder,
  deleteById,
  updateRushOrder,
  getSuppliersByStoreIds,
};
