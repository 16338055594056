import * as yup from 'yup';
import i18next from 'i18next';

// COMMONS
import { INPUT_TYPES } from '@commons/constants/inputType';
import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';

const STORAGE_AREA_INPUTS = {
  NAME: 'name',
};

export const FIELDS_TO_CHECK = ['name'];

export const STORAGE_AREA_INFOS_INPUTS = () => [
  {
    type: INPUT_TYPES.TEXT,
    name: STORAGE_AREA_INPUTS.NAME,
    width: INPUT_WIDTH.LARGE,
    isRequired: true,
    label: `* ${i18next.t('GENERAL.NAME')}`,
    rule: yup.string().required(
      i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
        field: i18next.t('GENERAL.NAME'),
      }),
    ),
  },
];

export const STORAGE_AREA_FORM_INPUTS = () => [...STORAGE_AREA_INFOS_INPUTS()];
