import styled from 'styled-components';

export const FormContainer = styled.div`
  margin-top: 20px;
`;

export const ContentContainer = styled.div`
  overflow-y: scroll;
  // 64px size of the sidePanel footer + 2px for Divider  // 24px margin size design wanted between divider and footer
  height: calc(100% - (64px + 2px + 24px));
`;

export const Container = styled.div`
  height: 100%;
`;

export const List = styled.div`
  padding: 24px 0px;

  display: flex;
  row-gap: 16px;
  align-items: center;
  flex-direction: column;
}
`;

export const Divider = styled.div`
  margin: auto;
  width: calc(100% - 48px);

  border: 1px solid;
  border-color: ${(props) => props.theme.colors.greys.light};
`;

export const TitleContainer = styled.div`
  padding: 24px 24px 8px 24px;

  font: ${(props) => props.theme.fonts.h1InterNonBold};
  color: ${(props) => props.theme.colors.greys.darkest};
`;

export const DetailContainer = styled.div`
  width: calc(100% - 48px);

  .SingleDatePickerInput {
    width: 220px;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;

  gap: 16px;
  display: flex;
  justify-content: center;
`;
