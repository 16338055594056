import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  width: 100%;
  padding: 30px 25px;
  border-top-left-radius: ${(props) => props.theme.borders?.radius.weak || '5px'};
  border-top-right-radius: ${(props) => props.theme.borders?.radius.weak || '5px'};
  box-shadow: ${(props) =>
    props.theme.colors ? undefined : '0 2px 6px 0 rgba(82, 134, 245, 0.1)'};
  -webkit-box-shadow: ${(props) =>
    props.theme.colors ? undefined : '0 2px 6px 0 rgba(82, 134, 245, 0.1)'};
  background: linear-gradient(270deg, #1ab3ec 0%, #0a35d6 98.83%);

  ${(props) =>
    props.white &&
    css`
      background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
    `}

  ${(props) =>
    props.noShadow &&
    css`
      box-shadow: none;
    `}
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
  font-style: normal;
  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts?.textBig};`
      : `font-size: 24px;
  font-weight: 400;`}
  ${(props) =>
    props.black &&
    css`
      color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};
    `}

  ${(props) =>
    props.medium &&
    css`
      ${(props) =>
        props.theme.fonts
          ? `font: ${props.theme.fonts?.textBig};`
          : `font-size: 16px;
      line-height: 20px;`}
    `}
`;

/* export const ContentProgressBar = styled.div`
  padding: 40px 40px 20px 40px;

  .progress {
    background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
    box-shadow: ${(props) => (props.theme.colors ? undefined : '0px 2px 4px rgba(0, 0, 0, 0.1)')};
    border-radius: ${(props) => props.theme.borders?.radius.weak || '3px'};
  }

  .progress-bar {
    background: linear-gradient(269.8deg, #1ab3ec -13.83%, #0a3eff 100.6%);
  }
`; */

export const ContentDateSelection = styled.div`
  padding: 40px;

  text-align: center;

  color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};

  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts?.textBig};`
      : `font-size: 14px;
  line-height: 24px;`}
`;

export const Footer = styled.div`
  display: flex;
  padding-bottom: 20px;
  justify-content: center;
  border-bottom-left-radius: ${(props) => props.theme.borders?.radius.weak || '5px'};
  border-bottom-right-radius: ${(props) => props.theme.borders?.radius.weak || '5px'};

  ${(props) =>
    props.shadow &&
    css`
      box-shadow: ${(props) =>
        props.theme.colors ? undefined : '0px -3px 5px rgba(0, 0, 0, 0.05)'};
    `}

  ${(props) =>
    props.alignLeft &&
    css`
      justify-content: left;
    `}
`;

export const DatePicker = styled.div`
  flex: 1;

  height: 36px;
  min-width: 200px;
  max-width: 250px;

  margin: 20px auto 0px auto;

  color: ${(props) => props.theme.colors?.greys.darker || '#6b768c'};

  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
  border-radius: ${(props) => props.theme.borders?.radius.weak || '4px'};
  box-shadow: ${(props) => (props.theme.colors ? undefined : '0 2px 6px 0 rgba(0, 0, 0, 0.1)')};

  border: ${(props) =>
    props.focusedDateInput
      ? props.theme.inputs?.primary.focus.border
      : props.theme.inputs?.primary.default.border};

  .DateRangePicker_picker {
    top: 40px !important;
    left: -155px !important;
  }

  > div {
    display: flex;
    align-items: center;

    height: 36px;
    width: calc(100% - 40px);

    > div {
      width: 100%;

      > div {
        width: 100%;
        display: flex;

        .DateInput_1 {
          flex: 1;
        }
      }
    }
  }
`;
