import _ from 'lodash';
import React, { useState, useEffect } from 'react';

import {
  Container as ContainerToggle,
  ToggleSection,
} from '@admin/products/products/detail/components/ProductRecipeAssociation/ProductRecipeAssociationToggle/styledComponents';

export default ({ channels, selectedChannelName, setSelectedChannelName }) => {
  const [channelChoices, setChannelsChoices] = useState([]);

  useEffect(() => {
    setChannelsChoices(channels.length);
    setSelectedChannelName(_.first(channels));
  }, [channels]);

  if (channelChoices <= 1) {
    return <></>;
  }

  return (
    <ContainerToggle>
      {channels.map((channelName, index) => {
        const isSelected = channelName === selectedChannelName;

        return (
          <ToggleSection
            className={isSelected ? 'active' : ''}
            key={index}
            onClick={() => setSelectedChannelName(channelName)}
          >
            <span>{channelName}</span>
          </ToggleSection>
        );
      })}
    </ContainerToggle>
  );
};
