import { isEmpty, get } from 'lodash';
import i18next from 'i18next';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_NEXT_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

import { LOSS_TYPE } from '../constants';

import ExportLossesSelectors from './ExportLossesSelectors';

const MODAL_TITLE = {
  [LOSS_TYPE.PRODUCT]: i18next.t('LOSSES.PRODUCTS.EXPORT_TITLE'),
  [LOSS_TYPE.SUPPLIER_PRODUCT]: i18next.t('LOSSES.SUPPLIER_PRODUCTS.EXPORT_TITLE'),
  [LOSS_TYPE.RECIPE]: i18next.t('LOSSES.RECIPES.EXPORT_TITLE'),
};

const getExportLossModalActions = (props) => {
  const { selectedStores, selectedDateRange, handleExport, resetExportingLosses } = props;

  const disabledAction =
    isEmpty(selectedStores) ||
    !get(selectedDateRange, 'startDate') ||
    !get(selectedDateRange, 'endDate');

  return [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_NEXT_BUTTON(),
      label: i18next.t('GENERAL.EXPORT'),
      icon: '/images/inpulse/file-download-white-small.svg',
      handleClick: () => {
        handleExport(selectedStores, selectedDateRange);
        resetExportingLosses();
      },
      isDisabled: disabledAction,
    },
  ];
};

export const getExportLossesModalConfig = (props) => {
  const { lossType, resetExportingLosses } = props;

  const params = {
    type: 'action',
    width: '542px',
    height: 'auto',
    icon: '/images/inpulse/file-download-black-small.svg',
    title: MODAL_TITLE[lossType],
    handleCloseCleanUp: resetExportingLosses,
    actions: getExportLossModalActions(props),
    component: ExportLossesSelectors,
    data: props,
  };

  return params;
};
