const SHEET = {
  ID: 'products',
  NAME: 'Produits',
};

const COLUMN_IDS = {
  NAME: 'name',
  BRAND: 'brand',
  CATEGORY: 'category',
  SUB_CATEGORY: 'subCategory',
  SKU: 'sku',
  SHELF_LIFE: 'shelfLife',
  PRICE_WITH_TAXES: 'priceWithTaxes',
  VAT_RATE: 'vatRate',
  DELIVERY_PRICE_WITH_TAXES: 'deliveryPriceWithTaxes',
  DELIVERY_VAT_RATE: 'deliveryVatRate',
  COMPOSITION_TYPE: 'compositionTypes',
  ASSOCIATED_RECIPE: 'associatedRecipe',
  ASSOCIATED_INGREDIENT: 'associatedIngredient',
};

const COLUMN_NORMALIZE_IDS = {
  NAME: 'nom',
  BRAND: 'marques',
  CATEGORY: 'categorie',
  SUB_CATEGORY: 'sous-categories',
  SKU: 'sku',
  SHELF_LIFE: 'dlc (j)',
  PRICE_WITH_TAXES: 'prix ttc',
  VAT_RATE: 'tva (%)',
  DELIVERY_PRICE_WITH_TAXES: 'prix ttc liv.',
  DELIVERY_VAT_RATE: 'tva liv. (%)',
  COMPOSITION_TYPE: 'type de composition',
  ASSOCIATED_RECIPE: 'recette associee',
  ASSOCIATED_INGREDIENT: 'ingredient associe',
};

// Category
const HIDDEN_SHEET_CATEGORY = {
  ID: 'categories',
  NAME: 'Catégories',
};

const HIDDEN_CATEGORY_COLUMN_ID = {
  NAME: 'name',
};

const HIDDEN_CATEGORY_NORMALIZE_COLUMN_ID = {
  NAME: 'nom',
};

// Subcategory
const HIDDEN_SHEET_SUB_CATEGORY = {
  ID: 'subCategories',
  NAME: 'SousCatégories',
};

const HIDDEN_SUB_CATEGORY_COLUMN_ID = {
  NAME: 'name',
};

const HIDDEN_SUB_CATEGORY_NORMALIZE_COLUMN_ID = {
  NAME: 'nom',
};

// Brand
const HIDDEN_SHEET_BRAND = {
  ID: 'brands',
  NAME: 'Marques',
};

const HIDDEN_BRAND_COLUMN_ID = {
  ID: 'id',
  NAME: 'name',
};

const HIDDEN_BRAND_NORMALIZE_COLUMN_ID = {
  ID: 'brandid',
  NAME: 'nom',
};

// Associated ingredient
const HIDDEN_SHEET_ASSOCIATED_INGREDIENT = {
  ID: 'associatedIngredient',
  NAME: 'ingrédientAssocié',
};

const HIDDEN_ASSOCIATED_INGREDIENT_COLUMN_ID = {
  ID: 'id',
  NAME: 'name',
};

const HIDDEN_ASSOCIATED_INGREDIENT_NORMALIZE_COLUMN_ID = {
  ID: 'ingredientid',
  NAME: 'nom',
};

// Associated recipe
const HIDDEN_SHEET_ASSOCIATED_RECIPE = {
  ID: 'associatedRecipe',
  NAME: 'recetteAssociée',
};

const HIDDEN_ASSOCIATED_RECIPE_COLUMN_ID = {
  ID: 'id',
  NAME: 'name',
};

const HIDDEN_ASSOCIATED_RECIPE_NORMALIZE_COLUMN_ID = {
  ID: 'recipeid',
  NAME: 'nom',
};

module.exports = {
  SHEET,
  COLUMN_IDS,
  COLUMN_NORMALIZE_IDS,
  // Category
  HIDDEN_SHEET_CATEGORY,
  HIDDEN_CATEGORY_COLUMN_ID,
  HIDDEN_CATEGORY_NORMALIZE_COLUMN_ID,
  // Subcategory
  HIDDEN_SHEET_SUB_CATEGORY,
  HIDDEN_SUB_CATEGORY_COLUMN_ID,
  HIDDEN_SUB_CATEGORY_NORMALIZE_COLUMN_ID,
  // Brand
  HIDDEN_SHEET_BRAND,
  HIDDEN_BRAND_COLUMN_ID,
  HIDDEN_BRAND_NORMALIZE_COLUMN_ID,
  // Associated ingredient
  HIDDEN_SHEET_ASSOCIATED_INGREDIENT,
  HIDDEN_ASSOCIATED_INGREDIENT_COLUMN_ID,
  HIDDEN_ASSOCIATED_INGREDIENT_NORMALIZE_COLUMN_ID,
  // Associated recipe
  HIDDEN_SHEET_ASSOCIATED_RECIPE,
  HIDDEN_ASSOCIATED_RECIPE_COLUMN_ID,
  HIDDEN_ASSOCIATED_RECIPE_NORMALIZE_COLUMN_ID,
};
