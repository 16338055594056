import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import utilsXLS from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { getEntityUnit } from '@stocks/utils';
import { PAST_STOCKS_TYPE } from '@stocks/utils/constants';

const getMainsSheetColumnsSettings = (pastStockType) => [
  {
    name: i18next.t(
      pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_RECIPES
        ? 'GENERAL.RECIPE'
        : 'GENERAL.NAME',
    ),
    propertyKey: 'name',
  },
  {
    name: i18next.t('GENERAL.UNIT'),
    propertyKey: 'unit',
    transform: (value) =>
      getEntityUnit(value) === 'u.' ? i18next.t('GENERAL.UNIT') : getEntityUnit(value),
  },
  {
    name: i18next.t('STOCKS.STOCKS.THEORETICAL_STOCK_SINGULAR_UNIT'),
    propertyKey: 'theoricalStockUnit',
  },
  {
    name: i18next.t('STOCKS.STOCKS.REAL_STOCK_SINGULAR_UNIT'),
    propertyKey: 'realStockUnit',
  },
  {
    name: i18next.t('STOCKS.STOCKS.GAP_STOCK_SINGULAR_UNIT'),
    propertyKey: 'stockGapUnit',
  },
  {
    type: 'currency',
    name: i18next.t('STOCKS.STOCKS.THEORETICAL_STOCK_SINGULAR_CURRENCY'),
    propertyKey: 'theoricalStockTurnover',
  },
  {
    name: i18next.t('STOCKS.STOCKS.REAL_STOCK_SINGULAR_CURRENCY'),
    propertyKey: 'realStockTurnover',
  },
  {
    name: i18next.t('STOCKS.STOCKS.GAP_STOCK_SINGULAR_CURRENCY'),
    propertyKey: 'stockGapTurnover',
  },
  {
    name: i18next.t('STOCKS.PAST_STOCKS.GAP_PERCENT'),
    propertyKey: 'stockGapPercentage',
  },
];

export const StocksAnalyticsPastExportModal = (props) => {
  const {
    currency,
    params: { title, mainSheetData, pastStockType },
    closeModal,
    showErrorMessage,
  } = props;

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [titleModal, setTitleModal] = useState(title || '');

  const mainSheetColumns = getMainsSheetColumnsSettings(pastStockType);

  useEffect(() => {
    (async function launchExport() {
      try {
        const mainSheet = utilsXLS.generateDefaultSheet(
          i18next.t('FEATURE.STOCKS.PAST'),
          mainSheetColumns,
          mainSheetData,
          currency,
        );

        utilsXLS.makeXLS(title, [mainSheet]);
        setTitleModal(i18next.t('GENERAL.EXPORT_SUCCESS'));
        setIsLoading(false);
      } catch (error) {
        showErrorMessage(i18next.t('GENERAL.EXPORT_FAILURE'));
        exitModal();
      }
    })();
  }, []);

  useEffect(() => {
    if (isLoading && progress < 100) {
      setProgress(100);
    }
  }, [progress]);

  const exitModal = () => {
    setIsLoading(false);
    closeModal();
  };

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setIsLoading}
      titleModal={titleModal}
    />
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StocksAnalyticsPastExportModal);
