import { getActionAuthorization } from '../commons/utils';

const INGREDIENT_ACTIONS_RESOURCE = {
  DOWNLOAD_TEMPLATE: 'ingredients:batch:template',
  IMPORT: 'ingredients:batch:import',
};

export const canDownloadIngredientTemplate = (actions) =>
  getActionAuthorization(actions, INGREDIENT_ACTIONS_RESOURCE.DOWNLOAD_TEMPLATE);

export const canImportIngredient = (actions) =>
  getActionAuthorization(actions, INGREDIENT_ACTIONS_RESOURCE.IMPORT);
