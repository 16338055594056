import PropTypes, { any } from 'prop-types';
import React from 'react';

import { ENUM_MODULE_NAME } from '../../../commons/utils/features';
import { STOCKS_FEATURE_PATHS } from '../../../commons/utils/featurePaths';

import { ModuleContainer } from '../../ModuleContainer';

export const StocksInventoriesContainer = (props) => {
  const { userRights, history } = props;

  return (
    <ModuleContainer
      history={history}
      moduleFeaturePaths={[
        STOCKS_FEATURE_PATHS.STOCKS_INVENTORIES_STOCKS,
        STOCKS_FEATURE_PATHS.STOCKS_INVENTORIES_TRANSFER,
      ]}
      moduleName={ENUM_MODULE_NAME.STOCK}
      userRights={userRights}
    />
  );
};

StocksInventoriesContainer.propTypes = {
  userRights: PropTypes.array,
  history: any, // react-router-dom's route prop
  moduleName: PropTypes.oneOf(Object.values(ENUM_MODULE_NAME)),
  moduleFeaturePaths: PropTypes.arrayOf(PropTypes.string),
};

export default StocksInventoriesContainer;
