import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  padding: 20px 30px;
  height: 15%;
  align-items: center;
`;

export const Logo = styled.img`
  height: 150px;
  width: 150px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85%;
  padding: 1.5rem;
`;

export const Illustration = styled.img`
  height: 400px;
  width: 300px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  height: 15%;

  font: ${(props) => props.theme.fonts.title};
`;

export const SubTile = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  height: 15%;

  font: ${(props) => props.theme.fonts.h1Inter};
`;
