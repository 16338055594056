import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import {
  CustomModalContainer,
  CustomModalBody,
  CloseIcon,
  // IconArrow,
} from './styledComponents';
// import PolygonSvg from '../../../images/icon-polygon.svg';
import IconCloseCross from '../../../images/icon-close-cross.svg';
import { ModalContentProps } from '../../interface';

import { getTheme } from '../../../utils/theme';

const CustomModalContentDisplay = (props: ModalContentProps): JSX.Element => {
  const { theme, modalContent, openModal } = props;

  return (
    <ThemeProvider theme={getTheme(theme, 'expandableEmbeddedList')}>
      <CustomModalContainer onClick={(e) => e.stopPropagation()}>
        {/*
        // TODO Uncomment when icon for the modal is available
          <IconArrow src={PolygonSvg} />
        */}
        <CustomModalBody>
          <CloseIcon src={IconCloseCross} onClick={openModal} />
          {modalContent()}
        </CustomModalBody>
      </CustomModalContainer>
    </ThemeProvider>
  );
};

CustomModalContentDisplay.propTypes = {
  modalContent: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.objectOf(PropTypes.any),
};

CustomModalContentDisplay.defaultProps = {
  theme: null,
};

export default CustomModalContentDisplay;
