import styled, { css } from 'styled-components';

export const Content = styled.div`
  width: 100%;
  height: 100%;

  overflow: hidden;
  padding: 0px 35px;
  position: relative;
`;

export const ContentList = styled.div`
  width: 100%;
  overflow: scroll;
  position: relative;
  height: calc(100% - 108px);
`;

export const ContentHeader = styled.div``;

export const Line = styled.div`
  width: 100%;
  padding: 15px 20px;
  background: ${(props) => props.theme.colors?.greys.lightest};
  box-shadow: ${(props) => props.theme.shadows?.short};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  border-radius: ${(props) => props.theme.borders?.radius.weak};

  &.header {
    background: inherit;
    box-shadow: none;
    border-bottom: none;
    > div {
      color: ${(props) => props.theme.colors?.greys.darker};
    }
  }
  .inpulse-checkbox {
    margin-right: 1%;
  }
  .deepsight-checkbox-square {
    margin-right: 30px;
  }
`;

export const Row = styled.div`
  flex: ${(props) => (props.width === 'small' ? 0.5 : 1)};
  display: flex;
  color: ${(props) => props.theme.colors?.greys.darkest};

  font: ${(props) => props.theme.fonts?.textBig};
  ${(props) =>
    props.isEllipsis &&
    css`
      flex: 1.5;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  > p {
    margin: 0;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: 24px;

  > div {
    &:first-child {
      height: 40px;
    }
  }
`;

export const ListRowText = styled.div`
  color: ${(props) => props.theme.colors?.greys.darkest};

  font: ${(props) => props.theme.fonts?.textSmall};
  &.link {
    color: ${(props) => props.theme.colors?.greys.darkest};
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.sku-label {
    display: block;
    overflow: hidden;

    max-width: 80px;
    width: fit-content;

    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const DateContainer = styled.p`
  font-weight: ${(props) => (props.bold ? '700' : '400')};

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const TagLabel = styled.div`
  background: ${(props) => props.theme.colors?.greys.lighter};

  min-width: 85px;

  width: fit-content;
  max-width: 80%;
  height: 24px;
  padding: 0px 6px;

  font: ${(props) => props.theme.fonts?.textBig};

  border-radius: ${(props) => props.theme.borders?.radius.weak};

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  display: flex;
  justify-content: start;

  &.box {
    min-width: fit-content;
    margin: auto 10px;
  }

  &:hover ~ div {
    display: flex;
    align-items: center;
  }
`;

export const TagContainer = styled.div`
  position: relative;

  display: flex;
  justify-content: start;
  align-items: center;

  height: 100%;
  width: 100%;

  cursor: default;
`;
export const TagInfo = styled.div`
  min-width: 20px;
  margin-left: 5px;

  &:hover ~ div {
    display: flex;
    align-items: center;
  }
`;

export const TagHoverBox = styled.div`
  width: auto;
  height: 35px;

  display: none;

  position: absolute;
  z-index: 1;
  top: 25px;

  background: #ffffff;
  border-radius: 3px;

  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
`;

export const PointerDiv = styled.div`
  width: 8px;
  height: 8px;

  background: ${(props) => props.theme.colors?.greys.lightest};

  position: absolute;
  z-index: 1;
  top: -4px;
  /* left: 50%; */
  left: 20px;
  transform: translateX(-50%) rotate(-135deg);
`;

export const NameContainer = styled.div`
  width: 100%;
  padding: 0px 5px;
  height: inherit;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > p {
    margin: 0;

    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #2c7dfe;
  }
`;

export const InfoDiv = styled.div`
  font: ${(props) => props.theme.fonts?.textSmall};
  color: ${(props) => props.theme.colors?.greys.darker};

  min-width: 150px;
`;

export const SyncBtn = styled.div`
  background: ${(props) => props.theme.colors?.greys.darkest};

  border-radius: ${(props) => props.theme.borders?.radius.weak};

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px 0px 12px;
  color: ${(props) => props.theme.colors?.greys.lightest};

  width: 35px;
  height: 35px;
`;

export const Icon = styled.img`
  width: 15px;
  height: 20px;
`;

export const CheckDropDownContainer = styled.div`
  display: flex;

  margin-left: 15px;
`;

export const ContainerLastSync = styled.div`
  height: 36px;
  padding-top: 3px;
  margin: 15px 0px 15px 0px;

  display: flex;

  align-items: center;
`;

export const Text = styled.div`
  font: ${(props) => props.theme.fonts?.textSmall};
  color: ${(props) => (props.grey ? '#6b768c' : '#000000')};
`;
