// Based on PreStepChoiceToProceed, should be improved later

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 50px 70px;
`;

export const MainText = styled.div`
  color: ${(props) => props.theme.colors.greys.darkest || '#000000'};

  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts.h2};`
      : `font-size: 24px;
  font-weight: 600;
  line-height: 32px;`}

  max-width: 720px;
`;

export const SubText = styled.div`
  color: ${(props) => props.theme.colors.greys.darkest || '#000000'};
  margin-top: 10px;

  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts.textBig};`
      : `font-size: 14px;
  line-height: 16px;
  font-weight: normal;`}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  height: 20%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  height: 35%;
  text-align: center;
  padding: 40px;
`;

export const EmptyStateIcon = styled.img`
  width: 150px;
  margin: auto;
  display: flex;
  position: relative;
`;

export const RadioSelectButton = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 240px;
  height: ${(props) => (props.theme.fonts ? '40px' : '56px')};

  box-shadow: ${(props) => props.theme.shadows?.short || '0px 1px 4px rgba(0, 0, 0, 0.1)'};
  border-radius: ${(props) => props.theme.radioButton?.size.outerCircleDefault || '3px'};

  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts.textBig};`
      : `font-weight: 600;
  font-size: 16px;
  line-height: 32px;`}
  margin: 10px;
  padding: 0 20px;

  background: ${(props) => props.theme.colors.greys.lightest || '#fff'};

  cursor: pointer;

  border: ${(props) =>
    props.theme.radioButton
      ? `${props.theme.radioButton.borderSize} solid ${props.theme.radioButton.borderColor}`
      : undefined};

  &:focus {
    border: ${(props) =>
      props.theme.radioButton
        ? `${props.theme.radioButton.selected.borderSize} solid ${props.theme.radioButton.selected.color}`
        : `1px solid ${props.theme.colors.greys.darkest}`};
  }

  &:hover {
    background: ${(props) => props.theme.radioButton?.hover.backgroundColor || undefined};
  }
`;
