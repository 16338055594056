import './InputNumber.css';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

class InputNumber extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mousePosition: 'outside',
    };
  }

  getClassNameFromProps = () => {
    const { className, readOnly } = this.props;
    let customClassName = 'deepsight-input-encloser';

    if (className.toString() !== '') {
      customClassName = customClassName + ' ' + className;
    }

    if (readOnly) {
      customClassName = customClassName + ' ' + 'readOnly';
    }

    return customClassName;
  };

  getStyleFromProps = () => {
    const { style, width, inputWidth, height, semiBold, textColor } = this.props;
    return {
      ...style,
      width: inputWidth ? inputWidth + 'px' : width - 20 + 'px',
      height: height + 'px',
      lineHeight: height + 'px',
      color: textColor,
      fontFamily: semiBold ? 'proximanovasemibold' : 'proximanovaregular',
    };
  };

  changeValue = (type) => {
    let { value, onChange } = this.props;
    if (type === '+') value = value + 1;
    else value = Math.max(0, value - 1);
    onChange({ target: { value } });
  };

  render() {
    let { value, onChange, height, readOnly, width, autoFocus } = this.props;
    const { mousePosition } = this.state;
    readOnly = readOnly || false;
    return (
      <div
        className={this.getClassNameFromProps()}
        style={{
          width: width + 'px',
          height: height + 'px',
          lineHeight: '0px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        onMouseEnter={() => this.setState({ mousePosition: 'inside' })}
        onMouseLeave={() => this.setState({ mousePosition: 'outside' })}
      >
        <input
          autoFocus={autoFocus}
          className="deepsight-input"
          min="0"
          readOnly={readOnly}
          style={this.getStyleFromProps()}
          type="number"
          value={value}
          onChange={onChange}
        />
        {mousePosition !== 'outside' && readOnly === false && (
          <div
            className={'deepsight-input-arrow-container'}
            style={{
              width: '20px',
            }}
          >
            <div className={'deepsight-input-top-arrow'} onClick={() => this.changeValue('+')}>
              <div className={'arrow arrow-top'} />
            </div>
            <div className={'deepsight-input-bottom-arrow'} onClick={() => this.changeValue('-')}>
              <div className={'arrow arrow-bottom'} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const StyledInputNumber = styled(InputNumber)`
  & .deepsight-input {
    font-family: proximanovaregular, serif;
    color: #4b4b4b;
    font-size: 12px;
    outline: none;
    border: 0;
    text-align: left;
    padding-left: 7px;
    margin-top: -1px;
    background-color: rgba(0, 0, 0, 0);
    font: ${(props) => props.theme.fonts?.textBig} !important;
  }
`;

InputNumber.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  width: PropTypes.number,
  height: PropTypes.number,
  readOnly: PropTypes.bool,
};

InputNumber.defaultProps = {
  className: '',
  style: {},
  width: 70,
  height: 30,
  readOnly: false,
};

export default StyledInputNumber;
