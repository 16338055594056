import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

// Design
import {
  ButtonContainer,
  Container,
  ContentContainer,
  DragAndDropContainer,
  DropdownContainer,
  FilterContainer,
  TextWithToolTipContainer,
  TopContainer,
} from './styledComponents';

// Actions
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// Services
import { supplier as supplierService } from '@services/supplier';
import { upload as uploadService } from '@services/upload';
import invoiceService from '@services/invoice';

// Selectors
import { getClientInfo } from '@selectors/client';

// Components
import { InvoiceControlFilesList } from './components/invoiceControlFilesList';
import { NavBarContainer } from '@src/routes/invoice/invoiceControlDetails/styledComponents';

// Common
import { Button, Dropdown, Tooltip } from '@commons/utils/styledLibraryComponents';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import NavigationBar from '@commons/NavigationBar';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { CreditInfoContainer } from '../invoiceControls/styledComponents';

import { fileStatusCodes, formatBytes } from './utils/fileStatus';
import DragAndDropUploader from './components/dragAndDropUploader';

const STEF_SUPPLIER_ID = '471e1f6f-2ac2-4aad-a5a8-cae7a1802e32'; // I hate that I have to do that

const InvoiceControlBatchImport = (props) => {
  const {
    // Props
    match: { path },
    // redux states
    client: { clientId },
    stores,
    showErrorMessage,
  } = props;

  const history = useHistory();

  const automaticSelectionStore = {
    id: null,
    name: i18next.t('INVOICE.INVOICE_CONTROLS.AUTOMATIC_SELECTION_STORE_NAME'),
  };

  /* STATES */
  const [availableCredits, setAvailableCredits] = useState({
    remaining: 0,
    invoiceVolume: 0,
  });
  const [isImportButtonDisabled, setIsImportButtonDisabled] = useState(true);

  // Selectors
  const [suppliers, setSuppliers] = useState([]);
  const [selectedStore, setSelectedStore] = useState({});
  const [selectedSupplier, setSelectedSupplier] = useState({});

  // Files
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [hasTooManyFiles, setHasTooManyFiles] = useState(false);
  const [filesWithErrors, setFilesWithErrors] = useState([]);
  const [isImporting, setIsImporting] = useState(false);
  const [treatedFilesCount, setTreatedFilesCount] = useState(0);

  const getAvailableCredits = async () => {
    try {
      const result = await invoiceService.getAvailableCredits(clientId);

      setAvailableCredits({ remaining: result.remaining, invoiceVolume: result.invoiceVolume });
    } catch {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROLS.FETCH_AVAILABLE_CREDITS_ERROR'));
    }
  };

  useEffect(() => {
    (async () => {
      const storeIds = stores.map(({ id }) => id);

      const fetchedSuppliers = await supplierService.getSuppliersOfStores(storeIds);

      setSuppliers(fetchedSuppliers);
    })();
  }, []);

  useEffect(() => {
    getAvailableCredits(clientId);
  }, [clientId]);

  useEffect(() => {
    const checkInvalidFiles =
      !selectedFiles.some(({ status }) => status !== fileStatusCodes.SUCCESS) &&
      !!treatedFilesCount &&
      treatedFilesCount === selectedFiles.length;

    setIsImportButtonDisabled(
      isEmpty(selectedSupplier) ||
        isEmpty(selectedStore) ||
        selectedFiles.length === 0 ||
        isImporting ||
        checkInvalidFiles,
    );
  }, [selectedSupplier, selectedStore, selectedFiles, isImporting]);

  // Prompt user before reloading the page, design is not customizable on modern browsers
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isImporting) {
        return;
      }
      // Perform actions before the component unloads
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isImporting]);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === 'POP' && isImporting) {
        return window.confirm(i18next.t('INVOICE.INVOICE_CONTROLS.GO_BACK_WARNING'));
      }
    });

    return () => {
      unblock();
    };
  }, [history, isImporting]);

  useEffect(() => {
    if (selectedSupplier.id !== STEF_SUPPLIER_ID) {
      // Reset selected store if the supplier is not STEF anymore
      if (selectedStore.id === automaticSelectionStore.id) {
        setSelectedStore({});
      }

      return;
    }

    setSelectedStore(automaticSelectionStore);
  }, [selectedSupplier]);

  const selectFiles = (files) => {
    const formattedFiles = files.map((file) => ({
      id: crypto.randomUUID(),
      originalFile: file,
      fileName: file.name,
      fileSize: formatBytes(file.size),
      status: fileStatusCodes.PENDING,
    }));

    setSelectedFiles(formattedFiles);
  };

  const removeFile = (fileId) => {
    const updatedFiles = selectedFiles.filter(({ id }) => id !== fileId);

    setSelectedFiles(updatedFiles);
  };

  const handleImport = async () => {
    setIsImporting(true);
    let filesToImport = selectedFiles;

    const errorFiles = [];
    let index = 0;

    const params = {
      clientId,
      keepOriginalFileName: true,
      type: 'invoice-control',
    };

    for (const selectedFile of selectedFiles) {
      try {
        filesToImport = filesToImport.map((file) => {
          if (file.id === selectedFile.id) {
            return {
              ...file,
              status: fileStatusCodes.IN_PROGRESS,
            };
          }

          return file;
        });

        setSelectedFiles(filesToImport);

        const { data } = await uploadService.uploadFile(selectedFile.originalFile, params);

        const link = get(data, 'fileUri', null);

        filesToImport = filesToImport.map((file) => {
          if (file.id === selectedFile.id) {
            return {
              ...file,
              link,
              status: fileStatusCodes.SUCCESS,
            };
          }

          return file;
        });

        setSelectedFiles(filesToImport);
      } catch {
        errorFiles.push(selectedFile);

        filesToImport = filesToImport.map((file) => {
          if (file.id === selectedFile.id) {
            return {
              ...file,
              status: fileStatusCodes.ERROR,
            };
          }

          return file;
        });

        setSelectedFiles(filesToImport);
      } finally {
        index++;
        setTreatedFilesCount(index);
      }
    }

    setFilesWithErrors(errorFiles);

    if (!errorFiles.length) {
      setIsImporting(false);

      try {
        const links = filesToImport.map(({ link }) => (link ? encodeURI(link) : null));

        await invoiceService.importInvoiceControlLists(
          clientId,
          selectedSupplier.id,
          selectedStore.id,
          links,
        );
      } catch {
        showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.IMPORT_FAILURE'));
      }
    }
  };

  return (
    <>
      <NavBarContainer>
        <NavigationBar
          disableGoBackButton={isImporting}
          invoiceControl={{ name: i18next.t('FEATURE.INVOICE.INVOICE_CONTROL_BATCH_IMPORT') }}
          path={path}
          bigTopBar
        />
      </NavBarContainer>
      <Container>
        <TopContainer>
          <LeftRightSplitter
            height={'66px'}
            left={
              <FilterContainer>
                <DropdownContainer>
                  <Dropdown
                    height={'66px'}
                    iconSrc={'/images/inpulse/app-black-small.svg'}
                    isDisabled={isImporting}
                    items={suppliers}
                    label={`${i18next.t('GENERAL.SUPPLIER')} :`}
                    searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                    selectedItem={selectedSupplier}
                    isRequired
                    onSelectionChange={setSelectedSupplier}
                  />
                </DropdownContainer>

                <DropdownContainer>
                  <Dropdown
                    height={'66px'}
                    iconSrc={'/images/store-black.svg'}
                    isDisabled={isImporting || selectedSupplier.id === STEF_SUPPLIER_ID}
                    items={
                      selectedSupplier.id === STEF_SUPPLIER_ID ? [automaticSelectionStore] : stores
                    }
                    label={`${i18next.t('GENERAL.STORE')} :`}
                    searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                    selectedItem={selectedStore}
                    isRequired
                    onSelectionChange={setSelectedStore}
                  />
                </DropdownContainer>

                <CreditInfoContainer>
                  <TextWithToolTipContainer>
                    <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_SMALL}>
                      {i18next.t('INVOICE.INVOICE_CONTROLS.HEADER_CREDITS_LEFT')}
                    </Text>
                    <Tooltip
                      text={i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.REMAINING_CREDITS_TOOLTIP')}
                      displayBigger
                    />
                  </TextWithToolTipContainer>

                  <Text
                    color={hasTooManyFiles ? ENUM_COLORS.INFO_RED : ENUM_COLORS.DARKEST}
                    font={ENUM_FONTS.TEXT_BIG_HEIGHT_24_BOLD}
                  >
                    {i18next.t('INVOICE.INVOICE_CONTROLS.HEADER_CREDITS_USAGE_INFO', {
                      allowed: availableCredits.invoiceVolume,
                      remaining: availableCredits.remaining,
                    })}
                  </Text>
                </CreditInfoContainer>
              </FilterContainer>
            }
            right={
              <ButtonContainer>
                <Button
                  handleClick={handleImport}
                  icon={'/images/inpulse/file-download-white-small.svg'}
                  isDisabled={isImportButtonDisabled}
                  label={i18next.t('GENERAL.IMPORT')}
                />
              </ButtonContainer>
            }
            width={'100%'}
          />
        </TopContainer>
        <ContentContainer>
          <DragAndDropContainer>
            <DragAndDropUploader
              availableCredits={availableCredits}
              filesWithErrors={filesWithErrors}
              hasEndedImport={
                !!treatedFilesCount && treatedFilesCount >= selectedFiles.length && !isImporting
              }
              hasTooManyFiles={hasTooManyFiles}
              isImporting={isImporting}
              selectedFiles={selectedFiles}
              setHasTooManyFiles={setHasTooManyFiles}
              setSelectedFiles={selectFiles}
              treatedFilesCount={treatedFilesCount}
            />
          </DragAndDropContainer>
          <InvoiceControlFilesList
            isImporting={isImporting}
            removeFile={removeFile}
            selectedFiles={selectedFiles}
          />
        </ContentContainer>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  stores: state.baseReducer.activeStores,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceControlBatchImport);
