import React from 'react';

import { LeftContainer, RightContainer, Splitter } from './styledComponents';

/**
 * As its name suggests, this component should be used whenever the need to divide horizontally an element in two.
 *
 * Usage is quite simple:
 * Import the component
 * <LeftRightSplitter left={...} right={} />
 *
 * An important point to keep in mind is that the Left and Right container are span elements.
 * @param {*} left
 * @param {*} right
 * @param {*} width
 * @returns
 */
export const LeftRightSplitter = ({ left, right, width, height }) => (
  <Splitter height={height} width={width}>
    <LeftContainer>{left}</LeftContainer>
    <RightContainer>{right}</RightContainer>
  </Splitter>
);
