import styled from 'styled-components';

export const Container = styled.div`
  .SingleDatePickerInput__disabled {
    width: ${(props) => `${props.width}px`};
    height: 40px;
    padding: 0 16px;

    border: 1px solid ${(props) => props.theme.colors.greys.light};
    border-radius: 8px;

    display: flex;

    align-items: center;
    justify-content: left;

    background-color: ${(props) => props.theme.colors.greys.light};
    cursor: pointer;
  }

  .DateInput {
    width: 100%;
  }
  .DateInput__disabled {
    display: flex;
  }
  .DateInput_input {
    font: ${(props) => props.theme.fonts.textSmallHeight16} !important;
    color: ${(props) => props.theme.colors.white} !important;
    cursor: not-allowed;
    border: 0;
  }

  .SingleDatePickerInput__disabled {
    gap: 8px;
  }

  .SingleDatePickerInput_calendarIcon {
    padding: 0;
    margin: 0;
  }
`;
