import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import { ConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import useLocalStorage from '@hooks/useLocalStorage';

import { getClientInfo } from '@selectors/client';

import storageAreaService from '@services/storageArea';

import { Container, ListViewContainer } from './styledComponents';
import actionsUtils from './utils/actionsUtils';
import columnsUtils from './utils/columnsUtils';
import exportUtils from './utils/exportUtils';

const StorageAreas = (props) => {
  const {
    match: { path },
    history,
    client: { clientId },
    pageLoading,
    pageLoaded,
    showErrorMessage,
    showSuccessMessage,
    openGenericModal,
  } = props;

  const [isInCentralKitchenMode] = useLocalStorage('isCentralMode', false);

  const [isLoading, setIsLoading] = useState(true);
  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [columns] = useState(columnsUtils.getListColumns(isInCentralKitchenMode));
  const [displayEmptyState, setDisplayEmptyState] = useState(false);

  const [storageAreas, setStorageAreas] = useState([]);
  const [selectedStorageAreas, setSelectedStorageAreas] = useState([]);

  /** USE EFFECT */

  useEffect(() => {
    (async () => {
      await getStorageAreas();
    })();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setDisplayEmptyState(!storageAreas.length);
    }
  }, [isLoading, storageAreas]);

  useEffect(() => {
    setActions(
      actionsUtils.getGlobalActions(
        goToCreateStorageArea,
        selectedStorageAreas,
        storageAreas,
        handleDeleteStorageAreas,
        exportStorageAreas,
      ),
    );

    setRowActions(actionsUtils.getRowActions(handleDeleteStorageAreas));
  }, [storageAreas, selectedStorageAreas]);

  /** FUNCTIONS */

  const getStorageAreas = async () => {
    pageLoading();

    try {
      const storageAreas = await storageAreaService.getStorageAreasByClientId(clientId, {
        filterByUserCatalog: true,
      });
      const formattedStorageAreas = storageAreas.map(
        ({ id, name, supplierProductIds, salesPointEntityIds, centralKitchenEntityIds }) => ({
          id,
          name,
          associatedSPsCount: supplierProductIds.length,
          associatedSalesPointRecipeCount: salesPointEntityIds.length,
          associatedCentralKitchenRecipeCount: centralKitchenEntityIds.length,
        }),
      );

      setStorageAreas(formattedStorageAreas);
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORAGE_AREAS.FETCHING_ERROR'));
    } finally {
      setIsLoading(false);
      pageLoaded();
    }
  };

  const deleteStorageAreas = async (storageAreas) => {
    pageLoading();
    setIsLoading(true);

    try {
      const storageAreaIds = storageAreas.map(({ id }) => id);
      await storageAreaService.disableStorageAreas(clientId, storageAreaIds);

      showSuccessMessage(
        i18next.t('ADMIN.STORAGE_AREAS.DELETION_SUCCESS', { count: storageAreas.length }),
      );

      await getStorageAreas();
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORAGE_AREAS.DELETION_ERROR'));
    } finally {
      setIsLoading(false);
      pageLoaded();
    }
  };

  const goToCreateStorageArea = () => {
    history.push('/admin/inventories/storage-areas/create');
  };

  const goToStorageAreaDetailsPage = (storageArea) => {
    history.push(`/admin/inventories/storage-areas/${storageArea.id}/details`);
  };

  const handleDeleteStorageAreas = (items) => {
    const count = items.length;

    const params = {
      type: 'warning',
      width: '542px',
      height: 'auto',
      icon: '/images/inpulse/warning-white-small.svg',
      title: i18next.t('ADMIN.STORAGE_AREAS.LABEL_DELETE_ACTION', {
        count,
      }),
      component: ConfirmationModal,
      data: {
        content: i18next.t('ADMIN.STORAGE_AREAS.DELETE_CONFIRMATION_MESSAGE', {
          count,
          name: get(items, '[0].name', ''),
        }),
      },
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: async () => await deleteStorageAreas(items),
        },
      ],
    };
    openGenericModal(params);
  };

  const exportStorageAreas = () => {
    exportUtils.exportStorageAreas(
      selectedStorageAreas.length ? selectedStorageAreas : storageAreas,
    );
  };

  const renderNoStorageAreas = () => (
    <EmptyState
      label={i18next.t('ADMIN.STORAGE_AREAS.EMPTY_STATE_TITLE')}
      labelColor={ENUM_COLORS.IP_BLACK_1}
      labelFont={ENUM_FONTS.H2_INTER}
      renderActionButton={() => (
        <Button
          color={'inpulse-default'}
          handleClick={goToCreateStorageArea}
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('GENERAL.CREATE')}
        />
      )}
      subtitle={i18next.t('ADMIN.STORAGE_AREAS.EMPTY_STATE_SUBTITLE')}
      subtitleMargin={'8px'}
    />
  );

  return (
    <ListViewContainer>
      <NavigationBreadCrumb featurePath={path} />
      <Container>
        {displayEmptyState ? (
          renderNoStorageAreas()
        ) : (
          <ListView
            actionOnClick={goToStorageAreaDetailsPage}
            actions={!isLoading && actions}
            columns={columns}
            data={storageAreas}
            isLoading={isLoading}
            padding={STANDARD_LISTVIEW_PADDING}
            renderEmptyState={() => <EmptyState />}
            rowActions={rowActions}
            setSelectedItems={setSelectedStorageAreas}
          />
        )}
      </Container>
    </ListViewContainer>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageAreas);
