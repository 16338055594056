import i18next from 'i18next';
import onClickOutside from 'react-onclickoutside';
import React, { Component } from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

export class ProductionWarningModal extends Component {
  componentDidMount = () => {
    document.body.addEventListener('keypress', this.handleKeyPress);
  };

  componentWillUnmount = () => {
    document.body.removeEventListener('keypress', this.handleKeyPress);
  };

  handleClickOutside = () => {
    this.props.cancelConfirmation();
  };

  formatWarningName = (tot, operationType) => {
    if (operationType === 'production') {
      return `${tot} unités produites`;
    } else if (operationType === 'stock') {
      return `${tot} unités en stock`;
    } else if (operationType === 'loss') {
      return `${tot} unités perdues`;
    }
  };

  render() {
    const { cancelConfirmation, handleConfirmation, product, tot, operationType } = this.props;

    return (
      <div className={'modal-confirm-upload-suppliers'}>
        <div className={'modal-mappings-container'} style={{ width: '600px', marginTop: '30vh' }}>
          <div className={'modal-error-header-orange'}>
            {this.formatWarningName(tot, operationType)}
          </div>
          <div className="modal-upload-suppliers-content">
            <p>{`${this.formatWarningName(tot, operationType)} de "${
              product.name
            }", souhaitez-vous confirmer ?`}</p>
          </div>
          <div className="conf-down-buttons">
            <Button
              buttonCustomStyle={{ marginLeft: 30 }}
              color={'inpulse-outline'}
              handleClick={() => cancelConfirmation()}
              icon={'/images/inpulse/close-black-small.svg'}
              label={i18next.t('GENERAL.CANCEL')}
            />
            <Button
              buttonCustomStyle={{ marginLeft: 30 }}
              color={'orange'}
              handleClick={() => handleConfirmation()}
              icon={'/images/inpulse/check-white-small.svg'}
              label={i18next.t('GENERAL.CONFIRM')}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default onClickOutside(ProductionWarningModal);
