import { connect } from 'react-redux';
import { get } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

// ACTIONS
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// COMMONS
import { buildSchema } from '@commons/GenericForm';
import { getTheme } from '@commons/utils/theme';
import Footer from '@commons/Footer/Footer';
import NavigationBar from '@commons/NavigationBar';
import Text, { ENUM_FONTS } from '@commons/Text';

// SELECTORS
import { canSeeAllCatalogs } from '@selectors/user';
import { getClientInfo } from '@selectors/client';

// SERVICES
import catalogService from '@services/catalogs';
import storageAreaService from '@services/storageArea';

import navigationUtils from '@admin/inventories/utils/navigation.utils';

import { STORAGE_AREA_FORM_INPUTS, FIELDS_TO_CHECK } from '../utils/formInputsConfigurations';
import StorageAreaInformations from '../storageAreaInformations';
import StorageAreaMappings from '../storageAreaMappings';
import StorageAreaRecipeMappings from '../storageAreaMappings/recipeMappings';

// STYLE
import {
  ButtonsContainer,
  ContentContainer,
  LabelContainer,
  LabelIcon,
  PageContainer,
  Step,
  StepsContainer,
} from './styledComponents';

const STEP_1_DETAILS = 'details';
const STEP_2_SUPPLIER_PRODUCT_MAPPING = 'supplierProductMapping';
const STEP_3_RECIPE_MAPPING = 'recipeMapping';

const StorageAreaCreation = (props) => {
  const {
    match: { path },
    client: { clientId, hasLocalCatalogs },
    history,
    showSuccessMessage,
    showErrorMessage,
    pageLoading,
    pageLoaded,
    user,
  } = props;

  const theme = getTheme();

  const STEPS_STORAGE_AREA_CREATION = [
    { key: STEP_1_DETAILS, label: i18next.t('GENERAL.INFORMATIONS') },
    {
      key: STEP_2_SUPPLIER_PRODUCT_MAPPING,
      label: i18next.t('GENERAL.SUPPLIER_PRODUCT_PLURAL'),
    },
    {
      key: STEP_3_RECIPE_MAPPING,
      label: i18next.t('GENERAL.RECIPE_PLURAL'),
    },
  ];
  const STEPS_MAX_INDEX = STEPS_STORAGE_AREA_CREATION.length - 1;

  const storageAreaForm = useForm({
    defaultValues: {},
    resolver: yupResolver(buildSchema(STORAGE_AREA_FORM_INPUTS())),
  });

  const formFields = useWatch({
    control: storageAreaForm.control,
  });

  const [isLoading, setIsLoading] = useState(true);

  const [isSaveAlreadyTriggered, setIsSaveAlreadyTriggered] = useState(false);
  const [currentStep, setCurrentStep] = useState(STEPS_STORAGE_AREA_CREATION[0].key);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [catalogs, setCatalogs] = useState([]);

  const getClientCatalogs = async () => {
    pageLoading();

    if (!hasLocalCatalogs) {
      pageLoaded();
      return;
    }

    try {
      const clientCatalogs = await catalogService.getCatalogsByClientId(clientId);

      const filteredCatalogs = clientCatalogs.filter(({ active }) => active);

      if (!canSeeAllCatalogs(user, { hasLocalCatalogs })) {
        setCatalogs([user.catalog]);

        pageLoaded();
        return;
      }

      setCatalogs(filteredCatalogs);
    } catch {
      showErrorMessage(i18next.t('ADMIN.CATALOGS.FETCH_ERROR'));
      setCatalogs([]);
    } finally {
      pageLoaded();
    }
  };
  /** USE EFFECTS */
  useEffect(() => {
    getClientCatalogs();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      switch (currentStep) {
        case STEPS_STORAGE_AREA_CREATION[0].key:
          const fieldsValidation = await storageAreaForm.trigger(FIELDS_TO_CHECK);
          setIsSaveDisabled(!fieldsValidation && isSaveAlreadyTriggered);
          break;
        case STEPS_STORAGE_AREA_CREATION[1].key:
          setIsSaveDisabled(false);
          break;
        default:
          return;
      }
    })();
  }, [formFields]);

  /** FUNCTIONS */

  const returnToStorageAreasList = () => history.goBack();

  const createStorageArea = async () => {
    pageLoading();

    const storageAreaName = get(formFields, 'name', '');
    const spMappingsToBeCreated = get(formFields, 'supplierProductMappings', []);
    const recipeMappingsToBeCreated = get(formFields, 'recipeMappings', []);

    const supplierProductIds = spMappingsToBeCreated.map(
      ({ supplierProductId }) => supplierProductId,
    );
    const entityIds = recipeMappingsToBeCreated.map(({ entityId }) => entityId);

    try {
      await storageAreaService.createStorageArea(
        clientId,
        storageAreaName,
        supplierProductIds,
        entityIds,
      );
      showSuccessMessage(i18next.t('ADMIN.STORAGE_AREAS.CREATION_SUCCESS'));

      pageLoaded();
      returnToStorageAreasList();
    } catch {
      pageLoaded();
      showErrorMessage(i18next.t('ADMIN.STORAGE_AREAS.CREATION_FAILURE'));
    }
  };

  const nextStepHandler = async () => {
    const fieldsValidation = await storageAreaForm.trigger(FIELDS_TO_CHECK);

    switch (currentStep) {
      case STEPS_STORAGE_AREA_CREATION[0].key:
        setIsSaveAlreadyTriggered(true);

        if (!fieldsValidation) {
          setIsSaveDisabled(true);

          return;
        }

        break;
      case STEPS_STORAGE_AREA_CREATION[1].key:
      case STEPS_STORAGE_AREA_CREATION[2].key:
        setIsSaveDisabled(false);

        break;
      default:
    }

    const currentStepIndex = STEPS_STORAGE_AREA_CREATION.findIndex(
      ({ key }) => key === currentStep,
    );

    if (currentStepIndex < STEPS_MAX_INDEX) {
      setCurrentStep(STEPS_STORAGE_AREA_CREATION[currentStepIndex + 1].key);
      setIsSaveAlreadyTriggered(false);
    }

    if (currentStepIndex === STEPS_MAX_INDEX) {
      setIsSaveDisabled(true);
      await createStorageArea();
    }
  };

  const previousStepHandler = () => {
    setIsSaveDisabled(false);

    const currentStepIndex = STEPS_STORAGE_AREA_CREATION.findIndex(
      ({ key }) => key === currentStep,
    );

    if (currentStepIndex > 0) {
      setCurrentStep(STEPS_STORAGE_AREA_CREATION[currentStepIndex - 1].key);
    }

    if (currentStepIndex === 0) {
      returnToStorageAreasList();
    }
  };

  if (isLoading) {
    return (
      <>
        <NavigationBar displaySubFeatures={false} path={path} bigTopBar enableActionBottomOrder />
      </>
    );
  }

  return (
    <>
      <NavigationBar
        displaySubFeatures={false}
        path={path}
        storageArea={{ name: i18next.t('ADMIN.STORAGE_AREAS.CREATE_TITLE') }}
        bigTopBar
        enableActionBottomOrder
      />
      <PageContainer>
        <ContentContainer>
          {currentStep === STEPS_STORAGE_AREA_CREATION[0].key && (
            <StorageAreaInformations
              isSaveAlreadyTriggered={isSaveAlreadyTriggered}
              storageAreaForm={storageAreaForm}
            />
          )}
          {currentStep === STEPS_STORAGE_AREA_CREATION[1].key && (
            <StorageAreaMappings
              catalogs={catalogs}
              formFields={formFields}
              isLoading={false}
              storageAreaForm={storageAreaForm}
              isCreation
            />
          )}
          {currentStep === STEPS_STORAGE_AREA_CREATION[2].key && (
            <StorageAreaRecipeMappings
              formFields={formFields}
              isLoading={false}
              storageAreaForm={storageAreaForm}
              isCreation
            />
          )}
        </ContentContainer>
        <Footer>
          <StepsContainer>
            {STEPS_STORAGE_AREA_CREATION.map(({ key, label }, index) => (
              <Step key={`step-${index}`}>
                <LabelContainer isActive={currentStep === key}>
                  <LabelIcon isActive={currentStep === key}>{index + 1}</LabelIcon>
                  <Text
                    children={label}
                    color={
                      currentStep === key
                        ? theme.colors.modalHeaders.green.text
                        : theme.colors.greys.dark
                    }
                    font={ENUM_FONTS.TEXT_MIDDLE_BOLD}
                  />
                </LabelContainer>
                {index !== STEPS_STORAGE_AREA_CREATION.length - 1 && (
                  <img src={'/images/inpulse/chevron-right-dmgrey-small.svg'} />
                )}
              </Step>
            ))}
            <ButtonsContainer>
              {navigationUtils.renderPreviousButton(
                currentStep === STEP_1_DETAILS,
                previousStepHandler,
              )}
              {navigationUtils.renderNextButton(
                currentStep === STEP_3_RECIPE_MAPPING, // TODO [Admin Recipe SA] - Update with STEP_3 when inventories with recipes are deployed
                isSaveDisabled,
                nextStepHandler,
              )}
            </ButtonsContainer>
          </StepsContainer>
        </Footer>
      </PageContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageAreaCreation);
