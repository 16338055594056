import styled from 'styled-components';

export const ContentInfosContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.textBigHeight16};
`;

export const Text = styled.div`
  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts?.textMiddleBold};
`;
