import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../../../utils/styledLibraryComponents';

import { Container, IconActive } from './styledComponents';

/**
 * The button to open the Filters' modal
 */
export const FiltersButton = (props) => {
  const { filtersButtonState, isDisabled, filterText, customWidth = 240 } = props;

  const { filtersModalOpen, setFiltersModalOpen, advancedFiltersApplied } = filtersButtonState;

  // Avoids redeclaring an anonymous function to call setFiltersModalOpen at every render
  const triggerModal = () => {
    setFiltersModalOpen(!filtersModalOpen);
  };

  return (
    <Container>
      <Button
        color="inpulse-filter"
        fontSize={14}
        formatText={false}
        handleClick={triggerModal}
        height={40}
        icon={'/images/inpulse/filter-black-small.svg'}
        isActive={filtersModalOpen}
        isDisabled={isDisabled}
        label={filterText}
        maxWidth={customWidth}
        minWidth={customWidth}
      />
      {/* If advanced filters are applied, add a dot in the corner of the button. */}
      {advancedFiltersApplied && (
        <IconActive
          alt="icon-filter-active"
          src={
            isDisabled
              ? '/images/icon-dot-active-grey-inpulse.svg'
              : '/images/icon-dot-active-green-inpulse.svg'
          }
        />
      )}
    </Container>
  );
};

FiltersButton.propTypes = {
  // filtersButtonState must be the result of a call to usefiltersButtonState
  filtersButtonState: PropTypes.shape({
    filtersModalOpen: PropTypes.bool.isRequired,
    setFiltersModalOpen: PropTypes.func.isRequired,
    advancedFiltersApplied: PropTypes.bool.isRequired,
    setAdvancedFiltersApplied: PropTypes.func.isRequired,
  }).isRequired,
  isDisabled: PropTypes.bool,
  filterText: PropTypes.string,
};

FiltersButton.defaultProps = {
  isDisabled: false,
  filterText: i18next.t('GENERAL.FILTER'),
};
