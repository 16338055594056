import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { DATE_PICKER_DOT_COLOR } from '@commons/DatePickers/constants';
import { Dropdown, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import { SingleDatePicker } from '@commons/DatePickers/SingleDatePicker';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import {
  Container,
  DateContainer,
  LabelContainer,
} from '../InventoryCreateInitialModal/InventoryCreateInitialSelectors/styledComponents';

const CreateTheoreticalInventorySelectors = (props) => {
  const {
    setFocusedMonth,
    selectedDate,
    setSelectedDate,
    stores,
    selectedStores,
    setSelectedStores,
    forbiddenDates,
    clientILTs,
    selectedILT,
    setSelectedILT,
    translatedStoreName,
  } = props;

  const isDatePickerDisabled = isEmpty(selectedStores);
  const hasILTs = !isEmpty(clientILTs);

  const CALENDAR_INFO = {
    dotsInformations: [
      {
        dotColor: DATE_PICKER_DOT_COLOR.RED,
        dotInfo: i18next.t('ORDERS.ORDERS.STOCK_FORM_DATEPICKER_TOOLTIP', { stockType: 'stock' }),
      },
    ],
  };

  return (
    <Container>
      <Dropdown
        customStyle={{ position: 'inherit' }}
        height={'64px'}
        isUniqueSelection={false}
        items={stores}
        label={translatedStoreName}
        selectedItems={selectedStores}
        width={INPUT_WIDTH.LARGE}
        isRequired
        onSelectionChange={setSelectedStores}
      />
      <DateContainer>
        <LabelContainer>
          <Text
            color={isDatePickerDisabled ? ENUM_COLORS.LIGHT : ENUM_COLORS.INFO_RED}
            font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}
          >
            *
          </Text>
          <Text
            color={isDatePickerDisabled ? ENUM_COLORS.LIGHT : ENUM_COLORS.DARKEST}
            font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}
          >
            {i18next.t('GENERAL.DATE')}
          </Text>
        </LabelContainer>
        <SingleDatePicker
          calendarInfo={CALENDAR_INFO}
          customStyle={{ position: 'inherit' }}
          date={selectedDate}
          disabled={isDatePickerDisabled}
          forbiddenDates={forbiddenDates}
          onDateChange={setSelectedDate}
          onNextMonthClick={setFocusedMonth}
          onPrevMonthClick={setFocusedMonth}
        ></SingleDatePicker>
      </DateContainer>

      <Dropdown
        customStyle={{ position: 'inherit', marginBottom: '24px' }}
        isDisabled={!hasILTs}
        items={clientILTs}
        label={i18next.t('GENERAL.INVENTORY_TEMPLATE')}
        selectedItem={selectedILT}
        isRequired
        onSelectionChange={setSelectedILT}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

export default connect(mapStateToProps)(CreateTheoreticalInventorySelectors);
