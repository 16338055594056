import styled from 'styled-components';

export const DisabledTooltipContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 256px;

  background-color: ${(props) => props.theme.colors.greys.dark};
  color: ${(props) => props.theme.colors.white};
  font: ${(props) => props.theme.fonts.textSmallHeight14};

  text-align: center;
  border-radius: 6px;

  padding: 8px;

  &::after {
    position: absolute;
  }
`;
