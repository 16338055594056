import { isEmpty } from 'lodash';
import { withRouter, Route, Redirect, Switch } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';

import DefaultProvider from '../context/DefaultContext';
import FiltersProvider from '../context/FiltersContext';

import { getIsCentralMode } from '../commons/utils/localStorage';
import { getProviders } from '../context/utils';
import {
  ORDERED_CENTRAL_KITCHEN_NAV_TREE,
  ORDERED_NAV_TREE,
  getCurrentFeature,
  getFirstFeature,
  getNavTreeKeyByPath,
} from '../commons/features';
import {
  PATHS_AVAILABLE_IN_ALL_WORKSPACES,
  HELP_CENTER_COMPONENT_NAME,
  REFERRAL_COMPONENT_NAME,
} from '../commons/constants/centralConstants';
import mixPanelUtils, { ENUM_EVENTS } from '../commons/utils/mixpanel';
import NoStoreDetector from '../commons/NoStoreDetector';
import OfflineDetector from '../commons/OfflineDetector';

import SideBar from '../commons/SideBar';

/**
 * MODULES
 */
import { AdminModule } from './admin';

import BackOfficeModule from './backoffice';
import DashboardModule from './dashboard';
import ForecastModule from './forecast';
import InvoiceModule from './invoice';
import LossesModule from './losses';
import MarginAnalyticsModule from './margin';
import OrdersModule from './orders';
import ProductionModule from './prod';
import StocksModule from './stocks';
import UsersModule from './users';

/**
 * CENTRAL KITCHEN MODULES
 */
import { CentralKitchenAdminModule } from './admin';
import CentralKitchenOrderModule from './orders/centralKitchenOrder';
import CentralKitchenProductionModule from './prod/centralKitchenProduction';
import CentralKitchenStocksModule from './stocks/centralKitchenStocks';

import { DETAILS } from '../commons/features/salesPointNavigation/subNavigation/user';

const components = {
  // MODULES
  ...UsersModule,
  ...AdminModule,
  ...OrdersModule,
  ...StocksModule,
  ...LossesModule,
  ...ForecastModule,
  ...DashboardModule,
  ...ProductionModule,
  ...BackOfficeModule,
  ...InvoiceModule,
  ...MarginAnalyticsModule,
  // CENTRALE MODULES
  ...CentralKitchenAdminModule,
  ...CentralKitchenStocksModule,
  ...CentralKitchenOrderModule,
  ...CentralKitchenProductionModule,
};

export class ClientContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      features: [],
      adminFeatures: [],
      defaultPath: '/dashboard/food-cost',
      currentProvider: { Component: DefaultProvider },
    };
  }

  componentWillMount() {
    this.props.history.listen(() => {
      this.setState({
        mainPath: this.props.history.location.pathname.split('/').slice(0, 2).join('/'),
      });
      this.handleProviderChange();
    });
  }

  getDefaultPath(features) {
    const defaultPath = '/dashboard/food-cost';

    for (const feature of features) {
      if (feature.isDefaultPath) {
        return feature.path;
      }
    }

    if (!features.some((feature) => feature.path === defaultPath)) {
      const mainFeature = getFirstFeature(
        features,
        getIsCentralMode() ? ORDERED_CENTRAL_KITCHEN_NAV_TREE : ORDERED_NAV_TREE,
      );

      if (isEmpty(mainFeature)) {
        return defaultPath;
      }

      if (mainFeature.path !== DETAILS.path) {
        return mainFeature.path;
      }

      return mainFeature.getLink({ profileUser: this.props.user });
    }

    return defaultPath;
  }

  handleProviderChange = () => {
    const provider = getProviders(this.props.history.location.pathname);

    if (this.state.currentProvider.path !== provider.path) {
      this.setState({
        currentProvider: provider,
      });
    }
  };

  initialiseData() {
    const { userRights } = this.props;

    let features = [],
      adminFeatures = [],
      filteredFeatures = [];

    if (userRights) {
      features = userRights;

      adminFeatures = userRights.filter((feature) => feature.icon === undefined);

      // Only "isCentral" features can be accessed by central kitchen mode (modulo PATHS_AVAILABLE_IN_ALL_WORKSPACES)
      filteredFeatures = features.filter(
        ({ isCentral, path, component }) =>
          isCentral === getIsCentralMode() ||
          (PATHS_AVAILABLE_IN_ALL_WORKSPACES.includes(path.split('/')[1]) &&
            (getIsCentralMode() || !isCentral)) ||
          component === HELP_CENTER_COMPONENT_NAME ||
          component === REFERRAL_COMPONENT_NAME,
      );
    }

    const defaultPath = this.getDefaultPath(filteredFeatures);

    this.handleProviderChange();

    this.setState({
      features: filteredFeatures,
      adminFeatures,
      defaultPath,
    });
  }

  componentDidMount() {
    this.setState({
      mainPath: this.props.history.location.pathname.split('/').slice(0, 2).join('/'),
    });
    if (this.props.userRights) {
      this.initialiseData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userRights !== this.props.userRights) {
      this.initialiseData();
    }

    if (prevProps.location !== this.props.location) {
      mixPanelUtils.sendMetric(ENUM_EVENTS.USER_CHANGED_PAGE, { path: location.pathname });
    }

    if (prevProps.isCentralView !== this.props.isCentralView) {
      this.initialiseData();
    }
  }

  render() {
    const { features, currentProvider, defaultPath } = this.state;

    const associatedStores = this.props.stores || [];
    const associatedActiveStores = associatedStores.filter((store) => store.active);

    const CurrentProviderComponent = currentProvider.Component;

    const navTreeByPath = getNavTreeKeyByPath({
      navTree: getIsCentralMode() ? ORDERED_CENTRAL_KITCHEN_NAV_TREE : ORDERED_NAV_TREE,
    });

    const currentFeature = getCurrentFeature(navTreeByPath, this.props.location);

    return (
      <FiltersProvider>
        <CurrentProviderComponent>
          <div className={`global-container ${this.props.className}`}>
            <SideBar
              client={this.props.client}
              defaultPath={defaultPath}
              isDisabled={!this.props.user.isDeepsight && !associatedActiveStores.length}
              user={this.props.user}
            />
            <div
              className="main-container"
              style={{
                width: currentFeature && currentFeature.isFullScreen ? '100%' : 'calc(100% - 64px)',
              }}
            >
              <NoStoreDetector
                associatedActiveStores={associatedActiveStores}
                associatedStores={associatedStores}
                handleLogout={this.props.handleLogout}
              >
                <OfflineDetector
                  mainPath={this.state.mainPath}
                  path={this.props.history.location.pathname}
                >
                  {features.length !== 0 && (
                    <Switch>
                      {features.map((feature, index) => {
                        const hasOrder = features.some(({ component }) => component === 'Orders');
                        const hasPlanning = features.some(
                          ({ component }) => component === 'Plannings',
                        );
                        const planningTypes = hasPlanning
                          ? features.filter((e) => e.props.planningTypes)[0].props.planningTypes
                          : [];

                        let ComponentName = components[feature.component];
                        let featureName = '';
                        if (feature.path === '/production/analytics/by-store') {
                          featureName = 'GENERAL.LABEL_BY';
                        } else {
                          featureName = feature.translationKey;
                        }

                        return (
                          <Route
                            key={index}
                            path={feature.path}
                            render={
                              ComponentName
                                ? (routerProps) => (
                                    <ComponentName
                                      {...feature.props}
                                      {...routerProps}
                                      adminFeatures={this.state.adminFeatures}
                                      handleLogout={this.props.handleLogout}
                                      hasOrder={hasOrder}
                                      hasPlanning={hasPlanning}
                                      history={this.props.history}
                                      key={index}
                                      planningTypes={planningTypes}
                                      stores={this.props.stores}
                                      title={this.props.t(featureName)}
                                      user={this.props.user}
                                      userRights={this.props.userRights}
                                    />
                                  )
                                : () => null
                            }
                            exact
                          />
                        );
                      })}
                      <Route path="/">
                        <Redirect to={defaultPath} />
                      </Route>
                    </Switch>
                  )}
                </OfflineDetector>
              </NoStoreDetector>
            </div>
          </div>
        </CurrentProviderComponent>
      </FiltersProvider>
    );
  }
}

export default withTheme(withRouter(withTranslation()(ClientContainer)));
