import styled, { css } from 'styled-components';
import ColorPalette from '../../../utils/colorPalette';

export const Button = styled.div`
  position: relative;

  width: 24px;
  height: 24px;
  background-color: ${ColorPalette.LGrey};
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.isModalOpen
      ? css`
          border: 1px solid ${ColorPalette.IP_DGreen};
        `
      : css`
          border: 1px solid ${ColorPalette.LMGrey};
        `};
`;

export const IconContainer = styled.img``;

export const ModalContainer = styled.div`
  position: absolute;
  top: 32px;
  width: 314px;
  background-color: ${ColorPalette.WHITE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);

  z-index: 1;
  &:hover {
    cursor: default;
  }
`;

export const TitleContainer = styled.div`
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${ColorPalette.LMGrey};
  font: normal 700 14px/24px inter;
`;

export const ContentContainer = styled.div`
  min-height: 56px;
  width: 100%;
`;

export default {
  Button,
  IconContainer,
  ModalContainer,
  TitleContainer,
  ContentContainer,
};
