import theme from '../../theme';

/**
 * Compute linear-gradient CSS property
 *
 * NOTE: We generate a property with only two color, this is why we have two different config according to the progress value
 *
 * @param {Number} progress - The progress value to takeinto consideration
 *
 * @returns {String} The computed linear CSS property
 */
export const getLinearGradientButtonForProgressStyling = (progress) => {
  if (!progress) {
    return null;
  }

  const formattedProgressValue = Math.round(progress * 100);

  const leftValue = 100 - formattedProgressValue;

  if (formattedProgressValue <= 50) {
    return `linear-gradient(-90deg, ${theme.colors.greys.light} ${leftValue}%, ${theme.colors.greys.darker} ${formattedProgressValue}%) !important`;
  }

  return `linear-gradient(90deg, ${theme.colors.greys.darker} ${formattedProgressValue}%, ${theme.colors.greys.light} ${leftValue}%) !important`;
};

export default {
  getLinearGradientButtonForProgressStyling,
};
