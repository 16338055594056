import styled from 'styled-components';

export const Container = styled.div`
  height: 40px;
  width: 40px;
  border: 1px solid ${(props) => props.border};
  border-radius: 8px;
  background: ${(props) =>
    !props.isDisabled ? props.theme.colors.greys.lightest : props.theme.colors.greys.light};

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;

export const Icon = styled.img`
  position: absolute;
`;

export const Tooltip = styled.div`
  position: relative;
  width: 240px;
  height: 40px;
  padding: 4px 16px;
  top: 50px;
  left: 100px;

  font: ${(props) => props.theme.fonts.textSmallHeight16};
  color: ${(props) => props.theme.colors.greys.darker};

  background-color: ${(props) => props.theme.colors.greys.lightest};
  border-radius: 8px;

  box-shadow: 0px 4px 8px 0px #00000029;
`;
