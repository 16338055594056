import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

// ACTIONS
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// COMMONS
import { Button } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { ENUM_MODULE_NAME } from '@commons/utils/features';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getTheme } from '@commons/utils/theme';
import { sortPackagings, formatNewItemsOfDropdownSelection } from '@commons/utils/format';
import Footer from '@commons/Footer/Footer';
import NavigationBar from '@commons/NavigationBar';
import Text, { ENUM_FONTS } from '@commons/Text';

// SELECTORS
import { getClientInfo } from '@selectors/client';

// SERVICES
import { supplierProduct as supplierProductService } from '@services/supplierProduct';
import { supplier as supplierService } from '@services/supplier';
import { upload as uploadService } from '@services/upload';

import { handleNewDataCreation } from '../utils/creations';
import SupplierProductDetails from '../details/supplierProductInformations/components/SupplierProductDetails';
import SupplierProductMappings from '../details/supplierProductStores/components/SupplierProductMappings';

import {
  ButtonsContainer,
  ContentContainer,
  LabelContainer,
  LabelIcon,
  PageContainer,
  Step,
  StepsContainer,
} from './styledComponents';

export const SupplierProductCreation = (props) => {
  const {
    match: { path },
    client: { storeName, clientId },
    currency,
    location,
    pageLoaded,
    pageLoading,
    history,
    showSuccessMessage,
    showErrorMessage,
  } = props;

  const theme = getTheme();

  const clientStoreNamePlural = getClientStoreNameTranslation(storeName, true);

  const STEPS_SUPPLIER_PRODUCT_CREATION = [
    { key: 'details', label: i18next.t('GENERAL.INFORMATIONS') },
    { key: 'storeMapping', label: clientStoreNamePlural },
  ];

  const [form, setForm] = useState(useForm());

  const [supplierProduct, setSupplierProduct] = useState({
    currency,
    active: true,
    category: null,
    customPrices: [],
    defaultStockUnit: null,
    entity: {},
    entityId: null,
    id: null,
    img: null,
    loss: undefined,
    manufacturerName: null,
    name: undefined,
    packagingName: null,
    packagingUnit: null,
    packagingValue: null,
    packagingWeight: undefined,
    packagings: [],
    conversions: [],
    price: undefined,
    sku: undefined,
    skuName: null,
    subCategory: null,
    supplier: null,
    supplierId: null,
    vatRate: undefined,
    ean: null,
  });

  const [isLoading, setIsLoading] = useState(true);

  const [updatedSupplierProduct, setUpdatedSupplierProduct] = useState({});
  const [currentStep, setCurrentStep] = useState(STEPS_SUPPLIER_PRODUCT_CREATION[0].key);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaveAlreadyTriggered, setIsSaveAlreadyTriggered] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [mappingsToCreate, setMappingsToCreate] = useState(null);
  const [createdCategories, setCreatedCategories] = useState([]);
  const [createdSubCategories, setCreatedSubCategories] = useState([]);
  const [createdStorageAreas, setCreatedStorageAreas] = useState([]);
  const [createdInventoryListTemplates, setCreatedInventoryListTemplates] = useState([]);

  useEffect(() => {
    // Early return when fetching child component Details data is done
    if (!isLoading) {
      return;
    }

    if (!location.supplierProductId) {
      setIsLoading(false);

      return;
    }

    (async () => {
      // Page loaded in children component SupplierProductDetails
      pageLoading();

      try {
        const fetchedSupplierProductId = location.supplierProductId;

        const supplierProductWithPackaging = await supplierProductService.getSingleSupplierProduct(
          fetchedSupplierProductId,
        );

        // Format packagings and remove ids to make sure that SPP.id are not preserved and avoid any unwanted manipulation in server side
        const packagings = sortPackagings(supplierProductWithPackaging.packagings).map(
          (packaging, index) => {
            const fakeId = index + 1;
            return {
              ...packaging,
              id: fakeId,
              parentSupplierProductPackagingId: !index ? null : index, // index is always behind by one from fakeId
              masterSupplierProductPackagingId: !index ? null : 1,
            };
          },
        );

        const supplierProductToCreate = {
          ...supplierProductWithPackaging,
          currency: {
            ...supplierProductWithPackaging.currency,
            name: `${supplierProductWithPackaging.currency.name} (${supplierProductWithPackaging.currency.alphabeticCode})`,
          },
          entity: !isEmpty(supplierProductWithPackaging.entity)
            ? supplierProductWithPackaging.entity
            : {},
          entityName:
            supplierProductWithPackaging.entity.Name === '-'
              ? null
              : supplierProductWithPackaging.entity.Name,
          packagings,
          name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DUPLICATE_SUPPLIER_PRODUCT_NAME', {
            supplierProductName: supplierProductWithPackaging.name,
          }),
        };

        setSupplierProduct(supplierProductToCreate);
        setUpdatedSupplierProduct(supplierProductToCreate);
      } catch {
        showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.SINGLE_FETCH_ERROR'));
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const fieldsValidation = await form.trigger();
      setIsSaveDisabled(
        (!fieldsValidation || isEmpty(updatedSupplierProduct.packagings)) && isSaveAlreadyTriggered,
      );
    })();
  }, [updatedSupplierProduct]);

  const validateForm = async () => {
    const fieldsValidation = await form.trigger();

    setIsSaveAlreadyTriggered(true);

    // Early return in case form is not valid
    if (!fieldsValidation || isEmpty(updatedSupplierProduct.packagings)) {
      setIsSaveDisabled(true);

      return;
    }

    await formatSupplierProduct();

    setCurrentStep(STEPS_SUPPLIER_PRODUCT_CREATION[1].key);
  };

  const redirectToSupplierProductList = () => history.goBack();

  const uploadPicture = async () => {
    const params = {
      clientId,
      // Add timestamp to avoid delay between two changes
      supplierProductId: `${supplierProduct.id}_${moment().format(
        DATE_DISPLAY_FORMATS.UNDERSCORED_MONTH_DAY_HOUR_MINUTE_SECOND,
      )}`,
    };

    const newUrl = await uploadService.uploadFile(
      updatedSupplierProduct.selectedPictureFile,
      params,
    );

    return newUrl;
  };

  const formatSupplierProduct = async () => {
    pageLoading();

    try {
      if (!!updatedSupplierProduct.supplier.id) {
        setSelectedSupplier(updatedSupplierProduct.supplier);
        updatedSupplierProduct.supplierId = updatedSupplierProduct.supplier.id;
      }

      if (!!updatedSupplierProduct.category.value) {
        updatedSupplierProduct.category = updatedSupplierProduct.category.value;
      }

      if (!!updatedSupplierProduct.subCategory.value) {
        updatedSupplierProduct.subCategory = updatedSupplierProduct.subCategory.value;
      }

      if (!!updatedSupplierProduct.selectedPictureFile) {
        const url = await uploadPicture();

        updatedSupplierProduct.img = get(url, 'data.fileUri', supplierProduct.img);
      }
      setSupplierProduct(updatedSupplierProduct);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.UPDATE_SUPPLIER_PRODUCT_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const createSupplierProductWithMappings = async () => {
    pageLoading();

    try {
      const { inputs: inputPrices } = await supplierService.getSupplierPriceInputs(
        clientId,
        supplierProduct.supplierId,
      );

      const customPricesPayload = inputPrices.reduce((res, { id }) => {
        if (!!supplierProduct[id]) {
          res.push({
            price: supplierProduct[id] === '' ? null : supplierProduct[id],
            inputId: id,
          });
        }

        return res;
      }, []);

      const formattedMappingsToCreate = !!mappingsToCreate
        ? mappingsToCreate.map((mapping) => ({
            ...mapping,
            id: null,
            price: supplierProduct.price,
          }))
        : [];

      const supplierProductPayload = {
        ...supplierProduct,
        id: null,
        conversions: supplierProduct.conversions,
        entityId: isEmpty(supplierProduct.entity) ? null : supplierProduct.entity.id,
        packagingWeight:
          supplierProduct.packagingWeight === '' ? null : supplierProduct.packagingWeight,
        sku: supplierProduct.sku === '' ? null : supplierProduct.sku,
        loss: supplierProduct.loss === '' ? null : supplierProduct.loss,
        price: supplierProduct.price === '' ? null : supplierProduct.price,
        vatRate: supplierProduct.vatRate === '' ? null : supplierProduct.vatRate,
        priceEditableAtReception: supplierProduct.priceEditableAtReception,
        inventoryListTemplates: formatNewItemsOfDropdownSelection(
          supplierProduct.inventoryListTemplates,
        ),
        storageAreas: formatNewItemsOfDropdownSelection(supplierProduct.storageAreas),
      };

      await supplierProductService.createSupplierProduct(
        supplierProductPayload,
        formattedMappingsToCreate,
        customPricesPayload,
      );

      redirectToSupplierProductList();

      showSuccessMessage(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.CREATE_SUPPLIER_PRODUCT_SUCCESS', {
          count: formattedMappingsToCreate.length,
          storeName,
          storeCount: formattedMappingsToCreate.length,
        }),
      );
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.CREATE_SUPPLIER_PRODUCT_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  if (isLoading) {
    return (
      <>
        <NavigationBar
          bigTopBar={true}
          displaySubFeatures={false}
          enableActionBottomOrder={true}
          module={ENUM_MODULE_NAME.SUPPLIER_PRODUCT_DETAIL}
          path={path}
          storeName={storeName}
          supplierProduct={{
            name:
              supplierProduct.name ||
              i18next.t('ADMIN.SUPPLIER_PRODUCTS.CREATE_SUPPLIER_PRODUCT_TITLE'),
          }}
        />
      </>
    );
  }

  return (
    <>
      <NavigationBar
        bigTopBar={true}
        displaySubFeatures={false}
        enableActionBottomOrder={true}
        module={ENUM_MODULE_NAME.SUPPLIER_PRODUCT_DETAIL}
        path={path}
        storeName={storeName}
        supplierProduct={{
          name:
            supplierProduct.name ||
            i18next.t('ADMIN.SUPPLIER_PRODUCTS.CREATE_SUPPLIER_PRODUCT_TITLE'),
        }}
      />
      <PageContainer>
        <ContentContainer>
          {currentStep === STEPS_SUPPLIER_PRODUCT_CREATION[0].key && (
            <SupplierProductDetails
              createdCategories={createdCategories}
              createdInventoryListTemplates={createdInventoryListTemplates}
              createdStorageAreas={createdStorageAreas}
              createdSubCategories={createdSubCategories}
              handleNewDataCreation={(item, type) =>
                handleNewDataCreation(item, type, {
                  createdCategories,
                  setCreatedCategories,
                  createdSubCategories,
                  setCreatedSubCategories,
                  createdStorageAreas,
                  setCreatedStorageAreas,
                  createdInventoryListTemplates,
                  setCreatedInventoryListTemplates,
                })
              }
              isCreation={true}
              isDuplication={!!location.supplierProductId}
              isSaveAlreadyTriggered={isSaveAlreadyTriggered}
              setForm={setForm}
              setMappingsToCreate={setMappingsToCreate}
              setUpdatedSupplierProduct={setUpdatedSupplierProduct}
              supplierProduct={supplierProduct}
            />
          )}
          {currentStep === STEPS_SUPPLIER_PRODUCT_CREATION[1].key && !!selectedSupplier.id && (
            <SupplierProductMappings
              isCreation={true}
              mappingsToCreate={mappingsToCreate}
              setMappingsToCreate={setMappingsToCreate}
              supplierProduct={{ ...supplierProduct, supplierId: selectedSupplier.id }}
            />
          )}
        </ContentContainer>
        <Footer>
          <StepsContainer>
            {STEPS_SUPPLIER_PRODUCT_CREATION.map((step, index) => (
              <Step key={`step-${index}`}>
                <LabelContainer isActive={currentStep === step.key}>
                  <LabelIcon isActive={currentStep === step.key}>{index + 1}</LabelIcon>
                  <Text
                    children={step.label}
                    color={
                      currentStep === step.key
                        ? theme.colors.modalHeaders.green.text
                        : theme.colors.greys.dark
                    }
                    font={ENUM_FONTS.TEXT_MIDDLE_BOLD}
                  />
                </LabelContainer>
                {index !== STEPS_SUPPLIER_PRODUCT_CREATION.length - 1 && (
                  <img src={'/images/inpulse/chevron-right-dmgrey-small.svg'} />
                )}
              </Step>
            ))}
          </StepsContainer>
          {currentStep === STEPS_SUPPLIER_PRODUCT_CREATION[0].key && (
            <ButtonsContainer>
              <Button
                color={'inpulse-outline'}
                handleClick={() => {
                  redirectToSupplierProductList();
                }}
                icon={'/images/inpulse/close-black-small.svg'}
                label={i18next.t('GENERAL.CANCEL')}
              />
              <Button
                color={'inpulse-default'}
                handleClick={() => {
                  validateForm();
                }}
                icon={'/images/inpulse/arrow-right-white.svg'}
                isDisabled={isSaveDisabled}
                label={i18next.t('GENERAL.NEXT')}
              />
            </ButtonsContainer>
          )}
          {currentStep === STEPS_SUPPLIER_PRODUCT_CREATION[1].key && !!selectedSupplier.id && (
            <ButtonsContainer>
              <Button
                color={'inpulse-outline'}
                handleClick={() => {
                  setCurrentStep(STEPS_SUPPLIER_PRODUCT_CREATION[0].key);
                }}
                icon={'/images/inpulse/arrow-left-ip-black.svg'}
                label={i18next.t('GENERAL.BACK')}
              />
              <Button
                color={'inpulse-default'}
                handleClick={() => {
                  createSupplierProductWithMappings();
                }}
                icon={'/images/inpulse/save-white-small.svg'}
                label={i18next.t('GENERAL.SAVE')}
              />
            </ButtonsContainer>
          )}
        </Footer>
      </PageContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierProductCreation);
