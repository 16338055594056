import PropTypes from 'prop-types';
import React from 'react';

import { ENUM_MODULE_NAME } from '@commons/utils/features';

import { MARGIN_FEATURE_PATHS } from '@commons/utils/featurePaths';

import { ModuleContainer } from '../ModuleContainer';

export const MarginAnalyticsContainer = (props) => {
  const { userRights, history } = props;

  return (
    <ModuleContainer
      history={history}
      moduleFeaturePaths={[MARGIN_FEATURE_PATHS.MARGIN_ANALYTICS]}
      moduleName={ENUM_MODULE_NAME.MARGIN_ANALYTICS}
      userRights={userRights}
    />
  );
};

MarginAnalyticsContainer.propTypes = {
  userRights: PropTypes.array,
  history: PropTypes.any, // react-router-dom's route prop
  moduleName: PropTypes.oneOf(Object.values(ENUM_MODULE_NAME)),
  moduleFeaturePaths: PropTypes.arrayOf(PropTypes.string),
};

import MarginAnalyticsByStore from './ByStore';
import MarginEvolution from './Evolution';

const MarginAnalyticsModule = {
  MarginAnalyticsContainer,

  MarginAnalyticsByStore,
  MarginEvolution,
};

export default MarginAnalyticsModule;
