import { connect } from 'react-redux';
import i18next from 'i18next';
import React from 'react';

import { ActionFrame } from './styledComponents';

import { loading, loadingSuccess, loadingFailure } from '@actions/loading';

import { Dropdown } from '@commons/utils/styledLibraryComponents';

import { getClientInfo } from '@selectors/client';

import recipeService from '@services/recipe';

import { getSearchableCompositions } from '@admin/utils/getSearchableCompositions';

import { getWarningModalParams } from '@admin/products/recipes/detail/utils/modalConfigurations';

import { formatEntity } from '../../utils/format';

/*************************/
/* Input Change Handlers */
/*************************/

export async function handleCompositionEntityCategorySelection({
  addEntity,
  recipe,
  selectedEntity,
  pageLoading,
  pageLoaded,
  pageDidNotLoad,
  recipeId,
  setWarningModalParams,
  channels,
  defaultChannelId,
}) {
  pageLoading();

  try {
    let existingCircularMapping = false;

    if (!!recipeId) {
      existingCircularMapping = await recipeService.checkForCircularRecipeMappings(
        recipeId,
        selectedEntity.id,
      );

      if (existingCircularMapping) {
        setWarningModalParams(getWarningModalParams(selectedEntity.name));
        pageLoaded();

        return;
      }
    }

    const defaultChannel = channels.find(({ id }) => id === defaultChannelId);
    const result = await recipeService.getRecipeCost(selectedEntity.id);

    const newComposition = {
      entity: result,
      quantity: 0,
      cost: selectedEntity.isIngredient ? selectedEntity.cost : null,
      channels: recipe.hasMultipleChannels ? channels : [defaultChannel],
      allergens: result.allergens,
    };

    const formattedNewComposition = formatEntity(newComposition);

    addEntity(formattedNewComposition);

    pageLoaded();
  } catch (err) {
    pageDidNotLoad();
  }
}

const RecipeCompositionSearchBar = (props) => {
  const {
    client: { defaultChannelId },
    addEntity,
    recipe,
    recipesAvailable,
    pageLoading,
    pageLoaded,
    pageDidNotLoad,
    isReadOnly,
    onCreationClick,
    setWarningModalParams,
    width,
    placeholder,
    iconSrc,
    compositions,
    channels,
  } = props;

  return (
    <ActionFrame className={`search-bar ${compositions.length ? 'no-top-border' : ''}`}>
      <Dropdown
        button={{
          id: 1,
          text: i18next.t('ADMIN.INGREDIENTS.CREATE'),
          handleClick: () => onCreationClick(),
          iconSrc: '/images/inpulse/add-black-small.svg',
        }}
        iconSrc={iconSrc}
        isDisabled={isReadOnly}
        items={
          getSearchableCompositions({
            currentEntity: recipe,
            compositions,
            entitiesToFormat: recipesAvailable,
            shouldDisplayItemsWithPicture: true,
          }) || []
        }
        placeholder={
          placeholder || i18next.t('ADMIN.PRODUCTS.ADD_RECIPE_OR_INGREDIENT_PLACEHOLDER')
        }
        width={width || '350px'}
        onSelectionChange={(selectedEntity) =>
          handleCompositionEntityCategorySelection({
            addEntity,
            selectedEntity,
            pageLoading,
            pageLoaded,
            recipe,
            pageDidNotLoad,
            recipeId: recipe.id,
            setWarningModalParams,
            channels,
            defaultChannelId,
          })
        }
      />
    </ActionFrame>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  pageDidNotLoad: () => {
    dispatch(loadingFailure());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipeCompositionSearchBar);
