import i18next from 'i18next';
import React from 'react';

import WhiteCardForm from '@commons/WhiteCardForm';

import { INVENTORY_LIST_TEMPLATE_INFOS_INPUTS } from '../utils/formInputsConfigurations';

import { Container, FormContainer } from './styledComponents';

const InventoryListTemplateInformations = (props) => {
  const { inventoryListTemplateForm, isSaveAlreadyTriggered } = props;

  return (
    <Container>
      <FormContainer>
        <WhiteCardForm
          form={inventoryListTemplateForm}
          inputs={INVENTORY_LIST_TEMPLATE_INFOS_INPUTS()}
          shouldDisplayError={isSaveAlreadyTriggered}
          title={i18next.t('ADMIN.SUPPLIER_PRODUCTS.FORM_GENERAL_INFOS')}
        />
      </FormContainer>
    </Container>
  );
};

export default InventoryListTemplateInformations;
