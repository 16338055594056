import i18next from 'i18next';
import React from 'react';

import { Label } from '@commons/utils/styledLibraryComponents';

import theme from '@theme';

import { LabelContainer } from './styledComponents';

const RenderInventoryValidationLabel = ({ isValidated }) => {
  const validationText = i18next.t(
    isValidated ? 'STOCKS.STOCKS.APPROVED' : 'STOCKS.STOCKS.TO_BE_APPROVED',
  );

  return (
    <LabelContainer>
      <Label
        background={isValidated ? theme.colors.brand.primaryDarker : theme.colors.greys.darkest}
        children={validationText}
        color={theme.colors.greys.lightest}
      />
    </LabelContainer>
  );
};

export default RenderInventoryValidationLabel;
