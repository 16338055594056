// ===== Module features for easy import in ClientContainer=====

import StocksAnalyticsContainer from './StocksAnalytics';
import StocksAnalyticsCurrent from './StocksAnalytics/StocksAnalyticsCurrent';
import StocksAnalyticsPast from './StocksAnalytics/StocksAnalyticsPast';

import StocksInventoriesContainer from './StocksInventories';
import StocksInventoriesStocks from './StocksInventories/StocksInventoriesStocks';
import StocksInventoriesTransfer from './StocksInventories/StocksInventoriesTransfer';

const StocksModule = {
  StocksAnalyticsContainer,
  StocksAnalyticsCurrent,
  StocksAnalyticsPast,
  StocksInventoriesContainer,
  StocksInventoriesStocks,
  StocksInventoriesTransfer,
};

export default StocksModule;
