import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import { showConfirmationMessage } from '@actions/messageconfirmation';

import utilsXLS from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

export const ExportListModal = (props) => {
  const {
    currency,
    params: { title, mainSheetData, clientStoreNameTranslation, sheetName },
    closeModal,
    showErrorMessage,
  } = props;

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [titleModal, setTitleModal] = useState(title || '');

  const MAIN_SHEET_COLUMNS_SETTINGS = [
    {
      name: i18next.t('STOCKS.TRANSFERS.SENDER_STORE', {
        storeName: clientStoreNameTranslation,
      }),
      propertyKey: 'sender',
    },
    {
      name: i18next.t('STOCKS.TRANSFERS.RECIPIENT_STORE', {
        storeName: clientStoreNameTranslation,
      }),
      propertyKey: 'recipient',
    },
    {
      name: i18next.t('STOCKS.TRANSFERS.SENDING_DATE'),
      propertyKey: 'sentAt',
      transform: (value) => moment(value).format('DD/MM/YYYY'),
    },
    {
      name: i18next.t('STOCKS.TRANSFERS.SENDING_STATUS'),
      propertyKey: 'status',
    },
    {
      name: i18next.t('STOCKS.TRANSFERS.TOTAL_EXCLUDING_TAXES'),
      propertyKey: 'totalPrice',
      type: 'currency',
    },
  ];

  useEffect(() => {
    (async function launchExport() {
      try {
        const mainSheet = utilsXLS.generateDefaultSheet(
          sheetName,
          MAIN_SHEET_COLUMNS_SETTINGS,
          mainSheetData,
          currency,
        );

        utilsXLS.makeXLS(sheetName, [mainSheet]);
        setTitleModal(i18next.t('GENERAL.EXPORT_SUCCESS'));
        setIsLoading(false);
      } catch (error) {
        showErrorMessage(i18next.t('GENERAL.EXPORT_FAILURE'));
        exitModal();
      }
    })();
  }, []);

  useEffect(() => {
    if (isLoading && progress < 100) {
      setProgress(100);
    }
  }, [progress]);

  const exitModal = () => {
    setIsLoading(false);
    closeModal();
  };

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setIsLoading}
      titleModal={titleModal}
    />
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showConfirmationMessage(message, 'error'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportListModal);
