import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import FiltersModal from './components/FiltersModal';

import { Button } from '../../../utils/styledLibraryComponents';

import { FiltersContainer, IconActive } from './styledComponents';

function DeepsightFiltersButton(props) {
  const { readOnly, textFilterButton, advancedFilters } = props;

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);

  useEffect(() => {
    if (
      !advancedFilters ||
      (advancedFilters && !advancedFilters.length) ||
      !advancedFilters.some((filter) => filter.display)
    ) {
      setIsFilterActive(false);
    }
  }, [advancedFilters]);

  return (
    <FiltersContainer>
      <Button
        color="inpulse-filter"
        fontSize={14}
        formatText={false}
        handleClick={() => {
          if (readOnly) {
            return;
          }

          setFiltersModalOpen(!filtersModalOpen);
        }}
        height={40}
        icon={'/images/inpulse/filter-black-small.svg'}
        isActive={filtersModalOpen}
        isDisabled={readOnly}
        label={textFilterButton}
        maxWidth={240}
        minWidth={240}
      />
      {isFilterActive && (
        <IconActive
          alt="icon-filter-active"
          readOnly={readOnly}
          src={
            readOnly
              ? '/images/icon-dot-active-grey-inpulse.svg'
              : '/images/icon-dot-active-green-inpulse.svg'
          }
        />
      )}
      {filtersModalOpen && (
        <FiltersModal
          params={props}
          setFiltersModalOpen={setFiltersModalOpen}
          setIsFilterActive={setIsFilterActive}
        />
      )}
    </FiltersContainer>
  );
}

DeepsightFiltersButton.propTypes = {
  readOnly: PropTypes.bool,
  minWidth: PropTypes.number,
  textFilterButton: PropTypes.string.isRequired,
};

DeepsightFiltersButton.defaultProps = {
  minWidth: 165,
  readOnly: false,
};

export default DeepsightFiltersButton;
