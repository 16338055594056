// ===== Module features for easy import in ClientContainer=====

import CentralKitchenOrderList from './CentralKitchenOrderList';
import CentralKitchenOrderPreparation from './CentralKitchenOrderPreparation';

const CentralKitchenOrderModule = {
  CentralKitchenOrderList,
  CentralKitchenOrderPreparation,
};

export default CentralKitchenOrderModule;
