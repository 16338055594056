import { orderBy, keyBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import utilsXLS from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import invoiceService from '@services/invoice';

import {
  getDetailedExportGapsSheetColumnsSettings,
  getDetailedExportInfoSheetColumnsSettings,
  getDetailedExportAssociatedOrdersSheetColumnsSettings,
} from '@src/routes/invoice/invoiceControlDetails/utils/exports';

export const InvoiceControlListsAnalyseAndExportModal = (props) => {
  const {
    closeModal,
    params: { invoiceControlListIds, currency, storeName },
  } = props;

  const [isLoading, setLoading] = useState(true);
  const [iclsDataToExport, setIclsDataToExport] = useState([]); // gaps sheet
  const [iclsInformationDataToExport, setIclsInformationDataToExport] = useState([]); // info sheet
  const [iclsAssociatedOrdersDataToExport, setIclsAssociatedOrdersDataToExport] = useState([]); // associated orders sheet
  const [ICLTotalFetchedCount, setICLTotalFetchedCount] = useState(0);

  const ICLTotalToFetchNb = invoiceControlListIds.length;

  // Progress modal
  const [progress, setProgress] = useState(0);
  const [title, setTitle] = useState(
    i18next.t('INVOICE.INVOICE_CONTROLS.CONTENT_EXPORT_MODAL_TITLE'),
  );

  const exitModal = () => {
    setLoading(false);
    closeModal();
  };

  const exportReady = () => {
    setTitle(i18next.t('GENERAL.EXPORT_SUCCESS'));
    setLoading(false);
  };

  const exportFailure = () => {
    setTitle(i18next.t('GENERAL.EXPORT_FAILURE'));
    setLoading(false);
  };

  const updateProgress = (totalFetched) => {
    const invoiceControlsLeft = ICLTotalToFetchNb - totalFetched;
    const updatedProgress = 100 - (invoiceControlsLeft / ICLTotalToFetchNb) * 100;

    setProgress(updatedProgress);
  };

  const getInvoiceControlListDetailsToBeExported = async (invoiceControlListId) => {
    try {
      const dataToExport = await invoiceService.analyseAndExportInvoiceControlListDetails(
        invoiceControlListId,
      );

      /** Informations sheet */
      const invoice = await invoiceService.getById(invoiceControlListId);

      const infoDataToExport = [
        {
          supplierName: invoice.supplier.name,
          storeName: invoice.store.name,
          importedAt: moment(invoice.createdAt).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
          invoiceNumber: invoice.number,
          invoiceDate: invoice.date
            ? moment(invoice.date).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)
            : null,
          total: invoice.total,
        },
      ];

      /** Associated orders sheet */
      const associatedOrdersKeyByOrderId = keyBy(dataToExport, 'orderId');

      const associatedOrdersDataToExport = Object.values(associatedOrdersKeyByOrderId).map(
        (item) => ({
          invoiceNumber: item.invoiceNumber,
          orderSupplierName: item.orderSupplierName,
          orderStoreName: item.orderStoreName,
          orderDeliveryDate: item.orderDeliveryDate,
          orderStatus: item.orderStatus,
          orderTotalPrice: item.orderTotalPrice,
          orderReference: item.orderReference,
        }),
      );

      const updatedFetchedNb = ICLTotalFetchedCount + 1;

      setIclsDataToExport([...iclsDataToExport, ...dataToExport]);
      setIclsInformationDataToExport([...iclsInformationDataToExport, ...infoDataToExport]);
      setIclsAssociatedOrdersDataToExport([
        ...iclsAssociatedOrdersDataToExport,
        ...associatedOrdersDataToExport,
      ]);

      setICLTotalFetchedCount(updatedFetchedNb);
      updateProgress(updatedFetchedNb);
    } catch {
      exportFailure();
    }
  };

  const exportData = () => {
    const fileName = i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_EXPORT_ANALYSIS_FILENAME');

    const gapsSheetColumns = getDetailedExportGapsSheetColumnsSettings();

    const sortedData = orderBy(iclsDataToExport, 'totalGap', 'desc');

    const gapsSheet = utilsXLS.generateDefaultSheet(
      i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_DETAIL_GAPS_SHEET_NAME'),
      gapsSheetColumns,
      sortedData,
      currency,
    );

    /** Informations sheet */
    const infoSheetColumns = getDetailedExportInfoSheetColumnsSettings(storeName);

    const infoSheet = utilsXLS.generateDefaultSheet(
      i18next.t('GENERAL.INFORMATIONS'),
      infoSheetColumns,
      iclsInformationDataToExport,
      currency,
    );

    /** Associated orders sheet */
    const associatedOrdersSheetColumns =
      getDetailedExportAssociatedOrdersSheetColumnsSettings(storeName);

    const associatedOrdersSheet = utilsXLS.generateDefaultSheet(
      i18next.t(
        'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_DETAIL_ASSOCIATED_ORDERS_SHEET_NAME',
      ),
      associatedOrdersSheetColumns,
      iclsAssociatedOrdersDataToExport,
      currency,
    );

    utilsXLS.makeXLS(fileName, [gapsSheet, infoSheet, associatedOrdersSheet]);

    return exportReady();
  };

  useEffect(() => {
    if (isLoading && ICLTotalFetchedCount < ICLTotalToFetchNb) {
      // Get invoice control list gap analysis data one by one
      const nextInvoiceControlListId = invoiceControlListIds[ICLTotalFetchedCount];

      (async () => {
        await getInvoiceControlListDetailsToBeExported(nextInvoiceControlListId);
      })();

      return;
    }

    exportData();
  }, [ICLTotalFetchedCount]);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setLoading}
      titleModal={title}
    />
  );
};

export default InvoiceControlListsAnalyseAndExportModal;
