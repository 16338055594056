import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import React from 'react';
import styled from 'styled-components';

import { deleteEventById, deleteEventSuccess, deleteEventError } from '@actions/event';
import { openConfirmationModal } from '@actions/confirmationmodal';
import { openSlidingModal } from '@actions/modal';

import ConfirmationModal from '@commons/DeepsightComponents/ConfirmationModal/ConfirmationModal';
import Drawer from '@commons/Drawer';

import EventForm from '@src/routes/events/EventForm';

import Utils from '../utils/UtilsFunctions.js';

import { InpulseCalendarDayContent } from './Inpulse/CalendarDayContent';
import { InpulseCalendarDayDisplay, InpulseCustomArrow } from './Inpulse/CalendarDayDisplay';
import { InpulseCalendarTooltip } from './Inpulse/CalendarTooltip';
import { InpulseWeatherSprite } from './Inpulse/CalendarWeatherSprite';

class CustomCalendar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      firstDate: null,
      aggregatedArray: [],
      displayTooltip: false,
      eventFocus: null,
      displaySprite: false,
      dayFocus: null,
      displayEventForm: false,
    };
  }

  componentDidMount() {
    this.getEvents();
    this.setState({ firstDate: moment.tz(this.props.storeTimezone) });
  }

  handleEventDeleteConfirmation = (event) => {
    this.props.deleteEventById(event.id).then(() => {
      this.props.loadStoreEvents();
    });
  };

  handleEventDelete = (event) => {
    const params = {
      component: ConfirmationModal,
      props: this.props,
      eventId: event.id,
      colorsheme: 'red',
      handleEventDeleteConfirmation: () => this.handleEventDeleteConfirmation(event),
      title: this.props.t('FORECAST.TURNOVER.EVENT_DELETE_MODAL_TITLE'),
      text: this.props.t('FORECAST.TURNOVER.EVENT_DELETE_MODAL_CONTENT'),
    };
    this.props.openConfirmationModal(params);
  };

  toggleSprite = (day, service) => {
    if (day !== null) {
      const position = document.getElementById(day.format() + service).getBoundingClientRect();
      const containerPos = document
        .getElementById('forecast-calendar-content')
        .getBoundingClientRect();
      let left = position.left;
      const top = position.top - containerPos.top + 75;
      const tooltipRight = left + 245;
      if (tooltipRight > containerPos.right - 25) {
        left = containerPos.right - 340;
      }
      left = left - 100;
      this.setState({
        displaySprite: true,
        dayFocus: day,
        serviceFocus: service,
        leftSprite: left > 0 ? left : 0,
        topSprite: top,
      });
    } else {
      this.setState({
        displaySprite: false,
        dayFocus: null,
        serviceFocus: null,
      });
    }
  };

  toggleTooltip = (event) => {
    if (event !== null) {
      const position = document.getElementById(event.id).getBoundingClientRect();
      const containerPos = document
        .getElementById('forecast-calendar-content')
        .getBoundingClientRect();
      let left = position.left - 85;
      const top = position.top - containerPos.top - 100;
      const tooltipRight = left + 245;
      if (tooltipRight > containerPos.right - 25) {
        left = containerPos.right - 340;
      }
      this.setState({
        displayTooltip: true,
        eventFocus: event,
        left: left > 0 ? left : 0,
        top: top,
      });
    } else {
      this.setState({
        displayTooltip: false,
        eventFocus: null,
      });
    }
  };

  selectDay = (daySelected) => {
    this.setState({ daySelected });
  };

  arrowClick = (direction) => {
    const { firstDate } = this.state;
    const { startDate, endDate, storeTimezone } = this.props;

    let newStartDate = firstDate;
    if (direction === 'left') {
      newStartDate = moment.tz(firstDate, storeTimezone).subtract(1, 'days');
      if (!newStartDate.isSameOrAfter(startDate)) {
        newStartDate = startDate;
      }
    } else {
      newStartDate = moment.tz(firstDate, storeTimezone).add(1, 'days');
      if (!moment.tz(newStartDate, storeTimezone).add(1, 'days').isSameOrBefore(endDate)) {
        newStartDate = moment.tz(endDate, storeTimezone).subtract(1, 'days');
      }
    }
    this.getEvents(newStartDate);
    this.setState({ firstDate: newStartDate });
  };

  getEvents = (date) => {
    const { events, storeTimezone } = this.props;
    const eventsArray = [];

    for (let i = 0; i < 7; i++) {
      const itemDate = moment.tz(date, storeTimezone).add(i, 'days');

      events.forEach((event) => {
        const timezonedEventStartDate = moment.tz(event.startDate, storeTimezone);
        const timezonedEventEndDate = moment.tz(event.endDate, storeTimezone);

        if (timezonedEventEndDate.clone().isSame(timezonedEventEndDate.clone().startOf('day'))) {
          event.endDate = timezonedEventEndDate.clone().subtract(1, 'minute').format();
        }
        if (
          itemDate.isBetween(
            timezonedEventStartDate.clone().startOf('day'),
            timezonedEventEndDate.clone().endOf('days'),
          )
        ) {
          eventsArray.push({
            ...event,
            date: itemDate,
            isFirst: itemDate.isSame(timezonedEventStartDate.clone(), 'day'),
            isLast: itemDate.isSame(timezonedEventEndDate.clone(), 'day'),
          });
        }
      });
    }

    let aggregatedArray = [];
    eventsArray.forEach((event) => {
      let isPresent = false;
      aggregatedArray = aggregatedArray.map((element) => {
        if (element.id === event.id) {
          isPresent = true;
          return {
            ...element,
            count: element.count + 1,
            isFirst: element.isFirst === true ? true : event.isFirst,
            isLast: element.isLast === true ? true : event.isLast,
            date: moment
              .tz(element.date, storeTimezone)
              .isAfter(moment.tz(event.date, storeTimezone))
              ? event.date
              : element.date,
          };
        }
        return element;
      });
      if (!isPresent) {
        aggregatedArray.push({ ...event, count: 1 });
      }
    });

    aggregatedArray = Utils.sortEvents(aggregatedArray);
    this.setState({ aggregatedArray });
  };

  componentDidUpdate(prevProps) {
    const { firstDate } = this.state;
    const { activeDate, storeTimezone } = this.props;

    const timezonedFirstDate = moment.tz(firstDate, storeTimezone);

    if (prevProps.activeDate !== activeDate) {
      if (!activeDate.isSameOrBefore(timezonedFirstDate.clone().add(6, 'days'), 'day')) {
        this.getEvents(timezonedFirstDate.clone().add(1, 'days'));
        this.setState({
          firstDate: timezonedFirstDate.clone().add(1, 'days'),
        });
      }
      if (activeDate.isBefore(timezonedFirstDate.clone()) || this.props.resetViewport) {
        this.getEvents(activeDate);
        this.setState({
          firstDate: activeDate,
        });
      }
    }
    if (JSON.stringify(prevProps.events) !== JSON.stringify(this.props.events)) {
      this.getEvents(firstDate);
    }
  }

  getMaxDisplayedEventsInColumn = () => {
    const { firstDate, aggregatedArray } = this.state;
    const { storeTimezone } = this.props;

    let maxDisplayedEventsInColumn = 0;
    for (let i = 0; i < 7; i++) {
      const date = moment.tz(firstDate, storeTimezone).add(i, 'days');

      let eventFiltered = aggregatedArray
        .filter((event) => event.date.isSame(date, 'day'))
        .map((event) => {
          event.howManyDaysLeft = moment
            .tz(event.endDate, storeTimezone)
            .diff(moment.tz(storeTimezone), 'days');
          return event;
        });
      eventFiltered = eventFiltered.sort((a, b) =>
        a.howManyDaysLeft < b.howManyDaysLeft ? 1 : -1,
      );

      const previousEvents = aggregatedArray.filter(
        (event) =>
          event.date.isBefore(date, 'day') && moment.tz(event.endDate, storeTimezone).isAfter(date),
      );

      const nbEvents = eventFiltered.length + previousEvents.length;
      if (nbEvents > maxDisplayedEventsInColumn) {
        maxDisplayedEventsInColumn = nbEvents;
      }
    }
    return maxDisplayedEventsInColumn;
  };

  toggleEventForm = (selectedEvent) => {
    this.setState({
      selectedEvent: selectedEvent || null,
      displayEventForm: !this.state.displayEventForm,
    });
  };

  render() {
    const { firstDate } = this.state;
    const { activeDate, startDate, endDate, storeTimezone } = this.props;
    const CalendarDayArray = [];

    for (let i = 0; i < 7; i++) {
      const itemDate = moment.tz(firstDate, storeTimezone).add(i, 'days');
      CalendarDayArray.push(
        <InpulseCalendarDayDisplay
          date={itemDate}
          isSelected={itemDate.isSame(activeDate, 'day')}
          key={'header-' + i}
          selectDay={this.props.changeActiveDate}
          storeTimezone={storeTimezone}
        />,
      );
    }

    const CalendarDayContentArray = [];

    for (let i = 0; i < 7; i++) {
      const itemDate = moment.tz(firstDate, storeTimezone).add(i, 'days');

      CalendarDayContentArray.push(
        <InpulseCalendarDayContent
          border={i !== 6 ? true : false}
          changeActiveDate={this.props.changeActiveDate}
          data={this.props.data}
          date={itemDate}
          displayName={this.props.displayName}
          events={this.state.aggregatedArray}
          forecasts={this.props.forecasts}
          handleEventEdit={this.toggleEventForm}
          index={i}
          key={'content-' + i}
          managerPrevDisplay={this.props.managerPrevDisplay}
          maxDisplayedEventsInColumn={this.getMaxDisplayedEventsInColumn()}
          sales={this.props.sales}
          storeTimezone={storeTimezone}
          toggleSprite={this.toggleSprite}
          toggleTooltip={this.toggleTooltip}
          weather={this.props.weather}
        />,
      );
    }

    return (
      <div className={this.props.className}>
        {this.state.displaySprite && !this.props.isStoreDropdownOpened ? (
          <InpulseWeatherSprite
            day={this.state.dayFocus}
            left={this.state.leftSprite}
            toggleSprite={this.toggleSprite}
            top={this.state.topSprite}
            weather={this.props.weather}
          />
        ) : undefined}
        {this.state.displayTooltip ? (
          <InpulseCalendarTooltip
            event={this.state.eventFocus}
            handleEventDelete={this.toggleEventForm}
            handleEventEdit={this.toggleEventForm}
            left={this.state.left}
            storeTimezone={storeTimezone}
            toggleTooltip={this.toggleTooltip}
            top={this.state.top}
          />
        ) : undefined}
        <div className={'forecast-calendar-header'}>
          {firstDate && firstDate.isSame(startDate, 'day') ? (
            <div className={'forecast-calendar-arrow-empty'} />
          ) : (
            <InpulseCustomArrow
              arrowClick={this.arrowClick}
              date={firstDate}
              direction={'left'}
              storeTimezone={storeTimezone}
            />
          )}
          {CalendarDayArray}
          {firstDate &&
          moment
            .tz(firstDate, storeTimezone)
            .add(6, 'days')
            .isSame(moment.tz(endDate, storeTimezone), 'day') ? (
            <div className={'forecast-calendar-arrow-empty'} />
          ) : (
            <InpulseCustomArrow
              arrowClick={this.arrowClick}
              date={moment.tz(firstDate, storeTimezone).add(6, 'days')}
              direction={'right'}
              storeTimezone={storeTimezone}
            />
          )}
        </div>
        <div className={'forecast-calendar-content'} id="forecast-calendar-content">
          <div
            className={`forecast-calendar-border left ${
              firstDate?.isBefore(moment.tz(storeTimezone).startOf('day')) ? 'past' : 'future'
            }`}
          ></div>
          {CalendarDayContentArray}
          <div
            className={`forecast-calendar-border right ${
              endDate?.isBefore(moment.tz(storeTimezone).startOf('day')) ? 'past' : 'future'
            }`}
          ></div>
        </div>
        <Drawer isOpened={this.state.displayEventForm} onClick={this.toggleEventForm}>
          <EventForm
            {...this.props}
            closeForm={this.toggleEventForm}
            selectedEvent={this.state.selectedEvent}
          />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  modalBool: state.modalReducer.slideModalBool,
});

const mapDispatchToProps = (dispatch) => ({
  openConfirmationModal: (params) => {
    dispatch(openConfirmationModal(params));
  },
  openModal: (params) => {
    dispatch(openSlidingModal(params));
  },
  deleteEventById: (id) =>
    dispatch(deleteEventById(id)).then(
      (result) => {
        dispatch(deleteEventSuccess());
      },
      (error) => {
        dispatch(deleteEventError(error));
      },
    ),
});

const ConnectedCalendar = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(CustomCalendar));

export const InpulseCustomCalendar = styled(ConnectedCalendar)`
  width: calc(100% - 400px);
  height: 100%;
  min-height: 560px;
  min-width: 800px;
  margin: 0;
  border-radius: ${(props) => props.theme.borders?.radius?.weak};
  background: ${(props) => props.theme.colors.greys.lightest};
  .forecast-calendar-header {
    width: 100%;
    height: 65px;
    border-radius: 0 4px 0 0;
    display: flex;
    background: ${(props) => props.theme.colors.greys.lighter};
  }

  .forecast-calendar-arrow-empty {
    width: 50px;
    display: inline-block;
  }

  .forecast-calendar-content {
    overflow: scroll;
    height: 515px;
    width: 100%;
    min-width: 800px;
    position: relative;
    border-radius: ${(props) => props.theme.borders?.radius?.weak};
  }

  .forecast-calendar-border {
    width: 50px;
    height: 100%;
    min-height: 499px;
    display: inline-block;
    vertical-align: top;
    position: relative;
  }

  .forecast-calendar-border.left {
    &.past {
      background: ${(props) => `repeating-linear-gradient(
        135deg,
        ${props.theme.colors.greys.lightest},
        ${props.theme.colors.greys.lightest} 2px,
        ${props.theme.colors.greys.lighter} 2px,
        ${props.theme.colors.greys.lighter} 4px
      )`};
    }
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    z-index: 1;
    vertical-align: top;
    position: relative;
  }

  .forecast-calendar-border.right {
    border-radius: ${(props) => props.theme.borders?.radius?.weak};
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    vertical-align: top;
    position: relative;
  }
`;

export const DeepsightCustomCalendar = styled(ConnectedCalendar)`
  width: calc(100% - 400px);
  height: 100%;
  min-height: 560px;
  min-width: 800px;
  margin: 0;

  .forecast-calendar-header {
    width: 100%;
    height: 65px;
    border-radius: 0 4px 0 0;
    display: flex;
  }

  .forecast-calendar-arrow-empty {
    width: 50px;
    display: inline-block;
  }
  .forecast-calendar-content {
    margin-top: 3px;
    height: 495px;
    width: 100%;
    min-width: 800px;
    position: relative;
    background: #ffffff;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0 4px 4px 0;
  }

  .forecast-calendar-border {
    width: 50px;
    height: 100%;
    min-height: 499px;
    display: inline-block;
    vertical-align: top;
    position: relative;
  }

  .forecast-calendar-border.left {
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    z-index: 1;
    vertical-align: top;
    position: relative;
  }

  .forecast-calendar-border.right {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    vertical-align: top;
    position: relative;
  }
`;
