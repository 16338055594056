import styled from 'styled-components';

export const TabletAndCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const NextAvailalitiesContainer = styled.div`
  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.colors.greys.darkest};
  border-radius: 10px;
`;

export const CalendarIcon = styled.img`
  width: 8px;
  height: 8px;
`;

export const TabletAndTooltipContainer = styled.div`
  & .tablet-tooltip::before {
    height: 0px !important;
  }
`;
