import styled from 'styled-components';

export const ContentContainer = styled.div`
  min-height: ${(props) => (!props.isSSO ? '288px' : '176px')};
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmailContainer = styled.div`
  padding-top: 24px;
  margin-bottom: 24px;
`;

export const PasswordContainer = styled.div`
  margin-bottom: 24px;
`;

export const ForgetPasswordContainer = styled.div`
  text-align: center;

  margin-bottom: 8px;
`;

export const ForgetPasswordLink = styled.a`
  font: ${(props) => props.theme.fonts.textSmallHeight16};
  color: ${(props) => props.theme.colors.greys.darkest};
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${(props) => props.theme.colors.greys.darkest};
  }

  &:active {
    color: ${(props) => props.theme.colors.greys.darkest};
  }
`;

export const ConnectionContainer = styled.div`
  margin-bottom: 24px;
`;

export const ErrorContainer = styled.div`
  color: ${(props) => props.theme.colors.infoRed};
  font: ${(props) => props.theme.fonts.textBigHeight16};
  padding-top: 24px;
`;

export default {
  PasswordContainer,
  EmailContainer,
  ForgetPasswordContainer,
  ConnectionContainer,
  ForgetPasswordLink,
  ErrorContainer,
};
