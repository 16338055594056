import styled from 'styled-components';

export const Container = styled.div`
  width: 492px;
  height: auto;

  padding: 15px;
  margin: -15px 15px 15px 15px;

  background: ${(props) => props.theme.colors.greys.lightest};
  border-radius: ${(props) => props.theme.borders.radius.weak};

  display: flex;
  align-items: center;
`;
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Text = styled.div`
  font: ${(props) => props.theme.fonts.textSmall};
  color: ${(props) => (props.red ? props.theme.colors.red : props.theme.colors.greys.dark)};
`;

export const DateContainer = styled.div`
  display: flex;
  font: ${(props) => props.theme.fonts.textSmall};
`;

export const DayCounter = styled.span`
  font: ${(props) => props.theme.fonts.textMiddleBold};
`;

export const IconContainer = styled.div`
  margin-right: 15px;
  width: 20px;
  height: 23px;
`;
