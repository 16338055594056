import i18next from 'i18next';

import { ConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { GENERIC_MODAL_CANCEL_BUTTON } from '@commons/Modals/GenericModal/genericModalActions';

export const getUpdateStatusWarningModal = (catalogsToDisable, updateCatalogsStatus) => {
  const activation = !catalogsToDisable[0].active;

  return {
    type: 'warning',
    width: '560px',
    maxHeight: '280px',
    height: 'auto',

    component: ConfirmationModal,
    data: {
      content: i18next.t(
        activation
          ? 'ADMIN.CATALOGS.ACTIVATION_WARNING_MODAL_CONTENT'
          : 'ADMIN.CATALOGS.DEACTIVATION_WARNING_MODAL_CONTENT',
      ),
    },
    title: i18next.t(
      activation
        ? 'ADMIN.CATALOGS.ACTIVATION_WARNING_MODAL_TITLE'
        : 'ADMIN.CATALOGS.DEACTIVATION_WARNING_MODAL_TITLE',
    ),
    icon: '/images/inpulse/power-white-small.svg',
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t(activation ? 'GENERAL.ACTIVATE' : 'GENERAL.DEACTIVATE'),
        icon: '/images/inpulse/power-white-small.svg',
        handleClick: () => updateCatalogsStatus(catalogsToDisable),
      },
    ],
  };
};
