import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  row-gap: 24px;
  position: relative;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 0 24px;
`;

export const NestedListContainer = styled.div`
  width: 100%;
  height: calc(100% - 54px);
  position: relative;
`;

export const FilterContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;

  gap: 24px;
`;

export const Filter = styled.div`
  width: 240px;

  display: flex;
  align-items: center;
`;

export const LittleBoxContainer = styled.div`
  color: ${(props) => props.theme.colors?.greys?.darker};
  font: ${(props) => props.theme.fonts.textSmall};

  > .kpi {
    color: ${(props) => props.theme.colors?.greys?.darkest};
    font: ${(props) => props.theme.fonts?.kpiSmall};
  }
`;

export const InfoBoxLabel = styled.div`
  display: flex;
  flex-direction: row;
`;

/** NestedList Rows */
export const ItemRowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 48px;
  align-items: center;
  column-gap: 16px;

  ${(props) =>
    props.borderTop &&
    css`
      border-top: 1px solid ${(props) => props.theme.colors.greys.lighty};
    `}

  ${(props) =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.greys.lighty};
    `}
`;

export const ItemRowContent = styled.div`
  display: flex;

  width: ${(props) => props.width || '160px'};

  ${(props) =>
    props.displayBigger &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    `}
`;

export const DisplayBiggerContainer = styled.div`
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/** NestedList Rows */
export const TextWithTooltipButtonContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
`;

/** NestedList Tooltip */
export const TooltipContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  position: relative;
  flex-direction: column;

  gap: 24px;
  overflow: hidden;
`;

export const TooltipContentContainer = styled.div`
  width: 100%;
  height: 100%;

  overflow: auto;
`;

export const TooltipRowContainer = styled.div`
  width: 100%;
  display: flex;
  height: 32px;

  justify-content: space-between;
  align-items: center;

  border-top: 1px solid ${(props) => props.theme.colors.greys.light};
`;

export const TooltipRowItem = styled.div`
  width: ${(props) => props.width};
  height: 32px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding-left: ${(props) => props.displayPaddingLeft && '8px'};
`;
