import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 40px);
  overflow-y: hidden;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;

  background-color: ${(props) => props.theme.colors.greys.lighter};
`;

export const EmptyStateButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;
