import i18next from 'i18next';

/**
 * Used by the LossListView to determine what to setup in terms of:
 * - actions
 * - data columns
 * - empty states
 * - data fetches
 */
export const LOSS_TYPE = {
  PRODUCT: 'product',
  RECIPE: 'recipe',
  SUPPLIER_PRODUCT: 'supplier_product',
};

export const LOSS_TYPE_TRANSLATIONS = {
  [LOSS_TYPE.PRODUCT]: i18next.t('GENERAL.PRODUCT'),
  [LOSS_TYPE.RECIPE]: i18next.t('GENERAL.RECIPE'),
  [LOSS_TYPE.SUPPLIER_PRODUCT]: i18next.t('GENERAL.SUPPLIER_PRODUCT'),
};

export const RETRIEVE_DATES_FAILURE = {
  [LOSS_TYPE.PRODUCT]: 'LOSSES.PRODUCTS.RETRIEVE_DATES_FAILURE',
  [LOSS_TYPE.RECIPE]: 'LOSSES.RECIPES.RETRIEVE_DATES_FAILURE',
  [LOSS_TYPE.SUPPLIER_PRODUCT]: 'LOSSES.SUPPLIER_PRODUCTS.RETRIEVE_DATES_FAILURE',
};

export const LOSS_TYPE_ITEM_PLACEHOLDER = {
  [LOSS_TYPE.PRODUCT]: '/images/inpulse/product-photo-placeholder.svg',
  [LOSS_TYPE.RECIPE]: '/images/inpulse/recipe-photo-placeholder.svg',
  [LOSS_TYPE.SUPPLIER_PRODUCT]: '/images/inpulse/supplier-product-photo-placeholder.svg',
};

export const LOSS_TYPE_TABLE_FORM_EMPTY_STATE = {
  [LOSS_TYPE.PRODUCT]: 'GENERAL.PRODUCT_EMPTY',
  [LOSS_TYPE.RECIPE]: 'LOSSES.RECIPES.TABLE_FORM_EMPTY_STATE_TEXT',
  [LOSS_TYPE.SUPPLIER_PRODUCT]: 'LOSSES.SUPPLIER_PRODUCTS.TABLE_FORM_EMPTY_STATE_TEXT',
};

export const LOSS_TYPE_DROPDOWN_PLACEHOLDER = {
  [LOSS_TYPE.PRODUCT]: 'GENERAL.ADD_PRODUCT',
  [LOSS_TYPE.RECIPE]: 'LOSSES.RECIPES.DROPDOWN_PLACEHOLDER',
  [LOSS_TYPE.SUPPLIER_PRODUCT]: 'LOSSES.SUPPLIER_PRODUCTS.DROPDOWN_PLACEHOLDER',
};

export const DELETE_LOSSES_SUCCESS = {
  [LOSS_TYPE.PRODUCT]: 'LOSSES.PRODUCTS.DELETE_PRODUCTS_SUCCESS',
  [LOSS_TYPE.SUPPLIER_PRODUCT]: 'LOSSES.SUPPLIER_PRODUCTS.DELETE_SUCCESS',
  [LOSS_TYPE.RECIPE]: 'LOSSES.RECIPES.DELETE_SUCCESS',
};

export const RETRIEVE_LOSSES_FAILURE = {
  [LOSS_TYPE.PRODUCT]: 'LOSSES.PRODUCTS.RETRIEVE_LOSSES_FAILURE',
  [LOSS_TYPE.SUPPLIER_PRODUCT]: 'LOSSES.SUPPLIER_PRODUCTS.RETRIEVE_LOSSES_FAILURE',
  [LOSS_TYPE.RECIPE]: 'LOSSES.RECIPES.RETRIEVE_LOSSES_FAILURE',
};

export const EXPORT_FAILURE = {
  [LOSS_TYPE.PRODUCT]: 'LOSSES.PRODUCTS.EXPORT_FAILURE',
  [LOSS_TYPE.SUPPLIER_PRODUCT]: 'LOSSES.SUPPLIER_PRODUCTS.EXPORT_FAILURE',
  [LOSS_TYPE.RECIPE]: 'LOSSES.RECIPES.EXPORT_FAILURE',
};

export const DELETE_LOSSES_FAILURE = {
  [LOSS_TYPE.PRODUCT]: 'LOSSES.PRODUCTS.DELETE_PRODUCTS_FAILURE',
  [LOSS_TYPE.SUPPLIER_PRODUCT]: 'LOSSES.SUPPLIER_PRODUCTS.DELETE_FAILURE',
  [LOSS_TYPE.RECIPE]: 'LOSSES.RECIPES.DELETE_FAILURE',
};

export const EMPTY_STATE_ICON = {
  [LOSS_TYPE.PRODUCT]: '/images/inpulse/product.svg',
  [LOSS_TYPE.SUPPLIER_PRODUCT]: '/images/inpulse/supplier-ingredients.svg',
  [LOSS_TYPE.RECIPE]: '/images/inpulse/product.svg',
};
