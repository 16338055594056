import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { REGEX_SIREN } from '@commons/utils/regex';

import { getClientInfo } from '@selectors/client';

import { upload as uploadService } from '@services/upload';

import { Container } from './styledComponents';

const REGEX_VALIDATE_SIREN = new RegExp(REGEX_SIREN);

const uploadPicture = async ({ clientId, supplierId, picture }) => {
  const params = {
    clientId,
    // Add timestamp to avoid delay between two changes
    supplierProductId: `${supplierId}_${moment().format(
      DATE_DISPLAY_FORMATS.UNDERSCORED_MONTH_DAY_HOUR_MINUTE_SECOND,
    )}`,
  };

  return uploadService.uploadFile(picture, params);
};

/**
 * Assert that the general form of that page can be saved (supplier)
 *
 * @param {Object} supplier - The supplier to save

 * @returns {boolean} Whether the form is valid
 */
export function isFormValid(supplier) {
  if (!supplier.name) {
    return false;
  }

  if (!!supplier.siren && !REGEX_VALIDATE_SIREN.test(supplier.siren)) {
    return false;
  }

  return true;
}

/**
 * Handle the save of the entire form (product & its recipes)
 *
 * @param {Props} props - The props linked to the component
 *
 * @returns {void}
 */
export async function handleSave({
  handlePrevPage,
  supplier,
  supplierId,
  patchSupplier,
  showMessage,
  pageLoading,
  pageLoaded,
  client: { clientId, currencyId },
  picture,
}) {
  pageLoading();
  supplier.clientId = clientId;
  supplier.currencyId = currencyId;

  try {
    if (picture !== supplier.picture) {
      if (!!picture) {
        const url = await uploadPicture({ clientId, supplierId, picture });

        supplier.picture = get(url, 'data.fileUri', supplier.picture);
      } else {
        supplier.picture = null;
      }
    }

    patchSupplier(supplier);

    if (supplierId)
      showMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_PATCH_SUPPLIER_PRODUCTS_SUCCESS'));
    else showMessage(i18next.t('ADMIN.SUPPLIERS.ADD_SUPPLIER_CONFIRMATION'));
    handlePrevPage();
  } catch (error) {
    showMessage(error.message, 'error');
  } finally {
    pageLoaded();
  }
}

export const SupplierDetailSave = (props) => {
  const { supplier, showMessage } = props;

  return (
    <Container>
      <Button
        buttonCustomStyle={{ width: '160px' }}
        color={'inpulse-default'}
        handleClick={() => handleSave({ ...props, showMessage })}
        icon={'/images/inpulse/save-white-small.svg'}
        isDisabled={!isFormValid(supplier)}
        label={'Sauvegarder'}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

export default connect(mapStateToProps, null)(SupplierDetailSave);
