import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage } from '@actions/messageconfirmation';

import { doesIngredientsHaveApplicableUnits } from '@commons/utils/units';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_ASSOCIATE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { ListView } from '@commons/utils/styledLibraryComponents';
import { translateUnit } from '@commons/utils/translateUnit';
import EmptyState from '@commons/EmptyState';

import { supplierProduct as supplierProductService } from '@services/supplierProduct';

import { UNITS_DROPDOWN_ITEMS } from '@admin/utils/DropdownItems';

import { Container, Content } from './styledComponents';
import columnUtils from './utils/columns';

const mappingAutoModalContent = ({
  user,
  shouldByPassLoading,
  supplierIds,
  setSelectedSpEntityMappings,
  // redux methods
  pageLoaded,
  pageLoading,
  showErrorMessage,
}) => {
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [supplierProducts, setSupplierProducts] = useState(null);
  const [dataDisplayed, setDataDisplayed] = useState(null);

  // Load Data
  const getElligibleAutoMappingSupplierProducts = async () => {
    pageLoading();

    try {
      const result = await supplierProductService.getElligibleAutoMapping(
        user.clientId,
        supplierIds,
      );

      const formattedSupplierProducts = result.reduce(
        (
          result,
          {
            id,
            name,
            sku,
            category,
            packagings,
            subCategory,
            conversions,
            supplier = {},
            relevantEntites = [],
          },
        ) => {
          const masterPackaging = packagings.find(
            ({ masterSupplierProductPackagingId }) => !masterSupplierProductPackagingId,
          );

          const formattedConversions = conversions.map(({ convertedUnit, convertedQuantity }) => ({
            convertedUnit,
            convertedQuantity,
            displayedUnit: UNITS_DROPDOWN_ITEMS.find(({ id }) => id === convertedUnit).value,
            isBaseUnit: convertedUnit === masterPackaging.unit,
          }));

          // The goal here is to display one line for each potential association IF / I
          // So we push for every occurence in relevantEntites
          for (const entity of relevantEntites) {
            result.push({
              id: `${id};${entity.id}`, // to ensure unicity on lines
              name,
              sku,
              category,
              subCategory,
              supplierProductId: id,
              relevance: entity.score,
              conversions: formattedConversions,
              packagingUnit: masterPackaging.unit,
              packagingNames: `${translateUnit(masterPackaging.unit)}`,
              entityName: entity.name, // to make possible search on entity name in list view
              supplierName: supplier.name, // to make possible search on supplier name in list view
              entity: {
                id: entity.id,
                name: entity.name,
                unit: entity.unit,
              },
            });
          }

          return result;
        },
        [],
      );

      setSupplierProducts(formattedSupplierProducts);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.MAPPING_AUTO_MODAL_DATA_FETCH_ERROR'));
    } finally {
      pageLoaded();
      setIsLoading(false);
    }
  };

  /**
   * This method aims to build the new list of items to send the component ListView
   *
   * by marking as disabled each association that is not possible due to
   *  - Unit incompatibility
   *  - Ingredient already selection for another supplier product
   *
   * and marking as selected the rows according to the received parameter
   */
  const computeSelectedItems = (selectedItems) => {
    if (!supplierProducts) {
      return {};
    }

    setSelectedSpEntityMappings(selectedItems);

    return (dataDisplayed || supplierProducts).reduce(
      (result, item) => {
        const isUnitCompatible = doesIngredientsHaveApplicableUnits(
          item.packagingUnit,
          item.entity.unit,
          item.conversions,
        );

        const isSupplierProductAlreadyAssociated = selectedItems.some(
          ({ supplierProductId }) => supplierProductId === item.supplierProductId,
        );

        const isRowSelected = selectedItems.some(({ id }) => id === item.id);

        const isNotSelectable =
          !isRowSelected && (!isUnitCompatible || isSupplierProductAlreadyAssociated);

        const isRowDifferent =
          item.isRowSelected !== isRowSelected || item.isNotSelectable !== isNotSelectable;

        if (!result.hasChanged && isRowDifferent) {
          result.hasChanged = true;
        }

        result.data.push({
          ...item,
          isUnitCompatible,
          isRowSelected,
          isNotSelectable,
        });

        return result;
      },
      {
        hasChanged: false,
        data: [],
      },
    );
  };

  const handleSelectionChanged = (selectedItems) => {
    if (!supplierProducts) {
      return setDataDisplayed([]);
    }

    const { hasChanged, data } = computeSelectedItems(selectedItems);

    if (hasChanged) {
      setDataDisplayed(data);
    }
  };

  // Setup columns
  useEffect(() => {
    setColumns(columnUtils.get());
  }, []);

  // Initial fetch
  useEffect(() => {
    if (isEmpty(supplierIds) || shouldByPassLoading) {
      return;
    }

    getElligibleAutoMappingSupplierProducts();
  }, [shouldByPassLoading, supplierIds]);

  // Simulate no SP selected for first render
  useEffect(() => {
    if (!supplierProducts) {
      return;
    }

    handleSelectionChanged([]);
  }, [supplierProducts]);

  // Display empty state
  if (!supplierProducts && !isLoading) {
    return (
      <Container>
        <EmptyState />
      </Container>
    );
  }

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  return (
    <Container>
      <Content>
        <ListView
          columns={columns}
          data={dataDisplayed}
          defaultOrderBy={'relevance'}
          defaultOrderType={'desc'}
          isLoading={isLoading}
          languageCode={userLanguageCode}
          padding={'24px 24px 0px 24px'}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          setSelectedItems={handleSelectionChanged}
          disableFullSelection // selecting all rows make impossible to check unicity between IF / I
          disableResetSelectionOnNavigation
          forceEnableSelection
          hideAllPerPageOption
        />
      </Content>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

const ReduxMappingAutoModalContent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(mappingAutoModalContent);

export const mappingAutoModalConfig = ({
  shouldByPassLoading,
  supplierIds,
  selectedSpEntityMappings,
  setSelectedSpEntityMappings,
  handleAutoMappingCreationBetweenSpAndEntities,
}) => ({
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_ASSOCIATE_BUTTON(),
      handleClick: handleAutoMappingCreationBetweenSpAndEntities,
      isDisabled: !selectedSpEntityMappings.length,
    },
  ],
  title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MAPPING_AUTO_MODAL_TITLE'),
  icon: '/images/inpulse/lightning-black-small.svg',
  type: 'actionWhite',
  component: ReduxMappingAutoModalContent,
  data: { shouldByPassLoading, supplierIds, setSelectedSpEntityMappings },
  height: 'auto',
  isFullscreen: true,
  customPadding: '0px',
});
