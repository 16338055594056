import saleService from '../services/sale';
import turnoverService from '../services/turnover';

export const receiveSales = (sales) => ({
  type: 'SALES_SUCCESS',
  sales,
});

export const receiveLastYearSales = (lastYearSales) => ({
  type: 'LAST_YEAR_SALES_SUCCESS',
  lastYearSales,
});

export const receivePastSales = (pastSales) => ({
  type: 'PAST_SALES_SUCCESS',
  pastSales,
});

export const receiveWeeklySales = (weeklySales) => ({
  type: 'WEEKLY_SALES_SUCCESS',
  weeklySales,
});

export const receiveRangeSales = (rangeSales) => ({
  type: 'RANGE_SALES_SUCCESS',
  rangeSales,
});

export const requestWeeklySalesError = (error) => ({
  type: 'WEEKLY_SALES_FAILURE',
  error,
});

export const requestRangeSalesError = (error) => ({
  type: 'RANGE_SALES_FAILURE',
  error,
});

export const requestSalesError = (error) => ({
  type: 'SALES_FAILURE',
  error,
});

export const requestPastSalesError = (error) => ({
  type: 'PAST_SALES_FAILURE',
  error,
});

export const fetchWeeklySalesOfStore = (store_id, start_date, end_date, fromTurnover = false) =>
  function (dispatch) {
    dispatch({
      type: 'WEEKLY_SALES_REQUEST',
    });

    if (!fromTurnover) {
      return saleService.getSalesByStoreId(store_id, start_date, end_date);
    }

    return turnoverService.getTurnoverByStoreId(store_id, start_date, end_date);
  };

export const fetchRangeSalesOfStore = (store_id, start_date, end_date, fromTurnover = false) =>
  function (dispatch) {
    dispatch({
      type: 'RANGE_SALES_REQUEST',
    });

    if (!fromTurnover) {
      return saleService.getSalesByStoreId(store_id, start_date, end_date);
    }

    return turnoverService.getTurnoverByStoreId(store_id, start_date, end_date);
  };

export const fetchSalesOfStore = (store_id, start_date, end_date, fromTurnover = false) =>
  function (dispatch) {
    dispatch({
      type: 'SALES_REQUEST',
    });

    if (!fromTurnover) {
      return saleService.getSalesByStoreId(store_id, start_date, end_date);
    }

    return turnoverService.getTurnoverByStoreId(store_id, start_date, end_date);
  };

export const fetchPastSalesOfStore = (store_id, start_date, end_date, fromTurnover = false) =>
  function (dispatch) {
    dispatch({
      type: 'PAST_SALES_REQUEST',
    });

    if (!fromTurnover) {
      return saleService.getSalesByStoreId(store_id, start_date, end_date);
    }

    return turnoverService.getTurnoverByStoreId(store_id, start_date, end_date);
  };
