import { upload } from '../services/upload';

export const uploadFile = (file, params) =>
  function (dispatch) {
    dispatch({
      type: 'UPLOAD_REQUEST',
    });
    return upload.uploadFile(file, params);
  };

export const uploadFileSuccess = (file) => ({
  type: 'UPLOAD_SUCCESS',
  file,
});

export const uploadFileError = (error) => ({
  type: 'UPLOAD_FAILURE',
  error,
});
