import moment from 'moment-timezone';

import { INVENTORY_TYPE } from '../../../common/constants';

const DATE_FORMAT = 'DDMMYY';

export function getReference(
  viewOnly,
  reference,
  inventoryType,
  partnerId = '',
  stockDate,
  timezone,
) {
  if (viewOnly) {
    return reference;
  }

  switch (inventoryType) {
    case INVENTORY_TYPE.LOSS:
      return 'PER-' + partnerId + '-' + (stockDate ? moment(stockDate).format(DATE_FORMAT) : '');
    case INVENTORY_TYPE.STOCK:
      return (
        'STK-' +
        partnerId +
        '-' +
        (stockDate ? moment.tz(stockDate, timezone).format(DATE_FORMAT) : '')
      );
    default:
      return '';
  }
}

export default {
  getReference,
};
