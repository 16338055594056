import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const Box = styled.div`
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  margin-right: 15px;

  color: ${(props) => props.theme.colors?.greys.darkest};
  font: ${(props) => props.theme.fonts?.kpiBig};

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.colors?.greys.lightest};

  border: ${(props) => `1px solid ${props.theme.colors.greys.light}`};
  border-radius: ${(props) => props.theme.borders?.radius.weak};

  > img {
    width: 15px;
  }

  &.clickable:hover {
    cursor: pointer;
  }

  &.empty {
    background: inherit;
    box-shadow: none;
    border: none;
  }
`;

export const BoxInput = styled.input`
  width: 60px;
  height: 60px;
  margin: 0px 15px 0px 0px;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  color: ${(props) => props.theme.colors?.greys.darkest};
  font: ${(props) => props.theme.fonts?.kpiBig};
  text-align: center;

  display: flex;
  align-items: center;

  background: ${(props) => props.theme.colors?.greys.lightest};
  box-sizing: border-box;

  border: ${(props) => `1px solid ${props.theme.colors.greys.darkest}`};
  border-radius: ${(props) => props.theme.borders?.radius.weak};
`;

export const BoxInfo = styled.div`
  width: 60px;
  height: 60px;

  color: ${(props) => props.theme.colors?.greys.darkest};

  font: ${(props) => props.theme.fonts?.textSmall};
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
`;
