import React, { Component } from 'react';

import { Button } from '../commons/utils/styledLibraryComponents';

import './MobilePage.css';

class MobileHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="blue-gradient-container">
        <div className="top-bar-logo-container">
          <img src="/images/deepsight-logo-white.png" />
          <div className="top-bar-logo-title-white">Inpulse</div>
        </div>
        <div className="mobile-top-bar-button-container">
          <Button
            color={'blue-outline'}
            fontSize={12}
            formatText={false}
            handleClick={() => (window.location.href = 'http://www.deepsight.io')}
            height={35}
            label={'Accéder au site'}
          />
        </div>
        <div className="mobile-welcome-text-container">
          <p>Inpulse arrive bientôt sur mobile ! 🙂</p>
        </div>
        <div className="mobile-welcome-sub-text-container">
          <p>Rendez-vous sur tablette ou ordinateur pour utiliser Inpulse dès maintenant !</p>
        </div>
      </div>
    );
  }
}

export default MobileHomePage;
