import styled, { css } from 'styled-components';

export const SwitchWrapper = styled.div`
  position: relative;
  width: 32px;
  height: 16px;
`;

export const SwitchLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  padding: 2px;

  background: ${(props) => props.theme.colors.typeOff.default};
  border-radius: 24px;
  box-sizing: border-box;

  transition: all 0.2s ease-in;

  ${(props) =>
    props.disabled
      ? css`
          background: ${props.theme.colors.disabled};

          cursor: not-allowed;

          &::after {
            content: '';
            display: block;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            background: ${props.theme.colors.lightest};
            transition: 0.2s;
          }
        `
      : css`
          cursor: pointer;

          &::after {
            content: '';
            display: block;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            background: ${props.theme.colors.lightest};
            transition: 0.2s;
          }

          &:hover {
            transition: all 0.2s ease-in;
            cursor: pointer;
            background: ${props.theme.colors.hover};
          }

          :active {
            background: ${props.theme.colors.pressed};
          }
        `}
`;

export const Switch = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 12px;
  height: 12px;

  transition: all 0.2s ease-in;

  ${(props) =>
    !props.disabled
      ? css`
          &:checked + ${SwitchLabel} {
            background: ${props.theme.colors.typeOn.default};

            &::after {
              content: '';
              display: block;
              border-radius: 50%;
              width: 12px;
              height: 12px;
              margin-left: 16px;
              transition: 0.2s;
            }

            &:hover {
              transition: all 0.2s ease-in;
              cursor: pointer;
              background: ${props.theme.colors.hover};
            }

            :active {
              background: ${props.theme.colors.pressed};
            }

            :disabled {
              background: ${props.theme.colors.disabled};
            }
          }
        `
      : css`
          &:checked + ${SwitchLabel} {
            &::after {
              content: '';
              display: block;
              border-radius: 50%;
              width: 12px;
              height: 12px;
              margin-left: 16px;
              transition: 0.2s;
            }
          }
        `}
`;
