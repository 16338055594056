import { user } from '../services/user';

export const passwordChangeSuccess = (user) => ({
  type: 'PASSWORD_CHANGE_SUCCESS',
  user,
});

export const passwordChangeFailure = (error) => ({
  type: 'PASSWORD_CHANGE_FAILURE',
  error,
});

export const passwordResetSuccess = () => ({
  type: 'PASSWORD_RESET_SUCCESS',
});

export const passwordResetFailure = (error) => ({
  type: 'PASSWORD_RESET_FAILURE',
  error,
});

export const password_change = (accessToken, password) => (dispatch) =>
  new Promise(function (resolve, reject) {
    dispatch({
      type: 'PASSWORD_CHANGE_REQUEST',
    });
    user.changePasswordFromResetToken(accessToken, password).then(
      (user) => {
        dispatch(passwordChangeSuccess(user));
        resolve(user);
      },
      (error) => {
        dispatch(passwordChangeFailure(error));
        reject(error);
      },
    );
  });

export const password_reset = (email) => (dispatch) =>
  new Promise(function (resolve, reject) {
    dispatch({
      type: 'PASSWORD_RESET_REQUEST',
    });
    user.resetPassword(email).then(
      () => {
        dispatch(passwordResetSuccess());
        resolve();
      },
      (error) => {
        dispatch(passwordResetFailure(error));
        reject(error);
      },
    );
  });
