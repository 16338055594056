import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React from 'react';

import { getTheme } from '@commons/utils/theme';
import { Label } from '@commons/utils/styledLibraryComponents';

import { statusLegend } from '@orders/OrderList/common/constants';
import ORDER_STATUS from '@orders/OrderList/components/OrderForm/constants/status';

import { getEntityUnit } from '@stocks/utils';

const MAX_DAYS_BEFORE_BREAKAGE_WARNING = 4;

const theme = getTheme();

const getListConfig = () => [
  {
    id: 0,
    name: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
    large: true,
    isSortable: true,
  },
  {
    id: 1,
    name: i18next.t('ANALYSIS.STOCKS.COLUMN_NAME_PREVIOUS_DAY_STOCK'),
    propertyKey: 'stock.value',
    tooltipDisplay: i18next.t('ANALYSIS.STOCKS.COLUMN_TOOLTIP_PREVIOUS_DAY_STOCK'),
    isNumber: true,
    render: (value, items) => {
      if (value == null) {
        return (
          <Label
            background={
              _.get(items, 'stock.isReal') ? theme.colors.greys.dark : theme.colors.highlightedBlue
            }
            children={'--'}
            color={
              _.get(items, 'stock.isReal') ? theme.colors.greys.dark : theme.colors.highlightedBlue
            }
            type={''}
          />
        );
      }

      return (
        <Label
          background={
            _.get(items, 'stock.isReal') ? theme.colors.greys.dark : theme.colors.highlightedBlue
          }
          children={`${value < 0 ? 0 : value.toFixed(2)} ${getEntityUnit(items.unit)}`}
          color={
            _.get(items, 'stock.isReal') ? theme.colors.greys.dark : theme.colors.highlightedBlue
          }
          type={''}
        />
      );
    },
    isSortable: true,
  },
  {
    id: 2,
    name: i18next.t('ANALYSIS.STOCKS.COLUMN_NAME_DAILY_FORECASTED_CONSUMPTION'),
    propertyKey: 'meanForecastedConsumption',
    tooltipDisplay: i18next.t('ANALYSIS.STOCKS.COLUMN_TOOLTIP_DAILY_FORECASTED_CONSUMPTION'),
    isNumber: true,
    render: (value, items) => {
      if (value == null) {
        return (
          <Label
            background={theme.colors.highlightedBlue}
            children={'--'}
            color={theme.colors.highlightedBlue}
            type={''}
          />
        );
      }

      return (
        <Label
          background={theme.colors.highlightedBlue}
          children={`${value} ${getEntityUnit(items.unit)}`}
          color={theme.colors.highlightedBlue}
          type={''}
        />
      );
    },
    isSortable: true,
  },
  {
    id: 3,
    name: i18next.t('ANALYSIS.STOCKS.COLUMN_NAME_DAYS_BEFORE_STOCKOUT'),
    propertyKey: 'daysBeforeBreakage',
    tooltipDisplay: i18next.t('ANALYSIS.STOCKS.COLUMN_TOOLTIP_DAYS_BEFORE_STOCKOUT'),
    isNumber: true,
    render: (value) => {
      // When day of breakage could not be calculated due to lack of consumption or initial stock
      if (value === undefined) {
        return '--';
      }

      // When no day of breakage could be determined there is always a positive stock on the range of time
      if (value === null) {
        return i18next.t('GENERAL.IN_15_DAYS_AND_MORE');
      }

      // daysBeforeBreakage could be negative when it's been several days without stock
      if (value <= 0) {
        return (
          <Label
            background={theme.colors.infoLightRed}
            children={i18next.t('GENERAL.IN_BREAKAGE')}
            color={theme.colors.greys.darkest}
            type={'plain'}
          />
        );
      }

      if (value < MAX_DAYS_BEFORE_BREAKAGE_WARNING) {
        return (
          <Label
            background={theme.colors.infoLightOrange}
            children={`${value} ${
              value === 1 ? i18next.t('GENERAL.DAY') : i18next.t('GENERAL.DAYS')
            }`}
            color={theme.colors.greys.darkest}
            type={'plain'}
          />
        );
      }

      return `${value} ${value === 1 ? i18next.t('GENERAL.DAY') : i18next.t('GENERAL.DAYS')}`;
    },
    isSortable: true,
  },
  {
    id: 4,
    name: i18next.t('ANALYSIS.STOCKS.COLUMN_NAME_NEXT_DELIVERY'),
    propertyKey: 'nextUpcomingOrder',
    tooltipDisplay: i18next.t('ANALYSIS.STOCKS.COLUMN_TOOLTIP_NEXT_DELIVERY'),
    isSortable: false,
    render: (value) => {
      if (value == null) {
        return '--';
      }

      return moment(value).format('L');
    },
  },
  {
    id: 5,
    name: i18next.t('ANALYSIS.STOCKS.COLUMN_NAME_NEXT_ORDERS'),
    propertyKey: 'nextOrders',
    tooltipDisplay: i18next.t('ANALYSIS.STOCKS.COLUMN_TOOLTIP_NEXT_ORDERS'),
    customModalRender: true,
    isSortable: false,
  },
  {
    id: 6,
    name: '',
    propertyKey: 'action',
    isClickable: true,
    isSortable: false,
  },
];

const getModalConfig = () => [
  { propertyKey: 'url' },
  {
    propertyKey: 'status',
    bold: true,
    render: (status) => (
      <div
        style={{
          color:
            status >= ORDER_STATUS.SENT ? theme.colors.greys.darkest : theme.colors.greys.darker,
        }}
      >
        {i18next.t(statusLegend[status].name)}
      </div>
    ),
  },
  { propertyKey: 'supplierName' },
  {
    propertyKey: 'quantity',
    bold: true,
  },
  { propertyKey: 'deliveryDate' },
];

export default {
  getListConfig,
  getModalConfig,
};
