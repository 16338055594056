import styled, { css } from 'styled-components';

export const EdiStateContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

export const IconContainer = styled.div``;

export const TitleContainer = styled.div`
  font: ${(props) => props.theme.fonts.h2Inter};
  color: ${(props) => props.theme.colors.greys.darkest};
`;

export const SubtitleContainer = styled.div`
  margin-top: 8px;
  text-align: center;

  ${(props) => {
    if (props.warning) {
      return css`
        color: ${(props) => props.theme.colors?.infoRed};
        font: ${(props) => props.theme.fonts?.textSmallBold};
      `;
    }

    return css`
      font: ${(props) => props.theme.fonts.textSmallHeight16};
      color: ${(props) => props.theme.colors.greys.darker};
    `;
  }}
`;

export default {
  IconContainer,
  TitleContainer,
  SubtitleContainer,
};
