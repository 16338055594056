import styled, { css } from 'styled-components';

export const MapSupplierProductsModalContainer = styled.div`
  width: 100%;
  height: 100%;

  > div > div > div:first-child {
    overflow-x: visible;

    ${(props) =>
      props.hasFooter &&
      css`
        height: calc(100% - 32px);
      `}
  }

  > div > div > div:last-child {
    ${(props) =>
      props.hasFooter &&
      css`
        bottom: 89px; // footer with actions of the modal
        left: 0;
      `}
  }
`;

export const FiltersContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const CostContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CostTitle = styled.span`
  color: ${(props) => props.theme.colors?.greys.darker};
  font: ${(props) => props.theme.fonts?.textSmall};
`;

export const CostValue = styled.span`
  color: ${(props) => props.theme.colors?.greys?.darkest};
  font: ${(props) => props.theme.fonts?.textBigHeight24Bold};
`;
