import {
  cloneDeep,
  first,
  flatten,
  get,
  isEmpty,
  isEqual,
  keyBy,
  keys,
  last,
  orderBy,
  mergeWith,
  set,
} from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openSmallModal } from '@actions/modal';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { FiltersContext } from '@context/FiltersContext';

import useLocalStorage from '@hooks/useLocalStorage';
import useReloadPageWarning from '@hooks/useReloadPageWarning';

import {
  canIncrementProductionLossesOnEANScan,
  canIncrementProductionStocksOnEANScan,
} from '@selectors/actions/productionActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { planningOperation } from '@services/planningOperation';

import { canPageBeRenderedInOffline } from '@productions/utils/isRenderedOffline';
import { fetchStoresUser } from '@productions/services/stores';
import { period } from '@productions/constants/period';
import { planningType as planningTypeEnum } from '@productions/constants/planning';

import { OfflineProductionPage } from '../OfflinePage/OfflineProductionPage';
import { OfflineSynchronisationModal } from '../OfflineSynchronisationModal';
import { sortProductsByProperty } from '../ProductionContainer/utils/sort';
import PlanningHeader from '../PlanningHeader';
import PlanningListing from '../PlanningListing';
import WarningModal from '../WarningModal';

import { Container, ContainerContent, FullHeightContainer, ShadowedBox } from './styledComponents';

const OTHER_CATEGORY = 'Autres';
const INPUT_NUMBER_MAX_VALUE = 30;

const MAXIMUM_REQUEST_IN_PENDING_TO_PLAY_IN_BACKGROUND = 20;

const DEFAULT_WARNING_MODAL_SETUP = {
  active: false,
  product: null,
  tot: null,
};

const EMPTY_ACTIVE_PRODUCT = {
  id: '',
  case: '',
  value: '',
};

/*********************/
/** Handler Methods **/
/*********************/

/**
 * Handle function attached to the search input that makes sure to display an updated list of products
 * matching the search input filled by the user
 *
 * @param {String} research                       - The research input value
 * @param {Products[]} products                   - The list of avaible products to filter on
 * @param {String} planningType                   - The type on which the listing is being displayed ['loss' || 'stock']
 * @param {Object} productsByCategory             - The list of products grouped by categories
 * @param {Boolean} displayProductsByCategory     - Whether the list of products should be displayed by category
 * @param {Method} setActiveProduct               - Method to set a product as active and allow its edition
 * @param {Method} setFilteredProducts            - Method to set the updated filtered list of products
 * @param {Method} setFilteredProductsByCategory  - Method to set the updated filtered list of products grouped by category
 *
 * @returns {void}
 */
export const handleSelectedBarcode = async (
  research,
  products,
  planningType,
  productsByCategory,
  displayProductsByCategory,
  setActiveProduct,
  setFilteredProducts,
  setFilteredProductsByCategory,
  activeProduct,
  productIdSuccess,
  updatedProps,
  authorizedActions,
) => {
  if (!research) {
    setFilteredProducts(products);
    setFilteredProductsByCategory(productsByCategory);

    if (activeProduct.id === productIdSuccess || activeProduct.isScanAdd) {
      setActiveProduct(EMPTY_ACTIVE_PRODUCT);
    }

    return;
  }

  const filteredProducts = products.filter((product) => {
    if (product.name.toLowerCase().includes(research.toLowerCase())) {
      return true;
    }

    return product.sku === research;
  });

  setFilteredProducts(filteredProducts);

  if (!displayProductsByCategory) {
    return;
  }

  const filteredProductsByCategory = {};

  Object.keys(productsByCategory).forEach((category) => {
    const products = productsByCategory[category].filter((product) => {
      if (product.name.toLowerCase().includes(research.toLowerCase())) {
        return true;
      }

      return product.sku === research;
    });

    if (products.length > 0) {
      filteredProductsByCategory[category] = products;
    }
  });

  setFilteredProductsByCategory(filteredProductsByCategory);

  if (filteredProducts.length === 1) {
    setActiveProduct({
      id: filteredProducts[0].productId,
      case: planningType,
      value: '',
      shouldResetSearchInput: true,
    });

    if (
      filteredProducts[0].name.toLowerCase() !== research.toLowerCase() &&
      filteredProducts[0].sku !== research
    ) {
      return;
    }

    const canIncrementStockOrLosses =
      (canIncrementProductionLossesOnEANScan(authorizedActions) &&
        planningType === planningTypeEnum.LOSSES) ||
      (canIncrementProductionStocksOnEANScan(authorizedActions) &&
        planningType === planningTypeEnum.STOCKS);

    if (canIncrementStockOrLosses && !activeProduct.id) {
      await handleInventorySave(updatedProps, filteredProducts[0], 1, planningType);
      setActiveProduct({ ...EMPTY_ACTIVE_PRODUCT, isScanAdd: true, shouldResetSearchInput: true });
      setFilteredProducts(products);
      setFilteredProductsByCategory(productsByCategory);
    }
  }
};

/**
 * Set the product matching the given product id as successful
 *
 * @param {String} productId  - The id of the product on which the previous call was successfull
 * @param {Props} props       - The props linked to the component
 *
 * @returns {void}
 */
export function handleSuccessCall(productId, props) {
  const { setProductIdSuccess } = props;

  setProductIdSuccess(productId);

  setTimeout(() => {
    setProductIdSuccess(null);
  }, 2000);
}

/**
 * Set the product matching the given product id as failed
 *
 * @param {String} productId  - The id of the product on which the previous call failed
 * @param {Props} props       - The props linked to the component
 *
 * @returns {void}
 */
export function handleErrorCall(productId, props) {
  const { setProductIdError } = props;

  setProductIdError(productId);

  setTimeout(() => {
    setProductIdError(null);
  }, 2000);
}

export async function handleInventorySaveInContext(
  props,
  product,
  value,
  planningType,
  currentDate,
) {
  const {
    selectedStore,
    productionInventoryRawData,
    setProductionInventoryRawData,
    productionRequestsToPlay,
    setProductionRequestsToPlay,
  } = props;

  const formattedDate = moment(currentDate).format('YYYY-MM-DD');

  const updatedProductionInventory = cloneDeep(
    productionInventoryRawData[planningType][formattedDate],
  );

  if (!updatedProductionInventory || !updatedProductionInventory[planningType][product.productId]) {
    handleErrorCall(product.productId, props);

    return;
  }

  updatedProductionInventory[planningType][product.productId][planningType] += value;

  const payloadRequest = {
    storeId: selectedStore.storeId,
    productId: product.productId,
    timestamp: currentDate.format(),
    tot: value,
    type: planningType,
    price: product.price || 0,
  };

  const updatedProductPlanningRequestsToplay = Array.isArray(productionRequestsToPlay)
    ? productionRequestsToPlay.concat(payloadRequest)
    : [payloadRequest];

  setProductionRequestsToPlay(updatedProductPlanningRequestsToplay);

  set(
    productionInventoryRawData,
    `[${planningType}][${formattedDate}]`,
    updatedProductionInventory,
  );

  setProductionInventoryRawData(productionInventoryRawData);

  handleSuccessCall(product.productId, props);

  return refreshPlanningInventory({
    ...props,
    updatedProductionInventory: productionInventoryRawData,
  });
}

/**
 * Handle the update of the production planning raw data potentially store in the context
 * whenever the user is sending stock operations
 */
function updateProductionPlanningContextFromStockOperation(
  selectedStore,
  productionPlanningRawData,
  setProductionPlanningRawData,
  planningType,
  productId,
  tot,
  planningDate,
  products,
  productionStockConvention,
) {
  // Discard when it is not a stock operation
  if (planningType !== planningTypeEnum.STOCKS) {
    return;
  }

  // Do not update if context for production planning hasn't been loaded
  if (
    !productionPlanningRawData ||
    !get(productionPlanningRawData.data, `dataByRange[${productId}]`)
  ) {
    return;
  }

  if (selectedStore.storeId !== get(productionPlanningRawData, 'selectedStore.storeId')) {
    return;
  }

  const formattedDate = moment(planningDate)
    .tz(selectedStore.timezone)
    .add(productionStockConvention === 'before_opening' ? '1' : '0', 'day')
    .startOf('day')
    .format('YYYY-MM-DD');

  let productionPlanningOfTheDay = get(productionPlanningRawData, formattedDate);

  const isProductionPlanningOfTheDayLoaded = !!productionPlanningOfTheDay;

  // Set planning operation for the missing date to keep track of the stocks
  if (!isProductionPlanningOfTheDayLoaded) {
    const originalProductionPlanningRawData = get(productionPlanningRawData, 'data');

    const resetOriginalProductionPlanning = cloneDeep(originalProductionPlanningRawData);

    Object.keys(resetOriginalProductionPlanning.dataByRange).forEach((productId) => {
      resetOriginalProductionPlanning.dataByRange[productId].production = 0;
    });

    resetOriginalProductionPlanning.totalTurnoverProduced = 0;

    productionPlanningOfTheDay = resetOriginalProductionPlanning;
  }

  const updatedProductionPlanningData = cloneDeep(productionPlanningOfTheDay);

  const leftToProduce = updatedProductionPlanningData.dataByRange[productId].leftToProduce || 0;

  updatedProductionPlanningData.dataByRange[productId].leftToProduce = Math.max(
    0,
    leftToProduce - tot,
  );

  const recommendation = updatedProductionPlanningData.dataByRange[productId].recommendation || 0;

  updatedProductionPlanningData.dataByRange[productId].recommendation = Math.max(
    0,
    recommendation - tot,
  );

  const matchingProduct = products.find((item) => productId === item.productId);

  updatedProductionPlanningData.totalTurnoverToProduce =
    Math.ceil(
      (updatedProductionPlanningData.totalTurnoverToProduce -
        get(matchingProduct, 'price', 0) * tot) *
        100,
    ) / 100;

  const updatedProductionPlanningRawData = {
    ...productionPlanningRawData,
    [formattedDate]: updatedProductionPlanningData,
  };

  setProductionPlanningRawData(updatedProductionPlanningRawData);
}

/**
 * Handle the save of whether stock|loss (inventory) for a given product
 *
 * @param {Props} props               - The props linked to a component
 * @param {Product} product           - The product on which update the stock|loss
 * @param {Number|String} value       - The value to apply on the inventory call
 * @param {String} planningType       - The type of inventory on which the call is being made
 * @param {Boolean} confirmedByModal  - (optional) Whether the user validate through the confimation modal
 *
 * @returns {Promise<void>}
 */
export async function handleInventorySave(
  props,
  product,
  value,
  planningType,
  confirmedByModal = false,
) {
  const {
    products,
    selectedStore,
    warningModal,
    setWarningModal,
    showMessage,
    shouldUseContext,
    productionPlanningRawData,
    setProductionPlanningRawData,
    activeStores,
  } = props;

  if (!value || isNaN(value)) {
    return;
  }

  if (isNaN(value) || typeof value === 'string') {
    handleErrorCall(product.productId, props);
    return;
  }

  if (value > INPUT_NUMBER_MAX_VALUE && (!confirmedByModal || !warningModal.active)) {
    setWarningModal({ active: true, product, tot: value });

    return;
  }

  const currentDate = moment().tz(selectedStore.timezone);

  const matchingActiveStore = activeStores.find(
    (activeStore) => activeStore.id === selectedStore.storeId,
  );

  const productionStockConvention = get(
    matchingActiveStore,
    'productionStockConvention',
    'before_opening',
  );

  // Handle request to keep in local storage to be played later when context is being used
  if (shouldUseContext) {
    handleInventorySaveInContext(props, product, value, planningType, currentDate);

    // Update production planning data in context to take into consideration to stock operation
    return updateProductionPlanningContextFromStockOperation(
      selectedStore,
      productionPlanningRawData,
      setProductionPlanningRawData,
      planningType,
      product.productId,
      value,
      currentDate,
      products,
      productionStockConvention,
    );
  }

  try {
    await planningOperation.postOperation(
      selectedStore.storeId,
      product.productId,
      currentDate.format(),
      value,
      planningType,
      product.price || 0,
    );

    // Update production planning data in context in case internet connection is lost before switching to operations
    updateProductionPlanningContextFromStockOperation(
      selectedStore,
      productionPlanningRawData,
      setProductionPlanningRawData,
      planningType,
      product.productId,
      value,
      currentDate,
      products,
      productionStockConvention,
    );

    await refreshPlanningInventory(props);

    handleSuccessCall(product.productId, props);
  } catch (err) {
    showMessage(i18next.t('PRODUCTION.PRODUCTION.TOP_ERROR_MESSAGE_PRODUCED'), 'error');

    handleErrorCall(product.productId, props);
  }
}

/*********************/
/** Fetch Methods **/
/*********************/

/**
 * Fetch the products inventory (stock|loss) list for a given store id and a specific date
 *
 * @param {String} storeId        - The id of the store on which retrieve the products inventory list
 * @param {Date} date             - The date on which data should be retrieved
 * @param {String} planningType   - The type of inventory (stock|loss) on which products inventory are being retrieved
 * @param {Mathod} showMessage    - The method to display custom message in case of erreur
 *
 * @returns {Promise<any>} The inventory (stock|loss) products list
 */
export async function fetchPlanningInventoryData(
  storeId,
  date,
  planningType,
  showMessage,
  shouldUseContext,
  productionInventoryRawData,
  setProductionInventoryRawData,
) {
  const formattedDate = moment(date).format('YYYY-MM-DD');

  if (shouldUseContext) {
    const productionInventoryOfTheDay = get(
      productionInventoryRawData[planningType],
      formattedDate,
    );

    if (productionInventoryOfTheDay) {
      return productionInventoryOfTheDay;
    }

    const originalProductionInventoryRawData = get(
      productionInventoryRawData[planningType],
      'data',
    );

    let resetOriginalProductionInventory = cloneDeep(originalProductionInventoryRawData);

    if (isEmpty(resetOriginalProductionInventory)) {
      resetOriginalProductionInventory = {
        products: [],
        productsByCategory: {},
        [planningType]: {},
      };
    }

    Object.keys(resetOriginalProductionInventory[planningType]).forEach((productId) => {
      resetOriginalProductionInventory[planningType][productId][planningType] = 0;
    });

    setProductionInventoryRawData({
      ...productionInventoryRawData,
      [planningType]: {
        ...productionInventoryRawData[planningType],
        [formattedDate]: resetOriginalProductionInventory,
      },
    });

    return resetOriginalProductionInventory;
  }

  try {
    const planningInventory = await planningOperation.getInventoryOperations(
      storeId,
      date,
      planningType,
    );

    setProductionInventoryRawData({
      ...productionInventoryRawData,
      [planningType]: {
        data: cloneDeep(planningInventory),
        [formattedDate]: cloneDeep(planningInventory),
        selectedStore: productionInventoryRawData[planningType].selectedStore,
      },
    });

    return planningInventory;
  } catch (err) {
    if (planningType === planningTypeEnum.LOSSES) {
      showMessage(i18next.t('PRODUCTION.LOSSES.TOP_ERROR_MESSAGE'), 'error');
    } else if (planningType === planningTypeEnum.STOCKS) {
      showMessage(i18next.t('PRODUCTION.STOCKS.TOP_ERROR_MESSAGE'), 'error');
    }

    return null;
  }
}

/**
 * Handle the refresh of the inventory planning by fetching the relevant data and set it to the local state
 *
 * @param {Object} props - The props linked to the component
 *
 * @returns {Promise<void>}
 */
export async function refreshPlanningInventory(props) {
  const {
    selectedStore,
    planningDate,
    planningType,
    showMessage,
    setLossByProduct,
    setStockByProduct,
    setDisplayProductsByCategory,
    setProductsByCategory,
    setProducts,
    setStockOrLossTurnover,
    setStockOrLossTurnoverByBrands,
    productionInventoryRawData,
    setProductionInventoryRawData,
    shouldUseContext,
  } = props;

  if (!planningDate || !moment(planningDate).isValid()) {
    return;
  }

  const result = await fetchPlanningInventoryData(
    selectedStore.storeId,
    planningDate.format(),
    planningType,
    showMessage,
    shouldUseContext,
    productionInventoryRawData,
    setProductionInventoryRawData,
  );

  if (!result) {
    return;
  }

  const hasMultipleProductCategories =
    Object.keys(result.productsByCategory).length > 1 || !result.productsByCategory[OTHER_CATEGORY];

  // Force category other to be the last displayed
  if (!!result.productsByCategory[OTHER_CATEGORY]) {
    const copyProductsOtherCategory = result.productsByCategory[OTHER_CATEGORY];

    delete result.productsByCategory[OTHER_CATEGORY];

    result.productsByCategory[OTHER_CATEGORY] = copyProductsOtherCategory;
  }

  // Whether we display the list of products by category
  setDisplayProductsByCategory(hasMultipleProductCategories);

  // Set list of products grouped by categories
  const sortedProductsByCategory = keys(result.productsByCategory).reduce((object, category) => {
    object[category] = orderBy(result.productsByCategory[category], 'name');

    return object;
  }, {});
  setProductsByCategory(sortedProductsByCategory);

  // Set list of products
  setProducts(orderBy(flatten(Object.values(result.productsByCategory)), 'name'));

  setLossByProduct(result.loss);
  setStockByProduct(result.stock);

  setStockOrLossTurnover(result.total);
  setStockOrLossTurnoverByBrands(result.totalByBrands);
}

export async function handleProductionPendingRequest(payload) {
  const { storeId, productId, timestamp, tot, type, price } = payload;

  if (!storeId || !productId || !timestamp || !tot || !type || !price) {
    return false;
  }

  try {
    await planningOperation.postOperation(storeId, productId, timestamp, tot, type, price, true);

    return true;
  } catch (err) {
    return false;
  }
}

export async function processPendingRequestInventoryOperation(props) {
  const {
    pageLoaded,
    pageLoading,
    showMessage,
    confirmationMessageReducer,
    productionRequestsToPlay,
    setProductionRequestsToPlay,
  } = props;

  pageLoading();

  const payload = first(productionRequestsToPlay);

  const successful = await handleProductionPendingRequest(payload);

  if (successful) {
    setProductionRequestsToPlay(productionRequestsToPlay.filter((item) => !isEqual(item, payload)));
  } else {
    pageLoaded();
  }

  if (productionRequestsToPlay.length === 1 && successful) {
    showMessage(
      i18next.t('PRODUCTION.PRODUCTION.OFFLINE_REQUEST_SYNCHRONIZED'),
      'success',
      confirmationMessageReducer.messageBool,
    );

    await refreshPlanningInventory(props);

    pageLoaded();
  }

  return successful;
}

/**
 * Check whether the store is closed on the selected date
 *
 * @param {Store} selectedStore - The store selected by the user
 * @param {Date} planningDate   - The selected date in the datepicker
 *
 * @returns {Boolean} Whether the store is closed on the selected planning date
 */
export function isStoreClosedOnDate(selectedStore, planningDate) {
  const isoWeekDaysStoreClosed = selectedStore.closingDays
    ? selectedStore.closingDays.split(',').map((day) => parseInt(day, 10))
    : [];

  return isoWeekDaysStoreClosed.includes(planningDate.isoWeekday() - 1);
}

export const InventoryContainer = (props) => {
  const {
    pageLoading,
    pageLoaded,
    showMessage,
    client: { hasOffline },
    isUserOffline,
    isConnectionSlow,
    confirmationMessageReducer,
    match,
    authorizedActions,
  } = props;

  const [planningType, setPlanningType] = useState('');

  useLayoutEffect(() => {
    const path = get(match, 'path', '');

    const type = last(path.split('/'));

    setPlanningType(planningTypeEnum[type.toUpperCase()]);
  }, [match]);

  const [planningDate, setPlanningDate] = useState(moment());

  const [barcodeScannerElement] = useState(React.createRef());

  const [isLoading, setIsLoading] = useState(true);

  const [stores, setStores] = useState([{}]);
  const [selectedStore, setSelectedStore] = useState({});

  const [products, setProducts] = useState([]);
  const [lossByProduct, setLossByProduct] = useState({});
  const [stockByProduct, setStockByProduct] = useState({});
  const [productsByCategory, setProductsByCategory] = useState({});
  const [displayProductsByCategory, setDisplayProductsByCategory] = useState(true);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredProductsByCategory, setFilteredProductsByCategory] = useState({});

  const [stockOrLossTurnover, setStockOrLossTurnover] = useState(0);
  const [stockOrLossTurnoverByBrands, setStockOrLossTurnoverByBrands] = useState(null);
  const [useCase, setUseCase] = useState(period.CURRENT);
  const [activeProduct, setActiveProduct] = useState(EMPTY_ACTIVE_PRODUCT);

  const [productIdError, setProductIdError] = useState(null);
  const [productIdSuccess, setProductIdSuccess] = useState(null);

  const [warningModal, setWarningModal] = useState(DEFAULT_WARNING_MODAL_SETUP);

  const [shouldUseContext, setShouldUseContext] = useState(isUserOffline || isConnectionSlow);

  const [isSynchronisationInProgress, setIsSynchronisationInProgress] = useState(false);

  const [productionRequestsToPlay, setProductionRequestsToPlay] = useLocalStorage(
    'productionRequestsToPlay',
    [],
  );

  const [sortProperties, setSortProperties] = useState({
    name: '',
    order: 'desc',
  });

  const {
    productionInventoryRawData,
    setProductionInventoryRawData,
    productionPlanningRawData,
    setProductionPlanningRawData,
  } = useContext(FiltersContext);

  const updatedProps = {
    ...props,
    planningType,
    products,
    pageLoading,
    pageLoaded,
    warningModal,
    planningDate,
    selectedStore,
    setProducts,
    setStockOrLossTurnover,
    setStockOrLossTurnoverByBrands,
    setWarningModal,
    setLossByProduct,
    setStockByProduct,
    setProductIdError,
    setProductIdSuccess,
    setProductsByCategory,
    setDisplayProductsByCategory,
    shouldUseContext,
    productionPlanningRawData,
    setProductionPlanningRawData,
    productionInventoryRawData,
    setProductionInventoryRawData,
    productionRequestsToPlay,
    setProductionRequestsToPlay,
  };

  const [, setShowReloadPageWarning] = useReloadPageWarning(true);

  // To remove handler when user leaves the page
  useEffect(
    () => () => {
      setShowReloadPageWarning(false);
    },
    [],
  );

  useEffect(() => {
    setShowReloadPageWarning(shouldUseContext);
  }, [shouldUseContext]);

  // Initial loading that fetches the list of stores that the current user is allowed to see
  useEffect(() => {
    pageLoading();

    (async function loadData() {
      let storesUser = [];

      if (shouldUseContext) {
        const selectedStoreInContext = get(
          productionInventoryRawData[planningType],
          'selectedStore',
          {
            storeId: '',
            storeName: '',
            closingDays: '',
            timezone: '',
          },
        );

        storesUser = [
          {
            id: selectedStoreInContext.storeId,
            name: selectedStoreInContext.storeName,
            closingDays: selectedStoreInContext.closingDays,
            timezone: selectedStoreInContext.timezone,
          },
        ];
      } else {
        storesUser = await fetchStoresUser(showMessage, true);
      }

      setStores(storesUser.filter(({ isKitchen }) => !isKitchen));

      if (storesUser.length) {
        const store = {
          storeId: storesUser[0].id,
          storeName: storesUser[0].name,
          closingDays: storesUser[0].closingDays,
          timezone: storesUser[0].timezone,
        };

        setSelectedStore(store);
        setPlanningDate(moment().tz(store.timezone));

        set(productionInventoryRawData, `[${planningType}].selectedStore`, store);

        setProductionInventoryRawData(productionInventoryRawData);
      }

      pageLoaded();
    })();
  }, []);

  useEffect(() => {
    const updatedShouldUseContext = hasOffline && (isUserOffline || isConnectionSlow);

    if (shouldUseContext !== updatedShouldUseContext) {
      setShouldUseContext(updatedShouldUseContext);

      if (!updatedShouldUseContext) {
        (async function loadData() {
          // Retrieve and set all stores in state
          const storesUser = await fetchStoresUser(showMessage, true);

          setStores(storesUser.filter(({ isKitchen }) => !isKitchen));

          if (!selectedStore.storeId && storesUser.length) {
            const store = {
              storeId: storesUser[0].id,
              storeName: storesUser[0].name,
              closingDays: storesUser[0].closingDays,
              timezone: storesUser[0].timezone,
            };

            setSelectedStore(store);

            set(productionInventoryRawData, `[${planningType}].selectedStore`, store);

            setProductionInventoryRawData(productionInventoryRawData);
          }
        })();
      }
    }
  }, [hasOffline, isUserOffline, isConnectionSlow]);

  useEffect(() => {
    if (shouldUseContext) {
      return;
    }

    if (!productionRequestsToPlay.length) {
      return;
    }

    if (isSynchronisationInProgress || !selectedStore.storeId) {
      return;
    }

    setIsSynchronisationInProgress(true);

    if (productionRequestsToPlay.length >= MAXIMUM_REQUEST_IN_PENDING_TO_PLAY_IN_BACKGROUND) {
      props.openModal({
        component: OfflineSynchronisationModal,
        showMessage,
        confirmationMessageReducer,
        setIsSynchronisationInProgress,
        refreshPage: () =>
          refreshPlanningInventory({
            ...props,
            planningType,
            products,
            pageLoading,
            pageLoaded,
            warningModal,
            planningDate,
            selectedStore,
            setProducts,
            setStockOrLossTurnover,
            setStockOrLossTurnoverByBrands,
            setWarningModal,
            setLossByProduct,
            setStockByProduct,
            setProductIdError,
            setProductIdSuccess,
            setProductsByCategory,
            setDisplayProductsByCategory,
            shouldUseContext,
            productionPlanningRawData,
            setProductionPlanningRawData,
            productionInventoryRawData,
            setProductionInventoryRawData,
            productionRequestsToPlay,
            setProductionRequestsToPlay,
          }),
      });

      return;
    }

    (async function playPendingRequests() {
      const successful = await processPendingRequestInventoryOperation(updatedProps);

      if (successful) {
        setIsSynchronisationInProgress(false);
      }
    })();
  }, [productionRequestsToPlay, shouldUseContext, selectedStore]);

  useEffect(() => {
    if (isSynchronisationInProgress) {
      return;
    }

    try {
      const item = window.localStorage.getItem('productionRequestsToPlay');

      if (item) {
        const parseLocalStorageValue = JSON.parse(item);

        setProductionRequestsToPlay(parseLocalStorageValue);
      }
    } catch (error) {
      console.log(error);
    }
  }, [isSynchronisationInProgress]);

  // Handle the fetch of the products inventory list
  useEffect(() => {
    if (selectedStore.storeId && planningDate) {
      if (isStoreClosedOnDate(selectedStore, planningDate)) {
        setUseCase(period.CLOSED);
        setIsLoading(false);

        return;
      }

      pageLoading();

      setIsLoading(true);

      (async function loadData() {
        await refreshPlanningInventory(updatedProps);

        pageLoaded();

        setIsLoading(false);
      })();
    }
  }, [selectedStore]);

  // Clear active inputs whenever an update of the quantities on a product was successful
  useEffect(() => {
    if (activeProduct.id == '' && activeProduct.isScanAdd) {
      if (activeProduct.shouldResetSearchInput && barcodeScannerElement.current) {
        barcodeScannerElement.current.resetInputs();
      }
    }

    if (activeProduct.id === productIdSuccess) {
      setActiveProduct(EMPTY_ACTIVE_PRODUCT);

      if (activeProduct.shouldResetSearchInput && barcodeScannerElement.current) {
        barcodeScannerElement.current.resetInputs();
      }
    }
  }, [productIdSuccess, activeProduct]);

  // Update the state of the filtered products that is used for the rendering
  useEffect(() => {
    const searchInputValue = get(barcodeScannerElement.current, 'state.searchValue');

    if (!searchInputValue) {
      setFilteredProducts(products);
      setFilteredProductsByCategory(productsByCategory);

      return;
    }

    handleSelectedBarcode(
      searchInputValue,
      products,
      planningType,
      productsByCategory,
      displayProductsByCategory,
      setActiveProduct,
      setFilteredProducts,
      setFilteredProductsByCategory,
      activeProduct,
      productIdSuccess,
      updatedProps,
      authorizedActions,
    );
  }, [products, productsByCategory]);

  const handleSort = (name, category) => {
    let sortedProducts;

    const updatedOrder =
      sortProperties.name !== name ? 'asc' : sortProperties.order === 'desc' ? 'asc' : 'desc';

    const productsGroupedById = keyBy(filteredProducts, 'productId');

    const inventoryData = planningType === planningTypeEnum.STOCKS ? stockByProduct : lossByProduct;

    keys(inventoryData).forEach((productId) => {
      if (productsGroupedById[productId]) {
        productsGroupedById[productId].total =
          Math.round(
            productsGroupedById[productId].price * inventoryData[productId][planningType] * 100,
          ) / 100;

        productsGroupedById[productId] = mergeWith(
          productsGroupedById[productId],
          inventoryData[productId],
        );
      }
    });

    if (displayProductsByCategory) {
      sortedProducts = sortProductsByProperty(
        filteredProductsByCategory[category],
        productsGroupedById,
        name,
        updatedOrder,
      );

      productsByCategory[category] = sortedProducts;
      setProductsByCategory(productsByCategory);
    } else {
      setProducts(
        sortProductsByProperty(filteredProducts, productsGroupedById, name, updatedOrder),
      );
    }

    setSortProperties({ name, category, order: updatedOrder });
  };

  const isPageRenderableInOffline = canPageBeRenderedInOffline(
    match,
    isUserOffline,
    planningType === planningTypeEnum.STOCKS
      ? productionInventoryRawData.stock.data
      : productionInventoryRawData.loss.data,
  );

  if (hasOffline && !isPageRenderableInOffline) {
    return (
      <Container>
        <NavigationBreadCrumb featurePath={updatedProps.match.path} />
        <OfflineProductionPage />
      </Container>
    );
  }
  return (
    <Container>
      <NavigationBreadCrumb featurePath={props.match.path} />
      <ContainerContent>
        {warningModal.active && (
          <WarningModal
            cancelConfirmation={() => setWarningModal(DEFAULT_WARNING_MODAL_SETUP)}
            handleConfirmation={() => {
              handleInventorySave(
                updatedProps,
                warningModal.product,
                warningModal.tot,
                planningType,
                true,
              );
              setWarningModal(DEFAULT_WARNING_MODAL_SETUP);
            }}
            operationType={planningType}
            product={warningModal.product}
            tot={warningModal.tot}
          />
        )}

        <PlanningHeader
          barcodeScannerElement={barcodeScannerElement}
          handleSelectedBarcode={(search) =>
            handleSelectedBarcode(
              search,
              products,
              planningType,
              productsByCategory,
              displayProductsByCategory,
              setActiveProduct,
              setFilteredProducts,
              setFilteredProductsByCategory,
              activeProduct,
              productIdSuccess,
              updatedProps,
              authorizedActions,
            )
          }
          handleSelectedDate={() => true}
          handleSelectedStore={(storeName, storeId, closingDays, timezone) => {
            const store = { storeName, storeId, closingDays, timezone };

            setSelectedStore(store);

            setProductionInventoryRawData({
              ...productionInventoryRawData,
              [planningType]: {
                ...productionInventoryRawData[planningType],
                selectedStore: store,
              },
            });
          }}
          planningDate={planningDate}
          planningType={planningType}
          readOnly={shouldUseContext}
          selectedStore={selectedStore}
          stockOrLossTurnover={stockOrLossTurnover}
          stores={stores}
          totalByBrands={stockOrLossTurnoverByBrands}
          useCase={useCase}
        />

        {!isLoading && !filteredProducts.length && useCase !== period.CLOSED && (
          <FullHeightContainer>
            <ShadowedBox>{i18next.t('PRODUCTION.PRODUCTION.NO_PRODUCT')}</ShadowedBox>
          </FullHeightContainer>
        )}

        {!isLoading && (filteredProducts.length || useCase === period.CLOSED) && (
          <PlanningListing
            activeProduct={activeProduct}
            displayProductsByCategory={displayProductsByCategory}
            handleLossSave={(product, value) =>
              handleInventorySave(updatedProps, product, value, planningType)
            }
            handleSort={handleSort}
            handleStockSave={(product, value) =>
              handleInventorySave(updatedProps, product, value, planningType)
            }
            isUserOffline={shouldUseContext}
            lossByProduct={lossByProduct}
            planningDate={planningDate}
            planningType={planningType}
            productError={productIdError}
            products={filteredProducts}
            productsByCategory={filteredProductsByCategory}
            productSuccess={productIdSuccess}
            setActiveProduct={setActiveProduct}
            sortProperties={sortProperties}
            stockByProduct={stockByProduct}
            useCase={useCase}
          />
        )}
      </ContainerContent>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  activeStores: state.baseReducer.activeStores,
  isUserOffline: state.baseReducer.isUserOffline,
  isConnectionSlow: state.baseReducer.isConnectionSlow,
  confirmationMessageReducer: state.confirmationMessageReducer,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: [
    ...getAuthorizedActions(state.baseReducer.userRights, '/production/losses'),
    ...getAuthorizedActions(state.baseReducer.userRights, '/production/stocks'),
  ],
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type, isToasterDisplayed) => {
    setTimeout(
      () => dispatch(showConfirmationMessage(message, type)),
      isToasterDisplayed ? 3000 : 0,
    );
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openModal: (params) => {
    dispatch(openSmallModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryContainer);
