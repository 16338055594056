export const PRODUCTION_PLANNING_TAB_INDEXES = {
  RECIPES: 0,
  PREPARATIONS: 1,
  INGREDIENTS: 2,
};

export const CONSUMPTION_STRATEGY = {
  ORDERS: 'orders',
  PRODUCT_MIX: 'product_mix',
};
