import { SingleDatePicker } from 'react-dates';
import MomentPropTypes from 'react-moment-proptypes';
import PropTypes from 'prop-types';
import React from 'react';

import { Container } from './styledComponents';
import { DATE_DISPLAY_FORMATS } from '../constants';
import { DatePickerInputIcon } from '../DatePickerIcon';

/**
 * A disabled SingleDatePicker allowing to easily add to a page a Date element that has no interaction.
 *
 * Usage example in: StocksAnalyticsCurrent
 */
export const DisabledDatePicker = (props) => {
  const { date, displayFormat, customWidth = 240 } = props;

  return (
    <Container width={customWidth}>
      <SingleDatePicker
        customInputIcon={DatePickerInputIcon(date, true)}
        date={date}
        disabled={true}
        displayFormat={displayFormat}
        timezone="Europe/Paris" // TODO - [TIMEZONES]
        onDateChange={() => {}}
        onFocusChange={() => {}}
      />
    </Container>
  );
};

DisabledDatePicker.propTypes = {
  date: MomentPropTypes.momentObj.isRequired,
  displayFormat: PropTypes.oneOf(Object.values(DATE_DISPLAY_FORMATS)),
};

DisabledDatePicker.defaultProps = {
  displayFormat: DATE_DISPLAY_FORMATS.CONDENSED_DAY_DATE_MONTH,
};
