import i18next from 'i18next';

import InputContentModal from '@commons/Modals/InputContentModal';

import ConfirmationModal from '@backoffice/components/ConfirmationModal';

export const getCreateGroupModalConfig = ({
  name,
  onInputChange,
  forbiddenNames,
  handleCreateGroup,
}) => {
  const errorMessage = forbiddenNames.includes(name.toLowerCase())
    ? i18next.t('BACKOFFICE.GROUPS.CREATE_MODAL_DUPLICATE_NAME')
    : '';

  return {
    type: 'action',
    width: '542px',
    height: 'auto',
    icon: '/images/inpulse/add-black-small.svg',
    title: i18next.t('BACKOFFICE.GROUPS.CREATE_MODAL_TITLE'),
    component: InputContentModal,
    data: {
      value: name,
      errorMessage,
      label: `* ${i18next.t('GENERAL.NAME')}`,
      onChange: (value) => onInputChange(value),
    },
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.SAVE'),
        icon: '/images/inpulse/save-white-small.svg',
        handleClick: () => handleCreateGroup(name),
        isDisabled: !name || name === '' || !!errorMessage,
      },
    ],
  };
};

export const getGroupEditModalParams = ({
  group,
  groupName,
  errorMessage,
  handleEditGroup,
  onGroupNameEditionChange,
}) => {
  const isInputEqualToCurrentGroupName = groupName === group.name;

  return {
    type: 'action',
    width: '560px',
    height: 'auto',
    icon: '/images/inpulse/edit-pen-black-small.svg',
    title: i18next.t('BACKOFFICE.GROUPS.EDIT'),
    component: InputContentModal,
    data: {
      value: groupName,
      onChange: (value) => onGroupNameEditionChange(value, group),
      errorMessage: !isInputEqualToCurrentGroupName && errorMessage,
      label: `* ${i18next.t('GENERAL.NAME')}`,
    },
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.SAVE'),
        icon: '/images/inpulse/save-white-small.svg',
        isDisabled: !!errorMessage || isInputEqualToCurrentGroupName,
        handleClick: () => handleEditGroup(groupName, group),
      },
    ],
  };
};

export const getDeleteGroupModalConfig = ({ groups, handleDeleteGroupById }) => {
  const groupIds = groups.map((group) => group.id);

  return {
    type: 'warning',
    width: '542px',
    height: 'auto',
    icon: '/images/inpulse/trash-white-small.svg',
    title:
      groupIds.length > 1
        ? i18next.t('BACKOFFICE.GROUPS.DELETE_MODAL_TITLE_PLURAL')
        : i18next.t('BACKOFFICE.GROUPS.DELETE_MODAL_TITLE_SINGULAR'),
    component: ConfirmationModal,
    data: {
      content:
        groupIds.length > 1
          ? i18next.t('BACKOFFICE.GROUPS.DELETE_MODAL_CONTENT_PLURAL')
          : i18next.t('BACKOFFICE.GROUPS.DELETE_MODAL_CONTENT_SINGULAR'),
    },
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.DELETE'),
        icon: '/images/inpulse/trash-white-small.svg',
        handleClick: () => handleDeleteGroupById(groupIds),
      },
    ],
  };
};

export default {
  getGroupEditModalParams,
  getCreateGroupModalConfig,
  getDeleteGroupModalConfig,
};
