import styled, { css } from 'styled-components';

export const InpulseDateContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CircleContainer = styled.div`
  width: 100%;
  height: 100%;
  display: ${(props) => (props.renderCircle ? 'flex' : 'none')};
`;

export const Circle = styled.div`
  margin: auto;
  flex-direction: column;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: ${(props) => props.theme.colors?.brand.primaryLight} !important;
  display: ${(props) => (props.renderCircle ? 'flex' : 'none')};
`;

export const InpulseDate = styled.div`
  display: ${(props) => !props.renderCircle && 'none'};
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DotContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;

  ${(props) => {
    if (props.renderCircle) {
      return css`
        position: absolute;
        margin-left: 27px;
        margin-top: 2px;
      `;
    }
  }}
`;

export const Dot = styled.div`
  float: right;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors?.brand.primary};
  margin-right: 5px;
  display: ${(props) => (props.isDot ? 'flex' : 'none')};
`;

export const Date = styled.div``;

export default {
  InpulseDateContainer,
  InpulseDate,
  DateContainer,
  DotContainer,
  CircleContainer,
  Dot,
  Circle,
  Date,
};
