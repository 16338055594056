import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  ${(props) =>
    props.theme.fonts ? `display: flex; flex-direction: column; align-items: center;` : undefined}
  background: ${(props) => props.theme.colors?.greys.lightest || 'white'};
  padding: 25px 35px;
  border-radius: ${(props) => props.theme.borders?.radius.weak || '10px'};
`;

export const Content = styled.div`
  display: flex;
  &.header {
    ${(props) =>
      props.theme.fonts
        ? `flex-direction: column;
          align-items: flex-start;
          align-self: flex-start;`
        : undefined}
    margin-bottom: 20px;
  }

  &.content {
    ${(props) => (props.theme.fonts ? 'width:80%;' : undefined)}
    justify-content: ${(props) => (props.theme.fonts ? 'space-evenly' : 'center')};
  }

  &.footer {
    margin-top: 40px;
    ${(props) =>
      props.theme.fonts
        ? `justify-content: space-evenly;
    width: 80%;`
        : undefined}
    > div:first-child {
      margin-left: 10px;
    }

    > div:last-child {
      margin-right: 10px;
    }
  }
`;

export const Section = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;

  &:first-child {
    align-items: flex-start;
  }

  &:last-child {
    align-items: flex-end;
  }

  .deepsight-tooltip-img-blue {
    margin-left: 3px;
    margin-top: -2px;
  }

  .reco {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background: ${(props) => props.theme.colors?.boxBackgrounds.blue};
    border-radius: ${(props) => props.theme.borders?.radius.weak};
    padding: 4px 8px 4px 8px;
    width: 7em;
  }
`;

export const Text = styled.div`
  text-align: left;

  display: flex;

  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts?.textBig};
      color: ${props.theme.colors.greys.darkest};`
      : `color: #000000;
  font-size: 14px;
  line-height: 16px;`}

  margin-bottom: 4px;

  &.grey {
    color: ${(props) => props.theme.colors?.greys.dark || '#6b768c'};
  }

  &.blue {
    color: #0a3eff;
  }

  &.bold {
    font-weight: 600;
  }

  &.big {
    font-size: 18px;
    line-height: 18px;
  }

  &.medium {
    ${(props) =>
      props.theme.fonts
        ? `font:${props.theme.fonts?.textSmall};`
        : `font-size: 16px;
    line-height: 16px;`}
  }

  &.align-center {
    text-align: center;
  }
`;

export const BoxInput = styled.input`
  width: 60px;
  height: 60px;
  margin-right: 15px;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
  color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};
  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts?.kpiBig};`
      : `font-size: 18px;
  font-weight: 600;
  line-height: 20px;`}
  text-align: center;

  display: flex;
  align-items: center;

  background: ${(props) => props.theme.colors?.greys.lightest || '#fff'};
  box-sizing: border-box;
  border: ${(props) => `1px solid ${props.theme.colors?.greys.darkest || '#6b768c'}`};

  border-radius: ${(props) => props.theme.borders?.radius.weak || '3px'};
  box-shadow: ${(props) => (props.theme.colors ? undefined : '0px 2px 6px rgba(0, 0, 0, 0.1)')};
`;

export const Box = styled.div`
  width: 60px;
  height: 60px;

  color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};
  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts?.kpiBig};`
      : `font-size: 18px;
  font-weight: 600;
  line-height: 18px;`}

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.colors?.greys.lightest || '#fff'};

  border: ${(props) =>
    props.theme.borders ? `1px solid ${props.theme.colors.greys.light}` : undefined};
  border-radius: ${(props) => props.theme.borders?.radius.weak || '3px'};
  box-shadow: ${(props) => (props.theme.colors ? undefined : '0px 3px 6px rgba(0, 0, 0, 0.1)')};

  > img {
    width: 15px;
  }

  &:first-child {
    margin-left: ${(props) => (props.theme.fonts ? undefined : '15px')};
  }

  &.clickable:hover {
    cursor: pointer;
  }

  &.empty {
    background: inherit;
    box-shadow: none;
    border: none;
  }
`;
