import { connect } from 'react-redux';
import _ from 'lodash';
// import hmacSHA256 from 'crypto-js/hmac-sha256';
import React, { useEffect } from 'react';

import { IntercomProvider, useIntercom } from 'react-use-intercom';

import { createIntercomContact } from '../../services/intercomServices';
import { getClientInfo } from '../../selectors/client';

const INTERCOM_APP_ID = 'oegdlauk';

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
});

const Container = connect(
  mapStateToProps,
  null,
)(({ user, client: { clientId }, children, isAuthenticated }) => {
  const { boot, shutdown } = useIntercom();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if (!_.isEmpty(user)) {
        (async function logAndCreateContact() {
          try {
            await createIntercomContact(clientId, user.id);

            // To uncomment if we activate user identity verification on Intercom
            // const userHash = hmacSHA256(
            //   user.email,
            //   process.env.INTERCOM_IDENTITY_SECRET,
            // ).toString();

            boot({
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
              clientId,
              // To uncomment if we activate user identity verification on Intercom
              // userHash,
            });
          } catch {}
        })();
        return;
      }

      if (!isAuthenticated) {
        // So that messenger box appears in login page
        shutdown(); // if not shut down, the name of the previously logged user will appear in the login page.
        boot();
      }
    }
  }, [user]);

  return <>{children}</>;
});

export const IntercomContainer = (props) => {
  const { children, isAuthenticated } = props;

  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <Container isAuthenticated={isAuthenticated}>{children}</Container>
    </IntercomProvider>
  );
};

export default IntercomContainer;
