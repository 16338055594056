import i18next from 'i18next';

export const CENTRAL_STORE_TYPE_ID = 'centrale';

export const POINT_OF_SALE_STORE_TYPE_ID = 'point_of_sale';

export const STORE_TYPES_DROPDOWN_ITEMS = [
  { id: POINT_OF_SALE_STORE_TYPE_ID, value: i18next.t('BACKOFFICE.STORES.POINT_OF_SALE') },
  { id: CENTRAL_STORE_TYPE_ID, value: i18next.t('BACKOFFICE.GENERAL.CENTRAL', { count: 1 }) },
];
