import { APIcaller } from './APIcaller';

const getMetricsByDates = (storeId, startDate, endDate) =>
  APIcaller.apiGet('/production/metrics', {
    storeId,
    startDate,
    endDate,
  });

const createMetrics = (productionMetrics) =>
  APIcaller.apiPost('/production/metrics', {
    productionMetrics,
  });

export const productionMetrics = {
  createMetrics,
  getMetricsByDates,
};
