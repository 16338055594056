import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const Container = styled.div`
  height: 100%;
  width: 240px;
  display: flex;
  flex-direction: column;
  position: relative;

  border-left: 1px solid ${(props) => props.theme.colors.greys.darker};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 16px;

  ::after {
    content: '';
    position: absolute;
    height: 1px;
    left: 8px;
    right: 8px;
    top: 64px;
    border-bottom: solid 1px ${(props) => props.theme.colors.greys.darker};
  }
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 32px;
  width: 16px;

  transition: filter 0.3s;

  &:hover {
    -webkit-filter: url(#colorize);
    filter: url(#colorize);
  }
`;

const CloseIcon = styled.img``;

const NavItemList = styled.div`
  display: flex;
  flex: 1;
  overflow-y: scroll;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 16px;

  ${({ scrollable, hasReachedTop, hasReachedBottom, theme }) => {
    if (!scrollable) {
      return;
    }

    switch (true) {
      // display both top box-shadow and bottom box-shadow
      case !hasReachedTop && !hasReachedBottom:
        return css`
          box-shadow: inset 0 80px 80px -80px ${theme.colors.dark},
            inset 0 -80px 80px -80px ${theme.colors.dark};
        `;
      // display top box-shadow
      case hasReachedBottom:
        return css`
          box-shadow: inset 0 80px 80px -80px ${theme.colors.dark};
        `;
      // by default, display bottom box-shadow (includes if hasReachedTop is true)
      default:
        return css`
          box-shadow: inset 0 -80px 80px -80px ${theme.colors.dark};
        `;
    }
  }}
`;

const SubModuleContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 8px 0;
  margin-top: 8px;

  border-bottom: solid 1px ${(props) => props.theme.colors.greys.darker};
`;

const Icon = styled.img`
  max-width: 16px;
  filter: brightness(0) invert(0.5);
`;

const PageContainer = styled.div`
  display: flex;
  height: 48px;
  width: 100%;
  padding: 8px 0;
  cursor: pointer;

  align-items: center;

  border-bottom: solid 1px ${(props) => props.theme.colors.greys.darker};
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
  padding: 0 8px;

  text-decoration: none;
  transition: color 0.3s; /* Add a smooth transition effect */
  color: ${(props) => (props.isActive ? props.theme.colors.green.light : props.theme.colors.white)};

  ${(props) =>
    props.isActive &&
    css`
      font-weight: bold;
    `}

  &:hover {
    color: ${(props) => props.theme.colors.green.light};
    text-decoration: none;
  }
`;

export {
  Container,
  Header,
  CloseButton,
  CloseIcon,
  NavItemList,
  SubModuleContainer,
  Icon,
  PageContainer,
  StyledLink,
};
