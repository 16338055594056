import styled from 'styled-components';

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 352px;
  padding-top: 24px;

  row-gap: 8px;

  overflow: scroll;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  border: 1px solid ${(props) => props.theme.colors.greys.light};
  border-radius: 8px;
  padding: 12px 16px 12px 16px;

  gap: 8px;
`;
