import { get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

import { LOSS_TYPE_TRANSLATIONS } from '@losses/LossesLosses/components/LossListView/constants';

import { PROP_KEYS } from './sheets';

// For one ingredient's analytics, return an object matching the sheet columns keys
const extractIngredientData = (
  currency,
  storeName,
  { name, entityUnit, lossBySP, lossByProducts, total },
) => ({
  [PROP_KEYS.STORE_NAME]: storeName,
  [PROP_KEYS.INGREDIENT_NAME]: name,
  [PROP_KEYS.SUPPLIER_PRODUCT_LOSS_CURRENCY]: lossBySP.currency,
  [PROP_KEYS.PRODUCT_LOSS_CURRENCY]: lossByProducts.currency,
  [PROP_KEYS.TOTAL_LOSS_CURRENCY]: total.currency,
  [PROP_KEYS.CURRENCY]: currency.alphabeticCode,
  [PROP_KEYS.SUPPLIER_PRODUCT_LOSS_UNIT]: lossBySP.unit,
  [PROP_KEYS.PRODUCT_LOSS_UNIT]: lossByProducts.unit,
  [PROP_KEYS.TOTAL_LOSS_UNIT]: total.unit,
  [PROP_KEYS.UNIT]: entityUnit,
});

const extractCategoryData = (
  lossCategoryById,
  storeName,
  ingredientData,
  { lossCategoryId, lossType, currency, ratio, unit },
) => {
  const category = get(lossCategoryById, `${lossCategoryId}`, null);

  const categoryName = i18next.t(category.id ? category.translationKey : 'GENERAL.NONE_VALUE');

  const translatedLossType = LOSS_TYPE_TRANSLATIONS[lossType];

  return {
    [PROP_KEYS.STORE_NAME]: storeName,
    [PROP_KEYS.INGREDIENT_NAME]: ingredientData[PROP_KEYS.INGREDIENT_NAME],
    [PROP_KEYS.LOSS_CATEGORY]: categoryName,
    [PROP_KEYS.LOSS_TYPE]: translatedLossType,
    [PROP_KEYS.TOTAL_LOSS_CURRENCY]: currency,
    [PROP_KEYS.CURRENCY]: ingredientData[PROP_KEYS.CURRENCY],
    [PROP_KEYS.TOTAL_LOSS_UNIT]: unit,
    [PROP_KEYS.CATEGORY_RATIO]: ratio,
    [PROP_KEYS.UNIT]: ingredientData[PROP_KEYS.UNIT],
  };
};

const extractContextData = (context, currency) => {
  const storesNames = context.stores.map(({ name }) => name).join(', ');
  const startDate = moment(context.startDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);
  const endDate = moment(context.endDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);
  const ingredientCategories = context.ingredientCategories.map(({ value }) => value).join(', ');
  const lossCategories = context.lossCategories.map(({ value }) => value).join(', ');

  return {
    [PROP_KEYS.STORES]: storesNames,
    [PROP_KEYS.START_DATE]: startDate,
    [PROP_KEYS.END_DATE]: endDate,
    [PROP_KEYS.INGREDIENT_CATEGORIES]: ingredientCategories,
    [PROP_KEYS.LOSS_CATEGORIES]: lossCategories,
    [PROP_KEYS.TURNOVER]: context.turnover,
    [PROP_KEYS.LOSS_RATIO]: context.lossRate,
    [PROP_KEYS.CURRENCY]: currency.alphabeticCode,
  };
};

export default {
  extractIngredientData,
  extractCategoryData,
  extractContextData,
};
