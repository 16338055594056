import React from 'react';

import _ from 'lodash';
import i18next from 'i18next';

import { Text } from '../components/styledComponents';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_IMPORT_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import entityXlsConstants from '@commons/Excels/constants/entity.xlsx.constant';
import importTypeXlsConstants from '@commons/Excels/constants/importType.xslx.constant';
import supplierProductXlsConstants from '@commons/Excels/constants/supplierProduct.xlsx.constant';
import supplierXlsConstants from '@commons/Excels/constants/supplier.xlsx.constant';

import { normalizeJsonObject } from '@backoffice/utils';
import ImportExcelFileModal from '@backoffice/components/ImportExcelFileModal';

import { IMPORT_TYPES, IMPORT_MODAL_STEPS, IMPORT_FILE_SHEETS_CONSTANTS } from './constants';

const defaultModalConfig = {
  width: '542px',
  height: 'auto',
  component: ImportExcelFileModal,
};

const getSelectFileConfig = (modalConfigProps, importType) => {
  const { selectedFile, handleFileChange } = modalConfigProps;

  let title = i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_TITLE');
  let subTitle = i18next.t(
    'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_SUBTITLE',
  );

  if (importType === IMPORT_TYPES.SUPPLIER_PRODUCTS_IMPORT) {
    title = i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_BATCH_TITLE');
    subTitle = i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_BATCH_SUBTITLE');
  }

  return {
    ...defaultModalConfig,
    type: 'action',
    title,
    icon: '/images/inpulse/file-upload-black-small.svg',
    data: {
      subTitle,
      selectedFile,
      setSelectedFile: (file) => handleFileChange(file, importType),
    },
    actions: [
      {
        ...GENERIC_MODAL_CANCEL_BUTTON(),
        handleClick: () => handleFileChange(null, importType),
      },
      {
        ...GENERIC_MODAL_IMPORT_BUTTON(),
        isDisabled: true,
        handleClick: () => false,
      },
    ],
  };
};

const getValidateFileConfig = (modalConfigProps, importType) => {
  const { updatedFile, handleFileChange, handleFileValidation } = modalConfigProps;

  const title = i18next.t(
    'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_TITLE',
  );
  const subTitle = i18next.t(
    'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_SUBTITLE',
  );

  return {
    ...defaultModalConfig,
    type: 'action',
    title,
    icon: '/images/inpulse/file-upload-black-small.svg',
    data: {
      subTitle,
      selectedFile: updatedFile,
      setSelectedFile: (file) => handleFileChange(file, importType),
    },
    actions: [
      {
        ...GENERIC_MODAL_CANCEL_BUTTON(),
        handleClick: () => handleFileChange(null, importType),
      },
      {
        ...GENERIC_MODAL_IMPORT_BUTTON(),
        handleClick: () => handleFileValidation(updatedFile),
      },
    ],
  };
};

const getErrorFileConfig = (modalConfigProps, importType, props) => {
  const { updatedFile, handleFileChange, exportSupplierProductErrors, statusCode } =
    modalConfigProps;

  const subTitle = i18next.t(
    statusCode === 422
      ? 'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_SELECT_FILE_ERROR_SUBTITLE'
      : 'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_SELECT_FILE_ERROR_CONTENT',
  );

  const additionalText = i18next.t(
    statusCode === 422
      ? 'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_BATCH_SELECT_FILE_UNVALIDATED_LINES_ERROR_ADDITIONAL_TEXT'
      : 'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_BATCH_SELECT_FILE_ERROR_ADDITIONAL_TEXT',
  );

  const configData = {
    subTitle,
    additionalText,
    validatedFile: false,
    selectedFile: updatedFile,
  };

  if (importType === IMPORT_TYPES.SUPPLIER_PRODUCTS_IMPORT) {
    return {
      ...defaultModalConfig,
      type: 'error',
      title: i18next.t(
        'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_BATCH_SELECT_FILE_ERROR_TITLE',
      ),
      icon: '/images/inpulse/info-white-small.svg',
      data:
        statusCode === 422
          ? { ...configData, exportXLS: () => exportSupplierProductErrors() }
          : configData,
      actions: [
        {
          key: 0,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.CLOSE'),
          icon: '/images/inpulse/close-white-small.svg',
          handleClick: () => {
            handleFileChange(null, importType);
          },
        },
      ],
    };
  }

  return {
    ...defaultModalConfig,
    type: 'error',
    title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_TITLE_BIS'),
    icon: '/images/inpulse/info-white-small.svg',
    data: {
      subTitle: i18next.t(
        'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_SELECT_FILE_ERROR_CONTENT',
      ),
      additionalText: i18next.t(
        'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_SELECT_FILE_ERROR_ADDITIONAL_TEXT',
      ),
      validatedFile: false,
      selectedFile: updatedFile,
    },
    actions: [
      {
        key: 0,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.CLOSE'),
        icon: '/images/inpulse/close-white-small.svg',
        handleClick: () => {
          handleFileChange(null, importType);
          props.showErrorMessage(
            i18next.t(
              'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_VALIDATION_ERROR',
            ),
          );
        },
      },
    ],
  };
};

const getValidatedFileConfig = (modalConfigProps, importType, props) => {
  const {
    updatedFile = null,
    checkedSupplierProducts,
    handleFileChange,
    exportSupplierProductErrors,
    handleFileImport,
    batchSPdata,
  } = modalConfigProps;

  if (importType === IMPORT_TYPES.SUPPLIER_PRODUCTS_IMPORT) {
    return {
      ...defaultModalConfig,
      type: 'success',
      title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_BATCH_TITLE'),
      icon: '/images/inpulse/check-white-small.svg',
      data: {
        validatedFile: true,
        selectedFile: updatedFile,
      },
      actions: [
        {
          key: 0,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.IMPORT'),
          icon: '/images/inpulse/check-white-small.svg',
          handleClick: () => handleFileImport(batchSPdata),
        },
      ],
    };
  }

  if (_.isEmpty(_.get(checkedSupplierProducts, 'validated'))) {
    return {
      ...defaultModalConfig,
      type: 'warning',
      title: i18next.t(
        'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_TITLE_BIS',
      ),
      icon: '/images/inpulse/check-white-small.svg',
      data: {
        validatedFile: true,
        unValidatedLines: _.get(checkedSupplierProducts, 'unValidated'),
        subTitle: i18next.t(
          'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_NO_VALIDATED_LINES_CONTENT',
        ),
        selectedFile: updatedFile,
        exportXLS: () => exportSupplierProductErrors(checkedSupplierProducts),
      },
      actions: [
        {
          key: 0,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.CLOSE'),
          icon: '/images/inpulse/close-white-small.svg',
          handleClick: () => handleFileChange(null, importType),
        },
      ],
    };
  }

  if (!_.isEmpty(_.get(checkedSupplierProducts, 'unValidated'))) {
    return {
      ...defaultModalConfig,
      type: 'warning',
      title: i18next.t(
        'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_TITLE_BIS',
      ),
      icon: '/images/inpulse/warning-white-small.svg',
      data: {
        validatedFile: true,
        unValidatedLines: _.get(checkedSupplierProducts, 'unValidated'),
        subTitle: i18next.t(
          'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_PRICE_UNVALIDATED_LINES_CONTENT',
        ),
        selectedFile: updatedFile,
        exportXLS: () => exportSupplierProductErrors(checkedSupplierProducts),
      },
      actions: [
        {
          ...GENERIC_MODAL_CANCEL_BUTTON(),
          handleClick: () => handleFileChange(null, importType),
        },
        {
          key: 1,
          preventClosing: true,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.IMPORT'),
          icon: '/images/inpulse/check-white-small.svg',
          handleClick: () => handleFileImport(_.get(checkedSupplierProducts, 'validated')),
        },
      ],
    };
  }

  return {
    ...defaultModalConfig,
    type: 'success',
    title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_TITLE_BIS'),
    icon: '/images/inpulse/check-white-small.svg',
    data: {
      validatedFile: true,
      selectedFile: updatedFile,
    },
    actions: [
      {
        key: 0,
        preventClosing: true,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.IMPORT'),
        icon: '/images/inpulse/check-white-small.svg',
        handleClick: () => handleFileImport(_.get(checkedSupplierProducts, 'validated')),
      },
    ],
  };
};

const getWarningFileConfig = (modalConfigProps, importType, props) => {
  const {
    client: { storeName },
  } = props;
  const { checkedSupplierProducts, handleFileChange, handleFileImport } = modalConfigProps;

  if (importType === IMPORT_TYPES.SUPPLIER_PRODUCTS_IMPORT) {
    window.alert('TO BE IMPLEMENTED WARNING SP');
    return false;
  }

  return {
    ...defaultModalConfig,
    type: 'warning',
    title: i18next.t(
      'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_WARNING_TITLE',
    ),
    icon: '/images/inpulse/warning-white-small.svg',
    data: {
      customContent: (
        <Text grey height24>
          {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_PRICE_WARNING_CONTENT', {
            storeName: getClientStoreNameTranslation(storeName, true).toLowerCase(),
          })}
        </Text>
      ),
    },
    actions: [
      {
        ...GENERIC_MODAL_CANCEL_BUTTON(),
        handleClick: () => handleFileChange(null, importType),
      },
      {
        key: 1,
        preventClosing: true,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.SAVE'),
        icon: '/images/inpulse/save-white-small.svg',
        handleClick: () => handleFileImport(_.get(checkedSupplierProducts, 'validated')),
      },
    ],
  };
};

/**
 * Modal configuations for imports
 * @param {object} modalConfigProps Import modal configurations props
 * @param {string} importType Import type need to be one of IMPORT_TYPES
 * @param {object} props Import modal parent component props
 * @returns Import modal configurations
 */
export const getSupplierProductModalConfig = (modalConfigProps, importType, props) => {
  const { type } = modalConfigProps;

  if (type === IMPORT_MODAL_STEPS.SELECT_FILE) {
    return getSelectFileConfig(modalConfigProps, importType);
  }

  if (type === IMPORT_MODAL_STEPS.VALIDATE_FILE) {
    return getValidateFileConfig(modalConfigProps, importType);
  }

  if (type === IMPORT_MODAL_STEPS.ERROR_FILE) {
    return getErrorFileConfig(modalConfigProps, importType, props);
  }

  if (type === IMPORT_MODAL_STEPS.VALIDATED_FILE) {
    return getValidatedFileConfig(modalConfigProps, importType, props);
  }

  if (type === IMPORT_MODAL_STEPS.WARNING) {
    return getWarningFileConfig(modalConfigProps, importType, props);
  }

  return defaultModalConfig;
};

export const refreshImportModal = (modalConfigProps, importType, props) => {
  props.refreshGenericModal(getSupplierProductModalConfig(modalConfigProps, importType, props));
};

export const formatJson = (supplierProducts) => {
  const formattedSupplierProducts = normalizeJsonObject(supplierProducts);

  const formattedJson = Object.keys(formattedSupplierProducts).reduce((result, sheetName) => {
    if (!result[importTypeXlsConstants.KEY]) {
      result[importTypeXlsConstants.KEY] = _.get(
        formattedSupplierProducts,
        'ImportType[0].importtype',
        null,
      );
    }

    if (sheetName === supplierProductXlsConstants.SHEET.name) {
      result[supplierProductXlsConstants.SHEET.id] = formattedSupplierProducts[sheetName].map(
        (current) => ({
          [supplierProductXlsConstants.COLUMN_IDS.NAME]: current['nom'],
          [supplierProductXlsConstants.COLUMN_IDS.SKU]: current['code (sku)']
            ? current['code (sku)'].toString()
            : current['code (sku)'],
          [supplierProductXlsConstants.COLUMN_IDS.SUPPLIER]: current['fournisseur'],
          [supplierProductXlsConstants.COLUMN_IDS.ENTITY]: current['ingredient associe'],
          [supplierProductXlsConstants.COLUMN_IDS.CATEGORY]: current['categorie'],
          [supplierProductXlsConstants.COLUMN_IDS.SUBCATEGORY]: current['sous-categorie'],
          [supplierProductXlsConstants.COLUMN_IDS.PRICE]: current['prix ht'],
          [supplierProductXlsConstants.COLUMN_IDS.PRICE_EDITABLE_AT_RECEPTION]:
            current['prix modifiable a reception'] === 'OUI',
          [supplierProductXlsConstants.COLUMN_IDS.VAT_RATE]: current['tva (%)'],
          [supplierProductXlsConstants.COLUMN_IDS.LOSS]: current['pertes (%)'],
          [supplierProductXlsConstants.COLUMN_IDS.PACKAGE_WEIGHT]: current['poids du colis (kg)'],
          [supplierProductXlsConstants.COLUMN_IDS.PACKAGING_NAME_1]: current['conditionnement 1'],
          [supplierProductXlsConstants.COLUMN_IDS.PACKAGING_QUANTITY_1]:
            current['valeur du conditionnement 1'],
          [supplierProductXlsConstants.COLUMN_IDS.PACKAGING_UNIT_1]:
            current['unite du conditionnement 1'],
          [supplierProductXlsConstants.COLUMN_IDS.PACKAGING_NAME_2]: current['conditionnement 2'],
          [supplierProductXlsConstants.COLUMN_IDS.PACKAGING_QUANTITY_2]:
            current['valeur du conditionnement 2'],
          [supplierProductXlsConstants.COLUMN_IDS.PACKAGING_UNIT_2]:
            current['unite du conditionnement 2'],
          [supplierProductXlsConstants.COLUMN_IDS.PACKAGING_NAME_3]: current['conditionnement 3'],
          [supplierProductXlsConstants.COLUMN_IDS.PACKAGING_QUANTITY_3]:
            current['valeur du conditionnement 3'],
          [supplierProductXlsConstants.COLUMN_IDS.PACKAGING_UNIT_3]:
            current['unite du conditionnement 3'],
          [supplierProductXlsConstants.COLUMN_IDS.EAN]: current['code ean 13'] || null,
        }),
      );
    }
    if (sheetName === IMPORT_FILE_SHEETS_CONSTANTS.CATEGORIES.name) {
      result[IMPORT_FILE_SHEETS_CONSTANTS.CATEGORIES.id] = formattedSupplierProducts[sheetName].map(
        (current) => ({
          [IMPORT_FILE_SHEETS_CONSTANTS.CATEGORIES.columns.NAME]: current['categorie'],
        }),
      );
    }

    if (sheetName === IMPORT_FILE_SHEETS_CONSTANTS.SUB_CATEGORIES.name) {
      result[IMPORT_FILE_SHEETS_CONSTANTS.SUB_CATEGORIES.id] = formattedSupplierProducts[
        sheetName
      ].map((current) => ({
        [IMPORT_FILE_SHEETS_CONSTANTS.SUB_CATEGORIES.columns.NAME]: current['sous-categorie'],
      }));
    }

    if (sheetName === entityXlsConstants.SHEET.name) {
      result[entityXlsConstants.SHEET.id] = formattedSupplierProducts[sheetName].map((current) => ({
        [entityXlsConstants.COLUMN_IDS.ID]: current['id'],
        [entityXlsConstants.COLUMN_IDS.NAME]: current['nom'],
        [entityXlsConstants.COLUMN_IDS.UNIT]: current['unite'],
      }));
    }

    if (sheetName === supplierXlsConstants.SHEET.name) {
      result[supplierXlsConstants.SHEET.id] = formattedSupplierProducts[sheetName].map(
        (current) => ({
          [supplierProductXlsConstants.COLUMN_IDS.ID]: current['id'],
          [supplierProductXlsConstants.COLUMN_IDS.NAME]: current['nom'],
        }),
      );
    }

    return result;
  }, {});

  return formattedJson;
};
