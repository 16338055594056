import styled, { css } from 'styled-components';

export const DropdownContainer = styled.div`
  position: relative;
`;

export const DropdownList = styled.div`
  position: absolute;
  left: auto;
  right: 0;
  z-index: 10;
  max-height: 500px;

  min-width: 216px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 8px;

  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
  border-radius: ${(props) => props.theme.dropdown?.borderRadius};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
`;
export const ToolTipText = styled.span`
  position: absolute;
  width: 240px;

  opacity: 0;
  background-color: ${(props) => props.theme.dropdown.tooltip.backgroundColor};
  color: ${(props) => props.theme.dropdown.tooltip.color};
  font: ${(props) => props.theme.dropdown.tooltip.font};

  text-align: center;
  border-radius: 6px;

  z-index: 1;
  margin-top: 16px;
  padding: 8px;
  left: -60%;

  transition: opacity 0.2s;

  &::after {
    position: absolute;
  }
`;

export const DropdownItem = styled.div`
  background: ${(props) =>
    props.backgroundColor || props.theme.dropdown?.default?.backgroundColor};
  color: ${(props) => props.theme.dropdown?.default?.color};
  min-width: 240px;
  width: 100%;
  padding: 12px 8px;
  gap: 8px;

  display: flex;
  align-items: center;

  font: ${(props) => props.theme.dropdown?.font};

  &:nth-child(1) {
    border-top-left-radius: ${(props) => props.theme.dropdown?.borderRadius};
    border-top-right-radius: ${(props) => props.theme.dropdown?.borderRadius};
  }

  &:nth-last-child(1) {
    border-bottom-left-radius: ${(props) => props.theme.dropdown?.borderRadius};
    border-bottom-right-radius: ${(props) =>
      props.theme.dropdown?.borderRadius};
  }

  &:hover {
    background: ${(props) =>
      props.backgroundColorOnHover ||
      props.theme.dropdown?.hover?.backgroundColor};
    color: ${(props) => props.theme.dropdown?.hover?.color};
    cursor: pointer;
  }

  &:hover div:nth-child(1) div {
    filter: brightness(0) invert(1);
  }

  position: relative;
  &:hover ${ToolTipText} {
    opacity: 1;
  }

  ${(props) => {
    if (props.isDisabled) {
      return css`
        background: ${props.theme.dropdown?.disabled?.backgroundColor};
        color: ${props.theme.dropdown?.disabled?.color};

        &:hover {
          background: ${props.theme.dropdown?.disabled?.backgroundColor};
          color: ${props.theme.dropdown?.disabled?.color};
          cursor: not-allowed;
        }

        &:hover div:nth-child(1) div {
          filter: none;
        }
      `;
    }

    if (props.isHighlighted) {
      return css`
        background: ${props.theme.dropdown?.highlighted?.backgroundColor};
        color: ${props.theme.dropdown?.highlighted?.color};

        &:hover {
          background: ${props.theme.dropdown?.highlighted?.backgroundColor};
          color: ${props.theme.dropdown?.highlighted?.color};
        }

        div:nth-child(1) img {
          filter: brightness(0) invert(1);
        }

        &:hover div:nth-child(1) div {
          filter: none;
        }
      `;
    }

    return css``;
  }}
`;

export const DropdownItemText = styled.span``;

export const CustomButton = styled.div`
  cursor: pointer;
`;

export const Icon = styled.img``;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;

  &:hover ~ div {
    color: #ffffff;
  }
`;

export default {
  DropdownContainer,
  DropdownList,
  DropdownItem,
  DropdownItemText,
  CustomButton,
  Icon,
  IconContainer,
  ToolTipText,
};
