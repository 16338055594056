import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { ModalIconProps } from '../../interface';

import Tooltip from '../../../Tooltip';

import CustomModalContentDisplay from '../CustomModalContentDisplay/CustomModalContentDisplay';

import { IconContainer, Icon } from './styledComponents';

import IconStockDark from '../../../images/icon-stock-dark.svg';
import IconStockBlue from '../../../images/icon-stock-blue.svg';
import IconStockWhite from '../../../images/icon-stock-white.svg';

import { getTheme } from '../../../utils/theme';

const CustomModalButtonDisplay = (props: ModalIconProps): JSX.Element => {
  const {
    icon,
    theme,
    openModal,
    openedModal,
    modalContent,
    customModalTooltip,
    displayTooltip,
  } = props;

  const icons = {
    'icon-stock-closed-blue': IconStockBlue,
    'icon-stock-closed-dark': IconStockDark,
    'icon-stock-opened-blue': IconStockWhite,
    'icon-stock-opened-dark': IconStockWhite,
  };

  return (
    <ThemeProvider theme={getTheme(theme, 'expandableEmbeddedList')}>
      <IconContainer
        onClick={(e) => {
          e.stopPropagation();
          openModal();
        }}
      >
        {!!customModalTooltip && icon && (
          <div style={{ cursor: 'pointer' }}>
            {displayTooltip && (
              <Tooltip
                theme={_.set(
                  theme,
                  'tooltip.icon.size',
                  theme?.expandableEmbeddedList?.customModal?.tooltipSize
                )}
                icon={!openedModal ? `${icon}-closed` : `${icon}-opened`}
                text={customModalTooltip}
                customClassName={
                  !openedModal
                    ? 'custom-tooltip-modal'
                    : 'custom-tooltip-modal-open'
                }
                place="top"
              />
            )}
            {!displayTooltip && (
              <Icon
                src={
                  !openedModal
                    ? icons[
                        `${icon}-closed-${theme?.expandableEmbeddedList?.customModal?.icon?.color}`
                      ]
                    : icons[
                        `${icon}-opened-${theme?.expandableEmbeddedList?.customModal?.icon?.color}`
                      ]
                }
                selected={openedModal}
              />
            )}
          </div>
        )}
        {openedModal && (
          <CustomModalContentDisplay
            modalContent={modalContent}
            openModal={openModal}
          />
        )}
      </IconContainer>
    </ThemeProvider>
  );
};

CustomModalButtonDisplay.propTypes = {
  icon: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  modalContent: PropTypes.func.isRequired,
  openedModal: PropTypes.bool,
  customModalTooltip: PropTypes.string,
  displayTooltip: PropTypes.bool,
};

CustomModalButtonDisplay.defaultProps = {
  openedModal: false,
  customModalTooltip: null,
  displayTooltip: true,
};

export default CustomModalButtonDisplay;
