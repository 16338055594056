import { connect } from 'react-redux';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import { Dropdown } from '@commons/utils/styledLibraryComponents';

import { Input } from '@lib/inpulse/Input';

import clientService from '@services/client';

import { Container, DropdownContainer, DropdownIcon, DropdownItem, Text } from './styledComponents';

const DEFAULT_LANGUAGE_CODE = 'fr';

const UserInformations = (props) => {
  const {
    showErrorMessage,
    params: { firstName, lastName, languageId, handleSetUserInformations },
  } = props;

  const [languagesDropdownItems, setLanguagesDropdownItems] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({});

  useEffect(() => {
    if (!languageId) {
      return;
    }

    const defaultLanguage = languagesDropdownItems.find(({ id }) => id === languageId);
    setSelectedLanguage(defaultLanguage);
  }, [languageId, languagesDropdownItems]);

  useEffect(() => {
    fetchLanguages();
  }, []);

  const getLanguagesDropdownItems = () =>
    languagesDropdownItems.map((language) => ({
      ...language,
      renderValue: () => (
        <DropdownItem>
          <DropdownIcon src={language.iconSrc} />
          <Text>{language.name}</Text>
        </DropdownItem>
      ),
    }));

  const fetchLanguages = async () => {
    try {
      const languages = await clientService.getAllLanguages();

      const formattedLanguages = languages.map((language) => ({
        ...language,
        iconSrc: `/images/flags/icon-flag-${language.code}.svg`,
      }));

      const defaultLanguage = formattedLanguages.find(
        (language) => language.code === DEFAULT_LANGUAGE_CODE,
      );

      setLanguagesDropdownItems(formattedLanguages);
      setSelectedLanguage(defaultLanguage);
    } catch {
      showErrorMessage(i18next.t('USERS.DETAILS.FETCH_CLIENT_LANGUAGES_ERROR'));
    }
  };

  return (
    <>
      <Container>
        <DropdownContainer>
          <Dropdown
            iconSrc={selectedLanguage ? selectedLanguage.iconSrc : null}
            isLabelTextSmall={true}
            isRequired={true}
            items={getLanguagesDropdownItems()}
            label={`${i18next.t('USERS.DETAILS.PROFILE_LANGUAGE_INPUT_LABEL')} :`}
            selectedItem={selectedLanguage}
            onSelectionChange={(selectedItem) => {
              handleSetUserInformations('languageId', selectedItem.id);

              setSelectedLanguage(languagesDropdownItems.find(({ id }) => id === selectedItem.id));
              i18next.changeLanguage(selectedItem.code);
            }}
          />
        </DropdownContainer>
        <div>
          <Input
            label={`* ${i18next.t('GENERAL.FIRSTNAME')}`}
            type="text"
            value={firstName}
            width={240}
            autoFocus
            onChange={(event) => handleSetUserInformations('firstName', event.target.value)}
          />
        </div>
        <div>
          <Input
            label={`* ${i18next.t('GENERAL.NAME')}`}
            type="text"
            value={lastName}
            width={240}
            onChange={(event) => handleSetUserInformations('lastName', event.target.value)}
          />
        </div>
      </Container>
    </>
  );
};

UserInformations.propTypes = {
  params: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    languageId: PropTypes.string.isRequired,
    handleSetUserInformations: PropTypes.func.isRequired,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message, type) => {
    dispatch(showErrorMessage(message, type));
  },
});

export default connect(null, mapDispatchToProps)(UserInformations);
