import styled from 'styled-components';

export const RushSupplierContainer = styled.div`
  // This is used to cancel the margin added in the style props of Input.jsx
  margin-bottom: 8px;
`;

export const ActionsContainer = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  position: relative;
`;

export const ActionDot = styled.div`
  right: 15px;
  bottom: 15px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  background: ${(props) => props.theme.colors.brand.primary};
`;
