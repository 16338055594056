import theme from '../../../theme';

export const modalStyling = {
  success: {
    titleBackgroundColor: theme?.colors.brand.primaryDarker,
    titleColor: theme?.colors.greys.lightest,
    closeIcon: '/images/inpulse/close-white-small.svg',
    isFooterSeparator: false,
  },
  error: {
    titleBackgroundColor: theme?.colors.infoRed,
    titleColor: theme?.colors.greys.lightest,
    closeIcon: '/images/inpulse/close-white-small.svg',
    isFooterSeparator: false,
  },
  warning: {
    titleBackgroundColor: theme?.colors.infoOrange,
    titleColor: theme?.colors.greys.lightest,
    closeIcon: '/images/inpulse/close-white-small.svg',
    isFooterSeparator: false,
  },
  info: {
    titleBackgroundColor: theme?.colors.greys.lighter,
    titleColor: theme?.colors.greys.darkest,
    closeIcon: '/images/inpulse/close-black-small.svg',
    isFooterSeparator: false,
  },
  action: {
    titleBackgroundColor: theme?.colors.brand.primaryLight,
    titleColor: theme?.colors.greys.darkest,
    closeIcon: '/images/inpulse/close-black-small.svg',
    isFooterSeparator: true,
  },
  default: {
    titleBackgroundColor: theme?.colors.brand.primary,
    titleColor: theme?.colors.greys.lightest,
    closeIcon: '/images/inpulse/close-white-small.svg',
    isFooterSeparator: false,
  },
  actionWhite: {
    titleBackgroundColor: theme?.colors.greys.lightest,
    titleColor: theme?.colors.greys.darkest,
    closeIcon: '/images/inpulse/close-black-small.svg',
    backgroundColor: theme?.colors.greys.lighter,
    footerBackgroundColor: theme?.colors.greys.lightest,
    isFooterSeparator: true,
  },
};

export default modalStyling;
