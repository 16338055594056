import styled from 'styled-components';

export const ContainerInput = styled.div`
  white-space: nowrap;

  width: 100%;
  height: 40px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #ffffff;
  border: ${(props) =>
    props.focusedDateInput
      ? props.theme.inputs?.primary.focus.border
      : props.theme.inputs?.primary.default.border};
  border-radius: ${(props) => props.theme.inputs?.primary.default.borderRadius};
  box-shadow: none;
`;

export const Container = styled.div`
  width: 240px;
  height: 40px;

  margin-right: '10px';

  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors?.greys.light};

  cursor: default;

  border-radius: ${(props) => props.theme.inputs?.primary.default.borderRadius};

  box-shadow: none !important;
  -webkit-box-shadow: none !important;

  color: #6b768c;

  .stockform-date-input-icon {
    margin-left: 15px;
  }

  .block-stock-filter-locked {
    line-height: 34px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 15px;
    width: calc(100% - 55px);

    cursor: not-allowed;

    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  .orderform-date-input-icon,
  .multiple-dropdown-icon {
    margin-left: 15px;
  }

  .SingleDatePicker {
    width: 100%;
  }

  .SingleDatePicker_picker {
    margin-left: -31px;
    margin-top: 98px !important;

    top: inherit !important;
    left: inherit !important;
    position: fixed !important;

    background: transparent;
  }

  .DateInput {
    width: 100% !important;
  }

  .DateInput_fang {
    display: none !important;
  }

  .DateRangePickerInput__disabled {
    background-color: lightgrey !important;
  }

  .CalendarMonth_caption {
    color: ${(props) => props.theme.colors?.greys?.darkest} !important;
    font: ${(props) => props.theme.fonts?.h3} !important;
  }

  .DateInput_input {
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    text-align: left !important;
    cursor: pointer;
    border-bottom: none !important;
  }

  .DateInput_input__focused {
    border-bottom: none !important;
  }

  .DayPicker_weekHeader_li {
    font: ${(props) => props.theme.fonts?.textMicro} !important;
  }

  .CalendarDay {
    background: ${(props) => props.theme.colors?.greys.lightest} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  .CalendarDay__default:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  .CalendarDay__highlighted_calendar {
    background: #fff !important;
    border: 1px double #0a3eff !important;
  }

  .CalendarDay__highlighted_calendar:hover {
    background: #e4e7e7 !important;
  }

  .CalendarDay__selected_span {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    border: 1px double #e2e2e2 !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active .CalendarDay__selected:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    border: 1px double #e2e2e2 !important;
  }

  .CalendarDay__selected_start,
  .CalendarDay__selected_start:hover,
  .CalendarDay__selected_end,
  .CalendarDay__selected_end:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover {
    color: ${(props) => props.theme.colors?.greys.dark} !important;
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    cursor: not-allowed;
  }

  .DayPicker__withBorder {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  }

  .DayPicker__withBorder,
  .DayPicker_transitionContainer {
    border-radius: 8px;
  }
`;
