import { APIcaller } from './APIcaller';

export const getAppVersion = async () => {
  try {
    const { version } = await APIcaller.apiGet('/version/app');

    return version;
  } catch (err) {
    return null;
  }
};
