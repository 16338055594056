import styled from 'styled-components';

export const DateContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const CircleContainer = styled.div`
  width: 100%;
  height: 100%;
  display: ${(props) => (props.isToday ? 'flex' : 'none')};
`;

export const Circle = styled.div`
  margin: auto;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: ${(props) => props.theme.colors?.brand.primaryLight} !important;
  display: ${(props) => (props.isToday ? 'flex' : 'none')};
`;

export const Date = styled.div`
  margin: auto;
  display: ${(props) => (props.isToday ? 'flex' : 'none')};
`;

export default {
  DateContainer,
  CircleContainer,
  Circle,
  Date,
};
