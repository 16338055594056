import _ from 'lodash';
import i18next from 'i18next';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import utilsXLS from '@commons/utils/makeXLS';

import { convertSPPQuantityInMasterUnit } from '@orders/utils/computePackagingToSupplierProduct';

const MAIN_SHEET_NAME_INVENTORIES = i18next.t('GENERAL.INGREDIENT_PLURAL');
const CONTEXT_SHEET_NAME_SUPPLIER_PRODUCTS = i18next.t(
  'STOCKS.STOCKS.FORM_EXPORT_XLS_SECOND_SHEET_NAME',
);
const CONTEXT_SHEET_NAME_RECIPES = i18next.t('GENERAL.RECIPE_PLURAL');

/**
 * Generate columns settings to display in the main sheet of the xls file
 * matching some rules to apply on value formatting and associated key name property
 *
 * @return {Array} the list of columns settings that should appear on export file
 */
const getMainSheetColumnsSettings = (
  storeName,
  isDetailInventoryExport,
  currency,
  isCentralKitchenView,
) => [
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_REFERENCE'),
    keyName: 'reference',
  },
  {
    displayName: isCentralKitchenView
      ? i18next.t('GENERAL.KITCHEN')
      : getClientStoreNameTranslation(storeName),
    keyName: 'storeName',
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_DATE'),
    keyName: 'stockDate',
  },
  {
    displayName: i18next.t('GENERAL.STORAGE_AREA_SINGLE'),
    keyName: 'storageAreaName',
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.LIST_LABEL_SUPPLIER'),
    keyName: isDetailInventoryExport ? 'supplier' : 'supplierName',
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_CATEGORY'),
    keyName: 'category',
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_SUBCATEGORY'),
    keyName: 'subCategory',
  },
  {
    displayName: i18next.t('GENERAL.SKU'),
    keyName: isDetailInventoryExport ? 'sku' : 'lnkSupplierproductInventoryrel.sku',
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_DESIGNATION'),
    keyName: isDetailInventoryExport ? 'name' : 'productName',
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_TOTAL_IN_UNIT'),
    keyName: 'totalUnitQuantity',
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_UNIT'),
    keyName: 'masterPackagingUnit',
    transform: (value) =>
      value === 'unit'
        ? i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_UNIT').toLowerCase()
        : value,
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_FILTERS_TOTAL_BOX_TITLE'),
    keyName: 'totalPrice',
    transform: (value) =>
      utilsXLS.formatRow(
        { totalPrice: value },
        { type: 'currency', propertyKey: 'totalPrice' },
        currency.numberDecimals,
      ),
  },
  {
    displayName: i18next.t('GENERAL.CURRENCY'),
    keyName: 'currencyCode',
    transform: () => currency.name,
  },
];

/**
 * Generate columns settings to display in the contect sheet of the xls file
 * matching some rules to apply on value formatting and associated key name property
 *
 * @return {Array} the list of columns settings that should appear on export file
 */
const getContextSheetColumnsSettings = (
  storeName,
  isDetailInventoryExport,
  currency,
  isCentralKitchenView,
) => [
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_REFERENCE'),
    keyName: 'reference',
  },
  {
    displayName: isCentralKitchenView
      ? i18next.t('GENERAL.KITCHEN')
      : getClientStoreNameTranslation(storeName),
    keyName: 'storeName',
  },
  {
    displayName: i18next.t('GENERAL.STORAGE_AREA_SINGLE'),
    keyName: 'storageAreaName',
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.LIST_LABEL_SUPPLIER'),
    keyName: isDetailInventoryExport ? 'supplier' : 'supplierName',
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_CATEGORY'),
    keyName: 'category',
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_SUBCATEGORY'),
    keyName: 'subCategory',
  },
  {
    displayName: i18next.t('GENERAL.SKU'),
    keyName: isDetailInventoryExport ? 'sku' : 'lnkSupplierproductInventoryrel.sku',
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_DESIGNATION'),
    keyName: isDetailInventoryExport ? 'name' : 'productName',
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_QUANTITY'),
    keyName: 'tot',
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_INVENTORY_UNIT'),
    keyName: isDetailInventoryExport ? 'packagings' : 'supplierProduct.packagings',
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_UNIT_COST'),
    keyName: isDetailInventoryExport ? 'price' : 'productPrice',
    transform: (value) =>
      utilsXLS.formatRow(
        { [isDetailInventoryExport ? 'price' : 'productPrice']: value },
        { type: 'currency', propertyKey: [isDetailInventoryExport ? 'price' : 'productPrice'] },
        currency.numberDecimals,
      ),
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_TOTAL_IN_UNIT'),
    keyName: 'totalUnit',
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_UNIT'),
    keyName: 'masterPackagingUnit',
    transform: (value) =>
      value === 'unit'
        ? i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_UNIT').toLowerCase()
        : value,
  },
  {
    displayName: i18next.t('STOCKS.STOCKS.FORM_FILTERS_TOTAL_BOX_TITLE'),
    keyName: isDetailInventoryExport ? 'totalPrice' : 'productTotalCost',
    transform: (value) =>
      utilsXLS.formatRow(
        { totalPrice: value },
        { type: 'currency', propertyKey: 'totalPrice' },
        currency.numberDecimals,
      ),
  },
  {
    displayName: i18next.t('GENERAL.CURRENCY'),
    keyName: 'currencyCode',
    transform: () => currency.name,
  },
];

const getRecipeSheetColumnsSettings = (storeName, isCentralKitchenView) => [
  {
    name: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_REFERENCE'),
    propertyKey: 'reference',
  },
  {
    name: isCentralKitchenView
      ? i18next.t('GENERAL.KITCHEN')
      : getClientStoreNameTranslation(storeName),
    propertyKey: 'storeName',
  },
  {
    name: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_DATE'),
    propertyKey: 'stockDate',
  },
  {
    name: i18next.t('GENERAL.STORAGE_AREA_SINGLE'),
    propertyKey: 'storageAreaName',
  },
  {
    name: i18next.t('GENERAL.BRAND_PLURAL'),
    propertyKey: 'brands',
  },
  {
    name: i18next.t('GENERAL.CATEGORY'),
    propertyKey: 'category',
  },
  {
    name: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
  },
  {
    name: i18next.t('STOCKS.STOCKS.FORM_EXPORT_XLS_LABEL_TOTAL_IN_UNIT'),
    propertyKey: 'quantity',
  },
  {
    name: i18next.t('GENERAL.UNIT'),
    propertyKey: 'unit',
  },
  {
    name: i18next.t('GENERAL.TOTAL_EX_TAX'),
    propertyKey: 'totalCost',
    type: 'currency',
  },
];

const sortInventoriesByPackaging = (inventories, isDetailInventoryExport) =>
  _.sortBy(inventories, (inventory) =>
    _.findIndex(
      isDetailInventoryExport
        ? inventory.packagings
        : _.get(inventory, 'supplierProduct.packagings'),
      (packaging) => packaging.id === inventory.supplierProductPackagingId,
    ),
  );

const computeInventoryQuantityInUnit = (inventory) => {
  const inventoryPackagingInUnit = convertSPPQuantityInMasterUnit(
    inventory.supplierProductPackagingId,
    _.get(inventory, 'supplierProduct.packagings'),
  );

  return inventory.tot * inventoryPackagingInUnit;
};

const formatInventories = (inventories, isDetailInventoryExport) => {
  const formattedInventories = inventories.reduce((result, inventory) => {
    const { reference, supplierProductId, id } = inventory;

    const key = `${reference}-${isDetailInventoryExport ? id : supplierProductId}-${
      inventory.storageAreaId || 'none'
    }`;

    if (!result[key]) {
      result[key] = {
        totalUnitQuantity: 0,
        totalPrice: 0,
        packagings: [],
      };
    }

    if (!isDetailInventoryExport) {
      inventory.totalUnit = computeInventoryQuantityInUnit(inventory);
    }

    result[key]['totalUnitQuantity'] += +inventory.totalUnit;

    result[key]['packagings'].push(inventory);

    if (inventory.totalPrice != null || inventory.productTotalCost != null) {
      result[key]['totalPrice'] += isDetailInventoryExport
        ? +(inventory.totalPrice || 0)
        : inventory.productTotalCost || 0;
      return result;
    }

    result[key]['totalPrice'] = null;
    return result;
  }, {});

  _.keys(formattedInventories).forEach(
    (key) =>
      (formattedInventories[key]['packagings'] = sortInventoriesByPackaging(
        _.get(formattedInventories[key], 'packagings'),
        isDetailInventoryExport,
      )),
  );

  return formattedInventories;
};

const formatMainSheetLine = (formattedInventory, headersSettings) => {
  const line = headersSettings.map(({ keyName, transform }) => {
    if (keyName === 'totalUnitQuantity' || keyName === 'totalPrice') {
      if (!transform) {
        return formattedInventory[keyName];
      }
      return transform(formattedInventory[keyName]);
    }
    if (!transform) {
      return _.get(formattedInventory, `packagings[0].${keyName}`);
    }
    return transform(_.get(formattedInventory, `packagings[0].${keyName}`));
  });

  return line;
};

const formatContextSheetLine = (formattedInventory, headersSettings) => {
  const line = headersSettings.map(({ keyName, transform }) => {
    if (keyName === 'packagings' || keyName === 'supplierProduct.packagings') {
      const packagings = _.get(formattedInventory, keyName);
      const matchingSPP = _.find(
        packagings,
        ({ id }) => id === formattedInventory.supplierProductPackagingId,
      );
      return matchingSPP.name;
    }
    if (!transform) {
      return _.get(formattedInventory, keyName);
    }
    return transform(_.get(formattedInventory, keyName));
  });

  return line;
};

/**
 *
 * @param {Array<Inventory>} inventories - an appropriately formatted array of inventories
 * @param {string} storeName -  the name to display in excel ('Établissement', 'Corner'...)
 * @param {boolean} isDetailInventoryExport - a boolean which inform if the function is call on inventory detailed page
 *
 * @returns {Array<Object>}
 */
const createSheets = (
  inventories,
  storeName,
  isDetailInventoryExport,
  currency,
  isCentralKitchenView,
) => {
  const mainHeadersSettings = getMainSheetColumnsSettings(
    storeName,
    isDetailInventoryExport,
    currency,
    isCentralKitchenView,
  );
  const contextHeadersSettings = getContextSheetColumnsSettings(
    storeName,
    isDetailInventoryExport,
    currency,
    isCentralKitchenView,
  );

  const mainHeaders = mainHeadersSettings.map(({ displayName }) => displayName);
  const contextHeaders = contextHeadersSettings.map(({ displayName }) => displayName);

  const formattedInventories = formatInventories(inventories, isDetailInventoryExport);

  const mainMappedData = [];
  const contextMappedData = [];

  _.keys(formattedInventories).forEach((key) => {
    mainMappedData.push(formatMainSheetLine(formattedInventories[key], mainHeadersSettings));
    const packagings = _.get(formattedInventories[key], 'packagings');
    packagings.forEach((packaging) => {
      contextMappedData.push(formatContextSheetLine(packaging, contextHeadersSettings));
    });
  });

  return [
    {
      title: MAIN_SHEET_NAME_INVENTORIES,
      headers: mainHeaders,
      data: mainMappedData,
    },
    {
      title: CONTEXT_SHEET_NAME_SUPPLIER_PRODUCTS,
      headers: contextHeaders,
      data: contextMappedData,
    },
  ];
};

/**
 * Handle the export of the analytics reference data into a XLS file
 *
 * @returns {void}
 */
export function exportInventories(
  inventories,
  recipeInventories,
  filename,
  storeName,
  isDetailInventoryExport = false,
  currency,
  isCentralKitchenView = false,
) {
  if (!inventories) {
    return;
  }

  const sheets = createSheets(
    inventories,
    storeName,
    isDetailInventoryExport,
    currency,
    isCentralKitchenView,
  );

  const recipeSheetColumns = getRecipeSheetColumnsSettings(storeName, isCentralKitchenView);

  const recipesSheet = utilsXLS.generateDefaultSheet(
    CONTEXT_SHEET_NAME_RECIPES,
    recipeSheetColumns,
    recipeInventories,
    currency,
  );

  utilsXLS.makeXLS(filename, [sheets[0], sheets[1], recipesSheet]);
}
