import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
`;

export const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  position: absolute;
  top: 0px;

  background-color: ${(props) => props.theme?.colors.greys.darkest};
  opacity: 64%;
  overflow: hidden;
  z-index: 10;
`;

export const ModalContainer = styled.div`
  ${(props) => {
    if (props.isFullscreen) {
      return css`
        width: calc(100vw - 96px);
        height: calc(100vh - 96px);
        margin: 48px;
      `;
    }

    return css`
      width: ${props.width || '560px'};
      height: ${props.height || '100%'};
      min-height: ${props.minHeight || '144px'};
      max-height: ${props.maxHeight};
    `;
  }}

  background: ${(props) => props.styling?.backgroundColor || props.theme?.colors.greys.lightest};
  border-radius: 8px;

  z-index: 12;
  position: absolute;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;

  padding: 24px;
  border-radius: 8px 8px 0px 0px;

  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  font: ${(props) => props.theme?.fonts.h1InterNonBold};

  background-color: ${(props) => props.styling?.titleBackgroundColor};
  color: ${(props) => props.styling?.titleColor};
`;

export const TitleTextAndIconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const ItemCountContainer = styled.div`
  height: 24px;
  min-width: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background: ${(props) => props.theme.colors?.greys?.darkest};
  border-radius: 4px;
  font: ${(props) => props.theme?.fonts.textSmallWeight600};
  color: ${(props) => props.theme.colors?.greys?.lightest};
`;

export const CloseModalIcon = styled.img`
  height: 16px;
  width: 16px;

  cursor: pointer;
`;

export const ComponentContainer = styled.div`
  width: 100%;
  height: calc(100% - 160px);
  min-height: ${(props) => props.minHeight && `calc(${props.minHeight} - (72px + 88px))`};

  overflow: ${(props) => props.applyOverflow && 'auto'};

  display: flex;
  justify-content: center;
  align-items: flex-start;

  padding-bottom: ${(props) => props.isLoading && '0px'};
`;

export const Footer = styled.div`
  width: 100%;
  padding: ${(props) => (props.footerSeparator ? '24px' : '0px 24px 24px 24px')};

  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 24px;

  ${(props) =>
    props.background &&
    css`
      background: ${(props) => props.background};
    `}

  border-radius: 0px 0px 8px 8px;
  border-top: ${(props) =>
    props.footerSeparator && `1px solid ${props.theme.colors?.greys?.lighter}`};
`;

export const TitleRightPartContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  align-items: center;

  font: ${(props) => props.theme.fonts?.h2};
`;

export const GenericModalContainer = styled.div`
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0px;

  z-index: 20;
`;
