import { CustomArrowContainer } from './styledComponents';
import React from 'react';

export const renderCustomNavIconLeft = () => (
  <div
    className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5"
    role="button"
    tabIndex="0"
  >
    <img
      src="/images/inpulse/chevron-left-black-small.svg"
      onMouseOut={(e) => (e.currentTarget.src = '/images/inpulse/chevron-left-black-small.svg')}
      onMouseOver={(e) => (e.currentTarget.src = '/images/inpulse/chevron-left-dmgrey-small.svg')}
    />
  </div>
);

export const renderCustomNavIconRight = () => (
  <div
    className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5"
    role="button"
    tabIndex="0"
  >
    <img
      src="/images/inpulse/chevron-right-black-small.svg"
      onMouseOut={(e) => (e.currentTarget.src = '/images/inpulse/chevron-right-black-small.svg')}
      onMouseOver={(e) => (e.currentTarget.src = '/images/inpulse/chevron-right-dmgrey-small.svg')}
    />
  </div>
);

export const renderCustomArrowIcon = () => (
  <CustomArrowContainer>
    <img alt="arrow" src={'/images/icon-arrow-date-picker-inpulse.svg'} />
  </CustomArrowContainer>
);
