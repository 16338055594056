import styled, { css } from 'styled-components';

export const PageContentContainer = styled.div`
  width: 100%;
  height: calc(100vh - 40px);
  overflow-y: auto;

  padding: 0 24px;
`;

export const ProductionFormContainer = styled.div`
  width: 100%;

  ${(props) =>
    props.displayBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.expandableEmbeddedList.childRow.separator.color};
    `}

  margin-bottom: 24px;

  cursor: pointer;
`;

// CATEGORY SUMUP
export const CategorySumupContainer = styled.div`
  display: flex;
  column-gap: 16px;
  padding: ${(props) => (props.isCategoryOpen ? '0px' : '0 0 16px 0')};
  border-bottom: ${(props) => (props.isCategoryOpen ? '0px' : '1px solid #e6e6e6')};
  cursor: pointer;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 3;
`;

export const Text = styled.div`
  text-transform: capitalize;
  font: ${(props) => props.theme.fonts.h2Inter} !important;
`;

// CATEGORY CONTENT
export const CategoryContentContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 0;
  margin-bottom: 24px;
`;

export const ColumnsContainer = styled.div`
  display: flex;
  padding: 0 16px;
  margin: 24px 0 16px 0;
`;

export const Column = styled.div`
  display: flex;
  font: ${(props) => props.theme.fonts.textBig} !important;
  color: ${(props) => props.theme.listView.header.default.color} !important;
  flex: 1;
  flex-basis: ${(props) => `calc(${(100 / props.nbColumns) * props.flex}%)`};
  padding-right: 16px;
  min-width: 240px;
`;

export const RowContainer = styled.div`
  border-radius: 8px;
  margin-bottom: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
  cursor: auto;
`;

// ROW
export const RowContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 0 16px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  background-color: ${(props) => props.theme.colors.greys.lightest};
`;

export const Item = styled.div`
  flex-direction: column;
  flex-basis: ${(props) => `calc(${(100 / props.nbColumns) * props.flex}%)`};
  padding-right: 16px;
  font: ${(props) => props.theme.fonts.textBigHeight16};
  min-width: 240px;
`;

export const NameContent = styled.div`
  margin-left: 8px;
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const BoxImg = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: url(${(props) => props.img}) no-repeat center;
  background-size: contain;
  cursor: ${(props) => (props.pointerCursor ? 'pointer' : 'default')};
`;

export const ProductionInteractionContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

// Drawer
export const Divider = styled.div`
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.greys.light};
`;

export const DrawerContainer = styled.div`
  padding: 0 24px;
  height: 100%;
`;

export const DrawerContentContainer = styled.div`
  overflow-y: scroll;
  // 64px size of the sidePanel footer + 2px for Divider  // 24px margin size design wanted between divider and footer
  height: calc(100% - (64px + 2px + 24px));
  padding: 24px 0px;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 16px;
`;

export const TitleContainer = styled.div`
  font: ${(props) => props.theme.fonts.h1InterNonBold};
  color: ${(props) => props.theme.colors.greys.darkest};
`;

export const ProductionQuantities = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 24px;
`;

export const List = styled.div`
  height: ${({ height }) => height};
  padding: 24px 0px;
`;

export const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 0px;

  ${({ gap }) =>
    gap &&
    css`
      column-gap: 2px;
    `};
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  margin-right: 8px;
`;

export const Thumbnail = styled.img`
  width: 40px;
  height: 40px;
`;

export const NameContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Name = styled.div`
  font: ${({ theme }) => theme.fonts.textMiddleNormal};
  color: ${({ theme }) => theme.colors.greys.darkest};
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DisplayNameHovered = styled.div`
  position: absolute;
  top: 72px;
  left: 72px;
  right: 32px;
  height: 40px;
  background: ${({ theme }) => theme.colors.greys.lighter};
  padding: 0 16px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font: ${({ theme }) => theme.fonts.textMiddleNorma};
`;

export const DrawerText = styled.div`
  font: ${({ theme, isBold }) =>
    isBold ? theme.fonts.textMiddleBold : theme.fonts.textMiddleNormal};
  color: ${({ theme, isLight }) =>
    isLight ? theme.colors.blacks.ipBlack5 : theme.colors.blacks.ipBlack1};

  ${({ theme, isDisabled }) =>
    isDisabled &&
    css`
      color: ${theme.colors.greys.dark};
    `};

  ${({ withMarginBottom }) =>
    withMarginBottom &&
    css`
      margin-bottom: 8px;
    `};
`;
