import moment from 'moment';

import { storeSupplierProductMapping } from '../services/storeSupplierProductMapping';

import { DATE_DISPLAY_FORMATS } from '../commons/DatePickers/constants';

export const receiveStoreSupplierProductMappings = (storeSupplierProductMappings) => ({
  type: 'STORE_SUPPLIER_PRODUCT_MAPPING_SUCCESS',
  storeSupplierProductMappings,
});

export const requestStoreSupplierProductMappingsError = (error) => ({
  type: 'STORE_SUPPLIER_PRODUCT_MAPPING_FAILURE',
  error,
});

export const getStoreSupplierProductMappingsOfStore = (
  storeId,
  startDate = moment().startOf('day').format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  filterByUserCatalog = false,
) =>
  function (dispatch) {
    dispatch({
      type: 'STORE_SUPPLIER_PRODUCT_MAPPING_REQUEST',
    });
    return storeSupplierProductMapping.getStoreSupplierProductMappingsOfStore(
      storeId,
      startDate,
      filterByUserCatalog,
    );
  };

/**********\   GET SSPM Of Account Stores  /***********/

export const receiveStoreSupplierProductMappingsOfAccountStores = (
  storeSupplierProductMappings,
) => ({
  type: 'STORES_SUPPLIER_PRODUCT_MAPPING_OF_ACCOUNT_STORES_SUCCESS',
  storeSupplierProductMappings,
});

export const requestStoreSupplierProductMappingsOfAccountStoresError = (error) => ({
  type: 'STORES_SUPPLIER_PRODUCT_MAPPING_OF_ACCOUNT_STORES_FAILURE',
  error,
});

export const getStoreSupplierProductMappingsOfAccountStores = (accountId, supplierProductId) =>
  function (dispatch) {
    dispatch({
      type: 'STORES_SUPPLIER_PRODUCT_MAPPING_OF_ACCOUNT_STORES_REQUEST',
    });
    return storeSupplierProductMapping.getStoreSupplierProductMappingsOfAccountStores(
      accountId,
      supplierProductId,
    );
  };

/**********\   GET Mappings SSPM Of Supplier Products  /***********/

export const receiveStoreSupplierProductMappingsOfSupplierProducts = (
  storeSupplierProductMappings,
) => ({
  type: 'STORES_SUPPLIER_PRODUCT_MAPPING_BY_SUPPLIER_PRODUCTS_SUCCESS',
  storeSupplierProductMappings,
});

export const requestStoreSupplierProductMappingsOfSupplierProductsError = (error) => ({
  type: 'STORES_SUPPLIER_PRODUCT_MAPPING_BY_SUPPLIER_PRODUCTS_FAILURE',
  error,
});

export const getStoreSupplierProductMappingsOfSupplierProducts = (supplierProductIds) =>
  function (dispatch) {
    dispatch({
      type: 'STORES_SUPPLIER_PRODUCT_MAPPING_BY_SUPPLIER_PRODUCTS_REQUEST',
    });
    return storeSupplierProductMapping.getStoreSupplierProductMappingsOfSupplierProducts(
      supplierProductIds,
    );
  };

// PATCH a supplierProduct from a store
export const receivePatchStoreSupplierProductMapping = (mapping) => ({
  type: 'PATCH_STORE_SUPPLIER_PRODUCT_MAPPING_SUCCESS',
  mapping,
});

export const requestPatchStoreSupplierProductMappingError = (error) => ({
  type: 'PATCH_STORE_SUPPLIER_PRODUCT_MAPPING_FAILURE',
  error,
});

export const patchStoreSupplierProductMapping = (mapping) =>
  function (dispatch) {
    dispatch({
      type: 'PATCH_STORE_SUPPLIER_PRODUCT_MAPPING_REQUEST',
    });
    return storeSupplierProductMapping.patchStoreSupplierProductMapping(mapping);
  };

export const receivePatchMultipleStoreSupplierProductMapping = (mapping) => ({
  type: 'PATCH_MULTIPLE_STORE_SUPPLIER_PRODUCT_MAPPING_SUCCESS',
  mapping,
});

export const requestPatchMultipleStoreSupplierProductMappingError = (error) => ({
  type: 'PATCH_MULTIPLE_STORE_SUPPLIER_PRODUCT_MAPPING_FAILURE',
  error,
});

export const receiveDeleteStoreSupplierProductMapping = (res) => ({
  type: 'DELETE_STORE_SUPPLIER_PRODUCT_MAPPING_SUCCESS',
  res,
});

export const requestDeleteStoreSupplierProductMappingError = (error) => ({
  type: 'DELETE_STORE_SUPPLIER_PRODUCT_MAPPING_FAILURE',
  error,
});

export const deleteStoreSupplierProductMapping = (mappingId) =>
  function (dispatch) {
    dispatch({
      type: 'DELETE_STORE_SUPPLIER_PRODUCT_MAPPING_REQUEST',
    });
    return storeSupplierProductMapping.deleteStoreSupplierProductMapping(mappingId);
  };

export const receiveDeleteMultipleStoreSupplierProductMapping = (res) => ({
  type: 'DELETE_MULTIPLE_STORE_SUPPLIER_PRODUCT_MAPPING_SUCCESS',
  res,
});

export const requestDeleteMultipleStoreSupplierProductMappingError = (error) => ({
  type: 'DELETE_MULTIPLE_STORE_SUPPLIER_PRODUCT_MAPPING_FAILURE',
  error,
});

export const deleteMultipleStoreSupplierProductMapping = (mappingIds) =>
  function (dispatch) {
    dispatch({
      type: 'DELETE_MULTIPLE_STORE_SUPPLIER_PRODUCT_MAPPING_REQUEST',
    });
    return storeSupplierProductMapping.deleteMultipleStoreSupplierProductMapping(mappingIds);
  };

export const receiveStoreSupplierProductMapping = (storeSupplierProductMapping) => ({
  type: 'SINGLE_STORE_SUPPLIER_PRODUCT_MAPPING_SUCCESS',
  storeSupplierProductMapping,
});

export const requestStoreSupplierProductMappingError = (error) => ({
  type: 'SINGLE_STORE_SUPPLIER_PRODUCT_MAPPING_FAILURE',
  error,
});

export const getStoreSupplierProductMapping = (id) =>
  function (dispatch) {
    dispatch({
      type: 'SINGLE_STORE_SUPPLIER_PRODUCT_MAPPING_REQUEST',
    });
    return storeSupplierProductMapping.getStoreSupplierProductMapping(id);
  };
