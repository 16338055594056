import React from 'react';

import {
  canEditProductionOperationsToProduce,
  canEditProductionOperationsProduced,
  canEditProductionLosses,
  canEditProductionStocks,
} from '@selectors/actions/productionActions';

import { period } from '@src/routes/prod/constants/period';

import { Section } from '../styledComponents';
import InputIncrementSection from '../components/InputIncrementSection';
import LeftToProduceSection from '../components/LeftToProduceSection';
import PriceSection from '../components/PriceSection';
import ProductInfoSection from '../components/ProductInfoSection';
import ToProduceSection from '../components/ToProduceSection';

import { ENUM_ACTIVE_PRODUCT_CASE } from './enums';

const EMPTY_ACTIVE_PRODUCT = {
  id: '',
  case: '',
  value: '',
};

export const INPUT_REFERENCE = {
  class: 'reference',
  name: 'PRODUCTION.PRODUCTION.HEADER_REFERENCE',
  render: ({ product }) => (
    <Section className={'product-name'}>
      <ProductInfoSection code={product.code} name={product.name} />
    </Section>
  ),
};

export const INPUT_TO_PRODUCE = {
  class: ENUM_ACTIVE_PRODUCT_CASE.TO_PRODUCE,
  name: 'PRODUCTION.PRODUCTION.HEADER_TO_PRODUCE',
  render: ({
    product,
    useCase,
    toProduce,
    isUserOffline,
    toProduceRecoDeepsight,
    handleToProduceSave,
    isProductActive,
    activeProduct,
    setActiveProduct,
    user,
    authorizedActions,
  }) => (
    <ToProduceSection
      handleSave={(value) => handleToProduceSave(value, product.productId)}
      isEditable={
        !isUserOffline &&
        (useCase === period.CURRENT || useCase === period.AFTER) &&
        canEditProductionOperationsToProduce(authorizedActions)
      }
      isProductActive={isProductActive && activeProduct.case === ENUM_ACTIVE_PRODUCT_CASE.PRODUCED}
      product={product}
      setActiveProduct={() =>
        setActiveProduct({
          id: product.productId,
          case: ENUM_ACTIVE_PRODUCT_CASE.TO_PRODUCE,
        })
      }
      toProduce={toProduce}
      toProduceRecoDeepsight={toProduceRecoDeepsight}
      unsetActiveProduct={() => setActiveProduct(EMPTY_ACTIVE_PRODUCT)}
    />
  ),
};

export const INPUT_PRODUCED = {
  class: ENUM_ACTIVE_PRODUCT_CASE.PRODUCED,
  name: 'PRODUCTION.PRODUCTION.HEADER_PRODUCED',
  render: ({
    dataByRange,
    product,
    production,
    isProductActive,
    useCase,
    handleProducedSave,
    activeProduct,
    setActiveProduct,
    isUserOffline,
    offlineLimitReached,
    authorizedActions,
  }) => {
    const dataProduct = dataByRange[product.productId];

    return (
      <InputIncrementSection
        classNameAddButton={'planning-product-row-prod-add'}
        handleSave={(value) => handleProducedSave(product, value)}
        isEditable={
          useCase === period.CURRENT &&
          (!isUserOffline || !offlineLimitReached) &&
          canEditProductionOperationsProduced(authorizedActions)
        }
        isProductActive={
          isProductActive && activeProduct.case === ENUM_ACTIVE_PRODUCT_CASE.PRODUCED
        }
        minValue={dataProduct['production'] * -1}
        production={production}
        setActiveProduct={() =>
          setActiveProduct({
            id: product.productId,
            case: ENUM_ACTIVE_PRODUCT_CASE.PRODUCED,
          })
        }
        unsetActiveProduct={() => setActiveProduct(EMPTY_ACTIVE_PRODUCT)}
      />
    );
  },
};

export const INPUT_LEFT = {
  class: 'left',
  name: 'PRODUCTION.PRODUCTION.HEADER_LEFT_TO_PRODUCE',
  render: ({
    isUserOffline,
    leftToProduce,
    remainingFromPreviousRanges,
    useCase,
    reportToProduceFromPreviousRanges,
    isProductActive,
  }) => (
    <Section>
      <LeftToProduceSection
        disabled={isUserOffline}
        isDisplayed={!isProductActive}
        remainingFromPreviousRanges={remainingFromPreviousRanges}
        reportToProduceFromPreviousRanges={reportToProduceFromPreviousRanges}
        useCase={useCase}
        value={leftToProduce}
      />
    </Section>
  ),
};

export const INPUT_PRICE_TTC = {
  class: 'price',
  name: 'PRODUCTION.LOSSES.HEADER_PRICE',
  render: ({ product, isProductActive }) => (
    <Section>
      <PriceSection isProductActive={isProductActive} value={product.price} />
    </Section>
  ),
};

export const INPUT_LOSS = {
  class: 'loss',
  name: 'PRODUCTION.LOSSES.HEADER_LOSS',
  render: ({
    product,
    lossByProduct,
    handleLossSave,
    isProductActive,
    setActiveProduct,
    isUserOffline,
    offlineLimitReached,
    authorizedActions,
  }) => (
    <Section>
      <InputIncrementSection
        classNameAddButton={'planning-product-row-loss-add'}
        handleSave={(value) => handleLossSave(product, value)}
        isEditable={
          (!isUserOffline || !offlineLimitReached) && canEditProductionLosses(authorizedActions)
        }
        isProductActive={isProductActive}
        minValue={parseInt(lossByProduct[product.productId]['loss']) * -1}
        production={parseInt(lossByProduct[product.productId]['loss'])}
        setActiveProduct={() =>
          setActiveProduct({
            id: product.productId,
            case: ENUM_ACTIVE_PRODUCT_CASE.LOSS,
          })
        }
        unsetActiveProduct={() => setActiveProduct(EMPTY_ACTIVE_PRODUCT)}
      />
    </Section>
  ),
};

export const INPUT_STOCK = {
  class: 'stock',
  name: 'PRODUCTION.STOCKS.HEADER_LOSS',
  render: ({
    product,
    stockByProduct,
    handleStockSave,
    isProductActive,
    setActiveProduct,
    isUserOffline,
    offlineLimitReached,
    authorizedActions,
  }) => (
    <Section>
      <InputIncrementSection
        classNameAddButton={'planning-product-row-stock-add'}
        handleSave={(value) => handleStockSave(product, value)}
        isEditable={
          (!isUserOffline || !offlineLimitReached) && canEditProductionStocks(authorizedActions)
        }
        isProductActive={isProductActive}
        minValue={parseInt(stockByProduct[product.productId]['stock']) * -1}
        production={parseInt(stockByProduct[product.productId]['stock'])}
        setActiveProduct={() =>
          setActiveProduct({
            id: product.productId,
            case: ENUM_ACTIVE_PRODUCT_CASE.STOCKS,
          })
        }
        unsetActiveProduct={() => setActiveProduct(EMPTY_ACTIVE_PRODUCT)}
      />
    </Section>
  ),
};

export const INPUT_TOTAL = {
  class: 'total',
  name: 'PRODUCTION.LOSSES.HEADER_TOTAL',
  render: ({ cost, isProductActive }) => (
    <Section>
      <PriceSection isDisplayed={!isProductActive} value={cost} />
    </Section>
  ),
};

export default {
  INPUT_REFERENCE,
  INPUT_TO_PRODUCE,
  INPUT_PRODUCED,
  INPUT_LEFT,
  INPUT_PRICE_TTC,
  INPUT_LOSS,
  INPUT_STOCK,
  INPUT_TOTAL,
};
