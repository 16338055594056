import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${(props) => props.theme.colors?.greys?.lighter};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 24px 0;
`;

export const WhiteCard = styled.div`
  width: 800px;
  padding: 24px;
  height: fit-content;

  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: ${(props) => props.theme.borders.radius.weak};
`;

export const Title = styled.div`
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
`;

export const SubTitle = styled.div`
  position: relative;
  margin-top: 30px;

  font-size: 14px;
  line-height: 16px;

  color: #000000;
`;

export const Export = styled.div`
  margin-top: 35px;

  > button {
    cursor: ${(props) => props.disableHover && 'default'};
    &:hover {
      cursor: ${(props) => props.disableHover && 'default'};
    }
  }
`;
