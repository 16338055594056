import { last } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { ZERO_AFTER_COMMA, LAST_CHARACTER_IS_NUMBER } from '@commons/utils/regexps';
import Text, { ENUM_COLORS } from '@commons/Text';

import { STOCK_FORM_TYPES } from '@stocks/StocksInventories/components/StockForm/constants/types';

import ModalStock from './modalStock';

export const formatInputValue = (value) => {
  if (value == null) {
    return null;
  }

  const formattedValue = value.toString().replace(',', '.');

  if (
    last(formattedValue) === '.' ||
    ZERO_AFTER_COMMA.test(formattedValue) ||
    LAST_CHARACTER_IS_NUMBER.test(formattedValue)
  ) {
    return formattedValue;
  }

  const updatedValue = !isNaN(parseFloat(value, 10)) ? parseFloat(value, 10) : '';

  return updatedValue;
};

export const getPackagingValues = (packagings, setStocksOfSupplierProductToAdd) => {
  const packagingsArray = Object.values(packagings);
  let inferiorOrEqualToZeroCount = 0;

  packagingsArray.forEach((value) => {
    if (!value || value <= 0 || value === 0) {
      inferiorOrEqualToZeroCount++;
    }
  });

  if (!!setStocksOfSupplierProductToAdd) {
    setStocksOfSupplierProductToAdd(packagings);
  }

  return inferiorOrEqualToZeroCount === packagingsArray.length;
};

export const getStockModalParams = ({
  name,
  price,
  isDirty,
  isStock,
  onChange,
  setTotByPackagingIdEdition,
  totByPackagingIdEdition,
  allPackagings,
  stockType,
}) => {
  let isSaveDisabled = false;

  if (stockType === STOCK_FORM_TYPES.TRANSFER) {
    isSaveDisabled = getPackagingValues(totByPackagingIdEdition);
  }

  return {
    type: 'action',
    width: '542px',
    height: 'auto',
    icon: !isDirty
      ? '/images/inpulse/add-black-small.svg'
      : '/images/inpulse/edit-pen-black-small.svg',
    title: i18next.t(
      !isDirty
        ? `STOCKS.STOCKS.FORM_ADD_${isStock ? 'STOCK' : 'LOSS'}`
        : `STOCKS.STOCKS.FORM_EDIT_${isStock ? 'STOCK' : 'LOSS'}`,
    ),
    component: ModalStock,
    data: {
      price,
      onChange: setTotByPackagingIdEdition,
      totByPackagingId: totByPackagingIdEdition,
      packagings: allPackagings,
      stockType,
    },
    closeConfig: {
      title: i18next.t('GENERAL.LEAVE'),
      content: (
        <Text color={ENUM_COLORS.DARKER} fontLineHeight={24}>
          {i18next.t(
            !isDirty
              ? `STOCKS.STOCKS.FORM_ADD_${isStock ? 'STOCK' : 'LOSS'}_CONFIRM_LEAVE_TEXT`
              : `STOCKS.STOCKS.FORM_EDIT_${isStock ? 'STOCK' : 'LOSS'}_CONFIRM_LEAVE_TEXT`,
            { name },
          )}
        </Text>
      ),
    },
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.SAVE'),
        icon: '/images/inpulse/check-white-small.svg',
        handleClick: () => onChange(totByPackagingIdEdition),
        isDisabled: isSaveDisabled,
      },
    ],
  };
};

export default {
  formatInputValue,
  getStockModalParams,
  getPackagingValues,
};
