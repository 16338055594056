import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getUserTimezone } from '@commons/utils/date';
import DisplayNumber from '@commons/DisplayNumber';

import { Text } from '../styledComponents';

export const getColumns = (clientStoreName) => [
  {
    id: 'storeName',
    name: getClientStoreNameTranslation(clientStoreName),
    propertyKey: 'storeName',
  },
  {
    id: 'rushSupplier',
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_SUPPLIER'),
    propertyKey: 'rushSupplier',
  },
  {
    hidden: true,
    id: 'reference',
    name: '',
    propertyKey: 'reference',
  },
  {
    id: 'createdAt',
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_CREATION_DATE'),
    propertyKey: 'createdAt',
    filterType: 'date',
    excludeFromSearch: true,
    render: (it) => <Text>{moment.tz(it, getUserTimezone()).format('L')}</Text>,
  },
  {
    id: 'receptionDate',
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_DELIVERY_DATE'),
    propertyKey: 'receptionDate',
    filterType: 'date',
    excludeFromSearch: true,
    render: (it, item) => <Text>{moment.tz(it, item.storeTimezone).format('L')}</Text>,
  },
  {
    id: 'totalPrice',
    name: i18next.t('GENERAL.TOTAL_EX_TAX'),
    propertyKey: 'totalPrice',
    filterType: 'numeric',
    excludeFromSearch: true,
    render: (it) => <DisplayNumber displayCurrencyCode={true} number={it} />,
  },
];

export default getColumns;
