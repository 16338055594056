import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';
import styled from 'styled-components';

import { getLimitOrderDay, getWeekDayString } from '@commons/utils/date';

import theme from '@theme';

import {
  Container,
  LegendContainer,
  LegendRow,
  ColoredBall,
  LegendFirstRow,
} from './styledComponents';

class InfoModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      nextDeliveryDate: '',
      deliveryConfiguration: [],
      displayDeliveryProfileLegendModal: false,
      displayTipModal: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.supplierSelected !== this.props.supplierSelected) {
      this.getData();
    }
  }

  getModalHeight = (orderDatesInPast, supplierSelected) => {
    let height = 45;
    if (!supplierSelected) {
      return 0;
    }

    if (orderDatesInPast.length > 0) {
      height += 20;
    }
    if (supplierSelected.deliveryDays && supplierSelected.deliveryLeadTimes) {
      height += 20;
    }
    return height;
  };

  getData = () => {
    let { orderDatesInPast, supplierSelected, nextDeliveryDate, timezone } = this.props;

    let displayDeliveryProfileLegendModal =
      supplierSelected && supplierSelected.deliveryDays && supplierSelected.deliveryLeadTimes;
    this.props.updateLegendModalHeight(this.getModalHeight(orderDatesInPast, supplierSelected));
    this.setState({ displayDeliveryProfileLegendModal });

    if (nextDeliveryDate) {
      this.setState({
        nextDeliveryDate: moment.tz(nextDeliveryDate, timezone).format('dddd DD MMM'),
      });
    }

    if (!displayDeliveryProfileLegendModal) return;

    const { deliveryDays, deliveryLeadTimes } = this.props;

    let deliveryConfiguration = deliveryDays.map((deliveryDay, idx) => ({
      deliveryDay,
      deliveryLeadTime: deliveryLeadTimes[idx],
    }));
    deliveryConfiguration = deliveryConfiguration.sort((a, b) =>
      a.deliveryDay > b.deliveryDay ? 1 : -1,
    );
    deliveryConfiguration = deliveryConfiguration.map((delivery) => ({
      deliveryDayStr: getWeekDayString(delivery.deliveryDay),
      limitOrderDayStr: getLimitOrderDay(delivery.deliveryDay, delivery.deliveryLeadTime),
    }));

    this.setState({ deliveryConfiguration });
  };

  render() {
    const { deliveryConfiguration, displayDeliveryProfileLegendModal, displayTipModal } =
      this.state;
    const { orderDatesInPast, orderTimeLimit, supplierSelected } = this.props;
    return (
      <div className={this.props.className}>
        <div className={`selection-info-div-encloser`}>
          <div
            className="selection-info-div"
            style={{
              height: this.getModalHeight(orderDatesInPast, supplierSelected) + 'px',
            }}
            onClick={() =>
              this.setState({
                displayTipModal: displayDeliveryProfileLegendModal,
              })
            }
          >
            <Container>
              <LegendContainer>
                <LegendFirstRow>
                  <LegendRow>
                    <ColoredBall backgroundColor={theme.colors?.infoGreen} />
                    <span>{i18next.t('ORDERS.ORDERS.FORM_INFO_MODAL_DELIVERY_AVAILABLE')}</span>
                  </LegendRow>
                  {displayDeliveryProfileLegendModal && (
                    <div className="container-more-information">
                      <div className={`more-information ${displayTipModal ? 'selected' : ''}`}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            height="14"
                            src="/images/icon-tooltip-dark.svg"
                            style={{ marginRight: '10px' }}
                            width="14"
                          />
                          <div>{this.props.t('ORDERS.ORDERS.FORM_INFO_MODAL_MORE_INFO')}</div>
                        </div>
                      </div>
                      {displayTipModal && (
                        <div className="selection-tip-div inpulse">
                          <img
                            className="icon-close-tip-panel"
                            src="/images/inpulse/close-black-small.svg"
                            onClick={(ev) => {
                              ev.stopPropagation();
                              this.setState({ displayTipModal: false });
                            }}
                          />
                          {orderTimeLimit && (
                            <div className="selection-tip-time-limit inpulse">
                              {`${this.props.t(
                                'ORDERS.ORDERS.FORM_INFO_MODAL_ORDERING_TIME_DEADLINE',
                              )} : `}
                              <span>{orderTimeLimit}</span>
                            </div>
                          )}
                          <div
                            className="selection-tip-title"
                            style={{ display: 'flex', paddingTop: '15px' }}
                          >
                            <div style={{ flex: 1 }}>
                              {this.props.t('ORDERS.ORDERS.FORM_INFO_MODAL_DAY_OF_DELIVERY')}
                            </div>
                            <div style={{ flex: 1 }}>
                              {this.props.t(
                                'ORDERS.ORDERS.FORM_INFO_MODAL_DAY_OF_DELIVERY_DEADLINE',
                              )}
                            </div>
                          </div>
                          {deliveryConfiguration.map((option) => (
                            <div
                              className="selection-tip-day"
                              key={option.deliveryDayStr}
                              style={{
                                display: 'flex',
                                paddingTop: '5px',
                              }}
                            >
                              <div style={{ flex: 1 }}>{option.deliveryDayStr}</div>
                              <div style={{ flex: 1 }}>{option.limitOrderDayStr}</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </LegendFirstRow>
                {this.props.enableMultipleOrderOnSameDate && (
                  <LegendRow>
                    <ColoredBall backgroundColor={theme.colors?.infoOrange} />
                    <span>
                      {i18next.t(
                        'ORDERS.ORDERS.FORM_INFO_MODAL_DELIVERY_AVAILABLE_BUT_WITH_ALREADY_EXISTING',
                      )}
                    </span>
                  </LegendRow>
                )}
                <LegendRow>
                  <ColoredBall backgroundColor={theme.colors?.infoRed} />
                  <span>{i18next.t('ORDERS.ORDERS.FORM_INFO_MODAL_DELIVERY_UNAVAILABLE')}</span>
                </LegendRow>
              </LegendContainer>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

const StyledInfoModal = styled(InfoModal)`
  & .selection-info-div-encloser {
    position: relative;
    margin-top: ${(props) => (props.enableMultipleOrderOnSameDate ? '58px' : '24px')};
  }

  & .more-information {
    width: 168px !important;
    height: 16px;
    padding: 0px 8px;
    border-radius: 4px;
    position: relative;
    height: fit-content;
    color: ${(props) => props.theme.colors?.greys?.darkest};
    background: ${(props) => props.theme.colors?.greys?.lightest};
    display: flex;
    align-items: center;
  }

  & .more-information.selected {
    background: ${(props) => props.theme.colors?.brand?.primary};
  }

  & .selection-tip-title {
    font: ${(props) => props.theme.fonts?.textSmall};
    color: ${(props) => props.theme.colors?.greys?.darker};
  }

  & .selection-tip-day {
    font: ${(props) => props.theme.fonts?.textSmall};
    color: ${(props) => props.theme.colors?.greys?.darkest};
  }

  & .more-information > div {
    font: ${(props) => props.theme.fonts?.textBigHeight26};
  }

  & .more-information .selection-tip-div {
    font: ${(props) => props.theme.fonts?.textBig};
  }

  & .calendar-order-day {
    font-size: 12px;
    padding-top: 5px;

    font: ${(props) => props.theme.fonts?.textMicro};
  }

  & .selection-info-div {
    position: absolute;
    bottom: 10px;
    width: 618px;
    height: auto !important;
    font-family: proximanovaregular;
    font-size: 13px;
    color: ${(props) => props.theme.colors?.infoGreen};
    line-height: 16px;
    font: ${(props) => props.theme.fonts?.kpiSmall};
    display: inline-block;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
    border-radius: 3px;
    padding: 24px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
    background: #ffffff;
    z-index: 10;
  }

  & .selection-tip-div.inpulse {
    position: absolute;
    width: 356px;
    margin-top: 10px;
    z-index: 11;
    left: 233px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
  }

  & .selection-tip-time-limit.inpulse {
    font: ${(props) => props.theme.fonts?.textBig};
    color: ${(props) => props.theme.colors?.greys?.darkest};
  }

  & .selection-info-div h3 {
    margin: 0;
    padding: 0;
    line-height: 16px;
    font-size: 13px;
    font-family: proximanovasemibold;
    display: inline-block;

    font: ${(props) => props.theme.fonts?.textBig};
  }

  & .selection-info-div p {
    font-family: proximanovasemibold;
    padding-top: 5px;
    font-size: 10px;
    color: #6b768c;
    line-height: 16px;
    font: ${(props) => props.theme.fonts?.textMicro} !important;
  }

  & .selection-tip-div {
    position: absolute;
    width: 300px;
    margin-top: -90px;
    background: #ffffff;
    z-index: 11;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
    border-radius: 3px;
  }

  & .icon-close-tip-panel {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 16px;
    height: 18px;
    cursor: pointer;
  }

  & .icon-close-tip-panel:hover {
    -webkit-filter: url(#colorize);
    filter: url(#colorize);
  }

  & .selection-tip-div {
    padding: 20px;
  }

  & .selection-tip-div > h2 {
    padding: 0;
    margin: 0;
    font-family: proximanovaregular;
    font-size: 14px;
    color: #2cd696;
  }

  & .selection-tip-time-limit {
    font-size: 12px;
    font-weight: bold;
    color: black;
  }

  & .selection-tip-time-limit > span {
    font-weight: normal;
  }

  & .deliveridays-tio-day {
    font-family: proximanovaregular;
    font-size: 12px;
    color: #000000;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
    margin-top: 15px;
  }

  & .deliveridays-tio-day > p {
    display: inline-block;
    line-height: 14px;
  }

  & .deliveridays-tio-day > h4 {
    font-family: proximanovasemibold;
    font-size: 12px;
    line-height: 10px;
    margin: 0 3px;
    padding: 0;
    display: inline-block;
  }
`;

export default withTranslation()(StyledInfoModal);
