import i18next from 'i18next';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

import StoreMappingModal from '@admin/suppliers/supplierProducts/details/supplierProductStores/components/SupplierProductMappings/components/StoreMappingModal';

export const getStoreMappingModalConfig = ({
  availableStores,
  setSelectedStores,
  selectedStores,
  handleAddNewMappings,
  price,
  isCentralKitchenView,
}) => ({
  type: 'actionWhite',
  isFullscreen: true,
  title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.CREATE_STORE_MAPPING_TITLE'),
  icon: '/images/inpulse/add-black-small.svg',
  customPadding: '24px 24px 0px 24px',
  component: StoreMappingModal,
  data: {
    availableStores,
    setSelectedAvailableStores: setSelectedStores,
    price,
    isCentralKitchenView,
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      handleClick: () => {
        handleAddNewMappings(selectedStores);
      },
    },
  ],
});

export default getStoreMappingModalConfig;
