import i18next from 'i18next';

const getRegistrationSuccessSettings = (settings) => {
  const { history } = settings;

  const title = i18next.t('AUTHENTICATION.REGISTRATION.LANDING_STEP_THREE_REGISTRATION_TITLE');

  const successButtonParams = {
    color: 'inpulse-default',
    isDisabled: false,
    label: i18next.t('AUTHENTICATION.LOGIN.CONNECT'),
    icon: '/images/inpulse/user-white.svg',
    handleClick: () => history.replace('/login'),
  };

  const actions = [successButtonParams];

  const params = {
    registrationText: i18next.t(
      'AUTHENTICATION.REGISTRATION.LANDING_STEP_THREE_REGISTRATION_SUCCESS_TEXT',
    ),
  };

  return {
    title,
    params,
    actions,
  };
};

export default getRegistrationSuccessSettings;
