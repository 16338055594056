import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { Button } from '@commons/utils/styledLibraryComponents';

import {
  canChangeOrderDeliveryDate,
  canCreateDraftOrder,
  canEditDraftOrder,
  canEditSentOrReceivedOrder,
  canReceiveOrderAndSendCreditRequest,
  canValidateCreditRequestWithoutMail,
} from '@selectors/actions/orderActions';
import { getAuthorizedActions } from '@selectors/featureProps';

import ORDER_STATUS, { ORDER_PREPARATION_STATUS } from '../../../constants/status';

const HeaderButtons = (props) => {
  const {
    handleSave,
    orderStatus,
    isConfirmable,
    orderedUnit,
    sending,
    sendingParams,
    isGeneratingData,
    disableSendButton,
    handleClickClose,
    authorizedActions,
    handleSaveDelivery,
    displayCart,
    setDisplayCart,
    nbReferencesOrdered,
    displayCreditForm,
    setDisplayCreditForm,
    nbAnomalies,
    isEditingPreparationOrder,
    handlePreparationSave,
    setDisplayDeliveryNotePreview,
    displayDeliveryNotePreview,
    preparationStatus,
  } = props;

  /* ----- COMMON VARIABLES ----- */

  const sendingParamsEmailOrder = get(sendingParams, 'email.order', {});

  const hasAnomalies = nbAnomalies > 0;

  const isBeforeSentStatus = [
    ORDER_STATUS.CREATION,
    ORDER_STATUS.DRAFT,
    ORDER_STATUS.SENT_EDITABLE,
  ].includes(orderStatus);

  const isSentOrReception = [ORDER_STATUS.SENT, ORDER_STATUS.RECEPTION].includes(orderStatus);

  const isAfterReceived = [
    ORDER_STATUS.COMPLIANT,
    ORDER_STATUS.INCOMPLETE,
    ORDER_STATUS.NON_COMPLIANT,
    ORDER_STATUS.CREDIT_REQUEST_PROCESSED,
  ].includes(orderStatus);

  /* ----- CLOSE BUTTON ----- */

  const isCloseButtonDisplayed = () =>
    !displayCart && !displayCreditForm && !displayDeliveryNotePreview;

  /* ----- CANCEL BUTTON ----- */

  const isCancelButtonDisplayed = () =>
    displayCart || displayCreditForm || displayDeliveryNotePreview;

  /* ----- SAVE BUTTON ----- */

  const isSaveButtonDisplayed = () => {
    if (orderStatus === ORDER_STATUS.CREATION && canCreateDraftOrder(authorizedActions)) {
      return true;
    }

    if (
      [ORDER_STATUS.DRAFT, ORDER_STATUS.SENT_EDITABLE].includes(orderStatus) &&
      canEditDraftOrder(authorizedActions)
    ) {
      return true;
    }

    if (
      isSentOrReception &&
      !isEditingPreparationOrder &&
      canEditSentOrReceivedOrder(authorizedActions)
    ) {
      return true;
    }

    if (
      isSentOrReception &&
      isEditingPreparationOrder &&
      preparationStatus < ORDER_PREPARATION_STATUS.COMPLIANT_PREPARED
    ) {
      return true;
    }

    if (
      isAfterReceived &&
      !isEditingPreparationOrder &&
      canChangeOrderDeliveryDate(authorizedActions)
    ) {
      return true;
    }

    return false;
  };

  const getSaveButtonColor = () => {
    if (
      (isBeforeSentStatus && (sending === 'edi' || !isEmpty(sendingParamsEmailOrder))) ||
      isSentOrReception
    ) {
      return 'inpulse-outline';
    }

    return 'inpulse-default';
  };

  const getSaveButtonIcon = () => {
    if ((isBeforeSentStatus && sending === '') || isAfterReceived) {
      return '/images/inpulse/save-white-small.svg';
    }

    return '/images/inpulse/save-black-small.svg';
  };

  const runSaveAction = () => {
    if (isEditingPreparationOrder) {
      return handlePreparationSave();
    }

    if (isSentOrReception) {
      return handleSaveDelivery(true);
    }

    if (isAfterReceived) {
      return handleSaveDelivery(false);
    }

    return handleSave('save');
  };

  const isSaveButtonDisabled = () => {
    if (isSentOrReception) {
      return isGeneratingData || disableSendButton;
    }

    if (isAfterReceived) {
      return disableSendButton;
    }

    return orderedUnit === 0 || !isConfirmable;
  };

  const handleValidation = () => {
    if (isEditingPreparationOrder) {
      setDisplayDeliveryNotePreview(true);
      return;
    }

    handleSaveDelivery(false);
  };

  /* ----- VALIDATION BUTTON ----- */

  const isValidationButtonDisplayed = () => {
    if (
      isSentOrReception &&
      canReceiveOrderAndSendCreditRequest(authorizedActions) &&
      !hasAnomalies &&
      !isEditingPreparationOrder
    ) {
      return true;
    }

    if (
      isSentOrReception &&
      isEditingPreparationOrder &&
      preparationStatus < ORDER_PREPARATION_STATUS.COMPLIANT_PREPARED &&
      !displayDeliveryNotePreview
    ) {
      return true;
    }

    return false;
  };
  /* ----- SEND BUTTON ----- */

  /* ----- CART BUTTON ----- */
  const isCartButtonDisplayed = () =>
    isBeforeSentStatus && !displayCart && !isEditingPreparationOrder;

  const isCartButtonDisabled = () => {
    if (isEditingPreparationOrder) {
      return false;
    }

    if (isBeforeSentStatus) {
      return orderedUnit <= 0 || disableSendButton;
    }

    return isGeneratingData || disableSendButton;
  };

  /* ----- CREDIT BUTTON ----- */
  const isCreditButtonDisplayed = () =>
    !displayCreditForm &&
    isSentOrReception &&
    (canValidateCreditRequestWithoutMail(authorizedActions) ||
      canReceiveOrderAndSendCreditRequest(authorizedActions)) &&
    hasAnomalies &&
    !isEditingPreparationOrder;
  /* ----- RENDER ----- */

  return (
    <div className={props.className}>
      <div className="orderForm-buttons-container">
        {isCloseButtonDisplayed() && (
          <Button
            color={'inpulse-grey'}
            handleClick={handleClickClose}
            icon={'/images/inpulse/close-white-small.svg'}
          />
        )}
        {isCancelButtonDisplayed() && (
          <Button
            color={'inpulse-grey'}
            handleClick={() => {
              displayCart && setDisplayCart(false);
              displayCreditForm && setDisplayCreditForm(false);
              displayDeliveryNotePreview && setDisplayDeliveryNotePreview(false);
            }}
            icon={'/images/inpulse/arrow-left-white.svg'}
          />
        )}
        {isSaveButtonDisplayed() && (
          <Button
            color={getSaveButtonColor()}
            handleClick={runSaveAction}
            icon={getSaveButtonIcon()}
            isDisabled={isSaveButtonDisabled()}
          />
        )}
        {isValidationButtonDisplayed() && (
          <Button
            color={'inpulse-default'}
            handleClick={handleValidation}
            icon={'/images/inpulse/check-white-small.svg'}
            isDisabled={isGeneratingData || disableSendButton}
          />
        )}
        {isCartButtonDisplayed() && (
          <Button
            color={'inpulse-default'}
            count={nbReferencesOrdered}
            handleClick={() => {
              setDisplayCart(true);
            }}
            icon={'/images/inpulse/order-white-small.svg'}
            isDisabled={isCartButtonDisabled()}
          />
        )}
        {isCreditButtonDisplayed() && (
          <Button
            color={'inpulse-default'}
            count={nbAnomalies}
            handleClick={() => {
              setDisplayCreditForm(true);
            }}
            icon={'/images/inpulse/send-white-small.svg'}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/order/orders'),
});

const Styled = styled(HeaderButtons)`
  & .orderForm-buttons-container {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
  }
  & .block-order-filters-right-wrapper {
    display: flex;
    & > :not(:last-child) {
      margin-right: 12px;
    }
  }
`;
export default connect(mapStateToProps)(Styled);
