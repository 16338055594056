// ===== Module features for easy import in ClientContainer =====

import ProfileDetail from './detail';
import ProfileDetailStores from './team/stores';

import BackOfficeTeamsManagement from '../backoffice/BackOfficeUsersContainer/BackOfficeTeamsManagement';

const UsersModule = {
  ProfileDetail,
  ProfileDetailStores,
  TeamDetail: BackOfficeTeamsManagement,
};

export default UsersModule;
