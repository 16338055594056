import PropTypes from 'prop-types';
import React from 'react';

import { GraphBarsProps } from './interfaces';
import { GraphBars, GraphValue, GraphBar } from './styledComponents';

const GraphBarsComponent = (props: GraphBarsProps): JSX.Element => {
  const {
    nbColumn,
    value,
    color,
    height,
    valuePosition,
    unit,
    hideUnit,
    hideValue,
  } = props;

  if (hideValue) {
    return (
      <GraphBars nbColumn={nbColumn}>
        <GraphBar color={color} height={Math.min(height, 100)} />
      </GraphBars>
    );
  }

  return (
    <GraphBars nbColumn={nbColumn}>
      <GraphValue position={valuePosition}>
        {hideUnit
          ? `${value > 0 ? value : 0}`
          : `${value > 0 ? value : 0} ${unit}`}
      </GraphValue>
      <GraphBar color={color} height={Math.min(height, 100)} />
    </GraphBars>
  );
};

GraphBarsComponent.propTypes = {
  nbColumn: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  valuePosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideUnit: PropTypes.bool,
  hideValue: PropTypes.bool,
};

GraphBarsComponent.defaultProps = {
  valuePosition: null,
  hideUnit: false,
  hideValue: false,
};

export default GraphBarsComponent;
