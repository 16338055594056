import i18next from 'i18next';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import utilsXLS, { generateDefaultSheet } from '@commons/utils/makeXLS';

const MAX_SHEET_ELEMENT_SIZE = 50000;

const FILENAME = i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_FILE_NAME');
const PRODUCT_SALES_MAIN_SHEET_TITLE = i18next.t(
  'BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_MAIN_SHEET_NAME',
);
const PRODUCT_SALES_CONTENT_SHEET_TITLE = i18next.t('GENERAL.INFORMATIONS');
const _getMainSheetHeaders = (storeName) => [
  {
    name: storeName,
    propertyKey: 'storeName',
    width: 30,
  },
  { name: i18next.t('GENERAL.CASHIER_CONNEXION'), propertyKey: 'cashierConfigName', width: 30 },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_CASHIER_STORE'),
    propertyKey: 'cashierStoreName',
    width: 30,
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_PARTNER_ID'),
    propertyKey: 'partnerId',
    width: 30,
  },
  { name: i18next.t('GENERAL.CREATION_DATE'), propertyKey: 'timestamp', width: 25 },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_CASHIER_PRODUCT_SKU'),
    propertyKey: 'sku',
    width: 30,
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_CASHIER_PRODUCT_NAME'),
    propertyKey: 'productName',
    width: 30,
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_PRICE_EXC_TAXES'),
    propertyKey: 'productPrice',
    width: 20,
    type: 'currency',
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_CASHIER_PRODUCT_TAXES'),
    propertyKey: 'productVatRate',
    width: 20,
  },
  { name: i18next.t('GENERAL.QUANTITY'), propertyKey: 'quantity', width: 10 },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_CASHIER_PRODUCT_TAGS'),
    propertyKey: 'tags',
    width: 30,
  },
  { name: i18next.t('GENERAL.CHANNEL'), propertyKey: 'channel', width: 10 },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_TRANSACTION_ID'),
    propertyKey: 'transactionId',
    width: 30,
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_TRANSACTION_PRICE_WITH_TAXES'),
    propertyKey: 'includingTaxesTransactionPrice',
    width: 20,
    type: 'currency',
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_TRANSACTION_PRICE_EXC_TAXES'),
    propertyKey: 'excludingTaxesTransactionPrice',
    width: 20,
    type: 'currency',
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_TRANSACTION_MENU_ID'),
    propertyKey: 'cashierMenuId',
    width: 30,
  },
  {
    name: i18next.t(
      'BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_TRANSACTION_MENU_PRICE_WITH_TAXES',
    ),
    propertyKey: 'menuPrice',
    width: 20,
    type: 'currency',
  },
];

const _getContentSheetHeaders = (storeName) => [
  {
    name: storeName,
    propertyKey: 'storeName',
    width: 30,
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_CASHIER_STORE'),
    propertyKey: 'cashierStoreName',
    width: 30,
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_INFO_HEADER_START_DATE'),
    propertyKey: 'startDate',
    width: 30,
  },
  {
    name: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_INFO_HEADER_END_DATE'),
    propertyKey: 'endDate',
    width: 30,
  },
];

const _createContentSheet = (storeCashierStoreMapping, contentSheetHeader, currency) =>
  generateDefaultSheet(
    PRODUCT_SALES_CONTENT_SHEET_TITLE,
    contentSheetHeader,
    storeCashierStoreMapping,
    currency,
  );
const _createMainSheet = (cashierProductSales, mainSheetHeader, currency) =>
  generateDefaultSheet(
    PRODUCT_SALES_MAIN_SHEET_TITLE,
    mainSheetHeader,
    cashierProductSales,
    currency,
  );
export const makeProductSalesXlsx = (
  formattedCashierProductSalesData,
  clientName,
  storeName,
  currency,
) => {
  const { cashierProductSales, cashierStoreMapping } = formattedCashierProductSalesData;

  const translatedStoreName = getClientStoreNameTranslation(storeName, true);
  const mainSheetHeader = _getMainSheetHeaders(translatedStoreName);

  const contentSheetHeader = _getContentSheetHeaders(translatedStoreName);

  let productSalesToExport = cashierProductSales;
  let index = 1;

  const hasManyFiles = productSalesToExport.length > MAX_SHEET_ELEMENT_SIZE;

  if (!hasManyFiles) {
    const mainSheet = _createMainSheet(productSalesToExport, mainSheetHeader, currency);
    const contentSheet = _createContentSheet(cashierStoreMapping, contentSheetHeader, currency);

    utilsXLS.makeXLS(`${FILENAME} - ${clientName}`, [mainSheet, contentSheet]);

    return;
  }

  while (productSalesToExport.length > 0) {
    const productSalesForSheet = productSalesToExport.splice(0, MAX_SHEET_ELEMENT_SIZE);

    const mainSheet = _createMainSheet(productSalesForSheet, mainSheetHeader, currency);
    const contentSheet = _createContentSheet(cashierStoreMapping, contentSheetHeader, currency);

    utilsXLS.makeXLS(`${FILENAME} - ${clientName} - ${index}`, [mainSheet, contentSheet]);
    index++;
  }
};

export default makeProductSalesXlsx;
