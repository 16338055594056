import _ from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { getTheme } from '@commons/utils/theme';
import { Label } from '@commons/utils/styledLibraryComponents';

import stockUtils from '@stocks/utils';

const GAP_VALUE_LIMIT = 20;

const theme = getTheme();

const getListConfig = (selectedMetric, currency) => {
  const metricKey = _.get(selectedMetric, 'key', null);

  return [
    {
      id: 0,
      name: i18next.t('GENERAL.NAME'),
      propertyKey: 'name',
      large: true,
      isSortable: true,
    },
    {
      id: 1,
      name: i18next.t('ANALYSIS.STOCKS.COLUMN_NAME_THEORETICAL_STOCK'),
      propertyKey: `theoricalStock.${metricKey}`,
      tooltipDisplay: i18next.t('ANALYSIS.STOCKS.COLUMN_TOOLTIP_THEORETICAL_STOCK'),
      isNumber: true,
      render: (value, item) => {
        if (value == null) {
          return (
            <Label
              background={theme.colors.highlightedBlue}
              children={'--'}
              color={theme.colors.highlightedBlue}
              type={''}
            />
          );
        }

        return (
          <Label
            background={theme.colors.highlightedBlue}
            children={stockUtils.getStockValueByMetric(value, item, metricKey, currency)}
            color={theme.colors.highlightedBlue}
            type={''}
          />
        );
      },
      isSortable: true,
    },
    {
      id: 2,
      name: i18next.t('ANALYSIS.STOCKS.COLUMN_NAME_ACTUAL_STOCK'),
      propertyKey: `realStockWithRecipes.${metricKey}`,
      tooltipDisplay: i18next.t('ANALYSIS.STOCKS.COLUMN_TOOLTIP_ACTUAL_STOCK'),
      isNumber: true,
      render: (value, item) => {
        if (value == null) {
          return (
            <Label
              background={theme.colors.greys.dark}
              children={'--'}
              color={theme.colors.greys.dark}
              type={''}
            />
          );
        }

        return (
          <Label
            background={theme.colors.greys.dark}
            children={stockUtils.getStockValueByMetric(value, item, metricKey, currency)}
            color={theme.colors.greys.dark}
            type={''}
          />
        );
      },
      isSortable: true,
    },
    {
      id: 3,
      name: i18next.t('ANALYSIS.STOCKS.COLUMN_NAME_STOCK_DEVIATION'),
      propertyKey: `stockGapValue.${metricKey}`,
      tooltipDisplay: i18next.t('ANALYSIS.STOCKS.COLUMN_TOOLTIP_STOCK_DEVIATION'),
      isNumber: true,
      render: (value, item) =>
        stockUtils.renderStockGap(value, item, metricKey, currency, GAP_VALUE_LIMIT),
      isSortable: true,
    },
    {
      id: 4,
      name: i18next.t('ANALYSIS.STOCKS.COLUMN_NAME_PERCENT_STOCK_DEVIATION'),
      propertyKey: 'stockGapPercentage',
      tooltipDisplay: i18next.t('ANALYSIS.STOCKS.COLUMN_TOOLTIP_PERCENT_STOCK_DEVIATION'),
      isNumber: true,
      render: (value) => stockUtils.renderGapPercentage(value, GAP_VALUE_LIMIT),
      isSortable: true,
    },
    {
      id: 5,
      name: i18next.t('ANALYSIS.STOCKS.COLUMN_NAME_LAST_INVENTORIES'),
      tooltipDisplay: i18next.t('ANALYSIS.STOCKS.COLUMN_TOOLTIP_LAST_INVENTORIES'),
      propertyKey: 'lastInventories',
      customModalRender: true,
      isSortable: false,
    },
    {
      id: 6,
      name: '',
      propertyKey: 'action',
      isClickable: true,
      isSortable: false,
    },
  ];
};

const getModalConfig = () => [
  { propertyKey: 'url' },
  {
    propertyKey: 'inventoryListReference',
    bold: true,
  },
  {
    propertyKey: 'quantity',
    bold: true,
  },
  { propertyKey: 'inventoryDate' },
];

export default {
  getListConfig,
  getModalConfig,
};
