import { Tooltip } from '@trendmicro/react-tooltip';
import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import styled from 'styled-components';

import { ENUM_FONTS } from '@commons/Text';
import DisplayNumber from '@commons/DisplayNumber';

export class DataTile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  formatEllipsis(number) {
    let string = Math.round(number).toString();
    if (string.length > 5) {
      string = string.substring(0, 5) + '..';
    }
    return string;
  }

  handleTileClick() {
    if (this.props.isCalendar) {
      this.props.handleDayOpen();
    }
  }

  mouseEnter = () => (this.props.mouseEnter ? this.props.mouseEnter() : null);

  mouseLeave = () => (this.props.mouseLeave ? this.props.mouseLeave() : null);

  render() {
    let { data, isPast } = this.props;
    const big = isPast ? data.sale : data.saved;
    const small = isPast ? data.saved : data.dynamic;
    const label = isPast ? data.error : data.salePast;
    let lowerRightClass = 'future';
    if (isPast) {
      if (label || label === 0) {
        if (label > 0) {
          lowerRightClass = 'positive';
        } else {
          lowerRightClass = 'negative';
        }
      }
    }

    return (
      <div
        className={`${this.props.className} ${this.props.isPast ? 'past' : 'future'}`}
        id={this.props.id}
        onClick={() => this.handleTileClick()}
      >
        <div
          className="forecast-data-tile-weather-activator"
          onMouseEnter={this.mouseEnter}
          onMouseLeave={this.mouseLeave}
        ></div>
        <div className="forecast-data-tile-upper">
          <img alt={data.weather.desc} src={data.weather.img || '/images/icon-empty-weather.png'} />
          <div className="forecast-data-tile-temp">
            <p className="forecast-data-tile-temp-left">
              {!data.weather.tMax && data.weather.tMax !== 0 ? '--' : data.weather.tMax}
            </p>
            <p className="forecast-data-tile-temp-right">
              {' '}
              {'| ' + (!data.weather.tMin && data.weather.tMin !== 0 ? '--' : data.weather.tMin)}
            </p>
          </div>
        </div>
        <div className="forecast-data-tile-lower">
          <Tooltip
            content={
              isPast
                ? this.props.displayName
                : this.props.t('FORECAST.TURNOVER.TOOLTIP_FORECAST_MANAGER')
            }
            placement="top"
            tooltipClassName="deepsight-tooltip"
          >
            <h3 className={`forecast-data-tile-lower-${isPast ? 'past' : 'future'}`}>
              {big || big === 0 ? (
                <DisplayNumber
                  font={ENUM_FONTS.KPI_BIG}
                  number={big}
                  shortenDigits={true}
                  withoutDecimals={true}
                />
              ) : (
                '-'
              )}
            </h3>
          </Tooltip>
          <div className="forecast-data-tile-lower-sec">
            <Tooltip
              content={
                isPast
                  ? this.props.t('FORECAST.TURNOVER.TOOLTIP_FORECAST_MANAGER')
                  : this.props.t('FORECAST.TURNOVER.TOOLTIP_FORECAST_INPULSE')
              }
              placement="top"
              tooltipClassName="deepsight-tooltip"
            >
              <p className={`forecast-data-tile-lower-left ${isPast ? 'past' : 'future'}`}>
                {small || small === 0 ? (
                  <DisplayNumber
                    font={ENUM_FONTS.TEXT_BIG}
                    number={small}
                    shortenDigits={true}
                    withoutDecimals={true}
                  />
                ) : (
                  '-'
                )}
              </p>
            </Tooltip>
            <div className="forecast-data-tile-lower-spacer"></div>
            <Tooltip
              content={
                isPast
                  ? this.props.t('FORECAST.TURNOVER.TOOLTIP_GAP_FORECAST_SALES')
                  : this.props.t('FORECAST.TURNOVER.TOOLTIP_PAST_YEAR')
              }
              placement="top"
              tooltipClassName="deepsight-tooltip"
            >
              <p className={`forecast-data-tile-lower-right ${lowerRightClass}`}>
                {label || label === 0 ? (
                  <DisplayNumber
                    font={ENUM_FONTS.TEXT_BIG}
                    number={label}
                    shortenDigits={true}
                    withoutDecimals={true}
                  />
                ) : (
                  '-'
                )}
              </p>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}

const StyledDataTile = styled(DataTile)`
  width: 100%;
  min-width: 80px;
  height: 84px;
  background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
  border-radius: ${(props) => props.theme.borders?.weak || '3px'};
  ${(props) => (props.theme.colors ? '' : 'box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);')}
  position: relative;
  cursor: ${(props) => (props.isCalendar ? 'pointer' : 'default')};
  &.past {
    background: ${(props) => `repeating-linear-gradient(
        135deg,
        ${props.theme.colors?.greys.lightest || '#fffff'},
        ${props.theme.colors?.greys.lightest || '#fffff'} 2px,
        ${props.theme.colors?.greys.lighter || '#fffff'} 2px,
        ${props.theme.colors?.greys.lighter || '#fffff'} 4px
      )`};
  }

  &.future {
    background: ${(props) => props.theme.colors?.greys.lightest || '#fffff'};
  }
  .forecast-data-tile-upper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
  }
  .forecast-data-tile-weather-activator {
    position: absolute;
    width: 70%;
    margin-left: 15%;
    height: 30px;
    z-index: 1;
  }
  .forecast-data-tile-upper > img {
    width: 22px;
    margin-right: 10px;
    /* height: 22px; */
  }

  .forecast-data-tile-temp > p {
    display: inline;
    ${(props) =>
      props.theme.fonts
        ? `font:${props.theme.fonts?.textBig};`
        : `font-family: proximanovaregular;
    font-size: 13px;`}

    color: #6b768c;
    text-align: center;

    &.forecast-data-tile-temp-left {
      color: ${(props) => props.theme.colors?.greys.darker || '#6B768C'};
    }

    &.forecast-data-tile-temp-right {
      color: ${(props) => props.theme.colors?.greys.dark || '#abb1be'};
    }
  }

  .forecast-data-tile-lower {
    /* margin-left: calc((100% - 80px)/2); */
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100% - 32px);
    align-items: center;
    justify-content: center;
  }

  .forecast-data-tile-lower > h3 {
    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.kpiBig};`
        : `font-family: proximanovasemibold;
          font-size: 26px;
          line-height: 26px;`}

    color: #000000;
    margin: 0;
    &.forecast-data-tile-lower-past {
      color: ${(props) => props.theme.colors?.greys.darker || '#98A6C3'};
    }
    &.forecast-data-tile-lower-future {
      color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};
    }
  }

  .forecast-data-tile-lower-sec {
    display: flex;
    height: 17px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: ${(props) => (props.theme.colors ? '10px' : undefined)};
  }

  .forecast-data-tile-lower-sec > p {
    ${(props) =>
      props.theme.fonts
        ? `font: ${props.theme.fonts?.textBig};`
        : `font-family: proximanovasemibold;
    padding-top: 2px;
    font-size: 14px;`}

    text-align: center;
    width: ${(props) => (props.theme.fonts ? '30%' : 'calc(50% - 1.5px)')};
    margin: 0;

    &.forecast-data-tile-lower-left {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${(props) => props.theme.colors?.brand.secondaryLight};
      border-radius: ${(props) => props.theme.borders?.radius.weaker};
      width: 45%;

      &.past {
        color: ${(props) => props.theme.colors?.greys.darker || '#000000'};
        background: ${(props) => props.theme.colors?.greys.light};
      }

      &.future {
        color: ${(props) => props.theme.colors?.greys.darkest || '#0A3EFF'};
      }
    }

    &.forecast-data-tile-lower-right {
      &.future {
        background: ${(props) => props.theme.colors?.greys.lighter};
        border-radius: ${(props) => props.theme.borders?.radius.weaker};
        width: 45%;
        color: ${(props) => props.theme.colors?.greys.darkest || '#0A3EFF'};
      }

      &.positive {
        color: ${(props) => props.theme.colors?.primaryDark || '#2CD696'};
      }

      &.negative {
        color: ${(props) => props.theme.colors?.red || '#EF252A'};
      }
    }
  }

  .forecast-data-tile-lower-spacer {
    width: 1.5px;
    height: 14px;
    background: #d8d8d8;
    margin-top: 4px;
  }
`;

export default withTranslation()(StyledDataTile);
