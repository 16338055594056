import { reverse } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import React, { useEffect, useState } from 'react';

import { Button, Dropdown, Label } from '../../../utils/styledLibraryComponents';

import { DATE_DISPLAY_FORMATS } from '../../constants';

import {
  Container,
  DropdownsContainer,
  EndDateContainer,
  Footer,
  Header,
  StartDateContainer,
} from './styledComponents';

const formattedDateToDropdownItem = (momentDate) => ({
  id: momentDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  value: momentDate.format(DATE_DISPLAY_FORMATS.DATE_MONTH_YEAR_WEEK_NUMBER),
});

const PeriodWeekPickerModal = (props) => {
  const {
    startDate,
    endDate,
    minDate,
    maxDate,
    maxDeltaWeek,
    setIsModalOpened,
    theme,
    onDateChange,
    setNewStartDate,
    setNewEndDate,
  } = props;

  const [selectedStartDate, setSelectedStartDate] = useState(
    formattedDateToDropdownItem(startDate),
  );
  const [selectedEndDate, setSelectedEndDate] = useState(formattedDateToDropdownItem(endDate));

  const [startDateDropdownSelectablesItems, setStartDateDropdownSelectablesItems] = useState([]);
  const [endDateDropdownSelectablesItems, setEndDateDropdownSelectablesItems] = useState([]);

  useEffect(() => {
    buildStartDateDropdownSelectablesItems();
    buildEndDateDropdownSelectablesItems();
  }, []);

  useEffect(() => {
    buildEndDateDropdownSelectablesItems();
  }, [selectedStartDate]);

  const buildStartDateDropdownSelectablesItems = () => {
    const weeks = [];

    for (
      let iterationDate = minDate.clone();
      iterationDate.isSameOrBefore(maxDate);
      iterationDate.add(1, 'week')
    ) {
      weeks.push(formattedDateToDropdownItem(iterationDate));
    }

    setStartDateDropdownSelectablesItems(reverse(weeks));
  };

  const buildEndDateDropdownSelectablesItems = () => {
    const weeks = [];

    for (
      let iterationDate = moment(selectedStartDate.id).clone();
      iterationDate.isSameOrBefore(maxDate) && (!maxDeltaWeek || weeks.length < maxDeltaWeek);
      iterationDate.add(1, 'week')
    ) {
      weeks.push(formattedDateToDropdownItem(iterationDate));
    }

    setEndDateDropdownSelectablesItems(reverse(weeks));
  };

  return (
    <Container>
      <Header>{i18next.t('GENERAL.SELECT_PERIOD')}</Header>

      <DropdownsContainer>
        <StartDateContainer>
          <Label background={theme.colors.white} color={theme.colors.blacks.ipBlack1}>
            {i18next.t('GENERAL.START')} :
          </Label>
          <Dropdown
            customStyle={{ width: '240px' }}
            items={startDateDropdownSelectablesItems}
            selectedItem={selectedStartDate}
            sortBy={null}
            isRequired
            onSelectionChange={(item) => {
              const newDate = moment(item.id);

              setNewStartDate(newDate);
              setSelectedStartDate(item);

              const weekDiffBetweenStartAndMax = maxDate.diff(newDate, 'weeks');

              if (!maxDeltaWeek || weekDiffBetweenStartAndMax < maxDeltaWeek) {
                setNewEndDate(maxDate);
                setSelectedEndDate(formattedDateToDropdownItem(maxDate));
                return;
              }

              const computedEndDate = newDate.clone().add(maxDeltaWeek - 1, 'weeks');

              setNewEndDate(computedEndDate);
              setSelectedEndDate(formattedDateToDropdownItem(computedEndDate));
            }}
          />
        </StartDateContainer>

        <EndDateContainer>
          <Label background={theme.colors.white} color={theme.colors.blacks.ipBlack1}>
            {i18next.t('GENERAL.END')} :
          </Label>
          <Dropdown
            customStyle={{ width: '240px' }}
            items={endDateDropdownSelectablesItems}
            selectedItem={selectedEndDate}
            sortBy={null}
            isRequired
            onSelectionChange={(item) => {
              setNewEndDate(moment(item.id));
              setSelectedEndDate(item);
            }}
          />
        </EndDateContainer>
      </DropdownsContainer>

      <Footer>
        <Button
          color={'inpulse-outline'}
          handleClick={() => {
            setIsModalOpened(false);
          }}
          icon={'/images/inpulse/close-black-small.svg'}
          label={i18next.t('GENERAL.CLOSE')}
        />
        <Button
          color={'inpulse-default'}
          handleClick={() => {
            onDateChange(moment(selectedStartDate.id), moment(selectedEndDate.id));
            setIsModalOpened(false);
          }}
          icon={'/images/inpulse/check-white-small.svg'}
          label={i18next.t('GENERAL.APPLY')}
        />
      </Footer>
    </Container>
  );
};

export default PeriodWeekPickerModal;
