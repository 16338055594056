import _ from 'lodash';
import i18next from 'i18next';
import parse from 'html-react-parser';
import React, { useRef, useState } from 'react';

import { Button, Tooltip } from '@commons/utils/styledLibraryComponents';

import { Input } from '@lib/inpulse/Input';

import { InputContainer, Text } from './styledComponents';
import MultiplePictureUpload from './components/OrderFormInfoPanel/components/MultiplePictureUpload';

const RenderConfirmationModal = (props) => {
  const {
    confirmationModalTitle,
    cc,
    supplierEmail,
    attachment,
    handleSupplierInfoEmail,
    handleCC,
    handleCancel,
    handleConfirm,
    showMessage,
  } = props;

  const pictureSelectedRef = useRef();
  const [displayDropdownActions, setDisplayDropdownActions] = useState(false);

  const [isSizeTooHeavy, setIsSizeTooHeavy] = useState(false);

  return (
    <div className="conf-modal-encloser">
      <div className="conf-modal-content-encloser">
        <div className="conf-modal-upper-info-green-light">
          <p className="conf-modal-upper-p-info-green-light">{confirmationModalTitle}</p>
        </div>
        <div className="conf-modal-down">
          <div className="modal-confirm-order-content-input-container">
            <>
              <InputContainer>
                <Input
                  label={i18next.t('USERS.DETAILS.PROFILE_EMAIL_INPUT_LABEL')}
                  type="text"
                  value={supplierEmail || ''}
                  onChange={handleSupplierInfoEmail}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  label={i18next.t('GENERAL.LABEL_EMAIL_CC')}
                  type="text"
                  value={cc}
                  onChange={handleCC}
                />
              </InputContainer>
              {attachment && (
                <>
                  <Text style={{ marginBottom: '8px' }}>
                    {i18next.t('ORDERS.ORDERS.FORM_SEND_CREDIT_REQUEST_ATTACHMENT')} :
                    <Tooltip
                      dataHtml={true}
                      text={i18next.t('ORDERS.ORDERS.FORM_SEND_CREDIT_REQUEST_ATTACHMENT_TOOLTIP')}
                    />
                  </Text>
                  <MultiplePictureUpload
                    displayDropdownActions={displayDropdownActions}
                    isSizeTooHeavy={isSizeTooHeavy}
                    multipleFiles={false}
                    ref={pictureSelectedRef}
                    setDisplayDropdownActions={setDisplayDropdownActions}
                    setIsSizeTooHeavy={setIsSizeTooHeavy}
                    showMessage={showMessage}
                  />
                </>
              )}
            </>
          </div>
          <div className="conf-down-buttons separator">
            <Button
              color={'inpulse-outline'}
              handleClick={handleCancel}
              icon={'/images/inpulse/close-black-small.svg'}
              label={i18next.t('GENERAL.CANCEL')}
            />
            <Button
              color={supplierEmail && !isSizeTooHeavy ? 'blue' : 'locked'}
              handleClick={() =>
                handleConfirm(
                  pictureSelectedRef.current &&
                    _.get(pictureSelectedRef.current.getSelectedPictures()[0], 'data'),
                )
              }
              icon={'/images/inpulse/check-white-small.svg'}
              isDisabled={!supplierEmail || isSizeTooHeavy}
              label={i18next.t('GENERAL.CONFIRM')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderCloseOrderModal = (props) => {
  const { closeCloseOrderModal, verifiedClose } = props;
  return (
    <div className="conf-modal-encloser">
      <div className="conf-modal-content-encloser">
        <div className="conf-modal-upper-red">
          <p className="conf-modal-upper-p-red">{i18next.t('GENERAL.LEAVE')}</p>
        </div>
        <div className="conf-modal-down">
          <div className="conf-modal-text">
            {i18next.t('ORDERS.ORDERS.MODAL_CLOSE_TEXT_1')}
            <br />
            {i18next.t('ORDERS.ORDERS.MODAL_CLOSE_TEXT_2')}
          </div>
          <div className="conf-down-buttons">
            <Button
              color={'inpulse-outline'}
              handleClick={closeCloseOrderModal}
              label={i18next.t('GENERAL.CANCEL')}
            />
            <Button color={'red'} handleClick={verifiedClose} label={i18next.t('GENERAL.LEAVE')} />
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderValidateModal = (props) => {
  const { title, text, closeModal, handleConfirm } = props;

  return (
    <div className="conf-modal-encloser">
      <div className="conf-modal-content-encloser">
        <div className="conf-modal-upper-orange">
          <div className="conf-modal-upper-p-orange">{title}</div>
        </div>
        <div className="conf-modal-down">
          <div className="conf-modal-text">{text}</div>
          <div className="conf-down-buttons">
            <Button
              color={'inpulse-outline'}
              handleClick={closeModal}
              icon={'/images/inpulse/close-black-small.svg'}
              label={i18next.t('GENERAL.NO')}
            />
            <Button
              color={'inpulse-default'}
              handleClick={handleConfirm}
              icon={'/images/inpulse/check-white-small.svg'}
              label={i18next.t('GENERAL.YES')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderStockReassemblyNotDoneErrorModal = (props) => {
  const { closeStockReassemblyNotDoneModal, goInventoriesPage } = props;
  return (
    <div
      className="conf-modal-encloser"
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          closeStockReassemblyNotDoneModal();
        }
      }}
    >
      <div className="conf-modal-content-encloser">
        <div className="conf-modal-upper-orange">
          <p className="conf-modal-upper-p-orange">
            {i18next.t('ORDERS.ORDERS.MODAL_ERROR_STOCK_REASSEMBLY_TITLE')}
          </p>
        </div>
        <div className={'modal-confirm-order-content'}>
          <p>{parse(i18next.t('ORDERS.ORDERS.MODAL_ERROR_STOCK_REASSEMBLY_CONTENT'))}</p>
        </div>
        <div className="conf-down-buttons">
          <Button
            color={'inpulse-outline'}
            handleClick={() => closeStockReassemblyNotDoneModal()}
            label={i18next.t('GENERAL.CANCEL')}
          />
          <Button
            color={'orange'}
            handleClick={goInventoriesPage}
            label={i18next.t('ORDERS.ORDERS.MODAL_ERROR_BUTTON_STOCK_REASSEMBLY')}
          />
        </div>
      </div>
    </div>
  );
};

const RenderForecastNotConfirmedErrorModal = (props) => {
  const { closeForecastNotFoundModal, endDate, goForecastPage, startDate } = props;
  const forecaseNotConfirmedErrorText = startDate.isSame(endDate, 'day')
    ? i18next.t('ORDERS.ORDERS.MODAL_ERROR_FORECASTS_NOT_CONFIRM_CONTENT_SAME_DATE_START_END', {
        date: startDate.format('DD/MM'),
      })
    : i18next.t('ORDERS.ORDERS.MODAL_ERROR_FORECASTS_NOT_CONFIRM_CONTENT_RANGE_DATE_START_END', {
        startDate: startDate.startOf('day').format('DD/MM/YYYY'),
        endDate: endDate.endOf('day').format('DD/MM/YYYY'),
      });
  return (
    <div
      className="conf-modal-encloser"
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          closeForecastNotFoundModal();
        }
      }}
    >
      <div className="conf-modal-content-encloser">
        <div className="conf-modal-upper-orange">
          <p className="conf-modal-upper-p-orange">
            {i18next.t('ORDERS.ORDERS.MODAL_ERROR_FORECASTS_NOT_CONFIRM_TITLE')}
          </p>
        </div>
        <div className="conf-modal-down">
          <div className="conf-modal-text">
            <p>{parse(forecaseNotConfirmedErrorText)}</p>
          </div>
          <div className="conf-down-buttons">
            <Button
              color={'inpulse-outline'}
              handleClick={() => closeForecastNotFoundModal()}
              label={i18next.t('GENERAL.CANCEL')}
            />
            <Button
              color={'orange'}
              handleClick={goForecastPage}
              label={i18next.t('ORDERS.ORDERS.MODAL_ERROR_BUTTON_FORECASTS')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderSendingErrorModal = (props) => {
  const { closeSendingErrorModal, displaySendingError, sendingErrorMessage } = props;
  let header = 'Echec';
  let reason = '';
  if (!!sendingErrorMessage) {
    header = i18next.t('ORDERS.ORDERS.MODAL_ERROR_SENDING_SEND_TITLE');
    reason = sendingErrorMessage;
  } else {
    if (displaySendingError === 'customerCode') {
      header = i18next.t('ORDERS.ORDERS.MODAL_ERROR_SENDING_CUSTOMER_CODE_TITLE');
      reason = i18next.t('ORDERS.ORDERS.MODAL_ERROR_SENDING_CUSTOMER_CODE_CONTENT');
    } else if (displaySendingError === 'save') {
      header = i18next.t('ORDERS.ORDERS.MODAL_ERROR_SENDING_SAVE_TITLE');
      reason = i18next.t('ORDERS.ORDERS.MODAL_ERROR_SENDING_SAVE_CONTENT');
    } else {
      header = i18next.t('ORDERS.ORDERS.MODAL_ERROR_SENDING_SEND_TITLE');
      reason =
        displaySendingError === 'save-before-send'
          ? i18next.t('ORDERS.ORDERS.MODAL_ERROR_SENDING_SEND_CONTENT_SAVE_BEFORE_SEND')
          : i18next.t('ORDERS.ORDERS.MODAL_ERROR_SENDING_SEND_CONTENT_SAVE');
    }
  }
  return (
    <div className="conf-modal-encloser">
      <div className="conf-modal-content-encloser">
        <div className="conf-modal-upper-red">
          <p className="conf-modal-upper-p-red">{header}</p>
        </div>
        <div className="conf-modal-down">
          <div className="conf-modal-text">
            <p>{parse(reason)}</p>
          </div>
          <div className="conf-down-buttons">
            <Button
              color={'red'}
              handleClick={() => closeSendingErrorModal()}
              icon={'/images/inpulse/check-white-small.svg'}
              label={i18next.t('ORDERS.ORDERS.MODAL_ERROR_BUTTON_UNDERSTOOD')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderSendToPartnerConfirmationModal = (props) => {
  const { closeSendToPartnerConfirmationModal, sendOrderToPartner } = props;
  return (
    <div
      className="conf-modal-encloser"
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          closeSendToPartnerConfirmationModal();
        }
      }}
    >
      <div className="conf-modal-content-encloser">
        <div className="conf-modal-upper-blue">
          <p className="conf-modal-upper-p-blue">
            {i18next.t('ORDERS.ORDERS.MODAL_SEND_TO_PARTNER_TITLE')}
          </p>
        </div>
        <div className="conf-modal-down">
          <div className="conf-modal-text">
            <p>{i18next.t('ORDERS.ORDERS.MODAL_SEND_TO_PARTNER_CONTENT')}</p>
          </div>
          <div className="conf-down-buttons">
            <Button
              color={'inpulse-outline'}
              handleClick={() => closeSendToPartnerConfirmationModal()}
              icon={'/images/inpulse/close-black-small.svg'}
              label={i18next.t('GENERAL.CANCEL')}
            />
            <Button
              color={'blue'}
              handleClick={sendOrderToPartner}
              icon={'/images/inpulse/check-white-small.svg'}
              label={i18next.t('GENERAL.CONFIRM')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenderConfirmationModal;

export {
  RenderConfirmationModal,
  RenderCloseOrderModal,
  RenderForecastNotConfirmedErrorModal,
  RenderStockReassemblyNotDoneErrorModal,
  RenderSendingErrorModal,
  RenderSendToPartnerConfirmationModal,
  RenderValidateModal,
};
