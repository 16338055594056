import i18next from 'i18next';
import React from 'react';
import styled from 'styled-components';

import { InpulseLabel } from '@commons/DeepsightComponents';
import { numberToFixed } from '@commons/utils/format';
import DisplayNumber from '@commons/DisplayNumber';

const LabelContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Label = styled.div`
  width: fit-content;
  height: 20px;

  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  color: #000000;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.red {
    color: #ef252a;
    font-weight: 700;
  }

  &.orange {
    color: #ff9c40;
    font-weight: 700;
  }

  &.green {
    color: #44d7b6;
    font-weight: 700;
  }
`;

const getColumnsTable = (currency, metric, hasMultipleBrands, allowedColumnsByKey) => {
  let columns = [
    {
      id: 0,
      propertyKey: 'productName',
      type: 'string',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCT_NAME'),
    },
    {
      id: 1,
      propertyKey: 'sku',
      type: 'string',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_SKU'),
    },
    {
      id: 2,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_FORECAST'),
      type: 'numeric',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'forecastedQuantity',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_FORECAST_TOOLTIP_UNIT'),
    },
    {
      id: 3,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_STOCKS'),
      type: 'numeric',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'stockedQuantity',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_STOCKS_TOOLTIP_UNIT'),
    },
    {
      id: 4,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_TO_PRODUCE'),
      type: 'numeric',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'toProduceQuantity',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_TO_PRODUCE_TOOLTIP_UNIT'),
    },
    {
      id: 5,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCED'),
      type: 'numeric',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'producedQuantity',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCED_TOOLTIP_UNIT'),
    },
    {
      id: 6,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCTION_RATIO'),
      type: 'percentage',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'quantityProductionRatio',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCTION_RATIO_TOOLTIP_UNIT'),
      render: (item) => {
        const valueToDisplay = numberToFixed(item, 0, '-', '%');

        if (item == null) {
          return (
            <LabelContainer>
              <Label>-</Label>
            </LabelContainer>
          );
        }

        if (item <= 75 || item >= 125) {
          return (
            <LabelContainer>
              <InpulseLabel color={'red'} text={valueToDisplay} type={'secondary'} />
            </LabelContainer>
          );
        }

        if ((item >= 75 && item < 90) || (item > 110 && item > 125)) {
          return (
            <LabelContainer>
              <InpulseLabel color={'orange'} text={valueToDisplay} type={'secondary'} />
            </LabelContainer>
          );
        }

        return (
          <LabelContainer>
            <Label>{valueToDisplay}</Label>
          </LabelContainer>
        );
      },
    },
    {
      id: 7,
      name: 'Casse',
      type: 'numeric',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'lossQuantity',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS_TOOLTIP_UNIT'),
    },
    {
      id: 8,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS_RATIO'),
      type: 'percentage',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'quantityTurnoverWasteRate',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_TURNOVER_LOSS_RATIO_TOOLTIP_UNIT'),
      render: (item) => {
        const valueToDisplay = numberToFixed(item, 0, '-', '%');
        return (
          <LabelContainer>
            <Label>{valueToDisplay}</Label>
          </LabelContainer>
        );
      },
    },
    {
      id: 9,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS_RATIO'),
      type: 'percentage',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'quantityProductionWasteRate',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCTION_LOSS_RATIO_TOOLTIP_UNIT'),
      render: (item) => {
        const valueToDisplay = numberToFixed(item, 0, '-', '%');
        return (
          <LabelContainer>
            <Label>{valueToDisplay}</Label>
          </LabelContainer>
        );
      },
    },
    {
      id: 10,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_SALES'),
      type: 'numeric',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'realSales',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_SALES_TOOLTIP_UNIT'),
    },
    {
      id: 11,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_CONFORMITY_RATIO'),
      type: 'percentage',
      filterType: 'numeric',
      propertyKey: 'conformity',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_CONFORMITY_RATIO_TOOLTIP'),
      render: (item) => (
        <LabelContainer>
          <Label>{numberToFixed(item, 2, '-', '%')}</Label>
        </LabelContainer>
      ),
    },
    {
      id: 12,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_CADENCE'),
      type: 'percentage',
      filterType: 'numeric',
      propertyKey: 'cadence',
      render: (item) => (
        <LabelContainer>
          <Label>{numberToFixed(item, 2, '-', '%')}</Label>
        </LabelContainer>
      ),
    },
  ];

  if (metric === 'turnover') {
    columns = [
      {
        id: 0,
        propertyKey: 'productName',
        type: 'string',
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCT_NAME'),
      },
      {
        id: 1,
        propertyKey: 'sku',
        type: 'string',
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_SKU'),
      },
      {
        id: 2,
        decimals: 0,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_FORECAST'),
        type: 'currency',
        filterType: 'numeric',
        propertyKey: 'forecastedTurnover',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_FORECAST_TOOLTIP_TURNOVER'),
        render: (item) => (
          <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
        ),
      },
      {
        id: 3,
        decimals: 0,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_STOCKS'),
        type: 'currency',
        filterType: 'numeric',
        propertyKey: 'stockedTurnover',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_STOCKS_TOOLTIP_TURNOVER'),
        render: (item) => (
          <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
        ),
      },
      {
        id: 4,
        decimals: 0,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_TO_PRODUCE'),
        type: 'currency',
        filterType: 'numeric',
        propertyKey: 'toProduceTurnover',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_TO_PRODUCE_TOOLTIP_TURNOVER'),
        render: (item) => (
          <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
        ),
      },
      {
        id: 5,
        decimals: 0,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCED'),
        type: 'currency',
        filterType: 'numeric',
        propertyKey: 'producedTurnover',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCED_TOOLTIP_TURNOVER'),
        render: (item) => (
          <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
        ),
      },
      {
        id: 6,
        decimals: 0,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCTION_RATIO'),
        type: 'percentage',
        filterType: 'numeric',
        propertyKey: 'turnoverProductionRatio',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCTION_RATIO_TOOLTIP_TURNOVER'),
        render: (item) => {
          const valueToDisplay = numberToFixed(item, 0, '-', '%');

          if (item == null) {
            return (
              <LabelContainer>
                <Label>-</Label>
              </LabelContainer>
            );
          }

          if (item <= 75 || item >= 125) {
            return (
              <LabelContainer>
                <InpulseLabel color={'red'} text={valueToDisplay} type={'secondary'} />
              </LabelContainer>
            );
          }

          if ((item >= 75 && item < 90) || (item > 110 && item > 125)) {
            return (
              <LabelContainer>
                <InpulseLabel color={'orange'} text={valueToDisplay} type={'secondary'} />
              </LabelContainer>
            );
          }

          return (
            <LabelContainer>
              <Label>{valueToDisplay}</Label>
            </LabelContainer>
          );
        },
      },
      {
        id: 7,
        name: 'Casse',
        type: 'currency',
        decimals: 0,
        filterType: 'numeric',
        propertyKey: 'loss',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS_TOOLTIP_TURNOVER'),
        render: (item) => (
          <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
        ),
      },
      {
        id: 8,
        decimals: 0,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS_RATIO'),
        type: 'percentage',
        propertyKey: 'productionWasteRate',
        tooltipText: i18next.t(
          'PRODUCTION.REAL_TIME.LABEL_PRODUCTION_LOSS_RATIO_TOOLTIP_TURNOVER',
          { currency: currency.alphabeticCode },
        ),
        filterType: 'numeric',
        render: (item) => {
          const valueToDisplay = numberToFixed(item, 0, '-', '%');
          return (
            <LabelContainer>
              <Label>{valueToDisplay}</Label>
            </LabelContainer>
          );
        },
      },
      {
        id: 9,
        decimals: 0,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS_RATIO'),
        type: 'percentage',
        propertyKey: 'turnoverWasteRate',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_TURNOVER_LOSS_RATIO_TOOLTIP_TURNOVER', {
          currency: currency.alphabeticCode,
        }),
        filterType: 'numeric',
        render: (item) => {
          const valueToDisplay = numberToFixed(item, 0, '-', '%');
          return (
            <LabelContainer>
              <Label>{valueToDisplay}</Label>
            </LabelContainer>
          );
        },
      },
      {
        id: 10,
        decimals: 0,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_SALES'),
        type: 'currency',
        filterType: 'numeric',
        propertyKey: 'realTurnover',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_SALES_TOOLTIP_TURNOVER', {
          currency: currency.alphabeticCode,
        }),
        render: (item) => (
          <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
        ),
      },
      {
        id: 11,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_CONFORMITY_RATIO'),
        type: 'percentage',
        propertyKey: 'conformity',
        filterType: 'numeric',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_CONFORMITY_RATIO_TOOLTIP'),
        render: (item) => (
          <LabelContainer>
            <Label>{numberToFixed(item, 2, '-', '%')}</Label>
          </LabelContainer>
        ),
      },
      {
        id: 12,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_CADENCE'),
        type: 'percentage',
        propertyKey: 'cadence',
        filterType: 'numeric',
        render: (item) => (
          <LabelContainer>
            <Label>{numberToFixed(item, 2, '-', '%')}</Label>
          </LabelContainer>
        ),
      },
    ];
  }

  if (hasMultipleBrands) {
    allowedColumnsByKey['brand'] = true;

    columns.splice(2, 0, {
      id: 'brand-row',
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_BRAND'),
      type: 'string',
      propertyKey: 'Marque',
    });
  }

  return columns.filter((column) => allowedColumnsByKey[column.propertyKey]);
};

export default getColumnsTable;
