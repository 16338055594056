const SHEET = {
  id: 'suppliers',
  name: 'Fournisseurs',
};

const COLUMN_IDS = {
  CATEGORY: 'category',
  ID: 'id',
  NAME: 'name',
};

module.exports = {
  SHEET,
  COLUMN_IDS,
};
