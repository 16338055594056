import _, { groupBy } from 'lodash';
import i18next from 'i18next';

import { formatNumberToExcelUsage } from '@commons/DisplayNumber';

import { getEntityUnit } from '@stocks/utils';

const PAST_STOCK_SHEET_COLUMNS_SETTINGS = (clientStoreName) => [
  {
    name: clientStoreName,
    propertyKey: 'storeName',
  },
  {
    name: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
  },
  {
    name: i18next.t('GENERAL.UNIT'),
    propertyKey: 'unit',
    transform: (value) =>
      getEntityUnit(value) === 'u.' ? i18next.t('GENERAL.UNIT') : getEntityUnit(value),
  },
  {
    name: i18next.t('STOCKS.STOCKS.THEORETICAL_STOCK_SINGULAR_UNIT'),
    propertyKey: 'theoricalStockUnit',
  },
  {
    name: i18next.t('STOCKS.STOCKS.REAL_STOCK_SINGULAR_UNIT'),
    propertyKey: 'realStockUnit',
  },
  {
    name: i18next.t('STOCKS.STOCKS.GAP_STOCK_SINGULAR_UNIT'),
    propertyKey: 'stockGapUnit',
  },
  {
    type: 'currency',
    name: i18next.t('STOCKS.STOCKS.THEORETICAL_STOCK_SINGULAR_CURRENCY'),
    propertyKey: 'theoricalStockTurnover',
  },
  {
    name: i18next.t('STOCKS.STOCKS.REAL_STOCK_SINGULAR_CURRENCY'),
    propertyKey: 'realStockTurnover',
  },
  {
    name: i18next.t('STOCKS.STOCKS.GAP_STOCK_SINGULAR_CURRENCY'),
    propertyKey: 'stockGapTurnover',
  },
  {
    name: i18next.t('STOCKS.PAST_STOCKS.GAP_PERCENT'),
    propertyKey: 'stockGapPercentage',
  },
];

const INFORMATION_SHEET_COLUMNS_SETTINGS = (clientStoreName) => [
  {
    name: clientStoreName,
    propertyKey: 'storeName',
  },
  {
    name: i18next.t('GENERAL.DATE'),
    propertyKey: 'date',
  },
  {
    name: i18next.t('STOCKS.PAST_STOCKS.EXPORT_COLUMN_NAME_TOTAL_THEORICAL_STOCK'),
    propertyKey: 'totalTheoricalStock',
  },
  {
    name: i18next.t('STOCKS.PAST_STOCKS.EXPORT_COLUMN_NAME_TOTAL_ACTUAL_STOCK'),
    propertyKey: 'totalActualStock',
  },
];

export const createPastStockSheet = (storesPastStock, clientStoreName) => {
  const data = [];

  for (const stock of storesPastStock) {
    const theoricalStockUnit = _.get(stock, 'theoricalStock.unit', null);
    const theoricalStockTurnover = _.get(stock, 'theoricalStock.turnover', null);

    const realStockUnit = _.get(stock, 'realStockWithRecipes.unit', null);
    const realStockTurnover = _.get(stock, 'realStockWithRecipes.turnover', null);

    const stockGapUnit = _.get(stock, 'stockGapValue.unit', null);
    const stockGapTurnover = _.get(stock, 'stockGapValue.turnover', null);

    data.push({
      ...stock,
      theoricalStockUnit: !!theoricalStockUnit ? Math.max(theoricalStockUnit.toFixed(2), 0) : '',
      theoricalStockTurnover: !!theoricalStockTurnover
        ? Math.max(theoricalStockTurnover.toFixed(2), 0)
        : '',

      realStockUnit:
        realStockUnit != null ? formatNumberToExcelUsage(realStockUnit.toFixed(2), 2) : '',
      realStockTurnover:
        realStockTurnover != null ? formatNumberToExcelUsage(realStockTurnover.toFixed(2), 2) : '',

      stockGapUnit: !!stockGapUnit ? formatNumberToExcelUsage(stockGapUnit.toFixed(2), 2) : '',
      stockGapTurnover: !!stockGapTurnover
        ? formatNumberToExcelUsage(stockGapTurnover.toFixed(2), 2)
        : '',
    });
  }

  return {
    data,
    title: i18next.t('FEATURE.STOCKS.PAST'),
    headers: PAST_STOCK_SHEET_COLUMNS_SETTINGS(clientStoreName),
  };
};

export const createInformationSheet = (storesPastStock, date, clientStoreName) => {
  const data = [];

  const pastStocksByStoreId = groupBy(storesPastStock, 'storeName');

  for (const [storeName, values] of Object.entries(pastStocksByStoreId)) {
    const totalActualStock = values.reduce((result, { realStockWithRecipes }) => {
      const value = realStockWithRecipes.turnover;

      if (value == null) {
        return result;
      }

      return result + Math.max(value, 0);
    }, null);

    const totalTheoricalStock = values.reduce((result, { theoricalStock }) => {
      const value = theoricalStock.turnover;

      if (value == null) {
        return result;
      }

      return result + Math.max(value, 0);
    }, null);

    data.push({
      date,
      storeName,
      totalActualStock: totalActualStock == null ? '' : Math.round(totalActualStock * 100) / 100,
      totalTheoricalStock:
        totalTheoricalStock == null ? '' : Math.round(totalTheoricalStock * 100) / 100,
    });
  }

  return {
    data,
    title: i18next.t('STOCKS.PAST_STOCKS.EXPORT_SHEET_NAME_INFORMATION'),
    headers: INFORMATION_SHEET_COLUMNS_SETTINGS(clientStoreName),
  };
};
