import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

import { canEditInventory } from '@selectors/actions/inventoriesStockActions';
import { getAuthorizedActions } from '@selectors/featureProps';

import { isEditionAllowed } from '@stocks/StocksInventories/common/rights';

import { HeaderButtonsContainer } from './styledComponents';

function StockFormHeaderButtons(props) {
  const {
    closeModal,
    handleSave,
    inventoryCreationDate,
    authorizedActions,
    isEditingDate,
    isCreation,
    isCorrectingInventory,
    isValidated,
  } = props;

  const [isSaveAvailable, setIsSaveAvailable] = useState(false);

  useEffect(() => {
    if (isCreation) {
      setIsSaveAvailable(true);

      return;
    }

    if (isValidated && !isCorrectingInventory && !isEditingDate) {
      setIsSaveAvailable(false);
      return;
    }

    if (isEditionAllowed(inventoryCreationDate) && canEditInventory(authorizedActions)) {
      setIsSaveAvailable(true);
      return;
    }

    if (!isEditionAllowed(inventoryCreationDate) && isCorrectingInventory) {
      setIsSaveAvailable(true);

      return;
    }

    if (isEditingDate) {
      setIsSaveAvailable(true);
      return;
    }

    setIsSaveAvailable(false);
  }, [inventoryCreationDate, authorizedActions, isEditingDate, isCorrectingInventory]);

  return (
    <HeaderButtonsContainer>
      <Button
        color={'inpulse-grey'}
        handleClick={closeModal}
        icon={'/images/inpulse/close-white-small.svg'}
      />
      {isSaveAvailable && (
        <Button
          color={'inpulse-default'}
          handleClick={() => {
            handleSave();
          }}
          icon={'/images/inpulse/save-white-small.svg'}
          isDisabled={!handleSave}
        />
      )}
    </HeaderButtonsContainer>
  );
}

const mapStateToProps = (state) => ({
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/stocks/inventories/stocks',
  ),
});

export default connect(mapStateToProps)(StockFormHeaderButtons);
