import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  background: #1d1d1b40;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const ImageContainer = styled.div`
  max-width: 400px;
`;

export const ProductImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const CrossCloseContainer = styled.div`
  width: 40px;
  height: 40px;

  top: 24px;
  right: 24px;
  position: absolute;

  cursor: pointer;

  border-radius: 50px;
  background-color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {
  Container,
  ProductImage,
  ImageContainer,
  CrossCloseContainer,
};
