import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChangelogContainer = styled.div``;

export const Thread = styled.div`
  border-radius: 34px;
  margin-left: 7px; // Required to have 7px so that the thread is aligned with the icons
  height: 48px;
  width: 2px;
  background-color: ${(props) => props.theme.colors.brand.primaryDarker};
`;

export const BeginningThread = styled.div`
  border-left-width: 2px;
  border-left-style: solid;
  border-image: url('/images/inpulse/changelog-beginning-border-pattern.svg') 0 2 0 round;
  margin-left: 7px;
  height: 48px;
`;

export const EndingThread = styled.div`
  border-left-width: 2px;
  border-left-style: solid;
  border-image: url('/images/inpulse/changelog-ending-border-pattern.svg') 0 16 / 8 / 4 stretch;
  margin-left: 8px;
  height: 48px;
`;
