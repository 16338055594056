import Ajv from 'ajv';
import i18next from 'i18next';

import ConfirmationModal from '../../../components/ConfirmationModal';
import PropsEditorModal from '../../../components/PropsEditorModal';

const ajv = new Ajv();

export const getPropsEditorModalConfig = ({
  feature,
  props,
  schema,
  onPropsChange,
  handleSavePropsFeature,
}) => {
  const validate = ajv.compile(schema);

  const valid = validate(props);

  return {
    type: 'action',
    width: '600px',
    height: 'auto',
    icon: '/images/inpulse/gear-black-small.svg',
    title: i18next.t('BACKOFFICE.FEATURES.HANDLE_PROPS_ACTION'),
    component: PropsEditorModal,
    data: {
      json: props,
      schema: schema,
      onChange: (value) => onPropsChange(feature, value),
    },
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        isDisabled: !valid,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.SAVE'),
        icon: '/images/inpulse/save-white-small.svg',
        handleClick: () => handleSavePropsFeature(feature),
      },
    ],
  };
};

export const getDeleteFeatureModalConfig = ({ feature, handleDeleteAssociatedFeatureById }) => ({
  type: 'warning',
  width: '542px',
  height: 'auto',
  icon: '/images/inpulse/trash-white-small.svg',
  title: i18next.t('BACKOFFICE.FEATURES.DELETE_FEATURE_ACTION'),
  component: ConfirmationModal,
  data: {
    content: i18next.t('BACKOFFICE.FEATURES.DELETE_FEATURE_CONTENT'),
  },
  actions: [
    {
      key: 0,
      color: 'inpulse-outline',
      label: i18next.t('GENERAL.CANCEL'),
      icon: '/images/inpulse/close-black-small.svg',
    },
    {
      key: 1,
      color: 'inpulse-default',
      label: i18next.t('GENERAL.DELETE'),
      icon: '/images/inpulse/trash-white-small.svg',
      handleClick: () => handleDeleteAssociatedFeatureById(feature.id),
    },
  ],
});

export default {
  getPropsEditorModalConfig,
  getDeleteFeatureModalConfig,
};
