import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { Container } from './styledComponents';

import { convertDateToCountDownText, getUserTimezone } from '../utils/date';

import Text, { ENUM_COLORS } from '../Text';

const DATE_FORMAT = 'DD/MM à HH:mm';

export const LastAction = (props) => {
  const { label, date, displayCountDown } = props;

  const formatDate = () => {
    if (!date) {
      return '--';
    }

    if (displayCountDown) {
      return convertDateToCountDownText(date);
    }

    return moment.tz(date, getUserTimezone()).format(DATE_FORMAT);
  };

  return (
    <Container>
      <Text color={ENUM_COLORS.DARKER} fontWeight={500}>
        {label}
      </Text>
      <Text fontWeight={700} style={{ marginTop: '8px' }}>
        {formatDate()}
      </Text>
    </Container>
  );
};

LastAction.propTypes = {
  label: PropTypes.string,
  displayCountDown: PropTypes.bool,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

LastAction.defaultProps = {
  label: '',
  date: null,
  displayCountDown: false,
};

export default LastAction;
