import { get, keyBy, orderBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import utilsXLS from '@commons/utils/makeXLS';

import { INVOICE_CONTROL_STATUS_TRANSLATED } from '../../invoiceControls/utils/constants';
import { statusLegend } from '@orders/OrderList/common/constants';

const getMainSheetOcerizationColumnsSettings = (storeName) => {
  const columns = [
    {
      displayName: i18next.t('GENERAL.SUPPLIER'),
      keyName: 'supplierName',
    },
    {
      displayName: getClientStoreNameTranslation(storeName),
      keyName: 'storeName',
    },
    {
      displayName: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_IMPORT_DATE'),
      keyName: 'importedAt',
    },
    {
      displayName: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_NUMBER_EXPORT'),
      keyName: 'invoiceNumber',
    },
    {
      displayName: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_DATE'),
      keyName: 'invoiceDate',
    },
    {
      displayName: i18next.t('INVOICE.INVOICE_CONTROLS.ARTICLE_CODE'),
      keyName: 'sku',
    },
    {
      displayName: i18next.t(
        'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_COLUMN_NAME_ARTICLE',
      ),
      keyName: 'invoiceName',
    },
    {
      displayName: i18next.t(
        'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_COLUMN_PACKAGING',
      ),
      keyName: 'invoicePackaging',
    },
    {
      displayName: i18next.t(
        'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_COLUMN_PRICE_EXCLUDING_TAXES',
      ),
      keyName: 'invoicePrice',
    },
    {
      displayName: i18next.t(
        'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_COLUMN_QUANTITY',
      ),
      keyName: 'invoiceQuantity',
    },
    {
      displayName: i18next.t(
        'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_COLUMN_TOTAL',
      ),
      keyName: 'computedTotal',
    },
  ];

  return columns;
};

const createMainSheetOcerization = (stores, storeName, sheetName) => {
  const headersSettings = getMainSheetOcerizationColumnsSettings(storeName);

  const headers = headersSettings.map(({ displayName }) => displayName);

  const mappedData = stores.map((store) =>
    headersSettings.map(({ keyName, transform }) => {
      if (!transform) {
        return store[keyName];
      }
      return transform(store[keyName]);
    }),
  );

  return {
    headers,
    title: sheetName,
    data: mappedData,
  };
};

export const exportOcerization = (invoice, data, storeName) => {
  const fileName = i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_FILENAME', {
    date: moment(invoice.createdAt).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  });

  const sheetName = i18next.t(
    'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_MAIN_SHEET_NAME',
  );

  const computedData = data.map((item) => ({
    ...item,
    storeName: get(invoice, 'store.name'),
    supplierName: get(invoice, 'supplier.name'),
    computedTotal: item.invoicePrice * item.invoiceQuantity,
    invoiceNumber: get(invoice, 'number', ''),
    invoiceDate:
      invoice.date && moment(invoice.date).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
    importedAt:
      invoice.createdAt &&
      moment(invoice.createdAt).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  }));

  const mainSheet = createMainSheetOcerization(computedData, storeName, sheetName);

  utilsXLS.makeXLS(fileName, [mainSheet]);
};

export const getDetailedExportGapsSheetColumnsSettings = () => {
  const columns = [
    {
      name: i18next.t(
        'INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_DETAIL_COLUMN_ORDER_REFERENCE',
      ),
      propertyKey: 'orderReference',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_NUMBER'),
      propertyKey: 'invoiceNumber',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.ARTICLE_CODE'),
      propertyKey: 'invoiceSku',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.ARTICLE_CODE_INPULSE'),
      propertyKey: 'spSku',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_ARTICLE_NAME'),
      propertyKey: 'invoiceSpName',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INPULSE_ARTICLE_NAME'),
      propertyKey: 'spName',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_PACKAGING'),
      propertyKey: 'invoicePackaging',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INPULSE_PACKAGING_NAME'),
      propertyKey: 'spPackagingName',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_PRICE_EXCL_TAXES'),
      propertyKey: 'invoiceSpPrice',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INPULSE_INVOICE_PRICE_EXCL_TAXES'),
      propertyKey: 'spPrice',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_PRICE_GAP_EXCL_TAXES'),
      propertyKey: 'priceGap',
      type: 'currency',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_QUANTITY'),
      propertyKey: 'invoiceSpQuantity',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INPULSE_QUANTITY'),
      propertyKey: 'spInvoicedQuantity',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_QUANTITY_GAP'),
      propertyKey: 'quantityGap',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_TOTAL_GAP'),
      propertyKey: 'totalGap',
      type: 'currency',
    },
    {
      name: i18next.t('GENERAL.STATUS'),
      propertyKey: 'status',
      transform: (status) => INVOICE_CONTROL_STATUS_TRANSLATED[status] || null,
    },
  ];

  return columns;
};

export const getDetailedExportInfoSheetColumnsSettings = (storeName) => {
  const columns = [
    {
      name: i18next.t('GENERAL.SUPPLIER'),
      propertyKey: 'supplierName',
    },
    {
      name: getClientStoreNameTranslation(storeName),
      propertyKey: 'storeName',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_IMPORT_DATE'),
      propertyKey: 'importedAt',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_NUMBER'),
      propertyKey: 'invoiceNumber',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_DATE'),
      propertyKey: 'invoiceDate',
    },
    {
      name: i18next.t('GENERAL.TOTAL_EX_TAX'),
      propertyKey: 'total',
      type: 'currency',
    },
  ];

  return columns;
};

export const getDetailedExportAssociatedOrdersSheetColumnsSettings = (storeName) => {
  const columns = [
    {
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_NUMBER'),
      propertyKey: 'invoiceNumber',
    },
    {
      name: i18next.t('GENERAL.SUPPLIER'),
      propertyKey: 'orderSupplierName',
    },
    {
      name: getClientStoreNameTranslation(storeName),
      propertyKey: 'orderStoreName',
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDER_COLUMN_DELIVERY_DATE'),
      propertyKey: 'orderDeliveryDate',
      transform: (orderDeliveryDate) =>
        moment(orderDeliveryDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
    },
    {
      name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDER_COLUMN_STATUS'),
      propertyKey: 'orderStatus',
      transform: (orderStatus) => {
        const orderStatusName = i18next.t(
          statusLegend[orderStatus]?.name || 'ORDERS.ORDERS.LIST_LABEL_STATUS_NOT_DEFINED',
        );

        return orderStatusName;
      },
    },
    {
      name: i18next.t('GENERAL.TOTAL_EX_TAX'),
      propertyKey: 'orderTotalPrice',
      type: 'currency',
    },
  ];

  return columns;
};

const exportInvoiceControlListDetails = (dataToExport, { invoice, storeName, currency }) => {
  const fileName = i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_DETAIL_FILENAME', {
    date: moment(invoice.createdAt).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  });

  const gapsSheetColumns = getDetailedExportGapsSheetColumnsSettings();

  const sortedData = orderBy(dataToExport, 'totalGap', 'desc');

  const gapsSheet = utilsXLS.generateDefaultSheet(
    i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_DETAIL_GAPS_SHEET_NAME'),
    gapsSheetColumns,
    sortedData,
    currency,
  );

  const infoSheetColumns = getDetailedExportInfoSheetColumnsSettings(storeName);

  const infoDataToExport = [
    {
      supplierName: invoice.supplier.name,
      storeName: invoice.store.name,
      importedAt: moment(invoice.createdAt).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
      invoiceNumber: invoice.number,
      invoiceDate: invoice.date
        ? moment(invoice.date).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)
        : null,
      total: invoice.total,
    },
  ];

  const infoSheet = utilsXLS.generateDefaultSheet(
    i18next.t('GENERAL.INFORMATIONS'),
    infoSheetColumns,
    infoDataToExport,
    currency,
  );

  const associatedOrdersSheetColumns =
    getDetailedExportAssociatedOrdersSheetColumnsSettings(storeName);

  const associatedOrdersKeyByOrderId = keyBy(dataToExport, 'orderId');

  const associatedOrdersDataToExport = Object.values(associatedOrdersKeyByOrderId).map(
    ({
      invoiceNumber,
      orderSupplierName,
      orderStoreName,
      orderDeliveryDate,
      orderStatus,
      orderTotalPrice,
      orderReference,
    }) => ({
      invoiceNumber,
      orderSupplierName,
      orderStoreName,
      orderDeliveryDate,
      orderStatus,
      orderTotalPrice,
      orderReference,
    }),
  );

  const associatedOrdersSheet = utilsXLS.generateDefaultSheet(
    i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_DETAIL_ASSOCIATED_ORDERS_SHEET_NAME'),
    associatedOrdersSheetColumns,
    associatedOrdersDataToExport,
    currency,
  );

  utilsXLS.makeXLS(fileName, [gapsSheet, infoSheet, associatedOrdersSheet]);
};

export default { exportOcerization, exportInvoiceControlListDetails };
