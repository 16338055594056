import i18next from 'i18next';
import React from 'react';

import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';

import { FORM_INPUTS, SALES_INPUTS } from './formInputsConfiguration';

export const getTableFormColumnsByType = (currency = {}) => [
  {
    name: i18next.t('ADMIN.SALES.FORM_COLUMN_DATE'),
    width: INPUT_WIDTH.LARGE,
    input: FORM_INPUTS[SALES_INPUTS.NAME],
    isFixed: true,
    customRender: (item) => <div>{item.formattedDate}</div>,
  },

  {
    name: i18next.t('ADMIN.SALES.FORM_COLUMN_SALES', { currency: currency.alphabeticCode }),
    width: INPUT_WIDTH.LARGE,
    input: FORM_INPUTS[SALES_INPUTS.VALUE],
  },
];
