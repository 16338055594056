import Proptypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const ModalContainer = (props) => {
  const {
    component: Component,
    closeModal,
    deactivateBackgroundClick,
    fullscreen,
    ...rest
  } = props;

  return (
    <div className={rest.className}>
      <div
        className="modal-background"
        onClick={deactivateBackgroundClick ? () => false : closeModal}
      ></div>
      <div className={`modal-container ${fullscreen ? 'fullscreen' : ''}`}>
        <div className={`modal-main-container ${fullscreen ? 'fullscreen' : ''}`}>
          <Component closeModal={closeModal} {...rest} />
        </div>
      </div>
    </div>
  );
};

const StyledModalContainer = styled(ModalContainer)`
  .modal-background {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0px;

    background-color: ${(props) => props.theme.colors.greys.darkest};
    opacity: 64%;
    overflow: hidden;
    z-index: 5;
  }

  & .modal-container {
    height: 100%;
    width: 100%;

    padding: ${(props) => (props.fullscreen ? '0px' : '48px')};

    position: absolute;
    z-index: 10;
    ${(props) =>
      props.fullscreen &&
      css`
        top: 0;
      `}
  }

  & .modal-main-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: ${(props) => props.theme.colors?.greys.lighter};
    border-radius: ${(props) => (props.fullscreen ? '0px' : '8px')};
  }
`;

StyledModalContainer.propTypes = {
  component: Proptypes.func.isRequired,
  closeModal: Proptypes.func.isRequired,
  deactivateBackgroundClick: Proptypes.bool,
  params: Proptypes.object.isRequired,
  fullscreen: Proptypes.bool,
};

StyledModalContainer.defaultProps = {
  deactivateBackgroundClick: false,
  fullscreen: false,
};

export default StyledModalContainer;
