import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: white;
  box-sizing: border-box;
  border-radius: 8px;
  border: ${(props) => props.theme.toggleButton?.border || '1px solid #ececec'};
`;

export const ToggleSection = styled.div`
  width: 120px;
  height: 40px;
  padding: 0 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.theme.toggleButton?.backgroundColor || '#ececec'};

  > span {
    color: ${(props) =>
      props.isDisabled ? 'white' : props.theme.colors?.greys.dark || '#979797'};

    font-family: ${(props) => props.theme.fonts?.families.secondary || 'inter'};
    font-size: 12px;
    line-height: 14.5px;
    font-weight: normal;
  }

  &.active {
    background: ${(props) => props.theme.colors?.greys.lightest || '#FFFFFF'};
    border: 1px solid ${(props) => props.theme.colors?.black || '#000000'};

    > span {
      color: ${(props) => props.theme.colors?.greys.darkest || '#1D1D1B'};
    }
  }

  &:hover {
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  }

  &:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;
