import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  overflow: hidden;

  background-color: white;
`;

export const ContainerContent = styled.div`
  background: ${(props) => props.theme.colors.greys.lighter};

  width: 100%;
  position: relative;
  height: calc(100% - 40px);

  overflow: scroll;

  background: ${(props) => props.theme.colors.greys.lighter};

  .planning-analytics-corner-ref {
    height: 48px;

    margin-top: 3px;
    padding-top: 0px;
  }

  .planning-analytics-selectors {
    padding-top: 65px;
  }
`;

export const LabelContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Label = styled.div`
  width: fit-content;
  height: 20px;

  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  color: #000000;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${(props) =>
    props.red &&
    css`
      color: #ef252a;
      font-weight: 700;
    `}

  ${(props) =>
    props.orange &&
    css`
      color: #ff9c40;
      font-weight: 700;
    `}
`;
