import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import {
  closeGenericModal,
  openGenericModal,
  openSmallModal,
  refreshGenericModal,
} from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getTheme } from '@commons/utils/theme';
import DeepsightFiltersButton from '@admin/components/FilterButton';
import EmptyState from '@commons/EmptyState';
import GeneralEmptyStateListView from '@commons/GeneralEmptyStateListView';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { supplier as supplierService } from '@services/supplier';
import invoiceService from '@services/invoice';

import { getClientInfo } from '@selectors/client';

import { exportInvoiceControlLists, exportInvoiceOcerizationData } from './utils/exportUtils';
import { getDeleteWarningModalParams } from './utils/modals';
import { getListActions, getRowActions } from './utils/actions';
import { getListColumns } from './utils/columns';
import { INVOICE_STATUS } from './utils/constants';
import invoiceDetailUtilsExport from '../invoiceControlDetails/utils/exports';

import InvoiceControlListsAnalyseAndExportModal from './components/invoiceControlListsAnalyseAndExportModal';
import InvoiceControlListsOcerizationExportModal from './components/invoiceControlListsOcerizationExportModal';

import {
  Container,
  ContentContainer,
  CreditInfoContainer,
  FilterButtonContainer,
  InvoiceControlInProgressContainer,
  LoaderContainer,
} from './styledComponents';

import resetOrderInvoiceAssociationModal from './components/resetOrderInvoiceAssociationModal';

const theme = getTheme();

const INVOICE_MODAL_STEPS = {
  IMPORT_STEP: 0,
  CONTROL_STEP: 1,
  RESET_STEP: 2,
};

const INTERVAL_CHECK_NEWLY_AVAILABLE_INVOICE_IN_MS = 30 * 1000; // 30 seconds

const InvoiceControls = (props) => {
  const {
    match: { path },
    // redux states
    client: { clientId, storeName },
    currency,
    history,
    user,
    // dispatch methods
    openGenericModal,
    showErrorMessage,
    showSuccessMessage,
    openModalExportInfo,
    stores,
    refreshGenericModal,
    closeGenericModal,
    modal,
    pageLoading,
    pageLoaded,
  } = props;

  const clientStoreName = getClientStoreNameTranslation(storeName);

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  const [columns] = useState(getListColumns(userLanguageCode, storeName));

  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [availableCredits, setAvailableCredits] = useState({
    remaining: 0,
    invoiceVolume: 0,
  });
  const [isLoadingCredits, setIsLoadingCredits] = useState(true);

  const [invoiceControls, setInvoiceControls] = useState([]);
  const [selectedInvoiceControls, setSelectedInvoiceControls] = useState([]);
  const [filteredInvoiceControls, setFilteredInvoiceControls] = useState([]);
  const [displayEmptyStates, setDisplayEmptyStates] = useState(false);

  // Check asynchronous invoice controls
  const [pendingInvoiceControlsCount, setPendingInvoiceControlsCount] = useState(0);

  // Modal states
  const [invoiceControlModalStep, setInvoiceControlModalStep] = useState(-1);
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [editingInvoice, setEditingInvoice] = useState(null);
  const [importNewSelectedSupplier, setImportNewSelectedSupplier] = useState({});
  const [importNewSelectedStore, setImportNewSelectedStore] = useState({});

  // Filter states
  const [filters, setFilters] = useState(null);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  /** USE EFFECTS */
  useEffect(() => {
    getSuppliers();
  }, []);

  useEffect(() => {
    if (!pendingInvoiceControlsCount) {
      return;
    }

    const intervalSync = setInterval(
      checkForNewlyAvailableInvoices,
      INTERVAL_CHECK_NEWLY_AVAILABLE_INVOICE_IN_MS,
    );

    return () => clearInterval(intervalSync);
  }, [pendingInvoiceControlsCount]);

  useEffect(() => {
    getAvailableCredits(clientId);
  }, [clientId]);

  useEffect(() => {
    if (!selectedSuppliers.length) {
      return;
    }

    getInvoiceControls();
  }, [selectedSuppliers]);

  useEffect(() => {
    const iclsToExport = selectedInvoiceControls.length
      ? selectedInvoiceControls
      : filteredInvoiceControls;

    const hideExportAnalysis = iclsToExport.some(({ orders }) => !orders.length);

    setActions(
      getListActions({
        handleDeleteById,
        hideExportAnalysis,
        goToBatchImport,
        handleResetOrderInvoiceAssociation,
        selectedItems: selectedInvoiceControls,
        handleExport: handleExportInvoiceControl,
        handleOcerizationExport: handleICLsOcerizationExport,
        handleICLsExportAnalysisByICLIds: handleICLsExportAnalysisByICLIds,
      }),
    );

    setRowActions(
      getRowActions({
        handleDeleteById,
        handleOpenInvoice,
        handleExportAnalysisById,
        handleInvoiceOcerizationExport,
        handleResetOrderInvoiceAssociation,
      }),
    );
  }, [isLoadingCredits, filteredInvoiceControls, selectedInvoiceControls]);

  useEffect(() => {
    if (!applyFilters) {
      return;
    }

    const filteredInvoices = invoiceControls.filter((invoiceList) =>
      selectedSuppliers.some(
        ({ name }) => name.toUpperCase() === get(invoiceList, 'supplier.name', '').toUpperCase(),
      ),
    );

    if (!advancedFilters || !advancedFilters.length) {
      setFilteredInvoiceControls(filteredInvoices);
      return;
    }

    const filteredInvoicesWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, propertyKey, value }) => doFilter(result, propertyKey, value),
      filteredInvoices,
    );

    setFilteredInvoiceControls(filteredInvoicesWithAdvancedFilters);
  }, [invoiceControls, applyFilters, advancedFilters]);

  useEffect(() => {
    const isModalOpened = get(modal, 'GenericModalBool', false);

    if (!isModalOpened) {
      return;
    }

    handleModalContentUpdate();
  }, [
    invoiceFile,
    editingInvoice,
    importNewSelectedStore,
    invoiceControlModalStep,
    importNewSelectedSupplier,
  ]);

  /** FUNCTIONS */
  const getSuppliers = async () => {
    setIsLoading(true);
    try {
      const suppliersOfCLient = await supplierService.getSuppliersOfClient(clientId, false);

      setSelectedSuppliers(suppliersOfCLient);
      setSuppliers(suppliersOfCLient);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIERS.FETCH_FAILURE'));
    }
  };

  const getAvailableCredits = async () => {
    setIsLoadingCredits(true);

    try {
      const result = await invoiceService.getAvailableCredits(clientId);

      setAvailableCredits({ remaining: result.remaining, invoiceVolume: result.invoiceVolume });
    } catch {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROLS.FETCH_AVAILABLE_CREDITS_ERROR'));
    } finally {
      setIsLoadingCredits(false);
    }
  };

  const getInvoiceControls = async () => {
    setIsLoading(true);

    try {
      const supplierIds = selectedSuppliers.map(({ id }) => id);
      // [TODO Invoice Control] Filter by user master catalog

      const fetchedInvoiceControls = await invoiceService.getInvoiceControlList(
        clientId,
        supplierIds,
      );

      setDisplayEmptyStates(!fetchedInvoiceControls.length);

      if (!fetchedInvoiceControls.length) {
        return;
      }

      // Make distinction between invoiceList being treated and those available
      const { availableInvoiceControls, pendingInvoiceControls } = fetchedInvoiceControls.reduce(
        (result, item) => {
          if ([INVOICE_STATUS.SUCCESS, INVOICE_STATUS.FAILURE].includes(item.status)) {
            result.availableInvoiceControls.push(item);
          }

          if (item.status === INVOICE_STATUS.PENDING) {
            result.pendingInvoiceControls.push(item);
          }

          return result;
        },
        { availableInvoiceControls: [], pendingInvoiceControls: [] },
      );

      // Handle available invoices
      const formattedInvoiceControls = availableInvoiceControls.map((invoice) => ({
        ...invoice,
        computedOrderReferences: invoice.orders.map(({ reference }) => reference).join(';'), // to make search on this field possible
      }));

      setInvoiceControls(formattedInvoiceControls);
      setPendingInvoiceControlsCount(pendingInvoiceControls.length);
    } catch {
      setInvoiceControls([]);

      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_FETCH_ERROR'));
    } finally {
      setIsLoading(false);
    }
  };

  const checkForNewlyAvailableInvoices = async () => {
    try {
      // [TODO Invoice Control] Filter by user master catalog
      const supplierIds = suppliers.map(({ id }) => id);

      const result = await invoiceService.getInvoiceControlList(clientId, supplierIds);

      const pendingInvoiceControls = result.filter(
        ({ status }) => status === INVOICE_STATUS.PENDING,
      );

      if (pendingInvoiceControls.length !== pendingInvoiceControlsCount) {
        await getInvoiceControls();
      }
    } catch (err) {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_FETCH_ERROR'));
    }
  };

  const deleteInvoiceControlListById = async (invoiceControlListById) => {
    pageLoading();

    try {
      await invoiceService.deleteById(invoiceControlListById);

      getInvoiceControls();

      // Refresh available credits
      getAvailableCredits(clientId);
    } catch (err) {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_DELETE_INVOICE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleExportInvoiceControl = () => {
    const invoiceControlsToExport = selectedInvoiceControls.length
      ? selectedInvoiceControls
      : filteredInvoiceControls;

    exportInvoiceControlLists(invoiceControlsToExport, userLanguageCode, currency, storeName);
  };

  // Can be multiple export in global actions
  const handleICLsOcerizationExport = () => {
    const iclToOcerizarionExports = selectedInvoiceControls.length
      ? selectedInvoiceControls
      : filteredInvoiceControls;

    const invoiceControlListIds = iclToOcerizarionExports.map(({ id }) => id);

    openModalExportInfo({
      component: InvoiceControlListsOcerizationExportModal,
      invoiceControlListIds,
      currency,
      storeName,
    });
  };

  const handleICLsExportAnalysisByICLIds = () => {
    const iclsToExport = selectedInvoiceControls.length
      ? selectedInvoiceControls
      : filteredInvoiceControls;

    const invoiceControlListIds = iclsToExport.map(({ id }) => id);

    openModalExportInfo({
      component: InvoiceControlListsAnalyseAndExportModal,
      invoiceControlListIds,
      currency,
      storeName,
    });
  };

  // Unique export in row actions
  const handleInvoiceOcerizationExport = async (invoiceControlListId) => {
    try {
      const invoice = await invoiceService.getById(invoiceControlListId);

      const fetchedICLOcerizationData = await invoiceService.getOcerizationById(
        invoiceControlListId,
      );

      exportInvoiceOcerizationData(invoice, fetchedICLOcerizationData, storeName, currency);
    } catch {
      showErrorMessage(
        i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_OCERIZATION_ERROR'),
      );
    }
  };

  const handleExportAnalysisById = async (invoiceControlListId) => {
    try {
      pageLoading();
      const invoice = await invoiceService.getById(invoiceControlListId);

      const dataToExport = await invoiceService.analyseAndExportInvoiceControlListDetails(
        invoiceControlListId,
      );

      invoiceDetailUtilsExport.exportInvoiceControlListDetails(dataToExport, {
        invoice,
        storeName,
        currency,
      });
    } catch {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ACTION_EXPORT_DETAIL_FAILURE'));
    } finally {
      pageLoaded();
    }
  };

  const handleOpenInvoice = async (invoiceControlList) => {
    if (!invoiceControlList.link) {
      return;
    }

    window.open(invoiceControlList.link);
  };

  const handleDeleteById = (invoiceControlListId) => {
    const params = getDeleteWarningModalParams({
      invoiceControlListId,
      deleteById: deleteInvoiceControlListById,
    });

    openGenericModal(params);
  };

  const resetOrderInvoiceAssociation = async (invoiceControlListId) => {
    setInvoiceControlModalStep(INVOICE_MODAL_STEPS.CONTROL_STEP);

    try {
      await invoiceService.resetOrderInvoiceAssociation(
        invoiceControlListId,
        importNewSelectedStore.id,
        importNewSelectedSupplier.id,
      );

      getInvoiceControls();

      showSuccessMessage(
        i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_RELAUNCH_ORDER_INVOICE_ASSOCIATION_SUCCESS'),
      );
    } catch {
      showErrorMessage(
        i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_RELAUNCH_ORDER_INVOICE_ASSOCIATION_ERROR'),
      );
    } finally {
      handleCloseCleanUp();
    }
  };

  const handleResetOrderInvoiceAssociation = (invoice) => {
    setEditingInvoice(invoice);

    const selectedStore = stores.find(({ id }) => invoice.storeId === id);

    setImportNewSelectedStore(selectedStore);

    const selectedSupplier = suppliers.find(({ id }) => invoice.supplierId === id);

    setImportNewSelectedSupplier(selectedSupplier);

    setInvoiceControlModalStep(INVOICE_MODAL_STEPS.RESET_STEP);

    const params = resetOrderInvoiceAssociationModal.getModalConfig({
      stores,
      invoice,
      suppliers,
      selectedStore,
      selectedSupplier,
      clientStoreName,
      setSelectedStore: setImportNewSelectedStore,
      setSelectedSupplier: setImportNewSelectedSupplier,
      callback: resetOrderInvoiceAssociation,
      handleCloseCleanUp,
    });

    openGenericModal(params);
  };

  const goToDetailsPage = (invoiceControlList) => {
    history.push(`/invoice/invoice-controls/${invoiceControlList.id}/details`);
  };

  const goToBatchImport = () => {
    history.push('/invoice/invoice-controls/batch-import');
  };

  const handleModalContentUpdate = () => {
    let params = {};

    switch (invoiceControlModalStep) {
      case INVOICE_MODAL_STEPS.RESET_STEP:
        params = resetOrderInvoiceAssociationModal.getModalConfig({
          stores,
          suppliers,
          clientStoreName,
          invoice: editingInvoice,
          selectedStore: importNewSelectedStore,
          selectedSupplier: importNewSelectedSupplier,
          setSelectedStore: setImportNewSelectedStore,
          setSelectedSupplier: setImportNewSelectedSupplier,
          callback: resetOrderInvoiceAssociation,
          handleCloseCleanUp,
        });
        break;
      default:
        return;
    }

    refreshGenericModal(params);
  };

  const handleCloseCleanUp = () => {
    setImportNewSelectedSupplier({});
    setImportNewSelectedStore({});
    setEditingInvoice(null);
    setInvoiceFile(null);

    closeGenericModal();
    setInvoiceControlModalStep(-1);
  };

  if (displayEmptyStates) {
    return (
      <Container>
        <NavigationBreadCrumb featurePath={path} />
        <ContentContainer>
          <GeneralEmptyStateListView
            icon={'/images/inpulse/catalogs.svg'}
            renderAction={() => (
              <Button
                color="inpulse-default"
                handleClick={goToBatchImport}
                icon={'/images/inpulse/add-white-small.svg'}
                label={i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_ADD_NEW_CONTROL')}
              />
            )}
            subtitle={i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_EMPTY_STATE_SUBTITLE')}
            title={i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_EMPTY_STATE_TITLE')}
          />
        </ContentContainer>
      </Container>
    );
  }

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ContentContainer>
        <ListView
          actionOnClick={goToDetailsPage}
          actions={actions}
          columns={columns}
          data={filteredInvoiceControls}
          defaultOrderBy={'createdAt'}
          defaultOrderType={'desc'}
          isLoading={isLoading}
          markerConfiguration={{
            isHidden: ({ status }) => status === INVOICE_STATUS.SUCCESS,
            backgroundColor: theme.colors.infoOrange,
            icon: { src: '/images/inpulse/warning-white-small.svg' },
          }}
          minActionsInActionsDropdown={1}
          padding={'24px 24px 0px 24px'}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => <EmptyState />}
          renderFilterButton={() => (
            <FilterButtonContainer>
              <DeepsightFiltersButton
                advancedFilters={advancedFilters}
                applyFilters={applyFilters}
                columnsFilterList={columns.filter(({ filterType }) => !!filterType)}
                filters={filters}
                isLoading={isLoading}
                readOnly={isLoading}
                selectedSuppliers={selectedSuppliers}
                setAdvancedFilters={setAdvancedFilters}
                setApplyFilters={setApplyFilters}
                setFilters={setFilters}
                setSelectedSuppliers={setSelectedSuppliers}
                suppliers={suppliers}
                textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
              />
              <CreditInfoContainer>
                <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_SMALL}>
                  {i18next.t('INVOICE.INVOICE_CONTROLS.HEADER_CREDITS_LEFT')}
                </Text>
                {isLoadingCredits ? (
                  <LoaderContainer>
                    <DeepsightComponentLoader height={16} width={16} />
                  </LoaderContainer>
                ) : (
                  <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG_HEIGHT_24_BOLD}>
                    {i18next.t('INVOICE.INVOICE_CONTROLS.HEADER_CREDITS_USAGE_INFO', {
                      allowed: availableCredits.invoiceVolume,
                      remaining: availableCredits.remaining,
                    })}
                  </Text>
                )}
              </CreditInfoContainer>
              <CreditInfoContainer>
                <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_SMALL}>
                  {i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROL_IN_PROGRESS', {
                    count: pendingInvoiceControlsCount,
                  })}
                </Text>
                {pendingInvoiceControlsCount > 0 ? (
                  <InvoiceControlInProgressContainer>
                    <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG_HEIGHT_24_BOLD}>
                      {pendingInvoiceControlsCount}
                    </Text>
                    <LoaderContainer>
                      <DeepsightComponentLoader height={16} width={16} />
                    </LoaderContainer>
                  </InvoiceControlInProgressContainer>
                ) : (
                  <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG_HEIGHT_24_BOLD}>
                    {i18next.t('GENERAL.SELECT_NONE_MASCULINE')}
                  </Text>
                )}
              </CreditInfoContainer>
            </FilterButtonContainer>
          )}
          rowActions={rowActions}
          setSelectedItems={setSelectedInvoiceControls}
        />
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
  stores: state.baseReducer.activeStores,
  modal: state.modalReducer,
});

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceControls);
