import React, { createContext, useEffect, useState } from 'react';

import { getListViewState } from './utils';

export const IngredientContext = createContext({});

export const IngredientProvider = ({ children }) => {
  const listViewStates = getListViewState();

  // Applied filters
  const [filters, setFilters] = useState(null);
  const [advancedFilters, setAdvancedFilters] = useState(null);

  useEffect(() => {
    listViewStates.setCurrentPage(1);
  }, [filters, advancedFilters]);

  // Advanced filters

  return (
    <IngredientContext.Provider
      value={{
        ...listViewStates,
        filters,
        setFilters,
        advancedFilters,
        setAdvancedFilters,
      }}
    >
      {children}
    </IngredientContext.Provider>
  );
};
