import inventoryService from '../services';

export const getInventoryLastUpdatedAt = async (selectedStoreId, inventoryListId) => {
  const { updatedAt } = await inventoryService.getInventoriesFromInventoriesListOfStore(
    selectedStoreId,
    inventoryListId,
  );

  return updatedAt;
};
