import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: inherit;
  ${(props) =>
    props.theme.borders ? `border-radius: ${props.theme.borders.radius.strong};` : undefined}
`;

export const Content = styled.div`
  width: 100%;
  padding: 0px;
  overflow: scroll;
  position: relative;
  height: calc(100% - 148px);
`;

export const TopContainer = styled.div`
  width: 100%;
  height: 75px;
  padding: 0px 35px;
  ${(props) =>
    props.theme.borders
      ? `border-radius: ${props.theme.borders.radius.strong} ${props.theme.borders.radius.strong} 0px 0px;`
      : undefined}
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};

  color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};
  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts.h2};`
      : `font-size: 18px;
  line-height: 22px;
  font-weight: normal;`}
`;

export const BottomContainer = styled.div`
  width: 100%;
  height: 88px;
  padding: 24px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: ${(props) => props.theme.colors?.greys.lightest || undefined};
  ${(props) =>
    props.theme.borders
      ? `border-radius: 0px 0px ${props.theme.borders.radius.weak} ${props.theme.borders.radius.weak};`
      : undefined}
  bottom: 0px;
  position: absolute;
  box-shadow: inset 0px 2px 0px ${(props) => props.theme.colors.greys.lighter};
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};
  font: ${(props) => props.theme.fonts?.textBig} !important;
`;
