import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px 0;
`;

export const ContainerCheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ContainerHeader = styled.div`
  margin: 24px 0;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  > div {
    width: auto !important;
    margin-right: 24px;
  }
`;
