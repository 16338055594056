import i18next from 'i18next';
import React from 'react';

import {
  DropdownItemSecondaryValue,
  Image,
  ItemNameContainer,
  Name,
  TextContainer,
} from '@commons/TableForm/TableFormInModal/styledComponents';
import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';

import { LOSS_TYPE, LOSS_TYPE_ITEM_PLACEHOLDER } from '../../LossListView/constants';

import { FORM_INPUTS, LOSS_INPUTS } from './formInputsConfiguration';

const renderNameColumn = (item, itemPlaceholder, lossType) => (
  <ItemNameContainer>
    <Image src={item.img ? item.img : itemPlaceholder}></Image>
    <TextContainer>
      <Name>{item.name}</Name>
      {lossType === LOSS_TYPE.SUPPLIER_PRODUCT && (
        <DropdownItemSecondaryValue>{item.sku}</DropdownItemSecondaryValue>
      )}
    </TextContainer>
  </ItemNameContainer>
);

export const getTableFormColumnsByType = (lossType) => {
  switch (lossType) {
    case LOSS_TYPE.PRODUCT:
      return [
        {
          name: i18next.t('GENERAL.NAME'),
          width: INPUT_WIDTH.LARGE,
          input: FORM_INPUTS[LOSS_INPUTS.NAME],
          isFixed: true,
          customRender: (item) =>
            renderNameColumn(item, LOSS_TYPE_ITEM_PLACEHOLDER[lossType], lossType),
        },
        {
          name: i18next.t('GENERAL.CHANNEL'),
          width: INPUT_WIDTH.MEDIUM,
          input: FORM_INPUTS[LOSS_INPUTS.CHANNEL],
          isRequired: true,
        },
        {
          name: i18next.t('GENERAL.COST_EX_TAX'),
          width: INPUT_WIDTH.SMALL,
          input: FORM_INPUTS[LOSS_INPUTS.COST],
        },
        {
          name: i18next.t('GENERAL.LOSSES'),
          width: INPUT_WIDTH.MEDIUM,
          input: FORM_INPUTS[LOSS_INPUTS.LOSSES],
          isRequired: true,
        },
        {
          name: i18next.t('GENERAL.CATEGORY'),
          width: INPUT_WIDTH.MEDIUM,
          input: FORM_INPUTS[LOSS_INPUTS.CATEGORY],
          isRequired: true,
        },
        {
          name: i18next.t('GENERAL.TOTAL_EX_TAX'),
          width: INPUT_WIDTH.MEDIUM,
          input: FORM_INPUTS[LOSS_INPUTS.TOTAL],
        },
      ];

    case LOSS_TYPE.SUPPLIER_PRODUCT:
      return [
        {
          name: i18next.t('GENERAL.NAME'),
          width: INPUT_WIDTH.LARGE,
          input: FORM_INPUTS[LOSS_INPUTS.NAME],
          isFixed: true,
          customRender: (item) =>
            renderNameColumn(item, LOSS_TYPE_ITEM_PLACEHOLDER[lossType], lossType),
        },
        {
          name: i18next.t('GENERAL.SUPPLIER'),
          width: INPUT_WIDTH.MEDIUM,
          input: FORM_INPUTS[LOSS_INPUTS.SUPPLIER_NAME],
        },
        ,
        {
          name: i18next.t('GENERAL.PACKAGING'),
          width: INPUT_WIDTH.MEDIUM,
          input: FORM_INPUTS[LOSS_INPUTS.PACKAGING],
        },
        {
          name: i18next.t('GENERAL.PRICE_EX_TAX'),
          width: INPUT_WIDTH.SMALL,
          input: FORM_INPUTS[LOSS_INPUTS.PRICE],
        },
        {
          name: i18next.t('GENERAL.LOSSES'),
          width: INPUT_WIDTH.MEDIUM,
          input: FORM_INPUTS[LOSS_INPUTS.LOSSES],
          isRequired: true,
        },
        {
          name: i18next.t('GENERAL.CATEGORY'),
          width: INPUT_WIDTH.MEDIUM,
          input: FORM_INPUTS[LOSS_INPUTS.CATEGORY],
        },
        {
          name: i18next.t('GENERAL.TOTAL_EX_TAX'),
          width: INPUT_WIDTH.MEDIUM,
          input: FORM_INPUTS[LOSS_INPUTS.TOTAL],
        },
      ];

    case LOSS_TYPE.RECIPE:
      return [
        {
          name: i18next.t('GENERAL.NAME'),
          width: INPUT_WIDTH.LARGE,
          input: FORM_INPUTS[LOSS_INPUTS.NAME],
          isFixed: true,
          customRender: (item) =>
            renderNameColumn(item, LOSS_TYPE_ITEM_PLACEHOLDER[lossType], lossType),
        },
        {
          name: i18next.t('GENERAL.COST_EX_TAX'),
          width: INPUT_WIDTH.SMALL,
          input: FORM_INPUTS[LOSS_INPUTS.COST],
        },
        {
          name: i18next.t('GENERAL.LOSSES'),
          width: INPUT_WIDTH.MEDIUM,
          input: FORM_INPUTS[LOSS_INPUTS.LOSSES],
          isRequired: true,
        },
        {
          name: i18next.t('GENERAL.UNIT'),
          width: INPUT_WIDTH.SMALL,
          input: FORM_INPUTS[LOSS_INPUTS.UNIT_VALUE],
        },
        {
          name: i18next.t('GENERAL.CATEGORY'),
          width: INPUT_WIDTH.MEDIUM,
          input: FORM_INPUTS[LOSS_INPUTS.CATEGORY],
        },
        {
          name: i18next.t('GENERAL.TOTAL_EX_TAX'),
          width: INPUT_WIDTH.MEDIUM,
          input: FORM_INPUTS[LOSS_INPUTS.TOTAL],
        },
      ];

    default:
      return;
  }
};
