import { getActionAuthorization } from '../commons/utils';

const LOSS_PRODUCT_ACTIONS_RESOURCE = {
  CREATE: 'loss:product:create',
  DELETE: 'loss:product:delete',
  CORRECT: 'loss:product:correct',
};

const LOSS_SUPPLIER_PRODUCT_ACTIONS_RESOURCE = {
  CREATE: 'loss:supplier-product:create',
  DELETE: 'loss:supplier-product:delete',
  CORRECT: 'loss:supplier-product:correct',
};

const CENTRAL_KITCHEN_LOSS_ACTIONS_RESOURCE = {
  CREATE: 'central-kitchen:loss:create',
  DELETE: 'central-kitchen:loss:delete',
  CORRECT: 'central-kitchen:loss:correct',
};

export const canCreateProductLoss = (actions) =>
  getActionAuthorization(actions, LOSS_PRODUCT_ACTIONS_RESOURCE.CREATE);

export const canDeleteProductLoss = (actions) =>
  getActionAuthorization(actions, LOSS_PRODUCT_ACTIONS_RESOURCE.DELETE);

export const canCorrectProductLoss = (actions) =>
  getActionAuthorization(actions, LOSS_PRODUCT_ACTIONS_RESOURCE.CORRECT);

export const canCreateCentralKitchenLoss = (actions) =>
  getActionAuthorization(actions, CENTRAL_KITCHEN_LOSS_ACTIONS_RESOURCE.CREATE);

export const canDeleteCentralKitchenLoss = (actions) =>
  getActionAuthorization(actions, CENTRAL_KITCHEN_LOSS_ACTIONS_RESOURCE.DELETE);

export const canCorrectCentralKitchenLoss = (actions) =>
  getActionAuthorization(actions, CENTRAL_KITCHEN_LOSS_ACTIONS_RESOURCE.CORRECT);

export const canCreateSupplierProductLoss = (actions) =>
  getActionAuthorization(actions, LOSS_SUPPLIER_PRODUCT_ACTIONS_RESOURCE.CREATE);

export const canDeleteSupplierProductLoss = (actions) =>
  getActionAuthorization(actions, LOSS_SUPPLIER_PRODUCT_ACTIONS_RESOURCE.DELETE);

export const canCorrectSupplierProductLoss = (actions) =>
  getActionAuthorization(actions, LOSS_SUPPLIER_PRODUCT_ACTIONS_RESOURCE.CORRECT);
