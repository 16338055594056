import styled from 'styled-components';

export const FiltersContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;

  gap: 24px;
`;

export const IconActive = styled.img`
  width: 14px;
  height: 14px;

  position: absolute;
  top: -5px;
  left: 233px;
`;

export const LittleBoxContainer = styled.div`
  color: ${(props) => props.theme.colors?.greys?.darker};
  font: ${(props) => props.theme.fonts.textSmall};

  > .kpi {
    color: ${(props) => props.theme.colors?.greys?.darkest};
    font: ${(props) => props.theme.fonts?.kpiSmall};
  }
`;

export const InfoBoxLabel = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Filter = styled.div`
  flex: 1;
  max-width: 280px;

  margin-right: 30px;

  display: flex;
  align-items: center;

  &:last-child {
    margin-right: 0px;
  }

  .deepsight-button.locked {
    background-color: lightgray;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: end;
`;
