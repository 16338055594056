import { get, isArray } from 'lodash';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { Dropdown, Label, ToggleSwitch } from '@commons/utils/styledLibraryComponents';
import { getTheme } from '@commons/utils/theme';

import { Container, Header, Content, ToggleContainer } from './styledComponents';

export const AppliedFiltersBlock = (props) => {
  const {
    filters,
    setFilters,
    filterNamesCustomMultipleDropDowns,
    readOnly,
    filterNamesCustomSingleDropDowns,
    filterNamesCustomToggles,
  } = props;

  const theme = getTheme();

  const [isCatalogDropdownDisabled, setIsCatalogDropdownDisabled] = useState(readOnly);

  const formatItemsForDropdown = (key, itemsKey) => {
    const itemsList = get(filters[key], itemsKey, []);

    if (isArray(itemsList)) {
      return itemsList;
    }

    return itemsList.name ? [itemsList] : Object.values(itemsList);
  };

  const handleDualDropdownChanges = (props, key, oldSelectedItems, newSelectedItems) => {
    if (isArray(oldSelectedItems)) {
      setFilters(props, key, newSelectedItems);

      return;
    }

    if (oldSelectedItems.name === newSelectedItems.name) {
      return;
    }

    setFilters(props, key, newSelectedItems);
  };

  const getIsCatalogDropdownDisabled = () => {
    const catalogsList = get(filters, 'catalogs.list', []);

    return readOnly || catalogsList.length === 1;
  };

  useEffect(() => {
    setIsCatalogDropdownDisabled(getIsCatalogDropdownDisabled());
  }, [filters.catalogs]);

  return (
    <Container>
      <Header>{`${i18next.t('GENERAL.ANALYTICS_GLOBAL_FILTERS')} :`}</Header>
      <Content>
        {filters.locations && (
          <Dropdown
            iconSrc={
              !filters.locations.selectedLocations.length
                ? readOnly
                  ? '/images/inpulse/location-white-small.svg'
                  : '/images/inpulse/location-dmgrey-small.svg'
                : '/images/inpulse/location-black-small.svg'
            }
            isDisabled={readOnly}
            isUniqueSelection={false}
            items={formatItemsForDropdown('locations', 'list')}
            placeholder={i18next.t('PRODUCTION.REAL_TIME.FILTER_HEADER_LOCATIONS_PLACEHOLDER')}
            searchPlaceholder={i18next.t('GENERAL.SEARCH')}
            selectedItems={formatItemsForDropdown('locations', 'selectedLocations')}
            onSelectionChange={(selectedItems) =>
              setFilters('locations', 'selectedLocations', selectedItems)
            }
          />
        )}
        {filters.brands && (
          <Dropdown
            iconSrc={
              !filters.brands.selectedBrands.length
                ? readOnly
                  ? '/images/inpulse/brand-white-small.svg'
                  : '/images/inpulse/brand-dmgrey-small.svg'
                : '/images/inpulse/brand-black-small.svg'
            }
            isDisabled={readOnly}
            isUniqueSelection={false}
            items={formatItemsForDropdown('brands', 'list')}
            placeholder={i18next.t('PRODUCTION.REAL_TIME.FILTER_HEADER_BRANDS_PLACEHOLDER')}
            searchPlaceholder={i18next.t('GENERAL.SEARCH')}
            selectedItems={formatItemsForDropdown('brands', 'selectedBrands')}
            onSelectionChange={(selectedItems) =>
              setFilters('brands', 'selectedBrands', selectedItems)
            }
          />
        )}
        {filters.retailers && (
          <Dropdown
            iconSrc={
              !filters.retailers.selectedRetailers.length
                ? readOnly
                  ? '/images/inpulse/retailer-white-small.svg'
                  : '/images/inpulse/retailer-dmgrey-small.svg'
                : '/images/inpulse/retailer-black-small.svg'
            }
            isDisabled={readOnly}
            isUniqueSelection={false}
            items={formatItemsForDropdown('retailers', 'list')}
            placeholder={i18next.t('PRODUCTION.REAL_TIME.FILTER_HEADER_RETAILERS_PLACEHOLDER')}
            searchPlaceholder={i18next.t('GENERAL.SEARCH')}
            selectedItems={formatItemsForDropdown('retailers', 'selectedRetailers')}
            onSelectionChange={(selectedItems) =>
              setFilters(
                'retailers',
                'selectedRetailers',
                selectedItems.map((item) => ({
                  ...item,
                  id: item.id === -1 ? null : item.id,
                  value: item.name,
                })),
              )
            }
          />
        )}
        {filters.groups && (
          <Dropdown
            iconSrc={
              !filters.groups.selectedGroups.length
                ? readOnly
                  ? '/images/inpulse/group-white-small.svg'
                  : '/images/inpulse/group-dmgrey-small.svg'
                : '/images/inpulse/group-black-small.svg'
            }
            isDisabled={readOnly}
            isUniqueSelection={false}
            items={formatItemsForDropdown('groups', 'list')}
            placeholder={i18next.t('PRODUCTION.REAL_TIME.FILTER_HEADER_GROUPS_PLACEHOLDER')}
            searchPlaceholder={i18next.t('GENERAL.SEARCH')}
            selectedItems={formatItemsForDropdown('groups', 'selectedGroups')}
            onSelectionChange={(selectedItems) =>
              setFilters(
                'groups',
                'selectedGroups',
                selectedItems.map((item) => ({
                  ...item,
                  id: item.id === -1 ? null : item.id,
                  value: item.name,
                })),
              )
            }
          />
        )}
        {filters.stores && (filters.stores.selectedStores || filters.stores.selectedStore) && (
          <Dropdown
            iconSrc={
              readOnly
                ? '/images/inpulse/pin-white-small.svg'
                : filters.stores.selectedStore || filters.stores.selectedStores.length
                ? '/images/inpulse/pin-black-small.svg'
                : '/images/inpulse/pin-dmgrey-small.svg'
            }
            isDisabled={readOnly}
            isUniqueSelection={!!filters.stores.selectedStore}
            items={formatItemsForDropdown('stores', 'activeList')}
            searchPlaceholder={i18next.t('GENERAL.SEARCH')}
            selectedItems={formatItemsForDropdown(
              'stores',
              get(filters.stores, 'selectedStore') ? 'selectedStore' : 'selectedStores',
            )}
            isRequired
            onSelectionChange={(selectedItems) =>
              get(filters.stores, 'selectedStore')
                ? handleDualDropdownChanges(
                    'stores',
                    'selectedStore',
                    get(filters.stores, 'selectedStore'),
                    selectedItems,
                  )
                : handleDualDropdownChanges(
                    'stores',
                    'selectedStores',
                    get(filters.stores, 'selectedStores'),
                    selectedItems,
                  )
            }
          />
        )}
        {filters.catalogs && !!filters.catalogs.list.length && (
          <Dropdown
            iconSrc={
              isCatalogDropdownDisabled
                ? '/images/inpulse/menu-book-dmgrey-small.svg'
                : '/images/inpulse/menu-book-black-small.svg'
            }
            isDisabled={isCatalogDropdownDisabled}
            isUniqueSelection={false}
            items={formatItemsForDropdown('catalogs', 'list')}
            placeholder={i18next.t('PRODUCTION.REAL_TIME.FILTER_HEADER_BRANDS_PLACEHOLDER')}
            searchPlaceholder={i18next.t('GENERAL.SEARCH')}
            selectedItems={formatItemsForDropdown('catalogs', 'selectedCatalogs')}
            isRequired
            onSelectionChange={(selectedItems) =>
              setFilters('catalogs', 'selectedCatalogs', selectedItems)
            }
          />
        )}
        {filters.suppliers && filters.suppliers.list.length > 0 && (
          <Dropdown
            iconSrc={
              readOnly
                ? '/images/inpulse/app-white-small.svg'
                : filters.suppliers.selectedSupplier || filters.suppliers.selectedSuppliers.length
                ? '/images/inpulse/app-black-small.svg'
                : '/images/inpulse/app-dmgrey-small.svg'
            }
            isDisabled={readOnly}
            isUniqueSelection={!!filters.suppliers.selectedSupplier}
            items={formatItemsForDropdown('suppliers', 'activeList')}
            searchPlaceholder={i18next.t('GENERAL.SEARCH')}
            selectedItems={formatItemsForDropdown(
              'suppliers',
              get(filters.stores, 'selectedSupplier') ? 'selectedSupplier' : 'selectedSuppliers',
            )}
            isRequired
            onSelectionChange={(selectedItems) =>
              get(filters.suppliers, 'selectedSupplier')
                ? handleDualDropdownChanges(
                    'suppliers',
                    'selectedSupplier',
                    get(filters.suppliers, 'selectedSupplier'),
                    selectedItems,
                  )
                : handleDualDropdownChanges(
                    'suppliers',
                    'selectedSuppliers',
                    get(filters.suppliers, 'selectedSuppliers'),
                    selectedItems,
                  )
            }
          />
        )}
        {filterNamesCustomMultipleDropDowns &&
          filterNamesCustomMultipleDropDowns.map((filterName, indexCustomMultipleDropDown) => {
            if (!filters[filterName]) {
              return null;
            }

            const {
              list,
              icon,
              placeholder,
              selectedItems,
              reliedOnFilter,
              setSelectedItems,
              forceStateUpdateTrigger = false,
            } = filters[filterName];

            let disabledByDependency = false; // by default, a custom filter has no dependency.

            if (reliedOnFilter) {
              // check for dependency
              const { id, filterValueKey, reliedOnValue } = reliedOnFilter;
              disabledByDependency = get(filters[id], `${filterValueKey}`) === reliedOnValue;
            }

            return (
              <Dropdown
                iconSrc={icon}
                isDisabled={readOnly || !list.length || disabledByDependency}
                isUniqueSelection={false}
                items={list}
                key={indexCustomMultipleDropDown}
                placeholder={placeholder}
                searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                selectedItems={disabledByDependency ? [] : selectedItems}
                onSelectionChange={(selectedItems) => {
                  setFilters(filterName, 'selectedItems', selectedItems);

                  if (forceStateUpdateTrigger) {
                    setSelectedItems(selectedItems);
                  }
                }}
              />
            );
          })}

        {filterNamesCustomToggles &&
          filterNamesCustomToggles.map((filterName, indexCustomToggle) => {
            if (!filters[filterName]) {
              return null;
            }

            const { value, label } = filters[filterName];

            return (
              <ToggleContainer key={label}>
                <ToggleSwitch
                  checked={value}
                  handleClick={() => {
                    setFilters(filterName, 'value', !value);
                  }}
                  id={`${indexCustomToggle}`}
                  key={indexCustomToggle}
                />
                <Label background={theme.colors.greys.lightest} color={theme.colors.greys.darkest}>
                  {label}
                </Label>
              </ToggleContainer>
            );
          })}

        {filterNamesCustomSingleDropDowns &&
          filterNamesCustomSingleDropDowns.map((filterName, indexCustomSingleDropDown) => {
            if (!filters[filterName]) {
              return null;
            }

            const {
              list,
              selectedItem,
              icon,
              disabledIcon,
              itemSelectedIcon,
              placeholder,
              reliedOnFilter,
              isDisabled = false,
              isRequired = true,
            } = filters[filterName];

            let disabledByDependency = false; // by default, a custom filter has no dependency.

            if (reliedOnFilter) {
              // check for dependency
              const { id, filterValueKey, reliedOnValue } = reliedOnFilter;
              disabledByDependency = get(filters[id], `${filterValueKey}`) === reliedOnValue;
            }

            const disabled = readOnly || isDisabled || disabledByDependency;

            let iconSrc = icon;
            if (!!selectedItem) {
              iconSrc = itemSelectedIcon;
            }
            if (disabled) {
              iconSrc = disabledIcon;
            }

            return (
              <Dropdown
                iconSrc={iconSrc}
                isDisabled={disabled}
                isRequired={isRequired}
                isUniqueSelection={true}
                items={list}
                key={indexCustomSingleDropDown}
                placeholder={placeholder}
                searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                selectedItem={disabledByDependency ? null : selectedItem}
                onSelectionChange={(selectedItem) =>
                  setFilters(filterName, 'selectedItem', selectedItem)
                }
              />
            );
          })}
      </Content>
    </Container>
  );
};

AppliedFiltersBlock.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilter: PropTypes.func,
  readOnly: PropTypes.bool,
};

AppliedFiltersBlock.defaultProps = {
  readOnly: false,
};

export default AppliedFiltersBlock;
