import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

import {
  EmptyStateContainer,
  EmptyStateIcon,
  MainText,
  SubText,
  TextContainer,
} from './styledComponents';

const CashierProductAssociationEmptyState = (props) => {
  const { isReadOnly, setTrigger } = props;

  return (
    <EmptyStateContainer>
      <EmptyStateIcon
        alt="detail-product-synchronisation"
        src="/images/inpulse/detail-product-synchronisation.svg"
      />
      <TextContainer>
        <MainText>
          {i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATE_CASHIER_PRODUCTS_TO_SYNC_SALES')}
        </MainText>
        <SubText>
          {i18next.t('ADMIN.CASHIER_PRODUCTS.ASSOCIATE_CASHIER_PRODUCTS_TO_SYNC_SALES_SUB_TEXT')}
        </SubText>
        {!isReadOnly && (
          <Button
            color={'inpulse-default'}
            handleClick={() => setTrigger(true)}
            icon={'/images/inpulse/link-white-small.svg'}
            label={i18next.t('GENERAL.ASSOCIATE')}
          />
        )}
      </TextContainer>
    </EmptyStateContainer>
  );
};

export default CashierProductAssociationEmptyState;
