import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS, TIME_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getUserTimezone } from '@commons/utils/date';
import utilsXLS from '@commons/utils/makeXLS';

import { LOSS_TYPE } from './constants';

const timezone = getUserTimezone();

const LOSSES_XLS_FILENAME = {
  [LOSS_TYPE.PRODUCT]: i18next.t('LOSSES.PRODUCTS.EXPORT_FILENAME', {
    date: moment.tz(timezone).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  }),
  [LOSS_TYPE.RECIPE]: i18next.t('LOSSES.RECIPES.EXPORT_FILENAME', {
    date: moment.tz(timezone).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  }),
  [LOSS_TYPE.SUPPLIER_PRODUCT]: i18next.t('LOSSES.SUPPLIER_PRODUCTS.EXPORT_FILENAME', {
    date: moment.tz(timezone).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  }),
};

const LOSSES_XLS_MAIN_COLUMNS = {
  DATE: {
    name: i18next.t('GENERAL.DATE'),
    propertyKey: 'timestamp',
  },
  NAME: {
    name: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
  },
  CHANNEL: {
    name: i18next.t('GENERAL.CHANNEL'),
    propertyKey: 'channel',
  },
  CATEGORY: {
    name: i18next.t('GENERAL.CATEGORY'),
    propertyKey: 'category',
  },
  COST: {
    name: i18next.t('GENERAL.COST_EX_TAX'),
    propertyKey: 'cost',
    type: 'currency',
  },
  LOSSES: {
    name: i18next.t('GENERAL.LOSSES'),
    propertyKey: 'losses',
  },
  PACKAGING: {
    name: i18next.t('GENERAL.PACKAGING'),
    propertyKey: 'packaging',
  },
  PRICE: {
    name: i18next.t('GENERAL.PRICE_EX_TAX'),
    propertyKey: 'price',
    type: 'currency',
  },
  IS_CASHIER_LOSS: {
    name: i18next.t('GENERAL.IS_CASHIER_LOSS'),
    propertyKey: 'isCashierLoss',
    type: 'boolean',
  },
  TOTAL: {
    name: i18next.t('GENERAL.TOTAL_EX_TAX'),
    propertyKey: 'total',
    type: 'currency',
  },
};

const getMainSheetColumns = (lossType, storeTranslations, currency) => {
  switch (lossType) {
    case LOSS_TYPE.PRODUCT:
      return [
        { name: storeTranslations.single, propertyKey: 'storeName' },
        LOSSES_XLS_MAIN_COLUMNS.DATE,
        LOSSES_XLS_MAIN_COLUMNS.NAME,
        LOSSES_XLS_MAIN_COLUMNS.CHANNEL,
        LOSSES_XLS_MAIN_COLUMNS.CATEGORY,
        LOSSES_XLS_MAIN_COLUMNS.COST,
        LOSSES_XLS_MAIN_COLUMNS.IS_CASHIER_LOSS,
        LOSSES_XLS_MAIN_COLUMNS.LOSSES,
        LOSSES_XLS_MAIN_COLUMNS.TOTAL,
      ];

    case LOSS_TYPE.SUPPLIER_PRODUCT:
      return [
        { name: storeTranslations.single, propertyKey: 'storeName' },
        LOSSES_XLS_MAIN_COLUMNS.DATE,
        LOSSES_XLS_MAIN_COLUMNS.NAME,
        LOSSES_XLS_MAIN_COLUMNS.PACKAGING,
        LOSSES_XLS_MAIN_COLUMNS.CATEGORY,
        LOSSES_XLS_MAIN_COLUMNS.PRICE,
        LOSSES_XLS_MAIN_COLUMNS.LOSSES,
        LOSSES_XLS_MAIN_COLUMNS.TOTAL,
      ];

    case LOSS_TYPE.RECIPE:
      return [
        { name: storeTranslations.single, propertyKey: 'storeName' },
        LOSSES_XLS_MAIN_COLUMNS.DATE,
        LOSSES_XLS_MAIN_COLUMNS.NAME,
        LOSSES_XLS_MAIN_COLUMNS.CATEGORY,
        LOSSES_XLS_MAIN_COLUMNS.COST,
        LOSSES_XLS_MAIN_COLUMNS.LOSSES,
        LOSSES_XLS_MAIN_COLUMNS.TOTAL,
      ];
  }
};

const createInfoSheet = (metaData, storeTranslations) => {
  const { stores, dateRange } = metaData;

  const headers = [
    i18next.t('LOSSES.PRODUCTS.EXPORT_COLUMN_DATE'),
    i18next.t('LOSSES.PRODUCTS.EXPORT_COLUMN_TIME'),
    storeTranslations.plural,
    i18next.t('LOSSES.PRODUCTS.EXPORT_COLUMN_PERIOD_START'),
    i18next.t('LOSSES.PRODUCTS.EXPORT_COLUMN_PERIOD_END'),
  ];

  const exportDate = moment.tz(timezone).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);
  const exportTime = moment.tz(timezone).format(TIME_DISPLAY_FORMATS.SLASHED_HOUR_MINUTE_SECOND);
  const storeNames = stores
    .map((store) => store.name)
    .sort()
    .toString();
  const startDate = dateRange.startDate.format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);
  const endDate = dateRange.endDate.format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);

  return {
    title: i18next.t('GENERAL.INFORMATIONS'),
    headers,
    data: [[exportDate, exportTime, storeNames, startDate, endDate]],
  };
};

export const exportLossData = (lossType, currency, lossData, metaData, storeTranslations) => {
  const columns = getMainSheetColumns(lossType, storeTranslations, currency);

  const mainSheet = utilsXLS.generateDefaultSheet(
    i18next.t('GENERAL.LOSSES'),
    columns,
    lossData,
    currency,
  );

  const infoSheet = createInfoSheet(metaData, storeTranslations);

  utilsXLS.makeXLS(LOSSES_XLS_FILENAME[lossType], [mainSheet, infoSheet]);
};
