import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

import { ALPHABET_DROPDOWN_ITEMS } from '@admin/utils/DropdownItems';
import { Button, Dropdown, INPUT_WIDTH, Tooltip } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { SingleDatePicker } from '@commons/DatePickers/SingleDatePicker';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { EXCEPTIONAL_DELIVERY_TYPES, EXCEPTIONAL_DELIVERY_TYPES_CHOICES } from '../utils/constants';

import {
  BlockContainer,
  BlockHeader,
  DeleteContainer,
  InputRow,
  LabelContainer,
  LeadTimeChoicesContainer,
  LeadTimeContainer,
  RightSide,
  RowInputContainer,
  WildCard,
} from './styledComponents';

const ExceptionalDeliveryDays = (props) => {
  const {
    addExceptionalDeliveryDay,
    deleteExceptionalDeliveryDay,
    exceptionalDeliveryDays,
    handleExceptionalDeliveryDays,
    isReadOnly,
  } = props;

  const today = moment();

  const getFordiddenDates = () =>
    exceptionalDeliveryDays.reduce((acc, { date }) => {
      if (!!date) {
        acc.push(date);
      }
      return acc;
    }, []);

  return (
    <BlockContainer>
      <BlockHeader>
        {i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_EXCEPTIONAL_DELIVERY')}
        <Tooltip
          text={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_EXCEPTIONAL_DELIVERY_TOOLTIP')}
        />
      </BlockHeader>
      {exceptionalDeliveryDays.map(({ date, type, leadTime }, index) => {
        const isInPast = date && date.isBefore(today, 'day');
        const isDisabled = isReadOnly || isInPast;
        const selectedType = EXCEPTIONAL_DELIVERY_TYPES_CHOICES.find(({ id }) => id === type);
        const selectedLeadTime = ALPHABET_DROPDOWN_ITEMS.find(({ id }) => id === leadTime);

        return (
          <InputRow key={index}>
            <RowInputContainer>
              <LabelContainer>
                <WildCard isDisabled={isDisabled}>*</WildCard>
                <Text
                  color={isDisabled ? ENUM_COLORS.DARK : ENUM_COLORS.DARKEST}
                  font={ENUM_FONTS.TEXT_SMALL}
                  style={{ margin: '0px 0px 8px' }}
                >
                  {i18next.t('GENERAL.DATE')}
                </Text>
              </LabelContainer>

              <SingleDatePicker
                date={date}
                disabled={isDisabled}
                displayFormat={DATE_DISPLAY_FORMATS.CONDENSED_DAY_DATE_MONTH_YEAR}
                forbiddenDates={getFordiddenDates()}
                maxPastDate={moment()}
                width={240}
                onDateChange={(value) => handleExceptionalDeliveryDays(value, index, 'date')}
              />
            </RowInputContainer>
            <RowInputContainer>
              <Dropdown
                isDisabled={isDisabled}
                items={EXCEPTIONAL_DELIVERY_TYPES_CHOICES}
                label={i18next.t('GENERAL.TYPE')}
                selectedItem={selectedType}
                width={INPUT_WIDTH.LARGE}
                isLabelTextSmall
                isRequired
                onSelectionChange={(value) =>
                  handleExceptionalDeliveryDays(value.id, index, 'type')
                }
              />
            </RowInputContainer>

            <RightSide>
              {type === EXCEPTIONAL_DELIVERY_TYPES.EXCEPTIONAL_DELIVERY && (
                <LeadTimeContainer>
                  <Dropdown
                    label={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_DELIVERY_TIME_CHOICE')}
                    selectedItem={{ id: '0', value: 'A' }}
                    width={INPUT_WIDTH.MEDIUM}
                    isDisabled
                    isLabelTextSmall
                  />
                  <Text
                    font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}
                    style={{ marginTop: '20px', alignSelf: 'center' }}
                  >
                    {i18next.t('GENERAL.FOR')}
                  </Text>
                  <LeadTimeChoicesContainer>
                    <Dropdown
                      isDisabled={isDisabled}
                      items={ALPHABET_DROPDOWN_ITEMS}
                      selectedItem={selectedLeadTime}
                      width={INPUT_WIDTH.MEDIUM}
                      isRequired
                      onSelectionChange={(value) =>
                        handleExceptionalDeliveryDays(value.id, index, 'leadTime')
                      }
                    />
                  </LeadTimeChoicesContainer>
                </LeadTimeContainer>
              )}
              <DeleteContainer>
                <Button
                  buttonCustomStyle={{
                    minWidth: 40,
                    width: 40,
                    marginTop: '20px',
                  }}
                  handleClick={() => deleteExceptionalDeliveryDay(index)}
                  icon={'/images/inpulse/close-white-small.svg'}
                  iconCustomStyle={{ margin: 'auto', width: '18px' }}
                  isDisabled={isReadOnly}
                />
              </DeleteContainer>
            </RightSide>
          </InputRow>
        );
      })}
      <Button
        buttonCustomStyle={{ paddingLeft: '0px', marginTop: '24px' }}
        color={'inpulse-outline'}
        handleClick={addExceptionalDeliveryDay}
        icon={'/images/inpulse/add-black-small.svg'}
        isDisabled={isReadOnly}
        label={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_ADD_EXCEPTIONAL_DELIVERY')}
        noBorder
      />
    </BlockContainer>
  );
};

export default ExceptionalDeliveryDays;
