import i18next from 'i18next';

import { CLIENT_STATUS } from '../../../routes/backoffice/BackOfficeGeneralContainer/BackOfficeGeneral/utils/constants';

import { clientStatusCheck } from '../../utils/clientStatusCheck';
import { getClientInfo } from '../../../selectors/client';
import { getClientStoreNameTranslation } from '../../utils/translations';
import { Store } from './subNavigation';

const PRODUCTS_ONBOARDING = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.PRODUCTS'),
  path: '/backoffice/onboarding/products',
};

const STORES_ONBOARDING = {
  getText: ({ storeName }) => getClientStoreNameTranslation(storeName, true),
  path: '/backoffice/onboarding/stores',
};

const DATA_MODEL = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.DATA_MODEL'),
  path: '/backoffice/onboarding/data-model',
};

const USERS_ONBOARDING = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.USERS'),
  path: '/backoffice/onboarding/accounts',
};

const ONBOARDING = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.ONBOARDING'),
  icon: '/images/backoffice.svg',
  children: [PRODUCTS_ONBOARDING, STORES_ONBOARDING, DATA_MODEL, USERS_ONBOARDING],
  isVisible: ({ clientStatus }) =>
    clientStatusCheck(clientStatus, CLIENT_STATUS.ONBOARDING.toLowerCase()),
};

const CLIENT = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.CLIENT'),
  path: '/backoffice/general/client',
};

const CHECKS = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.CHECKS'),
  path: '/backoffice/checks',
  getBreadCrumbText: () => i18next.t('FEATURE.BACKOFFICE.CHECKS_BREADCRUMB'),
};

const GENERAL = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.GENERAL'),
  path: '/backoffice/general/general',
};

const FEATURES = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.FEATURES'),
  path: '/backoffice/general/features',
};

const CASHIERS = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.CASHIERS'),
  path: '/backoffice/general/cashiers',
};

const INTEGRATIONS = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.INTEGRATIONS'),
  path: '/backoffice/general/integrations',
};

const SALES = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.SALES'),
  path: '/backoffice/general/sales',
};

const ANALYTICS = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.ANALYTICS'),
  path: '/backoffice/general/analytics',
};

const RECIPE_DEBUGGER = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.RECIPE_DEBUGGER'),
  path: '/backoffice/general/recipe-debugger',
  getBreadCrumbText: () => i18next.t('FEATURE.BACKOFFICE.CHECKS_BREADCRUMB'),
};

const TRANSLATIONS = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.TRANSLATIONS'),
  path: '/backoffice/translations',
};

const GENERAL_SUBMODULE = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.GENERAL'),
  icon: '/images/general.svg',
  children: [
    CLIENT,
    CHECKS,
    GENERAL,
    FEATURES,
    CASHIERS,
    INTEGRATIONS,
    SALES,
    ANALYTICS,
    RECIPE_DEBUGGER,
    TRANSLATIONS,
  ],
};

const CASHIER_PRODUCTS = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.CASHIER_PRODUCTS'),
  path: '/backoffice/products/cashier-products',
};

const INGREDIENTS = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.INGREDIENTS'),
  path: '/backoffice/products/ingredients',
};

const INGREDIENT_COST = {
  getText: () => i18next.t('FEATURE_NAME_BACKOFFICE_INGREDIENT_COST'),
  path: '/backoffice/products/ingredient-cost',
};

const PRODUCTS_SUBMODULE = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.PRODUCTS'),
  icon: '/images/burger.svg',
  children: [CASHIER_PRODUCTS, INGREDIENTS, INGREDIENT_COST],
};

const STORES = {
  getText: ({ storeName }) => getClientStoreNameTranslation(storeName, true),
  path: '/backoffice/stores/stores',
  customRender: ({ name, isCreation, isDuplication, user }) => {
    const { storeName } = getClientInfo(user);

    return {
      extraText: (() =>
        isCreation && !isDuplication
          ? i18next.t('BACKOFFICE.STORES.CREATION_MODAL_TITLE', {
              storeName: getClientStoreNameTranslation(storeName).toLowerCase(),
            })
          : name)(),
    };
  },
  subFeatures: [Store.BACKOFFICE_STORE_CREATION, Store.BACKOFFICE_STORE_DETAILS],
};

const CASHIER_STORES = {
  getText: ({ storeName }) =>
    i18next.t('FEATURE.BACKOFFICE.CASHIER_STORES', {
      storeName: getClientStoreNameTranslation(storeName, true),
    }),
  customRender: ({ cashierStore: { name } }) => ({
    extraText: ': ' + name,
    linkedText: i18next.t('BACKOFFICE.DATA.EXPORT_PRODUCT_SALES_HEADER_CASHIER_STORE'),
  }),
  path: '/backoffice/stores/cashier-stores',
  subFeatures: [Store.BACKOFFICE_CASHIER_STORE_DETAILS],
};

const LOCATIONS = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.LOCATIONS'),
  path: '/backoffice/stores/locations',
};

const GROUPS = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.GROUPS'),
  path: '/backoffice/stores/groups',
};

const STORES_SUBMODULE = {
  getText: ({ storeName }) => getClientStoreNameTranslation(storeName, true),
  icon: '/images/store.svg',
  children: [STORES, CASHIER_STORES, LOCATIONS, GROUPS],
};

const ROLES = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.ROLES'),
  path: '/backoffice/users/roles',
};

const TEAM = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.TEAM'),
  path: '/backoffice/users/team',
};

const USERS_SUBMODULE = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.USERS'),
  icon: '/images/user.svg',
  children: [ROLES, TEAM],
};

export const BACKOFFICE = {
  getText: () => i18next.t('FEATURE.BACKOFFICE.BACKOFFICE'),
  path: '/backoffice',
  icon: '/images/backoffice.svg',
  children: [ONBOARDING, GENERAL_SUBMODULE, PRODUCTS_SUBMODULE, STORES_SUBMODULE, USERS_SUBMODULE],
};
