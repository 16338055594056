import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { Container, OuterCircle, InnerCircle } from './styledComponents';

import { getTheme } from '../utils/theme';

interface Props {
  value: string;
  selected: string;
  onChange: Function;
  isReadOnly: boolean;
  size: string;
  borderSize: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any;
}

const RadioButton = (props: Props): JSX.Element => {
  const { value, theme, selected, onChange, isReadOnly, size, borderSize } =
    props;

  const handleClick = () => {
    if (isReadOnly || !onChange) {
      return;
    }

    onChange(value);
  };

  const isSelected = value === selected;

  return (
    <ThemeProvider theme={getTheme(theme, 'radioButton')}>
      <Container
        isSelected={isSelected}
        isReadOnly={isReadOnly}
        onClick={handleClick}
        size={size}
      >
        <OuterCircle
          isSelected={isSelected}
          isReadOnly={isReadOnly}
          size={size}
          borderSize={borderSize}
        >
          <InnerCircle
            isReadOnly={isReadOnly}
            isSelected={isSelected}
            size={size}
          />
        </OuterCircle>
      </Container>
    </ThemeProvider>
  );
};

RadioButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  isReadOnly: PropTypes.bool,
  value: PropTypes.string.isRequired,
  selected: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  size: PropTypes.string,
  borderSize: PropTypes.string,
};

RadioButton.defaultProps = {
  onChange: null,
  isReadOnly: false,
  size: 'default',
  theme: null,
  borderSize: '1px',
};

export default RadioButton;
