import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 56px);
  position: relative;
  padding-top: 104px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  z-index: 0;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: start;
  padding: 24px;
  ${(props) =>
    props.isFooterDisplayed &&
    css`
      margin-bottom: 56px;
    `}
`;

export const InputContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const PictureContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 16px;
`;

export const TooltipContainer = styled.div`
  > div {
    display: flex;
    align-items: center;
  }
`;

export const EmailContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: flex-start;

  width: 100%;
`;
