import { event } from '../services/event';
import storeService from '../services/store';

export const fetchPastEventsOfStore = (storeId, startDate, endDate) =>
  function (dispatch) {
    dispatch({
      type: 'PAST_EVENTS_REQUEST',
    });
    return storeService.getEventsOfStore(storeId, startDate, endDate);
  };
export const receivePastEvents = (pastEvents) => ({
  type: 'PAST_EVENTS_SUCCESS',
  pastEvents,
});
export const fetchPastEventError = (error) => ({
  type: 'PAST_EVENTS_FAILURE',
  error,
});

export const fetchLyEventsOfStore = (storeId, startDate, endDate) =>
  function (dispatch) {
    dispatch({
      type: 'LY_EVENTS_REQUEST',
    });
    return storeService.getEventsOfStore(storeId, startDate, endDate);
  };
export const receiveLyEvents = (lyEvents) => ({
  type: 'LY_EVENTS_SUCCESS',
  lyEvents,
});
export const fetchLyEventError = (error) => ({
  type: 'LY_EVENTS_FAILURE',
  error,
});

export const deleteEventSuccess = () => ({
  type: 'EVENT_DELETE_SUCCESS',
});

export const deleteEventError = (error) => ({
  type: 'EVENT_DELETE_FAILURE',
  error,
});

export const patchEventSuccess = () => ({
  type: 'PATCH_EVENT_SUCCESS',
});

export const patchEventError = (error) => ({
  type: 'PATCH_EVENT_FAILURE',
  error,
});

export const fetchEventsByStoreIdAndDates = (storeId, startDate, endDate) =>
  function (dispatch) {
    dispatch({
      type: 'EVENTS_REQUEST',
    });
    return storeService.getEventsOfStore(storeId, startDate, endDate);
  };
export const receiveEvents = (events) => ({
  type: 'EVENTS_SUCCESS',
  events,
});
export const fetchEventError = (error) => ({
  type: 'EVENTS_FAILURE',
  error,
});

export const deleteEventById = (id) =>
  function (dispatch) {
    dispatch({
      type: 'EVENT_DELETE_REQUEST',
    });
    return event.deleteEventById(id);
  };

export const patchEvent = (
  id,
  clientId,
  storeId,
  eventCategoryId,
  eventSubCategoryId,
  startDate,
  endDate,
  input1,
  input2,
  input3,
  impact,
) =>
  function (dispatch) {
    dispatch({
      type: 'PATCH_EVENT_REQUEST',
    });
    return event.patchEvent(
      id,
      clientId,
      storeId,
      eventCategoryId,
      eventSubCategoryId,
      startDate,
      endDate,
      input1,
      input2,
      input3,
      impact,
    );
  };
