import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import {
  Button,
  Dropdown,
  InputIncrement,
  INPUT_WIDTH,
  Checkbox,
} from '@commons/utils/styledLibraryComponents';
import {
  computeTypesOfPackaging,
  // convertPriceToPackagingById,
  convertQuantityToPackagingById,
} from '@commons/utils/packagings';
import {
  DropdownItem,
  DropdownItemImage,
  DropdownItemMainValue,
  DropdownItemSecondaryValue,
  DropdownItemValuesContainer,
} from '@commons/TableForm/TableFormInModal/styledComponents';
import { EmptyStateIcon } from '@commons/EmptyState/styledComponents';
import { formatTextNumberInputValue, sortPackagings } from '@commons/utils/format';
import Footer from '@commons/Footer/Footer';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { getAuthorizedActions } from '@selectors/featureProps';

import { Asterisk } from '@lib/inpulse/InputEmailChip/styledComponents';
import { Input } from '@lib/inpulse/Input';

import { getTranslatedOrderAnomalies } from '@orders/OrderList/components/OrderForm/constants/anomalies';

import {
  ButtonsContainer,
  Container,
  ContentContainer,
  DetailContainer,
  Divider,
  EmptyStateContainer,
  List,
  DrawerText,
  TitleContainer,
  RequiredTextContainer,
  InputIncrementContainer,
  DropdownContainer,
} from '../styledComponents';

const NUMBER_CHECK_REGEXP = new RegExp(/^\d+($|(\.|\,)(\d+|$))$/);
const BUTTON_STYLE = { padding: '0px 40px' };

const OrderAdditionReferenceDrawerContent = (props) => {
  const { currency, onClose, supplierProducts, handleNewReference, isEditingPreparationOrder } =
    props;

  const [selectedSupplierProduct, setSelectedSupplierProduct] = useState(null);
  const [anomaly, setAnomaly] = useState(null);
  const [disableAnomalyDropdown, setDisableAnomalyDropdown] = useState(true);

  const [newReferencePackagingData, setNewReferencePackagingData] = useState({
    packagingUsedInOrder: null,
    packagingUsedInInvoice: null,
    packagingUsedInReception: null,
    invoiced: 0,
    received: 0,
  });
  const [newReferencePriceData, setNewReferencePriceData] = useState({
    price: 0,
    overwriteSupplierProductPrice: false,
  });

  const [isValidationDisabled, setIsValidationDisabled] = useState(false);
  const [errorState, setErrorState] = useState(false);

  useEffect(() => {
    if (isEmpty(anomaly) && !disableAnomalyDropdown && !!errorState) {
      setIsValidationDisabled(true);
      return;
    }

    setIsValidationDisabled(false);
    setErrorState(false);
  }, [anomaly, disableAnomalyDropdown]);

  useEffect(() => {
    if (!!selectedSupplierProduct) {
      const { packagingUsedInOrder, packagingUsedInInvoice, packagingUsedInReception } =
        computeTypesOfPackaging(null, selectedSupplierProduct.packagings);

      setNewReferencePackagingData({
        packagingUsedInOrder,
        packagingUsedInInvoice,
        packagingUsedInReception,
        invoiced: 0,
        received: 0,
      });
    }
  }, [selectedSupplierProduct]);

  useEffect(() => {
    if (newReferencePackagingData.invoiced !== newReferencePackagingData.received) {
      setDisableAnomalyDropdown(false);
      return;
    }
    if (!!selectedSupplierProduct) {
      if (newReferencePriceData.price !== parseFloat(selectedSupplierProduct.price)) {
        setDisableAnomalyDropdown(false);
        return;
      }
    }
    setDisableAnomalyDropdown(true);
  }, [newReferencePackagingData.invoiced, newReferencePackagingData.received]);

  const handleNewReferencePackagingChange = (packaging) => {
    const updatedInvoicedQty = convertQuantityToPackagingById(
      newReferencePackagingData.invoiced,
      newReferencePackagingData.packagingUsedInReception.id,
      packaging.id,
      selectedSupplierProduct.packagings,
    );

    const updatedReceivedQty = convertQuantityToPackagingById(
      newReferencePackagingData.received,
      newReferencePackagingData.packagingUsedInReception.id,
      packaging.id,
      selectedSupplierProduct.packagings,
    );

    setNewReferencePackagingData({
      ...newReferencePackagingData,
      packagingUsedInReception: packaging,
      invoiced: Math.round(updatedInvoicedQty * 100) / 100,
      received: Math.round(updatedReceivedQty * 100) / 100,
    });
  };

  const orderAnomalies = getTranslatedOrderAnomalies(false);

  const handleReceptionAnomaly = (anomalyId) => {
    if (!anomalyId) {
      setAnomaly(null);
      return;
    }

    const matchingAnomaly = orderAnomalies.find(({ id }) => id === anomalyId);

    if (!matchingAnomaly) {
      return;
    }

    setAnomaly(matchingAnomaly);
  };

  const formattedSupplierProducts = supplierProducts.map((sp) => ({
    ...sp,
    renderValue: () => (
      <DropdownItem>
        <DropdownItemImage
          src={sp.img || '/images/inpulse/product-photo-placeholder.svg'}
        ></DropdownItemImage>
        <DropdownItemValuesContainer>
          <DropdownItemMainValue>{sp.name}</DropdownItemMainValue>
          <DropdownItemSecondaryValue>{sp.category}</DropdownItemSecondaryValue>
        </DropdownItemValuesContainer>
      </DropdownItem>
    ),
  }));

  const handleValidation = () => {
    if (isEmpty(anomaly) && !disableAnomalyDropdown && !isEditingPreparationOrder) {
      setErrorState(true);
      setIsValidationDisabled(true);
      return;
    }

    const newReference = {
      supplierProduct: selectedSupplierProduct,
      packagingData: newReferencePackagingData,
      priceData: newReferencePriceData,
      receptionAnomaly: anomaly,
      lnkSupplierproductProductorderrel: {
        ...selectedSupplierProduct,
        invoiced: newReferencePackagingData.invoiced,
        received: newReferencePackagingData.received,
      },
    };

    handleNewReference(newReference);
    onClose();
  };

  const onSupplierProductSelectionChange = (supplierProduct) => {
    if (!supplierProducts || !supplierProducts.length || isEmpty(supplierProduct)) {
      setSelectedSupplierProduct({});
      return;
    }

    setSelectedSupplierProduct(supplierProduct);
    setNewReferencePriceData({
      price: supplierProduct.price || 0,
      overwriteSupplierProductPrice: false,
    });
  };

  const handleReceptionPriceChange = (e) => {
    const value = e.target?.value;

    if (!value || !value.length) {
      setNewReferencePriceData({ ...newReferencePriceData, price: 0 });

      return;
    }

    if (!NUMBER_CHECK_REGEXP.test(value)) {
      return;
    }

    const formattedValue = formatTextNumberInputValue(value, 0);

    setNewReferencePriceData({ ...newReferencePriceData, price: formattedValue });
  };

  return (
    <Container>
      <ContentContainer>
        <TitleContainer>{i18next.t('ORDERS.ORDERS.DRAWER_TITLE_ADD_REFERENCE')}</TitleContainer>

        <List>
          <RequiredTextContainer>
            <Asterisk>*</Asterisk>
            <DrawerText>{i18next.t('GENERAL.REFERENCE')} :</DrawerText>
          </RequiredTextContainer>
          <DetailContainer style={{ marginTop: '8px' }}>
            <Dropdown
              iconSrc={'/images/inpulse/search-dmgrey-small.svg'}
              items={formattedSupplierProducts}
              placeholder={i18next.t('GENERAL.CHOOSE_PLACEHOLDER')}
              selectedItem={selectedSupplierProduct}
              width={INPUT_WIDTH.EXTRA_LARGE}
              isRequired
              onSelectionChange={(supplierProduct) => {
                onSupplierProductSelectionChange(supplierProduct);
              }}
            />
          </DetailContainer>
        </List>
        <Divider />
        {isEmpty(selectedSupplierProduct) ? (
          <List>
            <EmptyStateContainer style={{ marginTop: '96px' }}>
              <EmptyStateIcon
                alt="empty-state-no-result-icon"
                className={'center'}
                src="/images/inpulse/empty-state-no-result.svg"
              />
              <DrawerText>
                {i18next.t('ORDERS.ORDERS.DRAWER_EMPTY_STATE_REFERENCE_NOT_SELECTED')}
              </DrawerText>
            </EmptyStateContainer>
          </List>
        ) : (
          <List>
            <DetailContainer style={{ marginTop: '0px' }}>
              <DrawerText>{i18next.t('GENERAL.PACKAGING')} :</DrawerText>
              <Dropdown
                iconSrc={'/images/inpulse/search-dmgrey-small.svg'}
                items={selectedSupplierProduct.packagings}
                placeholder={i18next.t('GENERAL.CHOOSE_PLACEHOLDER')}
                selectedItem={newReferencePackagingData.packagingUsedInReception}
                sortBy={sortPackagings}
                width={INPUT_WIDTH.EXTRA_LARGE}
                isRequired
                onSelectionChange={(item) => handleNewReferencePackagingChange(item)}
              />
            </DetailContainer>

            <DetailContainer style={{ gap: '24px' }} inline>
              <InputIncrementContainer>
                <InputIncrement
                  isDisabled={!selectedSupplierProduct}
                  label={`${i18next.t('ORDERS.ORDERS.LIST_LABEL_QUANTITY_BDL')} :`}
                  limitValues={{ min: 0 }}
                  setValue={(value) =>
                    setNewReferencePackagingData({
                      ...newReferencePackagingData,
                      invoiced: value,
                    })
                  }
                  value={newReferencePackagingData.invoiced}
                  width={'100%'}
                  required
                />
              </InputIncrementContainer>
              {!isEditingPreparationOrder && (
                <InputIncrementContainer>
                  <InputIncrement
                    isDisabled={!selectedSupplierProduct}
                    label={`${i18next.t('ORDERS.ORDERS.LIST_LABEL_QUANTITY_RECEIVED')} :`}
                    limitValues={{ min: 0 }}
                    setValue={(value) =>
                      setNewReferencePackagingData({
                        ...newReferencePackagingData,
                        received: value,
                      })
                    }
                    value={newReferencePackagingData.received}
                    width={'100%'}
                    required
                  />
                </InputIncrementContainer>
              )}
            </DetailContainer>
          </List>
        )}
        {!isEmpty(selectedSupplierProduct) && (
          <>
            <Divider></Divider>
            <List>
              <div>
                <Input
                  autoComplete="off"
                  label={`* ${i18next.t('ORDERS.ORDERS.LIST_LABEL_PRICE_UNIT_EX_TAX_BDL')} (${
                    currency.alphabeticCode
                  }/${newReferencePackagingData.packagingUsedInInvoice?.name || ''})`}
                  placeholder={'00.00'}
                  readOnly={!selectedSupplierProduct}
                  type={'text'}
                  value={newReferencePriceData.price}
                  textBig
                  onChange={handleReceptionPriceChange}
                />
              </div>
              {selectedSupplierProduct.lnkSupplierproductProductorderrel
                ?.priceEditableAtReception && (
                <DetailContainer inline>
                  <Checkbox
                    handleClick={() =>
                      setNewReferencePriceData({
                        ...newReferencePriceData,
                        overwriteSupplierProductPrice:
                          !newReferencePriceData.overwriteSupplierProductPrice,
                      })
                    }
                    isChecked={newReferencePriceData.overwriteSupplierProductPrice}
                    shape="square"
                  />
                  <DrawerText>
                    {i18next.t('ORDERS.ORDERS.DRAWER_RECEPTION_PRICE_BDL_CHECKBOX_LABEL')}
                  </DrawerText>
                </DetailContainer>
              )}
            </List>
            {!isEditingPreparationOrder && (
              <>
                <Divider />
                <List>
                  <DropdownContainer>
                    <Dropdown
                      isDisabled={disableAnomalyDropdown}
                      isErrorState={errorState}
                      items={orderAnomalies}
                      label={`${i18next.t('ORDERS.ORDERS.LIST_LABEL_ANOMALY_REASON')} :`}
                      placeholder={i18next.t('GENERAL.CHOOSE_PLACEHOLDER')}
                      selectedItem={anomaly}
                      width={INPUT_WIDTH.EXTRA_LARGE}
                      isRequired
                      onSelectionChange={(anomaly) => handleReceptionAnomaly(anomaly?.id || null)}
                    />
                    {errorState && (
                      <Text
                        color={ENUM_COLORS.INFO_RED}
                        font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14_WEIGHT_600}
                      >
                        {i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
                          field: i18next.t('ORDERS.ORDERS.LIST_LABEL_ANOMALY_REASON'),
                        })}
                      </Text>
                    )}
                  </DropdownContainer>
                </List>
              </>
            )}
          </>
        )}
      </ContentContainer>
      <Divider />
      <Footer
        customStyle={{
          'box-shadow': 'none',
          'background-color': 'transparent',
          width: 'auto',
          height: 'calc(100% - calc(100% - (64px + 24px)))',
          padding: '24px 0px',
        }}
      >
        <ButtonsContainer>
          <Button
            buttonCustomStyle={BUTTON_STYLE}
            color={'inpulse-outline'}
            handleClick={onClose}
            icon={'/images/inpulse/close-black-small.svg'}
            label={i18next.t('GENERAL.CANCEL')}
            minWidth={168}
          />
          <Button
            buttonCustomStyle={BUTTON_STYLE}
            color={'inpulse-default'}
            handleClick={handleValidation}
            icon={'/images/inpulse/check-white-small.svg'}
            isDisabled={
              isEmpty(selectedSupplierProduct) ||
              (!newReferencePackagingData.received && !newReferencePackagingData.invoiced) ||
              isValidationDisabled
            }
            label={i18next.t('GENERAL.VALIDATE')}
            minWidth={168}
          />
        </ButtonsContainer>
      </Footer>
    </Container>
  );
};

OrderAdditionReferenceDrawerContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  supplierProducts: PropTypes.array.isRequired,
  handleNewReference: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/order/orders'),
});

export default connect(mapStateToProps)(OrderAdditionReferenceDrawerContent);
