import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import { ListView } from '@commons/utils/styledLibraryComponents';

import { getClientInfo } from '@selectors/client';

import EmptyState from '@backoffice/BackOfficeStoresContainer/BackOfficeStoresManagement/components/EmptyState';

import DeepsightFiltersButton from '@orders/components/FilterButton';

import { CHOICES_DROPDOWN_ACTIVE } from '@admin/utils/DropdownItems';
import { fetchPaginatedCashierProductsByClientId } from '@admin/cashier/cashierProducts';
import {
  LastSyncContainer,
  LastSyncTitle,
  LastSyncDate,
} from '@admin/cashier/cashierProducts/styledComponents';

import { Container, FiltersContainer } from '../styledComponents';

import { ListViewColumns } from './listViewColumnsConfig';

const CUSTOM_FILTERS_IDS = {
  STATUS: 'status',
  ASSOCIATED: 'associated',
  INCLUDED: 'included',
};

const QUERY_PARAMS = {
  orderBy: 'name',
  orderType: 'asc',
};

// TODO: DX - refacto dropdown items
export const CHOICES_DROPDOWN_MAPPED = [
  { id: 3, name: i18next.t('GENERAL.LINKED_PLURAL'), propertyKey: 'mapped', itemValue: true },
  { id: 4, name: i18next.t('GENERAL.UNLINKED'), propertyKey: 'mapped', itemValue: false },
];
export const CHOICES_DROPDOWN_HIDDEN = [
  { id: 5, name: i18next.t('GENERAL.EXCLUDED'), propertyKey: 'hidden', itemValue: true },
  { id: 6, name: i18next.t('GENERAL.UNEXCLUDED'), propertyKey: 'hidden', itemValue: false },
];

/**
 * Used to convert the selected filter data for Status/Associated/Included into a correct payload for the API
 * Based on applyFilterSelected from src/routes/admin/products/cashierProducts/index.js
 * @param {DropdownItem[]} statusDropdownSelectedItems
 * @param {DropdownItem[]} associatedDropdownSelectedItems
 * @param {DropdownItem[]} includedDropdownSelectedItems
 * @returns an object of the shape {
 *   findActive: 'true' | 'false | 'both',
 *   findMapped: 'true' | 'false | 'both',
 *   findHidden: 'true' | 'false | 'both',
 * }
 */
const resolveCustomFilter = (
  statusDropdownSelectedItems,
  associatedDropdownSelectedItems,
  includedDropdownSelectedItems,
) => {
  const findActive =
    statusDropdownSelectedItems.length > 1
      ? 'both'
      : _.head(statusDropdownSelectedItems).filterValue.toString();

  const findMapped =
    associatedDropdownSelectedItems.length > 1
      ? 'both'
      : _.head(associatedDropdownSelectedItems).itemValue.toString();

  const findHidden =
    includedDropdownSelectedItems.length > 1
      ? 'both'
      : _.head(includedDropdownSelectedItems).itemValue.toString();

  return { findActive, findMapped, findHidden };
};

// ============================================

const SelectCashierProductsModal = (props) => {
  const {
    user,
    client: { lastSync },
    selectCashierProductsData,
    setSelectCashierProductsData,
  } = props;

  const {
    statusDropdownSelectedItems,
    associatedDropdownSelectedItems,
    includedDropdownSelectedItems,
    selectedCashierProductIds,
  } = selectCashierProductsData;

  const userLanguageCode = _.get(user, 'lnkLanguageAccountrel.code', 'fr');

  const [isLoading, setIsLoading] = useState(false);

  // --- Filtering & Query state ---

  const customMultipleFilterStatus = {
    id: CUSTOM_FILTERS_IDS.STATUS,
    icon: '/images/icon-dropdown-grey.svg',
    list: CHOICES_DROPDOWN_ACTIVE,
    defaultSelectedItems: statusDropdownSelectedItems,
    selectedItems: statusDropdownSelectedItems,
    setSelectedItems: () => {}, // items selection is done by useEffect on [filters]
    allMessage: i18next.t('GENERAL.ACTIVES_INACTIVES'),
  };
  const customMultipleFilterAssociated = {
    id: CUSTOM_FILTERS_IDS.ASSOCIATED,
    icon: '/images/inpulse/link-black-small.svg',
    list: CHOICES_DROPDOWN_MAPPED,
    defaultSelectedItems: associatedDropdownSelectedItems,
    selectedItems: associatedDropdownSelectedItems,
    setSelectedItems: () => {}, // items selection is done by useEffect on [filters]
    allMessage: i18next.t('GENERAL.LINKED_OR_NOT'),
  };
  const customMultipleFilterIncluded = {
    id: CUSTOM_FILTERS_IDS.INCLUDED,
    icon: '/images/inpulse/eye-black-small.svg',
    list: CHOICES_DROPDOWN_HIDDEN,
    defaultSelectedItems: includedDropdownSelectedItems,
    selectedItems: includedDropdownSelectedItems,
    setSelectedItems: () => {}, // items selection is done by useEffect on [filters]
    allMessage: i18next.t('GENERAL.EXCLUDED_OR_NOT'),
  };

  const customMultipleDropDowns = [
    customMultipleFilterStatus,
    customMultipleFilterAssociated,
    customMultipleFilterIncluded,
  ];

  const [filters, setFilters] = useState(null);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);

  // The list of CashierProducts returned by the API
  const [queriedCashierProducts, setQueriedCashierProducts] = useState([]);
  // The same list, with its element to properly show selected items
  const [cashierProductsList, setCashierProductsList] = useState([]);

  // ------------------

  // Fetch of all CashierProducts matching the chosen filters
  useEffect(() => {
    if (!applyFilters) {
      return;
    }

    setIsLoading(true);

    (async function loadData() {
      try {
        const clientId = _.get(user, 'clientId');

        const cashierProducts = await fetchPaginatedCashierProductsByClientId(
          clientId,
          resolveCustomFilter(
            statusDropdownSelectedItems,
            associatedDropdownSelectedItems,
            includedDropdownSelectedItems,
          ),
          QUERY_PARAMS,
          true,
        );

        const formattedCashierProducts = cashierProducts.cashierProducts.map((cashierProduct) => ({
          ...cashierProduct,
          isRowSelected: false,
          mapped: cashierProduct.product_cashier_product_mapping_id, // for proper column display
        }));

        setQueriedCashierProducts(formattedCashierProducts);
      } catch (error) {
        showErrorMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.LOAD_ERROR'));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [applyFilters]);

  /**
   * After a query for CashierProducts is made, set the isRowSelected based on the step data
   * so that selection is kept through navigation.
   */
  useEffect(() => {
    const formattedCashierProducts = queriedCashierProducts.map((cashierProduct) => ({
      ...cashierProduct,
      isRowSelected: !!selectedCashierProductIds.find(
        (selectedId) => selectedId === cashierProduct.id,
      ),
    }));

    setCashierProductsList(formattedCashierProducts);
  }, [queriedCashierProducts]);

  // Storing the filters selection in the step data
  useEffect(() => {
    if (!filters) {
      return;
    }
    setSelectCashierProductsData({
      ...selectCashierProductsData,
      statusDropdownSelectedItems: filters[CUSTOM_FILTERS_IDS.STATUS].selectedItems,
      associatedDropdownSelectedItems: filters[CUSTOM_FILTERS_IDS.ASSOCIATED].selectedItems,
      includedDropdownSelectedItems: filters[CUSTOM_FILTERS_IDS.INCLUDED].selectedItems,
    });
  }, [filters]);

  const [lastSyncDate, setLastSyncDate] = useState({});

  useEffect(() => {
    const formattedDate = moment(lastSync);

    setLastSyncDate({ date: formattedDate.format('DD/MM'), hour: formattedDate.format('HH:mm') });
  }, []);

  return (
    <Container>
      <ListView
        columns={ListViewColumns}
        data={cashierProductsList}
        disableFooter={true}
        forceEnableSelection={true}
        isLoading={isLoading}
        languageCode={userLanguageCode}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => (
          <FiltersContainer>
            <DeepsightFiltersButton
              advancedFilters={advancedFilters}
              applyFilters={applyFilters}
              customMultipleDropDowns={customMultipleDropDowns}
              filters={filters}
              isLoading={isLoading}
              setAdvancedFilters={setAdvancedFilters}
              setApplyFilters={setApplyFilters}
              setFilters={setFilters}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
            />
            {!_.isEmpty(lastSyncDate) && (
              <LastSyncContainer>
                <LastSyncTitle>{i18next.t('GENERAL.LAST_SYNC')}</LastSyncTitle>
                <LastSyncDate>
                  {i18next.t('ADMIN.CASHIER_PRODUCTS.LAST_SYNC_DATE', {
                    date: lastSyncDate.date,
                    hour: lastSyncDate.hour,
                  })}
                </LastSyncDate>
              </LastSyncContainer>
            )}
          </FiltersContainer>
        )}
        setSelectedItems={(selectedCashierProducts) => {
          const selectedCashierProductsIds = selectedCashierProducts.map((cp) => cp.id);
          setSelectCashierProductsData({
            ...selectCashierProductsData,
            selectedCashierProductIds: selectedCashierProductsIds,
          });
        }}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectCashierProductsModal);
