import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 552px;
  height: 264px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  border: solid 1px ${(props) => props.theme.colors.greys.lighty};
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);

  position: absolute;
  z-index: 10;
  top: 48px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 24px;

  font: ${(props) => props.theme.fonts.textMiddleBold};
  border-bottom: solid 1px ${(props) => props.theme.colors.greys.lighty};
`;

export const DropdownsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
`;

export const StartDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const EndDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  padding: 24px;

  column-gap: 24px;

  border-top: solid 1px ${(props) => props.theme.colors.greys.lighty};
`;
