export function getUnit(unit, value) {
  if (unit === 'unit') {
    return value > 1 ? 'unités' : 'unité';
  }
  return unit;
}

export default {
  getUnit,
};
