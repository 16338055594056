import styled from 'styled-components';

export const EventContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 8px 0 8px 16px;
`;

export const EventLabel = styled.div`
  display: flex;

  align-items: center;
  justify-content: flex-start;

  padding: 0 16px;

  margin: 8px 0;

  height: 40px;

  border-radius: 20px;

  max-width: 282px;

  background-color: ${(props) =>
    !props.userEvent ? props.theme.colors.brand.secondaryLight : props.theme.colors.greys.lighter};

  border: 1px solid
    ${(props) =>
      !props.userEvent ? props.theme.colors.highlightedBlue : props.theme.colors.greys.light};
`;

export const EventIcon = styled.img`
  width: 24px;
  height: 24px;

  margin-right: 8px;
`;

export const EventText = styled.div`
  font: ${(props) => props.theme.fonts.textSmall};
  color: ${(props) => props.theme.colors.greys.darkest};

  max-width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export default {
  EventContainer,
  EventLabel,
  EventIcon,
  EventText,
};
