import { sortBy } from 'lodash';

const sortActionsList = (actions) => {
  const sortedActions = sortBy(actions, (action) => {
    const actionType = typeof action.id === 'string' ? action.id.split('-')[0] : action.id;

    switch (actionType) {
      case 'special': {
        return -1;
      }
      case 'edit': {
        return 0;
      }
      case 'consult': {
        return 1;
      }
      case 'action': {
        return 2;
      }
      case 'assign': {
        return 3;
      }
      case 'define': {
        return 4;
      }
      case 'duplicate': {
        return 5;
      }
      case 'activate': {
        return 6;
      }
      case 'deactivate': {
        return 7;
      }
      case 'delete': {
        return 8;
      }
      case 'disable': {
        return 9;
      }
      case 'enable': {
        return 10;
      }
      case 'export': {
        return 11;
      }
      case 'csm': {
        return 12;
      }
      default:
        return 12;
    }
  });

  return sortedActions;
};

export default sortActionsList;
