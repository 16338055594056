import utilsXLS from '@commons/utils/makeXLS';

const MAX_SHEET_ELEMENT_SIZE = 50000;

export default (filename, turnoverSheet, unitSheet, contextSheet) => {
  const turnoverSheetData = turnoverSheet.data;
  const unitSheetData = unitSheet.data;

  const hasManyFiles =
    turnoverSheetData.length > MAX_SHEET_ELEMENT_SIZE ||
    unitSheetData.length > MAX_SHEET_ELEMENT_SIZE;

  let generatedFileCount = 1;

  while (turnoverSheetData.length > 0 || unitSheetData.length > 0) {
    const turnoverBatchData = turnoverSheetData.splice(0, MAX_SHEET_ELEMENT_SIZE);
    const unitBatchData = unitSheetData.splice(0, MAX_SHEET_ELEMENT_SIZE);

    utilsXLS.makeXLS(hasManyFiles ? `${filename}-${generatedFileCount}` : filename, [
      { ...turnoverSheet, data: turnoverBatchData },
      { ...unitSheet, data: unitBatchData },
      contextSheet,
    ]);

    generatedFileCount++;
  }
};
