import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { makeOrderOrInventoryXLS } from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { fetchPaginatedInventories } from '@stocks/StocksInventories/common/services';
import { statusLegend } from '@stocks/StocksInventories/common/constants';

const DEFAULT_TITLE_EXPORT_FAILURE = i18next.t('GENERAL.EXPORT_FAILURE');
const DEFAULT_TITLE_EXPORT_READY = i18next.t('GENERAL.EXPORT_SUCCESS');
const BATCH_INVENTORIES_TO_FETCH_AT_ONCE = 500;

/**
 * Format a given data related to an order and make sure to get all information
 * that would be necessary for generating the export file
 *
 * @param {Object} inventories - The data to format
 *
 * @return {Object[]} The formatted data from the given list of inventories
 */
export const formatDataForXLSFile = (inventories, inventoryValidation, isCentralKitchenView) =>
  inventories.map((inventory) => {
    const formattedInventory = { ...inventory };

    if (inventoryValidation && !isCentralKitchenView) {
      formattedInventory.isValidated = i18next.t(
        inventory.isValidated ? 'STOCKS.STOCKS.APPROVED' : 'STOCKS.STOCKS.TO_BE_APPROVED',
      );
    }
    return {
      ...formattedInventory,
      type: i18next.t(statusLegend[inventory.type].name),
      timestamp: moment.tz(inventory.timestamp, inventory.storeTimezone).format('L'),
    };
  });

/**
 * Update the progress value from the elements that are still left to treat
 *
 * @param {Number} nbInventoriesLeft        - The number of inventories still left to trat
 * @param {Object[]} detailedInventoryList  - The list of orders that has already been treated
 * @param {Function} setProgress            - Method to set the local state of the progress value
 *
 * @return {void}
 */
export const updateProgress = (nbInventoriesLeft, detailedInventoryList, setProgress) => {
  const updatedProgress =
    100 - (nbInventoriesLeft / (nbInventoriesLeft + detailedInventoryList.length)) * 100;

  setProgress(updatedProgress);
};

/**
 * Handle the progressive loading of orders from the list of orders that are still to fetch
 *
 * @returns {void}
 */
export const loadInventoriesByBatch = async ({
  storeIds,
  searchQueryParam,
  queryParams,
  inventoriesProcessed,
  columns,
  setLoading,
  setTitle,
  setProgress,
  setInventoriesProcessed,
  totalInventories,
  setTotalInventories,
  skip,
  setSkip,
  fileName,
  sheetName,
  currency,
  inventoryValidation,
  isCentralKitchenView,
}) => {
  if (totalInventories && totalInventories <= skip) {
    setProgress(100);

    makeOrderOrInventoryXLS(sheetName, inventoriesProcessed, fileName, columns, currency);

    return exportReady(setLoading, setTitle);
  }

  try {
    let inventoryItemsList = [...inventoriesProcessed];

    const result = await fetchPaginatedInventories(
      storeIds,
      null,
      null,
      searchQueryParam,
      skip,
      BATCH_INVENTORIES_TO_FETCH_AT_ONCE,
      'timestamp',
      'DESC',
      queryParams.params,
      queryParams.inventoryListTemplateIds,
    );

    const inventoriesRetrieved = formatDataForXLSFile(
      result.inventories,
      inventoryValidation,
      isCentralKitchenView,
    );

    inventoryItemsList = inventoryItemsList.concat(inventoriesRetrieved);

    setSkip(skip + inventoriesRetrieved.length);

    setTotalInventories(result.totalCount);

    updateProgress(result.totalCount - inventoryItemsList.length, inventoryItemsList, setProgress);

    setTimeout(() => {
      setInventoriesProcessed(inventoryItemsList);
    }, 500);
  } catch (err) {
    exportFailure(setLoading, setTitle);
  }
};

/**
 * Close the modal
 *
 * @returns {void}
 */
export const exitModal = (setLoading, closeModal) => {
  setLoading(false);
  closeModal();
};

/**
 * Set component with export success state
 *
 * @returns {void}
 */
export const exportReady = (setLoading, setTitle) => {
  setTitle(DEFAULT_TITLE_EXPORT_READY);
  setLoading(false);
};

/**
 * Set component with export failure state
 *
 * @returns {void}
 */
export const exportFailure = (setLoading, setTitle) => {
  setTitle(DEFAULT_TITLE_EXPORT_FAILURE);
  setLoading(false);
};

export const InventoryExportListModal = (props) => {
  const {
    currency,
    params: {
      title,
      fileName,
      sheetName,
      columns,
      storeIds,
      searchQueryParam,
      queryParams,
      inventoryValidation,
      isCentralKitchenView,
    },
    closeModal,
  } = props;

  const [isLoading, setLoading] = useState(true);

  const [progress, setProgress] = useState(0);

  const [titleModal, setTitle] = useState(title || '');

  const [totalInventories, setTotalInventories] = useState();
  const [inventoriesProcessed, setInventoriesProcessed] = useState([]);

  const [skip, setSkip] = useState(0);

  useEffect(() => {
    loadInventoriesByBatch({
      storeIds,
      searchQueryParam,
      queryParams,
      inventoriesProcessed,
      columns,
      setLoading,
      setTitle,
      setProgress,
      setInventoriesProcessed,
      totalInventories,
      setTotalInventories,
      skip,
      setSkip,
      fileName,
      sheetName,
      currency,
      inventoryValidation,
      isCentralKitchenView,
    });
  }, [inventoriesProcessed]);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setLoading}
      titleModal={titleModal}
    />
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps)(InventoryExportListModal);
