import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { getTheme } from '@commons/utils/theme';
import { Label, ToggleSwitch } from '@commons/utils/styledLibraryComponents';

import {
  ContentContainer,
  MainContainer,
  Title,
  ToggleContainer,
  TogglesContainer,
} from './styledComponents';

export const ConfigureStoresMappingsModal = (props) => {
  const theme = getTheme();

  const { mapStoreData, configureStoresMappingsData, setConfigureStoresMappingsData } = props;

  const noStoreSelected = mapStoreData.length === 0;

  const { available, hasStock, hasDlc } = configureStoresMappingsData;

  return (
    <MainContainer>
      <ContentContainer>
        <Title>{i18next.t('GENERAL.PROPERTIES')}</Title>
        <TogglesContainer>
          <ToggleContainer>
            <ToggleSwitch
              checked={available}
              disabled={!!noStoreSelected}
              handleClick={() =>
                setConfigureStoresMappingsData({
                  ...configureStoresMappingsData,
                  available: !available,
                })
              }
              id={'setStoreAvailable'}
            />
            <Label background={theme.colors.greys.lightest} color={theme.colors.greys.darkest}>
              {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_PROPERTIES_AVAILABLE_ORDER')}
            </Label>
          </ToggleContainer>
          <ToggleContainer>
            <ToggleSwitch
              checked={hasStock}
              disabled={!!noStoreSelected}
              handleClick={() =>
                setConfigureStoresMappingsData({
                  ...configureStoresMappingsData,
                  hasStock: !hasStock,
                })
              }
              id={'setStoreHasStock'}
            />
            <Label background={theme.colors.greys.lightest} color={theme.colors.greys.darkest}>
              {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_PROPERTIES_INVENTORY_STOCKS')}
            </Label>
          </ToggleContainer>
          <ToggleContainer>
            <ToggleSwitch
              checked={hasDlc}
              disabled={!!noStoreSelected}
              handleClick={() =>
                setConfigureStoresMappingsData({
                  ...configureStoresMappingsData,
                  hasDlc: !hasDlc,
                })
              }
              id={'setStoreHasDlc'}
            />
            <Label background={theme.colors.greys.lightest} color={theme.colors.greys.darkest}>
              {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_PROPERTIES_INVENTORY_LOSS')}
            </Label>
          </ToggleContainer>
        </TogglesContainer>
      </ContentContainer>
    </MainContainer>
  );
};

ConfigureStoresMappingsModal.propTypes = {
  // Passed down from EnableSupplierProductModal
  configureStoresMappingsData: PropTypes.shape({
    available: PropTypes.bool,
    hasStock: PropTypes.bool,
    hasDlc: PropTypes.bool,
  }),
  setConfigureStoresMappingsData: PropTypes.func,
};
