import theme from '@commons/utils/theme';

const setElementStyle = (element, stylesObject) => {
  for (const property in stylesObject) {
    element.style[property] = stylesObject[property];
  }
};

const updatedTheme = theme.getTheme();

const font = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '10px',
  lineHeight: '10px',
};

const tableTitle = {
  width: '100%',
  paddingBottom: '16px',
};

const tableBody = {
  borderTop: `1px solid ${updatedTheme.colors.greys.light}`,
  borderBottom: `1px solid ${updatedTheme.colors.greys.light}`,
};

const tableRow = {
  borderBottom: `1px solid ${updatedTheme.colors.greys.light}`,
};

const tableCell = {
  height: '14px',
  display: 'flex',
  alignItems: 'center',
};

const tableLabel = {
  ...font,
  ...tableCell,
  color: updatedTheme.colors.greys.darkest,
  width: '120px',
};

const tableValue = {
  ...font,
  ...tableCell,
  color: updatedTheme.colors.greys.darker,
  width: '96px',
};

export default {
  setElementStyle,
  tableTitle,
  tableBody,
  tableRow,
  tableLabel,
  tableValue,
};
