import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { translateUnit } from '@commons/utils/translateUnit';
import DisplayNumber from '@commons/DisplayNumber';

export const getColumns = (translatedStoreName) => [
  {
    id: 0,
    name: translatedStoreName,
    propertyKey: 'storeName',
    large: true,
    isSortable: true,
    filterType: 'string',
  },
  {
    id: 1,
    name: i18next.t('BACKOFFICE.PRODUCTS.VALIDITY_START'),
    propertyKey: 'date',
    isSortable: true,
    filterType: 'date',
    render: (date) => moment(date).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
  },
  {
    id: 2,
    name: i18next.t('BACKOFFICE.PRODUCTS.VALIDITY_END'),
    propertyKey: 'validityEnd',
    isSortable: true,
    filterType: 'date',
    render: (validityEnd) =>
      !!validityEnd ? moment(validityEnd).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR) : '-',
  },
  {
    id: 4,
    name: i18next.t('GENERAL.COST'),
    propertyKey: 'cost',
    isNumber: true,
    isSortable: true,
    filterType: 'numeric',
    render: (cost) => <DisplayNumber displayCurrencyCode={true} number={cost} />,
  },
  {
    id: 6,
    name: i18next.t('GENERAL.UNIT'),
    propertyKey: 'unit',
    filterType: 'string',
    render: (unit) => translateUnit(unit),
  },
];
