import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Content = styled.div`
  width: 100%;
  position: relative;
  height: calc(100% - 135px);
`;

export const TopContainer = styled.div`
  width: 100%;
  height: 75px;
  padding: 0px 35px;

  display: flex;
  align-items: center;
  background: #ffffff;

  color: #000000;
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
`;

export const BottomContainer = styled.div`
  width: 100%;
  height: 75px;
  padding: 0px 35px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  bottom: 0px;
  position: absolute;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.08);

  > :nth-child(1) {
    margin-right: 15px;
  }
`;
