import { orderBy, head, get } from 'lodash';

const getSortKey = (item: object, orderByProperty: string) => {
  return typeof item[orderByProperty] === 'string'
    ? item[orderByProperty].toLowerCase()
    : item[orderByProperty];
};

export function sortListBy( // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[],
  orderByProperty: string,
  orderType: 'asc' | 'desc'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] {
  if (!orderByProperty) {
    return data;
  }

  if (get(head(data), 'nestedData', null)) {
    const withSortedNestedData = data.map((row) => {
      const sortedNestedData = orderBy(
        get(row, 'nestedData'),
        [
          orderByProperty,
          (item) => {
            return getSortKey(item, orderByProperty);
          },
        ],
        [orderType]
      );

      return { ...row, nestedData: sortedNestedData };
    });

    return orderBy(
      withSortedNestedData,
      [
        orderByProperty,
        (item) => {
          return getSortKey(item, orderByProperty);
        },
      ],
      [orderType]
    );
  }

  return orderBy(
    data,
    [
      orderByProperty,
      (item) => {
        return getSortKey(item, orderByProperty);
      },
    ],
    [orderType]
  );
}

export default sortListBy;
