/**
 * Given a context object, returns the read and write rights for the user on any supplier product.
 * The goal of this function is to be flexible and to be able to add more rights depending on the context object in the future.
 * Checks on whether the user is Inpulse or if the catalog object exist should be done before calling this function.
 *
 * @param {object} user
 * @param {uuid} supplierId - The supplier id of the supplier product
 * @param {boolean} isCreation - Is the user on the creation page
 * @return {{read: boolean, write: boolean}} - Can the user read or write about the supplier product
 */
export const catalogSupplierProductRights = (user, { supplierId, isCreation }) => {
  const userHasSupplierRights = user.catalog.supplierIds.includes(supplierId);

  return {
    read: !isCreation && user.catalog.isMasterCatalog && !userHasSupplierRights,
    write: !isCreation && userHasSupplierRights,
  };
};
