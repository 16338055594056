import { connect } from 'react-redux';
import _, { pick, sumBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import { getClientInfo } from '@selectors/client';

import { Button, Dropdown } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import ExportModalContent from '@lib/inpulse/ExportModal';

import {
  Container,
  Header,
  Content,
  Footer,
  DropdownContainer,
  LabelRequiredCharacter,
  PeriodDatePickerContainer,
  LabelContainer,
} from './styledComponents';
import { exportConsumptions } from './format';
import { getAnalysisByIngredient } from '@backoffice/BackOfficeGeneralContainer/BackOfficeRecipeDebugger/services';

const AGGREGATE_FIELDS = ['storeId', 'date', 'productName', 'channelName'];

export const ExportModal = (props) => {
  const {
    closeModal,
    params: {
      stores,
      defaultTimezone,
      storeName,
      currentSelectedStore,
      ingredients,
      currentSelectedIngredient,
      recipeByIngredientId,
      currentStartDate,
      currentEndDate,
      clientId,
    },
  } = props;
  const clientStoreName = getClientStoreNameTranslation(storeName, true);

  const yesterday = moment().subtract(1, 'days');
  const periodPickerState = usePeriodDatePickerState(currentStartDate, currentEndDate);

  // Stores selection step
  const [selectedStores, setSelectedStores] = useState([currentSelectedStore]);
  const [isSelectingStores, setIsSelectingStores] = useState(true);

  const [selectedIngredients, setSelectedIngredients] = useState([currentSelectedIngredient]);

  // Data retrieving step
  const [isLoading, setLoading] = useState(true);

  const [ingredientsConsumptions, setIngredientsConsumptions] = useState([]);
  const [countIngredientsFetched, setCountIngredientsFetched] = useState(0);

  // Dates states
  const [isSelectingDates, setIsSelectingDates] = useState(true);

  // Progress modal
  const [progress, setProgress] = useState(0);
  const [title, setTitle] = useState(
    i18next.t('ANALYSIS.SALES.INGREDIENT_CONSUMPTION_EXPORT_IN_PROGRESS'),
  );

  const updateProgress = () => {
    const updatedProgress = (countIngredientsFetched / selectedIngredients.length) * 100;

    setProgress(updatedProgress);
  };

  const exitModal = () => {
    setLoading(false);

    closeModal();
  };

  const exportReady = () => {
    setTitle(i18next.t('ADMIN.TRANSACTIONS.EXPORT_FINISHED'));
    setLoading(false);
  };

  const exportFailure = () => {
    setTitle(i18next.t('GENERAL.EXPORT_FAILURE'));
    setLoading(false);
  };

  const triggerExport = () => {
    if (!selectedStores.length || !periodPickerState.startDate || !periodPickerState.endDate) {
      return;
    }

    setIsSelectingStores(false);
    setIsSelectingDates(false);
  };

  const loadIngredientsConsumptions = async () => {
    const updatedIngredientsConsumptions = [...ingredientsConsumptions];

    // Perform request
    try {
      const selectedStoreIds = selectedStores.map(({ id }) => id);

      const analysisOfIngredient = await getAnalysisByIngredient(
        clientId,
        recipeByIngredientId[selectedIngredients[countIngredientsFetched].id],
        selectedStoreIds,
        moment(periodPickerState.startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        moment(periodPickerState.endDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        selectedIngredients[countIngredientsFetched].id,
      );

      const aggregatedAnalysis = _(analysisOfIngredient)
        .groupBy((analysisRow) => JSON.stringify(pick(analysisRow, AGGREGATE_FIELDS)))
        .map((analysisGroup, keys) => {
          // The groupBy will always return an array of at least one item, hence the [0]
          // We can assume that the first item is the one we want for the ingredient name and others since it's an aggregation
          const { ingredientName, storeName, recipeQuantity, ingredientUnit } = analysisGroup[0];

          const totalConsumption = analysisGroup.reduce((acc, { quantitySold, recipeQuantity }) => {
            if (!!recipeQuantity) {
              acc += quantitySold * recipeQuantity;
            }
            return acc;
          }, 0);

          return {
            ...JSON.parse(keys),
            recipeQuantity: recipeQuantity || 0,
            ingredientUnit,
            quantitySold: sumBy(analysisGroup, 'quantitySold'),
            totalConsumption,
            ingredientName,
            storeName,
          };
        })
        .value();

      updatedIngredientsConsumptions.push(...aggregatedAnalysis);

      setIngredientsConsumptions(updatedIngredientsConsumptions);

      setCountIngredientsFetched(countIngredientsFetched + 1);
    } catch {
      setProgress(100);

      exportFailure();
    }
  };

  useEffect(() => {
    if (isSelectingStores || isSelectingDates) {
      return;
    }

    if (countIngredientsFetched >= selectedIngredients.length) {
      setProgress(100);

      exportConsumptions(
        ingredientsConsumptions,
        periodPickerState.startDate,
        periodPickerState.endDate,
        clientStoreName,
      );

      return exportReady();
    }

    updateProgress();

    loadIngredientsConsumptions();
  }, [isSelectingStores, isSelectingDates, countIngredientsFetched]);

  return (
    <div className={props.className}>
      {isSelectingStores && isSelectingDates && (
        <Container>
          <Header>
            <img height={16} src={'/images/inpulse/file-download-black-small.svg'} width={16} />
            <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.H1}>
              {i18next.t('ANALYSIS.SALES.INGREDIENT_CONSUMPTION_EXPORT_MODAL_TITLE')}
            </Text>
            <img
              height={16}
              src={'/images/inpulse/close-black-small.svg'}
              width={16}
              onClick={closeModal}
            />
          </Header>
          <Content>
            <DropdownContainer>
              <Dropdown
                customListStyle={{
                  position: 'fixed',
                  top: 'inherit',
                  left: 'inherit',
                }}
                iconSrc={
                  selectedStores.length
                    ? '/images/inpulse/pin-black-small.svg'
                    : '/images/inpulse/pin-dmgrey-small.svg'
                }
                isUniqueSelection={false}
                items={stores}
                label={`${clientStoreName} :`}
                selectedItems={selectedStores}
                isRequired
                onSelectionChange={setSelectedStores}
              />
            </DropdownContainer>
            <DropdownContainer>
              <Dropdown
                customListStyle={{
                  position: 'fixed',
                  top: 'inherit',
                  left: 'inherit',
                }}
                isUniqueSelection={false}
                items={ingredients}
                label={`${i18next.t('GENERAL.INGREDIENT_PLURAL')} :`}
                selectedItems={selectedIngredients}
                isRequired
                onSelectionChange={setSelectedIngredients}
              />
            </DropdownContainer>
            <PeriodDatePickerContainer>
              <LabelContainer>
                <LabelRequiredCharacter>*</LabelRequiredCharacter>
                <Text
                  color={ENUM_COLORS.DARKEST}
                  font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {`${i18next.t('GENERAL.EXPORT_PERIOD')} :`}
                </Text>
              </LabelContainer>
              <PeriodDatePicker
                customStyle={{ position: 'absolute', marginTop: '28px' }} // for the list to appear over the modal (instead of inside)
                disabled={false}
                endDate={periodPickerState.endDate}
                focusedDateInput={periodPickerState.focusedDateInput}
                maxFutureDate={yesterday}
                setFocusedDateInput={periodPickerState.setFocusedDateInput}
                startDate={periodPickerState.startDate}
                timezone={defaultTimezone}
                onDatesChange={periodPickerState.handleSelectedDates}
              />
            </PeriodDatePickerContainer>
          </Content>
          <Footer>
            <Button
              color={'inpulse-outline'}
              handleClick={exitModal}
              icon={'/images/inpulse/close-black-small.svg'}
              label={i18next.t('GENERAL.CANCEL')}
            />
            <Button
              color={'inpulse-default'}
              handleClick={triggerExport}
              icon={'/images/inpulse/file-download-white-small.svg'}
              isDisabled={!selectedStores.length}
              label={i18next.t('GENERAL.EXPORT')}
            />
          </Footer>
        </Container>
      )}

      {!isSelectingStores && !isSelectingDates && (
        <ExportModalContent
          {...props}
          closeModal={closeModal}
          exitModal={exitModal}
          isLoading={isLoading}
          progress={progress}
          setLoading={setLoading}
          titleModal={title}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

export default connect(mapStateToProps)(ExportModal);
