import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { openGenericModal, refreshGenericModal } from '@actions/modal';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getIsCentralMode } from '@commons/utils/localStorage';

import { getCentralKitchenStores, getSalesPointStores } from '@selectors/stores';
import { getClientInfo } from '@selectors/client';

import { getExportLossesModalConfig } from './modalConfiguration';
import { LOSS_TYPE } from '../constants';

const ExportLossesModal = (props) => {
  const {
    lossType,
    resetExportingLosses,
    handleExport,
    openGenericModal,
    refreshGenericModal,
    client: { storeName },
    stores,
    modal,
  } = props;

  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  const [translatedStoreName, setTranslatedStoreName] = useState(null);

  useEffect(() => {
    const storeNameTranslation = getClientStoreNameTranslation(storeName, true);
    setTranslatedStoreName(storeNameTranslation);
  }, []);

  useEffect(() => {
    const isModalOpened = _.get(modal, 'GenericModalBool', false);

    const params = getExportLossesModalConfig({
      lossType,
      resetExportingLosses,
      handleExport,
      stores,
      selectedStores,
      setSelectedStores,
      selectedDateRange,
      setSelectedDateRange,
      translatedStoreName,
    });

    if (isModalOpened) {
      refreshGenericModal(params);
      return;
    }

    openGenericModal(params);
  }, [selectedStores, selectedDateRange, translatedStoreName]);

  return <div />;
};

const mapStateToProps = (state) => ({
  stores: getIsCentralMode()
    ? getCentralKitchenStores(state.baseReducer.activeStores)
    : getSalesPointStores(state.baseReducer.activeStores),
  modal: state.modalReducer,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
});

ExportLossesModal.propTypes = {
  lossType: PropTypes.oneOf(Object.values(LOSS_TYPE)), // passed by LossListView.js
  resetExportingLosses: PropTypes.func, // passed by LossListView.js, to reset its state
  handleExport: PropTypes.func, // passed by LossListView.js, to perform the API call
  openGenericModal: PropTypes.func, // passed by redux
  refreshGenericModal: PropTypes.func, // passed by redux
  stores: PropTypes.array, // passed by redux
  modal: PropTypes.array, // passed by redux
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportLossesModal);
