import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding: 30px;
  background: ${(props) => props.theme.colors?.greys.lightest};
  box-shadow: ${(props) => props.theme.shadows?.short};
  border-radius: ${(props) => props.theme.borders?.radius.strong};
  margin-bottom: 215px;
  min-height: 285px;
`;

export const ContainerHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ContainerContent = styled.div`
  width: 100%;
`;

export const TitleHeader = styled.div`
  color: ${(props) => props.theme.colors?.greys.darkest};
  font: ${(props) => props.theme.fonts.h2};
  width: fit-content;
  padding-right: 14px;
  text-align: left;
`;

export const NumberHeader = styled.div`
  color: ${(props) => props.theme.colors?.greys.lightest};
  width: 24px;
  height: 24px;
  font: ${(props) => props.theme.fonts.textSmallHeight14Weight600};
  padding: 6px 15px 6px 15px;
  border-radius: ${(props) => props.theme.borders?.radius.weaker};
  background: ${(props) => props.theme.colors?.greys.darkest};

  align-items: center;
  display: flex;
  justify-content: center;
`;

export const EmptyState = styled.div`
  width: 100%;
  height: 100%;
`;
