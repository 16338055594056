import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import utilsXLS from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { ingredient as ingredientService } from '@services/ingredient';

const DEFAULT_TITLE_EXPORT_FAILURE = i18next.t('GENERAL.EXPORT_FAILURE');
const DEFAULT_TITLE_EXPORT_READY = i18next.t('GENERAL.EXPORT_SUCCESS');
const BATCH_INGREDIENTS_TO_FETCH_AT_ONCE = 50;

const FILENAME = i18next.t('GENERAL.INGREDIENT_PLURAL');
const SHEET_NAME = i18next.t('GENERAL.INGREDIENT_PLURAL');

const getColumnsSettings = () => [
  {
    propertyKey: 'name',
    name: i18next.t('ADMIN.INGREDIENTS.EXPORT_LABEL_NAME'),
  },
  {
    propertyKey: 'status',
    name: i18next.t('GENERAL.STATUS'),
  },
  {
    propertyKey: 'category',
    name: i18next.t('ADMIN.INGREDIENTS.EXPORT_LABEL_CATEGORY'),
  },
  {
    propertyKey: 'unit',
    name: i18next.t('ADMIN.INGREDIENTS.EXPORT_LABEL_UNIT'),
  },
  {
    type: 'currency',
    propertyKey: 'cost',
    name: i18next.t('ADMIN.RECIPES.EXPORT_COLUMN_COST'),
  },
  {
    propertyKey: 'supplierProductNumber',
    name: i18next.t('ADMIN.INGREDIENTS.EXPORT_LABEL_SUPPLIER_PRODUCTS'),
  },
  {
    propertyKey: 'allergens',
    name: i18next.t('ADMIN.INGREDIENTS.EXPORT_LABEL_ALLERGENS'),
  },
];

/**
 * Format a given ingredient,  make sure to get all information that would be necessary
 * for generating the export file
 *
 * @param {Object} ingredient - The ingredient to format
 *
 * @return {Object[]} The list of formatted data from the given ingredient
 */
export function formatDataForXLSFile(ingredient) {
  const supplierProductNumber = ingredient.supplierProducts.length;
  const allergens = ingredient.allergens.map((allergen) => allergen.name).join(', ');
  const status = ingredient.active ? i18next.t('GENERAL.ACTIVE') : i18next.t('GENERAL.INACTIVE');

  const unit =
    ingredient.unit === 'unit'
      ? 'Unité'
      : ingredient.unit === 'g'
      ? 'Kg'
      : ingredient.unit === 'mL'
      ? 'L'
      : ingredient.unit;

  return {
    ...ingredient,
    unit,
    supplierProductNumber,
    allergens,
    status,
  };
}

/**
 * Update the progress value from the elements that are still left to treat
 *
 * @param {String[]} ingredientIds           - The list of ingredient ids that are still to be treated
 * @param {Object[]} detailedIngredientList  - The list of ingredient that has already been treated
 * @param {Function} setProgress          - Method to set the local state of the progress value
 *
 * @return {void}
 */
export function updateProgress(ingredientIds, detailedIngredientList, setProgress) {
  const ingredientLeft = ingredientIds.length;
  const totalIngredients = ingredientLeft + detailedIngredientList.length;
  const updatedProgress = 100 - (ingredientLeft / totalIngredients) * 100;

  setProgress(updatedProgress);
}

/**
 * Handle the progressive loading of ingredients from the list of ingredientIds that are still to fetch
 *
 * @returns {void}
 */
export async function loadProductsByBatchOfIds(
  ingredientIds,
  detailedIngredientList,
  setLoading,
  setTitle,
  setProgress,
  setIngredientIds,
  setDetailedIngredientList,
  currency,
) {
  if (!ingredientIds.length && !detailedIngredientList.length) {
    return;
  }

  // If no more ids, then all data has been fetched
  if (!ingredientIds.length) {
    setProgress(100);

    const columns = getColumnsSettings();

    const sheet = utilsXLS.generateDefaultSheet(
      SHEET_NAME,
      columns,
      detailedIngredientList,
      currency,
    );

    utilsXLS.makeXLS(FILENAME, [sheet]);

    return exportReady(setLoading, setTitle);
  }

  const selectedIngredientIds = ingredientIds.splice(0, BATCH_INGREDIENTS_TO_FETCH_AT_ONCE);

  // Perform request
  try {
    const ingredientsExportData = await ingredientService.getIngredientByIds(selectedIngredientIds);

    let ingredientItemsList = [...detailedIngredientList];

    // Format data
    for (const data of ingredientsExportData) {
      ingredientItemsList = ingredientItemsList.concat(formatDataForXLSFile(data, currency));
    }

    setDetailedIngredientList(ingredientItemsList);

    updateProgress(ingredientIds, ingredientItemsList, setProgress);

    setIngredientIds(ingredientIds);
  } catch (err) {
    exportFailure(setLoading, setTitle);
  }
}

/**
 * Close the modal
 *
 * @returns {void}
 */
export function exitModal(setLoading, closeModal) {
  setLoading(false);

  closeModal();
}

/**
 * Set component with export success state
 *
 * @returns {void}
 */
export function exportReady(setLoading, setTitle) {
  setTitle(DEFAULT_TITLE_EXPORT_READY);
  setLoading(false);
}

/**
 * Set component with export failure state
 *
 * @returns {void}
 */
export function exportFailure(setLoading, setTitle) {
  setTitle(DEFAULT_TITLE_EXPORT_FAILURE);
  setLoading(false);
}

export const IngredientExportModal = (props) => {
  const {
    params: { title, ingredients },
    closeModal,
    currency,
  } = props;

  const [progress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [titleModal, setTitle] = useState(title || '');
  const [ingredientIdsToProcess, setIngredientIds] = useState([]);
  const [detailedIngredientList, setDetailedIngredientList] = useState([]);

  useEffect(() => {
    const ingredientIds = ingredients.map((ingredient) => ingredient.id);

    setIngredientIds(ingredientIds);
  }, [ingredients]);

  useEffect(() => {
    loadProductsByBatchOfIds(
      [...ingredientIdsToProcess],
      detailedIngredientList,
      setLoading,
      setTitle,
      setProgress,
      setIngredientIds,
      setDetailedIngredientList,
      currency,
    );
  }, [ingredientIdsToProcess]);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setLoading}
      titleModal={titleModal}
    />
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps)(IngredientExportModal);
