import { areSPAndIngredientSameUnit } from '@commons/utils/units';

/**
 * If conversions exist for ordered supplier product, apply them before computing unit turn over
 *
 * @param {number} packagingValue
 * @param {string} packagingUnit
 * @param {object[]} conversions
 * @param {string} entityUnit

 * @returns {number}
 */
const getConvertedPackagingValue = (product) => {
  const { packagingValue, packagingUnit, conversions, entityStockEvent } = product;

  // If SP is not linked to an entity, has nos conversions, or entity unit and SP unit are similar, simply return packaging value
  if (
    !entityStockEvent ||
    !entityStockEvent.entity ||
    !conversions.length ||
    areSPAndIngredientSameUnit(packagingUnit, entityStockEvent.entity.unit)
  ) {
    return packagingValue;
  }

  const baseUnitConversion = conversions.find(({ convertedUnit }) =>
    areSPAndIngredientSameUnit(convertedUnit, entityStockEvent.entity.unit),
  );

  // Whereas the packaging unit is always in the same unit system as the conversions
  const packagingUnitConversion = conversions.find(
    ({ convertedUnit }) => convertedUnit === packagingUnit,
  );

  if (!baseUnitConversion || !packagingUnitConversion) {
    return packagingValue;
  }

  return (
    (packagingValue * baseUnitConversion.convertedQuantity) /
    packagingUnitConversion.convertedQuantity
  );
};

export default getConvertedPackagingValue;
