import i18next from 'i18next';

import { ConfirmationModal } from '@commons/Modals/ConfirmationModal';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

export const getDisableSupplierProductMappingModalParams = (
  supplierProduct,
  ingredient,
  mappedIngredient,
  onIngredientChange,
  handleSupplierProductMapping,
) => ({
  type: 'warning',
  width: '542px',
  height: 'auto',
  icon: '/images/inpulse/warning-white-small.svg',
  title: i18next.t('ADMIN.INGREDIENTS.DELETE_SUPPLIER_PRODUCT_MAPPING_MODAL_TITLE'),
  component: ConfirmationModal,
  data: {
    content: i18next.t('ADMIN.INGREDIENTS.DELETE_SUPPLIER_PRODUCT_MAPPING_MODAL_CONTENT', {
      supplierProductName: supplierProduct.name,
      ingredientName: mappedIngredient.name,
    }),
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_CONFIRM_BUTTON(),
      label: i18next.t('GENERAL.VALIDATE'),
      handleClick: () => {
        handleSupplierProductMapping(ingredient, onIngredientChange, supplierProduct);
      },
    },
  ],
});
