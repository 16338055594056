import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';
import React from 'react';

import LightbulbIcon from '@assets/icons/icon-lightbulb.svg';

import { period } from '@src/routes/prod/constants/period';

import { Container, Text, Icon, DateText, TextContainer } from './styledComponents';

const TIME_FORMAT = 'HH:mm:ss';
const DATE_FORMAT = 'DD/MM/YYYY';

/**
 * Get the formatted start time associated to the selected timeslot
 *
 * @param {Timeslot} selectedTimeSlot - The selected timeslot
 *
 * @returns {String} The formatted start time
 */
export function getStartTime(selectedTimeSlot) {
  if (selectedTimeSlot) {
    const startHour = moment
      .utc(selectedTimeSlot.startHour, TIME_FORMAT)
      .format(`H[${i18next.t('GENERAL.DATE_TIME_FORMAT_HOUR_SEPARATOR')}]mm`);

    return `${moment(selectedTimeSlot.startTime).format(DATE_FORMAT)} à ${startHour}.`;
  }

  return '';
}

/**
 * Get potential warning message from the use case on which the production planning is being displayed,
 * the selected date and the selected timeslot
 *
 * @param {Props} props - The props linked to the component
 *
 * @returns {String|undefined} The potential warning message
 */
export function getWarningText(props, t) {
  const { useCase, selectedTimeSlot } = props;

  if (useCase === period.BEFORE) {
    return t('PRODUCTION.PRODUCTION.HEADER_TOOLTIP_END_PRODUCTION');
  }

  if (useCase === period.AFTER) {
    return `${t('PRODUCTION.PRODUCTION.HEADER_TOOLTIP_BEGIN_PRODUCTION')} ${getStartTime(
      selectedTimeSlot,
    )}`;
  }
}

export const InfoBox = (props) => {
  const { t } = useTranslation();
  const text = getWarningText(props, t);

  if (!text) {
    return null;
  }

  return (
    <Container>
      <Icon>
        <img alt="" src={LightbulbIcon} />
      </Icon>

      {props.useCase === period.AFTER ? (
        <TextContainer>
          <Text>{t('PRODUCTION.PRODUCTION.HEADER_TOOLTIP_BEGIN_PRODUCTION')}:</Text>
          <DateText>{getStartTime(props.selectedTimeSlot)}</DateText>
        </TextContainer>
      ) : (
        <Text>{t('PRODUCTION.PRODUCTION.HEADER_TOOLTIP_END_PRODUCTION')}</Text>
      )}
    </Container>
  );
};

export default InfoBox;
