import i18next from 'i18next';
import moment from 'moment-timezone';

import { formatHourRangeName } from '../utils/formatHourRangeName';

import { productionRanges as productionRangesService } from '../../../services/productionRanges';

export function buildDateWithTime(date, time, timezone) {
  const formattedTime = moment(time, 'hh:mm:ss').tz(timezone);

  const baseHour = moment().tz(timezone).set({
    year: date.year(),
    month: date.month(),
    date: date.date(),
  });

  return moment(baseHour).tz(timezone).set({
    hour: formattedTime.hour(),
    minute: formattedTime.minute(),
    second: formattedTime.second(),
  });
}

/**
 * Handle the fetch of the production ranges from a given storeId and a date
 *
 * @param {Object} store          - The id of the store on which retrieve production ranges
 * @param {Date} date               - The date on which we should match production ranges
 * @param {String} userLanguageCode - The user language code
 * @param {Function} showMessage    - The method to display message relating to the request status
 *
 * @returns {Promise<[]>} The formatted list of ranges
 */
export async function fetchRanges(store, date, userLanguageCode, showMessage) {
  try {
    const dateWithTimezone = moment(date).tz(store.timezone);
    const productionRanges = await productionRangesService.getProductionRangesOfStore(
      store.storeId,
      dateWithTimezone.format(),
    );

    return productionRanges.map((hourRange) => {
      const splitStartHour = hourRange.startHour.split(':');
      const formattedStartHour = moment(date).set({
        hour: splitStartHour[0],
        minute: splitStartHour[1],
        second: splitStartHour[2],
      });
      const splitEndHour = hourRange.endHour.split(':');
      const formattedEndHour = moment(date).set({
        hour: splitEndHour[0],
        minute: splitEndHour[1],
        second: splitEndHour[2],
      });

      return {
        ...hourRange,
        name: formatHourRangeName(formattedStartHour, formattedEndHour, userLanguageCode),
        startTime: formattedStartHour,
        endTime: formattedEndHour,
      };
    });
  } catch (err) {
    if (showMessage) {
      showMessage(i18next.t('PRODUCTION.PRODUCTION.TOP_ERROR_MESSAGE_HOUR_RANGES'), 'error');
    }

    return [];
  }
}

export default {
  fetchRanges,
  buildDateWithTime,
};
