import { connect } from 'react-redux';
import { head, get, sumBy } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openSmallModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { Dropdown, NestedList } from '@commons/utils/styledLibraryComponents';
import { ExportButton } from '@commons/ExportButton';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getPreviousDayOfStore } from '@commons/utils/date';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import { SingleDatePicker } from '@commons/DatePickers/SingleDatePicker';
import DisplayNumber from '@commons/DisplayNumber';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { usePaginatorState } from '@hooks/usePaginatorState';

import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import storeService from '@services/store';

import {
  Container,
  FiltersContainer,
  FlexedGapContainer,
  TurnoverContainer,
  TurnoverLabel,
  Kpi,
  ContentContainer,
} from './styledComponents';
import columnsUtils from './utils/columnsUtils';
import ExportModal from './utils/exportModal';
import formatUtils from './utils/formatUtils';

const Transactions = (props) => {
  const {
    match: { path },
    client: { storeName },
    currency,
    user,
    stores,
    openModalExportInfo,
  } = props;

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');
  const clientStoreName = getClientStoreNameTranslation(storeName, false).toLowerCase();

  const [isLoading, setIsLoading] = useState(true);
  const [headers] = useState(columnsUtils.getListColumns());
  const [turnoverWithTax, setTurnoverWithTax] = useState(null);
  const [turnoverWithoutTax, setTurnoverWithoutTax] = useState(null);

  const [selectedStore, setSelectedStore] = useState(head(stores));
  const yesterday = getPreviousDayOfStore(selectedStore);
  const [selectedDate, setSelectedDate] = useState(yesterday);
  const [transactions, setTransactions] = useState([]);

  const paginatorState = usePaginatorState();

  /** USE EFFECT */

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const transactions = await storeService.getStoreTransactions(
          selectedStore.id,
          selectedDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        );

        setTurnoverWithTax(sumBy(transactions, 'turnover'));
        setTurnoverWithoutTax(sumBy(transactions, 'turnoverTaxExcluded'));

        const formattedNestedList = formatUtils.formatTransactionsForNestedList(transactions);

        setTransactions(formattedNestedList);
      } catch {
        showErrorMessage(i18next.t('ADMIN.TRANSACTIONS.FETCHING_ERROR'));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [selectedStore.id, selectedDate]);

  const handleTransactionExport = () => {
    openModalExportInfo({
      component: ExportModal,
      customStyle: {
        overflow: 'initial',
      },
      stores,
      currency,
      storeName,
    });
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ContentContainer>
        <FiltersContainer>
          <LeftRightSplitter
            left={
              <div>
                <FlexedGapContainer>
                  <Dropdown
                    customStyle={{ position: 'inherit' }}
                    iconSrc={'/images/inpulse/pin-black-small.svg'}
                    isDisabled={isLoading}
                    items={stores}
                    searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                    selectedItem={selectedStore}
                    isRequired
                    onSelectionChange={(selectedItem) => setSelectedStore(selectedItem)}
                  />
                  <SingleDatePicker
                    customStyle={{ position: 'inherit' }}
                    date={selectedDate}
                    disabled={isLoading}
                    maxFutureDate={yesterday}
                    onDateChange={(date) => setSelectedDate(date)}
                  />
                  <TurnoverContainer>
                    <TurnoverLabel>{i18next.t('GENERAL.TURNOVER_TAX_INCLUDED')}</TurnoverLabel>
                    <Kpi>
                      {isLoading ? (
                        <DeepsightComponentLoader height={16} width={16} />
                      ) : (
                        <DisplayNumber
                          number={turnoverWithTax}
                          displayCurrencyCode
                          withoutDecimals
                        />
                      )}
                    </Kpi>
                  </TurnoverContainer>
                  <TurnoverContainer>
                    <TurnoverLabel>{i18next.t('GENERAL.TURNOVER_EX_TAX')}</TurnoverLabel>
                    <Kpi>
                      {isLoading ? (
                        <DeepsightComponentLoader height={16} width={16} />
                      ) : (
                        <DisplayNumber
                          number={turnoverWithoutTax}
                          displayCurrencyCode
                          withoutDecimals
                        />
                      )}
                    </Kpi>
                  </TurnoverContainer>
                </FlexedGapContainer>
              </div>
            }
            right={
              <ExportButton
                handleClick={handleTransactionExport}
                isDisabled={isLoading}
                isInpulseOnly
              />
            }
          />
        </FiltersContainer>

        <NestedList
          currentPage={paginatorState.currentPage}
          customNestedContentPadding={'8px'}
          data={transactions}
          defaultOrderBy={'date'}
          defaultOrderType={'desc'}
          headers={headers}
          iconEmptyState="/images/inpulse/empty-state-no-result.svg"
          iconSizeEmptyState="160px"
          isLoading={isLoading}
          itemsCount={transactions.length}
          labelEmptyState={i18next.t('ADMIN.TRANSACTIONS.TRANSACTIONS_EMPTY_STATE', {
            storeName: clientStoreName,
          })}
          languageCode={userLanguageCode}
          maxPerPage={paginatorState.maxPerPage}
          maxPerPageOptions={paginatorState.maxPerPageOptions}
          setCurrentPage={paginatorState.setCurrentPage}
          setMaxPerPage={paginatorState.setMaxPerPage}
          hasPagination
        />
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
