import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Container, InputContainer } from '@commons/Modals/InputContentModal/styledComponents';
import { Dropdown } from '@commons/utils/styledLibraryComponents';

import DateRangeSelectionModal from '@backoffice/components/DateRangeSelectionModal';

export const ANALYTICS_TYPE = {
  ORDER: 'ORDER',
  PRODUCTION: 'PRODUCTION',
};

const SelectionModal = (props) => {
  const { storeName, stores, selectedStores, setDateRange, onModalSelectionChange } = props;

  return (
    <>
      <Container>
        <InputContainer>
          <Dropdown
            customListStyle={{
              position: 'fixed',
              top: 'inherit',
              left: 'inherit',
            }}
            iconSrc={
              selectedStores.length
                ? '/images/inpulse/pin-black-small.svg'
                : '/images/inpulse/pin-dmgrey-small.svg'
            }
            isRequired={true}
            isUniqueSelection={false}
            items={stores}
            label={storeName}
            selectedItems={selectedStores}
            tooltipText={i18next.t('BACKOFFICE.ANALYTICS.RELAUNCH_MODAL_STORES_TOOLTIP')}
            onSelectionChange={(selectedItems) => onModalSelectionChange('stores', selectedItems)}
          />
        </InputContainer>
      </Container>
      <Container style={{ marginTop: '54px' }}>
        <InputContainer>
          <DateRangeSelectionModal
            labelText={i18next.t('BACKOFFICE.ANALYTICS.RELAUNCH_MODAL_RANGE_DATE_TEXT')}
            setDateRange={setDateRange}
            timezone={'Europe/Paris'} // TODO - [TIMEZONES]
            tooltipText={i18next.t('BACKOFFICE.ANALYTICS.RELAUNCH_MODAL_RANGE_DATE_TOOLTIP')}
          />
        </InputContainer>
      </Container>
    </>
  );
};

export const getRelaunchAnalyticsModalParams = ({
  type,
  stores,
  storeName,
  handleSave,
  setDateRange,
  selectedStores,
  dateRange = {},
  setSelectedStores,
}) => {
  /**
   * Check whether the form is valid
   *
   * @returns {Boolean} Whether the form is valid
   */
  const isFormDisabled = () => {
    // Check type of analytics to relaunch
    if (!type || !Object.values(ANALYTICS_TYPE).includes(type)) {
      return true;
    }

    // Check if some stores are selected
    if (isEmpty(selectedStores)) {
      return true;
    }

    // Check if range of dates is properly set
    return !get(dateRange, 'endDate') || !get(dateRange, 'startDate');
  };

  /**
   * Handle the update of selected values in the modal
   *
   * @param {uuid} clientId - The client id on which retrieve stores
   *
   * @returns {Promise<Store[]>} The list of stores associated to the client
   */
  const onModalSelectionChange = (property, values) => {
    if (property === 'stores') {
      setSelectedStores(values);

      return;
    }
  };

  /**
   * Reset Relaunch Analytics states being used in form
   *
   * @returns {void}
   */
  const resetForm = async () => {
    setDateRange(null);
    setSelectedStores([]);
  };

  const activeStores = stores.filter(({ active }) => active);

  return {
    type: 'action',
    width: '542px',
    height: 'auto',
    icon: '/images/inpulse/sync-black.svg',
    title:
      type === ANALYTICS_TYPE.PRODUCTION
        ? i18next.t('BACKOFFICE.ANALYTICS.RELAUNCH_PRODUCTION_MODAL_TITLE')
        : i18next.t('BACKOFFICE.ANALYTICS.RELAUNCH_ORDER_MODAL_TITLE'),
    component: SelectionModal,
    data: {
      type,
      stores: activeStores,
      storeName,
      setDateRange,
      selectedStores,
      onModalSelectionChange,
    },
    handleCloseCleanUp: resetForm,
    actions: [
      {
        key: 0,
        color: 'inpulse-outline',
        label: i18next.t('GENERAL.CANCEL'),
        icon: '/images/inpulse/close-black-small.svg',
      },
      {
        key: 1,
        color: 'inpulse-default',
        label: i18next.t('BACKOFFICE.ANALYTICS.RELAUNCH_MODAL_RELAUNCH_BUTTON'),
        icon: '/images/inpulse/save-white-small.svg',
        handleClick: () => handleSave(type),
        isDisabled: isFormDisabled(),
      },
    ],
  };
};

export default {
  ANALYTICS_TYPE,
  getRelaunchAnalyticsModalParams,
};
