import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { Container, ToggleSection } from './styledComponents';
import { Props } from './interfaces';
import { getTheme } from '../utils/theme';

const ToggleButton = (props: Props): JSX.Element => {
  const {
    theme,
    choices,
    customStyle,
    selectedChoice,
    setSelectedChoice,
    isDisabled,
  } = props;

  return (
    <ThemeProvider theme={getTheme(theme, 'toggleButton')}>
      <Container>
        {choices.map((choice) => {
          const isSelected = !isDisabled && choice.key === selectedChoice.key;

          return (
            <ToggleSection
              className={isSelected ? 'active' : ''}
              isDisabled={isDisabled}
              style={customStyle}
              key={choice.key}
              onClick={() => setSelectedChoice(choice)}
            >
              <span>{choice.name}</span>
            </ToggleSection>
          );
        })}
      </Container>
    </ThemeProvider>
  );
};

ToggleButton.propTypes = {
  isDisabled: PropTypes.bool,
  selectedChoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  setSelectedChoice: PropTypes.func,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  customStyle: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.objectOf(PropTypes.any),
};

ToggleButton.defaultProps = {
  theme: null,
  selectedChoice: null,
  setSelectedChoice: () => {},
  choices: [],
  customStyle: {},
  isDisabled: false,
};

export default ToggleButton;
