const FORBIDDEN_SUBCATEGORIES = [
  //DEEPSIGHT RESERVED CATEGORIES
  '6743c5ea-4376-11e9-9c73-0af6991d48ca',
  '708db7dc-4376-11e9-9c74-0af6991d48ca',
];

const NATIONAL_EVENTS_CATEGORY_ID = [
  'fb9febda-4374-11e9-8441-0af6991d48ca',
  '3a5d88cc-4376-11e9-9c72-0af6991d48ca',
];

const sortEvents = (eventsArray) => {
  eventsArray.sort((a, b) => {
    if (FORBIDDEN_SUBCATEGORIES.includes(b.eventSubCategoryId)) {
      if (FORBIDDEN_SUBCATEGORIES.includes(a.eventSubCategoryId)) {
        const aName = a.eventSubCategory.name + a.input1;
        const bName = b.eventSubCategory.name + b.input1;
        const alphComp = bName.localeCompare(aName);
        if (alphComp === 0) {
          return b.id.localeCompare(a.id);
        }
        return alphComp;
      } else {
        return 1;
      }
    } else if (NATIONAL_EVENTS_CATEGORY_ID.includes(b.eventCategoryId)) {
      if (NATIONAL_EVENTS_CATEGORY_ID.includes(a.eventCategoryId)) {
        const aName = a.eventSubCategory.name + a.input1;
        const bName = b.eventSubCategory.name + b.input1;
        const alphComp = bName.localeCompare(aName);
        if (alphComp === 0) {
          return b.id.localeCompare(a.id);
        }
        return alphComp;
      } else {
        return 1;
      }
    } else {
      const aName = a.eventSubCategory.name + a.input1;
      const bName = b.eventSubCategory.name + b.input1;
      const alphComp = bName.localeCompare(aName);
      if (alphComp === 0) {
        return b.id.localeCompare(a.id);
      }
      return alphComp;
    }
  });
  return eventsArray;
};

export default {
  sortEvents,
};
