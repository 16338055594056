import { connect } from 'react-redux';
import { countBy, get } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import { ConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import useLocalStorage from '@hooks/useLocalStorage';

import { getClientInfo } from '@selectors/client';

import clientService from '@services/client';

import { ListViewContainer } from './styledComponents';
import actionsUtils from './utils/actionsUtils';
import columnsUtils from './utils/columnsUtils';
import exportUtils from './utils/exportUtils';

const InventoryListTemplates = (props) => {
  const {
    match: { path },
    client: { clientId },
    showErrorMessage,
    showSuccessMessage,
    history,
    pageLoading,
    pageLoaded,
    openGenericModal,
  } = props;

  const [isInCentralMode] = useLocalStorage('isCentralMode', false);

  const [isLoading, setIsLoading] = useState(false);
  const [displayEmptyState, setDisplayEmptyState] = useState(false);

  const [inventoryListTemplates, setInventoryListTemplates] = useState([]);
  const [selectedILTs, setSelectedILTs] = useState([]);

  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [columns] = useState(columnsUtils.getListColumns());

  useEffect(() => {
    (async () => {
      await fetchInventoryTemplates();
    })();
  }, []);

  useEffect(() => {
    setActions(
      actionsUtils.getGlobalActions(
        goToCreateILT,
        selectedILTs,
        inventoryListTemplates,
        handleDeletion,
        handleExport,
      ),
    );

    setRowActions(actionsUtils.getRowActions(handleDeletion));
  }, [inventoryListTemplates, selectedILTs]);

  const fetchInventoryTemplates = async () => {
    pageLoading();
    setIsLoading(true);

    try {
      const clientILTs = await clientService.getInventoryListTemplates(clientId, {
        withMappings: true,
        filterByUserCatalog: true,
      });

      const formattedILTs = clientILTs.map((mapping) => {
        const mappedRecipesCounts = countBy(mapping.entities, (recipe) =>
          recipe.isKitchen ? 'kitchenRecipesCount' : 'salesPointRecipesCount',
        );

        return {
          ...mapping,
          associatedSPsCount: get(mapping, 'supplierProducts.length', 0),
          associatedRecipesCount: get(
            mappedRecipesCounts,
            isInCentralMode ? 'kitchenRecipesCount' : 'salesPointRecipesCount',
            0,
          ),
        };
      });

      setInventoryListTemplates(formattedILTs);
      setDisplayEmptyState(!formattedILTs.length);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FETCH_INVENTORY_LIST_TEMPLATES_ERROR'));
      setDisplayEmptyState(true);
    } finally {
      pageLoaded();
      setIsLoading(false);
    }
  };

  const deleteInventoryListTemplates = async (templatesToDelete) => {
    pageLoading();
    setIsLoading(true);

    try {
      const storageAreaIds = templatesToDelete.map(({ id }) => id);
      await clientService.deleteInventoryListTemplates(clientId, storageAreaIds);

      await fetchInventoryTemplates();
      showSuccessMessage(
        i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.DELETION_SUCCESS', {
          count: storageAreaIds.length,
        }),
      );
    } catch {
      showErrorMessage(i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.DELETION_ERROR'));
    } finally {
      pageLoaded();
      setIsLoading(false);
    }
  };

  const handleDeletion = (selectedItems) => {
    const count = selectedItems.length;

    const params = {
      type: 'warning',
      width: '542px',
      height: 'auto',
      icon: '/images/inpulse/warning-white-small.svg',
      title: i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.LABEL_DELETE_ACTION', {
        count,
      }),
      component: ConfirmationModal,
      data: {
        content: i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.DELETE_CONFIRMATION_MESSAGE', {
          count,
          name: get(selectedItems, '[0].name', ''),
        }),
      },
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: async () => await deleteInventoryListTemplates(selectedItems),
        },
      ],
    };

    openGenericModal(params);
  };

  const handleExport = () => {
    exportUtils.exportInventoryListTemplates(
      selectedILTs.length ? selectedILTs : inventoryListTemplates,
    );
  };

  const goToCreateILT = () => {
    history.push('/admin/inventories/inventory-list-templates/create');
  };

  const goToILTDetailsPage = (inventoryListTemplateId) => {
    history.push(
      `/admin/inventories/inventory-list-templates/${inventoryListTemplateId.id}/details`,
    );
  };

  const renderNoILTs = () => (
    <EmptyState
      label={i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.EMPTY_STATE_TITLE')}
      labelColor={ENUM_COLORS.IP_BLACK_1}
      labelFont={ENUM_FONTS.H2_INTER}
      renderActionButton={() => (
        <Button
          color={'inpulse-default'}
          handleClick={goToCreateILT}
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('GENERAL.CREATE')}
        />
      )}
      subtitle={i18next.t('ADMIN.INVENTORY_LIST_TEMPLATES.EMPTY_STATE_SUBTITLE')}
      subtitleMargin={'8px'}
    />
  );

  return (
    <ListViewContainer>
      <NavigationBreadCrumb featurePath={path} />
      {displayEmptyState && !isLoading ? (
        renderNoILTs()
      ) : (
        <ListView
          actionOnClick={goToILTDetailsPage}
          actions={!isLoading && actions}
          columns={columns}
          data={inventoryListTemplates}
          isLoading={isLoading}
          padding={STANDARD_LISTVIEW_PADDING}
          renderEmptyState={() => <EmptyState />}
          rowActions={rowActions}
          setSelectedItems={setSelectedILTs}
        />
      )}
    </ListViewContainer>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryListTemplates);
