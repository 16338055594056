import styled, { css } from 'styled-components';

import TrashbinBlackIcon from '@assets/icons/icon-delete-trash.svg';
import TrashbinGreyIcon from '@assets/icons/icon-delete-trash-grey.svg';

export const ContainerListComposition = styled.div`
  margin: 15px 0px 0px 0px;
`;

export const ListHeader = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: ${(props) => (props.theme.colors ? undefined : `1px solid #a8b1c4`)};
`;

export const ListHeaderSection = styled.div`
  flex: 2;
  color: ${(props) => props.theme.colors?.greys.darker || '#6b768c'};
  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts?.textBig};`
      : `font-size: 13px;
  line-height: 12px;
  font-style: normal;`}

  &.name,
  &.category {
    flex: 3;
  }

  &:first-child {
    padding-left: 25px;
  }

  &:last-child {
    flex: 1;
  }
`;

export const ListRow = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: ${(props) => (props.theme.colors ? undefined : `1px solid #a8b1c4`)};
  ${(props) =>
    props.theme.colors
      ? `border: 1px solid ${props.theme.colors.greys.light};
      border-radius: ${props.theme.borders?.radius?.weak};
      margin-bottom: 8px;`
      : `border-bottom: 1px solid #a8b1c4;`}

  &:before {
    top: 0;
    width: 1px;
    left: 0px;
    content: ' ';
    height: 100%;
    box-shadow: ${(props) => (props.theme.colors ? undefined : '-15px 0 3px -17px inset')};
  }

  &:after {
    top: 0;
    width: 1px;
    right: 0px;
    content: ' ';
    height: 100%;
    box-shadow: ${(props) => (props.theme.colors ? undefined : '-15px 0 3px -17px inset')};
  }
`;

export const ListRowText = styled.div`
  color: ${(props) => props.theme.colors?.greys.darkest || '#000000'};

  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts?.textBig};`
      : `font-size: 14px;
  line-height: 16px;
  font-weight: normal;`}

  &.link {
    color: ${(props) => props.theme.colors?.greys.darkest || '#2176fa'};
    width: 95%;
  }

  ${(props) =>
    props.flex &&
    css`
      display: flex;
    `}
`;

export const ListRowSection = styled.div`
  flex: 2;

  &:first-child {
    padding-left: 25px;
    min-width: 0;
  }

  &:last-child {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  &.name,
  &.category {
    flex: 3;
  }

  p {
    margin: 0;
  }

  .actions {
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 4px;
    margin-right: 25px;
    background: ${(props) => props.theme.colors?.greys.lightest || '#ffffff'};
    align-items: center;
    justify-content: center;
    box-shadow: ${(props) => (props.theme.colors ? undefined : '0px 2px 6px rgba(0, 0, 0, 0.1)')};

    background-repeat: no-repeat;
    background-position: center;

    &:hover {
      cursor: pointer;
    }

    img {
      width: 15px;
      height: 15px;
    }
  }
`;

export const ItemLinkText = styled.a`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const DeleteAction = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 4px;
  margin-right: 25px;
  background: ${(props) => props.theme.colors?.greys.light || '#ffffff'};
  align-items: center;
  justify-content: center;
  box-shadow: ${(props) => (props.theme.colors ? undefined : '0px 2px 6px rgba(0, 0, 0, 0.1)')};

  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    cursor: pointer;
  }

  img {
    width: 15px;
    height: 15px;
  }
`;

export const DeleteIcon = styled.span`
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-image: url(${(props) => (props.theme.colors ? TrashbinBlackIcon : TrashbinGreyIcon)});
  background-position: center;
`;
