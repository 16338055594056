import styled from 'styled-components';

export const EditorContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.greys.light};
  padding: 4px;
  background-color: ${(props) =>
    props.isDisabled ? props.theme.colors.greys.light : props.theme.colors.greys.lightest};

  .wrapper-class {
    border: none !important;
  }

  .editor-class {
    height: auto;
    min-height: 160px;
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'text')};
    background-color: ${(props) =>
      props.isDisabled ? props.theme.colors.greys.light : props.theme.colors.greys.lightest};

    font: ${(props) => props.theme.fonts.textBigHeight16};
  }

  .public-DraftEditor-content {
    padding: 4px 12px;
  }

  .public-DraftStyleDefault-block {
    margin: 0;
  }
`;

export default {
  EditorContainer,
};
