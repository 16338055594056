import PropTypes from 'prop-types';
import React from 'react';

import { Tooltip } from '../../utils/styledLibraryComponents';

import { Label } from './styledComponents';

const LabelDot = (props) => {
  const { number, isTooltip, tooltipText, color } = props;

  if (isTooltip) {
    return (
      <>
        <Tooltip
          renderTooltipTrigger={() => (
            <Label color={color}>
              <span>{number}</span>
            </Label>
          )}
          text={tooltipText}
        />
      </>
    );
  }

  return (
    <>
      <Label color={color}>
        <span>{number}</span>
      </Label>
    </>
  );
};

LabelDot.propTypes = {
  number: PropTypes.number.isRequired,
  color: PropTypes.string,
  isTooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
};

LabelDot.defaultProps = {
  color: '#ff9c40',
  tooltipText: '',
  isTooltip: false,
};

export default LabelDot;
