import { connect } from 'react-redux';
import _, { get, groupBy, pick, sortBy, sumBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect, useMemo } from 'react';

import { openSmallModal } from '@actions/modal';
import { showErrorMessage } from '@actions/messageconfirmation';

import {
  getAnalysisByIngredient,
  getRecipes,
} from '@backoffice/BackOfficeGeneralContainer/BackOfficeRecipeDebugger/services';

import { Checkbox, Dropdown, NestedList } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { ExportButton } from '@commons/ExportButton';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import {
  Container,
  ContainerCheckBox,
  ContentContainer,
  ExternalRedirectionContainer,
  FiltersContainer,
  FlexedGapContainer,
  LinkImage,
} from './styledComponents';
import { ExportModal } from './export/modal';
import { getListHeaders } from './config';

const IngredientsConsumption = (props) => {
  const {
    match,
    user,
    stores,
    client: { clientId, defaultTimezone, storeName, hasMultipleChannels },
    showErrorMessage,
    openSmallModal,
  } = props;

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  const yesterday = moment().subtract(1, 'days');
  const weekBefore = moment().subtract(1, 'week');
  const periodPickerState = usePeriodDatePickerState(weekBefore, yesterday);

  const [isLoading, setIsLoading] = useState(false);

  const [recipes, setRecipes] = useState([]);

  const [selectedStore, setSelectedStore] = useState(stores[0]);
  const [selectedIngredient, setSelectedIngredient] = useState(null);

  const [dateChecked, setDateChecked] = useState(true);
  const [productChecked, setProductChecked] = useState(true);
  const [channelChecked, setChannelChecked] = useState(hasMultipleChannels && true);

  const [recipeByIngredientId, setRecipeByIngredientId] = useState({});

  const [analysis, setAnalysis] = useState([]);

  const ingredients = useMemo(() => {
    if (!recipes.length) {
      return [];
    }

    const selectableIngredients = recipes.reduce((acc, { ingredientId, ingredientName }) => {
      if (!acc[ingredientId]) {
        acc[ingredientId] = { id: ingredientId, name: ingredientName };
      }

      return acc;
    }, {});

    const sortedIngredients = sortBy(selectableIngredients, 'name');

    setSelectedIngredient(sortedIngredients[0]);

    const recipesGroupByIngredientId = groupBy(recipes, 'ingredientId');

    setRecipeByIngredientId(recipesGroupByIngredientId);

    return sortedIngredients;
  }, [recipes]);

  const headers = getListHeaders(
    dateChecked,
    productChecked,
    hasMultipleChannels && channelChecked,
  );

  // At page load, fetch recipes
  useEffect(() => {
    if (!clientId) {
      return;
    }

    (async () => {
      setIsLoading(true);

      try {
        const result = await getRecipes(clientId);

        setRecipes(result);
      } catch {
        setRecipes([]);

        showErrorMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_INGREDIENTS_FAILURE'));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [clientId]);

  useEffect(() => {
    if (
      !selectedStore ||
      !selectedIngredient ||
      !periodPickerState.startDate ||
      !periodPickerState.endDate
    ) {
      return;
    }

    (async () => {
      setIsLoading(true);

      try {
        const result = await getAnalysisByIngredient(
          clientId,
          recipeByIngredientId[selectedIngredient.id],
          [selectedStore.id],
          moment(periodPickerState.startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
          moment(periodPickerState.endDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
          selectedIngredient.id,
        );

        setAnalysis(result);
      } catch {
        showErrorMessage(i18next.t('ANALYSIS.SALES.INGREDIENT_CONSUMPTION_FETCH_FAILURE'));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [selectedStore, selectedIngredient, periodPickerState.startDate, periodPickerState.endDate]);

  const handleExportConsumption = () => {
    openSmallModal({
      component: ExportModal,
      defaultTimezone,
      storeName,
      stores,
      currentSelectedStore: selectedStore,
      ingredients,
      currentSelectedIngredient: selectedIngredient,
      recipeByIngredientId,
      currentStartDate: periodPickerState.startDate,
      currentEndDate: periodPickerState.endDate,
      clientId,
      customStyle: {
        overflow: 'initial',
      },
    });
  };

  const handleDateCheck = () => {
    setDateChecked(!dateChecked);
  };

  const handleProductCheck = () => {
    setProductChecked(!productChecked);
  };

  const handleChannelCheck = () => {
    setChannelChecked(!channelChecked);
  };

  const handleOpenEntityDetails = () => {
    if (!!selectedIngredient) {
      window.open(`/admin/products/ingredients/${selectedIngredient.id}/details`, '_blank');
    }
  };

  const aggregateAnalytics = () => {
    const aggregatedAnalysis = _(analysis)
      .groupBy((analysisRow) => JSON.stringify(pick(analysisRow, orderByFields())))
      .map((analysisGroup, keys) => {
        const recipeQuantity = analysisGroup[0].recipeQuantity;
        const ingredientUnit = analysisGroup[0].ingredientUnit;

        const totalConsumption = analysisGroup.reduce(
          (acc, { quantitySold, recipeQuantity }) => acc + quantitySold * recipeQuantity,
          0,
        );

        return {
          ...JSON.parse(keys),
          recipeQuantity,
          ingredientUnit,
          quantitySold: sumBy(analysisGroup, 'quantitySold'),
          totalConsumption,
        };
      })
      .value();

    return aggregatedAnalysis;
  };

  const orderByFields = () => {
    const fields = [];

    if (dateChecked) {
      fields.push('date');
    }

    if (productChecked) {
      fields.push('productName');
    }

    if (channelChecked) {
      fields.push('channelName');
    }

    return fields;
  };

  const DataFrameConfig = () => (
    <ContainerCheckBox>
      <Checkbox handleClick={handleDateCheck} isChecked={dateChecked} shape="square" />
      {i18next.t('BACKOFFICE.GENERAL.BY_DATE')}
      <Checkbox handleClick={handleProductCheck} isChecked={productChecked} shape="square" />
      {i18next.t('BACKOFFICE.GENERAL.BY_PRODUCT')}
      {hasMultipleChannels && (
        <Checkbox handleClick={handleChannelCheck} isChecked={channelChecked} shape="square" />
      )}
      {hasMultipleChannels && i18next.t('BACKOFFICE.GENERAL.BY_CHANNEL')}
    </ContainerCheckBox>
  );

  const SeeIngredient = () => (
    <ExternalRedirectionContainer enabled={!!selectedIngredient} onClick={handleOpenEntityDetails}>
      <LinkImage
        src={`/images/inpulse/open-a-new-${selectedIngredient ? 'black' : 'dgrey'}-small.svg`}
      />
      <Text
        color={selectedIngredient ? ENUM_COLORS.BLACK : ENUM_COLORS.DGREY}
        font={ENUM_FONTS.TEXT_MIDDLE_BOLD}
      >
        {i18next.t('ADMIN.PRODUCTS.SEE_INGREDIENT')}
      </Text>
    </ExternalRedirectionContainer>
  );

  const aggregatedAnalytics = aggregateAnalytics();

  return (
    <Container>
      <NavigationBreadCrumb featurePath={match.path} />
      <FiltersContainer>
        <LeftRightSplitter
          left={
            <FlexedGapContainer>
              <Dropdown
                customStyle={{ width: '160px' }}
                iconSrc={'/images/inpulse/pin-black-small.svg'}
                isDisabled={isLoading}
                items={stores}
                searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                selectedItem={selectedStore}
                isRequired
                onSelectionChange={setSelectedStore}
              />
              <Dropdown
                customStyle={{ width: '160px' }}
                items={ingredients}
                selectedItem={selectedIngredient}
                isRequired
                onSelectionChange={setSelectedIngredient}
              />
              <PeriodDatePicker
                endDate={periodPickerState.endDate}
                focusedDateInput={periodPickerState.focusedDateInput}
                maxFutureDate={yesterday}
                setFocusedDateInput={periodPickerState.setFocusedDateInput}
                startDate={periodPickerState.startDate}
                onDatesChange={periodPickerState.handleSelectedDates}
              />
              <DataFrameConfig />
              <SeeIngredient />
            </FlexedGapContainer>
          }
          right={<ExportButton handleClick={handleExportConsumption} isDisabled={isLoading} />}
        />
      </FiltersContainer>
      <ContentContainer>
        <NestedList
          customContentHeight={'90%'}
          data={aggregatedAnalytics}
          defaultOrderBy={'date'}
          defaultOrderType={'desc'}
          headers={headers}
          isExpandable={false}
          isLoading={isLoading}
          labelEmptyState={i18next.t('GENERAL.NO_RESULT')}
          languageCode={userLanguageCode}
          minWidth={'978px'}
        />
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  userRights: state.baseReducer.userRights,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openSmallModal: (params) => {
    dispatch(openSmallModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IngredientsConsumption);
