import orderService from '@services/order';

import ORDER_STATUS from '@orders/OrderList/components/OrderForm/constants/status';

const DEFAULT_ORDER_BY_REQUEST = 'orderDate';
const DEFAULT_ORDER_TYPE_REQUEST = 'DESC';

const getByIds = async (orderIds) => orderService.getOrderDetailsByOrderIds(orderIds);

const getPaginated = async (storeIds, supplierIds, search, skip, limit, params) => {
  // Override statuses to only fetch orders with anomalies
  const filterForAnomalyStatuses = `{"inq":["${ORDER_STATUS.NON_COMPLIANT}","${ORDER_STATUS.CREDIT_REQUEST_PROCESSED}"]}`;

  if (!params.includes('status')) {
    params += `&status=${filterForAnomalyStatuses}`;
  }

  const { orders: fetchOrders, totalCount: count } = await orderService.getOrdersOfStoresPaginate(
    storeIds,
    supplierIds,
    search,
    skip,
    limit,
    DEFAULT_ORDER_BY_REQUEST,
    DEFAULT_ORDER_TYPE_REQUEST,
    params,
  );

  const fetchOrderIds = fetchOrders.map(({ id }) => id);

  const orderDetails = await getByIds(fetchOrderIds);

  return { data: orderDetails, count };
};

export default {
  getByIds,
  getPaginated,
};
