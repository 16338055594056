import styled from 'styled-components';

/* Main component components */

export const ComponentContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const ListViewContainer = styled.div`
  height: calc(100vh - 40px);
  width: 100%;
`;

export const FiltersContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;
`;

/* Columns components */

export const SentAtContainer = styled.div`
  font: ${(props) => props.theme.fonts.textMiddleBold};
  color: ${(props) => props.theme.colors.greys.darkest};
`;

export const LabelContainer = styled.div`
  display: inline-block;
`;

/* ListView EmptyState components */

export const EmptyStateContainer = styled.div`
  width: 100%;
  height: calc(100% - 112px); // to take into account the nav bars and the margin top

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

/* Global EmptyState components */

export const GlobalEmptyStateImage = styled.img`
  width: 160px;
  height: 160px;
`;

export const GlobalEmptyStateTitle = styled.span`
  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.h2};

  margin-top: 24px;
`;

export const GlobalEmptyStateSubTitle = styled.span`
  color: ${(props) => props.theme.colors.greys.darker};
  font: ${(props) => props.theme.fonts.textSmallHeight16};

  margin: 8px 0px 16px 0px;
`;
