import styled from 'styled-components';

export const RecipeAllergenDropdown = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  > label {
    display: flex;
    color: ${(props) => props.theme.colors?.greys.darkest};
    font: ${(props) => props.theme.fonts?.textSmall};
    margin-bottom: 8px !important;
  }
  .location-filter-list-result {
    width: auto;

    margin-top: 5px;
  }

  .location-filter-input-no-data {
    height: 38px;
  }

  .location-filter-input-text {
    max-width: fit-content !important;
  }

  .location-filter-input {
    height: 38px;
  }

  .location-filter-input > div {
    width: calc(100% - 30px);
  }
`;
export const CostDetailSection = styled.div`
  margin-right: 30px;

  > label {
    display: flex;
    color: ${(props) => props.theme.colors?.greys.darkest};
    font: ${(props) => props.theme.fonts?.textSmall};
    margin-bottom: 8px !important;
  }
`;

export const CostDetail = styled.div`
  padding: 10px 25px 10px 15px;
  border-radius: ${(props) => props.theme.borders?.radius.weak};
  min-width: 100px;
  width: fit-content;

  font: ${(props) => props.theme.fonts.textSmall};

  color: ${(props) => props.theme.colors?.greys.darkest};
  background: ${(props) => props.theme.colors?.greys.lightest};
`;

export const TogglesVerticalList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ToggleContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;

  align-items: center;
`;
