import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 40px);
`;

export const LabelContainer = styled.div`
  min-width: 28px;
  display: flex;
`;

export const FilterButtonContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 24px;

  > div {
    width: 240px;
  }
`;

export const CreditInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InvoiceControlInProgressContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  > div:last-child {
    width: auto;
  }
`;

export const LoaderContainer = styled.div`
  width: fit-content;
`;
