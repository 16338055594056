import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { Props } from './interface';
import { Container } from './styledComponents';
import { getTheme } from '../utils/theme';

const DataTooltip = ({
  children,
  renderContent,
  theme,
}: Props): JSX.Element => {
  const random = Math.random().toString();

  const updatedTheme = getTheme(theme, 'tooltip');

  return (
    <Container>
      <div
        style={{ display: 'contents', height: 'inherit', width: 'inherit' }}
        data-for={random}
        data-tip
      >
        {children}
      </div>

      <ReactTooltip
        id={random}
        className="custom-tooltip"
        textColor={updatedTheme.textColor}
        arrowColor="transparent"
        backgroundColor={updatedTheme.backgroundColor}
        place="right"
      >
        {renderContent()}
      </ReactTooltip>
    </Container>
  );
};

DataTooltip.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.objectOf(PropTypes.any),
  renderContent: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

DataTooltip.defaultProps = {
  theme: null,
};

export default DataTooltip;
