import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { Dropdown } from '@commons/utils/styledLibraryComponents';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { getClientInfo } from '@selectors/client';
import { getPrevHorizon } from '@selectors/featureProps';

import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import {
  Container,
  PeriodDatePickerContainer,
  LabelContainer,
  LabelRequiredCharacter,
} from './styledComponents';

const MAX_NB_OF_DAYS_FOR_RANGE = 365;

const ForecastsExportModal = ({
  stores,
  prevHorizon,
  exportStartDate,
  setExportStartDate,
  exportEndDate,
  setExportEndDate,
  selectedStoresForExport,
  setSelectedStoresForExport,
  setIsExportModalButtonDisabled,
  client: { storeName, defaultTimezone },
}) => {
  const [selectedStores, setSelectedStores] = useState(selectedStoresForExport);

  const [maxFutureDate, setMaxFutureDate] = useState(null);
  const periodPickerState = usePeriodDatePickerState(
    exportStartDate,
    exportEndDate,
    defaultTimezone,
  );

  const clientStoreName = getClientStoreNameTranslation(storeName, true);

  const futurePrevHorizon = get(prevHorizon, 'future', 0);

  useEffect(() => {
    const currentMaxFutureDate = moment
      .tz(defaultTimezone)
      .startOf('day')
      .add(futurePrevHorizon, 'day');

    setMaxFutureDate(currentMaxFutureDate);
  }, []);

  useEffect(() => {
    if (!periodPickerState.startDate) {
      return;
    }

    const selectedStartDate = moment(periodPickerState.startDate)
      .tz(defaultTimezone)
      .startOf('day');

    const todayWithFuturePrevHorizon = moment()
      .tz(defaultTimezone)
      .startOf('day')
      .add(futurePrevHorizon, 'day');

    // If selectedDate is less than 365 days in the past we want to set the maxFutureDate to today + prevHorizon
    if (todayWithFuturePrevHorizon.diff(selectedStartDate, 'days') < MAX_NB_OF_DAYS_FOR_RANGE) {
      setMaxFutureDate(todayWithFuturePrevHorizon);
      return;
    }

    setMaxFutureDate(selectedStartDate.add(MAX_NB_OF_DAYS_FOR_RANGE, 'days'));
  }, [periodPickerState.startDate]);

  useEffect(() => {
    setSelectedStoresForExport(selectedStores);
    setExportStartDate(periodPickerState.startDate);
    setExportEndDate(periodPickerState.endDate);

    if (!selectedStores.length || !periodPickerState.startDate || !periodPickerState.endDate) {
      setIsExportModalButtonDisabled(true);
      return;
    }

    setIsExportModalButtonDisabled(false);
  }, [selectedStores, periodPickerState.startDate, periodPickerState.endDate]);

  return (
    <Container>
      <Dropdown
        customStyle={{ position: 'inherit', height: '68px' }} // for the list to appear over the modal (instead of inside)
        iconSrc="/images/inpulse/pin-black-small.svg"
        isUniqueSelection={false}
        items={stores}
        label={
          <Text
            color={ENUM_COLORS.DARKEST}
            font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {`${clientStoreName} :`}
          </Text>
        }
        searchPlaceholder={i18next.t('GENERAL.SEARCH')}
        selectedItems={selectedStores}
        isRequired
        onSelectionChange={setSelectedStores}
      />
      <PeriodDatePickerContainer>
        <LabelContainer>
          <LabelRequiredCharacter>*</LabelRequiredCharacter>
          <Text
            color={ENUM_COLORS.DARKEST}
            font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {`${i18next.t('GENERAL.EXPORT_PERIOD')} :`}
          </Text>
        </LabelContainer>
        <PeriodDatePicker
          customStyle={{ position: 'absolute', marginTop: '28px' }} // for the list to appear over the modal (instead of inside)
          disabled={false}
          endDate={periodPickerState.endDate}
          focusedDateInput={periodPickerState.focusedDateInput}
          maxFutureDate={maxFutureDate}
          setFocusedDateInput={periodPickerState.setFocusedDateInput}
          startDate={periodPickerState.startDate}
          timezone={defaultTimezone}
          onDatesChange={periodPickerState.handleSelectedDates}
        />
      </PeriodDatePickerContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  prevHorizon: getPrevHorizon(state.baseReducer.userRights),
});

export default connect(mapStateToProps)(ForecastsExportModal);
