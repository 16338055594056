import styled from 'styled-components';

export const Container = styled.div`
  overflow: auto;
  position: relative;

  width: 100%;
  height: 100%;

  background-color: ${(props) => props.theme.colors?.greys?.lighter};

  .DateRangePicker_picker {
    position: relative !important;
  }
`;

export const Content = styled.div`
  height: calc(100% - 40px);

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;

  overflow: auto;
  padding: 24px 0;
`;
