import styled, { css } from 'styled-components';

export const SearchBarContainer = styled.div`
  min-width: ${(props) => props.minWidth}px;
  ${(props) =>
    props.maxWidth &&
    css`
      width: 100%;
      max-width: ${props.maxWidth}px;
    `};
  height: ${(props) => props.height}px;
  background: ${(props) =>
    props.disabled
      ? props.theme.disabled?.backgroundColor
      : props.theme.default?.backgroundColor};
  border-radius: ${(props) => props.theme.borderRadius};
  border: ${(props) => props.theme.border};
  padding: 0px 16px;
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  min-width: ${(props) => props.theme.icon?.size};

  ${(props) =>
    props.clickable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `};
`;

export const SearchBarInput = styled.input`
  width: 100%;
  margin-left: 8px;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0px;

  font: ${(props) => props.theme.font};
  color: ${(props) => props.theme.default?.color};

  ::placeholder {
    color: ${(props) =>
      props.disabled
        ? props.theme.disabled?.color
        : props.theme.placeholder?.color};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer */
    color: ${(props) =>
      props.disabled
        ? props.theme.disabled?.color
        : props.theme.placeholder?.color};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) =>
      props.disabled
        ? props.theme.disabled?.color
        : props.theme.placeholder?.color};
  }
`;

export default {
  SearchBarContainer,
  Icon,
  SearchBarInput,
};
