import i18next from 'i18next';
import moment from 'moment';
import React from 'react';

import { InpulseLabel } from '@commons/DeepsightComponents';
import { numberToFixed } from '@commons/utils/format';
import DisplayNumber from '@commons/DisplayNumber';

import { LabelContainer, Label } from '../styledComponents';

const getColumnsTable = (currency, metric, allowedColumnsByKey, targetProductionWasteRate) => {
  let columns = [
    {
      id: 0,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_DATE'),
      type: 'date',
      propertyKey: 'date',
      render: (field) =>
        field == i18next.t('GENERAL.ANALYTICS_LABEL_TOTAL')
          ? field
          : moment(field).format('ddd D MMM'),
    },
    {
      id: 1,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_FORECAST'),
      type: 'numeric',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'forecastedQuantity',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_FORECAST_TOOLTIP_UNIT'),
    },
    {
      id: 2,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_STOCKS'),
      type: 'numeric',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'stockedQuantity',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_STOCKS_TOOLTIP_UNIT'),
    },
    {
      id: 3,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_TO_PRODUCE'),
      type: 'numeric',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'toProduceQuantity',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_TO_PRODUCE_TOOLTIP_UNIT'),
    },
    {
      id: 4,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCED'),
      type: 'numeric',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'producedQuantity',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCED_TOOLTIP_UNIT'),
    },
    {
      id: 5,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCTION_RATIO'),
      type: 'percentage',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'quantityProductionRatio',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCTION_RATIO_TOOLTIP_UNIT'),
      render: (item) => {
        const valueToDisplay = numberToFixed(item, 0, '-', '%');

        if (item == null) {
          return (
            <LabelContainer>
              <Label>-</Label>
            </LabelContainer>
          );
        }

        if (item <= 75 || item >= 125) {
          return (
            <LabelContainer>
              <InpulseLabel color={'red'} text={valueToDisplay} type={'secondary'} />
            </LabelContainer>
          );
        }

        if ((item >= 75 && item < 90) || (item > 110 && item > 125)) {
          return (
            <LabelContainer>
              <InpulseLabel color={'orange'} text={valueToDisplay} type={'secondary'} />
            </LabelContainer>
          );
        }

        return (
          <LabelContainer>
            <Label>{valueToDisplay}</Label>
          </LabelContainer>
        );
      },
    },
    {
      id: 6,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS'),
      type: 'numeric',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'lossQuantity',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS_TOOLTIP_UNIT'),
    },
    {
      id: 7,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS_RATIO'),
      type: 'percentage',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'quantityProductionWasteRate',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCTION_LOSS_RATIO_TOOLTIP_UNIT'),
      render: (item) => {
        const valueToDisplay = numberToFixed(item, 0, '-', '%');
        return (
          <LabelContainer>
            <Label>{valueToDisplay}</Label>
          </LabelContainer>
        );
      },
    },
    {
      id: 8,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS_RATIO'),
      type: 'percentage',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'quantityTurnoverWasteRate',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_TURNOVER_LOSS_RATIO_TOOLTIP_UNIT'),
      render: (item) => {
        const valueToDisplay = numberToFixed(item, 0, '-', '%');
        return (
          <LabelContainer>
            <Label>{valueToDisplay}</Label>
          </LabelContainer>
        );
      },
    },
    {
      id: 9,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_SALES'),
      type: 'numeric',
      decimals: 0,
      filterType: 'numeric',
      propertyKey: 'realSales',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_SALES_TOOLTIP_UNIT'),
    },
    {
      id: 10,
      decimals: 0,
      name: i18next.t('PRODUCTION.BY_DATE.COLUMN_REAL_SALES'),
      tooltipText: i18next.t('PRODUCTION.BY_DATE.COLUMN_REAL_SALES_TOOLTIP'),
      type: 'currency',
      filterType: 'numeric',
      propertyKey: 'realTurnoverInclTax',
      render: () => <LabelContainer>-</LabelContainer>,
    },
    {
      id: 11,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_CONFORMITY_RATIO'),
      type: 'percentage',
      propertyKey: 'conformity',
      tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_CONFORMITY_RATIO_TOOLTIP'),
      filterType: 'numeric',
      render: (item) => (
        <LabelContainer>
          <Label>{numberToFixed(item, 2, '-', '%')}</Label>
        </LabelContainer>
      ),
    },
    {
      id: 12,
      name: i18next.t('PRODUCTION.REAL_TIME.LABEL_CADENCE'),
      type: 'percentage',
      propertyKey: 'cadence',
      filterType: 'numeric',
      render: (item) => (
        <LabelContainer>
          <Label>{numberToFixed(item, 2, '-', '%')}</Label>
        </LabelContainer>
      ),
    },
  ];

  if (metric === 'turnover') {
    columns = [
      {
        id: 0,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_DATE'),
        type: 'date',
        propertyKey: 'date',
        render: (field) =>
          field === i18next.t('GENERAL.ANALYTICS_LABEL_TOTAL')
            ? field
            : moment(field).format('ddd D MMM'),
      },
      {
        id: 1,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_FORECAST'),
        type: 'currency',
        decimals: 0,
        filterType: 'numeric',
        propertyKey: 'forecastedTurnover',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_FORECAST_TOOLTIP_TURNOVER'),
        render: (item) => (
          <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
        ),
      },
      {
        id: 2,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_STOCKS'),
        type: 'currency',
        decimals: 0,
        filterType: 'numeric',
        propertyKey: 'stockedTurnover',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_STOCKS_TOOLTIP_TURNOVER'),
        render: (item) => (
          <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
        ),
      },
      {
        id: 3,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_TO_PRODUCE'),
        type: 'currency',
        decimals: 0,
        filterType: 'numeric',
        propertyKey: 'toProduceTurnover',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_TO_PRODUCE_TOOLTIP_TURNOVER'),
        render: (item) => (
          <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
        ),
      },
      {
        id: 4,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCED'),
        type: 'currency',
        decimals: 0,
        filterType: 'numeric',
        propertyKey: 'producedTurnover',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCED_TOOLTIP_TURNOVER'),
        render: (item) => (
          <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
        ),
      },
      {
        id: 5,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCTION_RATIO'),
        type: 'percentage',
        decimals: 0,
        filterType: 'numeric',
        propertyKey: 'turnoverProductionRatio',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_PRODUCTION_RATIO_TOOLTIP_TURNOVER'),
        render: (item) => {
          const valueToDisplay = numberToFixed(item, 0, '-', '%');

          if (item == null) {
            return (
              <LabelContainer>
                <Label>-</Label>
              </LabelContainer>
            );
          }

          if (item <= 75 || item >= 125) {
            return (
              <LabelContainer>
                <InpulseLabel color={'red'} text={valueToDisplay} type={'secondary'} />
              </LabelContainer>
            );
          }

          if ((item >= 75 && item < 90) || (item > 110 && item > 125)) {
            return (
              <LabelContainer>
                <InpulseLabel color={'orange'} text={valueToDisplay} type={'secondary'} />
              </LabelContainer>
            );
          }

          return (
            <LabelContainer>
              <Label>{valueToDisplay}</Label>
            </LabelContainer>
          );
        },
      },
      {
        id: 6,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS'),
        type: 'currency',
        decimals: 0,
        filterType: 'numeric',
        propertyKey: 'loss',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS_TOOLTIP_TURNOVER'),
        render: (item) => (
          <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
        ),
      },
      {
        id: 7,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS_RATIO'),
        type: 'percentage',
        decimals: 0,
        filterType: 'numeric',
        propertyKey: 'productionWasteRate',
        tooltipText: i18next.t(
          'PRODUCTION.REAL_TIME.LABEL_PRODUCTION_LOSS_RATIO_TOOLTIP_TURNOVER',
          { currency: currency.alphabeticCode },
        ),
        render: (item) => {
          const valueToDisplay = numberToFixed(item, 0, '-', '%');
          const isTargetProductionWasteRateEnabled =
            allowedColumnsByKey['targetProductionWasteRate'];

          if (isTargetProductionWasteRateEnabled) {
            if (item === null) {
              return (
                <LabelContainer>
                  <Label>{valueToDisplay}</Label>
                </LabelContainer>
              );
            }
            if (parseInt(numberToFixed(item, 0)) === parseInt(targetProductionWasteRate)) {
              return (
                <LabelContainer>
                  <InpulseLabel color={'green'} text={valueToDisplay} type={'secondary'} />
                </LabelContainer>
              );
            }
            if (
              parseInt(item) <= parseInt(targetProductionWasteRate) + 5 &&
              parseInt(item) >= parseInt(targetProductionWasteRate) - 5
            ) {
              return (
                <LabelContainer>
                  <InpulseLabel color={'orange'} text={valueToDisplay} type={'secondary'} />
                </LabelContainer>
              );
            }
            return (
              <LabelContainer>
                <InpulseLabel color={'red'} text={valueToDisplay} type={'secondary'} />
              </LabelContainer>
            );
          }

          return (
            <LabelContainer>
              <Label>{valueToDisplay}</Label>
            </LabelContainer>
          );
        },
      },
      {
        id: 8,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_LOSS_RATIO'),
        type: 'percentage',
        decimals: 0,
        filterType: 'numeric',
        propertyKey: 'turnoverWasteRate',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_TURNOVER_LOSS_RATIO_TOOLTIP_TURNOVER', {
          currency: currency.alphabeticCode,
        }),
        render: (item) => {
          const valueToDisplay = numberToFixed(item, 0, '-', '%');
          const isTargetProductionWasteRateEnabled =
            allowedColumnsByKey['targetProductionWasteRate'];

          if (isTargetProductionWasteRateEnabled) {
            if (item === null) {
              return (
                <LabelContainer>
                  <Label>{valueToDisplay}</Label>
                </LabelContainer>
              );
            }
            if (parseInt(numberToFixed(item, 0)) === parseInt(targetProductionWasteRate)) {
              return (
                <LabelContainer>
                  <InpulseLabel color={'green'} text={valueToDisplay} type={'secondary'} />
                </LabelContainer>
              );
            }
            if (
              parseInt(item) <= parseInt(targetProductionWasteRate) + 5 &&
              parseInt(item) >= parseInt(targetProductionWasteRate) - 5
            ) {
              return (
                <LabelContainer>
                  <InpulseLabel color={'orange'} text={valueToDisplay} type={'secondary'} />
                </LabelContainer>
              );
            }
            return (
              <LabelContainer>
                <InpulseLabel color={'red'} text={valueToDisplay} type={'secondary'} />
              </LabelContainer>
            );
          }

          return (
            <LabelContainer>
              <Label>{valueToDisplay}</Label>
            </LabelContainer>
          );
        },
      },
      {
        id: 9,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_SALES'),
        type: 'currency',
        decimals: 0,
        filterType: 'numeric',
        propertyKey: 'realTurnover',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_SALES_TOOLTIP_TURNOVER', {
          currency: currency.alphabeticCode,
        }),
        render: (item) => (
          <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
        ),
      },
      {
        id: 10,
        decimals: 0,
        name: i18next.t('PRODUCTION.BY_DATE.COLUMN_REAL_SALES'),
        tooltipText: i18next.t('PRODUCTION.BY_DATE.COLUMN_REAL_SALES_TOOLTIP'),
        type: 'currency',
        filterType: 'numeric',
        propertyKey: 'realTurnoverInclTax',
        render: (item) => (
          <DisplayNumber displayCurrencyCode={true} number={item} withoutDecimals={true} />
        ),
      },
      {
        id: 11,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_CONFORMITY_RATIO'),
        type: 'percentage',
        filterType: 'numeric',
        propertyKey: 'conformity',
        tooltipText: i18next.t('PRODUCTION.REAL_TIME.LABEL_CONFORMITY_RATIO_TOOLTIP'),
        render: (item) => (
          <LabelContainer>
            <Label>{numberToFixed(item, 2, '-', '%')}</Label>
          </LabelContainer>
        ),
      },
      {
        id: 12,
        name: i18next.t('PRODUCTION.REAL_TIME.LABEL_CADENCE'),
        type: 'percentage',
        filterType: 'numeric',
        propertyKey: 'cadence',
        render: (item) => (
          <LabelContainer>
            <Label>{numberToFixed(item, 2, '-', '%')}</Label>
          </LabelContainer>
        ),
      },
    ];
  }

  return columns.filter((column) => allowedColumnsByKey[column.propertyKey]);
};

export default getColumnsTable;
