import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';

import {
  updateOrCreateMultipleForecastsByDate,
  recieveupdateOrCreateMultipleForecastsByDateResult,
  requestupdateOrCreateMultipleForecastsByDateError,
} from '@actions/forecast';

import { Button, InputNumber } from '@commons/utils/styledLibraryComponents';
import mixpanelUtils, { ENUM_EVENTS } from '@commons/utils/mixpanel';

import { canCreateForecastTurnover } from '@selectors/actions/forecastActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client.js';
import { getSalesPointStores } from '@selectors/stores.js';

import DataTile from './DataTile.js';
import FiveSameDaysChart from './FiveSameDaysChart/FiveSameDaysChart';

export class ForecastCurrentDayTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      events: [],
      stores: [],
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data[0] !== this.props.data[0]) {
      if (this.props.data.length) {
        this.setState({ data: this.props.data[0].breakdownDatas });
      } else {
        this.setState({ data: [] });
      }
    }
  }

  handleLineUpdate = (newline) => {
    const oldLines = this.state.lines;
    let lines = oldLines.map((line) => {
      if (line.name === newline.name) {
        line = newline;
      }
      return line;
    });
    this.setState(lines);
  };

  handleConfirmForecasts = () => {
    const { client, storeId, activeDate, storeTimezone } = this.props;
    let data = this.state.data;
    let forecastsArray = [];

    data.forEach((line) => {
      if ((line.inputValue || line.inputValue === 0) && line.inputValue !== '') {
        let value = line.inputValue;

        let forecast = {};
        if (this.props.services && this.props.services.length) {
          forecast.service = line.baseName;
        }

        forecast.tot = parseInt(value, 10);

        if (client.isForecastTurnover) {
          forecast.ca = client.useTtc ? parseInt(value, 10) : null;
          forecast.turnoverExcludingTaxes = client.useTtc ? null : parseInt(value, 10);
        }

        forecast.type = 'saved';
        forecast.storeId = storeId;
        forecast.timestamp = activeDate.clone().endOf('day').subtract(59, 'seconds').format();
        forecastsArray.push(forecast);
      }
    });
    if (forecastsArray.length === data.length) {
      this.props.updateOrCreateMultipleForecastsByDate(forecastsArray).then(() => {
        this.props.handleNextDay();
      });
    }

    const clientStoresByIds = _.keyBy(this.props.stores, 'id');

    const currentStoreName = _.get(clientStoresByIds, `[${storeId}].name`);

    mixpanelUtils.sendMetric(ENUM_EVENTS.VALIDATE_FORECAST, {
      storeName: currentStoreName,
      forecastDate: moment.tz(activeDate, storeTimezone).format('DD/MM/YY'),
    });
  };

  updateLineValue(ev, bd) {
    const oldData = JSON.parse(JSON.stringify(this.state.data));
    let data = oldData.map((breakdown) => {
      if (breakdown.baseName === bd) {
        breakdown.inputValue = ev !== '' ? parseInt(ev, 10) : '';
        if (ev === '' || isNaN(ev)) {
          breakdown.inputCheckFail = true;
        } else {
          breakdown.inputCheckFail = false;
        }
      }
      return breakdown;
    });
    this.setState({ data });
  }

  isAfterToday(date) {
    const { storeTimezone } = this.props;

    return moment.tz(date, storeTimezone).isAfter(moment.tz(storeTimezone).endOf('day'));
  }

  render() {
    const {
      activeDate,
      authorizedActions,
      className,
      activeService,
      pastChart,
      events,
      client,
      productionMetricsByDates,
      services,
      updateActiveService,
      user,
      wasteMetricPropertyKey,
      weather,
      storeTimezone,
    } = this.props;

    const hasRightToCreateForecastTurnover = canCreateForecastTurnover(authorizedActions);

    return (
      <div className={className}>
        <div className="current-day-tile-header">
          <h1>{moment.tz(activeDate, storeTimezone).format('dddd DD MMMM YYYY')}</h1>
        </div>
        <div className="current-day-tile-content">
          <div className="current-day-tile-forecast-encloser">
            {this.state.data.length > 0 &&
              this.state.data.map((data, index) => (
                <div className="forecast-data-line-container" key={index}>
                  <div className="forecast-data-line-label">
                    {this.state.data.length > 1
                      ? `${client.turnoverName} ${data.name}`
                      : client.turnoverName}
                  </div>
                  <div className="forecast-data-line" key={data.name}>
                    <div className="forecast-data-line-content">
                      <DataTile
                        data={data}
                        displayName={client.turnoverName}
                        isPast={moment
                          .tz(activeDate, storeTimezone)
                          .isBefore(moment.tz(storeTimezone).startOf('day'))}
                      />
                    </div>
                    {hasRightToCreateForecastTurnover && (
                      <>
                        {this.isAfterToday(activeDate) && (
                          <div style={{ flex: 1 }}>
                            <InputNumber
                              height={'40px'}
                              value={
                                isNaN(data.inputValue) || data.inputValue === ''
                                  ? ''
                                  : Math.round(data.inputValue)
                              }
                              width={'100%'}
                              onChange={(ev) =>
                                this.updateLineValue(ev.target.value, data.baseName)
                              }
                            />
                          </div>
                        )}
                        {index === this.state.data.length - 1 && this.isAfterToday(activeDate) && (
                          <Button
                            color={'inpulse-default'}
                            handleClick={this.handleConfirmForecasts}
                            icon={'/images/inpulse/check-white-small.svg'}
                            isDisabled={data.inputCheckFail}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
          <hr />
          <div className="current-day-scroll">
            <FiveSameDaysChart
              activeDate={activeDate}
              activeService={activeService}
              data={pastChart}
              events={events}
              productionMetricsByDates={productionMetricsByDates}
              services={services}
              storeTimezone={storeTimezone}
              updateActiveService={updateActiveService}
              user={user}
              wasteMetricPropertyKey={wasteMetricPropertyKey}
              weather={weather}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stores: getSalesPointStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/forecast/forecast/turnover',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  updateOrCreateMultipleForecastsByDate: (params) =>
    dispatch(updateOrCreateMultipleForecastsByDate(params)).then(
      (result) => {
        dispatch(recieveupdateOrCreateMultipleForecastsByDateResult(result));
      },
      (error) => {
        dispatch(requestupdateOrCreateMultipleForecastsByDateError(error));
      },
    ),
});

ForecastCurrentDayTile = connect(mapStateToProps, mapDispatchToProps)(ForecastCurrentDayTile);

export default withTheme(ForecastCurrentDayTile);
