import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1000px;
  height: 100%;
  min-height: calc(100% - 60px);

  background-color: white;
`;

export const Content = styled.div`
  position: absolute;
  width: calc(100% - 64px);

  padding: 24px;
  height: 100%;
  background-color: ${(props) => props.theme.colors.greys.lighter};
`;

export const SettingContainer = styled.div`
  display: flex;

  align-items: center;
  column-gap: 24px;
`;

export const MixContainer = styled.div`
  margin-top: 24px;
`;

export default {
  Container,
  Content,
  SettingContainer,
  MixContainer,
};
