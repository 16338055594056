import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {
  TagContainer,
  TagLabel,
  TagInfo,
  TagHoverBox,
  PointerDiv,
  TagLabelAndInfoContainer,
} from './styledComponents';

export const Tags = ({ items, isNestedContent = false }) => {
  if (_.isEmpty(items)) {
    return '-';
  }

  const sortedItems = items.sort();

  return (
    <TagContainer pointer={true}>
      <TagLabelAndInfoContainer>
        <TagLabel isNestedContent={isNestedContent}>{sortedItems[0]}</TagLabel>
        {sortedItems.length > 1 && <TagInfo as="TagInfo">{`+ ${sortedItems.length - 1}`}</TagInfo>}
      </TagLabelAndInfoContainer>
      <TagHoverBox>
        <PointerDiv />
        {sortedItems.map((item, index) => (
          <TagLabel className="box" key={index}>
            {item}
          </TagLabel>
        ))}
      </TagHoverBox>
    </TagContainer>
  );
};

Tags.propTypes = {
  items: PropTypes.array.isRequired,
};
