import React from 'react';

import {
  Container,
  ContentContainer,
  LockIconContainer,
  SubtitleContainer,
  TitleContainer,
} from './styledComponents';
import i18next from 'i18next';

const STARTER_PLAN_BACKGROUND = '/images/inpulse/locked-order-background-forecasts.png';
const LOCK = '/images/inpulse/lock-yellow.svg';

const StarterPlanForecast = () => (
  <>
    <Container background={STARTER_PLAN_BACKGROUND}>
      <ContentContainer>
        <LockIconContainer>
          <img alt="lock-icon" src={LOCK} />
        </LockIconContainer>

        <TitleContainer>{i18next.t('ORDERS.ORDERS.STARTER_PLAN_FORECASTS_TITLE')}</TitleContainer>
        <SubtitleContainer>
          {i18next.t('ORDERS.ORDERS.STARTER_PLAN_FORECASTS_SUBTITLE')}
        </SubtitleContainer>
      </ContentContainer>
    </Container>
  </>
);

export default StarterPlanForecast;
