import ORDER_STATUS, { ORDER_PREPARATION_STATUS } from '../components/OrderForm/constants/status';

export const statusLegend = {
  [ORDER_STATUS.DRAFT]: {
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_DRAFT',
    color: 'grey',
  },
  [ORDER_STATUS.SENT_EDITABLE]: {
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_SENT_EDITABLE',
    color: 'grey',
  },
  [ORDER_STATUS.SENT]: {
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_SENT',
    color: 'black',
  },
  [ORDER_STATUS.RECEPTION]: {
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_RECEPTION',
    color: 'black',
  },
  [ORDER_STATUS.COMPLIANT]: {
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_COMPLIANT',
    color: 'green',
  },
  [ORDER_STATUS.INCOMPLETE]: {
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_INCOMPLETE',
    color: 'orange',
  },
  [ORDER_STATUS.NON_COMPLIANT]: {
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_NON_COMPLIANT',
    color: 'red',
  },
  [ORDER_STATUS.CREDIT_REQUEST_PROCESSED]: {
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_CREDIT_REQUEST_PROCESSED',
    color: 'red',
  },
  [ORDER_STATUS.BILLED]: {
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_BILLED',
    color: 'black',
  },
  [ORDER_STATUS.PAID]: {
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_PAID',
    color: 'black',
  },
};

export const preparationStatusLegend = {
  [ORDER_PREPARATION_STATUS.TO_BE_PREPARED]: {
    name: 'ORDERS.ORDERS.LIST_LABEL_PREPARATION_STATUS_TO_BE_PREPARED',
    color: 'grey',
  },
  [ORDER_PREPARATION_STATUS.PREPARING]: {
    name: 'ORDERS.ORDERS.LIST_LABEL_PREPARATION_STATUS_PREPARING',
    color: 'grey',
  },
  [ORDER_PREPARATION_STATUS.COMPLIANT_PREPARED]: {
    name: 'ORDERS.ORDERS.LIST_LABEL_PREPARATION_STATUS_PREPARED',
    color: 'green',
  },
  [ORDER_PREPARATION_STATUS.NON_COMPLIANT_PREPARED]: {
    name: 'ORDERS.ORDERS.LIST_LABEL_PREPARATION_STATUS_PREPARED',
    color: 'orange',
  },
  [ORDER_PREPARATION_STATUS.COMPLIANT_INVOICED]: {
    name: 'ORDERS.ORDERS.LIST_LABEL_PREPARATION_STATUS_INVOICED',
    color: 'green',
  },
  [ORDER_PREPARATION_STATUS.NON_COMPLIANT_INVOICED]: {
    name: 'ORDERS.ORDERS.LIST_LABEL_PREPARATION_STATUS_INVOICED',
    color: 'orange',
  },
};

export const statusColorCodes = [
  {
    id: String(ORDER_STATUS.DRAFT),
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_DRAFT',
    color: 'grey-2',
    inpulseColor: 'grey',
  },
  {
    id: String(ORDER_STATUS.SENT_EDITABLE),
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_SENT_EDITABLE',
    color: 'grey-2',
    inpulseColor: 'grey',
  },
  {
    id: String(ORDER_STATUS.SENT),
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_SENT',
    color: 'black',
    inpulseColor: 'black',
  },
  {
    id: String(ORDER_STATUS.RECEPTION),
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_RECEPTION',
    color: 'green-1',
    inpulseColor: 'black',
  },
  {
    id: String(ORDER_STATUS.COMPLIANT),
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_COMPLIANT',
    color: 'red',
    inpulseColor: 'green',
  },
  {
    id: String(ORDER_STATUS.INCOMPLETE),
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_INCOMPLETE',
    color: 'red',
    inpulseColor: 'orange',
  },
  {
    id: String(ORDER_STATUS.NON_COMPLIANT),
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_NON_COMPLIANT',
    color: 'red',
    inpulseColor: 'red',
  },
  {
    id: String(ORDER_STATUS.CREDIT_REQUEST_PROCESSED),
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_CREDIT_REQUEST_PROCESSED',
    color: 'red',
    inpulseColor: 'red',
  },
  {
    id: String(ORDER_STATUS.BILLED),
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_BILLED',
    color: 'black',
    inpulseColor: 'black',
  },
  {
    id: String(ORDER_STATUS.PAID),
    name: 'ORDERS.ORDERS.LIST_LABEL_STATUS_PAID',
    color: 'black',
    inpulseColor: 'black',
  },
];

export const preparationStatusColorCodes = [
  {
    id: String(ORDER_PREPARATION_STATUS.TO_BE_PREPARED),
    name: 'ORDERS.ORDERS.LIST_LABEL_PREPARATION_STATUS_TO_BE_PREPARED',
    color: 'black',
    inpulseColor: 'black',
  },
  {
    id: String(ORDER_PREPARATION_STATUS.PREPARING),
    name: 'ORDERS.ORDERS.LIST_LABEL_PREPARATION_STATUS_PREPARING',
    color: 'black',
    inpulseColor: 'black',
  },
  {
    id: String(ORDER_PREPARATION_STATUS.COMPLIANT_PREPARED),
    name: 'ORDERS.ORDERS.LIST_LABEL_PREPARATION_STATUS_PREPARED',
    color: 'green-1',
    inpulseColor: 'green',
  },
  {
    id: String(ORDER_PREPARATION_STATUS.NON_COMPLIANT_PREPARED),
    name: 'ORDERS.ORDERS.LIST_LABEL_PREPARATION_STATUS_PREPARED',
    color: 'red',
    inpulseColor: 'orange',
  },
  {
    id: String(ORDER_PREPARATION_STATUS.COMPLIANT_INVOICED),
    name: 'ORDERS.ORDERS.LIST_LABEL_PREPARATION_STATUS_INVOICED',
    color: 'green-1',
    inpulseColor: 'green',
  },
  {
    id: String(ORDER_PREPARATION_STATUS.NON_COMPLIANT_INVOICED),
    name: 'ORDERS.ORDERS.LIST_LABEL_PREPARATION_STATUS_INVOICED',
    color: 'red',
    inpulseColor: 'orange',
  },
];

export default {
  statusLegend,
  statusColorCodes,
  preparationStatusLegend,
  preparationStatusColorCodes,
};
