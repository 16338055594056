import i18next from 'i18next';

import {
  GENERIC_MODAL_BACK_BUTTON,
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_NEXT_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

import { IngredientInformationsModal } from './stepsModals/IngredientInformationsModal';
import MapSupplierProductsModal from './stepsModals/MapSupplierProductsModal';

export const CREATE_INGREDIENT_MODAL_STEPS = {
  INGREDIENT_INFORMATIONS: 'ingredientInformations',
  MAP_SUPPLIER_PRODUCTS: 'mapSupplierProducts',
};

const MODAL_STEPS_ORDER = [
  CREATE_INGREDIENT_MODAL_STEPS.INGREDIENT_INFORMATIONS,
  CREATE_INGREDIENT_MODAL_STEPS.MAP_SUPPLIER_PRODUCTS,
];

const _getStepNumber = (currentStep) => ({
  total: MODAL_STEPS_ORDER.length,
  current: MODAL_STEPS_ORDER.findIndex((step) => step === currentStep) + 1,
});

const createIngredientModalComponents = {
  [CREATE_INGREDIENT_MODAL_STEPS.INGREDIENT_INFORMATIONS]: IngredientInformationsModal,
  [CREATE_INGREDIENT_MODAL_STEPS.MAP_SUPPLIER_PRODUCTS]: MapSupplierProductsModal,
};

const getCreateIngredientModalActions = (props) => {
  const {
    currentStep,
    setCurrentStep,
    resetModal,
    handleCallToCreateIngredient,
    ingredientInformationsData,
    mapSupplierProductsData,
  } = props;

  if (currentStep === CREATE_INGREDIENT_MODAL_STEPS.INGREDIENT_INFORMATIONS) {
    const disabledAction =
      ingredientInformationsData.name === '' || ingredientInformationsData.unit === '';

    return [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_NEXT_BUTTON(),
        preventClosing: true,
        isDisabled: disabledAction,
        handleClick: () => {
          setCurrentStep(CREATE_INGREDIENT_MODAL_STEPS.MAP_SUPPLIER_PRODUCTS);
        },
      },
    ];
  }

  if (currentStep === CREATE_INGREDIENT_MODAL_STEPS.MAP_SUPPLIER_PRODUCTS) {
    return [
      {
        ...GENERIC_MODAL_BACK_BUTTON(),
        preventClosing: true,
        handleClick: () => {
          setCurrentStep(CREATE_INGREDIENT_MODAL_STEPS.INGREDIENT_INFORMATIONS);
        },
      },
      {
        ...GENERIC_MODAL_NEXT_BUTTON(),
        label: i18next.t('GENERAL.SAVE'),
        icon: '/images/inpulse/save-white-small.svg',
        preventClosing: true,
        handleClick: () => {
          handleCallToCreateIngredient({
            ...ingredientInformationsData,
            supplierProducts: mapSupplierProductsData,
          });
          resetModal();
        },
      },
    ];
  }
};

export const getCreateIngredientModalConfig = (props) => {
  const { currentStep, resetModal } = props;

  const params = {
    type: 'actionWhite',
    isFullscreen: true,
    icon: '/images/inpulse/add-black-small.svg',
    title: i18next.t('ADMIN.INGREDIENTS.CREATE'),
    handleCloseCleanUp: resetModal,
    actions: getCreateIngredientModalActions(props),
    component: createIngredientModalComponents[currentStep],
    data: props,
    step: _getStepNumber(currentStep),
    customPadding: '24px 24px 0px 24px',
  };

  return params;
};
