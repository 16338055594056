import styled, { css } from 'styled-components';

export const ItemRowContainer = styled.div`
  margin: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: ${(props) => props.theme?.row?.boxShadow};
  border-radius: 3px;
  background-color: #ffffff;
  border: 1px solid transparent;

  &:hover {
    border: ${(props) => props.isExpandable && props.theme?.row?.hover?.border};
  }

  &:hover #actionButton {
    filter: grayscale(0);
  }
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 54px;
  padding: 0 26px 0 0px;
  cursor: ${(props) => props.isExpandable && 'pointer'};
`;

export const ExpandedContainer = styled.div`
  padding: ${(props) => (props.open ? '0 25px 30px 25px' : '0 25px 0 25px')};
  background-color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  border-radius: ${(props) => props.theme.childRow?.borderRadius};
`;

export const ItemDisplayWithGraph = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex: 1;

  flex-basis: ${(props) => `calc(${100 / props.nbColumn}% )`};
  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth}px;
    `}
`;

export const ItemDisplayWithCustomModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex: 1;

  flex-basis: ${(props) => `calc(${100 / props.nbColumn}%)`};

  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth}px;
    `}
`;

export const ItemContainer = styled.div`
  flex: 1;
`;

export const ItemGraphContainer = styled.div`
  flex-basis: 50%;
`;

export const ModalContainer = styled.div``;

export default {
  ItemRowContainer,
  ItemRow,
  ExpandedContainer,
  ItemDisplayWithGraph,
  ItemDisplayWithCustomModal,
  ItemContainer,
  ItemGraphContainer,
  ModalContainer,
};
