import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  cursor: pointer;
  user-select: none;

  width: ${(props) => (props.size === 'small' ? '30px' : '40px')};
  height: ${(props) => (props.size === 'small' ? '30px' : '40px')};
  min-width: ${(props) => (props.size === 'small' ? '30px' : '40px')};
  min-height: ${(props) => (props.size === 'small' ? '30px' : '40px')};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  ${(props) => {
    if (props.isReadOnly) {
      return css`
        cursor: not-allowed;
        background-color: ${!props.isSelected && props.theme.disabled.color};
      `;
    }

    return css`
      &:hover:not(.disabled) {
        background: ${props.theme?.hover.backgroundColor};
      }
    `;
  }}
`;

export const OuterCircle = styled.div`
  width: ${(props) =>
    props.size === 'small'
      ? props.theme?.size.outerCircleSmall
      : props.theme?.size.outerCircleDefault};
  height: ${(props) =>
    props.size === 'small'
      ? props.theme?.size.outerCircleSmall
      : props.theme?.size.outerCircleDefault};
  min-width: ${(props) =>
    props.size === 'small'
      ? props.theme?.size.outerCircleSmall
      : props.theme?.size.outerCircleDefault};
  min-height: ${(props) =>
    props.size === 'small'
      ? props.theme?.size.outerCircleSmall
      : props.theme?.size.outerCircleDefault};

  border-radius: 50%;

  display: flex;
  align-items: 'center';
  justify-content: 'center';

  transition: all 0.1s linear;

  ${(props) => {
    if (props.isSelected) {
      return css`
        border: ${props.theme?.selected.borderSize} solid
          ${props.theme[props.isReadOnly ? 'disabled' : 'selected'].color};
      `;
    }

    return css`
      border: ${props.borderSize ? props.borderSize : props.theme.borderSize}
        solid
        ${props.isReadOnly
          ? props.theme.disabled.color
          : props.theme?.borderColor};
    `;
  }}
`;

export const InnerCircle = styled.div`
  width: ${(props) =>
    props.size === 'small'
      ? props.theme?.size.innerCircleSmall
      : props.theme?.size.innerCircleDefault};
  height: ${(props) =>
    props.size === 'small'
      ? props.theme?.size.innerCircleSmall
      : props.theme?.size.innerCircleDefault};

  margin: auto;

  border-radius: 50%;
  background-color: ${(props) =>
    props.theme[props.isReadOnly ? 'disabled' : 'selected'].color};
  transition: all 0.1s linear;

  ${(props) =>
    !props.isSelected &&
    css`
      width: 0;
      height: 0;
    `}
`;
