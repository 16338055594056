import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { generateDefaultSheet, makeXLS } from '@commons/utils/makeXLS';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getUserTimezone } from '@commons/utils/date';

const EXPORT_CONTENT_FILENAME = i18next.t('ORDERS.RUSH_ORDERS.EXPORT_CONTENT_FILENAME', {
  date: moment.tz(getUserTimezone()).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
});

const getColumns = (clientStoreName) => [
  {
    name: i18next.t('GENERAL.REFERENCE'),
    propertyKey: 'reference',
  },
  {
    name: getClientStoreNameTranslation(clientStoreName),
    propertyKey: 'storeName',
  },
  {
    name: i18next.t('ORDERS.RUSH_ORDERS.EXPORT_SUPPLIER'),
    propertyKey: 'rushSupplier',
  },
  {
    name: i18next.t('GENERAL.CREATION_DATE'),
    propertyKey: 'createdAt',
  },
  {
    name: i18next.t('GENERAL.DELIVERY_DATE'),
    propertyKey: 'receptionDate',
  },
  {
    name: i18next.t('GENERAL.INGREDIENT'),
    propertyKey: 'entityName',
  },
  {
    name: i18next.t('GENERAL.CATEGORY'),
    propertyKey: 'category',
  },
  {
    name: i18next.t('GENERAL.UNIT'),
    propertyKey: 'unit',
  },
  {
    name: i18next.t('GENERAL.QUANTITY'),
    propertyKey: 'quantity',
  },
  {
    name: i18next.t('ORDERS.RUSH_ORDERS.EXPORT_UNIT_COST_HT'),
    propertyKey: 'unitCostHT',
    type: 'currency',
  },
  {
    name: i18next.t('GENERAL.TOTAL_EX_TAX'),
    propertyKey: 'totalPriceHT',
    type: 'currency',
  },
];

const getUnitCost = (totalPrice, quantity) => {
  if (!quantity) {
    return '';
  }

  if (totalPrice === 0) {
    return 0;
  }

  return totalPrice / quantity;
};

const formatDataForExport = (rushOrderData) => {
  if (isEmpty(rushOrderData)) {
    return [];
  }

  const formattedRushOrders = [];

  rushOrderData.forEach((order) => {
    const orderEntities = get(order, 'rushOrderEntities', []);
    const storeTimezone = get(order, 'lnkStoreRushOrderrel.timezone', '');

    orderEntities.forEach((entity) => {
      formattedRushOrders.push({
        reference: order.reference || '',
        storeName: get(order, 'lnkStoreRushOrderrel.name', order.storeName),
        storeTimezone: get(order, 'lnkStoreRushOrderrel.timezone', ''),
        rushSupplier: order.rushSupplier || '',
        createdAt: moment
          .tz(order.createdAt, getUserTimezone())
          .format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
        receptionDate: moment
          .tz(order.receptionDate, storeTimezone)
          .format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
        entityName: get(entity, 'lnkEntityrel.name', entity.name),
        category: get(entity, 'lnkEntityrel.category', entity.category),
        unit: get(entity, 'unit.value', entity.unit),
        quantity: entity.quantity || '',
        unitCostHT: getUnitCost(entity.totalPrice, entity.quantity),
        totalPriceHT: get(entity, 'total', entity.totalPrice),
      });
    });
  });

  return formattedRushOrders;
};

/**
 * Format data to contains all fields mandatory to be export
 *
 * @param {object[]} rushOrderData  - an array of rushOrders
 * @param {string} clientStoreName  - storeName of Client
 * @param {Currency} currency       - the currency of the client
 * @param {string} fileName         - (optional) the name of the file to create
 * @returns
 */
export const exportContent = (
  rushOrderData,
  clientStoreName,
  currency,
  fileName = EXPORT_CONTENT_FILENAME,
) => {
  const formattedData = formatDataForExport(rushOrderData);

  const sheets = [
    generateDefaultSheet(
      i18next.t('ORDERS.RUSH_ORDERS.EXPORT_CONTENT_SHEETNAME'),
      getColumns(clientStoreName),
      formattedData,
      currency,
    ),
  ];

  makeXLS(fileName, sheets);
};
