export const getPasswordValidation = (password, rules) => {
  if (!password) {
    return;
  }

  const updatedRules = Object.keys(rules).reduce(
    (acc, ruleName) => {
      if (ruleName === 'all') {
        acc[ruleName] = rules[ruleName];
        return acc;
      }

      acc[ruleName] = { ...rules[ruleName], isValid: !!password.match(rules[ruleName].regex) };

      return acc;
    },
    {
      ...rules,
    },
  );

  const isAllValid = Object.values(updatedRules).every((rule) => {
    if (!rule.message) {
      return true;
    }

    return rule.isValid;
  });

  Object.assign(updatedRules.all, { isValid: isAllValid });

  return updatedRules;
};

export default getPasswordValidation;
