import i18next from 'i18next';
import React from 'react';

import {
  Image,
  ItemNameContainer,
  Name,
} from '@commons/TableForm/TableFormInModal/styledComponents';
import { INPUT_WIDTH, Tooltip } from '@commons/utils/styledLibraryComponents';

import { FORM_INPUTS, RUSH_ORDER_INPUTS } from './formInputsConfigurations';

const renderNameColumn = (item, itemPlaceholder) => (
  <ItemNameContainer>
    <Image src={item.img ? item.img : itemPlaceholder}></Image>
    <Name>{item.name}</Name>
  </ItemNameContainer>
);

export const getTableFormColumns = (currency = {}) => {
  const itemPlaceholder = '/images/inpulse/supplier-product-photo-placeholder.svg';

  return [
    {
      name: i18next.t('GENERAL.INGREDIENT'),
      width: INPUT_WIDTH.LARGE,
      input: FORM_INPUTS[RUSH_ORDER_INPUTS.NAME],
      isFixed: true,
      customRender: (item) => renderNameColumn(item, itemPlaceholder),
    },
    {
      name: i18next.t('GENERAL.UNIT'),
      width: INPUT_WIDTH.MEDIUM,
      input: FORM_INPUTS[RUSH_ORDER_INPUTS.UNIT],
    },
    {
      name: i18next.t('GENERAL.QUANTITY'),
      width: INPUT_WIDTH.MEDIUM,
      input: FORM_INPUTS[RUSH_ORDER_INPUTS.QUANTITY],
      isRequired: true,
      render: () => <Tooltip text={i18next.t('ORDERS.RUSH_ORDERS.FORM_QUANTITY_TOOLTIP')} />,
    },
    {
      name: i18next.t('GENERAL.TOTAL_EXCL_TAXES_WITH_CURRENCY', {
        currencyCode: currency.alphabeticCode,
      }),
      width: INPUT_WIDTH.MEDIUM,
      input: FORM_INPUTS[RUSH_ORDER_INPUTS.TOTAL],
      isRequired: true,
    },
  ];
};
