import styled, { css } from 'styled-components';

export const InpulseDateContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => {
    if (props.borderColor && !props.renderCircle) {
      return css`
        border: 1px solid ${props.borderColor === 'black' && props.theme.colors?.greys?.darkest};
        height: -webkit-fill-available;
        padding-top: 3px;
      `;
    }
  }}
`;

export const CircleContainer = styled.div`
  width: 100%;
  height: 100%;
  display: ${(props) => (props.renderCircle ? 'flex' : 'none')};
`;

export const Circle = styled.div`
  margin: auto;
  flex-direction: column;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  border: ${(props) =>
    props.borderColor &&
    `1px solid ${props.borderColor === 'black' && props.theme.colors?.greys?.darkest}`};
  background-color: ${(props) => props.theme.colors?.brand.primaryLight} !important;
  display: ${(props) => (props.renderCircle ? 'flex' : 'none')};
`;

export const InpulseDate = styled.div`
  padding-bottom: ${(props) => props.renderCircle && props.isDot && '5px'};
  display: ${(props) => !props.renderCircle && 'none'};
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DotContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: ${(props) => props.renderCircle && props.isDot && '-2px'};
`;

export const Dot = styled.div`
  float: right;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors?.brand.primary};
  margin-right: ${(props) => (props.renderCircle ? '2px' : '5px')};
  display: ${(props) => (props.isDot ? 'flex' : 'none')};

  ${(props) => {
    if (props.color === 'red') {
      return css`
        background-color: ${(props) => props.theme.colors?.infoRed};
      `;
    }

    if (props.color === 'green') {
      return css`
        background-color: ${(props) => props.theme.colors?.brand?.primary};
      `;
    }

    if (props.color === 'orange') {
      return css`
        background-color: ${(props) => props.theme.colors?.infoOrange};
      `;
    }
  }}
`;

export const Date = styled.div``;

export default {
  InpulseDateContainer,
  InpulseDate,
  DateContainer,
  DotContainer,
  CircleContainer,
  Dot,
  Circle,
  Date,
};
