import { connect } from 'react-redux';
import { get, head } from 'lodash';
import { getTimezonesForCountry } from 'countries-and-timezones';
import React, { useState } from 'react';

import { getClientInfo } from '@selectors/client';

import { SupplierProfileModalContainer } from '@admin/suppliers/suppliers/SupplierProfileModal/styledComponents';
import SupplierProfileModalInformation from '@admin/suppliers/suppliers/SupplierProfileModal/SupplierProfileModalInformation';

import { handleDeliveryOptionsValidation } from '../modalConfiguration';

const SetSupplierProfileInformationsModal = (props) => {
  const {
    supplier,
    actionType,
    supplierProfileInformationsData,
    setSupplierProfileInformationsData,
    client,
  } = props;

  const [countryHasUniqueTimezone, setCountryHasUniqueTimezone] = useState(true);

  const supplierProfile = get(supplierProfileInformationsData, 'supplierProfile', {});
  const deliveryOptions = get(supplierProfileInformationsData, 'deliveryOptions', []);

  // Add a new delivery option
  const getUpdatedDeliveryOptions = (deliveryOptions) => {
    const valid = handleDeliveryOptionsValidation(deliveryOptions);

    if (get(valid, 'isValid', false)) {
      let updatedDeliveryOptions = deliveryOptions;
      updatedDeliveryOptions.push({ deliveryDay: '', deliveryLeadTime: '1' });

      return updatedDeliveryOptions;
    }
  };

  const handleSupplierProfileInformationDataChange = (key, value) => {
    setSupplierProfileInformationsData({
      ...supplierProfileInformationsData,
      [key]: value,
    });
  };

  return (
    <SupplierProfileModalContainer hasCustomStyle>
      <SupplierProfileModalInformation
        actionType={actionType}
        addDeliveryOption={() => {
          const newDeliveryOptions = getUpdatedDeliveryOptions(deliveryOptions);

          handleSupplierProfileInformationDataChange('deliveryOptions', newDeliveryOptions);
        }}
        areDeliveryOptionsValid={() => handleDeliveryOptionsValidation(deliveryOptions)}
        countryHasUniqueTimezone={countryHasUniqueTimezone}
        deleteDeliveryOption={(index) => {
          deliveryOptions.splice(index, 1);

          handleSupplierProfileInformationDataChange('deliveryOptions', deliveryOptions);
        }}
        deliveryOptions={deliveryOptions}
        handleDeliveryOptionChange={(id, index, field) => {
          let updatedDeliveryOptions = deliveryOptions;
          updatedDeliveryOptions[index][field] = id;

          handleSupplierProfileInformationDataChange('deliveryOptions', updatedDeliveryOptions);
        }}
        handleSupplierProfileChange={(value, key) => {
          if (key === 'country') {
            const countryTimezones = getTimezonesForCountry(value);

            setCountryHasUniqueTimezone(countryTimezones.length === 1);

            const defaultValue = client.hasMultipleTimezones ? null : client.defaultTimezone;

            handleSupplierProfileInformationDataChange('supplierProfile', {
              ...supplierProfile,
              country: value,
              timezone:
                client.hasMultipleTimezones && countryTimezones.length === 1
                  ? head(countryTimezones).name
                  : defaultValue,
            });

            return;
          }

          handleSupplierProfileInformationDataChange('supplierProfile', {
            ...supplierProfile,
            [key]: value,
          });
        }}
        supplier={supplier}
        supplierProfile={supplierProfile}
      />
    </SupplierProfileModalContainer>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

export default connect(mapStateToProps)(SetSupplierProfileInformationsModal);
