import { get } from 'lodash';
import i18next from 'i18next';

import { inventoryList } from '@services/inventoryList';

const processInventoryList = (inventoryList) =>
  inventoryList.map((inventory) => {
    inventory.total = get(inventory, 'total', '');

    inventory.storeName = get(inventory, 'lnkStoreInventorylistrel.name');
    inventory.storeTimezone = get(inventory, 'lnkStoreInventorylistrel.timezone');

    inventory.template = {
      id: get(inventory, 'inventoryListTemplateId', null),
      name: get(
        inventory,
        'lnkInventoryListTemplateInventorylistrel.name',
        i18next.t('STOCKS.STOCKS.FORM_FULL_INVENTORY_TEMPLATE'),
      ),
    };

    return inventory;
  });

/**********************/
/* Fetch Data Methods */
/**********************/

/**
 * Retrieve the information related to the orders matching the given list of filters
 *
 * @param {Array} storeIds                    - The store ids on which retrieve the data
 * @param {Function} onInventoriesChange      - Method to set the local state of the orders
 * @param {Function} setTotalInventoriesCount - Method to set the total number of orders the route without pagination will send
 * @param {String} search                     - The search filter on which retrieve the data
 * @param {Number} skip                       - The number of elements to skip
 * @param {Number} limit                      - The number of elements to retrieve
 * @param {String} orderBy                    - The field on which order to list
 * @param {String} orderType                  - The order type to apply on order list
 * @param {String} params                     - A query string containing a set of query params to attached to request
 *
 * @returns {void}
 */
export async function fetchPaginatedInventories(
  storeIds,
  onInventoriesChange,
  setTotalInventoriesCount,
  search,
  skip,
  limit,
  orderBy,
  orderType,
  params,
  inventoryListTemplateIds,
) {
  const result = await inventoryList.getPaginatedInventoriesListOfStores(
    storeIds,
    search,
    skip,
    limit,
    orderBy,
    orderType,
    params,
    inventoryListTemplateIds,
  );

  const formatedInventories = processInventoryList(result.inventories);

  if (onInventoriesChange) {
    onInventoriesChange(formatedInventories);
  }

  if (setTotalInventoriesCount) {
    setTotalInventoriesCount(result.totalCount);
  }

  return {
    inventories: formatedInventories,
    totalCount: result.totalCount,
  };
}

/**
 * Retrieve the number of inventories associated to the given list of store ids
 *
 * @param {Array} storeIds  - The store ids on which retrieve the orders count
 *
 * @returns {Promise<Number|null>} The number of inventories found, NULL if an error happens
 */
export async function fetchInventoriesStoresCount(storeIds) {
  try {
    const { count } = await inventoryList.fetchInventoriesStoresCount(storeIds);

    return count;
  } catch (err) {
    return null;
  }
}

/**
 * Delete information related to an inventoryList by inventoryListId
 *
 * @param {Array} inventoryListId  - The id of the inventoryList to delete
 *
 * @returns {void}
 */
export async function deleteInventoryListById(inventoryListId) {
  await inventoryList.deleteInventoryListById(inventoryListId);
}
