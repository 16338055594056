import { APIcaller } from './APIcaller';

// TODO: [cleanup] remove store_id param
const getInventoriesFromInventoriesListOfStore = (store_id, inventoryListId) =>
  APIcaller.apiGet(`/stocks/inventories/${inventoryListId}`);

const createInventory = (
  inventoryList,
  supplierProducts,
  recipes,
  triggerComputeOrderAnalytics,
  userTimezone,
) =>
  APIcaller.apiPost(`/stocks/inventories/create`, {
    ...inventoryList,
    supplierProducts,
    recipes,
    triggerComputeOrderAnalytics,
    userTimezone,
  });

const updateInventory = async (
  inventoryList,
  supplierProducts,
  recipes,
  triggerComputeOrderAnalytics,
  userTimezone,
) =>
  APIcaller.apiPut(`/stocks/inventories/${inventoryList.id}`, {
    ...inventoryList,
    supplierProducts,
    recipes,
    triggerComputeOrderAnalytics,
    userTimezone,
  });

export const inventory = {
  getInventoriesFromInventoriesListOfStore,
  createInventory,
  updateInventory,
};
