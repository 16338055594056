const WEEKDAYS = [
  { id: '0', name: 'Lundi' },
  { id: '1', name: 'Mardi' },
  { id: '2', name: 'Mercredi' },
  { id: '3', name: 'Jeudi' },
  { id: '4', name: 'Vendredi' },
  { id: '5', name: 'Samedi' },
  { id: '6', name: 'Dimanche' },
];

export const getLimitOrderDay = (deliveryDay, deliveryLeadTime) => {
  if (deliveryDay && deliveryLeadTime) {
    let nbWeek = 0;
    let limitOrderWeekDay = deliveryDay;
    let deliveryLeadTimeIdx = deliveryLeadTime;
    while (deliveryLeadTimeIdx > 0) {
      limitOrderWeekDay--;
      deliveryLeadTimeIdx--;
      if (limitOrderWeekDay < 0) {
        limitOrderWeekDay = 6;
        nbWeek++;
      }
    }
    return `${WEEKDAYS[limitOrderWeekDay]['name']} ${nbWeek > 0 ? `(S-${nbWeek})` : ''}`;
  } else return '-';
};
