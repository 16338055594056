import { connect } from 'react-redux';
import { isEmpty, sumBy } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { CHANNEL_ID_KEY_BY_NAME, CHANNELS } from '@commons/constants/channel';
import { ENTITY_UNITS } from '@commons/constants/units';
import { INPUT_WIDTH, Tooltip } from '@commons/utils/styledLibraryComponents';
import { TableFormFooter } from '@commons/TableForm/Footer';
import DisplayNumber, { formatNumber } from '@commons/DisplayNumber';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { getClientInfo } from '@selectors/client';

import { getPriceExcludingVAT } from '@admin/products/products/detail/components/ProductInfo/utils/getPriceExcludingVAT';
import ExportRecipeWithCompositionAndPreparation from '@admin/products/recipes/components/ExportRecipeWithCompositionAndPreparation';

import RecipeCompositionSearchBar from '../RecipeCompositionSearchBar';

import {
  FooterContainer,
  FooterLeftContainer,
  FooterRightContainer,
  InformationContainer,
  LabelContainer,
  LinkImage,
} from './styledComponents';

const InformationComponent = ({ label, value, link, tooltipText, width }) => (
  <InformationContainer width={width}>
    <LabelContainer>
      <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
        {label} :
      </Text>
      {!!link && (
        <LinkImage src="/images/inpulse/open-a-new-black-small.svg" onClick={link.onClick} />
      )}
      {!!tooltipText && !!tooltipText.length && (
        <div style={{ marginTop: '-2px' }}>
          <Tooltip displayBigger={true} text={tooltipText} />
        </div>
      )}
    </LabelContainer>
    <Text color={ENUM_COLORS.BLACK} font={ENUM_FONTS.TEXT_MIDDLE_BOLD}>
      {value}
    </Text>
  </InformationContainer>
);

export const getEntityUnit = (unit) => {
  if (unit === ENTITY_UNITS.UNIT) {
    return 'u.';
  }

  return unit;
};

const RecipeComponentsFormHeader = ({
  client: { defaultChannelId },
  allergens,
  addEntity,
  isReadOnly,
  isKitchen,
  recipe,
  recipesAvailable,
  setWarningModalParams,
  supplierProductsAvailable,
  setOpenCreateIngredientModal,
  onSupplierProductsAvailableChange,
  compositions,
  channels,
  currency,
  formFields,
  associatedProduct,
  isDuplicatingRecipe,
}) => {
  const [totalCost, setTotalCost] = useState({ onSite: null, delivery: null, default: null });
  const [compositionForRecipePreparation, setCompositionForRecipePreparation] =
    useState(compositions);

  const getEntityCost = (entity) => {
    if (entity.isIngredient) {
      return (entity.cost || 0) * entity.quantity;
    }

    if (!entity.hasMultipleChannels) {
      return entity.costByChannelId[defaultChannelId] * entity.quantity;
    }

    if (entity.onSite) {
      return entity.costByChannelId[CHANNEL_ID_KEY_BY_NAME[CHANNELS.ON_SITE]] * entity.quantity;
    }

    return entity.costByChannelId[CHANNEL_ID_KEY_BY_NAME[CHANNELS.DELIVERY]] * entity.quantity;
  };

  const getProductPrice = (delivery = false) => {
    if (!!associatedProduct && associatedProduct.isLinkedToCashierProduct) {
      return getPriceExcludingVAT(associatedProduct, delivery);
    }

    return delivery ? associatedProduct.deliveryPrice : associatedProduct.price;
  };

  const computeTotalCost = (entities) => {
    const entitiesWithUpdatedCost = entities.map((entity) => ({
      ...entity,
      cost: getEntityCost(entity),
    }));

    if (!recipe.hasMultipleChannels) {
      return {
        onSite: null,
        delivery: null,
        default: sumBy(entitiesWithUpdatedCost, `cost`),
      };
    }

    const totalsByChannels = entitiesWithUpdatedCost.reduce(
      (acc, entity) => {
        if (entity.isIngredient) {
          acc.onSite += entity.onSite ? entity.cost : 0;
          acc.delivery += entity.delivery ? entity.cost : 0;
          return acc;
        }

        if (!entity.hasMultipleChannels) {
          acc.onSite += entity.onSite
            ? entity.costByChannelId[defaultChannelId] * entity.quantity
            : 0;
          acc.delivery += entity.delivery
            ? entity.costByChannelId[defaultChannelId] * entity.quantity
            : 0;
          return acc;
        }

        if (entity.onSite) {
          acc.onSite +=
            entity.costByChannelId[CHANNEL_ID_KEY_BY_NAME[CHANNELS.ON_SITE]] * entity.quantity;
        }

        if (entity.delivery) {
          acc.delivery +=
            entity.costByChannelId[CHANNEL_ID_KEY_BY_NAME[CHANNELS.DELIVERY]] * entity.quantity;
        }

        return acc;
      },
      { onSite: null, delivery: null, default: null },
    );

    return totalsByChannels;
  };

  const getRatio = (cost, delivery = false) => {
    const productPrice = getProductPrice(delivery);

    // Handle division by zero
    if (!productPrice || parseFloat(productPrice) === 0) {
      return null;
    }

    return !!cost ? (cost / getProductPrice(delivery)) * 100 : null;
  };

  const openProductDetailsPage = () => {
    isKitchen
      ? window.open(`/admin/kitchen-products/products/${associatedProduct.id}/details`, '_blank')
      : window.open(`/admin/products/${associatedProduct.id}/details`, '_blank');
  };

  useEffect(() => {
    if (!compositions || !compositions.length || !formFields || !formFields.length) {
      // setCompositionForRecipePreparation([]);
      return;
    }

    const totalCompositionCost = computeTotalCost(formFields);

    setTotalCost(totalCompositionCost);

    setCompositionForRecipePreparation(compositions);
  }, [compositions, recipe.hasMultipleChannels]);

  return (
    <TableFormFooter>
      <FooterContainer>
        <FooterLeftContainer>
          <RecipeCompositionSearchBar
            addEntity={addEntity}
            channels={channels}
            compositions={compositions}
            iconSrc={'/images/inpulse/search-dmgrey-small.svg'}
            isReadOnly={isReadOnly}
            placeholder={i18next.t('ADMIN.RECIPES.ADD_COMPONENT')}
            recipe={recipe}
            recipesAvailable={recipesAvailable}
            setWarningModalParams={setWarningModalParams}
            supplierProductsAvailable={supplierProductsAvailable}
            width={INPUT_WIDTH.LARGE}
            onCreationClick={() => setOpenCreateIngredientModal(true)}
            onSupplierProductsAvailableChange={onSupplierProductsAvailableChange}
          />
          {!isEmpty(associatedProduct) && !isDuplicatingRecipe && (
            <InformationComponent
              label={i18next.t('ADMIN.PRODUCTS.INFO_TITLE')}
              link={{
                onClick: openProductDetailsPage,
              }}
              value={associatedProduct.name}
              width={'240px'}
            />
          )}
          <InformationComponent
            label={i18next.t('ADMIN.RECIPES.COST_EX_TAX_WITH_CURRENCY_BY_UNIT', {
              currency: currency.alphabeticCode,
            })}
            tooltipText={
              recipe.hasMultipleChannels
                ? i18next.t('ADMIN.RECIPES.DETAILS_COSTS_TOOLTIP_MULTIPLE_CHANNELS')
                : null
            }
            value={
              recipe.hasMultipleChannels ? (
                // "|| null" so we show '-' when no quantity is selected
                `${formatNumber(
                  totalCost.onSite || null,
                  currency.numberDecimals,
                )} | ${formatNumber(totalCost.delivery || null, currency.numberDecimals)}`
              ) : (
                <DisplayNumber currency={currency} number={totalCost.default} />
              )
            }
          />
          {!isEmpty(associatedProduct) && !isDuplicatingRecipe && (
            <>
              <InformationComponent
                label={i18next.t('ADMIN.RECIPES.PRICE_EXCL_TAXES_WITH_CURRENCY', {
                  alphabeticCode: currency.alphabeticCode,
                })}
                tooltipText={
                  recipe.hasMultipleChannels &&
                  i18next.t('ADMIN.RECIPES.PRICE_EXCL_TAXES_MULTIPLE_CHANNELS_TOOLTIP')
                }
                value={
                  recipe.hasMultipleChannels
                    ? `${formatNumber(getProductPrice(), currency.numberDecimals)} | ${formatNumber(
                        getProductPrice(true),
                        currency.numberDecimals,
                      )}`
                    : formatNumber(getProductPrice(), currency.numberDecimals)
                }
              />
              <InformationComponent
                label={i18next.t('ADMIN.PRODUCTS.RECIPE_ASSOCIATION_RATIO')}
                tooltipText={
                  recipe.hasMultipleChannels
                    ? i18next.t('ADMIN.RECIPES.PRICE_COST_RATIO_MULTIPLE_CHANNELS')
                    : i18next.t('ADMIN.RECIPES.PRICE_COST_RATIO')
                }
                value={
                  recipe.hasMultipleChannels
                    ? `${formatNumber(
                        getRatio(totalCost.onSite),
                        currency.numberDecimals,
                      )}% | ${formatNumber(
                        getRatio(totalCost.delivery, true),
                        currency.numberDecimals,
                      )}%`
                    : `${formatNumber(getRatio(totalCost.default), currency.numberDecimals)}%`
                }
              />
            </>
          )}
        </FooterLeftContainer>
        <FooterRightContainer>
          <ExportRecipeWithCompositionAndPreparation
            allergens={allergens}
            compositionSettings={compositionForRecipePreparation}
            recipe={recipe}
            type="recipe"
          />
        </FooterRightContainer>
      </FooterContainer>
    </TableFormFooter>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps, null)(RecipeComponentsFormHeader);
