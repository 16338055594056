import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import { getTheme } from '../../../../utils/theme';
import { NestedDataItemProps } from '../../../interface';

import { Container, Link } from './styledComponents';

const NestedDataItem = (props: NestedDataItemProps): JSX.Element => {
  const { theme, value, large, narrow, nbColumn, isFirst, link } = props;

  const updatedTheme = getTheme(theme);

  return (
    <ThemeProvider theme={updatedTheme}>
      <Container
        large={large}
        narrow={narrow}
        nbColumn={nbColumn}
        isFirst={isFirst}
      >
        {link ? (
          <Link href={link} target="_blank">
            {value}
          </Link>
        ) : (
          value
        )}
      </Container>
    </ThemeProvider>
  );
};

export default NestedDataItem;
