import PropTypes from 'prop-types';
import React from 'react';

import { ENUM_MODULE_NAME } from '../../../commons/utils/features';

import { ADMIN_FEATURE_PATHS } from '../../../commons/utils/featurePaths';

import { ModuleContainer } from '../../ModuleContainer';

export const AdminInventories = (props) => {
  const { userRights, history } = props;

  return (
    <ModuleContainer
      history={history}
      moduleFeaturePaths={[ADMIN_FEATURE_PATHS.ADMIN_INVENTORIES_STORAGE_AREAS]}
      moduleName={ENUM_MODULE_NAME.ADMIN}
      style={{ overflowY: 'scroll' }}
      userRights={userRights}
    />
  );
};

AdminInventories.propTypes = {
  userRights: PropTypes.array,
  history: PropTypes.any, // react-router-dom's route prop
  moduleName: PropTypes.oneOf(Object.values(ENUM_MODULE_NAME)),
  moduleFeaturePaths: PropTypes.arrayOf(PropTypes.string),
};

import InventoryListTemplateCreation from './inventoryListTemplates/creation';
import InventoryListTemplateDetails from './inventoryListTemplates/details';
import InventoryListTemplateRecipes from './inventoryListTemplates/recipes';
import InventoryListTemplates from './inventoryListTemplates';
import InventoryListTemplateSupplierProducts from './inventoryListTemplates/supplierProducts';

import StorageAreaCreation from './storageAreas/creation';
import StorageAreaDetails from './storageAreas/details';
import StorageAreaRecipes from './storageAreas/recipes';
import StorageAreas from './storageAreas';
import StorageAreaSupplierProducts from './storageAreas/supplierProducts';

const AdminInventoriesModule = {
  AdminInventories,

  InventoryListTemplates,
  InventoryListTemplateCreation,
  InventoryListTemplateDetails,
  InventoryListTemplateSupplierProducts,
  InventoryListTemplateRecipes,

  StorageAreas,
  StorageAreaCreation,
  StorageAreaDetails,
  StorageAreaSupplierProducts,
  StorageAreaRecipes,
};

export default AdminInventoriesModule;
