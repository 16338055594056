import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { Button, InputIncrement } from '@commons/utils/styledLibraryComponents';
import { capitalizeFirstLetter } from '@commons/utils/format';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { ENTITY_UNITS } from '@commons/constants/units';
import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import DisplayNumber from '@commons/DisplayNumber';
import Footer from '@commons/Footer/Footer';

import { getEntityUnit } from '@stocks/utils';

import { computeRecipeProductionTotal } from '../utils/compute';
import {
  Divider,
  DrawerContainer,
  DrawerContentContainer,
  TitleContainer,
  List,
  InlineContainer,
  ThumbnailContainer,
  Thumbnail,
  NameContainer,
  Name,
  DisplayNameHovered,
  DrawerText,
  ButtonsContainer,
  ProductionQuantities,
} from '../styledComponents';

const RECIPE_PICTURE_PLACEHOLDER = '/images/inpulse/placeholder-fruit-square.svg';
const MAXIMUM_CHARACTER_BEFORE_SHOWING_BIGGER_NAME = 40;

const ProductionDrawerContent = (props) => {
  const {
    recipe,
    productionQuantity,
    handleQuantityUpdate,
    selectedDate,
    quantity,
    setQuantity,
    readOnly,
    onClose,
    plannedProduction,
    hasProductionPlanning,
  } = props;

  const [displayBigger, setDisplayBigger] = useState(false);
  const [computedTotal, setComputedTotal] = useState(0);

  const buttonStyle = { padding: '0px 40px' };

  useEffect(() => {
    // When we are writing quantity, we can write "-" to express negative number
    if (!isNaN(quantity)) {
      const total = computeRecipeProductionTotal(productionQuantity, quantity);

      setComputedTotal(total);
    }
  }, [quantity, productionQuantity]);

  const formatEntityUnit = (withCount = false) => {
    const formattedUnit = getEntityUnit(recipe.unit, false, withCount ? computedTotal : 1);

    return recipe.unit === ENTITY_UNITS.UNIT ? formattedUnit.toLowerCase() : formattedUnit;
  };

  return (
    <DrawerContainer>
      <DrawerContentContainer>
        <TitleContainer>
          {capitalizeFirstLetter(selectedDate.format(DATE_DISPLAY_FORMATS.FULL_DAY_DATE_MONTH))}
        </TitleContainer>

        <List>
          <InlineContainer>
            <ThumbnailContainer>
              <Thumbnail src={recipe.img || RECIPE_PICTURE_PLACEHOLDER} />
            </ThumbnailContainer>
            <NameContainer>
              <Name onMouseOver={() => setDisplayBigger(true)}>{recipe.name}</Name>
              {displayBigger &&
                recipe.name.length > MAXIMUM_CHARACTER_BEFORE_SHOWING_BIGGER_NAME && (
                  <DisplayNameHovered onMouseOut={() => setDisplayBigger(false)}>
                    {recipe.name}
                  </DisplayNameHovered>
                )}
            </NameContainer>
          </InlineContainer>
        </List>
        <Divider />
        <ProductionQuantities>
          <List>
            <DrawerText>
              {i18next.t('PRODUCTION.CENTRAL_KITCHEN.RECIPE_PRODUCTION_TOTAL')} :
            </DrawerText>
            <InlineContainer gap>
              <DisplayNumber font={ENUM_FONTS.TEXT_MIDDLE_BOLD} number={computedTotal} />
              <DrawerText isBold>{formatEntityUnit(true)}</DrawerText>
            </InlineContainer>
          </List>
          {hasProductionPlanning && (
            <List>
              <DrawerText isLight>
                {i18next.t('PRODUCTION.PRODUCTION.HEADER_TO_PRODUCE')} :
              </DrawerText>
              <InlineContainer gap>
                <DisplayNumber
                  color={ENUM_COLORS.DARK}
                  font={ENUM_FONTS.TEXT_MIDDLE_BOLD}
                  number={plannedProduction}
                />
                <DrawerText isBold isDisabled>
                  {formatEntityUnit(true)}
                </DrawerText>
              </InlineContainer>
            </List>
          )}
        </ProductionQuantities>
        <Divider />
        <List>
          <DrawerText withMarginBottom>
            {`${i18next.t(
              'PRODUCTION.CENTRAL_KITCHEN.RECIPE_PRODUCED_QUANTIY',
            )} (${formatEntityUnit()}) :`}
          </DrawerText>
          <InputIncrement
            isDisabled={readOnly}
            limitValues={computedTotal <= 0 ? { min: 0 } : {}}
            setValue={(value) => setQuantity(value)}
            value={quantity}
            width={'352px'}
          />
        </List>
      </DrawerContentContainer>
      <Divider />

      <Footer
        customStyle={{
          'box-shadow': 'none',
          'background-color': 'transparent',
          width: 'auto',
          height: 'calc(100% - calc(100% - (64px + 24px)))',
          padding: '24px 0px',
        }}
      >
        <ButtonsContainer>
          <Button
            buttonCustomStyle={buttonStyle}
            color={'inpulse-outline'}
            handleClick={() => {
              setQuantity(0);
              onClose();
            }}
            icon={'/images/inpulse/close-black-small.svg'}
            label={i18next.t(readOnly ? 'GENERAL.CLOSE' : 'GENERAL.CANCEL')}
            minWidth={168}
          />
          <Button
            buttonCustomStyle={buttonStyle}
            color={'inpulse-default'}
            handleClick={() => {
              handleQuantityUpdate(recipe.id, quantity);
              onClose();
            }}
            icon={'/images/inpulse/check-white-small.svg'}
            isDisabled={readOnly || computedTotal < 0}
            label={i18next.t('GENERAL.VALIDATE')}
            minWidth={168}
          />
        </ButtonsContainer>
      </Footer>
    </DrawerContainer>
  );
};

ProductionDrawerContent.PropTypes = {
  recipe: PropTypes.object.isRequired,
  productionQuantity: PropTypes.number.isRequired,
  handleQuantityUpdate: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  quantity: PropTypes.number.isRequired,
  setQuantity: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

ProductionDrawerContent.defaultProps = {
  readOnly: false,
};

export default ProductionDrawerContent;
