import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import {
  DateContainer,
  DayContainer,
  DotContainer,
  CircleContainer,
  Dot,
  Circle,
} from './styledComponents';

import { DATE_PICKER_DOT_COLOR } from '../constants';

export const DatePickerDay = (props) => {
  const { day, hasDot, hasCircle, color } = props;

  return (
    <DateContainer hasCircle={hasCircle}>
      {!!hasCircle && (
        <CircleContainer>
          <Circle>
            <DotContainer hasDot={hasDot}>
              <Dot color={color} hasCircle={hasCircle} hasDot={hasDot} />
            </DotContainer>
            <DayContainer hasDot={hasDot}>{moment(day).format('D')}</DayContainer>
          </Circle>
        </CircleContainer>
      )}
      {!hasCircle && (
        <>
          <DotContainer hasDot={hasDot}>
            <Dot color={color} hasCircle={hasCircle} hasDot={hasDot} />
          </DotContainer>
          <DayContainer hasDot={hasDot}>{moment(day).format('D')}</DayContainer>
        </>
      )}
    </DateContainer>
  );
};

DatePickerDay.propTypes = {
  day: PropTypes.any.isRequired, // day sent by datePicker
  hasDot: PropTypes.bool, // whether the day must display dot
  hasCircle: PropTypes.bool, // whether the day must display a circle (usually used for Today)
  color: PropTypes.oneOf(Object.values(DATE_PICKER_DOT_COLOR)), // dot color
};

DatePickerDay.defaultProps = {
  hasDot: false,
  hasCircle: false,
  color: undefined,
};

export default DatePickerDay;
