import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;

  gap: 24px;

  align-items: center;
`;

export const PeriodDatePickerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 8px;

  align-items: center;
`;

export const LabelContainer = styled.div`
  width: 240px;
  display: flex;
  flex-direction: row;
`;

export const LabelRequiredCharacter = styled.div`
  margin-right: 2px;

  color: ${(props) => props.theme.dropdown.labelRequiredCharacter.default.color};
`;
