import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1;
  position: fixed;

  top: 0px;
  left: 0px;

  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: ${(props) => props.theme.colors.greys.darkest};
  opacity: 64%;
  overflow: hidden;
  z-index: 1;
  position: absolute;
`;

export const ModalContainer = styled.div`
  margin: auto;
  position: relative;

  background: ${(props) => props.theme.colors.greys.lightest};

  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);

  width: 560px;
  height: 144px;

  display: flex;
  flex-direction: column;
  z-index: 2;
  overflow: hidden;
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 50%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font: ${(props) => props.theme.fonts.h1InterNonBold};

  background: ${(props) => props.theme?.colors.greys.lighter};
  padding: 24px;
`;

export const ModalContent = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseModalImg = styled.img`
  width: 8.79px;
  height: 13.18px;
  &:hover {
    cursor: pointer;
  }
`;

export const ProgressBarContainer = styled.div`
  width: 512px;
  height: 24px;

  .progress {
    width: 512px;
    height: 24px;
    background-color: ${(props) => props.theme?.colors.greys.lighter};
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    border: 1px solid ${(props) => props.theme?.colors.greys.light};
    border-radius: 20px;
  }

  .progress-bar {
    width: 512px;
    height: 24px;
    background-color: ${(props) => props.theme?.colors.brand.primary};
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    border-radius: 20px;
  }
`;
