import { head, isEmpty, omit } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { groupArrayByPropertyAndEmptyValue } from '@commons/utils/format';
import ItemListSorter, { STANDARD_PRICE } from '../../../commons/ItemListSorter';

import { ConfirmationModal } from '@commons/Modals/ConfirmationModal';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_DELETE_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import CheckboxList, { formatItemsForCheckboxList } from '@commons/CheckboxList';

/* Manage categories or sub-categories modals */
export const getCategorySubCategoryModalParams = (
  items,
  field,
  entityName,
  handleItemChangeModalBatch,
) => {
  const itemsGroupedByField = groupArrayByPropertyAndEmptyValue(items, field);

  return {
    type: 'action',
    width: '542px',
    height: 'auto',
    maxHeight: '560px',
    icon: '/images/inpulse/gear-ipblack-small.svg',
    title:
      field === 'category'
        ? i18next.t('GENERAL.UPDATE_CATEGORIES')
        : i18next.t('GENERAL.UPDATE_SUB_CATEGORIES'),
    component: ItemListSorter,
    data: {
      data: itemsGroupedByField,
      properties: Object.keys(itemsGroupedByField).sort(),
      entityName,
      field,
      onChange: (values) => handleItemChangeModalBatch(values, true, field),
    },
    closeConfig: {
      title: i18next.t('GENERAL.LEAVE'),
      content: (
        <div>
          {field === 'category'
            ? i18next.t('GENERAL.MODAL_CATEGORIES_CLOSE_CONFIRMATION_CONTENT')
            : i18next.t('GENERAL.MODAL_SUBCATEGORIES_CLOSE_CONFIRMATION_CONTENT')}
        </div>
      ),
    },
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_SAVE_BUTTON(),
        isDisabled: true,
      },
    ],
  };
};

/* Supplier products deletion warning modal params */
export const getSupplierProductsDeletionModalParams = (
  handleSupplierProductsDeletion,
  supplierProducts,
) => {
  if (isEmpty(supplierProducts)) {
    return;
  }

  const isSingleDelete = supplierProducts.length === 1;

  return {
    type: 'warning',
    width: '542px',
    height: 'auto',
    title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_SUPPLIER_PRODUCT', {
      count: supplierProducts.length,
    }),
    icon: '/images/inpulse/trash-white.svg',
    component: ConfirmationModal,
    data: {
      shouldInterpretHtml: true,
      content: isSingleDelete
        ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_MODAL_CONTENT_SINGLE', {
            supplierProductName: head(supplierProducts).name,
          })
        : i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_MODAL_CONTENT_MULTIPLE'),
    },
    closeConfig: null,
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_DELETE_BUTTON(),
        handleClick: () => handleSupplierProductsDeletion(supplierProducts, isSingleDelete),
      },
    ],
  };
};

/* Assign category or sub-category modals */
export const getAssignCategorySubCategoryModalParams = (
  items,
  selectedItems,
  field,
  handleItemChangeModalBatch,
) => {
  const itemsGroupedByField = groupArrayByPropertyAndEmptyValue(items, field);

  const formattedData = selectedItems.reduce((result, current) => {
    if (!result[current[field]]) {
      result[current[field]] = {
        associatedItems: [],
      };
    }

    result[current[field]].associatedItems.push(current);

    return result;
  }, {});

  const formattedItems = [];
  for (const [key, value] of Object.entries(itemsGroupedByField)) {
    formattedItems.push({ value: key, ...value });
  }

  return {
    type: 'action',
    width: '542px',
    height: 'auto',
    maxHeight: '560px',
    icon: '/images/inpulse/link-black-small.svg',
    title: i18next.t(
      field === 'category'
        ? 'GENERAL.LIST_ACTION_ASSIGN_CATEGORY'
        : 'GENERAL.LIST_ACTION_ASSIGN_SUBCATEGORY',
    ),
    itemsCount: selectedItems.length,
    component: ItemListSorter,
    data: {
      data: formattedData,
      properties: formattedItems,
      field,
      onChange: (values) => {
        handleItemChangeModalBatch(values, false, field);
      },
      groupAllAtOnce: true,
    },
    closeConfig: {
      title: i18next.t('GENERAL.LEAVE'),
      content: (
        <div>
          {i18next.t(
            field === 'category'
              ? 'GENERAL.MODAL_CATEGORIES_CLOSE_CONFIRMATION_CONTENT'
              : 'GENERAL.MODAL_SUBCATEGORIES_CLOSE_CONFIRMATION_CONTENT',
          )}
        </div>
      ),
    },
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_SAVE_BUTTON(),
        isDisabled: true,
      },
    ],
  };
};

export const getMultiPriceModalParams = (
  multiPriceEntries,
  existingPriceInputIds,
  handlePriceInputsChange,
  handlePriceInputsSave,
  disableSaveMultiPrices,
  resetMultiPriceStates,
) => ({
  type: 'action',
  width: '542px',
  height: 'auto',
  maxHeight: '344px',
  icon: '/images/inpulse/gear-ipblack-small.svg',
  title: i18next.t('GENERAL.LIST_ACTION_MANAGE_MULTI_PRICE'),
  component: ItemListSorter,
  data: {
    data: multiPriceEntries,
    properties: Object.keys(multiPriceEntries).sort(),
    entityName: i18next.t('GENERAL.MODAL_MULTI_PRICE_SUPPLIER_PRODUCT_SHORTENED'),
    disableCreateItem: Object.keys(multiPriceEntries).length >= 3, // we do not allow more than 2 price inputs (in addition to the standard price)
    createItemLabel: i18next.t('GENERAL.MODAL_MULTI_PRICE_ADD_PRICE'),
    onChange: (newPriceEntries) => handlePriceInputsChange(existingPriceInputIds, newPriceEntries),
    checkForPropertyNone: false,
  },
  handleCloseCleanUp: resetMultiPriceStates,
  closeConfig: {
    title: i18next.t('GENERAL.LEAVE'),
    content: <div>{i18next.t('GENERAL.MODAL_MULTI_PRICE_CLOSE_CONFIRMATION_CONTENT')}</div>,
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      handleClick: () =>
        // the ItemsList will always have the standard price, we want to update the custom prices
        handlePriceInputsSave(omit(multiPriceEntries, STANDARD_PRICE), existingPriceInputIds),
      preventClosing: true,
      isDisabled: disableSaveMultiPrices,
    },
  ],
});

const MultiPriceConfirmModal = (props) => <div>{props.content}</div>;

export const getMultiPriceConfirmModalParams = (clientId, inputs, updatePriceInputs) => ({
  type: 'warning',
  width: '542px',
  height: 'auto',
  title: i18next.t('GENERAL.MODAL_MULTI_PRICE_DELETE_INPUT_TITLE'),
  icon: '/images/inpulse/gear-white-small.svg',
  component: MultiPriceConfirmModal,
  data: {
    content: i18next.t('GENERAL.MODAL_MULTI_PRICE_DELETE_INPUT_CONTENT'),
  },
  closeConfig: null,
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      handleClick: () => updatePriceInputs(clientId, inputs),
    },
  ],
});

/** Manage Store Group */

export const getAssignGroupListTemplatesModalParams = (
  modalTitle,
  shouldAssociate,
  groupsOfClient,
  selectedStoreIds,
  handleStoreGroupMappingsChange,
  manageStoreGroupMapping,
) => {
  const { items, names, linkedData } = formatItemsForCheckboxList(groupsOfClient, selectedStoreIds);

  return {
    type: 'action',
    width: '542px',
    height: 'auto',
    maxHeight: '560px',
    customPadding: '0 24px 0',
    icon: '/images/inpulse/link-black-small.svg',
    title: modalTitle,
    tooltipText: i18next.t(
      shouldAssociate
        ? 'ADMIN.STORES.MODAL_ASSIGN_GROUPS_TOOLTIP'
        : 'ADMIN.STORES.MODAL_DISSOCIATE_GROUPS_TOOLTIP',
    ),
    component: CheckboxList,
    data: {
      items,
      names,
      linkedData,
      onChange: (items) => handleStoreGroupMappingsChange(items, shouldAssociate),
    },
    closeConfig: {
      title: i18next.t('GENERAL.LEAVE'),
      content: (
        <div>
          {i18next.t(
            shouldAssociate
              ? 'ADMIN.STORES.MODAL_ASSIGN_GROUP_CLOSE_CONFIRMATION_CONTENT'
              : 'ADMIN.STORES.MODAL_DISSOCIATE_GROUP_CLOSE_CONFIRMATION_CONTENT',
          )}
        </div>
      ),
    },
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_SAVE_BUTTON(),
        handleClick: () => manageStoreGroupMapping(selectedStoreIds, [], shouldAssociate),
        isDisabled: isEmpty(groupsOfClient),
      },
    ],
  };
};
