const SHEET = {
  id: 'supplierProducts',
  name: 'Ingrédients fournisseurs',
};

const COLUMN_IDS = {
  CATEGORY: 'category',
  ENTITY: 'entity',
  ID: 'id',
  LOSS: 'loss',
  MANUFACTURER_NAME: 'manufacturerName',
  NAME: 'name',
  PACKAGE_WEIGHT: 'packageWeight',
  PACKAGING_NAME_1: 'packagingName1',
  PACKAGING_NAME_2: 'packagingName2',
  PACKAGING_NAME_3: 'packagingName3',
  PACKAGING_UNIT_1: 'packagingUnit1',
  PACKAGING_UNIT_2: 'packagingUnit2',
  PACKAGING_UNIT_3: 'packagingUnit3',
  PACKAGING_QUANTITY_1: 'packagingQuantity1',
  PACKAGING_QUANTITY_2: 'packagingQuantity2',
  PACKAGING_QUANTITY_3: 'packagingQuantity3',
  PRICE: 'price',
  PRICE_EDITABLE_AT_RECEPTION: 'priceEditableAtReception',
  SKU: 'sku',
  SUBCATEGORY: 'subCategory',
  SUPPLIER: 'supplier',
  VAT_RATE: 'vatRate',
  EAN: 'ean',
};

module.exports = {
  SHEET,
  COLUMN_IDS,
};
