import i18next from 'i18next';
import moment from 'moment';
import React from 'react';

import { InpulseLabel } from '@commons/DeepsightComponents';
import { numberToFixed } from '@commons/utils/format';
import { Tags } from '@commons/Tags';
import DisplayNumber from '@commons/DisplayNumber';

import TooltipAssociationList from '../components/TooltipAssociationList';

import { DateContainer, NameContainer, TextCustomRender } from './styledComponents';

const renderTooltipOrLabelAssociation = (associated, item) => (
  <div
    className="supplier-label-render"
    style={{ minWidth: '115px', marginLeft: '2px', padding: '5px 0px' }}
  >
    {!!associated && <TooltipAssociationList product={item} />}

    {!associated && <InpulseLabel color="red" text={i18next.t('GENERAL.NO')} />}
  </div>
);

export const getColumns = () => [
  {
    id: 'store_name',
    name: i18next.t('GENERAL.NAME'),
    displayName: i18next.t('GENERAL.NAME'),
    propertyKey: 'name',
    baseName: 'name',
    large: true,
    render: (_, item) => (
      <TextCustomRender>
        <NameContainer>
          <TextCustomRender highlighted>{item.name}</TextCustomRender>
          <>
            <>
              {item.new && <InpulseLabel labelStyle={'background-green'} text={'New'} />}
              {item.hidden && <InpulseLabel labelStyle={'background-grey'} text={'Exclu'} />}
            </>
          </>
        </NameContainer>
      </TextCustomRender>
    ),
  },
  {
    id: 'sku',
    baseName: 'sku',
    propertyKey: 'sku',
    displayName: i18next.t('ORDERS.ORDERS.LIST_LABEL_SKU'),
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_SKU'),
    displayBigger: true,
  },
  {
    id: 'cashier_connection',
    baseName: 'cashierConnection',
    propertyKey: 'cashier_connection',
    filterType: 'string',
    displayName: i18next.t('GENERAL.CASHIER_CONNEXION'),
    name: i18next.t('GENERAL.CASHIER_CONNEXION'),
    excludeFromSearch: true,
    render: (item) => <TextCustomRender>{item}</TextCustomRender>,
  },
  {
    id: 'created_at',
    baseName: 'createdAt',
    propertyKey: 'created_at',
    displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_DATE'),
    name: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_DATE'),
    filterType: 'date',
    excludeFromSearch: true,
    render: (item) =>
      item ? (
        <TextCustomRender>
          <DateContainer bold>{moment(item).format('DD/MM/YY')}</DateContainer>
        </TextCustomRender>
      ) : (
        <TextCustomRender>-</TextCustomRender>
      ),
  },
  {
    id: 'sold_at',
    baseName: 'soldAt',
    propertyKey: 'sold_at',
    filterType: 'date',
    filterAllowsNull: true,
    displayName: i18next.t('ADMIN.CASHIER_PRODUCTS.LAST_SALE'),
    name: i18next.t('ADMIN.CASHIER_PRODUCTS.LAST_SALE'),
    excludeFromSearch: true,
    render: (item) =>
      item ? (
        <TextCustomRender>
          <DateContainer>{moment(item).format('DD/MM/YY')}</DateContainer>
        </TextCustomRender>
      ) : (
        <TextCustomRender>-</TextCustomRender>
      ),
  },
  {
    id: 'tags',
    baseName: 'tags',
    propertyKey: 'tags',
    filterType: 'string',
    displayName: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_TAGS'),
    name: i18next.t('BACKOFFICE.CHECKS.EXPORT_MODAL_LABEL_TAGS'),
    render: (items) => <Tags items={items} />,
  },
  {
    id: 'vat_rate',
    baseName: 'vatRate',
    propertyKey: 'vat_rate',
    displayName: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_VAT_RATE'),
    name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_VAT_RATE'),
    filterType: 'numeric',
    excludeFromSearch: true,
    render: (item) => <TextCustomRender>{numberToFixed(item, 2, '-', '%')}</TextCustomRender>,
  },
  {
    id: 'price_with_taxes',
    baseName: 'priceWithTaxes',
    propertyKey: 'price_with_taxes',
    displayName: i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_PRICE_LABEL'),
    name: i18next.t('FORECAST.PRODUCT_MIX.LIST_HEADER_PRICE_LABEL'),
    filterType: 'numeric',
    excludeFromSearch: true,
    render: (item) => <DisplayNumber displayCurrencyCode={true} number={item} />,
  },
  {
    id: 'associated',
    baseName: 'mapped',
    propertyKey: 'mapped',
    displayName: i18next.t('GENERAL.LINKED'),
    name: i18next.t('GENERAL.LINKED'),
    excludeFromSearch: true,
    render: (_, item) => renderTooltipOrLabelAssociation(_, item),
  },
];
