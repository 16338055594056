import { APIcaller } from './APIcaller';

const getDashboardAnalytics = (storeIds, startDate, endDate, userTimezone) =>
  APIcaller.apiPost('/dashboard/analytics-by-store', {
    storeIds,
    startDate,
    endDate,
    userTimezone,
  });

export default {
  getDashboardAnalytics,
};
