import AsyncLocalStorage from '@createnextapp/async-local-storage';

export const setLocalStorageItem = (key, value) =>
  (async () => await AsyncLocalStorage.setItem(key, value))();

export const removeLocalStorageItem = (key) =>
  (async () => await AsyncLocalStorage.removeItem(key))();

export const getLocalStorageItem = async (key) => AsyncLocalStorage.getItem(key);

export const getIsCentralMode = () => {
  const isCentralMode = window.localStorage.getItem('isCentralMode');

  return isCentralMode ? JSON.parse(isCentralMode) : false;
};
