import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage } from '@actions/messageconfirmation';

import { ListView } from '@commons/utils/styledLibraryComponents';
import DisplayNumber from '@commons/DisplayNumber';

import { fetchIngredientCostFromSupplierList } from '@admin/products/ingredients/detail/commons/services';
import { getIngredientUnit } from '@admin/products/ingredients/detail/components/IngredientInfo/common/inputs';

import {
  CostContainer,
  CostTitle,
  CostValue,
  FiltersContainer,
  MapSupplierProductsModalContainer,
} from './styledComponents';

const LIST_OPTION_MAX_PAGE = 500;
const MAX_ITEMS_LIST_BEFORE_DISPLAYING_FOOTER = 1000;
const TIMEOUT_IN_MS_BEFORE_DISPLAYING_LISTVIEW_WITH_DATA = 500;

const COLUMNS = [
  {
    id: 0,
    name: i18next.t('GENERAL.NAME'),
    type: 'string',
    propertyKey: 'name',
    filterType: 'string',
  },
  {
    id: 1,
    name: i18next.t('GENERAL.SUPPLIER'),
    type: 'string',
    propertyKey: 'supplierName',
    filterType: 'string',
  },
  {
    id: 2,
    name: i18next.t('GENERAL.UNIT'),
    type: 'string',
    propertyKey: 'packagingUnit',
    filterType: 'string',
    render: (item) => <div>{item === 'unit' ? i18next.t('GENERAL.UNIT') : item}</div>,
  },
  {
    id: 3,
    name: i18next.t('ADMIN.INGREDIENTS.LABEL_COST'),
    type: 'string',
    propertyKey: 'convertedPrice',
    filterType: 'string',
    render: (item) => <DisplayNumber displayCurrencyCode={true} number={item || 0} />,
  },
  {
    id: 4,
    name: i18next.t('ADMIN.RECIPES.INGREDIENT_CREATION_LOSS_PERCENTAGE'),
    type: 'string',
    propertyKey: 'loss',
    filterType: 'string',
    render: (item) => <div>{`${item || 0}%`}</div>,
  },
];

const MapSupplierProductsModal = (props) => {
  const {
    supplierProductsToDisplay,
    userLanguageCode,
    handleSupplierProductsSelection,
    ingredientInformationsData,
    pageLoading,
    pageLoaded,
    showErrorMessage,
    setIngredientInformationsData,
    currency,
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, TIMEOUT_IN_MS_BEFORE_DISPLAYING_LISTVIEW_WITH_DATA);
  }, []);

  const updateIngredientCost = async (supplierProducts) => {
    if (supplierProducts.length > 0) {
      pageLoading();

      const supplierProductsIds = supplierProducts.map((supplierProduct) => supplierProduct.id);

      try {
        const ingredientCost = await fetchIngredientCostFromSupplierList(
          supplierProductsIds,
          ingredientInformationsData.unit,
        );

        setIngredientInformationsData({ ...ingredientInformationsData, cost: ingredientCost });
      } catch {
        showErrorMessage(i18next.t('ADMIN.INGREDIENTS.COST_FECTH_ERROR'));
      }

      pageLoaded();
      return;
    }
  };

  const disableFooter = supplierProductsToDisplay.length <= MAX_ITEMS_LIST_BEFORE_DISPLAYING_FOOTER;

  return (
    <MapSupplierProductsModalContainer hasFooter={!disableFooter}>
      <ListView
        columns={COLUMNS}
        data={supplierProductsToDisplay}
        defaultMaxPerPage={LIST_OPTION_MAX_PAGE}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        disableFooter={disableFooter}
        forceEnableSelection={true}
        hideAllPerPageOption={true}
        isLoading={isLoading}
        languageCode={userLanguageCode}
        maxPerPageOptions={[LIST_OPTION_MAX_PAGE]}
        renderFilterButton={() => (
          <FiltersContainer>
            <CostContainer>
              <CostTitle>
                {i18next.t('ADMIN.RECIPES.INGREDIENT_CREATION_MODAL_EX_TAX_COST', {
                  unit: getIngredientUnit(ingredientInformationsData.unit),
                  currency: currency.alphabeticCode,
                })}
              </CostTitle>
              <CostValue>
                {ingredientInformationsData.cost
                  ? parseFloat(ingredientInformationsData.cost)
                  : '--'}
              </CostValue>
            </CostContainer>
          </FiltersContainer>
        )}
        setSelectedItems={(items) => {
          handleSupplierProductsSelection(items);
          updateIngredientCost(items);
        }}
      />
    </MapSupplierProductsModalContainer>
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MapSupplierProductsModal);
