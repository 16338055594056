/**
 * Stock module's feature paths
 */
export const STOCKS_FEATURE_PATHS = {
  STOCKS_ANALYTICS_CURRENT: '/stocks/analytics/current',
  STOCKS_ANALYTICS_PAST: '/stocks/analytics/past',
  STOCKS_ANALYTICS_PAST_DEPRECATED: '/stocks/analytics/pastDEPRECATED',
  STOCKS_INVENTORIES_STOCKS: '/stocks/inventories/stocks',
  STOCKS_INVENTORIES_TRANSFER: '/stocks/inventories/transfer',
};

export const CENTRAL_KITCHEN_STOCKS_FEATURE_PATHS = {
  STOCKS_CENTRAL_KITCHEN_INVENTORIES_RECIPES: '/central-kitchen-stocks/inventories/recipes',
  STOCKS_CENTRAL_KITCHEN_INVENTORIES_RECIPES_CREATE:
    '/central-kitchen-stocks/inventories/recipes/create',
  STOCKS_CENTRAL_KITCHEN_INVENTORIES_RECIPES_DETAILS:
    '/central-kitchen-stocks/inventories/recipes/:id/details',
  STOCKS_CENTRAL_KITCHEN_INVENTORIES_SUPPLIER_PRODUCTS:
    '/central-kitchen-stocks/inventories/supplier-products',
  STOCKS_CENTRAL_KITCHEN_ANALYTICS_PAST_RECIPES: '/central-kitchen-stocks/analytics-past/recipes',
  STOCKS_CENTRAL_KITCHEN_ANALYTICS_PAST_INGREDIENTS:
    '/central-kitchen-stocks/analytics-past/ingredients',
};

export const CENTRAL_KITCHEN_ORDER_FEATURE_PATHS = {
  ORDER_CENTRAL_KITCHEN_LIST: '/central-kitchen-order/orders',
  ORDER_CENTRAL_KITCHEN_PREPARATION: '/central-kitchen-order/preparation',
};

export const CENTRAL_KITCHEN_PRODUCTION_FEATURE_PATHS = {
  PRODUCTION_CENTRAL_KITCHEN_OPERATIONS: '/central-kitchen-production/operations',
};

/**
 * Loss module's feature paths
 */
export const LOSSES_FEATURE_PATHS = {
  LOSSES_LOSSES_PRODUCTS: '/losses/losses/products',
  LOSSES_LOSSES_SUPPLIER_PRODUCTS: '/losses/losses/supplier-products',
};

/**
 * Forecast module's feature paths
 */
export const FORECAST_FEATURE_PATHS = {
  FORECAST_FORECAST: '/forecast/forecast',
  FORECAST_FORECAST_TURNOVER: '/forecast/forecast/turnover',
  FORECAST_FORECAST_PRODUCT_MIX: '/forecast/forecast/product-mix',
  FORECAST_ANALYTICS_SALES: '/forecast/analytics/sales',
  FORECAST_ANALYTICS_PAST_MIX: '/forecast/analytics/past-mix',
  FORECAST_ANALYTICS_INGREDIENTS_CONSUMPTION: '/forecast/analytics/ingredients-consumption',
};

/**
 * Margin module's feature paths
 */

export const MARGIN_FEATURE_PATHS = {
  MARGIN_ANALYTICS: '/margin/analytics',
  MARGIN_ANALYTICS_BY_STORE: '/margin/analytics/store',
  MARGIN_ANALYTICS_EVOLUTION: '/margin/analytics/evolution',
};

/**
 * Admin module's feature paths
 */
export const ADMIN_FEATURE_PATHS = {
  ADMIN_STORES: '/admin/stores',
  ADMIN_STORES_DETAILS_INFORMATIONS: '/admin/stores/:id/details',
  ADMIN_STORES_DETAILS_ASSOCIATED_USERS: '/admin/stores/:id/details-users',
  ADMIN_STORES_DETAILS_PRODUCTION_PLANNING: '/admin/stores/:id/products',
  ADMIN_STORES_DETAILS_ASSOCIATED_SUPPLIER_PROFILES: '/admin/stores/:id/supplierProfiles',
  ADMIN_STORES_DETAILS_ASSOCIATED_SUPPLIER_PRODUCTS: '/admin/stores/:id/supplier-products',
  ADMIN_PRODUCTS: '/admin/products',
  ADMIN_KITCHEN_PRODUCTS_PRODUCTS: '/admin/kitchen-products/products',
  ADMIN_KITCHEN_PRODUCTS_RECIPES: '/admin/kitchen-products/recipes',
  ADMIN_SALES: '/admin/sales',
  ADMIN_PRODUCTS_INGREDIENTS: '/admin/products/ingredients',
  ADMIN_PRODUCTS_PRODUCTS: '/admin/products/products',
  ADMIN_KITCHEN_PRODUCTS_CREATE: '/admin/kitchen-products/products/create',
  ADMIN_KITCHEN_PRODUCTS_DETAILS: '/admin/kitchen-products/products/:id/details',
  ADMIN_PRODUCTS_RECIPES: '/admin/products/recipes',
  ADMIN_SUPPLIERS_SUPPLIERS: '/admin/suppliers/suppliers',
  ADMIN_SUPPLIERS_SUPPLIERS_DETAILS: '/admin/suppliers/:id/details',
  ADMIN_SUPPLIERS_SUPPLIERS_SUPPLIER_PROFILES: '/admin/suppliers/:id/supplier-profiles',
  ADMIN_SUPPLIERS_SUPPLIERS_ASSOCIATED_STORES: '/admin/suppliers/:id/associated-stores',
  ADMIN_SUPPLIERS_SUPPLIER_PRODUCTS: '/admin/suppliers/supplier-products',
  ADMIN_SUPPLIERS_SUPPLIER_PRODUCTS_CREATION: '/admin/suppliers/supplier-products/create',
  ADMIN_SUPPLIERS_SUPPLIER_PRODUCT_DETAIL: '/admin/suppliers/supplier-products/:id/details',
  ADMIN_SUPPLIERS_SUPPLIER_PRODUCT_STORES: '/admin/suppliers/supplier-products/:id/stores',
  ADMIN_INGREDIENTS: '/admin/products/ingredients',
  ADMIN_CASHIER: 'admin/cashier',
  ADMIN_CASHIER_TRANSACTIONS: 'admin/cashier/transactions',
  ADMIN_CASHIER_CASHIER_PRODUCTS: 'admin/cashier/cashier-products',
  ADMIN_CASHIER_CASHIER_PRODUCTS_MAPPING_WITH_PRODUCTS:
    '/admin/cashier/cashier-products/mapping-products',
  ADMIN_INVENTORIES: '/admin/inventories',
  ADMIN_INVENTORIES_STORAGE_AREAS: '/admin/inventories/storage-areas',
  ADMIN_CATALOGS: '/admin/catalogs',
  ADMIN_CATALOGS_CREATION: '/admin/catalogs/create',
};
