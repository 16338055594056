import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { showConfirmationMessage } from '@actions/messageconfirmation';

import { ListView, Button } from '@commons/utils/styledLibraryComponents';
import EmptyState from '@commons/EmptyState';

import cashierService from '@services/cashier';

import {
  Container,
  Header,
  Title,
  CloseButton,
  Icon,
  TitleContainer,
  Content,
  Footer,
} from './styledComponents';

const AddCashierConfigModal = (props) => {
  const { dataColumns, userLanguageCode, goToStepUpdateCashierConfig } = props.params;

  const [isLoading, setIsLoading] = useState(true);
  const [selectedCashier, setSelectedCashier] = useState(null);
  const [cashiersToDisplay, setCashierToDisplay] = useState([]);

  const loadData = async () => {
    setIsLoading(true);
    try {
      const cashiersToDisplay = await cashierService.getAllCashiers();
      setCashierToDisplay(cashiersToDisplay);
    } catch (err) {
      props.showMessage(i18next.t('BACKOFFICE.CASHIERS.FETCH_CASHIERS_FAILURE'), 'error');
      setCashierToDisplay([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, []);

  const transferCreationToModal = async () => {
    props.closeModal();

    return goToStepUpdateCashierConfig(selectedCashier);
  };

  return (
    <Container>
      <Header>
        <TitleContainer>
          <Icon alt="add" src="/images/inpulse/add-black-small.svg" />
          <Title>{i18next.t('BACKOFFICE.CASHIERS.ADD_CASHIER_CONFIG_TITLE')}</Title>
        </TitleContainer>
        <CloseButton
          alt="close"
          src={'/images/inpulse/close-black-small.svg'}
          onClick={props.closeModal}
        />
      </Header>
      <Content>
        <ListView
          columns={dataColumns}
          data={cashiersToDisplay}
          defaultOrderBy={'name'}
          defaultOrderType={'asc'}
          disableFooter={true}
          disableMultipleSelection={true}
          forceEnableSelection={true}
          isLoading={isLoading}
          languageCode={userLanguageCode}
          padding={'24px 24px 0px 24px'}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => <EmptyState />}
          setSelectedItems={(items) => {
            if (!items.length) {
              return;
            }
            setSelectedCashier(items[0]);
          }}
        />
      </Content>
      <Footer>
        <Button
          color={'inpulse-outline'}
          handleClick={() => props.closeModal()}
          icon="/images/inpulse/close-black-small.svg"
          isDisabled={isLoading}
          label={i18next.t('GENERAL.CANCEL')}
        />
        <Button
          buttonCustomStyle={{ marginLeft: 24 }}
          color={'inpulse-default'}
          handleClick={() => transferCreationToModal()}
          icon="/images/inpulse/arrow-right-white.svg"
          isDisabled={isLoading || !selectedCashier}
          label={i18next.t('GENERAL.NEXT')}
        />
      </Footer>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
});

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCashierConfigModal);
