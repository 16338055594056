import { DATE_PICKER_DOT_COLOR } from '../constants';
import styled, { css } from 'styled-components';

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const CircleContainer = styled.div`
  width: 100%;
  height: 100%;
  display: 'flex';
  justify-content: center;
`;

export const Circle = styled.div`
  margin: auto;
  flex-direction: column;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: ${(props) => props.theme.colors.brand.primaryLight} !important;
  display: flex;
  color: ${(props) => props.theme.colors.greys.darkest};
  margin-top: 3px;
`;

export const DayContainer = styled.div`
  padding-bottom: ${(props) => props.hasDot && '5px'};
  text-align: center;
`;

export const DotContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: ${(props) => props.hasDot && '-2px'};
`;

export const Dot = styled.div`
  float: right;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.brand.primary};
  margin-right: ${(props) => (props.hasCircle ? '2px' : '5px')};
  display: ${(props) => (props.hasDot ? 'flex' : 'none')};

  ${(props) => {
    if (props.color === DATE_PICKER_DOT_COLOR.RED) {
      return css`
        background-color: ${(props) => props.theme.colors.infoRed};
      `;
    }

    if (props.color === DATE_PICKER_DOT_COLOR.GREEN) {
      return css`
        background-color: ${(props) => props.theme.colors.brand.primary};
      `;
    }

    if (props.color === DATE_PICKER_DOT_COLOR.ORANGE) {
      return css`
        background-color: ${(props) => props.theme.colors.infoOrange};
      `;
    }
  }}
`;

export default {
  DateContainer,
  DayContainer,
  DotContainer,
  CircleContainer,
  Dot,
  Circle,
};
