import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { closeGenericModal, openGenericModal, refreshGenericModal } from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';

import { getClientInfo } from '@selectors/client';

import { storeSupplierProfileMapping } from '@services/storeSupplierProfileMapping';
import { supplierProduct as supplierProductService } from '@services/supplierProduct';
import { supplierProfile as supplierProfileService } from '@services/supplierProfile';

import { buildPackagingFormattedName } from './stepsModals/utils';
import {
  SET_SUPPLIER_PROFILE_MODAL_STEPS,
  getSetSupplierProfileModalConfig,
} from './modalConfiguration';
import { SUPPLIER_PROFILE_SETTING_ACTIONS } from './constants';

/**
 * This component does not directly render anything. Instead it makes use of the GenericModal via
 * the redux actions, manages their respective states and takes care of the supplier profile settings logic.
 *
 * @param {*} props
 */
const SupplierProfileSettingsModal = (props) => {
  const {
    user,
    modal,
    stores,
    activeStores,
    supplier,
    showSuccessMessage,
    showErrorMessage,
    pageLoading,
    pageLoaded,
    supplierProfile,
    actionType,
    reloadData,
    resetSettingSupplierProfile,
    handleCallToCreateSupplierProfile,
    handleCallToDuplicateSupplierProfile,
    handleCallToAssociateStoresSupplierProfile,
    openGenericModal,
    refreshGenericModal,
    closeGenericModal,
    allSupplierProfiles,
    client: { storeName, clientId, hasMultipleTimezones, defaultTimezone },
  } = props;

  // States //
  const [currentStep, setCurrentStep] = useState(
    actionType === SUPPLIER_PROFILE_SETTING_ACTIONS.ASSOCIATE_SUPPLIER_PROFILE_STORES
      ? SET_SUPPLIER_PROFILE_MODAL_STEPS.ASSOCIATE_SUPPLIER_PROFILE_STORES
      : SET_SUPPLIER_PROFILE_MODAL_STEPS.SET_SUPPLIER_PROFILE_INFORMATIONS,
  );
  const [includeSelectStoreToCloneStep, setIncludeSelectStoreToCloneStep] = useState(true);

  const [supplierProfileInformationsData, setSupplierProfileInformationsData] = useState({
    supplierProfile,
    deliveryOptions: [],
  });
  const [storeToCloneData, setStoreToCloneData] = useState({});
  const [availableStoresToClone, setAvailableStoresToClone] = useState([]);

  // Store associations
  const [selectedStoreAssociations, setSelectedStoreAssociations] = useState([]);
  const [availableStoreAssociations, setAvailableStoreAssociations] = useState([]);

  // Supplier product associations
  const [selectedSupplierProductAssociations, setSelectedSupplierProductAssociations] = useState(
    [],
  );
  const [availableSupplierProductAssociations, setAvailableSupplierProductAssociations] = useState(
    [],
  );

  const [supplierProductsPropertiesData, setSupplierProductsPropertiesData] = useState({
    available: true,
    hasStock: true,
    hasDlc: true,
  });

  const clientStoreName = storeName;
  const clientStoreNamePlural = getClientStoreNameTranslation(storeName, true).toLowerCase();

  const getStoreSupplierProfileMappingsOfSupplier = async () => {
    const storeSupplierProfileMappings =
      await storeSupplierProfileMapping.getStoreSupplierProfileMappingsOfSupplier(supplier.id);

    const storeSupplierProfileMappingsIds = storeSupplierProfileMappings.map(
      ({ storeId }) => storeId,
    );
    const filteredStores = activeStores.filter(
      ({ id }) => !storeSupplierProfileMappingsIds.includes(id),
    );

    return filteredStores;
  };

  const getSupplierProductsOfSupplier = async () => {
    try {
      const supplierId = _.get(supplier, 'id', null);

      const supplierProductsOfClient =
        await supplierProductService.getSupplierProductsGroupedByProperty(clientId, null, true);

      const supplierProducts = _.get(supplierProductsOfClient, 'supplierProducts');

      const supplierProductsOfSuppliers = supplierProducts.filter((supplierProduct) =>
        _.includes([supplierId], supplierProduct.supplierId),
      );

      let supplierProductAssociations = sortArrayOfObjectsAlphabetically(
        supplierProductsOfSuppliers,
        'name',
      );

      supplierProductAssociations = supplierProductAssociations
        .map((supplierProduct) => ({
          ...supplierProduct,
          isRowSelected: true,
          packagingFormattedName: buildPackagingFormattedName(supplierProduct),
        }))
        .filter((supplierProductAssociation) => supplierProductAssociation.active);

      setAvailableSupplierProductAssociations(supplierProductAssociations);
      setSelectedSupplierProductAssociations(supplierProductAssociations);
    } catch (error) {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FETCH_ERROR'));
    }
  };

  const getCloningStoresToDisplay = async () => {
    try {
      const storeSupplierProfileMappings =
        await storeSupplierProfileMapping.getStoreSupplierProfileMappingsOfSupplier(supplier.id);

      const allSupplierProfilesById = _.keyBy(allSupplierProfiles, 'id');

      const filteredStores = stores.reduce((result, store) => {
        const matchingStoreSupplierProfileMapping = _.find(storeSupplierProfileMappings, {
          storeId: store.id,
        });

        if (!matchingStoreSupplierProfileMapping) {
          return result;
        }

        const supplierProfileName = _.get(
          allSupplierProfilesById,
          `[${matchingStoreSupplierProfileMapping.supplierProfileId}].name`,
          '-',
        );

        result.push({ ...store, supplierProfileName });

        return result;
      }, []);

      setAvailableStoresToClone(filteredStores);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIERS.ASSOCIATED_STORES_FETCH_ERROR'));
    }
  };

  // UseEffect //
  useEffect(() => {
    (async function loadData() {
      try {
        const availableStores = await getStoreSupplierProfileMappingsOfSupplier();

        // If supplier is a kitchen supplier, kitchen stores are not available
        const formattedStores = availableStores.filter(
          ({ isKitchen }) => !supplier.isKitchen || !isKitchen,
        );

        setAvailableStoreAssociations(formattedStores);
      } catch {
        showErrorMessage(
          i18next.t(
            'ADMIN.SUPPLIERS.SUPPLIER_PROFILE_FETCH_STORE_SUPPLIER_PROFILE_MAPPINGS_ERROR',
            { storeName: clientStoreNamePlural },
          ),
        );
      }
    })();

    getSupplierProductsOfSupplier();
    getCloningStoresToDisplay();
  }, []);

  useEffect(() => {
    if (!currentStep) {
      return;
    }

    const isModalOpened = _.get(modal, 'GenericModalBool', false);

    const params = getSetSupplierProfileModalConfig({
      user,
      currentStep,
      setCurrentStep,
      resetModal,
      clientStoreName,
      stores,
      supplier,
      supplierProfile,
      clientStoreNamePlural,
      actionType,
      availableStoreAssociations,
      availableSupplierProductAssociations,
      availableStoresToClone,
      handleCallToCreateSupplierProfile,
      handleCallToDuplicateSupplierProfile,
      handleCallToAssociateStoresSupplierProfile,
      includeSelectStoreToCloneStep,
      supplierProfileInformationsData,
      setIncludeSelectStoreToCloneStep,
      setSupplierProfileInformationsData,
      storeToCloneData,
      setStoreToCloneData,
      supplierProductsPropertiesData,
      setSupplierProductsPropertiesData,
      allSupplierProfiles,
      selectedStoreAssociations,
      setSelectedStoreAssociations,
      handleSelectStoreAssociations,
      selectedSupplierProductAssociations,
      setSelectedSupplierProductAssociations,
      handleSelectSupplierProductAssociations,
      handleSupplierProfileSaving,
      clientHasMultipleTimezones: hasMultipleTimezones,
      clientDefaultTimezone: defaultTimezone,
    });

    if (isModalOpened) {
      refreshGenericModal(params);
      return;
    }

    openGenericModal(params);
  }, [
    currentStep,
    includeSelectStoreToCloneStep,
    supplierProfileInformationsData,
    storeToCloneData,
    availableStoreAssociations,
    selectedStoreAssociations,
    availableSupplierProductAssociations,
    selectedSupplierProductAssociations,
    supplierProductsPropertiesData,
    availableStoresToClone,
  ]);

  useEffect(() => {
    if (!!supplierProfile.deliveryDays && !!supplierProfile.deliveryLeadTimes) {
      const deliveryDays = _.get(supplierProfile, 'deliveryDays', '').split(';');
      const deliveryLeadTimes = _.get(supplierProfile, 'deliveryLeadTimes', '').split(';');

      const deliveryOptions = deliveryDays.map((deliveryDay, index) => ({
        deliveryDay: deliveryDay,
        deliveryLeadTime: deliveryLeadTimes[index],
      }));

      setSupplierProfileInformationsData({ ...supplierProfileInformationsData, deliveryOptions });
    }
  }, [supplierProfile.deliveryDays, supplierProfile.deliveryLeadTimes]);

  // Functions //
  const resetModal = () => {
    resetSettingSupplierProfile();
    closeGenericModal();
  };

  /**
   * Passed to setSelectedItems prop to the ListView managing the Stores
   * in order to properly navigate between the modal pages and keep the selection
   * */
  const handleSelectStoreAssociations = (selectedStores) => {
    const selectedStoresByIds = _.keyBy(selectedStores, 'id');

    const formattedStores = availableStoreAssociations.map((store) => {
      if (selectedStoresByIds[store.id]) {
        return { ...store, isRowSelected: true };
      }
      return { ...store, isRowSelected: false };
    });

    if (!_.isEqual(availableStoreAssociations, formattedStores)) {
      setAvailableStoreAssociations(formattedStores);
    }

    setSelectedStoreAssociations(selectedStores);
  };

  /**
   * Passed to setSelectedItems prop to the ListView managing the supplier products
   * in order to properly navigate between the modal pages and keep the selection
   * */
  const handleSelectSupplierProductAssociations = (selectedSupplierProducts) => {
    const selectedSPsByIds = _.keyBy(selectedSupplierProducts, 'id');

    const formattedSPs = availableSupplierProductAssociations.map((supplierProduct) => {
      if (selectedSPsByIds[supplierProduct.id]) {
        return { ...supplierProduct, isRowSelected: true };
      }
      return { ...supplierProduct, isRowSelected: false };
    });

    if (!_.isEqual(availableSupplierProductAssociations, formattedSPs)) {
      setAvailableSupplierProductAssociations(formattedSPs);
    }

    setSelectedSupplierProductAssociations(selectedSupplierProducts);
  };

  const formatSupplierProductAssociationForSaving = (supplierProductAssociations) => {
    const { available, hasStock, hasDlc } = supplierProductsPropertiesData;

    return supplierProductAssociations.map((supplierProduct) => ({
      id: supplierProduct.id,
      price: supplierProduct.price,
      available,
      hasDlc,
      hasStock,
    }));
  };

  const handleSupplierProfileSaving = async () => {
    pageLoading();

    let supplierProfile = _.get(supplierProfileInformationsData, 'supplierProfile', null);

    if (
      [
        SUPPLIER_PROFILE_SETTING_ACTIONS.CREATE_SUPPLIER_PROFILE,
        SUPPLIER_PROFILE_SETTING_ACTIONS.DUPLICATE_SUPPLIER_PROFILE,
      ].includes(actionType)
    ) {
      const validDeliveryOptions = _.get(supplierProfileInformationsData, 'deliveryOptions', []);

      const filteredDeliveryDate = validDeliveryOptions.filter(
        (option) => option.deliveryDay && option.deliveryLeadTime,
      );

      const deliveryDays = filteredDeliveryDate.map((option) => option.deliveryDay).join(';');
      const deliveryLeadTimes = filteredDeliveryDate
        .map((option) => option.deliveryLeadTime)
        .join(';');

      supplierProfile = {
        ...supplierProfile,
        deliveryDays,
        deliveryLeadTimes,
      };
    }

    const storeAssociationsIds = selectedStoreAssociations.map((store) => store.id);
    const cloneFromStoreId = _.get(storeToCloneData, 'id', false);
    const supplierProductAssociations =
      _.isEmpty(storeToCloneData) && !_.isEmpty(storeAssociationsIds)
        ? formatSupplierProductAssociationForSaving(selectedSupplierProductAssociations)
        : [];

    pageLoading();

    try {
      await supplierProfileService.postSupplierProfileWithMappings(
        supplierProfile,
        storeAssociationsIds,
        supplierProductAssociations,
        cloneFromStoreId,
      );
      showSuccessMessage(i18next.t('ADMIN.SUPPLIERS.SAVING_SUCCESS'));
      reloadData();
    } catch (error) {
      showErrorMessage(i18next.t('ADMIN.SUPPLIERS.SAVING_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  return <div />;
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  modal: state.modalReducer,
  stores: state.baseReducer.stores,
  activeStores: state.baseReducer.activeStores,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  closeGenericModal: () => {
    dispatch(closeGenericModal());
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

SupplierProfileSettingsModal.propTypes = {
  supplier: PropTypes.object,
  supplierProfile: PropTypes.object, // passed by SupplierProfile/index.js
  actionType: PropTypes.string, // passed by SupplierProfile/index.js
  reloadData: PropTypes.func,
  resetSettingSupplierProfile: PropTypes.func, // passed by SupplierProfile/index.js
  handleCallToCreateSupplierProfile: PropTypes.func, // passed by SupplierProfile/index.js
  handleCallToDuplicateSupplierProfile: PropTypes.func, // passed by SupplierProfile/index.js
  handleCallToAssociateStoresSupplierProfile: PropTypes.func, // passed by SupplierProfile/index.js
  openGenericModal: PropTypes.func, // passed by redux
  refreshGenericModal: PropTypes.func, // passed by redux
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierProfileSettingsModal);
