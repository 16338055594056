import styled from 'styled-components';

export const CustomModalContainer = styled.div`
  z-index: 3;
  position: absolute;

  display: flex;
  flex-direction: column;

  min-height: 164px;
  min-width: 496px;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  margin-top: 8px;

  background: ${(props) => props.theme.colors?.greys.lightest};
`;

export const Title = styled.div`
  z-index: 3;
  position: relative;

  padding: 16px 16px 8px 16px;

  font: ${(props) => props.theme.fonts?.textMiddleBold};
`;

export const CustomModalBody = styled.div`
  z-index: 2;
  position: relative;
  overflow: auto;

  padding: 16px;

  cursor: auto;
  height: 164px;
  min-width: 496px;
`;

export const CloseIcon = styled.img`
  z-index: 3;
  position: absolute;
  width: 10px;

  top: 20px;
  right: 20px;
  cursor: pointer;
`;
