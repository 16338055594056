import React, { useState } from 'react';
import styled from 'styled-components';

import { Button } from '@commons/utils/styledLibraryComponents';
import Drawer from '@commons/Drawer';

import EventForm from '@src/routes/events/EventForm';

const InpulseAddEventButton = (props) => {
  const [displayEventForm, setDisplayEventForm] = useState(false);

  const toggleEventForm = () => {
    setDisplayEventForm(!displayEventForm);
  };

  return (
    <div className={props.className}>
      <Button
        buttonCustomStyle={{ width: '60px', height: '60px', minWidth: '60px', borderRadius: '50%' }}
        color={'inpulse-default'}
        handleClick={toggleEventForm}
        icon={'/images/icon-plus-white.svg'}
        iconCustomStyle={{ width: '28px', height: '28px', margin: '0' }}
        label={''}
      />
      <Drawer isOpened={displayEventForm} onClick={toggleEventForm}>
        <EventForm {...props} closeForm={toggleEventForm} />
      </Drawer>
    </div>
  );
};

const StyledComponent = styled(InpulseAddEventButton)`
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  margin-left: 370px;
  left: 0px;
  bottom: 32px;
  width: 40px;
  height: 40px;
  padding: 0 0 3px 0;
  font: ${(props) => props.theme.fonts?.textBig};
  font-size: 32px;
`;

export const InpulseAddEvent = StyledComponent;
