import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.greys.lighter};
  border-radius: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  ${({ isDragging, hasError, theme }) => {
    if (isDragging) {
      return css`
        border: 1px solid ${theme.colors.brand.primaryDarker};
      `;
    }

    if (hasError) {
      return css`
        border: 1px solid ${theme.colors.infoRed};
      `;
    }

    return css`
      background-image: url('/images/inpulse/drag-and-drop-border.svg');
    `;
  }}
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 16px;
`;

export const EmptyStateTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const ImportInProgressContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.greys.lighter};
  border-radius: 15px;

  border: 1px solid ${(props) => props.theme.colors.dgrey};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImportInfosContainer = styled.div`
  width: 240px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;

  text-align: center;
`;

export const LoaderContainer = styled.div`
  width: fit-content;
  margin-bottom: 24px;
`;

export const ImportEndedContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.greys.lighter};
  border-radius: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid
    ${({ type, theme }) =>
      type === 'success' ? theme.colors.brand.primaryDarker : theme.colors.infoRed};
`;

export const ImportSuccessTextContainer = styled.div`
  width: 240px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
`;

export const ImportSuccessContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.greys.lighter};
  border-radius: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${(props) => props.theme.colors.brand.primaryDarker};
`;
