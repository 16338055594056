import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { ModalIconProps } from '../../interface';

import CustomModalContentDisplay from '../CustomModalContentDisplay/CustomModalContentDisplay';

import { IconContainer, Icon } from './styledComponents';

import IconOrderWhite from '../../../images/icon-order-white.svg';
import IconOrderDark from '../../../images/icon-order-dark.svg';
import IconOrderGreen from '../../../images/icon-order-green.svg';

import IconStockWhite from '../../../images/icon-stock-white-bordered.svg';
import IconStockDark from '../../../images/icon-stock-dark-bordered.svg';
import IconStockGreen from '../../../images/icon-stock-green-bordered.svg';

import { getTheme } from '../../../utils/theme';

const CustomModalButtonDisplay = (props: ModalIconProps): JSX.Element => {
  const {
    icon,
    theme,
    openModal,
    openedModal,
    modalContent,
    modalTitle,
    modalHeaders,
    labelModalEmptyState,
  } = props;

  const icons = {
    'icon-order-closed': IconOrderWhite,
    'icon-order-hovered': IconOrderGreen,
    'icon-order-opened': IconOrderDark,
    'icon-stock-closed': IconStockWhite,
    'icon-stock-hovered': IconStockGreen,
    'icon-stock-opened': IconStockDark,
  };

  const [activeChart, setActiveChart] = useState(false);

  return (
    <ThemeProvider theme={getTheme(theme, 'nestedList')}>
      <IconContainer
        onClick={(e) => {
          e.stopPropagation();
          openModal();
        }}
      >
        {icon && (
          <div style={{ cursor: 'pointer' }}>
            <Icon
              src={
                // eslint-disable-next-line no-nested-ternary
                openedModal
                  ? icons[`${icon}-opened`]
                  : activeChart
                  ? icons[`${icon}-hovered`]
                  : icons[`${icon}-closed`]
              }
              onMouseEnter={() => setActiveChart(true)}
              onMouseLeave={() => setActiveChart(false)}
              selected={openedModal}
            />
          </div>
        )}
        {openedModal && (
          <CustomModalContentDisplay
            theme={theme}
            modalContent={modalContent}
            openModal={openModal}
            title={modalTitle}
            modalHeaders={modalHeaders}
            labelModalEmptyState={labelModalEmptyState}
          />
        )}
      </IconContainer>
    </ThemeProvider>
  );
};

CustomModalButtonDisplay.propTypes = {
  icon: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  modalContent: PropTypes.func.isRequired,
  openedModal: PropTypes.bool,
};

CustomModalButtonDisplay.defaultProps = {
  openedModal: false,
};

export default CustomModalButtonDisplay;
